import FieldModel, { DATE } from '../../models/FieldModel';
import { getAttributeExistingObject, toBrazilianDate } from '../utils/utils';

export function buildCampanha(campanha) {
  if (campanha === null) {
    return null;
  }
  return {
    idCampanha: new FieldModel({
      name: 'idCampanha',
      value: getAttributeExistingObject(campanha, 'idCampanha'),
    }),
    idCliente: new FieldModel({
      name: 'idCliente',
      value: getAttributeExistingObject(campanha, 'email'),
    }),
    nome: new FieldModel({
      name: 'nome',
      value: getAttributeExistingObject(campanha, 'nome'),
    }),
    descricao: new FieldModel({
      name: 'descricao',
      value: getAttributeExistingObject(campanha, 'descricao'),
    }),
    dataInicio: new FieldModel({
      name: 'dataInicio',
      value: toBrazilianDate(getAttributeExistingObject(campanha, 'dataInicio')),
      type: DATE,
    }),
    dataTermino: new FieldModel({
      name: 'dataTermino',
      value: toBrazilianDate(getAttributeExistingObject(campanha, 'dataTermino')),
      type: DATE,
    }),
    dataLimiteAcordo: new FieldModel({
      name: 'dataLimiteAcordo',
      value: toBrazilianDate(getAttributeExistingObject(campanha, 'dataLimiteAcordo')),
      type: DATE,
    }),
    idTipoDesconto: new FieldModel({
      name: 'idTipoDesconto',
      value: getAttributeExistingObject(campanha, 'idTipoDesconto', 0),
    }),
    porcentagemDesconto: new FieldModel({
      name: 'porcentagemDesconto',
      value: getAttributeExistingObject(campanha, 'porcentagemDesconto'),
    }),
  };
}
