import { TableCell, TableRow } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { setSimpleValueBoletosRelacaoPrestacaoAction } from '../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { pesquisarDadosBoletosRelacaoPrestacaoAction } from '../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import IconAgrupamentoBlue from '../../../../src/assets/images/icons/iconAgrupamentoBlue.svg';
import OrdenacaoColuna from '../../UI/Tabela/Ordenacao/OrdenacaoColuna';

function TableHeaderBoletosRelacaoPrestacaoComponent() {
  const dispatch = useCCClienteDispatch();
  const columnOrdenacao = useCCClienteSelector(states => states.boletosRelacaoPrestacao.columnOrdenacao);
  const arrowDirection = useCCClienteSelector(states => states.boletosRelacaoPrestacao.arrowDirection);
  const dadosBoletos = useCCClienteSelector(states => states.boletosRelacaoPrestacao.dadosBoletos);

  const setColumnOrdenacao = useCallback((column) => {
    dispatch(setSimpleValueBoletosRelacaoPrestacaoAction('columnOrdenacao', column));
  }, [dispatch]);

  const setArrowDirection = useCallback((internalReturn) => {
    dispatch(setSimpleValueBoletosRelacaoPrestacaoAction('arrowDirection', internalReturn));
  }, [dispatch]);

  const onClickColumnOrdenar = useCallback(() => {
    if (dadosBoletos.length > 0) {
      dispatch(setSimpleValueBoletosRelacaoPrestacaoAction('page', 0));
      dispatch(pesquisarDadosBoletosRelacaoPrestacaoAction());
    }
  }, [dispatch, dadosBoletos]);


  return (
    <TableRow>
      <TableCell height={33} width={160}>
        <OrdenacaoColuna
          columnLabel="Data da Emissão"
          idColumn="dataEmissao"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickColumnOrdenar()}
        />
      </TableCell>
      <TableCell height={33} width={54}>
        <img src={IconAgrupamentoBlue} alt="Ícone de agrupamento" width="15px" height="14px" />
      </TableCell>
      <TableCell height={33} width={170}>
        Nosso Número
      </TableCell>
      <TableCell height={33} width={200}>
        Emitido por
      </TableCell>
      <TableCell height={33} width={160}>
        Vencimento
      </TableCell>
      <TableCell height={33} width={180}>
        Valor
      </TableCell>
      <TableCell height={33} width={170}>
        Status
      </TableCell>
      <TableCell height={33} width={220}>
        Ações
      </TableCell>
    </TableRow>
  );
}
export const TableHeaderBoletosRelacaoPrestacao = TableHeaderBoletosRelacaoPrestacaoComponent;
