import React, {
  memo, useCallback, useEffect, useState,
} from 'react';

import { Button, TextField } from '@mui/material';

import { toast } from 'react-toastify';
import Grid24 from '../../../../components/UI/Grid24';
import useStyle from '../AcordosStyle';
import { extractResponseErrorMessage, getFieldName } from '../../../../helpers';
import { dialogAlterarParcelaDinheiroController } from './dialogAlterarParcelaDinheiroController';
import { loadingInspecaoProcessoController } from '../../loadingInspecaoProcessoController';


function DialogAlterarDinheiro({
  setOpenDialogAlterarDinheiro,
  dadosParcelaDinheiro,
  idProcesso,
  nroParcela,
  idAcordo,
  idAcordoParcela,
  tipoFormPgtp,
  setLoadings,
  updatePage,
}) {
  const style = useStyle();

  const [formFields, setFormFields] = useState(dialogAlterarParcelaDinheiroController.makeFormFields(dadosParcelaDinheiro));

  const onChangeHandler = useCallback((e) => {
    const name = getFieldName(e.target);
    const { value } = e.target;
    setFormFields(oldValue => ({ ...oldValue, [name]: { ...oldValue[name], value } }));
  }, []);

  async function onClickSubmitParcela(e) {
    e.preventDefault();
    setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(true));
    try {
      await dialogAlterarParcelaDinheiroController.salvar(idProcesso,
        nroParcela,
        idAcordo,
        idAcordoParcela,
        tipoFormPgtp,
        dadosParcelaDinheiro.valor,
        formFields);
      setOpenDialogAlterarDinheiro(false);
      updatePage();
    } catch (error) {
      const erroMessage = extractResponseErrorMessage(error);
      if (erroMessage !== 'Erro não mapeado') {
        toast.error(erroMessage);
      }
    } finally {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(false));
    }
  }

  useEffect(() => {
    setFormFields(dialogAlterarParcelaDinheiroController.makeFormFields(dadosParcelaDinheiro));
  }, [dadosParcelaDinheiro]);

  return (
    <>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={24} className={style.dialogAlterarParcelaDinheiro}>
          <div style={{ display: 'grid', marginTop: '18px' }}>
            <TextField
              name="observacaoDinheiro"
              defaultValue={formFields.observacaoDinheiro.value}
              multiline
              rows={4}
              id="outlined-multiline-static"
              label="Observações"
              onChange={onChangeHandler}
            />
          </div>
        </Grid24>
      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          onClick={() => setOpenDialogAlterarDinheiro(false)}
        >
          Cancelar
        </Button>
        <Button
          onClick={onClickSubmitParcela}
        >
          Confirmar
        </Button>
      </div>

    </>
  );
}
export default memo(DialogAlterarDinheiro);
