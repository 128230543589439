export const CHECKBOX_COBRANCA_SIMPLES_LABEL = 'Cobrança Simples';
export const CHECKBOX_TELEGRAMA_DEVEDOR_LABEL = 'Permitir enviar Telegrama ao devedor';
export const CHECKBOX_TRATAR_COD_DEFESA_CONSUMIDOR_LABEL = 'Tratar o devedor de acordo com o código de defesa do consumidor';
export const CHECKBOX_AVISAR_PROCESSO_EXPIRADO_CONSUMIDOR_LABEL = 'Avisar Processo Expirado';
export const CHECKBOX_CARTA_DEVEDOR_LABEL = 'Permitir enviar Notificação Carta ao devedor';
export const CHECKBOX_NOTIFICACAO_TELEGRAMA_DEVEDOR_LABEL = 'Permitir enviar Notificação Telegrama ao devedor';
export const CHECKBOX_TITULOS_ANTIGOS_LABEL = 'Processar Títulos Antigos';
export const CHECKBOX_TITULOS_VALORES_BAIXOS_LABEL = 'Processar Títulos com valores baixos';
export const CHECKBOX_INCLUIR_PROCESSOS_PRODUCAO_BORDEROS = 'Incluir Processos na produção de Borderôs';
export const CHECKBOX_CALCULAR_JUROS_UNIUCO_LABEL = 'Calcular Juros Único';
export const CHECKBOX_PROCESSOS_COBRANCA_PRESENCIAL_LABEL = 'Permitir Cobrança Presencial';
export const CHECKBOX_PERMITIR_UNIFICAR_PROCESSO_LABEL = 'Permitir Unificação de Processos';
export const INTERVALO_ENTRE_PAGAMENTOS = 'Intervalo entre os Pagamentos preferencial para Acordos com Devedores.';
export const CHECKBOX_AJUIZAR_PROCESSOS = 'Permitir Ajuizar Processos';
export const MODELO_NOTIICACAO_EMAIL = 'Modelo de Notificação Para E-mail*';
