class LoadingInspecaoProcessoController {
  changeLoading(loadingTag, enabled) {
    return oldValue => ({ ...oldValue, [loadingTag]: enabled });
  }

  changeLoadingAction(enabled) {
    return this.changeLoading('actions', enabled);
  }

  changeLoadingStartPage(enabled) {
    return this.changeLoading('loadImmuttableInfo', enabled);
  }

  changeLoadingProcessoInfo(enabled) {
    return this.changeLoading('loadMutableInfo', enabled)
    && this.changeLoading('acordos', enabled);
  }

  changeLoadingSinalizarCores(enabled) {
    return this.changeLoading('sinalizacaoCores', enabled);
  }

  changeLoadingSinalizacaoIcones(enabled) {
    return this.changeLoading('sinalizacaoIcones', enabled);
  }

  changeLoadingAcompanhamento(enabled) {
    return this.changeLoading('acompanhamento', enabled);
  }

  changeLoadingAntecedente(enabled) {
    return this.changeLoading('antecedente', enabled);
  }

  changeLoadingTitulos(enabled) {
    return this.changeLoading('titulos', enabled);
  }

  changeLoadingPreferencia(enabled) {
    return this.changeLoading('preferencia', enabled);
  }

  changeLoadingListaPreferencias(enabled) {
    return this.changeLoading('listaPreferencias', enabled);
  }

  changeLoadingDadosDevedor(enabled) {
    return this.changeLoading('dadosDevedor', enabled);
  }

  changeLoadingDocumentos(enabled) {
    return this.changeLoading('documentos', enabled);
  }

  changeLoadingListaRecalculos(enabled) {
    return this.changeLoading('listaRecalculos', enabled);
  }

  changeLoadingAcordos(enabled) {
    return this.changeLoading('acordos', enabled);
  }

  changeLoadingFichaFinaceira(enabled) {
    return this.changeLoading('fichaFinanceira', enabled);
  }

  makeLoadings() {
    return {
      loadMutableInfo: false,
      loadImmuttableInfo: false,
      actions: false,
      sinalizacaoCores: false,
      sinalizacaoIcones: false,
      acompanhamento: false,
      resumo: false,
      antecedente: false,
      titulos: false,
      fase: false,
      acordos: false,
      preferencia: false,
      listaPreferencias: false,
      dadosDevedor: false,
      documentos: false,
      listaRecalculos: false,
      fichaFinanceira: false,
    };
  }
}

export const loadingInspecaoProcessoController = new LoadingInspecaoProcessoController();
