import React from 'react';
import AddActionTitleWrapper from '../../../../../UI/Wrapper/AddActionTitle';
import FaixaPrazoExpiracao from './FaixaPrazoExpiracao';
import useStyles from './prazoExpiracao.styles';
import { checkArrayIsRequired, getNextValueToRange } from '../../../../../../helpers/utils/utils';
import {
  buildFaixaPrazoExpiracao,
} from '../../../../../../helpers/factory/faixasPrazoExpiracao.factory';
import { EDIT_TITLE, READ_TITLE, TOOL_TIP } from './prazoExpiracao.constants';

function FaixasPrazoExpiracao({
  disabled,
  readMode,
  faixasPrazoExpiracao,
  setAttribute,
  setFaixaDePrazoExpiracao,
  setFocus,
}) {
  const styles = useStyles();

  const removeFaixaDePrazoExpiracaoHandler = (index) => {
    const allFaixasPrazoExpiracao = [...faixasPrazoExpiracao];
    allFaixasPrazoExpiracao.splice(index, 1);
    setFaixaDePrazoExpiracao(allFaixasPrazoExpiracao);
  };

  const addFaixaPrazoExpiracaoHandler = () => {
    const allFaixasPrazoExpiracao = [...faixasPrazoExpiracao];
    const newFaixaPrazoExpiracao = buildFaixaPrazoExpiracao();
    newFaixaPrazoExpiracao.valorInicial.value = getNextValueToRange(
      allFaixasPrazoExpiracao,
      'valorFinal',
      9999999999.99,
      0.01,
    );
    newFaixaPrazoExpiracao.valorFinal.value = 9999999999.99;
    newFaixaPrazoExpiracao.prazoExpiracao.value = 1;

    const isValidArray = checkArrayIsRequired(allFaixasPrazoExpiracao);
    if (isValidArray) {
      allFaixasPrazoExpiracao.push(newFaixaPrazoExpiracao);
    }

    setFaixaDePrazoExpiracao(allFaixasPrazoExpiracao);
  };

  const generateDividerLine = index => ((index !== faixasPrazoExpiracao.length - 1)
    && (<hr className={styles.divider} />)
  );

  const buildFaixasPrazoExpiracaoComponent = (faixaPrazoExpiracao, index) => (
    <div key={index}>
      <FaixaPrazoExpiracao
        setFocus={setFocus}
        setAttribute={setAttribute}
        disabled={disabled}
        index={index}
        model={faixaPrazoExpiracao}
        onRemove={() => removeFaixaDePrazoExpiracaoHandler(index)}
      />
      {generateDividerLine(index)}
    </div>
  );

  return (
    <AddActionTitleWrapper
      title={EDIT_TITLE}
      readTitle={READ_TITLE}
      disabled={disabled}
      addHandler={addFaixaPrazoExpiracaoHandler}
      tooltip={TOOL_TIP}
      readMode={readMode}
    >
      <div className={styles.wrapper}>
        {faixasPrazoExpiracao.map(buildFaixasPrazoExpiracaoComponent)}
      </div>
    </AddActionTitleWrapper>
  );
}

export default FaixasPrazoExpiracao;
