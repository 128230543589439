import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Typography, Grid } from '@material-ui/core';
import IconButtonToolTip from '../../UI/IconButtonTooltip/IconButtonTooltip';

import TableHeader from '../../../models/TableHeader';
import OrdenacaoColuna from '../../UI/Tabela/Ordenacao/OrdenacaoColuna';
import useUtilStyles from '../../../helpers/styles/utils.styles';
import Table from '../../UI/Tabela/PaginadaSimples/Table';
import { deleteTextoAction, pesquisarTextosAction } from '../../../store/TextosPadrao/textosPadrao.saga';
import { useTextosDispatch, useTextosSelector } from '../../../store/TextosPadrao/TextosPadraoProvider';
import {
  setAttributesAction,
  setPageAction,
  setRequestFieldsAction,
  setRowsPerPageAndPageAction,
} from '../../../store/TextosPadrao/textosPadrao.store';
import { getNewPage } from '../../../helpers/utils/utils';
import { removalDialogMessage } from '../textospadrao.constants';
import useStyles from './textoPadrao.styles';
import OptionDialogNew from '../../UI/Dialogs/OptionDialog/OptionDialogNew';

function TextosPadraoTable({
  columnOrdenacao, setColumnOrdenacao, arrowOrdenacao, setArrowOrdenacao, setCreateEditMode, permissao,
}) {
  const utilStyles = useUtilStyles();
  const styles = useStyles();
  const dispatch = useTextosDispatch();

  const settingsTable = useTextosSelector(states => states.settingsTable);
  const cacheRequestFields = useTextosSelector(states => states.cacheRequestFields);

  const [optinalDialogIngo, setOptionalDialogInfo] = useState({ codigoTexto: '', show: false });
  const [idsToDelete, setIdsToDelete] = useState({ idTextoPadrao: null, idTTipoextoPadrao: null });


  const [tableRows, setTableRows] = useState([]);
  const previewRowPerPage = useRef();
  const tableContentLength = useRef();
  tableContentLength.current = settingsTable.content.length;


  const onEditHandler = useCallback((content) => {
    setCreateEditMode(false);
    dispatch(setAttributesAction('showCrudDialog', true));
    dispatch(setRequestFieldsAction(content));
  }, [dispatch, setCreateEditMode]);

  const openRemoverDialogHandler = useCallback((idTexto, idTipoTexto) => {
    setIdsToDelete({ idTextoPadrao: idTexto, idTTipoextoPadrao: idTipoTexto });
    setOptionalDialogInfo({ codigoTexto: idTexto, show: true });
  }, []);

  const closeRemoverDialogHandler = () => {
    setOptionalDialogInfo(state => ({ ...state, show: false }));
  };

  const confirmRemoverDialogHandler = useCallback((idTexto, idTipoTextoPadrao) => {
    dispatch(deleteTextoAction(idTexto, idTipoTextoPadrao, columnOrdenacao));
    setOptionalDialogInfo(state => ({ ...state, show: false }));
    setIdsToDelete({ idTextoPadrao: null, idTTipoextoPadrao: null });
  }, [columnOrdenacao, dispatch]);

  const tableColumns = useMemo(() => [
    new TableHeader({
      id: 'idTextoPadrao',
      label: <OrdenacaoColuna
        columnLabel="Código"
        idColumn="ids.idTextoPadrao"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
      />,
      alignCell: 'center',
      width: 100,
    }),
    new TableHeader({
      id: 'idTipoTextoPadrao',
      label: 'Tipo',
      alignCell: 'center',
      width: 150,
    }),
    new TableHeader({
      id: 'texto',
      label: 'Textos',
      alignCell: 'flex-start',
      width: 612,
      showTip: true,
      maxWidth: 612,
    }),
    new TableHeader({
      id: 'acoes',
      label: 'Ações',
      alignCell: 'center',
      width: 100,
    }),
  ], [arrowOrdenacao, columnOrdenacao, setArrowOrdenacao, setColumnOrdenacao]);

  const buildTableTextos = useCallback(content => content.map(textos => ({
    id: textos.idTipo,
    values: {
      idTextoPadrao: textos.idTextoPadrao,
      idTipoTextoPadrao: textos.descricaoTipoTextoPadrao,
      texto: textos.texto,
      acoes: (
        <div style={{}}>
          <IconButtonToolTip
            type="edit"
            hint="Editar"
            clickArea="0"
            onClick={() => onEditHandler(textos)}
            className={styles.root}
            disabled={!permissao.gerenciarTextoPadrao}
          />
          <IconButtonToolTip
            type="trash"
            hint="Excluir"
            clickArea="0"
            onClick={() => openRemoverDialogHandler(textos.idTextoPadrao, textos.idTipoTextoPadrao)}
            disabled={!permissao.gerenciarTextoPadrao}
          />
        </div>
      ),
    },
  })), [onEditHandler, openRemoverDialogHandler, styles.root, permissao.gerenciarTextoPadrao]);

  useEffect(() => {
    const isChangePageWithourTableContent = () => previewRowPerPage.current
      && previewRowPerPage.current !== settingsTable.rowsPerPage
      && tableContentLength.current === 0;

    if (isChangePageWithourTableContent()) {
      previewRowPerPage.current = settingsTable.rowsPerPage;
      return;
    }

    if (cacheRequestFields) {
      const ordenacao = [`${columnOrdenacao.element},${columnOrdenacao.order}`];
      dispatch(pesquisarTextosAction(settingsTable.page, settingsTable.rowsPerPage, ordenacao));
    }
    previewRowPerPage.current = settingsTable.rowsPerPage;
  }, [dispatch, cacheRequestFields, settingsTable.page, settingsTable.rowsPerPage, columnOrdenacao]);

  useEffect(() => {
    setTableRows(buildTableTextos(
      settingsTable.content,
    ));
  }, [settingsTable.content, setTableRows, buildTableTextos]);

  const onChangePageHandler = useCallback((event, newPage) => {
    dispatch(setPageAction(newPage));
  }, [dispatch]);

  const onChangeRowsPerPageHandler = useCallback((event) => {
    const newRowsPerPage = event.target.value;
    const newPage = getNewPage(settingsTable.rowsPerPage, settingsTable.page, newRowsPerPage);
    dispatch(setRowsPerPageAndPageAction(newRowsPerPage, newPage));
  }, [dispatch, settingsTable.page, settingsTable.rowsPerPage]);


  return (
    <div>
      <OptionDialogNew
        open={optinalDialogIngo.show}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={closeRemoverDialogHandler}
        onClickConfirm={() => confirmRemoverDialogHandler(idsToDelete.idTextoPadrao, idsToDelete.idTTipoextoPadrao)}
      >
        <div style={{ fontSize: '16px' }}>
          {removalDialogMessage(optinalDialogIngo.codigoTexto)}
        </div>
      </OptionDialogNew>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Typography component="h3" className={utilStyles.tableTitle}>
            Resultado da Pesquisa
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Table
            rows={tableRows}
            columns={tableColumns}
            page={settingsTable.page}
            size={settingsTable.size}
            rowsPerPage={settingsTable.rowsPerPage}
            onChangePage={onChangePageHandler}
            onChangeRowsPerPage={onChangeRowsPerPageHandler}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default TextosPadraoTable;
