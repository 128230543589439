import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import useStyles from './historicoAS.styles';
import WrapperContent from '../../UI/WrapperContent/WrapperContent';
import HistoricoPesquisaLayoutAS from './PesquisaLayout/HistoricoPesquisaLayoutAS';
import Table from '../../UI/Tabela/PaginadaSimples/Table';
import TableHeader from '../../../models/TableHeader';
import useUtilStyles from '../../../helpers/styles/utils.styles';
import DataExpiracaoHistorico from './DataExpiracao/DataExpiracaoHistorico';
import LoadingUI from '../../UI/Loading/LoadingUI';
import {
  getEstatisticasHistoricoAction,
  pesquisarDadosAlterarPrazoAction,
  pesquisarHistoricoASAction,
} from '../../../store/AcordoSeguro/Historico/historicoAS.saga';
import {
  getArrowOrdenacao, getDateTimeMask, getMoneyMask, getNewPage,
} from '../../../helpers/utils/utils';
import {
  onChangeRequestFieldsAction,
  setPageAction,
  setRowsPerPageAndPageAction,
  setSnackbarHistoricoASAction,
  setStoreValuesAction,
} from '../../../store/AcordoSeguro/Historico/historicoAS.store';

import { SNACKBAR_DURATION } from '../../../helpers/constants/global.constants';
import SnackbarUI from '../../UI/Snackbar/Snackbar';
import HistoricoEstatisca from './Estatisca/Estatisca';
import IconButtonTooltip from '../../UI/IconButtonTooltip/IconButtonTooltip';


function HistoricoAS() {
  const dispatch = useDispatch();
  const styles = useStyles();
  const utilStyles = useUtilStyles();
  const history = useHistory();
  const location = useLocation();


  const [tableRows, setTableRows] = useState([]);
  const [order, setOrder] = useState('N1');
  const [elementId, setElementId] = useState('numero');
  const [arrowOrdenacao, setArrowOrdenacao] = useState(false);

  const loading = useSelector(states => states.historicoAS.loading);
  const settingsTable = useSelector(states => states.historicoAS.settingsTable);
  const cacheRequestFields = useSelector(states => states.historicoAS.cacheRequestFields);
  const snackbarHistoricoAS = useSelector(states => states.historicoAS.snackbarHistoricoAS);
  const openPesquisaAvancada = useSelector(states => states.historicoAS.openPesquisaAvancada);


  const previewRowPerPage = useRef();
  const tableContentLength = useRef();
  tableContentLength.current = settingsTable.content.length;


  const redirecionarPagina = useCallback((nroProcesso) => {
    const locationRef = {
      pathname: `/processos/${nroProcesso}/inspecionar`,
      state: { title: 'Histórico > Inspeção' },
    };
    return () => history.push(locationRef);
  }, [history]);

  const getDefaultOrdenation = useCallback(() => {
    setOrder('N1');
    setElementId('numero');
    setArrowOrdenacao(false);
  }, [setOrder, setElementId, setArrowOrdenacao]);

  // Quando clicar na opção do menu.
  useEffect(() => {
    getDefaultOrdenation();
    dispatch(getEstatisticasHistoricoAction());
  }, [dispatch, location.refreshPage, getDefaultOrdenation]);

  const onClickAlterarDataExpiracaoHandler = useCallback((nroProcesso) => {
    dispatch(setStoreValuesAction('openDialogDataExpiracao', true));
    dispatch(pesquisarDadosAlterarPrazoAction(nroProcesso));
  }, [dispatch]);

  const onCloseAlterarDataExpiracaoHandler = useCallback(() => {
    dispatch(setStoreValuesAction('openDialogDataExpiracao', false));
  }, [dispatch]);

  // Pesquisa o histórico.
  useEffect(() => {
    const isChangePageWithourTableContent = () => previewRowPerPage.current
      && previewRowPerPage.current !== settingsTable.rowsPerPage
      && tableContentLength.current === 0;

    if (isChangePageWithourTableContent()) {
      previewRowPerPage.current = settingsTable.rowsPerPage;
      return;
    }

    if (cacheRequestFields) {
      dispatch(pesquisarHistoricoASAction(settingsTable.page, settingsTable.rowsPerPage, order));
    }
    previewRowPerPage.current = settingsTable.rowsPerPage;
  }, [dispatch, cacheRequestFields, settingsTable.page, settingsTable.rowsPerPage, order]);

  const onClickOrdernarColumnHandler = useCallback((id, ordenacao) => {
    setElementId(id);
    setArrowOrdenacao(old => !old);

    if (arrowOrdenacao) {
      setOrder(ordenacao);
    } else {
      setOrder(ordenacao[0] + 2);
    }
  }, [setElementId, setArrowOrdenacao, setOrder, arrowOrdenacao]);

  const buildHeaderOrdenacao = useCallback((label, id, ordenacao) => (
    <div
      className={utilStyles.div__header__ordenacao}
      onClick={() => onClickOrdernarColumnHandler(id, ordenacao)}
    >
      <div>
        {label}
      </div>
      <div className={utilStyles.icon__ordenacao}>
        {getArrowOrdenacao(elementId, id, arrowOrdenacao)}
      </div>
    </div>
  ), [onClickOrdernarColumnHandler, elementId, arrowOrdenacao, utilStyles]);

  const tableColumns = useMemo(() => [
    new TableHeader({
      id: 'numero', label: buildHeaderOrdenacao('Número', 'numero', 'N1'), alignCell: 'center', width: 102,
    }),
    new TableHeader({
      id: 'dataAbertura',
      label: buildHeaderOrdenacao('Data de Abertura', 'dataAbertura', 'D1'),
      alignCell: 'center',
      width: 153,
    }),
    new TableHeader({
      id: 'nomeDevedor',
      label: buildHeaderOrdenacao('Nome do Devedor', 'nomeDevedor', 'ND1'),
      alignCell: 'left',
      showTip: true,
      minWidth: 'auto',
      width: 150,
    }),
    new TableHeader({
      id: 'estagioProcesso',
      label: buildHeaderOrdenacao('Estágio do Processo', 'estagioProcesso', 'E1'),
      alignCell: 'center',
      width: 204,
    }),
    new TableHeader({
      id: 'valorProcesso',
      label: buildHeaderOrdenacao('Valor do Processo', 'valorProcesso', 'V1'),
      alignCell: 'flex-end',
      minWidth: 'auto',
      width: 160,
    }),
    new TableHeader({
      id: 'acoes', label: 'Ações', alignCell: 'center', width: 102, isAction: true,
    }),
  ], [buildHeaderOrdenacao]);

  // Build das colunas.
  const buildHistoricoTable = useCallback(content => content.map(processo => ({
    id: processo.nroProcesso,
    values: {
      numero: processo.nroProcesso,
      dataAbertura: getDateTimeMask(processo.dataAbertura, true),
      valorProcesso: getMoneyMask(processo.valorProcesso),
      nomeDevedor: processo.nomeDevedor,
      estagioProcesso: processo.estagio,
      acoes: (
        <div className={styles.div__icons}>
          <IconButtonTooltip
            type="calendarAlt"
            hint="Alterar a Data de Expiração"
            disabled={!processo.permitirAlterarData}
            clickArea="0"
            onClick={() => onClickAlterarDataExpiracaoHandler(processo.nroProcesso)}
          />

          <IconButtonTooltip
            type="searchPlus"
            hint="Inspecionar"
            clickArea="0"
            onClick={redirecionarPagina(processo.nroProcesso)}
          />
        </div>
      ),
    },
  })), [styles, onClickAlterarDataExpiracaoHandler, redirecionarPagina]);

  // Construção da tabela
  useEffect(() => {
    setTableRows(buildHistoricoTable(
      settingsTable.content,
    ));
  }, [settingsTable, setTableRows, buildHistoricoTable]);

  const onClickPesquisaAvancadaHandler = useCallback(() => {
    dispatch(setStoreValuesAction('openPesquisaAvancada', !openPesquisaAvancada));
    dispatch(onChangeRequestFieldsAction('nomeDevedor', ''));
    dispatch(onChangeRequestFieldsAction('nroProcesso', ''));
  }, [openPesquisaAvancada, dispatch]);


  const onChangePageHandler = useCallback((event, newPage) => {
    dispatch(setPageAction(newPage));
  }, [dispatch]);

  const onChangeRowsPerPageHandler = useCallback((event) => {
    const newRowsPerPage = event.target.value;
    const newPage = getNewPage(settingsTable.rowsPerPage, settingsTable.page, newRowsPerPage);
    dispatch(setRowsPerPageAndPageAction(newRowsPerPage, newPage));
  }, [dispatch, settingsTable.page, settingsTable.rowsPerPage]);

  const onCloseSnackbarHandler = useCallback(() => {
    dispatch(setSnackbarHistoricoASAction({ open: false }));
  }, [dispatch]);

  return (
    <WrapperContent>
      <LoadingUI show={loading} />
      <SnackbarUI
        open={snackbarHistoricoAS.open}
        autoHideDuration={SNACKBAR_DURATION}
        variant={snackbarHistoricoAS.variant}
        message={snackbarHistoricoAS.message}
        onClose={onCloseSnackbarHandler}
      />
      <DataExpiracaoHistorico onCloseHandler={onCloseAlterarDataExpiracaoHandler} />
      <HistoricoEstatisca />
      <HistoricoPesquisaLayoutAS
        openPesquisaAvancada={openPesquisaAvancada}
        onClickPesquisaAvancadaHandler={onClickPesquisaAvancadaHandler}
      />
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Typography component="h3" className={utilStyles.tableTitle}>
            Resultado da Pesquisa
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Table
            rows={tableRows}
            columns={tableColumns}
            page={settingsTable.page}
            size={settingsTable.size}
            rowsPerPage={settingsTable.rowsPerPage}
            onChangePage={onChangePageHandler}
            onChangeRowsPerPage={onChangeRowsPerPageHandler}
          />
        </Grid>
      </Grid>
    </WrapperContent>
  );
}

export default withRouter(HistoricoAS);
