import { RequiredFieldValidator } from './validatiors/RequiredFieldValidator';

export class ValidationBuilder {
  constructor(fieldName, fieldValidators = []) {
    this.fieldName = fieldName;
    this.validators = fieldValidators;
  }

  static field(fieldName) {
    return new ValidationBuilder(fieldName, []);
  }

  required() {
    this.validators.push(new RequiredFieldValidator(this.fieldName));
    return this;
  }

  build() {
    return this.validators;
  }
}
