import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import _ from 'lodash';
import {
  getFieldName, getRequiredLabel, makeErrorField, DateUtils,
} from '../../../../../../helpers';
import { CampoObrigatorio, SelectorField } from '../../../../../../components';
import Grid24 from '../../../../../../components/UI/Grid24';
import { dialogNovaAlterarParcelaController } from './DialogNovaAlterarParcelaController';
import DateField from '../../../../../../components/UI/Field/Date/DateField';
import MoneyField from '../../../../../../components/UI/Field/Money/MoneyField';
import { loadingInspecaoProcessoController } from '../../../../loadingInspecaoProcessoController';
import CheckboxField from '../../../../../../components/UI/Field/Checkbox/CheckboxField';
import DetalheCheque from './DetalheCheque/DetalheCheque';


function DialogNovaAlterarParcela({
  setopenDialogNovaAlterarParcela,
  setParcelas,
  reOrderParcelas,
  reNumberParcelas,
  arrowDirectionVencimento,
  clickedParcelaOnEdit,
  setLoadings,
  parcelas,
  parametrosInternosAbe,
  dadosPadraoForm,
  dadosResumo,
  dadosFormFields,
  dataFinalPasso3,
  formaPagamentoPasso2,
}) {
  const formasPagamentoAcordo = useSelector(states => states.selectors.tiposFormaPagamento.selector);

  const [formFields, setFormFields] = useState(
    dialogNovaAlterarParcelaController.makeFormFieldsNovaAlterarParcela(clickedParcelaOnEdit),
  );

  const [chequesDetalhes, setChequesDetalhes] = useState([...formFields.chequeDetalhe]);
  const [originalChequesDetalhes, setOriginalChequesDetalhes] = useState([]);


  const [errorFields, setErrorFields] = useState(dialogNovaAlterarParcelaController.makeErrorFields());

  const onChangeHandler = useCallback((e) => {
    const name = getFieldName(e.target);
    const { value } = e.target;
    setFormFields(oldValue => ({ ...oldValue, [name]: value }));
  }, []);

  const onFocusHandler = useCallback((e) => {
    const name = getFieldName(e.target);
    setErrorFields(oldValues => ({ ...oldValues, [name]: makeErrorField() }));
  }, []);

  const onCheckedHandler = useCallback((e) => {
    const { name, checked } = e.target;
    setFormFields(oldValues => ({ ...oldValues, [name]: checked }));
  }, []);

  const onClose = useCallback(() => {
    setChequesDetalhes([...originalChequesDetalhes.slice()]);
    setopenDialogNovaAlterarParcela(false);
  }, [setopenDialogNovaAlterarParcela, originalChequesDetalhes, setChequesDetalhes]);

  function getFirstParcelaDate() {
    return DateUtils.getISODateFromText(DateUtils.formatTextDate(dadosPadraoForm.dataPrimeiroVencimento));
  }

  function getLastParcelaDate() {
    return DateUtils.getISODateFromText(dataFinalPasso3);
  }

  function getDinheiroMaxParcelaDate() {
    const date = DateUtils.getISODateFromText(DateUtils.formatTextDate(dadosPadraoForm.dataPrimeiroVencimento));
    date.setDate(date.getDate() + parametrosInternosAbe.prazoMaximoPrimeiroVencimento);
    return date;
  }

  function getMinDate() {
    if (formFields.nParcela === 1) {
      return getFirstParcelaDate();
    }
    const date = getFirstParcelaDate();
    date.setDate(date.getDate() + 1);
    return date;
  }

  function getMaxDate() {
    if (formFields.nParcela === 1) {
      return getDinheiroMaxParcelaDate();
    }
    return getLastParcelaDate();
  }

  const applyValueToNextIndices = (value = ({}), startIndex) => {
    setParcelas((prevArray) => {
      for (let i = startIndex - 1; i < prevArray.length; i++) {
        prevArray[i] = value[i] || prevArray[i];
      }

      return prevArray;
    });
  };


  function calculaValoresSomasValoresAnteriores(qtdeParcelasAnteriores) {
    let valoresSomasValoresAnteriores = 0;
    for (let i = 0; i < qtdeParcelasAnteriores; i++) {
      valoresSomasValoresAnteriores += parcelas[i].valor;
    }
    return valoresSomasValoresAnteriores;
  }

  function redistribuirParcelas() {
    if (formFields.redistribuirValores === false) {
      setopenDialogNovaAlterarParcela(false);
    }

    if (formFields.redistribuirValores === true) {
      // Somatória do valor de todas as parcelas anteriores à parcela alterada
      const valoresSomasValoresAnteriores = calculaValoresSomasValoresAnteriores(formFields.nParcela - 1);

      // Valor total que sobra para redistrubuir
      let valorTotalRestante = parseFloat(dadosResumo.valorTotal.toFixed(2)) - parseFloat(valoresSomasValoresAnteriores.toFixed(2)) - parseFloat(formFields.valor).toFixed(2);

      // Valor redistribuido de cada parcela posterior.
      const valorIndividualRestante = parseFloat(valorTotalRestante.toFixed(2)) / (dadosFormFields.quantidadeParcelas - formFields.nParcela);

      // Loop nas parcelas
      const parcelasAtualizado = parcelas.map((oldParcela, index) => {
        const indexParcelaAlterada = formFields.nParcela - 1;

        if (indexParcelaAlterada === index) { // Se a parcela atual for a parcela alterada
          // Valor da parcela recebe o valor de entrada do form.
          oldParcela.valor = parseFloat(formFields.valor).toFixed(2);
          oldParcela.formaPagamento = formFields?.formaPagamento;
          oldParcela.redistribuirValores = formFields?.redistribuirValores;
          oldParcela.vencimento = formFields?.dataVencimento;
          oldParcela.chequeDetalhe = chequesDetalhes;

          // Se a parcela atual for posterior à parcela alterada e não é a ultima parcela.
        } else if (indexParcelaAlterada < index && index !== parcelas.length - 1) {
          // Atribui o valor individual calculado e aredondado.
          oldParcela.valor = valorIndividualRestante.toFixed(2);
          // Remove o valor distribuido no laço do valor que falta ser distribuido
          valorTotalRestante -= oldParcela.valor;
        } else if (index === parcelas.length - 1) { // Se for a ultima parcela
          // Atribui o valor que falta ser distribuido
          oldParcela.valor = valorTotalRestante.toFixed(2);
        }

        oldParcela.valor = parseFloat(oldParcela.valor) < 0 ? parseFloat('0') : parseFloat(oldParcela.valor);

        return oldParcela;
      });

      applyValueToNextIndices(parcelasAtualizado, (formFields.nParcela));
    }
  }

  async function onSubmitAlterarParcela(e) {
    e.preventDefault();

    formFields.chequeDetalhe = chequesDetalhes;
    setErrorFields(dialogNovaAlterarParcelaController.setErrorsIfNeededOnSubmit(formFields, getMaxDate, getMinDate));
    dialogNovaAlterarParcelaController.setErrorsDetalhesDoChequeIfNeededOnSubmit(formFields.chequeDetalhe);
    if (!dialogNovaAlterarParcelaController.checkForErrorsOnSubmit(dialogNovaAlterarParcelaController.setErrorsIfNeededOnSubmit(formFields, getMaxDate, getMinDate))
      && !dialogNovaAlterarParcelaController.checkForErrorsDetalhesOnSubmit(formFields.chequeDetalhe)) {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(true));
      // Checar se data é correta
      try {
        formFields.dataVencimento = DateUtils.formatTextDate(await dialogNovaAlterarParcelaController.checkDate(formFields?.dataVencimento));
      } catch (err) {
        // sem tratamento
      } finally {
        setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(false));
      }

      if (clickedParcelaOnEdit.nParcela !== parcelas.length) {
        redistribuirParcelas();
      }

      setParcelas((oldParcelas) => {
        const newParcelas = oldParcelas.map((oldParcela) => {
          if (oldParcela.nParcela === clickedParcelaOnEdit.nParcela) {
            oldParcela.formaPagamento = formFields?.formaPagamento;
            oldParcela.valor = parseFloat(formFields?.valor);
            oldParcela.vencimento = formFields?.dataVencimento;
            oldParcela.redistribuirValores = formFields?.redistribuirValores;
            oldParcela.chequeDetalhe = formFields?.chequeDetalhe;
          }
          return oldParcela;
        });
        const reOrderedParcelas = reOrderParcelas(newParcelas, arrowDirectionVencimento);
        const reNumberedParcelas = reNumberParcelas(reOrderedParcelas, arrowDirectionVencimento);
        return reNumberedParcelas;
      });
      if (formFields.chequeDetalhe.length === 0 && formFields.formaPagamento === 6) {
        toast.warn('Pelo menos um cheque deverá ser cadastrado');
      } else {
        setopenDialogNovaAlterarParcela(false);
      }
    }
  }

  const getFormasPagamento = useCallback(() => {
    switch (clickedParcelaOnEdit.formaPagamento) {
      case 0:
        return formasPagamentoAcordo.filter(formaPgto => formaPgto.id !== 2);
      case 1:
        return formasPagamentoAcordo.filter(formaPgto => formaPgto.id !== 2);
      case 2:
        return formasPagamentoAcordo.filter(formaPgto => formaPgto.id === 2);
      case 5:
        if (formaPagamentoPasso2 === 5) {
          return formasPagamentoAcordo.filter(formaPgto => formaPgto.id === 5);
        }
        return formasPagamentoAcordo.filter(formaPgto => formaPgto.id !== 2);
      case 6:
        return formasPagamentoAcordo.filter(formaPgto => formaPgto.id !== 2);
      default:
        return formasPagamentoAcordo;
    }
  }, [formasPagamentoAcordo, clickedParcelaOnEdit.formaPagamento, formaPagamentoPasso2]);

  useEffect(() => {
    // Armazena uma cópia profunda dos detalhes originais dos cheques
    setOriginalChequesDetalhes(_.cloneDeep(chequesDetalhes));
  }, [chequesDetalhes]);

  return (
    <>
      <div>
        {errorFields?.dataVencimento.error === true || errorFields?.valor.error === true || errorFields?.formaPagamento.error === true ? (
          <div>
            <CampoObrigatorio />
          </div>
        ) : (
          ''
        )}
      </div>
      <Grid24 container isFormContainer spacing="1">
        {formFields?.formaPagamento !== 6 ? (
          // Forma de pagamento da parcela diferente de cheque
          <>
            <Grid24 xs={24} sm={5}>
              <SelectorField
                isForm
                fullWidth
                label={getRequiredLabel('Forma de Pagamento', true)}
                items={getFormasPagamento()}
                name="formaPagamento"
                value={formFields?.formaPagamento}
                disabled={parcelas.length > 1 && formFields.nParcela !== 1}
                onChange={onChangeHandler}
                onFocus={onFocusHandler}
                error={errorFields?.formaPagamento?.error}
                errorMessage={errorFields?.formaPagamento?.errorMessage}
              />
            </Grid24>
            <Grid24 xs={24} sm={5}>
              <DateField
                isForm
                fullWidth
                label={getRequiredLabel('Vencimento', true)}
                name="dataVencimento"
                minDate={getMinDate()}
                maxDate={getMaxDate()}
                value={formFields?.dataVencimento}
                error={errorFields?.dataVencimento.error}
                errorMessage={errorFields?.dataVencimento.errorMessage}
                onFocus={onFocusHandler}
                onChange={onChangeHandler}
                disabled={formFields.formaPagamento === 2
                  || (clickedParcelaOnEdit.nParcela === 1 && (formFields.formaPagamento === 0 || formFields.formaPagamento === 1
                    || formFields.formaPagamento === 6 || formFields.formaPagamento === 5))}
              />
            </Grid24>
            <Grid24 xs={24} sm={5}>
              <MoneyField
                isForm
                fullWidth
                label={getRequiredLabel('Valor', true)}
                name="valor"
                value={formFields?.valor}
                error={errorFields.valor.error}
                errorMessage={errorFields.valor.errorMessage}
                onFocus={onFocusHandler}
                onChange={onChangeHandler}
              />
            </Grid24>
            {clickedParcelaOnEdit.nParcela === parcelas.length ? (
              <Grid24 xs={24} sm={24} style={{ marginTop: '24px', marginLeft: '10px' }}>
                <CheckboxField
                  label="Redistribuir os valores para as parcelas seguintes"
                  name="redistribuirValores"
                  checked={false}
                  disabled
                />
              </Grid24>
            ) : (
              <Grid24 xs={24} sm={24} style={{ marginTop: '24px', marginLeft: '10px' }}>
                <CheckboxField
                  label="Redistribuir os valores para as parcelas seguintes"
                  name="redistribuirValores"
                  checked={formFields.redistribuirValores}
                  onChange={onCheckedHandler}
                />
              </Grid24>
            )}
          </>
        ) : ( // Forma de pagamento da parcela é cheque
          <Grid24 container isFormContainer>
            <DetalheCheque
              errorFields={errorFields}
              getFormasPagamento={getFormasPagamento}
              formFields={formFields}
              parcelas={parcelas}
              onChangeHandler={onChangeHandler}
              onFocusHandler={onFocusHandler}
              getMinDate={getMinDate}
              getMaxDate={getMaxDate}
              clickedParcelaOnEdit={clickedParcelaOnEdit}
              onCheckedHandler={onCheckedHandler}
              chequesDetalhes={chequesDetalhes}
              setChequesDetalhes={setChequesDetalhes}
              originalChequesDetalhes={originalChequesDetalhes}
            />
          </Grid24>
        )}

      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '22px' }}>
        <Button
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          onClick={onSubmitAlterarParcela}
        >
          Salvar
        </Button>
      </div>
    </>
  );
}


export default DialogNovaAlterarParcela;
