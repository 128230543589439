import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  info: {
    fontSize: '12px',
    marginTop: '7px',
  },
  bold: {
    fontWeight: 'bold',
    color: 'black',
  },
  displayFlex: {
    display: 'flex',
  },
  dFlexMt7: {
    display: 'flex',
    marginTop: '7px',
  },
  diferenciadoContainer: {
    position: 'relative',
    float: 'right',
    marginTop: '18px',
  },
  processamentoDiferenciado: {
    paddingLeft: '40px',
  },
  processamentoDiferenciado_checkBox: {
    paddingTop: '8px',
  },
  diferenciadoText: {
    display: 'inline', marginRight: '8px', fontWeight: 'bold',
  },
  cancelIcon: {
    '&:hover': {
      backgroundColor: '#fbd0d2',
      color: '#ff000f',
    },
  },
  confirmarIcon: {
    '&:hover': {
      backgroundColor: '#caf1cc',
      color: '#43a047',
    },
  },
});
