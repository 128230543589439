import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '23px',
    '& button': {
      width: '100px',
      height: '35px',
      backgroundColor: '#4caf50',
      backgroundImage: 'none',
      '&:hover': {
        backgroundColor: '#4caf50AA',
      },
    },
  },
});
