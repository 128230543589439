import { CORE, gateway, RELATORIOS } from '../../api';

export function buscarDadosDevedor(idProcesso) {
  return gateway.get(`${CORE}/processos/${idProcesso}/devedor`);
}

export function atualizarDadosDevedor(idProcesso, payload) {
  return gateway.put(`${CORE}/processos/${idProcesso}/devedor`, payload);
}

export function constultarDadosComplementaresDevedorWebserviceExterno(cpfCnpj) {
  return gateway.get(`${RELATORIOS}/processos/devedor/${cpfCnpj}/webservice`, {
    responseType: 'blob',
  });
}
