import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { FaPlus } from 'react-icons/fa';
import { Box, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import TableHeader from '../../../models/TableHeader';
import { buildSettingsTable } from '../../../helpers/factory/global.factory';
import { getDateTimeMask, getMoneyMask, getRequiredLabel } from '../../../helpers/utils/utils';
import OrdenacaoColuna from '../../../components/UI/Tabela/Ordenacao/OrdenacaoColuna';
import { loadingInspecaoProcessoController as loadingController, loadingInspecaoProcessoController } from '../loadingInspecaoProcessoController';
import TabelaPesquisaTitulos from './Tabela/TabelaPesquisaTitulos';
import useStyle, { ButtonNovo, HeaderTitulos } from './Titulos.styles';
import { extractResponseErrorMessage, toastUnmappedException } from '../../../helpers';
import DialogTitulo from './DialogTitulo/DialogTitulo';
import {
  INSPECIONAR, NOVO_TITULO, TITULO_EDITAR,
} from './Titulos.constants';
import { deleteTituloProcessoService, inspecionarTitulosProcessoService } from '../../../services/core/processos/processosTitulosService';
import OptionDialogNew from '../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';
import DialogNew from '../../../layouts/FormDialog/DialogNew';
import { SelectorField } from '../../../components';
import Grid24 from '../../../components/UI/Grid24';
import { findMotivosExclusaoTituloAction } from '../../../store/Global/LoadSelectors/loadSelectors.saga';


function TitulosProcessoComponent({
  processo, setLoadings, idFaseProcesso, sinalizacaoCoresQuery, updatePage, immutableSelectors, additionalInfos, fasesIds,
}) {
  const userData = JSON.parse(localStorage.getItem('userData'));
  let disabledGerenciarTitulos = true;
  if (userData) {
    disabledGerenciarTitulos = userData?.permissoes?.search('ROLE_1202') === -1;
  }

  const dispatch = useDispatch();


  const [settingsTable, setSettingsTable] = useState(buildSettingsTable([], 0, 10, 0));
  const [tableRows, setTableRows] = useState([]);
  const [arrowOrdenacao, setArrowOrdenacao] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [titulo, setTitulo] = useState(null);
  const [openButtonNovo, setOpenButtonNovo] = useState(false);
  const [routesName, setRouteNames] = useState([INSPECIONAR]);

  // Dialog Exclusão

  const itemsSelectorMotivoExclusao = useSelector(states => states.selectors.motivosExclusaoTitulo.selector);

  const [motivoExclusao, setMotivoExclusao] = useState(null);

  const style = useStyle();

  const [columnOrdenacao, setColumnOrdenacao] = useState({
    element: 'dataVencimento',
    order: 'ASC',
  });

  const configureStatesPesquisa = useCallback((data) => {
    setSettingsTable({
      content: data.content,
      page: data.number,
      rowsPerPage: data.size,
      size: data.totalElements,
    });
  }, []);

  const pesquisarTitulos = useCallback((page, rowsPerPage, ordenacao) => {
    setLoadings(loadingController.changeLoadingTitulos(true));
    inspecionarTitulosProcessoService(processo.id, page, rowsPerPage, [`${ordenacao.element},${ordenacao.order}`]).then((response) => {
      const { data } = response;
      configureStatesPesquisa(data);
    }).catch(() => {
      setSettingsTable(buildSettingsTable([[], 0, 10, 0]));
    }).finally(() => {
      setLoadings(loadingController.changeLoadingTitulos(false));
    });
  }, [configureStatesPesquisa, processo.id, setLoadings]);

  const onChangePageHandler = useCallback((event, newPage) => {
    pesquisarTitulos(newPage, settingsTable.rowsPerPage, columnOrdenacao);
    setSettingsTable(old => ({ ...old, page: newPage }));
  }, [pesquisarTitulos, settingsTable.rowsPerPage, columnOrdenacao]);

  const onChangeRowsPerPageHandler = useCallback(async (event) => {
    pesquisarTitulos(settingsTable.page, event.target.value, columnOrdenacao);
    setSettingsTable(old => ({ ...old, rowsPerPage: event.target.value }));
  }, [pesquisarTitulos, columnOrdenacao, settingsTable.page]);

  const onClickOrdernacaoColumn = useCallback(async (columnName) => {
    await pesquisarTitulos(settingsTable.page, settingsTable.rowsPerPage, { element: columnName, order: arrowOrdenacao ? 'ASC' : 'DESC' });
  }, [pesquisarTitulos, settingsTable.page, settingsTable.rowsPerPage, arrowOrdenacao]);

  const cannotEnabled = fasesIds?.idFaseAcordoSeguro === Number(idFaseProcesso) || fasesIds?.idFaseBaixa === Number(idFaseProcesso) || sinalizacaoCoresQuery.cobrancaJudicial === true;

  function closeDeleteDialogHandler() {
    setTitulo(null);
    setMotivoExclusao(null);
    setShowDeleteDialog(oldValue => !oldValue);
  }

  function onClickDeleteDialogHandler(tituloProcesso) {
    return () => {
      setTitulo(tituloProcesso);
      setShowDeleteDialog(oldValue => !oldValue);
    };
  }

  async function handleDeleteTitulo() {
    setLoadings(loadingInspecaoProcessoController.changeLoadingTitulos(true));
    try {
      await deleteTituloProcessoService(processo.id, titulo.idTitulo, motivoExclusao).then(() => {
        updatePage();
      });
    } catch (e) {
      const erroMessage = extractResponseErrorMessage(e);
      if (erroMessage !== 'Erro não mapeado') {
        toastUnmappedException(erroMessage);
      } else {
        toastUnmappedException(e, 'Ocorreu um problema ao tentar excluir o título');
      }
    } finally {
      closeDeleteDialogHandler();
      setLoadings(loadingInspecaoProcessoController.changeLoadingTitulos(false));
    }
  }

  const onClickButtonNovo = useCallback(() => {
    setOpenButtonNovo(oldValue => !oldValue);
    setRouteNames([NOVO_TITULO]);
  }, []);

  const onClose = useCallback(() => {
    setTitulo(null);
    setOpenButtonNovo(oldValue => !oldValue);
  }, []);

  function onEditTituloHandler(tituloProcesso) {
    return () => {
      setRouteNames([TITULO_EDITAR]);
      setTitulo(tituloProcesso);
      setOpenButtonNovo(oldValue => !oldValue);
    };
  }

  const tableColumns = [
    new TableHeader({
      id: 'sequencia',
      label: <OrdenacaoColuna
        columnLabel="Sequência"
        idColumn="sequencia"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('sequencia')}
      />,
      alignCell: 'center',
      width: 46,
      maxWidth: 46,
    }),
    new TableHeader({
      id: 'descricaoStatusTitulo',
      label: <OrdenacaoColuna
        columnLabel="Status"
        idColumn="descricaoStatusTitulo"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('descricaoStatusTitulo')}
      />,
      alignCell: 'center',
      width: 46,
      maxWidth: 46,
    }),
    new TableHeader({
      id: 'descricaoTipoTitulo',
      label: <OrdenacaoColuna
        columnLabel="Tipo"
        idColumn="descricaoTipoTitulo"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('descricaoTipoTitulo')}
      />,
      width: 90,
      maxWidth: 90,
      alignCell: 'center',
    }),
    new TableHeader({
      id: 'numero',
      label: <OrdenacaoColuna
        columnLabel="Número"
        idColumn="numero"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('numero')}
      />,
      width: 58,
      maxWidth: 58,
      alignCell: 'center',
    }),
    new TableHeader({
      id: 'dataVencimento',
      label: <OrdenacaoColuna
        columnLabel="Vencimento"
        idColumn="dataVencimento"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('dataVencimento')}
      />,
      width: 94,
      maxWidth: 94,
      alignCell: 'center',
    }),
    new TableHeader({
      id: 'atraso',
      label: <OrdenacaoColuna
        columnLabel="Atraso"
        idColumn="atraso"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('atraso')}
      />,
      width: 63,
      maxWidth: 63,
      alignCell: 'center',
    }),
    new TableHeader({
      id: 'valorOriginal',
      label: <OrdenacaoColuna
        columnLabel="Valor Original"
        idColumn="valorOriginal"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('valorOriginal')}
      />,
      width: 104,
      maxWidth: 104,
      alignCell: 'flex-end',
    }),
    new TableHeader({
      id: 'valorSaldoPrincipal',
      label: <OrdenacaoColuna
        columnLabel="Saldo do Original"
        idColumn="valorSaldoPrincipal"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('valorSaldoPrincipal')}
      />,
      width: 104,
      maxWidth: 104,
      alignCell: 'flex-end',
    }),
  ];

  useEffect(() => {
    inspecionarTitulosProcessoService(processo.id, 0, 10, ['dataVencimento,ASC']).then((response) => {
      const { data } = response;
      configureStatesPesquisa(data);
    }).catch(() => {
      setSettingsTable(buildSettingsTable([[], 0, 10, 0]));
    });
  }, [processo, configureStatesPesquisa]);

  const buildTable = useCallback((content) => {
    if (content.length === 0) {
      return [];
    }

    return {
      id: content.sequencia,
      values: {
        idTitulo: content.idTitulo,
        sequencia: content.sequencia,
        descricaoStatusTitulo: content.descricaoStatusTitulo,
        idTipoTitulo: content.idTipoTitulo,
        descricaoTipoTitulo: content.idTipoTitulo,
        numero: content.numero,
        dataVencimento: getDateTimeMask(content.dataVencimento, false, false),
        atraso: content.atraso,
        valorOriginal: getMoneyMask(content.valorOriginal),
        valorSaldoPrincipal: getMoneyMask(content.valorSaldoPrincipal),
        valorProtesto: getMoneyMask(content.valorProtesto),
        valorMulta: getMoneyMask(content.valorMulta),
        juros: getMoneyMask(content.valorJuros),
        valorHonorarios: getMoneyMask(content.valorHonorarios),
        valorDesconto: getMoneyMask(content.valorDesconto),
        linhaCinza: content.valorSaldoPrincipal === 0,
        fazParteSimulacaoAcordoPropostaQualquer: content.fazParteSimulacaoAcordoPropostaQualquer,
      },
    };
  }, []);

  // Constrói a tabela.
  const tableContent = useMemo(
    () => settingsTable.content.map((processoParam, index) => buildTable(
      processoParam,
      index,
    )),
    [settingsTable.content, buildTable],
  );

  useEffect(() => {
    setTableRows(tableContent);
  }, [tableContent]);

  useEffect(() => {
    if (showDeleteDialog) {
      dispatch(findMotivosExclusaoTituloAction());
    }
  }, [dispatch, showDeleteDialog]);

  return (
    <>
      <DialogNew
        open={openButtonNovo}
        text={routesName}
        className={style.dialog}
      >
        {openButtonNovo && (
          <DialogTitulo
            onClose={onClose}
            immutableSelectors={immutableSelectors}
            setLoadings={setLoadings}
            updatePage={updatePage}
            idProcesso={processo.id}
            titulo={titulo}
            additionalInfos={additionalInfos}
          />
        )}
      </DialogNew>
      <OptionDialogNew
        open={showDeleteDialog}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={closeDeleteDialogHandler}
        onClickConfirm={handleDeleteTitulo}
        disableConfirm={!motivoExclusao}
      >
        <Box className={style.dialogExcluir}>
          <Typography className={style.tituloDialogExcluir}>
            Excluir Título
          </Typography>
          <Grid24 xs={24} sm={16}>
            <SelectorField
              isForm
              label={getRequiredLabel('Motivo', true)}
              name="motivo"
              value={motivoExclusao}
              items={itemsSelectorMotivoExclusao}
              onChange={e => setMotivoExclusao(e.target.value)}
              fullWidth
            />
          </Grid24>
        </Box>
      </OptionDialogNew>
      <div style={{ width: '100%' }}>
        <HeaderTitulos>
          <ButtonNovo
            variant="outlined"
            disabled={disabledGerenciarTitulos || cannotEnabled}
            onClick={onClickButtonNovo}
          >
            <FaPlus />
            <span>Novo</span>
          </ButtonNovo>
        </HeaderTitulos>
        <TabelaPesquisaTitulos
          disabledActions={disabledGerenciarTitulos}
          rows={tableRows}
          columns={tableColumns}
          page={settingsTable.page}
          size={settingsTable.size}
          onChangePage={onChangePageHandler}
          onChangeRowsPerPage={onChangeRowsPerPageHandler}
          rowsPerPage={settingsTable.rowsPerPage}
          setLoadings={setLoadings}
          cannotEnabled={cannotEnabled}
          sinalizacaoCoresQuery={sinalizacaoCoresQuery}
          onEditTituloHandler={onEditTituloHandler}
          onClickDeleteDialogHandler={onClickDeleteDialogHandler}
        />
        <div />
      </div>
    </>
  );
}

export const TitulosProcesso = TitulosProcessoComponent;
