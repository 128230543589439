import React, { useCallback } from 'react';
import WrapperSubTitleForm from '../../../../UI/WrapperSubTitleForm/WrapperSubTitleForm';
import TitleList from '../../../../UI/List/Title/TitleList';
import List from '../../../../UI/List/ListUI';

import useStyles from './definirPermissoes.styles';
import useUtilStyles from '../../../../../helpers/styles/utils.styles';
import Grid24 from '../../../../UI/Grid24/Grid24';
import { useCrudUsuarioDispatch2, useCrudUsuarioSelector2 } from '../../../../../store/Usuario/V2/CrudUsuarioProvider';
import {
  onChangeActions,
  onChangeControls,
  onCheckTitlePermissionCheckbox,
} from '../../../../../store/Usuario/V2/crudUsuario.store';
import { getAcoesUsuarios } from '../../../../../store/Usuario/V2/crudUsuario.factory';
import {
  ACOES_CONFIGURAVEIS_SELECIONADO_TITLE,
  PERMISSOES_DEFINIDAS_USUARIO_TITLE, RELACAO_ITENS_CONFIGURAVEL_TITLE,
  SELECIONAR_TODOS_HINT,
} from './definirPermissoes.constants';

function DefinirPermissoes({ disabled = false }) {
  const styles = useStyles();
  const utilStyles = useUtilStyles();
  const dispatch = useCrudUsuarioDispatch2();

  const relacaoItensConfiguraveis = useCrudUsuarioSelector2(states => states.controls.permissoes);
  const acoesConfiguraveis = useCrudUsuarioSelector2(states => states.controls.acoesConfiguraveis);
  const indexPermissao = useCrudUsuarioSelector2(states => states.actions.indexPermissao);

  const getUpdatedAcoesUsuario = useCallback((acoesUsuarios, index, checked) => {
    acoesUsuarios[index].model.onChange(checked);
    const newAcoesConfiguraveis = [...relacaoItensConfiguraveis];
    newAcoesConfiguraveis[indexPermissao].acoesUsuario = acoesUsuarios;
    return newAcoesConfiguraveis;
  }, [relacaoItensConfiguraveis, indexPermissao]);

  const uncheckAcoesUsuarios = (acoesUsuarios, isChecked) => {
    if (!isChecked) {
      return acoesUsuarios;
    }
    return acoesUsuarios.map((acao) => {
      const newAcoes = { ...acao };
      newAcoes.model = acao.model.getNewModel(false);
      return newAcoes;
    });
  };

  const updateAllAcoesUsuarios = useCallback((acoesUsuarios, checked) => {
    const newAcoesUsuarios = acoesUsuarios.map((acao) => {
      const newAcoes = { ...acao };
      newAcoes.model = acao.model.getNewModel(checked);
      return newAcoes;
    });
    const newRelacaoItensConfiguraveis = [...relacaoItensConfiguraveis];
    newRelacaoItensConfiguraveis[indexPermissao].acoesUsuario = newAcoesUsuarios;
    return newRelacaoItensConfiguraveis;
  }, [relacaoItensConfiguraveis, indexPermissao]);

  const changeActiveRelacaoItensConfiguraveisAHandler = useCallback((index) => {
    const acoesUsuario = getAcoesUsuarios(index, relacaoItensConfiguraveis);
    dispatch(onCheckTitlePermissionCheckbox(acoesUsuario));
    dispatch(onChangeActions('indexPermissao', index));
  }, [dispatch, relacaoItensConfiguraveis]);

  const changeCheckboxPermissoesHandler = useCallback((event, index) => {
    const { checked } = event.target;
    const acoesUsuario = getAcoesUsuarios(indexPermissao, relacaoItensConfiguraveis);
    if (acoesUsuario.length === 0) {
      return;
    }
    const newAcoesConfiguraveis = getUpdatedAcoesUsuario(acoesUsuario, index, checked);
    dispatch(onChangeControls('permissoes', newAcoesConfiguraveis));
    dispatch(onCheckTitlePermissionCheckbox(acoesUsuario));
  }, [dispatch, relacaoItensConfiguraveis, indexPermissao, getUpdatedAcoesUsuario]);

  const checkAllPermissions = useCallback((event) => {
    const { checked } = event.target;
    dispatch(onChangeControls('acoesConfiguraveis', checked));
    let acoesUsuarios = getAcoesUsuarios(indexPermissao, relacaoItensConfiguraveis);
    if (acoesUsuarios.length === 0) {
      return;
    }
    acoesUsuarios = uncheckAcoesUsuarios(acoesUsuarios, checked);
    const newRelacaoItensConfiguraveis = updateAllAcoesUsuarios(acoesUsuarios, checked);
    dispatch(onChangeControls('permissoes', newRelacaoItensConfiguraveis));
  }, [dispatch, indexPermissao, relacaoItensConfiguraveis, updateAllAcoesUsuarios]);

  return (
    <WrapperSubTitleForm
      title={PERMISSOES_DEFINIDAS_USUARIO_TITLE}
      className={utilStyles.mt16}
    >
      <Grid24 container isFormContainer spacing="1" classes={{ root: styles.topTableDistance }}>
        <Grid24 xs={24} sm={7}>
          <List
            header={<TitleList title={RELACAO_ITENS_CONFIGURAVEL_TITLE} />}
            items={relacaoItensConfiguraveis}
            changeItem={changeActiveRelacaoItensConfiguraveisAHandler}
            activeIndex={indexPermissao}
          />
        </Grid24>
        <Grid24 xs={24} sm={10} classes={{ root: styles.distenceBetweenTables }}>
          <List
            className={styles.alignTitle}
            disabled={disabled}
            header={(
              <TitleList
                withChecked
                title={ACOES_CONFIGURAVEIS_SELECIONADO_TITLE}
                hint={disabled ? '' : SELECIONAR_TODOS_HINT}
                checkboxProps={{
                  name: acoesConfiguraveis.name,
                  checked: acoesConfiguraveis.value,
                  disabled,
                }}
                onChange={checkAllPermissions}
              />
            )}
            items={getAcoesUsuarios(indexPermissao, relacaoItensConfiguraveis)}
            onChangeCheckbox={changeCheckboxPermissoesHandler}
          />
        </Grid24>
      </Grid24>
    </WrapperSubTitleForm>
  );
}

export default React.memo(DefinirPermissoes);
