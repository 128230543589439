import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';

import CheckboxField from '../UI/Field/Checkbox/CheckboxField';

function TableHeaderDocumentosProcessoComponent({ selectAll, onSelectAll, podeModificarFase }) {
  return (
    <TableRow>
      <TableCell>
        <CheckboxField
          disabled={!podeModificarFase}
          onChange={onSelectAll}
          checked={selectAll}
        />
      </TableCell>
      <TableCell>Extensão</TableCell>
      <TableCell>Tipo</TableCell>
      <TableCell>Descrição</TableCell>
      <TableCell>Nome do Arquivo</TableCell>
      <TableCell>Original Arquivado</TableCell>
      <TableCell>Oculto</TableCell>
      <TableCell>Data de Arquivamento</TableCell>
      <TableCell>Responsável</TableCell>
      <TableCell>Ações</TableCell>
    </TableRow>
  );
}

export const TableHeaderDocumentosProcesso = TableHeaderDocumentosProcessoComponent;
