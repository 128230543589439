import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({

  // ###### Utils ######
  control: {
    paddingTop: '7px',
  },
  controlLeft: {
    paddingTop: '7px',
    paddingLeft: '10px',
  },
  divider: {
    borderColor: '#FEFEFEAA',
    marginTop: '7px',
  },
  dividerEndereco: {
    borderColor: '#FEFEFEAA',
    marginTop: '7px',
  },
  dividerTitulo: {
    borderColor: '#FEFEFEAA',
    marginTop: '3px !important',
  },
  dividerTelefone: {
    borderColor: '#FEFEFEAA',
    marginTop: '3px !important',
  },
  dividerEmail: {
    borderColor: '#FEFEFEAA',
    marginTop: '3px !important',
  },
  faixas__divider: {
    borderColor: '#FEFEFEAA',
    marginTop: '7px !important',
  },
  titleFieldDistance: {
    marginBottom: '7px',
  },
  checkboxPl8ml22: {
    '& .MuiFormControlLabel-root': {
      paddingLeft: '8px',
      marginTop: '22px',
    },
  },
  checkboxMt16ml18: {
    marginTop: '16px',
    marginLeft: '18px',
  },
  tableTitle: {
    fontWeight: 'bold',
    margin: '0px 0px 13px 13px',
  },
  div__header__ordenacao: {
    justifyContent: 'center',
    display: 'flex',
    cursor: 'pointer',
  },
  icon__ordenacao: {
    '& i': {
      marginTop: '2px',
      marginLeft: '6px',
    },
  },
  iconLabel__ordenacao: {
    display: 'flex',
    '& i': {
      marginLeft: '6px',
    },
  },

  pesquisaAvancadaButton: {
    color: '#034ddc',
  },
  pesquisaSimplesButton: {
    color: '#707070',
  },

  containerTooltip: {
    width: '100%',
  },
  tooltipMaxWidth: {
    maxWidth: '500px',
  },
  accordionsInternForm: {
    padding: '4px 10px 10px 8px !important',
  },
  overFlowText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tableRowRoot: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  // ###### Fonts ######
  fontSize12Typography: {
    ' & .MuiTypography-body1': {
      fontSize: '12px !important',
    },
  },
  labelFaseSelector: {
    fontWeight: 'bold',
  },


  // ###### Widths ######
  w100: {
    width: '100%',
  },
  w50: {
    width: '50%',
  },
  w80: {
    width: '80%',
  },
  w30: {
    width: '30%',
  },
  w20: {
    width: '20%',
  },
  w10: {
    width: '10%',
  },
  w585: {
    width: '585px',
  },

  // TODO Trocar todas as classes css dos botões(cancelar, salvar) quando possivel que estão apartadas em cada componente para usar essas.
  cancelarButton: {
    width: '100px',
    marginRight: '10px',
  },
  confirmarButton: {
    width: '152px',
  },


  areaSize: {
    '& textarea': {
      minHeight: '80px',
      maxHeight: '500px',
      width: '100% !important ',
    },
  },

  // ###### Positions ######
  posRelative: {
    position: 'relative',
  },
  posAbsolute: {
    position: 'absolute',
  },
  top7: {
    top: '7px',
  },

  // ###### Margins Right ######
  mr2: {
    marginRight: '2px',
  },
  mr8: {
    marginRight: '8px',
  },
  mr12: {
    marginRight: '12px',
  },

  // ###### Margins Left ######

  ml2: {
    marginLeft: '2px',
  },
  ml3: {
    marginLeft: '3px',
  },
  ml4: {
    marginLeft: '4px',
  },
  ml7: {
    marginLeft: '7px',
  },
  ml8: {
    marginLeft: '8px',
  },
  ml10: {
    marginLeft: '10px',
  },
  ml12: {
    marginLeft: '12px',
  },
  ml16: {
    marginLeft: '16px',
  },
  ml21: {
    marginLeft: '21px',
  },
  ml22: {
    marginLeft: '22px',
  },
  ml32: {
    marginLeft: '32px',
  },

  // ###### Margins Top ######
  mt2: {
    marginTop: '2px',
  },
  mt6: {
    marginTop: '6px',
  },
  mt7: {
    marginTop: '7px',
  },
  mt8: {
    marginTop: '8px',
  },
  mt9: {
    marginTop: '9px',
  },
  mt10: {
    marginTop: '10px',
  },
  mt13: {
    marginTop: '13px',
  },
  mt15: {
    marginTop: '15px',
  },
  mt16: {
    marginTop: '16px',
  },
  mt20: {
    marginTop: '20px',
  },
  mt22: {
    marginTop: '22px',
  },
  mt42: {
    marginTop: '42px',
  },
  mt54: {
    marginTop: '54px',
  },

  // ###### Margins Bottom ######
  mb1: {
    marginBottom: '1px',
  },
  mb2: {
    marginBottom: '2px',
  },
  mb3: {
    marginBottom: '3px',
  },
  mb5: {
    marginBottom: '5px',
  },
  mb7: {
    marginBottom: '7px',
  },
  mb8: {
    marginBottom: '8px',
  },
  mb12: {
    marginBottom: '12px',
  },
  mb16: {
    marginBottom: '16px',
  },
  mb22: {
    marginBottom: '22px',
  },
  mb4: {
    marginBottom: '4px',
  },

  // ###### Paddings Top ######
  pt8: {
    paddingTop: '8px',
  },

  // ###### Paddings Left ######
  pl8: {
    paddingLeft: '8px',
  },
  pl11: {
    paddingLeft: '11px',
  },
  pl19: {
    paddingLeft: '19px',
  },
  pl40: {
    paddingLeft: '40px',
  },

  // ###### Padding ######
  padding22: {
    padding: '22px',
  },
  padding10: {
    padding: '10px',
  },

  // ###### MarginsComposta ######
  mt16mb7: {
    marginTop: '16px',
    marginBottom: '7px',
  },
  mt7mb16: {
    marginTop: '7px',
    marginBottom: '16px',
  },
  mt16mb16: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  mt7ml10: {
    marginTop: '7px',
    marginLeft: '10px',
  },
  ml40mt11: {
    marginLeft: '40px',
    marginTop: '11px',
  },
  mt12ml10: {
    marginTop: '10px',
    marginLeft: '12px',
  },
  mt3ml8: {
    marginTop: '3px',
    marginLeft: '8px',
  },
  mt10ml8: {
    marginTop: '10px',
    marginLeft: '8px',
  },
  mr22ml22: {
    marginRight: '22px',
    marginLeft: '22px',
  },
  mb7mt20: {
    marginTop: '20px',
    marginBottom: '7px',
  },
});
