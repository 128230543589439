import React from 'react';
import { withRouter } from 'react-router';
import Grid24 from '../../../../components/UI/Grid24';
import SimpleEmails from '../../../../components/UI/SimpleEmails/SimpleEmails';
import FormWrapperWithTitle from '../../../../components/UI/Wrapper/Form/FormWrapperWithTitle';
import useStyles from '../DadosDevedor.styles';

function EmailDevedor({
  isEditMode, podeModificarFase, formFields, setAttributeHandler, setFocusHandler, setObjectsHandler,
}) {
  const styles = useStyles();

  return (
    <Grid24 container isFormContainer>
      <FormWrapperWithTitle>
        <Grid24 container isFormContainer spacing="1" className={styles.margin}>
          <Grid24 xs={24} sm={24}>
            <SimpleEmails
              disabled={!podeModificarFase || !isEditMode}
              readMode={!podeModificarFase || !isEditMode}
              emails={formFields.emails || ''}
              setFocus={setFocusHandler}
              setAttribute={setAttributeHandler}
              setEmails={setObjectsHandler}
            />
          </Grid24>
        </Grid24>
      </FormWrapperWithTitle>
    </Grid24>
  );
}

export default withRouter(EmailDevedor);
