import React from 'react';
import OptionDialogNew from '../../../../UI/Dialogs/OptionDialog/OptionDialogNew';

function ConfirmarEnvioDialog({ open, onClickConfirmarHandler, onClickCancelarHandler }) {
  return (
    <OptionDialogNew
      open={open}
      confirmLabel="Enviar"
      cancelLabel="Cancelar"
      onClickCancel={onClickCancelarHandler}
      onClickConfirm={onClickConfirmarHandler}
    >
      <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
        <p>
          Deseja enviar o código de acesso do Site Acordo Seguro
          <br />
          a todos os Processos selecionados?
        </p>
      </div>
    </OptionDialogNew>
  );
}

export default ConfirmarEnvioDialog;
