import React, { useCallback } from 'react';
import { Button } from '@mui/material';
import { Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import Grid24 from '../../components/UI/Grid24';

import { useRecebimentoDispatch, useRecebimentoSelector } from '../../store/RecebimentoDevedores/RecebimentoDevedoresProvider';
import MoneyField from '../../components/UI/Field/Money/MoneyField';
import { getMoneyMask } from '../../helpers';
import { setSimpleValueRecebimentoDistribuicao } from '../../store/RecebimentoDevedores/RecebimentoDevedores.store';
import { submitDistribuicaoManualAction } from '../../store/RecebimentoDevedores/RecebimentoDevedores.saga';


function DialogDistribuicaoManual({ setOpenDialogDistribuicaoManual, openPesquisaAvancada }) {
  const dispatch = useRecebimentoDispatch();

  const valorRepasse = useRecebimentoSelector(states => states.recebimentoDistribuicao.valorRepasse.value);
  const valorTotalRecebimento = useRecebimentoSelector(states => states.recebimentoDistribuicao.valorTotalRecebimentoDistribuicao);

  const resetCampos = useCallback(() => {
    dispatch(setSimpleValueRecebimentoDistribuicao('valorRepasse', 0));
    dispatch(setSimpleValueRecebimentoDistribuicao('valorTotalRecebimentoDistribuicao', 0));
  }, [dispatch]);

  const onClickConfirmar = useCallback(() => {
    if (parseFloat(valorRepasse, 10) > valorTotalRecebimento) {
      toast.error('O valor do repasse ao Cliente não pode ser superior ao valor recebido.');
    } else {
      dispatch(submitDistribuicaoManualAction(setOpenDialogDistribuicaoManual));
    }
    resetCampos();
  }, [valorRepasse, valorTotalRecebimento, dispatch, setOpenDialogDistribuicaoManual, resetCampos]);

  const onClickCancelar = useCallback(() => {
    setOpenDialogDistribuicaoManual(false);
    resetCampos();
  }, [setOpenDialogDistribuicaoManual, resetCampos]);

  return (
    <>
      <Grid24 container isFormContainer spacing={1}>
        <Grid24 xs={24} sm={24}>
          <Typography style={{ fontSize: '12px', color: '#000', marginBottom: '18px' }}>
            Valor total do Recebimento:
            {'     ' }

            <strong>{getMoneyMask(valorTotalRecebimento)}</strong>
          </Typography>
        </Grid24>
        <Grid24 xs={24} sm={24}>
          <Typography style={{ fontSize: '12px', color: '#707070', marginBottom: '10px' }}>
            O Valor informado abaixo será repassado ao Cliente e o restante repassado à ABE.
          </Typography>
        </Grid24>
        <Grid24 xs={24} sm={6}>
          <MoneyField
            isForm
            fullWidth
            name="valorRepasse"
            label="Repasse ao Cliente*"
            onChange={(e) => {
              dispatch(setSimpleValueRecebimentoDistribuicao(e.target.name, e.target.value));
            }}
            value={valorRepasse}
          />
        </Grid24>
      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '22px' }}>
        <Button
          onClick={onClickCancelar}
        >
          Cancelar
        </Button>
        <Button
          onClick={onClickConfirmar}
          disabled={valorRepasse === null || valorRepasse === ''}
        >
          Confirmar
        </Button>
      </div>

    </>
  );
}

export default DialogDistribuicaoManual;
