import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export const SearchButton = styled(IconButton)`
  background-color: #ff1381;
  color: white;
  width: 44px;
  height: 44px;

  :hover {
    background-color: #ff1381;
    opacity: 0.7;
  }
`;
