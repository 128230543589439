import styled from 'styled-components';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button } from '@material-ui/core';

export default makeStyles({
  dialog: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '559px',
      maxHeight: '350px',
    },
  },

  text: {
    fontSize: '12px',
    marginLeft: '10px',
  },

  grid__radioButton: {
    display: 'flex',
    marginLeft: '19px',
  },

  grid__footer: {
    display: 'flex',
    marginTop: '15px',
  },

  dialogExcluir: {
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'flex-start',
    textAlign: 'start',
  },

  tituloDialogExcluir: {
    fontSize: 18,
    fontWeight: 700,
    marginBottom: '15px',
  },

});

export const HeaderTitulos = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  button {
    color: #564872;
    border: 1px solid #564872;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    width: 89px;
    height: 26px;
    text-transform: none;
    min-width: 89px;
  }
`;

export const ButtonNovo = styled(Button)`
margin-right: 9px;
font-size: 14px;
color:  #564872;

:disabled {
color: #c4c4c4;
}

& .MuiButton-label span {
    margin-left: 8px;
  }
`;
