import { getUriParams } from '../../../helpers';
import {
  CORE, gateway, RELATORIOS,
} from '../../api';

export default {
  importarLancamento(
    arquivoLancamentosRetorno,
  ) {
    const data = new FormData();
    data.append('arquivoLancamentosRetorno', arquivoLancamentosRetorno);
    return gateway.post(`${CORE}/recebimento-cliente/importar-lancamentos`, data);
  },

  getInfoCards(data) {
    return gateway.get(`${CORE}/recebimento-cliente/info-cards/${data}`);
  },

  buscarRecebimento(data, somenteNaoLiberados, page, size, order) {
    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    const queryParam = getUriParams({ page, size });

    return gateway.get(`${CORE}/recebimento-cliente/${data}/${somenteNaoLiberados}?${queryParam}${sortedQuery}`);
  },

  deleteLancamento(idLancamentoCliente) {
    return gateway.delete(`${CORE}/recebimento-cliente/excluir/${idLancamentoCliente}`);
  },

  cancelarLiberarLancamento(idLancamentoCliente) {
    return gateway.put(`${CORE}/recebimento-cliente/atualizar-liberacao/${idLancamentoCliente}`);
  },

  getTiposFormasPagamento() {
    return gateway.get(`${CORE}/tipos-formas-pagamento/recebimento-cliente`);
  },

  getContasBancarias() {
    return gateway.get(`${CORE}/conta-bancaria/recebimento-cliente`);
  },

  inserir(payload) {
    return gateway.post(`${CORE}/recebimento-cliente/incluir-lancamento`, payload);
  },

  getInfosEditar(idLancamentoCliente) {
    return gateway.get(`${CORE}/recebimento-cliente/buscar-lancamento/${idLancamentoCliente}`);
  },

  onClickButtonImprimirHandler(
    data, lancamentosNaoLiberados, order,
  ) {
    const body = {
      data, lancamentosNaoLiberados,
    };
    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    return gateway.post(`${RELATORIOS}/recebimento-cliente/imprimir?${sortedQuery}`, body, {
      responseType: 'blob',
    });
  },
};
