import { useDispatch, useSelector } from 'react-redux';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { MdPrint as MdPrintIcon } from 'react-icons/md';
import {
  Button, RadioGroup, TableContainer, TableRow, Tooltip,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';
import { TableDefault } from '../../../../../components/UI/Tabela/TableDefault';
import {
  useCCClienteDispatch,
  useCCClienteSelector,
} from '../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import {
  buildSaldosPagamentos,
  onChangeFormFieldsPesquisaAction,
  resetFormFieldsNovaNotaAction,
  resetFormFieldsNovoBoletoAction,
  resetFormFieldsPesquisaAction,
  setClienteSelecionadoAction, setDefaultNaoPrestadosAction,
  setSimpleValueBoletosRelacaoPrestacaoAction,
  setSimpleValueLancamentoHandlerAction,
  setSimpleValueNotasFiscaisHandlerAction,
  setSimpleValuePagamentosAction,
  setSimpleValuePagamentosValorAction,
  setSimpleValueRelacaoPrestacaoAction,
  setSimpleValuesDetalhesAction,
  setSimpleValuesPesquisaAction,
} from '../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import {
  findFiliaisAction,
  findResponsaveisAction,
  findTiposDeLancamentosAction,
} from '../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import { LockOpenIcon } from '../../../../../assets/Icons/AttachFileIcon/lockOpen';
import useStyle, {
  ButtonImprimir,
  ButtonPesquisaAvancada,
  ButtonPesquisar,
  CardPreviaPrestacao,
  ContainerPesquisa,
  ContainerPesquisaAvancada,
  CustomTableActionButtomCliente,
  DateFieldPrestacao,
  FaLockIconGrey,
  FiltroPesquisaSimples,
  Switch,
  TableHeader,
} from '../contaCorrenteClienteStyles';
import {
  getMoneyMask, getNewPage, insertIntoArray, TableUtils, useUtilStyles,
} from '../../../../../helpers';
import { FaLockIcon } from '../../../../Comissionado/ContaCorrenteComissionadoStyles';
import { ContaCorrenteClienteFactory } from '../ContaCorrenteClienteController';
import { TableCellCenter, TableCellLeft, TableCellRight } from '../../../../../components/UI/Tabela/tableDefaultStyles';
import RadioField from '../../../../../components/UI/Field/Radio/RadioField';
import TooltipUI from '../../../../../components/UI/Tooltip/Tooltip';

import InputField from '../../../../../components/UI/Field/Input/InputField';
import SelectorField from '../../../../../components/UI/Field/Selector/SelectorField';
import { TableHeaderContaCorrenteCliente } from '../../../../../components/TabelaHeader/V2/TableHeaderContaCorrenteCliente';
import {
  pesquisarContaCorrenteClientesAction,
  pesquisarClientesOnClickAction,
  downloadRelatorioClienteAction,
} from '../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import LoadingUI from '../../../../../components/UI/Loading/LoadingUI';

const statusPrestacao = {
  DESBLOQUEADO: {
    icon: <LockOpenIcon size={15} />,
    tooltip: 'Bloquear prestação de contas',
    type: 'normal',
    isCustom: false,
  },
  SUSPENSO_INATIVO: {
    icon: <FaLockIconGrey />,
    tooltip: 'Cliente Inativo, Prestação Suspensa ou sem Lançamentos',
    type: 'custom',
    isCustom: true,
  },
  BLOQUEIO_TEMPORARIO: {
    icon: <FaLockIcon />,
    tooltip: 'Desbloquear prestação de contas',
    type: 'custom',
    isCustom: true,
  },
};


function ContaCorrentePesquisaCliente({
  setTableView, idClientePage, nossoNumeroBoletoPage,
}) {
  const globalDispatch = useDispatch();
  const dispatch = useCCClienteDispatch();
  const style = useStyle();
  const utilStyle = useUtilStyles();

  const idCliente = useCCClienteSelector(states => ({ ...states.pesquisa.formFields.idCliente }));
  const dataPrestacao = useCCClienteSelector(states => ({ ...states.pesquisa.formFields.dataPrestacao }));
  const formFields = useCCClienteSelector(states => ({ ...states.pesquisa.formFields }));
  const page = useCCClienteSelector(states => states.pesquisa.page);
  const rowsPerPage = useCCClienteSelector(states => states.pesquisa.rowsPerPage);
  const clienteSelecionado = useCCClienteSelector(states => states.common.clienteSelecionado);
  const cacheFormFields = useCCClienteSelector(states => states.pesquisa.cacheFormFields);
  const allClientes = useCCClienteSelector(states => states.pesquisa.allClientes);
  const tableView = useCCClienteSelector(states => states.pesquisa.tableView);
  const filiais = useSelector(states => states.selectors.filiais.selector);
  const tiposLancamentos = useSelector(states => states.selectors.tiposLancamentosItems);
  const responsaveis = useSelector(states => states.selectors.responsaveisItems);
  const tableViewLancamentos = useCCClienteSelector(states => (states.lancamentos.tableView));
  const tableViewPagamentos = useCCClienteSelector(states => ([...states.pagamentos.pagamentos]));
  const tableViewNotasFiscais = useCCClienteSelector(states => (states.notasFiscais.tableView));
  const dataVencimentoBoleto = useCCClienteSelector(states => (states.relacaoPrestacao.informacoesAdicionais.dataVencimentoBoleto));
  const dadosBoletos = useCCClienteSelector(states => states.boletosRelacaoPrestacao.dadosBoletos);
  const nossoNumeroBoleto = useCCClienteSelector(states => ({ ...states.pesquisa.formFields.nossoNumeroBoleto }));


  const [errorsField, setErrorsField] = React.useState(ContaCorrenteClienteFactory.makeErrorFields());

  const [openPesquisaAvancada, setOpenPesquisaAvancada] = useState(!!nossoNumeroBoletoPage);
  const [valorPrevia, setValorPrevia] = useState(null);
  const [mostrarSoDesbloqueados, setMostrarSoDesbloqueados] = useState(true);

  const onChangeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeFormFieldsPesquisaAction(name, value));
  }, [dispatch]);

  const setExternalDataPrestacaoError = useCallback((dateFieldInternalError) => {
    const { error, message } = dateFieldInternalError;
    setErrorsField(old => ({
      ...old,
      dataPrestacao: { error, errorMessage: message },
    }));
  }, []);


  const onClickPesquisaAvancadaAcordoHandler = useCallback(() => {
    setOpenPesquisaAvancada((old) => {
      dispatch(resetFormFieldsPesquisaAction());
      return !old;
    });
  }, [dispatch]);

  const buttonClassPesquisaAvancada = cx({
    [style.pesquisaAvancadaButton]: !openPesquisaAvancada,
    [style.pesquisaSimplesButton]: openPesquisaAvancada,
  });

  const valuesPagamento = useCallback(async () => {
    dispatch(setSimpleValuePagamentosValorAction('valor', 0));
    dispatch(setSimpleValuePagamentosAction('columnOrdenacao', { element: 'data', order: 'DESC' }));
    dispatch(setSimpleValuePagamentosAction('arrowDirection', true));
    dispatch(setSimpleValuePagamentosAction('saldos', buildSaldosPagamentos(null)));
    dispatch(setSimpleValuePagamentosAction('pagamentos', []));
    dispatch(setSimpleValuePagamentosAction('totalElements', tableViewPagamentos.length));
  }, [dispatch, tableViewPagamentos.length]);

  const valuesLancamento = useCallback(async () => {
    dispatch(setSimpleValueLancamentoHandlerAction('page', 0));
    dispatch(setSimpleValueLancamentoHandlerAction('rowsPerPage', 10));
    dispatch(setSimpleValueLancamentoHandlerAction('tableView', []));
    dispatch(setSimpleValueLancamentoHandlerAction('totalElements', tableViewLancamentos.length));
  }, [dispatch, tableViewLancamentos.length]);

  const valuesNotasFiscais = useCallback(async () => {
    dispatch(setSimpleValueNotasFiscaisHandlerAction('page', 0));
    dispatch(setSimpleValueNotasFiscaisHandlerAction('rowsPerPage', 10));
    dispatch(setSimpleValueNotasFiscaisHandlerAction('tableView', []));
    dispatch(setSimpleValueNotasFiscaisHandlerAction('totalElements', tableViewNotasFiscais.length));
    dispatch(setSimpleValueNotasFiscaisHandlerAction('openButtonNovaNota', false));
    dispatch(resetFormFieldsNovaNotaAction());
  }, [dispatch, tableViewNotasFiscais.length]);

  const valuesBoletos = useCallback(async () => {
    dispatch(setSimpleValueBoletosRelacaoPrestacaoAction('page', 0));
    dispatch(setSimpleValueBoletosRelacaoPrestacaoAction('rowsPerPage', 10));
    dispatch(setSimpleValueBoletosRelacaoPrestacaoAction('dadosBoletos', []));
    dispatch(setSimpleValueBoletosRelacaoPrestacaoAction('totalElements', dadosBoletos.length));
    dispatch(setSimpleValueBoletosRelacaoPrestacaoAction('openButtonNovoBoleto', false));
    dispatch(resetFormFieldsNovoBoletoAction(dataVencimentoBoleto));
  }, [dispatch, dadosBoletos.length, dataVencimentoBoleto]);

  const onClickPesquisar = useCallback(async (event) => {
    event.preventDefault();
    valuesPagamento();
    valuesLancamento();
    valuesNotasFiscais();
    valuesBoletos();
    const formData = new FormData(event.currentTarget);
    dispatch(pesquisarClientesOnClickAction(formData, mostrarSoDesbloqueados));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, mostrarSoDesbloqueados]);


  const handleChangePage = useCallback((e, newPage) => {
    dispatch(setSimpleValuesPesquisaAction('page', newPage));

    const viewPaginada = TableUtils.paginar(allClientes, rowsPerPage, newPage);

    // Paginação no front...
    // Não tem pesquisa novamente...
    dispatch(setSimpleValuesPesquisaAction('tableView', viewPaginada));
    dispatch(setClienteSelecionadoAction(viewPaginada[0].idCliente, viewPaginada[0].statusPrestacaoContas));
  }, [dispatch, allClientes, rowsPerPage]);


  const handleRowsPerPageChange = useCallback((e) => {
    const size = e.target.value;
    const newPage = getNewPage(rowsPerPage, page, size);
    dispatch(setSimpleValuesPesquisaAction('rowsPerPage', size));
    dispatch(setSimpleValuesPesquisaAction('page', newPage));

    // Paginação no front...
    // Não tem pesquisa novamente...
    if (allClientes?.length > 0) {
      dispatch(setSimpleValuesPesquisaAction('tableView', TableUtils.paginar(allClientes, size, newPage)));
    }
  }, [dispatch, allClientes, rowsPerPage, page]);

  const handlePrintClientes = useCallback(() => {
    dispatch(downloadRelatorioClienteAction());
  }, [dispatch]);

  const onChangeClienteSelecionado = useCallback((event, idClienteParam, status) => {
    event.preventDefault();
    dispatch(setDefaultNaoPrestadosAction());
    dispatch(setSimpleValueRelacaoPrestacaoAction('page', 0));
    dispatch(setSimpleValueRelacaoPrestacaoAction('rowsPerPage', 10));
    valuesPagamento();
    valuesLancamento();
    valuesNotasFiscais();
    valuesBoletos();
    dispatch(setClienteSelecionadoAction(idClienteParam, status));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);


  const onClickBloquearDesbloquearCliente = useCallback((event, idClienteParam, idxAlterado, statusAtual) => {
    if (statusAtual !== 'SUSPENSO_INATIVO') {
      const allClientesAux = [...allClientes];
      const tableViewAux = [...tableView];
      // Bloquear ou desbloquear o que estou vendo na tabela.
      let contaCorrenteAlterar = tableViewAux.find(conta => conta.idCliente === idClienteParam);

      contaCorrenteAlterar = {
        ...contaCorrenteAlterar,
        statusPrestacaoContas: (statusAtual === 'BLOQUEADO' || statusAtual === 'BLOQUEIO_TEMPORARIO') ? 'DESBLOQUEADO' : 'BLOQUEIO_TEMPORARIO',
      };

      const viewNaoDuplicada = tableViewAux.filter(conta => conta.idCliente !== contaCorrenteAlterar.idCliente);
      const newView = insertIntoArray(viewNaoDuplicada, idxAlterado, contaCorrenteAlterar);

      if (page === 0) {
        dispatch(setSimpleValuesPesquisaAction('tableView', TableUtils.paginar(newView, rowsPerPage, page)));
      } else {
        dispatch(setSimpleValuesPesquisaAction('tableView', newView));
      }

      // Fazer essa alteração no array que tem todos os elementos também (para que se mantenha na mudança de página)

      const idxSelecionado = allClientesAux.findIndex(ids => ids.idCliente === idClienteParam);
      allClientesAux[idxSelecionado] = {
        ...allClientesAux[idxSelecionado],
        statusPrestacaoContas: contaCorrenteAlterar.statusPrestacaoContas,
        valorRepasse: contaCorrenteAlterar.valorRepasse,
      };
      dispatch(setSimpleValuesPesquisaAction('allClientes', allClientesAux));
      dispatch(setClienteSelecionadoAction(idClienteParam, contaCorrenteAlterar.statusPrestacaoContas));
    }
  }, [dispatch, allClientes, tableView, page, rowsPerPage]);

  /**
   * Busca as combo box para filtros.
   */
  useEffect(() => {
    globalDispatch(findFiliaisAction());
    globalDispatch(findTiposDeLancamentosAction());
    globalDispatch(findResponsaveisAction());
  }, [globalDispatch]);

  // Soma da prévia só de quem é desbloqueado.
  useEffect(() => {
    if (allClientes) {
      const valorSomatoriaPrevia = allClientes.filter(conta => conta.statusPrestacaoContas === 'DESBLOQUEADO').reduce((actual, next) => actual + next.valorRepasseWithoutMask, 0);
      setValorPrevia(valorSomatoriaPrevia);
    }
  }, [allClientes]);

  /**
   * Bloquear ou desbloquear o botão de Prestar Todos.
   */
  useEffect(() => {
    if (allClientes) {
      const hasClienteDesbloqueado = allClientes.filter(conta => conta.statusPrestacaoContas === 'DESBLOQUEADO').length > 0;
      dispatch(setSimpleValuesDetalhesAction('canPrestarTodos', hasClienteDesbloqueado));
    }
  }, [allClientes, dispatch]);

  const onClickMostrarDesbloqueado = useCallback((e) => {
    const { checked } = e.target;
    setMostrarSoDesbloqueados(checked);
    const fields = { cacheFormFields, exibirApenasDesbloqueados: checked };
    dispatch(pesquisarContaCorrenteClientesAction(fields, page));
    valuesPagamento();
    valuesLancamento();
    valuesNotasFiscais();
    valuesBoletos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheFormFields, dispatch, page]);


  const getDisabledPesquisarButton = () => {
    if (openPesquisaAvancada) {
      return !(formFields.dataPrestacao.value
        || formFields.idCliente.value
        || formFields.idUsuarioResponsavel.value
        || formFields.nossoNumeroBoleto.value
        || formFields.tipoLancamento.value
        || formFields.numeroRPSNotaFiscal.value
        || formFields.nomeCliente.value
        || formFields.idFilial.value
      );
    }
    return !(formFields.dataPrestacao.value || formFields.idCliente.value);
  };

  useEffect(() => {
    if (idClientePage || nossoNumeroBoletoPage) {
      if (idClientePage) {
        dispatch(onChangeFormFieldsPesquisaAction('idCliente', idClientePage));
        const form = document.getElementById('form-conta-corrente-cliente');
        form.elements.idCliente.value = idClientePage;
        const event = new Event('submit', {
          cancelable: true,
        });
        event.preventDefault();
        form.dispatchEvent(event);
      }
      if (nossoNumeroBoletoPage) {
        dispatch(onChangeFormFieldsPesquisaAction('nossoNumeroBoleto', nossoNumeroBoletoPage));
        const form = document.getElementById('form-conta-corrente-cliente');
        form.elements.nossoNumeroBoleto.value = nossoNumeroBoletoPage;
        const event = new Event('submit', {
          cancelable: true,
        });
        event.preventDefault();
        form.dispatchEvent(event);
      }
    }
  }, [idClientePage, nossoNumeroBoletoPage, dispatch]);

  function RowsTableBodyComponent({
    content,
  }) {
    return content.map((item, idx) => (
      <TableRow key={item.idCliente}>
        <TableCellCenter>
          <RadioField
            value={item.idCliente}
            label=""
            onClick={e => onChangeClienteSelecionado(e, item.idCliente, item.statusPrestacaoContas)}
          />
        </TableCellCenter>
        <TableCellCenter>
          {item.idCliente}
        </TableCellCenter>
        <TableCellLeft>
          <TooltipUI title={item.nomeClienteAlt}>
            <span>{item?.nomeCliente}</span>
          </TooltipUI>
        </TableCellLeft>
        <TableCellCenter>
          {item.tipoPrestacao}
        </TableCellCenter>
        <TableCellRight>
          {item.valorRepasse}
        </TableCellRight>
        <TableCellCenter>
          {!!statusPrestacao && (
            <Tooltip title={statusPrestacao[item.statusPrestacaoContas]?.tooltip} placement="bottom">
              <CustomTableActionButtomCliente
                iscustom={statusPrestacao[item.statusPrestacaoContas]?.isCustom ? 1 : 0}
                iscadeadocinza={item.statusPrestacaoContas === 'SUSPENSO_INATIVO' ? 1 : 0}
                isdesbloqueado={(item.statusPrestacaoContas === 'DESBLOQUEADO' ? 1 : 0)}
                onClick={e => onClickBloquearDesbloquearCliente(e, item.idCliente, idx, item.statusPrestacaoContas)}
              >
                <span>
                  {statusPrestacao[item.statusPrestacaoContas]?.icon}
                </span>
              </CustomTableActionButtomCliente>
            </Tooltip>
          )}
        </TableCellCenter>
      </TableRow>
    ));
  }

  return (
    <ContainerPesquisa>
      <LoadingUI show={tiposLancamentos.loading || responsaveis.loading} />
      <form id="form-conta-corrente-cliente" onSubmit={onClickPesquisar}>
        <FiltroPesquisaSimples>
          <div>
            <InputField
              fullWidth
              name="idCliente"
              label="Código"
              value={idCliente.value}
              error={errorsField.idCliente.error}
              errorMessage={errorsField.idCliente.errorMessage}
              onChange={onChangeFieldsHandler}
            />
          </div>
          <div>
            <p>Dia da Prestação</p>
            <div>
              <DateFieldPrestacao
                name="dataPrestacao"
                value={dataPrestacao.value}
                error={errorsField.dataPrestacao.error}
                errorMessage={errorsField.dataPrestacao.errorMessage}
                setExternalError={setExternalDataPrestacaoError}
                onChange={onChangeFieldsHandler}
              />
            </div>
          </div>
          <ButtonPesquisar
            type="submit"
            disabled={getDisabledPesquisarButton()}
          >
            <SearchIcon />
          </ButtonPesquisar>
        </FiltroPesquisaSimples>
        {openPesquisaAvancada && (
          <ContainerPesquisaAvancada>
            <div>
              <SelectorField
                fullWidth
                hasEmptyLabel
                name="idFilial"
                label="Filial"
                items={filiais}
                onChange={onChangeFieldsHandler}
              />
            </div>
            <div>
              <InputField
                fullWidth
                name="numeroRPSNotaFiscal"
                label="Número da Nota Fiscal ou RPS"
                onChange={onChangeFieldsHandler}
                className={utilStyle.mr8}
              />
              <InputField
                fullWidth
                name="nossoNumeroBoleto"
                label="Nosso Número do boleto"
                value={nossoNumeroBoleto.value}
                error={errorsField.nossoNumeroBoleto.error}
                errorMessage={errorsField.nossoNumeroBoleto.errorMessage}
                onChange={onChangeFieldsHandler}
              />
            </div>
            <div>
              <InputField
                fullWidth
                name="nomeCliente"
                label="Nome do Cliente"
                onChange={onChangeFieldsHandler}
              />
            </div>
            <div>
              <SelectorField
                hasEmptyLabel
                label="Responsável"
                items={responsaveis.selector}
                name="idUsuarioResponsavel"
                onChange={onChangeFieldsHandler}
              />
            </div>
            <SelectorField
              hasEmptyLabel
              items={tiposLancamentos.selector}
              label="Tipo do Lançamento"
              name="tipoLancamento"
              onChange={onChangeFieldsHandler}
            />
          </ContainerPesquisaAvancada>
        )}
        <ButtonPesquisaAvancada>
          <div>
            <Button
              fullWidth
              className={buttonClassPesquisaAvancada}
              onClick={onClickPesquisaAvancadaAcordoHandler}
            >
              {openPesquisaAvancada ? 'Pesquisa Simples' : 'Pesquisa Avançada'}
            </Button>
          </div>
        </ButtonPesquisaAvancada>

      </form>
      <Switch
        name="mostrarSoDesbloqueados"
        label="Exibir apenas clientes com lançamentos desbloqueados"
        checked={mostrarSoDesbloqueados}
        onChange={onClickMostrarDesbloqueado}
        marginTop="8px"
      />
      <div>
        <ButtonImprimir
          disabled={allClientes?.length === undefined || allClientes?.length === 0}
          onClick={handlePrintClientes}
        >
          <MdPrintIcon size={16} />
          <span>Imprimir</span>
        </ButtonImprimir>
      </div>
      <CardPreviaPrestacao>
        <div>
          <p>Prévia do Total de Repasses</p>
          <p>{getMoneyMask(valorPrevia) || '0,00'}</p>
        </div>
      </CardPreviaPrestacao>
      <TableContainer>
        <div className={utilStyle.padding22}>
          <TableHeader>
            Resultado da Pesquisa
          </TableHeader>
          <RadioGroup
            key={uuid()}
            value={clienteSelecionado.idCliente}
          >
            <div>
              <TableDefault
                columnsHead={(
                  <TableHeaderContaCorrenteCliente
                    allItems={allClientes}
                    setTableView={setTableView}
                    page={page}
                    rowsPerPage={rowsPerPage}
                  />
                )}
                page={page}
                rowsPerPage={rowsPerPage}
                totalElements={allClientes?.length || 0}
                totalElementsOnPage={tableView?.length}
                totalColumns={6}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleRowsPerPageChange}
                emptyRowsHeight={33}
                rowsPerPageOptions={[10, 15, 20]}
              >
                {tableView?.length > 0 ? (
                  <RowsTableBodyComponent
                    content={tableView}
                  />
                ) : []}
              </TableDefault>
            </div>
          </RadioGroup>
        </div>
      </TableContainer>
    </ContainerPesquisa>
  );
}

export const ContaCorrentePesquisaClienteComponent = React.memo(ContaCorrentePesquisaCliente);
