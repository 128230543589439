import React, { useCallback } from 'react';
import { getRequiredLabel, useUtilStyles } from '../../../../../../../helpers';
import { CNPJ_MASK } from '../../../../../../../helpers/constants/layout.constants';
import { setContaBacariaAttributeFocusAction, setContaBancariaAttributeValueAction } from '../../../../../../../store/Parametros/Crud/crudParametros.store';
import { useCrudParametrosDispatch, useCrudParametrosSelector } from '../../../../../../../store/Parametros/Crud/CrudParametrosProvider';
import InputField from '../../../../../../UI/Field/Input';
import MaskField from '../../../../../../UI/Field/Mask';
import SelectorField from '../../../../../../UI/Field/Selector';
import Grid24 from '../../../../../../UI/Grid24';
import { SubTitleWrapperForm } from '../ContasBancarias.styles';
import {
  AGENCIA_LABEL, BANCO_LABEL, CNPJ_LABEL, DAC_LABEL, NUMERO_CONTA_LABEL, SISPAG, TITULAR_LABEL,
} from '../TabContasBancarias.constants';


function ContaSispag({
  isInsertMode, bancos,
}) {
  const utilStyle = useUtilStyles();
  const dispatch = useCrudParametrosDispatch();

  const conta = useCrudParametrosSelector(states => states.requestFields.contaBancariaSispag);

  const changeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setContaBancariaAttributeValueAction(name, value));
  }, [dispatch]);


  const focusFieldsHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(setContaBacariaAttributeFocusAction(fieldName));
  }, [dispatch]);

  return (
    <>
      <SubTitleWrapperForm title={SISPAG} />
      <Grid24 xs={24} sm={24} spacing={1}>
        <Grid24 xs={24} sm={13}>
          <InputField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(TITULAR_LABEL, isInsertMode)}
            onFocus={focusFieldsHandler}
            onChange={changeFieldsHandler}
            name={conta.titular.name}
            value={conta.titular.value}
            maxLength={60}
            error={conta.titular.error}
            errorMessage={conta.titular.errorMessage}
          />
        </Grid24>
        <Grid24 xs={24} sm={5}>
          <MaskField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(CNPJ_LABEL, isInsertMode)}
            format={CNPJ_MASK}
            onFocus={focusFieldsHandler}
            onChange={changeFieldsHandler}
            name={conta.cnpjCpf.name}
            value={conta.cnpjCpf.value}
            error={conta.cnpjCpf.error}
            errorMessage={conta.cnpjCpf.errorMessage}
          />
        </Grid24>
      </Grid24>
      <hr className={utilStyle.divider} />
      <Grid24 xs={24} sm={24} spacing={1}>
        <Grid24 xs={24} sm={9}>
          <SelectorField
            isForm
            fullWidth
            disabled
            label={getRequiredLabel(BANCO_LABEL, isInsertMode)}
            items={bancos}
            name={conta.idBanco.name}
            value={conta.idBanco.value}
            error={conta.idBanco.error}
            errorMessage={conta.idBanco.errorMessage}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <InputField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(AGENCIA_LABEL, isInsertMode)}
            onFocus={focusFieldsHandler}
            onChange={changeFieldsHandler}
            name={conta.agencia.name}
            value={conta.agencia.value}
            maxLength={5}
            error={conta.agencia.error}
            errorMessage={conta.agencia.errorMessage}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <InputField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(NUMERO_CONTA_LABEL, isInsertMode)}
            onFocus={focusFieldsHandler}
            onChange={changeFieldsHandler}
            name={conta.numero.name}
            value={conta.numero.value}
            maxLength={15}
            error={conta.numero.error}
            errorMessage={conta.numero.errorMessage}
          />
        </Grid24>
        <Grid24 xs={24} sm={1}>
          <InputField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(DAC_LABEL, isInsertMode)}
            onFocus={focusFieldsHandler}
            onChange={changeFieldsHandler}
            name={conta.dac.name}
            value={conta.dac.value}
            maxLength={1}
            error={conta.dac.error}
            errorMessage={conta.dac.errorMessage}
          />
        </Grid24>
      </Grid24>

    </>
  );
}

export default ContaSispag;
