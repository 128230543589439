import React from 'react';
import {
  Container, ChildContainer, Divider, Total,
} from './Resume.styles';

export function Resume({ valores }) {
  return (
    <Container>
      <header>Resumo</header>
      <div>
        <ChildContainer>
          <p>Valor principal:</p>
          <p>Juros e despesas:</p>
          <p>Honorários: </p>
        </ChildContainer>
        <ChildContainer alignItem="flex-end">
          <p>
            {valores.valorPrincipalProcesso}
          </p>
          <p>
            {valores.valorJurosDespesasProcesso}
          </p>
          <p>
            {valores.valorHonorarioProcesso}
          </p>
          <Divider />
          <Total>
            <span>TOTAL: </span>
            {valores.valorTotalProcesso}
          </Total>
        </ChildContainer>
      </div>
    </Container>
  );
}
