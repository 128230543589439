import { getMoneyMask, toBrazilianDate } from '../../../../../../helpers';

class LancamentosFactory {
  makeConteudoTabela(lancamentos) {
    if (!lancamentos || lancamentos.length === 0) {
      return [];
    }

    return lancamentos.map(lancamento => ({
      data: toBrazilianDate(lancamento.data),
      descricaoLancamento: lancamento.descricaoLancamento,
      historico: lancamento.historico,
      idProcesso: lancamento.idProcesso,
      valorCredito: getMoneyMask(lancamento.valorCredito),
      valorDebito: getMoneyMask(lancamento.valorDebito),
    }));
  }
}

export const lancamentosFactory = new LancamentosFactory();
