import styled from 'styled-components';

export const Container = styled.div`
  display: inline;
  & .MuiButtonBase-root {
    margin-right: 5px;
    padding: 5px;
    color: black;
  }
  & .MuiButtonBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    background-color: transparent;
  }
`;
