export const ON_CHANGE_REQUEST_FIELDS = '@historicoAS/ON_CHANGE_REQUEST_FIELDS';
export const ON_FOCUS_REQUEST_FIELDS = '@historicoAS/ON_FOCUS_REQUEST_FIELDS';
export const SET_STORE_VALUE = '@historicoAS/SET_STORE_VALUE';
export const SET_ESTATISCAS_HISTORICO = '@historicoAS/SET_ESTATISCAS_HISTORICO';
export const GET_ESTATISCAS_HISTORICO = '@historicoAS/GET_ESTATISCAS_HISTORICO';
export const PESQUISAR_DADOS_ALTERAR_PRAZO = '@historicoAS/PESQUISAR_DADOS_ALTERAR_PRAZO';
export const CONFIRMAR_DADOS_ALTERAR_PRAZO = '@historicoAS/CONFIRMAR_DADOS_ALTERAR_PRAZO';
export const PESQUISAR_HISTORICO_AS = '@historicoAS/PESQUISAR_HISTORICO_AS';
export const PESQUISAR_HISTORICO_ENTRAR_TELA_AS = '@historicoAS/PESQUISAR_HISTORICO_ENTRAR_TELA_AS';
export const SET_ROWS_PER_PAGE = '@historicoAS/SET_ROWS_PER_PAGE';
export const SET_PAGE = '@historicoAS/SET_PAGE';
export const SET_TABLE_INFO = '@historicoAS/SET_TABLE_INFO';
export const SET_SNACKBAR_HISTORICO = '@historicoAS/SET_SNACKBAR_HISTORICO';
export const ON_UPDATE_REQUEST_FIELDS = '@historicoAS/ON_UPDATE_REQUEST_FIELDS';
export const SET_CACHE_REQUEST_FIELDS = '@historicoAS/SET_CACHE_REQUEST_FIELDS';
export const SET_DATAS_CAMPOS_OBRIGATORIOS = '@historicoAS/SET_DATAS_CAMPOS_OBRIGATORIOS';
export const ON_CHANGE_DATA_EXPIRACAO_FIELDS = '@historicoAS/ON_CHANGE_DATA_EXPIRACAO_FIELDS';
export const RESET_SCREEN = '@historicoAS/RESET_SCREEN';

