import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import OrdenacaoColuna from '../../../../../components/UI/Tabela/Ordenacao/OrdenacaoColuna';

function TableHeaderPassoTres({
  arrowDirection, columnOrdenacao, setColumnOrdenacao, onClickColumnVencimento,
}) {
  return (
    <TableRow>
      <TableCell height={38} width={260} style={{ marginLeft: '20px' }}>
        Forma do Pagamento
      </TableCell>
      <TableCell height={38}>
        Nº Parcela
      </TableCell>
      <TableCell height={38}>
        Valor
      </TableCell>
      <TableCell height={38}>
        <OrdenacaoColuna
          columnLabel="Vencimento"
          idColumn="vencimentoParcela"
          arrowOrdenacao={arrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnVencimento}
        />
      </TableCell>
      <TableCell height={38}>
        Ações
      </TableCell>
    </TableRow>
  );
}
export default TableHeaderPassoTres;
