import React, { createContext } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider, createSelectorHook, createDispatchHook } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import reducer from './PagamentoDireto.store';
import watchPagamentoDireto from './PagamentoDireto.saga';

export const PagamentoDiretoContext = createContext();
export const usePagamentoDiretoSelector = createSelectorHook(PagamentoDiretoContext);
export const usePagamentoDiretoDispatch = createDispatchHook(PagamentoDiretoContext);

export default function PagamentoDiretoProvider({ children }) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(watchPagamentoDireto);

  return (
    <Provider context={PagamentoDiretoContext} store={store}>
      {children}
    </Provider>
  );
}
