import { getMoneyMask, TableUtils, toBrazilianDate } from '../../helpers';
import store from '../../store/createStore';
import ContaCorrenteHonorarioService from '../../services/core/ContaCorrenteHonorario/ContaCorrenteHonorarioService';

function getLancamentoHonorarioPageableFromStore() {
  const { pageable } = store.getState().previousPageStates.contaCorrenteHonorario;
  return {
    ...pageable,
    number: pageable?.page || 0,
    size: pageable?.size || 10,
    last: pageable?.lastPage || true,
  };
}

function makeLancamentos(httpResponse) {
  return TableUtils.makeTableItems({
    httpResponse,
    makeContentFactory: lancamento => ({
      dataLancamento: toBrazilianDate(lancamento?.dataLancamento),
      historico: lancamento?.historico || '',
      idContaCorrenteHonorario: lancamento.idContaCorrenteUsuario,
      valorCredito: getMoneyMask(lancamento?.valorCredito) || '',
      valorDebito: getMoneyMask(lancamento?.valorDebito) || '',
      estarConsolidado: lancamento?.estarConsolidado || '',
      idTipoContaCorrenteHonorario: lancamento?.idTipoContaCorrenteHonorario || '',
    }),
  });
}

export const ContaCorrenteHonorarioFactory = {
  makeLancamentos,
  defaultMakeLancamentos() {
    return makeLancamentos(getLancamentoHonorarioPageableFromStore());
  },
};

export const ContaCorrenteHonorarioController = {
  async findAllLancamentosHonorarios(fields, columnOrder, pageable) {
    const {
      period,
      exibirLancamentosNaoConsolidados,
      exibirLancamentosConsolidados,
      exibirLancamentosDeConsolidacao,
    } = fields;
    let response = await ContaCorrenteHonorarioService.findAllLancamentos(
      period,
      exibirLancamentosNaoConsolidados,
      exibirLancamentosConsolidados,
      exibirLancamentosDeConsolidacao,
      pageable,
      columnOrder.get(),
    );
    if (response.data.content.length === 0 && response.data.number > 0) {
      pageable.page -= 1;
      response = await ContaCorrenteHonorarioService.findAllLancamentos(
        period,
        exibirLancamentosNaoConsolidados,
        exibirLancamentosConsolidados,
        exibirLancamentosDeConsolidacao,
        pageable,
        columnOrder.get(),
      );
    }
    const newLancamentos = ContaCorrenteHonorarioFactory.makeLancamentos(
      response.data,
    );
    return {
      lancamentos: { ...newLancamentos },
    };
  },
  async downloadExtratoLancamentos(fields, columnOrder) {
    const { period } = fields;
    const httpResponse = await ContaCorrenteHonorarioService.downloadExtratoLancamento(
      period,
      fields.exibirLancamentosNaoConsolidados,
      fields.exibirLancamentosConsolidados,
      fields.exibirLancamentosDeConsolidacao,
      columnOrder.get(),
    );
    return httpResponse;
  },
};
