// Opções generalistas

export const PADROES_DISTRIBUICAO = {
  PADRAO_ABE: {
    label: 'Padrão ABE',
    value: 0,
  },
  PROPORCIONAL: {
    label: 'Proporcional',
    value: 1,
  },
};

//  Opções Radio Buttons especificos

export const PADRAO_DISTRIBUICAO_OPCOES_DEVEDOR = [
  { ...PADROES_DISTRIBUICAO.PADRAO_ABE },
  { ...PADROES_DISTRIBUICAO.PROPORCIONAL },
];

export const PADRAO_DISTRIBUICAO_OPCOES_TITULOS = [
  { ...PADROES_DISTRIBUICAO.PADRAO_ABE },
  { ...PADROES_DISTRIBUICAO.PROPORCIONAL },
];


// Dicas (Lampada):

export const DICA_PADRAO_DISTRIBUICAO_DEVEDOR = 'Informa se o recebimento do Devedor será distribuído proporcionalmente entre a ABE e o Cliente ou se será priorizado o recebimento dos Honorários (padrão ABE).';
export const DICA_PADRAO_DISTRIBUICAO_TITULOS = 'Informa se o recebimento do Devedor será distribuído primeiramente para amortizar o Principal ou proporcionalmente entre Principal, Juros e Multa.';
