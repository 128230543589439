import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  dialog: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '756px',
    },
  },
  checkbox: {
    paddingTop: '13px',
  },
});
