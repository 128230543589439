import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { DELETE, PESQUISAR_TEXTOS, SAVE } from './textosPadrao.constants';
import {
  onUpdateRequestFieldsAction,
  resetTextosRequestFieldsAction,
  setAlertDialogValuesAction,
  setAttributesAction,
  setStoreValuesAction,
  setTableInfoAction,
} from './textosPadrao.store';
import { RESULT_NOT_FOUND } from '../../helpers/constants/global.constants';
import {
  buscarTextos,
  cadastrarTextoPadrao,
  deleteTexto,
  editTexto,
} from '../../services/textosPadrao/textosPadrao.services';
import {
  buildPayload,
  getExceptionMessage,
  isBadRequestException,
  isNotFoundException,
} from '../../helpers/utils/utils';
import { buildEmptyEditorTexto } from '../../components/UI/EditorTexto/editorTexto.utils';
import { getExceptionHandler } from '../../helpers/utils/exception.util';


export const saveTextoAction = (isCreateMode, ordenacao) => ({
  type: SAVE,
  isCreateMode,
  ordenacao,
});

export const pesquisarTextosAction = (page, rowsPerPage, ordenacao) => ({
  type: PESQUISAR_TEXTOS,
  page,
  rowsPerPage,
  ordenacao,
});

export const deleteTextoAction = (idTextoPadrao, idTipoTextoPadrao, ordenacao) => ({
  type: DELETE,
  idTextoPadrao,
  idTipoTextoPadrao,
  ordenacao,
});

function configureSnackbarAlert(content) {
  if (content.length === 0) {
    toast.warn(RESULT_NOT_FOUND);
  }
}

function* catchSubmitException(exception, controls) {
  const [updatedControls] = yield getExceptionHandler(exception, controls);
  const exceptionMessage = getExceptionMessage(exception);
  if (isNotFoundException(exception) && exceptionMessage) {
    yield put(setAlertDialogValuesAction(true, 'error', exceptionMessage));
  }
  if (isBadRequestException(exception) && exceptionMessage) {
    toast.error(exceptionMessage, { style: { width: '392px' } });
  }
  if (updatedControls) {
    yield put(onUpdateRequestFieldsAction(updatedControls));
  }
}

function* buildTable(data) {
  yield put(setTableInfoAction(data.content, data.number, data.size, data.totalElements));
}

function* getTextosHandler(filters, ordenacao, page, rowsPerPage) {
  const {
    idTipoTextoPadrao,
  } = filters;
  let response;
  response = yield call(buscarTextos, idTipoTextoPadrao, ordenacao, page, rowsPerPage);

  if (response.data.content.length === 0 && response.data.totalElements !== 0) {
    const lastPage = yield response.data.totalPages - 1;
    response = yield call(buscarTextos, idTipoTextoPadrao, ordenacao, lastPage, rowsPerPage);
  }

  return response.data;
}

function* pesquisarTextosHandler(actions) {
  yield put(setStoreValuesAction('loading', true));
  const fields = yield select(states => states.requestFields);
  try {
    const cacheRequestFields = yield select(states => states.cacheRequestFields);
    const { page, rowsPerPage, ordenacao } = actions;
    const data = yield getTextosHandler(cacheRequestFields, ordenacao, page, rowsPerPage);
    yield buildTable(data);
    yield configureSnackbarAlert(data.content);
  } catch (exception) {
    yield catchSubmitException(exception, fields);
  } finally {
    yield put(setStoreValuesAction('loading', false));
  }
}

function* submitRequest(isCreateMode, payload) {
  return isCreateMode ? yield cadastrarTextoPadrao(payload) : yield editTexto(payload);
}

function* searchTextos(ordenacao) {
  const cacheRequestFields = yield select(states => states.cacheRequestFields);
  const { page, rowsPerPage } = yield select(states => states.settingsTable);

  const { data } = yield call(buscarTextos, cacheRequestFields ? cacheRequestFields.idTipoTextoPadrao : null, [`${ordenacao.element},${ordenacao.order}`], page, rowsPerPage);
  yield buildTable(data);
  yield put(setAttributesAction('showCrudDialog', false));
}

function getPayload(requestFields) {
  const texto = buildEmptyEditorTexto(requestFields.texto);
  return {
    ...buildPayload(requestFields),
    texto,
  };
}


function* saveTextoHandler({ ordenacao, isCreateMode = false }) {
  yield put(setAttributesAction('loading', true));
  const requestFields = yield select(state => state.requestFields);
  try {
    const payload = getPayload(requestFields);
    yield submitRequest(isCreateMode, payload);

    if (isCreateMode) {
      toast.success('Texto Padrão cadastrado com sucesso!');
      yield put(setAttributesAction('showCrudDialog', false));
    }
    yield searchTextos(ordenacao);
    yield put(resetTextosRequestFieldsAction());
  } catch (exception) {
    yield catchSubmitException(exception, requestFields);
  } finally {
    yield put(setAttributesAction('loading', false));
  }
}

function* removeTextoHandler({ idTextoPadrao, idTipoTextoPadrao, ordenacao }) {
  yield put(setAttributesAction('loading', true));
  try {
    yield call(deleteTexto, idTextoPadrao, idTipoTextoPadrao);
    yield searchTextos(ordenacao);
  } catch (exception) {
    const exceptionMessage = getExceptionMessage(exception);
    if (isNotFoundException(exception) && exceptionMessage) {
      yield put(setAlertDialogValuesAction(true, 'error', exceptionMessage));
    }
  } finally {
    yield put(setAttributesAction('loading', false));
  }
}

export default function* watchTextosPadrao() {
  yield takeLatest(PESQUISAR_TEXTOS, pesquisarTextosHandler);
  yield takeLatest(SAVE, saveTextoHandler);
  yield takeLatest(DELETE, removeTextoHandler);
}
