import { TableCell, TableRow, Tooltip } from '@material-ui/core';
import React, { useCallback } from 'react';
import { MdDeleteForever, MdEdit } from 'react-icons/md';
import { TableActionButton, TableCellCenter, TableCellRight } from '../../UI/Tabela/tableDefaultStyles';
import { LockOpenIcon } from '../../../assets/Icons/AttachFileIcon/lockOpen';
import {
  CustomTableActionButtomNaoPrestados,
  FaLockIcon,
} from '../../../pages/ContaCorrenteCliente/Accordions/NaoPrestados/LancamentosNaoPrestadosStyle';
import TooltipUI from '../../UI/Tooltip/Tooltip';
import { useCCClienteDispatch } from '../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { setSimpleValueNaoPrestadosAction } from '../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';

const statusLancamentoBloqueado = {
  BLOQUEADO: {
    icon: <FaLockIcon />,
    tooltip: 'Desbloquear Lançamento',
    type: 'normal',
    isCustom: true,
  },
  NAO_PRESTADO: {
    icon: <LockOpenIcon size={15} />,
    tooltip: 'Bloquear Lançamento',
    type: 'custom',
    isCustom: false,
  },
};


function TableBodyLancamentosNaoPrestadosComponent({
  lancamento,
  disabledPermissao,
  onEditLancamentoHandler,
}) {
  const dispatch = useCCClienteDispatch();

  const statusContaCorrenteCliente = lancamento.statusContaCorrenteCliente === 'NAO_PRESTADO' ? 'BLOQUEADO' : 'NAO_PRESTADO';

  const openDialogAtualizarStatus = useCallback((idContaCorrenteCliente) => {
    dispatch(setSimpleValueNaoPrestadosAction('idContaCorrenteClienteTable', idContaCorrenteCliente));
    dispatch(setSimpleValueNaoPrestadosAction('statusContaCorrenteClienteTable', statusContaCorrenteCliente));
    dispatch(setSimpleValueNaoPrestadosAction('openDialogAtualizarStatusLancamento', true));
  }, [dispatch, statusContaCorrenteCliente]);

  const openDialogDeleteLancamento = useCallback((idContaCorrenteCliente) => {
    dispatch(setSimpleValueNaoPrestadosAction('idContaCorrenteClienteTable', idContaCorrenteCliente));
    dispatch(setSimpleValueNaoPrestadosAction('openDialogDeleteLancamento', true));
  }, [dispatch]);

  return (
    <TableRow>
      <TableCellCenter>
        {lancamento.data}
      </TableCellCenter>
      <TableCellCenter>
        {lancamento.idProcesso}
      </TableCellCenter>
      <TableCellCenter>
        {lancamento.tipoDescricao}
      </TableCellCenter>
      <TableCell style={{
        maxWidth: '479px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
      }}
      >
        <TooltipUI title={lancamento.historicoAlt}>
          <span>
            {lancamento.historicoAlt}
          </span>
        </TooltipUI>
      </TableCell>
      <TableCellRight>
        {lancamento.valorCredito}
      </TableCellRight>
      <TableCellRight>
        {lancamento.valorDebito}
      </TableCellRight>
      <TableCellCenter>
        {!!statusLancamentoBloqueado && (
          <Tooltip title={statusLancamentoBloqueado[lancamento.statusContaCorrenteCliente]?.tooltip} placement="bottom">
            <CustomTableActionButtomNaoPrestados
              iscustom={statusLancamentoBloqueado[lancamento.statusContaCorrenteCliente]?.isCustom ? 1 : 0}
              disabled={disabledPermissao}
              onClick={() => openDialogAtualizarStatus(lancamento.idContaCorrenteCliente, statusContaCorrenteCliente)}
            >
              <span>
                {statusLancamentoBloqueado[lancamento.statusContaCorrenteCliente]?.icon}
              </span>
            </CustomTableActionButtomNaoPrestados>
          </Tooltip>
        )}
        <Tooltip title="Editar" placement="bottom">
          <span>
            <TableActionButton
              disabled={disabledPermissao || lancamento.lancamentoAutomatico === 'S'}
              onClick={() => onEditLancamentoHandler(lancamento.idContaCorrenteCliente)}
            >
              <MdEdit size={20} />
            </TableActionButton>
          </span>
        </Tooltip>
        <Tooltip title="Excluir" placement="bottom">
          <span>
            <TableActionButton
              onClick={() => openDialogDeleteLancamento(lancamento.idContaCorrenteCliente)}
              disabled={disabledPermissao || lancamento.lancamentoAutomatico === 'S'}
            >
              <MdDeleteForever size={20} />
            </TableActionButton>
          </span>
        </Tooltip>
      </TableCellCenter>
    </TableRow>
  );
}

export const TableBodyLancamentosNaoPrestados = TableBodyLancamentosNaoPrestadosComponent;
