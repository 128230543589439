import React from 'react';
import { format } from 'date-fns-tz';

import { toast } from 'react-toastify';

import { TableDefault } from '../UI/Tabela/TableDefault';

import {
  ExportButtonGroupConsultaDinamica,
  TableTitle,
} from './ConsultaDinamicaResultStyles';

import {
  config,
  downloadFile,
} from '../../helpers';
import { ConsultaDinamicaService } from '../../services';

import SubTitleForm from '../UI/SubTitleForm/SubTitleForm';
import { ButtonOutlined } from '../ButtonOutlined/ButtonOurlined';
import { TableHeaderConsultaDinamicaResult } from '../TabelaHeader/TableHeaderConsultaDinamicaResult';
import { TableBodyConsultaDinamicaResult } from '../TabelaBody/TableBodyConsultaDinamicaResult';

function ConsultaDinamicaResultComponent({
  idConsultaDinamica,
  columnsNames,
  list,
  parametersPayload,
  onChangePage,
  onChangeRowsPerPage,
  onChangeLoading,
}) {
  async function exportFiles(extension, type) {
    onChangeLoading(true);
    try {
      const httpResponse = await ConsultaDinamicaService.exportConsultaDinamica(type, {
        idConsultaDinamica,
        parametros: parametersPayload,
      });
      const date = format(new Date(), 'yyyy-MM-dd hh:mm', config); // rename config
      downloadFile(`consulta-dinamica ${date}.${extension}`, httpResponse.data);
    } catch {
      toast.error('Erro ao exportar o arquivo. Tente novamente.',
        { style: { width: '392px' } });
    } finally {
      onChangeLoading(false);
    }
  }

  return (
    <div>
      <div style={{ marginLeft: '15px' }}>
        <SubTitleForm>Exportar</SubTitleForm>
        <ExportButtonGroupConsultaDinamica>
          <ButtonOutlined onClick={() => exportFiles('xlsx', 'EXCEL')}>Excel</ButtonOutlined>
          <ButtonOutlined onClick={() => exportFiles('pdf', 'PDF')}>PDF</ButtonOutlined>
          <ButtonOutlined onClick={() => exportFiles('json', 'JSON')}>Json</ButtonOutlined>
          <ButtonOutlined onClick={() => exportFiles('xml', 'XML')}>XML</ButtonOutlined>
        </ExportButtonGroupConsultaDinamica>
        <TableTitle>Resultado da Pesquisa</TableTitle>
      </div>

      <TableDefault
        columnsHead={<TableHeaderConsultaDinamicaResult columnsNames={columnsNames} />}
        page={list.page}
        rowsPerPage={list.size}
        totalElements={list.totalElements}
        totalElementsOnPage={list.content?.length}
        totalColumns={columnsNames?.length}
        emptyRowsHeight={32}
        rowsPerPageOptions={[10, 15, 20]}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      >
        <TableBodyConsultaDinamicaResult list={list?.content} />
      </TableDefault>
    </div>
  );
}

export const ConsultaDinamicaResult = ConsultaDinamicaResultComponent;
