import React from 'react';
import useStyles from '../../../../../../UI/Honorarios/Faixa/faixaHonorario.styles';
import Grid24 from '../../../../../../UI/Grid24';
import MoneyField from '../../../../../../UI/Field/Money/MoneyField';
import NumberInput from '../../../../../../UI/Field/Number/NumberInput';

import { TrashIconContainer, TrashIconButton } from './styles';

import constants from './constants';
import { getRequiredLabel } from '../../../../../../../helpers/utils/utils';

function CustomField({
  type = 'MONEY',
  label,
  disabled = false,
  name,
  value,
  error,
  errorMessage,
  onChange,
  onFocus,
}) {
  return type === 'MONEY' ? (
    <MoneyField
      isForm
      fullWidth
      disabled={disabled}
      maxlength={16}
      label={label}
      name={name}
      value={value}
      error={error}
      errorMessage={errorMessage}
      onChange={onChange}
      onFocus={onFocus}
    />
  ) : (
    <NumberInput
      isForm
      fullWidth
      disabled={disabled}
      sideText="(dias)"
      numberType="integer"
      maxValue={999999}
      label={label}
      name={name}
      value={value}
      error={error}
      errorMessage={errorMessage}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
}

function TrashButton({
  disabled = false, showTrash = false, attributes, onRemove,
}) {
  return (
    <TrashIconContainer showtrash={(!disabled && !showTrash) ? 1 : 0} itemxs={24} sm={1}>
      <TrashIconButton
        type="trash"
        attributes={attributes}
        disabled={disabled}
        hint={constants.trash}
        onClick={onRemove}
      />
    </TrashIconContainer>
  );
}

function Faixa({
  type = 'MONEY',
  disabled = false,
  initial,
  final,
  rate,
  showTrash = false,
  idxTipoHonorario,
  idxHonorario,
  onChange,
  onFocus,
  onRemove,

}) {
  const rename = name => `${name}-${idxHonorario}`;

  const disabledFirstValue = (disabled || idxHonorario === 0);

  const styles = useStyles();

  return (
    <>
      <Grid24 container isFormContainer spacing="1" className={styles.faixaHonorario__row}>
        <span className={styles.faixaHonorario__tituloFaixa}>
          Faixa
          {' '}
          {idxHonorario + 1}
          :
        </span>
        <Grid24 item xs={24} sm={4}>
          <CustomField
            type={type}
            label={getRequiredLabel(constants.from, !disabledFirstValue)}
            disabled={disabledFirstValue}
            name={rename(initial.name)}
            value={initial.value}
            error={initial.error}
            errorMessage={initial.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>

        <Grid24 item xs={24} sm={4}>
          <CustomField
            type={type}
            label={getRequiredLabel(constants.until, !disabled)}
            disabled={disabled}
            name={rename(final.name)}
            value={final.value}
            error={final.error}
            errorMessage={final.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>

        <Grid24 item xs={24} sm={3}>
          <NumberInput
            isForm
            fullWidth
            disabled={disabled}
            sideText="(%)"
            numberType="percantage"
            maxValue={100}
            label={getRequiredLabel(constants.rate, !disabled)}
            name={rename(rate.name)}
            value={rate.value}
            error={rate.error}
            errorMessage={rate.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>

        <TrashButton
          showTrash={showTrash}
          disabled={disabled}
          attributes={{ idxHonorario, idxTipoHonorario }}
          onRemove={onRemove}
        />
      </Grid24>
    </>
  );
}

export default Faixa;
