import React, {
  useState, useCallback, useEffect, useRef, useImperativeHandle, useLayoutEffect,
} from 'react';
import { v4 as uuid } from 'uuid';
import { FaSearch, FaEraser } from 'react-icons/fa';

import { Typography } from '@material-ui/core';
import {
  ActionsButtons,
  BottomGrid,
  Container,
  ClearButtonAcompanhamento,
  SearchButton,
  AcompanhamentoGrid,
  Form,
  FieldsContainer,
  VisualizarAPartirDeContainer,
} from './AcompanhamentoStyles';

import { AcompanhamentoProcesso } from '../../../components/UI/AcompanhamentoProcesso/AcompanhamentoProcesso';

import CheckboxUI from '../../../components/UI/Field/Checkbox/CheckboxField';
import DateField from '../../../components/UI/Field/Date/DateField';
import InputField from '../../../components/UI/Field/Input';
import Grid24 from '../../../components/UI/Grid24/Grid24';
import SelectorField from '../../../components/UI/Field/Selector/SelectorField';
import { clearErrorField } from '../../../helpers/utils/form.utils';

import { inspecionarProcessoPageController as controller } from '../inspecaoProcessoController';
import { loadingInspecaoProcessoController as loadingController } from '../loadingInspecaoProcessoController';

import AlertDialog from '../../../components/UI/Dialogs/AlertDialog/AlertDialog';


function AcompanhamentoComponent({
  processo,
  tiposAcompanhamento = [],
  autores = [],
  fases = [],
  numeroDias,
  setLoadings,
  podeModificarFase = false,
}, ref) {
  const acompanhamentoRef = useRef();
  const formRef = useRef();

  const [acompanhamentos, setAcompanhamentos] = useState({});
  const [dropdowns, setDropdowns] = useState(controller.makeDefaultDropdownAcompanhamento());

  const [dataCadastramento, setDataCadastramento] = useState(controller.makeDefaultDataCadastramento(numeroDias));

  const [visualizarDataCadastramento, setVisualizarDataCadastramento] = useState({ key: uuid(), value: true });
  const [loadingAcompanhamento, setLoadingAcompanhamento] = useState(false);
  const [warningDialog, setWarningDialog] = useState({ open: false, message: '' });
  const [errors, setErrors] = useState(controller.makeDefaultAcompanhamentoSearchErrors());


  function moveScrollToTop() {
    acompanhamentoRef.current.scrollToTop();
  }

  function closeWarningDialog() {
    setWarningDialog({ ...warningDialog, open: false });
  }

  const findAcompanhamentosByFilters = useCallback(async (formElement, dataCadastro) => {
    setLoadings(loadingController.changeLoadingAcompanhamento(true));
    const data = await controller.findAcompanhamentosWhenSubmit(processo.id, formElement, dataCadastro, errors);
    if (data.error) {
      setErrors(data.error);
    }
    setAcompanhamentos(data.acompanhamentos);
    acompanhamentoRef.current.scrollToTop();
    setLoadings(loadingController.changeLoadingAcompanhamento(false));
  }, [processo, errors, setLoadings]);

  async function onReset() {
    setVisualizarDataCadastramento({ key: uuid(), value: false });
    setDataCadastramento('');
    setErrors(controller.makeDefaultAcompanhamentoSearchErrors());
    setDropdowns(controller.makeDefaultDropdownAcompanhamento());
    setAcompanhamentos(await controller.findAcompanhementWhenReset(processo.id));
    acompanhamentoRef.current.scrollToTop();
  }

  async function onSubmit(e) {
    e.preventDefault();
    await findAcompanhamentosByFilters(e.target, dataCadastramento);
  }

  async function addNextAcompanhamentos() {
    setLoadingAcompanhamento(true);
    setAcompanhamentos(await controller.findAcompanhamentoWhenScrolled(processo.id, acompanhamentos));
    setLoadingAcompanhamento(false);
  }


  async function onCheckedAcompanhamento(e) {
    e.persist();
    setLoadings(loadingController.changeLoadingAcompanhamento(true));
    const { name: idProcessoAcompanhamento, checked } = e.target;
    const updateReturn = await controller.udpateAcompanhamentosWhenChecked(processo.id, idProcessoAcompanhamento, checked);
    if (updateReturn.error) {
      setWarningDialog({ open: true, message: updateReturn.error });
    } else {
      acompanhamentoRef.current.scrollToTop();
      setAcompanhamentos(updateReturn.acompanhamentos);
    }
    e.target.checked = updateReturn.checked;
    setLoadings(loadingController.changeLoadingAcompanhamento(false));
  }

  const onChangetVisualizarData = useCallback(async (e) => {
    const { checked } = e.target;
    let data = '';
    if (checked) {
      data = controller.makeDefaultDataCadastramento(numeroDias);
    }
    setDataCadastramento(data);
    setVisualizarDataCadastramento({ ...visualizarDataCadastramento, value: checked });
    await findAcompanhamentosByFilters(formRef.current, data);
  }, [numeroDias, visualizarDataCadastramento, findAcompanhamentosByFilters]);

  const onChangeData = useCallback((e) => {
    setDataCadastramento(e.target.value);
  }, []);

  const onChangeDropdown = useCallback((e) => {
    const { name, value } = e.target;
    setDropdowns(old => ({ ...old, [name]: value }));
  }, []);

  const clearError = useCallback((e) => {
    const { name } = e.target;
    setErrors(clearErrorField(errors, name));
  }, [errors]);

  useImperativeHandle(ref, () => ({
    moveScrollToTop,
  }));

  useLayoutEffect(() => {
    if (!numeroDias) {
      return;
    }
    const dataCadastro = controller.makeDefaultDataCadastramento(numeroDias);
    setDataCadastramento(dataCadastro);
  }, [numeroDias]);

  useEffect(() => {
    if (!processo.id || !numeroDias) {
      return;
    }

    setLoadings(loadingController.changeLoadingAcompanhamento(true));
    controller.findAcompanhamentosWhenSubmit(processo.id, formRef.current, dataCadastramento)
      .then((res) => {
        setAcompanhamentos(res.acompanhamentos);
      }).catch(() => {
        setAcompanhamentos({});
      }).finally(() => {
        setLoadings(loadingController.changeLoadingAcompanhamento(false));
      });
  }, [processo, numeroDias, setLoadings, dataCadastramento]);

  return (
    <Container>
      <AlertDialog
        open={warningDialog.open}
        variant="warning"
        onClickConfirm={closeWarningDialog}
      >
        <Typography>{warningDialog.message}</Typography>
      </AlertDialog>
      <Form onReset={onReset} onSubmit={onSubmit} ref={formRef}>
        <FieldsContainer>
          <Grid24 container spacing="1">
            <VisualizarAPartirDeContainer container item xs={24} sm={9}>
              <div style={{ width: '131px' }}>
                <CheckboxUI
                  key={visualizarDataCadastramento.key}
                  checked={visualizarDataCadastramento.value}
                  label="Visualizar a partir de"
                  onChange={onChangetVisualizarData}
                />
              </div>
              <div style={{ width: 'calc(100% - 131px)' }}>
                <DateField
                  isForm
                  maxDate={new Date()}
                  fullWidth
                  disabled={!visualizarDataCadastramento}
                  name="dataCadastramento"
                  value={dataCadastramento}
                  error={errors.dataCadastramento.error}
                  errorMessage={errors.dataCadastramento.errorMessage}
                  onChange={onChangeData}
                  onFocus={clearError}
                />
              </div>
            </VisualizarAPartirDeContainer>
            <Grid24 container item xs={24} sm={7}>
              <SelectorField
                hasEmptyLabel
                fullWidth
                label="Tipos de Acompanhamento"
                items={tiposAcompanhamento}
                name="idTipoAcompanhamento"
                value={dropdowns.idTipoAcompanhamento}
                onChange={onChangeDropdown}
              />
            </Grid24>
            <Grid24 container item xs={24} sm={8}>
              <SelectorField
                hasEmptyLabel
                fullWidth
                label="Fase"
                items={fases}
                name="idFase"
                value={dropdowns.idFase}
                onChange={onChangeDropdown}
              />
            </Grid24>
          </Grid24>
          <BottomGrid container spacing="1">
            <Grid24 container item xs={24} sm={16}>
              <SelectorField
                hasEmptyLabel
                fullWidth
                label="Autor"
                items={autores}
                name="idUsuario"
                value={dropdowns.idUsuario}
                onChange={onChangeDropdown}
              />
            </Grid24>
            <Grid24 container item xs={24} sm={8}>
              <InputField
                fullWidth
                label="Conteúdo"
                name="conteudo"
              />
            </Grid24>
          </BottomGrid>
        </FieldsContainer>
        <ActionsButtons>
          <SearchButton type="submit">
            <FaSearch size={18} />
          </SearchButton>
          <ClearButtonAcompanhamento type="reset" variant="outlined">
            <FaEraser size={18} />
            <span>Limpar</span>
          </ClearButtonAcompanhamento>
        </ActionsButtons>
      </Form>
      <AcompanhamentoGrid>
        <AcompanhamentoProcesso
          ref={acompanhamentoRef}
          isDisbaledChangePin={!podeModificarFase}
          showLoading={loadingAcompanhamento}
          acompanhamentos={acompanhamentos}
          addNextAcompanhamentos={addNextAcompanhamentos}
          onChangeChecked={onCheckedAcompanhamento}
        />
      </AcompanhamentoGrid>
    </Container>
  );
}
export const Acompanhamento = React.memo(React.forwardRef(AcompanhamentoComponent));
