import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Paper, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import Grid24 from '../../../../UI/Grid24/Grid24';
import useUtilStyles from '../../../../../helpers/styles/utils.styles';
import {
  useCrudClienteDispatch,
  useCrudClienteSelector,
} from '../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import TableHeader from '../../../../../models/TableHeader';
import useStyles from './TabelaEmpresasGrupo.style';
import { checkPermission, formatCpfCnpj } from '../../../../../helpers/utils/utils';
import { updateClienteAction } from '../../../../../store/Cliente/V2/Crud/crudCliente.saga';
import { openOptionalDialogAction } from '../../../../../store/Cliente/V2/Crud/crudCliente.store';
import TooltipUI from '../../../../UI/Tooltip/Tooltip';
import AlertDialog from '../../../../UI/Dialogs/AlertDialog/AlertDialog';
import { BoldContentDialog } from '../../../../UI/Dialogs/Content/Bold/BoldContentDialog';
import { OPTIONAL_DIALOG, ERROR_REDIRECT_DIALOG } from './tableEmpresasGrupo.constants';
import OptionDialogNew from '../../../../UI/Dialogs/OptionDialog/OptionDialogNew';

function TableEmpresasGrupo({
  isCliente,
  isEditMode,
  history,
  isMigrarParaCliente,
}) {
  const utilStyles = useUtilStyles();
  const styles = useStyles();
  const dispatch = useCrudClienteDispatch();

  const permissoesUsuario = useSelector(states => (states.authentication.userInformation ? states.authentication.userInformation.permissoes : []));
  const empresasDoGrupo = useCrudClienteSelector(states => states.empresasGrupo);
  const optionalDialog = useCrudClienteSelector(states => states.actions.optionalDialogContent);
  const controls = useCrudClienteSelector(states => states.controls);
  const copyForCheckEmpresasGrupo = useCrudClienteSelector(states => states.copyForCheckEmpresasGrupo);
  const [selectedCliente, setSelectedCliente] = useState({
    idCliente: -1,
    serCliente: isCliente,
  });
  const [errorRedirect, setErrorRedirect] = useState(false);

  const [columns] = useState([
    new TableHeader({
      id: 'idCliente', label: 'Código', alignCell: 'center',
    }),
    new TableHeader({
      id: 'nome', label: 'Nome', showTip: true, minWidth: 'auto', width: 157, maxWidth: 157,
    }),
    new TableHeader({
      id: 'nomeFantasia', label: 'Nome Fantasia', showTip: true, width: 157, maxWidth: 157,
    }),
    new TableHeader({
      id: 'documento', label: 'CPF / CNPJ', minWidth: 'auto', alignCell: 'center',
    }),
    new TableHeader({ id: 'cidade', label: 'Cidade', minWidth: 'auto' }),
    new TableHeader({
      id: 'idEstado', label: 'Estado', minWidth: 'auto', alignCell: 'center',
    }),
  ]);

  const rows = empresasDoGrupo.map(empresaGrupo => ({
    id: empresaGrupo.idCliente,
    values: {
      idCliente: empresaGrupo.idCliente,
      nome: empresaGrupo.nome,
      nomeFantasia: empresaGrupo.nomeFantasia,
      documento: formatCpfCnpj(empresaGrupo.documento),
      cidade: empresaGrupo.cidade || '-',
      idEstado: empresaGrupo.idEstado || '-',
      serCliente: empresaGrupo.serCliente,
    },
  }));

  const isDifferentForm = () => JSON.stringify(controls) !== copyForCheckEmpresasGrupo;

  const redirectForAction = (idCliente, serCliente) => {
    const recurso = serCliente ? 'cliente' : 'possivel-cliente';
    return (isEditMode)
      ? history.replace(`/${recurso}/${idCliente}/editar`)
      : history.replace(`/${recurso}/${idCliente}/detalhar`);
  };

  const makeOptionalDialogConten = (open) => {
    dispatch(openOptionalDialogAction({
      open,
      message: OPTIONAL_DIALOG.MESSAGE_INFORMATION,
    }));
  };

  const closeOptionalDialogAndLoadClienteSelected = () => {
    const { idCliente, serCliente } = selectedCliente;
    makeOptionalDialogConten(false);
    redirectForAction(idCliente, serCliente);
  };

  const closeOptionalDialogAndGetClientHandler = () => {
    closeOptionalDialogAndLoadClienteSelected();
  };

  // const closeOptionalDialogHandler = () => {
  // makeOptionalDialogConten(false);
  // };

  const openOptionalDialoghandler = () => {
    makeOptionalDialogConten(true);
  };

  const closeRedirectDialogHandler = () => {
    setErrorRedirect(false);
  };

  const openRedirectDialoghandler = () => {
    setErrorRedirect(true);
  };

  const onSaveChanges = () => {
    dispatch(updateClienteAction(isCliente, closeOptionalDialogAndLoadClienteSelected));
  };

  const onClickEmpresaGrupoHander = (row) => {
    const idPermission = isEditMode ? 504 : 501;
    if (checkPermission(permissoesUsuario, idPermission)) {
      openRedirectDialoghandler();
    } else if (!isMigrarParaCliente) {
      const { idCliente, serCliente } = row.values;

      setSelectedCliente({
        idCliente,
        serCliente,
      });
      if (isEditMode && isDifferentForm()) {
        openOptionalDialoghandler();
      } else {
        redirectForAction(idCliente, serCliente);
      }
    }
  };

  const getContent = (value, column) => {
    if (column.showTip) {
      return (
        <TooltipUI title={value} placement="bottom-start" interactive>
          <div className={styles.fitContent}>
            <pre
              className={styles.overFlowText}
              style={{ maxWidth: column.maxWidth - 16 }}
            >
              {column.format && typeof value === 'number' ? column.format(value) : value}
            </pre>
          </div>
        </TooltipUI>
      );
    }
    return (
      <pre>
        {column.format && typeof value === 'number' ? column.format(value) : value}
      </pre>
    );
  };

  const buildTableRow = row => (
    <TableRow
      onClick={() => onClickEmpresaGrupoHander(row)}
      hover
      selected={(selectedCliente.idCliente === row.values.idCliente)}
      key={row.id}
    >
      {columns.map((column) => {
        const value = row.values[column.id];
        return (
          <TableCell
            key={column.id}
            align={column.alignCell}
            style={{ textAlign: value === '-' ? 'center' : 'auto' }}
          >
            {getContent(value, column)}
          </TableCell>
        );
      })}
    </TableRow>
  );

  return (
    <>
      <AlertDialog
        open={errorRedirect}
        variant="error"
        onClickConfirm={closeRedirectDialogHandler}
      >
        <BoldContentDialog fullyMessage={isEditMode
          ? ERROR_REDIRECT_DIALOG.MESSAGE_EDITAR_CLIENTE
          : ERROR_REDIRECT_DIALOG.MESSAGE_CONSULTAR_CLIENTE}
        />
      </AlertDialog>
      <OptionDialogNew
        open={optionalDialog.open}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={closeOptionalDialogAndGetClientHandler}
        onClickConfirm={onSaveChanges}
        text={optionalDialog.message}
      />
      <Grid24 container isFormContainer spacing={1} className={utilStyles.mt16}>
        <Grid24 item xs={24} sm={24}>
          <Paper className={styles.wrapper}>
            <Table
              stickyHeader
              size="small"
            >
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      id={column.id}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => buildTableRow(row))}
              </TableBody>
            </Table>
          </Paper>
        </Grid24>
      </Grid24>
    </>
  );
}

export default React.memo(withRouter(TableEmpresasGrupo));
