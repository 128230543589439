import React, { createContext } from 'react';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createDispatchHook, createSelectorHook, Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import pesquisaTituloCompromissoReducer from './Pesquisa/pesquisaTituloCompromisso.store';
import crudTituloCompromissoReducer from './Crud/crudTituloCompromisso.store';
import watchPesquisaTituloCompromisso from './Pesquisa/pesquisaTituloCompromisso.saga';
import watchCrudTituloCompromisso from './Crud/crudTituloCompromisso.saga';


export const titulosCompromissoContext = createContext();
export const useTitulosCompromissoSelector = createSelectorHook(titulosCompromissoContext);
export const useTitulosCompromissoDispatch = createDispatchHook(titulosCompromissoContext);

export default function TituloCompromissoProvider({ children }) {
  const sagaMiddleware = createSagaMiddleware();

  const reducers = combineReducers({
    pesquisaTituloCompromisso: pesquisaTituloCompromissoReducer,
    crudTituloCompromisso: crudTituloCompromissoReducer,
  });

  const store = createStore(reducers, applyMiddleware(sagaMiddleware));

  sagaMiddleware.run(watchPesquisaTituloCompromisso);
  sagaMiddleware.run(watchCrudTituloCompromisso);

  return (
    <Provider context={titulosCompromissoContext} store={store}>
      {children}
    </Provider>
  );
}
