import React from 'react';
import { useSelector } from 'react-redux';
import Grid24 from '../../Grid24/Grid24';
import MoneyField from '../../Field/Money/MoneyField';
import {
  FIELD_INITIAL, FIELD_FINAL, TRASH_HINT, PORCENTAGEM_COMISSAO, PORCENTAGEM_PAGTO_DIRETO, LABEL_REGRA,
} from './faixaComissao.constans';
import IconButtonTooltip from '../../IconButtonTooltip/IconButtonTooltip';
import NumberInput from '../../Field/Number/NumberInput';
import { getRequiredLabel } from '../../../../helpers/utils/utils';
import useStyles from './faixaComissao.styles';
import SelectorField from '../../Field/Selector';

function FaixaComissao({
  type = 'MONEY',
  idxComissao,
  priority = false,
  idxTipoComissao,
  initialValue,
  finalValue,
  porcentagemComissao,
  porcentagemPagtoDiretoComissao,
  showTrash = false,
  disabled = false,
  onRemove,
  onChange,
  onFocus,
  idTipoRegraComissaoComercial,
}) {
  const styles = useStyles();
  const rename = name => `${name}-${idxTipoComissao}-${idxComissao}`;
  const isDisabledInitialField = () => disabled || priority;

  const tipoComissao = useSelector(states => states.selectors.tipoComissao.selector);

  return (
    <>
      <Grid24 container isFormContainer spacing="1">
        {type === 'MONEY' && (
          <Grid24 item xs={24} sm={4}>
            <MoneyField
              isForm
              fullWidth
              disabled={isDisabledInitialField()}
              maxlength={16}
              label={getRequiredLabel(FIELD_INITIAL, !isDisabledInitialField())}
              name={rename(initialValue.name)}
              value={initialValue.value}
              error={initialValue.error}
              errorMessage={initialValue.errorMessage}
              onChange={onChange}
              onFocus={onFocus}
            />
          </Grid24>
        )}
        {type === 'DIAS' && (
          <Grid24 item xs={24} sm={4}>
            <NumberInput
              isForm
              fullWidth
              sideText="(dias)"
              numberType="integer"
              maxValue={999999}
              disabled={isDisabledInitialField()}
              label={getRequiredLabel(FIELD_INITIAL, !isDisabledInitialField())}
              name={rename(initialValue.name)}
              value={initialValue.value}
              error={initialValue.error}
              errorMessage={initialValue.errorMessage}
              onChange={onChange}
              onFocus={onFocus}
            />
          </Grid24>
        )}
        {type === 'MONEY' && (
          <Grid24 item xs={24} sm={4}>
            <MoneyField
              isForm
              fullWidth
              disabled={disabled}
              maxlength={16}
              label={getRequiredLabel(FIELD_FINAL, !disabled)}
              name={rename(finalValue.name)}
              value={finalValue.value}
              error={finalValue.error}
              errorMessage={finalValue.errorMessage}
              onChange={onChange}
              onFocus={onFocus}
            />
          </Grid24>
        )}
        {type === 'DIAS' && (
          <Grid24 item xs={24} sm={4}>
            <NumberInput
              isForm
              fullWidth
              sideText="(dias)"
              numberType="integer"
              maxValue={999999}
              disabled={disabled}
              label={getRequiredLabel(FIELD_FINAL, !disabled)}
              name={rename(finalValue.name)}
              value={finalValue.value}
              error={finalValue.error}
              errorMessage={finalValue.errorMessage}
              onChange={onChange}
              onFocus={onFocus}
            />
          </Grid24>
        )}
        <Grid24 item xs={24} sm={4}>
          <NumberInput
            fullWidth
            isForm
            disabled={disabled}
            sideText="(%)"
            numberType="percentage"
            maxValue={100}
            label={getRequiredLabel(PORCENTAGEM_COMISSAO, !disabled)}
            name={rename(porcentagemComissao.name)}
            value={porcentagemComissao.value}
            error={porcentagemComissao.error}
            errorMessage={porcentagemComissao.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>
        <Grid24 item xs={24} sm={4}>
          <NumberInput
            fullWidth
            isForm
            disabled={disabled}
            sideText="(%)"
            numberType="percentage"
            maxValue={100}
            label={getRequiredLabel(PORCENTAGEM_PAGTO_DIRETO, !disabled)}
            name={rename(porcentagemPagtoDiretoComissao.name)}
            value={porcentagemPagtoDiretoComissao.value}
            error={porcentagemPagtoDiretoComissao.error}
            errorMessage={porcentagemPagtoDiretoComissao.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>
        <Grid24 item xs={24} sm={6}>
          <SelectorField
            isForm
            disabled={disabled}
            label={getRequiredLabel(LABEL_REGRA, !disabled)}
            items={tipoComissao}
            onChange={onChange}
            name={rename(idTipoRegraComissaoComercial.name)}
            value={idTipoRegraComissaoComercial.value}
          />
        </Grid24>
        {/* TODO: disabled IconButtonTooltip */}
        {!showTrash && !disabled && (
          <Grid24 item xs={24} sm={2} justify="end" className={styles.faixaComissao__trashContainer}>
            <IconButtonTooltip
              type="trash"
              attributes={{ idxComissao, idxTipoComissao }}
              disabled={disabled}
              hint={TRASH_HINT}
              onClick={onRemove}
              tooltipProps={{ className: styles.faixaComissao__trashButton }}
            />
          </Grid24>
        )}
      </Grid24>
    </>
  );
}

export default React.memo(FaixaComissao);
