import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './customLabel.styles';

function CustomLabel({ title, value }) {
  const styles = useStyles();
  return (
    <div className={styles.div__label}>
      <div className={styles.label_title}>
        <Typography component="p">{title}</Typography>
      </div>
      <div className={styles.label_value}>
        <Typography component="p">{value}</Typography>
      </div>
    </div>
  );
}

export default CustomLabel;
