import { getUriParams } from '../../../helpers';
import {
  CORE, gateway, RELATORIOS,
} from '../../api';

export default {
  desmarcarLidodadosGeracaoNF(
    idNFClienteRemessa,
  ) {
    return gateway.put(`${CORE}/conta-corrente-cliente/recebimento/desmarcar/nota-fiscal-cliente-remessa?idNotaFiscalClienteRemessa=${idNFClienteRemessa}`);
  },
  findDadosGeracaoNF(
    listarUltimosTrintaDias,
    listarSomenteNaoConcluidos,
    pageable,
    order,
  ) {
    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    const queryParam = getUriParams(pageable);
    return gateway.get(`${CORE}/conta-corrente-cliente/recebimento/buscar-geracao-nota-fiscal/${listarUltimosTrintaDias}/${listarSomenteNaoConcluidos}?${queryParam}${sortedQuery}`);
  },
  downloadRecebimentos(payload) {
    const body = {
      idCliente: payload.idCliente,
      idPrestacao: payload.idPrestacao,
      idResponsavel: payload.idResposavel,
      periodoInicial: payload.periodoInicial,
      periodoFinal: payload.periodoFinal,
      somentePadrao: payload.somentePadrao,

    };
    return gateway.post(`${RELATORIOS}/conta-corrente-cliente/relatorio/cliente/recebimento?sort=${payload.ordenacao.element},${payload.ordenacao.order}`,
      body,
      {
        responseType: 'blob',
      });
  },

  agruparRecebimentos(filteredIds) {
    const payload = filteredIds;
    return gateway.post(`${CORE}/conta-corrente-cliente/recebimento/agrupamento-rps`, payload);
  },

  findAllRecebimentos(
    payload,
    pageable,
    order,
  ) {
    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    const queryParam = getUriParams(pageable);
    return gateway.post(`${CORE}/conta-corrente-cliente/recebimento/buscar-recebimentos?${queryParam}${sortedQuery}`, payload);
  },

  findAllRecebimentosMaximo(
    payload,
    order,
  ) {
    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    return gateway.post(`${CORE}/conta-corrente-cliente/recebimento/buscar-recebimentos?size=${Number(2147483647)}${sortedQuery}`, payload);
  },

  findIdsRecebimentos(
    payload,
  ) {
    return gateway.post(`${CORE}/conta-corrente-cliente/recebimento/ids-recebimentos`, payload);
  },

  emitirRps(
    listaDeIds,
  ) {
    return gateway.post(`${CORE}/conta-corrente-cliente/recebimento/emitissao-rps`, listaDeIds);
  },

  downloadArquivoRemessa(idNotaFiscalClienteRemessa) {
    return gateway.get(`${CORE}/conta-corrente-cliente/recebimento/download-nota-fiscal/${idNotaFiscalClienteRemessa}`);
  },

  gerarPDFvisualizacaoRemessa(
    idNFClienteRemessa,
  ) {
    return gateway.get(`${RELATORIOS}/conta-corrente-cliente/relatorio/cliente/remessas/${idNFClienteRemessa}`, { responseType: 'blob' });
  },

  importarRemessa(
    arquivoRemessaRetorno, grupoPrefeituraNotaFiscal,
  ) {
    const data = new FormData();
    data.append('arquivoRemessaRetorno', arquivoRemessaRetorno);
    data.append('grupoPrefeituraNotaFiscal', grupoPrefeituraNotaFiscal);
    return gateway.post(`${CORE}/conta-corrente-cliente/recebimento/importar-remessa`, data);
  },
};
