import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { MdArrowDropDown } from 'react-icons/md';
import {
  useUtilStyles,
} from '../../helpers';
import {
  AltTableRow, TableCellCenter, TableCellLeft, TableCellRight,
} from '../UI/Tabela/tableDefaultStyles';
import { CollapseTableButton } from '../CollapseTableButton/CollapseTableButton';
import { CollapseTableRow } from '../CollapseTableRow/CollapseTableRow';

function TableBodyListaRecalculoProcessoComponent({ recalculo }) {
  const height = 42;
  const styles = useUtilStyles();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <AltTableRow key={recalculo.key}>
        <TableCellCenter height={height}>{recalculo.dataRecalculo}</TableCellCenter>
        <TableCellCenter height={height}>{recalculo.dataBaseRecalculo}</TableCellCenter>
        <TableCellLeft height={height}>{recalculo.nomeFase}</TableCellLeft>
        <TableCellLeft height={height}>{recalculo.nomeUsuario}</TableCellLeft>
        <TableCellLeft height={height}>{recalculo.motivoRecalculo}</TableCellLeft>
        <TableCellLeft height={height}>{recalculo.descricaoTipoDesconto}</TableCellLeft>
        <TableCellRight height={height}>{recalculo.valorTotal}</TableCellRight>
        <TableCellCenter height={height}>
          <CollapseTableButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}><MdArrowDropDown /></CollapseTableButton>
        </TableCellCenter>
      </AltTableRow>
      <CollapseTableRow isOpen={isOpen} key={`${recalculo.key}-collapse`}>
        <Grid container justifyContent="space-between" alignItems="center" style={{ padding: '13px 28px 7px' }}>
          <Grid item xs={12} sm={3} container direction="column">
            <Grid item>
              <Typography class={styles.fontSize12Typography}>
                <b>Juros: </b>
                {recalculo.porcentagemTaxaJuros}
              </Typography>
            </Grid>
            <Grid item>
              <Typography class={styles.fontSize12Typography}>
                <b>Valor do Juros: </b>
                {recalculo.valorJuros}
              </Typography>
            </Grid>
            <Grid item>
              <Typography class={styles.fontSize12Typography}>
                <b>Tipo de Juros: </b>
                {recalculo.utilizarJurosSimples}
              </Typography>
            </Grid>
            <Grid item>
              <Typography class={styles.fontSize12Typography}>
                <b>Juros Único: </b>
                {recalculo.calcularJurosUnico}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={3} container direction="column">
            <Grid item>
              <Typography class={styles.fontSize12Typography}>
                <b>Honorários: </b>
                {recalculo.porcentagemHonorarios}
              </Typography>
            </Grid>
            <Grid item>
              <Typography class={styles.fontSize12Typography}>
                <b>Valor dos Honorários: </b>
                {recalculo.valorHonorarios}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={3} container direction="column">
            <Grid item>
              <Typography class={styles.fontSize12Typography}>
                <b>Descontos: </b>
                {recalculo.porcentagemDesconto}
              </Typography>
            </Grid>
            <Grid item>
              <Typography class={styles.fontSize12Typography}>
                <b>Valor do Desconto: </b>
                {recalculo.valorDesconto}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3} container direction="column">
            <Grid item>
              <Typography class={styles.fontSize12Typography}>
                <b>Taxa do cartão: </b>
                {recalculo.porcentagemTaxaCartao}
              </Typography>
            </Grid>
            <Grid item>
              <Typography class={styles.fontSize12Typography}>
                <b>Valor da Taxa do cartão: </b>
                {recalculo.valorTaxaCartao}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CollapseTableRow>
    </>
  );
}

export const TableBodyListaRecalculoProcesso = TableBodyListaRecalculoProcessoComponent;
