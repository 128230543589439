import React, { useMemo, useState } from 'react';
import {
  TableCell, TablePagination, TableRow,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import {
  TableWithAccordion,
} from './RecebimentoClienteStyles';
import { useUtilStyles } from '../../../../helpers';
import TooltipUI from '../../../../components/UI/Tooltip/Tooltip';
import PaginationActions from '../../../../components/UI/Tabela/PaginadaSimples/Pagination/PaginationActions';


function getAlignOption(row, column) {
  let alignOption = row.values[column.id] ? column.alignCell : 'center';
  if (!row.values[column.id] && column.showTip) {
    alignOption = 'center';
  }
  return alignOption;
}

function Row({
  row, columns, columnLength,
}) {
  const utilStyles = useUtilStyles();
  const [expand, setExpanded] = useState(false);

  const getContent = (value, column) => {
    if (column.showTip) {
      return (
        <TooltipUI title={value} placement="bottom" interactive>
          <pre
            className={utilStyles.overFlowText}
            style={{ maxWidth: column.maxWidth }}
          >
            {column.format && typeof value === 'number' ? column.format(value) : value}
          </pre>
        </TooltipUI>
      );
    }
    if (column.hiddenFullLine) {
      return (
        <pre
          className={utilStyles.overFlowText}
          style={{ maxWidth: column.maxWidth - 16 }}
        >
          {column.format && typeof value === 'number' ? column.format(value) : value}
        </pre>
      );
    }
    return <pre>{column.format && typeof value === 'number' ? column.format(value) : value}</pre>;
  };

  return (
    <>
      <TableRow className={utilStyles.tableRowRoot}>
        {columns.map((column) => {
          const value = row.values[column.id];
          const alignOption = getAlignOption(row, column);
          return (
            <TableCell
              key={column.id}
              style={{
                maxWidth: column.maxWidth,
                minWidth: column.minWidth,
                width: column.width,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: alignOption,
                }}
              >

                {getContent(value, column)}
              </div>
            </TableCell>
          );
        })}
        <TableCell style={{ borderTop: '1px solid #e0e0e0' }}>
          <IconButton size="small" onClick={() => setExpanded(old => !old)} style={{ color: '#034ddc' }}>
            {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={columnLength} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={expand} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div style={{
                display: 'flex', gap: '100px', alignItems: 'center', marginBottom: '20px',
              }}
              >
                <p>
                  <b>Responsável:</b>
                  {row.values.nomeUsuario}
                </p>
                <p>
                  <b>Conta Corrente: </b>
                  {`${row.values.nomeBanco}${' '}-${' '}${row.values.agencia}${' '}-${' '}${row.values.contaNumero}`}
                </p>
                <p>
                  <b>Nosso Número: </b>
                  {row.values.nossoNumero}
                </p>
                <p>
                  <b>Valor Original:</b>
                  {row.values.valorOriginal}
                </p>
              </div>
              <div style={{ display: 'flex', gap: '176px', alignItems: 'center' }}>
                <p>
                  <b>Código: </b>
                  {row.values.codigoOcorrencia}
                </p>
                <p>
                  <b>Observações:</b>
                  {row.values.observacao}
                </p>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function TabelaRecebimentoClientes({
  columns = [],
  rows = [],
  size = 0,
  rowsPerPageOptions = [10, 15, 20],
  page = 0,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
}) {
  const columnLength = useMemo(() => columns.length + 1, [columns.length]);

  const buildEmptyRows = () => {
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

    const content = [];
    for (let i = 0; i < emptyRows; i += 1) {
      content.push(
        <TableRow key={i} style={{ height: 31 }}>
          <TableCell colSpan={columnLength}>
            <p />
          </TableCell>
        </TableRow>,
      );
    }
    return content;
  };

  return (
    <>
      <TableWithAccordion>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  id={column.id}
                  key={column.id}
                  style={{
                    maxWidth: column.maxWidth,
                    minWidth: column.minWidth,
                    width: column.width,
                    textAlign: column.align,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <Row
                key={row.id}
                row={row}
                columns={columns}
                columnLength={columnLength}
              />
            ))}
            {buildEmptyRows()}
          </TableBody>
        </Table>
      </TableWithAccordion>
      <TablePagination
        style={{ width: '100%' }}
        rowsPerPageOptions={rowsPerPageOptions}
        colSpan={columns.length}
        component="div"
        count={size}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Linhas por página"
        backIconButtonProps={{
          'aria-label': 'Página Anterior',
        }}
        nextIconButtonProps={{
          'aria-label': 'Próxima Página',
        }}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        ActionsComponent={PaginationActions}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      />
    </>
  );
}

export default TabelaRecebimentoClientes;
