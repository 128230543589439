
class AcaoAlterarNegociadorController {
  makeFormFields(values) {
    return {
      novoNegociador: values?.novoNegociador || '',
    };
  }
}

export const acaoAlterarNegociadorController = new AcaoAlterarNegociadorController();
