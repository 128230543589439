import React, { createContext } from 'react';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createDispatchHook, createSelectorHook, Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import pesquisaRamoAtividadeReducer from './Pesquisa/pesquisaRamoAtividade.store';
import crudRamoAtividadeReducer from './Crud/crudRamoAtividade.store';
import watchPesquisaRamoAtividade from './Pesquisa/pesquisaRamoAtividade.saga';
import watchCrudRamoAtividade from './Crud/CrudRamoAtividade.saga';


export const ramoAtividadeContext = createContext();
export const useRamoAtividadeSelector = createSelectorHook(ramoAtividadeContext);
export const useRamoAtividadeDispatch = createDispatchHook(ramoAtividadeContext);

export default function RamoAtividadeProvider({ children }) {
  const sagaMiddleware = createSagaMiddleware();

  const reducers = combineReducers({
    pesquisaRamoAtividade: pesquisaRamoAtividadeReducer,
    crudRamoAtividade: crudRamoAtividadeReducer,
  });

  const store = createStore(reducers, applyMiddleware(sagaMiddleware));

  sagaMiddleware.run(watchPesquisaRamoAtividade);
  sagaMiddleware.run(watchCrudRamoAtividade);

  return (
    <Provider context={ramoAtividadeContext} store={store}>
      {children}
    </Provider>
  );
}
