import { Grid, IconButton } from '@material-ui/core';
import React, { useCallback, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch, useSelector } from 'react-redux';
import SelectorField from '../UI/Field/Selector/SelectorField';
import useStyles from './textosPadrao.styles';
import ButtonUI from '../UI/Button/ButtonUI';
import { useTextosDispatch, useTextosSelector } from '../../store/TextosPadrao/TextosPadraoProvider';
import {
  resetTextosRequestFieldsAction, setAlertDialogValuesAction,
  setAttributesAction,
  setCacheRequestFieldsAction,
  setPageAction,
  setSnackbarTextoAction,
} from '../../store/TextosPadrao/textosPadrao.store';
import LoadingUI from '../UI/Loading/LoadingUI';
import TextosPadraoTable from './Table/TextosPadraoTable';
import WrapperContent from '../UI/WrapperContent/WrapperContent';
import CrudTextosPadrao from './Crud/CrudTextosPadrao';
import { findTipoTextoPadraoAction } from '../../store/Global/LoadSelectors/loadSelectors.saga';
import { SNACKBAR_DURATION } from '../../helpers/constants/global.constants';
import SnackbarUI from '../UI/Snackbar/Snackbar';
import { pesquisarTextosAction } from '../../store/TextosPadrao/textosPadrao.saga';
import { hasUserPermissions } from '../../helpers';
import OptionDialogNew from '../UI/Dialogs/OptionDialog/OptionDialogNew';


function TextosPadrao() {
  const styles = useStyles();
  const dispatch = useTextosDispatch();
  const globalDispatch = useDispatch();

  const tipoTextoPadrao = useSelector(states => states.selectors.tipoTextoPadrao.selector);
  const showCrudDialog = useTextosSelector(states => states.showCrudDialog);
  const loading = useTextosSelector(states => states.loading);
  const idTipoTextoPadraoPesquisa = useTextosSelector(states => states.idTipoTextoPadraoPesquisa);
  const snackBar = useTextosSelector(states => states.snackbar);
  const alertDialog = useTextosSelector(states => states.alertDialog);

  const [routeNames, setRouteNames] = useState(['Texto Padrão']);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [arrowOrdenacao, setArrowOrdenacao] = useState(false);
  const [columnOrdenacao, setColumnOrdenacao] = useState({
    element: 'ids.idTextoPadrao',
    order: 'ASC',
  });


  const permissaoTextoPadrao = React.useMemo(() => {
    const gerenciarTextoPadrao = hasUserPermissions(2001);
    return {
      gerenciarTextoPadrao,
    };
  }, []);


  const onChangeTipo = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setAttributesAction(name, value));
  }, [dispatch]);

  const setCreateEditMode = useCallback((isCreate) => {
    setIsCreateMode(isCreate);
  }, []);


  const openNewTextoButtonHandler = useCallback(() => {
    setCreateEditMode(true);
    dispatch(resetTextosRequestFieldsAction());
    dispatch(setAttributesAction('showCrudDialog', true));
  }, [dispatch, setCreateEditMode]);

  const onClickPesquisarHandler = useCallback(() => {
    dispatch(setCacheRequestFieldsAction());
    dispatch(setPageAction(0));
  }, [dispatch]);

  const closeSnackbarHandler = useCallback(() => {
    dispatch(setSnackbarTextoAction(false, '', ''));
  }, [dispatch]);

  const closeAlertDialog = useCallback(() => {
    const ordenacao = [`${columnOrdenacao.element},${columnOrdenacao.order}`];
    dispatch(setAttributesAction('showCrudDialog', false));
    dispatch(pesquisarTextosAction(0, 10, ordenacao));
    dispatch(setAlertDialogValuesAction(false, '', ''));
  }, [dispatch, columnOrdenacao]);

  useEffect(() => {
    globalDispatch(findTipoTextoPadraoAction());
  }, [globalDispatch]);

  useEffect(() => {
    if (isCreateMode) {
      setRouteNames(['Novo Cadastro de Texto Padrão']);
    } else {
      setRouteNames(['Editar Texto Padrão']);
    }
  }, [isCreateMode]);

  return (
    <WrapperContent>
      <LoadingUI show={loading} />
      <OptionDialogNew
        open={alertDialog.open}
        confirmLabel="OK, ENTENDI"
        onClickConfirm={closeAlertDialog}
      >
        <div style={{ fontSize: '18px' }}>
          <p>
            Não foi possível atender sua solicitação, pois o cadastro
            <br />
            deste Texto Padrão foi removido do sistema.
          </p>
        </div>
      </OptionDialogNew>
      <SnackbarUI
        open={snackBar.open}
        autoHideDuration={SNACKBAR_DURATION}
        variant={snackBar.variant}
        message={snackBar.message}
        onClose={closeSnackbarHandler}
      />
      <CrudTextosPadrao
        open={showCrudDialog}
        onClose={() => dispatch(setAttributesAction('showCrudDialog', false))}
        routesName={routeNames}
        isCreateMode={isCreateMode}
        columnOrdenacao={columnOrdenacao}
      />
      <div className={styles.div__root}>
        <div className={styles.div__tipo}>
          <SelectorField
            hasEmptyLabel
            fullWidth
            label="Tipo"
            items={tipoTextoPadrao}
            name="idTipoTextoPadraoPesquisa"
            value={idTipoTextoPadraoPesquisa || ''}
            onChange={onChangeTipo}
          />
        </div>
        <div>
          <IconButton
            type="submit"
            className={styles.div__buttonPesquisar}
            onClick={onClickPesquisarHandler}
          >
            <SearchIcon style={{ marginLeft: 2 }} />
          </IconButton>
        </div>

      </div>
      <div>
        <Grid container justify="flex-end" className={styles.buttonNovo}>
          <Grid item>
            <ButtonUI onClick={openNewTextoButtonHandler} disabled={!permissaoTextoPadrao.gerenciarTextoPadrao}>
              Novo
            </ButtonUI>
          </Grid>
        </Grid>
      </div>
      <TextosPadraoTable
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacao={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        setCreateEditMode={setCreateEditMode}
        permissao={permissaoTextoPadrao}
      />
    </WrapperContent>
  );
}

export default withRouter(TextosPadrao);
