import { TableRow, Tooltip } from '@material-ui/core';
import React, { useCallback } from 'react';
import { AiFillPrinter } from 'react-icons/ai';
import { FaEnvelope } from 'react-icons/fa';
import {
  TableActionButton, TableCellCenter, TableCellRight, TableCellLeft, TableActionContainer,
} from '../../UI/Tabela/tableDefaultStyles';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { setSimpleValueBoletosRelacaoPrestacaoAction } from '../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { CancelarBoletoIcon } from '../../../assets/Icons/CancelarBoletoIcon';
import { ConfirmacaoBoletoIcon } from '../../../assets/Icons/ConfirmacaoBoletoIcon';
import TooltipUI from '../../UI/Tooltip/Tooltip';
import IconAgrupamento from '../../../assets/images/icons/iconAgrupamento.svg';
import { enviarEmailBoletoAction, imprimirBoletoRelacaoPrestacaoAction, registrarBoletoRelacaoPrestacaoAction } from '../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';

function TableBodyBoletosRelacaoPrestacaoComponent({ content = {}, permissoes, setBoletoSelecionado }) {
  const dispatch = useCCClienteDispatch();
  const emailsPermitemBoleto = useCCClienteSelector(states => (states.relacaoPrestacao.informacoesAdicionais.emailsPermitemBoleto));
  const prestacaoComBoletoAgrupado = useCCClienteSelector(states => (states.relacaoPrestacao.informacoesAdicionais.prestacaoComBoletoAgrupado));
  const idsBoletosAgrupados = useCCClienteSelector(states => (states.relacaoPrestacao.informacoesAdicionais.idsBoletosAgrupados));


  const openCancelHandler = useCallback(() => {
    setBoletoSelecionado(content.idBoletoCliente);
    dispatch(setSimpleValueBoletosRelacaoPrestacaoAction('openCancelarBoletoDialog', true));
  }, [dispatch, content.idBoletoCliente, setBoletoSelecionado]);

  const handleRegistroBoletoClick = useCallback((idBoletoCliente) => {
    dispatch(registrarBoletoRelacaoPrestacaoAction(idBoletoCliente));
  }, [dispatch]);

  const handleEnviarEmailBoletoClick = useCallback((idBoletoCliente) => {
    dispatch(enviarEmailBoletoAction(idBoletoCliente));
  }, [dispatch]);

  function showButtonRegistro() {
    return (
      <TableActionButton
        disabled={content.descricao !== 'Não registrado' && content.descricao !== 'Erro no registro'}
        onClick={() => handleRegistroBoletoClick(content.idBoletoCliente)}
      >
        <ConfirmacaoBoletoIcon />
      </TableActionButton>
    );
  }

  const handleImpressaoBoletoClick = useCallback((idBoletoCliente) => {
    dispatch(imprimirBoletoRelacaoPrestacaoAction(idBoletoCliente));
  }, [dispatch]);

  return (
    <TableRow>
      <TableCellCenter>
        {content.dataEmissao}
      </TableCellCenter>
      <TableCellCenter>
        {prestacaoComBoletoAgrupado && (
          <TooltipUI title={idsBoletosAgrupados}>
            <img src={IconAgrupamento} alt="Boletos agrupados" height="14px" width="15px" />
          </TooltipUI>
        )
        }
      </TableCellCenter>
      <TableCellCenter>
        {content.nossoNumero}
      </TableCellCenter>
      <TableCellLeft>
        {content.nomeUsuario}
      </TableCellLeft>
      <TableCellCenter>
        {content.dataVencimento}
      </TableCellCenter>
      <TableCellRight>
        {content.valor}
      </TableCellRight>
      <TableCellCenter>
        {content.descricao}
      </TableCellCenter>
      <TableCellCenter>
        <TableActionContainer>
          {(content.descricao === 'Cancelado' || !permissoes.prestarContas) ? (
            <TableActionButton
              disabled
            >
              <CancelarBoletoIcon />
            </TableActionButton>
          ) : (
            <Tooltip title="Cancelar" placement="bottom">
              <TableActionButton
                onClick={() => openCancelHandler()}
              >
                <CancelarBoletoIcon />
              </TableActionButton>
            </Tooltip>
          )}
          {
            content.mensagem !== null
              ? (
                <Tooltip title={content.mensagem} placement="bottom">
                  {showButtonRegistro()}
                </Tooltip>
              )
              : (
                showButtonRegistro()
              )
          }
          {(content.descricao === 'Cancelado' || !emailsPermitemBoleto) ? (
            <TableActionButton disabled>
              <FaEnvelope size={18} />
            </TableActionButton>
          ) : (
            <Tooltip title="Enviar" placement="bottom">
              <TableActionButton
                disabled={content.descricao === 'Cancelado' || !emailsPermitemBoleto}
                onClick={() => handleEnviarEmailBoletoClick(content.idBoletoCliente)}
              >
                <FaEnvelope size={18} />
              </TableActionButton>
            </Tooltip>
          )}
          <Tooltip title="Imprimir" placement="bottom">
            <TableActionButton
              onClick={() => handleImpressaoBoletoClick(content.idBoletoCliente)}
            >
              <AiFillPrinter size={20} />
            </TableActionButton>
          </Tooltip>
        </TableActionContainer>
      </TableCellCenter>
    </TableRow>
  );
}

export const TableBodyBoletosRelacaoPrestacao = TableBodyBoletosRelacaoPrestacaoComponent;
