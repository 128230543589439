import React, {
  useEffect, useCallback, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useUtilStyles, getRequiredLabel } from '../../../../../helpers';
import Grid24 from '../../../../UI/Grid24';
import WrapperSubTitleForm from '../../../../UI/WrapperSubTitleForm';
import { useCrudUsuarioDispatch2, useCrudUsuarioSelector2 } from '../../../../../store/Usuario/V2/CrudUsuarioProvider';
import SelectorField from '../../../../UI/Field/Selector';
import NumberInput from '../../../../UI/Field/Number/NumberInput';
import {
  ADICIONAR_TOOLTIP,
  EXCLUIR_TOOLTIP, TITLES_FASES_ATUACAO_CRUD_USUARIO, TITULO_COMISSAO, TITULO_FASES,
} from './FasesAtuacaoCrudUsuario.constants';
import IconButtonTooltip from '../../../../UI/IconButtonTooltip/IconButtonTooltip';
import {
  onChangeFaseAtualAction, onClickAdicionarFase, onClickExcluirFase, onMaxFasesSelectedAction,
} from '../../../../../store/Usuario/V2/crudUsuario.store';


function FasesAtuacaoUsuarioCrud({
  isEditMode,
  isInsertMode,
  disabled,
}) {
  const [bloquearAdicao, setBloquerAdicao] = useState(false);
  const [lengthFase, setLengthFase] = useState();
  const utilStyles = useUtilStyles();
  const fasesUsuarioSelector = useSelector(states => states.selectors.fases.selector);
  const fasesSelecionadas = useCrudUsuarioSelector2(states => states.controls.fasesSelecionadas);
  const tipoUsuarioTipoComissao = useCrudUsuarioSelector2(state => state.controls.tipoUsuarioTipoComissao.copy());
  const dispatch = useCrudUsuarioDispatch2();
  const globalDispatch = useDispatch();

  const filterFasesFromSelected = useCallback(() => fasesUsuarioSelector.filter((fase) => {
    let flag;
    fasesSelecionadas.forEach((faseSelected) => {
      if (faseSelected.idFase.value === fase.id) {
        flag = true;
      }
    });
    if (flag) {
      return false;
    }
    return true;
  }), [fasesUsuarioSelector, fasesSelecionadas]);

  const filterFasesAndAddFaseAtual = useCallback((faseAtual) => {
    const fasesFiltradas = filterFasesFromSelected();
    const elementoAtual = fasesUsuarioSelector.find(fase => fase.id === faseAtual.idFase.value);
    if (elementoAtual) {
      fasesFiltradas.push(elementoAtual);
    }
    return (fasesFiltradas);
  }, [filterFasesFromSelected, fasesUsuarioSelector]);

  const onChangeSelectorFieldFase = useCallback((e, idx) => {
    const { name, value } = e.target;
    dispatch(onChangeFaseAtualAction(name, value, idx));
  }, [dispatch]);

  const onClickAdicionarFaseNova = useCallback(() => {
    dispatch(onClickAdicionarFase());
  }, [dispatch]);

  const onClickExcluirFaseExistente = useCallback((idx) => {
    dispatch(onClickExcluirFase(idx));
  }, [dispatch]);


  const verificaComissaoDesabilitada = useCallback((faseAtual) => {
    const pagarComissaoNaFase = fasesUsuarioSelector.find(fase => fase.id === faseAtual.idFase.value);
    if (pagarComissaoNaFase) {
      return pagarComissaoNaFase.aux2;
    }
    return false;
  }, [fasesUsuarioSelector]);


  useEffect(() => {
    setLengthFase(filterFasesFromSelected().length);
  }, [globalDispatch, filterFasesFromSelected]);

  useEffect(() => {
    if (bloquearAdicao) {
      dispatch(onMaxFasesSelectedAction());
    }
  }, [bloquearAdicao, dispatch]);

  return (
    <Grid24 container className={utilStyles.w100}>
      <WrapperSubTitleForm
        title={tipoUsuarioTipoComissao.value !== 1 ? TITLES_FASES_ATUACAO_CRUD_USUARIO.fasesUsuario : TITLES_FASES_ATUACAO_CRUD_USUARIO.fasesUsuarioComissoes}
        fullWidth
        sideComponent={(
          <IconButtonTooltip
            type="add"
            color="primary"
            disabled={disabled || bloquearAdicao || lengthFase === 0}
            hint={ADICIONAR_TOOLTIP}
            className={utilStyles.mb4}
            onClick={onClickAdicionarFaseNova}
          />
        )}
      >
        {
          fasesSelecionadas.map((faseObj, idx) => (
            <Grid24 xs={24} sm={24} spacing="1" alignItems="start" className={utilStyles.mt6}>
              <Grid24 xs={24} sm={5} className={utilStyles.mt2}>
                <SelectorField
                  isForm
                  label={getRequiredLabel(TITULO_FASES, !disabled)}
                  fullWidth
                  disabled={disabled}
                  name={faseObj.idFase.name}
                  error={faseObj.idFase.error}
                  errorMessage={faseObj.idFase.errorMessage}
                  items={filterFasesAndAddFaseAtual(faseObj)}
                  value={faseObj.idFase.value}
                  onChange={(e) => {
                    onChangeSelectorFieldFase(e, idx);
                    if (filterFasesFromSelected().length === 0) {
                      setBloquerAdicao(true);
                    } else {
                      setBloquerAdicao(false);
                    }
                  }}
                />
              </Grid24>
              {(tipoUsuarioTipoComissao.value === 1) && (
                <Grid24 xs={20} sm={2} className={utilStyles.mt2}>
                  <NumberInput
                    label={getRequiredLabel(TITULO_COMISSAO, !disabled)}
                    acceptEmptyValue
                    isForm
                    disabled={disabled || !verificaComissaoDesabilitada(faseObj)}
                    sideText="(%)"
                    name={faseObj.porcentagemComissao.name}
                    error={faseObj.porcentagemComissao.error}
                    errorMessage={faseObj.porcentagemComissao.errorMessage}
                    value={faseObj.porcentagemComissao.value !== '' ? faseObj.porcentagemComissao.value : '0.00'}
                    numberType="percentage"
                    maxValue={100}
                    onChange={e => onChangeSelectorFieldFase(e, idx)}
                  />
                </Grid24>
              )}
              <Grid24 xs={4} sm={2} className={`${utilStyles.top7} ${utilStyles.ml12}`}>
                <IconButtonTooltip
                  type="trash"
                  color="primary"
                  disabled={disabled}
                  hint={EXCLUIR_TOOLTIP}
                  onClick={() => {
                    onClickExcluirFaseExistente(idx);
                    setLengthFase(filterFasesFromSelected().length);
                    if (filterFasesFromSelected().length === 0) {
                      setBloquerAdicao(true);
                    } else {
                      setBloquerAdicao(false);
                    }
                  }}
                />
              </Grid24>
            </Grid24>
          ))
        }
      </WrapperSubTitleForm>
    </Grid24>
  );
}


export default FasesAtuacaoUsuarioCrud;
