import { gateway } from '../../api';
import { getUriParams } from '../../../helpers/utils/utils';

const CORE = process.env.REACT_APP_CORE;

export function getPasta(idFasePasta) {
  return gateway.get(`${CORE}/pastas/${idFasePasta}`);
}

export function findPastas(idFase, publico) {
  if (publico) {
    const query = getUriParams({ publico });
    return gateway.get(`${CORE}/pastas/${idFase}/fases?${query}`);
  }
  return gateway.get(`${CORE}/pastas/${idFase}/fases`);
}

export function deletePastas(idFasePasta) {
  return gateway.delete(`${CORE}/pastas/${idFasePasta}`);
}

export function insertPasta(payload) {
  return gateway.post(`${CORE}/pastas`, payload);
}

export function editPasta(payload) {
  return gateway.put(`${CORE}/pastas`, payload);
}
