import React, {
  memo, useCallback, useState, useEffect,
} from 'react';

import { Button, TextField } from '@mui/material';

import { toast } from 'react-toastify';
import Grid24 from '../../../../components/UI/Grid24';
import useStyle from '../AcordosStyle';

import {
  getExceptionErrorMessage, getRequiredLabel, isBadRequestException, toAmericanDate, toBrazilianDate,
} from '../../../../helpers/utils/utils';
import DateField from '../../../../components/UI/Field/Date/DateField';
import { dialogAlterarParcelaPixController } from './dialogAlterarParcelaPixController';
import { DateUtils, extractResponseErrorMessage, getFieldName } from '../../../../helpers';
import { loadingInspecaoProcessoController } from '../../loadingInspecaoProcessoController';
import { getParametrosInterno } from '../../../../services/core/parametro/parametro.services';


function DialogAlterarPix({
  setOpenDialogAlterarPix,
  dadosParcelaPix,
  idProcesso,
  nroParcela,
  idAcordo,
  idAcordoParcela,
  tipoFormPgtp,
  setLoadings,
  updatePage,
  dados,
  parcela,
}) {
  const style = useStyle();

  const [formFields, setFormFields] = useState(dialogAlterarParcelaPixController.makeFormFields(dadosParcelaPix));
  const [parametrosInternosAbe, setParametrosInternosAbe] = useState({});
  const [novaDataMax, setNovaDataMax] = useState('');
  const [parcelaAnteriorPix, setParcelaAnteriorPix] = useState('');
  const [proximaParcelaPix, setProximaParcelaPix] = useState('');


  const onChangeHandler = useCallback((e) => {
    const name = getFieldName(e.target);
    const { value } = e.target;
    setFormFields(oldValue => ({ ...oldValue, [name]: { ...oldValue[name], value } }));
  }, []);


  async function onClickSubmitParcela(e) {
    e.preventDefault();
    setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(true));
    try {
      await dialogAlterarParcelaPixController.salvar(idProcesso,
        nroParcela,
        idAcordo,
        idAcordoParcela,
        tipoFormPgtp, formFields);
      setOpenDialogAlterarPix(false);
      updatePage();
    } catch (error) {
      const erroMessage = extractResponseErrorMessage(error);
      if (erroMessage !== 'Erro não mapeado') {
        toast.error(erroMessage);
      }
    } finally {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(false));
    }
  }


  async function buscarParametrosInternosAbe() {
    try {
      const { data } = await getParametrosInterno();
      setParametrosInternosAbe(data);
    } catch (error) {
      if (isBadRequestException(error)) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(getExceptionErrorMessage(error));
      }
    }
  }

  useEffect(() => {
    setFormFields(dialogAlterarParcelaPixController.makeFormFields(dadosParcelaPix));
    buscarParametrosInternosAbe();
  }, [dadosParcelaPix]);


  useEffect(() => {
    if (nroParcela === dados.length && nroParcela > 1) {
      setFormFields(dialogAlterarParcelaPixController.makeFormFields(dadosParcelaPix));
      buscarParametrosInternosAbe();
      const date = DateUtils.getISODateFromText(DateUtils.formatTextDate(parcela.dataVencimento));
      date.setDate(date.getDate() + parametrosInternosAbe.prazoMaximoPrimeiroVencimento);
      setNovaDataMax(date);
      setParcelaAnteriorPix(toBrazilianDate(parcela.dataVencimento));
    } else if (nroParcela === dados.length && nroParcela < 1) {
      setFormFields(dialogAlterarParcelaPixController.makeFormFields(dadosParcelaPix));
      buscarParametrosInternosAbe();
      const date = DateUtils.getISODateFromText(DateUtils.formatTextDate(parcela.dataVencimento));
      date.setDate(date.getDate() + parametrosInternosAbe.prazoMaximoPrimeiroVencimento);
      setNovaDataMax(date);
      setParcelaAnteriorPix(toBrazilianDate(parcela.dataVencimento));
    } if (nroParcela > 1 && nroParcela !== dados.length) {
      setParcelaAnteriorPix(toBrazilianDate(parcela.dataVencimento));
      const proximaParcela = dados[nroParcela + 0];
      setProximaParcelaPix(proximaParcela.values.dataVencimento);
    } if (nroParcela === 1 && nroParcela !== dados.length) {
      setParcelaAnteriorPix(toBrazilianDate(parcela.dataVencimento));
      const proximaParcela = dados[nroParcela + 0];
      setProximaParcelaPix(proximaParcela.values.dataVencimento);
    }
    if (nroParcela === dados.length && nroParcela === 1) {
      setParcelaAnteriorPix(toBrazilianDate(parcela.dataVencimento));
      const date = DateUtils.getISODateFromText(DateUtils.formatTextDate(parcela.dataVencimento));
      date.setDate(date.getDate() + parametrosInternosAbe.prazoMaximoPrimeiroVencimento);
      setNovaDataMax(date);
    }
  }, [formFields.dataVencimento.value, parametrosInternosAbe.prazoMaximoPrimeiroVencimento, dados.length,
    dadosParcelaPix,
    nroParcela,
    dados,
    parcela,
    idAcordo,
  ]);


  return (
    <>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={24}>
          {nroParcela === dados.length ? (
            <Grid24 item xs={24} sm={6}>
              <DateField
                isForm
                fullWidth
                label={getRequiredLabel('Vencimento', true)}
                name={formFields.dataVencimento.name}
                value={formFields.dataVencimento.value}
                onChange={onChangeHandler}
                maxDate={novaDataMax}
                minDate={new Date(`${toAmericanDate(parcelaAnteriorPix)} 00:00:00`)}
              />
            </Grid24>
          ) : (
            <Grid24 item xs={24} sm={6}>
              <DateField
                isForm
                fullWidth
                label={getRequiredLabel('Vencimento', true)}
                name={formFields.dataVencimento.name}
                value={formFields.dataVencimento.value}
                onChange={onChangeHandler}
                minDate={new Date(`${toAmericanDate(parcelaAnteriorPix)} 00:00:00`)}
                maxDate={toAmericanDate(proximaParcelaPix)}
              />
            </Grid24>
          )}

          <Grid24 item xs={24} sm={24} className={style.dialogAlterarParcelaDinheiro}>
            <div style={{ display: 'grid', marginTop: '18px' }}>
              <TextField
                name={formFields.observacaoPix.name}
                value={formFields.observacaoPix.value}
                onChange={onChangeHandler}
                multiline
                rows={4}
                id="outlined-multiline-static"
                label="Observações"
              />
            </div>
          </Grid24>
        </Grid24>
      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          onClick={() => setOpenDialogAlterarPix(false)}
        >
          Cancelar
        </Button>
        <Button disabled={!formFields.dataVencimento.value} onClick={onClickSubmitParcela}>
          Confirmar
        </Button>
      </div>

    </>
  );
}
export default memo(DialogAlterarPix);
