
import React, { useCallback } from 'react';
import { getRequiredLabel, useUtilStyles } from '../../../../../../../helpers';
import { CNPJ_MASK, CPF_MASK, PHONE_MASK } from '../../../../../../../helpers/constants/layout.constants';
import InputField from '../../../../../../UI/Field/Input';
import MaskField from '../../../../../../UI/Field/Mask';
import SelectorField from '../../../../../../UI/Field/Selector';
import Grid24 from '../../../../../../UI/Grid24';
import IconButtonTooltip from '../../../../../../UI/IconButtonTooltip/IconButtonTooltip';
import {
  AGENCIA_LABEL, BANCO_LABEL, CHAVE_PIX, CNPJ_LABEL, DAC_LABEL, NUMERO_CONTA_LABEL, TIPO_CHAVE_PIX, TITULAR_LABEL,
} from '../TabContasBancarias.constants';

function CamposContaDepositoCliente({
  isInsertMode,
  showTrash = false,
  onRemove,
  setAttribute,
  setFocus,
  index,
  model,
  bancos,
  tiposChavesPix,
}) {
  const utilStyles = useUtilStyles();

  const focusFieldsHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    setFocus(fieldName, index);
  }, [setFocus, index]);

  const changeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    setAttribute(name, value, index);
  }, [setAttribute, index]);

  const maskChavePix = () => {
    if (model.idTipoChavePix.value === 0) {
      return CNPJ_MASK;
    } if (model.idTipoChavePix.value === 1) {
      return CPF_MASK;
    } if (model.idTipoChavePix.value === 3) {
      return PHONE_MASK;
    } return null;
  };

  return (
    <>

      <Grid24 xs={24} sm={24} spacing={1}>
        <Grid24 xs={24} sm={13} className={utilStyles.mb7}>
          <InputField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(TITULAR_LABEL, isInsertMode)}
            name={model.titular.name}
            value={model.titular.value}
            error={model.titular.error}
            errorMessage={model.titular.errorMessage}
            maxLength={60}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={5}>
          <MaskField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(CNPJ_LABEL, isInsertMode)}
            name={model.cnpjCpf.name}
            value={model.cnpjCpf.value}
            error={model.cnpjCpf.error}
            errorMessage={model.cnpjCpf.errorMessage}
            format={CNPJ_MASK}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
          />
        </Grid24>
      </Grid24>
      <Grid24 xs={24} sm={24} spacing={1}>
        <Grid24 xs={24} sm={9}>
          <SelectorField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(BANCO_LABEL, isInsertMode)}
            items={bancos}
            name={model.idBanco.name}
            value={model.idBanco.value}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <InputField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(AGENCIA_LABEL, isInsertMode)}
            name={model.agencia.name}
            value={model.agencia.value}
            error={model.agencia.error}
            errorMessage={model.agencia.errorMessage}
            maxLength={5}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <InputField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(NUMERO_CONTA_LABEL, isInsertMode)}
            name={model.numero.name}
            value={model.numero.value}
            errorMessage={model.numero.errorMessage}
            error={model.numero.error}
            maxLength={15}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={1}>
          <InputField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(DAC_LABEL, isInsertMode)}
            name={model.dac.name}
            value={model.dac.value}
            errorMessage={model.dac.errorMessage}
            error={model.dac.error}
            maxLength={1}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
          />
        </Grid24>
        {showTrash && (
          <Grid24 item xs={24} sm={2} justify="end" className={utilStyles.top7}>
            <IconButtonTooltip
              type="trash"
              disabled={!isInsertMode}
              hint="Excluir Faixa"
              onClick={onRemove}
            />
          </Grid24>
        )}
      </Grid24>
      <Grid24 xs={24} sm={24} spacing={1} className={utilStyles.mt7}>
        <Grid24 xs={12} sm={4}>
          <SelectorField
            isForm
            fullWidth
            hasEmptyLabel
            disabled={!isInsertMode}
            label={TIPO_CHAVE_PIX}
            items={tiposChavesPix}
            name={model.idTipoChavePix.name}
            value={model.idTipoChavePix.value}
            errorMessage={model.idTipoChavePix.errorMessage}
            error={model.idTipoChavePix.error}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={14}>
          {model.idTipoChavePix.value === 2 || model.idTipoChavePix.value === 4
            ? (
              <InputField
                isForm
                fullWidth
                disabled={!isInsertMode}
                label={CHAVE_PIX}
                name={model.chavePix.name}
                value={model.chavePix.value}
                errorMessage={model.chavePix.errorMessage}
                error={model.chavePix.error}
                maxLength={100}
                onChange={changeFieldsHandler}
                onFocus={focusFieldsHandler}
              />
            )
            : (
              <MaskField
                isForm
                fullWidth
                disabled={!isInsertMode}
                label={CHAVE_PIX}
                name={model.chavePix.name}
                value={model.chavePix.value}
                maxLength={100}
                onChange={changeFieldsHandler}
                onFocus={focusFieldsHandler}
                format={maskChavePix()}
                errorMessage={model.chavePix.errorMessage}
                error={model.chavePix.error}
              />
            )}
        </Grid24>
      </Grid24>
    </>
  );
}
export default React.memo(CamposContaDepositoCliente);
