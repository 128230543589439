import React, {
  useEffect, useState, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Container,
  BodyContainer,
  CorrigirButton,
  DivError,
  FooterContainer,
  StatusValidacao,
} from './SumarioValidacaoBordero.styles';
import { initializePageAction } from '../../store/theme.actions';
import { InputFile } from '../UI/InputFile/InputFile';
import { validarArquivoBordero } from '../../services/bordero/bordero/bordero.services';
import Dica from '../UI/Dica/Dica';

const tiposRetornos = {
  ARQUIVO_INVALIDO: {
    name: 'ARQUIVO INVALIDO',
    color: '#ff000f',
  },
  ARQUIVO_COM_ERRO_NOS_CAMPOS: {
    name: 'ERROS NOS CAMPOS',
    color: '#ffa000',
  },
  ARQUIVO_COM_ERRO_NA_ESTRUTURA: {
    name: 'ESTRUTURA COM ERROS',
    color: '#ffa000',
  },
  ARQUIVO_CORRETO: {
    name: 'ARQUIVO CORRETO',
    color: '#43a047',
  },
};

function SumarioValidacaoBordero({
  onClickCorrections, onChangeRetorno, retornoBordero, setRetornoBordero, nomeArquivo, setNomeArquivo,
}) {
  const dispatch = useDispatch();
  const [arquivoBordero, setArquivoBordero] = useState(null);
  const [showLoading, setShowLoading] = useState(false);

  const mensagemValidacao = {
    ARQUIVO_COM_ERRO_NOS_CAMPOS: retornoBordero?.quantidadeErros,
    ARQUIVO_COM_ERRO_NA_ESTRUTURA:
        'A estrutura deste arquivo de borderô não respeita o padrão informado no documento "Layout Arquivo de Borderô - ABE". O arquivo precisa ser ajustado',
    ARQUIVO_CORRETO: 'Nenhum erro foi encontrado.',
  };

  useEffect(() => {
    dispatch(initializePageAction('Validar Borderô'));
  }, [dispatch]);

  useEffect(() => {
    if (!arquivoBordero) {
      setRetornoBordero(null);
      return;
    }
    setShowLoading(true);
    validarArquivoBordero(arquivoBordero).then((response) => {
      onChangeRetorno(response.data.borderoCnab, response.data.version);
    }).catch(() => {
      toast.error('Erro na transferência do arquivo. Tente novamente.', { style: { width: '392px' } });
    }).finally(() => {
      setShowLoading(false);
    });
  }, [arquivoBordero, onChangeRetorno, setRetornoBordero]);

  const handleChange = useCallback((e) => {
    const { value } = e.target;
    setNomeArquivo(value);
  }, [setNomeArquivo]);

  return (
    <>
      <BodyContainer>
        <div className="chooseFile">
          <div className="chooseFileIntenal">
            <InputFile
              isForm
              maxLength={200}
              label="Arquivo*"
              labelName="bordero"
              name="arquivoBordero"
              onChange={handleChange}
              value={nomeArquivo}
              variant="contained"
              contentButtonText="Escolher Arquivo"
              setFile={setArquivoBordero}
              acceptAll
            />
          </div>
          <div className="tip">
            <Dica mensagemDica="O tamanho máximo do arquivo não pode exceder 10MB." />
          </div>
        </div>
        <div className="loadingText">
          <div
            className="loadingInfo"
            style={{ visibility: showLoading ? 'visible' : 'hidden' }}
          >
            <h1>Fazendo upload do arquivo...</h1>
          </div>
        </div>
        <div>
          <DivError>
            <div className="result">
              <p>Resultado da Validação:</p>
              <StatusValidacao
                color={tiposRetornos[retornoBordero?.statusVerificacao]?.color}
              >
                {tiposRetornos[retornoBordero?.statusVerificacao]?.name}
              </StatusValidacao>
            </div>
            <div>
              <p className="infoResult">Nome Arquivo:</p>
              <span>{nomeArquivo}</span>
            </div>

            <div>
              <p className="infoResult">Erros a serem corrigidos:</p>
              <span>{mensagemValidacao[retornoBordero?.statusVerificacao]}</span>
            </div>
          </DivError>
        </div>
      </BodyContainer>
      <Container>
        <FooterContainer>
          <CorrigirButton
            color="success"
            disabled={retornoBordero?.statusVerificacao !== 'ARQUIVO_COM_ERRO_NOS_CAMPOS'}
            onClick={onClickCorrections}
          >
            Corrigir Borderô
          </CorrigirButton>
        </FooterContainer>
      </Container>
    </>
  );
}

export default SumarioValidacaoBordero;
