export const TITLE = 'Comissões';
export const ABE_DEF = 'Utilizar Comissões definidas pela ABE';
export const CLIENTE_DEF = 'Utilizar Comissões definidas pelo Cliente';
export const COMBO_BOX_TYPE = 'Tipo de Cálculo para as Comissões';
export const ADD_TITLE = 'Adicionar Faixa de Comissões por ';
export const ADD_TITLE_QUERY = 'Faixa(s) de Comissões por ';
export const ADD_HINT = 'Adicionar Faixa';
export const INITIAL = 'De';
export const FINAL = 'Até';
export const COMISSAO = 'Comissão';
export const PAGAMENTO_DIRETO = 'Pagamento Direto';
