import React, {
  useCallback, useState,
} from 'react';
import { Button } from '@mui/material';
import { IconButton } from '@material-ui/core';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { getRequiredLabel } from '../../../helpers';
import InputField from '../../UI/Field/Input';
import { alterarSenhaAction } from '../../../store/Login/login.saga';
import useStyle from './DialogAlterarSenhaStyles';
import { limpaDialogAlterarSenhaAction, onChangeFieldAlterarSenhaAction, onFocusFieldAlterarSenhaAction } from '../../../store/Login/login.store';

function DialogAlterarSenha({
  onClose,
  settingsFloatReg,
}) {
  const style = useStyle();
  const dispatch = useDispatch();

  const [revelaSenhaAtual, setRevelaSenhaAtual] = useState(false);
  const [revelaNovaSenha, setRevelaNovaSenha] = useState(false);
  const [revelaConfirmarNovaSenha, setRevelaConfirmarNovaSenha] = useState(false);

  const senhaAtual = useSelector(state => state.authentication?.alterarSenha?.senhaAtual || null);
  const novaSenha = useSelector(state => state.authentication?.alterarSenha?.novaSenha || null);
  const confirmarNovaSenha = useSelector(state => state.authentication?.alterarSenha?.confirmarNovaSenha || null);

  const onCloseDialog = useCallback(() => {
    dispatch(limpaDialogAlterarSenhaAction());
    onClose();
  }, [dispatch, onClose]);

  const onClickConfirmar = useCallback(() => {
    dispatch(alterarSenhaAction(senhaAtual.value, novaSenha.value, confirmarNovaSenha.value));
    settingsFloatReg.current.handlerShowBox();
  }, [senhaAtual, novaSenha, confirmarNovaSenha, dispatch, settingsFloatReg]);

  const onChangeField = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeFieldAlterarSenhaAction(name, value));
  }, [dispatch]);

  const onFocusField = useCallback((event) => {
    const { name } = event.target;
    dispatch(onFocusFieldAlterarSenhaAction(name));
  }, [dispatch]);

  return (
    <>
      <div className={style.div_senhaAtual}>
        <InputField
          isForm
          fullWidth
          autoComplete="off"
          value={senhaAtual.value}
          type={revelaSenhaAtual ? 'text' : 'password'}
          name={senhaAtual.nome}
          error={senhaAtual.error}
          errorMessage={senhaAtual.errorMessage}
          maxLength="25"
          label={getRequiredLabel('Senha Atual', true)}
          onChange={onChangeField}
          onFocus={onFocusField}
        />
        <IconButton
          className={style.iconButton}
          onClick={() => setRevelaSenhaAtual(!revelaSenhaAtual)}
        >
          {revelaSenhaAtual ? (
            <FaEye className={style.icon} />
          ) : (
            <FaEyeSlash className={style.icon} />
          )}

        </IconButton>
      </div>
      <div className={style.div_novaSenha}>
        <InputField
          isForm
          fullWidth
          autoComplete="off"
          value={novaSenha.value}
          type={revelaNovaSenha ? 'text' : 'password'}
          name={novaSenha.nome}
          error={novaSenha.error}
          errorMessage={novaSenha.errorMessage}
          maxLength="25"
          label={getRequiredLabel('Nova Senha', true)}
          onChange={onChangeField}
          onFocus={onFocusField}
        />
        <IconButton
          className={style.iconButton}
          onClick={() => setRevelaNovaSenha(!revelaNovaSenha)}
        >
          {revelaNovaSenha ? (
            <FaEye className={style.icon} />
          ) : (
            <FaEyeSlash className={style.icon} />
          )}
        </IconButton>
      </div>
      <div className={style.div_confirmarNovaSenha}>
        <InputField
          isForm
          fullWidth
          autoComplete="off"
          value={confirmarNovaSenha.value}
          type={revelaConfirmarNovaSenha ? 'text' : 'password'}
          name={confirmarNovaSenha.nome}
          error={confirmarNovaSenha.error}
          errorMessage={confirmarNovaSenha.errorMessage}
          maxLength="25"
          label={getRequiredLabel('Confirmar Nova Senha', true)}
          onChange={onChangeField}
          onFocus={onFocusField}
        />
        <IconButton
          className={style.iconButton}
          onClick={() => setRevelaConfirmarNovaSenha(!revelaConfirmarNovaSenha)}
        >
          {revelaConfirmarNovaSenha ? (
            <FaEye className={style.icon} />
          ) : (
            <FaEyeSlash className={style.icon} />
          )}
        </IconButton>
      </div>
      <div className={style.div_buttons}>
        <Button
          style={{ marginRight: '22px' }}
          onClick={onCloseDialog}
        >
          Cancelar
        </Button>
        <Button
          onClick={onClickConfirmar}
        >
          Confirmar
        </Button>
      </div>
    </>
  );
}

export default DialogAlterarSenha;
