import { TableRow } from '@material-ui/core';
import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { BiCheckDouble } from 'react-icons/bi';
import {
  TableCellCenter, TableCellLeft, TableCellRight,
} from '../UI/Tabela/tableDefaultStyles';
import TooltipUI from '../UI/Tooltip/Tooltip';
import { blackTwo } from '../../helpers/constants/colors.constants';

function TableBodyContaCorrenteHonorarioComponent({
  lancamento,
}) {
  return (
    <TableRow>
      <TableCellCenter>
        {lancamento.dataLancamento}
      </TableCellCenter>
      <TableCellLeft>
        {lancamento.historico}
      </TableCellLeft>
      <TableCellRight>
        {lancamento.valorCredito}
      </TableCellRight>
      <TableCellRight>
        {lancamento.valorDebito}
      </TableCellRight>
      <TableCellCenter>
        {lancamento.estarConsolidado === true
          && (lancamento.idTipoContaCorrenteHonorario === 5
            ? (
              <TooltipUI title="Lançamentos de Consolidação" placement="bottom">
                <span>
                  <BiCheckDouble
                    size={22}
                    color={blackTwo}
                  />
                </span>
              </TooltipUI>
            )
            : (<FaCheck />))
        }
      </TableCellCenter>
    </TableRow>
  );
}

export const TableBodyContaCorrenteHonorario = TableBodyContaCorrenteHonorarioComponent;
