import React, { useCallback } from 'react';
import { Button, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import InputField from '../../../UI/Field/Input';
import useStyles from './historicoPesquisaLayoutAS.styles';
import PeriodField from '../../../UI/Field/Period/PeriodField';
import {
  onChangeRequestFieldsAction,
  onFocusRequestFieldsAction, setCacheRequestFieldsAction, setDatasCamposObrigatoriosAction, setPageAction,
} from '../../../../store/AcordoSeguro/Historico/historicoAS.store';

function HistoricoPesquisaLayoutAS({ openPesquisaAvancada, onClickPesquisaAvancadaHandler }) {
  const dispatch = useDispatch();
  const styles = useStyles();

  const periodoAberturaInicial = useSelector(states => states.historicoAS.requestFields.periodoAberturaInicial.copy());
  const periodoAberturaFinal = useSelector(states => states.historicoAS.requestFields.periodoAberturaFinal.copy());
  const nomeDevedor = useSelector(states => states.historicoAS.requestFields.nomeDevedor.copy());
  const nroProcesso = useSelector(states => states.historicoAS.requestFields.nroProcesso.copy());


  const buttonClassPesquisaAvancada = cx({
    [styles.pesquisaAvancadaButton]: !openPesquisaAvancada,
    [styles.pesquisaSimplesButton]: openPesquisaAvancada,
  });

  const validarCamposObrigatoriosPesquisa = useCallback(() => {
    if ((!nomeDevedor.value && !nroProcesso.value) && (!periodoAberturaInicial.value && !periodoAberturaFinal.value)) {
      dispatch(setDatasCamposObrigatoriosAction(periodoAberturaInicial.name));
      dispatch(setDatasCamposObrigatoriosAction(periodoAberturaFinal.name));
    } else if ((!nomeDevedor.value || !nroProcesso.value)) {
      if (periodoAberturaInicial.value && !periodoAberturaFinal.value) {
        dispatch(setDatasCamposObrigatoriosAction(periodoAberturaFinal.name));
      } else if (!periodoAberturaInicial.value && periodoAberturaFinal.value) {
        dispatch(setDatasCamposObrigatoriosAction(periodoAberturaInicial.name));
      } else { // Só as datas preenchidas
        dispatch(setCacheRequestFieldsAction());
        dispatch(setPageAction(0));
      }
    } else { // So os campos sem datas preenchidos.
      dispatch(setCacheRequestFieldsAction());
      dispatch(setPageAction(0));
    }
  }, [
    periodoAberturaInicial.value,
    periodoAberturaInicial.name,
    periodoAberturaFinal.value,
    periodoAberturaFinal.name,
    nomeDevedor.value,
    nroProcesso.value,
    dispatch,
  ]);

  const onChangeRequestFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeRequestFieldsAction(name, value));
  }, [dispatch]);

  const onFocusRequestFieldsHandler = useCallback((event) => {
    const { name } = event.target;
    dispatch(onFocusRequestFieldsAction(name));
  }, [dispatch]);

  const onClickPesquisarHandler = useCallback(() => {
    validarCamposObrigatoriosPesquisa();
  }, [validarCamposObrigatoriosPesquisa]);

  return (
    <>
      <div className={styles.div__root}>
        <div className={styles.div__rootAvancada}>
          {openPesquisaAvancada && (
            <div className={styles.div__pesquisaAvancada}>
              <div className={styles.field__devedor}>
                <InputField
                  fullWidth
                  label="Nome do Devedor"
                  name={nomeDevedor.name}
                  value={nomeDevedor.value}
                  onChange={onChangeRequestFieldsHandler}
                  onFocus={onFocusRequestFieldsHandler}
                />
              </div>
              <div className={styles.field__nroProcesso}>
                <InputField
                  fullWidth
                  label="Nº Processo"
                  name={nroProcesso.name}
                  value={nroProcesso.value}
                  onChange={onChangeRequestFieldsHandler}
                  onFocus={onFocusRequestFieldsHandler}
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.div__fields}>
          <div>
            <PeriodField
              label="Período de Abertura do Processo (máximo 30 dias)"
              firstField={periodoAberturaInicial}
              lastField={periodoAberturaFinal}
              onChange={onChangeRequestFieldsHandler}
              onFocus={onFocusRequestFieldsHandler}
            />
          </div>
          <div>
            <div>
              <div>
                <IconButton
                  type="submit"
                  className={styles.div__pesquisarBtn}
                  onClick={onClickPesquisarHandler}
                >
                  <SearchIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.div__pesquisaAvancadaBtn}>
        <Button className={buttonClassPesquisaAvancada} onClick={onClickPesquisaAvancadaHandler}>
          {openPesquisaAvancada ? 'Pesquisa Simples' : 'Pesquisa Avançada'}
        </Button>
      </div>
    </>
  );
}

export default HistoricoPesquisaLayoutAS;
