import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PaperUI from '../UI/Paper/PaperUI';
import CardWrapper from '../UI/CardWrapper/CardWrapper';
import {
  useCrudFasesPastasDispatch,
  useCrudFasesPastasSelector,
} from '../../store/FasesPastas/Crud/CrudFasesPastasProvider';
import Grid24 from '../UI/Grid24/Grid24';
import { SELECTOR_LABEL } from './fasesPastas.constants';
import SelectorField from '../UI/Field/Selector/SelectorField';
import IdentificacaoFases from './Dados/IndentificacaoFasesPastas';
import useUtilStyles from '../../helpers/styles/utils.styles';
import { findFasesAction } from '../../store/Global/LoadSelectors/loadSelectors.saga';
import {
  changeEditModeAction,
  setRequestedFieldValueAction,
} from '../../store/FasesPastas/Crud/crudFasesPastas.store';
import { getFaseByIdAction, insertFasesAction } from '../../store/FasesPastas/Crud/crudFasesPastas.saga';
import TabCardLayout from '../../layouts/TabCardLayout/v2/TabCardLayout';
import TabModel from '../../models/TabModel';
import TabPanel from '../UI/TabPanel/TabPanel';
import PesquisaPastas from '../Pastas/TabsFasesPastas/RelacaoPasta';
import FooterForm from '../UI/FooterForm/FooterForm';
import { usePastaSelector } from '../../store/FasesPastas/Pastas/PastasProvicer';
import CampoObrigatorio from '../UI/CampoObrigatorio/CampoObrigatorio';
import RelacaoUsuarios from '../Pastas/TabsFasesPastas/RelacaoUsuario/RelacaoUsuarios';

function CrudFasesPastas({
  hasEditPermission = false,
}) {
  const globalDispatch = useDispatch();
  const dispatch = useCrudFasesPastasDispatch();

  const [tabItens] = useState([
    new TabModel('tab-pastas', 0, 'Relação de Pastas', 'scrollable-auto-tabpanel'),
    new TabModel('tab-relacao-usuario', 1, 'Relação de Usuários', 'scrollable-auto-tabpanel'),
  ]);
  const [activeTab, setActiveTab] = useState(0);

  const utilStyles = useUtilStyles();
  const showLoading = useCrudFasesPastasSelector(states => states.loading);
  const isEditMode = useCrudFasesPastasSelector(states => states.pageView.isEditMode);
  const selectedIdFase = useCrudFasesPastasSelector(states => states.requestedFields.idFase);

  const allFases = useSelector(states => states.selectors.fases.selector);
  const loadingFases = useSelector(states => states.selectors.fases.loading);

  const loadingPastas = usePastaSelector(states => states.loading);


  const changeTabViewHandler = useCallback((activeTabParam) => {
    setActiveTab(activeTabParam);
  }, [setActiveTab]);

  useEffect(() => {
    globalDispatch(findFasesAction());
    dispatch(getFaseByIdAction(selectedIdFase.value));
  }, [globalDispatch, dispatch, selectedIdFase.value]);

  const onChangeFaseSelector = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setRequestedFieldValueAction(name, value));
    dispatch(getFaseByIdAction(value));
  }, [dispatch]);

  const setEditModeOn = useCallback(() => {
    dispatch(changeEditModeAction(true));
  }, [dispatch]);

  const setEditModeOff = useCallback(() => {
    dispatch(getFaseByIdAction(selectedIdFase.value));
    dispatch(changeEditModeAction(false));
  }, [dispatch, selectedIdFase.value]);

  const disableFields = () => !hasEditPermission || !isEditMode;

  const onSubmit = useCallback(() => {
    dispatch(insertFasesAction());
  }, [dispatch]);

  return (
    <>
      <PaperUI>
        <CardWrapper showLoading={showLoading || loadingPastas}>
          <CampoObrigatorio visible={isEditMode} />
          <Grid24 container isFormContainer spacing="1">
            <Grid24 item xs={24} sm={5}>
              <SelectorField
                fullWidth
                isForm
                disabled={false}
                loading={loadingFases}
                label={SELECTOR_LABEL}
                labelProps={{ className: utilStyles.labelFaseSelector }}
                items={allFases}
                name={selectedIdFase.name}
                value={selectedIdFase.value}
                onChange={onChangeFaseSelector}
              />
            </Grid24>
          </Grid24>
          <IdentificacaoFases
            disabled={disableFields()}
          />
        </CardWrapper>
      </PaperUI>
      <div className={utilStyles.mt16}>
        <TabCardLayout
          tabItems={tabItens}
          parentStates={[activeTab, changeTabViewHandler]}
          footerComponent={(
            <FooterForm
              editHandler={hasEditPermission && !isEditMode ? setEditModeOn : null}
              submitHandler={hasEditPermission && isEditMode ? onSubmit : null}
              cancelHandler={hasEditPermission && isEditMode ? setEditModeOff : null}
            />
          )}
        >
          <TabPanel id={tabItens[0].id} index={tabItens[0].index} value={activeTab}>
            <PesquisaPastas />
          </TabPanel>
          <TabPanel id={tabItens[1].id} index={tabItens[1].index} value={activeTab}>
            <RelacaoUsuarios />
          </TabPanel>
        </TabCardLayout>
      </div>
    </>
  );
}

export default withRouter(CrudFasesPastas);
