import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';

function TableHeaderConsultaDinamicaResultComponent({ columnsNames = [] }) {
  return (
    <TableRow>
      {columnsNames.map((name => <TableCell key={name} height="32px">{name}</TableCell>))}
    </TableRow>
  );
}

export const TableHeaderConsultaDinamicaResult = TableHeaderConsultaDinamicaResultComponent;
