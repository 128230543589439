import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForever from '@material-ui/icons/DeleteForever';
import PermissionIconButton from '../../UI/Button/Permission/IconButton/PermissionIconButton';
import { Container } from './styles';
import { useCrudFasesPastasSelector } from '../../../store/FasesPastas/Crud/CrudFasesPastasProvider';

function PastasActions({
  onEdit,
  onRemove,
  allows,
}) {
  const isEditMode = useCrudFasesPastasSelector(states => states.pageView.isEditMode);

  return (
    <Container>
      <PermissionIconButton
        idAllow={allows.editar}
        userAllow="U"
        title="Editar"
        onClick={onEdit}
        isEnable={isEditMode}
      >
        <EditIcon />
      </PermissionIconButton>
      <PermissionIconButton
        title="Excluir"
        idAllow={allows.excluir}
        userAllow="U"
        onClick={onRemove}
        isEnable={isEditMode}
      >
        <DeleteForever />
      </PermissionIconButton>
    </Container>
  );
}

export default PastasActions;
