import styled from 'styled-components';
import React from 'react';

export const FooterFormButtonGroup = React.memo(styled.div`
  position: relative;
  float: right;
  margin-top: 18px;

  button:nth-child(1){
    width: 100px;
    margin-right: 10px;
  }

`);
