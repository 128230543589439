import React from 'react';
import OrdenacaoColuna from '../../../components/UI/Tabela/Ordenacao/OrdenacaoColuna';
import TableHeader from '../../../models/TableHeader';

export function buildTableHeader(arrowDirection, columnOrdenacao, setArrowOrdenacao, setColumnOrdenacao, onClickOrdernacaoColumn, buildCheckboxSelectAll) {
  return ([
    new TableHeader({
      id: 'selecionarTodos',
      label: buildCheckboxSelectAll(),
      maxWidth: 35,
    }),
    new TableHeader({
      id: 'codigoCliente',
      label: <OrdenacaoColuna
        columnLabel="Cód. Cliente"
        idColumn="codigoCliente"
        arrowOrdenacao={arrowDirection}
        columnOrdenacao={columnOrdenacao}
        setArrowStoreFunction={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={onClickOrdernacaoColumn}
      />,
      showTip: true,
      maxWidth: 75,
    }),
    new TableHeader({
      id: 'nomeCliente',
      label: <OrdenacaoColuna
        columnLabel="Cliente"
        idColumn="nomeCliente"
        arrowOrdenacao={arrowDirection}
        columnOrdenacao={columnOrdenacao}
        setArrowStoreFunction={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={onClickOrdernacaoColumn}
      />,
      showTip: true,
      maxWidth: 180,
    }),
    new TableHeader({
      id: 'nomeDevedor',
      label: <OrdenacaoColuna
        columnLabel="Devedor"
        idColumn="nomeDevedor"
        arrowOrdenacao={arrowDirection}
        columnOrdenacao={columnOrdenacao}
        setArrowStoreFunction={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={onClickOrdernacaoColumn}
      />,
      showTip: true,
      maxWidth: 190,
    }),
    new TableHeader({
      id: 'quantidadeTitulos',
      label: 'Qtde Títulos',
      alignCell: 'center',
      maxWidth: 47,
    }),
    new TableHeader({
      id: 'valorTotal',
      label: <OrdenacaoColuna
        columnLabel="Valor Total"
        idColumn="valorTitulo"
        arrowOrdenacao={arrowDirection}
        columnOrdenacao={columnOrdenacao}
        setArrowStoreFunction={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={onClickOrdernacaoColumn}
      />,
      maxWidth: 126,
      showTip: true,
      alignCell: 'flex-end',
    }),
    new TableHeader({
      id: 'valorMedio',
      label: 'Valor Médio',
      maxWidth: 107,
      showTip: true,
      alignCell: 'flex-end',
    }),
    new TableHeader({
      id: 'idadeMedia',
      label: 'Média de dias vencidos',
      maxWidth: 87,
      alignCell: 'center',
    }),
    new TableHeader({
      id: 'dataCadastramento',
      label: <OrdenacaoColuna
        columnLabel="Cadastramento"
        idColumn="dataCadastramento"
        arrowOrdenacao={arrowDirection}
        columnOrdenacao={columnOrdenacao}
        setArrowStoreFunction={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={onClickOrdernacaoColumn}
      />,
      maxWidth: 117,
    }),
    new TableHeader({
      id: 'inspecionar',
      label: '',
      maxWidth: 45,
      alignCell: 'flex-end',
    }),
  ]);
}
