import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { Button, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import Grid24 from '../../../../components/UI/Grid24';
import useStyle from '../AcordosStyle';

import { dialogAlterarParcelaChequeController } from './dialogAlterarParcelaChequeController';
import { extractResponseErrorMessage, getFieldName } from '../../../../helpers';
import { loadingInspecaoProcessoController } from '../../loadingInspecaoProcessoController';

function DialogAlterarCheque({
  setOpenDialogAlterarCheque,
  dadosParcelaCheque,
  idProcesso,
  nroParcela,
  idAcordo,
  idAcordoParcela,
  tipoFormPgtp,
  setLoadings,
  updatePage,
}) {
  const style = useStyle();

  const [formFields, setFormFields] = useState(dialogAlterarParcelaChequeController.makeFormFields(dadosParcelaCheque));

  const onChangeHandler = useCallback((e) => {
    const name = getFieldName(e.target);
    const { value } = e.target;
    setFormFields(oldValue => ({ ...oldValue, [name]: { ...oldValue[name], value } }));
  }, []);


  async function onClickSubmitParcela(e) {
    e.preventDefault();
    setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(true));
    try {
      await dialogAlterarParcelaChequeController.salvar(idProcesso,
        nroParcela,
        idAcordo,
        idAcordoParcela,
        tipoFormPgtp, formFields);
      setOpenDialogAlterarCheque(false);
      updatePage();
    } catch (error) {
      const erroMessage = extractResponseErrorMessage(error);
      if (erroMessage !== 'Erro não mapeado') {
        toast.error(erroMessage);
      }
    } finally {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(false));
    }
  }


  useEffect(() => {
    setFormFields(dialogAlterarParcelaChequeController.makeFormFields(dadosParcelaCheque));
  }, [dadosParcelaCheque]);

  return (
    <>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={24} spacing="1">
          <Grid24 item xs={24} sm={24} className={style.dialogAlterarParcelaDinheiro}>
            <div style={{ display: 'grid', marginTop: '18px' }}>
              <TextField
                name={formFields.observacaoCheque.name}
                value={formFields.observacaoCheque.value}
                onChange={onChangeHandler}
                multiline
                rows={4}
                id="outlined-multiline-static"
                label="Observações"
              />
            </div>
          </Grid24>
        </Grid24>
      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          onClick={() => setOpenDialogAlterarCheque(false)}
        >
          Cancelar
        </Button>
        <Button onClick={onClickSubmitParcela}>
          Confirmar
        </Button>
      </div>

    </>
  );
}
export default memo(DialogAlterarCheque);
