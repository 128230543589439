import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  containerTooltip: {
    width: '100%',
  },
  tooltipMaxWidth: {
    maxWidth: '500px',
  },
});
