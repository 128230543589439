import React, { useCallback, useRef } from 'react';

import { Avatar, Typography } from '@material-ui/core';

import cx from 'classnames';
import ButtonUI from '../Button/ButtonUI';
import SnackbarUI from '../Snackbar/Snackbar';

import DefaultAvatarImage from '../../../assets/images/user-alt-avatar.png';
import LogoTipoImage from '../../../assets/images/logotipo.png';


import useStyles from './ImageSelector.styles';

import { SNACKBAR_DURATION } from '../../../helpers/constants/global.constants';

function ImageSelector({
  image,
  disabled = false,
    size = 100000, // 100KB
  accept = 'image/png,image/jpeg',
  maxLenghtText,
  componentType = 'usuario',
  errorImage = false,
  setErrorImage,
  onClearErrorImage,
  errorMessageLenght = '',
  errorMessageType = '',
}) {
  const styles = useStyles();
  const fileRef = useRef();
  const [img, setImg] = image;
  const acceptValidate = accept.split(',');

  const wrapperStyles = cx(styles.wrapper,
    {
      [styles.wrapperDefault]: componentType === 'usuario',
      [styles.wrapperLogoTipo]: componentType === 'logoTipo',
      [styles.errorImage]: errorImage,
    });

  const selectImageHandler = useCallback(() => {
    fileRef.current.click();
    if (errorImage) {
      onClearErrorImage();
    }
  }, [errorImage, onClearErrorImage]);

  const imageChangeHandler = (event) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      if (!acceptValidate.includes(file.type)) {
        setImg({ ...img, error: true, errorMessage: errorMessageType });
        fileRef.current.value = null;
        setErrorImage();
        return;
      }
      if (file.size > size) {
        setImg({ ...img, error: true, errorMessage: errorMessageLenght });
        fileRef.current.value = null;
        setErrorImage();
        return;
      }
      setImg({
        ...img,
        file,
        imagePreviewUrl: reader.result,
        error: false,
      });
    };

    if (file instanceof Blob) {
      reader.readAsDataURL(file);
    }
  };

  const closeSnackbarHandler = () => {
    setImg({ ...img, error: false });
  };

  return (
    <div className={wrapperStyles}>
      <SnackbarUI
        open={img.error}
        autoHideDuration={SNACKBAR_DURATION}
        variant="error"
        message={img.errorMessage}
        onClose={closeSnackbarHandler}
      />
      <input
        type="file"
        onChange={imageChangeHandler}
        ref={fileRef}
        accept={accept}
      />
      {img.imagePreviewUrl ? (
        <Avatar src={img.imagePreviewUrl} alt="Avatar" />
      ) : <Avatar src={componentType === 'usuario' ? DefaultAvatarImage : LogoTipoImage} alt="Avatar" />}

      {!disabled && (
        <div style={{ marginTop: '10px' }}>
          <div className={styles.button}>
            <ButtonUI onClick={selectImageHandler}>
              Selecionar
            </ButtonUI>
          </div>
          <div>
            <Typography component="p">{maxLenghtText}</Typography>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(ImageSelector);
