import makeStyles from '@material-ui/core/styles/makeStyles';
import { blue } from '../../../helpers/constants/colors.constants';

export default makeStyles({
  wrapper: props => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: props.marginTop,
    '& .Mui-checked': {
      color: blue,
    },
    '& span.MuiSwitch-root': {
      color: blue,
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: 'black',
    },
    ' & span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
      fontSize: '12px',
    },
  }),
});
