import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import HeaderCrudBordero from './Header';
import DadosAdvogadoCrudBordero from './DadosAdvogado/DadosAdvogadoCrudBordero';
import DadosClienteCrudBordero from './DadosCliente';
import DadosDevedorCrudBordero from './DadosDevedor/DadosDevedorCrudBordero';
import EnderecosCrudBordero from './Enderecos/EnderecosCrudBordero';
import TelefonesCrudBordero from './Telefones/TelefonesCrudBordero';
import EmailsCrudBordero from './Emails/EmailsCrudBordero';
import TitulosCrudBordero from './Titulos/TitulosCrudBordero';
import InstrucoesCrudBordero from './Instrucoes/InstrucoesCrudBordero';
import PaperUI from '../../UI/Paper/PaperUI';
import CardWrapper from '../../UI/CardWrapper/CardWrapper';
import FooterForm from '../../UI/FooterForm/FooterForm';

import {
  setAttributeAction,
  setDialogAction,
  setFocusAction,
  setSuccessDialogAction,
  clearAllAction,
  clearBorderoAction,
} from '../../../store/Borderos/Crud/CrudBordero.store';
import { findBoderoByIdAction, submitAction } from '../../../store/Borderos/Crud/CrudBorderos.saga';

import { useCrudBorderoSelector, useCrudBoderoDispatch } from '../../../store/Borderos/Crud/CrudBorderoProvider';
import {
  findCategoriasAction, findEstadosAction, findTiposTelefoneAction, findTiposTituloAction,
} from '../../../store/Global/LoadSelectors/loadSelectors.saga';

import withTokenInterceptor from '../../../hoc/withTokenInterceptor/withTokenInterceptor';
import { moveScroll } from '../../../helpers/utils/utils';
import OptionDialogNew from '../../UI/Dialogs/OptionDialog/OptionDialogNew';


function CrudBordero({
  match, history, mode, location,
}) {
  const globalDispatch = useDispatch();
  const dispatch = useCrudBoderoDispatch();

  const dialog = useCrudBorderoSelector(states => states.dialog);
  const optionDialog = useCrudBorderoSelector(states => states.successDialog);
  const disabledForm = useCrudBorderoSelector(states => states.requestFields.cliente.idCliente.value === '');

  const loadingCategorias = useSelector(states => states.selectors.categorias.loading);
  const loadingEstados = useSelector(states => states.selectors.estados.loading);
  const loadingTiposTelefone = useSelector(states => states.selectors.tiposTelefone.loading);
  const loadingTiposTitulo = useSelector(states => states.selectors.tiposTitulo.loading);
  const loadingBordero = useCrudBorderoSelector(states => states.loading.global);


  const [mvScroll, setMvScroll] = useState(false);
  const isInspectMode = useCallback(() => mode === 'inspect', [mode]);

  useEffect(() => {
    const findBorderoById = () => {
      if (match && match.params.id) {
        dispatch(findBoderoByIdAction(match.params.id));
      }
    };

    if (isInspectMode()) {
      findBorderoById();
    }
  }, [dispatch, history, match, isInspectMode]);

  useEffect(() => {
    moveScroll();
  }, [mvScroll]);

  useEffect(() => {
    globalDispatch(findCategoriasAction());
    globalDispatch(findEstadosAction());
    globalDispatch(findTiposTelefoneAction());
    globalDispatch(findTiposTituloAction());
  }, [globalDispatch, location.refreshPage]);

  const wrapperByMode = (componentRegister, componentInspect) => {
    if (isInspectMode()) {
      return componentInspect;
    }
    return componentRegister;
  };

  const showLoading = () => loadingCategorias || loadingEstados || loadingTiposTelefone || loadingBordero || loadingTiposTitulo;

  const changeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setAttributeAction(name, value));
  }, [dispatch]);

  const clearErrorFieldsHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(setFocusAction(fieldName));
  }, [dispatch]);

  const goBackHandler = useCallback(() => {
    const replace = isInspectMode() ? '/processar-borderos' : '/';
    history.replace(replace);
  }, [history, isInspectMode]);

  const closeAlertDialogHandler = () => {
    dispatch(clearBorderoAction());
    dispatch(setDialogAction(false));
    if (dialog.origin === 'INSPECT_SCREEN_BORDERO_NOT_FOUND') {
      history.replace('/processar-borderos');
    }
  };

  const closeOptionDialogHandler = () => {
    dispatch(setSuccessDialogAction(false));
  };

  const clearAllFieldsHandler = () => {
    dispatch(clearAllAction());
    setMvScroll(!mvScroll);
  };

  const clearBorderoFieldsHandler = () => {
    dispatch(clearBorderoAction());
    setMvScroll(!mvScroll);
  };

  const submitHandler = () => {
    dispatch(submitAction());
  };

  return (
    <>
      <OptionDialogNew
        open={dialog.open}
        variant="warning"
        confirmLabel="OK, ENTENDI"
        onClick={closeAlertDialogHandler}
      >
        <div style={{
          width: '480px', height: '29.5px', fontSize: '16px', textAlign: 'left',
        }}
        >
          <p>
            {dialog.message}
          </p>
        </div>
      </OptionDialogNew>

      <OptionDialogNew
        open={optionDialog.open}
        confirmLabel="Sim"
        cancelLabel="Não"
        onClickConfirm={clearBorderoFieldsHandler}
        onClickCancel={clearAllFieldsHandler}
        onClose={closeOptionDialogHandler}
      >
        <div style={{ fontSize: '18px' }}>
          <p>
            Borderô incluído com sucesso!
            <br />
            Deseja incluir um novo Borderô para o mesmo cliente?
          </p>
        </div>
      </OptionDialogNew>
      <PaperUI>
        <CardWrapper showLoading={showLoading()}>
          <HeaderCrudBordero wrapperByMode={wrapperByMode} />
          <DadosClienteCrudBordero wrapperByMode={wrapperByMode} />
          <DadosDevedorCrudBordero
            disabled={disabledForm}
            wrapperByMode={wrapperByMode}
            isInspectMode={isInspectMode()}
            changeFields={changeFieldsHandler}
            clearErrorFields={clearErrorFieldsHandler}
          />
          <EnderecosCrudBordero
            disabled={disabledForm}
            isInspectMode={isInspectMode()}
          />
          <TelefonesCrudBordero
            disabled={disabledForm}
            isInspectMode={isInspectMode()}
          />
          <EmailsCrudBordero
            disabled={disabledForm}
            isInspectMode={isInspectMode()}
          />
          <DadosAdvogadoCrudBordero
            disabled={disabledForm}
            isInspectMode={isInspectMode()}
            changeFields={changeFieldsHandler}
          />
          <TitulosCrudBordero
            disabled={disabledForm}
            isInspectMode={isInspectMode()}
          />
          <InstrucoesCrudBordero
            disabled={disabledForm}
            wrapperByMode={wrapperByMode}
            isInspectMode={isInspectMode()}
            changeFields={changeFieldsHandler}
          />
        </CardWrapper>
        <FooterForm
          hasRollback={isInspectMode()}
          disabledSubmit={disabledForm}
          rollbackHandler={goBackHandler}
          rollbackWithOtherButtons
          cancelHandler={mode !== 'register' ? goBackHandler : null}
          submitHandler={submitHandler}
        />
      </PaperUI>
    </>
  );
}

export default withTokenInterceptor(withRouter(CrudBordero));
