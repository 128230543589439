import React from 'react';
import PrivateRoute from '../hoc/PrivateRoute/PrivateRoute';

import PesquisarProcessosPage from '../pages/Processos/Pesquisar/PesquisarProcessosPage';
import { InspecionarProcessoPage } from '../pages/InspecionarProcesso/InspecionarProcessoPage';

function RoutesProcessosComponent() {
  return (
    <>
      <PrivateRoute
        path="/processos"
        tipoUsuario="U"
        exact
        component={PesquisarProcessosPage}
      />
      <PrivateRoute
        exact
        tipoUsuario="U"
        path="/processos/inspecao"
        component={InspecionarProcessoPage}
      />
    </>
  );
}

export const RoutesProcessos = RoutesProcessosComponent;
