import React from 'react';
import PrivateRoute from '../hoc/PrivateRoute/PrivateRoute';
import NovoLancamentoPage from '../pages/Lancamento/NovoLancamentoPage';
import EditarLancamentoPage from '../pages/Lancamento/EditarLancamentoPage';
import { ContaCorrenteComissionadoPage } from '../pages/Comissionado/ContaCorrenteComissionadoPage';
import EditarContaCorrenteComissionadoPage from '../pages/LancamentosComissionados/EditarContaCorrenteComissionadoPage';
import CadastrarContaCorrenteComissionadoPage from '../pages/LancamentosComissionados/CadastrarContaCorrenteComissionadoPage';
import PrestarContasPage from '../pages/LancamentoPrestarConta/PrestarContasPage';
import { ContaCorrenteHonorarioPage } from '../pages/ContaCorrenteHonorario/ContaCorrenteHonorarioPage';
import { ContaCorrenteClientePage } from '../pages/ContaCorrenteCliente/ContaCorrenteClientePage';
import ContaCorrenteClientePageV2 from '../pages/ContaCorrenteCliente/V2/ContaCorrenteClientePage';
import RecebimentoClientesPage from '../pages/ContaCorrenteCliente/V2/RecebimentoClientes/RecebimentoClientesPage';
import RecebimentoClienteNovoPage from '../pages/ContaCorrenteCliente/V2/RecebimentoClientes/RecebimentoClienteNovoPage';
import RecebimentoClienteEditarPage from '../pages/ContaCorrenteCliente/V2/RecebimentoClientes/RecebimentoClienteEditarPage';
import RecebimentoDevedoresPage from '../pages/RecebimentoDevedores/RecebimentoDevedoresPage';
import PagamentoDiretoPage from '../pages/PagamentoDireto/PagamentoDiretoPage';

function RoutesFinanceiro() {
  return (
    <>
      {
      /*
      A rota caixa de filiais está comentada pois a funcionalidade foi desativada sem previsão de uso ou retorno.
      Em caso de reativação remova este comentário.

      <PrivateRoute
        tipoUsuario="U"
        exact
        roles={[]}
        path="/caixas-filiais"
        component={CaixaDeFiliaisPage}
      />
      */
    }
      <PrivateRoute
        tipoUsuario="U"
        exact
        roles={[]}
        path="/conta-corrente-comissionado"
        component={ContaCorrenteComissionadoPage}
      />

      <PrivateRoute
        path="/conta-corrente-comissionado/cadastrar"
        roles={['201']}
        tipoUsuario="U"
        exact
        component={CadastrarContaCorrenteComissionadoPage}
      />

      <PrivateRoute
        path="/conta-corrente-comissionado/editar"
        roles={['201']}
        tipoUsuario="U"
        exact
        component={EditarContaCorrenteComissionadoPage}
      />

      <PrivateRoute
        roles={['1502', '1503']}
        path="/financeiro-lancamentos/cadastrar"
        exact
        tipoUsuario="U"
        component={NovoLancamentoPage}
      />
      <PrivateRoute
        roles={['1502', '1503']}
        path="/financeiro-lancamentos/editar"
        exact
        tipoUsuario="U"
        component={EditarLancamentoPage}
      />
      <PrivateRoute
        tipoUsuario="U"
        exact
        roles={[]}
        path="/conta-corrente-comissionado/prestar-contas"
        component={PrestarContasPage}
      />
      <PrivateRoute
        tipoUsuario="U"
        exact
        roles={[]}
        path="/conta-corrente-honorario"
        component={ContaCorrenteHonorarioPage}
      />
      <PrivateRoute
        tipoUsuario="U"
        exact
        roles={['1801', '1802', '1803', '1804']}
        path="/conta-corrente-cliente/v1"
        component={ContaCorrenteClientePage}
      />
      <PrivateRoute
        tipoUsuario="U"
        exact
        roles={['1801', '1802', '1803', '1804']}
        path="/conta-corrente-cliente"
        component={ContaCorrenteClientePageV2}
      />
      <PrivateRoute
        tipoUsuario="U"
        exact
        roles={['1902']}
        path="/recebimento-clientes"
        component={RecebimentoClientesPage}
      />
      <PrivateRoute
        tipoUsuario="U"
        exact
        path="/recebimento-clientes/novo"
        component={RecebimentoClienteNovoPage}
      />
      <PrivateRoute
        tipoUsuario="U"
        exact
        path="/recebimento-clientes/Editar"
        component={RecebimentoClienteEditarPage}
      />
      <PrivateRoute
        tipoUsuario="U"
        exact
        path="/recebimento-devedores"
        component={RecebimentoDevedoresPage}
      />
      <PrivateRoute
        tipoUsuario="U"
        exact
        path="/pagamento-direto"
        roles={['1207']}
        component={PagamentoDiretoPage}
      />
    </>
  );
}

export default RoutesFinanceiro;
