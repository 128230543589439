import axios from 'axios';
import { makeOrderBy } from '../../../helpers/utils/query.utils';
import { getUriParams } from '../../../helpers/utils/utils';
import { tiposAcompanhamentos } from '../../../types/tiposAcompanhamentoTypes';
import {
  CORE, GPT_KEY, gateway, gatewayShortJson,
} from '../../api';

export function buscarProcessoComFiltrosSimples(filtro, page, size, ordenacao) {
  const queryParams = getUriParams({ ...filtro, page, size });
  const orderBy = makeOrderBy(ordenacao);
  return gateway.get(`${CORE}/processos?${queryParams}${orderBy}`);
}

export function buscarProcessoComFiltrosAvancada(filtro, page, size, ordenacao) {
  const queryParams = getUriParams({ ...filtro, page, size });
  const orderBy = makeOrderBy(ordenacao);
  return gateway.get(`${CORE}/processos/avancada?${queryParams}${orderBy}`);
}

export function buscarProcessoComFiltrosEspecial(filtro, page, size, ordenacao) {
  const queryParams = getUriParams({ ...filtro, page, size });
  const orderBy = makeOrderBy(ordenacao);
  return gateway.get(`${CORE}/processos/especial?${queryParams}${orderBy}`);
}

export function pesquisarProcessosComNotificacoes(page, size, ordenacao) {
  const queryParams = getUriParams({ page, size });
  const orderBy = makeOrderBy(ordenacao);
  return gateway.get(`${CORE}/processos/notificacoes?${queryParams}${orderBy}`);
}

export function buscarIdsProcessosComNotificacoes() {
  return gateway.get(`${CORE}/processos/notificacoes/ids`);
}

export function buscarIdsProcessos(filtro, tipoPesquisa) {
  const queryParams = getUriParams(filtro);
  const tipoPesquisaParam = getUriParams({ tipoPesquisa });
  return gateway.get(`${CORE}/processos/ids?${queryParams}&${tipoPesquisaParam}`);
}

export function buscarIdsAvancadoProcessos(filtro, tipoPesquisa) {
  const queryParams = getUriParams(filtro);
  const tipoPesquisaParam = getUriParams({ tipoPesquisa });
  return gateway.get(`${CORE}/processos/ids-avancado?${queryParams}&${tipoPesquisaParam}`);
}

export function validarQuantidadeProcessosSelecionadoService(quantidadeSelecionada) {
  return gatewayShortJson.get(`${CORE}/processos/${quantidadeSelecionada}/qtdSelecionada`);
}

export function updateTipoExibicaoAcompanhamento(idProcesso, idProcessoAcompanhamento, tipoExibicao) {
  return gateway.put(`${CORE}/processos/${idProcesso}/acompanhamentos/${idProcessoAcompanhamento}/tipo-exibicao/${tipoExibicao}`);
}

export function buscarAcompanhamentoProcesso(numeroProcesso, sorted = [], queryParams) {
  const getQueryParam = () => {
    if (queryParams) {
      return `&${getUriParams(queryParams)}`;
    }
    return '';
  };


  const sortedQuery = sorted.reduce((obj, sort) => (
    `${obj}&sort=${sort}`
  ), '');

  return gateway.get(`${CORE}/processos/${numeroProcesso}/acompanhamentos?${sortedQuery}${getQueryParam()}`);
}

export function findInformacoesAdicionaisInpescaoProcessoService(idProcesso, requisicoesUsuarioTemPermissao = []) {
  const queryParams = requisicoesUsuarioTemPermissao.reduce((prev, requisicao) => {
    if (prev === '') {
      return `?idRequisicaoUsuario=${requisicao}`;
    }
    return `${prev}&idRequisicaoUsuario=${requisicao}`;
  }, '');
  return gateway.get(`${CORE}/processos/${idProcesso}/inspecao${queryParams}`);
}

export function findAutoresAcompanhamentosService(idProcesso) {
  return gatewayShortJson.get(`${CORE}/processos/${idProcesso}/autores-acompanhamentos`);
}

export function buscarUltimoProcessoAcompanhamento(idProcesso, tipos = [tiposAcompanhamentos.RELATORIO.value]) {
  const tiposQueryParam = tipos.reduce((acc, tipo) => {
    if (acc.length === 0) {
      return `?tipoAcompanhamento=${tipo}`;
    }
    return `${acc}&tipoAcompanhamento=${tipo}`;
  }, '');
  return gateway.get(`${CORE}/processos/${idProcesso}/processo-acompanhamento${tiposQueryParam}`);
}

export function buscarIsUsuarioAssistente() {
  return gateway.get(`${CORE}/processos/isUsuarioAssistente`);
}

export function findTiposAcompanhamentosProcessoService(idsTipoAcompanhamento) {
  let queryParam = '';
  if (idsTipoAcompanhamento) {
    queryParam = `&${getUriParams({ tipoAcompanhamentos: idsTipoAcompanhamento })}`;
  }

  return gatewayShortJson.get(`${CORE}/processos/tipo-acompanhamentos${queryParam}`);
}

export function inserirNovoAcompanhamento(idProcesso, idTipoAcompanhamento, texto) {
  return gateway.post(`${CORE}/processos/acompanhamento`, {
    idProcesso,
    idTipoAcompanhamento,
    texto,
  });
}

export function getTiposAgendamentoProcessoService() {
  return gatewayShortJson.get(`${CORE}/processos/tipo-agendamentos`);
}

export function getUsuariosNegociadoresProcessoService() {
  return gatewayShortJson.get(`${CORE}/processos/buscar-negociadores`);
}

export function buscarResumoDoProcessoService(idProcesso) {
  const params = getUriParams({ idProcesso });
  return gateway.get(`${CORE}/processos/resumo-processo?${params}`);
}

export function atualizarEndossanteDoProcessoService(idProcesso, endossante) {
  const params = getUriParams({ endossante });
  return gateway.put(`${CORE}/processos/${idProcesso}/endossante?${params}`);
}

export function buscarAgendamentoProcessoService(idProcesso) {
  return gateway.get(`${CORE}/processos/${idProcesso}/agendamento`);
}

export function agendarCompromissoProcessoSerivce(agendamentoCompromisso) {
  return gateway.post(`${CORE}/processos/agendamento`, agendamentoCompromisso);
}

export function excluirAgendamentoCompromissoProcessoService(idProcesso) {
  return gateway.delete(`${CORE}/processos/${idProcesso}/agendamento`);
}

export function alterarExistirCobrancaJudicialDoProcessoService(idProcesso, ajuizarProcesso) {
  const params = getUriParams({ idProcesso, ajuizarProcesso });
  return gateway.put(`${CORE}/processos/processo-ajuizado?${params}`);
}

export function alterarProtegerCodigoConsumidorDoProcessoService(idProcesso, protegerCodigoConsumidor) {
  const params = getUriParams({ idProcesso, protegerCodigoConsumidor });
  return gateway.put(`${CORE}/processos/proteger-codigo-consumidor?${params}`);
}

export function alterarPermitirCobrancaPresencialService(idProcesso, permitirCobrancaPresencial) {
  const params = getUriParams({ idProcesso, permitirCobrancaPresencial: !permitirCobrancaPresencial });
  return gateway.put(`${CORE}/processos/permitir-cobranca-presencial?${params}`);
}

export function enviarClienteEmailProcessoService(payload) {
  return gateway.post(`${CORE}/processos/cliente-email?fases=${payload.fases}`, payload);
}

export function buscarClienteEmailProcessoService(idProcesso) {
  return gateway.get(`${CORE}/processos/${idProcesso}/email-cliente`);
}

export function buscarCadastroCliente(idProcesso) {
  return gateway.get(`${CORE}/processos/${idProcesso}/clientes`);
}

export function buscarWarningsDevedorProcesso(idProcesso) {
  return gateway.get(`${CORE}/processos/${idProcesso}/devedor-warnings`);
}

export function melhorarTextService(content) {
  const headers = {
    Authorization: `Bearer ${GPT_KEY}`,
    ContentType: 'application/json',
  };
  const requestBody = {
    model: 'gpt-3.5-turbo',
    messages: [
      {
        role: 'user',
        content,
      },
    ],
    temperature: 0.8,
  };

  const response = axios.post(
    'https://api.openai.com/v1/chat/completions',
    requestBody,
    {
      headers,
    },
  );

  return response;
}

export function buscarFasesAndInfoAtuaisProcessos(idsProcessos) {
  return gateway.post(`${CORE}/processos/get-fases-atuais-info`,
    idsProcessos);
}

export function buscarNegociadoresHabilitadosProcesso(idProcesso) {
  return gateway.get(`${CORE}/processos/${idProcesso}/negociadores-habilitados`);
}

export function buscarNegociadorAtualProcesso(idProcesso) {
  return gateway.get(`${CORE}/processos/${idProcesso}/negociador-atual`);
}

export function submitTrocarNegociadorAtualProcesso(idProcesso, novoNegociadorId) {
  return gateway.put(`${CORE}/processos/${idProcesso}/novo-negociador/${novoNegociadorId}`);
}

export function buscarDevedorGrupo(idDevedor, idDevedorAtual) {
  return gateway.get(`${CORE}/processos/${idDevedorAtual}/devedor-associado/${idDevedor}`);
}

export function getTiposTituloAgendamentoProcessoService() {
  return gatewayShortJson.get(`${CORE}/processos/titulo-compromisso`);
}
