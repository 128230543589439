import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles({
  wrapper: props => ({
    paddingTop: props.withChecked ? '0px' : '6px',
    paddingBottom: props.withChecked ? '0px' : '6px',
    paddingLeft: '0px',
    '& p': {
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '29px',
    },
  }),
  checkbox: {
    height: 42,
    width: 32,
  },
});
