export const BUILD_PARAMETROS = '@parametros/BUILD';
export const ON_CHANGE_ACTIONS = '@parametros/ON_CHANGE_ACTIONS';
export const ON_CHANGE_ENDERECO = '@parametros/ON_CHANGE_ENDERECO';
export const CHANGE_TAB_PAGE_VIEW = '@parametros/ON_CHANGE_TAB_PAGE_VIEW_VALUE';
export const CHANGE_EDIT_MODE = '@parametros/ON_CHANGE_EDIT_MODE';
export const SET_TELEFONE_ATTRIBUTE_FOCUS = '@parametros/SET_TELEFONE_ATTRIBUTE_FOCUS';
export const SET_TELEFONE_ATTRIBUTE_VALUE = '@parametros/SET_TELEFONE_ATTRIBUTE_VALUE';
export const SET_TELEFONES = '@parametros/SET_TELEFONES';
export const SET_CONTA_PAGAMENTOS_GERAIS = '@parametros/SET_CONTA_PAGAMENTOS_GERAIS';
export const SET_ENDERECO = '@parametros/SET_ENDERECO';
export const SET_ENDERECO_ATTRIBUTE_VALUE = '@parametros/SET_ENDERECO_ATTRIBUTE_VALUE';
export const SET_ENDERECO_ATTRIBUTE_FOCUS = '@parametros/SET_ENDERECO_ATTRIBUTE_FOCUS';
export const SET_LOADING = '@parametros/SET_LOADING';
export const SET_ALERT_DIALOG = '@parametros/SET_ALERT_DIALOG';
export const ON_OPEN_SNACKBAR = '@parametros/OPEN_SNACKBAR';
export const SET_REQUEST_FIELDS_VALUE = '@parametros/SET_REQUEST_FIELDS_VALUE';
export const SET_REQUEST_FIELDS_FOCUS = '@parametros/SET_REQUEST_FIELDS_FOCUS';
export const SET_REQUEST_FIELDS = '@parametros/SET_REQUEST_FIELDS';
export const UPDATE_REQUEST_FIELDS = '@parametros/UPDATE_REQUEST_FIELDS';
export const SET_JUROS_ATTRIBUTE_FOCUS = '@parametros/SET_JUROS_ATTRIBUTE_FOCUS';
export const SET_JUROS_ATTRIBUTE_VALUE = '@parametros/SET_JUROS_ATTRIBUTE_VALUE';
export const SET_JUROS = '@parametros/SET_JUROS';
export const ADD_REPASSE = '@parametros/ADD_REPASSE';
export const UPDATE_REPASSE = '@parametros/UPDATE_REPASSE';
export const REMOVE_REPASSE = '@parametros/REMOVE_REPASSE';
export const SET_IMAGE = '@parametros/SET_IMAGE';
export const SET_FILE_CONFIG_PAGTO = '@parametros/SET_FILE_CONFIG_PAGTO';
export const SET_FILE_ARQUIVO_EXTRA_EMAIL_DEVEDOR = '@parametros/SET_FILE_ARQUIVO_EXTRA_EMAIL_DEVEDOR';
export const INSERT_PARAMETROS = '@parametros/INSERT_PARAMETROS';
export const GET_PARAMETROS = '@parametros/GET_PARAMETROS';
export const GET_FASES = '@parametros/GET_FASES';
export const SET_DESCONTO_PIX_ATTRIBUTE_FOCUS = '@parametros/SET_DESCONTO_PIX_ATTRIBUTE_FOCUS';
export const SET_DESCONTO_PIX_ATTRIBUTE_VALUE = '@parametros/SET_DESCONTO_PIX_ATTRIBUTE_VALUE';
export const SET_CONTA_BANCARIA_ATTRIBUTE_FOCUS = '@parametros/SET_CONTA_BANCARIA_ATTRIBUTE_FOCUS';
export const SET_CONTA_BOLETO_CLIENTE_ATTRIBUTE_FOCUS = '@parametros/SET_CONTA_BOLETO_CLIENTE_ATTRIBUTE_FOCUS';
export const SET_BOLETO_DEVEDOR_ATTRIBUTE_FOCUS = '@parametros/SET_BOLETO_DEVEDOR_ATTRIBUTE_FOCUS';
export const SET_CONTA_BANCARIA_ATTRIBUTE_VALUE = '@parametros/SET_CONTA_BANCARIA_ATTRIBUTE_VALUE';
export const SET_CONTA_BOLETO_CLIENTE_ATTRIBUTE_VALUE = '@parametros/SET_CONTA_BOLETO_CLIENTE_ATTRIBUTE_VALUE';
export const LIMPAR_ARQUIVO_QUITACAO_DEVEDOR_ACTION = '@parametros/LIMPAR_ARQUIVO_QUITACAO_DEVEDOR_ACTION';
export const LIMPAR_ARQUIVO_EXTRA_EMAIL_DEVEDOR_ACTION = '@parametros/LIMPAR_ARQUIVO_EXTRA_EMAIL_DEVEDOR_ACTION';
export const BUILD_FAIXAS_FASES_ACTION = '@parametros/BUILD_FAIXAS_FASES_ACTION';
export const ALTERAR_VALOR_HONORARIOS_MINIMO_FAIXA_FASE_ACTION = '@parametros/ALTERAR_VALOR_HONORARIOS_MINIMO_FAIXA_FASE_ACTION';
// export const SET_DESCONTO_PIX = '@parametros/SET_DESCONTO_PIX'; USAR QUANDO PIX VIRAR FAIXAS

export const SET_DESCONTOS_BOLETO = '@parametros/SET_DESCONTOS_BOLETO';
export const SET_DESCONTOS_BOLETO_ATTRIBUTE_FOCUS = '@parametros/SET_DESCONTOS_BOLETO_ATTRIBUTE_FOCUS';
export const SET_CONTA_DEPOSITO_CLIENTES_ATTRIBUTE_FOCUS = '@parametros/SET_CONTA_DEPOSITO_CLIENTES_ATTRIBUTE_FOCUS';
export const SET_CONTA_PAGAMENTOS_GERAIS_ATTRIBUTE_FOCUS = '@parametros/SET_CONTA_PAGAMENTOS_GERAIS_ATTRIBUTE_FOCUS';
export const SET_DESCONTOS_BOLETO_ATTRIBUTE_VALUE = '@parametros/SET_DESCONTOS_BOLETO_ATTRIBUTE_VALUE';
export const SET_CONTA_PAGAMENTOS_GERAIS_ATTRIBUTE_VALUE = '@parametros/SET_CONTA_PAGAMENTOS_GERAIS_ATTRIBUTE_VALUE';
export const SET_CONTA_DEPOSITO_CLIENTES_ATTRIBUTE_VALUE = '@parametros/SET_CONTA_DEPOSITO_CLIENTES_ATTRIBUTE_VALUE';
export const SET_TAXA_CARTAO_ATTRIBUTE_VALUE = '@parametros/SET_TAXA_CARTAO_ATTRIBUTE_VALUE';
export const SET_TAXA_CARTAO_FOCUS_VALUE = '@parametros/SET_TAXA_CARTAO_FOCUS_VALUE';
export const SET_TAXA_CARTAO = '@parametros/SET_TAXA_CARTAO';
export const SET_ADICIONAR_CAMPOS = '@parametros/SET_ADICIONAR_CAMPOS';
export const SET_ADICIONAR_CAMPOS_ATTRIBUTE_VALUE = '@parametros/SET_ADICIONAR_CAMPOS_ATTRIBUTE_VALUE';
export const SET_CONTA_DEPOSITO_ATTRIBUTE_VALUE = '@paramentros/SET_CONTA_DEPOSITO_ATTRIBUTE_VALUE';
export const SET_CONTA_DEPOSITO_DEVEDOR_ATTRIBUTE_FOCUS = '@parametros/SET_CONTA_DEPOSITO_DEVEDOR_ATTRIBUTE_FOCUS';
export const SET_ADICIONAR_CAMPOS_ATTRIBUTE_FOCUS = '@parametros/SET_ADICIONAR_CAMPOS_ATTRIBUTE_FOCUS';
export const ADD_CONTA_DEPOSITO_CLIENTE_VALUE = '@parametros/ADD_CONTA_DEPOSITO_CLIENTE_VALUE';
export const ADD_CONTA_PAGAMENTOS_GERAIS_VALUE = '@parametros/ADD_CONTA_PAGAMENTOS_GERAIS_VALUE';
export const ADD_CONTA_DEPOSITO_DEVEDORES_VALUE = '@parametros/ADD_CONTA_DEPOSITO_DEVEDORES_VALUE';
export const REMOVE_CONTA_DEPOSITO_CLIENTE_VALUE = '@parametros/REMOVE_CONTA_DEPOSITO_CLIENTE_VALUE';
export const SET_BOLETO_DEVEDOR_ATTRIBUTE_VALUE = '@parametros/SET_BOLETO_DEVEDOR_ATTRIBUTE_VALUE';
export const SET_OBJECTS = '@parametros/SET_OBJECTS';
export const SET_CONTA_DEPOSITO_ATTRIBUTE_FOCUS = '@parametros/SET_CONTA_DEPOSITO_ATTRIBUTE_FOCUS';
export const SET_OBJECT_ATTRIBUTE = '@parametros/SET_OBJECT_ATTRIBUTE';
