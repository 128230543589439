import styled from 'styled-components';
import { blue, white } from '../../helpers/constants/colors.constants';

export const RightDrawerContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1250;

  height: 100vh;

  width: ${props => props.width};

  transform-origin: right;
  transform: scaleX(${props => (props.open ? 1 : 0)});

  transition: all 0.2s ease;

  background-color: white;

  > button {
    position: absolute;
    top: 12px;
    left: -32px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 32px;
    height: 37px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    background: ${blue};
    cursor: pointer;

    svg {
      color: ${white};
    }
  }
`;
