import FieldModel from '../../../models/FieldModel';
import { buildSettingsTable } from '../../../helpers/factory/global.factory';


const ON_ONLY_CHANGE_ROWS_PER_PAGE_TITULO_COMPROMISSO = 'ON_ONLY_CHANGE_ROWS_PER_PAGE_TITULO_COMPROMISSO';
const ON_SUCESS_CADASTRO = 'ON_SUCESS_CADASTRO';
const ON_CHANGE_PAGE_TITULO_COMPROMISSO = 'ON_CHANGE_PAGE_TITULO_COMPROMISSO';
const ON_ASSIGN_PESQUISA_COPY = 'ON_ASSIGN_PESQUISA_COPY';
const ON_CHANGE_ROWS_PER_PAGE_TITULO_COMPROMISSO = 'ON_CHANGE_ROWS_PER_PAGE_TITULO_COMPROMISSO';
const ON_DIGITAR_PESQUISA_TITULO_COMPROMISSO = 'ON_PESQUISAR_TITULO_COMPROMISSO';
const ON_TABLE_CONTENT_TITULO_COMPROMISSO = 'ON_TABLE_CONTENT_TITULO_COMPROMISSO';
const ON_CHANGE_SIZE_TITULO_COMPROMISSO = 'ON_CHANGE_SIZE_TITULO_COMPROMISSO';
const ON_SET_PAGEABLE_TITULO_COMPROMISSO = 'ON_SET_PAGEABLE_TITULO_COMPROMISSO';
const ON_SET_ACTIONS_TITULO_COMPROMISSO = 'ON_SET_ACTIONS_TITULO_COMPROMISSO';
const ON_ID_TIPO_TITULO_DE_COMPROMISSO_DELETE = 'ON_ID_TIPO_TITULO_DE_COMPROMISSO_DELETE';
const ON_TITULO_COMPROMISSO_DELETE_MESSAGE = 'ON_TITULO_COMPROMISSO_DELETE_MESSAGE';
const ON_OPEN_DIALOG_TITULO_COMPROMISSO = 'ON_OPEN_DIALOG_TITULO_COMPROMISSO';
const ON_OPEN_DELETE_TITULO_COMPROMISSO = 'ON_OPEN_DELETE_TITULO_COMPROMISSO';
const ON_OPEN_CRUD_DIALOG_TITULO_COMPROMISSO = 'ON_OPEN_CRUD_DIALOG_TITULO_COMPROMISSO';
const ON_NOME_TITULO_COMPROMISSO_EDIT = 'ON_NOME_TITULO_COMPROMISSO_EDIT';
const ON_ID_TITULO_DE_COMPROMISSO_EDIT = 'ON_ID_TITULO_DE_COMPROMISSO_EDIT';
const ON_ACAO_CRUD_TITULO_COMPROMISSO = 'ON_ACAO_CRUD_TITULO_COMPROMISSO';
const ON_CONTENT_ERROR_TITULO_COMPROMISSO = 'ON_CONTENT_ERROR_TITULO_COMPROMISSO';
const ON_SHOULD_UPDATE_CONTENT_TITULO_COMPROMISSO = 'ON_SHOULD_UPDATE_CONTENT_TITULO_COMPROMISSO';
const SET_ONLY_LOAD_TITULO_COMPROMISSO = 'SET_ONLY_LOAD_TITULO_COMPROMISSO';

function buildInitialStates() {
  return {
    controls: {
      existsTituloCompromisso: false,
      acaoCrud: '',
      tituloCompromissoDeleteMsg: '',
      contentError: '',
      idTipoTituloDeCompromisso: null,
      nomeTituloCompromisso: '',
      tableContent: [],
      tablePageable: buildSettingsTable(),
      pesquisa: {
        fieldPesquisa: new FieldModel({ name: 'nome' }),
        pesquisaCopy: new FieldModel({ name: 'nome' }),
      },
    },
    actions: {
      openDialog: false,
      openDelete: false,
      loading: false,
      openSnackBar: false,
      removendo: false,
      fail: false,
      sucessCadastro: false,
      openCrudDialog: false,
      shouldUpdateContent: false,
    },
  };
}

function setOnlyLoadingHandler(states, action) {
  return {
    ...states,
    actions: {
      ...states.actions,
      loading: action.loading,
    },
  };
}

function shouldUpdateContentTituloCompromissoHandler(states, action) {
  return {
    ...states,
    actions: {
      ...states.actions,
      shouldUpdateContent: action.should,
    },
  };
}

function onNomeTituloCompromissoEditHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      nomeTituloCompromisso: action.nomeTituloCompromisso,
    },
  };
}

function onContentErrorHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      contentError: action.messageError,
    },
  };
}

function onAcaoCrudHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      acaoCrud: action.acaoCrud,
    },
  };
}

function onIdTipoTituloDeCompromissoEditHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      idTipoTituloDeCompromisso: action.idTipoTituloDeCompromisso,
    },
  };
}

function onSucessCadastroHandler(states, action) {
  return {
    ...states,
    actions: {
      ...states.actions,
      sucessCadastro: action.value,
    },
  };
}

function onAssignPesquisaCopyHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      pesquisa: {
        ...states.controls.pesquisa,
        pesquisaCopy: action.value,
      },
    },
  };
}


function onSetActionsHandler(states, action) {
  return {
    ...states,
    actions: {
      ...states.actions,
      loading: action.loading,
      failRequest: action.failRequest,
      openSnackBar: action.openSnackBar,
      removendo: action.removendo,
      fail: action.fail,
    },
  };
}


function onIdTipoTituloDeCompromissoDeleteHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      idTipoTituloDeCompromisso: action.idTipoTituloDeCompromissoToDelete,
    },
  };
}

function onOpenDialogTituloCompromissoHandler(states, action) {
  return {
    ...states,
    actions: {
      ...states.actions,
      openDialog: action.open,
    },
  };
}

function onOpenDeleteTituloCompromissoHandler(states, action) {
  return {
    ...states,
    actions: {
      ...states.actions,
      openDelete: action.open,
    },
  };
}


function onChangeSizeHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      tablePageable: {
        ...states.controls.tablePageable,
        size: action.size,
      },
    },
  };
}


const onTituloCompromissoMessageDeleteHandler = (states, action) => ({
  ...states,
  controls: {
    ...states.controls,
    tituloCompromissoDeleteMsg: action.message,
  },
});


const onChangePageHandler = (states, action) => ({
  ...states,
  controls: {
    ...states.controls,
    tablePageable: {
      ...states.controls.tablePageable,
      page: action.page,
    },
  },
});


const onChangeRowsPerPageHandler = (states, action) => ({
  ...states,
  controls: {
    ...states.controls,
    tablePageable: {
      ...states.controls.tablePageable,
      rowsPerPage: action.rowsPerPage,
    },
  },
});

const onOnlyChangeRowsPerPageHandler = (states, action) => ({
  ...states,
  controls: {
    ...states.controls,
    tablePageable: {
      ...states.controls.tablePageable,
      rowsPerPage: action.rowsPerPage,
    },
  },
});


const onSetTablePageableHandler = (states, action) => ({
  ...states,
  controls: {
    ...states.controls,
    tablePageable: {
      ...states.controls.tablePageable,
      page: action.page,
      rowsPerPage: action.rowsPerPage,
      size: action.size,
    },
  },
});


const onDigitarPesquisaHandler = (states, action) => {
  const pesquisaSimplesModel = states.controls.pesquisa.fieldPesquisa.getNewModel(action.value);
  return {
    ...states,
    controls: {
      ...states.controls,
      pesquisa: {
        ...states.controls.pesquisa,
        fieldPesquisa: pesquisaSimplesModel,
      },
    },
  };
};


const onTableContentHandler = (states, action) => ({
  ...states,
  controls: {
    ...states.controls,
    tableContent: action.tableContent,
  },
});


function onOpenCrudDialogHandler(states, action) {
  return {
    ...states,
    actions: {
      ...states.actions,
      openCrudDialog: action.open,
    },
  };
}

export const setOnlyLoadingAction = loading => ({
  type: SET_ONLY_LOAD_TITULO_COMPROMISSO,
  loading,
});

export const shouldUpdateContentTituloCompromissoAction = should => ({
  type: ON_SHOULD_UPDATE_CONTENT_TITULO_COMPROMISSO,
  should,
});

export const onNomeTituloCompromissoEditAction = nomeTituloCompromisso => ({
  type: ON_NOME_TITULO_COMPROMISSO_EDIT,
  nomeTituloCompromisso,
});

export const onContentErrorAction = messageError => ({
  type: ON_CONTENT_ERROR_TITULO_COMPROMISSO,
  messageError,
});

export const onAcaoCrudAction = acaoCrud => ({
  type: ON_ACAO_CRUD_TITULO_COMPROMISSO,
  acaoCrud,
});

export const onIdTipoTituloDeCompromissoEditAction = idTipoTituloDeCompromisso => ({
  type: ON_ID_TITULO_DE_COMPROMISSO_EDIT,
  idTipoTituloDeCompromisso,
});

export const onOpenCrudDialogAction = open => ({
  type: ON_OPEN_CRUD_DIALOG_TITULO_COMPROMISSO,
  open,
});

export const onSucessCadastroAction = value => ({
  type: ON_SUCESS_CADASTRO,
  value,
});
export const onAssignPesquisaCopyAction = value => ({
  type: ON_ASSIGN_PESQUISA_COPY,
  value,
});
export const onSetActions = (loading, openSnackBar, removendo, fail) => ({
  type: ON_SET_ACTIONS_TITULO_COMPROMISSO,
  loading,
  openSnackBar,
  removendo,
  fail,
});
export const onIdTipoTituloDeCompromissoDeleteAction = idTipoTituloDeCompromissoToDelete => ({
  type: ON_ID_TIPO_TITULO_DE_COMPROMISSO_DELETE,
  idTipoTituloDeCompromissoToDelete,
});
export const onOpenDialogTituloCompromissoAction = open => ({
  type: ON_OPEN_DIALOG_TITULO_COMPROMISSO,
  open,
});
export const onOpenDeleteTituloCompromissoAction = open => ({
  type: ON_OPEN_DELETE_TITULO_COMPROMISSO,
  open,
});
export const onChangeSizeAction = size => ({
  type: ON_CHANGE_SIZE_TITULO_COMPROMISSO,
  size,
});
export const onTituloCompromissoMessageDeleteAction = message => ({
  type: ON_TITULO_COMPROMISSO_DELETE_MESSAGE,
  message,
});
export const onChangePageAction = page => ({
  type: ON_CHANGE_PAGE_TITULO_COMPROMISSO,
  page,
});
export const onChangeRowsPerPageAction = rowsPerPage => ({
  type: ON_CHANGE_ROWS_PER_PAGE_TITULO_COMPROMISSO,
  rowsPerPage,
});

export const onDigitarPesquisaAction = value => ({
  type: ON_DIGITAR_PESQUISA_TITULO_COMPROMISSO,
  value,
});
export const onTableContentAction = tableContent => ({
  type: ON_TABLE_CONTENT_TITULO_COMPROMISSO,
  tableContent,
});

export default (states = buildInitialStates(), action) => {
  switch (action.type) {
    case ON_TABLE_CONTENT_TITULO_COMPROMISSO:
      return onTableContentHandler(states, action);
    case ON_DIGITAR_PESQUISA_TITULO_COMPROMISSO:
      return onDigitarPesquisaHandler(states, action);
    case ON_SET_PAGEABLE_TITULO_COMPROMISSO:
      return onSetTablePageableHandler(states, action);
    case ON_CHANGE_SIZE_TITULO_COMPROMISSO:
      return onChangeSizeHandler(states, action);
    case ON_CHANGE_ROWS_PER_PAGE_TITULO_COMPROMISSO:
      return onChangeRowsPerPageHandler(states, action);
    case ON_CHANGE_PAGE_TITULO_COMPROMISSO:
      return onChangePageHandler(states, action);
    case ON_SET_ACTIONS_TITULO_COMPROMISSO:
      return onSetActionsHandler(states, action);
    case ON_ID_TIPO_TITULO_DE_COMPROMISSO_DELETE:
      return onIdTipoTituloDeCompromissoDeleteHandler(states, action);
    case ON_TITULO_COMPROMISSO_DELETE_MESSAGE:
      return onTituloCompromissoMessageDeleteHandler(states, action);
    case ON_OPEN_DELETE_TITULO_COMPROMISSO:
      return onOpenDeleteTituloCompromissoHandler(states, action);
    case ON_OPEN_DIALOG_TITULO_COMPROMISSO:
      return onOpenDialogTituloCompromissoHandler(states, action);
    case ON_ASSIGN_PESQUISA_COPY:
      return onAssignPesquisaCopyHandler(states, action);
    case ON_ONLY_CHANGE_ROWS_PER_PAGE_TITULO_COMPROMISSO:
      return onOnlyChangeRowsPerPageHandler(states, action);
    case ON_SUCESS_CADASTRO:
      return onSucessCadastroHandler(states, action);
    case ON_OPEN_CRUD_DIALOG_TITULO_COMPROMISSO:
      return onOpenCrudDialogHandler(states, action);
    case ON_NOME_TITULO_COMPROMISSO_EDIT:
      return onNomeTituloCompromissoEditHandler(states, action);
    case ON_ID_TITULO_DE_COMPROMISSO_EDIT:
      return onIdTipoTituloDeCompromissoEditHandler(states, action);
    case ON_ACAO_CRUD_TITULO_COMPROMISSO:
      return onAcaoCrudHandler(states, action);
    case ON_CONTENT_ERROR_TITULO_COMPROMISSO:
      return onContentErrorHandler(states, action);
    case ON_SHOULD_UPDATE_CONTENT_TITULO_COMPROMISSO:
      return shouldUpdateContentTituloCompromissoHandler(states, action);
    case SET_ONLY_LOAD_TITULO_COMPROMISSO:
      return setOnlyLoadingHandler(states, action);
    default:
      return states;
  }
};
