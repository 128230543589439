import { v1 as uuidv1 } from 'uuid';
import { getAttributeExistingObject, getExistingFieldModel } from '../utils/utils';
import FieldModel from '../../models/FieldModel';
import { REQUEST_FIELD_ERROR_MESSAGE } from '../constants/global.constants';


export function buildParametrosHonorarioMinimoPorFase(honorarioMinimoFase) {
  return {
    idFase: getExistingFieldModel(honorarioMinimoFase, 'idFase'),
    porcentagemHonorarioMinimo: getExistingFieldModel(honorarioMinimoFase, 'porcentagemHonorarioMinimo'),
  };
}
export function buildParametrosHonorarioMinimoPorFases(honorariosMinimoFase, fases = []) {
  if (fases.length) {
    return fases.map(fase => ({ idFase: new FieldModel({ name: 'idFase', value: fase.id }), porcentagemHonorarioMinimo: new FieldModel({ name: 'porcentagemHonorarioMinimo', value: 0 }) }));
  }
  return honorariosMinimoFase.map(honorario => buildParametrosHonorarioMinimoPorFase(honorario));
}

export function buildParametroHonorario(honorario, fases = []) {
  const baseModel = { required: true, errorMessage: REQUEST_FIELD_ERROR_MESSAGE };
  return {
    key: new FieldModel({ name: 'key', value: uuidv1() }),
    parametrosHonorarioMinimoPorFase: buildParametrosHonorarioMinimoPorFases(getAttributeExistingObject(honorario, 'parametrosHonorarioMinimoPorFase', []), fases),
    idParametroHonorario: getExistingFieldModel(honorario, 'idParametroHonorario'),
    valorFaixaInicial: getExistingFieldModel(honorario, 'valorFaixaInicial', 0, baseModel),
    valorFaixaFinal: getExistingFieldModel(honorario, 'valorFaixaFinal', 0, baseModel),
    porcentagemHonorario: getExistingFieldModel(honorario, 'porcentagemHonorario', 0, baseModel),
  };
}

export function buildParametroHonorarios(honorarios = []) {
  return honorarios.map(buildParametroHonorario);
}

export function buildTipoCalculoHonorario(tipoCalculoHonorario) {
  const { honorarios, type } = tipoCalculoHonorario;
  if (honorarios.length === 0) {
    const honorario = {
      valorFaixaInicial: 0,
      valorFaixaFinal: type === 'MONEY' ? 9999999999.99 : 999999,
      porcentagemHonorario: 0,
    };
    honorarios.push(honorario);
  }
  return {
    idTipoCalculoHonorario: getExistingFieldModel(tipoCalculoHonorario, 'idTipoCalculoHonorario'),
    descricaoTipoCalculoHonorario: getExistingFieldModel(tipoCalculoHonorario, 'descricaoTipoCalculoHonorario'),
    type: getExistingFieldModel(tipoCalculoHonorario, 'type'),
    honorarios: buildParametroHonorarios(getAttributeExistingObject(tipoCalculoHonorario, 'honorarios')),
  };
}

export function buildTipoCalculoHonorarios(tiposCalculoHonorario = []) {
  const tiposHonorarios = tiposCalculoHonorario.map(buildTipoCalculoHonorario);
  return tiposHonorarios.sort(
    (prev, next) => next.idTipoCalculoHonorario.value - prev.idTipoCalculoHonorario.value,
  );
}

export function buildParametroComissao(comissao) {
  const baseModel = { required: true, errorMessage: REQUEST_FIELD_ERROR_MESSAGE };
  return {
    key: new FieldModel({ name: 'key', value: uuidv1() }),
    idParametroComissao: getExistingFieldModel(comissao, 'idParametroComissao'),
    valorFaixaInicial: getExistingFieldModel(comissao, 'valorFaixaInicial', 0, baseModel),
    valorFaixaFinal: getExistingFieldModel(comissao, 'valorFaixaFinal', 0, baseModel),
    porcentagemComissao: getExistingFieldModel(comissao, 'porcentagemComissao', 0, baseModel),
    porcentagemPagtoDiretoComissao: getExistingFieldModel(comissao, 'porcentagemPagtoDiretoComissao', 0, baseModel),
    idTipoRegraComissaoComercial: getExistingFieldModel(comissao, 'idTipoRegraComissaoComercial', 0, baseModel),
  };
}

export function buildParametroComissoes(comissoes = []) {
  return comissoes.map(buildParametroComissao);
}

export function buildTipoCalculoComissao(tipoCalculoComissao) {
  const { comissoes, type } = tipoCalculoComissao;
  if (comissoes.length === 0) {
    const comissao = {
      valorFaixaInicial: 0,
      valorFaixaFinal: type === 'MONEY' ? 9999999999.99 : 999999,
      porcentagemComissao: 0,
      porcentagemPagtoDiretoComissao: 0,
      idTipoRegraComissaoComercial: 0,
    };
    comissoes.push(comissao);
  }
  return {
    idTipoCalculoComissao: getExistingFieldModel(tipoCalculoComissao, 'idTipoCalculoComissao'),
    descricaoTipoCalculoComissao: getExistingFieldModel(tipoCalculoComissao, 'descricaoTipoCalculoComissao'),
    type: getExistingFieldModel(tipoCalculoComissao, 'type'),
    priority: getExistingFieldModel(tipoCalculoComissao, 'priority'),
    comissoes: buildParametroComissoes(getAttributeExistingObject(tipoCalculoComissao, 'comissoes')),
  };
}

export function buildTipoCalculoComissoes(tiposCalculoComissao = []) {
  const tiposComissoes = tiposCalculoComissao.map(buildTipoCalculoComissao);
  return tiposComissoes.sort(
    (prev, next) => next.idTipoCalculoComissao.value - prev.idTipoCalculoComissao.value,
  );
}

export function buildRepasse(repasse) {
  const baseModel = { required: true, errorMessage: REQUEST_FIELD_ERROR_MESSAGE };
  return {
    key: new FieldModel({ name: 'key', value: uuidv1() }),
    idParametroRepasse: getExistingFieldModel(repasse, 'idParametroRepasse'),
    valorFaixaInicial: getExistingFieldModel(repasse, 'valorFaixaInicial', 0, baseModel),
    valorFaixaFinal: getExistingFieldModel(repasse, 'valorFaixaFinal', 0, baseModel),
    porcentagemJurosRepasse: getExistingFieldModel(repasse, 'porcentagemJurosRepasse', 0, baseModel),
  };
}

export function buildParametroRepasses(repasses = []) {
  return repasses.map(buildRepasse);
}
