import React from 'react';
import PrivateRoute from '../../hoc/PrivateRoute/PrivateRoute';
import PesquisaRequisicaoPage from './Pesquisa/PesquisaRequisicaoPage';

export default function RoutesRequisicao() {
  return (
    <>
      <PrivateRoute
        path="/requisicoes"
        tipoUsuario="U"
        exact
        component={PesquisaRequisicaoPage}
      />
    </>
  );
}
