import { extractInputsDatasFromForm, updateFieldsWithErrors } from '../../../helpers/utils/form.utils';
import { haveExceptionValidations, isBadRequestException } from '../../../helpers/utils/utils';
import { enviarClienteEmailProcessoService } from '../../../services/core/processos/processosService';

class AcaoEnviarConsultaClienteController {
  makeFormFields(values) {
    return {
      emailCliente: values?.emailCliente || '',
      nomePreposto: values?.nomePreposto || '',
      emailUsuario: values?.emailUsuario || '',
      texto: values?.texto || '',
    };
  }

  makeErrorFields() {
    return {
      emailCliente: {
        error: false,
        errorMessage: '',
      },
      nomePreposto: {
        error: false,
        errorMessage: '',
      },
      emailUsuario: {
        error: false,
        errorMessage: '',
      },
      texto: {
        error: false,
        errorMessage: '',
      },
    };
  }

  makePayload(idProcesso, formTarget, texto, fases) {
    const value = extractInputsDatasFromForm(formTarget);
    return {
      idProcesso,
      emailCliente: value?.emailCliente,
      emailUsuario: value?.emailUsuario,
      nomePreposto: value?.nomePreposto,
      texto,
      fases,
    };
  }

  async enviarEmailCliente(idProcesso, formTarget, errorFields, textoValue, fasesPermitidasUsuario) {
    const fases = fasesPermitidasUsuario.join(',');
    try {
      const payload = this.makePayload(idProcesso, formTarget, textoValue, fases);
      await enviarClienteEmailProcessoService(payload);

      return { success: true, errorFields };
    } catch (exception) {
      if (isBadRequestException(exception) && haveExceptionValidations(exception)) {
        const [updateErrorFields] = updateFieldsWithErrors(exception, errorFields);
        return { success: false, errorFields: updateErrorFields };
      }
      return { success: false, errorFields };
    }
  }
}

export const acaoEnviarConsultaClienteController = new AcaoEnviarConsultaClienteController();
