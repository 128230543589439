import React, { useRef, useState, useEffect } from 'react';
import { BsPaperclip } from 'react-icons/bs';
import { toast } from 'react-toastify';
import InputField from '../Field/Input';
import useStyle, { ButtonAnexar, ButtonContainer, InputFileContainer } from './InputFileIconStyles';

function InputFileIconComponent({
  fileSize = 10485760,
  fileSizeLabel = '10MB',
  name,
  labelName,
  accept = '',
  disabled = false,
  keepButtonDisabled = false,
  textExtensions = '',
  value,
  onChange,
  clearFile = false,
  variant = 'outlined',
  setFile,
  multiFile = false,
  buttonComponent = false,
  ...props
}) {
  const fileRef = useRef();
  const styles = useStyle();
  const [fileNames, setFileNames] = useState(value);

  function handleClickFileSelector() {
    fileRef.current.click();
  }

  useEffect(() => {
    if (clearFile) {
      fileRef.current.value = null;
    }
  }, [clearFile]);

  function handleChangeFile(e) {
    e.preventDefault();
    const { files } = e.target;
    const totalSize = Array.from(files).reduce((acc, file) => acc + file.size, 0);

    if (totalSize > fileSize) {
      toast.error(`O tamanho total dos arquivos excedeu ${fileSizeLabel}`, {
        style: { width: '392px' },
      });
      return;
    }

    if (setFile && multiFile) {
      setFile(files);
    } else if (setFile) {
      setFile(files[0]);
    }

    const fileNamesArray = Array.from(files).map(file => file.name);
    setFileNames(fileNamesArray.join(', '));

    if (props.onFocus) {
      props.onFocus(e);
    }

    onChange({ target: { name: labelName, value: fileNamesArray.join(', ') } });
  }

  useEffect(() => {
    setFileNames(value);
  }, [value]);

  return (
    <InputFileContainer>
      <div className={styles.div_container}>
        <InputField
          {...props}
          name={labelName}
          fullWidth
          disabled
          value={fileNames}
        />
        <div>
          {(!disabled || keepButtonDisabled) && !buttonComponent ? (
            <ButtonContainer hasTextExtensions={!!textExtensions}>
              <ButtonAnexar
                disabled={disabled}
                variant={variant}
                type="button"
                onClick={handleClickFileSelector}
              >
                <div className={styles.div_svg}>
                  <BsPaperclip />
                </div>
                <div className={styles.div_label}>
                  Anexar
                </div>
              </ButtonAnexar>
              <span>
                {textExtensions}
              </span>
            </ButtonContainer>
          ) : (
            buttonComponent && React.cloneElement(buttonComponent, {
              onClick: handleClickFileSelector,
            })
          )}
        </div>
      </div>
      <input accept={accept} name={name} type="file" onChange={handleChangeFile} ref={fileRef} multiple={multiFile} />
    </InputFileContainer>
  );
}

export const InputFileIcon = InputFileIconComponent;
