import { v4 as uuid } from 'uuid';
import {
  FaMoneyCheckAlt, FaHandshake, FaClipboardCheck,
} from 'react-icons/fa';
import { AcaoAcordo } from './AcaoAcordo/AcaoAcordo';
import { AcaoPagamento } from './AcaoPagamento/AcaoPagamento';
import { AcaoQuitar } from './AcaoQuitar/AcaoQuitar';
import {
  DateUtils, toBrazilianDate,
} from '../../../../../../helpers';


export const actionProps = {
  pagamento: {
    tag: 'pagamento',
    makeActionProps: preveiwActions => ({ ...preveiwActions }),
  },
  acordo: {
    tag: 'acordo',
    makeActionProps: preveiwActions => ({ ...preveiwActions }),
  },
  quitar: {
    tag: 'quitar',
    makeActionProps: preveiwActions => ({ ...preveiwActions }),
  },
};


class PagamentosController {
  changeActionButton(actionsButtons, selectedTag) {
    return actionsButtons.map((action) => {
      if (action.tag === selectedTag) {
        action.active = !action.active;
      } else {
        action.active = false;
      }
      return action;
    });
  }

  makeActionsButtons(disabledButtons) {
    return [
      {
        key: uuid(),
        label: 'Pagamento',
        active: false,
        tag: actionProps.pagamento.tag,
        Icon: FaMoneyCheckAlt,
        Content: AcaoPagamento,
        disabled: disabledButtons,
      },
      {
        key: uuid(),
        label: 'Acordo',
        active: false,
        tag: actionProps.acordo.tag,
        Icon: FaHandshake,
        styles: { transform: 'translateX(1px)' },
        Content: AcaoAcordo,
        disabled: disabledButtons,
      },
      {
        key: uuid(),
        label: 'Quitar',
        active: false,
        tag: actionProps.quitar.tag,
        Icon: FaClipboardCheck,
        Content: AcaoQuitar,
        disabled: disabledButtons,
      },
    ];
  }
}


export const PagamentosFactory = {

  makeFormFields(values) {
    return {
      bloqueado: values?.bloqueado || false,
      data: toBrazilianDate(values?.data) || toBrazilianDate(DateUtils.makeDate(0)),
      historico: values?.historico || '',
      idCliente: values?.idCliente || '',
      idContaCorrenteCliente: values?.idContaCorrenteCliente || '',
      idProcesso: values?.idProcesso || '',
      idTipoContaCorrenteCliente: values?.idTipoContaCorrenteCliente || '',
      valor: values?.valor || '',
    };
  },
};

export const pagamentosController = new PagamentosController();
