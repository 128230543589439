import React from 'react';
import {
  InputLabel, Typography, Grid,
} from '@material-ui/core';
import useStyles from './PeriodField.styles';
import DateField from '../Date/DateField';

function PeriodField({
  label,
  firstField,
  lastField,
  onChange,
  onFocus,
  className = '',
}) {
  const styles = useStyles();

  return (
    <div className={`${styles.root} ${className}`}>
      <InputLabel>{label}</InputLabel>
      <Grid container>
        <Grid item xs={12} sm={5}>
          <DateField
            resultWithMask
            disabled={firstField.disabled}
            name={firstField.name}
            value={firstField.value}
            error={firstField.error}
            errorMessage={firstField.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid>
        <Grid item xs={12} sm={2} className={styles.centralizeText}>
          <Typography component="p">até</Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <DateField
            resultWithMask
            disabled={lastField.disabled}
            name={lastField.name}
            value={lastField.value}
            error={lastField.error}
            errorMessage={lastField.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default PeriodField;
