import React, { useCallback } from 'react';


import SimpleEmails from '../../../UI/SimpleEmails/SimpleEmails';

import {
  setObjectFocusAction, setObjectAttributeAction, setObjectsAction,
} from '../../../../store/Borderos/Crud/CrudBordero.store';
import { useCrudBoderoDispatch, useCrudBorderoSelector } from '../../../../store/Borderos/Crud/CrudBorderoProvider';

import useUtilStyles from '../../../../helpers/styles/utils.styles';

const EMAILS = 'emails';

export default function EmailsCrudBordero({ isInspectMode, disabled }) {
  const utilStyles = useUtilStyles();

  const dispatch = useCrudBoderoDispatch();
  const emails = useCrudBorderoSelector(states => states.requestFields.emails);

  const setAttributeHandler = useCallback((name, value, index) => {
    dispatch(setObjectAttributeAction(EMAILS, name, value, index));
  }, [dispatch]);

  const setFocusHandler = useCallback((name, index) => {
    dispatch(setObjectFocusAction(EMAILS, name, index));
  }, [dispatch]);

  const setObjectsHandler = useCallback((emailsParam) => {
    dispatch(setObjectsAction(EMAILS, emailsParam));
  }, [dispatch]);

  return (
    <SimpleEmails
      className={utilStyles.mt16}
      emails={emails}
      disabled={isInspectMode || disabled}
      readMode={isInspectMode}
      setFocus={setFocusHandler}
      setAttribute={setAttributeHandler}
      setEmails={setObjectsHandler}
      requiredFields={{
        email: !isInspectMode,
      }}
    />
  );
}
