import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import ButtonUI from '../../../UI/Button/ButtonUI';

export const ButtonNewPasta = styled(ButtonUI)`
  width: 152px;
  margin-right: 0;
  align-self: flex-end;
  margin-bottom: 16px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentDialog = styled(Typography)`
  text-align: center;
`;
