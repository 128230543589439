import { getValudWithPositiveInteger } from '../../models/FieldModel';

export const KEY_NUMBER = 1;
export const KEY_TEXT = 2;

const valueTypes = {
  [KEY_NUMBER]: getValudWithPositiveInteger,
  [KEY_TEXT]: value => value,
};

export function changeKeyHandler(e, valueType = KEY_TEXT) {
  e.target.value = valueTypes[valueType](e.target.value);
}
