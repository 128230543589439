import { getUriParams } from '../../../helpers';
import {
  gateway, CORE,
} from '../../api';

export function salvartTituloCompromisso(nomeTituloCompromisso) {
  return gateway.post(`${CORE}/tipo-titulo-agendamento/${nomeTituloCompromisso}/salvar`);
}

export function excluirTituloCompromisso(idTipoTituloDeCompromisso) {
  return gateway.get(`${CORE}/tipo-titulo-agendamento/${idTipoTituloDeCompromisso}/excluir`);
}

export function buscarTituloCompromisso(nomeTituloCompromisso, page, size) {
  const uri = getUriParams({ page, size });
  return gateway.get(`${CORE}/tipo-titulo-agendamento/${nomeTituloCompromisso}/buscar?${uri}`);
}

export function atualizarTituloCompromisso(idTipoTituloDeCompromisso, nomeTituloCompromisso) {
  return gateway.get(`${CORE}/tipo-titulo-agendamento/${idTipoTituloDeCompromisso}/${nomeTituloCompromisso}/atualizar`);
}

export function buscarTodosTituloCompromisso(page, size) {
  const uri = getUriParams({ page, size });
  return gateway.get(`${CORE}/tipo-titulo-agendamento/buscar-todos?${uri}`);
}
