import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentUI from '../SnackbarContent/SnackbarContentUI';

function SnackbarUI({
  open = false, onClose, message = '', variant, autoHideDuration = 6000, ...props
}) {
  const buldSnackbar = () => {
    if (!variant) {
      return null;
    }
    return (
      <Snackbar
        autoHideDuration={autoHideDuration}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        key="top,right"
        open={open}
        onClose={onClose}
        {...props}
      >
        <SnackbarContentUI message={message} onClose={onClose} variant={variant} />
      </Snackbar>
    );
  };

  return buldSnackbar();
}

export default SnackbarUI;
