import React from 'react';
import { useDispatch } from 'react-redux';
import { MdCheck as MdCheckIcon, MdPrint as MdPrintIcon } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { TableHeaderCaixasFiliais } from '../../components/TabelaHeader/TableHeaderCaixasFiliais';
import {
  DateUtils,
  getFieldName,
  getMoneyMask,
  getNewPage,
  hasUserPermissions,
  openPageFile,
  TableUtils,
  toastUnmappedException,
} from '../../helpers';
import { Loading, TableDefault } from '../../components';
import {
  ApprovedButton,
  ButtonImprimir,
  ButtonNovo,
  Card,
  Container,
  InfoContainer,
  PeriodSelector,
  SaldoAtualContainer,
  SelectFilial,
  TableActionHeader,
  TableContainer,
} from './CaixaDeFiliaisStyles';
import { TableBodyCaixasFiliais } from '../../components/TabelaBody/TableBodyCaixasFiliais';
import CaixaFiliaisService from '../../services/core/caixafiliais/CaixaFiliaisService';
import { initializePageAction } from '../../store/theme.actions';
import { setValusCaixaFiliaisPageAction } from '../../store/MenuAccessPage/menuAccessPageActions';

import { CaixaDeFiliaisPageController, CaixaDeFiliaisPageFactory, ID_LANCAMENTO_NAME } from '../../controller';

import store from '../../store/createStore';
import OptionDialogNew from '../../components/UI/Dialogs/OptionDialog/OptionDialogNew';

function makeLoading() {
  return {
    filiais: false,
    saldos: false,
    lancamentos: false,
    idsLancamentos: false,
    delete: false,
    download: false,
  };
}


function makeSaldos(httpResponse) {
  return {
    saldoBancario: getMoneyMask(httpResponse?.saldoBancario) || 'R$ 0,00',
    saldoCaixinha: getMoneyMask(httpResponse?.saldoCaixinha) || 'R$ 0,00',
    saldoTotal: getMoneyMask(httpResponse?.saldoTotal) || 'R$ 0,00',
  };
}


function makeColumnOrder(columnName, columnOrder) {
  return TableUtils.makeColumnOrder({
    element: columnName || 'dataLancamento',
    order: columnOrder?.getNextOrder() || 'DESC',
    arrow: columnOrder ? columnOrder?.getNextArrow() : true,
  });
}

function makeFields(fields, selectedIdFilial) {
  return {
    idFilial: fields ? fields.idFilial : selectedIdFilial || '',
    period: {
      interval: fields ? fields?.period?.interval : 30,
      startDate: fields ? fields?.period?.startDate : DateUtils.makeDate(30),
      endDate: fields ? fields?.period?.endDate : DateUtils.makeDate(0),
    },
  };
}

function getColumnOrder() {
  const { columnOrder } = store.getState().previousPageStates.caixaFiliais;

  return {
    ...makeColumnOrder(),
    ...columnOrder,
  };
}

function getFields() {
  return store.getState().previousPageStates.caixaFiliais.filters;
}


function CaixaDeFiliaisPageComponent() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = React.useState(makeLoading());
  const [filiais, setFiliais] = React.useState([]);
  const [saldos, setSaldos] = React.useState(makeSaldos());
  const [idsLancamentos, setIdsLancamentos] = React.useState(TableUtils.makeAllIdsSelections());
  const [isSelectAll, setIsSelectAll] = React.useState(false);
  const [lancamentos, setLancamentos] = React.useState(CaixaDeFiliaisPageFactory.defaultMakeLancamentos());
  const [fields, setFields] = React.useState(makeFields(getFields()));

  const [columnOrder, setColumnOrder] = React.useState(getColumnOrder());
  const [openDeleteLancamento, setOpenDeleteLancamento] = React.useState(false);
  const [openAprovarLancamentos, setOpenAprovarLancamentos] = React.useState(false);
  const [selectedIdCaixaFilial, setSelectedIdCaixaFilial] = React.useState(null);

  const permission = React.useMemo(() => {
    const isRead = hasUserPermissions(1501);
    const isUpdated = hasUserPermissions(1502);
    const isManager = hasUserPermissions(1503);
    const hasAnyPermission = isRead || isUpdated || isManager;
    return {
      isRead, isUpdated, isManager, hasAnyPermission,
    };
  }, []);

  const screenInfo = React.useMemo(() => ({
    isSelectAll: TableUtils.getIsSelectAll(idsLancamentos),
    totalSelectRows: TableUtils.getIdsSelected(idsLancamentos).length,
    totalLancamentos: lancamentos?.content?.length || 0,
  }), [idsLancamentos, lancamentos?.content?.length]);


  function savePreviousPageStates() {
    const pageable = TableUtils.makePageableFromTableItem(lancamentos);
    dispatch(setValusCaixaFiliaisPageAction(pageable, fields, columnOrder));
  }

  function goToCadastrar() {
    savePreviousPageStates();
    history.push({
      pathname: '/financeiro-lancamentos/cadastrar',
      state: { idFilial: fields.idFilial },
    });
  }

  function goToEditar(idCaixaFilial) {
    savePreviousPageStates();
    history.push({
      pathname: '/financeiro-lancamentos/editar',
      state: {
        idFilial: fields.idFilial,
        idCaixaFilial,
      },
    });
  }

  function handleSelectAll(e) {
    const { checked } = e.target;
    const newLancamentos = TableUtils.updateAllItensSelectionByNewSelectionValue({
      newSelectionValue: checked,
      items: lancamentos.content,
      ids: idsLancamentos,
      itemIdFieldName: ID_LANCAMENTO_NAME,
      notAddContentIds: true,
    });
    setLancamentos({ ...lancamentos, content: newLancamentos.items });
    setIdsLancamentos(newLancamentos.ids);
    setIsSelectAll(checked);
  }

  function handleSelectRow(e) {
    const newLancamentos = TableUtils.updateUniqueItemSelectionByCurrentId({
      currentId: Number(e.target.name),
      items: lancamentos.content,
      ids: idsLancamentos,
      itemIdFieldName: ID_LANCAMENTO_NAME,
    });
    setIdsLancamentos(newLancamentos.ids);
    setLancamentos({ ...lancamentos, content: newLancamentos.items });
  }

  function handleCloseDeleteDialogLancamento() {
    setSelectedIdCaixaFilial(null);
    setOpenDeleteLancamento(false);
  }

  function handleOpenDeleteDialogLancamento(idCaixaFilial) {
    setSelectedIdCaixaFilial(idCaixaFilial);
    setOpenDeleteLancamento(true);
  }

  async function handleShowComprovantes(idCaixaFilial) {
    try {
      setLoading(oldLoading => ({ ...oldLoading, download: true }));
      const httpResponse = await CaixaFiliaisService.downloadComprovante(idCaixaFilial);
      if (httpResponse.data.size > 0) {
        openPageFile(httpResponse.data, httpResponse.data.type);
      }
    } catch (err) {
      toastUnmappedException(err, 'Ocorreu um problema ao tentar abrir o comprovante');
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, download: false }));
    }
  }

  async function handlePrintLancamentos() {
    try {
      setLoading(oldLoading => ({ ...oldLoading, download: true }));
      const httpResponse = await CaixaFiliaisService.downloadExtratoLancamento(fields.idFilial, fields.period.startDate, columnOrder.get());
      if (httpResponse.data.size > 0) {
        openPageFile(httpResponse.data, httpResponse.data.type);
      }
    } catch (err) {
      toastUnmappedException(err, 'Ocorreu um problema ao tentar abrir o comprovante');
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, download: false }));
    }
  }

  const currentState = React.useMemo(() => ({
    formFields: fields,
    order: columnOrder,
    ids: idsLancamentos,
    page: lancamentos.page,
    size: lancamentos.size,
    selectAll: isSelectAll,
  }), [fields, columnOrder, idsLancamentos, lancamentos.page, lancamentos.size, isSelectAll]);

  const findAllLancamentos = React.useCallback(async ({
    formFields, order, ids, page, size, isCheckedAllSelection,
  }) => {
    setLoading(oldLoading => ({ ...oldLoading, lancamentos: true }));

    try {
      const pageable = { page, size };
      const newLancamentos = await CaixaDeFiliaisPageController.findAllLancamentos(
        formFields, order, pageable, isCheckedAllSelection, ids,
      );

      setIdsLancamentos(newLancamentos.idsLancamentos);
      setLancamentos(newLancamentos.lancamentos);
    } catch (err) {
      toastUnmappedException(err, 'Ocorreu um problema ao tentar consultas os lançamentos');
      setLancamentos(CaixaDeFiliaisPageFactory.makeLancamentos({ size: 10 }));
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, lancamentos: false }));
    }
  }, []);

  const handleChangeFilial = React.useCallback(async (e) => {
    const name = getFieldName(e.target);
    const { value } = e.target;
    const newFields = makeFields({
      [name]: value,
      period: {
        interval: 30,
        startDate: DateUtils.makeDate(30),
        endDate: DateUtils.makeDate(0),
      },
    });

    setFields(newFields);
    const newIdsLancamentos = await CaixaDeFiliaisPageController.findAllIdsLancamentos(newFields);
    await findAllLancamentos({
      ...currentState,
      formFields: newFields,
      ids: newIdsLancamentos,
      page: 0,
      size: lancamentos.size,
      order: makeColumnOrder(),
    });
  }, [currentState, lancamentos.size, findAllLancamentos]);

  async function handleChangePeriod(interval, startDate, endDate) {
    const newFields = {
      ...fields,
      period: {
        ...fields.period, startDate, endDate, interval,
      },
    };
    setFields(newFields);
    setColumnOrder(makeColumnOrder());
    const newIdsLancamentos = await CaixaDeFiliaisPageController.findAllIdsLancamentos(newFields);
    await findAllLancamentos({
      ...currentState,
      formFields: newFields,
      ids: newIdsLancamentos,
      page: 0,
      size: lancamentos.size,
      order: makeColumnOrder(),
    });
  }

  async function handleApplyOrder(columnName) {
    const newColumnOrder = makeColumnOrder(columnName, columnOrder);
    setColumnOrder(newColumnOrder);
    await findAllLancamentos({ ...currentState, order: newColumnOrder });
  }

  async function handlePageChange(e, newPage) {
    const pageable = { size: lancamentos.size, page: Number(newPage) };
    await findAllLancamentos({ ...currentState, ...pageable });
  }

  async function handleChangeRowsPerPage(e) {
    const size = e.target.value;
    const newPage = getNewPage(lancamentos.size, lancamentos.page, size);
    const pageable = { size, page: Number(newPage) };
    await findAllLancamentos({ ...currentState, ...pageable });
  }

  async function handleAprovarLancamentos() {
    const idsSelecionados = TableUtils.getIdsSelected(idsLancamentos);
    try {
      await CaixaFiliaisService.approveLancamento(idsSelecionados);
      const newIdsLancamentos = await CaixaDeFiliaisPageController.findAllIdsLancamentos(fields);
      await findAllLancamentos({ ...currentState, ids: newIdsLancamentos });
    } catch (err) {
      toastUnmappedException(err, 'Ocorreu um problema ao aprovar os lançamentos');
    } finally {
      setOpenAprovarLancamentos(false);
    }
  }

  const findSaldosLancamentos = React.useCallback(() => {
    if (!fields.idFilial || !permission.hasAnyPermission) {
      return;
    }

    setLoading(oldLoading => ({ ...oldLoading, saldos: true }));
    CaixaFiliaisService.findSaldos(fields.idFilial).then((httpResponse) => {
      setSaldos(makeSaldos(httpResponse.data));
    }).catch((err) => {
      toastUnmappedException(err, 'Ocorreu um problema ao tentar consultar os saldos');
      setSaldos(makeSaldos());
    }).finally(() => {
      setLoading(oldLoading => ({ ...oldLoading, saldos: false }));
    });
  }, [fields.idFilial, permission.hasAnyPermission]);

  async function handleDeleteLancamento() {
    setLoading(oldLoading => ({ ...oldLoading, delete: true }));
    try {
      await CaixaFiliaisService.deleteLancamento(selectedIdCaixaFilial);
      findSaldosLancamentos();
      const newIdsLancamentos = await CaixaDeFiliaisPageController.findAllIdsLancamentos(fields);
      await findAllLancamentos({ ...currentState, ids: newIdsLancamentos });
    } catch (err) {
      toastUnmappedException(err, `Ocorreu um problema ao tentar excluir lançamento ${selectedIdCaixaFilial}`);
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, delete: false }));
      setSelectedIdCaixaFilial(null);
      setOpenDeleteLancamento(false);
    }
  }

  const loadInitialStatesFromLancamento = React.useCallback(async () => {
    setLoading(oldLoading => ({ ...oldLoading, filiais: true }));
    try {
      const newFiliais = await CaixaDeFiliaisPageController.findAllFiliais();
      const newFields = makeFields(getFields(), newFiliais.selectedIdFilial);
      const newIdsLancamentos = await CaixaDeFiliaisPageController.findAllIdsLancamentos(newFields);
      const newLancamentos = CaixaDeFiliaisPageFactory.defaultMakeLancamentos();
      await findAllLancamentos({
        formFields: newFields,
        order: getColumnOrder(),
        ids: newIdsLancamentos,
        page: newLancamentos.page,
        size: newLancamentos.size,
        selectAll: false,
      });

      setFields(newFields);
      setFiliais(newFiliais.filiais);
    } catch (err) {
      toastUnmappedException(err, 'Ocorreu um problema ao tentar consultar as filiais');
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, filiais: false }));
    }
  }, [findAllLancamentos]);

  /** Validar se o usuário tem permissão para acessar esta tela e adicionar título na tela */
  React.useLayoutEffect(() => {
    dispatch(initializePageAction('Caixa de Filiais'));
    if (!permission.hasAnyPermission) {
      window.location.replace('/');
    }
  }, [permission, dispatch]);

  /** Atualizar selecionar todos */
  React.useLayoutEffect(() => {
    setIsSelectAll(screenInfo.isSelectAll);
  }, [screenInfo]);

  /** Preencher saldos dos lançamentos */
  React.useEffect(findSaldosLancamentos, [findSaldosLancamentos]);

  /** Preencher filiais e lançamentos */
  React.useEffect(() => {
    if (!permission.hasAnyPermission) {
      return;
    }

    loadInitialStatesFromLancamento().then();
  }, [loadInitialStatesFromLancamento, permission.hasAnyPermission]);

  return (
    <>
      <OptionDialogNew
        open={openDeleteLancamento}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickConfirm={handleDeleteLancamento}
        onClickCancel={handleCloseDeleteDialogLancamento}
        onClose={handleCloseDeleteDialogLancamento}
        text="Deseja excluir o lançamento selecionado? "
      />
      <OptionDialogNew
        open={openAprovarLancamentos}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickConfirm={handleAprovarLancamentos}
        onClickCancel={() => setOpenAprovarLancamentos(false)}
        onClose={() => setOpenAprovarLancamentos(false)}
        text="Deseja aprovar todos os lançamentos selecionados?"
      />
      <Loading
        show={loading.filiais || loading.saldos || loading.lancamentos || loading.idsLancamentos || loading.download}
      />
      <Container>
        <InfoContainer>
          <div className="filters">
            <SelectFilial
              disabled={!permission.isManager}
              label="Filial Selecionada"
              name="idFilial"
              items={filiais}
              value={fields.idFilial}
              onChange={handleChangeFilial}
            />
            <PeriodSelector
              value={fields.period}
              onChange={handleChangePeriod}
            />
            <ButtonImprimir
              disabled={screenInfo.totalLancamentos === 0}
              onClick={handlePrintLancamentos}
            >
              <MdPrintIcon size={16} />
              <span>Imprimir</span>
            </ButtonImprimir>
          </div>
          <div className="additional-info">
            <SaldoAtualContainer>
              <p className="title">Saldo Atual</p>
              <div>
                <Card color="#FF8AC1">
                  <p>Bancário</p>
                  <p>{saldos.saldoBancario}</p>
                </Card>
                <Card color="#75C3C8">
                  <p>Caixinha</p>
                  <p>{saldos.saldoCaixinha}</p>
                </Card>
                <Card color="#79DF49">
                  <p>Saldo Total</p>
                  <p>{saldos.saldoTotal}</p>
                </Card>
              </div>
            </SaldoAtualContainer>

            <ButtonNovo
              onClick={goToCadastrar}
              disabled={!(permission.isUpdated || permission.isManager)}
            >
              Novo
            </ButtonNovo>
          </div>
        </InfoContainer>
        <TableContainer>
          {screenInfo.totalSelectRows > 0 ? (
            <TableActionHeader>
              <p>
                {screenInfo.totalSelectRows}
                <span>{screenInfo.totalSelectRows === 1 ? 'Selecionado' : 'Selecionados'}</span>
              </p>
              <ApprovedButton onClick={() => setOpenAprovarLancamentos(true)}>
                <MdCheckIcon size={14} />
                Aprovar
              </ApprovedButton>
            </TableActionHeader>
          ) : (
            <p>Lançamentos</p>
          )}
          <TableDefault
            columnsHead={(
              <TableHeaderCaixasFiliais
                columnOrder={columnOrder}
                isSelectAll={isSelectAll}
                onChecked={handleSelectAll}
                onChangeOrder={columnName => handleApplyOrder(columnName)}
                isManager={permission.isManager}
              />
            )}
            page={lancamentos.page}
            rowsPerPage={lancamentos.size}
            totalElements={lancamentos.totalElements}
            totalElementsOnPage={lancamentos.content?.length}
            totalColumns={8}
            emptyRowsHeight={37}
            rowsPerPageOptions={[10, 15, 20]}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          >
            {lancamentos.content?.map(lancamento => (
              <TableBodyCaixasFiliais
                key={lancamento.idCaixaFilial}
                lancamento={lancamento}
                isManager={permission.isManager}
                isUpdated={permission.isUpdated}
                onSelectRow={handleSelectRow}
                onOpenDelete={handleOpenDeleteDialogLancamento}
                onShowComprovante={handleShowComprovantes}
                onGoToEditar={goToEditar}
              />
            ))}
          </TableDefault>
        </TableContainer>
      </Container>
    </>
  );
}

export const CaixaDeFiliaisPage = CaixaDeFiliaisPageComponent;
