import React, { createContext } from 'react';
import { createDispatchHook, createSelectorHook, Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, createStore } from 'redux';
import reducer from './requisicao.store';
import watchRequisicoes from './requisicao.saga';


export const requisicaoContext = createContext();
export const useRequisicaoSelector = createSelectorHook(requisicaoContext);
export const useRequisicaoDispatch = createDispatchHook(requisicaoContext);

export default function RequisicaoProvider({ children }) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(watchRequisicoes);

  return (
    <Provider context={requisicaoContext} store={store}>
      {children}
    </Provider>
  );
}
