import React, { createContext } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider, createSelectorHook, createDispatchHook } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import reducer from './crudUsuario.store';
import watchCrudUsuario from './crudUsuario.saga';

export const context = createContext();
export const useCrudUsuarioSelector2 = createSelectorHook(context);
export const useCrudUsuarioDispatch2 = createDispatchHook(context);

export default function CrudUsuarioProvider({ children }) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(watchCrudUsuario);

  return (
    <Provider context={context} store={store}>
      {children}
    </Provider>
  );
}
