import React from 'react';
import moment from 'moment';
import useStyle from './acompanhamentoItem.styles';
import AcompanhamentoSubItem from '../SubItem/AcompanhamentoSubItem';
import { DATE_FORMAT_PT_BAR } from '../../../../../helpers/constants/global.constants';
import { getDayOfWeek } from '../../../../../helpers/utils/utils';

function isLastContent(subItens, index) {
  return index !== subItens[1].length - 1;
}

function AcompanhamentoItem({ acompanhamentos }) {
  const styles = useStyle();

  function buildAcompanhamentoItem() {
    const data = moment(acompanhamentos[0]);
    const formatedDate = data.format(DATE_FORMAT_PT_BAR);
    const diaSemana = getDayOfWeek(data.weekday());
    return (
      <>
        <div className={styles.boxTitle}>
          <div className={styles.textTitle}>
            {`${formatedDate} - ${diaSemana}`}
          </div>
        </div>
        {acompanhamentos[1] && acompanhamentos[1].map((value, idx) => (
          <div key={idx}>
            <AcompanhamentoSubItem anotacoes={value} />
            {isLastContent(acompanhamentos, idx) && <hr className={styles.lineConfiguration} />}
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      {buildAcompanhamentoItem()}
    </>
  );
}

export default React.memo(AcompanhamentoItem);
