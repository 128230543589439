import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { CssBaseline, Typography } from '@material-ui/core';
import styleSweetAlert from './sweetAlert.styles';
import CloseIcon from '../../../assets/Icons/CloseIcon';

export default function SweetAlertGeneric({
  open, sucess, title = '', closeDialog, message = '',
}) {
  const styleAlert = styleSweetAlert();

  return (
    <div>
      <CssBaseline />
      {open && sucess && (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title={title}
          onConfirm={closeDialog}
          onCancel={closeDialog}
          confirmBtnCssClass={`${styleAlert.button}  ${styleAlert.success}`}
        >
          <Typography component="p" style={{ color: '#333333', fontSize: '16px' }}>
            {message}
          </Typography>
        </SweetAlert>
      )}
      {open && !sucess && (
        <SweetAlert
          style={{ display: 'block', marginTop: '-100px', maxHeight: '450px' }}
          title={title}
          onConfirm={closeDialog}
          onCancel={closeDialog}
          confirmBtnCssClass={`${styleAlert.confirmButton}`}
        >
          <CloseIcon />
          <Typography component="p" style={{ color: '#333333', fontSize: '16px' }}>
            {message}
          </Typography>
        </SweetAlert>
      )}
    </div>
  );
}
