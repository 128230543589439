import React from 'react';
import Grid24 from '../../../../components/UI/Grid24';
import InputField from '../../../../components/UI/Field/Input';
import CheckboxField from '../../../../components/UI/Field/Checkbox/CheckboxField';
import useStyles from './telefoneDadosCliente.styles';
import useUtilStyles from '../../../../helpers/styles/utils.styles';
import { tipoTelefonesTypes } from '../../../../types/tipoTelefonesTypes';

function TelefoneDadosCliente({ telefones }) {
  const styles = useStyles();
  const utilStyles = useUtilStyles();

  if (telefones) {
    return telefones.map((telefone, idx) => (
      <div key={telefone.idTelefone}>
        <Grid24 container spacing={1}>
          <Grid24 xs={24} sm={3}>
            <InputField
              isForm
              fullWidth
              disabled
              label="Tipo Telefone"
              name="tipoTelefone"
              value={tipoTelefonesTypes[telefone.tipoTelefone]}
            />
          </Grid24>
          <Grid24 xs={24} sm={1}>
            <InputField
              isForm
              fullWidth
              disabled
              label="DDD"
              name="ddd"
              value={telefone.ddd}
            />
          </Grid24>
          <Grid24 xs={24} sm={3}>
            <InputField
              isForm
              fullWidth
              disabled
              label="Telefone"
              name="telefone"
              value={telefone.numero}
            />
          </Grid24>
          <Grid24 xs={24} sm={6}>
            <InputField
              isForm
              fullWidth
              disabled
              label="Observação"
              name="observacao"
              value={telefone.observacao}
            />
          </Grid24>
          <Grid24
            xs={24}
            sm={11}
            container
            className={styles.actionWithRemoveButton}
          >
            <Grid24 xs={8} sm={6} spacing={1}>
              <CheckboxField
                label="Principal"
                disabled
                name="principal"
                checked={telefone.principal}
              />
            </Grid24>
            <Grid24 xs={8} sm={6} spacing={1}>
              <CheckboxField
                label="WhatsApp"
                disabled
                name="whatsapp"
                checked={telefone.permitirWhatsApp}
              />
            </Grid24>
          </Grid24>
        </Grid24>
        {idx !== telefones.length - 1 ? (
          <hr className={utilStyles.divider} />
        ) : null}
      </div>
    ));
  }
  return null;
}

export default TelefoneDadosCliente;
