import React, { useCallback, useEffect, useState } from 'react';
import { FaGlobe } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Grid24 from '../../../components/UI/Grid24';
import DadosAdvogado from './DadosAdvogado/DadosAdvogado';
import EmailDevedor from './Email Devedor/EmailDevedor';
import EnderecoDevedor from './Endereco Devedor/EnderecoDevedor';
import IdentificacaoDevedor from './Identificacao Devedor/IdentificacaoDevedor';
import TelefoneDevedor from './Telefone Devedor/TelefoneDevedor';
import useStyles, { BotaoWebService } from './DadosDevedor.styles';
import { dadosDevedorController as controller } from './dadosDevedorController';
import { getFieldName, isValidationException } from '../../../helpers/utils/form.utils';
import ButtonUI from '../../../components/UI/Button/ButtonUI';
import { loadingInspecaoProcessoController as loadingController } from '../loadingInspecaoProcessoController';
import { getExceptionHandler } from '../../../helpers/utils/exception.util';
import { getRequiredLabel, openPagePDF } from '../../../helpers/utils/utils';
import { hasUserPermissions } from '../../../helpers';
import { atualizarDadosDevedor, constultarDadosComplementaresDevedorWebserviceExterno } from '../../../services/core/processos/processosDevedorService';
import OptionDialogNew from '../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';
import CustomInputField from '../../../components/UI/Field/Custom/CustomInputField';
import TableDevedorGrupo from './Table Devedor Grupo/TableDevedorGrupo';
import FormWrapperWithTitle from '../../../components/UI/Wrapper/Form/FormWrapperWithTitle';
import { buscarDevedorGrupo } from '../../../services/core/processos/processosService';

function DadosDevedorProcessoComponent({
  immutableSelectors,
  devedor,
  podeModificarFase,
  idProcesso,
  setLoadings,
  updatePage,
}) {
  const styles = useStyles();

  const CONSTANTS = {
    COD_DEVEDOR_PRINCIPAL: 'Cód. Devedor Principal',
  };

  const [isEditMode, setIsEditMode] = useState(false);
  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const [formFields, setFormFields] = useState(controller.makeFormFields(devedor, idProcesso));

  const [devedoresDoGrupo, setDevedoresDoGrupo] = useState([]);
  const [isPrimeiraExecucao, setIsPrimeiraExecucao] = useState(true);

  const permissoesDevedor = React.useMemo(() => {
    const gerenciarDevedor = hasUserPermissions(702);
    const pesquisarWebService = hasUserPermissions(703);

    return {
      gerenciarDevedor,
      pesquisarWebService,
    };
  }, []);

  const onChangeHandler = useCallback((e) => {
    const { value } = e.target;
    const name = getFieldName(e.target);
    setFormFields(oldValue => ({ ...oldValue, [name]: oldValue[name].getNewModel(value) }));
  }, []);

  const onFocusHandler = useCallback((e) => {
    const name = getFieldName(e.target);
    setFormFields(oldValue => ({ ...oldValue, [name]: oldValue[name].onFocus() }));
  }, []);

  const cancelHandler = useCallback(() => {
    setIsEditMode(false);
    setFormFields(controller.makeFormFields(devedor, idProcesso));
    setIsPrimeiraExecucao(true);
  }, [devedor, idProcesso, setIsEditMode]);

  const editHandler = () => {
    setIsEditMode(true);
  };

  const salveHandler = () => {
    setShowSaveAlert(true);
    setIsPrimeiraExecucao(true);
  };

  const closeAlert = useCallback(() => {
    setShowSaveAlert(false);
    setIsEditMode(false);
    setFormFields(controller.makeFormFields(devedor, idProcesso));
  }, [setShowSaveAlert, setIsEditMode, setFormFields, devedor, idProcesso]);

  const onChangeTelefoneHandler = useCallback((name, value, index) => {
    setFormFields((oldValue) => {
      const telefones = [...oldValue.telefones];
      telefones[index][name].onChange(value);
      return { ...oldValue, telefones };
    });
  }, []);

  const onFocusTelefoneHandler = useCallback((name, index) => {
    setFormFields((oldValue) => {
      const telefones = [...oldValue.telefones];
      telefones[index][name].onFocus();
      return { ...oldValue, telefones };
    });
  }, []);

  const setTelefonesHandler = useCallback((telefones) => {
    setFormFields(oldValue => ({ ...oldValue, telefones }));
  }, []);

  const onChangeEnderecoHandler = useCallback((name, value, index) => {
    setFormFields((oldValue) => {
      const enderecos = [...oldValue.enderecos];
      enderecos[index][name].onChange(value);
      return { ...oldValue, enderecos };
    });
  }, []);

  const onFocusEnderecoHandler = useCallback((name, index) => {
    setFormFields((oldValue) => {
      const enderecos = [...oldValue.enderecos];
      enderecos[index][name].onFocus();
      return { ...oldValue, enderecos };
    });
  }, []);

  const setEnderecosHandler = useCallback((enderecos) => {
    setFormFields(oldValue => ({ ...oldValue, enderecos }));
  }, []);

  const setEnderecoHandler = useCallback((enderecos, index) => {
    setFormFields((oldValue) => {
      const newEnderecos = [...oldValue.enderecos];
      newEnderecos[index] = enderecos;
      return { ...oldValue, enderecos: newEnderecos };
    });
  }, []);

  const onChangeEmailHandler = useCallback((name, value, index) => {
    setFormFields((oldValue) => {
      const emails = [...oldValue.emails];
      emails[index][name].onChange(value);
      return { ...oldValue, emails };
    });
  }, []);

  const onFocusEmailHandler = useCallback((name, index) => {
    setFormFields((oldValue) => {
      const emails = [...oldValue.emails];
      emails[index][name].onFocus();
      return { ...oldValue, emails };
    });
  }, []);

  const setEmailHandler = useCallback((emails) => {
    setFormFields(oldValue => ({ ...oldValue, emails }));
  }, []);

  async function onSubmitHandler(event) {
    event.preventDefault();
    setLoadings(loadingController.changeLoadingDadosDevedor(true));
    const payload = controller.makePayload(formFields, immutableSelectors);
    try {
      await atualizarDadosDevedor(idProcesso, payload);
      setIsEditMode(false);
      updatePage();
      toast.success('Ação realizada com sucesso!');
    } catch (e) {
      if (isValidationException(e)) {
        const [updateErrorFields] = await getExceptionHandler(e, formFields);
        setFormFields({ ...updateErrorFields });
        if (updateErrorFields?.emails?.errorMessage) {
          toast.error(updateErrorFields?.emails?.errorMessage, { style: { width: '392px' } });
        }
        if (updateErrorFields?.telefones?.errorMessage) {
          toast.error(updateErrorFields?.telefones?.errorMessage, { style: { width: '392px' } });
        }
        if (updateErrorFields?.enderecos?.errorMessage) {
          toast.error(updateErrorFields?.enderecos?.errorMessage, { style: { width: '392px' } });
        }
      } else if (e.response.data.message) {
        toast.error(e.response.data.message, { style: { width: '392px' } });
      } else {
        toast.error(e.response.data.error, { style: { width: '392px' } });
      }
    } finally {
      setLoadings(loadingController.changeLoadingDadosDevedor(false));
      setShowSaveAlert(false);
    }
  }

  async function onClickPesquisarWebService() {
    try {
      setLoadings(loadingController.changeLoadingDadosDevedor(true));
      const response = await constultarDadosComplementaresDevedorWebserviceExterno(devedor?.cnpjCpf);
      if (response.data.size > 0) {
        openPagePDF(response.data);
      }
    } catch (e) {
      const fileReader = new FileReader();
      fileReader.addEventListener('loadend', (apiResponse) => {
        const exception = JSON.parse(apiResponse.target.result);
        toast.warning(exception.message);
      });
      fileReader.readAsText(e.response.data);
    } finally {
      setLoadings(loadingController.changeLoadingDadosDevedor(false));
    }
  }

  const getDevedorGrupoHandler = useCallback(() => {
    loadingController.changeLoadingDadosDevedor(true);

    buscarDevedorGrupo(formFields.codDevedorPrincipal.value, formFields.idDevedor.value).then(
      (response) => {
        if (response.data.length === 0) {
          formFields.statusPromisseCodDevedor.value = 'fail';
          formFields.codDevedorPrincipal.error = true;
          formFields.errorMessageCodDevedor = '';
        } else if (response.data.length > 0) {
          formFields.statusPromisseCodDevedor.value = 'sucess';
          formFields.codDevedorPrincipal.error = false;
          formFields.errorMessageCodDevedor = '';
        }
        setDevedoresDoGrupo(response.data);
      },
    ).catch((e) => {
      formFields.statusPromisseCodDevedor.value = 'fail';
      formFields.codDevedorPrincipal.error = true;
      formFields.errorMessageCodDevedor = e.response.data.message;
      setDevedoresDoGrupo([]);
    });
    loadingController.changeLoadingDadosDevedor(false);
  }, [formFields]);

  useEffect(() => {
    setFormFields(controller.makeFormFields(devedor, idProcesso));
  }, [devedor, idProcesso, setFormFields]);

  useEffect(() => {
    if (!formFields.codDevedorPrincipal.value) {
      formFields.statusPromisseCodDevedor.value = '';
      formFields.codDevedorPrincipal.error = false;
      formFields.errorMessageCodDevedor = '';
    } else if (isPrimeiraExecucao) {
      getDevedorGrupoHandler();
      setIsPrimeiraExecucao(false);
    }
  }, [formFields, getDevedorGrupoHandler, isPrimeiraExecucao]);

  return (
    <>
      <OptionDialogNew
        open={showSaveAlert}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={closeAlert}
        onClickConfirm={onSubmitHandler}
      >
        <center style={{ fontSize: '18px' }}>
          As alterações feitas no cadastro serão refletidas em
          <p>todos os Processos que fizerem referência a este</p>
          <p>Devedor. Confirma as alterações?</p>
        </center>
      </OptionDialogNew>
      <Grid24 container isFormContainer>
        <Grid24 xs={24} sm={24} className={styles.root}>
          <Grid24 xs={24} sm={5}>
            <BotaoWebService disabled={!devedor?.cnpjCpf || !permissoesDevedor.pesquisarWebService} onClick={onClickPesquisarWebService}>
              <FaGlobe />
              <span> Pesquisar WebService</span>
            </BotaoWebService>
          </Grid24>
        </Grid24>
        <Grid24 container>
          <IdentificacaoDevedor
            isEditMode={isEditMode}
            immutableSelectors={immutableSelectors}
            podeModificarFase={podeModificarFase}
            onChangeHandler={onChangeHandler}
            onFocusHandler={onFocusHandler}
            formFields={formFields}
            setFormFields={setFormFields}
          />
        </Grid24>

        <Grid24 container isFormContainer>
          <FormWrapperWithTitle title="Devedores Antecedentes">
            <Grid24 container isFormContainer spacing={1}>
              <Grid24 item xs={24} sm={4}>
                <CustomInputField
                  fullWidth
                  isForm
                  statusPromisse={formFields.statusPromisseCodDevedor.value}
                  disabled={!isEditMode}
                  label={getRequiredLabel(CONSTANTS.COD_DEVEDOR_PRINCIPAL, (isEditMode))}
                  name="codDevedorPrincipal"
                  value={formFields.codDevedorPrincipal.value}
                  error={formFields.codDevedorPrincipal.error}
                  errorMessage={formFields.codDevedorPrincipal.errorMessage}
                  maxLength={9}
                  onChange={onChangeHandler}
                  onBlur={getDevedorGrupoHandler}
                />
              </Grid24>
              <Grid24 xs={24} sm={20}>
                <div className={styles.errorMessage}>
                  {formFields.errorMessageCodDevedor}
                </div>
              </Grid24>
              <TableDevedorGrupo content={devedoresDoGrupo} />
            </Grid24>
          </FormWrapperWithTitle>
        </Grid24>

        <Grid24 container isFormContainer>
          <EnderecoDevedor
            isEditMode={isEditMode}
            immutableSelectors={immutableSelectors}
            devedor={devedor}
            podeModificarFase={podeModificarFase}
            formFields={formFields}
            setEnderecoAttributeValueHandler={onChangeEnderecoHandler}
            setEnderecoAttributeFocusHandler={onFocusEnderecoHandler}
            setEnderecosHandler={setEnderecosHandler}
            setEnderecoHandler={setEnderecoHandler}
          />
        </Grid24>
        <Grid24 container isFormContainer>
          <TelefoneDevedor
            isEditMode={isEditMode}
            immutableSelectors={immutableSelectors}
            devedor={devedor}
            podeModificarFase={podeModificarFase}
            formFields={formFields}
            setAttributeHandler={onChangeTelefoneHandler}
            setFocusHandler={onFocusTelefoneHandler}
            setTelefonesHandler={setTelefonesHandler}
          />
        </Grid24>
        <Grid24 container isFormContainer>
          <EmailDevedor
            isEditMode={isEditMode}
            devedor={devedor}
            podeModificarFase={podeModificarFase}
            formFields={formFields}
            setAttributeHandler={onChangeEmailHandler}
            setFocusHandler={onFocusEmailHandler}
            setObjectsHandler={setEmailHandler}
          />
        </Grid24>
        <Grid24 container isFormContainer>
          <DadosAdvogado
            devedor={devedor}
            isEditMode={isEditMode}
            podeModificarFase={podeModificarFase}
            onChangeHandler={onChangeHandler}
            onFocusHander={onFocusHandler}
            formFields={formFields}
          />
        </Grid24>
        <Grid24 isFormContainer className={styles.buttonFooter} justify="flex-end">
          {isEditMode ? (
            <>
              <Grid24 container className={styles.buttonCancelar}>
                <ButtonUI color="error" onClick={cancelHandler}>
                  Cancelar
                </ButtonUI>
              </Grid24>
              <Grid24 container className={styles.buttonSalvar}>
                <ButtonUI color="success" onClick={salveHandler}>
                  Salvar
                </ButtonUI>
              </Grid24>
            </>
          ) : (
            <ButtonUI
              className={styles.buttonEditar}
              disabled={!podeModificarFase || !permissoesDevedor.gerenciarDevedor}
              onClick={editHandler}
            >
              Editar
            </ButtonUI>
          )}
        </Grid24>
      </Grid24>

    </>
  );
}

export const DadosDevedorProcesso = DadosDevedorProcessoComponent;
