/** Redux * */
export const CHANGE_SEARCH_CRITERIAS = '@logProcessamento/CHANGE_SEARCH_CRITERIAS_LOG_PROCESSAMENTO';
export const CHANGE_SEARCH_CRITERIAS_VALUES = '@logProcessamento/CHANGE_SEARCH_CRITERIAS_LOG_PROCESSAMENTO_VALUES';
export const FOCUS_SEARCH_CRITERIAS = '@logProcessamento/FOCUS_SEARCH_CRITERIAS_LOG_PROCESSAMENTO';
export const ON_CHANGE_STATES_SNACKBACK = '@logProcessamento/ON_CHANGE_STATES_SNACKBACK';
export const ON_CHANGE_ACTIONS_PAGE_VIEW = '@logProcessamento/ON_CHANGE_ACTIONS_PAGE_VIEW';
export const SET_SETTINGS_TABLE = '@logProcessamento/SET_SETTINGS_TABLE_PESQUISA_CLIENTE';
export const SET_CACHE_SEARCH_CRITERIA_FIELDS = '@logProcessamento/SET_CACHE_REQUEST_FIELDS_PESQUISA_CLIENTE';
export const SET_PAGE = '@logProcessamento/SET_PAGE_PESQUISA_CLIENTE';
export const SET_ROWS_PER_PAGE = '@logProcessamento/SET_ROWS_PER_PAGE_PESQUISA_CLIENTE';
export const ON_LOAD_LOG_PROCESSAMENTO_BORDERO_DETALHES = '@pesquisarLogProcessamento/ON_LOAD_LOG_PROCESSAMENTO_BORDERO_DETALHES';

/** Saga * */
export const FIND_LOG_PROCESSAMENTO_BORDERO = '@pesquisarLogProcessamento/FIND_LOG_PROCESSAMENTO_BORDERO';
export const FIND_LOG_PROCESSAMENTO_BORDERO_DETALHES = '@pesquisarLogProcessamento/FIND_LOG_PROCESSAMENTO_BORDERO_DETALHES';
