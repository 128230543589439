import styled from 'styled-components';

export const TransparentBackground = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1200;
  background-color: rgba(0, 0, 0, 0.24);
`;
