import React, { useRef } from 'react';
import {
  BoxSinalizacao,
  ButtonDropDown,
  SinalizacaoContainer,
  DropdownContainer,
} from '../../InspecionarProcessoStyles';
import TooltipUI from '../../../../components/UI/Tooltip/Tooltip';
import CheckboxUI from '../../../../components/UI/Checkbox/CheckboxUI';
import { loadingInspecaoProcessoController } from '../../loadingInspecaoProcessoController';
import { FloatBox } from '../../../../components/FloatContainer/FloatBox';
import { alterarProtegerCodigoConsumidorDoProcessoService, findInformacoesAdicionaisInpescaoProcessoService } from '../../../../services/core/processos/processosService';

const carImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAABO0lEQVRIx72Uu0oEQRBFb6+BgQ8UDDUSwUdkIIYmIshmphsIgumCoS/YPzAy8FM01GgxFMUvMJJVA0EEPSa1K8z0TNfijJUMPVO3Tt+u6pH+K4DJOou3gGdgveraDXuOSpqWdFkHpO9iH/gGXoA1e7cDfJKOd+A0VjdkIZIuJL1J2goh3ALbkjYS+1uVtClpLoTwlHJybrsaOHG4XzHNgSe5C/Syx+XQ3QPdVNK8Fe4Ae8AX8OppvGkAFsqSTixp2dZuCLBo2uOypLuSSRlAgCbwUZD3UAZIhQviARwCY8BRRJ+9Jx1gFjgbBjBu64kCJ7npAmZigEYBq22QdsH3KUlXGUgrNWmeHrh6UiUgCqkakGu8C5A8z1+N/8YDN1b/2gswXe5XX3kM5eQPkL6TR2CkLsgusFRL8Vj8ABl88YpHVQf0AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTA5LTEzVDE4OjU3OjQ4KzAwOjAwiLsl4wAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wOS0xM1QxODo1Nzo0OCswMDowMPnmnV8AAAAASUVORK5CYII=';
const naoPermiteCobrancaPresencial = 'Não permite Cobrança Presencial';

function SinalizacaoIcones({
  idProcesso,
  sinalizacaoClienteProcessoQuery,
  podeModificarFase,
  setLoadings,
  setAdictionalInfos,
}) {
  const parentFloatBoxRef = useRef();
  const floatBoxRef = useRef();

  async function onChangeClienteComboBox(e, callback) {
    try {
      const { checked } = e.target;
      floatBoxRef.current.handlerShowBox();
      setLoadings(loadingInspecaoProcessoController.changeLoadingSinalizacaoIcones(true));
      await callback(idProcesso, checked);
      const { data } = await findInformacoesAdicionaisInpescaoProcessoService(idProcesso);
      setAdictionalInfos(data);
    } finally {
      setLoadings(loadingInspecaoProcessoController.changeLoadingSinalizacaoIcones(false));
    }
  }

  return (
    <div style={{ width: '33%', display: 'flex', justifyContent: 'flex-end' }}>
      <BoxSinalizacao ref={parentFloatBoxRef}>
        <TooltipUI title={sinalizacaoClienteProcessoQuery?.codigoConsumidor ? 'Código do Consumidor' : ''}>
          <SinalizacaoContainer indicador={sinalizacaoClienteProcessoQuery?.codigoConsumidor ? 'codigoConsumidor' : ''}>
            <i className="fas fa-lock" />
          </SinalizacaoContainer>
        </TooltipUI>

        <TooltipUI title={!sinalizacaoClienteProcessoQuery?.permiteCobrancaPresencial ? naoPermiteCobrancaPresencial : ''}>
          <SinalizacaoContainer indicador={!sinalizacaoClienteProcessoQuery?.permiteCobrancaPresencial ? 'permiteCobrancaPresencial' : ''}>
            <img
              src={carImage}
              alt={naoPermiteCobrancaPresencial}
            />
          </SinalizacaoContainer>
        </TooltipUI>

        <TooltipUI title={sinalizacaoClienteProcessoQuery?.hintStatusProcesso || ''}>
          <SinalizacaoContainer
            statusProcesso={sinalizacaoClienteProcessoQuery?.statusExpiracao}
            indicador={sinalizacaoClienteProcessoQuery?.statusExpiracao ? 'processoExpirado' : ''}
          >
            <i className="fas fa-frown" />
          </SinalizacaoContainer>
        </TooltipUI>

        <TooltipUI title={sinalizacaoClienteProcessoQuery?.clienteInativo ? 'Cliente Inativo' : ''}>
          <SinalizacaoContainer indicador={sinalizacaoClienteProcessoQuery?.clienteInativo ? 'clienteInativo' : ''}>
            <i className="fas fa-user-times" />
          </SinalizacaoContainer>
        </TooltipUI>

        <TooltipUI title={sinalizacaoClienteProcessoQuery?.recomendacaoEspecial ? 'Recomendação Especial' : ''}>
          <SinalizacaoContainer
            indicador={sinalizacaoClienteProcessoQuery?.recomendacaoEspecial ? 'recomendacaoEspecial' : ''}
          >
            <i className="fas fa-star" />
          </SinalizacaoContainer>
        </TooltipUI>

        <ButtonDropDown onClick={() => floatBoxRef.current.handlerShowBox()}>
          <div />
        </ButtonDropDown>
      </BoxSinalizacao>
      <FloatBox ref={floatBoxRef} parentRef={parentFloatBoxRef} variant="right">
        <DropdownContainer>
          <li>
            <CheckboxUI
              label="Código do Consumidor"
              disabled={!podeModificarFase}
              checked={sinalizacaoClienteProcessoQuery?.codigoConsumidor}
              onChange={e => onChangeClienteComboBox(e, alterarProtegerCodigoConsumidorDoProcessoService)}
            />
          </li>
          <li>
            <CheckboxUI
              label={naoPermiteCobrancaPresencial}
              disabled
              checked={!sinalizacaoClienteProcessoQuery?.permiteCobrancaPresencial}
            />
          </li>
          <li>
            <CheckboxUI
              label="Processo Expirado"
              checked={sinalizacaoClienteProcessoQuery?.processoExpirado}
              disabled
            />
          </li>
          <li>
            <CheckboxUI
              label="Cliente Inativo"
              checked={sinalizacaoClienteProcessoQuery?.clienteInativo}
              disabled
            />
          </li>
          <li>
            <CheckboxUI
              label="Recomendação Especial"
              checked={sinalizacaoClienteProcessoQuery?.recomendacaoEspecial}
              disabled
            />
          </li>
        </DropdownContainer>
      </FloatBox>
    </div>
  );
}

export default React.memo(SinalizacaoIcones);
