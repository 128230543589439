import React from 'react';
import { IconButton } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import CheckboxField from '../../../../../../../components/UI/Field/Checkbox/CheckboxField';
import OrdenacaoColuna from '../../../../../../../components/UI/Tabela/Ordenacao/OrdenacaoColuna';
import TableHeader from '../../../../../../../models/TableHeader';
import IconAgrupamento from '../../../../../../../assets/images/icons/iconAgrupamento.svg';
import TooltipUI from '../../../../../../../components/UI/Tooltip/Tooltip';
import { CancelarEnvioIcon } from '../../../../../../../assets/Icons/CancelarEnvioIcon';
import { blackTwo, lightGrey } from '../../../../../../../helpers/constants/colors.constants';
import { CommentExclamationIcon } from '../../../../../../../assets/Icons/CommentExclamationIcon';
import { CommentPlusIcon } from '../../../../../../../assets/Icons/CommentPlusIcon';
import { FaLockIcon } from '../../../../../../Comissionado/ContaCorrenteComissionadoStyles';

class RpsEmitidosFactory {
  makeCabecalhoTabela(columnOrdenacao,
    arrowDirection,
    setColumnOrdenacao,
    setArrowDirection,
    onClickColumnOrdenar,
    checked,
    changeSelectAll = () => { },
    permissoes) {
    return [
      new TableHeader({
        id: 'idNotaFiscalCliente',
        checkbox: true,
        label:
  <div>
    <CheckboxField
      checked={checked}
      onChange={changeSelectAll}
      disabled={!permissoes.prestarContas}
    />
  </div>,
        width: 16,
      }),
      new TableHeader({
        id: 'dataNota',
        label: <OrdenacaoColuna
          columnLabel="Data da Emissão"
          idColumn="dataNota"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 160,
      }),
      new TableHeader({
        id: 'idPrestacao',
        label: <OrdenacaoColuna
          columnLabel="Prestação"
          idColumn="idPrestacao"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        maxWidth: 106,
        alignCell: 'center',
      }),
      new TableHeader({
        id: 'idPrestacaoAgrupadas',
        label: 'Agrupado',
        alignCell: 'center',
        maxWidth: 108,
      }),
      new TableHeader({
        id: 'nomeUsuarioEmissao',
        label: <OrdenacaoColuna
          columnLabel="Emitida por"
          idColumn="nomeUsuarioEmissao"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'left',
        maxWidth: 160,
      }),
      new TableHeader({
        id: 'idCliente',
        label: <OrdenacaoColuna
          columnLabel="Cód. Cliente"
          idColumn="idCliente"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 100,
      }),
      new TableHeader({
        id: 'nomeCliente',
        label: <OrdenacaoColuna
          columnLabel="Nome do Cliente"
          idColumn="nomeCliente"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'left',
        maxWidth: 319,
      }),
      new TableHeader({
        id: 'emissaoNotaFiscalBloqueada',
        label: <Info />,
        alignCell: 'center',
        maxWidth: 40,
      }),
      new TableHeader({
        id: 'acoes',
        label: 'Ações',
        alignCell: 'center',
        maxWidth: 123,
      }),
    ];
  }

  makeConteudoTabela(utilStyles, rpsEmitidos, changeSelectId, listIds, permissoes, onClickButton, handleOpenDialogCancel) {
    if (!rpsEmitidos || rpsEmitidos.length === 0) {
      return [];
    }

    return rpsEmitidos.map(recebimento => ({
      id: recebimento.idNotaFiscalCliente,
      values: {
        dataNota: recebimento.dataNota,
        idPrestacao: recebimento.idPrestacao,
        notaFiscalAgrupada: recebimento.notaFiscalAgrupada,
        nomeUsuarioEmissao: recebimento.nomeUsuarioEmissao,
        idCliente: recebimento.idCliente,
        nomeCliente: recebimento.nomeCliente,
        idNotaFiscalCliente:
        recebimento.emissaoNotaFiscalBloqueada || (recebimento.requerInformacaoExtraNF === 'S' && recebimento.informacaoExtra === null)
          ? ''
          : (
            <CheckboxField
              name={String(recebimento.idNotaFiscalCliente)}
              checked={listIds[recebimento.idNotaFiscalCliente] || false}
              onChange={changeSelectId}
              disabled={!permissoes.prestarContas}
            />
          ),
        idPrestacaoAgrupadas: recebimento.idPrestacaoAgrupadas !== '' ? (
          <TooltipUI title={recebimento.idPrestacaoAgrupadas}>
            <img src={IconAgrupamento} alt="" width="15px" height="14px" />
          </TooltipUI>
        ) : '',
        requerInformacaoExtraNF: recebimento.requerInformacaoExtraNF,
        informacaoExtra: recebimento.informacaoExtra,
        informacoesComplementares: recebimento.informacoesComplementares,
        rpsEmitidoCorpoContaCorrenteCliente: recebimento.rpsEmitidoCorpoContaCorrenteCliente,
        emissaoNotaFiscalBloqueada: recebimento.emissaoNotaFiscalBloqueada ? (

          <TooltipUI title="Fora do período de Emissão de NF deste Cliente" placement="bottom">
            <span>
              <FaLockIcon />
            </span>
          </TooltipUI>
        ) : '',
        acoes: (
          <div>
            {!permissoes.prestarContas ? (
              <IconButton
                size="small"
                className={utilStyles.mr6}
                disabled
              >
                <CancelarEnvioIcon
                  color={lightGrey}
                />
              </IconButton>
            ) : (
              <TooltipUI title="Cancelar">
                <IconButton
                  size="small"
                  className={utilStyles.mr6}
                  onClick={() => handleOpenDialogCancel(recebimento.idPrestacao, recebimento.idCliente)}
                >
                  <CancelarEnvioIcon
                    color={blackTwo}
                  />
                </IconButton>
              </TooltipUI>
            )}
            {recebimento.requerInformacaoExtraNF === 'N' || !permissoes.prestarContas ? (
              <IconButton
                size="small"
                disabled
                className={utilStyles.mr6}
              >
                <CommentExclamationIcon
                  color={lightGrey}
                />
              </IconButton>
            ) : (
              <TooltipUI title="Adicionar Informação Extra">
                <IconButton
                  size="small"
                  onClick={() => onClickButton(
                    true,
                    recebimento.informacaoExtra,
                    recebimento.informacoesComplementares,
                    recebimento.idPrestacao,
                    recebimento.idCliente,
                    recebimento.detalhesInfoExtraNF,
                    recebimento.requerInformacaoExtraNF,
                  )}
                  className={utilStyles.mr6}
                >
                  <CommentExclamationIcon
                    color={blackTwo}
                  />
                </IconButton>
              </TooltipUI>
            )}
            {!permissoes.prestarContas ? (
              <IconButton
                size="small"
                disabled
                className={utilStyles.mr6}
              >
                <CommentPlusIcon
                  color={lightGrey}
                />
              </IconButton>
            ) : (
              <TooltipUI title="Adicionar Informação Complementar">
                <IconButton
                  size="small"
                  onClick={() => onClickButton(
                    false,
                    recebimento.informacaoExtra,
                    recebimento.informacoesComplementares,
                    recebimento.idPrestacao,
                    recebimento.idCliente,
                    recebimento.detalhesInfoExtraNF,
                    recebimento.requerInformacaoExtraNF,
                  )}
                  className={utilStyles.mr6}
                >
                  <CommentPlusIcon
                    color={blackTwo}
                  />
                </IconButton>
              </TooltipUI>
            )}
          </div>
        ),
      },
    }));
  }
}

export const rpsEmitidosFactory = new RpsEmitidosFactory();
