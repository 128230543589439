import React from 'react';
import { useDispatch } from 'react-redux';
import { initializePageAction } from '../../../../../../store/theme.actions';
import withTokenInterceptor from '../../../../../../hoc/withTokenInterceptor/withTokenInterceptor';
import ContaCorrenteClienteProvider from '../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import ImprimirPdf from './ImprimirPdf';

function ImprimirPage() {
  const dispatch = useDispatch();
  dispatch(initializePageAction('Impressão do PDF'));
  return (
    <ContaCorrenteClienteProvider>
      <ImprimirPdf />
    </ContaCorrenteClienteProvider>
  );
}
export default withTokenInterceptor(ImprimirPage);
