import React from 'react';
import { useDispatch } from 'react-redux';
import PagamentoDiretoProvider from '../../store/PagamentoDireto/PagamentoDiretoProvider';
import PagamentoDireto from '../../components/PagamentoDireto/PagamentoDireto';
import { initializePageAction } from '../../store/theme.actions';

export default function PagamentoDiretoPage() {
  const dispatch = useDispatch();

  dispatch(initializePageAction('Pagamento Direto'));
  return (
    <PagamentoDiretoProvider>
      <PagamentoDireto />
    </PagamentoDiretoProvider>
  );
}
