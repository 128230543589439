import React, { useCallback } from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import useStyles from './dataExpiracaoHistorico.styles';
import WrapperSubTitleForm from '../../../UI/WrapperSubTitleForm/WrapperSubTitleForm';
import { getCurrentIdUsuario, getFormatedDateUSA, toAmericanDate } from '../../../../helpers/utils/utils';
import DateField from '../../../UI/Field/Date/DateField';
import { onChangeDataExpiracaoFieldsAction } from '../../../../store/AcordoSeguro/Historico/historicoAS.store';
import { confirmarDadosAlterarPrazoAction } from '../../../../store/AcordoSeguro/Historico/historicoAS.saga';
import DialogNew from '../../../../layouts/FormDialog/DialogNew';

function DataExpiracaoHistorico({ onCloseHandler }) {
  const dispatch = useDispatch();

  const styles = useStyles();

  const dataExpiracaoAcordoSeguro = useSelector(states => states.historicoAS.dataExpiracaoFields.dataExpiracaoAcordoSeguro.copy());
  const estagio = useSelector(states => states.historicoAS.estagio);
  const openDialogDataExpiracao = useSelector(states => states.historicoAS.openDialogDataExpiracao);
  const nroProcesso = useSelector(states => states.historicoAS.nroProcesso);

  const validarDataCampoCalendario = useCallback(() => !isNaN(parseInt(dataExpiracaoAcordoSeguro.value[9])) && `${getFormatedDateUSA(toAmericanDate(dataExpiracaoAcordoSeguro.value))}` !== 'Invalid Date', [dataExpiracaoAcordoSeguro.value]);

  const onConfirmarDataExpiracaoHandler = useCallback(() => {
    dispatch(confirmarDadosAlterarPrazoAction(nroProcesso, getCurrentIdUsuario()));
  }, [dispatch, nroProcesso]);

  const onChangeDateFieldHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeDataExpiracaoFieldsAction(name, value));
  }, [dispatch]);

  return (
    <DialogNew
      open={openDialogDataExpiracao}
      text={['Histórico do Acordo Seguro > Alteração na Data de Expiração']}
      onClose={onCloseHandler}
      className={styles.dialog}
    >
      <div className={styles.div__root}>
        <div className={styles.div__info__root}>
          <WrapperSubTitleForm title="Informações Gerais" className={styles.wrapperSubtitle} />
          <Typography component="p" className={styles.infos__gerais}>
            <b>Processo: </b>
            {nroProcesso}
          </Typography>

          <Typography component="p" className={styles.infos__gerais}>
            <b>Estágio do Processo: </b>
            {estagio}
          </Typography>

          <Typography component="p" className={styles.infos__gerais}>
            <b>Data de Expiração: </b>
            {dataExpiracaoAcordoSeguro.value}
          </Typography>
          <div style={{ fontSize: '12px' }}>
            <p>
              Selecione no calendário a nova data para prorrogação ou antecipação*
              {' '}

            </p>
          </div>
          <div style={{ width: '103px' }}>
            <DateField
              isForm
              name={dataExpiracaoAcordoSeguro.name}
              value={dataExpiracaoAcordoSeguro.value}
              onChange={onChangeDateFieldHandler}
            />
          </div>
        </div>
      </div>
      <div className={styles.div__footer}>
        <Button
          onClick={onCloseHandler}
        >
          Cancelar
        </Button>
        <Button
          disabled={!validarDataCampoCalendario()}
          onClick={onConfirmarDataExpiracaoHandler}
        >
          Confirmar
        </Button>
      </div>
    </DialogNew>
  );
}

export default React.memo(DataExpiracaoHistorico);
