import React, { useCallback, useEffect, useState } from 'react';
import { ButtonUI } from '../../../../../../../components';
import TabelaRpsEmitidos from './Tabela/TabelaRpsEmitidos';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { rpsEmitidosFactory as factory } from './RpsEmitidosFactory';
import useStyles from './RpsEmitidosStyles';
import { cancelarRpsEmitidoAction, gerarNotaFiscalAction, pesquisarRpsEmitidosAction } from '../../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import {
  setSimpleValueRpsEmitidosAction,
  setSimpleValueRpsEmitidosFormFieldsHandlerAction,
  setSimpleValuesListIdsRpsEmitidosAction,
} from '../../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { getNewPage, useUtilStyles } from '../../../../../../../helpers';
import DialogInformacaoComplementar from './Dialog/DialogInformacaoComplementar';
import {
  INFORMACAO_COMPLEMENTAR, INFORMACAO_EXTRA, RPS_EMITIDOS,
} from './RpsEmitidosConstantes';
import OptionDialogNew from '../../../../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';
import DialogNew from '../../../../../../../layouts/FormDialog/DialogNew';

function RpsEmitidos({ permissoes }) {
  const styles = useStyles();
  const utilStyles = useUtilStyles();
  const dispatch = useCCClienteDispatch();

  const page = useCCClienteSelector(states => (states.rpsEmitidos.page));
  const rowsPerPage = useCCClienteSelector(states => (states.rpsEmitidos.rowsPerPage));
  const totalElements = useCCClienteSelector(states => (states.rpsEmitidos.totalElements));
  const columnOrdenacao = useCCClienteSelector(states => (states.rpsEmitidos.columnOrdenacao));
  const arrowDirection = useCCClienteSelector(states => (states.rpsEmitidos.arrowDirection));
  const tableView = useCCClienteSelector(states => (states.rpsEmitidos.tableView));
  const [conteudoTabela, setConteudoTabela] = useState([]); // State que vai atualizar quando tiver dadosPesquisa...
  const listIds = useCCClienteSelector(states => (states.rpsEmitidos.listIds));
  const selectAll = useCCClienteSelector(states => (states.rpsEmitidos.selectAll));
  const openDialogButton = useCCClienteSelector(states => (states.rpsEmitidos.openDialogButton));
  const openDialogCancelar = useCCClienteSelector(states => (states.rpsEmitidos.openDialogCancelar));

  const [routesName, setRouteNames] = useState([RPS_EMITIDOS]);
  const [informacaoExtra, setInformacaoExtra] = useState(false);
  const [gerarNotaFiscal, setGerarNotaFiscal] = useState(Object.keys(listIds).filter((key => listIds[key] === true)).map(key => Number(key)).length !== 0);
  const [informacaoExtraValue, setInformacaoExtraValue] = useState();

  const changeSelectId = useCallback((e) => {
    const { name, checked } = e.target;
    dispatch(setSimpleValuesListIdsRpsEmitidosAction(name, checked));
  }, [dispatch]);

  const onClickColumnOrdenar = useCallback(() => {
    if (tableView.length > 0) {
      dispatch(setSimpleValueRpsEmitidosAction('page', 0));
      dispatch(pesquisarRpsEmitidosAction(false));
    }
  }, [dispatch, tableView]);

  const setColumnOrdenacao = useCallback((column) => {
    dispatch(setSimpleValueRpsEmitidosAction('columnOrdenacao', column));
  }, [dispatch]);

  const setArrowDirection = useCallback((internalReturn) => {
    dispatch(setSimpleValueRpsEmitidosAction('arrowDirection', internalReturn));
  }, [dispatch]);

  const changePageHandler = useCallback((event, newPage) => {
    dispatch(setSimpleValueRpsEmitidosAction('page', Number(newPage)));

    dispatch(pesquisarRpsEmitidosAction(false));
  }, [dispatch]);

  const changeRowsPerPageHandler = useCallback((event) => {
    const size = event.target.value;
    const newPage = getNewPage(rowsPerPage, page, size);

    dispatch(setSimpleValueRpsEmitidosAction('page', Number(newPage)));
    dispatch(setSimpleValueRpsEmitidosAction('rowsPerPage', Number(size)));
    dispatch(pesquisarRpsEmitidosAction(false));
  }, [dispatch, rowsPerPage, page]);

  const changeSelectAll = useCallback((e) => {
    const { checked } = e.target;
    dispatch(setSimpleValueRpsEmitidosAction('selectAll', checked));
    dispatch(setSimpleValueRpsEmitidosAction('listIds', Object.keys(listIds).reduce((acc, key) => ({ ...acc, [key]: checked }), {})));
  }, [listIds, dispatch]);

  const onClose = useCallback(() => {
    dispatch(setSimpleValueRpsEmitidosAction('openDialogButton', false));
  }, [dispatch]);

  const onClickButton = useCallback((isInfoExtra, informacaoExtraTexto, informacoesComplementares, idPrestacao, idCliente, detalhesInfoExtraNF, requerInformacaoExtraNF) => {
    setInformacaoExtra(isInfoExtra);
    setInformacaoExtraValue(requerInformacaoExtraNF);
    if (isInfoExtra === false) {
      setRouteNames([INFORMACAO_COMPLEMENTAR]);
    } else {
      setRouteNames([INFORMACAO_EXTRA]);
    }
    dispatch(setSimpleValueRpsEmitidosAction('idPrestacaoSelecionada', idPrestacao));
    dispatch(setSimpleValueRpsEmitidosAction('idClienteSelecionado', idCliente));
    dispatch(setSimpleValueRpsEmitidosFormFieldsHandlerAction('informacaoExtra', informacaoExtraTexto));
    dispatch(setSimpleValueRpsEmitidosFormFieldsHandlerAction('informacoesComplementares', informacoesComplementares));
    dispatch(setSimpleValueRpsEmitidosAction('detalhesInfoExtraNF', detalhesInfoExtraNF));
    dispatch(setSimpleValueRpsEmitidosAction('openDialogButton', true));
  }, [dispatch]);

  function handleOnClickCancel() {
    dispatch(setSimpleValueRpsEmitidosAction('openDialogCancelar', false));
    dispatch(setSimpleValueRpsEmitidosAction('idPrestacaoSelecionada', null));
    dispatch(setSimpleValueRpsEmitidosAction('idClienteSelecionado', null));
  }

  const handleDeleteRps = useCallback(() => {
    dispatch(cancelarRpsEmitidoAction());
  }, [dispatch]);

  const handleGerarNotaFiscalClick = useCallback(() => {
    dispatch(setSimpleValueRpsEmitidosAction('selectAll', false));
    dispatch(gerarNotaFiscalAction());
  }, [dispatch]);

  const handleOpenDialogCancel = useCallback((idPrestacao, idCliente) => {
    dispatch(setSimpleValueRpsEmitidosAction('openDialogCancelar', true));
    dispatch(setSimpleValueRpsEmitidosAction('idPrestacaoSelecionada', idPrestacao));
    dispatch(setSimpleValueRpsEmitidosAction('idClienteSelecionado', idCliente));
  }, [dispatch]);

  useEffect(() => {
    setConteudoTabela(factory.makeConteudoTabela(
      utilStyles,
      tableView,
      changeSelectId,
      listIds,
      permissoes,
      onClickButton,
      handleOpenDialogCancel,
    ));
  }, [tableView, listIds, changeSelectId, onClickButton, permissoes, utilStyles, handleOpenDialogCancel]);

  useEffect(() => {
    setGerarNotaFiscal(Object.keys(listIds).filter((key => listIds[key] === true)).map(key => Number(key)).length === 0);
  }, [listIds]);

  useEffect(() => {
    dispatch(pesquisarRpsEmitidosAction(true));
  }, [dispatch]);

  return (
    <>
      <OptionDialogNew
        open={openDialogCancelar}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={handleOnClickCancel}
        onClickConfirm={handleDeleteRps}
      >
        <div style={{ fontSize: '16px' }}>
          <p>
            Tem certeza que deseja cancelar a emissão do RPS selecionado?
          </p>
        </div>
      </OptionDialogNew>
      <DialogNew
        open={openDialogButton}
        text={routesName}
        className={styles.dialog}
        onClose={onClose}
      >
        <DialogInformacaoComplementar
          onClose={onClose}
          isInformacaoExtra={informacaoExtra}
          informacaoExtraValue={informacaoExtraValue}
        />
      </DialogNew>
      <div className={styles.body}>
        <header className={styles.header}>
          <div>
            <ButtonUI
              className={styles.button}
              disabled={!permissoes.prestarContas || gerarNotaFiscal}
              onClick={handleGerarNotaFiscalClick}
            >
              Gerar Nota Fiscal
            </ButtonUI>
          </div>
        </header>
        <main className={styles.main}>
          <div>
            <TabelaRpsEmitidos
              columns={factory.makeCabecalhoTabela(
                columnOrdenacao,
                arrowDirection,
                setColumnOrdenacao,
                setArrowDirection,
                onClickColumnOrdenar,
                selectAll,
                changeSelectAll,
                permissoes,
              )}
              rows={conteudoTabela}
              page={page}
              rowsPerPage={rowsPerPage}
              size={totalElements}
              rowsPerPageOptions={[10, 15, 20]}
              onChangePage={changePageHandler}
              onChangeRowsPerPage={changeRowsPerPageHandler}
            />
          </div>
        </main>
      </div>
    </>
  );
}

export default React.memo(RpsEmitidos);
