
import styled, { css } from 'styled-components';

function getHoverTransparency(outlined = false) {
  if (outlined) {
    return this.hoverTransparency - 1;
  }
  return this.hoverTransparency;
}

const colors = {
  none: {
    primary: 'black',
    secondary: 'transparent',
    hoverTransparency: 0,
    getHoverTransparency,
  },
  purple: {
    primary: 'white',
    secondary: '#423362',
    hoverTransparency: 1,
    getHoverTransparency,
  },
};


export const ButtonWrapper = styled.span`
  button {
    padding: 5px;

    :hover {
      opacity: 0.64;
    }

    ${props => (props?.outlined ? css`
      color: ${colors[props.color].secondary};
      background-color: white;
      outline: 2px solid ${colors[props.color].secondary};
    ` : css`
      color: ${colors[props.color].primary};
      background-color: ${colors[props.color].secondary};
      outline: none;
    `)}
  }

  .MuiButtonBase-root.Mui-disabled {
    cursor: not-allowed;
    color: #707070;
    ${props => (props?.outlined ? css`
      background-color: transparent;
    ` : css`
      background-color: #a2a0a0;
    `)}
  }

  .MuiIconButton-root:hover {
    opacity: 0.64;
    background-color: ${props => `rgba(0,0,0,${colors[props.color].getHoverTransparency(props?.outlined)})`}
  }

  ${props => (props?.disabled ? css`
    cursor: not-allowed;
  ` : css`
    cursor: pointer;
  `)}
`;
