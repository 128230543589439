export const PROCESSAMENTO_TITLE = 'Processamento de Borderôs';
export const TIPO_ACORDO_LABEL = 'Tipo Acordo Seguro';
export const FORMA_PAGAMENTO_LABEL = 'Forma de Pagamento de Comissões dos Borderôs';
export const QUANTIDADE_BORDERO_LABEL = 'Quantidade de Borderôs';
export const QUANTIDADE_BORDERO_DIAS_LABEL = 'Quantidade de Dias';
export const COMISSAO_COMERCIAL_LABEL = 'Comissão Comercial Principal';
export const COMISSAO_TELEMARKETING_LABEL = 'Comissão Telemarketing';
export const COMISSAO_PERMANENTE_LABEL = 'Comissão Permanente Comercial Secundário';
export const COMISSAO_PERMANENTE_TELEMARKETING_LABEL = 'Comissão Permanente Telemarketing';
export const INSTRUCAO_INICIAL_LABEL = 'Instrução Inicial';
export const INSERIR_INSTRUCAO_INICIAL_LABEL = 'Insira a instrução';
