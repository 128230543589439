import { updateFieldsWithErrors } from '../../../helpers/utils/form.utils';
import { isBadRequestException, toAmericanDate, toBrazilianDate } from '../../../helpers/utils/utils';
import { agendarCompromissoProcessoSerivce, buscarAgendamentoProcessoService, excluirAgendamentoCompromissoProcessoService } from '../../../services/core/processos/processosService';

const ID_TIPO_AGENDAMENTO = '1';
const ID_TIPO_TITULO_AGENDAMENTO = '1';

class AcaoAgendarCompromisso {
  makeFormFields(values, isLimparForm = false) {
    const idTipoAgendamento = isLimparForm ? '' : values?.idTipoAgendamento || ID_TIPO_AGENDAMENTO;
    const tituloAgendamento = isLimparForm ? '' : values?.tituloAgendamento || ID_TIPO_TITULO_AGENDAMENTO;
    return {
      dataAgendamento: values?.dataAgendamento || '',
      idTipoAgendamento,
      textoAgendamento: values?.textoAgendamento || '',
      changeIndicators: values?.changeIndicators || '',
      tituloAgendamento,

    };
  }

  makeChangeIndicators(isLimparForm = false) {
    return {
      dataAgendamento: false,
      idTipoAgendamento: !isLimparForm,
      textoAgendamento: false,
      tituloAgendamento: !isLimparForm,
    };
  }

  makeErrors(errors) {
    return {
      dataAgendamento: {
        error: errors?.dataAgendamento?.error || false,
        errorMessage: errors?.dataAgendamento?.errorMessage || '',
      },
      idTipoAgendamento: {
        error: errors?.idTipoAgendamento?.error || false,
        errorMessage: errors?.idTipoAgendamento?.errorMessage || '',
      },
      tituloAgendamento: {
        error: errors?.tituloAgendamento?.error || false,
        errorMessage: errors?.tituloAgendamento?.errorMessage || '',
      },
    };
  }

  makePayloadAgendarCompromisso(idProcesso, formFields, isAntecedente) {
    return {
      idProcesso,
      dataAgendamento: toAmericanDate(formFields?.dataAgendamento),
      idTipoAgendamento: formFields?.idTipoAgendamento,
      textoAgendamento: formFields?.textoAgendamento,
      idTipoTituloAgendamento: formFields?.tituloAgendamento,
      isAntecedente,
    };
  }

  makeDefaultAgendamento(response) {
    return {
      dataAgendamento: toBrazilianDate(response?.dataAgendamento || ''),
      idTipoAgendamento: response?.idTipoAgendamento || ID_TIPO_AGENDAMENTO,
      textoAgendamento: response?.textoAgendamento || '',
      tituloAgendamento: response?.idTituloAgendamento || '',
      hasAgendamentoCadastrado: !!response?.dataAgendamento,
    };
  }

  async buscarAgendamentoProcesso(idProcesso) {
    try {
      const response = await buscarAgendamentoProcessoService(idProcesso);
      return this.makeDefaultAgendamento(response?.data);
    } catch {
      return this.makeDefaultAgendamento();
    }
  }

  async agendarCompromisso(idProcesso, formFields, errors, isAntecedente) {
    try {
      const payload = this.makePayloadAgendarCompromisso(idProcesso, formFields, isAntecedente);
      await agendarCompromissoProcessoSerivce(payload);
      return { success: true, errors };
    } catch (error) {
      if (isBadRequestException(error)) {
        const [updatedErrors] = updateFieldsWithErrors(error, errors);
        return { success: false, errors: this.makeErrors(updatedErrors) };
      }
      return { errors };
    }
  }

  async excluirAgendamentoCompromisso(idProcesso) {
    try {
      await excluirAgendamentoCompromissoProcessoService(idProcesso);
      return { error: null, success: true };
    } catch (error) {
      return { error, success: false };
    }
  }
}

export const acaoAgendarCompromissoController = new AcaoAgendarCompromisso();
