export const OPTIONAL_DIALOG = {
  MESSAGE_INFORMATION: 'As informações na tela serão atualizadas com os dados da empresa selecionada. '
    + 'Deseja salvar  as alterações antes de prosseguir?',
};

export const ERROR_REDIRECT_DIALOG = {
  MESSAGE_CONSULTAR_CLIENTE: 'Acesso não permitido. Para consultar as informações cadastrais do cliente, '
    + 'revise as permissões do usuário',
  MESSAGE_EDITAR_CLIENTE: 'Acesso não permitido. Para editar as informações cadastrais do cliente, '
    + 'revise as permissões do usuário',
};
