import { textOverflow, toBrazilianDate } from '../../../../../helpers/utils/utils';
import { buscarDocumentosClientePorIdClienteService } from '../../../../../services/core/cliente/cliente.services';

const TOTAL_CHARS_1 = 30;
const TOTAL_CHARS_2 = 48;

class DocumentosClienteController {
  makeDocumentos(res) {
    return {
      content: res?.content?.map(this.makeContentResponseDocumentos) || [],
      size: res?.size || 0,
      page: res?.number || 0,
      totalElements: res?.totalElements || 0,
      lastPage: res?.last || true,
    };
  }

  makeContentResponseDocumentos(documento) {
    let nomeDocumento = '';
    if (documento?.nomeDocumento) {
      nomeDocumento = `${documento.nomeDocumento}.${documento.extensao}`;
    }
    return {
      idClienteDocumento: documento.idClienteDocumento || -1,
      tipo: textOverflow(documento?.tipo || '', TOTAL_CHARS_2),
      tipoAlt: documento?.tipo || '',
      descricao: textOverflow(documento?.descricao || '', TOTAL_CHARS_1),
      descricaoAlt: documento?.descricao || '',
      dataArquivamento: toBrazilianDate(documento?.dataArquivamento) || '',
      nomeUsuario: textOverflow(documento?.nomeUsuario || '', TOTAL_CHARS_1),
      nomeUsuarioAlt: documento?.nomeUsuario || '',
      isDocumentoOriginal: documento?.serDocumentoOriginal || false,
      nomeDocumento: textOverflow(nomeDocumento, TOTAL_CHARS_2),
      nomeDocumentoAlt: nomeDocumento,
      extensao: documento?.extensao || '',
    };
  }

  async findInitialDatasOnScreen(idCliente) {
    const [documentos] = await Promise.all([
      buscarDocumentosClientePorIdClienteService(idCliente),
    ]);

    return {
      documentos: this.makeDocumentos(documentos.data),
    };
  }
}

export const documentosClienteController = new DocumentosClienteController();
