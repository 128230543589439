import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid24 from '../UI/Grid24';
import { getFieldName, useUtilStyles } from '../../helpers';
import { findCategoriasAction } from '../../store/Global/LoadSelectors/loadSelectors.saga';
import { InputField, SelectorField } from '..';
import MaskField from '../UI/Field/Mask';
import { getRequiredLabel } from '../../helpers/utils/utils';
import SubTitleForm from '../UI/SubTitleForm/SubTitleForm';
import { CNPJ_MASK, CPF_MASK } from '../../helpers/constants/layout.constants';


function CorrigirDadosDevedorBorderoComponent({
  borderoComErro, idCliente, nomeCliente, setBordero,
}) {
  const constants = {
    INFO_CLIENTE: 'Informações do Cliente',
    CODIGO_CLIENTE: 'Código',
    NOME_CLIENTE: 'Nome',
    INFO_DEVEDOR: 'Informações Básicas do Devedor',
    CATEGORIA: 'Categoria*',
    CPF_CPNJ: 'CPF/CNPJ*',
    CODIGO_NO_CLIENTE: 'Código no Cliente',
    CODIGO_DEVEDOR: 'Código do Devedor',
    RAZAO_SOCIAL: 'Razão Social/Nome*',
    PREPOSTO: 'Preposto',
    NOME_FANTASIA: 'Nome Fantasia',
    INSCRICAO_ESTADUAL: 'Inscrição Estadual',
    NOME_ADVOGADO: 'Nome do Advogado',
    TELEFONE_ADVOGADO: 'Telefone do Advogado',
    INSTRUCOES: 'Instruções',
  };

  const utilStyles = useUtilStyles();
  const dispatch = useDispatch();

  const categorias = useSelector(states => states.selectors.categorias.selector);

  useEffect(() => {
    dispatch(findCategoriasAction());
  }, [dispatch]);

  const onChangeHandler = useCallback((event) => {
    const name = getFieldName(event.target);
    const { value } = event.target;
    setBordero(old => ({ ...old, [name]: { ...old[name], value } }));
  }, [setBordero]);

  const onFocusHandler = useCallback((event) => {
    const name = getFieldName(event.target);
    setBordero(old => ({ ...old, [name]: { ...old[name], error: false, errorMessage: '' } }));
  }, [setBordero]);

  const getFormatMask = () => {
    if (borderoComErro?.categoria?.value === 'PESSOA_FISICA') {
      return CPF_MASK;
    }
    return CNPJ_MASK;
  };

  const onChangeCategoriaHandler = useCallback((event) => {
    borderoComErro.documento.value = '';
    borderoComErro.documento.error = true;
    borderoComErro.documento.errorMessage = 'CPF ou CNPJ inválido';
    const documento = [{ ...borderoComErro?.documento }];
    setBordero(oldBordero => ({ ...oldBordero, ...documento }));
    onChangeHandler(event);
  }, [borderoComErro, setBordero, onChangeHandler]);

  return (
    <>
      <SubTitleForm>{constants.INFO_CLIENTE}</SubTitleForm>
      <Grid24 container isFormContainer spacing="1" style={{ marginBottom: '16px' }}>
        <Grid24 xs={24} sm={4}>
          <InputField
            fullWidth
            isForm
            disabled
            label={constants.CODIGO_CLIENTE}
            name="idCliente"
            value={idCliente}
          />
        </Grid24>
        <Grid24 xs={24} sm={10}>
          <InputField
            fullWidth
            isForm
            disabled
            label={constants.NOME_CLIENTE}
            name="nomeCliente"
            value={nomeCliente}
          />
        </Grid24>
      </Grid24>
      <SubTitleForm>{constants.INFO_DEVEDOR}</SubTitleForm>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={3}>
          <SelectorField
            fullWidth
            isForm
            label={getRequiredLabel(constants.CATEGORIA)}
            items={categorias}
            name="categoria"
            value={String(borderoComErro?.categoria?.value)}
            error={borderoComErro?.categoria?.error || borderoComErro.documento?.error}
            errorMessage={borderoComErro?.categoria?.errorMessage}
            onChange={onChangeCategoriaHandler}
            onFocus={onFocusHandler}
            disabled={borderoComErro?.categoria?.disabled && borderoComErro.documento?.disabled}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <MaskField
            isForm
            fullWidth
            disabled={borderoComErro?.documento?.disabled}
            label={constants.CPF_CPNJ}
            name="documento"
            value={borderoComErro?.documento?.value || ''}
            error={borderoComErro?.documento?.error}
            errorMessage={borderoComErro?.documento?.errorMessage}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
            format={getFormatMask()}
          />
        </Grid24>
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={4}>
          <InputField
            fullWidth
            isForm
            disabled={borderoComErro?.codigoNoCliente?.disabled}
            label={constants.CODIGO_NO_CLIENTE}
            value={borderoComErro?.codigoNoCliente?.value || ''}
            name="codigoNoCliente"
            error={borderoComErro?.codigoNoCliente?.error}
            errorMessage={borderoComErro?.codigoNoCliente?.errorMessage}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={9}>
          <InputField
            fullWidth
            isForm
            disabled={borderoComErro?.razaoSocial?.disabled}
            label={constants.RAZAO_SOCIAL}
            value={borderoComErro?.razaoSocial?.value || ''}
            name="razaoSocial"
            error={borderoComErro?.razaoSocial?.error}
            errorMessage={borderoComErro?.razaoSocial?.errorMessage}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <InputField
            fullWidth
            isForm
            disabled={borderoComErro?.preposto?.disabled}
            label={constants.PREPOSTO}
            value={borderoComErro?.preposto?.value || ''}
            name="preposto"
            error={borderoComErro?.preposto?.error}
            errorMessage={borderoComErro?.preposto?.errorMessage}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
          />
        </Grid24>
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={8}>
          <InputField
            fullWidth
            isForm
            disabled={borderoComErro?.nomeFantasia?.disabled}
            label={constants.NOME_FANTASIA}
            value={borderoComErro?.nomeFantasia?.value || ''}
            name="nomeFantasia"
            error={borderoComErro?.nomeFantasia?.error}
            errorMessage={borderoComErro?.nomeFantasia?.errorMessage}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={3}>
          <InputField
            fullWidth
            isForm
            disabled={borderoComErro?.inscricaoEstadual?.disabled}
            label={constants.INSCRICAO_ESTADUAL}
            value={borderoComErro?.inscricaoEstadual?.value || ''}
            name="inscricaoEstadual"
            error={borderoComErro?.inscricaoEstadual?.error}
            errorMessage={borderoComErro?.inscricaoEstadual?.errorMessage}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
          />
        </Grid24>
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={7}>
          <InputField
            fullWidth
            isForm
            disabled={borderoComErro?.nomeAdvogado?.disabled}
            label={constants.NOME_ADVOGADO}
            value={borderoComErro?.nomeAdvogado?.value || ''}
            name="nomeAdvogado"
            error={borderoComErro?.nomeAdvogado?.error}
            errorMessage={borderoComErro?.nomeAdvogado?.errorMessage}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={3}>
          <InputField
            fullWidth
            isForm
            disabled={borderoComErro?.telefoneAdvogado?.disabled}
            label={constants.TELEFONE_ADVOGADO}
            value={borderoComErro?.telefoneAdvogado?.value || ''}
            name="telefoneAdvogado"
            error={borderoComErro?.telefoneAdvogado?.error}
            errorMessage={borderoComErro?.telefoneAdvogado?.errorMessage}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
          />
        </Grid24>
      </Grid24>
      <Grid24 className={utilStyles.w80} container isFormContainer spacing="1">
        <Grid24 xs={24} sm={24}>
          <InputField
            fullWidth
            isForm
            disabled={borderoComErro?.instrucoes?.disabled}
            label={constants.INSTRUCOES}
            value={borderoComErro?.instrucoes?.value || ''}
            name="instrucoes"
            erro={borderoComErro?.instrucoes?.error}
            errorMessage={borderoComErro?.instrucoes?.errorMessage}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
          />
        </Grid24>
      </Grid24>
    </>
  );
}

export const CorrigirDadosDevedorBordero = CorrigirDadosDevedorBorderoComponent;
