import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PaperUI from '../../UI/Paper/PaperUI';
import PrazoExpiradoLayoutAS from './PrazoExpiradoLayout/PrazoExpiradoLayoutAS';
import ButtonUI from '../../UI/Button/ButtonUI';
import useStyles from './prazoExpiradoAS.styles';
import Table from '../../UI/Tabela/PaginadaSimples/Table';
import { buildSettingsTable } from '../../../helpers/factory/global.factory';
import useUtilStyles from '../../../helpers/styles/utils.styles';
import CardWrapper from '../../UI/CardWrapper/CardWrapper';
import { buildTableColumns } from './factory/prazoExpirado.factory';
import {
  getCurrentIdUsuario, getDate7daysAgo, getDateNow, getDateTimeMask, getMoneyMask, toAmericanDate,
} from '../../../helpers/utils/utils';
import CheckboxSelecionarTodos from '../../UI/Tabela/CheckboxSelecionarTodos/CheckboxSelecionarTodos';
import TempoFaseAmpulheta from '../../UI/TempoFaseAmpulheta/TempoFaseAmpulheta';
import CheckboxField from '../../UI/Field/Checkbox/CheckboxField';
import TooltipUI from '../../UI/Tooltip/Tooltip';
import WhiteCircleIcon from '../../UI/Icon/WhiteCircleIcon';
import {
  atualizarStatusExpiradoParaAguardandoEnvioSmsEmail, buscarIdProcessoExpirados,
  buscarPrazoExpiradosAcordoSeguro, distribuirProcessoExpiradoAcordoSeguro,
} from '../../../services/core/acordoseguro/prazoExpirado.service';
import { initializePageAction } from '../../../store/theme.actions';
import EnviarProcesso from './EnviarProcesso/EnviarProcesso';
import OptionDialogNew from '../../UI/Dialogs/OptionDialog/OptionDialogNew';

function PrazoExpiradoASPage() {
  const styles = useStyles();
  const { tableTitle } = useUtilStyles();

  const location = useLocation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [todosIds, setTodosIds] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [disableButtons, setDisableButtons] = useState(true);
  const [openEnviarProcesso, setOpenEnviarProcesso] = useState(false);

  const [nroProcessoRealocar, setNroProcessoRealocar] = useState(null);
  const [openRealocarDialog, setOpenRealocarDialog] = useState(false);
  const [openDistribuirDialog, setOpenDistribuirDialog] = useState(false);
  const [openPesquisaAvancada, setOpenPesquisaAvancada] = useState(false);
  const [cacheRequestFields, setCacheRequestFields] = useState(null);
  const [settingsTable, setSettingsTable] = useState(buildSettingsTable([], 0, 10, 0));
  const [tableRows, setTableRows] = useState([]);
  const [arrowOrdenacao, setArrowOrdenacao] = useState(false);
  const [columnOrdenacao, setColumnOrdenacao] = useState({
    element: 'nroProcesso',
    order: 'ASC',
  });

  useEffect(() => {
    dispatch(initializePageAction('Prazos Expirados'));
  }, [dispatch]);

  const loadingPage = useCallback(async (func) => {
    try {
      setLoading(true);
      await func();
    } finally {
      setLoading(false);
    }
  }, []);


  const changeSelectAllHandler = useCallback((event) => {
    const { checked } = event.target;
    setSelectAll(checked);
    setTodosIds(Object.keys(todosIds).reduce((acc, nroProcessoCheck) => ({
      ...acc,
      [nroProcessoCheck]: checked,
    }), {}));
    const tableCopy = settingsTable.content.map(content => ({
      ...content,
      checked,
    }));
    setSettingsTable({ ...settingsTable, content: tableCopy });
  }, [settingsTable, todosIds]);

  const changeTableRowCheckboxHandler = useCallback((event) => {
    const { name, checked } = event.target;
    setTodosIds(old => ({
      ...old,
      [name]: checked,
    }));
    const tableCopy = settingsTable.content.map((content) => {
      if (name === String(content.nroProcesso)) {
        return {
          ...content,
          checked,
        };
      }
      return content;
    });
    setSettingsTable({ ...settingsTable, content: tableCopy });
  }, [settingsTable, setSettingsTable]);

  const pesquisarPrazosExpirados = useCallback(async (cacheFields, ordenacao, page, rowsPerPage) => {
    const payload = cacheFields || {};
    try {
      const odernacaoQuery = ordenacao.element === 'nroProcesso' ? [`${ordenacao.element},${ordenacao.order}`] : [`${ordenacao.element},${ordenacao.order}`, `nroProcesso,${ordenacao.order}`];

      let response = await buscarPrazoExpiradosAcordoSeguro(payload, odernacaoQuery, page, rowsPerPage);

      if (response.data.content.length === 0 && response.data.totalElements !== 0) {
        const lastPage = response.data.totalPages - 1;
        response = await buscarPrazoExpiradosAcordoSeguro(payload, [`${ordenacao.element},${ordenacao.order}`], lastPage, rowsPerPage);
      }
      let tableCopy = response.data.content;

      if (selectAll) {
        setTodosIds(old => Object.keys(old).reduce((acc, nroProcessoCheck) => ({
          ...acc,
          [nroProcessoCheck]: true,
        }), {}));
        tableCopy = response.data.content.map(s => ({
          ...s,
          checked: true,
        }));
      }


      setSettingsTable(({
        content: tableCopy,
        page: response.data.number,
        rowsPerPage: response.data.size,
        size: response.data.totalElements,
      }));
    } catch {
    }
  }, [selectAll]);

  async function buscarIdsProcesso(cacheFields) {
    const responseIds = await buscarIdProcessoExpirados(cacheFields);
    const ids = responseIds?.data || [];
    setTodosIds(ids.reduce((acc, nroProcessoChecked) => (
      {
        ...acc,
        [nroProcessoChecked]: false,
      }
    ), {}));
  }

  const recarregarTabela = useCallback(async () => {
    await buscarIdsProcesso(cacheRequestFields || {});
    await loadingPage(async () => pesquisarPrazosExpirados(cacheRequestFields || {}, {
      element: columnOrdenacao.element,
      order: columnOrdenacao.order,
    }, settingsTable.page, settingsTable.rowsPerPage));
  }, [settingsTable.page, settingsTable.rowsPerPage, columnOrdenacao, cacheRequestFields, loadingPage, pesquisarPrazosExpirados]);

  const atualizarStatusExpirado = useCallback(async (idProcesso) => {
    try {
      setLoading(true);
      await atualizarStatusExpiradoParaAguardandoEnvioSmsEmail(idProcesso, getCurrentIdUsuario());
      await recarregarTabela();
      setOpenRealocarDialog(false);
      setNroProcessoRealocar(null);
    } finally {
      setLoading(false);
    }
  }, [recarregarTabela]);

  const distribuirProcesso = useCallback(async () => {
    try {
      setLoading(true);

      const idProcesso = [];
      [Object.entries(todosIds).filter(idz => idz[1] === true)][0].forEach((processo) => {
        idProcesso.push(+processo[0]);
      });

      const payload = {
        idProcesso,
        idUsuario: getCurrentIdUsuario(),
      };

      await distribuirProcessoExpiradoAcordoSeguro(payload);
      await recarregarTabela();
      setSelectAll(false);
      setOpenDistribuirDialog(false);
    } catch {
      // Nada definido.
    } finally {
      setLoading(false);
    }
  }, [recarregarTabela, todosIds]);

  const onClickOrdernacaoColumn = useCallback(async (columnName) => {
    await loadingPage(async () => pesquisarPrazosExpirados(cacheRequestFields || {}, {
      element: columnName,
      order: arrowOrdenacao ? 'ASC' : 'DESC',
    }, settingsTable.page, settingsTable.rowsPerPage));
  }, [loadingPage, pesquisarPrazosExpirados, settingsTable, arrowOrdenacao, cacheRequestFields]);

  const buildAntecedentesIcon = useCallback((antecedente) => {
    if (antecedente !== 'NENHUM') {
      let hint;
      let colorAntecedente;

      if (antecedente === 'ATIVO') {
        hint = 'Antecedente Ativo';
        colorAntecedente = styles.antecedenteAtivo;
      } else {
        hint = 'Antecedente Inativo';
        colorAntecedente = styles.antecedenteInativo;
      }
      return (
        <WhiteCircleIcon>
          <TooltipUI title={hint}>
            <div className={colorAntecedente}>
              <i className="fas fa-archive" />
            </div>
          </TooltipUI>
        </WhiteCircleIcon>
      );
    } return null;
  }, [styles]);

  const buildCheckboxSelectAll = useCallback(() => (
    <CheckboxSelecionarTodos
      checked={selectAll}
      onChange={changeSelectAllHandler}
    />
  ), [selectAll, changeSelectAllHandler]);

  const onClickRealocar = useCallback((nroProcesso) => {
    setOpenRealocarDialog(true);
    setNroProcessoRealocar(nroProcesso);
  }, []);

  const buildCheckbox = useCallback((checked, name) => (
    <CheckboxField
      name={name}
      checked={checked}
      onChange={changeTableRowCheckboxHandler}
    />
  ), [changeTableRowCheckboxHandler]);

  const buildTable = useCallback((content, index) => {
    if (content.length === 0) {
      return [];
    }
    return {
      id: content.nroProcesso,
      values: {
        selecionarTodos: buildCheckbox(todosIds[content.nroProcesso] || false, `${content.nroProcesso}`),
        nroProcesso: content.nroProcesso,
        proporcaoFase: (
          <TempoFaseAmpulheta
            corAlertaFase={content.corAlertaFase}
            dataVencimento={content.dataVencimentoFaseHint}
          />),
        tipoAntecedente: buildAntecedentesIcon(content.antecedente),
        dataAcordo: getDateTimeMask(content.dataAbertura, true),
        nomeDevedor: content.nomeDevedor,
        estagio: content.estagio,
        valorAcordo: getMoneyMask(content.valorProcesso),
        acoes: (
          <TooltipUI title="Recolocar na fila de Envio de SMS/E-mail">
            <div
              style={{ cursor: 'pointer' }}
              role="button"
              tabIndex={0}
              onClick={() => onClickRealocar(content.nroProcesso)}
              onKeyUp={() => {
              }}
            >
              <i className="fas fa-reply-all" />
            </div>
          </TooltipUI>
        ),
      },
    };
  }, [buildAntecedentesIcon, onClickRealocar, buildCheckbox, todosIds]);

  const onChangePageHandler = useCallback(async (event, newPage) => {
    await loadingPage(async () => pesquisarPrazosExpirados(cacheRequestFields, columnOrdenacao, newPage, settingsTable.rowsPerPage));
    setSettingsTable(old => ({ ...old, page: newPage }));
  }, [pesquisarPrazosExpirados, loadingPage, columnOrdenacao, settingsTable.rowsPerPage, cacheRequestFields]);

  const onChangeRowsPerPageHandler = useCallback(async (event) => {
    await loadingPage(async () => pesquisarPrazosExpirados(cacheRequestFields, columnOrdenacao, settingsTable.page, event.target.value));
    setSettingsTable(old => ({ ...old, rowsPerPage: event.target.value }));
  }, [pesquisarPrazosExpirados, loadingPage, columnOrdenacao, settingsTable.page, cacheRequestFields]);

  const closeRealocarDialogHandler = useCallback(() => {
    setOpenRealocarDialog(false);
  }, []);

  const closeDistribuirDialogHandler = useCallback(() => {
    setOpenDistribuirDialog(false);
  }, []);

  // Constrói a tabela.
  const tableContent = useMemo(
    () => settingsTable.content.map((processo, index) => buildTable(
      processo,
      index,
    )),
    [settingsTable.content, buildTable],
  );

  useEffect(() => {
    if (tableContent?.length > 0) {
      setSelectAll(Object.keys(todosIds).every(key => todosIds[key] === true));
    }
    setTableRows(tableContent);
  }, [tableContent, todosIds]);


  const carregarDadosIniciais = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        periodoAcordoInicial: toAmericanDate(getDate7daysAgo()),
        periodoAcordoFinal: toAmericanDate(getDateNow()),
      };

      await buscarIdsProcesso(payload);
      const response = await buscarPrazoExpiradosAcordoSeguro(payload, ['nroProcesso,ASC'], 0, 10);
      setTableRows([]);
      const tableCopy = response.data.content.map(processo => ({
        ...processo,
        checked: false,
      }));
      setSettingsTable(({
        content: tableCopy,
        page: response.data.number,
        rowsPerPage: response.data.size,
        size: response.data.totalElements,
      }));
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    carregarDadosIniciais().then();
  }, [carregarDadosIniciais, location.refreshPage]);

  useEffect(() => {
    setCacheRequestFields(null);
    setArrowOrdenacao(false);
    setColumnOrdenacao({
      element: 'nroProcesso',
      order: 'ASC',
    });
    setOpenPesquisaAvancada(false);
    setNroProcessoRealocar(null);
  }, [location.refreshPage]);

  useEffect(() => {
    if ([Object.entries(todosIds).filter(idz => idz[1] === true)][0].length > 0) {
      setDisableButtons(false);
    } else {
      setDisableButtons(true);
    }
  }, [todosIds]);

  return (
    <PaperUI>
      <CardWrapper showLoading={loading}>
        <EnviarProcesso
          setLoading={setLoading}
          open={openEnviarProcesso}
          onClose={() => setOpenEnviarProcesso(false)}
          nroProcessoEnviar={todosIds}
          recarregarTabela={recarregarTabela}
        />
        <OptionDialogNew
          open={openRealocarDialog}
          confirmLabel="Confirmar"
          cancelLabel="Cancelar"
          onClickCancel={closeRealocarDialogHandler}
          onClickConfirm={() => atualizarStatusExpirado(nroProcessoRealocar)}
        >
          <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
            <p>
              Tem certeza que deseja enviar este processo para o estágio
              <br />
              'Aguardando o envio de SMS/E-mail' ?
            </p>
          </div>
        </OptionDialogNew>
        <OptionDialogNew
          open={openDistribuirDialog}
          confirmLabel="Confirmar"
          cancelLabel="Cancelar"
          onClickCancel={closeDistribuirDialogHandler}
          onClickConfirm={() => distribuirProcesso()}
          text="  Tem certeza que deseja distribuir os processos selecionados? "
        />
        <PrazoExpiradoLayoutAS
          openPesquisaAvancada={openPesquisaAvancada}
          buscarIdsProcesso={buscarIdsProcesso}
          onClickPesquisaAvancada={() => setOpenPesquisaAvancada(old => !old)}
          setCacheRequestFields={setCacheRequestFields}
          settingsTable={settingsTable}
          setSettingsTable={setSettingsTable}
          setLoading={setLoading}
          ordenacao={[`${columnOrdenacao.element},${columnOrdenacao.order}`]}
        />
        <div className={styles.div__buttons}>
          <div className={styles.button}>
            <ButtonUI color="white" onClick={() => setOpenEnviarProcesso(true)} disabled={disableButtons}>
              Enviar Processo
            </ButtonUI>
          </div>
          <div className={styles.buttonDistribuir}>
            <ButtonUI color="white" onClick={() => setOpenDistribuirDialog(true)} disabled={disableButtons}>
              Distribuir Processo
            </ButtonUI>
          </div>
        </div>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Typography component="h3" className={tableTitle}>
              Resultado da Pesquisa
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Table
              rows={tableRows}
              columns={buildTableColumns(arrowOrdenacao, columnOrdenacao, setArrowOrdenacao, setColumnOrdenacao, onClickOrdernacaoColumn, buildCheckboxSelectAll)}
              page={settingsTable.page}
              size={settingsTable.size}
              rowsPerPage={settingsTable.rowsPerPage}
              onChangePage={onChangePageHandler}
              onChangeRowsPerPage={onChangeRowsPerPageHandler}
            />
          </Grid>
        </Grid>
      </CardWrapper>
    </PaperUI>
  );
}

export default PrazoExpiradoASPage;
