import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { logoutAction } from '../../store/Login/login.saga';

let timeoutId = -1;

function LogoutTimeout({ children }) {
  const dispatch = useDispatch();
  const expirationSession = useSelector((states) => {
    if (states.authentication.userInformation && states.authentication.userInformation.expirationSession) {
      return states.authentication.userInformation.expirationSession;
    }
    return 0;
  });

  useEffect(() => {
    const doInactive = () => {
      dispatch(logoutAction());
    };

    const startTimer = () => {
      timeoutId = window.setTimeout(doInactive, (expirationSession * 1000));
    };

    const resetTimer = () => {
      window.clearTimeout(timeoutId);
      startTimer();
    };

    window.addEventListener('click', resetTimer);
    startTimer();
    return () => {
      window.removeEventListener('click', resetTimer);
    };
  }, [dispatch, expirationSession]);

  return children;
}

export default LogoutTimeout;
