import { call, put, takeLatest } from '@redux-saga/core/effects';
import { select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import ContaCorrenteClienteService, {
  cancelarNotaFiscal, findBuscarLancamento, findBuscarNotasFiscais, inserirNotaFiscal, downloadProtocoloCancelamentoNF, inserirNovoBoleto,
} from '../../services/core/contaCorrenteClienteService/ContaCorrenteClienteService';
import {
  buildFormToSave,
  buildSaldosPagamentos,
  buildSaldosRelacaoPrestacao,
  isDisabledButtons,
  onChangeFormFieldsRelacaoPrestacaoErrorAction,
  resetStatesEmptyValueAction,
  setCommonAction,
  setDefaultClienteSelecionadoAction,
  setDetalhesDoClieteSelecionadoAction,
  setFormFieldsRepasseAction,
  setNaoPrestadosFormToSaveAction,
  setRecebimentosFormFieldsErrorsAction,
  setRpsEmitidosFormAction,
  setSimpleValueAutorizacaoRepasseHandlerAction,
  setSimpleValueLancamentoHandlerAction,
  setSimpleValueNaoPrestadosAction,
  setSimpleValuePagamentosAction,
  setSimpleValuePagamentosValorAction,
  setSimpleValueRecebimentosAction,
  setSimpleValueRelacaoPrestacaoAction,
  setSimpleValueRpsEmitidosAction,
  setSimpleValuesDetalhesAction,
  setSimpleValuesPesquisaAction,
  setSimpleValuesRepasseAction,
  setSimpleValueDadosGeracaoNFAction,
  setSimpleValueNotasFiscaisHandlerAction,
  setSimpleValueBoletosRelacaoPrestacaoAction,
  setSimpleValueInformacoesAdicionaisRelacaoPrestacaoAction,
  resetFormFieldsNovaNotaAction,
  setNovaNotaFormFieldsErrorsAction,
  resetFormFieldsNovoBoletoAction,
  setBoletoFormFieldsErrorsAction,
  setNovoBoletoAction,
  setSimpleValuePdfAction,
} from './contaCorrenteCliente.store';
import {
  ATUALIZAR_PAGINA_ACTION,
  BUSCAR_DETALHES_CLIENTE_SELECIONADO_ACTION,
  CANCELAR_PRESTACAO_ACTION,
  DELETE_LANCAMENTO_NAO_PRESTADO_CLIENTE_SELECIONADO,
  DETALHES_ORIGIN,
  NAO_PRESTADOS_ORIGIN,
  PESQUISAR_CONTA_CORRENTE_CLIENTES_ACTION,
  PESQUISAR_CONTAS_CORRENTES_CLIENTE_ONCLICK_ACTION,
  PESQUISAR_PRESTACAO_INICIAL_ACTION,
  PRESTACAO_SUCCESS,
  PRESTAR_CONTAS_CLIENTE_SELECIONADO_ACTION,
  RELACAO_PRESTACAO_ORIGIN,
  ESTORNAR_PRESTACAO_ACTION,
  ATUALIZAR_STATUS_LANCAMENTO_NAO_PRESTADO_ACTION,
  PRESTAR_TODOS_ACTION,
  DOWNLOAD_RELATORIO_CLIENTE,
  DOWNLOAD_RELATORIO_LANCAMENTOS_NAO_PRESTADOS,
  FIND_EDITAR_LANCAMENTO_ACTION,
  SAVE_LANCAMENTO_NAO_PRESTADO_ACTION,
  EDIT_LANCAMENTO_NAO_PRESTADO_ACTION,
  BUSCAR_RELACOES_DO_CLIENTE_ACTION,
  BUSCAR_SALDOS_RELACAO_PRESTACAO_ACTION,
  PRINT_RELACAO_PRESTACAO_ACTION,
  ENVIAR_EMAIL_PRESTACAO_ACTION,
  PESQUISAR_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION,
  FIND_PAGAMENTOS_ACTION,
  PAGAMENTOS,
  DELETE_LANCAMENTO_PAGAMENTO_ACTION,
  INSERIR_LANCAMENTO_PAGAMENTO_ACTION,
  DOWNLOAD_RELATORIO_PAGAMENTOS,
  PESQUISAR_REPASSE_ACTION,
  PESQUISAR_LISTA_AUTORIZACAO_REPASSE_ACTION,
  PESQUISAR_DADOS_GERACAO_NOTA_FISCAL_ACTION,
  ATUALIZAR_STATUS_LIDO_AUTORIZACAO_REPASSE_ACTION,
  DOWNLOAD_ARQUIVO_AUTORIZACAO_REPASSE_ACTION,
  PESQUISAR_TOTAL_REPASSE_ACTION,
  LIBERAR_REPASSES_ACTION,
  DEFINIR_VALOR_SELECIONADO_REPASSE_ACTION,
  PRINT_ARQUIVO_REPASSES_AUTORIZACAO_REPASSE_PDF_ACTION,
  PESQUISAR_TIPOS_LANCAMENTOS_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION,
  ATUALIZA_STATE_TIPOS_LANCAMENTOS_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION,
  MUDA_STATUS_LANCAMENTOS_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION,
  DOWNLOAD_RELATORIO_REPASSES_ACTION,
  PESQUISAR_RECEBIMENTOS_ONCLICK_ACTION,
  DEFINIR_VALOR_RECEBIMENTO_ACTION,
  HABILITAR_EMITIR_EH_AGRUPAR_RPS_ACTION,
  GERAR_PDF_RECEBIMENTOS_PESQUISADOS_ACTION,
  EMITIR_RPS_ONCLICK_ACTION,
  AGRUPAR_RPS_ON_CLICK_ACTION,
  PESQUISAR_RPS_EMITIDOS_ACTION,
  SALVAR_ALTERACOES_INFORMACOES_CLIENTE_ACTION,
  CANCELAR_RPS_ACTION,
  PESQUISAR_LISTA_LANCAMENTOS_ACTION,
  ATUALIZAR_STATUS_LIDO_DADOS_GERACAO_NF_ACTION,
  DOWNLOAD_ARQUIVO_REMESSA_ACTION,
  VISUALIZAR_PDF_REMESSA_ON_CLICK_ACTION,
  GERAR_NOTA_FISCAL_ACTION,
  IMPORTAR_ARQUIVO_RETORNO_ACTION,
  PESQUISAR_NOTAS_FISCAIS_ACTION,
  CANCELAR_NOTAS_FISCAIS_ACTION,
  INSERIR_NOVA_NOTA_FISCAL,
  PESQUISAR_DADOS_BOLETOS_RELACAO_PRESTACAO_ACTION,
  CANCELAR_BOLETO_RELACAO_PRESTACAO_ACTION,
  BUSCAR_INFORMACOES_RELACAO_PRESTACAO_ACTION,
  VISUALIZAR_NOTA_FISCAL_CLIENTE_ACTION,
  DOWNLOAD_PROTOCOLO_CANCELAMENTO_NF_ACTION,
  INSERIR_NOVO_BOLETO,
  REGISTRAR_BOLETO_RELACAO_PRESTACAO_ACTION,
  ENVIAR_EMAIL_BOLETO_ACTION,
  IMPRIMIR_BOLETO_RELACAO_PRESTACAO_ACTION,
  GET_PDF_ACTION,
  DOWNLAOD_PDF_ACTION,
  BUSCAR_MOTIVO_ESTORNO_PRESTACAO_ACTION,
} from './contaCorrenteCliente.contants';
import {
  buildPayload,
  getDate30daysAgoCorridos,
  getMoneyMask, openPageFile, TableUtils, textOverflow, toAmericanDate, toastUnmappedException,
  toBrazilianDate, downloadFileSispag, openPagePDFBase64, downloadFile,
} from '../../helpers';
import LancamentosNaoPrestadosService
from '../../services/core/contaCorrenteClienteService/LancamentosNaoPrestadosService';
import FieldModel from '../../models/FieldModel';
import { RESULT_NOT_FOUND } from '../../helpers/constants/global.constants';
import { ContaCorrenteClienteFactory } from '../../pages/ContaCorrenteCliente/V2/ContaCorrenteCliente/ContaCorrenteClienteController';
import { getExceptionHandler } from '../../helpers/utils/exception.util';
import ContaCorrenteClientePagamentosService from '../../services/core/contaCorrenteClienteService/ContaCorrenteClientePagamentosService';
import ContaCorrenteClienteBoletoService from '../../services/core/contaCorrenteClienteService/ContaCorrenteClienteBoletosService';
import RepasseService from '../../services/core/contaCorrenteClienteService/Repasse.Service';
import RecebimentosService from '../../services/core/contaCorrenteClienteService/RecebimentosService';
import RpsEmitidosService from '../../services/core/contaCorrenteClienteService/RpsEmitidosService';
import RelacaoPrestacaoService from '../../services/core/contaCorrenteClienteService/RelacaoPrestacaoService';

const getFormDataValue = (formData, name) => (formData.get(name) !== '' ? formData.get(name) : null);

function* dadosResumo(quantidadeSelecionadaRecebimento,
  valorHonorariosAmigaveisSelecionado,
  valorHonorariosJudiciaisSelecionado,
  valorDespesasSelecionada) {
  yield put(setSimpleValueRecebimentosAction('quantidadeSelecionadaRecebimento', quantidadeSelecionadaRecebimento));
  yield put(setSimpleValueRecebimentosAction('valorHonorariosAmigaveisSelecionado', valorHonorariosAmigaveisSelecionado));
  yield put(setSimpleValueRecebimentosAction('valorHonorariosJudiciaisSelecionado', valorHonorariosJudiciaisSelecionado));
  yield put(setSimpleValueRecebimentosAction('valorDespesasSelecionada', valorDespesasSelecionada));
}

function buildListaRepasse(repasses) {
  return repasses.map(repasse => ({
    idCliente: repasse?.idCliente || null,
    dataPrestacao: toBrazilianDate(repasse?.dataPrestacao) || '',
    idPrestacaoContaCliente: repasse?.idPrestacaoContaCliente || null,
    nomeCliente: repasse?.nomeCliente || '',
    valorRepasse: getMoneyMask(repasse?.valorRepasse) || '',
    tipoLiquidacaoPrestacao: repasse.tipoLiquidacaoPrestacao || '',
    nomeBanco: repasse?.nomeBanco || '',
    tipoDescricao: repasse?.tipoDescricao || '',
    valorRepasseWithoutMask: repasse?.valorRepasse || '',
    idBanco: repasse?.idBanco || null,
  }));
}

function buildListaAutorizacaoRepasse(autorizacaoRepasses) {
  return autorizacaoRepasses.map(autorizacaoRepasse => ({
    idAutorizacaoRepasse: autorizacaoRepasse?.idAutorizacaoRepasse || null,
    dataLiberacao: toBrazilianDate(autorizacaoRepasse?.dataLiberacao),
    idUsuarioLeitura: autorizacaoRepasse?.idUsuarioLeitura || null,
    nomeUsuario: autorizacaoRepasse?.nomeUsuario || '',
    dataLeitura: toBrazilianDate(autorizacaoRepasse?.dataLeitura),
    valorSispag: getMoneyMask(autorizacaoRepasse?.valorSispag) || '',
    valorOutros: getMoneyMask(autorizacaoRepasse?.valorOutros) || '',
    lido: autorizacaoRepasse.lido,
  }));
}

function buildListaDadosGeracaoNF(dadosGeracaoNF) {
  return dadosGeracaoNF.map(dadosGeracaoNF => ({
    idNotaFiscalClienteRemessa: dadosGeracaoNF?.idNotaFiscalClienteRemessa || null,
    serAvulso: dadosGeracaoNF?.serAvulso || null,
    data: toBrazilianDate(dadosGeracaoNF?.data) || '',
    nomeUsuario: dadosGeracaoNF?.nomeUsuario || '',
    dataLeitura: toBrazilianDate(dadosGeracaoNF?.dataLeitura) || '',
    valorTotal: getMoneyMask(dadosGeracaoNF?.valorTotal) || '',
    prefeitura: dadosGeracaoNF?.prefeitura || null,
    lido: dadosGeracaoNF?.lido || null,
  }));
}

function buildListaDadosBoletos(dadosBoletos) {
  return dadosBoletos.map(dadoBoleto => ({
    idBoletoCliente: dadoBoleto.idBoletoCLiente || null,
    dataEmissao: toBrazilianDate(dadoBoleto.dataEmissao) || '',
    dataVencimento: toBrazilianDate(dadoBoleto.dataVencimento) || '',
    descricao: dadoBoleto.descricao || '',
    mensagem: dadoBoleto.mensagem || null,
    nomeUsuario: dadoBoleto.nomeUsuario || '',
    nossoNumero: dadoBoleto.nossoNumero || '',
    valor: getMoneyMask(dadoBoleto.valor) || '',
  }));
}

function buildPayloadFromFormData(formData, mostrarSoDesbloqueados) {
  return {
    idCliente: getFormDataValue(formData, 'idCliente'),
    dataPrestacao: toAmericanDate(getFormDataValue(formData, 'dataPrestacao')),
    exibirApenasDesbloqueados: mostrarSoDesbloqueados,
    idFilial: getFormDataValue(formData, 'idFilial'),
    idUsuarioResponsavel: getFormDataValue(formData, 'idUsuarioResponsavel'),
    nossoNumeroBoleto: getFormDataValue(formData, 'nossoNumeroBoleto'),
    tipoLancamento: getFormDataValue(formData, 'tipoLancamento'),
    nomeCliente: getFormDataValue(formData, 'nomeCliente'),
    numeroRPSNotaFiscal: getFormDataValue(formData, 'numeroRPSNotaFiscal'),
  };
}

function buildRelacaoPrestacaoPayload(idCliente, fields) {
  return {
    idCliente,
    periodoInicial: fields.periodoInicial ? toAmericanDate(fields.periodoInicial?.value) : toAmericanDate(getDate30daysAgoCorridos()),
    periodoFinal: fields.periodoFinal ? toAmericanDate(fields.periodoFinal?.value) : toAmericanDate(toBrazilianDate(new Date())),
    prestacaoAPagar: fields.prestacaoAPagar?.value,
  };
}

function buildPesquisaContaCorrenteClientePayload(fields) {
  return {
    idCliente: fields.idCliente,
    dataPrestacao: toAmericanDate(fields.dataPrestacao),
    exibirApenasDesbloqueados: fields.exibirApenasDesbloqueados,
    idFilial: fields.idFilial,
    idUsuarioResponsavel: fields.idUsuarioResponsavel,
    nossoNumeroBoleto: fields.nossoNumeroBoleto,
    tipoLancamento: fields.tipoLancamento,
    nomeCliente: fields.nomeCliente,
  };
}

function buildPesquisaSwitchContaCorrenteClientePayload(fields, exibirApenasDesbloqueados) {
  return {
    idCliente: fields.idCliente,
    dataPrestacao: toAmericanDate(fields.dataPrestacao),
    exibirApenasDesbloqueados,
    idFilial: fields.idFilial,
    idUsuarioResponsavel: fields.idUsuarioResponsavel,
    nossoNumeroBoleto: fields.nossoNumeroBoleto,
    tipoLancamento: fields.tipoLancamento,
    nomeCliente: fields.nomeCliente,
    numeroRPSNotaFiscal: fields.numeroRPSNotaFiscal,
  };
}

function buildAllContaCorrenteClientes(res) {
  return res.map(cliente => ({
    idCliente: cliente.idCliente,
    nomeClienteAlt: cliente?.nomeCliente || null,
    nomeCliente: textOverflow(cliente?.nomeCliente || '', 23),
    tipoPrestacao: cliente.descricaoTipoPrestacao,
    statusPrestacaoContas: cliente.statusPrestacaoContas,
    valorRepasse: getMoneyMask(cliente.valorRepasse),
    valorRepasseWithoutMask: cliente.valorRepasse,
  }));
}

function buildLancamentosNaoPrestados(lancamentos) {
  return lancamentos.map(lancamento => ({
    data: toBrazilianDate(lancamento?.data),
    historicoAlt: lancamento?.historico || null,
    historico: textOverflow(lancamento?.historico || '', 30),
    idContaCorrenteCliente: lancamento.idContaCorrenteCliente,
    idProcesso: lancamento.idProcesso,
    statusContaCorrenteCliente: lancamento.statusContaCorrenteCliente,
    tipoDescricao: lancamento.tipoDescricao,
    valorCredito: getMoneyMask(lancamento?.valorCredito) || '',
    valorDebito: getMoneyMask(lancamento?.valorDebito) || '',
    lancamentoAutomatico: lancamento.lancamentoAutomatico,
  }));
}

function buildPagamentosPrestacao(pagamentos) {
  return pagamentos.map(pagamento => ({
    idContaCorrenteCliente: pagamento.idContaCorrenteCliente,
    isPrestado: pagamento?.isPrestado || false,
    data: toBrazilianDate(pagamento?.data),
    isAcordo: pagamento.isAcordo || false,
    isPagamento: pagamento.isPagamento || false,
    isAcerto: pagamento.isAcerto || false,
    valor: getMoneyMask(pagamento?.valor) || '',
    excluir: pagamento.excluir || false,
  }));
}

function buildPesquisaRecebimento(recebimentos) {
  return recebimentos.map(recebimento => ({
    descricaoPersonalizado: recebimento.descricaoPersonalizado || '',
    despesas: recebimento.despesas || '',
    detalhesInfoExtraNF: recebimento.detalhesInfoExtraNF || '',
    detalhesInformacoesExtras: recebimento.detalhesInformacoesExtras || '',
    honorariosAmigaveis: recebimento.honorariosAmigaveis || '',
    honorariosJudiciais: recebimento.honorariosJudiciais || '',
    idCliente: recebimento.idCliente || '',
    idPrestacao: recebimento.idPrestacao || '',
    idPrestacaoContaCliente: recebimento.idPrestacaoContaCliente || '',
    nomeCliente: recebimento.nomeCliente || '',
    requerInformacoesExtras: recebimento.requerInformacoesExtras || false,
    tipoEmissao: recebimento.tipoEmissao || '',
    tipoPrestacao: recebimento.tipoPrestacao || '',
  }));
}

function buildRpsEmitidoCorpo(rpsEmitidoCorpoContaCorrenteCliente) {
  return rpsEmitidoCorpoContaCorrenteCliente.map(rpsEmitido => ({
    numeroNota: rpsEmitido.numeroNota || '',
    tipoNota: rpsEmitido.tipoNota || '',
    grupoPrefeitura: rpsEmitido.grupoPrefeitura || '',
    valorBruto: getMoneyMask(rpsEmitido.valorBruto) || '',
    impostos: getMoneyMask(rpsEmitido.impostos) || '',
    valorLiquido: getMoneyMask(rpsEmitido.valorLiquido) || '',
  }));
}

function buildRpsEmitidos(rpsEmitidos) {
  return rpsEmitidos.map(rpsEmitido => ({
    idNotaFiscalCliente: rpsEmitido.idNotaFiscalCliente || '',
    dataNota: rpsEmitido.dataNota || '',
    idPrestacao: rpsEmitido.idPrestacao || '',
    notaFiscalAgrupada: rpsEmitido.notaFiscalAgrupada || '',
    nomeUsuarioEmissao: rpsEmitido.nomeUsuarioEmissao || '',
    idCliente: rpsEmitido.idCliente || '',
    nomeCliente: rpsEmitido.nomeCliente || '',
    requerInformacaoExtraNF: rpsEmitido.requerInformacaoExtraNF || '',
    informacaoExtra: rpsEmitido.informacaoExtra,
    informacoesComplementares: rpsEmitido.informacoesComplementares || '',
    idPrestacaoAgrupadas: rpsEmitido.idPrestacaoAgrupadas || '',
    rpsEmitidoCorpoContaCorrenteCliente: buildRpsEmitidoCorpo(rpsEmitido.rpsEmitidoCorpoContaCorrenteCliente),
    detalhesInfoExtraNF: rpsEmitido.detalhesInfoExtraNF || '',
    emissaoNotaFiscalBloqueada: rpsEmitido.emissaoNotaFiscalBloqueada,
  }));
}

function buildPayloadFromFormDataRecebimentos(formData, somentePadrao) {
  return {
    idCliente: getFormDataValue(formData, 'idCliente'),
    idPrestacao: getFormDataValue(formData, 'idPrestacao'),
    idResponsavel: getFormDataValue(formData, 'idResponsavel'),
    periodoFinal: toAmericanDate(getFormDataValue(formData, 'periodoFinal')),
    periodoInicial: toAmericanDate(getFormDataValue(formData, 'periodoInicial')),
    somentePadrao,
  };
}

function buildPayloadRecebimentos(formData, somentePadrao) {
  return {
    idCliente: formData?.idCliente.value,
    idPrestacao: formData?.idPrestacao.value,
    idResponsavel: formData?.idResponsavel.value,
    periodoFinal: toAmericanDate(formData?.periodoFinal.value),
    periodoInicial: toAmericanDate(formData?.periodoInicial.value),
    somentePadrao,
  };
}

function buildListaLancamento(lancamentos) {
  return lancamentos.map(lancamento => ({
    data: toAmericanDate(lancamento?.data) || '',
    idProcesso: lancamento?.idProcesso || null,
    descricaoLancamento: lancamento?.descricaoLancamento || '',
    historico: lancamento?.historico || '',
    valorCredito: getMoneyMask(lancamento?.valorCredito) || '',
    valorDebito: getMoneyMask(lancamento?.valorDebito) || '',
  }));
}

function buildImportarRemessa(response) {
  return {
    qtdeRps: response?.qtdeRps || null,
    qtdeRpsAtualizados: response?.qtdeRpsAtualizados || null,
    qtdeRpsNaoLocalizados: response?.qtdeRpsNaoLocalizados || null,
    erroArquivo: response?.erroArquivo || false,
  };
}

function getPayloadRecebimentos(formData, somentePadrao, switchValue) {
  if (switchValue === true) {
    return buildPayloadRecebimentos(formData, somentePadrao);
  }
  return buildPayloadFromFormDataRecebimentos(formData, somentePadrao);
}

function buildNotasFiscais(notasFiscais) {
  return notasFiscais.map(notas => ({
    autorizarEmissaoNF: notas?.autorizarEmissaoNF || false,
    codigoVerificacao: notas?.codigoVerificacao || null,
    dataCancelamento: toAmericanDate(notas?.dataCancelamento) || '',
    dataNota: toAmericanDate(notas?.dataNota) || '',
    idNotaFiscalCliente: notas?.idNotaFiscalCliente || null,
    idsPrestacoesAgrupadas: notas?.idsPrestacoesAgrupadas || '',
    nomePrefeitura: notas?.nomePrefeitura || '',
    nomeUsuarioEmissao: notas?.nomeUsuarioEmissao || '',
    numeroNota: notas?.numeroNota || null,
    numeroNotaFiscalEletronica: notas?.numeroNotaFiscalEletronica || null,
    statusNotaFiscal: notas?.statusNotaFiscal || '',
    tipoEmissao: notas?.tipoEmissao || '',
    valorAmigavel: getMoneyMask(notas?.valorAmigavel) || '',
    valorDespesa: getMoneyMask(notas?.valorDespesa) || '',
    valorJudicial: getMoneyMask(notas?.valorJudicial) || '',
    valorLiquido: getMoneyMask(notas?.valorLiquido) || '',
    valorRetencaoCOFINS: getMoneyMask(notas?.valorRetencaoCOFINS) || '',
    valorRetencaoCSLL: getMoneyMask(notas?.valorRetencaoCSLL) || '',
    valorRetencaoIR: getMoneyMask(notas?.valorRetencaoIR) || '',
    valorRetencaoPIS: getMoneyMask(notas?.valorRetencaoPIS) || '',
    urlPrefeituraNF: notas?.urlPrefeituraNF || null,
  }));
}

function* ordenarPesquisaCliente(clientes) {
  const arrowDirection = yield select(states => states.pesquisa.arrowDirection);
  const element = yield select(states => states.pesquisa.columnOrdenacao.element);
  return TableUtils.ordenarTabela(arrowDirection, clientes, element);
}

function* definirExibicaoPesquisaCliente(allClientes, page, rowsPerPage, repesquisarMesmoCliente = false, manterPrestacao = false) {
  const clientesOrdenados = yield ordenarPesquisaCliente(allClientes);
  const clientesPaginados = yield TableUtils.paginar(clientesOrdenados, rowsPerPage, page);
  yield put(setSimpleValuesPesquisaAction('allClientes', clientesOrdenados));
  yield put(setSimpleValuesPesquisaAction('tableView', clientesPaginados));
  const oldClienteSelecionado = yield select(states => states.common.clienteSelecionado);
  yield put(setCommonAction('clienteSelecionado',
    {
      idCliente: null,
      desbloqueado: null,
    }));
  if (repesquisarMesmoCliente === true) {
    yield put(setDefaultClienteSelecionadoAction()); // Zerando para forçar o useEffect...
    yield put(setCommonAction('manterPrestacao', manterPrestacao));
    const clienteSelecionadoAtualizado = clientesPaginados.find(element => element.idCliente === oldClienteSelecionado.idCliente);
    const clienteSelecionado = {
      idCliente: clienteSelecionadoAtualizado?.idCliente,
      desbloqueado: clienteSelecionadoAtualizado?.statusPrestacaoContas === 'DESBLOQUEADO',
    };
    yield put(setCommonAction('clienteSelecionado', clienteSelecionado)); // Colocando o antigo para ativar o useEffect...
  } else {
    const clienteSelecionado = {
      idCliente: clientesPaginados[0]?.idCliente,
      desbloqueado: clientesPaginados[0]?.statusPrestacaoContas === 'DESBLOQUEADO',
    };
    yield put(setCommonAction('clienteSelecionado', clienteSelecionado));
  }
}


function* definirPaginacaoPesquisaCliente(page, rowsPerPage) {
  yield put(setSimpleValuesPesquisaAction('page', page));
  yield put(setSimpleValuesPesquisaAction('rowsPerPage', rowsPerPage));
}

function* emptyPesquisaClienteRequestResult() {
  toast.warning('Nenhum resultado foi encontrado relacionado à sua pesquisa');
  yield put(resetStatesEmptyValueAction());
}

/**
 * Define o fluxo da tela, atualiza todos os states com base na pesquisa da conta corrente...
 * @param responseToTransform Resposta da pesquisa da conta corrente...
 * @param cacheFieldsToSave Payload usado como filtro para ser salvo e usado como cache posteriormente...
 * @param page página da pesquisa da conta corrente...
 * @param repesquisarMesmoCliente Caso true, vai zerar o cliente selecionado e colocar o valor antigo há fim de forçar o side effect e re-pesquisar pela mesma conta corrente...
 * Senão vai marcar a primeira conta corrente como selecionada, assim irá forçar o side effect a re-pesquisar com a nova conta corrente selecionada...
 */
function* successPesquisaContasCorrentes(responseToTransform, cacheFieldsToSave, page, repesquisarMesmoCliente = false, manterPrestacao = false) {
  const allClientes = yield buildAllContaCorrenteClientes(responseToTransform);
  yield put(setSimpleValuesPesquisaAction('cacheFormFields', cacheFieldsToSave));
  yield put(setSimpleValuesPesquisaAction('allClientes', allClientes));

  const rowsPerPageStored = yield select(states => states.pesquisa.rowsPerPage);
  yield definirPaginacaoPesquisaCliente(page, rowsPerPageStored);
  if (allClientes.length === 0) {
    yield emptyPesquisaClienteRequestResult();
  } else {
    yield definirExibicaoPesquisaCliente(allClientes, page, rowsPerPageStored, repesquisarMesmoCliente, manterPrestacao);
  }
}

function* successBuscarDetalhesClienteSelecionado(resumoToTransform, detalhesToTransform) {
  yield put(setDetalhesDoClieteSelecionadoAction(resumoToTransform, detalhesToTransform));
}

function* successPesquisarLancamentosNaoPrestados(data) {
  yield put(setSimpleValueNaoPrestadosAction('lancamentosNaoPrestados', buildLancamentosNaoPrestados(data.content)));
  yield put(setSimpleValueNaoPrestadosAction('totalElements', data.totalElements));
  yield put(setSimpleValueNaoPrestadosAction('page', data.pageable.pageNumber));
  yield put(setSimpleValueNaoPrestadosAction('rowsPerPage', data.pageable.pageSize));
}

function* successPesquisarListaAutorizacaoRepasse(data) {
  yield put(setSimpleValueAutorizacaoRepasseHandlerAction('autorizacoesRepasses', buildListaAutorizacaoRepasse(data.content)));
  yield put(setSimpleValueAutorizacaoRepasseHandlerAction('totalElements', data.totalElements));
  yield put(setSimpleValueAutorizacaoRepasseHandlerAction('page', data.pageable.pageNumber));
  yield put(setSimpleValueAutorizacaoRepasseHandlerAction('rowsPerPage', data.pageable.pageSize));
}

function* successPesquisarDadosGeracaoNF(data) {
  yield put(setSimpleValueDadosGeracaoNFAction('dadosGeracaoNFData', buildListaDadosGeracaoNF(data.content)));
  yield put(setSimpleValueDadosGeracaoNFAction('totalElements', data.totalElements));
  yield put(setSimpleValueDadosGeracaoNFAction('page', data.pageable.pageNumber));
  yield put(setSimpleValueDadosGeracaoNFAction('rowsPerPage', data.pageable.pageSize));
}

function* successPesquisarDadosBoletosRelacaoPrestacao(data) {
  yield put(setSimpleValueBoletosRelacaoPrestacaoAction('dadosBoletos', buildListaDadosBoletos(data.content)));
  yield put(setSimpleValueBoletosRelacaoPrestacaoAction('totalElements', data.totalElements));
  yield put(setSimpleValueBoletosRelacaoPrestacaoAction('page', data.pageable.pageNumber));
  yield put(setSimpleValueBoletosRelacaoPrestacaoAction('rowsPerPage', data.pageable.pageSize));
}

function* successObterInformacoesAdicionaisRelacaoPrestacao(data) {
  yield put(setSimpleValueInformacoesAdicionaisRelacaoPrestacaoAction('autorizarEmissaoBoleto', data.autorizarEmissaoBoleto));
  yield put(setSimpleValueInformacoesAdicionaisRelacaoPrestacaoAction('autorizarEmissaoNF', data.autorizarEmissaoNF));
  yield put(setSimpleValueInformacoesAdicionaisRelacaoPrestacaoAction('emailsPermitemBoleto', data.emailsPermitemBoleto));
  yield put(setSimpleValueInformacoesAdicionaisRelacaoPrestacaoAction('prestacaoComNotaFiscal', data.prestacaoComNotaFiscal));
  yield put(setSimpleValueInformacoesAdicionaisRelacaoPrestacaoAction('requerInformacaoExtraNF', data.requerInformacaoExtraNF));
  yield put(setSimpleValueInformacoesAdicionaisRelacaoPrestacaoAction('detalhesInfoExtraNF', data.detalhesInfoExtraNF));
  yield put(setSimpleValueInformacoesAdicionaisRelacaoPrestacaoAction('idsPrestacoesAgrupadas', data.idsPrestacoesAgrupadas));
  yield put(setSimpleValueInformacoesAdicionaisRelacaoPrestacaoAction('prestacaoComNotaFiscalAgrupada', data.prestacaoComNotaFiscalAgrupada));
  yield put(setSimpleValueInformacoesAdicionaisRelacaoPrestacaoAction('prestacaoComBoletoAgrupado', data.prestacaoComBoletoAgrupado));
  yield put(setSimpleValueInformacoesAdicionaisRelacaoPrestacaoAction('idsBoletosAgrupados', data.idsBoletosAgrupados));
  yield put(setSimpleValueInformacoesAdicionaisRelacaoPrestacaoAction('dataVencimentoBoleto', data.dataVencimentoBoleto));
  yield put(setNovoBoletoAction('dataVencimentoBoleto', data.dataVencimentoBoleto));
}

function* successPesquisarTiposLancamentosNaoPrestados(data) {
  yield put(setSimpleValueNaoPrestadosAction('tiposLancamentos', data));
}

function* successPesquisarRecebimentos(data, dataMax, formFields) {
  yield put(setSimpleValueRecebimentosAction('tableMax', buildPesquisaRecebimento(dataMax.content)));
  yield put(setSimpleValueRecebimentosAction('tableView', buildPesquisaRecebimento(data.content)));
  yield put(setSimpleValueRecebimentosAction('totalElements', data.totalElements));
  yield put(setSimpleValueRecebimentosAction('page', data.pageable.pageNumber));
  yield put(setSimpleValueRecebimentosAction('rowsPerPage', data.pageable.pageSize));
  yield put(setSimpleValueRecebimentosAction('cacheFormFields', formFields));
}

function* successPesquisaRpsEmitidos(data, dataAll) {
  yield put(setSimpleValueRpsEmitidosAction('tableView', buildRpsEmitidos(data.content)));
  yield put(setSimpleValueRpsEmitidosAction('tableViewMax', buildRpsEmitidos(dataAll.content)));
  yield put(setSimpleValueRpsEmitidosAction('totalElements', data.totalElements));
  yield put(setSimpleValueRpsEmitidosAction('page', data.pageable.pageNumber));
  yield put(setSimpleValueRpsEmitidosAction('rowsPerPage', data.pageable.pageSize));
}

function* successPesquisarLancamentos(data) {
  yield put(setSimpleValueLancamentoHandlerAction('tableView', buildListaLancamento(data.content)));
  yield put(setSimpleValueLancamentoHandlerAction('totalElements', data.totalElements));
  yield put(setSimpleValueLancamentoHandlerAction('page', data.pageable.pageNumber));
  yield put(setSimpleValueLancamentoHandlerAction('rowsPerPage', data.pageable.pageSize));
}

function* successPesquisarNotasFiscais(data) {
  yield put(setSimpleValueNotasFiscaisHandlerAction('tableView', buildNotasFiscais(data.content)));
  yield put(setSimpleValueNotasFiscaisHandlerAction('totalElements', data.totalElements));
  yield put(setSimpleValueNotasFiscaisHandlerAction('page', data.pageable.pageNumber));
  yield put(setSimpleValueNotasFiscaisHandlerAction('rowsPerPage', data.pageable.pageSize));
}

function* successImportarRemessa(data) {
  yield put(setSimpleValueDadosGeracaoNFAction('rpsProcessados', buildImportarRemessa(data)));
}


function getPayloadNovaNota(formData, idPrestacaoContaCliente) {
  return {
    despesas: formData?.despesas?.value || '0.00',
    honorarios: formData?.honorarios?.value || '0.00',
    informacaoComplementar: formData.informacaoComplementar.value,
    informacaoExtra: formData.informacaoExtra.value,
    tipoNovaNFCliente: formData.tipoNovaNFCliente.value,
    idPrestacaoContaCliente,
  };
}

function getPayloadNovoBoleto(formData, idPrestacaoContaCliente) {
  return {
    valor: formData?.valor?.value || '0.00',
    idPrestacaoContaCliente,
    dataVencimentoBoleto: toAmericanDate(formData.dataVencimentoBoleto.value),
  };
}

export const pesquisarClientesOnClickAction = (formData, mostrarSoDesbloqueados) => ({
  type: PESQUISAR_CONTAS_CORRENTES_CLIENTE_ONCLICK_ACTION,
  formData,
  mostrarSoDesbloqueados,
});

export const pesquisarRecebimentosOnClickAction = (formData, somentePadrao, switchValue, atualizarListaIds) => ({
  type: PESQUISAR_RECEBIMENTOS_ONCLICK_ACTION,
  formData,
  somentePadrao,
  switchValue,
  atualizarListaIds,
});

export const emitirEpsOnClickAction = () => ({
  type: EMITIR_RPS_ONCLICK_ACTION,
});

export const buscarDetalhesDoClienteSelecionadoAction = () => ({
  type: BUSCAR_DETALHES_CLIENTE_SELECIONADO_ACTION,
});

export const prestarContasDoClienteSelecionadoAction = dataPrestacao => ({
  type: PRESTAR_CONTAS_CLIENTE_SELECIONADO_ACTION,
  dataPrestacao,
});

export const pesquisarContaCorrenteClientesAction = (fields, page) => ({
  type: PESQUISAR_CONTA_CORRENTE_CLIENTES_ACTION,
  fields,
  page,
});

export const atualizarPaginaAction = origin => ({
  type: ATUALIZAR_PAGINA_ACTION,
  origin,
});

export const pesquisarLancamentosNaoPrestadosClienteSelecionadoAction = (statusContaCorrenteCliente, page, rowsPerPage, columnOrdenacao) => ({
  type: PESQUISAR_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION,
  statusContaCorrenteCliente,
  page,
  rowsPerPage,
  columnOrdenacao,
});

export const deleteLancamentoNaoPrestadoClienteSelecionadoAction = idContaCorrenteCliente => ({
  type: DELETE_LANCAMENTO_NAO_PRESTADO_CLIENTE_SELECIONADO,
  idContaCorrenteCliente,
});

export const pesquisarPrestacaoInicialAction = () => ({
  type: PESQUISAR_PRESTACAO_INICIAL_ACTION,
});

export const cancelarPrestacaoAction = idPrestacao => ({
  type: CANCELAR_PRESTACAO_ACTION,
  idPrestacao,
});

export const estornarPrestacaoAction = (idPrestacao, motivoEstorno) => ({
  type: ESTORNAR_PRESTACAO_ACTION,
  idPrestacao,
  motivoEstorno,
});

export const buscarMotivoEstornoPrestacaoAction = idPrestacao => ({
  type: BUSCAR_MOTIVO_ESTORNO_PRESTACAO_ACTION,
  idPrestacao,
});

export const atualizarStatusLancamentoNaoPrestadoAction = (idContaCorrenteCliente, statusContaCorrenteCliente) => ({
  type: ATUALIZAR_STATUS_LANCAMENTO_NAO_PRESTADO_ACTION,
  idContaCorrenteCliente,
  statusContaCorrenteCliente,
});

export const downloadRelatorioClienteAction = () => ({
  type: DOWNLOAD_RELATORIO_CLIENTE,
});

export const prestarContasDeTodosOsLancamentosAction = dataPrestacao => ({
  type: PRESTAR_TODOS_ACTION,
  dataPrestacao,
});

export const downloadLancamentosNaoPrestadosAction = statusContaCorrenteCliente => ({
  type: DOWNLOAD_RELATORIO_LANCAMENTOS_NAO_PRESTADOS,
  statusContaCorrenteCliente,
});

export const findEditarLancamentoAction = idContaCorrenteNaoPrestado => ({
  type: FIND_EDITAR_LANCAMENTO_ACTION,
  idContaCorrenteNaoPrestado,
});

export const salvarLancamentoNaoPrestadoAction = () => ({
  type: SAVE_LANCAMENTO_NAO_PRESTADO_ACTION,
});

export const editarLancamentoNaoPrestadoAction = () => ({
  type: EDIT_LANCAMENTO_NAO_PRESTADO_ACTION,
});

export const buscarRelacoesDoClienteAction = formFields => ({
  type: BUSCAR_RELACOES_DO_CLIENTE_ACTION,
  formFields,
});

export const buscarSaldosRelacaoAction = idRelacaoSelecionada => ({
  type: BUSCAR_SALDOS_RELACAO_PRESTACAO_ACTION,
  idRelacaoSelecionada,
});

export const printRelacaoPrestacaoAction = idPrestacaoContaCliente => ({
  type: PRINT_RELACAO_PRESTACAO_ACTION,
  idPrestacaoContaCliente,
});

export const enviarEmailPrestacaoAction = idPrestacaoContaCliente => ({
  type: ENVIAR_EMAIL_PRESTACAO_ACTION,
  idPrestacaoContaCliente,
});

export const findPagamentosAction = () => ({
  type: FIND_PAGAMENTOS_ACTION,
});

export const deleteLancamentoPagamentoAction = () => ({
  type: DELETE_LANCAMENTO_PAGAMENTO_ACTION,
});

export const inserirLancamentoPagamentoAction = isPagamento => ({
  type: INSERIR_LANCAMENTO_PAGAMENTO_ACTION,
  isPagamento,
});

export const downloadRelatorioPagamentosAction = () => ({
  type: DOWNLOAD_RELATORIO_PAGAMENTOS,
});

export const pesquisarRepasseAction = () => ({
  type: PESQUISAR_REPASSE_ACTION,
});

export const pesquisarTotalRepasseAction = () => ({
  type: PESQUISAR_TOTAL_REPASSE_ACTION,
});

export const pesquisarListaAutorizacaoRepasseAction = () => ({
  type: PESQUISAR_LISTA_AUTORIZACAO_REPASSE_ACTION,
});

export const atualizarLidoAutorizacaoRepasseHandlerAction = idAutorizacaoRepasse => ({
  type: ATUALIZAR_STATUS_LIDO_AUTORIZACAO_REPASSE_ACTION,
  idAutorizacaoRepasse,
});

export const downloadArquivoAutorizacaoRepasseHandlerAction = (idAutorizacaoRepasse, tipoArquivo) => ({
  type: DOWNLOAD_ARQUIVO_AUTORIZACAO_REPASSE_ACTION,
  idAutorizacaoRepasse,
  tipoArquivo,
});

export const downloadArquivoRemessaHandlerAction = idNotaFiscalClienteRemessa => ({
  type: DOWNLOAD_ARQUIVO_REMESSA_ACTION,
  idNotaFiscalClienteRemessa,
});

export const liberarPrestacoesAction = () => ({
  type: LIBERAR_REPASSES_ACTION,
});

export const definirValorRepassesSelecionadosAction = () => ({
  type: DEFINIR_VALOR_SELECIONADO_REPASSE_ACTION,
});

export const printRepassesAutorizacaoRepassePdfAction = idAutorizacaoRepasse => ({
  type: PRINT_ARQUIVO_REPASSES_AUTORIZACAO_REPASSE_PDF_ACTION,
  idAutorizacaoRepasse,
});

export const pesquisarTiposLancamentosNaoPrestadosClienteSelecionadoAction = (statusContaCorrenteCliente, idCliente) => ({
  type: PESQUISAR_TIPOS_LANCAMENTOS_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION,
  statusContaCorrenteCliente,
  idCliente,
});

export const atualizaStateTiposLancamentosNaoPrestadosClienteSelecionadoAction = tiposLancamentos => ({
  type: ATUALIZA_STATE_TIPOS_LANCAMENTOS_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION,
  tiposLancamentos,
});

export const mudaStatusLancamentosNaoPrestadosClienteSelecionadoAction = (idCliente, bloquear, tipos) => ({
  type: MUDA_STATUS_LANCAMENTOS_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION,
  idCliente,
  bloquear,
  tipos,
});

export const downloadRelatorioRepassesAction = () => ({
  type: DOWNLOAD_RELATORIO_REPASSES_ACTION,
});

export const definirValorRecebimentoSelecionadosAction = () => ({
  type: DEFINIR_VALOR_RECEBIMENTO_ACTION,
});

export const habilitarEmitirEhAgruparRpsAction = () => ({
  type: HABILITAR_EMITIR_EH_AGRUPAR_RPS_ACTION,
});

export const gerarPDFRecebimentosPesquisadosAction = () => ({
  type: GERAR_PDF_RECEBIMENTOS_PESQUISADOS_ACTION,
});

export const agruparRPSOnClickAction = () => ({
  type: AGRUPAR_RPS_ON_CLICK_ACTION,
});

export const pesquisarRpsEmitidosAction = atualizarListaIds => ({
  type: PESQUISAR_RPS_EMITIDOS_ACTION,
  atualizarListaIds,
});

export const salvarAlteracoesDasInformacoesClienteAction = isInformacaoExtra => ({
  type: SALVAR_ALTERACOES_INFORMACOES_CLIENTE_ACTION,
  isInformacaoExtra,
});

export const cancelarRpsEmitidoAction = () => ({
  type: CANCELAR_RPS_ACTION,
});

export const pesquisarListaLancamentosAction = idPrestacaoContaCliente => ({
  type: PESQUISAR_LISTA_LANCAMENTOS_ACTION,
  idPrestacaoContaCliente,
});
export const pesquisarDadosGeracaoNotaFiscalAction = () => ({
  type: PESQUISAR_DADOS_GERACAO_NOTA_FISCAL_ACTION,
});

export const atualizarStatusLidoDadosGeracaoNotaFiscalAction = idNFClienteRemessa => ({
  type: ATUALIZAR_STATUS_LIDO_DADOS_GERACAO_NF_ACTION,
  idNFClienteRemessa,
});

export const visualizarPDFRemessaOnClickAction = idNFClienteRemessa => ({
  type: VISUALIZAR_PDF_REMESSA_ON_CLICK_ACTION,
  idNFClienteRemessa,
});

export const gerarNotaFiscalAction = () => ({
  type: GERAR_NOTA_FISCAL_ACTION,
});

export const pesquisarDadosBoletosRelacaoPrestacaoAction = () => ({
  type: PESQUISAR_DADOS_BOLETOS_RELACAO_PRESTACAO_ACTION,
});

export const cancelarBoletoRelacaoPrestacaoAction = idBoletoCliente => ({
  type: CANCELAR_BOLETO_RELACAO_PRESTACAO_ACTION,
  idBoletoCliente,
});

export const buscarInformacoesRelacaoPrestacaoAction = () => ({
  type: BUSCAR_INFORMACOES_RELACAO_PRESTACAO_ACTION,
});

export const importarArquivoRetornoAction = arquivoRemessa => ({
  type: IMPORTAR_ARQUIVO_RETORNO_ACTION,
  arquivoRemessa,
});

export const pesquisarNotasFiscaisAction = () => ({
  type: PESQUISAR_NOTAS_FISCAIS_ACTION,
});

export const cancelarNotasFiscaisAction = idNotaFiscalCliente => ({
  type: CANCELAR_NOTAS_FISCAIS_ACTION,
  idNotaFiscalCliente,
});

export const inserirNovaNotaFiscalAction = () => ({
  type: INSERIR_NOVA_NOTA_FISCAL,
});

export const visualizarNotaFiscalClienteAction = urlPrefeituraNF => ({
  type: VISUALIZAR_NOTA_FISCAL_CLIENTE_ACTION,
  urlPrefeituraNF,
});

export const downloadProtocoloCancelamentoNFAction = idNotaFiscalCliente => ({
  type: DOWNLOAD_PROTOCOLO_CANCELAMENTO_NF_ACTION,
  idNotaFiscalCliente,
});

export const inserirNovoBoletoAction = () => ({
  type: INSERIR_NOVO_BOLETO,
});

export const registrarBoletoRelacaoPrestacaoAction = idBoletoCliente => ({
  type: REGISTRAR_BOLETO_RELACAO_PRESTACAO_ACTION,
  idBoletoCliente,
});

export const enviarEmailBoletoAction = idBoletoCliente => ({
  type: ENVIAR_EMAIL_BOLETO_ACTION,
  idBoletoCliente,
});

export const imprimirBoletoRelacaoPrestacaoAction = idBoletoCliente => ({
  type: IMPRIMIR_BOLETO_RELACAO_PRESTACAO_ACTION,
  idBoletoCliente,
});

export const getPdfAction = id => ({
  type: GET_PDF_ACTION,
  id,
});

export const downloadPdfAction = (id, nome) => ({
  type: DOWNLAOD_PDF_ACTION,
  id,
  nome,
});

function* downloadPdfHandler(actions) {
  const response = yield ContaCorrenteClienteService.downloadRepassesAutorizacaoRepasse(actions.id);
  if (response.data != null) {
    yield downloadFile(actions.nome, response.data);
  }
}

function* getPdfHandler(actions) {
  const response = yield ContaCorrenteClienteService.downloadRepassesAutorizacaoRepasse(actions.id);
  yield put(setSimpleValuePdfAction('pdf', response));
}

function* pesquisarHandler(payload) {
  return yield call(ContaCorrenteClienteService.pesquisarContaCorrenteTodosClienteV2, payload);
}

// Quando clicar no botão de pesquisar...
function* pesquisarClientesOnClickHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  yield put(setSimpleValuesPesquisaAction('tableView', []));
  try {
    const payload = yield buildPayloadFromFormData(actions.formData, actions.mostrarSoDesbloqueados);

    const response = yield pesquisarHandler(payload);
    yield successPesquisaContasCorrentes(response.data, payload, 0, false);
  } catch (e) {
    // Nenhum tratamento definido...
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* pesquisarLancamentosNaoPrestadosClienteSelecionadoHandler(actions) {
  yield put(setCommonAction('loadingPesquisarLancamentosNaoPrestados', true));
  const idClienteSelecionado = yield select(states => states.common.clienteSelecionado.idCliente);
  const {
    statusContaCorrenteCliente, page, rowsPerPage, columnOrdenacao,
  } = actions;
  try {
    const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];

    const response = yield call(
      LancamentosNaoPrestadosService.findAllLancamentosNaoPrestados,
      idClienteSelecionado, statusContaCorrenteCliente, { page, size: rowsPerPage }, order,
    );
    yield successPesquisarLancamentosNaoPrestados(response.data);
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema ao tentar consultas os lançamentos');
  } finally {
    yield put(setCommonAction('loadingPesquisarLancamentosNaoPrestados', false));
  }
}

function* buscarDetalhesDoClienteSelecionadoHandler() {
  yield put(setCommonAction('loadingPage', true));
  const idClienteSelecionado = yield select(states => states.common.clienteSelecionado.idCliente);
  try {
    if (idClienteSelecionado) {
      const detalhesResponse = yield call(ContaCorrenteClienteService.getDetalhesCliente, idClienteSelecionado);
      const resumoClienteResponse = yield call(ContaCorrenteClienteService.getDetalhesFinanceiroCliente, idClienteSelecionado);

      yield successBuscarDetalhesClienteSelecionado(resumoClienteResponse.data, detalhesResponse.data);
    } else {
      // Quando não tem ninguem selecionado, limpar detalhes...
      yield put(setDetalhesDoClieteSelecionadoAction(null, null));
    }
  } catch (e) {
    // Nenhum tratamento definido...
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* atualizarInterface(repesquisarMesmoCliente, manterPrestacao) {
  const cacheFormFields = yield select(states => states.pesquisa.cacheFormFields);
  const pagePesquisaConta = yield select(states => states.pesquisa.page);
  // @TODO: Descomentar para usar nas implementações de Editar e Novo para quando repesquisar...
  // const page = yield select(states => states.naoPrestados.page);
  // const rowsPerPage = yield select(states => states.naoPrestados.rowsPerPage);
  // const columnOrdenacao = yield select(states => states.naoPrestados.columnOrdenacao);
  // const statusContaCorrenteCliente = yield select(states => states.naoPrestados.statusContaCorrenteCliente);

  const payload = yield buildPesquisaContaCorrenteClientePayload(cacheFormFields);
  try {
    const response = yield pesquisarHandler(payload);
    yield successPesquisaContasCorrentes(response.data, payload, pagePesquisaConta, repesquisarMesmoCliente, manterPrestacao);
  } catch (e) {

    // Nada a tratar...
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* atualizarPaginaHandler(actions) {
  let { origin } = actions;
  origin = origin === undefined ? actions : origin;
  switch (origin) {
    case DETALHES_ORIGIN: return yield atualizarInterface(false, false);
    case NAO_PRESTADOS_ORIGIN: return yield atualizarInterface(true, false);
    case RELACAO_PRESTACAO_ORIGIN: return yield atualizarInterface(true, false);
    case PAGAMENTOS: return yield atualizarInterface(true, true);
    default: return null;
  }
}

function* prestarContasClienteSelecionadoHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const idClienteSelecionado = yield select(states => states.common.clienteSelecionado.idCliente);
  try {
    const payloadPrestarContas = { idCliente: idClienteSelecionado, dataPrestacao: toAmericanDate(actions.dataPrestacao) };

    const { status } = yield call(ContaCorrenteClienteService.prestarContasCliente, payloadPrestarContas);
    if (status === 204) {
      toast.success(PRESTACAO_SUCCESS);
    }
  } catch (e) {
    toastUnmappedException(e, e.response?.data?.message);
  } finally {
    yield atualizarPaginaHandler(DETALHES_ORIGIN);
    yield put(setCommonAction('loadingPage', false));
  }
}

function* deleteLancamentoNaoPrestadoClienteSelecionadoHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { idContaCorrenteCliente } = actions;
  try {
    yield put(setSimpleValueNaoPrestadosAction('openDialogDeleteLancamento', false));
    yield call(LancamentosNaoPrestadosService.deleteLancamento, idContaCorrenteCliente);
    yield put(setSimpleValueNaoPrestadosAction('idContaCorrenteClienteTable', null));
    yield atualizarPaginaHandler(NAO_PRESTADOS_ORIGIN);
  } catch (e) {
    toastUnmappedException(e, `Ocorreu um problema ao tentar excluir lançamento ${idContaCorrenteCliente}`);
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* emptyPesquisaRelacaoPrestacao(showToast = true, formFields) {
  if (showToast) {
    toast.warning(RESULT_NOT_FOUND);
  }
  yield put(setSimpleValueRelacaoPrestacaoAction('relacoesPrestacoes', []));
  yield put(setCommonAction('idRelacaoSelecionada', null));
  yield put(setSimpleValueRelacaoPrestacaoAction('saldos', buildSaldosRelacaoPrestacao(null)));
  yield put(setSimpleValueRelacaoPrestacaoAction('totalElements', 0));
  yield put(setSimpleValueRelacaoPrestacaoAction('cacheFormFields', formFields));
}

function* successPesquisaRelacaoPrestacao(data, saldos, idPrestacao, idPrestacaoContaCliente, formFields) {
  yield put(setSimpleValueRelacaoPrestacaoAction('relacoesPrestacoes', data.content));
  yield put(setSimpleValueRelacaoPrestacaoAction('totalElements', data.totalElements));
  yield put(setCommonAction('idRelacaoSelecionada', null));
  yield put(setCommonAction('idPrestacaoContaClienteSelecionado', null));
  yield put(setCommonAction('idRelacaoSelecionada', idPrestacao));
  yield put(setCommonAction('idPrestacaoContaClienteSelecionado', idPrestacaoContaCliente));
  yield put(setSimpleValueRelacaoPrestacaoAction('saldos', buildSaldosRelacaoPrestacao(saldos)));
  yield put(setSimpleValueRelacaoPrestacaoAction('cacheFormFields', formFields));
}

function* pesquisarSaldosPrestacaoHandler(idPrestacao) {
  const idClienteSelecionado = yield select(states => states.common.clienteSelecionado.idCliente);
  return yield call(ContaCorrenteClienteService.findSaldosPrestacao, idClienteSelecionado, idPrestacao);
}

function* pesquisarRelacaoPrestacao(idClienteSelecionado, fields, page) {
  const rowsPerPage = yield select(states => states.relacaoPrestacao.rowsPerPage);
  const columnOrdenacao = yield select(states => states.relacaoPrestacao.columnOrdenacao);
  const payload = yield buildRelacaoPrestacaoPayload(idClienteSelecionado, fields);
  const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];

  return yield call(ContaCorrenteClienteService.buscarRelacaoPrestacao, payload, page, rowsPerPage, order);
}

function* pesquisarPrestacaoInicialHandler() {
  yield put(setCommonAction('loadingPage', true));
  const idClienteSelecionado = yield select(states => states.common.clienteSelecionado.idCliente);
  const manterPrestacao = yield select(states => states.common.manterPrestacao);
  const defaultFields = yield select(states => states.relacaoPrestacao.defaultFields);
  const idRelacaoSelecionada = yield select(states => states.common.idRelacaoSelecionada);
  const idPrestacaoContaClienteSelecionada = yield select(states => (states.common.idPrestacaoContaClienteSelecionado));

  yield put(setSimpleValueRelacaoPrestacaoAction('formFields', defaultFields));
  try {
    const { data } = yield pesquisarRelacaoPrestacao(idClienteSelecionado, defaultFields, 0);
    if (data.content.length > 0) {
      let idPrestacao;
      let idPrestacaoContaCliente;
      if (manterPrestacao === true) {
        idPrestacao = idRelacaoSelecionada;
        idPrestacaoContaCliente = idPrestacaoContaClienteSelecionada;
      } else {
        idPrestacao = data.content[0]?.idPrestacao;
        idPrestacaoContaCliente = data.content[0]?.idPrestacaoContaCliente;
      }
      const saldosResponse = yield pesquisarSaldosPrestacaoHandler(idPrestacao);
      yield successPesquisaRelacaoPrestacao(data, saldosResponse.data, idPrestacao, idPrestacaoContaCliente);
      yield put(setCommonAction('manterPrestacao', false));
    } else {
      yield emptyPesquisaRelacaoPrestacao(false);
    }
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema ao pesquisar as Prestações');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* cancelarPrestacaoHandler(actions) {
  const { idPrestacao } = actions;
  yield put(setCommonAction('loadingPage', true));
  const idClienteSelecionado = yield select(states => states.common.clienteSelecionado.idCliente);

  try {
    yield put(setSimpleValueRelacaoPrestacaoAction('openDialogCancelPrestacao', false));
    yield call(ContaCorrenteClienteService.cancelarPrestacao, idClienteSelecionado, idPrestacao);
    yield atualizarPaginaHandler(RELACAO_PRESTACAO_ORIGIN); // @TODO se atentar pq ele re-pesquisa pelos defaults e nao leva em conta oq o usuario filtrou...
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema ao tentar consolidar lançamentos');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* estornarPrestacaoHandler(actions) {
  const { idPrestacao, motivoEstorno } = actions;
  yield put(setCommonAction('loadingPage', true));
  const idClienteSelecionado = yield select(states => states.common.clienteSelecionado.idCliente);

  try {
    yield put(setSimpleValueRelacaoPrestacaoAction('openDialogEstornoPrestacao', false));
    const response = yield call(ContaCorrenteClienteService.estornarPrestacao, idClienteSelecionado, idPrestacao, motivoEstorno);
    yield put(setSimpleValueNaoPrestadosAction('formToSave', buildFormToSave(response.data)));
    yield put(setSimpleValueNaoPrestadosAction('openEdit', true));
    yield atualizarPaginaHandler(RELACAO_PRESTACAO_ORIGIN); // @TODO se atentar pq ele re-pesquisa pelos defaults e nao leva em conta oq o usuario filtrou...
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema ao tentar consolidar lançamentos');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* buscarMotivoEstornoPrestacaoHandler(actions) {
  const { idPrestacao } = actions;
  yield put(setCommonAction('loadingPage', true));
  const idClienteSelecionado = yield select(states => states.common.clienteSelecionado.idCliente);

  try {
    const response = yield call(ContaCorrenteClienteService.buscarMotivoEstornoPrestacao, idClienteSelecionado, idPrestacao);
    yield put(setSimpleValueRelacaoPrestacaoAction('motivoEstornoPrestacao', response.data));
    yield put(setSimpleValueRelacaoPrestacaoAction('openDialogMotivoEstornoPrestacao', true));
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema ao tentar consolidar lançamentos');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* atualizarStatusLancamentoNaoPrestadoHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { idContaCorrenteCliente, statusContaCorrenteCliente } = actions;
  try {
    yield put(setSimpleValueNaoPrestadosAction('openDialogAtualizarStatusLancamento', false));
    yield call(LancamentosNaoPrestadosService.atualizarStatus, idContaCorrenteCliente, statusContaCorrenteCliente);
    yield put(setSimpleValueNaoPrestadosAction('idContaCorrenteClienteTable', null));
    yield put(setSimpleValueNaoPrestadosAction('statusContaCorrenteClienteTable', null));
    yield atualizarPaginaHandler(NAO_PRESTADOS_ORIGIN);
  } catch (e) {
    toastUnmappedException(e, `Ocorreu um problema ao tentar desbloquear o lançamento ${idContaCorrenteCliente}`);
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* prestarTodosHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { dataPrestacao } = actions;
  const allClientes = yield select(states => states.pesquisa.allClientes);
  const filterDesbloqueados = allClientes.filter(status => status.statusPrestacaoContas === 'DESBLOQUEADO');
  try {
    yield put(setSimpleValuesDetalhesAction('openDialogConfirmLancamento', false));
    const response = yield call(ContaCorrenteClienteService.prestarTodos, {
      dataPrestacao: toAmericanDate(dataPrestacao),
      idClientes: filterDesbloqueados.map(ids => ids.idCliente),
    });
    if (response.status === 204) {
      toast.success('Todas as Prestações foram realizadas com sucesso.');
    }
  } catch (e) {
    toastUnmappedException(e, e.response?.data?.message);
  } finally {
    yield atualizarPaginaHandler(DETALHES_ORIGIN);
    yield put(setCommonAction('loadingPage', false));
  }
}

function* downloadRelatorioClientes() {
  yield put(setCommonAction('loadingPage', true));
  const allClientes = yield select(states => states.pesquisa.allClientes);
  const cacheFormFields = yield select(states => states.pesquisa.cacheFormFields);
  const columnOrdenacao = yield select(states => states.pesquisa.columnOrdenacao);
  try {
    const response = yield call(ContaCorrenteClienteService.downloadRelatorioClientes,
      toAmericanDate(cacheFormFields.dataPrestacao),
      cacheFormFields.idFilial,
      cacheFormFields.idCliente,
      cacheFormFields.nossoNumeroBoleto,
      cacheFormFields.idUsuarioResponsavel,
      cacheFormFields.tipoLancamento,
      cacheFormFields.exibirApenasDesbloqueados,
      cacheFormFields.nomeCliente,
      ContaCorrenteClienteFactory.makeIdsContasCorrenteClientes(allClientes),
      columnOrdenacao);
    if (response.data.size > 0) {
      openPageFile(response.data, response.data.type);
    }
  } catch (e) {
    toastUnmappedException(
      e,
      'Ocorreu um problema ao tentar abrir o relatório',
    );
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* salvarLancamentoNaoPrestadoHandler() {
  yield put(setCommonAction('loadingPage', true));
  const requestsFields = yield select(state => state.naoPrestados.formToSave);
  try {
    const payload = buildPayload(requestsFields);
    yield call(LancamentosNaoPrestadosService.findCadastrarLancamento, payload);
    yield put(setNaoPrestadosFormToSaveAction(null));
    yield put(setSimpleValueNaoPrestadosAction('openDialogButtonNovo', false));
    toast.success('Lançamento inserido com sucesso!');
  } catch (e) {
    yield getExceptionHandler(e, requestsFields);
  } finally {
    yield atualizarPaginaHandler(NAO_PRESTADOS_ORIGIN);
    yield put(setCommonAction('loadingPage', false));
  }
}

function* editarLancamentoNaoPrestadoHandler() {
  yield put(setCommonAction('loadingPage', true));
  const requestsFields = yield select(state => state.naoPrestados.formToSave);
  try {
    const payload = buildPayload(requestsFields);
    yield call(LancamentosNaoPrestadosService.alterarLancamento, payload);
    yield put(setNaoPrestadosFormToSaveAction(null));
    yield put(setSimpleValueNaoPrestadosAction('openDialogButtonNovo', false));
    yield atualizarPaginaHandler(NAO_PRESTADOS_ORIGIN);
  } catch (e) {
    yield getExceptionHandler(e, requestsFields);
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* findEditarLancamento(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { idContaCorrenteNaoPrestado } = actions;
  try {
    const response = yield call(LancamentosNaoPrestadosService.findEditarLancamento, idContaCorrenteNaoPrestado);
    yield put(setNaoPrestadosFormToSaveAction(response.data));
    yield put(setSimpleValueNaoPrestadosAction('openDialogButtonNovo', true));
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema ao tentar consultar o Lançamento');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* downloadRelatorioLancamentosNaoPrestados(actions) {
  yield put(setCommonAction('loadingPage', true));
  const clienteSelecionado = yield select(states => states.common.clienteSelecionado);
  const detalhes = yield select(states => states.detalhes.detalhesCliente);
  const columnOrdenacao = yield select(states => states.naoPrestados.columnOrdenacao);
  const { statusContaCorrenteCliente } = actions;
  try {
    const response = yield call(LancamentosNaoPrestadosService.downloadLancamentoNaoPrestado,
      clienteSelecionado.idCliente,
      detalhes.nome,
      statusContaCorrenteCliente,
      columnOrdenacao);

    if (response.data.size > 0) {
      openPageFile(response.data, response.data.type);
    }
  } catch (e) {
    toastUnmappedException(
      e,
      'Ocorreu um problema ao tentar abrir o relatório',
    );
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}


function* buscarRelacoesDoClienteHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const idClienteSelecionado = yield select(states => states.common.clienteSelecionado.idCliente);
  const { formFields } = actions;
  const page = yield select(states => states.relacaoPrestacao.page);
  const idPrestacaoContaClienteSelecionada = yield select(states => (states.common.idPrestacaoContaClienteSelecionado));

  try {
    if (formFields !== null) {
      const { data } = yield pesquisarRelacaoPrestacao(idClienteSelecionado, formFields, page);
      let idPrestacaoContaCliente = idPrestacaoContaClienteSelecionada;
      if (data.content.length > 0) {
        const idPrestacao = data.content[0]?.idPrestacao;
        idPrestacaoContaCliente = data.content[0]?.idPrestacaoContaCliente;
        const saldosResponse = yield pesquisarSaldosPrestacaoHandler(idPrestacao);

        yield successPesquisaRelacaoPrestacao(data, saldosResponse.data, idPrestacao, idPrestacaoContaCliente, formFields);
      } else {
        yield emptyPesquisaRelacaoPrestacao(true, formFields);
      }
    }
  } catch (e) {
    if (e.response.data.validations) {
      const periodoInicial = yield select(states => states.relacaoPrestacao.formFields.periodoInicial);
      const periodoFinal = yield select(states => states.relacaoPrestacao.formFields.periodoFinal);

      const periodoInicialAlterado = new FieldModel({
        ...periodoInicial,
        error: true,
        errorMessage: 'Data ou Período inválido.',
      });

      const periodoFinalAlterado = new FieldModel({
        ...periodoFinal,
        error: true,
        errorMessage: 'Data ou Período inválido.',
      });

      yield put(onChangeFormFieldsRelacaoPrestacaoErrorAction('periodoInicial', periodoInicialAlterado));
      yield put(onChangeFormFieldsRelacaoPrestacaoErrorAction('periodoFinal', periodoFinalAlterado));
    }
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* buscarSaldosRelacaoPrestacaoHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { idRelacaoSelecionada } = actions;
  try {
    const saldosResponse = yield pesquisarSaldosPrestacaoHandler(idRelacaoSelecionada);
    yield put(setSimpleValueRelacaoPrestacaoAction('saldos', buildSaldosRelacaoPrestacao(saldosResponse.data)));
  } catch (e) {
    yield put(setSimpleValueRelacaoPrestacaoAction('saldos', buildSaldosRelacaoPrestacao(null)));
    toastUnmappedException(e, 'Ocorreu um problema ao tentar consultar os saldos do Resumo da Prestação');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* printRelacaoPrestacaoHandler(actions) {
  const { idPrestacaoContaCliente } = actions;
  yield put(setCommonAction('loadingPage', true));
  try {
    const response = yield call(ContaCorrenteClienteService.downloadRelatorioPrestacao, idPrestacaoContaCliente);
    if (response.data.size > 0) {
      openPageFile(response.data, response.data.type);
    }
  } catch (e) {
    toastUnmappedException(
      e,
      'Ocorreu um problema ao tentar abrir o relatório',
    );
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* enviarEmailPrestacaoHandler(actions) {
  const { idPrestacaoContaCliente } = actions;
  yield put(setCommonAction('loadingPage', true));
  try {
    yield call(ContaCorrenteClienteService.enviarEmailPrestacao, idPrestacaoContaCliente);
    toast.success('E-mail enviado com sucesso!');
  } catch (e) {
    toastUnmappedException(e,
      'Erro durante o envio do E-mail');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* pesquisarPagamentosDePrestacaoEspecificaHandler() {
  yield put(setCommonAction('loadingPesquisarPagamentos', true));
  const idCliente = yield select(states => states.common.clienteSelecionado.idCliente);
  const idRelacaoSelecionada = yield select(states => states.common.idRelacaoSelecionada);
  const columnOrdenacao = yield select(states => states.pagamentos.columnOrdenacao);
  const page = yield select(states => states.pagamentos.page);
  const rowsPerPage = yield select(states => states.pagamentos.rowsPerPage);

  try {
    const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];

    const response = yield call(
      ContaCorrenteClientePagamentosService.findAllPagamentosPrestacaoEspecifica,
      idCliente, idRelacaoSelecionada, { page, size: rowsPerPage }, order,
    );

    const responseSaldos = yield call(
      ContaCorrenteClientePagamentosService.findSaldos,
      idCliente, idRelacaoSelecionada,
    );
    yield put(setSimpleValuePagamentosAction('pagamentos', buildPagamentosPrestacao(response.data.content)));
    yield put(setSimpleValuePagamentosAction('totalElements', response.data.totalElements));
    yield put(setSimpleValuePagamentosAction('saldos', buildSaldosPagamentos(responseSaldos.data)));
    yield put(setSimpleValuePagamentosAction('disabledButtons', isDisabledButtons(responseSaldos.data)));
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema ao tentar consultas os pagamentos');
  } finally {
    yield put(setCommonAction('loadingPesquisarPagamentos', false));
  }
}

function* deleteLancamentoPagamentoHandler() {
  yield put(setCommonAction('loadingPage', true));
  const idContaCorrenteCliente = yield select(states => states.pagamentos.idContaCorrenteClienteSelecionado);

  try {
    yield put(setSimpleValuePagamentosAction('openDialogExcluirLancamento', false));
    yield call(ContaCorrenteClientePagamentosService.deleteLancamentoPagamento, idContaCorrenteCliente);
    yield atualizarPaginaHandler(PAGAMENTOS);
  } catch (e) {
    toastUnmappedException(e, `Ocorreu um problema ao tentar excluir lançamento ${idContaCorrenteCliente}`);
  } finally {
    yield put(setCommonAction('loadingPage', false));
    yield put(setSimpleValueNaoPrestadosAction('idContaCorrenteClienteSelecionado', null));
  }
}

function* inserirLancamentoPagamentoHandler(actions) {
  const { isPagamento } = actions;
  yield put(setCommonAction('loadingPage', true));

  const idCliente = yield select(states => states.common.clienteSelecionado.idCliente);
  const idRelacaoSelecionada = yield select(states => states.common.idRelacaoSelecionada);
  const valor = yield select(states => states.pagamentos.formToSave.valor);
  const requestsFields = yield select(state => state.pagamentos.formToSave);

  try {
    yield call(ContaCorrenteClientePagamentosService.inserirLancamentoPagamento,
      idCliente,
      isPagamento,
      idRelacaoSelecionada,
      null,
      valor.value);

    yield put(setSimpleValuePagamentosValorAction('valor', 0));
    yield atualizarPaginaHandler(PAGAMENTOS);
  } catch (e) {
    yield getExceptionHandler(e, requestsFields);
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* downloadRelatorioPagamentos() {
  yield put(setCommonAction('loadingPage', true));

  const idCliente = yield select(states => states.common.clienteSelecionado.idCliente);
  const idRelatorio = yield select(states => states.common.idRelacaoSelecionada);
  const columnOrdenacao = yield select(states => states.pagamentos.columnOrdenacao);

  try {
    const response = yield call(ContaCorrenteClientePagamentosService.downloadRelatorioPagamentos,
      idCliente,
      idRelatorio,
      columnOrdenacao);
    if (response.data.size > 0) {
      openPageFile(response.data, response.data.type);
    }
  } catch (e) {
    toastUnmappedException(
      e,
      'Ocorreu um problema ao tentar abrir o relatório',
    );
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* pesquisarRepasseHandler() {
  const columnOrdenacao = yield select(states => states.repasse.repasse.columnOrdenacao);
  const page = yield select(states => states.repasse.repasse.page);
  const rowsPerPage = yield select(states => states.repasse.repasse.rowsPerPage);
  const formFields = yield select(states => states.repasse.repasse.formFields);
  const atualizarListaIds = yield select(states => states.repasse.repasse.atualizarListaIds);

  yield put(setCommonAction('loadingPesquisarRepasse', true));

  try {
    const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];
    const response = yield call(RepasseService.findBuscarRepasses, toAmericanDate(formFields.periodoInicial.value),
      toAmericanDate(formFields.periodoFinal.value), { page, size: rowsPerPage }, order);

    const responseSizeMaximo = yield call(RepasseService.findBuscarRepassesMaxSize, toAmericanDate(formFields.periodoInicial.value),
      toAmericanDate(formFields.periodoFinal.value), order);

    yield put(setSimpleValuesRepasseAction('tableView', buildListaRepasse(response.data.content)));
    yield put(setSimpleValuesRepasseAction('tableViewMax', buildListaRepasse(responseSizeMaximo.data.content)));

    if (atualizarListaIds) {
      const responseIds = yield call(RepasseService.findIdsReoasses, toAmericanDate(formFields.periodoInicial.value),
        toAmericanDate(formFields.periodoFinal.value));

      const ids = responseIds.data.reduce((acumulador, id) => ({ ...acumulador, [id]: false }), {});

      yield put(setSimpleValuesRepasseAction('listIds', ids));
    }
    yield put(setSimpleValuesRepasseAction('totalElements', response.data.totalElements));
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema ao tentar consultas os repasses');
    if (e.response.data.validations) {
      const periodoInicial = yield select(states => states.repasse.repasse.formFields.periodoInicial);
      const periodoFinal = yield select(states => states.repasse.repasse.formFields.periodoFinal);
      const periodoInicialAlterado = new FieldModel({
        ...periodoInicial,
        error: true,
        errorMessage: 'Data ou Período inválido.',
      });
      const periodoFinalAlterado = new FieldModel({
        ...periodoFinal,
        error: true,
        errorMessage: 'Data ou Período inválido.',
      });
      yield put(setFormFieldsRepasseAction('periodoInicial', periodoInicialAlterado));
      yield put(setFormFieldsRepasseAction('periodoFinal', periodoFinalAlterado));
    }
  } finally {
    yield put(setCommonAction('loadingPesquisarRepasse', false));
  }
}

function* pesquisarTotalRepasseHandler() {
  yield put(setCommonAction('loadingPesquisarTotalRepasse', true));
  const formFields = yield select(states => states.repasse.repasse.formFields);

  try {
    const response = yield call(RepasseService.findBuscarTotalRepasse, toAmericanDate(formFields.periodoInicial.value),
      toAmericanDate(formFields.periodoFinal.value));
    yield put(setSimpleValuesRepasseAction('totalRepasse', getMoneyMask(response.data || 0)));
  } catch (e) {
    toastUnmappedException(
      e,
      'Ocorreu um problema ao tentar consultar o Total de Repasses',
    );
  } finally {
    yield put(setCommonAction('loadingPesquisarTotalRepasse', false));
  }
}

function* pesquisarListaAutorizacaoRepasse() {
  const columnOrdenacao = yield select(states => states.autorizacaoRepasse.columnOrdenacao);
  const page = yield select(states => states.autorizacaoRepasse.page);
  const rowsPerPage = yield select(states => states.autorizacaoRepasse.rowsPerPage);
  const listarUltimosTrintaDias = yield select(states => states.autorizacaoRepasse.formFields.listarUltimosTrintaDias);
  const listarSomenteNaoConcluidos = yield select(states => states.autorizacaoRepasse.formFields.listarSomenteNaoConcluidos);

  yield put(setCommonAction('loadingPesquisarListaAutorizacaoRepasse', true));

  try {
    const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];

    const response = yield call(RepasseService.findBuscarAutorizacaoRepasse,
      listarUltimosTrintaDias.value,
      listarSomenteNaoConcluidos.value,
      { page, size: rowsPerPage },
      order);

    yield successPesquisarListaAutorizacaoRepasse(response.data);
  } catch (e) {
    toastUnmappedException(e,
      'Erro ao buscar as Autorizações de Repasses.');
  } finally {
    yield put(setCommonAction('loadingPesquisarListaAutorizacaoRepasse', false));
  }
}

function* atualizarLidoAutorizacaoRepasseHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { idAutorizacaoRepasse } = actions;
  try {
    yield call(RepasseService.alterarStatusDeLidoAutorizacaoDeRepasse, idAutorizacaoRepasse);
    yield pesquisarListaAutorizacaoRepasse();
  } catch (e) {
    toastUnmappedException(e,
      'Erro ao alterar status de lido.');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* downloadArquivoAutorizacaoRepasseHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { idAutorizacaoRepasse, tipoArquivo } = actions;
  try {
    const response = yield call(RepasseService.downloadArquivoAutorizacaoRepasse, idAutorizacaoRepasse, tipoArquivo);
    if (response.data != null) {
      yield downloadFileSispag(response.headers.filename, response.data);
    }
    yield pesquisarListaAutorizacaoRepasse();
  } catch (e) {
    toastUnmappedException(e,
      'Erro ao fazer download do repasse.');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* definirValorRepasseSelecionadoHandler() {
  const listIds = yield select(states => states.repasse.repasse.listIds);
  const tableViewMax = yield select(states => states.repasse.repasse.tableViewMax);

  yield put(setCommonAction('loadingDefinirValorRepasse', true));
  try {
    const listaDeIds = Object.keys(listIds).filter((key => listIds[key] === true)).map(key => Number(key));
    let valorFinalSelecionado = 0;

    listaDeIds.forEach((id) => {
      tableViewMax.forEach((objectLista) => {
        if (objectLista.idPrestacaoContaCliente === id) {
          valorFinalSelecionado += objectLista.valorRepasseWithoutMask;
        }
      });
    });

    yield put(setSimpleValuesRepasseAction('totalRepasseSelecionada', valorFinalSelecionado));
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema calcular valor selecionado.');
  } finally {
    yield put(setCommonAction('loadingDefinirValorRepasse', false));
  }
}

function* liberarRepassesHandler() {
  const listIds = yield select(states => states.repasse.repasse.listIds);
  yield put(setCommonAction('loadingPage', true));
  try {
    yield put(setSimpleValuesRepasseAction('openDialogLiberar', false));
    yield call(RepasseService.liberarRepasses,
      Object.keys(listIds).filter((key => listIds[key] === true)).map(key => Number(key)));

    yield pesquisarListaAutorizacaoRepasse();
    yield put(setSimpleValuesRepasseAction('atualizarListaIds', true));
    yield pesquisarRepasseHandler();
    yield pesquisarTotalRepasseHandler();
    yield definirValorRepasseSelecionadoHandler();
  } catch (e) {
    toastUnmappedException(e, e.message);
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* printRepassesAutorizacaoPrestacaoHandler(actions) {
  const { idAutorizacaoRepasse } = actions;
  yield put(setCommonAction('loadingPage', true));
  try {
    const response = yield call(ContaCorrenteClienteService.downloadRepassesAutorizacaoRepasse, idAutorizacaoRepasse);
    if (response.data.size > 0) {
      openPageFile(response.data, response.data.type);
    }
  } catch (e) {
    toastUnmappedException(
      e,
      'Ocorreu um problema ao tentar abrir o relatório',
    );
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* pesquisarTiposLancamentosNaoPrestadosClienteSelecionadoHandler(actions) {
  yield put(setCommonAction('loadingPesquisarTiposLancamentosNaoPrestados', true));
  const {
    statusContaCorrenteCliente, idCliente,
  } = actions;
  try {
    const response = yield call(
      LancamentosNaoPrestadosService.findAllTiposLancamentosNaoPrestados,
      idCliente, statusContaCorrenteCliente,
    );
    yield successPesquisarTiposLancamentosNaoPrestados(response.data);
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema ao tentar consultas os lançamentos');
  } finally {
    yield put(setCommonAction('loadingPesquisarTiposLancamentosNaoPrestados', false));
  }
}

function* atualizaStateTiposLancamentosNaoPrestadosClienteSelecionadoHandler(actions) {
  const {
    tiposLancamentos,
  } = actions;
  yield successPesquisarTiposLancamentosNaoPrestados(tiposLancamentos);
}

function* mudaStatusLancamentosNaoPrestadosClienteSelecionadoHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const {
    idCliente,
    bloquear,
    tipos,
  } = actions;
  try {
    const response = yield call(
      LancamentosNaoPrestadosService.mudaStatusLancamentosNaoPrestados,
      idCliente, bloquear, tipos,
    );
    yield successPesquisarTiposLancamentosNaoPrestados(response.data);
    yield atualizarPaginaHandler(NAO_PRESTADOS_ORIGIN);
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema ao tentar consultas os lançamentos');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* downloadRelatorioRepassesHandler() {
  yield put(setCommonAction('loadingPage', true));

  const columnOrdenacao = yield select(states => states.repasse.repasse.columnOrdenacao);
  const formFields = yield select(states => states.repasse.repasse.formFields);

  try {
    const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];
    const response = yield call(RepasseService.downloadRelatorioRepasses, toAmericanDate(formFields.periodoInicial.value),
      toAmericanDate(formFields.periodoFinal.value), order);
    if (response.data.size > 0) {
      openPageFile(response.data, response.data.type);
    }
  } catch (e) {
    toastUnmappedException(
      e,
      'Ocorreu um problema ao tentar abrir o relatório',
    );
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* pesquisarRecebimentosOnClickHandler(actions, emitirRps = false) {
  yield put(setCommonAction('loadingPesquisarRecebimentos', true));

  const rowsPerPage = yield select(states => (states.recebimentos.rowsPerPage));
  const page = yield select(states => (states.recebimentos.page));
  const columnOrdenacao = yield select(states => (states.recebimentos.columnOrdenacao));
  const { formFields } = actions;

  try {
    if (actions.formData !== null) {
      const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];

      let payload;

      if (emitirRps === true) {
        payload = buildPayloadRecebimentos(actions.formData, actions.somentePadrao, actions.switchValue);
      } else {
        payload = buildPayloadFromFormDataRecebimentos(actions.formData, actions.somentePadrao, actions.switchValue);
      }


      const response = yield call(RecebimentosService.findAllRecebimentos, payload, { page, size: rowsPerPage }, order);
      const responseMaximo = yield call(RecebimentosService.findAllRecebimentosMaximo, payload, order);

      if (actions.atualizarListaIds === true) {
        const responseIds = yield call(RecebimentosService.findIdsRecebimentos, payload);
        const ids = responseIds.data.reduce((acumulador, id) => ({ ...acumulador, [id]: false }), {});
        yield put(setSimpleValueRecebimentosAction('listIds', ids));
        yield dadosResumo(0, 0, 0, 0);
      }
      yield successPesquisarRecebimentos(response.data, responseMaximo.data, actions.formData);
    }
  } catch (e) {
    yield put(setSimpleValueRecebimentosAction('tableView', []));
    const [updateErrorFields] = yield getExceptionHandler(e, formFields);
    yield put(setRecebimentosFormFieldsErrorsAction(updateErrorFields));
    yield dadosResumo(0, 0, 0, 0);
  } finally {
    yield put(setCommonAction('loadingPesquisarRecebimentos', false));
  }
}

function* pesquisarRpsEmitidos(actions) {
  yield put(setCommonAction('loadingPesquisarRpsEmitidos', true));

  const rowsPerPage = yield select(states => (states.rpsEmitidos.rowsPerPage));
  const page = yield select(states => (states.rpsEmitidos.page));
  const columnOrdenacao = yield select(states => (states.rpsEmitidos.columnOrdenacao));
  const listIds = yield select(states => (states.rpsEmitidos.listIds));
  const storedListIds = new Map();


  try {
    const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];

    const response = yield call(RpsEmitidosService.findRpsEmitidos, { page, size: rowsPerPage }, order);
    const responseMaximo = yield call(RpsEmitidosService.findAllRpsEmitidos, order);

    if (actions.atualizarListaIds === true) {
      const responseIds = yield call(RpsEmitidosService.findIdsRpsEmitidos);
      Object.keys(listIds).forEach((key) => {
        storedListIds.set(parseInt(key, 10), listIds[key]);
      });
      const ids = responseIds.data.reduce((acumulador, id) => ({ ...acumulador, [id]: (storedListIds.has(id) ? storedListIds.get(id) : false) }), {});
      yield put(setSimpleValueRpsEmitidosAction('listIds', ids));
    }

    yield successPesquisaRpsEmitidos(response.data, responseMaximo.data);
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema ao pesquisar os Rps Emitidos');
  } finally {
    yield put(setCommonAction('loadingPesquisarRpsEmitidos', false));
  }
}

function* emitirRpsOnClickHandler() {
  const listIds = yield select(states => states.recebimentos.listIds);
  const listaDeIds = Object.keys(listIds).filter((key => listIds[key] === true)).map(key => Number(key));
  try {
    yield call(RecebimentosService.emitirRps, listaDeIds);
    toast.success('RPS emitidos com sucesso!');
  } catch (e) {
    toast.error('Falha na emissão de algum dos RPS. Tente novamente.', { style: { width: '392px' } });
  } finally {
    const formData = yield select(states => (states.recebimentos.formFields));
    const somentePadrao = yield select(states => states.recebimentos.formFields.somentePadrao.value);

    const recebimentosObject = {
      formData, somentePadrao, switchValue: true, atualizarListaIds: true,
    };
    yield pesquisarRecebimentosOnClickHandler(recebimentosObject, true);
    yield pesquisarRpsEmitidos({ atualizarListaIds: true });
  }
}

function* valorRecebimentoSelecionadoHandler() {
  const listIds = yield select(states => states.recebimentos.listIds);
  const tableMax = yield select(states => states.recebimentos.tableMax);

  yield put(setCommonAction('loadingPage', true));
  try {
    const listaDeIds = Object.keys(listIds).filter((key => listIds[key] === true)).map(key => Number(key));
    let valorHonorariosAmigaveisSelecionado = 0;
    let valorHonorariosJudiciaisSelecionado = 0;
    let valorDespesasSelecionada = 0;
    let quantidadeSelecionadaRecebimento = 0;

    listaDeIds.forEach((id) => {
      tableMax.forEach((objectLista) => {
        if (objectLista.idPrestacaoContaCliente === id) {
          quantidadeSelecionadaRecebimento += 1;
          valorHonorariosAmigaveisSelecionado += objectLista.honorariosAmigaveis ? Number.parseFloat(objectLista.honorariosAmigaveis) : 0;
          valorHonorariosJudiciaisSelecionado += objectLista.honorariosJudiciais ? Number.parseFloat(objectLista.honorariosJudiciais) : 0;
          valorDespesasSelecionada += objectLista.despesas ? Number.parseFloat(objectLista.despesas) : 0;
        }
      });
    });

    yield dadosResumo(quantidadeSelecionadaRecebimento,
      valorHonorariosAmigaveisSelecionado,
      valorHonorariosJudiciaisSelecionado,
      valorDespesasSelecionada);
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema ao calcular recebimento selecionado.');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* habilitarEmitirEhAgruparRps() {
  const listIds = yield select(states => states.recebimentos.listIds);
  const tableMax = yield select(states => states.recebimentos.tableMax);

  try {
    const listaDeIds = Object.keys(listIds).filter((key => listIds[key] === true)).map(key => Number(key));
    let idClienteCompare = null;
    let isClienteDiferente = false;
    let isTipoDeEmissaoPersonalizada = true;

    listaDeIds.forEach((id) => {
      tableMax.forEach((objectLista) => {
        if (objectLista.idPrestacaoContaCliente === id) {
          if (idClienteCompare !== null && isClienteDiferente === false) {
            isClienteDiferente = idClienteCompare !== objectLista.idCliente;
          }
          if (objectLista.tipoEmissao !== 'Personalizada') {
            isTipoDeEmissaoPersonalizada = false;
          }
          idClienteCompare = objectLista.idCliente;
        }
      });
    });

    yield put(setSimpleValueRecebimentosAction('habilitarEmitirRps', listaDeIds.length !== 0));
    yield put(setSimpleValueRecebimentosAction('habilitarAgruparRps', listaDeIds.length > 1 && !isClienteDiferente && isTipoDeEmissaoPersonalizada));
  } catch (e) {
    // Sem tratamento definido
  } finally {
    // Sem atualização
  }
}

function* gerarPDFRecebimentosPesquisadosHandler() {
  yield put(setCommonAction('loadingPage', true));
  const formData = yield select(states => states.recebimentos.formFields);
  const somentePadrao = yield select(states => states.recebimentos.formFields.somentePadrao.value);
  try {
    const ordenacao = yield select(states => states.recebimentos.columnOrdenacao);
    const payload = { ...getPayloadRecebimentos(formData, somentePadrao, true), ordenacao };
    const response = yield call(RecebimentosService.downloadRecebimentos, payload);
    if (response.data.size > 0) {
      openPageFile(response.data, response.data.type);
    }
  } catch (e) {
    toastUnmappedException(
      e,
      'Ocorreu um problema ao tentar abrir o relatório de recebimentos',
    );
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

// Quando ficar alterando o switch...
function* pesquisarContaCorrenteClientesHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  try {
    const { fields, page } = actions;
    const payload = yield buildPesquisaSwitchContaCorrenteClientePayload(fields.cacheFormFields, fields.exibirApenasDesbloqueados);
    const response = yield pesquisarHandler(payload);
    yield successPesquisaContasCorrentes(response.data, payload, page, false);
  } catch (e) {
    // Nenhum tratamento definido...
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* agruparRPSOnClickHandler() {
  const listIds = yield select(states => states.recebimentos.listIds);
  const filteredListIds = Object.keys(listIds).filter(key => listIds[key] === true);
  try {
    yield call(RecebimentosService.agruparRecebimentos, filteredListIds);
    toast.success('Prestações agrupadas e RPS emitidos com sucesso!');
  } catch (e) {
    toast.error('Falha na operação de agrupamento de RPS. Tente novamente.', { style: { width: '392px' } });
  } finally {
    const formData = yield select(states => (states.recebimentos.formFields));
    const somentePadrao = yield select(states => states.recebimentos.formFields.somentePadrao.value);

    const recebimentosObject = {
      formData, somentePadrao, switchValue: true, atualizarListaIds: true,
    };
    yield pesquisarRecebimentosOnClickHandler(recebimentosObject, true);
    yield pesquisarRpsEmitidos({ atualizarListaIds: true });
  }
}

function* resetDataAndFinallyAfterSaveInformationCliente(isInformacaoExtra) {
  yield pesquisarRpsEmitidos({ atualizarListaIds: true });
  yield put(setSimpleValueRpsEmitidosAction('idClienteSelecionado', false));
  yield put(setSimpleValueRpsEmitidosAction('idPrestacaoSelecionada', false));
  yield put(setSimpleValueRpsEmitidosAction('openDialogButton', false));
  yield put(setRpsEmitidosFormAction(null));
  yield put(setCommonAction('loadingPage', false));
  toast.success(isInformacaoExtra
    ? 'Informação Extra cadastrada com sucesso!'
    : 'Informação complementar cadastrada com sucesso!');
}

function* salvarAlteracoesDasInformacoesClienteHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const formFields = yield select(state => state.rpsEmitidos.formFields);
  const idClienteSelecionado = yield select(state => state.rpsEmitidos.idClienteSelecionado);
  const idPrestacaoSelecionada = yield select(state => state.rpsEmitidos.idPrestacaoSelecionada);
  try {
    yield call(RpsEmitidosService.salvarAlteracoesDasInformacoesClienteHandler, formFields, idPrestacaoSelecionada, idClienteSelecionado);
  } catch (e) {
    yield getExceptionHandler(e, formFields);
  } finally {
    yield resetDataAndFinallyAfterSaveInformationCliente(actions.isInformacaoExtra);
  }
}

function* cancelarRpsEmitidoHandler() {
  yield put(setCommonAction('loadingPage', true));
  const idClienteSelecionado = yield select(state => state.rpsEmitidos.idClienteSelecionado);
  const idPrestacaoSelecionada = yield select(state => state.rpsEmitidos.idPrestacaoSelecionada);
  try {
    yield call(RpsEmitidosService.cancelarRpsEmitido, idPrestacaoSelecionada, idClienteSelecionado);
  } catch (e) {
    // Sem tratamento Definido
  } finally {
    const formData = yield select(states => (states.recebimentos.formFields));
    const somentePadrao = yield select(states => states.recebimentos.formFields.somentePadrao.value);

    const recebimentosObject = {
      formData, somentePadrao, switchValue: true, atualizarListaIds: true,
    };
    yield pesquisarRecebimentosOnClickHandler(recebimentosObject);
    yield pesquisarRpsEmitidos({ atualizarListaIds: true });
    yield put(setCommonAction('loadingPage', false));
    yield put(setSimpleValueRpsEmitidosAction('openDialogCancelar', false));
  }
}

function* pesquisarListaLancamentosHandler(actions) {
  const { idPrestacaoContaCliente } = actions;
  const page = yield select(states => states.lancamentos.page);
  const rowsPerPage = yield select(states => states.lancamentos.rowsPerPage);

  yield put(setCommonAction('loadingPesquisarLancamentos', true));

  try {
    const response = yield call(findBuscarLancamento, idPrestacaoContaCliente, { page, size: rowsPerPage });
    yield successPesquisarLancamentos(response.data);
  } catch (e) {
  //
  } finally {
    yield put(setCommonAction('loadingPesquisarLancamentos', false));
  }
}
function* pesquisarDadosGeracaoNotaFiscalHandler() {
  const columnOrdenacao = yield select(states => states.dadosGeracaoNF.columnOrdenacao);
  const page = yield select(states => states.dadosGeracaoNF.page);
  const rowsPerPage = yield select(states => states.dadosGeracaoNF.rowsPerPage);
  const ultimos30Dias = yield select(states => states.dadosGeracaoNF.formFields.ultimos30Dias);
  const naoConcluidos = yield select(states => states.dadosGeracaoNF.formFields.naoConcluidos);

  yield put(setCommonAction('loadingPesquisarDadosGeracaoNF', true));

  try {
    const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];

    const response = yield call(RecebimentosService.findDadosGeracaoNF,
      ultimos30Dias.value,
      naoConcluidos.value,
      { page, size: rowsPerPage },
      order);
    yield successPesquisarDadosGeracaoNF(response.data);
  } catch (e) {
    toastUnmappedException(e,
      'Erro ao buscar as Autorizações de Repasses.');
  } finally {
    yield put(setCommonAction('loadingPesquisarDadosGeracaoNF', false));
  }
}

function* downloadArquivoRemessaHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { idNotaFiscalClienteRemessa } = actions;
  try {
    const response = yield call(RecebimentosService.downloadArquivoRemessa, idNotaFiscalClienteRemessa);
    if (response.data !== null) {
      yield downloadFileSispag(response.headers.filename, response.data);
    }
    yield pesquisarDadosGeracaoNotaFiscalHandler();
  } catch (e) {
    toastUnmappedException(e,
      'Erro ao fazer download da remessa.');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* atualizarStatusLidoDadosGeracaoNotaFiscalHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { idNFClienteRemessa } = actions;

  try {
    yield call(RecebimentosService.desmarcarLidodadosGeracaoNF, idNFClienteRemessa);
    yield pesquisarDadosGeracaoNotaFiscalHandler();
  } catch (e) {
    toastUnmappedException(e,
      'Erro ao alterar status de lido.');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* visualizarPDFRemessaOnClickHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { idNFClienteRemessa } = actions;
  try {
    const response = yield call(RecebimentosService.gerarPDFvisualizacaoRemessa, idNFClienteRemessa);
    if (response.data.size > 0) {
      openPageFile(response.data, response.data.type);
    }
  } catch (e) {
    toastUnmappedException(e,
      'Erro ao visualizar documento');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* pesquisarNotasFiscaisHandler() {
  const idCliente = yield select(states => states.common.clienteSelecionado.idCliente);
  const idPrestacao = yield select(states => states.common.idRelacaoSelecionada);
  const page = yield select(states => states.notasFiscais.page);
  const rowsPerPage = yield select(states => states.notasFiscais.rowsPerPage);

  yield put(setCommonAction('loadingPesquisarNotasFiscais', true));

  try {
    const response = yield call(findBuscarNotasFiscais, idCliente, idPrestacao, { page, size: rowsPerPage });
    yield successPesquisarNotasFiscais(response.data);
  } catch (e) {
  //
  } finally {
    yield put(setCommonAction('loadingPesquisarNotasFiscais', false));
  }
}

function* pesquisarDadosBoletosRelacaoPrestacaoHandler() {
  const columnOrdenacao = yield select(states => states.boletosRelacaoPrestacao.columnOrdenacao);
  const page = yield select(states => states.boletosRelacaoPrestacao.page);
  const rowsPerPage = yield select(states => states.boletosRelacaoPrestacao.rowsPerPage);
  const idPrestacaoConta = yield (select(states => states.common.idPrestacaoContaClienteSelecionado));

  yield put(setCommonAction('loadingDadosBoletoPrestacao', true));
  try {
    const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];
    const response = yield call(ContaCorrenteClienteBoletoService.findBoletosRelacaoPrestacao,
      idPrestacaoConta,
      { page, size: rowsPerPage },
      order);
    yield successPesquisarDadosBoletosRelacaoPrestacao(response.data);
  } catch (e) {
    toastUnmappedException(e,
      'Erro ao buscar boletos da relação de prestação');
  } finally {
    yield put(setCommonAction('loadingDadosBoletoPrestacao', false));
  }
}

function* buscarInformacoesRelacaoPrestacaoHandler() {
  const idPrestacaoConta = yield (select(states => states.common.idPrestacaoContaClienteSelecionado));
  yield put(setCommonAction('loadingPage', true));
  try {
    const response = yield call(RelacaoPrestacaoService.getInformacoesAdicionais, idPrestacaoConta);
    yield successObterInformacoesAdicionaisRelacaoPrestacao(response.data);
  } catch (e) {
    toastUnmappedException('Erro na obtenção de dados');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}


function* gerarNotaFiscalHandler() {
  const listIds = yield select(states => states.rpsEmitidos.listIds);
  const tableViewMax = yield select(states => states.rpsEmitidos.tableViewMax);

  yield put(setCommonAction('loadingPage', true));
  try {
    const listaDeIds = Object.keys(listIds).filter((key => listIds[key] === true)).map(key => Number(key));

    const listaPayload = [];
    listaDeIds.forEach((id) => {
      tableViewMax.forEach((objectLista) => {
        if (objectLista.idNotaFiscalCliente === id) {
          const objetoPayload = {
            idCliente: objectLista.idCliente,
            idPrestacao: objectLista.idPrestacao,
          };
          listaPayload.push(objetoPayload);
        }
      });
    });

    const response = yield call(RpsEmitidosService.gerarNotaFiscal, listaPayload);
    yield pesquisarRpsEmitidos({ atualizarListaIds: true });
    yield pesquisarDadosGeracaoNotaFiscalHandler();
    yield pesquisarNotasFiscaisHandler();
    if (response.data.erroBoleto === true) {
      toast.warning('Erro ao registrar algum dos boletos. Uma nova tentativa de registro poderá ser feita na opção "Ferramentas > Registrar Boletos" no menu principal.');
    } else {
      toast.success('Solicitação de emissão das Notas Fiscais e Boletos realizada com sucesso!');
    }
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema ao Gerar Nota Fiscal.');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* importarArquivoRetornoHandler(actions) {
  const prefeitura = yield select(states => states.dadosGeracaoNF.formFields.grupoPrefeituraNotaFiscal);
  const grupoPrefeituraNotaFiscal = prefeitura.value;

  const { arquivoRemessa } = actions;

  yield put(setCommonAction('loadingPage', true));
  try {
    const response = yield call(RecebimentosService.importarRemessa, arquivoRemessa, grupoPrefeituraNotaFiscal);
    if (response.data.erroArquivo === true) {
      yield put(setSimpleValueDadosGeracaoNFAction('openDialogErro', true));
    } else {
      yield successImportarRemessa(response.data);
      yield put(setSimpleValueDadosGeracaoNFAction('openDialogSucesso', true));
    }
  } catch (e) {
    toast.error('Ocorreu um erro ao processar o arquivo. Tente novamente.', { style: { width: '392px' } });
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* cancelarNotasFiscaisHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const idCliente = yield select(states => states.common.clienteSelecionado.idCliente);
  const idPrestacao = yield select(states => states.common.idRelacaoSelecionada);
  const { idNotaFiscalCliente } = actions;

  try {
    yield put(setSimpleValueNotasFiscaisHandlerAction('openDialogCancelNotaFiscal', false));
    yield call(cancelarNotaFiscal, idNotaFiscalCliente);
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema ao tentar cancelar a Nota Fiscal');
  } finally {
    yield pesquisarNotasFiscaisHandler({ idCliente, idPrestacao });
    yield put(setCommonAction('loadingPage', false));
  }
}

function* inserirNovaNotaFiscalHandler() {
  yield put(setCommonAction('loadingPage', true));

  const formData = yield select(states => states.notasFiscais.formFields);
  const idPrestacaoContaCliente = yield select(states => states.common.idPrestacaoContaClienteSelecionado);
  const idCliente = yield select(states => states.common.clienteSelecionado.idCliente);
  const idPrestacao = yield select(states => states.common.idRelacaoSelecionada);

  try {
    const payload = { ...getPayloadNovaNota(formData, idPrestacaoContaCliente) };
    yield call(inserirNotaFiscal, payload);
    yield put(setSimpleValueNotasFiscaisHandlerAction('openButtonNovaNota', false));
    yield put(resetFormFieldsNovaNotaAction());
    yield pesquisarNotasFiscaisHandler({ idCliente, idPrestacao });
    yield pesquisarDadosGeracaoNotaFiscalHandler();
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema ao tentar inserir uma Nova Nota Fiscal ');
    const [updateErrorFields] = yield getExceptionHandler(e, formData);
    yield put(setNovaNotaFormFieldsErrorsAction(updateErrorFields));
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* cancelarBoletoRelacaoPrestacaoHandler(actions) {
  const { idBoletoCliente } = actions;
  yield put(setCommonAction('loadingPage', true));

  try {
    const response = yield call(ContaCorrenteClienteBoletoService.cancelarBoletoRelacaoPrestacao, idBoletoCliente);

    if (response.data.codResult === 200 || response.data.codResult === 201 || response.data.codResult === 204) {
      toast.success('Boleto cancelado com sucesso.');
    } else {
      toast.error('Não foi possível efetuar o cancelamento do Boleto. Tente novamente.', { style: { width: '392px' } });
    }
  } catch (e) {
    toastUnmappedException(e,
      'Erro ao cancelar boleto selecionado');
  } finally {
    yield pesquisarDadosBoletosRelacaoPrestacaoHandler();
    yield put(setCommonAction('loadingPage', false));
  }
}


function* visualizarNotaFiscalClienteHandler(actions) {
  const { urlPrefeituraNF } = actions;
  yield put(setCommonAction('loadingPage', true));
  try {
    window.open(urlPrefeituraNF, '_blank');
  } catch (e) {
    toastUnmappedException('Erro na visualização de Nota Fiscal');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* downloadProtocoloCancelamentoNFHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { idNotaFiscalCliente } = actions;
  try {
    const response = yield call(downloadProtocoloCancelamentoNF, idNotaFiscalCliente);
    if (response.data.size > 0) {
      openPageFile(response.data, response.data.type);
    }
  } catch (e) {
    toastUnmappedException(
      e,
      'Ocorreu um problema ao tentar abrir o relatório',
    );
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* inserirNovoBoletoHandler() {
  yield put(setCommonAction('loadingPage', true));

  const formData = yield select(states => states.boletosRelacaoPrestacao.formFields);
  const idPrestacaoContaCliente = yield select(states => states.common.idPrestacaoContaClienteSelecionado);
  const dataVencimentoBoleto = yield select(states => (states.relacaoPrestacao.informacoesAdicionais.dataVencimentoBoleto));

  try {
    const payload = { ...getPayloadNovoBoleto(formData, idPrestacaoContaCliente) };
    const response = yield call(inserirNovoBoleto, payload);
    if (response.data.erroBoleto) {
      toast.error('Erro no registro do Boleto. Tente um novo registro através do botão "Registrar Boleto" abaixo ou através da opção "Ferramentas > Registrar Boletos".', { style: { width: '392px' } });
    } else {
      toast.success('Boleto gerado com sucesso.');
    }
    yield put(setSimpleValueBoletosRelacaoPrestacaoAction('openButtonNovoBoleto', false));
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema ao tentar inserir um Novo Boleto ');
    const [updateErrorFields] = yield getExceptionHandler(e, formData);
    yield put(setBoletoFormFieldsErrorsAction(updateErrorFields));
  } finally {
    yield put(setCommonAction('loadingPage', false));
    yield pesquisarDadosBoletosRelacaoPrestacaoHandler();
    yield resetFormFieldsNovoBoletoAction(dataVencimentoBoleto);
  }
}


function* registrarBoletoRelacaoPrestacaoHandler(actions) {
  const { idBoletoCliente } = actions;
  yield put(setCommonAction('loadingPage', true));

  try {
    const response = yield call(ContaCorrenteClienteBoletoService.registrarBoletoRelacaoPrestacao, idBoletoCliente);
    if (!response.data.erroBoleto) {
      toast.success('Boleto registrado com sucesso!');
      yield call(pesquisarDadosBoletosRelacaoPrestacaoHandler);
    } else {
      toast.error('Erro ao registrar o boleto.', { style: { width: '392px' } });
    }
  } catch (e) {
    toastUnmappedException(
      e,
      'Erro ao registrar o boleto.',
    );
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* enviarEmailBoletoHandler(actions) {
  const { idBoletoCliente } = actions;
  yield put(setCommonAction('loadingPage', true));

  try {
    const response = yield call(ContaCorrenteClienteBoletoService.enviarEmailBoleto, idBoletoCliente);
    if (response.data != null && response.data.erroBoleto === false) {
      toast.success('Email enviado com sucesso!');
    } else {
      toast.error('Erro ao enviar o email.', { style: { width: '392px' } });
    }
  } catch (e) {
    toastUnmappedException(
      e,
      'Erro ao enviar o email.',
    );
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* imprimirBoletoRelacaoPrestacaoHandler(actions) {
  const { idBoletoCliente } = actions;
  yield put(setCommonAction('loadingPage', true));

  try {
    const response = yield call(ContaCorrenteClienteBoletoService.imprimirBoletoRelacaoPrestacao, idBoletoCliente);
    if (response.data != null && response.data.erroBoleto === false) {
      yield openPagePDFBase64(response.data.pdfBase64);
    } else {
      toast.error('Erro ao visualizar boleto.', { style: { width: '392px' } });
    }
  } catch (e) {
    toastUnmappedException(
      e,
      'Erro ao visualizar boleto.',
    );
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

export default function* watchCCCliente() {
  yield takeLatest(PESQUISAR_CONTAS_CORRENTES_CLIENTE_ONCLICK_ACTION, pesquisarClientesOnClickHandler);
  yield takeLatest(BUSCAR_DETALHES_CLIENTE_SELECIONADO_ACTION, buscarDetalhesDoClienteSelecionadoHandler);
  yield takeLatest(PRESTAR_CONTAS_CLIENTE_SELECIONADO_ACTION, prestarContasClienteSelecionadoHandler);
  yield takeLatest(PESQUISAR_CONTA_CORRENTE_CLIENTES_ACTION, pesquisarContaCorrenteClientesHandler);
  yield takeLatest(ATUALIZAR_PAGINA_ACTION, atualizarPaginaHandler);
  yield takeLatest(DELETE_LANCAMENTO_NAO_PRESTADO_CLIENTE_SELECIONADO, deleteLancamentoNaoPrestadoClienteSelecionadoHandler);
  yield takeLatest(PESQUISAR_PRESTACAO_INICIAL_ACTION, pesquisarPrestacaoInicialHandler);
  yield takeLatest(CANCELAR_PRESTACAO_ACTION, cancelarPrestacaoHandler);
  yield takeLatest(ESTORNAR_PRESTACAO_ACTION, estornarPrestacaoHandler);
  yield takeLatest(BUSCAR_MOTIVO_ESTORNO_PRESTACAO_ACTION, buscarMotivoEstornoPrestacaoHandler);
  yield takeLatest(ATUALIZAR_STATUS_LANCAMENTO_NAO_PRESTADO_ACTION, atualizarStatusLancamentoNaoPrestadoHandler);
  yield takeLatest(PESQUISAR_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION, pesquisarLancamentosNaoPrestadosClienteSelecionadoHandler);
  yield takeLatest(PRESTAR_TODOS_ACTION, prestarTodosHandler);
  yield takeLatest(DOWNLOAD_RELATORIO_CLIENTE, downloadRelatorioClientes);
  yield takeLatest(DOWNLOAD_RELATORIO_LANCAMENTOS_NAO_PRESTADOS, downloadRelatorioLancamentosNaoPrestados);
  yield takeLatest(FIND_EDITAR_LANCAMENTO_ACTION, findEditarLancamento);
  yield takeLatest(SAVE_LANCAMENTO_NAO_PRESTADO_ACTION, salvarLancamentoNaoPrestadoHandler);
  yield takeLatest(EDIT_LANCAMENTO_NAO_PRESTADO_ACTION, editarLancamentoNaoPrestadoHandler);
  yield takeLatest(BUSCAR_RELACOES_DO_CLIENTE_ACTION, buscarRelacoesDoClienteHandler);
  yield takeLatest(BUSCAR_SALDOS_RELACAO_PRESTACAO_ACTION, buscarSaldosRelacaoPrestacaoHandler);
  yield takeLatest(PRINT_RELACAO_PRESTACAO_ACTION, printRelacaoPrestacaoHandler);
  yield takeLatest(ENVIAR_EMAIL_PRESTACAO_ACTION, enviarEmailPrestacaoHandler);
  yield takeLatest(FIND_PAGAMENTOS_ACTION, pesquisarPagamentosDePrestacaoEspecificaHandler);
  yield takeLatest(DELETE_LANCAMENTO_PAGAMENTO_ACTION, deleteLancamentoPagamentoHandler);
  yield takeLatest(INSERIR_LANCAMENTO_PAGAMENTO_ACTION, inserirLancamentoPagamentoHandler);
  yield takeLatest(DOWNLOAD_RELATORIO_PAGAMENTOS, downloadRelatorioPagamentos);
  yield takeLatest(PESQUISAR_REPASSE_ACTION, pesquisarRepasseHandler);
  yield takeLatest(PESQUISAR_LISTA_AUTORIZACAO_REPASSE_ACTION, pesquisarListaAutorizacaoRepasse);
  yield takeLatest(ATUALIZAR_STATUS_LIDO_AUTORIZACAO_REPASSE_ACTION, atualizarLidoAutorizacaoRepasseHandler);
  yield takeLatest(DOWNLOAD_ARQUIVO_AUTORIZACAO_REPASSE_ACTION, downloadArquivoAutorizacaoRepasseHandler);
  yield takeLatest(PESQUISAR_TOTAL_REPASSE_ACTION, pesquisarTotalRepasseHandler);
  yield takeLatest(LIBERAR_REPASSES_ACTION, liberarRepassesHandler);
  yield takeLatest(DEFINIR_VALOR_SELECIONADO_REPASSE_ACTION, definirValorRepasseSelecionadoHandler);
  yield takeLatest(PRINT_ARQUIVO_REPASSES_AUTORIZACAO_REPASSE_PDF_ACTION, printRepassesAutorizacaoPrestacaoHandler);
  yield takeLatest(PESQUISAR_TIPOS_LANCAMENTOS_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION, pesquisarTiposLancamentosNaoPrestadosClienteSelecionadoHandler);
  yield takeLatest(ATUALIZA_STATE_TIPOS_LANCAMENTOS_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION, atualizaStateTiposLancamentosNaoPrestadosClienteSelecionadoHandler);
  yield takeLatest(MUDA_STATUS_LANCAMENTOS_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION, mudaStatusLancamentosNaoPrestadosClienteSelecionadoHandler);
  yield takeLatest(DOWNLOAD_RELATORIO_REPASSES_ACTION, downloadRelatorioRepassesHandler);
  yield takeLatest(PESQUISAR_RECEBIMENTOS_ONCLICK_ACTION, pesquisarRecebimentosOnClickHandler);
  yield takeLatest(EMITIR_RPS_ONCLICK_ACTION, emitirRpsOnClickHandler);
  yield takeLatest(DEFINIR_VALOR_RECEBIMENTO_ACTION, valorRecebimentoSelecionadoHandler);
  yield takeLatest(HABILITAR_EMITIR_EH_AGRUPAR_RPS_ACTION, habilitarEmitirEhAgruparRps);
  yield takeLatest(GERAR_PDF_RECEBIMENTOS_PESQUISADOS_ACTION, gerarPDFRecebimentosPesquisadosHandler);
  yield takeLatest(AGRUPAR_RPS_ON_CLICK_ACTION, agruparRPSOnClickHandler);
  yield takeLatest(PESQUISAR_RPS_EMITIDOS_ACTION, pesquisarRpsEmitidos);
  yield takeLatest(SALVAR_ALTERACOES_INFORMACOES_CLIENTE_ACTION, salvarAlteracoesDasInformacoesClienteHandler);
  yield takeLatest(CANCELAR_RPS_ACTION, cancelarRpsEmitidoHandler);
  yield takeLatest(PESQUISAR_LISTA_LANCAMENTOS_ACTION, pesquisarListaLancamentosHandler);
  yield takeLatest(PESQUISAR_DADOS_GERACAO_NOTA_FISCAL_ACTION, pesquisarDadosGeracaoNotaFiscalHandler);
  yield takeLatest(ATUALIZAR_STATUS_LIDO_DADOS_GERACAO_NF_ACTION, atualizarStatusLidoDadosGeracaoNotaFiscalHandler);
  yield takeLatest(DOWNLOAD_ARQUIVO_REMESSA_ACTION, downloadArquivoRemessaHandler);
  yield takeLatest(VISUALIZAR_PDF_REMESSA_ON_CLICK_ACTION, visualizarPDFRemessaOnClickHandler);
  yield takeLatest(GERAR_NOTA_FISCAL_ACTION, gerarNotaFiscalHandler);
  yield takeLatest(IMPORTAR_ARQUIVO_RETORNO_ACTION, importarArquivoRetornoHandler);
  yield takeLatest(PESQUISAR_NOTAS_FISCAIS_ACTION, pesquisarNotasFiscaisHandler);
  yield takeLatest(CANCELAR_NOTAS_FISCAIS_ACTION, cancelarNotasFiscaisHandler);
  yield takeLatest(INSERIR_NOVA_NOTA_FISCAL, inserirNovaNotaFiscalHandler);
  yield takeLatest(PESQUISAR_DADOS_BOLETOS_RELACAO_PRESTACAO_ACTION, pesquisarDadosBoletosRelacaoPrestacaoHandler);
  yield takeLatest(CANCELAR_BOLETO_RELACAO_PRESTACAO_ACTION, cancelarBoletoRelacaoPrestacaoHandler);
  yield takeLatest(BUSCAR_INFORMACOES_RELACAO_PRESTACAO_ACTION, buscarInformacoesRelacaoPrestacaoHandler);
  yield takeLatest(VISUALIZAR_NOTA_FISCAL_CLIENTE_ACTION, visualizarNotaFiscalClienteHandler);
  yield takeLatest(DOWNLOAD_PROTOCOLO_CANCELAMENTO_NF_ACTION, downloadProtocoloCancelamentoNFHandler);
  yield takeLatest(INSERIR_NOVO_BOLETO, inserirNovoBoletoHandler);
  yield takeLatest(REGISTRAR_BOLETO_RELACAO_PRESTACAO_ACTION, registrarBoletoRelacaoPrestacaoHandler);
  yield takeLatest(ENVIAR_EMAIL_BOLETO_ACTION, enviarEmailBoletoHandler);
  yield takeLatest(IMPRIMIR_BOLETO_RELACAO_PRESTACAO_ACTION, imprimirBoletoRelacaoPrestacaoHandler);
  yield takeLatest(GET_PDF_ACTION, getPdfHandler);
  yield takeLatest(DOWNLAOD_PDF_ACTION, downloadPdfHandler);
}
