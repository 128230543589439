import React from 'react';
import { useDispatch } from 'react-redux';
import { initializePageAction } from '../../../../store/theme.actions';
import CrudCliente from '../../../../components/Cliente/Crud/CrudCliente';
import withTokenInterceptor from '../../../../hoc/withTokenInterceptor/withTokenInterceptor';
import CrudClienteProvider from '../../../../store/Cliente/V2/Crud/CrudClienteProvider';


function CadastrarClientePage() {
  const dispatch = useDispatch();

  dispatch(initializePageAction('Clientes > Novo Cadastro'));
  return (
    <CrudClienteProvider>
      <CrudCliente isInsertMode />
    </CrudClienteProvider>
  );
}

export default withTokenInterceptor(CadastrarClientePage);
