import React, { useCallback } from 'react';

import WrapperSubTitleForm from '../../../UI/WrapperSubTitleForm';
import TextareaField from '../../../UI/Field/Textarea/TextareaField';

import { setFocusAction } from '../../../../store/Borderos/Crud/CrudBordero.store';
import { useCrudBoderoDispatch, useCrudBorderoSelector } from '../../../../store/Borderos/Crud/CrudBorderoProvider';

import useUtilStyles from '../../../../helpers/styles/utils.styles';

import constants from './InstrucoesCrudBordero.constants';
import useStyles from './InstrucoesCrudBordero.styles';

export default function InstrucoesCrudBordero({
  wrapperByMode, isInspectMode, changeFields, disabled,
}) {
  const utilStyles = useUtilStyles();
  const styles = useStyles();

  const dispatch = useCrudBoderoDispatch();

  const instrucao = useCrudBorderoSelector(states => states.requestFields.instrucao);

  const clearErrorFieldsHandler = useCallback((event) => {
    dispatch(setFocusAction(event.target.name));
  }, [dispatch]);

  return (
    <WrapperSubTitleForm
      title={wrapperByMode(constants.TITLE_REGISTER, constants.TITLE_READ)}
      className={utilStyles.mt16}
    >
      <TextareaField
        label={constants.INSTRUCOES}
        classForm={styles.root}
        disabled={isInspectMode || disabled}
        name={instrucao.name}
        value={instrucao.value}
        error={instrucao.error}
        errorMessage={instrucao.errorMessage}
        maxLength={instrucao.maxLength}
        onChange={changeFields}
        onFocus={clearErrorFieldsHandler}
        showCounter={!isInspectMode}
      />
    </WrapperSubTitleForm>
  );
}
