import React from 'react';
import cx from 'classnames';

import {
  Grid, Button, Typography, Fade,
  IconButton,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import WrapperContent from '../../components/UI/WrapperContent/WrapperContent';
import ButtonUI from '../../components/UI/Button/ButtonUI';

import useStyles from './SearchLayout.styles';
import useStylesSimpleSearch from '../../components/UI/SimpleSearch/SimpleSearch.styles';
import Table from '../../components/UI/Tabela/PaginadaSimples/Table';
import InputField from '../../components/UI/Field/Input';

function SearchLayout({
  children = null,
  onClickButtonNovo,
  allowedNovo,
  searchFieldLabel,
  fieldPesquisa,
  fieldSecundarioPesquisa,
  isCliente = false,
  tituloTabela,
  tableColumns,
  tableRows,
  tablePageable,
  openPesquisaAvancada,
  clickPesquisaAvancada,
  onChangePesquisa,
  onClickPesquisa,
  onChangePage,
  onChangeRowsPerPage,
}) {
  const styles = useStyles({ openPesquisaAvancada });
  const stylesSimpleSearch = useStylesSimpleSearch();

  let advancedSearchLabel = 'Pesquisa Avançada';
  if (openPesquisaAvancada) {
    advancedSearchLabel = 'Pesquisa Simples';
  }
  const buttonClassPesquisaAvancada = cx({
    [styles.pesquisaAvancadaButton]: !openPesquisaAvancada,
    [styles.pesquisaSimplesButton]: openPesquisaAvancada,
    [styles.hidePesquisaAvancada]: children === null,
  });

  return (
    <WrapperContent>
      <form onSubmit={onClickPesquisa}>
        <Grid container>
          <Grid item xs={12} sm={7}>
            {openPesquisaAvancada && (
              <Fade in={openPesquisaAvancada}>
                <div>
                  {children}
                </div>
              </Fade>
            )}
          </Grid>
          <Grid item xs={12} sm={5}>
            <div>
              <Grid container justifyContent="flex-end" alignItems="flex-end">
                {isCliente
                && (
                <Grid item className={stylesSimpleSearch.textFieldSecundario}>
                  <InputField
                    fullWidth
                    autoComplete="nope"
                    type="search"
                    name={fieldSecundarioPesquisa.name}
                    label="Código"
                    value={fieldSecundarioPesquisa.value}
                    onChange={onChangePesquisa}
                    inputProps={{
                      maxLength: 60,
                    }}
                  />
                </Grid>
                )
}


                <Grid item className={stylesSimpleSearch.textField}>
                  <InputField
                    fullWidth
                    name={fieldPesquisa.name}
                    autoComplete="nope"
                    type="search"
                    label={searchFieldLabel}
                    value={fieldPesquisa.value}
                    onChange={onChangePesquisa}
                    inputProps={{
                      maxLength: 60,
                    }}
                  />
                </Grid>
                <Grid item>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <IconButton
                        type="submit"
                        className={stylesSimpleSearch.button}
                      >
                        <SearchIcon style={{ marginLeft: 2 }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>


              <div className={styles.pesquisaAvancada}>
                <Button className={buttonClassPesquisaAvancada} onClick={clickPesquisaAvancada}>
                  {advancedSearchLabel}
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
      <Grid container justifyContent="flex-end" className={styles.buttonNovo}>
        <Grid item>
          <ButtonUI disabled={allowedNovo} onClick={onClickButtonNovo}>
            Novo
          </ButtonUI>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Typography component="h3" className={styles.tableTitle}>
            {tituloTabela}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Table
            rows={tableRows}
            columns={tableColumns}
            page={tablePageable.page}
            size={tablePageable.size}
            rowsPerPage={tablePageable.rowsPerPage}
            isMemory={tablePageable.isMemory}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </WrapperContent>
  );
}

export default SearchLayout;
