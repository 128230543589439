import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  actions: {
    paddingTop: '20px',
    '& label': {
      marginLeft: '8px',
    },
  },
});
