import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Topbar from '../../components/UI/Topbar/Topbar';

import useStyles from './FormDialogLayout.styles';

function FormDialogLayout({
  children, open, icon, routesName, onClose, ...props
}) {
  const styles = useStyles();
  return (
    <Dialog classes={{ root: styles.root }} open={open} fullWidth {...props}>
      <DialogTitle>
        <Topbar icon={icon} routesName={routesName} onClose={onClose} />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

FormDialogLayout.defaultProps = {
  open: false,
};

FormDialogLayout.propTypes = {
  open: PropTypes.bool,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  routesName: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FormDialogLayout;
