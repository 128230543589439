import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import * as _ from 'lodash';

import { FaEraser } from 'react-icons/fa';
import { MdLibraryBooks, MdLightbulbOutline } from 'react-icons/md';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Typography } from '@material-ui/core';
import { Button } from '@mui/material';

import Grid24 from '../../../components/UI/Grid24/Grid24';
import SelectorField from '../../../components/UI/Field/Selector/SelectorField';
import CheckboxField from '../../../components/UI/Field/Checkbox/CheckboxField';
import TextareaField from '../../../components/UI/Field/Textarea/TextareaField';
import ButtonUI from '../../../components/UI/Button/ButtonUI';
import TextoPadraoDialog from '../TextoPadraoDialog/TextoPadraoDialog';

import { tipoTextoPadraoTypes } from '../../../types/tipoTextoPadraoTypes';

import {
  ActionHeaderContainer,
  CustomButton,
  TextoPadraoButton,
} from '../InspecionarProcessoStyles';
import useStyles, { CheckboxGridRow, FooterContainer, GridRow } from './acaoEnviarStyles';
import { AntecedenteProcesso } from '../AntecendentesProcesso';
import DialogNew from '../../../layouts/FormDialog/DialogNew';
import { acaoEnviarController as controller } from './acaoEnviarController';
import { loadingInspecaoProcessoController as loadingController } from '../loadingInspecaoProcessoController';

import {
  clearErrorField, getFieldName, updateFieldsWithErrors,
} from '../../../helpers/utils/form.utils';

import { findPastasFaseAction } from '../../../store/Global/LoadSelectors/loadSelectors.saga';
import { clearSelectorAction } from '../../../store/Global/LoadSelectors/loadSelectors.store';


import { haveExceptionValidations, isBadRequestException } from '../../../helpers/utils/utils';
import { Title } from '../../../components/Title/Title';
import { atualizarFaseProcessoService, validarAntecedentes } from '../../../services/core/processos/processosFaseService';
import OptionDialogNew from '../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';
import DialogGPT from '../../../components/DialogGPT/DialogGPT';
import TooltipUI from '../../../components/UI/Tooltip/Tooltip';
import { LightIndicator } from '../../../components/Title/Title.styles';


function AcaoEnviarComponent({
  processo,
  idFaseAtual,
  idFasePastaAtual,
  idMotivoBaixa,
  idNegociador,
  permiteCobrancaPresencial,
  updatePage,
  motivosBaixa = [],
  setLoadings,
  temAgendamento = false,
  idProcesso,
  clientePermiteUnificar,
  dadosProcesso,
  fasesIds,
  podeModificarFase,
  disabledAntecedentes,
}) {
  const [showAlertLimpar, setShowAlertLimpar] = useState(false);
  const [showChooserTextoPadrao, setShowChooserTextoPadrao] = useState(false);
  const [formFields, setFormFields] = useState(controller.makeFormFields({ liberarCompromissos: temAgendamento }));
  const [errorFiedls, setErrorFields] = useState(controller.makeErrorFields());
  const [faseSelecionada, setFaseSelecionada] = useState({});
  const [negociadores, setNegociadores] = useState([]);
  const [fases, setFases] = useState([]);
  const [antecedentesSelecionados, setAntecedentesSelecionados] = useState([]);
  const [openDialogAntecedentes, setOpenDialogAntecedentes] = useState(false);
  const [openDialogConfirmaEnvio, setOpenDialogConfirmaEnvio] = useState(false);

  // Dialog GPT
  const [desabilitarReescrever, setDesabilitarReescrever] = useState(true);
  const [showDialogTest, setShowDialogTest] = useState(false);

  const styles = useStyles();
  const dispatch = useDispatch();

  const fasePastas = useSelector(states => states.selectors.pastasDaFase.selector);

  const isFaseAtual = useMemo(() => idFaseAtual === Number(formFields.idFase), [idFaseAtual, formFields.idFase]);
  const isDisabledLimpar = _.isEqual(formFields, controller.makeFormFields({ liberarCompromissos: temAgendamento }));

  const visibleStates = useMemo(() => ({
    idFasePasta: fasePastas.length > 0,
    idMotivoBaixa: faseSelecionada?.exigeMotivoBaixa && motivosBaixa.length > 0,
    recalcularValorProcesso: faseSelecionada?.recalcularValorProcesso && faseSelecionada.id !== idFaseAtual,
    exigeNegociadorExterno: faseSelecionada?.exigeNegociadorExterno,
  }), [
    faseSelecionada,
    fasePastas,
    motivosBaixa.length,
    idFaseAtual,
  ]);

  const confirmAlertLimpar = useCallback(() => {
    setFormFields(controller.makeFormFields({ liberarCompromissos: temAgendamento }));
    setErrorFields(controller.makeErrorFields());
    dispatch(clearSelectorAction('pastasDaFase'));
    setNegociadores([]);
    setShowAlertLimpar(false);
    setDesabilitarReescrever(true);
  }, [dispatch, temAgendamento]);

  async function onSubmitHandler(e) {
    e.preventDefault();
    setLoadings(loadingController.changeLoadingAction(false));
    try {
      await atualizarFaseProcessoService(processo.id, { ...formFields });
      antecedentesSelecionados.forEach((idSelecionado) => { atualizarFaseProcessoService(idSelecionado, { ...formFields }); });
      toast.success('Ação realizada com sucesso!');
      updatePage();
    } catch (exception) {
      if (isBadRequestException(exception) && haveExceptionValidations(exception)) {
        const [updatedErrors] = updateFieldsWithErrors(exception, errorFiedls);
        setErrorFields({ ...updatedErrors });
      } else if (isBadRequestException(exception)) {
        toast.error(exception?.response?.data?.message || 'Erro não mapeado', { style: { width: '392px' } });
      }
    }
    setLoadings(loadingController.changeLoadingAction(false));
  }

  async function checkSubmitHandlerDialog(e) {
    setLoadings(loadingController.changeLoadingAction(true));
    try {
      const validacaoAntecedentes = await validarAntecedentes(antecedentesSelecionados, Number(formFields.idFase));
      if (!validacaoAntecedentes.data) {
        setOpenDialogConfirmaEnvio(true);
      } else {
        onSubmitHandler(e);
      }
    } catch (err) {
      toast.error('Erro ao obter dados antecedentes.');
    } finally {
      setLoadings(loadingController.changeLoadingAction(false));
    }
  }

  const buscarPastas = useCallback((idFase) => {
    const onlyPublico = Number(idFaseAtual) === Number(idFase) ? null : 'S';
    dispatch(findPastasFaseAction(idFase, onlyPublico));
  }, [dispatch, idFaseAtual]);

  const buscarNegociadores = useCallback(async (idFase, fase) => {
    if (fase?.exigeNegociadorExterno) {
      const res = await controller.buscarNegociadoresDaFase(idFase);
      setNegociadores(res);
    } else {
      setNegociadores([]);
    }
  }, []);


  const onChangeFaseHandler = useCallback((e) => {
    const { value: idFase } = e.target;
    const name = getFieldName(e.target);

    const isNotFaseAtual = String(idFaseAtual) !== String(idFase);

    let liberarCompromissos = temAgendamento;
    if (temAgendamento) {
      liberarCompromissos = isNotFaseAtual;
    }

    setFormFields(oldValues => ({
      ...oldValues,
      liberarCompromissos,
      liberarRequisicao: isNotFaseAtual,
      idFasePasta: '',
      idMotivoBaixa: '',
      idNegociador: '',
      [name]: String(idFase),
    }));

    setErrorFields(controller.makeErrorFields());

    const newFaseSelecionada = fases.find(fase => String(fase.id) === String(idFase));
    buscarPastas(idFase, newFaseSelecionada);
    buscarNegociadores(idFase, newFaseSelecionada);
    setFaseSelecionada(newFaseSelecionada);
  }, [idFaseAtual,
    fases,
    temAgendamento,
    buscarNegociadores,
    buscarPastas,
  ]);

  const onChangeHandler = useCallback((e) => {
    const { value } = e.target;
    const name = getFieldName(e.target);
    if (name === 'texto') {
      const qtdePalavras = e.target.value.trim().split(' ').length;
      setDesabilitarReescrever(qtdePalavras < 5);
    }
    setFormFields(oldValues => ({ ...oldValues, [name]: String(value) }));
  }, []);

  const onCheckedHandler = useCallback((e) => {
    const { name, checked } = e.target;
    setFormFields(oldValues => ({ ...oldValues, [name]: checked }));
  }, []);

  const clearErrorFields = useCallback((e) => {
    setErrorFields(clearErrorField(errorFiedls, getFieldName(e.target)));
  }, [errorFiedls]);


  function isNotValidForm() {
    const isDifferentFase = String(formFields.idFase) !== String(idFaseAtual);
    const containsFase = !!formFields.idFase && isDifferentFase;

    const isDifferentPasta = String(formFields.idFasePasta) !== String(idFasePastaAtual);
    const containsPasta = visibleStates.idFasePasta && isDifferentPasta;

    const isDifferentMotivo = String(formFields.idMotivoBaixa) !== String(idMotivoBaixa);
    const containsMotivo = visibleStates.idMotivoBaixa && formFields.idMotivoBaixa && isDifferentMotivo;

    const isDifferentNegociador = String(formFields.idNegociador) !== String(idNegociador);
    const containsNegociador = negociadores.length > 0 && formFields.idNegociador && isDifferentNegociador;
    return !(containsFase || containsPasta || containsMotivo || containsNegociador);
  }

  useEffect(() => {
    dispatch(clearSelectorAction('pastasDaFase'));
  }, [dispatch]);

  // Reseta os valores a cada nova pesquisa de fases.
  // Atualmente apenas alterar o checkbox "Nao permite cobrança presencial" recarrega as fases.
  useEffect(() => {
    if (processo.id) {
      controller.buscarFases(permiteCobrancaPresencial).then((res) => {
        setFases(res);
        confirmAlertLimpar();
      });
    }
  }, [processo.id, permiteCobrancaPresencial, confirmAlertLimpar]);

  const openMelhorarTextoDialog = () => {
    setShowDialogTest(true);
  };

  const updateDescricaoAtividades = (newText) => {
    const name = 'texto';
    setFormFields(oldValues => ({ ...oldValues, [name]: String(newText) }));
  };

  return (
    <>
      <DialogNew
        open={openDialogAntecedentes}
        text="Antecedentes"
        className={styles.dialog}
      >
        <Typography component="p" className={styles.typography}>
          Selecione os antecedentes:
        </Typography>
        <AntecedenteProcesso
          idProcesso={idProcesso}
          setLoadings={setLoadings}
          clientePermiteUnificar={clientePermiteUnificar}
          dadosProcesso={dadosProcesso}
          updatePage={updatePage}
          podeModificarFase={podeModificarFase}
          idFaseProcesso={idFaseAtual}
          fasesIds={fasesIds}
          accordion={false}
          antecedentesSelecionados={antecedentesSelecionados}
        />
        <div className={styles.div__footer}>
          <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
            <Button
              onClick={() => {
                setOpenDialogAntecedentes(false);
                setAntecedentesSelecionados([]);
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => { setOpenDialogAntecedentes(false); }}
            >
              Confirmar
            </Button>
          </div>
        </div>
      </DialogNew>
      <OptionDialogNew
        open={openDialogConfirmaEnvio}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickConfirm={(e) => { setOpenDialogConfirmaEnvio(false); onSubmitHandler(e); }}
        onClickCancel={() => setOpenDialogConfirmaEnvio(false)}
        text="Há processo antecedente cujo acordo se encontra ativo.
        Portanto, a mudança de fase poderá causar problemas.
        Confirma o envio dos processos para outra fase? "
      />
      <TextoPadraoDialog
        open={showChooserTextoPadrao}
        tipos={[tipoTextoPadraoTypes.ACOMPANHAMENTO.value, tipoTextoPadraoTypes.DEVOLUCAO.value]}
        setTextoHandler={texto => setFormFields(oldValues => ({ ...oldValues, texto: `${oldValues.texto} ${texto}` }))}
        closeDialogHandler={() => setShowChooserTextoPadrao(false)}
      />
      <OptionDialogNew
        open={showAlertLimpar}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickConfirm={confirmAlertLimpar}
        onClickCancel={() => setShowAlertLimpar(false)}
        text="Tem certeza de que deseja descartar essas informações? "
      />
      <form onSubmit={onSubmitHandler}>
        <ActionHeaderContainer>
          <Title
            text="Envio do Processo"
            hint="A Anotação será inserida no Acompanhamento do Processo"
          />
          <CustomButton
            disabled={isDisabledLimpar}
            onClick={() => setShowAlertLimpar(true)}
          >
            <FaEraser size={18} />
            <span>Limpar</span>
          </CustomButton>
        </ActionHeaderContainer>
        <GridRow container spacing="1">
          <Grid24 item xs={24} sm={9}>
            <SelectorField
              isForm
              label="Fase*"
              name="idFase"
              items={fases}
              value={formFields.idFase}
              error={errorFiedls.idFase.error}
              errorMessage={errorFiedls.idFase.errorMessage}
              onChange={onChangeFaseHandler}
              onFocus={clearErrorFields}
            />
          </Grid24>
          {visibleStates.idFasePasta && (
            <Grid24 item xs={24} sm={9}>
              <SelectorField
                isForm
                hasEmptyLabel
                label="Pasta"
                name="idFasePasta"
                items={fasePastas}
                value={formFields.idFasePasta}
                onChange={onChangeHandler}
                onFocus={clearErrorFields}
              />
            </Grid24>
          )}
          {visibleStates.idMotivoBaixa && (
            <Grid24 item xs={24} sm={9}>
              <SelectorField
                isForm
                hasEmptyLabel
                label="Motivo*"
                name="idMotivoBaixa"
                items={motivosBaixa}
                value={formFields.idMotivoBaixa}
                error={errorFiedls.idMotivoBaixa.error}
                errorMessage={errorFiedls.idMotivoBaixa.errorMessage}
                onChange={onChangeHandler}
                onFocus={clearErrorFields}
              />
            </Grid24>
          )}
          {visibleStates.exigeNegociadorExterno && (
            <Grid24 item xs={24} sm={9}>
              <SelectorField
                isForm
                hasEmptyLabel
                label="Negociador*"
                name="idNegociador"
                items={negociadores}
                value={formFields.idNegociador}
                error={errorFiedls.idNegociador.error}
                errorMessage={errorFiedls.idNegociador.errorMessage}
                onChange={onChangeHandler}
                onFocus={clearErrorFields}
              />
            </Grid24>
          )}

        </GridRow>
        <CheckboxGridRow container>
          <div>
            <CheckboxField
              disabled={isFaseAtual}
              label="Liberar Requisição do Processo"
              name="liberarRequisicao"
              checked={formFields.liberarRequisicao}
              onChange={onCheckedHandler}
            />
          </div>
          <div>
            <CheckboxField
              disabled={isFaseAtual || !temAgendamento}
              label="Liberar compromisso da Agenda do Processo"
              name="liberarCompromissos"
              checked={formFields.liberarCompromissos}
              onChange={onCheckedHandler}
            />
          </div>
          <div>
            {visibleStates.recalcularValorProcesso === true && (
              <CheckboxField
                label="Recalcular o valor do Processo"
                name="recalcularValorProcesso"
                checked={formFields.recalcularValorProcesso}
                onChange={onCheckedHandler}
              />
            )}
          </div>
        </CheckboxGridRow>
        <GridRow container>
          <TextareaField
            label="Texto"
            name="texto"
            minRows={8}
            placeholder="Inserir Texto"
            value={formFields.texto}
            onChange={onChangeHandler}
          />
        </GridRow>
        <FooterContainer>
          <TextoPadraoButton
            variant="outlined"
            onClick={() => setShowChooserTextoPadrao(true)}
          >
            <MdLibraryBooks />
            <span>Textos Padrão</span>
          </TextoPadraoButton>
          <TextoPadraoButton
            variant="outlined"
            onClick={openMelhorarTextoDialog}
            style={{ marginLeft: '10px' }}
            disabled={desabilitarReescrever}
          >
            <MdLibraryBooks />
            <span>Reescrever</span>
          </TextoPadraoButton>
          <TooltipUI style={{ marginTop: '8px', marginLeft: '8px' }} title="São necessárias no mínimo 5 palavras para reescrever o texto.">
            <LightIndicator><MdLightbulbOutline /></LightIndicator>
          </TooltipUI>
          <DialogGPT
            open={showDialogTest}
            onClose={() => setShowDialogTest(false)}
            initialText={formFields.texto}
            updateText={updateDescricaoAtividades}
          />
          <TextoPadraoButton
            variant="outlined"
            onClick={() => setOpenDialogAntecedentes(true)}
            style={{ marginRight: '10px', marginLeft: 'auto' }}
            disabled={disabledAntecedentes}
          >
            <MdLibraryBooks />
            <span>Antecedentes</span>
          </TextoPadraoButton>
          <ButtonUI
            disabled={isNotValidForm()}
            color="success"
            style={{ width: '136px' }}
            onClick={checkSubmitHandlerDialog}
          >
            Enviar
          </ButtonUI>
        </FooterContainer>
      </form>
    </>
  );
}

export const AcaoEnviar = AcaoEnviarComponent;
