import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  wrapper: {
    display: 'flex',
    padding: '15px 22px',
    justifyContent: 'flex-end',
  },
  wrapperRollbackWithOtherButtons: {
    display: 'flex',
    padding: '15px 22px',
    justifyContent: 'space-between',
  },
  wrapperRollback: {
    display: 'flex',
    padding: '15px 22px',
    justifyContent: 'flex-start',
  },

  wrapperDialog: {
    display: 'flex',
    paddingTop: '24px',
    justifyContent: 'flex-end',
  },
  wrapperRollbackDialog: {
    display: 'flex',
    paddingTop: '24px',
    justifyContent: 'flex-start',
  },
  salvarWidth: {
    width: '152px',
  },
  migrarWidth: {
    width: '170px',
  },
  cancelarWidth: {
    width: '100px',
    marginRight: '10px',
  },
  voltarWidth: {
    width: '152px',
  },
});
