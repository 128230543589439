import styled from 'styled-components';

export const FoorterFormContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button:first-child {
    width: 100px;
    margin-right: 10px;
  }

`;
