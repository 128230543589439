import React, { useImperativeHandle } from 'react';

import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { buildSelectorTipoConsultaDinamica, getFieldName, toastUnmappedException } from '../../helpers';

import { FoorterFormContainer } from '../FoorterFormContainer/FoorterFormContainer';
import MultilineSelect from '../UI/Select/Multiline/MultilineSelect';
import SwitchUI from '../UI/Switch/SwitchUI';
import SelectorField from '../UI/Field/Selector/SelectorField';
import InputField from '../UI/Field/Input/InputField';
import { ContainerAtualizarConsultaDinamica, Layout } from './AtualizarConsultaDinamicaStyles';
import { ValidationBuilder, ValidationComposite } from '../../validations';
import { ConsultaDinamicaService } from '../../services';
import { findAllUsuariosComPermissaoConsultaDinamicaAction } from '../../store/Global/LoadSelectors/loadSelectors.saga';
import { ConsultaDinamicaTypes } from '../../types';

const validation = ValidationComposite.build([
  ...ValidationBuilder.field('tipoConsulta').required().build(),
  ...ValidationBuilder.field('descricao').required().build(),
]);

function makeUsuariosComPermissao(usuarios) {
  if (!usuarios && !Array.isArray(usuarios)) {
    return [];
  }
  return usuarios.filter(usuario => !!usuario.possuiPermissao).map(usuario => usuario.idUsuario);
}

function makeFields(consultaDinamicaItem) {
  return {
    idConsultaDinamica: consultaDinamicaItem?.idConsultaDinamica || '',
    tipoConsulta: consultaDinamicaItem?.idTipo || '',
    idsUsuarios: [],
    descricao: consultaDinamicaItem?.descricao || '',
    statusConsulta: consultaDinamicaItem?.status || false,
  };
}

function AtualizarConsultaDinamicaComponent({ onLoading, onUpdateTable }, ref) {
  const dispatch = useDispatch();

  const [fields, setFields] = React.useState(makeFields());
  const [isOpen, setIsOpen] = React.useState(false);

  const usuarios = useSelector(state => state.selectors.usuariosPermissaoConsultaDinamica.selector);

  const tiposConsultaDinamica = React.useMemo(() => buildSelectorTipoConsultaDinamica(), []);

  function handleClose() {
    setIsOpen(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    onLoading(true);
    try {
      await ConsultaDinamicaService.updateConsultaDinamica(fields);
      onUpdateTable();
      setIsOpen(false);
    } catch (error) {
      toastUnmappedException(error, 'Ocorreu um problema ao tentar salvar a consulta');
    } finally {
      onLoading(false);
    }
  }

  const handleChange = React.useCallback((e) => {
    const name = getFieldName(e.target);
    const { value } = e.target;
    setFields(oldValue => ({ ...oldValue, [name]: value }));
  }, []);

  const handleChangeTipo = React.useCallback((e) => {
    handleChange(e);
    setFields((oldValue) => {
      if (ConsultaDinamicaTypes.isPublica(oldValue.tipoConsulta)) {
        return { ...oldValue, idsUsuarios: [] };
      }
      return oldValue;
    });
  }, [handleChange]);

  const handleChangeSwitch = React.useCallback((e) => {
    const name = getFieldName(e.target);
    const { checked } = e.target;
    setFields(latestValue => ({ ...latestValue, [name]: checked }));
  }, []);

  useImperativeHandle(ref, () => ({
    loadData: (consultaDinamicaItem) => {
      setFields(makeFields(consultaDinamicaItem));
      dispatch(findAllUsuariosComPermissaoConsultaDinamicaAction(consultaDinamicaItem.idConsultaDinamica));
      setIsOpen(true);
    },
  }));

  React.useEffect(() => {
    setFields(oldFields => ({ ...oldFields, idsUsuarios: makeUsuariosComPermissao(usuarios) }));
  }, [usuarios]);

  return (
    <Layout
      open={isOpen}
      onClose={handleClose}
      maxWidth="xs"
      text={['Gerenciar Consulta Dinâmica']}
    >
      <form onSubmit={handleSubmit}>
        { }
        <ContainerAtualizarConsultaDinamica>
          <SelectorField
            hasEmptyLabel
            fullWidth
            isForm
            style={{ width: '116px' }}
            name="tipoConsulta"
            label="Tipo*"
            items={tiposConsultaDinamica}
            value={fields.tipoConsulta}
            onChange={handleChangeTipo}
          />

          <MultilineSelect
            fullWidth
            disabled={ConsultaDinamicaTypes.isPublica(fields.tipoConsulta)}
            isForm
            name="idsUsuarios"
            label="Usuários com Permissão"
            items={usuarios}
            value={fields.idsUsuarios}
            onChange={handleChange}
          />

          <InputField
            fullWidth
            isForm
            name="descricao"
            label="Descrição*"
            maxLength={100}
            value={fields.descricao}
            onChange={handleChange}
          />

          <Grid container>
            <SwitchUI
              name="statusConsulta"
              label="Consulta Ativa"
              checked={fields.statusConsulta}
              onChange={handleChangeSwitch}
            />
          </Grid>
        </ContainerAtualizarConsultaDinamica>
        <FoorterFormContainer>
          <Button
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            disabled={!validation.isValidPayload(fields)}
          >
            Salvar
          </Button>
        </FoorterFormContainer>
      </form>
    </Layout>
  );
}

export const AtualizarConsultaDinamica = React.forwardRef(AtualizarConsultaDinamicaComponent);
