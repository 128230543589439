import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  Typography, Grid,
} from '@material-ui/core';
import { changeKeyHandler, KEY_NUMBER, KEY_TEXT } from '../../../../helpers/utils/changeEvents.utils';
import {
  MEUS_PROCESSOS, MINHAS_REQUISICOES, pesquisaProcessoController, PROCESSOS_AJUIZADOS,
} from '../../../../controller/pesquisarProcessos.controller';
import { CNPJ_MASK, CPF_MASK } from '../../../../helpers/constants/layout.constants';
import { GroupDefinitions } from '../../../Cliente/Crud/Tabs/BaseCalculo/Honorarios/styles';
import InputField from '../../../UI/Field/Input/InputField';
import RadioField from '../../../UI/Field/Radio/RadioField';
import SelectorField from '../../../UI/Field/Selector/SelectorField';

import {
  ProcessoNaoTrabalhadoFiltros, ProcessoExpiradosFiltros, SelectionGroup, PocessoComPrazoEmPeriodoFiltros, ProcessosDevedorEspecificoFiltros,
} from './PesquisaEspecialProcesso.styles';
import { getRequiredLabel, removeScrollFromBackgroundLayout } from '../../../../helpers/utils/utils';
import Grid24 from '../../../UI/Grid24';
import PeriodField from '../../../UI/Field/Period/PeriodField';
import MaskField from '../../../UI/Field/Mask/MaskField';
import useStyles from '../../../UI/Field/Period/PeriodField24.styles';
import DateField from '../../../UI/Field/Date/DateField';

const NAO_TRABALHADO = 'processosNaoTrabalhados';
const EXPIRADOS = 'processosProximosExpirarOuExpirados';
const PROCESSO_PRAZO_PERIODO = 'processoComPrazoEmPeriodo';
const PROCESSOS_DEVEDOR_ESPECIFICO = 'processosDevedorEspecifico';
const PROCESSO_ACORDO_VENCENDO_PERIODO = 'processoAcordoVencendoPeriodo';
const PROCESSOS_COM_ACORDO_QUITADOS_PERIODO = 'processosComAcordoQuitadosPeriodo';

export const PesquiaEspecialProcesso = React.forwardRef(({ items, pesquisar, changeButtonStatus }, ref) => {
  const [externalGroupRadios, setExternalGroupRadios] = useState('');
  const [dropdownValues, setDropdownValues] = useState(pesquisaProcessoController.makeDefaultDropdownSpecialdSearch());
  const [filtroProcessoComPrazo, setFiltroProcessoComPrazo] = useState(pesquisaProcessoController.makeDefaultFiltroProcessoComPrazo());
  const [filtroProcessoAcordoVencendoPeriodo, setFiltroProcessoAcordoVencendoPeriodo] = useState(pesquisaProcessoController.makeDefaultFiltroProcessoAcordoVencendoPeriodo());
  const [filtroProcessosComAcordoQuitadosPeriodo, setFiltroProcessosComAcordoQuitadosPeriodo] = useState(pesquisaProcessoController.makeDefaultFiltroProcessosComAcordoQuitadosPeriodo());
  const [, setLoading] = useState(false);
  const [documentFormat, setDocumentFormat] = useState(CPF_MASK);

  const stylesPeriod = useStyles();

  function changeExternalGroupRadios(e) {
    pesquisaProcessoController.lastSelectionSpecialSearch = e.target.value;
    setExternalGroupRadios(e.target.value);
  }

  const changeDropdown = useCallback((e) => {
    const { name, value } = e.target;
    setDropdownValues(old => pesquisaProcessoController.updateDropdownValue(old, name, value));
  }, []);

  const changeNumberKey = useCallback((e) => {
    const { name, value } = e.target;
    changeKeyHandler(e, KEY_NUMBER);
    changeButtonStatus([{ name, value }]);
  }, [changeButtonStatus]);

  const changeStringKey = useCallback((e) => {
    const { name, value } = e.target;
    changeKeyHandler(e, KEY_TEXT);
    changeButtonStatus([{ name, value }]);
  }, [changeButtonStatus]);

  const changeDocumentoStringKey = useCallback((e) => {
    const { name, value } = e.target;
    changeKeyHandler(e, KEY_TEXT);
    changeButtonStatus([{ name, value }]);
    if (value.length <= 11) {
      setDocumentFormat(CPF_MASK);
    } else {
      setDocumentFormat(CNPJ_MASK);
    }
  }, [changeButtonStatus, setDocumentFormat]);

  const onChangeFiltroProcessoComPrazo = useCallback((e) => {
    const { name, value } = e.target;
    if (value === '') {
      filtroProcessoComPrazo.inicioAgendamento.disabled = true;
      filtroProcessoComPrazo.fimAgendamento.disabled = true;
      filtroProcessoComPrazo.inicioAgendamento.value = '';
      filtroProcessoComPrazo.fimAgendamento.value = '';
    } else {
      filtroProcessoComPrazo.inicioAgendamento.disabled = false;
      filtroProcessoComPrazo.fimAgendamento.disabled = false;
    }
    setFiltroProcessoComPrazo(old => pesquisaProcessoController.updateFiltroProcessoComPrazo(old, name, value));
  }, [filtroProcessoComPrazo]);

  const onChangeFiltroProcessoAcordoVencendoPeriodo = useCallback((e) => {
    const { name, value } = e.target;
    setFiltroProcessoAcordoVencendoPeriodo(old => pesquisaProcessoController.updateFiltroProcessoAcordoVencendoPeriodo(old, name, value));
  }, []);

  const onChangeFiltroProcessosComAcordoQuitadosPeriodo = useCallback((e) => {
    const { name, value } = e.target;
    setFiltroProcessosComAcordoQuitadosPeriodo(old => pesquisaProcessoController.updateFiltroProcessosComAcordoQuitadosPeriodo(old, name, value));
  }, []);

  function reset() {
    setExternalGroupRadios('');
    setDropdownValues(pesquisaProcessoController.makeDefaultDropdownSpecialdSearch());
    setFiltroProcessoComPrazo(pesquisaProcessoController.makeDefaultFiltroProcessoComPrazo());
  }

  useEffect(() => {
    if (pesquisaProcessoController.isNotActiveSpecialSearch()) {
      return;
    }

    setDropdownValues(pesquisaProcessoController.makeDefaultDropdownSpecialdSearch());
    setExternalGroupRadios(pesquisaProcessoController.lastSelectionSpecialSearch);
  }, []);


  useEffect(removeScrollFromBackgroundLayout, []);

  useEffect(() => {
    changeButtonStatus();
  }, [dropdownValues, externalGroupRadios, changeButtonStatus]);

  const onFocusIdTipoAgendamento = useCallback(() => {
    setLoading(true);
    filtroProcessoComPrazo.idTipoAgendamento.error = false;
    filtroProcessoComPrazo.idTipoAgendamento.errorMessage = '';
    setLoading(false);
  }, [filtroProcessoComPrazo, setLoading]);

  const onFocusDataAgendamento = useCallback((e) => {
    const nome = e.target.name;
    setLoading(true);
    if (nome === 'inicioAgendamento') {
      filtroProcessoComPrazo.inicioAgendamento.error = false;
      filtroProcessoComPrazo.inicioAgendamento.errorMessage = '';
    }
    if (nome === 'fimAgendamento') {
      filtroProcessoComPrazo.fimAgendamento.error = false;
      filtroProcessoComPrazo.fimAgendamento.errorMessage = '';
    }
    setLoading(false);
  }, [filtroProcessoComPrazo, setLoading]);

  const onFocusDataVencimento = useCallback((e) => {
    const nome = e.target.name;
    setLoading(true);
    if (nome === 'inicioVencimento') {
      filtroProcessoAcordoVencendoPeriodo.inicioVencimento.error = false;
      filtroProcessoAcordoVencendoPeriodo.inicioVencimento.errorMessage = '';
    }
    if (nome === 'fimVencimento') {
      filtroProcessoAcordoVencendoPeriodo.fimVencimento.error = false;
      filtroProcessoAcordoVencendoPeriodo.fimVencimento.errorMessage = '';
    }
    setLoading(false);
  }, [filtroProcessoAcordoVencendoPeriodo, setLoading]);

  const onFocusDataQuitado = useCallback((e) => {
    const nome = e.target.name;
    setLoading(true);
    if (nome === 'inicioVencimento') {
      filtroProcessosComAcordoQuitadosPeriodo.inicioVencimento.error = false;
      filtroProcessosComAcordoQuitadosPeriodo.inicioVencimento.errorMessage = '';
    }
    if (nome === 'fimVencimento') {
      filtroProcessosComAcordoQuitadosPeriodo.fimVencimento.error = false;
      filtroProcessosComAcordoQuitadosPeriodo.fimVencimento.errorMessage = '';
    }
    setLoading(false);
  }, [filtroProcessosComAcordoQuitadosPeriodo, setLoading]);

  const submit = useCallback(async (e) => {
    setLoading(true);
    const response = await pesquisar(e);
    if (externalGroupRadios === PROCESSO_PRAZO_PERIODO) {
      if (response.error?.response?.data) {
        if (response.error?.response.data.validations) {
          response.error.response.data.validations.forEach((validation) => {
            if (validation.field === 'fimAgendamento') {
              filtroProcessoComPrazo.fimAgendamento.error = true;
              filtroProcessoComPrazo.fimAgendamento.errorMessage = validation.message;
            }
            if (validation.field === 'inicioAgendamento') {
              filtroProcessoComPrazo.inicioAgendamento.error = true;
              filtroProcessoComPrazo.inicioAgendamento.errorMessage = validation.message;
            }
            if (validation.field === 'idTipoAgendamento') {
              filtroProcessoComPrazo.idTipoAgendamento.error = true;
              filtroProcessoComPrazo.idTipoAgendamento.errorMessage = validation.message;
            }
          });
          toast.error('O Tipo e o Período de Agendamento devem ser informados.',
            { style: { width: '392px' } });
        } else {
          toast.error(response.error?.response.data.message, { style: { width: '392px' } });
        }
      }
    }

    if (externalGroupRadios === PROCESSOS_DEVEDOR_ESPECIFICO) {
      if (response.error?.response?.data) {
        toast.error(response.error?.response.data.message, { style: { width: '392px' } });
      }
    }

    if (externalGroupRadios === PROCESSO_ACORDO_VENCENDO_PERIODO) {
      if (response.error?.response?.data) {
        if (response.error?.response.data.validations) {
          response.error.response.data.validations.forEach((validation) => {
            if (validation.field === 'fimVencimento') {
              filtroProcessoAcordoVencendoPeriodo.fimVencimento.error = true;
              filtroProcessoAcordoVencendoPeriodo.fimVencimento.errorMessage = validation.message;
            }
            if (validation.field === 'inicioVencimento') {
              filtroProcessoAcordoVencendoPeriodo.inicioVencimento.error = true;
              filtroProcessoAcordoVencendoPeriodo.inicioVencimento.errorMessage = validation.message;
            }
          });
          toast.error('O Período de Vencimento deve ser informado.', { style: { width: '392px' } });
        } else {
          toast.error(response.error?.response.data.message, { style: { width: '392px' } });
        }
      }
    }

    if (externalGroupRadios === PROCESSOS_COM_ACORDO_QUITADOS_PERIODO) {
      if (response.error?.response?.data) {
        if (response.error?.response.data.validations) {
          response.error.response.data.validations.forEach((validation) => {
            if (validation.field === 'fimVencimento') {
              filtroProcessosComAcordoQuitadosPeriodo.fimVencimento.error = true;
              filtroProcessosComAcordoQuitadosPeriodo.fimVencimento.errorMessage = validation.message;
            }
            if (validation.field === 'inicioVencimento') {
              filtroProcessosComAcordoQuitadosPeriodo.inicioVencimento.error = true;
              filtroProcessosComAcordoQuitadosPeriodo.inicioVencimento.errorMessage = validation.message;
            }
          });
          toast.error('O Período de Agendamento deve ser informado.', { style: { width: '392px' } });
        } else {
          toast.error(response.error?.response.data.message, { style: { width: '392px' } });
        }
      }
    }

    setLoading(false);
  }, [filtroProcessosComAcordoQuitadosPeriodo, filtroProcessoAcordoVencendoPeriodo, filtroProcessoComPrazo, pesquisar, setLoading, externalGroupRadios]);

  return (
    <form ref={ref} onReset={reset} onSubmit={submit}>
      <SelectionGroup
        aria-label="pesquisa-especial"
        value={externalGroupRadios}
        onChange={changeExternalGroupRadios}
      >
        <RadioField
          name={MEUS_PROCESSOS}
          label="Meus Processos"
          value={MEUS_PROCESSOS}
        />

        <RadioField
          name="processoAcordoVencendoPeriodo"
          label="Processos com Acordos vencidos no período"
          value={PROCESSO_ACORDO_VENCENDO_PERIODO}
        />
        {externalGroupRadios === PROCESSO_ACORDO_VENCENDO_PERIODO && (
          <div style={{ marginLeft: '13px' }}>
            <PocessoComPrazoEmPeriodoFiltros>
              <div>
                <Grid24 xs={24} sm={24}>
                  <SelectorField
                    fullWidth
                    hasEmptyLabel
                    isForm
                    name={filtroProcessoAcordoVencendoPeriodo.idUsuarioNegociador.name}
                    label="Negociador"
                    items={items.negociadores}
                    value={filtroProcessoAcordoVencendoPeriodo.idUsuarioNegociador.value}
                    onChange={onChangeFiltroProcessoAcordoVencendoPeriodo}
                  />
                </Grid24>
              </div>
              <div className={stylesPeriod.root}>
                <Grid24 xs={24} sm={15} alignItems="flex-end" justify="space-between">
                  <Grid item sm={5}>
                    <DateField
                      maxDate={new Date()}
                      resultWithMask
                      name={filtroProcessoAcordoVencendoPeriodo.inicioVencimento.name}
                      value={filtroProcessoAcordoVencendoPeriodo.inicioVencimento.value}
                      error={filtroProcessoAcordoVencendoPeriodo.inicioVencimento.error}
                      errorMessage={filtroProcessoAcordoVencendoPeriodo.inicioVencimento.errorMessage}
                      onChange={onChangeFiltroProcessoAcordoVencendoPeriodo}
                      onFocus={onFocusDataVencimento}
                    />
                  </Grid>
                  <Grid item sm={2} className={stylesPeriod.centralizeText}>
                    <Typography component="p">até</Typography>
                  </Grid>
                  <Grid item sm={5}>
                    <DateField
                      maxDate={new Date()}
                      resultWithMask
                      name={filtroProcessoAcordoVencendoPeriodo.fimVencimento.name}
                      value={filtroProcessoAcordoVencendoPeriodo.fimVencimento.value}
                      error={filtroProcessoAcordoVencendoPeriodo.fimVencimento.error}
                      errorMessage={filtroProcessoAcordoVencendoPeriodo.fimVencimento.errorMessage}
                      onChange={onChangeFiltroProcessoAcordoVencendoPeriodo}
                      onFocus={onFocusDataVencimento}
                    />
                  </Grid>
                </Grid24>
              </div>
            </PocessoComPrazoEmPeriodoFiltros>
          </div>
        )}

        <RadioField
          name="processosComAcordoQuitadosPeriodo"
          label="Processos com a última Parcela quitada no período"
          value={PROCESSOS_COM_ACORDO_QUITADOS_PERIODO}
        />
        {externalGroupRadios === PROCESSOS_COM_ACORDO_QUITADOS_PERIODO && (
          <div style={{ marginLeft: '13px' }}>
            <PocessoComPrazoEmPeriodoFiltros>
              <div>
                <Grid24 xs={24} sm={24}>
                  <SelectorField
                    fullWidth
                    hasEmptyLabel
                    isForm
                    name={filtroProcessosComAcordoQuitadosPeriodo.idUsuarioNegociador.name}
                    label="Negociador"
                    items={items.negociadores}
                    value={filtroProcessosComAcordoQuitadosPeriodo.idUsuarioNegociador.value}
                    onChange={onChangeFiltroProcessosComAcordoQuitadosPeriodo}
                  />
                </Grid24>
              </div>
              <div className={stylesPeriod.root}>
                <Grid item sm={15} className={stylesPeriod.textCampoPeriodField}>
                  <label>Período de Agendamento*</label>
                </Grid>
                <Grid24 xs={24} sm={15} alignItems="flex-end" justify="space-between">
                  <Grid item sm={5}>
                    <DateField
                      maxDate={new Date()}
                      resultWithMask
                      name={filtroProcessosComAcordoQuitadosPeriodo.inicioVencimento.name}
                      value={filtroProcessosComAcordoQuitadosPeriodo.inicioVencimento.value}
                      error={filtroProcessosComAcordoQuitadosPeriodo.inicioVencimento.error}
                      errorMessage={filtroProcessosComAcordoQuitadosPeriodo.inicioVencimento.errorMessage}
                      onChange={onChangeFiltroProcessosComAcordoQuitadosPeriodo}
                      onFocus={onFocusDataQuitado}
                    />
                  </Grid>
                  <Grid item sm={2} className={stylesPeriod.centralizeText}>
                    <Typography component="p">até</Typography>
                  </Grid>
                  <Grid item sm={5}>
                    <DateField
                      maxDate={new Date()}
                      resultWithMask
                      name={filtroProcessosComAcordoQuitadosPeriodo.fimVencimento.name}
                      value={filtroProcessosComAcordoQuitadosPeriodo.fimVencimento.value}
                      error={filtroProcessosComAcordoQuitadosPeriodo.fimVencimento.error}
                      errorMessage={filtroProcessosComAcordoQuitadosPeriodo.fimVencimento.errorMessage}
                      onChange={onChangeFiltroProcessosComAcordoQuitadosPeriodo}
                      onFocus={onFocusDataQuitado}
                    />
                  </Grid>
                </Grid24>
              </div>
            </PocessoComPrazoEmPeriodoFiltros>
          </div>
        )}

        <RadioField
          name={MINHAS_REQUISICOES}
          label="Minhas Requisições"
          value={MINHAS_REQUISICOES}
        />
        <RadioField
          name="processosNaoTrabalhados"
          label="Processos não Trabalhados"
          value={NAO_TRABALHADO}
        />
        {externalGroupRadios === NAO_TRABALHADO && (
          <ProcessoNaoTrabalhadoFiltros>
            <div>
              <SelectorField
                fullWidth
                hasEmptyLabel
                isForm
                name="idFase"
                label="Fase"
                items={items.fases}
                value={dropdownValues.idFase}
                onChange={changeDropdown}
              />
            </div>
            <div>
              <SelectorField
                fullWidth
                hasEmptyLabel
                isForm
                name="idUsuarioNegociador"
                label="Negociador"
                items={items.negociadores}
                value={dropdownValues.idUsuarioNegociador}
                onChange={changeDropdown}
              />
            </div>
          </ProcessoNaoTrabalhadoFiltros>
        )}

        <RadioField
          name="processosProximosExpirarOuExpirados"
          label="Processos Expirados ou a Expirar"
          value={EXPIRADOS}
        />
        {externalGroupRadios === EXPIRADOS && (
          <ProcessoExpiradosFiltros>
            <GroupDefinitions
              aria-label="expirados-expirar"
              defaultValue={
                pesquisaProcessoController.filters?.processosExpirados
                || pesquisaProcessoController.filters?.processosProximosExpirar
                || 'processosExpirados'}
            >
              <RadioField
                name="processosExpirados"
                label="Processos Expirados"
                value="processosExpirados"
              />
              <RadioField
                name="processosProximosExpirar"
                label="Processos Próximos a Expirar"
                value="processosProximosExpirar"
              />
            </GroupDefinitions>
            <div>
              <InputField
                isForm
                fullWidth
                defaultValue={pesquisaProcessoController.filters?.idCliente}
                label="Cód. Cliente"
                name="idCliente"
                maxLength={9}
                onChange={changeNumberKey}
              />
            </div>
          </ProcessoExpiradosFiltros>
        )}
        <RadioField
          name="processosTitulosNaoVencidos"
          label="Processos somente com Títulos não Vencidos"
          value="naoVencidos"
        />

        <RadioField
          name={PROCESSOS_AJUIZADOS}
          label="Processos Ajuizados"
          value={PROCESSOS_AJUIZADOS}
        />
        <RadioField
          name="processosDevedorEspecifico"
          label="Processos de um Devedor Específico"
          value={PROCESSOS_DEVEDOR_ESPECIFICO}
        />
        {externalGroupRadios === PROCESSOS_DEVEDOR_ESPECIFICO && (
          <ProcessosDevedorEspecificoFiltros>
            <div>
              <Grid24 xs={24} sm={10}>
                <MaskField
                  isForm
                  fullWidth
                  label="CPF/CNPJ do Devedor"
                  name="cpfCnpjDevedor"
                  onChange={changeDocumentoStringKey}
                  format={documentFormat === CPF_MASK ? `${documentFormat}#` : documentFormat}
                />
              </Grid24>
            </div>
            <div>
              <InputField
                isForm
                fullWidth
                label="Nome do Devedor"
                name="nomeDevedor"
                maxLength={60}
                onChange={changeStringKey}
              />
            </div>
          </ProcessosDevedorEspecificoFiltros>
        )}
        <RadioField
          name="processoComPrazoEmPeriodo"
          label="Processos com Prazo em um período"
          value={PROCESSO_PRAZO_PERIODO}
        />
        {externalGroupRadios === PROCESSO_PRAZO_PERIODO && (
          <PocessoComPrazoEmPeriodoFiltros>
            <div>
              <Grid24 xs={24} sm={14}>
                <SelectorField
                  fullWidth
                  hasEmptyLabel
                  isForm
                  onFocus={onFocusIdTipoAgendamento}
                  name={filtroProcessoComPrazo.idTipoAgendamento.name}
                  label={getRequiredLabel('Tipo de Agendamento', true)}
                  items={items.tipoAgendamento}
                  value={filtroProcessoComPrazo.idTipoAgendamento.value}
                  onChange={onChangeFiltroProcessoComPrazo}
                  error={filtroProcessoComPrazo.idTipoAgendamento?.error}
                  errorMessage={filtroProcessoComPrazo.idTipoAgendamento?.errorMessage}
                />
              </Grid24>
            </div>
            <div>
              <Grid24 isFormContainer container xs={24} sm={24}>
                <Grid24 item xs={24} sm={14}>
                  <PeriodField
                    label={getRequiredLabel('Período de Agendamento', true)}
                    firstField={filtroProcessoComPrazo.inicioAgendamento}
                    lastField={filtroProcessoComPrazo.fimAgendamento}
                    onChange={onChangeFiltroProcessoComPrazo}
                    onFocus={onFocusDataAgendamento}
                  />
                </Grid24>
              </Grid24>
            </div>
          </PocessoComPrazoEmPeriodoFiltros>
        )}
      </SelectionGroup>
    </form>
  );
});
