import React from 'react';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Timeline from '@material-ui/lab/Timeline';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import { FaCircle, FaShoePrints } from 'react-icons/fa';
import { v4 as uuid } from 'uuid';
import { DetalheFase, SequenciaFase } from './FaseStyles';
import { getDateTimeMask } from '../../../helpers/utils/utils';

function DetalheFaseComponent({ fase, isFaseAtual }) {
  return (
    <DetalheFase isFaseAtual={isFaseAtual}>
      <div style={{ minWidth: '200px' }}>
        {isFaseAtual ? (
          <div style={{ display: 'flex' }}>
            <span style={{
              color: '#f52c2c',
              fontWeight: 'bold',
              fontSize: '12px',
              alignItems: 'center',
              marginRight: '2px',
            }}
            >
              Fase Atual:
            </span>
            <p>{fase.nome}</p>
          </div>
        ) : (
          <p>{fase.nome}</p>
        )}
        <p style={{ fontWeight: 'normal' }}>
          <span>Entrada:</span>
          <span>{getDateTimeMask(fase.dataInicio, true, true, true)}</span>
        </p>
        <p>
          <span>Trabalhado:</span>
          <span>{getDateTimeMask(fase.dataTrabalhoFase, true, true, true)}</span>
        </p>
        <p>
          <span>Saída:</span>
          <span>{getDateTimeMask(fase.dataTermino, true, true, true)}</span>
        </p>
      </div>
    </DetalheFase>
  );
}

function FaseProcessoComponent({ fasesTimeline }) {
  if (!fasesTimeline) {
    return null;
  }

  return (
    <Timeline position="alternate">
      {fasesTimeline.map((fase, i) => {
        if (i % 2 === 0) {
          // Construir para a direita.
          return (
            <SequenciaFase key={i + 1} isFaseAtual={i === 0}>
              <TimelineItem key={uuid}>
                <TimelineOppositeContent
                  sx={{ m: 'auto 0' }}
                  variant="body2"
                  color="text.secondary"
                />
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot color="primary">
                    {i === 0 ? <FaCircle /> : <FaShoePrints />}
                  </TimelineDot>
                  {i === fasesTimeline.length - 1 ? null : <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                  <DetalheFaseComponent
                    fase={fase}
                    isFaseAtual={i === 0}
                  />
                </TimelineContent>
              </TimelineItem>
            </SequenciaFase>
          );
        }
        return (
          <SequenciaFase key={i + 399} isFaseAtual={i === 0}>
            <TimelineItem key={uuid}>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                <DetalheFaseComponent
                  fase={fase}
                  isFaseAtual={i === 0}
                />
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                  {i === 0 ? <FaCircle /> : <FaShoePrints />}
                </TimelineDot>
                {i === fasesTimeline.length - 1 ? null : <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }} />
            </TimelineItem>
          </SequenciaFase>
        );
      })}
    </Timeline>
  );
}

export const FaseProcesso = React.memo(FaseProcessoComponent);
