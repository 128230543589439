import makeStyles from '@material-ui/core/styles/makeStyles';
import { blue } from '../../../helpers/constants/colors.constants';

export default makeStyles({
  checkedColor: {
    color: `${blue} !important`,
  },
  disbaledLabel: {
    color: 'black !important',
  },
});
