import React, { useCallback } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import TextareaField from '../../../../UI/Field/Textarea/TextareaField';
import useStyles from './inserirAnotacaoCliente.styles';
import Grid24 from '../../../../UI/Grid24/Grid24';
import IconButtonUI from '../../../../UI/Icon/IconButtonUI';
import { COPIAR_ANOTACAO, DATA_FOLLOW_UP, INSERIR_ANOTACAO } from '../acompanhamentoCliente.constants';
import CheckboxField from '../../../../UI/Field/Checkbox/CheckboxField';
import DateField from '../../../../UI/Field/Date/DateField';
import { useCrudClienteDispatch, useCrudClienteSelector } from '../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import {
  onChangeControls,
  setRequestFieldsAcompanhamentoErrorAction,
  setRequestFieldsAcompanhamentoValueAction,
  setScrollDirectionAction,
} from '../../../../../store/Cliente/V2/Crud/crudCliente.store';
import { insertAcompanhamentoClienteAction } from '../../../../../store/Cliente/V2/Crud/crudCliente.saga';


function InserirAnotacaoCliente({
  disabled, isCliente, onFocus, isEditMode,
}) {
  const styles = useStyles();
  const dispatch = useCrudClienteDispatch();

  const texto = useCrudClienteSelector(states => states.settingsAcompanhamento.requestFields.texto);
  const dataFollowUp = useCrudClienteSelector(states => states.settingsAcompanhamento.requestFields.dataFollowUp);
  const obrigatorioFollowUp = useCrudClienteSelector(states => states.settingsAcompanhamento.requestFields.obrigatorioFollowUp);
  const copiaAnotacoesEmpresasGrupo = useCrudClienteSelector(states => states.settingsAcompanhamento.requestFields.copiaAnotacoesEmpresasGrupo);
  const loadingInsertAcompanhamento = useCrudClienteSelector(states => states.settingsAcompanhamento.loadingInsertAcompanhamento);

  const changeHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setRequestFieldsAcompanhamentoValueAction(name, value));
  }, [dispatch]);

  const changeCheckedHandler = useCallback((event) => {
    const { name, checked } = event.target;
    dispatch(setRequestFieldsAcompanhamentoValueAction(name, checked));
  }, [dispatch]);

  const changeDateCheckedHandler = useCallback((event) => {
    const { name, checked } = event.target;
    dispatch(setRequestFieldsAcompanhamentoValueAction(name, checked));
    if (!checked) {
      dispatch(setRequestFieldsAcompanhamentoValueAction('dataFollowUp', ''));
      dispatch(setRequestFieldsAcompanhamentoErrorAction('dataFollowUp', false));
    }
  }, [dispatch]);

  const insertAcompanhamentoHandler = useCallback(() => {
    dispatch(onChangeControls('dataFu', dataFollowUp.value));
    dispatch(setScrollDirectionAction('UP'));
    dispatch(insertAcompanhamentoClienteAction(isCliente));
  }, [dispatch, isCliente, dataFollowUp]);

  return (
    <>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={22}>
          <TextareaField
            disabled={disabled}
            name={texto.name}
            value={texto.value}
            error={texto.error}
            errorMessage={texto.errorMessage}
            placeholder={INSERIR_ANOTACAO}
            classForm={styles.areaSize}
            onChange={changeHandler}
          />
        </Grid24>
        <Grid24 item xs={24} sm={2}>
          <IconButtonUI
            className={styles.iconButton}
            disabled={disabled || loadingInsertAcompanhamento || texto.value.trim().length === 0}
            onClick={insertAcompanhamentoHandler}
          >
            <SvgIcon className={styles.svgIcon}>
              <path d="M3.01 22.357l20.823-8.929L3.01 4.5 3 11.444l14.881 1.984L3 15.413z" />
            </SvgIcon>
          </IconButtonUI>
        </Grid24>
      </Grid24>
      <div className={styles.mainContainer}>
        <div className={styles.copiaAnotacoesContainer}>
          <CheckboxField
            disabled={disabled || (!isCliente && isEditMode)}
            label={COPIAR_ANOTACAO}
            name={copiaAnotacoesEmpresasGrupo.name}
            checked={copiaAnotacoesEmpresasGrupo.value}
            onChange={changeCheckedHandler}
          />
        </div>
        <div className={styles.dataContainer}>
          <CheckboxField
            disabled={disabled}
            label={DATA_FOLLOW_UP}
            name={obrigatorioFollowUp.name}
            checked={obrigatorioFollowUp.value}
            onChange={changeDateCheckedHandler}
          />
        </div>
        <div style={{ width: '122px' }}>
          <DateField
            formProps={{}}
            opens="top"
            fullWidth={false}
            disabled={!obrigatorioFollowUp.value || disabled}
            name={dataFollowUp.name}
            value={dataFollowUp.value}
            error={dataFollowUp.error}
            errorMessage={dataFollowUp.errorMessage}
            onChange={changeHandler}
            onFocus={onFocus}
          />
        </div>
      </div>
    </>
  );
}

export default InserirAnotacaoCliente;
