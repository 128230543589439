import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  title: {
    border: 'solid',
    borderWidth: 'thin',
    padding: '6px',
    paddingLeft: '10px',
    paddingTop: '4px',
    color: '#707070',
    width: '100%',
    cursor: 'default',
  },
  marginTopTitle: {
    marginTop: '7px',
  },
  componentDica: {
    display: 'inline-flex',
    position: 'relative',
    top: '2px',
    left: '6px',
  },
});
