import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { MdReceipt } from 'react-icons/md';
import { GiReceiveMoney } from 'react-icons/gi';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TableHeader from '../../models/TableHeader';
import OrdenacaoColuna from '../../components/UI/Tabela/Ordenacao/OrdenacaoColuna';
import { getMoneyMask, toBrazilianDate } from '../../helpers';
import TooltipUI from '../../components/UI/Tooltip/Tooltip';
import { blackTwo, lightGrey } from '../../helpers/constants/colors.constants';
import TbSettingsDollar from '../../assets/images/icons/TbSettingsDollar.svg';
import TbSettingsDollarDisabled from '../../assets/images/icons/TbSettingsDollarDisabled.svg';
import { TableActionButton } from '../../components/UI/Tabela/tableDefaultStyles';


class RecebimentoDevedoresFactory {
  makeCabecalhoTabela(
    columnOrdenacao, setColumnOrdenacao, arrowDirection, setArrowDirection, onClickColumnOrdenar,
  ) {
    return [
      new TableHeader({
        id: 'serRecebimentoManual',
        label: <OrdenacaoColuna
          columnLabel="Recebimento Manual"
          idColumn="serRecebimentoManual"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 90,
      }),
      new TableHeader({
        id: 'tipoFormaPagto',
        label: <OrdenacaoColuna
          columnLabel="Tipo Pagto"
          idColumn="tipoFormaPagto"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 120,
      }),
      new TableHeader({
        id: 'statusParcela',
        label: <OrdenacaoColuna
          columnLabel="Status da Parcela"
          idColumn="statusParcela"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 145,
      }),

      new TableHeader({
        id: 'dataVencimento',
        label: <OrdenacaoColuna
          columnLabel="Data do Vencimento"
          idColumn="dataVencimento"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 155,
      }),
      new TableHeader({
        id: 'valorParcela',
        label: <OrdenacaoColuna
          columnLabel="Valor Parcela"
          idColumn="valorParcela"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'right',
        maxWidth: 155,
      }),
      new TableHeader({
        id: 'dataPagamento',
        label: <OrdenacaoColuna
          columnLabel="Data Pagamento"
          idColumn="dataPagamento"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 155,
      }),
      new TableHeader({
        id: 'valorPago',
        label: <OrdenacaoColuna
          columnLabel="Valor Pago"
          idColumn="valorPago"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'right',
        maxWidth: 155,
      }),

      new TableHeader({
        id: 'valorDistribuido',
        label: <OrdenacaoColuna
          columnLabel="Valor Distribuído"
          idColumn="valorDistribuido"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 120,
      }),
      new TableHeader({
        id: 'acoes',
        label: 'Ações',
        alignCell: 'center',
        maxWidth: 75,
      }),
    ];
  }

  makeConteudoTabela(recebimentos, handleClickRecebimentoManual, handleClickComprovante, handleClickDistribuicaoManual) {
    if (!recebimentos || recebimentos.length === 0) {
      return [];
    }

    return recebimentos.map((recebimento, idx) => ({
      id: `${idx + recebimento.cliente + recebimento.numeroAcordo + recebimento.numeroParcela}`,
      values: {
        serRecebimentoManual: recebimento.serRecebimentoManual === 'S' ? <CheckCircleIcon style={{ fontSize: '22px' }} /> : '',
        tipoFormaPagto: recebimento.tipoPagamento,
        statusParcela: recebimento.statusParcela,
        dataVencimento: toBrazilianDate(recebimento.dataVencimento),
        valorParcela: getMoneyMask(recebimento.valorParcela),
        dataPagamento: toBrazilianDate(recebimento.dataPagamento),
        valorPago: getMoneyMask(recebimento.valorPago),
        numeroProcesso: recebimento.numeroProcesso,
        numeroAcordo: recebimento.numeroAcordo,
        numeroParcela: recebimento.numeroParcela,
        idAcordoParcelas: recebimento.idAcordoParcelas,
        cliente: recebimento.cliente,
        devedor: recebimento.devedor,
        valorDistribuido: getMoneyMask(recebimento.valorDistribuido),
        usuarioResponsavelPeloRecebimentoManual: recebimento.usuarioResponsavelPeloRecebimentoManual,
        idAcordo: recebimento.idAcordo,
        idAcordoParcela: recebimento.idAcordoParcela,
        possuiDocumento: recebimento.possuiDocumento,
        acoes: (
          <div style={{ display: 'flex', gap: '10px' }}>
            {recebimento.statusParcela === 'Quitada' && recebimento.possuiDocumento === 'Sim' ? (
              <TooltipUI title="Visualizar Comprovante">
                <IconButton
                  size="small"
                  onClick={() => handleClickComprovante(recebimento.numeroAcordo, recebimento.numeroProcesso, recebimento.numeroParcela)}
                >
                  <MdReceipt
                    size={17}
                    color={blackTwo}
                  />
                </IconButton>
              </TooltipUI>

            ) : (
              <IconButton
                size="small"
                disabled="true"
              >
                <MdReceipt
                  size={17}
                  color={lightGrey}
                />
              </IconButton>
            )}
            {recebimento.statusParcela === 'Aguardando Pagamento' ? (
              <TooltipUI title="Recebimento Manual">
                <IconButton
                  size="small"
                  onClick={() => handleClickRecebimentoManual(recebimento.idAcordo,
                    recebimento.numeroParcela, recebimento.numeroAcordo, recebimento.numeroProcesso, recebimento.tipoPagamento,
                    recebimento.valorParcela, recebimento.idAcordoParcela)}
                >
                  <GiReceiveMoney
                    size={17}
                    color={blackTwo}
                  />
                </IconButton>
              </TooltipUI>
            ) : (
              <IconButton
                size="small"
                disabled="true"
              >
                <GiReceiveMoney
                  size={17}
                  color={lightGrey}
                />
              </IconButton>
            )}
            {recebimento.statusParcela === 'Quitada' && recebimento.valorDistribuido === 'Não'
              ? (
                <TooltipUI title="Distribuição Manual">
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleClickDistribuicaoManual(recebimento.valorPago, recebimento.idAcordoParcela, recebimento.dataPagamento);
                    }}
                  >

                    <img
                      src={TbSettingsDollar}
                      alt=""
                      width="20px"
                      height="20px"
                    />
                  </IconButton>
                </TooltipUI>
              )
              : (
                <TooltipUI>
                  <IconButton
                    size="small"
                    disabled="true"
                  >
                    <TableActionButton disabled>
                      <img
                        src={TbSettingsDollarDisabled}
                        alt=""
                        width="20px"
                        height="20px"
                      />
                    </TableActionButton>
                  </IconButton>
                </TooltipUI>
              )
            }

          </div>
        ),
      },
    }));
  }
}

export const recebimentoDevedoresFactory = new RecebimentoDevedoresFactory();
