import React from 'react';
import { Typography } from '@material-ui/core';
import { IoMdCheckmark } from 'react-icons/io';
import { FaHandshake } from 'react-icons/fa';
import { AiFillCalculator } from 'react-icons/ai';
import { HiClock } from 'react-icons/hi';
import handshakeWhite from '../../../assets/images/icons/handShakeWhite.svg';
import useStyle from './AcordosStyle';

function InfoAdicionaisAcordo({ conteudoCards }) {
  const styles = useStyle();
  return (
    <div className={styles.div__root}>
      <div className={styles.estatisticas__cards}>
        <div className={styles.cards__external__root}>
          <div className={styles.cards__quitados}>
            <div className={styles.cards__internal__root}>
              <div className={styles.icon__quitados}>
                <IoMdCheckmark />
              </div>
              <div className={styles.cards__infos__root}>
                <Typography component="p" className={styles.infos__title}>
                  Acordos Quitados
                </Typography>
                <div className={styles.infos__counter}>
                  {conteudoCards.qtdAcordosQuitados}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.cards__external__root}>
          <div className={styles.cards__ativos}>
            <div className={styles.cards__internal__root}>
              <div className={styles.icon__ativos}>
                <FaHandshake />
              </div>
              <div className={styles.cards__infos__root}>
                <Typography component="p" className={styles.infos__title}>
                  Acordos Ativos
                </Typography>
                <div className={styles.infos__counter}>
                  {conteudoCards.qtdAcordosAtivos}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.cards__external__root}>
          <div className={styles.cards__quebrados}>
            <div className={styles.cards__internal__root}>
              <div className={styles.icon__quebrados}>
                <img src={handshakeWhite} alt="" width="15px" height="14px" />
              </div>
              <div className={styles.cards__infos__root}>
                <Typography component="p" className={styles.infos__title}>
                  Acordos Quebrados
                </Typography>
                <div className={styles.infos__counter}>
                  {conteudoCards.qtdAcordosQuebrados}
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className={styles.cards__external__root}>
          <div className={styles.cards__simulacoes}>
            <div className={styles.cards__internal__root}>
              <div className={styles.icon__simulacoes}>
                <AiFillCalculator />
              </div>
              <div className={styles.cards__infos__root}>
                <Typography component="p" className={styles.infos__title}>
                  Simulações
                </Typography>
                <div className={styles.infos__counter}>
                  {conteudoCards.qtdAcordosSimulados}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.cards__external__root}>
          <div className={styles.cards__simulacoesPendentes}>
            <div className={styles.cards__internal__root}>
              <div className={styles.icon__simulacoesPendentes}>
                <HiClock color="white" />
              </div>
              <div className={styles.cards__infos__root}>
                <Typography component="p" className={styles.infos__titleMargin8}>
                  Propostas
                </Typography>
                <div className={styles.infos__counter}>
                  {conteudoCards.qtdAcordosSimulacoesPendentes}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoAdicionaisAcordo;
