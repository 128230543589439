import { getUriParams, toAmericanDate } from '../../helpers';
import {
  CORE, RELATORIOS, gateway,
} from '../../services/api';

export default {
  getInfoCards() {
    return gateway.get(`${CORE}/recebimento-devedor/buscar-informacao-cards`);
  },

  getDefaultDadosTabela() {
    return gateway.get(`${CORE}/recebimento-devedor/buscar-dados-default-grid`);
  },

  getDadosTabelaAvançada(formFields, pageable, order) {
    const body = {
      dataPagamento: toAmericanDate(formFields?.dataPagamento?.value),
      dataVencimento: toAmericanDate(formFields?.dataVencimento?.value),
      idTipoFormaPagamento: formFields?.idTipoFormaPagamento?.value,
      nomeCliente: formFields?.nomeCliente?.value,
      nomeDevedor: formFields?.nomeDevedor?.value,
      somenteParcelasAguardandoPagamento: formFields?.somenteParcelasAguardandoPagamento?.value,
      somenteParcelasrecebidasNaoDistribuidas: formFields?.somenteParcelasrecebidasNaoDistribuidas?.value,
      valorDistribuido: formFields?.valorDistribuido?.value,
    };

    const sortList = order[0].split(',');

    if (sortList[0] === 'tipoFormaPagto') {
      sortList[0] = 'TPF.descricao';
    }

    order[0] = `${sortList[0]},${sortList[1]}`;

    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    const queryParam = getUriParams(pageable);

    return gateway.post(`${CORE}/recebimento-devedor/pesquisa-avancada-dados-grid?${queryParam}${sortedQuery}`, body);
  },

  getAtualizarParcelas(formFields, tipoPesquisa, idAcordoParcela) {
    const body = {
      dataVencimento: toAmericanDate(formFields?.dataVencimento?.value),
      idAcordoParcela,
      tipoPesquisa,
    };

    return gateway.post(`${CORE}/recebimento-devedor/atualizar-parcelas`, body);
  },

  getAtualizarParcelasLote(formFields, tipoPesquisa, idAcordoParcelasLote) {
    const body = {
      dataVencimento: toAmericanDate(formFields?.dataVencimento?.value),
      idAcordoParcelasLote,
      tipoPesquisa,
    };

    return gateway.post(`${CORE}/recebimento-devedor/atualizar-parcelas/lote`, body);
  },

  getDadosTabelaSimples(formFields, pageable, order) {
    const body = {
      dataVencimento: toAmericanDate(formFields.dataVencimento.value),
      somenteParcelasAguardandoPagamento: formFields.somenteParcelasAguardandoPagamento.value,
      somenteParcelasrecebidasNaoDistribuidas: formFields.somenteParcelasrecebidasNaoDistribuidas.value,
    };

    const sortList = order[0].split(',');

    if (sortList[0] === 'tipoFormaPagto') {
      sortList[0] = 'TPF.descricao';
    }

    order[0] = `${sortList[0]},${sortList[1]}`;

    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    const queryParam = getUriParams(pageable);

    return gateway.post(`${CORE}/recebimento-devedor/pesquisar-dados-grid?${queryParam}${sortedQuery}`, body);
  },

  imprimirParcelas(formFields, tipoPesquisa, order) {
    const body = {
      dataPagamento: toAmericanDate(formFields?.dataPagamento?.value),
      dataVencimento: toAmericanDate(formFields?.dataVencimento?.value),
      idTipoFormaPagamento: formFields?.idTipoFormaPagamento?.value,
      nomeCliente: formFields?.nomeCliente?.value,
      nomeDevedor: formFields?.nomeDevedor?.value,
      somenteParcelasAguardandoPagamento: formFields?.somenteParcelasAguardandoPagamento?.value,
      somenteParcelasrecebidasNaoDistribuidas: formFields?.somenteParcelasrecebidasNaoDistribuidas?.value,
      valorDistribuido: formFields?.valorDistribuido?.value,
      tipoPesquisa,
    };

    const sortList = order[0].split(',');

    if (sortList[0] === 'tipoFormaPagto') {
      sortList[0] = 'tipoPagamento';
    }

    order[0] = `${sortList[0]},${sortList[1]}`;

    const sortedQuery = order.reduce((obj, sort) => `${obj}sort=${sort}`, '');

    return gateway.post(`${RELATORIOS}/recebimentos/devedores/imprimir-parcelas?${sortedQuery}`, body, {
      responseType: 'blob',
    });
  },

  exportar(formFields, tipoPesquisa, order) {
    const body = {
      dataPagamento: toAmericanDate(formFields?.dataPagamento?.value),
      dataVencimento: toAmericanDate(formFields?.dataVencimento?.value),
      idTipoFormaPagamento: formFields?.idTipoFormaPagamento?.value,
      nomeCliente: formFields?.nomeCliente?.value,
      nomeDevedor: formFields?.nomeDevedor?.value,
      somenteParcelasAguardandoPagamento: formFields?.somenteParcelasAguardandoPagamento?.value,
      somenteParcelasrecebidasNaoDistribuidas: formFields?.somenteParcelasrecebidasNaoDistribuidas?.value,
      valorDistribuido: formFields?.valorDistribuido?.value,
      tipoPesquisa,
    };

    const sortList = order[0].split(',');

    if (sortList[0] === 'tipoFormaPagto') {
      sortList[0] = 'tipoPagamento';
    }

    order[0] = `${sortList[0]},${sortList[1]}`;

    const sortedQuery = order.reduce((obj, sort) => `${obj}sort=${sort}`, '');

    return gateway.post(`${RELATORIOS}/recebimentos/devedores/exportar-xlsx?${sortedQuery}`, body, {
      responseType: 'blob',
    });
  },

  inserirRecebimentoManual(quitarIntegralmenteParcela, comprovanteRecebimento, nomeComprovante,
    dataPagamento, valorPago, idAcordo, nroParcela, numeroProcesso, numeroAcordo, tipoFormaPagto, valorParcela, idAcordoParcela) {
    const data = new FormData();
    if (comprovanteRecebimento === null) {
      data.set('quitarParcelaIntegralmente', quitarIntegralmenteParcela ? 'S' : 'N');
      data.set('dataPagamento', toAmericanDate(dataPagamento.value));
      data.set('valorPago', valorPago.value);
      data.set('idAcordo', idAcordo);
      data.set('nroParcela', nroParcela);
      data.set('numeroProcesso', numeroProcesso);
      data.set('numeroAcordo', numeroAcordo);
      data.set('tipoFormaPagto', tipoFormaPagto);
      data.set('valorParcela', valorParcela);
      data.set('idAcordoParcela', idAcordoParcela);
    } else {
      data.set('quitarParcelaIntegralmente', quitarIntegralmenteParcela ? 'S' : 'N');
      data.set('comprovanteRecebimento', comprovanteRecebimento);
      data.set('nomeComprovante', nomeComprovante);
      data.set('dataPagamento', toAmericanDate(dataPagamento.value));
      data.set('valorPago', valorPago.value);
      data.set('idAcordo', idAcordo);
      data.set('nroParcela', nroParcela);
      data.set('numeroProcesso', numeroProcesso);
      data.set('numeroAcordo', numeroAcordo);
      data.set('tipoFormaPagto', tipoFormaPagto);
      data.set('valorParcela', valorParcela);
      data.set('idAcordoParcela', idAcordoParcela);
    }
    return gateway.post(`${CORE}/recebimento-devedor/recebimento-manual`, data);
  },

  clickComprovanteHandler(numeroAcordo, numeroProcesso, numeroParcela) {
    const body = {
      numeroAcordo,
      numeroProcesso,
      nroParcela: numeroParcela,
    };

    return gateway.post(`${CORE}/recebimento-devedor/recebimento-manual/comprovante`, body);
  },

  submitDistribuicaoManual(valorRepasse, valorTotalRecebimento, idAcordoParcela, dataPagamento) {
    const body = {
      valorTotalRecebimento, repasseAoCliente: valorRepasse, idAcordoParcela, dataPagamento,
    };

    return gateway.post(`${CORE}/recebimento-devedor/distribuicao-manual`, body);
  },

  buscarUltimaDataHoraAtualizacao() {
    return gateway.get(`${CORE}/recebimento-devedor/buscar-ultima-data-hora-atualizacao`);
  },
};
