import styled from 'styled-components';

import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  blueGrey, warmGrey,
} from '../../../helpers/constants/colors.constants';

export const Container = styled.div`
  opacity: 1 !important;
  background-color: ${props => props.backgroundColor} !important;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;

  .Mui-selected {
    background-color: ${props => props.tabSelectedBackground} !important;
    color: ${warmGrey};
    font-weight: bold !important;
    letter-spacing: 0.49px;
    font: "Roboto-Bold";
  }

  .MuiTab-root {
    color: ${warmGrey};
    font: "Roboto-Regular";
    padding: 10px 20px !important;
    min-width: 41px;
    margin-right: 3px;
    text-transform: none;
    font-size: 12px;
    line-height: 1.19;
    font-weight: normal;
    min-height: 41px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background-color: ${blueGrey};
    opacity: 1;
  }

  .MuiTabs-indicator {
    background-color: white;
  }

`;

export default makeStyles({

  ajustWhiteSpace: {
    whiteSpace: 'nowrap',
  },
  bsNone: {
    boxShadow: 'none',
  },
  separator: {
    boxShadow: 'none',
    marginTop: '20px',
  },
});
