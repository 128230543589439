import makeStyles from '@material-ui/core/styles/makeStyles';
import { black, warmGrey } from '../../../../helpers/constants/colors.constants';

export default makeStyles({
  icon__relatorios: props => ({
    '& i': {
      cursor: props.habilitarRelatorio ? 'pointer' : 'default',
      fontSize: '18px',
      color: props.habilitarRelatorio ? black : warmGrey,
    },
  }),
});
