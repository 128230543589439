import { Tab, Tabs } from '@material-ui/core';
import React from 'react';

import { Container } from './TabUi.styles';
import { paleGrey, white } from '../../../helpers/constants/colors.constants';

function TabsUiComponent({
  items, containerBackground = paleGrey, tabSelectedBackground = white, ...props
}) {
  return (
    <Container backgroundColor={containerBackground} tabSelectedBackground={tabSelectedBackground}>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        {...props}
      >
        {items.map(item => (
          <Tab
            key={item.name}
            id={item.name}
            label={item.name}
            {...item.props}
          />
        ))}
      </Tabs>
    </Container>
  );
}

export const TabsUi = React.memo(TabsUiComponent);
