import React from 'react';
import Grid24 from '../../../../../../UI/Grid24/Grid24';
import CheckboxField from '../../../../../../UI/Field/Checkbox/CheckboxField';
import useUtilStyles from '../../../../../../../helpers/styles/utils.styles';
import {
  CHECKBOX_INCLUIR_PROCESSOS_PRODUCAO_BORDEROS,
  CHECKBOX_TITULOS_ANTIGOS_LABEL,
  CHECKBOX_TITULOS_VALORES_BAIXOS_LABEL,
  CHECKBOX_TRATAR_COD_DEFESA_CONSUMIDOR_LABEL,
} from './processamentoCheckbox.constants';
import { useCrudClienteSelector } from '../../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';


function ProcessamentoCheckbox({ onChangeChecked, disabled }) {
  const utilStyle = useUtilStyles();

  const protegerCodigoConsumidor = useCrudClienteSelector(states => states.controls.protegerCodigoConsumidor.copy());
  const processarTitulosAntigos = useCrudClienteSelector(states => states.controls.processarTitulosAntigos.copy());
  const processarValoresBaixos = useCrudClienteSelector(states => states.controls.processarValoresBaixos.copy());
  const incluirNaProducao = useCrudClienteSelector(states => states.controls.incluirNaProducao.copy());

  return (
    <>
      <Grid24 isFormContainer container spacing="1" className={utilStyle.mt16}>
        <Grid24 xs={24} sm={4} className={utilStyle.ml8}>
          <CheckboxField
            label={CHECKBOX_TITULOS_ANTIGOS_LABEL}
            name={processarTitulosAntigos.name}
            checked={processarTitulosAntigos.value}
            onChange={onChangeChecked}
            disabled={disabled}
          />
        </Grid24>
        <Grid24 xs={24} sm={5}>
          <CheckboxField
            label={CHECKBOX_TITULOS_VALORES_BAIXOS_LABEL}
            name={processarValoresBaixos.name}
            checked={processarValoresBaixos.value}
            onChange={onChangeChecked}
            disabled={disabled}
          />
        </Grid24>
        <Grid24 xs={24} sm={5}>
          <CheckboxField
            label={CHECKBOX_INCLUIR_PROCESSOS_PRODUCAO_BORDEROS}
            name={incluirNaProducao.name}
            checked={incluirNaProducao.value}
            onChange={onChangeChecked}
            disabled={disabled}
          />
        </Grid24>
        <Grid24 xs={24} sm={7} style={{ marginLeft: '10px' }}>
          <CheckboxField
            label={CHECKBOX_TRATAR_COD_DEFESA_CONSUMIDOR_LABEL}
            name={protegerCodigoConsumidor.name}
            checked={protegerCodigoConsumidor.value}
            onChange={onChangeChecked}
            disabled={disabled}
          />
        </Grid24>
      </Grid24>
    </>
  );
}

export default ProcessamentoCheckbox;
