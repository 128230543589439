import FieldModel from '../../models/FieldModel';
import ChangeAttrModel from '../../models/ChangeAttrModel';
import { getNotEmptyValues } from '../utils/utils';

export const ID_STATUS_USUARIO_ATIVO = 'A';
export const ID_STATUS_USUARIO_INATIVO = 'I';

function validateEnderecoAtributes(atributes) {
  return (
    atributes && ((atributes instanceof FieldModel && atributes.getValueNotEmpty()) || atributes.value !== '')
  );
}

export const initialStatesTelefone = (
  args = {
    principal: false,
    tipoTelefone: '',
    ddd: '',
    numero: '',
    permitirWhatsApp: false,
    observacao: '',
    idTelefone: undefined,
    idEntidadeTelefone: undefined,
  },
) => ({
  idTelefone: new FieldModel({
    name: 'idTelefone',
    value: args.idTelefone,
  }),
  idEntidadeTelefone: new FieldModel({
    name: 'idEntidadeTelefone',
    value: args.idEntidadeTelefone,
  }),
  principal: new FieldModel({
    name: 'principal',
    value: args.principal || false,
  }),
  tipoTelefone: new FieldModel({
    name: 'tipoTelefone',
    value: args.tipoTelefone || '',
  }),
  ddd: new FieldModel({
    name: 'ddd',
    value: args.ddd || '',
    changeAttr: new ChangeAttrModel({ maxLength: 2, isPositiveInteger: true }),
  }),
  numero: new FieldModel({
    name: 'numero',
    value: args.numero || '',
    changeAttr: new ChangeAttrModel({ maxLength: 11, isPositiveInteger: true }),
  }),
  permitirWhatsApp: new FieldModel({
    name: 'permitirWhatsApp',
    value: args.permitirWhatsApp || false,
  }),
  observacao: new FieldModel({
    name: 'observacao',
    value: args.observacao || '',
    changeAttr: new ChangeAttrModel({ maxLength: 60 }),
  }),
});

export function initiateEmail(
  args = {
    email: '',
    permitirPadrao: true,
    permitirBoleto: false,
    permitirNotaFiscal: false,
    idClienteEmail: undefined,
    permitirPrestacaoConta: false,
  },
) {
  return {
    idClienteEmail: new FieldModel({
      name: 'idClienteEmail',
      value: args.idClienteEmail,
    }),
    email: new FieldModel({
      name: 'email',
      value: args.email || '',
      changeAttr: new ChangeAttrModel({ maxLength: 70 }),
    }),
    permitirPadrao: new FieldModel({
      name: 'permitirPadrao',
      value: args.permitirPadrao || false,
    }),
    permitirBoleto: new FieldModel({
      name: 'permitirBoleto',
      value: args.permitirBoleto || false,
    }),
    permitirNotaFiscal: new FieldModel({
      name: 'permitirNotaFiscal',
      value: args.permitirNotaFiscal || false,
    }),
    permitirPrestacaoConta: new FieldModel({
      name: 'permitirPrestacaoConta',
      value: args.permitirPrestacaoConta || false,
    }),
  };
}

export function initiateEmails(emails) {
  if (!emails) {
    return [];
  }
  return emails.map(initiateEmail);
}

export function buildEmails(emails) {
  return emails.map(email => ({
    idClienteEmail: email.idClienteEmail,
    email: email.email.getValueNotEmpty(),
    permitirPadrao: email.permitirPadrao.getValueNotEmpty(),
    permitirBoleto: email.permitirBoleto.getValueNotEmpty(),
    permitirNotaFiscal: email.permitirNotaFiscal.getValueNotEmpty(),
    permitirPrestacaoConta: email.permitirPrestacaoConta.getValueNotEmpty(),
  }));
}
// Deprected
export const initialStatesStatus = [
  { id: 'A', value: 'Ativo' },
  { id: 'I', value: 'Inativo' },
];

export const initiateTiposDocumento = [
  { id: 'CPF', value: 'CPF' },
  { id: 'CNPJ', value: 'CNPJ' },
];

export const buildTelefones = telefones => telefones.map(telefone => ({
  idEntidadeTelefone: telefone.idEntidadeTelefone,
  idTelefone: telefone.idTelefone,
  tipoTelefone: getNotEmptyValues(telefone.tipoTelefone.value),
  ddd: getNotEmptyValues(telefone.ddd.value.trim()),
  numero: getNotEmptyValues(telefone.numero.value.trim()),
  observacao: getNotEmptyValues(telefone.observacao.value.trim()),
  permitirWhatsApp: telefone.permitirWhatsApp.value,
  principal: telefone.principal.value,
}));

export const buildEndereco = (endereco) => {
  const keys = Object.keys(endereco);
  let isValidEndereco = false;
  keys.forEach((key) => {
    if (validateEnderecoAtributes(endereco[key])) {
      isValidEndereco = true || isValidEndereco;
    }
  });
  if (!isValidEndereco) {
    return null;
  }
  return {
    idEndereco: endereco.idEndereco,
    endereco: endereco.endereco.getValueNotEmpty(),
    estado: endereco.estado.getValueNotEmpty(),
    numero: endereco.numero.getValueNotEmpty(),
    complemento: endereco.complemento.getValueNotEmpty(),
    bairro: endereco.bairro.getValueNotEmpty(),
    cidade: endereco.cidade.getValueNotEmpty(),
    cep: endereco.cep.getValueNotEmpty(),
  };
};


export function buildSnackbar(open = false, variant = 'error', message = '') {
  return { open, variant, message };
}

export function buildDialog(open = false, variant = 'error', message = '', origin) {
  return {
    open, variant, message, origin,
  };
}

export function buildOptinalDialog(open = false, message = '') {
  return {
    open, message,
  };
}

export function buildSettingsTable(content = [], page = 0, rowsPerPage = 10, size = 0) {
  return {
    page, size, rowsPerPage, content,
  };
}

export function buildSelectorStatus() {
  return ([
    { id: ID_STATUS_USUARIO_ATIVO, value: 'Ativo' },
    { id: ID_STATUS_USUARIO_INATIVO, value: 'Inativo' },
  ]);
}

export function buildSelectorTipoConsultaDinamica() {
  return [
    { id: 'PUBLICA', value: 'Pública' },
    { id: 'RESTRITA', value: 'Restrita' },
  ];
}
