import styled from 'styled-components';
import { Button, Typography } from '@material-ui/core';
import SelectorField from '../../../components/UI/Field/Selector/SelectorField';
import PaperUI from '../../../components/UI/Paper/PaperUI';

export const Container = styled(PaperUI)`
  padding: 21px 31px;
  display: flex;
  flex-direction: column;
`;

export const SimpleSearchContainer = styled.form`
  display: flex;
  flex-direction: row-reverse;

  > div {
    padding-right: 10px;
  }
`;

export const ComplementarySearchButton = styled(Button)`
  font-weight: bold;
  text-transform: none;
  font-size: 12px;
  color: #034ddc;

  align-self: flex-end;
  margin-right: 44px;
`;

export const Table = styled.div``;

export const SelectorInputField = styled(SelectorField)`
  margin-right: 8px;
`;

export const TableTitle = styled(Typography)`
  margin-top: 14px;
  margin-left: 8px;
  height: 42px;
  font-size: 16px;
  font-weight: bold;
`;
