import { TableRow, Tooltip } from '@material-ui/core';
import React, { useCallback } from 'react';
import { FaFileDownload, FaCheckDouble } from 'react-icons/fa';
import { IoMdEye } from 'react-icons/io';
import {
  atualizarLidoAutorizacaoRepasseHandlerAction, downloadArquivoAutorizacaoRepasseHandlerAction,
} from '../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import { useCCClienteDispatch } from '../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import {
  TableActionButton, TableCellCenter, TableCellLeft, TableCellRight, TableActionContainer,
} from '../UI/Tabela/tableDefaultStyles';
import TooltipUI from '../UI/Tooltip/Tooltip';


function TableBodyAutorizacoesRepassesComponent({ content, permissoes }) {
  const dispatch = useCCClienteDispatch();

  const alterarStatusDeLido = useCallback((idAutorizacaoRepasse) => {
    dispatch(atualizarLidoAutorizacaoRepasseHandlerAction(idAutorizacaoRepasse));
  }, [dispatch]);

  const downloadArquivo = useCallback(() => {
    if (content.valorOutros) {
      dispatch(downloadArquivoAutorizacaoRepasseHandlerAction(content.idAutorizacaoRepasse, 'EXCEL'));
      return;
    }
    dispatch(downloadArquivoAutorizacaoRepasseHandlerAction(content.idAutorizacaoRepasse, 'TXT'));
  }, [dispatch, content.idAutorizacaoRepasse, content.valorOutros]);

  const handlePrintAutorizacao = useCallback(async (idPrestacaoContaCliente) => {
    if (content.valorOutros) {
      window.open(`/conta-corrente-cliente/REPASSE/${idPrestacaoContaCliente}/imprimir`, '_blank');
    } else {
      window.open(`/conta-corrente-cliente/SISPAG/${idPrestacaoContaCliente}/imprimir`, '_blank');
    }
  }, [content.valorOutros]);

  return (
    <TableRow>
      <TableCellCenter>
        {content.idAutorizacaoRepasse}
      </TableCellCenter>
      <TableCellCenter>
        {content.dataLiberacao}
      </TableCellCenter>
      <TableCellLeft>
        <TooltipUI title={content.nomeUsuario}>
          <span>
            {content.nomeUsuario}
          </span>
        </TooltipUI>
      </TableCellLeft>
      <TableCellCenter>
        {content.dataLeitura}
      </TableCellCenter>
      <TableCellRight>
        {content.valorSispag}
      </TableCellRight>
      <TableCellRight>
        {content.valorOutros}
      </TableCellRight>
      <TableCellCenter>
        <TableActionContainer>
          {!permissoes.baixarVisualizarSispagPlanilha ? (
            <TableActionButton
              disabled
            >
              <FaFileDownload size={16} />
            </TableActionButton>
          ) : (
            <Tooltip title="Download" placement="bottom">
              <TableActionButton
                onClick={() => downloadArquivo(content)}
              >
                <FaFileDownload size={16} />
              </TableActionButton>
            </Tooltip>
          )}
          {!permissoes.baixarVisualizarSispagPlanilha ? (
            <TableActionButton
              disabled
            >
              <IoMdEye size={20} />
            </TableActionButton>
          ) : (
            <Tooltip title="Visualização" placement="bottom">
              <TableActionButton
                onClick={() => handlePrintAutorizacao(content.idAutorizacaoRepasse)}
              >
                <IoMdEye size={20} />
              </TableActionButton>
            </Tooltip>
          )}
          {content.lido && (
            <Tooltip title="Retirar leitura" placement="bottom">
              <TableActionButton
                disabled={!permissoes.baixarVisualizarSispagPlanilha}
                onClick={() => alterarStatusDeLido(content.idAutorizacaoRepasse)}
              >
                <FaCheckDouble size={14} />
              </TableActionButton>
            </Tooltip>
          )}
        </TableActionContainer>
      </TableCellCenter>
    </TableRow>
  );
}

export const TableBodyAutorizacoesRepasses = TableBodyAutorizacoesRepassesComponent;
