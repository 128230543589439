import { makeStyles } from '@material-ui/core';


export default makeStyles({
    style__checkbox: {
        paddingTop: '15px',
        '& label': {
            marginLeft: '8px',
        },
    },
});
