import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
  Background,
  Drawer, MobileActions, NavBar, UserNavBar,
} from './SideDrawerNavigationStyles';
import { NavigationLink } from '../NavigationLink/NavigationLink';
import { NavigationDropdown } from '../NavigationDropdown/NavigationDropdown';
import { obterFotoUsuario } from '../../services/core/usuario/usuario.services';
import withTokenInterceptor from '../../hoc/withTokenInterceptor/withTokenInterceptor';
import { ActionsNavBar } from '../NavBar/ActionsNavBar';
import { hasUserPermissions } from '../../helpers/utils/permissoes';
import { menuRoutes } from '../../routes/menuRoutes';

import defaultAvatar from '../../assets/images/default-avatar.jpg';

import { resetPesquisaRamoAtividadeRedux } from '../../store/RamoAtividade/Pesquisa/pesquisaRamoAtividade.store';
import { clearPesquisarUsuarioAction } from '../../store/Usuario/Pesquisa/pesquisarUsuario.store';
import { clearPesquisarClienteAction } from '../../store/Cliente/Pesquisar/pesquisaCliente.store';
import { clearProcessarBorderoAction } from '../../store/Borderos/Processar/processarBorderos.store';
import { clearPesquisaFilialAction } from '../../store/Filial/Pesquisar/pesquisarFilial.store';
import { clearSelectorsAction } from '../../store/Global/LoadSelectors/loadSelectors.store';
import { clearImportarBorderosAction } from '../../store/Borderos/Importar/importarBordero.store';
import { clearPesquisaHistoricoASAction } from '../../store/AcordoSeguro/Historico/historicoAS.store';
import { clearPesquisaAcordosASAction } from '../../store/AcordoSeguro/Acordos/acordosAs.store';
import { pesquisarAcordoASEntrarTelaAction } from '../../store/AcordoSeguro/Acordos/acordosAs.saga';
import { pesquisarHistoricoASEntrarTelaAction } from '../../store/AcordoSeguro/Historico/historicoAS.saga';
import { pesquisaProcessoController } from '../../controller/pesquisarProcessos.controller';
import { clearAllStatesFromPageAction } from '../../store/MenuAccessPage/menuAccessPageActions';


function hasNotUserPermissions(userPermissions) {
  if (userPermissions) {
    return !hasUserPermissions(...userPermissions || []);
  }
  return false;
}

function getUserAttribute(state, attributeName) {
  if (!state?.authentication?.userInformation) {
    return '';
  }
  return state?.authentication?.userInformation[attributeName] || '';
}

function makeLink({
  pathItem, minimized, isDarkMode, handleClickLink,
}) {
  if (hasNotUserPermissions(pathItem.userPermissions)) {
    return null;
  }

  return (
    <NavigationLink
      key={pathItem.text}
      Icon={pathItem?.Icon || null}
      to={pathItem.to}
      newTab={pathItem?.newTab}
      text={pathItem.text}
      iconColor={pathItem?.iconColor || null}
      minimized={minimized}
      isDarkMode={isDarkMode}
      onClick={() => handleClickLink(pathItem.name, pathItem.to)}
    />
  );
}


function makeDropdown({
  pathItem,
  minimized,
  isDarkMode,
  selectionName,
  activeSelectionName,
  handleClickLink,
  handleOpenDropdown,
  handleCloseNavigation,
  mouseOnHoverDrawer,
  handleChangeMouseOnhoverDrawer,
}) {
  if (hasNotUserPermissions(pathItem.userPermissions)) {
    return null;
  }

  return (
    <NavigationDropdown
      key={pathItem.text}
      name={pathItem.name}
      Icon={pathItem.Icon}
      text={pathItem.text}
      iconColor={pathItem.iconColor}
      selectionName={selectionName}
      isDarkMode={isDarkMode}
      onClick={(e) => { handleOpenDropdown(e); handleCloseNavigation(); handleChangeMouseOnhoverDrawer(true); }}
      isActiveSelection={pathItem.name === activeSelectionName}
      minimized={minimized}
      mouseOnHoverDrawer={mouseOnHoverDrawer}
    >
      {pathItem.views.map(view => (
        <li key={`${pathItem.name}-${view.text}`}>
          {makeLink({
            pathItem: view,
            minimized,
            isDarkMode,
            handleClickLink: () => handleClickLink(pathItem.name, pathItem.to),
          })}
        </li>
      ))}
    </NavigationDropdown>
  );
}


function SideDrawerNavigationComponent({
  minimized, handleCloseNavigation, handleChangeMouseOnhoverDrawer, mouseOnHoverDrawer,
}) {
  const { habilitarAcordoSeguro } = useSelector(state => state.commonGlobal);

  // Quando uma feature for desabilitada a depender do seu estados no banco de dados / backend adicionar aqui
  const DISABLED_FEATURES = [
    {
      name: 'acordo-seguro',
      disabled: habilitarAcordoSeguro,
    },
  ];

  const [selectionName, setSelectionName] = React.useState('');
  const [userPicture, setUsePicture] = React.useState('');
  const [activeSelectionName, setActiveSelectionName] = React.useState('');
  const dispatch = useDispatch();

  const user = useSelector(state => ({
    id: getUserAttribute(state, 'idUsuario'),
    name: getUserAttribute(state, 'nomeUsuario'),
  }));

  const isDarkMode = useSelector(state => state.theme.bgColor !== 'white');

  function handleOpenDropdown(e) {
    if (e.currentTarget?.name === selectionName) {
      setSelectionName('');
    } else {
      setSelectionName(e.currentTarget?.name || '');
    }
  }

  function handleClickLink(activeDropdownName = '', pathname) {
    sessionStorage.setItem('menu-dropdown', activeDropdownName);
    setActiveSelectionName(activeDropdownName);

    pesquisaProcessoController.init();

    const acordoSeguroActions = {
      '/acordo-seguro/acordos': pesquisarAcordoASEntrarTelaAction(0, 10, ['dataAcordo,ASC']),
      '/acordo-seguro/historico': pesquisarHistoricoASEntrarTelaAction(0, 10),
    };
    const action = acordoSeguroActions[pathname];
    if (action) {
      dispatch(action);
    }

    dispatch(resetPesquisaRamoAtividadeRedux());
    dispatch(clearPesquisarClienteAction());
    dispatch(clearProcessarBorderoAction());
    dispatch(clearPesquisaFilialAction());
    dispatch(clearPesquisarUsuarioAction());
    dispatch(clearImportarBorderosAction());
    dispatch(clearSelectorsAction());
    dispatch(clearPesquisaHistoricoASAction());
    dispatch(clearPesquisaAcordosASAction());
    dispatch(clearAllStatesFromPageAction());

    handleCloseNavigation();
    handleChangeMouseOnhoverDrawer(true);
  }

  /**
   * Carregar foto do usuário
   *  */
  React.useEffect(() => {
    obterFotoUsuario(user.id).then((res) => {
      setUsePicture(`data:image;base64,${res.data}`);
    }).catch(() => {
      setUsePicture('');
    });
  }, [user.id]);

  /**
   * Manter o último dropdown do menu que foi selecionado
   */
  React.useEffect(() => {
    const activeDropdown = sessionStorage.getItem('menu-dropdown');
    setActiveSelectionName(activeDropdown);
  }, []);

  return (
    <>
      <Background role="button" onClick={handleCloseNavigation} minimized={minimized} />
      <Drawer
        minimized={minimized}
        isDarkMode={isDarkMode}
        onMouseEnter={() => handleChangeMouseOnhoverDrawer(true)}
        onMouseLeave={() => handleChangeMouseOnhoverDrawer(false)}
      >
        <UserNavBar isDarkMode={isDarkMode}>
          <img src={userPicture || defaultAvatar} alt="Foto do perfil" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <MobileActions isDarkMode={isDarkMode}>
              <ActionsNavBar />
            </MobileActions>
            <Typography>{user.name}</Typography>
          </div>
        </UserNavBar>

        <NavBar>
          {menuRoutes.filter((menu) => {
            const foundFeature = DISABLED_FEATURES.find(feature => feature.name === menu.name);
            return foundFeature ? foundFeature.disabled : true;
          }).map((pathItem) => {
            if (pathItem?.views) {
              return makeDropdown({
                pathItem,
                minimized,
                isDarkMode,
                selectionName,
                activeSelectionName,
                handleClickLink,
                handleOpenDropdown,
                handleCloseNavigation,
                mouseOnHoverDrawer,
                handleChangeMouseOnhoverDrawer,
              });
            }
            return makeLink({
              pathItem, minimized, isDarkMode, handleClickLink,
            });
          })}
        </NavBar>
      </Drawer>
    </>
  );
}

SideDrawerNavigationComponent.propTypes = {
  minimized: PropTypes.bool.isRequired,
  handleCloseNavigation: PropTypes.func.isRequired,
};

export const SideDrawerNavigation = withTokenInterceptor(React.memo(SideDrawerNavigationComponent));
