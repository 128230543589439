import React from 'react';
import { OulinedButtonContainer } from './buttonOurlinedStyles';

function ButtonOutlinedComponent({ children, ...rest }) {
  return (
    <OulinedButtonContainer variant="outlined" {...rest}>
      {children}
    </OulinedButtonContainer>
  );
}

export const ButtonOutlined = ButtonOutlinedComponent;
