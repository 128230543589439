import makeStyles from '@material-ui/styles/makeStyles';
import { amber, green } from '@material-ui/core/colors';

export default makeStyles({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: 'red',
  },
  info: {
    backgroundColor: 'blue',
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: '15px',
  },
  message: {
    display: 'flex',
    fontWeight: 'bold',
    alignItems: 'center',
  },
  root: {
    minWidth: 0,
  },
});
