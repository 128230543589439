import React from 'react';
import { InfoCard } from './DashboardInfoCards.styles';
import { DateUtils } from '../../../helpers';

const infos = {
  borderos: {
    icon: 'fas fa-paperclip',
    name: 'Borderôs',
    footer: `Mês de ${DateUtils.CALENDAR_MONTHS.find(month => month.id === new Date().getMonth() + 1).name}`,
  },
  clientes: {
    icon: 'fas fa-user-plus',
    name: 'Novos Clientes',
    footer: 'Últimos 30 dias',
  },
  acordos: {
    icon: 'fas fa-handshake',
    name: 'Acordos Firmados',
    footer: 'Últimos 30 dias',
  },
  processos: {
    icon: 'fas fa-file-medical-alt',
    name: 'Processos Ativos',
    footer: null,
  },
};

function DashboardInfoCard({ type, count }) {
  return (
    <InfoCard type={type}>
      <i className={infos[type].icon} />
      <span>{infos[type].name}</span>
      <span>{count}</span>
      {infos[type].footer && (
        <div>
          <i className="fas fa-calendar-alt" />
          {infos[type].footer}
        </div>
      )}
    </InfoCard>
  );
}

export default DashboardInfoCard;
