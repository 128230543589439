import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import cx from 'classnames';

import useStyles from './Button.styles';

function ButtonUI({
  children,
  classes = {},
  showLoading = false,
  disabled = false,
  color = 'primary',
  customColor = '#000000',
  ...props
}) {
  const styleProps = { customColor };
  const styles = useStyles(styleProps);
  const colors = {
    primary: styles.primary,
    white: styles.white,
    success: styles.success,
    error: styles.error,
    dataFu: styles.dataFu,
    pesquisa: styles.lupa,
    custom: styles.custom,
  };

  return (
    <Button
      fullWidth
      classes={{ root: cx(styles.text, colors[color]), disabled: styles.disabled, ...classes }}
      variant="outlined"
      disabled={showLoading || disabled}
      {...props}
    >
      <div className={styles.children}>
        <div>{children}</div>
        {showLoading && (
          <CircularProgress size={18} color="inherit" classes={{ root: styles.loading }} />
        )}
      </div>
    </Button>
  );
}

export default React.memo(ButtonUI);
