import React, {
  useCallback, useMemo, useState,
} from 'react';

import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Grid24 from '../Grid24/Grid24';
import FormWrapperWithTitle from '../Wrapper/Form/FormWrapperWithTitle';
import {
  HONORARIOS_TITLE,
  TIPO_CALCULO_LABEL,
  RANGE_LABEL,
  RANGE_QUERY_LABEL,
  ADD_RANGE_HINT,
  TAXA_HONORARIO_FASE_TITLE,
} from './honorarios.constants';
import SelectorField from '../Field/Selector/SelectorField';

import useUtilStyles from '../../../helpers/styles/utils.styles';
import AddActionTitleWrapper from '../Wrapper/AddActionTitle';
import FaixaHonorario from './Faixa/FaixaHonorario';
import { getRequiredLabel } from '../../../helpers/utils/utils';

import useStyles from './honorarios.styles';
import { SubTitleWrapperForm } from '../../Paramentros/Crud/Tabs/ContasBancarias/TabContasBancarias/ContasBancarias.styles';
import { TabsUi } from '../TabsUi/TabsUI';
import { baseGrey, white } from '../../../helpers/constants/colors.constants';
import NumberInput from '../Field/Number/NumberInput';
import FaixaNumeroHonorarios from './Faixa/FaixaNumeroHonorarios';
import { alterarValorHonorariosMinimoFaixaFaseAction } from '../../../store/Parametros/Crud/crudParametros.store';
import { useCrudParametrosDispatch } from '../../../store/Parametros/Crud/CrudParametrosProvider';

function FaixasHonorario({
  tiposCalculoHonorarios = [],
  disabled = true,
  onAddHandler,
  onChangeHonorarioItem,
  onRemoveHonorarioItem,
  onFocusHonoratioItem,
  selectorValue,
}) {
  const utilStyles = useUtilStyles();
  const styles = useStyles();

  return tiposCalculoHonorarios.map(
    (tipo, idxTipo) => ((selectorValue === 1 && tipo.type.value === 'DIAS')
        || (selectorValue === 2 && tipo.type.value === 'MONEY')) && (
        <AddActionTitleWrapper
          key={tipo.idTipoCalculoHonorario.value}
          className={utilStyles.mt16}
          identifier={idxTipo}
          title={RANGE_LABEL + tipo.descricaoTipoCalculoHonorario.value}
          readTitle={
            RANGE_QUERY_LABEL + tipo.descricaoTipoCalculoHonorario.value
          }
          disabled={disabled}
          addHandler={onAddHandler}
          tooltip={ADD_RANGE_HINT}
          readMode={disabled}
        >
          {tipo.honorarios.map((honorario, idx) => (
            <div key={honorario.key.value}>
              <FaixaHonorario
                priority={idx === 0}
                idxHonorario={idx}
                type={tipo.type.value}
                idxTipoHonorario={idxTipo}
                initialValue={honorario.valorFaixaInicial}
                finalValue={honorario.valorFaixaFinal}
                showTrash={idx === 0}
                disabled={disabled}
                rate={honorario.porcentagemHonorario}
                onChange={onChangeHonorarioItem}
                onRemove={onRemoveHonorarioItem}
                onFocus={onFocusHonoratioItem}
              />
              {idx !== tipo.honorarios.length - 1 && (
                <hr className={styles.faixasHonorarios__divider} />
              )}
            </div>
          ))}
        </AddActionTitleWrapper>
    ),
  );
}

function FaixasHonorariosFaseParametros({
  tiposCalculoHonorarios,
  tipoHonorarioSelecionado,
  disabled,
}) {
  const dispatchParametros = useCrudParametrosDispatch();

  const [currentTab, setCurrentTab] = useState(0);

  const fases = useSelector(state => state.selectors.fases.selector);


  const tipoHonorario = useMemo(
    () => tiposCalculoHonorarios.find(
      tipo => tipo.idTipoCalculoHonorario.value === tipoHonorarioSelecionado.value,
    ),
    [tipoHonorarioSelecionado, tiposCalculoHonorarios],
  );
  const tipoHonorarioIndex = useMemo(
    () => tiposCalculoHonorarios.findIndex(
      tipo => tipo.idTipoCalculoHonorario.value === tipoHonorarioSelecionado.value,
    ),
    [tipoHonorarioSelecionado, tiposCalculoHonorarios],
  );

  const faixas = tipoHonorario?.honorarios;

  const tabs = useMemo(
    () => fases.map(faseTab => ({ id: faseTab.id, name: faseTab.value })),
    [fases],
  );

  const onChangeTabs = useCallback(
    (event, newValue) => {
      setCurrentTab(newValue);
    },
    [setCurrentTab],
  );

  const onChangeTaxaMinima = useCallback(
    (faixaNum, valueMin) => {
      const fase = tabs[currentTab];
      const newFaixa = faixas[faixaNum];

      const existingObjIndex = newFaixa?.parametrosHonorarioMinimoPorFase.findIndex(
        obj => obj.idFase.value === fase.id,
      );
      if (existingObjIndex > -1) {
        newFaixa.parametrosHonorarioMinimoPorFase[
          existingObjIndex
        ].porcentagemHonorarioMinimo.onChange(Number(valueMin));
      }

      dispatchParametros(
        alterarValorHonorariosMinimoFaixaFaseAction(
          faixaNum,
          tipoHonorarioIndex,
          newFaixa,
        ),
      );
    },
    [faixas, currentTab, tabs, dispatchParametros, tipoHonorarioIndex],
  );


  return (
    <Box mt={2}>
      <TabsUi
        items={tabs}
        value={currentTab}
        containerBackground={white}
        onChange={onChangeTabs}
        tabSelectedBackground={baseGrey}
      />
      {faixas?.map((faixa, idx) => {
        const valueTaxaMinima = faixa?.parametrosHonorarioMinimoPorFase[
          currentTab
        ]?.porcentagemHonorarioMinimo.value.toString();

        return (
          <Box mt={1} display="flex" key={idx}>
            <FaixaNumeroHonorarios numFaixa={idx} />
            <NumberInput
              key={`${idx}${currentTab}`}
              isForm
              disabled={disabled}
              sideText="(%)"
              numberType="percentage"
              maxValue={100}
              label="Taxa Mínima"
              name="taxaMinima"
              value={valueTaxaMinima || '0.00'}
              onChange={e => onChangeTaxaMinima(idx, e.target.value)}
            />
          </Box>
        );
      })}
    </Box>
  );
}

export default function Honorarios({
  tiposCalculoHonorarios = [],
  selectorItens,
  disabled,
  selector,
  onChangeSelector,
  onAddHandler,
  onChangeHonorarioItem,
  onFocusHonoratioItem,
  onRemoveHonorarioItem,
}) {
  const utilStyles = useUtilStyles();
  return (
    <FormWrapperWithTitle title={HONORARIOS_TITLE}>
      <Grid24 container isFormContainer>
        <Grid24 xs={24} sm={7} className={utilStyles.mt7}>
          <SelectorField
            isForm
            disabled={disabled}
            label={getRequiredLabel(TIPO_CALCULO_LABEL, !disabled)}
            items={selectorItens}
            name={selector.name}
            value={selector.value}
            onChange={onChangeSelector}
          />
        </Grid24>
      </Grid24>
      <FaixasHonorario
        tiposCalculoHonorarios={tiposCalculoHonorarios}
        disabled={disabled}
        onAddHandler={onAddHandler}
        onChangeHonorarioItem={onChangeHonorarioItem}
        onRemoveHonorarioItem={onRemoveHonorarioItem}
        onFocusHonoratioItem={onFocusHonoratioItem}
        selectorValue={selector.value}
      />
      <SubTitleWrapperForm title={TAXA_HONORARIO_FASE_TITLE}>
        <FaixasHonorariosFaseParametros
          tiposCalculoHonorarios={tiposCalculoHonorarios}
          tipoHonorarioSelecionado={selector}
          disabled={disabled}
        />
      </SubTitleWrapperForm>
    </FormWrapperWithTitle>
  );
}
