import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  wrapperRemoveButton: {
    height: '100%',
    '& button': {
      marginTop: '10.3px',
    },
  },
});
