import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  spaceBetweenVerticalControl: {
    paddingTop: '10px',
  },
  selectorControl: {
    paddingRight: '20px',
    paddingLeft: '20px',
  },
  selectorRigthControl: {
    paddingRight: '20px',
  },
  wrapper: {
    marginLeft: '4px',
    border: 'solid',
    borderWidth: 'thin',
    '& button': {
      width: '100%',
      padding: '5px 0px',
    },
  },
  radios: {
    marginTop: '16px',
    marginLeft: '2px',
    '& span': {
      paddingRight: '8px',
    },
  },
  buttonWrapper: {
    width: '100%',
    marginTop: '16px',
  },
  buttonContent: {
    margin: 'auto',
    width: '21%',
  },
});
