import { CORE, gateway } from '../../api';

export function buscarQuantidadeNotificacoesRequisicoesService() {
  return gateway.get(`${CORE}/processos/noticacoes-requisicoes`);
}

export function buscarRequisicaoProcessoService(idProcesso) {
  return gateway.get(`${CORE}/processos/${idProcesso}/requisicao`);
}

export function gerarRequisicaoProcessoService(payload) {
  return gateway.post(`${CORE}/processos/gerar-requisicao`, payload);
}

export function atenderRequisicaoProcessoService(payload) {
  return gateway.post(`${CORE}/processos/atender-requisicao`, payload);
}
