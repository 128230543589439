import { Typography } from '@material-ui/core';
import React from 'react';
import { MdLightbulbOutline } from 'react-icons/md';

import TooltipUI from '../UI/Tooltip/Tooltip';
import { TitleContainer, LightIndicator } from './Title.styles';

function TitleComponent({ text, hint }) {
  return (
    <TitleContainer>
      <Typography component="h3">{text}</Typography>
      <TooltipUI title={hint} fullWidth>
        <LightIndicator><MdLightbulbOutline /></LightIndicator>
      </TooltipUI>
    </TitleContainer>
  );
}

export const Title = TitleComponent;
