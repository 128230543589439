export class ValidationComposite {
  constructor(validators = []) {
    this.validators = validators;
  }

  static build(validators = []) {
    return new ValidationComposite(validators);
  }

  isValidPayload(payload = {}) {
    return this.validators
      .reduce((acc, v) => {
        if (!acc) {
          return false;
        }
        return v.validate(payload[v.fieldName]);
      }, true);
  }
}
