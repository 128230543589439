import React, { createContext } from 'react';
import { createDispatchHook, createSelectorHook, Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, createStore } from 'redux';
import reducer from './logProcessamentoBordero.store';
import watchRequisicoes from './logProcessamentoBordero.saga';


export const context = createContext();
export const usePesquisarLogProcessamentoSelector = createSelectorHook(context);
export const usePesquisarLogProcessamentoDispatch = createDispatchHook(context);

export default function LogProcessamentoBorderoProvider({ children }) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(watchRequisicoes);

  return (
    <Provider context={context} store={store}>
      {children}
    </Provider>
  );
}
