import React from 'react';
import { Typography } from '@material-ui/core';

function CustomFieldView({ textField }) {
  return (
    <Typography component="p" style={{ fontSize: '12px', marginLeft: '8px', marginTop: '5px' }}>
      {textField}
    </Typography>
  );
}

export default React.memo(CustomFieldView);
