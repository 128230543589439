import { put, select, takeLatest } from 'redux-saga/effects';
import { onErrorTituloCompromissoAction, onLoadingCrudAction } from './crudTituloCompromisso.store';

import { onOpenCrudDialogAction, onSucessCadastroAction, shouldUpdateContentTituloCompromissoAction } from '../Pesquisa/pesquisaTituloCompromisso.store';
import { getExceptionHandler } from '../../../helpers/utils/exception.util';
import { salvartTituloCompromisso, atualizarTituloCompromisso } from '../../../services/core/tituloCompromisso/TituloCompromisso.services';

const SALVAR_TITULO_COMPROMISSO = 'SALVAR_TITULO_COMPROMISSO';
const ATUALIZAR_TITULO_COMPROMISSO = 'ATUALIZAR_TITULO_COMPROMISSO';

export const salvarTituloCompromissoAction = nomeTituloCompromisso => ({
  type: SALVAR_TITULO_COMPROMISSO,
  nomeTituloCompromisso,
});
export const atualizarTituloCompromissoAction = (idTipoTituloDeCompromisso, nomeTituloCompromisso, nomeAtual) => ({
  type: ATUALIZAR_TITULO_COMPROMISSO,
  idTipoTituloDeCompromisso,
  nomeTituloCompromisso,
  nomeAtual,
});


function* salvarTituloCompromissoHandler(actions) {
  const fields = yield select(states => states.crudTituloCompromisso.controls);
  yield put(onLoadingCrudAction(true));
  try {
    yield salvartTituloCompromisso(actions.nomeTituloCompromisso);
    yield put(onSucessCadastroAction(true));
    yield put(onOpenCrudDialogAction(false));
    yield put(shouldUpdateContentTituloCompromissoAction(true));
  } catch (exception) {
    yield put(onSucessCadastroAction(false));
    const [updatedFields] = yield getExceptionHandler(exception, fields);
    if (updatedFields) {
      yield put(onErrorTituloCompromissoAction(updatedFields));
    }
  } finally {
    yield put(onLoadingCrudAction(false));
  }
}

function* atualizarTituloCompromissoHandler(actions) {
  const fields = yield select(states => states.crudTituloCompromisso.controls);
  yield put(onLoadingCrudAction(true));
  try {
    const { nomeTituloCompromisso, idTipoTituloDeCompromisso, nomeAtual } = actions;
    if (nomeAtual !== nomeTituloCompromisso) {
      yield atualizarTituloCompromisso(idTipoTituloDeCompromisso, nomeTituloCompromisso);
    }
    yield put(onSucessCadastroAction(true));
    yield put(onOpenCrudDialogAction(false));
    yield put(shouldUpdateContentTituloCompromissoAction(true));
  } catch (exception) {
    yield put(onSucessCadastroAction(false));
    const [updatedFields] = yield getExceptionHandler(exception, fields);
    if (updatedFields) {
      yield put(onErrorTituloCompromissoAction(updatedFields));
    }
  } finally {
    yield put(onLoadingCrudAction(false));
  }
}


export default function* watchCrudTituloCompromisso() {
  yield takeLatest(SALVAR_TITULO_COMPROMISSO, salvarTituloCompromissoHandler);
  yield takeLatest(ATUALIZAR_TITULO_COMPROMISSO, atualizarTituloCompromissoHandler);
}
