import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid24 from '../../../../../UI/Grid24';
import BoletoCliente from './ContasPadrao/BoletoCliente';
import BoletoDevedor from './ContasPadrao/BoletoDevedor';
import ContasAdicionaisDepositoClientes from './Cliente/ContaDepositoClientes';
import ContaSispag from './ContasPadrao/ContaSispag';

import { CONTA_DEPOSITO, TITLE_CONTA_BANCARIA } from './TabContasBancarias.constants';
import { WrapperFormWithTitle } from './ContasBancarias.styles';
import ContaPagamentosGerais from './Gerais/ContaPagamentosGerais';
import { findAllTiposChavePixAction, findBancosAction } from '../../../../../../store/Global/LoadSelectors/loadSelectors.saga';


function TabContasBancarias({
  isInsertMode,
}) {
  const dispatch = useDispatch();

  const bancos = useSelector(states => states.selectors.bancos.selector);
  const tiposChavesPix = useSelector(states => states.selectors.tiposChavesPix.selector);

  useEffect(() => {
    dispatch(findAllTiposChavePixAction());
    dispatch(findBancosAction());
  }, [dispatch]);

  return (
    <>
      <Grid24 container isFormContainer>
        <Grid24 xs={24} sm={24}>
          <WrapperFormWithTitle title={TITLE_CONTA_BANCARIA}>
            <ContaSispag isInsertMode={isInsertMode} bancos={bancos} />
            <BoletoCliente isInsertMode={isInsertMode} bancos={bancos} />
            <BoletoDevedor isInsertMode={isInsertMode} bancos={bancos} />
          </WrapperFormWithTitle>

          <WrapperFormWithTitle title={CONTA_DEPOSITO}>
            <ContasAdicionaisDepositoClientes
              readMode={!isInsertMode}
              isInsertMode={isInsertMode}
              bancos={bancos}
              tiposChavesPix={tiposChavesPix}
            />
            <ContaPagamentosGerais
              readMode={!isInsertMode}
              isInsertMode={isInsertMode}
              bancos={bancos}
              tiposChavesPix={tiposChavesPix}
            />
          </WrapperFormWithTitle>
        </Grid24>
      </Grid24>
    </>
  );
}

export default TabContasBancarias;
