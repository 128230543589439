import React from 'react';
import useStyles from './faixaHonorario.styles';


function FaixaNumeroHonorarios({ numFaixa }) {
  const styles = useStyles();

  return (
    <span className={styles.faixaHonorario__tituloFaixa}>
      Faixa
      {' '}
      {numFaixa + 1}
      :
    </span>
  );
}


export default FaixaNumeroHonorarios;
