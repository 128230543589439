import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import ButtonWithIconUI from '../../UI/Button/Icon/ButtonWithIconUI';

export const AdvancedSearchContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.64);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1300;

  >button {
    position: absolute;
    top: 25px;
    right: 500px;

    width: 32px;
    height: 37px;
    background-color: #034ddc;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: white;
    font-weight: bold;
    outline: none;
    cursor: pointer;

  }

  >div {
    width: 500px;
    height: 100vh;
    float: right;
    overflow-y: hidden;
    box-sizing: border-box;
    background-color: #ffffff;

    display: flex;
    flex-direction: column;
  }
`;

export const AdvancedActionButtons = styled.div`
  display: flex;
  justify-content: space-around;
  align-content: center;
  height: 48px;
  margin-bottom: 16px;
  margin-top: 16px;
  box-shadow: 0px 3px 3px 0 rgb(33 35 38 / 28%);

  >button {
    width: 215px;
    padding-top: 5px;
    margin-bottom: 16px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 42px;
  text-align: center;
  margin-bottom: 24px;
  box-shadow: 0px -10px 10px -10px rgb(33 35 38 / 28%);
`;

export const ClearButton = styled(ButtonWithIconUI)`
  width: 196px;
  margin-top: 16px;
  background-color: white;
  border: 1px solid #034ddc;
  color: #034ddc;

  :hover {
    background-color: 'white',
  }
`;

export const AdvancedSearchBody = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 10px 20px;
  padding-right: 12px;

  ::-webkit-scrollbar {
    width: '10px';
  };

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  };

  ::-webkit-scrollbar-thumb {
      background-color: #888;
      outline: '1px solid #cec8c8';
  };

  ::-webkit-scrollbar-thumb:hover {
    background: #555,
  };
`;

export const AdvancedSearchTitle = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
`;
