import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Endereco from '../../../UI/EnderecosV2/Endereco/Endereco';

import {
  useCrudParametrosDispatch,
  useCrudParametrosSelector,
} from '../../../../store/Parametros/Crud/CrudParametrosProvider';

import {
  setEnderecoAction,
  setEnderecoAttributeFocusAction,
  setEnderecoAttributeValueAction,
} from '../../../../store/Parametros/Crud/crudParametros.store';
import constants from './EnderecoCrudParametros.constants';
import WrapperSubTitleForm from '../../../UI/WrapperSubTitleForm';
import useUtilStyles from '../../../../helpers/styles/utils.styles';
import { findEstadosAction } from '../../../../store/Global/LoadSelectors/loadSelectors.saga';

function EnderecoCrudParametros({ isInsertMode, location }) {
  const dispatch = useCrudParametrosDispatch();
  const globalDispatch = useDispatch();

  const utilStyles = useUtilStyles();

  const endereco = useCrudParametrosSelector(states => ({ ...states.requestFields.endereco }));
  const estados = useSelector(states => states.selectors.estados.selector);

  const requiredFields = {
    cep: isInsertMode,
    endereco: isInsertMode,
    bairro: isInsertMode,
    cidade: isInsertMode,
    estado: isInsertMode,
  };

  useEffect(() => {
    globalDispatch(findEstadosAction());
  }, [globalDispatch, location.refreshPage]);

  const setEnderecoHandler = useCallback((enderecoParam) => {
    dispatch(setEnderecoAction(enderecoParam));
  }, [dispatch]);

  const setEnderecoAttributeValueHandler = useCallback((name, value) => {
    dispatch(setEnderecoAttributeValueAction(name, value));
  }, [dispatch]);

  const setEnderecoAttributeFocusFocusHandler = useCallback((enderecoParam) => {
    dispatch(setEnderecoAttributeFocusAction(enderecoParam));
  }, [dispatch]);

  return (
    <WrapperSubTitleForm title={constants.TITLE} className={utilStyles.mt16}>
      <Endereco
        disabled={!isInsertMode}
        estados={estados}
        model={endereco}
        requiredFields={requiredFields}
        setEndereco={setEnderecoHandler}
        setAttribute={setEnderecoAttributeValueHandler}
        setFocus={setEnderecoAttributeFocusFocusHandler}
      />
    </WrapperSubTitleForm>
  );
}

export default withRouter(EnderecoCrudParametros);
