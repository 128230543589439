import React from 'react';
import styled from 'styled-components';
import {
  FaHourglassEnd,
  FaHourglassHalf,
  FaHourglassStart,
  FaClock,
  FaCircle,
  FaFlag,
} from 'react-icons/fa';
import TooltipUI from '../Tooltip/Tooltip';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  cursor: 'default';
  box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 20%);
`;


export const getVencimentoProcessoIcon = {
  M: <FaHourglassEnd color="#e91b1b" />,
  L: <FaHourglassHalf color="#ffa000" />,
  V: <FaHourglassStart color="#35c399" />,
};

export const getCompromissoProcessoIcon = {
  COMPROMISSO_EXPIRADO: <FaClock color="#ff000f" />,
  COMPROMISSO_EXPIRACAO_PROXIMA: <FaClock color="#ff8800" />,
  COMPROMISSO_MARCADO: <FaClock color="#35c399" />,
  DISABLED: <FaClock color="#e4e4e4" />,
};

export function AlertaVencimentoProcesso({ cor, hint = '' }) {
  if (!getVencimentoProcessoIcon[cor]) {
    return null;
  }
  return (
    <TooltipUI title={hint}>
      <Container>
        {getVencimentoProcessoIcon[cor]}
      </Container>
    </TooltipUI>
  );
}

export function AlertaCompromissoProcesso({ compromisso, hint = '' }) {
  if (!getCompromissoProcessoIcon[compromisso]) {
    return null;
  }

  return (
    <TooltipUI title={hint}>
      <Container>
        {getCompromissoProcessoIcon[compromisso]}
      </Container>
    </TooltipUI>
  );
}

export function AlertaProcessoNaoTrabalhado({ naoTrabalhado, hint = '' }) {
  if (!naoTrabalhado) {
    return null;
  }
  return (

    <TooltipUI title={hint}>
      <Container>
        <FaCircle color="#8a2a7d" size={8} />
      </Container>
    </TooltipUI>

  );
}

export function AlertaComRequisicao({ hint = '' }) {
  if (!hint) {
    return null;
  }
  return (
    <TooltipUI title={hint}>
      <Container>
        <FaFlag color="#034ddc" />
      </Container>
    </TooltipUI>
  );
}
