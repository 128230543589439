import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WrapperSubTitleForm from '../../../../UI/WrapperSubTitleForm/WrapperSubTitleForm';
import InputField from '../../../../UI/Field/Input/InputField';

import useUtilStyles from '../../../../../helpers/styles/utils.styles';

import {
  onChangeControls,
  setTelefoneAdvogadoAttributeFocusAction,
  setTelefoneAdvogadoAttributeValueAction,
} from '../../../../../store/Cliente/V2/Crud/crudCliente.store';
import {
  useCrudClienteDispatch,
  useCrudClienteSelector,
} from '../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import { findTiposTelefoneAction } from '../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import Grid24 from '../../../../UI/Grid24/Grid24';
import TelefoneAdvogadoCrudCliente from './TelefoneAdvogadoCrudCliente';

const CONSTANTS = {
  title: 'Dados do Advogado',
  nomeAdvogado: 'Nome',
};

function DadosAdvogadoCrudCliente({ isEditMode }) {
  const utilStyles = useUtilStyles();
  const globalDispatch = useDispatch();
  const dispatch = useCrudClienteDispatch();

  const nomeAdvogado = useCrudClienteSelector(states => states.controls.nomeAdvogado);
  const tiposTelefone = useSelector(states => states.selectors.tiposTelefone.selector);
  const onReloadAllCombos = useCrudClienteSelector(states => states.actions.reloadAllCombos);

  useEffect(() => {
    globalDispatch(findTiposTelefoneAction());
  }, [globalDispatch, onReloadAllCombos]);

  const onChangeControlsHanlder = useCallback(
    (event) => {
      const { name, value } = event.target;
      dispatch(onChangeControls(name, value));
    },
    [dispatch],
  );

  const setTelefoneAdvogadoAttributeValueHandler = useCallback((name, value) => {
    dispatch(setTelefoneAdvogadoAttributeValueAction(name, value));
  }, [dispatch]);

  const setTelefoneAdvogadoAttributeFocusFocusHandler = useCallback((telefoneAdvogadoParam) => {
    dispatch(setTelefoneAdvogadoAttributeFocusAction(telefoneAdvogadoParam));
  }, [dispatch]);

  return (
    <WrapperSubTitleForm
      title={CONSTANTS.title}
      className={utilStyles.mt16}
    >
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={10}>
          <InputField
            isForm
            fullWidth
            disabled={!isEditMode}
            label={CONSTANTS.nomeAdvogado}
            value={nomeAdvogado.value}
            name={nomeAdvogado.name}
            maxLength={nomeAdvogado.maxLength}
            error={nomeAdvogado.error}
            errorMessage={nomeAdvogado.errorMessage}
            onChange={onChangeControlsHanlder}
          />
        </Grid24>

        <TelefoneAdvogadoCrudCliente
          disabled={!isEditMode}
          tiposTelefone={tiposTelefone}
          setAttribute={setTelefoneAdvogadoAttributeValueHandler}
          setFocus={setTelefoneAdvogadoAttributeFocusFocusHandler}
        />
      </Grid24>
    </WrapperSubTitleForm>
  );
}

export default DadosAdvogadoCrudCliente;
