import FieldModel from '../../models/FieldModel';
import { getAttributeExistingObject } from '../utils/utils';

export function buildcontaBancariaSispagItau(contaBancaria) {
  return ({
    idContaBancaria: new FieldModel({
      value: getAttributeExistingObject(contaBancaria, 'idContaBancaria'),
    }),
    serSispag: new FieldModel({
      value: getAttributeExistingObject(contaBancaria, 'serSispag'),
    }),
    agencia: new FieldModel({
      name: 'agencia',
      value: getAttributeExistingObject(contaBancaria, 'agencia'),
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    chavePix: new FieldModel({
      name: 'chavePix',
      value: getAttributeExistingObject(contaBancaria, null),
    }),
    cnpjCpf: new FieldModel({
      name: 'cnpjCpf',
      value: getAttributeExistingObject(contaBancaria, 'cnpjCpf'),
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    dac: new FieldModel({
      name: 'dac',
      value: getAttributeExistingObject(contaBancaria, 'dac'),
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    idBanco: new FieldModel({
      name: 'idBanco',
      value: getAttributeExistingObject(contaBancaria, 'idBanco'),
    }),
    numero: new FieldModel({
      name: 'numero',
      value: getAttributeExistingObject(contaBancaria, 'numero'),
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    titular: new FieldModel({
      name: 'titular',
      value: getAttributeExistingObject(contaBancaria, 'titular'),
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
  });
}
