import React from 'react';
import Grid24 from '../../../../../UI/Grid24';
import { getRequiredLabel } from '../../../../../../helpers/utils/utils';
import constants from '../Honorarios/Faixa/constants';
import NumberInput from '../../../../../UI/Field/Number/NumberInput';
import MoneyField from '../../../../../UI/Field/Money/MoneyField';
import { TrashIconButton, TrashIconContainer } from '../Honorarios/Faixa/styles';
import {
  FINAL, HONORARIOS_CONTRATUAIS_FINAL, HONORARIOS_CONTRATUAIS_INICIAL, INITIAL,
} from './HonorariosContratuais.constants';

function CustomField({
  label,
  disabled = false,
  name,
  value,
  error,
  errorMessage,
  onChange,
  onFocus,
}) {
  return (
    <MoneyField
      isForm
      fullWidth
      disabled={disabled}
      maxlength={16}
      label={label}
      name={name}
      value={value}
      error={error}
      errorMessage={errorMessage}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
}

function TrashButton({
  disabled = false,
  showTrash = false,
  attributes,
  onRemove,
}) {
  return (
    <TrashIconContainer showtrash={(!disabled && !showTrash) ? 1 : 0} itemxs={24} sm={1}>
      <TrashIconButton
        type="trash"
        attributes={attributes}
        disabled={disabled}
        hint={constants.trash}
        onClick={onRemove}
      />
    </TrashIconContainer>
  );
}

function FaixaHonorariosContratuais({
  disabled = false,
  initial,
  final,
  porcentagemHonorariosContratuaisInicial,
  porcentagemHonorariosContratuaisFinal,
  showTrash = false,
  idxHonorarioContratual,
  onChange,
  onFocus,
  onRemove,
}) {
  const rename = name => `${name}-${idxHonorarioContratual}`;

  const disabledFirstValue = (disabled || idxHonorarioContratual === 0);

  return (
    <>
      <Grid24 container isFormContainer spacing="1">

        <Grid24 item xs={24} sm={4}>
          <CustomField
            label={getRequiredLabel(INITIAL, !disabledFirstValue)}
            disabled={disabledFirstValue}
            name={rename(initial.name)}
            value={initial.value}
            error={initial.error}
            errorMessage={initial.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>

        <Grid24 item xs={24} sm={4}>
          <CustomField
            label={getRequiredLabel(FINAL, !disabled)}
            disabled={disabled}
            name={rename(final.name)}
            value={final.value}
            error={final.error}
            errorMessage={final.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>

        <Grid24 item xs={24} sm={3}>
          <NumberInput
            isForm
            fullWidth
            disabled={disabled}
            sideText="(%)"
            numberType="percantage"
            maxValue={100}
            label={getRequiredLabel(HONORARIOS_CONTRATUAIS_INICIAL, !disabled)}
            name={rename(porcentagemHonorariosContratuaisInicial.name)}
            value={porcentagemHonorariosContratuaisInicial.value}
            error={porcentagemHonorariosContratuaisInicial.error}
            errorMessage={porcentagemHonorariosContratuaisInicial.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>

        <Grid24 item xs={24} sm={4}>
          <NumberInput
            isForm
            fullWidth
            disabled={disabled}
            sideText="(%)"
            numberType="percantage"
            maxValue={100}
            label={getRequiredLabel(HONORARIOS_CONTRATUAIS_FINAL, !disabled)}
            name={rename(porcentagemHonorariosContratuaisFinal.name)}
            value={porcentagemHonorariosContratuaisFinal.value}
            error={porcentagemHonorariosContratuaisFinal.error}
            errorMessage={porcentagemHonorariosContratuaisFinal.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>
        <TrashButton
          showTrash={showTrash}
          disabled={disabled}
          attributes={{ idxHonorarioContratual }}
          onRemove={onRemove}
        />
      </Grid24>
    </>
  );
}

export default FaixaHonorariosContratuais;
