import React, { useCallback } from 'react';
import FormWrapperTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import {
  INSERIR_INSTRUCAO_INICIAL_LABEL,
  INSTRUCAO_INICIAL_LABEL,
  PROCESSAMENTO_TITLE,
} from './ProcessamentoFields/processamentoFields.constants';
import useUtilStyles from '../../../../../../helpers/styles/utils.styles';
import ProcessamentoCheckbox from './ProcessamentoCheckBox/ProcessamentoCheckbox';
import ProcessamentoFields from './ProcessamentoFields/ProcessamentoFields';
import ProcessamentoEstatistica from './ProcessamentoEstatistica/ProcessamentoEstatistica';
import Grid24 from '../../../../../UI/Grid24/Grid24';
import { onChangeControls } from '../../../../../../store/Cliente/V2/Crud/crudCliente.store';
import {
  useCrudClienteDispatch,
  useCrudClienteSelector,
} from '../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import PrazoExpiracaoProcessos from './PrazoExpiracaoProcessos/PrazoExpiracaoProcessos';
import TextareaField from '../../../../../UI/Field/Textarea/TextareaField';
import { SubTitle } from '../../../../..';

function ProcessamentoBordero({
  onChangeInputFields,
  onFocus,
  disabled,
  isEditMode,
  onChangeChecked,
}) {
  const utilStyle = useUtilStyles();
  const dispatch = useCrudClienteDispatch();

  const instrucaoInicial = useCrudClienteSelector(states => states.controls.instrucaoInicial.copy());

  const onChangeInstrucaoInicial = useCallback((event) => {
    dispatch(onChangeControls('instrucaoInicial', event.target.value));
  }, [dispatch]);

  return (
    <FormWrapperTitle title={PROCESSAMENTO_TITLE}>
      <ProcessamentoCheckbox
        onChangeChecked={onChangeChecked}
        disabled={disabled}
      />
      <hr className={utilStyle.divider} />
      <ProcessamentoFields
        onChange={onChangeInputFields}
        onChangeChecked={onChangeChecked}
        onFocus={onFocus}
        disabled={disabled}
      />
      <hr className={utilStyle.divider} />


      <ProcessamentoEstatistica />
      <hr className={utilStyle.divider} />

      <Grid24 isFormContainer container spacing="1" className={utilStyle.mt16}>
        <Grid24 xs={24}>
          <SubTitle>{INSTRUCAO_INICIAL_LABEL}</SubTitle>
          <TextareaField
            disabled={disabled}
            name={instrucaoInicial.name}
            value={instrucaoInicial.value}
            placeholder={INSERIR_INSTRUCAO_INICIAL_LABEL}
            classForm={utilStyle.areaSize}
            onChange={onChangeInstrucaoInicial}
          />
        </Grid24>
      </Grid24>

      <hr className={utilStyle.divider} />
      <PrazoExpiracaoProcessos
        onChange={onChangeInputFields}
        onChangeChecked={onChangeChecked}
        onFocus={onFocus}
        disabled={disabled}
        isEditMode={isEditMode}
      />

    </FormWrapperTitle>
  );
}

export default ProcessamentoBordero;
