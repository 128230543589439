import React from 'react';
import { FaEraser } from 'react-icons/fa';
import ButtonUI from '../../../../../components/UI/Button/ButtonUI';
import {
  MainContainer, FooterContainer,
} from './AcaoPagamentoStyles';
import { ActionHeaderContainer, CustomButton } from '../PagamentosStyle';
import { SimpleTitle } from '../../../../../components/Title/SimpleTitle/SimpleTitle';
import Grid24 from '../../../../../components/UI/Grid24/Grid24';
import MoneyField from '../../../../../components/UI/Field/Money/MoneyField';

export default function AcaoPagamentoComponent() {
  return (
    <>
      <form>
        <ActionHeaderContainer>
          <SimpleTitle
            text="Valor do Pagamento"
          />

          <CustomButton
            disabled={' '}
            onClick={' '}
          >
            <FaEraser size={18} />
            <span>Limpar</span>
          </CustomButton>

        </ActionHeaderContainer>
        <MainContainer>
          <Grid24 xs={24} sm={24}>
            <Grid24 xs={24} sm={12}>
              <MoneyField
                isForm
                disabled={' '}
                label="Valor*"
                name={' '}
                value={' '}
                error={' '}
                errorMessage="Valor do pagamento maior que o saldo a pagar"
                onChange={' '}
                onFocus={' '}
              />
            </Grid24>
          </Grid24>
        </MainContainer>
        <FooterContainer>
          <ButtonUI
            color="success"
            style={{ width: '136px' }}
            type="submit"
          >
            Confirmar
          </ButtonUI>
        </FooterContainer>
      </form>
    </>
  );
}

export const AcaoPagamento = AcaoPagamentoComponent;
