import { makeStyles } from '@material-ui/core/styles';
import { warmGrey } from '../../../../helpers/constants/colors.constants';

export default makeStyles({
  numberInput: {
    display: 'flex',
  },
  numberInput_sideText: {
    alignSelf: 'flex-end',
    fontSize: '0.75rem',
    color: warmGrey,
    marginLeft: '8px',
  },
  numberInput_fullWidth: {
    width: '100%',
  },
});
