import { makeErrorField } from '../../../../helpers';
import FieldModel from '../../../../models/FieldModel';
import { buscarEmailsPadraoDevedorAcordo, buscarParametrosEmailTermoAcordo, enviarEmailsTermoAcordo } from '../../../../services/core/processos/processosAcordosService';

class DialogEnviarEmailController {
  makeFormFields() {
    return ({
      emailList: new FieldModel({
        name: 'emailList',
        value: '',
      }),
    });
  }

  makeErrorFields() {
    return {
      emailList: makeErrorField(),
    };
  }

  enviarEmails(idAcordo, formFields) {
    return buscarParametrosEmailTermoAcordo(
      idAcordo,
      {
        emailList: formFields.emailList.value,
        nroParcela: null,
      },
    ).then(response => enviarEmailsTermoAcordo(response.data, 'completo'));
  }

  buscarEmailsPadrao(idAcordo) {
    return buscarEmailsPadraoDevedorAcordo(idAcordo);
  }
}

export const dialogEnviarEmailController = new DialogEnviarEmailController();
