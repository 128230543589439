import React from 'react';
import useUtilStyles from '../../../helpers/styles/utils.styles';
import FormWrapperTitle from '../Wrapper/Form/FormWrapperWithTitle';
import SelectorField from '../Field/Selector/SelectorField';
import {
  ADD_RANGE_HINT,
  COMISSOES_TITLE,
  RANGE_LABEL,
  RANGE_QUERY_LABEL,
  TIPO_CALCULO_LABEL,
} from './comissoes.constants';
import Grid24 from '../Grid24/Grid24';
import useStyles from './comissoes.styles';
import AddActionTitleWrapper from '../Wrapper/AddActionTitle';
import { getRequiredLabel } from '../../../helpers/utils/utils';
import FaixaComissao from './Faixa/FaixaComissao';

function FaixasComissao({
  tiposCalculoComissoes = [],
  disabled = true,
  onAddHandler,
  onChangeComissaoItem,
  onRemoveComissaoItem,
  onFocusComissaoItem,
  selectorValue,
  onChangeSelector,
}) {
  const utilStyles = useUtilStyles();
  const styles = useStyles();
  return tiposCalculoComissoes.map((tipo, idxTipo) => (
    (((selectorValue === 1 && tipo.type.value === 'DIAS')
      || (selectorValue === 2 && tipo.type.value === 'MONEY'))
      && (
        <AddActionTitleWrapper
          key={tipo.idTipoCalculoComissao.value}
          className={utilStyles.mt16}
          identifier={idxTipo}
          title={RANGE_LABEL + tipo.descricaoTipoCalculoComissao.value}
          readTitle={RANGE_QUERY_LABEL + tipo.descricaoTipoCalculoComissao.value}
          disabled={disabled}
          addHandler={onAddHandler}
          tooltip={ADD_RANGE_HINT}
          readMode={disabled}
        >
          {tipo.comissoes.map((comissao, idx) => (
            <div key={comissao.key.value}>
              <FaixaComissao
                priority={idx === 0}
                idxComissao={idx}
                type={tipo.type.value}
                idxTipoComissao={idxTipo}
                initialValue={comissao.valorFaixaInicial}
                finalValue={comissao.valorFaixaFinal}
                showTrash={idx === 0}
                disabled={disabled}
                porcentagemComissao={comissao.porcentagemComissao}
                porcentagemPagtoDiretoComissao={comissao.porcentagemPagtoDiretoComissao}
                onChange={onChangeComissaoItem}
                onRemove={onRemoveComissaoItem}
                onFocus={onFocusComissaoItem}
                onChangeSelector={onChangeSelector}
                idTipoRegraComissaoComercial={comissao.idTipoRegraComissaoComercial}
              />
              {idx !== tipo.comissoes.length - 1 && (
                <hr className={styles.faixasComissoes__divider} />
              )}
            </div>
          ))}
        </AddActionTitleWrapper>
      )
    )

  ));
}


function Comissoes({
  tiposCalculoComissoes = [],
  selectorItens,
  disabled,
  selector,
  onChangeSelector,
  onAddHandler,
  onChangeComissaoItem,
  onFocusComissaoItem,
  onRemoveComissaoItem,
}) {
  const utilStyles = useUtilStyles();

  return (
    <FormWrapperTitle title={COMISSOES_TITLE}>
      <Grid24 container isFormContainer>
        <Grid24 xs={24} sm={7} className={utilStyles.mt7}>
          <SelectorField
            isForm
            disabled={disabled}
            label={getRequiredLabel(TIPO_CALCULO_LABEL, !disabled)}
            items={selectorItens}
            name={selector.name}
            value={selector.value}
            onChange={onChangeSelector}
          />
        </Grid24>
      </Grid24>
      <FaixasComissao
        tiposCalculoComissoes={tiposCalculoComissoes}
        disabled={disabled}
        onAddHandler={onAddHandler}
        onChangeComissaoItem={onChangeComissaoItem}
        onRemoveComissaoItem={onRemoveComissaoItem}
        onFocusComissaoItem={onFocusComissaoItem}
        selectorValue={selector.value}
        onChangeSelector={onChangeSelector}
      />
    </FormWrapperTitle>
  );
}

export default Comissoes;
