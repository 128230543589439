import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function EmissaoAvulsaIcon(props) {
  const path = 'M12.7138 7.95C12.8125 7.95 12.9041 7.99286 12.9816 8.07143L13.8837 8.98571C14.0388 9.13571 14.0388 9.38571 13.8837 9.53572L13.179 10.25L11.7342 8.78572L12.439 8.07143C12.5165 7.99286 12.6152 7.95 12.7138 7.95ZM11.3254 9.2L12.7702 10.6643L8.49937 15H7.04757V13.5286L11.3254 9.2ZM11.2761 1.42857C12.0513 1.42857 12.6856 2.07143 12.6856 2.85714V5.71429L5.63806 12.8571V14.2857H1.40951C0.634281 14.2857 0 13.6429 0 12.8571V2.85714C0 2.07143 0.634281 1.42857 1.40951 1.42857H4.3554C4.6514 0.6 5.42663 0 6.34281 0C7.259 0 8.03423 0.6 8.33023 1.42857H11.2761ZM6.34281 1.42857C5.9552 1.42857 5.63806 1.75 5.63806 2.14286C5.63806 2.53571 5.9552 2.85714 6.34281 2.85714C6.73043 2.85714 7.04757 2.53571 7.04757 2.14286C7.04757 1.75 6.73043 1.42857 6.34281 1.42857Z';
  return (
    <SvgIcon
      transform="scale(1.3)"
      viewBox="-8 -10 30 30"
    >
      <path d={path} {...props} />
    </SvgIcon>
  );
}
