import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Typography } from '@material-ui/core';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { FaEraser, FaHandshake } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Grid24 from '../../../../components/UI/Grid24';
import useStyle from '../AcordosStyle';
import { TextoPadraoButton } from '../../InspecionarProcessoStyles';
import { getMoneyMask, toBrazilianDate } from '../../../../helpers';
import { buscarParcelasDistribuicaoPersonalizada, descartarDistribuicaoPersonalizada, salvarDistribuicaoPersonalizada } from '../../../../services/core/processos/processosAcordosService';
import OptionDialogNew from '../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';


function DialogDistribuicaoPersonalizada({
  setOpenDialogDistribuicaoPersonalizada,
  idAcordo,
}) {
  const [openDialogSalvarDistribuicaoPersonalizada, setOpenSalvarDistribuicaoPersonalizada] = useState(false);

  const [openDialogDescartarPersonalizacao, setOpenDescartarPersonalizacao] = useState(false);

  const [openDialogApenasLeitura, setOpenDialogApenasLeitura] = useState(false);

  const [valorTotal, setValorTotal] = useState(0);

  const [tableContent, setTableContent] = useState([]);

  const [parcelasAlteradas, setParcelasAlteradas] = useState([]);

  const styles = useStyle();

  const disableBotaoSalvar = parcelasAlteradas.length !== tableContent.length;

  const [disableBotaoDescartarPersonalizacao, setDisableBotaoDescartarPersonalizacao] = useState(false);

  const disableBotaoLimpar = parcelasAlteradas.reduce((total, parcela) => total + parcela.valorCliente, 0) === 0;

  const [enableValorCliente, setEnableValorCliente] = useState(true);

  const onValueSetter = useCallback((value, row) => {
    const valorCliente = value || 0;
    const valorABE = row.valor - valorCliente;
    return { ...row, valorABE, valorCliente };
  }, []);

  const columns = [
    {
      field: 'nroParcela',
      headerAlign: 'center',
      headerName: 'Parcela',
      headerClassName: 'header-data-grid-parcelas',
      editable: false,
      align: 'center',
      width: 70,
    },
    {
      field: 'dataVencimento',
      headerAlign: 'center',
      headerName: 'Vencimento',
      headerClassName: 'header-data-grid-parcelas',
      editable: false,
      width: 96,
      align: 'center',
      valueGetter: value => toBrazilianDate(value),
    },
    {
      field: 'valor',
      headerAlign: 'center',
      headerName: 'Valor',
      headerClassName: 'header-data-grid-parcelas',
      editable: false,
      width: 134,
      align: 'right',
      type: 'number',
      valueGetter: value => getMoneyMask(value || 0),
    },
    {
      field: 'valorCliente',
      headerAlign: 'center',
      headerName: 'Valor para Cliente',
      headerClassName: 'header-data-grid-parcelas',
      editable: enableValorCliente,
      width: 134,
      align: 'right',
      type: 'number',
      valueSetter: (value, row) => onValueSetter(value, row),
      valueFormatter: value => getMoneyMask(value || 0),
    },
    {
      field: 'valorABE',
      headerAlign: 'center',
      headerName: 'Valor HB',
      headerClassName: 'header-data-grid-parcelas',
      editable: false,
      width: 134,
      align: 'right',
      type: 'number',
      valueGetter: value => getMoneyMask(value || 0),
    },
  ];

  const onClickConfirmarDistribuicaoPersonalizada = useCallback(() => {
    salvarDistribuicaoPersonalizada(idAcordo, parcelasAlteradas);
    setOpenSalvarDistribuicaoPersonalizada(false);
    setOpenDialogDistribuicaoPersonalizada(false);
  }, [parcelasAlteradas, idAcordo, setOpenDialogDistribuicaoPersonalizada]);

  const onClickConfirmarDescartarPersonalizacao = useCallback(() => {
    descartarDistribuicaoPersonalizada(idAcordo);
    setOpenDescartarPersonalizacao(false);
    setOpenDialogDistribuicaoPersonalizada(false);
  }, [idAcordo, setOpenDialogDistribuicaoPersonalizada]);


  const onLimpar = useCallback(() => {
    setTableContent([]);
  }, []);

  const onClose = useCallback(() => {
    setOpenDialogDistribuicaoPersonalizada(false);
  }, [setOpenDialogDistribuicaoPersonalizada]);

  const handleEditCellChange = (updatedRows, originalRows) => {
    if (updatedRows.valorABE < 0) {
      toast.error('O valor digitado no campo Valor para Cliente deve ser menor ou igual ao valor da parcela.', { style: { width: '392px' } });
      updatedRows.valorCliente = originalRows.valorCliente;
      updatedRows.valorABE = originalRows.valorABE;
    }
    const objIndex = parcelasAlteradas.findIndex(obj => obj.nroParcela === updatedRows.nroParcela);

    if (objIndex >= 0) {
      parcelasAlteradas[objIndex].valorABE = updatedRows.valorABE;
      parcelasAlteradas[objIndex].valorCliente = updatedRows.valorCliente;
    } else {
      setParcelasAlteradas([...parcelasAlteradas, { nroParcela: updatedRows.nroParcela, valorABE: updatedRows.valorABE, valorCliente: updatedRows.valorCliente }]);
    }
    if (updatedRows.valorABE < 0) {
      return originalRows;
    }

    return updatedRows;
  };

  useEffect(() => {
    if (tableContent.length === 0) {
      buscarParcelasDistribuicaoPersonalizada(idAcordo).then(((response) => {
        setTableContent(response.data.parcelaDistribuicaoPersonalizadaQueryList);
        setValorTotal(response.data.valorTotal);

        if (response.data.parcelaDistribuicaoPersonalizadaQueryList.find(element => element.idStatusParcelaAcordo === 0)) {
          setEnableValorCliente(false);
          setDisableBotaoDescartarPersonalizacao(true);
          setOpenDialogApenasLeitura(true);
        } else {
          setEnableValorCliente(true);
          setDisableBotaoDescartarPersonalizacao(response.data.parcelaDistribuicaoPersonalizadaQueryList[0].valorCliente === null);
        }
      }));
    }
  }, [idAcordo, tableContent]);

  return (
    <>
      <OptionDialogNew
        open={openDialogSalvarDistribuicaoPersonalizada}
        confirmLabel="Ok"
        cancelLabel="Cancelar"
        onClickCancel={() => setOpenSalvarDistribuicaoPersonalizada(false)}
        onClickConfirm={onClickConfirmarDistribuicaoPersonalizada}
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>
            A distribuição de valores deste acordo seguirá os valores configurados, deseja continuar?
          </p>
        </div>
      </OptionDialogNew>
      <OptionDialogNew
        open={openDialogDescartarPersonalizacao}
        confirmLabel="Ok"
        cancelLabel="Cancelar"
        onClickCancel={() => setOpenDescartarPersonalizacao(false)}
        onClickConfirm={onClickConfirmarDescartarPersonalizacao}
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>
            Ao descartar a personalização, a distribuição de valores será baseada nos parâmetros configurados para este cliente, deseja continuar?
          </p>
        </div>
      </OptionDialogNew>
      <OptionDialogNew
        open={openDialogApenasLeitura}
        confirmLabel="Voltar"
        onClickConfirm={() => setOpenDialogApenasLeitura(false)}
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>
            Após o pagamento da primeira parcela, a distribuição personalizada não pode ser alterada
          </p>
        </div>
      </OptionDialogNew>
      <div style={{ display: 'flex' }}>
        <Typography component="p" className={styles.typography}>
          Para editar os valores, clique 2 vezes no campo
          { ' " ' }
          Valor para Cliente
          { ' " ' }
          :
        </Typography>

        <div className={styles.cards__external__root_parcelas}>
          <div className={styles.cards__ativos}>
            <div className={styles.cards__internal__root}>
              <div className={styles.icon__ativos}>
                <FaHandshake />
              </div>
              <div className={styles.cards__infos__root}>
                <Typography component="p" className={styles.infos__title}>
                  Valor do Acordo
                </Typography>
                <div className={styles.infos__counter}>
                  {getMoneyMask(valorTotal)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Box
        className={styles.headerDataGridParcelas}
      >
        <Grid24
          xs={24}
          style={{
            marginBottom: '15px', marginTop: '12px', width: '587px', height: '290px',
          }}
        >
          <DataGrid
            processRowUpdate={(params, event) => handleEditCellChange(params, event)}
            disableColumnMenu
            disableColumnResize
            disableColumnSorting
            rowHeight={30}
            hideFooter
            rows={tableContent}
            columns={columns}
            getRowId={row => row.nroParcela}
            columnHeaderHeight={30}
          />
        </Grid24>
      </Box>

      <Grid24 xs={24} style={{ marginBottom: '15px', marginTop: '12px' }}>
        <TextoPadraoButton
          variant="outlined"
          style={{ marginRight: '20px' }}
          onClick={() => setOpenDescartarPersonalizacao(true)}
          disabled={disableBotaoDescartarPersonalizacao}
        >
          <DeleteForeverIcon size={16} />
          <span>Descartar Personalização</span>
        </TextoPadraoButton>

        <TextoPadraoButton
          variant="outlined"
          onClick={onLimpar}
          disabled={disableBotaoLimpar}
        >
          <FaEraser size={18} />
          <span>Limpar</span>
        </TextoPadraoButton>
      </Grid24>

      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          disabled={disableBotaoSalvar}
          onClick={() => setOpenSalvarDistribuicaoPersonalizada(true)}
        >
          OK
        </Button>
      </div>
    </>
  );
}

export default DialogDistribuicaoPersonalizada;
