import React, { useCallback } from 'react';
import Grid24 from '../../../../../UI/Grid24/Grid24';
import InputField from '../../../../../UI/Field/Input/InputField';
import IconButtonTooltip from '../../../../../UI/IconButtonTooltip/IconButtonTooltip';
import useUtilStyles from '../../../../../../helpers/styles/utils.styles';
import { NOME_FIELD, VALOR_FIELD } from '../dadosRetorno.constants';
import { getRequiredLabel } from '../../../../../../helpers/utils/utils';

function DadoRetorno({
  model,
  setAttribute,
  setFocus,
  index,
  disabled,
  onRemove,
}) {
  const utilStyles = useUtilStyles();
  const onFocusAttributeHandler = useCallback((event) => {
    let fieldName = event.target.name;

    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    setFocus(fieldName, index);
  }, [setFocus, index]);

  const onChangeAttributeHandler = useCallback((event) => {
    const { name, value } = event.target;
    setAttribute(name, value, index);
  }, [setAttribute, index]);

  return (
    <Grid24 container spacing={1}>
      <Grid24 xs={24} sm={7}>
        <InputField
          isForm
          fullWidth
          disabled={disabled}
          label={getRequiredLabel(NOME_FIELD, !disabled)}
          name={model.nome.name}
          value={model.nome.value}
          error={model.nome.error}
          errorMessage={model.nome.errorMessage}
          maxLength={30}
          onChange={onChangeAttributeHandler}
          onFocus={onFocusAttributeHandler}
        />
      </Grid24>
      <Grid24 xs={24} sm={15}>
        <InputField
          isForm
          fullWidth
          disabled={disabled}
          label={getRequiredLabel(VALOR_FIELD, !disabled)}
          name={model.valor.name}
          value={model.valor.value}
          error={model.valor.error}
          errorMessage={model.valor.errorMessage}
          maxLength={70}
          onChange={onChangeAttributeHandler}
          onFocus={onFocusAttributeHandler}
        />
      </Grid24>
      <Grid24 item xs={24} sm={2} justify="end" className={utilStyles.top7}>
        {!disabled && (
          <IconButtonTooltip
            type="trash"
            hint="Excluir"
            onClick={onRemove}
          />
        )}
      </Grid24>
    </Grid24>
  );
}

export default DadoRetorno;
