import React from 'react';
import { withRouter } from 'react-router';
import InputField from '../../../../components/UI/Field/Input';
import Grid24 from '../../../../components/UI/Grid24';
import FormWrapperWithTitle from '../../../../components/UI/Wrapper/Form/FormWrapperWithTitle';

function DadosAdvogado({
  podeModificarFase, onChangeHandler, onFocusHandler, isEditMode, formFields,
}) {
  return (
    <Grid24 container isFormContainer>
      <FormWrapperWithTitle title="Dados do Advogado do devedor">
        <Grid24 container isFormContainer spacing="1">
          <Grid24 xs={24} sm={7}>
            <InputField
              isForm
              fullWidth
              label="Nome"
              value={formFields.nomeAdvogado.value}
              disabled={!podeModificarFase || !isEditMode}
              name="nomeAdvogado"
              onChange={onChangeHandler}
              onFocus={onFocusHandler}

            />
          </Grid24>
          <Grid24 xs={24} sm={4}>
            <InputField
              isForm
              fullWidth
              label="Telefone"
              value={formFields.telefoneAdvogado.value}
              disabled={!podeModificarFase || !isEditMode}
              name="telefoneAdvogado"
              onChange={onChangeHandler}
              onFocus={onFocusHandler}

            />
          </Grid24>
        </Grid24>
      </FormWrapperWithTitle>
    </Grid24>
  );
}

export default withRouter(DadosAdvogado);
