import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  customIconTrash: {
    top: '7px',
  },
  labelStyles: {
    fontSize: '12px',
    marginTop: '19px',
  },
});
