import React, { useCallback } from 'react';
import Grid24 from '../../../../../UI/Grid24/Grid24';
import NumberInput from '../../../../../UI/Field/Number/NumberInput';
import { getRequiredLabel } from '../../../../../../helpers/utils/utils';
import IconButtonTooltip from '../../../../../UI/IconButtonTooltip/IconButtonTooltip';
import MoneyField from '../../../../../UI/Field/Money/MoneyField';
import {
  FIELD_FINAL, FIELD_INITIAL, PRAZO, TOOLTIP_TRASH,
} from './prazoExpiracao.constants';
import useStyles from './prazoExpiracao.styles';

function FaixaPrazoExpiracao({
  index,
  model,
  disabled,
  setAttribute,
  setFocus,
  onRemove,
}) {
  const styles = useStyles();
  const onFocusAttributeHandler = useCallback((event) => {
    const fieldName = event.target.name;

    /* if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    } */
    setFocus(fieldName, index);
  }, [setFocus, index]);

  const onChangeAttributeHandler = useCallback((event) => {
    const { name, value } = event.target;
    setAttribute(name, value, index);
  }, [setAttribute, index]);

  const disabledFirst = (disabled || index === 0);

  return (
    <Grid24 container spacing={1}>
      <Grid24 xs={24} sm={4}>
        <MoneyField
          isForm
          fullWidth
          disabled={disabledFirst}
          maxlength={16}
          label={getRequiredLabel(FIELD_INITIAL, !disabledFirst)}
          name={model.valorInicial.name}
          value={model.valorInicial.value}
          error={model.valorInicial.error}
          errorMessage={model.valorInicial.errorMessage}
          onChange={onChangeAttributeHandler}
          onFocus={onFocusAttributeHandler}
        />
      </Grid24>
      <Grid24 xs={24} sm={4}>
        <MoneyField
          isForm
          fullWidth
          disabled={disabled}
          maxlength={16}
          label={getRequiredLabel(FIELD_FINAL, !disabled)}
          name={model.valorFinal.name}
          value={model.valorFinal.value}
          error={model.valorFinal.error}
          errorMessage={model.valorFinal.errorMessage}
          onChange={onChangeAttributeHandler}
          onFocus={onFocusAttributeHandler}
        />
      </Grid24>
      <Grid24 xs={24} sm={3}>
        <NumberInput
          isForm
          fullWidth
          numberType="integer"
          maxValue={999999}
          sideText="(dias)"
          disabled={disabled}
          label={getRequiredLabel(PRAZO, !disabled)}
          error={model.prazoExpiracao.error}
          name={model.prazoExpiracao.name}
          value={model.prazoExpiracao.value}
          errorMessage={model.prazoExpiracao.errorMessage}
          maxLength={model.prazoExpiracao.maxLength}
          onChange={onChangeAttributeHandler}
          onFocus={onFocusAttributeHandler}
        />
      </Grid24>
      {(index !== 0 && !disabled) && (
        <Grid24 item xs={24} sm={2} justify="end" className={styles.customIconTrash}>
          <IconButtonTooltip
            type="trash"
            disabled={disabled}
            hint={TOOLTIP_TRASH}
            onClick={onRemove}
          />
        </Grid24>
      )}
    </Grid24>
  );
}

export default FaixaPrazoExpiracao;
