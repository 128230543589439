import React, { useRef } from 'react';

import { FaEye, FaEyeSlash, FaPalette } from 'react-icons/fa';

import { Divider } from '@material-ui/core';
import { FloatBox } from '../../../components/FloatContainer/FloatBox';

import { ListaPreferencias, Preferencia, PreferenciasButton } from './preferenciasProcessoStyles';

import { loadingInspecaoProcessoController } from '../loadingInspecaoProcessoController';
import { deletarPreferenciaInspecaoUsuarioService, salvarPreferenciaInspecaoUsuarioService } from '../../../services/core/preferenciainspecaousuario/preferenciaInspecaoUsuarioService';

function PreferenciasProcessoComponent({
  listaPreferencias = [],
  loadListaPreferencias = async () => { },
  setLoadings,
}) {
  const preferenciasRef = useRef();
  const preferenciasButtonRef = useRef();

  async function onClickPreferenciaHandler(preferencia) {
    if (preferencia.permitirSelecionar) {
      preferenciasRef.current.handlerShowBox();
      try {
        setLoadings(loadingInspecaoProcessoController.changeLoadingPreferencia(true));
        if (preferencia.isVisivel) {
          await deletarPreferenciaInspecaoUsuarioService(preferencia.idPreferenciaInspecao);
        } else {
          await salvarPreferenciaInspecaoUsuarioService(preferencia.idPreferenciaInspecao);
        }
        await loadListaPreferencias();
      } finally {
        setLoadings(loadingInspecaoProcessoController.changeLoadingPreferencia(false));
      }
    }
  }

  async function onClickExibirTodosAccordionsHandler() {
    preferenciasRef.current.handlerShowBox();
    listaPreferencias.forEach((preferencia) => {
      preferencia.isVisivel = false;
      onClickPreferenciaHandler(preferencia);
    });
  }

  async function onClickOcultarTodosAccordionsHandler() {
    preferenciasRef.current.handlerShowBox();
    listaPreferencias.forEach((preferencia) => {
      preferencia.isVisivel = true;
      onClickPreferenciaHandler(preferencia);
    });
  }

  return (
    <>
      <PreferenciasButton
        ref={preferenciasButtonRef}
        variant="outlined"
        onClick={() => preferenciasRef.current.handlerShowBox()}
      >
        <FaPalette color="#034ddc" />
        <span style={{ color: '#034ddc' }}>Preferências</span>
      </PreferenciasButton>
      <FloatBox ref={preferenciasRef} parentRef={preferenciasButtonRef}>
        <ListaPreferencias>
          <ul>
            {listaPreferencias.map(preferencia => (
              <Preferencia
                key={preferencia.idPreferenciaInspecao}
                disabled={!preferencia.permitirSelecionar}
                onClick={() => onClickPreferenciaHandler(preferencia)}
                value={preferencia.idPreferenciaInspecao}
              >
                {preferencia.isVisivel || !preferencia.permitirSelecionar ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                <span>{preferencia.nome}</span>
              </Preferencia>
            ))}
            <Divider style={{ marginBottom: '4px', marginTop: '4px' }} />
            <Preferencia
              onClick={() => onClickExibirTodosAccordionsHandler(listaPreferencias)}
            >
              <FaEye size={20} />
              <span>Exibir todos os Accordions</span>
            </Preferencia>
            <Preferencia
              onClick={() => onClickOcultarTodosAccordionsHandler(listaPreferencias)}
            >
              <FaEyeSlash size={20} />
              <span>Ocultar todos os Accordions</span>
            </Preferencia>
          </ul>
        </ListaPreferencias>
      </FloatBox>
    </>
  );
}

export const PreferenciasProcesso = React.forwardRef(PreferenciasProcessoComponent);
