export default {
  P: 'Pública',
  R: 'Restrita',

  lookup(type) {
    if (!type) {
      return null;
    }

    return this[type];
  },

  altLookup(type) {
    if (type === 'R') {
      return 'RESTRITA';
    }
    return 'PUBLICA';
  },

  isPublica(type) {
    return type === 'PUBLICA';
  },
};
