import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { withRouter } from 'react-router-dom';
import SwapList from './SwapList';
import NumberInput from '../../../UI/Field/Number/NumberInput';
import Grid24 from '../../../UI/Grid24/Grid24';
import { useCrudUsuarioDispatch2, useCrudUsuarioSelector2 } from '../../../../store/Usuario/V2/CrudUsuarioProvider';
import {
  configureDialogReferenteEquipeAction,
  onChangeActions,
  onChangeControls,
  setMembrosEquipeAction,
} from '../../../../store/Usuario/V2/crudUsuario.store';
import { getUsuariosElegiveisFazerParteEquipeService } from '../../../../services/core/usuario/usuario.services';
import {
  INATIVAR_GERENTE,
  INATIVAR_MEMBRO,
  TROCAR_USUARIO_COMERCIAL_GERENTE,
  TROCAR_USUARIO_COMERCIAL_MEMBRO,
} from '../../../../store/Usuario/V2/crudUsuario.constans';
import OptionDialogNew from '../../../UI/Dialogs/OptionDialog/OptionDialogNew';

// @TODO Código duplicado, futuramente extrair...
function makeUsuariosParaFormarEquipe(usuario) {
  return {
    id: usuario?.idUsuario,
    label: usuario?.nomeUsuario,
    rightChecked: usuario?.pertenceEquipe,
    currentChecked: false,
  };
}


function UsuarioEquipe({
  changeHandler,
  onFocusHandler,
  idUsuarioEdicao,
  isInspectMode = false,
  isInsertMode = false,
}) {
  const dispatch = useCrudUsuarioDispatch2();

  const porcentagemComissaoEquipe = useCrudUsuarioSelector2(state => state.controls.porcentagemComissaoEquipe.copy());
  const dialogReferenteEquipe = useCrudUsuarioSelector2(state => state.actions.dialogReferenteEquipe);
  const tipoUsuarioAlterar = useCrudUsuarioSelector2(state => state.actions.tipoUsuarioAlterar);
  const statusUsuarioAlterar = useCrudUsuarioSelector2(state => state.actions.statusUsuarioAlterar);
  const limparListaMembros = useCrudUsuarioSelector2(state => state.actions.limparListaMembros);
  const listaUsuariosElegiveisEquipe = useCrudUsuarioSelector2(state => state.actions.listaUsuariosElegiveisEquipe);
  const idsUsuariosMembros = useCrudUsuarioSelector2(state => state.controls.idsUsuariosMembros);

  const [usuariosElegiveis, setUsuariosElegiveis] = useState([]);
  const disabledComissao = useMemo(() => usuariosElegiveis?.filter(parent => !!parent.rightChecked).length === 0, [usuariosElegiveis]);

  useEffect(() => {
    if (disabledComissao) {
      dispatch(onChangeControls('porcentagemComissaoEquipe', ''));
    }
  }, [disabledComissao, dispatch]);

  const buscarUsuariosEquipeCadastrar = useCallback(async () => {
    try {
      dispatch(onChangeActions('loadingPage', true));
      const response = await getUsuariosElegiveisFazerParteEquipeService(null);
      setUsuariosElegiveis(response.data.map(makeUsuariosParaFormarEquipe));
    } catch {
      setUsuariosElegiveis([]);
    } finally {
      dispatch(onChangeActions('loadingPage', false));
    }
  }, [dispatch]);


  useEffect(() => {
    if (limparListaMembros && isInsertMode) {
      buscarUsuariosEquipeCadastrar().then();
    }
  }, [limparListaMembros, dispatch, buscarUsuariosEquipeCadastrar, isInsertMode]);

  useEffect(() => {
    if (isInsertMode) {
      buscarUsuariosEquipeCadastrar().then();
    }
  }, [isInsertMode, buscarUsuariosEquipeCadastrar]);

  useEffect(() => {
    if (listaUsuariosElegiveisEquipe) {
      setUsuariosElegiveis(listaUsuariosElegiveisEquipe);
    }
  }, [dispatch, listaUsuariosElegiveisEquipe]);


  const setParentList = useCallback((usuariosMembroEquipeParam) => {
    setUsuariosElegiveis(usuariosMembroEquipeParam);
  }, []);

  const removerMembroEquipe = useCallback(() => {
    setUsuariosElegiveis(old => ([...old.filter(membroParaDesativar => membroParaDesativar.id === idUsuarioEdicao).map((membroForaEquipe) => {
      membroForaEquipe.rightChecked = false;
      membroForaEquipe.currentChecked = false;
      return membroForaEquipe;
    })]));
  }, [idUsuarioEdicao]);

  const apagarEquipeGerente = useCallback(() => {
    setUsuariosElegiveis(old => ([...old.map((equipeExcluir) => {
      equipeExcluir.rightChecked = false;
      equipeExcluir.currentChecked = false;
      return equipeExcluir;
    })]));
    dispatch(setMembrosEquipeAction(null, false, true));
  }, [dispatch]);

  const onClickConfirm = useCallback(() => {
    if (dialogReferenteEquipe.origin === TROCAR_USUARIO_COMERCIAL_GERENTE) {
      dispatch(onChangeControls('idTipoUsuario', tipoUsuarioAlterar));
      apagarEquipeGerente();
    }
    if (dialogReferenteEquipe.origin === TROCAR_USUARIO_COMERCIAL_MEMBRO) {
      dispatch(onChangeControls('idTipoUsuario', tipoUsuarioAlterar));
      removerMembroEquipe();
    }
    if (dialogReferenteEquipe.origin === INATIVAR_GERENTE) {
      dispatch(onChangeControls('statusUsuario', statusUsuarioAlterar));
      apagarEquipeGerente();
    }
    if (dialogReferenteEquipe.origin === INATIVAR_MEMBRO) {
      dispatch(onChangeControls('statusUsuario', statusUsuarioAlterar));
      removerMembroEquipe();
    }

    dispatch(configureDialogReferenteEquipeAction(false, null));
  }, [dispatch, statusUsuarioAlterar, tipoUsuarioAlterar, dialogReferenteEquipe.origin, apagarEquipeGerente, removerMembroEquipe]);

  const onClickCancel = useCallback(() => {
    dispatch(configureDialogReferenteEquipeAction(false, null));
  }, [dispatch]);

  const addPayloadList = useCallback((idUsuarioParam) => {
    if (idsUsuariosMembros.length === 0) {
      dispatch(onChangeControls('porcentagemComissaoEquipe', 20));
    }
    dispatch(setMembrosEquipeAction(idUsuarioParam, true, false));
  }, [dispatch, idsUsuariosMembros]);

  const removePayloadList = useCallback((idUsuarioParam) => {
    dispatch(setMembrosEquipeAction(idUsuarioParam, false, false));
  }, [dispatch]);

  return (
    <>
      <OptionDialogNew
        open={dialogReferenteEquipe.open}
        confirmLabel="Sim"
        cancelLabel="Não"
        onClickConfirm={onClickConfirm}
        onClickCancel={onClickCancel}
      >
        <div style={{ fontSize: '18px' }}>
          <p>
            {dialogReferenteEquipe.message}
          </p>
        </div>
      </OptionDialogNew>
      <Grid24 container isFormContainer spacing={1} style={{ border: '1px solid #a2a0a0' }}>
        <Grid24 item xs={24} sm={24}>
          <SwapList
            leftTitle="Selecione os Membros da Equipe"
            rightTitle="Membros da Equipe"
            parentList={usuariosElegiveis}
            setParentList={setParentList}
            addPayloadList={addPayloadList}
            removePayloadList={removePayloadList}
            disabled={isInspectMode}
          />
        </Grid24>
        <Grid24 container isFormContainer spacing={1} style={{ marginTop: '16px', marginBottom: '8px', marginLeft: '5px' }}>
          <Grid24 item xs={24} sm={4}>
            <NumberInput
              fullWidth
              isForm
              disabled={isInspectMode || disabledComissao}
              sideText="(%)"
              numberType="percentage"
              maxValue={100}
              label="Comissão sobre equipe"
              name="porcentagemComissaoEquipe"
              value={porcentagemComissaoEquipe?.value}
              onChange={changeHandler}
              onFocus={onFocusHandler}
            />
          </Grid24>
        </Grid24>
      </Grid24>
    </>
  );
}

export default withRouter(UsuarioEquipe);
