import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  root: {
    padding: '38px 38px 25px 38px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  icon: {
    '& img': {
      height: '60px',
      width: '60px',
    },
  },
  content: {
    marginTop: '28px',
    marginBottom: '25px',
  },
  footer: {
    '& button': {
      width: '100px',
      paddingLeft: 'auto',
      paddingRight: 'auto',
    },
  },
});
