import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function LockClock(props) {
  const bloqueioAutomatico = 'M11.0449 7.27257C11.554 7.27257 12.0412 7.3453 12.4994 7.48348V6.54531C12.4994 5.74533 11.8449 5.0908 11.0449 5.0908H10.3176V3.63629C10.3176 1.62906 8.68857 0 6.68134 0C4.67411 0 3.04505 1.62906 3.04505 3.63629V5.0908H2.3178C1.51781 5.0908 0.863281 5.74533 0.863281 6.54531V13.8179C0.863281 14.6179 1.51781 15.2724 2.3178 15.2724H6.87043C6.33771 14.5096 6.0244 13.6153 5.96459 12.6868C5.90477 11.7583 6.10074 10.8312 6.53117 10.0063C6.9616 9.18144 7.61001 8.49041 8.40585 8.00841C9.20168 7.52641 10.1145 7.2719 11.0449 7.27257ZM4.49957 3.63629C4.49957 2.42904 5.47409 1.45451 6.68134 1.45451C7.88859 1.45451 8.86311 2.42904 8.86311 3.63629V5.0908H4.49957V3.63629Z'
   + 'M11.0425 8.72656C9.03531 8.72656 7.40625 10.3556 7.40625 12.3628C7.40625 14.3701 9.03531 15.9991 11.0425 15.9991C13.0498 15.9991 14.6788 14.3701 14.6788 12.3628C14.6788 10.3556 13.0498 8.72656 11.0425 8.72656ZM12.497 13.8174C12.3516 13.9628 12.1261 13.9628 11.9807 13.8174L10.7807 12.6174C10.7129 12.5498 10.6738 12.4586 10.6716 12.3628V10.5447C10.6716 10.3411 10.8316 10.1811 11.0353 10.1811C11.2389 10.1811 11.3989 10.3411 11.3989 10.5447V12.2101L12.4898 13.301C12.6425 13.4465 12.6425 13.6719 12.497 13.8174Z';
  return (
    <SvgIcon transform="scale(1.13)" viewBox="-4 -4 24 24" {...props}>
      <path d={bloqueioAutomatico} />
    </SvgIcon>
  );
}
