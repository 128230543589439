import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  wrapper: {
    display: 'flex',
  },
  text: {
    textTransform: 'none',
    padding: '5px auto',
    border: 'none',
    borderRadius: '46px',
    fontWeight: 'bold',
    color: 'white',
  },
  primary: {
    backgroundImage: 'linear-gradient(to bottom, #005dff, #4c547e) !important',
    '&:hover': {
      backgroundImage: 'linear-gradient(to bottom, #005dffAA, #4c547eAA) !important',
    },
  },
  dataFu: {
    backgroundColor: '#f1f4f9 !important',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    '& span': {
      color: 'black',
    },
    '&:hover': {
      backgroundColor: '#034ddc !important',
      '& span': {
        color: 'white',
      },
    },
  },
  success: {
    backgroundColor: '#4caf50',
    '&:hover': {
      backgroundColor: '#4caf50AA',
    },
  },
  white: {
    backgroundColor: 'white',
    border: '1px solid #034ddc',
    color: '#034ddc',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  disabled: {
    textTransform: 'none',
    // padding: '5px 55px',
    border: 'none',
    borderRadius: '46px',
    backgroundImage: 'none !important',
    backgroundColor: 'white !important',
    opacity: '0.85',
    color: 'black',
    cursor: '',
  },
  loading: {
    marginLeft: '9px',
    marginTop: '2px',
  },
  children: {
    display: 'flex',
    justifyContent: 'center',
  },
  error: {
    backgroundColor: '#ff000f',
    '&:hover': {
      backgroundColor: '#ff000fAA',
    },
  },
  lupa: {
    backgroundColor: '#ff1381',
    '&:hover': {
      backgroundColor: '#ff1381',
    },
  },
  custom: {
    backgroundColor: props => props.customColor,
    '&:hover': {
      backgroundColor: props => props.customColor,
    },
  },
}));
