import {
  applyMiddleware, combineReducers, createStore,
} from 'redux';

import createSagaMiddleware from 'redux-saga';

import loginReducer from './Login/login.store';
import themeReducer from './theme.store';

import loadSelectors from './Global/LoadSelectors';

import pesquisaFilialReducer from './Filial/Pesquisar/pesquisarFilial.store';
import pesquisaUsuarioReducer from './Usuario/Pesquisa/pesquisarUsuario.store';
import crudRamoAtividadeReducer from './RamoAtividade/Crud/crudRamoAtividade.store';
import pesquisaClienteReducer from './Cliente/Pesquisar/pesquisaCliente.store';
import processarBorderosReducer from './Borderos/Processar/processarBorderos.store';
import importarBorderosReducer from './Borderos/Importar/importarBordero.store';
import historicoASReducer from './AcordoSeguro/Historico/historicoAS.store';
import relatoriosASReducer from './AcordoSeguro/Relatorios/relatoriosAS.store';
import acordosAsReducer from './AcordoSeguro/Acordos/acordosAs.store';
import commonGlobalReducer from './Global/CommonGlobal/commonGlobal.store';
import { menuAccessPageReducer } from './MenuAccessPage/menuAccessPageStore';
import { configSageMiddleware } from './createSagaMiddleware';

const rootStore = combineReducers({
  authentication: loginReducer,
  pesquisaFilial: pesquisaFilialReducer,
  theme: themeReducer,
  pesquisaUsuario: pesquisaUsuarioReducer,
  pesquisaCliente: pesquisaClienteReducer,
  processarBorderos: processarBorderosReducer,
  crudRamoAtividade: crudRamoAtividadeReducer,
  importarBorderos: importarBorderosReducer,
  historicoAS: historicoASReducer,
  relatoriosAS: relatoriosASReducer,
  acordosAs: acordosAsReducer,
  selectors: loadSelectors.loadSelectorsReducer,
  previousPageStates: menuAccessPageReducer,
  commonGlobal: commonGlobalReducer,
});

export const sageMiddleware = createSagaMiddleware();

const store = createStore(rootStore, applyMiddleware(sageMiddleware));
configSageMiddleware(sageMiddleware);

export default store;
