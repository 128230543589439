import React, { useCallback, useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton } from '@material-ui/core';
import AcompanhamentoWrapper from './Wrapper/AcompanhamentoWrapper';
import AcompanhamentoItem from './Item/AcompanhamentoItem';
import useUtilStyle from '../../../../helpers/styles/utils.styles';
import useStyle from './acompanhamentoCliente.styles';
import InserirAnotacaoCliente from './Anotacao/InserirAnotacaoCliente';
import { useCrudClienteDispatch, useCrudClienteSelector } from '../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import { convertDataToObject } from './AcompanhamentoCliente.utils';
import Grid24 from '../../../UI/Grid24/Grid24';
import CheckboxField from '../../../UI/Field/Checkbox/CheckboxField';
import { CONTEUDO, VISUALIZAR_PARTIR } from './acompanhamentoCliente.constants';
import DateField from '../../../UI/Field/Date/DateField';
import {
  setAttributesAcompanhamentoAction,
  setRequestFieldsAcompanhamentoErrorAction,
  setRequestFieldsAcompanhamentoValueAction,
  setScrollDirectionAction,
} from '../../../../store/Cliente/V2/Crud/crudCliente.store';
import { getAcompanhamentoClienteAction } from '../../../../store/Cliente/V2/Crud/crudCliente.saga';
import { DateUtils } from '../../../../helpers';
import InputField from '../../../UI/Field/Input/InputField';


const buildAcompanhamentos = (acompanhamentos, utilStyle, styles) => {
  const content = [];
  const acompanhamentosMap = convertDataToObject(acompanhamentos);
  let i = 0;
  for (const entry of acompanhamentosMap.entries()) {
    content.push(
      <div key={i} className={utilStyle.posRelative}>
        <div className={styles.acompanhamentoItem}>
          <AcompanhamentoItem acompanhamentos={entry} />
        </div>
      </div>,
    );
    i += 1;
  }
  return content;
};

function AcompanhamentoCliente({
  isQueryMode,
  isEditMode,
  isInsertMode,
  isCliente,
}) {
  const utilStyle = useUtilStyle();
  const styles = useStyle();
  const dispatch = useCrudClienteDispatch();

  const acompanhamentos = useCrudClienteSelector(states => states.settingsAcompanhamento.acompanhamentos);
  const dataEnvioInicial = useCrudClienteSelector(states => states.settingsAcompanhamento.requestFields.dataEnvioInicial.copy());
  const conteudo = useCrudClienteSelector(states => states.settingsAcompanhamento.requestFields.conteudo.copy());
  const sendDataFollowUp = useCrudClienteSelector(states => states.settingsAcompanhamento.sendDataFollowUp);


  const onChangeCheckedHandler = useCallback((event) => {
    const { checked } = event.target;
    dispatch(setAttributesAcompanhamentoAction('sendDataFollowUp', checked));
    if (!checked) {
      dispatch(setRequestFieldsAcompanhamentoValueAction('dataEnvioInicial', ''));
      dispatch(setRequestFieldsAcompanhamentoErrorAction('dataEnvioInicial', false));
    }
  }, [dispatch]);

  const onFocusHandler = useCallback((event) => {
    const { name } = event.target;
    dispatch(setRequestFieldsAcompanhamentoErrorAction(name, false));
  }, [dispatch]);

  const onChangeAcompanhamentoFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setRequestFieldsAcompanhamentoValueAction(name, value));
  }, [dispatch]);

  const buscarAcompanhamentoHandler = useCallback(() => {
    dispatch(setScrollDirectionAction('BOTTOM'));
    dispatch(getAcompanhamentoClienteAction(isCliente, true));
  }, [dispatch, isCliente]);

  function drawVerticalLine() {
    return isInsertMode || acompanhamentos.length === 0;
  }

  // Carrega comportamento inicial dos componentes.
  useEffect(() => {
    dispatch(setAttributesAcompanhamentoAction('sendDataFollowUp', true));
    dispatch(setRequestFieldsAcompanhamentoValueAction('dataEnvioInicial', DateUtils.getLastDays()));
  }, [dispatch]);

  return (
    <>
      <Grid24 container justify="flex-end" isFormContainer spacing="1">
        <Grid24 item xs={24} sm={5} justify="flex-end" className={styles.checkbox}>
          <CheckboxField
            disabled={isInsertMode}
            checked={sendDataFollowUp}
            label={VISUALIZAR_PARTIR}
            onChange={onChangeCheckedHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={3} className={utilStyle.mt16}>
          <DateField
            maxDate={new Date()}
            disabled={!sendDataFollowUp || isInsertMode}
            name={dataEnvioInicial.name}
            value={dataEnvioInicial.value}
            error={dataEnvioInicial.error}
            errorMessage={dataEnvioInicial.errorMessage}
            onChange={onChangeAcompanhamentoFieldsHandler}
            onFocus={onFocusHandler}
          />
        </Grid24>
        <Grid24 item xs={24} justify="flex-end" sm={9}>
          <InputField
            label={CONTEUDO}
            name={conteudo.name}
            value={conteudo.value}
            fullWidth
            disabled={isInsertMode}
            onChange={onChangeAcompanhamentoFieldsHandler}
          />
        </Grid24>
        <Grid24 item xs={24} sm={1}>
          <div>
            <IconButton
              onClick={buscarAcompanhamentoHandler}
              disabled={isInsertMode}
              type="submit"
              className={styles.button}
            >
              <SearchIcon />
            </IconButton>
          </div>
        </Grid24>
      </Grid24>
      <AcompanhamentoWrapper
        className={utilStyle.mt16}
        drawVerticalLine={drawVerticalLine()}
        isCliente={isCliente}
        isQueryMode={isQueryMode}
      >
        {isInsertMode && <div />}
        {(((isQueryMode || isEditMode) && (acompanhamentos !== undefined)))
          && buildAcompanhamentos(acompanhamentos, utilStyle, styles)}
      </AcompanhamentoWrapper>
      <InserirAnotacaoCliente
        disabled={isQueryMode || isInsertMode}
        isEditMode={isEditMode}
        isCliente={isCliente}
        onFocus={onFocusHandler}
      />
    </>
  );
}

export default React.memo(AcompanhamentoCliente);
