import React from 'react';
import WrapperSubTitleForm from '../../../../../../UI/WrapperSubTitleForm/WrapperSubTitleForm';
import {
  ESTATISTICA_TITLE, PRIMEIRO_TITLE, QUANTIDADE_TITLE, ULTIMO_TITLE,
} from './processamentoEstatistica.constants';
import Grid24 from '../../../../../../UI/Grid24/Grid24';
import useStyles from './processamentoEstatistica.style';
import { useCrudClienteSelector } from '../../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';

function ProcessamentoEstatistica() {
  const styles = useStyles();

  const dataPrimeiroBordero = useCrudClienteSelector(states => states.controls.dataPrimeiroBordero.copy());
  const dataUltimoBordero = useCrudClienteSelector(states => states.controls.dataUltimoBordero.copy());
  const qtdeBorderoEnviado = useCrudClienteSelector(states => states.controls.qtdeBorderoEnviado.copy());


  return (
    <>
      <WrapperSubTitleForm title={ESTATISTICA_TITLE}>
        <Grid24 isFormContainer container spacing="1" className={styles.title}>
          <Grid24 xs={24} sm={10}>
            {PRIMEIRO_TITLE}
            {dataPrimeiroBordero.value}
          </Grid24>
          <Grid24 xs={24} sm={10}>
            {ULTIMO_TITLE}
            {dataUltimoBordero.value}
          </Grid24>
        </Grid24>
        <Grid24 isFormContainer container spacing="1" className={styles.titleBottom}>
          <Grid24 xs={24} sm={10}>
            {QUANTIDADE_TITLE}
            {qtdeBorderoEnviado.value}
          </Grid24>
        </Grid24>
      </WrapperSubTitleForm>
    </>
  );
}

export default ProcessamentoEstatistica;
