import { put, select, takeLatest } from 'redux-saga/effects';
import {
  onAcaoCrudAction,
  onChangePageAction,
  onChangeSizeAction,
  onContentErrorAction,
  onIdRamoEditAction,
  onNomeRamoEditAction,
  onOpenCrudDialogAction,
  onOpenDialogRamoAction,
  onSetActions,
  onTableContentAction, setOnlyLoadingAction,
  shouldUpdateContentRamoAction,
} from './pesquisaRamoAtividade.store';
import {
  findRamoAtividade,
  findRamoAtividadeById,
  deleteRamoAtividade,
} from '../../../services/core/ramoAtividade/ramoAtividade.services';
import { getExceptionMessage, isNotFoundException } from '../../../helpers/utils/utils';
import { EDITAR_ACTION } from '../../../pages/RamoAtividade/Pesquisa/pesquisarRamoAtividade.constants';

const FIND_RAMO_ATIVIDADE = 'FIND_RAMO_ATIVIDADE_PESQUISA';
const CONFIGURE_EDIT_RAMO_ATIVIDADE = 'CONFIGURE_EDIT_RAMO_ATIVIDADE_PESQUISA';
const DELETAR_RAMO_ATIVIDADE = 'DELETAR_RAMO_ATIVIDADE';

export const findRamoAtividadeAction = (nomeRamoAtividadeParam, page = 0, rowsPerPage = 10) => ({
  type: FIND_RAMO_ATIVIDADE,
  nomeRamoAtividadeParam,
  page,
  rowsPerPage,
});

export const beforeEditRamoAtividadeAction = idRamoAtividade => ({
  type: CONFIGURE_EDIT_RAMO_ATIVIDADE,
  idRamoAtividade,
});

export const deleteRamoAtividadeAction = idRamoAtividade => ({
  type: DELETAR_RAMO_ATIVIDADE,
  idRamoAtividade,
});

function* selectControlStates() {
  const openSnackBar = yield select(states => states.pesquisaRamoAtividade.actions.openSnackBar);
  const removendo = yield select(states => states.pesquisaRamoAtividade.actions.removendo);
  const fail = yield select(states => states.pesquisaRamoAtividade.actions.fail);
  const loading = yield select(states => states.pesquisaRamoAtividade.actions.loading);

  return {
    openSnackBar,
    removendo,
    fail,
    loading,
  };
}

function* findRamoAtividadeHandler(actions) {
  const { openSnackBar, removendo, fail } = yield selectControlStates();
  const shouldUpdateContent = yield select(states => states.pesquisaRamoAtividade.actions.shouldUpdateContent);

  yield put(onSetActions(true, openSnackBar, removendo, fail));
  try {
    const ramosAtividadeResponse = yield findRamoAtividade(
      actions.nomeRamoAtividadeParam,
      actions.page,
      actions.rowsPerPage,
    );
    if (ramosAtividadeResponse.data.content.length === 0 && !shouldUpdateContent) {
      yield put(onSetActions(false, true, false, fail));
    }
    yield put(onTableContentAction(ramosAtividadeResponse.data.content));
    yield put(onChangeSizeAction(ramosAtividadeResponse.data.totalElements));
    yield put(onChangePageAction(actions.page));
    yield put(shouldUpdateContentRamoAction(false));
  } finally {
    yield put(setOnlyLoadingAction(false));
  }
}

function* configureRamoAtividadeEditHandler(actions) {
  const {
    openSnackBar, removendo, fail, loading,
  } = yield selectControlStates();
  yield put(onSetActions(true, openSnackBar, removendo, fail));
  try {
    const response = yield findRamoAtividadeById(actions.idRamoAtividade);
    yield put(onNomeRamoEditAction(response.data.nomeRamoAtividade));
    yield put(onIdRamoEditAction(response.data.idRamoAtividade));
    yield put(onOpenCrudDialogAction(true));
    yield put(onAcaoCrudAction(EDITAR_ACTION));
  } catch (exception) {
    yield put(onContentErrorAction(getExceptionMessage(exception)));
    yield put(onSetActions(loading, openSnackBar, removendo, true));
    yield put(shouldUpdateContentRamoAction(true));
    yield put(onOpenDialogRamoAction(true));
  } finally {
    yield put(onSetActions(false, openSnackBar, removendo, fail));
  }
}

function* deleteRamoAtividadeHandler(actions) {
  let { openSnackBar, removendo, fail } = yield selectControlStates();
  try {
    yield put(onSetActions(true, openSnackBar, removendo, fail));
    yield deleteRamoAtividade(actions.idRamoAtividade);
    yield put(shouldUpdateContentRamoAction(true));
    openSnackBar = false;
  } catch (exception) {
    if (yield isNotFoundException(exception)) {
      yield put(shouldUpdateContentRamoAction(true));
    }
    yield put(onContentErrorAction(getExceptionMessage(exception)));
    fail = true;
    yield put(onSetActions(false, false, false, true));
    yield put(onOpenDialogRamoAction(true));
  } finally {
    yield put(onSetActions(false, openSnackBar, removendo, fail));
  }
}


export default function* watchPesquisaRamoAtividade() {
  yield takeLatest(FIND_RAMO_ATIVIDADE, findRamoAtividadeHandler);
  yield takeLatest(CONFIGURE_EDIT_RAMO_ATIVIDADE, configureRamoAtividadeEditHandler);
  yield takeLatest(DELETAR_RAMO_ATIVIDADE, deleteRamoAtividadeHandler);
}
