import { FaHome, FaFileAlt } from 'react-icons/fa';
import { AiFillDollarCircle } from 'react-icons/ai';
import { MdLock, MdAssignment, MdBuild } from 'react-icons/md';
import { MdAttachFile45deg } from '../components/Icons/MdAttachFile45deg';

export const menuRoutes = [
  {
    Icon: FaHome,
    to: '/',
    text: 'Home',
    iconColor: '#FAFD79',
  },
  {
    Icon: FaFileAlt,
    to: '/processos',
    text: 'Processos',
    iconColor: '#65E2E9',
  },
  {
    name: 'acordo-seguro',
    Icon: MdLock,
    text: 'Acordo Seguro',
    iconColor: '#93F068',
    views: [
      {
        to: '/acordo-seguro/historico',
        text: 'Histórico',
      },
      {
        to: '/acordo-seguro/enviar-sms-email',
        text: 'Enviar SMS/E-mail',
      },
      {
        to: '/acordo-seguro/acordos',
        text: 'Acordos',
      },
      {
        to: '/acordo-seguro/relatorios',
        text: 'Relatórios',
      },
      {
        to: '/acordo-seguro/prazo-expirado',
        text: 'Prazos Expirados',
      },
    ],
  },
  {
    name: 'borderos',
    Icon: MdAttachFile45deg,
    text: 'Borderôs',
    iconColor: '#FFA000',
    userPermissions: [801, 802, 803],
    views: [
      {
        to: '/importar-bordero',
        text: 'Importar',
        userPermissions: [803],
      },
      {
        to: '/incluir-bordero',
        text: 'Incluir',
        userPermissions: [801],
      },
      {
        to: '/processar-borderos',
        text: 'Processar',
        userPermissions: [802],
      },
      {
        to: '/logs-processamentos-borderos',
        text: 'Visualizar Log',
        userPermissions: [802],
      },
      {
        to: '/validar-bordero-correcao',
        text: 'Validar Borderô',
        userPermissions: [803],
      },
    ],
  },
  {
    name: 'cadastros',
    Icon: MdAssignment,
    text: 'Cadastros',
    iconColor: '#C156D2',
    views: [
      {
        to: '/parametros',
        text: 'Parâmetros',
        userPermissions: [601, 602],
      },
      {
        to: '/usuario',
        text: 'Usuários',
      },
      {
        to: '/cliente',
        text: 'Clientes',
      },
      /*
      Funcionalidade desativada.
      Remover comentários para ativar novamente.

      {
        to: '/possivel-cliente',
        text: 'Possíveis Clientes',
      },
      */
      {
        to: '/filial',
        text: 'Filiais',
      },
      {
        to: '/ramo-atividade',
        text: 'Ramos de Atividade',
      },
      {
        to: '/fases',
        text: 'Fases e Pastas',
      },
      {
        to: '/requisicoes',
        text: 'Requisições',
      },
      {
        to: '/textos-padrao',
        text: 'Textos Padrão',
      },
      {
        to: '/titulo-de-compromisso',
        text: 'Títulos de Compromisso',
      },
    ],
  }, {
    name: 'financeiro',
    Icon: AiFillDollarCircle,
    text: 'Financeiro',
    iconColor: '#35C399',
    userPermissions: [1601, 1602, 1603, 1604, 1701, 1702, 1801, 1802, 1803, 1804, 1902, 1207],
    views: [
      /*
      A Funcionalidade de Caixa de filias está desativada.
      Removar este comentário para ativar novamente.
      {
        to: '/caixas-filiais',
        text: 'Caixa de Filiais',
        userPermissions: [1501, 1502, 1503],
      },
      */
      {
        to: '/conta-corrente-comissionado',
        text: 'C/C de Comissionado',
        userPermissions: [1601, 1602, 1603, 1604],
      }, {
        to: '/conta-corrente-honorario',
        text: 'C/C de Honorários',
        userPermissions: [1701, 1702],
      }, {
        to: '/conta-corrente-cliente',
        text: 'C/C de Cliente',
        userPermissions: [1801, 1802, 1803, 1804],
      },
      {
        to: '/recebimento-clientes',
        text: 'Recebimento de Clientes',
        userPermissions: [1902],
      },
      {
        to: '/recebimento-devedores',
        text: 'Recebimento de Devedores',
        userPermissions: [1902],
      },
      {
        to: '/pagamento-direto',
        text: 'Pagamento Direto',
        userPermissions: [1207],
      },
    ],
  }, {
    name: 'ferramentas',
    Icon: MdBuild,
    text: 'Ferramentas',
    iconColor: '#FC4482',
    userPermissions: [1301, 2101, 1210],
    views: [
      {
        to: '/ferramentas/consulta-dinamica',
        text: 'Consulta Dinâmica',
        userPermissions: [1301],
      }, {
        to: '/ferramentas/registrar-boleto',
        text: 'Registrar Boletos',
        userPermissions: [2101],
      },
      {
        to: '/ferramentas/email-entrada-bordero',
        text: 'E-mail Entrada Borderô',
        userPermissions: [1210],
      },
      /*
      {
        to: 'https://app.powerbi.com/view?r=eyJrIjoiNTcwOTgwYjctNGViMi00MTk1LTlhNGItMzg5MWY3NWI3YjM3IiwidCI6ImJiNmFlZmY5LTczYWItNGNmNS1iZDVlLTkyYmM2M2E3NTI2YyJ9',
        text: 'Business Inteligence',
        iconColor: '#65E2E9',
        newTab: true,
      },
      */
    ],
  },
];
