import { makeStyles, Button } from '@material-ui/core';

import styled, { css } from 'styled-components';
import { FaLock } from 'react-icons/fa';
import {
  PaperUI, ButtonUI, SelectorField, PeriodSelector as PeriodSelectorUi,
} from '../../components';
import { LockClock } from '../../assets/Icons/AttachFileIcon/lockClockIcon';
import SubTitleForm from '../../components/UI/SubTitleForm/SubTitleForm';
import {
  TableActionButton,
} from '../../components/UI/Tabela/tableDefaultStyles';


const redIconStyleLockClock = css`
  color: red;
  font-size: 23px;
`;

const redIconStyleFaLock = css`
  color: red;
  font-size: 16px;
`;


export default makeStyles({
  div__check: {
    marginLeft: '9px',
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      marginLeft: '9px',
    },
  },

  div__cards: {
    marginTop: '6px',
  },
  div__buttons: {
    width: '152px',
    height: '35px',
    marginRight: '22px',
  },
  div__align: {
    display: 'flex',
    marginTop: '32px',
    alignSelf: 'self-end',
  },

  div__container: {
    marginLeft: '22px',
    display: 'flex',
  },

  div__dataLancamento: {
    width: '163px',
    marginRight: '-6px',
  },

  div__tipoLancamento: {
    width: '207px',
    marginRight: '8px',
  },

  div__historico: {
    marginLeft: '22px',
    width: '843px',
    marginTop: '11px',
  },

  div__valorLancamento: {
    width: '207px',
  },

  div__checkbox: {
    marginLeft: '30px',
    marginRight: '5.7px',
    marginTop: '26px',
    marginBottom: '24px',
    display: 'inline-grid',
  },

  div__previsaoPrestacao: {
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    marginTop: '8.8px',
    borderTop: 'solid 2px lightgray',
    width: '495px',

    '& p': {

      marginTop: '8.8px',
    },
  },

});

export const Container = styled(PaperUI)`
  padding: 22px;
`;

export const InformationTitle = styled(SubTitleForm)`
   margin-left: 22px;
   margin-bottom: 7px;

`;

export const FieldContainer = styled.div`
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 22px;
`;

export const RootLancamentoComissionado = styled(PaperUI)`
  border-radius: 7px 7px 0px 0px;
`;

export const FooterLancamentoComissionado = styled.footer`
  > div:first-child {
    display: flex;
    justify-content: flex-end;
    padding: 22px;

    > div:first-child {
      width: 100px;
      margin-right: 10px;
    }

    > div:last-child {
      width: 152px;
    }
  }
`;


export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .filters {
    display: flex;
    flex-direction: column;
    width: 589px;
  }

  .additional-info {
    display: flex;
    flex-direction: column;
  }
`;

export const SelectComissionado = styled(SelectorField)`
  &.MuiFormControl-root {
    max-width: 302px;
  }
`;

export const PeriodSelector = styled(PeriodSelectorUi)`
  margin-top: 15px;
`;

export const ButtonImprimir = styled(Button)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
  margin-top: 12px;

  &.MuiButtonBase-root {
    width: 73px;
    padding: 4px 0;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: none;

    color: #000000;

    &.Mui-disabled {
      opacity: 0.64;
    }
  }
`;

export const ResumoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    display: block;
    margin-bottom: 9px;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  >div {
    display: flex;
    justify-content: space-around;

    >div + div {
      margin-left: 8px;
          }
  }
`;

export const CardTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  box-sizing: border-box;
  width: 160px;
  height: 52px;
  border-radius: 5px;

  background-color: ${props => props.color};

  p {
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;

    color: #FFFFFF;
  }

  p:first-child {
    padding-top: 5px;
    margin-Top: 8px;
  }

  p:last-child {
    padding-top: 11px;
    padding-bottom: 13px;

  }
`;

export const CardBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  box-sizing: border-box;
  width: 244px;
  height: 52px;
  border-radius: 5px;

  background-color: ${props => props.color};

  p {
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;

    color: #FFFFFF;
  }

  p:first-child {
    padding-top: 5px;
    margin-Top: 8px;
  }

  p:last-child {
    padding-top: 11px;
    padding-bottom: 13px;

  }
`;

export const ButtonNovo = styled(ButtonUI)`
  align-self: flex-end;
  width: 152px;
`;

export const TableHeader = styled.div`
  display: block;
  margin-left: 15px;
  margin-bottom: 13px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
`;

export const LockClockIcon = styled(LockClock)`
  ${redIconStyleLockClock}
`;

export const FaLockIcon = styled(FaLock)`
  ${redIconStyleFaLock}
`;

export const CustomTableActionButtom = styled(TableActionButton)`
  ${props => props.isCustom && css`
    &.Mui-disabled {
      opacity: 0.32;
    }
  `}
`;

export const ContainerComissionado = styled.div`
  padding: 22px;
`;
