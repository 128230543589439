import React from 'react';

import Paper from '@material-ui/core/Paper';

import useStyles from './PaperUI.styles';

function PaperUI({ children, ...props }) {
  const classes = useStyles();
  return <Paper classes={{ root: classes.wrapper }} {...props}>{children}</Paper>;
}

export default React.memo(PaperUI);
