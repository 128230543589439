import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import useStyles from './relatorioAS.styles';
import {
  onChangeRequestFieldsAction,
  onFocusRequestFieldsAction, resetScreenAction,
  setSnackbarRelatorioASAction,
} from '../../../store/AcordoSeguro/Relatorios/relatoriosAS.store';
import RelatorioAcessoSite from './AcessoSite/RelatorioAcessoSite';
import RelatorioNotificados from './Notificados/RelatorioNotificados';
import LoadingUI from '../../UI/Loading/LoadingUI';
import { SNACKBAR_DURATION } from '../../../helpers/constants/global.constants';
import SnackbarUI from '../../UI/Snackbar/Snackbar';
import PaperUI from '../../UI/Paper/PaperUI';
import CardWrapper from '../../UI/CardWrapper/CardWrapper';
import { BackButton } from '../../../pages/AcordoSeguro/Inspecionar/InspecionarASPage.styles';

function RelatoriosAS({ location }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();

  const loading = useSelector(states => states.relatoriosAS.loading);
  const snackbarRelatorioAS = useSelector(states => states.relatoriosAS.snackbarRelatorioAS);

  useEffect(() => {
    dispatch(resetScreenAction());
  }, [dispatch, location.refreshPage]);

  const onChangeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeRequestFieldsAction(name, value));
  }, [dispatch]);

  const onFocusFieldsHandler = useCallback((event) => {
    const { name } = event.target;
    dispatch(onFocusRequestFieldsAction(name));
  }, [dispatch]);

  const onCloseSnackbarHandler = useCallback(() => {
    dispatch(setSnackbarRelatorioASAction({ open: false }));
  }, [dispatch]);

  const onClickVoltarHandler = useCallback(() => {
    history.replace('/');
  }, [history]);

  return (
    <PaperUI>
      <CardWrapper>
        <LoadingUI show={loading} />
        <SnackbarUI
          open={snackbarRelatorioAS.open}
          autoHideDuration={SNACKBAR_DURATION}
          variant={snackbarRelatorioAS.variant}
          message={snackbarRelatorioAS.message}
          onClose={onCloseSnackbarHandler}
        />
        <div className={styles.div__root}>
          <div className={styles.div__relatoriosAcesso}>
            <RelatorioAcessoSite
              onChange={onChangeFieldsHandler}
              onFocus={onFocusFieldsHandler}
            />
          </div>

          <div className={styles.div__relatoriosNotificado}>
            <RelatorioNotificados
              onChange={onChangeFieldsHandler}
              onFocus={onFocusFieldsHandler}
            />
          </div>
        </div>
      </CardWrapper>
      <BackButton onClick={onClickVoltarHandler}>
        Voltar
      </BackButton>
    </PaperUI>
  );
}

export default withRouter(RelatoriosAS);
