import React, { useCallback } from 'react';
import cx from 'classnames';
import Grid24 from '../UI/Grid24';
import InputField from '../UI/Field/Input';
import CheckboxField from '../UI/Field/Checkbox/CheckboxField';
import useUtilStyles from '../../helpers/styles/utils.styles';
import AddActionTitleWrapper from '../UI/Wrapper/AddActionTitle';
import { getFieldName } from '../../helpers';
import useStyles from './Emails.styles';

function CorrigirEmailDevedorBorderoComponent({ devedor, setBordero }) {
  const constants = {
    INFO_EMAILS: 'Informação do E-mail do Devedor',
    EMAIL: 'E-mail*',
    PRINCIPAL: 'Principal',
  };

  const utilStyles = useUtilStyles();
  const styles = useStyles();

  const onChangeEmailHandler = useCallback((event, index) => {
    const name = getFieldName(event.target);
    const { value } = event.target;
    const emails = [...devedor.emails];
    emails[index][name].onChange(value);
    setBordero(oldBordero => ({ ...oldBordero, emails }));
  }, [devedor?.emails, setBordero]);

  const onFocusEmailHandler = useCallback((event, index) => {
    const name = getFieldName(event.target);
    const emails = [...devedor.emails];
    emails[index][name].onFocus();
    setBordero(oldBordero => ({ ...oldBordero, emails }));
  }, [devedor?.emails, setBordero]);

  const getEmailsWithPrincipalValueFalse = useCallback(() => devedor?.emails.map((email) => {
    const modiFieldEmail = { ...email };
    modiFieldEmail.principal.onChange(false);
    return modiFieldEmail;
  }), [devedor?.emails]);

  const choosePrincipalHandler = useCallback((event, index) => {
    const name = getFieldName(event.target);
    const emails = getEmailsWithPrincipalValueFalse();
    emails[index][name].onChange(true);
    setBordero(oldBordero => ({ ...oldBordero, emails }));
  }, [setBordero, getEmailsWithPrincipalValueFalse]);

  const buildEmailsComponent = (emails, index) => (
    <div key={index}>
      <Grid24 container spacing={1}>
        <Grid24 xs={24} sm={8}>
          <InputField
            isForm
            fullWidth
            label={constants.EMAIL}
            disabled={emails?.email?.disabled}
            error={emails?.email?.error}
            name="email"
            value={emails?.email?.value}
            errorMessage={emails.email?.errorMessage}
            onChange={e => onChangeEmailHandler(e, index)}
            onFocus={e => onFocusEmailHandler(e, index)}
          />
        </Grid24>
        <Grid24
          xs={24}
          sm={11}
          container
          className={
          cx([styles.actions])}
        >
          <Grid24 xs={8} sm={6} spacing={1}>
            <CheckboxField
              label={constants.PRINCIPAL}
              disabled={emails?.principal?.disabled}
              name="principal"
              checked={emails?.principal?.value}
              onChange={e => choosePrincipalHandler(e, index)}
            />
          </Grid24>
        </Grid24>
      </Grid24>
      {index !== emails.length - 1 && (
        <hr className={cx(utilStyles.dividerEmail)} />
      )}
    </div>
  );

  return (
    <AddActionTitleWrapper
      title={constants.INFO_EMAILS}
      className={utilStyles.mt2}
      hideAddButton
    >
      {devedor?.emails?.map(buildEmailsComponent)}
    </AddActionTitleWrapper>
  );
}

export const CorrigirEmailDevedorBordero = CorrigirEmailDevedorBorderoComponent;
