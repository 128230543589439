import axios from 'axios';

const URL = process.env.REACT_APP_URL;

export const CORE = process.env.REACT_APP_CORE;
export const BORDERO = process.env.REACT_APP_BORDERO;
export const IMPORTACAO = process.env.REACT_APP_IMPORTACAO;
export const RELATORIOS = process.env.REACT_APP_RELATORIOS;

export const GEMINI_KEY = process.env.REACT_APP_GEMINI_API_KEY;
export const GPT_KEY = process.env.REACT_APP_GPT_API_KEY;

export const gatewayImage = axios.create({
  baseURL: URL,
  headers: {
    'Content-Disposition': 'inline',
    'Content-Type': 'image/*',
  },
  timeout: 100000,
});

export const gateway = axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 11000,
});

export const gatewayShortJson = axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/short+json',
  },
  timeout: 100000,
});

export const local = axios.create({
  baseURL: 'http://localhost:8082',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 100000,
});

export const localShortJson = axios.create({
  baseURL: 'http://localhost:8082',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/short+json',
  },
  timeout: 100000,
});
