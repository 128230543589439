import React from 'react';
import { useDispatch } from 'react-redux';
import { initializePageAction } from '../../../store/theme.actions';
import constants from '../../../helpers/constants/navigationTitle.constants';
import CrudParametros from '../../../components/Paramentros/Crud/CrudParametros';
import withTokenInterceptor from '../../../hoc/withTokenInterceptor/withTokenInterceptor';
import CrudParametrosProvider from '../../../store/Parametros/Crud/CrudParametrosProvider';
import { hasUserPermissions } from '../../../helpers';

function DetalharParametrosPage() {
  const dispatch = useDispatch();
  dispatch(initializePageAction(`${constants.PARAMETROS}`));


  const permissoesParametro = React.useMemo(() => {
    const consultarParametro = hasUserPermissions(601);
    const gerenciarParametro = hasUserPermissions(602);

    return {
      consultarParametro,
      gerenciarParametro,
    };
  }, []);

  return (
    <CrudParametrosProvider>
      <CrudParametros hasEditPermission={permissoesParametro} />
    </CrudParametrosProvider>
  );
}

export default withTokenInterceptor(DetalharParametrosPage);
