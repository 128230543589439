import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles({
  dialog: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '585px',
    },
  },
  typography: {
    fontSize: '12px',
  },
  div__table: {
    marginTop: '12px',
    textAlign: 'left',
  },
  div__footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '18px',
  },
});
