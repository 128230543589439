import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import LogoutTimer from '../hoc/LogoutTimer/LogoutTimer';

import LoginPage from '../pages/Login/LoginPage';
import DashboardPage from '../pages/Dashboard/Dashboard';
import RoutesFilial from '../pages/Filial/RoutesFilial';
import RoutesBoderos from '../pages/Bordero/RoutesBordero';

import PrivateRoute from '../hoc/PrivateRoute/PrivateRoute';
import DetalharParametrosPage from '../pages/Parametros/Detalhar/DetalharParametrosPage';

import RoutesRamoAtividade from '../pages/RamoAtividade/RoutesRamoAtividade';
import RoutesTituloCompromisso from '../pages/TituloCompromisso/RoutesTituloCompromisso';
import RoutesUsuario from '../pages/Usuario/RoutesUsuario';
import RoutesCliente from '../pages/Cliente/RoutesCliente';
import RoutesPossivelCliente from '../pages/PossivelCliente/RoutesPossivelCliente';
import RoutesFasesPastas from '../pages/FasesPastas/RoutesFasesPastas';
import RoutesRequisicao from '../pages/Requisicao/RoutesRequisicao';
import RoutesAcordoSeguro from '../pages/AcordoSeguro/RoutesAcordoSeguro';
import RoutesTextosPadrao from '../pages/TextosPadrao/RoutesTextosPadrao';
import RoutesFinanceiro from './RoutesFinanceiro';

import { RoutesProcessos } from './RoutesProcessos';
import { LayoutNavigation } from '../components/LayoutNativation/LayoutNavigation';
import { RoutesFerramentas } from './RoutesFerramentas';
import ImprimirPage from '../pages/ContaCorrenteCliente/V2/ContaCorrenteCliente/Repasse/Accordion/ImprimirPage';
import { getStatusHabilitarFuncoesProjetoAction } from '../store/Global/CommonGlobal/commonGlobal.saga';

function RoutesComponent() {
  const dispatch = useDispatch();
  const { habilitarAcordoSeguro } = useSelector(states => states.commonGlobal);

  useEffect(() => {
    dispatch(getStatusHabilitarFuncoesProjetoAction());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Switch>

        <Route path="/login" component={LoginPage} />
        <Route
          path="/conta-corrente-cliente/:tipo/:id/imprimir"
          component={ImprimirPage}
        />
        <LogoutTimer>
          <LayoutNavigation>
            <PrivateRoute path="/" exact component={DashboardPage} />
            <RoutesFilial />
            <RoutesUsuario />
            <PrivateRoute
              roles={['601', '602']}
              path="/parametros"
              exact
              tipoUsuario="U"
              component={DetalharParametrosPage}
            />
            <RoutesRamoAtividade />
            <RoutesTituloCompromisso />
            <RoutesCliente />
            <RoutesPossivelCliente />
            <RoutesBoderos />
            <RoutesFasesPastas />
            <RoutesRequisicao />
            <RoutesProcessos />
            { habilitarAcordoSeguro
            && <RoutesAcordoSeguro />
            }
            <RoutesTextosPadrao />
            <RoutesFerramentas />
            <RoutesFinanceiro />
          </LayoutNavigation>
        </LogoutTimer>
      </Switch>
    </BrowserRouter>
  );
}

export const Routes = RoutesComponent;
