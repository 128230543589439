import styled from 'styled-components';

export const MuralRoot = styled.div`
  display: flex;
  justify-content: center;
  width: 467px;
  height: 286px;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 20%);

  background-color: white;

  div {
    overflow-y: auto;
    width: 100%;
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #888;
      outline: 1px solid #cec8c8;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .ql-video {
      width: 440px;
      height: 238px;
    }

    img {
      max-width: 440px;
      width: 440px;
    }
  }



  @media (min-width: 1400px){
    width: 510px;
    height: 312px;

    div {
      .ql-video {
        width: 480px;
        height: 268px;
      }
  }

  @media (min-width: 1920px){
    width: 700px;
    height: 432px;

    div {
      .ql-video {
        width: 670px;
        height: 385px;
      }
      }
    }
  }
`;
