import styled from 'styled-components';

import { Button } from '@mui/material';
import SelectorField from '../../UI/Field/Selector/SelectorField';
import WrapperSubTitleForm from '../../UI/WrapperSubTitleForm';
import DialogNew from '../../../layouts/FormDialog/DialogNew';

export const Dialog = styled(DialogNew)`
  & .MuiDialog-paperWidthSm {
      max-width: 584px;
  }
`;

export const Selector = styled(SelectorField)`
  margin-top: 8px;
`;

export const CheckboxGroup = styled.div`
  margin-top: 8px;
  margin-left: 10px;
`;

export const WrapperTitle = styled(WrapperSubTitleForm)`
  > * {
    margin-top: 8px;
  }
`;

export const ButtonGroup = styled(Button)`
  padding: 0;
  padding-top: 18px;
`;
