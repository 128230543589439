import { Tooltip } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { FaExclamationTriangle } from 'react-icons/fa';
import useStyle, { ButtonNovaNota } from './NotasFiscaisStyle';
import TabelaNotasFiscais from './TabelaNotasFiscais';
import { notasFiscaisFactory } from './NotasFiscaisFactory';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import {
  cancelarNotasFiscaisAction, downloadProtocoloCancelamentoNFAction, pesquisarNotasFiscaisAction, visualizarNotaFiscalClienteAction,
} from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import { setSimpleValueNotasFiscaisHandlerAction, resetFormFieldsNovaNotaAction } from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { getNewPage } from '../../../../../../helpers';
import { NovaNotaFiscal } from './NovaNotaFiscal';
import OptionDialogNew from '../../../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';


function NotasFiscais({ permissoes }) {
  const styles = useStyle();
  const dispatch = useCCClienteDispatch();

  const page = useCCClienteSelector(states => (states.notasFiscais.page));
  const rowsPerPage = useCCClienteSelector(states => (states.notasFiscais.rowsPerPage));
  const totalElements = useCCClienteSelector(states => (states.notasFiscais.totalElements));
  const tableView = useCCClienteSelector(states => (states.notasFiscais.tableView));
  const idRelacaoSelecionada = useCCClienteSelector(states => (states.common.idRelacaoSelecionada));
  const openDialogCancelNotaFiscal = useCCClienteSelector(states => (states.notasFiscais.openDialogCancelNotaFiscal));
  const openButtonNovaNota = useCCClienteSelector(states => (states.notasFiscais.openButtonNovaNota));
  const autorizarEmissaoNF = useCCClienteSelector(states => (states.relacaoPrestacao.informacoesAdicionais.autorizarEmissaoNF));

  const [conteudoTabela, setConteudoTabela] = useState([]);
  const [idNotaFiscalClienteSelecionado, setIdNotaFiscalClienteSelecionado] = useState(null);

  const changePageHandler = useCallback((event, newPage) => {
    dispatch(setSimpleValueNotasFiscaisHandlerAction('page', Number(newPage)));
    dispatch(pesquisarNotasFiscaisAction());
  }, [dispatch]);

  const changeRowsPerPageHandler = useCallback((e) => {
    const size = e.target.value;
    const newPage = getNewPage(rowsPerPage, page, size);

    dispatch(setSimpleValueNotasFiscaisHandlerAction('page', Number(newPage)));
    dispatch(setSimpleValueNotasFiscaisHandlerAction('rowsPerPage', Number(size)));
    dispatch(pesquisarNotasFiscaisAction());
  }, [dispatch, rowsPerPage, page]);

  function handleOnClickCancel() {
    dispatch(setSimpleValueNotasFiscaisHandlerAction('openDialogCancelNotaFiscal', false));
  }

  const handleCancelNotaFiscais = useCallback(async (idNotaFiscalCliente) => {
    dispatch(setSimpleValueNotasFiscaisHandlerAction('openDialogCancelNotaFiscal', true));
    setIdNotaFiscalClienteSelecionado(idNotaFiscalCliente);
  }, [dispatch]);

  const handleCancelarNotasFiscais = useCallback(() => {
    dispatch(cancelarNotasFiscaisAction(idNotaFiscalClienteSelecionado));
  }, [dispatch, idNotaFiscalClienteSelecionado]);

  const onClickButtonNovaNota = useCallback(() => {
    dispatch(setSimpleValueNotasFiscaisHandlerAction('openButtonNovaNota', !openButtonNovaNota));
    dispatch(resetFormFieldsNovaNotaAction());
  }, [dispatch, openButtonNovaNota]);

  const downloadProtocoloCancelamentoNF = useCallback((idNotaFiscalCliente) => {
    dispatch(downloadProtocoloCancelamentoNFAction(idNotaFiscalCliente));
  }, [dispatch]);

  const visualizarNotaFiscalCliente = useCallback((urlPrefeituraNF) => {
    dispatch(visualizarNotaFiscalClienteAction(urlPrefeituraNF));
  }, [dispatch]);

  useEffect(() => {
    setConteudoTabela(notasFiscaisFactory.makeConteudoTabela(
      tableView,
      permissoes,
      handleCancelNotaFiscais,
      visualizarNotaFiscalCliente,
      downloadProtocoloCancelamentoNF,
    ));
  }, [tableView, permissoes, handleCancelNotaFiscais, visualizarNotaFiscalCliente, downloadProtocoloCancelamentoNF]);

  useEffect(() => {
    if (idRelacaoSelecionada) {
      dispatch(pesquisarNotasFiscaisAction());
    }
  }, [dispatch, idRelacaoSelecionada]);

  return (
    <>
      <OptionDialogNew
        open={openDialogCancelNotaFiscal}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={handleOnClickCancel}
        onClickConfirm={handleCancelarNotasFiscais}
        text=" Deseja realmente cancelar esta Nota Fiscal?"
      />
      {tableView?.length > 0 ? (
        <div className={styles.table__NotasFiscais}>
          <div>
            <ButtonNovaNota
              disabled={!permissoes.prestarContas || !autorizarEmissaoNF}
              active={openButtonNovaNota.toString()}
              onClick={onClickButtonNovaNota}
            >
              <Tooltip title="Nova Nota Fiscal" placement="bottom-start">
                <PostAddIcon />
              </Tooltip>
            </ButtonNovaNota>
          </div>
          {openButtonNovaNota === true && (
            <NovaNotaFiscal />
          )}
          <div>
            <TabelaNotasFiscais
              columns={notasFiscaisFactory.makeCabecalhoTabela()}
              rows={conteudoTabela}
              page={page}
              rowsPerPage={rowsPerPage}
              size={totalElements}
              rowsPerPageOptions={[10, 15, 20]}
              onChangePage={changePageHandler}
              onChangeRowsPerPage={changeRowsPerPageHandler}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.semNotas}>
            <FaExclamationTriangle size={48} color="#FFA000" />
          </div>
          <div className={styles.semNotasLabel}>
            <p> Não foram encontradas Notas Fiscais para esta Prestação.</p>
          </div>
        </div>
      )}

    </>
  );
}
export default NotasFiscais;
