import React from 'react';
import PrivateRoute from '../hoc/PrivateRoute/PrivateRoute';
import { ConsultaDinamicaPage } from '../pages';
import RegistrarBoletoPage from '../pages/RegistrarBoletos/RegistrarBoletoPage';
import EmailEntradaBorderoPage from '../pages/EmailEntradaBordero/EmailEntradaBorderoPage';

function RoutesFerramentasComponents() {
  return (
    <>
      <PrivateRoute
        path="/ferramentas/consulta-dinamica"
        tipoUsuario="U"
        roles={['1301']}
        exact
        component={ConsultaDinamicaPage}
      />
      <PrivateRoute
        path="/ferramentas/registrar-boleto"
        tipoUsuario="U"
        roles={['2101']}
        exact
        component={RegistrarBoletoPage}
      />
      <PrivateRoute
        path="/ferramentas/email-entrada-bordero"
        tipoUsuario="U"
        roles={['1210']}
        exact
        component={EmailEntradaBorderoPage}
      />
    </>
  );
}

export const RoutesFerramentas = RoutesFerramentasComponents;
