import React from 'react';
import SubTitleForm from '../../SubTitleForm/SubTitleForm';
import useStyles from './formWrapperWithTitle.styles';

function FormWrapperTitle({
  className = '',
  children,
  title,
  show = true,
  isDisableForm = false,
  sectionControl = {},
  subTitleClassName = '',
  dicaComponent = null,
}) {
  const styles = useStyles();
  return show ? (
    <section
      className={`${styles.title} ${className}`}
      {...sectionControl}
    >
      <SubTitleForm
        className={subTitleClassName}
        disable={isDisableForm}
      >
        {title}
        <div className={styles.componentDica}>
          {dicaComponent}
        </div>
      </SubTitleForm>
      <div className={styles.marginTopTitle}>{children}</div>
    </section>
  )
    : null;
}

export default React.memo(FormWrapperTitle);
