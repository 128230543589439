import {
  CLEAR_ALL_STATE_FROM_PAGE,
  SET_VALUES_CAIXA_FILIAIS_PAGE,
  SET_VALUE_CONTA_CORRENTE_COMISSIONADO_PAGE,
  SET_VALUE_CONTA_CORRENTE_HONORARIO_PAGE,
  SET_VALUE_LANCAMENTO_NAO_PRESTADOS_PAGE,
  SET_VALUE_RECEBIMENTO_PAGE,
} from './menuAccessPageActions';

const initialStates = {
  caixaFiliais: {
    pageable: null,
    filters: null,
    columnOrder: null,
  },
  contaCorrenteComissionado: {
    pageable: null,
    filters: null,
    columnOrder: null,
  },
  contaCorrenteHonorario: {
    pageable: null,
    filters: null,
    columnOrder: null,
  },
  lancamentosNaoPrestados: {
    pageable: null,
    filters: null,
    columnOrder: null,
  },
  recebimentoCliente: {
    pageable: null,
    filters: null,
    columnOrder: null,
  },
};

function changeValuesCaixaFiliaisPage(states, actions) {
  return {
    ...states,
    caixaFiliais: {
      pageable: actions.pageable,
      filters: actions.filters,
      columnOrder: actions.columnOrder,
    },
  };
}

function changeValuesContaCorrenteComissionado(states, actions) {
  return {
    ...states,
    contaCorrenteComissionado: {
      pageable: actions.pageable,
      filters: actions.filters,
      columnOrder: actions.columnOrder,
    },
  };
}

function changeValueslancamentosNaoPrestados(states, actions) {
  return {
    ...states,
    lancamentosNaoPrestados: {
      pageable: actions.pageable,
      filters: actions.filters,
      columnOrder: actions.columnOrder,
    },
  };
}

function changeValuesContaCorrenteHonorario(states, actions) {
  return {
    ...states,
    contaCorrenteHonorario: {
      pageable: actions.pageable,
      filters: actions.filters,
      columnOrder: actions.columnOrder,
    },
  };
}

function changeValuesRecebimentoCliente(states, actions) {
  return {
    ...states,
    recebimentoCliente: {
      pageable: actions.pageable,
      filters: actions.filters,
      columnOrder: actions.columnOrder,
    },
  };
}

function clearAllStates() {
  return initialStates;
}

export function menuAccessPageReducer(states = initialStates, actions) {
  switch (actions.type) {
    case SET_VALUES_CAIXA_FILIAIS_PAGE:
      return changeValuesCaixaFiliaisPage(states, actions);
    case CLEAR_ALL_STATE_FROM_PAGE:
      return clearAllStates();
    case SET_VALUE_CONTA_CORRENTE_COMISSIONADO_PAGE:
      return changeValuesContaCorrenteComissionado(states, actions);
    case SET_VALUE_CONTA_CORRENTE_HONORARIO_PAGE:
      return changeValuesContaCorrenteHonorario(states, actions);
    case SET_VALUE_LANCAMENTO_NAO_PRESTADOS_PAGE:
      return changeValueslancamentosNaoPrestados(states, actions);
    case SET_VALUE_RECEBIMENTO_PAGE:
      return changeValuesRecebimentoCliente(states, actions);
    default:
      return states;
  }
}
