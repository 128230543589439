import styled from 'styled-components';

export const Container = styled.div`
  display: ${props => (props?.open ? 'flex' : 'none')};
  align-items: flex-end;
  justify-content: space-between;

  margin-bottom: 16px;
  padding: 8px;
  border: solid 1px #a2a0a0;

  display


  h3 {
    margin-bottom: 12px;
  }

  p {
    font-size: 12px;
  }

  p + p {
    margin-top: 6px;
  }

  button {
    width: 136px;
  }

`;
