import React, { useCallback } from 'react';

import Endereco from './Endereco/Endereco';
import AddActionTitleWrapper from '../Wrapper/AddActionTitle';

import { buildEndereco } from '../../../helpers/factory/endereco.factory';
import useUtilStyles from '../../../helpers/styles/utils.styles';
import SubTitleForm from '../SubTitleForm/SubTitleForm';

const constants = {
  TITLE_EDIT: 'Adicionar Endereço',
  TITLE_READ: 'Endereços',
  TOOLTIP: 'Adicionar Endereço',
};

export default function Enderecos({
  enderecos = [],
  estados = [],
  disabled = false,
  readMode = false,
  setFocus,
  setEndereco,
  setAttribute,
  setEnderecos,
  requiredFields = {},
  haveAdd = true,
  subtitle = '',
  isCorrigir = false,
}) {
  const utilStyles = useUtilStyles();

  const addEnderecoHandler = () => {
    const modifiedEnderecos = [...enderecos];
    modifiedEnderecos.push(buildEndereco());
    setEnderecos(modifiedEnderecos);
  };

  const removeEnderecoHandler = (index) => {
    const modifiedEnderecos = [...enderecos];
    const removedItem = modifiedEnderecos.splice(index, 1);
    if (removedItem[0].principal.value === true && modifiedEnderecos.length > 0) {
      modifiedEnderecos[0].principal.value = true;
    }
    setEnderecos(modifiedEnderecos);
  };

  const getEnderecosWithPrincipalValueFalse = useCallback(() => enderecos.map((endereco) => {
    const modifiedEndereco = { ...endereco };
    modifiedEndereco.principal.onChange(false);
    return modifiedEndereco;
  }), [enderecos]);

  const showTrashAtFirstItemHandler = (index) => {
    if (isCorrigir) {
      return false;
    }
    return index !== 0;
  };

  const buildEnderecoComponent = (endereco, index) => (
    <div key={index}>
      <Endereco
        hasPrincipal
        hasTrash={showTrashAtFirstItemHandler(index)}
        index={index}
        disabled={disabled}
        estados={estados}
        model={endereco}
        setEndereco={setEndereco}
        setAttribute={setAttribute}
        setEnderecos={setEnderecos}
        setFocus={setFocus}
        onRemove={() => removeEnderecoHandler(index)}
        enderecosWithPrincipalFalse={getEnderecosWithPrincipalValueFalse}
        requiredFields={requiredFields}
      />
      {index !== enderecos.length - 1 && <hr className={utilStyles.dividerEndereco} />}
    </div>
  );

  return (
    <>
      {haveAdd ? (
        <AddActionTitleWrapper
          title={constants.TITLE_EDIT}
          readTitle={constants.TITLE_READ}
          disabled={disabled}
          addHandler={addEnderecoHandler}
          tooltip={constants.TOOLTIP}
          readMode={readMode}
          className={utilStyles.mt16}
        >
          {enderecos.map(buildEnderecoComponent)}
        </AddActionTitleWrapper>
      ) : (
        <div className={utilStyles.w100}>
          <SubTitleForm>{subtitle}</SubTitleForm>
          {enderecos.map(buildEnderecoComponent)}
        </div>
      )}
    </>
  );
}
