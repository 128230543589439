import React, { useCallback, useEffect } from 'react';
import Circul from '../../../../../assets/images/icons/circulWhite.svg';
import iconCliente from '../../../../../assets/images/icons/iconCliente.svg';
import { formatCpfCnpj, getRequiredLabel, toBrazilianDate } from '../../../../../helpers';
import { ContaCorrenteClienteFactory } from '../ContaCorrenteClienteController';
import {
  CamposDetalheCliente,
  CardFooter,
  ContainerDetalhes,
  ContainerInfosCliente,
  ContainerPrestacaoContas,
  ContainerResumo,
} from '../contaCorrenteClienteStyles';
import SubTitleForm from '../../../../../components/UI/SubTitleForm/SubTitleForm';
import InputField from '../../../../../components/UI/Field/Input/InputField';
import CardDetalhesCliente from './CardDetalhesCliente';
import WrapperSubTitleForm from '../../../../../components/UI/WrapperSubTitleForm/WrapperSubTitleForm';
import Dica from '../../../../../components/UI/Dica/Dica';
import ButtonUI from '../../../../../components/UI/Button/ButtonUI';
import DateField from '../../../../../components/UI/Field/Date/DateField';
import {
  useCCClienteDispatch,
  useCCClienteSelector,
} from '../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import {
  buscarDetalhesDoClienteSelecionadoAction,
  prestarContasDeTodosOsLancamentosAction,
  prestarContasDoClienteSelecionadoAction,
} from '../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import { setSimpleValuesDetalhesAction } from '../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import OptionDialogNew from '../../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';

function makeDetailInfo(firstLabel, secondLabel, thirdLabel, values) {
  return {
    first: {
      label: firstLabel || null,
      value: values[0] || null,
    },
    second: {
      label: secondLabel || null,
      value: values[1] || null,
    },
    third: {
      label: thirdLabel || null,
      value: thirdLabel ? values[2] : null,
    },
  };
}

function ContaCorrenteDetalhesCliente({
  permissoes,
}) {
  const dispatch = useCCClienteDispatch();

  const [dataPrestacao, setDataPrestacao] = React.useState(toBrazilianDate(ContaCorrenteClienteFactory.getProximoDiaUtil(new Date())));
  const [errorDataPrestacao, setErrorDataPrestacao] = React.useState({ error: false, errorMessage: '' });

  const clienteSelecionado = useCCClienteSelector(states => states.common.clienteSelecionado);
  const saldos = useCCClienteSelector(states => states.detalhes.resumoCliente);
  const detalhes = useCCClienteSelector(states => states.detalhes.detalhesCliente);
  const canPrestarTodos = useCCClienteSelector(states => states.detalhes.canPrestarTodos);
  const openDialogConfirmLancamento = useCCClienteSelector(states => states.detalhes.openDialogConfirmLancamento);


  /**
   * Função que vai ser passada para dentro do @DateField.
   * Para que quando o valor da data tiver que ser validado no front e estiver sendo controlado por algum state externo
   * fora do @DateField.
   *
   * Usado aqui para controlar o state errorDataPrestacao da tela, que desabilita o botão de prestar caso o componente
   * identifique a data como inválida.
   *
   * @param errors O que a função de validação do @DateField retorna como parâmetro: {error: true || false, message: string}
   */
  const validarData = (errors) => {
    const { error, message } = errors;
    setErrorDataPrestacao({ error, errorMessage: message });
  };

  useEffect(() => {
    if (clienteSelecionado.idCliente) {
      dispatch(buscarDetalhesDoClienteSelecionadoAction());
    }
  }, [dispatch, clienteSelecionado.idCliente]);

  const getDisablePrestarContas = useCallback((isPrestar = false, isPrestarTodos = false) => {
    if (!permissoes?.prestarContas) {
      return true;
    }

    if (isPrestar) {
      if (permissoes?.prestarContas && clienteSelecionado?.desbloqueado) {
        return false;
      }
      if (permissoes?.prestarContas && !clienteSelecionado?.desbloqueado) {
        return true;
      }
    }

    if (isPrestarTodos) {
      return !canPrestarTodos;
    }
    return true;
  }, [permissoes?.prestarContas, clienteSelecionado?.desbloqueado, canPrestarTodos]);


  const onClickPrestarContas = useCallback(() => {
    dispatch(prestarContasDoClienteSelecionadoAction(dataPrestacao));
  }, [dispatch, dataPrestacao]);

  const onChangeDataPrestacao = (event) => {
    const { value } = event.target;
    setDataPrestacao(value);
  };

  const onClickPrestarTodos = useCallback(() => {
    dispatch(prestarContasDeTodosOsLancamentosAction(dataPrestacao));
  }, [dispatch, dataPrestacao]);

  function handleOnClickCancel() {
    dispatch(setSimpleValuesDetalhesAction('openDialogConfirmLancamento', false));
  }

  function handleOpenConfirmLancamento() {
    dispatch(setSimpleValuesDetalhesAction('openDialogConfirmLancamento', true));
  }

  return (
    <>
      <OptionDialogNew
        open={openDialogConfirmLancamento}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={handleOnClickCancel}
        onClickConfirm={onClickPrestarTodos}
      >
        <div style={{ fontSize: '16px', display: 'box', textAlign: 'justify' }}>
          <p>
            Você está prestes a Prestar Contas de todos os clientes pesquisados e
            {' '}
            <p>desbloqueados.</p>
          </p>
          <p>Tem certeza que deseja realizar esta operação?</p>
        </div>
      </OptionDialogNew>
      <ContainerDetalhes>
        <ContainerInfosCliente>
          {detalhes ? (
            <img src={iconCliente} alt="Foto Usuario" width="70px" height="70px" />
          ) : (
            <img src={Circul} alt="Icone em Branco" width="70px" height="70px" />
          )}
          <div>
            <SubTitleForm>
              Detalhes do Cliente
            </SubTitleForm>
            <CamposDetalheCliente>
              <div>
                <InputField
                  fullWidth
                  label="Nome"
                  disabled
                  isForm
                  value={detalhes?.nome}
                />
              </div>
              <div>
                <InputField
                  label="Código"
                  disabled
                  isForm
                  fullWidth
                  value={detalhes?.idCliente}
                />
                <InputField
                  label="CPF/CNPJ"
                  disabled
                  isForm
                  fullWidth
                  value={formatCpfCnpj(detalhes?.cnpjCpf)}
                />
                <InputField
                  label="Última Prestação"
                  disabled
                  isForm
                  fullWidth
                  value={detalhes?.dataUltimaPrestacao}
                />
              </div>
              <div>
                <div id="campoPeriodicidade">
                  <InputField
                    label="Periocidade"
                    disabled
                    isForm
                    fullWidth
                    value={detalhes?.descricaoPeriodicidade}
                  />
                </div>
                <InputField
                  label="Regra"
                  disabled
                  isForm
                  fullWidth
                  value={detalhes?.descricaoRegra}
                />
              </div>
              <div id="campoStatus">
                <InputField
                  label="Status"
                  disabled
                  isForm
                  fullWidth
                  value={detalhes?.statusCliente}
                />
                <InputField
                  label="Prestação de Contas"
                  disabled
                  isForm
                  fullWidth
                  value={detalhes?.suspenderPrestacaoConta}
                />
              </div>
            </CamposDetalheCliente>
          </div>
        </ContainerInfosCliente>
        <ContainerResumo>
          <div>
            <p>Resumo</p>
          </div>
          <div>
            <CardDetalhesCliente
              title="Saldo Anterior"
              infoValue={saldos.saldoAnterior}
              bgColor="#58a9f4"
            />
            <CardDetalhesCliente
              title="Total Estornos"
              infoValue={saldos.totalEstornos}
              bgColor="#3373ec"
            />
            <CardDetalhesCliente
              title="Saldo Atual"
              infoValue={saldos.saldoAtual}
              bgColor="#2b59b0"
            />
          </div>
          <div>
            <CardDetalhesCliente
              title="Despesas Bloqueadas"
              detailInfo={makeDetailInfo(
                'Não Reemb.',
                'Reemb.',
                null,
                [saldos.despesaBloquedaNaoReembolsavel, saldos.despesaBloquedaReembolsavel],
              )}
              bgColor="#ff7b51"
              lineColor="#ff521b"
            />
            <CardDetalhesCliente
              title="Honorários Bloqueados"
              detailInfo={makeDetailInfo(
                'Amigáveis',
                'Judiciais',
                null,
                [saldos.honorarioBloquedoAmigavel, saldos.honorarioBloquedoJudicial],
              )}
              bgColor="#f85861"
              lineColor="#e9303b"
            />
            <CardDetalhesCliente
              title="Valores Bloqueados"
              detailInfo={makeDetailInfo(
                'Créditos',
                'Pagto.',
                'Acordos',
                [saldos.valorBloquedoCredito, saldos.valorBloquedoPagamento, saldos.valorBloquedoAcordo],
              )}
              bgColor="#ff449b"
              lineColor="#e9207e"
            />
          </div>
          <div>
            <CardDetalhesCliente
              title="Despesas Liberadas"
              detailInfo={makeDetailInfo(
                'Não Reemb.',
                'Reemb.',
                null,
                [saldos.despesaLiberadaNaoReembolsavel, saldos.despesaLiberadaReembolsavel],
              )}
              bgColor="#ef997e"
              lineColor="#d67556"
            />
            <CardDetalhesCliente
              title="Honorários Liberados"
              detailInfo={makeDetailInfo(
                'Amigáveis',
                'Judiciais',
                null,
                [saldos.honorarioLiberadoAmigavel, saldos.honorarioLiberadoJudicial],
              )}
              bgColor="#f49096"
              lineColor="#e2646d"
            />
            <CardDetalhesCliente
              title="Valores Liberados"
              detailInfo={makeDetailInfo(
                'Créditos',
                'Pagto.',
                'Acordos',
                [saldos.valorLiberadoCredito, saldos.valorLiberadoPagamento, saldos.valorLiberadoAcordo],
              )}
              bgColor="#e98db7"
              lineColor="#ec62a2"
            />
          </div>
          <div>
            <CardFooter color="#c54fd9">
              <p>Total do Repasse</p>
              <p>{saldos.totalRepasse}</p>
            </CardFooter>
            <CardFooter color="#3cb6be">
              <p>Total da Demonstração</p>
              <p>{saldos.totalDemonstracao}</p>
            </CardFooter>
          </div>
        </ContainerResumo>
        <ContainerPrestacaoContas>
          <div>
            <span>
              <WrapperSubTitleForm title="Prestação de Contas" />
              <Dica mensagemDica="A Prestação será realizada somente para o cliente selecionado" />
            </span>
            <div>
              <ButtonUI
                disabled={dataPrestacao === '' || errorDataPrestacao.error || getDisablePrestarContas(false, true)}
                color="white"
                onClick={handleOpenConfirmLancamento}
              >
                Prestar Todos
              </ButtonUI>
            </div>
          </div>
          <div>
            <DateField
              isForm
              label={getRequiredLabel('Data da Prestação', true)}
              name="dataPrestacao"
              disabled={(!permissoes.prestarContas) || !(clienteSelecionado?.desbloqueado || canPrestarTodos)}
              value={clienteSelecionado.idCliente ? dataPrestacao : ''}
              minDate={new Date()}
              onChange={onChangeDataPrestacao}
              setExternalError={validarData}
              error={errorDataPrestacao.error}
              errorMessage={errorDataPrestacao.errorMessage}
            />
            <div>
              <ButtonUI
                onClick={onClickPrestarContas}
                disabled={dataPrestacao === '' || errorDataPrestacao.error || getDisablePrestarContas(true, false)}
                color="success"
              >
                Prestar Contas
              </ButtonUI>
            </div>
          </div>
        </ContainerPrestacaoContas>
      </ContainerDetalhes>
    </>
  );
}

export const ContaCorrenteDetalhesClienteComponent = ContaCorrenteDetalhesCliente;
