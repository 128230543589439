import React, { createContext, useEffect } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider, createSelectorHook, createDispatchHook } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import reducer from './CrudBordero.store';
import watchCrudBordero from './CrudBorderos.saga';

import { moveScroll } from '../../../helpers/utils/utils';

export const crudBorderoContext = createContext();
export const useCrudBorderoSelector = createSelectorHook(crudBorderoContext);
export const useCrudBoderoDispatch = createDispatchHook(crudBorderoContext);

export default function CrudBoderoProvider({ children }) {
  const saga = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(saga));
  saga.run(watchCrudBordero);

  useEffect(() => {
    moveScroll();
  }, []);

  return (
    <Provider context={crudBorderoContext} store={store}>
      {children}
    </Provider>
  );
}
