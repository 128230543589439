import React from 'react';

import PrivateRoute from '../../hoc/PrivateRoute/PrivateRoute';
import RelatoriosASPage from './Relatorios/RelatoriosASPage';
import HistoricoASPage from './Historico/HistoricoASPage';

import { InspecionarASPage } from './Inspecionar/InpecionarASPage';

import withTokenInterceptor from '../../hoc/withTokenInterceptor/withTokenInterceptor';
import AcordosASPage from './Acordos/AcordosAsPage';
import PesquisaSmsASPage from '../../components/AcordoSeguro/EnviarSMS/PesquisaSmsASPage';
import PrazoExpiradoASPage from '../../components/AcordoSeguro/PrazoExpirado/PrazoExpiradoASPage';

export default function RoutesAcordoSeguro() {
  return (
    <>
      <PrivateRoute
        path="/acordo-seguro/historico"
        tipoUsuario="U"
        exact
        component={HistoricoASPage}
      />
      <PrivateRoute
        path="/processos/:numeroProcesso/inspecionar"
        tipoUsuario="U"
        exact
        component={withTokenInterceptor(InspecionarASPage)}
      />
      <PrivateRoute
        path="/acordo-seguro/relatorios"
        tipoUsuario="U"
        exact
        component={RelatoriosASPage}
      />
      <PrivateRoute
        path="/acordo-seguro/acordos"
        tipoUsuario="U"
        exact
        component={AcordosASPage}
      />
      <PrivateRoute
        path="/acordo-seguro/enviar-sms-email"
        tipoUsuario="U"
        exact
        component={withTokenInterceptor(PesquisaSmsASPage)}
      />
      <PrivateRoute
        path="/acordo-seguro/prazo-expirado"
        tipoUsuario="U"
        exact
        component={withTokenInterceptor(PrazoExpiradoASPage)}
      />
    </>
  );
}
