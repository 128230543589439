import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SelectorField from '../../../UI/Field/Selector';
import InputField from '../../../UI/Field/Input';
import Grid24 from '../../../UI/Grid24';
import WrapperSubTitleForm from '../../../UI/WrapperSubTitleForm';

import { useCrudFilialDispatch, useCrudFilialSelector } from '../../../../store/Filial/Crud/CrudFilialProvider';
import { findUsuariosAction, findCentrosCustoAction } from '../../../../store/Global/LoadSelectors/loadSelectors.saga';
import { setRequestFieldsValueAction, setRequestFieldsFocusAction } from '../../../../store/Filial/Crud/crudFilial.store';

import { buildSelectorStatus } from '../../../../helpers/factory/global.factory';
import { getRequiredLabel } from '../../../../helpers/utils/utils';

import constants from './IdentificacaoCrudFilial.constants';


function IdentificacaoCrudFilial({ isQueryMode, location }) {
  const [statusSelector] = useState(buildSelectorStatus());

  const dispatch = useCrudFilialDispatch();
  const globalDispatch = useDispatch();

  const nome = useCrudFilialSelector(states => states.requestFields.nomeFilial.copy());
  const email = useCrudFilialSelector(states => states.requestFields.email.copy());
  const status = useCrudFilialSelector(states => states.requestFields.statusFilial.copy());
  const centroCusto = useCrudFilialSelector(states => states.requestFields.idCentroCusto.copy());
  const responsavel = useCrudFilialSelector(states => states.requestFields.idUsuario.copy());

  const responsaveis = useSelector(states => states.selectors.usuarios.selector);
  const centrosCusto = useSelector(states => (states.selectors.centrosCusto.selector));

  const reloadSelectors = useCrudFilialSelector(states => states.reloadSelectors);

  // Carregar combobox/seletores
  useEffect(() => {
    globalDispatch(findUsuariosAction());
    globalDispatch(findCentrosCustoAction());
  }, [globalDispatch, location.refreshPage]);

  // Carregar combobox/seletores, caso seja solicitada a atualização
  useEffect(() => {
    globalDispatch(findUsuariosAction());
  }, [globalDispatch, reloadSelectors]);

  const changeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setRequestFieldsValueAction(name, value));
  }, [dispatch]);

  const focusFieldsHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(setRequestFieldsFocusAction(fieldName));
  }, [dispatch]);

  return (
    <WrapperSubTitleForm title={constants.TITLE}>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={12} sm={11}>
          <InputField
            isForm
            fullWidth
            disabled={isQueryMode}
            label={getRequiredLabel(constants.NOME, !isQueryMode)}
            name={nome.name}
            value={nome.value}
            error={nome.error}
            maxLength={nome.maxLength}
            errorMessage={nome.errorMessage}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
          />
        </Grid24>
        <Grid24 xs={12} sm={2}>
          <SelectorField
            isForm
            fullWidth
            disabled={isQueryMode}
            label={getRequiredLabel(constants.STATUS, !isQueryMode)}
            items={statusSelector}
            name={status.name}
            value={status.value}
            error={status.error}
            errorMessage={status.errorMessage}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
          />
        </Grid24>
        <Grid24 xs={12} sm={9}>
          <InputField
            isForm
            fullWidth
            disabled={isQueryMode}
            label={constants.EMAIL}
            name={email.name}
            value={email.value}
            error={email.error}
            maxLength={email.maxLength}
            errorMessage={email.errorMessage}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
          />
        </Grid24>
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={12} sm={11}>
          <SelectorField
            isForm
            fullWidth
            hasEmptyLabel
            disabled={isQueryMode}
            label={constants.RESPONSAVEL}
            items={responsaveis}
            name={responsavel.name}
            value={responsavel.value}
            error={responsavel.error}
            errorMessage={responsavel.errorMessage}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
          />
        </Grid24>
        <Grid24 xs={12} sm={9}>
          <SelectorField
            isForm
            fullWidth
            hasEmptyLabel
            disabled={isQueryMode}
            label={getRequiredLabel(constants.CENTRO_CUSTO, !isQueryMode)}
            items={centrosCusto}
            name={centroCusto.name}
            value={centroCusto.value}
            error={centroCusto.error}
            errorMessage={centroCusto.errorMessage}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
          />
        </Grid24>
      </Grid24>
    </WrapperSubTitleForm>
  );
}

export default withRouter(IdentificacaoCrudFilial);
