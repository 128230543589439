import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  root: {
    '& label': {
      fontSize: '0.75rem',
      color: '#707070',
    },
  },
  group: {
    margin: '5px 10px',
    display: 'inline-block',
  },
});
