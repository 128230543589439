import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  wrapper: props => ({
    backgroundColor: '#f1f4f9',
    overflow: 'auto',
    maxHeight: props.maxHeight,
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      outline: '1px solid #cec8c8',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    '& td': {
      fontSize: '12px',
      padding: '0px 16px',
      height: '33px',
    },
    '& th': {
      fontSize: '12px',
      backgroundColor: '#f1f4f9',
      color: '#034ddc',
      fontWeight: 'bold',
      padding: '0px 20px',
      height: '33px',
    },
  }),
});
