import React from 'react';
import PrivateRoute from '../../hoc/PrivateRoute/PrivateRoute';
import CrudFasesPastasPage from './Crud/CrudFasesPastasPage';

export default function RoutesFasesPastas() {
  return (
    <>
      <PrivateRoute path="/fases" exact component={CrudFasesPastasPage} />
    </>
  );
}
