import React, { useCallback } from 'react';
import { useUtilStyles } from '../../../../../../../helpers';
import {
  addContaDepositoClientesAction, setContaDepositoClientesAttributeFocusAction, setContaDepositoClientesAttributeValueAction, setObjectsAction,
} from '../../../../../../../store/Parametros/Crud/crudParametros.store';
import { useCrudParametrosDispatch, useCrudParametrosSelector } from '../../../../../../../store/Parametros/Crud/CrudParametrosProvider';
import AddActionTitleWrapper from '../../../../../../UI/Wrapper/AddActionTitle';
import CamposContaDepositoCliente from './CamposContaDepositoCliente';


const CONSTANTS = {
  EDIT_TITLE: 'Adicionar Conta Depósito de Clientes',
  READ_TITLE: 'Conta Depósito de Clientes',
  TOOLTIP: 'Adicionar Conta Depósito de Clientes',
};

function ContasAdicionaisDepositoClientes({
  isInsertMode,
  readMode = false,
  bancos,
  tiposChavesPix,
}) {
  const dispatch = useCrudParametrosDispatch();

  const utilStyles = useUtilStyles();
  const contaCliente = 'contasBancariasDepositoClientes';

  const contasBancariasDepositoClientes = useCrudParametrosSelector(states => ([...states.requestFields.contasBancariasDepositoClientes]));

  const generateDividerLine = index => ((index !== contasBancariasDepositoClientes.length - 1)
    && (<hr className={utilStyles.mt7} />)
  );

  const setDepoistoClienteAttributeValueHandler = useCallback((name, value, index) => {
    dispatch(setContaDepositoClientesAttributeValueAction(name, value, index));
  }, [dispatch]);

  const setDepositoClienteAttributeFocusHandler = useCallback((name, index) => {
    dispatch(setContaDepositoClientesAttributeFocusAction(name, index));
  }, [dispatch]);

  const setCamposClienteHandler = useCallback((campos) => {
    dispatch(setObjectsAction(contaCliente, campos));
  }, [dispatch]);


  const addCampos = useCallback(() => {
    dispatch(addContaDepositoClientesAction());
  }, [dispatch]);

  const removeCamposHandler = useCallback((index) => {
    const allCampos = [...contasBancariasDepositoClientes];
    allCampos.splice(index, 1);
    setCamposClienteHandler(allCampos);
  }, [contasBancariasDepositoClientes, setCamposClienteHandler]);

  const showTrash = index => index === contasBancariasDepositoClientes.length - 1 && isInsertMode && index !== 0;

  const buildAdicionarConta = ((depositoCliente, index) => (
    <div key={index}>
      <CamposContaDepositoCliente
        index={index}
        isInsertMode={isInsertMode}
        onRemove={() => removeCamposHandler(index)}
        model={depositoCliente}
        showTrash={showTrash(index)}
        setAttribute={setDepoistoClienteAttributeValueHandler}
        setFocus={setDepositoClienteAttributeFocusHandler}
        bancos={bancos}
        tiposChavesPix={tiposChavesPix}
      />
      {generateDividerLine(index)}
    </div>
  ));
  return (
    <AddActionTitleWrapper
      title={CONSTANTS.EDIT_TITLE}
      readTitle={CONSTANTS.READ_TITLE}
      addHandler={addCampos}
      tooltip={CONSTANTS.TOOLTIP}
      readMode={readMode}
      className={utilStyles.mt7}
    >
      <div className={utilStyles.mt7}>
        {contasBancariasDepositoClientes?.map(buildAdicionarConta)}
      </div>
    </AddActionTitleWrapper>
  );
}

export default ContasAdicionaisDepositoClientes;
