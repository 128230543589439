export const buttonBlueGradienColor = ['#005dff', '#4c547e'];
export const alternativeTableTitile = '#D2DCF8';

export const black = '#333333';
export const black14 = 'rgba(0, 0, 0, 0.1411764705882353)';
export const black15 = 'rgba(0, 0, 0, 0.14901960784313725)';
export const black16 = 'rgba(0, 0, 0, 0.1607843137254902)';
export const blackTwo = '#000000';
export const blue = '#034ddc';
export const blue36 = 'rgba(3; 77; 220; 0.3607843137254902)';
export const brightBlue = '#005dff';
export const brightRed = '#ff000f';
export const greyish = '#a8a8a8';
export const lightPeriwinkle = '#d2dcf8';
export const lipstick = '#e91e63';
export const lipstick40 = 'rgba(233; 30; 99; 0.4)';
export const mango = '#ffa726';
export const midGreen = '#43a047';
export const paleGrey = '#f1f4f9';
export const perrywinkle = '#a0bbef';
export const purpleBlue14 = 'rgba(49; 30; 233; 0.1411764705882353)';
export const royal = '#0f1f71';
export const silver = '#c8cdd1';
export const strongPink = '#ff1381';
export const tangerine = '#fb8c00';
export const tealish = '#26c6da';
export const tomato = '#e91b1b';
export const turquoiseBlue = '#00acc1';
export const warmGrey = '#707070';
export const warmGreyFour = '#a2a1a1';
export const warmGreyThree = '#858484';
export const warmGreyTwo = '#707070';
export const white = '#ffffff';
export const blueGrey = '#cfd9eb';
export const whiteGrey = '#ddd';
export const baseGrey = '#e8e8e8';
export const lightGrey = '#a2a0a0';
