import { CORE, gateway } from '../../api';
import { getUriParams } from '../../../helpers/utils/utils';

export function enviarSmsTeste(payload) {
  return gateway.post(`${CORE}/acordo-seguro/processos/sms-teste`, payload);
}

export function buscarHistoricoSmsEmail(ordenacao, page, size) {
  const params = getUriParams({ page, size });
  const sortedQuery = ordenacao.reduce((obj, sort) => (
    `${obj}&sort=${sort}`
  ), '');
  return gateway.get(`${CORE}/acordo-seguro/processos/sms-email?${sortedQuery}&${params}`);
}

export function buscarIdsHistoricoSmsEmail() {
  return gateway.get(`${CORE}/acordo-seguro/processos/id-processo/sms-email`);
}

export function enviarSmsEmail(payload) {
  return gateway.post(`${CORE}/acordo-seguro/processos/sms-email`, payload);
}
