export const IDENTIFICACAO_TITLE = 'Identificação';
export const IDENTIFICACAO_NOME = 'Nome';
export const IDENTIFICACAO_ESTAGIO = 'Estágio da Cobrança';
export const IDENTIFICACAO_DURACAO = 'Duração (dias úteis)';
export const IDENTIFICACAO_EXIGIR_NEGOCIADOR = 'Negociador';
export const IDENTIFICACAO_PERMITIR_PROCESSO = 'Permite receber Processo';
export const IDENTIFICACAO_PAGAR_COMISSAO = 'Pagar Comissão na Fase';
export const IDENTIFICACAO_PLACEHOLDER = 'Insira as atividades';
export const ATIVIDADE_FASES_LABEL = 'Atividades da Fase';
export const EXIGE_MOTIVO_LABEL = 'Exige Motivo';
export const RECALCULA_PROCESSO = 'Recalcula o valor do Processo';
