
import React, {
  useCallback,
  useEffect, useImperativeHandle, useLayoutEffect, useState,
} from 'react';
import ReactDOM from 'react-dom';
import { useWindowDimensions } from '../../hoc/useWindowDimensions';

import { Box } from './FloatStyles';

const ELEMENT_ID = 'float-container';
const NAVBAR_ID = 'navbar';

const xDistanceFromVariant = {
  center: (parentDimension, childDimension) => parentDimension?.left + parentDimension?.width / 2 - childDimension?.width / 2,
  right: (parentDimension, childDimension) => parentDimension?.left + parentDimension?.width - childDimension?.width,
  left: parentDimension => parentDimension?.left,
};

function openContainerBackground() {
  const el = document.getElementById(ELEMENT_ID);
  el.style.display = 'block';
  el.style.overflow = 'hidden';

  const hasScroll = document.body.scrollHeight > document.body.clientHeight;
  if (hasScroll) {
    const scrollbarWidth = window.innerWidth - document.body.clientWidth;
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    document.getElementById(NAVBAR_ID).style.paddingRight = `${scrollbarWidth}px`;
  }
}

function closeContainerBackground() {
  const el = document.getElementById(ELEMENT_ID);
  if (el) {
    el.style.display = 'none';
    el.style.overflow = 'auto';
    document.body.style.overflow = 'auto';
    document.body.style.paddingRight = 0;
  }

  const t = document.getElementById(NAVBAR_ID);
  if (t) {
    t.style.paddingRight = 0;
  }
}

function getLeftDistance(windowDimensions, parentDimension, childDimension, variant) {
  const screenOverflowX = windowDimensions?.width - (parentDimension?.x + childDimension?.width / 2);
  if (screenOverflowX < 0) {
    return windowDimensions?.width - childDimension?.width;
  }
  return xDistanceFromVariant[variant](parentDimension, childDimension);
}

function getTopDistance(windowDimensions, parentDimension, childDimension) {
  const screenOverflowY = windowDimensions?.height - (parentDimension?.y + childDimension?.height + 12);
  if (screenOverflowY < 0) {
    return parentDimension?.top - childDimension?.height + parentDimension?.height;
  }
  return parentDimension?.top + parentDimension?.height;
}


function FloatBoxComponent({
  parentRef,
  children,
  onClose,
  variant = 'center',
}, ref) {
  const [show, setShow] = useState(false);
  const [, setForceUpdate] = useState(false);

  const windowDimensions = useWindowDimensions();

  useImperativeHandle(ref, () => ({
    handlerShowBox: () => {
      setShow(oldState => !oldState);
    },
  }));

  const closeHandler = useCallback((e) => {
    const boxElement = document.getElementById(ELEMENT_ID)?.children;
    if (boxElement && boxElement.length > 0 && !boxElement[0].contains(e.target)) {
      if (onClose) {
        onClose();
      }
      setShow(false);
    }
  }, [onClose]);

  useEffect(() => {
    const el = document.getElementById(ELEMENT_ID);
    el.addEventListener('click', closeHandler);
    return () => el.removeEventListener('click', closeHandler);
  }, [closeHandler]);

  /**
   * Gerenciar a abertura do plano de fundo para colocar a 'Box'
   */
  useLayoutEffect(() => {
    if (show) {
      openContainerBackground();
      setForceUpdate(oldState => !oldState);
    }
  }, [show]);

  const boxElement = document.getElementById(ELEMENT_ID)?.children;
  if (boxElement && boxElement.length > 0) {
    const child = boxElement[0];

    const childDimension = child.getBoundingClientRect();
    const parentDimension = parentRef?.current?.getBoundingClientRect();

    const leftDistance = getLeftDistance(windowDimensions, parentDimension, childDimension, variant);
    const topDistance = getTopDistance(windowDimensions, parentDimension, childDimension);

    child.style.top = `${topDistance}px`;
    child.style.left = `${leftDistance}px`;
  }

  if (!show) {
    closeContainerBackground();
  }

  return show ? ReactDOM.createPortal(<Box>{children}</Box>, document.getElementById(ELEMENT_ID)) : null;
}

export const FloatBox = React.forwardRef(FloatBoxComponent);
