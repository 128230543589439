import styled from 'styled-components';
import FormWrapperWithTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import WrapperSubTitleForm from '../../../../../UI/WrapperSubTitleForm';


export const SubTitleWrapperForm = styled(WrapperSubTitleForm)`
margin-bottom: 18px;
margin-top: 15px;
`;

export const WrapperFormWithTitle = styled(FormWrapperWithTitle)`
 margin-bottom: 10px;
 > h3 {
    color: black;
 }
`;
