export const tiposAcompanhamentos = {
  NENHUM: { value: 'NENHUM', key: 1 },
  INSTRUCAO: { value: 'INSTRUCAO', key: 2 },
  PESQUISA_AO_CLIENTE: { value: 'PESQUISA_AO_CLIENTE', key: 3 },
  REQUISICAO: { value: 'REQUISICAO', key: 4 },
  ALERTA: { value: 'ALERTA', key: 5 },
  RELATORIO: { value: 'RELATORIO', key: 6 },
  COMENTARIO: { value: 'COMENTARIO', key: 7 },
  COMENTARIO_AUTOMATIO: { value: 'COMENTARIO_AUTOMATIO', key: 8 },
  EMAIL: { value: 'EMAIL', key: 9 },
};
