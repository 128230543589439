import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  FIND_LOG_PROCESSAMENTO_BORDERO,
  FIND_LOG_PROCESSAMENTO_BORDERO_DETALHES,
} from './logProcessamentoBordero.constants';
import {
  changeSearchCriteriaFieldsAction,
  onChangeActionsPageViewAction,
  onLoadLogProcessamentoBorderoDetalhesAction,
  setSettingsTableAction,
} from './logProcessamentoBordero.store';
import {
  getDetalhesLogProcessamentoBodero,
  getLogProcessamentoBordero,
} from '../../services/bordero/bordero/bordero.services';
import { getExceptionMessage, isBadRequestException } from '../../helpers/utils/utils';
import { getExceptionHandler } from '../../helpers/utils/exception.util';
import { RESULT_NOT_FOUND } from '../../helpers/constants/global.constants';

export const findLogProcessamentoBorderoAction = () => ({
  type: FIND_LOG_PROCESSAMENTO_BORDERO,
});

export const findLogProcessamentoBorderoDetalhesAction = idBorderoLog => ({
  type: FIND_LOG_PROCESSAMENTO_BORDERO_DETALHES,
  idBorderoLog,
});


function setMessageWhenResultNotFound(length) {
  if (length === 0) {
    toast.warn(RESULT_NOT_FOUND);
  }
}

function* findLogProcessamentoBorderoHandler() {
  yield put(onChangeActionsPageViewAction('loading', true));
  try {
    const {
      dataCadastramentoInicial,
      dataCadastramentoFinal,
      idCliente,
      idProcesso,
      nomeDevedor,
      texto,
      tipoBorderoLog,
    } = yield select(states => states.logProcessamentoSearchCriteriasCache);

    const { page, rowsPerPage } = yield select(states => states.settingsTable);

    const { data } = yield call(getLogProcessamentoBordero,
      dataCadastramentoInicial,
      dataCadastramentoFinal,
      idCliente,
      idProcesso,
      nomeDevedor,
      texto,
      tipoBorderoLog,
      page,
      rowsPerPage);

    yield put(setSettingsTableAction(data.content, data.number, data.size, data.totalElements));

    yield setMessageWhenResultNotFound(data.content.length);
  } catch (exception) {
    const logProcessamentoSearchCriterias = yield select(states => states.logProcessamentoSearchCriterias);
    const [updateFields] = getExceptionHandler(exception, logProcessamentoSearchCriterias);
    yield put(changeSearchCriteriaFieldsAction(updateFields));

    const exceptionMessage = getExceptionMessage(exception);
    const isBadRequest = isBadRequestException(exception);

    if (isBadRequest && exceptionMessage !== '') {
      toast.error(exceptionMessage, { style: { width: '392px' } });
    }
  } finally {
    yield put(onChangeActionsPageViewAction('loading', false));
  }
}


function* findLogProcessamentoBorderoDetalhesHandler(actions) {
  const { idBorderoLog } = actions;
  yield put(onChangeActionsPageViewAction('loading', true));
  try {
    const { data } = yield call(getDetalhesLogProcessamentoBodero, idBorderoLog);
    yield put(onLoadLogProcessamentoBorderoDetalhesAction(data));
    yield put(onChangeActionsPageViewAction('openDetalhes', true));
  } catch (exception) {
    const exceptionMessage = getExceptionMessage(exception);
    if (exceptionMessage !== '') {
      toast.error(exceptionMessage, { style: { width: '392px' } });
    }
  } finally {
    yield put(onChangeActionsPageViewAction('loading', false));
  }
}

export default function* watchLogProcessamentoBordero() {
  yield takeLatest(FIND_LOG_PROCESSAMENTO_BORDERO, findLogProcessamentoBorderoHandler);
  yield takeLatest(FIND_LOG_PROCESSAMENTO_BORDERO_DETALHES, findLogProcessamentoBorderoDetalhesHandler);
}
