import { CORE, gateway } from '../../api';
import { getUriParams } from '../../../helpers/utils/utils';
import { tiposConsultasAntecedentesTypes } from '../../../types/tiposConsultasAntecedentesTypes';

export function buscarAntecedentesDoProcessoService({
  idProcesso, pageable = { page: 0, size: 10 }, ordenacao = ['idProcesso,ASC'], tiposConsultasAntecedentes = tiposConsultasAntecedentesTypes.simples,
}) {
  const queryParams = getUriParams({ ...pageable, tiposConsultasAntecedentes });
  const sortedQuery = ordenacao.reduce((obj, sort) => (
    `${obj}&sort=${sort}`
  ), '');
  return gateway.get(`${CORE}/processos/${idProcesso}/antecedentes?${sortedQuery}&${queryParams}`);
}

export function buscarIdsAntecedentesDoProcessoService(idProcesso, tiposConsultasAntecedentes) {
  const queryParams = getUriParams({ tiposConsultasAntecedentes });
  return gateway.get(`${CORE}/processos/${idProcesso}/antecedentes-ids?${queryParams}`);
}

export function unificarProcessosService(idProcesso, idsProcessos = []) {
  return gateway.post(`${CORE}/processos/${idProcesso}/unificar`, idsProcessos);
}

export function juntarProcessosService(idProcesso, idsProcessos = []) {
  return gateway.post(`${CORE}/processos/${idProcesso}/juntar`, idsProcessos);
}
