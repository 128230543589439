import FieldModel, { POSITIVE_INTEGER } from '../../../models/FieldModel';
import { getAttributeExistingObject } from '../../../helpers/utils/utils';
import { makeErrorField } from '../../../helpers/utils/form.utils';


class DadosDevedorController {
  buildEmailDevedor(devedorEmailCommand) {
    return {
      idDevedorEmail: new FieldModel({
        name: 'idDevedorEmail', value: getAttributeExistingObject(devedorEmailCommand, 'emidDevedorEmailail'),
      }),
      email: new FieldModel({
        name: 'email',
        value: getAttributeExistingObject(devedorEmailCommand, 'email'),
      }),
      principal: new FieldModel({
        name: 'principal', value: getAttributeExistingObject(devedorEmailCommand, 'principal', true),
      }),
    };
  }

  buildEnderecoDevedor(devedorEnderecoCommand) {
    return {
      idDevedorEndereco: new FieldModel({
        name: 'idDevedorEndereco', value: getAttributeExistingObject(devedorEnderecoCommand, 'idDevedorEndereco'),
      }),
      estado: new FieldModel({
        name: 'estado', value: getAttributeExistingObject(devedorEnderecoCommand, 'estado'),
      }),
      endereco: new FieldModel({
        name: 'endereco',
        value: getAttributeExistingObject(devedorEnderecoCommand, 'endereco'),
      }),
      numero: new FieldModel({
        name: 'numero',
        value: getAttributeExistingObject(devedorEnderecoCommand, 'numero'),
        type: POSITIVE_INTEGER,
      }),
      complemento: new FieldModel({
        name: 'complemento',
        value: getAttributeExistingObject(devedorEnderecoCommand, 'complemento'),
      }),
      bairro: new FieldModel({
        name: 'bairro',
        value: getAttributeExistingObject(devedorEnderecoCommand, 'bairro'),
      }),
      cidade: new FieldModel({
        name: 'cidade',
        value: getAttributeExistingObject(devedorEnderecoCommand, 'cidade'),
      }),
      cep: new FieldModel({
        name: 'cep',
        value: getAttributeExistingObject(devedorEnderecoCommand, 'cep'),
      }),
      principal: new FieldModel({
        name: 'principal',
        value: getAttributeExistingObject(devedorEnderecoCommand, 'principal', true),
      }),
      idEndereco: new FieldModel({
        name: 'idEndereco', value: getAttributeExistingObject(devedorEnderecoCommand, 'idEndereco'),
      }),
    };
  }

  buildTelefoneDevedor(telefoneCommand) {
    return {
      ddd: new FieldModel({
        name: 'ddd',
        value: getAttributeExistingObject(telefoneCommand, 'ddd'),
        type: POSITIVE_INTEGER,
      }),
      idEntidadeTelefone: new FieldModel({
        name: 'idEntidadeTelefone',
        value: getAttributeExistingObject(telefoneCommand, 'idEntidadeTelefone'),
      }),
      idTelefone: new FieldModel({
        name: 'idTelefone',
        value: getAttributeExistingObject(telefoneCommand, 'idTelefone'),
      }),
      numero: new FieldModel({
        name: 'numero',
        value: getAttributeExistingObject(telefoneCommand, 'numero'),
        maxLength: 11,
        type: POSITIVE_INTEGER,
      }),
      observacao: new FieldModel({
        name: 'observacao',
        value: getAttributeExistingObject(telefoneCommand, 'observacao'),
        maxLength: 60,
      }),
      permitirWhatsApp: new FieldModel({
        name: 'permitirWhatsApp', value: getAttributeExistingObject(telefoneCommand, 'permitirWhatsApp', true),
      }),
      principal: new FieldModel({
        name: 'principal', value: getAttributeExistingObject(telefoneCommand, 'principal', true),
      }),
      tipoTelefone: new FieldModel({
        name: 'tipoTelefone', value: getAttributeExistingObject(telefoneCommand, 'tipoTelefone'),
      }),
    };
  }

  buildTelefonesDevedor(telefoneCommand) {
    if (!telefoneCommand) {
      return [];
    }

    return telefoneCommand
      .sort((origin, to) => to.principal - origin.principal)
      .map(this.buildTelefoneDevedor);
  }

  buildEnderecosDevedor(devedorEnderecoCommand) {
    if (!devedorEnderecoCommand) {
      return [this.buildEnderecoDevedor({ principal: true })];
    }

    return devedorEnderecoCommand
      .sort((origin, to) => to.principal - origin.principal)
      .map(this.buildEnderecoDevedor);
  }

  buildEmailsDevedor(devedorEmailCommand) {
    if (!devedorEmailCommand) {
      return [];
    }
    return devedorEmailCommand.map(this.buildEmailDevedor);
  }

  makeFormFields(values, idProcesso) {
    return {
      cnpjCpf: FieldModel.newInstance(values?.cnpjCpf || ''),
      codigoNoCliente: FieldModel.newInstance(values?.codigoNoCliente || ''),
      inscricaoEstadual: FieldModel.newInstance(values?.inscricaoEstadual || ''),
      nomeAdvogado: FieldModel.newInstance(values?.nomeAdvogado || ''),
      nomeFantasia: FieldModel.newInstance(values?.nomeFantasia || ''),
      preposto: FieldModel.newInstance(values?.preposto || ''),
      razaoSocial: FieldModel.newInstance(values?.razaoSocial || ''),
      telefoneAdvogado: FieldModel.newInstance(values?.telefoneAdvogado || ''),
      idDevedor: FieldModel.newInstance(values?.idDevedor || ''),
      idCategoria: FieldModel.newInstance(values?.categoria || ''),
      idProcesso,
      enderecos: this.buildEnderecosDevedor(values?.enderecos || []),
      telefones: this.buildTelefonesDevedor(values?.telefones || []),
      emails: this.buildEmailsDevedor(values?.emails || []),
      statusPromisseCodDevedor: FieldModel.newInstance(values?.statusPromisseCodDevedor || true),
      codDevedorPrincipal: FieldModel.newInstance(values?.idDevedorRelacionado || ''),
    };
  }

  makeErrorFields() {
    return {
      cnpjCpf: makeErrorField(),
      codigoNoCliente: makeErrorField(),
      idCategoria: makeErrorField(),
      idDevedor: makeErrorField(),
      idProcesso: makeErrorField(),
      inscricaoEstadual: makeErrorField(),
      nomeAdvogado: makeErrorField(),
      telefoneAdvogado: makeErrorField(),
      nomeFantasia: makeErrorField(),
      razaoSocial: makeErrorField(),
      nomePreposto: makeErrorField(),
      devedorEnderecoCommand: [],
      telefoneCommand: [],
      devedorEmailCommand: [],
    };
  }


  makePayload(fields, immutableSelectors) {
    return {
      cnpjCpf: fields?.cnpjCpf?.value,
      codigoNoCliente: fields?.codigoNoCliente?.value,
      idCategoria: fields?.idCategoria?.value,
      idDevedor: fields?.idDevedor?.value,
      idProcesso: fields?.idProcesso?.value,
      inscricaoEstadual: fields?.inscricaoEstadual?.value,
      nomeAdvogado: fields?.nomeAdvogado?.value,
      telefoneAdvogado: fields?.telefoneAdvogado?.value,
      nomeFantasia: fields?.nomeFantasia?.value,
      razaoSocial: fields?.razaoSocial?.value,
      nomePreposto: fields?.preposto?.value,
      permiteCnpj: immutableSelectors?.categorias.filter(cat => cat.id === fields?.idCategoria?.value)[0].aux === 'CNPJ',
      enderecos: fields?.enderecos?.map(this.makeDevedorEnderecoCommand) || [],
      telefones: fields?.telefones?.map(this.makeDevedorTelefoneCommand) || [],
      emails: fields?.emails?.map(this.makeDevedorEmailCommand) || [],
      idDevedorRelacionado: fields?.codDevedorPrincipal?.value,
    };
  }

  makeDevedorEnderecoCommand(endereco) {
    return {
      bairro: endereco?.bairro?.value || null,
      cep: endereco?.cep?.value || null,
      cidade: endereco?.cidade?.value || null,
      complemento: endereco?.complemento?.value || null,
      endereco: endereco?.endereco?.value || null,
      estado: endereco?.estado?.value || null,
      idEndereco: endereco?.idEndereco?.value || null,
      numero: endereco?.numero?.value || null,
      idDevedorEndereco: endereco?.idDevedorEndereco?.value || null,
      principal: endereco?.principal?.value,
    };
  }

  makeDevedorTelefoneCommand(telefone) {
    return {
      ddd: telefone?.ddd?.value || null,
      idEntidadeTelefone: telefone?.idEntidadeTelefone?.value || null,
      idTelefone: telefone?.idTelefone?.value || null,
      numero: telefone?.numero?.value || null,
      observacao: telefone?.observacao?.value || null,
      permitirWhatsApp: telefone?.permitirWhatsApp?.value,
      principal: telefone?.principal?.value,
      tipoTelefone: telefone?.tipoTelefone?.value || null,

    };
  }

  makeDevedorEmailCommand(email) {
    return {
      email: email?.email?.value || null,
      idDevedorEmail: email?.idDevedorEmail?.value || null,
      principal: email?.principal?.value,
    };
  }

  makeDevedorEnderecoErrorCommand() {
    return {
      bairro: makeErrorField(),
      cep: makeErrorField(),
      cidade: makeErrorField(),
      complemento: makeErrorField(),
      endereco: makeErrorField(),
      estado: makeErrorField(),
      idEndereco: makeErrorField(),
      numero: makeErrorField(),
      idDevedorEndereco: makeErrorField(),
      principal: makeErrorField(),
    };
  }

  makeDevedorTelefoneErrorCommand() {
    return {
      ddd: makeErrorField(),
      idEntidadeTelefone: makeErrorField(),
      idTelefone: makeErrorField(),
      numero: makeErrorField(),
      observacao: makeErrorField(),
      permitirWhatsApp: makeErrorField(),
      principal: makeErrorField(),
      tipoTelefone: makeErrorField(),

    };
  }

  makeDevedorEmailErrorCommand() {
    return {
      email: makeErrorField(),
      idDevedorEmail: makeErrorField(),
      principal: makeErrorField(),
    };
  }
}

export const dadosDevedorController = new DadosDevedorController();
