import React from 'react';
import { RadioGroup } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { useSelector } from 'react-redux';
import Grid24 from '../UI/Grid24';
import { SearchBarForm, TableTitle } from './consultaDinamicaManagerList.styles';
import { TableDefault } from '../UI/Tabela/TableDefault';
import { SearchButton } from '../SearchButton/SeachButton';
import { TableBodyConsultaDinamicaList } from '../TabelaBody/TableBodyConsultaDinamicaList';
import { TableHeaderConsultaDinamicaList } from '../TabelaHeader/TableHeaderConsultaDinamicaList';
import { AtualizarConsultaDinamica } from '../AtualizarConsultaDinamica/AtualizarConsultaDinamica';
import SelectorField from '../UI/Field/Selector/SelectorField';
import InputField from '../UI/Field/Input/InputField';
import NumberInput from '../UI/Field/Number/NumberInput';
import LoadingUI from '../UI/Loading/LoadingUI';
import {
  buildSelectorStatus, buildSelectorTipoConsultaDinamica, getFieldName,
} from '../../helpers';

function makeFields(payload) {
  return {
    idConsultaDinamica: payload?.idConsultaDinamica || '',
    status: payload?.status || '',
    tipo: payload?.tipo || '',
    idUsuario: payload?.idUsuario || '',
    descricao: payload?.descricao || '',
  };
}

function ConsultaDinamicaManagerListComponent({
  payload,
  idConsultaDinamica,
  onChangeSelectedId,
  columnOrder,
  list,
  clearParameterPayload,
  onChangeColumnOrder,
  onChangePage,
  onChangeRowsPerPage,
  onUpdatePayload,
}) {
  const statusList = React.useMemo(() => buildSelectorStatus(), []);
  const tiposConsultaDinamica = React.useMemo(() => buildSelectorTipoConsultaDinamica(), []);
  const [fields, setFields] = React.useState(makeFields());
  const [isLoading, setIsLoading] = React.useState(false);

  const atualizarRef = React.useRef();

  const responsaveis = useSelector(states => states.selectors.usuariosConsultaDinamica.selector);
  const isLoadingSelectors = useSelector(state => state.selectors.usuariosPermissaoConsultaDinamica.loading || state.selectors.usuariosConsultaDinamica.loading);

  function onSubmitSearchForm(e) {
    e.preventDefault();
    onUpdatePayload(fields);
    onChangeSelectedId(null);
  }

  function handleOpenGerenciarConsultaDinamica(consultaDinamicaItem) {
    return () => {
      atualizarRef.current.loadData(consultaDinamicaItem);
    };
  }

  const handleChange = React.useCallback((e) => {
    const name = getFieldName(e.target);
    const { value } = e.target;
    setFields(latestFields => ({ ...latestFields, [name]: value }));
  }, []);

  React.useEffect(() => {
    setFields(makeFields(payload));
  }, [payload]);

  React.useEffect(() => {
    clearParameterPayload();
  }, [clearParameterPayload]);

  return (
    <>
      <LoadingUI show={isLoading || isLoadingSelectors} />
      <AtualizarConsultaDinamica
        ref={atualizarRef}
        onLoading={setIsLoading}
        onUpdateTable={() => onUpdatePayload({ ...payload })}
      />
      <SearchBarForm onSubmit={onSubmitSearchForm}>
        <Grid24 container spacing="1">
          <Grid24 xs={24} sm={4}>
            <SelectorField
              hasEmptyLabel
              fullWidth
              name="status"
              label="Status"
              items={statusList}
              value={fields.status}
              onChange={handleChange}

            />
          </Grid24>
          <Grid24 xs={24} sm={4}>
            <NumberInput
              fullWidth
              name="idConsultaDinamica"
              label="Código"
              acceptEmptyValue
              value={fields.idConsultaDinamica}
              onChange={handleChange}
            />
          </Grid24>
          <Grid24 xs={24} sm={4}>
            <SelectorField
              hasEmptyLabel
              fullWidth
              name="tipo"
              label="Tipo"
              items={tiposConsultaDinamica}
              value={fields.tipo}
              onChange={handleChange}
            />
          </Grid24>
          <Grid24 xs={24} sm={6}>
            <SelectorField
              hasEmptyLabel
              fullWidth
              name="idUsuario"
              label="Responsável"
              items={responsaveis}
              value={fields.idUsuario}
              onChange={handleChange}
            />
          </Grid24>
          <Grid24 xs={24} sm={6}>
            <InputField
              fullWidth
              name="descricao"
              label="Descrição"
              maxLength={100}
              value={fields.descricao}
              onChange={handleChange}
            />
          </Grid24>
        </Grid24>
        <SearchButton type="submit">
          <SearchIcon style={{ marginLeft: 2 }} />
        </SearchButton>
      </SearchBarForm>
      <TableTitle>Selecione a Consulta</TableTitle>
      <RadioGroup
        value={idConsultaDinamica}
        onChange={e => onChangeSelectedId(Number(e.target.value))}
      >
        <TableDefault
          columnsHead={(
            <TableHeaderConsultaDinamicaList
              columnOrder={columnOrder}
              onChangeOrder={onChangeColumnOrder}
            />
        )}
          page={list.page}
          rowsPerPage={list.size}
          totalElements={list.totalElements}
          totalElementsOnPage={list.content?.length}
          totalColumns={9}
          emptyRowsHeight={43}
          rowsPerPageOptions={[10, 15, 20]}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        >
          <TableBodyConsultaDinamicaList
            list={list}
            onOpenGerenciarConsultaDinamica={handleOpenGerenciarConsultaDinamica}
          />
        </TableDefault>
      </RadioGroup>
    </>
  );
}

export const ConsultaDinamicaManagerList = ConsultaDinamicaManagerListComponent;
