import React from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import constants from '../../../../pages/Bordero/Processar/ProcessarBorderoPage.constants';
import Grid24 from '../../../UI/Grid24/Grid24';
import useStyles from './estatisticasBordero.styles';

function EstatisticasBordero() {
  const styles = useStyles();

  const estatisticas = useSelector(states => ({ ...states.processarBorderos.estatisticas }));

  return (
    <Grid24 xs={24} sm={7}>
      <div className={styles.cardEstatisticasBorderos}>
        <header>
          <Typography component="h2">
            {constants.ESTATISTICA_TITULO}
          </Typography>
        </header>
        <hr />
        <section>
          <Typography component="p">
            {constants.ESTATISTICA_AGUARDANDO_PROCESSAMENTO}
            <span>
              {estatisticas.qtdeBorderosAguardandoProcessamento}
            </span>
          </Typography>
          <Typography component="p">
            {constants.ESTATISTICA_BORDEROS_EM_PROCESSAMENTO}
            <span>
              {estatisticas.qtdeBorderoEmProcessamento}
            </span>
          </Typography>
          <Typography component="p">
            {constants.ESTATISTICA_PROCESSADOS}
            <span>{estatisticas.qtdeBorderosProcessados}</span>
          </Typography>
          <Typography component="p">
            {constants
              .ESTATISTICA_EXCLUIDOS}
            <span>{estatisticas.qtdeBorderosExcluidos}</span>
          </Typography>
        </section>
      </div>
    </Grid24>
  );
}

export default React.memo(EstatisticasBordero);
