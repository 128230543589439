import { TableCell, TableRow } from '@material-ui/core';
import React, { useCallback } from 'react';
import { pesquisarListaAutorizacaoRepasseAction } from '../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import { setSimpleValueAutorizacaoRepasseHandlerAction } from '../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { useCCClienteDispatch, useCCClienteSelector } from '../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import OrdenacaoColuna from '../UI/Tabela/Ordenacao/OrdenacaoColuna';

function TableHeaderAutorizacoesRepassesComponent() {
  const dispatch = useCCClienteDispatch();

  const columnOrdenacao = useCCClienteSelector(states => (states.autorizacaoRepasse.columnOrdenacao));
  const arrowDirection = useCCClienteSelector(states => (states.autorizacaoRepasse.arrowDirection));
  const autorizacoesRepasses = useCCClienteSelector(states => ([...states.autorizacaoRepasse.autorizacoesRepasses]));

  const setColumnOrdenacao = useCallback((column) => {
    dispatch(setSimpleValueAutorizacaoRepasseHandlerAction('columnOrdenacao', column));
  }, [dispatch]);

  const setArrowDirection = useCallback((internalReturn) => {
    dispatch(setSimpleValueAutorizacaoRepasseHandlerAction('arrowDirection', internalReturn));
  }, [dispatch]);

  const onClickColumnOrdenar = useCallback(() => {
    if (autorizacoesRepasses.length > 0) {
      dispatch(setSimpleValueAutorizacaoRepasseHandlerAction('page', 0));
      dispatch(pesquisarListaAutorizacaoRepasseAction());
    }
  }, [dispatch, autorizacoesRepasses]);

  return (
    <TableRow>
      <TableCell height={36} width={76}>
        ID
      </TableCell>
      <TableCell height={36} width={135}>
        <OrdenacaoColuna
          columnLabel="Data"
          idColumn="dataLiberacao"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickColumnOrdenar()}
        />
      </TableCell>
      <TableCell height={36} width={276}>
        Lido por
      </TableCell>
      <TableCell height={36} width={135}>
        Data da Leitura
      </TableCell>
      <TableCell height={36} width={194}>
        Valor do Repasse Sispag
      </TableCell>
      <TableCell height={36} width={197}>
        Valor dos outros Repasses
      </TableCell>
      <TableCell height={36} width={130}>
        Ações
      </TableCell>
    </TableRow>
  );
}
export const TableHeaderAutorizacoesRepasses = TableHeaderAutorizacoesRepassesComponent;
