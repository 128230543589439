import { getUriParams } from '../../../helpers/utils/utils';
import { CORE, gateway } from '../../api';

export function gerarAcordo(idAcordo) {
  return gateway.put(`${CORE}/processos/acordos/${idAcordo}/fecha-acordo`);
}

export function findAcordosService(idProcesso, page, size, ordenacao, exibirApenasAcordosAtivosEhPropostas = false) {
  const queryParams = getUriParams({ page, size });
  const sortedQuery = ordenacao.reduce((obj, sort) => `${obj}&sort=${sort}`, '');

  return gateway.get(`${CORE}/processos/acordos/${idProcesso}/${exibirApenasAcordosAtivosEhPropostas}?${sortedQuery}&${queryParams}`);
}

export function imprimirAcordoService(idAcordo) {
  return gateway.get(`${CORE}/processos/acordos/${idAcordo}/imprimir-termo`, {
    responseType: 'blob',
  });
}

export function getDadosResumo(idProcesso) {
  return gateway.get(`${CORE}/processos/acordos/${idProcesso}/informacoes`);
}

export function findResumoAcordo(idProcesso, idsTitulos = []) {
  return gateway.post(`${CORE}/processos/acordos/${idProcesso}/quadro-resumo`, idsTitulos);
}

export function findDadosDefaultAcordo(idProcesso) {
  return gateway.get(`${CORE}/processos/acordos/${idProcesso}/dados-padrao`);
}

export function getTiposFormaPagamentoService() {
  return gateway.get(`${CORE}/tipos-formas-pagamento`);
}

export function findRecalcularAcordo(idProcesso, payload) {
  return gateway.post(`${CORE}/processos/acordos/${idProcesso}/recalcula-quadro-resumo`, payload);
}

export function checkDateParcelaNovoAcordoService(date) {
  return gateway.get(`${CORE}/processos/acordos/novo-acordo/parcelas?dataVencimento=${date}`);
}

export function findParcelasNovoAcordo(payload) {
  return gateway.post(`${CORE}/processos/acordos/prestacoes/acordo-simulacao`, payload);
}

export function quebrarAcordo(payload) {
  return gateway.put(`${CORE}/processos/acordos/quebrar-acordo`, payload);
}

export function gerarAcordoPasso3(payload) {
  return gateway.post(`${CORE}/processos/acordos/prestacoes/gerar-acordo-simulacao`, payload);
}

export function buscarEmailsPadraoAcordo(idAcordo) {
  return gateway.get(`${CORE}/processos/acordos/${idAcordo}/email-padrao-cliente`);
}

export function buscarEmailsPadraoDevedorAcordo(idAcordo) {
  return gateway.get(`${CORE}/processos/acordos/${idAcordo}/email-padrao-devedor`);
}

export function enviarEmailsTermoAcordo(payload, tipoImpressao) {
  return gateway.post(`${CORE}/processos/acordos/enviar-email/${tipoImpressao}`, payload);
}

export function buscarParametrosEmailTermoAcordo(idAcordo, payload) {
  return gateway.put(`${CORE}/processos/acordos/${idAcordo}/enviar-email/buscar-parametros`, payload);
}

export function enviarEmailsParcelaAcordo(payload) {
  return gateway.post(`${CORE}/processos/acordos/parcela/enviar-email`, payload);
}

export function findUsuariosPermitemSimulacao() {
  return gateway.get(`${CORE}/processos/acordos/usuarios-permitem-simulacao`);
}

export function persistirAnaliseSimulacao(payload, idAcordo) {
  return gateway.put(`${CORE}/processos/acordos/${idAcordo}/analise-simulacao`, payload);
}

export function buscarDadosEditarDinheiro(idAcordo, nroParcela) {
  return gateway.get(`${CORE}/processos/acordos/${nroParcela}/${idAcordo}/alterar-parcela-dinheiro`);
}

export function buscarDadosEditarPix(idAcordo, nroParcela) {
  return gateway.get(`${CORE}/processos/acordos/${nroParcela}/${idAcordo}/alterar-parcela-pix`);
}

export function buscarDadosEditarBoleto(idAcordo, nroParcela) {
  return gateway.get(`${CORE}/processos/acordos/${nroParcela}/${idAcordo}/alterar-parcela-boleto`);
}

export function buscarDadosEditarCartao(idAcordo, nroParcela) {
  return gateway.get(`${CORE}/processos/acordos/${nroParcela}/${idAcordo}/alterar-parcela-cartao`);
}

export function buscarDadosEditarCheque(idAcordo, nroParcela) {
  return gateway.get(`${CORE}/processos/acordos/${nroParcela}/${idAcordo}/alterar-parcela-cheque`);
}

export function alterarParcelaDinheiro(payload) {
  return gateway.put(`${CORE}/processos/acordos/alterar-parcela-dinheiro`, payload);
}

export function alterarParcelaCheque(payload) {
  return gateway.put(`${CORE}/processos/acordos/alterar-parcela-cheque`, payload);
}

export function alterarParcelaBoleto(payload) {
  return gateway.put(`${CORE}/processos/acordos/alterar-parcela-boleto`, payload);
}

export function alterarParcelaPix(payload) {
  return gateway.put(`${CORE}/processos/acordos/alterar-parcela-pix`, payload);
}

export function alterarParcelaCartao(payload) {
  return gateway.put(`${CORE}/processos/acordos/alterar-parcela-cartao`, payload);
}

export function motivosQuebraAcordo() {
  return gateway.put(`${CORE}/processos/acordos/quebrar-acordo/motivos`);
}

export function cancelarSimulacao(idAcordo, idProcesso) {
  return gateway.put(`${CORE}/processos/${idProcesso}/acordos/${idAcordo}/cancelar-simulacao`);
}

export function cancelarProposta(idAcordo, idProcesso) {
  return gateway.put(`${CORE}/processos/${idProcesso}/acordos/${idAcordo}/cancelar-proposta`);
}

export function buscarParcelasDistribuicaoPersonalizada(idAcordo) {
  return gateway.get(`${CORE}/processos/acordos/${idAcordo}/distribuicao-personalizada/parcelas`);
}

export function salvarDistribuicaoPersonalizada(idAcordo, payload) {
  return gateway.post(`${CORE}/processos/acordos/${idAcordo}/distribuicao-personalizada/salvar`, payload);
}

export function descartarDistribuicaoPersonalizada(idAcordo) {
  return gateway.get(`${CORE}/processos/acordos/${idAcordo}/distribuicao-personalizada/descartar-personalizacao`);
}
