import {
  put, select, takeLatest, call,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  isNotFoundException,
  isBadRequestException,
  getExceptionMessage,
  getCurrentIdUsuario,
} from '../../../helpers/utils/utils';
import { RESULT_NOT_FOUND } from '../../../helpers/constants/global.constants';
import {
  clearSearchModelAction,
  onOpenFollowUpModalAction,
  setAlertDialogValuesAction,
  setLoadingAction,
  setRequestFieldsAction,
  setSettingsTableAction,
} from './pesquisaCliente.store';
import {
  buscarClientes,
  removerCliente,
  obterAcompanhamentoPorIdAcompanhamento,
  removeFollowUpCliente,
} from '../../../services/core/cliente/cliente.services';
import {
  deletePossivelCliente,
  getPossiveisClientes,
  obterAcompanhamentoPossivelClientePorIdAcompanhamento,
  removeFollowUpPossivelCliente,
} from '../../../services/core/possivelCliente/possivelCliente.services';
import { getExceptionHandler } from '../../../helpers/utils/exception.util';

const FIND_CLIENTES = 'FIND_CLIENTES_PESQUISA_CLIENTE';
const DELETE_CLIENTE = 'DELETE_CLIENTE';
const GET_ACOMPANHAMENTO_CLIENTE = 'GET_ACOMPANHAMENTO_CLIENTE';
const DELETE_DATAFU = 'DELETE_DATAFU_POSSIVEL_CLIENTE';

export const findClientesAction = (page, rowsPerPage, isCliente) => ({
  type: FIND_CLIENTES, page, rowsPerPage, isCliente,
});

export const deleteClienteAction = (idCliente, isCliente) => ({
  type: DELETE_CLIENTE, idCliente, isCliente,
});

export const removeFollowUpAction = (idCliente, idClienteAcompanhamento, isCliente) => ({
  type: DELETE_DATAFU,
  idCliente,
  idClienteAcompanhamento,
  isCliente,
});

export const getAcompanhamentoForClienteAction = (isCliente, idCliente, idClienteAcompanhamento) => ({
  type: GET_ACOMPANHAMENTO_CLIENTE,
  isCliente,
  idCliente,
  idClienteAcompanhamento,
});

function setMessageWhenResultNotFound(length) {
  if (length === 0) {
    toast.warn(RESULT_NOT_FOUND);
  }
}

function* updateTableClientes(cidade, documento, idCliente, idEstado, nome, idFilial, dataFuInicial, dataFuFinal, page, rowsPerPage) {
  let clientes = yield call(buscarClientes, cidade, documento, idCliente, idEstado, nome, idFilial, dataFuInicial, dataFuFinal, page, rowsPerPage);

  if (clientes.data.content.length === 0 && clientes.data.totalElements !== 0) {
    const lastPage = yield clientes.data.totalPages - 1;
    clientes = yield call(buscarClientes, cidade, documento, idCliente, idEstado, nome, idFilial, dataFuInicial, dataFuFinal, lastPage, rowsPerPage);
  }
  yield put(setSettingsTableAction(clientes.data.content, clientes.data.number, clientes.data.size,
    clientes.data.totalElements));
  return clientes;
}

function* updateTablePossiveisClientes(
  cidade, documento, idCliente, idEstado, nome, idFilial, dataFuInicial, dataFuFinal, page, rowsPerPage,
) {
  let clientes = yield call(getPossiveisClientes, cidade, documento, idCliente, idEstado, nome, idFilial, dataFuInicial, dataFuFinal, page, rowsPerPage);
  if (clientes.data.content.length === 0 && clientes.data.totalElements !== 0) {
    const lastPage = yield clientes.data.totalPages - 1;
    clientes = yield call(getPossiveisClientes, cidade, documento, idCliente, idEstado, nome, idFilial, dataFuInicial, dataFuFinal, lastPage, rowsPerPage);
  }
  yield put(setSettingsTableAction(clientes.data.content, clientes.data.number, clientes.data.size,
    clientes.data.totalElements));
  return clientes;
}

function* findClientesHandler(actions) {
  yield put(setLoadingAction(true));
  const cacheRequestFields = yield select(states => states.pesquisaCliente.cacheRequestFields);
  const isSearchMode = yield select(states => states.pesquisaCliente.searchMode);
  const { page, rowsPerPage } = actions;
  const {
    nome, idCliente, cidade, cnpjCpfField, unidadeResponsavel, estado, dataFuInicial, dataFuFinal,
  } = cacheRequestFields;

  try {
    let clientes;
    if (actions.isCliente) {
      clientes = yield updateTableClientes(
        cidade, cnpjCpfField, idCliente, estado, nome, unidadeResponsavel, dataFuInicial, dataFuFinal, page, rowsPerPage,
      );
    } else {
      clientes = yield updateTablePossiveisClientes(
        cidade, cnpjCpfField, idCliente, estado, nome, unidadeResponsavel, dataFuInicial, dataFuFinal, page, rowsPerPage,
      );
    }
    if (isSearchMode) {
      yield setMessageWhenResultNotFound(clientes.data.content.length);
    }
  } catch (exception) {
    const requestFields = yield select(states => states.pesquisaCliente.requestFields);
    const [updateFields] = getExceptionHandler(exception, requestFields);
    yield put(setRequestFieldsAction(updateFields));
    if (isBadRequestException(exception) && getExceptionMessage(exception) !== '') {
      toast.error(getExceptionMessage(exception), { style: { width: '392px' } });
    }
  } finally {
    yield put(clearSearchModelAction());
    yield put(setLoadingAction(false));
  }
}

function* updateSearchTableCliente(isCliente) {
  const nome = yield select(states => states.pesquisaCliente.requestFields.nome.getValueNotEmpty());
  const idCliente = yield select(states => states.pesquisaCliente.requestFields.idCliente.getValueNotEmpty());
  const unidadeResponsavel = yield select(states => states.pesquisaCliente.requestFields.unidadeResponsavel.getValueNotEmpty());
  const cnpjCpfField = yield select(states => states.pesquisaCliente.requestFields.cnpjCpfField.getValueNotEmpty());
  const cidade = yield select(states => states.pesquisaCliente.requestFields.cidade.getValueNotEmpty());
  const estado = yield select(states => states.pesquisaCliente.requestFields.estado.getValueNotEmpty());
  const dataFuInicial = yield select(states => states.pesquisaCliente.requestFields.dataFuInicial.getValueNotEmpty());
  const dataFuFinal = yield select(states => states.pesquisaCliente.requestFields.dataFuFinal.getValueNotEmpty());
  const page = yield select(states => states.pesquisaCliente.settingsTable.page);
  const rowsPerPage = yield select(states => states.pesquisaCliente.settingsTable.rowsPerPage);

  if (isCliente) {
    yield updateTableClientes(cidade,
      cnpjCpfField,
      idCliente,
      estado,
      nome,
      unidadeResponsavel,
      dataFuInicial,
      dataFuFinal,
      page,
      rowsPerPage);
  } else {
    yield updateTablePossiveisClientes(
      cidade,
      cnpjCpfField,
      idCliente,
      estado,
      nome,
      unidadeResponsavel,
      dataFuInicial,
      dataFuFinal,
      page,
      rowsPerPage,
    );
  }
}

function* deleteCliente(idCliente, isCliente) {
  if (isCliente) {
    yield removerCliente(idCliente);
  } else {
    yield deletePossivelCliente(idCliente);
  }
}

function* deleteClienteHandler(actions) {
  const { idCliente, isCliente } = actions;
  yield put(setLoadingAction(true));
  try {
    yield deleteCliente(idCliente, isCliente);
    yield updateSearchTableCliente(isCliente);
  } catch (exception) {
    const notFoundException = isNotFoundException(exception);
    const badRequestException = isBadRequestException(exception);
    const exceptionMessage = getExceptionMessage(exception);

    if (notFoundException || badRequestException) {
      yield put(setAlertDialogValuesAction(true, 'error', exceptionMessage));
    }
    if (notFoundException) {
      yield updateSearchTableCliente(isCliente);
    }
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* getAcompanhamento(promise, idCliente, idClienteAcompanhamento) {
  yield put(setLoadingAction(true));
  try {
    const { data } = yield call(promise, idCliente, idClienteAcompanhamento);
    const isOwnerAcompanhamento = getCurrentIdUsuario() === data.idUsuario;
    yield put(onOpenFollowUpModalAction(true, data.texto, isOwnerAcompanhamento, idCliente, idClienteAcompanhamento));
  } catch (exception) {
    const notFoundException = isNotFoundException(exception);
    const badRequestException = isBadRequestException(exception);
    const messageException = getExceptionMessage(exception);

    if (notFoundException || badRequestException) {
      yield put(setAlertDialogValuesAction(true, 'error', messageException));
    }
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* getAcompanhamentoForClienteHanlder(actions) {
  const { isCliente, idCliente, idClienteAcompanhamento } = actions;
  const promise = (isCliente) ? obterAcompanhamentoPorIdAcompanhamento : obterAcompanhamentoPossivelClientePorIdAcompanhamento;
  yield getAcompanhamento(promise, idCliente, idClienteAcompanhamento);
}

function* deleteDataFu(idCliente, idClienteAcompanhamento, isCliente, promise) {
  const rowsPerPage = select(states => states.pesquisaCliente.settingsTable.rowsPerPage);
  const page = select(states => states.pesquisaCliente.settingsTable.page);

  yield put(setLoadingAction(true));
  try {
    yield call(promise, idCliente, idClienteAcompanhamento);
    yield put(onOpenFollowUpModalAction(false));
    yield put(findClientesAction(page, rowsPerPage, isCliente));
  } catch (exception) {
    // Nenhum tratamento definido.
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* deleteDataFuHandler(actions) {
  const { idCliente, idClienteAcompanhamento, isCliente } = actions;
  const promise = (isCliente) ? removeFollowUpCliente : removeFollowUpPossivelCliente;
  yield deleteDataFu(idCliente, idClienteAcompanhamento, isCliente, promise);
}

export default function* watchPesquisaCliente() {
  yield takeLatest(FIND_CLIENTES, findClientesHandler);
  yield takeLatest(DELETE_CLIENTE, deleteClienteHandler);
  yield takeLatest(GET_ACOMPANHAMENTO_CLIENTE, getAcompanhamentoForClienteHanlder);
  yield takeLatest(DELETE_DATAFU, deleteDataFuHandler);
}
