import React from 'react';
import { useDispatch } from 'react-redux';
import EmailEntradaBorderoProvider from '../../store/EmailEntradaBordero/EmailEntradaBorderoProvider';
import withTokenInterceptor from '../../hoc/withTokenInterceptor/withTokenInterceptor';
import { EmailEntradaBordero } from './EmailEntradaBordero';
import { initializePageAction } from '../../store/theme.actions';


function EmailEntradaBorderoPage() {
  const dispatch = useDispatch();

  dispatch(initializePageAction('E-mail de Entrada de Borderô'));
  return (
    <EmailEntradaBorderoProvider>
      <EmailEntradaBordero />
    </EmailEntradaBorderoProvider>
  );
}

export default withTokenInterceptor(EmailEntradaBorderoPage);
