import React, { useCallback } from 'react';
import {
  Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow,
} from '@material-ui/core';
import useStyles from './Table.styles';

import PaginationAction from './Pagination/PaginationActions';
import TooltipUI from '../../Tooltip/Tooltip';

const layoutConstants = {
  LABEL_ROWS_PER_PAGE: 'Linhas por página',
  PAGINA_ANTERIOR: 'Página Anterior',
  PROXIMA_PAGINA: 'Próxima Página',
};

function getAlignOption(row, column) {
  let alignOption = row.values[column.id] ? column.alignCell : 'center';
  if (!row.values[column.id] && column.showTip) {
    alignOption = 'center';
  }
  return alignOption;
}

function TableUI({
  isMemory = false,
  isScrollable = false,
  columns = [],
  rows = [],
  size = 0,
  rowsPerPageOptions = [10, 15, 20],
  page = 0,
  rowsPerPage,
  onChangePage = () => {},
  onChangeRowsPerPage,
  maxTableHeigth = '325px',
  onClickInLine = null,
  hover = false,
  scrollRef,
  ...props
}) {
  const styles = useStyles({ isScrollable, maxTableHeigth });

  const getContent = (value, column) => {
    if (column.isAction) {
      return value;
    }

    if (column.showTip) {
      return (
        <TooltipUI title={value} placement="bottom" interactive>
          <pre
            className={styles.overFlowText}
            style={{ maxWidth: column.maxWidth === 'auto' ? 'auto' : column.maxWidth - 16 }}
          >
            {column.format && typeof value === 'number' ? column.format(value) : value}
          </pre>
        </TooltipUI>
      );
    }
    if (column.hiddenFullLine) {
      return (
        <pre
          className={styles.overFlowText}
          style={{ maxWidth: column.maxWidth - 16 }}
        >
          {column.format && typeof value === 'number' ? column.format(value) : value}
        </pre>
      );
    }
    return <pre>{column.format && typeof value === 'number' ? column.format(value) : value}</pre>;
  };

  const sanitizeValue = (value) => {
    if (value === undefined || value === '') {
      return '-';
    }
    return value;
  };

  const buildEmptyRows = () => {
    let emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);
    if (isMemory) {
      emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    }
    const content = [];
    for (let i = 0; i < emptyRows; i += 1) {
      content.push(
        <TableRow key={i} style={{ height: 35 }}>
          <TableCell colSpan={columns.length}>
            <p />
          </TableCell>
        </TableRow>,
      );
    }
    return content;
  };

  const buildTableRow = row => (
    <TableRow
      key={row.id}
      {...row.props}
      onClick={onClickInLine && (() => onClickInLine(row.id))}
      hover={hover}
    >
      {columns.map((column) => {
        const value = sanitizeValue(row.values[column.id]);
        const alignOption = getAlignOption(row, column);
        return (
          <TableCell
            key={column.id}
            style={{
              maxWidth: column.maxWidth,
              minWidth: column.minWidth,
              width: column.width,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: alignOption,
              }}
            >
              {getContent(value, column)}
            </div>
          </TableCell>
        );
      })}
    </TableRow>
  );

  const buildContent = () => {
    if (isMemory) {
      return rows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(row => buildTableRow(row));
    }
    return rows.map(row => buildTableRow(row));
  };

  const getColumnLabel = useCallback((column) => {
    const content = column.isIconLabel ? (
      <div className={styles.iconLabel}>
        <i className={column.iconLabel} />
      </div>
    ) : (column.label);
    if (column.hintLabel) {
      return (
        <TooltipUI title={column.hintLabel}>
          {content}
        </TooltipUI>
      );
    }
    return content;
  }, [styles.iconLabel]);


  return (
    <>
      <Paper className={styles.wrapper} style={{ overflowX: 'auto' }} ref={scrollRef} {...props}>
        <Table stickyHeader={isScrollable}>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  id={column.id}
                  key={column.id}
                  style={{
                    maxWidth: column.maxWidth,
                    minWidth: column.minWidth,
                    width: column.width,
                    textAlign: column.align,
                  }}
                >
                  {getColumnLabel(column)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {buildContent()}
            {buildEmptyRows()}
          </TableBody>
        </Table>
      </Paper>
      {!isScrollable && (
        <TablePagination
          style={{ width: '100%' }}
          rowsPerPageOptions={rowsPerPageOptions}
          colSpan={columns.length}
          component="div"
          count={size}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={layoutConstants.LABEL_ROWS_PER_PAGE}
          backIconButtonProps={{
            'aria-label': layoutConstants.PAGINA_ANTERIOR,
          }}
          nextIconButtonProps={{
            'aria-label': layoutConstants.PROXIMA_PAGINA,
          }}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          ActionsComponent={PaginationAction}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        />
      )}
    </>
  );
}

export default React.memo(TableUI);
