import { getUriParams } from '../../../helpers/utils/utils';
import {
  BORDERO, gateway, gatewayShortJson,
} from '../../api';

export const OPERATION_EXCLUIR = 'EXCLUIR';
export const OPERATION_RECUPARAR = 'RECUPERAR';

export function findEstatiticas() {
  return gateway.get(`${BORDERO}/borderos/estatisticas`);
}

export function findBorderos(pageable, order) {
  const queryParam = getUriParams(pageable);
  const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
  return gateway.get(`${BORDERO}/borderos?${queryParam}&${sortedQuery}`);
}

export function findBorderosIds(idCliente, dataInicial, dataFinal, status) {
  const query = getUriParams({
    idCliente,
    dataInicial,
    dataFinal,
    status,
  });
  return gatewayShortJson.get(`${BORDERO}/borderos?${query}`);
}

export function findBorderoById(idBorderoDevedor) {
  return gateway.get(`${BORDERO}/borderos/${idBorderoDevedor}`);
}

export function removeBorderos(idsBorderoDevedor) {
  const query = getUriParams({
    operation: OPERATION_EXCLUIR,
  });
  return gateway.patch(`${BORDERO}/borderos?${query}`, idsBorderoDevedor);
}

export function recoverBorderos(idsBorderoDevedor) {
  const query = getUriParams({
    operation: OPERATION_RECUPARAR,
  });
  return gateway.patch(`${BORDERO}/borderos?${query}`, idsBorderoDevedor);
}

export function insertBordero(payload) {
  return gateway.post(`${BORDERO}/borderos`, payload, {
    headers: { tipoEntrada: 'DIGITACAO_BORDERO' },
  });
}

export function findTiposTitulo() {
  return gateway.get(`${BORDERO}/borderos/tipos-titulo`);
}

export function getInformacoesGeraisProcessamentoBordero(idsBordero) {
  const params = getUriParams({ idsBordero });
  return gateway.get(
    `${BORDERO}/borderos/estatisticas/processamento?${params}`,
  );
}

export function getBorderoTipoStatus() {
  return gateway.get(`${BORDERO}/borderos/tipos-status`);
}

export function iniciarProcessamento(payload) {
  return gateway.post(`${BORDERO}/borderos/processar`, payload);
}

export function getLogProcessamentoBordero(
  dataCadastramentoInicial,
  dataCadastramentoFinal,
  idCliente,
  idProcesso,
  nomeDevedor,
  texto,
  tipoBorderoLog,
  page,
  size,
) {
  const uri = getUriParams({
    dataCadastramentoInicial,
    dataCadastramentoFinal,
    idCliente,
    idProcesso,
    nomeDevedor,
    texto,
    tipoBorderoLog,
    page,
    size,
  });
  return gateway.get(`${BORDERO}/borderos/processamentos/logs?${uri}`);
}

export function getTiposLogProcessamentoBordero() {
  return gateway.get(`${BORDERO}/borderos/processamentos/logs/tipos`);
}

export function getDetalhesLogProcessamentoBodero(idborderoLog) {
  return gateway.get(
    `${BORDERO}/borderos/processamentos/logs/${idborderoLog}/detalhes`,
  );
}

export function validarArquivoBordero(arquivo) {
  const data = new FormData();
  data.append('file', arquivo);
  return gateway.post(`${BORDERO}/borderos/validar-arquivo`, data);
}

export function corrigirBorderoComErro(devedorComErro, versionBordero) {
  return gateway.post(`${BORDERO}/borderos/validar-formulario/${versionBordero}`, devedorComErro);
}

export function downloadBorderoCorrigido(downloadBordero, nomeArquivo) {
  return gateway.post(`${BORDERO}/borderos/download-bordero/${nomeArquivo}`, downloadBordero, { responseType: 'blob' });
}
