import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { MdPrint as MdPrintIcon } from 'react-icons/md';

import useStyles, {
  ButtonImprimir,
  ActionButton,
  ActionButtonGroup,
  ActionContainer,
  ActionContentContainer,
} from './PagamentosStyle';
import CardResumo from '../RelacaoPrestacao/Cards/CardResumo';
import TooltipUI from '../../../../../../components/UI/Tooltip/Tooltip';

import { actionProps, pagamentosController as controller } from './pagamentosController';
import { TableDefault } from '../../../../../../components/UI/Tabela/TableDefault';
import { TableHeaderPagamentos } from '../../../../../../components/TabelaHeader/TableHeaderPagamentos';
import { TableBodyPagamentos } from '../../../../../../components/TabelaBody/TableBodyPagamentos';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { deleteLancamentoPagamentoAction, downloadRelatorioPagamentosAction, findPagamentosAction } from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import LoadingUI from '../../../../../../components/UI/Loading/LoadingUI';
import { setSimpleValuePagamentosAction, setSimpleValuePagamentosValorAction } from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { getNewPage } from '../../../../../../helpers';
import OptionDialogNew from '../../../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';


function PagamentosComponent({ permissoes }) {
  const dispatch = useCCClienteDispatch();

  const idRelacaoSelecionada = useCCClienteSelector(states => (states.common.idRelacaoSelecionada));
  const pagamentos = useCCClienteSelector(states => ([...states.pagamentos.pagamentos]));
  const page = useCCClienteSelector(states => (states.pagamentos.page));
  const rowsPerPage = useCCClienteSelector(states => (states.pagamentos.rowsPerPage));
  const totalElements = useCCClienteSelector(states => (states.pagamentos.totalElements));
  const saldos = useCCClienteSelector(states => (states.pagamentos.saldos));
  const openDialogExcluirLancamento = useCCClienteSelector(states => (states.pagamentos.openDialogExcluirLancamento));
  const disabledButtons = useCCClienteSelector(states => (states.pagamentos.disabledButtons));
  const style = useStyles();
  const [actionsButtons, setActionsButtons] = useState(controller.makeActionsButtons(true));

  function runActionButtonByTag(tag) {
    return () => {
      if (actionProps.quitar.tag === tag) {
        dispatch(setSimpleValuePagamentosValorAction('valor', saldos?.saldoAtualWithoutMask));
      } else {
        dispatch(setSimpleValuePagamentosValorAction('valor', 0));
      }
      setActionsButtons(controller.changeActionButton(actionsButtons, tag));
    };
  }

  const handleCancelExcluirLancamento = useCallback(async () => {
    dispatch(setSimpleValuePagamentosAction('openDialogExcluirLancamento', false));
  }, [dispatch]);

  const handleExcluirLancamento = useCallback(() => {
    dispatch(deleteLancamentoPagamentoAction());
  }, [dispatch]);

  const handleChangePage = useCallback((e, newPage) => {
    dispatch(setSimpleValuePagamentosAction('page', Number(newPage)));
    dispatch(findPagamentosAction());
  }, [dispatch]);

  const handleRowsPerPageChange = useCallback((e) => {
    const size = e.target.value;
    const newPage = getNewPage(rowsPerPage, page, size);
    dispatch(setSimpleValuePagamentosAction('page', Number(newPage)));
    dispatch(setSimpleValuePagamentosAction('rowsPerPage', Number(size)));
    dispatch(findPagamentosAction());
  }, [dispatch, rowsPerPage, page]);

  const downloadRelatorioPagamentoHandler = useCallback(async () => {
    dispatch(downloadRelatorioPagamentosAction());
  }, [dispatch]);

  useEffect(() => {
    if (idRelacaoSelecionada) {
      dispatch(findPagamentosAction());
      setActionsButtons(controller.makeActionsButtons(disabledButtons));
    }
  }, [dispatch, idRelacaoSelecionada, disabledButtons]);

  return (
    <>
      <LoadingUI show={false} />
      <OptionDialogNew
        open={openDialogExcluirLancamento}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={handleCancelExcluirLancamento}
        onClickConfirm={handleExcluirLancamento}
        text=" Deseja excluir o pagamento/acordo da prestação de contas selecionada?"
      />
      <div className={style.body}>
        <header>
          <section className={style.header_nome_cliente}>
            <div>
              <span>
                A Pagar:
                {' '}
              </span>
              <span>{saldos?.saldoAtual}</span>
            </div>
          </section>
          <section className={style.header_imprimir_resumo}>
            <div>
              <ButtonImprimir
                disabled={pagamentos.length === 0}
                onClick={downloadRelatorioPagamentoHandler}
              >
                <MdPrintIcon size={16} />
                <span>Imprimir</span>
              </ButtonImprimir>
            </div>
            <div className={style.card}>
              <div>
                <h3><b>Resumo dos Pagamentos</b></h3>
              </div>
              <div className={style.cardResumo}>
                <CardResumo
                  title="Total"
                  value={saldos?.resumoPagamento}
                  isDetail={false}
                  bgColor="#3cb6be"
                />
              </div>
            </div>
          </section>
          <section>
            <div>
              <ActionContainer>
                <ActionButtonGroup>
                  {actionsButtons.map(actions => (
                    <div key={actions.key}>
                      <TooltipUI title={actions.label} placement="bottom">
                        <div>
                          <ActionButton
                            active={actions.active.toString()}
                            onClick={runActionButtonByTag(actions.tag)}
                            disabled={actions.disabled}
                          >
                            <actions.Icon size={16} style={actions.styles} />
                          </ActionButton>
                        </div>
                      </TooltipUI>
                    </div>
                  ))}
                </ActionButtonGroup>
                {actionsButtons.filter(action => action.active === true).map(action => (
                  <ActionContentContainer key={action.tag}>
                    <action.Content />
                  </ActionContentContainer>
                ))}
              </ActionContainer>
            </div>
          </section>
        </header>
        <main className={style.div__table}>
          <TableDefault
            columnsHead={(
              <TableHeaderPagamentos />
            )}
            page={page}
            rowsPerPage={rowsPerPage}
            totalElements={totalElements}
            totalElementsOnPage={pagamentos?.length}
            totalColumns={7}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            emptyRowsHeight={36}
            rowsPerPageOptions={[10, 15, 20]}
          >
            {pagamentos?.map(pagamento => (
              <TableBodyPagamentos
                pagamento={pagamento}
                permissoes={permissoes}
              />
            ))}
          </TableDefault>
        </main>
      </div>
    </>
  );
}

export default PagamentosComponent;
