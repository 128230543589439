import {
  IconButton, Paper,
} from '@material-ui/core';
import styled from 'styled-components';
import { ButtonUI, PaperUI } from '../../components';
import { white } from '../../helpers/constants/colors.constants';

export const ContainerPrincipal = styled(PaperUI)`
    padding: 22px;
 
    #resumo{
     display:flex ;
     flex-direction:column ;
     justify-content:start ;
     width:45% ;
      }
 `;


export const Container = styled(PaperUI)`
  border-radius: 7px 7px 0px 0px;

  #resumo{
    display:flex ;
    flex-direction:column ;
    justify-content:start ;
    width:45% ;
     }
`;

export const Header = styled.div`
  display:flex ;
  justify-content:space-between ;
  width:100% ;
`;

export const Filter = styled.div`
  display:flex ;
 align-items:center ;
 margin-bottom:10px ;
`;

export const ContainerResumo = styled.div`
    display: flex ;
    justify-content:center ;
    gap:8px ;
    flex-flow:column ;


  > h3 {
    display:flex ;
    justify-content:center ;
    font-size:16px ;
  }

  > div {
    display:flex ;
    flex-direction:row ;
    gap:10px ;
    justify-content:center ;
    color:${white} ;

    h3 {
      text-align:center ;
      font-size:16px ;
    }

    h4 {
      text-align:center ;
      font-size:14px ;
      font-weight:700 ;
    }
  }
 
  > div + div {
    display:flex ;
    flex-direction:row ;
    gap:4px ;
    justify-content:center ;
    color:${white} ;
  }
  `;

export const ButtonEnviar = styled(ButtonUI)`
  width: 152px;
  height:35px ;
`;

export const Buttons = styled.div`
  display:flex ;
  width: 100%;
  justify-content: flex-end;
`;


export const TableWithAccordion = styled(Paper)`
  background-color: #f1f4f9;
  border-radius: 7px;
  margin-top: 22px;
  overflow-x: auto;

  & td {
    font-size: .75rem;
    padding: 0px 8px !important;
  }

  & th {
    cursor: default;
    font-size: .75rem;
    color: #034ddc;
    font-weight: bold;
    padding: 0px 8px !important;
    height: 33px;
    text-align: center;
    line-height: 1rem;
  }
`;

export const TitleTable = styled.div`
  margin-bottom:13px ;
  margin-top:25px ;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
`;

export const ButtonPesquisarCliente = styled(IconButton)`
    margin-left:14px ;
    width:40px;
    height:40px ;
    background-color: #423362;
    color: #ffff ;
    
:hover {
  background-color: ${({ active }) => (active === 'true' ? 'transparent' : '#423362')};
  opacity: 0.64;
}

 `;

export const Footer = styled.footer`

  > div:first-child {
    display: flex;
    justify-content: flex-end;
   padding: 22px;

    > div:first-child {
      width: 100px;
      margin-right: 10px;
    }

    > div:last-child {
      width: 152px;
    }
  }
`;
