import styled from 'styled-components';
import {
  Typography,
  AccordionDetails as AccordionDetailsUI,
  Button,
} from '@material-ui/core';
import ButtonUI from '../../../components/UI/Button/ButtonUI';
import DialogNew from '../../../layouts/FormDialog/DialogNew';

export const Container = styled.div``;

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 16px;

  margin-bottom: 20px;
`;

export const ParentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ChildContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;

  p {
    span {
      font-weight: bold;
    }
  }
`;

export const Divider = styled.hr`
  width: 100%;
  margin-block-start: 8px;
  margin-block-end: 4px;
  border: 1px solid #a2a0a0;
  color: #a2a0a0;
  background-color: #a2a0a0;
`;

export const Info = styled.p`
  span {
    font-weight: bold;
  }
`;

export const Titulos = styled.table`
  border-collapse: collapse;
  border: 1px solid #707070;
  margin-top: 8px;

  td,
  th {
    width: 226px;
    padding: 8px 0;
    font-size: 14px;

    text-align: center;
    border: 1px solid black;
  }

  tbody tr:nth-child(2n+1) {
    background-color: #f4f4f4;
  }

  th {
    font-weight: bold;
  }

  tbody tr td:nth-child(4) {
    text-align: right;
    padding-right: 16px;
  }
`;

export const Pagamento = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin-top: 8px;
  margin-bottom: 12px;
  padding: 12px 32px;

  box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.25);
  border-radius: 100px;

  p {
    font-size: 14px;
    span {
      font-weight: bold;
    }
  }
`;

export const AccordionDetails = styled(AccordionDetailsUI)`
  display: flex;
  flex-direction: column;

  align-items: ${props => (props.alignitems ? props.alignitems : 'flex-start')};

  padding: 16px 36px 8px 36px;
`;

export const BackButton = styled(ButtonUI)`
  margin: 16px 22px;
  width: 152px;
`;

export const ButtonErro = styled(Button)`
&.MuiButton-root {
    color: #034ddc;

    &.Mui-disabled {
      color: #000000;
      opacity: 0.26;
    }
  }
`;

export const DialogNewErro = styled(DialogNew)`
& .MuiDialog-paperWidthSm {
    max-width: 584px;
}
`;
