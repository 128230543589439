import React, {
  useState, useEffect, useRef, useImperativeHandle,
} from 'react';

import parse from 'html-react-parser';
import { acompanhamentoProcessoController } from './acompanhamentoProcessoController';
import { tipoExibicaoAcompanhamento } from '../../../models/types';
import {
  Checkbox,
  Container,
  Content,
  DateIndicator,
  Loading,
  VerticalLine,
} from './AcompanhamentoProcesso.styles';
import { colocarTagEmString } from '../../../helpers';

function getDateIndicator(date) {
  return date.replace(/^[0-9]+\?/, '');
}

function isEndOfScroll(target, acompanhamentos) {
  const { scrollTop, clientHeight, scrollHeight } = target;
  const acceptedInterval = 30;
  return ((scrollTop + clientHeight) >= (scrollHeight - acceptedInterval)) && acompanhamentos && !acompanhamentos.last;
}

export function AcompanhamentoProcessoComponent({
  acompanhamentos = {},
  addNextAcompanhamentos,
  showLoading,
  isDisbaledChangePin = false,
  onChangeChecked = () => { },
  height = 312,
}, ref) {
  const [content, setContent] = useState({});
  const acompanhamentosRef = useRef();

  function onScrollHandler(e) {
    if (isEndOfScroll(e.currentTarget, acompanhamentos)) {
      addNextAcompanhamentos();
    }
  }

  function scrollToTop() {
    if (acompanhamentosRef.current) {
      acompanhamentosRef.current.scrollTop = 0;
    }
  }

  useImperativeHandle(ref, () => ({
    scrollToTop,
  }));

  function formatacaoAcordoAcompanhamento(formTexto) {
    if (formTexto.substr(0, 10) === 'Simulação:') {
      let textoFormatado = colocarTagEmString(formTexto, 'Taxas: Juros: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, 'Valor Total do Acordo: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, 'Acordo: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, 'Sequência dos Títulos negociados: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, 'Total de Parcelas: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, '- Honorários: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, '- Descontos: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, '- Parcelas: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, 'Valor Total da Simulação: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, 'Simulação:', 'strong');
      return textoFormatado;
    }
    if (formTexto.substr(0, 7) === 'Acordo:') {
      let textoFormatado = colocarTagEmString(formTexto, 'Taxas: Juros: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, 'Sequência dos Títulos negociados: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, 'Valor Total do Acordo: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, 'Acordo: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, 'Total de Parcelas: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, '- Honorários: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, '- Descontos: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, '- Parcelas: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, 'Valor Total do Acordo: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, 'Acordo:', 'strong');
      return textoFormatado;
    }
    if (formTexto.substr(0, 13) === 'Simulação nº ') {
      let textoFormatado = colocarTagEmString(formTexto, 'Taxas: Juros: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, 'Sequência dos Títulos negociados: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, 'Valor Total do Acordo: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, 'Acordo: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, 'Total de Parcelas: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, '- Honorários: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, '- Descontos: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, '- Parcelas: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, 'Valor Total do Acordo: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, 'Acordo:', 'strong');
      return textoFormatado;
    }
    if (formTexto.substr(0, 33) === 'Simulação aguardando aprovação de') {
      let textoFormatado = colocarTagEmString(formTexto, 'Simulação aguardando aprovação de ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, '- Motivos: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, '- Motivo: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, '- Sequência dos Títulos negociados: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, '- Taxas: Juros: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, '- Honorários:', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, '- Descontos: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, '- Total de Parcelas: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, '- Parcelas: ', 'strong');
      textoFormatado = colocarTagEmString(textoFormatado, '- Valor Total do Acordo: ', 'strong');
      return textoFormatado;
    }
    return formTexto;
  }


  useEffect(() => {
    if (acompanhamentos && acompanhamentos.content) {
      setContent(acompanhamentoProcessoController.makeItems(acompanhamentos.content));
    } else {
      setContent(acompanhamentoProcessoController.makeItems());
    }
  }, [acompanhamentos]);

  return (
    <Container height={height}>
      <Loading show={showLoading}>
        Carregando...
      </Loading>
      <VerticalLine />
      <div ref={acompanhamentosRef} className="wrapper" onScroll={onScrollHandler}>
        {Object.keys(content).map(data => (
          <ul key={data}>
            <DateIndicator>{getDateIndicator(data)}</DateIndicator>
            {
              content[data].map(acompanhamento => (
                <Content
                  key={acompanhamento.key}
                  type={acompanhamento.idTipoAcompanhamento}
                >
                  <div>
                    <Checkbox
                      type={acompanhamento.idTipoAcompanhamento}
                      unchangedPin={acompanhamento.fixar === tipoExibicaoAcompanhamento.fixoSistema}
                    >
                      {acompanhamento.descricaoTipo === 'Instrução' ? (
                        <input
                          type="checkbox"
                          name={acompanhamento.idProcessoAcompanhamento}
                          disabled
                          defaultValue={acompanhamento.fixar}
                          defaultChecked={acompanhamento.fixar === tipoExibicaoAcompanhamento.fixoSistema || acompanhamento.fixar === tipoExibicaoAcompanhamento.fixo}
                          onChange={onChangeChecked}
                        />
                      )
                        : (
                          <input
                            type="checkbox"
                            name={acompanhamento.idProcessoAcompanhamento}
                            disabled={acompanhamento.fixar === tipoExibicaoAcompanhamento.fixoSistema || isDisbaledChangePin}
                            defaultValue={acompanhamento.fixar}
                            defaultChecked={acompanhamento.fixar === tipoExibicaoAcompanhamento.fixoSistema || acompanhamento.fixar === tipoExibicaoAcompanhamento.fixo}
                            onChange={onChangeChecked}
                          />
                        )
                      }
                      <span className="checkmark" />
                    </Checkbox>
                    {acompanhamento.dataHora && (
                      <p>
                        <span>Data: </span>
                        {acompanhamento.dataHora}
                      </p>
                    )}
                    {acompanhamento.usuario && (
                      <p>
                        <span>Autor: </span>
                        {acompanhamento.usuario}
                      </p>
                    )}
                    {acompanhamento.faseAtualProcesso && (
                      <p>
                        <span>Fase Atual: </span>
                        {acompanhamento.faseAtualProcesso}
                      </p>
                    )}
                    {acompanhamento.texto && (
                      <pre>
                        <span>
                          {acompanhamento.descricaoTipo}
                          :
                          {' '}
                        </span>
                        {parse(formatacaoAcordoAcompanhamento(acompanhamento.texto))}
                      </pre>
                    )}
                    {acompanhamento.faseSeguinteProcesso && (
                      <p>
                        <span>Enviado para: </span>
                        {acompanhamento.faseSeguinteProcesso}
                      </p>
                    )}
                  </div>
                </Content>
              ))
            }
          </ul>
        ))}
      </div>
    </Container>
  );
}


export const AcompanhamentoProcesso = React.forwardRef(AcompanhamentoProcessoComponent);
