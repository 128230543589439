import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

import ButtonUI from '../../Button/ButtonUI';

import WarningIcon from '../../../../assets/images/icons/warning.svg';
import ErrorIcon from '../../../../assets/images/icons/error.svg';
import SuccessIcon from '../../../../assets/images/icons/success.svg';

import useStyles from './AlertDialog.styles';
import { OK } from '../../../../helpers/constants/global.constants';

const icons = {
  warning: WarningIcon,
  error: ErrorIcon,
  success: SuccessIcon,
};

function buildIcons(variant, styles) {
  if (!variant || variant === 'empty') {
    return null;
  }
  return (
    <div className={styles.icon}>
      <img src={icons[variant]} alt="Aviso!" />
    </div>
  );
}

function AlertDialog({
  children,
  onClickConfirm,
  confirmLabel = OK,
  variant,
  withoutButton = false,
  ...props
}) {
  const styles = useStyles();

  return (
    <Dialog {...props}>
      <DialogContent className={styles.root}>
        {buildIcons(variant, styles)}
        <div className={styles.content}>{children}</div>
        {!withoutButton && (
        <div className={styles.footer}>
          <ButtonUI color="success" onClick={onClickConfirm}>
            {confirmLabel}
          </ButtonUI>
        </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default AlertDialog;
