import React, { useCallback, useRef } from 'react';
import SnackbarUI from '../Snackbar/Snackbar';
import { SNACKBAR_DURATION } from '../../../helpers/constants/global.constants';
import ButtonUI from '../Button/ButtonUI';
import useStyles from './fileSelector.styles';


function FileSelector({
  arquivosObject,
  accept = 'text/plain',
  errorMessage,
  error,
  setStatesErrors,
  callbackToExecute,
  closeSnackbarFileHandler,
}) {
  const styles = useStyles();

  const fileRef = useRef();
  const [setArquivos] = arquivosObject;

  const selectFileHandler = useCallback(() => {
    fileRef.current.click();
  }, [fileRef]);

  const onClickFileHandler = useCallback(() => {
    document.getElementById('file-selector').removeAttribute('type');
    document.getElementById('file-selector').setAttribute('type', 'file');
  }, []);


  const fileChangeHandler = useCallback((event) => {
    event.preventDefault();
    const file = event.target.files;

    let tamanhoTotalArquivos = 0;
    let maior10mb = false;
    file.forEach((f) => {
      if (f.size > 10000000) {
        maior10mb = true;
      } else if (!maior10mb) {
        tamanhoTotalArquivos += f.size;
      }
    });
    if (maior10mb || tamanhoTotalArquivos > 100000000) {
      setStatesErrors('error', true);
      setStatesErrors('errorMessage', errorMessage);
      fileRef.current.value = null;
    } else {
      setArquivos(file);
      if (callbackToExecute) {
        callbackToExecute();
      }
    }
  }, [setArquivos, errorMessage, setStatesErrors, callbackToExecute]);

  return (
    <div className={styles.wrapper}>
      <SnackbarUI
        open={error}
        autoHideDuration={SNACKBAR_DURATION}
        variant="error"
        message={errorMessage}
        onClose={closeSnackbarFileHandler}
      />
      <input
        id="file-selector"
        type="file"
        onChange={fileChangeHandler}
        onClick={onClickFileHandler}
        ref={fileRef}
        accept={accept}
        multiple
      />
      <div style={{ marginTop: '10px' }}>
        <div className={styles.button}>
          <ButtonUI onClick={selectFileHandler}>
            Escolher Arquivos
          </ButtonUI>
        </div>
      </div>
    </div>
  );
}

export default FileSelector;
