export const SET_ACTIONS_VALUE = '@importar-bordero/SET_ACTIONS_VALUE';
export const SET_REQUEST_FIELDS = '@importar-bordero/SET_REQUEST_FIELDS';
export const ON_FOCUS_REQUEST_FIELDS = '@importar-bordero/ON_FOCUS_REQUEST_FIELDS';
export const SET_ARQUIVOS = '@importar-bordero/SET_ARQUIVOS';
export const SET_SNACKBAR_IMPORTACAO = '@importar-bordero/SET_SNACKBAR_IMPORTACAO';
export const PESQUISAR_ARQUIVOS_IMPORTACAO = '@importar-bordero/PESQUISAR_ARQUIVOS_IMPORTACAO';
export const GERAR_RELATORIO_ARQUIVO = '@importar-bordero/GERAR_RELATORIO_ARQUIVO';
export const SET_PAGE = '@importar-bordero/SET_PAGE';
export const SET_ROWS_PER_PAGE = '@importar-bordero/SET_ROWS_PER_PAGE';
export const SET_CACHE_REQUEST_FIELDS = '@importar-bordero/SET_CACHE_REQUEST_FIELDS';
export const SET_TABLE_INFO = '@importar-bordero/SET_TABLE_INFO';
export const ON_UPDATE_REQUEST_FIELDS = '@importar-bordero/ON_UPDATE_REQUEST_FIELDS';
export const CLEAR_STATES = '@importar-bordero/CLEAR_STATES';
export const SET_CLICK_PESQUISAR = '@importar-bordero/SET_CLICK_PESQUISAR';
