import { IconButton } from '@material-ui/core';
import styled, { css } from 'styled-components';

const Arrow = styled.button`
  height: 35px;
  width: 35px;
  padding-bottom: 3px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #313131;
  font-size: 12px;
  border: none;
  background-color: white;
  cursor: pointer;

  :hover {
    background-color: #f3f3f3;
    border-radius: 50%;
  }

  :active {
    background-color: #f0f0f0;
    border-radius: 50%;
    color: #FEFEFE;
  }

  :disabled {
    visibility: hidden;
  }
`;

export const PreviousMonth = styled(Arrow)`
`;

export const NextMonth = styled(Arrow)`
`;

export const DateContainer = styled.div`
`;

export const HeaderCalendar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
`;

export const LabelMonth = styled.p`

`;

export const Week = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 4px;
`;

export const Weekday = styled.p`
  text-align: center;
  color: #b09eb0;
  font-size: 10px;
`;

export const Day = styled.button`
  background-color: white;

  ${({ disabled }) => (!disabled ? css`
    color: #313131;
    cursor: pointer;
  ` : css`
    color: #CECECE;
    cursor: default;
  `)};

 ${({ selected }) => (selected && css`
    background-color: #034DDC;
    color: white;
    border-radius: 50%;
 `)}
`;

export const Days = styled.div`
  height: 160px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-size: 12px;
  padding: 4px;
`;

export const CalendarContainer = styled.div`
  width: 220px;
  background-color: #fff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
`;

export const CalendarIconButton = styled(IconButton)`
  padding: 0;
  margin-right: 4px;
`;
