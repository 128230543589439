import React, { createContext } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider, createSelectorHook, createDispatchHook } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import reducer from './crudFilial.store';
import watchCrudFilial from './crudFilial.saga';

export const crudFilialContext = createContext();
export const useCrudFilialSelector = createSelectorHook(crudFilialContext);
export const useCrudFilialDispatch = createDispatchHook(crudFilialContext);

export default function CrudFilialProvider({ children }) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(watchCrudFilial);

  return (
    <Provider context={crudFilialContext} store={store}>
      {children}
    </Provider>
  );
}
