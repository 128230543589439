import { makeStyles } from '@material-ui/styles';

export default makeStyles({

  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    gap: '19px',
  },

  div__table: {
    marginTop: '25px',
  },

});
