import React from 'react';
import SuccessIcon from '../../assets/images/icons/success.svg';
import { dadosDevedorController } from '../../controller/correcaoBorderoController';

import {
  ImgSuccessIcon,
  BodyContainer,
  Container,
  FooterContainer,
  ConclusionBorderoButton,
  ProgressBar,
} from './ConclusaoValidacaoBordero.styles';

const icons = {
  success: SuccessIcon,
};

function ConclusaoValidacaoBordero({
  nomeArquivo, downloadBordero, setHasFieldsCorrections, setCorrecaoFinalizada, setNomeArquivo,
}) {
  function novaValidacao() {
    setHasFieldsCorrections(false);
    setCorrecaoFinalizada(false);
    setNomeArquivo(null);
  }

  async function downloadArquivo() {
    dadosDevedorController.downloadArquivoBordero(downloadBordero, nomeArquivo);
  }

  return (
    <>
      <ProgressBar />
      <BodyContainer>
        <div>
          <ImgSuccessIcon src={icons.success} alt="" />
        </div>
        <div>
          <h1 className="ConclusionText">Correções realizadas com sucesso!</h1>
        </div>
        <div className="FileNameBordero">
          <p>
            Nome Arquivo:
            {' '}
            {nomeArquivo}
          </p>
        </div>
      </BodyContainer>
      <Container>
        <FooterContainer>
          <div>
            <ConclusionBorderoButton color="white" onClick={novaValidacao}>
              Nova Validação
            </ConclusionBorderoButton>
          </div>
          <div>
            <ConclusionBorderoButton color="success" onClick={downloadArquivo}>
              Download
            </ConclusionBorderoButton>
          </div>
        </FooterContainer>
      </Container>
    </>
  );
}

export default ConclusaoValidacaoBordero;
