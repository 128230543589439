import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';

import { toast } from 'react-toastify';
import {
  BottomCalendario,
  BottomMuralAvisos,
  BottomRoot,
  CardsRoot,
  DashboardDrawer,
  EventosCalendario,
  EventosCalendarioRolagem,
  FacilitadoresButton,
  FacilitadoresRoot,
  HeaderDashboardDrawer,
  InputNumeroProcesso,
  MuralCards,
  MuralCardsRoot,
  MuralRoot,
  MyInfoText,
  Root,
} from './Dashboard.style';
import { initializePageAction } from '../../store/theme.actions';
import { moveScroll } from '../../helpers/utils/utils';
import DashboardInfoCard from './Cards/DashboardInfoCards';
import { DashboardCalendario } from '../../components/DashboardCalendario/DashboardCalendario';
import {
  buscarInformacoesDashboardService,
  buscarInformacoesMuralService,
} from '../../services/core/dashboard/dashboard.services';
import LoadingUI from '../../components/UI/Loading/LoadingUI';
import DashboardMural from './Mural/DashboardMural';
import { DateUtils, hasUserPermissions } from '../../helpers';
import { DashboardController } from '../../controller';
import {
  RightDrawer, PublicarEventoCalendarioDashboard, AtualizarMuralDashboard, IconButtonTooltip,
} from '../../components';
import { SearchButton } from '../../components/SearchButton/SeachButton';
import InputField from '../../components/UI/Field/Input/InputField';
import { pesquisaProcessoController as controller } from '../../controller/pesquisarProcessos.controller';
import { KEY_NUMBER, changeKeyHandler } from '../../helpers/utils/changeEvents.utils';

const drawerContextTypes = {
  calendario: 'calendario',
  mural: 'mural',

  isCalendario(value) {
    return value === this.calendario;
  },

  isMural(value) {
    return value === this.mural;
  },
};


function DashboardPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  dispatch(initializePageAction('Home'));

  const [openSide, setOpenSide] = useState(false);
  const [activeContext, setActiveContext] = useState('');
  const [cardsInfos, setCardsInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [informacoesMural, setInformacoesMural] = useState(null);
  const [nroProcesso, setNroProcesso] = useState(null);

  const [selectedDate, setSelectedDate] = useState({
    date: DateUtils.getDateWithoutTime(),
    fullDate: DateUtils.getFullDateFromText(),
  });

  const [eventosCalendario, serEventosCalendario] = useState(DashboardController.makeDashboardCalendarEvents());

  const onClickEditarPaineis = (active) => {
    setOpenSide(old => !old);
    setActiveContext(active);
  };


  async function openNewProcesso() {
    const result = await controller.buscarIdsProcessos();
    const nroProcessoString = nroProcesso.toString();
    if (nroProcesso !== null && Object.prototype.hasOwnProperty.call(result, nroProcessoString)) {
      sessionStorage.setItem('nroProcesso', nroProcesso);
      localStorage.setItem('previousUrl', '/');
      history.push(`/processos/inspecao?nroProcesso=${nroProcesso}`);
    } else {
      toast.warning('Nenhum resultado foi encontrado relacionado à sua pesquisa.',
        { style: { width: '392px' } });
    }
  }

  const buscarInformacoesInicias = useCallback(async () => {
    try {
      setLoading(true);
      const response = await buscarInformacoesDashboardService();
      const muralResponse = await buscarInformacoesMuralService();
      setCardsInfo(response.data);
      setInformacoesMural(muralResponse.data);
    } catch (e) {
      // Nenhum tratamento...
    } finally {
      setLoading(false);
    }
  }, []);

  const updateDashboardCalendar = useCallback(async () => {
    const events = await DashboardController.findAllDashboardCalendarEventByDate(selectedDate.date);
    serEventosCalendario(events);
  }, [selectedDate.date]);

  const permission = React.useMemo(() => {
    const dashboardGerenciar = hasUserPermissions(1401);
    const ccClienteConsultar = hasUserPermissions(1801);
    const ccClienteConsultarTodos = hasUserPermissions(1802);
    const ccClienteEfetuarLancamentos = hasUserPermissions(1803);
    const ccClientePrestarContas = hasUserPermissions(1804);
    const hasAnyPermissionCCCliente = ccClienteConsultar
      || ccClienteConsultarTodos
      || ccClienteEfetuarLancamentos
      || ccClientePrestarContas;
    return {
      dashboardGerenciar,
      ccClienteConsultar,
      ccClienteConsultarTodos,
      ccClienteEfetuarLancamentos,
      ccClientePrestarContas,
      hasAnyPermissionCCCliente,
    };
  }, []);

  const onChange = (e) => {
    changeKeyHandler(e, KEY_NUMBER);
    setNroProcesso(e.target.value);
  };

  // Busca informações iniciais da tela.
  useEffect(() => {
    moveScroll();
    buscarInformacoesInicias().then();
  }, [buscarInformacoesInicias]);

  useEffect(() => {
    updateDashboardCalendar().then();
  }, [updateDashboardCalendar]);

  function handleCloseSideDrawer() {
    setActiveContext('');
    setOpenSide(!openSide);
  }
  return (
    <>
      <Root>
        <LoadingUI show={loading} />
        <CardsRoot>
          <DashboardInfoCard type="borderos" count={cardsInfos?.qtdeBorderos || 0} />
          <DashboardInfoCard type="clientes" count={cardsInfos?.novosClientes || 0} />
          <DashboardInfoCard type="acordos" count={cardsInfos?.acordosFirmados || 0} />
          <DashboardInfoCard type="processos" count={cardsInfos?.processosAtivos || 0} />
        </CardsRoot>
        <MuralRoot>
          <FacilitadoresRoot>
            <FacilitadoresButton onClick={() => history.push('/processos')}>
              Processos
            </FacilitadoresButton>
            <FacilitadoresButton onClick={() => history.push('/cliente')}>
              Clientes
            </FacilitadoresButton>
            <FacilitadoresButton
              onClick={() => history.push('/conta-corrente-cliente')}
              disabled={!permission.hasAnyPermissionCCCliente}
            >
              C/C de Cliente
            </FacilitadoresButton>
            <InputNumeroProcesso>
              <InputField
                name="numeroProcesso"
                label="Nº Processo"
                onChange={onChange}
                maxLength={9}
                fullWidth
                value={nroProcesso || ''}
              />
              <SearchButton onClick={openNewProcesso} style={{ marginTop: '5px' }}>
                <SearchIcon style={{ marginLeft: 2 }} />
              </SearchButton>
            </InputNumeroProcesso>
          </FacilitadoresRoot>
          <MyInfoText>
            Minhas Informações
          </MyInfoText>
          <MuralCardsRoot>
            <MuralCards>
              <div>{cardsInfos?.processosProximoVencimento || 0}</div>
              <span>Processos Próximos ao Vencimento</span>
            </MuralCards>
            <MuralCards>
              <div>{cardsInfos?.processosComPrazoVencido || 0}</div>
              <span>Processos com Prazo Vencido</span>
            </MuralCards>
            <MuralCards>
              <div>{cardsInfos?.processosAVencer || 0}</div>
              <span>Processos a Vencer</span>
            </MuralCards>

            <MuralCards>
              <div>{cardsInfos?.processosNovos || 0}</div>
              <span>Novos Processos</span>
            </MuralCards>
            <MuralCards>
              <div>{cardsInfos?.processosAguardandoRequisicao || 0}</div>
              <span>Processos Aguardando Requisição</span>
            </MuralCards>
            <MuralCards>
              <div>{cardsInfos?.meusProcessos || 0}</div>
              <span>Meus Processos</span>
            </MuralCards>
          </MuralCardsRoot>
          <BottomRoot>
            <BottomCalendario>
              <span>Calendário</span>
              <IconButtonTooltip
                hint="Editar"
                type="edit"
                onClick={() => onClickEditarPaineis(drawerContextTypes.calendario)}
                disabled={!permission.dashboardGerenciar}
              >
                <EditIcon />
              </IconButtonTooltip>
              <div style={{ display: 'flex' }}>
                <DashboardCalendario date={selectedDate.date} handleChangeDate={setSelectedDate} />
                <EventosCalendario>
                  <div>{selectedDate.fullDate}</div>
                  <EventosCalendarioRolagem>
                    {eventosCalendario.map(event => (
                      <p key={event.key}>
                        <i className={event.icone} />
                        {event.descricao}
                      </p>
                    ))}
                  </EventosCalendarioRolagem>
                </EventosCalendario>
              </div>
            </BottomCalendario>
            <BottomMuralAvisos>
              <span>Mural de Avisos</span>
              <IconButtonTooltip
                hint="Editar"
                type="edit"
                onClick={() => onClickEditarPaineis(drawerContextTypes.mural)}
                disabled={!permission.dashboardGerenciar}
              />
              <DashboardMural informacoesMural={informacoesMural} />
            </BottomMuralAvisos>
          </BottomRoot>
        </MuralRoot>
      </Root>

      <RightDrawer open={openSide} handleCloseDrawer={handleCloseSideDrawer}>
        <DashboardDrawer>
          <HeaderDashboardDrawer>
            <p data-active={drawerContextTypes.isCalendario(activeContext)}>
              Calendário
            </p>
            <p data-active={drawerContextTypes.isMural(activeContext)}>
              Mural de Avisos
            </p>
          </HeaderDashboardDrawer>
          {drawerContextTypes.isCalendario(activeContext) && (
            <PublicarEventoCalendarioDashboard
              dashboardDate={selectedDate.date}
              handleCloseSideDrawer={handleCloseSideDrawer}
              handleLoading={setLoading}
              updateDashboardCalendar={updateDashboardCalendar}
            />
          )}

          {drawerContextTypes.isMural(activeContext) && (
            <AtualizarMuralDashboard
              setLoading={setLoading}
              setInformacoesMural={setInformacoesMural}
              handleCloseSideDrawer={handleCloseSideDrawer}
            />
          )}
        </DashboardDrawer>
      </RightDrawer>
    </>
  );
}

export default DashboardPage;
