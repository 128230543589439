import React, {
  useRef, useEffect, useState,
} from 'react';
import { MdEventNote } from 'react-icons/md';

import { isEqual } from 'date-fns';
import MaskField from '../Mask/MaskField';

import { DATE_MASK } from '../../../../helpers/constants/global.constants';

import {
  DateContainer,
  Days,
  HeaderCalendar,
  LabelMonth,
  NextMonth,
  PreviousMonth,
  Week,
  Weekday,
  CalendarContainer,
  CalendarIconButton,
  Day,
} from './dateFieldStyles';
import { FloatBox } from '../../../FloatContainer/FloatBox';
import { DateUtils } from '../../../../helpers';

export function DaysPreviousMonthComponent({ year, month }) {
  const lastDatePreviousMonth = DateUtils.getFirstDatePreviousMonth(year, month);

  if (lastDatePreviousMonth.getDay() === 6) return [];

  const continer = [];
  const startDay = lastDatePreviousMonth.getDate() - lastDatePreviousMonth.getDay() - 1;
  for (let day = startDay; day < lastDatePreviousMonth.getDate(); day += 1) {
    continer.push(<Day key={`previous-month-${day + 1}`} disabled>{day + 1}</Day>);
  }
  return continer;
}

export function DaysNextMonthComponent({ year, month }) {
  const lastDateNextMonth = DateUtils.getFirstDateNextMonth(year, month);

  if (lastDateNextMonth.getDay() > 6) return [];

  const continer = [];
  const endDate = 7 - lastDateNextMonth.getDay();
  for (let day = 0; day < endDate; day += 1) {
    continer.push(<Day key={`next-month-${day + 1}`} disabled>{day + 1}</Day>);
  }
  return continer;
}

function DaysAtMonthComponent({
  year, month, minDate, maxDate, selectedDate = new Date(), onChange,
}) {
  const lastDayCurrentMonth = DateUtils.getLastDateCurrentMonth(year, month);
  const container = [];
  for (let day = 1; day <= lastDayCurrentMonth.getDate(); day += 1) {
    const date = new Date(lastDayCurrentMonth);
    date.setDate(day);
    if (DateUtils.isBetweenDate(minDate, maxDate, date)) {
      const dateToCompare = new Date(year, month, (day));
      container.push(
        <Day
          key={`at-month-${day}`}
          onClick={onChange}
          selected={isEqual(dateToCompare, selectedDate)}
        >
          {day}
        </Day>,
      );
    } else {
      container.push(<Day key={`at-month-${day}`} onClick={onChange} disabled>{day}</Day>);
    }
  }
  return container;
}

function DateFieldComponent({
  name,
  value,
  minDate,
  maxDate,
  disabled,
  error,
  errorMessage,
  inputProps = {},
  onChange = e => e.target.value,
  onBlur = () => { },
  onFocus = () => { },
  setExternalError = () => { },
  ...props
}) {
  const [labelHeader, setLabelHeader] = useState('');
  const [monthYear, setMonthYear] = useState((() => DateUtils.makeMonthYear(value)));
  const [disabledStatusMonth, setDisabledStatusMonth] = useState({ previous: false, next: false });

  const [selectedDate, setSelectedDate] = useState(DateUtils.getISODateFromText(value));

  const [minFullDate, setMinFullDate] = useState(minDate || DateUtils.getDefaultMinDate());
  const [maxFullDate, setMaxFullDate] = useState(maxDate || DateUtils.getDefaultMaxDate());

  const [errorField, setErrorField] = useState(DateUtils.makeErrorField());

  const calentarFloatRef = useRef();
  const calentarContainerRef = useRef();
  const inputRef = useRef();
  const calendarButtonRef = useRef();

  function showPreviousMonth() {
    setMonthYear(DateUtils.decrementMonth);
  }

  function showNextMonth() {
    setMonthYear(DateUtils.incrementMonth);
  }

  function changeDateSeletectHandler(e) {
    const selectedDay = Number(e.target.textContent);
    const newDate = DateUtils.toStringDate(monthYear, selectedDay);
    const newEvent = { target: { name, value: newDate } };

    if (inputRef.current) {
      inputRef.current.value = newDate;
    }


    setSelectedDate(DateUtils.getISODateFromText(newDate));
    onChange(newEvent);
    if (calentarFloatRef.current) calentarFloatRef.current.handlerShowBox();
  }

  function validateDateHandler(e) {
    onBlur(e);

    const { value: newValue } = e.target;
    const validations = DateUtils.getValidationDate(newValue, minFullDate, maxFullDate);
    setErrorField(validations.errorField);
    setExternalError(validations.errorField);
    setSelectedDate(validations.selectedDate);
    setMonthYear(validations.monthYear);
  }

  function resetErrorField(e) {
    onFocus(e);
    setErrorField(DateUtils.makeErrorField());
  }

  /**
   * Coloca a data selecionada no calendário caso haja valor previamente no campo de input.
   */
  useEffect(() => {
    setSelectedDate(DateUtils.getISODateFromText(value));
    setMonthYear(DateUtils.makeMonthYear(value));
  }, [value]);

  /**
   * Adicionar datas mínima e máxima em 'states' para evitar renderizações
   */
  useEffect(() => {
    setMinFullDate(DateUtils.getDefaultMinDate(minDate));
    setMaxFullDate(DateUtils.getDefaultMaxDate(maxDate));
  }, [minDate, maxDate]);

  /**
   * Atualizar internamente os 'erros de campos' para ser gerenciado internamente
   */
  useEffect(() => {
    setErrorField(DateUtils.makeErrorField({ error, message: errorMessage }));
  }, [error, errorMessage]);


  /**
   * Atualizar cabeçalho do calendário
   */
  useEffect(() => {
    if (monthYear.month !== undefined) {
      setLabelHeader(`${DateUtils.CALENDAR_MONTHS[monthYear.month].name} ${monthYear.year}`);
      const disbaledStatus = DateUtils.getDisbaledMonthStatus(monthYear, minFullDate, maxFullDate);
      setDisabledStatusMonth(disbaledStatus);
    }
  }, [monthYear, minFullDate, maxFullDate]);

  const daysHeight = DateUtils.weekCount(monthYear.year, monthYear.month) === 5 ? '190px' : '160px';

  return (
    <>
      <DateContainer>
        <MaskField
          {...props}
          resultWithMask
          disabled={disabled}
          name={name}
          format={DATE_MASK}
          aria-label="date"
          value={value}
          error={errorField.error}
          errorMessage={errorField.message}
          onBlur={validateDateHandler}
          onChange={onChange}
          onFocus={resetErrorField}
          inputProps={{
            ref: inputRef,
            ...inputProps,
          }}
          endAdornment={(
            <CalendarIconButton
              disabled={disabled}
              onClick={() => calentarFloatRef.current.handlerShowBox()}
              name={name}
              ref={calendarButtonRef}
              onFocus={resetErrorField}
            >
              <MdEventNote size={16} />
            </CalendarIconButton>
          )}
        />
      </DateContainer>
      <FloatBox
        ref={calentarFloatRef}
        parentRef={inputRef}
      >
        <CalendarContainer ref={calentarContainerRef}>
          <HeaderCalendar>
            <PreviousMonth disabled={disabledStatusMonth.previous} onClick={showPreviousMonth}>
              &lt;
            </PreviousMonth>
            <LabelMonth>
              {labelHeader}
            </LabelMonth>
            <NextMonth disabled={disabledStatusMonth.next} onClick={showNextMonth}>
              &gt;
            </NextMonth>
          </HeaderCalendar>
          <Week>
            <Weekday>Dom</Weekday>
            <Weekday>Seg</Weekday>
            <Weekday>Ter</Weekday>
            <Weekday>Qua</Weekday>
            <Weekday>Qui</Weekday>
            <Weekday>Sex</Weekday>
            <Weekday>Sab</Weekday>
          </Week>
          <Days height={daysHeight}>
            <DaysPreviousMonthComponent year={monthYear.year} month={monthYear.month} />
            <DaysAtMonthComponent
              year={monthYear.year}
              month={monthYear.month}
              minDate={minFullDate}
              maxDate={maxFullDate}
              selectedDate={selectedDate}
              onChange={changeDateSeletectHandler}
            />
            <DaysNextMonthComponent year={monthYear.year} month={monthYear.month} />
          </Days>
        </CalendarContainer>
      </FloatBox>
    </>
  );
}

export default React.memo(DateFieldComponent);
