import React from 'react';
import AlertDialog from '../../../../UI/Dialogs/AlertDialog/AlertDialog';
import useStyles from './enviouSucessoDialog.styles';

function EnviouSucessoDialog({
  open, onCloseHandler, qtdEmails, qtdSms,
}) {
  const styles = useStyles();

  return (
    <AlertDialog
      variant="success"
      open={open}
      onClickConfirm={onCloseHandler}
    >
      <div className={styles.div__info}>
        <b>Resumo</b>
      </div>
      <div className={styles.div__contadores}>
        <p>
          Quantidade de E-mail(s) enviado(s):
          {qtdEmails}
        </p>
        <p>
          Quantidade de SMS enviado(s):
          {qtdSms}
        </p>
      </div>
    </AlertDialog>
  );
}

export default EnviouSucessoDialog;
