import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';

import Email from './Email/Email';
import WrapperSubTitleForm from '../WrapperSubTitleForm/WrapperSubTitleForm';
import IconButtonTooltip from '../IconButtonTooltip/IconButtonTooltip';

import { initiateEmail } from '../../../helpers/factory/global.factory';

import useUtilStyles from '../../../helpers/styles/utils.styles';

const CONSTANTS = {
  titulo: 'Adicionar E-mail',
  tituloDetalhes: 'E-mails',
  hintAdicionar: 'Adicionar E-mail',
};

function Emails({
  baseName = 'emails',
  emails,
  disabled = false,
  onUpdateAll,
  onChange,
  onFocus,
  className = '',
  requiredFields = [],
}) {
  const utilStyles = useUtilStyles();

  const emailsLength = emails.length;

  const buildBaseName = index => `${baseName}-${index}`;

  const addEmailHandler = () => {
    const newEmail = initiateEmail();
    const newEmails = [...emails];
    newEmails.push(newEmail);
    onUpdateAll(newEmails);
  };

  const removeEmailHandler = index => () => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    onUpdateAll(newEmails);
  };

  const changeHandler = useCallback(
    (event) => {
      const { name, value } = event.target;
      const [, idx, targetName] = name.split('-');
      onChange(idx, targetName, value);
    },
    [onChange],
  );

  const checkHandler = useCallback(
    (event) => {
      const { name, checked } = event.target;
      const [, idx, targetName] = name.split('-');
      onChange(idx, targetName, checked);
    },
    [onChange],
  );

  const focusHandler = useCallback(
    (event) => {
      const { name } = event.target;
      const [, idx, targetName] = name.split('-');
      const newEmails = [...emails];
      const email = newEmails[idx][targetName].copy();
      onFocus(idx, targetName, email.onFocus());
    },
    [onFocus, emails],
  );

  const title = (
    <Grid container alignItems="center">
      {disabled ? CONSTANTS.tituloDetalhes : CONSTANTS.titulo}
      {!disabled && (
        <IconButtonTooltip
          color="primary"
          type="add"
          hint={CONSTANTS.hintAdicionar}
          onClick={addEmailHandler}
        />
      )}
    </Grid>
  );

  const buildEmailComponent = (email, index) => (
    <div key={index}>
      <Email
        disabled={disabled}
        baseName={buildBaseName(index)}
        model={email}
        onChange={changeHandler}
        onFocus={focusHandler}
        onChecked={checkHandler}
        onRemove={removeEmailHandler(index)}
        requiredFields={requiredFields}
      />
      {index !== emailsLength - 1 && <hr className={utilStyles.divider} />}
    </div>
  );

  return (
    <WrapperSubTitleForm title={title} className={className}>
      {emails.map(buildEmailComponent)}
    </WrapperSubTitleForm>
  );
}

export default Emails;
