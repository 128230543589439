import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  grid__dataImportacao: {
    marginTop: '-20px',
    marginLeft: '8px',
    marginRight: '4px',
  },
  grid__buttonPesquisa: {
    marginTop: '-20px',
  },
  grid__status: {
    marginTop: '-12px',
  },
  grid__escolherArquivos: {
    display: 'flex',
    maxWidth: 'max-content',
  },
  grid__icon: {
    marginTop: '20px',
    display: 'flex',
    marginLeft: '10px',
  },
});
