import React, { useCallback, useEffect } from 'react';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { Tooltip } from '@material-ui/core';
import useStyles, { ButtonImportar } from './DadosGeracaoNFStyles';
import SwitchUI from '../../../../../../../components/UI/Switch/SwitchUI';
import { getFieldName, getNewPage } from '../../../../../../../helpers';
import {
  useCCClienteDispatch,
  useCCClienteSelector,
} from '../../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { resetFormFieldsNFAction, setDadosGeracaoNFSwitchAction, setSimpleValueDadosGeracaoNFAction } from '../../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { TableDefault } from '../../../../../../../components';
import { TableHeaderDadosGeracaoNF } from '../../../../../../../components/TabelaHeader/V2/TableHeaderDadosGeracaoNF';
import { TableBodyDadosGeracaoNF } from '../../../../../../../components/TabelaBody/V2/TableBodyDadosGeracaoNF';
import { pesquisarDadosGeracaoNotaFiscalAction } from '../../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import { ImportarArquivoRetorno } from './ImportarArquivoRetorno';

function DadosGeracaoNF({ permissoes }) {
  const styles = useStyles();

  const dispatch = useCCClienteDispatch();

  const formFields = useCCClienteSelector(states => states.dadosGeracaoNF.formFields);
  const dadosGeracaoNFData = useCCClienteSelector(states => (states.dadosGeracaoNF.dadosGeracaoNFData));
  const page = useCCClienteSelector(states => (states.dadosGeracaoNF.page));
  const rowsPerPage = useCCClienteSelector(states => (states.dadosGeracaoNF.rowsPerPage));
  const totalElements = useCCClienteSelector(states => (states.dadosGeracaoNF.totalElements));
  const openButtonImportarArquivo = useCCClienteSelector(states => (states.dadosGeracaoNF.openButtonImportarArquivo));

  const handleChangeSwitch = useCallback((e) => {
    const { checked } = e.target;
    const name = getFieldName(e.target);
    dispatch(setDadosGeracaoNFSwitchAction(name, checked));
    dispatch(setSimpleValueDadosGeracaoNFAction('page', 0));
  }, [dispatch]);

  const changePageHandler = useCallback((event, newPage) => {
    dispatch(setSimpleValueDadosGeracaoNFAction('page', Number(newPage)));
  }, [dispatch]);

  const changeRowsPerPageHandler = useCallback((e) => {
    const size = e.target.value;
    const newPage = getNewPage(rowsPerPage, page, size);

    dispatch(setSimpleValueDadosGeracaoNFAction('page', Number(newPage)));
    dispatch(setSimpleValueDadosGeracaoNFAction('rowsPerPage', Number(size)));
  }, [dispatch, rowsPerPage, page]);

  const onClickButtonImportarArquivo = useCallback(() => {
    dispatch(setSimpleValueDadosGeracaoNFAction('openButtonImportarArquivo', !openButtonImportarArquivo));
    dispatch(resetFormFieldsNFAction());
  }, [dispatch, openButtonImportarArquivo]);

  useEffect(() => {
    dispatch(pesquisarDadosGeracaoNotaFiscalAction());
  }, [dispatch, formFields, rowsPerPage, page]);

  return (
    <>
      <div className={styles.geracaoNFDiv}>
        <div className={styles.dadosNF}>
          <div className={styles.headerDadosNF}>
            <SwitchUI
              name="ultimos30Dias"
              label="Listar os Ultimos 30 dias"
              checked={formFields?.ultimos30Dias.value}
              onChange={handleChangeSwitch}
            />
            <SwitchUI
              name="naoConcluidos"
              label="Listar somente os itens não Concluídos"
              checked={formFields?.naoConcluidos.value}
              onChange={handleChangeSwitch}
            />
          </div>
          <div>
            <ButtonImportar
              onClick={onClickButtonImportarArquivo}
              disabled={!permissoes.prestarContas}
              active={openButtonImportarArquivo.toString()}
            >
              <Tooltip title="Importar Arquivos de Retorno" placement="bottom-start">
                <ImportExportIcon fontSize="small" />
              </Tooltip>
            </ButtonImportar>
          </div>
          {openButtonImportarArquivo === true && (
            <ImportarArquivoRetorno />
          )}
          <div className={styles.dadosNFTable}>
            <TableDefault
              columnsHead={(
                <TableHeaderDadosGeracaoNF />
              )}
              page={page}
              rowsPerPage={rowsPerPage}
              totalElements={totalElements}
              totalElementsOnPage={dadosGeracaoNFData?.length}
              totalColumns={8}
              onPageChange={changePageHandler}
              onRowsPerPageChange={changeRowsPerPageHandler}
              emptyRowsHeight={36}
              rowsPerPageOptions={[10, 15, 20]}
            >
              {
                dadosGeracaoNFData?.map((dadoGeracaoNFData, i) => (
                  <TableBodyDadosGeracaoNF
                    content={dadoGeracaoNFData}
                    permissoes={permissoes}
                    key={i}
                  />
                ))
              }
            </TableDefault>

          </div>
        </div>
      </div>
    </>
  );
}

export default DadosGeracaoNF;
