import styled from 'styled-components';
import ButtonWithIconUI from '../UI/Button/Icon/ButtonWithIconUI';

export const Root = styled.div`
  display: block;
  width: 100%;
`;

export const EditorTexto = styled.div`
  display: block;
  padding: 20px;
`;

export const ClearButton = styled(ButtonWithIconUI)`
  width: 196px;
  background-color: white;
  border: 1px solid #034ddc;
  color: #034ddc;

  :hover {
    background-color: 'white',
  }
`;
