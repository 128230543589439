import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  actions: {
    paddingTop: '17px',
    '& label': {
      marginLeft: '8px',
    },
  },
  actionWithRemoveButton: {
    paddingTop: '11px',
    '& label': {
      marginLeft: '8px',
    },
  },
});
