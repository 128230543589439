import makeStyles from '@material-ui/styles/makeStyles';
import { white } from '../../../helpers/constants/colors.constants';

export default makeStyles({
  div__icon__root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: white,
    cursor: 'default',
    boxShadow: '2px 2px 2px 1px rgb(0 0 0 / 20%)',
  },
});
