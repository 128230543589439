import React, { useRef, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import { DeleteForever, AddCircleOutline, Edit } from '@material-ui/icons';
import { FaReceipt as FaReceiptIcon } from 'react-icons/fa';
import Tooltip from '../Tooltip/Tooltip';
import useStyles from './IconButtonTooltip.styles';


const iconActions = {
  receipt: <FaReceiptIcon />,
  trash: <DeleteForever />,
  add: <AddCircleOutline />,
  searchPlus: <i className="fas fa-search-plus" />,
  calendarAlt: <i className="fas fa-calendar-alt" />,
  edit: <Edit />,
};

function IconButtonTooltip({
  type = 'trash',
  hint,
  onClick,
  attributes,
  tooltipProps,
  disabled,
  className,
  clickArea = '5px',
  ...props
}) {
  const styles = useStyles();
  const ref = useRef();

  useEffect(() => {
    if (ref.current && attributes) {
      Object.keys(attributes).forEach((key) => {
        ref.current.setAttribute(key, attributes[key]);
      });
    }
  }, [ref, attributes]);

  const styleActions = {
    trash: styles.trashButton,
    add: styles.addButton,
    searchPlus: styles.searchPlus,
    calendarAlt: styles.calendarAlt,
    edit: styles.editText,
  };

  let content = (
    <IconButton
      ref={ref}
      style={{ padding: clickArea }}
      className={`${styleActions[type]} ${className}`}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {iconActions[type]}
    </IconButton>
  );

  if (!disabled) {
    content = (
      <Tooltip title={hint} placement="bottom-start" {...tooltipProps}>
        {content}
      </Tooltip>
    );
  }

  return content;
}

export default React.memo(IconButtonTooltip);
