import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, TextField } from '@mui/material';
import CampoObrigatorio from '../../UI/CampoObrigatorio/CampoObrigatorio';
import {
  INFORMACOES, NUMERO, TEXTO, TIPO,
} from '../textospadrao.constants';
import InputField from '../../UI/Field/Input/InputField';
import { getRequiredLabel } from '../../../helpers/utils/utils';
import Grid24 from '../../UI/Grid24/Grid24';
import useStyles from '../textosPadrao.styles';
import useUtilStyles from '../../../helpers/styles/utils.styles';
import { useTextosDispatch, useTextosSelector } from '../../../store/TextosPadrao/TextosPadraoProvider';
import { setRequestFieldFocusAction, setRequestFieldValueAction } from '../../../store/TextosPadrao/textosPadrao.store';
import SelectorField from '../../UI/Field/Selector/SelectorField';
import { saveTextoAction } from '../../../store/TextosPadrao/textosPadrao.saga';
import WrapperSubTitleForm from '../../UI/WrapperSubTitleForm/WrapperSubTitleForm';
import DialogNew from '../../../layouts/FormDialog/DialogNew';

function CrudTextosPadrao({
  routesName = [],
  isCreateMode = false,
  open,
  onClose,
  columnOrdenacao,
}) {
  const styles = useStyles();
  const utilStyles = useUtilStyles();
  const dispatch = useTextosDispatch();
  const [enableButton, setEnableButton] = useState(false);

  const tipoTextoPadrao = useSelector(states => states.selectors.tipoTextoPadrao.selector);

  const numero = useTextosSelector(states => states.requestFields.numero.copy());
  const texto = useTextosSelector(states => states.requestFields.texto.copy());
  const idTipotextoPadrao = useTextosSelector(states => states.requestFields.idTipotextoPadrao.copy());


  const submitHandler = useCallback(() => {
    dispatch(saveTextoAction(isCreateMode, columnOrdenacao));
  }, [dispatch, isCreateMode, columnOrdenacao]);

  const onChangeHandler = useCallback((e) => {
    const { name, value } = e.target;
    dispatch(setRequestFieldValueAction(name, value));
  }, [dispatch]);

  const onFocusTextField = useCallback(() => {
    dispatch(setRequestFieldValueAction('texto'));
  }, [dispatch]);

  const onFocusHandler = useCallback((e) => {
    let fieldName = e.target.name;
    if (e.target.nextElementSibling) {
      fieldName = e.target.nextElementSibling.name;
    }
    dispatch(setRequestFieldFocusAction(fieldName));
  }, [dispatch]);


  useEffect(() => {
    if (numero.value && texto.value && idTipotextoPadrao.value) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  }, [numero.value, texto.value, idTipotextoPadrao.value]);

  return (
    <>
      <DialogNew
        open={open}
        text={routesName}
        onClose={onClose}
        className={styles.dialog}
      >
        {(numero.error || idTipotextoPadrao.error || texto.error) === true ? (
          <CampoObrigatorio visible />
        ) : ('')}

        <WrapperSubTitleForm title={INFORMACOES} className={utilStyles.mt13}>
          <Grid24 container isFormContainer spacing="1">
            <Grid24 item xs={24} sm={7}>
              <InputField
                isForm
                fullWidth
                disabled={!isCreateMode}
                label={getRequiredLabel(NUMERO, true)}
                name="numero"
                value={numero.value}
                error={numero.error}
                errorMessage={numero.errorMessage}
                onChange={onChangeHandler}
                onFocus={onFocusHandler}
              />
            </Grid24>
            <Grid24 xs={24} sm={17} justify="flex-end">

              <SelectorField
                isForm
                fullWidth
                disabled={!isCreateMode}
                label={getRequiredLabel(TIPO, true)}
                items={tipoTextoPadrao}
                name={idTipotextoPadrao.name}
                value={idTipotextoPadrao.value}
                onChange={onChangeHandler}
                onFocus={onFocusHandler}
                error={idTipotextoPadrao.error}
                errorMessage={idTipotextoPadrao.errorMessage}
              />
            </Grid24>
          </Grid24>
          <Grid24 container isFormContainer spacing="1">
            <WrapperSubTitleForm title={getRequiredLabel(TEXTO, true)} className={styles.titleTextoPadrao} />
            <Grid24 item xs={24} className={utilStyles.mt9}>
              <TextField
                multiline
                placeholder="Insira o Texto Padrão"
                readOnly={false}
                id="outlined-multiline-static"
                name="texto"
                value={texto.value}
                error={texto.error}
                errorMessage={texto.errorMessage}
                rows={4}
                onChange={onChangeHandler}
                onFocus={onFocusTextField}
                style={{ width: '100%', marginRight: '6px', marginTop: '8px' }}
              />
            </Grid24>
          </Grid24>
        </WrapperSubTitleForm>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
          <Button
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            disabled={!enableButton}
            onClick={submitHandler}
          >
            Confirmar
          </Button>
        </div>
      </DialogNew>


    </>
  );
}

export default withRouter(CrudTextosPadrao);
