
import { getUriParams } from '../../../helpers';
import {
  CORE, gateway,
} from '../../api';

export default {
  findBoletosRelacaoPrestacao(
    idPrestacaoConta,
    pageable,
    order,
  ) {
    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    const queryParam = getUriParams(pageable);
    return gateway.get(`${CORE}/conta-corrente-cliente/prestacao-relacao/boletos/${idPrestacaoConta}?${queryParam}${sortedQuery}`);
  },
  cancelarBoletoRelacaoPrestacao(
    idBoletoCliente,
  ) {
    return gateway.put(`${CORE}/conta-corrente-cliente/prestacao-relacao/boletos/cancelar/${idBoletoCliente}`);
  },
  registrarBoletoRelacaoPrestacao(
    idBoletoCliente,
  ) {
    return gateway.put(`${CORE}/conta-corrente-cliente/prestacao-relacao/boletos/registrar/${idBoletoCliente}`);
  },
  enviarEmailBoleto(
    idBoletoCliente,
  ) {
    return gateway.post(`${CORE}/conta-corrente-cliente/prestacao-relacao/boletos/email/${idBoletoCliente}`);
  },
  imprimirBoletoRelacaoPrestacao(
    idBoletoCliente,
  ) {
    return gateway.post(`${CORE}/conta-corrente-cliente/prestacao-relacao/boletos/imprimir/${idBoletoCliente}`, { responseType: 'blob' });
  },
};
