import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  dialog: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '756px',
    },
  },
  div__header: {
    width: '100%',
    '& p': {
      fontSize: '16px',
    },
  },
  div__root: {
    border: '1px solid black',
    marginTop: '17px',
    overflowY: 'auto',
    overflowX: 'hidden',
    minHeight: '60px',
    maxHeight: '300px',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      outline: '1px solid #cec8c8',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  div__dadoRetorno: {
    marginLeft: '24px',
    marginTop: '16px',
    marginBottom: '16px',
  },
  div__footer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
  },
  salvarButton: {
    width: '152px',
  },
});
