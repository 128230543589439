import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  root: {
    '& .MuiDialogTitle-root': {
      padding: '0px',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '585px',
    },
    '& .MuiDialogContent-root': {
      padding: '24px',
      width: '100%',
    },
  },

  rootNew: {
    '& .MuiDialogTitle-root': {
      padding: '0px',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '1000px',
    },
    '& .MuiDialogContent-root': {
      width: '100%',
    },
  },
});
