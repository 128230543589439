import React from 'react';
import { Collapse, TableCell } from '@material-ui/core';
import { TableRowContainer } from './CollapseTableRowStyles';

function CollapseTableRowComponent({ children, isOpen }) {
  return (
    <TableRowContainer>
      <TableCell colSpan={8}>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </TableCell>
    </TableRowContainer>
  );
}

export const CollapseTableRow = CollapseTableRowComponent;
