import styled, { css } from 'styled-components';

export const InputFileContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;


  button {
    min-width: auto;
    height: 30px;

    padding: 0 26px;
    margin-left: 11px;
    border-radius: 17px;

    text-transform: none;
    font-weight: bold;
    font-size: 14px;
  }

  input[type='file'] {
    display: none;
  }

  & .MuiButton-outlined {
    color: #034ddc;
    border: 1px solid #034ddc;
  }

  & .MuiButton-outlined button:disabled {
    color: #a2a0a0;
    border: 1px solid #a2a0a0;
  }

  & .MuiButton-contained {
    background-image: linear-gradient(to bottom, #005dff, #4c547e) !important;
    color: white;
    &:hover: {
      background-image: linear-gradient(
        to bottom,
        #005dffaa,
        #4c547eaa
      ) !important;
    }
  }

  & .MuiButton-root.Mui-disabled {
    color: #A2A0A0;
    border: 1px solid #A2A0A0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > span {
    display: none;
    font-size: 11px;
    color: #707070;
    width: 200px;
  }

  ${props => props.hasTextExtensions && css`
    margin-left: 8px;
    position: relative;
    top: 20px;

    > span {
      display: block;
      text-align: center;
    }
  `}
`;
