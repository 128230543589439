import React, { useCallback, useEffect, useState } from 'react';
import { RadioGroup, Typography } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { Button } from '@mui/material';
import useStyles from './textoPadraoDialog.styles';
import TableHeader from '../../../models/TableHeader';
import RadioField from '../../../components/UI/Field/Radio/RadioField';
import LoadingUI from '../../../components/UI/Loading/LoadingUI';
import {
  atualizarFavoritoService,
  buscarTextosPadraoDeAcordoComTiposService,
} from '../../../services/textosPadrao/textosPadrao.services';
import Table from '../../../components/UI/Tabela/PaginadaSimples/Table';
import DialogNew from '../../../layouts/FormDialog/DialogNew';


const tableColumns = [
  new TableHeader({
    id: 'selecionado', label: '', width: '70px', align: 'center', alignCell: 'center',
  }),
  new TableHeader({
    id: 'numero', label: 'Número', width: '70px', alignCell: 'center',
  }),
  new TableHeader({
    id: 'tipo', label: 'Tipo', width: '70px', alignCell: 'center',
  }),
  new TableHeader({
    id: 'texto', label: 'Texto', minWidth: '200px', width: '200px', maxWidth: '250px', showTip: true,
  }),
  new TableHeader({
    id: 'favorito', label: '', width: '30px',
  }),
];

function TextoPadraoDialog({
  open, tipos = [], closeDialogHandler, setTextoHandler,
}) {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [tableRows, setTableRows] = useState([]);

  const [selecionadoRadio, setSelecionadoRadio] = useState('');
  const [textoSelecionado, setTextoSelecionado] = useState('');

  const carregarTextoPadrao = useCallback(async () => {
    setLoading(true);
    try {
      const response = await buscarTextosPadraoDeAcordoComTiposService(tipos);
      setTableRows(response.data);
    } finally {
      setLoading(false);
    }
  }, [tipos]);

  const onChangeRadioGroup = (event) => {
    setSelecionadoRadio(event.target.value);
  };

  const onCloseHandler = useCallback(() => {
    setSelecionadoRadio('');
    setTextoSelecionado('');
    closeDialogHandler();
  }, [closeDialogHandler]);

  const onClickConfirmarHandler = useCallback(() => {
    setTextoHandler(textoSelecionado);
    setSelecionadoRadio('');
    closeDialogHandler();
  }, [textoSelecionado, setTextoHandler, closeDialogHandler]);

  const onClicKFavoritarHandler = useCallback(async (favoritar, conteudo) => {
    const { idTextoPadraoUsuario, idTextoPadrao, idTipoTextoPadrao } = conteudo;
    setLoading(true);
    try {
      await atualizarFavoritoService({
        favoritar, idTextoPadraoUsuario, idTextoPadrao, idTipoTextoPadrao,
      });
      carregarTextoPadrao().then();
    } finally {
      setLoading(false);
    }
  }, [carregarTextoPadrao]);


  const conteudoTabela = tableRows.map(conteudo => ({
    id: uuid(),
    values: {
      selecionado: (
        <RadioField
          name="selecionados"
          onChange={() => setTextoSelecionado(conteudo.texto)}
          value={`${conteudo.idTextoPadrao}-${conteudo.descricaoTipoTextoPadrao}`}
        />),
      numero: conteudo.idTextoPadrao,
      tipo: conteudo.descricaoTipoTextoPadrao,
      texto: conteudo.texto,
      favorito: (
        <button
          type="button"
          className={styles.div__favorito}
          onClick={() => onClicKFavoritarHandler(!conteudo.idTextoPadraoUsuario, conteudo)}
        >
          {conteudo.idTextoPadraoUsuario ? <i className="fas fa-heart" /> : <i className="far fa-heart" />}
        </button>
      ),
    },
  }));

  useEffect(() => {
    if (open) {
      carregarTextoPadrao().then();
    }
  }, [open, carregarTextoPadrao]);

  return (
    <DialogNew
      open={open}
      text={['Inspecionar > Textos Padrão']}
      className={styles.dialog}
      onClose={onCloseHandler}
    >
      <LoadingUI show={loading} />
      <Typography component="p" className={styles.typography}>
        Selecione um Texto Padrão:
      </Typography>
      <div className={styles.div__table}>
        <RadioGroup
          name="selecionados"
          value={selecionadoRadio}
          onChange={onChangeRadioGroup}
        >
          <Table isScrollable rows={conteudoTabela} columns={tableColumns} />
        </RadioGroup>
      </div>
      <div className={styles.div__footer}>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
          <Button
            onClick={onCloseHandler}
          >
            Cancelar
          </Button>
          <Button
            disabled={!selecionadoRadio}
            onClick={onClickConfirmarHandler}
          >
            Confirmar
          </Button>
        </div>
      </div>
    </DialogNew>
  );
}

export default TextoPadraoDialog;
