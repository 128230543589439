import React, {
  useCallback, useState, useEffect, useMemo,
} from 'react';

import { useDispatch } from 'react-redux';
import { pesquisaProcessoController } from '../../../../controller/pesquisarProcessos.controller';

import { changeKeyHandler, KEY_NUMBER, KEY_TEXT } from '../../../../helpers/utils/changeEvents.utils';
import { removeScrollFromBackgroundLayout } from '../../../../helpers/utils/utils';
import { SelectorInputField } from '../../../../pages/Processos/Pesquisar/PesquisarProcessosPage.styles';
import { findPastasFaseAction } from '../../../../store/Global/LoadSelectors/loadSelectors.saga';
import CheckboxField from '../../../UI/Field/Checkbox/CheckboxField';
import DateField from '../../../UI/Field/Date/DateField';
import InputField from '../../../UI/Field/Input';
import PeriodField from '../../../UI/Field/Period/PeriodField';
import SelectorField from '../../../UI/Field/Selector/SelectorField';
import Grid24 from '../../../UI/Grid24';
import { FaseAtualPeriod } from './PesquisaAvancadaProcesso.styles';
import MaskField from '../../../UI/Field/Mask/MaskField';
import { CNPJ_MASK, CPF_MASK } from '../../../../helpers/constants/layout.constants';

export const PesquisaAvancadaProcesso = React.forwardRef(({ items, pesquisar, changeButtonStatus }, ref) => {
  const dispatch = useDispatch();

  const [defaultFilters, setDefaultFilters] = useState(pesquisaProcessoController.makeDefaultFiltersFieldsAdvancedSearch());
  const [dropdownValues, setDropdownValues] = useState(pesquisaProcessoController.makeDefaultDropdownAdvancedSearch());
  const [dataAgendamento, setDataAgendamento] = useState('');
  const [dataAbertura, setDataAbertura] = useState(pesquisaProcessoController.makePeriodoAberturaPesquisaAvancada());
  const [dataFase, setDataFase] = useState(pesquisaProcessoController.makeDataFaseAtualPesquisaAvancada());
  const [processosComRequisicao, setProcessosComRequisicao] = useState(false);
  const [documentFormat, setDocumentFormat] = useState(CPF_MASK);

  function reset() {
    setDefaultFilters(pesquisaProcessoController.makeDefaultFiltersFieldsAdvancedSearch());
    setDropdownValues(pesquisaProcessoController.makeDefaultDropdownAdvancedSearch());
    setDataAbertura(pesquisaProcessoController.makePeriodoAberturaPesquisaAvancada());
    setDataFase(pesquisaProcessoController.makeDataFaseAtualPesquisaAvancada());
    setDataAgendamento('');
    setProcessosComRequisicao(false);
  }

  const fieldsToClear = useMemo(() => (
    [{
      name: dataAbertura.periodoAberturaInicial.name,
      value: dataAbertura.periodoAberturaInicial.value,
    }, {
      name: dataAbertura.periodoAberturaFinal.name,
      value: dataAbertura.periodoAberturaFinal.value,
    }, {
      name: dataFase.dataFaseAtualInicio.name,
      value: dataFase.dataFaseAtualInicio.value,
    },
    {
      name: dataFase.dataFaseAtualFinal.name,
      value: dataFase.dataFaseAtualFinal.value,
    }]
  ), [dataAbertura, dataFase]);

  const changeDataAbertura = useCallback((e) => {
    const { name, value } = e.target;
    setDataAbertura(old => pesquisaProcessoController.updateDatasPesquisaAvancada(old, name, value));
  }, []);

  const changeDataFase = useCallback((e) => {
    const { name, value } = e.target;
    setDataFase(old => pesquisaProcessoController.updateDatasPesquisaAvancada(old, name, value));
  }, []);

  const changeFase = useCallback((e) => {
    const { name, value } = e.target;
    setDropdownValues(old => pesquisaProcessoController.updateAfterChargeFasePesquisaAvancada(old, name, value));
    if (!value) {
      setDropdownValues(old => ({ ...old, idFasePasta: '' }));
    } else {
      dispatch(findPastasFaseAction(value, 'S'));
    }
  }, [dispatch]);

  const changeTipoAgendamento = useCallback((e) => {
    const { name, value } = e.target;
    setDropdownValues(old => pesquisaProcessoController.updateAfterChargeFasePesquisaAvancada(old, name, value));
    if (!value) {
      setDataAgendamento('');
    }
  }, []);

  const changeCheckbox = useCallback((e) => {
    const { checked } = e.target;
    setProcessosComRequisicao(checked);
  }, []);

  const changeDataAgendamento = useCallback((e) => {
    setDataAgendamento(e.target.value);
  }, []);

  const changeDropdown = useCallback((e) => {
    const { name, value } = e.target;
    setDropdownValues(old => pesquisaProcessoController.updateDropdownValue(old, name, value));
  }, []);

  const changeNumberKey = useCallback((e) => {
    const { name, value } = e.target;
    changeKeyHandler(e, KEY_NUMBER);
    changeButtonStatus([{ name, value }]);
  }, [changeButtonStatus]);

  const changeText = useCallback(() => {
    changeButtonStatus();
  }, [changeButtonStatus]);

  const changeDocumentoStringKey = useCallback((e) => {
    const { name, value } = e.target;
    changeKeyHandler(e, KEY_TEXT);
    changeButtonStatus([{ name, value }]);
    if (value.length <= 11) {
      setDocumentFormat(CPF_MASK);
    } else {
      setDocumentFormat(CNPJ_MASK);
    }
  }, [changeButtonStatus, setDocumentFormat]);

  useEffect(() => {
    if (pesquisaProcessoController.isNotActiveAdvancedSearch()) {
      return;
    }

    setDefaultFilters(pesquisaProcessoController.makeDefaultFiltersFieldsAdvancedSearch());
    setDropdownValues(pesquisaProcessoController.makeDefaultDropdownAdvancedSearch(pesquisaProcessoController.filters));
    setDataAgendamento(pesquisaProcessoController.filters?.dataAgendamento || '');
    setDataAbertura(pesquisaProcessoController.makePeriodoAberturaPesquisaAvancada(pesquisaProcessoController.filters));
    setDataFase(pesquisaProcessoController.makeDataFaseAtualPesquisaAvancada(pesquisaProcessoController.filters));
    setProcessosComRequisicao(!!pesquisaProcessoController.filters?.processosComRequisicao);
  }, []);

  useEffect(removeScrollFromBackgroundLayout, []);

  useEffect(() => {
    changeButtonStatus(fieldsToClear);
  }, [dropdownValues, fieldsToClear, processosComRequisicao, changeButtonStatus]);

  return (
    <form ref={ref} onReset={reset} onSubmit={pesquisar}>
      <Grid24 isFormContainer container xs={24} sm={24} spacing={1}>
        <Grid24 item xs={24} sm={8} style={{ marginTop: '5px' }}>
          <InputField
            key={defaultFilters.numeroProcesso.key}
            fullWidth
            defaultValue={pesquisaProcessoController.getNumeroProcessoPesquisaAvancada(defaultFilters.numeroProcesso.value)}
            name="numeroProcesso"
            label="Número do Processo"
            onChange={changeNumberKey}
            maxLength={9}
          />
        </Grid24>
        <Grid24 item xs={24} sm={16} style={{ marginTop: '5px' }}>
          <PeriodField
            label="Período de Abertura"
            firstField={dataAbertura.periodoAberturaInicial}
            lastField={dataAbertura.periodoAberturaFinal}
            onChange={changeDataAbertura}
          />
        </Grid24>
      </Grid24>
      <Grid24 isFormContainer container xs={24} sm={24} spacing={1}>
        <Grid24 item xs={24} sm={8} style={{ marginTop: '5px' }}>
          <InputField
            fullWidth
            key={defaultFilters.codigoCliente.key}
            defaultValue={defaultFilters.codigoCliente.value}
            name="codigoCliente"
            label="Cód. Cliente"
            onChange={changeNumberKey}
            maxLength={9}
          />
        </Grid24>
        <Grid24 xs={24} sm={10} style={{ marginTop: '5px' }}>
          <MaskField
            fullWidth
            key={defaultFilters.cpfCnpjCliente.key}
            label="CPF/CNPJ Cliente"
            name="cpfCnpjCliente"
            onChange={changeDocumentoStringKey}
            format={documentFormat === CPF_MASK ? `${documentFormat}#` : documentFormat}
          />
        </Grid24>
        <Grid24 item xs={24} sm={24} style={{ marginTop: '5px' }}>
          <InputField
            fullWidth
            key={defaultFilters.nomeCliente.key}
            defaultValue={defaultFilters.nomeCliente.value}
            name="nomeCliente"
            label="Nome do Cliente"
            onChange={changeText}
            maxLength={40}
          />
        </Grid24>
        <Grid24 item xs={24} sm={7} style={{ marginTop: '5px' }}>
          <InputField
            fullWidth
            key={defaultFilters.idDevedor.key}
            defaultValue={defaultFilters.idDevedor.value}
            name="idDevedor"
            label="Cód. Devedor"
            onChange={changeNumberKey}
            maxLength={9}
          />
        </Grid24>
        <Grid24 item xs={24} sm={9} style={{ marginTop: '5px' }}>
          <InputField
            fullWidth
            key={defaultFilters.codigoDevedorNoCliente.key}
            defaultValue={defaultFilters.codigoDevedorNoCliente.value}
            name="codigoDevedorNoCliente"
            label="Cód. Devedor no Cliente"
            onChange={changeText}
            maxLength={40}
          />
        </Grid24>
        <Grid24 xs={24} sm={8} style={{ marginTop: '5px' }}>
          <MaskField
            fullWidth
            key={defaultFilters.cpfCnpjDevedor.key}
            label="CPF/CNPJ Devedor"
            name="cpfCnpjDevedor"
            onChange={changeDocumentoStringKey}
            format={documentFormat === CPF_MASK ? `${documentFormat}#` : documentFormat}
          />
        </Grid24>
        <Grid24 item xs={24} sm={24} style={{ marginTop: '5px' }}>
          <InputField
            fullWidth
            key={defaultFilters.nomeDevedor.key}
            defaultValue={defaultFilters.nomeDevedor.value}
            name="nomeDevedor"
            label="Nome do Devedor"
            onChange={changeText}
            maxLength={60}
          />
        </Grid24>
      </Grid24>
      <Grid24 isFormContainer container xs={24} sm={24} spacing={1}>
        <Grid24 item xs={24} sm={14} style={{ marginTop: '5px' }}>
          <InputField
            fullWidth
            key={defaultFilters.cidadeDevedor.key}
            defaultValue={defaultFilters.cidadeDevedor.value}
            name="cidadeDevedor"
            label="Cidade do Devedor"
            onChange={changeText}
            maxLength={75}
          />
        </Grid24>
        <Grid24 item xs={24} sm={10} style={{ marginTop: '5px' }}>
          <SelectorField
            hasEmptyLabel
            fullWidth
            name="idEstado"
            label="Estado do Devedor"
            items={items.estados}
            value={dropdownValues.idEstado}
            onChange={changeDropdown}
          />
        </Grid24>
      </Grid24>
      <Grid24 isFormContainer container xs={24} sm={24} spacing={1}>
        <Grid24 item xs={24} sm={14} style={{ marginTop: '5px' }}>
          <SelectorField
            hasEmptyLabel
            fullWidth
            name="idAgendamento"
            label="Tipo de Agendamento"
            items={items.tiposAgendamentos}
            value={dropdownValues.idAgendamento}
            onChange={changeTipoAgendamento}
          />
        </Grid24>
        <Grid24 item xs={24} sm={10} style={{ marginTop: '5px' }}>
          <DateField
            disabled={!dropdownValues.idAgendamento}
            fullWidth
            name="dataAgendamento"
            label="Agendamento"
            value={dataAgendamento}
            onChange={changeDataAgendamento}
          />
        </Grid24>
      </Grid24>
      <Grid24 isFormContainer container xs={24} sm={24} style={{ marginTop: '5px' }}>
        <SelectorInputField
          hasEmptyLabel
          fullWidth
          name="idFase"
          label="Fase"
          items={items.fases}
          value={dropdownValues.idFase}
          onChange={changeFase}
        />
      </Grid24>
      <Grid24 container isFormContainer xs={24} sm={24} style={{ marginTop: '5px' }}>
        <FaseAtualPeriod
          label="Período de envio para fase atual"
          firstField={dataFase.dataFaseAtualInicio}
          lastField={dataFase.dataFaseAtualFinal}
          right0FirstCalendar={false}
          onChange={changeDataFase}
        />
      </Grid24>
      <Grid24 container isFormContainer xs={24} sm={24} style={{ marginTop: '5px' }}>
        <SelectorInputField
          hasEmptyLabel
          fullWidth
          disabled={dropdownValues.idFase === ''}
          name="idFasePasta"
          label="Pasta"
          items={items.pastasDaFase}
          value={dropdownValues.idFasePasta}
          onChange={changeDropdown}
        />
      </Grid24>
      <Grid24 container isFormContainer xs={24} sm={24} style={{ marginTop: '5px' }}>
        <SelectorInputField
          hasEmptyLabel
          fullWidth
          name="idUsuarioNegociador"
          label="Negociador"
          items={items.negociadores}
          value={dropdownValues.idUsuarioNegociador}
          onChange={changeDropdown}
        />
      </Grid24>
      <Grid24 isFormContainer container xs={24} sm={24} spacing={2}>
        <div style={{ marginLeft: '16px', marginTop: '8px' }}>
          <CheckboxField
            name="processosComRequisicao"
            label="Processos com Requisição"
            value="processosComRequisicao"
            checked={processosComRequisicao}
            onChange={changeCheckbox}
          />
        </div>
      </Grid24>
    </form>
  );
});
