import { getUriParams } from '../../../helpers';
import {
  CORE, RELATORIOS, gateway,
} from '../../api';

export function findResumoFichaFinanceira(idProcesso) {
  return gateway.get(`${CORE}/processos/ficha-financeira/${idProcesso}/quadro-resumo`);
}

export function findFichaFinanceiraAnalitica(idProcesso, page, size, ordenacao) {
  const queryParams = getUriParams({ page, size });
  const sortedQuery = ordenacao.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
  return gateway.get(`${CORE}/processos/ficha-financeira/${idProcesso}/analitica?${sortedQuery}&${queryParams}`);
}

export function findFichaFinanceiraSintetica(idProcesso, page, size, ordenacao) {
  const queryParams = getUriParams({ page, size });
  const sortedQuery = ordenacao.reduce((obj, sort) => `${obj}&sort=${sort}`, '');

  return gateway.get(`${CORE}/processos/ficha-financeira/${idProcesso}/sintetica?${sortedQuery}&${queryParams}`);
}

export function downloadFichaFinanceira(idProcesso, activeTab, ordenacao) {
  const sortedQuery = ordenacao.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
  const tipo = activeTab === 1 ? 'sintetica' : 'analitica';
  return gateway.get(`${RELATORIOS}/processos/ficha-financeira/${idProcesso}/${tipo}/relatorio?${sortedQuery}`);
}
