import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  appBar: {
    opacity: '1 !important',
    borderTopLeftRadius: '7px',
    borderTopRightRadius: '7px',
    '& .Mui-selected': {
      backgroundColor: '#CFD9EB !important',
      color: '#564872',
      fontWeight: 'bold !important',
      letterSpacing: '0.49px',
      font: 'Roboto-Bold',
    },
    '& .MuiTab-root': {
      color: '#564872',
      font: 'Roboto-Regular',
      padding: '10px 6px !important',
      minWidth: '31px',
      marginRight: '3px',
      textTransform: 'none',
      fontSize: '12px',
      lineHeight: '1.19',
      fontWeight: 'normal',
      minHeight: '31px',
      borderTopLeftRadius: '7px',
      borderTopRightRadius: '7px',
      backgroundColor: '#F3F2FC',
      opacity: 1,
    },
    '& .MuiTabs-root': {
      minHeight: '41px',
      '& .MuiTabs-scrollable': {
        marginBottom: '5px !important',
      },
    },
  },
  tabSelectColor: {
    backgroundColor: '#564872',
  },
  ajustWhiteSpace: {
    whiteSpace: 'nowrap',
  },
  bsNone: {
    boxShadow: 'none',
  },
  separator: {
    boxShadow: 'none',
    marginTop: '20px',
  },
});
