import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { toast } from 'react-toastify';
import ButtonUI from '../UI/Button/ButtonUI';
import { DashboardController } from '../../controller';
import { DateUtils, getFieldName, toBrazilianDate } from '../../helpers';
import { findAllTiposCalendarioAction } from '../../store/Global/LoadSelectors/loadSelectors.saga';
import { BodyDashboardDrawer, FooterDashboardDrawer } from '../DashboardDrawer/DashboardDrawer';
import {
  CalendarEvents, DashboardChangeCalendarEventsContainer, SubTitle, TitleAddButton,
} from './publicarEventoCalendarioDashboard.styles';
import { ItemEventoCalendarioDashboard } from '../ItemEventoCalendarioDashboard/ItemEventoCalendarioDashboard';


function PublicarEventoCalendarioDashboardComponent({
  dashboardDate, handleCloseSideDrawer, handleLoading, updateDashboardCalendar,
}) {
  const dispatch = useDispatch();
  const [selectedDate] = React.useState(DateUtils.getDateWithoutTime(dashboardDate));
  const [events, setEvents] = React.useState([]);
  const eventTypes = useSelector(states => states.selectors.tiposCalendario.selector);

  const eventsCalendar = React.useRef();

  function handleChangeField(idx) {
    return (e) => {
      const name = getFieldName(e.target);
      events[idx][name].value = e.target.value;
      setEvents([...events]);
    };
  }

  function handleFocusField(idx) {
    return (e) => {
      const name = getFieldName(e.target);
      events[idx][name].error = false;
      setEvents([...events]);
    };
  }

  function handleDeleteEvent(idx) {
    return () => {
      events.splice(idx, 1);
      setEvents([...events]);
    };
  }

  function addNewEvent() {
    events.push(DashboardController.makeCalendarEvent({ salvar: true }));
    setEvents([...events]);
  }

  async function saveCalendarEvent() {
    handleLoading(true);
    const response = await DashboardController.saveEventCalendar(selectedDate, events);
    setEvents(response.eventos);
    handleLoading(false);

    if (response.status === DashboardController.status.failure) {
      toast.error(response.message, { style: { width: '392px' } });
    } else if (response.status === DashboardController.status.success) {
      await updateDashboardCalendar();
    }

    return response.status;
  }

  async function publishCalendarEvent() {
    const status = await saveCalendarEvent();
    if (status === DashboardController.status.success) {
      handleCloseSideDrawer();
    }
  }

  React.useEffect(() => {
    DashboardController.findAllCalendarEventByDate(selectedDate).then((newEvents) => {
      setEvents(newEvents);
    });
  }, [selectedDate]);

  React.useEffect(() => {
    dispatch(findAllTiposCalendarioAction());
  }, [dispatch]);

  React.useEffect(() => {
    if (eventsCalendar.current) {
      eventsCalendar.current.scrollTop = eventsCalendar.current.scrollHeight;
    }
  }, [events.length]);

  return (
    <>
      <BodyDashboardDrawer>
        <DashboardChangeCalendarEventsContainer>
          <SubTitle>
            <TitleAddButton
              title={`Adicionar Evento para o dia ${toBrazilianDate(dashboardDate)}`}
              readTitle="Adicionar Evento"
              addHandler={addNewEvent}
              tooltip="Adicionar Evento"
            />
          </SubTitle>
          {events?.length > 0 && (
            <CalendarEvents ref={eventsCalendar}>
              {events?.map((event, idx) => (
                <ItemEventoCalendarioDashboard
                  key={event.key.value}
                  idx={idx}
                  event={event}
                  eventTypes={eventTypes}
                  handleChangeField={handleChangeField(idx)}
                  handleDeleteEvent={handleDeleteEvent(idx)}
                  handleFocusField={handleFocusField(idx)}
                />
              ))}
            </CalendarEvents>
          )}
        </DashboardChangeCalendarEventsContainer>
      </BodyDashboardDrawer>
      <FooterDashboardDrawer>
        <ButtonUI
          color="error"
          onClick={handleCloseSideDrawer}
        >
          Cancelar
        </ButtonUI>
        <ButtonUI
          color="success"
          onClick={publishCalendarEvent}
        >
          Publicar
        </ButtonUI>
      </FooterDashboardDrawer>
    </>
  );
}

export const PublicarEventoCalendarioDashboard = PublicarEventoCalendarioDashboardComponent;
