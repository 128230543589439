import React, { useCallback } from 'react';
import {
  useCrudParametrosDispatch,
} from '../../../../../store/Parametros/Crud/CrudParametrosProvider';
import {
  setRequestFieldsFocusAction,
  setRequestFieldsValueAction,
} from '../../../../../store/Parametros/Crud/crudParametros.store';
import Grid24 from '../../../../UI/Grid24/Grid24';
import RegrasProcessamentoBordero from './RegrasProcessamento/RegrasProcessamentoBordero';
import ConfiguracoesBordero from './Configuracao/ConfiguracaoBordero';


function TabCrudParametrosBorderos({ isInsertMode }) {
  const dispatch = useCrudParametrosDispatch();


  const changeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setRequestFieldsValueAction(name, value));
  }, [dispatch]);

  const focusFieldsHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(setRequestFieldsFocusAction(fieldName));
  }, [dispatch]);

  return (
    <>
      <Grid24 container isFormContainer spacing="1">
        <ConfiguracoesBordero
          isInsertMode={isInsertMode}
          editVisible={!isInsertMode}
          onChange={changeFieldsHandler}
          onFocus={focusFieldsHandler}
        />
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <RegrasProcessamentoBordero
          isInsertMode={isInsertMode}
          editVisible={!isInsertMode}
          onChange={changeFieldsHandler}
          onFocus={focusFieldsHandler}
        />
      </Grid24>
    </>
  );
}

export default TabCrudParametrosBorderos;
