import React from 'react';
import Grid24 from '../../../../../UI/Grid24';
import FormWrapperWithTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import CheckboxField from '../../../../../UI/Field/Checkbox/CheckboxField';
import { useCrudClienteSelector } from '../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';

import useStyles from '../PrestacaoContasStyles';

function BloqueioPrestacao({ isEditMode, onChangeChecked, onChangeCheckbox }) {
  const style = useStyles();

  const suspenderPrestacaoConta = useCrudClienteSelector(states => states.controls.suspenderPrestacaoConta.copy());

  return (
    <Grid24 container isFormContainer spacing="1">
      <Grid24 xs={24} sm={24}>
        <FormWrapperWithTitle
          className={style.grid__bloqueio}
          title="Bloqueio de Prestação"
        >
          <CheckboxField
            className={style.grid__checkBox}
            label="Suspender Prestação de Contas"
            name={suspenderPrestacaoConta.name}
            checked={suspenderPrestacaoConta.value}
            onChange={onChangeChecked}
            onChangeCheckbox={onChangeCheckbox}
            disabled={!isEditMode}
          />
        </FormWrapperWithTitle>
      </Grid24>
    </Grid24>
  );
}

export default BloqueioPrestacao;
