import { getUriParams } from '../../../helpers/utils/utils';
import { CORE, gateway } from '../../api';

export function inserirDocumentoProcessoService(multipartPayload) {
  return gateway.post(`${CORE}/processos/documento`, multipartPayload, {
    'Content-Type': 'multipart/form-data',
  });
}

export function updateDocumentoProcessoService(multipartPayload) {
  return gateway.post(`${CORE}/processos/editar-documento`, multipartPayload, {
    'Content-Type': 'multipart/form-data',
  });
}

export function enviarEmailComDocumentosProcessoService(payload) {
  return gateway.post(`${CORE}/processos/documento-email`, payload);
}

export function deleteDocumentoProcessoService(idProcessoDocumento) {
  return gateway.delete(`${CORE}/processos/documento/${idProcessoDocumento}`);
}

export function buscarDocumentosProcessoPorIdProcessoService(idProcesso, pageable = { page: 0, size: 5 }) {
  const queryParams = getUriParams(pageable);
  return gateway.get(`${CORE}/processos/${idProcesso}/documentos?${queryParams}`);
}

export function buscarTodosIdsProcessoDocumentoPorIdProcessoService(idProcesso) {
  return gateway.get(`${CORE}/processos/${idProcesso}/ids-documentos`);
}

export function buscarDocumentosProcessoPorIdDocumentoService(idProcessoDocumento) {
  return gateway.get(`${CORE}/processos/documento/${idProcessoDocumento}`);
}

export function buscarArquivoDocumentoProcessoPorIdDocumentoService(idProcessoDocumento) {
  return gateway.get(`${CORE}/processos/documento-download/${idProcessoDocumento}`, {
    responseType: 'blob',
  });
}
