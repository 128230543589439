import React from 'react';
import { TableRow } from '@material-ui/core';
import { TableCellCenter } from '../UI/Tabela/tableDefaultStyles';

function TableHeaderListaRecalculoProcessoComponent() {
  const height = 54;
  return (
    <TableRow>
      <TableCellCenter height={height} width={130}>Data do Recálculo</TableCellCenter>
      <TableCellCenter height={height} width={103}>Data Base</TableCellCenter>
      <TableCellCenter height={height} width={156}>Fase</TableCellCenter>
      <TableCellCenter height={height} width={220}>Nome do Responsável</TableCellCenter>
      <TableCellCenter height={height} width={154}>Motivo do Recálculo</TableCellCenter>
      <TableCellCenter height={height} width={156}>Tipo de Desconto</TableCellCenter>
      <TableCellCenter height={height} width={156}>Valor Principal</TableCellCenter>
      <TableCellCenter height={height} width={49}><span /></TableCellCenter>
    </TableRow>
  );
}

export const TableHeaderListaRecalculoProcesso = TableHeaderListaRecalculoProcessoComponent;
