import React from 'react';
import OrdenacaoColuna from '../../../../../components/UI/Tabela/Ordenacao/OrdenacaoColuna';
import { getMoneyMask } from '../../../../../helpers';
import TableHeader from '../../../../../models/TableHeader';
import CommentExclamationBlack from '../../../../../assets/images/icons/commentExclamationBlack.svg';
import CommentExclamationBlue from '../../../../../assets/images/icons/commentExclamationBlue.svg';
import TooltipUI from '../../../../../components/UI/Tooltip/Tooltip';
import CheckboxField from '../../../../../components/UI/Field/Checkbox/CheckboxField';

class RecebimentosFactory {
  makeCabecalhoTabela(columnOrdenacao, arrowDirection, setColumnOrdenacao, setArrowDirection, onClickColumnOrdenar, checked = false, onChange = () => { }) {
    return [
      new TableHeader({
        id: 'idPrestacaoContaCliente',
        checkbox: true,
        label:
          <div>
            <CheckboxField
              checked={checked}
              onChange={onChange}
            />
          </div>,
        width: 10,
      }),
      new TableHeader({
        id: 'idPrestacao',
        label: <OrdenacaoColuna
          columnLabel="Prestação"
          idColumn="idPrestacao"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        maxWidth: 120,
        alignCell: 'center',
      }),
      new TableHeader({
        id: 'idCliente',
        label: <OrdenacaoColuna
          columnLabel="Cód.Cliente"
          idColumn="idCliente"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 82,
      }),
      new TableHeader({
        id: 'nomeCliente',
        label: <OrdenacaoColuna
          columnLabel="Nome do Cliente"
          idColumn="nomeCliente"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 200,
      }),
      new TableHeader({
        id: 'tipoEmissao',
        label: <OrdenacaoColuna
          columnLabel="Tipo de Emissão"
          idColumn="tipoEmissao"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 130,
      }),
      new TableHeader({
        id: 'requerInformacoesExtras',
        label: <img src={CommentExclamationBlue} alt="" width="15px" height="14px" />,
        alignCell: 'center',
        maxWidth: 40,
      }),
      new TableHeader({
        id: 'honorariosAmigaveis',
        label: 'Honorários Amigáveis ',
        alignCell: 'left',
        maxWidth: 160,
      }),
      new TableHeader({
        id: 'honorariosJudiciais',
        label: 'Honorários Judiciais',
        alignCell: 'left',
        maxWidth: 160,
      }),
      new TableHeader({
        id: 'despesas',
        label: 'Despesas',
        alignCell: 'left',
        maxWidth: 110,
      }),
    ];
  }

  makeConteudoTabela(recebimentos, changeSelectId, listIds) {
    if (!recebimentos || recebimentos.length === 0) {
      return [];
    }

    return recebimentos.map(recebimento => ({
      id: recebimento.idPrestacaoContaCliente,
      values: {
        descricaoPersonalizado: recebimento.descricaoPersonalizado,
        despesas: getMoneyMask(recebimento.despesas),
        detalhesInfoExtraNF: recebimento.detalhesInfoExtraNF,
        detalhesInformacoesExtras: recebimento.detalhesInformacoesExtras,
        honorariosAmigaveis: getMoneyMask(recebimento.honorariosAmigaveis),
        honorariosJudiciais: getMoneyMask(recebimento.honorariosJudiciais),
        idCliente: recebimento.idCliente,
        idPrestacao: recebimento.idPrestacao,
        idPrestacaoContaCliente: (
          <CheckboxField
            name={String(recebimento.idPrestacaoContaCliente)}
            checked={listIds[recebimento.idPrestacaoContaCliente] || false}
            onChange={changeSelectId}
          />),
        nomeCliente: recebimento.nomeCliente,
        requerInformacoesExtras: recebimento.requerInformacoesExtras === true ? (
          <TooltipUI title={recebimento.detalhesInfoExtraNF}>
            <img src={CommentExclamationBlack} alt="" width="15px" height="14px" />
          </TooltipUI>
        ) : '',
        tipoEmissao: recebimento.tipoEmissao,
        tipoPrestacao: recebimento.tipoPrestacao,
      },
    }));
  }
}


export const recebimentosFactory = new RecebimentosFactory();
