import { toAmericanDate, toBrazilianDate } from '../../../../helpers';
import FieldModel from '../../../../models/FieldModel';
import { alterarParcelaPix } from '../../../../services/core/processos/processosAcordosService';

class DialogAlterarParcelaPixController {
  makeFormFields(dadosParcelaPix) {
    return ({
      dataVencimento: new FieldModel({
        name: 'dataVencimento',
        value: toBrazilianDate(dadosParcelaPix.dataVencimento) || '',
      }),
      observacaoPix: new FieldModel({
        name: 'observacaoPix',
        value: dadosParcelaPix.observacao || '',
      }),
    });
  }


  makePayload(idProcesso, nroParcela, idAcordo, idAcordoParcela, tipoFormPgtp, formFields) {
    return {
      dataValidade: `${toAmericanDate(formFields.dataVencimento.value)}T20:59:00.000`,
      observacao: formFields.observacaoPix.value,
      idProcesso,
      nroParcela,
      idAcordo,
      idAcordoParcela,
      tipoFormPgtp,
    };
  }

  async salvar(idProcesso, nroParcela, idAcordo, idAcordoParcela, tipoFormPgtp, formFields) {
    const payload = this.makePayload(idProcesso, nroParcela, idAcordo, idAcordoParcela, tipoFormPgtp, formFields);
    await alterarParcelaPix(payload);
  }
}

export const dialogAlterarParcelaPixController = new DialogAlterarParcelaPixController();
