export const OBTER_FOTO = '@usuarios/OBTER_FOTO';
export const INSERT_USUARIO = '@usuarios/INSERT_USUARIO';
export const UPDATE_USUARIO = '@usuarios/UPDATE_USUARIO';
export const GET_USUARIO = '@usuarios/GET_USUARIO';
export const APLICAR_PERMISSOES = '@usuarios/APLICAR_PERMISSOES';
export const COMPARAR_PERMISSOES = '@usuarios/COMPARAR_PERMISSOES';
export const ATUALIZAR_LISTA_PERMISSOES = '@usuarios/ATUALIZAR_LISTA_PERMISSOES';
export const BUILD_RELACOES_ITENS_CONFIGURAVEIS = '@usuarios/BUILD_RELACOES_ITENS_CONFIGURAVEIS';
export const SET_ACTIONS_ATTRIBUTE = '@usuarios/SET_ACTIONS_ATTRIBUTE';
export const CLEAR_STATES = '@usuarios/CLEAR_STATES';
export const CLEAR_TO_INITIAL_STATES = '@usuarios/CLEAR_TO_INITIAL_STATES';
export const SET_IMAGE = '@usuarios/SET_IMAGE';
export const SUCCESSFULLY_INSERTED = '@usuarios/SUCCESSFULLY_INSERTED';
export const ON_ATUALIZAR_PERMISSOES = '@usuarios/ON_ATUALIZAR_PERMISSOES';
export const ON_CHANGE_CONSTROLS = '@usuarios/ON_CHANGE_CONSTROLS';
export const ON_CHANGE_ACTIONS = '@usuarios/ON_CHANGE_ACTIONS';
export const ON_CLEAR_PERMISSION_SELECTOR = '@usuarios/ON_CLEAR_PERMISSION_SELECTOR';
export const CHECK_TITLE_PERMISSION_CHECKBOX = '@usuarios/CHECK_TITLE_PERMISSION_CHECKBOX';
export const APPLY_SNACKBAR_ERROR_CONFIG = '@usuarios/APPLY_SNACKBAR_ERROR_CONFIG';
export const CHECK_ENABLED_ADVOGADO_ASSISTENTE_EXTERNO = '@usuarios/ATUALIZAR_ACTIONS_DISABLED_FIELDS';
export const ON_CHANGE_FULLY_CONTROLS = '@usuarios/ON_CHANGE_FULLY_CONTROLS';
export const BUILD_USUARIO = '@usuarios/BUILD';
export const OPEN_ERROR_DIALOG_CONTEXT = '@usuarios/OPEN_ERROR_DIALOG_CONTEXT';
export const OPEN_ERROR_VINCULADO_DIALOG_CONTEXT = '@usuarios/OPEN_ERROR_VINCULADO_DIALOG_CONTEXT';
export const CLOSE_ERROR_DIALOG_CONTEXT = '@usuarios/CLOSE_ERROR_DIALOG_CONTEXT';
export const CLOSE_ERROR_VINCULADO_DIALOG_CONTEXT = '@usuarios/CLOSE_ERROR_VINCULADO_DIALOG_CONTEXT';
export const ON_CHANGE_TIPO_DOCUMENTO = '@usuarios/ON_CHANGE_TIPO_DOCUMENTO';
export const ON_RELOAD_COMBOS_PERMISSOES = '@usuarios/ON_RELOAD_COMBOS_PERMISSOES';
export const SET_ENDERECO_ATTRIBUTE_VALUE = '@usuarios/SET_ENDERECO_ATTRIBUTE_VALUE';
export const SET_ENDERECO_ATTRIBUTE_FOCUS = '@usuarios/SET_ENDERECO_ATTRIBUTE_FOCUS';
export const SET_ENDERECO = '@usuarios/SET_ENDERECO';
export const SET_TELEFONE_ATTRIBUTE_VALUE = '@usuarios/SET_TELEFONE_ATTRIBUTE_VALUE';
export const SET_TELEFONE_ATTRIBUTE_FOCUS = '@usuarios/SET_TELEFONE_ATTRIBUTE_FOCUS';
export const SET_TELEFONES = '@usuarios/SET_TELEFONES';
export const ON_FOCUS_FIELDS = '@usuarios/ON_FOCUS_FIELDS';
export const ON_CLEAR_COMBO_FILIAL_USUARIO = '@usuarios/ON_CLEAR_COMBO_FILIAL';
export const ON_CHANGE_COMBO_FASES = '@usuarios/ON_CHANGE_COMBO_FASES';
export const ON_FOCUS_COMBO_FASES = '@usuarios/ON_FOCUS_COMBO_FASES';
export const ON_CHANGE_REQUISICOES = '@usuarios/ON_CHANGE_REQUISICOES';
export const SET_ALERT_DIALOG = '@usuarios/SET_ALERT_DIALOG';
export const SET_MEMBROS_EQUIPE = '@usuarios/SET_MEMBROS_EQUIPE';
export const CONFIGURE_DIALOG_REFERENTE_EQUIPE = '@usuarios/CONFIGURE_DIALOG_REFERENTE_EQUIPE';
export const GET_FIND_FILIAL_PRINCIPAL_ACTION = '@usuarios/GET_FIND_FILIAL_PRINCIPAL_ACTION';
export const ON_CHANGE_FASE_ATUAL = '@usuarios/ON_CHANGE_FASE_ATUAL';
export const ON_CLICK_ADICIONAR_FASE = '@usuarios/ON_CLICK_ADICIONAR_FASE';
export const ON_CLICK_EXCLUIR_FASE = '@usuarios/ON_CLICK_EXCLUIR_FASE';
export const CHANGE_MUDANCA_TIPO_USUARIO = '@usuarios/CHANGE_MUDANCA_TIPO_USUARIO';
export const DIALOG_MUDANCA_TIPO_USUARIO = '@usuarios/DIALOG_MUDANCA_TIPO_USUARIO';
export const PERMITIR_SUBMIT_MUDANCA_TIPO_USUARIO = '@usuarios/PERMITIR_SUBMIT_MUDANCA_TIPO_USUARIO';
export const ZERAR_FASES_SELECIONADAS_COMISSAO = '@usuarios/ZERAR_FASES_SELECIONADAS_COMISSAO';
export const ON_MAX_FASES_SELECTED = '@usuarios/ON_MAX_FASES_SELECTED';
export const INATIVAR_GERENTE = 'INATIVAR_GERENTE';
export const INATIVAR_MEMBRO = 'INATIVAR_MEMBRO';
export const TROCAR_USUARIO_COMERCIAL_GERENTE = 'TROCAR_USUARIO_COMERCIAL_GERENTE';
export const TROCAR_USUARIO_COMERCIAL_MEMBRO = 'TROCAR_USUARIO_COMERCIAL_MEMBRO';
export const FETCH_TIPOS_USUARIOS = 'FETCH_TIPOS_USUARIOS';
export const ON_CHANGE_IDS_TIPOS_USUARIOS = 'ON_CHANGE_IDS_TIPOS_USUARIOS';


export const MESSAGES_REFERENTE_EQUIPE = {
  INATIVAR_GERENTE: { msg: 'Atenção! Este Usuário Comercial é gerente de uma equipe e sua inativação implicará na dissolução da equipe. Confirma a inativação do Usuário?' },
  INATIVAR_MEMBRO: { msg: 'Atenção! Este Usuário é membro de uma equipe e sua inativação implicará na saída da equipe. Confirma a inativação do Usuário?' },
  TROCAR_USUARIO_COMERCIAL_GERENTE: { msg: 'Atenção! Este  Usuário é gerente de uma equipe e a alteração de seu tipo implicará na dissolução da equipe. Confirma a alteração do tipo do Usuário?' },
  TROCAR_USUARIO_COMERCIAL_MEMBRO: { msg: 'Atenção! Este Usuário é membro de uma equipe e a alteração do seu tipo implicará na sua saída da equipe. Confirma a alteração do tipo do Usuário?' },
};
