export const CHECKED_TYPE = 'checked';
export const SELECTOR_TYPE = 'selector';

export function getValidTextFieldWithMaxLength(maxLength, value) {
  if (maxLength && value.length > maxLength) {
    return value.slice(0, maxLength);
  }
  return value;
}

export function getValidTextFieldPositiveInteger(isPositiveInteger, value) {
  const returnedValue = value;
  if (isPositiveInteger && returnedValue.length < 11) {
    return returnedValue.match(/[0-9]*/g).join('');
  }
  return value;
}

export function isValidRequiredField(field) {
  if (field.required) {
    switch (field.type) {
      case CHECKED_TYPE:
        return field.value === false;
      case SELECTOR_TYPE:
        return field.value === '';
      default:
        return field.value.length === 0;
    }
  }
  return false;
}

export function isValidEmailField(isEmail, value, oldValidations) {
  const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  if (isEmail) {
    return value.length > 0 && !pattern.test(value) && oldValidations;
  }
  return oldValidations;
}

export function isValidMaxLength(maxLength, value, oldValidations) {
  if (maxLength) {
    return value.length > 0 && value.length !== maxLength && oldValidations;
  }
  return oldValidations;
}

export function defaultChangeHandler(event, changeAttr) {
  let targetValue = event.target.value;
  if (changeAttr) {
    targetValue = getValidTextFieldWithMaxLength(changeAttr.maxLength, targetValue);
    targetValue = getValidTextFieldPositiveInteger(changeAttr.isPositiveInteger, targetValue);
  }
  return targetValue;
}
