import React, {
  memo, useCallback, useEffect,
} from 'react';
import { Button, TextField } from '@mui/material';
import {
  CampoObrigatorio, Loading, SelectorField,
} from '../../../../../../../components';
import WrapperSubTitleForm from '../../../../../../../components/UI/WrapperSubTitleForm';
import iconCliente from '../../../../../../../assets/images/icons/iconCliente.svg';
import InputField from '../../../../../../../components/UI/Field/Input/InputField';
import useUtilStyle from '../../../../../../../helpers/styles/utils.styles';
import FormWrapperWithTitle from '../../../../../../../components/UI/Wrapper/Form/FormWrapperWithTitle';
import Grid24 from '../../../../../../../components/UI/Grid24';
import DateField from '../../../../../../../components/UI/Field/Date/DateField';
import {
  getRequiredLabel, toastUnmappedException,
} from '../../../../../../../helpers';
import MoneyField from '../../../../../../../components/UI/Field/Money/MoneyField';
import CheckboxField from '../../../../../../../components/UI/Field/Checkbox/CheckboxField';
import { LancamentosNaoPrestadosController } from '../LancamentosNaoPrestadosController';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { editarLancamentoNaoPrestadoAction, salvarLancamentoNaoPrestadoAction } from '../../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import { setRequestFieldsFocusNaoPrestadosFormToSaveAction, setSimpleValueNaoPrestaosFormToSaveHandlerAction } from '../../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';

function makeLoading() {
  return {
    tiposDeLancamentos: false,
    lancamentoCliente: false,
  };
}

function DialogLancamentosNaoPrestados({
  onClose, nome, idCliente,
}) {
  const dispatch = useCCClienteDispatch();
  const utilStyle = useUtilStyle();

  const historico = useCCClienteSelector(states => (states.naoPrestados.formToSave.historico.copy()));
  const bloqueado = useCCClienteSelector(states => (states.naoPrestados.formToSave.bloqueado.copy()));
  const data = useCCClienteSelector(states => (states.naoPrestados.formToSave.data.copy()));
  const idProcesso = useCCClienteSelector(states => (states.naoPrestados.formToSave.idProcesso.copy()));
  const idTipoContaCorrenteCliente = useCCClienteSelector(states => (states.naoPrestados.formToSave.idTipoContaCorrenteCliente.copy()));
  const valor = useCCClienteSelector(states => (states.naoPrestados.formToSave.valor.copy()));
  const idContaCorrenteClienteTable = useCCClienteSelector(states => (states.naoPrestados.idContaCorrenteClienteTable));


  const [tiposLancamentosItems, setTiposLancamentosItems] = React.useState([]);
  const [loading, setLoading] = React.useState(makeLoading());


  const focusFieldsHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(setRequestFieldsFocusNaoPrestadosFormToSaveAction(fieldName));
  }, [dispatch]);

  const onChangeFields = (event) => {
    const { name, value, checked } = event.target;
    dispatch(setSimpleValueNaoPrestaosFormToSaveHandlerAction(name, checked || value));
  };

  const onFocusEditor = useCallback(() => {
    dispatch(setRequestFieldsFocusNaoPrestadosFormToSaveAction('historico'));
  }, [dispatch]);

  // Passar para a Action
  const findAllTiposDoLancamentos = React.useCallback(async () => {
    setLoading(oldLoading => ({ ...oldLoading, tiposDoLancamentos: true }));
    try {
      const response = await LancamentosNaoPrestadosController.findAllTiposDoLancamentos();
      setTiposLancamentosItems(response.tiposDoLancamentos);
    } catch (err) {
      toastUnmappedException(err, 'Ocorreu um problema ao tentar consultar os nomes dos Tipos de Lançamentos');
      setTiposLancamentosItems([]);
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, tiposDoLancamentos: false }));
    }
  }, []);

  const onClickSalvarLancamentoAction = useCallback(() => {
    if (idContaCorrenteClienteTable) {
      dispatch(editarLancamentoNaoPrestadoAction());
      return;
    }
    dispatch(setSimpleValueNaoPrestaosFormToSaveHandlerAction('idCliente', idCliente));
    dispatch(salvarLancamentoNaoPrestadoAction());
  }, [dispatch, idCliente, idContaCorrenteClienteTable]);


  useEffect(() => {
    // Dar dispatch para buscar tipos do Lancamentos
    findAllTiposDoLancamentos().then();
  }, [findAllTiposDoLancamentos, dispatch]);


  return (
    <>
      <Loading
        show={loading.tiposDeLancamentos || loading.lancamentoCliente}
      />
      <CampoObrigatorio />
      <WrapperSubTitleForm title="Detalhe do Cliente" />
      <Grid24 xs={24} sm={24}>
        <Grid24 xs={24} sm={5}>
          <img src={iconCliente} alt="Foto Usuario" />
        </Grid24>
        <Grid24 xs={24} sm={19} className={utilStyle.mt10}>
          <InputField
            isForm
            label="Código"
            name="idCliente"
            disabled
            value={idCliente}
          />
          <Grid24 xs={24} sm={24}>
            <InputField
              isForm
              fullWidth
              label="Nome"
              name="nome"
              disabled
              value={nome}
            />
          </Grid24>
        </Grid24>
      </Grid24>
      <Grid24 xs={24} sm={24} className={utilStyle.mt20}>
        <FormWrapperWithTitle title="Informações">
          <Grid24 container isFormContainer>
            <Grid24 xs={24} sm={24} spacing="1">
              <Grid24 xs={24} sm={6}>
                <DateField
                  isForm
                  label={getRequiredLabel('Data do Lançamento', true)}
                  name="data"
                  value={data.value}
                  error={data.error}
                  errorMessage={data.errorMessage}
                  onFocus={focusFieldsHandler}
                  onChange={onChangeFields}
                />
              </Grid24>
              <Grid24 xs={24} sm={18}>
                <SelectorField
                  isForm
                  name="idTipoContaCorrenteCliente"
                  value={idTipoContaCorrenteCliente.value}
                  label={getRequiredLabel('Tipo do Lançamento', true)}
                  items={tiposLancamentosItems}
                  onFocus={focusFieldsHandler}
                  onChange={onChangeFields}
                  error={idTipoContaCorrenteCliente.error}
                  errorMessage={idTipoContaCorrenteCliente.errorMessage}
                />
              </Grid24>
            </Grid24>
            <Grid24 xs={24} sm={24} className={utilStyle.mt7}>
              <TextField
                label={getRequiredLabel('Histórico', true)}
                multiline
                placeholder="Insira o Histórico"
                readOnly={false}
                id="outlined-multiline-static"
                name="historico"
                rows={4}
                style={{ width: '100%', marginRight: '6px', marginTop: '8px' }}
                value={historico.value}
                onFocus={onFocusEditor}
                onChange={onChangeFields}
                error={historico.error}
                errorMessage={historico.errorMessage}
              />
            </Grid24>
            <Grid24 xs={24} sm={24} className={utilStyle.mt7}>
              <Grid24 xs={24} sm={8}>
                <MoneyField
                  isForm
                  name="valor"
                  label={getRequiredLabel('Valor', true)}
                  onFocus={focusFieldsHandler}
                  onChange={onChangeFields}
                  value={valor.value}
                  error={valor.error}
                  errorMessage={valor.errorMessage}
                />
              </Grid24>
              <Grid24 xs={24} sm={8}>
                <InputField
                  isForm
                  name="idProcesso"
                  label="Número do Processo"
                  onChange={onChangeFields}
                  onFocus={focusFieldsHandler}
                  value={idProcesso.value}
                  error={idProcesso.error}
                  errorMessage={idProcesso.errorMessage}
                />
              </Grid24>
            </Grid24>
            <Grid24 xs={24} sm={24} className={utilStyle.mt7ml10}>
              <CheckboxField
                label="Bloqueado"
                name="bloqueado"
                checked={bloqueado.value}
                onChange={onChangeFields}
                onFocus={focusFieldsHandler}
              />
            </Grid24>
          </Grid24>
        </FormWrapperWithTitle>
      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
        <Button
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          onClick={onClickSalvarLancamentoAction}
        >
          Confirmar
        </Button>
      </div>
    </>
  );
}

export default memo(DialogLancamentosNaoPrestados);
