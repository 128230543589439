import styled from 'styled-components';
import { widthScreen } from '../../helpers/styles/mediaScreenConstants';
import SubTitleForm from '../UI/SubTitleForm/SubTitleForm';

export const ExportButtonGroupConsultaDinamica = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: 20px;
  margin-bottom: 22px;

  button {
    width: 100%;
  }
  >button + button {
    margin-top: 8px;
  }

  @media screen and (min-width: ${widthScreen.small}) {
    >button + button {
      margin-left: 20px;
      margin-top: 0;
    }

    button {
      width: 120px;
    }
  }
`;

export const TableTitle = styled(SubTitleForm)`
  margin-bottom: 12px;
  color: black;
  font-size: 14px;
`;
