import React, { useEffect, useRef } from 'react';
import useStyles from './acompanhamentoWrapper.styles';
import useUtilStyles from '../../../../../helpers/styles/utils.styles';
import { getAcompanhamentoClienteAction } from '../../../../../store/Cliente/V2/Crud/crudCliente.saga';
import { useCrudClienteDispatch, useCrudClienteSelector } from '../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import LoadingAcompanhamento from '../../../../UI/Loading/Acompanhamento/LoadingAcompanhamento';

let scrollPositionTemp;

function AcompanhamentoWrapper({
  drawVerticalLine,
  children,
  isCliente,
  isQueryMode,
  ...props
}) {
  const styles = useStyles();
  const utilStyles = useUtilStyles();
  const dispatch = useCrudClienteDispatch();

  const scrolledDiv = useRef();

  const scrollInBottom = useCrudClienteSelector(states => states.settingsAcompanhamento.scrollInBottom);
  const direction = useCrudClienteSelector(states => states.settingsAcompanhamento.pageable.direction);
  const isLastPage = useCrudClienteSelector(states => states.settingsAcompanhamento.pageable.lastPage);
  const loadingAcompanhamento = useCrudClienteSelector(states => states.settingsAcompanhamento.loadingAcompanhamento);

  useEffect(() => {
    if (scrollInBottom) {
      scrolledDiv.current.scrollTo(0, scrolledDiv.current.scrollHeight);
    }
  }, [scrollInBottom]);

  function scrolled(obj) {
    const { scrollTop, clientHeight, scrollHeight } = obj.currentTarget;
    if ((scrollTop + clientHeight) >= scrollHeight && !isLastPage && scrollTop !== 0 && direction === 'BOTTOM') {
      dispatch(getAcompanhamentoClienteAction(isCliente, false));
    } else if (scrollTop === 0 && !isLastPage && direction === 'UP') {
      scrollPositionTemp = scrollHeight;
      dispatch(getAcompanhamentoClienteAction(isCliente, false, 'data,DESC'));
    }
  }

  useEffect(() => {
    if (!loadingAcompanhamento && !isLastPage && direction === 'UP') {
      const { scrollHeight } = scrolledDiv.current;
      scrolledDiv.current.scrollTo(0, scrollHeight - scrollPositionTemp);
    }
  }, [loadingAcompanhamento, isLastPage, direction]);

  return (
    <div className={styles.loadingWrapper}>
      <div {...props}>
        {!drawVerticalLine && (<div className={styles.verticalLine} />)}
        <div
          id="sectionAcompanhamento"
          ref={scrolledDiv}
          className={styles.wrapper}
          onScroll={scrolled}
        >
          <div className={utilStyles.ml10}>
            {[children]}
          </div>
        </div>
      </div>
      <LoadingAcompanhamento show={loadingAcompanhamento} />
    </div>
  );
}

export default React.memo(AcompanhamentoWrapper);
