import { TableRow } from '@material-ui/core';
import React from 'react';
import CheckboxField from '../UI/Field/Checkbox/CheckboxField';
import { TableCellCenter, TableCellLeft, TableCellRight } from '../UI/Tabela/tableDefaultStyles';

function TableBodyRepasseComponent({ content, listIds, changeSelectId }) {
  return (
    <TableRow>
      <TableCellCenter>
        <CheckboxField
          name={String(content.idPrestacaoContaCliente)}
          checked={listIds[content.idPrestacaoContaCliente] || false}
          onChange={changeSelectId}
        />
      </TableCellCenter>
      <TableCellCenter>
        {content.dataPrestacao}
      </TableCellCenter>
      <TableCellCenter>
        {content.idCliente}
      </TableCellCenter>
      <TableCellLeft>
        {content.nomeCliente}
      </TableCellLeft>
      <TableCellCenter>
        {content.tipoLiquidacaoPrestacao}
      </TableCellCenter>
      <TableCellLeft>
        {content.idBanco}
        {' '}
        -
        {' '}
        {content.nomeBanco}
      </TableCellLeft>
      <TableCellRight>
        {content.valorRepasse}
      </TableCellRight>
    </TableRow>
  );
}

export const TableBodyRepasse = TableBodyRepasseComponent;
