import { v4 as uuid } from 'uuid';
import { format } from 'date-fns-tz';
import {
  config,
  isValidationException,
  makeErrorField,
  updateFieldsWithErrors,
} from '../helpers';
import { buscarEventosCalendarioService, buscarInformacoesMuralService, salvarEventosCalendarioService } from '../services';


export class DashboardController {
  static status = {
    success: 'success',
    failure: 'failure',
    fatal: 'fatal',
  }

  static makeDashboardCalendarEvents(events = []) {
    return events.map(event => ({
      key: uuid().slice(0, 5),
      descricao: event?.descricao || '',
      icone: event?.icone || '',
    }));
  }

  static makeCalendarEvent(event) {
    return {
      key: { value: uuid().slice(0, 5) },
      descricao: { value: event?.descricao || '', ...makeErrorField() },
      idCalendario: { value: event?.idCalendario || null },
      idTipoCalendario: { value: event?.idTipoCalendario || '', ...makeErrorField() },
      salvar: { value: event?.salvar },
    };
  }

  static makeCalendarEvents(events = []) {
    return events.map(DashboardController.makeCalendarEvent);
  }


  static async findAllDashboardCalendarEventByDate(date) {
    try {
      const res = await buscarEventosCalendarioService(date);
      return DashboardController.makeDashboardCalendarEvents(res.data);
    } catch {
      return DashboardController.makeDashboardCalendarEvents();
    }
  }

  static async findAllCalendarEventByDate(date) {
    try {
      const res = await buscarEventosCalendarioService(date);
      return DashboardController.makeCalendarEvents(res.data);
    } catch {
      return DashboardController.makeCalendarEvents();
    }
  }

  static makePayloadsToSaveEventCalendar(date, events) {
    return {
      data: format(new Date(date), 'yyyy-MM-dd', config),
      eventos: events.map(event => ({
        descricao: event?.descricao.value,
        idCalendario: event?.idCalendario.value,
        idTipoCalendario: event?.idTipoCalendario.value,
        salvar: event?.salvar.value,
      })),
    };
  }

  static async saveEventCalendar(date, events) {
    const payload = DashboardController.makePayloadsToSaveEventCalendar(date, events);
    try {
      await salvarEventosCalendarioService(payload);
      return { status: DashboardController.status.success, eventos: events, message: null };
    } catch (error) {
      if (isValidationException(error)) {
        const [updatedFields] = updateFieldsWithErrors(error, { eventos: events });
        return {
          status: DashboardController.status.failure,
          eventos: [...updatedFields.eventos],
          message: error?.response?.data?.error,
        };
      }

      return { status: DashboardController.status.fatal, eventos: events, message: null };
    }
  }

  static async buscarInformacoesMural() {
    try {
      return await buscarInformacoesMuralService();
    } catch {
      return null;
    }
  }
}
