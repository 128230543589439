import React, { useCallback, useEffect, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { BiBarcodeReader } from 'react-icons/bi';
import { FaExclamationTriangle } from 'react-icons/fa';
import {
  useCCClienteDispatch,
  useCCClienteSelector,
} from '../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import useStyles, { ButtonNovoBoleto } from './BoletosStyles';
import { resetFormFieldsNovoBoletoAction, setSimpleValueBoletosRelacaoPrestacaoAction } from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { pesquisarDadosBoletosRelacaoPrestacaoAction, cancelarBoletoRelacaoPrestacaoAction } from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import { getNewPage } from '../../../../../../helpers';
import { TableDefault } from '../../../../../../components';
import { TableHeaderBoletosRelacaoPrestacao } from '../../../../../../components/TabelaHeader/V2/TableHeaderBoletosRelacaoPrestacao';
import { TableBodyBoletosRelacaoPrestacao } from '../../../../../../components/TabelaBody/V2/TableBodyBoletosRelacaoPrestacao';
import Grid24 from '../../../../../../components/UI/Grid24';
import { NovoBoleto } from './NovoBoleto';
import OptionDialogNew from '../../../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';


function Boletos({ permissoes }) {
  const styles = useStyles();
  const dispatch = useCCClienteDispatch();

  const dadosBoletos = useCCClienteSelector(states => states.boletosRelacaoPrestacao.dadosBoletos);
  const page = useCCClienteSelector(states => (states.boletosRelacaoPrestacao.page));
  const rowsPerPage = useCCClienteSelector(states => (states.boletosRelacaoPrestacao.rowsPerPage));
  const totalElements = useCCClienteSelector(states => (states.boletosRelacaoPrestacao.totalElements));
  const idPrestacaoContaCliente = useCCClienteSelector(states => (states.common.idPrestacaoContaClienteSelecionado));
  const prestacaoComNotaFiscal = useCCClienteSelector(states => (states.relacaoPrestacao.informacoesAdicionais.prestacaoComNotaFiscal));
  const autorizarEmissaoBoleto = useCCClienteSelector(states => (states.relacaoPrestacao.informacoesAdicionais.autorizarEmissaoBoleto));

  const openCancelarBoletoDialog = useCCClienteSelector(states => (states.boletosRelacaoPrestacao.openCancelarBoletoDialog));
  const openButtonNovoBoleto = useCCClienteSelector(states => (states.boletosRelacaoPrestacao.openButtonNovoBoleto));
  const [boletoSelecionado, setBoletoSelecionado] = useState(null);
  const dataVencimentoBoleto = useCCClienteSelector(states => (states.relacaoPrestacao.informacoesAdicionais.dataVencimentoBoleto));

  const changeRowsPerPageHandler = useCallback((e) => {
    const size = e.target.value;
    const newPage = getNewPage(rowsPerPage, page, size);

    dispatch(setSimpleValueBoletosRelacaoPrestacaoAction('page', Number(newPage)));
    dispatch(setSimpleValueBoletosRelacaoPrestacaoAction('rowsPerPage', Number(size)));
  }, [dispatch, rowsPerPage, page]);

  const changePageHandler = useCallback((event, newPage) => {
    dispatch(setSimpleValueBoletosRelacaoPrestacaoAction('page', Number(newPage)));
  }, [dispatch]);

  const confirmCancelHandler = useCallback(() => {
    dispatch(cancelarBoletoRelacaoPrestacaoAction(boletoSelecionado));
    dispatch(setSimpleValueBoletosRelacaoPrestacaoAction('openCancelarBoletoDialog', false));
  }, [dispatch, boletoSelecionado]);

  useEffect(() => {
    if (idPrestacaoContaCliente) {
      dispatch(pesquisarDadosBoletosRelacaoPrestacaoAction());
    }
  }, [dispatch, rowsPerPage, page, idPrestacaoContaCliente]);

  const onClickButtonNovoBoleto = useCallback(() => {
    dispatch(setSimpleValueBoletosRelacaoPrestacaoAction('openButtonNovoBoleto', !openButtonNovoBoleto));
    dispatch(resetFormFieldsNovoBoletoAction(dataVencimentoBoleto));
  }, [dispatch, openButtonNovoBoleto, dataVencimentoBoleto]);

  useEffect(() => {
    dispatch(setSimpleValueBoletosRelacaoPrestacaoAction('page', 0));
  }, [dispatch, idPrestacaoContaCliente]);

  return (
    <div className={styles.boletosTabela}>
      <OptionDialogNew
        open={openCancelarBoletoDialog}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickConfirm={confirmCancelHandler}
        onClickCancel={() => dispatch(setSimpleValueBoletosRelacaoPrestacaoAction('openCancelarBoletoDialog', false))}
        text="Deseja realmente cancelar este boleto?"
      />
      {(dadosBoletos.length > 0 || prestacaoComNotaFiscal) && (
        <Grid24>
          <Tooltip title="Novo Boleto" placement="bottom">
            <ButtonNovoBoleto
              size="small"
              className={styles.gerarNovoBoleto}
              disabled={!(permissoes.prestarContas && autorizarEmissaoBoleto)}
              openButtonNovoBoleto={openButtonNovoBoleto}
              onClick={onClickButtonNovoBoleto}
            >
              <BiBarcodeReader size={24} />
            </ButtonNovoBoleto>
          </Tooltip>
        </Grid24>
      )
      }
      {openButtonNovoBoleto === true && (
        <NovoBoleto />
      )}
      {(dadosBoletos.length > 0) && (
        <div>
          <TableDefault
            columnsHead={(
              <TableHeaderBoletosRelacaoPrestacao />
            )}
            page={page}
            rowsPerPage={rowsPerPage}
            totalElements={totalElements}
            totalElementsOnPage={dadosBoletos?.length}
            totalColumns={8}
            onPageChange={changePageHandler}
            onRowsPerPageChange={changeRowsPerPageHandler}
            emptyRowsHeight={36}
            rowsPerPageOptions={[10, 15, 20]}
          >
            {
              dadosBoletos.map((dadoBoleto, index) => (
                <TableBodyBoletosRelacaoPrestacao
                  content={dadoBoleto}
                  permissoes={permissoes}
                  setBoletoSelecionado={setBoletoSelecionado}
                  key={index}
                />
              ))
            }
          </TableDefault>
        </div>
      )}
      {
        (dadosBoletos.length === 0 || !prestacaoComNotaFiscal)
        && (
          <div className={styles.warningBoletos}>
            <FaExclamationTriangle size={46} color="#FFA000" />
            <span className={styles.warningText}>Não foram encontrados Boletos para esta Prestação.</span>
          </div>
        )
      }
    </div>
  );
}


export default Boletos;
