import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import Circul from '../../../assets/images/icons/circulWhite.svg';
import iconCliente from '../../../assets/images/icons/iconCliente.svg';
import { ButtonUI, InputField, OptionDialog } from '../../../components';
import {
  formatCpfCnpj, getMoneyMask, getRequiredLabel, toAmericanDate, toastUnmappedException, toBrazilianDate,
} from '../../../helpers';
import SubTitleForm from '../../../components/UI/SubTitleForm/SubTitleForm';
import CardDetalhesCliente from './CardDetalhesCliente';
import Dica from '../../../components/UI/Dica/Dica';
import DateField from '../../../components/UI/Field/Date/DateField';
import WrapperSubTitleForm from '../../../components/UI/WrapperSubTitleForm';
import { ContaCorrenteClienteController, ContaCorrenteClienteFactory } from '../ContaCorrenteClienteController';
import LoadingUI from '../../../components/UI/Loading/LoadingUI';
import {
  CamposDetalheCliente,
  CardFooter,
  ContainerDetalhes,
  ContainerInfosCliente,
  ContainerPrestacaoContas,
  ContainerResumo,
} from '../contaCorrenteClienteStyles';


function makeDetailInfo(firstLabel, secondLabel, thirdLabel, values) {
  return {
    first: {
      label: firstLabel || null,
      value: values[0] || null,
    },
    second: {
      label: secondLabel || null,
      value: values[1] || null,
    },
    third: {
      label: thirdLabel || null,
      value: thirdLabel ? values[2] : null,
    },
  };
}

function makeResumo(value) {
  return {
    despesaBloquedaNaoReembolsavel: getMoneyMask(value?.despesaBloquedaNaoReembolsavel) || 'R$ 0,00',
    despesaBloquedaReembolsavel: getMoneyMask(value?.despesaBloquedaReembolsavel) || 'R$ 0,00',
    despesaLiberadaNaoReembolsavel: getMoneyMask(value?.despesaLiberadaNaoReembolsavel) || 'R$ 0,00',
    despesaLiberadaReembolsavel: getMoneyMask(value?.despesaLiberadaReembolsavel) || 'R$ 0,00',
    honorarioBloquedoAmigavel: getMoneyMask(value?.honorarioBloquedoAmigavel) || 'R$ 0,00',
    honorarioBloquedoJudicial: getMoneyMask(value?.honorarioBloquedoJudicial) || 'R$ 0,00',
    honorarioLiberadoAmigavel: getMoneyMask(value?.honorarioLiberadoAmigavel) || 'R$ 0,00',
    honorarioLiberadoJudicial: getMoneyMask(value?.honorarioLiberadoJudicial) || 'R$ 0,00',
    saldoAtual: getMoneyMask(value?.saldoAtual) || 'R$ 0,00',
    saldoAnterior: getMoneyMask(value?.saldoAnterior) || 'R$ 0,00',
    totalDemonstracao: getMoneyMask(value?.totalDemonstracao) || 'R$ 0,00',
    totalEstornos: getMoneyMask(value?.totalEstornos) || 'R$ 0,00',
    totalRepasse: getMoneyMask(value?.totalRepasse) || 'R$ 0,00',
    valorBloquedoAcordo: getMoneyMask(value?.valorBloquedoAcordo) || 'R$ 0,00',
    valorBloquedoCredito: getMoneyMask(value?.valorBloquedoCredito) || 'R$ 0,00',
    valorBloquedoPagamento: getMoneyMask(value?.valorBloquedoPagamento) || 'R$ 0,00',
    valorLiberadoAcordo: getMoneyMask(value?.valorLiberadoAcordo) || 'R$ 0,00',
    valorLiberadoCredito: getMoneyMask(value?.valorLiberadoCredito) || 'R$ 0,00',
    valorLiberadoPagamento: getMoneyMask(value?.valorLiberadoPagamento) || 'R$ 0,00',
  };
}

function makeDetalhes(value) {
  if (value === undefined) {
    return null;
  }
  return {
    nome: value?.nome,
    idCliente: value?.idCliente || '',
    cnpjCpf: formatCpfCnpj(value?.cnpjCpf),
    dataUltimaPrestacao: toBrazilianDate(value?.dataUltimaPrestacao),
    descricaoPeriodicidade: value?.descricaoPeriodicidade || '',
    descricaoRegra: value?.descricaoRegra,
    statusCliente: value?.statusCliente,
    suspenderPrestacaoConta: value?.suspenderPrestacaoConta,
  };
}

function ContaCorrenteDetalhesCliente({
  dadosResumoCliente,
  clienteSelecionado,
  permissoes,
  canPrestarTodos,
  atualizarPaginaAfterAction,
  idsContasCorrenteClientes,
}) {
  const [saldos, setSaldos] = React.useState(makeResumo(dadosResumoCliente?.financeiro));
  const [detalhes, setDetalhes] = React.useState(makeDetalhes(dadosResumoCliente?.detalhes));
  const [dataPrestacao, setDataPrestacao] = React.useState(toBrazilianDate(ContaCorrenteClienteFactory.getProximoDiaUtil(new Date())));
  const [errorDataPrestacao, setErrorDataPrestacao] = React.useState({ error: false, errorMessage: '' });
  const [loading, setLoading] = React.useState(false);
  const [openDialogConfirmLancamento, setOpenDialogConfirmLancamento] = React.useState(false);

  /**
   * Função que vai ser passada para dentro do @DateField.
   * Para que quando o valor da data tiver que ser validado no front e estiver sendo controlado por algum state externo
   * fora do @DateField.
   *
   * Usado aqui para controlar o state errorDataPrestacao da tela, que desabilita o botão de prestar caso o componente
   * identifique a data como inválida.
   *
   * @param errors O que a função de validação do @DateField retorna como parâmetro: {error: true || false, message: string}
   */
  const validarData = (errors) => {
    const { error, message } = errors;
    setErrorDataPrestacao({ error, errorMessage: message });
  };

  useEffect(() => {
    setSaldos(makeResumo(dadosResumoCliente?.financeiro));
    setDetalhes(makeDetalhes(dadosResumoCliente?.detalhes));
  }, [dadosResumoCliente]);

  const getDisablePrestarContas = useCallback((isPrestar = false, isPrestarTodos = false) => {
    if (!permissoes?.prestarContas) {
      return true;
    }

    if (isPrestar) {
      if (permissoes?.prestarContas && clienteSelecionado?.desbloqueado) {
        return false;
      }
      if (permissoes?.prestarContas && !clienteSelecionado?.desbloqueado) {
        return true;
      }
    }

    if (isPrestarTodos) {
      return !canPrestarTodos;
    }
    return true;
  }, [permissoes?.prestarContas, clienteSelecionado?.desbloqueado, canPrestarTodos]);


  const onClickPrestarContas = useCallback(async () => {
    setLoading(true);
    try {
      const response = await ContaCorrenteClienteController.prestarContas({
        idCliente: clienteSelecionado?.idCliente,
        dataPrestacao: toAmericanDate(dataPrestacao),
      });
      if (response.status === 204) {
        // Re-pesquisar os detalhes do cliente.
        toast.success('Prestação realizada com sucesso!');
        atualizarPaginaAfterAction(false);
      }
    } catch (e) {
      toastUnmappedException(e, e.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }, [clienteSelecionado?.idCliente, dataPrestacao, atualizarPaginaAfterAction]);

  const onChangeDataPrestacao = (event) => {
    const { value } = event.target;
    setDataPrestacao(value);
  };

  const onClickPrestarTodos = useCallback(async () => {
    setLoading(true);
    try {
      const filterDesbloqueados = idsContasCorrenteClientes.filter(status => status.statusPrestacaoContas === 'DESBLOQUEADO');
      const response = await ContaCorrenteClienteController.prestarTodos({
        dataPrestacao: toAmericanDate(dataPrestacao),
        idClientes: filterDesbloqueados.map(ids => ids.idCliente),
      });
      if (response.status === 204) {
        toast.success('Todas as Prestações foram realizadas com sucesso.');
      }
    } catch (e) {
      toastUnmappedException(e, e.response?.data?.message);
    } finally {
      setLoading(false);
      atualizarPaginaAfterAction(false);
      setOpenDialogConfirmLancamento(false);
    }
  }, [idsContasCorrenteClientes, dataPrestacao, atualizarPaginaAfterAction]);

  function handleOnClickCancel() {
    setOpenDialogConfirmLancamento(false);
  }

  function handleOpenConfirmLancamento() {
    setOpenDialogConfirmLancamento(true);
  }

  return (
    <>
      <OptionDialog
        open={openDialogConfirmLancamento}
        variant="warning"
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={handleOnClickCancel}
        onClickConfirm={onClickPrestarTodos}
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>Você está prestes a Prestar Contas de todos os clientes</p>
          <br />
          pesquisados e desbloqueados.
          <br />
          Tem certeza que
          <br />
          deseja realizar esta operação?

        </div>
      </OptionDialog>
      <LoadingUI show={loading} />
      <ContainerDetalhes>
        <ContainerInfosCliente>
          {dadosResumoCliente?.detalhes ? (
            <img src={iconCliente} alt="Foto Usuario" width="70px" height="70px" />
          ) : (
            <img src={Circul} alt="Icone em Branco" width="70px" height="70px" />
          )}
          <div>
            <SubTitleForm>
              Detalhes do Cliente
            </SubTitleForm>
            <CamposDetalheCliente>
              <div>
                <InputField
                  fullWidth
                  label="Nome"
                  disabled
                  isForm
                  value={detalhes?.nome || ''}
                />
              </div>
              <div>
                <InputField
                  label="Código"
                  disabled
                  isForm
                  fullWidth
                  value={detalhes?.idCliente || ''}
                />
                <InputField
                  label="CPF/CNPJ"
                  disabled
                  isForm
                  fullWidth
                  value={formatCpfCnpj(detalhes?.cnpjCpf) || ''}
                />
                <InputField
                  label="Última Prestação"
                  disabled
                  isForm
                  fullWidth
                  value={detalhes?.dataUltimaPrestacao || ''}
                />
              </div>
              <div>
                <div id="campoPeriodicidade">
                  <InputField
                    label="Periocidade"
                    disabled
                    isForm
                    fullWidth
                    value={detalhes?.descricaoPeriodicidade || ''}
                  />
                </div>
                <InputField
                  label="Regra"
                  disabled
                  isForm
                  fullWidth
                  value={detalhes?.descricaoRegra || ''}
                />
              </div>
              <div id="campoStatus">
                <InputField
                  label="Status"
                  disabled
                  isForm
                  fullWidth
                  value={detalhes?.statusCliente || ''}
                />
                <InputField
                  label="Prestação de Contas"
                  disabled
                  isForm
                  fullWidth
                  value={detalhes?.suspenderPrestacaoConta || ''}
                />
              </div>
            </CamposDetalheCliente>
          </div>
        </ContainerInfosCliente>
        <ContainerResumo>
          <div>
            <p>Resumo</p>
          </div>
          <div>
            <CardDetalhesCliente
              title="Saldo Anterior"
              infoValue={saldos.saldoAnterior}
              bgColor="#58a9f4"
            />
            <CardDetalhesCliente
              title="Total Estornos"
              infoValue={saldos.totalEstornos}
              bgColor="#3373ec"
            />
            <CardDetalhesCliente
              title="Saldo Atual"
              infoValue={saldos.saldoAtual}
              bgColor="#2b59b0"
            />
          </div>
          <div>
            <CardDetalhesCliente
              title="Despesas Bloqueadas"
              detailInfo={makeDetailInfo(
                'Não Reemb.',
                'Reemb.',
                null,
                [saldos.despesaBloquedaNaoReembolsavel, saldos.despesaBloquedaReembolsavel],
              )}
              bgColor="#ff7b51"
              lineColor="#ff521b"
            />
            <CardDetalhesCliente
              title="Honorários Bloqueados"
              detailInfo={makeDetailInfo(
                'Amigáveis',
                'Judiciais',
                null,
                [saldos.honorarioBloquedoAmigavel, saldos.honorarioBloquedoJudicial],
              )}
              bgColor="#f85861"
              lineColor="#e9303b"
            />
            <CardDetalhesCliente
              title="Valores Bloqueados"
              detailInfo={makeDetailInfo(
                'Créditos',
                'Pagto.',
                'Acordos',
                [saldos.valorBloquedoCredito, saldos.valorBloquedoPagamento, saldos.valorBloquedoAcordo],
              )}
              bgColor="#ff449b"
              lineColor="#e9207e"
            />
          </div>
          <div>
            <CardDetalhesCliente
              title="Despesas Liberadas"
              detailInfo={makeDetailInfo(
                'Não Reemb.',
                'Reemb.',
                null,
                [saldos.despesaLiberadaNaoReembolsavel, saldos.despesaLiberadaReembolsavel],
              )}
              bgColor="#ef997e"
              lineColor="#d67556"
            />
            <CardDetalhesCliente
              title="Honorários Liberados"
              detailInfo={makeDetailInfo(
                'Amigáveis',
                'Judiciais',
                null,
                [saldos.honorarioLiberadoAmigavel, saldos.honorarioLiberadoJudicial],
              )}
              bgColor="#f49096"
              lineColor="#e2646d"
            />
            <CardDetalhesCliente
              title="Valores Liberados"
              detailInfo={makeDetailInfo(
                'Créditos',
                'Pagto.',
                'Acordos',
                [saldos.valorLiberadoCredito, saldos.valorLiberadoPagamento, saldos.valorLiberadoAcordo],
              )}
              bgColor="#e98db7"
              lineColor="#ec62a2"
            />
          </div>
          <div>
            <CardFooter color="#c54fd9">
              <p>Total do Repasse</p>
              <p>{saldos.totalRepasse}</p>
            </CardFooter>
            <CardFooter color="#3cb6be">
              <p>Total da Demonstração</p>
              <p>{saldos.totalDemonstracao}</p>
            </CardFooter>
          </div>
        </ContainerResumo>
        <ContainerPrestacaoContas>
          <div>
            <span>
              <WrapperSubTitleForm title="Prestação de Contas" />
              <Dica mensagemDica="A Prestação será realizada somente para o cliente selecionado" />
            </span>
            <div>
              <ButtonUI
                disabled={dataPrestacao === '' || errorDataPrestacao.error || getDisablePrestarContas(false, true)}
                color="white"
                onClick={handleOpenConfirmLancamento}
              >
                Prestar Todos
              </ButtonUI>
            </div>
          </div>
          <div>
            <DateField
              isForm
              label={getRequiredLabel('Data da Prestação', true)}
              name="dataPrestacao"
              disabled={(!permissoes.prestarContas) || !(clienteSelecionado?.desbloqueado || canPrestarTodos)}
              value={detalhes ? dataPrestacao : ''}
              minDate={new Date()}
              onChange={onChangeDataPrestacao}
              setExternalError={validarData}
              error={errorDataPrestacao.error}
              errorMessage={errorDataPrestacao.errorMessage}
            />
            <div>
              <ButtonUI
                onClick={onClickPrestarContas}
                disabled={dataPrestacao === '' || errorDataPrestacao.error || getDisablePrestarContas(true, false)}
                color="success"
              >
                Prestar Contas
              </ButtonUI>
            </div>
          </div>
        </ContainerPrestacaoContas>
      </ContainerDetalhes>
    </>
  );
}

export const ContaCorrenteDetalhesClienteComponent = ContaCorrenteDetalhesCliente;
