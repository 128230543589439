import { takeLatest, put, select } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { findUsuarios, deleteUsuario } from '../../../services/core/usuario/usuario.services';

import {
  setSettingsTableAction, setLoadingAction, setAlertDialogValuesAction, clearSearchModelAction,
} from './pesquisarUsuario.store';

import { isNotFoundException, isBadRequestException, getExceptionMessage } from '../../../helpers/utils/utils';
import { RESULT_NOT_FOUND } from '../../../helpers/constants/global.constants';

const FIND_USUARIOS = 'FIND_USUARIOS_PESQUISA_USUARIO';
const DELETE_USUARIO = 'DELETE_USUARIO';

export const findUsuariosAction = (page, rowsPerPage, origin) => ({
  type: FIND_USUARIOS, page, rowsPerPage, origin,
});

export const deleteUsuarioAction = idUsuario => ({
  type: DELETE_USUARIO, idUsuario,
});

function setMessageWhenResultNotFound(length) {
  if (length === 0) {
    toast.warn(RESULT_NOT_FOUND);
  }
}

function* updateTableUsuarios(nome, tipoUsuario, filial, statusUsuario, page, rowsPerPage) {
  let usuarios = yield findUsuarios(nome, tipoUsuario, filial, statusUsuario, page, rowsPerPage);
  if (usuarios.data.content.length === 0 && usuarios.data.totalElements !== 0) {
    const lastPage = yield usuarios.data.totalPages - 1;
    usuarios = yield findUsuarios(nome, tipoUsuario, filial, statusUsuario, page, lastPage, rowsPerPage);
  }
  yield put(setSettingsTableAction(usuarios.data.content, usuarios.data.number, usuarios.data.size,
    usuarios.data.totalElements));
  return usuarios;
}

function* findUsuariosHandler(actions) {
  yield put(setLoadingAction(true));
  const cacheRequestFields = yield select(states => states.pesquisaUsuario.cacheRequestFields);
  const isSearchMode = yield select(states => states.pesquisaUsuario.searchMode);
  const { page, rowsPerPage } = actions;
  const {
    nome, tipoUsuario, status, filial,
  } = cacheRequestFields;

  try {
    const usuarios = yield updateTableUsuarios(nome, tipoUsuario, filial, status, page, rowsPerPage);
    if (isSearchMode) {
      yield setMessageWhenResultNotFound(usuarios.data.content.length);
    }
  } finally {
    yield put(clearSearchModelAction());
    yield put(setLoadingAction(false));
  }
}

function* deleteUsuarioHandler(actions) {
  yield put(setLoadingAction(true));
  const nome = yield select(states => states.pesquisaUsuario.requestFields.nome.getValueNotEmpty());
  const status = yield select(states => states.pesquisaUsuario.requestFields.status.getValueNotEmpty());
  const filial = yield select(states => states.pesquisaUsuario.requestFields.filial.getValueNotEmpty());
  const tipoUsuario = yield select(states => states.pesquisaUsuario.requestFields.tipoUsuario.getValueNotEmpty());
  const page = yield select(states => states.pesquisaUsuario.settingsTable.page);
  const rowsPerPage = yield select(states => states.pesquisaUsuario.settingsTable.rowsPerPage);

  try {
    yield deleteUsuario(actions.idUsuario);
    yield updateTableUsuarios(nome, tipoUsuario, filial, status, page, rowsPerPage);
  } catch (exception) {
    if (isNotFoundException(exception) || isBadRequestException(exception)) {
      yield put(setAlertDialogValuesAction(true, 'error', getExceptionMessage(exception)));
    }

    if (isNotFoundException(exception)) {
      yield updateTableUsuarios(nome, tipoUsuario, filial, status, page, rowsPerPage);
    }
  } finally {
    yield put(setLoadingAction(false));
  }
}

export default function* watchPesquisaUsuario() {
  yield takeLatest(FIND_USUARIOS, findUsuariosHandler);
  yield takeLatest(DELETE_USUARIO, deleteUsuarioHandler);
}
