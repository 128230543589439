import React from 'react';
import Typography from '@material-ui/core/Typography';
import useStyle from './acompanhamentoSubItem.styles';
import useUtilStyle from '../../../../../helpers/styles/utils.styles';


function sanitizeText(text) {
  if (text !== undefined) {
    return `- ${text}`;
  } return '';
}

function AcompanhamentoSubItem({ anotacoes }) {
  const style = useStyle();
  const utilStyle = useUtilStyle();

  const {
    hora, nome, nomeLogin, email, conteudo,
  } = anotacoes;

  return (
    <div className={utilStyle.posRelative}>
      <div className={style.circle} />
      <Typography component="p" className={style.subTitle}>
        {`${hora} - ${nomeLogin} - ${nome} ${sanitizeText(email)}`}
      </Typography>
      <div className={style.contentWrapper}>
        <Typography component="pre" className={style.content}>
          {conteudo}
        </Typography>
      </div>
    </div>
  );
}
export default React.memo(AcompanhamentoSubItem);
