import React, { useCallback, useEffect, useState } from 'react';
import { TableDefault } from '../../../../components';
import { TableHeaderAnalitica } from './TableHeaderAnalítica';
import { TableBodyAnalitica } from './TableBodyAnalitica';
import { loadingInspecaoProcessoController } from '../../loadingInspecaoProcessoController';
import { buildSettingsTable } from '../../../../helpers';
import { findFichaFinanceiraAnalitica } from '../../../../services/core/processos/processosFichaFinanceira';


function FichaAnalíticaComponent({
  idProcesso, setLoadings, columnOrdenacao, setColumnOrdenacao,
}) {
  const [arrowOrdenacao, setArrowOrdenacao] = useState(true);

  const [settingsTable, setSettingsTable] = useState(
    buildSettingsTable([], 0, 10, 0),
  );

  const configureStatesPesquisa = useCallback((data) => {
    setSettingsTable({
      content: data.content,
      page: data.number,
      rowsPerPage: data.size,
      size: data.totalElements,
    });
  }, [setSettingsTable]);

  const pesquisarFichaFinanceira = useCallback(async (page, rowsPerPage, ordenacao, filtro) => {
    setLoadings(loadingInspecaoProcessoController.changeLoadingFichaFinaceira(true));
    findFichaFinanceiraAnalitica(idProcesso, page, rowsPerPage, [`${ordenacao.element},${ordenacao.order}`], filtro)
      .then((response) => {
        const { data } = response;
        configureStatesPesquisa(data);
      })
      .catch(() => {
        setSettingsTable(buildSettingsTable([[], 0, 10, 0]));
      })
      .finally(() => {
        setLoadings(
          loadingInspecaoProcessoController.changeLoadingFichaFinaceira(false),
        );
      });
  },
  [configureStatesPesquisa, idProcesso, setLoadings]);

  const onClickOrdernacaoColumn = useCallback(async (columnName) => {
    pesquisarFichaFinanceira(settingsTable.page, settingsTable.rowsPerPage, { element: columnName, order: arrowOrdenacao ? 'ASC' : 'DESC' });
  }, [pesquisarFichaFinanceira, arrowOrdenacao, settingsTable.page, settingsTable.rowsPerPage]);

  const onChangePageHandler = useCallback((event, newPage) => {
    pesquisarFichaFinanceira(newPage, settingsTable.rowsPerPage, columnOrdenacao);
    setSettingsTable(old => ({ ...old, page: newPage }));
  }, [pesquisarFichaFinanceira, settingsTable.rowsPerPage, columnOrdenacao]);

  const onChangeRowsPerPageHandler = useCallback(async (event) => {
    pesquisarFichaFinanceira(settingsTable.page, event.target.value, columnOrdenacao);
    setSettingsTable(old => ({ ...old, rowsPerPage: event.target.value }));
  }, [pesquisarFichaFinanceira, columnOrdenacao, settingsTable.page]);

  useEffect(() => {
    findFichaFinanceiraAnalitica(idProcesso, 0, 10, ['dataLancamento,DESC']).then((response) => {
      const { data } = response;
      configureStatesPesquisa(data);
    }).catch(() => {
      setSettingsTable(buildSettingsTable([[], 0, 10, 0]));
    });
  }, [idProcesso, configureStatesPesquisa]);

  return (
    <div>
      <TableDefault
        scrollableX
        columnsHead={(
          <TableHeaderAnalitica
            setArrowOrdenacao={setArrowOrdenacao}
            arrowOrdenacao={arrowOrdenacao}
            columnOrdenacao={columnOrdenacao}
            setColumnOrdenacao={setColumnOrdenacao}
            onClickOrdernacaoColumn={onClickOrdernacaoColumn}
          />
        )}
        page={settingsTable.page}
        rowsPerPage={settingsTable.rowsPerPage}
        totalElements={settingsTable.size}
        totalElementsOnPage={settingsTable.content?.length}
        totalColumns={15}
        onPageChange={onChangePageHandler}
        onRowsPerPageChange={onChangeRowsPerPageHandler}
        emptyRowsHeight={30}
        rowsPerPageOptions={[10, 15, 20]}
      >
        {settingsTable?.content?.map((ficha, index) => (
          <TableBodyAnalitica
            ficha={ficha}
            key={index}
          />
        ))}
      </TableDefault>
    </div>
  );
}

export default FichaAnalíticaComponent;
