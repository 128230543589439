import React, { useEffect, useState } from 'react';
import ButtonUI from '../UI/Button/ButtonUI';
import Grid24 from '../UI/Grid24';
import {
  FieldsContainer, FooterCorrigir, RootCorrigir, ProgressBar,
} from './CorrecaoBordero.styles';
import { CorrigirEnderecoDevedorBordero } from '../CorrigirEnderecoDevedorBordero/CorrigirEnderecoDevedorBordero';
import { CorrigirTelefoneDevedorBordero } from '../CorrigirTelefoneDevedorBordero/CorrigirTelefoneDevedorBordero';
import { CorrigirEmailDevedorBordero } from '../CorrigirEmailDevedorBordero/CorrigirEmailDevedorBordero';
import { CorrigirTituloDevedorBordero } from '../CorrigirTituloDevedorBordero/CorrigirTitulosDevedorBordero';
import { CorrigirDadosDevedorBordero } from '../CorrigirDadosDevedorBordero/CorrigirDadosDevedorBordero';
import { useUtilStyles } from '../../helpers';
import { dadosDevedorController } from '../../controller/correcaoBorderoController';
import { corrigirBorderoComErro } from '../../services/bordero/bordero/bordero.services';

function CorrecaoBorderoComponent({
  retornoBordero, retornoVersionBordero, borderosComErro, idCliente, nomeCliente, onClickCorrections, setDownloadBordero, setCorrecaoFinalizada, setHasFieldsCorrections,
}) {
  const utilStyles = useUtilStyles();
  const [widthProgressBar, setWidthProgressBar] = useState(0);
  const [indice, setIndice] = useState(0);
  const [botaoAnterior, setBotaoAnterior] = useState(false);
  const [borderoCorrigido, setBorderoCorrigido] = useState([]);
  const [bordero, setBordero] = useState([]);
  const [devedoresCorrigidos] = useState([]);

  function makePayloadFinal(corrigidos, corretos, objetoRetorno) {
    const payloadDevedoresCorrigidos = dadosDevedorController.makePayloadDevedoreCorrigidos(corrigidos);
    const payloadDevedoresCorretos = dadosDevedorController.makePayloadDevedoreCorretos(corretos);
    const payload = dadosDevedorController.makePayloadFinal(payloadDevedoresCorrigidos, payloadDevedoresCorretos, objetoRetorno);
    return payload;
  }

  function proximoDevedor(devedor) {
    function buscaProximoDevedor() {
      const copy = Array.from(borderoCorrigido);
      if (indice !== borderoCorrigido.length) {
        copy.splice(indice, 1, bordero);
        setBorderoCorrigido(copy);
      } else {
        copy.push(bordero);
        setBorderoCorrigido(copy);
        devedoresCorrigidos.push(devedor);
      }
      if (retornoBordero.devedoresComErro.length === (indice + 1)) {
        const devedoresCorretos = Array.from(retornoBordero.devedoresCorretos);
        const payloadFinal = makePayloadFinal(devedoresCorrigidos, devedoresCorretos, retornoBordero);
        setDownloadBordero(payloadFinal);
      }
      setIndice(oldIndice => oldIndice + 1);
      if (indice === bordero.length - 1) {
        setHasFieldsCorrections(false);
        setCorrecaoFinalizada(true);
      }
    }

    corrigirBorderoComErro(devedor, retornoVersionBordero).then(() => {
      buscaProximoDevedor();
    }).catch((e) => {
      if (e.response && e.response.data) {
        e.response.data.validations.forEach((element) => {
          // eslint-disable-next-line no-eval
          const attr = eval(`bordero.${element.field}`);
          attr.error = true;
          attr.errorMessage = element.message;
        });
        setBordero(oldBordero => ({ ...oldBordero, ...bordero }));
      }
    });
    setBotaoAnterior(false);
  }

  function anteriorDevedor() {
    setIndice(oldIndice => oldIndice - 1);
    setBotaoAnterior(true);
  }

  useEffect(() => {
    // Caso aperte no botão proximo e exista o borderô corrigido, deve ser utilizado o corrigido
    if (botaoAnterior || borderoCorrigido[indice] != null) {
      setBordero(borderoCorrigido[indice]);
    } else if (borderosComErro[indice]) {
      setBordero(dadosDevedorController.makeFormFields(borderosComErro[indice], idCliente, nomeCliente));
    } else {
      setCorrecaoFinalizada(true);
    }
  }, [borderosComErro, indice, idCliente, nomeCliente, borderoCorrigido, botaoAnterior, setCorrecaoFinalizada]);

  useEffect(() => {
    setWidthProgressBar(indice * 100 / borderosComErro.length);
  }, [indice, borderosComErro.length]);

  return (
    <>
      <RootCorrigir>
        <ProgressBar>
          <div id="progressBar" style={{ width: `${widthProgressBar}%` }} />
        </ProgressBar>
        <FieldsContainer>
          <Grid24 container isFormContainer>
            <CorrigirDadosDevedorBordero
              borderoComErro={bordero}
              idCliente={idCliente}
              nomeCliente={nomeCliente}
              setBordero={setBordero}
            />
          </Grid24>
          <Grid24 container isFormContainer className={utilStyles.mt16}>
            <CorrigirEnderecoDevedorBordero
              devedor={bordero}
              setBordero={setBordero}
            />
          </Grid24>
          <Grid24 container isFormContainer className={utilStyles.mt16}>
            <CorrigirTelefoneDevedorBordero
              devedor={bordero}
              setBordero={setBordero}
            />
          </Grid24>
          <Grid24 container isFormContainer className={utilStyles.mt16}>
            <CorrigirEmailDevedorBordero
              devedor={bordero}
              setBordero={setBordero}
            />
          </Grid24>
          <Grid24 container isFormContainer className={utilStyles.mt16}>
            <CorrigirTituloDevedorBordero
              devedor={bordero}
              setBordero={setBordero}
            />
          </Grid24>
        </FieldsContainer>
        <FooterCorrigir>
          <div>
            <ButtonUI
              color="error"
              onClick={onClickCorrections}
            >
              Cancelar
            </ButtonUI>
          </div>
          <div>
            <div>
              <ButtonUI
                disabled={indice < 1}
                onClick={() => anteriorDevedor()}
              >
                Anterior
              </ButtonUI>
            </div>
            <div>
              <ButtonUI
                type="submit"
                color="success"
                onClick={() => proximoDevedor(dadosDevedorController.makePayload(bordero, idCliente, nomeCliente))}
              >
                Próximo
              </ButtonUI>
            </div>
          </div>
        </FooterCorrigir>
      </RootCorrigir>
    </>
  );
}

export const CorrecaoBordero = CorrecaoBorderoComponent;
