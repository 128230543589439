import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { navigationButtonStyles, navigationIconStyles, navigationButtonTransition } from '../../helpers/styles/sharedNavigationStyles';

export const Container = styled(NavLink)`
  ${navigationButtonStyles}
  ${navigationButtonTransition}

  text-decoration: none;
  display: flex;
  align-items: center;

  >i {
    ${navigationIconStyles}
  }

  >span {
    position: relative;
    left: 40px;
    vertical-align: middle;

    white-space:nowrap;

    transform-origin: top left;
    transform: scaleX(${props => (props?.minimized ? 0 : '100%')});

    ${navigationButtonTransition}
  }
`;


export const ContainerExternalLink = styled(Link)`
  ${navigationButtonStyles}
  ${navigationButtonTransition}

  text-decoration: none;
  display: flex;
  align-items: center;

  >i {
    ${navigationIconStyles}
  }

  >span {
    position: relative;
    left: 40px;
    vertical-align: middle;

    white-space:nowrap;

    transform-origin: top left;
    transform: scaleX(${props => (props?.minimized ? 0 : '100%')});

    ${navigationButtonTransition}
  }
`;
