export default {
  makeColumnOrder({ element, order, arrow }) {
    return {
      element: element || '',
      order: order || 'ASC',
      arrow: arrow || false,

      get() {
        return [`${this.element},${this.order}`];
      },

      getNextOrder() {
        return this.order === 'ASC' ? 'DESC' : 'ASC';
      },

      getNextArrow() {
        return this.order === 'ASC';
      },
    };
  },

  makePageable(tableItem) {
    return {
      page: tableItem.page,
      size: tableItem.size,
    };
  },

  makeAllIdsSelections(ids = []) {
    return ids.reduce((acc, id) => {
      acc.set(id, false);
      return acc;
    }, new Map());
  },

  makeTableItems({ httpResponse, makeContentFactory = item => ({ ...item }) }) {
    return {
      content: httpResponse?.content?.map(makeContentFactory) || [],
      size: httpResponse?.size || 5,
      page: httpResponse?.number || 0,
      totalElements: httpResponse?.totalElements || 0,
      lastPage: httpResponse?.last || true,
    };
  },

  makePageableFromTableItem(tableItem) {
    return {
      size: tableItem?.size || null,
      page: tableItem?.page || null,
      totalElements: tableItem?.totalElements || null,
      lastPage: tableItem?.lastPage || null,
    };
  },

  updateAllItensSelectionByNewSelectionValue({
    newSelectionValue, items, ids = new Map(), itemIdFieldName = '', currentId = null, notAddContentIds = false,
  }) {
    const contentWithSelectionUpdated = items.map((item) => {
      item.checked = newSelectionValue;
      return item;
    });

    ids.forEach((value, key) => {
      let selection = newSelectionValue;
      if (!!currentId && Number(key) === Number(currentId)) {
        selection = true;
      }
      ids.set(key, selection);
    });

    if (!notAddContentIds) {
      items.forEach((item) => {
        const keyId = item[itemIdFieldName];
        ids.set(keyId, newSelectionValue);
      });
    }

    return {
      items: contentWithSelectionUpdated,
      ids: new Map(ids),
    };
  },

  updateItensSelectionByIds({ ids = new Map(), items, itemIdFieldName = '' }) {
    const contentWithSelectionUpdated = items.map((item) => {
      const keyId = item[itemIdFieldName];
      item.checked = ids.get(keyId) || false;
      return item;
    });

    return {
      items: contentWithSelectionUpdated,
      ids: new Map(ids),
    };
  },

  updateUniqueItemSelectionByCurrentId({
    currentId, items, ids = new Map(), itemIdFieldName = '',
  }) {
    const contentWithUpdateSelection = items.map((item) => {
      if (item[itemIdFieldName] === currentId) {
        item.checked = !item.checked;
      }
      return item;
    });

    const currentDocumento = contentWithUpdateSelection.find(documento => documento[itemIdFieldName] === currentId);
    ids.set(currentId, currentDocumento.checked);

    return {
      ids: new Map(ids),
      items: contentWithUpdateSelection,
    };
  },

  updateTableAfterChangePageOrItemsPerPage({
    isSelectAll, newSelectionValue, ids, items, itemIdFieldName,
  }) {
    if (isSelectAll) {
      return this.updateAllItensSelectionByNewSelectionValue({
        newSelectionValue,
        ids,
        items,
        itemIdFieldName,
      });
    }

    return this.updateItensSelectionByIds({
      ids,
      items,
      itemIdFieldName,
    });
  },

  getIdsSelected(ids = new Map()) {
    return Array.from(ids.entries())
      .filter(value => !!value[1])
      .map(value => value[0]);
  },

  getIsSelectAll(ids = new Map()) {
    const idsList = Array.from(ids.values());
    if (idsList.length > 0) {
      return idsList.every(value => !!value);
    }
    return false;
  },

  paginar(allItems, rowsPerPageSize, pageNumber) {
    return allItems.slice(pageNumber * rowsPerPageSize, pageNumber * rowsPerPageSize + rowsPerPageSize);
  },

  ordenarTabela(arrowDirection, items, element) {
    let ordenados = [];
    if (arrowDirection) {
      ordenados = items.sort((a, b) => ((b[element] > a[element]) ? 1 : ((a[element] > b[element]) ? -1 : 0)));
    } else {
      ordenados = items.sort((a, b) => ((a[element] > b[element]) ? 1 : ((b[element] > a[element]) ? -1 : 0)));
    }
    return ordenados;
  },
};
