export default {
  TITLE: 'Processar Borderôs',
  ESTATISTICA_TITULO: 'Estatísticas',
  ESTATISTICA_AGUARDANDO_PROCESSAMENTO: 'Itens Aguardando Processamento:',
  ESTATISTICA_BORDEROS_EM_PROCESSAMENTO: 'Itens em Processamento:',
  ESTATISTICA_PROCESSADOS: 'Itens Processados:',
  ESTATISTICA_EXCLUIDOS: 'Itens Excluídos: ',
  ID_CLIENTE: 'Código do Cliente',
  PERIODO: 'Período de Cadastramento',
  STATUS: 'Status',
  RECUPERAR: 'Recuperar',
  EXCLUIR: 'Excluir',
  PROCESSAR: 'Processar',
  INSPECIONAR: 'Inspecionar',
  SELECIONAR_TODOS: 'Selecionar Todos',
  DIALOG_REMOVE_MESSAGE:
    'A exclusão é temporária e os itens excluídos poderão ser recuperados para o estado atual enquanto não for atingido o tempo de permanência. Deseja confirmar a exclusão?',
  DIALOG_RECOVERY_MESSAGE:
    'Os itens selecionados serão recuperados para o estado de origem. Deseja confirmar?',
};
