import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WrapperSubTitleForm from '../../../UI/WrapperSubTitleForm/WrapperSubTitleForm';
import Dica from '../../../UI/Dica/Dica';
import DateField from '../../../UI/Field/Date/DateField';
import Radio from '../../../UI/Field/Radio/RadioField';
import ButtonWithIconUI from '../../../UI/Button/Icon/ButtonWithIconUI';
import useStyles from '../relatorioAS.styles';
import { DateUtils } from '../../../../helpers';
import { gerarRelatorioAction } from '../../../../store/AcordoSeguro/Relatorios/relatoriosAS.saga';

function RelatorioAcessoSite({ onChange, onFocus }) {
  const dispatch = useDispatch();
  const styles = useStyles();

  const [habilitarRelatorioAcesso, setHabilitarRelatorioAcesso] = useState(false);

  const dataInicialAcesso = useSelector(states => states.relatoriosAS.requestFields.dataInicialAcesso.copy());
  const dataFinalAcesso = useSelector(states => states.relatoriosAS.requestFields.dataFinalAcesso.copy());


  // Habilita o botão de gerar relatorio de acesso ao site.
  useEffect(() => {
    if (DateUtils.isAValidDate(dataInicialAcesso.value) && DateUtils.isAValidDate(dataFinalAcesso.value)) {
      setHabilitarRelatorioAcesso(true);
    } else {
      setHabilitarRelatorioAcesso(false);
    }
  }, [dataInicialAcesso.value, dataFinalAcesso.value, setHabilitarRelatorioAcesso]);

  const onClickGerarRelatorio = useCallback(() => {
    dispatch(gerarRelatorioAction(true));
  }, [dispatch]);

  return (
    <div className={styles.div__container}>
      <div className={styles.div__subTitle}>
        <WrapperSubTitleForm title="Acessos ao Site" className={styles.subTitleAcesso} />
        <Dica mensagemDica="Período máximo 180 dias" />
      </div>
      <div className={styles.div__inputFieldsRoot}>
        <div className={styles.div__dataDistance}>
          <DateField
            isForm
            name={dataInicialAcesso.name}
            value={dataInicialAcesso.value}
            error={dataInicialAcesso.error}
            errorMessage={dataInicialAcesso.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </div>
        <div>
          <DateField
            isForm
            name={dataFinalAcesso.name}
            value={dataFinalAcesso.value}
            error={dataFinalAcesso.error}
            errorMessage={dataFinalAcesso.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </div>
      </div>
      <div className={styles.div__checkboxRoot}>
        <Radio label="PDF" checked />
        <Radio disabled label="Planilha" />
      </div>
      <div className={styles.div__buttonRoot}>
        <ButtonWithIconUI
          label="Gerar Relatório"
          iconFontAwesome="fas fa-file-download"
          className={styles.btn__gerarRelatorio}
          disabled={!habilitarRelatorioAcesso}
          onClick={onClickGerarRelatorio}
        />
      </div>
    </div>
  );
}

export default RelatorioAcessoSite;
