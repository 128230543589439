import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';

import { Breadcrumbs, Typography, IconButton } from '@material-ui/core';

import useStyles from './Topbar.styles';

function Topbar({ icon, routesName, onClose }) {
  const styles = useStyles();
  return (
    <header className={styles.root}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.routes}>
        <Breadcrumbs separator=">">
          {routesName.map(name => (
            <Typography key={name} component="p">
              {name}
            </Typography>
          ))}
        </Breadcrumbs>
      </div>
      <div className={styles.close}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
    </header>
  );
}

Topbar.defaultProps = {
  onClose: () => null,
};

Topbar.propTypes = {
  onClose: PropTypes.func,
  icon: PropTypes.node.isRequired,
  routesName: PropTypes.array.isRequired,
};

export default React.memo(Topbar);
