import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import WrapperSubTitleForm from '../../../../UI/WrapperSubTitleForm/WrapperSubTitleForm';
import InputField from '../../../../UI/Field/Input/InputField';
import SelectorField from '../../../../UI/Field/Selector/SelectorField';
import MaskField from '../../../../UI/Field/Mask/MaskField';
import { CATEGORIAS, tipoUsuarioComercialEnum } from '../../../../../helpers/constants/enums.constants';
import MultilineSelect from '../../../../UI/Select/Multiline/MultilineSelect';
import {
  useCrudClienteDispatch,
  useCrudClienteSelector,
} from '../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import { buildSelectorStatus } from '../../../../../helpers/factory/global.factory';
import { generateValuesWithSeparator, getRequiredLabel } from '../../../../../helpers/utils/utils';
import Grid24 from '../../../../UI/Grid24/Grid24';
import {
  onChangeCategoria,
  onChangeControls,
  onChangeRamosAtividade,
  onFocusComercialPrincipalAction,
  onFocusControls,
  onFocusRamosAtividadeAction,
} from '../../../../../store/Cliente/V2/Crud/crudCliente.store';
import { CNPJ_MASK, CPF_MASK } from '../../../../../helpers/constants/layout.constants';

import useStyles from './identificacaoCrudCliente.styles';
import Editor from '../../../../UI/EditorTexto';
import {
  CATEGORIA,
  CNPJ, CODIGO_CLIENTE,
  COMERCIAL_PRINCIPAL,
  CPF, FILIAL_RESPONSAVEL,
  IDENTIFICACAO_INTERNA,
  INSCRICAO_ESTADUAL,
  INSCRICAO_MUNICIPAL,
  INSERIR_OBSERVACAO,
  NOME,
  NOME_FANTASIA,
  OBSERVACAO,
  ORIGEM_LEAD,
  PREPOSTO,
  RAMOS_ATIVIDADE,
  STATUS,
  MOTIVO_STATUS,
  TELEMARKETING, TITULO,
  TIPO_CLASSIFICACAO_CLIENTES,
} from './identificacaoCrudCliente.constants';
import {
  findCategoriasAction,
  findFiliaisAction,
  findRamosAtividadesAction,
  findTipoClassificacaoClientesAction,
  findUsuariosAction,
  findUsuariosComercialMarketingClienteAction,
  findUsuariosComercialPrincipalClienteAction,
  findUsuariosTelemarketingAction,
} from '../../../../../store/Global/LoadSelectors/loadSelectors.saga';

import { changeIdFilialAction, fetchTiposUsuariosAction } from '../../../../../store/Cliente/V2/Crud/crudCliente.saga';

function IdentificacaoCrudCliente({
  isEditMode,
  match,
}) {
  const globalDispatch = useDispatch();
  const dispatch = useCrudClienteDispatch();
  const [statusSelector] = useState(buildSelectorStatus());
  const classes = useStyles();

  const filiais = useSelector(states => states.selectors.filiais.selector);
  const tipoClassificacaoClientes = useSelector(states => states.selectors.tipoClassificacaoClientes.selector);
  const tipoUsuarioComercialPrincipalSelector = useSelector(states => states.selectors.tipoUsuarioComercialPrincipal.selector);
  const tipoUsuarioComercialTelemarketingSelector = useSelector(states => states.selectors.tipoUsuarioComercialTelemarketing.selector);
  const categorias = useSelector(states => states.selectors.categorias.selector);
  const ramosAtividades = useSelector(states => states.selectors.ramosAtividades.selector);
  const usuarios = useSelector(states => states.selectors.usuarios.selector);
  const usuariosTelemarketing = useSelector(states => states.selectors.usuariosTelemarketing.selector);

  const idCliente = useCrudClienteSelector(states => states.controls.idCliente);
  const nome = useCrudClienteSelector(states => states.controls.nome.copy());
  const nomeFantasia = useCrudClienteSelector(states => states.controls.nomeFantasia.copy());
  const identificacaoInterna = useCrudClienteSelector(states => states.controls.identificacaoInterna.copy());
  const categoria = useCrudClienteSelector(states => states.controls.categoria.copy());
  const documento = useCrudClienteSelector(states => states.controls.documento.copy());
  const preposto = useCrudClienteSelector(states => states.controls.preposto.copy());
  const idUsuarioPrincipal = useCrudClienteSelector(states => states.controls.idUsuarioPrincipal.copy());
  const inscricaoEstadual = useCrudClienteSelector(states => states.controls.inscricaoEstadual.copy());
  const inscricaoMunicipal = useCrudClienteSelector(states => states.controls.inscricaoMunicipal.copy());
  const idFilial = useCrudClienteSelector(states => states.controls.idFilial.copy());
  const idTipoClassificacaoCliente = useCrudClienteSelector(states => states.controls.idTipoClassificacaoCliente.copy());
  const observacao = useCrudClienteSelector(states => states.controls.observacao.copy());
  const status = useCrudClienteSelector(states => states.controls.statusCliente.copy());
  const motivoStatusInativo = useCrudClienteSelector(states => states.controls.motivoStatusInativo.copy());
  const ramosAtividade = useCrudClienteSelector(states => states.controls.ramosAtividade.copy());
  const origemLead = useCrudClienteSelector(states => states.controls.origemLead.copy());
  const idUsuarioTelemarketing = useCrudClienteSelector(states => states.controls.idUsuarioTelemarketing.copy());
  const onReloadAllCombos = useCrudClienteSelector(states => states.actions.reloadAllCombos);
  const idsTiposUsuarios = useCrudClienteSelector(states => states.idsTiposUsuarios);

  const hasClientId = match.params.id;
  const isPessoaFisica = (categoria.value === CATEGORIAS.PESSOA_FISICA);

  const modulesEditor = ['bold', 'italic', 'underline'];


  const onChangeObservacao = useCallback((event) => {
    dispatch(onChangeControls('observacao', event));
  }, [dispatch]);

  const usuariosPrincipalMemo = useMemo(() => {
    if (hasClientId) {
      return tipoUsuarioComercialPrincipalSelector;
    }
    return usuarios;
  },
  [tipoUsuarioComercialPrincipalSelector, usuarios, hasClientId]);


  // Busca os dados para as combo box.
  useEffect(() => {
    globalDispatch(findFiliaisAction());
    globalDispatch(findTipoClassificacaoClientesAction());
    globalDispatch(findCategoriasAction());
    globalDispatch(findRamosAtividadesAction());
    dispatch(fetchTiposUsuariosAction());
  }, [globalDispatch, onReloadAllCombos, dispatch]);

  // Busca os usuários dos tipos comerciais para as 3 combo box de Comercial.
  useEffect(() => {
    if (hasClientId) {
      globalDispatch(findUsuariosComercialPrincipalClienteAction(hasClientId, tipoUsuarioComercialEnum.PRINCIPAL));
      globalDispatch(findUsuariosComercialMarketingClienteAction(hasClientId, tipoUsuarioComercialEnum.TELEMARKETING));
    } else {
      globalDispatch(findUsuariosAction(idsTiposUsuarios?.idComercial));
      globalDispatch(findUsuariosTelemarketingAction(idsTiposUsuarios?.idTelemarketing));
    }
  }, [globalDispatch, hasClientId, idsTiposUsuarios]);

  const sanitizeOnChangeTelemark = useCallback((value) => {
    if (value === '') {
      dispatch(onChangeControls('serComissaoPermanenteTelemark', false));
      dispatch(onChangeControls('porcentagemComissaoTelemark', ''));
    }
  }, [dispatch]);

  const sanitizeOnChangePrincipal = useCallback((value) => {
    if (value === '') {
      dispatch(onChangeControls('serComissaoPermanenteSecunda', false));
      dispatch(onChangeControls('idUsuarioTelemarketing', ''));
      dispatch(onChangeControls('porcentagemComissaoPrincipal', ''));
    }
    sanitizeOnChangeTelemark(value);
  }, [dispatch, sanitizeOnChangeTelemark]);

  const onChangeComercialTelemarketing = useCallback((event) => {
    const { name, value } = event.target;
    sanitizeOnChangeTelemark(value);
    dispatch(onChangeControls(name, value));
  }, [dispatch, sanitizeOnChangeTelemark]);

  const onChangeComercialPrincipal = useCallback((event) => {
    const { name, value } = event.target;
    sanitizeOnChangePrincipal(value);
    dispatch(onChangeControls(name, value));

    dispatch(changeIdFilialAction(value));
  }, [dispatch, sanitizeOnChangePrincipal]);

  const onChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      if (name === 'statusCliente') {
        dispatch(onChangeControls('motivoStatusInativo', ''));
      }
      dispatch(onChangeControls(name, value));
    },
    [dispatch],
  );

  const onFocus = useCallback((event) => {
    const fieldName = event.target.name;
    /* if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    } */
    dispatch(onFocusControls(fieldName));
  }, [dispatch]);

  const onBlurNomeHandler = useCallback(() => {
    if (nomeFantasia.value === '' && !nomeFantasia.disabled) {
      dispatch(onChangeControls('nomeFantasia', nome.value));
    }
    if (identificacaoInterna.value === '' && !identificacaoInterna.disabled) {
      dispatch(onChangeControls('identificacaoInterna', nome.value));
    }
  }, [dispatch, nomeFantasia.value, nome.value, nomeFantasia.disabled, identificacaoInterna]);

  const changeCategoriaHandler = useCallback(
    (event) => {
      const { value } = event.target;
      dispatch(onChangeCategoria(value));
    },
    [dispatch],
  );

  const changeRamoAtividadeHandler = useCallback(
    (event) => {
      const { value } = event.target;
      dispatch(onChangeRamosAtividade(value));
    },
    [dispatch],
  );

  const onFocusRamosAtividade = useCallback(() => {
    dispatch(onFocusRamosAtividadeAction());
  }, [dispatch]);

  const onFocusComercialPrincipal = useCallback(() => {
    dispatch(onFocusComercialPrincipalAction());
  }, [dispatch]);

  const maskCpfCnpj = categorias.filter(cat => cat.id === categoria?.value)[0]?.aux;

  useEffect(() => {
    dispatch(onChangeControls('permiteCnpj', maskCpfCnpj === 'CNPJ'));
  }, [dispatch, maskCpfCnpj]);

  const makeMultiLineSelectWithToolTip = (notInEditMode, component) => {
    const title = generateValuesWithSeparator(ramosAtividades, ramosAtividade.value);
    return ((notInEditMode) ? (
      <Grid24 item xs={24} sm={13}>
        <Tooltip
          classes={{ tooltip: classes.tooltipMaxWidth }}
          arrow
          title={title}
          placement="bottom-start"
        >
          <div className={classes.containerTooltip}>
            {component}
          </div>
        </Tooltip>
      </Grid24>
    ) : (
      <Grid24 item xs={24} sm={13}>
        {component}
      </Grid24>
    ));
  };

  return (
    <WrapperSubTitleForm title={TITULO}>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={12} sm={3}>
          <SelectorField
            fullWidth
            isForm
            disabled={!isEditMode}
            label={getRequiredLabel(STATUS, isEditMode)}
            items={statusSelector}
            name={status.name}
            value={status.value}
            error={status.error}
            errorMessage={status.errorMessage}
            onChange={onChange}
          />
        </Grid24>
        {status.value === 'I'
          && (
            <Grid24 item xs={24} sm={13}>
              <InputField
                fullWidth
                isForm
                disabled={!isEditMode}
                label={getRequiredLabel(MOTIVO_STATUS, isEditMode)}
                name={motivoStatusInativo.name}
                value={motivoStatusInativo.value}
                error={motivoStatusInativo.error}
                maxLength={100}
                errorMessage={motivoStatusInativo.errorMessage}
                onChange={onChange}
                onFocus={onFocus}
              />
            </Grid24>
          )}
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={3}>
          <InputField
            fullWidth
            isForm
            disabled
            label={CODIGO_CLIENTE}
            value={idCliente.value}
          />
        </Grid24>
        <Grid24 item xs={24} sm={13}>
          <InputField
            fullWidth
            isForm
            disabled={!isEditMode}
            label={getRequiredLabel(NOME, isEditMode)}
            name={nome.name}
            value={nome.value}
            error={nome.error}
            maxLength={nome.maxLength}
            errorMessage={nome.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlurNomeHandler}
          />
        </Grid24>
        <Grid24 item xs={24} sm={8}>
          <InputField
            disabled={!isEditMode}
            fullWidth
            isForm
            label={ORIGEM_LEAD}
            name={origemLead.name}
            value={origemLead.value}
            error={origemLead.error}
            errorMessage={origemLead.errorMessage}
            maxLength={origemLead.maxLength}
            onFocus={onFocus}
            onChange={onChange}
          />
        </Grid24>
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={16}>
          <InputField
            fullWidth
            isForm
            disabled={nomeFantasia.disabled || !isEditMode || isPessoaFisica}
            label={NOME_FANTASIA}
            name={nomeFantasia.name}
            value={(isPessoaFisica) ? '' : nomeFantasia.value}
            maxLength={nomeFantasia.maxLength}
            error={nomeFantasia.error}
            errorMessage={nomeFantasia.errorMessage}
            onChange={onChange}
          />
        </Grid24>
        <Grid24 item xs={24} sm={4}>
          <InputField
            fullWidth
            isForm
            disabled={inscricaoEstadual.disabled || !isEditMode || isPessoaFisica}
            label={INSCRICAO_ESTADUAL}
            name={inscricaoEstadual.name}
            value={inscricaoEstadual.value}
            maxLength={inscricaoEstadual.maxLength}
            error={inscricaoEstadual.error}
            errorMessage={inscricaoEstadual.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>
        <Grid24 item xs={24} sm={4}>
          <InputField
            fullWidth
            isForm
            disabled={inscricaoMunicipal.disabled || !isEditMode || isPessoaFisica}
            label={INSCRICAO_MUNICIPAL}
            name={inscricaoMunicipal.name}
            value={inscricaoMunicipal.value}
            maxLength={inscricaoMunicipal.maxLength}
            error={inscricaoMunicipal.error}
            errorMessage={inscricaoMunicipal.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={3}>
          <SelectorField
            fullWidth
            isForm
            disabled={!isEditMode}
            label={getRequiredLabel(CATEGORIA, isEditMode)}
            items={categorias}
            name={categoria.name}
            value={categoria.value}
            error={categoria.error}
            errorMessage={categoria.errorMessage}
            onChange={changeCategoriaHandler}
          />
        </Grid24>
        <Grid24 item xs={24} sm={4}>
          <MaskField
            isForm
            fullWidth
            disabled={documento.disabled || !isEditMode}
            label={getRequiredLabel(categoria.value === CATEGORIAS.PESSOA_FISICA
              ? CPF : CNPJ, isEditMode)}
            name={documento.name}
            value={documento.value}
            error={documento.error}
            errorMessage={documento.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
            format={maskCpfCnpj === 'CPF' ? CPF_MASK : CNPJ_MASK}
          />
        </Grid24>
        <Grid24 item xs={24} sm={4}>
          <InputField
            isForm
            fullWidth
            disabled={!isEditMode}
            label={PREPOSTO}
            name={preposto.name}
            value={preposto.value}
            maxLength={preposto.maxLength}
            error={preposto.error}
            errorMessage={preposto.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>
        <Grid24 item xs={24} sm={13}>
          <InputField
            fullWidth
            isForm
            disabled={!isEditMode}
            label={IDENTIFICACAO_INTERNA}
            name={identificacaoInterna.name}
            value={identificacaoInterna.value}
            maxLength={identificacaoInterna.maxLength}
            error={identificacaoInterna.error}
            errorMessage={identificacaoInterna.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={3}>
          <SelectorField
            fullWidth
            isForm
            disabled={idTipoClassificacaoCliente.disabled || !isEditMode}
            label={getRequiredLabel(TIPO_CLASSIFICACAO_CLIENTES, isEditMode)}
            items={tipoClassificacaoClientes}
            name={idTipoClassificacaoCliente.name}
            value={idTipoClassificacaoCliente.value}
            error={idTipoClassificacaoCliente.error}
            errorMessage={idTipoClassificacaoCliente.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>
        <Grid24 item xs={24} sm={8}>
          <SelectorField
            fullWidth
            isForm
            disabled={idFilial.disabled || !isEditMode}
            label={getRequiredLabel(FILIAL_RESPONSAVEL, isEditMode)}
            items={filiais}
            name={idFilial.name}
            value={idFilial.value}
            error={idFilial.error}
            errorMessage={idFilial.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>
        {makeMultiLineSelectWithToolTip(!isEditMode, (<MultilineSelect
          fullWidth
          isForm
          disabled={!isEditMode}
          label={getRequiredLabel(RAMOS_ATIVIDADE, isEditMode)}
          items={ramosAtividades}
          value={ramosAtividade.value}
          error={ramosAtividade.error}
          errorMessage={ramosAtividade.errorMessage}
          onChange={changeRamoAtividadeHandler}
          onFocus={onFocusRamosAtividade}
        />
        ))}
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={11}>
          <SelectorField
            fullWidth
            isForm
            hasEmptyLabel
            disabled={!isEditMode}
            label={getRequiredLabel(COMERCIAL_PRINCIPAL, isEditMode)}
            items={usuariosPrincipalMemo}
            name={idUsuarioPrincipal.name}
            value={idUsuarioPrincipal.value}
            error={idUsuarioPrincipal.error}
            errorMessage={idUsuarioPrincipal.errorMessage}
            onChange={onChangeComercialPrincipal}
            onFocus={onFocusComercialPrincipal}
          />
        </Grid24>
        <Grid24 item xs={24} sm={13}>
          <SelectorField
            fullWidth
            isForm
            hasEmptyLabel
            disabled={idUsuarioPrincipal.value === '' || !isEditMode}
            label={TELEMARKETING}
            items={hasClientId ? tipoUsuarioComercialTelemarketingSelector : usuariosTelemarketing}
            name={idUsuarioTelemarketing.name}
            value={idUsuarioTelemarketing.value}
            onChange={onChangeComercialTelemarketing}
          />
        </Grid24>
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={24}>
          <Editor
            label={OBSERVACAO}
            placeholder={INSERIR_OBSERVACAO}
            readOnly={!isEditMode}
            name={observacao.name}
            modules={modulesEditor}
            model={observacao}
            value={observacao.value}
            error={observacao.error}
            isInputLabel
            errorMessage={observacao.errorMessage}
            onChange={onChangeObservacao}
          />
        </Grid24>
      </Grid24>
    </WrapperSubTitleForm>
  );
}

export default withRouter(IdentificacaoCrudCliente);
