export const SET_VALUES_CAIXA_FILIAIS_PAGE = '@caixas_filiais/set_values';
export const CLEAR_ALL_STATE_FROM_PAGE = '@menu/clear_all_state';
export const SET_VALUE_CONTA_CORRENTE_COMISSIONADO_PAGE = '@conta_corrente_comissionado/set_values';
export const SET_VALUE_CONTA_CORRENTE_HONORARIO_PAGE = '@conta_corrente_honorario/set_values';
export const SET_VALUE_LANCAMENTO_NAO_PRESTADOS_PAGE = '@conta_corrente_cliente/set_values';
export const SET_VALUE_RECEBIMENTO_PAGE = '@recebimentoClientes/set_values';
export const SET_VALUE_REGISTRAR_BOLETO_PAGE = '@registroBoleto/set_values';


export function setValusCaixaFiliaisPageAction(pageable, filters, columnOrder) {
  return {
    type: SET_VALUES_CAIXA_FILIAIS_PAGE, pageable, filters, columnOrder,
  };
}

export function setValuesContaCorrenteComissionadoPageAction(pageable, filters, columnOrder) {
  return {
    type: SET_VALUE_CONTA_CORRENTE_COMISSIONADO_PAGE, pageable, filters, columnOrder,
  };
}

export function setValuesContaCorrenteHonorarioPageAction(pageable, filters, columnOrder) {
  return {
    type: SET_VALUE_CONTA_CORRENTE_HONORARIO_PAGE, pageable, filters, columnOrder,
  };
}

export function setValuesLancamentosNaoPrestadosPageAction(pageable, filters, columnOrder) {
  return {
    type: SET_VALUE_LANCAMENTO_NAO_PRESTADOS_PAGE, pageable, filters, columnOrder,
  };
}

export function clearAllStatesFromPageAction() {
  return { type: CLEAR_ALL_STATE_FROM_PAGE };
}

export function setValuesRecebimentoPageAction(pageable, filters, columnOrder) {
  return {
    type: SET_VALUE_RECEBIMENTO_PAGE, pageable, filters, columnOrder,
  };
}
export function setValuesRegistrarBoletoPageAction(pageable, filters, columnOrder) {
  return {
    type: SET_VALUE_REGISTRAR_BOLETO_PAGE, pageable, filters, columnOrder,
  };
}
