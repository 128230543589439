import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Endereco from '../../../../UI/EnderecosV2/Endereco/Endereco';

import useUtilStyles from '../../../../../helpers/styles/utils.styles';

import WrapperSubTitleForm from '../../../../UI/WrapperSubTitleForm';
import { ENDERECO } from '../../../../../helpers/constants/global.constants';
import { findEstadosAction } from '../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import { useCrudUsuarioDispatch2, useCrudUsuarioSelector2 } from '../../../../../store/Usuario/V2/CrudUsuarioProvider';
import {
  setEnderecoAction,
  setEnderecoAttributeFocusAction,
  setEnderecoAttributeValueAction,
} from '../../../../../store/Usuario/V2/crudUsuario.store';

function EnderecoCrudUsuario({ isInspectMode }) {
  const utilStyles = useUtilStyles();
  const dispatch = useCrudUsuarioDispatch2();
  const globalDispatch = useDispatch();


  const estados = useSelector(states => states.selectors.estados.selector);
  const endereco = useCrudUsuarioSelector2(states => ({ ...states.controls.endereco }));

  // Busca os estados.
  useEffect(() => {
    globalDispatch(findEstadosAction());
  }, [globalDispatch]);

  const setEnderecoHandler = useCallback((enderecoParam) => {
    dispatch(setEnderecoAction(enderecoParam));
  }, [dispatch]);

  const setEnderecoAttributeValueHandler = useCallback((name, value) => {
    dispatch(setEnderecoAttributeValueAction(name, value));
  }, [dispatch]);

  const setEnderecoAttributeFocusFocusHandler = useCallback((enderecoParam) => {
    dispatch(setEnderecoAttributeFocusAction(enderecoParam));
  }, [dispatch]);

  return (
    <WrapperSubTitleForm title={ENDERECO} className={utilStyles.mt16}>
      <Endereco
        model={endereco}
        estados={estados}
        disabled={isInspectMode}
        setEndereco={setEnderecoHandler}
        setAttribute={setEnderecoAttributeValueHandler}
        setFocus={setEnderecoAttributeFocusFocusHandler}
      />
    </WrapperSubTitleForm>
  );
}

export default React.memo(EnderecoCrudUsuario);
