import {gateway, gatewayShortJson} from '../../api';
import {getUriParams} from '../../../helpers/utils/utils';

const CORE = process.env.REACT_APP_CORE;

export function insertRamoAtividade(nomeRamoAtividade) {
  return gateway.post(`${CORE}/ramos-atividade`, nomeRamoAtividade);
}

export function updateRamoAtividade(idRamoAtividade, ramoAtividade) {
  return gateway.put(`${CORE}/ramos-atividade/${idRamoAtividade}`, ramoAtividade);
}

export function findRamoAtividade(nomeRamoAtividade, page, size) {
  const uri = getUriParams({nomeRamoAtividade, page, size});
  return gateway.get(`${CORE}/ramos-atividade/?${uri}`);
}

export function getShortRamoAtividade() {
  return gatewayShortJson.get(`${CORE}/ramos-atividade`);
}

export function deleteRamoAtividade(idRamoAtividade) {
  return gateway.delete(`${CORE}/ramos-atividade/${idRamoAtividade}`);
}

export function findRamoAtividadeById(idRamoAtividade) {
  return gateway.get(`${CORE}/ramos-atividade/${idRamoAtividade}`);
}
