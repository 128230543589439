import { getUriParams } from '../../../helpers';
import {
  CORE, gateway, gatewayShortJson, RELATORIOS,
} from '../../api';

export default {
  findAllComissionados(statusUsuario) {
    return gatewayShortJson.get(`${CORE}/conta-corrente-usuario/${statusUsuario}/statusUsuario`);
  },

  findSaldos(period, idUsuario, statusConta) {
    const body = {
      dataInicial: period?.startDate, dataFinal: period?.endDate, idUsuario, statusConta,
    };
    return gateway.post(`${CORE}/conta-corrente-usuario/resumo`, body);
  },

  findAllLancamentos(period, idUsuario, statusConta, pageable, order) {
    const sortedQuery = order.reduce((obj, sort) => (
      `${obj}&sort=${sort}`
    ), '');
    const queryParam = getUriParams(pageable);
    const body = {
      dataInicial: period?.startDate, dataFinal: period?.endDate, idUsuario, statusConta,
    };
    return gateway.post(`${CORE}/conta-corrente-usuario/lancamento?${queryParam}${sortedQuery}`, body);
  },

  deleteLancamento(idContaCorrenteUsuario) {
    return gateway.delete(`${CORE}/conta-corrente-usuario/${idContaCorrenteUsuario}/lancamento`);
  },

  atualizarStatus(idContaCorrenteUsuario, idStatusContaCorrenteUsuario) {
    const body = {
      idContaCorrenteUsuario, idStatusContaCorrenteUsuario,
    };
    return gateway.put(`${CORE}/conta-corrente-usuario/lancamento`, body);
  },

  downloadExtratoLancamento(period, idUsuario, statusConta, order) {
    const sortedQuery = order.reduce((obj, sort) => (
      `${obj}&sort=${sort}`
    ), '');
    const body = {
      dataInicial: period?.startDate, dataFinal: period?.endDate, idUsuario, statusConta,
    };
    return gateway.post(`${RELATORIOS}/conta-corrente-usuario/relatorio-conta-corrente?${sortedQuery}`, body, {
      responseType: 'blob',
    });
  },
};
