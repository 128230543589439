import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import CheckboxField from '../UI/Field/Checkbox/CheckboxField';
import OrdenacaoColuna from '../UI/Tabela/Ordenacao/OrdenacaoColuna';

const height = '44px';

function TableHeaderCaixasFiliaisComponent({
  columnOrder, isSelectAll, onChangeOrder, onChecked, isManager,
}) {
  return (
    <TableRow>
      {isManager && (
        <TableCell height={height} width={53}>
          <CheckboxField checked={isSelectAll} onChange={onChecked} />
        </TableCell>
      )}
      <TableCell height={height} width={isManager ? 160 : 214}>
        <OrdenacaoColuna
          columnLabel="Data do lançamento"
          idColumn="dataLancamento"
          arrowOrdenacao={columnOrder?.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder('dataLancamento')}
        />
      </TableCell>
      <TableCell height={height} width={isManager ? 213 : 212}>
        <OrdenacaoColuna
          columnLabel="Lançamento"
          idColumn="lancamento"
          arrowOrdenacao={columnOrder?.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder('lancamento')}
        />
      </TableCell>
      <TableCell height={height} width={268}>
        <OrdenacaoColuna
          columnLabel="Usuário"
          idColumn="nomeResponsavelLancamento"
          arrowOrdenacao={columnOrder?.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder('nomeResponsavelLancamento')}
        />
      </TableCell>
      <TableCell height={height} width={159}>
        <OrdenacaoColuna
          columnLabel="Valor"
          idColumn="valorLancamento"
          arrowOrdenacao={columnOrder?.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder('valorLancamento')}
        />
      </TableCell>
      <TableCell height={height} width={isManager ? 107 : 109}>
        <OrdenacaoColuna
          columnLabel="Aprovado"
          idColumn="dataAprovacao"
          arrowOrdenacao={columnOrder?.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder('dataAprovacao')}
        />
      </TableCell>
      <TableCell height={height} width={isManager ? 144 : 104}>
        Ações
      </TableCell>
      <TableCell height={height} width={isManager ? 42 : 80}><span /></TableCell>
    </TableRow>
  );
}

export const TableHeaderCaixasFiliais = TableHeaderCaixasFiliaisComponent;
