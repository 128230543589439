import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import { Button, IconButton } from '@material-ui/core';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { MdPrint as MdPrintIcon } from 'react-icons/md';
import { AiOutlineReload, AiOutlineClockCircle } from 'react-icons/ai';
import { FaFileExcel } from 'react-icons/fa';


import useStyle, {
  AlignButtons,
  AlignLabel,
  ButtonAtualizar,
  ButtonExcel,
  ButtonImprimir,
  ButtonPesquisaAvancada,
  Container,
  ContainerPesquisaAvancada,
  ContainerResumo, FiltroPesquisa, TitleTable,
} from './RecebimentoDevedoresStyles';
import DateField from '../../components/UI/Field/Date/DateField';
import CardResumo from '../ContaCorrenteCliente/Accordions/RelacaoPrestacao/Cards/CardResumo';
import { InputField, SelectorField } from '../../components';
import SwitchUI from '../../components/UI/Switch/SwitchUI';
import TabelaRecebimentoDevedores from './TabelaRecebimentoDevedores';
import { recebimentoDevedoresFactory } from './RecebimentoDevedoresFactory';
import { useRecebimentoDispatch, useRecebimentoSelector } from '../../store/RecebimentoDevedores/RecebimentoDevedoresProvider';
import {
  getFieldName, getMoneyMask, getNewPage, toAmericanDate,
} from '../../helpers';
import {
  onChangeFormFieldsRecebimentosAction, onFocusDateRecebimentosAction, resetFormFieldsAction, setSimpleRecebimentoAction, setSimpleValueRecebimentoDistribuicao,
} from '../../store/RecebimentoDevedores/RecebimentoDevedores.store';
import { findTiposFormaPagamentoAction } from '../../store/Global/LoadSelectors/loadSelectors.saga';
import {
  atualizarParcelasAction,
  buscarDadosTabelaAction,
  buscarDadosTabelaFiltroAction,
  buscarDadosTabelaFiltroSimplesAction,
  buscarInfosCardsAction,
  buscarUltimaDataHoraAtualizacaoAction,
  clickComprovanteAction,
  exportarAction,
  imprimirParcelasAction,
} from '../../store/RecebimentoDevedores/RecebimentoDevedores.saga';
import LoadingUI from '../../components/UI/Loading/LoadingUI';
import DialogNew from '../../layouts/FormDialog/DialogNew';
import { DialogRecebimentoManual } from './DialogRecebimentoManual';
import DialogDistribuicaoManual from './DialogDistribuicaoManual';


function valorDistribuidoSelected() {
  return [
    { id: 'S', value: 'Sim' },
    { id: 'N', value: 'Não' },

  ];
}
function RecebimentoDevedoresComponent() {
  const style = useStyle();
  const dispatch = useRecebimentoDispatch();
  const globalDispatch = useDispatch();

  const [openPesquisaAvancada, setOpenPesquisaAvancada] = useState(false);
  const [openRecebimentoManual, setOpenRecebimentoManual] = useState(false);
  const [openDistribuicaoManual, setOpenDistribuicaoManual] = useState(false);

  const tiposFormasPagamentoRecebimento = useSelector(states => (states.selectors.tiposFormaPagamento.selector));
  const page = useRecebimentoSelector(states => states.page);
  const rowsPerPage = useRecebimentoSelector(states => states.rowsPerPage);
  const totalElements = useRecebimentoSelector(states => states.totalElements);
  const arrowDirection = useRecebimentoSelector(states => states.arrowDirection);
  const columnOrdenacao = useRecebimentoSelector(states => states.columnOrdenacao);
  const tableView = useRecebimentoSelector(states => states.tableView);
  const recebimentosNoDia = useRecebimentoSelector(states => states.recebimentosNoDia);
  const valoresRecebidosNoDia = useRecebimentoSelector(states => states.valoresRecebidosNoDia);
  const parcelasAguardandoPagamento = useRecebimentoSelector(states => states.parcelasAguardandoPagamento);
  const parcelasAtrasadas = useRecebimentoSelector(states => states.parcelasAtrasadas);
  const dataPagamento = useRecebimentoSelector(states => states.formFields.dataPagamento);
  const dataVencimento = useRecebimentoSelector(states => states.formFields.dataVencimento);
  const idTipoFormaPagamento = useRecebimentoSelector(states => states.formFields.idTipoFormaPagamento);
  const nomeCliente = useRecebimentoSelector(states => states.formFields.nomeCliente);
  const nomeDevedor = useRecebimentoSelector(states => states.formFields.nomeDevedor);
  const somenteParcelasAguardandoPagamento = useRecebimentoSelector(states => states.formFields.somenteParcelasAguardandoPagamento);
  const somenteParcelasrecebidasNaoDistribuidas = useRecebimentoSelector(states => states.formFields.somenteParcelasrecebidasNaoDistribuidas);
  const valorDistribuido = useRecebimentoSelector(states => states.formFields.valorDistribuido);
  const loadingPage = useRecebimentoSelector(states => states.common.loadingPage);
  const idAcordoParcelas = useRecebimentoSelector(states => states.idAcordoParcelas);
  const selectedDate = useRecebimentoSelector(states => states.selectedDate);

  const valorDistribuidoSelect = React.useMemo(() => valorDistribuidoSelected(), []);
  const [conteudoTabela, setConteudoTabela] = useState([]);
  const [conteudoTabelaMap, setConteudoTabelaMap] = useState('');

  const buttonClassPesquisaAvancada = cx({
    [style.pesquisaAvancadaButton]: !openPesquisaAvancada,
    [style.pesquisaSimplesButton]: openPesquisaAvancada,
  });

  const getDisabledButton = () => {
    if (openPesquisaAvancada) {
      return !(dataVencimento.value
        || nomeCliente.value
        || nomeDevedor.value
        || dataPagamento.value
        || (idTipoFormaPagamento.value
          || (idTipoFormaPagamento.value === 0
            || idTipoFormaPagamento.value === 1
            || idTipoFormaPagamento.value === 2
            || idTipoFormaPagamento.value === 5
            || idTipoFormaPagamento.value === 6))
        || valorDistribuido.value);
    }
    return !(dataVencimento.value);
  };

  const onClickPesquisaAvancadaHandler = useCallback(() => {
    dispatch(resetFormFieldsAction());
    setOpenPesquisaAvancada(oldValue => !oldValue);
  }, [dispatch]);


  const changeRowsPerPageHandler = useCallback((e) => {
    const size = e.target.value;
    const newPage = getNewPage(rowsPerPage, page, size);

    dispatch(setSimpleRecebimentoAction('page', Number(newPage)));
    dispatch(setSimpleRecebimentoAction('rowsPerPage', Number(size)));

    if (openPesquisaAvancada === true) {
      dispatch(buscarDadosTabelaFiltroAction(true));
    } else {
      dispatch(buscarDadosTabelaFiltroSimplesAction(true));
    }
  }, [dispatch, rowsPerPage, page, openPesquisaAvancada]);


  const changePageHandler = useCallback((event, newPage) => {
    dispatch(setSimpleRecebimentoAction('page', Number(newPage)));
    dispatch(setSimpleRecebimentoAction('tableView', tableView));
    if (openPesquisaAvancada === true) {
      dispatch(buscarDadosTabelaFiltroAction(true));
    } else {
      dispatch(buscarDadosTabelaFiltroSimplesAction(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, openPesquisaAvancada]);

  const setColumnOrdenacao = useCallback((column) => {
    dispatch(setSimpleRecebimentoAction('columnOrdenacao', column));
  }, [dispatch]);

  const setArrowDirection = useCallback((internalReturn) => {
    dispatch(setSimpleRecebimentoAction('arrowDirection', internalReturn));
  }, [dispatch]);

  const onClickColumnOrdenar = useCallback(() => {
    if (tableView?.content?.length > 0 || tableView?.length > 0) {
      dispatch(setSimpleRecebimentoAction('page', 0));
      if (openPesquisaAvancada === true) {
        dispatch(buscarDadosTabelaFiltroAction(true));
      } else {
        dispatch(buscarDadosTabelaFiltroSimplesAction(true));
      }
    }
  }, [dispatch, tableView, openPesquisaAvancada]);

  const onChangeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeFormFieldsRecebimentosAction(name, value));
  }, [dispatch]);

  const onFocusFieldsHandler = useCallback((event) => {
    const { name } = event.target;
    dispatch(onFocusDateRecebimentosAction(name));
  }, [dispatch]);

  const onChangeCheckedSwicth = React.useCallback((e) => {
    const name = getFieldName(e.target);
    const { checked } = e.target;

    if (checked) {
      if (name === 'somenteParcelasrecebidasNaoDistribuidas') {
        dispatch(onChangeFormFieldsRecebimentosAction('somenteParcelasAguardandoPagamento', false));
      }
      if (name === 'somenteParcelasAguardandoPagamento') {
        dispatch(onChangeFormFieldsRecebimentosAction('somenteParcelasrecebidasNaoDistribuidas', false));
      }
    }

    dispatch(setSimpleRecebimentoAction('page', 0));
    dispatch(onChangeFormFieldsRecebimentosAction(name, checked));

    if (openPesquisaAvancada === true) {
      dispatch(buscarDadosTabelaFiltroAction(true));
    } else {
      dispatch(buscarDadosTabelaFiltroSimplesAction(true));
    }
  }, [dispatch, openPesquisaAvancada]);

  const onClickPesquisar = useCallback(async () => {
    dispatch(setSimpleRecebimentoAction('page', 0));
    dispatch(setSimpleRecebimentoAction('rowsPerPage', 10));
    if (openPesquisaAvancada === true) {
      dispatch(buscarDadosTabelaFiltroAction(false));
    } else {
      dispatch(buscarDadosTabelaFiltroSimplesAction(false));
    }
  }, [dispatch, openPesquisaAvancada]);

  const onClickAtualizarHandler = useCallback(() => {
    dispatch(atualizarParcelasAction(idAcordoParcelas, openPesquisaAvancada));
    // Descomentar para envio de parcelas em lote
    // dispatch(atualizarParcelasLoteAction(idAcordoParcelas, openPesquisaAvancada));
  }, [dispatch, openPesquisaAvancada, idAcordoParcelas]);

  const onClickImprimirHandler = useCallback(() => {
    dispatch(imprimirParcelasAction(openPesquisaAvancada ? 'avancada' : 'simples'));
  }, [openPesquisaAvancada, dispatch]);

  const onClickExportarHandler = useCallback(() => {
    dispatch(exportarAction(openPesquisaAvancada ? 'avancada' : 'simples'));
  }, [openPesquisaAvancada, dispatch]);

  const handleClickRecebimentoManual = useCallback((idAcordo,
    numeroParcela, numeroAcordo, numeroProcesso, tipoPagamento,
    valorParcela, idAcordoParcela) => {
    dispatch(setSimpleRecebimentoAction('idAcordo', idAcordo));
    dispatch(setSimpleRecebimentoAction('nroParcela', numeroParcela));
    dispatch(setSimpleRecebimentoAction('numeroAcordo', numeroAcordo));
    dispatch(setSimpleRecebimentoAction('idProcesso', numeroProcesso));
    dispatch(setSimpleRecebimentoAction('tipoFormaPagto', tipoPagamento));
    dispatch(setSimpleRecebimentoAction('valorParcela', valorParcela));
    dispatch(setSimpleRecebimentoAction('idAcordoParcela', idAcordoParcela));
    setOpenRecebimentoManual(true);
  }, [dispatch]);

  const handleClickComprovante = useCallback((numeroAcordo, numeroProcesso, numeroParcela) => {
    dispatch(clickComprovanteAction(numeroAcordo, numeroProcesso, numeroParcela));
  }, [dispatch]);

  const handleClickDistribuicaoManual = useCallback((valorTotalDistribuicao, idAcordoParcelaDistribuicao, dataPagamentoRecebimento) => {
    dispatch(setSimpleValueRecebimentoDistribuicao('valorTotalRecebimentoDistribuicao', valorTotalDistribuicao));
    dispatch(setSimpleValueRecebimentoDistribuicao('idAcordoParcelaDistribuicao', idAcordoParcelaDistribuicao));
    dispatch(setSimpleValueRecebimentoDistribuicao('dataPagamento', dataPagamentoRecebimento));
    setOpenDistribuicaoManual(true);
  }, [dispatch]);

  useEffect(() => {
    setConteudoTabela(recebimentoDevedoresFactory.makeConteudoTabela(
      tableView.content,
      handleClickRecebimentoManual,
      handleClickComprovante,
      handleClickDistribuicaoManual,
    ));
  }, [tableView, setConteudoTabelaMap, handleClickRecebimentoManual, handleClickComprovante, handleClickDistribuicaoManual]);

  useEffect(() => {
    globalDispatch(findTiposFormaPagamentoAction());
  }, [globalDispatch]);

  useEffect(() => {
    dispatch(buscarInfosCardsAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(buscarDadosTabelaAction());
  }, [dispatch]);

  useEffect(() => {
    const isAllSameStatus = idAcordoParcelas.every(idAcordoParcela => idAcordoParcela.statusParcela === 'Quitada');
    const isAllSamePaymentType = idAcordoParcelas.every(
      idAcordoParcela => idAcordoParcela.tipoPagamento === 'Dinheiro'
      || idAcordoParcela.tipoPagamento === 'Cheque'
      || idAcordoParcela.tipoPagamento === 'Cartão',
    );

    const anyDataMatchesCondition = isAllSameStatus || isAllSamePaymentType;

    setConteudoTabelaMap(anyDataMatchesCondition);
  }, [conteudoTabela, setConteudoTabelaMap, idAcordoParcelas]);

  useEffect(() => {
    dispatch(buscarUltimaDataHoraAtualizacaoAction());
  }, [dispatch]);

  return (
    <>
      <DialogNew
        open={openRecebimentoManual}
        text="Recebimento Manual"
        className={style.dialogRecebimentoManual}
      >
        {openRecebimentoManual && (
          <DialogRecebimentoManual
            setOpenRecebimentoManual={setOpenRecebimentoManual}
            openPesquisaAvancada={openPesquisaAvancada}
          />
        )}
      </DialogNew>
      <DialogNew
        open={openDistribuicaoManual}
        text="Distribuição Manual"
        className={style.dialogDistribuicao}
      >
        <DialogDistribuicaoManual
          setOpenDialogDistribuicaoManual={setOpenDistribuicaoManual}
        />
      </DialogNew>
      <LoadingUI show={loadingPage} />
      <Container>
        <div>
          <div className={style.container}>
            <div className="pesquisa">
              <FiltroPesquisa>
                <div>
                  {openPesquisaAvancada === true ? (
                    <p style={{ marginRight: '5px', marginTop: '24px' }}> Data do Vencimento: </p>
                  ) : (
                    <p style={{ marginRight: '5px', marginTop: '24px' }}>Vencimento a partir de:</p>
                  )}
                  <div style={{ display: 'flex', alignSelf: 'end', marginRight: '10px' }}>
                    <DateField
                      fullWidth
                      name="dataVencimento"
                      value={dataVencimento.value}
                      style={{ marginLeft: '3px' }}
                      onChange={onChangeFieldsHandler}
                      onFocus={onFocusFieldsHandler}
                    />
                  </div>

                  <IconButton
                    onClick={() => onClickPesquisar()}
                    disabled={getDisabledButton() || (dataPagamento.value && new Date(toAmericanDate(dataPagamento.value)).getTime() > new Date().getTime())}
                  >
                    <SearchIcon />
                  </IconButton>

                </div>
              </FiltroPesquisa>
              <ButtonPesquisaAvancada>
                <div>
                  <Button
                    fullWidth
                    className={buttonClassPesquisaAvancada}
                    onClick={onClickPesquisaAvancadaHandler}
                  >
                    {openPesquisaAvancada ? 'Pesquisa Simples' : 'Pesquisa Avançada'}
                  </Button>
                </div>
              </ButtonPesquisaAvancada>
              {openPesquisaAvancada && (
                <ContainerPesquisaAvancada>
                  <div>
                    <InputField
                      fullWidth
                      name="nomeCliente"
                      label="Nome do Cliente"
                      onChange={onChangeFieldsHandler}
                      value={nomeCliente.value}
                    />
                  </div>
                  <div style={{ marginTop: '15px' }}>
                    <InputField
                      fullWidth
                      name="nomeDevedor"
                      label="Nome do Devedor"
                      onChange={onChangeFieldsHandler}
                      value={nomeDevedor.value}
                    />
                  </div>
                  <div style={{ gap: '5px', marginTop: '15px', marginBottom: '15px' }}>
                    <div style={{ width: '37%' }}>
                      <DateField
                        name="dataPagamento"
                        value={dataPagamento.value}
                        maxDate={new Date()}
                        onChange={onChangeFieldsHandler}
                        label="Data do Pagamento"
                      />
                    </div>
                    <div style={{ width: '36%' }}>
                      <SelectorField
                        fullWidth
                        hasEmptyLabel
                        name="idTipoFormaPagamento"
                        label="Tipo do Pagamento"
                        items={tiposFormasPagamentoRecebimento}
                        onChange={onChangeFieldsHandler}
                        value={idTipoFormaPagamento.value}
                      />
                    </div>
                    <div style={{ width: '31%' }}>
                      <SelectorField
                        fullWidth
                        hasEmptyLabel
                        name="valorDistribuido"
                        label="Valor Distribuído"
                        items={valorDistribuidoSelect}
                        onChange={onChangeFieldsHandler}
                        value={valorDistribuido.value}
                      />
                    </div>
                  </div>
                </ContainerPesquisaAvancada>
              )}
              <div>
                <SwitchUI
                  label="Exibir somente as Parcelas aguardando pagamento."
                  name="somenteParcelasAguardandoPagamento"
                  onChange={onChangeCheckedSwicth}
                  marginTop="0px"
                  checked={somenteParcelasAguardandoPagamento.value}
                />
              </div>
              <div>
                <SwitchUI
                  label="Exibir somente as Parcelas recebidas e não distribuídas."
                  name="somenteParcelasrecebidasNaoDistribuidas"
                  onChange={onChangeCheckedSwicth}
                  marginTop="0px"
                  checked={somenteParcelasrecebidasNaoDistribuidas.value}
                />
              </div>
              <div style={{ display: 'flex', gap: '31px' }}>
                <ButtonImprimir
                  disabled={tableView?.content?.length <= 0}
                  onClick={onClickImprimirHandler}
                >
                  <MdPrintIcon size={16} />
                  <span>Imprimir</span>

                </ButtonImprimir>
                <ButtonExcel
                  disabled={tableView?.content?.length <= 0}
                  onClick={onClickExportarHandler}
                >
                  <FaFileExcel size={14} />
                  <span>Exportar Excel</span>

                </ButtonExcel>
              </div>
            </div>
            <div id="resumo">
              <ContainerResumo>
                <h3><b>Resumo </b></h3>
                <div>
                  <CardResumo
                    title="Nº Recebimentos no dia"
                    isDetail={false}
                    bgColor="#70aab1"
                    value={recebimentosNoDia}
                  />
                  <CardResumo
                    title="Valores Recebidos no dia"
                    value={getMoneyMask(valoresRecebidosNoDia)}
                    isDetail={false}
                    bgColor="#389b7e"
                  />
                </div>
                <div>
                  <CardResumo
                    title="Nº de Parcelas Aguardando Pagto"
                    isDetail={false}
                    bgColor="#2c838e"
                    value={parcelasAguardandoPagamento}
                  />
                  <CardResumo
                    title="Parcelas Atrasadas"
                    value={parcelasAtrasadas}
                    isDetail={false}
                    bgColor="#38be97"
                  />
                </div>
              </ContainerResumo>
              <AlignButtons>
                <ButtonAtualizar
                  variant="outlined"
                  disabled={tableView?.content?.length <= 0 || conteudoTabelaMap === true}
                  onClick={() => onClickAtualizarHandler()}
                >
                  <AiOutlineReload style={{ fontSize: 'larger' }} />
                  <span>Atualizar</span>
                </ButtonAtualizar>

              </AlignButtons>
              <AlignLabel>
                <AiOutlineClockCircle size={11} />
                <p style={{ fontSize: '11px' }}>
                  Última Atualização:
                  {' '}
                  {selectedDate}
                </p>
              </AlignLabel>
            </div>
          </div>
          <TitleTable>
            Resultado da Pesquisa
          </TitleTable>
          <TabelaRecebimentoDevedores
            columns={recebimentoDevedoresFactory.makeCabecalhoTabela(
              columnOrdenacao, setColumnOrdenacao, arrowDirection, setArrowDirection, onClickColumnOrdenar,
            )}
            rows={conteudoTabela}
            page={page}
            size={totalElements}
            rowsPerPageOptions={[10, 15, 20]}
            rowsPerPage={rowsPerPage}
            onChangePage={changePageHandler}
            onChangeRowsPerPage={changeRowsPerPageHandler}
          />
        </div>
      </Container>
    </>
  );
}

export const RecebimentoDevedores = withRouter(RecebimentoDevedoresComponent);
