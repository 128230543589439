import React, { createContext } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider, createSelectorHook, createDispatchHook } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import reducer from './crudParametros.store';
import watchCrudParametros from './crudParametros.saga';

export const crudParametrosContext = createContext();
export const useCrudParametrosSelector = createSelectorHook(crudParametrosContext);
export const useCrudParametrosDispatch = createDispatchHook(crudParametrosContext);

export default function CrudParametrosProvider({ children }) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(watchCrudParametros);

  return (
    <Provider context={crudParametrosContext} store={store}>
      {children}
    </Provider>
  );
}
