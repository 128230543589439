import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  perrywinkle,
  lightPeriwinkle,
  blackTwo,
} from '../../helpers/constants/colors.constants';

export default makeStyles({
  div__customTable: {
    minWidth: '100%',
    overflow: 'auto',
    cursor: 'pointer !important',
    '& .Mui-selected': {
      backgroundColor: perrywinkle,
      '&:hover ': {
        backgroundColor: perrywinkle,
      },
    },
    '& tr': {
      '&:hover ': {
        backgroundColor: 'black16',
      },
    },
    '& td': {
      backGroundColor: 'tomato',
      fontSize: '12px',
      paddingTop: '5.1px',
      paddingBottom: '4.1px',
      padding: '0px 16px',
      height: '33px',
    },
    '& th': {
      fontSize: '12px',
      backgroundColor: lightPeriwinkle,
      color: blackTwo,
      fontWeight: 'bold',
      padding: '0px 20px',
      height: '45px',
    },
  },
  titleTable: {
    fontWeight: 'bold',
    margin: '0px 0px 13px 13px',
  },
});
