import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@material-ui/core/Tooltip';
import useStyles from './logProcessamentoCustomIcon.styles';


function LogProcessamentoCustomIcon({ tipoBorderoLog }) {
  const styles = useStyles();
  if (tipoBorderoLog === 'INFORMATIVO') {
    return (
      <Tooltip title="Informativo" arrow>
        <InfoIcon className={styles.informativo_icon} />
      </Tooltip>
    );
  }
  if (tipoBorderoLog === 'ADVERTENCIA') {
    return (
      <Tooltip title="Advertência" arrow>
        <ReportProblemIcon className={styles.advertencia_icon} />
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Erro" arrow>
      <CancelIcon className={styles.error_icon} />
    </Tooltip>
  );
}

export default React.memo(LogProcessamentoCustomIcon);
