import React from 'react';
import { useDispatch } from 'react-redux';
import RamoAtividadeProvider from '../../store/RamoAtividade/RamoAtividadeProvider';
import PrivateRoute from '../../hoc/PrivateRoute/PrivateRoute';
import PesquisaRamoAtividadePage from './Pesquisa/PesquisarRamoAtividadePage';
import { initializePageAction } from '../../store/theme.actions';
import constants from '../../helpers/constants/navigationTitle.constants';


function Wrapper() {
  const dispatch = useDispatch();
  dispatch(initializePageAction(`${constants.RAMO_ATIVIDADE}`));

  return (
    <RamoAtividadeProvider>
      <PesquisaRamoAtividadePage />
    </RamoAtividadeProvider>
  );
}

export default function RoutesRamoAtividade() {
  return (
    <PrivateRoute path="/ramo-atividade" component={Wrapper} />
  );
}
