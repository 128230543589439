import React from 'react';
import { TableRow } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { TableCellCenter, TableCellLeft } from '../UI/Tabela/tableDefaultStyles';
import { RadioButton } from '../RadioButton/RadioButton';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';

function colorStyles(statusConsulta) {
  return { color: statusConsulta ? 'black' : '#a2a0a0' };
}

function TableBodyConsultaDinamicaListComponent({ list, onOpenGerenciarConsultaDinamica }) {
  const signedUserId = useSelector(states => states.authentication.userInformation.idUsuario);

  return list?.content.map(item => (
    <TableRow key={item.idConsultaDinamica}>
      <TableCellCenter>
        <RadioButton disabled={!item.statusConsulta} value={item.idConsultaDinamica} />
      </TableCellCenter>
      <TableCellCenter style={colorStyles(item.statusConsulta)}>
        {item.idConsultaDinamica}
      </TableCellCenter>
      <TableCellCenter style={colorStyles(item.statusConsulta)}>
        {item.tipo}
      </TableCellCenter>
      <TableCellLeft style={colorStyles(item.statusConsulta)}>
        {item.nomeUsuario}
      </TableCellLeft>
      <TableCellLeft style={colorStyles(item.statusConsulta)}>
        {item.descricao}
      </TableCellLeft>
      <TableCellCenter>
        <IconButtonTooltip
          disabled={item?.idUsuario !== signedUserId}
          type="edit"
          hint="Gerenciar"
          onClick={onOpenGerenciarConsultaDinamica({
            idConsultaDinamica: item.idConsultaDinamica,
            idTipo: item.idTipo,
            status: item.statusConsulta,
            descricao: item.descricao,
          })}
        />
      </TableCellCenter>
    </TableRow>
  ));
}

export const TableBodyConsultaDinamicaList = TableBodyConsultaDinamicaListComponent;
