import React, { useCallback } from 'react';
import FormWrapperTitle from '../../UI/Wrapper/Form/FormWrapperWithTitle';
import {
  ATIVIDADE_FASES_LABEL,
  EXIGE_MOTIVO_LABEL,
  IDENTIFICACAO_DURACAO, IDENTIFICACAO_ESTAGIO,
  IDENTIFICACAO_EXIGIR_NEGOCIADOR,
  IDENTIFICACAO_NOME,
  IDENTIFICACAO_PAGAR_COMISSAO,
  IDENTIFICACAO_PERMITIR_PROCESSO,
  IDENTIFICACAO_PLACEHOLDER,
  IDENTIFICACAO_TITLE,
  RECALCULA_PROCESSO,
} from './IndentificacaoFasesPastas.constants';
import useUtilStyles from '../../../helpers/styles/utils.styles';
import Grid24 from '../../UI/Grid24/Grid24';
import InputField from '../../UI/Field/Input/InputField';
import NumberInput from '../../UI/Field/Number/NumberInput';
import CheckboxField from '../../UI/Field/Checkbox/CheckboxField';
import Editor from '../../UI/EditorTexto';
import {
  useCrudFasesPastasDispatch,
  useCrudFasesPastasSelector,
} from '../../../store/FasesPastas/Crud/CrudFasesPastasProvider';
import {
  setRequestedFieldValueAction,
  setRequestFieldsFocusAction,
} from '../../../store/FasesPastas/Crud/crudFasesPastas.store';
import { getRequiredLabel } from '../../../helpers/utils/utils';

function IdentificacaoFases({
  disabled = true,
}) {
  const utilStyles = useUtilStyles();
  const dispatch = useCrudFasesPastasDispatch();


  const nome = useCrudFasesPastasSelector(states => states.requestedFields.nome);
  const duracao = useCrudFasesPastasSelector(states => states.requestedFields.duracao);
  const descricaoTipoEstagioFase = useCrudFasesPastasSelector(states => states.requestedFields.descricaoTipoEstagioFase);
  const descricaoTipoNegociador = useCrudFasesPastasSelector(states => states.requestedFields.descricaoTipoNegociador);
  const permitirReceberProcesso = useCrudFasesPastasSelector(states => states.requestedFields.permitirReceberProcesso);
  const descricaoAtividades = useCrudFasesPastasSelector(states => states.requestedFields.descricaoAtividades.copy());
  const pagarComissao = useCrudFasesPastasSelector(states => states.requestedFields.pagarComissao);
  const exigirMotivoBaixa = useCrudFasesPastasSelector(states => states.requestedFields.exigirMotivoBaixa);
  const recalcularProcesso = useCrudFasesPastasSelector(states => states.requestedFields.recalcularProcesso);
  const porcentagemDescontoJuros = useCrudFasesPastasSelector(states => states.requestedFields.porcentagemDescontoJuros);

  const onChange = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setRequestedFieldValueAction(name, value));
  }, [dispatch]);


  const onFocus = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(setRequestFieldsFocusAction(fieldName));
  }, [dispatch]);

  const onChangeAtividades = useCallback((event) => {
    dispatch(setRequestedFieldValueAction('descricaoAtividades', event));
  }, [dispatch]);

  const onFocusAtividades = useCallback(() => {
    dispatch(setRequestFieldsFocusAction('descricaoAtividades'));
  }, [dispatch]);

  return (
    <FormWrapperTitle className={utilStyles.mt16} title={IDENTIFICACAO_TITLE}>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={5}>
          <InputField
            label={IDENTIFICACAO_NOME}
            isForm
            disabled
            fullWidth
            name={nome.name}
            value={nome.value}
          />
        </Grid24>
        <Grid24 item xs={24} sm={4}>
          <InputField
            label={IDENTIFICACAO_ESTAGIO}
            isForm
            disabled
            fullWidth
            name={descricaoTipoEstagioFase.name}
            value={descricaoTipoEstagioFase.value}
          />
        </Grid24>
        <Grid24 item xs={24} sm={4}>
          <NumberInput
            acceptEmptyValue
            isForm
            fullWidth
            disabled={disabled}
            numberType="integer"
            maxValue={999999}
            label={IDENTIFICACAO_DURACAO}
            name={duracao.name}
            value={duracao.value}
            error={duracao.error}
            errorMessage={duracao.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={5}>
          <InputField
            isForm
            fullWidth
            label={IDENTIFICACAO_EXIGIR_NEGOCIADOR}
            disabled
            name={descricaoTipoNegociador.name}
            value={descricaoTipoNegociador.value}
          />
        </Grid24>
        <Grid24 xs={24} sm={6}>
          <NumberInput
            isForm
            sideText="(%)"
            numberType="percentage"
            minValue={1}
            maxValue={999}
            disabled={disabled}
            label="Desconto Máximo nos Juros"
            name={porcentagemDescontoJuros.name}
            value={porcentagemDescontoJuros.value}
            error={porcentagemDescontoJuros.error}
            errorMessage={porcentagemDescontoJuros.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={24} className={`${utilStyles.mt7} ${utilStyles.ml8}`}>
          <Grid24 xs={24} sm={4}>
            <CheckboxField
              label={EXIGE_MOTIVO_LABEL}
              disabled
              name={exigirMotivoBaixa.name}
              checked={exigirMotivoBaixa.value}
            />
          </Grid24>
          <Grid24 xs={24} sm={5}>
            <CheckboxField
              label={IDENTIFICACAO_PERMITIR_PROCESSO}
              disabled
              name={permitirReceberProcesso.name}
              checked={permitirReceberProcesso.value}
            />
          </Grid24>
          <Grid24 xs={24} sm={5}>
            <CheckboxField
              label={RECALCULA_PROCESSO}
              disabled
              name={recalcularProcesso.name}
              checked={recalcularProcesso.value}
            />
          </Grid24>
          <Grid24 item xs={24} sm={5}>
            <CheckboxField
              label={IDENTIFICACAO_PAGAR_COMISSAO}
              disabled
              checked={pagarComissao.value}
              name={pagarComissao.name}
              value={pagarComissao.value}
            />
          </Grid24>
        </Grid24>
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={24}>
          <Editor
            label={getRequiredLabel(ATIVIDADE_FASES_LABEL, !disabled)}
            value={descricaoAtividades.value}
            placeholder={IDENTIFICACAO_PLACEHOLDER}
            name={descricaoAtividades.name}
            model={descricaoAtividades}
            readOnly={disabled}
            onChange={onChangeAtividades}
            onFocus={onFocusAtividades}
          />
        </Grid24>
      </Grid24>
    </FormWrapperTitle>
  );
}

export default IdentificacaoFases;
