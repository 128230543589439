import { makeStyles, Button } from '@material-ui/core';
import styled from 'styled-components';
import {
  PaperUI,
  PeriodSelector as PeriodSelectorUi,
} from '../../components';

export default makeStyles({
  div__check: {
    marginLeft: '9px',
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      marginLeft: '9px',
    },
  },
  div__checkFooter: {
    marginLeft: '0px',
    marginTop: '-70PX',
    '& .MuiFormControlLabel-label': {
      fontSize: '12px',
      marginLeft: '0px',
      color: '#707070',
    },
  },
  div__cards: {
    marginTop: '6px',
  },
  div__buttons: {
    width: '152px',
    height: '35px',
    marginRight: '22px',
  },
  div__align: {
    display: 'flex',
    marginTop: '32px',
    alignSelf: 'self-end',
  },

  div__consolidacao: {

    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    marginTop: '8.8px',
    width: '495px',

    '& p': {

      marginTop: '8.8px',
    },
  },
});

export const Container = styled(PaperUI)`
  padding: 22px;
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .filters {
    display: flex;
    flex-direction: column;
    width: 589px;
  }

  .additional-info {
    display: flex;
    flex-direction: column;
  }
`;

export const PeriodSelector = styled(PeriodSelectorUi)`
  margin-top: 15px;
`;

export const ButtonImprimir = styled(Button)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
  margin-top: 12px;

  &.MuiButtonBase-root {
    width: 73px;
    padding: 4px 0;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: none;

    color: #000000;

    &.Mui-disabled {
      opacity: 0.64;
    }
  }
`;

export const TableHeader = styled.div`
  display: block;
  margin-left: 15px;
  margin-bottom: 13px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
`;

export const ResumoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 
 .title {
    display: block;
    margin-bottom: 9px;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  > div {
    display: flex;
    justify-content: space-around;

    > div + div {
      margin-left: 8px;
    }
  }
`;

export const CardTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

   box-sizing: border-box;
  width: 184px;
  height: 84px;
  border-radius: 10px;

  background-color: ${props => props.color};

  p {
    margin: 1px 0px 1.5px 0px;
    font-family: Roboto;
    font-size: 11px;
    text-align: left;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    
    color: #ffffff;
  }

  p:first-child {
    margin: 0 30px 4px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 600;
    text-align: left;
  }
`;


export const HrColored = styled.hr`
  border: none;
    height: 1px;
    margin: 1px;
    color: ${props => props.color};
    background-color: ${props => props.color};
`;

export const CardBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  box-sizing: border-box;
  width: 573px;
  height: 52px;
  border-radius: 5px;

  background-color: ${props => props.color};

  p {
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
  }

  p:first-child {
    padding-top: 5px;
    margin-top: 8px;
  }

  p:last-child {
    padding-top: 11px;
    padding-bottom: 13px;
  }
`;

export const CheckFooter = styled.div`
  display: flex;
  justify-content: start;
  align-items: flex-start;
  margin-top: 37px;
`;
