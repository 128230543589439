import { makeStyles } from '@material-ui/core';

export default makeStyles({
  grid__footer: {
    padding: 'initial',
    marginTop: '22px',
  },

  areaSize: {
    '& textarea': {
      minHeight: '80px',
      width: '100% !important ',
    },
  },

  label: {
    fontSize: '0.75rem',
    color: '#707070',
    width: '120px',
    marginRight: '10px',
  },

  inputField: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
  },
});
