import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles({
  V: {
    '& i': {
      display: 'flex',
      color: '#35c399',
    },
  },
  M: {
    '& i': {
      display: 'flex',
      color: '#e91b1b',
    },
  },
  L: {
    '& i': {
      display: 'flex',
      color: '#ffa000',
    },
  },
});
