import React, { useCallback } from 'react';
import WrapperSubTitleForm from '../../../../../../UI/WrapperSubTitleForm/WrapperSubTitleForm';
import Grid24 from '../../../../../../UI/Grid24/Grid24';
import Radio from '../../../../../../UI/Field/Radio/RadioField';
import { Container, GroupDefinitions } from '../../../BaseCalculo/Honorarios/styles';
import {
  useCrudClienteDispatch,
  useCrudClienteSelector,
} from '../../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import {
  onChangeControls,
  setFaixasPrazoExpiracaoAction,
  setStoreValueAction
} from '../../../../../../../store/Cliente/V2/Crud/crudCliente.store';
import NumberInput from '../../../../../../UI/Field/Number/NumberInput';
import { getRequiredLabel } from '../../../../../../../helpers/utils/utils';
import CheckboxField from '../../../../../../UI/Field/Checkbox/CheckboxField';
import { CHECKBOX_AVISAR_PROCESSO_EXPIRADO_CONSUMIDOR_LABEL } from '../ProcessamentoCheckBox/processamentoCheckbox.constants';
import useUtilStyles from '../../../../../../../helpers/styles/utils.styles';
import PrazoExpiracao from '../../../BaseCalculo/PrazoExpiracao/PrazoExpiracao';
import {getDefaultFieldsFaixa} from "../../../../../../../helpers/factory/faixasPrazoExpiracao.factory";

function PrazoExpiracaoProcessos({
  onChange,
  onChangeChecked,
  onFocus,
  disabled,
  isEditMode,
}) {
  const dispatch = useCrudClienteDispatch();
  const utilStyles = useUtilStyles();

  const controlarExpiracaoProcessoDefinition = useCrudClienteSelector(states => states.controlarExpiracaoProcessoDefinition);
  const diasAvisoExpiracaoProcesso = useCrudClienteSelector(states => states.controls.diasAvisoExpiracaoProcesso.copy());
  const avisarProcessoExpirado = useCrudClienteSelector(states => states.controls.avisarProcessoExpirado.copy());


  const onChangeControlarExpiracaoProcessoHandler = useCallback((e) => {
    const { value } = e.target;
    dispatch(setStoreValueAction('controlarExpiracaoProcessoDefinition', value));
    if (value === 'cliente') {
      dispatch(onChangeControls('controlarExpiracaoProcesso', true));
    } else {
      dispatch(onChangeControls('controlarExpiracaoProcesso', false));
      dispatch(onChangeControls('diasAvisoExpiracaoProcesso', 30));
      dispatch(onChangeControls('avisarProcessoExpirado', false));
      dispatch(setFaixasPrazoExpiracaoAction([getDefaultFieldsFaixa()]));
    }
  }, [dispatch]);


  return (
    <>
      <WrapperSubTitleForm title="Prazo de Expiração dos Processos">
        <Container container isFormContainer>
          <GroupDefinitions
            aria-label="prazos-expiracao-definicoes"
            value={controlarExpiracaoProcessoDefinition}
            onChange={onChangeControlarExpiracaoProcessoHandler}
          >
            <Radio label="Não controlar Data de Expiração" value="abe" disabled={disabled} />
            <Radio label="Controlar Data de Expiração" value="cliente" disabled={disabled} />
          </GroupDefinitions>
        </Container>
        {controlarExpiracaoProcessoDefinition === 'cliente' ? (
          <>
            <Grid24 xs={24} sm={24} isFormContainer container className={utilStyles.mt20}>
              <Grid24 xs={24} sm={6}>
                <NumberInput
                  isForm
                  fullWidth
                  sideText="(dias)"
                  label={getRequiredLabel('Dias Aviso Expiração Processo', !disabled)}
                  name={diasAvisoExpiracaoProcesso.name}
                  value={diasAvisoExpiracaoProcesso.value}
                  error={diasAvisoExpiracaoProcesso.error}
                  errorMessage={diasAvisoExpiracaoProcesso.errorMessage}
                  onChange={onChange}
                  onFocus={onFocus}
                  disabled={disabled || controlarExpiracaoProcessoDefinition === 'abe'}
                />
              </Grid24>
              <Grid24 xs={24} sm={5} className={utilStyles.ml40mt11}>
                <CheckboxField
                  label={CHECKBOX_AVISAR_PROCESSO_EXPIRADO_CONSUMIDOR_LABEL}
                  name={avisarProcessoExpirado.name}
                  checked={avisarProcessoExpirado.value}
                  onChange={onChangeChecked}
                  disabled={disabled}
                />
              </Grid24>
            </Grid24>
            <Grid24 container isFormContainer>
              <PrazoExpiracao controlarDefinition={controlarExpiracaoProcessoDefinition} isInsertMode={isEditMode} />
            </Grid24>
          </>
        ) : null}

      </WrapperSubTitleForm>
    </>
  );
}

export default PrazoExpiracaoProcessos;
