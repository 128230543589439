import { buildSettingsTable, buildSnackbar } from '../../../helpers/factory/global.factory';
import { getDate15daysAgo, getDateNow, toAmericanDate } from '../../../helpers/utils/utils';
import FieldModel from '../../../models/FieldModel';
import {
  ON_CHANGE_REQUEST_FIELDS_ACORDOS,
  ON_FOCUS_REQUEST_FIELDS_ACORDOS,
  ON_UPDATE_REQUEST_FIELDS, RESET_SCREEN,
  SET_CACHE_REQUEST_FIELDS,
  SET_DATAS_CAMPOS_OBRIGATORIOS,
  SET_PAGE,
  SET_ROWS_PER_PAGE,
  SET_SNACKBAR_ACORDO,
  SET_STORE_VALUE,
  SET_TABLE_INFO,
} from './acordosAs.constants';

function buildRequestFields() {
  return {
    periodoAcordoInicial: new FieldModel({
      name: 'periodoAcordoInicial',
      value: getDate15daysAgo(),
    }),
    periodoAcordoFinal: new FieldModel({
      name: 'periodoAcordoFinal',
      value: getDateNow(),
    }),
    nroProcesso: new FieldModel({
      name: 'nroProcesso',
    }),
    nomeDevedor: new FieldModel({
      name: 'nomeDevedor',
    }),
    nomeCliente: new FieldModel({
      name: 'nomeCliente',
    }),
    idTipoAcordo: new FieldModel({
      name: 'idTipoAcordo',
    }),
  };
}

function initiateStates() {
  return {
    requestFields: buildRequestFields(),
    settingsTable: buildSettingsTable(),
    snackbarAcordosAs: buildSnackbar(),
    cacheRequestFields: null,
    loading: false,
    openPesquisaAvancada: false,
  };
}

export const onChangeRequestFieldsAcordoAction = (name, value) => ({
  type: ON_CHANGE_REQUEST_FIELDS_ACORDOS,
  name,
  value,
});

export const setDatasCamposObrigatoriosAcordoAction = name => ({
  type: SET_DATAS_CAMPOS_OBRIGATORIOS,
  name,
});

export const onFocusRequestFieldsAcordosAction = name => ({
  type: ON_FOCUS_REQUEST_FIELDS_ACORDOS,
  name,
});

export const setTableInfoAction = (content, page, rowsPerPage, totalElements) => ({
  type: SET_TABLE_INFO,
  tableInfo: buildSettingsTable(content, page, rowsPerPage, totalElements),
});

export const setStoreValuesAction = (name, value) => ({
  type: SET_STORE_VALUE,
  name,
  value,
});
export const onUpdateRequestFieldsAction = requestFields => ({
  type: ON_UPDATE_REQUEST_FIELDS,
  requestFields,
});

export const setSnackbarAcordoASAction = (open, variant, message) => ({
  type: SET_SNACKBAR_ACORDO,
  open,
  variant,
  message,
});

export const setCacheRequestFieldsAction = () => ({
  type: SET_CACHE_REQUEST_FIELDS,
});

export const setPageAction = page => ({
  type: SET_PAGE,
  page,
});

export const setRowsPerPageAndPageAction = (rowsPerPage, page) => ({
  type: SET_ROWS_PER_PAGE,
  rowsPerPage,
  page,
});

export const clearPesquisaAcordosASAction = () => ({
  type: RESET_SCREEN,
});


function onChangeRequestFieldsAcordoHandler(states, actions) {
  const { name, value } = actions;
  const model = states.requestFields[name].onChange(value);

  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [name]: model,
    },
  };
}

function onFocusRequestFieldsAcordosHandler(states, actions) {
  const { name } = actions;
  const model = states.requestFields[name].onFocus();

  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [name]: model,
    },
  };
}

function setDatasCamposObrigatoriosAcordoHandler(states, actions) {
  const modelData = states.requestFields[actions.name];

  modelData.error = true;
  modelData.errorMessage = 'Campo Obrigatório';

  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [actions.name]: modelData,
    },
  };
}

function changeTableInfoHandler(states, actions) {
  return {
    ...states,
    settingsTable: { ...actions.tableInfo },
  };
}

function setStoreValueHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    [name]: value,
  };
}

function onUpdateRequestFieldsHandler(states, actions) {
  const { requestFields } = actions;
  return {
    ...states,
    requestFields,
  };
}

function setSnackbarValueHandler(states, actions) {
  const snackbar = { ...states.snackbar, open: actions.open };
  if (actions.variant || actions.message) {
    snackbar.variant = actions.variant;
    snackbar.message = actions.message;
  }
  return {
    ...states,
    snackbarAcordosAs: snackbar,
  };
}

function setCacheRequestFieldsHandler(states) {
  const periodoAcordoInicial = states.requestFields.periodoAcordoInicial.getValueNotEmpty();
  const periodoAcordoFinal = states.requestFields.periodoAcordoFinal.getValueNotEmpty();
  const nroProcesso = states.requestFields.nroProcesso.getValueNotEmpty();
  const nomeDevedor = states.requestFields.nomeDevedor.getValueNotEmpty();
  const nomeCliente = states.requestFields.nomeCliente.getValueNotEmpty();
  const idTipoAcordo = states.requestFields.idTipoAcordo.getValueNotEmpty();

  return {
    ...states,
    cacheRequestFields: {
      periodoAcordoInicial: periodoAcordoInicial ? toAmericanDate(periodoAcordoInicial) : periodoAcordoInicial,
      periodoAcordoFinal: periodoAcordoFinal ? toAmericanDate(periodoAcordoFinal) : periodoAcordoFinal,
      nroProcesso,
      nomeDevedor,
      nomeCliente,
      idTipoAcordo,
    },
  };
}

function onChangePageHandler(states, actions) {
  return {
    ...states,
    settingsTable: {
      ...states.settingsTable,
      page: actions.page,
    },
  };
}

function onChangeRowsPerPageAndPageHandler(states, actions) {
  return {
    ...states,
    settingsTable: {
      ...states.settingsTable,
      rowsPerPage: actions.rowsPerPage,
      page: actions.page,
    },
  };
}

function resetScreenHandler() {
  return initiateStates();
}

export default (states = initiateStates(), actions) => {
  switch (actions.type) {
    case ON_CHANGE_REQUEST_FIELDS_ACORDOS:
      return onChangeRequestFieldsAcordoHandler(states, actions);
    case SET_DATAS_CAMPOS_OBRIGATORIOS:
      return setDatasCamposObrigatoriosAcordoHandler(states, actions);
    case ON_FOCUS_REQUEST_FIELDS_ACORDOS:
      return onFocusRequestFieldsAcordosHandler(states, actions);
    case SET_TABLE_INFO:
      return changeTableInfoHandler(states, actions);
    case SET_STORE_VALUE:
      return setStoreValueHandler(states, actions);
    case ON_UPDATE_REQUEST_FIELDS:
      return onUpdateRequestFieldsHandler(states, actions);
    case SET_SNACKBAR_ACORDO:
      return setSnackbarValueHandler(states, actions);
    case SET_CACHE_REQUEST_FIELDS:
      return setCacheRequestFieldsHandler(states);
    case SET_PAGE:
      return onChangePageHandler(states, actions);
    case SET_ROWS_PER_PAGE:
      return onChangeRowsPerPageAndPageHandler(states, actions);
    case RESET_SCREEN:
      return resetScreenHandler();
    default:
      return states;
  }
};
