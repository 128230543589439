import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  acompanhamentoItem: {
    marginLeft: '10px',
  },
  checkbox: {
    marginTop: '16px',
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
    },
  },
  button: {
    backgroundColor: '#ff1381',
    color: 'white',
    width: '44px',
    height: '44px',
    '&:hover': {
      backgroundColor: '#ff1381',
      opacity: '.7',
    },
  },
});
