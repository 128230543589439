import { TableCell, TableRow } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useCCClienteDispatch, useCCClienteSelector } from '../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import OrdenacaoColuna from '../UI/Tabela/Ordenacao/OrdenacaoColuna';
import { setSimpleValuePagamentosAction } from '../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { findPagamentosAction } from '../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';

function TableHeaderPagamentosComponent() {
  const dispatch = useCCClienteDispatch();

  const columnOrdenacao = useCCClienteSelector(states => (states.pagamentos.columnOrdenacao));
  const arrowDirection = useCCClienteSelector(states => (states.pagamentos.arrowDirection));
  const pagamentos = useCCClienteSelector(states => ([...states.pagamentos.pagamentos]));

  const setColumnOrdenacao = useCallback((column) => {
    dispatch(setSimpleValuePagamentosAction('columnOrdenacao', column));
  }, [dispatch]);

  const setArrowDirection = useCallback((internalReturn) => {
    dispatch(setSimpleValuePagamentosAction('arrowDirection', internalReturn));
  }, [dispatch]);

  const onClickColumnOrdenar = useCallback(() => {
    if (pagamentos.length > 0) {
      dispatch(setSimpleValuePagamentosAction('page', 0));
      dispatch(findPagamentosAction());
    }
  }, [dispatch, pagamentos]);

  return (
    <TableRow>
      <TableCell height={36} width={100}>
        Prestado
      </TableCell>
      <TableCell height={36} width={160}>
        <OrdenacaoColuna
          columnLabel="Data"
          idColumn="data"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickColumnOrdenar()}
        />
      </TableCell>
      <TableCell height={36} width={100}>
        Acordo
      </TableCell>
      <TableCell height={36} width={160}>
        Pagamento
      </TableCell>
      <TableCell height={36} width={120}>
        Acerto
      </TableCell>
      <TableCell height={36} width={110}>
        Valor
      </TableCell>
      <TableCell height={36} width={100}>
        Ação
      </TableCell>
    </TableRow>
  );
}
export const TableHeaderPagamentos = TableHeaderPagamentosComponent;
