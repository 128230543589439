import React, { useCallback, useEffect, useState } from 'react';
import { MdPrint as MdPrintIcon } from 'react-icons/md';
import {
  FaPlus,
} from 'react-icons/fa';
import useStyle, {
  ActionButton,
  ActionButtonGroup,
  ActionContainer,
  ActionContentContainer,
  ButtonImprimir, ButtonNovo, HeaderCliente,
} from './LancamentosNaoPrestadosStyle';
import {
  getNewPage,
} from '../../../../../../helpers';
import { TableDefault } from '../../../../../../components';
import { TableBodyLancamentosNaoPrestados } from '../../../../../../components/TabelaBody/V2/TableBodyLancamentosNaoPrestados';
import SwitchUI from '../../../../../../components/UI/Switch/SwitchUI';
import { CONTA_CLIENTE, EDITAR, NOVO } from './LancamentosNaoPrestados.constants';
import DialogLancamentosNaoPrestados from './DialogNovo/DialogLancamentosNaoPrestados';
import {
  useCCClienteDispatch,
  useCCClienteSelector,
} from '../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import {
  atualizarStatusLancamentoNaoPrestadoAction,
  deleteLancamentoNaoPrestadoClienteSelecionadoAction,
  downloadLancamentosNaoPrestadosAction,
  findEditarLancamentoAction,
  mudaStatusLancamentosNaoPrestadosClienteSelecionadoAction,
  pesquisarLancamentosNaoPrestadosClienteSelecionadoAction,
} from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import { setNaoPrestadosFormToSaveAction, setSimpleValueNaoPrestadosAction } from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { TableHeaderlancamentosNaoPrestados } from '../../../../../../components/TabelaHeader/V2/TableHeaderLancamentosNaoPrestados';
import { actionProps, LancamentosNaoPrestadosController } from './LancamentosNaoPrestadosController';
import TooltipUI from '../../../../../../components/UI/Tooltip/Tooltip';
import OptionDialogNew from '../../../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';
import DialogNew from '../../../../../../layouts/FormDialog/DialogNew';

function LancamentosNaoPrestadosComponent({
  permissoes, atualizarPaginaAfterAction,
}) {
  const dispatch = useCCClienteDispatch();
  const style = useStyle();

  const clienteSelecionado = useCCClienteSelector(states => (states.common.clienteSelecionado));
  const lancamentosNaoPrestados = useCCClienteSelector(states => ([...states.naoPrestados.lancamentosNaoPrestados]));
  const page = useCCClienteSelector(states => (states.naoPrestados.page));
  const rowsPerPage = useCCClienteSelector(states => (states.naoPrestados.rowsPerPage));
  const totalElements = useCCClienteSelector(states => (states.naoPrestados.totalElements));
  const columnOrdenacao = useCCClienteSelector(states => (states.naoPrestados.columnOrdenacao));
  const detalhes = useCCClienteSelector(states => states.detalhes.detalhesCliente);
  const openDialogAtualizarStatusLancamento = useCCClienteSelector(states => (states.naoPrestados.openDialogAtualizarStatusLancamento));
  const openDialogDeleteLancamento = useCCClienteSelector(states => (states.naoPrestados.openDialogDeleteLancamento));
  const idContaCorrenteClienteTable = useCCClienteSelector(states => (states.naoPrestados.idContaCorrenteClienteTable));
  const statusContaCorrenteClienteTable = useCCClienteSelector(states => (states.naoPrestados.statusContaCorrenteClienteTable));
  const openDialogButtonNovo = useCCClienteSelector(states => (states.naoPrestados.openDialogButtonNovo));
  const exibirApenasBloqueados = useCCClienteSelector(states => (states.naoPrestados.exibirApenasBloqueados));
  const [disabled, setDisabled] = useState(false); // Desabilitar campos da tela...
  const [routesName, setRouteNames] = useState([CONTA_CLIENTE]);
  const [actionsButtons, setActionsButtons] = useState(LancamentosNaoPrestadosController.makeActionsButtons());
  const [habilitaBloqueio, setHabilitaBloqueio] = useState(false);
  const [habilitaDesbloqueio, setHabilitaDesbloqueio] = useState(false);

  useEffect(() => {
    setDisabled(clienteSelecionado.idCliente === null);
  }, [clienteSelecionado]);

  useEffect(() => {
    setHabilitaDesbloqueio(false);
    setHabilitaBloqueio(false);
    lancamentosNaoPrestados.forEach((lancamento) => {
      if (lancamento.statusContaCorrenteCliente === 'BLOQUEADO') {
        setHabilitaDesbloqueio(true);
      } else {
        setHabilitaBloqueio(true);
      }
    });
  }, [lancamentosNaoPrestados]);

  const onClickButtonNovo = useCallback(() => {
    dispatch(setSimpleValueNaoPrestadosAction('idContaCorrenteClienteTable', null));
    setRouteNames([NOVO]);
    dispatch(setSimpleValueNaoPrestadosAction('openDialogButtonNovo', true));
  }, [dispatch]);

  const onClose = useCallback(() => {
    dispatch(setNaoPrestadosFormToSaveAction(null));
    dispatch(setSimpleValueNaoPrestadosAction('idContaCorrenteClienteTable', null));
    dispatch(setSimpleValueNaoPrestadosAction('openDialogButtonNovo', false));
  }, [dispatch]);

  const onEditLancamentoHandler = useCallback((idContaCorrenteCliente) => {
    setRouteNames([EDITAR]);
    dispatch(setSimpleValueNaoPrestadosAction('idContaCorrenteClienteTable', idContaCorrenteCliente));
    dispatch(findEditarLancamentoAction(idContaCorrenteCliente));
  }, [dispatch]);

  function handleOnClickCancel() {
    dispatch(setSimpleValueNaoPrestadosAction('idContaCorrenteClienteTable', null));
    dispatch(setSimpleValueNaoPrestadosAction('openDialogDeleteLancamento', false));
  }

  function handleOnClickCancelAtualizarStatus() {
    dispatch(setSimpleValueNaoPrestadosAction('idContaCorrenteClienteTable', null));
    dispatch(setSimpleValueNaoPrestadosAction('statusContaCorrenteClienteTable', null));
    dispatch(setSimpleValueNaoPrestadosAction('openDialogAtualizarStatusLancamento', false));
  }

  /* function handleOpenAtualizarLancamento(idContaCorrenteCliente, statusContaCorrente) {
    setSelectedIdContaCorrenteCliente(idContaCorrenteCliente);
    setSelectedStatusContaCorrenteCliente(statusContaCorrente);
    setOpenDialogAtualizarStatusLancamento(true);
  } */

  const onClickExibirApenasDesloqueados = useCallback((e) => {
    dispatch(setSimpleValueNaoPrestadosAction('page', 0));
    const { checked } = e.target;
    dispatch(setSimpleValueNaoPrestadosAction('exibirApenasBloqueados', checked));
  }, [dispatch]);

  const handleChangePage = useCallback((e, newPage) => {
    dispatch(setSimpleValueNaoPrestadosAction('page', Number(newPage)));
    // dispatch(pesquisarLancamentosNaoPrestadosClienteSelecionadoAction(
    //   fields.statusContaCorrenteCliente, newPage, rowsPerPage, columnOrdenacao,
    // ));
  }, [dispatch]);

  const handleRowsPerPageChange = useCallback((e) => {
    const size = e.target.value;
    const newPage = getNewPage(rowsPerPage, page, size);
    // // Se a tabela nao tiver vazia, pesquisar!
    // if (lancamentosNaoPrestados.length > 0) {
    //   dispatch(pesquisarLancamentosNaoPrestadosClienteSelecionadoAction(
    //     fields.statusContaCorrenteCliente, newPage, size, columnOrdenacao,
    //   ));
    // }
    dispatch(setSimpleValueNaoPrestadosAction('page', Number(newPage)));
    dispatch(setSimpleValueNaoPrestadosAction('rowsPerPage', Number(size)));
  }, [dispatch, rowsPerPage, page]);

  const handleDeleteLancamento = useCallback(() => {
    dispatch(deleteLancamentoNaoPrestadoClienteSelecionadoAction(idContaCorrenteClienteTable));
  }, [dispatch, idContaCorrenteClienteTable]);

  async function handleAtualizarStatus() {
    dispatch(atualizarStatusLancamentoNaoPrestadoAction(idContaCorrenteClienteTable, statusContaCorrenteClienteTable));
  }

  const handlePrintLancamentoNaoPrestado = useCallback(() => {
    dispatch(downloadLancamentosNaoPrestadosAction(exibirApenasBloqueados));
  }, [dispatch, exibirApenasBloqueados]);

  useEffect(() => {
    if (clienteSelecionado.idCliente) {
      dispatch(pesquisarLancamentosNaoPrestadosClienteSelecionadoAction(
        exibirApenasBloqueados, page, rowsPerPage, columnOrdenacao,
      ));
    }
  }, [dispatch, clienteSelecionado, page, rowsPerPage, columnOrdenacao, exibirApenasBloqueados]);

  function runActionButtonByTag(tag) {
    return () => {
      setActionsButtons(LancamentosNaoPrestadosController.changeActionButton(actionsButtons, tag));
    };
  }

  const onSubmitHandler = useCallback((idCliente, tipos, tag) => {
    dispatch(mudaStatusLancamentosNaoPrestadosClienteSelecionadoAction(idCliente, tag === 'bloquear', tipos));
    setActionsButtons(LancamentosNaoPrestadosController.makeActionsButtons());
  }, [dispatch]);

  function disableButton(actions) {
    return (!permissoes?.efetuarLancamentos || clienteSelecionado?.idCliente === null)
      || ((actions.tag === actionProps.desbloquear.tag && !habilitaDesbloqueio)
        || (actions.tag === actionProps.bloquear.tag && !habilitaBloqueio));
  }

  return (
    <>
      <DialogNew
        open={openDialogButtonNovo}
        text={routesName}
        className={style.dialog}
        onClose={onClose}
      >
        {openDialogButtonNovo && (
          <DialogLancamentosNaoPrestados
            onClose={onClose}
            idCliente={clienteSelecionado?.idCliente}
            nome={detalhes.nome}
            atualizarPaginaAfterAction={atualizarPaginaAfterAction}
          />
        )}
      </DialogNew>
      <OptionDialogNew
        open={openDialogDeleteLancamento}
        variant="warning"
        confirmLabel="Sim"
        cancelLabel="Não"
        onClickCancel={handleOnClickCancel}
        onClickConfirm={handleDeleteLancamento}
        text=" Deseja excluir o Lançamento da Conta Corrente do Cliente?"
      />
      <OptionDialogNew
        open={openDialogAtualizarStatusLancamento}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={handleOnClickCancelAtualizarStatus}
        onClickConfirm={handleAtualizarStatus}
      >
        <div style={{ fontSize: '16px' }}>
          Confirma o
          {statusContaCorrenteClienteTable === 'BLOQUEADO' ? ' bloqueio ' : ' desbloqueio '}
          do Lançamento?
        </div>
      </OptionDialogNew>
      <div className={style.div__root}>
        <div className={style.div__header}>
          <div>
            <ButtonImprimir
              disabled={lancamentosNaoPrestados?.length === 0}
              onClick={handlePrintLancamentoNaoPrestado}
            >
              <MdPrintIcon size={16} />
              <span>Imprimir</span>
            </ButtonImprimir>
          </div>
          <div className={style.div__nomeCliente}>
            <HeaderCliente>
              <b>
                Cliente:
                {' '}
              </b>
              {clienteSelecionado?.idCliente}
              {clienteSelecionado?.idCliente !== null ? (' - ') : (' ')}
              {detalhes.nome}
            </HeaderCliente>
          </div>
          <div>
            <ButtonNovo
              disabled={!permissoes?.efetuarLancamentos || clienteSelecionado?.idCliente === null}
              onClick={onClickButtonNovo}
            >

              <FaPlus />
              <span>Novo</span>
            </ButtonNovo>
          </div>
        </div>
        <div>
          <ActionContainer>
            <ActionButtonGroup>
              {actionsButtons.map(actions => (
                <div key={actions.key}>
                  <TooltipUI title={actions.label} placement="bottom">
                    <div>
                      <ActionButton
                        active={actions.active.toString()}
                        onClick={runActionButtonByTag(actions.tag)}
                        disabled={disableButton(actions)}
                      >
                        <actions.Icon size={16} style={actions.styles} />
                      </ActionButton>
                    </div>
                  </TooltipUI>
                </div>
              ))}
            </ActionButtonGroup>
            {actionsButtons.filter(action => action.active === true).map(action => (
              <ActionContentContainer key={action.tag}>
                <action.Content
                  onSubmitHandler={onSubmitHandler}
                />
              </ActionContentContainer>
            ))}
          </ActionContainer>
        </div>

        <div className={style.div__table}>
          <TableDefault
            columnsHead={(
              <TableHeaderlancamentosNaoPrestados />
            )}
            page={page}
            rowsPerPage={rowsPerPage}
            totalElements={totalElements}
            totalElementsOnPage={lancamentosNaoPrestados?.length}
            totalColumns={7}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            emptyRowsHeight={36}
            rowsPerPageOptions={[10, 15, 20]}
          >
            {lancamentosNaoPrestados?.map(lancamento => (
              <TableBodyLancamentosNaoPrestados
                disabledPermissao={!permissoes?.efetuarLancamentos}
                key={lancamento.idContaCorrenteCliente}
                lancamento={lancamento}
                onEditLancamentoHandler={onEditLancamentoHandler}
                cannotAcoesIcones={!permissoes?.efetuarLancamentos}
              />
            ))}
          </TableDefault>
        </div>
        <div className={style.div__switch}>
          <SwitchUI
            label="Exibir apenas lançamentos Bloqueados"
            name="statusContaCorrenteCliente"
            checked={exibirApenasBloqueados}
            onChange={onClickExibirApenasDesloqueados}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
}

export const LancamentosNaoPrestados = LancamentosNaoPrestadosComponent;
