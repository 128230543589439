import React, { memo, useMemo, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { TablePagination } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import PaginationAction from '../../../UI/Tabela/PaginadaSimples/Pagination/PaginationActions';
import useStyles from './tabelaPesquisaProcesso.styles';
import TooltipUI from '../../../UI/Tooltip/Tooltip';
import CheckboxField from '../../../UI/Field/Checkbox/CheckboxField';

function getAlignOption(row, column) {
  let alignOption = row.values[column.id] ? column.alignCell : 'center';
  if (!row.values[column.id] && column.showTip) {
    alignOption = 'center';
  }
  return alignOption;
}

function Row({
  row, columns, styles, columnLength,
}) {
  const [open, setOpen] = useState(false);

  const getContent = (value, column) => {
    if (column.checkbox) {
      return (
        <CheckboxField
          name={String(value.name)}
          checked={value.checked}
          onChange={value.changeSelectId}
        />
      );
    }

    if (column.showTip) {
      return (
        <TooltipUI title={value} placement="bottom" interactive>
          <pre
            className={styles.overFlowText}
            style={{ maxWidth: column.maxWidth - 16 }}
          >
            {column.format && typeof value === 'number' ? column.format(value) : value}
          </pre>
        </TooltipUI>
      );
    }
    if (column.hiddenFullLine) {
      return (
        <pre
          className={styles.overFlowText}
          style={{ maxWidth: column.maxWidth - 16 }}
        >
          {column.format && typeof value === 'number' ? column.format(value) : value}
        </pre>
      );
    }
    return <pre>{column.format && typeof value === 'number' ? column.format(value) : value}</pre>;
  };

  return (
    <>
      <TableRow className={styles.tableRowRoot}>
        {columns.map((column) => {
          const value = row.values[column.id];
          const alignOption = getAlignOption(row, column);
          return (
            <TableCell
              key={column.id}
              style={{
                maxWidth: column.maxWidth,
                minWidth: column.minWidth,
                width: column.width,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: alignOption,
                }}
              >
                {getContent(value, column)}
              </div>
            </TableCell>
          );
        })}
        <TableCell style={{ borderTop: '1px solid #e0e0e0' }}>
          <IconButton size="small" onClick={() => setOpen(!open)} style={{ color: '#034ddc' }}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={columnLength} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <p style={{ flex: 1, marginLeft: '2%' }}>
                  <b>Quantidade de Antecedentes: </b>
                  {row.values.qtdeAntecedentes}
                </p>
                <p style={{ flex: 1, width: '25%' }}>
                  <b>Dias na fase atual: </b>
                  {row.values.qtdeDiasFaseAtual}
                </p>
                <p style={{ flex: 1, width: '25%' }}>
                  <b>Entrada na fase atual: </b>
                  {row.values.dataEntradaFase}
                </p>
                <p style={{ flex: 1, width: '25%' }}>
                  <b>Data Expiração: </b>
                  {row.values.dataExpiracao}
                </p>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const layoutConstants = {
  LABEL_ROWS_PER_PAGE: 'Linhas por página',
  PAGINA_ANTERIOR: 'Página Anterior',
  PROXIMA_PAGINA: 'Próxima Página',
};

function TabelaPesquisaProcessos({
  columns = [],
  rows = [],
  size = 0,
  rowsPerPageOptions = [15, 20, 25],
  page = 0,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
}) {
  const styles = useStyles();

  const columnLength = useMemo(() => columns.length + 1, [columns.length]);

  const buildEmptyRows = () => {
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

    const content = [];
    for (let i = 0; i < emptyRows; i += 1) {
      content.push(
        <TableRow key={i} style={{ height: 31 }}>
          <TableCell colSpan={columnLength}>
            <p />
          </TableCell>
        </TableRow>,
      );
    }
    return content;
  };

  return (
    <>
      <Paper className={styles.wrapper} style={{ overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  id={column.id}
                  key={column.id}
                  style={{
                    maxWidth: column.maxWidth,
                    minWidth: column.minWidth,
                    width: column.width,
                    textAlign: column.align,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <Row
                key={row.id}
                row={row}
                columns={columns}
                styles={styles}
                columnLength={columnLength}
              />
            ))}
            {buildEmptyRows()}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination
        style={{ width: '100%' }}
        rowsPerPageOptions={rowsPerPageOptions}
        colSpan={columns.length}
        component="div"
        count={size}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={layoutConstants.LABEL_ROWS_PER_PAGE}
        backIconButtonProps={{
          'aria-label': layoutConstants.PAGINA_ANTERIOR,
        }}
        nextIconButtonProps={{
          'aria-label': layoutConstants.PROXIMA_PAGINA,
        }}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        ActionsComponent={PaginationAction}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      />
    </>
  );
}

export default memo(TabelaPesquisaProcessos);
