import { TableCell, TableRow } from '@material-ui/core';
import React, { useCallback } from 'react';
import OrdenacaoColuna from '../../UI/Tabela/Ordenacao/OrdenacaoColuna';
import { TableUtils } from '../../../helpers';
import {
  useCCClienteDispatch,
  useCCClienteSelector,
} from '../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import {
  setClienteSelecionadoAction,
  setSimpleValuesPesquisaAction,
} from '../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';

function TableHeaderContaCorrenteClienteComponent() {
  const dispatch = useCCClienteDispatch();
  const idClienteSelecionado = useCCClienteSelector(states => (states.common.clienteSelecionado.idCliente));
  const allClientes = useCCClienteSelector(states => (states.pesquisa.allClientes));
  const rowsPerPage = useCCClienteSelector(states => (states.pesquisa.rowsPerPage));
  const arrowDirection = useCCClienteSelector(states => (states.pesquisa.arrowDirection));
  const columnOrdenacao = useCCClienteSelector(states => (states.pesquisa.columnOrdenacao));


  const setColumnOrdenacao = useCallback((column) => {
    dispatch(setSimpleValuesPesquisaAction('columnOrdenacao', column));
  }, [dispatch]);

  const setArrowDirection = useCallback((internalReturn) => {
    dispatch(setSimpleValuesPesquisaAction('arrowDirection', internalReturn));
  }, [dispatch]);

  const onClickColumnOrdenar = useCallback((arrowDirectionAux, columnOrdenacaoAux) => {
    if (allClientes.length > 0) {
      const contasCorrentesOrdenadas = TableUtils.ordenarTabela(arrowDirectionAux, allClientes, columnOrdenacaoAux.element);
      const contasCorrentesPaginadas = TableUtils.paginar(contasCorrentesOrdenadas, rowsPerPage, 0);
      dispatch(setSimpleValuesPesquisaAction('page', 0));
      dispatch(setSimpleValuesPesquisaAction('allClientes', contasCorrentesOrdenadas));

      dispatch(setSimpleValuesPesquisaAction('tableView', contasCorrentesPaginadas));

      if (contasCorrentesPaginadas.find(conta => conta.idCliente === idClienteSelecionado) === undefined) {
        const primeiraConta = contasCorrentesPaginadas[0];
        dispatch(setClienteSelecionadoAction(primeiraConta.idCliente, primeiraConta.statusPrestacaoContas));
      }
    }
  }, [dispatch, allClientes, rowsPerPage, idClienteSelecionado]);

  return (
    <TableRow>
      <TableCell height={36} width={30}>
        <span />
      </TableCell>
      <TableCell height={36} width={53}>
        <OrdenacaoColuna
          columnLabel="Código"
          idColumn="idCliente"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />
      </TableCell>
      <TableCell height={36} width={160}>
        <OrdenacaoColuna
          columnLabel="Nome"
          idColumn="nomeCliente"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />
      </TableCell>
      <TableCell height={36} width={53}>
        <OrdenacaoColuna
          columnLabel="Tipo da Prestação"
          idColumn="tipoPrestacao"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />
      </TableCell>
      <TableCell height={36} width={60}>
        <OrdenacaoColuna
          columnLabel="Valor do Repasse"
          idColumn="valorRepasse"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />
      </TableCell>
      <TableCell height={36} width={45}>
        <span />
      </TableCell>
    </TableRow>
  );
}


export const TableHeaderContaCorrenteCliente = React.memo(TableHeaderContaCorrenteClienteComponent);
