class ResumoProcessoController {
  hasNotPermissaoEndossante(state) {
    const permissoes = state.authentication.userInformation?.permissoes || [];
    if (!permissoes) {
      return true;
    }
    return !permissoes.includes('ROLE_1203');
  }
}

export const resumoProcessoController = new ResumoProcessoController();
