import React from 'react';

import Grid24 from '../../Grid24/Grid24';
import MoneyField from '../../Field/Money/MoneyField';
import {
  FIELD_INITIAL, FIELD_FINAL, RATE, TRASH_HINT,
} from './faixaHonorario.constants';

import IconButtonTooltip from '../../IconButtonTooltip/IconButtonTooltip';
import NumberInput from '../../Field/Number/NumberInput';

import { getRequiredLabel } from '../../../../helpers/utils/utils';

import useStyles from './faixaHonorario.styles';
import FaixaNumeroHonorarios from './FaixaNumeroHonorarios';

function FaixaHonorario({
  type = 'MONEY',
  idxHonorario,
  priority = false,
  idxTipoHonorario,
  initialValue,
  finalValue,
  rate,
  showTrash = false,
  disabled = false,
  onRemove,
  onChange,
  onFocus,
}) {
  const styles = useStyles();

  const isDisabledInitialField = () => disabled || priority;

  const rename = name => `${name}-${idxTipoHonorario}-${idxHonorario}`;

  return (
    <>
      <Grid24 container isFormContainer spacing="1" className={styles.faixaHonorario__row}>
        <FaixaNumeroHonorarios numFaixa={idxHonorario} />
        {type === 'MONEY' && (
          <Grid24 item xs={24} sm={4}>
            <MoneyField
              isForm
              fullWidth
              disabled={isDisabledInitialField()}
              maxlength={16}
              label={getRequiredLabel(FIELD_INITIAL, !isDisabledInitialField())}
              name={rename(initialValue.name)}
              value={initialValue.value}
              error={initialValue.error}
              errorMessage={initialValue.errorMessage}
              onChange={onChange}
              onFocus={onFocus}
            />
          </Grid24>
        )}
        {type === 'DIAS' && (
          <Grid24 item xs={24} sm={4}>
            <NumberInput
              isForm
              fullWidth
              sideText="(dias)"
              numberType="integer"
              maxValue={999999}
              disabled={isDisabledInitialField()}
              label={getRequiredLabel(FIELD_INITIAL, !isDisabledInitialField())}
              name={rename(initialValue.name)}
              value={initialValue.value}
              error={initialValue.error}
              errorMessage={initialValue.errorMessage}
              onChange={onChange}
              onFocus={onFocus}
            />
          </Grid24>
        )}
        {type === 'MONEY' && (
          <Grid24 item xs={24} sm={4}>
            <MoneyField
              isForm
              fullWidth
              disabled={disabled}
              maxlength={16}
              label={getRequiredLabel(FIELD_FINAL, !disabled)}
              name={rename(finalValue.name)}
              value={finalValue.value}
              error={finalValue.error}
              errorMessage={finalValue.errorMessage}
              onChange={onChange}
              onFocus={onFocus}
            />
          </Grid24>
        )}
        {type === 'DIAS' && (
          <Grid24 item xs={24} sm={4}>
            <NumberInput
              isForm
              fullWidth
              sideText="(dias)"
              numberType="integer"
              maxValue={999999}
              disabled={disabled}
              label={getRequiredLabel(FIELD_FINAL, !disabled)}
              name={rename(finalValue.name)}
              value={finalValue.value}
              error={finalValue.error}
              errorMessage={finalValue.errorMessage}
              onChange={onChange}
              onFocus={onFocus}
            />
          </Grid24>
        )}
        <Grid24 item xs={24} sm={3}>
          <NumberInput
            fullWidth
            isForm
            disabled={disabled}
            sideText="(%)"
            numberType="percantage"
            maxValue={100}
            label={getRequiredLabel(RATE, !disabled)}
            name={rename(rate.name)}
            value={rate.value}
            error={rate.error}
            errorMessage={rate.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>
        {!showTrash && !disabled && (
          <Grid24 item xs={24} sm={2} justify="end" className={styles.faixaHonorario__trashContainer}>
            <IconButtonTooltip
              type="trash"
              attributes={{ idxHonorario, idxTipoHonorario }}
              disabled={disabled}
              hint={TRASH_HINT}
              onClick={onRemove}
              tooltipProps={{ className: styles.faixaHonorario__trashButton }}
            />
          </Grid24>
        )}

      </Grid24>
    </>
  );
}

export default React.memo(FaixaHonorario);
