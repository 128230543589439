import React, { useMemo } from 'react';
import cx from 'classnames';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  CircularProgress, FormControl, InputLabel, ListItemText, MenuItem, Select,
} from '@material-ui/core';

import TooltipUI from '../../Tooltip/Tooltip';
import useStyles from './MultilineSelect.styles';
import CheckboxField from '../../Field/Checkbox/CheckboxField';

function MultilineSelect({
  className,
  label,
  hasEmptyLabel = false,
  items = [],
  loading = false,
  disabled = false,
  isForm = false,
  error = false,
  fullWidth = true,
  errorMessage = '',
  value = [],
  tooltipProps,
  labelProps,
  ...props
}) {
  const styles = useStyles();

  const adornment = useMemo(() => (loading ? CircularProgress : ArrowDropDownIcon), [loading]);

  const emptyItem = useMemo(() => (!hasEmptyLabel ? null : (
    <MenuItem value="" classes={{ root: styles.menuItemRoot }}>
      <pre> </pre>
    </MenuItem>
  )), [hasEmptyLabel, styles.menuItemRoot]);

  const selector = useMemo(() => (
    <>
      <InputLabel error={error} {...labelProps}>{label}</InputLabel>
      <Select
        multiple
        error={error}
        value={value}
        renderValue={selected => items.filter(item => selected.includes(item.id)).map(item => item.value).join('; ')}
        disabled={disabled || loading}
        className={cx({ [styles.error]: error, [styles.disabled]: disabled })}
        MenuProps={{
          MenuListProps: {
            classes: {
              root: styles.listRoot,
            },
          },
        }}
        IconComponent={adornment}
        {...props}
      >
        {emptyItem}
        {items.map(item => (
          <MenuItem key={item.id} value={item.id}>
            <CheckboxField checked={value.includes(item.id)} />
            <ListItemText className={styles.fontSize12Typography} primary={item.value} />
          </MenuItem>
        ))}
      </Select>
    </>
  ), [
    adornment,
    disabled,
    emptyItem,
    error, items,
    label,
    labelProps,
    loading,
    value,
    styles,
    props,
  ]);

  function buildOutlinedGroup() {
    return (
      <FormControl
        error={error}
        className={
          cx(styles.outlinedRoot, className, { [styles.loading]: loading })
        }
        fullWidth
      >
        {selector}
      </FormControl>
    );
  }

  function buildFormInput() {
    return (
      <div className={
        cx(styles.formRoot, className, { [styles.fullWidth]: fullWidth, [styles.loading]: loading })
      }
      >
        {selector}
      </div>
    );
  }

  function buildField() {
    const content = isForm ? buildFormInput() : buildOutlinedGroup();
    if (error) {
      return (
        <TooltipUI error title={errorMessage} placement="bottom-start" {...tooltipProps}>
          {content}
        </TooltipUI>
      );
    }
    return content;
  }

  return buildField();
}

export default React.memo(MultilineSelect);
