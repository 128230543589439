import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import useStyles from './estatistica.styles';


function HistoricoEstatisca() {
  const styles = useStyles();
  const [mesVigente, setMesVigente] = useState(null);

  const acessoSite = useSelector(states => states.historicoAS.estatisticas.acessoSite);
  const notificados = useSelector(states => states.historicoAS.estatisticas.notificados);
  const qtdeSmsEnviado = useSelector(states => states.historicoAS.estatisticas.qtdeSmsEnviado);
  const qtdeEmailEnviados = useSelector(states => states.historicoAS.estatisticas.qtdeEmailEnviados);

  // Configura o mês vigente
  useEffect(() => {
    const mesAtual = new Date().toLocaleDateString('pt-BR', { month: 'long' });
    setMesVigente(mesAtual[0].toUpperCase() + mesAtual.substring(1, mesAtual.length));
  }, [setMesVigente]);

  return (
    <div className={styles.div__root}>
      <div className={styles.div__estatisticas}>
        <Typography component="p" style={{ fontWeight: 'bold' }}>
          Estatísticas referentes a
          {' '}
          {mesVigente}
        </Typography>
      </div>
      <div className={styles.estatisticas__cards}>
        <div className={styles.cards__external__root}>
          <div className={styles.cards__acesso}>
            <div className={styles.cards__internal__root}>
              <div className={styles.icon__acesso}>
                <i className="fas fa-check-circle" />
              </div>
              <div className={styles.cards__infos__root}>
                <Typography component="p" className={styles.infos__title}>
                  Acessos ao Site
                </Typography>
                <div className={styles.infos__counter}>
                  {acessoSite || 0}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.cards__external__root}>
          <div className={styles.cards__notificados}>
            <div className={styles.cards__internal__root}>
              <div className={styles.icon__notificado}>
                <i className="fas fa-comment-dots" />
              </div>
              <div className={styles.cards__infos__root}>
                <Typography component="p" className={styles.infos__title}>
                  Notificados
                </Typography>
                <div className={styles.infos__counter}>
                  {notificados || 0}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.cards__external__root}>
          <div className={styles.cards__sms}>
            <div className={styles.cards__internal__root}>
              <div className={styles.icon__sms}>
                <i className="fas fa-sms" />
              </div>
              <div className={styles.cards__infos__root}>
                <Typography component="p" className={styles.infos__title}>
                  SMS Enviados
                </Typography>
                <div className={styles.infos__counter}>
                  {qtdeSmsEnviado || 0}
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className={styles.cards__external__root}>
          <div className={styles.cards__email}>
            <div className={styles.cards__internal__root}>
              <div className={styles.icon__email}>
                <i className="fas fa-envelope" />
              </div>
              <div className={styles.cards__infos__root}>
                <Typography component="p" className={styles.infos__title}>
                  E-mails Enviados
                </Typography>
                <div className={styles.infos__counter}>
                  {qtdeEmailEnviados || 0}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoricoEstatisca;
