import { getUriParams } from '../../../helpers';
import {
  CORE, gateway, RELATORIOS,
} from '../../api';


export default {
  findEditarLancamento(idContaCorrenteNaoPrestado) {
    return gateway.get(`${CORE}/conta-corrente-cliente/${idContaCorrenteNaoPrestado}/lancamento`);
  },
  findCadastrarLancamento(payload) {
    return gateway.post(`${CORE}/conta-corrente-cliente/lancamento-nao-prestados`, payload);
  },
  alterarLancamento(payload) {
    return gateway.put(`${CORE}/conta-corrente-cliente/lancamento-nao-prestados`, payload);
  },
  findAllTiposDoLancamentos() {
    return gateway.get(`${CORE}/conta-corrente-cliente/tipos`);
  },
  findAllLancamentosNaoPrestados(
    idCliente,
    statusContaCorrenteCliente,
    pageable,
    order,
  ) {
    const body = {
      idCliente, statusContaCorrenteCliente,
    };

    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    const queryParam = getUriParams(pageable);
    return gateway.post(
      `${CORE}/conta-corrente-cliente/nao-prestado?${queryParam}${sortedQuery}`, body,
    );
  },

  deleteLancamento(idContaCorrenteCliente) {
    return gateway.delete(`${CORE}/conta-corrente-cliente/${idContaCorrenteCliente}/nao-prestado`);
  },

  atualizarStatus(idContaCorrenteCliente, idStatusContaCorrenteCliente) {
    const body = {
      idContaCorrenteCliente, idStatusContaCorrenteCliente,
    };
    return gateway.put(`${CORE}/conta-corrente-cliente/nao-prestado`, body);
  },

  downloadLancamentoNaoPrestado(
    idCliente,
    nomeCliente,
    statusContaCorrenteCliente,
    columnOrdenacao,
  ) {
    const body = {
      idCliente,
      nomeCliente,
      statusContaCorrenteCliente,
    };
    return gateway.post(
      `${RELATORIOS}/conta-corrente-cliente/relatorio/nao-prestado?sort=${columnOrdenacao.element},${columnOrdenacao.order}`,
      body,
      {
        responseType: 'blob',
      },
    );
  },
  findAllTiposLancamentosNaoPrestados(
    idCliente,
    statusContaCorrenteCliente,
  ) {
    return gateway.get(
      `${CORE}/conta-corrente-cliente/tipos-lancamentos-nao-prestado/${idCliente}/${statusContaCorrenteCliente}`,
    );
  },
  mudaStatusLancamentosNaoPrestados(
    idCliente,
    bloquear,
    tiposLancamentos,
  ) {
    const body = {
      idCliente, bloquear, tiposLancamentos,
    };

    return gateway.post(
      `${CORE}/conta-corrente-cliente/muda-status-lancamentos-nao-prestado`, body,
    );
  },
};
