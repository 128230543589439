import React, {
  useEffect,
  useState,
} from 'react';
import { MdPrint as MdPrintIcon } from 'react-icons/md';

import useStyles, {
  ButtonImprimir,
  ActionButton,
  ActionButtonGroup,
  ActionContainer,
  ActionContentContainer,
} from './PagamentosStyle';
import CardResumo from '../RelacaoPrestacao/Cards/CardResumo';
import LoadingUI from '../../../../components/UI/Loading/LoadingUI';
import TooltipUI from '../../../../components/UI/Tooltip/Tooltip';

import { pagamentosController as controller } from './pagamentosController';
import { TableDefault } from '../../../../components/UI/Tabela/TableDefault';
import { TableHeaderPagamentos } from '../../../../components/TabelaHeader/TableHeaderPagamentos';
import { TableBodyPagamentos } from '../../../../components/TabelaBody/TableBodyPagamentos';
import tableUtils from '../../../../helpers/utils/table.utils';
import store from '../../../../store/createStore';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { findPagamentosAction } from '../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import OptionDialogNew from '../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';


function makeColumnOrder(columnOrder) {
  return tableUtils.makeColumnOrder({
    element: 'data',
    order: columnOrder?.getNextOrder() || 'ASC',
    arrow: columnOrder ? columnOrder?.getNextArrow() : true,
  });
}

function getColumnOrder() {
  const { columnOrder } = store.getState().previousPageStates.lancamentosNaoPrestados;

  return {
    ...makeColumnOrder(),
    ...columnOrder,
  };
}

function PagamentosComponent() {
  const dispatch = useCCClienteDispatch();
  const [actionsButtons, setActionsButtons] = useState(controller.makeActionsButtons());

  const clienteSelecionado = useCCClienteSelector(states => (states.common.clienteSelecionado));
  const idRelacaoSelecionada = useCCClienteSelector(states => (states.common.idRelacaoSelecionada));
  const pagamentos = useCCClienteSelector(states => ([...states.pagamentos.pagamentos]));
  const page = useCCClienteSelector(states => (states.pagamentos.page));
  const rowsPerPage = useCCClienteSelector(states => (states.pagamentos.rowsPerPage));
  const totalElements = useCCClienteSelector(states => (states.pagamentos.totalElements));
  const detalhes = useCCClienteSelector(states => states.detalhes.detalhesCliente);
  const saldos = useCCClienteSelector(states => (states.pagamentos.saldos));

  function runActionButtonByTag(tag) {
    return () => {
      setActionsButtons(controller.changeActionButton(actionsButtons, tag));
    };
  }

  const [columnOrder, setColumnOrder] = React.useState(getColumnOrder());

  async function handleApplyOrder(columnName) {
    const newColumnOrder = makeColumnOrder(columnName, columnOrder);
    setColumnOrder(newColumnOrder);
  }

  useEffect(() => {
    if (idRelacaoSelecionada) {
      dispatch(findPagamentosAction());
    }
  }, [dispatch, idRelacaoSelecionada]);
  const style = useStyles();
  return (

    <>
      <LoadingUI show={false} />
      <OptionDialogNew
        open={false}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={' '}
        onClickConfirm={' '}
        text="Deseja excluir o pagamento/acordo da prestação de contas selecionada? "
      />
      <div className={style.body}>
        <header className={style.header}>
          <section className={style.header_nome_cliente}>
            <div>
              <span>
                Cliente:
                {' '}
              </span>
              <span>
                {clienteSelecionado?.idCliente}
                {clienteSelecionado?.idCliente !== null ? (' - ') : (' ')}
                {detalhes.nome}
              </span>
            </div>
            <div>
              <span>
                Prestação:
                {' '}
              </span>
              <span>{idRelacaoSelecionada}</span>
            </div>
            <div>
              <span>
                A Pagar:
                {' '}
              </span>
              <span>{saldos?.saldoAtual}</span>
            </div>
          </section>
          <section className={style.header_imprimir_resumo}>
            <div>
              <ButtonImprimir
                disabled={' '}
                onClick={' '}
              >
                <MdPrintIcon size={16} />
                <span>Imprimir</span>
              </ButtonImprimir>
            </div>
            <div className={style.card}>
              <div>
                <h3><b>Resumo dos Pagamentos</b></h3>
              </div>
              <div className={style.cardResumo}>
                <CardResumo
                  title="Total"
                  value={saldos?.resumoPagamento}
                  isDetail={false}
                  bgColor="#3cb6be"
                />
              </div>
            </div>
          </section>
          <section>
            <div>
              <ActionContainer>
                <ActionButtonGroup>
                  {actionsButtons.map(actions => (
                    <div key={actions.key}>
                      <TooltipUI title={actions.label} placement="bottom">
                        <div>
                          <ActionButton
                            active={actions.active.toString()}
                            onClick={runActionButtonByTag(actions.tag)}
                          >
                            <actions.Icon size={16} style={actions.styles} />
                          </ActionButton>
                        </div>
                      </TooltipUI>
                    </div>
                  ))}
                </ActionButtonGroup>
                {actionsButtons.filter(action => action.active === true).map(action => (
                  <ActionContentContainer key={action.tag}>
                    <action.Content />
                  </ActionContentContainer>
                ))}
              </ActionContainer>
            </div>
          </section>
        </header>
        <main className={style.div__table}>
          <TableDefault
            columnsHead={(
              <TableHeaderPagamentos
                columnOrder={columnOrder}
                onChangeOrder={columnName => handleApplyOrder(columnName)}
              />
            )}
            page={page}
            rowsPerPage={rowsPerPage}
            totalElements={totalElements}
            totalElementsOnPage={pagamentos?.length}
            totalColumns={7}
            onPageChange={' '}
            onRowsPerPageChange={' '}
            emptyRowsHeight={36}
            rowsPerPageOptions={[10, 15, 20]}
          >
            {pagamentos?.map(pagamento => (
              <TableBodyPagamentos pagamento={pagamento} />
            ))}
          </TableDefault>
        </main>

      </div>

    </>
  );
}

export default PagamentosComponent;
