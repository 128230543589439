export default {
  DADOS: 'Dados Cadastrais',
  NOVO: 'Novo Cadastro',
  EDITAR: 'Editar Cadastro',
  SEPARADOR: '>',
  USUARIO: 'Usuários',
  FILIAL: 'Filiais',
  PARAMETROS: 'Parâmetros',
  CLIENTE: 'Clientes',
  RAMO_ATIVIDADE: 'Ramos de Atividade',
  FASES_PASTAS: 'Fases e Pastas',
  REQUISICOES: 'Requisições',
  TITULOS_COMPROMISSO: 'Títulos de Compromisso',
};
