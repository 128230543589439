import styled from 'styled-components';

export const MainContainer = styled.div`
  margin-top: 8px;

  >div + div{
    margin-top: 8px;
  }
`;

export const FooterContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row-reverse;;
`;
