export const STATUS = 'Status';
export const MOTIVO_STATUS = 'Motivo do Status Inativo';
export const CODIGO_CLIENTE = 'Código';
export const NOME = 'Nome';
export const CATEGORIA = 'Categoria';
export const CPF = 'CPF';
export const CNPJ = 'CNPJ';
export const FILIAL_RESPONSAVEL = 'Filial Responsável';
export const TIPO_CLASSIFICACAO_CLIENTES = 'Classificação';
export const RAMOS_ATIVIDADE = 'Ramos de Atividade';
export const TITULO = 'Identificação';
export const IDENTIFICACAO_INTERNA = 'Identificação Interna';
export const PREPOSTO = 'Preposto';
export const NOME_FANTASIA = 'Nome Fantasia';
export const INSCRICAO_MUNICIPAL = 'Inscrição Municipal';
export const INSCRICAO_ESTADUAL = 'Inscrição Estadual';
export const OBSERVACAO = 'Observação';
export const ORIGEM_LEAD = 'Origem Lead';
export const COMERCIAL_PRINCIPAL = 'Comercial Principal';
export const TELEMARKETING = 'Telemarketing';
export const INSERIR_OBSERVACAO = 'Insira a observação';
