import React from 'react';
import { withRouter } from 'react-router';
import Grid24 from '../../../../components/UI/Grid24';
import Telefones from '../../../../components/UI/TelefonesV2/Telefones';
import FormWrapperWithTitle from '../../../../components/UI/Wrapper/Form/FormWrapperWithTitle';
import useStyles from '../DadosDevedor.styles';

function TelefoneDevedor({
  immutableSelectors, isEditMode, podeModificarFase, formFields, setAttributeHandler, setFocusHandler, setTelefonesHandler,
}) {
  const styles = useStyles();

  return (
    <Grid24 container isFormContainer>
      <FormWrapperWithTitle>
        <Grid24 container isFormContainer spacing="1" className={styles.margin}>
          <Grid24 xs={24} sm={24}>
            <Telefones
              readMode={!podeModificarFase || !isEditMode}
              disabled={!podeModificarFase || !isEditMode}
              tiposTelefone={immutableSelectors.tiposTelefone}
              telefones={formFields.telefones}
              setAttribute={setAttributeHandler}
              setFocus={setFocusHandler}
              setTelefones={setTelefonesHandler}
              requiredFields={{
                tipoTelefone: isEditMode,
                numero: isEditMode,
              }}
            />
          </Grid24>
        </Grid24>
      </FormWrapperWithTitle>
    </Grid24>
  );
}

export default withRouter(TelefoneDevedor);
