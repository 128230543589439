import React, { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from '@mui/material';
import InputField from '../../UI/Field/Input/InputField';
import CheckboxField from '../../UI/Field/Checkbox/CheckboxField';

import { findUsuariosAction } from '../../../store/Global/LoadSelectors/loadSelectors.saga';
import { setRequestFieldValueAction, setRequestFieldFocusAction } from '../../../store/FasesPastas/Pastas/pastas.store';
import { savePastaAction } from '../../../store/FasesPastas/Pastas/pastas.saga';

import {
  Dialog, Selector, CheckboxGroup, WrapperTitle,
} from './styles';

import constants from './constants';
import { usePastaSelector, usePastaDispatch } from '../../../store/FasesPastas/Pastas/PastasProvicer';
import CampoObrigatorio from '../../UI/CampoObrigatorio/CampoObrigatorio';
import { getRequiredLabel } from '../../../helpers/utils/utils';


export default function CrudPastas({
  open, onClose, idFase, isEditMode = false,
}) {
  const dispatch = usePastaDispatch();
  const globalDispatch = useDispatch();

  useEffect(() => {
    globalDispatch(findUsuariosAction());
  }, [globalDispatch]);

  const nome = usePastaSelector(state => state.requestFields.nome.copy());
  const publico = usePastaSelector(state => state.requestFields.publico.copy());
  const idUsuario = usePastaSelector(state => state.requestFields.idUsuario.copy());

  const usuarios = useSelector(state => state.selectors.usuarios.selector);

  const onFocusHandler = useCallback((e) => {
    let fieldName = e.target.name;

    if (e.target.nextElementSibling) {
      fieldName = e.target.nextElementSibling.name;
    }
    dispatch(setRequestFieldFocusAction(fieldName));
  }, [dispatch]);

  const onChangeValueHandler = useCallback((e) => {
    const { name, value } = e.target;
    dispatch(setRequestFieldValueAction(name, value));
  }, [dispatch]);

  const onChangeCheckedHandler = useCallback((e) => {
    const { name, checked } = e.target;
    dispatch(setRequestFieldValueAction(name, checked));
  }, [dispatch]);

  const submitHandler = () => {
    dispatch(savePastaAction(isEditMode, idFase));
  };

  const path = useMemo(() => (isEditMode ? constants.pathEdit : constants.pathRegister), [isEditMode]);

  return (
    <Dialog
      open={open}
      text={path}
      onClose={onClose}
    >
      {(nome.error || idUsuario.error) === true ? (
        <CampoObrigatorio />
      ) : ('')}
      <WrapperTitle title={constants.groupTitle}>
        <div style={{ marginBottom: '10px' }}>
          <InputField
            isForm
            fullWidth
            label={constants.labelName}
            name="nome"
            value={nome.value}
            error={nome.error}
            errorMessage={nome.errorMessage}
            maxLength={50}
            onFocus={onFocusHandler}
            onChange={onChangeValueHandler}
          />
        </div>
        <Selector
          isForm
          fullWidth
          hasEmptyLabel
          disabled={isEditMode}
          label={getRequiredLabel(constants.labelResponsavel, !isEditMode)}
          name="idUsuario"
          value={idUsuario.value}
          error={idUsuario.error}
          errorMessage={idUsuario.errorMessage}
          items={usuarios}
          onFocus={onFocusHandler}
          onChange={onChangeValueHandler}
        />
        <CheckboxGroup>
          <CheckboxField
            label={constants.labelAtributo}
            name="publico"
            checked={publico.value}
            onChange={onChangeCheckedHandler}
          />
        </CheckboxGroup>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
          <Button
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            onClick={submitHandler}
          >
            Confirmar
          </Button>
        </div>
      </WrapperTitle>
    </Dialog>
  );
}
