import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import CrudBorderoProvider from '../../../store/Borderos/Crud/CrudBorderoProvider';
import CrudBordero from '../../../components/Bordero/Crud/CrudBordero';
import { initializePageAction } from '../../../store/theme.actions';

const constants = {
  TITLE: 'Incluir Borderô',
};

export default function InpsecionarBorderoPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initializePageAction(constants.TITLE));
  }, [dispatch]);

  return (
    <CrudBorderoProvider>
      <CrudBordero mode="register" />
    </CrudBorderoProvider>
  );
}
