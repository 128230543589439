import makeStyles from '@material-ui/core/styles/makeStyles';
import { warmGrey } from '../../../helpers/constants/colors.constants';

export default makeStyles({
  buttons: {
    color: '#034ddc',
  },
  dialog: {
    ' & .MuiDialog-paperWidthSm': {
      width: '671px',
    },
  },
  followUpBox: {
    border: `solid 1px ${warmGrey}`,
    borderRadius: '7px',
    maxHeight: '318px',
    overflow: 'auto',
    marginBottom: '15px',
  },
  followUpText: {
    fontSize: '12px',
    margin: '8px',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
  },
  div__footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '48px',
  },
});
