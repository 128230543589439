import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  button: {
    backgroundColor: '#ff1381',
    color: 'white',
    width: '44px',
    height: '44px',
    '&:hover': {
      backgroundColor: '#ff1381',
      opacity: '.7',
    },
  },
  textField: {
    width: '84%',
    paddingRight: '10px',
  },
  textFieldSecundario: {
    width: '90%',
    paddingRight: '55px',
  },
});
