import makeStyles from '@material-ui/core/styles/makeStyles';
import { blackTwo } from '../../helpers/constants/colors.constants';

export default makeStyles({
  searchIconButton: {
    backgroundColor: '#ff1381',
    color: 'white',
    width: '44px',
    height: '44px',

    '&:hover': {
      backgroundColor: '#ff1381',
      opacity: '.7',
    },
  },
  periodo: {
    width: '84%',
    paddingRight: '10px',
    paddingBottom: '10px',
  },
  serachIcon: {
    marginLeft: '2px',
  },
  search_period_label: {
    '& p': {
      marginBottom: '3px',
      paddingLeft: '20px',
      fontSize: '14px',
      color: blackTwo,
      fontWeight: 'normal',
      height: '17px',
    },
  },
  div_pesquisaAvancada: {
    marginTop: '0px',
    marginBottom: '17px',
  },
  pesquisaAvancada: {
    marginRight: '12%',
  },
  pesquisaAvancadaButton: {
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '12px',
    color: '#034ddc',
  },
  pesquisaSimplesButton: {
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '12px',
    color: '#707070',
  },
  button__exportarLog__imprimrir: {
    padding: '5px 15px',
  },
  div_button__exportarLog__imprimrir: {
    marginRight: '18px',
  },
  div_date_label: {
    textAlign: 'center',
    '& p': {
      fontSize: '.865rem',
      marginTop: '10px',
    },
  },
  div_search_card: {
    minHeight: '112px',
  },
  div__buttons: {
    marginTop: '56px',
  },
});
