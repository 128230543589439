import { put, select, takeLatest } from 'redux-saga/effects';
import { onErrorNomeRamoAction, onLoadingCrudAction, onOpenEditDialogError } from './crudRamoAtividade.store';
import { insertRamoAtividade, updateRamoAtividade } from '../../../services/core/ramoAtividade/ramoAtividade.services';
import { onOpenCrudDialogAction, onSucessCadastroAction, shouldUpdateContentRamoAction } from '../Pesquisa/pesquisaRamoAtividade.store';
import { getExceptionHandler } from '../../../helpers/utils/exception.util';
import {
  buildPayload,
  getExceptionMessage,
  isBadRequestException,
  isNotFoundException,
} from '../../../helpers/utils/utils';

const INSERIR_RAMO_ATIVIDADE = 'INSERIR_RAMO_ATIVIDADE';
const EDITAR_RAMO_ATIVIDADE = 'EDITAR_RAMO_ATIVIDADE';

export const insertRamoAtividadeAction = nomeRamoAtividade => ({
  type: INSERIR_RAMO_ATIVIDADE,
  nomeRamoAtividade,
});
export const editRamoAtividadeAction = (idRamoAtividade, ramoAtividade) => ({
  type: EDITAR_RAMO_ATIVIDADE,
  idRamoAtividade,
  ramoAtividade,
});


function* inserirRamoAtividadeHandler(actions) {
  const fields = yield select(states => states.crudRamoAtividade.controls);
  const tableContent = yield select(states => states.pesquisaRamoAtividade.controls.tableContent);

  yield put(onLoadingCrudAction(true));
  try {
    yield insertRamoAtividade({ nomeRamoAtividade: actions.nomeRamoAtividade });
    yield put(onOpenCrudDialogAction(false));
    yield put(onSucessCadastroAction(true));
    if (tableContent.length > 0) {
      yield put(shouldUpdateContentRamoAction(true));
    }
  } catch (exception) {
    yield put(onSucessCadastroAction(false));
    const [updatedFields] = yield getExceptionHandler(exception, fields);
    if (updatedFields) {
      yield put(onErrorNomeRamoAction(updatedFields));
    }
  } finally {
    yield put(onLoadingCrudAction(false));
  }
}

function getPayload(fields) {
  return buildPayload(fields);
}

function* editarRamoAtividadeHandler(actions) {
  yield put(onLoadingCrudAction(true));
  const fields = yield select(states => states.crudRamoAtividade.controls);
  const payload = yield getPayload(fields);
  try {
    yield updateRamoAtividade(actions.idRamoAtividade, payload);
    yield put(onOpenCrudDialogAction(false));
    yield put(shouldUpdateContentRamoAction(true));
  } catch (exception) {
    yield put(shouldUpdateContentRamoAction(false));
    yield put(onSucessCadastroAction(false));
    const [updatedFields] = yield getExceptionHandler(exception, fields);

    if (isNotFoundException(exception)) {
      yield put(onOpenEditDialogError(true, getExceptionMessage(exception)));
      yield put(shouldUpdateContentRamoAction(true));
    } else if (isBadRequestException(exception)) {
      if (updatedFields) {
        yield put(onErrorNomeRamoAction(updatedFields));
      }
    }
  } finally {
    yield put(onLoadingCrudAction(false));
  }
}


export default function* watchCrudRamoAtividade() {
  yield takeLatest(INSERIR_RAMO_ATIVIDADE, inserirRamoAtividadeHandler);
  yield takeLatest(EDITAR_RAMO_ATIVIDADE, editarRamoAtividadeHandler);
}
