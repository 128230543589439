import React, { useCallback, useEffect } from 'react';
import {
  useCrudClienteDispatch,
  useCrudClienteSelector,
} from '../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import Grid24 from '../../../../UI/Grid24/Grid24';
import {
  onChangeControls,
  onFocusControls,
  setStatusPromisseCodEmpresa,
} from '../../../../../store/Cliente/V2/Crud/crudCliente.store';
import { getRequiredLabel } from '../../../../../helpers/utils/utils';

import { getEmpresasGrupoAction } from '../../../../../store/Cliente/V2/Crud/crudCliente.saga';
import TableEmpresasGrupo from './TableEmpresasGrupo';
import CustomInputField from '../../../../UI/Field/Custom/CustomInputField';
import useStyles from './empresaGrupo.styles';

const CONSTANTS = {
  COD_EMPRESA_PRINCIPAL: 'Cód. Empresa Principal',
};

function EmpresasGrupo({
  isEditMode,
  isCliente,
  isMigrarParaCliente,
}) {
  const dispatch = useCrudClienteDispatch();
  const styles = useStyles();

  const codEmpresaPrincipal = useCrudClienteSelector(states => states.controls.codEmpresaPrincipal.copy());
  const loadingEmpresasGrupo = useCrudClienteSelector(states => states.actions.loadingEmpresasGrupo);
  const statusPromisseCodEmpresa = useCrudClienteSelector(states => states.actions.statusPromisseCodEmpresa);
  const errorMessageCodEmpresa = useCrudClienteSelector(states => states.actions.errorMessageCodEmpresa);

  const onBlurUpdateEmpresasGrupoHandler = useCallback((event) => {
    const { value } = event.target;
    if (value) {
      dispatch(getEmpresasGrupoAction(value, isCliente));
    }
  }, [dispatch, isCliente]);

  const onChangeEmpresaPrinicpal = useCallback(
    (event) => {
      const { name, value } = event.target;
      dispatch(onChangeControls(name, value));
    },
    [dispatch],
  );

  const onFocusEmpresaPrincipal = useCallback((event) => {
    const fieldName = event.target.name;
    dispatch(onFocusControls(fieldName));
  }, [dispatch]);

  useEffect(() => {
    if (codEmpresaPrincipal.error && !errorMessageCodEmpresa) {
      dispatch(setStatusPromisseCodEmpresa('fail', ''));
    }
  }, [dispatch, codEmpresaPrincipal.error, errorMessageCodEmpresa]);

  return (
    <>
      <Grid24 container isFormContainer spacing={1}>
        <Grid24 item xs={24} sm={4}>
          <CustomInputField
            fullWidth
            isForm
            statusPromisse={statusPromisseCodEmpresa}
            disabled={!isEditMode}
            label={getRequiredLabel(CONSTANTS.COD_EMPRESA_PRINCIPAL, (isEditMode))}
            name={codEmpresaPrincipal.name}
            value={codEmpresaPrincipal.value}
            error={codEmpresaPrincipal.error}
            errorMessage={codEmpresaPrincipal.errorMessage}
            maxLength={codEmpresaPrincipal.maxLength}
            loading={loadingEmpresasGrupo}
            onFocus={onFocusEmpresaPrincipal}
            onChange={onChangeEmpresaPrinicpal}
            onBlur={onBlurUpdateEmpresasGrupoHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={20}>
          <div className={styles.errorMessage}>
            {errorMessageCodEmpresa}
          </div>
        </Grid24>
      </Grid24>
      <TableEmpresasGrupo
        isMigrarParaCliente={isMigrarParaCliente}
        isCliente={isCliente}
        isEditMode={isEditMode}
      />
    </>
  );
}

export default React.memo(EmpresasGrupo);
