import React, { useCallback, useState } from 'react';
import SubTitleForm from '../../../../components/UI/SubTitleForm/SubTitleForm';
import {
  ButtonConfirmar, ContainerImportar, InputImportar,
} from './RecebimentoClienteStyles';
import { InputFileIcon } from '../../../../components/UI/InputFile/InputFileIcon';
import { useRecebimentoDispatch, useRecebimentoSelector } from '../../../../store/Recebimento/RecebimentoClienteProvider';
import { resetFormFieldsAction, setSimpleRecebimentoAction, setValueImportarAction } from '../../../../store/Recebimento/RecebimentoCliente.store';
import { importarArquivoRetornoAction } from '../../../../store/Recebimento/RecebimentoClientes.saga';
import OptionDialogNew from '../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';


function ImportarArquivoComponent() {
  const dispatch = useRecebimentoDispatch();
  const openDialogErro = useRecebimentoSelector(states => states.openDialogErro);
  const openDialogSucesso = useRecebimentoSelector(states => states.openDialogSucesso);
  const arquivoRetorno = useRecebimentoSelector(states => states.formFieldsImportar.arquivoRetorno);
  const arquivosProcessados = useRecebimentoSelector(states => states.arquivosProcessados);

  const [arquivoLancamentosRetorno, setArquivoLancamentosRetorno] = useState(null);

  const onClose = useCallback(() => {
    dispatch(setSimpleRecebimentoAction('openDialogErro', false));
  }, [dispatch]);

  const onChangeFieldsFile = (event) => {
    const { name, value } = event.target;
    dispatch(setValueImportarAction(name, value));
  };

  const onClickConfirm = useCallback(() => {
    dispatch(setSimpleRecebimentoAction('openDialogSucesso', false));
    dispatch(setSimpleRecebimentoAction('openButtonImportar', false));
    dispatch(resetFormFieldsAction());
  }, [dispatch]);

  const onClickConfirmar = useCallback(() => {
    dispatch(importarArquivoRetornoAction(arquivoLancamentosRetorno));
  }, [dispatch, arquivoLancamentosRetorno]);

  return (
    <>
      <OptionDialogNew
        open={openDialogErro === true}
        confirmLabel="OK"
        onClickConfirm={onClose}
      >
        <div style={{ fontSize: '18px' }}>
          <p>
            Arquivo Inválido
            <br />
            O Arquivo não obedece ao layout esperado!
          </p>
        </div>
      </OptionDialogNew>
      <OptionDialogNew
        open={openDialogSucesso === true}
        confirmLabel="OK"
        onClickConfirm={onClickConfirm}
      >
        <div style={{ fontSize: '18px' }}>
          <center>Arquivo importado com sucesso:</center>
          <center>
            <p>
              Total de Boletos inseridos:
              {' '}
              {arquivosProcessados?.qtdeBoletosInseridos || 0}
            </p>
          </center>
          <center>
            <p>
              Total de Boletos não inseridos:
              {' '}
              {arquivosProcessados?.qtdeBoletosNaoInseridos || 0}
            </p>
          </center>
        </div>
      </OptionDialogNew>
      <ContainerImportar>
        <SubTitleForm>
          Importar Lançamentos
        </SubTitleForm>
        <InputImportar>
          <InputFileIcon
            isForm
            acceptAll
            label="Arquivo de Retorno"
            name="arquivoRetorno"
            labelName="arquivoRetorno"
            onChange={onChangeFieldsFile}
            setFile={setArquivoLancamentosRetorno}
          />
        </InputImportar>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <ButtonConfirmar
            color="success"
            disabled={arquivoRetorno === ''}
            onClick={onClickConfirmar}
          >
            Confirmar
          </ButtonConfirmar>
        </div>
      </ContainerImportar>
    </>
  );
}
export const ImportarArquivo = ImportarArquivoComponent;
