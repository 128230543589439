import { removeMoneyMask, toAmericanDate } from '../../../../helpers';
import { makeErrorField } from '../../../../helpers/utils/form.utils';
import { alterarTituloService, cadastrarTituloService } from '../../../../services/core/processos/processosTitulosService';

class DialogTituloController {
  makeErrorFields() {
    return {
      idTipoTitulo: makeErrorField(),
      numero: makeErrorField(),
      dataVencimento: makeErrorField(),
      valorOriginal: makeErrorField(),
      valorProtesto: makeErrorField(),
      tituloProtestado: makeErrorField(),
    };
  }

  makeFormFields(values) {
    return {
      idTitulo: values?.idTitulo,
      dataVencimento: values?.dataVencimento || '',
      idTipoTitulo: values?.idTipoTitulo || '',
      numero: values?.numero || '',
      tituloProtestado: values?.tituloProtestado || 'N',
      valorOriginal: removeMoneyMask(values?.valorOriginal),
      valorProtesto: removeMoneyMask(values?.valorProtesto),
    };
  }

  makePayload(idProcesso, fields) {
    return {
      dataVencimento: toAmericanDate(fields?.dataVencimento || null),
      idProcesso,
      idTipoTitulo: fields?.idTipoTitulo || null,
      idTitulo: fields?.idTitulo || null,
      numero: fields?.numero || null,
      tituloProtestado: fields?.tituloProtestado === 'S',
      valorOriginal: fields?.valorOriginal || null,
      valorProtesto: fields?.valorProtesto || null,
    };
  }

  async salvarTitulo(idProcesso, idTitulo, fields) {
    const payload = this.makePayload(idProcesso, fields);

    if (idTitulo) {
      await alterarTituloService(payload);
    } else {
      await cadastrarTituloService(payload);
    }
  }
}

export const dialogTituloController = new DialogTituloController();
