import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import ButtonUI from '../../../UI/Button/ButtonUI';
import Grid24 from '../../../UI/Grid24/Grid24';
import SelectorField from '../../../UI/Field/Selector';
import InputField from '../../../UI/Field/Input/InputField';
import MaskField from '../../../UI/Field/Mask/MaskField';
import WrapperSubTitleForm from '../../../UI/WrapperSubTitleForm/WrapperSubTitleForm';

import { useCrudBoderoDispatch, useCrudBorderoSelector } from '../../../../store/Borderos/Crud/CrudBorderoProvider';
import { setCategoriaAction, updateNomeFantasiaAction } from '../../../../store/Borderos/Crud/CrudBordero.store';
import { findDevedorByDocumentoAction } from '../../../../store/Borderos/Crud/CrudBorderos.saga';

import { CATEGORIAS } from '../../../../helpers/constants/enums.constants';
import { CNPJ_MASK, CPF_MASK } from '../../../../helpers/constants/layout.constants';
import { getRequiredLabel } from '../../../../helpers/utils/utils';

import useUtilStyles from '../../../../helpers/styles/utils.styles';
import useCrudBorderoStyles from '../CrudBordero.styles';

const constants = {
  TITLE_INSPECT: 'Dados do Devedor',
  TITLE_REGISTER: 'Informe os dados cadastrais do devedor',
  CONSULTAR: 'Consultar',
  CATEGORIA: 'Categoria',
  DOCUMENTO: 'CPF/CNPJ',
  CODIGO_NO_CLIENTE: 'Código no Cliente',
  CODIGO_DEVEDOR: 'Código do Devedor',
  NOME: 'Razão Social/Nome',
  PREPOSTO: 'Preposto',
  NOME_FANTASIA: 'Nome Fantasia',
  INSCRICAO_ESTADUAL: 'Inscrição Estadual',
};

export default function DadosDevedorCrudBordero({
  wrapperByMode,
  changeFields,
  isInspectMode,
  disabled,
  clearErrorFields,
}) {
  const utilStyles = useUtilStyles();
  const styleCrudBordero = useCrudBorderoStyles();
  const dispatch = useCrudBoderoDispatch();

  const [disabledSearch, setDisabledSearch] = useState(true);

  const categorias = useSelector(states => states.selectors.categorias.selector);

  const categoria = useCrudBorderoSelector(states => states.requestFields.categoria);
  const documento = useCrudBorderoSelector(states => states.requestFields.documento);
  const razaoSocial = useCrudBorderoSelector(states => states.requestFields.razaoSocial);
  const nomePreposto = useCrudBorderoSelector(states => states.requestFields.nomePreposto);
  const nomeFantasia = useCrudBorderoSelector(states => states.requestFields.nomeFantasia);
  const inscricaoEstadual = useCrudBorderoSelector(states => states.requestFields.inscricaoEstadual);
  const codigoNoCliente = useCrudBorderoSelector(states => states.requestFields.codigoNoCliente);
  const idDevedor = useCrudBorderoSelector(states => states.requestFields.idDevedor);

  const loadingDevedor = useCrudBorderoSelector(states => states.loading.devedor);

  const findDevedorHandler = () => {
    dispatch(findDevedorByDocumentoAction(documento.value));
  };

  const isCategoriaEditable = () => isInspectMode || disabled || !categoria.value;

  const changeCategoriaHandler = useCallback((event) => {
    dispatch(setCategoriaAction(event.target.value));
  }, [dispatch]);

  const changeDocumentoHandler = useCallback((event) => {
    const { length } = event.target.value;
    if ((CATEGORIAS.PESSOA_FISICA === categoria.value && length === 11)
      || (CATEGORIAS.PESSOA_FISICA !== categoria.value && length === 14)) {
      setDisabledSearch(false);
    } else {
      setDisabledSearch(true);
    }
    changeFields(event);
  }, [changeFields, categoria.value]);

  const maskCpfCnpj = categorias.filter(cat => cat.id === categoria?.value)[0]?.aux;

  const updateNomeFantasiaHandler = useCallback(() => {
    dispatch(updateNomeFantasiaAction());
  }, [dispatch]);

  const buildButtonConsultar = () => (
    <Grid24 xs={24} sm={3}>
      <ButtonUI
        className={styleCrudBordero.searchButton}
        disabled={disabled || disabledSearch}
        showLoading={loadingDevedor}
        onClick={findDevedorHandler}
      >
        {constants.CONSULTAR}
      </ButtonUI>
    </Grid24>
  );

  return (
    <WrapperSubTitleForm
      title={wrapperByMode(constants.TITLE_REGISTER, constants.TITLE_INSPECT)}
      className={utilStyles.mt16}
    >
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={3}>
          <SelectorField
            isForm
            disabled={isInspectMode || disabled}
            label={constants.CATEGORIA}
            items={categorias}
            name={categoria.name}
            value={categoria.value}
            error={categoria.error}
            errorMessage={categoria.errorMessage}
            onChange={changeCategoriaHandler}
            onFocus={clearErrorFields}
            hasEmptyLabel
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <MaskField
            isForm
            fullWidth
            disabled={isCategoriaEditable()}
            label={constants.DOCUMENTO}
            name={documento.name}
            value={documento.value}
            error={documento.error}
            errorMessage={documento.errorMessage}
            format={maskCpfCnpj === 'CPF' ? CPF_MASK : CNPJ_MASK}
            onChange={changeDocumentoHandler}
            onFocus={clearErrorFields}
          />
        </Grid24>
        {wrapperByMode(buildButtonConsultar(), null)}
      </Grid24>
      <Grid24 container isFormContainer spacing="1" style={{ marginTop: '16px' }}>
        <Grid24 xs={24} sm={5}>
          <InputField
            isForm
            fullWidth
            disabled={isInspectMode || disabled}
            label={constants.CODIGO_NO_CLIENTE}
            name={codigoNoCliente.name}
            value={codigoNoCliente.value}
            error={codigoNoCliente.error}
            errorMessage={codigoNoCliente.errorMessage}
            maxLength={codigoNoCliente.maxLength}
            onChange={changeFields}
            onFocus={clearErrorFields}
          />
        </Grid24>
        <Grid24 xs={24} sm={3}>
          <InputField
            isForm
            disabled
            label={constants.CODIGO_DEVEDOR}
            name={idDevedor.name}
            value={idDevedor.value}
          />
        </Grid24>
        <Grid24 xs={24} sm={11}>
          <InputField
            isForm
            fullWidth
            disabled={isInspectMode || disabled}
            label={getRequiredLabel(constants.NOME, !isInspectMode)}
            name={razaoSocial.name}
            value={razaoSocial.value}
            error={razaoSocial.error}
            errorMessage={razaoSocial.errorMessage}
            maxLength={razaoSocial.maxLength}
            onChange={changeFields}
            onBlur={updateNomeFantasiaHandler}
            onFocus={clearErrorFields}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <InputField
            isForm
            fullWidth
            disabled={isInspectMode || disabled}
            label={constants.PREPOSTO}
            name={nomePreposto.name}
            value={nomePreposto.value}
            error={nomePreposto.error}
            errorMessage={nomePreposto.errorMessage}
            maxLength={nomePreposto.maxLength}
            onChange={changeFields}
            onFocus={clearErrorFields}
          />
        </Grid24>
      </Grid24>
      {categoria.value !== CATEGORIAS.PESSOA_FISICA && (
        <Grid24 container isFormContainer spacing="1">
          <Grid24 xs={24} sm={10}>
            <InputField
              isForm
              fullWidth
              disabled={isInspectMode || disabled}
              label={constants.NOME_FANTASIA}
              name={nomeFantasia.name}
              value={nomeFantasia.value}
              error={nomeFantasia.error}
              errorMessage={nomeFantasia.errorMessage}
              maxLength={nomeFantasia.maxLength}
              onChange={changeFields}
              onFocus={clearErrorFields}
            />
          </Grid24>
          <Grid24 xs={24} sm={3}>
            <InputField
              isForm
              disabled={isInspectMode || disabled}
              label={constants.INSCRICAO_ESTADUAL}
              name={inscricaoEstadual.name}
              value={inscricaoEstadual.value}
              error={inscricaoEstadual.error}
              errorMessage={inscricaoEstadual.errorMessage}
              maxLength={inscricaoEstadual.maxLength}
              onChange={changeFields}
              onFocus={clearErrorFields}
            />
          </Grid24>
        </Grid24>
      )}

    </WrapperSubTitleForm>
  );
}
