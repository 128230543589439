import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  distenceBetweenTables: {
    paddingLeft: '18px',
    maxWidth: '46.333%',
  },
  topTableDistance: {
    marginTop: '17px',
  },
  alignTitle: {
    '& .MuiListSubheader-gutters': {
      paddingLeft: '12px',
      paddingRight: '12px',
    },
  },
});
