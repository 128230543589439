import React from 'react';
import ButtonUI from '../../ButtonUI';
import iconLupa from '../../../../../layouts/SearchProcessosLayout/IconLupa.png';
import useStyles from './searchButtonWithLabel.styles';

function SearchButtonWithLabel({ label, onClick, ...props }) {
  const { disabled } = props;
  const styles = useStyles({ disabled });

  return (
    <ButtonUI onClick={onClick} color="pesquisa" {...props}>
      <div className={styles.div__BtnPesquisar}>
        {label}
        <img src={iconLupa} alt="Lupa" />
      </div>
    </ButtonUI>
  );
}

export default React.memo(SearchButtonWithLabel);
