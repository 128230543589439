import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Tooltip from '../../Tooltip/Tooltip';

import useStyles from './TitleList.styles';
import CheckboxField from '../../Field/Checkbox/CheckboxField';

function TitleList({
  title = '', hint = '', onChange = null, withChecked = false, checkboxProps = {},
}) {
  const styles = useStyles({ withChecked });
  return (
    <Grid container alignItems="center" className={styles.wrapper}>
      {withChecked && (
        <Tooltip title={hint} placement="bottom-start">
          <div>
            <CheckboxField
              label=""
              propsFormLabel={{ className: styles.checkbox }}
              onChange={onChange}
              {...checkboxProps}
            />
          </div>
        </Tooltip>
      )}
      <div style={{ textAlign: 'center' }}>
        <Typography component="p">{title}</Typography>
      </div>
    </Grid>
  );
}
export default TitleList;
