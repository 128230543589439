import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import CardWrapper from '../../UI/CardWrapper/CardWrapper';
import useStyles from './pesquisaSmsASPage.styles';
import ButtonWithIconUI from '../../UI/Button/Icon/ButtonWithIconUI';
import useUtilStyles from '../../../helpers/styles/utils.styles';
import Table from '../../UI/Tabela/PaginadaSimples/Table';
import { buildSettingsTable } from '../../../helpers/factory/global.factory';
import TableHeader from '../../../models/TableHeader';
import OrdenacaoColuna from '../../UI/Tabela/Ordenacao/OrdenacaoColuna';
import PaperUI from '../../UI/Paper/PaperUI';
import { initializePageAction } from '../../../store/theme.actions';
import TestarSmsDialog from './Dialogs/TestarSMS/TestarSmsDialog';
import ButtonUI from '../../UI/Button/ButtonUI';
import ConfirmarEnvioDialog from './Dialogs/ConfirmarEnvio/ConfirmarEnvioDialog';
import EnviouSucessoDialog from './Dialogs/EnviouSucessoDialog/EnviouSucessoDialog';
import TooltipUI from '../../UI/Tooltip/Tooltip';
import CheckboxField from '../../UI/Field/Checkbox/CheckboxField';
import {
  buscarHistoricoSmsEmail,
  buscarIdsHistoricoSmsEmail,
  enviarSmsEmail,
} from '../../../services/core/acordoseguro/enviarSMS.services';
import {
  getCheckedValue,
  getCurrentIdUsuario, getDateTimeMask, getMoneyMask,
} from '../../../helpers/utils/utils';
import LoadingUI from '../../UI/Loading/LoadingUI';
import TempoFaseAmpulheta from '../../UI/TempoFaseAmpulheta/TempoFaseAmpulheta';

function PesquisaSmsASPage({ location }) {
  const styles = useStyles();
  const utilStyles = useUtilStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializePageAction('Enviar SMS/E-mail'));
  }, [dispatch]);

  const idUsuario = getCurrentIdUsuario();
  const [todosIds, setTodosIds] = useState({});
  const [openConfirmarEnvioDialog, setOpenConfirmarEnvioDialog] = useState(false);
  const [openEnviouSucessoDialog, setOpenEnviouSucessoDialog] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [qtdEmailEnviado, setQtdEmailEnviado] = useState(0);
  const [qtdSmsEnviado, setQtdSmsEnviado] = useState(0);

  const [loading, setLoading] = useState(false);
  const [openTestarSMS, setOpenTestarSMS] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [settingsTable, setSettingsTable] = useState(buildSettingsTable());
  const [columnOrdenacao, setColumnOrdenacao] = useState({
    element: 'nroProcesso',
    order: 'ASC',
  });
  const [disableCheckBoxTable] = useState(false);

  const loadingPage = useCallback(async (func) => {
    try {
      setLoading(true);
      await func();
    } finally {
      setLoading(false);
    }
  }, []);

  const getCheckedIcon = useCallback((content) => {
    const iconColor = content ? styles.icon__eneabled : styles.icon__disabled;
    return (
      <div className={iconColor}>
        <i className="fas fa-check-circle" />
      </div>
    );
  }, [styles]);

  const pesquisarProcessos = useCallback(async (ordenacao, page, rowsPerPage) => {
    try {
      let response = await buscarHistoricoSmsEmail([`${ordenacao.element},${ordenacao.order}`], page, rowsPerPage);

      if (response.data.content.length === 0 && response.data.totalElements !== 0) {
        const lastPage = response.data.totalPages - 1;
        response = await buscarHistoricoSmsEmail([`${ordenacao.element},${ordenacao.order}`], lastPage, rowsPerPage);
      }
      let tableCopy = response.data.content;

      if (selectAll) {
        setTodosIds(old => Object.keys(old).reduce((acc, nroProcessoCheck) => ({
          ...acc,
          [nroProcessoCheck]: true,
        }), {}));
        tableCopy = response.data.content.map(s => ({
          ...s,
          checked: true,
        }));
      }
      setSettingsTable(({
        content: tableCopy,
        page: response.data.number,
        rowsPerPage: response.data.size,
        size: response.data.totalElements,
      }));
    } catch {

    }
  }, [selectAll]);

  async function buscarIdsProcesso() {
    const responseIds = await buscarIdsHistoricoSmsEmail();
    const ids = responseIds?.data || [];
    setTodosIds(ids.reduce((acc, nroProcessoChecked) => (
      {
        ...acc,
        [nroProcessoChecked]: false,
      }
    ), {}));
  }


  const changeSelectAllHandler = useCallback((event) => {
    const { checked } = event.target;
    setSelectAll(checked);
    setTodosIds(Object.keys(todosIds).reduce((acc, nroProcessoCheck) => ({
      ...acc,
      [nroProcessoCheck]: checked,
    }), {}));
    const tableCopy = settingsTable.content.map(content => ({
      ...content,
      checked,
    }));
    setSettingsTable({ ...settingsTable, content: tableCopy });
  }, [settingsTable, todosIds]);

  const changeTableRowCheckboxHandler = useCallback((event) => {
    const { name, checked } = event.target;
    setTodosIds(old => ({
      ...old,
      [name]: checked,
    }));
    const tableCopy = settingsTable.content.map((content) => {
      if (name === String(content.nroProcesso)) {
        return {
          ...content,
          checked,
        };
      }
      return content;
    });
    setSettingsTable({ ...settingsTable, content: tableCopy });
  }, [settingsTable, setSettingsTable]);

  const buildCheckbox = useCallback((checked, name) => (
    <CheckboxField
      name={name}
      checked={getCheckedValue(disableCheckBoxTable, checked)}
      onChange={changeTableRowCheckboxHandler}
      disabled={disableCheckBoxTable}
    />
  ), [disableCheckBoxTable, changeTableRowCheckboxHandler]);

  const buildCheckboxSelectAll = useCallback(() => (
    <TooltipUI title="Selecionar Todos">
      <div style={{ paddingRight: '6px' }}>
        <CheckboxField
          checked={getCheckedValue(disableCheckBoxTable, selectAll)}
          onChange={changeSelectAllHandler}
          disabled={disableCheckBoxTable}
        />
      </div>
    </TooltipUI>
  ), [changeSelectAllHandler, disableCheckBoxTable, selectAll]);

  const [arrowOrdenacao, setArrowOrdenacao] = useState(false);

  const onClickOrdernacaoColumn = useCallback(async (columnName) => {
    await loadingPage(async () => pesquisarProcessos({ element: columnName, order: arrowOrdenacao ? 'ASC' : 'DESC' }, settingsTable.page, settingsTable.rowsPerPage));
  }, [loadingPage, pesquisarProcessos, settingsTable, arrowOrdenacao]);

  const tableColumns = useMemo(() => [
    new TableHeader({
      id: 'selecionarTodos',
      label: buildCheckboxSelectAll(),
      width: 16,
    }),
    new TableHeader({
      id: 'proporcaoFase',
      label: <OrdenacaoColuna
        isIconLabel
        columnLabel="fas fa-hourglass-half"
        idColumn="proporcaoFase"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('proporcaoFase')}
      />,
      alignCell: 'center',
      width: 50,
    }),
    new TableHeader({
      id: 'nroProcesso',
      label: <OrdenacaoColuna
        columnLabel="Número"
        idColumn="nroProcesso"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('nroProcesso')}
      />,
      alignCell: 'center',
      width: 102,
    }),

    new TableHeader({
      id: 'dataAbertura',
      label: <OrdenacaoColuna
        columnLabel="Data de Abertura"
        idColumn="dataAbertura"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('dataAbertura')}
      />,
      alignCell: 'center',
      width: 153,
    }),
    new TableHeader({
      id: 'nomeDevedor',
      label: <OrdenacaoColuna
        columnLabel="Nome do Devedor"
        idColumn="nomeDevedor"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('nomeDevedor')}
      />,
      alignCell: 'left',
      showTip: true,
      maxWidth: 204,
      width: 204,
    }),
    new TableHeader({
      id: 'estagio',
      label: <OrdenacaoColuna
        columnLabel="Estágio do Processo"
        idColumn="estagio"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('estagio')}
      />,
      alignCell: 'center',
      width: 153,
    }),
    new TableHeader({
      id: 'valorProcesso',
      label: <OrdenacaoColuna
        columnLabel="Valor Principal"
        idColumn="valorProcesso"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('valorProcesso')}
      />,
      alignCell: 'flex-end',
      width: 153,
    }),
    new TableHeader({
      id: 'sms',
      isIconLabel: true,
      iconLabel: 'fas fa-sms',
      hintLabel: 'SMS',
      alignCell: 'center',
      width: 54,
    }),
    new TableHeader({
      id: 'email',
      isIconLabel: true,
      iconLabel: 'fas fa-envelope',
      hintLabel: 'E-mail',
      alignCell: 'center',
      width: 52,
    }),
  ], [columnOrdenacao, arrowOrdenacao, buildCheckboxSelectAll, onClickOrdernacaoColumn]);

  const onClickTestarSmsHandler = useCallback(() => {
    setOpenTestarSMS(true);
  }, [setOpenTestarSMS]);

  const onCloseTestarSmsHandler = useCallback(() => {
    setOpenTestarSMS(false);
  }, [setOpenTestarSMS]);

  const onClickEnviarHandler = useCallback(() => {
    setOpenConfirmarEnvioDialog(true);
  }, [setOpenConfirmarEnvioDialog]);

  const onClickOkEnviouSucessoHandler = useCallback(async () => {
    setOpenEnviouSucessoDialog(false);
    await loadingPage(async () => {
      await buscarIdsProcesso();
      await pesquisarProcessos(columnOrdenacao, settingsTable.page, settingsTable.rowsPerPage);
    });
    setQtdEmailEnviado(0);
    setQtdSmsEnviado(0);
  }, [settingsTable, pesquisarProcessos, columnOrdenacao, loadingPage]);

  const onChangePageHandler = useCallback(async (event, newPage) => {
    await loadingPage(async () => pesquisarProcessos(columnOrdenacao, newPage, settingsTable.rowsPerPage));
    setSettingsTable(old => ({ ...old, page: newPage }));
  }, [pesquisarProcessos, loadingPage, columnOrdenacao, settingsTable.rowsPerPage]);

  const onChangeRowsPerPageHandler = useCallback(async (event) => {
    await loadingPage(async () => pesquisarProcessos(columnOrdenacao, settingsTable.page, event.target.value));
    setSettingsTable(old => ({ ...old, rowsPerPage: event.target.value }));
  }, [pesquisarProcessos, loadingPage, columnOrdenacao, settingsTable.page]);


  function configureContadorEnvio(email, sms) {
    if (email) {
      setQtdEmailEnviado(old => old + 1);
    }
    if (sms) {
      setQtdSmsEnviado(old => old + 1);
    }
  }

  const enviarSmsEmailChamada = useCallback(async (i, idsProcesso) => {
    if (i < idsProcesso.length) {
      const idProcesso = idsProcesso[i];
      try {
        const response = await enviarSmsEmail({ idProcesso, idUsuario });
        configureContadorEnvio(response.data.email, response.data.sms);
        if (i < idsProcesso.length) {
          i += 1;
          await enviarSmsEmailChamada(i, idsProcesso);
        }
      } catch {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setOpenEnviouSucessoDialog(true);
    }
  }, [idUsuario]);

  const onClickConfirmarEnviarHandler = useCallback(() => {
    setOpenConfirmarEnvioDialog(false);
    setLoading(true);
    const idsProcesso = [];
    [Object.entries(todosIds).filter(idz => idz[1] === true)][0].forEach((processo) => {
      idsProcesso.push(+processo[0]);
    });
    enviarSmsEmailChamada(0, idsProcesso).then();
  }, [todosIds, enviarSmsEmailChamada]);

  const onClickCancelarHandler = useCallback(() => {
    setOpenConfirmarEnvioDialog(false);
  }, []);

  const buildTable = useCallback((content, index) => {
    if (content.length === 0) {
      return [];
    }
    return {
      id: content.nroProcesso,
      values: {
        selecionarTodos: buildCheckbox(todosIds[content.nroProcesso] || false, `${content.nroProcesso}`),
        proporcaoFase: (
          <TempoFaseAmpulheta
            corAlertaFase={content.corAlertaFase}
            dataVencimento={content.dataVencimentoFaseHint}
          />),
        nroProcesso: content.nroProcesso,
        dataAbertura: getDateTimeMask(content.dataAbertura, true),
        valorProcesso: getMoneyMask(content.valorProcesso),
        nomeDevedor: content.nomeDevedor,
        estagio: content.estagio,
        sms: getCheckedIcon(content.ddd),
        email: getCheckedIcon(content.email),
      },
    };
  }, [getCheckedIcon, buildCheckbox, todosIds]);

  // Constrói a tabela.
  const tableContent = useMemo(
    () => settingsTable.content.map((processo, index) => buildTable(
      processo,
      index,
    )),
    [settingsTable.content, buildTable],
  );

  useEffect(() => {
    if (tableContent?.length > 0) {
      setSelectAll(Object.keys(todosIds).every(key => todosIds[key] === true));
    }
    setTableRows(tableContent);
  }, [tableContent, todosIds]);


  const carregarDadosIniciais = useCallback(async () => {
    setLoading(true);
    try {
      await buscarIdsProcesso();
      const response = await buscarHistoricoSmsEmail(['nroProcesso,ASC'], 0, 10);
      setTableRows([]);
      const tableCopy = response.data.content.map(processo => ({
        ...processo,
        checked: false,
      }));

      setSettingsTable(({
        content: tableCopy,
        page: response.data.number,
        rowsPerPage: response.data.size,
        size: response.data.totalElements,
      }));
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    carregarDadosIniciais().then();
  }, [carregarDadosIniciais, location.refreshPage]);

  // Ao clicar na opção do menu.
  useEffect(() => {
    setOpenConfirmarEnvioDialog(false);
    setOpenEnviouSucessoDialog(false);
    setOpenTestarSMS(false);
    setSelectAll(false);
    setQtdEmailEnviado(0);
    setQtdSmsEnviado(0);
    setColumnOrdenacao({
      element: 'nroProcesso',
      order: 'ASC',
    });
    setTableRows([]);
    setSettingsTable(old => ({
      ...old, content: [], page: 0, rowsPerPage: 10,
    }));
  }, [location.refreshPage]);

  return (
    <PaperUI>
      <LoadingUI show={loading} />
      <ConfirmarEnvioDialog
        open={openConfirmarEnvioDialog}
        onClickConfirmarHandler={onClickConfirmarEnviarHandler}
        onClickCancelarHandler={onClickCancelarHandler}
      />
      <EnviouSucessoDialog
        open={openEnviouSucessoDialog}
        qtdEmails={qtdEmailEnviado}
        qtdSms={qtdSmsEnviado}
        onCloseHandler={onClickOkEnviouSucessoHandler}
      />
      <CardWrapper showLoading={loading}>
        <TestarSmsDialog
          open={openTestarSMS}
          setLoading={setLoading}
          onCloseHandler={onCloseTestarSmsHandler}
        />
        <div className={styles.div__header}>
          <div className={utilStyles.mr12}>
            <ButtonUI
              color="white"
              className={styles.div__buttonsHeader}
              onClick={onClickTestarSmsHandler}
            >
              Testar SMS
            </ButtonUI>
          </div>
          <div>
            <ButtonWithIconUI
              label="Enviar"
              iconFontAwesome="fas fa-paper-plane"
              className={styles.div__buttonsHeader}
              disabled={[Object.entries(todosIds).filter(idz => idz[1] === true)][0].length === 0}
              onClick={onClickEnviarHandler}
            />
          </div>
        </div>
        <Grid container direction="column">
          <Grid item xs={12} sm={12}>
            <Typography component="h3" className={utilStyles.tableTitle}>
              Resultado da Pesquisa
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Table
              rows={tableRows}
              columns={tableColumns}
              page={settingsTable.page}
              size={settingsTable.size}
              rowsPerPage={settingsTable.rowsPerPage}
              onChangePage={onChangePageHandler}
              onChangeRowsPerPage={onChangeRowsPerPageHandler}
            />
          </Grid>
        </Grid>
      </CardWrapper>
    </PaperUI>
  );
}

export default withRouter(PesquisaSmsASPage);
