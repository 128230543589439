import styled from 'styled-components';
import { Button, Paper, makeStyles } from '@material-ui/core';
import { PaperUI } from '../../components';
import { lightGrey, strongPink, white } from '../../helpers/constants/colors.constants';

export default makeStyles({

  pesquisaAvancadaButton: {
    color: '#034ddc',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    fontSize: '12px',
    textTransform: 'none',
    width: '50%',
  },

  pesquisaSimplesButton: {
    color: '#707070',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    fontSize: '12px',
    textTransform: 'none',
    width: '29%',
    marginTop: '10px',
  },

  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    // marginRight: '49px',
  },

  dialogRecebimentoManual: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '685px',
      maxHeight: '280px',
    },
  },
  dialogDistribuicao: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '685px',
      maxHeight: '280px',
    },
  },

});


export const Filter = styled.div`
  display:flex ;
 align-items:center ;
width:100%;
margin-top:15px;
`;

export const PesquisaAvancada = styled.div`
  display:flex ;
 justify-content:end;
 margin-right:30px;

`;


export const Container = styled.div`
  margin: 6px 0px 12px 0;
  box-Shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-Color: ${white};
  padding:22px;

  > div+div {
    display:flex ;
    flex-direction:column ;
      }

  #resumo{
    display:flex ;
    flex-direction:column ;
    justify-content:start ;
    width:45% ;
  }
`;

export const Header = styled.div`
  display:flex ;
  justify-content:space-between ;
  width:100% ;
`;

export const ContainerPrincipal = styled(PaperUI)`

    #resumo{
     display:flex ;
     flex-direction:column ;
     justify-content:start ;
     width:45% ;
      }
 `;

export const ContainerResumo = styled.div`
  display: flex ;
  justify-content:center ;
  gap:8px ;
  flex-flow:column ;


    > h3 {
      display:flex ;
      justify-content:center ;
      font-size:16px ;
    }

    > div {
      display:flex ;
      flex-direction:row ;
      gap:15px ;
      justify-content:center ;
      color:${white} ;

    h3 {
      text-align:center ;
      font-size:16px ;
    }

    h4 {
      text-align:center ;
      font-size:14px ;
      font-weight:700 ;
    }
    }

    > div + div {
      display:flex ;
      flex-direction:row ;
      gap:15px ;
      justify-content:center ;
      color:${white} ;
    }
`;

export const ContainerPesquisaAvancada = styled.div`

    width: 104%;

  > div {
    display: flex;
    width: 110%;

    #codigoCliente{
      width: 140%;
    margin-right: 8px;
    }

  }
`;

export const ButtonPesquisaAvancada = styled.div`
  > div {
    width: 94%;
    display: flex;
    justify-content: flex-end;

@media (min-width: 1400px) {
      width: 94%;
    }

    > button {
      width: 50%;
    }

  }
`;

export const FiltroPesquisa = styled.div`
  display: flex ;
  margin-top:15px;


  > div {
    display: flex ;
    flex-direction:row ;
      > button {
        background-color: ${strongPink};
        color: ${white};
        width: 44px;
        height: 44px;
        margin-left: 7px;

  &:hover {
    background-color: ${strongPink};
    opacity: .7;
  }

  &:disabled {
    border: 1px solid ${lightGrey};
      }
    }
  }
`;

export const ButtonImprimir = styled(Button)`
display: flex;
justify-content: space-between;
margin-top:18px ;

&.MuiButton-root {
  width: 73px;
  padding: 4px 0;
  font-weight: bold;
  text-transform: none;
  color: #000000;
  font-size:12px ;

  &.Mui-disabled {
    opacity: 0.64;
  }
}
`;


export const ButtonExcel = styled(Button)`
display: flex;
justify-content: space-between;
margin-top:18px ;

&.MuiButton-root {
  width: 107px;
  padding: 4px 0;
  font-weight: bold;
  text-transform: none;
  color: #000000;
  font-size:12px ;

  &.Mui-disabled {
    opacity: 0.64;
  }
}
`;

export const ButtonAtualizar = styled(Button)`
margin-right: 9px;
color:  #564872;

    border: 1px solid #564872;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    width: 118px;
    height: 26px;
    text-transform: none;
    min-width: 89px;


:disabled {
color: #c4c4c4;
}

& .MuiButton-label span {
    margin-left: 8px;
    display:flex;
    margin-top:2px
  }
`;

export const AlignButtons = styled.div`
   display: flex;
   width:100%;
   justify-content:end;
   margin-top:30px;
`;

export const AlignLabel = styled.div`
   display: flex;
   width:100%;
   justify-content:end;
   margin-top:15px;
  gap:5px;
 > svg {
  margin-top:1px;
 }
`;

export const TitleTable = styled.div`
  margin-bottom:13px ;
  margin-top:25px ;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
`;

export const TableWithAccordion = styled(Paper)`
  background-color: #f1f4f9;
  border-radius: 7px;
  margin-top: 22px;
  max-height: ${props => (props.isScrollable ? props.maxTableHeigth : 'auto')};
  overflow-x: auto;

  & td {
    font-size: .75rem;
    padding: 0px 8px !important;
  }

  & th {
    cursor: default;
    font-size: .75rem;
    color: #034ddc;
    font-weight: bold;
    padding: 0px 8px !important;
    height: 33px;
    text-align: center;
    line-height: 1rem;
  }
`;
