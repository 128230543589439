import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@mui/material';
import {
  CONFIGURACOES_TITLE,
  INFO_GERAL_TITLE,
  PROCESSAMENTO_DIFERENCIADO_TITLE,
  PROCESSAMENTO_NORMAL_TITLE,
  PROCESSAMENTO_TITLE,
  PROCESSOS_DUPLICCADOS,
  PROCESSOS_FASE_INICIAL,
  PROCESSOS_TITULOS_ANTIGOS,
  PROCESSOS_VALOR_INFERIOR,
  TOTAL_BORDERO_TITLE,
  VALOR_TOTAL_BORDERO_TITLE,
} from './ProcessamentoBordero.constants';
import FormWrapperWithTitle from '../../UI/Wrapper/Form/FormWrapperWithTitle';
import Grid24 from '../../UI/Grid24/Grid24';
import useUtilStyles from '../../../helpers/styles/utils.styles';
import useStyles from './processamentoBordero.styles';
import { FooterFormButtonGroup } from '../../../helpers/styles/utils.styledComponents';
import { GroupDefinitions } from '../../Cliente/Crud/Tabs/BaseCalculo/Honorarios/styles';
import Radio from '../../UI/Field/Radio/RadioField';
import CheckboxField from '../../UI/Field/Checkbox/CheckboxField';
import { changeOptionProcessamentoAction } from '../../../store/Borderos/Processar/processarBorderos.store';
import { iniciarProcessamentoBorderoAction } from '../../../store/Borderos/Processar/processarBorderos.saga';
import DialogNew from '../../../layouts/FormDialog/DialogNew';

function ProcessamentoBordero({
  open,
  onClose,
}) {
  const dispatch = useDispatch();
  const utilStyles = useUtilStyles();
  const styles = useStyles();

  const informacoesProcessamentoBordero = useSelector(states => ({ ...states.processarBorderos.informacoesProcessamentoBordero }));
  const processamentoDiferenciados = useSelector(states => ({ ...states.processarBorderos.processamentoDiferenciado }));

  const qntdSelecionada = `${TOTAL_BORDERO_TITLE}${informacoesProcessamentoBordero.qtdBorderoSelecionado}`;
  const valorTotalBordero = `${VALOR_TOTAL_BORDERO_TITLE}${informacoesProcessamentoBordero.valorTotalBorderoProcessamento}`;

  const [isDiferenciado, setIsDiferenciado] = useState(false);

  const [definition, setDefinition] = useState('normal');
  const [showNormalButton, setShowNormalButton] = useState(false);
  const processamentoDiferenciado = useSelector(states => ({ ...states.processarBorderos.processamentoDiferenciado }));

  // Configura os valores default quando trocar o radio button
  const defaultValues = useCallback(() => {
    dispatch(changeOptionProcessamentoAction('manterProcessoFaseInicial', false));
    dispatch(changeOptionProcessamentoAction('criarProcessoDuplicidade', false));
    dispatch(changeOptionProcessamentoAction('processarTitulosAntigos', false));
    dispatch(changeOptionProcessamentoAction('aceitarProcessoValorBaixo', false));
  }, [dispatch]);

  // Configura o valor do radio button
  const onChangeRadioHandler = useCallback((event) => {
    const { value } = event.target;
    if (value !== 'diferenciado') {
      defaultValues();
      setIsDiferenciado(false);
    }
    setDefinition(value);
  }, [setDefinition, defaultValues, setIsDiferenciado]);

  const onChangeCheckedHanlder = useCallback((event) => {
    const { name, checked } = event.target;
    dispatch(changeOptionProcessamentoAction(name, checked));
  }, [dispatch]);

  // Checa se tem algum checkbox marcado no processamento diferenciado
  const isAnyChecked = useCallback(() => {
    const {
      manterProcessoFaseInicial, processarTitulosAntigos, criarProcessoDuplicidade, aceitarProcessoValorBaixo,
    } = processamentoDiferenciados;
    return manterProcessoFaseInicial.value || processarTitulosAntigos.value || criarProcessoDuplicidade.value || aceitarProcessoValorBaixo.value;
  }, [processamentoDiferenciados]);

  const renderDiferenciadoComponent = () => isDiferenciado && definition === 'diferenciado' && isAnyChecked();

  const onCloseHandler = useCallback(() => {
    setIsDiferenciado(false);
    setDefinition('normal');
    defaultValues();
    onClose();
  }, [onClose, setIsDiferenciado, setDefinition, defaultValues]);

  const onClickIniciarHandler = useCallback(() => {
    if (definition === 'diferenciado' && isAnyChecked()) {
      setIsDiferenciado(true);
      setShowNormalButton(false);
    } else {
      dispatch(iniciarProcessamentoBorderoAction());
    }
  }, [definition, isAnyChecked, dispatch, setShowNormalButton]);

  const onClickIniciarDiferenciadoHandler = useCallback(() => {
    dispatch(iniciarProcessamentoBorderoAction());
    setDefinition('normal');
    defaultValues();
    setIsDiferenciado(false);
  }, [dispatch, defaultValues]);

  const onClickCancelarDiferenciadoHandler = useCallback(() => {
    setShowNormalButton(true);
  }, [setShowNormalButton]);

  const normalIniciarButton = () => (
    <FooterFormButtonGroup>
      <Button onClick={onCloseHandler}>Cancelar</Button>
      {definition === 'diferenciado' && (
        <Button onClick={onClickIniciarHandler} disabled={!isAnyChecked()}>Iniciar</Button>
      )}
      {definition !== 'diferenciado' && (
        <Button onClick={onClickIniciarHandler}>Iniciar</Button>
      )}
    </FooterFormButtonGroup>
  );

  const diferenciadoIniciarButton = () => (
    <div className={styles.diferenciadoContainer}>
      <div className={styles.diferenciadoText}>
        Tem certeza de que deseja um processamento diferenciado?
      </div>
      <IconButton className={styles.cancelIcon} onClick={onClickCancelarDiferenciadoHandler}>
        <CloseIcon />
      </IconButton>
      <IconButton className={styles.confirmarIcon} onClick={onClickIniciarDiferenciadoHandler}>
        <CheckIcon />
      </IconButton>
    </div>
  );

  return (
    <DialogNew
      open={open}
      text={PROCESSAMENTO_TITLE}
      onClose={onCloseHandler}
    >
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24}>
          <FormWrapperWithTitle title={INFO_GERAL_TITLE}>
            <div className={styles.info}>
              <div className={styles.displayFlex}>
                <div className={styles.bold}>
                  {qntdSelecionada}
                </div>
              </div>
              <div className={styles.dFlexMt7}>
                <div className={styles.bold}>
                  {valorTotalBordero}
                </div>
              </div>
            </div>
          </FormWrapperWithTitle>
        </Grid24>
      </Grid24>
      <Grid24 container isFormContainer spacing="1" className={utilStyles.mt16}>
        <Grid24 xs={24}>
          <FormWrapperWithTitle title={CONFIGURACOES_TITLE}>
            <GroupDefinitions
              aria-label="repasses-definicoes"
              value={definition}
              onChange={onChangeRadioHandler}
            >
              <Grid24 xs={24} sm={12}>
                <Radio label={PROCESSAMENTO_NORMAL_TITLE} value="normal" />
              </Grid24>
              <Grid24 xs={24} sm={12}>
                <Radio label={PROCESSAMENTO_DIFERENCIADO_TITLE} value="diferenciado" />
              </Grid24>
            </GroupDefinitions>
            {(definition === 'diferenciado'
              && (
                <>
                  <Grid24 isFormContainer spacing={1} className={utilStyles.pl40}>
                    <Grid24 xs={24} sm={12}>
                      <CheckboxField
                        name={processamentoDiferenciado.manterProcessoFaseInicial.name}
                        checked={processamentoDiferenciado.manterProcessoFaseInicial.value}
                        label={PROCESSOS_FASE_INICIAL}
                        onChange={onChangeCheckedHanlder}
                      />
                    </Grid24>
                    <Grid24 xs={24} sm={12}>
                      <CheckboxField
                        name={processamentoDiferenciado.criarProcessoDuplicidade.name}
                        checked={processamentoDiferenciado.criarProcessoDuplicidade.value}
                        label={PROCESSOS_DUPLICCADOS}
                        onChange={onChangeCheckedHanlder}
                      />
                    </Grid24>
                  </Grid24>
                  <Grid24 isFormContainer spacing={1} className={utilStyles.pl40}>
                    <Grid24 xs={24} sm={12} className={utilStyles.pt8}>
                      <CheckboxField
                        name={processamentoDiferenciado.processarTitulosAntigos.name}
                        checked={processamentoDiferenciado.processarTitulosAntigos.value}
                        label={PROCESSOS_TITULOS_ANTIGOS}
                        onChange={onChangeCheckedHanlder}
                      />
                    </Grid24>
                    <Grid24 xs={24} sm={12} className={utilStyles.pt8}>
                      <CheckboxField
                        name={processamentoDiferenciado.aceitarProcessoValorBaixo.name}
                        checked={processamentoDiferenciado.aceitarProcessoValorBaixo.value}
                        label={PROCESSOS_VALOR_INFERIOR}
                        onChange={onChangeCheckedHanlder}
                      />
                    </Grid24>
                  </Grid24>
                </>
              ))}
          </FormWrapperWithTitle>
        </Grid24>
      </Grid24>
      {(!renderDiferenciadoComponent() || showNormalButton) && (normalIniciarButton())}
      {(renderDiferenciadoComponent() && !showNormalButton) && (diferenciadoIniciarButton())}
    </DialogNew>
  );
}

export default React.memo(ProcessamentoBordero);
