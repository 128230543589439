import IconButton from '@material-ui/core/IconButton';
import { Edit } from '@material-ui/icons';
import React from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import OrdenacaoColuna from '../../../../components/UI/Tabela/Ordenacao/OrdenacaoColuna';
import TooltipUI from '../../../../components/UI/Tooltip/Tooltip';
import { getMoneyMask, toBrazilianDate } from '../../../../helpers';
import { blackTwo, lightGrey } from '../../../../helpers/constants/colors.constants';
import TableHeader from '../../../../models/TableHeader';

class RecebimentoClientesFactory {
  makeCabecalhoTabela(
    columnOrdenacao, setColumnOrdenacao, arrowDirection, setArrowDirection, onClickColumnOrdenar,
  ) {
    return [
      new TableHeader({
        id: 'estarLiberado',
        label: (
          <IconButton
            size="small"
            disabled="true"
            color="#034ddc"
          >
            <AiFillCheckCircle
              color="#034ddc"
              size={16}
            />
          </IconButton>
        ),
        alignCell: 'center',
        maxWidth: 40,
      }),
      new TableHeader({
        id: 'dataRecebimento',
        label: <OrdenacaoColuna
          columnLabel="Data do Recebimento"
          idColumn="dataRecebimento"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 155,
      }),
      new TableHeader({
        id: 'descricaoFormaPgto',
        label: <OrdenacaoColuna
          columnLabel="Tipo"
          idColumn="descricaoFormaPgto"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 75,
      }),

      new TableHeader({
        id: 'dataCadastramento',
        label: <OrdenacaoColuna
          columnLabel="Data do Cadastramento"
          idColumn="dataCadastramento"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 155,
      }),
      new TableHeader({
        id: 'idCliente',
        label: <OrdenacaoColuna
          columnLabel="Cód. do Cliente"
          idColumn="idCliente"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 115,
      }),
      new TableHeader({
        id: 'nomeCliente',
        label: <OrdenacaoColuna
          columnLabel="Nome do Cliente"
          idColumn="nomeCliente"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'left',
        maxWidth: 250,
      }),
      new TableHeader({
        id: 'valorRecebimento',
        label: <OrdenacaoColuna
          columnLabel="Valor Recebido"
          idColumn="valorRecebimento"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'right',
        maxWidth: 120,
      }),
      new TableHeader({
        id: 'acoes',
        label: 'Ações',
        alignCell: 'center',
        maxWidth: 112,
      }),

    ];
  }

  makeConteudoTabela(recebimentos, openDialogDelete, handleOpenCancelLancamento, handleCancelarPrestacao, handleLiberarLancamento, goToEditar) {
    if (!recebimentos || recebimentos.length === 0) {
      return [];
    }

    return recebimentos.map(recebimento => ({
      id: recebimento.idLancamentoCliente,
      values: {
        estarLiberado: (
          <div>
            {(recebimento.estarLiberado === 'S') && (
              <IconButton
                size="small"
                disabled="true"
              >
                <AiFillCheckCircle
                  color={blackTwo}
                  size={16}
                />
              </IconButton>
            )}
          </div>
        ),
        idLancamentoCliente: recebimento.idLancamentoCliente,
        agencia: recebimento.agencia,
        codigoOcorrencia: recebimento.codigoOcorrencia,
        contaNumero: recebimento.contaNumero,
        nomeBanco: recebimento.nomeBanco,
        nomeUsuario: recebimento.nomeUsuario,
        nossoNumero: recebimento.nossoNumero,
        observacao: recebimento.observacao,
        valorOriginal: getMoneyMask(recebimento.valorOriginal),
        dataCadastramento: toBrazilianDate(recebimento.dataCadastramento),
        descricaoFormaPgto: recebimento.descricaoFormaPgto,
        dataRecebimento: toBrazilianDate(recebimento.dataRecebimento),
        idCliente: recebimento.idCliente,
        nomeCliente: recebimento.nome,
        valorRecebimento: getMoneyMask(recebimento.valorRecebimento),
        acoes: (
          <div>
            {(recebimento.estarLiberado === 'S' || recebimento.idTipoFormaPagto === 0) ? (
              <IconButton
                disabled
                size="small"
              >
                <Edit
                  style={{
                    color: lightGrey,
                  }}
                  size={13}
                />
              </IconButton>
            ) : (
              <TooltipUI title="Editar">
                <IconButton
                  size="small"
                  onClick={() => goToEditar(recebimento.idLancamentoCliente)}
                >
                  <Edit
                    style={{
                      color: blackTwo,
                    }}
                    size={13}
                  />
                </IconButton>
              </TooltipUI>
            )}

            {(recebimento.estarLiberado === 'S') ? (
              <IconButton
                disabled
                size="small"
              >
                <FaFileInvoiceDollar
                  size={17}
                  color={lightGrey}
                />
              </IconButton>
            ) : (
              <TooltipUI title="Baixa">
                <IconButton
                  size="small"
                  onClick={() => (recebimento.idTipoFormaPagto === 0 ? window.open(`/conta-corrente-cliente?nossoNumeroBoleto=${recebimento.nossoNumero}`, '_blank')
                    : window.open(`/conta-corrente-cliente?idCliente=${recebimento.idCliente}`, '_blank'))}
                >
                  <FaFileInvoiceDollar
                    size={17}
                    color={blackTwo}
                  />
                </IconButton>
              </TooltipUI>
            )}
            {(recebimento.estarLiberado === 'S') ? (
              <TooltipUI title="Cancelar Liberação">
                <IconButton
                  size="small"
                  onClick={() => handleOpenCancelLancamento(recebimento.idLancamentoCliente)}
                >
                  <AiFillCheckCircle
                    color={blackTwo}
                    size={18}
                  />
                </IconButton>
              </TooltipUI>
            ) : (
              <TooltipUI title="Liberar ">
                <IconButton
                  size="small"
                  onClick={() => handleLiberarLancamento(recebimento.idLancamentoCliente)}
                >
                  <AiFillCheckCircle
                    color={blackTwo}
                    size={18}
                  />
                </IconButton>
              </TooltipUI>
            )}
            {(recebimento.estarLiberado === 'S' || recebimento.idTipoFormaPagto === 0) ? (

              <IconButton size="small" disabled>
                <MdDeleteForever
                  color={lightGrey}
                  size={22}
                />
              </IconButton>
            ) : (
              <TooltipUI title="Excluir">
                <IconButton
                  size="small"
                  onClick={() => openDialogDelete(recebimento.idLancamentoCliente)}
                >
                  <MdDeleteForever
                    color={blackTwo}
                    size={22}
                  />
                </IconButton>
              </TooltipUI>
            )}
          </div>
        ),
      },
    }));
  }
}

export const recebimentoClientesFactory = new RecebimentoClientesFactory();
