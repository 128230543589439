import FieldModel, { POSITIVE_INTEGER } from '../../../models/FieldModel';
import { getAttributeExistingObject } from '../../../helpers/utils/utils';
import { buildDialog } from '../../../helpers/factory/global.factory';
import { buildEnderecos } from '../../../helpers/factory/endereco.factory';
import { buildTelefones } from '../../../helpers/factory/telefone.factory';
import { buildTitulos } from '../../../helpers/factory/titulos.factory';
import { buildClienteBordero } from '../../../helpers/factory/cliente.factory';
import { buildSimpleEmails } from '../../../helpers/factory/email.factory';
import {
  CLEAR_ALL,
  CLEAR_BORDERO,
  SET_ATTRIBUTE,
  SET_BORDERO,
  SET_BORDERO_WITH_ERROR,
  SET_CATEGORIA,
  SET_CLIENTE,
  SET_DADOS_RETORNO,
  SET_DEVEDOR,
  SET_DIALOG,
  SET_FOCUS,
  SET_LOADING,
  SET_OBJECT,
  SET_OBJECT_ATTRIBUTE,
  SET_OBJECT_FOCUS,
  SET_OBJECTS,
  SET_SUCCESS_DIALOG,
  UPDATE_NOME_FANTASIA,
} from './crudBordero.constants';

function buildRequestFields(fields) {
  return {
    codigoCliente: new FieldModel({
      name: 'codigoCliente',
      value: getAttributeExistingObject(fields, 'codigoCliente'),
      maxLength: 9,
      type: POSITIVE_INTEGER,
    }),
    categoria: new FieldModel({
      name: 'categoria', value: getAttributeExistingObject(fields, 'categoria'),
    }),
    cliente: buildClienteBordero(getAttributeExistingObject(fields, 'cliente')),
    codigoNoCliente: new FieldModel({
      name: 'codigoNoCliente',
      value: getAttributeExistingObject(fields, 'codigoNoCliente'),
      maxLength: 40,
    }),
    dataCadastramento: new FieldModel({
      name: 'dataCadastramento',
      value: getAttributeExistingObject(fields, 'dataCadastramento'),
    }),
    dataProcessamento: new FieldModel({
      name: 'dataProcessamento',
      value: getAttributeExistingObject(fields, 'dataProcessamento'),
    }),
    documento: new FieldModel({
      name: 'documento', value: getAttributeExistingObject(fields, 'documento'),
    }),
    idBorderoDevedor: new FieldModel({
      name: 'idBorderoDevedor', value: getAttributeExistingObject(fields, 'idBorderoDevedor'),
    }),
    inscricaoEstadual: new FieldModel({
      name: 'inscricaoEstadual',
      value: getAttributeExistingObject(fields, 'inscricaoEstadual'),
      maxLength: 15,
    }),
    instrucao: new FieldModel({
      name: 'instrucao',
      value: getAttributeExistingObject(fields, 'instrucao'),
      maxLength: 1000,
    }),
    nomeAdvogado: new FieldModel({
      name: 'nomeAdvogado',
      value: getAttributeExistingObject(fields, 'nomeAdvogado'),
      maxLength: 40,
    }),
    nomeFantasia: new FieldModel({
      name: 'nomeFantasia',
      value: getAttributeExistingObject(fields, 'nomeFantasia'),
      maxLength: 60,
    }),
    nomePreposto: new FieldModel({
      name: 'nomePreposto',
      value: getAttributeExistingObject(fields, 'nomePreposto'),
      maxLength: 20,
    }),
    razaoSocial: new FieldModel({
      name: 'razaoSocial',
      value: getAttributeExistingObject(fields, 'razaoSocial'),
      maxLength: 60,
    }),
    statusBordero: new FieldModel({
      name: 'statusBordero',
      value: getAttributeExistingObject(fields, 'statusBordero'),
    }),
    telefoneAdvogado: new FieldModel({
      name: 'telefoneAdvogado',
      value: getAttributeExistingObject(fields, 'telefoneAdvogado'),
      maxLength: 25,
      type: POSITIVE_INTEGER,
    }),
    idDevedor: new FieldModel({
      name: 'idDevedor',
      value: getAttributeExistingObject(fields, 'idDevedor'),
    }),
    emails: buildSimpleEmails(getAttributeExistingObject(fields, 'emails')),
    enderecos: buildEnderecos(getAttributeExistingObject(fields, 'enderecos')),
    telefones: buildTelefones(getAttributeExistingObject(fields, 'telefones')),
    titulos: buildTitulos(getAttributeExistingObject(fields, 'titulos')),
  };
}

function buildDevedor(fields) {
  return {
    inscricaoEstadual: new FieldModel({
      name: 'inscricaoEstadual',
      value: getAttributeExistingObject(fields, 'inscricaoEstadual'),
      maxLength: 15,
    }),
    nomeAdvogado: new FieldModel({
      name: 'nomeAdvogado',
      value: getAttributeExistingObject(fields, 'nomeAdvogado'),
      maxLength: 40,
    }),
    nomeFantasia: new FieldModel({
      name: 'nomeFantasia',
      value: getAttributeExistingObject(fields, 'nomeFantasia'),
      maxLength: 60,
    }),
    razaoSocial: new FieldModel({
      name: 'razaoSocial',
      value: getAttributeExistingObject(fields, 'razaoSocial'),
      maxLength: 60,
    }),
    telefoneAdvogado: new FieldModel({
      name: 'telefoneAdvogado',
      value: getAttributeExistingObject(fields, 'telefoneAdvogado'),
    }),
    idDevedor: new FieldModel({
      name: 'telefoneAdvogado',
      value: getAttributeExistingObject(fields, 'idDevedor'),
    }),
    nomePreposto: new FieldModel({
      name: 'nomePreposto',
      value: getAttributeExistingObject(fields, 'preposto'),
      maxLength: 20,
    }),
    codigoNoCliente: new FieldModel({
      name: 'codigoNoCliente',
      value: getAttributeExistingObject(fields, 'codigoNoCliente'),
      maxLength: 40,
    }),
    emails: buildSimpleEmails(getAttributeExistingObject(fields, 'emails')),
    enderecos: buildEnderecos(getAttributeExistingObject(fields, 'enderecos')),
    telefones: buildTelefones(getAttributeExistingObject(fields, 'telefones')),
  };
}

function buildInitialStates(fields) {
  return {
    loading: {
      global: false,
      cliente: false,
      devedor: false,
    },
    requestFields: buildRequestFields(fields),
    notFormValid: true,
    dialog: buildDialog(),
    successDialog: buildDialog(false, 'success'),
  };
}

function setBorderosHandler(states, actions) {
  return {
    ...states,
    requestFields: buildRequestFields(actions.borderos),
  };
}

function setBorderosWithErrorHandler(states, actions) {
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      ...actions.borderos,
    },
  };
}

function setLoadingHandler(states, actions) {
  return {
    ...states,
    loading: {
      ...states.loading,
      [actions.name]: actions.loading,
    },
  };
}

function setDialogHandler(states, actions) {
  const dialog = { ...states.dialog, open: actions.open };
  if (actions.variant || actions.message) {
    dialog.variant = actions.variant;
    dialog.message = actions.message;
    dialog.origin = actions.origin;
  }
  return { ...states, dialog };
}

function setSuccesDialogHandler(states, actions) {
  return {
    ...states,
    successDialog: {
      ...states.dialog,
      open: actions.open,
      variant: 'success',
    },
  };
}

function setCategoriaHandler(states, actions) {
  const categoria = states.requestFields.categoria.copy();
  const documento = states.requestFields.documento.copy();
  const nomeFantasia = states.requestFields.nomeFantasia.copy();
  const inscricaoEstadual = states.requestFields.inscricaoEstadual.copy();
  documento.value = '';
  nomeFantasia.value = '';
  inscricaoEstadual.value = '';
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      documento,
      nomeFantasia,
      inscricaoEstadual,
      categoria: categoria.onChange(actions.value),
    },
  };
}

function updateNomeFantasiaHandler(states) {
  const razaoSocial = states.requestFields.razaoSocial.copy();
  const nomeFantasia = states.requestFields.nomeFantasia.copy();
  if (nomeFantasia.value.length === 0) {
    nomeFantasia.value = razaoSocial.value;
  }
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      nomeFantasia,
    },
  };
}

function setAttributeHandler(states, actions) {
  const model = states.requestFields[actions.name]?.copy();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [actions.name]: model?.onChange(actions.value),
    },
  };
}

function setFocusHandler(states, actions) {
  const model = states.requestFields[actions.name].copy();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [actions.name]: model.onFocus(),
    },
  };
}

function setObjectAttributeHandler(states, actions) {
  const objects = [...states.requestFields[actions.objectName]];
  objects[actions.index][actions.name].onChange(actions.value);
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [actions.objectName]: objects,
    },
  };
}

function setObjectFocusHandler(states, actions) {
  const objects = [...states.requestFields[actions.objectName]];
  objects[actions.index][actions.name].onFocus();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [actions.objectName]: objects,
    },
  };
}

function setObjectHandler(states, actions) {
  const objects = [...states.requestFields[actions.objectName]];
  objects[actions.index] = actions.object;
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [actions.objectName]: objects,
    },
  };
}

function setObjectsHandler(states, actions) {
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [actions.objectName]: [...actions.objects],
    },
  };
}

function setClienteHandler(states, actions) {
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      cliente: { ...buildClienteBordero(actions.cliente) },
    },
  };
}

function setDevedorHandler(states, actions) {
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      ...buildDevedor(actions.devedor),
    },
  };
}

function clearAllHandler() {
  return buildInitialStates();
}

function clearBorderoHandler(states) {
  const cliente = { ...states.requestFields.cliente };
  const codigoCliente = states.requestFields.codigoCliente.copy();
  const dialog = { ...states.dialog, open: false };
  const newStates = buildInitialStates();
  return {
    ...newStates,
    dialog,
    requestFields: {
      ...newStates.requestFields,
      cliente,
      codigoCliente,
    },
  };
}

function setDadosRetornoHandler(states, actions) {
  const newTitulos = [...states.requestFields.titulos];
  newTitulos[actions.indexTitulo].dadosRetorno = actions.dadosRetorno;
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      titulos: newTitulos,
    },
  };
}

export const setDadosRetornoAction = (dadosRetorno, indexTitulo) => ({
  type: SET_DADOS_RETORNO,
  dadosRetorno,
  indexTitulo,
});

export const setBorderosAction = borderos => ({
  type: SET_BORDERO,
  borderos,
});

export const setLoadingAction = (loading, name = 'global') => ({
  type: SET_LOADING,
  loading,
  name,
});

export const setDialogAction = (open = false, variant, message, origin) => ({
  type: SET_DIALOG, open, variant, message, origin,
});

export const setSuccessDialogAction = (open = false) => ({
  type: SET_SUCCESS_DIALOG, open,
});

export const setAttributeAction = (name, value) => ({
  type: SET_ATTRIBUTE,
  name,
  value,
});

export const setFocusAction = name => ({
  type: SET_FOCUS,
  name,
});

export const setObjectAttributeAction = (objectName, name, value, index) => ({
  type: SET_OBJECT_ATTRIBUTE,
  objectName,
  name,
  value,
  index,
});

export const setObjectFocusAction = (objectName, name, index) => ({
  type: SET_OBJECT_FOCUS,
  objectName,
  name,
  index,
});

export const setObjectAction = (objectName, object, index) => ({
  type: SET_OBJECT,
  objectName,
  object,
  index,
});

export const setObjectsAction = (objectName, objects) => ({
  type: SET_OBJECTS,
  objectName,
  objects,
});

export const setClienteAction = cliente => ({
  type: SET_CLIENTE,
  cliente,
});

export const setCategoriaAction = value => ({
  type: SET_CATEGORIA,
  value,
});

export const updateNomeFantasiaAction = () => ({
  type: UPDATE_NOME_FANTASIA,
});

export const setDevedorAction = devedor => ({
  type: SET_DEVEDOR,
  devedor,
});

export const setBorderoWithErrorAction = bordero => ({
  type: SET_BORDERO_WITH_ERROR,
  bordero,
});

export const clearAllAction = () => ({
  type: CLEAR_ALL,
});

export const clearBorderoAction = () => ({
  type: CLEAR_BORDERO,
});

export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    case SET_BORDERO:
      return setBorderosHandler(states, actions);
    case SET_LOADING:
      return setLoadingHandler(states, actions);
    case SET_DIALOG:
      return setDialogHandler(states, actions);
    case SET_SUCCESS_DIALOG:
      return setSuccesDialogHandler(states, actions);
    case SET_ATTRIBUTE:
      return setAttributeHandler(states, actions);
    case SET_OBJECT_ATTRIBUTE:
      return setObjectAttributeHandler(states, actions);
    case SET_OBJECT:
      return setObjectHandler(states, actions);
    case SET_OBJECTS:
      return setObjectsHandler(states, actions);
    case SET_OBJECT_FOCUS:
      return setObjectFocusHandler(states, actions);
    case SET_CLIENTE:
      return setClienteHandler(states, actions);
    case SET_CATEGORIA:
      return setCategoriaHandler(states, actions);
    case UPDATE_NOME_FANTASIA:
      return updateNomeFantasiaHandler(states);
    case SET_DEVEDOR:
      return setDevedorHandler(states, actions);
    case SET_BORDERO_WITH_ERROR:
      return setBorderosWithErrorHandler(states, actions);
    case SET_FOCUS:
      return setFocusHandler(states, actions);
    case CLEAR_ALL:
      return clearAllHandler();
    case CLEAR_BORDERO:
      return clearBorderoHandler(states);
    case SET_DADOS_RETORNO:
      return setDadosRetornoHandler(states, actions);
    default:
      return states;
  }
};
