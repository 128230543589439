import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
import { CampoObrigatorio, InputField } from '../../../../components';
import Grid24 from '../../../../components/UI/Grid24';
import {
  extractResponseErrorMessage, getFieldName, getRequiredLabel, isBadRequestException, makeErrorField,
} from '../../../../helpers';
import { loadingInspecaoProcessoController } from '../../loadingInspecaoProcessoController';
import { dialogEnviarParcelaAcordoController } from './dialogEnviarParcelaAcordoController';


function DialogEnviarParcelaAcordo({
  setOpenDialogEnviarParcelaAcordo, updatePage, setLoadings, idAcordo, nroParcela, tipoFormaPagto,
}) {
  const [formFields, setFormFields] = useState(dialogEnviarParcelaAcordoController.makeFormFields());
  const [errorFields, setErrorFields] = useState(dialogEnviarParcelaAcordoController.makeErrorFields());

  const onChangeHandler = useCallback((e) => {
    const name = getFieldName(e.target);
    const { value } = e.target;
    setFormFields(oldValue => ({ ...oldValue, [name]: { ...oldValue[name], value } }));
  }, []);

  const onFocusHandler = useCallback((e) => {
    const name = getFieldName(e.target);
    setErrorFields(oldValues => ({ ...oldValues, [name]: makeErrorField() }));
  }, []);

  const onClose = useCallback(() => {
    setOpenDialogEnviarParcelaAcordo(false);
    setFormFields(dialogEnviarParcelaAcordoController.makeFormFields());
    setErrorFields(dialogEnviarParcelaAcordoController.makeErrorFields());
  }, [setOpenDialogEnviarParcelaAcordo]);

  async function onClickSubmitEmail(e) {
    e.preventDefault();
    setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(true));
    try {
      await dialogEnviarParcelaAcordoController.enviarEmails(idAcordo, nroParcela, tipoFormaPagto, formFields);
      updatePage();
      onClose();
      toast.success('E-mail enviado com sucesso.');
    } catch (error) {
      toast.error(error?.response?.data?.error, { style: { width: '392px' } });
      const validations = error?.response?.data?.validations;
      validations.forEach((validation) => {
        setErrorFields((errFields) => {
          errFields[validation.field].error = true;
          errFields[validation.field].errorMessage = validation.message;
          return errFields;
        });
      });
    } finally {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(false));
      setOpenDialogEnviarParcelaAcordo(false);
      setFormFields(dialogEnviarParcelaAcordoController.makeFormFields());
      setErrorFields(dialogEnviarParcelaAcordoController.makeErrorFields());
    }
  }

  useEffect(() => {
    dialogEnviarParcelaAcordoController.buscarEmailsPadrao(idAcordo).then((response) => {
      const emails = response.data;
      setFormFields((forms) => {
        forms.emailList.value = emails;
        return forms;
      });
    }).catch((error) => {
      if (isBadRequestException(error)) {
        toast.error('Ocorreu um erro ao comunicar com o servidor para obter os emails.', { style: { width: '392px' } });
      }
      const erroMessage = extractResponseErrorMessage(error);
      if (erroMessage !== 'Erro não mapeado') {
        toast.error(erroMessage, { style: { width: '392px' } });
      }
    }).finally(() => {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(false));
    });
  }, [idAcordo, setLoadings]);

  return (
    <>
      {errorFields?.emailList.error === true ? (
        <CampoObrigatorio />
      ) : (
        ''
      )}
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} style={{ marginBottom: '15px' }}>
          <InputField
            isForm
            fullWidth
            autoComplete="off"
            label={getRequiredLabel('Para', true)}
            name={formFields.emailList.name}
            value={formFields.emailList.value}
            error={errorFields.emailList.error}
            errorMessage={errorFields.emailList.errorMessage}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
          />
        </Grid24>
      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          onClick={onClickSubmitEmail}
        >
          Enviar
        </Button>
      </div>

    </>
  );
}


export default DialogEnviarParcelaAcordo;
