import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  dialog: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '402px',
    },
  },
  div__inputs: {
    display: 'flex',
    marginTop: '6px',
  },
  ddd__field: {
    width: '40px',
    marginRight: '10px',
  },
  numero__field: {
    width: '172px',
  },
  div__footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '50px',
  },
  button__cancelar: {
    width: '100px',
    marginRight: '10px',
  },
  button__enviar: {
    width: '152px',
  },
});
