import { buscarFasesParaSelecaoEnvioProcessoService } from '../../../services/core/fase/fase.services';
import { buscarNegociadoresDaFaseService } from '../../../services/core/usuario/usuario.services';

class AcaoEnviarController {
  makeFormFields(values) {
    return {
      idFase: '',
      idFasePasta: '',
      idMotivoBaixa: '',
      idNegociador: '',
      texto: '',
      liberarCompromissos: values?.liberarCompromissos !== false,
      liberarRequisicao: true,
      recalcularValorProcesso: true,
    };
  }

  makeErrorFields() {
    return {
      idFase: {
        error: false,
        errorMessage: '',
      },
      idMotivoBaixa: {
        error: false,
        errorMessage: '',
      },
      idNegociador: {
        error: false,
        errorMessage: '',
      },
    };
  }

  makeVisibleState() {
    return {
      idFasePasta: false,
      idMotivoBaixa: false,
      idNegociador: false,
    };
  }

  makeFases(fases) {
    return {
      id: fases?.id,
      value: fases?.nome,
      contemPastas: fases?.contemPastas,
      exigeMotivoBaixa: fases?.exigeMotivoBaixa,
      exigeNegociadorExterno: fases?.exigeNegociadorExterno,
      recalcularValorProcesso: fases?.recalcularProcesso,
    };
  }

  makeNegociadores(negociador) {
    return {
      id: negociador?.id,
      value: negociador?.nome,
    };
  }

  async buscarFases(permiteCobrancaPresencial) {
    try {
      const fases = await buscarFasesParaSelecaoEnvioProcessoService(permiteCobrancaPresencial);
      return fases.data?.map(this.makeFases);
    } catch {
      return [];
    }
  }

  async buscarNegociadoresDaFase(idFase) {
    try {
      const response = await buscarNegociadoresDaFaseService(idFase);
      return response.data?.map(this.makeNegociadores);
    } catch (e) {
      return [];
    }
  }
}

export const acaoEnviarController = new AcaoEnviarController();
