import styled, { css } from 'styled-components';

export const CardResumoContainer = styled.div`
  ${props => (props?.isDetail ? css`
  display: flex;  
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    background-color: ${props.bgColor};
  
    & div {
      display: flex;
      flex-direction: row;
      gap: 10px;
      width: 100%;
      justify-content:space-between;
      border-top: 1px solid ${props.lineColor};
    }

    & h5{
      margin-bottom: 2px;
    }
  }

` : css` 
    display: flex;
    flex-direction: column;
    background-color: ${props.bgColor};
    border-radius: 10px;
    padding: 10px;
    width: 100%;
  `)}
  
`;
