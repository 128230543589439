import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { FaEnvelope } from 'react-icons/fa';
import { MdPrint as MdPrintIcon } from 'react-icons/md';
import TableHeader from '../../../../models/TableHeader';
import OrdenacaoColuna from '../../../../components/UI/Tabela/Ordenacao/OrdenacaoColuna';
import {
  getDate30daysAgoCorridos, getMoneyMask, toAmericanDate, toBrazilianDate,
} from '../../../../helpers';
import FieldModel from '../../../../models/FieldModel';
import ContaCorrenteClienteService
  from '../../../../services/core/contaCorrenteClienteService/ContaCorrenteClienteService';
import TooltipUI from '../../../../components/UI/Tooltip/Tooltip';
import { blackTwo, lightGrey } from '../../../../helpers/constants/colors.constants';
import { CancelarEnvioIcon } from '../../../../assets/Icons/CancelarEnvioIcon';
import { EstornarIcon } from '../../../../assets/Icons/EstornarIcon';

const tiposRetornoEnum = {
  AGUARDANDO_ENVIO_EMAIL: { color: '#3f51b5' },
  EMAIL_ENVIADO_COM_SUCESSO: { color: '#43a047' },
  CLIENTE_NAO_POSSUI_EMAIL_DE_PRESTACAO_DE_CONTAS: { color: '#ffa000' },
  ENVIO_NAO_SOLICITADO: { color: '#858484' },
  ERRO_NO_ENVIO_DO_EMAIL: { color: '#e91b1b' },
};

class RelacaoPrestacaoController {
  makeCabecalhoTabela(columnOrdenacao, setColumnOrdenacao, arrowOrdenacao, setArrowOrdenacao, applyOrder) {
    return [
      new TableHeader({
        id: 'relacaoSelecionada',
        radioButton: true,
        label: '',
        maxWidth: 55,
        alignCell: 'center',
      }),
      new TableHeader({
        id: 'idPrestacao',
        label: <OrdenacaoColuna
          columnLabel="Prestação"
          idColumn="idPrestacao"
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          arrowOrdenacao={arrowOrdenacao}
          setArrowOrdenacaoFunc={setArrowOrdenacao}
          onClickColumn={applyOrder}
        />,
        maxWidth: 55,
        alignCell: 'center',
      }),
      new TableHeader({
        id: 'tipoRetornoEnvioPrestacaoContaCliente',
        label: <i className="fas fa-envelope" />,
        alignCell: 'center',
        maxWidth: 45,
      }),
      new TableHeader({
        id: 'dataPrestacao',
        label: <OrdenacaoColuna
          columnLabel="Data"
          idColumn="dataPrestacao"
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          arrowOrdenacao={arrowOrdenacao}
          setArrowOrdenacaoFunc={setArrowOrdenacao}
          onClickColumn={applyOrder}
        />,
        alignCell: 'center',
        maxWidth: 80,
      }),
      new TableHeader({
        id: 'tipoPrestacaoContaCliente',
        label: 'Tipo da Prestação',
        alignCell: 'center',
        maxWidth: 116,
      }),
      new TableHeader({
        id: 'dataEstorno',
        label: 'Data Estorno',
        alignCell: 'center',
        maxWidth: 90,
      }),
      new TableHeader({
        id: 'totalPrestacao',
        label: 'Total Repasse',
        alignCell: 'right',
        maxWidth: 154,
      }),
      new TableHeader({
        id: 'totalDemonstracao',
        label: 'Total Demonstração',
        alignCell: 'right',
        maxWidth: 154,
      }),
      new TableHeader({
        id: 'saldoAPagar',
        label: 'A Pagar',
        alignCell: 'right',
        maxWidth: 160,
      }),
      new TableHeader({
        id: 'acoes',
        label: 'Ações',
        icone: true,
        maxWidth: 140,
        alignCell: 'center',
      }),
    ];
  }


  getTipoRetornoEmail(tipoRetorno, hint) {
    return (
      <TooltipUI title={hint}>
        <i style={{ color: tiposRetornoEnum[tipoRetorno].color }} className="fas fa-circle" />
      </TooltipUI>
    );
  }

  makeConteudoTabela(utilStyles, prestacoes, permissoes, handlePrintPrestacao, handleOpenCancelPrestacao, handleOpenEstornoPrestacao, handleEnviarEmailPrestacao) {
    if (!prestacoes || prestacoes.length === 0) {
      return [];
    }

    return prestacoes.map(prestacao => ({
      id: prestacao.idPrestacao,
      values: {
        relacaoSelecionada: prestacao.idPrestacao,
        idPrestacao: prestacao.idPrestacao,
        dataEstorno: toBrazilianDate(prestacao.dataEstorno),
        dataPrestacao: toBrazilianDate(prestacao.dataPrestacao),
        existirEmailPrestacao: prestacao.existirEmailPrestacao,
        prestacaoRelacaoClientes: prestacao.prestacaoRelacaoClientes,
        saldoAPagar: getMoneyMask(prestacao.saldoAPagar),
        tipoPrestacaoContaCliente: prestacao.tipoPrestacaoContaCliente,
        totalDemonstracao: getMoneyMask(prestacao.totalDemonstracao),
        totalPrestacao: getMoneyMask(prestacao.totalPrestacao),
        tipoRetornoEnvioPrestacaoContaCliente: this.getTipoRetornoEmail(prestacao.tipoRetornoEnvioPrestacaoContaCliente, prestacao.retornoEnvioPrestacaoContaCliente),
        acoes: (
          <div>
            <TooltipUI title={prestacao.existirEmailPrestacao ? 'Enviar Prestação' : ''}>
              <IconButton
                size="small"
                disabled={!prestacao.existirEmailPrestacao}
                className={utilStyles.mr8}
                onClick={() => handleEnviarEmailPrestacao(prestacao.idPrestacaoContaCliente)}
              >
                <div>
                  <FaEnvelope style={{ color: prestacao.existirEmailPrestacao ? blackTwo : lightGrey }} size={17} />
                </div>
              </IconButton>
            </TooltipUI>

            <TooltipUI title="Imprimir">
              <IconButton
                size="small"
                className={utilStyles.mr8}
                style={{ color: blackTwo }}
                onClick={() => handlePrintPrestacao(prestacao.idPrestacaoContaCliente)}
              >
                <MdPrintIcon size={20} />
              </IconButton>
            </TooltipUI>

            <TooltipUI title={permissoes.prestarContas && prestacao.isUltimaPrestacao ? 'Cancelar' : ''}>
              <IconButton
                size="small"
                className={utilStyles.mr8}
                onClick={() => handleOpenCancelPrestacao(prestacao.idPrestacao)}
                disabled={!permissoes.prestarContas || !prestacao.isUltimaPrestacao}
              >
                <CancelarEnvioIcon
                  color={permissoes.prestarContas && prestacao.isUltimaPrestacao ? blackTwo : lightGrey}
                />
              </IconButton>
            </TooltipUI>

            <TooltipUI title={permissoes.prestarContas && !prestacao.dataEstorno ? 'Estornar' : ''}>
              <IconButton
                size="small"
                onClick={() => handleOpenEstornoPrestacao(prestacao.idPrestacao)}
                disabled={!permissoes.prestarContas || prestacao.dataEstorno !== null}
              >
                <EstornarIcon
                  color={permissoes.prestarContas && prestacao.dataEstorno === null ? blackTwo : lightGrey}
                />
              </IconButton>
            </TooltipUI>
          </div>
        ),
      },
    }));
  }

  makePaginacaoTabela(response, paginacao) {
    return {
      page: paginacao?.page,
      rowsPerPage: paginacao?.rowsPerPage,
    };
  }

  makeDefaultDadosPesquisa() {
    return {
      content: [],
      pageable: {
        page: 0,
        rowsPerPage: 10,
      },
      totalElements: 0,
    };
  }

  makeDadosPesquisa(response, paginacao, error = null) {
    return {
      content: response?.data?.content || [],
      pageable: this.makePaginacaoTabela(response?.data, paginacao),
      totalElements: response?.data?.totalElements || 0,
      error,

    };
  }

  makeDefaultFormFields() {
    return {
      periodoInicial: new FieldModel({
        name: 'periodoInicial', value: getDate30daysAgoCorridos(), error: false, errorMessage: '', disabled: false,
      }),
      periodoFinal: new FieldModel({
        name: 'periodoFinal', value: '', error: false, errorMessage: '', disabled: false,
      }),
      prestacaoAPagar: new FieldModel({ name: 'prestacaoAPagar', value: false, disabled: false }),
    };
  }

  makeFormFields() {
    return {
      periodoInicial: new FieldModel({
        name: 'periodoInicial', value: getDate30daysAgoCorridos(), error: false, errorMessage: '', disabled: false,
      }),
      periodoFinal: new FieldModel({
        name: 'periodoFinal', value: toBrazilianDate(new Date()), error: false, errorMessage: '', disabled: false,
      }),
      prestacaoAPagar: new FieldModel({ name: 'prestacaoAPagar', value: false, disabled: false }),
    };
  }

  makeErrorFields() {
    return {
      periodoInicial: { error: false, errorMessage: '' },
      periodoFinal: { error: false, errorMessage: '' },
    };
  }

  makeResumo(value) {
    return {
      totalSaldoAnterior: getMoneyMask(value?.totalsaldoAnterior) || 'R$ 0,00',
      totalSaldo: getMoneyMask(value?.totalsaldo) || 'R$ 0,00',
      totalHonorarios: getMoneyMask(value?.totalHonorarios) || 'R$ 0,00',
      totalEstornos: getMoneyMask(value?.totalEstornos) || 'R$ 0,00',
      totalDespesasReembolsaveis: getMoneyMask(value?.totalDespesasReembolsaveis) || 'R$ 0,00',
      totalDespesasNaoReembolsaveis: getMoneyMask(value?.totalDespesasNaoReembolsaveis) || 'R$ 0,00',
      totalCreditos: getMoneyMask(value?.totalCreditos) || 'R$ 0,00',
      totalPagamentos: getMoneyMask(value?.totalPagamentos) || 'R$ 0,00',
      totalAcordos: getMoneyMask(value?.totalAcordos) || 'R$ 0,00',
    };
  }

  async pesquisarRelacoes(idClienteSelecionado, fields, page, rowsPerPage, ordenacao) {
    const {
      periodoInicial, periodoFinal, prestacaoAPagar,
    } = fields;
    const requestPayload = {
      idCliente: idClienteSelecionado,
      periodoInicial: periodoInicial ? toAmericanDate(periodoInicial?.value) : toAmericanDate(getDate30daysAgoCorridos()),
      periodoFinal: periodoFinal ? toAmericanDate(periodoFinal?.value) : toAmericanDate(toBrazilianDate(new Date())),
      prestacaoAPagar: prestacaoAPagar?.value,
    };

    const response = await ContaCorrenteClienteService.buscarRelacaoPrestacao(requestPayload, page, rowsPerPage, [`${ordenacao.element},${ordenacao.order}`]);
    return this.makeDadosPesquisa(response, { page, rowsPerPage });
  }

  async downloadRelatorioPrestacao(idPrestacaoContaCliente) {
    const download = await ContaCorrenteClienteService.downloadRelatorioPrestacao(idPrestacaoContaCliente);
    return download;
  }

  findSaldosPrestacao(idCliente, idPrestacao) {
    return ContaCorrenteClienteService.findSaldosPrestacao(idCliente, idPrestacao);
  }

  cancelarPrestacao(idCliente, idPrestacao) {
    return ContaCorrenteClienteService.cancelarPrestacao(idCliente, idPrestacao);
  }

  estornarPrestacao(idCliente, idPrestacao) {
    return ContaCorrenteClienteService.estornarPrestacao(idCliente, idPrestacao);
  }

  enviarEmailPrestacao(idPrestacaoContaCliente) {
    return ContaCorrenteClienteService.enviarEmailPrestacao(idPrestacaoContaCliente);
  }
}

export const relacaoPrestacaoController = new RelacaoPrestacaoController();
