import { Tooltip, Typography } from '@material-ui/core';
import React, { useState, useCallback, useEffect } from 'react';
import { RiInformationFill } from 'react-icons/ri';
import { IoMdCalculator } from 'react-icons/io';
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ButtonUI, SelectorField } from '../../../../../components';
import { SimpleTitle } from '../../../../../components/Title/SimpleTitle/SimpleTitle';
import DateField from '../../../../../components/UI/Field/Date/DateField';
import NumberInput from '../../../../../components/UI/Field/Number/NumberInput';
import { TableActionButton } from '../../../../../components/UI/Tabela/tableDefaultStyles';
import {
  DateUtils,
  getExceptionErrorMessage,
  getFieldName, getMoneyMask, getRequiredLabel, isBadRequestException, toBrazilianDate,
} from '../../../../../helpers';
import { blackTwo, warmGrey } from '../../../../../helpers/constants/colors.constants';
import { ContainerNovoAcordo, StepSubLabel } from '../../AcordosStyle';
import useStyle, {
  Border,
  ButtonRecalcular,
  ContainerResumo,
  HeaderButton,
  InstrucaoCampanha, Label, Margins, Root,
} from './PassoDoisStyle';
import DialogInstrucaoInicial from './DialogInstrucaoInicial';
import DialogCampanha from './DialogCampanha';
import { acordoPassoDoisController } from './acordoPassoDoisController';
import { findTiposFormaPagamentoAction, findTiposIntervaloPagamentoAction } from '../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import { loadingInspecaoProcessoController } from '../../../loadingInspecaoProcessoController';
import { ITEMS_ANALISE_ACORDO } from './PassoDoisConstants';
import TooltipUI from '../../../../../components/UI/Tooltip/Tooltip';
import DialogNew from '../../../../../layouts/FormDialog/DialogNew';

function NovoAcordoPassoDoisComponent({
  handleBackStep,
  dadosDefault,
  dadosResumo,
  setLoadings,
  idProcesso,
  listaDeIds,
  setDadosResumo,
  handleNextStep,
  setParcelas,
  setDadosFormFields,
  setDadosPadraoForm,
  setDataFinalPasso3,
}) {
  const style = useStyle();
  const dispatch = useDispatch();

  const [openButtonInstrucao, setOpenButtonInstrucao] = useState(false);
  const [openButtonCampanha, setOpenButtonCampanha] = useState(false);

  const [disabledButtonProximo, setDisabledButtonProximo] = useState(false);

  const [formFields, setFormFields] = useState(acordoPassoDoisController.makeFormFields(dadosDefault));

  const formaPagamento = useSelector(states => states.selectors.tiposFormaPagamento.selector);

  const tiposIntervaloPagamento = useSelector(states => states.selectors.tiposIntervaloPagamento.selector);

  const onClickButtonInstrucao = useCallback(() => {
    setOpenButtonInstrucao(oldValue => !oldValue);
  }, []);

  const onClickButtonCampanha = useCallback(() => {
    setOpenButtonCampanha(oldValue => !oldValue);
  }, []);

  const onCloseInstrucao = useCallback(() => {
    setOpenButtonInstrucao(oldValue => !oldValue);
  }, []);

  const onCloseCampanha = useCallback(() => {
    setOpenButtonCampanha(oldValue => !oldValue);
  }, []);

  const onChangeHandler = useCallback((e) => {
    const name = getFieldName(e.target);
    const { value } = e.target;
    setFormFields(oldValue => ({ ...oldValue, [name]: { ...oldValue[name], value } }));
    setDisabledButtonProximo(true);
  }, []);

  const onChangeSelectorsFields = useCallback((event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: formFields[name].onChange(value) });
    setDisabledButtonProximo(true);

    // Condições para forma de pagamento Cartão e Dinheiro
    if (name === 'idTipoFormaPagto' && (value === 2 || value === 5)) {
      setFormFields((prevFormFields) => {
        prevFormFields.dataPrimeiroVencimento.value = toBrazilianDate(DateUtils.makeDate(0));
        prevFormFields.idIntervaloPagamento.value = 0;
        if (value === 5 || value === 2) {
          prevFormFields.quantidadeParcelas.value = 1;
        }
        return prevFormFields;
      });
    }
  }, [formFields]);

  const onClickButtonVoltar = useCallback(() => {
    handleBackStep();
  }, [handleBackStep]);

  async function onClickProximo() {
    setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(true));
    try {
      if (formFields.quantidadeParcelas.value > 0) {
        const { data } = await acordoPassoDoisController.criarParcelasAcordo(dadosResumo, idProcesso, formFields);
        const parcelas = acordoPassoDoisController.buildParcelas(data);
        setParcelas(parcelas);
        setDataFinalPasso3(parcelas[parcelas.length - 1].vencimento);
        setDadosPadraoForm(acordoPassoDoisController.buildFormFields(formFields));
        handleNextStep();
      } else {
        toast.error('Quantidade de parcelas deve ser maior que 0.', { style: { width: '392px' } });
      }
    } catch (error) {
      if (isBadRequestException(error)) {
        toast.error(error?.response?.data?.message, { style: { width: '392px' } });
      } else {
        toast.error(getExceptionErrorMessage(error), { style: { width: '392px' } });
      }
    } finally {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(false));
    }
  }

  async function onClickRecalcular(e) {
    e.preventDefault();
    setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(true));
    try {
      if (formFields.quantidadeParcelas.value <= 0) {
        toast.error('Quantidade de parcelas deve ser maior que 0.', { style: { width: '392px' } });
      } else {
        const { data } = await acordoPassoDoisController.recalcularAcordo(idProcesso, formFields, listaDeIds, dadosDefault);
        // eslint-disable-next-line prefer-destructuring
        data.jsonErros = JSON.parse(data.jsonErros)[0];
        setDadosResumo(data);
        setDisabledButtonProximo(false);
        setDadosFormFields(acordoPassoDoisController.buildFormFields(formFields));
      }
    } catch (error) {
      if (isBadRequestException(error)) {
        toast.error(error?.response?.data?.message, { style: { width: '392px' } });
      } else {
        toast.error(getExceptionErrorMessage(error), { style: { width: '392px' } });
      }
    } finally {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(false));
    }
  }

  useEffect(() => {
    setFormFields(acordoPassoDoisController.makeFormFields(dadosDefault));
    setDadosFormFields(acordoPassoDoisController.buildFormFields(acordoPassoDoisController.makeFormFields(dadosDefault)));
  }, [dadosDefault, setDadosFormFields]);


  useEffect(() => {
    dispatch(findTiposFormaPagamentoAction());
    dispatch(findTiposIntervaloPagamentoAction());
  }, [dispatch]);

  return (
    <>
      <DialogNew
        open={openButtonInstrucao}
        className={style.dialog}
        onClose={onCloseInstrucao}
        text="Instrução inicial"
      >
        {openButtonInstrucao && (
          <DialogInstrucaoInicial
            onClose={onCloseInstrucao}
            dadosDefault={dadosDefault}
          />
        )}
      </DialogNew>
      <DialogNew
        open={openButtonCampanha}
        text="Campanha"
        className={style.dialog}
        onClose={onCloseCampanha}
      >
        {openButtonCampanha && (
          <DialogCampanha
            onClose={onCloseCampanha}
            dadosDefault={dadosDefault}
          />
        )}
      </DialogNew>
      <ContainerNovoAcordo>
        <StepSubLabel>
          Utilize a Calculadora para definir os novos valores negociados. Escolha também a forma de pagamento e a quantidade de parcelas do Acordo ou
          Simulação.
        </StepSubLabel>
        <Root>
          <div style={{ width: '40%' }}>
            <InstrucaoCampanha>
              <SimpleTitle
                text="Instrução Inicial"
              />
              <div style={{ marginBottom: ' 2px' }}>
                {dadosDefault.instrucaoInicial !== null ? (
                  <Tooltip title="Veja a Instrução" placement="bottom">
                    <TableActionButton>
                      <RiInformationFill
                        size={20}
                        color="#034ddc"
                        onClick={onClickButtonInstrucao}
                      />
                    </TableActionButton>
                  </Tooltip>
                ) : (
                  <RiInformationFill
                    size={20}
                    color="#a2a0a0"
                    disabled
                  />
                )}

              </div>
            </InstrucaoCampanha>
            <div style={{ marginTop: '13px' }}>
              <div className={style.div__inputs}>
                <div style={{ width: '139px' }}>
                  <SelectorField
                    isForm
                    label={getRequiredLabel('Forma de Pagamento', true)}
                    items={formaPagamento}
                    name={formFields.idTipoFormaPagto.name}
                    value={formFields.idTipoFormaPagto.value}
                    onChange={onChangeSelectorsFields}
                  />
                </div>
                <DateField
                  isForm
                  minDate={new Date()}
                  name={formFields.dataPrimeiroVencimento.name}
                  label={getRequiredLabel('Data 1º Vencimento', true)}
                  value={formFields.dataPrimeiroVencimento.value}
                  onChange={onChangeHandler}
                  disabled={formFields.idTipoFormaPagto.value === 2 || formFields.idTipoFormaPagto.value === 5}
                />
              </div>
              <div className={style.div__inputsLabel}>
                <Label>
                  {getRequiredLabel('Intervalo entre os Pagamentos', true)}
                </Label>
                <Label>
                  {getRequiredLabel('Qtde. de Parcelas', true)}
                </Label>
              </div>
              <div className={style.div__inputsCampo}>
                <div style={{ width: '139px' }}>
                  <SelectorField
                    isForm
                    items={tiposIntervaloPagamento}
                    name={formFields.idIntervaloPagamento.name}
                    value={formFields.idIntervaloPagamento.value}
                    disabled={formFields.idTipoFormaPagto.value === 2 || formFields.idTipoFormaPagto.value === 5}
                    onChange={onChangeSelectorsFields}
                  />
                </div>
                <NumberInput
                  isForm
                  numberType="integer"
                  minValue={1}
                  maxValue={1000}
                  style={{ width: '50px' }}
                  name={formFields.quantidadeParcelas.name}
                  value={formFields.quantidadeParcelas.value}
                  disabled={formFields.idTipoFormaPagto.value === 5}
                  onChange={onChangeHandler}
                />
              </div>
              <div className={style.div__inputsLabelDois}>
                <Label>
                  {getRequiredLabel('Juros', true)}
                </Label>
                <Label>
                  {getRequiredLabel('Honorários', true)}
                </Label>
              </div>
              <div className={style.div__inputsCampoDois}>
                <NumberInput
                  isForm
                  sideText="(%)"
                  numberType="percentage"
                  minValue={0}
                  maxValue={100}
                  style={{ width: '70px' }}
                  value={formFields.porcentagemJuros.value || '0.00'}
                  name={formFields.porcentagemJuros.name}
                  onChange={onChangeHandler}
                />
                <NumberInput
                  isForm
                  sideText="(%)"
                  numberType="percentage"
                  minValue={0}
                  maxValue={100}
                  style={{ width: '70px' }}
                  value={formFields.porcentagemHonorarios.value || '0.00'}
                  name={formFields.porcentagemHonorarios.name}
                  onChange={onChangeHandler}
                />
              </div>
            </div>
            <div className={style.div__instrucaoInicial}>
              <InstrucaoCampanha>
                <SimpleTitle
                  text="Campanha"
                />
                <div style={{ marginBottom: ' 2px' }}>
                  {dadosDefault.descricaoCampanha !== null && dadosDefault.temCampanha === 'S' ? (
                    <Tooltip title="Veja a Campanha" placement="bottom">
                      <TableActionButton>
                        <RiInformationFill
                          size={20}
                          color="#034ddc"
                          onClick={onClickButtonCampanha}
                        />
                      </TableActionButton>
                    </Tooltip>
                  ) : (
                    <RiInformationFill
                      size={20}
                      color="#a2a0a0"
                      disabled
                    />
                  )}

                </div>
              </InstrucaoCampanha>
            </div>
            <div style={{ marginTop: '12px' }}>
              <Label>
                {getRequiredLabel('Desconto', true)}
              </Label>
              {dadosDefault.temCampanha === 'S' ? (
                <NumberInput
                  isForm
                  sideText="(%)"
                  numberType="percentage"
                  minValue={0}
                  maxValue={99}
                  style={{ width: '70px' }}
                  value={formFields.porcentagemDesconto.value || '0.00'}
                  name={formFields.porcentagemDesconto.name}
                  onChange={onChangeHandler}
                />
              ) : (
                <NumberInput
                  isForm
                  sideText="(%)"
                  numberType="percentage"
                  style={{ width: '70px' }}
                  value="0.00"
                  name={formFields.porcentagemDesconto.name}
                  disabled
                />
              )}
            </div>
            <div className={style.div__AlignButton}>
              <div className={style.div__Button}>
                <ButtonUI
                  color="white"
                  onClick={onClickButtonVoltar}
                >
                  Voltar
                </ButtonUI>
              </div>
              <div className={style.div__Button}>
                <ButtonUI disabled={disabledButtonProximo === true} onClick={onClickProximo}>
                  Próximo
                </ButtonUI>
              </div>
            </div>
          </div>
          <div className={style.div__quadroResumo}>
            <Margins>
              <SimpleTitle
                text="Resumo"
              />
              <ContainerResumo>
                <div className={style.div__line}>
                  <b className={style.div__name} style={{ color: blackTwo }}>Valor Principal</b>
                  <b className={style.div__valueMaior} style={{ color: blackTwo }}>
                    {getMoneyMask(dadosResumo.valorSaldo, false)}
                    {' '}
                    (+)
                  </b>
                </div>
                <div className={style.div__line}>
                  <div style={{ color: warmGrey }} className={style.div__name}>Protesto</div>
                  <div className={style.div__value}>
                    {getMoneyMask(dadosResumo.valorProtesto, false)}
                    {' '}
                    (+)
                  </div>
                </div>
                <div className={style.div__line}>
                  <div style={{ color: warmGrey }} className={style.div__name}>Multa</div>
                  <div className={style.div__value}>
                    {getMoneyMask(dadosResumo.valorMulta, false)}
                    {' '}
                    (+)
                  </div>
                </div>
                <Border />
                <div className={style.div__lineBold}>

                  <b className={style.div__name}>SUBTOTAL</b>
                  <b className={style.div__value}>
                    {getMoneyMask(dadosResumo.valorSubTotal, false)}
                    {' '}
                    (=)
                  </b>
                </div>
                <div className={style.div__line} style={{ marginTop: '18px' }}>
                  <div style={{ color: warmGrey }} className={style.div__name}>Juros/Desp.</div>
                  <div className={style.div__value}>
                    {getMoneyMask(dadosResumo.valorJuros, false)}
                    {' '}
                    (+)
                  </div>
                </div>
                <div className={style.div__line}>
                  <div style={{ color: warmGrey }} className={style.div__name}>Honorários</div>
                  <div className={style.div__value}>
                    {getMoneyMask(dadosResumo.valorHonorarios, false)}
                    {' '}
                    (+)
                  </div>
                </div>
                <div className={style.div__line}>
                  <div style={{ color: warmGrey }} className={style.div__name}>Desconto</div>
                  <div className={style.div__value}>
                    {getMoneyMask(dadosResumo.valorDesconto, false)}
                    {' '}
                    (-)
                  </div>
                </div>
                <Border />
                <div className={style.div__lineBoldBlue}>
                  <b className={style.div__name}>Valor</b>
                  <b className={style.div__value}>
                    {getMoneyMask(dadosResumo.valorTotal, false)}
                    {' '}
                    (=)
                  </b>
                </div>
              </ContainerResumo>
            </Margins>
            <HeaderButton>
              <ButtonRecalcular
                variant="outlined"
                onClick={onClickRecalcular}
              >
                <IoMdCalculator />
                <span>Recalcular</span>
              </ButtonRecalcular>
            </HeaderButton>
          </div>
          <div className={style.div__analiseAcordo}>
            <Margins className={style.containerListaAnalise}>
              <SimpleTitle
                text="Análise do Acordo"
              />
              <ul className={style.listaAnalise}>
                {
                  ITEMS_ANALISE_ACORDO.map(item => (
                    <li style={{ display: 'flex', flexDirection: 'row' }}>
                      {
                        // eslint-disable-next-line no-nested-ternary
                        dadosResumo.jsonErros
                          ? (
                            dadosResumo.jsonErros[item.messageProp] !== '' && dadosResumo.jsonErros[item.messageProp] !== null
                              && dadosResumo.jsonErros[item.colorProp] !== 'SUCESSO'
                              ? (
                                <TooltipUI title={dadosResumo.jsonErros[item.messageProp]}>
                                  {
                                    dadosResumo.jsonErros[item.colorProp] === 'ALERTA'
                                      ? (
                                        <div>
                                          <FaThumbsUp size={18} color="#FFAE36" />
                                        </div>
                                      )
                                      : (
                                        <div style={{ marginTop: '6px' }}>
                                          <FaThumbsDown size={18} color="#E9303B" />
                                        </div>
                                      )
                                  }
                                </TooltipUI>
                              )
                              : (
                                <div>
                                  <FaThumbsUp size={18} color="#43A047" />
                                </div>
                              )
                          )
                          : (
                            <div>
                              <FaThumbsUp size={18} color="#43A047" />
                            </div>
                          )
                      }
                      <Typography className={style.textoItemListaAnalise}>
                        {item.name}
                      </Typography>
                    </li>
                  ))
                }
              </ul>
              <footer>
                <Typography variant="p" className={style.textoAtencao}>
                  Atenção:
                </Typography>
                <Typography variant="p" style={{ fontSize: '12px' }}>
                  Propostas com algum tipo de restrição só serão liberadas com aprovação da ABE ou expressa autorização do cliente.
                </Typography>
              </footer>
            </Margins>
          </div>
        </Root>
      </ContainerNovoAcordo>
    </>
  );
}

export const PassoDois = NovoAcordoPassoDoisComponent;
