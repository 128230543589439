import styled from 'styled-components';
import { Button, makeStyles } from '@material-ui/core';
import { blackTwo, warmGreyTwo } from '../../../../../helpers/constants/colors.constants';

export default makeStyles({
  div__instrucaoInicial: {
    display: 'flex',
    alignItems: 'end',
  },
  div__inputs: {
    display: 'flex',
    gap: '90px',
  },
  div__inputsLabel: {
    display: 'flex',
    gap: '55px',
    marginTop: '12px',
  },
  div__inputsCampo: {
    display: 'flex',
    gap: '90px',
  },
  div__inputsLabelDois: {
    display: 'flex',
    gap: '191px',
    marginTop: '12px',
  },
  div__inputsCampoDois: {
    display: 'flex',
    gap: '132px',
  },
  div__Button: {
    marginTop: '22px',
    width: '148px',
  },
  div__AlignButton: {
    display: 'flex',
    gap: '16px',
  },
  div__name: {
    width: '44%',
  },
  div__value: {
    display: 'flex',
    width: '56%',
    justifyContent: 'flex-end',
  },
  div__line: {
    display: 'flex',
    marginTop: '7px',
    fontSize: '12px',
  },
  div__lineBold: {
    display: 'flex',
    marginTop: '7px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: blackTwo,
  },
  div__quadroResumo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '25%',
    marginLeft: '30px',
  },
  div__lineBoldBlue: {
    display: 'flex',
    marginTop: '11px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'blue !important',
  },
  divider: {
    borderColor: '#FEFEFEAA',
    marginTop: '7px',
  },
  dialog: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '577px',
      maxHeight: '382px',
    },
  },
  buttonDialogInstrucao: {
    width: '165px',
    alignSelf: 'center',
  },
  textInstrucao: {
    width: '100%',
    display: 'grid',
    marginBottom: '12px',
  },
  div__valueMaior: {
    display: 'flex',
    width: '56%',
    justifyContent: 'flex-end',
    fontSize: '14px',
  },
  div__analiseAcordo: {
    width: '35%',
  },
  listaAnalise: {
    marginTop: '16px',

    '& > li': {
      marginBottom: '20px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      maxHeight: '40px',
    },
  },
  containerListaAnalise: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: '60px',
  },
  textoItemListaAnalise: {
    marginLeft: '8px',
    fontSize: '14px',
    color: '#707070',
    alignSelf: 'center',
  },
  textoAtencao: {
    fontSize: '12px',
    fontWeight: '700',
    color: '#034DDC',
    marginRight: '5px',
  },
});


export const InstrucaoCampanha = styled.div`
 margin-top:22px ;
 display: flex;
   align-items:end ;
   align-items:start ;
`;

export const Margins = styled.div`
 margin-top:22px ;
`;

export const Label = styled.label`
  font-size: 0.75rem;
  color: #707070;
`;

export const Root = styled.label`
  display:flex ;
`;

export const ContainerResumo = styled.div`
  width:100% ;
  display:flex ;
  margin-top:13px ;
  flex-direction:column ;
  padding:10px ;
  border: 1px solid ${warmGreyTwo};
`;

export const ButtonRecalcular = styled(Button)`
color:  #564872;
font-weight:bold ;

:disabled {
color: #c4c4c4;
}

& .MuiButton-label span {
    margin-left: 5px;
  }
`;

export const HeaderButton = styled.div`
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
  width:100%;
  button {
    color: #564872;
    border: 1px solid #564872;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    width: 153px;
    height: 30px;
    text-transform: none;
    min-width: 89px;
  }
`;

export const Border = styled.div`
border: 1px solid ${warmGreyTwo};
margin-top: 5px;
`;

export const HeaderInstrucao = styled.div`
  display:flex ;
  width:100% ;
  flex-direction:column ;
`;
