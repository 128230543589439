import { getUriParams } from '../../../helpers';
import { CORE, gateway } from '../../api';

export default {
  buscarEmailEntradaBordero(pageable) {
    const queryParam = getUriParams(pageable);

    return gateway.get(`${CORE}/email-entrada-bordero/buscar-notificacoes?${queryParam}`);
  },
  enviarEmailEntradaBordero() {
    return gateway.get(`${CORE}/email-entrada-bordero/enviar-email`);
  },
  excluirEmailEntradaBordero(idEmailEntradaBordero) {
    return gateway.get(`${CORE}/email-entrada-bordero/excluir-notificacao/${idEmailEntradaBordero}`);
  },
};
