import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  wrapper: {
    width: '100%',
    display: 'flex',
    '& input[type="file"]': {
      display: 'none',
    },
    '& p': {
      marginTop: '4px',
      fontSize: '11px',
    },
  },
  button: {
    '& button': {
      padding: '6px 25px !important',
    },
    marginImageButton: {
      marginTop: '10px',
    },
  },
});
