
import { getDateDown } from '../../helpers';
import FieldModel from '../../models/FieldModel';
import {
  SET_COMMON_ACTION, SET_DATE_FIELD_ACTION, SET_SIMPLE_REGISTRAR_BOLETO_ACTION, SET_REQUEST_FIELDS_VALUE, LIST_IDS,
} from './RegistrarBoleto.constants';

function buildRequestFields() {
  return {
    data: new FieldModel({
      name: 'data',
      value: getDateDown(30),
    }),
  };
}

function buildInitialStates() {
  return {
    requestFields: buildRequestFields(),
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    columnOrdenacao: { element: 'dataEmissao', order: 'DESC' },
    arrowDirection: true,
    tableView: [],
    selectAll: false,
    listIds: {},
    atualizarListaIds: false,
    common: {
      loadingPage: false,
    },
  };
}

function onChangeDateFieldHandler(states, actions) {
  const { name, value } = actions;
  const field = states.requestFields[name].getNewModel(value);

  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [name]: field,
    },
  };
}


function setSimpleRegistrarBoletoHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    [name]: value,
  };
}

function setCommonHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    common: {
      ...states.common,
      [name]: value,
    },
  };
}

function changeRequestFieldsValueHandler(states, actions) {
  const { name, value } = actions;
  const fieldModel = states.formFields[actions.name].copy();
  return { ...states, formFields: { ...states.formFields, [name]: fieldModel.onChange(value) } };
}

function setSimpleValuesListIdsHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    listIds: {
      ...states.listIds,
      [name]: value,
    },
  };
}

export const onChangeDateFieldAction = (name, value) => ({
  type: SET_DATE_FIELD_ACTION,
  name,
  value,
});
export const setSimpleRegistrarBoletoAction = (name, value) => ({
  type: SET_SIMPLE_REGISTRAR_BOLETO_ACTION,
  name,
  value,
});


export const setCommonAction = (name, value) => ({
  type: SET_COMMON_ACTION,
  name,
  value,
});

export const setRequestFieldsValueAction = (name, value) => ({
  type: SET_REQUEST_FIELDS_VALUE,
  name,
  value,
});

export const setSimpleValuesListIdsAction = (name, value) => ({
  type: LIST_IDS,
  name,
  value,
});

export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    case SET_DATE_FIELD_ACTION:
      return onChangeDateFieldHandler(states, actions);
    case SET_SIMPLE_REGISTRAR_BOLETO_ACTION:
      return setSimpleRegistrarBoletoHandler(states, actions);
    case SET_COMMON_ACTION:
      return setCommonHandler(states, actions);
    case SET_REQUEST_FIELDS_VALUE:
      return changeRequestFieldsValueHandler(states, actions);
    case LIST_IDS:
      return setSimpleValuesListIdsHandler(states, actions);
    default:
      return states;
  }
};
