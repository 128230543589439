import React, { useMemo, useState } from 'react';
import {
  RadioGroup, TableCell, TablePagination, TableRow,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import { v4 as uuid } from 'uuid';
import PaginationAction from '../../../../../components/UI/Tabela/PaginadaSimples/Pagination/PaginationActions';
import {
  ListSeparator, Subtitles, SubtitlesValue, TableWithAccordion,
} from './TabelaRelacaoPrestacaoStyle';
import TooltipUI from '../../../../../components/UI/Tooltip/Tooltip';
import { getMoneyMask, toBrazilianDate, useUtilStyles } from '../../../../../helpers';
import RadioField from '../../../../../components/UI/Field/Radio/RadioField';


function getAlignOption(row, column) {
  let alignOption = row.values[column.id] ? column.alignCell : 'center';
  if (!row.values[column.id] && column.showTip) {
    alignOption = 'center';
  }
  return alignOption;
}

function Row({
  row, columns, onChangeSelecionarRelacao, columnLength,
}) {
  const utilStyles = useUtilStyles();
  const [expand, setExpanded] = useState(false);

  const getContent = (value, column) => {
    if (column.radioButton) {
      return (
        <RadioField
          name="radio"
          value={value}
          onChange={e => onChangeSelecionarRelacao(e, value)}
        />
      );
    }

    if (column.showTip) {
      return (
        <TooltipUI title={value} placement="bottom" interactive>
          <pre
            className={utilStyles.overFlowText}
            style={{ maxWidth: column.maxWidth }}
          >
            {column.format && typeof value === 'number' ? column.format(value) : value}
          </pre>
        </TooltipUI>
      );
    }
    if (column.hiddenFullLine) {
      return (
        <pre
          className={utilStyles.overFlowText}
          style={{ maxWidth: column.maxWidth - 16 }}
        >
          {column.format && typeof value === 'number' ? column.format(value) : value}
        </pre>
      );
    }
    return <pre>{column.format && typeof value === 'number' ? column.format(value) : value}</pre>;
  };

  return (
    <>
      <TableRow className={utilStyles.tableRowRoot}>
        {columns.map((column) => {
          const value = row.values[column.id];
          const alignOption = getAlignOption(row, column);
          return (
            <TableCell
              key={column.id}
              style={{
                maxWidth: column.maxWidth,
                minWidth: column.minWidth,
                width: column.width,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: alignOption,
                }}
              >

                {getContent(value, column)}
              </div>
            </TableCell>
          );
        })}
        <TableCell style={{ borderTop: '1px solid #e0e0e0' }}>
          <IconButton size="small" onClick={() => setExpanded(old => !old)} style={{ color: '#034ddc' }}>
            {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={columnLength} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={expand} timeout="auto" unmountOnExit style={{ margin: '0 40px' }}>
            <Subtitles>
              <SubtitlesValue width="20%"><b>Data</b></SubtitlesValue>
              <SubtitlesValue width="10%"><b>Processo</b></SubtitlesValue>
              <SubtitlesValue width="20%"><b>Tipo do Lançamento</b></SubtitlesValue>
              <SubtitlesValue width="20%"><b>Histórico</b></SubtitlesValue>
              <SubtitlesValue width="15%"><b>Créditos</b></SubtitlesValue>
              <SubtitlesValue width="15%"><b>Débitos</b></SubtitlesValue>
            </Subtitles>
            <Box margin={1}>
              {row.values?.prestacaoRelacaoClientes.map(prestacao => (
                <ListSeparator key={uuid()}>
                  <SubtitlesValue width="20%">{toBrazilianDate(prestacao.data)}</SubtitlesValue>
                  <SubtitlesValue width="10%">{prestacao.idProcesso}</SubtitlesValue>
                  <SubtitlesValue width="20%">{prestacao.descricaoLancamento}</SubtitlesValue>
                  <SubtitlesValue width="20%" textAlign="left">{prestacao.historico}</SubtitlesValue>
                  <SubtitlesValue width="15%" textAlign="right">{getMoneyMask(prestacao.valorCredito)}</SubtitlesValue>
                  <SubtitlesValue width="15%" textAlign="right">{getMoneyMask(prestacao.valorDebito)}</SubtitlesValue>
                </ListSeparator>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function TableRelacaoPrestacao({
  columns = [],
  rows = [],
  size = 0,
  rowsPerPageOptions = [15, 20, 25],
  page = 0,
  rowsPerPage,
  relacaoSelecionada,
  onChangeRelacaoSelecionada,
  onChangePage,
  onChangeRowsPerPage,
}) {
  const columnLength = useMemo(() => columns.length + 1, [columns.length]);

  const buildEmptyRows = () => {
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

    const content = [];
    for (let i = 0; i < emptyRows; i += 1) {
      content.push(
        <TableRow key={i} style={{ height: 31 }}>
          <TableCell colSpan={columnLength}>
            <p />
          </TableCell>
        </TableRow>,
      );
    }
    return content;
  };

  return (
    <>
      <TableWithAccordion>
        <RadioGroup
          key={uuid()}
          value={relacaoSelecionada}
        >
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    id={column.id}
                    key={column.id}
                    style={{
                      maxWidth: column.maxWidth,
                      minWidth: column.minWidth,
                      width: column.width,
                      textAlign: column.align,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <Row
                  key={row.id}
                  row={row}
                  columns={columns}
                  columnLength={columnLength}
                  onChangeSelecionarRelacao={onChangeRelacaoSelecionada}
                />
              ))}
              {buildEmptyRows()}
            </TableBody>
          </Table>
        </RadioGroup>
      </TableWithAccordion>
      <TablePagination
        style={{ width: '100%' }}
        rowsPerPageOptions={rowsPerPageOptions}
        colSpan={columns.length}
        component="div"
        count={size}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Linhas por página"
        backIconButtonProps={{
          'aria-label': 'Página Anterior',
        }}
        nextIconButtonProps={{
          'aria-label': 'Próxima Página',
        }}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        ActionsComponent={PaginationAction}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      />
    </>
  );
}

export default TableRelacaoPrestacao;
