import { Button, IconButton } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import cx from 'classnames';
import { MdPrint as MdPrintIcon } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import PeriodField from '../../../../../components/UI/Field/Period/PeriodField';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import useStyle, {
  ButtonImprimir, ButtonPesquisaAvancada, Container, ContainerPesquisaAvancada, ContainerResumo, FiltroPesquisa, TableHeader,
} from './RecebimentosStyles';
import { ButtonUI, InputField, SelectorField } from '../../../../../components';
import SwitchUI from '../../../../../components/UI/Switch/SwitchUI';
import CardResumo from '../Accordions/RelacaoPrestacao/Cards/CardResumo';
import {
  getMoneyMask, getNewPage, TableUtils, useUtilStyles,
} from '../../../../../helpers';
import TabelaRecebimentos from './TabelaRecebimentos';
import { recebimentosFactory as factory } from './RecebimentosFactory';
import {
  findResponsaveisAction,
} from '../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import {
  definirValorRecebimentoSelecionadosAction,
  emitirEpsOnClickAction,
  habilitarEmitirEhAgruparRpsAction,
  pesquisarRecebimentosOnClickAction,
  gerarPDFRecebimentosPesquisadosAction,
  agruparRPSOnClickAction,
} from '../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import {
  onChangeFormFieldsRecebimentosAction, onFocusPeriodFieldRecebimentosAction, setFormFieldsRecebimentosAction, setSimpleValueRecebimentosAction, setSimpleValueRecebimentosListIdsAction,
} from '../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';


function RecebimentosComponent({ permissoes }) {
  const style = useStyle();
  const utilStyle = useUtilStyles();

  const globalDispatch = useDispatch();
  const dispatch = useCCClienteDispatch();

  const formFields = useCCClienteSelector(states => ({ ...states.recebimentos.formFields }));
  const page = useCCClienteSelector(states => (states.recebimentos.page));
  const rowsPerPage = useCCClienteSelector(states => (states.recebimentos.rowsPerPage));
  const totalElements = useCCClienteSelector(states => (states.recebimentos.totalElements));
  const columnOrdenacao = useCCClienteSelector(states => (states.recebimentos.columnOrdenacao));
  const arrowDirection = useCCClienteSelector(states => (states.recebimentos.arrowDirection));
  const tableView = useCCClienteSelector(states => (states.recebimentos.tableView));
  const listIds = useCCClienteSelector(states => (states.recebimentos.listIds));
  const somentePadrao = useCCClienteSelector(states => (states.recebimentos.formFields.somentePadrao));
  const quantidadeSelecionadaRecebimento = useCCClienteSelector(states => (states.recebimentos.quantidadeSelecionadaRecebimento));
  const valorHonorariosAmigaveisSelecionado = useCCClienteSelector(states => (states.recebimentos.valorHonorariosAmigaveisSelecionado));
  const valorHonorariosJudiciaisSelecionado = useCCClienteSelector(states => (states.recebimentos.valorHonorariosJudiciaisSelecionado));
  const valorDespesasSelecionada = useCCClienteSelector(states => (states.recebimentos.valorDespesasSelecionada));
  const [conteudoTabela, setConteudoTabela] = useState([]); // State que vai atualizar quando tiver dadosPesquisa...
  const selectAll = useCCClienteSelector(states => (states.recebimentos.selectAll));
  const habilitarEmitirRps = useCCClienteSelector(states => (states.recebimentos.habilitarEmitirRps));
  const habilitarAgruparRps = useCCClienteSelector(states => (states.recebimentos.habilitarAgruparRps));
  const tableMax = useCCClienteSelector(states => (states.recebimentos.tableMax));
  const cacheFormFields = useCCClienteSelector(states => (states.recebimentos.cacheFormFields));

  const [openPesquisaAvancada, setOpenPesquisaAvancada] = useState(false);
  const responsaveis = useSelector(states => states.selectors.responsaveisItems);

  const buttonClassPesquisaAvancada = cx({
    [style.pesquisaAvancadaButton]: !openPesquisaAvancada,
    [style.pesquisaSimplesButton]: openPesquisaAvancada,
  });

  const onClickPesquisaAvancadaHandler = useCallback(() => {
    setOpenPesquisaAvancada(oldValue => !oldValue);
  }, []);

  const onClickImprimirHandler = useCallback(() => {
    dispatch(gerarPDFRecebimentosPesquisadosAction());
  }, [dispatch]);

  const onChangeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeFormFieldsRecebimentosAction(name, value));
  }, [dispatch]);

  const onFocusFieldsHandler = useCallback((event) => {
    const { name } = event.target;
    dispatch(onFocusPeriodFieldRecebimentosAction(name));
  }, [dispatch]);


  const onChangeCheckedSwicth = React.useCallback((e) => {
    const { checked } = e.target;
    dispatch(setFormFieldsRecebimentosAction('somentePadrao', checked));
    dispatch(setSimpleValueRecebimentosAction('page', 0));
    dispatch(pesquisarRecebimentosOnClickAction(cacheFormFields, checked, true, true));
  }, [cacheFormFields, dispatch]);

  const setColumnOrdenacao = useCallback((column) => {
    dispatch(setSimpleValueRecebimentosAction('columnOrdenacao', column));
  }, [dispatch]);

  const setArrowDirection = useCallback((internalReturn) => {
    dispatch(setSimpleValueRecebimentosAction('arrowDirection', internalReturn));
  }, [dispatch]);

  const onClickColumnOrdenar = useCallback((arrowDirectionAux, columnOrdenacaoAux) => {
    if (tableMax.length > 0) {
      const recebimentosOrdenados = TableUtils.ordenarTabela(arrowDirectionAux, tableMax, columnOrdenacaoAux.element);
      const recebimentosPaginados = TableUtils.paginar(recebimentosOrdenados, rowsPerPage, 0);
      dispatch(setSimpleValueRecebimentosAction('page', 0));
      dispatch(setSimpleValueRecebimentosAction('tableMax', recebimentosOrdenados));
      dispatch(setSimpleValueRecebimentosAction('tableView', recebimentosPaginados));
    }
  }, [dispatch, rowsPerPage, tableMax]);


  const onClickPesquisar = useCallback(async (event) => {
    event.preventDefault();
    dispatch(setSimpleValueRecebimentosAction('selectAll', false));
    dispatch(setSimpleValueRecebimentosAction('columnOrdenacao', { element: 'idPrestacao', order: 'DESC' }));
    dispatch(setSimpleValueRecebimentosAction('arrowDirection', true));
    dispatch(setSimpleValueRecebimentosAction('tableView', []));
    dispatch(setSimpleValueRecebimentosAction('page', 0));
    const formData = new FormData(event.currentTarget);
    dispatch(pesquisarRecebimentosOnClickAction(formData, somentePadrao.value, false, true));
  }, [dispatch, somentePadrao]);

  const onClickAgruparRpsHandler = useCallback(() => {
    dispatch(agruparRPSOnClickAction());
  }, [dispatch]);

  const changeSelectAll = useCallback((e) => {
    const { checked } = e.target;
    dispatch(setSimpleValueRecebimentosAction('selectAll', checked));
    dispatch(setSimpleValueRecebimentosAction('listIds', Object.keys(listIds).reduce((acc, key) => ({ ...acc, [key]: checked }), {})));
    dispatch(definirValorRecebimentoSelecionadosAction());
  }, [listIds, dispatch]);

  const changeSelectId = useCallback((e) => {
    const { name, checked } = e.target;
    dispatch(setSimpleValueRecebimentosListIdsAction(name, checked));
    dispatch(definirValorRecebimentoSelecionadosAction());
  }, [dispatch]);

  const getDisabledButton = () => {
    if (openPesquisaAvancada) {
      return !(formFields.periodoInicial.value
        || formFields.periodoFinal.value
        || formFields.idCliente.value
        || formFields.idPrestacao.value
        || formFields.idResponsavel.value);
    }
    return !(formFields.periodoInicial.value || formFields.periodoFinal.value);
  };

  const changePageHandler = useCallback((event, newPage) => {
    dispatch(setSimpleValueRecebimentosAction('page', Number(newPage)));

    dispatch(pesquisarRecebimentosOnClickAction(cacheFormFields, somentePadrao.value, true, false));
  }, [dispatch, cacheFormFields, somentePadrao]);

  const changeRowsPerPageHandler = useCallback((event) => {
    const size = event.target.value;
    const newPage = getNewPage(rowsPerPage, page, size);

    dispatch(setSimpleValueRecebimentosAction('page', Number(newPage)));
    dispatch(setSimpleValueRecebimentosAction('rowsPerPage', Number(size)));

    dispatch(pesquisarRecebimentosOnClickAction(cacheFormFields, somentePadrao.value, true, false));
  }, [dispatch, rowsPerPage, page, cacheFormFields, somentePadrao]);

  const onClickEmitirRps = useCallback(async () => {
    dispatch(emitirEpsOnClickAction());
  }, [dispatch]);

  useEffect(() => {
    setConteudoTabela(factory.makeConteudoTabela(
      tableView,
      changeSelectId,
      listIds,
    ));
    dispatch(habilitarEmitirEhAgruparRpsAction());
  }, [globalDispatch, tableView, listIds, changeSelectId, dispatch]);

  useEffect(() => {
    globalDispatch(findResponsaveisAction());
  }, [globalDispatch]);

  return (
    <>
      <Container>
        <form id="form-recebimentos" onSubmit={onClickPesquisar}>
          <div className={style.container}>
            <div className="pesquisa">
              <FiltroPesquisa>
                <div>
                  <div>
                    <PeriodField
                      label="Período da Prestação:"
                      firstField={formFields.periodoInicial.copy()}
                      lastField={formFields.periodoFinal.copy()}
                      onChange={onChangeFieldsHandler}
                      onFocus={onFocusFieldsHandler}
                    />
                  </div>
                  <IconButton
                    type="submit"
                    disabled={getDisabledButton()}
                  >
                    <SearchIcon />
                  </IconButton>
                </div>
              </FiltroPesquisa>
              {openPesquisaAvancada && (
                <ContainerPesquisaAvancada>
                  <div>
                    <div id="codigoCliente">
                      <InputField
                        fullWidth
                        name="idCliente"
                        label="Código do Cliente"
                        onChange={onChangeFieldsHandler}
                      />
                    </div>
                    <InputField
                      fullWidth
                      name="idPrestacao"
                      label="ID Prestação"
                      onChange={onChangeFieldsHandler}
                    />
                  </div>
                  <SelectorField
                    fullWidth
                    hasEmptyLabel
                    name="idResponsavel"
                    label="Responsável"
                    items={responsaveis.selector}
                    onChange={onChangeFieldsHandler}
                  />
                </ContainerPesquisaAvancada>
              )}
              <ButtonPesquisaAvancada>
                <div>
                  <Button
                    fullWidth
                    className={buttonClassPesquisaAvancada}
                    onClick={onClickPesquisaAvancadaHandler}
                  >
                    {openPesquisaAvancada ? 'Pesquisa Simples' : 'Pesquisa Avançada'}
                  </Button>
                </div>
              </ButtonPesquisaAvancada>
              <SwitchUI
                name="somentePadrao"
                label="Somente Padrão"
                marginTop="21px"
                checked={somentePadrao.value}
                onChange={onChangeCheckedSwicth}
              />
              <ButtonImprimir
                disabled={tableView?.length === 0}
                onClick={onClickImprimirHandler}
              >
                <MdPrintIcon size={16} />
                <span>Imprimir</span>
              </ButtonImprimir>
            </div>
            <div id="resumo">
              <ContainerResumo>
                <h3><b>Resumo das Prestações Selecionadas</b></h3>
                <div>
                  <CardResumo
                    title="Quantidade"
                    isDetail={false}
                    bgColor="#a3a1a3"
                    value={quantidadeSelecionadaRecebimento}
                  />
                </div>
                <div>
                  <CardResumo
                    title="Honorários Amigáveis"
                    isDetail={false}
                    bgColor="#c54fd9"
                    value={getMoneyMask(valorHonorariosAmigaveisSelecionado)}
                  />
                  <CardResumo
                    title="Honorários Judiciais"
                    isDetail={false}
                    bgColor="#f85861"
                    value={getMoneyMask(valorHonorariosJudiciaisSelecionado)}
                  />
                  <CardResumo
                    title="Despesas"
                    isDetail={false}
                    bgColor="#ff7b51"
                    value={getMoneyMask(valorDespesasSelecionada)}
                  />
                </div>
                <div id="buttons">
                  <div id="emitir">
                    <ButtonUI
                      disabled={!permissoes.prestarContas || !habilitarEmitirRps}
                      onClick={onClickEmitirRps}
                    >
                      Emitir RPS
                    </ButtonUI>
                  </div>
                  <div id="agrupar">
                    <ButtonUI color="white" disabled={!permissoes.prestarContas || !habilitarAgruparRps} onClick={onClickAgruparRpsHandler}>
                      Agrupar RPS
                    </ButtonUI>
                  </div>
                </div>
              </ContainerResumo>
            </div>
          </div>
          <div className={utilStyle.mr22ml22}>
            <TableHeader>
              Resultado da Pesquisa
            </TableHeader>
            <div>
              <TabelaRecebimentos
                columns={factory.makeCabecalhoTabela(
                  columnOrdenacao,
                  arrowDirection,
                  setColumnOrdenacao,
                  setArrowDirection,
                  onClickColumnOrdenar,
                  selectAll,
                  changeSelectAll,
                )}
                rows={conteudoTabela}
                page={page}
                rowsPerPage={rowsPerPage}
                size={totalElements}
                rowsPerPageOptions={[10, 15, 20]}
                onChangePage={changePageHandler}
                onChangeRowsPerPage={changeRowsPerPageHandler}
              />
            </div>
          </div>
        </form>
      </Container>
    </>
  );
}

export default React.memo(RecebimentosComponent);
