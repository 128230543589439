import React from 'react';
import useStyles from './LoadingAcompanhamento.style';
import { CARREGANDO } from '../../../../helpers/constants/global.constants';

function LoadingAcompanhamento({ show = false }) {
  const styles = useStyles();

  return (
    show && (
      <div className={styles.loadingWrapper}>
        <div className={styles.loading}>
          <span className={styles.loadingText}>
            {CARREGANDO}
          </span>
        </div>
      </div>
    )
  );
}

export default LoadingAcompanhamento;
