
import {
  CORE, gateway,
} from '../../api';

export default {
  getInformacoesAdicionais(idPrestacaoConta) {
    return gateway.get(`${CORE}/conta-corrente-cliente/prestacao-relacao/informacoes/${idPrestacaoConta}`);
  },
};
