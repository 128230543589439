import {
  gateway, gatewayShortJson,
} from '../../api';
import { getUriParams } from '../../../helpers/utils/utils';

const CORE = process.env.REACT_APP_CORE;

export function findFilialById(idFilial) {
  return gateway.get(`${CORE}/filiais/${idFilial}`);
}

export function insertFilial(filial) {
  filial.valorReferenciaCaixaFilial = 0.01;
  filial.percentualAlertaCaixaFilial = 0.01;
  return gateway.post(`${CORE}/filiais/`, filial);
}

export function updateFilial(filial) {
  filial.valorReferenciaCaixaFilial = 0.01;
  filial.percentualAlertaCaixaFilial = 0.01;
  return gateway.put(`${CORE}/filiais/`, filial);
}

export function findFiliais(nomeFilial, page, size) {
  const uri = getUriParams({
    nomeFilial,
    page,
    size,
  });
  return gateway.get(`${CORE}/filiais/?${uri}`);
}

export function getShortFiliais(nomeFilial, statusFilial) {
  const uri = getUriParams({
    nomeFilial,
    statusFilial,
  });
  return gatewayShortJson.get(`${CORE}/filiais/?${uri}`);
}

export function getFilialPrincipal() {
  return gateway.get(`${CORE}/filiais/filial-principal/`);
}

export function deleteFilial(idFilial) {
  return gateway.delete(`${CORE}/filiais/${idFilial}`);
}

export function buscarIdFilialPorIdUsuarioPrincipal(idUsuarioPrincipal) {
  return gateway.get(`${CORE}/filiais/filial-por-usuario-principal/${idUsuarioPrincipal}`);
}
