import styled from 'styled-components';
import ButtonUI from '../UI/Button/ButtonUI';
import PaperUI from '../UI/Paper/PaperUI';

export const BodyContainer = styled(PaperUI)`
  padding: 22px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0%;

  button {
    width: 176px;
    height: 36px;
  }

  img {
    width: 18px;
    height: 18px;
  }

  .chooseFile {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .chooseFileIntenal {
    display: flex;
    width: 50%;
  }

  .tip {
    display: flex;
    margin-left: 15px;

    >div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 5px;
    }
  }

  .loadingText {
    display: flex;
    width: 100%;
    padding-top: 7px;
    padding-bottom: 23px;
    justify-content: left;
    align-items: left;
  }

  .loadingInfo {
    h1 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .result {
    font-size: 14px;
    color: #034ddc;
    font-weight: bold;
    font-family: Roboto;
  }

  .infoResult {
    font-size: 12px;
    color: black;
    font-weight: bold;
    font-family: Roboto;
  }
`;

export const Container = styled(PaperUI)`
  padding: 21px 31px;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0%;
`;

export const DivError = styled.div`
  width: 100%;
  padding: 9px 9px 9px 9px;
  border: solid 2px #cbcbcb;

  div + div {
    margin-top: 3px;
  }

  div {
    display: flex;
    align-items: center;

    span {
      padding-left: 5px;
    }
  }
`;

export const CorrigirButton = styled(ButtonUI)`
  width: 169px;
`;
export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StatusValidacao = styled.span`
  color: ${props => props.color};
  font-weight: bold;
`;
