import FieldModel from '../../../models/FieldModel';
import ChangeAttr from '../../../models/ChangeAttrModel';
import { copyObject, getMoneyMask, toBrazilianDate } from '../../../helpers/utils/utils';

import { STATUS_PROCESSAMENTO_BORDERO } from '../../../helpers/constants/enums.constants';

const SET_ESTATISTICAS = 'SET_ESTATISTICAS_PROCESSAR_BORDEROS';
const CLEAR_ESTATISTICAS = 'CLEAR_ESTATISTICAS_PROCESSAR_BORDEROS';
const LOADING = 'LOADING_PROCESSAR_BORDEROS';
const CHANGE_SEARCH_CRITERIAS = 'CHANGE_SEARCH_CRITERIAS_PROCESSAR_BORDEROS';
const FOCUS_SEARCH_CRITERIAS = 'FOCUS_SEARCH_CRITERIAS_PROCESSAR_BORDEROS';
const CHANGE_ALL_SEARCH_CRITERIAS = 'CHANGE_ALL_SEARCH_CRITERIAS_PROCESSAR_BORDEROS';
const SET_TABLE_INFO = 'SET_TABLE_INFO_PROCESSAR_BORDEROS';
const SET_SELECTED_IDS = 'SET_SELECTED_IDS_PROCESSAR_BORDEROS';
const CHANGE_SELECTED_IDS = 'CHANGE_SELECTED_IDS_PROCESSAR_BORDEROS';
const CHANGE_SELECT_ALL = 'CHANGE_SELECT_ALL_PROCESSAR_BORDEROS';
const SET_TOOLTIP = 'SET_TOOLTIP_PROCESSAR_BORDEROS';
const SET_PROCESSAMENTO_DIALOG = 'SET_PROCESSAMENTO_DIALOG_PROCESSAR_BORDEROS';
const SET_DEFINED_SEARCH_CRITERIAS = 'SET_DEFINED_SEARCH_CRITERIAS_PROCESSAR_BORDEROS';
const SET_SELECT_ALL = 'SET_SELECT_ALL_PROCESSAR_BORDEROS';
const CHANGE_CHECKBOX_TABLE_ROW = 'CHANGE_CHECKBOX_TABLE_ROW_PROCESSAR_BORDEROS';
const SET_DIALOG_INFO = 'SET_DIALOG_INFO_PROCESSAR_BORDEROS';
const CLOSE_DIALOG = 'CLOSE_DIALOG_PROCESSAR_BORDEROS';
const CLEAR_STATES = 'CLEAR_STATES_PROCESSAR_BORDEROS';
const SET_INFORMACOES_PROCESSAMENTO = 'SET_INFORMACOES_PROCESSAMENTO_BORDEROS';
const CHANGE_OPTIONS_PROCESSAMENTO = 'CHANGE_OPTIONS_PROCESSAMENTO_BORDEROS';
const SET_STATES_VALUE = 'SET_STATES_VALUE_PROCESSAMENTO_BORDEROS';
const SET_STATES_VALUE_PROCESSAR = 'SET_STATES_VALUE_PROCESSAR';

function getSelectedIds(states, tableContent, checked) {
  const selectedIds = { ...states.selectedIds };
  const newTableItens = tableContent.filter(bordero => selectedIds[bordero.idBorderoDevedor] === undefined);
  newTableItens.forEach((bordero) => {
    selectedIds[bordero.idBorderoDevedor] = checked;
  });
  return selectedIds;
}

function buildDateNow() {
  return toBrazilianDate(new Date());
}

function buildDate30daysAgo() {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  return toBrazilianDate(date);
}

function buildEstatisticas(aguardandoProcessamentos, emProcessamento, processados, excluidos) {
  return {
    qtdeBorderosAguardandoProcessamento: aguardandoProcessamentos || 0,
    qtdeBorderoEmProcessamento: emProcessamento || 0,
    qtdeBorderosProcessados: processados || 0,
    qtdeBorderosExcluidos: excluidos || 0,
  };
}

function buildInitialStates() {
  return {
    loading: false,
    isProcessamentoDiferenciado: false,
    openProcessamentoModal: false,
    qtdeMaximoTentativasBorderoParametro: 0,
    listaIdsBorderoDevedor: [],
    informacoesProcessamentoBordero: {
      qtdBorderoSelecionado: 0,
      valorTotalBorderoProcessamento: 0,
    },
    estatisticas: {
      qtdeBorderosAguardandoProcessamento: 0,
      qtdeBorderoEmProcessamento: 0,
      qtdeBorderosProcessados: 0,
      qtdeBorderosExcluidos: 0,
    },
    processamentoDiferenciado: {
      manterProcessoFaseInicial: new FieldModel({
        name: 'manterProcessoFaseInicial',
        value: false,
      }),
      processarTitulosAntigos: new FieldModel({
        name: 'processarTitulosAntigos',
        value: false,
      }),
      criarProcessoDuplicidade: new FieldModel({
        name: 'criarProcessoDuplicidade',
        value: false,
      }),
      aceitarProcessoValorBaixo: new FieldModel({
        name: 'aceitarProcessoValorBaixo',
        value: false,
      }),
    },
    searchCriterias: {
      idCliente: new FieldModel({
        name: 'idCliente',
        maxLength: 10,
        changeAttr: new ChangeAttr({ isPositiveInteger: true }),
      }),
      dataInicial: new FieldModel({ name: 'dataInicial', value: buildDate30daysAgo() }),
      dataFinal: new FieldModel({ name: 'dataFinal', value: buildDateNow() }),
      status: new FieldModel({
        name: 'status', value: STATUS_PROCESSAMENTO_BORDERO.AGUARDANDO_PROCESSAMENTO,
      }),
    },
    definedSearchCriterias: null,
    tableInfo: {
      content: [],
      size: 0,
      rowsPerPage: 10,
      page: 0,
      columnOrdenacao: { element: 'dataCadastramento', order: 'DESC' },
      arrowDirection: true,
    },
    selectedIds: {},
    selectAll: false,
    snackbar: {
      open: false,
      message: '',
      variant: 'warning',
    },
    dialog: {
      open: false,
      message: '',
      action: null,
    },
    processamentoDialog: {
      open: false,
      message: '',
      variant: 'warning',
    },
  };
}

function setProcessamentoDialogHandler(states, actions) {
  const processamentoDialog = {
    open: actions.open,
    variant: actions.variant,
    message: actions.message,
  };
  return { ...states, processamentoDialog };
}

function setStatesValueHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    [name]: value,
  };
}

function setInformacoesProcessamentoBorderoHandler(states, actions) {
  return {
    ...states,
    informacoesProcessamentoBordero: {
      qtdBorderoSelecionado: actions.qtdSelecionado,
      valorTotalBorderoProcessamento: getMoneyMask(actions.valorTotalProcessamento),
    },
  };
}

function clearEstatisticasHandler(states) {
  return { ...states, estatisticas: buildEstatisticas() };
}

function setEstatisticaHandler(states, actions) {
  return ({ ...states, estatisticas: { ...actions.estatisticas } });
}

function setLoadingHandler(states, actions) {
  return ({ ...states, loading: actions.loading });
}

function onChangeSearchCriteriasHandler(states, actions) {
  const newSearchCriterias = { ...states.searchCriterias };
  newSearchCriterias[actions.name].onChange(actions.value);
  return ({ ...states, searchCriterias: newSearchCriterias });
}

function onFocusSearchCriteriasHandler(states, actions) {
  const newSearchCriterias = { ...states.searchCriterias };
  newSearchCriterias[actions.name].onFocus();
  return ({ ...states, searchCriterias: newSearchCriterias });
}

function onChangeAllSearchCriteriasHandler(states, actions) {
  return ({ ...states, searchCriterias: { ...actions.searchCriterias } });
}

function setTableInfoHandler(states, actions) {
  return {
    ...states,
    tableInfo: {
      ...states.tableInfo,
      content: actions.content,
      size: actions.size,
      page: actions.page,
      rowsPerPage: actions.rowsPerPage,
    },
  };
}

function changeSelectedIdsHandler(states, actions) {
  const idsCopy = copyObject(states.selectedIds);
  Object.keys(actions.selectedIds).forEach((key) => {
    idsCopy[key] = false;
  });

  return { ...states, selectedIds: idsCopy };
}

function setSelectedIdsHandler(states, actions) {
  return { ...states, selectedIds: { ...actions.selectedIds } };
}

function onChangeAllCheckboxHandler(states, actions) {
  let tableContent = [...states.tableInfo.content];
  let selectedIds = getSelectedIds(states, tableContent, actions.checked);

  selectedIds = Object.keys(states.selectedIds).reduce((map, ids) => {
    map[ids] = actions.checked;
    return map;
  }, {});

  tableContent = tableContent.map(bordero => ({
    ...bordero,
    checked: selectedIds[bordero.idBorderoDevedor],
  }));

  return {
    ...states,
    selectedIds,
    selectAll: actions.checked,
    tableInfo: {
      ...states.tableInfo,
      content: tableContent,
    },
  };
}

function setSnackbarHandler(states, actions) {
  const snackbar = {
    open: actions.open,
    variant: actions.variant,
    message: actions.message,
  };
  return { ...states, snackbar };
}

function setDefinedSearchCriteriasHandler(states, actions) {
  return { ...states, definedSearchCriterias: { ...actions.criterias } };
}

function setSelectAllHandler(states, actions) {
  return { ...states, selectAll: actions.checked };
}

function onChangeTableRowCheckboxHandler(states, actions) {
  const tableContent = [...states.tableInfo.content];
  tableContent[actions.index].checked = actions.checked;

  const selectedIds = copyObject(states.selectedIds);
  const { idBorderoDevedor } = tableContent[actions.index];
  selectedIds[idBorderoDevedor] = actions.checked;
  return {
    ...states,
    selectedIds,
    tableInfo: {
      ...states.tableInfo,
      content: tableContent,
    },
  };
}

function setDialogInfoHandler(states, actions) {
  const dialog = { ...states.dialog, open: actions.open };
  if (actions.message) {
    dialog.message = actions.message;
    dialog.action = actions.action;
  }
  return {
    ...states,
    dialog,
  };
}

function clearStatesHandler() {
  return buildInitialStates();
}

function closeDialogHandler(states) {
  return {
    ...states,
    dialog: {
      ...states.dialog,
      open: false,
    },
  };
}

function changeOptionProcessamentoHandler(states, actions) {
  const { name, checked } = actions;
  const isChecked = states.processamentoDiferenciado[name].getNewModel(checked);
  return {
    ...states,
    processamentoDiferenciado: {
      ...states.processamentoDiferenciado,
      [name]: isChecked,
    },
  };
}

function setStatesValueProcessarBorderoHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    tableInfo: {
      ...states.tableInfo,
      [name]: value,
    },
  };
}

export const setProcessamentoDialogAction = (open, variant = null, message = '') => ({
  type: SET_PROCESSAMENTO_DIALOG,
  open,
  variant,
  message,
});

export const setStatesValueAction = (name, value) => ({
  type: SET_STATES_VALUE,
  name,
  value,
});

export const setInformacoesProcessamentoBorderoAction = (qtdSelecionado, valorTotalProcessamento) => ({
  type: SET_INFORMACOES_PROCESSAMENTO,
  qtdSelecionado,
  valorTotalProcessamento,
});

export const closeDialogAction = () => ({
  type: CLOSE_DIALOG,
});

export const setEstatisticasAction = estatisticas => ({
  type: SET_ESTATISTICAS,
  estatisticas,
});

export const clearEstatisticasAction = () => ({
  type: CLEAR_ESTATISTICAS,
});

export const setLoadingAction = loading => ({
  type: LOADING,
  loading,
});

export const onChangeSearchCriteriasAction = (name, value) => ({
  type: CHANGE_SEARCH_CRITERIAS,
  name,
  value,
});

export const onFocusSearchCriteriasAction = name => ({
  type: FOCUS_SEARCH_CRITERIAS,
  name,
});

export const onChangeAllSearchCriteriasAction = searchCriterias => ({
  type: CHANGE_ALL_SEARCH_CRITERIAS,
  searchCriterias,
});

export const setTableInfoAction = (content, size, page, rowsPerPage) => ({
  type: SET_TABLE_INFO,
  content,
  size,
  page,
  rowsPerPage,
});

export const setSelectedIdsAction = selectedIds => ({
  type: SET_SELECTED_IDS,
  selectedIds,
});

export const changeSelectedIdsAction = selectedIds => ({
  type: CHANGE_SELECTED_IDS,
  selectedIds,
});

export const onChangeAllCheckboxAction = checked => ({
  type: CHANGE_SELECT_ALL,
  checked,
});

export const setSnackbarAction = (open, variant = null, message = '') => ({
  type: SET_TOOLTIP,
  open,
  variant,
  message,
});

export const setDefinedSearchCriteriasAction = criterias => ({
  type: SET_DEFINED_SEARCH_CRITERIAS,
  criterias,
});

export const setSelectAllAction = checked => ({
  type: SET_SELECT_ALL,
  checked,
});

export const onChangeTableRowCheckboxAction = (index, checked) => ({
  type: CHANGE_CHECKBOX_TABLE_ROW,
  index,
  checked,
});

export const setDialogInfoAction = (open, message, action = null) => ({
  type: SET_DIALOG_INFO,
  open,
  message,
  action,
});

export const changeOptionProcessamentoAction = (name, checked) => ({
  type: CHANGE_OPTIONS_PROCESSAMENTO,
  name,
  checked,
});


export const clearProcessarBorderoAction = () => ({
  type: CLEAR_STATES,
});

export const setStatesValueProcessarBorderoAction = (name, value) => ({
  type: SET_STATES_VALUE_PROCESSAR,
  name,
  value,
});


export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    case SET_ESTATISTICAS:
      return setEstatisticaHandler(states, actions);
    case CLEAR_ESTATISTICAS:
      return clearEstatisticasHandler(states);
    case LOADING:
      return setLoadingHandler(states, actions);
    case CHANGE_SEARCH_CRITERIAS:
      return onChangeSearchCriteriasHandler(states, actions);
    case FOCUS_SEARCH_CRITERIAS:
      return onFocusSearchCriteriasHandler(states, actions);
    case SET_TABLE_INFO:
      return setTableInfoHandler(states, actions);
    case CHANGE_ALL_SEARCH_CRITERIAS:
      return onChangeAllSearchCriteriasHandler(states, actions);
    case SET_SELECTED_IDS:
      return setSelectedIdsHandler(states, actions);
    case CHANGE_SELECTED_IDS:
      return changeSelectedIdsHandler(states, actions);
    case CHANGE_SELECT_ALL:
      return onChangeAllCheckboxHandler(states, actions);
    case SET_TOOLTIP:
      return setSnackbarHandler(states, actions);
    case SET_DEFINED_SEARCH_CRITERIAS:
      return setDefinedSearchCriteriasHandler(states, actions);
    case SET_SELECT_ALL:
      return setSelectAllHandler(states, actions);
    case CHANGE_CHECKBOX_TABLE_ROW:
      return onChangeTableRowCheckboxHandler(states, actions);
    case SET_DIALOG_INFO:
      return setDialogInfoHandler(states, actions);
    case CLEAR_STATES:
      return clearStatesHandler(states);
    case CLOSE_DIALOG:
      return closeDialogHandler(states);
    case SET_INFORMACOES_PROCESSAMENTO:
      return setInformacoesProcessamentoBorderoHandler(states, actions);
    case CHANGE_OPTIONS_PROCESSAMENTO:
      return changeOptionProcessamentoHandler(states, actions);
    case SET_STATES_VALUE:
      return setStatesValueHandler(states, actions);
    case SET_PROCESSAMENTO_DIALOG:
      return setProcessamentoDialogHandler(states, actions);
    case SET_STATES_VALUE_PROCESSAR:
      return setStatesValueProcessarBorderoHandler(states, actions);
    default:
      return states;
  }
};
