import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineClose } from 'react-icons/ai';
import { RightDrawerContainer } from './rightDrawer..styles';
import { TransparentBackground } from '../TransparentBackground/TransparentBackground';

function RightDrawerComponent({
  children, width, open, handleCloseDrawer,
}) {
  return (
    <>
      <TransparentBackground show={open} role="button" onClick={handleCloseDrawer} />
      <RightDrawerContainer width={width} open={open}>
        <button type="button" onClick={handleCloseDrawer}>
          <AiOutlineClose size={16} />
        </button>
        <div>
          {children}
        </div>
      </RightDrawerContainer>
    </>
  );
}

RightDrawerComponent.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  handleCloseDrawer: PropTypes.func.isRequired,
  width: PropTypes.string,
};

RightDrawerComponent.defaultProps = {
  width: '512px',
};

export const RightDrawer = RightDrawerComponent;
