import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  cardEstatisticasBorderos: {
    borderRadius: '6px',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    border: '1px solid #e4e4e4',
    width: '100%',
    '& hr': {
      border: '1px solid #cecece',
      marginLeft: '7.5px',
      marginRight: '7.5px',
    },
    '& header': {
      textAlign: 'center',
    },
    '& header h2': {
      fontSize: '1rem',
      lineHeight: '1.1875rem',
      color: '#034ddc',
      fontWeight: 'normal',
      marginTop: '6.5px',
    },
    '& section': {
      margin: '12px 17px',
    },
    '& section p': {
      fontSize: '12px',
    },
    '& section span': {
      fontSize: '16px',
    },
  },
});
