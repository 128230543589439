import React from 'react';
/*
import PrivateRoute from '../../hoc/PrivateRoute/PrivateRoute';
import CadastrarPossivelClientePage from './Cadastrar/CadastrarPossivelClientePage';
import EditarPossivelClientePage from './Atualizar/EditarPossivelClientePage';
import DetalharPossivelClientePage from './Detalhes/DetalharPossivelClientePage';
import PesquisaPossivelCliente from './PesquisaPossivelCliente';
import MigrarPossivelCliente from './Migrar/MigrarPossivelCliente';
*/

export default function RoutesPossivelCliente() {
  return (
    <>
      {
      /* Funcionalidade desativada

      Remover comentários para ativar novamente.

      <PrivateRoute
        exact
        tipoUsuario="U"
        path="/possivel-cliente"
        component={PesquisaPossivelCliente}
      />
      <PrivateRoute
        path="/possivel-cliente/cadastrar"
        tipoUsuario="U"
        roles={['902']}
        component={CadastrarPossivelClientePage}
      />
      <PrivateRoute
        path="/possivel-cliente/:id/editar"
        tipoUsuario="U"
        roles={['904']}
        component={EditarPossivelClientePage}
      />
      <PrivateRoute
        path="/possivel-cliente/:id/detalhar"
        exact
        tipoUsuario="U"
        roles={['901']}
        component={DetalharPossivelClientePage}
      />
      <PrivateRoute
        path="/possivel-cliente/:id/migrar"
        exact
        tipoUsuario="U"
        roles={['904']}
        component={MigrarPossivelCliente}
      />
      */
    }
    </>
  );
}
