import React, { useCallback } from 'react';
import cx from 'classnames';
import CheckboxField from '../../Field/Checkbox/CheckboxField';
import IconButtonTooltip from '../../IconButtonTooltip/IconButtonTooltip';
import InputField from '../../Field/Input';
import useStyles from './Email.styles';
import Grid24 from '../../Grid24';
import { getAttributeExistingObject, getRequiredLabel } from '../../../../helpers/utils/utils';


export function buildLayoutConstants(requiredFields) {
  return {
    email: getRequiredLabel('E-mail', getAttributeExistingObject(requiredFields, 'email', false)),
    padrao: 'Padrão',
    boleto: 'Boleto',
    notaFiscal: 'Nota Fiscal',
    hintRemover: 'Excluir E-mail',
  };
}


function EmailProvisorio({
  model,
  onChange,
  onFocus,
  onChecked,
  onRemove,
  disabled = false,
  baseName = '',
  requiredFields = [],
}) {
  const styles = useStyles();
  const {
    email, permitirPadrao, permitirBoleto, permitirNotaFiscal,
  } = model;

  // @TODO
  // Linha 60 --> Para campos que não sao FieldModel diretamente
  // Por causa do tempo da sprint fez assim, analisar factory para transformar em fieldModel.

  const constants = buildLayoutConstants(requiredFields);

  const buildName = name => `${baseName}-${name}`;
  return (
    <Grid24 container spacing={1}>
      <Grid24 xs={24} sm={13}>
        <InputField
          isForm
          fullWidth
          disabled={disabled}
          label={constants.email}
          name={buildName(email.name)}
          value={email.value || email}
          error={email.error || null}
          errorMessage={email.errorMessage}
          onChange={useCallback(event => onChange(event), [onChange])}
          onFocus={useCallback(event => onFocus(event), [onFocus])}
        />
      </Grid24>
      <Grid24
        xs={24}
        sm={11}
        container
        className={
          cx({
            [styles.actionWithRemoveButton]: true,
          })}
      >
        <Grid24 xs={24} sm={6} spacing={1}>
          <CheckboxField
            disabled={disabled}
            label={constants.padrao}
            name={buildName(permitirPadrao.name)}
            checked={permitirPadrao.value || permitirPadrao}
            onChange={onChecked}
          />
        </Grid24>
        <Grid24 xs={24} sm={6} spacing={1}>
          <CheckboxField
            disabled={disabled}
            label={constants.boleto}
            name={buildName(permitirBoleto.name)}
            checked={permitirBoleto.value || permitirBoleto}
            onChange={onChecked}
          />
        </Grid24>
        <Grid24 xs={24} sm={6} spacing={1}>
          <CheckboxField
            disabled={disabled}
            label={constants.notaFiscal}
            name={buildName(permitirNotaFiscal.name)}
            checked={permitirNotaFiscal.value || permitirNotaFiscal}
            onChange={onChecked}
          />
        </Grid24>
        <Grid24 xs={24} sm={6}>
          {!disabled && (
            <div>
              <IconButtonTooltip
                type="trash"
                hint={constants.hintRemover}
                onClick={onRemove}
              />
            </div>
          )}
        </Grid24>
      </Grid24>
    </Grid24>
  );
}

export default React.memo(EmailProvisorio);
