import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';


export const MainContainer = styled.div`
  margin-top: 8px;

  >div + div {
    margin-top: 8px;
  }
`;

export const FooterContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row-reverse;
`;

export const ContentDialog = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default makeStyles({
  dialog: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '1200px',
    },
    typography: {
      fontSize: '12px',
    },
  },
});
