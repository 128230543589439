import React from 'react';
import ButtonUI from '../../../../../components/UI/Button/ButtonUI';
import {
  MainContainer, FooterContainer,
} from './AcaoQuitarStyles';
import { ActionHeaderContainer } from '../PagamentosStyle';
import { SimpleTitle } from '../../../../../components/Title/SimpleTitle/SimpleTitle';
import Grid24 from '../../../../../components/UI/Grid24/Grid24';
import MoneyField from '../../../../../components/UI/Field/Money/MoneyField';

export default function AcaoQuitarComponent() {
  return (
    <>
      <form>
        <ActionHeaderContainer>
          <SimpleTitle
            text="Quitação"
          />
        </ActionHeaderContainer>
        <MainContainer>
          <Grid24 xs={24} sm={24}>
            <Grid24 xs={24} sm={12}>
              <MoneyField
                isForm
                disabled
                label="Valor da Quitação"
                name={' '}
                value={' '}
                onChange={' '}
                onFocus={' '}
              />
            </Grid24>
          </Grid24>
        </MainContainer>
        <FooterContainer>
          <ButtonUI
            color="success"
            style={{ width: '136px' }}
            type="submit"
          >
            Quitar
          </ButtonUI>
        </FooterContainer>
      </form>
    </>
  );
}

export const AcaoQuitar = AcaoQuitarComponent;
