import React, { useCallback, useEffect, useState } from 'react';
import { RadioGroup, Typography } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { Button } from '@mui/material';
import useStyles from './AutoTextoDialog.styles';
import TableHeader from '../../../models/TableHeader';
import RadioField from '../../../components/UI/Field/Radio/RadioField';
import LoadingUI from '../../../components/UI/Loading/LoadingUI';
import Table from '../../../components/UI/Tabela/PaginadaSimples/Table';
import DialogNew from '../../../layouts/FormDialog/DialogNew';
import { buscarAutoTextos } from '../../../services/core/processos/processosEnviarEmailDevedorService';


const tableColumns = [
  new TableHeader({
    id: 'selecionado', label: '', width: '70px', align: 'center', alignCell: 'center',
  }),
  new TableHeader({
    id: 'opcoesTags', label: 'Opções de Tags', width: '70px', alignCell: 'left',
  }),
  new TableHeader({
    id: 'descricao', label: 'Descrição', width: '70px', alignCell: 'left',
  }),
];

function AutoTextoDialog({
  open, closeDialogHandler, insertTextAtCursor,
}) {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [tableRows, setTableRows] = useState([]);

  const [selecionadoRadio, setSelecionadoRadio] = useState('');
  const [tagSelecionada, setTagSelecionada] = useState('');


  const carregarAutoTextos = useCallback(async () => {
    setLoading(true);
    try {
      const response = await buscarAutoTextos();
      setTableRows(response.data);
    } finally {
      setLoading(false);
    }
  }, []);

  const onChangeRadioGroup = (event) => {
    setSelecionadoRadio(event.target.value);
  };

  const onCloseHandler = useCallback(() => {
    setSelecionadoRadio('');
    setTagSelecionada('');
    closeDialogHandler();
  }, [closeDialogHandler]);


  const onClickConfirmarHandler = useCallback(() => {
    insertTextAtCursor(tagSelecionada);
    closeDialogHandler();
  }, [insertTextAtCursor, closeDialogHandler, tagSelecionada]);


  const conteudoTabela = tableRows.map(conteudo => ({
    id: uuid(),
    values: {
      selecionado: (
        <RadioField
          name="selecionados"
          onChange={() => setTagSelecionada(conteudo.valorTag)}
          value={`${conteudo.valorTag}-${conteudo.descricaoTag}`}
        />),
      opcoesTags: conteudo.valorTag,
      descricao: conteudo.descricaoTag,
    },
  }));

  useEffect(() => {
    if (open) {
      carregarAutoTextos().then();
    }
  }, [open, carregarAutoTextos]);

  return (
    <DialogNew
      open={open}
      text={['Auto Texto']}
      className={styles.dialog}
      onClose={onCloseHandler}
    >
      <LoadingUI show={loading} />
      <Typography component="p" className={styles.typography}>
        Selecione um Texto:
      </Typography>
      <div className={styles.div__table}>
        <RadioGroup
          name="selecionados"
          value={selecionadoRadio}
          onChange={onChangeRadioGroup}
        >
          <Table isScrollable rows={conteudoTabela} columns={tableColumns} />
        </RadioGroup>
      </div>
      <div className={styles.div__footer}>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
          <Button
            onClick={onCloseHandler}
          >
            Cancelar
          </Button>
          <Button
            disabled={!selecionadoRadio}
            onClick={onClickConfirmarHandler}
          >
            Confirmar
          </Button>
        </div>
      </div>
    </DialogNew>
  );
}

export default AutoTextoDialog;
