import { makeErrorField } from '../../../helpers/utils/form.utils';
import { inserirDocumentoProcessoService, updateDocumentoProcessoService } from '../../../services/core/processos/processoDocumentoService';

class SalvarDocumentoProcessoController {
  makeErrorFields() {
    return {
      documentoProcesso: makeErrorField(),
      tipo: makeErrorField(),
      descricao: makeErrorField(),
    };
  }

  makeFormFields(values) {
    return {
      nomeDocumento: values?.nomeDocumento || '',
      tipo: values?.tipo || '',
      descricao: values?.descricao || '',
      ocultarDocumentoCliente: values?.ocultarDocumentoCliente || false,
      serDocumentoOriginal: values?.serDocumentoOriginal || false,
    };
  }

  makePayload(idProcesso, idProcessoDocumento, formData) {
    formData.set('idProcesso', idProcesso);

    const arquivado = !!formData.get('serDocumentoOriginal');
    formData.set('serDocumentoOriginal', arquivado);

    const ocultarDocumentoCliente = !!formData.get('ocultarDocumentoCliente');
    formData.set('ocultarDocumentoCliente', ocultarDocumentoCliente);

    if (idProcessoDocumento) {
      formData.delete('documentoProcesso');
      formData.set('idProcessoDocumento', idProcessoDocumento);
    }

    return formData;
  }

  async salvarDocumento(idProcesso, idProcessoDocumento, formData) {
    const payload = this.makePayload(idProcesso, idProcessoDocumento, formData);

    if (idProcessoDocumento) {
      await updateDocumentoProcessoService(payload);
    } else {
      await inserirDocumentoProcessoService(payload);
    }
  }
}

export const salvarDocumentoProcessoController = new SalvarDocumentoProcessoController();
