import React, {
  useCallback, useMemo, useState,
} from 'react';

import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { useCrudClienteDispatch } from '../../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import { TabsUi } from '../../../../../../UI/TabsUi/TabsUI';
import { baseGrey, white } from '../../../../../../../helpers/constants/colors.constants';
import FaixaNumeroHonorarios from '../../../../../../UI/Honorarios/Faixa/FaixaNumeroHonorarios';
import NumberInput from '../../../../../../UI/Field/Number/NumberInput';
import { alterarValorHonorariosMinimoFaixaFaseClienteAction } from '../../../../../../../store/Cliente/V2/Crud/crudCliente.store';


function FaixasHonorariosFaseCliente({ tipoCalculoHonorario, disabled }) {
  const dispatchCliente = useCrudClienteDispatch();
  const fases = useSelector(state => state.selectors.fases.selector);

  const [currentTab, setCurrentTab] = useState(0);

  const faixas = tipoCalculoHonorario?.honorarios;

  const tabs = useMemo(() => (
    fases.map(faseTab => ({ id: faseTab.id, name: faseTab.value }))
  ), [fases]);

  const onChangeTabs = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, [setCurrentTab]);

  const onChangeTaxaMinima = useCallback((faixaNum, valueMin) => {
    const fase = tabs[currentTab];
    const newFaixa = faixas[faixaNum];

    const existingObjIndex = newFaixa?.clientesHonorarioMinimoPorFase.findIndex(obj => obj.idFase.value === fase.id);
    if (existingObjIndex > -1) {
      newFaixa.clientesHonorarioMinimoPorFase[existingObjIndex].porcentagemHonorarioMinimo.onChange(Number(valueMin));
    }

    dispatchCliente(alterarValorHonorariosMinimoFaixaFaseClienteAction(faixaNum, newFaixa, fases));
  }, [faixas, currentTab, tabs, dispatchCliente, fases]);

  return (
    <Box mt={2}>
      <TabsUi
        items={tabs}
        value={currentTab}
        containerBackground={white}
        onChange={onChangeTabs}
        tabSelectedBackground={baseGrey}
      />
      { faixas?.map((faixa, idx) => {
        const valueTaxaMinima = faixa?.clientesHonorarioMinimoPorFase[currentTab]?.porcentagemHonorarioMinimo.value.toString();

        return (
          <Box mt={1} display="flex" key={idx}>
            <FaixaNumeroHonorarios numFaixa={idx} />
            <NumberInput
              key={`${idx}${currentTab}`}
              isForm
              disabled={disabled}
              sideText="(%)"
              numberType="percentage"
              maxValue={100}
              label="Percentual de Honorários"
              name="taxaMinima"
              value={valueTaxaMinima || '0.00'}
              onChange={e => onChangeTaxaMinima(idx, e.target.value)}
            />
          </Box>
        );
      })}
    </Box>

  );
}

export default FaixasHonorariosFaseCliente;
