import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  message: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'red',
    fontSize: '0.875rem',
  },
  hide: {
    opacity: '0',
  },
});
