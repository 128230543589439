import { makeErrorField } from '../../../../helpers';
import FieldModel from '../../../../models/FieldModel';
import {
  buscarEmailsPadraoDevedorAcordo, buscarParametrosEmailTermoAcordo, enviarEmailsTermoAcordo,
} from '../../../../services/core/processos/processosAcordosService';

class DialogReciboController {
  makeFormFields() {
    return ({
      emailList: new FieldModel({
        name: 'emailList',
        value: '',
      }),
    });
  }

  makeErrorFields() {
    return {
      emailList: makeErrorField(),
    };
  }

  makePayload(camposPayload, formFields) {
    return {
      emailList: formFields.emailList.value,
      idAcordo: camposPayload.idAcordo,
      nroParcela: camposPayload.nroParcelaDialogRecibo,
      tipoFormaPagto: camposPayload.tipoFormaPagtoDialogRecibo,
    };
  }


  enviarEmails(camposPayload, formFields) {
    return buscarParametrosEmailTermoAcordo(
      camposPayload.idAcordo,
      {
        emailList: formFields.emailList.value,
        nroParcela: camposPayload.nroParcelaDialogRecibo,
      },
    ).then(response => enviarEmailsTermoAcordo(response.data, 'parcela'));
  }

  buscarEmailsPadraoDevedorAcordo(idAcordo) {
    return buscarEmailsPadraoDevedorAcordo(idAcordo);
  }
}

export const dialogReciboController = new DialogReciboController();
