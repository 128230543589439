import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  wrapper: {
    marginTop: '6px',
    width: '100%',
  },
  divider: {
    marginTop: '7px',
  },
});
