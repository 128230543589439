import React from 'react';
import cx from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import './Grid24.css';

function getMediaQuery(matchXs, matchSm, matchLg) {
  if (matchSm) {
    return 'sm';
  }
  if (matchXs) {
    return 'xs';
  }
  if (matchLg) {
    return 'lg';
  }
  return 'xl';
}

function hasValue(value) {
  return value !== null && value !== undefined;
}

function Grid24({
  children,
  className,
  style,
  xs,
  sm,
  lg,
  xl,
  spacing,
  alignContent = 'stretch',
  alignItems = 'stretch',
  direction = 'row',
  justify = 'flex-start',
  alignSelf = 'stretch',
  wrap = 'wrap',
  container = false,
  fullWidth = true,
  item = true,
  component = 'div',
  isFormContainer = false,
  ...props
}) {
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));

  const gridMediaQuery = {
    xs, sm, lg, xl,
  };

  const mediaQuery = getMediaQuery(matchXs, matchSm, matchLg);
  const hasMediaQuery = hasValue(xs) || hasValue(sm) || hasValue(lg) || hasValue(xl);

  const gridClass = `Grid24-grid-${gridMediaQuery[mediaQuery]}`;
  const hasClassName = !!className;

  const spacingClass = `Grid24-spacing-${spacing}`;
  const hasSpacing = !!spacing;
  const Tag = component;
  return (
    <Tag
      {...props}
      className={
        cx({
          [spacingClass]: hasSpacing,
          'Grid24-root': true,
          'Grid24-container': container,
          'Grid24-item': item,
          'Grid24-fullWidth': fullWidth,
          [gridClass]: hasMediaQuery,
          [className]: hasClassName,
          'Form-container-margin': isFormContainer,
        })
      }
      style={{
        alignContent,
        alignItems,
        flexDirection: direction,
        justifyContent: justify,
        flexWrap: wrap,
        alignSelf,
        ...style,
      }}
    >
      {children}
    </Tag>
  );
}

export default React.memo(Grid24);
