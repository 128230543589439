import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import Grid24 from '../../../components/UI/Grid24/Grid24';

export const GridRow = styled(Grid24)`
  >div {
    margin-top: 8px;
  }
`;

export const CheckboxGridRow = styled(Grid24)`
  padding-left: 8px;
  margin-top: 8px;

  >div + div {
    margin-left: 35px;
  }
`;

export const FooterContainer = styled.footer`
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
`;

export default makeStyles({
  dialog: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '1200px',
    },
    typography: {
      fontSize: '12px',
    },
  },
});
