import React, {
  useCallback, useImperativeHandle, useState,
} from 'react';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';

import {
  TextareaField, InputField, SelectorField,
} from '../../../components';

import {
  ButtonDialog, DialogNewEmail,
} from './enviarEmailDocumentoProcessoStyles';

import {
  getFieldName, makeErrorField, updateFieldsWithErrors,
  toastUnmappedException, TableUtils,
} from '../../../helpers';

import { enviarEmailComDocumentosProcessoService } from '../../../services';

import { enviarEmailDocumentoProcessoController as controller } from './enviarEmailDocumentoProcessoController';
import { loadingInspecaoProcessoController } from '../loadingInspecaoProcessoController';


const emailsTypes = [
  { id: 'EMAIL_CLIENTE', value: 'Cliente' },
  { id: 'EMAIL_DEVEDOR', value: 'Devedor' },
  { id: 'EMAIL_COLABORADOR_ABE', value: 'Outros' },
];

function EnviarEmailDocumentoProcessoComponent({ idProcesso, setLoadings }, ref) {
  const [isOpen, setIsOpen] = useState(false);
  const [totalDocumentos, setTotalDocumentos] = useState();
  const [formFields, setFormFields] = useState(controller.makeFormFields());
  const [errorFields, setErrorFields] = useState(controller.makeErrorFields());

  function handleClose() {
    setIsOpen(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoadings(loadingInspecaoProcessoController.changeLoadingDocumentos(true));

    try {
      const payload = controller.makePayload(idProcesso, formFields);
      await enviarEmailComDocumentosProcessoService(payload);
      toast.success('O E-mail foi enviado com sucesso');
      handleClose();
    } catch (err) {
      if (err?.response?.data?.validations) {
        const [updatedErrorsFields] = updateFieldsWithErrors(err, errorFields);
        setErrorFields({ ...updatedErrorsFields });
      } else {
        toastUnmappedException(err, 'Ocorreu um problema ao tentar enviar o e-mail');
      }
    } finally {
      setLoadings(loadingInspecaoProcessoController.changeLoadingDocumentos(false));
    }
  }

  const handleFocus = useCallback((e) => {
    const name = getFieldName(e.target);
    setErrorFields(oldValues => ({ ...oldValues, [name]: makeErrorField() }));
  }, []);

  const handleChange = useCallback((e) => {
    const name = getFieldName(e.target);
    const { value } = e.target;
    setFormFields(oldValue => ({ ...oldValue, [name]: value }));
  }, []);

  const handleChangeTiposDestinatario = useCallback(async (e) => {
    const { value } = e.target;
    const name = getFieldName(e.target);

    const emails = await controller.buscarEmails(idProcesso, value);
    setFormFields(oldFields => ({ ...oldFields, email: emails, [name]: value }));
  }, [idProcesso]);

  useImperativeHandle(ref, () => ({
    handleOpen: async (idsDocumentosProcesso = new Map()) => {
      setLoadings(loadingInspecaoProcessoController.changeLoadingDocumentos(true));

      const emails = await controller.buscarEmails(idProcesso, formFields.tipoDestinatario);
      const checkedIdsDocumentos = TableUtils.getIdsSelected(idsDocumentosProcesso);

      setFormFields(controller.makeFormFields(emails, checkedIdsDocumentos));
      setErrorFields(controller.makeErrorFields());
      setTotalDocumentos(checkedIdsDocumentos.length);
      setLoadings(loadingInspecaoProcessoController.changeLoadingDocumentos(false));
      setIsOpen(!isOpen);
    },
  }));

  const maisQueUmDocumento = totalDocumentos > 1;

  return (
    <DialogNewEmail
      open={isOpen}
      text="Enviar Informações do E-mail"
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <Grid container style={{ marginTop: '22px' }}>
          <SelectorField
            fullWidth={false}
            isForm
            style={{ width: '168px' }}
            label="Tipos do Destinatário"
            name="tipoDestinatario"
            items={emailsTypes}
            value={formFields.tipoDestinatario}
            onChange={handleChangeTiposDestinatario}
          />
        </Grid>
        <Grid container style={{ marginTop: '22px' }}>
          <InputField
            fullWidth
            isForm
            label="Para*"
            name="email"
            value={formFields.email}
            error={errorFields.email?.error}
            errorMessage={errorFields.email?.errorMessage}
            onChange={handleChange}
            onFocus={handleFocus}
          />
        </Grid>
        <Grid container style={{ marginTop: '22px' }}>
          <InputField
            fullWidth
            isForm
            label="Cópia"
            name="copia"
            value={formFields.copia}
            error={errorFields.copia?.error}
            errorMessage={errorFields.copia?.errorMessage}
            onChange={handleChange}
            onFocus={handleFocus}
          />
        </Grid>
        <Grid container style={{ marginTop: '22px' }}>
          <InputField
            fullWidth
            isForm
            label="Assunto*"
            name="assunto"
            value={formFields.assunto}
            error={errorFields.assunto?.error}
            errorMessage={errorFields.assunto?.errorMessage}
            onChange={handleChange}
            onFocus={handleFocus}
          />
        </Grid>
        <Grid style={{ marginTop: '22px' }}>
          <InputField
            disabled
            fullWidth
            isForm
            label="Anexos"
            value={`${totalDocumentos} documento${maisQueUmDocumento ? 's' : ''} ${maisQueUmDocumento ? 'foram anexados' : 'foi anexado'} ao e-mail`}
          />
        </Grid>
        <Grid container style={{ marginTop: '22px' }}>
          <TextareaField
            fullWidth
            minRows={4}
            label="Corpo do E-mail*"
            name="corpoEmail"
            placeholder="Insira o Corpo do E-mail"
            value={formFields.corpoEmail}
            error={errorFields.corpoEmail?.error}
            errorMessage={errorFields.corpoEmail?.errorMessage}
            onChange={handleChange}
            onFocus={handleFocus}
          />
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '22px' }}>

          <ButtonDialog
            onClick={handleClose}
          >
            Cancelar
          </ButtonDialog>
          <ButtonDialog
            type="submit"
          >
            Confirmar
          </ButtonDialog>
        </div>
      </form>
    </DialogNewEmail>
  );
}

export const EnviarEmailDocumentoProcesso = React.forwardRef(EnviarEmailDocumentoProcessoComponent);
