import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForever from '@material-ui/icons/DeleteForever';

import PermissionIconButton from '../../UI/Button/Permission/IconButton/PermissionIconButton';
import useStyles from './RamoAtividadeActionsTable.styles';

const CONSTANTS = {
  editar: 'Editar',
  excluir: 'Excluir',
};

function RamoAtividadeActionsTable({
  id, nome, onEdit, onRemove, allows, userAllow,
}) {
  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <PermissionIconButton
        idAllow={allows.editar}
        userAllow={userAllow}
        title={CONSTANTS.editar}
        onClick={() => onEdit(id, nome)}
      >
        <EditIcon />
      </PermissionIconButton>
      <PermissionIconButton
        title={CONSTANTS.excluir}
        idAllow={allows.excluir}
        userAllow={userAllow}
        onClick={() => onRemove(id, nome)}
      >
        <DeleteForever />
      </PermissionIconButton>
    </div>
  );
}

RamoAtividadeActionsTable.propTypes = {
  id: PropTypes.number.isRequired,
  nome: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  allows: PropTypes.object.isRequired,
  userAllow: PropTypes.string.isRequired,
};

export default RamoAtividadeActionsTable;
