import styled from 'styled-components';
import { Button, makeStyles } from '@material-ui/core';
import { white } from '../../../helpers/constants/colors.constants';

export default makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  descricao: {
    display: 'flex',
    flexDirection: 'row',
  },


});

export const Container = styled.div`
  margin: 6px 0px 12px 0;
  box-Shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-Color: ${white};
  padding:22px;

  > div+div {
    display:flex ;
    flex-direction:column ;
      }

 // #resumo{
 //   display:flex ;
   // flex-direction:column ;
   // justify-content:start ;
   // width:45% ;
  //}
`;

export const ContainerResumo = styled.div`
  display: flex ;
  justify-content:center ;
  gap:8px ;
  flex-flow:column ;


    > h3 {
      display:flex ;
      justify-content:center ;
      font-size:16px ;
    }

    > div {
      display:flex ;
      flex-direction:row ;
      gap:15px ;
      justify-content:center ;
      color:${white} ;

    h3 {
      text-align:center ;
      font-size:16px ;
    }

    h4 {
      text-align:center ;
      font-size:14px ;
      font-weight:700 ;
    }
    }

    > div + div {
      display:flex ;
      flex-direction:row ;
      gap:15px ;
      justify-content:center ;
      color:${white} ;
    }
`;

export const ButtonImprimir = styled(Button)`
display: flex;
justify-content: space-between;
margin-top:30px ;

&.MuiButton-root {
  width: 73px;
  padding: 4px 0;
  font-weight: bold;
  text-transform: none;
  color: #000000;
  font-size:12px ;

  &.Mui-disabled {
    opacity: 0.64;
  }
}
`;
