import React from 'react';
import { TableRow } from '@material-ui/core';
import { TableCellCenter } from '../UI/Tabela/tableDefaultStyles';

function TableBodyConsultaDinamicaResultComponent({ list }) {
  let columnsKey = [];
  if (list?.length > 0) {
    columnsKey = Object.keys(list[0]);
  }

  return (
    list?.map(item => (
      <TableRow>
        {columnsKey.map(column => (
          <TableCellCenter height="32px" key={column}>{item[column]}</TableCellCenter>
        ))}
      </TableRow>
    ))
  );
}

export const TableBodyConsultaDinamicaResult = TableBodyConsultaDinamicaResultComponent;
