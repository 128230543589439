import styled from 'styled-components';
import { widthScreen } from '../../helpers/styles/mediaScreenConstants';
import { navigationButtonTransition } from '../../helpers/styles/sharedNavigationStyles';

export const Container = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1020;

  width: 100%;
  height: 50px;

  background: linear-gradient(180deg,#858484 0%,#A2A1A1 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NavigationInfo = styled.div`
  display: flex;
  align-items: center;

  background-color: transparent;

  ${navigationButtonTransition}

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 8px;
    background-color: transparent;
    border-radius: 50%;
    color: white;
    cursor: pointer;

    transition: left 0.2s linear;

    &:hover {
      opacity: 0.64;
    }
  }

  p {
    display: block;
    color: white;
    font-size: 16px;
    font-weight: 600;
  }

  @media screen and (min-width: ${widthScreen.small}) {
    left: 0;

    button {
      display: none;
    }

    p {
      margin-left: 16px;
    }
  }
`;


export const Actions = styled.div`
  display: flex;
`;

export const ActionsContainer = styled.div`
  display: none;

  @media screen and (min-width: ${widthScreen.small}) {
    display: block;

    button {
      color: black;
    }
  }
`;
