import React, {
  useImperativeHandle,
  useState,
  useCallback,
} from 'react';

import { Grid } from '@material-ui/core';

import { Button } from '@mui/material';
import CampoObrigatorio from '../../../components/UI/CampoObrigatorio/CampoObrigatorio';
import InputField from '../../../components/UI/Field/Input/InputField';
import CheckboxField from '../../../components/UI/Field/Checkbox/CheckboxField';

import { FormContainer, TitleFormDivider } from './salvarDocumentoProcessoStyles';

import { buscarDocumentosProcessoPorIdDocumentoService } from '../../../services/core/processos/processoDocumentoService';
import { salvarDocumentoProcessoController as controller } from './salvarDocumentoProcessoController';
import { loadingInspecaoProcessoController } from '../loadingInspecaoProcessoController';

import { getFieldName, updateFieldsWithErrors } from '../../../helpers/utils/form.utils';
import { toastUnmappedException } from '../../../helpers/utils/toastUtils';
import { InputFileIcon } from '../../../components/UI/InputFile/InputFileIcon';

function SalvarDocumentoProcessoComponent({
  idProcesso, setLoadings, onUpdateTable, updatePage,
}, ref) {
  const [show, setShow] = useState(false);
  const [idProcessoDocumento, setIdProcessoDocumento] = useState(null);
  const [formFields, setFormFields] = useState(controller.makeFormFields());

  const [errorFields, setErrorFields] = useState(controller.makeErrorFields());

  function handleClose() {
    setShow(!show);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setLoadings(loadingInspecaoProcessoController.changeLoadingDocumentos(true));
    try {
      const formData = new FormData(e.target);
      await controller.salvarDocumento(idProcesso, idProcessoDocumento, formData);
      onUpdateTable(!idProcessoDocumento);
      updatePage();
      handleClose();
    } catch (err) {
      if (err?.response?.data?.validations) {
        const [updatedFields] = updateFieldsWithErrors(err, errorFields);
        setErrorFields({ ...updatedFields });
      }
    } finally {
      setLoadings(loadingInspecaoProcessoController.changeLoadingDocumentos(false));
    }
  }

  const handleFocus = useCallback((e) => {
    const name = getFieldName(e.target);
    setErrorFields(oldValues => ({ ...oldValues, [name]: false }));
  }, []);

  const onFocusFileWhenSelecionar = () => {
    setErrorFields(oldValues => ({ ...oldValues, nomeDocumento: { error: false, errorMessage: '' } }));
  };

  const handleChange = useCallback((e) => {
    const name = getFieldName(e.target);
    const { value } = e.target;
    setFormFields(oldValue => ({ ...oldValue, [name]: value }));
  }, []);

  const handleChangeCheckbox = useCallback((e) => {
    const name = getFieldName(e.target);
    const { checked } = e.target;
    setFormFields(oldValue => ({ ...oldValue, [name]: checked }));
  }, []);

  /**
    * Externalizar ação de abrir e fechar component
    */
  useImperativeHandle(ref, () => ({
    handleOpen: async (idProcessoDocumentoParam) => {
      setShow(!show);
      setLoadings(loadingInspecaoProcessoController.changeLoadingDocumentos(true));
      let fields = controller.makeFormFields();
      try {
        if (idProcessoDocumentoParam) {
          const res = await buscarDocumentosProcessoPorIdDocumentoService(idProcessoDocumentoParam);
          fields = controller.makeFormFields(res.data);
        }
      } catch (err) {
        toastUnmappedException(err, 'Ocorreu um problema ao buscar informações específicas do documento');
        setShow(false);
      } finally {
        setLoadings(loadingInspecaoProcessoController.changeLoadingDocumentos(false));
      }
      setIdProcessoDocumento(idProcessoDocumentoParam);
      setFormFields(fields);
    },
  }));

  const isDisabledConfirmButton = !(formFields.nomeDocumento.trim() !== '' && formFields.tipo.trim() !== '');

  let routeName = ['Novo Documento do Processo'];
  if (idProcessoDocumento) {
    routeName = ['Editar Documento do Processo'];
  }

  return show && (
    <FormContainer
      open={show}
      text={routeName}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        {(errorFields.documentoProcesso.error || errorFields.tipo.error || errorFields.descricao.error) === true ? (
          <CampoObrigatorio />
        ) : (
          ''
        )}
        <Grid container>
          <InputFileIcon
            fullWidth
            isForm
            disabled={!!idProcessoDocumento}
            maxLength={200}
            label="Arquivo*"
            labelName="nomeDocumento"
            name="documentoProcesso"
            acceptAll
            value={formFields?.nomeDocumento || ''}
            error={errorFields.documentoProcesso.error}
            errorMessage={errorFields.documentoProcesso.errorMessage}
            onFocus={onFocusFileWhenSelecionar}
            onChange={handleChange}
          />
        </Grid>
        <TitleFormDivider>Informações do Documento</TitleFormDivider>
        <Grid container>
          <InputField
            fullWidth
            isForm
            label="Tipo*"
            name="tipo"
            maxLength={50}
            value={formFields.tipo || ''}
            error={errorFields.tipo.error}
            errorMessage={errorFields.tipo.errorMessage}
            onFocus={handleFocus}
            onChange={handleChange}
          />
        </Grid>
        <Grid container>
          <InputField
            fullWidth
            isForm
            label="Descrição"
            name="descricao"
            maxLength={200}
            value={formFields.descricao || ''}
            error={errorFields.descricao.error}
            errorMessage={errorFields.descricao.error}
            onFocus={handleFocus}
            onChange={handleChange}
          />
        </Grid>
        <Grid container>
          <Grid container item xs={12}>
            <CheckboxField
              name="serDocumentoOriginal"
              label="Documento original está arquivado na ABE"
              value="original"
              checked={formFields.serDocumentoOriginal}
              onChange={handleChangeCheckbox}
            />
          </Grid>
          <Grid container item xs={12}>
            <CheckboxField
              name="ocultarDocumentoCliente"
              label="Ocultar o documento do Cliente"
              value="ocultarDocumentoCliente"
              checked={formFields.ocultarDocumentoCliente}
              onChange={handleChangeCheckbox}
            />
          </Grid>
        </Grid>

        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
          <Button
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            disabled={isDisabledConfirmButton}
          >
            {idProcessoDocumento ? 'Confirmar' : 'Anexar'}
          </Button>
        </div>


      </form>
    </FormContainer>
  );
}

export const SalvarDocumentoProcesso = React.forwardRef(SalvarDocumentoProcessoComponent);
