import React from 'react';
import { FaCircle, FaFlag } from 'react-icons/fa';
import { AlertaContainer, Alertas, BoxAlertas } from '../../InspecionarProcessoStyles';
import TooltipUI from '../../../../components/UI/Tooltip/Tooltip';
import { toBrazilianDate } from '../../../../helpers/utils/utils';
import {
  getCompromissoProcessoIcon,
  getVencimentoProcessoIcon,
} from '../../../../components/UI/AlertasProcesso/AlertasProcesso';

function SinalizacaoAlertas({ sinalizacaoAlertasQuery }) {
  const getHintCompromisso = () => `${sinalizacaoAlertasQuery?.descricaoTipoAgendamento}: ${toBrazilianDate(sinalizacaoAlertasQuery?.dataAgendamento)} - ${sinalizacaoAlertasQuery?.tituloAgendamento}`;

  return (
    <BoxAlertas>
      <Alertas>
        <TooltipUI
          title={`Vencimento do processo na fase: ${toBrazilianDate(sinalizacaoAlertasQuery?.dataVencimentoFase)}`}
        >
          <AlertaContainer>
            {getVencimentoProcessoIcon[sinalizacaoAlertasQuery?.corAlertaFase]}
          </AlertaContainer>
        </TooltipUI>

        <TooltipUI title={!sinalizacaoAlertasQuery?.processoTrabalhado ? 'Processo não trabalhado' : ''}>
          <AlertaContainer>
            <FaCircle
              color={!sinalizacaoAlertasQuery?.processoTrabalhado ? '#8a2a7d' : '#e4e4e4'}
              size={8}
            />
          </AlertaContainer>
        </TooltipUI>

        <TooltipUI title={sinalizacaoAlertasQuery?.nomeRequisicao ? sinalizacaoAlertasQuery?.nomeRequisicao : ''}>
          <AlertaContainer>
            <FaFlag color={sinalizacaoAlertasQuery?.nomeRequisicao ? '#034ddc' : 'e4e4e4'} />
          </AlertaContainer>
        </TooltipUI>

        {sinalizacaoAlertasQuery?.compromisso ? (
          <TooltipUI
            title={getHintCompromisso()}
          >
            <AlertaContainer>
              {getCompromissoProcessoIcon[sinalizacaoAlertasQuery?.compromisso]}
            </AlertaContainer>
          </TooltipUI>
        ) : (
          <AlertaContainer>
            {getCompromissoProcessoIcon.DISABLED}
          </AlertaContainer>
        )}

      </Alertas>
    </BoxAlertas>
  );
}

export default React.memo(SinalizacaoAlertas);
