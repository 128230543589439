import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import { MdPrint as MdPrintIcon } from 'react-icons/md';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useDispatch } from 'react-redux';
import { SearchButton } from '../../../../components/SearchButton/SeachButton';
import DateField from '../../../../components/UI/Field/Date/DateField';
import {
  ButtonImportar,
  ButtonImprimir,
  ButtonNovo,
  Buttons,
  ContainerPrincipal, ContainerResumo, Filter, Header, TitleTable,
} from './RecebimentoClienteStyles';
import CardResumo from '../../Accordions/RelacaoPrestacao/Cards/CardResumo';
import TabelaRecebimentoClientes from './TabelaRecebimentoClientes';
import { recebimentoClientesFactory } from './RecebimentoClientesFactory';
import SwitchUI from '../../../../components/UI/Switch/SwitchUI';
import { useRecebimentoDispatch, useRecebimentoSelector } from '../../../../store/Recebimento/RecebimentoClienteProvider';
import {
  onChangeDateFieldAction, resetFormFieldsAction, setSimpleRecebimentoAction,
} from '../../../../store/Recebimento/RecebimentoCliente.store';
import { ImportarArquivo } from './ImportarArquivo';
import {
  buscarDadosTabelaAction, buscarInfosCardsAction, cancelarLiberarRecebimentoSelecionadoAction, deleteRecebimentoSelecionadoAction, liberarRecebimentoSelecionadoAction, onClickButtonImprimirAction,
} from '../../../../store/Recebimento/RecebimentoClientes.saga';
import {
  getFieldName, getMoneyMask, getNewPage, toAmericanDate, toBrazilianDate,
} from '../../../../helpers';
import LoadingUI from '../../../../components/UI/Loading/LoadingUI';
import TooltipUI from '../../../../components/UI/Tooltip/Tooltip';
import { setValuesRecebimentoPageAction } from '../../../../store/MenuAccessPage/menuAccessPageActions';
import tableUtils from '../../../../helpers/utils/table.utils';
import store from '../../../../store/createStore';
import OptionDialogNew from '../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';


function RecebimentoClienteComponent() {
  const dispatch = useRecebimentoDispatch();
  const dispatchGlobal = useDispatch();
  const history = useHistory();

  const [conteudoTabela, setConteudoTabela] = useState([]);

  const data = useRecebimentoSelector(states => states.requestFields.data);
  const lancamentosNaoLiberados = useRecebimentoSelector(states => states.requestFields.lancamentosNaoLiberados);
  const openButtonImportar = useRecebimentoSelector(states => states.openButtonImportar);
  const valoresCards = useRecebimentoSelector(states => states.valoresCards);
  const page = useRecebimentoSelector(states => states.page);
  const rowsPerPage = useRecebimentoSelector(states => states.rowsPerPage);
  const totalElements = useRecebimentoSelector(states => states.totalElements);
  const arrowDirection = useRecebimentoSelector(states => states.arrowDirection);
  const columnOrdenacao = useRecebimentoSelector(states => states.columnOrdenacao);
  const tableView = useRecebimentoSelector(states => states.tableView);
  const openDialogExcluir = useRecebimentoSelector(states => states.openDialogExcluir);
  const openDialogCancel = useRecebimentoSelector(states => states.openDialogCancel);
  const idLancamentoCliente = useRecebimentoSelector(states => states.idLancamentoCliente);
  const loadingPage = useRecebimentoSelector(states => states.common.loadingPage);
  const fields = useRecebimentoSelector(states => states.requestFields);
  const prevStates = store.getState().previousPageStates.recebimentoCliente;

  const savePreviewsPagesState = useCallback(() => {
    const pageable = tableUtils.makePageableFromTableItem(tableView);
    dispatchGlobal(setValuesRecebimentoPageAction(pageable, fields, columnOrdenacao));
  }, [tableView, columnOrdenacao, fields, dispatchGlobal]);

  function goToCadastrar() {
    savePreviewsPagesState();
    history.push({
      pathname: '/recebimento-clientes/novo',
    });
  }

  function goToEditar(idLancamentoSelecionado) {
    savePreviewsPagesState();
    history.push({
      pathname: '/recebimento-clientes/editar',
      state: {
        idLancamentoSelecionado,
      },
    });
  }

  const onChangeDateFieldHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeDateFieldAction(name, value));
  }, [dispatch]);

  const onClickButtonImprimir = useCallback(() => {
    dispatch(onClickButtonImprimirAction(toAmericanDate(data.value), lancamentosNaoLiberados.value ? 'S' : 'N'));
  }, [dispatch, data, lancamentosNaoLiberados]);

  const onClicklancamentosNaoLiberados = React.useCallback((e) => {
    const name = getFieldName(e.target);
    const { checked } = e.target;
    dispatch(setSimpleRecebimentoAction('page', 0));
    dispatch(onChangeDateFieldAction(name, checked));
    dispatch(buscarDadosTabelaAction());
  }, [dispatch]);

  const onClickButtonImportar = useCallback(() => {
    dispatch(setSimpleRecebimentoAction('openButtonImportar', !openButtonImportar));
    dispatch(resetFormFieldsAction());
  }, [dispatch, openButtonImportar]);

  const onClickPesquisar = useCallback(async () => {
    dispatch(buscarInfosCardsAction());
    dispatch(setSimpleRecebimentoAction('page', 0));
    dispatch(setSimpleRecebimentoAction('rowsPerPage', 10));
    dispatch(buscarDadosTabelaAction());
  }, [dispatch]);

  const changePageHandler = useCallback((event, newPage) => {
    dispatch(setSimpleRecebimentoAction('page', Number(newPage)));
    dispatch(buscarDadosTabelaAction());
  }, [dispatch]);

  const changeRowsPerPageHandler = useCallback((e) => {
    const size = e.target.value;
    const newPage = getNewPage(rowsPerPage, page, size);

    dispatch(setSimpleRecebimentoAction('page', Number(newPage)));
    dispatch(setSimpleRecebimentoAction('rowsPerPage', Number(size)));

    dispatch(buscarDadosTabelaAction());
  }, [dispatch, rowsPerPage, page]);

  const setColumnOrdenacao = useCallback((column) => {
    dispatch(setSimpleRecebimentoAction('columnOrdenacao', column));
  }, [dispatch]);

  const setArrowDirection = useCallback((internalReturn) => {
    dispatch(setSimpleRecebimentoAction('arrowDirection', internalReturn));
  }, [dispatch]);

  const onClickColumnOrdenar = useCallback(() => {
    if (tableView?.content?.length > 0 || tableView?.length > 0) {
      dispatch(setSimpleRecebimentoAction('page', 0));
      dispatch(buscarDadosTabelaAction());
    }
  }, [dispatch, tableView]);

  const openDialogDelete = useCallback((idClienteSelecionado) => {
    dispatch(setSimpleRecebimentoAction('idLancamentoCliente', idClienteSelecionado));
    dispatch(setSimpleRecebimentoAction('openDialogExcluir', true));
  }, [dispatch]);

  function onCloseDialog() {
    dispatch(setSimpleRecebimentoAction('idLancamentoCliente', null));
    dispatch(setSimpleRecebimentoAction('openDialogExcluir', false));
  }

  function onCloseDialogCancelar() {
    dispatch(setSimpleRecebimentoAction('idLancamentoCliente', null));
    dispatch(setSimpleRecebimentoAction('openDialogCancel', false));
  }

  const handleConfirmDelete = useCallback(() => {
    dispatch(deleteRecebimentoSelecionadoAction(idLancamentoCliente));
  }, [dispatch, idLancamentoCliente]);


  const handleOpenCancelLancamento = useCallback((idClienteSelecionado) => {
    dispatch(setSimpleRecebimentoAction('idLancamentoCliente', idClienteSelecionado));
    dispatch(setSimpleRecebimentoAction('openDialogCancel', true));
  }, [dispatch]);

  const handleCancelarPrestacao = useCallback(() => {
    dispatch(cancelarLiberarRecebimentoSelecionadoAction(idLancamentoCliente));
  }, [dispatch, idLancamentoCliente]);

  const handleLiberarLancamento = useCallback((idClienteSelecionado) => {
    dispatch(setSimpleRecebimentoAction('idLancamentoCliente', idClienteSelecionado));
    dispatch(liberarRecebimentoSelecionadoAction(idClienteSelecionado));
  }, [dispatch]);


  useEffect(() => {
    setConteudoTabela(recebimentoClientesFactory.makeConteudoTabela(
      tableView.content,
      openDialogDelete,
      handleOpenCancelLancamento,
      handleCancelarPrestacao,
      handleLiberarLancamento,
      goToEditar,
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableView, openDialogDelete, handleOpenCancelLancamento, handleCancelarPrestacao, handleLiberarLancamento]);


  useEffect(() => {
    if (prevStates.pageable !== null) {
      dispatch(setSimpleRecebimentoAction('page', Number(prevStates?.pageable?.page) || 0));
      dispatch(setSimpleRecebimentoAction('rowsPerPage', Number(prevStates?.pageable?.size) || 10));
      dispatch(setSimpleRecebimentoAction('totalElements', Number(prevStates?.pageable?.totalElements) || 0));
    }
    if (prevStates.columnOrder !== null) {
      dispatch(setSimpleRecebimentoAction('columnOrdenacao', prevStates?.columnOrder || { element: 'dataCadastramento', order: 'ASC' }));
    }
    if (prevStates.filters !== null) {
      dispatch(onChangeDateFieldAction('data', prevStates?.filters?.data.value || toBrazilianDate(new Date())));
      dispatch(onChangeDateFieldAction('lancamentosNaoLiberados', prevStates?.filters?.lancamentosNaoLiberados.value));
      dispatch(buscarDadosTabelaAction());
      dispatch(buscarInfosCardsAction());
    }
  }, [dispatch, prevStates]);

  return (
    <>
      <LoadingUI show={loadingPage} />
      <OptionDialogNew
        open={openDialogExcluir}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={onCloseDialog}
        onClickConfirm={handleConfirmDelete}
        text="Deseja excluir o lançamento selecionado?"
      />
      <OptionDialogNew
        open={openDialogCancel}
        variant="warning"
        confirmLabel="Confirmar"
        cancelLabel="Não"
        onClickCancel={onCloseDialogCancelar}
        onClickConfirm={handleCancelarPrestacao}
        text="Deseja realmente cancelar a liberação deste lançamento?"
      />
      <ContainerPrincipal>
        <Header>
          <Filter>
            <p>Lançamentos a partir de:</p>
            <div style={{ width: '160px' }}>
              <DateField
                name={data.name}
                value={data.value}
                maxDate={new Date()}
                style={{ marginLeft: '3px' }}
                onChange={onChangeDateFieldHandler}
              />
            </div>
            <div style={{ marginLeft: 15 }}>
              <SearchButton onClick={() => onClickPesquisar()}>
                <SearchIcon style={{ marginLeft: 2 }} />
              </SearchButton>
            </div>
          </Filter>
          <div id="resumo">
            <ContainerResumo>
              <h3><b>Resumo no período</b></h3>
              <div>
                <CardResumo
                  title="Valor Total de Recebimentos"
                  isDetail={false}
                  bgColor="#4aced6"
                  value={getMoneyMask(valoresCards.valorTotalRecebimentosDia)}
                />
                <CardResumo
                  title="Lançamentos não Liberados"
                  value={valoresCards.lancamentosNaoLiberadosDia}
                  isDetail={false}
                  bgColor="#3373ec"
                />
              </div>
            </ContainerResumo>
          </div>
        </Header>
        <div>
          <ButtonImprimir
            disabled={tableView?.content?.length <= 0 || tableView?.length <= 0}
            onClick={onClickButtonImprimir}
          >
            <MdPrintIcon size={16} />
            <span>Imprimir</span>
          </ButtonImprimir>
        </div>
        <Buttons>
          <ButtonImportar
            onClick={onClickButtonImportar}
            active={openButtonImportar.toString()}
          >
            <TooltipUI title="Importar Lançamentos" placement="bottom-start">
              <ImportExportIcon fontSize="small" />
            </TooltipUI>
          </ButtonImportar>
          <ButtonNovo onClick={goToCadastrar}>
            Novo
          </ButtonNovo>
        </Buttons>
        {openButtonImportar === true && (
          <ImportarArquivo />
        )}
        <TitleTable>
          Resultado da Pesquisa
        </TitleTable>
        <TabelaRecebimentoClientes
          columns={recebimentoClientesFactory.makeCabecalhoTabela(
            columnOrdenacao, setColumnOrdenacao, arrowDirection, setArrowDirection, onClickColumnOrdenar,
          )}
          rows={conteudoTabela}
          page={page}
          size={totalElements}
          rowsPerPageOptions={[10, 15, 20]}
          rowsPerPage={rowsPerPage}
          onChangePage={changePageHandler}
          onChangeRowsPerPage={changeRowsPerPageHandler}
        />
        <div>
          <SwitchUI
            label="Exibir somente lançamentos não liberados "
            name="lancamentosNaoLiberados"
            checked={lancamentosNaoLiberados.value}
            onChange={onClicklancamentosNaoLiberados}
          />
        </div>
      </ContainerPrincipal>
    </>
  );
}

export const RecebimentoCliente = withRouter(RecebimentoClienteComponent);
