import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SelectorField } from '../../../../../../../components';
import Grid24 from '../../../../../../../components/UI/Grid24';
import DateField from '../../../../../../../components/UI/Field/Date/DateField';
import MoneyField from '../../../../../../../components/UI/Field/Money/MoneyField';
import { getRequiredLabel, useUtilStyles } from '../../../../../../../helpers';
import CheckboxField from '../../../../../../../components/UI/Field/Checkbox/CheckboxField';
import AddActionTitleWrapper from '../../../../../../../components/UI/Wrapper/AddActionTitle';
import { dialogNovaAlterarParcelaController } from '../DialogNovaAlterarParcelaController';
import { findBancosAction } from '../../../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import ChequeCampos from './ChequeCampos';

function DetalheCheque({
  errorFields,
  getFormasPagamento,
  formFields,
  parcelas,
  onChangeHandler,
  onFocusHandler,
  getMinDate,
  getMaxDate,
  clickedParcelaOnEdit,
  onCheckedHandler,
  chequesDetalhes,
  setChequesDetalhes,
  originalChequesDetalhes,
}) {
  const utilStyles = useUtilStyles();

  const globalDispatch = useDispatch();

  const [listaValoresCheques] = useState([]);

  const addChequeDetalheHandler = useCallback(() => {
    const modifiedCheques = [...chequesDetalhes];
    const cheque = dialogNovaAlterarParcelaController.makeChequeDetalhe(formFields);
    modifiedCheques.push(cheque);
    setChequesDetalhes(modifiedCheques);
    listaValoresCheques.push(parseFloat(0));
  }, [setChequesDetalhes, chequesDetalhes, formFields, listaValoresCheques]);


  const generateDividerLine = index => ((index !== chequesDetalhes.length - 1)
    && (<hr className={utilStyles.mt7} />)
  );

  useEffect(() => {
    globalDispatch(findBancosAction());
    if (chequesDetalhes.length > 0 && listaValoresCheques.length === 0) {
      chequesDetalhes.forEach((obj) => {
        if (obj.valor.value !== undefined) {
          listaValoresCheques.push(parseFloat(obj.valor.value).toFixed(2));
        }
      });
    }
  }, [globalDispatch, listaValoresCheques, chequesDetalhes]);


  const buildChequeDetalheComponent = (chequeDetalhe, index) => (
    <div key={index}>
      <ChequeCampos
        chequeDetalhe={chequeDetalhe}
        chequesDetalhes={chequesDetalhes}
        setChequesDetalhes={setChequesDetalhes}
        formFields={formFields}
        onFocusHandler={onFocusHandler}
        index={index}
        originalChequesDetalhes={originalChequesDetalhes}
      />
      {generateDividerLine(index)}
    </div>
  );

  return (
    <>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={5}>
          <SelectorField
            isForm
            fullWidth
            label={getRequiredLabel('Forma de Pagamento', true)}
            items={getFormasPagamento()}
            name="formaPagamento"
            value={formFields?.formaPagamento}
            disabled={parcelas.length > 1 && formFields.nParcela !== 1}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
            error={errorFields?.formaPagamento?.error}
            errorMessage={errorFields?.formaPagamento?.errorMessage}
          />
        </Grid24>
        <Grid24 xs={24} sm={5}>
          <DateField
            isForm
            fullWidth
            label={getRequiredLabel('Vencimento', true)}
            name="dataVencimento"
            minDate={getMinDate()}
            maxDate={getMaxDate()}
            value={formFields?.dataVencimento}
            error={errorFields?.dataVencimento?.error}
            errorMessage={errorFields?.dataVencimento?.errorMessage}
            onFocus={onFocusHandler}
            onChange={onChangeHandler}
            disabled={clickedParcelaOnEdit.nParcela === 1}
          />
        </Grid24>
        <Grid24 xs={24} sm={5}>
          <MoneyField
            isForm
            fullWidth
            label={getRequiredLabel('Valor', true)}
            name="valor"
            disabled="true"
            value={formFields?.valor}
            error={errorFields?.valor?.error}
            errorMessage={errorFields?.valor?.errorMessage}
            onFocus={onFocusHandler}
            onChange={onChangeHandler}
          />
        </Grid24>
        {clickedParcelaOnEdit.nParcela === parcelas.length ? (
          <Grid24 xs={24} sm={24} style={{ marginTop: '24px', marginLeft: '10px', marginBottom: '24px' }}>
            <CheckboxField
              label="Redistribuir os valores para as parcelas seguintes"
              name="redistribuirValores"
              checked={false}
              disabled
            />
          </Grid24>
        ) : (
          <Grid24 xs={24} sm={24} style={{ marginTop: '24px', marginLeft: '10px', marginBottom: '24px' }}>
            <CheckboxField
              label="Redistribuir os valores para as parcelas seguintes"
              name="redistribuirValores"
              checked={formFields.redistribuirValores}
              onChange={onCheckedHandler}
            />
          </Grid24>
        )}
        <div style={{ marginLeft: '5px' }}>
          <AddActionTitleWrapper
            title="Cadastrar Cheques"
            addHandler={addChequeDetalheHandler}
          >
            <div style={{ marginTop: '15px', width: '100%' }}>
              {chequesDetalhes.map(buildChequeDetalheComponent)}
            </div>
          </AddActionTitleWrapper>
        </div>
      </Grid24>
    </>
  );
}

export default DetalheCheque;
