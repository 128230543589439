import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';

export default makeStyles({
  footerButtons: {
    marginRight: '12px',
    width: '150px',
    height: '35px',
  },
  totalParcelasText: {
    fontWeight: '700',
    fontSize: '14px',
    color: '#034DDC',
  },
  valorAcordadoItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '12px',
  },
  dialogNovaParcela: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '813px',
      maxHeight: '430px',
    },
  },
  dialogForm: {
    marginBottom: '-15px',
  },

  dialogErroAoGerarAcordo: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '577px',
      maxHeigh: '252px',
    },
  },

  textErroAoGerarAcordo: {
    display: 'grid',
    width: '100%',
    marginBottom: '22px',
  },

  buttonDialogErroAoGerarAcordoOk: {
    width: '165px',
    alignSelf: 'center',
  },

  boxPermissaoProposta: {
    border: '1px solid #A2A0A0',
    marginTop: '16px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '16px',
    paddingBottom: '26px',
  },

  boxJustificativa: {
    display: 'flex',
    width: '100%',
  },

});

export const Passo3Body = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const Passo3Footer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: -40px;
`;

export const Passo3FooterActions = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Passo3FooterTotalValue = styled.div`
 display: flex;
 justify-content: flex-end;
`;

export const HeaderDialogErroAoGerarAcordo = styled.div`
  display:flex ;
  width:100% ;
  flex-direction:column;
`;
