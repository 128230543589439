import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';

import { toast } from 'react-toastify';
import {
  downloadFile, getDate60daysAgo, openPageFileBase64, openPagePDF, toastUnmappedException,
} from '../../helpers';
import RecebimentoDevedoresService from './RecebimentoDevedoresService';
import {
  setCommonAction, setErroEmCampoAction, setSimpleRecebimentoAction,
} from './RecebimentoDevedores.store';
import {
  BUSCAR_INFO_CARDS,
  IMPORTAR_COMPROVANTE_ACTION,
  SET_BUSCAR_DADOS_TABELA,
  SET_BUSCAR_DADOS_TABELA_FILTRO,
  SET_IMPRIMIR_PARCELAS,
  SET_BUSCAR_DADOS_TABELA_FILTRO_SIMPLES,
  CLICK_COMPROVANTE_ACTION,
  SET_EXPORTAR,
  SUBMIT_DISTRIBUICAO_MANUAL_ACTION,
  BUSCAR_ULTIMA_DATA_HORA_ATUALIZACAO_ACTION,
  SET_ATUALIZAR_PARCELA,
  ATUALIZAR_PARCELAS_ACTION,
  ATUALIZAR_PARCELAS_LOTE_ACTION,
} from './RecebimentoDevedores.constants';

export const buscarInfosCardsAction = () => ({
  type: BUSCAR_INFO_CARDS,
});

export const buscarDadosTabelaAction = () => ({
  type: SET_BUSCAR_DADOS_TABELA,
});

export const buscarDadosTabelaFiltroAction = usarCache => ({
  type: SET_BUSCAR_DADOS_TABELA_FILTRO,
  usarCache,
});

export const buscarDadosTabelaFiltroSimplesAction = usarCache => ({
  type: SET_BUSCAR_DADOS_TABELA_FILTRO_SIMPLES,
  usarCache,
});

export const atualizarParcelaAction = (tipoPesquisa, idAcordoParcela) => ({
  type: SET_ATUALIZAR_PARCELA,
  tipoPesquisa,
  idAcordoParcela,
});

export const imprimirParcelasAction = tipoPesquisa => ({
  type: SET_IMPRIMIR_PARCELAS,
  tipoPesquisa,
});

export const exportarAction = tipoPesquisa => ({
  type: SET_EXPORTAR,
  tipoPesquisa,
});

export const importarComprovanteAction = (quitarIntegralmenteParcela, comprovanteRecebimento, setOpenRecebimentoManual, openPesquisaAvancada, resetCampos) => ({
  type: IMPORTAR_COMPROVANTE_ACTION,
  quitarIntegralmenteParcela,
  comprovanteRecebimento,
  setOpenRecebimentoManual,
  openPesquisaAvancada,
  resetCampos,
});

export const clickComprovanteAction = (numeroAcordo, numeroProcesso, numeroParcela) => ({
  type: CLICK_COMPROVANTE_ACTION,
  numeroAcordo,
  numeroProcesso,
  numeroParcela,
});

export const submitDistribuicaoManualAction = setOpenDialogDistribuicaoManual => ({
  type: SUBMIT_DISTRIBUICAO_MANUAL_ACTION,
  setOpenDialogDistribuicaoManual,
});

export const buscarUltimaDataHoraAtualizacaoAction = () => ({
  type: BUSCAR_ULTIMA_DATA_HORA_ATUALIZACAO_ACTION,
});

export const atualizarParcelasAction = (idAcordoParcelas, openPesquisaAvancada) => ({
  type: ATUALIZAR_PARCELAS_ACTION,
  idAcordoParcelas,
  openPesquisaAvancada,
});

export const atualizarParcelasLoteAction = (idAcordoParcelas, openPesquisaAvancada) => ({
  type: ATUALIZAR_PARCELAS_LOTE_ACTION,
  idAcordoParcelas,
  openPesquisaAvancada,
});

function* sucessInfoCards(data) {
  yield put(setSimpleRecebimentoAction('recebimentosNoDia', data.recebimentosNoDia));
  yield put(setSimpleRecebimentoAction('valoresRecebidosNoDia', data.valoresRecebidosNoDia));
  yield put(setSimpleRecebimentoAction('parcelasAguardandoPagamento', data.parcelasAguardandoPagamento));
  yield put(setSimpleRecebimentoAction('parcelasAtrasadas', data.parcelasAtrasadas));
}


function* successPesquisarRecebimento(data) {
  yield put(setSimpleRecebimentoAction('tableView', data.data.recebimentos));
  yield put(setSimpleRecebimentoAction('totalElements', data.data.recebimentos.totalElements));
  yield put(setSimpleRecebimentoAction('rowsPerPage', data.data.recebimentos.pageable.pageSize));
  yield put(setSimpleRecebimentoAction('page', data.data.recebimentos.pageable.pageNumber));
  yield put(setSimpleRecebimentoAction('idAcordoParcelas', data.data.idAcordoParcelas));
}

function* emptyPesquisaRecebimento() {
  yield put(setSimpleRecebimentoAction('tableView', []));
  yield put(setSimpleRecebimentoAction('totalElements', 0));
}

function* buscarInfoCardsHandler() {
  yield put(setCommonAction('loadingPage', true));
  try {
    const response = yield call(RecebimentoDevedoresService.getInfoCards);
    yield sucessInfoCards(response.data);
  } catch (e) {
    toastUnmappedException('Erro na obtenção de dados');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* buscarDadosTabelaHandler() {
  yield put(setCommonAction('loadingPage', true));
  const formFields = yield select(state => state.formFields);
  try {
    const response = yield call(RecebimentoDevedoresService.getDefaultDadosTabela);
    yield successPesquisarRecebimento(response);
  } catch (e) {
    yield emptyPesquisaRecebimento();
  } finally {
    yield put(setSimpleRecebimentoAction('cacheFormFields', formFields));
    yield put(setCommonAction('loadingPage', false));
  }
}

function makePayloadRecebimentoDevedor(formFields, cacheFormFields, usarCache) {
  if (usarCache) {
    return {
      dataPagamento: cacheFormFields?.dataPagamento,
      dataVencimento: cacheFormFields?.dataVencimento,
      idTipoFormaPagamento: cacheFormFields?.idTipoFormaPagamento,
      nomeCliente: cacheFormFields?.nomeCliente,
      nomeDevedor: cacheFormFields?.nomeDevedor,
      somenteParcelasAguardandoPagamento: formFields?.somenteParcelasAguardandoPagamento,
      somenteParcelasrecebidasNaoDistribuidas: formFields?.somenteParcelasrecebidasNaoDistribuidas,
      valorDistribuido: cacheFormFields?.valorDistribuido,
    };
  }
  return {
    dataPagamento: formFields?.dataPagamento,
    dataVencimento: formFields?.dataVencimento,
    idTipoFormaPagamento: formFields?.idTipoFormaPagamento,
    nomeCliente: formFields?.nomeCliente,
    nomeDevedor: formFields?.nomeDevedor,
    somenteParcelasAguardandoPagamento: formFields?.somenteParcelasAguardandoPagamento,
    somenteParcelasrecebidasNaoDistribuidas: formFields?.somenteParcelasrecebidasNaoDistribuidas,
    valorDistribuido: formFields?.valorDistribuido,
  };
}

function* buscarDadosTabelaFiltroAvancadoHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { usarCache } = actions;
  const formFields = yield select(state => state.formFields);
  const cacheFormFields = yield select(state => state.cacheFormFields);
  const page = yield select(states => states.page);
  const rowsPerPage = yield select(states => states.rowsPerPage);
  const columnOrdenacao = yield select(states => states.columnOrdenacao);
  const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];
  try {
    const payload = yield makePayloadRecebimentoDevedor(formFields, cacheFormFields, usarCache);
    const response = yield call(RecebimentoDevedoresService.getDadosTabelaAvançada, payload, { page, size: rowsPerPage }, order);
    yield successPesquisarRecebimento(response);
  } catch (e) {
    if (e.response) {
      yield emptyPesquisaRecebimento();
    }
  } finally {
    if (!usarCache) {
      yield put(setSimpleRecebimentoAction('cacheFormFields', formFields));
    }
    yield put(setCommonAction('loadingPage', false));
  }
}

function* buscarDadosTabelaFiltroSimplesHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { usarCache } = actions;
  const formFields = yield select(state => state.formFields);
  const cacheFormFields = yield select(state => state.cacheFormFields);
  const page = yield select(states => states.page);
  const rowsPerPage = yield select(states => states.rowsPerPage);
  const columnOrdenacao = yield select(states => states.columnOrdenacao);
  const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];
  try {
    const payload = yield makePayloadRecebimentoDevedor(formFields, cacheFormFields, usarCache);
    const response = yield call(RecebimentoDevedoresService.getDadosTabelaSimples, payload, { page, size: rowsPerPage }, order);
    yield successPesquisarRecebimento(response);
  } catch (e) {
    if (e.response) {
      yield emptyPesquisaRecebimento();
    }
  } finally {
    if (!usarCache) {
      yield put(setSimpleRecebimentoAction('cacheFormFields', formFields));
    }
    yield put(setCommonAction('loadingPage', false));
  }
}

function* getAtualizarParcela(actions) {
  const {
    idAcordoParcela, tipoPesquisa,
  } = actions;
  const dataVencimento = yield select(states => states.formFields.dataVencimento);
  const formFields = yield select(state => state.formFields);
  try {
    yield call(RecebimentoDevedoresService.getAtualizarParcelas, {
      ...formFields,
    }, tipoPesquisa, idAcordoParcela);
  } catch (e) {
    if (e.response) {
      yield emptyPesquisaRecebimento();
    }
  } finally {
    if (tipoPesquisa === 'avancada') {
      yield buscarDadosTabelaFiltroAvancadoHandler({ usarCache: true });
    } else if (dataVencimento.value !== getDate60daysAgo(new Date())) {
      yield buscarDadosTabelaFiltroSimplesHandler({ usarCache: true });
    } else {
      yield buscarDadosTabelaHandler();
    }
    yield buscarInfoCardsHandler();
  }
}

function* imprimirParcelasHandler(actions) {
  const { tipoPesquisa } = actions;
  yield put(setCommonAction('loadingPage', true));
  const formFields = yield select(state => state.formFields);
  try {
    const columnOrdenacao = yield select(states => states.columnOrdenacao);
    const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];
    const response = yield call(RecebimentoDevedoresService.imprimirParcelas, formFields, tipoPesquisa, order);
    if (response.data.size > 0) {
      openPagePDF(response.data);
    }
  } catch (e) {
    if (e.response) {
      yield emptyPesquisaRecebimento();
    }
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* exportarHandler(actions) {
  const { tipoPesquisa } = actions;
  yield put(setCommonAction('loadingPage', true));
  const formFields = yield select(state => state.formFields);
  try {
    const columnOrdenacao = yield select(states => states.columnOrdenacao);
    const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];
    const response = yield call(RecebimentoDevedoresService.exportar, formFields, tipoPesquisa, order);
    if (response.data.size > 0) {
      downloadFile(response.headers.filename, response.data);
    }
  } catch (e) {
    if (e.response) {
      yield emptyPesquisaRecebimento();
    }
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* importarComprovanteHandler(actions) {
  const dataPagamento = yield select(states => states.recebimentoManual.dataPagamento);
  const nomeComprovante = yield select(states => states.recebimentoManual.comprovante);
  const valorPago = yield select(states => states.recebimentoManual.valorPago);
  const idAcordo = yield select(states => states.idAcordo);
  const nroParcela = yield select(states => states.nroParcela);
  const numeroProcesso = yield select(states => states.idProcesso);
  const numeroAcordo = yield select(states => states.numeroAcordo);
  const tipoFormaPagto = yield select(states => states.tipoFormaPagto);
  const valorParcela = yield select(states => states.valorParcela);
  const idAcordoParcela = yield select(states => states.idAcordoParcela);
  const dataVencimento = yield select(states => states.formFields.dataVencimento);

  const {
    quitarIntegralmenteParcela,
    comprovanteRecebimento,
    setOpenRecebimentoManual,
    openPesquisaAvancada,
    resetCampos,
  } = actions;

  yield put(setCommonAction('loadingPage', true));
  try {
    if (valorPago.value === '0.00') {
      yield put(setErroEmCampoAction('valorPago', 'Valor pago deve ser maior que 0.00'));
    } else {
      yield call(RecebimentoDevedoresService.inserirRecebimentoManual, quitarIntegralmenteParcela, comprovanteRecebimento, nomeComprovante,
        dataPagamento, valorPago, idAcordo, nroParcela, numeroProcesso, numeroAcordo, tipoFormaPagto, valorParcela, idAcordoParcela);
      toast.success('Recebimento manual inserido com sucesso!');
    }
  } catch (e) {
    toast.error(e.response.data.message, { style: { width: '392px' } });
  } finally {
    setOpenRecebimentoManual(false);
    if (openPesquisaAvancada === true) {
      yield buscarDadosTabelaFiltroAvancadoHandler({ usarCache: true });
    } else if (dataVencimento.value !== getDate60daysAgo(new Date())) {
      yield buscarDadosTabelaFiltroSimplesHandler({ usarCache: true });
    } else {
      yield buscarDadosTabelaHandler();
    }
    yield buscarInfoCardsHandler();
    resetCampos();
    yield put(setCommonAction('loadingPage', false));
  }
}

function* clickComprovanteHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { numeroAcordo, numeroProcesso, numeroParcela } = actions;
  try {
    const response = yield call(RecebimentoDevedoresService.clickComprovanteHandler, numeroAcordo, numeroProcesso, numeroParcela);
    openPageFileBase64(response.data, response.headers['content-type']);
  } catch (e) {
    if (e.response) {
      yield emptyPesquisaRecebimento();
    }
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* submitDistribuicaoManualHandler(actions) {
  yield put(setCommonAction('loadingPage', true));

  const { setOpenDialogDistribuicaoManual } = actions;

  const valorRepasse = yield select(states => states.recebimentoDistribuicao.valorRepasse.value);
  const idAcordoParcela = yield select(states => states.recebimentoDistribuicao.idAcordoParcelaDistribuicao);
  const valorTotalRecebimento = yield select(states => states.recebimentoDistribuicao.valorTotalRecebimentoDistribuicao);
  const dataPagamento = yield select(states => states.recebimentoDistribuicao.dataPagamento);

  try {
    yield call(RecebimentoDevedoresService.submitDistribuicaoManual, parseFloat(valorRepasse), parseFloat(valorTotalRecebimento), idAcordoParcela, dataPagamento);
    toast.success('Distribuição Manual realizada com sucesso!');
    setOpenDialogDistribuicaoManual(false);
    yield put(setSimpleRecebimentoAction('page', 0));
    yield put(setSimpleRecebimentoAction('rowsPerPage', 10));
    yield put(buscarDadosTabelaFiltroSimplesAction(false));
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.message);
    }
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* buscarUltimaDataHoraAtualizacaoHandler() {
  yield put(setCommonAction('loadingPage', true));

  try {
    const response = yield call(RecebimentoDevedoresService.buscarUltimaDataHoraAtualizacao);
    yield put(setSimpleRecebimentoAction('selectedDate', response.data));
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.message);
    }
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* atualizarParcela(idAcordoParcela, tipoPesquisa) {
  const formFields = yield select(state => state.formFields);
  try {
    yield call(RecebimentoDevedoresService.getAtualizarParcelas, {
      ...formFields,
    }, tipoPesquisa, idAcordoParcela);
  } catch (e) {
    if (e.response) {
      yield emptyPesquisaRecebimento();
    }
  }
}

function* atualizarParcelasHandler(actions) {
  const { idAcordoParcelas, openPesquisaAvancada } = actions;
  yield put(setCommonAction('loadingPage', true));
  const dataVencimento = yield select(states => states.formFields.dataVencimento);
  try {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < idAcordoParcelas.length; i++) {
      const parcela = idAcordoParcelas[i];
      yield atualizarParcela(parcela.idAcordoParcela, openPesquisaAvancada ? 'avancada' : 'simples');
    }
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.message);
    }
  } finally {
    yield put(setCommonAction('loadingPage', false));
    yield buscarUltimaDataHoraAtualizacaoHandler();
    if (openPesquisaAvancada) {
      yield buscarDadosTabelaFiltroAvancadoHandler({ usarCache: true });
    } else if (dataVencimento.value !== getDate60daysAgo(new Date())) {
      yield buscarDadosTabelaFiltroSimplesHandler({ usarCache: true });
    } else {
      yield buscarDadosTabelaHandler();
    }
    yield buscarInfoCardsHandler();
  }
}

function* atualizarParcelaLote(idAcordoParcelasLote, tipoPesquisa) {
  const formFields = yield select(state => state.formFields);
  try {
    yield call(RecebimentoDevedoresService.getAtualizarParcelasLote, {
      ...formFields,
    }, tipoPesquisa, idAcordoParcelasLote);
  } catch (e) {
    if (e.response) {
      yield emptyPesquisaRecebimento();
    }
  }
}

function* atualizarParcelasLoteHandler(actions) {
  const { idAcordoParcelas, openPesquisaAvancada } = actions;
  yield put(setCommonAction('loadingPage', true));
  const dataVencimento = yield select(states => states.formFields.dataVencimento);
  try {
    while (idAcordoParcelas.length > 0) {
      const idAcordoParcelasLote = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 5; i++) {
        const parcela = idAcordoParcelas.pop();
        idAcordoParcelasLote.push(parcela.idAcordoParcela);
      }
      yield atualizarParcelaLote(idAcordoParcelasLote, openPesquisaAvancada ? 'avancada' : 'simples');
    }
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.message);
    }
  } finally {
    yield put(setCommonAction('loadingPage', false));
    yield buscarUltimaDataHoraAtualizacaoHandler();
    if (openPesquisaAvancada) {
      yield buscarDadosTabelaFiltroAvancadoHandler({ usarCache: true });
    } else if (dataVencimento.value !== getDate60daysAgo(new Date())) {
      yield buscarDadosTabelaFiltroSimplesHandler({ usarCache: true });
    } else {
      yield buscarDadosTabelaHandler();
    }
    yield buscarInfoCardsHandler();
  }
}

export default function* watchRecebimentoDevedores() {
  yield takeLatest(BUSCAR_INFO_CARDS, buscarInfoCardsHandler);
  yield takeLatest(SET_BUSCAR_DADOS_TABELA, buscarDadosTabelaHandler);
  yield takeLatest(SET_BUSCAR_DADOS_TABELA_FILTRO, buscarDadosTabelaFiltroAvancadoHandler);
  yield takeLatest(SET_BUSCAR_DADOS_TABELA_FILTRO_SIMPLES, buscarDadosTabelaFiltroSimplesHandler);
  yield takeLatest(SET_ATUALIZAR_PARCELA, getAtualizarParcela);
  yield takeLatest(SET_IMPRIMIR_PARCELAS, imprimirParcelasHandler);
  yield takeLatest(SET_EXPORTAR, exportarHandler);
  yield takeLatest(IMPORTAR_COMPROVANTE_ACTION, importarComprovanteHandler);
  yield takeLatest(CLICK_COMPROVANTE_ACTION, clickComprovanteHandler);
  yield takeLatest(SUBMIT_DISTRIBUICAO_MANUAL_ACTION, submitDistribuicaoManualHandler);
  yield takeLatest(BUSCAR_ULTIMA_DATA_HORA_ATUALIZACAO_ACTION, buscarUltimaDataHoraAtualizacaoHandler);
  yield takeLatest(ATUALIZAR_PARCELAS_ACTION, atualizarParcelasHandler);
  yield takeLatest(ATUALIZAR_PARCELAS_LOTE_ACTION, atualizarParcelasLoteHandler);
}
