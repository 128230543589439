import React from 'react';
import TableHeader from '../../../../models/TableHeader';
import OrdenacaoColuna from '../../../UI/Tabela/Ordenacao/OrdenacaoColuna';
import FieldModel from '../../../../models/FieldModel';
import { getDate7daysAgo, getDateNow } from '../../../../helpers/utils/utils';


export function buildInitialStatesEnviarProcesso() {
  return {
    idFase: new FieldModel({ name: 'idFase', value: '' }),
    idFasePasta: new FieldModel({ name: 'idFasePasta', value: '' }),
    idMotivoBaixa: new FieldModel({ name: 'idMotivoBaixa', value: '' }),
    texto: new FieldModel({ name: 'texto', value: '' }),
  };
}

export function buildInitialStatesPesquisaAvancada() {
  return {
    nomeDevedor: new FieldModel({ name: 'nomeDevedor', value: '' }),
    nroProcesso: new FieldModel({ name: 'nroProcesso', value: '' }),
    tipoAntecedente: new FieldModel({ name: 'tipoAntecedente', value: '' }),
    valorProcessoInicial: new FieldModel({ name: 'valorProcessoInicial', value: '' }),
    valorProcessoFinal: new FieldModel({ name: 'valorProcessoFinal', value: '' }),
  };
}

export function buildInitialStatesForm() {
  const avancada = buildInitialStatesPesquisaAvancada();
  return {
    ...avancada,
    periodoAcordoInicial: new FieldModel({ name: 'periodoAcordoInicial', value: getDate7daysAgo() }),
    periodoAcordoFinal: new FieldModel({ name: 'periodoAcordoFinal', value: getDateNow() }),
  };
}

export function buildTableColumns(arrowOrdenacao, columnOrdenacao, setArrowOrdenacao, setColumnOrdenacao, onClickOrdernacaoColumn, buildCheckboxSelectAll) {
  return ([
    new TableHeader({
      id: 'selecionarTodos',
      label: buildCheckboxSelectAll(),
      width: 43,
    }),
    new TableHeader({
      id: 'nroProcesso',
      label: <OrdenacaoColuna
        columnLabel="Número"
        idColumn="nroProcesso"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('nroProcesso')}
      />,
      alignCell: 'center',
      width: 104,
    }),
    new TableHeader({
      id: 'proporcaoFase',
      label: <OrdenacaoColuna
        isIconLabel
        columnLabel="fas fa-hourglass-half"
        idColumn="proporcaoFase"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('proporcaoFase')}
      />,
      alignCell: 'center',
      width: 53,
    }),
    new TableHeader({
      id: 'tipoAntecedente',
      label: <OrdenacaoColuna
        isIconLabel
        columnLabel="fas fa-archive"
        idColumn="tipoAntecedente"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('tipoAntecedente')}
      />,
      alignCell: 'center',
      width: 53,
    }),
    new TableHeader({
      id: 'dataAcordo',
      label: <OrdenacaoColuna
        columnLabel="Data de Abertura"
        idColumn="dataAcordo"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('dataAcordo')}
      />,
      alignCell: 'center',
      width: 153,
    }),
    new TableHeader({
      id: 'nomeDevedor',
      label: <OrdenacaoColuna
        columnLabel="Nome do Devedor"
        idColumn="nomeDevedor"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('nomeDevedor')}
      />,
      alignCell: 'left',
      showTip: true,
      maxWidth: 259,
      width: 259,
    }),
    new TableHeader({
      id: 'estagio',
      label: <OrdenacaoColuna
        columnLabel="Estágio do Processo"
        idColumn="estagio"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('estagio')}
      />,
      alignCell: 'center',
      width: 156,
    }),
    new TableHeader({
      id: 'valorAcordo',
      label: <OrdenacaoColuna
        columnLabel="Valor do Processo"
        idColumn="valorAcordo"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
        onClickColumn={() => onClickOrdernacaoColumn('valorAcordo')}
      />,
      alignCell: 'flex-end',
      width: 157,
    }),
    new TableHeader({
      id: 'acoes',
      width: 43,
    }),
  ]

  );
}
