import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectorField from '../../../../../UI/Field/Selector';
import Grid24 from '../../../../../UI/Grid24';
import FormWrapperWithTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import {
  AGENCIA_LABEL, BANCO_LABEL, CHAVE_PIX, DETALHES_LIQUIDACAO, FORMA_PADRAO_PRESTACAO, NUMERO_CONTA_LABEL, DAC, TIPO_CHAVE_PIX_LABEL,
} from '../PrestacaoContas.constants';
import { getRequiredLabel, useUtilStyles } from '../../../../../../helpers';
import InputField from '../../../../../UI/Field/Input';
import WrapperSubTitleForm from '../../../../../UI/WrapperSubTitleForm';
import { findAllTiposChavePixAction, findAllTiposLiquidacaoPrestacaoAction, findBancosAction } from '../../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import { useCrudClienteDispatch, useCrudClienteSelector } from '../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import MaskField from '../../../../../UI/Field/Mask';
import { CNPJ_MASK, CPF_MASK, PHONE_MASK } from '../../../../../../helpers/constants/layout.constants';
import { onFocusControls } from '../../../../../../store/Cliente/V2/Crud/crudCliente.store';

function InformacoesTransferencia({ isEditMode, onChange }) {
  const dispatch = useDispatch();
  const dispatchCliente = useCrudClienteDispatch();

  const utilStyle = useUtilStyles();

  const tiposLiquidacaoPrestacao = useSelector(states => states.selectors.tiposLiquidacaoPrestacao.selector);
  const bancos = useSelector(states => states.selectors.bancos.selector);
  const tiposChavesPix = useSelector(states => states.selectors.tiposChavesPix.selector);

  const idTipoLiquidacaoPrestacao = useCrudClienteSelector(states => states.controls.idTipoLiquidacaoPrestacao.copy());

  const idBanco = useCrudClienteSelector(states => states.controls.idBanco.copy());
  const ccAgenciaSispag = useCrudClienteSelector(states => states.controls.ccAgenciaSispag.copy());
  const ccContaSispag = useCrudClienteSelector(states => states.controls.ccContaSispag.copy());
  const dacContaSispag = useCrudClienteSelector(states => states.controls.dacContaSispag.copy());
  const chavePix = useCrudClienteSelector(states => states.controls.chavePix.copy());
  const idTipoChavePix = useCrudClienteSelector(states => states.controls.idTipoChavePix.copy());
  const detalheLiquidacaoPrestacao = useCrudClienteSelector(states => states.controls.detalheLiquidacaoPrestacao.copy());

  const maskChavePix = () => {
    if (idTipoChavePix.value === 0) {
      return CNPJ_MASK;
    } if (idTipoChavePix.value === 1) {
      return CPF_MASK;
    } if (idTipoChavePix.value === 3) {
      return PHONE_MASK;
    } return null;
  };

  const onFocus = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling?.name) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatchCliente(onFocusControls(fieldName));
  }, [dispatchCliente]);

  useEffect(() => {
    dispatch(findAllTiposLiquidacaoPrestacaoAction());
    dispatch(findBancosAction());
    dispatch(findAllTiposChavePixAction());
  }, [dispatch]);

  return (
    <Grid24 container isFormContainer spacing="1">
      <Grid24 xs={24} sm={24}>
        <FormWrapperWithTitle
          title="Informação para Transferência de Valores"
        >
          <Grid24 xs={24} sm={24}>
            <Grid24 xs={24} sm={7} className={utilStyle.mt10}>
              <SelectorField
                isForm
                disabled={!isEditMode}
                label={FORMA_PADRAO_PRESTACAO}
                items={tiposLiquidacaoPrestacao}
                name={idTipoLiquidacaoPrestacao.name}
                value={idTipoLiquidacaoPrestacao.value}
                onChange={onChange}
              />
            </Grid24>
            <Grid24 xs={24} sm={13} className={utilStyle.mt10ml8}>
              {(idTipoLiquidacaoPrestacao.value === 2
                && (
                  <InputField
                    fullWidth
                    isForm
                    maxLength="100"
                    disabled={!isEditMode}
                    label={getRequiredLabel(DETALHES_LIQUIDACAO, true)}
                    name={detalheLiquidacaoPrestacao.name}
                    value={detalheLiquidacaoPrestacao.value}
                    error={detalheLiquidacaoPrestacao.error}
                    errorMessage={detalheLiquidacaoPrestacao.errorMessage}
                    onChange={onChange}
                    onFocus={onFocus}
                  />
                ))}
            </Grid24>
          </Grid24>
          <hr className={utilStyle.divider} />
          <Grid24 container isFormContainer spacing="1">
            <Grid24 xs={24} sm={24}>
              <WrapperSubTitleForm title="Dados Bancários" />
            </Grid24>
            <Grid24 xs={24} sm={24} spacing="1" className={utilStyle.mt10}>
              <Grid24 xs={24} sm={12}>
                <SelectorField
                  isForm
                  hasEmptyLabel
                  isNative
                  disabled={!isEditMode}
                  label={BANCO_LABEL}
                  items={bancos}
                  name={idBanco.name}
                  value={idBanco.value}
                  error={idBanco.error}
                  errorMessage={idBanco.errorMessage}
                  onChange={onChange}
                  onFocus={onFocus}
                />
              </Grid24>
              <Grid24 xs={24} sm={3}>
                <InputField
                  fullWidth
                  isForm
                  disabled={!isEditMode}
                  label={AGENCIA_LABEL}
                  name={ccAgenciaSispag.name}
                  value={ccAgenciaSispag.value}
                  error={ccAgenciaSispag.error}
                  errorMessage={ccAgenciaSispag.errorMessage}
                  onChange={onChange}
                  onFocus={onFocus}
                />
              </Grid24>
              <Grid24 xs={24} sm={3}>
                <InputField
                  fullWidth
                  isForm
                  disabled={!isEditMode}
                  label={NUMERO_CONTA_LABEL}
                  name={ccContaSispag.name}
                  value={ccContaSispag.value}
                  error={ccContaSispag.error}
                  errorMessage={ccContaSispag.errorMessage}
                  onChange={onChange}
                  onFocus={onFocus}
                />
              </Grid24>
              <Grid24 xs={24} sm={1}>
                <InputField
                  fullWidth
                  isForm
                  disabled={!isEditMode}
                  label={DAC}
                  name={dacContaSispag.name}
                  value={dacContaSispag.value}
                  maxLength={1}
                  error={dacContaSispag.error}
                  errorMessage={dacContaSispag.errorMessage}
                  onChange={onChange}
                  onFocus={onFocus}
                />
              </Grid24>
            </Grid24>
            <Grid24 xs={24} sm={24} className={utilStyle.mt20}>
              <Grid24 xs={24} sm={24} spacing="1">
                <Grid24 xs={24} sm={4}>
                  <SelectorField
                    isForm
                    disabled={!isEditMode}
                    hasEmptyLabel
                    label={TIPO_CHAVE_PIX_LABEL}
                    items={tiposChavesPix}
                    name={idTipoChavePix.name}
                    value={idTipoChavePix.value}
                    onChange={onChange}
                  />
                </Grid24>
                <Grid24 xs={24} sm={9}>
                  {idTipoChavePix.value === 2 || idTipoChavePix.value === 4
                    ? (
                      <InputField
                        isForm
                        fullWidth
                        disabled={!isEditMode}
                        label={CHAVE_PIX}
                        name={chavePix.name}
                        value={chavePix.value}
                        error={chavePix.error}
                        errorMessage={chavePix.errorMessage}
                        onChange={onChange}
                        onFocus={onFocus}
                      />
                    )
                    : (
                      <MaskField
                        isForm
                        disabled={!isEditMode}
                        label={CHAVE_PIX}
                        name={chavePix.name}
                        value={chavePix.value}
                        onChange={onChange}
                        onFocus={onFocus}
                        format={maskChavePix()}
                        error={chavePix.error}
                        errorMessage={chavePix.errorMessage}
                      />
                    )}
                </Grid24>
              </Grid24>
            </Grid24>
          </Grid24>
        </FormWrapperWithTitle>
      </Grid24>
    </Grid24>
  );
}
export default React.memo(InformacoesTransferencia);
