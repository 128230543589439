import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function CommentExclamationIcon(props) {
  const path = 'M9.51479 0C4.26976 0 0 3.64665 0 8.12947C0 12.6123 4.26976 16.2589 9.51479 16.2589C10.4961 16.2578 11.4726 16.125 12.4168 15.8641L15.7053 17.9081C15.7951 17.9643 15.8987 17.9959 16.0053 17.9996C16.1119 18.0033 16.2176 17.979 16.3112 17.9291C16.4049 17.8792 16.4831 17.8057 16.5376 17.7162C16.5921 17.6266 16.621 17.5244 16.6211 17.4203V13.5123C17.3652 12.8153 17.959 11.98 18.3675 11.0556C18.776 10.1312 18.991 9.13626 18.9998 8.12947C19.0296 3.64665 14.7598 0 9.51479 0ZM8.87849 3.84988C8.86872 3.73696 8.88307 3.62328 8.92063 3.51606C8.95818 3.40883 9.01813 3.31038 9.09666 3.22694C9.1752 3.1435 9.27062 3.07689 9.37689 3.03133C9.48315 2.98577 9.59795 2.96224 9.714 2.96224C9.83006 2.96224 9.94486 2.98577 10.0511 3.03133C10.1574 3.07689 10.2528 3.1435 10.3313 3.22694C10.4099 3.31038 10.4698 3.40883 10.5074 3.51606C10.5449 3.62328 10.5593 3.73696 10.5495 3.84988V9.52309C10.5593 9.63602 10.5449 9.74969 10.5074 9.85692C10.4698 9.96415 10.4099 10.0626 10.3313 10.146C10.2528 10.2295 10.1574 10.2961 10.0511 10.3416C9.94486 10.3872 9.83006 10.4107 9.714 10.4107C9.59795 10.4107 9.48315 10.3872 9.37689 10.3416C9.27062 10.2961 9.1752 10.2295 9.09666 10.146C9.01813 10.0626 8.95818 9.96415 8.92063 9.85692C8.88307 9.74969 8.86872 9.63602 8.87849 9.52309V3.84988ZM9.71698 13.9246C9.49704 13.9246 9.28204 13.8609 9.09916 13.7416C8.91629 13.6223 8.77375 13.4527 8.68959 13.2543C8.60542 13.0559 8.5834 12.8376 8.62631 12.6269C8.66921 12.4163 8.77513 12.2228 8.93065 12.0709C9.08617 11.9191 9.28432 11.8157 9.50003 11.7738C9.71575 11.7319 9.93934 11.7534 10.1425 11.8355C10.3457 11.9177 10.5194 12.0569 10.6416 12.2355C10.7638 12.414 10.829 12.624 10.829 12.8388C10.8274 13.1257 10.7096 13.4004 10.5012 13.6028C10.2928 13.8052 10.0109 13.9188 9.71698 13.9188V13.9246Z';
  return (
    <SvgIcon transform="scale(1.13)" viewBox="-4 -4 30 30">
      <path d={path} {...props} />
    </SvgIcon>
  );
}
