import React, { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import Grid24 from '../../components/UI/Grid24';
import DateField from '../../components/UI/Field/Date/DateField';
import { getRequiredLabel, toBrazilianDate } from '../../helpers';
import { InputFileIcon } from '../../components/UI/InputFile/InputFileIcon';
import { useRecebimentoDispatch, useRecebimentoSelector } from '../../store/RecebimentoDevedores/RecebimentoDevedoresProvider';
import { onChangeRecebimentoManualAction, onFoucusRecebimentoManualAction, setSimpleValueArquivoAction } from '../../store/RecebimentoDevedores/RecebimentoDevedores.store';
import MoneyField from '../../components/UI/Field/Money/MoneyField';
import { importarComprovanteAction } from '../../store/RecebimentoDevedores/RecebimentoDevedores.saga';
import CheckboxField from '../../components/UI/Field/Checkbox/CheckboxField';


function DialogRecebimentoManualComponent({ setOpenRecebimentoManual, openPesquisaAvancada }) {
  const dispatch = useRecebimentoDispatch();

  const dataPagamento = useRecebimentoSelector(states => states.recebimentoManual.dataPagamento);
  const valorPago = useRecebimentoSelector(states => states.recebimentoManual.valorPago);
  const comprovante = useRecebimentoSelector(states => states.recebimentoManual.comprovante);

  const [comprovanteRecebimento, setComprovanteRecebimento] = useState(null);
  const [quitarIntegralmenteParcela, setQuitarIntegralmenteParcela] = useState(false);

  const onChangeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeRecebimentoManualAction(name, value));
  }, [dispatch]);

  const onChangeFieldsFile = (event) => {
    const { name, value } = event.target;
    dispatch(setSimpleValueArquivoAction(name, value));
  };

  const resetCampos = useCallback(() => {
    dispatch(onChangeRecebimentoManualAction('dataPagamento', ''));
    dispatch(onChangeRecebimentoManualAction('valorPago', ''));
    setQuitarIntegralmenteParcela(false);
  }, [dispatch, setQuitarIntegralmenteParcela]);

  const onFoucusCampos = useCallback((event) => {
    const { name } = event.target;
    dispatch(onFoucusRecebimentoManualAction(name));
  }, [dispatch]);

  const onClickConfirmar = useCallback(() => {
    dispatch(importarComprovanteAction(quitarIntegralmenteParcela, comprovanteRecebimento, setOpenRecebimentoManual, openPesquisaAvancada, resetCampos));
  }, [quitarIntegralmenteParcela, dispatch, comprovanteRecebimento, openPesquisaAvancada, setOpenRecebimentoManual, resetCampos]);

  const onClickCancelar = useCallback(() => {
    resetCampos();
    setOpenRecebimentoManual(false);
  }, [setOpenRecebimentoManual, resetCampos]);

  return (
    <>
      <Grid24 container isFormContainer spacing={1}>
        <Grid24 xs={24} sm={7}>
          <DateField
            fullWidth
            isForm
            name="dataPagamento"
            label={getRequiredLabel('Data do Pagamento', true)}
            maxDate={new Date()}
            value={dataPagamento.value}
            onChange={onChangeFieldsHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={7}>
          <MoneyField
            fullWidth
            isForm
            name="valorPago"
            label={getRequiredLabel('Valor', true)}
            value={valorPago.value}
            onChange={onChangeFieldsHandler}
            error={valorPago.error}
            errorMessage={valorPago.errorMessage}
            onFocus={onFoucusCampos}
          />
        </Grid24>

        <Grid24 xs={24} sm={8}>
          <div style={{ width: '282px', marginTop: '13px', marginLeft: '8px' }}>
            <CheckboxField
              label="Quitar integralmente a Parcela"
              name="quitarIntegralmenteParcela"
              checked={quitarIntegralmenteParcela}
              onChange={(e) => { setQuitarIntegralmenteParcela(e.target.checked); }}
            />
          </div>
        </Grid24>
        <Grid24 xs={24} sm={20} style={{ marginTop: '22px' }}>
          <InputFileIcon
            isForm
            acceptAll
            accept="image/png,image/jpeg,application/pdf"
            label="Comprovante"
            name="comprovante"
            labelName="comprovante"
            value={comprovante.value}
            onChange={onChangeFieldsFile}
            setFile={setComprovanteRecebimento}
          />
        </Grid24>
        <Grid24 xs={24} sm={14} />
        <Grid24 xs={24} sm={9}>
          <span style={{
            fontSize: '11px',
            color: '#707070',
            width: '220px',
            marginTop: '6px',
          }}
          >
            (.pdf, .png, .jpeg ou .jpg /máx: 10MB)

          </span>
        </Grid24>
      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '22px' }}>
        <Button
          onClick={onClickCancelar}
        >
          Cancelar
        </Button>
        <Button
          onClick={onClickConfirmar}
          disabled={!dataPagamento.value || dataPagamento.value > toBrazilianDate(new Date()) || !valorPago.value || valorPago.value === '.00'}
        >
          Confirmar
        </Button>
      </div>

    </>
  );
}

export const DialogRecebimentoManual = DialogRecebimentoManualComponent;
