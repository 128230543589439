import React from 'react';
import { useDispatch } from 'react-redux';
import withTokenInterceptor from '../../../../hoc/withTokenInterceptor/withTokenInterceptor';
import PesquisarCliente from '../../../../components/Cliente/Pesquisa/PesquisarCliente';
import { initializePageAction } from '../../../../store/theme.actions';

function PesquisaClientePage() {
  const dispatch = useDispatch();
  dispatch(initializePageAction('Clientes'));

  return (
    <PesquisarCliente isCliente />
  );
}

export default withTokenInterceptor(PesquisaClientePage);
