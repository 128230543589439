import React from 'react';
import { toast } from 'react-toastify';
import { TableCell, TableRow } from '@material-ui/core';

import {
  FaCheck,
  FaFileDownload,
  FaRegFile,
  FaSearchPlus,
} from 'react-icons/fa';
import { MdDeleteForever, MdEdit } from 'react-icons/md';

import { TableActionButton, TableActionContainer, TableCellCenter } from '../UI/Tabela/tableDefaultStyles';
import TooltipUI from '../UI/Tooltip/Tooltip';
import CheckboxField from '../UI/Field/Checkbox/CheckboxField';

import {
  buscarArquivoDocumentoProcessoPorIdDocumentoService,
} from '../../services/core/processos/processoDocumentoService';

import { downloadFile, openPagePDF } from '../../helpers/utils/utils';
import { extensao } from '../../types/extensoesDocumentosTypes';

const NAO_EXISTE_DOCUMENTO = 'Não existem documentos anexados ao Processo';

function TableBodyDocumentosProcessoComponent({
  content, podeModificarFase, onOpenEdit, onShowDeleteProcesso, onSelectRow,
}) {
  function handleOpenDocumento(idDocumento) {
    return async () => {
      try {
        const arquivoDocumento = await buscarArquivoDocumentoProcessoPorIdDocumentoService(idDocumento);
        openPagePDF(arquivoDocumento.data);
      } catch {
        toast.error(NAO_EXISTE_DOCUMENTO, { style: { width: '392px' } });
      }
    };
  }

  function handleDownloadDocumento(documento) {
    return async () => {
      try {
        const response = await buscarArquivoDocumentoProcessoPorIdDocumentoService(documento.idProcessoDocumento);
        downloadFile(documento.nomeDocumento, response.data);
      } catch {
        toast.error(NAO_EXISTE_DOCUMENTO, { style: { width: '392px' } });
      }
    };
  }
  return content.map((item) => {
    const selectedExtension = extensao[item.extensao];
    const hasNotPdf = (item.extensao && item.extensao.toUpperCase() !== 'PDF');

    return (
      <TableRow key={item.idProcessoDocumento}>
        <TableCellCenter width={24}>
          <CheckboxField
            disabled={!podeModificarFase}
            onChange={onSelectRow(item.idProcessoDocumento)}
            checked={item.checked}
          />
        </TableCellCenter>
        <TableCellCenter width={62}>
          {selectedExtension || <FaRegFile size={40} color="#9B67B4" />}
        </TableCellCenter>
        <TableCell width={155}>
          <TooltipUI title={item.tipoAlt} placement="bottom">
            <span>{item.tipo}</span>
          </TooltipUI>
        </TableCell>
        <TableCell width={103}>
          <TooltipUI title={item.descricaoAlt} placement="bottom">
            <span>{item.descricao}</span>
          </TooltipUI>
        </TableCell>
        <TableCell width={50}>
          <TooltipUI title={item.nomeDocumentoAlt} placement="bottom">
            <span>{item.nomeDocumento}</span>
          </TooltipUI>
        </TableCell>
        <TableCellCenter width={100}>
          {item.serDocumentoOriginal && <FaCheck size={16} />}
        </TableCellCenter>
        <TableCellCenter width={100}>
          {item.ocultarDocumentoCliente && <FaCheck size={16} />}
        </TableCellCenter>
        <TableCellCenter width={100}>{item.dataArquivamento}</TableCellCenter>
        <TableCell width={100}>
          <TooltipUI title={item.nomeUsuarioAlt}>
            <span>{item.nomeUsuario}</span>
          </TooltipUI>
        </TableCell>
        <TableCellCenter width={150}>
          <TableActionContainer>
            <TooltipUI title="Baixar" placement="bottom">
              <span>
                <TableActionButton onClick={handleDownloadDocumento(item)}>
                  <FaFileDownload size={16} />
                </TableActionButton>
              </span>
            </TooltipUI>
            <TooltipUI title="Visualizar" placement="bottom">
              <span>
                <TableActionButton
                  disabled={hasNotPdf}
                  onClick={handleOpenDocumento(item.idProcessoDocumento)}
                >
                  <FaSearchPlus size={16} />
                </TableActionButton>
              </span>
            </TooltipUI>
            <TooltipUI title="Editar" placement="bottom">
              <span>
                <TableActionButton
                  disabled={!podeModificarFase}
                  onClick={onOpenEdit(item.idProcessoDocumento)}
                >
                  <MdEdit size={21} />
                </TableActionButton>
              </span>
            </TooltipUI>
            <TooltipUI title="Excluir" placement="bottom">
              <span>
                <TableActionButton
                  disabled={!podeModificarFase}
                  onClick={onShowDeleteProcesso(item.idProcessoDocumento)}
                >
                  <MdDeleteForever size={21} />
                </TableActionButton>
              </span>
            </TooltipUI>
          </TableActionContainer>
        </TableCellCenter>
      </TableRow>
    );
  });
}

export const TableBodyDocumentosProcesso = TableBodyDocumentosProcessoComponent;
