import { TableContainer } from '@material-ui/core';
import React from 'react';

import { MdPrint } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ButtonUI, Loading, TableDefault,
} from '../../components';
import { TableBodyContaCorrenteComissionado } from '../../components/TabelaBody/TableBodyContaCorrenteComissionado';
import { TableHeaderContaCorrenteComissionado } from '../../components/TabelaHeader/TableHeaderContaCorrenteComissionado';
import SwitchUI from '../../components/UI/Switch/SwitchUI';
import {
  DateUtils, getMoneyMask, toastUnmappedException, TableUtils, getFieldName, hasUserPermissions, getNewPage, openPageFile, removeMoneyMask,
} from '../../helpers';
import tableUtils from '../../helpers/utils/table.utils';
import ContaCorrenteComissionadoService from '../../services/core/contaCorrenteComissionado/ContaCorrenteComissionadoService';
import store from '../../store/createStore';
import { setValuesContaCorrenteComissionadoPageAction } from '../../store/MenuAccessPage/menuAccessPageActions';
import { initializePageAction } from '../../store/theme.actions';
import { statusContaCorrenteUsuarioType } from '../../types/statusContaCorrenteUsuarioTypes';
import { ContaCorrenteComissionadoController, ContaCorrenteComissionadoFactory } from './ContaCorrenteComissionadoController';
import useStyle, {
  ButtonImprimir, ButtonNovo, CardBottom, CardTop, Container, InfoContainer, PeriodSelector, ResumoContainer, SelectComissionado, TableHeader,
} from './ContaCorrenteComissionadoStyles';
import OptionDialogNew from '../../components/UI/Dialogs/OptionDialog/OptionDialogNew';


function getFields() {
  return store.getState().previousPageStates?.contaCorrenteComissionado?.filters;
}

function makeLoading() {
  return {
    nome: false,
    saldos: false,
    lancamentos: false,
    delete: false,
  };
}

function makeResumo(response) {
  return {
    saldoAnterior: getMoneyMask(response?.saldoAnterior) || 'R$ 0,00',
    totalCreditosPeriodo: getMoneyMask(response?.totalCreditosPeriodo) || 'R$ 0,00',
    totalEstornosPeriodo: getMoneyMask(response?.totalEstornosPeriodo) || 'R$ 0,00',
    totalDebitosPeriodo: getMoneyMask(response?.totalDebitosPeriodo) || 'R$ 0,00',
    totalLancamentoBloqueado: getMoneyMask(response?.totalLancamentoBloqueado) || 'R$ 0,00',
    totalPrestar: getMoneyMask(response?.totalPrestar) || 'R$ 0,00',
  };
}

function makeColumnOrder(columnName, columnOrder) {
  return TableUtils.makeColumnOrder({
    element: columnName || 'idPrestacao',
    order: columnOrder?.getNextOrder() || 'ASC',
    arrow: columnOrder ? columnOrder?.getNextArrow() : true,
  });
}

function makeFields(fields, selectedIdNome = '') {
  return {
    idComissionado: fields ? fields.idComissionado : selectedIdNome || '',
    exibirAtivos: fields ? fields.exibirAtivos : true,
    exibirLancamentosNaoPrestados: fields ? fields.exibirLancamentosNaoPrestados : true,
    period: {
      interval: fields ? fields?.period?.interval : 30,
      startDate: fields ? fields?.period?.startDate : DateUtils.makeDate(30), // TODO Mudar no CAIXA FILIAL
      endDate: fields ? fields?.period?.endDate : DateUtils.makeDate(0),
    },
  };
}

function getColumnOrder() {
  const { columnOrder } = store.getState().previousPageStates.contaCorrenteComissionado;

  return {
    ...makeColumnOrder(),
    ...columnOrder,
  };
}

function ContaCorrenteComissionadoComponent() {
  const dispatch = useDispatch();
  const style = useStyle();
  const history = useHistory();

  const [openDialogDeleteComissionado, setOpenDialogDeleteComissionado] = React.useState(false);
  const [openDialogBloqueio, setOpenDialogBloqueio] = React.useState(false);
  const [comissionados, setComissionados] = React.useState([]);
  const [loading, setLoading] = React.useState(makeLoading());
  const [saldos, setSaldos] = React.useState(makeResumo());
  const [fields, setFields] = React.useState(makeFields(getFields() !== null ? getFields() : null));
  const [lancamentos, setLancamentos] = React.useState(ContaCorrenteComissionadoFactory.defaultMakeLancamentos());
  const [columnOrder, setColumnOrder] = React.useState(getColumnOrder());

  const [selectedIdContaCorrenteUsuario, setSelectedIdContaCorrenteUsuario] = React.useState(null);

  const cannotInsertNewLancamento = React.useMemo(() => !hasUserPermissions(1603), []);
  const cannotPrestarContas = React.useMemo(() => !hasUserPermissions(1604), []);
  const cannotAcoesIcones = React.useMemo(() => !hasUserPermissions(1603), []);
  const hasNoMoreThenOneComissionado = React.useMemo(() => comissionados.length > 1, [comissionados]);
  const hasMoreThenOneComissionados = React.useMemo(() => comissionados.length > 1 && hasUserPermissions(1601) && !hasUserPermissions(1602, 1603, 1604), [comissionados]);

  function handleOpenDeleteComissionado(idContaCorrenteUsuario) {
    setSelectedIdContaCorrenteUsuario(idContaCorrenteUsuario);
    setOpenDialogDeleteComissionado(true);
  }

  function handleOpenBloqueadoAutomatico(idContaCorrente) {
    setOpenDialogBloqueio(true);
    setSelectedIdContaCorrenteUsuario(idContaCorrente);
  }

  function handleOnClickCancel() {
    setOpenDialogDeleteComissionado(false);
  }

  function handleOnClickCancelBloqueio() {
    setOpenDialogBloqueio(false);
  }

  function savePreviewsPagesState() {
    const pageable = tableUtils.makePageableFromTableItem(lancamentos);
    dispatch(setValuesContaCorrenteComissionadoPageAction(pageable, fields, columnOrder));
  }

  function goToCadastrar() {
    savePreviewsPagesState();
    history.push({
      pathname: '/conta-corrente-comissionado/cadastrar',
      state: { idComissionado: fields.idComissionado },
    });
  }


  function goToEditar(idContaCorrenteUsuario) {
    savePreviewsPagesState();
    setSelectedIdContaCorrenteUsuario(idContaCorrenteUsuario);
    history.push({
      pathname: '/conta-corrente-comissionado/editar',
      state: {
        idComissionado: fields.idComissionado,
        idContaCorrenteUsuario,
      },
    });
  }

  const handleChangeLancamentoNaoPrestados = React.useCallback((e) => {
    const name = getFieldName(e.target);
    const { checked } = e.target;
    setFields(oldFields => ({ ...oldFields, [name]: checked }));
  }, []);

  const findSaldosComissionados = React.useCallback(async (idComissionado, exibirLancamentosNaoPrestados) => {
    setLoading(oldLoading => ({ ...oldLoading, saldos: true }));
    try {
      const statusContaCorrente = statusContaCorrenteUsuarioType.isOnlyNaoPrestado(exibirLancamentosNaoPrestados);
      const httpResponse = await ContaCorrenteComissionadoService.findSaldos(fields.period, idComissionado, statusContaCorrente);
      setSaldos(makeResumo(httpResponse.data));
    } catch (err) {
      toastUnmappedException(err, 'Ocorreu um problema ao tentar consultar os saldos');
      setSaldos(makeResumo());
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, saldos: false }));
    }
  }, [fields.period]);

  const findAllLancamentosComissionado = React.useCallback(async (formFields, order, page, size) => {
    setLoading(oldLoading => ({ ...oldLoading, lancamentos: true }));
    try {
      const pageable = { page, size };
      const newLancamentos = await ContaCorrenteComissionadoController.findAllLancamentosComissionados(
        formFields, order, pageable,
      );
      setLancamentos(newLancamentos.lancamentos);
    } catch (err) {
      toastUnmappedException(err, 'Ocorreu um problema ao tentar consultas os lançamentos');
      setLancamentos(ContaCorrenteComissionadoFactory.makeLancamentos({ size: 10 }));
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, lancamentos: false }));
    }
  }, []);

  async function handleDeleteLancamento() {
    setLoading(oldLoading => ({ ...oldLoading, delete: true }));
    try {
      await ContaCorrenteComissionadoService.deleteLancamento(selectedIdContaCorrenteUsuario);
      findSaldosComissionados(fields.idComissionado);
      setFields(oldFields => ({ ...oldFields }));
    } catch (err) {
      toastUnmappedException(err, `Ocorreu um problema ao tentar excluir lançamento ${selectedIdContaCorrenteUsuario}`);
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, delete: false }));
      setSelectedIdContaCorrenteUsuario(null);
      setOpenDialogDeleteComissionado(false);
    }
  }

  async function handleApplyOrder(columnName) {
    const newColumnOrder = makeColumnOrder(columnName, columnOrder);
    setColumnOrder(newColumnOrder);
  }

  const handleChangeUsuarioComissionado = React.useCallback(async (e) => {
    const name = getFieldName(e.target);
    const { value } = e.target;
    setFields(oldFields => ({
      ...oldFields,
      ...oldFields.idComissionado,
      ...oldFields.exibirLancamentosNaoPrestados,
      [name]: value,
      period: {
        interval: 30,
        startDate: DateUtils.makeDate(30),
        endDate: DateUtils.makeDate(0),
      },
    }));
  }, []);

  async function handlePrintLancamentos() {
    try {
      setLoading(oldLoading => ({ ...oldLoading, download: true }));
      const httpResponse = await ContaCorrenteComissionadoController.downloadExtratoLancamentos(fields, columnOrder);
      if (httpResponse.data.size > 0) {
        openPageFile(httpResponse.data, httpResponse.data.type);
      }
    } catch (err) {
      toastUnmappedException(err, 'Ocorreu um problema ao tentar abrir o comprovante');
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, download: false }));
    }
  }


  const findAllComissionadosAndComissionadoSelecionado = React.useCallback(async (comissionadosAtivos) => {
    try {
      const response = await ContaCorrenteComissionadoController.findAllComissionados(comissionadosAtivos);
      setComissionados(response.comissionados);
      return response.selectedComissionado;
    } catch (err) {
      toastUnmappedException(err, 'Ocorreu um problema ao tentar consultar os nomes de Usuário');
      return '';
    }
  }, []);

  const handleChangeComissionadosAtivos = React.useCallback(async (e) => {
    const name = getFieldName(e.target);
    const { checked } = e.target;
    const comissioandoSelecioando = await findAllComissionadosAndComissionadoSelecionado(checked);
    setFields(oldFields => ({ ...oldFields, idComissionado: comissioandoSelecioando, [name]: checked }));
  }, [findAllComissionadosAndComissionadoSelecionado]);

  async function handleChangePeriod(interval, startDate, endDate) {
    setFields(oldFields => ({
      ...oldFields,
      period: {
        ...oldFields.period, startDate, endDate, interval,
      },
    }));
    setColumnOrder(makeColumnOrder());
  }

  function handleChangePage(e, newPage) {
    setLancamentos(oldLancamentos => ({ ...oldLancamentos, size: lancamentos.size, page: Number(newPage) }));
  }

  function handleRowsPerPageChange(e) {
    const size = e.target.value;
    const newPage = getNewPage(lancamentos.size, lancamentos.page, size);
    setLancamentos(oldLancamentos => ({ ...oldLancamentos, size, page: Number(newPage) }));
  }

  async function handleAtualizarStatusAutomatico() {
    setLoading(oldLoading => ({ ...oldLoading }));
    try {
      await ContaCorrenteComissionadoService.atualizarStatus(selectedIdContaCorrenteUsuario, 4);
      setFields(oldFields => ({ ...oldFields }));
      findSaldosComissionados(fields.idComissionado).then();
    } catch (err) {
      toastUnmappedException(err, `Ocorreu um problema ao tentar desbloquear o lançamento ${selectedIdContaCorrenteUsuario}`);
    } finally {
      setLoading(oldLoading => ({ ...oldLoading }));
      setOpenDialogBloqueio(false);
    }
  }

  async function handleAtualizarStatus(idContaCorrenteUsuario, idStatusContaCorrenteUsuario) {
    setLoading(oldLoading => ({ ...oldLoading }));
    try {
      await ContaCorrenteComissionadoService.atualizarStatus(idContaCorrenteUsuario, idStatusContaCorrenteUsuario);
      setFields(oldFields => ({ ...oldFields }));
      findSaldosComissionados(fields.idComissionado).then();
    } catch (err) {
      toastUnmappedException(err, `Ocorreu um problema ao tentar desbloquear o lançamento ${selectedIdContaCorrenteUsuario}`);
    } finally {
      setLoading(oldLoading => ({ ...oldLoading }));
    }
  }

  function savePreviousPageStates() {
    const pageable = TableUtils.makePageableFromTableItem(lancamentos);
    dispatch(setValuesContaCorrenteComissionadoPageAction(pageable, fields, columnOrder));
  }


  function openPrestarContas() {
    savePreviousPageStates();

    history.push({
      pathname: '/conta-corrente-comissionado/prestar-contas',
      state: {
        idUsuarioComissionado: fields.idComissionado,
        saldo: removeMoneyMask(saldos.totalPrestar),
      },
    });
  }

  React.useEffect(() => {
    dispatch(initializePageAction('Conta-Corrente de Comissionado'));
  }, [dispatch]);

  /** Carregar usuários comissionados */
  React.useEffect(() => {
    findAllComissionadosAndComissionadoSelecionado(fields.exibirAtivos).then((comissionadoSelecionado) => {
      setFields(makeFields(getFields(), comissionadoSelecionado));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Atualizar saldo comissionado */
  React.useEffect(() => {
    if (fields.idComissionado) {
      findSaldosComissionados(fields.idComissionado).then();
    } else {
      setSaldos(makeResumo());
    }
  }, [fields.idComissionado, findSaldosComissionados]);

  React.useEffect(() => {
    if (fields.idComissionado) {
      findAllLancamentosComissionado(fields, columnOrder, lancamentos.page, lancamentos.size).then();
    } else {
      setLancamentos(ContaCorrenteComissionadoFactory.makeLancamentos({ size: 10 }));
    }
  }, [fields, columnOrder, lancamentos.page, lancamentos.size, findAllLancamentosComissionado]);


  return (
    <>
      <OptionDialogNew
        open={openDialogDeleteComissionado}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={handleOnClickCancel}
        onClickConfirm={handleDeleteLancamento}
        text="Deseja excluir o lançamento selecionado?"
      />
      <OptionDialogNew
        open={openDialogBloqueio}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={handleOnClickCancelBloqueio}
        onClickConfirm={handleAtualizarStatusAutomatico}
      >
        <div style={{ fontSize: '18px' }}>
          <center>O lançamento selecionado foi bloqueado</center>
          <p> de maneira automática pois está aguardando a quitação.</p>
          <center>Tem certeza que deseja desbloquear?</center>
        </div>
      </OptionDialogNew>
      <Loading
        show={loading.nome || loading.saldos || loading.lancamentos}
      />
      <Container>
        <InfoContainer>
          <div className="filters">
            <SelectComissionado
              disabled={!hasNoMoreThenOneComissionado}
              label="Comissionados"
              name="idComissionado"
              items={comissionados}
              value={fields.idComissionado}
              onChange={handleChangeUsuarioComissionado}
            />
            <div className={style.div__check}>
              <SwitchUI
                disabled={hasMoreThenOneComissionados}
                label="Exibir apenas os comissionados ativos"
                name="exibirAtivos"
                checked={fields.exibirAtivos}
                onChange={handleChangeComissionadosAtivos}
              />
            </div>
            <PeriodSelector
              name="period"
              value={fields.period}
              withPeriod
              onChange={handleChangePeriod}
            />
            <ButtonImprimir
              disabled={lancamentos.content.length === 0}
              onClick={handlePrintLancamentos}
            >
              <MdPrint size={16} />
              <span>Imprimir</span>
            </ButtonImprimir>
          </div>
          <div className="additional-info">
            <ResumoContainer>
              <p className="title">Resumo no Período</p>
              <div>
                <CardTop color="#a3a1a3">
                  <p>Créditos</p>
                  <p>{saldos.totalCreditosPeriodo}</p>
                </CardTop>
                <CardTop color="#a3a1a3">
                  <p>Débitos</p>
                  <p>{saldos.totalDebitosPeriodo}</p>
                </CardTop>
                <CardTop color="#a3a1a3">
                  <p>Estornos</p>
                  <p>{saldos.totalEstornosPeriodo}</p>
                </CardTop>
              </div>
              <div className={style.div__previsaoPrestacao}>
                <p>Previsão para a próxima Prestação</p>
              </div>
              <div className={style.div__cards}>
                <CardBottom color="#ff8ac1">
                  <p>Total de Lançamentos Bloqueados</p>
                  <p>{saldos.totalLancamentoBloqueado}</p>
                </CardBottom>
                <CardBottom color="#75c3c8">
                  <p>Total a Prestar</p>
                  <p>{saldos.totalPrestar}</p>
                </CardBottom>
              </div>
            </ResumoContainer>
            <div className={style.div__align}>
              <div className={style.div__buttons}>
                <ButtonUI color="white" disabled={cannotPrestarContas || !fields.idComissionado || saldos.totalPrestar <= '0,00'} onClick={openPrestarContas}>
                  Prestar Contas
                </ButtonUI>
              </div>
              <ButtonNovo
                onClick={goToCadastrar}
                disabled={cannotInsertNewLancamento || !fields.idComissionado}
              >
                Novo
              </ButtonNovo>
            </div>
          </div>
        </InfoContainer>

        <TableContainer>
          <TableHeader>
            Lançamentos
          </TableHeader>
          <div>
            <TableDefault
              columnsHead={(
                <TableHeaderContaCorrenteComissionado
                  columnOrder={columnOrder}
                  onChangeOrder={columnName => handleApplyOrder(columnName)}
                />
              )}
              page={lancamentos.page}
              rowsPerPage={lancamentos.size}
              totalElements={lancamentos.totalElements}
              totalElementsOnPage={lancamentos.content?.length}
              totalColumns={7}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleRowsPerPageChange}
              emptyRowsHeight={33}
              rowsPerPageOptions={[10, 15, 20]}
            >
              {lancamentos.content?.map(lancamento => (
                <TableBodyContaCorrenteComissionado
                  key={lancamento.idContaCorrenteUsuario}
                  openDialogEdit={goToEditar}
                  openDialogDelete={handleOpenDeleteComissionado}
                  openDialogBloqueio={handleOpenBloqueadoAutomatico}
                  lancamento={lancamento}
                  handleAtualizarStatus={handleAtualizarStatus}
                  cannotAcoesIcones={cannotAcoesIcones}
                />
              ))}
            </TableDefault>
          </div>
        </TableContainer>
        <div className={style.div__checkFooter}>
          <SwitchUI
            label="Exibir apenas lançamentos não prestados"
            name="exibirLancamentosNaoPrestados"
            checked={fields.exibirLancamentosNaoPrestados}
            onChange={handleChangeLancamentoNaoPrestados}
          />
        </div>

      </Container>
    </>
  );
}


export const ContaCorrenteComissionadoPage = ContaCorrenteComissionadoComponent;
