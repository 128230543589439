export const LABEL_PERIODO = 'Período';
export const LABEL_CODIGO_CLIENTE = 'Código do Cliente';
export const LABEL_TIPO_INFORMACAO = 'Tipo de Informação';
export const LABEL_PALAVRA_CHAVE = 'Palavra-chave';
export const LABEL_NUMERO_PROCESSO = 'Nº Processo';
export const LABEL_NOME_DEVEDOR = 'Nome do Devedor';
export const LABEL_PESQUISA_SIMPLES = 'Pesquisa Simples';
export const LABEL_PESQUISA_AVANCADA = 'Pesquisa Avançada';
export const LABEL_TABELA = 'Resultado da Pesquisa';
export const BTN_LABEL_EXPORTAR_LOG = 'Exportar Log';
export const BTN_LABEL_IMPRIMIR = 'Imprimir';
export const LABEL_DATE_ATE = 'até';
