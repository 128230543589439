import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  APLICAR_PERMISSOES,
  ATUALIZAR_LISTA_PERMISSOES,
  BUILD_RELACOES_ITENS_CONFIGURAVEIS,
  COMPARAR_PERMISSOES,
  FETCH_TIPOS_USUARIOS,
  GET_FIND_FILIAL_PRINCIPAL_ACTION,
  GET_USUARIO,
  INSERT_USUARIO,
  OBTER_FOTO,
  UPDATE_USUARIO,
} from './crudUsuario.constans';
import {
  buildUsuario,
  checkEnabledAdvogadoAndAssistenteExterno,
  clearStates,
  onAtualizarPermissoes,
  onChangeActions,
  onChangeControls,
  onChangeFullyControls,
  onChangeIdsTiposUsuariosAction,
  onCheckTitlePermissionCheckbox,
  onSetActionAttributesAction,
  openErrorDialogContext,
  openErrorVinculadoDialogContext,
  setAlertDialogAction,
  setImage,
  successfullyInserted,
} from './crudUsuario.store';
import {
  buscarIdTipoAdvogadoExterno,
  buscarIdTipoUsuarioAdministrativo,
  buscarIdTipoUsuarioAdvogado,
  buscarIdTipoUsuarioComercial,
  buscarIdTipoUsuarioNegociador,
  buscarIdTipoUsuarioNegociadorExterno,
  buscarIdTipoUsuarioTelemarketing,
  deleteUsuario,
  getComparacaoPermissoesUsuario,
  getPermissoesUsuario,
  getPermissoesUsuarioByIdPerfil,
  getPermissoesUsuarioByIdUsuario,
  getUsuarioCompletoPeloId, getUsuariosElegiveisFazerParteEquipeService,
  insertUsuario,
  obterFotoUsuario,
  saveFotoUsuario,
  updateUsuario,
} from '../../../services/core/usuario/usuario.services';
import {
  buildPayload,
  buildPayloadArray,
  getExceptionErrorMessage,
  getExceptionMessage,
  isBadRequestException,
  isConflictException,
  isNotFoundException,
} from '../../../helpers/utils/utils';
import { buildEnderecoPayload } from '../../../helpers/factory/endereco.factory';
import {
  buildNewRelacaoItensConfiguraveis,
  buildPermissoes,
  buildPermissoesComparadas,
  buildRelacaoItemsConfiguraveis,
  buildRelacaoPermissoes,
  getAcoesUsuarios,
  getDadosBancoTipoDocumento,
  getItensAtualizados,
  getNomeSelecionado,
  getPermissoesSelecionadas,
} from './crudUsuario.factory';
import { getExceptionHandler } from '../../../helpers/utils/exception.util';
import {
  ERROR_APLICAR_PERMISSOES_EXCEPTION,
  ERROR_COMPARAR_PERMISSOES_EXCEPTION,
} from '../../../components/Usuario/V2/Permissoes/Selecionar/Seletor/seletorPermissoes.constants';
import { getFilialPrincipal } from '../../../services/core/filial/filial.services';


export const obterFotoAction = idUsuario => ({
  type: OBTER_FOTO,
  idUsuario,
});

export const insertUsuarioAction = foto => ({
  type: INSERT_USUARIO,
  foto,
});

export const updateUsuarioAction = (idUsuario, roolBackFunction, foto) => ({
  type: UPDATE_USUARIO,
  idUsuario,
  roolBackFunction,
  foto,
});

export const getUsuarioByIdAction = idUsuario => ({
  type: GET_USUARIO,
  idUsuario,
});

export const aplicarPermissoesAction = (baseadoEm, value) => ({
  type: APLICAR_PERMISSOES,
  baseadoEm,
  value,
});

export const compararPermissoesAction = (perfis, usuarios) => ({
  type: COMPARAR_PERMISSOES,
  perfis,
  usuarios,
});

export const atualizarListaPermissoesAction = () => ({
  type: ATUALIZAR_LISTA_PERMISSOES,
});

export const findFilialPrincipalAction = () => ({
  type: GET_FIND_FILIAL_PRINCIPAL_ACTION,
});

export const buildRelacoesItensConfiguraveisAction = () => ({
  type: BUILD_RELACOES_ITENS_CONFIGURAVEIS,
});

export const fetchIdsTiposUsuariosAction = () => ({
  type: FETCH_TIPOS_USUARIOS,
});


function isErrorSalvarFoto(exception) {
  return exception.response.data.path.endsWith('/foto');
}

function* generateAndShowException(exception, idUsuarioCriado) {
  const exceptionMessage = getExceptionMessage(exception);

  if (isNotFoundException(exception)) {
    yield put(openErrorDialogContext(exceptionMessage));
  }
  if (isBadRequestException(exception)) {
    if (isErrorSalvarFoto(exception)) {
      yield put(onChangeActions('errorImage', true));
      yield put(setAlertDialogAction(true, exceptionMessage));
      if (idUsuarioCriado) {
        yield call(deleteUsuario, idUsuarioCriado);
      }
    } else {
      const message = exceptionMessage || getExceptionErrorMessage(exception);
      if (message.includes('Transfira o(s) cliente(s)')) {
        yield put(onSetActionAttributesAction('openErrorVinculadoDialog', true));
        yield put(openErrorVinculadoDialogContext(exceptionMessage));
      } else {
        toast.error(message, { style: { width: '392px' } });
      }
    }
  }
  if (isConflictException(exception)) {
    yield put(onSetActionAttributesAction('isConflict', true));
    yield put(openErrorDialogContext(exceptionMessage));
  } else {
    yield put(onSetActionAttributesAction('isConflict', false));
  }
}

function getUsuarioPayload(controls) {
  const usuarioPayload = buildPayload(controls);
  usuarioPayload.endereco = buildEnderecoPayload(controls.endereco);
  usuarioPayload.telefones = buildPayloadArray(controls.telefones);
  usuarioPayload.permitirLogar = !controls.permitirLogar.value;

  usuarioPayload.dadosBancoTipoDocumento = getDadosBancoTipoDocumento(controls.dadosBancoTipoDocumento, controls.dadosBancoDocumento);
  usuarioPayload.permissoes = buildPermissoes(controls.permissoes);
  usuarioPayload.idsUsuariosMembros = controls?.idsUsuariosMembros;


  return usuarioPayload;
}

function* configureStatesOnSucessInsert() {
  yield put(clearStates());
  yield put(onChangeActions('limparListaMembros', true));
  yield put(successfullyInserted());
}

function* successSubmit(isInsert, roolBackFunction) {
  if (isInsert) {
    yield configureStatesOnSucessInsert();
  } else if (roolBackFunction) {
    yield roolBackFunction();
  }
}


function* catchSubmitException(exception, controls, idUsuarioCriado) {
  const [updatedControls] = yield getExceptionHandler(exception, controls);
  if (updatedControls) {
    yield put(onChangeFullyControls(updatedControls));
  }
  yield generateAndShowException(exception, idUsuarioCriado);
}


function* obterFotoHandler(actions) {
  const image = yield select(state => state.image);
  const newImage = { ...image };
  try {
    const { idUsuario } = actions;

    const response = yield call(obterFotoUsuario, idUsuario);
    newImage.imagePreviewUrl = `data:image;base64,${response.data}`;
    yield put(setImage(newImage));
  } catch (e) {
    // Nenhum tratamento até agora...
  }
}

function* insertUsuarioHandler(actions) {
  const allControls = yield select(states => states.controls);
  const { foto } = actions;
  let idUsuarioCriado;
  yield put(onChangeActions('loadingPage', true));
  try {
    const usuarioPaylod = getUsuarioPayload(allControls);
    const { data } = yield insertUsuario(usuarioPaylod);
    idUsuarioCriado = data.idUsuario;

    if (idUsuarioCriado && foto) {
      yield saveFotoUsuario(idUsuarioCriado, foto);
    }
    yield successSubmit(true);
  } catch (exception) {
    yield catchSubmitException(exception, allControls, idUsuarioCriado);
  } finally {
    yield put(onChangeActions('loadingPage', false));
  }
}

function* updateUsuarioHandler(actions) {
  const allControls = yield select(states => states.controls);
  yield put(onChangeActions('loadingPage', true));
  try {
    const { idUsuario, roolBackFunction, foto } = actions;
    const usuarioPaylod = getUsuarioPayload(allControls);

    yield updateUsuario(usuarioPaylod, idUsuario);
    if (idUsuario && foto) {
      yield saveFotoUsuario(idUsuario, foto);
    }
    yield successSubmit(false, roolBackFunction);
  } catch (exception) {
    yield catchSubmitException(exception, allControls);
  } finally {
    yield put(onChangeActions('loadingPage', false));
  }
}

function* buildItensConfiguraveis(data) {
  const itensConfiguraveis = yield buildRelacaoItemsConfiguraveis(data);
  yield put(onAtualizarPermissoes(itensConfiguraveis));
  return itensConfiguraveis;
}

function* buildItensConfiguraveisHandler() {
  yield put(onChangeActions('loadingPage', true));
  try {
    const permissoes = yield call(getPermissoesUsuario);
    yield buildItensConfiguraveis(permissoes.data);
  } catch (exception) {
    // Nenhum tratamento definido...
  } finally {
    yield put(onChangeActions('loadingPage', false));
  }
}

function* buildItensConfiguraveisAtualizado(data, permissoesUsuario) {
  const itensConfiguraveis = yield buildItensConfiguraveis(data);
  const itensAtualizados = yield getItensAtualizados(itensConfiguraveis, permissoesUsuario);
  const acoesUsuario = yield getAcoesUsuarios(0, itensAtualizados);
  yield put(onCheckTitlePermissionCheckbox(acoesUsuario));
  yield put(onAtualizarPermissoes(itensAtualizados));
}

function makeUsuariosParaFormarEquipe(usuario) {
  return {
    id: usuario?.idUsuario,
    label: usuario?.nomeUsuario,
    rightChecked: usuario?.pertenceEquipe,
    currentChecked: false,
  };
}

function* getUsuarioHandler(actions) {
  yield put(onChangeActions('loadingPage', true));
  try {
    const { idUsuario } = actions;

    const usuarioResponse = yield call(getUsuarioCompletoPeloId, idUsuario);
    const membrosResponse = yield call(getUsuariosElegiveisFazerParteEquipeService, idUsuario);
    yield put(buildUsuario(usuarioResponse.data, membrosResponse.data.map(makeUsuariosParaFormarEquipe).filter(membro => membro.rightChecked).map(membro => membro.id)));
    yield put(onChangeActions('listaUsuariosElegiveisEquipe', membrosResponse.data.map(makeUsuariosParaFormarEquipe)));

    const permissoes = yield call(getPermissoesUsuario);

    yield buildItensConfiguraveisAtualizado(permissoes.data, usuarioResponse.data.permissoes);
    yield put(checkEnabledAdvogadoAndAssistenteExterno(usuarioResponse.data.idTipoUsuario));
  } catch (exception) {
    yield generateAndShowException(exception);
  } finally {
    yield put(onChangeActions('loadingPage', false));
  }
}

function* findFilialPrincipalHandler() {
  yield put(onChangeActions('loadingPage', true));
  try {
    const idFilial = yield select(state => state.controls.idFilial);

    const response = yield call(getFilialPrincipal);

    if (idFilial.value === '') {
      yield put(onChangeControls('idFilial', response.data));
    }
  } catch (exception) {
    yield generateAndShowException(exception);
  } finally {
    yield put(onChangeActions('loadingPage', false));
  }
}

function* atualizarTituloListaAcoesUsuarioAtualHandler() {
  const itensConfiguraveis = yield select(state => state.controls.permissoes);
  const indexPermissao = yield select(state => state.actions.indexPermissao);

  const acoesUsuario = yield getAcoesUsuarios(indexPermissao, itensConfiguraveis);
  yield put(onCheckTitlePermissionCheckbox(acoesUsuario));
}

function* atualizarPermissoesUsuarioAposAplicar(permissoes) {
  const itensConfiguraveis = yield select(state => state.controls.permissoes);
  const indexPermissao = yield select(state => state.actions.indexPermissao);

  const newRelacaoItensConfiguraveis = yield buildNewRelacaoItensConfiguraveis(itensConfiguraveis, permissoes);
  const acoesUsuario = yield getAcoesUsuarios(indexPermissao, newRelacaoItensConfiguraveis);

  yield put(onCheckTitlePermissionCheckbox(acoesUsuario));
  yield put(onChangeControls('permissoes', newRelacaoItensConfiguraveis));
}

function* aplicarPermissoes(value, promise) {
  yield put(onChangeActions('loadingAplicar', true));
  try {
    const { data } = yield call(promise, value);
    yield atualizarPermissoesUsuarioAposAplicar(data);
  } catch (exception) {
    yield put(onChangeActions('openErrorComparar', true));
    yield put(onChangeActions('messageErrorComparar', getExceptionMessage(exception)));
  } finally {
    yield put(onChangeActions('loadingAplicar', false));
  }
}

function* aplicarPermissoesHandler(actions) {
  const { baseadoEm, value } = actions;

  if (value === '') {
    toast.error(ERROR_APLICAR_PERMISSOES_EXCEPTION, { style: { width: '392px' } });
  } else {
    const promise = baseadoEm === 'PERFIL' ? getPermissoesUsuarioByIdPerfil : getPermissoesUsuarioByIdUsuario;
    yield aplicarPermissoes(value, promise);
  }
}


function* buildComparadorTable(perfis, usuarios) {
  const columnsComparacao = yield select(state => state.actions.columnsComparacao);
  const nomeLoginUsuario = yield select(state => state.nomeLoginUsuario);
  const perfilPermissaoComparar = yield select(state => state.controls.perfilCompararSelector.getValueNotEmpty());
  const usuarioPermissaoComparar = yield select(state => state.controls.usuarioPermissaoCompararSelector.getValueNotEmpty());

  const nomeSelecionado = yield getNomeSelecionado(perfilPermissaoComparar, usuarioPermissaoComparar, perfis, usuarios);

  const newColumns = [...columnsComparacao];

  newColumns[0].label = nomeLoginUsuario;
  newColumns[1].label = nomeSelecionado;
  yield put(onChangeActions('columnsComparacao', newColumns));
}

function* configureAndBuildRelacaoPermissaoComparacao(comparacaoPermissaoUsuario, relacaoItensConfiguraveis, perfis, usuarios) {
  const relacoesPermissoes = yield buildRelacaoPermissoes(relacaoItensConfiguraveis);
  const permissoesComparadas = yield buildPermissoesComparadas(relacoesPermissoes, comparacaoPermissaoUsuario);

  yield put(onChangeControls('permissoesComparacao', permissoesComparadas));
  yield put(onChangeActions('openComparador', true));
  yield buildComparadorTable(perfis, usuarios);
}

function* compararPermissoesHandler(actions) {
  yield put(onChangeActions('loadingComparar', true));
  const usuarioSelecionado = yield select(state => state.controls.usuarioPermissaoCompararSelector.getValueNotEmpty());
  const perfilSelecionado = yield select(state => state.controls.perfilCompararSelector.getValueNotEmpty());
  const relacaoItensConfiguraveis = yield select(state => state.controls.permissoes);

  if (perfilSelecionado === undefined && usuarioSelecionado === undefined) {
    toast.error(ERROR_COMPARAR_PERMISSOES_EXCEPTION, { style: { width: '392px' } });
    yield put(onChangeActions('loadingComparar', false));
  } else {
    try {
      const permissoesSelecionadas = yield getPermissoesSelecionadas(relacaoItensConfiguraveis);
      const { perfis, usuarios } = actions;

      const { data } = yield call(getComparacaoPermissoesUsuario, perfilSelecionado, usuarioSelecionado, permissoesSelecionadas);
      yield configureAndBuildRelacaoPermissaoComparacao(data, relacaoItensConfiguraveis, perfis, usuarios);
    } catch (exception) {
      yield put(onChangeActions('openErrorComparar', true));
      yield put(onChangeActions('messageErrorComparar', getExceptionMessage(exception)));
    } finally {
      yield put(onChangeActions('loadingComparar', false));
    }
  }
}


// function* getUsuariosElegiveisFazerParteEquipeHandler(actions) {
//   yield put(onChangeActions('loadingPage', true));
//   try {
//     const { idUsuario } = actions;
//
//     const membrosResponse = yield call(getUsuariosElegiveisFazerParteEquipeService, idUsuario);
//    yield put(onChangeControls('idsUsuariosMembros', []))
//
//   } catch (exception) {
//
//   } finally {
//     yield put(onChangeActions('loadingPage', false));
//   }
// }


function* fetchTiposUsuariosHandler() {
  try {
    const [responseComercial, responseAdvogado, responseAdvogadoExterno, responseAdministrativo, responseTelemarketing, responseNegociador, responseNegociadorExterno] = [
      yield call(buscarIdTipoUsuarioComercial),
      yield call(buscarIdTipoUsuarioAdvogado),
      yield call(buscarIdTipoAdvogadoExterno),
      yield call(buscarIdTipoUsuarioAdministrativo),
      yield call(buscarIdTipoUsuarioTelemarketing),
      yield call(buscarIdTipoUsuarioNegociador),
      yield call(buscarIdTipoUsuarioNegociadorExterno),
    ];

    const dataIdsObject = {
      idComercial: responseComercial?.data,
      idAdvogado: responseAdvogado?.data,
      idAdvogadoExterno: responseAdvogadoExterno?.data,
      idAdministrativo: responseAdministrativo?.data,
      idTelemarketing: responseTelemarketing?.data,
      idNegociador: responseNegociador?.data,
      idNegociadorExterno: responseNegociadorExterno?.data,
    };
    yield put(onChangeIdsTiposUsuariosAction(dataIdsObject));
  } catch (e) {
    toast.error('Erro na obtenção dos tipos de usuário.');
  }
}


export default function* watchCrudUsuario() {
  yield takeLatest(OBTER_FOTO, obterFotoHandler);
  yield takeLatest(INSERT_USUARIO, insertUsuarioHandler);
  yield takeLatest(UPDATE_USUARIO, updateUsuarioHandler);
  yield takeLatest(GET_USUARIO, getUsuarioHandler);
  yield takeLatest(APLICAR_PERMISSOES, aplicarPermissoesHandler);
  yield takeLatest(COMPARAR_PERMISSOES, compararPermissoesHandler);
  yield takeLatest(ATUALIZAR_LISTA_PERMISSOES, atualizarTituloListaAcoesUsuarioAtualHandler);
  yield takeLatest(GET_FIND_FILIAL_PRINCIPAL_ACTION, findFilialPrincipalHandler);
  yield takeLatest(BUILD_RELACOES_ITENS_CONFIGURAVEIS, buildItensConfiguraveisHandler);
  yield takeLatest(FETCH_TIPOS_USUARIOS, fetchTiposUsuariosHandler);
}
