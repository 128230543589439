import { getMoneyMask, TableUtils, toBrazilianDate } from '../../helpers';
import ContaCorrenteComissionadoService from '../../services/core/contaCorrenteComissionado/ContaCorrenteComissionadoService';

import store from '../../store/createStore';
import { statusContaCorrenteUsuarioType } from '../../types/statusContaCorrenteUsuarioTypes';


function getLancamentoComissionadoPageableFromStore() {
  const { pageable } = store.getState().previousPageStates.contaCorrenteComissionado;
  return {
    ...pageable,
    number: pageable?.page || 0,
    size: pageable?.size || 10,
    last: pageable?.lastPage || true,
  };
}

function makeLancamentos(httpResponse) {
  return TableUtils.makeTableItems({
    httpResponse,
    makeContentFactory: lancamento => ({
      data: toBrazilianDate(lancamento?.data),
      historico: lancamento?.historico || '',
      idContaCorrenteUsuario: lancamento.idContaCorrenteUsuario,
      idPrestacao: lancamento?.idPrestacao,
      statusContaCorrenteUsuario: lancamento?.statusContaCorrenteUsuario,
      tipoContaCorrenteUsuario: lancamento?.tipoContaCorrenteUsuario,
      valorCredito: getMoneyMask(lancamento?.valorCredito) || '',
      valorDebito: getMoneyMask(lancamento?.valorDebito) || '',
      idStatusContaCorrenteUsuario: lancamento?.idStatusContaCorrenteUsuario || '',
      isLancamentoAutomatico: lancamento?.isLancamentoAutomatico || '',
    }),
  });
}

export const ContaCorrenteComissionadoFactory = {
  makeComissionados(comissionado) {
    return {
      id: String(comissionado?.id),
      isOfUser: comissionado?.isOfUser,
      value: comissionado?.nome,
    };
  },
  makeLancamentos,
  defaultMakeLancamentos() {
    return makeLancamentos(getLancamentoComissionadoPageableFromStore());
  },
};

export const ContaCorrenteComissionadoController = {
  async findAllComissionados(statusUsuario) {
    const responseNome = await ContaCorrenteComissionadoService.findAllComissionados(statusUsuario);
    const nomesResponse = responseNome.data.map(ContaCorrenteComissionadoFactory.makeComissionados);
    const selectedComissionado = responseNome.data
      .find(nome => !!nome.isOfUser)?.id;
    return { comissionados: nomesResponse, selectedComissionado: selectedComissionado || '' };
  },

  async findAllLancamentosComissionados(fields, columnOrder, pageable) {
    const {
      period, idComissionado, exibirLancamentosNaoPrestados,
    } = fields;
    const statusContaCorrente = statusContaCorrenteUsuarioType.isOnlyNaoPrestado(exibirLancamentosNaoPrestados);
    let response = await ContaCorrenteComissionadoService.findAllLancamentos(period, idComissionado, statusContaCorrente, pageable, columnOrder.get());
    if (response.data.content.length === 0 && response.data.number > 0) {
      pageable.page -= 1;
      response = await ContaCorrenteComissionadoService.findAllLancamentos(period, idComissionado, statusContaCorrente, pageable, columnOrder.get());
    }
    const newLancamentos = ContaCorrenteComissionadoFactory.makeLancamentos(response.data);
    return {
      lancamentos: { ...newLancamentos },
    };
  },

  async downloadExtratoLancamentos(fields, columnOrder) {
    const {
      period, idComissionado, exibirLancamentosNaoPrestados,
    } = fields;
    const statusContaCorrente = statusContaCorrenteUsuarioType.isOnlyNaoPrestado(exibirLancamentosNaoPrestados);
    const httpResponse = await ContaCorrenteComissionadoService.downloadExtratoLancamento(period, idComissionado, statusContaCorrente, columnOrder.get());
    return httpResponse;
  },

};
