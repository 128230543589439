import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  setObjectsAction,
  setObjectAttributeAction,
  setObjectAction,
  setObjectFocusAction,
} from '../../../../store/Borderos/Crud/CrudBordero.store';

import {
  useCrudBorderoSelector, useCrudBoderoDispatch,
} from '../../../../store/Borderos/Crud/CrudBorderoProvider';

import Enderecos from '../../../UI/EnderecosV2';

const ENDERECOS = 'enderecos';

export default function EnderecosCrudBordero({ isInspectMode, disabled }) {
  const dispatch = useCrudBoderoDispatch();
  const enderecos = useCrudBorderoSelector(states => states.requestFields.enderecos);

  const estados = useSelector(states => states.selectors.estados.selector);

  const setAttributeHandler = useCallback((name, value, index) => {
    dispatch(setObjectAttributeAction(ENDERECOS, name, value, index));
  }, [dispatch]);

  const setFocusHandler = useCallback((name, index) => {
    dispatch(setObjectFocusAction(ENDERECOS, name, index));
  }, [dispatch]);

  const setEnderecoHandler = useCallback((endereco, index) => {
    dispatch(setObjectAction(ENDERECOS, endereco, index));
  }, [dispatch]);

  const setEnderecosHandler = useCallback((enderecosParam) => {
    dispatch(setObjectsAction(ENDERECOS, enderecosParam));
  }, [dispatch]);

  const getRequiredFields = () => {
    if (isInspectMode) {
      return {};
    }
    return {
      cep: true,
      estado: true,
    };
  };

  return (
    <Enderecos
      enderecos={enderecos}
      disabled={isInspectMode || disabled}
      readMode={isInspectMode}
      estados={estados}
      setAttribute={setAttributeHandler}
      setFocus={setFocusHandler}
      setEndereco={setEnderecoHandler}
      setEnderecos={setEnderecosHandler}
      requiredFields={getRequiredFields()}
    />
  );
}
