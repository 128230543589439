import React, { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import Grid24 from '../../../../../../../components/UI/Grid24';
import { InputField } from '../../../../../../../components';

function DialogEstornoPrestacao({
  handleOnClickCancelEstorno,
  handleEstornarPrestacao,
}) {
  const [disabledConfirmarEstornarPrestacao, setDisabledConfirmarEstornarPrestacao] = useState(true);
  const [motivoEstorno, setMotivoEstorno] = useState('');

  const onChangeMotivoEstornoHandler = useCallback((event) => {
    const { value } = event.target;
    setMotivoEstorno(value);
    if (value.length > 0) {
      setDisabledConfirmarEstornarPrestacao(false);
    } else {
      setDisabledConfirmarEstornarPrestacao(true);
    }
  }, []);

  return (
    <>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={24}>
          <InputField
            fullWidth
            label="Motivo*"
            name="motivo"
            onChange={onChangeMotivoEstornoHandler}
          />
        </Grid24>
      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '22px' }}>
        <Button
          onClick={() => handleOnClickCancelEstorno()}
        >
          Cancelar
        </Button>
        <Button
          onClick={() => handleEstornarPrestacao(motivoEstorno)}
          disabled={disabledConfirmarEstornarPrestacao}
        >
          Confirmar
        </Button>
      </div>
    </>
  );
}
export default DialogEstornoPrestacao;
