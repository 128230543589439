import FieldModel from '../../models/FieldModel';
import { getAttributeExistingObject } from '../utils/utils';

export function buildFaixaTaxaCartao(parametroDescontoBoleto) {
  return {
    idParametroTaxaCartao: new FieldModel({
      value: getAttributeExistingObject(parametroDescontoBoleto, 'idParametroTaxaCartao'),
    }),
    qtdeParcelas: new FieldModel({
      name: 'qtdeParcelas',
      value: getAttributeExistingObject(parametroDescontoBoleto, 'qtdeParcelas'),
    }),
    porcentagemTaxaCartao: new FieldModel({
      name: 'porcentagemTaxaCartao',
      value: getAttributeExistingObject(parametroDescontoBoleto, 'porcentagemTaxaCartao'),
      errorMessage: 'Campo obrigatório',
      maxLength: 5,
      required: true,
    }),
  };
}


export function buildParametroTaxasCartao(taxasCartao) {
  return (taxasCartao) ? taxasCartao.map(buildFaixaTaxaCartao) : [];
}
