import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { GERAR_RELATORIO_AS } from './relatoriosAS.constans';
import { openPagePDF, toAmericanDate } from '../../../helpers/utils/utils';
import {
  gerarRelatorioAcessoSite,
  gerarRelatorioNotificados,
} from '../../../services/core/acordoseguro/relatorios.service';
import { onUpdateRequestFieldsAction, setStoreValuesAction } from './relatoriosAS.store';
import { getExceptionHandler } from '../../../helpers/utils/exception.util';


export const gerarRelatorioAction = isAcessoSite => ({
  type: GERAR_RELATORIO_AS,
  isAcessoSite,
});

function buildRelatorioPayload(dataInicial, dataFinal) {
  return {
    dataInicial: toAmericanDate(dataInicial.getValueNotEmpty()),
    dataFinal: toAmericanDate(dataFinal.getValueNotEmpty()),
  };
}

function* gerarRelatorioHandler(actions) {
  yield put(setStoreValuesAction('loading', true));
  const requestFields = yield select(states => states.relatoriosAS.requestFields);
  const { isAcessoSite } = actions;
  try {
    let response = null;

    if (isAcessoSite) {
      const {
        dataInicial,
        dataFinal,
      } = yield buildRelatorioPayload(requestFields.dataInicialAcesso, requestFields.dataFinalAcesso);
      response = yield call(gerarRelatorioAcessoSite, dataInicial, dataFinal);
    } else {
      const {
        dataInicial,
        dataFinal,
      } = yield buildRelatorioPayload(requestFields.dataInicialNotificado, requestFields.dataFinalNotificado);
      response = yield call(gerarRelatorioNotificados, dataInicial, dataFinal);
    }

    if (response.data.size > 0) {
      yield openPagePDF(response.data);
    } else {
      toast.console.warn('Nenhum resultado foi encontrado para o período selecionado');
    }
  } catch (exception) {
    const [updatedControls] = yield getExceptionHandler(exception, requestFields);
    if (updatedControls) {
      yield put(onUpdateRequestFieldsAction(updatedControls));
    }
  } finally {
    yield put(setStoreValuesAction('loading', false));
  }
}

export default function* watchRelatorioAS() {
  yield takeLatest(GERAR_RELATORIO_AS, gerarRelatorioHandler);
}
