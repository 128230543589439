import { getUriParams } from '../../../helpers';
import { CORE, gateway, RELATORIOS } from '../../api';

export default {
  findBuscarRepasses(
    periodoInicial,
    periodoFinal,
    pageable,
    order,
  ) {
    const body = {
      periodoInicial,
      periodoFinal,
    };
    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    const queryParam = getUriParams(pageable);
    return gateway.post(`${CORE}/conta-corrente-cliente/repasse/buscar-repasses?${queryParam}${sortedQuery}`, body);
  },

  findBuscarRepassesMaxSize(
    periodoInicial,
    periodoFinal,
    order,
  ) {
    const body = {
      periodoInicial,
      periodoFinal,
    };
    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    return gateway.post(`${CORE}/conta-corrente-cliente/repasse/buscar-repasses/size-maximo?${sortedQuery}`, body);
  },

  findLiberarRepasses(idPrestacaoContaCliente) {
    const body = {
      idPrestacaoContaCliente,
    };
    return gateway.post(`${CORE}/conta-corrente-cliente/repasse/liberar-repasses`, body);
  },

  findResumo(
    periodoInicial, periodoFinal,
  ) {
    const body = {
      periodoInicial, periodoFinal,
    };
    return gateway.post(`${CORE}/conta-corrente-cliente/repasse/buscar-total-repasses`, body);
  },

  findBuscarIdsRepasse(
    periodoInicial, periodoFinal,
  ) {
    const body = {
      periodoInicial, periodoFinal,
    };
    return gateway.post(`${CORE}/conta-corrente-cliente/repasse/ids-repasses`, body);
  },

  findBuscarTotalRepasse(
    periodoInicial, periodoFinal,
  ) {
    const body = {
      periodoInicial, periodoFinal,
    };
    return gateway.post(`${CORE}/conta-corrente-cliente/repasse/valor-total-repasses`, body);
  },

  findBuscarAutorizacaoRepasse(
    listarUltimosTrintaDias,
    listarSomenteNaoConcluidos,
    pageable,
    order,
  ) {
    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    const queryParam = getUriParams(pageable);
    return gateway.get(`${CORE}/conta-corrente-cliente/repasse/autorizacoes-repasses/${listarUltimosTrintaDias}/${listarSomenteNaoConcluidos}?${queryParam}${sortedQuery}`);
  },

  alterarStatusDeLidoAutorizacaoDeRepasse(idAutorizacaoRepasse) {
    const params = getUriParams({ idAutorizacaoRepasse });
    return gateway.put(`${CORE}/conta-corrente-cliente/repasse/lido?${params}`);
  },

  downloadArquivoAutorizacaoRepasse(idAutorizacaoRepasse, tipoArquivo) {
    return gateway.post(`${CORE}/conta-corrente-cliente/repasse/exportar/${idAutorizacaoRepasse}?tipoExportacaoEnum=${tipoArquivo}`, { responseType: 'blob' });
  },

  findIdsReoasses(
    periodoInicial,
    periodoFinal,
  ) {
    const body = {
      periodoInicial,
      periodoFinal,
    };
    return gateway.post(`${CORE}/conta-corrente-cliente/repasse/ids-repasses`, body);
  },

  liberarRepasses(
    idsPrestacoesContaCliente,
  ) {
    const params = getUriParams({ idsPrestacoesContaCliente });
    return gateway.post(`${CORE}/conta-corrente-cliente/repasse/liberar-repasses?${params}`);
  },

  downloadRelatorioRepasses(
    periodoInicial,
    periodoFinal,
    order,
  ) {
    const body = {
      periodoInicial,
      periodoFinal,
    };
    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    return gateway.post(`${RELATORIOS}/conta-corrente-cliente/relatorio/cliente/repasses?${sortedQuery}`, body,
      {
        responseType: 'blob',
      });
  },
};
