import styled from 'styled-components';
import { Grid, IconButton } from '@material-ui/core';
import Grid24 from '../../../components/UI/Grid24/Grid24';
import { CustomButton } from '../InspecionarProcessoStyles';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FieldsContainer = styled.div`
  width: 100%;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
`;

export const BottomGrid = styled(Grid24)`
  margin-top: 8px;
`;

export const ActionsButtons = styled(Grid)`
  margin-top: 20px;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;

  button + button {
    margin-left: 30px;
  }
`;

export const SearchButton = styled(IconButton)`
  margin-left: 42px;
  background-color: #FF1381;
  color: white;

  :hover {
    background-color: #FF1381;
    opacity: 0.64;
  }
`;

export const ClearButtonAcompanhamento = styled(CustomButton)`
  height: 28px;
`;

export const AcompanhamentoGrid = styled(Grid)`
  margin-top: 34px;
  padding-left: 12px;
  border-radius: 7px;
  border: 1px solid #707070;
`;

export const VisualizarAPartirDeContainer = styled(Grid24)`
  display: flex;
  justify-content: space-around;
  flex-basis: content;

  .MuiInputBase-root  {
    background-color: white;
  }

  :first-child {
    padding-top: 24px;
    padding-left: 12px;
    color: #707070;

    label {
      margin-right: 4px;
    }
  }
`;
