import { makeStyles } from '@material-ui/core/styles';
import { blueGradientColor } from '../../../../../assets/jss/material-dashboard-pro-react';

export default makeStyles({
  areaSize: {
    '& textarea': {
      minHeight: '80px',
      width: '100% !important ',
    },
  },
  iconButton: {
    bottom: '0',
    marginLeft: '8px',
    position: 'absolute',
    color: 'white',
    backgroundImage: `linear-gradient(180deg, ${blueGradientColor[0]} 0%, ${blueGradientColor[1]} 100%)`,
  },
  svgIcon: {
    paddingBottom: '3px',
    paddingLeft: '3px',
  },
  labelSize: {
    '& .MuiFormControlLabel-label': {
      fontSize: '14px !important',
    },
  },
  mainContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    marginTop: '16px',
  },
  copiaAnotacoesContainer: {
    paddingLeft: '11px',
    paddingRight: '39px',
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
    },
  },
  dataContainer: {
    marginRight: '14px',
    fontSize: '14px',
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
    },
  },
});
