import { css } from 'styled-components';

export const navigationBarWidth = {
  open: 260,
  onlyIcons: 96,
  close: 0,
};

export const navigationButtonTransition = css`
  transition: all 0.2s linear;
`;

export const navigationButtonStyles = css`
  position: relative;
  display: block;

  width: 100%;
  height: 48px;
  padding: 0px 16px;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;

  font-family: 'Roboto';
  font-size: 14px;
  color: ${props => (props?.$isDarkMode ? 'white' : 'black')};

  &:hover {
    color: ${props => (props?.$isDarkMode ? 'white' : 'black')};
    background-color: #80808069;

    i {
      color: ${props => (props?.$isDarkMode ? props.$activeIconColor : '#707070')};
      svg {
        color: ${props => (props?.$isDarkMode ? props.$activeIconColor : '#707070')}
      }
    }
  }

  &.active {
    color: white;
    background-color: #707070;
    box-shadow: -2px 4px 4px rgba(90, 62, 62, 0.4);

    i {
      color: ${props => props.$activeIconColor};
      svg {
        color: ${props => props.$activeIconColor};
      }
    }

    span {
      color: white;
    }
  }
`;

export const navigationIconStyles = css`
  position: absolute;
  top: 24px;
  left: 32px;
  transform: translate(-50%, -50%);

  color: ${props => props.$defaultIconColor};
  svg {
    color: ${props => props.$defaultIconColor};
  }
`;
