import React, { useMemo } from 'react';
import cx from 'classnames';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import {
  ListBox, Root, SwapButtons, SwapRoot,
} from './SwapList.styles';
import SubTitleForm from '../../../UI/SubTitleForm/SubTitleForm';
import CheckboxField from '../../../UI/Field/Checkbox/CheckboxField';
import useStyles from './SwapList.styles.error';

function SwapList({
  leftTitle = '',
  rightTitle = '',
  parentList = [],
  setParentList = () => {
  },
  addPayloadList = () => {},
  removePayloadList = () => {},
  disabled = false,
  error = false,
}) {
  const styles = useStyles();
  const leftList = useMemo(() => parentList.filter(parent => !parent.rightChecked), [parentList]);

  const rightList = useMemo(() => parentList.filter(parent => !!parent.rightChecked), [parentList]);

  const disabledLeft = leftList.filter(left => left.currentChecked).length === 0;
  const disabledRight = rightList.filter(right => right.currentChecked).length === 0;

  const rightListStyle = cx(
    {
      [styles.errorSwapRight]: error,
    },
  );

  const onchangeChecked = (e) => {
    const { name, checked } = e.target;
    setParentList(parentList.map((parent) => {
      if (String(parent.id) === name) {
        parent.currentChecked = checked;
      }
      return parent;
    }));
  };

  const clickSwap = (isGoingRight) => {
    if (isGoingRight) {
      setParentList(parentList.map((parent) => {
        if (parent.currentChecked && !parent.rightChecked) {
          parent.rightChecked = true;
          parent.currentChecked = false;
          if (addPayloadList) {
            addPayloadList(parent.id);
          }
        }
        return parent;
      }));
    } else {
      setParentList(parentList.map((parent) => {
        if (parent.currentChecked && parent.rightChecked) {
          parent.rightChecked = false;
          parent.currentChecked = false;
          if (removePayloadList) {
            removePayloadList(parent.id);
          }
        }
        return parent;
      }));
    }
  };

  return (
    <Root>
      <div>
        <SubTitleForm>{leftTitle}</SubTitleForm>
        <ListBox>
          {leftList.map(esq => (
            <div key={esq.id}>
              <CheckboxField
                name={String(esq.id)}
                label={esq.label}
                checked={esq.currentChecked}
                disabled={disabled}
                onChange={onchangeChecked}
              />
            </div>
          ))}
        </ListBox>
      </div>

      <SwapRoot>
        <SwapButtons disabledLeft={disabled || disabledLeft} disabledRight={disabled || disabledRight}>
          <button type="button" disabled={disabled || disabledRight} onClick={() => clickSwap(false)}>
            <AiOutlineLeft />
          </button>
          <button type="button" disabled={disabled || disabledLeft} onClick={() => clickSwap(true)}>
            <AiOutlineRight />
          </button>

        </SwapButtons>
      </SwapRoot>
      <div>
        <SubTitleForm>{rightTitle}</SubTitleForm>
        <div className={rightListStyle}>
          <ListBox>
            {rightList.map(dir => (
              <div key={dir.id}>
                <CheckboxField
                  name={String(dir.id)}
                  label={dir.label}
                  checked={dir.currentChecked}
                  disabled={disabled}
                  onChange={onchangeChecked}
                />
              </div>
            ))}
          </ListBox>
        </div>
      </div>
    </Root>
  );
}

export default React.memo(SwapList);
