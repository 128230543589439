import React from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const allowRouteAccess = (userInformation, roles, tipo, tipoExpected) => {
  if (roles.length !== 0) {
    return (
      roles.some(rule => userInformation.permissoes.indexOf(rule) !== -1) && tipo === tipoExpected
    );
  }
  return true;
};

function PrivateRoute({
  component: Component, roles, tipoUsuario, ...rest
}) {
  const userInformation = useSelector(state => state.authentication.userInformation);
  const rendered = (props) => {
    if (
      userInformation
      && allowRouteAccess(userInformation, roles, userInformation.tipoUsuario, tipoUsuario)
    ) {
      return <Component key={uuid} {...props} />;
    }
    return <Redirect to="/login" />;
  };

  return <Route {...rest} render={rendered} />;
}

PrivateRoute.defaultProps = {
  roles: [],
  tipoUsuario: '',
};

PrivateRoute.propTypes = {
  roles: PropTypes.array,
  tipoUsuario: PropTypes.string,
};

export default PrivateRoute;
