import { RadioGroup } from '@material-ui/core';
import styled from 'styled-components';

export const SelectionGroup = styled(RadioGroup)`
    margin-left: 8px;

    span {
      margin-right: 4px;
    }

    > label {
      width: fit-content;
    }

    >label + label {
      margin-top: 13px;
    }

`;

export const ProcessoNaoTrabalhadoFiltros = styled.div`
margin: 8px 20px 16px 8px;

  >div + div {
    padding-top: 16px;
  }
`;

export const ProcessoExpiradosFiltros = styled.div`
  margin: 10px 20px 16px 8px;

  > div + div {
    margin-top: 16px;
  }
`;

export const ProcessosDevedorEspecificoFiltros = styled.div`
  margin: 10px 20px 16px 8px;

  > div + div {
    margin-top: 16px;
  }
`;

export const PocessoComPrazoEmPeriodoFiltros = styled.div`
  margin: 10px 20px 16px 8px;

  > div + div {
    margin-top: 16px;
  }
`;
