import React from 'react';
import FormWrapperTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import Grid24 from '../../../../../UI/Grid24/Grid24';
import CustomFieldTitle from '../../../../../UI/CustomFields/CustomFieldTitle';
import { getRequiredLabel } from '../../../../../../helpers/utils/utils';
import NumberInput from '../../../../../UI/Field/Number/NumberInput';
import { useCrudParametrosSelector } from '../../../../../../store/Parametros/Crud/CrudParametrosProvider';
import {
  PRAZO_RECALCULO_FIELD_TITLE,
  REGRAS_PROCESSAMENTO_BORDERO_TITLE,
} from './regrasProcessamentoBordero.constants';

function RegrasProcessamentoBordero({
  isInsertMode,
  editVisible,
  onChange,
  onFocus,
}) {
  const prazoRecalculo = useCrudParametrosSelector(state => state.requestFields.prazoRecalculo.copy());

  return (
    <FormWrapperTitle title={REGRAS_PROCESSAMENTO_BORDERO_TITLE}>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={11}>
          <Grid24 xs={24} sm={24}>
            <CustomFieldTitle textField={getRequiredLabel(PRAZO_RECALCULO_FIELD_TITLE, isInsertMode)} />
          </Grid24>
          <Grid24 xs={24} sm={10}>
            <NumberInput
              isForm
              fullWidth
              disabled={editVisible}
              name={prazoRecalculo.name}
              value={prazoRecalculo.value}
              error={prazoRecalculo.error}
              errorMessage={prazoRecalculo.errorMessage}
              onChange={onChange}
              onFocus={onFocus}
              maxLength={3}
              sideText="(dias)"
            />
          </Grid24>
        </Grid24>

        <Grid24 xs={24} sm={7} />
      </Grid24>
    </FormWrapperTitle>
  );
}

export default React.memo(RegrasProcessamentoBordero);
