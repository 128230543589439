import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useCrudClienteDispatch,
  useCrudClienteSelector,
} from '../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import {
  onAddUsuarios,
  setObjectAttributeAction,
  setObjectFocusAction,
  setObjectsAction,
} from '../../../../../../store/Cliente/V2/Crud/crudCliente.store';
import UsuariosCliente from './UsuariosCliente';
import { buildSelectorStatus } from '../../../../../../helpers/factory/global.factory';
import { findPerfisUsuarioClienteAction } from '../../../../../../store/Global/LoadSelectors/loadSelectors.saga';

export default function UsuariosCrudCliente({ isEditMode, showPassword }) {
  const dispatch = useCrudClienteDispatch();
  const globalDispatch = useDispatch();

  const usuarios = useCrudClienteSelector(states => states.controls.usuarios);
  const perfisUsuarioCliente = useSelector(states => states.selectors.perfisUsuarioCliente.selector);
  const status = buildSelectorStatus();

  useEffect(() => {
    globalDispatch(findPerfisUsuarioClienteAction());
  }, [globalDispatch]);

  const onAddusuarioHandler = useCallback(() => {
    dispatch(onAddUsuarios());
  }, [dispatch]);

  const setAttributeHandler = useCallback((name, value, index) => {
    dispatch(setObjectAttributeAction('usuarios', name, value, index));
  }, [dispatch]);

  const setFocusHandler = useCallback((name, index) => {
    dispatch(setObjectFocusAction('usuarios', name, index));
  }, [dispatch]);

  const setObjectsHandler = useCallback((param) => {
    dispatch(setObjectsAction('usuarios', param));
  }, [dispatch]);

  return (
    <UsuariosCliente
      isEditMode={isEditMode}
      showPassword={showPassword}
      usuarios={usuarios}
      perfisUsuarioCliente={perfisUsuarioCliente}
      status={status}
      onAddUsuario={onAddusuarioHandler}
      setAttribute={setAttributeHandler}
      setFocus={setFocusHandler}
      setUsuariosCliente={setObjectsHandler}
    />
  );
}
