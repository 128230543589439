import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function ConfirmacaoBoletoIcon(props) {
  const path = 'M8.5085 0H2.33333C1.7145 0 1.121 0.237053 0.683417 0.65901C0.245833 1.08097 0 1.65326 0 2.25V15.75C0 16.3467 0.245833 16.919 0.683417 17.341C1.121 17.7629 1.7145 18 2.33333 18H11.6667C12.2855 18 12.879 17.7629 13.3166 17.341C13.7542 16.919 14 16.3467 14 15.75V5.29538C13.9999 4.99703 13.877 4.71093 13.6582 4.5L9.33333 0.329625C9.11459 0.118632 8.8179 6.37171e-05 8.5085 0ZM8.75 3.9375V1.6875L12.25 5.0625H9.91667C9.60725 5.0625 9.3105 4.94397 9.09171 4.733C8.87292 4.52202 8.75 4.23587 8.75 3.9375ZM10.3297 8.83575L6.82967 12.2108C6.77548 12.2631 6.71111 12.3047 6.64024 12.3331C6.56937 12.3614 6.4934 12.376 6.41667 12.376C6.33994 12.376 6.26396 12.3614 6.19309 12.3331C6.12223 12.3047 6.05785 12.2631 6.00367 12.2108L4.25367 10.5233C4.19943 10.471 4.15641 10.4089 4.12706 10.3405C4.0977 10.2722 4.0826 10.199 4.0826 10.125C4.0826 10.051 4.0977 9.9778 4.12706 9.90947C4.15641 9.84114 4.19943 9.77905 4.25367 9.72675C4.3632 9.62113 4.51176 9.56179 4.66667 9.56179C4.74337 9.56179 4.81932 9.57636 4.89018 9.60466C4.96104 9.63297 5.02543 9.67445 5.07967 9.72675L6.41667 11.0171L9.50367 8.03925C9.6132 7.93363 9.76176 7.87429 9.91667 7.87429C10.0716 7.87429 10.2201 7.93363 10.3297 8.03925C10.4392 8.14487 10.5007 8.28813 10.5007 8.4375C10.5007 8.58687 10.4392 8.73013 10.3297 8.83575Z';
  return (
    <SvgIcon transform="scale(1.3)" viewBox="-6 -6 30 30">
      <path d={path} {...props} />
    </SvgIcon>
  );
}
