import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function EstornarIcon(props) {
  const path = 'M12.4444 11.5854C13.4311 11.5854 14.32 12.0024 14.96 12.6698L16 11.5854V15.2927H12.4444L14.0178 13.6522C13.6976 13.3183 13.2873 13.0943 12.8416 13.0102C12.396 12.9262 11.9364 12.9861 11.5244 13.182C11.1123 13.3778 10.7675 13.7003 10.5361 14.1061C10.3046 14.512 10.1976 14.9819 10.2294 15.4529C10.2612 15.9239 10.4302 16.3735 10.7138 16.7416C10.9975 17.1097 11.3822 17.3786 11.8165 17.5124C12.2508 17.6462 12.7139 17.6385 13.1439 17.4902C13.5738 17.342 13.9501 17.0603 14.2222 16.6829H15.7422C15.4774 17.3684 15.0218 17.9556 14.434 18.3685C13.8462 18.7815 13.1532 19.0015 12.4444 19C10.48 19 8.88889 17.341 8.88889 15.2927C8.88889 13.2444 10.48 11.5854 12.4444 11.5854ZM8 6.4878H12.8889L8 1.39024V6.4878ZM1.77778 0H8.88889L14.2222 5.56097V9.42585C13.7778 9.3239 13.3333 9.26829 12.8889 9.26829C11.3565 9.26829 9.88693 9.903 8.80338 11.0328C7.71984 12.1626 7.11111 13.6949 7.11111 15.2927C7.11111 16.4883 7.44 17.6097 8 18.5366H1.77778C0.782222 18.5366 0 17.7024 0 16.6829V1.85366C0 0.824878 0.791111 0 1.77778 0Z';
  return (
    <SvgIcon transform="scale(1.13)" viewBox="-4 -4 30 30">
      <path d={path} {...props} />
    </SvgIcon>
  );
}
