export function converterBlobToJson(blob) {
  return new Promise((resolve) => {
    const fr = new FileReader();
    fr.onload = (e) => {
      const enc = new TextDecoder('utf-8');
      const data = JSON.parse(enc.decode(new Uint8Array(e.target.result)));
      resolve(data);
    };
    fr.readAsArrayBuffer(blob);
  });
}
