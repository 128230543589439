import styled from 'styled-components';
import { TitleActionButton } from '../TitleActionButton/TitleActionButton';
import SubTitleForm from '../UI/SubTitleForm/SubTitleForm';
import { blue } from '../../helpers/constants/colors.constants';

export const DashboardChangeCalendarEventsContainer = styled.div`
  padding: 20px;

  h2 {
    display: block;
    margin-left: 16px;

    color: ${blue};
    font-size: 16px;
    font-weight: bold;
  }
`;

export const CalendarContext = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  margin-top: 17px auto;
`;

export const CalendarEvents = styled.div`
  max-height: calc(100vh - 218px);
  overflow-y: auto;
  box-sizing: border-box;
  padding: 8px 10px;
  border: 1px solid #707070;

  > div + div {
    margin-top: 8px;
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    outline: 1px solid #cec8c8;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const SubTitle = styled(SubTitleForm)`
  margin-top: 12px;
`;

export const TitleAddButton = styled(TitleActionButton)`
  margin-top: 18px;
  color: ${blue};
  font-weight: bold;
`;
