import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  informativo_icon: {
    fontSize: '18px',
    color: '#034DDC',
    marginTop:
     '5px',
  },
  advertencia_icon: {
    fontSize: '18px',
    color: '#FFA500',
    marginTop: '5px',
  },
  error_icon: {
    fontSize: '18px',
    color: '#FF000F',
    marginTop: '5px',
  },
});
