import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Paper, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import useUtilStyles from '../../../../helpers/styles/utils.styles';
import TableHeader from '../../../../models/TableHeader';
import useStyles from './TabelaDevedorGrupo.style';
import { formatCpfCnpj } from '../../../../helpers/utils/utils';
import TooltipUI from '../../../../components/UI/Tooltip/Tooltip';
import Grid24 from '../../../../components/UI/Grid24';

function TableDevedorGrupo({ content }) {
  const utilStyles = useUtilStyles();
  const styles = useStyles();


  const [columns] = useState([
    new TableHeader({
      id: 'idDevedor', label: 'Código', alignCell: 'center',
    }),
    new TableHeader({
      id: 'razaoSocial', label: 'Nome', showTip: true, minWidth: 'auto', width: 157, maxWidth: 157,
    }),
    new TableHeader({
      id: 'nomeFantasia', label: 'Nome Fantasia', showTip: true, width: 157, maxWidth: 157,
    }),
    new TableHeader({
      id: 'cnpjCpf', label: 'CPF / CNPJ', minWidth: 'auto', alignCell: 'center',
    }),
    new TableHeader({ id: 'cidade', label: 'Cidade', minWidth: 'auto' }),
    new TableHeader({
      id: 'idEstado', label: 'Estado', minWidth: 'auto', alignCell: 'center',
    }),
  ]);

  const rows = content?.map(devedoresGrupo => ({
    id: devedoresGrupo.idDevedor,
    values: {
      idDevedor: devedoresGrupo.idDevedor,
      razaoSocial: devedoresGrupo.razaoSocial,
      nomeFantasia: devedoresGrupo.nomeFantasia,
      cnpjCpf: formatCpfCnpj(devedoresGrupo.cnpjCpf),
      cidade: devedoresGrupo.cidade || '-',
      idEstado: devedoresGrupo.idEstado,
    },
  }));

  const getContent = (value, column) => {
    if (column.showTip) {
      return (
        <TooltipUI title={value} placement="bottom-start" interactive>
          <div className={styles.fitContent}>
            <pre
              className={styles.overFlowText}
              style={{ maxWidth: column.maxWidth - 16 }}
            >
              {column.format && typeof value === 'number' ? column.format(value) : value}
            </pre>
          </div>
        </TooltipUI>
      );
    }
    return (
      <pre>
        {column.format && typeof value === 'number' ? column.format(value) : value}
      </pre>
    );
  };

  const buildTableRow = row => (
    <TableRow>
      {columns.map((column) => {
        const value = row.values[column.id];
        return (
          <TableCell
            key={column.id}
            align={column.alignCell}
            style={{ textAlign: value === '-' ? 'center' : 'auto' }}
          >
            {getContent(value, column)}
          </TableCell>
        );
      })}
    </TableRow>
  );

  return (
    <>
      <Grid24 container isFormContainer spacing={1} className={utilStyles.mt16}>
        <Grid24 item xs={24} sm={24}>
          <Paper className={styles.wrapper}>
            <Table
              stickyHeader
              size="small"
            >
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      id={column.id}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map(row => buildTableRow(row))}
              </TableBody>
            </Table>
          </Paper>
        </Grid24>
      </Grid24>
    </>
  );
}

export default withRouter(TableDevedorGrupo);
