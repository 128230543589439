import React from 'react';
import { useDispatch } from 'react-redux';
import withTokenInterceptor from '../../hoc/withTokenInterceptor/withTokenInterceptor';
import { initializePageAction } from '../../store/theme.actions';
import TextosPadrao from '../../components/TextoPadrao/TextosPadrao';
import TextosPadraoProvider from '../../store/TextosPadrao/TextosPadraoProvider';


function TextosPadraoPage() {
  const dispatch = useDispatch();

  dispatch(initializePageAction('Textos Padrão'));

  return (
    <TextosPadraoProvider>
      <TextosPadrao />
    </TextosPadraoProvider>
  );
}

export default withTokenInterceptor(TextosPadraoPage);
