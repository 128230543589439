import React from 'react';
import InputField from '../../../../UI/Field/Input/InputField';
import SwitchUI from '../../../../UI/Switch/SwitchUI';

import FormWrapperTitle from '../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import useUtilStyles from '../../../../../helpers/styles/utils.styles';
import { useCrudUsuarioSelector2 } from '../../../../../store/Usuario/V2/CrudUsuarioProvider';
import { getRequiredLabel } from '../../../../../helpers/utils/utils';
import {
  BLOQUEIO_ACESSO, DADOS_ACESSO, NOME_LOGIN, SENHA,
} from './dadosAcesso.constants';

function DadosAcesso({
  disabled = false,
  showPassword = false,
  changeHandler,
  onFocusHandler,
}) {
  const utilStyles = useUtilStyles();

  const login = useCrudUsuarioSelector2(state => state.controls.login.copy());
  const senha = useCrudUsuarioSelector2(state => state.controls.senha.copy());
  const permitirLogar = useCrudUsuarioSelector2(state => state.controls.permitirLogar.copy());

  return (
    <FormWrapperTitle title={DADOS_ACESSO}>
      <div className={utilStyles.mt7}>
        <InputField
          inputProps={{
            autoComplete: 'off',
          }}
          onCopy
          isForm
          fullWidth
          disabled={disabled}
          label={getRequiredLabel(NOME_LOGIN, !disabled)}
          name="nome do usuario editado"
          value={login.value}
          error={login.error}
          errorMessage={login.errorMessage}
          onChange={changeHandler}
          onFocus={onFocusHandler}
          maxLength={login.maxLength}
        />
      </div>
      <div className={utilStyles.mt7}>
        <InputField
          inputProps={{
            style: {
              textSecurity: 'disc',
              WebkitTextSecurity: 'disc',
              fontFamily: 'password-font',
            },
            autoComplete: 'off',
            fontFamily: 'password-font',
          }}
          isForm
          fullWidth
          disabled={disabled}
          label={getRequiredLabel(SENHA, !disabled)}
          type="text"
          name="senha do usuario editada"
          value={senha.value}
          error={senha.error}
          errorMessage={senha.errorMessage}
          onChange={changeHandler}
          onFocus={onFocusHandler}
          maxLength={senha.maxLength}
        />
      </div>
      <SwitchUI
        disabled={disabled}
        label={BLOQUEIO_ACESSO}
        name={permitirLogar.name}
        checked={permitirLogar.value}
        onChange={changeHandler}
      />
    </FormWrapperTitle>
  );
}

export default React.memo(DadosAcesso);
