import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  div__buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px 0',
  },
  antecedenteAtivo: {
    '& i': {
      color: '#E400B2',
      display: 'flex',
      paddingLeft: '1px',
    },
  },
  antecedenteInativo: {
    '& i': {
      color: '#707070',
      display: 'flex',
      paddingLeft: '1px',
    },
  },
  button: {
    width: '200px',
  },
  buttonDistribuir: {
    width: '200px',
    marginLeft: '38px',
  },
  div__footer: {
    display: 'flex',
    justifyContent: 'center',
  },
  div__text: {
    fontSize: '16px',
    textAlign: 'center',
    marginBottom: '46px',
  },
});
