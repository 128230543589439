import React from 'react';
import { v4 as uuid } from 'uuid';
import {
  FaCircle, FaClock, FaFlag, FaHourglassHalf,
} from 'react-icons/fa';

import TableHeader from '../models/TableHeader';
import OrdenacaoColuna from '../components/UI/Tabela/Ordenacao/OrdenacaoColuna';

import { makeMoney } from '../helpers/utils/masks.util';
import FieldModel from '../models/FieldModel';
import {
  AlertaCompromissoProcesso, AlertaComRequisicao, AlertaProcessoNaoTrabalhado, AlertaVencimentoProcesso,
} from '../components/UI/AlertasProcesso/AlertasProcesso';
import { toAmericanDate, toBrazilianDate } from '../helpers/utils/utils';
import CheckboxSelecionarTodos from '../components/UI/Tabela/CheckboxSelecionarTodos/CheckboxSelecionarTodos';

import store from '../store/createStore';
import {
  buscarIdsAvancadoProcessos,
  buscarIdsProcessos, buscarIdsProcessosComNotificacoes, buscarProcessoComFiltrosAvancada, buscarProcessoComFiltrosEspecial, buscarProcessoComFiltrosSimples,
  pesquisarProcessosComNotificacoes,
} from '../services/core/processos/processosService';

export const MEUS_PROCESSOS = 'meusProcessos';
export const MINHAS_REQUISICOES = 'processosComMinhasRequisicoes';
export const PROCESSOS_AJUIZADOS = 'processosAjuizados';

class PesquisaProcessoController {
  static PESQUISA_AVANCADA_TITLE = 'Pesquisa Avançada';

  static PESQUISA_ESPECIAL_TITLE = 'Pesquisas Especiais';

  static PESQUISA_SIMPLES = 'SIMPLE';

  static PESQUISA_AVANCADA = 'ADVANCED';

  static PESQUISA_ESPECIAL = 'SPECIAL';

  constructor() {
    this.type = null;
    this.orderView = null;
    this.filters = {};
    this.payload = {};
    this.page = 0;
    this.pageSize = 15;
    this.order = ['nroProcesso,ASC'];
    this.getAdvancedSearchDropdownItems = this.getAdvancedSearchDropdownItems.bind(this);
    this.makeTypeSearch = this.makeTypeSearch.bind(this);
    this.defaultFilters = this.getDataFromForm.bind(this);
    this.lastSelectionSpecialSearch = '';
  }

  init() {
    this.type = null;
    this.orderView = null;
    this.filters = {};
    this.payload = {};
    this.page = 0;
    this.pageSize = 15;
    this.order = ['nroProcesso,ASC'];
    this.lastSelectionSpecialSearch = '';
  }

  makeDefaultFiltersFieldsAdvancedSearch() {
    return {
      numeroProcesso: {
        key: uuid(),
        value: this.filters?.numeroProcesso || '',
      },
      codigoCliente: {
        key: uuid(),
        value: this.filters?.codigoCliente || '',
      },
      idDevedor: {
        key: uuid(),
        value: this.filters?.idDevedor || '',
      },
      codigoDevedorNoCliente: {
        key: uuid(),
        value: this.filters?.codigoDevedorNoCliente || '',
      },
      cpfCnpjCliente: {
        key: uuid(),
        value: this.filters?.cpfCnpjCliente || '',
      },
      cpfCnpjDevedor: {
        key: uuid(),
        value: this.filters?.cpfCnpjDevedor || '',
      },
      nomeDevedor: {
        key: uuid(),
        value: this.filters?.nomeDevedor || '',
      },
      nomeCliente: {
        key: uuid(),
        value: this.filters?.nomeDoCliente || '',
      },
      cidadeDevedor: {
        key: uuid(),
        value: this.filters?.cidadeDevedor || '',
      },
    };
  }

  makeDefaultDadosPesquisa() {
    return {
      content: [],
      pageable: {
        page: 0,
        size: 15,
      },
      totalElements: 0,
    };
  }

  makeOrdenacao(isFirstSearch = false) {
    let element = '';
    if (isFirstSearch) {
      element = 'nroProcesso';
    }
    if (this.orderView) {
      return this.orderView;
    }

    return {
      column: {
        element,
        order: 'ASC',
      },
      arrow: false,
    };
  }

  makeCabecalhoTabela(order, applyOrder, selectAll = { checked: false, onChange: () => { } }) {
    return [
      new TableHeader({
        id: 'checkbox',
        checkbox: true,
        label: (
          <CheckboxSelecionarTodos
            checked={selectAll.checked}
            onChange={selectAll.onChange}
          />),
        width: 16,
      }),
      new TableHeader({
        id: 'nroProcesso',
        label: <OrdenacaoColuna
          idColumn="nroProcesso"
          columnLabel="Número"
          arrowOrdenacao={order.arrow}
          columnOrdenacao={order.column}
          onClickColumn={() => applyOrder('nroProcesso')}
        />,
        maxWidth: 70,
        alignCell: 'center',
      }),
      new TableHeader({
        id: 'corAlertaFase',
        label: <OrdenacaoColuna
          idColumn="proporcaoFase"
          columnLabel={<FaHourglassHalf />}
          arrowOrdenacao={order.arrow}
          columnOrdenacao={order.column}
          onClickColumn={() => applyOrder('proporcaoFase')}
        />,
        alignCell: 'center',
        maxWidth: 34,
      }),
      new TableHeader({
        id: 'naoTrabalhado',
        label: <OrdenacaoColuna
          idColumn="processoTrabalhado"
          columnLabel={<FaCircle size={8} />}
          arrowOrdenacao={order.arrow}
          columnOrdenacao={order.column}
          onClickColumn={() => applyOrder('processoTrabalhado')}
        />,
        alignCell: 'center',
        maxWidth: 34,
      }),
      new TableHeader({
        id: 'comRequisicao',
        label: <OrdenacaoColuna
          idColumn="idRequisicaoProcesso"
          columnLabel={<FaFlag />}
          arrowOrdenacao={order.arrow}
          columnOrdenacao={order.column}
          onClickColumn={() => applyOrder('idRequisicaoProcesso')}
        />,
        alignCell: 'center',
        maxWidth: 34,
      }),
      new TableHeader({
        id: 'compromisso',
        label: <OrdenacaoColuna
          idColumn="compromisso"
          columnLabel={<FaClock />}
          arrowOrdenacao={order.arrow}
          columnOrdenacao={order.column}
          onClickColumn={() => applyOrder('compromisso')}
        />,
        alignCell: 'center',
        maxWidth: 34,
      }),
      new TableHeader({
        id: 'nomeFase',
        label: <OrdenacaoColuna
          idColumn="nomeFase"
          columnLabel="Fase atual do Processo"
          arrowOrdenacao={order.arrow}
          columnOrdenacao={order.column}
          onClickColumn={() => applyOrder('nomeFase')}
        />,
        alignCell: 'center',
        maxWidth: 150,
      }),
      new TableHeader({
        id: 'nomeDevedor',
        label: <OrdenacaoColuna
          idColumn="nomeDevedor"
          columnLabel="Nome do Devedor"
          arrowOrdenacao={order.arrow}
          columnOrdenacao={order.column}
          onClickColumn={() => applyOrder('nomeDevedor')}
        />,
        maxWidth: 150,
        hiddenFullLine: true,
        showTip: true,
      }),
      new TableHeader({
        id: 'nomeCliente',
        label: <OrdenacaoColuna
          idColumn="nomeCliente"
          columnLabel="Nome do Cliente"
          arrowOrdenacao={order.arrow}
          columnOrdenacao={order.column}
          onClickColumn={() => applyOrder('nomeCliente')}
        />,
        maxWidth: 150,
        hiddenFullLine: true,
        showTip: true,
      }),
      new TableHeader({
        id: 'valorProcesso',
        label: <OrdenacaoColuna
          idColumn="valorProcesso"
          columnLabel="Valor Principal"
          arrowOrdenacao={order.arrow}
          columnOrdenacao={order.column}
          onClickColumn={() => applyOrder('valorProcesso')}
        />,
        maxWidth: 128,
        alignCell: 'flex-end',
      }),
    ];
  }

  makeConteudoTabela(processos, checkboxEvent) {
    if (!processos || processos.length === 0) {
      return [];
    }

    return processos.map(processo => ({
      id: processo.nroProcesso,
      values: {
        checkbox: {
          name: processo.nroProcesso || '',
          checked: checkboxEvent.selectedIds[processo.nroProcesso] || false,
          changeSelectId: checkboxEvent.changeSelectId,
        },
        nroProcesso: processo.nroProcesso,
        nomeFase: processo.nomeFase,
        nomeDevedor: processo.nomeDevedor,
        nomeCliente: processo.nomeCliente,
        valorProcesso: makeMoney(processo.valorProcesso),
        naoTrabalhado: (
          <AlertaProcessoNaoTrabalhado
            naoTrabalhado={processo.naoTrabalhado}
            hint="Processo não trabalhado"
          />),
        corAlertaFase: (
          <AlertaVencimentoProcesso
            cor={processo.corAlertaFase}
            hint={`Vencimento do processo na fase: ${toBrazilianDate(processo.dataVencimentoFase)}`}
          />),
        compromisso: (
          <AlertaCompromissoProcesso
            compromisso={processo.compromisso}
            hint={`${(processo.descricaoTipoAgendamento)} : ${toBrazilianDate(processo.dataAgendamento)} - ${(processo.tituloAgendamento)} `}
          />),
        comRequisicao: <AlertaComRequisicao hint={processo.nomeRequisicao} />,
        qtdeAntecedentes: processo.qtdeAntecedentes,
        qtdeDiasFaseAtual: processo.qtdeDiasFaseAtual,
        dataEntradaFase: toBrazilianDate(processo.dataEntradaFase),
        dataExpiracao: toBrazilianDate(processo.dataExpiracao),
      },
    }));
  }

  makePaginacaoTabela(response, paginacao) {
    return {
      page: paginacao?.page || response?.number,
      size: paginacao?.size || response?.size,
    };
  }

  makeDadosPesquisa(response, paginacao, error = null) {
    return {
      content: response?.data?.content || [],
      pageable: this.makePaginacaoTabela(response?.data, paginacao),
      totalElements: response?.data?.totalElements || 0,
      error,

    };
  }


  makeTypeSearch() {
    return {
      isEspecial: false,
      title: PesquisaProcessoController.PESQUISA_AVANCADA_TITLE,
      button: PesquisaProcessoController.PESQUISA_ESPECIAL_TITLE,
    };
  }

  makeDefaultDropdownSimpleSearch(values) {
    return {
      idFase: values?.idFase || '',
      idUsuarioNegociador: values?.idUsuarioNegociador || '',
    };
  }

  makeDefaultDropdownAdvancedSearch(values) {
    return {
      dataAgendamento: values?.dataAgendamento || '',
      idAgendamento: values?.idAgendamento || '',
      idFase: values?.idFase || '',
      idUsuarioNegociador: values?.idUsuarioNegociador || '',
      idEstado: values?.idEstado || '',
      idFasePasta: values?.idFasePasta || '',
    };
  }

  makeDefaultDropdownSpecialdSearch() {
    return {
      ...this.makeDefaultDropdownSimpleSearch(this.filters),
    };
  }

  makeDataFaseAtualPesquisaAvancada(values) {
    return {
      dataFaseAtualInicio: new FieldModel({
        name: 'dataFaseAtualInicio',
        value: values?.dataFaseAtualInicio || '',
      }),
      dataFaseAtualFinal: new FieldModel({
        name: 'dataFaseAtualFinal',
        value: values?.dataFaseAtualFinal || '',
      }),
    };
  }

  makeDefaultFiltroProcessoComPrazo(values) {
    return {
      inicioAgendamento: new FieldModel({
        name: 'inicioAgendamento',
        value: values?.inicioAgendamento || '',
        error: values?.inicioAgendamento.error || false,
        errorMessage: values?.inicioAgendamento.errorMessage || '',
        disabled: true,
      }),
      fimAgendamento: new FieldModel({
        name: 'fimAgendamento',
        value: values?.fimAgendamento || '',
        error: values?.fimAgendamento.error || false,
        errorMessage: values?.fimAgendamento.errorMessage || '',
        disabled: true,
      }),
      idTipoAgendamento: new FieldModel({
        name: 'idTipoAgendamento',
        value: values?.idTipoAgendamento || '',
        error: values?.idTipoAgendamento.error || false,
        errorMessage: values?.idTipoAgendamento.errorMessage || '',
      }),
    };
  }

  makeDefaultFiltroProcessoAcordoVencendoPeriodo(values) {
    return {
      inicioVencimento: new FieldModel({
        name: 'inicioVencimento',
        value: values?.inicioVencimento || '',
        error: values?.inicioVencimento.error || false,
        errorMessage: values?.inicioVencimento.errorMessage || '',
      }),
      fimVencimento: new FieldModel({
        name: 'fimVencimento',
        value: values?.fimVencimento || '',
        error: values?.fimVencimento.error || false,
        errorMessage: values?.fimVencimento.errorMessage || '',
      }),
      idUsuarioNegociador: new FieldModel({
        name: 'idUsuarioNegociador',
        value: values?.idUsuarioNegociador || '',
        error: values?.idUsuarioNegociador.error || false,
        errorMessage: values?.idUsuarioNegociador.errorMessage || '',
      }),
    };
  }

  makeDefaultFiltroProcessosComAcordoQuitadosPeriodo(values) {
    return {
      inicioVencimento: new FieldModel({
        name: 'inicioVencimento',
        value: values?.inicioVencimento || '',
        error: values?.inicioVencimento.error || false,
        errorMessage: values?.inicioVencimento.errorMessage || '',
      }),
      fimVencimento: new FieldModel({
        name: 'fimVencimento',
        value: values?.fimVencimento || '',
        error: values?.fimVencimento.error || false,
        errorMessage: values?.fimVencimento.errorMessage || '',
      }),
      idUsuarioNegociador: new FieldModel({
        name: 'idUsuarioNegociador',
        value: values?.idUsuarioNegociador || '',
        error: values?.idUsuarioNegociador.error || false,
        errorMessage: values?.idUsuarioNegociador.errorMessage || '',
      }),
    };
  }

  makePeriodoAberturaPesquisaAvancada(values) {
    return {
      periodoAberturaInicial: new FieldModel({
        name: 'periodoAberturaInicial',
        value: values?.periodoAberturaInicial || '',
      }),
      periodoAberturaFinal: new FieldModel({
        name: 'periodoAberturaFinal',
        value: values?.periodoAberturaFinal || '',
      }),
    };
  }

  makeCheckboxEvent(selectAll, changeSelectAll) {
    return {
      checked: selectAll,
      onChange: changeSelectAll,
    };
  }

  updateAfterChargeFasePesquisaAvancada(states, name, value) {
    return {
      ...states,
      [name]: value,
      idFasePasta: '',
    };
  }

  updateDatasPesquisaAvancada(states, name, value) {
    return {
      ...states,
      [name]: states[name].getNewModel(value),
    };
  }

  updateFiltroProcessoComPrazo(states, name, value) {
    return {
      ...states,
      [name]: states[name].getNewModel(value),
    };
  }

  updateFiltroProcessoAcordoVencendoPeriodo(states, name, value) {
    return {
      ...states,
      [name]: states[name].getNewModel(value),
    };
  }

  updateFiltroProcessosComAcordoQuitadosPeriodo(states, name, value) {
    return {
      ...states,
      [name]: states[name].getNewModel(value),
    };
  }

  updateDropdownTipoAgendamentoValue(state, name, value) {
    return {
      ...state,
      [name]: state[name].getNewModel(value),
    };
  }

  updateDropdownValue(state, name, value) {
    return {
      ...state,
      [name]: value,
    };
  }

  switchTypeSearch(status) {
    return {
      isEspecial: !status.isEspecial,
      title: !status.isEspecial ? PesquisaProcessoController.PESQUISA_ESPECIAL_TITLE : PesquisaProcessoController.PESQUISA_AVANCADA_TITLE,
      button: !status.isEspecial ? PesquisaProcessoController.PESQUISA_AVANCADA_TITLE : PesquisaProcessoController.PESQUISA_ESPECIAL_TITLE,
    };
  }

  getDataFromForm(payload, unknowField = []) {
    const newPayload = Array.from(payload.keys()).reduce((values, key) => ({
      ...values,
      [key]: payload.get(key),
    }), {});

    if (!unknowField) {
      return payload;
    }

    return unknowField.reduce((accumulator, field) => ({
      ...accumulator,
      [field.name]: field.value,
    }), newPayload);
  }

  isPesquisaAvancada() {
    return this.type === PesquisaProcessoController.PESQUISA_AVANCADA;
  }

  isPesquisaEspecial() {
    return this.type === PesquisaProcessoController.PESQUISA_ESPECIAL;
  }

  isDisabledButtonAdvancedSearch(form, unknowField = []) {
    const filter = this.getDataFromForm(form, unknowField);
    return Object.keys(filter).filter(key => !!filter[key]).length === 0;
  }

  isNotActiveAdvancedSearch() {
    return !(this.hasFilledPayload() && this.isPesquisaAvancada());
  }

  isNotActiveSpecialSearch() {
    return !(this.hasFilledPayload() && this.isPesquisaEspecial());
  }

  hasNotFilledPayload() {
    return Object.keys(this.payload).length === 0;
  }

  hasFilledPayload() {
    return Object.keys(this.payload).length > 0;
  }

  getLoadingDropdown(states) {
    return [
      states.selectors.estados.loading,
      states.selectors.fases.loading,
      states.selectors.pastasDaFase.loading,
      states.selectors.negociadores.loading,
      states.selectors.tiposAgendamentos.loading,
    ];
  }

  getSimpleSearchDropdownItems(states) {
    return {
      negociadores: states.selectors.negociadores.selector,
      fases: states.selectors.fases.selector,
      tipoAgendamento: states.selectors.tiposAgendamentos.selector,
    };
  }

  getAdvancedSearchDropdownItems(states) {
    return {
      ...this.getSimpleSearchDropdownItems(states),
      estados: states.selectors.estados.selector,
      pastasDaFase: states.selectors.pastasDaFase.selector,
      tiposAgendamentos: states.selectors.tiposAgendamentos.selector,
    };
  }

  clearFilters() {
    this.filters = {};
    this.payload = {};
  }

  orderDirection(arrowDirection, reserser = false) {
    if (reserser) {
      return arrowDirection ? 'DESC' : 'ASC';
    }

    return arrowDirection ? 'ASC' : 'DESC';
  }

  setDefaultOrder() {
    this.order = ['nroProcesso,ASC'];
    this.orderView = { arrow: false, column: { element: 'nroProcesso', order: 'ASC' } };
  }

  async pesquisar() {
    try {
      let response;
      if (this.type === PesquisaProcessoController.PESQUISA_ESPECIAL) {
        response = await buscarProcessoComFiltrosEspecial(this.payload, this.page, this.size, this.order);
      } else if (this.type === PesquisaProcessoController.PESQUISA_AVANCADA) {
        response = await buscarProcessoComFiltrosAvancada(this.payload, this.page, this.size, this.order);
      } else if (this.type === PesquisaProcessoController.PESQUISA_SIMPLES) {
        response = await buscarProcessoComFiltrosSimples(this.payload, this.page, this.size, this.order);
      }

      return this.makeDadosPesquisa(response, {
        page: this.page,
        size: this.size,
      });
    } catch (error) {
      return this.makeDadosPesquisa({}, {
        page: this.page,
        size: this.size,
      }, error);
    }
  }

  async pesquisarAplicandoOrdenacao(columnName, arrowDirection) {
    if (columnName === 'nroProcesso') {
      this.order = [`${columnName},${this.orderDirection(arrowDirection)}`];
    } else if (columnName === 'idRequisicaoProcesso') {
      this.order = [`${columnName},${this.orderDirection(arrowDirection, true)}`, 'nroProcesso,ASC'];
    } else if (columnName === 'compromisso') {
      this.order = [
        `dataAgendamento,${this.orderDirection(arrowDirection, false)}`,
        'nroProcesso,ASC',
      ];
    } else {
      this.order = [`${columnName},${this.orderDirection(arrowDirection)}`, 'nroProcesso,ASC'];
    }

    this.orderView = { arrow: !arrowDirection, column: { element: columnName, order: this.orderDirection(arrowDirection) } };
    const response = await this.pesquisar();
    return { order: this.orderView, ...response };
  }

  async buscarIdsProcessos() {
    try {
      const response = await buscarIdsProcessos(this.payload, this.type);
      const ids = response.data.reduce((acumulador, id) => ({ ...acumulador, [id]: false }), {});
      return ids;
    } catch (err) {
      return {};
    }
  }

  async buscarIdsProcessosComNotificacoes() {
    try {
      const response = await buscarIdsProcessosComNotificacoes();
      const ids = response.data.reduce((acumulador, id) => ({ ...acumulador, [id]: false }), {});
      return ids;
    } catch (err) {
      return {};
    }
  }

  async buscarIdsAvancadoProcessos() {
    try {
      const response = await buscarIdsAvancadoProcessos(this.payload, this.type);
      const ids = response.data.reduce((acumulador, id) => ({ ...acumulador, [id]: false }), {});
      return ids;
    } catch (err) {
      return {};
    }
  }

  async pesquisarComFiltroSimples(form, pageSize) {
    this.lastSelectionSpecialSearch = '';
    this.type = PesquisaProcessoController.PESQUISA_SIMPLES;
    this.setDefaultOrder();
    this.filters = this.getDataFromForm(form);
    this.payload = { ...this.filters };
    this.page = 0;
    this.size = pageSize;

    const data = await this.pesquisar();
    return data;
  }

  async pesquisarComFiltroAvancada(form, pageSize = 15) {
    this.lastSelectionSpecialSearch = '';
    this.type = PesquisaProcessoController.PESQUISA_AVANCADA;
    this.setDefaultOrder();
    this.filters = this.getDataFromForm(form);
    this.payload = { ...this.filters };
    this.payload.processosComRequisicao = !!this.filters.processosComRequisicao;
    this.payload.dataAgendamento = toAmericanDate(this.filters.dataAgendamento);
    this.payload.dataFaseAtualInicio = toAmericanDate(this.filters.dataFaseAtualInicio);
    this.payload.dataFaseAtualFinal = toAmericanDate(this.filters.dataFaseAtualFinal);
    this.payload.periodoAberturaInicial = toAmericanDate(this.filters.periodoAberturaInicial);
    this.payload.periodoAberturaFinal = toAmericanDate(this.filters.periodoAberturaFinal);
    this.payload.processosComRequisicao = !!this.filters.processosComRequisicao;
    this.page = 0;
    this.size = pageSize;

    const result = await this.pesquisar();
    return result;
  }

  async pesquisarComFiltroEspecial(form, pageSize) {
    this.type = PesquisaProcessoController.PESQUISA_ESPECIAL;
    this.setDefaultOrder();
    this.filters = this.getDataFromForm(form);
    this.payload = { ...this.filters };
    if (this.payload[MEUS_PROCESSOS]) {
      const assignedUserId = store.getState().authentication?.userInformation?.idUsuario || null;
      this.payload.idUsuarioNegociador = assignedUserId;
    }

    this.payload[PROCESSOS_AJUIZADOS] = !!this.payload[PROCESSOS_AJUIZADOS];
    this.payload[MINHAS_REQUISICOES] = !!this.payload[MINHAS_REQUISICOES];
    this.payload.processosNaoTrabalhados = !!this.filters.processosNaoTrabalhados;
    this.payload.processosProximosExpirar = !!this.filters.processosProximosExpirar;
    this.payload.processosTitulosNaoVencidos = !!this.filters.processosTitulosNaoVencidos;
    this.payload.processosExpirados = !!this.filters.processosExpirados;
    this.payload.processosProximosExpirar = !!this.filters.processosProximosExpirar;
    this.payload.processosDevedorEspecifico = !!this.filters.processosDevedorEspecifico;
    this.payload.processoComPrazoEmPeriodo = !!this.filters.processoComPrazoEmPeriodo;
    this.payload.processoAcordoVencendoPeriodo = !!this.filters.processoAcordoVencendoPeriodo;
    this.payload.processosComAcordoQuitadosPeriodo = !!this.filters.processosComAcordoQuitadosPeriodo;
    this.payload.inicioAgendamento = toAmericanDate(this.filters.inicioAgendamento);
    this.payload.fimAgendamento = toAmericanDate(this.filters.fimAgendamento);
    this.payload.inicioVencimento = toAmericanDate(this.filters.inicioVencimento);
    this.payload.fimVencimento = toAmericanDate(this.filters.fimVencimento);
    this.payload.processosProximosExpirarOuExpirados = null;
    this.payload[MEUS_PROCESSOS] = null;
    this.page = 0;
    this.size = pageSize;

    const result = await this.pesquisar();
    return result;
  }

  async pesquisarProcessosComNotificacoes(pageSize) {
    this.type = PesquisaProcessoController.PESQUISA_ESPECIAL;
    this.setDefaultOrder();

    this.page = 0;
    this.size = pageSize;

    try {
      const response = await pesquisarProcessosComNotificacoes(this.page, this.size, this.order);
      return this.makeDadosPesquisa(response, {
        page: this.page,
        size: this.size,
      });
    } catch (error) {
      return this.makeDadosPesquisa({}, {
        page: this.page,
        size: this.size,
      }, error);
    }
  }

  async pesquisarProcessoCarregarPagina() {
    if (this.type) {
      const processos = await this.pesquisar();
      const idsProcessos = await this.buscarIdsProcessos();
      return { processos, idsProcessos };
    }
    return { naoCarregar: true };
  }

  getNumeroProcessoPesquisaSimples() {
    if (this.type === PesquisaProcessoController.PESQUISA_SIMPLES) {
      return this.filters?.numeroProcesso || '';
    }
    return '';
  }

  getNumeroProcessoPesquisaAvancada(numeroProcesso) {
    if (this.type === PesquisaProcessoController.PESQUISA_AVANCADA) {
      return numeroProcesso || '';
    }
    return '';
  }
}


export const pesquisaProcessoController = new PesquisaProcessoController();
