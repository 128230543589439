import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  onChangeDataExpiracaoFieldsAction,
  onUpdateRequestFieldsAction,
  setEstatisticasAction,
  setStoreValuesAction,
  setTableInfoAction,
} from './historicoAS.store';

import {
  CONFIRMAR_DADOS_ALTERAR_PRAZO,
  GET_ESTATISCAS_HISTORICO,
  PESQUISAR_DADOS_ALTERAR_PRAZO,
  PESQUISAR_HISTORICO_AS,
  PESQUISAR_HISTORICO_ENTRAR_TELA_AS,
} from './historicoAS.constants';
import {
  alterarDataPrazoExpiracao,
  buscarHistoricoAS,
  buscarHistoricoProcessoAcordoSeguroPorId,
  getTipoAcordoSeguro,
} from '../../../services/core/acordoseguro/historico.services';
import { RESULT_NOT_FOUND } from '../../../helpers/constants/global.constants';
import { getExceptionHandler } from '../../../helpers/utils/exception.util';
import { toAmericanDate, toBrazilianDate } from '../../../helpers/utils/utils';

export const getEstatisticasHistoricoAction = () => ({
  type: GET_ESTATISCAS_HISTORICO,
});

export const pesquisarDadosAlterarPrazoAction = nroProcesso => ({
  type: PESQUISAR_DADOS_ALTERAR_PRAZO,
  nroProcesso,
});

export const confirmarDadosAlterarPrazoAction = (idProcesso, idUsuario) => ({
  type: CONFIRMAR_DADOS_ALTERAR_PRAZO,
  idProcesso,
  idUsuario,
});

export const pesquisarHistoricoASAction = (page, rowsPerPage, ordenacao) => ({
  type: PESQUISAR_HISTORICO_AS,
  page,
  rowsPerPage,
  ordenacao,
});

export const pesquisarHistoricoASEntrarTelaAction = (page, rowsPerPage) => ({
  type: PESQUISAR_HISTORICO_ENTRAR_TELA_AS,
  page,
  rowsPerPage,
});

function* buildTable(data) {
  yield put(setTableInfoAction(data.content, data.number, data.size, data.totalElements));
}

function configureSnackbarAlert(content) {
  if (content.length === 0) {
    toast.warn(RESULT_NOT_FOUND);
  }
}

function* catchSubmitException(exception, controls) {
  const [updatedControls] = yield getExceptionHandler(exception, controls);
  if (updatedControls) {
    yield put(onUpdateRequestFieldsAction(updatedControls));
  }
}

function* getHistoricoASHandler(filters, ordenacao, page, rowsPerPage) {
  const {
    periodoAberturaInicial, periodoAberturaFinal, nroProcesso, nomeDevedor,
  } = filters;
  let response;
  response = yield call(buscarHistoricoAS, periodoAberturaInicial, periodoAberturaFinal, nroProcesso, nomeDevedor, ordenacao, page, rowsPerPage);

  if (response.data.content.length === 0 && response.data.totalElements !== 0) {
    const lastPage = yield response.data.totalPages - 1;
    response = yield call(buscarHistoricoAS, periodoAberturaInicial, periodoAberturaFinal, nroProcesso, nomeDevedor, ordenacao, lastPage, rowsPerPage);
  }

  return response.data;
}

function* confirmarDadosAlterarPrazoHandler(actions) {
  yield put(setStoreValuesAction('loading', true));
  const fields = yield select(states => states.historicoAS.dataExpiracaoFields);
  try {
    const { idProcesso, idUsuario } = actions;
    const dataExpiracaoAcordoSeguro = toAmericanDate(fields.dataExpiracaoAcordoSeguro.getValueNotEmpty());
    yield call(alterarDataPrazoExpiracao, { idProcesso, idUsuario, dataExpiracaoAcordoSeguro });

    const cacheRequestFields = yield select(states => states.historicoAS.cacheRequestFields);
    const { page, rowsPerPage, ordenacao } = actions;

    const data = yield getHistoricoASHandler(cacheRequestFields, ordenacao, page, rowsPerPage);
    yield buildTable(data);
    yield configureSnackbarAlert(data.content);
    yield put(setStoreValuesAction('openDialogDataExpiracao', false));
  } catch (exception) {
    yield catchSubmitException(exception, fields);
  } finally {
    yield put(setStoreValuesAction('loading', false));
  }
}

function* pesquisarDadosAlterarPrazoHandler(actions) {
  yield put(setStoreValuesAction('loading', true));
  try {
    const { data } = yield call(buscarHistoricoProcessoAcordoSeguroPorId, actions.nroProcesso);
    const dataExpiracaoAcordoSeguro = toBrazilianDate(data.dataExpiracaoAcordoSeguro);


    yield put(setStoreValuesAction('estagio', data.estagio));
    yield put(setStoreValuesAction('nroProcesso', data.nroProcesso));
    yield put(onChangeDataExpiracaoFieldsAction('dataExpiracaoAcordoSeguro', dataExpiracaoAcordoSeguro));
  } catch (exception) {
    // Nenhum tratamento definido...
  } finally {
    yield put(setStoreValuesAction('loading', false));
  }
}

function* pesquisarHistoricoHandler(actions) {
  yield put(setStoreValuesAction('loading', true));
  const fields = yield select(states => states.historicoAS.requestFields);
  try {
    const cacheRequestFields = yield select(states => states.historicoAS.cacheRequestFields);
    const { page, rowsPerPage, ordenacao } = actions;
    const data = yield getHistoricoASHandler(cacheRequestFields, ordenacao, page, rowsPerPage);
    yield buildTable(data);
    yield configureSnackbarAlert(data.content);
  } catch (exception) {
    yield catchSubmitException(exception, fields);
  } finally {
    yield put(setStoreValuesAction('loading', false));
  }
}

function* pesquisarHistoricoEntrarTelaHandler(actions) {
  yield put(setStoreValuesAction('loading', true));
  const fields = yield select(states => states.historicoAS.requestFields);
  try {
    const periodoAberturaInicial = toAmericanDate(fields.periodoAberturaInicial.getValueNotEmpty());
    const periodoAberturaFinal = toAmericanDate(fields.periodoAberturaFinal.getValueNotEmpty());
    const { page, rowsPerPage, ordenacao } = actions;

    const data = yield getHistoricoASHandler({
      periodoAberturaInicial,
      periodoAberturaFinal,
    }, ordenacao, page, rowsPerPage);
    yield buildTable(data);
  } catch (exception) {
    yield catchSubmitException(exception, fields);
  } finally {
    yield put(setStoreValuesAction('loading', false));
  }
}


function* getEstatisticasHistoricoHandler() {
  yield put(setStoreValuesAction('loading', true));
  try {
    const { data } = yield call(getTipoAcordoSeguro);
    yield put(setEstatisticasAction(data.acessoSite, data.notificados, data.qtdeSmsEnviado, data.qtdeEmailEnviados));
  } catch (exception) {
    // Nenhum tratamento definido
  } finally {
    yield put(setStoreValuesAction('loading', false));
  }
}

export default function* watchHistoricoAS() {
  yield takeLatest(GET_ESTATISCAS_HISTORICO, getEstatisticasHistoricoHandler);
  yield takeLatest(PESQUISAR_HISTORICO_AS, pesquisarHistoricoHandler);
  yield takeLatest(PESQUISAR_DADOS_ALTERAR_PRAZO, pesquisarDadosAlterarPrazoHandler);
  yield takeLatest(PESQUISAR_HISTORICO_ENTRAR_TELA_AS, pesquisarHistoricoEntrarTelaHandler);
  yield takeLatest(CONFIRMAR_DADOS_ALTERAR_PRAZO, confirmarDadosAlterarPrazoHandler);
}
