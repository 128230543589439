import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import InputField from '../../../../components/UI/Field/Input';
import SelectorField from '../../../../components/UI/Field/Selector';
import Grid24 from '../../../../components/UI/Grid24';
import FormWrapperWithTitle from '../../../../components/UI/Wrapper/Form/FormWrapperWithTitle';
import { getRequiredLabel } from '../../../../helpers/utils/utils';
import {
  CATEGORIA,
  CODIGO_CLIENTE,
  CODIGO_DEVEDOR,
  CPF_CPNJ,
  DADOS_CADASTRAIS,
  INSCRICAO_ESTADUAL,
  NOME_FANTASIA,
  PREPOSTO,
  RAZAO_SOCIAL,
} from './IdentificacaoDevedor.constants';
import MaskField from '../../../../components/UI/Field/Mask/MaskField';
import { CNPJ_MASK, CPF_MASK } from '../../../../helpers/constants/layout.constants';

function IdentificacaoDevedor({
  immutableSelectors,
  onFocusHandler,
  onChangeHandler,
  isEditMode,
  podeModificarFase,
  formFields,
}) {
  const [permiteCnpj, setPermiteCnpj] = useState(immutableSelectors?.categorias.filter(cat => cat.id === formFields?.idCategoria?.value)[0].aux === 'CNPJ');

  useEffect(() => {
    setPermiteCnpj(immutableSelectors?.categorias.filter(cat => cat.id === formFields?.idCategoria?.value)[0].aux === 'CNPJ');
  }, [formFields, immutableSelectors, setPermiteCnpj]);

  return (
    <Grid24 container isFormContainer>
      <FormWrapperWithTitle title={DADOS_CADASTRAIS}>
        <Grid24 container isFormContainer spacing="1">
          <Grid24 xs={24} sm={3}>
            <SelectorField
              fullWidth
              isForm
              label={getRequiredLabel(CATEGORIA, isEditMode)}
              disabled={!podeModificarFase || !isEditMode}
              items={immutableSelectors.categorias}
              name="idCategoria"
              value={formFields.idCategoria.value}
              onChange={onChangeHandler}
              onFocus={onFocusHandler}
              error={formFields.idCategoria.error}
              errorMessage={formFields.idCategoria.errorMessage}
            />
          </Grid24>
          <Grid24 xs={24} sm={4}>
            <MaskField
              isForm
              fullWidth
              disabled={!podeModificarFase || !isEditMode}
              label={getRequiredLabel(CPF_CPNJ, isEditMode)}
              name="cnpjCpf"
              value={formFields.cnpjCpf.value}
              error={formFields.cnpjCpf.error}
              errorMessage={formFields.cnpjCpf.errorMessage}
              onChange={onChangeHandler}
              onFocus={onFocusHandler}
              format={permiteCnpj ? CNPJ_MASK : CPF_MASK}
            />
          </Grid24>
        </Grid24>
        <Grid24 container isFormContainer spacing="1">
          <Grid24 xs={24} sm={4}>
            <InputField
              fullWidth
              isForm
              label={CODIGO_CLIENTE}
              value={formFields.codigoNoCliente.value}
              disabled={!podeModificarFase || !isEditMode}
              name="codigoNoCliente"
              onChange={onChangeHandler}
              onFocus={onFocusHandler}
            />
          </Grid24>
          <Grid24 xs={24} sm={3}>
            <InputField
              fullWidth
              isForm
              label={CODIGO_DEVEDOR}
              value={formFields.idDevedor.value}
              disabled
              name="idDevedor"
            />
          </Grid24>
          <Grid24 xs={24} sm={9}>
            <InputField
              fullWidth
              isForm
              label={getRequiredLabel(RAZAO_SOCIAL, isEditMode)}
              value={formFields.razaoSocial.value}
              disabled={!podeModificarFase || !isEditMode}
              name="razaoSocial"
              onChange={onChangeHandler}
              onFocus={onFocusHandler}
              error={formFields.razaoSocial.error}
              errorMessage={formFields.razaoSocial.errorMessage}
            />
          </Grid24>
          <Grid24 xs={24} sm={4}>
            <InputField
              fullWidth
              isForm
              label={PREPOSTO}
              value={formFields.preposto.value}
              disabled={!podeModificarFase || !isEditMode}
              name="preposto"
              onChange={onChangeHandler}
              onFocus={onFocusHandler}
            />
          </Grid24>
        </Grid24>
        <Grid24 container isFormContainer spacing="1">
          <Grid24 xs={24} sm={8}>
            <InputField
              fullWidth
              isForm
              label={NOME_FANTASIA}
              value={formFields.nomeFantasia.value}
              disabled={!podeModificarFase || !isEditMode}
              name="nomeFantasia"
              onChange={onChangeHandler}
              onFocus={onFocusHandler}
            />
          </Grid24>
          <Grid24 xs={24} sm={3}>
            <InputField
              fullWidth
              isForm
              label={INSCRICAO_ESTADUAL}
              value={formFields.inscricaoEstadual.value}
              disabled={!podeModificarFase || !isEditMode}
              name="inscricaoEstadual"
              onChange={onChangeHandler}
              onFocus={onFocusHandler}
            />
          </Grid24>
        </Grid24>
      </FormWrapperWithTitle>
    </Grid24>

  );
}

export default withRouter(IdentificacaoDevedor);
