import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { GERAR_RELATORIO_ARQUIVO, PESQUISAR_ARQUIVOS_IMPORTACAO } from './importarBordero.constans';
import {
  onUpdateRequestFieldsAction,
  setActionsValue,
  setTableInfoAction,
} from './importarBordero.store';
import { buscarBorderoImportarPorStatus, gerarRelatorio } from '../../../services/bordero/importacao/importacao.service';
import { RESULT_NOT_FOUND } from '../../../helpers/constants/global.constants';
import { getExceptionHandler } from '../../../helpers/utils/exception.util';
import {
  get7DaysAgo, openPagePDF, toAmericanDate, toBrazilianDate,
} from '../../../helpers/utils/utils';


export const gerarRelatorioAction = idBorderoImportar => ({
  type: GERAR_RELATORIO_ARQUIVO,
  idBorderoImportar,
});

export const pesquisarArquivosImportacaoAction = (page, rowsPerPage) => ({
  type: PESQUISAR_ARQUIVOS_IMPORTACAO,
  page,
  rowsPerPage,
});

function* catchSubmitException(exception, controls) {
  const [updatedControls] = yield getExceptionHandler(exception, controls);
  if (updatedControls) {
    yield put(onUpdateRequestFieldsAction(updatedControls));
  }
}

function* getArquivosImportacaoHandler(filters, page, rowsPerPage) {
  const { idStatus, dataImportacao } = filters;
  let response;
  response = yield call(buscarBorderoImportarPorStatus, idStatus, dataImportacao, page, rowsPerPage);

  if (response.data.content.length === 0 && response.data.totalElements !== 0) {
    const lastPage = yield response.data.totalPages - 1;
    response = yield call(
      buscarBorderoImportarPorStatus,
      idStatus,
      dataImportacao,
      lastPage,
      rowsPerPage,
    );
  }

  return response.data;
}

function* buildTable(data) {
  yield put(setTableInfoAction(data.content, data.number, data.size, data.totalElements));
}

function* configureSnackbarAlert(content) {
  const clickPesquisar = yield select(states => states.importarBorderos.actions.clickPesquisar);
  if (content.length === 0 && clickPesquisar) {
    toast.warn(RESULT_NOT_FOUND);
  }
}

function* pesquisarArquivosImportacaoHandler(actions) {
  yield put(setActionsValue('loading', true));
  const requestFields = yield select(states => states.importarBorderos.requestFields);
  try {
    let cacheRequestFields = yield select(states => states.importarBorderos.cacheRequestFields);
    const { page, rowsPerPage } = actions;

    // Primeira busca para quando entrar na tela.
    if (!cacheRequestFields) {
      cacheRequestFields = { idStatus: 0, dataImportacao: toAmericanDate(toBrazilianDate(get7DaysAgo())) };
    }
    const data = yield getArquivosImportacaoHandler(cacheRequestFields, page, rowsPerPage);
    yield buildTable(data);
    yield configureSnackbarAlert(data.content);
  } catch (exception) {
    yield catchSubmitException(exception, requestFields);
  } finally {
    yield put(setActionsValue('loading', false));
  }
}

function* gerarRelatorioHandler(actions) {
  yield put(setActionsValue('loading', true));
  try {
    const { data } = yield call(gerarRelatorio, actions.idBorderoImportar);
    yield openPagePDF(data);
  } catch (exception) {
    // Nenhum tratamento definido...
  } finally {
    yield put(setActionsValue('loading', false));
  }
}

export default function* watchImportarBordero() {
  yield takeLatest(PESQUISAR_ARQUIVOS_IMPORTACAO, pesquisarArquivosImportacaoHandler);
  yield takeLatest(GERAR_RELATORIO_ARQUIVO, gerarRelatorioHandler);
}
