import React, { useCallback } from 'react';
import { AccordionDetails } from '@material-ui/core';
import { useCrudClienteDispatch, useCrudClienteSelector } from '../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import Grid24 from '../../../../UI/Grid24';
import ConfiguracoesGerais from './ConfiguracoesGerais/ConfiguracoesGerais';
import InformacoesTransferencia from './InformacoesTransferencias/InformacoesTransferencia';
import NotasFiscaisBoletos from './NotasFiscaisBoletos/NotasFiscaisBoletos';
import Periodicidade from './Periodicidade/Periodicidade';
import RegrasEspeciais from './RegrasEspeciais/RegrasEspeciais';
import BloqueioPrestacao from './BloqueioPrestacao/BloqueioPrestacao';
import { onChangeControls, onChangeControlsNewEmpresaClienteNotaFiscalAction, setSimpleValuesClienteAction } from '../../../../../store/Cliente/V2/Crud/crudCliente.store';
import { Accordion } from '../../../../UI/Inspecionar/Accordion/Accordion';
import PadraoDistribuicao from './PadraoDistribuicaoValores/PadraoDistribuicao';
import {
  DICA_PADRAO_DISTRIBUICAO_DEVEDOR, DICA_PADRAO_DISTRIBUICAO_TITULOS, PADRAO_DISTRIBUICAO_OPCOES_DEVEDOR, PADRAO_DISTRIBUICAO_OPCOES_TITULOS,
} from './PadraoDistribuicaoValores/PadraoDistribuicaoOpcoes';


function PrestacaoContas({
  isEditMode, onFocus, isInsertMode, idCliente,
}) {
  const dispatch = useCrudClienteDispatch();
  const idTipoDistribuicaoValorCliente = useCrudClienteSelector(states => states.controls.idTipoDistribuicaoValorCliente);
  const idFormaQuitacaoValorCliente = useCrudClienteSelector(states => states.controls.idFormaQuitacaoValorCliente);

  const onChangeChecked = useCallback((event) => {
    const { name, checked } = event.target;
    if (name === 'enviarPrestacaoContaEmail' && !checked) {
      dispatch(onChangeControls('idGeradorRelatorioPrestacao', ''));
    }

    if (name === 'enviarPrestacaoContaEmail' && checked) {
      dispatch(onChangeControls('idGeradorRelatorioPrestacao', 1));
    }
    dispatch(onChangeControls(name, checked));
  }, [dispatch]);

  const onChangeCheckbox = useCallback((event) => {
    const { name, checked } = event.target;
    if (name === 'requerInformacaoExtraNF') {
      dispatch(onChangeControls('detalhesInfoExtraNF', ''));
    } else if (name === 'utilizaEmpresaAlternativaEmissaoNF') {
      dispatch(onChangeControlsNewEmpresaClienteNotaFiscalAction({}));
      dispatch(setSimpleValuesClienteAction('bloquearCadastroEmpresaClienteNF', true));
      dispatch(setSimpleValuesClienteAction('bloquearBuscarEmpresaClienteNF', false));
    }
    dispatch(onChangeControls(name, checked));
  }, [dispatch]);

  const onChangeHandler = useCallback(
    (event) => {
      const { name, value } = event.target;
      if (name === 'idTipoChavePix') {
        dispatch(onChangeControls('chavePix', ''));
      } else if (name === 'idTipoLiquidacaoPrestacao') {
        dispatch(onChangeControls('detalheLiquidacaoPrestacao', ''));
      } else if (name === 'idTipoNotaFiscal') {
        dispatch(onChangeControls('descricaoNotaPersonalizada', ''));
      }
      dispatch(onChangeControls(name, value));
    }, [dispatch],
  );

  const onChangeDistribuicaoRadio = useCallback((e, fieldName) => {
    const { value } = e.target;
    dispatch(onChangeControls(fieldName, parseInt(value, 10)));
  }, [dispatch]);

  return (
    <>
      <Accordion
        style={{ marginBottom: '11px', width: '100%' }}
        color="purePurple"
        title="Distribuição de Valores"
      >
        <AccordionDetails>
          <Grid24 container isFormContainer>
            <PadraoDistribuicao
              dicaDistribuicao={DICA_PADRAO_DISTRIBUICAO_DEVEDOR}
              title="Distribuição dos Valores pagos pelo Devedor"
              opcoes={PADRAO_DISTRIBUICAO_OPCOES_DEVEDOR}
              onChange={onChangeDistribuicaoRadio}
              formModel={idTipoDistribuicaoValorCliente}
              isEditMode={isEditMode}
              isInsertMode={isInsertMode}
              onChangeChecked={onChangeChecked}
              onChangeCheckbox={onChangeCheckbox}
            />
            <PadraoDistribuicao
              dicaDistribuicao={DICA_PADRAO_DISTRIBUICAO_TITULOS}
              title="Quitação dos Títulos"
              opcoes={PADRAO_DISTRIBUICAO_OPCOES_TITULOS}
              onChange={onChangeDistribuicaoRadio}
              formModel={idFormaQuitacaoValorCliente}
              isEditMode={isEditMode}
              isInsertMode={isInsertMode}
            />
          </Grid24>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{ marginBottom: '11px', width: '100%' }}
        color="purePurple"
        title="Prestações de Contas"
      >
        <AccordionDetails>
          <Grid24 container isFormContainer>
            <BloqueioPrestacao
              isEditMode={isEditMode || isInsertMode}
              onChangeChecked={onChangeChecked}
              onChangeCheckbox={onChangeCheckbox}
            />
            <ConfiguracoesGerais
              isEditMode={isEditMode || isInsertMode}
              onChangeChecked={onChangeChecked}
              onChange={onChangeHandler}
              onChangeCheckbox={onChangeCheckbox}
              onFocus={onFocus}
            />
            <Periodicidade
              isEditMode={isEditMode || isInsertMode}
              onChangeChecked={onChangeChecked}
            />
            <RegrasEspeciais
              isEditMode={isEditMode || isInsertMode}
              onChange={onChangeHandler}
            />
          </Grid24>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{ marginBottom: '11px' }}
        color="purePurple"
        title="Repasses"
      >
        <AccordionDetails>
          <InformacoesTransferencia
            isEditMode={isEditMode || isInsertMode}
            onChange={onChangeHandler}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{ marginBottom: '11px' }}
        color="purePurple"
        title="Recebimentos"
      >
        <AccordionDetails>
          <NotasFiscaisBoletos
            isEditMode={isEditMode}
            onChange={onChangeHandler}
            onChangeCheckbox={onChangeCheckbox}
            onFocus={onFocus}
            isInsertMode={isInsertMode}
            idCliente={idCliente}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
export default PrestacaoContas;
