import FieldModel from '../../models/FieldModel';
import { getAttributeExistingObject } from '../utils/utils';

export function buildParametroDescontoPix(descontoPix) {
  return ({
    idParametroDescontoPix: new FieldModel({
      value: getAttributeExistingObject(descontoPix, 'idParametroDescontoPix', 1),
    }),
    porcentagemDescontoPix: new FieldModel({
      name: 'porcentagemDescontoPix',
      value: getAttributeExistingObject(descontoPix, 'porcentagemDescontoPix'),
      maxLength: 5,
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    qtdeParcelas: new FieldModel({
      name: 'qtdeParcelas',
      value: getAttributeExistingObject(descontoPix, 'qtdeParcelas', 1),
    }),
  });
}

export function buildParametroDescontosPix(descontoPix) {
  return (descontoPix) ? descontoPix.map(buildParametroDescontoPix) : [];
}

export function buildClienteDescontoPix(descontoPix) {
  return ({
    idClienteDescontoPixAS: new FieldModel({
      value: getAttributeExistingObject(descontoPix, 'idClienteDescontoPixAS'),
    }),
    porcentagemDescontoPix: new FieldModel({
      name: 'porcentagemDescontoPix',
      value: getAttributeExistingObject(descontoPix, 'porcentagemDescontoPix'),
      maxLength: 5,
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    qtdeParcelas: new FieldModel({
      name: 'qtdeParcelas',
      value: getAttributeExistingObject(descontoPix, 'qtdeParcelas', 1),
    }),
  });
}

export function buildClienteDescontosPix(descontoPix) {
  return (descontoPix) ? descontoPix.map(buildClienteDescontoPix) : [];
}



