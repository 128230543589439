import React, { useCallback, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Button, IconButton } from '@material-ui/core';

import { MdSettings, MdExitToApp } from 'react-icons/md';

import useStyles, {
  ColorButton, CloseButton, CloseContainer, SettingsContainer, ButtonColorGroup,
} from './actionsNavBarStyles';
import { FloatBox } from '../FloatContainer/FloatBox';
import { logoutAction } from '../../store/Login/login.saga';
import { changeColorTheme } from '../../store/theme.store';
import { atualizarConfiguracoesLayoutDeLogin } from '../../services/core/login/login.services';
import DialogNew from '../../layouts/FormDialog/DialogNew';
import DialogAlterarSenha from './Dialog/DialogAlterarSenha';
import { setOpenDialogAlterarSenhaAction } from '../../store/Login/login.store';

const BLACK_HEX = 'black';
const WHITE_HEX = 'white';
const PRETO = 'black';
const ID_PRETO = 1;
const ID_BRANCO = 0;

function ActionsNavBarComponent() {
  const dispatch = useDispatch();

  const backgroundMenu = useSelector(state => state.theme.bgColor);
  const login = useSelector(state => state.authentication.userInformation?.nomeUsuario || null);

  const styles = useStyles();

  const settingsRef = useRef();
  const settingsFloatReg = useRef();

  const closeRef = useRef();
  const closeFloatRef = useRef();
  const openDialogAlterarSenha = useSelector(state => state.authentication.openDialogAlterarSenha || null);

  function makePreferencesMenuSettings(color) {
    return {
      idOpcaoCor: color === PRETO ? ID_PRETO : ID_BRANCO,
    };
  }

  function updateUserData(preferencias) {
    const userData = JSON.parse(localStorage.getItem('userData'));
    userData.idOpcaoCor = preferencias.idOpcaoCor;
    localStorage.setItem('userData', JSON.stringify(userData));
  }


  function handleSaveColor(color) {
    const preferencias = makePreferencesMenuSettings(color);
    atualizarConfiguracoesLayoutDeLogin(login, preferencias).then();
    updateUserData(preferencias);
    dispatch(changeColorTheme(color));
    settingsFloatReg.current.handlerShowBox();
  }

  const setOpenDialogAlterarSenha = useCallback((value) => {
    dispatch(setOpenDialogAlterarSenhaAction(value));
  }, [dispatch]);

  return (
    <>
      <DialogNew
        open={openDialogAlterarSenha}
        text="Alterar Senha"
        className={styles.dialog}
        onClose={() => setOpenDialogAlterarSenha(false)}
      >
        <DialogAlterarSenha
          onClose={() => {
            setOpenDialogAlterarSenha(false);
            settingsFloatReg.current.handlerShowBox();
          }}
          settingsFloatReg={settingsFloatReg}
        />
      </DialogNew>
      <div>
        <IconButton
          ref={settingsRef}
          onClick={() => settingsFloatReg.current.handlerShowBox()}
        >
          <MdSettings size={24} />
        </IconButton>
        <FloatBox
          ref={settingsFloatReg}
          parentRef={settingsRef}
        >
          <SettingsContainer>
            <div>
              <p>Cor</p>
              <ButtonColorGroup>
                <ColorButton
                  background="black"
                  selected={backgroundMenu === BLACK_HEX}
                  onClick={() => handleSaveColor('black')}
                />
                <ColorButton
                  background="white"
                  selected={backgroundMenu === WHITE_HEX}
                  onClick={() => handleSaveColor('white')}
                />
              </ButtonColorGroup>
            </div>
            <div style={{ marginTop: '12px' }}>
              <Button
                style={{
                  textTransform: 'capitalize',
                  fontSize: '14px',
                  paddingLeft: '0',
                  fontWeight: 'normal',
                }}
                onClick={() => setOpenDialogAlterarSenha(true)}
              >
                <p>Alterar Senha</p>
              </Button>
            </div>
          </SettingsContainer>

        </FloatBox>

        <IconButton
          ref={closeRef}
          onClick={() => closeFloatRef.current?.handlerShowBox()}
        >
          <MdExitToApp size={24} />
        </IconButton>
        <FloatBox
          ref={closeFloatRef}
          parentRef={closeRef}
        >
          <CloseContainer width={120}>
            <CloseButton
              onClick={() => dispatch(logoutAction())}
            >
              Sair
            </CloseButton>
          </CloseContainer>
        </FloatBox>
      </div>
    </>
  );
}

export const ActionsNavBar = React.memo(ActionsNavBarComponent);
