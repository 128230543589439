import { takeLatest, put, select } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import {
  setLoadingAction,
  clearRequestFieldsAction,
  setAlertDialogValuesAction,
  reloadSelectorsAction,
  setFilialAction,
  setGoToBackPageAction,
} from './crudFilial.store';

import {
  buildPayload, buildPayloadArray, isNotFoundException, isConflictException, getExceptionMessage,
} from '../../../helpers/utils/utils';
import { insertFilial, updateFilial, findFilialById } from '../../../services/core/filial/filial.services';
import { getExceptionHandler } from '../../../helpers/utils/exception.util';
import { buildEnderecoPayload } from '../../../helpers/factory/endereco.factory';

import constants from './crudFilial.constants';
import { setRequestFieldsAction } from '../../Parametros/Crud/crudParametros.store';

const INSERT_FILIAL = 'INSERT_FILIAL_CRUD_FILIAL';
const UPDATE_FILIAL = 'UPDATE_FILIAL_CRUD_FILIAL';
const FIND_FILIAL_BY_ID = 'FIND_FILIAL_BY_ID_CRUD_FILIAL';

const UPDATE_TYPE = 'UPDATE_TYPE';

export const insertFilialAction = () => ({
  type: INSERT_FILIAL,
});

export const updateFilialAction = () => ({
  type: UPDATE_FILIAL,
});

export const findFilialByIdAction = idFilial => ({
  type: FIND_FILIAL_BY_ID,
  idFilial,
});

function getFilialPayload(requestFields) {
  const filialPayload = buildPayload(requestFields);
  filialPayload.endereco = buildEnderecoPayload(requestFields.endereco);
  filialPayload.telefones = buildPayloadArray(requestFields.telefones);
  return filialPayload;
}

function* catchSubmitException(exception, requestFields) {
  const [updatedRequestFields] = yield getExceptionHandler(exception, requestFields);
  if (updatedRequestFields) {
    yield put(setRequestFieldsAction(updatedRequestFields));
  }

  if (yield (isNotFoundException(exception))) {
    yield put(setAlertDialogValuesAction(true, 'error', getExceptionMessage(exception), 'FILIAL_NOT_FOUND'));
  } else if (isConflictException(exception)) {
    yield put(setAlertDialogValuesAction(true, 'error', getExceptionMessage(exception)));
  }

  if (isConflictException(exception)) {
    yield put(reloadSelectorsAction());
  }
}

function* successSubmit(requestType) {
  if (requestType === UPDATE_TYPE) {
    yield put(setGoToBackPageAction(true));
  } else {
    toast.success(constants.SUCCESS_MESSAGE);
    yield put(clearRequestFieldsAction());
  }
}

function* submit(promise, requestType) {
  yield put(setLoadingAction(true));
  const requestFields = yield select(states => states.requestFields);
  try {
    const payload = yield getFilialPayload(requestFields);
    yield promise(payload);
    yield successSubmit(requestType);
  } catch (exception) {
    yield catchSubmitException(exception, requestFields);
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* insertFilialHandler() {
  yield submit(insertFilial);
}

function* updateFilialHanlder() {
  yield submit(updateFilial, UPDATE_TYPE);
}

function* findFilialByIdHandler(action) {
  try {
    const filial = yield findFilialById(action.idFilial);
    yield put(setFilialAction(filial.data));
  } catch (exception) {
    yield put(setAlertDialogValuesAction(true, 'error', getExceptionMessage(exception), 'FILIAL_NOT_FOUND'));
  }
}

export default function* watchCrudBordero() {
  yield takeLatest(INSERT_FILIAL, insertFilialHandler);
  yield takeLatest(UPDATE_FILIAL, updateFilialHanlder);
  yield takeLatest(FIND_FILIAL_BY_ID, findFilialByIdHandler);
}
