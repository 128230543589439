export class RequiredFieldValidator {
  constructor(fieldName) {
    this.fieldName = fieldName;
  }

  validate(input) {
    if (typeof input === 'string') {
      return !!input.trim();
    }

    if (input === 'undefined') {
      return false;
    }
    return !!input;
  }
}
