import React, { useEffect, useRef, useState } from 'react';
import {
  FaCheck, FaFileDownload, FaRegFile, FaSearchPlus,
} from 'react-icons/fa';
import { TableCell, TableRow } from '@material-ui/core';
import { MdDeleteForever, MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import { documentosClienteController as controller } from './documentosClienteController';
import { TableDefault } from '../../../../UI/Tabela/TableDefault';
import { TableActionButton, TableActionContainer, TableCellCenter } from '../../../../UI/Tabela/tableDefaultStyles';
import TooltipUI from '../../../../UI/Tooltip/Tooltip';
import ButtonUI from '../../../../UI/Button/ButtonUI';
import useUtilStyles from '../../../../../helpers/styles/utils.styles';
import { toastUnmappedException } from '../../../../../helpers/utils/toastUtils';
import { setLoadingAction } from '../../../../../store/Cliente/V2/Crud/crudCliente.store';
import { useCrudClienteDispatch } from '../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import { SalvarDocumentoCliente } from './SalvarDocumentoCliente/SalvarDocumentoCliente';
import {
  buscarArquivoDocumentoClientePorIdDocumentoService,
  buscarDocumentosClientePorIdClienteService,
  deleteDocumentoClienteService,
} from '../../../../../services/core/cliente/cliente.services';
import { downloadFile, getNewPage, openPagePDF } from '../../../../../helpers/utils/utils';
import { extensao } from '../../../../../types/extensoesDocumentosTypes';
import { DocumentosClienteContainer, HeaderContainerCliente } from './SalvarDocumentoCliente/DocumentosClienteStyles';
import OptionDialogNew from '../../../../UI/Dialogs/OptionDialog/OptionDialogNew';


function DocumentosClienteComponent({ idCliente, isEditMode, isInsertMode }) {
  const dispatch = useCrudClienteDispatch();
  const utilStyles = useUtilStyles();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [documentos, setDocumentos] = useState(controller.makeDocumentos());
  const [idClienteDocumento, setIdClienteDocumento] = useState(null);

  const salvarDocumentoRef = useRef();

  function handleShowDeleteDialog(idDocumento) {
    return () => {
      setIdClienteDocumento(idDocumento);
      setShowDeleteDialog(oldValue => !oldValue);
    };
  }

  function handleOpenEdit(idDocumento) {
    return () => {
      setIdClienteDocumento(idDocumento);
      salvarDocumentoRef.current.handleOpen(idDocumento);
    };
  }

  function handleOpenNovo() {
    salvarDocumentoRef.current.handleOpen();
  }


  // Pageable param sendo tratado igual em DocumentosProcesso.
  async function findAllDocumentosAfterAnyAction() {
    dispatch(setLoadingAction(true));
    const pageableParam = { size: documentos.size, page: documentos.lastPage && documentos.content.length === 1 ? documentos.page - 1 : documentos.page };
    try {
      const documentosResponse = await buscarDocumentosClientePorIdClienteService(idCliente, pageableParam);
      const newDocumentos = controller.makeDocumentos(documentosResponse.data);
      setDocumentos(newDocumentos);
    } catch (e) {
      toastUnmappedException(e, 'Ocorreu um problema ao tentar buscar os documentos');
    } finally {
      dispatch(setLoadingAction(false));
    }
  }

  async function handleDeleteDocumento() {
    dispatch(setLoadingAction(true));
    try {
      await deleteDocumentoClienteService(idClienteDocumento);
      await findAllDocumentosAfterAnyAction();
    } catch (e) {
      toastUnmappedException(e, 'Ocorreu um problema ao tentar excluir o documento');
    } finally {
      handleShowDeleteDialog()();
      dispatch(setLoadingAction(false));
    }
  }

  function ColumnsTableHeadComponent() {
    return (
      <TableRow>
        <TableCell>Extensão</TableCell>
        <TableCell>Tipo</TableCell>
        <TableCell>Descrição</TableCell>
        <TableCell>Nome do Arquivo</TableCell>
        <TableCell>Original Arquivado</TableCell>
        <TableCell>Data de Arquivamento</TableCell>
        <TableCell>Responsável</TableCell>
        <TableCell>Ações</TableCell>
      </TableRow>
    );
  }


  function RowsTableBodyComponent({
    content, onOpenEdit, onShowDeleteDoc,
  }) {
    function handleOpenDocumento(idDocumento) {
      return async () => {
        try {
          const arquivoDocumento = await buscarArquivoDocumentoClientePorIdDocumentoService(idDocumento);
          openPagePDF(arquivoDocumento.data);
        } catch {
          toast.error('Não existem documentos anexados ao Processo',
            { style: { width: '392px' } });
        }
      };
    }

    function handleDownloadDocumento(documento) {
      return async () => {
        try {
          const response = await buscarArquivoDocumentoClientePorIdDocumentoService(documento.idClienteDocumento);
          downloadFile(documento.nomeDocumento, response.data);
        } catch {
          toast.error('Não existem documentos anexados ao Processo',
            { style: { width: '392px' } });
        }
      };
    }

    return content.map((item) => {
      const selectedExtension = extensao[item.extensao];
      const hasNotPdf = (item.extensao && item.extensao.toUpperCase() !== 'PDF');

      return (
        <TableRow key={item.idProcessoDocumento}>
          <TableCellCenter width={64}>
            {selectedExtension || <FaRegFile size={40} color="#9B67B4" />}
          </TableCellCenter>
          <TableCell width={155}>
            <TooltipUI title={item.tipoAlt} placement="bottom">
              <span>{item.tipo}</span>
            </TooltipUI>
          </TableCell>
          <TableCell width={103}>
            <TooltipUI title={item.descricaoAlt} placement="bottom">
              <span>{item.descricao}</span>
            </TooltipUI>
          </TableCell>
          <TableCell width={155}>
            <TooltipUI title={item.nomeDocumentoAlt} placement="bottom">
              <span>{item.nomeDocumento}</span>
            </TooltipUI>
          </TableCell>
          <TableCellCenter width={103}>
            {item.isDocumentoOriginal && <FaCheck size={16} />}
          </TableCellCenter>
          <TableCellCenter width={100}>{item.dataArquivamento}</TableCellCenter>
          <TableCell width={103}>
            <TooltipUI title={item.nomeUsuarioAlt}>
              <span>{item.nomeUsuario}</span>
            </TooltipUI>
          </TableCell>
          <TableCellCenter width={143}>
            <TableActionContainer>
              <TooltipUI title="Baixar" placement="bottom">
                <span>
                  <TableActionButton onClick={handleDownloadDocumento(item)}>
                    <FaFileDownload size={16} />
                  </TableActionButton>
                </span>
              </TooltipUI>
              <TooltipUI
                title={hasNotPdf ? '' : 'Visualizar'}
                placement="bottom"
              >
                <span>
                  <TableActionButton
                    disabled={hasNotPdf}
                    onClick={handleOpenDocumento(item.idClienteDocumento)}
                  >
                    <FaSearchPlus size={16} />
                  </TableActionButton>
                </span>
              </TooltipUI>
              <TooltipUI title={isEditMode ? 'Editar' : ''} placement="bottom">
                <span>
                  <TableActionButton
                    disabled={!isEditMode}
                    onClick={onOpenEdit(item.idClienteDocumento)}
                  >
                    <MdEdit size={21} />
                  </TableActionButton>
                </span>
              </TooltipUI>
              <TooltipUI
                title={isEditMode ? 'Excluir' : ''}
                placement="bottom"
              >
                <span>
                  <TableActionButton
                    disabled={!isEditMode}
                    onClick={onShowDeleteDoc(item.idClienteDocumento)}
                  >
                    <MdDeleteForever size={21} />
                  </TableActionButton>
                </span>
              </TooltipUI>
            </TableActionContainer>
          </TableCellCenter>
        </TableRow>
      );
    });
  }

  async function updateTableAfterChangePageableAttributes(pageable) {
    dispatch(setLoadingAction(true));
    try {
      const documentosResponse = await buscarDocumentosClientePorIdClienteService(idCliente, pageable);
      const newDocumentos = controller.makeDocumentos(documentosResponse.data);
      setDocumentos(newDocumentos);
    } catch (e) {
      toastUnmappedException(e, 'Ocorreu um problema ao tentar buscar os documentos');
    } finally {
      dispatch(setLoadingAction(false));
    }
  }

  async function handlePageChange(e, newPage) {
    const pageable = { size: documentos.size, page: Number(newPage) };
    updateTableAfterChangePageableAttributes(pageable).then();
  }

  function handleChangeRowsPerPage(e) {
    const size = e.target.value;
    const newPage = getNewPage(documentos.size, documentos.page, size);
    const pageable = { size, page: Number(newPage) };
    updateTableAfterChangePageableAttributes(pageable).then();
  }

  /**
   * Carregar dados iniciais da tela
   */
  useEffect(() => {
    dispatch(setLoadingAction(true));
    controller.findInitialDatasOnScreen(idCliente).then((res) => {
      setDocumentos(res.documentos);
    }).catch(() => {
      toastUnmappedException('Ocorreu um problema ao tentar buscar as informações dos documentos');
    }).finally(() => {
      dispatch(setLoadingAction(false));
    });
  }, [idCliente, dispatch]);

  return (
    <>
      <SalvarDocumentoCliente
        idCliente={idCliente}
        onUpdateTable={findAllDocumentosAfterAnyAction}
        ref={salvarDocumentoRef}
      />
      <OptionDialogNew
        open={showDeleteDialog}
        confirmLabel="Sim"
        cancelLabel="Não"
        onClickCancel={handleShowDeleteDialog()}
        onClickConfirm={handleDeleteDocumento}
        text="Deseja realmente excluir o documento?"
      />
      <DocumentosClienteContainer>
        <HeaderContainerCliente>
          <div className={utilStyles.confirmarButton}>
            <ButtonUI
              disabled={!isEditMode || isInsertMode}
              onClick={handleOpenNovo}
            >
              Novo
            </ButtonUI>
          </div>
        </HeaderContainerCliente>
        <div>
          <TableDefault
            columnsHead={(
              <ColumnsTableHeadComponent />
            )}
            page={documentos.page}
            rowsPerPage={documentos.size}
            totalElements={documentos.totalElements}
            totalElementsOnPage={documentos.content?.length}
            totalColumns={9}
            emptyRowsHeight={62}
            rowsPerPageOptions={[5, 10, 15]}
            showTablePagination={documentos?.content?.length > 0}
            withEmptyRows={documentos?.content?.length > 0}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          >
            {documentos?.content?.length > 0 ? (
              <RowsTableBodyComponent
                onOpenEdit={handleOpenEdit}
                onShowDeleteDoc={handleShowDeleteDialog}
                content={documentos?.content}
              />
            ) : []}
          </TableDefault>
        </div>
      </DocumentosClienteContainer>
    </>
  );
}

export const DocumentosCliente = DocumentosClienteComponent;
