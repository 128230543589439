export const ON_CHANGE_REQUEST_FIELDS_ACORDOS = '@acordosAs/ON_CHANGE_REQUEST_FIELDS_ACORDOS';
export const SET_DATAS_CAMPOS_OBRIGATORIOS = '@acordosAs/SET_DATAS_CAMPOS_OBRIGATORIOS';
export const ON_FOCUS_REQUEST_FIELDS_ACORDOS = '@acordosAs/ON_FOCUS_REQUEST_FIELDS,';
export const PESQUISAR_ACORDOS_AS = '@acordos/PESQUISAR_ACORDOS_AS';
export const SET_TABLE_INFO = '@acordos/SET_TABLE_INFO';
export const SET_STORE_VALUE = '@acordos/SET_STORE_VALUE';
export const ON_UPDATE_REQUEST_FIELDS = '@acordos/ON_UPDATE_REQUEST_FIELDS';
export const SET_SNACKBAR_ACORDO = '@acordos/SET_SNACKBAR_ACORDO';
export const SET_CACHE_REQUEST_FIELDS = '@acordos/SET_CACHE_REQUEST_FIELDS';
export const SET_PAGE = '@acordos/SET_PAGE';
export const SET_ROWS_PER_PAGE = '@acordos/SET_ROWS_PER_PAGE';
export const PESQUISAR_ACORDO_ENTRAR_TELA_AS = '@acordos/PESQUISAR_ACORDO_ENTRAR_TELA_AS';
export const RESET_SCREEN = '@acordos/RESET_SCREEN';
