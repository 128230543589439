import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  h3: {
    fontSize: '16px',
    color: '#034ddc',
    fontWeight: '700',
  },
});

export const MainContainer = styled.div`
  margin-top: 8px;

  >div + div {
    margin-top: 8px;
  }
`;

export const FooterContainer = styled.footer`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
`;
