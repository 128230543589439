import React from 'react';
import Typography from '@material-ui/core/Typography';

import cx from 'classnames';
import useStyles from './SubTitleForm.styles';

function SubTitleForm({
  children,
  disable,
  isInputLabel = false,
  className,
}) {
  const styles = useStyles();

  const classes = cx(isInputLabel
    ? { [styles.propertiesInputLabel]: !disable, [styles.disabledInputLabel]: disable }
    : { [styles.properties]: !disable, [styles.disabled]: disable });

  return (
    <Typography
      variant={isInputLabel ? 'inherit' : 'h6'}
      component="h3"
      className={`${classes} ${className}`}
    >
      {children}
    </Typography>
  );
}

export default React.memo(SubTitleForm);
