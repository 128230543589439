import React, {
  memo, useCallback,
} from 'react';
import { InputLabel } from '@material-ui/core';
import { Button } from '@mui/material';
import {
  InputField, TextareaField,
} from '../../../../../../../../components';
import useStyle from './DialogInformacaoComplementarStyles';
import Grid24 from '../../../../../../../../components/UI/Grid24';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { useUtilStyles } from '../../../../../../../../helpers';
import { setSimpleValueRpsEmitidosFormFieldsHandlerAction } from '../../../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { salvarAlteracoesDasInformacoesClienteAction } from '../../../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import Dica from '../../../../../../../../components/UI/Dica/Dica';

function DialogInformacoesComplementares({
  onClose, isInformacaoExtra, informacaoExtraValue,
}) {
  const dispatch = useCCClienteDispatch();
  const style = useStyle();
  const utilStyle = useUtilStyles();

  const informacaoExtra = useCCClienteSelector(states => (states.rpsEmitidos.formFields.informacaoExtra.copy()));
  const informacoesComplementares = useCCClienteSelector(states => (states.rpsEmitidos.formFields.informacoesComplementares.copy()));
  const detalhesInfoExtraNF = useCCClienteSelector(states => (states.rpsEmitidos.detalhesInfoExtraNF));

  const onChangeFields = (event) => {
    const { name, value } = event.target;
    dispatch(setSimpleValueRpsEmitidosFormFieldsHandlerAction(name, value));
  };

  const salvarAlteracoesDasInformacoesCliente = useCallback(() => {
    dispatch(salvarAlteracoesDasInformacoesClienteAction(isInformacaoExtra));
  }, [dispatch, isInformacaoExtra]);

  return (
    <>
      <Grid24 xs={24} sm={24} className={utilStyle.mt8}>
        <Grid24 xs={24} sm={24}>
          <div className={style.inputField}>
            <InputLabel className={style.label}>Informação Extra</InputLabel>
            {isInformacaoExtra === false && informacaoExtraValue === 'N' ? (
              <Dica mensagemDica="Não exige a Informação Extra" />
            )
              : <Dica mensagemDica={detalhesInfoExtraNF} />
            }
          </div>
          <InputField
            isForm
            fullWidth
            labe="Informação Extra"
            name={informacaoExtra.name}
            disabled={!isInformacaoExtra}
            value={informacaoExtra.value}
            onChange={onChangeFields}
          />
        </Grid24>
        <Grid24 xs={24} sm={24} className={utilStyle.mt10}>
          <TextareaField
            label="Informação Complementar"
            disabled={isInformacaoExtra}
            name={informacoesComplementares.name}
            value={informacoesComplementares.value}
            classForm={style.areaSize}
            onChange={onChangeFields}
          />
        </Grid24>
      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
        <Button
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          onClick={salvarAlteracoesDasInformacoesCliente}
        >
          Confirmar
        </Button>
      </div>
    </>
  );
}

export default memo(DialogInformacoesComplementares);
