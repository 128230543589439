import { v4 as uuid } from 'uuid';

import {
  makeCep,
  makeEndereco,
  makeTelefone,
  makeCPFOuCNPJ,
  makeMoney,
} from '../utils/masks.util';

import { toBrazilianDate } from '../utils/utils';


function makeTitulos(titulos) {
  if (!titulos) {
    return [];
  }

  return titulos.map(titulo => ({
    id: uuid(),
    descricaoTitulo: titulo.descricaoTitulo || '',
    numeroTitulo: titulo.numeroTitulo || '',
    dataVencimentoTitulo: toBrazilianDate(titulo.dataVencimentoTitulo) || '',
    valorPrincipalTitulo: makeMoney(titulo.valorPrincipalTitulo, false) || '',
  }));
}

function makeValores(processo) {
  return {
    valorPrincipalProcesso: (processo && makeMoney(processo.valorPrincipalProcesso)) || 'R$ 0.00',
    valorHonorarioProcesso: (processo && makeMoney(processo.valorHonorarioProcesso)) || 'R$ 0.00',
    valorJurosDespesasProcesso: (processo && makeMoney(processo.valorJurosDespesasProcesso)) || 'R$ 0.00',
    valorTotalProcesso: (processo && makeMoney(processo.valorTotalProcesso)) || 'R$ 0.00',
  };
}

function makeCliente(processo) {
  return {
    nome: (processo && processo.nomeCliente) || '',
    cep: (processo && makeCep(processo.cepCliente)) || '',
    endereco: (processo && makeEndereco(processo.enderecoCliente, processo.numeroEnderecoCliente)) || '',
    bairro: (processo && processo.bairroCliente) || '',
    cidade: (processo && processo.cidadeCliente) || '',
    estado: (processo && processo.estadoCliente) || '',
  };
}

function makeDevedor(processo) {
  return {
    nome: (processo && processo.nomeDevedor) || '',
    cnpjCpf: (processo && makeCPFOuCNPJ(processo.cnpjCpfDevedor)) || '',
    email: (processo && processo.emailDevedor) || '',
    telefone: (processo && makeTelefone(processo.dddTelefoneDevedor, processo.numeroTelefoneDevedor)) || '',
    cep: (processo && makeCep(processo.cepDevedor)) || '',
    endereco: (processo && makeEndereco(processo.enderecoDevedor, processo.numeroEnderecoDevedor)) || '',
    cidade: (processo && processo.cidadeDevedor) || '',
    bairro: (processo && processo.bairroDevedor) || '',
    estado: (processo && processo.estadoDevedor) || '',
  };
}


export function makeProcesso(processo) {
  return {
    devedor: makeDevedor(processo),
    cliente: makeCliente(processo),
    valores: makeValores(processo),
    titulos: makeTitulos((processo && processo.titulos) || []),
  };
}
