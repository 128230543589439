import React, { createContext } from 'react';
import { createDispatchHook, createSelectorHook, Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, createStore } from 'redux';
import reducer from './crudFasesPastas.store';
import watchCrudFasesPastas from './crudFasesPastas.saga';


export const crudFasesPastasContext = createContext();
export const useCrudFasesPastasSelector = createSelectorHook(crudFasesPastasContext);
export const useCrudFasesPastasDispatch = createDispatchHook(crudFasesPastasContext);

export default function CrudFasesPastasProvider({ children }) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(watchCrudFasesPastas);

  return (
    <Provider context={crudFasesPastasContext} store={store}>
      {children}
    </Provider>
  );
}
