import React, { useCallback } from 'react';
import {
  useCrudParametrosDispatch,
  useCrudParametrosSelector,
} from '../../../../../store/Parametros/Crud/CrudParametrosProvider';

import { getRequiredLabel } from '../../../../../helpers/utils/utils';
import {
  setRequestFieldsFocusAction,
  setRequestFieldsValueAction,
} from '../../../../../store/Parametros/Crud/crudParametros.store';
import Grid24 from '../../../../UI/Grid24/Grid24';
import InputField from '../../../../UI/Field/Input/InputField';
import FormWrapperTitle from '../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import CustomFieldView from '../../../../UI/CustomFields/CustomFieldView';
import CustomFieldTitle from '../../../../UI/CustomFields/CustomFieldTitle';
import { useUtilStyles } from '../../../../../helpers';
import MoneyField from '../../../../UI/Field/Money/MoneyField';

const CONSTANTS = {
  minutos: '(minutos)',
  dias: '(dias)',
};

function TabCrudParametrosGerais({ isInsertMode }) {
  const dispatch = useCrudParametrosDispatch();
  const style = useUtilStyles();

  const tempoExpiracaoSessaoUsuario = useCrudParametrosSelector(state => state.requestFields.tempoExpiracaoSessaoUsuario.copy());
  const tempoExpiracaoSessaoCliente = useCrudParametrosSelector(state => state.requestFields.tempoExpiracaoSessaoCliente.copy());
  const qtdeDiasTitulosVencidos = useCrudParametrosSelector(state => state.requestFields.qtdeDiasTitulosVencidos.copy());
  const valorMinimoPrestacaoContas = useCrudParametrosSelector(state => state.requestFields.valorMinimoPrestacaoContas.copy());
  const prazoToleranciaBloqueioPrestacaoConta = useCrudParametrosSelector(state => state.requestFields.prazoToleranciaBloqueioPrestacaoConta.copy());


  const ConfigCrudParametrosConstants = {
    TITLE_FIELD: 'Expiração da Sessão do Sistema Web',
    FIELD_TEMPO_EXPIRACAO_CLIENTE: getRequiredLabel('Tempo de expiração da sessão para inatividade do cliente', isInsertMode),
    FIELD_TEMPO_EXPIRACAO_USUARIO: getRequiredLabel('Tempo de expiração da sessão para inatividade do usuário', isInsertMode),
    FIELD_IDADE_MAXIMA: getRequiredLabel('Idade máxima dos Títulos para Cobrança Presencial', isInsertMode),
    FIELD_DIFERENCIAR_PROCESSOS: getRequiredLabel('Diferenciar Processos com todos os Títulos vencidos há mais de', isInsertMode),
    TITLE_FIELD_PRESTACAO_CONTAS: 'Regras para Prestação de Contas',
    FIELD_VALOR_MINIMO: getRequiredLabel('Valor Mínimo para Prestação de Contas', isInsertMode),
    FIELD_TOLERANCIA_PRESTAR_CONTAS: getRequiredLabel('Tolerância antes de bloquear uma Prestação de Contas', isInsertMode),
  };

  const changeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setRequestFieldsValueAction(name, value));
  }, [dispatch]);

  const focusFieldsHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(setRequestFieldsFocusAction(fieldName));
  }, [dispatch]);

  const tabForm = editVisible => (
    <>

      <FormWrapperTitle title={ConfigCrudParametrosConstants.TITLE_FIELD}>
        <Grid24 container isFormContainer spacing="1">
          <Grid24 xs={24} sm={9}>
            <Grid24 xs={24} sm={24}>
              <CustomFieldTitle textField={ConfigCrudParametrosConstants.FIELD_TEMPO_EXPIRACAO_CLIENTE} />
            </Grid24>
            <Grid24 xs={24} sm={3}>
              <InputField
                isForm
                disabled={editVisible}
                name={tempoExpiracaoSessaoCliente.name}
                value={tempoExpiracaoSessaoCliente.value}
                error={tempoExpiracaoSessaoCliente.error}
                errorMessage={tempoExpiracaoSessaoCliente.errorMessage}
                onChange={changeFieldsHandler}
                onFocus={focusFieldsHandler}
                maxLength={3}
              />
            </Grid24>
            <Grid24 xs={24} sm={2}>
              <CustomFieldView textField={CONSTANTS.minutos} />
            </Grid24>
          </Grid24>
          <Grid24 xs={24} sm={9}>
            <Grid24 xs={24} sm={24}>
              <CustomFieldTitle textField={ConfigCrudParametrosConstants.FIELD_TEMPO_EXPIRACAO_USUARIO} />
            </Grid24>
            <Grid24 xs={24} sm={3}>
              <InputField
                style={{ marginBottom: '7px !important' }}
                isForm
                disabled={editVisible}
                name={tempoExpiracaoSessaoUsuario.name}
                value={tempoExpiracaoSessaoUsuario.value}
                error={tempoExpiracaoSessaoUsuario.error}
                errorMessage={tempoExpiracaoSessaoUsuario.errorMessage}
                onChange={changeFieldsHandler}
                onFocus={focusFieldsHandler}
                maxLength={3}
              />
            </Grid24>
            <Grid24 xs={24} sm={2}>
              <CustomFieldView textField={CONSTANTS.minutos} />
            </Grid24>
          </Grid24>
        </Grid24>
      </FormWrapperTitle>

      <FormWrapperTitle title={ConfigCrudParametrosConstants.TITLE_FIELD_PRESTACAO_CONTAS} className={style.mt10}>
        <Grid24 container isFormContainer spacing="1">
          <Grid24 xs={24} sm={9}>
            <Grid24 xs={24} sm={24}>
              <CustomFieldTitle textField={ConfigCrudParametrosConstants.FIELD_VALOR_MINIMO} />
            </Grid24>
            <Grid24 xs={24} sm={15}>
              <MoneyField
                isForm
                fullWidth
                disabled={editVisible}
                name={valorMinimoPrestacaoContas.name}
                value={valorMinimoPrestacaoContas.value}
                error={valorMinimoPrestacaoContas.error}
                errorMessage={valorMinimoPrestacaoContas.errorMessage}
                onChange={changeFieldsHandler}
                onFocus={focusFieldsHandler}
              />
            </Grid24>
          </Grid24>
          <Grid24 xs={24} sm={9}>
            <Grid24 xs={24} sm={24}>
              <CustomFieldTitle textField={ConfigCrudParametrosConstants.FIELD_TOLERANCIA_PRESTAR_CONTAS} />
            </Grid24>
            <Grid24 xs={24} sm={3}>
              <InputField
                style={{ marginBottom: '7px !important' }}
                isForm
                disabled={editVisible}
                name={prazoToleranciaBloqueioPrestacaoConta.name}
                value={prazoToleranciaBloqueioPrestacaoConta.value}
                error={prazoToleranciaBloqueioPrestacaoConta.error}
                errorMessage={prazoToleranciaBloqueioPrestacaoConta.errorMessage}
                onChange={changeFieldsHandler}
                onFocus={focusFieldsHandler}
              />
            </Grid24>
            <Grid24 xs={24} sm={2}>
              <CustomFieldView textField={CONSTANTS.dias} />
            </Grid24>
          </Grid24>
        </Grid24>
      </FormWrapperTitle>


      <Grid24 constainer isFormContainer style={{ marginTop: '20px' }}>
        <Grid24 xs={24} sm={10} style={{ paddingLeft: '8px' }}>
          <Grid24 xs={24} sm={24} style={{ color: 'grey' }}>
            <CustomFieldTitle textField={ConfigCrudParametrosConstants.FIELD_DIFERENCIAR_PROCESSOS} />
          </Grid24>
          <Grid24 xs={24} sm={3}>
            <InputField
              isForm
              disabled={editVisible}
              onChange={changeFieldsHandler}
              onFocus={focusFieldsHandler}
              name={qtdeDiasTitulosVencidos.name}
              value={qtdeDiasTitulosVencidos.value}
              error={qtdeDiasTitulosVencidos.error}
              errorMessage={qtdeDiasTitulosVencidos.errorMessage}
            />
          </Grid24>
          <Grid24 xs={24} sm={2} style={{ color: 'grey' }}>
            <CustomFieldView textField={CONSTANTS.dias} />
          </Grid24>
        </Grid24>

      </Grid24>

    </>
  );

  return (
    <>
      {tabForm(!isInsertMode)}
    </>
  );
}

export default TabCrudParametrosGerais;
