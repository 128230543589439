import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button, Grid, IconButton, Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import cx from 'classnames';
import {
  usePesquisarLogProcessamentoDispatch,
  usePesquisarLogProcessamentoSelector,
} from '../../store/LogProcessamento/LogProcessamentoBorderoProvider';
import {
  changeCacheCriteriasFieldsActions,
  onChangeActionsPageViewAction,
  onChangeSearchCriteriasAction,
  onChangeStatesSnackbarAction,
  onFocusSearchCriteriasAction,
  setPageAction,
} from '../../store/LogProcessamento/logProcessamentoBordero.store';
import SnackbarUI from '../UI/Snackbar/Snackbar';
import { FIRST_PAGE, SNACKBAR_DURATION } from '../../helpers/constants/global.constants';
import useStyles from './logProcessamentoBordero.styles';
import Grid24 from '../UI/Grid24/Grid24';
import {
  LABEL_CODIGO_CLIENTE,
  LABEL_DATE_ATE,
  LABEL_NOME_DEVEDOR,
  LABEL_NUMERO_PROCESSO,
  LABEL_PALAVRA_CHAVE,
  LABEL_PERIODO,
  LABEL_PESQUISA_AVANCADA,
  LABEL_PESQUISA_SIMPLES,
  LABEL_TIPO_INFORMACAO,
} from './logProcessamentoBorderoPage.constants';
import InputField from '../UI/Field/Input';
import SelectorField from '../UI/Field/Selector/SelectorField';
import { findTiposLogProcessamentoBorderoAction } from '../../store/Global/LoadSelectors/loadSelectors.saga';
import withTokenInterceptor from '../../hoc/withTokenInterceptor/withTokenInterceptor';
import LoadingUI from '../UI/Loading/LoadingUI';
import WrapperContent from '../UI/WrapperContent/WrapperContentCustom';
import TabelaLogProcessamento from './TabelaLogProcessamento';
import DateField from '../UI/Field/Date/DateField';
import LogProcessamentoBorderoDetalhes from './Detalhes/LogProcessamentoBorderoDetalhesModal';
import { findLogProcessamentoBorderoDetalhesAction } from '../../store/LogProcessamento/logProcessamentoBordero.saga';


function LogProcessamentoBorderoPage({ location }) {
  const dispatch = usePesquisarLogProcessamentoDispatch();
  const globalDispatch = useDispatch();
  const styles = useStyles();

  const findTiposLogProcessamentoBordero = useSelector(states => states.selectors.tiposLogProcessamentoBordero);

  const searchCriterias = usePesquisarLogProcessamentoSelector(states => ({ ...states.logProcessamentoSearchCriterias }));
  const snackbar = usePesquisarLogProcessamentoSelector(states => ({ ...states.actionsPageView.snackbar }));
  const loading = usePesquisarLogProcessamentoSelector(states => states.actionsPageView.loading);
  const openDetalhesLogProcessamento = usePesquisarLogProcessamentoSelector(states => states.actionsPageView.openDetalhes);
  const isAdvacedSearch = usePesquisarLogProcessamentoSelector(states => states.actionsPageView.isAdvacedSearch);
  const logProcessamentoBorderoDetalhes = usePesquisarLogProcessamentoSelector(states => ({ ...states.logProcessamentoBorderoDetalhes }));

  useEffect(() => {
    globalDispatch(findTiposLogProcessamentoBorderoAction());
  }, [globalDispatch, location.refreshPage]);

  const buttonClassPesquisaAvancada = cx({
    [styles.pesquisaAvancadaButton]: !isAdvacedSearch,
    [styles.pesquisaSimplesButton]: isAdvacedSearch,
  });

  const onClearSearchCriteria = useCallback(() => {
    dispatch(onChangeSearchCriteriasAction('idCliente', ''));
    dispatch(onChangeSearchCriteriasAction('idProcesso', ''));
    dispatch(onChangeSearchCriteriasAction('nomeDevedor', ''));
    dispatch(onChangeSearchCriteriasAction('texto', ''));
    dispatch(onChangeSearchCriteriasAction('tipoBorderoLog', ''));
  }, [dispatch]);

  const onChangeHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeSearchCriteriasAction(name, value));
  }, [dispatch]);

  const onFocusHandler = useCallback((event) => {
    const { name } = event.target;
    dispatch(onFocusSearchCriteriasAction(name));
  }, [dispatch]);

  const onCloseSnackBarhandler = useCallback(() => {
    dispatch(onChangeStatesSnackbarAction({ open: false }));
  }, [dispatch]);

  const onChangePesquisaAvancadaHandler = useCallback(() => {
    onClearSearchCriteria();
    dispatch(onChangeActionsPageViewAction('isAdvacedSearch', !isAdvacedSearch));
  }, [dispatch, isAdvacedSearch, onClearSearchCriteria]);

  const onPesquisarLogProcessamentoHandler = useCallback(() => {
    dispatch(setPageAction(FIRST_PAGE));
    dispatch(changeCacheCriteriasFieldsActions());
  }, [dispatch]);

  const onCloeLogProcessamentoBorderoDetalhesHandler = useCallback(() => {
    dispatch(onChangeActionsPageViewAction('openDetalhes', false));
  }, [dispatch]);

  const onFindDetalhesLogProcessamentoBorderoHandler = useCallback((idBorderoLog) => {
    dispatch(findLogProcessamentoBorderoDetalhesAction(idBorderoLog));
  }, [dispatch]);

  const makeAdvancedSearch = () => (
    (isAdvacedSearch && (
      <>
        <Grid24
          isFormContainer
          spacing="1"
        >
          <Grid24 xl={24} sm={8} alignItems="flex-end">
            <InputField
              fullWidth
              label={LABEL_CODIGO_CLIENTE}
              name={searchCriterias.idCliente.name}
              value={searchCriterias.idCliente.value}
              onChange={onChangeHandler}
              onFocus={onFocusHandler}
              inputProps={{
                maxLength: 10,
              }}
            />
          </Grid24>
          <Grid24 xl={24} sm={8} alignItems="flex-end">
            <SelectorField
              hasEmptyLabel
              label={LABEL_TIPO_INFORMACAO}
              loading={findTiposLogProcessamentoBordero.loading}
              items={findTiposLogProcessamentoBordero.selector}
              name={searchCriterias.tipoBorderoLog.name}
              value={searchCriterias.tipoBorderoLog.value}
              onChange={onChangeHandler}
            />
          </Grid24>
          <Grid24 xl={24} sm={8} alignItems="flex-end">
            <InputField
              fullWidth
              label={LABEL_PALAVRA_CHAVE}
              name={searchCriterias.texto.name}
              value={searchCriterias.texto.value}
              onChange={onChangeHandler}
              onFocus={onFocusHandler}
              inputProps={{
                maxLength: 50,
              }}
            />
          </Grid24>
          <Grid24
            isFormContainer
            spacing="1"
            direction="row"
            justify="flex-end"
            alignItems="flex-end"
          >
            <Grid24 xl={24} sm={8} alignItems="flex-end">
              <InputField
                fullWidth
                label={LABEL_NUMERO_PROCESSO}
                name={searchCriterias.idProcesso.name}
                value={searchCriterias.idProcesso.value}
                onChange={onChangeHandler}
                onFocus={onFocusHandler}
                inputProps={{
                  maxLength: 15,
                }}
              />
            </Grid24>
            <Grid24 xl={24} sm={16} alignItems="flex-end">
              <InputField
                fullWidth
                label={LABEL_NOME_DEVEDOR}
                name={searchCriterias.nomeDevedor.name}
                value={searchCriterias.nomeDevedor.value}
                onChange={onChangeHandler}
                onFocus={onFocusHandler}
                inputProps={{
                  maxLength: 60,
                }}
              />
            </Grid24>
          </Grid24>
        </Grid24>
      </>
    ))
  );

  return (
    <>
      <LoadingUI show={loading} />
      <LogProcessamentoBorderoDetalhes
        onOpenModal={openDetalhesLogProcessamento}
        onCloseHandlerModal={onCloeLogProcessamentoBorderoDetalhesHandler}
        logProcessamentoBorderoDetalhes={logProcessamentoBorderoDetalhes}
      />
      <WrapperContent>
        <SnackbarUI
          open={snackbar.open}
          variant={snackbar.variant}
          message={snackbar.message}
          autoHideDuration={SNACKBAR_DURATION}
          onClose={onCloseSnackBarhandler}
        />
        <Grid24
          isFormContainer
          alignItems="baseline"
          className={styles.div_search_card}
        >
          <Grid24 alignItems="baseline" sm={15} xl={24}>
            {makeAdvancedSearch()}
          </Grid24>
          <Grid24 alignItems="baseline" sm={9} xl={24}>
            <Grid24
              isFormContainer
              spacing="1"
              alignItems="flex-end"
              justify="flex-end"
            >
              <Grid24 alignItems="flex-end" justify="flex-end" xs={24} sm={6} className={styles.search_period_label}>
                <Typography component="p">{LABEL_PERIODO}</Typography>
              </Grid24>
              <Grid24 xs={24} sm={15} alignItems="flex-end" justify="space-between">
                <Grid item sm={5}>
                  <DateField
                    resultWithMask
                    name={searchCriterias.dataCadastramentoInicial.name}
                    value={searchCriterias.dataCadastramentoInicial.value}
                    error={searchCriterias.dataCadastramentoInicial.error}
                    errorMessage={searchCriterias.dataCadastramentoInicial.errorMessage}
                    onChange={onChangeHandler}
                    onFocus={onFocusHandler}
                  />
                </Grid>
                <Grid item sm={2} className={styles.div_date_label}>
                  <Typography component="p">{LABEL_DATE_ATE}</Typography>
                </Grid>
                <Grid item sm={5}>
                  <DateField
                    resultWithMask
                    name={searchCriterias.dataCadastramentoFinal.name}
                    value={searchCriterias.dataCadastramentoFinal.value}
                    error={searchCriterias.dataCadastramentoFinal.error}
                    errorMessage={searchCriterias.dataCadastramentoFinal.errorMessage}
                    onChange={onChangeHandler}
                    onFocus={onFocusHandler}
                  />
                </Grid>

              </Grid24>
              <Grid24 xs={24} sm={3} alignItems="flex-end" justify="flex-end">
                <IconButton className={styles.searchIconButton} onClick={onPesquisarLogProcessamentoHandler}>
                  <SearchIcon />
                </IconButton>
              </Grid24>
            </Grid24>
            <Grid24
              isFormContainer
              spacing="1"
              className={styles.div_pesquisaAvancada}
            >
              <Grid24 xl={24} sm={24} alignItems="flex-end" justify="flex-end" className={styles.pesquisaAvancada}>
                <Button className={buttonClassPesquisaAvancada} onClick={onChangePesquisaAvancadaHandler}>
                  {(isAdvacedSearch) ? LABEL_PESQUISA_SIMPLES : LABEL_PESQUISA_AVANCADA}
                </Button>
              </Grid24>
            </Grid24>
          </Grid24>
        </Grid24>

        <TabelaLogProcessamento
          onClickInLine={onFindDetalhesLogProcessamentoBorderoHandler}
        />
      </WrapperContent>
    </>
  );
}

export default withTokenInterceptor(withRouter(React.memo(LogProcessamentoBorderoPage)));
