import { gateway, RELATORIOS } from '../../api';
import { getUriParams } from '../../../helpers/utils/utils';

export function gerarRelatorioAcessoSite(dataInicial, dataFinal) {
  const params = getUriParams({
    dataInicial, dataFinal,
  });

  return gateway.get(`${RELATORIOS}/acordo-seguro/acesso-site?${params}`, {
    responseType: 'blob',
  });
}

export function gerarRelatorioNotificados(dataInicial, dataFinal) {
  const params = getUriParams({
    dataInicial, dataFinal,
  });

  return gateway.get(`${RELATORIOS}/acordo-seguro/notificados?${params}`, {
    responseType: 'blob',
  });
}
