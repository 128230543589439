import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  wrapper: {
    border: 'solid',
    borderWidth: 'thin',
    paddingLeft: '10px',
    color: '#707070',
    width: '100%',
    cursor: 'default',
    borderRadius: '7px',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    height: '318px',
    marginBottom: '16px',
  },

  verticalLine: {
    padding: '20px 0px',
    height: '318px',
    left: '21px',
    width: '2px',
    backgroundColor: 'grey',
    position: 'absolute',
  },
  loadingWrapper: {
    position: 'relative',
  },
});
