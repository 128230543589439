import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';

export const MainContainer = styled.div`
  margin-top: 7px;

  >div + div {
    margin-top: 16px;
  }
`;

export const FooterContainer = styled.footer`
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
`;

export default makeStyles({
  dialog: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '1200px',
    },
    typography: {
      fontSize: '12px',
    },
  },
});
