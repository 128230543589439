
import { getUriParams } from '../../../helpers';
import {
  CORE, gateway, RELATORIOS,
} from '../../api';

export default {
  findAllPagamentosPrestacaoEspecifica(
    idCliente,
    idRelacaoSelecionada,
    pageable,
    order,
  ) {
    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    const queryParam = getUriParams(pageable);
    return gateway.get(
      `${CORE}/conta-corrente-cliente/pagamentos/${idRelacaoSelecionada}/${idCliente}?${queryParam}${sortedQuery}`,
    );
  },

  findSaldos(
    idCliente,
    idRelacaoSelecionada,
  ) {
    return gateway.get(
      `${CORE}/conta-corrente-cliente/resumo-pagamentos/${idRelacaoSelecionada}/${idCliente}`,
    );
  },

  inserirLancamentoPagamento(
    idCliente,
    isPagamento,
    idPrestacaoPaga,
    idProcesso,
    valor,
  ) {
    const body = {
      idCliente,
      isPagamento,
      idPrestacaoPaga,
      idProcesso,
      valor,
    };
    return gateway.post(
      `${CORE}/conta-corrente-cliente/pagamentos-inserir`, body,
      {
        responseType: 'blob',
      },
    );
  },

  deleteLancamentoPagamento(idContaCorrenteCliente) {
    return gateway.delete(`${CORE}/conta-corrente-cliente/deletar-pagamento/${idContaCorrenteCliente}`);
  },

  downloadRelatorioPagamentos(
    idCliente,
    idPrestacao,
    columnOrdenacao,
  ) {
    const body = {
      idCliente,
      idPrestacao,
    };
    return gateway.post(
      `${RELATORIOS}/conta-corrente-cliente/relatorio/cliente/pagamentos?sort=${columnOrdenacao.element},${columnOrdenacao.order}`,
      body,
      {
        responseType: 'blob',
      },
    );
  },
};
