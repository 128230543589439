import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { FaCheck as FaCheckIcon, FaReceipt } from 'react-icons/fa';
import { MdArrowDropDown as MdArrowDropDownIcon, MdDeleteForever, MdEdit } from 'react-icons/md';

import { CollapseTableButton } from '../CollapseTableButton/CollapseTableButton';
import { CollapseTableRow } from '../CollapseTableRow/CollapseTableRow';
import CheckboxField from '../UI/Field/Checkbox/CheckboxField';
import TooltipUI from '../UI/Tooltip/Tooltip';

import {
  AltTableRow, TableActionButton, TableActionContainer, TableCellCenter, TableCellLeft, TableCellRight,
} from '../UI/Tabela/tableDefaultStyles';

const height = '36px';

const CollapseContainer = styled.div`
  padding: 9px ${props => (props.isManager ? '97px' : '72px')};

  p {
    font-family: Roboto;
    font-size: 12px;
    line-height: 14px;
  }
`;

function TableBodyCaixasFiliaisComponent({
  lancamento, isManager, isUpdated, onSelectRow, onOpenDelete, onShowComprovante, onGoToEditar,
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const disabledEditarAndExcluir = !(isManager || (isUpdated && !lancamento.aprovado));


  return (
    <>
      <AltTableRow>
        {isManager && (
          <TableCellCenter height={height}>
            {!lancamento.aprovado && (
              <CheckboxField
                name={String(lancamento.idCaixaFilial)}
                checked={lancamento.checked}
                onChange={onSelectRow}
              />
            )}
          </TableCellCenter>
        )}

        <TableCellCenter height={height}>
          {lancamento.dataLancamento}
        </TableCellCenter>
        <TableCellLeft height={height}>
          <TooltipUI title={lancamento.lancamento} placement="bottom">
            <span>
              {lancamento.lancamento}
            </span>
          </TooltipUI>
        </TableCellLeft>
        <TableCellLeft height={height}>
          <TooltipUI title={lancamento.nomeUsuarioResponsavelLancamento} placement="bottom">
            <span>
              {lancamento.nomeUsuarioResponsavelLancamento}
            </span>
          </TooltipUI>
        </TableCellLeft>
        <TableCellRight height={height}>
          <span style={lancamento.lancamentoStyles}>
            {lancamento.valorLancamento}
          </span>
        </TableCellRight>
        <TableCellCenter height={height}>
          {lancamento.aprovado && (
            <TooltipUI title={lancamento.labelAprovado} placement="bottom">
              <span>
                <FaCheckIcon />
              </span>
            </TooltipUI>
          )}
        </TableCellCenter>
        <TableCellCenter height={height}>
          <TableActionContainer>
            <TooltipUI title="Visualizar Comprovante" placement="bottom">
              <span>
                <TableActionButton
                  disabled={!lancamento.existeComprovante}
                  onClick={() => onShowComprovante(lancamento.idCaixaFilial)}
                >
                  <FaReceipt size={18} />
                </TableActionButton>
              </span>
            </TooltipUI>
            <TooltipUI title="Editar" placement="bottom">
              <span>
                <TableActionButton
                  onClick={() => onGoToEditar(lancamento.idCaixaFilial)}
                  disabled={disabledEditarAndExcluir}
                >
                  <MdEdit size={21} />
                </TableActionButton>
              </span>
            </TooltipUI>
            <TooltipUI title="Excluir" placement="bottom">
              <span>
                <TableActionButton
                  disabled={disabledEditarAndExcluir}
                  onClick={() => onOpenDelete(lancamento.idCaixaFilial)}
                >
                  <MdDeleteForever size={21} />
                </TableActionButton>
              </span>
            </TooltipUI>
          </TableActionContainer>
        </TableCellCenter>
        <TableCellCenter height={height}>
          <CollapseTableButton
            isOpen={isOpen}
            onClick={() => setIsOpen(!isOpen)}
          >
            <MdArrowDropDownIcon />
          </CollapseTableButton>
        </TableCellCenter>
      </AltTableRow>
      <CollapseTableRow isOpen={isOpen}>
        <CollapseContainer isManager={isManager}>
          <Typography>
            <b>Descrição: </b>
            {lancamento?.descricaoLancamento}
          </Typography>
        </CollapseContainer>
      </CollapseTableRow>
    </>
  );
}

export const TableBodyCaixasFiliais = TableBodyCaixasFiliaisComponent;
