import { CORE, gateway } from '../../api';
import { getUriParams } from '../../../helpers/utils/utils';

export function getTipoAcordoSeguro() {
  return gateway.get(`${CORE}/acordo-seguro`);
}

export function buscarHistoricoAS(periodoAberturaInicial, periodoAberturaFinal, nroProcesso, nomeDevedor, ordenacao, page, size) {
  const params = getUriParams({
    periodoAberturaInicial, periodoAberturaFinal, nroProcesso, nomeDevedor, ordenacao, page, size,
  });

  return gateway.get(`${CORE}/acordo-seguro/processos?${params}`);
}

export function buscarHistoricoProcessoAcordoSeguroPorId(idProcesso) {
  return gateway.get(`${CORE}/acordo-seguro/processos/${idProcesso}`);
}

export function alterarDataPrazoExpiracao(payload) {
  return gateway.put(`${CORE}/acordo-seguro/processos/`, payload);
}
