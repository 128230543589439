import styled from 'styled-components';
import ButtonUI from '../UI/Button/ButtonUI';
import PaperUI from '../UI/Paper/PaperUI';

export const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background: #43A047;
`;

export const BodyContainer = styled(PaperUI)`
  padding: 22px;
  padding-top: 45px; 
  padding-bottom: 45px; 
  height: 226px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0%;


  .ConclusionText {
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: black;
  }

  .FileNameBordero {
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    text-align: center;
    color: black;
  }
`;

export const ImgSuccessIcon = styled.img`
  width: 50px;
  height: 50px;
`;

export const Container = styled(PaperUI)`
  padding: 23px 22px;
  height: 79px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) inset;
  border-radius: 0%;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ConclusionBorderoButton = styled(ButtonUI)`
  width: 169px;
  height: 35px;
`;
