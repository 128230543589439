import React, { useCallback, useState } from 'react';
import {
  Step, StepContent, StepLabel, Stepper,
} from '@material-ui/core';
import PassoUm from './PassoUm/PassoUm';
import { usePagamentoDiretoSelector, usePagamentoDiretoDispatch } from '../../store/PagamentoDireto/PagamentoDiretoProvider';
import {
  setPassoUmControlsAction, setPassoUmDataAction, setSimplePagamentoDiretoStateAction,
} from '../../store/PagamentoDireto/PagamentoDireto.store';
import usePagamentoDiretoStyle from './PagamentoDireto.style';
import LoadingUI from '../UI/Loading/LoadingUI';
import PassoDois from './PassoDois/PassoDois';
import PassoTres from './PassoTres/PassoTres';

export default function PagamentoDireto() {
  const activeStep = usePagamentoDiretoSelector(state => state.activeStep);
  const loadingPage = usePagamentoDiretoSelector(state => state.loadingPage);
  const passoUmControls = usePagamentoDiretoSelector(state => state.controls.passoUm);

  const [selectedIds, setSelectedIds] = useState({});

  const dispatch = usePagamentoDiretoDispatch();
  const style = usePagamentoDiretoStyle();

  const handleNextStep = useCallback(() => {
    dispatch(setSimplePagamentoDiretoStateAction('activeStep', activeStep + 1));
  }, [dispatch, activeStep]);

  const handlePreviousStep = useCallback(() => {
    dispatch(setSimplePagamentoDiretoStateAction('activeStep', activeStep - 1));
  }, [dispatch, activeStep]);

  const handleResetSteps = useCallback(() => {
    setSelectedIds({});
    dispatch(setPassoUmDataAction());
    dispatch(setPassoUmControlsAction());
    dispatch(setSimplePagamentoDiretoStateAction('activeStep', 0));
  }, [dispatch]);

  const STEPS = [
    {
      label: 'Passo 01',
      content: <PassoUm
        handleNextStep={handleNextStep}
        passoUmControls={passoUmControls}
        setSelectedIds={setSelectedIds}
      />,
    },
    {
      label: 'Passo 02',
      content: <PassoDois
        handleNextStep={handleNextStep}
        handlePreviousStep={handlePreviousStep}
        passoUmControls={passoUmControls}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
      />,
    },
    {
      label: 'Passo 03',
      content: <PassoTres
        passoUmControls={passoUmControls}
        handlePreviousStep={handlePreviousStep}
        selectedIds={selectedIds}
        handleResetSteps={handleResetSteps}
      />,
    },
  ];

  return (
    <>
      <LoadingUI show={loadingPage} />
      <Stepper className={style.stepperStyle} activeStep={activeStep} orientation="vertical">
        {
        STEPS.map((step, idx) => (
          <Step key={step.label + idx}>
            <StepLabel>
              {step.label}
            </StepLabel>
            <StepContent>
              {step.content}
            </StepContent>
          </Step>
        ))
      }
      </Stepper>
    </>
  );
}
