import { Button, makeStyles, IconButton } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { FaLock } from 'react-icons/fa';
import PaperUI from '../../../../components/UI/Paper/PaperUI';
import { TableActionButton } from '../../../../components/UI/Tabela/tableDefaultStyles';
import {
  black,
  lightGrey, strongPink, warmGrey, white,
} from '../../../../helpers/constants/colors.constants';
import DateField from '../../../../components/UI/Field/Date/DateField';
import SwitchUI from '../../../../components/UI/Switch/SwitchUI';


export default makeStyles({

  pesquisaAvancadaButton: {
    color: '#034ddc',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    fontSize: '12px',
    textTransform: 'none',
  },

  pesquisaSimplesButton: {
    color: '#707070',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    fontSize: '12px',
    textTransform: 'none',
  },
});

const greyIconStyleFaLock = css`
  color: grey;
  font-size: 16px;
`;

export const FaLockIconGrey = styled(FaLock)`
  ${greyIconStyleFaLock}
`;

export const CustomTableActionButtomCliente = styled(TableActionButton)`
  ${props => props.iscustom && css`
    &.Mui-disabled {
      opacity: 0.32;
    }
  `}
  ${props => props.iscadeadocinza && css`
    cursor: default;
  `}
  ${props => props.isdesbloqueado && css`
    margin-left: 1px;
  `}
`;

export const Container = styled(PaperUI)`
  overflow-x: hidden;
  background-color: transparent;
  margin-top: 22px;
`;

export const Root = styled.div`
  display: flex;
`;

export const ContainerPesquisa = styled.div`
  width: 47%;
  margin: 6px 8px 12px 0;
  box-Shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-Color: white;
`;

export const ContainerDetalhes = styled.div`
  padding: 22px;
  width: 53%;
  margin: 6px 0px 12px;
  box-Shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-Color: white;
`;

export const FiltroPesquisaSimples = styled.div`
  width: 100%;
  display: flex;
  padding: 22px 22px 0 22px;

  > div {
    width: 60%;
      }

  > div + div {
    width: 60%;
    margin-top: 16px;
    display: flex;

    > div {
      margin-right: 10px;
    }

  > p {
      display: flex;
      width: 115px;
      margin: 8px 0 0 8px;
      color: ${warmGrey};
    }

    @media (min-width: 1600px) {
      width: 40%;
    }
  }
`;

export const ButtonPesquisaAvancada = styled.div`
  > div {
    width: 84%;
    display: flex;
    justify-content: flex-end;

    > button {
      width: 30%;
    }
    @media (min-width: 1400px) {
      width: 88%;
    }
  }
`;

export const DateFieldPrestacao = styled(DateField)`
  width: 118px;
`;

export const ButtonPesquisar = styled(IconButton)`
  background-color: ${strongPink};
  color: ${white};
  width: 44px;
  height: 44px;
  margin-top: 5px;
  margin-left: 24px;

  &:hover {
    background-color: ${strongPink};
    opacity: .7;
  }

  &:disabled {
    border: 1px solid ${lightGrey};
  }
`;

export const ContainerPesquisaAvancada = styled.div`
  padding: 8px 22px 0 22px;
  width: 86%;

  > div {
    display: flex;

    #campoFilial{
      width: 85%;
    margin-right: 8px;
    }
  }
`;

export const CardPreviaPrestacao = styled.div`
  display: flex;
  justify-content: right;
  margin-right: 22px;

  >div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 204px;
  height: 61px;
  border-radius: 10px;

  background-color:#564872;

  p {
    display: block;
    font-style: normal;
    line-height: 13px;
    color: ${white};
    font-size:13px ;
  }

  p:first-child {
    margin-top: 11px;
  }

  p:last-child {
    font-size: 14px;
    font-family: Roboto, sans-serif;
    margin-bottom: 10px;
    margin-top: 9px;
  }
  }
 `;

export const ButtonImprimir = styled(Button)`
  display: flex;
  justify-content: space-between;
  margin-left: 22px;
  margin-top: 12px;

  &.MuiButtonBase-root {
    width: 73px;
    padding: 4px 0;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: none;
    color: ${black};

    &.Mui-disabled {
      opacity: 0.64;
    }
  }
`;

export const TableHeader = styled.div`
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
`;

export const ContainerInfosCliente = styled.div`
  display: flex;
  margin-bottom: 16px;

  > div {
    margin-left: 11px;
    width: 100%;

    > h3 {
      margin-bottom: 9px;
    }
  }
`;

export const CamposDetalheCliente = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 100%;

    > div {
      display: flex;
      gap: 8px;

      #campoPeriodicidade{
        width:49% ;
      }
    }
    #campoStatus{
        width:66% ;
      }
    > div {
    gap: 8px;
  }
`;

export const ContainerResumo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  > div {
    display: flex;
    justify-content: center;

    > p {
      font-family: Roboto, sans-serif;
      font-weight: bold;
      font-size: 16px;
    }
  }

  > div {
    gap: 8px;
  }
`;

export const Card = styled.div`
  display: block;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  background-color: ${props => props.bgColor};
  color: white;

  & span:first-child {
    font-size: 13px;
  }

  & span {
    font-family: Roboto;
    font-weight: normal;
    display: block;
    font-size: 11px;
    margin-top: 5px;
  }

  ${props => (props?.detailInfo ? css`
    min-height: 84px;
    max-height: 120px;

    > div {
      display: block;
      padding: 0 8px 0 8px;

      & hr {
        border: none;
        height: 1px;
        margin: 1px;
        color: ${props.lineColor};
        background-color: ${props.lineColor};
      }

      & div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1px;

        & p {
          font-size: 11px;
        }
      }
    }
  ` : css`
    height: 50px;
  `)}
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 52px;
  border-radius: 10px;

  background-color: ${props => props.color};

  p {
    display: block;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
  }

  p:first-child {
    padding-top: 5px;
    margin-Top: 8px;
    font-size: 14px;
    font-weight: bold;
  }

  p:last-child {
    padding-top: 7px;
    padding-bottom: 13px;
    font-size: 16px;
    font-weight: 500;
  }
`;

export const Switch = styled(SwitchUI)`
  margin-left: 22px;
`;


export const ContainerPrestacaoContas = styled.div`
  width: 100%;
  border: 1px solid ${lightGrey};
  margin-top: 16px;
  justify-content: space-between;
  padding: 8px;

  & :nth-child(2) {
    margin-top: 3px;
  }

  > div {
    display: flex;
    justify-content: space-between;

    > span {
      display: inline-flex;
      width: 58%;
      gap:8px ;

      @media (min-width: 1400px) {
        width: 50%;
      }
       @media (min-width: 1600px) {
         width: 44%;
      }
        @media (min-width: 1900px) {
         width: 36%;
      }

    }

    > div {
      width: 165px;
      height: 35px;
    }
  }

  > div + div {
    margin-top: 15px !important;
    margin-bottom: 7px !important;

    > div {
      width: 165px;
      height: 35px;
    }
  }
`;
