import { takeLatest, put, select } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { findFiliais, deleteFilial } from '../../../services/core/filial/filial.services';

import {
  setSettingsTableAction, setLoadingAction, setAlertDialogValuesAction, clearSearchModeAction,
} from './pesquisarFilial.store';

import { isNotFoundException, isBadRequestException, getExceptionMessage } from '../../../helpers/utils/utils';
import { RESULT_NOT_FOUND } from '../../../helpers/constants/global.constants';

const FIND_FILIAIS = 'FIND_FILIAIS_PESQUISA_FILIAL';
const DELETE_FILIAL = 'DELETE_FILIAL';

export const findFiliaisAction = (name, page, rowsPerPage, origin) => ({
  type: FIND_FILIAIS,
  name,
  page,
  rowsPerPage,
  origin,
});

export const deleteFilialAction = idFilial => ({
  type: DELETE_FILIAL,
  idFilial,
});

function setMessageWhenResultNotFound(length) {
  if (length === 0) {
    toast.warn(RESULT_NOT_FOUND);
  }
}

function* updateTableFiliais(name, page, rowsPerPage) {
  let filiais = yield findFiliais(name, page, rowsPerPage);
  if (filiais.data.content.length === 0 && filiais.data.totalElements !== 0) {
    const lastPage = yield filiais.data.totalPages - 1;
    filiais = yield findFiliais(name, lastPage, rowsPerPage);
  }
  yield put(setSettingsTableAction(filiais.data.content, filiais.data.number, filiais.data.size,
    filiais.data.totalElements));
  return filiais;
}

function* findFiliaisHandler(actions) {
  yield put(setLoadingAction(true));
  const isSearchMode = yield select(states => states.pesquisaFilial.searchMode);
  const { name, page, rowsPerPage } = actions;
  try {
    const filiais = yield updateTableFiliais(name, page, rowsPerPage);
    if (isSearchMode) {
      yield setMessageWhenResultNotFound(filiais.data.content.length);
    }
  } finally {
    yield put(clearSearchModeAction());
    yield put(setLoadingAction(false));
  }
}

function* deleteFilialHandler(actions) {
  yield put(setLoadingAction(true));

  const searchCriterias = yield select(states => ({
    nome: states.pesquisaFilial.requestFields.nome.getValueNotEmpty(),
    page: states.pesquisaFilial.settingsTable.page,
    rowsPerPage: states.pesquisaFilial.settingsTable.rowsPerPage,
  }));

  try {
    yield deleteFilial(actions.idFilial);
    yield updateTableFiliais(searchCriterias.nome, searchCriterias.page, searchCriterias.rowsPerPage);
  } catch (exception) {
    if (isNotFoundException(exception) || isBadRequestException(exception)) {
      yield put(setAlertDialogValuesAction(true, 'error', getExceptionMessage(exception)));
    }
    if (isNotFoundException(exception)) {
      yield updateTableFiliais(searchCriterias.nome, searchCriterias.page, searchCriterias.rowsPerPage);
    }
  } finally {
    yield put(setLoadingAction(false));
  }
}

export default function* watchPesquisaFilial() {
  yield takeLatest(FIND_FILIAIS, findFiliaisHandler);
  yield takeLatest(DELETE_FILIAL, deleteFilialHandler);
}
