import React, { createContext } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider, createSelectorHook, createDispatchHook } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import reducer from './RegistrarBoleto.store';
import watchRegistrarBoleto from './RegistrarBoleto.saga';

export const RegistrarBoletoContext = createContext();
export const useRegistrarBoletoSelector = createSelectorHook(RegistrarBoletoContext);
export const useRegistrarBoletoDispatch = createDispatchHook(RegistrarBoletoContext);

export default function RegistrarBoletoProvider({ children }) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(watchRegistrarBoleto);

  return (
    <Provider context={RegistrarBoletoContext} store={store}>
      {children}
    </Provider>
  );
}
