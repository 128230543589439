import React from 'react';
import { Grid } from '@material-ui/core';
import { Accordion } from '../UI/Inspecionar/Accordion/Accordion';
import {
  AccordionDetails, SummaryParameters, TextSummary, Title,
} from './consultaDinamicaParameters.styles';
import InputField from '../UI/Field/Input/InputField';
import NumberInput from '../UI/Field/Number/NumberInput';
import MoneyField from '../UI/Field/Money/MoneyField';
import DateField from '../UI/Field/Date/DateField';
import { getFieldName, toAmericanDate } from '../../helpers';


function InputXpto({
  type, updateParametersFields, ...rest
}) {
  const inputs = {
    INTEGER: NumberInput,
    DECIMAL: MoneyField,
    MONEY: MoneyField,
    DATE: DateField,
    TEXT: InputField,
  };

  if (!inputs[type]) {
    return null;
  }

  const Input = inputs[type];

  return (
    <Input
      {...rest}
      onChange={(e) => {
        const name = getFieldName(e.target);
        const { value } = e.target;
        updateParametersFields(oldValue => ({
          ...oldValue,
          [name]: value,
        }));
      }}
      inputProps={{ 'data-type': type }}
      acceptEmptyValue
    />
  );
}


function ConsultaDinamicaParametersComponent({
  summary, formRef, updateParametersFields, parametersFields, onChangeParametersPayload,
}) {
  async function onSubmit(e) {
    e.preventDefault();
    const payload = summary.parametros.map((param) => {
      let value = parametersFields[param.parametro];
      if (param.tipo === 'DATE') {
        value = toAmericanDate(parametersFields[param.parametro]);
      }

      return {
        parametro: param.parametro,
        tipo: param.tipo,
        valor: value,
      };
    });

    onChangeParametersPayload(payload);
  }

  let columnsNamesSummary = '';
  if (summary?.resultado) {
    columnsNamesSummary = summary?.resultado.join(', ');
  }

  return (
    <form ref={formRef} onSubmit={onSubmit}>
      <Accordion title="Resumo da Consulta">
        <AccordionDetails>
          <TextSummary>{`Esta consulta traz os seguintes resultados: ${columnsNamesSummary}.`}</TextSummary>
        </AccordionDetails>
      </Accordion>
      {summary?.parametros.length > 0 && (
        <Title>Informe os Parâmetros</Title>
      )}
      <SummaryParameters container spacing={1}>
        {summary?.parametros.map(((param, i) => (
          <Grid item xs={12} sm={4} key={i}>
            <InputXpto
              isForm
              fullWidth
              value={parametersFields[param.parametro]}
              type={param.tipo}
              label={param.nome}
              name={param.parametro}
              updateParametersFields={updateParametersFields}
            />
          </Grid>
        )))}
      </SummaryParameters>
    </form>
  );
}

export const ConsultaDinamicaParameters = ConsultaDinamicaParametersComponent;
