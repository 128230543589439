import React from 'react';

import NumberFormat from 'react-number-format';
import InputField from '../Input/InputField';

function MaskFieldWithResultMask(props) {
  const {
    inputRef, onChange, name, ...other
  } = props;
  return (
    <NumberFormat
      {...other}
      name={name}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
            name,
          },
        });
      }}
    />
  );
}

function MaskFieldWithoutResultMask(props) {
  const { inputRef, ...other } = props;
  return <NumberFormat getInputRef={inputRef} {...other} />;
}

function MaskField({
  resultWithMask = false, format, inputProps, name, ...props
}) {
  function getMaskField() {
    return resultWithMask ? MaskFieldWithoutResultMask : MaskFieldWithResultMask;
  }

  return (
    <InputField
      {...props}
      inputProps={{ format, name, ...inputProps }}
      inputComponent={getMaskField()}
    />
  );
}

export default React.memo(MaskField);
