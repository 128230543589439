import React, { useCallback, useState } from 'react';
import { ButtonUI, SelectorField } from '../../../../../../../components';
import { SimpleTitle } from '../../../../../../../components/Title/SimpleTitle/SimpleTitle';
import { InputFileIcon } from '../../../../../../../components/UI/InputFile/InputFileIcon';
import { getRequiredLabel } from '../../../../../../../helpers';
import { importarArquivoRetornoAction, pesquisarDadosGeracaoNotaFiscalAction } from '../../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import {
  resetFormFieldsNFAction, setDadosGeracaoNFSwitchAction, setSimpleValueDadosGeracaoNFAction, setSimpleValueDadosGeracaoNFArquivoAction,
} from '../../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { Button, Container, Header } from './DadosGeracaoNFStyles';
import OptionDialogNew from '../../../../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';

function MakePrefeituras() {
  return [
    { id: 1, value: 'Prefeitura de São Paulo' },
    { id: 2, value: 'Prefeitura de Curitiba' },
    { id: 3, value: 'Outras' },
  ];
}

function ImportarArquivoRetornoComponent() {
  const dispatch = useCCClienteDispatch();

  const formFields = useCCClienteSelector(states => states.dadosGeracaoNF.formFields);
  const openDialogErro = useCCClienteSelector(states => (states.dadosGeracaoNF.openDialogErro));
  const openDialogSucesso = useCCClienteSelector(states => (states.dadosGeracaoNF.openDialogSucesso));
  const rpsProcessados = useCCClienteSelector(states => (states.dadosGeracaoNF.rpsProcessados));
  const prefeituras = React.useMemo(() => MakePrefeituras(), []);
  const [arquivoRemessa, setArquivoRemessa] = useState(null);

  const onChangeFields = (event) => {
    const { name, value, checked } = event.target;
    dispatch(setDadosGeracaoNFSwitchAction(name, checked || value));
  };

  const onChangeFieldsFile = (event) => {
    const { name, value } = event.target;
    dispatch(setSimpleValueDadosGeracaoNFArquivoAction(name, value));
  };

  const onClickConfirmar = useCallback(() => {
    dispatch(importarArquivoRetornoAction(arquivoRemessa));
  }, [dispatch, arquivoRemessa]);

  const onClose = useCallback(() => {
    dispatch(setSimpleValueDadosGeracaoNFAction('openDialogErro', false));
  }, [dispatch]);

  const onClickConfirm = useCallback(() => {
    dispatch(pesquisarDadosGeracaoNotaFiscalAction());
    dispatch(setSimpleValueDadosGeracaoNFAction('openDialogSucesso', false));
    dispatch(setSimpleValueDadosGeracaoNFAction('openButtonImportarArquivo', false));
    dispatch(resetFormFieldsNFAction());
  }, [dispatch]);

  const hasPrefeitura = formFields.grupoPrefeituraNotaFiscal.value === '';
  const hasArquivoAndPrefeitura = formFields.grupoPrefeituraNotaFiscal.value === '' || formFields.arquivoRemessaRetorno === '';

  return (
    <>
      <OptionDialogNew
        open={openDialogErro === true}
        confirmLabel="OK"
        onClickConfirm={onClose}
      >
        <div style={{ fontSize: '18px' }}>
          <p>
            Arquivo Inválido
            <br />
            O Arquivo não obedece ao layout esperado!
          </p>
        </div>
      </OptionDialogNew>
      <OptionDialogNew
        open={openDialogSucesso === true}
        confirmLabel="OK"
        onClickConfirm={onClickConfirm}
      >
        <div style={{ fontSize: '18px' }}>
          <p>
            Arquivo importado com sucesso:
            <br />
            Total de Notas Fiscais do Arquivo:
            {' '}
            {rpsProcessados?.qtdeRps || 0}
            <br />
            Total de RPS atualizados:
            {' '}
            {rpsProcessados?.qtdeRpsAtualizados || 0}
            <br />
            Total de RPS não localizados:
            {' '}
            {rpsProcessados?.qtdeRpsNaoLocalizados || 0}
            {' '}
          </p>
        </div>
      </OptionDialogNew>
      <Container>
        <SimpleTitle
          text="Importar Arquivos de Retorno"
        />
        <Header>
          <SelectorField
            isForm
            label={getRequiredLabel('Prefeitura', true)}
            items={prefeituras}
            name="grupoPrefeituraNotaFiscal"
            value={formFields.grupoPrefeituraNotaFiscal.value}
            onChange={onChangeFields}
          />
          <InputFileIcon
            isForm
            acceptAll
            label="Arquivo de Retorno"
            name="arquivoRemessaRetorno"
            labelName="arquivoRemessaRetorno"
            value={formFields?.arquivoRemessaRetorno || ''}
            onChange={onChangeFieldsFile}
            setFile={setArquivoRemessa}
            disabled={hasPrefeitura}
          />
        </Header>
        <Button>
          <div>
            <ButtonUI
              color="success"
              disabled={hasArquivoAndPrefeitura}
              onClick={onClickConfirmar}
            >
              Confirmar
            </ButtonUI>
          </div>
        </Button>
      </Container>
    </>
  );
}
export const ImportarArquivoRetorno = ImportarArquivoRetornoComponent;
