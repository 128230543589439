import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export default makeStyles({
  div__buttons: {
    width: '166px',
    height: '35px',
    marginRight: '22px',
  },
  div__align: {
    display: 'flex',
    marginTop: '22px',
    marginBottom: '22px',
    flexDirection: 'row-reverse',
  },

});

export const WarningContainer = styled.div`
  text-align: center;
  font-weight: bold;
`;
