import React from 'react';

import PrivateRoute from '../../hoc/PrivateRoute/PrivateRoute';

import CadastrarFilialPage from './Cadastar/CadastrarFilialPage';
import DetalharFilialPage from './Detalhes/DetalharFilialPage';
import EditarFilialPage from './Atualizar/EditarFilialPage';

import PesquisaFilialPage from './Pesquisar/PesquisarFilialPage';

export default function RoutesFilial() {
  return (
    <>
      <PrivateRoute exact path="/filial" component={PesquisaFilialPage} />
      <PrivateRoute
        exact
        path="/filial/cadastrar"
        roles={['101']}
        tipoUsuario="U"
        component={CadastrarFilialPage}
      />
      <PrivateRoute
        exact
        path="/filial/:id/detalhar"
        tipoUsuario="U"
        component={DetalharFilialPage}
      />
      <PrivateRoute
        exact
        path="/filial/:id/editar"
        roles={['101']}
        tipoUsuario="U"
        component={EditarFilialPage}
      />
    </>
  );
}
