import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function CancelarBoletoIcon(props) {
  // eslint-disable-next-line max-len
  const path = 'M15.698 19L13.754 17.0297L11.8101 19L10.5173 17.6865L12.4612 15.7254L10.5173 13.7644L11.8101 12.4601L13.754 14.4211L15.698 12.4601L17 13.7644L15.0469 15.7254L17 17.6865L15.698 19V19ZM9.16936 0H1.83387C0.816073 0 0 0.823272 0 1.85005V16.6504C0 17.6772 0.816073 18.5005 1.83387 18.5005H8.99515C8.50917 17.6587 8.25243 16.6967 8.25243 15.7254C8.25243 12.6636 10.719 10.1753 13.754 10.1753C14.0658 10.1753 14.3684 10.203 14.671 10.2493V5.55015L9.16936 0ZM8.25243 6.47517V1.38754L13.2956 6.47517H8.25243Z';
  return (
    <SvgIcon transform="scale(1.3)" viewBox="-6 -6 30 30">
      <path d={path} {...props} />
    </SvgIcon>
  );
}
