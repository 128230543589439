import FieldModel from '../../models/FieldModel';

export function buildRequestFields(data) {
  return {
    idRequisicao: data.idRequisicao,
    nome: new FieldModel({
      name: 'nome',
      value: data.nome,
    }),
    enviarNotificacao: new FieldModel({
      name: 'enviarNotificacao',
      value: data.enviarNotificacao,
    }),
    descricaoAtividades: new FieldModel({
      name: 'descricaoAtividades',
      value: data.descricaoAtividades,
    }),
  };
}

export function buildUsuariosAtivosRequisicoes(usuariosAtivos) {
  return usuariosAtivos.map(data => new Array(data.nomeUsuario));
}
