import React from 'react';
import { useDispatch } from 'react-redux';


import CrudUsuarioProvider from '../../../../../store/Usuario/V2/CrudUsuarioProvider';
import CrudUsuario from '../../../../../components/Usuario/V2/CrudUsuario';
import { initializePageAction } from '../../../../../store/theme.actions';
import withTokenInterceptor from '../../../../../hoc/withTokenInterceptor/withTokenInterceptor';


function DetalharUsuarioPage() {
  const dispatch = useDispatch();
  dispatch(initializePageAction('Usuários > Dados Cadastrais'));

  return (
    <CrudUsuarioProvider>
      <CrudUsuario isInspectMode />
    </CrudUsuarioProvider>
  );
}

export default withTokenInterceptor(DetalharUsuarioPage);
