import styled, { css } from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
  padding-left: 10px;

  > div:first-child {
    width: 40%;
  }

  > div:last-child {
    width: 40%;
    margin-right: 10px;
  }
`;


export const ListBox = styled.div`
  height: 234px;
  padding: 11px 1px 37.3px 0;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: white;
  overflow-y: auto;
  margin-top: 7px;

  > div {
    display: block;
    padding-left: 24px;
    margin-top: 16px;
  }
`;


export const SwapRoot = styled.div`
  display: flex;
  align-items: center;
  height: 117px;
`;

export const SwapButtons = styled.div`
  display: block;

  button {
    display: block;
    margin-top: 40px;
    padding: 10px 10px 10px 10px;
    border-radius: 50%;
    background-color: white;

    svg {
      display: flex;
      width: 25px;
      height: 25px;
    }
  }

  button:first-child {
    ${props => (props?.disabledRight ? css`
      border: 1px solid #C6C6C6;
      cursor: default;
    ` : css`
      border: 1px solid #564872;
      cursor: pointer;

      svg {
        color: #564872;
      }
    `)}
  }

  button:last-child {
    ${props => (props?.disabledLeft ? css`
      border: 1px solid #C6C6C6;
      cursor: default;
    ` : css`
      border: 1px solid #564872;
      cursor: pointer;

      svg {
        color: #564872;
      }
    `)}
    svg {
      margin-left: 2px;
    }
  }
`;
