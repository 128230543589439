import styled from 'styled-components';
import { widthScreen } from '../../helpers/styles/mediaScreenConstants';
import { navigationBarWidth } from '../../helpers/styles/sharedNavigationStyles';

export const MainContainer = styled.main`
  position: relative;

  margin-left: 0;

  transition: margin-left 0.2s linear;

  @media screen and (min-width: ${widthScreen.small}) {
    margin-left: ${navigationBarWidth.onlyIcons}px;
  }
`;

export const Content = styled.div`
  overflow: auto;
  background-color: #f1f4f9;
  margin: 8px;
`;
