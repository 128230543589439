import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  div__principal: {
    display: 'flex',
    marginBottom: '15px',
    marginLeft: '15px',
    marginTop: '13px',
  },
});

export const AntecedentesProcessoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  >div:nth-child(1) {
    display: inline-block;
    padding: 8px 14px 16px 14px;

    >span + span {
      margin-left: 8px;
    }

  }
`;

export const SemAntecedenteContainer = styled.div`
  width: 100%;
  display: block;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;


  svg {
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 16px;
  }

  b {
    font-size: 14px;
  }
`;

export const ButtonInspecionar = styled.div`
  display: flex;
  cursor: pointer;
`;
