import FieldModel, { MONEY } from '../../models/FieldModel';
import { getAttributeExistingObject } from '../utils/utils';

export function buildFaixaPrazoExpiracao(faixaPrazoExpiracao) {
  return ({
    idClientePrazoExpiracao: new FieldModel({
      value: getAttributeExistingObject(faixaPrazoExpiracao, 'idClientePrazoExpiracao'),
    }),
    valorInicial: new FieldModel({
      name: 'valorInicial',
      value: getAttributeExistingObject(faixaPrazoExpiracao, 'valorInicial', 0),
      errorMessage: 'Campo obrigatório',
      required: true,
      type: MONEY,
    }),
    valorFinal: new FieldModel({
      name: 'valorFinal',
      value: getAttributeExistingObject(faixaPrazoExpiracao, 'valorFinal'),
      errorMessage: 'Campo obrigatório',
      required: true,
      type: MONEY,
    }),
    prazoExpiracao: new FieldModel({
      name: 'prazoExpiracao',
      value: getAttributeExistingObject(faixaPrazoExpiracao, 'prazoExpiracao', 1),
      maxLength: 7,
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
  });
}

export function buildFaixasPrazoExpiracao(faixasPrazoExpiracao) {
  if (faixasPrazoExpiracao.length === 0) {
    const faixaPrazo = {
      valorInicial: 0,
      valorFinal: 9999999999.99,
      prazoExpiracao: 1,
    };
    faixasPrazoExpiracao.push(faixaPrazo);
  }
  return faixasPrazoExpiracao.map(buildFaixaPrazoExpiracao);
}

export function getDefaultFieldsFaixa() {
  return {
    idClientePrazoExpiracao: new FieldModel({
      name: 'idClientePrazoExpiracao',
    }),
    valorInicial: new FieldModel({
      name: 'valorInicial',
      value: 0,
      required: true,
      type: MONEY,
    }),
    valorFinal: new FieldModel({
      name: 'valorFinal',
      value: 9999999999.99,
      required: true,
      type: MONEY,
    }),
    prazoExpiracao: new FieldModel({
      name: 'prazoExpiracao',
      value: 1,
      maxLength: 7,
      required: true,
    }),
  };
}
