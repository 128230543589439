import FieldModel, { DATE, POSITIVE_INTEGER } from '../../../models/FieldModel';
import { CrudUsuarioStoreConst } from '../../../helpers/constants/layout.constants';
import { convertDate, getAttributeExistingObject } from '../../../helpers/utils/utils';
import { buildEndereco } from '../../../helpers/factory/endereco.factory';
import { buildTelefones } from '../../../helpers/factory/telefone.factory';
import {
  APPLY_SNACKBAR_ERROR_CONFIG,
  BUILD_USUARIO,
  CHECK_ENABLED_ADVOGADO_ASSISTENTE_EXTERNO,
  CHECK_TITLE_PERMISSION_CHECKBOX,
  CLEAR_STATES,
  CLEAR_TO_INITIAL_STATES,
  CLOSE_ERROR_DIALOG_CONTEXT, CONFIGURE_DIALOG_REFERENTE_EQUIPE, MESSAGES_REFERENTE_EQUIPE,
  ON_ATUALIZAR_PERMISSOES,
  ON_CHANGE_ACTIONS,
  ON_CHANGE_COMBO_FASES,
  ON_CHANGE_CONSTROLS,
  ON_CHANGE_FULLY_CONTROLS,
  ON_CHANGE_REQUISICOES,
  ON_CHANGE_TIPO_DOCUMENTO,
  ON_CLEAR_COMBO_FILIAL_USUARIO,
  ON_CLEAR_PERMISSION_SELECTOR, ON_FOCUS_COMBO_FASES,
  ON_FOCUS_FIELDS,
  ON_RELOAD_COMBOS_PERMISSOES,
  OPEN_ERROR_DIALOG_CONTEXT,
  SET_ACTIONS_ATTRIBUTE, SET_ALERT_DIALOG,
  SET_ENDERECO,
  SET_ENDERECO_ATTRIBUTE_FOCUS,
  SET_ENDERECO_ATTRIBUTE_VALUE,
  SET_IMAGE, SET_MEMBROS_EQUIPE,
  SET_TELEFONE_ATTRIBUTE_FOCUS,
  SET_TELEFONE_ATTRIBUTE_VALUE,
  SET_TELEFONES,
  SUCCESSFULLY_INSERTED,
  ON_CHANGE_FASE_ATUAL,
  ON_CLICK_ADICIONAR_FASE,
  ON_CLICK_EXCLUIR_FASE,
  CHANGE_MUDANCA_TIPO_USUARIO,
  DIALOG_MUDANCA_TIPO_USUARIO,
  PERMITIR_SUBMIT_MUDANCA_TIPO_USUARIO,
  ZERAR_FASES_SELECIONADAS_COMISSAO,
  ON_MAX_FASES_SELECTED,
  ON_CHANGE_IDS_TIPOS_USUARIOS,
  OPEN_ERROR_VINCULADO_DIALOG_CONTEXT,
  CLOSE_ERROR_VINCULADO_DIALOG_CONTEXT,
} from './crudUsuario.constans';
import TableHeader from '../../../models/TableHeader';

const EMPTY_PASSWORD = '      ';

function buildRequisicoes(requisicoes) {
  return (requisicoes) ? requisicoes.map(requisicao => requisicao.idRequisicao) : [];
}

function buildEmptyFase() {
  return {
    idFase: new FieldModel({
      name: 'idFase',
      value: 0,
    }),
    porcentagemComissao: new FieldModel({
      name: 'porcentagemComissao',
      value: '0.00',
    }),
  };
}

function buildFaseSelecionada(faseSelecionada) {
  return {
    idFase: new FieldModel({
      name: 'idFase',
      value: getAttributeExistingObject(faseSelecionada, 'idFase'),
    }),
    porcentagemComissao: new FieldModel({
      name: 'porcentagemComissao',
      value: getAttributeExistingObject(faseSelecionada, 'porcentagemComissao'),
    }),
  };
}

function buildFasesSelecionadas(fasesSelecionadas) {
  return (fasesSelecionadas) ? fasesSelecionadas.map(buildFaseSelecionada) : [];
}

function buildControls(controls, membrosEquipe) {
  return {
    nome: new FieldModel({
      name: 'nome',
      value: getAttributeExistingObject(controls, 'nome'),
      maxLength: 60,
    }),
    login: new FieldModel({
      name: 'login',
      value: getAttributeExistingObject(controls, 'login'),
      maxLength: 25,
    }),
    senha: new FieldModel({
      name: 'senha',
      value: getAttributeExistingObject(controls, 'senha'),
      maxLength: 25,
    }),
    cpf: new FieldModel({ name: 'cpf', value: getAttributeExistingObject(controls, 'cpf') }),
    rg: new FieldModel({
      name: 'rg',
      value: getAttributeExistingObject(controls, 'rg'),
      maxLength: 15,
    }),
    matricula: new FieldModel({
      name: 'matricula',
      value: getAttributeExistingObject(controls, 'matricula'),
      type: POSITIVE_INTEGER,
      maxLength: 9,
    }),
    dataNascimento: new FieldModel({
      name: 'dataNascimento',
      value: getAttributeExistingObject(controls, 'dataNascimento'),
      errorMessage: 'Data inválida',
      type: DATE,
    }),
    permitirLogar: new FieldModel({
      name: 'permitirLogar',
      value: getAttributeExistingObject(controls, 'permitirLogar', false),
    }),
    email: new FieldModel({
      name: 'email',
      value: getAttributeExistingObject(controls, 'email'),
      maxLength: 50,
    }),
    dadosBancoFavorecido: new FieldModel({
      name: 'dadosBancoFavorecido',
      value: getAttributeExistingObject(controls, 'dadosBancoFavorecido'),
      maxLength: 60,
    }),
    dadosBancoTipoDocumento: new FieldModel({
      name: 'dadosBancoTipoDocumento',
      value: getAttributeExistingObject(controls, 'dadosBancoTipoDocumento', 'CPF'),
    }),
    dadosBancoDocumento: new FieldModel({
      name: 'dadosBancoDocumento',
      value: getAttributeExistingObject(controls, 'dadosBancoDocumento'),
      maxLength: 15,
    }),
    idBanco: new FieldModel({ name: 'idBanco', value: getAttributeExistingObject(controls, 'idBanco') }),
    dadosBancoAgencia: new FieldModel({
      name: 'dadosBancoAgencia',
      value: getAttributeExistingObject(controls, 'dadosBancoAgencia'),
      maxLength: 6,
    }),
    dadosBancoConta: new FieldModel({
      name: 'dadosBancoConta',
      value: getAttributeExistingObject(controls, 'dadosBancoConta'),
      maxLength: 15,
    }),
    idTipoConta: new FieldModel({
      name: 'idTipoConta',
      value: getAttributeExistingObject(controls, 'idTipoConta'),
    }),
    dadosBancoComplemento: new FieldModel({
      name: 'dadosBancoComplemento',
      value: getAttributeExistingObject(controls, 'dadosBancoComplemento'),
      maxLength: 20,
    }),

    advogadoNacionalidade: new FieldModel({
      name: 'advogadoNacionalidade',
      value: getAttributeExistingObject(controls, 'advogadoNacionalidade'),
      maxLength: 25,
    }),
    advogadoSeccional: new FieldModel({
      name: 'advogadoSeccional',
      value: getAttributeExistingObject(controls, 'advogadoSeccional'),
      maxLength: 20,
    }),
    advogadoOab: new FieldModel({
      name: 'advogadoOab',
      value: getAttributeExistingObject(controls, 'advogadoOab'),
      maxLength: 15,
    }),
    idFilial: new FieldModel({ name: 'idFilial', value: getAttributeExistingObject(controls, 'idFilial') }),
    idTipoUsuario: new FieldModel({
      name: 'idTipoUsuario',
      value: getAttributeExistingObject(controls, 'idTipoUsuario'),
    }),
    suspenderDistribuicaoProcesso: new FieldModel({
      name: 'suspenderDistribuicaoProcesso',
      value: getAttributeExistingObject(controls, 'suspenderDistribuicaoProcesso'),
    }),
    porcentagemComissaoComAdvExterno: new FieldModel({
      name: 'porcentagemComissaoComAdvExterno',
      value: getAttributeExistingObject(controls, 'porcentagemComissaoComAdvExterno', 0),
    }),
    compartilharComissao: new FieldModel({
      name: 'compartilharComissao',
      value: getAttributeExistingObject(controls, 'compartilharComissao', false),
    }),
    statusUsuario: new FieldModel({
      name: 'statusUsuario',
      value: getAttributeExistingObject(controls, 'statusUsuario', 'A'),
    }),
    endereco: buildEndereco(getAttributeExistingObject(controls, 'endereco')),
    telefones: buildTelefones(getAttributeExistingObject(controls, 'telefones')),
    radiosBaseadoEm: new FieldModel({ name: 'radiosBaseadoEm', value: 'PERFIL' }),
    perfilBaseadoSelector: new FieldModel({
      name: 'perfilBaseadoSelector',
      value: '',
    }),
    usuarioPermissaoAplicarSelector: new FieldModel({
      name: 'usuarioPermissaoAplicarSelector',
      value: '',
    }),
    radiosCompararCom: new FieldModel({
      name: 'radiosCompararCom',
      value: 'PERFIL',
    }),
    perfilCompararSelector: new FieldModel({
      name: 'perfilCompararSelector',
      value: '',
    }),
    usuarioPermissaoCompararSelector: new FieldModel({
      name: 'usuarioPermissaoCompararSelector',
      value: '',
    }),
    acoesConfiguraveis: new FieldModel({
      name: 'acoesConfiguraveis',
      value: false,
    }),
    requisicoes: new FieldModel({
      name: 'requisicoes',
      value: buildRequisicoes(getAttributeExistingObject(controls, 'requisicoes', [])),
    }),
    fasesSelecionadas: buildFasesSelecionadas(getAttributeExistingObject(controls, 'fasesSelecionadas')),
    porcentagemComissao: new FieldModel({
      name: 'porcentagemComissao',
      value: getAttributeExistingObject(controls, 'porcentagemComissao', 0),
    }),
    tipoUsuarioTipoComissao: new FieldModel({
      name: 'tipoUsuarioTipoComissao',
      value: getAttributeExistingObject(controls, 'tipoUsuarioTipoComissao', 0),
    }),
    porcentagemComissaoEquipe: new FieldModel({
      name: 'porcentagemComissaoEquipe',
      value: getAttributeExistingObject(controls, 'porcentagemComissaoEquipe'),
    }),
    permissoes: [],
    mudancaTipoUsuario: false,
    permissoesComparacao: [],
    idsUsuariosMembros: membrosEquipe || [],
    pertenceAlgumaEquipe: controls?.pertenceAlgumaEquipe || false,
  };
}

function buildColumnsComparacao() {
  return [new TableHeader({
    id: 'usuario', label: '', minWidth: 'auto', align: 'center', alignCell: 'center',
  }),
  new TableHeader({
    id: 'usuarioPerfil', label: '', minWidth: 'auto', alignCell: 'center',
  }),
  new TableHeader({ id: 'item', label: 'Item', minWidth: 'auto' }),
  new TableHeader({ id: 'acao', label: 'Ação', minWidth: 'auto' }),
  ];
}

function buildDialogReferenteEquipe(open, origin) {
  return {
    open,
    origin,
    message: MESSAGES_REFERENTE_EQUIPE[origin]?.msg,
  };
}

function initiateStates(usuario, membrosEquipe) {
  return {
    nomeLoginUsuario: getAttributeExistingObject(usuario, 'login'),
    image: {
      file: null,
      imagePreviewUrl: null,
      error: false,
      errorMessage: CrudUsuarioStoreConst.IMAGEM_ERROR_MESSAGE,
    },
    controls: buildControls(usuario, membrosEquipe),
    idsTiposUsuarios: null,
    actions: {
      permitirSubmitMudancaTipoUsuario: false,
      openDialogMudancaTipoUsuario: false,
      idTipoUsuarioAux: usuario?.idTipoUsuario || '',
      listaUsuariosElegiveisEquipe: [],
      dialogReferenteEquipe: buildDialogReferenteEquipe(null, null),
      limparListaMembros: false,
      openDialogReferenteEquipe: false,
      tipoUsuarioAlterar: null,
      statusUsuarioAlterar: null,
      errorImage: false,
      loadingPage: false,
      isAdvogado: true,
      isAssistenteExterno: true,
      indexPermissao: 0,
      openSnackbar: false,
      snackbarVariantion: 'error',
      snackbarMessage: '',
      openErrorDialog: false,
      openErrorVinculadoDialog: false,
      messageDialog: '',
      messageCompararDialog: '',
      openErrorCompararDialog: false,
      isConflict: false,
      loadingAplicar: false,
      loadingComparar: false,
      openErrorComparar: false,
      messageErrorComparar: '',
      columnsComparacao: buildColumnsComparacao(),
      openComparador: false,
      alertDialog: {
        open: false,
        message: '',
      },
    },
  };
}

// Actions

export const configureDialogReferenteEquipeAction = (open, origin) => ({
  type: CONFIGURE_DIALOG_REFERENTE_EQUIPE,
  open,
  origin,
});

export const setMembrosEquipeAction = (idUsuarioMembro, isAdd, fullRemove) => ({
  type: SET_MEMBROS_EQUIPE,
  idUsuarioMembro,
  isAdd,
  fullRemove,
});

export const setAlertDialogAction = (open, message) => ({
  type: SET_ALERT_DIALOG,
  open,
  message,
});

export const onSetActionAttributesAction = (name, value) => ({
  type: SET_ACTIONS_ATTRIBUTE,
  name,
  value,
});

export const onClearComboFilial = value => ({
  type: ON_CLEAR_COMBO_FILIAL_USUARIO,
  value,
});

export const onClearCombosPermissoes = () => ({
  type: ON_RELOAD_COMBOS_PERMISSOES,
});

export const onFocusAction = name => ({
  type: ON_FOCUS_FIELDS,
  name,
});

export const openErrorDialogContext = message => ({
  type: OPEN_ERROR_DIALOG_CONTEXT,
  message,
});

export const openErrorVinculadoDialogContext = message => ({
  type: OPEN_ERROR_VINCULADO_DIALOG_CONTEXT,
  message,
});

export const closeErrorDialogContext = () => ({
  type: CLOSE_ERROR_DIALOG_CONTEXT,
});

export const closeErrorVinculadoDialogContext = () => ({
  type: CLOSE_ERROR_VINCULADO_DIALOG_CONTEXT,
});

export const onChangeTipoDocumentoAction = (name, value) => ({
  type: ON_CHANGE_TIPO_DOCUMENTO,
  name,
  value,
});

export const clearStates = () => ({
  type: CLEAR_STATES,
});

export const successfullyInserted = () => ({
  type: SUCCESSFULLY_INSERTED,
});

export const setImage = image => ({
  type: SET_IMAGE,
  image,
});

export const setTelefoneAttributeValueAction = (name, value, index) => ({
  type: SET_TELEFONE_ATTRIBUTE_VALUE,
  name,
  value,
  index,
});

export const setTelefoneAttributeFocusAction = (name, index) => ({
  type: SET_TELEFONE_ATTRIBUTE_FOCUS,
  name,
  index,
});

export const setTelefonesAction = telefones => ({
  type: SET_TELEFONES,
  telefones,
});

export const onCheckTitlePermissionCheckbox = permissoes => ({
  type: CHECK_TITLE_PERMISSION_CHECKBOX,
  permissoes,
});

export const applySnackbarErrorConfig = message => ({
  type: APPLY_SNACKBAR_ERROR_CONFIG,
  message,
});

export const onAtualizarPermissoes = permissoes => ({
  type: ON_ATUALIZAR_PERMISSOES,
  permissoes,
});

export const onChangeControls = (name, value) => ({
  type: ON_CHANGE_CONSTROLS,
  name,
  value,
});

export const onChangeFullyControls = controls => ({
  type: ON_CHANGE_FULLY_CONTROLS,
  controls,
});

export const onChangeActions = (name, value) => ({
  type: ON_CHANGE_ACTIONS,
  name,
  value,
});

export const onClearPermissionSelector = (value, namePerfil, nameUsuario) => ({
  type: ON_CLEAR_PERMISSION_SELECTOR,
  value,
  namePerfil,
  nameUsuario,
});

export const checkEnabledAdvogadoAndAssistenteExterno = (tipoUsuario, idsTiposUsuarios) => ({
  type: CHECK_ENABLED_ADVOGADO_ASSISTENTE_EXTERNO,
  tipoUsuario,
  idsTiposUsuarios,
});

export const buildUsuario = (usuario, membrosEquipe) => ({
  type: BUILD_USUARIO,
  usuario,
  membrosEquipe,
});

export const setEnderecoAttributeValueAction = (name, value) => ({
  type: SET_ENDERECO_ATTRIBUTE_VALUE,
  name,
  value,
});

export const setEnderecoAttributeFocusAction = name => ({
  type: SET_ENDERECO_ATTRIBUTE_FOCUS,
  name,
});

export const setEnderecoAction = endereco => ({
  type: SET_ENDERECO,
  endereco,
});

export const onChangeFasesUsuarioAction = values => ({
  type: ON_CHANGE_COMBO_FASES,
  values,
});

export const onFocusRamosAtividadeAction = fasesUsuario => ({
  type: ON_FOCUS_COMBO_FASES,
  fasesUsuario,
});

export const onChangeRequisicoesAction = values => ({
  type: ON_CHANGE_REQUISICOES,
  values,
});

export const onChangeFaseAtualAction = (fieldName, value, idx) => ({
  type: ON_CHANGE_FASE_ATUAL,
  fieldName,
  value,
  idx,
});

export const onClickAdicionarFase = () => ({
  type: ON_CLICK_ADICIONAR_FASE,
});

export const onClickExcluirFase = idx => ({
  type: ON_CLICK_EXCLUIR_FASE,
  idx,
});

export const changeMudancaTipoUsuarioAction = newTipo => ({
  type: CHANGE_MUDANCA_TIPO_USUARIO,
  newTipo,
});

export const openDialogMudancaTipoUsuarioAction = () => ({
  type: DIALOG_MUDANCA_TIPO_USUARIO,
});

export const permitirSubmitMudancaTipoUsuarioAction = () => ({
  type: PERMITIR_SUBMIT_MUDANCA_TIPO_USUARIO,
});

export const zerarFasesSelecionadasComissaoAction = () => ({
  type: ZERAR_FASES_SELECIONADAS_COMISSAO,
});

export const onMaxFasesSelectedAction = () => ({
  type: ON_MAX_FASES_SELECTED,
});

export const onChangeIdsTiposUsuariosAction = idsTiposUsuarios => ({
  type: ON_CHANGE_IDS_TIPOS_USUARIOS,
  idsTiposUsuarios,
});

const isAdvogado = (tipoUsuario, idsTiposUsuarios) => tipoUsuario === idsTiposUsuarios?.idAdvogado || tipoUsuario === idsTiposUsuarios?.idAdvogadoExterno;

function setMembrosEquipeHandler(states, action) {
  const {
    idUsuarioMembro,
    isAdd,
    fullRemove,
  } = action;

  if (fullRemove) {
    return {
      ...states,
      controls: {
        ...states.controls,
        idsUsuariosMembros: [],
      },
    };
  }
  const idsMembros = [...states.controls.idsUsuariosMembros];
  if (isAdd) {
    idsMembros.push(idUsuarioMembro);
    return {
      ...states,
      controls: {
        ...states.controls,
        idsUsuariosMembros: idsMembros,
      },
    };
  }

  idsMembros.splice(idsMembros.indexOf(idUsuarioMembro), 1);
  return {
    ...states,
    controls: {
      ...states.controls,
      idsUsuariosMembros: idsMembros,
    },
  };
}

function configureDialogReferenteEquipeHandler(states, action) {
  const { open, origin } = action;

  return {
    ...states,
    actions: {
      ...states.actions,
      dialogReferenteEquipe: buildDialogReferenteEquipe(open, origin),
    },
  };
}

function setAlertDialogHandler(states, action) {
  const { open, message } = action;

  return {
    ...states,
    actions: {
      ...states.actions,
      alertDialog: {
        open,
        message,
      },
    },
  };
}

function onClearComboFilialHandler(states, action) {
  const idFilialWithError = states.controls.idFilial.getNewModel(action.value);

  return {
    ...states,
    controls: {
      ...states.controls,
      idFilial: idFilialWithError,
    },
  };
}

function changeEnderecoAttributeFocusHandler(states, actions) {
  const field = states.controls.endereco[actions.name].copy();
  return {
    ...states,
    controls: {
      ...states.controls,
      endereco: {
        ...states.controls.endereco,
        [actions.name]: field.onFocus(),
      },
    },
  };
}

function changeTelefoneAttributeValueHandler(states, actions) {
  const telefones = [...states.controls.telefones];
  telefones[actions.index][actions.name].onChange(actions.value);
  return {
    ...states,
    controls: {
      ...states.controls,
      telefones,
    },
  };
}

function changeTelefoneAttributeFocusHandler(states, actions) {
  const telefones = [...states.controls.telefones];
  telefones[actions.index][actions.name].onFocus();
  return {
    ...states,
    controls: {
      ...states.controls,
      telefones,
    },
  };
}

function changeTelefonesHandler(states, actions) {
  return {
    ...states,
    controls: {
      ...states.controls,
      telefones: [...actions.telefones],
    },
  };
}

function changeEnderecoAttributeValueHandler(states, actions) {
  const { name, value } = actions;
  const field = states.controls.endereco[name].getNewModel(value);
  return {
    ...states,
    controls: {
      ...states.controls,
      endereco: {
        ...states.controls.endereco,
        [name]: field,
      },
    },
  };
}

function changeEnderecoHandler(states, actions) {
  return {
    ...states,
    controls: {
      ...states.controls,
      endereco: { ...actions.endereco },
    },
  };
}

function getClearSelectors(value, statesParam, namePerfil, nameUsuario) {
  if (value === 'PERFIL') {
    return {
      name: nameUsuario,
      value: statesParam.controls[nameUsuario].getClearModel(),
    };
  }
  return { name: namePerfil, value: statesParam.controls[namePerfil].getClearModel() };
}

const resetPermission = relacaoAcoes => ({
  ...relacaoAcoes,
  acoesUsuario: relacaoAcoes.acoesUsuario.map(acao => ({
    ...acao,
    model: new FieldModel({
      name: acao.model.name,
      value: false,
    }),
  })),
});

const openErrorDialogContextHandler = (states, actions) => ({
  ...states,
  actions: {
    ...states.actions,
    openErrorDialog: true,
    messageDialog: actions.message,
  },
});

const openErrorVinculadoDialogContextHandler = (states, actions) => ({
  ...states,
  actions: {
    ...states.actions,
    openErrorVinculadoDialog: true,
    messageDialog: actions.message,
  },
});

const closeErrorDialogContextHandler = states => ({
  ...states,
  actions: {
    ...states.actions,
    openErrorDialog: false,
    messageDialog: '',
  },
});

const closeErrorVinculadoDialogContextHandler = states => ({
  ...states,
  actions: {
    ...states.actions,
    openErrorVinculadoDialog: false,
    messageDialog: '',
  },
});

const clearStatesHandler = (states) => {
  const newStates = initiateStates();
  let newPermissoes = states.controls.permissoes;
  newPermissoes = newPermissoes.map(resetPermission);
  newStates.controls.permissoes = newPermissoes;
  return newStates;
};

const clearToInitialStatesHandler = () => initiateStates();

const setImageHandler = (states, action) => ({
  ...states,
  image: { ...action.image },
});

const applySuccessfullyInsertedInformationsHandler = states => ({
  ...states,
  actions: {
    ...states.actions,
    openSnackbar: true,
    snackbarVariantion: 'success',
    snackbarMessage: CrudUsuarioStoreConst.CADASTRADO_COM_SUCESSO,
  },
});

const onAtualizarPermissoesHandler = (states, action) => ({
  ...states,
  controls: {
    ...states.controls,
    permissoes: action.permissoes,
  },
});

const onChangeTipoDocumentoHandler = (states, action) => {
  const model = states.controls.dadosBancoDocumento.copy();
  model.value = '';
  model.error = false;
  return {
    ...states,
    controls: {
      ...states.controls,
      [action.name]: action.value,
      dadosBancoDocumento: model,
    },
  };
};

function onChangeConstrolsHandler(states, action) {
  const { name, value } = action;
  if (value instanceof Array) {
    return {
      ...states,
      controls: {
        ...states.controls,
        [name]: value,
      },
    };
  }
  if (name === 'nome do usuario editado') {
    const fieldModel = states.controls.login;
    return {
      ...states,
      controls: { ...states.controls, login: fieldModel.onChange(value) },
    };
  }
  if (name === 'senha do usuario editada') {
    const fieldModel = states.controls.senha;
    return {
      ...states,
      controls: { ...states.controls, senha: fieldModel.onChange(value) },
    };
  }
  const fieldModel = states.controls[action.name];
  return {
    ...states,
    controls: { ...states.controls, [name]: fieldModel.onChange(value) },
  };
}

function onFieldsFocusHandler(states, actions) {
  const { name } = actions;
  if (name === 'nome do usuario editado') {
    const fieldModel = states.controls.login.copy();
    return { ...states, controls: { ...states.controls, login: fieldModel.onFocus() } };
  }
  if (name === 'senha do usuario editada') {
    const fieldModel = states.controls.senha.copy();
    return { ...states, controls: { ...states.controls, senha: fieldModel.onFocus() } };
  }

  const fieldModel = states.controls[name].copy();
  return { ...states, controls: { ...states.controls, [name]: fieldModel.onFocus() } };
}

const onReloadCombosPermissoesHandler = (states) => {
  const usuarioPermissaoAplicarWithError = states.controls.usuarioPermissaoAplicarSelector.getNewModel('');
  const usuarioPermissaoCompararWithError = states.controls.usuarioPermissaoCompararSelector.getNewModel('');

  return {
    ...states,
    controls: {
      ...states.controls,
      usuarioPermissaoAplicarSelector: usuarioPermissaoAplicarWithError,
      usuarioPermissaoCompararSelector: usuarioPermissaoCompararWithError,
    },
  };
};

const onChangeActionsHandler = (states, action) => ({
  ...states,
  actions: {
    ...states.actions,
    [action.name]: action.value,
  },
});

const onClearPermissionSelectorHandler = (states, actions) => {
  const targetSelectorModel = getClearSelectors(
    actions.value,
    states,
    actions.namePerfil,
    actions.nameUsuario,
  );
  return {
    ...states,
    controls: {
      ...states.controls,
      [targetSelectorModel.name]: targetSelectorModel.value,
    },
  };
};

const onCheckEnabledAdvogadoAndAssistenteExterno = (states, action) => {
  let disabledFields = { isAdvogado: true };
  const controls = { ...states.controls };
  if (isAdvogado(action.tipoUsuario, action.idsTiposUsuarios)) {
    disabledFields = { isAdvogado: false };
  } else {
    disabledFields = { isAdvogado: true };
  }
  return {
    ...states,
    controls: { ...controls },
    actions: {
      ...states.actions,
      ...disabledFields,
    },
  };
};

const onCheckTitlePermissionCheckboxHandler = (states, action) => {
  const uncheckedItems = action.permissoes.filter(acao => !acao.model.value);
  const controls = { ...states.controls };
  if (uncheckedItems.length === 0) {
    controls.acoesConfiguraveis.value = true;
  } else {
    controls.acoesConfiguraveis.value = false;
  }
  return {
    ...states,
    controls: {
      ...controls,
    },
  };
};

const applySnackbarErrorConfigHandler = (states, action) => ({
  ...states,
  actions: {
    ...states.actions,
    openSnackbar: true,
    snackbarVariantion: 'error',
    snackbarMessage: action.message,
  },
});

function onChangeFullyControlsHandler(states, action) {
  return {
    ...states,
    controls: { ...action.controls },
  };
}

const buildUsuarioHandler = (states, actions) => {
  const { usuario, membrosEquipe } = actions;
  if (usuario === null || usuario === undefined) {
    return states;
  }

  const imageStates = { ...states.image };
  usuario.senha = EMPTY_PASSWORD;
  usuario.dataNascimento = convertDate(usuario.dataNascimento);
  usuario.permitirLogar = !usuario.permitirLogar;
  const newStates = initiateStates(usuario, membrosEquipe);
  return {
    ...newStates,
    image: {
      ...imageStates,
    },
  };
};

function onChangeFasesUsuarioHandler(states, actions) {
  const fases = states.controls.fases.copy();
  fases.value = [...actions.values];
  return {
    ...states,
    controls: {
      ...states.controls,
      fases,
    },
  };
}

function onChangeRequisicoesUsuarioHandler(states, actions) {
  const requisicoes = states.controls.requisicoes.copy();
  requisicoes.value = [...actions.values];
  return {
    ...states,
    controls: {
      ...states.controls,
      requisicoes,
    },
  };
}

function setActionsAttributeHandler(states, actions) {
  const { name, value } = actions;

  return {
    ...states,
    actions: {
      ...states.actions,
      [name]: value,
    },
  };
}

function onChangeFaseAtualhandler(states, actions) {
  const { fieldName, value, idx } = actions;
  const { fasesSelecionadas } = states.controls;
  fasesSelecionadas[idx][fieldName].value = value;

  return {
    ...states,
    controls: {
      ...states.controls,
      fasesSelecionadas,
    },
  };
}

function onClickAdicionarFaseHandler(states, actions) {
  const fasesAnteriores = states.controls.fasesSelecionadas;
  fasesAnteriores.push(buildEmptyFase());

  return {
    ...states,
    controls: {
      ...states.controls,
      fasesSelecionadas: fasesAnteriores,
    },
  };
}

function onClickExcluirFaseHandler(states, actions) {
  const { idx } = actions;
  const fasesAnteriores = states.controls.fasesSelecionadas;
  fasesAnteriores.splice(idx, 1);

  return {
    ...states,
    controls: {
      ...states.controls,
      fasesSelecionadas: fasesAnteriores,
    },
  };
}

function chageMudancaTipoUsuarioHandler(states, actions) {
  const { newTipo } = actions;
  const { idTipoUsuarioAux } = states.actions;

  return {
    ...states,
    controls: {
      ...states.controls,
      mudancaTipoUsuario: idTipoUsuarioAux !== newTipo,
    },
  };
}

function changeDialogMudancaTipoUsuarioHandler(states) {
  const { openDialogMudancaTipoUsuario } = states.actions;

  return {
    ...states,
    actions: {
      ...states.actions,
      openDialogMudancaTipoUsuario: !openDialogMudancaTipoUsuario,
    },
  };
}

function permitirSubmitMudancaTipoUsuarioHandler(states) {
  const { permitirSubmitMudancaTipoUsuario } = states.actions;

  return {
    ...states,
    actions: {
      ...states.actions,
      permitirSubmitMudancaTipoUsuario: !permitirSubmitMudancaTipoUsuario,
    },
  };
}

function zerarFasesSelecionadasComissaoHandler(states) {
  const { fasesSelecionadas } = states.controls;

  return {
    ...states,
    controls: {
      ...states.controls,
      fasesSelecionadas: fasesSelecionadas.map((fase) => {
        fase.porcentagemComissao.value = '0.00';
        return fase;
      }),
    },
  };
}

function onMaxFasesSelectedHandler(states) {
  const { fasesSelecionadas } = states.controls;
  return {
    ...states,
    controls: {
      ...states.controls,
      fasesSelecionadas: fasesSelecionadas.filter(faseObj => faseObj.idFase.value !== 0),
    },
  };
}

function onChangeIdsTiposUsuariosHandler(states, actions) {
  const { idsTiposUsuarios } = actions;
  return {
    ...states,
    idsTiposUsuarios,
  };
}

export default (states = initiateStates(), action) => {
  switch (action.type) {
    case CLEAR_STATES:
      return clearStatesHandler(states);
    case CLEAR_TO_INITIAL_STATES:
      return clearToInitialStatesHandler();
    case SET_IMAGE:
      return setImageHandler(states, action);
    case ON_ATUALIZAR_PERMISSOES:
      return onAtualizarPermissoesHandler(states, action);
    case ON_CHANGE_CONSTROLS:
      return onChangeConstrolsHandler(states, action);
    case ON_CHANGE_ACTIONS:
      return onChangeActionsHandler(states, action);
    case ON_CLEAR_PERMISSION_SELECTOR:
      return onClearPermissionSelectorHandler(states, action);
    case APPLY_SNACKBAR_ERROR_CONFIG:
      return applySnackbarErrorConfigHandler(states, action);
    case CHECK_ENABLED_ADVOGADO_ASSISTENTE_EXTERNO:
      return onCheckEnabledAdvogadoAndAssistenteExterno(states, action);
    case CHECK_TITLE_PERMISSION_CHECKBOX:
      return onCheckTitlePermissionCheckboxHandler(states, action);
    case ON_CHANGE_FULLY_CONTROLS:
      return onChangeFullyControlsHandler(states, action);
    case SUCCESSFULLY_INSERTED:
      return applySuccessfullyInsertedInformationsHandler(states);
    case BUILD_USUARIO:
      return buildUsuarioHandler(states, action);
    case OPEN_ERROR_DIALOG_CONTEXT:
      return openErrorDialogContextHandler(states, action);
    case OPEN_ERROR_VINCULADO_DIALOG_CONTEXT:
      return openErrorVinculadoDialogContextHandler(states, action);
    case CLOSE_ERROR_DIALOG_CONTEXT:
      return closeErrorDialogContextHandler(states);
    case CLOSE_ERROR_VINCULADO_DIALOG_CONTEXT:
      return closeErrorVinculadoDialogContextHandler(states);
    case ON_CHANGE_TIPO_DOCUMENTO:
      return onChangeTipoDocumentoHandler(states, action);
    case ON_RELOAD_COMBOS_PERMISSOES:
      return onReloadCombosPermissoesHandler(states);
    case SET_ENDERECO:
      return changeEnderecoHandler(states, action);
    case SET_ENDERECO_ATTRIBUTE_FOCUS:
      return changeEnderecoAttributeFocusHandler(states, action);
    case SET_ENDERECO_ATTRIBUTE_VALUE:
      return changeEnderecoAttributeValueHandler(states, action);
    case SET_TELEFONE_ATTRIBUTE_VALUE:
      return changeTelefoneAttributeValueHandler(states, action);
    case SET_TELEFONE_ATTRIBUTE_FOCUS:
      return changeTelefoneAttributeFocusHandler(states, action);
    case SET_TELEFONES:
      return changeTelefonesHandler(states, action);
    case ON_FOCUS_FIELDS:
      return onFieldsFocusHandler(states, action);
    case ON_CLEAR_COMBO_FILIAL_USUARIO:
      return onClearComboFilialHandler(states, action);
    case ON_CHANGE_COMBO_FASES:
      return onChangeFasesUsuarioHandler(states, action);
    case ON_CHANGE_REQUISICOES:
      return onChangeRequisicoesUsuarioHandler(states, action);
    case SET_ACTIONS_ATTRIBUTE:
      return setActionsAttributeHandler(states, action);
    case SET_ALERT_DIALOG:
      return setAlertDialogHandler(states, action);
    case SET_MEMBROS_EQUIPE:
      return setMembrosEquipeHandler(states, action);
    case CONFIGURE_DIALOG_REFERENTE_EQUIPE:
      return configureDialogReferenteEquipeHandler(states, action);
    case ON_CHANGE_FASE_ATUAL:
      return onChangeFaseAtualhandler(states, action);
    case ON_CLICK_ADICIONAR_FASE:
      return onClickAdicionarFaseHandler(states, action);
    case ON_CLICK_EXCLUIR_FASE:
      return onClickExcluirFaseHandler(states, action);
    case CHANGE_MUDANCA_TIPO_USUARIO:
      return chageMudancaTipoUsuarioHandler(states, action);
    case DIALOG_MUDANCA_TIPO_USUARIO:
      return changeDialogMudancaTipoUsuarioHandler(states, action);
    case PERMITIR_SUBMIT_MUDANCA_TIPO_USUARIO:
      return permitirSubmitMudancaTipoUsuarioHandler(states, action);
    case ZERAR_FASES_SELECIONADAS_COMISSAO:
      return zerarFasesSelecionadasComissaoHandler(states, action);
    case ON_MAX_FASES_SELECTED:
      return onMaxFasesSelectedHandler(states, action);
    case ON_CHANGE_IDS_TIPOS_USUARIOS:
      return onChangeIdsTiposUsuariosHandler(states, action);
    default:
      return states;
  }
};
