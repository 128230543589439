import React from 'react';
import { Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { AccordionSummary, CustomAccordion } from './Accordion.styles';
import TooltipUI from '../../Tooltip/Tooltip';

export function Accordion({
  children, title, icon, iconTitle = '', color = 'purePurple', ...rest
}) {
  return (
    <CustomAccordion color={color} {...rest}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>
          {title}
        </Typography>
        { icon
          && (
          <TooltipUI title={iconTitle}>
            {icon}
          </TooltipUI>
          )
        }
      </AccordionSummary>
      {children}
    </CustomAccordion>
  );
}
