import { toast } from 'react-toastify';
import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import {
  toAmericanDate,
  toastUnmappedException,
} from '../../helpers';

import RegistrarBoletoService from '../../services/core/RegistrarBoleto/RegistrarBoletoService';
import {
  setCommonAction, setSimpleRegistrarBoletoAction,
} from './RegistrarBoleto.store';
import {
  INSERT_BOLETO, REGISTRAR_BOLETO_SELECIONADO,
  SET_BUSCAR_DADOS_TABELA,
} from './RegistrarBoleto.constants';


function* successPesquisarRegistrarBoleto(data) {
  yield put(setSimpleRegistrarBoletoAction('tableView', data.data.content));
  yield put(setSimpleRegistrarBoletoAction('totalElements', data.data.totalElements));
  yield put(setSimpleRegistrarBoletoAction('rowsPerPage', data.data.pageable.pageSize));
  yield put(setSimpleRegistrarBoletoAction('page', data.data.pageable.pageNumber));
}

function* emptyPesquisaRegistrarBoleto() {
  yield put(setSimpleRegistrarBoletoAction('tableView', []));
  yield put(setSimpleRegistrarBoletoAction('totalElements', 0));
}


function* buscarDadosTabelaHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const data = yield select(states => states.requestFields.data);
  const page = yield select(states => states.page);
  const rowsPerPage = yield select(states => states.rowsPerPage);
  const columnOrdenacao = yield select(states => states.columnOrdenacao);
  const listIds = yield select(states => states.listIds);
  const storedListIds = new Map();

  try {
    const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];
    const response = yield call(RegistrarBoletoService.buscarInfoRegistroBoleto, toAmericanDate(data.value), { page, size: rowsPerPage }, order);
    yield successPesquisarRegistrarBoleto(response);
    if (actions.atualizarListaIds === true) {
      const responseIds = yield call(RegistrarBoletoService.buscarIdsBoletos, toAmericanDate(data.value));
      Object.keys(listIds).forEach((key) => {
        storedListIds.set(parseInt(key, 10), listIds[key]);
      });
      const ids = responseIds.data.reduce((acumulador, id) => ({ ...acumulador, [id]: false }), {});
      yield put(setSimpleRegistrarBoletoAction('listIds', ids));
    }
  } catch (e) {
    yield emptyPesquisaRegistrarBoleto();
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}


function* registrarBoletoHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const listIds = yield select(states => states.listIds);
  const idBoletoCliente = Object.keys(listIds).filter((key => listIds[key] === true)).map(key => Number(key));
  const storedListIds = new Map();
  const data = yield select(states => states.requestFields.data);

  try {
    const response = yield call(RegistrarBoletoService.registrarBoleto, idBoletoCliente);
    if (!response.data.erroBoleto) {
      toast.success('Todos os Boletos foram registrados com sucesso.');
    } else {
      toast.error('Alguns Boletos não puderam ser registrados.', { style: { width: '392px' } });
    }
  } catch (e) {
    toastUnmappedException(
      e,
      'Erro ao registrar o boleto.',
    );
  } finally {
    yield buscarDadosTabelaHandler(actions);
    if (actions.atualizarListaIds === true) {
      const responseIds = yield call(RegistrarBoletoService.buscarIdsBoletos, toAmericanDate(data.value));
      Object.keys(listIds).forEach((key) => {
        storedListIds.set(parseInt(key, 10), listIds[key]);
      });
      const ids = responseIds.data.reduce((acumulador, id) => ({ ...acumulador, [id]: false }), {});
      yield put(setSimpleRegistrarBoletoAction('listIds', ids));
    }
    yield put(setCommonAction('loadingPage', false));
  }
}


export const buscarDadosTabelaAction = atualizarListaIds => ({
  type: SET_BUSCAR_DADOS_TABELA,
  atualizarListaIds,
});

export const liberarRegistrarBoletoSelecionadoAction = idLancamentoCliente => ({
  type: REGISTRAR_BOLETO_SELECIONADO,
  idLancamentoCliente,
});

export const inserirBoletoAction = atualizarListaIds => ({
  type: INSERT_BOLETO,
  atualizarListaIds,
});


export default function* watchRegistrarBoleto() {
  yield takeLatest(SET_BUSCAR_DADOS_TABELA, buscarDadosTabelaHandler);
  yield takeLatest(INSERT_BOLETO, registrarBoletoHandler);
}
