export const SET_BORDERO = '@crudBordero/SET_BORDERO_CRUD_BORDEROS';
export const SET_LOADING = '@crudBordero/SET_LOADING_CRUD_BORDEROS';
export const SET_DIALOG = '@crudBordero/SET_DIALOG_CRUD_BORDEROS';
export const SET_SUCCESS_DIALOG = '@crudBordero/SET_SUCCESS_DIALOG_CRUD_BORDEROS';
export const SET_ATTRIBUTE = '@crudBordero/SET_ATTRIBUTE_CRUD_BORDEROS';
export const SET_OBJECT_ATTRIBUTE = '@crudBordero/SET_OBJECT_ATTRIBUTE_CRUD_BORDEROS';
export const SET_OBJECT_FOCUS = '@crudBordero/SET_OBJECT_FOCUS_CRUD_BORDEROS';
export const SET_OBJECT = '@crudBordero/SET_OBJECT_CRUD_BORDEROS';
export const SET_OBJECTS = '@crudBordero/SET_OBJECTS_CRUD_BORDEROS';
export const SET_CLIENTE = '@crudBordero/SET_CLIENTE_CRUD_BORDEROS';
export const SET_CATEGORIA = '@crudBordero/SET_CATEGORIA_CRUD_BORDEROS';
export const SET_DEVEDOR = '@crudBordero/SET_DEVEDOR_CRUD_BORDEROS';
export const UPDATE_NOME_FANTASIA = '@crudBordero/UPDATE_NOME_FANTASIA_CRUD_BORDEROS';
export const SET_BORDERO_WITH_ERROR = '@crudBordero/SET_BORDERO_WITH_ERROR_CRUD_BORDEROS';
export const SET_DADOS_RETORNO = '@crudBordero/SET_DADOS_RETORNO';
export const SET_FOCUS = '@crudBordero/SET_FOCUS_CRUD_BORDEROS';
export const CLEAR_ALL = '@crudBordero/CLEAR_ALL_CRUD_BORDEROS';
export const CLEAR_BORDERO = '@crudBordero/CLEAR_BORDERO_CRUD_BORDEROS';
