import React from 'react';
import SubTitleForm from '../SubTitleForm/SubTitleForm';

function WrapperSubTitleForm({
  children,
  title,
  className = '',
  show = true,
  isInputLabel = false,
  sideComponent,
}) {
  let content = (
    <section className={className} style={{ width: '100%', cursor: 'default' }}>
      <SubTitleForm isInputLabel={isInputLabel}>
        {title}
        {sideComponent}
      </SubTitleForm>
      <div>{children}</div>
    </section>
  );
  if (!show) {
    content = null;
  }

  return content;
}

export default React.memo(WrapperSubTitleForm);
