import { MenuItem } from '@mui/material';
import React, { useRef } from 'react';
import { FloatBox } from '../../../../../../../components/FloatContainer/FloatBox';
import CheckboxUI from '../../../../../../../components/UI/Checkbox/CheckboxUI';
import {
  BoxTiposLancamentos, ButtonDropDown, DropdownContainer, InputLabelTiposLancamentos,
} from './TiposLancamentosSelectorStyle';

function TiposLancamentosSelector({
  tiposLancamentos,
  onChangeHandler,
  selecionados,
}) {
  const parentFloatBoxRef = useRef();
  const floatBoxRef = useRef();

  return (
    <div style={{ display: 'block' }}>
      <InputLabelTiposLancamentos>Tipo de Lançamentos</InputLabelTiposLancamentos>
      <div style={{ display: 'flex' }}>
        <BoxTiposLancamentos ref={parentFloatBoxRef}>
          <span style={{ fontSize: '12px' }}>{selecionados}</span>
          <ButtonDropDown onClick={() => floatBoxRef.current.handlerShowBox()}>
            <div />
          </ButtonDropDown>
        </BoxTiposLancamentos>
        <FloatBox ref={floatBoxRef} parentRef={parentFloatBoxRef} variant="left">
          <DropdownContainer>
            {tiposLancamentos.map((tipoLancamento, index) => (
              <MenuItem key={tipoLancamento.idTipoContaCorrenteCliente}>
                <CheckboxUI
                  label={tipoLancamento.descricao}
                  checked={tipoLancamento?.selecionado}
                  onChange={event => onChangeHandler(event, index)}
                />
              </MenuItem>
            ))}
          </DropdownContainer>
        </FloatBox>
      </div>

    </div>
  );
}

export default React.memo(TiposLancamentosSelector);
