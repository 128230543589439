import { useDispatch } from 'react-redux';
import React from 'react';
import { initializePageAction } from '../../../store/theme.actions';
import withTokenInterceptor from '../../../hoc/withTokenInterceptor/withTokenInterceptor';
import HistoricoAS from '../../../components/AcordoSeguro/Historico/HistoricoAS';

function HistoricoASPage() {
  const dispatch = useDispatch();

  dispatch(initializePageAction('Histórico do Acordo Seguro'));

  return (<HistoricoAS />);
}

export default withTokenInterceptor(HistoricoASPage);
