import React from 'react';
import PropTypes from 'prop-types';
import { IconButton as IconButtonUi } from '@material-ui/core';
import TooltipUI from '../UI/Tooltip/Tooltip';
import { ButtonWrapper } from './IconButton.styles';

function IconButtonComponent({
  title, disabled, children, outlined, color, ...rest
}) {
  return (
    <TooltipUI title={title}>
      <ButtonWrapper disabled={disabled} color={color} outlined={outlined}>
        <IconButtonUi disabled={disabled} {...rest}>
          {children}
        </IconButtonUi>
      </ButtonWrapper>
    </TooltipUI>
  );
}


IconButtonComponent.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  outlined: PropTypes.bool,
  color: PropTypes.oneOf(['none', 'purple']),
};

IconButtonComponent.defaultProps = {
  disabled: false,
  outlined: false,
  color: 'none',
};

export const IconButton = IconButtonComponent;
