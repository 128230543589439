import React, {
  useCallback, useImperativeHandle, useRef, useState,
} from 'react';

import { useSelector } from 'react-redux';
import { pesquisaProcessoController } from '../../../controller/pesquisarProcessos.controller';
import ButtonUI from '../../UI/Button/ButtonUI';
import SearchButtonWithLabel from '../../UI/Button/Search/Label/SearchButtonWithLabel';
import { PesquisaAvancadaProcesso } from './Avancada/PesquisaAvancadaProcesso';
import { PesquiaEspecialProcesso } from './Especial/PesquisaEspecialProcesso';
import {
  AdvancedActionButtons, AdvancedSearchBody, AdvancedSearchContainer, AdvancedSearchTitle, ClearButton, Footer,
} from './PesquisaComplementarProcesso.styles';


function PesquisaComplementarProcessoComponent({
  pesquisarComFiltroEspecial,
  pesquisarComFiltroAvancado,
}, ref) {
  const pesquisaEspecial = useRef();
  const pesquisaAvancada = useRef();

  const [disableButton, setDisableButton] = useState(false);
  const [typeSearch, setTypeSearch] = useState(pesquisaProcessoController.makeTypeSearch());
  const [show, setShow] = useState(false);

  const advancedSeachDropdown = useSelector(pesquisaProcessoController.getAdvancedSearchDropdownItems);

  function changeAdvancedSearchType() {
    setTypeSearch(pesquisaProcessoController.switchTypeSearch);
  }

  function closeAdvancedSearch() {
    setShow(false);
  }

  function openAdvancedSearch() {
    setShow(true);
  }

  const changeButtonPesquisaElimparStatus = useCallback((unknowFields = []) => {
    const form = new FormData(pesquisaAvancada.current ? pesquisaAvancada.current : pesquisaEspecial.current);
    setDisableButton(pesquisaProcessoController.isDisabledButtonAdvancedSearch(form, unknowFields));
  }, []);

  function pesquisar() {
    if (typeSearch.isEspecial && pesquisaEspecial.current) {
      pesquisaEspecial.current.dispatchEvent(new Event('submit', { cancelable: true }));
    } else if (pesquisaAvancada.current) {
      pesquisaAvancada.current.dispatchEvent(new Event('submit', { cancelable: true }));
    }
  }

  function clearFilters() {
    pesquisaProcessoController.clearFilters();
    if (pesquisaAvancada.current) {
      pesquisaAvancada.current.reset();
    }
    if (pesquisaEspecial.current) {
      pesquisaEspecial.current.reset();
    }
  }

  useImperativeHandle(ref, () => ({
    openAdvancedSearch,
    closeAdvancedSearch,
  }));

  if (!show) {
    return null;
  }

  return (
    <AdvancedSearchContainer>
      <button
        type="button"
        onClick={closeAdvancedSearch}
      >
        X
      </button>
      <div>
        <AdvancedActionButtons>
          <ButtonUI onClick={changeAdvancedSearchType}>
            {typeSearch.button}
          </ButtonUI>
          <SearchButtonWithLabel
            disabled={disableButton}
            label="Pesquisar"
            type="button"
            onClick={pesquisar}
          />
        </AdvancedActionButtons>
        <AdvancedSearchBody>
          <AdvancedSearchTitle>
            {typeSearch.title}
          </AdvancedSearchTitle>
          {typeSearch.isEspecial
            ? (
              <PesquiaEspecialProcesso
                ref={pesquisaEspecial}
                pesquisar={pesquisarComFiltroEspecial}
                items={advancedSeachDropdown}
                changeButtonStatus={changeButtonPesquisaElimparStatus}
              />
            )
            : (
              <PesquisaAvancadaProcesso
                ref={pesquisaAvancada}
                pesquisar={pesquisarComFiltroAvancado}
                items={advancedSeachDropdown}
                changeButtonStatus={changeButtonPesquisaElimparStatus}
              />
            )}
        </AdvancedSearchBody>
        <Footer>
          <ClearButton
            disabled={disableButton}
            onClick={clearFilters}
            iconFontAwesome="fas fa-eraser"
            label="Limpar"
          />
        </Footer>
      </div>
    </AdvancedSearchContainer>
  );
}

export const PesquisaComplementarProcesso = React.forwardRef(PesquisaComplementarProcessoComponent);
