import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  wrapper: {
    marginTop: '6px',
  },
  divider: {
    marginTop: '3px !important',
  },
});
