import React from 'react';
import Grid24 from '../../../../../UI/Grid24';
import { getRequiredLabel } from '../../../../../../helpers/utils/utils';
import constants from '../Honorarios/Faixa/constants';
import NumberInput from '../../../../../UI/Field/Number/NumberInput';
import MoneyField from '../../../../../UI/Field/Money/MoneyField';
import { TrashIconButton, TrashIconContainer } from '../Honorarios/Faixa/styles';
import {
  COMISSAO, FINAL, INITIAL, PAGAMENTO_DIRETO,
} from './comissoes.constants';
import SelectorField from '../../../../../UI/Field/Selector';

function CustomField({
  type = 'MONEY',
  label,
  disabled = false,
  name,
  value,
  error,
  errorMessage,
  onChange,
  onFocus,
}) {
  return type === 'MONEY' ? (
    <MoneyField
      isForm
      fullWidth
      disabled={disabled}
      maxlength={16}
      label={label}
      name={name}
      value={value}
      error={error}
      errorMessage={errorMessage}
      onChange={onChange}
      onFocus={onFocus}
    />
  ) : (
    <NumberInput
      isForm
      fullWidth
      disabled={disabled}
      sideText="(dias)"
      numberType="integer"
      maxValue={999999}
      label={label}
      name={name}
      value={value}
      error={error}
      errorMessage={errorMessage}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
}

function TrashButton({
  disabled = false,
  showTrash = false,
  attributes,
  onRemove,
}) {
  return (
    <TrashIconContainer showtrash={(!disabled && !showTrash) ? 1 : 0} itemxs={24} sm={1}>
      <TrashIconButton
        type="trash"
        attributes={attributes}
        disabled={disabled}
        hint={constants.trash}
        onClick={onRemove}
      />
    </TrashIconContainer>
  );
}


function FaixaComissao({
  type = 'MONEY',
  disabled = false,
  initial,
  final,
  porcentagemComissao,
  porcentagemPagtoDiretoComissao,
  showTrash = false,
  idxTipoComissao,
  idxComissao,
  onChange,
  onFocus,
  onRemove,
  tipoComissao,
  idTipoRegraComissaoComercial,
}) {
  const rename = name => `${name}-${idxComissao}`;

  const disabledFirstValue = (disabled || idxComissao === 0);

  return (
    <>
      <Grid24 container isFormContainer spacing="1">

        <Grid24 item xs={24} sm={4}>
          <CustomField
            type={type}
            label={getRequiredLabel(INITIAL, !disabledFirstValue)}
            disabled={disabledFirstValue}
            name={rename(initial.name)}
            value={initial.value}
            error={initial.error}
            errorMessage={initial.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>

        <Grid24 item xs={24} sm={4}>
          <CustomField
            type={type}
            label={getRequiredLabel(FINAL, !disabled)}
            disabled={disabled}
            name={rename(final.name)}
            value={final.value}
            error={final.error}
            errorMessage={final.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>

        <Grid24 item xs={24} sm={3}>
          <NumberInput
            isForm
            fullWidth
            disabled={disabled}
            sideText="(%)"
            numberType="percantage"
            maxValue={100}
            label={getRequiredLabel(COMISSAO, !disabled)}
            name={rename(porcentagemComissao.name)}
            value={porcentagemComissao.value}
            error={porcentagemComissao.error}
            errorMessage={porcentagemComissao.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>

        <Grid24 item xs={24} sm={4}>
          <NumberInput
            isForm
            fullWidth
            disabled={disabled}
            sideText="(%)"
            numberType="percantage"
            maxValue={100}
            label={getRequiredLabel(PAGAMENTO_DIRETO, !disabled)}
            name={rename(porcentagemPagtoDiretoComissao.name)}
            value={porcentagemPagtoDiretoComissao.value}
            error={porcentagemPagtoDiretoComissao.error}
            errorMessage={porcentagemPagtoDiretoComissao.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>
        <Grid24 sx={24} sm={7}>
          <SelectorField
            isForm
            fullWidth
            label={getRequiredLabel('Regra de Comissionamento do Comercial', !disabled)}
            disabled={disabled}
            name={rename(idTipoRegraComissaoComercial.name)}
            value={idTipoRegraComissaoComercial.value}
            onChange={onChange}
            items={tipoComissao}
          />
        </Grid24>
        <TrashButton
          showTrash={showTrash}
          disabled={disabled}
          attributes={{ idxComissao, idxTipoComissao }}
          onRemove={onRemove}
        />
      </Grid24>
    </>
  );
}

export default FaixaComissao;
