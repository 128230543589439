import makeStyles from '@material-ui/styles/makeStyles';
import { black16 } from '../../../helpers/constants/colors.constants';

export default makeStyles({
  wrapper: {
    width: '100%',
    display: 'flex',
    marginTop: '10px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    '& input[type="file"]': {
      display: 'none',
    },
    '& p': {
      marginTop: '4px',
      fontSize: '11px',
    },
  },

  errorImage: {
    '& .MuiAvatar-root': {
      border: '4px solid red',
    },
  },
  button: {
    '& button': {
      padding: '6px 25px !important',
    },
    marginImageButton: {
      marginTop: '10px',
    },
  },
  wrapperDefault: {
    '& .MuiAvatar-root': {
      width: '99px',
      height: '99px',
      boxShadow: `0 3px 6px 0 ${black16}`,
    },
  },
  wrapperLogoTipo: {
    '& .MuiAvatar-root': {
      width: '359px',
      height: '141px',
      boxShadow: `0 3px 6px 0 ${black16}`,
      borderRadius: 0,
      '& .MuiAvatar-img': {
        objectFit: 'contain',
      },
    },
  },
});
