import styled from 'styled-components';
import { AccordionDetails as AccordionDetailsUI, Grid, Typography } from '@material-ui/core';
import SubTitleForm from '../UI/SubTitleForm/SubTitleForm';

export const SummaryParameters = styled(Grid)`
  > div {
    margin-top: 8px;
  }
`;

export const Title = styled(SubTitleForm)`
  margin-top: 31px;
  margin-bottom: 8px;
`;

export const AccordionDetails = styled(AccordionDetailsUI)`
  &.MuiAccordionDetails-root {
    padding: 16px;
  }
`;

export const TextSummary = styled(Typography)`
  font-size: 12px;
`;
