import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles({
  areaSize: {
    '& textarea': {
      minHeight: '80px',
      width: '100% !important ',
    },
  },
  dialog: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '586px',
    },
  },
  div__inputs: {
    marginTop: '8px',
    '& div + div': {
      marginTop: '18px',
    },
  },
  div__subtitleDica: {
    width: '95% !important',
  },
  div__dica: {
    display: 'flex',
    alignItems: 'center',
    '& div:nth-child(2)': {
      paddingTop: '2px',
    },
  },
  div__footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '18px',
  },
});
