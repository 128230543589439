import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WrapperSubTitleForm from '../../../UI/WrapperSubTitleForm/WrapperSubTitleForm';
import Endereco from '../../../UI/EnderecosV2/Endereco/Endereco';

import { findEstadosAction } from '../../../../store/Global/LoadSelectors/loadSelectors.saga';

import { setEnderecoAttributeValueAction, setEnderecoAttributeFocusAction, setEnderecoAction } from '../../../../store/Filial/Crud/crudFilial.store';
import { useCrudFilialDispatch, useCrudFilialSelector } from '../../../../store/Filial/Crud/CrudFilialProvider';

import constants from './EnderecoCrudFilial.constants';
import useUtilStyles from '../../../../helpers/styles/utils.styles';

function EnderecoCrudFilial({ isQueryMode }) {
  const utilStyles = useUtilStyles();

  const dispatch = useCrudFilialDispatch();
  const globalDispatch = useDispatch();

  const endereco = useCrudFilialSelector(states => ({ ...states.requestFields.endereco }));
  const estados = useSelector(states => states.selectors.estados.selector);

  useEffect(() => {
    globalDispatch(findEstadosAction());
  }, [globalDispatch]);


  const setEnderecoHandler = useCallback((enderecoParam) => {
    dispatch(setEnderecoAction(enderecoParam));
  }, [dispatch]);

  const setEnderecoAttributeValueHandler = useCallback((name, value) => {
    dispatch(setEnderecoAttributeValueAction(name, value));
  }, [dispatch]);

  const setEnderecoAttributeFocusFocusHandler = useCallback((enderecoParam) => {
    dispatch(setEnderecoAttributeFocusAction(enderecoParam));
  }, [dispatch]);

  return (
    <WrapperSubTitleForm title={constants.TITLE} className={utilStyles.mt16}>
      <Endereco
        model={endereco}
        estados={estados}
        disabled={isQueryMode}
        setEndereco={setEnderecoHandler}
        setAttribute={setEnderecoAttributeValueHandler}
        setFocus={setEnderecoAttributeFocusFocusHandler}
      />
    </WrapperSubTitleForm>

  );
}

export default EnderecoCrudFilial;
