import React, { createContext } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider, createSelectorHook, createDispatchHook } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import reducer from './contaCorrenteCliente.store';
import watchCCCliente from './contaCorrenteCliente.saga';

export const context = createContext();
export const useCCClienteSelector = createSelectorHook(context);
export const useCCClienteDispatch = createDispatchHook(context);

export default function ContaCorrenteClienteProvider({ children }) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(watchCCCliente);

  return (
    <Provider context={context} store={store}>
      {children}
    </Provider>
  );
}
