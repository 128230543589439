import React from 'react';
import { useDispatch } from 'react-redux';
import RegistrarBoletoProvider from '../../store/RegistrarBoleto/RegistrarBoletoProvider';
import withTokenInterceptor from '../../hoc/withTokenInterceptor/withTokenInterceptor';
import { RegistrarBoleto } from './RegistrarBoleto';
import { initializePageAction } from '../../store/theme.actions';


function RegistrarBoletoPage() {
  const dispatch = useDispatch();

  dispatch(initializePageAction('Registrar Boletos'));
  return (
    <RegistrarBoletoProvider>
      <RegistrarBoleto />
    </RegistrarBoletoProvider>
  );
}

export default withTokenInterceptor(RegistrarBoletoPage);
