import { getUriParams } from '../../../helpers';
import {
  CORE, gateway,
} from '../../api';

export default {
  buscarInfoRegistroBoleto(data, pageable, order) {
    const body = {
      data,
    };

    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    const queryParam = getUriParams(pageable);

    return gateway.post(`${CORE}/pesquisa-registro-boletos/buscar-boleto?${queryParam}${sortedQuery}`, body);
  },

  buscarIdsBoletos(data) {
    const body = {
      data,
    };
    return gateway.post(`${CORE}/pesquisa-registro-boletos/ids-boletos`, body);
  },

  registrarBoleto(idBoletoCliente) {
    return gateway.put(`${CORE}/pesquisa-registro-boletos/registrar`, idBoletoCliente);
  },
};
