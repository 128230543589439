// Sets (conjuntos)

export function uniao(setA, setB) {
  const uniaoSet = new Set(setA);
  setB.forEach(elem => uniaoSet.add(elem));
  return uniaoSet;
}

export function interseccao(setA, setB) {
  const interseccaoSet = new Set();
  setB.forEach((elem) => {
    if (setA.has(elem)) {
      interseccaoSet.add(elem);
    }
  });
  return interseccaoSet;
}

export function diferencaSimetrica(setA, setB) {
  const diferencaSet = new Set(setA);
  setB.forEach((elem) => {
    if (diferencaSet.has(elem)) {
      diferencaSet.delete(elem);
    } else {
      diferencaSet.add(elem);
    }
  });
  return diferencaSet;
}

export function diferenca(setA, setB) {
  const diferencaSet = new Set(setA);
  setB.forEach((elem) => {
    diferencaSet.delete(elem);
  });
  return diferencaSet;
}
