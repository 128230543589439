import React from 'react';
import { useDispatch } from 'react-redux';
import { initializePageAction } from '../../../store/theme.actions';
import constants from '../../../helpers/constants/navigationTitle.constants';
import RequisicaoProvider from '../../../store/Requisicao/RequisicaoProvider';
import withTokenInterceptor from '../../../hoc/withTokenInterceptor/withTokenInterceptor';
import PesquisaRequisicao from '../../../components/Requisicao/Pesquisa/PesquisaRequisicao';


function PesquisaRequisicaoPage() {
  const dispatch = useDispatch();

  dispatch(initializePageAction(`${constants.REQUISICOES}`));

  return (
    <RequisicaoProvider>
      <PesquisaRequisicao />
    </RequisicaoProvider>
  );
}

export default withTokenInterceptor(PesquisaRequisicaoPage);
