import { StepConnector, StepLabel as StepLabelUI, withStyles } from '@material-ui/core';
import styled from 'styled-components';
import { PaperUI } from '../../components';

export const ContainerConsultaDinamica = styled(PaperUI)`

  >div:nth-child(2){
    padding: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  >div:nth-child(3) {
    display: flex;
    justify-content: space-between;

    padding: 16px;

    button {
      width: 152px;
    }
  }
`;

export const ContainerStepperConsultaDinamica = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: 38px;
  width: 38px;

  border-radius: 50%;
  background-color: ${props => ((props.active || props.completed) ? '#564872' : '#A7A7A7')};

  svg {
    color: white;
  }
`;

export const CustomStepConnector = withStyles({
  alternativeLabel: {
    top: 17,
  },
  active: {
    '& $line': {
      backgroundColor: '#564872',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#564872',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);


export const StepLabel = styled(StepLabelUI)`
  .MuiTypography-root {
    font-weight: bold !important;
    font-size: 12px;
  }

  .MuiStepLabel-active {
    color: #564872 !important;
  }

  .MuiStepLabel-completed {
    color: #564872 !important;
  }
`;
