import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './FormDialogLayout.styles';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function DialogNew({
  children,
  open,
  onClose,
  text,
  closeIcon = false,
  ...props
}) {
  const styles = useStyles();
  return (
    <Dialog
      classes={{ root: styles.rootNew }}
      open={open}
      fullWidth
      {...props}
      aria-labelledby="draggable-dialog-title"
      PaperComponent={PaperComponent}
    >
      <DialogTitle
        id="draggable-dialog-title"
        style={{
          marginTop: '20px',
          marginLeft: '25px',
          cursor: 'move',
          padding: '0px',
        }}
      >
        {text}
      </DialogTitle>
      {closeIcon && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent id="draggable-dialog-title" style={{ fontSize: '18px' }}>
        {children}
      </DialogContent>
    </Dialog>
  );
}

DialogNew.defaultProps = {
  open: false,
};

DialogNew.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.func.isRequired,
};

export default DialogNew;
