import { buildSnackbar, buildDialog, buildSettingsTable } from '../../../helpers/factory/global.factory';

import FieldModel from '../../../models/FieldModel';

const SET_SETTINGS_TABLE = 'SET_SETTINGS_TABLE_PESQUISA_USUARIO';
const SET_CACHE_REQUEST_FIELDS = 'SET_CACHE_REQUEST_FIELDS_PESQUISA_USUARIO';
const SET_PAGE = 'SET_PAGE_PESQUISA_USUARIO';
const SET_ROWS_PER_PAGE = 'SET_ROWS_PER_PAGE_PESQUISA_USUARIO';
const SET_LOADING = 'SET_LOADING_PESQUISA_USUARIO';
const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION_PESQUISA_USUARIO';
const SET_SNACKBAR = 'SET_SNACKBAR_PESQUISA_USUARIO';
const SET_REQUEST_FIELDS_VALUE = 'SET_REQUEST_FIELDS_VALUE_PESQUISA_USUARIO';
const SET_ALERT_DIALOG = 'SET_ALERT_DIALOG_PESQUISA_USUARIO';
const SET_OPTION_DIALOG = 'SET_OPTION_DIALOG_PESQUISA_USUARIO';
const SET_SHOW_ADVANCED_SEARCH = 'SET_SHOW_ADVANCED_SEARCH_PESQUISA_USUARIO';
const CLEAR_SEARCH_MODE = 'CLEAR_SEARCH_MODE_PESQUISA_USUARIO';
const CLEAR_ADVANCED_SEARCH = 'CLEAR_ADVANCED_SEARCH_PESQUISA_USUARIO';
const CLEAR = 'CLEAR_PESQUISA_USUARIO';

function buildRequestFields() {
  return {
    nome: new FieldModel({
      name: 'nome',
    }),
    status: new FieldModel({
      name: 'status',
    }),
    filial: new FieldModel({
      name: 'filial',
    }),
    tipoUsuario: new FieldModel({
      name: 'tipoUsuario',
    }),
  };
}

function buildInitialStates() {
  return {
    requestFields: buildRequestFields(),
    snackbar: buildSnackbar(),
    alertDialog: buildDialog(),
    optionDialog: buildDialog(),
    settingsTable: buildSettingsTable(),
    showAdvancedSearch: false,
    cacheRequestFields: null,
    searchMode: false,
    scrollPosition: { x: 0, y: 0 },
    loading: false,
  };
}

function changeRequestFieldsValueHandler(states, actions) {
  const { name, value } = actions;
  const fieldModel = states.requestFields[actions.name].copy();
  return { ...states, requestFields: { ...states.requestFields, [name]: fieldModel.onChange(value) } };
}

function changeAlertDialogValueHandler(states, actions) {
  const alertDialog = { ...states.alertDialog, open: actions.open };
  if (actions.variant || actions.message) {
    alertDialog.variant = actions.variant;
    alertDialog.message = actions.message;
  }
  return { ...states, alertDialog };
}

function changeOptionDialogValueHandler(states, actions) {
  const optionDialog = { ...states.optionDialog, open: actions.open };
  if (actions.variant || actions.message) {
    optionDialog.variant = actions.variant;
  }
  return { ...states, optionDialog };
}

function changeSettingsTableHandler(states, actions) {
  return {
    ...states,
    settingsTable: { ...actions.settingsTable },
  };
}

function changeCacheRequestFieldsHandler(states) {
  const nome = states.requestFields.nome.getValueNotEmpty();
  const status = states.requestFields.status.getValueNotEmpty();
  const filial = states.requestFields.filial.getValueNotEmpty();
  const tipoUsuario = states.requestFields.tipoUsuario.getValueNotEmpty();
  return {
    ...states,
    cacheRequestFields: {
      nome, status, filial, tipoUsuario,
    },
    searchMode: true,
  };
}

function changePageHandler(states, actions) {
  return {
    ...states,
    settingsTable: {
      ...states.settingsTable,
      page: actions.page,
    },
  };
}

function changeRowsPerPageAndPageHandler(states, actions) {
  return {
    ...states,
    settingsTable: {
      ...states.settingsTable,
      rowsPerPage: actions.rowsPerPage,
      page: actions.page,
    },
  };
}

function changeLoadingHandler(states, actions) {
  return {
    ...states,
    loading: actions.loading,
  };
}

function changeScrollPositionHandler(states, actions) {
  return {
    ...states,
    scrollPosition: { x: actions.x, y: actions.y },
  };
}

function changeSnackbarValueHandler(states, actions) {
  const snackbar = { ...states.snackbar, open: actions.open };
  if (actions.variant || actions.message) {
    snackbar.variant = actions.variant;
    snackbar.message = actions.message;
  }
  return { ...states, snackbar };
}

function changeStateShowAdvancedSearchHandler(states, actions) {
  return { ...states, showAdvancedSearch: actions.show };
}

function clearAdvancedSearchHandler(states) {
  const { requestFields } = states;
  requestFields.status.value = '';
  requestFields.filial.value = '';
  requestFields.tipoUsuario.value = '';
  return { ...states, requestFields };
}

function clearSearchModeHandler(states) {
  return { ...states, searchMode: false };
}

function clearHandler(states) {
  const snackbar = { ...states.snackbar, open: false };
  return {
    ...buildInitialStates(),
    snackbar,
  };
}

export const setRequestFieldsValueAction = (name, value) => ({
  type: SET_REQUEST_FIELDS_VALUE,
  name,
  value,
});

export const setAlertDialogValuesAction = (open, variant, message) => ({
  type: SET_ALERT_DIALOG,
  open,
  variant,
  message,
});

export const setOptionDialogValuesAction = (open, variant = 'warning') => ({
  type: SET_OPTION_DIALOG,
  open,
  variant,
});

export const setSettingsTableAction = (content, page, rowsPerPage, totalElements) => ({
  type: SET_SETTINGS_TABLE,
  settingsTable: buildSettingsTable(content, page, rowsPerPage, totalElements),
});

export const setCacheRequestFieldsAction = () => ({
  type: SET_CACHE_REQUEST_FIELDS,
});

export const setPageAction = page => ({
  type: SET_PAGE,
  page,
});

export const setRowsPerPageAndPageAction = (rowsPerPage, page) => ({
  type: SET_ROWS_PER_PAGE,
  rowsPerPage,
  page,
});

export const setLoadingAction = loading => ({
  type: SET_LOADING,
  loading,
});

export const setScrollPositionAction = (x, y) => ({
  type: SET_SCROLL_POSITION,
  x,
  y,
});

export const setSnackbarValuesAction = (open, variant, message) => ({
  type: SET_SNACKBAR,
  open,
  variant,
  message,
});

export const setShowAdvancedSearchAction = show => ({
  type: SET_SHOW_ADVANCED_SEARCH,
  show,
});

export const clearSearchModelAction = () => ({
  type: CLEAR_SEARCH_MODE,
});

export const clearAdvancedSearchAction = () => ({
  type: CLEAR_ADVANCED_SEARCH,
});

export const clearPesquisarUsuarioAction = () => ({
  type: CLEAR,
});

export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    case SET_REQUEST_FIELDS_VALUE:
      return changeRequestFieldsValueHandler(states, actions);
    case SET_SETTINGS_TABLE:
      return changeSettingsTableHandler(states, actions);
    case SET_CACHE_REQUEST_FIELDS:
      return changeCacheRequestFieldsHandler(states);
    case SET_PAGE:
      return changePageHandler(states, actions);
    case SET_ROWS_PER_PAGE:
      return changeRowsPerPageAndPageHandler(states, actions);
    case SET_LOADING:
      return changeLoadingHandler(states, actions);
    case SET_SCROLL_POSITION:
      return changeScrollPositionHandler(states, actions);
    case SET_SNACKBAR:
      return changeSnackbarValueHandler(states, actions);
    case SET_ALERT_DIALOG:
      return changeAlertDialogValueHandler(states, actions);
    case SET_OPTION_DIALOG:
      return changeOptionDialogValueHandler(states, actions);
    case SET_SHOW_ADVANCED_SEARCH:
      return changeStateShowAdvancedSearchHandler(states, actions);
    case CLEAR_SEARCH_MODE:
      return clearSearchModeHandler(states);
    case CLEAR_ADVANCED_SEARCH:
      return clearAdvancedSearchHandler(states);
    case CLEAR:
      return clearHandler(states);
    default:
      return states;
  }
};
