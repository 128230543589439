import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './MoneyField.styles';
import InputField from '../Input';

function buildStartAdornment(styles) {
  return <Typography component="p" className={styles.startAdornment}>R$</Typography>;
}

function MoneyField({
  onChange = () => {},
  value,
  onClick = () => null,
  maxlength = 20,
  reference,
  maxValue = 9999999999.99,
  showAdornment = true,
  acceptEmptyValue = false,
  ...props
}) {
  const styles = useStyles();
  let ref = useRef();
  if (reference) {
    ref = reference;
  }

  const [inputValue, setInputValue] = useState('');
  const [inputSelection, setInputSelection] = useState('');

  const format = valueParam => ((valueParam)
    ? Number(String(valueParam))
      .toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : '');

  const setInputValueHandler = useCallback((valueForFormat) => {
    let formatedValue = valueForFormat;
    if (valueForFormat === 0) {
      formatedValue = '0,00';
    } else if (valueForFormat !== '' && valueForFormat !== '0,0') {
      formatedValue = format(valueForFormat);
    }
    setInputValue(formatedValue);
  }, [setInputValue]);

  useEffect(() => {
    setInputValueHandler(value);
  }, [value, setInputValueHandler]);

  const getLimitedIntegerPartFromDecimalNumber = (integerPartValue, limitLength = 14) => {
    const maxLength = integerPartValue.length;
    if (maxLength > limitLength) {
      const minLength = maxLength - limitLength;
      return integerPartValue.slice(minLength, maxLength);
    }
    return integerPartValue;
  };

  const splitRealAndDecimalMoneyParts = (money) => {
    const decimal = money.substring(money.length - 2, money.length);
    const real = money.substring(0, money.length - 2);
    return [real, decimal];
  };

  const getValue = (valueParam) => {
    const money = valueParam.replace(/[^0-9]/g, '');
    if (money.length === 1) {
      return `0.0${money}`;
    }
    const [real, decimal] = splitRealAndDecimalMoneyParts(money);
    const decimalNumberRealPart = getLimitedIntegerPartFromDecimalNumber(real);
    const convertedValue = `${decimalNumberRealPart}.${decimal}`;
    return Number.isNaN(Number(convertedValue)) ? 0 : convertedValue;
  };

  const changeHandler = (event) => {
    const eventUpdated = { ...event };
    if ((acceptEmptyValue) && (eventUpdated.target.value === '0,0')) {
      eventUpdated.target.value = '';
      setInputValueHandler('');
      onChange(eventUpdated);
    } else {
      eventUpdated.target.value = event.target.value.replace(/[.]/g, '').replace(/[,]/g, '.');
      eventUpdated.target.value = getValue(eventUpdated.target.value);

      if (eventUpdated.target.value <= maxValue) {
        setInputValueHandler(eventUpdated.target.value);
        onChange(eventUpdated);
      }
    }
  };

  const onKeyDown = (event) => {
    if (inputSelection && (event.keyCode === 8 && acceptEmptyValue)) {
      setInputSelection('');
      event.target.value = '';
      setInputValueHandler('');
      onChange(event);
    }
  };

  const onSelect = (event) => {
    const selection = event.target.value.substring(event.target.selectionStart, event.target.selectionEnd);
    if (selection) {
      setInputSelection(selection);
    }
  };

  const clickHandler = (event) => {
    onClick(event);
  };

  return (
    <InputField
      {...props}
      onChange={changeHandler}
      onClick={clickHandler}
      startAdornment={showAdornment && buildStartAdornment(styles)}
      value={inputValue}
      maxLength={maxlength}
      onSelect={onSelect}
      onKeyDown={onKeyDown}
      inputProps={{
        ref,
        className: styles.root,
      }}
    />
  );
}

export default React.memo(MoneyField);
