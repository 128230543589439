import React from 'react';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import { ButtonGroup, Container, DateFieldContainer } from './PeriodSelectorStyles';
import DateField from '../UI/Field/Date/DateField';
import {
  DateUtils, getFieldName, toAmericanDate, toBrazilianDate,
} from '../../helpers';

function isValidDate(value) {
  const returnedDate = new Date(toAmericanDate(value));
  return returnedDate instanceof Date && !isNaN(returnedDate);
}

function PeriodSelectorComponent({
  className = '',
  name,
  range = [30, 60, 90],
  value = { interval: 30, startDate: '', endDate: '' },
  setMaxDate = false,
  withPeriod = false,
  onChange = () => { },
}) {
  const [selectedInterval] = React.useState(range.map(day => ({
    fullDate: DateUtils.makeDate(day),
    day,
  })));

  const [showAlternativeDate, setShowAlternativeDate] = React.useState(false);
  const [alternativaDateValue, setAlternativaDateValue] = React.useState({ startDate: '', endDate: '' });
  const [erroPeriodo, setErroPeriodo] = React.useState(false);
  const [erroPeriodoMessage, setErroPeriodoMessage] = React.useState('');


  function handleChangeDate(e) {
    const startDate = e?.currentTarget?.dataset?.fulldate;
    const dayInterval = e?.currentTarget?.dataset?.day || null;
    onChange(dayInterval, startDate, DateUtils.makeDate(0));
  }


  function handleChooseAlternativeDate(e) {
    const dateName = getFieldName(e.target).replace(`${name}-`, '');
    const { value: dateValue } = e.target;
    setAlternativaDateValue(oldDate => ({ ...oldDate, [dateName]: dateValue }));
  }

  function applyDate() {
    const startDate = toAmericanDate(alternativaDateValue.startDate);
    const endDate = toAmericanDate(alternativaDateValue.endDate);
    if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
      setErroPeriodo(true);
      setErroPeriodoMessage('Data inválida');
      toast.warn('Período inválido');
    } else {
      onChange(null, startDate, endDate);
    }
  }

  function onFocus(e) {
    setErroPeriodo(false);
    setErroPeriodoMessage('');
  }

  React.useEffect(() => {
    if (value.interval) {
      setAlternativaDateValue({ startDate: '', endDate: '' });
    } else {
      setAlternativaDateValue({ startDate: toBrazilianDate(value.startDate), endDate: toBrazilianDate(value.endDate) });
    }

    setShowAlternativeDate(!value.interval);
  }, [value]);

  const disabledApply = withPeriod ? !(isValidDate(alternativaDateValue.startDate) && isValidDate(alternativaDateValue.endDate)) : !isValidDate(alternativaDateValue.startDate);

  return (
    <Container className={className}>
      <p>Período Selecionado</p>
      <ButtonGroup>
        {selectedInterval.map((interval) => {
          const isSameDate = (interval.fullDate === value.startDate && value.interval);
          return (
            <Button
              key={interval.day}
              data-fulldate={interval.fullDate}
              data-day={String(interval.day)}
              variant="outlined"
              className={isSameDate && !showAlternativeDate ? 'active' : ''}
              onClick={handleChangeDate}
            >
              {`${interval.day} ${interval.day === 1 ? 'dia' : 'dias'}`}
            </Button>
          );
        })}
        <Button
          className={showAlternativeDate || !value.interval ? 'active' : ''}
          onClick={() => setShowAlternativeDate(true)}
          variant="outlined"
        >
          Outro
        </Button>
        {showAlternativeDate && (
          <DateFieldContainer>
            {(withPeriod) ? (
              <>

                <DateField
                  name={`${name}-startDate`}
                  value={alternativaDateValue.startDate}
                  maxDate={setMaxDate && new Date()}
                  onChange={handleChooseAlternativeDate}
                  label="De:"
                  error={erroPeriodo}
                  errorMessage={erroPeriodoMessage}
                  onFocus={e => onFocus(e)}
                  isForm
                  style={{ background: 'transparent', width: '97px' }}
                />


                <DateField
                  name={`${name}-endDate`}
                  value={alternativaDateValue.endDate}
                  maxDate={setMaxDate && new Date()}
                  onChange={handleChooseAlternativeDate}
                  label="Até:"
                  error={erroPeriodo}
                  errorMessage={erroPeriodoMessage}
                  onFocus={e => onFocus(e)}
                  isForm
                  style={{ background: 'transparent', width: '97px' }}
                />
              </>
            ) : (
              <DateField
                name={`${name}-startDate`}
                value={alternativaDateValue.startDate}
                onChange={handleChooseAlternativeDate}
                label="A partir de:"
                isForm
                style={{ background: 'transparent', width: '97px' }}
              />
            )}


          </DateFieldContainer>
        )}
        {showAlternativeDate && (
          <Button
            disabled={disabledApply}
            variant="outlined"
            className="action-apply"
            onClick={applyDate}
          >
            Aplicar
          </Button>
        )}
      </ButtonGroup>
    </Container>
  );
}

export const PeriodSelector = PeriodSelectorComponent;
