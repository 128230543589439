import React from 'react';

export const removalDialogMessage = idTextoPadrao => (
  <div style={{ display: 'box', textAlign: 'left', fontSize: '16px' }}>
    <p>
      Deseja excluir o Texto Padrão código
      {' '}
      {idTextoPadrao}
      ?
    </p>
    <p>
      Ao confirmar, o Texto Padrão será excluído permanentemente.
    </p>
  </div>
);
export const INFORMACOES = ' Informações';
export const NUMERO = 'Número';
export const TEXTO = 'Texto Padrão';
export const INSIRA_TEXTO = 'Insira o Texto Padrão';
export const TIPO = 'Tipo';
