import React from 'react';
import { useDispatch } from 'react-redux';

import CrudFilial from '../../../components/Filial/Crud/CrudFilial';

import CrudFilialProvider from '../../../store/Filial/Crud/CrudFilialProvider';
import { initializePageAction } from '../../../store/theme.actions';

import constants from '../../../helpers/constants/navigationTitle.constants';
import withTokenInterceptor from '../../../hoc/withTokenInterceptor/withTokenInterceptor';

function EditarFilialPage() {
  const dispatch = useDispatch();
  dispatch(initializePageAction(`${constants.FILIAL}  ${constants.SEPARADOR}  ${constants.EDITAR}`));

  return (
    <CrudFilialProvider>
      <CrudFilial isUpdateMode />
    </CrudFilialProvider>
  );
}

export default withTokenInterceptor(EditarFilialPage);
