import { useDispatch } from 'react-redux';
import React from 'react';
import { initializePageAction } from '../../../../store/theme.actions';
import withTokenInterceptor from '../../../../hoc/withTokenInterceptor/withTokenInterceptor';
import { LancamentoRecebimento } from './LancamentoRecebimento';
import RecebimentoClienteProvider from '../../../../store/Recebimento/RecebimentoClienteProvider';


function RecebimentoClienteNovoPage() {
  const dispatch = useDispatch();

  dispatch(initializePageAction('Recebimentos Clientes >  Novo Lançamento'));

  return (
    <>
      <RecebimentoClienteProvider>
        <LancamentoRecebimento isCreateMode />
      </RecebimentoClienteProvider>
    </>
  );
}

export default withTokenInterceptor(RecebimentoClienteNovoPage);
