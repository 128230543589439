import {
  Button, IconButton, makeStyles,
} from '@material-ui/core';
import styled from 'styled-components';

export default makeStyles({
  boletosTabela: {
    paddingTop: 22,
    paddingBottom: 8,
    paddingRight: 8,
    paddingLeft: 8,
  },
  gerarNovoBoleto: {
    marginBottom: 22,
    width: 35,
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#423362',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(66, 51, 98, 0.7)',
    },
    '&:disabled': {
      backgroundColor: '#c4c4c4',
      color: '#rgba(112, 112, 112, 0.64)',
    },
  },
  warningBoletos: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  warningText: {
    marginTop: 10,
    marginBottom: 10,
    fontWeight: 700,
  },
});

export const ButtonNovoBoleto = styled(IconButton)`
    width:35px;
    height:35px;
    background-color: ${({ openButtonNovoBoleto }) => (openButtonNovoBoleto === true ? 'transparent' : '#423362')};
    color: ${({ openButtonNovoBoleto }) => (openButtonNovoBoleto === true ? '#423362' : '#ffff')};
    ${({ openButtonNovoBoleto }) => (openButtonNovoBoleto === true ? 'border: 2px solid #423362;' : '')}

:hover {
  background-color: ${({ openButtonNovoBoleto }) => (openButtonNovoBoleto === true ? 'transparent' : '#423362')};
  opacity: 0.64;
}

:disabled {
  background-color: #c4c4c4;
}
 `;

export const Container = styled.section`

padding: 8px;
border :1px solid #A2A0A0;
> div {
  display:flex ;
    >div {
    display: flex ; 
      > img {
  margin-top:4px;
}
  }
 }
`;

export const CustomButtonLimpar = styled(Button)`
  width: 120px;
  height: 35px;

  background-color: white;
  color: #034DDC;
  border-radius: 16px;
  border:1px solid #034DDC;
  font-weight: bold;
  text-transform: none;

  svg {
    display: block;
  }

  span span {
    padding-left: 8px;

    display: block;
    font-size: 14px;
  }

  :disabled {
    border:1px solid #C4C4C4;
  }
`;

export const Inputs = styled.div`
display:flex ;
width:100% ;
margin-top:16px ;

>div {
  display: flex ;
  width:20% ;
  margin-right:8px ;
}
> div + div{
  width:30% ;
}
`;

export const ButtonConfirmar = styled.div`
display:flex ;
width:100% ;
margin-top:10px ;
justify-content:flex-end ;

> button {
  width: 135px;
  height: 35px;
}

`;
