import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  containerTooltip: {
    maxWidth: '500px',
  },
  multiLineSelectBox: {
    marginTop: '7px',
    width: '80%',
  },
});
