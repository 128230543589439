import React, { memo, useMemo, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { TablePagination } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import useStyles from './tabelaPesquisaTitulos.styles';
import PaginationAction from '../../../../components/UI/Tabela/PaginadaSimples/Pagination/PaginationActions';
import TooltipUI from '../../../../components/UI/Tooltip/Tooltip';

import { IconButtonTooltip } from '../../../../components';

function getAlignOption(row, column) {
  let alignOption = row.values[column.id] ? column.alignCell : 'center';
  if (!row.values[column.id] && column.showTip) {
    alignOption = 'center';
  }
  return alignOption;
}

function Row({
  row, columns, styles, columnLength, disabledActions, cannotEnabled, onClickDeleteDialogHandler, onEditTituloHandler, rows,
}) {
  const [open, setOpen] = useState(false);

  const getContent = (value, column) => {
    if (column.id === 'descricaoTipoTitulo') {
      return (
        <TooltipUI title={row.values.hintDescicaoTitulo}>
          <pre style={{ color: row.values.linhaCinza ? '#a2a0a0' : 'inherit' }}>{column.format && typeof value === 'number' ? column.format(value) : value}</pre>
        </TooltipUI>
      );
    }
    return <pre style={{ color: row.values.linhaCinza ? '#a2a0a0' : 'inherit' }}>{column.format && typeof value === 'number' ? column.format(value) : value}</pre>;
  };

  return (
    <>
      <TableRow className={styles.tableRowRoot}>
        {columns.map((column) => {
          const value = row.values[column.id];
          const alignOption = getAlignOption(row, column);
          return (
            <TableCell
              key={column.id}
              style={{
                maxWidth: column.maxWidth,
                minWidth: column.minWidth,
                width: column.width,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: alignOption,
                }}
              >
                {getContent(value, column)}
              </div>
            </TableCell>
          );
        })}
        <TableCell style={{ borderTop: '1px solid #e0e0e0' }}>
          <IconButton size="small" onClick={() => setOpen(!open)} style={{ color: '#034ddc' }}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={columnLength} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '25px',
              }}
              >
                <p style={{ flex: 1 }}>
                  <b>Protesto: </b>
                  <span style={{ color: row.values.linhaCinza ? '#a2a0a0' : 'inherit' }}>{row.values.valorProtesto}</span>
                </p>
                <p style={{ flex: 1 }}>
                  <b>Multa: </b>
                  <span style={{ color: row.values.linhaCinza ? '#a2a0a0' : 'inherit' }}>{row.values.valorMulta}</span>
                </p>
                <p style={{ flex: 1 }}>
                  <b>Juros: </b>
                  <span style={{ color: row.values.linhaCinza ? '#a2a0a0' : 'inherit' }}>{row.values.juros}</span>
                </p>
                <p style={{ flex: 1 }}>
                  <b>Honorários: </b>
                  <span style={{ color: row.values.linhaCinza ? '#a2a0a0' : 'inherit' }}>{row.values.valorHonorarios}</span>
                </p>
                <p style={{ flex: 1 }}>
                  <b>Descontos: </b>
                  <span style={{ color: row.values.linhaCinza ? '#a2a0a0' : 'inherit' }}>{row.values.valorDesconto}</span>
                </p>
                <div>
                  <IconButtonTooltip
                    hint="Editar"
                    type="edit"
                    style={{ padding: '2px', marginRight: '18px' }}
                    disabled={disabledActions
                      || row.values.linhaCinza
                      || cannotEnabled
                      || row.values.descricaoStatusTitulo !== 'Liberado'
                      || row.values.fazParteSimulacaoAcordoPropostaQualquer
                    }
                    onClick={onEditTituloHandler(row.values)}
                  />
                  <IconButtonTooltip
                    hint="Excluir"
                    type="trash"
                    style={{ padding: '2px', marginRight: '8px' }}
                    disabled={disabledActions
                      || row.values.linhaCinza
                      || cannotEnabled
                      || rows.length <= 1
                      || row.values.descricaoStatusTitulo !== 'Liberado'
                      || row.values.fazParteSimulacaoAcordoPropostaQualquer
                    }
                    onClick={onClickDeleteDialogHandler(row.values)}
                  />
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const layoutConstants = {
  LABEL_ROWS_PER_PAGE: 'Linhas por página',
  PAGINA_ANTERIOR: 'Página Anterior',
  PROXIMA_PAGINA: 'Próxima Página',
};

function TabelaPesquisaTitulos({
  disabledActions,
  columns = [],
  rows = [],
  size = 0,
  rowsPerPageOptions = [10, 15, 20],
  page = 0,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  cannotEnabled,
  onEditTituloHandler,
  onClickDeleteDialogHandler,
}) {
  const styles = useStyles();

  const columnLength = useMemo(() => columns.length + 1, [columns.length]);

  const buildEmptyRows = () => {
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

    const content = [];
    for (let i = 0; i < emptyRows; i += 1) {
      content.push(
        <TableRow key={i} style={{ height: 31 }}>
          <TableCell colSpan={columnLength}>
            <p />
          </TableCell>
        </TableRow>,
      );
    }
    return content;
  };

  return (
    <>
      <Paper className={styles.wrapper} style={{ overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  id={column.id}
                  key={column.id}
                  style={{
                    maxWidth: column.maxWidth,
                    minWidth: column.minWidth,
                    width: column.width,
                    textAlign: column.align,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <Row
                key={row.id}
                row={row}
                rows={rows}
                columns={columns}
                styles={styles}
                columnLength={columnLength}
                disabledActions={disabledActions}
                cannotEnabled={cannotEnabled}
                onEditTituloHandler={onEditTituloHandler}
                onClickDeleteDialogHandler={onClickDeleteDialogHandler}
              />
            ))}
            {buildEmptyRows()}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination
        style={{ width: '100%' }}
        rowsPerPageOptions={rowsPerPageOptions}
        colSpan={columns.length}
        component="div"
        count={size}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={layoutConstants.LABEL_ROWS_PER_PAGE}
        backIconButtonProps={{
          'aria-label': layoutConstants.PAGINA_ANTERIOR,
        }}
        nextIconButtonProps={{
          'aria-label': layoutConstants.PROXIMA_PAGINA,
        }}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        ActionsComponent={PaginationAction}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      />
    </>
  );
}

export default memo(TabelaPesquisaTitulos);
