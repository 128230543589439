import React, { useCallback, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useDispatch, useSelector } from 'react-redux';
import {
  buscarSequenceBorderoLote,
  salvarArquivoBordero,
} from '../../../../services/bordero/importacao/importacao.service';
import { convertDateZoneToLocalDateTime, getCurrentIdUsuario } from '../../../../helpers/utils/utils';
import useStyles from './importarDialog.styles';
import { setCacheRequestFieldsAction, setPageAction } from '../../../../store/Borderos/Importar/importarBordero.store';
import ButtonUI from '../../../UI/Button/ButtonUI';
import OptionDialogNew from '../../../UI/Dialogs/OptionDialog/OptionDialogNew';


// @TODO Discutir com o vitor quando ele entrar na equipe sobre o tokenInterceptor gerando aviso no console neste componente.
function ImportarDialog({ open, closeHandler }) {
  const styles = useStyles();
  const dispatch = useDispatch();
  const idUsuarioAtual = getCurrentIdUsuario();

  const arquivos = useSelector(states => states.importarBorderos.arquivos);
  const [progress, setProgress] = useState(0);
  const [qtdEnviados, setQtdEnviados] = useState(0);
  const [openDialogErrorImportacao, setOpenDialogErrorImportacao] = useState(false); // Dialog que mostra o arquivo que deu erro e o que deseja fazer.
  const [openDialogContinuacaoImportacao, setOpenDialogContinuacaoImportacao] = useState(false); // Dialog do carregamento de arquivos, fazendo lógica junto com o open.
  const [openDialogQtdImportados, setOpenDialogQtdImportados] = useState(false); // Dialog que mostra qtds de arquivos importados no sistema.
  const [nomeArquivoAtualEnvio, setNomeArquivoAtualEnvio] = useState('');
  const [loteBordero, setLoteBordero] = useState(null);
  const [ultimoArquivoEnviado, setUltimoArquivoEnviado] = useState(null); // Guarda o ultimo arquivo enviado para poder pular um quando ignorar.
  const [terminouEnviar, setTerminouEnviar] = useState(false); // Responsavel por fechar o dialog em caso de ignorar arquivos e continuar enviando.
  const [carregados, setArquivosCarregados] = useState();
  const [enviados, setQuantidadeEnviados] = useState();


  // Quando terminar de enviar, fecha o dialog.
  useEffect(() => {
    if (terminouEnviar || (arquivos.length > 0 && qtdEnviados === arquivos.length)) {
      setOpenDialogQtdImportados(true);
      closeHandler();
      setOpenDialogContinuacaoImportacao(false);
      setTerminouEnviar(false);
    }
    if ((arquivos.length <= 1 && qtdEnviados === 1) || (arquivos.length > 1 && qtdEnviados === 1)) {
      setQuantidadeEnviados(`${qtdEnviados} arquivo foi carregado no sistema.`);
    } else if ((arquivos.length <= 1 && qtdEnviados === 0) || (arquivos.length > 1 && qtdEnviados === 0)) {
      setQuantidadeEnviados('nenhum arquivo foi carregado no sistema.');
    } else if (arquivos.length > 1 && qtdEnviados > 1) {
      setQuantidadeEnviados(`${qtdEnviados} arquivos foram carregados no sistema.`);
    }
  }, [arquivos.length, qtdEnviados, closeHandler, terminouEnviar, setTerminouEnviar]);

  const configureProgressImportacao = useCallback((contador, filesLength, isContinuar = false) => {
    if (isContinuar) {
      setQtdEnviados(contador);
    } else {
      setQtdEnviados(contador += 1);
    }


    if (contador === filesLength) {
      setProgress(100);
    } else {
      setProgress(Math.ceil(contador / filesLength * 100));
    }
    if (isContinuar) {
      return contador + 1;
    }
    return contador;
  }, [setQtdEnviados, setProgress]);

  const continuarChamada = useCallback(async (contador, i, lote, idUsuario) => {
    if (i < arquivos.length) {
      const { lastModified } = arquivos[i];
      const lastModiefiedDate = convertDateZoneToLocalDateTime(new Date(lastModified));
      try {
        await salvarArquivoBordero(arquivos[i], lastModiefiedDate, lote, idUsuario);
        contador = configureProgressImportacao(contador, arquivos.length, true);
        if (i < arquivos.length) {
          i += 1;
          await continuarChamada(contador, i, lote, idUsuario);
        }
      } catch (e) {
        closeHandler();
        setNomeArquivoAtualEnvio(arquivos[i].name);
        setUltimoArquivoEnviado(i);
        setOpenDialogErrorImportacao(true);
      }
    } else {
      setTerminouEnviar(true);
    }
  }, [arquivos, setNomeArquivoAtualEnvio, setOpenDialogErrorImportacao, configureProgressImportacao, closeHandler, setUltimoArquivoEnviado, setTerminouEnviar]);

  const onClickCancelarArquivoHandler = useCallback(() => {
    closeHandler();
    setOpenDialogErrorImportacao(false);
    setOpenDialogQtdImportados(true);
  }, [closeHandler, setOpenDialogErrorImportacao, setOpenDialogQtdImportados]);

  const onClickOkHandler = useCallback(() => {
    setOpenDialogQtdImportados(false);
    setOpenDialogContinuacaoImportacao(false);
    setQtdEnviados(0);
    dispatch(setCacheRequestFieldsAction(false));
    dispatch(setPageAction(0));
  }, [dispatch, setOpenDialogContinuacaoImportacao, setOpenDialogQtdImportados, setQtdEnviados]);


  const onClickIgnorarArquivoHandler = useCallback(() => {
    setOpenDialogErrorImportacao(false);
    if (ultimoArquivoEnviado + 1 >= arquivos.length) { // Index que não existe.
      setOpenDialogContinuacaoImportacao(false);
      setOpenDialogQtdImportados(true);
    } else {
      setOpenDialogContinuacaoImportacao(true);
      continuarChamada(qtdEnviados + 1, ultimoArquivoEnviado + 1, loteBordero, idUsuarioAtual).then();
    }
  }, [continuarChamada, qtdEnviados, loteBordero, ultimoArquivoEnviado, setOpenDialogContinuacaoImportacao, idUsuarioAtual, arquivos.length, setOpenDialogQtdImportados]);

  // Percorre a lista de arquivos fazendo a chamada um a um.
  useEffect(() => {
    const fazAChamada = async (contador, i, lote, idUsuario) => {
      if (i < arquivos.length) {
        const { lastModified } = arquivos[i];
        const lastModiefiedDate = convertDateZoneToLocalDateTime(new Date(lastModified));
        try {
          await salvarArquivoBordero(arquivos[i], lastModiefiedDate, lote, idUsuario);
          contador = configureProgressImportacao(contador, arquivos.length);
          if (i < arquivos.length) {
            i += 1;
            await fazAChamada(contador, i, lote, idUsuario);
          }
          return i;
        } catch (e) {
          closeHandler();
          setUltimoArquivoEnviado(i);
          setNomeArquivoAtualEnvio(arquivos[i].name);
          setOpenDialogErrorImportacao(true);
        }
      }
    };

    const buscarLote = async () => {
      try {
        const { data } = await buscarSequenceBorderoLote();
        setLoteBordero(data.loteBordero);
        return data.loteBordero;
      } catch (e) {
        closeHandler();
      }
      return null;
    };

    if (arquivos.length > 0) {
      const i = 0;
      const contador = 0;
      buscarLote().then((loteBorderoResponse) => {
        fazAChamada(contador, i, loteBorderoResponse, idUsuarioAtual).then();
      });
    }


    if (arquivos.length <= 1) {
      const carregados = `De ${arquivos.length
        } arquivo selecionado, `;
      setArquivosCarregados(carregados);
    } else {
      const carregados = `Dos ${arquivos.length
        } arquivos selecionados, `;
      setArquivosCarregados(carregados);
    }


    // Reseta os valores.
    return () => {
      setQtdEnviados(0);
      setProgress(0);
    };
  }, [
    arquivos,
    setProgress,
    setQtdEnviados,
    closeHandler,
    configureProgressImportacao,
    dispatch,
    setNomeArquivoAtualEnvio,
    setOpenDialogErrorImportacao,
    setUltimoArquivoEnviado,
    setLoteBordero,
    idUsuarioAtual]);

  return (
    <>
      <OptionDialogNew
        open={open || openDialogContinuacaoImportacao}
        withoutButton
      >
        <Typography className={styles.typography__textOne} component="p">
          Estamos enviando o arquivo para a importação.
        </Typography>
        <Typography className={styles.typography__textTwo} component="p">
          Por favor, aguarde…
        </Typography>
        <div className={styles.div__progressbar}>
          <LinearProgress variant="determinate" value={progress} />
          <div className={styles.div__qtdEnviados}>{`Arquivos Enviados com Sucesso: ${qtdEnviados} de ${arquivos.length}`}</div>
        </div>
      </OptionDialogNew>

      <OptionDialogNew
        open={openDialogErrorImportacao}
        withoutButton
      >
        <div>
          <Typography className={styles.typography__textTwo} component="p">
            Ocorreu um erro durante o Upload do arquivo
            <b>
              {' '}
              {nomeArquivoAtualEnvio}
            </b>
          </Typography>
          <Typography className={styles.typography__textTwo} component="p">
            Você deseja:
          </Typography>
          <div className={styles.div__buttonGroup}>
            <div>
              <ButtonUI onClick={onClickCancelarArquivoHandler}>
                Cancelar
              </ButtonUI>
            </div>
            <div>
              <ButtonUI onClick={onClickIgnorarArquivoHandler}>
                Ignorar este Arquivo
              </ButtonUI>
            </div>
          </div>
        </div>
      </OptionDialogNew>

      <OptionDialogNew
        open={openDialogQtdImportados}
        onClickConfirm={onClickOkHandler}
        confirmLabel="OK, ENTENDI"
      >
        <Typography className={styles.typography__textTwo} component="p">
          {carregados}
          {enviados}
        </Typography>
      </OptionDialogNew>

    </>
  );
}

export default React.memo(ImportarDialog);
