import React from 'react';
import CheckboxField from '../../Field/Checkbox/CheckboxField';
import TooltipUI from '../../Tooltip/Tooltip';

function CheckboxSelecionarTodos({ checked, onChange, disabled }) {
  return (
    <>
      <TooltipUI title="Selecionar Todos">
        <div style={{ paddingRight: '6px' }}>
          <CheckboxField
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
      </TooltipUI>
    </>
  );
}

export default React.memo(CheckboxSelecionarTodos);
