export const INITIALIZE_MENU = '@menu/initialize_page';
export const FIND_QUANTIDADE_REQUISICOES = '@menu/find_quantidade_requisicoes';
export const SET_QUANTIDADE_NOTIFICAOES = '@menu/set_quantidade_notificacoes';

export function initializePageAction(title) {
  return { type: INITIALIZE_MENU, title };
}

export function setQuantidadeNotificacoesAction(quantidade) {
  return { type: SET_QUANTIDADE_NOTIFICAOES, quantidade };
}

export function findQuantidadeRequisicoesAction() {
  return { type: FIND_QUANTIDADE_REQUISICOES };
}
