import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;

  h3 {
    margin-right: 8px;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: #034DDC;
  }
`;

export const LightIndicator = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  background-color: #FFA206;
  border-radius: 50%;

  svg {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.3, 1.3);
  }
`;
