import { getMoneyMask, TableUtils, toBrazilianDate } from '../helpers';
import CaixaFiliaisService from '../services/core/caixafiliais/CaixaFiliaisService';

import store from '../store/createStore';

export const ID_LANCAMENTO_NAME = 'idCaixaFilial';


function getLancamentoPageableFromStore() {
  const { pageable } = store.getState().previousPageStates.caixaFiliais;
  return {
    ...pageable,
    number: pageable?.page || 0,
    size: pageable?.size || 10,
    last: pageable?.lastPage || true,
  };
}

function makeLancamentos(httpResponse) {
  return TableUtils.makeTableItems({
    httpResponse,
    makeContentFactory: (lancamento) => {
      let lancamentoStyles = { color: '#000' };
      if (lancamento?.tipoLancamentoCaixaFilial === 'CREDITO') {
        lancamentoStyles = { color: '#43a047', fontWeight: 'bold' };
      } else if (lancamento?.tipoLancamentoCaixaFilial === 'TRANSFERENCIA') {
        lancamentoStyles = { color: '#ffa000', fontWeight: 'bold' };
      }
      return {
        idCaixaFilial: lancamento?.idCaixaFilial,
        checked: false,
        dataLancamento: toBrazilianDate(lancamento?.dataLancamento),
        lancamento: lancamento?.lancamento || '',
        nomeUsuarioResponsavelLancamento: lancamento?.nomeUsuarioResponsavelLancamento || '',
        valorLancamento: getMoneyMask(lancamento?.valorLancamento) || '',
        idUsuarioAprovacao: lancamento?.idUsuarioAprovacao,
        descricaoLancamento: lancamento?.descricaoLancamento || '',
        labelAprovado: `${lancamento?.nomeResponsavelAprovacao || ''} - ${toBrazilianDate(lancamento?.dataAprovacao)}`,
        aprovado: lancamento?.aprovado || false,
        lancamentoStyles,
        existeComprovante: lancamento?.existeComprovante || false,
      };
    },
  });
}


export const CaixaDeFiliaisPageFactory = {
  makeFilial(filial) {
    return {
      id: String(filial?.idFilial),
      value: filial?.nome,
      isThisUser: filial?.isThisUser,
    };
  },
  makeLancamentos,

  defaultMakeLancamentos() {
    return makeLancamentos(getLancamentoPageableFromStore());
  },

};


export const CaixaDeFiliaisPageController = {
  async findAllFiliais() {
    const httpResponseFiliais = await CaixaFiliaisService.findAllFiliais();
    const filiaisResponse = httpResponseFiliais.data.map(CaixaDeFiliaisPageFactory.makeFilial);
    const selectedIdFilial = httpResponseFiliais.data
      .find(filial => !!filial.isOfUser)?.idFilial || '';
    return { filiais: filiaisResponse, selectedIdFilial };
  },

  async findAllLancamentos(fields, columnOrder, pageable, isCheckedAllSelection, idsLancamentos) {
    const { idFilial, period } = fields;
    let httpResponse = await CaixaFiliaisService.findAllLancamentos(idFilial, period.startDate, pageable, columnOrder.get());
    if (httpResponse.data.content.length === 0 && httpResponse.data.number > 0) {
      pageable.page -= 1;
      httpResponse = await CaixaFiliaisService.findAllLancamentos(idFilial, period.startDate, pageable, columnOrder.get());
    }
    const newLancamentos = CaixaDeFiliaisPageFactory.makeLancamentos(httpResponse.data);
    const updatedLancamentos = TableUtils.updateTableAfterChangePageOrItemsPerPage({
      newSelectionValue: isCheckedAllSelection,
      ids: idsLancamentos,
      items: newLancamentos.content,
      itemIdFieldName: ID_LANCAMENTO_NAME,
    });

    return {
      idsLancamentos: updatedLancamentos.ids,
      lancamentos: { ...newLancamentos, content: updatedLancamentos.items },
    };
  },

  async findAllIdsLancamentos(fields) {
    try {
      const httpResponse = await CaixaFiliaisService.findAllIdsLancamentos(fields.idFilial, fields.period.startDate);
      return TableUtils.makeAllIdsSelections(httpResponse.data);
    } catch (err) {
      return TableUtils.makeAllIdsSelections();
    }
  },
};
