export const TelefoneConst = {
  HINT_ADICIONAR: 'Adicionar Telefone',
  HINT_REMOVER: 'Excluir Telefone',
  TITULO: 'Adicionar Telefone',
  TITULO_VIEW: 'Telefones',
  TIPO_TELEFONE: 'Tipo Telefone',
  DDD: 'DDD',
  TELEFONE: 'Telefone',
  TELEFONE_PRINCIPAL: 'Principal',
  OBSERVACAO: 'Observação',
  PERMITIR_WHATSAPP: 'WhatsApp',
};

export const EnderecoConst = {
  CEP: 'CEP',
  ENDERECO: 'Endereço',
  NUMERO: 'Número',
  COMPLEMENTO: 'Complemento',
  BAIRRO: 'Bairro',
  CIDADE: 'Cidade',
  ESTADO: 'Estado',
};

export const UsuarioCrudConst = {
  IDENTIFICACAO_TITULO: 'Identificação',
  CPF: 'CPF',
  EMAIL: 'E-mail',
  CONFIGURACOES_USUARIO_TITULO: 'Configurações do Usuário',
  DADOS_BANCARIOS_TITULO: 'Dados Bancários',
  FAVORECIDO: 'Favorecido',
  CNPJ: 'CNPJ',
  BANCO: 'Banco',
  AGENCIA: 'Agência',
  CONTA: 'Conta',
  TIPO_CONTA: 'Tipo Conta',
  TIPO_DOCUMENTO: 'Tipo Documento',
  COMPLEMENTO: 'Complemento',
  ADVOGADO_TITULO: 'Advogado',
  NACIONALIDADE: 'Nacionalidade',
  OAB: 'OAB',
  SECCIONAL: 'Seccional',
  FASES_USUARIO_TITULO: 'Fases do Usuário',
};

export const SelectorPermissaoUsuarioConst = {
  RADIO_PERFIL: 'Perfil',
  CAMPO_PERFIL: 'Perfil do Usuário',
  RADIO_USUARIO: 'Usuário',
  CAMPO_USUARIO: 'Nome do Usuário',
  ERROR_COMPARPAR_PERMISSOES: 'Selecione um Perfil ou Usuário para comparar as permissões',
  ERROR_APLICAR_PERMISSOES: 'Selecione um Perfil ou Usuário para aplicar as permissões',
};

export const CrudUsuarioStoreConst = {
  IMAGEM_ERROR_MESSAGE: 'Imagem inválida',
  CADASTRADO_COM_SUCESSO: 'Usuário cadastrado com sucesso!',
};

export const ParametrosCrudConst = {
  TITULO_IDENTIFICACAO: 'Identificação',
  TITULO_CONFIGURACOES: 'Sistema Web',
};

export const CPF_MASK = '###.###.###-##';
export const CNPJ_MASK = '##.###.###/####-##';
export const CEP_MASK = '##.###-###';
export const DATE = '##/##/####';
export const PHONE_MASK = '(##) #####-####';
export const CELPHONE_MASK = '#####-####';
export const RESIDENCIAL_MASK = '####-####';
export const SERVICO_MASK = '####-###-####';
