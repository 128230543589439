import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButtonTooltip, InputField, SelectorField } from '../../../../../../../components';
import Grid24 from '../../../../../../../components/UI/Grid24';
import MoneyField from '../../../../../../../components/UI/Field/Money/MoneyField';
import { getRequiredLabel } from '../../../../../../../helpers';
import CheckboxField from '../../../../../../../components/UI/Field/Checkbox/CheckboxField';
import { findBancosAction } from '../../../../../../../store/Global/LoadSelectors/loadSelectors.saga';

function ChequeCampos({
  formFields,
  onFocusHandler,
  chequesDetalhes,
  chequeDetalhe,
  setChequesDetalhes,
  index,
  originalChequesDetalhes,
}) {
  const globalDispatch = useDispatch();

  const [listaValoresCheques] = useState([]);


  const getValue = (value, checked, type) => ((type === 'checkbox') ? checked : value);

  const bancos = useSelector(states => states.selectors.bancos.selector);

  const [atualizaForm, setAtualizaForm] = useState(false);

  const atualizaValorForm = useCallback(() => {
    formFields.valor = listaValoresCheques.reduce((accumulator, valor) => {
      const val = parseFloat(accumulator) + parseFloat(valor);
      return val;
    }, parseFloat(0));
  }, [listaValoresCheques, formFields]);

  const focusAttributeHandler = useCallback((event, index) => {
    const {
      name,
    } = event.target;
    chequesDetalhes[index] = {
      ...chequesDetalhes[index],
      [name]: {
        ...chequesDetalhes[index][name],
        error: false,
        errorMessage: '',
      },
    };
    setAtualizaForm(!atualizaForm);
  }, [chequesDetalhes, setAtualizaForm, atualizaForm]);

  const changeAttributeHandler = useCallback((event, index) => {
    const {
      name, value, checked, type,
    } = event.target;

    if (!chequesDetalhes[index][name].error) {
      onFocusHandler(event);
    }

    chequesDetalhes[index] = {
      ...chequesDetalhes[index],
      [name]: {
        value: getValue(value, checked, type),
        error: false,
        errorMessage: '',
      },
    };

    if (name === 'valor') {
      listaValoresCheques[index] = parseFloat(value).toFixed(2);
      atualizaValorForm();
    }
  }, [chequesDetalhes, onFocusHandler, atualizaValorForm, listaValoresCheques]);


  const onRemove = useCallback((index) => {
    const modifiedCheques = [...chequesDetalhes];
    modifiedCheques.splice(index, 1);
    setChequesDetalhes(modifiedCheques);
    listaValoresCheques.splice(index, 1);
    atualizaValorForm();
  }, [setChequesDetalhes, chequesDetalhes, listaValoresCheques, atualizaValorForm]);

  useEffect(() => {
    globalDispatch(findBancosAction());
    if (chequesDetalhes.length > 0 && listaValoresCheques.length === 0) {
      chequesDetalhes.forEach((obj) => {
        if (obj.valor.value !== undefined) {
          listaValoresCheques.push(parseFloat(obj.valor.value).toFixed(2));
        }
      });
    }
  }, [globalDispatch, listaValoresCheques, chequesDetalhes]);


  return (
    <Grid24 container isFormContainer spacing="1">
      <Grid24 xs={24} sm={16}>
        <SelectorField
          isForm
          fullWidth
          label={getRequiredLabel('Banco', true)}
          items={bancos}
          name="idBanco"
          value={chequeDetalhe?.idBanco.value}
          onChange={(event) => { changeAttributeHandler(event, index); }}
          error={chequeDetalhe?.idBanco.error}
          errorMessage={chequeDetalhe?.idBanco.errorMessage}
        />
      </Grid24>
      <Grid24 xs={24} sm={5}>
        <InputField
          isForm
          fullWidth
          label={getRequiredLabel('Agencia', true)}
          name="numeroAgencia"
          maxLength={5}
          value={chequeDetalhe?.numeroAgencia.value}
          error={chequeDetalhe?.numeroAgencia.error}
          errorMessage={chequeDetalhe?.numeroAgencia.errorMessage}
          onFocus={(event) => { focusAttributeHandler(event, index); }}
          onChange={(event) => { changeAttributeHandler(event, index); }}
        />
      </Grid24>
      <Grid24 xs={8} sm={1} style={{ marginTop: '10px', marginLeft: '20px' }}>
        <IconButtonTooltip
          type="trash"
          hint="Excluir Cheque"
          onClick={() => { onRemove(index); }}
        />
      </Grid24>
      <Grid24 xs={24} sm={5} style={{ marginTop: '12px' }}>
        <InputField
          isForm
          fullWidth
          label={getRequiredLabel('Número da Conta', true)}
          name="numeroConta"
          maxLength={10}
          value={chequeDetalhe?.numeroConta.value}
          error={chequeDetalhe?.numeroConta.error}
          errorMessage={chequeDetalhe?.numeroConta.errorMessage}
          onFocus={(event) => { focusAttributeHandler(event, index); }}
          onChange={(event) => { changeAttributeHandler(event, index); }}
        />
      </Grid24>
      <Grid24 xs={24} sm={1} style={{ marginTop: '12px' }}>
        <InputField
          isForm
          fullWidth
          label="DAC"
          name="dac"
          maxLength={1}
          value={chequeDetalhe?.dac.value}
          error={chequeDetalhe?.dac.error}
          errorMessage={chequeDetalhe?.dac.errorMessage}
          onFocus={(event) => { focusAttributeHandler(event, index); }}
          onChange={(event) => { changeAttributeHandler(event, index); }}
        />
      </Grid24>
      <Grid24 xs={24} sm={5} style={{ marginTop: '12px' }}>
        <InputField
          isForm
          fullWidth
          label={getRequiredLabel('Número do Cheque', true)}
          name="numeroCheque"
          maxLength={10}
          value={chequeDetalhe?.numeroCheque.value}
          error={chequeDetalhe?.numeroCheque.error}
          errorMessage={chequeDetalhe?.numeroCheque.errorMessage}
          onFocus={(event) => { focusAttributeHandler(event, index); }}
          onChange={(event) => { changeAttributeHandler(event, index); }}
        />
      </Grid24>
      <Grid24 xs={24} sm={6} style={{ marginTop: '12px' }}>
        <MoneyField
          isForm
          fullWidth
          label={getRequiredLabel('Valor', true)}
          name="valor"
          value={chequeDetalhe?.valor.value}
          error={chequeDetalhe?.valor.error}
          errorMessage={chequeDetalhe?.valor.errorMessage}
          onFocus={(event) => { focusAttributeHandler(event, index); }}
          onChange={(event) => { changeAttributeHandler(event, index); }}
        />
      </Grid24>
      <Grid24 xs={24} sm={6} style={{ marginTop: '27px', marginLeft: '30px' }}>
        <CheckboxField
          label="Cheque de Terceiros"
          name="serChequeTerceiro"
          checked={chequeDetalhe?.serChequeTerceiro.value}
          onChange={(event) => { changeAttributeHandler(event, index); }}
        />
      </Grid24>
    </Grid24>
  );
}

export default ChequeCampos;
