import {
  IconButton, Paper, StepLabel as StepLabelUI,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';
import { ButtonUI } from '../../../components';


export default makeStyles({
  div__header: {
    paddingTop: '22px',
    width: '100%',
  },
  div__root: {
    width: '100%',
    display: 'block',
  },
  div__estatisticas: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '14px',
  },
  estatisticas__cards: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '8px',
  },
  cards__external__root: {
    display: 'flex',
    borderRadius: '6px',
    height: '55px',
    justifyContent: 'end',
  },
  cards__external__root_parcelas: {
    display: 'flex',
    borderRadius: '6px',
    height: '55px',
    justifyContent: 'end',
    width: '58%',
  },
  cards__internal__root: {
    display: 'flex',
    width: '100%',
  },
  cards__infos__root: {
    display: 'block',
    width: '100%',
  },
  infos__title: {
    width: '100%',
    marginTop: '6px',
    textAlign: 'center',
    fontSize: '14px',
    color: 'white',
  },
  infos__titleMargin8: {
    width: '100%',
    margin: '6px 8px 0px 4px',
    textAlign: 'center',
    fontSize: '14px',
    color: 'white',
  },
  infos__counter: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'white',
  },
  cards__quitados: {
    width: '200px',
    border: '3px solid transparent',
    borderRadius: '6px',
    display: 'flex',
    fontSize: '12px',
    backgroundColor: '#52b656',
    marginRight: '10px',
  },
  icon__quitados: {
    width: '61px',
    backgroundColor: '#43a047',
    '& svg': {
      display: 'flex',
      width: '25px',
      height: '50px',
      marginLeft: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '20px',
      color: 'white',
    },
  },

  cards__ativos: {
    width: '200px',
    border: '3px solid transparent',
    borderRadius: '6px',
    display: 'flex',
    fontSize: '12px',
    backgroundColor: '#009bfa',
    marginRight: '10px',
  },

  icon__ativos: {
    width: '61px',
    backgroundColor: '#006fc2',
    '& svg': {
      display: 'flex',
      width: '25px',
      height: '50px',
      marginLeft: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '29px',
      color: 'white',
    },
  },

  cards__quebrados: {
    width: '200px',
    border: '3px solid transparent',
    borderRadius: '6px',
    display: 'flex',
    fontSize: '12px',
    backgroundColor: '#f1515b',
    marginRight: '10px',
  },
  icon__quebrados: {
    width: '61px',
    backgroundColor: '#e9303b',
    '& img': {
      display: 'flex',
      width: '25px',
      height: '50px',
      marginLeft: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '29px',
      color: 'white ',
    },
  },


  cards__simulacoes: {
    width: '200px',
    border: '3px solid transparent',
    borderRadius: '6px',
    display: 'flex',
    fontSize: '12px',
    backgroundColor: '#ffae36',
    marginRight: '10px',
  },
  icon__simulacoes: {
    width: '61px',
    backgroundColor: '#ff8a00',
    '& svg': {
      display: 'flex',
      width: '25px',
      height: '50px',
      marginLeft: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '29px',
      color: 'white',
    },
  },

  cards__simulacoesPendentes: {
    width: '230px',
    border: '3px solid transparent',
    borderRadius: '6px',
    display: 'flex',
    fontSize: '12px',
    backgroundColor: '#7D69A4',
  },
  icon__simulacoesPendentes: {
    width: '61px',
    backgroundColor: '#423362',
    '& svg': {
      display: 'flex',
      width: '25px',
      height: '50px',
      marginLeft: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '29px',
      color: 'white',
    },
  },

  icon__simulacoesPendentesGrid: {
    '& svg': {
      display: 'flex',
      width: '20px',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '28px',
      color: 'white',
    },
  },

  semAcordos: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '22px',
    '& div + div': {
      fontWeight: 'bold',
    },
  },

  semAcordosLabel: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '17px',
    fontWeight: 'bold',
    marginBottom: '23px',
  },

  border: {
    border: '1px solid #F1F4F9',
  },

  root_novoAcordo: {
    padding: '9px 7px 9px 7px',
    marginLeft: '-7px',
  },

  div__buttons: {
    display: 'flex',
    gap: '16px',
    marginTop: '28px',
  },

  div__buttonCancelar: {
    width: '100px',
  },

  div__buttonConfirmar: {
    width: '152px',
  },

  div_buttonsQuebrarAcordo: {
    display: 'flex',
    width: '100%',
    marginTop: '22px',
    gap: '10px',
    justifyContent: 'end',
  },

  dialogQuebrarAcordo: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '827px',
      maxHeigh: '175px',
    },
  },
  dialogEnviarEmai: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '585px',
      maxHeigh: '187.25px',
    },
  },
  dialogDistribuicaoPersonalizada: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '645px',
      maxHeight: '550px',
    },
  },
  dialogErroEnviarTermoAcordoBoleto: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '641px',
      maxHeigh: '136px',
    },
  },
  dialogEnviarParcelaAcordo: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '585px',
      maxHeigh: '238px',
    },
  },
  dialogAnaliseSimulacao: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '900px',
      maxHeigh: '900px',
    },
  },
  placeholderDescription: {
    '& .MuiInput-input::placeholder': {
      color: '707070',
      fontStyle: 'italic',
    },
  },
  // Dialog Analise Simulacao
  footerAnaliseSimulacao: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    border: '1px solid red',
  },
  textFieldScrollBarBox: {
    '& .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root': {
      padding: '10px 1px 2px 6px',
    },
    '& textarea::-webkit-scrollbar': {
      width: '12px',
      height: '100%',
    },
    '& textarea::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '& textarea::-webkit-scrollbar-thumb': {
      background: '#888',
    },
  },
  inputSize: {
    fontSize: 12,
  },
  acordoSimulacao: {
    marginTop: '22px',
  },
  dialogAcompanhamento: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '633px',
      maxHeigh: '199px',
    },
  },

  // Dialog alterar Parcela Dinheiro
  dialogAlterarParcelaDinheiro: {
    display: 'flow-root',
    marginBottom: '22px',
  },

  alterarParcelaSize: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '585px',
      maxHeigh: '359px',
    },
  },

  dialogRecibo: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '585px',
      maxHeigh: '175px',
    },
  },
  typography: {
    fontSize: '12px',
    marginTop: '20px',
    fontStyle: 'Roboto',
    color: '#707070',
    justifyContent: 'start',
    width: '100%',
  },
  headerDataGridParcelas: {
    '& .header-data-grid-parcelas': {
      color: '#034DDC',
      fontSize: '12px',
    },
  },
});

export const ButtonNovoAcordo = styled(IconButton)`
    width:35px;
    height:35px;

    background-color: ${({ active }) => (active === 'true' ? 'transparent' : '#423362')};
    color: ${({ active }) => (active === 'true' ? '#423362' : '#ffff')};
    ${({ active }) => (active === 'true' ? 'border: 2px solid #423362;' : '')}

    >span {
      width:22px ;
      height:22px ;
    }

:hover {
  background-color: ${({ active }) => (active === 'true' ? 'transparent' : '#423362')};
  opacity: 0.64;
}

:disabled {
  background-color: #c4c4c4;
}
 `;


export const TableHeader = styled.div`
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
`;

export const Subtitles = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  width: 100%;
`;

export const SubtitlesValue = styled.div`
  width: ${props => (props.width)};
  text-align: ${props => (props.textAlign || 'center')};
`;

export const ListSeparator = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e0e0e0;
  padding: 6px;
  width: 100%;
`;


export const TableWithAccordion = styled(Paper)`
  background-color: #f1f4f9;
  border-radius: 7px;
  margin-top: 22px;
  max-height: ${props => (props.isScrollable ? props.maxTableHeigth : 'auto')};
  overflow-x: auto;

  & td {
    font-size: .75rem;
    padding: 0px 8px !important;
  }

  & th {
    cursor: default;
    font-size: .75rem;
    color: #034ddc;
    font-weight: bold;
    padding: 0px 8px !important;
    height: 33px;
    text-align: center;
    line-height: 1rem;
  }

`;

export const Container = styled.section`
margin-top: 14px;
border :1px solid #A2A0A0;
margin-bottom: 19px;
`;

export const StepLabel = styled(StepLabelUI)`
  .MuiTypography-root {
    font-weight: bold !important;
    font-size: 14px;
  }

    .MuiStepIcon-root.MuiStepIcon-active{
   color: #034DDC !important;
}
.MuiStepIcon-root.MuiStepIcon-completed{
  color: #034DDC !important;
}
`;

export const StepSubLabel = styled.div`
    font-size: 16px;
    font-family:Roboto ;
    color:#707070;
`;

export const ContainerNovoAcordo = styled.div`
  padding:14px 0 28px 0px ;
`;

export const Buttons = styled(ButtonUI)`
  display:flex ;
  width:148px ;
`;

export const Passo3Body = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableAccordion = styled(Paper)`
  background-color: #f1f4f9;
  border-radius: 7px;
  max-height: ${props => (props.isScrollable ? props.maxTableHeigth : 'auto')};
  overflow-x: auto;
 
  & td {
    font-size: .75rem;
    padding: 0px 8px !important;
  }

  & th {
    cursor: default;
    font-size: .75rem;
    color: black;
    font-weight: bold;
    padding: 0px 8px !important;
    height: 33px;
    text-align: center;
    line-height: 1rem;
  }

`;
