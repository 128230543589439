import React from 'react';

import PrivateRoute from '../../hoc/PrivateRoute/PrivateRoute';

import InpsecionarBorderoPage from './Inspecionar/InpecionarBorderoPage';
import IncluirBorderoPage from './Incluir/IncluirBorderoPage';
import ProcessarBorderoPage from './Processar/ProcessarBorderoPage';
import LogProcessamentoBorderoPage from './LogProcessamento/LogProcessamentoBorderoPage';
import ImportarBorderoPage from './Importar/ImportarBorderoPage';
import ValidarBorderoPage from '../ValidarBorderoPage/ValidarBorderoPage';

export default function RoutesBoderos() {
  return (
    <>
      <PrivateRoute
        path="/importar-bordero"
        component={ImportarBorderoPage}
        tipoUsuario="U"
        roles={['803']}
      />
      <PrivateRoute
        path="/processar-borderos"
        roles={['802']}
        tipoUsuario="U"
        component={ProcessarBorderoPage}
      />
      <PrivateRoute
        exact
        path="/incluir-bordero"
        component={IncluirBorderoPage}
        tipoUsuario="U"
        roles={['801']}
      />
      <PrivateRoute
        roles={['802']}
        tipoUsuario="U"
        path="/inspecionar/:id"
        component={InpsecionarBorderoPage}
      />
      <PrivateRoute
        roles={['802']}
        tipoUsuario="U"
        path="/logs-processamentos-borderos"
        component={LogProcessamentoBorderoPage}
      />
      <PrivateRoute
        path="/validar-bordero-correcao"
        component={ValidarBorderoPage}
        tipoUsuario="U"
      />
    </>
  );
}
