import { toAmericanDate, toBrazilianDate } from '../../../../helpers';
import FieldModel from '../../../../models/FieldModel';
import { alterarParcelaBoleto } from '../../../../services/core/processos/processosAcordosService';

class DialogAlterarParcelaBoletoController {
  makeFormFields(dadosParcelaBoleto) {
    return ({
      dataVencimento: new FieldModel({
        name: 'dataVencimento',
        value: toBrazilianDate(dadosParcelaBoleto.dataVencimento) || '',
      }),
      observacaoBoleto: new FieldModel({
        name: 'observacaoBoleto',
        value: dadosParcelaBoleto.observacao || '',
      }),
    });
  }


  makePayload(idProcesso, nroParcela, idAcordo, idAcordoParcela, tipoFormPgtp, idBoletoCliente, formFields) {
    return {
      dataVencimento: toAmericanDate(formFields.dataVencimento.value),
      observacao: formFields.observacaoBoleto.value,
      idProcesso,
      nroParcela,
      idAcordo,
      idAcordoParcela,
      tipoFormPgtp,
      idBoletoCliente,
    };
  }

  async salvar(idProcesso, nroParcela, idAcordo, idAcordoParcela, tipoFormPgtp, idBoletoCliente, formFields) {
    const payload = this.makePayload(idProcesso, nroParcela, idAcordo, idAcordoParcela, tipoFormPgtp, idBoletoCliente, formFields);
    const retorno = await alterarParcelaBoleto(payload);
    return retorno;
  }
}

export const dialogAlterarParcelaBoletoController = new DialogAlterarParcelaBoletoController();
