import React from 'react';
import { v4 as uuid } from 'uuid';

import {
  formatToNumberMask, getMoneyMask, getNewPage, TableUtils, toastUnmappedException, toBrazilianDate,
} from '../../helpers';
import { TableDefault } from '../UI/Tabela/TableDefault';
import { loadingInspecaoProcessoController } from '../../pages/InspecionarProcesso/loadingInspecaoProcessoController';
import { Container } from './ListaRecalculoProcessoStyles';
import { TableHeaderListaRecalculoProcesso } from '../TabelaHeader/TableHeaderListaRecalculoProcesso';
import { TableBodyListaRecalculoProcesso } from '../TabelaBody/TableBodyListaRecalculoProcesso';
import { findAllRecalculosProcessoService } from '../../services/core/processos/processoRecalculoService';


function makeListaRecalculos(httpResponse) {
  return {
    key: uuid().slice(0, 6),
    dataRecalculo: toBrazilianDate(httpResponse?.dataRecalculo),
    dataBaseRecalculo: toBrazilianDate(httpResponse?.dataBaseRecalculo),
    nomeFase: httpResponse?.nomeFase || '',
    nomeUsuario: httpResponse?.nomeUsuario || '',
    motivoRecalculo: httpResponse?.motivoRecalculo || '',
    descricaoTipoDesconto: httpResponse?.descricaoTipoDesconto || '',
    valorTotal: getMoneyMask(httpResponse?.valorTotal),
    porcentagemTaxaJuros: formatToNumberMask(httpResponse?.porcentagemTaxaJuros, '%'),
    valorJuros: getMoneyMask(httpResponse?.valorJuros),
    utilizarJurosSimples: httpResponse?.utilizarJurosSimples,
    calcularJurosUnico: httpResponse?.calcularJurosUnico,
    porcentagemHonorarios: formatToNumberMask(httpResponse?.porcentagemHonorarios, '%'),
    valorHonorarios: getMoneyMask(httpResponse?.valorHonorarios),
    porcentagemDesconto: formatToNumberMask(httpResponse?.porcentagemDesconto, '%'),
    valorDesconto: getMoneyMask(httpResponse?.valorDesconto),
    porcentagemTaxaCartao: formatToNumberMask(httpResponse?.porcentagemTaxaCartao, '%'),
    valorTaxaCartao: getMoneyMask(httpResponse?.valorTaxaCartao),
  };
}

function ListaRecalculoProcessoComponent({ processoIdsWithUpdater, setLoadings }) {
  const [listaRecalculos, setListaRecalculos] = React.useState(TableUtils.makeTableItems({}));

  function handlePageChange(e, newPage) {
    const pageable = { size: listaRecalculos.size, page: Number(newPage) };
    setListaRecalculos(oldRecalculos => ({ ...oldRecalculos, ...pageable }));
  }

  function handleChangeRowsPerPage(e) {
    const size = e.target.value;
    const newPage = getNewPage(listaRecalculos.size, listaRecalculos.page, size);
    const pageable = { size, page: Number(newPage) };
    setListaRecalculos(oldRecalculos => ({ ...oldRecalculos, ...pageable }));
  }

  React.useEffect(() => {
    setLoadings(loadingInspecaoProcessoController.changeLoadingListaRecalculos(true));
    const pageable = { page: listaRecalculos.page, size: listaRecalculos.size };
    findAllRecalculosProcessoService(processoIdsWithUpdater.id, pageable).then((httpResponse) => {
      setListaRecalculos(TableUtils.makeTableItems({
        httpResponse: httpResponse.data,
        makeContentFactory: makeListaRecalculos,
      }));
    }).catch((e) => {
      toastUnmappedException(e, 'Ocorreu um problema ao tentar consultar a lista de recalculos');
    }).finally(() => {
      setLoadings(loadingInspecaoProcessoController.changeLoadingListaRecalculos(false));
    });
  }, [processoIdsWithUpdater, setLoadings, listaRecalculos.page, listaRecalculos.size]);

  return (
    <Container>
      <TableDefault
        columnsHead={<TableHeaderListaRecalculoProcesso />}
        page={listaRecalculos.page}
        rowsPerPage={listaRecalculos.size}
        totalElements={listaRecalculos.totalElements}
        totalElementsOnPage={listaRecalculos.content?.length}
        totalColumns={9}
        emptyRowsHeight={42}
        rowsPerPageOptions={[5, 10, 15]}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      >
        {listaRecalculos.content.map(item => (
          <TableBodyListaRecalculoProcesso key={item.key} recalculo={item} />
        ))}
      </TableDefault>
    </Container>
  );
}

export const ListaRecalculoProcesso = ListaRecalculoProcessoComponent;
