import { makeStyles } from '@material-ui/core/styles';
import { warmGreyTwo } from '../../../../helpers/constants/colors.constants';

export default makeStyles({
  rootProtestado: {
    flexWrap: 'wrap',
    marginTop: '16px',
    '& p': {
      marginTop: '3px',
      marginRight: '16px',
      fontSize: '0.75rem',
    },
  },
  radioGroup: {
    display: 'inline-block',
    padding: 0,
  },
  tipoDocumento: {
    '& .MuiInputBase-root': {
      width: '70px !important',
    },
  },
  removeButton: {
    paddingTop: '11px',
  },
  buttonDadosRetornoEnable:{
    cursor: 'pointer',
    color: 'black',
  },
  buttonDadosRetornoDisabled:{
    cursor: 'default',
    color: warmGreyTwo,
  },
  buttonDadosRetornoComItens:{
    cursor: 'pointer',
    color: 'green',
  },
});
