import { Button, IconButton } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  dialog: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '610px',
      maxHeight: '305px',
    },
  },
});
export const Notifications = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 280px;
  border-radius: 4px;
  padding-top: 9px;
  padding-bottom: 9px;

  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.25);
  color: black;
  background-color: white;
`;

export const CloseButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  text-transform: none;
`;

export const CloseContainer = styled.div`
  width: ${props => props.width}px;
  border-radius: 4px;

  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.25);
  background-color: white;
`;

export const SettingsContainer = styled.div`
  width: 148px;
  height: 98px;
  padding: 16px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.25);

  & >div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & >div + div {
    margin-top: 16px;
  }
`;

export const ButtonColorGroup = styled.div`
  & >button + button {
    margin-left: 8px;
  }
`;

export const ColorButton = styled(IconButton)`
  background-color: ${props => props.background};


  ${props => props.background === 'white' && css`
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
  `}

  ${props => props.selected && css`
    border: solid 2px #ff1381;
  `}

  :hover {
    opacity: 0.64;
    background-color: ${props => props.background};
  }
`;
