import { TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { TableCellRight } from '../../../../../components/UI/Tabela/tableDefaultStyles';
import { getMoneyMask } from '../../../../../helpers';
import usePasso3Styles from './Passo3Styles';

function TableFooterPassoTres({
  calculateTotalParcelas,
}) {
  const passo3Styles = usePasso3Styles();

  return (
    <TableRow>
      <TableCell />
      <TableCell />
      <TableCellRight>
        <Typography className={passo3Styles.totalParcelasText}>
          {getMoneyMask(calculateTotalParcelas())}
        </Typography>
      </TableCellRight>
      <TableCell />
      <TableCell />

    </TableRow>
  );
}
export default TableFooterPassoTres;
