import React, {
  useCallback, useState, useImperativeHandle,
} from 'react';
import * as _ from 'lodash';

import { Button } from '@mui/material';
import useStyles from './dadosRetorno.styles';
import {
  EDIT_TITLE, ROUTE_NAME_INCLUIR, ROUTE_NAME_INSPECIONAR, TOOLTIP,
} from './dadosRetorno.constants';
import AddActionTitleWrapper from '../../../../UI/Wrapper/AddActionTitle/AddActionTitleWrapper';
import { buildDadoRetorno } from '../../../../../helpers/factory/titulos.factory';
import { useCrudBoderoDispatch } from '../../../../../store/Borderos/Crud/CrudBorderoProvider';
import { setDadosRetornoAction } from '../../../../../store/Borderos/Crud/CrudBordero.store';
import DadoRetorno from './DadoRetorno/DadoRetorno';
import DialogNew from '../../../../../layouts/FormDialog/DialogNew';


export const DadosRetorno = React.forwardRef(({
  numero,
  indexTitulo,
  disabled,
  onEnableColor,
}, ref) => {
  const dispatch = useCrudBoderoDispatch();
  const styles = useStyles();

  const [fields, setFields] = useState([]);
  const [visible, setVisible] = useState(false);

  function validarCamposObrigatorios() {
    setFields(fields.map((field) => {
      const updatedField = { ...field };
      if (!field.nome.isFilledField()) {
        updatedField.nome.error = true;
        updatedField.nome.errorMessage = 'Campo Obrigatório';
      }

      if (!field.valor.isFilledField()) {
        updatedField.valor.error = true;
        updatedField.valor.errorMessage = 'Campo Obrigatório';
      }

      return updatedField;
    }));
  }

  function naoTemErrosCampos() {
    return fields.filter(field => field.nome.error || field.valor.error).length === 0;
  }

  function addLinhaDadoRetornoHandler() {
    setFields([...fields, buildDadoRetorno()]);
  }

  function onChangeFieldsHandler(name, value, index) {
    setFields((data) => {
      const newFields = [...data];
      newFields[index][name].value = value;
      return newFields;
    });
  }

  function onFocusFieldsHandler(name, index) {
    setFields((data) => {
      const newFields = [...data];
      newFields[index][name].onFocus();
      return newFields;
    });
  }

  function onRemoveFieldsHandler(index) {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  }

  function onCloseHandler() {
    setVisible(false);
    onEnableColor();
  }

  function onConfirmHandler() {
    if (disabled) {
      onCloseHandler();
      return;
    }

    validarCamposObrigatorios();
    if (naoTemErrosCampos()) {
      dispatch(setDadosRetornoAction(_.cloneDeep(fields), indexTitulo));
      onCloseHandler();
    }
  }

  const openModal = useCallback((datas) => {
    setVisible(true);
    setFields(_.cloneDeep(datas));
  }, []);

  useImperativeHandle(ref, () => ({
    openModal,
  }));

  return (
    <DialogNew
      open={visible}
      text={disabled ? [ROUTE_NAME_INSPECIONAR] : [ROUTE_NAME_INCLUIR]}
      onClose={onCloseHandler}
      className={styles.dialog}
      confirmLabel="Confirmar"
      cancelLabel="Cancelar"
    >
      <div className={styles.div__header}>
        <p>
          <b>Título: </b>
          {' '}
          {numero.value}
        </p>
      </div>
      <AddActionTitleWrapper
        title={EDIT_TITLE}
        disabled={disabled}
        addHandler={addLinhaDadoRetornoHandler}
        tooltip={TOOLTIP}
        readMode={disabled}
      />
      {fields.length > 0 && (
        <div className={styles.div__root}>
          {fields.map((dado, index) => (
            <div key={index} className={styles.div__dadoRetorno}>
              <DadoRetorno
                model={dado}
                index={index}
                disabled={disabled}
                setAttribute={onChangeFieldsHandler}
                setFocus={onFocusFieldsHandler}
                onRemove={() => onRemoveFieldsHandler(index)}
              />
            </div>
          ))}
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
        <Button
          onClick={onCloseHandler}
        >
          Cancelar
        </Button>
        <Button
          onClick={onConfirmHandler}
        >
          {disabled ? 'Voltar' : 'Confirmar'}
        </Button>
      </div>
    </DialogNew>
  );
});
