import React, { useCallback, useState } from 'react';
import { FormControl, RadioGroup } from '@material-ui/core';
import { TextField, Button } from '@mui/material';
import { toast } from 'react-toastify';
import { CampoObrigatorio } from '../../../../components';
import Grid24 from '../../../../components/UI/Grid24';
import SubTitleForm from '../../../../components/UI/SubTitleForm/SubTitleForm';
import RadioField from '../../../../components/UI/Field/Radio/RadioField';
import {
  getFieldName, haveExceptionValidations, isBadRequestException, makeErrorField, useUtilStyles,
} from '../../../../helpers';
import useStyle from '../AcordosStyle';
import { analiseSimulacaoController } from './dialogAnaliseSimulacaoController';
import TooltipUI from '../../../../components/UI/Tooltip/Tooltip';
import { loadingInspecaoProcessoController } from '../../loadingInspecaoProcessoController';


const RADIO_LABELS = {
  aprovada: 'Aprovada',
  naoAprovada: 'Não Aprovada',
};

function DialogAnaliseSimulacao({
  setOpenDialogAnaliseSimulacao,
  selectedAcordoAnaliseSimulacao,
  updatePage,
  setLoadings,
  pesquisarAcordos,
  getDadosResumo,
  settingsTable,
  columnOrdenacao,
  idProcesso,
  setInfoAdicionaisAcordo,
  exibirApenasAcordosAtivosEhPropostas,
}) {
  const [formFields, setFormFields] = useState(analiseSimulacaoController.makeFormFields());
  const [errorFields, setErrorFields] = useState(analiseSimulacaoController.makeErrorFields());

  const utilStyles = useUtilStyles();
  const style = useStyle();

  const onClose = useCallback(() => {
    setOpenDialogAnaliseSimulacao(false);
  }, [setOpenDialogAnaliseSimulacao]);

  async function onClickSubmitAnalise(e) {
    e.preventDefault();
    setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(true));
    try {
      const result = await analiseSimulacaoController.persistirAnaliseSimulacao(formFields, selectedAcordoAnaliseSimulacao.idAcordo);

      if (result.data.codResult === 0) {
        toast.success(result.data.msgResult);
      }

      if (result.data.codResult === 1) {
        toast.warning(result.data.msgResult);
      }

      if (result.data.codResult === 2) {
        toast.error(result.data.msgResult, { style: { width: '392px' } });
      }

      pesquisarAcordos(settingsTable.page, settingsTable.rowsPerPage, columnOrdenacao, exibirApenasAcordosAtivosEhPropostas);
      getDadosResumo(idProcesso).then((responseData) => {
        const { data } = responseData;
        setInfoAdicionaisAcordo(data);
      });
      updatePage();
      onClose();
    } catch (error) {
      if (isBadRequestException(error)) {
        if (haveExceptionValidations(error)) {
          const validations = error?.response?.data?.validations;
          validations.forEach((validation) => {
            setErrorFields((errFields) => {
              errFields[validation.field].error = true;
              errFields[validation.field].errorMessage = validation.message;
              return errFields;
            });
          });
        } else {
          toast.error(error.response.data.message, { style: { width: '392px' } });
          pesquisarAcordos(settingsTable.page, settingsTable.rowsPerPage, columnOrdenacao, exibirApenasAcordosAtivosEhPropostas);
          getDadosResumo(idProcesso).then((responseData) => {
            const { data } = responseData;
            setInfoAdicionaisAcordo(data);
          });
          updatePage();
          onClose();
        }
      }
    } finally {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(false));
    }
  }

  const onChangeHandler = useCallback((e) => {
    const name = getFieldName(e.target);
    const { value } = e.target;
    setFormFields(oldValue => ({ ...oldValue, [name]: { ...oldValue[name], value } }));
  }, []);

  const onFocusHandler = useCallback((e) => {
    const name = getFieldName(e.target);
    setErrorFields(oldValues => ({ ...oldValues, [name]: makeErrorField() }));
  }, []);

  return (
    <>
      {errorFields?.simulacaoAprovada.error === true || errorFields.textoJustificativa.error === true ? (
        <CampoObrigatorio />
      ) : (
        ''
      )}
      <div>
        <Grid24 xs={24}>
          <FormControl style={{ width: '100%', paddingBottom: '10px' }} error={errorFields.simulacaoAprovada.error}>
            <TooltipUI open={errorFields.simulacaoAprovada.error} error title={errorFields.simulacaoAprovada.errorMessage}>
              <RadioGroup
                value={formFields.simulacaoAprovada.value}
                onChange={onChangeHandler}
                row
                className={utilStyles.padding10}
                name="simulacaoAprovada"
                onFocus={onFocusHandler}
              >
                <RadioField className={utilStyles.mr2} label={RADIO_LABELS.aprovada} value="A" />
                <RadioField className={utilStyles.mr2} label={RADIO_LABELS.naoAprovada} value="NA" />
              </RadioGroup>
            </TooltipUI>
          </FormControl>
        </Grid24>
        <Grid24 style={{ display: 'flex', flexDirection: 'column' }}>
          <SubTitleForm>
            Justificativa
          </SubTitleForm>
          <TooltipUI style={{ marginTop: '12px', marginBottom: '22px' }} open={errorFields.textoJustificativa.error} error title={errorFields.textoJustificativa.errorMessage}>
            <TextField
              name="textoJustificativa"
              label="Texto"
              InputProps={{
                style: { fontSize: '13px' },
              }}
              id="outlined-error"
              value={formFields.textoJustificativa.value}
              className={style.textFieldScrollBarBox}
              defaultValue=""
              onChange={onChangeHandler}
              rows={11}
              multiline
              onFocus={onFocusHandler}
              error={errorFields.textoJustificativa.error}
            />
          </TooltipUI>
        </Grid24>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            disabled={formFields.simulacaoAprovada.value === ''
              || formFields.simulacaoAprovada.value === null
              || formFields.simulacaoAprovada.value === undefined}
            onClick={onClickSubmitAnalise}
          >
            OK
          </Button>
        </div>
      </div>

    </>
  );
}


export default DialogAnaliseSimulacao;
