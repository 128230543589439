import { Box, Button } from '@material-ui/core';
import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { FaEraser } from 'react-icons/fa';
import { toast } from 'react-toastify';
import EnviarEmailDevedor from '../../../../../pages/InspecionarProcesso/AcaoEnviarEmailDevedor/EnviarEmailDevedor';
import { dialogEnviarEmailDevedorController } from './DialogEnviarEmailDevedorController';
import { CustomButton } from '../../../../../pages/InspecionarProcesso/InspecionarProcessoStyles';
import { buscarInfoInicialLimparEnvioEmail, submitFormEnvioEmailEmMassa } from '../../../../../services/core/processos/processosEnviarEmailDevedorService';


export default function DialogEnviarEmailDevedor({
  processosSelecionados,
  setOpenEnviarEmailDevedores,
  setLoading,
}) {
  const [formFields, setFormFields] = useState(dialogEnviarEmailDevedorController.makeFormFields());
  const [errorFields, setErrorFields] = useState(dialogEnviarEmailDevedorController.makeErrorFields());
  const [anexos, setAnexos] = useState([]);
  const [textoValue, setTextoValue] = useState('');

  const limparFormularioEstadoInicial = useCallback(async () => {
    setFormFields(dialogEnviarEmailDevedorController.makeFormFields());
    const { data } = await buscarInfoInicialLimparEnvioEmail(parseInt(processosSelecionados[0], 10));
    setFormFields(forms => ({ ...forms, emailDe: data.emailDe }));
    setErrorFields(dialogEnviarEmailDevedorController.makeErrorFields());
    setTextoValue(null);
    setAnexos([]);
  }, [setFormFields, setErrorFields, processosSelecionados]);


  const onSubmitFormEnviarEmail = useCallback(() => {
    const numberProcessosSelecionados = processosSelecionados.map(processo => parseInt(processo, 10));
    setLoading(true);
    submitFormEnvioEmailEmMassa(numberProcessosSelecionados, formFields, anexos).then(() => {
      toast.success('E-mails enviados com sucesso!');
      setOpenEnviarEmailDevedores(false);
    }).catch((e) => {
      if (e.response.data.validations && e.response.data.validations.length > 0) {
        e.response.data.validations.forEach((validation) => {
          const field = validation.field.replace(/^dadosEmailDevedorCommand\./, '');
          setErrorFields(errFields => ({
            ...errFields,
            [field]: {
              error: true,
              errorMessage: validation.message,
            },
          }));
        });
      } else if (e.response.data.message.includes('Consulte o acompanhamento dos Processos.')) {
        setOpenEnviarEmailDevedores(false);
        toast.warn(e.response.data.message);
      } else {
        toast.error(e.response.data.message);
      }
    }).finally(() => {
      setLoading(false);
    });
  }, [formFields, processosSelecionados, anexos, setOpenEnviarEmailDevedores, setLoading]);

  useEffect(() => {
    limparFormularioEstadoInicial();
  }, [limparFormularioEstadoInicial]);

  const disableConfirmar = useMemo(() => (formFields.emailDe === null || formFields.emailDe === '')
  || (formFields.assunto === null || formFields.assunto === '')
  || (formFields.texto === null || formFields.texto === ''), [formFields]);


  return (
    <Box>
      <Box style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '12px',
      }}
      >
        <Box />
        <CustomButton onClick={() => { limparFormularioEstadoInicial(); setAnexos([]); }}>
          <FaEraser size={18} />
          <span>Limpar</span>
        </CustomButton>
      </Box>
      <EnviarEmailDevedor
        formFields={formFields}
        errorFields={errorFields}
        setAnexos={setAnexos}
        setFormFields={setFormFields}
        setErrorFields={setErrorFields}
        textoValue={textoValue}
        setTextoValue={setTextoValue}
        isAcaoEmMassa
      />
      <Box style={{
        marginTop: '15px', width: '100%', display: 'flex', justifyContent: 'flex-end',
      }}
      >
        <Button style={{ marginRight: '20px', color: '#3373EC' }} onClick={() => setOpenEnviarEmailDevedores(false)}>Cancelar</Button>
        <Button style={{ color: disableConfirmar ? 'grey' : '#3373EC' }} disabled={disableConfirmar} color="primary" onClick={() => onSubmitFormEnviarEmail()}>Enviar</Button>
      </Box>
    </Box>
  );
}
