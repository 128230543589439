import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  buttonDataFu: {
    width: '75px',
    padding: 0,
    backgroundColor: '#f1f4f9 !important',
  },

  textDataFu: {
    fontSize: '12px',
    fontWeight: 'normal',
  },
});
