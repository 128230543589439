import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';

import TableHeader from '../../../../models/TableHeader';

import { useCrudFasesPastasSelector } from '../../../../store/FasesPastas/Crud/CrudFasesPastasProvider';
import { setAttributesAction, resetRequestsFieldsAction } from '../../../../store/FasesPastas/Pastas/pastas.store';
import { findPastasAction, deletePastaAction, getPastaAction } from '../../../../store/FasesPastas/Pastas/pastas.saga';
import { usePastaSelector, usePastaDispatch } from '../../../../store/FasesPastas/Pastas/PastasProvicer';

import PastasActions from '../../Actions';
import TableUI from '../../../UI/Tabela/PaginadaSimples/Table';
import AlertDialog from '../../../UI/Dialogs/AlertDialog/AlertDialog';
import CrudPastas from '../../Crud';
import SnackbarUI from '../../../UI/Snackbar/Snackbar';

import { BoldContentDialog } from '../../../UI/Dialogs/Content/Bold/BoldContentDialog';

import { ButtonNewPasta, Container } from './styles';
import constants from './constants';
import { checkPermission } from '../../../../helpers/utils/utils';
import OptionDialogNew from '../../../UI/Dialogs/OptionDialog/OptionDialogNew';

const columns = [
  new TableHeader({
    id: 'publico', label: 'Atributo', alignCell: 'center', width: 1,
  }),
  new TableHeader({
    id: 'nome', label: 'Nome da Pasta', alignCell: 'left', width: 280,
  }),
  new TableHeader({
    id: 'responsavel', label: 'Responsável', alignCell: 'left', width: 210,
  }),
  new TableHeader({
    id: 'dataCadastramento', label: 'Data de Criação', alignCell: 'center', width: 120,
  }),
  new TableHeader({
    id: 'acoes', label: 'Ações', alignCell: 'center', width: 70,
  }),
];

export default function PesquisaPastas() {
  const [rows, setRows] = useState([]);
  const [idFasePasta, setIdFasePasta] = useState();
  const [optinalDialogIngo, setOptionalDialogInfo] = useState({ folderName: '', show: false });
  const [editMode, setEditMode] = useState(false);

  const dispatch = usePastaDispatch();

  const pastas = usePastaSelector(states => states.content);
  const alert = usePastaSelector(states => states.alert);
  const snackbar = usePastaSelector(states => states.snackbar);
  const showCrudDialog = usePastaSelector(states => states.showCrudDialog);

  const isNotAllowed = useSelector((state) => {
    const { tipoUsuario, permissoes } = state.authentication.userInformation;
    return checkPermission(permissoes, 1001, tipoUsuario, 'U');
  });

  const isNotEditMode = useCrudFasesPastasSelector(states => !states.pageView.isEditMode);
  const idFase = useCrudFasesPastasSelector(states => states.requestedFields.idFase.value);

  useEffect(() => {
    dispatch(findPastasAction(idFase));
  }, [dispatch, idFase]);


  const closeRemoverDialogHandler = () => {
    setIdFasePasta(null);
    setOptionalDialogInfo(state => ({ ...state, show: false }));
  };

  const confirmRemoverDialogHandler = () => {
    dispatch(deletePastaAction(idFasePasta, idFase));
    setIdFasePasta(null);
    setOptionalDialogInfo(state => ({ ...state, show: false }));
  };

  const openRemoverDialogHandler = useCallback((idFasePastaParam, name) => {
    setOptionalDialogInfo({ folderName: name, show: true });
    setIdFasePasta(idFasePastaParam);
  }, [setIdFasePasta, setOptionalDialogInfo]);

  const openEditableDialogHandler = useCallback((idFasePastaParam) => {
    setEditMode(true);
    dispatch(getPastaAction(idFasePastaParam, idFase));
  }, [dispatch, idFase]);

  const openNewPastaDialogHandler = () => {
    setEditMode(false);
    dispatch(resetRequestsFieldsAction());
    dispatch(dispatch(setAttributesAction('showCrudDialog', true)));
  };

  const buildTableRow = useCallback(row => ({
    id: row.idFasePasta,
    values: {
      publico: row.publico ? constants.public : constants.private,
      nome: row.nome,
      responsavel: row.responsavel,
      dataCadastramento: format(parseISO(row.dataCadastramento), 'dd/MM/yyyy'),
      acoes: (
        <PastasActions
          onEdit={() => openEditableDialogHandler(row.idFasePasta)}
          onRemove={() => openRemoverDialogHandler(row.idFasePasta, row.nome)}
          allows={{
            editar: 1001,
            excluir: 1001,
          }}
        />
      ),
    },
  }), [openRemoverDialogHandler, openEditableDialogHandler]);

  useEffect(() => {
    setRows(pastas.map(buildTableRow));
  }, [setRows, pastas, buildTableRow]);

  return (
    <Container>
      <SnackbarUI
        open={snackbar.show}
        variant="success"
        message={snackbar.message}
        onClose={() => dispatch(setAttributesAction('snackbar', { ...snackbar, show: false }))}
      />
      <OptionDialogNew
        open={optinalDialogIngo.show}
        onClickCancel={closeRemoverDialogHandler}
        onClickConfirm={confirmRemoverDialogHandler}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
      >
        <div style={{ fontSize: '16px' }}>
          <p>
            {constants.removalDialogMessage(optinalDialogIngo.folderName)}
          </p>
        </div>
      </OptionDialogNew>
      <AlertDialog
        open={alert.show}
        variant="error"
        onClickConfirm={() => dispatch(setAttributesAction('alert', { ...alert, show: false }))}
      >
        <BoldContentDialog pontoFinalNaUltimaMsg fullyMessage={alert.message} />
      </AlertDialog>

      <CrudPastas
        open={showCrudDialog}
        idFase={idFase}
        isEditMode={editMode}
        onClose={() => dispatch(setAttributesAction('showCrudDialog', false))}
      />

      <ButtonNewPasta
        disabled={isNotEditMode || isNotAllowed}
        onClick={openNewPastaDialogHandler}
      >
        {constants.buttonNew}
      </ButtonNewPasta>
      <TableUI
        isScrollable
        columns={columns}
        rows={rows}
        rowsPerPage={8}
      />
    </Container>
  );
}
