import { FaLessThanEqual } from 'react-icons/fa';
import {
  DISABLE_FIELDS_RELACAO_PRESTACAO_ACTION,
  ON_FOCUS_PERIOD_FIELD_RELACAO_PRESTACAO_ACTION,
  RESET_FORM_FIELDS_PESQUISA_ACTION,
  RESET_STATES_EMPTY_VALUE,
  SET_CLIENTE_SELECIONADO_ACTION,
  SET_COMMON_ACTION,
  SET_DEFAULT_CLIENTE_SELECIONADO_ACTION,
  SET_DETALHES_CLIENTE_SELECIONADO_ACTION,
  SET_FORM_FIELDS_RELACAO_PRESTACAO_ACTION,
  SET_FORM_PESQUISA_CLIENTE_ACTION,
  SET_SIMPLE_VALUE_NAO_PRESTADOS_ACTION,
  SET_SIMPLE_VALUE_RELACAO_PRESTACAO_ACTION,
  SET_SIMPLE_VALUES_DETALHES_ACTION,
  SET_SIMPLE_VALUES_PESQUISA_ACTION,
  SET_DEFAULT_NAO_PRESTADOS,
  SET_NAO_PRESTADOS_FORM_TO_SAVE,
  SET_SIMPLE_VALUE_NAO_PRESTADOS_FORM_TO_SAVE_ACTION,
  ON_FOCUS_REQUEST_FIELDS_NAO_PRESTADOS_FORM_TO_SAVE,
  SET_RELACAO_PRESTACAO_ERROR_ACTION,
  RESET_FORM_FIELDS_RELACAO_PRESTACAO_ACTION,
  SET_SIMPLE_VALUE_PAGAMENTOS_ACTION,
  SET_SIMPLE_VALUE_PAGAMENTOS_VALOR_ACTION,
  ON_FOCUS_REQUEST_FIELDS_PAGAMENTOS_ACTION,
  SET_SIMPLE_VALUES_REPASSE_ACTION,
  SET_SIMPLE_AUTORIZACAO_REPASSE_ACTION,
  SET_FORM_FIELDS_AUTORIZACAO_REPASSE_ACTION,
  SET_FORM_CHANGE_REPASSE_ACTION,
  SET_FORM_FOCUS_REPASSE_ACTION,
  SET_FORM_FIELDS_REPASSE_ACTION,
  SET_SIMPLE_VALUE_REPASSE_LIST_IDS_ACTION,
  SET_FORM_FIELDS_RECEBIMENTOS_ACTION,
  ON_FOCUS_PERIOD_FIELD_RECEBIMENTOS_ACTION,
  SET_SIMPLE_VALUE_RECEBIMENTOS_ACTION,
  ON_CHANGE_RECEBIMENTOS_ACTION,
  SET_SIMPLE_VALUE_RECEBIMENTOS_LIST_IDS_ACTION,
  SET_RECEBIMENTOS_FORM_TO_SAVE,
  SET_FORM_FIELDS_RECEBIMENTOS_ERRORS_ACTION,
  SET_SIMPLE_VALUE_RPS_EMITIDOS,
  SET_SIMPLE_VALUE_RPS_EMITIDOS_LIST_IDS_ACTION,
  SET_SIMPLE_VALUE_RPS_EMITIDOS_FORM_ACTION,
  SET_RPS_EMITIDOS_FORM_ACTION,
  SET_SIMPLE_VALUE_LANCAMENTO_ACTION,
  SET_DADOS_GERACAO_NF_SWITCH,
  SET_SIMPLE_VALUE_DADOS_GERACAO_NF_ACTION,
  SET_SIMPLE_VALUE_DADOS_GERACAO_NF_ARQUIVO_ACTION,
  RESET_FORM_FIELDS_NF_ACTION,
  SET_SIMPLE_VALUE_NOTAS_FISCAIS_ACTION,
  SET_NOVA_NOTA,
  RESET_FORM_FIELDS_NOVA_NOTA_ACTION,
  ON_FOCUS,
  SET_SIMPLE_VALUE_BOLETOS_RELACAO_PRESTACAO_ACTION,
  SET_SIMPLE_VALUE_INFORMACOES_ADICIONAIS_RELACAO_PRESTACAO_ACTION,
  SET_FORM_FIELDS_NOVA_NOTA_ERRORS_ACTION,
  RESET_FORM_FIELDS_NOVO_BOLETO_ACTION,
  SET_NOVO_BOLETO,
  ON_FOCUS_BOLETO,
  SET_FORM_FIELDS_BOLETO_ERRORS_ACTION,
  SET_SIMPLE_VALUE_PDF_ACTION,
} from './contaCorrenteCliente.contants';
import {
  DateUtils,
  formatCpfCnpj,
  getDate30daysAgoCorridos,
  getMoneyMask,
  toBrazilianDate,
} from '../../helpers';
import FieldModel, { DATE, MONEY, POSITIVE_INTEGER } from '../../models/FieldModel';

const EMPTY_STRING = '';
const EMPTY_MONEY = 0;


export function buildSaldosRelacaoPrestacao(saldos) {
  return {
    totalSaldoAnterior: getMoneyMask(saldos?.totalsaldoAnterior ?? EMPTY_MONEY),
    totalSaldo: getMoneyMask(saldos?.totalsaldo ?? EMPTY_MONEY),
    totalHonorarios: getMoneyMask(saldos?.totalHonorarios ?? EMPTY_MONEY),
    totalEstornos: getMoneyMask(saldos?.totalEstornos ?? EMPTY_MONEY),
    totalDespesasReembolsaveis: getMoneyMask(saldos?.totalDespesasReembolsaveis ?? EMPTY_MONEY),
    totalDespesasNaoReembolsaveis: getMoneyMask(saldos?.totalDespesasNaoReembolsaveis ?? EMPTY_MONEY),
    totalCreditos: getMoneyMask(saldos?.totalCreditos ?? EMPTY_MONEY),
    totalPagamentos: getMoneyMask(saldos?.totalPagamentos ?? EMPTY_MONEY),
    totalAcordos: getMoneyMask(saldos?.totalAcordos ?? EMPTY_MONEY),
  };
}

export function buildResumo(resumo) {
  return {
    despesaBloquedaNaoReembolsavel: getMoneyMask(resumo?.despesaBloquedaNaoReembolsavel ?? EMPTY_MONEY),
    despesaBloquedaReembolsavel: getMoneyMask(resumo?.despesaBloquedaReembolsavel ?? EMPTY_MONEY),
    despesaLiberadaNaoReembolsavel: getMoneyMask(resumo?.despesaLiberadaNaoReembolsavel ?? EMPTY_MONEY),
    despesaLiberadaReembolsavel: getMoneyMask(resumo?.despesaLiberadaReembolsavel ?? EMPTY_MONEY),
    honorarioBloquedoAmigavel: getMoneyMask(resumo?.honorarioBloquedoAmigavel ?? EMPTY_MONEY),
    honorarioBloquedoJudicial: getMoneyMask(resumo?.honorarioBloquedoJudicial ?? EMPTY_MONEY),
    honorarioLiberadoAmigavel: getMoneyMask(resumo?.honorarioLiberadoAmigavel ?? EMPTY_MONEY),
    honorarioLiberadoJudicial: getMoneyMask(resumo?.honorarioLiberadoJudicial ?? EMPTY_MONEY),
    saldoAtual: getMoneyMask(resumo?.saldoAtual ?? EMPTY_MONEY),
    saldoAnterior: getMoneyMask(resumo?.saldoAnterior ?? EMPTY_MONEY),
    totalDemonstracao: getMoneyMask(resumo?.totalDemonstracao ?? EMPTY_MONEY),
    totalEstornos: getMoneyMask(resumo?.totalEstornos ?? EMPTY_MONEY),
    totalRepasse: getMoneyMask(resumo?.totalRepasse ?? EMPTY_MONEY),
    valorBloquedoAcordo: getMoneyMask(resumo?.valorBloquedoAcordo ?? EMPTY_MONEY),
    valorBloquedoCredito: getMoneyMask(resumo?.valorBloquedoCredito ?? EMPTY_MONEY),
    valorBloquedoPagamento: getMoneyMask(resumo?.valorBloquedoPagamento ?? EMPTY_MONEY),
    valorLiberadoAcordo: getMoneyMask(resumo?.valorLiberadoAcordo ?? EMPTY_MONEY),
    valorLiberadoCredito: getMoneyMask(resumo?.valorLiberadoCredito ?? EMPTY_MONEY),
    valorLiberadoPagamento: getMoneyMask(resumo?.valorLiberadoPagamento ?? EMPTY_MONEY),
  };
}

export function buildSaldosPagamentos(saldos) {
  return {
    resumoPagamento: getMoneyMask(saldos?.resumoPagamento ?? EMPTY_MONEY),
    saldoAtual: getMoneyMask(saldos?.saldoAtual ?? EMPTY_MONEY),
    saldoAtualWithoutMask: saldos?.saldoAtual,
  };
}

export function isDisabledButtons(saldos) {
  if (saldos?.saldoAtual === 0) {
    return true;
  }
  return false;
}

export function buildDetalhes(detalhes) {
  return {
    nome: detalhes?.nome ?? EMPTY_STRING,
    idCliente: detalhes?.idCliente ?? EMPTY_STRING,
    cnpjCpf: formatCpfCnpj(detalhes?.cnpjCpf) ?? EMPTY_STRING,
    dataUltimaPrestacao: toBrazilianDate(detalhes?.dataUltimaPrestacao) ?? EMPTY_STRING,
    descricaoPeriodicidade: detalhes?.descricaoPeriodicidade ?? EMPTY_STRING,
    descricaoRegra: detalhes?.descricaoRegra ?? EMPTY_STRING,
    statusCliente: detalhes?.statusCliente ?? EMPTY_STRING,
    suspenderPrestacaoConta: detalhes?.suspenderPrestacaoConta ?? EMPTY_STRING,
  };
}

function buildClienteSelecionado() {
  return { idCliente: null, desbloqueado: false };
}

function buildInitialStatesForm() {
  return {
    dataPrestacao: new FieldModel({ name: 'dataPrestacao', value: toBrazilianDate(new Date()) }),
    idCliente: new FieldModel({ name: 'idCliente', value: '', type: POSITIVE_INTEGER }),
    idFilial: new FieldModel({ name: 'idFilial', value: null }),
    idUsuarioResponsavel: new FieldModel({ name: 'idUsuarioResponsavel', value: null }),
    nossoNumeroBoleto: new FieldModel({ name: 'nossoNumeroBoleto', value: null }),
    tipoLancamento: new FieldModel({ name: 'tipoLancamento', value: null }),
    nomeCliente: new FieldModel({ name: 'nomeCliente', value: '' }),
    numeroRPSNotaFiscal: new FieldModel({ name: 'numeroRPSNotaFiscal', value: null }),
  };
}

export function buildFormToSave(dadosLancamento) {
  return {
    bloqueado: new FieldModel({ name: 'bloqueado', value: dadosLancamento?.bloqueado || false }),
    data: new FieldModel({ name: 'data', value: toBrazilianDate(dadosLancamento?.data) || toBrazilianDate(DateUtils.makeDate(0)), type: DATE }),
    historico: new FieldModel({
      name: 'historico', value: dadosLancamento?.historico || '', errorMessage: '', error: false,
    }),
    idCliente: new FieldModel({ name: 'idCliente', value: dadosLancamento?.idCliente || '' }),
    idContaCorrenteCliente: new FieldModel({ name: 'idContaCorrenteCliente', value: dadosLancamento?.idContaCorrenteCliente || '' }),
    idProcesso: new FieldModel({ name: 'idProcesso', value: dadosLancamento?.idProcesso || '' }),
    idTipoContaCorrenteCliente: new FieldModel({ name: 'idTipoContaCorrenteCliente', value: dadosLancamento?.idTipoContaCorrenteCliente || '' }),
    valor: new FieldModel({ name: 'valor', value: dadosLancamento?.valor || '', type: MONEY }),
  };
}

export function buildFormToSaveInserirPagamento(idCliente, isPagamento, idRelacaoSelecionada, idProcesso, valor) {
  return {
    valor: new FieldModel({ name: 'valor', value: valor || EMPTY_MONEY, type: MONEY }),
  };
}

export function buildFormToSaveRepasse() {
  return {
    periodoInicial: new FieldModel({
      name: 'periodoInicial', value: toBrazilianDate(new Date()), error: false, errorMessage: '', disabled: false,
    }),
    periodoFinal: new FieldModel({
      name: 'periodoFinal', value: toBrazilianDate(new Date()), error: false, errorMessage: '', disabled: false,
    }),
  };
}

export function buildFormAutorizacaoRepasse() {
  return {
    listarUltimosTrintaDias: new FieldModel({ name: 'listarUltimosTrintaDias', value: true, disabled: false }),
    listarSomenteNaoConcluidos: new FieldModel({ name: 'listarSomenteNaoConcluidos', value: true, disabled: false }),
  };
}

export function buildFormToSaveRecebimentos() {
  return {
    periodoInicial: new FieldModel({
      name: 'periodoInicial', value: toBrazilianDate(new Date()), error: false, errorMessage: '', disabled: false,
    }),
    periodoFinal: new FieldModel({
      name: 'periodoFinal', value: toBrazilianDate(new Date()), error: false, errorMessage: '', disabled: false,
    }),
    idCliente: new FieldModel({ name: 'idCliente', value: '' }),
    idPrestacao: new FieldModel({ name: 'idPrestacao', value: '' }),
    idResponsavel: new FieldModel({ name: 'idResponsavel', value: '' }),
    somentePadrao: new FieldModel({ name: 'somentePadrao', value: false, disable: false }),
  };
}

export function buildFormToDialogInfoRps(infos) {
  return {
    informacaoExtra: new FieldModel({ name: 'informacaoExtra', value: infos?.informacaoExtra || '' }),
    informacoesComplementares: new FieldModel({ name: 'informacoesComplementares', value: infos?.informacoesComplementares || '' }),
  };
}

function buildStatesNaoPrestados() {
  return {
    lancamentosNaoPrestados: [],
    formToSave: buildFormToSave(null),
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    columnOrdenacao: { element: 'data', order: 'ASC' },
    arrowDirection: false,
    openDialogAtualizarStatusLancamento: false,
    openDialogDeleteLancamento: false,
    idContaCorrenteClienteTable: null,
    statusContaCorrenteClienteTable: null,
    openDialogButtonNovo: false,
    exibirApenasBloqueados: false,
  };
}

function buildDefaultRelacaoPrestacaoFormFields() {
  return {
    periodoInicial: new FieldModel({
      name: 'periodoInicial', value: getDate30daysAgoCorridos(), error: false, errorMessage: '', disabled: false,
    }),
    periodoFinal: new FieldModel({
      name: 'periodoFinal', value: '', error: false, errorMessage: '', disabled: false,
    }),
    prestacaoAPagar: new FieldModel({ name: 'prestacaoAPagar', value: false, disabled: false }),
  };
}

function buildRelacaoPrestacaoFormFields() {
  return {
    periodoInicial: new FieldModel({
      name: 'periodoInicial', value: getDate30daysAgoCorridos(), error: false, errorMessage: '', disabled: false,
    }),
    periodoFinal: new FieldModel({
      name: 'periodoFinal', value: toBrazilianDate(new Date()), error: false, errorMessage: '', disabled: false,
    }),
    prestacaoAPagar: new FieldModel({ name: 'prestacaoAPagar', value: false, disabled: false }),
  };
}

function buildInformacoesAdicionaisRelacaoPrestacao() {
  return {
    autorizarEmissaoBoleto: false,
    emailsPermitemBoleto: false,
    prestacaoComNotaFiscal: false,
    requerInformacaoExtraNF: false,
    autorizarEmissaoNF: false,
    detalhesInfoExtraNF: '',
    prestacaoComNotaFiscalAgrupada: false,
    idsPrestacoesAgrupadas: '',
    dataVencimentoBoleto: '',
    idsBoletosAgrupados: '',
    prestacaoComBoletoAgrupado: false,
  };
}

function buildStatesRelacaoPrestacao() {
  return {
    relacoesPrestacoes: [],
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    columnOrdenacao: { element: 'idPrestacao', order: 'DESC' },
    arrowDirection: true,
    saldos: buildSaldosRelacaoPrestacao(null),
    formFields: buildRelacaoPrestacaoFormFields(),
    defaultFields: buildDefaultRelacaoPrestacaoFormFields(),
    openDialogCancelPrestacao: false,
    openDialogEstornoPrestacao: false,
    openDialogMotivoEstornoPrestacao: false,
    motivoEstornoPrestacao: '',
    informacoesAdicionais: buildInformacoesAdicionaisRelacaoPrestacao(),
  };
}


function buildStatesPagamentos() {
  return {
    pagamentos: [],
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    columnOrdenacao: { element: 'data', order: 'DESC' },
    arrowDirection: true,
    saldos: buildSaldosPagamentos(),
    openDialogExcluirLancamento: false,
    idContaCorrenteClienteSelecionado: null,
    formToSave: buildFormToSaveInserirPagamento(),
    disabledMoneyField: false,
    disabledButtons: true,
  };
}

function buildStatesRepasse() {
  return {
    repasse: {
      page: 0,
      rowsPerPage: 10,
      totalElements: 0,
      columnOrdenacao: { element: 'dataPrestacao', order: 'DESC' },
      arrowDirection: true,
      formFields: buildFormToSaveRepasse(),
      cacheFormFields: null,
      tableView: [],
      totalRepasse: getMoneyMask(EMPTY_MONEY),
      listIds: {},
      openDialogLiberar: false,
      totalRepasseSelecionada: EMPTY_MONEY,
      tableViewMax: [],
    },
  };
}

function buildStatesAutorizacaoRepasses() {
  return {
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    columnOrdenacao: { element: 'dataLiberacao', order: 'DESC' },
    arrowDirection: true,
    formFields: buildFormAutorizacaoRepasse(),
    autorizacoesRepasses: [],
    atualizarListaIds: false,
  };
}

function buildStatesRecebimentos() {
  return {
    tableView: [],
    tableMax: [],
    allRecebimentos: [],
    listIds: {},
    cacheFormFields: null,
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    columnOrdenacao: { element: 'idPrestacao', order: 'DESC' },
    arrowDirection: true,
    formFields: buildFormToSaveRecebimentos(),
    quantidadeSelecionadaRecebimento: 0,
    valorHonorariosAmigaveisSelecionado: EMPTY_MONEY,
    valorHonorariosJudiciaisSelecionado: EMPTY_MONEY,
    valorDespesasSelecionada: EMPTY_MONEY,
    selectAll: false,
    habilitarEmitirRps: false,
    habilitarAgruparRps: false,
  };
}

function buildStatesRpsEmitidos() {
  return {
    tableView: [],
    tableViewMax: [],
    listIds: {},
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    columnOrdenacao: { element: 'dataNota', order: 'DESC' },
    arrowDirection: true,
    selectAll: false,
    openDialogButton: false,
    formFields: buildFormToDialogInfoRps(),
    idClienteSelecionado: null,
    idPrestacaoSelecionada: null,
    detalhesInfoExtraNF: null,
    openDialogCancelar: false,
  };
}

function buildStatesLancamentos() {
  return {
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    arrowDirection: true,
    tableView: [],
  };
}

function buildFormFieldsNovaNota() {
  return {
    despesas: new FieldModel({
      name: 'despesas', value: '', error: false, errorMessage: '',
    }),
    honorarios: new FieldModel({
      name: 'honorarios', value: '', error: false, errorMessage: '',
    }),
    informacaoComplementar: new FieldModel({
      name: 'informacaoComplementar', value: '', error: false, errorMessage: '',
    }),
    informacaoExtra: new FieldModel({
      name: 'informacaoExtra', value: '', error: false, errorMessage: '',
    }),
    tipoNovaNFCliente: new FieldModel({
      name: 'tipoNovaNFCliente', value: '', error: false, errorMessage: '',
    }),
  };
}

function buildFormFieldsNovoBoleto(data) {
  return {
    dataVencimentoBoleto: new FieldModel({
      name: 'dataVencimentoBoleto',
      value: toBrazilianDate(data?.dataVencimentoBoleto) || toBrazilianDate(DateUtils.makeDate(0)),
      type: DATE,
      error: false,
      errorMessage: '',
    }),
    valor: new FieldModel({
      name: 'valor', value: '', error: false, errorMessage: '',
    }),
  };
}

function buildStatesNotasFiscais() {
  return {
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    tableView: [],
    openDialogCancelNotaFiscal: false,
    openButtonNovaNota: false,
    formFields: buildFormFieldsNovaNota(null),
  };
}

function buildFormFieldsDadosGeracaoNF(arquivo) {
  return {
    ultimos30Dias: new FieldModel({ name: 'ultimos30dias', value: true, disabled: false }),
    naoConcluidos: new FieldModel({ name: 'naoConcluido', value: true, disabled: false }),
    grupoPrefeituraNotaFiscal: new FieldModel({ name: 'grupoPrefeituraNotaFiscal', value: '' }),
    arquivoRemessaRetorno: arquivo?.arquivoRemessaRetorno || '',
  };
}

function buildStatesDadosGeracaoNF() {
  return {
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    columnOrdenacao: { element: 'data', order: 'DESC' },
    arrowDirection: true,
    dadosGeracaoNFData: [],
    formFields: buildFormFieldsDadosGeracaoNF(),
    openButtonImportarArquivo: false,
    openDialogErro: false,
    openDialogSucesso: false,
    rpsProcessados: {},
  };
}

function buildStatesBoletosRelacaoPrestacao() {
  return {
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    columnOrdenacao: { element: 'dataEmissao', order: 'DESC' },
    arrowDirection: true,
    dadosBoletos: [],
    openCancelarBoletoDialog: false,
    openButtonNovoBoleto: false,
    formFields: buildFormFieldsNovoBoleto(null),
  };
}

function buildInitialStates() {
  return {
    pesquisa: {
      formFields: buildInitialStatesForm(),
      cacheFormFields: null,
      tableView: [],
      allClientes: [],
      page: 0,
      rowsPerPage: 10,
      arrowDirection: false,
      columnOrdenacao: { element: 'nomeCliente', order: 'ASC' },
    },
    detalhes: {
      canPrestarTodos: false,
      resumoCliente: buildResumo(null),
      detalhesCliente: buildDetalhes(null),
      openDialogConfirmLancamento: false,
    },
    naoPrestados: buildStatesNaoPrestados(),
    relacaoPrestacao: buildStatesRelacaoPrestacao(),
    pagamentos: buildStatesPagamentos(),
    repasse: buildStatesRepasse(),
    autorizacaoRepasse: buildStatesAutorizacaoRepasses(),
    recebimentos: buildStatesRecebimentos(),
    rpsEmitidos: buildStatesRpsEmitidos(),
    lancamentos: buildStatesLancamentos(),
    dadosGeracaoNF: buildStatesDadosGeracaoNF(),
    notasFiscais: buildStatesNotasFiscais(),
    boletosRelacaoPrestacao: buildStatesBoletosRelacaoPrestacao(),
    pdf: null,
    common: {
      loadingPage: false,
      loadingPesquisarDadosBoletoPrestacao: false,
      loadingPesquisarNotasFiscais: false,
      loadingPesquisarRpsEmitidos: false,
      loadingPesquisarDadosGeracaoNF: false,
      loadingPesquisarRecebimentos: false,
      loadingPesquisarTiposLancamentosNaoPrestados: false,
      loadingPesquisarListaAutorizacaoRepasse: false,
      loadingPesquisarRepasse: false,
      loadingPesquisar: false,
      loadingPesquisarPagamentos: false,
      loadingPesquisarLancamentos: false,
      loadingPesquisarLancamentosNaoPrestados: false,
      clienteSelecionado: buildClienteSelecionado(), // Cliente selecionado inicialmente...
      idRelacaoSelecionada: null, // Id da relação selecionada para usar no accordion de Pagamentos...
      manterPrestacao: false,
      idPrestacaoContaClienteSelecionado: null,
    },
  };
}

function setSimpleValuePdfHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    [name]: value,
  };
}

function setCommonHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    common: {
      ...states.common,
      [name]: value,
    },
  };
}

function onChangeFormFieldsPesquisaHandler(states, actions) {
  const { name, value } = actions;
  const model = states.pesquisa.formFields[name].getNewModel(value);
  return {
    ...states,
    pesquisa: {
      ...states.pesquisa,
      formFields: {
        ...states.pesquisa.formFields,
        [name]: model,
      },
    },
  };
}

function onChangeFormFieldsRepasseHandler(states, actions) {
  const { name, value } = actions;
  const fields = states.repasse.repasse.formFields[name].onChange(value);
  return {
    ...states,
    repasse: {
      ...states.repasse,
      formFields: {
        ...states.repasse.formFields,
        [name]: fields,
      },
    },
  };
}

function onFocusFormFieldsRepasseHandler(states) {
  const periodoInicialModel = states.repasse.repasse.formFields.periodoInicial.copy();
  const periodoFinalModel = states.repasse.repasse.formFields.periodoFinal.copy();

  return {
    ...states,
    repasse: {
      ...states.repasse,
      repasse: {
        ...states.repasse.repasse,
        formFields: {
          ...states.repasse.repasse.formFields,
          periodoInicial: periodoInicialModel.onFocus(),
          periodoFinal: periodoFinalModel.onFocus(),
        },
      },
    },
  };
}

function resetFormFieldsPesquisaHandler(states) {
  return {
    ...states,
    pesquisa: {
      ...states.pesquisa,
      formFields: {
        ...states.pesquisa.formFields,
        idFilial: new FieldModel({ name: 'idFilial', value: null }),
        idUsuarioResponsavel: new FieldModel({ name: 'idUsuarioResponsavel', value: null }),
        nossoNumeroBoleto: new FieldModel({ name: 'nossoNumeroBoleto', value: null }),
        tipoLancamento: new FieldModel({ name: 'tipoLancamento', value: null }),
        nomeCliente: new FieldModel({ name: 'nomeCliente', value: null }),
        numeroRPSNotaFiscal: new FieldModel({ name: 'numeroRPSNotaFiscal', value: null }),
      },
    },
  };
}

function resetFormFieldsRelacaoPrestacaoHandler(states) {
  return {
    ...states,
    relacaoPrestacao: {
      ...states.relacaoPrestacao,
      formFields: { ...states.relacaoPrestacao.defaultFields },
    },
  };
}

function setSimpleValuesPesquisaHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    pesquisa: {
      ...states.pesquisa,
      [name]: value,
    },
  };
}

function setSimpleValuesRepasseHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    repasse: {
      ...states.repasse,
      repasse: {
        ...states.repasse.repasse,
        [name]: value,
      },
    },
  };
}

function setSimpleValuesRepasseListIdsHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    repasse: {
      ...states.repasse,
      repasse: {
        ...states.repasse.repasse,
        listIds: {
          ...states.repasse.repasse.listIds,
          [name]: value,
        },
      },
    },
  };
}

function setDefaultClienteSelecionadoHandler(states) {
  return {
    ...states,
    common: {
      ...states.common,
      clienteSelecionado: buildClienteSelecionado(),
    },
  };
}

function setDefaultNaoPrestadosHandler(states) {
  return {
    ...states,
    naoPrestados: buildStatesNaoPrestados(),
  };
}

function setClienteSelecionadoHandler(states, actions) {
  const { idCliente, status } = actions;
  const newClienteSelecionado = { idCliente, desbloqueado: status === 'DESBLOQUEADO' };
  return {
    ...states,
    common: {
      ...states.common,
      clienteSelecionado: newClienteSelecionado,
    },
  };
}

function setSimpleValuesDetalhesHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    detalhes: {
      ...states.detalhes,
      [name]: value,
    },
  };
}

function setDetalhesDoClieteSelecionadoHandler(states, actions) {
  const { resumoCliente, detalhesCliente } = actions;
  return {
    ...states,
    detalhes: {
      ...states.detalhes,
      resumoCliente: buildResumo(resumoCliente),
      detalhesCliente: buildDetalhes(detalhesCliente),
    },
  };
}

function setSimpleValueNaoPrestadosHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    naoPrestados: {
      ...states.naoPrestados,
      [name]: value,
    },
  };
}

function resetStatesEmptyValueHandler(states) {
  const newStates = buildInitialStates();
  return {
    ...newStates,
    pesquisa: {
      ...newStates.pesquisa,
      cacheFormFields: { ...states.pesquisa.cacheFormFields },
      formFields: { ...states.pesquisa.formFields },
    },
  };
}

function setSimpleValueRelacaoPrestacaoHandler(states, actions) {
  const { name, value } = actions;

  return {
    ...states,
    relacaoPrestacao: {
      ...states.relacaoPrestacao,
      [name]: value,
    },
  };
}

function onChangeFormFieldsRelacaoPrestacaoHandler(states, actions) {
  const { name, value } = actions;
  const model = states.relacaoPrestacao.formFields[name].getNewModel(value);

  return {
    ...states,
    relacaoPrestacao: {
      ...states.relacaoPrestacao,
      formFields: {
        ...states.relacaoPrestacao.formFields,
        [name]: model,
      },
    },
  };
}

function onChangeFormFieldsRelacaoPrestacaoHandlerTeste(states, actions) {
  const { name, value } = actions;

  return {
    ...states,
    relacaoPrestacao: {
      ...states.relacaoPrestacao,
      formFields: {
        ...states.relacaoPrestacao.formFields,
        [name]: value,
      },
    },
  };
}

function disableFieldsRelacaoPrestacaoHandler(states, actions) {
  const { disabled } = actions;
  return {
    ...states,
    relacaoPrestacao: {
      ...states.relacaoPrestacao,
      formFields: {
        ...states.relacaoPrestacao.formFields,
        periodoInicial: new FieldModel({ ...states.relacaoPrestacao.formFields.periodoInicial, disabled }),
        periodoFinal: new FieldModel({ disabled }),
      },
    },
  };
}

function onFocusPeriodFieldRelacaoPrestacaoHandler(states) {
  const periodoInicialModel = states.relacaoPrestacao.formFields.periodoInicial.copy();
  const periodoFinalModel = states.relacaoPrestacao.formFields.periodoFinal.copy();

  return {
    ...states,
    relacaoPrestacao: {
      ...states.relacaoPrestacao,
      formFields: {
        ...states.relacaoPrestacao.formFields,
        periodoInicial: periodoInicialModel.onFocus(),
        periodoFinal: periodoFinalModel.onFocus(),
      },
    },
  };
}

function setNaoPrestadosFormToSave(states, actions) {
  return {
    ...states,
    naoPrestados: {
      ...states.naoPrestados,
      formToSave: buildFormToSave(actions.data),
    },
  };
}

function setRpsEmitidosFormHandler(states, actions) {
  return {
    ...states,
    rpsEmitidos: {
      ...states.rpsEmitidos,
      formFields: buildFormToDialogInfoRps(actions.data),
    },
  };
}

function setSimpleValueNaoPrestaosFormToSaveHandler(states, actions) {
  const { name, value } = actions;
  const fieldModel = states.naoPrestados.formToSave[name].copy();
  return {
    ...states,
    naoPrestados: {
      ...states.naoPrestados,
      formToSave: {
        ...states.naoPrestados.formToSave,
        [name]: fieldModel.onChange(value),
      },
    },
  };
}

function changeRequestFieldsFocusNaoPrestadosFormToSaveHandler(states, actions) {
  const { name } = actions;
  const fieldModel = states.naoPrestados.formToSave[name].copy();
  return {
    ...states,
    naoPrestados: {
      ...states.naoPrestados,
      formToSave: {
        ...states.naoPrestados.formToSave,
        [name]: fieldModel.onFocus(),
      },
    },
  };
}

function setSimpleValuePagamentosHandler(states, actions, isFieldModel) {
  const { name, value } = actions;
  if (isFieldModel === true) {
    const fieldModel = states.pagamentos.formToSave[name].copy();
    return {
      ...states,
      pagamentos: {
        ...states.pagamentos,
        formToSave: {
          ...states.pagamentos.formToSave,
          [name]: fieldModel.onChange(value),
        },
      },
    };
  }
  return {
    ...states,
    pagamentos: {
      ...states.pagamentos,
      [name]: value,
    },
  };
}

function changeRequestFieldsFocusPagamentosValorHandler(states, actions) {
  const { name } = actions;
  const fieldModel = states.pagamentos.formToSave[name].copy();
  return {
    ...states,
    pagamentos: {
      ...states.pagamentos,
      formToSave: {
        ...states.pagamentos.formToSave,
        [name]: fieldModel.onFocus(),
      },
    },
  };
}

function setSimpleValuesAutorizacaoRepassesHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    autorizacaoRepasse: {
      ...states.autorizacaoRepasse,
      [name]: value,
    },
  };
}

function onChangeFormFieldsAutorizacaoRepasseHandler(states, actions) {
  const { name, value } = actions;
  const fieldModel = states.autorizacaoRepasse.formFields[name].copy();
  return {
    ...states,
    autorizacaoRepasse: {
      ...states.autorizacaoRepasse,
      formFields: {
        ...states.autorizacaoRepasse.formFields,
        [name]: fieldModel.onChange(value),
      },
    },
  };
}

function setFormFieldsRepasseHandler(states, actions) {
  const { name, value } = actions;

  return {
    ...states,
    repasse: {
      ...states.repasse,
      repasse: {
        ...states.repasse.repasse,
        formFields: {
          ...states.repasse.repasse.formFields,
          [name]: value,
        },
      },
    },
  };
}


function setFormFieldsRpsEmitidosHandler(states, actions) {
  const { name, value } = actions;
  const model = states.rpsEmitidos.formFields[name].getNewModel(value);
  return {
    ...states,
    rpsEmitidos: {
      ...states.rpsEmitidos,
      formFields: {
        ...states.rpsEmitidos.formFields,
        [name]: model,
      },
    },
  };
}

function onChangeFormFieldsRecebimentosHandler(states, actions) {
  const { name, value } = actions;
  const model = states.recebimentos.formFields[name].getNewModel(value);

  return {
    ...states,
    recebimentos: {
      ...states.recebimentos,
      formFields: {
        ...states.recebimentos.formFields,
        [name]: model,
      },
    },
  };
}


function onFocusPeriodFieldRecebimentosHandler(states) {
  const periodoInicialModel = states.recebimentos.formFields.periodoInicial.copy();
  const periodoFinalModel = states.recebimentos.formFields.periodoFinal.copy();

  return {
    ...states,
    recebimentos: {
      ...states.recebimentos,
      formFields: {
        ...states.recebimentos.formFields,
        periodoInicial: periodoInicialModel.onFocus(),
        periodoFinal: periodoFinalModel.onFocus(),
      },
    },
  };
}

function setFormFieldsRecebimentosHandler(states, actions) {
  const { name, value } = actions;
  const fieldModel = states.recebimentos.formFields[name].copy();
  return {
    ...states,
    recebimentos: {
      ...states.recebimentos,
      formFields: {
        ...states.recebimentos.formFields,
        [name]: fieldModel.onChange(value),
      },
    },
  };
}

function setSimpleValueRecebimentosHandler(states, actions) {
  const { name, value } = actions;

  return {
    ...states,
    recebimentos: {
      ...states.recebimentos,
      [name]: value,
    },
  };
}

function setSimpleValuesListIdsRecebimentosHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    recebimentos: {
      ...states.recebimentos,
      listIds: {
        ...states.recebimentos.listIds,
        [name]: value,
      },
    },
  };
}

function setRecebimentosFormFieldsErrorsHandler(states, actions) {
  const { value } = actions;
  return {
    ...states,
    recebimentos: {
      ...states.recebimentos,
      formFields: {
        ...states.recebimentos.formFields,
        value,
      },
    },
  };
}

function setSimpleValueRpsEmitidoHandler(states, actions) {
  const { name, value } = actions;

  return {
    ...states,
    rpsEmitidos: {
      ...states.rpsEmitidos,
      [name]: value,
    },
  };
}

function setSimpleValuesListIdsRpsEmitidosHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    rpsEmitidos: {
      ...states.rpsEmitidos,
      listIds: {
        ...states.rpsEmitidos.listIds,
        [name]: value,
      },
    },
  };
}

function setSimpleValueLancamentoHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    lancamentos: {
      ...states.lancamentos,
      [name]: value,
    },
  };
}
function setDadosGeracaoNFSwitchHandler(states, actions) {
  const { name, value } = actions;
  const fieldModel = states.dadosGeracaoNF.formFields[name].copy();
  return {
    ...states,
    dadosGeracaoNF: {
      ...states.dadosGeracaoNF,
      formFields: {
        ...states.dadosGeracaoNF.formFields,
        [name]: fieldModel.onChange(value),
      },
    },
  };
}

function setSimpleValueDadosGeracaoNFHandler(states, actions) {
  const { name, value } = actions;

  return {
    ...states,
    dadosGeracaoNF: {
      ...states.dadosGeracaoNF,
      [name]: value,
    },
  };
}

function setSimpleValueDadosGeracaoNFArquivoHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    dadosGeracaoNF: {
      ...states.dadosGeracaoNF,
      formFields: {
        ...states.dadosGeracaoNF.formFields,
        [name]: value,
      },
    },
  };
}

function setSimpleValueBoletosRelacaoPrestacaoHandler(states, actions) {
  const { name, value } = actions;

  return {
    ...states,
    boletosRelacaoPrestacao: {
      ...states.boletosRelacaoPrestacao,
      [name]: value,
    },
  };
}

function setSimpleValueInformacoesAdicionaisRelacaoPrestacaoHandler(states, actions) {
  const { name, value } = actions;

  return {
    ...states,
    relacaoPrestacao: {
      ...states.relacaoPrestacao,
      informacoesAdicionais: {
        ...states.relacaoPrestacao.informacoesAdicionais,
        [name]: value,
      },
    },
  };
}

function setResetFormFieldsNF(states) {
  return {
    ...states,
    dadosGeracaoNF: {
      ...states.dadosGeracaoNF,
      formFields: {
        ...states.dadosGeracaoNF.formFields,
        ultimos30Dias: new FieldModel({ name: 'ultimos30dias', value: true, disabled: false }),
        naoConcluidos: new FieldModel({ name: 'naoConcluido', value: true, disabled: false }),
        grupoPrefeituraNotaFiscal: new FieldModel({ name: 'grupoPrefeituraNotaFiscal', value: '' }),
        arquivoRemessaRetorno: '',
      },
    },
  };
}

function setNovaNotaHandler(states, actions) {
  const { name, value } = actions;
  const fieldModel = states.notasFiscais.formFields[name].copy();
  return {
    ...states,
    notasFiscais: {
      ...states.notasFiscais,
      formFields: {
        ...states.notasFiscais.formFields,
        [name]: fieldModel.onChange(value),
      },
    },
  };
}

function setSimpleValueNotasFiscaisHandler(states, actions) {
  const { name, value } = actions;

  return {
    ...states,
    notasFiscais: {
      ...states.notasFiscais,
      [name]: value,
    },
  };
}

function setFormFieldsNovaNotaHandler(states) {
  return {
    ...states,
    notasFiscais: {
      ...states.notasFiscais,
      formFields: {
        ...states.notasFiscais.formFields,
        despesas: new FieldModel({
          name: 'despesas', value: '', error: false, errorMessage: '',
        }),
        honorarios: new FieldModel({
          name: 'honorarios', value: '', error: false, errorMessage: '',
        }),
        informacaoComplementar: new FieldModel({
          name: 'informacaoComplementar', value: '', error: false, errorMessage: '',
        }),
        informacaoExtra: new FieldModel({
          name: 'informacaoExtra', value: '', error: false, errorMessage: '',
        }),
        tipoNovaNFCliente: new FieldModel({
          name: 'tipoNovaNFCliente', value: '', error: false, errorMessage: '',
        }),
      },
    },
  };
}

function onFocusHandler(states, actions) {
  const model = states.notasFiscais.formFields[actions.name].copy();
  return {
    ...states,
    notasFiscais: {
      ...states.notasFiscais,
      formFields: {
        ...states.notasFiscais.formFields,
        [actions.name]: model.onFocus(),
      },
    },
  };
}

function setNovaNotaFormFieldsErrorsHandler(states, actions) {
  const { value } = actions;
  return {
    ...states,
    notasFiscais: {
      ...states.notasFiscais,
      formFields: {
        ...states.notasFiscais.formFields,
        value,
      },
    },
  };
}

function resetFormFieldsNovoBoletoHandler(states, actions) {
  return {
    ...states,
    boletosRelacaoPrestacao: {
      ...states.boletosRelacaoPrestacao,
      formFields: {
        ...states.boletosRelacaoPrestacao.formFields,
        dataVencimentoBoleto: new FieldModel({
          name: 'dataVencimentoBoleto',
          value: toBrazilianDate(actions?.dataVencimentoBoleto) || toBrazilianDate(DateUtils.makeDate(0)),
          type: DATE,
          error: false,
          errorMessage: '',
        }),
        valor: new FieldModel({
          name: 'valor', value: '', error: false, errorMessage: '',

        }),
      },
    },
  };
}

function setNovoBoletoHandler(states, actions) {
  const { name, value } = actions;
  const fieldModel = states.boletosRelacaoPrestacao.formFields[name].copy();
  return {
    ...states,
    boletosRelacaoPrestacao: {
      ...states.boletosRelacaoPrestacao,
      formFields: {
        ...states.boletosRelacaoPrestacao.formFields,
        [name]: fieldModel.onChange(value),
      },
    },
  };
}

function onFocusBoletoHandler(states, actions) {
  const model = states.boletosRelacaoPrestacao.formFields[actions.name].copy();
  return {
    ...states,
    boletosRelacaoPrestacao: {
      ...states.boletosRelacaoPrestacao,
      formFields: {
        ...states.boletosRelacaoPrestacao.formFields,
        [actions.name]: model.onFocus(),
      },
    },
  };
}

function setBoletoFormFieldsErrorsHandler(states, actions) {
  const { value } = actions;
  return {
    ...states,
    boletosRelacaoPrestacao: {
      ...states.boletosRelacaoPrestacao,
      formFields: {
        ...states.boletosRelacaoPrestacao.formFields,
        value,
      },
    },
  };
}

export const setSimpleValuePdfAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_PDF_ACTION,
  name,
  value,
});

export const setCommonAction = (name, value) => ({
  type: SET_COMMON_ACTION,
  name,
  value,
});

export const onChangeFormFieldsPesquisaAction = (name, value) => ({
  type: SET_FORM_PESQUISA_CLIENTE_ACTION,
  name,
  value,
});

export const resetFormFieldsPesquisaAction = () => ({
  type: RESET_FORM_FIELDS_PESQUISA_ACTION,
});

export const resetFormFieldsRelacaoPrestacaoAction = () => ({
  type: RESET_FORM_FIELDS_RELACAO_PRESTACAO_ACTION,
});

export const setSimpleValuesPesquisaAction = (name, value) => ({
  type: SET_SIMPLE_VALUES_PESQUISA_ACTION,
  name,
  value,
});

export const setSimpleValuesRepasseAction = (name, value) => ({
  type: SET_SIMPLE_VALUES_REPASSE_ACTION,
  name,
  value,
});

export const setDefaultClienteSelecionadoAction = () => ({
  type: SET_DEFAULT_CLIENTE_SELECIONADO_ACTION,
});

export const setDefaultNaoPrestadosAction = () => ({
  type: SET_DEFAULT_NAO_PRESTADOS,
});

export const setClienteSelecionadoAction = (idCliente, status) => ({
  type: SET_CLIENTE_SELECIONADO_ACTION,
  idCliente,
  status,
});

export const setSimpleValuesDetalhesAction = (name, value) => ({
  type: SET_SIMPLE_VALUES_DETALHES_ACTION,
  name,
  value,
});

export const setDetalhesDoClieteSelecionadoAction = (resumoCliente, detalhesCliente) => ({
  type: SET_DETALHES_CLIENTE_SELECIONADO_ACTION,
  resumoCliente,
  detalhesCliente,
});

export const setSimpleValueNaoPrestadosAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_NAO_PRESTADOS_ACTION,
  name,
  value,
});

export const resetStatesEmptyValueAction = () => ({
  type: RESET_STATES_EMPTY_VALUE,
});

export const setSimpleValueRelacaoPrestacaoAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_RELACAO_PRESTACAO_ACTION,
  name,
  value,
});

export const onChangeFormFieldsRelacaoPrestacaoAction = (name, value) => ({
  type: SET_FORM_FIELDS_RELACAO_PRESTACAO_ACTION,
  name,
  value,
});

export const onChangeFormFieldsAutorizacaoRepasse = (name, value) => ({
  type: SET_FORM_FIELDS_AUTORIZACAO_REPASSE_ACTION,
  name,
  value,
});

export const onChangeFormFieldsRelacaoPrestacaoErrorAction = (name, value) => ({
  type: SET_RELACAO_PRESTACAO_ERROR_ACTION,
  name,
  value,
});

export const disableFieldsRelacaoPrestacaoAction = disabled => ({
  type: DISABLE_FIELDS_RELACAO_PRESTACAO_ACTION,
  disabled,
});

export const onFocusPeriodFieldRelacaoPrestacaoAction = () => ({
  type: ON_FOCUS_PERIOD_FIELD_RELACAO_PRESTACAO_ACTION,
});

export const onFocusPeriodFieldRecebimentosAction = name => ({
  type: ON_FOCUS_PERIOD_FIELD_RECEBIMENTOS_ACTION,
  name,
});


export const setNaoPrestadosFormToSaveAction = data => ({
  type: SET_NAO_PRESTADOS_FORM_TO_SAVE,
  data,
});

export const setRpsEmitidosFormAction = data => ({
  type: SET_RPS_EMITIDOS_FORM_ACTION,
  data,
});

export const setSimpleValueNaoPrestaosFormToSaveHandlerAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_NAO_PRESTADOS_FORM_TO_SAVE_ACTION,
  name,
  value,
});

export const setSimpleValueRpsEmitidosFormFieldsHandlerAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_RPS_EMITIDOS_FORM_ACTION,
  name,
  value,
});

export const setRequestFieldsFocusNaoPrestadosFormToSaveAction = name => ({
  type: ON_FOCUS_REQUEST_FIELDS_NAO_PRESTADOS_FORM_TO_SAVE,
  name,
});

export const setSimpleValuePagamentosAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_PAGAMENTOS_ACTION,
  name,
  value,
});

export const setSimpleValuePagamentosValorAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_PAGAMENTOS_VALOR_ACTION,
  name,
  value,
});

export const setRequestFieldsFocusPagamentosAction = name => ({
  type: ON_FOCUS_REQUEST_FIELDS_PAGAMENTOS_ACTION,
  name,
});

export const setSimpleValueAutorizacaoRepasseHandlerAction = (name, value) => ({
  type: SET_SIMPLE_AUTORIZACAO_REPASSE_ACTION,
  name,
  value,
});

export const onChangeFormFieldsRepasseAction = (name, value) => ({
  type: SET_FORM_CHANGE_REPASSE_ACTION,
  name,
  value,
});

export const onFocusFormFieldsRepasseAction = name => ({
  type: SET_FORM_FOCUS_REPASSE_ACTION,
  name,
});

export const setFormFieldsRepasseAction = (name, value) => ({
  type: SET_FORM_FIELDS_REPASSE_ACTION,
  name,
  value,
});

export const setSimpleValueRepasseListIdsAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_REPASSE_LIST_IDS_ACTION,
  name,
  value,
});

export const onChangeFormFieldsRecebimentosAction = (name, value) => ({
  type: ON_CHANGE_RECEBIMENTOS_ACTION,
  name,
  value,
});

export const setSimpleValueRecebimentosAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_RECEBIMENTOS_ACTION,
  name,
  value,
});

export const setFormFieldsRecebimentosAction = (name, value) => ({
  type: SET_FORM_FIELDS_RECEBIMENTOS_ACTION,
  name,
  value,
});

export const setSimpleValueRecebimentosListIdsAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_RECEBIMENTOS_LIST_IDS_ACTION,
  name,
  value,
});

export const setRecebimentosFormToSaveAction = data => ({
  type: SET_RECEBIMENTOS_FORM_TO_SAVE,
  data,
});

export const setRecebimentosFormFieldsErrorsAction = value => ({
  type: SET_FORM_FIELDS_RECEBIMENTOS_ERRORS_ACTION,
  value,
});

export const setSimpleValueRpsEmitidosAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_RPS_EMITIDOS,
  name,
  value,
});

export const setSimpleValuesListIdsRpsEmitidosAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_RPS_EMITIDOS_LIST_IDS_ACTION,
  name,
  value,
});

export const setSimpleValueLancamentoHandlerAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_LANCAMENTO_ACTION,
  name,
  value,
});

export const setDadosGeracaoNFSwitchAction = (name, value) => ({
  type: SET_DADOS_GERACAO_NF_SWITCH,
  name,
  value,
});

export const setSimpleValueDadosGeracaoNFAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_DADOS_GERACAO_NF_ACTION,
  name,
  value,
});

export const setSimpleValueBoletosRelacaoPrestacaoAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_BOLETOS_RELACAO_PRESTACAO_ACTION,
  name,
  value,
});

export const setSimpleValueDadosGeracaoNFArquivoAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_DADOS_GERACAO_NF_ARQUIVO_ACTION,
  name,
  value,
});

export const setSimpleValueInformacoesAdicionaisRelacaoPrestacaoAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_INFORMACOES_ADICIONAIS_RELACAO_PRESTACAO_ACTION,
  name,
  value,
});

export const resetFormFieldsNFAction = () => ({
  type: RESET_FORM_FIELDS_NF_ACTION,
});

export const setSimpleValueNotasFiscaisHandlerAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_NOTAS_FISCAIS_ACTION,
  name,
  value,
});

export const setNovaNotaAction = (name, value) => ({
  type: SET_NOVA_NOTA,
  name,
  value,
});

export const resetFormFieldsNovaNotaAction = () => ({
  type: RESET_FORM_FIELDS_NOVA_NOTA_ACTION,
});

export const onFocusAction = name => ({
  type: ON_FOCUS,
  name,
});

export const setNovaNotaFormFieldsErrorsAction = value => ({
  type: SET_FORM_FIELDS_NOVA_NOTA_ERRORS_ACTION,
  value,
});

export const resetFormFieldsNovoBoletoAction = dataVencimentoBoleto => ({
  type: RESET_FORM_FIELDS_NOVO_BOLETO_ACTION,
  dataVencimentoBoleto,
});

export const setNovoBoletoAction = (name, value) => ({
  type: SET_NOVO_BOLETO,
  name,
  value,
});

export const onFocusBoletoAction = name => ({
  type: ON_FOCUS_BOLETO,
  name,
});

export const setBoletoFormFieldsErrorsAction = value => ({
  type: SET_FORM_FIELDS_BOLETO_ERRORS_ACTION,
  value,
});

export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    case SET_COMMON_ACTION:
      return setCommonHandler(states, actions);
    case SET_FORM_PESQUISA_CLIENTE_ACTION:
      return onChangeFormFieldsPesquisaHandler(states, actions);
    case RESET_FORM_FIELDS_PESQUISA_ACTION:
      return resetFormFieldsPesquisaHandler(states);
    case SET_SIMPLE_VALUES_PESQUISA_ACTION:
      return setSimpleValuesPesquisaHandler(states, actions);
    case SET_SIMPLE_VALUES_REPASSE_ACTION:
      return setSimpleValuesRepasseHandler(states, actions);
    case SET_DEFAULT_CLIENTE_SELECIONADO_ACTION:
      return setDefaultClienteSelecionadoHandler(states);
    case SET_CLIENTE_SELECIONADO_ACTION:
      return setClienteSelecionadoHandler(states, actions);
    case SET_SIMPLE_VALUES_DETALHES_ACTION:
      return setSimpleValuesDetalhesHandler(states, actions);
    case SET_DETALHES_CLIENTE_SELECIONADO_ACTION:
      return setDetalhesDoClieteSelecionadoHandler(states, actions);
    case SET_SIMPLE_VALUE_NAO_PRESTADOS_ACTION:
      return setSimpleValueNaoPrestadosHandler(states, actions);
    case SET_SIMPLE_VALUE_RELACAO_PRESTACAO_ACTION:
      return setSimpleValueRelacaoPrestacaoHandler(states, actions);
    case SET_FORM_FIELDS_RELACAO_PRESTACAO_ACTION:
      return onChangeFormFieldsRelacaoPrestacaoHandler(states, actions);
    case DISABLE_FIELDS_RELACAO_PRESTACAO_ACTION:
      return disableFieldsRelacaoPrestacaoHandler(states, actions);
    case ON_FOCUS_PERIOD_FIELD_RELACAO_PRESTACAO_ACTION:
      return onFocusPeriodFieldRelacaoPrestacaoHandler(states, actions);
    case SET_DEFAULT_NAO_PRESTADOS:
      return setDefaultNaoPrestadosHandler(states);
    case RESET_STATES_EMPTY_VALUE:
      return resetStatesEmptyValueHandler(states);
    case SET_NAO_PRESTADOS_FORM_TO_SAVE:
      return setNaoPrestadosFormToSave(states, actions);
    case SET_SIMPLE_VALUE_NAO_PRESTADOS_FORM_TO_SAVE_ACTION:
      return setSimpleValueNaoPrestaosFormToSaveHandler(states, actions);
    case ON_FOCUS_REQUEST_FIELDS_NAO_PRESTADOS_FORM_TO_SAVE:
      return changeRequestFieldsFocusNaoPrestadosFormToSaveHandler(states, actions);
    case SET_RELACAO_PRESTACAO_ERROR_ACTION:
      return onChangeFormFieldsRelacaoPrestacaoHandlerTeste(states, actions);
    case RESET_FORM_FIELDS_RELACAO_PRESTACAO_ACTION:
      return resetFormFieldsRelacaoPrestacaoHandler(states, actions);
    case SET_SIMPLE_VALUE_PAGAMENTOS_ACTION:
      return setSimpleValuePagamentosHandler(states, actions, FaLessThanEqual);
    case SET_SIMPLE_VALUE_PAGAMENTOS_VALOR_ACTION:
      return setSimpleValuePagamentosHandler(states, actions, true);
    case ON_FOCUS_REQUEST_FIELDS_PAGAMENTOS_ACTION:
      return changeRequestFieldsFocusPagamentosValorHandler(states, actions);
    case SET_SIMPLE_AUTORIZACAO_REPASSE_ACTION:
      return setSimpleValuesAutorizacaoRepassesHandler(states, actions);
    case SET_FORM_FIELDS_AUTORIZACAO_REPASSE_ACTION:
      return onChangeFormFieldsAutorizacaoRepasseHandler(states, actions);
    case SET_FORM_CHANGE_REPASSE_ACTION:
      return onChangeFormFieldsRepasseHandler(states, actions);
    case SET_FORM_FOCUS_REPASSE_ACTION:
      return onFocusFormFieldsRepasseHandler(states);
    case SET_FORM_FIELDS_REPASSE_ACTION:
      return setFormFieldsRepasseHandler(states, actions);
    case SET_SIMPLE_VALUE_REPASSE_LIST_IDS_ACTION:
      return setSimpleValuesRepasseListIdsHandler(states, actions);
    case ON_CHANGE_RECEBIMENTOS_ACTION:
      return onChangeFormFieldsRecebimentosHandler(states, actions);
    case ON_FOCUS_PERIOD_FIELD_RECEBIMENTOS_ACTION:
      return onFocusPeriodFieldRecebimentosHandler(states, actions);
    case SET_SIMPLE_VALUE_RECEBIMENTOS_ACTION:
      return setSimpleValueRecebimentosHandler(states, actions);
    case SET_FORM_FIELDS_RECEBIMENTOS_ACTION:
      return setFormFieldsRecebimentosHandler(states, actions);
    case SET_SIMPLE_VALUE_RECEBIMENTOS_LIST_IDS_ACTION:
      return setSimpleValuesListIdsRecebimentosHandler(states, actions);
    case SET_FORM_FIELDS_RECEBIMENTOS_ERRORS_ACTION:
      return setRecebimentosFormFieldsErrorsHandler(states, actions);
    case SET_SIMPLE_VALUE_RPS_EMITIDOS:
      return setSimpleValueRpsEmitidoHandler(states, actions);
    case SET_SIMPLE_VALUE_RPS_EMITIDOS_LIST_IDS_ACTION:
      return setSimpleValuesListIdsRpsEmitidosHandler(states, actions);
    case SET_SIMPLE_VALUE_RPS_EMITIDOS_FORM_ACTION:
      return setFormFieldsRpsEmitidosHandler(states, actions);
    case SET_RPS_EMITIDOS_FORM_ACTION:
      return setRpsEmitidosFormHandler(states, actions);
    case SET_SIMPLE_VALUE_LANCAMENTO_ACTION:
      return setSimpleValueLancamentoHandler(states, actions);
    case SET_DADOS_GERACAO_NF_SWITCH:
      return setDadosGeracaoNFSwitchHandler(states, actions);
    case SET_SIMPLE_VALUE_DADOS_GERACAO_NF_ACTION:
      return setSimpleValueDadosGeracaoNFHandler(states, actions);
    case SET_SIMPLE_VALUE_DADOS_GERACAO_NF_ARQUIVO_ACTION:
      return setSimpleValueDadosGeracaoNFArquivoHandler(states, actions);
    case RESET_FORM_FIELDS_NF_ACTION:
      return setResetFormFieldsNF(states, actions);
    case SET_SIMPLE_VALUE_NOTAS_FISCAIS_ACTION:
      return setSimpleValueNotasFiscaisHandler(states, actions);
    case SET_NOVA_NOTA:
      return setNovaNotaHandler(states, actions);
    case RESET_FORM_FIELDS_NOVA_NOTA_ACTION:
      return setFormFieldsNovaNotaHandler(states, actions);
    case ON_FOCUS:
      return onFocusHandler(states, actions);
    case SET_SIMPLE_VALUE_BOLETOS_RELACAO_PRESTACAO_ACTION:
      return setSimpleValueBoletosRelacaoPrestacaoHandler(states, actions);
    case SET_SIMPLE_VALUE_INFORMACOES_ADICIONAIS_RELACAO_PRESTACAO_ACTION:
      return setSimpleValueInformacoesAdicionaisRelacaoPrestacaoHandler(states, actions);
    case SET_FORM_FIELDS_NOVA_NOTA_ERRORS_ACTION:
      return setNovaNotaFormFieldsErrorsHandler(states, actions);
    case RESET_FORM_FIELDS_NOVO_BOLETO_ACTION:
      return resetFormFieldsNovoBoletoHandler(states, actions);
    case SET_NOVO_BOLETO:
      return setNovoBoletoHandler(states, actions);
    case ON_FOCUS_BOLETO:
      return onFocusBoletoHandler(states, actions);
    case SET_FORM_FIELDS_BOLETO_ERRORS_ACTION:
      return setBoletoFormFieldsErrorsHandler(states, actions);
    case SET_SIMPLE_VALUE_PDF_ACTION:
      return setSimpleValuePdfHandler(states, actions);
    default:
      return states;
  }
};
