import { Step, StepContent, Stepper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { SimpleTitle } from '../../../../components/Title/SimpleTitle/SimpleTitle';
import useStyle, {
  Container, StepLabel,
} from '../AcordosStyle';
import { PassoUm } from './PassoUm/NovoAcordoPassoUm';
import { PassoDois } from './PassoDois/NovoAcordoPassoDois';
import { PassoTres } from './Passo3/NovoAcordoPassoTres';
import { getParametrosInterno } from '../../../../services/core/parametro/parametro.services';
import { getExceptionErrorMessage, isBadRequestException } from '../../../../helpers';

function NovoAcordoComponent({
  setLoadings,
  idProcesso,
  setOpenButtonNovoAcordo,
  pesquisarAcordos,
  settingsTable,
  columnOrdenacao,
  updatePage,
  getDadosResumo,
  setInfoAdicionaisAcordo,
  exibirApenasAcordosAtivosEhPropostas,
  setCollapse,
}) {
  const style = useStyle();
  const [activeStep, setActiveStep] = React.useState(0);
  const [dadosDefault, setDadosDefault] = useState({});
  const [dadosResumo, setDadosResumo] = useState({});
  const [selectedIds, setSelectedIds] = useState({});
  const [parcelas, setParcelas] = useState([]);
  const [dataFinalPasso3, setDataFinalPasso3] = useState('');
  const [dadosPadraoForm, setDadosPadraoForm] = useState([]);
  const [dadosFormFields, setDadosFormFields] = useState({});
  const [parametrosInternosAbe, setParametrosInternosAbe] = useState({});

  const listaDeIds = Object.keys(selectedIds).filter((key => selectedIds[key] === true)).map(key => Number(key));

  function handleNextStep() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBackStep() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  async function buscarParametrosInternosAbe() {
    try {
      const { data } = await getParametrosInterno();
      setParametrosInternosAbe(data);
    } catch (error) {
      if (isBadRequestException(error)) {
        toast.error(error?.response?.data?.message, { style: { width: '392px' } });
      } else {
        toast.error(getExceptionErrorMessage(error), { style: { width: '392px' } });
      }
    }
  }

  useEffect(() => {
    buscarParametrosInternosAbe();
  }, []);

  return (
    <>
      <Container>
        <div className={style.root_novoAcordo}>
          <div style={{ marginLeft: '23px' }}>
            <SimpleTitle
              text="Novo Acordo ou Simulação"
            />
          </div>
          <div>
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step>
                <StepLabel>PASSO 1</StepLabel>
                <StepContent>
                  <div>
                    {activeStep === 0 && (
                      <PassoUm
                        setLoadings={setLoadings}
                        idProcesso={idProcesso}
                        setOpenButtonNovoAcordo={setOpenButtonNovoAcordo}
                        handleNextStep={handleNextStep}
                        setDadosDefault={setDadosDefault}
                        setDadosResumo={setDadosResumo}
                        setSelectedIds={setSelectedIds}
                        selectedIds={selectedIds}
                        listaDeIds={listaDeIds}
                      />
                    )}
                  </div>
                </StepContent>
              </Step>

              <Step>
                <StepLabel>PASSO 2</StepLabel>
                <StepContent>
                  <div>
                    {activeStep === 1 && (
                      <PassoDois
                        setLoadings={setLoadings}
                        idProcesso={idProcesso}
                        setOpenButtonNovoAcordo={setOpenButtonNovoAcordo}
                        handleBackStep={handleBackStep}
                        dadosDefault={dadosDefault}
                        dadosResumo={dadosResumo}
                        listaDeIds={listaDeIds}
                        setDadosResumo={setDadosResumo}
                        handleNextStep={handleNextStep}
                        setParcelas={setParcelas}
                        setDataFinalPasso3={setDataFinalPasso3}
                        formFields={dadosFormFields}
                        setDadosFormFields={setDadosFormFields}
                        setDadosPadraoForm={setDadosPadraoForm}
                      />
                    )}
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>PASSO 3</StepLabel>
                <div>
                  {activeStep === 2 && (
                    <PassoTres
                      idProcesso={idProcesso}
                      setOpenButtonNovoAcordo={setOpenButtonNovoAcordo}
                      setLoadings={setLoadings}
                      handleBackStep={handleBackStep}
                      dadosResumo={dadosResumo}
                      parcelas={parcelas}
                      setParcelas={setParcelas}
                      dataFinalPasso3={dataFinalPasso3}
                      dadosPadraoForm={dadosPadraoForm}
                      pesquisarAcordos={pesquisarAcordos}
                      exibirApenasAcordosAtivosEhPropostas={exibirApenasAcordosAtivosEhPropostas}
                      settingsTable={settingsTable}
                      columnOrdenacao={columnOrdenacao}
                      updatePage={updatePage}
                      getDadosResumo={getDadosResumo}
                      setInfoAdicionaisAcordo={setInfoAdicionaisAcordo}
                      dadosFormFields={dadosFormFields}
                      setDadosFormFields={setDadosFormFields}
                      dadosDefault={dadosDefault}
                      setDadosDefault={setDadosDefault}
                      parametrosInternosAbe={parametrosInternosAbe}
                      setCollapse={setCollapse}
                    />
                  )}
                </div>
              </Step>
            </Stepper>
          </div>
        </div>
      </Container>
    </>
  );
}

export const NovoAcordo = NovoAcordoComponent;
