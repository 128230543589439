import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SeletorPermissoes from './Seletor/SeletorPermissoes';

import useUtilStyles from '../../../../../helpers/styles/utils.styles';
import CompararPermissoesUsuario from '../Comparar/CompararPermissoesUsuario';
import Grid24 from '../../../../UI/Grid24/Grid24';
import { findPerfisAction, findUsuariosAction } from '../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import { useCrudUsuarioDispatch2, useCrudUsuarioSelector2 } from '../../../../../store/Usuario/V2/CrudUsuarioProvider';
import {
  aplicarPermissoesAction,
  atualizarListaPermissoesAction,
  compararPermissoesAction,
} from '../../../../../store/Usuario/V2/crudUsuario.saga';
import {
  onChangeActions,
  onChangeControls,
  onClearCombosPermissoes,
} from '../../../../../store/Usuario/V2/crudUsuario.store';
import {
  buildPermissaoComparada,
  getCheckedPermissaoComparada,
} from '../../../../../store/Usuario/V2/crudUsuario.factory';
import {
  APLICAR_TITLE,
  COMPARACAO_PERMISSOES_TITLE,
  COMPARAR_TITLE,
  PERMISSOES_BASEADAS_TITLE,
} from './SelecionarPermissoes.constants';
import OptionDialogNew from '../../../../UI/Dialogs/OptionDialog/OptionDialogNew';

function PermissoesUsuario({
  isInsertMode = false,
  isInspectMode = false,
  changeHandler,
}) {
  const utilStyles = useUtilStyles();
  const dispatch = useCrudUsuarioDispatch2();
  const globalDispatch = useDispatch();

  const [reloadUsuarios, setReloadUsuarios] = useState(false);
  const [disableAplicar, setDisableAplicar] = useState(false);
  const [disableComparar, setDisableComparar] = useState(false);

  const perfis = useSelector(states => states.selectors.perfis.selector);
  const usuarios = useSelector(states => states.selectors.usuarios.selector);
  const permissoesComparacao = useCrudUsuarioSelector2(states => states.controls.permissoesComparacao);
  const relacaoItensConfiguraveis = useCrudUsuarioSelector2(states => states.controls.permissoes);
  const loadingAplicar = useCrudUsuarioSelector2(states => states.actions.loadingAplicar);
  const loadingComparar = useCrudUsuarioSelector2(states => states.actions.loadingComparar);
  const openErrorComparar = useCrudUsuarioSelector2(states => states.actions.openErrorComparar);
  const messageErrorComparar = useCrudUsuarioSelector2(states => states.actions.messageErrorComparar);
  const openComparador = useCrudUsuarioSelector2(states => states.actions.openComparador);
  const columnsComparacao = useCrudUsuarioSelector2(states => states.actions.columnsComparacao);
  const radiosBaseadoEm = useCrudUsuarioSelector2(states => states.controls.radiosBaseadoEm);
  const perfilBaseadoSelector = useCrudUsuarioSelector2(states => states.controls.perfilBaseadoSelector.copy());
  const usuarioPermissaoAplicar = useCrudUsuarioSelector2(states => states.controls.usuarioPermissaoAplicarSelector.copy());
  const radiosCompararCom = useCrudUsuarioSelector2(states => states.controls.radiosCompararCom);
  const perfilCompararSelector = useCrudUsuarioSelector2(states => states.controls.perfilCompararSelector.copy());
  const usuarioPermissaoComparar = useCrudUsuarioSelector2(states => states.controls.usuarioPermissaoCompararSelector.copy());

  // Carrega os combobox dessa tela
  useEffect(() => {
    globalDispatch(findPerfisAction());
    globalDispatch(findUsuariosAction());
  }, [dispatch, globalDispatch, reloadUsuarios]);

  const closeDialogComparacaoHandler = useCallback(() => {
    dispatch(onChangeActions('openComparador', false));
  }, [dispatch]);

  const clickAplicarPermissesHandler = useCallback(() => {
    if (radiosBaseadoEm.value === 'PERFIL') {
      dispatch(aplicarPermissoesAction(radiosBaseadoEm.value, perfilBaseadoSelector.value));
    } else {
      dispatch(aplicarPermissoesAction(radiosBaseadoEm.value, usuarioPermissaoAplicar.value));
    }
  }, [
    dispatch,
    perfilBaseadoSelector,
    usuarioPermissaoAplicar,
    radiosBaseadoEm,
  ]);

  const clickCompararPermissoesHandler = useCallback(() => {
    dispatch(compararPermissoesAction(perfis, usuarios));
  }, [dispatch, perfis, usuarios]);


  const mapPermissoesComparadasToRelacaoItensConfiguraveis = useCallback((acoes) => {
    const newModel = acoes.model.copy();
    const permissaoComparada = getCheckedPermissaoComparada(acoes.id, permissoesComparacao);
    return buildPermissaoComparada(permissaoComparada, newModel, acoes);
  }, [permissoesComparacao]);

  const aplicarPermissoesHandler = useCallback(() => {
    const newRelacaoItensConfiguraveis = relacaoItensConfiguraveis.map(item => ({
      ...item,
      acoesUsuario: item.acoesUsuario.map(mapPermissoesComparadasToRelacaoItensConfiguraveis),
    }));
    dispatch(onChangeControls('permissoes', newRelacaoItensConfiguraveis));
    dispatch(atualizarListaPermissoesAction());
    closeDialogComparacaoHandler();
  }, [
    closeDialogComparacaoHandler,
    dispatch,
    mapPermissoesComparadasToRelacaoItensConfiguraveis,
    relacaoItensConfiguraveis,
  ]);

  const closeErrorCompararDialogHandler = useCallback(() => {
    dispatch(onChangeActions('openErrorComparar', false));
    dispatch(onChangeActions('messageErrorComparar', ''));
    setReloadUsuarios(reload => !reload);
    dispatch(onClearCombosPermissoes());
  }, [dispatch, setReloadUsuarios]);

  const disableSelecionarPermissoes = useCallback((aplicar, comparar) => {
    setDisableAplicar(aplicar);
    setDisableComparar(comparar);
  }, [setDisableAplicar, setDisableComparar]);

  const disableSeletorPermissoes = useCallback(() => {
    if (isInsertMode) {
      disableSelecionarPermissoes(false, true);
    } else if (isInspectMode) {
      disableSelecionarPermissoes(true, true);
    } else {
      disableSelecionarPermissoes(false, false);
    }
  }, [isInsertMode, isInspectMode, disableSelecionarPermissoes]);

  // Vai habilitar ou não o componente de selecionar permissoes
  useEffect(() => {
    disableSeletorPermissoes();
  }, [disableSeletorPermissoes]);

  return (
    <>
      <OptionDialogNew
        open={openErrorComparar}
        confirmLabel="OK, ENTENDI"
        onClickConfirm={closeErrorCompararDialogHandler}
      >
        <div style={{ fontSize: '18px' }}>
          <p>
            {messageErrorComparar}
          </p>
        </div>
      </OptionDialogNew>
      <CompararPermissoesUsuario
        open={openComparador}
        columns={columnsComparacao}
        onClose={closeDialogComparacaoHandler}
        onFinish={aplicarPermissoesHandler}
      />
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={12}>
          <SeletorPermissoes
            show
            disable={disableAplicar}
            title={PERMISSOES_BASEADAS_TITLE}
            perfis={perfis}
            usuarios={usuarios}
            radioModel={radiosBaseadoEm}
            perfilModel={perfilBaseadoSelector}
            usuarioModel={usuarioPermissaoAplicar}
            loading={loadingAplicar}
            buttonLabel={APLICAR_TITLE}
            onClickButton={clickAplicarPermissesHandler}
            onChange={changeHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={12}>
          <SeletorPermissoes
            show
            disable={disableComparar}
            title={COMPARACAO_PERMISSOES_TITLE}
            clazzes={utilStyles.mt16}
            perfis={perfis}
            usuarios={usuarios}
            radioModel={radiosCompararCom}
            perfilModel={perfilCompararSelector}
            usuarioModel={usuarioPermissaoComparar}
            loading={loadingComparar}
            buttonLabel={COMPARAR_TITLE}
            onClickButton={clickCompararPermissoesHandler}
            onChange={changeHandler}
          />
        </Grid24>
      </Grid24>
    </>
  );
}

export default React.memo(PermissoesUsuario);
