import styled from 'styled-components';
import { RadioGroup } from '@material-ui/core';
import Grid24 from '../../../../../UI/Grid24';
import AddActionTitleWrapper from '../../../../../UI/Wrapper/AddActionTitle';

export const GroupDefinitions = styled(RadioGroup)`
  margin-left: 8px;

  span {
    margin-right: 4px;
  }

  label {
    width: fit-content;

    &:last-child {
      margin-top: 13px;
    }
  }
`;

export const GroupDefinitionsCliente = styled(RadioGroup)`
margin-left: 8px;
margin-top: 14px;

span {
  margin-right: 4px;
}

label {
  width: fit-content;

  &:last-child {
    margin-top: 13px;
  }
}
`;

export const Container = styled(Grid24)`
  flex-direction: column !important;

  > div {
    margin-top: 16px;

    &:first-child {
      margin-top: 0px;
    }
  }
`;

export const AddContainer = styled(AddActionTitleWrapper)`
`;
