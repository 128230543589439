import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaEraser } from 'react-icons/fa';
import FormWrapperWithTitle from '../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import Grid24 from '../../../../UI/Grid24/Grid24';


import {
  useCrudParametrosDispatch,
  useCrudParametrosSelector,
} from '../../../../../store/Parametros/Crud/CrudParametrosProvider';

import useUtilStyle from '../../../../../helpers/styles/utils.styles';
import {
  ARQUIVO_MENSAGEM_EXTRA_EMAIL_DEVEDOR_TITLE,
  ARQUIVO_MENSAGEM_QUITACAO_DEVEDOR_TITLE,
  PRAZO_EXPIRACAO_ACESSO_FIELD,
  PRAZO_EXPIRACAO_PIX_FIELD,
  REGRAS_ACORDO_PIX_TITLE,
  REGRAS_PAGAMENTO_CARTAO_TITLE,
  VALOR_MAX_PROCESSO_FIELD,
} from './TabCrudParametroConfigPagamento.constants';
import SelectorField from '../../../../UI/Field/Selector';
import { getRequiredLabel } from '../../../../../helpers/utils/utils';
import NumberInput from '../../../../UI/Field/Number/NumberInput';
import { findTipoPrazoExpiracaoPixAction } from '../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import {
  limparArquivoExtraEmailDevedorAction,
  limparArquivoQuitacaoAction, setFileArquivoExtraEmailDevedor, setFileConfigPagto, setRequestFieldsValueAction,
} from '../../../../../store/Parametros/Crud/crudParametros.store';
import MoneyField from '../../../../UI/Field/Money/MoneyField';
import { InputFileIcon } from '../../../../UI/InputFile/InputFileIcon';
import { CustomButton } from '../../../../../pages/InspecionarProcesso/InspecionarProcessoStyles';
import ButtonUI from '../../../../UI/Button/ButtonUI';


function TabCrudParametroConfigPagamento({ isInsertMode, onChange, onFocus }) {
  const utilStyle = useUtilStyle();
  const dispatch = useCrudParametrosDispatch();
  const globalDispatch = useDispatch();

  const tipoPrazoExpiracaoPix = useSelector(states => states.selectors.tipoPrazoExpiracaoPix.selector);
  const idTipoExpiracaoPix = useCrudParametrosSelector(state => state.requestFields.idTipoExpiracaoPix.copy());
  const duracaoExpiracaoPix = useCrudParametrosSelector(state => state.requestFields.duracaoExpiracaoPix.copy());
  const prazoExpiracaoCartaoAcordoSeguro = useCrudParametrosSelector(state => state.requestFields.prazoExpiracaoCartaoAcordoSeguro.copy());
  const valorMaximoPagtoCartao = useCrudParametrosSelector(state => state.requestFields.valorMaximoPagtoCartao.copy());
  const arquivoDevedorQuitacao = useCrudParametrosSelector(state => state.requestFields.arquivoDevedorQuitacao.copy());
  const arquivoExtraEmailDevedor = useCrudParametrosSelector(state => state.requestFields.arquivoExtraEmailDevedor.copy());

  const [prazoSideText, setPrazoSideText] = useState('');
  const [prazoMaxValue, setPrazoMaxValue] = useState(24);

  // Carrega o combo box.
  useEffect(() => {
    globalDispatch(findTipoPrazoExpiracaoPixAction());
  }, [globalDispatch]);

  // Altera o sideText e o valor máximo do campo.
  useEffect(() => {
    if (idTipoExpiracaoPix.value === 1) {
      setPrazoSideText('(dias)');
      setPrazoMaxValue(99);
    } else {
      setPrazoSideText('(horas)');
      setPrazoMaxValue(24);
    }
  }, [idTipoExpiracaoPix, setPrazoSideText, setPrazoMaxValue]);

  const onChangeTipoPrazoExpiracao = useCallback((event) => {
    dispatch(setRequestFieldsValueAction('duracaoExpiracaoPix', 0));
    onChange(event);
  }, [onChange, dispatch]);

  const handleSetFile = useCallback((file) => {
    dispatch(setFileConfigPagto(file));
  }, [dispatch]);

  const handleSetFileArquivoExtraEmailDevedor = useCallback((file) => {
    dispatch(setFileArquivoExtraEmailDevedor(file));
  }, [dispatch]);

  const handleClickLimparArquivoQuitacao = useCallback(() => {
    dispatch(limparArquivoQuitacaoAction());
  }, [dispatch]);

  const handleClickLimparArquivoExtraEmailDevedor = useCallback(() => {
    dispatch(limparArquivoExtraEmailDevedorAction());
  }, [dispatch]);

  return (
    <>
      <Grid24 container isFormContainer>
        <Grid24 xs={24} sm={24}>
          <FormWrapperWithTitle title={REGRAS_PAGAMENTO_CARTAO_TITLE}>
            <Grid24 xs={24} sm={24} spacing="1">
              <Grid24 xs={24} sm={5} className={utilStyle.mt2}>
                <MoneyField
                  isForm
                  fullWidth
                  disabled={!isInsertMode}
                  label={getRequiredLabel(VALOR_MAX_PROCESSO_FIELD, isInsertMode)}
                  name={valorMaximoPagtoCartao.name}
                  value={valorMaximoPagtoCartao.value}
                  onChange={onChange}
                  onFocus={onFocus}
                  error={valorMaximoPagtoCartao.error}
                  errorMessage={valorMaximoPagtoCartao.errorMessage}
                />
              </Grid24>
              <Grid24 xs={24} sm={10}>
                <Grid24 xs={24} sm={24}>
                  <p style={{ fontSize: '12px' }}>{getRequiredLabel(PRAZO_EXPIRACAO_ACESSO_FIELD, isInsertMode)}</p>
                </Grid24>
                <Grid24 xs={24} sm={8}>
                  <NumberInput
                    isForm
                    fullWidth
                    disabled={!isInsertMode}
                    sideText="(dias)"
                    numberType="integer"
                    maxValue={99}
                    name={prazoExpiracaoCartaoAcordoSeguro.name}
                    value={prazoExpiracaoCartaoAcordoSeguro.value}
                    onChange={onChange}
                    onFocus={onFocus}
                    error={prazoExpiracaoCartaoAcordoSeguro.error}
                    errorMessage={prazoExpiracaoCartaoAcordoSeguro.errorMessage}
                  />
                </Grid24>
              </Grid24>
            </Grid24>
          </FormWrapperWithTitle>
        </Grid24>
        <Grid24 container isFormContainer>
          <FormWrapperWithTitle title={REGRAS_ACORDO_PIX_TITLE}>
            <Grid24 xs={24} sm={24} spacing="1">
              <Grid24 xs={24} sm={5}>
                <SelectorField
                  isForm
                  fullWidth
                  disabled={!isInsertMode}
                  label={getRequiredLabel(PRAZO_EXPIRACAO_PIX_FIELD, isInsertMode)}
                  items={tipoPrazoExpiracaoPix}
                  name={idTipoExpiracaoPix.name}
                  value={idTipoExpiracaoPix.value}
                  onChange={onChangeTipoPrazoExpiracao}
                  onFocus={onFocus}
                  error={idTipoExpiracaoPix.error}
                  errorMessage={idTipoExpiracaoPix.errorMessage}
                />
              </Grid24>
              <Grid24 xs={24} sm={4} className={utilStyle.mt15}>
                <NumberInput
                  isForm
                  fullWidth
                  disabled={!isInsertMode}
                  sideText={prazoSideText}
                  numberType="integer"
                  maxValue={prazoMaxValue}
                  name={duracaoExpiracaoPix.name}
                  value={duracaoExpiracaoPix.value}
                  onChange={onChange}
                  onFocus={onFocus}
                  error={duracaoExpiracaoPix.error}
                  errorMessage={duracaoExpiracaoPix.errorMessage}
                />
              </Grid24>
            </Grid24>
          </FormWrapperWithTitle>
        </Grid24>
        <Grid24 container isFormContainer>
          <FormWrapperWithTitle title={ARQUIVO_MENSAGEM_QUITACAO_DEVEDOR_TITLE}>
            <Grid24 xs={24} sm={24} spacing="1" className={utilStyle.mt2} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
              <Grid24 xs={24} sm={16} spacing="1" className={utilStyle.mt2} style={{ marginLeft: '2px' }}>
                <InputFileIcon
                  isForm
                  acceptAll
                  accept="image/png,image/jpeg,application/pdf"
                  label="Arquivo"
                  name="arquivoDevedorQuitacao"
                  labelName="arquivoDevedorQuitacao"
                  value={arquivoDevedorQuitacao.value}
                  onChange={onChange}
                  setFile={handleSetFile}
                  buttonComponent={(
                    <ButtonUI
                      style={{
                        color: isInsertMode ? 'white' : 'inherit', padding: '18px 0px', width: '190px', marginLeft: '14px',
                      }}
                      disabled={!isInsertMode}
                    >
                      Selecionar Arquivo
                    </ButtonUI>
                  )}
                />
              </Grid24>
              <CustomButton style={{ width: '140px', marginBottom: '25px' }} disabled={!isInsertMode} onClick={handleClickLimparArquivoQuitacao}>
                <FaEraser size={18} />
                <span>Limpar</span>
              </CustomButton>
            </Grid24>
          </FormWrapperWithTitle>
        </Grid24>

        <Grid24 container isFormContainer>
          <FormWrapperWithTitle title={ARQUIVO_MENSAGEM_EXTRA_EMAIL_DEVEDOR_TITLE}>
            <Grid24 xs={24} sm={24} spacing="1" className={utilStyle.mt2} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
              <Grid24 xs={24} sm={16} spacing="1" className={utilStyle.mt2} style={{ marginLeft: '2px' }}>
                <InputFileIcon
                  isForm
                  acceptAll
                  accept="image/png,image/jpeg,application/pdf"
                  label="Arquivo"
                  name="arquivoExtraEmailDevedor"
                  labelName="arquivoExtraEmailDevedor"
                  value={arquivoExtraEmailDevedor.value}
                  onChange={onChange}
                  setFile={handleSetFileArquivoExtraEmailDevedor}
                  buttonComponent={(
                    <ButtonUI
                      style={{
                        color: isInsertMode ? 'white' : 'inherit', padding: '18px 0px', width: '190px', marginLeft: '14px',
                      }}
                      disabled={!isInsertMode}
                    >
                      Selecionar Arquivo
                    </ButtonUI>
                  )}
                />
              </Grid24>
              <CustomButton style={{ width: '150px', marginBottom: '25px' }} disabled={!isInsertMode} onClick={handleClickLimparArquivoExtraEmailDevedor}>
                <FaEraser size={18} />
                <span>Limpar Arquivo</span>
              </CustomButton>
            </Grid24>
          </FormWrapperWithTitle>
        </Grid24>

      </Grid24>
    </>
  );
}

export default TabCrudParametroConfigPagamento;
