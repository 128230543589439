import { format } from 'date-fns';
import { CORE, gateway, gatewayShortJson } from '../../api';


export function buscarInformacoesDashboardService() {
  return gateway.get(`${CORE}/dashboard`);
}

export function buscarEventosCalendarioService(date) {
  return gateway.get(`${CORE}/dashboard/eventos/${format(date, 'yyyy-MM-dd')}`);
}

export function buscarTiposCalendarioService() {
  return gatewayShortJson.get(`${CORE}/dashboard/tipo-calendario`);
}

export function salvarEventosCalendarioService(payload) {
  return gateway.post(`${CORE}/dashboard/eventos`, payload);
}

export function salvarInformacoesMuralService(payload) {
  return gateway.post(`${CORE}/dashboard/mural`, payload);
}

export function buscarInformacoesMuralService() {
  return gateway.get(`${CORE}/dashboard/mural`);
}
