import React, { useCallback, useEffect } from 'react';
import { TableDefault } from '../../../../../../components';
import { TableBodyAutorizacoesRepasses } from '../../../../../../components/TabelaBody/TabelaBodyAutorizacoesRepasses';
import { TableHeaderAutorizacoesRepasses } from '../../../../../../components/TabelaHeader/TableHeaderAutorizacoesRepasses';
import SwitchUI from '../../../../../../components/UI/Switch/SwitchUI';
import { getFieldName, getNewPage } from '../../../../../../helpers';
import { pesquisarListaAutorizacaoRepasseAction } from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import { onChangeFormFieldsAutorizacaoRepasse, setSimpleValueAutorizacaoRepasseHandlerAction } from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import useStyles from './AutorizacoesRepassesStyles';

export default function AutorizacoesRepasses({ permissoes }) {
  const styles = useStyles();
  const dispatch = useCCClienteDispatch();

  const autorizacoesRepasses = useCCClienteSelector(states => (states.autorizacaoRepasse.autorizacoesRepasses));
  const formFields = useCCClienteSelector(states => (states.autorizacaoRepasse.formFields));
  const page = useCCClienteSelector(states => (states.autorizacaoRepasse.page));
  const rowsPerPage = useCCClienteSelector(states => (states.autorizacaoRepasse.rowsPerPage));
  const totalElements = useCCClienteSelector(states => (states.autorizacaoRepasse.totalElements));

  const handleChangeChecked = React.useCallback((e) => {
    const name = getFieldName(e.target);
    const { checked } = e.target;
    dispatch(setSimpleValueAutorizacaoRepasseHandlerAction('page', 0));
    dispatch(onChangeFormFieldsAutorizacaoRepasse(name, checked));
  }, [dispatch]);

  const changePageHandler = useCallback((event, newPage) => {
    dispatch(setSimpleValueAutorizacaoRepasseHandlerAction('page', Number(newPage)));
  }, [dispatch]);

  const changeRowsPerPageHandler = useCallback((e) => {
    const size = e.target.value;
    const newPage = getNewPage(rowsPerPage, page, size);

    dispatch(setSimpleValueAutorizacaoRepasseHandlerAction('page', Number(newPage)));
    dispatch(setSimpleValueAutorizacaoRepasseHandlerAction('rowsPerPage', Number(size)));
  }, [dispatch, rowsPerPage, page]);

  useEffect(() => {
    dispatch(pesquisarListaAutorizacaoRepasseAction());
  }, [dispatch, formFields, rowsPerPage, page]);

  return (
    <div className={styles.body}>
      <header className={styles.header}>
        <div>
          <SwitchUI
            label="Listar os últimos 30 dias"
            name="listarUltimosTrintaDias"
            disabled={false}
            checked={formFields?.listarUltimosTrintaDias.value}
            onChange={handleChangeChecked}
          />
        </div>
        <div>
          <SwitchUI
            label="Listar somente os itens não Concluídos"
            name="listarSomenteNaoConcluidos"
            disabled={false}
            checked={formFields?.listarSomenteNaoConcluidos.value}
            onChange={handleChangeChecked}
          />
        </div>
      </header>
      <main>
        <div className={styles.div__table}>
          <TableDefault
            columnsHead={(
              <TableHeaderAutorizacoesRepasses />
            )}
            page={page}
            rowsPerPage={rowsPerPage}
            totalElements={totalElements}
            totalElementsOnPage={autorizacoesRepasses?.length}
            totalColumns={7}
            onPageChange={changePageHandler}
            onRowsPerPageChange={changeRowsPerPageHandler}
            emptyRowsHeight={36}
            rowsPerPageOptions={[10, 15, 20]}
          >
            {autorizacoesRepasses?.map((autorizacao, i) => (
              <TableBodyAutorizacoesRepasses
                content={autorizacao}
                permissoes={permissoes}
                key={i}
              />
            ))}
          </TableDefault>
        </div>
      </main>
    </div>
  );
}
