import makeStyles from '@material-ui/core/styles/makeStyles';
import { warmGrey } from '../../../helpers/constants/colors.constants';

export default makeStyles({
  div__root: {
    width: '100%',
    display: 'flex',
  },
  div__container: {
    display: 'block',
    width: '100%',
    marginLeft: '10px',
  },
  div__subTitle: {
    display: 'flex',
    '& div:nth-child(2)': {
      marginTop: '3px',
      marginLeft: '5px',
    },
  },
  subTitleAcesso: {
    '@media (min-width: 1279px)': {
      width: '164px !important',
    },
    '@media (min-width: 1919px)': {
      width: '146px !important',
    },
  },
  subTitleNotificacao: {
    '@media (min-width: 1279px)': {
      width: '122px !important',
    },
    '@media (min-width: 1919px)': {
      width: '110px !important',
    },
  },
  div__relatoriosAcesso: {
    width: '50%',
    border: `1px solid ${warmGrey}`,
    marginRight: '14px',
  },
  div__relatoriosNotificado: {
    width: '50%',
    border: `1px solid ${warmGrey}`,
  },
  div__inputFieldsRoot: {
    display: 'flex',
    width: '100%',
    marginBottom: '24px',
  },
  div__dataDistance: {
    marginRight: '8px',
  },
  div__checkboxRoot: {
    display: 'flex',
    marginLeft: '9px',
    marginBottom: '24px',
    '& label:nth-child(1)': {
      marginRight: '24px',
    },
  },
  div__buttonRoot: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '7px',
  },
  btn__gerarRelatorio: {
    width: '280px',
    backgroundColor: 'white',
    border: '1px solid #034ddc',
    color: '#034ddc',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
});
