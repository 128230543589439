import React, { useEffect, useMemo } from 'react';
import {
  TableCell, TableRow,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TooltipUI from '../../../components/UI/Tooltip/Tooltip';
import { TableAccordion } from './AcordosStyle';
import { useUtilStyles } from '../../../helpers';

function getAlignOption(row, column) {
  let alignOption = row.values[column.id] ? column.alignCell : 'center';
  if (!row.values[column.id] && column.showTip) {
    alignOption = 'center';
  }
  return alignOption;
}


function Row({ row, columns }) {
  const utilStyles = useUtilStyles();

  const getContent = (value, column) => {
    if (column.showTip) {
      return (
        <TooltipUI title={value} placement="bottom" interactive>
          <pre
            className={utilStyles.overFlowText}
            style={{ maxWidth: column.maxWidth }}
          >
            {column.format && typeof value === 'number' ? column.format(value) : value}
          </pre>
        </TooltipUI>
      );
    }
    if (column.hiddenFullLine) {
      return (
        <pre
          className={utilStyles.overFlowText}
          style={{ maxWidth: column.maxWidth - 16 }}
        >
          {column.format && typeof value === 'number' ? column.format(value) : value}
        </pre>
      );
    }
    return <pre>{column.format && typeof value === 'number' ? column.format(value) : value}</pre>;
  };

  return (
    <>
      <TableRow className={utilStyles.tableRowRoot}>
        {columns.map((column) => {
          const value = row.values[column.id];
          const alignOption = getAlignOption(row, column);
          return (
            <TableCell
              key={column.id}
              style={{
                maxWidth: column.maxWidth,
                minWidth: column.minWidth,
                width: column.width,
                borderTop: '1px solid #e0e0e0',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: alignOption,
                }}
              >

                {getContent(value, column)}
              </div>
            </TableCell>
          );
        })}

      </TableRow>
    </>
  );
}

function TableAcordoAccordion({
  columns = [],
  rows = [],
  rowsPerPage,
  setDadosLength,
}) {
  const columnLength = useMemo(() => columns.length + 1, [columns.length]);

  const buildEmptyRows = () => {
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

    const content = [];
    for (let i = 0; i < emptyRows; i += 1) {
      content.push(
        <TableRow key={i} style={{ height: 31 }}>
          <TableCell colSpan={columnLength}>
            <p />
          </TableCell>
        </TableRow>,
      );
    }
    return content;
  };

  useEffect(() => {
    setDadosLength(rows);
  }, [setDadosLength, rows]);

  return (
    <>
      <TableAccordion>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  id={column.id}
                  key={column.id}
                  style={{
                    maxWidth: column.maxWidth,
                    minWidth: column.minWidth,
                    width: column.width,
                    textAlign: column.align,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(parcela => (
              <Row
                key={parcela.id}
                row={parcela}
                columns={columns}
                columnLength={columnLength}
              />
            ))}
            {buildEmptyRows()}
          </TableBody>
        </Table>
      </TableAccordion>
    </>
  );
}

export default TableAcordoAccordion;
