import { IconButton } from '@material-ui/core';
import styled, { css } from 'styled-components';

const Arrow = styled.button`
  height: 35px;
  width: 35px;
  padding-bottom: 3px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #564872;
  font-size: 20px;
  font-weight: bold;
  border: none;
  background-color: white;
  cursor: pointer;

  @media (min-width: 1400px){
    height: 38px;
    width: 38px;
    padding-bottom: 4px;
    font-size: 21px;
  }

  @media (min-width: 1920px){
    height: 52px;
    width: 52px;
    padding-bottom: 4px;
    font-size: 30px;
  }

  :hover {
    background-color: #f3f3f3;
    border-radius: 50%;
  }

  :active {
    background-color: #f0f0f0;
    border-radius: 50%;
    color: #FEFEFE;
  }

  :disabled {
    visibility: hidden;
  }
`;

export const PreviousMonth = styled(Arrow)`
`;

export const NextMonth = styled(Arrow)`
`;

export const DateContainer = styled.div`
`;

export const HeaderCalendar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;

  @media (min-width: 1920px){
    padding: 6px;
  }
`;

export const LabelMonth = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: #000000;

  @media (min-width: 1400px){
    font-size: 15px;
  }

  @media (min-width: 1920px){
    font-size: 21px;
  }
`;

export const Week = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 4px;

  @media (min-width: 1920px){
    padding: 6px;
  }

`;

export const Weekday = styled.p`
  text-align: center;
  color: #707070;
  font-size: 11px;
  font-weight: bold;

  @media (min-width: 1400px){
    font-size: 12px;
  }

  @media (min-width: 1920px){
    font-size: 16px;
  }
`;

export const Day = styled.button`
  background-color: white;
  border-radius: 8px;

  ${({ disabled }) => (!disabled ? css`
    color: #707070;
    cursor: pointer;
  ` : css`
    color: #CECECE;
    cursor: default;
  `)};

 ${({ selected }) => (selected && css`
    background-color: #564872;
    color: white;
 `)}
`;

export const Days = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-size: 12px;
  padding: 4px;
  height: 220px;

  @media (min-width: 1400px){
    font-size: 13px;
    padding: 4px;
    height: 240px;
  }

  @media (min-width: 1920px){
    font-size: 18px;
    padding: 6px;
    height: 330px;
  }
`;

export const CalendarContainer = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 20%);
  width: 220px;
  height: 286px;

  @media (min-width: 1400px){
    width: 240px;
    height: 312px;
  }

  @media (min-width: 1920px){
    width: 330px;
    height: 430px;
  }
`;

export const CalendarIconButton = styled(IconButton)`
  padding: 0;
  margin-right: 4px;
`;
