import FieldModel from '../../../models/FieldModel';
import { buildSettingsTable } from '../../../helpers/factory/global.factory';


const ON_ONLY_CHANGE_ROWS_PER_PAGE_RAMO = 'ON_ONLY_CHANGE_ROWS_PER_PAGE_RAMO';
const ON_SUCESS_CADASTRO = 'ON_SUCESS_CADASTRO';
const ON_CHANGE_PAGE_RAMO = 'ON_CHANGE_PAGE_RAMO';
const ON_ASSIGN_PESQUISA_COPY = 'ON_ASSIGN_PESQUISA_COPY';
const ON_CHANGE_ROWS_PER_PAGE_RAMO = 'ON_CHANGE_ROWS_PER_PAGE_RAMO';
const ON_DIGITAR_PESQUISA_RAMO = 'ON_PESQUISAR_RAMO';
const ON_TABLE_CONTENT_RAMO = 'ON_TABLE_CONTENT_RAMO';
const ON_CHANGE_SIZE_RAMO = 'ON_CHANGE_SIZE_RAMO';
const ON_SET_PAGEABLE_RAMO = 'ON_SET_PAGEABLE_RAMO';
const RESET_PESQUISA_RAMO = 'RESET_PESQUISA_RAMO';
const ON_SET_ACTIONS_RAMO = 'ON_SET_ACTIONS_RAMO';
const ON_ID_RAMO_DELETE = 'ON_ID_RAMO_DELETE';
const ON_RAMO_DELETE_MESSAGE = 'ON_RAMO_DELETE_MESSAGE';
const ON_OPEN_DIALOG_RAMO = 'ON_OPEN_DIALOG_RAMO';
const ON_OPEN_DELETE_RAMO = 'ON_OPEN_DELETE_RAMO';
const ON_OPEN_CRUD_DIALOG_RAMO = 'ON_OPEN_CRUD_DIALOG_RAMO';
const ON_NOME_RAMO_EDIT = 'ON_NOME_RAMO_EDIT';
const ON_ID_RAMO_EDIT = 'ON_ID_RAMO_EDIT';
const ON_ACAO_CRUD_RAMO = 'ON_ACAO_CRUD_RAMO';
const ON_CONTENT_ERROR_RAMO = 'ON_CONTENT_ERROR_RAMO';
const ON_SHOULD_UPDATE_CONTENT_RAMO = 'ON_SHOULD_UPDATE_CONTENT_RAMO';
const SET_ONLY_LOAD_RAMO_ATIVIDADE = 'SET_ONLY_LOAD_RAMO_ATIVIDADE';

function buildInitialStates() {
  return {
    controls: {
      existsRamoAtividade: false,
      acaoCrud: '',
      ramoDeleteMsg: '',
      contentError: '',
      idRamoDelete: null,
      nomeRamoEdit: '',
      idRamoEdit: null,
      tableContent: [],
      tablePageable: buildSettingsTable(),
      pesquisa: {
        pesquisaSimples: new FieldModel({ name: 'nome' }),
        pesquisaCopy: new FieldModel({ name: 'nome' }),
      },
    },
    actions: {
      openDialog: false,
      openDelete: false,
      loading: false,
      openSnackBar: false,
      removendo: false,
      fail: false,
      sucessCadastro: false,
      openCrudDialog: false,
      shouldUpdateContent: false,
    },
  };
}


const resetPesquisaRamoAtividadeHandler = states => ({
  ...states,
  controls: {
    ...states.controls,
    ramoDeleteMsg: '',
    idRamoDelete: null,
    tableContent: [],
    tablePageable: {
      ...states.controls.tablePageable,
      page: 0,
      rowsPerPage: 10,
      size: 0,
    },
    pesquisa: {
      ...states.controls.pesquisa,
      pesquisaSimples: new FieldModel({ name: 'nome' }),
      pesquisaCopy: new FieldModel({ name: 'nome' }),
    },
  },
  actions: {
    ...states.actions,
    openDialog: false,
    openDelete: false,
    loading: false,
    openSnackBar: false,
    removendo: false,
    fail: false,
  },
});

function setOnlyLoadingHandler(states, action) {
  return {
    ...states,
    actions: {
      ...states.actions,
      loading: action.loading,
    },
  };
}

function shouldUpdateContentRamoHandler(states, action) {
  return {
    ...states,
    actions: {
      ...states.actions,
      shouldUpdateContent: action.should,
    },
  };
}

function onNomeRamoEditHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      nomeRamoEdit: action.nomeRamoEdit,
    },
  };
}

function onContentErrorHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      contentError: action.messageError,
    },
  };
}

function onAcaoCrudHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      acaoCrud: action.acaoCrud,
    },
  };
}

function onIdRamoEditHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      idRamoEdit: action.idRamoEdit,
    },
  };
}

function onSucessCadastroHandler(states, action) {
  return {
    ...states,
    actions: {
      ...states.actions,
      sucessCadastro: action.value,
    },
  };
}

function onAssignPesquisaCopyHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      pesquisa: {
        ...states.controls.pesquisa,
        pesquisaCopy: action.value,
      },
    },
  };
}


function onSetActionsHandler(states, action) {
  return {
    ...states,
    actions: {
      ...states.actions,
      loading: action.loading,
      failRequest: action.failRequest,
      openSnackBar: action.openSnackBar,
      removendo: action.removendo,
      fail: action.fail,
    },
  };
}


function onIdRamoDeleteHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      idRamoDelete: action.idRamoToDelete,
    },
  };
}

function onOpenDialogRamoHandler(states, action) {
  return {
    ...states,
    actions: {
      ...states.actions,
      openDialog: action.open,
    },
  };
}

function onOpenDeleteRamoHandler(states, action) {
  return {
    ...states,
    actions: {
      ...states.actions,
      openDelete: action.open,
    },
  };
}


function onChangeSizeHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      tablePageable: {
        ...states.controls.tablePageable,
        size: action.size,
      },
    },
  };
}


const onRamoMessageDeleteHandler = (states, action) => ({
  ...states,
  controls: {
    ...states.controls,
    ramoDeleteMsg: action.message,
  },
});


const onChangePageHandler = (states, action) => ({
  ...states,
  controls: {
    ...states.controls,
    tablePageable: {
      ...states.controls.tablePageable,
      page: action.page,
    },
  },
});


const onChangeRowsPerPageHandler = (states, action) => ({
  ...states,
  controls: {
    ...states.controls,
    tablePageable: {
      ...states.controls.tablePageable,
      rowsPerPage: action.rowsPerPage,
    },
  },
});

const onOnlyChangeRowsPerPageHandler = (states, action) => ({
  ...states,
  controls: {
    ...states.controls,
    tablePageable: {
      ...states.controls.tablePageable,
      rowsPerPage: action.rowsPerPage,
    },
  },
});


const onSetTablePageableHandler = (states, action) => ({
  ...states,
  controls: {
    ...states.controls,
    tablePageable: {
      ...states.controls.tablePageable,
      page: action.page,
      rowsPerPage: action.rowsPerPage,
      size: action.size,
    },
  },
});


const onDigitarPesquisaHandler = (states, action) => {
  const pesquisaSimplesModel = states.controls.pesquisa.pesquisaSimples.getNewModel(action.value);
  return {
    ...states,
    controls: {
      ...states.controls,
      pesquisa: {
        ...states.controls.pesquisa,
        pesquisaSimples: pesquisaSimplesModel,
      },
    },
  };
};


const onTableContentHandler = (states, action) => ({
  ...states,
  controls: {
    ...states.controls,
    tableContent: action.tableContent,
  },
});

function onOpenCrudDialogHandler(states, action) {
  return {
    ...states,
    actions: {
      ...states.actions,
      openCrudDialog: action.open,
    },
  };
}

export const setOnlyLoadingAction = loading => ({
  type: SET_ONLY_LOAD_RAMO_ATIVIDADE,
  loading,
});

export const shouldUpdateContentRamoAction = should => ({
  type: ON_SHOULD_UPDATE_CONTENT_RAMO,
  should,
});

export const onNomeRamoEditAction = nomeRamoEdit => ({
  type: ON_NOME_RAMO_EDIT,
  nomeRamoEdit,
});

export const onContentErrorAction = messageError => ({
  type: ON_CONTENT_ERROR_RAMO,
  messageError,
});

export const onAcaoCrudAction = acaoCrud => ({
  type: ON_ACAO_CRUD_RAMO,
  acaoCrud,
});

export const onIdRamoEditAction = idRamoEdit => ({
  type: ON_ID_RAMO_EDIT,
  idRamoEdit,
});

export const onOpenCrudDialogAction = open => ({
  type: ON_OPEN_CRUD_DIALOG_RAMO,
  open,
});

export const resetPesquisaRamoAtividadeRedux = () => ({
  type: RESET_PESQUISA_RAMO,
});
export const onSucessCadastroAction = value => ({
  type: ON_SUCESS_CADASTRO,
  value,
});
export const onAssignPesquisaCopyAction = value => ({
  type: ON_ASSIGN_PESQUISA_COPY,
  value,
});
export const onSetActions = (loading, openSnackBar, removendo, fail) => ({
  type: ON_SET_ACTIONS_RAMO,
  loading,
  openSnackBar,
  removendo,
  fail,
});
export const onIdRamoDeleteAction = idRamoToDelete => ({
  type: ON_ID_RAMO_DELETE,
  idRamoToDelete,
});
export const onOpenDialogRamoAction = open => ({
  type: ON_OPEN_DIALOG_RAMO,
  open,
});
export const onOpenDeleteRamoAction = open => ({
  type: ON_OPEN_DELETE_RAMO,
  open,
});
export const onChangeSizeAction = size => ({
  type: ON_CHANGE_SIZE_RAMO,
  size,
});
export const onRamoMessageDeleteAction = message => ({
  type: ON_RAMO_DELETE_MESSAGE,
  message,
});
export const onChangePageAction = page => ({
  type: ON_CHANGE_PAGE_RAMO,
  page,
});
export const onChangeRowsPerPageAction = rowsPerPage => ({
  type: ON_CHANGE_ROWS_PER_PAGE_RAMO,
  rowsPerPage,
});

export const onDigitarPesquisaAction = value => ({
  type: ON_DIGITAR_PESQUISA_RAMO,
  value,
});
export const onTableContentAction = tableContent => ({
  type: ON_TABLE_CONTENT_RAMO,
  tableContent,
});

export default (states = buildInitialStates(), action) => {
  switch (action.type) {
    case ON_TABLE_CONTENT_RAMO:
      return onTableContentHandler(states, action);
    case ON_DIGITAR_PESQUISA_RAMO:
      return onDigitarPesquisaHandler(states, action);
    case ON_SET_PAGEABLE_RAMO:
      return onSetTablePageableHandler(states, action);
    case ON_CHANGE_SIZE_RAMO:
      return onChangeSizeHandler(states, action);
    case ON_CHANGE_ROWS_PER_PAGE_RAMO:
      return onChangeRowsPerPageHandler(states, action);
    case ON_CHANGE_PAGE_RAMO:
      return onChangePageHandler(states, action);
    case ON_SET_ACTIONS_RAMO:
      return onSetActionsHandler(states, action);
    case ON_ID_RAMO_DELETE:
      return onIdRamoDeleteHandler(states, action);
    case ON_RAMO_DELETE_MESSAGE:
      return onRamoMessageDeleteHandler(states, action);
    case RESET_PESQUISA_RAMO:
      return resetPesquisaRamoAtividadeHandler(states, action);
    case ON_OPEN_DELETE_RAMO:
      return onOpenDeleteRamoHandler(states, action);
    case ON_OPEN_DIALOG_RAMO:
      return onOpenDialogRamoHandler(states, action);
    case ON_ASSIGN_PESQUISA_COPY:
      return onAssignPesquisaCopyHandler(states, action);
    case ON_ONLY_CHANGE_ROWS_PER_PAGE_RAMO:
      return onOnlyChangeRowsPerPageHandler(states, action);
    case ON_SUCESS_CADASTRO:
      return onSucessCadastroHandler(states, action);
    case ON_OPEN_CRUD_DIALOG_RAMO:
      return onOpenCrudDialogHandler(states, action);
    case ON_NOME_RAMO_EDIT:
      return onNomeRamoEditHandler(states, action);
    case ON_ID_RAMO_EDIT:
      return onIdRamoEditHandler(states, action);
    case ON_ACAO_CRUD_RAMO:
      return onAcaoCrudHandler(states, action);
    case ON_CONTENT_ERROR_RAMO:
      return onContentErrorHandler(states, action);
    case ON_SHOULD_UPDATE_CONTENT_RAMO:
      return shouldUpdateContentRamoHandler(states, action);
    case SET_ONLY_LOAD_RAMO_ATIVIDADE:
      return setOnlyLoadingHandler(states, action);
    default:
      return states;
  }
};
