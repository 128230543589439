import React, { useCallback } from 'react';
import ButtonUI from '../../../../../../../components/UI/Button/ButtonUI';
import {
  MainContainer, FooterContainer,
} from './AcaoQuitarStyles';
import { ActionHeaderContainer } from '../PagamentosStyle';
import { SimpleTitle } from '../../../../../../../components/Title/SimpleTitle/SimpleTitle';
import Grid24 from '../../../../../../../components/UI/Grid24/Grid24';
import MoneyField from '../../../../../../../components/UI/Field/Money/MoneyField';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { inserirLancamentoPagamentoAction } from '../../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';

export default function AcaoQuitarComponent() {
  const dispatch = useCCClienteDispatch();

  const valor = useCCClienteSelector(states => (states.pagamentos.formToSave.valor));

  const onSubmitHandler = useCallback(() => {
    dispatch(inserirLancamentoPagamentoAction(true));
  }, [dispatch]);

  return (
    <>
      <ActionHeaderContainer>
        <SimpleTitle
          text="Quitação"
        />
      </ActionHeaderContainer>
      <MainContainer>
        <Grid24 xs={24} sm={24}>
          <Grid24 xs={24} sm={12} style={{ paddingTop: '11px' }}>
            <MoneyField
              isForm
              disabled
              label="Valor da Quitação"
              name={valor}
              value={valor.value}
            />
          </Grid24>
        </Grid24>
      </MainContainer>
      <FooterContainer>
        <ButtonUI
          color="success"
          style={{ width: '136px' }}
          onClick={onSubmitHandler}
        >
          Quitar
        </ButtonUI>
      </FooterContainer>
    </>
  );
}

export const AcaoQuitar = AcaoQuitarComponent;
