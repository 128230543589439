export const AUTHENTICATE_STARTED = 'AUTHENTICATE_STARTED';
export const AUTHENTICATE_SUCCEEDED = 'AUTHENTICATE_SUCCEEDED';
export const AUTHENTICATE_FAILED = 'AUTHENTICATE_FAILED';
export const LOGOUT_SUCCEED = 'LOGOUT_SUCCEED';
export const ON_CHANGE_FIELD_ALTERAR_SENHA = 'ON_CHANGE_FIELD_ALTERAR_SENHA';
export const SET_ERROR_FIELD_ATUALIZAR_SENHA_ACTION = 'SET_ERROR_FIELD_ATUALIZAR_SENHA_ACTION';
export const ON_FOCUS_FIELD_ALTERAR_SENHA = 'ON_FOCUS_FIELD_ALTERAR_SENHA';
export const LIMPA_DIALOG_ALTERAR_SENHA = 'LIMPA_DIALOG_ALTERAR_SENHA';
export const SET_OPEN_DIALOG_ALTERAR_SENHA = 'SET_OPEN_DIALOG_ALTERAR_SENHA';

const INITIAL_STATES = {
  userInformation: null,
  loading: false,
  error: null,
  openDialogAlterarSenha: false,
  alterarSenha: {
    senhaAtual: {
      nome: 'senhaAtual',
      value: '',
      error: false,
      errorMessage: '',
    },
    novaSenha: {
      nome: 'novaSenha',
      value: '',
      error: false,
      errorMessage: '',
    },
    confirmarNovaSenha: {
      nome: 'confirmarNovaSenha',
      value: '',
      error: false,
      errorMessage: '',
    },
  },
};

export const authenticateStatedAction = () => ({
  type: AUTHENTICATE_STARTED,
});

export const authenticateSucceededAction = userParam => ({
  type: AUTHENTICATE_SUCCEEDED,
  user: userParam,
});

export const authenticateFailedAction = errorParam => ({
  type: AUTHENTICATE_FAILED,
  error: errorParam,
});

export const logoutSucceedAction = () => ({
  type: LOGOUT_SUCCEED,
});

export const onChangeFieldAlterarSenhaAction = (name, value) => ({
  type: ON_CHANGE_FIELD_ALTERAR_SENHA,
  name,
  value,
});

export const setErrorFieldAtualizarSenhaAction = (campo, errorMessage) => ({
  type: SET_ERROR_FIELD_ATUALIZAR_SENHA_ACTION,
  campo,
  errorMessage,
});

export const onFocusFieldAlterarSenhaAction = name => ({
  type: ON_FOCUS_FIELD_ALTERAR_SENHA,
  name,
});

export const limpaDialogAlterarSenhaAction = () => ({
  type: LIMPA_DIALOG_ALTERAR_SENHA,
});

export const setOpenDialogAlterarSenhaAction = value => ({
  type: SET_OPEN_DIALOG_ALTERAR_SENHA,
  value,
});

function authenticateStarterHandler(state) {
  return {
    ...state,
    error: null,
    loading: true,
  };
}

function authenticateSucceededHandler(state, action) {
  return {
    ...state,
    userInformation: action.user,
    loading: false,
    error: null,
  };
}

function authenticateFailedHandler(state, action) {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
}

function logoutSucceedHandler(state) {
  return {
    ...state,
    userInformation: null,
  };
}

function onChangeFieldAlterarSenhaHandler(state, action) {
  const { name, value } = action;
  return {
    ...state,
    alterarSenha: {
      ...state.alterarSenha,
      [name]: {
        ...[name],
        nome: name,
        value,
        error: false,
        errorMessage: '',
      },
    },
  };
}

function setErrorFieldAtualizarSenhaHandler(state, action) {
  const { campo, errorMessage } = action;
  return {
    ...state,
    alterarSenha: {
      ...state.alterarSenha,
      [campo]: {
        ...state.alterarSenha[campo],
        error: true,
        errorMessage,
      },
    },
  };
}

function onFocusFieldAlterarSenhaHandler(state, action) {
  const { name } = action;
  return {
    ...state,
    alterarSenha: {
      ...state.alterarSenha,
      [name]: {
        ...state.alterarSenha[name],
        error: false,
        errorMessage: '',
      },
    },
  };
}

function limpaDialogAlterarSenhaHandler(state) {
  return {
    ...state,
    alterarSenha: {
      senhaAtual: {
        nome: 'senhaAtual',
        value: '',
        error: false,
        errorMessage: '',
      },
      novaSenha: {
        nome: 'novaSenha',
        value: '',
        error: false,
        errorMessage: '',
      },
      confirmarNovaSenha: {
        nome: 'confirmarNovaSenha',
        value: '',
        error: false,
        errorMessage: '',
      },
    },
  };
}

function setOpenDialogAlterarSenhaHandler(state, action) {
  const { value } = action;
  return {
    ...state,
    openDialogAlterarSenha: value,
  };
}

export default function (state = INITIAL_STATES, action) {
  switch (action.type) {
    case AUTHENTICATE_STARTED:
      return authenticateStarterHandler(state);
    case AUTHENTICATE_SUCCEEDED:
      return authenticateSucceededHandler(state, action);
    case AUTHENTICATE_FAILED:
      return authenticateFailedHandler(state, action);
    case LOGOUT_SUCCEED:
      return logoutSucceedHandler(state);
    case ON_CHANGE_FIELD_ALTERAR_SENHA:
      return onChangeFieldAlterarSenhaHandler(state, action);
    case SET_ERROR_FIELD_ATUALIZAR_SENHA_ACTION:
      return setErrorFieldAtualizarSenhaHandler(state, action);
    case ON_FOCUS_FIELD_ALTERAR_SENHA:
      return onFocusFieldAlterarSenhaHandler(state, action);
    case LIMPA_DIALOG_ALTERAR_SENHA:
      return limpaDialogAlterarSenhaHandler(state, action);
    case SET_OPEN_DIALOG_ALTERAR_SENHA:
      return setOpenDialogAlterarSenhaHandler(state, action);

    default:
      return state;
  }
}
