import React from 'react';

function CloseIcon() {
  return (
    <div
      style={{
        width: '40px',
        height: '40px',
        borderWidth: '4px',
        borderStyle: 'solid',
        borderColor: 'rgb(212, 63, 58)',
        borderRadius: '50%',
        margin: '20px auto',
        position: 'relative',
        boxSizing: 'content-box',
        animation: '0.5s ease 0s 1 normal none running animateErrorIcon',
      }}
    >
      <span
        style={{
          position: 'relative',
          display: 'block',
          animation: '0.5s ease 0s 1 normal none running animateXMark',
        }}
      >
        <span
          style={{
            position: 'absolute',
            height: '5px',
            width: '24px',
            backgroundColor: 'rgb(217, 83, 79)',
            display: 'block',
            top: '18px',
            borderRadius: '2px',
            transform: 'rotate(45deg)',
            left: '8px',
          }}
        />

        <span
          style={{
            position: 'absolute',
            height: '5px',
            width: '24px',
            backgroundColor: 'rgb(217, 83, 79)',
            display: 'block',
            top: '18px',
            borderRadius: '2px',
            transform: 'rotate(-45deg)',
            right: '8px',
          }}
        />
      </span>
    </div>
  );
}

export default CloseIcon;
