import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const AcoesSelecao = styled.div`
  margin-top: 14px;
  height: 42px;
  padding: 0 0 0 23px;
  background-color: #fec1dd;
  display: flex;
  justify-content: space-between;
  align-items: center;

  >p {
     font-weight: bold;
     font-size: 12px;
  }
  >div {
    height: 42px;
    display: flex;
  }
`;

export const Button = styled.button`
  height: 42px;
  padding: 0 15px;
  background-color: #fec1dd;
  cursor: pointer;

  font-size: 12px;
  color: black;
  display: flex;
  align-items: center;

  span {
    font-weight: bold;
    margin-left: 8px;
  }

  :disabled {
    background-color: #fec1dd;
    cursor: default;
    color: #ff7db9;
  }

  :enabled:hover {
    background-color: #ff1381;
    transition: background-color 0.15s linear;
    color: white;
  }
`;

export const AcoesEmMassa = styled.div`
  position: relative;

  ul {
    height: 24px;
    width: 234px;
    position: absolute;
    right: 0;
  }

  ul li {
    background-color: white;
    font-size: 12px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  }

`;

export const Acao = styled.button`
  :hover {
    background-color: #cfd9eb;
    transition: background-color 0.15s linear;
  }
  padding: 5px 0px;
  width: 100%;
  background-color: white;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};;
  text-align: start;
  padding-left: 10px;
  color: ${props => (props.disabled ? 'lightgrey' : 'black')};
`;

export default makeStyles({

  dialogEnviarProcessosParaOutraFase: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '585px',
    },
  },
  dialogEnviarEmailDevedores: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '800px',
    },
  },

});
