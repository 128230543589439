import React from 'react';
import ButtonUI from '../ButtonUI';
import useStyles from './buttonWithIconUI.styles';


function ButtonWithIconUI({
  iconPng,
  iconFontAwesome,
  label,
  onClick,
  ...props
}) {
  const { disabled } = props;
  const styles = useStyles({ disabled });

  let contentIcon;

  if (iconPng) {
    contentIcon = <img src={iconPng} alt="" />;
  } else {
    contentIcon = <i className={iconFontAwesome} />;
  }

  return (
    <ButtonUI onClick={onClick} color="white" {...props}>
      <div className={styles.div__root}>
        {contentIcon}
        {label}
      </div>
    </ButtonUI>
  );
}

export default React.memo(ButtonWithIconUI);
