import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { Button } from '@mui/material';
import WrapperSubTitleForm from '../../../components/UI/WrapperSubTitleForm/WrapperSubTitleForm';
import {
  onChangeTituloCompromissoAction,
  onClearErrorFocusAction,
} from '../../../store/TituloCompromisso/Crud/crudTituloCompromisso.store';
import LoadingUI from '../../../components/UI/Loading/LoadingUI';
import { CADASTRO_ACTION, EDITAR_ACTION } from '../Pesquisa/PesquisarTituloCompromisso.constants';
import { useTitulosCompromissoDispatch, useTitulosCompromissoSelector } from '../../../store/TituloCompromisso/TituloCompromissoProvider';
import InputField from '../../../components/UI/Field/Input/InputField';
import useUtilStyle from '../../../helpers/styles/utils.styles';
import AlertDialog from '../../../components/UI/Dialogs/AlertDialog/AlertDialog';
import { ERROR } from '../../../helpers/constants/global.constants';
import { replaceSemiColonOnValue } from '../../../helpers/utils/utils';
import DialogNew from '../../../layouts/FormDialog/DialogNew';
import { FIELD_LABEL, SUBTITLE_FORM, routeName } from './CrudTituloCompromisso.constants';
import { atualizarTituloCompromissoAction, salvarTituloCompromissoAction } from '../../../store/TituloCompromisso/Crud/crudTituloCompromisso.saga';

function CrudTituloCompromisso({
  nomeTituloCompromisso,
  idTipoTituloDeCompromisso,
  acao,
  open,
  onClose,
}) {
  const dispatch = useTitulosCompromissoDispatch();

  const utilStyle = useUtilStyle();

  const loading = useTitulosCompromissoSelector(states => states.crudTituloCompromisso.actions.loading);
  const openDialogErrorEdit = useTitulosCompromissoSelector(states => states.crudTituloCompromisso.actions.openDialogErrorEdit);
  const contentDialogError = useTitulosCompromissoSelector(states => states.crudTituloCompromisso.actions.contentDialogError);
  const tituloCompromisso = useTitulosCompromissoSelector(states => states.crudTituloCompromisso.controls.nomeTituloCompromisso);

  const [nomeAtual, setNomeAtual] = useState('');

  const closeDialogEditHandler = () => {
    onClose();
  };

  useEffect(() => {
    setNomeAtual(nomeTituloCompromisso);
    dispatch(onChangeTituloCompromissoAction(nomeTituloCompromisso));
  }, [nomeTituloCompromisso, dispatch]);

  const clearErrorOnFocus = useCallback(() => {
    dispatch(onClearErrorFocusAction());
  }, [dispatch]);

  const changeHandler = useCallback((event) => {
    const { value } = event.target;
    dispatch(onChangeTituloCompromissoAction(replaceSemiColonOnValue(value)));
  }, [dispatch]);

  const submitHandler = () => {
    if (acao === CADASTRO_ACTION) {
      dispatch(salvarTituloCompromissoAction(tituloCompromisso.getValueNotEmpty()));
    } else if (acao === EDITAR_ACTION) {
      dispatch(atualizarTituloCompromissoAction(idTipoTituloDeCompromisso, tituloCompromisso.getValueNotEmpty(), nomeAtual));
    }
  };

  return (
    <>
      <LoadingUI show={loading} />
      <AlertDialog
        open={openDialogErrorEdit}
        variant={ERROR}
        onClickConfirm={closeDialogEditHandler}
      >
        <Typography style={{ textAlign: 'center' }} component="p">{contentDialogError}</Typography>
      </AlertDialog>
      <DialogNew
        open={open}
        text={routeName(acao)}
        onClose={onClose}
      >
        <WrapperSubTitleForm title={SUBTITLE_FORM}>
          <div className={utilStyle.titleFieldDistance} />
          <InputField
            isForm
            fullWidth
            error={tituloCompromisso.error}
            errorMessage={tituloCompromisso.errorMessage}
            label={FIELD_LABEL}
            onChange={changeHandler}
            onFocus={clearErrorOnFocus}
            value={tituloCompromisso.value}
          />
        </WrapperSubTitleForm>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
          <Button
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            onClick={submitHandler}
            disabled={tituloCompromisso.getValueNotEmpty() === undefined}
          >
            Confirmar
          </Button>
        </div>
      </DialogNew>
    </>
  );
}

export default CrudTituloCompromisso;
