import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import OrdenacaoColuna from '../UI/Tabela/Ordenacao/OrdenacaoColuna';

const consultaDinamicaColumnsIds = {
  codigo: 'idConsultaDinamica',
  tipo: 'tipo',
  reponsavel: 'nomeUsuario',
  descricao: 'descricao',
};

function TableHeaderConsultaDinamicaListComponent({ columnOrder, onChangeOrder }) {
  return (
    <TableRow>
      <TableCell width={48} height={43}>
        <span />
      </TableCell>
      <TableCell width={66} height={43}>
        <OrdenacaoColuna
          columnLabel="Código"
          idColumn={consultaDinamicaColumnsIds.codigo}
          arrowOrdenacao={columnOrder.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder(consultaDinamicaColumnsIds.codigo)}
        />
      </TableCell>
      <TableCell width={68} height={43}>
        <OrdenacaoColuna
          columnLabel="Tipo"
          idColumn={consultaDinamicaColumnsIds.tipo}
          arrowOrdenacao={columnOrder.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder(consultaDinamicaColumnsIds.tipo)}
        />
      </TableCell>
      <TableCell width={155} height={43}>
        <OrdenacaoColuna
          columnLabel="Responsável"
          idColumn={consultaDinamicaColumnsIds.reponsavel}
          arrowOrdenacao={columnOrder.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder(consultaDinamicaColumnsIds.reponsavel)}
        />
      </TableCell>
      <TableCell width={553} height={43}>
        <OrdenacaoColuna
          columnLabel="Descrição"
          idColumn={consultaDinamicaColumnsIds.descricao}
          arrowOrdenacao={columnOrder.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder(consultaDinamicaColumnsIds.descricao)}
        />
      </TableCell>
      <TableCell width={68} height={43}>Ação</TableCell>
    </TableRow>
  );
}

export const TableHeaderConsultaDinamicaList = TableHeaderConsultaDinamicaListComponent;
