import styled from 'styled-components';
import DialogNew from '../../layouts/FormDialog/DialogNew';


export const ContainerAtualizarConsultaDinamica = styled.div`
  > div {
    margin-top: 16px;
  }

  div:last-child {
    margin-top: 0;
  }
`;

export const Layout = styled(DialogNew)`
  .MuiPaper-root.MuiDialog-paperWidthXs {
    min-width: 585px !important;
  }
`;
