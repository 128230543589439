import {
  getExceptionErrorMessage,
  getExceptionMessage, toAmericanDate, updateFieldsWithErrors,
} from '../../helpers';
import { prestarContasService } from './PrestarContasService';

export const PrestarContasController = {
  makeErrorFields(errors) {
    return {
      dataLancamento: {
        error: errors?.dataLancamento?.error || false,
        errorMessage: errors?.dataLancamento?.errorMessage || '',
      },
      historico: {
        error: errors?.historico?.error || false,
        errorMessage: errors?.historico?.errorMessage || '',
      },
      valor: {
        error: errors?.valor?.error || false,
        errorMessage: errors?.valor?.errorMessage || '',
      },
      mes: {
        error: errors?.mes?.error || false,
        errorMessage: errors?.mes?.errorMessage || '',
      },
      numeroDiasTrabalhados: {
        error: errors?.numeroDiasTrabalhados?.error || false,
        errorMessage: errors?.numeroDiasTrabalhados?.errorMessage || '',
      },
      domingosFeriados: {
        error: errors?.domingosFeriados?.error || false,
        errorMessage: errors?.domingosFeriados?.errorMessage || '',
      },
    };
  },

  makePayload(formFields, idComissionado, idContaCorrenteUsuario) {
    return {
      idComissionado,
      idContaCorrenteUsuario,
      dataLancamento: toAmericanDate(formFields?.dataLancamento) || null,
      domingosFeriados: formFields?.domingosFeriados || null,
      historico: formFields?.historico || null,
      mes: formFields?.mes || null,
      numeroDiasTrabalhados: formFields?.numeroDiasTrabalhados || null,
      valor: formFields?.valor || null,
      saldo: formFields?.saldo || null,
    };
  },

  async prestarContas(errorFields, formFields, idComissionado) {
    try {
      const payload = this.makePayload(formFields, idComissionado);
      await prestarContasService(payload);
      return { success: true, error: null };
    } catch (error) {
      const [updatedErrors] = updateFieldsWithErrors(error, errorFields);
      const exceptionMessage = getExceptionMessage(error);
      const exceptionErrorMessage = getExceptionErrorMessage(error);
      return {
        success: false,
        error: {
          campos: { ...updatedErrors },
          toastMessage: error.response.data.validations ? exceptionErrorMessage : exceptionMessage,
        },
      };
    }
  },
};
