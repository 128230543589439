import { makeStyles } from '@material-ui/styles';

export default makeStyles({

  body: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: '14px',
    paddingRight: '6px',
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  button: {
    width: '176px',
    height: '36px',
  },

  dialog: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '585px',
      maxHeight: '313px',
    },
  },
});
