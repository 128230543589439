import { buildDialog, buildSnackbar } from '../../../helpers/factory/global.factory';
import FieldModel from '../../../models/FieldModel';
import { getAttributeExistingObject } from '../../../helpers/utils/utils';

const SET_LOADING = 'SET_LOADING_CRUD_FASES_PASTAS';
const SET_ALERT_DIALOG = 'SET_ALERT_DIALOG_CRUD_FASES_PASTAS';
const ON_OPEN_SNACKBAR = 'OPEN_SNACKBAR_CRUD_FASES_PASTAS';
const SET_REQUEST_FIELDS = 'SET_REQUEST_FIELDS';
const BUILD_REQUESTED_FIELDS = 'BUILD_REQUESTED_FIELDS';
const SET_OTHER_FIELDS = 'SET_OTHER_FIELDS_CRUD_FASE_PASTA';
const CHANGE_EDIT_MODE = 'CHANGE_EDIT_MODE_FASES_PASTAS';
const UPDATE_REQUESTED_FIELDS = 'UPDATE_REQUESTED_FIELDS';
const SET_REQUEST_FIELDS_FOCUS = 'SET_REQUEST_FIELDS_FOCUS_CRUD_FASES_PASTAS';
const CLEAR_EDITOR_TEXTO = 'CLEAR_EDITOR_TEXTO_PASTAS';


function buildFields(fields) {
  return {
    idFase: new FieldModel({
      name: 'idFase',
      value: getAttributeExistingObject(fields, 'idFase', 1),
    }),
    idFaseProximaFase: new FieldModel({
      name: 'idFaseProximaFase',
      value: getAttributeExistingObject(fields, 'idFaseProximaFase', ''),
    }),
    nome: new FieldModel({
      name: 'nome',
      value: getAttributeExistingObject(fields, 'nome', ''),
    }),
    duracao: new FieldModel({
      name: 'duracao',
      value: getAttributeExistingObject(fields, 'duracao', ''),
    }),
    descricaoAtividades: new FieldModel({
      name: 'descricaoAtividades',
      value: getAttributeExistingObject(fields, 'descricaoAtividades', ''),
    }),
    pagarComissao: new FieldModel({
      name: 'pagarComissao',
      value: getAttributeExistingObject(fields, 'pagarComissao', false),
    }),
    permitirReceberProcesso: new FieldModel({
      name: 'permitirReceberProcesso',
      value: getAttributeExistingObject(fields, 'permitirReceberProcesso', false),
    }),
    exigirMotivoBaixa: new FieldModel({
      name: 'exigirMotivoBaixa',
      value: getAttributeExistingObject(fields, 'exigirMotivoBaixa', false),
    }),
    descricaoTipoEstagioFase: new FieldModel({
      name: 'descricaoTipoEstagioFase',
      value: getAttributeExistingObject(fields, 'descricaoTipoEstagioFase', ''),
    }),
    descricaoTipoNegociador: new FieldModel({
      name: 'descricaoTipoNegociador',
      value: getAttributeExistingObject(fields, 'descricaoTipoNegociador', ''),
    }),
    idTipoNegociador: new FieldModel({
      name: 'idTipoNegociador',
      value: getAttributeExistingObject(fields, 'idTipoNegociador', ''),
    }),
    idTipoEstagioFase: new FieldModel({
      name: 'idTipoEstagioFase',
      value: getAttributeExistingObject(fields, 'idTipoEstagioFase', ''),
    }),
    recalcularProcesso: new FieldModel({
      name: 'recalcularProcesso',
      value: getAttributeExistingObject(fields, 'recalcularProcesso', false),
    }),
    porcentagemDescontoJuros: new FieldModel({
      name: 'porcentagemDescontoJuros',
      value: getAttributeExistingObject(fields, 'porcentagemDescontoJuros', 0.00),
    }),
  };
}

function buildInitialStates(fields) {
  return {
    requestedFields: buildFields(fields),
    usuariosAtivosFase: [],
    pageView: {
      tabActive: 0,
      isEditMode: false,
    },
    actions: {
      alertDialog: buildDialog(),
      openSnackbar: buildSnackbar(),
      loading: false,
    },
  };
}

export const setOtherFieldsAction = (name, value) => ({
  type: SET_OTHER_FIELDS,
  name,
  value,
});

export const buildRequestedFieldsAction = requestedFields => ({
  type: BUILD_REQUESTED_FIELDS,
  requestedFields,
});

export const setLoadingAction = loading => ({
  type: SET_LOADING,
  loading,
});

export const setAlertDialogValuesAction = (open, variant, message, origin) => ({
  type: SET_ALERT_DIALOG,
  open,
  variant,
  message,
  origin,
});

export const setSnackBarAction = (open, variant, message, origin) => ({
  type: ON_OPEN_SNACKBAR,
  open,
  variant,
  message,
  origin,
});

export const setRequestedFieldValueAction = (name, value) => ({
  type: SET_REQUEST_FIELDS,
  name,
  value,
});
export const changeEditModeAction = editMode => ({
  type: CHANGE_EDIT_MODE,
  editMode,
});

export const updateRequestFieldsAction = updateRequestedFields => ({
  type: UPDATE_REQUESTED_FIELDS,
  updateRequestedFields,
});

export const setRequestFieldsFocusAction = name => ({
  type: SET_REQUEST_FIELDS_FOCUS,
  name,
});

export const clearEditorTextoAction = () => ({
  type: CLEAR_EDITOR_TEXTO,
});


function clearEditorTextoHandler(states) {
  const newEditor = states.requestedFields.descricaoAtividades.getNewModel('');

  newEditor.error = true;
  newEditor.errorMessage = 'Campo Obrigatório';

  return {
    ...states,
    requestFields: {
      ...states.requestedFields,
      descricaoAtividades: newEditor,
    },
  };
}

function changeLoadingHandler(states, actions) {
  return {
    ...states,
    loading: actions.loading,
  };
}

function changeAlertDialogValueHandler(states, actions) {
  const alertDialog = { ...states.alertDialog, open: actions.open };
  if (actions.variant || actions.message) {
    alertDialog.variant = actions.variant;
    alertDialog.message = actions.message;
    alertDialog.origin = actions.origin;
  }
  return { ...states, alertDialog };
}

function setRequestedFieldValueHandler(states, actions) {
  const { name, value } = actions;
  const field = states.requestedFields[name].getNewModel(value);
  return {
    ...states,
    requestedFields: {
      ...states.requestedFields,
      [name]: field,
    },
  };
}

function buildRequestedFieldsHandler(states, actions) {
  const { requestedFields } = actions;
  return {
    ...states,
    requestedFields: buildFields(requestedFields),
  };
}

function openSnackbarHandler(states, actions) {
  const openSnackbar = { ...states.openSnackbar, open: actions.open };

  if (actions.variant || actions.message) {
    openSnackbar.variant = actions.variant;
    openSnackbar.message = actions.message;
    openSnackbar.origin = actions.origin;
  }
  return {
    ...states,
    actions: {
      ...states.actions,
      openSnackbar,
    },
  };
}

function changeEditModeHandler(states, actions) {
  const { editMode } = actions;
  return {
    ...states,
    pageView: {
      ...states.pageView,
      isEditMode: editMode,
    },
  };
}

function updateRequestFieldsHandler(states, actions) {
  return {
    ...states,
    requestedFields: {
      ...states.requestedFields,
      ...actions.updateRequestedFields,
    },
  };
}
function changeRequestFieldsFocusHandler(states, actions) {
  const { name } = actions;
  const fieldModel = states.requestedFields[actions.name].copy();
  return {
    ...states,
    requestedFields: {
      ...states.requestedFields,
      [name]: fieldModel.onFocus(),
    },
  };
}

function setOtherFieldsHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    [name]: value,
  };
}

export default (states = buildInitialStates(), action) => {
  switch (action.type) {
    case SET_LOADING:
      return changeLoadingHandler(states, action);
    case SET_ALERT_DIALOG:
      return changeAlertDialogValueHandler(states, action);
    case ON_OPEN_SNACKBAR:
      return openSnackbarHandler(states, action);
    case SET_REQUEST_FIELDS:
      return setRequestedFieldValueHandler(states, action);
    case BUILD_REQUESTED_FIELDS:
      return buildRequestedFieldsHandler(states, action);
    case CHANGE_EDIT_MODE:
      return changeEditModeHandler(states, action);
    case UPDATE_REQUESTED_FIELDS:
      return updateRequestFieldsHandler(states, action);
    case SET_REQUEST_FIELDS_FOCUS:
      return changeRequestFieldsFocusHandler(states, action);
    case CLEAR_EDITOR_TEXTO:
      return clearEditorTextoHandler(states);
    case SET_OTHER_FIELDS:
      return setOtherFieldsHandler(states, action);
    default:
      return states;
  }
};
