import FieldModel, { POSITIVE_INTEGER } from '../../models/FieldModel';
import { getAttributeExistingObject } from '../utils/utils';

export function buildFaixaDeJurosEhRepasse(faixaDeJuros) {
  return ({
    idParametroJuros: new FieldModel({
      value: getAttributeExistingObject(faixaDeJuros, 'idParametroJuros'),
    }),
    quantidadeDiasInicial: new FieldModel({
      name: 'quantidadeDiasInicial',
      value: getAttributeExistingObject(faixaDeJuros, 'quantidadeDiasInicial'),
      maxLength: 6,
      type: POSITIVE_INTEGER,
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    quantidadeDiasFinal: new FieldModel({
      name: 'quantidadeDiasFinal',
      value: getAttributeExistingObject(faixaDeJuros, 'quantidadeDiasFinal'),
      maxLength: 6,
      type: POSITIVE_INTEGER,
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    porcentagemJuros: new FieldModel({
      name: 'porcentagemJuros',
      value: getAttributeExistingObject(faixaDeJuros, 'porcentagemJuros'),
      maxLength: 5,
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    porcentagemJurosRepasse: new FieldModel({
      name: 'porcentagemJurosRepasse',
      value: getAttributeExistingObject(faixaDeJuros, 'porcentagemJurosRepasse'),
      maxLength: 5,
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
  });
}

export function buildFaixasDeJurosEhRepasse(faixasDeJurosEhRepasse) {
  return (faixasDeJurosEhRepasse) ? faixasDeJurosEhRepasse.map(buildFaixaDeJurosEhRepasse) : [];
}
