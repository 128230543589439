import React from 'react';
import Grid24 from '../../../../../UI/Grid24';
import { getRequiredLabel } from '../../../../../../helpers/utils/utils';
import NumberInput from '../../../../../UI/Field/Number/NumberInput';
import MoneyField from '../../../../../UI/Field/Money/MoneyField';
import { TrashIconButton, TrashIconContainer } from '../Honorarios/Faixa/styles';
import {
  FINAL, INITIAL, TAXA, TAXA_REPASSE,
} from './JurosDosDevedores.constants';
import { TRASH } from '../../../../../UI/JurosDosDevedores/Faixa/FaixasJurosDosDevedores.constants';

function CustomField({
  type = 'MONEY',
  label,
  disabled = false,
  name,
  value,
  error,
  errorMessage,
  onChange,
  onFocus,
}) {
  return type === 'MONEY' ? (
    <MoneyField
      isForm
      fullWidth
      disabled={disabled}
      maxlength={16}
      label={label}
      name={name}
      value={value}
      error={error}
      errorMessage={errorMessage}
      onChange={onChange}
      onFocus={onFocus}
    />
  ) : (
    <NumberInput
      isForm
      fullWidth
      disabled={disabled}
      sideText="(dias)"
      maxValue={999999}
      label={label}
      name={name}
      value={value}
      error={error}
      errorMessage={errorMessage}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
}

function TrashButton({
  disabled = false,
  showTrash = false,
  attributes,
  onRemove,
}) {
  return (
    <TrashIconContainer showtrash={(!disabled && !showTrash) ? 1 : 0} item xs={24} sm={1}>
      <TrashIconButton
        type="trash"
        attributes={attributes}
        disabled={disabled}
        hint={TRASH}
        onClick={onRemove}
      />
    </TrashIconContainer>
  );
}


function FaixaJurosDosDevedores({
  type = 'MONEY',
  disabled = false,
  quantidadeDiasInicial,
  quantidadeDiasFinal,
  porcentagemJuros,
  porcentagemJurosRepasse,
  showTrash = false,
  idxClienteJuros,
  onChange,
  onFocus,
  onRemove,
}) {
  const rename = name => `${name}-${idxClienteJuros}`;

  const disabledFirstValue = (disabled || idxClienteJuros === 0);
  return (
    <>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={4}>
          <CustomField
            type={type}
            label={getRequiredLabel(INITIAL, !disabledFirstValue)}
            disabled={disabledFirstValue}
            name={rename(quantidadeDiasInicial.name)}
            value={quantidadeDiasInicial.value}
            error={quantidadeDiasInicial.error}
            errorMessage={quantidadeDiasInicial.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>

        <Grid24 item xs={24} sm={4}>
          <CustomField
            type={type}
            label={getRequiredLabel(FINAL, !disabled)}
            disabled={disabled}
            name={rename(quantidadeDiasFinal.name)}
            value={quantidadeDiasFinal.value}
            error={quantidadeDiasFinal.error}
            errorMessage={quantidadeDiasFinal.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>

        <Grid24 item xs={24} sm={4}>
          <NumberInput
            isForm
            fullWidth
            disabled={disabled}
            sideText="(%)"
            numberType="percantage"
            maxValue={100}
            label={getRequiredLabel(TAXA, !disabled)}
            name={rename(porcentagemJuros.name)}
            value={porcentagemJuros.value}
            error={porcentagemJuros.error}
            errorMessage={porcentagemJuros.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>

        <Grid24 item xs={24} sm={4}>
          <NumberInput
            isForm
            fullWidth
            disabled={disabled}
            sideText="(%)"
            numberType="percantage"
            maxValue={100}
            label={getRequiredLabel(TAXA_REPASSE, !disabled)}
            name={rename(porcentagemJurosRepasse.name)}
            value={porcentagemJurosRepasse.value}
            error={porcentagemJurosRepasse.error}
            errorMessage={porcentagemJurosRepasse.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Grid24>

        <TrashButton
          showTrash={showTrash}
          disabled={disabled}
          attributes={{ idxClienteJuros }}
          onRemove={onRemove}
        />
      </Grid24>
    </>
  );
}

export default React.memo(FaixaJurosDosDevedores);
