import React, { useCallback } from 'react';
import { Button } from '@mui/material';
import CampoObrigatorio from '../../UI/CampoObrigatorio/CampoObrigatorio';
import InputField from '../../UI/Field/Input/InputField';
import { useRequisicaoDispatch, useRequisicaoSelector } from '../../../store/Requisicao/RequisicaoProvider';
import FormWrapperWithTitle from '../../UI/Wrapper/Form/FormWrapperWithTitle';
import Grid24 from '../../UI/Grid24/Grid24';
import CheckboxField from '../../UI/Field/Checkbox/CheckboxField';
import useStyles from './crudRequisicoes.styles';
import useUtilStyles from '../../../helpers/styles/utils.styles';
import {
  ATIVIDADES_REQUISICAO,
  IDENTIFICACAO,
  INSIRA_ATIVIDADES,
  NOME,
  NOTIFICAR_RESPONSAVEIS,
  USUARIOS_ASSOCIADOS,
} from './crudRequisicoes.constants';
import { arrayValuesToStringSeparator, getRequiredLabel, replaceSemiColonOnValue } from '../../../helpers/utils/utils';
import Editor from '../../UI/EditorTexto';
import { saveRequisicaoAction } from '../../../store/Requisicao/requisicao.saga';
import { setRequestFieldFocusAction, setRequestFieldValueAction } from '../../../store/Requisicao/requisicao.store';
import DialogNew from '../../../layouts/FormDialog/DialogNew';


function CrudRequisicoes({
  routesName = [],
  isCreateMode = false,
  isEditMode = false,
  open,
  onClose,
}) {
  const styles = useStyles();
  const utilStyles = useUtilStyles();
  const dispatch = useRequisicaoDispatch();


  const idRequisicao = useRequisicaoSelector(states => states.requestFields.idRequisicao);
  const nome = useRequisicaoSelector(states => states.requestFields.nome.copy());
  const enviarNotificacao = useRequisicaoSelector(states => states.requestFields.enviarNotificacao.copy());
  const descricaoAtividades = useRequisicaoSelector(states => states.requestFields.descricaoAtividades.copy());
  const usuariosAssociados = useRequisicaoSelector(states => states.usuariosAssociados);

  const isDetailMode = !isCreateMode && !isEditMode;


  const submitHandler = useCallback(() => {
    dispatch(saveRequisicaoAction(isEditMode, idRequisicao));
  }, [dispatch, isEditMode, idRequisicao]);

  const onChangeNomeHandler = useCallback((e) => {
    const { name, value } = e.target;
    dispatch(setRequestFieldValueAction(name, replaceSemiColonOnValue(value)));
  }, [dispatch]);

  const onChangeCheckedHandler = useCallback((e) => {
    const { name, checked } = e.target;
    dispatch(setRequestFieldValueAction(name, checked));
  }, [dispatch]);

  const onChangeAtividades = useCallback((event) => {
    dispatch(setRequestFieldValueAction('descricaoAtividades', event));
  }, [dispatch]);

  const onFocusAtividades = useCallback(() => {
    dispatch(setRequestFieldFocusAction('descricaoAtividades'));
  }, [dispatch]);

  const onFocusHandler = useCallback((e) => {
    let fieldName = e.target.name;

    if (e.target.nextElementSibling) {
      fieldName = e.target.nextElementSibling.name;
    }
    dispatch(setRequestFieldFocusAction(fieldName));
  }, [dispatch]);


  return (
    <DialogNew
      open={open}
      text={routesName}
      onClose={onClose}
      className={styles.dialog}
    >
      {nome.error === true ? (
        <CampoObrigatorio visible={!isDetailMode} />
      ) : ('')}

      <FormWrapperWithTitle title={IDENTIFICACAO} className={utilStyles.mt13}>
        <Grid24 container isFormContainer spacing="1">
          <Grid24 item xs={24} sm={17}>
            <InputField
              isForm
              fullWidth
              label={getRequiredLabel(NOME, !isDetailMode)}
              name="nome"
              value={nome.value}
              error={nome.error}
              errorMessage={nome.errorMessage}
              maxLength={50}
              disabled={isDetailMode}
              onChange={onChangeNomeHandler}
              onFocus={onFocusHandler}
            />
          </Grid24>
          <Grid24 xs={24} sm={6} justify="flex-end">
            <CheckboxField
              name={enviarNotificacao.name}
              label={NOTIFICAR_RESPONSAVEIS}
              checked={enviarNotificacao.value}
              onChange={onChangeCheckedHandler}
              disabled={isDetailMode}
              propsFormLabel={{ className: styles.checkbox }}
            />
          </Grid24>
        </Grid24>
        <Grid24 container isFormContainer spacing="1">
          <Grid24 item xs={24} className={utilStyles.mt9}>
            <Editor
              label={getRequiredLabel(ATIVIDADES_REQUISICAO, !isDetailMode)}
              placeholder={INSIRA_ATIVIDADES}
              readOnly={isDetailMode}
              name={descricaoAtividades.name}
              model={descricaoAtividades}
              value={descricaoAtividades.value}
              onChange={onChangeAtividades}
              onFocus={onFocusAtividades}
            />
          </Grid24>
        </Grid24>
        {isDetailMode && (
          <Grid24 isFormContainer container spacing="1">
            <Grid24 item xs={24} className={utilStyles.mt9}>
              <InputField
                isForm
                label={USUARIOS_ASSOCIADOS}
                fullWidth
                disabled
                value={arrayValuesToStringSeparator(usuariosAssociados, '; ')}
              />
            </Grid24>
          </Grid24>
        )}
      </FormWrapperWithTitle>
      {isDetailMode ? (
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
          <Button
            onClick={onClose}
          >
            Voltar
          </Button>

        </div>
      )
        : (
          <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
            <Button
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              onClick={submitHandler}
            >
              Confirmar
            </Button>
          </div>
        )}
    </DialogNew>
  );
}

export default React.memo(CrudRequisicoes);
