import React, { useCallback, useEffect } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import Grid24 from '../UI/Grid24';
import InputField from '../UI/Field/Input';
import CheckboxField from '../UI/Field/Checkbox/CheckboxField';
import SelectorField from '../UI/Field/Selector/SelectorField';
import MaskField from '../UI/Field/Mask/MaskField';
import useUtilStyles from '../../helpers/styles/utils.styles';
import AddActionTitleWrapper from '../UI/Wrapper/AddActionTitle';
import { findEstadosAction } from '../../store/Global/LoadSelectors/loadSelectors.saga';
import { CEP_MASK } from '../../helpers/constants/layout.constants';
import { getFieldName } from '../../helpers';
import useStyles from './Endereco.styles';

function CorrigirEnderecoDevedorBorderoComponent({ devedor, setBordero }) {
  const layoutConstanst = {
    INFO_ENDERECO: 'Informações do Endereço do Devedor',
    CEP: 'CEP*',
    ENDERECO: 'Endereço',
    NUMERO: 'Número',
    COMPLEMENTO: 'Complemento',
    BAIRRO: 'Bairro',
    CIDADE: 'Cidade',
    ESTADO: 'Estado*',
    PRINCIPAL: 'Principal',
  };

  const utilStyles = useUtilStyles();
  const styles = useStyles();

  const dispatch = useDispatch();

  const estados = useSelector(states => states.selectors.estados.selector);

  useEffect(() => {
    dispatch(findEstadosAction());
  }, [dispatch]);

  const onChangeEnderecoHandler = useCallback((event, index) => {
    const name = getFieldName(event.target);
    const { value } = event.target;
    const enderecos = [...devedor.enderecos];
    enderecos[index][name].onChange(value);
    setBordero(oldBordero => ({ ...oldBordero, enderecos }));
  }, [devedor?.enderecos, setBordero]);

  const onFocusEnderecoHandler = useCallback((event, index) => {
    const name = getFieldName(event.target);
    const enderecos = [...devedor.enderecos];
    enderecos[index][name].onFocus();
    setBordero(oldBordero => ({ ...oldBordero, enderecos }));
  }, [devedor?.enderecos, setBordero]);

  const getEnderecosWithPrincipalValueFalse = useCallback(() => devedor?.enderecos.map((endereco) => {
    const modiFieldEndereco = { ...endereco };
    modiFieldEndereco.principal.onChange(false);
    return modiFieldEndereco;
  }), [devedor?.enderecos]);

  const choosePrincipalHandler = useCallback((event, index) => {
    const name = getFieldName(event.target);
    const enderecos = getEnderecosWithPrincipalValueFalse();
    enderecos[index][name].onChange(true);
    setBordero(oldBordero => ({ ...oldBordero, enderecos }));
  }, [setBordero, getEnderecosWithPrincipalValueFalse]);

  const buildEnderecoComponent = (endereco, index) => (
    <div key={index}>
      <Grid24 container spacing="1">
        <Grid24 item xs={24} sm={19}>
          <Grid24 item container isFormContainer spacing="1">
            <Grid24 item xs={24} sm={3}>
              <MaskField
                isForm
                fullWidth
                disabled={endereco?.cep?.disabled}
                label={layoutConstanst.CEP}
                name="cep"
                value={endereco?.cep?.value}
                error={endereco?.cep?.error}
                errorMessage={endereco.cep?.errorMessage}
                onChange={e => onChangeEnderecoHandler(e, index)}
                format={CEP_MASK}
                onFocus={e => onFocusEnderecoHandler(e, index)}
              />
            </Grid24>
            <Grid24 item xs={24} sm={14}>
              <InputField
                isForm
                fullWidth
                disabled={endereco?.endereco?.disabled}
                label={layoutConstanst.ENDERECO}
                error={endereco?.endereco?.error}
                name="endereco"
                value={endereco?.endereco?.value}
                errorMessage={endereco.endereco?.errorMessage}
                onChange={e => onChangeEnderecoHandler(e, index)}
                onFocus={e => onFocusEnderecoHandler(e, index)}
              />
            </Grid24>
            <Grid24 item xs={24} sm={2}>
              <InputField
                isForm
                fullWidth
                disabled={endereco?.numero?.disabled}
                label={layoutConstanst.NUMERO}
                name="numero"
                value={endereco?.numero?.value}
                error={endereco?.numero?.error}
                errorMessage={endereco?.numero?.errorMessage}
                onChange={e => onChangeEnderecoHandler(e, index)}
                onFocus={e => onFocusEnderecoHandler(e, index)}
              />
            </Grid24>
            <Grid24 item xs={24} sm={5}>
              <InputField
                isForm
                fullWidth
                disabled={endereco?.complemento?.disabled}
                label={layoutConstanst.COMPLEMENTO}
                name="complemento"
                value={endereco?.complemento?.value}
                error={endereco?.complemento?.error}
                errorMessage={endereco?.complemento?.errorMessage}
                onChange={e => onChangeEnderecoHandler(e, index)}
                onFocus={e => onFocusEnderecoHandler(e, index)}
              />
            </Grid24>
          </Grid24>
          <Grid24 container isFormContainer spacing="1">
            <Grid24 item xs={24} sm={15}>
              <InputField
                isForm
                fullWidth
                disabled={endereco?.bairro?.disabled}
                label={layoutConstanst.BAIRRO}
                name="bairro"
                value={endereco?.bairro?.value}
                error={endereco?.bairro?.error}
                errorMessage={endereco?.bairro?.errorMessage}
                onChange={e => onChangeEnderecoHandler(e, index)}
                onFocus={e => onFocusEnderecoHandler(e, index)}
              />
            </Grid24>
            <Grid24 item xs={24} sm={7}>
              <InputField
                isForm
                fullWidth
                disabled={endereco?.cidade?.disabled}
                label={layoutConstanst.CIDADE}
                name="cidade"
                value={endereco?.cidade?.value}
                error={endereco?.cidade?.error}
                errorMessage={endereco?.cidade?.errorMessage}
                onChange={e => onChangeEnderecoHandler(e, index)}
                onFocus={e => onFocusEnderecoHandler(e, index)}
              />
            </Grid24>
            <Grid24 item xs={24} sm={2}>
              <SelectorField
                isForm
                fullWidth
                disabled={endereco?.estado?.disabled}
                hasEmptyLabel
                label={layoutConstanst.ESTADO}
                items={estados}
                name="estado"
                value={endereco?.estado?.value}
                error={endereco?.estado?.error}
                errorMessage={endereco?.estado?.errorMessage}
                onChange={e => onChangeEnderecoHandler(e, index)}
                onFocus={e => onFocusEnderecoHandler(e, index)}
              />
            </Grid24>
          </Grid24>
        </Grid24>
        <Grid24
          item
          container
          xs={24}
          sm={5}
          alignContent="flex-start"
          className={
          cx([styles.actions])
        }
        >
          <Grid24 item xs={12} sm={12}>
            <CheckboxField
              label={layoutConstanst.PRINCIPAL}
              disabled={endereco?.principal?.disabled}
              name="principal"
              checked={endereco?.principal?.value}
              onChange={e => choosePrincipalHandler(e, index)}
            />
          </Grid24>
        </Grid24>
      </Grid24>
    </div>
  );

  return (
    <AddActionTitleWrapper
      title={layoutConstanst.INFO_ENDERECO}
      className={utilStyles.mt16}
      hideAddButton
    >
      {devedor?.enderecos?.map(buildEnderecoComponent)}
    </AddActionTitleWrapper>
  );
}

export const CorrigirEnderecoDevedorBordero = CorrigirEnderecoDevedorBorderoComponent;
