import { updateFieldsWithErrors } from '../../../helpers/utils/form.utils';
import { haveExceptionValidations, isBadRequestException } from '../../../helpers/utils/utils';
import { atenderRequisicaoProcessoService, gerarRequisicaoProcessoService } from '../../../services/core/processos/processosRequisicoesService';

class AcaoGerarRequisicaoControler {
  makeFormFields(requisicao) {
    return {
      idRequisicao: requisicao?.idRequisicao || '',
      texto: requisicao?.texto || '',
    };
  }

  makeErrorFields() {
    return {
      idRequisicao: {
        error: false,
        errorMessage: '',
      },
    };
  }

  makePayload(idProcesso, formFields) {
    return {
      idProcesso,
      idRequisicao: formFields?.idRequisicao,
      texto: formFields?.texto,
      temPermissaoAtender: formFields?.temPermissaoAtender,
    };
  }


  isEnabledLimpar(formFields, hasRequisicao) {
    if (hasRequisicao) {
      return formFields?.texto?.trim() === '';
    }

    return Object.keys(formFields).reduce((acc, field) => {
      if (!acc) {
        return false;
      }
      return formFields[field] === '';
    }, true);
  }

  getDefaultFields(defaultFields, hasRequisicao) {
    if (!hasRequisicao) {
      return this.makeFormFields();
    }
    return {
      ...defaultFields,
      texto: '',
    };
  }

  async gerarRequisicao(idProcesso, formFields, errorFields) {
    try {
      const payload = this.makePayload(idProcesso, formFields);
      await gerarRequisicaoProcessoService(payload);
      return { success: true, errorFields };
    } catch (exception) {
      if (isBadRequestException(exception) && haveExceptionValidations(exception)) {
        const [updateErrorFields] = updateFieldsWithErrors(exception, errorFields);
        return { success: false, errorFields: updateErrorFields };
      }
      return { success: false, errorFields };
    }
  }

  async atenderRequisicao(idProcesso, formFields, errorFields) {
    try {
      const payload = this.makePayload(idProcesso, formFields);
      await atenderRequisicaoProcessoService(payload);
      return { success: true, errorFields };
    } catch (exception) {
      return { success: false, errorFields };
    }
  }
}

export const acaoGerarRequisicaoController = new AcaoGerarRequisicaoControler();
