import makeStyles from '@material-ui/core/styles/makeStyles';
import { grayColor } from '../../../assets/jss/material-dashboard-pro-react';

import buttonStyle from '../../Template/CustomButtons/button.style';

export default makeStyles({
  buttonSize: {
    width: '100px',
    padding: '8px auto !important',
  },
  cardTitle: {
    marginTop: '0',
    marginBottom: '3px',
    color: grayColor[2],
    fontSize: '18px',
  },
  center: {
    textAlign: 'center',
    fontSize: '25px',
    backgroundColor: 'red',
  },
  right: {
    textAlign: 'right',
  },
  left: {
    textAlign: 'left',
  },
  confirmButton: {
    ...buttonStyle.button,
    padding: '8px 0px',
    width: '100px',
    marginRight: '0px !important',
    backgroundColor: '#4caf50 !important',
    '&:hover': {
      backgroundColor: '#4caf50AA',
    },
  },
});
