import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
import CampoObrigatorio from '../../../../components/UI/CampoObrigatorio/CampoObrigatorio';
import InputField from '../../../../components/UI/Field/Input';
import MoneyField from '../../../../components/UI/Field/Money/MoneyField';
import RadioField from '../../../../components/UI/Field/Radio/RadioField';
import SelectorField from '../../../../components/UI/Field/Selector';
import Grid24 from '../../../../components/UI/Grid24';
import WrapperSubTitleForm from '../../../../components/UI/WrapperSubTitleForm';
import { getRequiredLabel } from '../../../../helpers/utils/utils';
import { INCLUIR_TITULO, TITULO_PROTESTADO } from '../Titulos.constants';
import useStyle from '../Titulos.styles';
import DateField from '../../../../components/UI/Field/Date/DateField';
import {
  extractResponseErrorMessage, getFieldName, isValidationException, updateFieldsWithErrors,
} from '../../../../helpers/utils/form.utils';
import { dialogTituloController as controller } from './dialogTituloController';
import ChooseField from '../../../../components/UI/Field/Choose/ChooseField';
import { loadingInspecaoProcessoController } from '../../loadingInspecaoProcessoController';
import { findTipoTituloAction } from '../../../../store/Global/LoadSelectors/loadSelectors.saga';
import { makeErrorField } from '../../../../helpers';

function DialogTitulo({
  onClose, immutableSelectors, setLoadings, updatePage, idProcesso, titulo, additionalInfos,
}) {
  const style = useStyle();
  const globalDispatch = useDispatch();

  const [formFields, setFormFields] = useState(controller.makeFormFields(titulo));
  const [errorFields, setErrorFields] = useState(controller.makeErrorFields());

  const onChangeHandler = useCallback((e) => {
    const name = getFieldName(e.target);
    const { value } = e.target;
    setFormFields(oldValue => ({ ...oldValue, [name]: value }));
  }, []);

  const onFocusHandler = useCallback((e) => {
    const name = getFieldName(e.target);
    setErrorFields(oldValues => ({ ...oldValues, [name]: makeErrorField() }));
  }, []);


  const changeTitulosProtestadosHandler = useCallback((event) => {
    onChangeHandler(event);
    if (event.target.value === 'N') {
      setFormFields(oldValues => ({ ...oldValues, valorProtesto: '0' }));
    } if (event.target.value === 'S') {
      if (additionalInfos === 'SP' || additionalInfos === 'MG') {
        setFormFields(oldValues => ({ ...oldValues, valorProtesto: '0' }));
      }
    }
  }, [onChangeHandler, additionalInfos]);


  async function onClickSubmitTitulo(e) {
    e.preventDefault();
    setLoadings(loadingInspecaoProcessoController.changeLoadingTitulos(true));
    try {
      await controller.salvarTitulo(idProcesso, titulo?.idTitulo, formFields);
      updatePage();
      onClose();
    } catch (error) {
      if (isValidationException(error)) {
        const [updatedFields] = updateFieldsWithErrors(error, errorFields);
        setErrorFields({ ...updatedFields });
      }

      const erroMessage = extractResponseErrorMessage(error);
      if (erroMessage !== 'Erro não mapeado') {
        toast.error(erroMessage, { style: { width: '392px' } });
      }
    } finally {
      setLoadings(loadingInspecaoProcessoController.changeLoadingTitulos(false));
    }
  }

  const isDisableValorProtesto = useMemo(() => {
    if (formFields.tituloProtestado === 'N') {
      return true;
    } if (formFields.tituloProtestado === 'S') {
      if (additionalInfos === 'SP' || additionalInfos === 'MG') {
        return true;
      }
    }
  }, [formFields.tituloProtestado, additionalInfos]);

  useEffect(() => {
    globalDispatch(findTipoTituloAction());
  }, [globalDispatch]);


  return (
    <>
      {(errorFields.idTipoTitulo.error || errorFields.numero.error || errorFields.dataVencimento.error
        || errorFields.valorOriginal.error || errorFields.valorProtesto.error) === true ? (
        <CampoObrigatorio />
      ) : (
        ''
      )}
      <WrapperSubTitleForm title={INCLUIR_TITULO} />
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={9}>
          <SelectorField
            isForm
            fullWidth
            label={getRequiredLabel('Tipo', true)}
            items={immutableSelectors.tipoTitulo}
            name="idTipoTitulo"
            value={formFields?.idTipoTitulo}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
            error={errorFields.idTipoTitulo.error}
            errorMessage={errorFields.idTipoTitulo.errorMessage}
          />
        </Grid24>
        <Grid24 item xs={24} sm={9}>
          <InputField
            isForm
            fullWidth
            label={getRequiredLabel('Número', true)}
            name="numero"
            value={formFields?.numero}
            error={errorFields.numero.error}
            errorMessage={errorFields.numero.errorMessage}
            onFocus={onFocusHandler}
            onChange={onChangeHandler}
          />
        </Grid24>
        <Grid24 item xs={24} sm={6}>
          <DateField
            isForm
            fullWidth
            label={getRequiredLabel('Vencimento', true)}
            name="dataVencimento"
            value={formFields?.dataVencimento}
            error={errorFields.dataVencimento.error}
            errorMessage={errorFields.dataVencimento.errorMessage}
            onFocus={onFocusHandler}
            onChange={onChangeHandler}
          />
        </Grid24>

      </Grid24>
      <Grid24 container isFormContainer spacing="1" style={{ marginTop: '15px' }}>
        <Grid24 item xs={24} sm={6}>
          <MoneyField
            isForm
            fullWidth
            label={getRequiredLabel('Valor Original', true)}
            name="valorOriginal"
            value={formFields?.valorOriginal || ''}
            error={errorFields.valorOriginal.error}
            errorMessage={errorFields.valorOriginal.errorMessage}
            onFocus={onFocusHandler}
            onChange={onChangeHandler}
          />
        </Grid24>
        <Grid24 item xs={24} sm={6}>
          <MoneyField
            isForm
            fullWidth
            disabled={isDisableValorProtesto}
            label="Valor Protesto"
            name="valorProtesto"
            value={formFields?.valorProtesto || ''}
            error={errorFields.valorProtesto.error}
            errorMessage={errorFields.valorProtesto.errorMessage}
            onFocus={onFocusHandler}
            onChange={onChangeHandler}
          />
        </Grid24>
        <Grid24 item xs={24} sm={10}>
          <Grid24 item xs={24} sm={24} className={style.grid__radioButton}>
            <ChooseField
              label={TITULO_PROTESTADO}
              name="tituloProtestado"
              value={formFields?.tituloProtestado || ''}
              onChange={changeTitulosProtestadosHandler}
            >
              <RadioField value="S" label="Sim" />
              <RadioField value="N" label="Não" />
            </ChooseField>
          </Grid24>
        </Grid24>
      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
        <Button
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          onClick={onClickSubmitTitulo}
        >
          Confirmar
        </Button>
      </div>
    </>
  );
}
export default memo(DialogTitulo);
