import React from 'react';
import { FaSearchPlus } from 'react-icons/fa';
import Grid24 from '../../../components/UI/Grid24';
import DadosAdvogadoCliente from './Advogado/DadosAdvogadoCliente';
import EnderecoCliente from './Endereco/EnderecoCliente';
import IdentificacaoCliente from './Identificacao/IdentificacaoCliente';
import useStyles, { BotaoInspecionarCliente } from './DadosClientes.styles';
import FormWrapperWithTitle from '../../../components/UI/Wrapper/Form/FormWrapperWithTitle';
import EmailsProvisorio from '../../../components/UI/EmailsProvisorio/EmailsProvisorio';
import TelefoneDadosCliente from './Telefone/TelefoneDadosCliente';
import { hasUserPermissions } from '../../../helpers/utils/permissoes';


function DadosClienteProcessoComponent({ cliente, podeModificarFase }) {
  const styles = useStyles();

  return (
    <Grid24 isFormContainer spacing="1">
      <Grid24 xs={24} sm={24} className={styles.buttonInspecionar}>
        <BotaoInspecionarCliente
          disabled={!(podeModificarFase && hasUserPermissions(501, 504))}
          variant="outlined"
          onClick={() => window.open(`/cliente/${cliente?.idCliente}/detalhar`, '_blank')}
        >
          <FaSearchPlus style={{ marginRight: '9px', fontSize: '14px' }} />
          <span>Inspecionar Cliente</span>
        </BotaoInspecionarCliente>
      </Grid24>
      <Grid24 xs={24} sm={24} spacing="1">
        <IdentificacaoCliente
          cliente={cliente}
        />
      </Grid24>
      <Grid24 xs={24} sm={24} spacing="1">
        <EnderecoCliente
          cliente={cliente}
        />
      </Grid24>
      <Grid24 container className={styles.grid__telefone}>
        <FormWrapperWithTitle title="Telefones">
          <TelefoneDadosCliente telefones={cliente.telefones} />
        </FormWrapperWithTitle>
      </Grid24>
      <Grid24 container className={styles.grid__email}>
        <FormWrapperWithTitle>
          <EmailsProvisorio
            className={styles.margin}
            emails={cliente?.emails}
            baseName
            disabled
          />
        </FormWrapperWithTitle>
      </Grid24>
      <Grid24 xs={24} sm={24} spacing="1">
        <DadosAdvogadoCliente
          cliente={cliente}
        />
      </Grid24>
    </Grid24>
  );
}


export const DadosClienteProcesso = React.memo(DadosClienteProcessoComponent);
