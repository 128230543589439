import { makeStyles } from '@material-ui/core/styles';
import { black16 } from '../../../../helpers/constants/colors.constants';

export default makeStyles({
  wrapper: {

    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginLeft: '29px',
    marginRight: '29px',
    marginBottom: '10px',
    '& span': {
      fontWeight: 'bold',
    },
    '& p': {
      fontSize: '0.865rem !important',
      lineHeight: 1,
    },
  },
  root: {
    '& hr': {
      border: `1px solid ${black16}`,
    },
  },
});
