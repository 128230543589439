import { getAttributeExistingObject, buildPayload } from '../utils/utils';
import FieldModel, { POSITIVE_INTEGER } from '../../models/FieldModel';

export function buildEndereco(endereco) {
  return {
    idEndereco: new FieldModel({
      name: 'idEndereco', value: getAttributeExistingObject(endereco, 'idEndereco'),
    }),
    idEntidadeEndereco: new FieldModel({
      name: 'idEntidadeEndereco', value: getAttributeExistingObject(endereco, 'idEntidadeEndereco'),
    }),
    estado: new FieldModel({
      name: 'estado', value: getAttributeExistingObject(endereco, 'estado'),
    }),
    endereco: new FieldModel({
      name: 'endereco',
      value: getAttributeExistingObject(endereco, 'endereco'),
      maxLength: 75,
    }),
    numero: new FieldModel({
      name: 'numero',
      value: getAttributeExistingObject(endereco, 'numero'),
      maxLength: 9,
      type: POSITIVE_INTEGER,
    }),
    complemento: new FieldModel({
      name: 'complemento',
      value: getAttributeExistingObject(endereco, 'complemento'),
      maxLength: 20,
    }),
    bairro: new FieldModel({
      name: 'bairro',
      value: getAttributeExistingObject(endereco, 'bairro'),
      maxLength: 75,
    }),
    cidade: new FieldModel({
      name: 'cidade',
      value: getAttributeExistingObject(endereco, 'cidade'),
      maxLength: 30,
    }),
    cep: new FieldModel({
      name: 'cep',
      value: getAttributeExistingObject(endereco, 'cep'),
      maxLength: 8,
    }),
    principal: new FieldModel({
      name: 'principal',
      value: getAttributeExistingObject(endereco, 'principal', false),
    }),
  };
}

export function buildEnderecos(enderecos) {
  if (!enderecos) {
    return [buildEndereco({ principal: true })];
  }

  return enderecos
    .sort((origin, to) => to.principal - origin.principal)
    .map(buildEndereco);
}

export function buildEnderecoPayload(endereco, withPrincipal = false) {
  const enderecoPayload = buildPayload(endereco);
  if (!withPrincipal) {
    enderecoPayload.principal = undefined;
    const attributesEndereco = Object.keys(enderecoPayload).filter(key => enderecoPayload[key] !== undefined);
    return attributesEndereco.length > 0 ? enderecoPayload : undefined;
  }
  return enderecoPayload;
}
