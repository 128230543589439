import { getUriParams } from '../../../helpers';
import { CORE, gateway } from '../../api';

export default {
  findRpsEmitidos(
    pageable,
    order,
  ) {
    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    const queryParam = getUriParams(pageable);
    return gateway.post(`${CORE}/conta-corrente-cliente/recebimento/buscar-rps-emitidos?${queryParam}${sortedQuery}`);
  },

  findAllRpsEmitidos(
    order,
  ) {
    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    return gateway.post(`${CORE}/conta-corrente-cliente/recebimento/buscar-rps-emitidos?size=${Number(2147483647)}${sortedQuery}`);
  },

  findIdsRpsEmitidos() {
    return gateway.get(`${CORE}/conta-corrente-cliente/recebimento/ids-rps-emitidos`);
  },

  salvarAlteracoesDasInformacoesClienteHandler(
    formFields,
    idPrestacaoSelecionada,
    idClienteSelecionado,
  ) {
    const body = {
      idPrestacao: idPrestacaoSelecionada,
      codCliente: idClienteSelecionado,
      informacaoExtra: formFields.informacaoExtra.value,
      informacoesComplementares: formFields.informacoesComplementares.value,
    };
    return gateway.put(`${CORE}/conta-corrente-cliente/recebimento/informacoes-especificas`, body);
  },

  cancelarRpsEmitido(
    idPrestacao,
    idCliente,
  ) {
    return gateway.delete(`${CORE}/conta-corrente-cliente/recebimento/deletar/rps-emitidos?idPrestacao=${idPrestacao}&codCliente=${idCliente}`);
  },

  gerarNotaFiscal(
    payload,
  ) {
    return gateway.post(`${CORE}/conta-corrente-cliente/recebimento/gerar-nota-fiscal`, payload);
  },
};
