import makeStyles from '@material-ui/styles/makeStyles';

import { blue } from '../../../helpers/constants/colors.constants';

export default makeStyles({
  wrapper: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& input': {
      color: 'black',
    },
    '& .MuiInputAdornment-root': {
      color: 'black',
    },
    '& .Mui-focused': {
      color: `${blue}`,
    },
    '& .MuiInput-underline::after': {
      borderBottom: `2px solid ${blue}`,
    },
    '& .MuiInput-underline.Mui-error::after': {
      borderBottom: '2px solid red',
    },
  },

  rootBlockLabel: {
    '& .MuiFormLabel-root': {
      fontSize: '.75rem',
    },
    '& .MuiInputLabel-formControl': {
      transform: 'none',
    },
  },

  fieldWrapper: {
    width: '100%',
  },
  disableOnlyRead: {
    color: 'black',
  },
});
