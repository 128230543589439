import React from 'react';
import { useDispatch } from 'react-redux';
import withTokenInterceptor from '../../../../hoc/withTokenInterceptor/withTokenInterceptor';
import RecebimentoClienteProvider from '../../../../store/Recebimento/RecebimentoClienteProvider';
import { initializePageAction } from '../../../../store/theme.actions';
import { RecebimentoCliente } from './RecebimentoCliente';


function RecebimentoClientesPage() {
  const dispatch = useDispatch();

  dispatch(initializePageAction('Recebimento de Clientes'));
  return (
    <RecebimentoClienteProvider>
      <RecebimentoCliente />
    </RecebimentoClienteProvider>
  );
}

export default withTokenInterceptor(RecebimentoClientesPage);
