import { TableCell, TableRow, Tooltip } from '@material-ui/core';
import React from 'react';
import { MdDeleteForever, MdEdit } from 'react-icons/md';
import { TableActionButton, TableCellCenter, TableCellRight } from '../UI/Tabela/tableDefaultStyles';
import { LockOpenIcon } from '../../assets/Icons/AttachFileIcon/lockOpen';
import {
  CustomTableActionButtomNaoPrestados,
  FaLockIcon,
} from '../../pages/ContaCorrenteCliente/Accordions/NaoPrestados/LancamentosNaoPrestadosStyle';
import TooltipUI from '../UI/Tooltip/Tooltip';

const statusLancamentoBloqueado = {
  BLOQUEADO: {
    icon: <FaLockIcon />,
    tooltip: 'Desbloquear Lançamento',
    type: 'normal',
    isCustom: true,
  },
  NAO_PRESTADO: {
    icon: <LockOpenIcon size={15} />,
    tooltip: 'Bloquear Lançamento',
    type: 'custom',
    isCustom: false,
  },
};

function TableBodyLancamentosNaoPrestadosComponent({
  lancamento,
  disabledPermissao,
  openDialogDelete,
  openDialogAtualizarStatus,
  onEditLancamentoHandler,
}) {
  const statusContaCorrenteCliente = lancamento.statusContaCorrenteCliente === 'NAO_PRESTADO' ? 'BLOQUEADO' : 'NAO_PRESTADO';

  return (
    <TableRow>
      <TableCellCenter>
        {lancamento.data}
      </TableCellCenter>
      <TableCellCenter>
        {lancamento.idProcesso}
      </TableCellCenter>
      <TableCellCenter>
        {lancamento.tipoDescricao}
      </TableCellCenter>
      <TableCell>
        <TooltipUI title={lancamento.historicoAlt}>
          <span>{lancamento?.historico}</span>
        </TooltipUI>
      </TableCell>
      <TableCellRight>
        {lancamento.valorCredito}
      </TableCellRight>
      <TableCellRight>
        {lancamento.valorDebito}
      </TableCellRight>
      <TableCellCenter>
        {!!statusLancamentoBloqueado && (
          <Tooltip title={statusLancamentoBloqueado[lancamento.statusContaCorrenteCliente]?.tooltip} placement="bottom">
            <CustomTableActionButtomNaoPrestados
              iscustom={statusLancamentoBloqueado[lancamento.statusContaCorrenteCliente]?.isCustom ? 1 : 0}
              disabled={disabledPermissao}
              onClick={() => openDialogAtualizarStatus(lancamento.idContaCorrenteCliente, statusContaCorrenteCliente)}
            >
              <span>
                {statusLancamentoBloqueado[lancamento.statusContaCorrenteCliente]?.icon}
              </span>
            </CustomTableActionButtomNaoPrestados>
          </Tooltip>
        )}
        <Tooltip title="Editar" placement="bottom">
          <span>
            <TableActionButton
              disabled={disabledPermissao}
              onClick={() => onEditLancamentoHandler(lancamento.idContaCorrenteCliente)}
            >
              <MdEdit size={20} />
            </TableActionButton>
          </span>
        </Tooltip>
        <Tooltip title="Excluir" placement="bottom">
          <span>
            <TableActionButton
              onClick={() => openDialogDelete(lancamento.idContaCorrenteCliente)}
              disabled={disabledPermissao}
            >
              <MdDeleteForever size={20} />
            </TableActionButton>
          </span>
        </Tooltip>
      </TableCellCenter>
    </TableRow>
  );
}

export const TableBodyLancamentosNaoPrestados = TableBodyLancamentosNaoPrestadosComponent;
