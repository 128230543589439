import { getMoneyMask, toBrazilianDate, TableUtils } from '../../../helpers';
import { buscarAntecedentesDoProcessoService, buscarIdsAntecedentesDoProcessoService } from '../../../services';
import { tiposConsultasAntecedentesTypes } from '../../../types';

class ShowResumoProcesso {
  constructor(type) {
    this.type = type || tiposConsultasAntecedentesTypes.simples;
  }

  changeType(type) {
    const newType = this.type === type ? tiposConsultasAntecedentesTypes.simples : type;
    return new ShowResumoProcesso(newType);
  }

  getTitle() {
    return {
      text: this.type === tiposConsultasAntecedentesTypes.unificar ? 'Unificar Processo' : 'Juntar Processo',
      hint: this.type === tiposConsultasAntecedentesTypes.unificar
        ? 'A ação da Unificação será inserida no Acompanhamento do Processo' : 'A ação de Juntar será inserida no Acompanhamento do Processo',
    };
  }

  getLabelButton() {
    return this.type === tiposConsultasAntecedentesTypes.unificar ? 'Unificar' : 'Juntar';
  }

  isOpen() {
    return this.type === tiposConsultasAntecedentesTypes.unificar || this.type === tiposConsultasAntecedentesTypes.juntar;
  }

  isUnificar() {
    return this.type === tiposConsultasAntecedentesTypes.unificar;
  }

  isJuntar() {
    return this.type === tiposConsultasAntecedentesTypes.juntar;
  }

  hasType() {
    return this.type !== tiposConsultasAntecedentesTypes.simples;
  }

  join(ids = []) {
    const { length } = ids;
    return ids.reduce((acc, id, index) => {
      if (index + 1 === length && length > 1) {
        return `${acc} e ${id}`;
      }

      if (acc) {
        return `${acc}, ${id}`;
      }

      return `${id}`;
    }, '');
  }

  getMessage(ids = []) {
    const oldIdProcesso = ids.sort()[0];
    if (this.isJuntar()) {
      return `Os Processos ${this.join(ids)} serão juntados no Processo de nº ${oldIdProcesso}. Confirma esta operação?`;
    }
    if (this.isUnificar()) {
      return `Os Processos ${this.join(ids)} serão unificados no Processo de nº ${oldIdProcesso}. Confirma esta operação?`;
    }
    return '';
  }
}

export class AntecedentesProcessosFactory {
  static makeShowResumoProcesso(type) {
    return new ShowResumoProcesso(type);
  }

  static makeAntecedentes(httpResponse) {
    return TableUtils.makeTableItems({
      httpResponse,
      makeContentFactory: antecedente => ({
        id: antecedente.idProcesso,
        checked: false,
        numero: antecedente.idProcesso,
        fase: antecedente.nomeFase,
        negociador: antecedente.nomeUsuario,
        dataAbertura: toBrazilianDate(antecedente.dataAbertura, true),
        cliente: antecedente.nomeCliente,
        devedor: antecedente.nomeDevedor,
        acordoAtivo: antecedente.temAcordoAtivo,
        valorProcesso: getMoneyMask(antecedente.valorTotalCalculado, false),
      }),
    });
  }
}

export class AntecedentesProcessosController {
  static async buscarAntecedentesEIdsAntecedentes(idProcesso, tiposConsultas = tiposConsultasAntecedentesTypes.simples) {
    const [antecedentes, idsAntecedentes] = await Promise.all([
      buscarAntecedentesDoProcessoService({ idProcesso, tiposConsultasAntecedentes: tiposConsultas }),
      buscarIdsAntecedentesDoProcessoService(idProcesso, tiposConsultas),
    ]);

    const idsAntecedenteProcesso = TableUtils.makeAllIdsSelections([idProcesso, ...idsAntecedentes.data]);
    idsAntecedenteProcesso.set(idProcesso, true);

    return {
      antecedentes: AntecedentesProcessosFactory.makeAntecedentes(antecedentes.data),
      idsAntecedentes: idsAntecedenteProcesso,
    };
  }

  static async buscarAntecedentesProcesso({
    idProcesso,
    pageable = { page: 0, size: 10 },
    ordenacao = ['idProcesso,ASC'],
    tipoConsulta = tiposConsultasAntecedentesTypes.simples,
  }) {
    const antecedentes = await buscarAntecedentesDoProcessoService({
      idProcesso, pageable, ordenacao, tiposConsultasAntecedentes: tipoConsulta,
    });
    return AntecedentesProcessosFactory.makeAntecedentes(antecedentes.data);
  }
}
