import React, { useCallback, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { toast } from 'react-toastify';
import useStyles from './RelacaoPrestacaoStyle';
import PeriodField from '../../../../components/UI/Field/Period/PeriodField';
import {
  getNewPage, toastUnmappedException, openPageFile, getFieldName, useUtilStyles,
} from '../../../../helpers/index';
import { ButtonPesquisar } from '../../contaCorrenteClienteStyles';
import SwitchUI from '../../../../components/UI/Switch/SwitchUI';
import { Loading } from '../../../../components';
import CardResumo from './Cards/CardResumo';
import TabelaRelacaoPrestacao from './Tabela/TabelaRelacaoPrestacao';
import { relacaoPrestacaoController as controller } from './relacaoPrestacaoController';
import FieldModel from '../../../../models/FieldModel';
import LoadingUI from '../../../../components/UI/Loading/LoadingUI';
import { RESULT_NOT_FOUND } from '../../../../helpers/constants/global.constants';
import OptionDialogNew from '../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';


function getDetailsCard(details = []) {
  return details.map(info => ({
    label: info.label,
    value: info.value,
  }));
}

function RelacaoPrestacao({ clienteSelecionado = null, permissoes, nome }) {
  const utilStyles = useUtilStyles();


  const [idPrestacaoSelecTable, setIdPrestacaoSelecTable] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);
  const [conteudoTabela, setConteudoTabela] = useState([]); // State que vai atualizar quando tiver dadosPesquisa...
  const [dadosPesquisa, setDadosPesquisa] = useState(controller.makeDefaultDadosPesquisa()); // Referente a paginação e conteúdo...
  const [saldos, setSaldos] = React.useState(controller.makeResumo());
  const [openDialogCancelPrestacao, setOpenDialogCancelPrestacao] = React.useState(false);
  const [openDialogEstornoPrestacao, setOpenDialogEstornoPrestacao] = React.useState(false);


  const [defaultFormFields] = useState(controller.makeDefaultFormFields());
  const [formFields, setFormFields] = useState(controller.makeFormFields());
  const [arrowOrdenacao, setArrowOrdenacao] = useState(false);
  const [columnOrdenacao, setColumnOrdenacao] = useState({
    element: 'idPrestacao',
    order: 'DESC',
  });

  // @TODO futuramente esse state vai ter que ser pego do context.
  const [relacaoSelecionada, setRelacaoSelecionada] = useState(null); // Radio button selecionado atualmente...
  const [disabled, setDisabled] = useState(false); // Radio button selecionado atualmente...

  useEffect(() => {
    setDisabled(clienteSelecionado?.idCliente === null);
  }, [clienteSelecionado]);


  useEffect(() => {
    setFormFields(old => ({
      ...old,
      periodoInicial: new FieldModel({ ...old.periodoInicial, disabled }),
      periodoFinal: new FieldModel({ disabled }),
    }));
  }, [disabled]);

  const onChangeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    setFormFields(old => ({ ...old, [name]: old[name]?.onChange(value) }));
  }, []);

  const onFocusFieldsHandler = useCallback(() => {
    setFormFields(old => ({
      ...old,
      periodoInicial: new FieldModel({ ...old.periodoInicial, error: false, errorMessage: '' }),
      periodoFinal: new FieldModel({ ...old.periodoFinal, error: false, errorMessage: '' }),
    }));
  }, []);

  function handleOnClickCancelPrestacao() {
    setIdPrestacaoSelecTable(null);
    setOpenDialogCancelPrestacao(false);
  }

  function handleOnClickCancelEstorno() {
    setIdPrestacaoSelecTable(null);
    setOpenDialogEstornoPrestacao(false);
  }

  function handleOpenCancelPrestacao(idPrestacao) {
    setIdPrestacaoSelecTable(idPrestacao);
    setOpenDialogCancelPrestacao(true);
  }

  function handleOpenEstornoPrestacao(idPrestacao) {
    setIdPrestacaoSelecTable(idPrestacao);
    setOpenDialogEstornoPrestacao(true);
  }

  function failureRequest() {
    toast.error('Data ou Período inválido.', { style: { width: '392px' } });
    setFormFields(old => ({
      ...old,
      periodoInicial: new FieldModel({
        ...old.periodoInicial,
        error: true,
        errorMessage: 'Data ou Período inválido.',
      }),
      periodoFinal: new FieldModel({ ...old.periodoFinal, error: true, errorMessage: 'Data ou Período inválido.' }),
    }));
  }

  function successRequest(tableResponse, saldosResponse) {
    setDadosPesquisa(tableResponse);
    setRelacaoSelecionada(tableResponse?.content[0]?.idPrestacao); // Marcar o primeiro quando pesquisar...
    setSaldos(controller.makeResumo(saldosResponse.data));
  }

  function emptyRequestResult(showToast = true) {
    if (showToast) {
      toast.warning(RESULT_NOT_FOUND);
    }
    setDadosPesquisa(controller.makeDefaultDadosPesquisa());
    setRelacaoSelecionada(null);
    setSaldos(controller.makeResumo());
  }

  const pesquisarHandler = useCallback(async (idCliente, pageParam = 0, rowsPerPageParam, order) => {
    setLoadingPage(true);
    try {
      const response = await controller.pesquisarRelacoes(idCliente, formFields, pageParam, rowsPerPageParam, order);
      if (response?.content?.length > 0) {
        const saldosResponse = await controller.findSaldosPrestacao(clienteSelecionado?.idCliente, response?.content[0]?.idPrestacao);
        successRequest(response, saldosResponse);
      } else {
        emptyRequestResult(true);
      }
    } catch (exception) {
      if (exception.response.data.validations) {
        failureRequest();
      }
    } finally {
      setLoadingPage(false);
    }
  }, [formFields, clienteSelecionado?.idCliente]);


  const changePageHandler = useCallback((event, newPage) => {
    pesquisarHandler(clienteSelecionado?.idCliente, newPage, dadosPesquisa?.pageable?.rowsPerPage, columnOrdenacao).then();
  }, [pesquisarHandler, clienteSelecionado, dadosPesquisa?.pageable, columnOrdenacao]);

  const changeRowsPerPageHandler = useCallback((event) => {
    const rowsPerPage = event.target.value;
    const page = getNewPage(dadosPesquisa.pageable.rowsPerPage, dadosPesquisa.pageable.page, rowsPerPage);
    const pageable = { page, rowsPerPage };
    setDadosPesquisa(old => ({
      ...old,
      pageable,
    }));
    if (conteudoTabela.length > 0) {
      pesquisarHandler(clienteSelecionado?.idCliente, page, rowsPerPage, columnOrdenacao).then();
    }
  }, [pesquisarHandler, dadosPesquisa.pageable, conteudoTabela, clienteSelecionado, columnOrdenacao]);

  const handlePrintPrestacao = useCallback(async (idPrestacaoContaCliente) => {
    try {
      setLoadingPage(true);
      const httpResponse = await controller.downloadRelatorioPrestacao(idPrestacaoContaCliente);
      if (httpResponse.data.size > 0) {
        openPageFile(httpResponse.data, httpResponse.data.type);
      }
    } catch (err) {
      toastUnmappedException(
        err,
        'Ocorreu um problema ao tentar abrir o relatório',
      );
    } finally {
      setLoadingPage(false);
    }
  }, []);

  const handleChangeChecked = React.useCallback((e) => {
    const name = getFieldName(e.target);
    const { checked } = e.target;
    setFormFields(oldFields => ({ ...oldFields, [name]: oldFields[name].onChange(checked) }));
    pesquisarHandler(clienteSelecionado?.idCliente, dadosPesquisa.pageable.page, dadosPesquisa.pageable.rowsPerPage, columnOrdenacao).then();
  }, [pesquisarHandler, dadosPesquisa.pageable, clienteSelecionado?.idCliente, columnOrdenacao]);

  const findSaldosPrestacao = useCallback(async (relacaoParam) => {
    setLoadingPage(true);
    try {
      const response = await controller.findSaldosPrestacao(clienteSelecionado?.idCliente, relacaoParam);
      setSaldos(controller.makeResumo(response.data));
    } catch (err) {
      toastUnmappedException(err, 'Ocorreu um problema ao tentar consultar os saldos do Resumo da Prestação');
      setSaldos(controller.makeResumo());
    } finally {
      setLoadingPage(false);
    }
  }, [clienteSelecionado]);

  async function handleEnviarEmailPrestacao(idPrestacaoContaCliente) {
    setLoadingPage(true);
    try {
      await controller.enviarEmailPrestacao(idPrestacaoContaCliente);
      toast.success('E-mail enviado com sucesso!');
    } catch (err) {
      toastUnmappedException(err,
        'Erro durante o envio do E-mail');
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    setConteudoTabela(controller.makeConteudoTabela(
      utilStyles,
      dadosPesquisa?.content,
      permissoes,
      handlePrintPrestacao,
      handleOpenCancelPrestacao,
      handleOpenEstornoPrestacao,
      handleEnviarEmailPrestacao,
    ));
  }, [dadosPesquisa, handlePrintPrestacao, permissoes, utilStyles]);

  const onChangeRelacaoSelecionadaHandler = useCallback((event, relacaoParam) => {
    setRelacaoSelecionada(relacaoParam);
    findSaldosPrestacao(relacaoParam).then();
  }, [findSaldosPrestacao]);

  /**
   * Entra sempre quando selecionar um novo cliente.
   * Reseta os campos para os valores default.
   */
  useEffect(() => {
    if (clienteSelecionado?.idCliente) {
      setFormFields(() => ({
        ...defaultFormFields,
        prestacaoAPagar: new FieldModel({ name: 'prestacaoAPagar', value: false, disabled: false }),
      }));
      controller.pesquisarRelacoes(clienteSelecionado?.idCliente, defaultFormFields, 0, 10, { element: 'idPrestacao', order: 'DESC' }).then((response) => {
        if (response?.content?.length > 0) {
          controller.findSaldosPrestacao(clienteSelecionado?.idCliente, response?.content[0]?.idPrestacao).then((saldosResponse) => {
            successRequest(response, saldosResponse);
          });
        } else {
          emptyRequestResult(false);
        }
      });
    } else {
      emptyRequestResult(false);
    }
  }, [clienteSelecionado, defaultFormFields]);

  async function handleCancelarPrestacao() {
    setLoadingPage(true);
    try {
      await controller.cancelarPrestacao(clienteSelecionado?.idCliente, idPrestacaoSelecTable);
      pesquisarHandler(clienteSelecionado?.idCliente, 0, dadosPesquisa?.pageable?.rowsPerPage, columnOrdenacao).then();
    } catch (err) {
      toastUnmappedException(err,
        'Ocorreu um problema ao tentar consolidar lançamentos');
    } finally {
      setLoadingPage(false);
      setIdPrestacaoSelecTable(null);
      setOpenDialogCancelPrestacao(false);
    }
  }

  async function handleEstornarPrestacao() {
    setLoadingPage(true);
    try {
      await controller.estornarPrestacao(clienteSelecionado?.idCliente, idPrestacaoSelecTable);
      pesquisarHandler(clienteSelecionado?.idCliente, 0, dadosPesquisa?.pageable?.rowsPerPage, columnOrdenacao).then();
    } catch (err) {
      toastUnmappedException(err,
        'Ocorreu um problema ao tentar consolidar lançamentos');
    } finally {
      setLoadingPage(false);
      setIdPrestacaoSelecTable(null);
      setOpenDialogEstornoPrestacao(false);
    }
  }

  const styles = useStyles();
  return (
    <div className={styles.body}>
      <LoadingUI show={loadingPage} />
      <OptionDialogNew
        open={openDialogCancelPrestacao}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={handleOnClickCancelPrestacao}
        onClickConfirm={handleCancelarPrestacao}
        text="Deseja realmente cancelar esta Prestação? "
      />
      <OptionDialogNew
        open={openDialogEstornoPrestacao}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={handleOnClickCancelEstorno}
        onClickConfirm={handleEstornarPrestacao}
        text="Deseja realmente estornar esta Prestação? "
      />
      <Loading show={false} />
      <header className={styles.header}>
        <section className={styles.header_nome_cliente}>
          <div>
            <b>
              Cliente:
              {' '}
            </b>
            {clienteSelecionado?.idCliente}
            {clienteSelecionado?.idCliente !== null ? (' - ') : (' ')}
            {nome}
          </div>
        </section>
        <section className={styles.header_filtros_cards}>
          <div className={styles.header_filtro}>
            <PeriodField
              label="Período:"
              firstField={formFields?.periodoInicial.copy()}
              lastField={formFields?.periodoFinal.copy()}
              onChange={onChangeFieldsHandler}
              onFocus={onFocusFieldsHandler}
            />
            <ButtonPesquisar
              type="button"
              onClick={() => pesquisarHandler(clienteSelecionado?.idCliente, 0, dadosPesquisa?.pageable?.rowsPerPage, columnOrdenacao)}
              disabled={disabled}
            >
              <SearchIcon />
            </ButtonPesquisar>
          </div>
          <div className={styles.header_card}>
            <div>
              <h3><b>Resumo da Prestação</b></h3>
            </div>
            <div className={styles.cards_primeira_linha}>
              <CardResumo
                title="Totais"
                value={getDetailsCard([
                  {
                    label: 'Honorários',
                    value: saldos.totalHonorarios,
                  },
                  {
                    label: 'Estornos',
                    value: saldos.totalEstornos,
                  }])}
                isDetail
                bgColor="#FF7B51"
                lineColor="#FF521B"
              />
              <CardResumo
                title="Despesas"
                value={getDetailsCard([
                  {
                    label: 'Não Reemb.',
                    value: saldos.totalDespesasNaoReembolsaveis,
                  },
                  {
                    label: 'Reemb.',
                    value: saldos.totalDespesasReembolsaveis,
                  }])}
                isDetail
                bgColor="#F85861"
                lineColor="#E9303B"
              />
              <CardResumo
                title="Valores"
                value={getDetailsCard([
                  {
                    label: 'Créditos',
                    value: saldos.totalCreditos,
                  },
                  {
                    label: 'Pagamentos',
                    value: saldos.totalPagamentos,
                  },
                  {
                    label: 'Acordos',
                    value: saldos.totalAcordos,
                  }])}
                isDetail
                bgColor="#FF449B"
                lineColor="#E9207E"
              />
            </div>
            <div className={styles.cards_segunda_linha}>
              <CardResumo
                title="Saldo Anterior"
                value={saldos.totalSaldoAnterior}
                isDetail={false}
                bgColor="#c54fd9"
              />
              <CardResumo
                title="Saldo"
                value={saldos.totalSaldo}
                isDetail={false}
                bgColor="#3cb6be"
              />
            </div>
          </div>
        </section>
      </header>
      <main />
      <div>
        <TabelaRelacaoPrestacao
          columns={controller.makeCabecalhoTabela(
            columnOrdenacao,
            setColumnOrdenacao,
            arrowOrdenacao,
            setArrowOrdenacao,
            conteudoTabela.length > 0 ? () => pesquisarHandler(clienteSelecionado?.idCliente, dadosPesquisa.pageable.page, dadosPesquisa.pageable.rowsPerPage, columnOrdenacao) : () => { },
          )}
          rows={conteudoTabela}
          relacaoSelecionada={relacaoSelecionada}
          onChangeRelacaoSelecionada={onChangeRelacaoSelecionadaHandler}
          page={dadosPesquisa.pageable.page}
          rowsPerPage={dadosPesquisa.pageable.rowsPerPage}
          size={dadosPesquisa.totalElements}
          rowsPerPageOptions={[10, 15, 20]}
          onChangePage={changePageHandler}
          onChangeRowsPerPage={changeRowsPerPageHandler}
        />
      </div>
      <footer className={styles.switch}>
        <SwitchUI
          label="Exibir apenas as Prestações a pagar"
          name="prestacaoAPagar"
          disabled={disabled}
          checked={formFields?.prestacaoAPagar.value}
          onChange={handleChangeChecked}
        />
      </footer>
    </div>
  );
}

export default RelacaoPrestacao;
