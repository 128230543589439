import { useDispatch } from 'react-redux';
import React, { useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { initializePageAction } from '../../store/theme.actions';
import withTokenInterceptor from '../../hoc/withTokenInterceptor/withTokenInterceptor';
import InformacoesLancamento from './InformacoesLancamento';

function EditarLancamentoPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  dispatch(initializePageAction('Caixa de Filiais >  Editar Lançamento'));

  const [idFilial, setIdFilial] = useState(null);
  const [idCaixaFilial, setIdCaixaFilial] = useState(null);

  useLayoutEffect(() => {
    if (history.location.state?.idFilial && history.location.state?.idCaixaFilial) {
      setIdFilial(history.location.state?.idFilial);
      setIdCaixaFilial(history.location.state?.idCaixaFilial);
    } else {
      history.goBack();
    }
  }, [history]);
  return (
    <>
      <InformacoesLancamento isEditMode idFilial={idFilial} idCaixaFilial={idCaixaFilial} />
    </>
  );
}

export default withTokenInterceptor(EditarLancamentoPage);
