export function makeEndereco(endereco, numero) {
  if (endereco && (numero !== null || numero !== undefined)) {
    return `${endereco}, ${numero}`;
  }
  return '';
}

export function makeTelefone(ddd, telefone) {
  if (ddd && telefone) {
    return `(${ddd}) ${telefone}`;
  }

  return telefone;
}

export function makeCep(cep) {
  return cep.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2');
}

export function makeCPFOuCNPJ(documento) {
  if (documento && documento.length === 11) {
    return documento
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }

  if (documento && documento.length === 14) {
    return documento
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }

  return (documento && documento) || '';
}

export function makeMoney(value, withCipher = true) {
  const money = value ? value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '';

  if (withCipher) {
    return money;
  }

  return money.replace('R$', '').trim();
}
