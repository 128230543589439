export const CATEGORIAS = {
  ORGAO_PUBLICO: 'ORGAO_PUBLICO',
  OUTROS: 'OUTROS',
  PESSOA_FISICA: 'PESSOA_FISICA',
  PESSOA_JURIDICA: 'PESSOA_JURIDICA',
};

export const STATUS_PROCESSAMENTO_BORDERO = {
  AGUARDANDO_PROCESSAMENTO: 'AGUARDANDO_PROCESSAMENTO',
  EM_PROCESSAMENTO: 'EM_PROCESSAMENTO',
  PROCESSADO: 'PROCESSADO',
  EXCLUIDO: 'EXCLUIDO',
};

export const statusProcessamentoBordero = {
  AGUARDANDO_PROCESSAMENTO: 'Aguardando Processamento',
  EM_PROCESSAMENTO: 'Em Processamento',
  PROCESSADO: 'Processado',
  EXCLUIDO: 'Excluído',
};


export const tipoCalculoEnum = {
  1: { type: 'DIAS', max: 999999, variation: 1 },
  2: { type: 'MONEY', max: 9999999999.99, variation: 0.01 },
  3: { type: 'DIAS_ABERTURA', max: 999999, variation: 1 },
};

export const tipoUsuarioComercialEnum = {
  PRINCIPAL: 'PRINCIPAL',
  TELEMARKETING: 'TELEMARKETING',
  COMERCIAL: 'COMERCIAL',
};
