import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';

function TableHeaderLancamentosComponent() {
  return (
    <TableRow>
      <TableCell height={36} width={80}>
        Data
      </TableCell>
      <TableCell height={36} width={85}>
        Processo
      </TableCell>
      <TableCell height={36} width={167}>
        Tipo do Lançamento
      </TableCell>
      <TableCell height={36} width={501}>
        Histórico
      </TableCell>
      <TableCell height={36} width={158}>
        Créditos
      </TableCell>
      <TableCell height={36} width={133}>
        Débitos
      </TableCell>
    </TableRow>
  );
}

export const TableHeaderLacamentos = TableHeaderLancamentosComponent;
