import React, { useCallback, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import TableHeader from '../../models/TableHeader';
import Table from '../UI/Tabela/PaginadaSimples/Table';
import { LABEL_TABELA } from './logProcessamentoBorderoPage.constants';
import {
  usePesquisarLogProcessamentoDispatch,
  usePesquisarLogProcessamentoSelector,
} from '../../store/LogProcessamento/LogProcessamentoBorderoProvider';
import useStyles from './tabelaLogProcessamento.styles';
import { setPageAction, setRowsPerPageAndPageAction } from '../../store/LogProcessamento/logProcessamentoBordero.store';
import { getDateTimeMask, getNewPage } from '../../helpers/utils/utils';
import { findLogProcessamentoBorderoAction } from '../../store/LogProcessamento/logProcessamentoBordero.saga';
import LogProcessamentoCustomIcon from './LogProcessamentoCustomIcon';

const buildTableHeader = [
  new TableHeader({
    id: 'tipoBorderoLog',
    label: 'Tipo',
    maxWidth: 47,
    alignCell: 'center',
  }),
  new TableHeader({
    id: 'numeroProcesso',
    label: 'Nº do Processo',
    alignCell: 'center',
    maxWidth: 73,
  }),
  new TableHeader({
    id: 'codigoCliente',
    label: 'Código do Cliente',
    alignCell: 'center',
    maxWidth: 73,
  }),
  new TableHeader({
    id: 'nomeDevedor',
    label: 'Nome do Devedor',
    maxWidth: 271,
    hiddenFullLine: true,
  }),
  new TableHeader({
    id: 'mensagem',
    label: 'Mensagem',
    maxWidth: 386,
    hiddenFullLine: true,
  }),
  new TableHeader({
    id: 'dataCadastramento',
    label: 'Data e Hora',
    maxWidth: 116,
    alignCell: 'center',
  }),
];


function buildTableLogProcessamentoBordero(logsProcessamentos) {
  return logsProcessamentos.map(logProcessamento => ({
    id: logProcessamento.idBorderoLog,
    values: {
      tipoBorderoLog: <LogProcessamentoCustomIcon tipoBorderoLog={logProcessamento.tipoBorderoLog} />,
      numeroProcesso: logProcessamento.numeroProcesso,
      codigoCliente: logProcessamento.codigoCliente,
      nomeDevedor: logProcessamento.nomeDevedor,
      mensagem: logProcessamento.mensagem,
      dataCadastramento: getDateTimeMask(logProcessamento.dataCadastramento, false),
    },
  }));
}

function TabelaLogProcessamento({ onClickInLine = null }) {
  const styles = useStyles();
  const dispatch = usePesquisarLogProcessamentoDispatch();

  const settingsTable = usePesquisarLogProcessamentoSelector(states => ({ ...states.settingsTable }));
  const logProcessamentoSearchCriteriasCache = usePesquisarLogProcessamentoSelector(states => states.logProcessamentoSearchCriteriasCache);

  useEffect(() => {
    if (logProcessamentoSearchCriteriasCache) {
      dispatch(findLogProcessamentoBorderoAction(false));
    }
  }, [dispatch, logProcessamentoSearchCriteriasCache, settingsTable.page, settingsTable.rowsPerPage]);

  const changePageHandler = useCallback((event, newPage) => {
    dispatch(setPageAction(newPage));
  }, [dispatch]);

  const changeRowsPerPageHandler = useCallback((event) => {
    const newRowsPerPage = event.target.value;
    const newPage = getNewPage(settingsTable.rowsPerPage, settingsTable.page, newRowsPerPage);

    dispatch(setRowsPerPageAndPageAction(newRowsPerPage, newPage));
  }, [dispatch, settingsTable.page, settingsTable.rowsPerPage]);

  return (
    <Grid container direction="column">
      <Grid item xs={12} sm={12}>
        <Typography component="h3" className={styles.titleTable}>
          {LABEL_TABELA}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} className={styles.div__customTable}>
        <Table
          hover
          columns={buildTableHeader}
          rows={buildTableLogProcessamentoBordero(settingsTable.content)}
          size={settingsTable.size}
          page={settingsTable.page}
          rowsPerPage={settingsTable.rowsPerPage}
          onChangePage={changePageHandler}
          onChangeRowsPerPage={changeRowsPerPageHandler}
          onClickInLine={onClickInLine}
        />
      </Grid>
    </Grid>
  );
}

export default React.memo(TabelaLogProcessamento);
