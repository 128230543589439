import React from 'react';
import SelectorField from '../UI/Field/Selector/SelectorField';
import InputField from '../UI/Field/Input/InputField';
import { EventsCalendarContainer, TrashButton } from './itemEventoCalendarioDashboard.styles';

function ItemEventoCalendarioDashboardComponent({
  eventTypes = [], idx, event, handleChangeField, handleDeleteEvent, handleFocusField,
}) {
  return (
    <EventsCalendarContainer>

      <SelectorField
        data-idx={idx}
        isForm
        fullWidth
        disabled={!event?.salvar.value}
        name="idTipoCalendario"
        label={event?.salvar ? 'Tipo*' : 'Tipo'}
        items={eventTypes}
        value={event?.idTipoCalendario.value}
        error={event?.idTipoCalendario.error}
        errorMessage={event?.idTipoCalendario.errorMessage}
        onChange={handleChangeField}
        onFocus={handleFocusField}
      />
      <InputField
        isForm
        fullWidth
        disabled={!event?.salvar.value}
        name="descricao"
        label={event?.salvar ? 'Título*' : 'Título'}
        maxLength={70}
        value={event?.descricao.value}
        error={event?.descricao.error}
        errorMessage={event?.descricao.errorMessage}
        onChange={handleChangeField}
        onFocus={handleFocusField}
        inputProps={{
          autoComplete: 'off',
        }}
      />

      <div>
        {event?.salvar.value && <TrashButton onClick={handleDeleteEvent} hint="Excluir Evento" />}
      </div>
    </EventsCalendarContainer>
  );
}

export const ItemEventoCalendarioDashboard = ItemEventoCalendarioDashboardComponent;
