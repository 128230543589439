import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WrapperSubTitleForm from '../../../UI/WrapperSubTitleForm/WrapperSubTitleForm';
import Dica from '../../../UI/Dica/Dica';
import DateField from '../../../UI/Field/Date/DateField';
import Radio from '../../../UI/Field/Radio/RadioField';
import ButtonWithIconUI from '../../../UI/Button/Icon/ButtonWithIconUI';
import useStyles from '../relatorioAS.styles';
import { DateUtils } from '../../../../helpers';
import { gerarRelatorioAction } from '../../../../store/AcordoSeguro/Relatorios/relatoriosAS.saga';


function RelatorioNotificados({ onChange, onFocus }) {
  const dispatch = useDispatch();
  const styles = useStyles();

  const [habilitarRelatorioNotificado, setHabilitarRelatorioNotificado] = useState(false);

  const dataInicialNotificado = useSelector(states => states.relatoriosAS.requestFields.dataInicialNotificado.copy());
  const dataFinalNotificado = useSelector(states => states.relatoriosAS.requestFields.dataFinalNotificado.copy());


  // Habilita o botão de gerar relatorio de notificados.
  useEffect(() => {
    if (DateUtils.isAValidDate(dataInicialNotificado.value) && DateUtils.isAValidDate(dataFinalNotificado.value)) {
      setHabilitarRelatorioNotificado(true);
    } else {
      setHabilitarRelatorioNotificado(false);
    }
  }, [dataInicialNotificado.value, dataFinalNotificado.value, setHabilitarRelatorioNotificado]);

  const onClickGerarRelatorio = useCallback(() => {
    dispatch(gerarRelatorioAction(false));
  }, [dispatch]);

  return (
    <div className={styles.div__container}>
      <div className={styles.div__subTitle}>
        <WrapperSubTitleForm title="Notificações" className={styles.subTitleNotificacao} />
        <Dica mensagemDica="Período máximo 30 dias" />
      </div>
      <div className={styles.div__inputFieldsRoot}>
        <div className={styles.div__dataDistance}>
          <DateField
            isForm
            name={dataInicialNotificado.name}
            value={dataInicialNotificado.value}
            error={dataInicialNotificado.error}
            errorMessage={dataInicialNotificado.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </div>
        <div>
          <DateField
            isForm
            name={dataFinalNotificado.name}
            value={dataFinalNotificado.value}
            error={dataFinalNotificado.error}
            errorMessage={dataFinalNotificado.errorMessage}
            onChange={onChange}
            onFocus={onFocus}
          />
        </div>
      </div>
      <div className={styles.div__checkboxRoot}>
        <Radio label="PDF" checked />
        <Radio disabled label="Planilha" />
      </div>
      <div className={styles.div__buttonRoot}>
        <ButtonWithIconUI
          label="Gerar Relatório"
          iconFontAwesome="fas fa-file-download"
          className={styles.btn__gerarRelatorio}
          disabled={!habilitarRelatorioNotificado}
          onClick={onClickGerarRelatorio}
        />
      </div>
    </div>
  );
}

export default RelatorioNotificados;
