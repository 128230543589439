import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PESQUISAR_INFO_PROCESSO } from './PagamentoDireto.constants';
import { setPassoUmDataAction, setSimplePagamentoDiretoStateAction } from './PagamentoDireto.store';
import { buscarProcessoPassoUmPagamentoDireto } from '../../services/core/pagamentoDireto/pagamentoDireto.service';

export const PesquisarInfoProcessoAction = () => ({
  type: PESQUISAR_INFO_PROCESSO,
});

function* PesquisarInfoProcessoHandler() {
  yield put(setSimplePagamentoDiretoStateAction('loadingPage', true));
  const { numProcesso } = yield select(state => state.controls.passoUm);
  try {
    const response = yield call(buscarProcessoPassoUmPagamentoDireto, numProcesso.value);
    yield put(setPassoUmDataAction(response.data));
    yield put(setSimplePagamentoDiretoStateAction('disabledProximoPassoUm', false));
  } catch (e) {
    yield put(setSimplePagamentoDiretoStateAction('openDialogErroPassoUm', true));
    yield put(setSimplePagamentoDiretoStateAction('messageDialogErroPassoUm', e.response.data.message));
    yield put(setSimplePagamentoDiretoStateAction('disabledProximoPassoUm', true));
  } finally {
    yield put(setSimplePagamentoDiretoStateAction('loadingPage', false));
  }
}

export default function* watchPagamentoDireto() {
  yield takeLatest(PESQUISAR_INFO_PROCESSO, PesquisarInfoProcessoHandler);
}
