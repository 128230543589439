import { getUriParams } from '../../../helpers';
import { gateway, gatewayShortJson } from '../../api';

const CORE = process.env.REACT_APP_CORE;

export default {
  findAllUsuarioComPermissaoConsultaDinamica(idConsultaDinamica) {
    return gatewayShortJson.get(`${CORE}/usuarios/consulta-dinamica/${idConsultaDinamica}`);
  },

  findAllListConsultaDinamica(pageable, searchManagerListPayload, order) {
    const sortedQuery = order.reduce((obj, sort) => (
      `${obj}&sort=${sort}`
    ), '');

    const quertParams = getUriParams({ ...pageable, ...searchManagerListPayload });
    return gateway.get(`${CORE}/consulta-dinamica?${sortedQuery}&${quertParams}`);
  },

  findSummaryConsultaDinamica(idConsultaDinamica) {
    return gateway.get(`${CORE}/consulta-dinamica/${idConsultaDinamica}`);
  },

  findAllResponsaveis() {
    return gatewayShortJson.get(`${CORE}/consulta-dinamica/usuario`);
  },

  executeConsultaDinamica(pageable, idConsultaDinamica, parametros = []) {
    return gateway.post(`${CORE}/consulta-dinamica`, { idConsultaDinamica, parametros }, { params: pageable });
  },

  exportConsultaDinamica(type, payload) {
    return gateway.post(`${CORE}/consulta-dinamica/exportar`, payload, {
      params: {
        tipoExportacaoEnum: type,
      },
      responseType: 'blob',
    });
  },

  updateConsultaDinamica(payload) {
    return gateway.put(`${CORE}/consulta-dinamica`, payload);
  },
};
