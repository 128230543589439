import { useState } from 'react';
import {
  CHECKED_TYPE,
  getValidTextFieldPositiveInteger,
  getValidTextFieldWithMaxLength,
  isValidRequiredField,
  isValidEmailField,
  isValidMaxLength,
} from '../../helpers/validators/validators';

/**
 * {
 *    [nameHtmlField]: {
 *      value: ''
 *      type: 'checked' | 'selector'
 *      valid: true,
 *      required: true,
 *      changeAttr: {
 *        maxLength: 25,
 *        positiveInteger: true
 *      },
 *      blurAttr: {
 *        email: true,
 *      }
 *    },
 * }
 *
 */

const getValue = (type, event) => {
  if (type && type === CHECKED_TYPE) {
    return event.target.checked;
  }
  return event.target.value;
};

export default function useFormObject(
  initialStates = {}, handleErrors, realTimeValidation = false, handleBlur,
) {
  const [values, setValues] = useState(initialStates);

  const getValidField = (target) => {
    let field = { ...values[target] };
    let isValid = true;
    field.error = isValidRequiredField(field, true);
    if (field.blurAttr && !field.error) {
      isValid = isValidEmailField(field.blurAttr.email, field.value, isValid);
      isValid = isValidMaxLength(field.blurAttr.maxLength, field.value, isValid);
      field.error = isValid;
    }
    if (handleBlur) {
      field = handleBlur(field, target, realTimeValidation);
    }
    return field;
  };

  const getNewValuesFormValidation = (keys, submited) => {
    let newValues = {};
    keys.forEach((key) => {
      const field = getValidField(key, submited);
      newValues = {
        ...newValues,
        [key]: { ...field },
      };
    });
    return newValues;
  };

  const updateValues = (newValues) => {
    handleErrors(newValues, realTimeValidation);
    setValues(newValues);
  };

  const onChange = (event) => {
    const field = { ...values[event.target.name] };
    field.value = getValue(field.type, event);
    if (field.changeAttr) {
      field.value = getValidTextFieldWithMaxLength(field.changeAttr.maxLength, field.value);
      field.value = getValidTextFieldPositiveInteger(field.changeAttr.positiveInteger, field.value);
    }
    setValues({
      ...values,
      [event.target.name]: { ...field },
    });
  };

  const onBlur = (event) => {
    const target = event.target.name;
    const field = getValidField(target, realTimeValidation);
    const newValues = {
      ...values,
      [target]: field,
    };
    updateValues(newValues);
  };

  const isValidForm = (submited) => {
    const keys = Object.keys(values);
    const newValues = getNewValuesFormValidation(keys, submited);
    updateValues(newValues);
    return !keys.some(key => newValues[key].error);
  };

  return {
    values, setValues, onChange, onBlur, isValidForm,
  };
}
