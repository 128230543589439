import { Typography } from '@material-ui/core';
import React from 'react';
import { TitleContainer } from './SimpleTitle.styles';

function SimpleTitleComponent({ text }) {
  return (
    <TitleContainer>
      <Typography component="h3">{text}</Typography>
    </TitleContainer>
  );
}

export const SimpleTitle = SimpleTitleComponent;
