import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import Titulos from '../../../UI/Titulos/Titulos';

import {
  useCrudBoderoDispatch, useCrudBorderoSelector,
} from '../../../../store/Borderos/Crud/CrudBorderoProvider';
import {
  setObjectsAction, setObjectFocusAction, setObjectAttributeAction,
} from '../../../../store/Borderos/Crud/CrudBordero.store';

import useUtilStyles from '../../../../helpers/styles/utils.styles';

const TITULOS = 'titulos';

export default function TitulosCrudBordero({ isInspectMode, disabled }) {
  const utilStyles = useUtilStyles();
  const dispatch = useCrudBoderoDispatch();
  const titulos = useCrudBorderoSelector(states => [...states.requestFields.titulos]);

  const tiposTitulo = useSelector(states => states.selectors.tiposTitulo.selector);

  const setAttributeHandler = useCallback((name, value, index) => {
    dispatch(setObjectAttributeAction(TITULOS, name, value, index));
  }, [dispatch]);

  const setFocusHandler = useCallback((name, index) => {
    dispatch(setObjectFocusAction(TITULOS, name, index));
  }, [dispatch]);

  const setTitulosHandler = useCallback((titulosParam) => {
    dispatch(setObjectsAction(TITULOS, titulosParam));
  }, [dispatch]);

  const getRequiredFields = () => {
    if (isInspectMode) {
      return {};
    }
    return {
      tipoDocumento: true,
      numero: true,
      dataVencimento: true,
      valor: true,
    };
  };

  return (
    <Titulos
      className={utilStyles.mt16}
      titulos={titulos}
      disabled={isInspectMode || disabled}
      readMode={isInspectMode}
      tiposDocumento={tiposTitulo}
      setAttribute={setAttributeHandler}
      setFocus={setFocusHandler}
      setTitulos={setTitulosHandler}
      requiredFields={getRequiredFields()}
    />
  );
}
