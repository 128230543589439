import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FaExclamationTriangle } from 'react-icons/fa';
import SelectorField from '../../../../../UI/Field/Selector';
import Grid24 from '../../../../../UI/Grid24';
import FormWrapperWithTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import {
  getRequiredLabel, useUtilStyles,
} from '../../../../../../helpers';
import InputField from '../../../../../UI/Field/Input';
import { useCrudClienteSelector } from '../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import DateField from '../../../../../UI/Field/Date/DateField';
import useStyle, { WarningContainer } from './stylesCampanha';
import NumberInput from '../../../../../UI/Field/Number/NumberInput';
import ButtonUI from '../../../../../UI/Button/ButtonUI';

import { onChangeCampanha, onChangeCampanhaGeral, onFocusCampanha } from '../../../../../../store/Cliente/V2/Crud/crudCliente.store';
import { buildCampanha } from '../../../../../../helpers/factory/campanha.factory';


function Campanha({ disabled, dispatch }) {
  const utilStyle = useUtilStyles();
  const style = useStyle();

  const campanha = useCrudClienteSelector(states => states.controls.campanha);
  const tiposDescontosCliente = useSelector(states => states.selectors.tipoDescontoCliente.selector);

  const onChangeHandler = useCallback(
    (event) => {
      const { name, value } = event.target;
      dispatch(onChangeCampanha(name, value));
    }, [dispatch],
  );

  const newOrCancelCampaign = useCallback((value) => {
    if (value) {
      dispatch(onChangeCampanhaGeral(buildCampanha(undefined)));
    } else {
      dispatch(onChangeCampanhaGeral(null));
    }
  }, [dispatch]);

  const onFocusCampanhaHandler = useCallback((event) => {
    const { name } = event.target;
    dispatch(onFocusCampanha(name));
  }, [dispatch]);

  return (
    <Grid24>
      <Grid24 xs={24} sm={24}>
        <FormWrapperWithTitle
          title="Campanha"
        >
          {campanha !== null && campanha !== undefined
            ? (
              <div>
                <Grid24 xs={24} sm={24} spacing="1" className={utilStyle.mt10}>
                  <Grid24 xs={24} sm={7}>
                    <InputField
                      fullWidth
                      isForm
                      disabled={disabled}
                      label={getRequiredLabel('Nome da Campanha', !disabled)}
                      name={campanha.nome.name}
                      value={campanha.nome.value}
                      error={campanha.nome.error}
                      errorMessage={campanha.nome.errorMessage}
                      onChange={onChangeHandler}
                      onFocus={onFocusCampanhaHandler}
                    />
                  </Grid24>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '8px', marginLeft: '4px' }}>
                      <DateField
                        isForm
                        fullWidth
                        disabled={disabled}
                        label={getRequiredLabel('Data início da Campanha', !disabled)}
                        error={campanha.dataInicio.error}
                        name={campanha.dataInicio.name}
                        value={campanha.dataInicio.value}
                        errorMessage={campanha.dataInicio.errorMessage}
                        onChange={onChangeHandler}
                        onFocus={onFocusCampanhaHandler}
                      />
                    </div>
                    <div style={{ marginRight: '8px' }}>
                      <DateField
                        isForm
                        fullWidth
                        disabled={disabled}
                        label={getRequiredLabel('Data Término da Campanha', !disabled)}
                        error={campanha.dataTermino.error}
                        name={campanha.dataTermino.name}
                        value={campanha.dataTermino.value}
                        errorMessage={campanha.dataTermino.errorMessage}
                        onChange={onChangeHandler}
                        onFocus={onFocusCampanhaHandler}
                      />
                    </div>
                    <div style={{ marginRight: '8px' }}>
                      <DateField
                        isForm
                        fullWidth
                        disabled={disabled}
                        label={getRequiredLabel('Data Limite para Acordos', !disabled)}
                        error={campanha.dataLimiteAcordo.error}
                        name={campanha.dataLimiteAcordo.name}
                        value={campanha.dataLimiteAcordo.value}
                        errorMessage={campanha.dataLimiteAcordo.errorMessage}
                        onChange={onChangeHandler}
                        onFocus={onFocusCampanhaHandler}
                      />
                    </div>
                  </div>
                </Grid24>
                <Grid24 xs={24} sm={24} spacing="1" className={utilStyle.mt10}>
                  <Grid24 xs={24} sm={12}>
                    <InputField
                      fullWidth
                      isForm
                      disabled={disabled}
                      label={getRequiredLabel('Descrição', !disabled)}
                      name={campanha.descricao.name}
                      value={campanha.descricao.value}
                      error={campanha.descricao.error}
                      errorMessage={campanha.descricao.errorMessage}
                      onChange={onChangeHandler}
                      onFocus={onFocusCampanhaHandler}
                    />
                  </Grid24>
                  <Grid24 xs={24} sm={4} className={utilStyle.ml2}>
                    <SelectorField
                      isForm
                      disabled={disabled}
                      label={getRequiredLabel('Tipo de Desconto', !disabled)}
                      items={tiposDescontosCliente}
                      name={campanha.idTipoDesconto.name}
                      value={campanha.idTipoDesconto.value}
                      error={campanha.idTipoDesconto.error}
                      errorMessage={campanha.idTipoDesconto.errorMessage}
                      onChange={onChangeHandler}
                    />
                  </Grid24>
                  <Grid24 xs={24} sm={3} className={utilStyle.ml2}>
                    <NumberInput
                      numberType="percentage"
                      acceptEmptyValue
                      maxValue={100}
                      fullWidth
                      disabled={disabled}
                      isForm
                      label={getRequiredLabel('Desconto', !disabled)}
                      sideText="(%)"
                      name={campanha.porcentagemDesconto.name}
                      value={campanha.porcentagemDesconto.value}
                      error={campanha.porcentagemDesconto.error}
                      errorMessage={campanha.porcentagemDesconto.errorMessage}
                      onChange={onChangeHandler}
                    />
                  </Grid24>
                </Grid24>
                <div className={style.div__align}>
                  <div className={style.div__buttons}>
                    <ButtonUI
                      color="white"
                      disabled={disabled}
                      onClick={() => newOrCancelCampaign(false)}
                    >
                      Cancelar Campanha
                    </ButtonUI>
                  </div>
                </div>
              </div>
            )
            : (
              <div style={{ marginTop: '28px' }}>
                <WarningContainer>
                  <FaExclamationTriangle size={48} color="#FFA000" />
                  <p>Não existe Campanha Ativa para o Cliente</p>
                </WarningContainer>
                <div className={style.div__align}>
                  <div className={style.div__buttons}>
                    <ButtonUI
                      color="white"
                      disabled={disabled}
                      onClick={() => newOrCancelCampaign(true)}
                    >
                      Nova Campanha
                    </ButtonUI>
                  </div>
                </div>
              </div>
            )
}
        </FormWrapperWithTitle>
      </Grid24>
    </Grid24>

  );
}

export default React.memo(Campanha);
