import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import DateField from '../../components/UI/Field/Date/DateField';
import {
  ButtonRegistrar,
  Buttons,
  ContainerPrincipal, Filter, Header, TitleTable,
} from './RegistrarBoletoStyles';
import TabelaRegistrarBoleto from './TabelaRegistrarBoleto';
import { registrarBoletoFactory } from './RegistrarBoletoFactory';
import { useRegistrarBoletoDispatch, useRegistrarBoletoSelector } from '../../store/RegistrarBoleto/RegistrarBoletoProvider';
import {
  onChangeDateFieldAction, setSimpleRegistrarBoletoAction, setSimpleValuesListIdsAction,
} from '../../store/RegistrarBoleto/RegistrarBoleto.store';
import {
  buscarDadosTabelaAction, inserirBoletoAction,
} from '../../store/RegistrarBoleto/RegistrarBoleto.saga';
import {
  getNewPage,
} from '../../helpers';
import { SearchButton } from '../../components/SearchButton/SeachButton';
import LoadingUI from '../../components/UI/Loading/LoadingUI';


function RegistrarBoletoComponent() {
  const dispatch = useRegistrarBoletoDispatch();

  const [conteudoTabela, setConteudoTabela] = useState([]);

  const data = useRegistrarBoletoSelector(states => states.requestFields.data);
  const page = useRegistrarBoletoSelector(states => states.page);
  const rowsPerPage = useRegistrarBoletoSelector(states => states.rowsPerPage);
  const totalElements = useRegistrarBoletoSelector(states => states.totalElements);
  const arrowDirection = useRegistrarBoletoSelector(states => states.arrowDirection);
  const columnOrdenacao = useRegistrarBoletoSelector(states => states.columnOrdenacao);
  const tableView = useRegistrarBoletoSelector(states => states.tableView);
  const loadingPage = useRegistrarBoletoSelector(states => states.common.loadingPage);
  const listIds = useRegistrarBoletoSelector(states => states.listIds);
  const selectAll = useRegistrarBoletoSelector(states => states.selectAll);


  const onChangeDateFieldHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeDateFieldAction(name, value));
  }, [dispatch]);

  const onClickPesquisar = useCallback(async () => {
    dispatch(setSimpleRegistrarBoletoAction('page', 0));
    dispatch(setSimpleRegistrarBoletoAction('rowsPerPage', 10));
    dispatch(setSimpleRegistrarBoletoAction('atualizarListaIds', true));
    dispatch(setSimpleRegistrarBoletoAction('selectAll', false));
    dispatch(buscarDadosTabelaAction(true));
  }, [dispatch]);

  const changePageHandler = useCallback((event, newPage) => {
    dispatch(setSimpleRegistrarBoletoAction('page', Number(newPage)));
    dispatch(setSimpleRegistrarBoletoAction('tableView', tableView));
    dispatch(buscarDadosTabelaAction(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const changeRowsPerPageHandler = useCallback((e) => {
    const size = e.target.value;
    const newPage = getNewPage(rowsPerPage, page, size);

    dispatch(setSimpleRegistrarBoletoAction('page', Number(newPage)));
    dispatch(setSimpleRegistrarBoletoAction('rowsPerPage', Number(size)));
    dispatch(setSimpleRegistrarBoletoAction('tableView', tableView));
    dispatch(buscarDadosTabelaAction(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, rowsPerPage, page]);

  const onClickColumnOrdenar = useCallback(() => {
    dispatch(setSimpleRegistrarBoletoAction('page', 0));
    dispatch(buscarDadosTabelaAction(false));
    dispatch(setSimpleRegistrarBoletoAction('tableView', tableView));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const setColumnOrdenacao = useCallback((column) => {
    dispatch(setSimpleRegistrarBoletoAction('columnOrdenacao', column));
    dispatch(setSimpleRegistrarBoletoAction('tableView', tableView));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const setArrowDirection = useCallback((internalReturn) => {
    dispatch(setSimpleRegistrarBoletoAction('arrowDirection', internalReturn));
    dispatch(setSimpleRegistrarBoletoAction('tableView', tableView));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const changeSelectAll = useCallback((e) => {
    const { checked } = e.target;
    dispatch(setSimpleRegistrarBoletoAction('selectAll', checked));
    dispatch(setSimpleRegistrarBoletoAction('listIds', Object.keys(listIds).reduce((acc, key) => ({ ...acc, [key]: checked }), {})));
  }, [listIds, dispatch]);

  const changeSelectId = useCallback((e) => {
    const { name, checked } = e.target;
    dispatch(setSimpleValuesListIdsAction(name, checked));
  }, [dispatch]);

  const onClickRegistrar = useCallback(async () => {
    dispatch(inserirBoletoAction(true));
  }, [dispatch]);


  useEffect(() => {
    setConteudoTabela(registrarBoletoFactory.makeConteudoTabela(
      tableView,
      changeSelectId,
      listIds,
    ));
  }, [tableView, changeSelectId, listIds]);


  useEffect(() => {
    dispatch(buscarDadosTabelaAction(true));
    dispatch(setSimpleRegistrarBoletoAction('tableView', tableView));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <LoadingUI show={loadingPage} />
      <ContainerPrincipal>
        <Header>
          <Filter>
            <p>Exibir boletos emitidos a partir de:</p>
            <div style={{ width: '160px' }}>
              <DateField
                name={data.name}
                value={data.value}
                maxDate={new Date()}
                style={{ marginLeft: '3px' }}
                onChange={onChangeDateFieldHandler}
              />
            </div>
            <div style={{ marginLeft: 15 }}>
              <SearchButton
                onClick={() => onClickPesquisar()}
              >
                <SearchIcon style={{ marginLeft: 2 }} />
              </SearchButton>
            </div>
          </Filter>
        </Header>
        <Buttons>
          <ButtonRegistrar
            disabled={Object.keys(listIds).filter((key => listIds[key] === true)).map(key => Number(key)).length === 0}
            onClick={onClickRegistrar}
          >
            Registrar
          </ButtonRegistrar>
        </Buttons>
        <TitleTable>
          Boletos com Erros
        </TitleTable>
        <TabelaRegistrarBoleto
          columns={registrarBoletoFactory.makeCabecalhoTabela(
            columnOrdenacao, setColumnOrdenacao, arrowDirection, setArrowDirection, onClickColumnOrdenar, changeSelectAll, selectAll,
          )}
          rows={conteudoTabela}
          page={page}
          size={totalElements}
          rowsPerPageOptions={[10, 15, 20]}
          rowsPerPage={rowsPerPage}
          onChangePage={changePageHandler}
          onChangeRowsPerPage={changeRowsPerPageHandler}
        />
      </ContainerPrincipal>
    </>
  );
}

export const RegistrarBoleto = withRouter(RegistrarBoletoComponent);
