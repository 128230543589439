import FieldModel from '../../../../models/FieldModel';
import { alterarParcelaCartao } from '../../../../services/core/processos/processosAcordosService';

class DialogAlterarParcelaCartaoController {
  makeFormFields(dadosParcelaCartao) {
    return ({
      observacaoCartao: new FieldModel({
        name: 'observacaoCartao',
        value: dadosParcelaCartao.observacao || '',
      }),
    });
  }


  makePayload(idProcesso, nroParcela, idAcordo, idAcordoParcela, tipoFormPgtp, formFields) {
    return {
      observacao: formFields.observacaoCartao.value,
      idProcesso,
      nroParcela,
      idAcordo,
      idAcordoParcela,
      tipoFormPgtp,
    };
  }

  async salvar(idProcesso, nroParcela, idAcordo, idAcordoParcela, tipoFormPgtp, formFields) {
    const payload = this.makePayload(idProcesso, nroParcela, idAcordo, idAcordoParcela, tipoFormPgtp, formFields);
    await alterarParcelaCartao(payload);
  }
}

export const dialogAlterarParcelaCartaoController = new DialogAlterarParcelaCartaoController();
