import React, { useEffect, useState } from 'react';
import ButtonUI from '../../../../../../../components/UI/Button/ButtonUI';
import Grid24 from '../../../../../../../components/UI/Grid24/Grid24';
import { SimpleTitle } from '../../../../../../../components/Title/SimpleTitle/SimpleTitle';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { FooterContainer, MainContainer } from './AcaoBloqueioStyle';
import { ActionHeaderContainer } from '../LancamentosNaoPrestadosStyle';
import {
  atualizaStateTiposLancamentosNaoPrestadosClienteSelecionadoAction, pesquisarTiposLancamentosNaoPrestadosClienteSelecionadoAction,
} from '../../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import TiposLancamentosSelector from '../TiposLancamentosSelector/TiposLancamentosSelector';

export default function AcaoBloqueioComponent({
  onSubmitHandler,
}) {
  const dispatch = useCCClienteDispatch();

  const tiposLancamentos = useCCClienteSelector(states => (states.naoPrestados.tiposLancamentos));
  const clienteSelecionado = useCCClienteSelector(states => (states.common.clienteSelecionado));

  const [habilitaConfirma, setHabilidaConfirma] = useState(false);
  const [selecionados, setSelecionados] = useState('');

  useEffect(() => {
    if (clienteSelecionado.idCliente) {
      dispatch(pesquisarTiposLancamentosNaoPrestadosClienteSelecionadoAction('NAO_PRESTADO', clienteSelecionado.idCliente));
    }
  }, [clienteSelecionado.idCliente, dispatch]);

  function onChangeHandler(event, index) {
    tiposLancamentos[index].selecionado = event.target.checked;
    if (tiposLancamentos[index].selecionado) {
      setSelecionados(selecionados.concat(`${tiposLancamentos[index].descricao}; `));
    } else {
      setSelecionados(selecionados.replace(`${tiposLancamentos[index].descricao}; `, ''));
    }

    setHabilidaConfirma(false);
    tiposLancamentos.forEach((tipo) => {
      if (tipo.selecionado) {
        setHabilidaConfirma(true);
      }
    });

    dispatch(atualizaStateTiposLancamentosNaoPrestadosClienteSelecionadoAction(tiposLancamentos));
  }

  return (
    <>
      <ActionHeaderContainer>
        <SimpleTitle
          text="Bloquear Lançamentos"
        />
      </ActionHeaderContainer>
      <MainContainer>
        <Grid24 xs={24} sm={24}>
          <Grid24 xs={24} sm={12}>
            {tiposLancamentos?.length > 0 && (
              <TiposLancamentosSelector
                tiposLancamentos={tiposLancamentos}
                onChangeHandler={onChangeHandler}
                selecionados={selecionados}
              />
            )}
          </Grid24>
        </Grid24>
      </MainContainer>
      <FooterContainer>
        <ButtonUI
          color="success"
          style={{ width: '136px' }}
          disabled={!habilitaConfirma}
          onClick={() => onSubmitHandler(clienteSelecionado.idCliente, tiposLancamentos, 'bloquear')}
        >
          Confirmar
        </ButtonUI>
      </FooterContainer>
    </>
  );
}

export const AcaoBloqueio = AcaoBloqueioComponent;
