import React, { useCallback } from 'react';

import ScrollTabela from '../../../../UI/Tabela/Scroll/ScrollTabela';
import useStyles from './compararPermissoesUsuario.styles';
import CheckboxField from '../../../../UI/Field/Checkbox/CheckboxField';

import { useCrudUsuarioDispatch2, useCrudUsuarioSelector2 } from '../../../../../store/Usuario/V2/CrudUsuarioProvider';
import { APLICAR_TITLE, TITULOS_ROTAS } from './compararPermissoesUsuario.constants';
import { onChangeControls } from '../../../../../store/Usuario/V2/crudUsuario.store';
import DialogNew from '../../../../../layouts/FormDialog/DialogNew';
import ButtonUI from '../../../../UI/Button/ButtonUI';


function CompararPermissoesUsuario({
  open = false, columns, onClose = null, onFinish = null,
}) {
  const dispatch = useCrudUsuarioDispatch2();
  const styles = useStyles();

  const permissoesComparacao = useCrudUsuarioSelector2(states => states.controls.permissoesComparacao);

  const changeCheckboxHandler = useCallback((event, index) => {
    const { checked } = event.target;
    const newPermissoesComparacao = [...permissoesComparacao];
    newPermissoesComparacao[index].usuario.value = checked;
    dispatch(onChangeControls('permissoesComparacao', newPermissoesComparacao));
  }, [dispatch, permissoesComparacao]);

  const buildCheckbox = useCallback((index, checked) => (
    <CheckboxField
      label=""
      checked={checked}
      onChange={event => changeCheckboxHandler(event, index)}
    />
  ), [changeCheckboxHandler]);

  const conteudoTabela = permissoesComparacao.map((conteudo, index) => ({
    id: conteudo.id,
    values: {
      item: conteudo.item,
      acao: conteudo.acao,
      usuario: buildCheckbox(index, conteudo.usuario.value),
      usuarioPerfil: conteudo.usuarioPerfil,
    },
  }));

  return (
    <DialogNew
      open={open}
      text={TITULOS_ROTAS}
      onClose={onClose}
    >

      <ScrollTabela rows={conteudoTabela} columns={columns} />
      <div className={styles.footer}>
        <ButtonUI color="success" onClick={onFinish}>
          {APLICAR_TITLE}
        </ButtonUI>
      </div>
    </DialogNew>
  );
}

export default CompararPermissoesUsuario;
