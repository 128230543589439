import React from 'react';
import PrivateRoute from '../../hoc/PrivateRoute/PrivateRoute';
import TextosPadrao from './TextosPadraoPage';

export default function RoutesTextosPadrao() {
    return (
        <>
            <PrivateRoute path="/textos-padrao" exact component={TextosPadrao} />
        </>
    );
}
