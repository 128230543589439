import {
  Typography,
} from '@material-ui/core';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { StepSubLabel } from '../../../pages/InspecionarProcesso/Acordos/AcordosStyle';
import useStylesPassoDois from './PassoDois.style';
import { ButtonUI } from '../..';
import CheckboxField from '../../UI/Field/Checkbox/CheckboxField';
import { buildSettingsTable, getMoneyMask, toBrazilianDate } from '../../../helpers';
import { findIdsTitulosPagamentoDiretoService, findTitulosPagamentoDiretoService } from '../../../services/core/pagamentoDireto/pagamentoDireto.service';
import TableTitulosPagamentoDireto from './TablePassoDois';
import { tablePassoDoisFactory } from './TablePassoDoisFactory';
import { diferenca } from '../../../helpers/utils/dataStructure.utils';


export default function PassoDois({
  handleNextStep,
  handlePreviousStep,
  passoUmControls,
  selectedIds,
  setSelectedIds,
}) {
  const [tableRows, setTableRows] = useState([]);
  const [settingsTable, setSettingsTable] = useState(buildSettingsTable([], 0, 0, 0));
  const [selectAll, setSelectAll] = useState(false);
  const [arrowOrdenacao, setArrowOrdenacao] = useState(false);
  const [columnOrdenacao, setColumnOrdenacao] = useState({
    element: 'sequencia',
    order: 'ASC',
  });

  const stylePassoDois = useStylesPassoDois();

  const getSelectTitulos = useCallback(
    () => Object.keys(selectedIds)
      .filter(ids => selectedIds[ids])
      .map(ids => +ids),
    [selectedIds],
  );

  const hasSelectedIds = useCallback(() => getSelectTitulos().length > 0, [getSelectTitulos]);

  const configureStatesPesquisa = useCallback((data) => {
    setSettingsTable({
      content: data.content,
    });
  }, []);

  const pesquisarTitulos = useCallback((ordenacao) => {
    findTitulosPagamentoDiretoService(passoUmControls.numProcesso.value, [`${ordenacao.element},${ordenacao.order}`]).then((response) => {
      const { data } = response;
      configureStatesPesquisa(data);
    }).catch(() => {
      setSettingsTable(buildSettingsTable([[], 0, 0, 0]));
    });
  }, [configureStatesPesquisa, passoUmControls.numProcesso.value]);

  const onClickOrdernacaoColumn = useCallback(async (columnName) => {
    pesquisarTitulos({ element: columnName, order: arrowOrdenacao ? 'ASC' : 'DESC' });
  }, [pesquisarTitulos, arrowOrdenacao]);

  const changeSelectAll = useCallback((e) => {
    const { checked } = e.target;
    setSelectAll(checked);
    setSelectedIds(Object.keys(selectedIds).reduce((acc, key) => ({ ...acc, [key]: checked }), {}));
  }, [selectedIds, setSelectAll, setSelectedIds]);

  const changeSelectId = useCallback((e) => {
    const { name, checked } = e.target;
    setSelectedIds({ ...selectedIds, [name]: checked });
  }, [selectedIds, setSelectedIds]);

  const makeConteudoTabela = useCallback((titulos) => {
    if (!titulos || titulos.length === 0) {
      return [];
    }

    return {
      values: {
        idTitulo: (
          <CheckboxField
            name={String(titulos.idTitulo)}
            checked={selectedIds[titulos.idTitulo] || false}
            onChange={changeSelectId}
          />),
        sequencia: titulos.sequencia,
        idTipoTitulo: titulos.idTipoTitulo,
        numero: titulos.numero,
        dataVencimento: toBrazilianDate(titulos.dataVencimento),
        atraso: titulos.atraso,
        valorOriginal: getMoneyMask(titulos.valorOriginal),
        valorSaldoOriginal: getMoneyMask(titulos.valorSaldoOriginal),
        valorProtesto: getMoneyMask(titulos.valorProtesto),
        valorMulta: getMoneyMask(titulos.valorMulta),
        valorJuros: getMoneyMask(titulos.valorJuros),
        valorHonorarios: getMoneyMask(titulos.valorHonorarios),
        valorDesconto: getMoneyMask(titulos.valorDesconto),
      },
    };
  }, [changeSelectId, selectedIds]);

  const tableContent = useMemo(
    () => settingsTable.content.map((processoParam, index) => makeConteudoTabela(
      processoParam,
      index,
    )),
    [settingsTable.content, makeConteudoTabela],
  );

  useEffect(() => {
    pesquisarTitulos(columnOrdenacao);
  }, [pesquisarTitulos, columnOrdenacao]);

  useEffect(() => {
    findIdsTitulosPagamentoDiretoService(passoUmControls.numProcesso.value).then((response) => {
      setSelectedIds((prevSelectedIds) => {
        if (Object.keys(prevSelectedIds).every(key => prevSelectedIds[key] !== true)) {
          return response.data.reduce((acumulador, id) => ({ ...acumulador, [id]: false }), {});
        }
        const setPrevSelected = new Set(Object.keys(prevSelectedIds).map(key => Number(key)));
        const setNewIds = new Set(response.data);
        const diffNewPrev = diferenca(setNewIds, setPrevSelected);
        if (diffNewPrev.size === 0) {
          return prevSelectedIds;
        }
        const newObjectIds = [...diffNewPrev].reduce((acumulador, id) => ({ ...acumulador, [id]: false }), {});
        return { ...prevSelectedIds, ...newObjectIds };
      });
    }).catch(() => {
      //
    });
  }, [passoUmControls.numProcesso.value, setSelectedIds]);

  useEffect(() => {
    setTableRows(tableContent);
    setSelectAll(Object.keys(selectedIds).every(key => selectedIds[key] === true));
  }, [tableContent, selectedIds]);

  return (
    <>
      <StepSubLabel>
        <Typography>Selecione os Títulos que deseja realizar a quitação total ou parcial. Somente Títulos que não fazem parte de Acordos estão listados abaixo:</Typography>
      </StepSubLabel>
      <div>
        <TableTitulosPagamentoDireto
          columns={tablePassoDoisFactory.makeCabecalhoTablePassoDois(
            setArrowOrdenacao,
            arrowOrdenacao,
            columnOrdenacao,
            setColumnOrdenacao,
            onClickOrdernacaoColumn, {
              checked: selectAll,
              onChange: changeSelectAll,
            },
          )}
          rows={tableRows}
          page={settingsTable.page}
          size={settingsTable.size}
          rowsPerPage={settingsTable.rowsPerPage}
        />
      </div>
      <div className={stylePassoDois.div__AlignButton}>
        <div className={stylePassoDois.div__Button}>
          <ButtonUI
            onClick={handlePreviousStep}
            color="white"
          >
            Voltar
          </ButtonUI>
        </div>
        <div className={stylePassoDois.div__Button}>
          <ButtonUI
            onClick={handleNextStep}
            disabled={!hasSelectedIds()}
          >
            Próximo
          </ButtonUI>
        </div>
      </div>
    </>
  );
}
