import React, { useCallback, useEffect, useState } from 'react';
import { Container } from '../RelacaoPasta/styles';
import TableUI from '../../../UI/Tabela/PaginadaSimples/Table';
import TableHeader from '../../../../models/TableHeader';
import {
  useCrudFasesPastasDispatch,
  useCrudFasesPastasSelector,
} from '../../../../store/FasesPastas/Crud/CrudFasesPastasProvider';
import { getUsuariosAtivosFaseAction } from '../../../../store/FasesPastas/Crud/crudFasesPastas.saga';
import { setLoadingAction } from '../../../../store/FasesPastas/Crud/crudFasesPastas.store';


const columns = [
  new TableHeader({
    id: 'usuarios', label: 'Usuários Ativos associados à Fase Selecionada', alignCell: 'left', width: 150,
  }),
  new TableHeader({
    id: 'nomeLogin', label: 'Nome Login', alignCell: 'left', width: 70,
  }),
];

function RelacaoUsuarios() {
  const dispatch = useCrudFasesPastasDispatch();

  const [rows, setRows] = useState([]);

  const idFase = useCrudFasesPastasSelector(states => states.requestedFields.idFase.value);
  const usuariosAtivosFase = useCrudFasesPastasSelector(states => states.usuariosAtivosFase);

  const buildTableRow = useCallback(row => ({
    id: row.nomeUsuario,
    values: {
      usuarios: row.nomeUsuario,
      nomeLogin: row.nomeLogin,
    },
  }), []);

  const buildTable = useCallback(() => {
    dispatch(setLoadingAction(true));
    setRows(usuariosAtivosFase.map(buildTableRow));
    dispatch(setLoadingAction(false));
  }, [dispatch, setRows, usuariosAtivosFase, buildTableRow]);

  useEffect(() => {
    dispatch(getUsuariosAtivosFaseAction(idFase));
  }, [dispatch, idFase]);

  useEffect(() => {
    buildTable();
  }, [buildTable]);

  return (
    <Container>
      <TableUI
        isScrollable
        columns={columns}
        rows={rows}
        rowsPerPage={8}
      />
    </Container>
  );
}

export default React.memo(RelacaoUsuarios);
