import {
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { StepSubLabel } from '../../../pages/InspecionarProcesso/Acordos/AcordosStyle';
import { ButtonUI, InputField } from '../..';
import useStylesPassoTres from './PassoTres.style';
import MoneyField from '../../UI/Field/Money/MoneyField';
import CheckboxField from '../../UI/Field/Checkbox/CheckboxField';
import NumberInput from '../../UI/Field/Number/NumberInput';
import { getRequiredLabel } from '../../../helpers';
import { finalizaPagamentoDiretoService, findDebitarClienteService } from '../../../services/core/pagamentoDireto/pagamentoDireto.service';
import OptionDialogNew from '../../UI/Dialogs/OptionDialog/OptionDialogNew';

export default function PassoTres({
  passoUmControls,
  handlePreviousStep,
  selectedIds,
  handleResetSteps,
}) {
  const stylePassoTres = useStylesPassoTres();
  const [valorPagamento, setValorPagamento] = useState(0);
  const [quitarIntegralmente, setQuitarIntegralmente] = useState(false);
  const [debitarCliente, setDebitarCliente] = useState(false);
  const [comissao, setComissao] = useState(0);
  const [justificativa, setJustificativa] = useState('');
  const [showFinalizarDialog, setShowFinalizarDialog] = useState(false);
  const [openReturnMessageDialog, setOpenReturnMessageDialog] = useState(false);
  const [returnMessage, setReturnMessage] = useState('');
  const [returnCodResult, setReturnCodResult] = useState(0);

  const listaDeIds = Object.keys(selectedIds).filter((key => selectedIds[key] === true)).map(key => Number(key));

  const desabilitaFinalizar = !justificativa
  || (!valorPagamento || valorPagamento <= 0)
  || (debitarCliente && (!comissao || comissao <= 0));

  const handleFinalizar = useCallback(() => {
    finalizaPagamentoDiretoService(listaDeIds, passoUmControls, valorPagamento, quitarIntegralmente, debitarCliente, comissao, justificativa)
      .then((response) => {
        const { data } = response;
        setReturnCodResult(data.codResult);
        if (data.codResult === 0) {
          setReturnMessage('Pagamento Direto realizado com sucesso.');
        } else {
          setReturnMessage(data.msgResult);
        }
        setOpenReturnMessageDialog(true);
      }).catch((e) => {
        toast.error(e.response.data.message);
      });
  }, [listaDeIds, passoUmControls, valorPagamento, quitarIntegralmente, debitarCliente, comissao, justificativa]);

  useEffect(() => {
    if (passoUmControls?.numProcesso?.value) {
      findDebitarClienteService(passoUmControls.numProcesso.value)
        .then((response) => {
          const { data } = response;
          if (data.habilitaDebitarCliente === 'S') {
            setDebitarCliente(true);
            setComissao(data.comissao);
          }
        }).catch((e) => {
          toast.error(e.response.data.message);
        });
    }
  }, [passoUmControls, setDebitarCliente, setComissao]);

  return (
    <>
      <OptionDialogNew
        open={openReturnMessageDialog}
        confirmLabel="OK, ENTENDI"
        onClickConfirm={() => {
          setReturnMessage('');
          setOpenReturnMessageDialog(false);
          if (returnCodResult === 0) {
            handleResetSteps();
          }
        }}
      >
        <div style={{
          fontSize: '16px', width: '537px', display: 'flex', textAlign: 'justify',
        }}
        >
          <p>
            {returnMessage}
          </p>
        </div>
      </OptionDialogNew>
      <OptionDialogNew
        open={showFinalizarDialog}
        confirmLabel="Sim"
        cancelLabel="Não"
        onClickCancel={() => { setShowFinalizarDialog(false); }}
        onClickConfirm={() => {
          handleFinalizar();
          setShowFinalizarDialog(false);
        }}
      >
        <div style={{
          fontSize: '16px', width: '537px', display: 'flex', textAlign: 'justify',
        }}
        >
          <p>
            Esta operação de Pagamento Direto não poderá ser desfeita.
            <br />
            Tem certeza que deseja realmente continuar?
          </p>
        </div>
      </OptionDialogNew>
      <StepSubLabel>
        <Typography>Informe o valor do Pagamento Direto e o valor de Comissionamento, se devido:</Typography>
      </StepSubLabel>
      <div style={{ width: '184px', marginTop: '10px' }}>
        <MoneyField
          isForm
          name="valorPagamento"
          value={valorPagamento}
          label={getRequiredLabel('Valor do Pagamento', true)}
          onChange={(e) => { setValorPagamento(e.target.value); }}
        />
      </div>
      <div style={{ width: '282px', marginTop: '10px', marginLeft: '8px' }}>
        <CheckboxField
          label="Quitar integralmente os Títulos selecionados"
          name="quitarIntegralmente"
          checked={quitarIntegralmente}
          onChange={(e) => { setQuitarIntegralmente(e.target.checked); }}
        />
      </div>
      <div style={{ width: '126px', marginTop: '10px', marginLeft: '8px' }}>
        <CheckboxField
          label="Debitar ao Cliente"
          name="debitarCliente"
          checked={debitarCliente}
          onChange={(e) => {
            if (!e.target.checked) {
              setComissao(0);
            }
            setDebitarCliente(e.target.checked);
          }}
        />
      </div>
      {debitarCliente && (
        <div style={{ width: '126px', marginTop: '10px' }}>
          <NumberInput
            numberType="percentage"
            acceptEmptyValue
            maxValue={100}
            fullWidth
            isForm
            label={getRequiredLabel('Comissão', true)}
            sideText="(%)"
            value={comissao}
            name="comissao"
            onChange={(e) => { setComissao(e.target.value); }}
          />
        </div>

      )}

      <div style={{ width: '439px', marginTop: '10px' }}>
        <InputField
          fullWidth
          isForm
          label="Justificativa*:"
          value={justificativa}
          name="justificativa"
          onChange={(e) => { setJustificativa(e.target.value); }}
        />
      </div>

      <div className={stylePassoTres.div__AlignButton}>
        <div className={stylePassoTres.div__Button}>
          <ButtonUI
            color="white"
            onClick={handlePreviousStep}
          >
            Voltar
          </ButtonUI>
        </div>
        <div className={stylePassoTres.div__ButtonFinalizar}>
          <ButtonUI
            onClick={() => { setShowFinalizarDialog(true); }}
            color="custom"
            customColor="#43A047"
            disabled={desabilitaFinalizar}
          >
            Finalizar
          </ButtonUI>
        </div>
      </div>
    </>
  );
}
