import FieldModel from '../../../../models/FieldModel';
import { alterarParcelaDinheiro } from '../../../../services/core/processos/processosAcordosService';

class DialogAlterarParcelaDinheiroController {
  makeFormFields(dadosParcelaDinheiro) {
    return ({
      observacaoDinheiro: new FieldModel({
        name: 'observacaoDinheiro',
        value: dadosParcelaDinheiro.observacao || null,
      }),
    });
  }


  makePayload(idProcesso, nroParcela, idAcordo, idAcordoParcela, tipoFormPgtp, valor, formFields) {
    return {
      observacao: formFields.observacaoDinheiro.value,
      idProcesso,
      nroParcela,
      idAcordo,
      idAcordoParcela,
      tipoFormPgtp,
      valor,
    };
  }

  async salvar(idProcesso, nroParcela, idAcordo, idAcordoParcela, tipoFormPgtp, valor, formFields) {
    const payload = this.makePayload(idProcesso, nroParcela, idAcordo, idAcordoParcela, tipoFormPgtp, valor, formFields);
    await alterarParcelaDinheiro(payload);
  }
}

export const dialogAlterarParcelaDinheiroController = new DialogAlterarParcelaDinheiroController();
