import { Box } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import WrapperSubTitleForm from '../../../components/UI/WrapperSubTitleForm';
import useStyles from './AcaoAlterarNegociados.styles';
import { ButtonUI, SelectorField } from '../../../components';
import { acaoAlterarNegociadorController } from './acaoAlterarNegociadorController';
import { getFieldName } from '../../../helpers';
import Grid24 from '../../../components/UI/Grid24';
import { findNegociadoresValidosAction } from '../../../store/Global/LoadSelectors/loadSelectors.saga';
import { buscarNegociadorAtualProcesso, submitTrocarNegociadorAtualProcesso } from '../../../services/core/processos/processosService';

function AcaoAlterarNegociador({ processo, updatePage }) {
  const dispatch = useDispatch();

  const styles = useStyles();

  const [formFields, setFormFields] = useState(
    acaoAlterarNegociadorController.makeFormFields({}),
  );

  const negociadoresValidos = useSelector(
    states => states.selectors.negociadoresValidos.selector,
  );

  const [negociadorAtual, setNegociadorAtual] = useState({});

  const onChangeHandler = useCallback(
    (e) => {
      const { value } = e.target;
      const name = getFieldName(e.target);
      setFormFields(oldValues => ({ ...oldValues, [name]: value }));
    },
    [setFormFields],
  );

  const buscarNegociadorAtual = useCallback(async () => {
    const { data } = await buscarNegociadorAtualProcesso(processo?.id);
    setNegociadorAtual(data);
  }, [processo?.id]);

  const submitHandler = useCallback(async () => {
    try {
      await submitTrocarNegociadorAtualProcesso(
        processo?.id,
        formFields?.novoNegociador,
      );
      updatePage();
      toast.success('Ação realizada com sucesso!');
    } catch (err) {
      toast.error('Erro ao trocar usuário do processo.');
    }
  }, [formFields.novoNegociador, processo, updatePage]);


  useEffect(() => {
    dispatch(findNegociadoresValidosAction(processo?.id));
    buscarNegociadorAtual();
  }, [dispatch, processo?.id, buscarNegociadorAtual]);

  return (
    <Box>
      <WrapperSubTitleForm className={styles.titleAlterarNegociador}>
        Alterar o Negociador do Processo
      </WrapperSubTitleForm>
      <Box>
        <form className={styles.formAlterarNegociador}>
          <Grid24 container>
            <Grid24 fullWidth>
              <div className={styles.negociadorAtual}>
                <span>Negociador Atual:</span>
                <span>{negociadorAtual?.nome}</span>
              </div>
            </Grid24>
            <Grid24 xs={24} sm={8}>
              <SelectorField
                isForm
                label="Novo Negociador*"
                name="novoNegociador"
                value={formFields.novoNegociador}
                items={negociadoresValidos}
                onChange={onChangeHandler}
              />
            </Grid24>
          </Grid24>
        </form>
      </Box>
      <Box className={styles.footerNovoNegociador}>
        <ButtonUI
          color="success"
          style={{ width: '136px' }}
          onClick={submitHandler}
          disabled={!formFields.novoNegociador}
        >
          Concluir
        </ButtonUI>
      </Box>
    </Box>
  );
}


export default AcaoAlterarNegociador;
