import { Button } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import styled from 'styled-components';


export default makeStyles({
  grid__telefone: {
    marginBottom: '7px',
    marginTop: '7px',
    marginRight: '8px',
  },

  grid__email: {
    marginBottom: '7px',
    marginRight: '8px',
  },

  margin: {
    marginTop: '-33px',
  },

  buttonInspecionar: {
    marginTop: '16px',
    marginBottom: '16px',
  },
});

export const BotaoInspecionarCliente = styled(Button)`
  color: #564872;
  border: 1px solid #564872;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  width: 187px;
  height: 26px;
  text-transform: none;
  min-width: 187px;
`;
