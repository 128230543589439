import {
  call, put, takeLatest, select,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  buildRequestedFieldsAction,
  changeEditModeAction, clearEditorTextoAction, setAlertDialogValuesAction,
  setLoadingAction, setOtherFieldsAction,
  updateRequestFieldsAction,
} from './crudFasesPastas.store';
import {
  getFaseById,
  getUsuariosAtivosFase,
  updateFase,
} from '../../../services/core/fase/fase.services';
import {
  buildPayload,
  getExceptionErrorMessage, hasMessageInException,
  isConflictException,
  isNotFoundException,
} from '../../../helpers/utils/utils';
import { getExceptionHandler } from '../../../helpers/utils/exception.util';
import { buildEmptyEditorTexto, isErrorOnEditorTexto } from '../../../components/UI/EditorTexto/editorTexto.utils';

const GET_FASE_ID = 'GET_FASE_ID';
const GET_USUARIOS_ATIVO_FASE = 'GET_USUARIOS_ATIVO_FASE';
const INSERT_FASES_PASTAS = 'INSERT_FASES_PASTAS';

export const getUsuariosAtivosFaseAction = idFase => ({
  type: GET_USUARIOS_ATIVO_FASE,
  idFase,
});

export const getFaseByIdAction = idFase => ({
  type: GET_FASE_ID,
  idFase,
});

export const insertFasesAction = () => ({
  type: INSERT_FASES_PASTAS,
});

function* getFaseByIdHanlder(actions) {
  const { idFase } = actions;
  yield put(setLoadingAction(true));
  const { data } = yield call(getFaseById, idFase);
  yield put(buildRequestedFieldsAction(data));
  yield put(setLoadingAction(false));
}

function getFasesToPayload(requestedFields) {
  const descricaoAtividades = buildEmptyEditorTexto(requestedFields.descricaoAtividades);
  return {
    ...buildPayload(requestedFields),
    descricaoAtividades,
  };
}

function* catchSubmitException(exception, requestedFields) {
  const [updatedRequestedFields] = yield getExceptionHandler(exception, requestedFields);
  const exceptionMessage = getExceptionErrorMessage(exception);
  const conflictException = isConflictException(exception);
  const notFoundException = isNotFoundException(exception);
  const badRequestExceptionMessage = hasMessageInException(exception);

  if (updatedRequestedFields && !badRequestExceptionMessage) {
    toast.error(exceptionMessage, { style: { width: '392px' } });
    yield put(updateRequestFieldsAction(updatedRequestedFields));

    if (isErrorOnEditorTexto(exception.response.data.validations, 'descricaoAtividades')) {
      yield put(clearEditorTextoAction());
    }
  }

  if (yield (notFoundException || conflictException)) {
    yield put(setAlertDialogValuesAction(true, 'error', exceptionMessage, 'reload'));
  }
}

function* insertFasesHandler() {
  yield put(setLoadingAction(true));
  const requestedFields = yield select(states => states.requestedFields);
  const { idFase } = requestedFields;
  try {
    const payloads = yield getFasesToPayload(requestedFields);
    yield updateFase(idFase.value, payloads);
    yield put(getFaseByIdAction(idFase.value));
    yield put(changeEditModeAction(false));
  } catch (exception) {
    yield catchSubmitException(exception, requestedFields);
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* getUsuariosAtivosFaseHandler(action) {
  yield put(setLoadingAction(true));
  const { idFase } = action;
  try {
    const { data } = yield call(getUsuariosAtivosFase, idFase);
    yield put(setOtherFieldsAction('usuariosAtivosFase', data));
  } catch (exception) {
    // Nenhum tratamento definido...
  } finally {
    yield put(setLoadingAction(false));
  }
}

export default function* watchCrudFasesPastas() {
  yield takeLatest(GET_FASE_ID, getFaseByIdHanlder);
  yield takeLatest(INSERT_FASES_PASTAS, insertFasesHandler);
  yield takeLatest(GET_USUARIOS_ATIVO_FASE, getUsuariosAtivosFaseHandler);
}
