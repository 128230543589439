import React from 'react';
import { useDispatch } from 'react-redux';
import CrudUsuarioProvider from '../../../../../store/Usuario/V2/CrudUsuarioProvider';
import { initializePageAction } from '../../../../../store/theme.actions';
import withTokenInterceptor from '../../../../../hoc/withTokenInterceptor/withTokenInterceptor';
import CrudUsuario from '../../../../../components/Usuario/V2/CrudUsuario';


function CadastrarUsuarioPage2() {
  const dispatch = useDispatch();

  dispatch(initializePageAction('Usuários > Novo Cadastro'));
  return (
    <CrudUsuarioProvider>
      <CrudUsuario isInsertMode />
    </CrudUsuarioProvider>
  );
}

export default withTokenInterceptor(CadastrarUsuarioPage2);
