import React from 'react';
import cx from 'classnames';

import { List, ListSubheader } from '@material-ui/core';
import ContentList from './Content/ContentList';

import useStyles from './ListUI.styles';

function ListUI({
  disabled,
  items,
  header,
  height,
  className,
  activeIndex,
  changeItem,
  onChangeCheckbox,
}) {
  const styles = useStyles({ height });
  return (
    <List className={cx(styles.wrapper, className)} disablePadding>
      {header && <ListSubheader>{header}</ListSubheader>}
      {items.map((item, index) => (
        <ContentList
          key={item.id}
          disabled={disabled}
          onClick={() => changeItem(index)}
          onChangeCheckbox={event => onChangeCheckbox(event, index)}
          active={activeIndex === index}
          withCheckbox={item.isCheckbox}
          title={item.value}
          selected={item.selected}
          model={item.model}
        />
      ))}
    </List>
  );
}

ListUI.defaultProps = {
  disabled: false,
  height: 'auto',
  items: [],
  header: null,
  className: '',
  activeIndex: null,
  changeItem: () => null,
  onChangeCheckbox: () => null,
};

export default ListUI;
