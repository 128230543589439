import React, { useEffect, useState } from 'react';
import { isEqual } from 'date-fns';
import {
  CalendarContainer,
  Day,
  Days,
  HeaderCalendar,
  LabelMonth,
  NextMonth,
  PreviousMonth,
  Week,
  Weekday,
} from './dashboardCalendario.styles';
import { DaysNextMonthComponent, DaysPreviousMonthComponent } from '../UI/Field/Date/DateField';
import { DateUtils, toBrazilianDate } from '../../helpers';

function DaysAtMonthComponent({
  year, month, minDate, maxDate, selectedDate = new Date(), onChange,
}) {
  const lastDayCurrentMonth = DateUtils.getLastDateCurrentMonth(year, month);
  const container = [];
  for (let day = 1; day <= lastDayCurrentMonth.getDate(); day += 1) {
    const date = new Date(lastDayCurrentMonth);
    date.setDate(day);
    if (DateUtils.isBetweenDate(minDate, maxDate, date)) {
      const dateToCompare = new Date(year, month, (day));
      container.push(
        <Day
          key={`at-month-${day}`}
          onClick={onChange}
          selected={isEqual(dateToCompare, selectedDate)}
        >
          {day}
        </Day>,
      );
    } else {
      container.push(<Day key={`at-month-${day}`} onClick={onChange} disabled>{day}</Day>);
    }
  }
  return container;
}

function DashboardCalendarioComponent({
  date,
  handleChangeDate,
}) {
  const [labelHeader, setLabelHeader] = useState('');
  const [monthYear, setMonthYear] = useState((() => DateUtils.makeMonthYear(toBrazilianDate(date))));
  const [disabledStatusMonth, setDisabledStatusMonth] = useState({ previous: false, next: false });
  const [minFullDate] = useState(DateUtils.getDefaultMinDate());
  const [maxFullDate] = useState(DateUtils.getDefaultMaxDate());

  function changeDateSeletectHandler(e) {
    const selectedDay = Number(e.target.textContent);
    let newDate = DateUtils.toStringDate(monthYear, selectedDay);
    newDate = DateUtils.getISODateFromText(newDate);
    handleChangeDate({
      date: newDate,
      fullDate: DateUtils.getFullDateFromText(newDate),
    });
  }

  /**
   * Atualizar cabeçalho do calendário
   */
  useEffect(() => {
    if (monthYear.month !== undefined) {
      setLabelHeader(`${DateUtils.CALENDAR_MONTHS[monthYear.month].name} ${monthYear.year}`);
      const disbaledStatus = DateUtils.getDisbaledMonthStatus(monthYear, minFullDate, maxFullDate);
      setDisabledStatusMonth(disbaledStatus);
    }
  }, [monthYear, minFullDate, maxFullDate]);

  function showPreviousMonth() {
    setMonthYear(DateUtils.decrementMonth);
  }

  function showNextMonth() {
    setMonthYear(DateUtils.incrementMonth);
  }

  return (
    <>
      <CalendarContainer>
        <HeaderCalendar>
          <PreviousMonth disabled={disabledStatusMonth.previous} onClick={showPreviousMonth}>
            &lt;
          </PreviousMonth>
          <LabelMonth>
            {labelHeader}
          </LabelMonth>
          <NextMonth disabled={disabledStatusMonth.next} onClick={showNextMonth}>
            &gt;
          </NextMonth>
        </HeaderCalendar>
        <Week>
          <Weekday>Dom</Weekday>
          <Weekday>Seg</Weekday>
          <Weekday>Ter</Weekday>
          <Weekday>Qua</Weekday>
          <Weekday>Qui</Weekday>
          <Weekday>Sex</Weekday>
          <Weekday>Sab</Weekday>
        </Week>
        <Days>
          <DaysPreviousMonthComponent year={monthYear.year} month={monthYear.month} />
          <DaysAtMonthComponent
            year={monthYear.year}
            month={monthYear.month}
            minDate={minFullDate}
            maxDate={maxFullDate}
            selectedDate={date}
            onChange={changeDateSeletectHandler}
          />
          <DaysNextMonthComponent year={monthYear.year} month={monthYear.month} />
        </Days>
      </CalendarContainer>
    </>
  );
}

export const DashboardCalendario = DashboardCalendarioComponent;
