import React from 'react';
import { Grid } from '@material-ui/core';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';

function TitleActionButtonComponent({
  title = '',
  readTitle = '',
  disabled = false,
  addHandler,
  tooltip = '',
  readMode = false,
  identifier,
  hideAddButton = false,
}) {
  return (
    <Grid container alignItems="center">
      {readMode ? readTitle : title}
      {(!readMode && !hideAddButton) && (
        <IconButtonTooltip
          type="add"
          color="primary"
          attributes={{ identifier }}
          disabled={disabled}
          hint={tooltip}
          onClick={addHandler}
        />
      )}
    </Grid>
  );
}

export const TitleActionButton = TitleActionButtonComponent;
