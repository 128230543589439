import React, { useCallback } from 'react';

import cx from 'classnames';
import CheckboxField from '../../Field/Checkbox/CheckboxField';
import IconButtonTooltip from '../../IconButtonTooltip/IconButtonTooltip';
import InputField from '../../Field/Input';
import useStyles from './Email.styles';
import Grid24 from '../../Grid24';
import { getAttributeExistingObject, getRequiredLabel } from '../../../../helpers/utils/utils';

export function buildLayoutConstants(requiredFields) {
  return {
    email: getRequiredLabel('E-mail', getAttributeExistingObject(requiredFields, 'email', false)),
    padrao: 'Padrão',
    boleto: 'Boleto',
    notaFiscal: 'Nota Fiscal',
    hintRemover: 'Excluir E-mail',
    permitirPrestacaoConta: 'Prestação de Contas',
  };
}


function Email({
  model,
  onChange,
  onFocus,
  onChecked,
  onRemove,
  disabled = false,
  baseName = '',
  requiredFields = [],
}) {
  const styles = useStyles();
  const {
    email, permitirPadrao, permitirBoleto, permitirNotaFiscal, permitirPrestacaoConta,
  } = model;

  const constants = buildLayoutConstants(requiredFields);

  const buildName = name => `${baseName}-${name}`;
  return (
    <Grid24 container spacing={1}>
      <Grid24 xs={24} sm={10}>
        <InputField
          isForm
          fullWidth
          disabled={disabled}
          label={constants.email}
          name={buildName(email.name)}
          value={email.value}
          error={email.error}
          errorMessage={email.errorMessage}
          onChange={useCallback(event => onChange(event), [onChange])}
          onFocus={useCallback(event => onFocus(event), [onFocus])}
        />
      </Grid24>
      <Grid24
        xs={24}
        sm={12}
        container
        className={
          cx({
            [styles.actionWithRemoveButton]: true,
          })}
      >
        <Grid24 xs={24} sm={4} spacing={1}>
          <CheckboxField
            disabled={disabled}
            label={constants.padrao}
            name={buildName(permitirPadrao.name)}
            checked={permitirPadrao.value}
            onChange={onChecked}
          />
        </Grid24>
        <Grid24 xs={24} sm={4} spacing={1}>
          <CheckboxField
            disabled={disabled}
            label={constants.boleto}
            name={buildName(permitirBoleto.name)}
            checked={permitirBoleto.value}
            onChange={onChecked}
          />
        </Grid24>
        <Grid24 xs={24} sm={5} spacing={1}>
          <CheckboxField
            disabled={disabled}
            label={constants.notaFiscal}
            name={buildName(permitirNotaFiscal.name)}
            checked={permitirNotaFiscal.value}
            onChange={onChecked}
          />
        </Grid24>
        <Grid24 xs={24} sm={8} spacing={1}>
          <CheckboxField
            disabled={disabled}
            label={constants.permitirPrestacaoConta}
            name={buildName(permitirPrestacaoConta.name)}
            checked={permitirPrestacaoConta.value}
            onChange={onChecked}
          />
        </Grid24>
        <Grid24 sm={2}>
          {!disabled && (
            <div>
              <IconButtonTooltip
                type="trash"
                hint={constants.hintRemover}
                onClick={onRemove}
              />
            </div>
          )}
        </Grid24>
      </Grid24>
    </Grid24>
  );
}

export default React.memo(Email);
