import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { TextField, InputAdornment, CircularProgress } from '@material-ui/core';

import Tooltip from '../Tooltip/Tooltip';

import useStyles from './textFieldUI.style';

function TextFieldUI({
  model,
  InputProps,
  blockLabelAnimation,
  classesInput,
  showLoading,
  classes,
  disabled,
  name,
  value,
  error,
  errorMessage,
  classForm,
  ...props
}) {
  const styles = useStyles();
  const classesDisable = disabled ? styles.disableOnlyRead : '';
  const rootClass = cx(styles.wrapper, {
    [styles.rootBlockLabel]: blockLabelAnimation,
  });
  let content = (
    <TextField
      fullWidth
      name={model.name || name}
      value={model.value || value}
      error={model.error || error}
      disabled={disabled}
      classes={{ root: rootClass, ...classes }}
      InputProps={{
        endAdornment: showLoading && (
          <InputAdornment position="end">
            <CircularProgress size={18} />
          </InputAdornment>
        ),
        classes: { disabled: classesDisable, ...classesInput },
        ...InputProps,
      }}
      {...props}
    />
  );

  if (model.error || error) {
    let message = model.errorMessage;
    if (message === undefined) {
      message = errorMessage;
    }
    content = (
      <Tooltip error title={message} placement="bottom-start">
        {content}
      </Tooltip>
    );
  }

  return <div className={`${classForm} ${styles.fieldWrapper}`}>{content}</div>;
}

TextFieldUI.defaultProps = {
  error: false,
  blockLabelAnimation: false,
  errorMessage: '',
  classForm: '',
  disabled: false,
  classes: {},
  showLoading: false,
  classesInput: {},
  InputProps: {},
  model: {
    name: undefined,
    value: undefined,
    error: undefined,
    errorMessage: undefined,
  },
  value: '',
  name: '',
};

TextFieldUI.propTypes = {
  blockLabelAnimation: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  classForm: PropTypes.string,
  classes: PropTypes.object,
  showLoading: PropTypes.bool,
  classesInput: PropTypes.object,
  InputProps: PropTypes.object,
  model: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default React.memo(TextFieldUI);
