import React from 'react';
import {
  Paper, Table, TableHead, TableBody, TableRow, TableCell,
} from '@material-ui/core';

import useStyles from './ScrollTabela.styles';

function TableUI({
  columns = [], rows = [], maxHeight = '300px', handlerClick, hover,
}) {
  const styles = useStyles({ maxHeight });

  const buildTableRow = row => (
    <TableRow
      onClick={handlerClick}
      hover={hover}
      key={row.id}
      {...row.props}
    >
      {columns.map((column) => {
        const value = row.values[column.id];
        return (
          <TableCell key={column.id} align={column.alignCell}>
            <pre>{column.format && typeof value === 'number' ? column.format(value) : value}</pre>
          </TableCell>
        );
      })}
    </TableRow>
  );

  return (
    <Paper className={styles.wrapper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                id={column.id}
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{rows.map(row => buildTableRow(row))}</TableBody>
      </Table>
    </Paper>
  );
}

export default React.memo(TableUI);
