import { gateway, gatewayShortJson } from '../../api';
import { getUriParams } from '../../../helpers/utils/utils';

const CORE = process.env.REACT_APP_CORE;

export function findAllFases(permiteReceberProcesso) {
  let uri = getUriParams({ permiteReceberProcesso });
  if (uri !== '') {
    uri = `?${uri}`;
  }

  return gatewayShortJson.get(`${CORE}/fases?${uri}`);
}

export function getFaseById(idFase) {
  return gateway.get(`${CORE}/fases/${idFase}`);
}

export function updateFase(idFase, payload) {
  return gateway.put(`${CORE}/fases/${idFase}`, payload);
}

export function getUsuariosAtivosFase(idFase) {
  return gateway.get(`${CORE}/fases/${idFase}/usuarios`);
}

export function buscarFasesParaSelecaoEnvioProcessoService(permiteCobrancaPresencial) {
  return gatewayShortJson.get(`${CORE}/fases/processos/${permiteCobrancaPresencial}`);
}

export function findFasesEnviarProcessos(permiteCobrancaPresencial) {
  return gateway.get(`${CORE}/fases/processos/fases-enviar-processos/${permiteCobrancaPresencial}`);
}


// Ids tipos fases
export function buscarIdFaseAcordoSeguro() {
  return gateway.get(`${CORE}/fases/tipo-fase/acordo-seguro`);
}

export function buscarIdFaseBaixa() {
  return gateway.get(`${CORE}/fases/tipo-fase/baixa`);
}
