import { Button, TextField } from '@mui/material';
import React, { memo } from 'react';
import useStyle, { HeaderInstrucao } from './PassoDoisStyle';


function DialogCampanha({ onClose, dadosDefault }) {
  const style = useStyle();

  return (
    <>
      <HeaderInstrucao>
        <div className={style.textInstrucao}>
          <TextField
            name="campanha"
            defaultValue={dadosDefault.descricaoCampanha}
            disabled
            multiline
            rows={4}
            id="outlined-multiline-static"
            InputProps={{
              style: { fontSize: '13px' },
            }}
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#000000',
              },
            }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            onClick={onClose}
          >
            Ok, ENTENDI
          </Button>
        </div>
      </HeaderInstrucao>
    </>
  );
}

export default memo(DialogCampanha);
