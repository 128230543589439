import { buildDialog, buildSettingsTable, buildSnackbar } from '../../helpers/factory/global.factory';
import { getAttributeExistingObject } from '../../helpers/utils/utils';
import FieldModel, { POSITIVE_INTEGER } from '../../models/FieldModel';
import {
  ON_UPDATE_REQUEST_FIELDS,
  RESET_REQUEST_FIELDS, SET_ALERT_DIALOG,
  SET_ATTRIBUTES,
  SET_CACHE_REQUEST_FIELDS,
  SET_PAGE,
  SET_REQUEST_FIELD_FOCUS,
  SET_REQUEST_FIELD_VALUE,
  SET_REQUEST_FIELDS,
  SET_ROWS_PER_PAGE,
  SET_SNACKBAR_TEXTO,
  SET_STORE_VALUE,
  SET_TABLE_INFO,
} from './textosPadrao.constants';


function buildRequestFields(fields) {
  return {
    numero: new FieldModel({
      name: 'numero',
      value: getAttributeExistingObject(fields, 'numero'),
      type: POSITIVE_INTEGER,
    }),
    idTipotextoPadrao: new FieldModel({
      name: 'idTipotextoPadrao',
      value: getAttributeExistingObject(fields, 'idTipotextoPadrao'),
    }),
    texto: new FieldModel({
      name: 'texto',
      value: getAttributeExistingObject(fields, 'texto'),
    }),
  };
}

function initiateStates() {
  return {
    requestFields: buildRequestFields(),
    settingsTable: buildSettingsTable(),
    cacheRequestFields: null,
    loading: false,
    showCrudDialog: false,
    idTipoTextoPadraoPesquisa: '',
    alertDialog: buildDialog(),
    snackbar: buildSnackbar(),
  };
}

export const setRequestFieldValueAction = (name, value) => ({
  type: SET_REQUEST_FIELD_VALUE,
  name,
  value,
});

export const setRequestFieldFocusAction = name => ({
  type: SET_REQUEST_FIELD_FOCUS,
  name,
});

export const resetTextosRequestFieldsAction = () => ({
  type: RESET_REQUEST_FIELDS,
});

export const setAttributesAction = (name, value) => ({
  type: SET_ATTRIBUTES,
  name,
  value,
});

export const setPageAction = page => ({
  type: SET_PAGE,
  page,
});

export const setRowsPerPageAndPageAction = (rowsPerPage, page) => ({
  type: SET_ROWS_PER_PAGE,
  rowsPerPage,
  page,
});

export const setTableInfoAction = (content, page, rowsPerPage, totalElements) => ({
  type: SET_TABLE_INFO,
  tableInfo: buildSettingsTable(content, page, rowsPerPage, totalElements),
});

export const setSnackbarTextoAction = (open, variant, message) => ({
  type: SET_SNACKBAR_TEXTO,
  open,
  variant,
  message,
});

export const setCacheRequestFieldsAction = () => ({
  type: SET_CACHE_REQUEST_FIELDS,
});

export const setStoreValuesAction = (name, value) => ({
  type: SET_STORE_VALUE,
  name,
  value,
});

export const onUpdateRequestFieldsAction = requestFields => ({
  type: ON_UPDATE_REQUEST_FIELDS,
  requestFields,
});

export const setRequestFieldsAction = textos => ({
  type: SET_REQUEST_FIELDS,
  textos,
});

export const setAlertDialogValuesAction = (open, variant, message) => ({
  type: SET_ALERT_DIALOG,
  open,
  variant,
  message,
});

function changeAlertDialogValueHandler(states, actions) {
  const alertDialog = { ...states.alertDialog, open: actions.open };
  if (actions.variant || actions.message) {
    alertDialog.variant = actions.variant;
    alertDialog.message = actions.message;
  }
  return { ...states, alertDialog };
}

function setSnackbarValueHandler(states, actions) {
  const snackbar = { ...states.snackbar, open: actions.open };
  if (actions.variant || actions.message) {
    snackbar.variant = actions.variant;
    snackbar.message = actions.message;
  }
  return {
    ...states,
    snackbar,
  };
}

function setRequestFieldValueHandler(states, { name, value }) {
  const newValue = states.requestFields[name].getNewModel(value);
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [name]: newValue,
    },
  };
}

function setRequestFieldFocusHandler(states, { name }) {
  const newField = states.requestFields[name].copy();
  newField.error = false;
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [name]: newField,
    },
  };
}

function resetTextoRequestFieldsHandler(states) {
  return {
    ...states,
    requestFields: buildRequestFields(),
  };
}


function setRequestFieldsHandler(states, actions) {
  const { idTextoPadrao, idTipoTextoPadrao, texto } = actions.textos;
  return {
    ...states,
    requestFields: {
      numero: new FieldModel({ name: 'numero', value: idTextoPadrao }),
      idTipotextoPadrao: new FieldModel({ name: 'idTipotextoPadrao', value: idTipoTextoPadrao }),
      texto: new FieldModel({ name: 'texto', value: texto }),
    },
  };
}

function setAttributesHandler(states, { name, value }) {
  return {
    ...states,
    [name]: value,
  };
}

function onChangePageHandler(states, actions) {
  return {
    ...states,
    settingsTable: {
      ...states.settingsTable,
      page: actions.page,
    },
  };
}

function onChangeRowsPerPageAndPageHandler(states, actions) {
  return {
    ...states,
    settingsTable: {
      ...states.settingsTable,
      rowsPerPage: actions.rowsPerPage,
      page: actions.page,
    },
  };
}

function changeTableInfoHandler(states, actions) {
  return {
    ...states,
    settingsTable: { ...actions.tableInfo },
  };
}

function setCacheRequestFieldsHandler(states) {
  const idTipoTextoPadrao = states.idTipoTextoPadraoPesquisa;

  return {
    ...states,
    cacheRequestFields: {
      idTipoTextoPadrao,
    },
  };
}

function setStoreValueHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    [name]: value,
  };
}

function onUpdateRequestFieldsHandler(states, actions) {
  const { requestFields } = actions;
  return {
    ...states,
    requestFields,
  };
}

export default (states = initiateStates(), action) => {
  switch (action.type) {
    case SET_REQUEST_FIELD_VALUE:
      return setRequestFieldValueHandler(states, action);
    case SET_REQUEST_FIELD_FOCUS:
      return setRequestFieldFocusHandler(states, action);
    case RESET_REQUEST_FIELDS:
      return resetTextoRequestFieldsHandler(states);
    case SET_ATTRIBUTES:
      return setAttributesHandler(states, action);
    case SET_PAGE:
      return onChangePageHandler(states, action);
    case SET_ROWS_PER_PAGE:
      return onChangeRowsPerPageAndPageHandler(states, action);
    case SET_TABLE_INFO:
      return changeTableInfoHandler(states, action);
    case SET_SNACKBAR_TEXTO:
      return setSnackbarValueHandler(states, action);
    case SET_CACHE_REQUEST_FIELDS:
      return setCacheRequestFieldsHandler(states);
    case SET_STORE_VALUE:
      return setStoreValueHandler(states, action);
    case ON_UPDATE_REQUEST_FIELDS:
      return onUpdateRequestFieldsHandler(states, action);
    case SET_REQUEST_FIELDS:
      return setRequestFieldsHandler(states, action);
    case SET_ALERT_DIALOG:
      return changeAlertDialogValueHandler(states, action);
    default:
      return states;
  }
};
