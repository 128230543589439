import React from 'react';
import { InputLabel, Typography } from '@material-ui/core';
import useStyles from './intervalMoney.styles';
import MoneyField from '../Money/MoneyField';

function IntervalMoney({
  label, firstField, lastField, onFocus, firstFieldProps, lastFieldProps,
}) {
  const styles = useStyles();
  return (
    <div className={styles.div__root}>
      <InputLabel>{label}</InputLabel>
      <div className={styles.div__fields}>
        <div className={styles.div__field}>
          <MoneyField
            acceptEmptyValue
            disabled={firstField.disabled}
            name={firstField.name}
            error={firstField.error}
            errorMessage={firstField.errorMessage}
            onFocus={onFocus}
            {...firstFieldProps}
          />
        </div>
        <div className={styles.centralizeText}>
          <Typography component="p">até</Typography>
        </div>
        <div className={styles.div__field}>
          <MoneyField
            acceptEmptyValue
            disabled={lastField.disabled}
            name={lastField.name}
            error={lastField.error}
            errorMessage={lastField.errorMessage}
            onFocus={onFocus}
            {...lastFieldProps}
          />
        </div>
      </div>
    </div>
  );
}

export default IntervalMoney;
