import React, {
  memo, useState, useCallback, useEffect,
} from 'react';
import { Button, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import Grid24 from '../../../../components/UI/Grid24';
import useStyle from '../AcordosStyle';

import {
  getExceptionErrorMessage, getRequiredLabel, isBadRequestException, toAmericanDate, toBrazilianDate,
} from '../../../../helpers/utils/utils';
import DateField from '../../../../components/UI/Field/Date/DateField';
import { dialogAlterarParcelaBoletoController } from './dialogAlterarParcelaBoleto';
import { DateUtils, extractResponseErrorMessage, getFieldName } from '../../../../helpers';
import { loadingInspecaoProcessoController } from '../../loadingInspecaoProcessoController';
import { getParametrosInterno } from '../../../../services/core/parametro/parametro.services';
import OptionDialogNew from '../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';


function DialogAlterarBoleto({
  setOpenDialogAlterarBoleto,
  dadosParcelaBoleto,
  idProcesso,
  nroParcela,
  idAcordo,
  idAcordoParcela,
  tipoFormPgtp,
  setLoadings,
  updatePage,
  dados,
  parcela,
}) {
  const style = useStyle();
  const [formFields, setFormFields] = useState(dialogAlterarParcelaBoletoController.makeFormFields(dadosParcelaBoleto));
  const [parametrosInternosAbe, setParametrosInternosAbe] = useState({});
  const [novaDataMax, setNovaDataMax] = useState('');
  const [parcelaAnteriorBoleto, setParcelaAnteriorBoleto] = useState('');
  const [proximaParcelaBoleto, setProximaParcelaBoleto] = useState('');
  const [openButtonAlterarDataDeEmissao, setOpenButtonAlterarDataDeEmissao] = useState(false);


  const onChangeHandler = useCallback((e) => {
    const name = getFieldName(e.target);
    const { value } = e.target;
    setFormFields(oldValue => ({ ...oldValue, [name]: { ...oldValue[name], value } }));
  }, []);

  async function onClickSubmitParcela(e) {
    e.preventDefault();
    setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(true));
    try {
      const retorno = await dialogAlterarParcelaBoletoController.salvar(idProcesso,
        nroParcela,
        idAcordo,
        idAcordoParcela,
        tipoFormPgtp,
        dadosParcelaBoleto.idBoletoCliente,
        formFields);

      if (retorno.data.status === 200 || retorno.data.status === 201 || retorno.data.status === 204) {
        setOpenDialogAlterarBoleto(false);
        updatePage();
      } else if (retorno.data.status === -1) {
        setOpenButtonAlterarDataDeEmissao(oldValue => !oldValue);
      } else {
        toast.error(retorno.data.message, { style: { width: '392px' } });
      }
    } catch (error) {
      const erroMessage = extractResponseErrorMessage(error);
      if (erroMessage !== 'Erro não mapeado') {
        toast.error(erroMessage, { style: { width: '392px' } });
      }
    } finally {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(false));
    }
  }

  async function buscarParametrosInternosAbe() {
    try {
      const { data } = await getParametrosInterno();
      setParametrosInternosAbe(data);
    } catch (error) {
      if (isBadRequestException(error)) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(getExceptionErrorMessage(error));
      }
    }
  }

  const onCloseAlterarDataDeEmissao = useCallback(() => {
    setOpenButtonAlterarDataDeEmissao(oldValue => !oldValue);
  }, [setOpenButtonAlterarDataDeEmissao]);

  useEffect(() => {
    setFormFields(dialogAlterarParcelaBoletoController.makeFormFields(dadosParcelaBoleto));
    buscarParametrosInternosAbe();
  }, [dadosParcelaBoleto]);

  useEffect(() => {
    if (nroParcela === dados.length && nroParcela > 1) {
      setFormFields(dialogAlterarParcelaBoletoController.makeFormFields(dadosParcelaBoleto));
      buscarParametrosInternosAbe();
      const date = DateUtils.getISODateFromText(DateUtils.formatTextDate(parcela.dataVencimento));
      date.setDate(date.getDate() + parametrosInternosAbe.prazoMaximoPrimeiroVencimento);
      setNovaDataMax(date);
      setParcelaAnteriorBoleto(toBrazilianDate(parcela.dataVencimento));
    } else if (nroParcela === dados.length && nroParcela < 1) {
      setFormFields(dialogAlterarParcelaBoletoController.makeFormFields(dadosParcelaBoleto));
      buscarParametrosInternosAbe();
      const date = DateUtils.getISODateFromText(DateUtils.formatTextDate(parcela.dataVencimento));
      date.setDate(date.getDate() + parametrosInternosAbe.prazoMaximoPrimeiroVencimento);
      setNovaDataMax(date);
      setParcelaAnteriorBoleto(toBrazilianDate(parcela.dataVencimento));
    } if (nroParcela > 1 && nroParcela !== dados.length) {
      setParcelaAnteriorBoleto(toBrazilianDate(parcela.dataVencimento));
      const proximaParcela = dados[nroParcela + 0];
      setProximaParcelaBoleto(proximaParcela.values.dataVencimento);
    } if (nroParcela === 1 && nroParcela !== dados.length) {
      setParcelaAnteriorBoleto(toBrazilianDate(parcela.dataVencimento));
      const proximaParcela = dados[nroParcela + 0];
      setProximaParcelaBoleto(proximaParcela.values.dataVencimento);
    }
    if (nroParcela === dados.length && nroParcela === 1) {
      setParcelaAnteriorBoleto(toBrazilianDate(parcela.dataVencimento));
      const date = DateUtils.getISODateFromText(DateUtils.formatTextDate(parcela.dataVencimento));
      date.setDate(date.getDate() + parametrosInternosAbe.prazoMaximoPrimeiroVencimento);
      setNovaDataMax(date);
    }
  }, [formFields.dataVencimento.value, parametrosInternosAbe.prazoMaximoPrimeiroVencimento, dados.length,
    dadosParcelaBoleto,
    nroParcela,
    dados,
    parcela,
    idAcordo,
  ]);

  return (
    <>
      <OptionDialogNew
        open={openButtonAlterarDataDeEmissao}
        variant="warning"
        confirmLabel="Ok, ENTENDI"
        onClickConfirm={onCloseAlterarDataDeEmissao}
      >
        <div className={style.textDialogAlterarChave}>
          <div>
            Por uma restrição do Banco Itaú, o boleto só poderá ser alterado a partir do próximo dia útil.
          </div>
        </div>
      </OptionDialogNew>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={24}>
          {nroParcela === dados.length ? (
            <Grid24 item xs={24} sm={6}>
              <DateField
                isForm
                fullWidth
                label={getRequiredLabel('Vencimento', true)}
                name={formFields.dataVencimento.name}
                value={formFields.dataVencimento.value}
                onChange={onChangeHandler}
                maxDate={novaDataMax}
                minDate={new Date(`${toAmericanDate(parcelaAnteriorBoleto)} 00:00:00`)}
              />
            </Grid24>
          ) : (
            <Grid24 item xs={24} sm={6}>
              <DateField
                isForm
                fullWidth
                label={getRequiredLabel('Vencimento', true)}
                name={formFields.dataVencimento.name}
                value={formFields.dataVencimento.value}
                onChange={onChangeHandler}
                minDate={new Date(`${toAmericanDate(parcelaAnteriorBoleto)} 00:00:00`)}
                maxDate={toAmericanDate(proximaParcelaBoleto)}
              />
            </Grid24>
          )
          }

          <Grid24 item xs={24} sm={24} className={style.dialogAlterarParcelaDinheiro}>
            <div style={{ display: 'grid', marginTop: '18px' }}>
              <TextField
                name={formFields.observacaoBoleto.name}
                value={formFields.observacaoBoleto.value}
                onChange={onChangeHandler}
                multiline
                rows={4}
                id="outlined-multiline-static"
                label="Observações"
              />
            </div>
          </Grid24>
        </Grid24>
      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          onClick={() => setOpenDialogAlterarBoleto(false)}
        >
          Cancelar
        </Button>
        <Button
          disabled={!formFields.dataVencimento.value}
          onClick={onClickSubmitParcela}
        >
          Confirmar
        </Button>
      </div>

    </>
  );
}
export default memo(DialogAlterarBoleto);
