export const SET_REQUEST_FIELD_VALUE = '@textosPadrao/SET_REQUEST_FIELD_VALUE';
export const SET_REQUEST_FIELD_FOCUS = '@textosPadrao/SET_REQUEST_FIELD_FOCUS';
export const RESET_REQUEST_FIELDS = '@textosPadrao/RESET_REQUEST_FIELDS';
export const SET_ATTRIBUTES = '@textosPadrao/SET_ATTRIBUTES';
export const SET_PAGE = '@textosPadrao/SET_PAGE';
export const SET_ROWS_PER_PAGE = '@textosPadrao/SET_ROWS_PER_PAGE';
export const PESQUISAR_TEXTOS = '@textosPadrao/PESQUISAR_TEXTOS';
export const SET_TABLE_INFO = '@textosPadrao/SET_TABLE_INFO';
export const SET_SNACKBAR_TEXTO = '@textosPadrao/SET_SNACKBAR_TEXTO';
export const SAVE = '@textosPadrao/SAVE';
export const SET_CACHE_REQUEST_FIELDS = '@textosPadrao/SET_CACHE_REQUEST_FIELDS';
export const SET_STORE_VALUE = '@textosPadrao/SET_STORE_VALUE';
export const ON_UPDATE_REQUEST_FIELDS = '@textosPadrao/ON_UPDATE_REQUEST_FIELDS';
export const SET_REQUEST_FIELDS = '@textosPadrao/SET_REQUEST_FIELDS';
export const SET_ALERT_DIALOG = '@textosPadrao/SET_ALERT_DIALOG';
export const DELETE = '@textosPadrao/DELETE';
