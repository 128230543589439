import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getRequiredLabel, useUtilStyles } from '../../../../../../../helpers';
import { CNPJ_MASK, CPF_MASK, PHONE_MASK } from '../../../../../../../helpers/constants/layout.constants';
import { setBoletoClienteAttributeValueAction, setContaBoletoClienteAttributeFocusAction } from '../../../../../../../store/Parametros/Crud/crudParametros.store';
import { useCrudParametrosDispatch, useCrudParametrosSelector } from '../../../../../../../store/Parametros/Crud/CrudParametrosProvider';
import InputField from '../../../../../../UI/Field/Input';
import MaskField from '../../../../../../UI/Field/Mask';
import SelectorField from '../../../../../../UI/Field/Selector';
import Grid24 from '../../../../../../UI/Grid24';
import { SubTitleWrapperForm } from '../ContasBancarias.styles';
import {
  AGENCIA_LABEL, BANCO_LABEL, BOLETO_CLIENTE, CNPJ_LABEL, DAC_LABEL, NUMERO_CONTA_LABEL, TITULAR_LABEL,
} from '../TabContasBancarias.constants';

function BoletoCliente({
  isInsertMode, bancos,
}) {
  const utilStyle = useUtilStyles();
  const dispatch = useCrudParametrosDispatch();

  const contaBoletoCliente = useCrudParametrosSelector(states => states.requestFields.contaBancariaBoletoCliente);
  const tiposChavePix = useSelector(states => states.selectors.tiposChavesPix.selector);

  const changeFieldsHandlerBoletoCliente = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setBoletoClienteAttributeValueAction(name, value));
  }, [dispatch]);

  const focusFieldsBoletoClienteHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(setContaBoletoClienteAttributeFocusAction(fieldName));
  }, [dispatch]);

  const getPixMask = () => {
    switch (contaBoletoCliente.idTipoChavePix.value) {
      case 0:
        return CNPJ_MASK;
      case 1:
        return CPF_MASK;
      case 3:
        return PHONE_MASK;
      default:
        return '';
    }
  };

  return (
    <>
      <SubTitleWrapperForm title={BOLETO_CLIENTE} />
      <Grid24 xs={24} sm={24} spacing={1}>

        <Grid24 xs={24} sm={13}>
          <InputField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(TITULAR_LABEL, isInsertMode)}
            onFocus={focusFieldsBoletoClienteHandler}
            onChange={changeFieldsHandlerBoletoCliente}
            name={contaBoletoCliente.titular.name}
            value={contaBoletoCliente.titular.value}
            maxLength={60}
            error={contaBoletoCliente.titular.error}
            errorMessage={contaBoletoCliente.titular.errorMessage}
          />
        </Grid24>
        <Grid24 xs={24} sm={5}>
          <MaskField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(CNPJ_LABEL, isInsertMode)}
            format={CNPJ_MASK}
            onFocus={focusFieldsBoletoClienteHandler}
            onChange={changeFieldsHandlerBoletoCliente}
            name={contaBoletoCliente.cnpjCpf.name}
            value={contaBoletoCliente.cnpjCpf.value}
            error={contaBoletoCliente.cnpjCpf.error}
            errorMessage={contaBoletoCliente.cnpjCpf.errorMessage}
          />
        </Grid24>
      </Grid24>
      <hr className={utilStyle.divider} />
      <Grid24 xs={24} sm={24} spacing={1}>
        <Grid24 xs={24} sm={9}>
          <SelectorField
            isForm
            fullWidth
            disabled
            label={getRequiredLabel(BANCO_LABEL, isInsertMode)}
            items={bancos}
            name={contaBoletoCliente.idBanco.name}
            value={contaBoletoCliente.idBanco.value}
            error={contaBoletoCliente.idBanco.error}
            errorMessage={contaBoletoCliente.idBanco.errorMessage}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <InputField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(AGENCIA_LABEL, isInsertMode)}
            onFocus={focusFieldsBoletoClienteHandler}
            onChange={changeFieldsHandlerBoletoCliente}
            name={contaBoletoCliente.agencia.name}
            value={contaBoletoCliente.agencia.value}
            maxLength={5}
            error={contaBoletoCliente.agencia.error}
            errorMessage={contaBoletoCliente.agencia.errorMessage}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <InputField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(NUMERO_CONTA_LABEL, isInsertMode)}
            onFocus={focusFieldsBoletoClienteHandler}
            onChange={changeFieldsHandlerBoletoCliente}
            name={contaBoletoCliente.numero.name}
            value={contaBoletoCliente.numero.value}
            maxLength={15}
            error={contaBoletoCliente.numero.error}
            errorMessage={contaBoletoCliente.numero.errorMessage}
          />
        </Grid24>
        <Grid24 xs={24} sm={1}>
          <InputField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(DAC_LABEL, isInsertMode)}
            onFocus={focusFieldsBoletoClienteHandler}
            onChange={changeFieldsHandlerBoletoCliente}
            name={contaBoletoCliente.dac.name}
            value={contaBoletoCliente.dac.value}
            maxLength={1}
            error={contaBoletoCliente.dac.error}
            errorMessage={contaBoletoCliente.dac.errorMessage}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <InputField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel('Carteira', isInsertMode)}
            onFocus={focusFieldsBoletoClienteHandler}
            onChange={changeFieldsHandlerBoletoCliente}
            name={contaBoletoCliente.carteira.name}
            value={contaBoletoCliente.carteira.value}
            error={contaBoletoCliente.carteira.error}
            errorMessage={contaBoletoCliente.carteira.errorMessage}
          />
        </Grid24>
      </Grid24>
      <hr className={utilStyle.divider} />
      <Grid24 xs={24} sm={24} spacing={1}>
        <Grid24 xs={24} sm={4}>
          <SelectorField
            isForm
            disabled={!isInsertMode}
            label={getRequiredLabel('Tipo de Chave Pix', isInsertMode)}
            items={tiposChavePix}
            name={contaBoletoCliente.idTipoChavePix.name}
            value={contaBoletoCliente.idTipoChavePix.value}
            onChange={changeFieldsHandlerBoletoCliente}
            onFocus={focusFieldsBoletoClienteHandler}
            error={contaBoletoCliente.idTipoChavePix.error}
            errorMessage={contaBoletoCliente.idTipoChavePix.errorMessage}
          />
        </Grid24>
        <Grid24 xs={24} sm={14}>
          { (contaBoletoCliente.idTipoChavePix.value === 0 || contaBoletoCliente.idTipoChavePix.value === 1
          || contaBoletoCliente.idTipoChavePix.value === 3)
            ? (
              <MaskField
                isForm
                fullWidth
                disabled={!isInsertMode}
                label={getRequiredLabel('Chave Pix', isInsertMode)}
                format={getPixMask()}
                onFocus={focusFieldsBoletoClienteHandler}
                onChange={changeFieldsHandlerBoletoCliente}
                name={contaBoletoCliente.chavePix.name}
                value={contaBoletoCliente.chavePix.value}
                error={contaBoletoCliente.chavePix.error}
                errorMessage={contaBoletoCliente.chavePix.errorMessage}
              />
            ) : (
              <InputField
                isForm
                fullWidth
                disabled={!isInsertMode}
                label={getRequiredLabel('Chave Pix', isInsertMode)}
                onFocus={focusFieldsBoletoClienteHandler}
                onChange={changeFieldsHandlerBoletoCliente}
                name={contaBoletoCliente.chavePix.name}
                value={contaBoletoCliente.chavePix.value}
                error={contaBoletoCliente.chavePix.error}
                errorMessage={contaBoletoCliente.chavePix.errorMessage}
              />
            )
         }
        </Grid24>
      </Grid24>
    </>
  );
}

export default BoletoCliente;
