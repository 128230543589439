import React, { useCallback, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import IdentificacaoCrudCliente from './DadosCadastrais/Identificacao/IdentificacaoCrudCliente';
import SnackbarUI from '../../UI/Snackbar/Snackbar';
import { SNACKBAR_DURATION } from '../../../helpers/constants/global.constants';
import useUtilStyles from '../../../helpers/styles/utils.styles';
import {
  getAcompanhamentoClienteAction,
  getClienteByIdAction, getEmpresasGrupoAction,
  insertClienteAction, migrarPossivelClienteAction,
  updateClienteAction,
} from '../../../store/Cliente/V2/Crud/crudCliente.saga';
import {
  changeTabViewAction,
  openDialogAction,
  openMigrarFollowUpAction,
  openSnackbarAction,
  onChangeControls, onChangeActions, setRequestFieldsFocusAction,
} from '../../../store/Cliente/V2/Crud/crudCliente.store';

import { useCrudClienteDispatch, useCrudClienteSelector } from '../../../store/Cliente/V2/Crud/CrudClienteProvider';
import PaperUI from '../../UI/Paper/PaperUI';
import CardWrapper from '../../UI/CardWrapper/CardWrapper';
import EnderecoCrudCliente from './DadosCadastrais/Endereco/EnderecoCrudCliente';
import TelefonesCrudCliente from './DadosCadastrais/Telefones/TelefonesCrudCliente';
import EmailCrudCliente from './DadosCadastrais/Emails/EmailsCrudCliente';
import DadosAdvogadoCrudCliente from './DadosCadastrais/DadosAdvogado/DadosAdvogadoCrudCliente';
import TabCardLayout from '../../../layouts/TabCardLayout/v2/TabCardLayout';
import FooterForm from '../../UI/FooterForm/FooterForm';
import TabPanel from '../../UI/TabPanel/TabPanel';
import TabModel from '../../../models/TabModel';
import { moveScroll, replaceTo } from '../../../helpers/utils/utils';
import CampoObrigatorio from '../../UI/CampoObrigatorio/CampoObrigatorio';
import UsuariosCrudCliente from './Tabs/Usuario/Usuarios/UsuariosCrudCliente';
import DadosComplementares from './Tabs/DadosComplementares/DadosComplementares';
import EmpresasGrupo from './Tabs/EmpresaGrupo/EmpresasGrupo';
import AlertDialog from '../../UI/Dialogs/AlertDialog/AlertDialog';
import AcompanhamentoCliente from './Acompanhamento/AcompanhamentoCliente';
import BaseDeCalculo from './Tabs/BaseCalculo/BaseCalculo';
import { MESSAGE_GREEN_LABEL, MESSAGE_MIGRAR_DIALOG, MESSAGE_RED_LABEL } from './crudCliente.constants';
import dicaIcon from '../../UI/Dica/lampada-icon.png';
import { DocumentosCliente } from './Tabs/Documentos/DocumentosCliente';
import PrestacaoContas from './Tabs/PrestacaoDeContas/PrestacaoContas';
import OptionDialogNew from '../../UI/Dialogs/OptionDialog/OptionDialogNew';

const tabItems = [
  new TabModel('tab-gerenciamento-acessos', 0, 'Gerenciamento de Acessos', 'scrollable-auto-tabpanel'),
  new TabModel('tab-acompanhamento', 1, 'Acompanhamento', 'scrollable-auto-tabpanel'),
  new TabModel('tab-empresas-grupo', 2, 'Empresas do Grupo', 'scrollable-auto-tabpanel'),
  new TabModel('tab-base-calculo', 3, 'Base de Cálculo', 'scrollable-auto-tabpanel'),
  new TabModel('tab-documentos', 4, 'Documentos', 'scrollable-auto-tabpanel'),
  new TabModel('tab-prestacao-contas', 5, 'Prestação de Contas', 'scrollable-auto-tabpanel'),
  new TabModel('tab-dados-complementares', 6, 'Dados Complementares', 'scrollable-auto-tabpanel'),
];

function CrudCliente({
  isCliente = true,
  isEditMode = false,
  isInsertMode = false,
  isInpectMode = false,
  isMigrar = false,
  match,
  history,
}) {
  const utilStyles = useUtilStyles();
  const dispatch = useCrudClienteDispatch();

  const snackbar = useCrudClienteSelector(states => states.actions.snackbar);
  const alertDialog = useCrudClienteSelector(states => states.actions.alertDialog);
  const showLoading = useCrudClienteSelector(states => states.actions.loading);
  const activeTabView = useCrudClienteSelector(states => states.actions.tabActive);
  const hasNotAcompanhamentos = useCrudClienteSelector(states => states.settingsAcompanhamento.acompanhamentos.length === 0);
  const migrarFollowUpDialog = useCrudClienteSelector(states => states.actions.followUpMigrarDialogContent);
  const hasDataFu = useCrudClienteSelector(states => states.controls.dataFu.value !== '');
  const codEmpresaPrincipal = useCrudClienteSelector(states => states.controls.codEmpresaPrincipal.value);
  const logoCliente = useCrudClienteSelector(state => state.image);
  const exibirLogoProprioAS = useCrudClienteSelector(state => state.controls.exibirLogoProprioAS);

  const focusFieldsHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(setRequestFieldsFocusAction(fieldName));
  }, [dispatch]);

  const roolBackHandler = useCallback(() => (isCliente
    ? replaceTo(history, '/cliente')
    : replaceTo(history, '/possivel-cliente')), [history, isCliente]);

  const closeErrorDialogHandler = () => {
    dispatch(openDialogAction(false));
    if (alertDialog.origin === 'CLIENTE_NOT_FOUND') {
      roolBackHandler();
    }
  };

  const closeSnackbarHandler = () => {
    dispatch(openSnackbarAction({ open: false }));
  };

  const onMigrarPossivelCliente = useCallback(() => {
    dispatch(migrarPossivelClienteAction(roolBackHandler));
  }, [dispatch, roolBackHandler]);

  const verificaDataFollowUp = useCallback((hasDataFollowUp) => {
    if (hasDataFollowUp) {
      dispatch(openMigrarFollowUpAction({
        open: true,
        message: MESSAGE_MIGRAR_DIALOG,
        greenLabel: MESSAGE_GREEN_LABEL,
        redLabel: MESSAGE_RED_LABEL,
      }));
    } else {
      onMigrarPossivelCliente();
    }
  }, [dispatch, onMigrarPossivelCliente]);

  const submitHandler = useCallback(() => {
    const foto = logoCliente.file ? logoCliente.file : null;
    const hasPreviewUrl = logoCliente.imagePreviewUrl ? logoCliente.imagePreviewUrl : null;
    if (isInsertMode) {
      if (!hasPreviewUrl && exibirLogoProprioAS.value) {
        dispatch(onChangeActions('errorImage', true));
        toast.error('Logo obrigatório',
          { style: { width: '392px' } });
      } else {
        dispatch(insertClienteAction(isCliente, foto));
      }
    }

    if (isEditMode && !isMigrar) {
      if (!hasPreviewUrl && exibirLogoProprioAS.value) {
        dispatch(onChangeActions('errorImage', true));
        toast.error('Logo obrigatório',
          { style: { width: '392px' } });
      } else {
        dispatch(updateClienteAction(isCliente, roolBackHandler, foto));
      }
    }

    if (isMigrar) {
      verificaDataFollowUp(hasDataFu);
    }
  }, [dispatch, hasDataFu, isCliente, isEditMode, isInsertMode, isMigrar, logoCliente, roolBackHandler, verificaDataFollowUp, exibirLogoProprioAS]);

  function loadTabView(activeTab) {
    if (activeTab === 1 && hasNotAcompanhamentos && (isEditMode || isInpectMode)) {
      dispatch(getAcompanhamentoClienteAction(isCliente, true));
    }
    if (activeTab === 2 && (isEditMode || isInpectMode) && codEmpresaPrincipal !== '') {
      dispatch(getEmpresasGrupoAction(codEmpresaPrincipal, isCliente));
    }
  }

  const changeActiveTabeViewHandler = (activeTab) => {
    loadTabView(activeTab);
    dispatch(changeTabViewAction(activeTab));
  };

  useEffect(() => {
    moveScroll();
    const hasClientId = match.params.id;
    if (!isInsertMode && hasClientId) {
      dispatch(getClienteByIdAction(isCliente, hasClientId));
    }
  }, [dispatch, match, isInsertMode, isCliente]);

  const onClose = useCallback(() => {
    dispatch(openMigrarFollowUpAction({ open: false }));
  }, [dispatch]);

  const onClickGreen = useCallback(() => {
    onClose();
    onMigrarPossivelCliente();
  }, [onClose, onMigrarPossivelCliente]);

  const onClickRed = useCallback(() => {
    onClose();
    dispatch(onChangeControls('removerDataFollowUp', true));
    onMigrarPossivelCliente();
  }, [onClose, dispatch, onMigrarPossivelCliente]);

  function buildAlertDialogContent(variant) {
    if (variant === 'warning') {
      return (
        <>
          <Typography style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '10px' }} component="p">{alertDialog.message}</Typography>
          <Typography style={{ textAlign: 'center' }} component="p">
            <img src={dicaIcon} alt="Dica" width={18} height={18} />
            {' '}
            <b>Dica</b>
            : Abra o arquivo inválido em um editor de imagem e
            salve como uma nova imagem de formato válido (PNG, JPEG ou JPG).
            Retorne para o cadastro e tente selecionar a imagem novamente.
          </Typography>
        </>
      );
    }
    return <Typography style={{ textAlign: 'center' }} component="p">{alertDialog.message}</Typography>;
  }

  const pageForm = () => (
    <>
      <SnackbarUI
        open={snackbar.open}
        autoHideDuration={SNACKBAR_DURATION}
        variant={snackbar.variant}
        message={snackbar.message}
        onClose={closeSnackbarHandler}
      />
      <AlertDialog
        open={alertDialog.open}
        variant={alertDialog.variant}
        onClickConfirm={closeErrorDialogHandler}
      >
        {buildAlertDialogContent(alertDialog.variant)}
      </AlertDialog>
      <OptionDialogNew
        open={migrarFollowUpDialog.open}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={onClickRed}
        onClickConfirm={onClickGreen}
      >
        <div style={{ fontSize: '18px' }}>
          <p>
            {migrarFollowUpDialog.message}
          </p>
        </div>
      </OptionDialogNew>
      <PaperUI>
        <CardWrapper showLoading={showLoading}>
          <CampoObrigatorio visible={isEditMode || isInsertMode} />
          <IdentificacaoCrudCliente
            isEditMode={isEditMode || isInsertMode}
            match={match}
          />
          <EnderecoCrudCliente
            isEditMode={isEditMode || isInsertMode}
          />
          <TelefonesCrudCliente
            isEditMode={isEditMode || isInsertMode}
          />
          <EmailCrudCliente
            isEditMode={isEditMode || isInsertMode}
          />
          <DadosAdvogadoCrudCliente
            isEditMode={isEditMode || isInsertMode}
          />
        </CardWrapper>
      </PaperUI>
    </>
  );

  const tabsForm = () => (
    <div className={utilStyles.mt16}>
      <TabCardLayout
        tabItems={tabItems}
        parentStates={[activeTabView, changeActiveTabeViewHandler]}
        footerComponent={(
          <FooterForm
            hasRollback={isInpectMode}
            cancelHandler={roolBackHandler}
            submitHandler={submitHandler}
            rollbackHandler={roolBackHandler}
            isMigrarParaCliente={isMigrar}
          />
        )}
      >
        <TabPanel id={tabItems[0].id} index={tabItems[0].index} value={activeTabView}>
          <UsuariosCrudCliente
            isEditMode={isEditMode}
            isInsertMode={isInsertMode}
            showPassword={isEditMode || isInpectMode}
          />
        </TabPanel>
        <TabPanel id={tabItems[1].id} index={tabItems[1].index} value={activeTabView}>
          <AcompanhamentoCliente
            isInsertMode={isInsertMode}
            isEditMode={isEditMode}
            isQueryMode={isInpectMode}
            isCliente={isCliente}
            idCliente={match.params.id}
          />
        </TabPanel>
        <TabPanel id={tabItems[2].id} index={tabItems[2].index} value={activeTabView}>
          <EmpresasGrupo
            isEditMode={isEditMode}
            isCliente={isCliente}
            isMigrarParaCliente={isMigrar}
          />
        </TabPanel>
        <TabPanel id={tabItems[3].id} index={tabItems[3].index} value={activeTabView}>
          <BaseDeCalculo
            isEditMode={isEditMode || isInsertMode}
            isInsertMode={isInsertMode}
          />
        </TabPanel>
        <TabPanel id={tabItems[4].id} index={tabItems[4].index} value={activeTabView}>
          <DocumentosCliente
            idCliente={match.params.id}
            isEditMode={isEditMode}
            isInsertMode={isInsertMode}
          />
        </TabPanel>
        <TabPanel id={tabItems[5].id} index={tabItems[5].index} value={activeTabView}>
          <PrestacaoContas
            isEditMode={isEditMode}
            isInsertMode={isInsertMode}
            onFocus={focusFieldsHandler}
            idCliente={match.params.id}
          />
        </TabPanel>
        <TabPanel id={tabItems[6].id} index={tabItems[6].index} value={activeTabView}>
          <DadosComplementares isEditMode={isEditMode || isInsertMode} />
        </TabPanel>
      </TabCardLayout>
    </div>
  );

  return (
    <>
      {pageForm()}
      {tabsForm()}
    </>
  );
}

export default withRouter(CrudCliente);
