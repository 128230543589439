import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useHistory, withRouter } from 'react-router-dom';
import {
  CampoObrigatorio, InputField, SelectorField,
} from '../../components';
import DateField from '../../components/UI/Field/Date/DateField';
import MoneyField from '../../components/UI/Field/Money/MoneyField';
import FooterForm from '../../components/UI/FooterForm/FooterForm';
import LoadingUI from '../../components/UI/Loading/LoadingUI';
import SubTitleForm from '../../components/UI/SubTitleForm/SubTitleForm';
import {
  DateUtils, getFieldName, getRequiredLabel, toastUnmappedException, toBrazilianDate,
} from '../../helpers';
import { FieldsContainer } from '../Lancamento/CrudLancamentoFinanceiro.styles';
import { PrestarContasController } from './PrestarContasController';
import { calculoDsrService } from './PrestarContasService';
import {
  BigFields, FieldHistorico, FieldsFirstDiv, RootContainer, SmallFields,
} from './PrestarContasStyle';
import NumberInput from '../../components/UI/Field/Number/NumberInput';

function makeFormFields(saldoComissionado, data) {
  return {
    dataLancamento: toBrazilianDate(DateUtils.makeDate(0)),
    domingosFeriados: null,
    historico: 'PAGAMENTO',
    mes: '',
    numeroDiasTrabalhados: data,
    valor: saldoComissionado,
    saldo: saldoComissionado,
  };
}

function makeMeses() {
  return [
    { id: 1, value: 'Janeiro' },
    { id: 2, value: 'Fevereiro' },
    { id: 3, value: 'Março' },
    { id: 4, value: 'Abril' },
    { id: 5, value: 'Maio' },
    { id: 6, value: 'Junho' },
    { id: 7, value: 'Julho' },
    { id: 8, value: 'Agosto' },
    { id: 9, value: 'Setembro' },
    { id: 10, value: 'Outubro' },
    { id: 11, value: 'Novembro' },
    { id: 12, value: 'Dezembro' },
  ];
}


function pegarUltimoDiaDoMes(mes) {
  if (!mes) {
    return null;
  }
  const date = new Date();
  date.setDate(1);
  date.setMonth(mes);
  date.setDate(date.getDate() - 1);
  return date;
}

function PrestarContas() {
  const history = useHistory();

  const [formFields, setFormFields] = React.useState(makeFormFields());
  const [errorFields, setErrorFields] = useState(PrestarContasController.makeErrorFields());
  const [loading, setLoading] = useState(false);
  const [idComissionado, setIdComissionado] = useState(null);
  const [canCalcularDsr, setCanCalcularDsr] = React.useState(false);
  const meses = React.useMemo(() => makeMeses(), []);

  const backPageHandler = () => {
    history.goBack();
  };

  const onChangeFields = (event) => {
    const { name, value } = event.target;
    setFormFields(oldValues => ({ ...oldValues, [name]: value }));
  };

  const onFocusFields = (event) => {
    const name = getFieldName(event.target);
    setErrorFields(oldValues => ({ ...oldValues, [name]: { error: false, errorMessage: '' } }));
  };

  const onChangeSelectorFields = useCallback((event) => {
    const { value } = event.target;
    const data = pegarUltimoDiaDoMes(Number(value));
    setFormFields(oldValues => ({
      ...oldValues,
      mes: value,
      numeroDiasTrabalhados: data?.getDate() || '',
    }));
  }, []);

  async function onClickSalvar(event) {
    event.preventDefault();
    const { success, error } = await PrestarContasController.prestarContas(errorFields, formFields, idComissionado);
    if (success) {
      setLoading(true);
      setFormFields(makeFormFields());
      setErrorFields(PrestarContasController.makeErrorFields());
      backPageHandler();
    } else {
      setLoading(true);
      setErrorFields(error?.campos);
      toast.error(error.toastMessage, { style: { width: '392px' } });
    } setLoading(false);
  }

  useEffect(() => {
    if (!idComissionado) {
      return;
    }
    calculoDsrService(idComissionado).then((res) => {
      setCanCalcularDsr(res.data);
    }).catch((err) => {
      toastUnmappedException(err, 'Ocorreu um erro');
    });
  }, [idComissionado]);

  useEffect(() => {
    const idUsuarioComissionado = history.location?.state?.idUsuarioComissionado;
    const saldoComissionado = history.location?.state?.saldo;

    if (idUsuarioComissionado && saldoComissionado) {
      setFormFields(makeFormFields(saldoComissionado));
      setIdComissionado(idUsuarioComissionado);
    } else {
      history.goBack();
    }
  }, [history]);

  return (
    <>
      <LoadingUI show={loading} />
      <RootContainer>
        <FieldsContainer>
          <CampoObrigatorio />
          <SubTitleForm>
            Informações
          </SubTitleForm>
          <FieldsFirstDiv>
            <SmallFields>
              <DateField
                isForm
                label={getRequiredLabel('Data do Lançamento', true)}
                name="dataLancamento"
                value={formFields.dataLancamento}
                onChange={onChangeFields}
                onFocus={onFocusFields}
                error={errorFields.dataLancamento.error}
                errorMessage={errorFields.dataLancamento.errorMessage}
              />
            </SmallFields>
            <BigFields>
              <MoneyField
                isForm
                disabled
                label="Saldo"
                name="saldo"
                value={formFields?.saldo}
                onChange={onChangeFields}
              />
            </BigFields>
            <BigFields>
              <MoneyField
                isForm
                label={getRequiredLabel('Valor', true)}
                name="valor"
                value={formFields?.valor}
                onChange={onChangeFields}
                onFocus={onFocusFields}
                error={errorFields.valor.error}
                errorMessage={errorFields.valor.errorMessage}
              />
            </BigFields>
          </FieldsFirstDiv>
          <FieldHistorico>
            <InputField
              isForm
              label={getRequiredLabel('Histórico', true)}
              name="historico"
              value={formFields.historico}
              onChange={onChangeFields}
              onFocus={onFocusFields}
              error={errorFields.historico.error}
              errorMessage={errorFields.historico.errorMessage}
            />
          </FieldHistorico>
          <SubTitleForm>
            Cálculo de DSR
          </SubTitleForm>
          <FieldsFirstDiv>
            <SmallFields>
              <SelectorField
                isForm
                label={getRequiredLabel('Mês', canCalcularDsr)}
                name="mes"
                items={meses}
                value={formFields.mes}
                disabled={!canCalcularDsr}
                onChange={onChangeSelectorFields}
                onFocus={onFocusFields}
                error={errorFields.mes.error}
                errorMessage={errorFields.mes.errorMessage}
              />
            </SmallFields>
            <BigFields>
              <InputField
                type="number"
                isForm
                label={getRequiredLabel('Número de dias trabalhados no mês', canCalcularDsr)}
                name="numeroDiasTrabalhados"
                value={formFields.numeroDiasTrabalhados}
                disabled={!canCalcularDsr}
                onChange={onChangeFields}
                onFocus={onFocusFields}
                error={errorFields.numeroDiasTrabalhados.error}
                errorMessage={errorFields.numeroDiasTrabalhados.errorMessage}
              />
            </BigFields>
            <SmallFields>
              <NumberInput
                isForm
                label={getRequiredLabel('Domingos e Feriados', canCalcularDsr)}
                name="domingosFeriados"
                value={formFields.domingosFeriados}
                disabled={!canCalcularDsr}
                onChange={onChangeFields}
                onFocus={onFocusFields}
                error={errorFields.domingosFeriados.error}
                errorMessage={errorFields.domingosFeriados.errorMessage}
              />
            </SmallFields>
          </FieldsFirstDiv>
        </FieldsContainer>
        <FooterForm
          submitHandler={onClickSalvar}
          cancelHandler={backPageHandler}
          onFocusFields={onFocusFields}
        />
      </RootContainer>
    </>
  );
}

export default withRouter(PrestarContas);
