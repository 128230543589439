import { useDispatch } from 'react-redux';
import React from 'react';
import { initializePageAction } from '../../store/theme.actions';
import withTokenInterceptor from '../../hoc/withTokenInterceptor/withTokenInterceptor';
import PrestarContas from './PrestarContas';

function PrestarContasPage() {
  const dispatch = useDispatch();

  dispatch(initializePageAction('Conta-Corrente de Comissionado > Prestação de Contas'));

  return (
    <>
      <PrestarContas />
    </>
  );
}

export default withTokenInterceptor(PrestarContasPage);
