import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SearchLayout from '../../../layouts/SimpleSearchLayout/SimpleSearchLayout';
import TableHeader from '../../../models/TableHeader';
import {
  checkPermission,
  formatCpfCnpj,
  toBrazilianDate,
  getNewPage,
  getScrollPosition,
  isLoading,
  moveScroll,
} from '../../../helpers/utils/utils';
import LoadingUI from '../../UI/Loading/LoadingUI';
import SnackbarUI from '../../UI/Snackbar/Snackbar';
import { FIRST_PAGE, SEARCH_TABLE_TITLE, SNACKBAR_DURATION } from '../../../helpers/constants/global.constants';
import { findEstadosAction, findFiliaisAction } from '../../../store/Global/LoadSelectors/loadSelectors.saga';
import BasicActionsTable from '../../UI/Tabela/PaginadaSimples/BasicActons/BasicActions';
import {
  CIDADE,
  CPF_CNPJ,
  ESTADOS,
  getQuestionMessageDeleteDialog,
  UNIDADE_RESPONSAVEL,
} from './pesquisarCliente.constans';
import {
  clearAdvancedSearchAction,
  setAlertDialogValuesAction,
  setCacheRequestFieldsAction,
  setCpfCnpjSelectorAction,
  setFocusObjectAction,
  setOptionDialogValuesAction,
  setPageAction,
  setRequestFieldsValueAction,
  setRowsPerPageAndPageAction,
  setScrollPositionAction,
  setShowAdvancedSearchAction,
  setSnackbarValuesAction,
  onOpenFollowUpModalAction,
} from '../../../store/Cliente/Pesquisar/pesquisaCliente.store';
import {
  deleteClienteAction,
  findClientesAction,
  getAcompanhamentoForClienteAction,
  removeFollowUpAction,
} from '../../../store/Cliente/Pesquisar/pesquisarCliente.saga';
import SelectorField from '../../UI/Field/Selector/SelectorField';
import { initiateTiposDocumento } from '../../../helpers/factory/global.factory';
import InputField from '../../UI/Field/Input/InputField';
import MaskField from '../../UI/Field/Mask/MaskField';
import Grid24 from '../../UI/Grid24/Grid24';
import useUtilStyles from '../../../helpers/styles/utils.styles';
import useStyles from './pesquisarCliente.styles';
import ButtonUI from '../../UI/Button/ButtonUI';
import PeriodField from '../../UI/Field/Period/PeriodField';
import FollowUpmodal from './FollowUpModal';
import OptionDialogNew from '../../UI/Dialogs/OptionDialog/OptionDialogNew';

const layoutConstants = {
  NOME: 'Nome',
};

function buildDataFollowUpCell(cliente, styles, onOpenModalFollowUpHandler) {
  return (
    (cliente.dataFu && cliente.idClienteAcompanhamento)
      ? (
        <div>
          <ButtonUI
            color="dataFu"
            className={styles.buttonDataFu}
            onClick={() => onOpenModalFollowUpHandler(cliente.idCliente, cliente.idClienteAcompanhamento)}
          >
            <span className={styles.textDataFu}>
              {toBrazilianDate(cliente.dataFu)}
            </span>
          </ButtonUI>
        </div>
      ) : <span>-</span>
  );
}

function buildClienteTable(styles, clientes, onEdit, onMigrar, onDetails, onRemove, isCliente, userPermissions, onOpenModalFollowUpHandler) {
  return clientes.map(cliente => ({
    id: cliente.idCliente,
    values: {
      codigoCliente: cliente.idCliente,
      nome: cliente.nome,
      cnpjCpf: formatCpfCnpj(cliente.documento),
      unidadeResponsavel: cliente.nomeFilial,
      cidade: cliente.cidade,
      estado: cliente.idEstado,
      dataFu: buildDataFollowUpCell(cliente, styles, onOpenModalFollowUpHandler),
      acoes: (
        <BasicActionsTable
          id={cliente.idCliente}
          nome={cliente.nome}
          onDetails={onDetails}
          onEdit={onEdit}
          onMigrarParaCliente={onMigrar}
          onRemove={onRemove}
          isCliente={isCliente}
          userPermissions={userPermissions}
          allows={isCliente !== undefined && isCliente === true ? {
            consultar: 501,
            editar: 504,
            excluir: 503,
          } : {
            consultar: 901,
            editar: 904,
            excluir: 903,
            migrar: 904,
          }}
          userAllow="U"
        />
      ),
    },
  }));
}

const columns = [
  new TableHeader({
    id: 'codigoCliente', label: 'Código', alignCell: 'center', width: 88, maxWidth: 88,
  }),
  new TableHeader({
    id: 'nome', label: 'Nome', showTip: true, maxWidth: 170, width: 80,
  }),
  new TableHeader({
    id: 'cnpjCpf', label: 'CPF/CNPJ', alignCell: 'center', showTip: true, width: 130, maxWidth: 130,
  }),
  new TableHeader({
    id: 'unidadeResponsavel', label: 'Unidade Responsável', showTip: true, maxWidth: 130, width: 100,
  }),
  new TableHeader({
    id: 'cidade', label: 'Cidade', showTip: true, maxWidth: 120, width: 100,
  }),
  new TableHeader({
    id: 'estado', label: 'Estado', alignCell: 'center', width: 68,
  }),
  new TableHeader({
    id: 'dataFu', label: 'Follow up', alignCell: 'center', width: 86,
  }),
  new TableHeader({
    id: 'acoes', label: 'Ações', alignCell: 'center', width: 155,
  }),
];


function PesquisarCliente({ history, isCliente = false }) {
  const dispatch = useDispatch();
  const utilStyles = useUtilStyles();
  const styles = useStyles();

  const [tableRows, setTableRows] = useState([]);
  const [removedCliente, setRemovedCliente] = useState({ id: null, nome: '' });

  const loadings = useSelector(states => [
    states.selectors.estados.loading,
    states.selectors.filiais.loading,
  ]);

  const nome = useSelector(states => states.pesquisaCliente.requestFields.nome);
  const codigoCliente = useSelector(states => states.pesquisaCliente.requestFields.idCliente);
  const unidadeResponsavel = useSelector(states => states.pesquisaCliente.requestFields.unidadeResponsavel);
  const cnpjCpfSelect = initiateTiposDocumento;
  const cnpjCpfField = useSelector(states => states.pesquisaCliente.requestFields.cnpjCpfField);
  const cnpjCpf = useSelector(states => states.pesquisaCliente.requestFields.cnpjCpf);
  const cidade = useSelector(states => states.pesquisaCliente.requestFields.cidade);
  const estado = useSelector(states => states.pesquisaCliente.requestFields.estado);
  const dataFuInicial = useSelector(states => states.pesquisaCliente.requestFields.dataFuInicial.copy());
  const dataFuFinal = useSelector(states => states.pesquisaCliente.requestFields.dataFuFinal.copy());
  const cacheRequestFields = useSelector(states => states.pesquisaCliente.cacheRequestFields);
  const settingsTable = useSelector(states => states.pesquisaCliente.settingsTable);
  const searchLoading = useSelector(states => states.pesquisaCliente.loading);
  const snackbar = useSelector(states => states.pesquisaCliente.snackbar);
  const alertDialog = useSelector(states => states.pesquisaCliente.alertDialog);
  const optionDialog = useSelector(states => states.pesquisaCliente.optionDialog);
  const scrollPosition = useSelector(states => states.pesquisaCliente.scrollPosition);
  const showAdvancedSearch = useSelector(states => states.pesquisaCliente.showAdvancedSearch);
  const filiais = useSelector(states => states.selectors.filiais.selector);
  const estados = useSelector(states => states.selectors.estados.selector);
  const followUpModal = useSelector(states => states.pesquisaCliente.followUpModal);

  const onCloseModalFollowUpHandler = useCallback(() => {
    dispatch(onOpenFollowUpModalAction(false));
  }, [dispatch]);

  const onOpenModalFollowUpHandler = useCallback((idCliente, idClienteAcompanhamento) => {
    dispatch(getAcompanhamentoForClienteAction(isCliente, idCliente, idClienteAcompanhamento));
  }, [dispatch, isCliente]);

  const userPermissions = useSelector(states => (states.authentication.userInformation
    ? states.authentication.userInformation.permissoes : []));


  const previewRowPerPage = useRef();
  const tableContentLength = useRef();
  tableContentLength.current = settingsTable.content.length;

  const openRemoveDialogHandler = useCallback((idFilail, nomeUsuario) => {
    setRemovedCliente({ id: idFilail, nome: nomeUsuario });
    dispatch(setOptionDialogValuesAction(true));
  }, [dispatch]);

  const setScrollPostion = useCallback(() => {
    const position = getScrollPosition();
    dispatch(setScrollPositionAction(position.x, position.y));
  }, [dispatch]);

  const editHandler = useCallback((idCliente) => {
    setScrollPostion();
    if (isCliente) {
      history.push(`/cliente/${idCliente}/editar`);
    } else {
      history.push(`/possivel-cliente/${idCliente}/editar`);
    }
  }, [history, setScrollPostion, isCliente]);

  const migrarParaClienteHandler = useCallback((idCliente) => {
    setScrollPostion();
    history.push(`/possivel-cliente/${idCliente}/migrar`);
  }, [history, setScrollPostion]);

  const detailsHandler = useCallback((idCliente) => {
    setScrollPostion();
    if (isCliente) {
      history.push(`/cliente/${idCliente}/detalhar`);
    } else {
      history.push(`/possivel-cliente/${idCliente}/detalhar`);
    }
  }, [history, setScrollPostion, isCliente]);

  useEffect(() => {
    moveScroll(scrollPosition.x, scrollPosition.y);
  }, [dispatch, scrollPosition]);

  // Consultar dados para preencher a tabela
  useEffect(() => {
    const isChangePageWithourTableContent = () => previewRowPerPage.current
      && previewRowPerPage.current !== settingsTable.rowsPerPage
      && tableContentLength.current === 0;

    if (isChangePageWithourTableContent()) {
      previewRowPerPage.current = settingsTable.rowsPerPage;
      return;
    }

    if (cacheRequestFields) {
      dispatch(findClientesAction(settingsTable.page, settingsTable.rowsPerPage, isCliente));
    }
    previewRowPerPage.current = settingsTable.rowsPerPage;
  }, [dispatch, cacheRequestFields, settingsTable.page, settingsTable.rowsPerPage, isCliente]);

  // Construção da tabela
  useEffect(() => {
    setTableRows(buildClienteTable(
      styles,
      settingsTable.content,
      editHandler,
      migrarParaClienteHandler,
      detailsHandler,
      openRemoveDialogHandler,
      isCliente,
      userPermissions,
      onOpenModalFollowUpHandler,
    ));
  }, [settingsTable, setTableRows, editHandler, detailsHandler, openRemoveDialogHandler, migrarParaClienteHandler, isCliente, onOpenModalFollowUpHandler, userPermissions, styles]);

  const searchClientesHandler = useCallback((event) => {
    event.preventDefault();
    dispatch(setCacheRequestFieldsAction());
    dispatch(setPageAction(FIRST_PAGE));
  }, [dispatch]);

  const removeClienteHandler = useCallback(() => {
    dispatch(deleteClienteAction(removedCliente.id, isCliente));
    dispatch(setOptionDialogValuesAction(false));
  }, [dispatch, removedCliente.id, isCliente]);

  const goToRegisterClienteHandler = useCallback(() => {
    dispatch(setSnackbarValuesAction(false));
    if (isCliente) {
      history.push('/cliente/cadastrar');
    } else {
      history.push('/possivel-cliente/cadastrar');
    }
  }, [dispatch, history, isCliente]);

  const changePageHandler = useCallback((event, newPage) => {
    dispatch(setPageAction(newPage));
  }, [dispatch]);

  const changeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setRequestFieldsValueAction(name, value));
  }, [dispatch]);

  const changecnpjCpfHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setCpfCnpjSelectorAction(name, value));
  }, [dispatch]);

  const focusAttributeHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling && event.target.nextElementSibling.name) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(setFocusObjectAction((fieldName)));
  }, [dispatch]);

  const changeRowsPerPageHandler = useCallback((event) => {
    const newRowsPerPage = event.target.value;
    const newPage = getNewPage(settingsTable.rowsPerPage, settingsTable.page, newRowsPerPage);
    dispatch(setRowsPerPageAndPageAction(newRowsPerPage, newPage));
  }, [dispatch, settingsTable.page, settingsTable.rowsPerPage]);

  const showAdvancedSearchHandler = useCallback(() => {
    if (filiais.length === 0) {
      dispatch(findFiliaisAction());
      dispatch(findEstadosAction());
    }
    dispatch(clearAdvancedSearchAction());
    dispatch(setShowAdvancedSearchAction(!showAdvancedSearch));
  }, [dispatch, showAdvancedSearch, filiais.length]);

  const closeSnackbarHandler = () => {
    dispatch(setSnackbarValuesAction(false));
  };

  const closeRemoveClienteAlertDialogHandler = () => {
    dispatch(setAlertDialogValuesAction(false));
  };

  const closeOptionDialogHandler = () => {
    dispatch(setOptionDialogValuesAction(false));
  };

  const onClickDeleteDataFu = useCallback(() => {
    dispatch(removeFollowUpAction(followUpModal.idCliente, followUpModal.idClienteAcompanhamento, isCliente));
  }, [dispatch, followUpModal, isCliente]);

  const defineMaskFormat = (type) => {
    let format = '';
    if (type === 'CPF') {
      format = '###.###.###-##';
    } else if (type === 'CNPJ' || type === '') {
      format = '##.###.###/####-##';
    }
    return format;
  };

  return (
    <>
      <LoadingUI show={isLoading([...loadings, searchLoading])} />
      <SnackbarUI
        open={snackbar.open}
        variant={snackbar.variant}
        message={snackbar.message}
        autoHideDuration={SNACKBAR_DURATION}
        onClose={closeSnackbarHandler}
      />
      <OptionDialogNew
        open={alertDialog.open}
        confirmLabel="OK"
        onClickConfirm={closeRemoveClienteAlertDialogHandler}
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>{`${alertDialog.message}.`}</p>
        </div>
      </OptionDialogNew>
      <OptionDialogNew
        open={optionDialog.open}
        onClickCancel={closeOptionDialogHandler}
        onClickConfirm={removeClienteHandler}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>
            {getQuestionMessageDeleteDialog(removedCliente.nome, isCliente)}
          </p>
        </div>
      </OptionDialogNew>
      <FollowUpmodal
        openFollowUpModal={followUpModal.open}
        onCloseFollowUpModal={onCloseModalFollowUpHandler}
        messageFollowUp={followUpModal.messageFollowUp}
        onClickDeleteDataFu={onClickDeleteDataFu}
        isOWnerAcompanhamento={followUpModal.isOwnerAcompanhamento}
      />
      <SearchLayout
        allowedNovo={checkPermission(userPermissions, isCliente !== undefined && isCliente === true ? 502 : 902)}
        searchFieldLabel={layoutConstants.NOME}
        openPesquisaAvancada={showAdvancedSearch}
        tituloTabela={SEARCH_TABLE_TITLE}
        fieldPesquisa={nome}
        fieldSecundarioPesquisa={codigoCliente}
        isCliente
        tablePageable={settingsTable}
        tableColumns={columns}
        tableRows={tableRows}
        onChangePesquisa={changeFieldsHandler}
        onClickPesquisa={searchClientesHandler}
        onClickButtonNovo={goToRegisterClienteHandler}
        onChangePage={changePageHandler}
        onChangeRowsPerPage={changeRowsPerPageHandler}
        clickPesquisaAvancada={showAdvancedSearchHandler}
      >
        <Grid24 container spacing="1">
          <Grid24 xs={24} sm={18}>
            <SelectorField
              label={UNIDADE_RESPONSAVEL}
              hasEmptyLabel
              fullWidth
              onChange={changeFieldsHandler}
              name={unidadeResponsavel.name}
              value={unidadeResponsavel.value}
              items={filiais}
            />
          </Grid24>
        </Grid24>
        <Grid24 container spacing="1" className={utilStyles.mt7}>
          <Grid24 xs={24} sm={3}>
            <SelectorField
              label={CPF_CNPJ}
              onChange={changecnpjCpfHandler}
              name="cnpjCpf"
              value={cnpjCpf.value === '' ? cnpjCpfSelect[1].value : cnpjCpf.value}
              items={cnpjCpfSelect}
            />
          </Grid24>
          <Grid24 item xs={24} sm={6}>
            <MaskField
              fullWidth
              blockLabelAnimation={false}
              disabled={false}
              label={' '}
              name={cnpjCpfField.name}
              value={cnpjCpfField.value}
              onChange={changeFieldsHandler}
              inputProps={{
                format: defineMaskFormat(cnpjCpf.value),
              }}
            />
          </Grid24>
          <Grid24 xs={24} sm={11}>
            <InputField
              fullWidth
              name={cidade.name}
              label={CIDADE}
              value={cidade.value}
              onChange={changeFieldsHandler}
              inputProps={{
                maxLength: 30,
              }}
            />
          </Grid24>
          <Grid24 xs={24} sm={4} style={{ marginTop: '1px' }}>
            <SelectorField
              fullWidth
              label={ESTADOS}
              hasEmptyLabel
              onChange={changeFieldsHandler}
              name={estado.name}
              value={estado.value}
              items={estados}
            />
          </Grid24>
        </Grid24>
        <Grid24 container spacing="1" className={utilStyles.mt7}>
          <Grid24 xs={24} sm={6} style={{ paddingTop: '14px' }}>
            <span>Período de Follow up</span>
          </Grid24>
          <Grid24 xs={24} sm={10}>
            <PeriodField
              label=""
              firstField={dataFuInicial}
              lastField={dataFuFinal}
              onFocus={focusAttributeHandler}
              onChange={changeFieldsHandler}
            />
          </Grid24>
        </Grid24>
      </SearchLayout>
    </>
  );
}

export default withRouter(PesquisarCliente);
