import React from 'react';
import { Card } from '../contaCorrenteClienteStyles';


function CardDetalhesCliente({
  detailInfo = null, bgColor, lineColor, title, infoValue = '',
}) {
  return (
    <Card detailInfo={detailInfo !== null} bgColor={bgColor} lineColor={lineColor}>
      <span>{title}</span>
      {detailInfo === null ? (
        <span>{infoValue}</span>
      ) : (
        <div>
          <hr />
          <div>
            <p>{detailInfo.first?.label}</p>
            <p>{detailInfo.first?.value}</p>
          </div>

          <hr />
          <div>
            <p>{detailInfo.second?.label}</p>
            <p>{detailInfo.second?.value}</p>
          </div>

          {detailInfo?.third?.label && (
            <>
              <hr />
              <div>
                <p>{detailInfo.third?.label}</p>
                <p>{detailInfo.third?.value}</p>
              </div>
            </>
          )}

        </div>
      )}
    </Card>
  );
}

export default CardDetalhesCliente;
