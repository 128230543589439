function makeErrorField() {
  return {
    error: false,
    errorMessage: '',
  };
}

function makeErrorTrueField(errorMessage) {
  return {
    error: true,
    errorMessage,
  };
}

function isObjectOrArray(fieldsPath) {
  return fieldsPath.length > 1;
}

function isArray(fieldPath) {
  return /[[0-9]+]/g.test(fieldPath);
}


function getArrayInformations(fieldsPath) {
  return fieldsPath
    .replace('[', ';')
    .replace(']', '')
    .split(';');
}

function setErrorsInFields(fieldsPath, message, fields, addtionalErrors) {
  if (isObjectOrArray(fieldsPath)) {
    const path = fieldsPath.pop();
    if (isArray(path)) {
      const [name, index] = getArrayInformations(path);
      const children = fields[name][index];
      return setErrorsInFields(fieldsPath, message, children, addtionalErrors);
    }
    const children = fields[path];
    return setErrorsInFields(fieldsPath, message, children, addtionalErrors);
  }

  const path = fieldsPath[0];
  if (fields[path]) {
    fields[path].errorMessage = message;
    fields[path].error = true;
  } else {
    addtionalErrors.push({ field: fieldsPath, message });
  }
  return fields;
}

function updateErrorsInFields(validations, fields, addtionalErrors) {
  validations.forEach((validation) => {
    const fieldsPath = validation.field.split('.').reverse();
    setErrorsInFields(fieldsPath, validation.message, fields, addtionalErrors);
  });
  return fields;
}

function resetErrors(fields) {
  Object.keys(fields).forEach((key) => {
    fields[key].error = false;
  });
}

function getValidationsFields(response) {
  if (response && response.data.validations) {
    return response.data.validations;
  }
  return null;
}

function updateFieldsWithErrors(exception, fields) {
  const addtionalErrors = [];
  resetErrors(fields);
  const { response } = exception;

  const validations = getValidationsFields(response, fields, addtionalErrors);
  if (validations) {
    updateErrorsInFields(validations, fields, addtionalErrors);
  }
  return [fields, addtionalErrors];
}

function extractInputsDatasFromForm(targetElement, unknowInputs = []) {
  const data = new FormData(targetElement);
  const newPayload = Array.from(data.keys()).reduce((values, key) => ({
    ...values,
    [key]: data.get(key).trim().length > 0 ? data.get(key).trim() : null,
  }), {});

  return unknowInputs.reduce((accumulator, field) => ({
    ...accumulator,
    [field.name]: field.value,
  }), newPayload);
}

function clearErrorField(errors, name) {
  return { ...errors, [name]: { ...errors[name], error: false } };
}

function extractResponseErrorMessage(error) {
  return error?.response?.data?.message || 'Erro não mapeado';
}

function getFieldName(targetSelector) {
  if (targetSelector?.nextElementSibling && targetSelector.nextElementSibling.name) {
    return targetSelector?.nextElementSibling.name;
  }
  return targetSelector.name;
}

function isValidationException(e) {
  return e?.response?.data?.validations;
}

function getPayloadFromForm(payload, unknowField = []) {
  const newPayload = Array.from(payload.keys()).reduce((values, key) => ({
    ...values,
    [key]: payload.get(key) === '' ? null : payload.get(key),
  }), {});

  if (!unknowField) {
    return payload;
  }

  return unknowField.reduce((accumulator, field) => ({
    ...accumulator,
    [field.name]: field.value,
  }), newPayload);
}


export {
  extractInputsDatasFromForm,
  updateFieldsWithErrors,
  extractResponseErrorMessage,
  clearErrorField,
  getFieldName,
  isValidationException,
  makeErrorField,
  getPayloadFromForm,
  makeErrorTrueField,
};
