import { makeErrorField } from '../../../../helpers';
import FieldModel from '../../../../models/FieldModel';
import { persistirAnaliseSimulacao } from '../../../../services/core/processos/processosAcordosService';

class AnaliseSimulacaoController {
  makeFormFields() {
    return ({
      simulacaoAprovada: new FieldModel({
        name: 'simulacaoAprovada',
        value: '',
      }),
      textoJustificativa: new FieldModel({
        name: 'textoJustificativa',
        value: '',
      }),
    });
  }

  makeErrorFields() {
    return {
      simulacaoAprovada: makeErrorField(),
      textoJustificativa: makeErrorField(),
    };
  }

  makePayload(formFields) {
    return {
      simulacaoAprovada: formFields.simulacaoAprovada.value,
      textoJustificativa: formFields.textoJustificativa.value,
    };
  }

  persistirAnaliseSimulacao(formFields, idAcordo) {
    const payload = this.makePayload(formFields);
    return persistirAnaliseSimulacao(payload, idAcordo);
  }
}

export const analiseSimulacaoController = new AnaliseSimulacaoController();
