import { TableCell, TableRow } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import OrdenacaoColuna from '../UI/Tabela/Ordenacao/OrdenacaoColuna';
import { TableUtils } from '../../helpers';

function TableHeaderContaCorrenteClienteComponent({
  setTableView, allItems, page, rowsPerPage, columnOrdenacao, setColumnOrdenacao, idClienteSelecionado, setClienteSelecionado,
}) {
  const [arrowDirection, setArrowDirection] = useState(false);


  const ordenar = useCallback((coluna) => {
    let ordenados;
    const column = coluna === 'valorRepasse' ? 'valorRepasseWithoutMask' : coluna;
    if (arrowDirection) {
      ordenados = allItems.sort((a, b) => ((b[column] > a[column]) ? 1 : ((a[column] > b[column]) ? -1 : 0)));
    } else {
      ordenados = allItems.sort((a, b) => ((a[column] > b[column]) ? 1 : ((b[column] > a[column]) ? -1 : 0)));
    }
    const tableViewOrdenada = TableUtils.paginar(ordenados, rowsPerPage, page);
    setTableView(tableViewOrdenada);

    // Sempre selecionar o primeiro após clicar em ordenar se não tiver id previamente selecionado na página.
    if (tableViewOrdenada.find(conta => conta.idCliente === idClienteSelecionado) === undefined) {
      setClienteSelecionado({
        idCliente: tableViewOrdenada[0]?.idCliente || null,
        desbloqueado: tableViewOrdenada[0]?.statusPrestacaoContas === 'DESBLOQUEADO' || false,
      });
    }
  }, [setTableView, page, rowsPerPage, allItems, arrowDirection, idClienteSelecionado, setClienteSelecionado]);

  useEffect(() => {
    if (allItems) {
      ordenar(columnOrdenacao.element);
    }
  }, [ordenar, allItems, columnOrdenacao.element]);

  return (
    <TableRow>
      <TableCell height={36} width={30}>
        <span />
      </TableCell>
      <TableCell height={36} width={53}>
        <OrdenacaoColuna
          columnLabel="Código"
          idColumn="idCliente"
          arrowOrdenacao={arrowDirection}
          setArrowOrdenacaoFunc={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
        />
      </TableCell>
      <TableCell height={36} width={160}>
        <OrdenacaoColuna
          columnLabel="Nome"
          idColumn="nomeCliente"
          arrowOrdenacao={arrowDirection}
          setArrowOrdenacaoFunc={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
        />
      </TableCell>
      <TableCell height={36} width={53}>
        <OrdenacaoColuna
          columnLabel="Tipo da Prestação"
          idColumn="tipoPrestacao"
          arrowOrdenacao={arrowDirection}
          setArrowOrdenacaoFunc={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
        />
      </TableCell>
      <TableCell height={36} width={60}>
        <OrdenacaoColuna
          columnLabel="Valor do Repasse"
          idColumn="valorRepasse"
          arrowOrdenacao={arrowDirection}
          setArrowOrdenacaoFunc={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
        />
      </TableCell>
      <TableCell height={36} width={45}>
        <span />
      </TableCell>
    </TableRow>
  );
}


export const TableHeaderContaCorrenteCliente = React.memo(TableHeaderContaCorrenteClienteComponent);
