/* eslint-disable no-nested-ternary */
import React from 'react';
import cx from 'classnames';

import ButtonUI from '../Button/ButtonUI';
import useStyles from './FooterForm.styles';

const layoutConstants = {
  EDITAR: 'Editar',
  ENVIAR: 'Enviar',
  SALVAR: 'Salvar',
  OK: 'OK',
  CANCELAR: 'Cancelar',
  MIGRAR_CLIENTE: 'Confirmar Migração',
};

function FooterForm({
  className = '',
  cancelHandler,
  submitHandler,
  disabledSubmit = false,
  editHandler,
  rollbackHandler,
  rollbackWithOtherButtons = false,
  hasRollback = false,
  isDialogForm = false,
  isMigrarParaCliente = false,
  isEnviar = false,
  isOk = false,
}) {
  const styles = useStyles();
  let classesWrapper;

  if (hasRollback) {
    classesWrapper = styles.wrapperRollback;
  } else if (rollbackWithOtherButtons) {
    classesWrapper = styles.wrapperRollbackWithOtherButtons;
  } else {
    classesWrapper = styles.wrapper;
  }

  if (isDialogForm) {
    classesWrapper = hasRollback ? styles.wrapperRollbackDialog : styles.wrapperDialog;
  }

  const getButtonComponent = (handler, component) => {
    if (handler) {
      return component;
    }
    return null;
  };

  let content = (
    <>
      {getButtonComponent(cancelHandler, (
        <div className={styles.cancelarWidth}>
          <ButtonUI color="error" onClick={cancelHandler}>
            {layoutConstants.CANCELAR}
          </ButtonUI>
        </div>
      ))}

      {getButtonComponent(rollbackWithOtherButtons, (
        <div className={styles.voltarWidth}>
          <ButtonUI onClick={rollbackHandler}>Voltar</ButtonUI>
        </div>
      ))}

      { getButtonComponent(submitHandler,
        (isMigrarParaCliente)
          ? (
            <div className={styles.migrarWidth}>
              <ButtonUI disabled={disabledSubmit} color="success" onClick={submitHandler}>
                {layoutConstants.MIGRAR_CLIENTE}
              </ButtonUI>
            </div>
          ) : (isEnviar) ? (
            <div className={styles.salvarWidth}>
              <ButtonUI disabled={disabledSubmit} color="success" onClick={submitHandler}>
                {layoutConstants.ENVIAR}
              </ButtonUI>
            </div>
          ) : (isOk) ? (
            <div className={styles.salvarWidth}>
              <ButtonUI disabled={disabledSubmit} color="success" onClick={submitHandler}>
                {layoutConstants.OK}
              </ButtonUI>
            </div>
          ) : (
            <div className={styles.salvarWidth}>
              <ButtonUI disabled={disabledSubmit} color="success" onClick={submitHandler}>
                {layoutConstants.SALVAR}
              </ButtonUI>
            </div>
          ))}

      {getButtonComponent(editHandler, (
        <div className={styles.salvarWidth}>
          <ButtonUI onClick={editHandler}>
            {layoutConstants.EDITAR}
          </ButtonUI>
        </div>
      ))}
    </>
  );
  if (hasRollback) {
    content = (
      <div className={styles.voltarWidth}>
        <ButtonUI onClick={rollbackHandler}>Voltar</ButtonUI>
      </div>
    );
  }
  return <div className={cx(classesWrapper, className)}>{content}</div>;
}

export default React.memo(FooterForm);
