import React, { useCallback, useEffect, useState } from 'react';
import { MdPrint as MdPrintIcon } from 'react-icons/md';
import WrapperSubTitleForm from '../../../components/UI/WrapperSubTitleForm';
import useStyle, { ButtonImprimir, ContainerResumo } from './FichaFinanceiraStyle';
import CardResumo from '../../ContaCorrenteCliente/Accordions/RelacaoPrestacao/Cards/CardResumo';
import LoadingUI from '../../../components/UI/Loading/LoadingUI';
import TabCardLayoutAccordion from '../../../layouts/TabCardLayoutAccordion/TabCardLayoutAccordion';
import TabModel from '../../../models/TabModel';
import TabPanel from '../../../components/UI/TabPanel/TabPanel';
import FichaAnalíticaComponent from './Analitica/FichaAnalítica';
import FichaSinteticaComponent from './Sintetica/FichaSintetica';
import { downloadFichaFinanceira, findResumoFichaFinanceira } from '../../../services/core/processos/processosFichaFinanceira';
import {
  getMoneyMask, openPageFileBase64, toastUnmappedException,
} from '../../../helpers';
import { loadingInspecaoProcessoController } from '../loadingInspecaoProcessoController';
import { blue } from '../../../helpers/constants/colors.constants';

function FichaFinanceiraComponent({ setLoadings, idProcesso }) {
  const style = useStyle();

  const [columnOrdenacaoSintetica, setColumnOrdenacaoSintetica] = useState({
    order: 'DESC',
    element: 'dataLancamento',
  });

  const [columnOrdenacaoAnalitica, setColumnOrdenacaoAnalitica] = useState({
    order: 'DESC',
    element: 'dataLancamento',
  });

  const [activeTab, setActiveTab] = useState(0);
  const [settingsResumo, setSettingsResumo] = useState({
    valorCliente: 0,
    valorAbe: 0,
    valorComercial: 0,
    valorNegociador: 0,
    quitacaoTitulos: '',
    distribuicaoValoresDevedor: '',
    distribuicaoFichaFinanceira: '',
  });

  const [tabItens] = useState([
    new TabModel('tab-analitica', 0, 'Analítica', 'scrollable-auto-tabpanel'),
    new TabModel('tab-sintética', 1, 'Sintética', 'scrollable-auto-tabpanel'),
  ]);

  const configureStatesResumo = useCallback((data) => {
    setSettingsResumo({
      valorCliente: data.valorCliente ?? 0,
      valorAbe: data.valorAbe ?? 0,
      valorComercial: data.valorComercial ?? 0,
      valorNegociador: data.valorNegociador ?? 0,
      quitacaoTitulos: data.quitacaoTitulos,
      distribuicaoValoresDevedor: data.distribuicaoValoresDevedor,
      distribuicaoFichaFinanceira: data.distribuicaoFichaFinanceira,
    });
  }, [setSettingsResumo]);

  const changeTabViewHandler = useCallback((activeTabParam) => {
    setActiveTab(activeTabParam);
  }, [setActiveTab]);

  async function handlePrintFichaFinanceira() {
    try {
      let ordenacao;
      setLoadings(loadingInspecaoProcessoController.changeLoadingFichaFinaceira(true));
      if (activeTab === 1) {
        ordenacao = columnOrdenacaoSintetica;
      } else {
        ordenacao = columnOrdenacaoAnalitica;
      }

      const httpResponse = await downloadFichaFinanceira(idProcesso, activeTab, [`${ordenacao.element},${ordenacao.order}`]);
      if (httpResponse.data.length > 0) {
        openPageFileBase64(httpResponse.data, httpResponse.headers['content-type']);
      }
    } catch (err) {
      toastUnmappedException(
        err,
        'Ocorreu um problema ao tentar abrir o relatório',
      );
    } finally {
      setLoadings(loadingInspecaoProcessoController.changeLoadingFichaFinaceira(false));
    }
  }

  useEffect(() => {
    findResumoFichaFinanceira(idProcesso).then((response) => {
      const { data } = response;
      configureStatesResumo(data);
    }).catch(() => {
      setSettingsResumo({

      });
    });
  }, [idProcesso, configureStatesResumo]);

  return (
    <div className={style.container}>
      <LoadingUI />
      <div className={style.header}>
        <div>
          <div>
            <WrapperSubTitleForm title="Distribuição de Valores" />
            <div style={{ marginTop: '8px', fontSize: '12px' }}>
              <h1>
                - Distribuição na Ficha Financeira:
                <b>
                  {' '}
                  {settingsResumo.distribuicaoFichaFinanceira}
                </b>
              </h1>
              <h1>
                - Distribuição dos Valores pagos pelo Devedor:
                <b>
                  {' '}
                  {settingsResumo.distribuicaoValoresDevedor}
                </b>

              </h1>
              <h1>
                - Quitação de Títulos:
                <b>
                  {' '}
                  {settingsResumo.quitacaoTitulos}
                </b>
              </h1>
            </div>
          </div>
          <div>
            <ButtonImprimir
              onClick={handlePrintFichaFinanceira}
            >
              <MdPrintIcon size={16} />
              <span>Imprimir</span>

            </ButtonImprimir>
          </div>
        </div>
        <div style={{ width: '35%' }}>
          <ContainerResumo>
            <h3><b>Valores Distribuídos </b></h3>
            <div>
              <CardResumo
                title="Cliente"
                isDetail={false}
                bgColor="#ff8a00"
                value={getMoneyMask(settingsResumo.valorCliente)}
              />
              <CardResumo
                title="ABE"
                value={getMoneyMask(settingsResumo.valorAbe)}
                isDetail={false}
                bgColor="#f1515b"
              />
            </div>
            <div>
              <CardResumo
                title="Comercial"
                isDetail={false}
                bgColor="#efa77e"
                value={getMoneyMask(settingsResumo.valorComercial)}
              />
              <CardResumo
                title="Negociador"
                value={getMoneyMask(settingsResumo.valorNegociador)}
                isDetail={false}
                bgColor="#f49096"
              />
            </div>
          </ContainerResumo>
        </div>
      </div>
      <div style={{
        width: '55%', color: blue, fontSize: '12px', fontWeight: 'bold', marginBottom: '12px',
      }}
      >
        Visões:

      </div>
      <TabCardLayoutAccordion
        tabItems={tabItens}
        parentStates={[activeTab, changeTabViewHandler]}
      />
      <div>
        <TabPanel id={tabItens[0].id} index={tabItens[0].index} value={activeTab}>
          <div>
            <FichaAnalíticaComponent
              idProcesso={idProcesso}
              setLoadings={setLoadings}
              columnOrdenacao={columnOrdenacaoAnalitica}
              setColumnOrdenacao={setColumnOrdenacaoAnalitica}
            />
          </div>
        </TabPanel>
        <TabPanel id={tabItens[1].id} index={tabItens[1].index} value={activeTab}>
          <FichaSinteticaComponent
            idProcesso={idProcesso}
            setLoadings={setLoadings}
            columnOrdenacao={columnOrdenacaoSintetica}
            setColumnOrdenacao={setColumnOrdenacaoSintetica}
          />
        </TabPanel>

      </div>
    </div>
  );
}

export const FichaFinanceira = FichaFinanceiraComponent;
