import { IconButton, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export default makeStyles({
  dadosNF: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  headerDadosNF: {
    display: 'flex',
    flexDirection: 'row',
  },

  geracaoNFDiv: {
    display: 'flex',
    flex: 1,
  },

  dadosNFTable: {
    marginTop: '10px',
  },

});

export const ButtonImportar = styled(IconButton)`
    margin-top: 30px;
    width:30px;
    height:30px ;
    
    background-color: ${({ active }) => (active === 'true' ? 'transparent' : '#423362')};
    color: ${({ active }) => (active === 'true' ? '#423362' : '#ffff')};
    ${({ active }) => (active === 'true' ? 'border: 2px solid #423362;' : '')}

:hover {
  background-color: ${({ active }) => (active === 'true' ? 'transparent' : '#423362')};
  opacity: 0.64;
}

:disabled {
  background-color: #c4c4c4;
}
 `;

export const Container = styled.section`
  margin-top: 14px;
  padding: 8px;
  border :1px solid #A2A0A0;
`;

export const Header = styled.div`
  display:flex ;
  width:100% ;
  margin-top:24px ;

  >div {
    display: flex ;
    width:17% ;
    margin-right:8px ;
  }
  > div + div{
    width:40% ;
  }
`;

export const Button = styled.div`
  display:flex ;
  width:100% ;
  justify-content:flex-end ;

  >div{
    display:flex ;
    width:140px ;
  }
`;
