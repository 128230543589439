import { CORE, gateway } from '../../api';


export function buscarProcessoPassoUmPagamentoDireto(idProcesso) {
  return gateway.get(`${CORE}/pagamento-direto/buscar-processo/${idProcesso}`);
}

export function findTitulosPagamentoDiretoService(idProcesso, ordenacao) {
  const sortedQuery = ordenacao.reduce((obj, sort) => (
    `${obj}&sort=${sort}`
  ), '');

  return gateway.get(`${CORE}/pagamento-direto/${idProcesso}/titulos?${sortedQuery}`);
}

export function findIdsTitulosPagamentoDiretoService(idProcesso) {
  return gateway.get(`${CORE}/pagamento-direto/${idProcesso}/titulos/ids`);
}

export function finalizaPagamentoDiretoService(listaDeIds, passoUmControls, valorPagamento, quitarIntegralmente, debitarCliente, comissao, justificativa) {
  const payload = {
    listaDeIds,
    valorPagamento,
    quitarIntegralmente,
    debitarCliente,
    comissao,
    justificativa,
    idProcesso: passoUmControls.numProcesso.value,
  };
  return gateway.post(`${CORE}/pagamento-direto/finaliza-pagamento-direto`, payload);
}

export function findDebitarClienteService(idProcesso) {
  return gateway.get(`${CORE}/pagamento-direto/debitar-cliente/${idProcesso}`);
}
