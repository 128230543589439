import styled from 'styled-components';
import { PaperUI } from '../../components';

export const RootLancamento = styled(PaperUI)`
  border-radius: 7px 7px 0px 0px;
`;

export const FieldsContainer = styled.div`
  padding: 22px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const FooterLancamento = styled.footer`
  > div:first-child {
    display: flex;
    justify-content: flex-end;
    padding: 22px;

    > div:first-child {
      width: 100px;
      margin-right: 10px;
    }

    > div:last-child {
      width: 152px;
    }
  //}
`;
