import { TableRow, Tooltip } from '@material-ui/core';
import React, { useCallback } from 'react';
import {
  MdDeleteForever, MdEdit,
} from 'react-icons/md';
import {
  FaUndo, FaHistory, FaHandHoldingUsd, FaCalendarCheck, FaCheck,
} from 'react-icons/fa';
import {
  TableActionButton, TableCellCenter, TableCellLeft, TableCellRight,
} from '../UI/Tabela/tableDefaultStyles';
import {
  CustomTableActionButtom,
  FaLockIcon,
  LockClockIcon,
} from '../../pages/Comissionado/ContaCorrenteComissionadoStyles';
import { LockOpenIcon } from '../../assets/Icons/AttachFileIcon/lockOpen';

function isCustom() {
  return this.type === 'custom';
}

const statusContaCorrente = {
  NAO_PRESTADO: {
    icon: <LockOpenIcon size={15} />,
    name: 'Bloquear Lançamento',
    type: 'normal',
    isCustom,
  },
  BLOQUEADO_AUTOMATICAMENTE: {
    icon: <LockClockIcon />,
    name: 'Desbloquear Lançamento Automático',
    type: 'custom',
    isCustom,
  },
  BLOQUEADO_MANUALMENTE: {
    icon: <FaLockIcon />,
    name: 'Desbloquear Lançamento',
    type: 'custom',
    isCustom,
  },
  PRESTADO: {
    icon: <FaCheck size={16} />,
    name: 'Lançamento Prestado',
    type: 'normal',
    isCustom,
  },

  lookup(status) {
    return statusContaCorrente[status] || null;
  },
};

const tiposLancamentoContaCorrente = {
  ESTORNO: {
    icon: <FaUndo size={16} />,
    name: 'Estorno',
  },
  ADIANTAMENTO: {
    icon: <FaHistory size={16} />,
    name: 'Adiantamento',
  },
  PAGAMENTO: {
    icon: <FaHandHoldingUsd size={16} />,
    name: 'Pagamento',
  },
  DSR: {
    icon: <FaCalendarCheck size={16} />,
    name: 'Descanso Semanal Remunerado',
  },

  lookup(tipo) {
    return tiposLancamentoContaCorrente[tipo] || null;
  },
};

function TableBodyContaCorrenteComissionadoComponent({
  openDialogDelete, openDialogEdit, lancamento, openDialogBloqueio, handleAtualizarStatus, cannotAcoesIcones,
}) {
  const tipoLancamento = tiposLancamentoContaCorrente.lookup(lancamento.tipoContaCorrenteUsuario);
  const status = statusContaCorrente.lookup(lancamento.statusContaCorrenteUsuario);
  const handleBloqueioAutomatico = lancamento.statusContaCorrenteUsuario === 'BLOQUEADO_AUTOMATICAMENTE';
  const handleBloqueioManual = lancamento.statusContaCorrenteUsuario === 'BLOQUEADO_MANUALMENTE';
  const handleDesbloqueado = lancamento.statusContaCorrenteUsuario === 'NAO_PRESTADO';
  const handlePrestado = lancamento.statusContaCorrenteUsuario === 'PRESTADO';
  const isLancamentoAutomatico = lancamento.isLancamentoAutomatico === 'S';

  const onClickAtualizarStatus = useCallback(() => {
    if (handleBloqueioAutomatico) {
      openDialogBloqueio(lancamento.idContaCorrenteUsuario);
    } if (handleBloqueioManual) {
      handleAtualizarStatus(lancamento.idContaCorrenteUsuario, 4);
    } if (handleDesbloqueado) {
      handleAtualizarStatus(lancamento.idContaCorrenteUsuario, 1);
    }
  }, [handleAtualizarStatus, openDialogBloqueio, handleBloqueioAutomatico, handleBloqueioManual, handleDesbloqueado, lancamento.idContaCorrenteUsuario]);

  return (
    <TableRow>
      <TableCellCenter>
        {lancamento.idPrestacao}
      </TableCellCenter>
      <TableCellCenter>
        {lancamento.data}
      </TableCellCenter>
      <TableCellLeft>
        {lancamento.historico}
      </TableCellLeft>
      <TableCellRight>
        {lancamento.valorCredito}
      </TableCellRight>
      <TableCellRight>
        {lancamento.valorDebito}
      </TableCellRight>
      <TableCellCenter>
        {!!tipoLancamento && (
          <Tooltip title={tipoLancamento.name} placement="bottom">
            <span>
              {tipoLancamento.icon}
            </span>
          </Tooltip>
        )}
      </TableCellCenter>
      <TableCellCenter>
        {!!status && (
          <Tooltip title={status.name} placement="bottom">
            <span>
              <CustomTableActionButtom
                onClick={onClickAtualizarStatus}
                disabled={cannotAcoesIcones}
                isCustom={status.isCustom()}
              >
                {status.icon}
              </CustomTableActionButtom>
            </span>
          </Tooltip>
        )}
        <Tooltip title="Editar" placement="bottom">
          <span>
            <TableActionButton
              onClick={() => openDialogEdit(lancamento.idContaCorrenteUsuario)}
              disabled={cannotAcoesIcones || handlePrestado || isLancamentoAutomatico}
            >
              <MdEdit size={20} />
            </TableActionButton>
          </span>
        </Tooltip>
        <Tooltip title="Excluir" placement="bottom">
          <span>
            <TableActionButton
              onClick={() => openDialogDelete(lancamento.idContaCorrenteUsuario)}
              disabled={cannotAcoesIcones || handlePrestado || isLancamentoAutomatico}
            >
              <MdDeleteForever size={20} />
            </TableActionButton>
          </span>
        </Tooltip>
      </TableCellCenter>
    </TableRow>
  );
}

export const TableBodyContaCorrenteComissionado = TableBodyContaCorrenteComissionadoComponent;
