import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import FormWrapperWithTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import Grid24 from '../../../../../UI/Grid24/Grid24';
import { Container, GroupDefinitions } from '../Honorarios/styles';
import { useCrudClienteDispatch, useCrudClienteSelector } from '../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import {
  ABE_DEF, ADD_HINT, ADD_TITLE, ADD_TITLE_QUERY, CLIENTE_DEF, REGRA_CALCULO_REPASSE, TITLE,
} from './JurosDosDevedores.constants';
import Radio from '../../../../../UI/Field/Radio/RadioField';
import AddActionTitleWrapper from '../../../../../UI/Wrapper/AddActionTitle/AddActionTitleWrapper';
import useUtilStyles from '../../../../../../helpers/styles/utils.styles';
import FaixaJurosDosDevedores from './FaixaJurosDosDevedores';
import {
  addJurosDoDevedorAction, setJurosDosDevedoresDefinitionAction, updateJurosDosDevedoresAction, onChangeControls, setJurosDosDevedoresAction,
} from '../../../../../../store/Cliente/V2/Crud/crudCliente.store';
import SelectorField from '../../../../../UI/Field/Selector';
import { getListIntervalCorrectAfterDeleteQuantidade, getRequiredLabel } from '../../../../../../helpers';


function JurosDosDevedores({
  disabled,
}) {
  const dispatch = useCrudClienteDispatch();
  const utilStyles = useUtilStyles();

  const itensRegraCalculoRepasse = useSelector(states => states.selectors.regraCalculoRepasseParametro.selector);

  const definition = useCrudClienteSelector(states => states.jurosDosDevedoresDefinition);
  const tipoCalculoJurosDevedores = useCrudClienteSelector(states => states.controls.tipoCalculoJurosDevedores);
  const idTipoRegraCalculoRepasse = useCrudClienteSelector(states => states.controls.idTipoRegraCalculoRepasse);

  const onChangeJurosDosDevedoresHandler = useCallback((e) => {
    const { name: fullname, value } = e.target;
    const [name, idx] = fullname.split('-');
    dispatch(updateJurosDosDevedoresAction(name, value, idx));
  }, [dispatch]);

  const onChangeTipoRegraRepasseHandler = useCallback((e) => {
    const { value } = e.target;
    dispatch(onChangeControls('idTipoRegraCalculoRepasse', value));
  }, [dispatch]);

  const onFocusJurosDosDevedoresHandler = useCallback((e) => {
    const { name: fullname } = e.target;
    const [name, idx] = fullname.split('-');
    dispatch(updateJurosDosDevedoresAction(name, '', idx, 'focus'));
  }, [dispatch]);

  const setFaixasDeJurosDoDevedorHandler = useCallback((jurosDosDevedores) => {
    dispatch(setJurosDosDevedoresAction(jurosDosDevedores));
  }, [dispatch]);

  const onRemoveJurosDosDevedoresRangeHandler = useCallback((e) => {
    const idxClienteJuros = +e.currentTarget.getAttribute('idxClienteJuros');
    const tipo = tipoCalculoJurosDevedores;
    const lista = [...tipo.jurosDosDevedores];
    lista.splice(idxClienteJuros, 1);
    const listaFaixaJuros = getListIntervalCorrectAfterDeleteQuantidade(lista, 'quantidadeDiasFinal', 'quantidadeDiasInicial', idxClienteJuros, 999999);
    setFaixasDeJurosDoDevedorHandler(listaFaixaJuros);
  }, [tipoCalculoJurosDevedores, setFaixasDeJurosDoDevedorHandler]);

  const onChangeDefinitionHandler = useCallback((e) => {
    const { value } = e.target;
    dispatch(setJurosDosDevedoresDefinitionAction(value));
  }, [dispatch]);

  const onAddJurosDoDevedorRangeHandler = useCallback(() => {
    dispatch(addJurosDoDevedorAction());
  }, [dispatch]);

  return (
    <FormWrapperWithTitle title={TITLE}>
      <Container>
        <GroupDefinitions
          aria-label="juros-dos-devedores-definicoes"
          value={definition}
          onChange={onChangeDefinitionHandler}
        >
          <Radio disabled={disabled} label={ABE_DEF} value="abe" />
          <Radio disabled={disabled} label={CLIENTE_DEF} value="cliente" />
        </GroupDefinitions>
        {tipoCalculoJurosDevedores && (
        <>
          <Grid24 xs={24} sm={6} className={utilStyles.mt7}>
            <SelectorField
              isForm
              fullWidth
              disabled={disabled}
              label={getRequiredLabel(REGRA_CALCULO_REPASSE, disabled)}
              items={itensRegraCalculoRepasse}
              value={idTipoRegraCalculoRepasse.value}
              onChange={onChangeTipoRegraRepasseHandler}
            />
          </Grid24>
          <Grid24 isFormContainer container spacing="1">
            <AddActionTitleWrapper
              title={ADD_TITLE}
              readTitle={ADD_TITLE_QUERY}
              disabled={disabled}
              addHandler={onAddJurosDoDevedorRangeHandler}
              tooltip={ADD_HINT}
              readMode={disabled}
            >
              {tipoCalculoJurosDevedores.jurosDosDevedores.map((juros, idx) => (
                <div key={juros.key.value}>
                  <FaixaJurosDosDevedores
                    key={juros.key.value}
                    type="DIAS"
                    quantidadeDiasInicial={juros.quantidadeDiasInicial}
                    quantidadeDiasFinal={juros.quantidadeDiasFinal}
                    porcentagemJuros={juros.porcentagemJuros}
                    porcentagemJurosRepasse={juros.porcentagemJurosRepasse}
                    showTrash={idx === 0}
                    disabled={disabled}
                    idxClienteJuros={idx}
                    onChange={onChangeJurosDosDevedoresHandler}
                    onFocus={onFocusJurosDosDevedoresHandler}
                    onRemove={onRemoveJurosDosDevedoresRangeHandler}
                  />
                  {idx !== juros.length - 1 && (
                    <hr className={utilStyles.faixas__divider} />
                  )}
                </div>
              ))}
            </AddActionTitleWrapper>
          </Grid24>
        </>
        )}
      </Container>
    </FormWrapperWithTitle>
  );
}

export default React.memo(JurosDosDevedores);
