import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function CommentPlusIcon(props) {
  const path = 'M9.5 0C14.7465 0 19 3.74143 19 8.35714C19 10.3484 18.2055 12.1741 16.8848 13.6101C17.3501 15.6315 18.8998 17.4375 18.9184 17.4576C18.9582 17.5032 18.9849 17.5604 18.9951 17.6223C19.0052 17.6841 18.9984 17.7478 18.9755 17.8055C18.9526 17.8631 18.9146 17.9123 18.8662 17.9469C18.8178 17.9815 18.7611 17.9999 18.7031 18C16.2443 18 14.3984 16.7243 13.4855 15.9356C12.2111 16.4528 10.861 16.7166 9.5 16.7143C4.25311 16.7143 0 12.9729 0 8.35714C0 3.74143 4.25311 0 9.5 0ZM5.9375 9.32143C5.9375 9.40668 5.96878 9.48843 6.02445 9.54871C6.08013 9.60899 6.15564 9.64286 6.23438 9.64286H8.3125V11.8929C8.3125 11.9781 8.34378 12.0599 8.39945 12.1201C8.45513 12.1804 8.53064 12.2143 8.60938 12.2143H10.3906C10.4694 12.2143 10.5449 12.1804 10.6005 12.1201C10.6562 12.0599 10.6875 11.9781 10.6875 11.8929V9.64286H12.7656C12.8444 9.64286 12.9199 9.60899 12.9755 9.54871C13.0312 9.48843 13.0625 9.40668 13.0625 9.32143V7.39286C13.0625 7.30761 13.0312 7.22585 12.9755 7.16557C12.9199 7.10529 12.8444 7.07143 12.7656 7.07143H10.6875V4.82143C10.6875 4.73618 10.6562 4.65442 10.6005 4.59414C10.5449 4.53386 10.4694 4.5 10.3906 4.5H8.60938C8.53064 4.5 8.45513 4.53386 8.39945 4.59414C8.34378 4.65442 8.3125 4.73618 8.3125 4.82143V7.07143H6.23438C6.15564 7.07143 6.08013 7.10529 6.02445 7.16557C5.96878 7.22585 5.9375 7.30761 5.9375 7.39286V9.32143Z';
  return (
    <SvgIcon transform="scale(1.13)" viewBox="-4 -4 30 30">
      <path d={path} {...props} />
    </SvgIcon>
  );
}
