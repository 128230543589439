import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getRequiredLabel, useUtilStyles } from '../../../../../../../helpers';
import { CNPJ_MASK, CPF_MASK, PHONE_MASK } from '../../../../../../../helpers/constants/layout.constants';
import {
  setBoletoDevedorAttributeValueAction, setContaBoletoDevedorAttributeFocusAction,
} from '../../../../../../../store/Parametros/Crud/crudParametros.store';
import { useCrudParametrosDispatch, useCrudParametrosSelector } from '../../../../../../../store/Parametros/Crud/CrudParametrosProvider';
import InputField from '../../../../../../UI/Field/Input';
import MaskField from '../../../../../../UI/Field/Mask';
import SelectorField from '../../../../../../UI/Field/Selector';
import Grid24 from '../../../../../../UI/Grid24';
import { SubTitleWrapperForm } from '../ContasBancarias.styles';
import {
  AGENCIA_LABEL, BANCO_LABEL, BOLETO_DEVEDOR, CNPJ_LABEL, DAC_LABEL, NUMERO_CONTA_LABEL, TITULAR_LABEL,
} from '../TabContasBancarias.constants';


function BoletoDevedor({
  isInsertMode, bancos,
}) {
  const utilStyle = useUtilStyles();
  const dispatch = useCrudParametrosDispatch();

  const contaBoletoDevedor = useCrudParametrosSelector(states => states.requestFields.contaBancariaBoletoDevedor);
  const tiposChavePix = useSelector(states => states.selectors.tiposChavesPix.selector);

  const changeFieldsHandlerBoletoDevedor = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setBoletoDevedorAttributeValueAction(name, value));
  }, [dispatch]);

  const focusFieldsBoletoDevedorHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(setContaBoletoDevedorAttributeFocusAction(fieldName));
  }, [dispatch]);

  const getPixMask = () => {
    switch (contaBoletoDevedor.idTipoChavePix.value) {
      case 0:
        return CNPJ_MASK;
      case 1:
        return CPF_MASK;
      case 3:
        return PHONE_MASK;
      default:
        return '';
    }
  };

  return (
    <>
      <SubTitleWrapperForm title={BOLETO_DEVEDOR} />
      <Grid24 xs={24} sm={24} spacing={1}>

        <Grid24 xs={24} sm={13}>
          <InputField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(TITULAR_LABEL, isInsertMode)}
            onFocus={focusFieldsBoletoDevedorHandler}
            onChange={changeFieldsHandlerBoletoDevedor}
            maxLength={60}
            name={contaBoletoDevedor.titular.name}
            value={contaBoletoDevedor.titular.value}
            error={contaBoletoDevedor.titular.error}
            errorMessage={contaBoletoDevedor.titular.errorMessage}
          />
        </Grid24>
        <Grid24 xs={24} sm={5}>
          <MaskField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(CNPJ_LABEL, isInsertMode)}
            format={CNPJ_MASK}
            onFocus={focusFieldsBoletoDevedorHandler}
            onChange={changeFieldsHandlerBoletoDevedor}
            name={contaBoletoDevedor.cnpjCpf.name}
            value={contaBoletoDevedor.cnpjCpf.value}
            error={contaBoletoDevedor.cnpjCpf.error}
            errorMessage={contaBoletoDevedor.cnpjCpf.errorMessage}
          />
        </Grid24>
      </Grid24>
      <hr className={utilStyle.divider} />
      <Grid24 xs={24} sm={24} spacing={1}>
        <Grid24 xs={24} sm={9}>
          <SelectorField
            isForm
            fullWidth
            disabled
            label={getRequiredLabel(BANCO_LABEL, isInsertMode)}
            items={bancos}
            name={contaBoletoDevedor.idBanco.name}
            value={contaBoletoDevedor.idBanco.value}
            error={contaBoletoDevedor.idBanco.error}
            errorMessage={contaBoletoDevedor.idBanco.errorMessage}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <InputField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(AGENCIA_LABEL, isInsertMode)}
            onFocus={focusFieldsBoletoDevedorHandler}
            onChange={changeFieldsHandlerBoletoDevedor}
            name={contaBoletoDevedor.agencia.name}
            value={contaBoletoDevedor.agencia.value}
            maxLength={5}
            error={contaBoletoDevedor.agencia.error}
            errorMessage={contaBoletoDevedor.agencia.errorMessage}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <InputField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(NUMERO_CONTA_LABEL, isInsertMode)}
            onFocus={focusFieldsBoletoDevedorHandler}
            onChange={changeFieldsHandlerBoletoDevedor}
            name={contaBoletoDevedor.numero.name}
            value={contaBoletoDevedor.numero.value}
            maxLength={15}
            error={contaBoletoDevedor.numero.error}
            errorMessage={contaBoletoDevedor.numero.errorMessage}
          />
        </Grid24>
        <Grid24 xs={24} sm={1}>
          <InputField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel(DAC_LABEL, isInsertMode)}
            onFocus={focusFieldsBoletoDevedorHandler}
            onChange={changeFieldsHandlerBoletoDevedor}
            name={contaBoletoDevedor.dac.name}
            value={contaBoletoDevedor.dac.value}
            maxLength={1}
            error={contaBoletoDevedor.dac.error}
            errorMessage={contaBoletoDevedor.dac.errorMessage}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <InputField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel('Carteira', isInsertMode)}
            onFocus={focusFieldsBoletoDevedorHandler}
            onChange={changeFieldsHandlerBoletoDevedor}
            name={contaBoletoDevedor.carteira.name}
            value={contaBoletoDevedor.carteira.value}
            error={contaBoletoDevedor.carteira.error}
            errorMessage={contaBoletoDevedor.carteira.errorMessage}
          />
        </Grid24>
      </Grid24>
      <hr className={utilStyle.divider} />
      <Grid24 xs={24} sm={24} spacing={1}>
        <Grid24 xs={24} sm={4}>
          <SelectorField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={getRequiredLabel('Tipo de Chave Pix', isInsertMode)}
            items={tiposChavePix}
            name={contaBoletoDevedor.idTipoChavePix.name}
            value={contaBoletoDevedor.idTipoChavePix.value}
            onChange={changeFieldsHandlerBoletoDevedor}
            onFocus={focusFieldsBoletoDevedorHandler}
            error={contaBoletoDevedor.idTipoChavePix.error}
            errorMessage={contaBoletoDevedor.idTipoChavePix.errorMessage}
          />
        </Grid24>
        <Grid24 xs={24} sm={14}>
          { (contaBoletoDevedor.idTipoChavePix.value === 0 || contaBoletoDevedor.idTipoChavePix.value === 1
          || contaBoletoDevedor.idTipoChavePix.value === 3)
            ? (
              <MaskField
                isForm
                fullWidth
                disabled={!isInsertMode}
                label={getRequiredLabel('Chave Pix', isInsertMode)}
                format={getPixMask()}
                onFocus={focusFieldsBoletoDevedorHandler}
                onChange={changeFieldsHandlerBoletoDevedor}
                name={contaBoletoDevedor.chavePix.name}
                value={contaBoletoDevedor.chavePix.value}
                error={contaBoletoDevedor.chavePix.error}
                errorMessage={contaBoletoDevedor.chavePix.errorMessage}
              />
            ) : (
              <InputField
                isForm
                fullWidth
                disabled={!isInsertMode}
                label={getRequiredLabel('Chave Pix', isInsertMode)}
                onFocus={focusFieldsBoletoDevedorHandler}
                onChange={changeFieldsHandlerBoletoDevedor}
                name={contaBoletoDevedor.chavePix.name}
                value={contaBoletoDevedor.chavePix.value}
                error={contaBoletoDevedor.chavePix.error}
                errorMessage={contaBoletoDevedor.chavePix.errorMessage}
              />
            )
            }

        </Grid24>
      </Grid24>

    </>

  );
}

export default BoletoDevedor;
