import { getUriParams } from '../../../helpers/utils/utils';
import { CORE, gateway } from '../../api';

export function inspecionarTitulosProcessoService(idProcesso, page, size, ordenacao) {
  const queryParams = getUriParams({ page, size });
  const sortedQuery = ordenacao.reduce((obj, sort) => (
    `${obj}&sort=${sort}`
  ), '');

  return gateway.get(`${CORE}/processos/${idProcesso}/titulos?${sortedQuery}&${queryParams}`);
}

export function getTipoTitulo() {
  return gateway.get(`${CORE}/processos/tipos-titulos`);
}

export function cadastrarTituloService(payload) {
  return gateway.post(`${CORE}/processos/titulo`, payload);
}

export function alterarTituloService(payload) {
  return gateway.put(`${CORE}/processos/titulo`, payload);
}

export function deleteTituloProcessoService(idProcesso, idTitulo, motivosExclusao) {
  return gateway.delete(`${CORE}/processos/${idProcesso}/processo/${idTitulo}/titulo/${motivosExclusao}`);
}

export function findNovoAcordosService(idProcesso, ordenacao) {
  const sortedQuery = ordenacao.reduce((obj, sort) => (
    `${obj}&sort=${sort}`
  ), '');

  return gateway.get(`${CORE}/processos/${idProcesso}/titulos/acordo?${sortedQuery}`);
}

export function findIdsTitulosService(idProcesso) {
  return gateway.get(`${CORE}/processos/${idProcesso}/titulos/acordo/ids`);
}

export function buscarMotivosExclusaoTitulo() {
  return gateway.get(`${CORE}/processos/titulos/motivos-exclusao`);
}
