export const TITLE_CONTA_BANCARIA = 'Contas Padrão';
export const TITULAR_LABEL = 'Titular';
export const CNPJ_LABEL = 'CNPJ';
export const BANCO_LABEL = 'Banco';
export const AGENCIA_LABEL = 'Agência';
export const NUMERO_CONTA_LABEL = 'Número da Conta';
export const DAC_LABEL = 'DAC';
export const BOLETO_CLIENTE = 'Boleto para Cliente';
export const BOLETO_DEVEDOR = 'Boleto para Devedor';
export const CONTAS_ADICIONAIS = 'Contas Adicionais';
export const SISPAG = 'Sispag';
export const TIPO_CHAVE_PIX = 'Tipo de Chave Pix';
export const CHAVE_PIX = 'Chave Pix';
export const CONTA_DEPOSITO = 'Contas adicionais';
