import styled from 'styled-components';
import PaperUI from '../UI/Paper/PaperUI';

export const RootCorrigir = styled(PaperUI)`
  border-radius: 0px;
`;

export const FieldsContainer = styled.div`
  padding: 23px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const FooterCorrigir = styled.footer`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 22px;

  > div:first-child{
    display: flex;
    justify-content: center;
    padding: 7px;

    button{
      width: 100px;
    }
  }

  > div:last-child{
    display: flex;
    justify-content: center;
    flex-direction: row;

    button{
      width: 169px;
      margin: 7px;
    }
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  
  > div:first-child{
    height: 10px;
    background-color: green;
  }
`;
