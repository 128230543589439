import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import useStyles from './CheckboxUI.styles';

function CheckboxUI({
  classControl, label = '', disabled, checked, name, model, ...props
}) {
  const classes = useStyles();
  return (
    <div className={classControl}>
      <FormControlLabel
        disabled={disabled}
        classes={{ disabled: classes.disbaledLabel }}
        control={(
          <Checkbox
            color="primary"
            name={model.name || name}
            checked={model.value || checked}
            classes={{ checked: classes.checkedColor }}
            {...props}
          />
)}
        label={label}
      />
    </div>
  );
}

CheckboxUI.defaultProps = {
  checked: false,
  classControl: '',
  disabled: false,
  name: '',
  model: {
    name: undefined,
    value: undefined,
    error: undefined,
    errorMessage: undefined,
  },
};

CheckboxUI.propTypes = {
  name: PropTypes.string,
  model: PropTypes.object,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  classControl: PropTypes.string,
};

export default React.memo(CheckboxUI);
