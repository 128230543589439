import React from 'react';
import { TableRow } from '@material-ui/core';
import { FaSearchPlus } from 'react-icons/fa';
import { TbAlignBoxCenterMiddle } from 'react-icons/tb';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


import { TableCellCenter, TableCellLeft, TableCellRight } from '../UI/Tabela/tableDefaultStyles';
import CheckboxField from '../UI/Field/Checkbox/CheckboxField';
import TooltipUI from '../UI/Tooltip/Tooltip';
import { IconButton } from '../IconButton/IconButton';

function TableBodyAntecedentesProcessoComponent({
  content,
  onSelectRow,
  disabledSelection,
  openAndSearchDialogResumo,
  accordion,
}) {
  function openNewProcesso(nroProcesso) {
    localStorage.setItem('previousUrl', '');
    window.open(`/processos/inspecao?nroProcesso=${nroProcesso}`, '_blank');
  }

  return content.map(item => (
    <TableRow key={item.id}>
      <TableCellCenter>
        <CheckboxField
          name={String(item.id)}
          disabled={disabledSelection && accordion}
          onChange={onSelectRow}
          checked={item.checked}
        />
      </TableCellCenter>
      <TableCellCenter>{item.numero}</TableCellCenter>
      <TableCellCenter>{item.acordoAtivo ? <CheckCircleIcon style={{ fontSize: '22px' }} /> : ''}</TableCellCenter>
      <TableCellLeft>
        <TooltipUI title={item.fase} placement="bottom">
          <span>{item.fase}</span>
        </TooltipUI>
      </TableCellLeft>

      <TableCellLeft>
        <TooltipUI title={item.negociador} placement="bottom">
          <span>{item.negociador}</span>
        </TooltipUI>
      </TableCellLeft>

      <TableCellCenter>{item.dataAbertura}</TableCellCenter>

      <TableCellLeft>
        <TooltipUI title={item.cliente} placement="bottom">
          <span>{item.cliente}</span>
        </TooltipUI>
      </TableCellLeft>
      <TableCellLeft>
        <TooltipUI title={item.devedor} placement="bottom">
          <span>{item.devedor}</span>
        </TooltipUI>
      </TableCellLeft>
      <TableCellRight>
        <TooltipUI title={item.valorProcesso} placement="bottom">
          <span>{item.valorProcesso}</span>
        </TooltipUI>
      </TableCellRight>

      <TableCellCenter>
        <IconButton
          title="Inspecionar"
          onClick={() => openNewProcesso(item.id)}
        >
          <FaSearchPlus size={14} color="black" />
        </IconButton>
        <IconButton
          title="Resumo"
          onClick={() => openAndSearchDialogResumo(item.numero)}
        >
          <TbAlignBoxCenterMiddle size={15} color="black" />
        </IconButton>
      </TableCellCenter>
    </TableRow>
  ));
}

export const TableBodyAntecedentesProcesso = TableBodyAntecedentesProcessoComponent;
