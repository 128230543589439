import React from 'react';
import { FaExclamationTriangle, FaObjectGroup, FaObjectUngroup } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Box, CircularProgress } from '@material-ui/core';
import {
  TableHeaderAntecedentesProcesso,
  TableBodyAntecedentesProcesso,
  IconButton,
  ResumoAntecedenteProcessoAtual,
  TableDefault,
} from '../../../components';

import {
  toastUnmappedException, TableUtils, getExceptionErrorMessage, getNewPage, hasUserPermissions,
} from '../../../helpers';

import { juntarProcessosService, unificarProcessosService } from '../../../services';
import { tiposConsultasAntecedentesTypes } from '../../../types';
import { AntecedentesProcessoContainer, SemAntecedenteContainer } from './AntecedentesProcessos.styles';
import { loadingInspecaoProcessoController as loadingController } from '../loadingInspecaoProcessoController';
import { AntecedentesProcessosController, AntecedentesProcessosFactory } from './antecedentesProcessosController';
import OptionDialogNew from '../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';
import { buscarResumoDoProcessoService } from '../../../services/core/processos/processosService';
import { ResumoProcesso } from '../Resumo/Resumo';
import DialogNew from '../../../layouts/FormDialog/DialogNew';

const ID_ANTECEDENTE = 'id';

function AntecedenteProcessoComponent({
  idProcesso,
  setLoadings,
  clientePermiteUnificar,
  dadosProcesso,
  updatePage,
  podeModificarFase,
  idFaseProcesso,
  fasesIds,
  accordion,
  antecedentesSelecionados,
  setDisabledAntecedentes,
}) {
  const [antecedentes, setAntecedentes] = React.useState(AntecedentesProcessosFactory.makeAntecedentes());
  const [idsAntecedentes, setIdsAntecedentes] = React.useState(TableUtils.makeAllIdsSelections());
  const [isSelectAll, setIsSelectAll] = React.useState(false);
  const [ordenacao, setOrdenacao] = React.useState(TableUtils.makeColumnOrder({ element: 'idProcesso', order: 'ASC' }));
  const [actionButtonType, setActionButtonType] = React.useState(AntecedentesProcessosFactory.makeShowResumoProcesso());
  const [openDialog, setOpenDialog] = React.useState(false);


  // Resumo Antecedente Dialog
  const [openDialogResumoAntecedente, setOpenDialogResumoAntecedente] = React.useState(false);
  const [loadingDialogResumo, setLoadingDialogResumo] = React.useState(false);
  const [dadosResumoAntecedente, setDadosResumoAntecedente] = React.useState([]);


  async function updateTableAfterChangePageableAttributes(pageable, tipoConsulta, ordem = ordenacao.get()) {
    setLoadings(loadingController.changeLoadingAntecedente(true));
    try {
      const newAntecedentes = await AntecedentesProcessosController.buscarAntecedentesProcesso({
        idProcesso, pageable, ordenacao: ordem, tipoConsulta,
      });
      const updatedAntecedentes = TableUtils.updateTableAfterChangePageOrItemsPerPage({
        newSelectionValue: isSelectAll,
        ids: idsAntecedentes,
        items: newAntecedentes.content,
        itemIdFieldName: ID_ANTECEDENTE,
      });
      setAntecedentes({ ...newAntecedentes, content: updatedAntecedentes.items });
    } catch (e) {
      toastUnmappedException(e, 'Ocorreu um problema ao tentar buscar os documentos');
    } finally {
      setLoadings(loadingController.changeLoadingAntecedente(false));
    }
  }

  async function openAndSearchDialogResumo(idProcessoSelecionado) {
    setOpenDialogResumoAntecedente(true);
    setLoadingDialogResumo(true);
    try {
      const { data } = await buscarResumoDoProcessoService(
        idProcessoSelecionado,
      );
      setDadosResumoAntecedente(data);
    } catch (e) {
      toast.error('Erro ao buscar resumo do processo antecedente.');
    } finally {
      setLoadingDialogResumo(false);
    }
  }

  async function handleChangeOrder(columnName) {
    const pageable = TableUtils.makePageable(antecedentes);
    const newOrdenacao = TableUtils.makeColumnOrder({ element: columnName, order: ordenacao.getNextOrder() });
    updateTableAfterChangePageableAttributes(pageable, actionButtonType.type, newOrdenacao.get()).then();
    setOrdenacao(newOrdenacao);
  }

  function handleSelectAll(e) {
    const { checked } = e.target;
    const newAntecedentes = TableUtils.updateAllItensSelectionByNewSelectionValue({
      newSelectionValue: checked,
      ids: idsAntecedentes,
      items: antecedentes.content,
      itemIdFieldName: ID_ANTECEDENTE,
      currentId: idProcesso,
    });
    if (!accordion) {
      antecedentesSelecionados.splice(0, antecedentesSelecionados.length);
      if (checked) {
        antecedentes.content.forEach(({ id }) => {
          antecedentesSelecionados.push(id);
        });
      }
    }
    setAntecedentes({ ...antecedentes, content: newAntecedentes.items });
    setIdsAntecedentes(newAntecedentes.ids);
    setIsSelectAll(checked);
  }


  function handleSelectRow(e) {
    const newAntecedentes = TableUtils.updateUniqueItemSelectionByCurrentId({
      currentId: Number(e.target.name),
      items: antecedentes.content,
      ids: idsAntecedentes,
      itemIdFieldName: ID_ANTECEDENTE,
    });
    if (!accordion) {
      if (e.target.checked) {
        antecedentesSelecionados.push(Number(e.target.name));
      } else {
        antecedentesSelecionados.splice(antecedentesSelecionados.indexOf(Number(e.target.name)), 1);
      }
    }
    setIdsAntecedentes(newAntecedentes.ids);
    setAntecedentes({ ...antecedentes, content: newAntecedentes.items });
  }

  function handlePageChange(e, newPage) {
    const pageable = { size: antecedentes.size, page: Number(newPage) };
    updateTableAfterChangePageableAttributes(pageable, actionButtonType.type).then();
  }

  function handleChangeRowsPerPage(e) {
    const size = e.target.value;
    const newPage = getNewPage(antecedentes.size, antecedentes.page, size);
    const pageable = { size, page: Number(newPage) };
    updateTableAfterChangePageableAttributes(pageable, actionButtonType.type).then();
  }

  function handleOpenResumoProcessoByType(type) {
    return async () => {
      const newActionButtonType = actionButtonType.changeType(type);
      setLoadings(loadingController.changeLoadingAntecedente(true));
      try {
        const newOrdenacao = TableUtils.makeColumnOrder({ element: 'idProcesso', order: 'ASC' });
        const response = await AntecedentesProcessosController.buscarAntecedentesEIdsAntecedentes(idProcesso, newActionButtonType.type, newOrdenacao);
        setAntecedentes(response.antecedentes);
        setOrdenacao(newOrdenacao);
        setIdsAntecedentes(response.idsAntecedentes);
      } catch {
        toastUnmappedException('Ocorreu um problema ao tentar buscar as informações dos documentos');
      } finally {
        setActionButtonType(newActionButtonType);
        setLoadings(loadingController.changeLoadingAntecedente(false));
      }
    };
  }

  const screenEnabledStatus = React.useMemo(() => {
    const ids = TableUtils.getIdsSelected(idsAntecedentes);
    const hasMoreOneItem = Array.from(idsAntecedentes.values()).length > 1;
    return {
      isSelectAll: hasMoreOneItem && TableUtils.getIsSelectAll(idsAntecedentes),
      idsSelected: TableUtils.getIdsSelected(idsAntecedentes),
      disabledButton: ids?.length <= 1,
      disabledSelectAll: !hasMoreOneItem,
    };
  }, [idsAntecedentes]);

  const atualizarAntecedentesComStatusInicial = React.useCallback(async () => {
    const response = await AntecedentesProcessosController.buscarAntecedentesProcesso({ idProcesso });
    if (!accordion) {
      response.content.forEach((content) => {
        antecedentesSelecionados.forEach((idAntecedentesSelecionado) => {
          if (idAntecedentesSelecionado === content.id) {
            content.checked = true;
          }
        });
      });
    }

    setAntecedentes(response);
    setIdsAntecedentes(TableUtils.makeAllIdsSelections([idProcesso]));
    setActionButtonType(AntecedentesProcessosFactory.makeShowResumoProcesso(tiposConsultasAntecedentesTypes.simples));
  }, [idProcesso, antecedentesSelecionados, accordion]);

  async function applyAction() {
    setLoadings(loadingController.changeLoadingAntecedente(true));
    try {
      if (actionButtonType.isJuntar()) {
        await juntarProcessosService(idProcesso, screenEnabledStatus.idsSelected);
      } else if (actionButtonType.isUnificar()) {
        await unificarProcessosService(idProcesso, screenEnabledStatus.idsSelected);
      }
      atualizarAntecedentesComStatusInicial().then();
      updatePage();
    } catch (error) {
      toast.error(getExceptionErrorMessage(error), { style: { width: '392px' } });
    } finally {
      setLoadings(loadingController.changeLoadingAntecedente(false));
      setOpenDialog(false);
    }
  }

  const disabledActions = React.useMemo(() => {
    const cannotEnabled = fasesIds?.idFaseAcordoSeguro === Number(idFaseProcesso) || fasesIds?.idFaseBaixa === Number(idFaseProcesso);
    return {
      unificar: !hasUserPermissions(1204) || !clientePermiteUnificar || !podeModificarFase || cannotEnabled,
      juntar: !hasUserPermissions(1205) || !podeModificarFase || cannotEnabled,
    };
  }, [idFaseProcesso, clientePermiteUnificar, podeModificarFase, fasesIds]);

  /**
   * Carregar dados iniciais da tela
   */
  React.useEffect(() => {
    setLoadings(loadingController.changeLoadingAntecedente(true));
    atualizarAntecedentesComStatusInicial().then()
      .catch(() => {
        toastUnmappedException('Ocorreu um problema ao tentar buscar as informações dos documentos');
      }).finally(() => {
        setLoadings(loadingController.changeLoadingAntecedente(false));
      });
  }, [atualizarAntecedentesComStatusInicial, setLoadings]);

  /**
   * Atualizar seleção de todos os itens com base no ids selecionados
   */
  React.useEffect(() => {
    if (accordion) {
      setDisabledAntecedentes(antecedentes.content.length === 0);
      setIsSelectAll(screenEnabledStatus.isSelectAll);
    } else {
      setIsSelectAll(antecedentes.content.length === antecedentesSelecionados.length);
    }
  }, [setDisabledAntecedentes, screenEnabledStatus, accordion, antecedentes.content.length, antecedentesSelecionados]);

  return antecedentes.content.length || actionButtonType.hasType() ? (
    <>
      <OptionDialogNew
        open={openDialog}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickConfirm={applyAction}
        onClickCancel={() => setOpenDialog(false)}
        onClose={() => setOpenDialog(false)}
      >
        <div style={{ fontSize: '16px', textAlign: 'left' }}>
          <p>{actionButtonType.getMessage(screenEnabledStatus.idsSelected)}</p>
        </div>
      </OptionDialogNew>
      <DialogNew
        open={openDialogResumoAntecedente}
        text={['Resumo']}
        closeIcon
        onClose={() => setOpenDialogResumoAntecedente(false)}
      >
        {loadingDialogResumo
          ? (
            <Box style={{
              width: '100%', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
            >
              <CircularProgress color="inherit" />
            </Box>
          )
          : (
            <ResumoProcesso
              dadosProcesso={dadosResumoAntecedente}
              disableEditarEndossante
            />
          )
        }
      </DialogNew>
      <AntecedentesProcessoContainer>
        {accordion
          ? (
            <>
              <div>
                <IconButton
                  color="purple"
                  title="Unificar Processos"
                  outlined={actionButtonType.isUnificar()}
                  onClick={handleOpenResumoProcessoByType(
                    tiposConsultasAntecedentesTypes.unificar,
                  )}
                  disabled={disabledActions.unificar}
                >
                  <FaObjectGroup size={18} />
                </IconButton>
                <IconButton
                  color="purple"
                  title="Juntar Processos"
                  outlined={actionButtonType.isJuntar()}
                  onClick={handleOpenResumoProcessoByType(
                    tiposConsultasAntecedentesTypes.juntar,
                  )}
                  disabled={disabledActions.juntar}
                >
                  <FaObjectUngroup size={18} />
                </IconButton>
              </div>
              <ResumoAntecedenteProcessoAtual
                idProcesso={idProcesso}
                dadosProcesso={dadosProcesso}
                open={actionButtonType.isOpen()}
                title={actionButtonType.getTitle()}
                buttonLabel={actionButtonType.getLabelButton()}
                disabledButton={screenEnabledStatus.disabledButton}
                onClick={() => setOpenDialog(true)}
              />
            </>
          )
          : (<></>)}

        <div>
          <TableDefault
            colgroup={[
              '0',
              '80px',
              '104px',
              '120px',
              '96px',
              '104px',
              '104px',
              '24px',
            ]}
            columnsHead={(
              <TableHeaderAntecedentesProcesso
                disabledSelection={
                  !actionButtonType.hasType()
                  || screenEnabledStatus.disabledSelectAll
                }
                isSelectedAll={isSelectAll}
                onSelectAll={handleSelectAll}
                changeOrder={handleChangeOrder}
                order={ordenacao}
                arrowDirection={ordenacao.order === 'DESC'}
                accordion={accordion}
              />
            )}
            page={antecedentes.page}
            rowsPerPage={antecedentes.size}
            totalElements={antecedentes.totalElements}
            totalElementsOnPage={antecedentes.content?.length}
            totalColumns={10}
            rowsPerPageOptions={[10, 15, 20]}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          >
            <TableBodyAntecedentesProcesso
              disabledSelection={!actionButtonType.hasType()}
              onSelectRow={handleSelectRow}
              content={antecedentes.content}
              openAndSearchDialogResumo={openAndSearchDialogResumo}
              accordion={accordion}
            />
          </TableDefault>
        </div>
      </AntecedentesProcessoContainer>
    </>
  ) : (
    <SemAntecedenteContainer>
      <FaExclamationTriangle size={48} color="#ffa000" />
      <b>Não foram encontrados Antecedentes para este Processo.</b>
    </SemAntecedenteContainer>
  );
}

export const AntecedenteProcesso = AntecedenteProcessoComponent;
