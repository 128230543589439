import React from 'react';
import { useDispatch } from 'react-redux';
import { initializePageAction } from '../../../store/theme.actions';
import AcordosAs from '../../../components/AcordoSeguro/Acordos/AcordosAs';
import withTokenInterceptor from '../../../hoc/withTokenInterceptor/withTokenInterceptor';

function AcordosASPage() {
  const dispatch = useDispatch();

  dispatch(initializePageAction('Acordos'));

  return (<AcordosAs />);
}

export default withTokenInterceptor(AcordosASPage);
