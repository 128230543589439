import {
  DateUtils,
  getDatePlus, toAmericanDate, toBrazilianDate,
} from '../../../../../helpers';
import FieldModel from '../../../../../models/FieldModel';
import { findParcelasNovoAcordo, findRecalcularAcordo } from '../../../../../services/core/processos/processosAcordosService';


class AcordoPassoDoisController {
  makeFormFields(values) {
    return {
      dataPrimeiroVencimento: new FieldModel({
        name: 'dataPrimeiroVencimento',
        value: toBrazilianDate(values?.dataPrimeiroVencimento) || getDatePlus(1),
      }),
      idTipoFormaPagto: new FieldModel({
        name: 'idTipoFormaPagto',
        value: values?.idTipoFormaPagto || 0,
      }),
      idIntervaloPagamento: new FieldModel({
        name: 'idIntervaloPagamento',
        value: values?.idIntervaloPagamento || 0,
      }),
      quantidadeParcelas: new FieldModel({
        name: 'quantidadeParcelas',
        value: values?.quantidadeParcelas || '1',
      }),
      porcentagemJuros: new FieldModel({
        name: 'porcentagemJuros',
        value: values?.porcentagemJuros || '',
      }),
      porcentagemHonorarios: new FieldModel({
        name: 'porcentagemHonorarios',
        value: values?.porcentagemHonorarios || '',
      }),
      porcentagemDesconto: new FieldModel({
        name: 'porcentagemDesconto',
        value: values?.porcentagemDesconto || 0,
      }),
      qtdeMaximaParcelasCartao: new FieldModel({
        name: 'qtdeMaximaParcelasCartao',
        value: values?.qtdeMaximaParcelasCartao || 0,
      }),
    };
  }

  makePayload(fields, idsTitulos = [], dadosDefault) {
    return {
      idsTitulos,
      porcentagemTaxaJuros: Number(fields?.porcentagemJuros.value),
      porcentagemHonorarios: Number(fields.porcentagemHonorarios.value),
      porcentagemDesconto: dadosDefault.temCampanha === 'N' ? Number('0.00') : Number(fields.porcentagemDesconto.value),
      qtdeParcelas: fields?.quantidadeParcelas.value,
      idIntervaloPagamento: fields?.idIntervaloPagamento.value,
      dataPrimeiroVencimento: toAmericanDate(fields?.dataPrimeiroVencimento.value),
      idTipoFormaPagto: fields?.idTipoFormaPagto.value,
    };
  }

  makePayloadParcelas(dadosResumo, idProcesso, formFields) {
    return {
      idProcesso,
      valorTotal: String(dadosResumo.valorTotal),
      qtdeParcelas: formFields.quantidadeParcelas.value,
      intervaloParcelas: formFields.idIntervaloPagamento.value,
      primeiroVencimento: toAmericanDate(formFields.dataPrimeiroVencimento.value),
      idTipoFormaPagto: formFields.idTipoFormaPagto.value,
    };
  }

  buildParcelas(data) {
    return data.map(parcela => ({
      formaPagamento: parcela.idTipoFormaPagto,
      vencimento: DateUtils.formatTextDate(parcela.dataVencimento),
      valor: parcela.valor,
      nParcela: parcela.nroParcela,
      redistribuirValores: parcela.redistribuirValores,
      chequeDetalhe: parcela ? parcela?.chequeDetalhe : [this.buildChequeDetalhe(parcela)],
    }));
  }

  buildChequeDetalhe(parcela) {
    return {
      idBanco: {
        value: parcela?.chequeDetalhe.idBanco,
        error: false,
        errorMessage: '',
      },
      serChequeTerceiro: {
        value: parcela?.chequeDetalhe.serChequeTerceiro,
        error: false,
        errorMessage: '',
      },
      numeroAgencia: {
        value: parcela?.chequeDetalhe.numeroAgencia,
        error: false,
        errorMessage: '',
      },
      numeroConta: {
        value: parcela?.chequeDetalhe.numeroConta,
        error: false,
        errorMessage: '',
      },
      dac: {
        value: parcela?.chequeDetalhe.dac,
        error: false,
        errorMessage: '',
      },
      numeroCheque: {
        value: parcela?.chequeDetalhe.numeroCheque,
        error: false,
        errorMessage: '',
      },
      valor: {
        value: parcela?.chequeDetalhe.valor,
        error: false,
        errorMessage: '',
      },
    };
  }

  buildFormFields(formFields) {
    return {
      dataPrimeiroVencimento: toAmericanDate(formFields.dataPrimeiroVencimento.value),
      idTipoFormaPagto: formFields.idTipoFormaPagto.value,
      idIntervaloPagamento: formFields.idIntervaloPagamento.value,
      porcentagemDesconto: formFields.porcentagemDesconto.value,
      porcentagemHonorarios: formFields.porcentagemHonorarios.value,
      porcentagemJuros: formFields.porcentagemJuros.value,
      quantidadeParcelas: formFields.quantidadeParcelas.value,
    };
  }

  async recalcularAcordo(idProcesso, fields, idsTitulos, dadosDefault) {
    const payload = this.makePayload(fields, idsTitulos, dadosDefault);

    const response = await findRecalcularAcordo(idProcesso, payload);
    return response;
  }

  async criarParcelasAcordo(dadosResumo, idProcesso, formFields) {
    const payload = this.makePayloadParcelas(dadosResumo, idProcesso, formFields);

    const response = await findParcelasNovoAcordo(payload);
    return response;
  }
}

export const acordoPassoDoisController = new AcordoPassoDoisController();
