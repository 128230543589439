import FieldModel from '../../../models/FieldModel';
import { getAttributeExistingObject } from '../../../helpers/utils/utils';

import { buildEndereco } from '../../../helpers/factory/endereco.factory';
import { buildTelefones } from '../../../helpers/factory/telefone.factory';
import { buildSnackbar, buildDialog } from '../../../helpers/factory/global.factory';

const SET_REQUEST_FIELDS_VALUE = 'SET_REQUEST_FIELDS_VALUE_CRUD_FILIAL';
const SET_REQUEST_FIELDS_FOCUS = 'SET_REQUEST_FIELDS_FOCUS_CRUD_FILIAL';
const SET_ENDERECO_ATTRIBUTE_VALUE = 'SET_ENDERECO_ATTRIBUTE_VALUE_CRUD_FILIAL';
const SET_ENDERECO_ATTRIBUTE_FOCUS = 'SET_ENDERECO_ATTRIBUTE_FOCUS_CRUD_FILIAL';
const SET_ENDERECO = 'SET_ENDERECO_CRUD_FILIAL';
const SET_TELEFONE_ATTRIBUTE_VALUE = 'SET_TELEFONE_ATTRIBUTE_VALUE_CRUD_FILIAL';
const SET_TELEFONE_ATTRIBUTE_FOCUS = 'SET_TELEFONE_ATTRIBUTE_FOCUS_CRUD_FILIAL';
const SET_TELEFONES = 'SET_TELEFONES_CRUD_FILIAL';
const SET_LOADING = 'SET_LOADING_CRUD_FILIAL';
const SET_FILIAL = 'SET_FILIAL_CRUD_FILIAL';
const UPDATE_REQUEST_FIELDS = 'UPDATE_REQUEST_FIELDS_CRUD_FILIAL';
const SET_SNACKBAR = 'SET_SNACKBAR_CRUD_FILIAL';
const SET_ALERT_DIALOG = 'SET_ALERT_DIALOG_CRUD_FILIAL';
const RELOAD_SELECTORS = 'RELOAD_SELECTORS_CRUD_FILIAL';
const CLEAR_REQUEST_FIELDS = 'CLEAR_REQUEST_FIELDS_CRUD_FILIAL';
const GO_TO_BACK_PAGE = 'GO_TO_BACK_PAGE_CRUD_FILIAL';

function buildRequestFields(fields) {
  return {
    idFilial: new FieldModel({
      value: getAttributeExistingObject(fields, 'idFilial'),
    }),
    principal: new FieldModel({
      value: getAttributeExistingObject(fields, 'principal', 'N'),
    }),
    idUsuario: new FieldModel({
      name: 'idUsuario',
      value: getAttributeExistingObject(fields, 'idUsuario'),
    }),
    idCentroCusto: new FieldModel({
      name: 'idCentroCusto',
      value: getAttributeExistingObject(fields, 'idCentroCusto'),
    }),
    nomeFilial: new FieldModel({
      name: 'nomeFilial',
      value: getAttributeExistingObject(fields, 'nomeFilial'),
      maxLength: 60,
    }),
    email: new FieldModel({
      name: 'email',
      value: getAttributeExistingObject(fields, 'email'),
      maxLength: 50,
    }),
    statusFilial: new FieldModel({
      name: 'statusFilial',
      value: getAttributeExistingObject(fields, 'statusFilial', 'A'),
    }),
    endereco: buildEndereco(getAttributeExistingObject(fields, 'endereco')),
    telefones: buildTelefones(getAttributeExistingObject(fields, 'telefones')),
    valorReferenciaCaixaFilial: new FieldModel({
      name: 'valorReferenciaCaixaFilial',
      value: getAttributeExistingObject(fields, 'valorReferenciaCaixaFilial'),
    }),
    percentualAlertaCaixaFilial: new FieldModel({
      name: 'percentualAlertaCaixaFilial',
      value: getAttributeExistingObject(fields, 'percentualAlertaCaixaFilial'),
    }),
  };
}

function buildInitialStates(fields) {
  return {
    requestFields: buildRequestFields(fields),
    snackbar: buildSnackbar(),
    loading: false,
    reloadSelectors: false,
    alertDialog: buildDialog(),
  };
}

function changeRequestFieldsValueHandler(states, actions) {
  const { name, value } = actions;
  const fieldModel = states.requestFields[actions.name].copy();
  return { ...states, requestFields: { ...states.requestFields, [name]: fieldModel.onChange(value) } };
}

function changeRequestFieldsFocusHandler(states, actions) {
  const { name } = actions;
  const fieldModel = states.requestFields[actions.name].copy();
  return { ...states, requestFields: { ...states.requestFields, [name]: fieldModel.onFocus() } };
}

function changeEnderecoAttributeValueHandler(states, actions) {
  const { name, value } = actions;
  const field = states.requestFields.endereco[name].getNewModel(value);
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      endereco: {
        ...states.requestFields.endereco,
        [name]: field,
      },
    },
  };
}

function changeEnderecoAttributeFocusHandler(states, actions) {
  const field = states.requestFields.endereco[actions.name].copy();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      endereco: {
        ...states.requestFields.endereco,
        [actions.name]: field.onFocus(),
      },
    },
  };
}

function changeEnderecoHandler(states, actions) {
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      endereco: { ...actions.endereco },
    },
  };
}

function changeTelefoneAttributeValueHandler(states, actions) {
  const telefones = [...states.requestFields.telefones];
  telefones[actions.index][actions.name].onChange(actions.value);
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      telefones,
    },
  };
}

function changeTelefoneAttributeFocusHandler(states, actions) {
  const telefones = [...states.requestFields.telefones];
  telefones[actions.index][actions.name].onFocus();
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      telefones,
    },
  };
}

function changeTelefonesHandler(states, actions) {
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      telefones: [...actions.telefones],
    },
  };
}

function changeLoadingHandler(states, actions) {
  return {
    ...states,
    loading: actions.loading,
  };
}

function updateRequestFieldsHandler(states, actions) {
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      ...actions.updatedRequestFields,
    },
  };
}

function changeSnackbarValueHandler(states, actions) {
  const snackbar = { ...states.snackbar, open: actions.open };
  if (actions.variant || actions.message) {
    snackbar.variant = actions.variant;
    snackbar.message = actions.message;
  }
  return { ...states, snackbar };
}

function changeAlertDialogValueHandler(states, actions) {
  const alertDialog = { ...states.alertDialog, open: actions.open };
  if (actions.variant || actions.message) {
    alertDialog.variant = actions.variant;
    alertDialog.message = actions.message;
    alertDialog.origin = actions.origin;
  }
  return { ...states, alertDialog };
}

function reloadSelectorsHandler(states) {
  const idUsuario = states.requestFields.idUsuario.getNewModel('');
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      idUsuario,
    },
    reloadSelectors: !states.reloadSelectors,
  };
}

function clearRequestFieldsHandler(states) {
  return { ...states, requestFields: buildRequestFields() };
}

function updateFilialHandler(actions) {
  return buildInitialStates(actions.filial);
}

function changeGoToBackPageHandler(states, actions) {
  return {
    ...states,
    goToBackPage: actions.goToBackPage,
  };
}

export const setRequestFieldsValueAction = (name, value) => ({
  type: SET_REQUEST_FIELDS_VALUE,
  name,
  value,
});

export const setRequestFieldsFocusAction = name => ({
  type: SET_REQUEST_FIELDS_FOCUS,
  name,
});

export const setEnderecoAttributeValueAction = (name, value) => ({
  type: SET_ENDERECO_ATTRIBUTE_VALUE,
  name,
  value,
});

export const setEnderecoAttributeFocusAction = name => ({
  type: SET_ENDERECO_ATTRIBUTE_FOCUS,
  name,
});

export const setEnderecoAction = endereco => ({
  type: SET_ENDERECO,
  endereco,
});

export const setTelefoneAttributeValueAction = (name, value, index) => ({
  type: SET_TELEFONE_ATTRIBUTE_VALUE,
  name,
  value,
  index,
});

export const setTelefoneAttributeFocusAction = (name, index) => ({
  type: SET_TELEFONE_ATTRIBUTE_FOCUS,
  name,
  index,
});

export const setTelefonesAction = telefones => ({
  type: SET_TELEFONES,
  telefones,
});

export const setLoadingAction = loading => ({
  type: SET_LOADING,
  loading,
});

export const updateRequestFieldsAction = updatedRequestFields => ({
  type: UPDATE_REQUEST_FIELDS,
  updatedRequestFields,
});

export const setSnackbarValuesAction = (open, variant, message) => ({
  type: SET_SNACKBAR,
  open,
  variant,
  message,
});

export const setAlertDialogValuesAction = (open, variant, message, origin) => ({
  type: SET_ALERT_DIALOG,
  open,
  variant,
  message,
  origin,
});

export const reloadSelectorsAction = () => ({
  type: RELOAD_SELECTORS,
});

export const clearRequestFieldsAction = () => ({
  type: CLEAR_REQUEST_FIELDS,
});

export const setFilialAction = filial => ({
  type: SET_FILIAL,
  filial,
});

export const setGoToBackPageAction = goToBackPage => ({
  type: GO_TO_BACK_PAGE,
  goToBackPage,
});

export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    case SET_REQUEST_FIELDS_VALUE:
      return changeRequestFieldsValueHandler(states, actions);
    case SET_REQUEST_FIELDS_FOCUS:
      return changeRequestFieldsFocusHandler(states, actions);
    case SET_ENDERECO_ATTRIBUTE_VALUE:
      return changeEnderecoAttributeValueHandler(states, actions);
    case SET_ENDERECO_ATTRIBUTE_FOCUS:
      return changeEnderecoAttributeFocusHandler(states, actions);
    case SET_ENDERECO:
      return changeEnderecoHandler(states, actions);
    case SET_TELEFONE_ATTRIBUTE_VALUE:
      return changeTelefoneAttributeValueHandler(states, actions);
    case SET_TELEFONE_ATTRIBUTE_FOCUS:
      return changeTelefoneAttributeFocusHandler(states, actions);
    case SET_TELEFONES:
      return changeTelefonesHandler(states, actions);
    case SET_LOADING:
      return changeLoadingHandler(states, actions);
    case UPDATE_REQUEST_FIELDS:
      return updateRequestFieldsHandler(states, actions);
    case SET_SNACKBAR:
      return changeSnackbarValueHandler(states, actions);
    case SET_ALERT_DIALOG:
      return changeAlertDialogValueHandler(states, actions);
    case CLEAR_REQUEST_FIELDS:
      return clearRequestFieldsHandler(states);
    case RELOAD_SELECTORS:
      return reloadSelectorsHandler(states);
    case SET_FILIAL:
      return updateFilialHandler(actions);
    case GO_TO_BACK_PAGE:
      return changeGoToBackPageHandler(states, actions);
    default:
      return states;
  }
};
