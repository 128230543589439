import React from 'react';

import { Typography } from '@material-ui/core';

import CampoObrigatorio from '../../../UI/CampoObrigatorio/CampoObrigatorio';

import { statusProcessamentoBordero } from '../../../../helpers/constants/enums.constants';
import useStyles from './HeaderCrudBordero.styles';

import { useCrudBorderoSelector } from '../../../../store/Borderos/Crud/CrudBorderoProvider';

const layoutConstants = {
  CADASTRAMENTO: 'Cadastramento: ',
  PROCESSAMENTO: 'Processamento: ',
  STATUS: 'Status: ',
};

export default function HeaderCrudBordero({ wrapperByMode }) {
  const styles = useStyles();
  const dataCadastramento = useCrudBorderoSelector(states => states.requestFields.dataCadastramento.copy());
  const dataProcessamento = useCrudBorderoSelector(states => states.requestFields.dataProcessamento.copy());
  const statusBordero = useCrudBorderoSelector(states => states.requestFields.statusBordero.value);

  const buildComponentForInspectMode = () => (
    <>
      <div className={styles.wrapper}>
        <div style={{ width: '276px' }}>
          <Typography component="p">
            <span>{layoutConstants.CADASTRAMENTO}</span>
            {dataCadastramento.getDateTime()}
          </Typography>
        </div>
        <div style={{ width: '276px' }}>
          <Typography component="p">
            <span>{layoutConstants.PROCESSAMENTO}</span>
            {dataProcessamento.getDateTime()}
          </Typography>
        </div>
        <div style={{ width: '280px' }}>
          <Typography component="p">
            <span>{layoutConstants.STATUS}</span>
            {statusProcessamentoBordero[statusBordero]}
          </Typography>
        </div>
      </div>
      <hr />
    </>
  );

  return (
    <div className={styles.root}>
      {wrapperByMode(<CampoObrigatorio />, buildComponentForInspectMode())}
    </div>
  );
}
