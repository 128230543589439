import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { ButtonUI } from '../../../../../components';
import { lightGrey, strongPink, white } from '../../../../../helpers/constants/colors.constants';


export const Container = styled.div`
  margin: 6px 0px 12px 0;
  box-Shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-Color: ${white};

  >div {
    > div {
       display:flex ;
      }
     }
`;

export const FiltroPesquisa = styled.div`
  display: flex ;
  width:57% ;
 
  > div {
    display: flex ;
    flex-direction:row ; 
      > button {
        background-color: ${strongPink};
        color: ${white};
        width: 44px;
        height: 44px;
        margin-left: 7px;

  &:hover {
    background-color: ${strongPink};
    opacity: .7;
  }

  &:disabled {
    border: 1px solid ${lightGrey};
      }
    }  
  }
`;

export const ButtonImprimir = styled(Button)`
margin-top: 22px;
display: flex;
justify-content: space-between;

&.MuiButtonBase-root {
  padding: 4px 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: none;
  color: #000000;

  &.Mui-disabled {
    opacity: 0.64;
  }
}
`;

export const ContainerResumo = styled.div`
  display: flex ;
  width:43%;
  justify-content:center ;
  gap:8px ;
  flex-flow:column ;

  > h3 {
    display:flex ;
    justify-content:center ;
  }

  > div {
    display:flex ;
    flex-direction:row ;
    gap:10px ;
    justify-content:center ;
    color:${white} ;

    h3 {
      text-align:center ;
      font-size:14px ;
    }

    h4 {
      text-align:center ;
      font-size:14px ;
      font-weight:700 ;
    }
  }
 
  > div + div{
   justify-content: end;
   margin-top: 22px;
  }
  `;

export const ButtonLiberar = styled(ButtonUI)`
width: 176px;
height: 36px;
`;

export const TableHeader = styled.div`
  margin-bottom:13px ;
  margin-top:24px ;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
`;
