import { toast } from 'react-toastify';
import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import {
  buildPayload, toAmericanDate, toastUnmappedException, openPagePDF,
} from '../../helpers';
import { getExceptionHandler } from '../../helpers/utils/exception.util';

import RecebimentoClienteService from '../../services/core/RecebimentoService/RecebimentoClienteService';
import {
  buildValoresCards, formEditarAction, resetFormFieldsNovoAction, saveWithErrorAction, setCommonAction, setSimpleRecebimentoAction, setSimpleValueInfoCardsAction,
} from './RecebimentoCliente.store';
import {
  BUSCAR_INFO_CARDS, BUSCAR_INFO_EDITAR, CANCEL_LIBERAR_RECEBIMENTO_CLIENTE_SELECIONADO, DELETE_RECEBIMENTO_CLIENTE_SELECIONADO, IMPORTAR_ARQUIVO_LANCAMENTO, INSERT_LANCAMENTO, LIBERAR_RECEBIMENTO_CLIENTE_SELECIONADO, ON_CLICK_BUTTON_IMPRIMIR_ACTION, SET_BUSCAR_DADOS_TABELA,
} from './RecebimentoClientes.constants';


function* successPesquisarRecebimento(data, saldos) {
  yield put(setSimpleRecebimentoAction('tableView', data.data));
  yield put(setSimpleRecebimentoAction('totalElements', data.data.totalElements));
  yield put(setSimpleRecebimentoAction('rowsPerPage', data.data.pageable.pageSize));
  yield put(setSimpleRecebimentoAction('valoresCards', buildValoresCards(saldos.data)));
}

function* emptyPesquisaRecebimento() {
  yield put(setSimpleRecebimentoAction('tableView', []));
  yield put(setSimpleRecebimentoAction('saldos', buildValoresCards(null)));
  yield put(setSimpleRecebimentoAction('totalElements', 0));
}

function* sucessInfoCards(data) {
  yield put(setSimpleValueInfoCardsAction('valorTotalRecebimentosDia', data.valorTotalRecebimentosDia));
  yield put(setSimpleValueInfoCardsAction('lancamentosNaoLiberadosDia', data.lancamentosNaoLiberadosDia));
}

function buildImportarRetorno(response) {
  return {
    qtdeBoletosInseridos: response?.qtdeBoletosInseridos || null,
    qtdeBoletosNaoInseridos: response?.qtdeBoletosNaoInseridos || null,
    erroArquivo: response?.erroArquivo || false,
  };
}

function* successImportarRetorno(data) {
  yield put(setSimpleRecebimentoAction('arquivosProcessados', buildImportarRetorno(data)));
}

function* buscarDadosTabelaHandler() {
  yield put(setCommonAction('loadingPage', true));
  const data = yield select(states => states.requestFields.data);
  const lancamentosNaoLiberados = yield select(states => states.requestFields.lancamentosNaoLiberados);
  const page = yield select(states => states.page);
  const rowsPerPage = yield select(states => states.rowsPerPage);
  const columnOrdenacao = yield select(states => states.columnOrdenacao);
  const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];

  let somenteNaoLiberados = '';
  if (lancamentosNaoLiberados.value === true) {
    somenteNaoLiberados = 'S';
  } else {
    somenteNaoLiberados = 'N';
  }
  try {
    const response = yield call(RecebimentoClienteService.buscarRecebimento, toAmericanDate(data.value), somenteNaoLiberados, page, rowsPerPage, order);
    const valoresCards = yield call(RecebimentoClienteService.getInfoCards, toAmericanDate(data.value));
    yield successPesquisarRecebimento(response, valoresCards);
  } catch (e) {
    yield emptyPesquisaRecebimento();
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* onClickButtonImprimirHandler(actions) {
  const { data, lancamentosNaoLiberados } = actions;

  const columnOrdenacao = yield select(states => states.columnOrdenacao);
  const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];

  yield put(setCommonAction('loadingPage', true));
  try {
    const response = yield call(RecebimentoClienteService.onClickButtonImprimirHandler, data, lancamentosNaoLiberados, order);
    if (response.data.size > 0) {
      openPagePDF(response.data, response.data.type);
    }
  } catch (e) {
    toastUnmappedException(
      e,
      'Ocorreu um problema ao tentar abrir o relatório',
    );
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* importarArquivoHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { arquivoLancamentosRetorno } = actions;

  try {
    const response = yield call(RecebimentoClienteService.importarLancamento, arquivoLancamentosRetorno);
    if (response.data.erroArquivo === true) {
      yield put(setSimpleRecebimentoAction('openDialogErro', true));
    } else {
      yield successImportarRetorno(response.data);
      yield put(setSimpleRecebimentoAction('openDialogSucesso', true));
      yield buscarDadosTabelaHandler();
    }
  } catch (e) {
    toast.error('Ocorreu um erro ao processar o arquivo. Tente novamente.', { style: { width: '392px' } });
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* buscarInfoCardsHandler() {
  yield put(setCommonAction('loadingPage', true));
  const data = yield select(states => states.requestFields.data);
  try {
    const response = yield call(RecebimentoClienteService.getInfoCards, toAmericanDate(data.value));
    yield sucessInfoCards(response.data);
  } catch (e) {
    toastUnmappedException('Erro na obtenção de dados');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}


function* deleteRecebimentoHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { idLancamentoCliente } = actions;
  try {
    yield put(setSimpleRecebimentoAction('openDialogExcluir', false));
    yield call(RecebimentoClienteService.deleteLancamento, idLancamentoCliente);
    yield put(setSimpleRecebimentoAction('idCliente', null));
  } catch (e) {
    toastUnmappedException(e, `Ocorreu um problema ao tentar excluir Lancamento ${idLancamentoCliente}`);
  } finally {
    yield put(setCommonAction('loadingPage', false));
    yield buscarDadosTabelaHandler();
  }
}

function* cancelLiberarRecebimentoHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { idLancamentoCliente } = actions;

  try {
    yield put(setSimpleRecebimentoAction('openDialogCancel', false));
    yield call(RecebimentoClienteService.cancelarLiberarLancamento, idLancamentoCliente);
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema ao cancelar lançamento');
  } finally {
    yield put(setCommonAction('loadingPage', false));
    yield buscarDadosTabelaHandler();
  }
}

function* liberarRecebimentoHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { idLancamentoCliente } = actions;

  try {
    yield call(RecebimentoClienteService.cancelarLiberarLancamento, idLancamentoCliente);
  } catch (e) {
    toastUnmappedException(e, 'Ocorreu um problema ao liberar lançamento');
  } finally {
    yield put(setCommonAction('loadingPage', false));
    yield buscarDadosTabelaHandler();
  }
}

function* inserirLancamentoHandler(actions) {
  yield put(setCommonAction('loadingPage', true));
  const { isEditMode } = actions;
  const formFields = yield select(state => state.formFields);
  try {
    const payload = buildPayload(formFields);
    yield call(RecebimentoClienteService.inserir, payload);

    toast.success('Lançamento inserido com sucesso!');
    if (isEditMode) {
      yield put(setSimpleRecebimentoAction('closeEdit', true));
    }
    yield put(resetFormFieldsNovoAction());
  } catch (e) {
    if (e.response) {
      toast.warning('Revise as informações e preencha o cadastro corretamente');
    }
    const [updateWithError] = yield getExceptionHandler(e, formFields);
    yield put(saveWithErrorAction(updateWithError));
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}


function* buscarInfoEditarHandler(actions) {
  yield put(setCommonAction('loadingPage', true));

  const { idLancamentoCliente } = actions;

  try {
    const response = yield call(RecebimentoClienteService.getInfosEditar, idLancamentoCliente);
    yield put(formEditarAction(response.data));
  } catch (e) {
    toastUnmappedException('Erro na obtenção de dados');
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

export const onClickButtonImprimirAction = (data, lancamentosNaoLiberados) => ({
  type: ON_CLICK_BUTTON_IMPRIMIR_ACTION,
  data,
  lancamentosNaoLiberados,

});

export const importarArquivoRetornoAction = arquivoLancamentosRetorno => ({
  type: IMPORTAR_ARQUIVO_LANCAMENTO,
  arquivoLancamentosRetorno,
});

export const buscarInfosCardsAction = () => ({
  type: BUSCAR_INFO_CARDS,
});

export const buscarDadosTabelaAction = () => ({
  type: SET_BUSCAR_DADOS_TABELA,
});

export const deleteRecebimentoSelecionadoAction = idLancamentoCliente => ({
  type: DELETE_RECEBIMENTO_CLIENTE_SELECIONADO,
  idLancamentoCliente,
});

export const cancelarLiberarRecebimentoSelecionadoAction = idLancamentoCliente => ({
  type: CANCEL_LIBERAR_RECEBIMENTO_CLIENTE_SELECIONADO,
  idLancamentoCliente,
});

export const liberarRecebimentoSelecionadoAction = idLancamentoCliente => ({
  type: LIBERAR_RECEBIMENTO_CLIENTE_SELECIONADO,
  idLancamentoCliente,
});

export const inserirLancamentoAction = isEditMode => ({
  type: INSERT_LANCAMENTO,
  isEditMode,
});


export const buscarInfosEditarAction = idLancamentoCliente => ({
  type: BUSCAR_INFO_EDITAR,
  idLancamentoCliente,
});

export default function* watchRecebimento() {
  yield takeLatest(IMPORTAR_ARQUIVO_LANCAMENTO, importarArquivoHandler);
  yield takeLatest(BUSCAR_INFO_CARDS, buscarInfoCardsHandler);
  yield takeLatest(SET_BUSCAR_DADOS_TABELA, buscarDadosTabelaHandler);
  yield takeLatest(DELETE_RECEBIMENTO_CLIENTE_SELECIONADO, deleteRecebimentoHandler);
  yield takeLatest(CANCEL_LIBERAR_RECEBIMENTO_CLIENTE_SELECIONADO, cancelLiberarRecebimentoHandler);
  yield takeLatest(LIBERAR_RECEBIMENTO_CLIENTE_SELECIONADO, liberarRecebimentoHandler);
  yield takeLatest(INSERT_LANCAMENTO, inserirLancamentoHandler);
  yield takeLatest(BUSCAR_INFO_EDITAR, buscarInfoEditarHandler);
  yield takeLatest(ON_CLICK_BUTTON_IMPRIMIR_ACTION, onClickButtonImprimirHandler);
}
