import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';

import {
  savePastaFailureAction,
  savePastaSuccessAction,
  setAttributesAction,
  upadateAfterGetPastaSuccessAction,
} from './pastas.store';

import {
  deletePastas,
  editPasta,
  findPastas,
  getPasta,
  insertPasta,
} from '../../../services/core/fasePasta/fasePasta.services';

import {
  buildPayload,
  getExceptionMessage,
  isBadRequestException,
  isConflictException,
} from '../../../helpers/utils/utils';


import { getExceptionHandler, isExceptionFromValidations } from '../../../helpers/utils/exception.util';


const GET_PASTA = '@pasta/GET_PASTA';
const FIND_PASTAS = '@pasta/FIND_PASTAS';
const REMOVE = '@pasta/REMOVE';
const SAVE = '@pasta/SAVE';


export const getPastaAction = (idFasePasta, idFase) => ({
  type: GET_PASTA,
  idFasePasta,
  idFase,
});

export const findPastasAction = idFase => ({
  type: FIND_PASTAS,
  idFase,
});

export const deletePastaAction = (idFasePasta, idFase) => ({
  type: REMOVE,
  idFasePasta,
  idFase,
});

export const savePastaAction = (isEditMode, idFase) => ({
  type: SAVE,
  isEditMode,
  idFase,
});

function* searchPastas(idFase) {
  const { data } = yield call(findPastas, idFase);
  yield put(setAttributesAction('content', data));
}

function* findPastasHandler(actions) {
  const { idFase } = actions;
  yield put(setAttributesAction('loading', true));
  try {
    yield searchPastas(idFase);
  } finally {
    yield put(setAttributesAction('loading', false));
  }
}

function* removePastasHandler({ idFasePasta, idFase }) {
  yield put(setAttributesAction('loading', true));
  try {
    yield call(deletePastas, idFasePasta);
  } catch (err) {
    if (isBadRequestException(err) || isConflictException(err)) {
      const message = getExceptionMessage(err);
      yield put(setAttributesAction('alert', { message, show: true }));
    }
  } finally {
    yield searchPastas(idFase);
    yield put(setAttributesAction('loading', false));
  }
}

function getSaveRequest(isEditMode) {
  return isEditMode ? editPasta : insertPasta;
}

function getPayload(requestFields, idFase) {
  return {
    ...buildPayload(requestFields),
    idFase,
  };
}

function* savePastaHandler({ isEditMode, idFase }) {
  const requestFields = yield select(state => state.requestFields);
  yield put(setAttributesAction('loading', true));
  try {
    const payload = yield call(getPayload, requestFields, idFase);
    yield call(getSaveRequest(isEditMode), payload);
    yield searchPastas(idFase);
    yield put(savePastaSuccessAction(isEditMode));
  } catch (err) {
    if (isBadRequestException(err) && isExceptionFromValidations(err)) {
      const [updatedFields] = yield getExceptionHandler(err, requestFields);
      yield put(savePastaFailureAction(updatedFields));
    }
  } finally {
    yield put(setAttributesAction('loading', false));
  }
}

function* getPastasHandler({ idFasePasta, idFase }) {
  yield put(setAttributesAction('loading', true));
  try {
    const { data } = yield call(getPasta, idFasePasta);
    yield put(upadateAfterGetPastaSuccessAction(data));
  } catch (err) {
    if (isBadRequestException(err) || isConflictException(err)) {
      const message = getExceptionMessage(err);
      yield put(setAttributesAction('alert', { message, show: true }));
    }
  } finally {
    yield searchPastas(idFase);
    yield put(setAttributesAction('loading', false));
  }
}

export default function* watchPastas() {
  yield all([
    takeLatest(FIND_PASTAS, findPastasHandler),
    takeLatest(REMOVE, removePastasHandler),
    takeLatest(SAVE, savePastaHandler),
    takeLatest(GET_PASTA, getPastasHandler),
  ]);
}
