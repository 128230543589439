import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Telefones from '../../../UI/TelefonesV2/Telefones';
import {
  setTelefoneAttributeFocusAction,
  setTelefoneAttributeValueAction,
  setTelefonesAction,
} from '../../../../store/Parametros/Crud/crudParametros.store';
import {
  useCrudParametrosDispatch,
  useCrudParametrosSelector,
} from '../../../../store/Parametros/Crud/CrudParametrosProvider';
import { findTiposTelefoneAction } from '../../../../store/Global/LoadSelectors/loadSelectors.saga';
import useUtilStyles from '../../../../helpers/styles/utils.styles';

function TelefonesCrudParametros({ isInsertMode, location }) {
  const utilStyles = useUtilStyles();

  const dispatch = useCrudParametrosDispatch();
  const globalDispatch = useDispatch();

  const telefones = useCrudParametrosSelector(states => states.requestFields.telefones);
  const tiposTelefone = useSelector(states => states.selectors.tiposTelefone.selector);

  useEffect(() => {
    globalDispatch(findTiposTelefoneAction());
  }, [globalDispatch, location.refreshPage]);

  const setTelefoneAttributeValueHandler = useCallback((name, value, index) => {
    dispatch(setTelefoneAttributeValueAction(name, value, index));
  }, [dispatch]);

  const setTelefoneAttributeFocusHandler = useCallback((name, index) => {
    dispatch(setTelefoneAttributeFocusAction(name, index));
  }, [dispatch]);

  const setTelefonesHandler = useCallback((telefonesParam) => {
    dispatch(setTelefonesAction(telefonesParam));
  }, [dispatch]);

  return (
    <Telefones
      validaWhatsApp
      className={utilStyles.mt16}
      disabled={!isInsertMode}
      readMode={!isInsertMode}
      telefones={telefones}
      tiposTelefone={tiposTelefone}
      setAttribute={setTelefoneAttributeValueHandler}
      setFocus={setTelefoneAttributeFocusHandler}
      setTelefones={setTelefonesHandler}
      showTrashAtFirstItem
      requiredFields={{
        tipoTelefone: isInsertMode,
        numero: isInsertMode,
      }}
    />
  );
}

export default withRouter(TelefonesCrudParametros);
