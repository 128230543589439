import React, { useCallback, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { Button } from '@mui/material';
import WrapperSubTitleForm from '../../../components/UI/WrapperSubTitleForm/WrapperSubTitleForm';
import {
  onChangeNomeRamoAtividadeAction,
  onClearErrorFocusAction,
  onOpenEditDialogError,
} from '../../../store/RamoAtividade/Crud/crudRamoAtividade.store';
import LoadingUI from '../../../components/UI/Loading/LoadingUI';
import {
  editRamoAtividadeAction,
  insertRamoAtividadeAction,
} from '../../../store/RamoAtividade/Crud/CrudRamoAtividade.saga';
import { CADASTRO_ACTION, EDITAR_ACTION } from '../Pesquisa/pesquisarRamoAtividade.constants';
import { useRamoAtividadeDispatch, useRamoAtividadeSelector } from '../../../store/RamoAtividade/RamoAtividadeProvider';
import InputField from '../../../components/UI/Field/Input/InputField';
import useUtilStyle from '../../../helpers/styles/utils.styles';
import { FIELD_LABEL, routeName, SUBTITLE_FORM } from './CrudRamoAtividade.constants';
import AlertDialog from '../../../components/UI/Dialogs/AlertDialog/AlertDialog';
import { ERROR } from '../../../helpers/constants/global.constants';
import { replaceSemiColonOnValue } from '../../../helpers/utils/utils';
import DialogNew from '../../../layouts/FormDialog/DialogNew';

function CrudRamoAtividade({
  nomeRamo,
  idRamo,
  acao,
  open,
  onClose,
}) {
  const dispatch = useRamoAtividadeDispatch();

  const utilStyle = useUtilStyle();

  const loading = useRamoAtividadeSelector(states => states.crudRamoAtividade.actions.loading);
  const openDialogErrorEdit = useRamoAtividadeSelector(states => states.crudRamoAtividade.actions.openDialogErrorEdit);
  const contentEditDialogError = useRamoAtividadeSelector(states => states.crudRamoAtividade.actions.contentEditDialogError);
  const ramoAtividade = useRamoAtividadeSelector(states => states.crudRamoAtividade.controls.nomeRamoAtividade);

  const closeDialogEditHandler = () => {
    dispatch(onOpenEditDialogError(false));
    onClose();
  };

  useEffect(() => {
    dispatch(onChangeNomeRamoAtividadeAction(nomeRamo));
  }, [nomeRamo, dispatch]);

  const clearErrorOnFocus = useCallback(() => {
    dispatch(onClearErrorFocusAction());
  }, [dispatch]);

  const changeHandler = useCallback((event) => {
    const { value } = event.target;
    dispatch(onChangeNomeRamoAtividadeAction(replaceSemiColonOnValue(value)));
  }, [dispatch]);

  const submitHandler = () => {
    if (acao === CADASTRO_ACTION) {
      const payload = {
        nomeRamoAtividade: ramoAtividade.getValueNotEmpty(),
      };
      dispatch(insertRamoAtividadeAction(payload));
    } else if (acao === EDITAR_ACTION) {
      dispatch(editRamoAtividadeAction(idRamo, ramoAtividade.getValueNotEmpty()));
    }
  };

  return (
    <>
      <LoadingUI show={loading} />
      <AlertDialog
        open={openDialogErrorEdit}
        variant={ERROR}
        onClickConfirm={closeDialogEditHandler}
      >
        <Typography style={{ textAlign: 'center' }} component="p">{contentEditDialogError}</Typography>
      </AlertDialog>
      <DialogNew
        open={open}
        text={routeName(acao)}
        onClose={onClose}
      >
        <WrapperSubTitleForm title={SUBTITLE_FORM}>
          <div className={utilStyle.titleFieldDistance} />
          <InputField
            isForm
            fullWidth
            error={ramoAtividade.error}
            errorMessage={ramoAtividade.errorMessage}
            label={FIELD_LABEL}
            onChange={changeHandler}
            onFocus={clearErrorOnFocus}
            value={ramoAtividade.value}
          />
        </WrapperSubTitleForm>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
          <Button
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            onClick={submitHandler}
          >
            Confirmar
          </Button>
        </div>
      </DialogNew>
    </>
  );
}

export default CrudRamoAtividade;
