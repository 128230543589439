import React, {
  memo, useEffect, useState,
} from 'react';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
import {
  CampoObrigatorio, Loading, SelectorField, TextareaField,
} from '../../../../../components';
import WrapperSubTitleForm from '../../../../../components/UI/WrapperSubTitleForm';
import iconCliente from '../../../../../assets/images/icons/iconCliente.svg';
import InputField from '../../../../../components/UI/Field/Input/InputField';
import useUtilStyle from '../../../../../helpers/styles/utils.styles';
import FormWrapperWithTitle from '../../../../../components/UI/Wrapper/Form/FormWrapperWithTitle';
import Grid24 from '../../../../../components/UI/Grid24';
import DateField from '../../../../../components/UI/Field/Date/DateField';
import {
  getFieldName, getRequiredLabel, toastUnmappedException,
} from '../../../../../helpers';
import MoneyField from '../../../../../components/UI/Field/Money/MoneyField';
import CheckboxField from '../../../../../components/UI/Field/Checkbox/CheckboxField';
import { LancamentosNaoPrestadosController, LancamentosNaoPrestadosFactory } from '../LancamentosNaoPrestadosController';

function makeLoading() {
  return {
    tiposDeLancamentos: false,
    lancamentoCliente: false,
  };
}

function DialogLancamentosNaoPrestados({
  onClose, nome, idCliente, idContaCorrenteNaoPrestado, setAtualizar, atualizarPaginaAfterAction,
}) {
  const utilStyle = useUtilStyle();
  const [formFields, setFormFields] = useState(LancamentosNaoPrestadosFactory.makeFormFields(null));
  const [tiposLancamentosItems, setTiposLancamentosItems] = React.useState([]);
  const [loading, setLoading] = React.useState(makeLoading());
  const [errorsField, setErrorsField] = React.useState(LancamentosNaoPrestadosController.makeErrorFields());

  const onFocusFields = (event) => {
    const name = getFieldName(event.target);
    setErrorsField(oldValues => ({ ...oldValues, [name]: { error: false, errorMessage: '' } }));
  };

  const onChangeFields = (event) => {
    const { name, value, checked } = event.target;
    setFormFields(oldValues => ({ ...oldValues, [name]: checked || value }));
  };

  const findAllTiposDoLancamentos = React.useCallback(async () => {
    setLoading(oldLoading => ({ ...oldLoading, tiposDoLancamentos: true }));
    try {
      const response = await LancamentosNaoPrestadosController.findAllTiposDoLancamentos();
      setTiposLancamentosItems(response.tiposDoLancamentos);
    } catch (err) {
      toastUnmappedException(err, 'Ocorreu um problema ao tentar consultar os nomes dos Tipos de Lançamentos');
      setTiposLancamentosItems([]);
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, tiposDoLancamentos: false }));
    }
  }, []);

  const findEditarLancamento = React.useCallback(async () => {
    setLoading(oldLoading => ({ ...oldLoading, lancamentoCliente: true }));
    try {
      const response = await LancamentosNaoPrestadosController.findEditarLancamento(idContaCorrenteNaoPrestado);
      setFormFields(response.lancamentoCliente);
    } catch (err) {
      toastUnmappedException(err, 'Ocorreu um problema ao tentar consultar os nomes dos Tipos de Lançamentos');
      setFormFields(LancamentosNaoPrestadosFactory.makeFormFields());
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, lancamentoCliente: false }));
    }
  }, [idContaCorrenteNaoPrestado]);

  async function onClickSalvarLancamento(event) {
    setLoading(oldLoading => ({ ...oldLoading, lancamentoCliente: true }));
    event.preventDefault();
    if (!idContaCorrenteNaoPrestado) {
      const { success, error } = await LancamentosNaoPrestadosController.novoLancamento(errorsField,
        formFields, idCliente, setLoading);
      if (success) {
        setFormFields(LancamentosNaoPrestadosFactory.makeFormFields());
        setErrorsField(LancamentosNaoPrestadosController.makeErrorFields());
        onClose();
        atualizarPaginaAfterAction(true, idCliente);
        setAtualizar(true);
        setLoading(oldLoading => ({ ...oldLoading, lancamentoCliente: true }));
        toast.success('Lançamento inserido com sucesso!');
        return;
      }
      setErrorsField(error?.campos);
      return;
    }
    const {
      success,
      error,
    } = await LancamentosNaoPrestadosController.editarLancamento(errorsField, formFields, idCliente, setLoading, idContaCorrenteNaoPrestado);
    if (success) {
      setFormFields(LancamentosNaoPrestadosFactory.makeFormFields());
      setLoading(oldLoading => ({ ...oldLoading, lancamentoCliente: true }));
      onClose();
      atualizarPaginaAfterAction(true, idCliente);
      setAtualizar(true);
    } else {
      setErrorsField(error?.campos);
    }
    setLoading(oldLoading => ({ ...oldLoading, lancamentoCliente: false }));
  }


  useEffect(() => {
    if (idContaCorrenteNaoPrestado) {
      findEditarLancamento();
    }
    findAllTiposDoLancamentos().then();
  }, [findAllTiposDoLancamentos, findEditarLancamento, idContaCorrenteNaoPrestado]);


  return (
    <>
      <Loading
        show={loading.tiposDeLancamentos || loading.lancamentoCliente}
      />
      <CampoObrigatorio />
      <WrapperSubTitleForm title="Detalhe do Cliente" />
      <Grid24 xs={24} sm={24}>
        <Grid24 xs={24} sm={5}>
          <img src={iconCliente} alt="Foto Usuario" />
        </Grid24>
        <Grid24 xs={24} sm={19} className={utilStyle.mt10}>
          <InputField
            isForm
            label="Código"
            name="idCliente"
            disabled
            value={idCliente}
          />
          <Grid24 xs={24} sm={24}>
            <InputField
              isForm
              fullWidth
              label="Nome"
              name="nome"
              disabled
              value={nome}
            />
          </Grid24>
        </Grid24>
      </Grid24>
      <Grid24 xs={24} sm={24} className={utilStyle.mt20}>
        <FormWrapperWithTitle title="Informações">
          <Grid24 container isFormContainer>
            <Grid24 xs={24} sm={24} spacing="1">
              <Grid24 xs={24} sm={6}>
                <DateField
                  isForm
                  label={getRequiredLabel('Data do Lançamento', true)}
                  name="data"
                  value={formFields.data}
                  error={errorsField.data.error}
                  errorMessage={errorsField.data.errorMessage}
                  onFocus={onFocusFields}
                  onChange={onChangeFields}
                />
              </Grid24>
              <Grid24 xs={24} sm={18}>
                <SelectorField
                  isForm
                  name="idTipoContaCorrenteCliente"
                  value={formFields.idTipoContaCorrenteCliente}
                  label={getRequiredLabel('Tipo do Lançamento', true)}
                  items={tiposLancamentosItems}
                  onFocus={onFocusFields}
                  onChange={onChangeFields}
                  error={errorsField.idTipoContaCorrenteCliente.error}
                  errorMessage={errorsField.idTipoContaCorrenteCliente.errorMessage}
                />
              </Grid24>
            </Grid24>
            <Grid24 xs={24} sm={24} className={utilStyle.mt7}>
              <TextareaField
                label={getRequiredLabel('Histórico', true)}
                placeholder="Insira o Histórico"
                readOnly={false}
                name="historico"
                classForm={utilStyle.areaSize}
                value={formFields.historico}
                onFocus={onFocusFields}
                onChange={onChangeFields}
                error={errorsField.historico.error}
                errorMessage={errorsField.historico.errorMessage}

              />
            </Grid24>
            <Grid24 xs={24} sm={24} className={utilStyle.mt7}>
              <Grid24 xs={24} sm={8}>
                <MoneyField
                  isForm
                  name="valor"
                  label={getRequiredLabel('Valor', true)}
                  onFocus={onFocusFields}
                  onChange={onChangeFields}
                  value={formFields.valor}
                  error={errorsField.valor.error}
                  errorMessage={errorsField.valor.errorMessage}
                />
              </Grid24>
              <Grid24 xs={24} sm={8}>
                <InputField
                  isForm
                  name="idProcesso"
                  label="Número do Processo"
                  onChange={onChangeFields}
                  onFocus={onFocusFields}
                  value={formFields.idProcesso}
                  error={errorsField.idProcesso.error}
                  errorMessage={errorsField.idProcesso.errorMessage}
                />
              </Grid24>
            </Grid24>
            <Grid24 xs={24} sm={24} className={utilStyle.mt7ml10}>
              <CheckboxField
                label="Bloqueado"
                name="bloqueado"
                checked={formFields.bloqueado}
                onChange={onChangeFields}
                onFocus={onFocusFields}
              />
            </Grid24>
          </Grid24>
        </FormWrapperWithTitle>
      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
        <Button
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          onClick={onClickSalvarLancamento}
        >
          Confirmar
        </Button>
      </div>
    </>
  );
}

export default memo(DialogLancamentosNaoPrestados);
