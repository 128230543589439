import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';

import { toast } from 'react-toastify';
import FormValidation from '../../../helpers/validators/FormValidation';

import {
  getDateTimeMask, getExceptionMessage, getMoneyMask, isBadRequestException,
} from '../../../helpers/utils/utils';

import {
  findBorderos,
  findBorderosIds,
  findEstatiticas,
  getInformacoesGeraisProcessamentoBordero,
  iniciarProcessamento,
  recoverBorderos,
  removeBorderos,
} from '../../../services/bordero/bordero/bordero.services';

import {
  changeSelectedIdsAction,
  clearEstatisticasAction,
  onChangeAllSearchCriteriasAction,
  setDefinedSearchCriteriasAction,
  setDialogInfoAction,
  setEstatisticasAction,
  setInformacoesProcessamentoBorderoAction,
  setLoadingAction,
  setProcessamentoDialogAction,
  setSelectedIdsAction,
  setStatesValueAction,
  setTableInfoAction,
} from './processarBorderos.store';
import { RESULT_NOT_FOUND } from '../../../helpers/constants/global.constants';
import { getParametrosInterno } from '../../../services/core/parametro/parametro.services';


const FIND_ESTATISTICAS = 'FIND_ESTATISTICAS_PROCESSAR_BORDEROS_SAGA';
const FIND_BORDEROS = 'FIND_BORDEROS_PROCESSAR_BORDEROS_SAGA';
const CHANGE_TABLE_INFO = 'CHANGE_TABLE_INFO_PROCESSAR_BORDEROS_SAGA';
const RECOVER_BORDEROS = 'RECOVER_BORDEROS_PROCESSAR_BORDEROS_SAGA';
const REMOVE_BORDEROS = 'REMOVE_BORDEROS_PROCESSAR_BORDEROS_SAGA';
const GET_INFORMACOES_GERAIS_PROCESSAMENTO = 'GET_INFORMACOES_GERAIS_PROCESSAMENTO_BORDERO_SAGA';
const GET_QNTD_MAXIMA_FALHAS_BORDERO = 'GET_QNTD_MAXIMA_FALHAS_BORDERO_SAGA';
const INICIAR_PROCESSAMENTO_BORDERO = 'INICIAR_PROCESSAMENTO_BORDERO_SAGA';

export const iniciarProcessamentoBorderoAction = () => ({
  type: INICIAR_PROCESSAMENTO_BORDERO,
});

export const getInformacoesGeraisProcessamentoBorderoAction = idsBordero => ({
  type: GET_INFORMACOES_GERAIS_PROCESSAMENTO,
  idsBordero,
});

export const getQtdMaximaFalhasBorderoAction = () => ({
  type: GET_QNTD_MAXIMA_FALHAS_BORDERO,
});

export const findEstatisticasAction = () => ({
  type: FIND_ESTATISTICAS,
});

export const findBorderosAction = (searchCriterias, page, rowsPerPage) => ({
  type: FIND_BORDEROS,
  searchCriterias,
  page,
  rowsPerPage,
});

export const onChangeTableInfoAction = (searchCriterias, page, rowsPerPage, selectedIds) => ({
  type: CHANGE_TABLE_INFO,
  searchCriterias,
  page,
  rowsPerPage,
  selectedIds,
});

export const onRecoverBorderosAction = (idsBorderosDevedor, params, selectedIds) => ({
  type: RECOVER_BORDEROS,
  idsBorderosDevedor,
  params,
  selectedIds,
});

export const onRemoveBorderosAction = (idsBorderosDevedor, params, selectedIds) => ({
  type: REMOVE_BORDEROS,
  idsBorderosDevedor,
  params,
  selectedIds,
});

function buildBorderos(bordero, selectedIds) {
  return {
    ...bordero,
    valorTotal: getMoneyMask(bordero.valorTotal),
    valorMedio: getMoneyMask(bordero.valorMedio),
    dataCadastramento: getDateTimeMask(bordero.dataCadastramento),
    checked: selectedIds[bordero.idBorderoDevedor],
  };
}

function* buildTable(borderos, selectedIds, page, size) {
  yield put(setTableInfoAction(
    borderos.content.map(bordero => buildBorderos(bordero, selectedIds)),
    borderos.totalElements,
    page,
    size,
  ));
}

function* updateScreenAfterActions(params, selectedIds) {
  yield put(findEstatisticasAction());
  yield put(changeSelectedIdsAction(selectedIds));
  yield put(onChangeTableInfoAction(params, params.page, params.size, selectedIds));
}

function* setSuccessSearchResponseData(borderos, params) {
  const selectedIdsResponse = yield findBorderosIds(...Object.values(params));
  const selectedIds = selectedIdsResponse.data.reduce((map, id) => {
    map[id] = false;
    return map;
  }, {});
  yield put(setSelectedIdsAction(selectedIds));
  yield buildTable(borderos, selectedIds, params.page, params.size);
}

function setWarningEmptyResponse(borderos) {
  if (borderos.length === 0) {
    toast.warn(RESULT_NOT_FOUND);
  }
}

function setErrorMessage(exception) {
  const message = getExceptionMessage(exception);
  if (message !== '') {
    toast.error(message, { style: { width: '392px' } });
  }
}

function callbackSuccessSearch(params) {
  return function* callback(response) {
    try {
      yield setSuccessSearchResponseData(response.data, params);
      yield setWarningEmptyResponse(response.data.content);
      yield put(setDefinedSearchCriteriasAction(params));
    } finally {
      yield put(setLoadingAction(false));
    }
  };
}

function* callbackErrorSearch(states, exception) {
  if (yield isBadRequestException(exception)) {
    yield put(onChangeAllSearchCriteriasAction(states));
    yield setErrorMessage(exception);
  }
  yield put(setLoadingAction(false));
}

function* findEstatisticasHandler() {
  yield put(setLoadingAction(true));
  try {
    const { data } = yield call(findEstatiticas);
    yield put(setEstatisticasAction(data));
  } catch (excetion) {
    yield put(clearEstatisticasAction());
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* findBorderosHandler(actions) {
  yield put(setLoadingAction(true));

  const columnOrdenacao = yield select(states => states.processarBorderos.tableInfo.columnOrdenacao);
  const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];
  const params = {
    idCliente: actions.searchCriterias.idCliente.getValueNotEmpty(),
    dataInicial: actions.searchCriterias.dataInicial.getSerializeDate(),
    dataFinal: actions.searchCriterias.dataFinal.getSerializeDate(),
    status: actions.searchCriterias.status.getValueNotEmpty(),
    page: actions.page,
    size: actions.rowsPerPage,
  };
  const validator = new FormValidation(
    actions.searchCriterias,
    findBorderos,
    callbackSuccessSearch(params),
    callbackErrorSearch,
    params,
    order,
  );
  yield validator.requestSubmit();
}

function* onChangeTableInfoHanler(actions) {
  yield put(setLoadingAction(true));
  try {
    const content = yield select(states => states.processarBorderos.tableInfo.content);
    const columnOrdenacao = yield select(states => states.processarBorderos.tableInfo.columnOrdenacao);
    let borderos = { content: [], totalElements: 0 };
    if (content.length > 0) {
      const params = {
        ...actions.searchCriterias,
        page: actions.page,
        size: actions.rowsPerPage,
      };
      const order = [`${columnOrdenacao.element},${columnOrdenacao.order}`];
      const response = yield findBorderos(params, order);
      borderos = response.data;
    }
    yield buildTable(borderos, actions.selectedIds, actions.page, actions.rowsPerPage);
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* onRecoverBorderosHandler(actions) {
  yield put(setDialogInfoAction(false));
  yield put(setLoadingAction(true));
  try {
    yield recoverBorderos(actions.idsBorderosDevedor);
    yield updateScreenAfterActions(actions.params, actions.selectedIds);
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* onRemoveBorderosHandler(actions) {
  yield put(setDialogInfoAction(false));
  yield put(setLoadingAction(true));
  try {
    yield removeBorderos(actions.idsBorderosDevedor);
    yield updateScreenAfterActions(actions.params, actions.selectedIds);
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* getInformacoesGeraisProcessamentoBorderoHandler(actions) {
  yield put(setLoadingAction(true));
  const { idsBordero } = actions;
  try {
    const { data } = yield getInformacoesGeraisProcessamentoBordero(idsBordero);
    const { qtdBorderoSelecionado, valorTotalBorderoProcessamento } = data;

    yield put(setInformacoesProcessamentoBorderoAction(qtdBorderoSelecionado, valorTotalBorderoProcessamento));
    yield put(setStatesValueAction('listaIdsBorderoDevedor', idsBordero));
    yield put(setStatesValueAction('openProcessamentoModal', true));
  } catch (exception) {
    // Nenhum tratamento definido
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* getQtdMaximaFalhasBorderoHandler() {
  yield put(setLoadingAction(true));
  try {
    const { data } = yield call(getParametrosInterno);
    const { qtdeMaximoTentativasBordero } = data;
    yield put(setStatesValueAction('qtdeMaximoTentativasBorderoParametro', qtdeMaximoTentativasBordero));
  } catch (exception) {
    // Nenhum tratamento definido
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* buildProcessamentoPayload() {
  const idsBorderos = yield select(state => state.processarBorderos.listaIdsBorderoDevedor);
  const {
    manterProcessoFaseInicial, processarTitulosAntigos, criarProcessoDuplicidade, aceitarProcessoValorBaixo,
  } = yield select(state => state.processarBorderos.processamentoDiferenciado);

  return {
    listaIdsBorderoDevedor: idsBorderos,
    aceitarProcessoValorBaixo: aceitarProcessoValorBaixo.value,
    manterProcessoFaseInicial: manterProcessoFaseInicial.value,
    processarTitulosAntigos: processarTitulosAntigos.value,
    criarProcessoDuplicidade: criarProcessoDuplicidade.value,
  };
}

function* iniciarProcessamentoHandler() {
  yield put(setLoadingAction(true));
  yield put(setStatesValueAction('openProcessamentoModal', false));
  try {
    const payload = yield buildProcessamentoPayload();
    yield call(iniciarProcessamento, payload);
    yield put(setProcessamentoDialogAction(true, 'success', ''));
  } catch (exception) {
    if (isBadRequestException(exception)) {
      yield put(setProcessamentoDialogAction(true, 'error', getExceptionMessage(exception)));
    }
  } finally {
    yield put(setLoadingAction(false));
  }
}

export default function* watchProcessarBorderos() {
  yield all([
    takeLatest(FIND_ESTATISTICAS, findEstatisticasHandler),
    takeLatest(FIND_BORDEROS, findBorderosHandler),
    takeLatest(CHANGE_TABLE_INFO, onChangeTableInfoHanler),
    takeLatest(RECOVER_BORDEROS, onRecoverBorderosHandler),
    takeLatest(REMOVE_BORDEROS, onRemoveBorderosHandler),
    takeLatest(GET_INFORMACOES_GERAIS_PROCESSAMENTO, getInformacoesGeraisProcessamentoBorderoHandler),
    takeLatest(GET_QNTD_MAXIMA_FALHAS_BORDERO, getQtdMaximaFalhasBorderoHandler),
    takeLatest(INICIAR_PROCESSAMENTO_BORDERO, iniciarProcessamentoHandler),
  ]);
}
