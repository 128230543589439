import React from 'react';

function TabPanel({
  children, id, index, value,
}) {
  return (
    <div
      id={`${id}-${index}`}
      aria-labelledby={`${id}-${index}`}
      hidden={value !== index}
    >
      {children}
    </div>
  );
}

export default React.memo(TabPanel);
