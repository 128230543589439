import React, {
  useCallback, useEffect, useImperativeHandle, useState,
} from 'react';
import { MdBook } from 'react-icons/md';
import { DialogTitle, Grid, Typography } from '@material-ui/core';
import { salvarClienteProcessoController as controller } from './salvarDocumentoClienteController';
import { getFieldName, updateFieldsWithErrors } from '../../../../../../helpers/utils/form.utils';
import { toastUnmappedException } from '../../../../../../helpers/utils/toastUtils';
import {
  ButtonDocumentoCliente,
  FormContainer,
  TitleFormDivider,
} from '../../../../../../pages/InspecionarProcesso/SalvarDocumentoProcesso/salvarDocumentoProcessoStyles';
import InputField from '../../../../../UI/Field/Input/InputField';
import CheckboxField from '../../../../../UI/Field/Checkbox/CheckboxField';
import { setLoadingAction } from '../../../../../../store/Cliente/V2/Crud/crudCliente.store';
import { useCrudClienteDispatch } from '../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import { buscarDocumentosClientePorIdDocumentoService } from '../../../../../../services/core/cliente/cliente.services';
import { InputFileIcon } from '../../../../../UI/InputFile/InputFileIcon';

function SalvarDocumentoClienteComponent({ idCliente, onUpdateTable }, ref) {
  const dispatch = useCrudClienteDispatch();

  const [showDialog, setShowDialog] = useState(false);
  const [idClienteDocumento, setIdClienteDocumento] = useState(null);
  const [formFields, setFormFields] = useState(controller.makeFormFields());

  const [errorFields, setErrorFields] = useState(controller.makeErrorFields());

  function handleClose() {
    setShowDialog(!showDialog);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      dispatch(setLoadingAction(true));
      const formData = new FormData(e.target);
      await controller.salvarDocumento(idCliente, idClienteDocumento, formData);
      onUpdateTable(!idClienteDocumento);
      handleClose();
    } catch (err) {
      if (err?.response?.data?.validations) {
        const [updatedFields] = updateFieldsWithErrors(err, errorFields);
        setErrorFields({ ...updatedFields });
      }
    } finally {
      dispatch(setLoadingAction(false));
    }
  }

  const handleFocus = useCallback((e) => {
    const name = getFieldName(e.target);
    setErrorFields(oldValues => ({ ...oldValues, [name]: false }));
  }, []);

  const onFocusFileWhenSelecionar = () => {
    setErrorFields(oldValues => ({ ...oldValues, nomeDocumento: { error: false, errorMessage: '' } }));
  };

  const handleChange = useCallback((e) => {
    const name = getFieldName(e.target);
    const { value } = e.target;
    setFormFields(oldValue => ({ ...oldValue, [name]: value }));
  }, []);

  const handleChangeCheckbox = useCallback((e) => {
    const name = getFieldName(e.target);
    const { checked } = e.target;
    setFormFields(oldValue => ({ ...oldValue, [name]: checked }));
  }, []);

  /**
   * Externalizar ação de abrir e fechar component
   */
  useImperativeHandle(ref, () => ({
    handleOpen: (idClienteDocumentoParam) => {
      setShowDialog(!showDialog);
      setIdClienteDocumento(idClienteDocumentoParam);
      if (!idClienteDocumentoParam) {
        setFormFields(controller.makeFormFields());
      }
    },
  }));

  /**
   * Carregar informações inicias do componente, caso exista.
   */
  useEffect(() => {
    if (!idClienteDocumento) {
      return;
    }
    dispatch(setLoadingAction(true));
    buscarDocumentosClientePorIdDocumentoService(idClienteDocumento).then((res) => {
      setFormFields(controller.makeFormFields(res.data));
    }).catch((e) => {
      setShowDialog(false);
      toastUnmappedException(e, 'Ocorreu um problema ao buscar informações específicas do documento');
    }).finally(() => {
      dispatch(setLoadingAction(false));
    });
  }, [idClienteDocumento, dispatch]);


  const isDisabledConfirmButton = !(formFields.nomeDocumento.trim() !== '' && formFields.tipo.trim() !== '');

  return showDialog && (
    <FormContainer
      open={showDialog}
      icon={<MdBook />}
      onClose={handleClose}
    >
      <DialogTitle id="draggable-dialog-title" style={{ fontWeight: 'bold' }}>
        <Typography align="left">
          <div style={{ fontSize: '16px', marginBottom: '10px', fontWeight: 'bold' }}>
            Novo Cadastro de Clientes
          </div>
        </Typography>
      </DialogTitle>

      <form onSubmit={handleSubmit}>
        <Grid container>
          <InputFileIcon
            fullWidth
            isForm
            disabled={!!idClienteDocumento}
            maxLength={200}
            label="Arquivo*"
            labelName="nomeDocumento"
            name="documentoCliente"
            acceptAll
            value={formFields?.nomeDocumento || ''}
            error={errorFields.documentoCliente.error}
            errorMessage={errorFields.documentoCliente.errorMessage}
            onFocus={onFocusFileWhenSelecionar}
            onChange={handleChange}
          />
        </Grid>
        <TitleFormDivider>Informações do Documento</TitleFormDivider>
        <Grid container>
          <InputField
            fullWidth
            isForm
            label="Tipo*"
            name="tipo"
            maxLength={50}
            value={formFields.tipo || ''}
            error={errorFields.tipo.error}
            errorMessage={errorFields.tipo.errorMessage}
            onFocus={handleFocus}
            onChange={handleChange}
          />
        </Grid>
        <Grid container>
          <InputField
            fullWidth
            isForm
            label="Descrição"
            name="descricao"
            maxLength={200}
            placeholder="Insira a descrição"
            value={formFields.descricao || ''}
            error={errorFields.descricao.error}
            errorMessage={errorFields.descricao.error}
            onFocus={handleFocus}
            onChange={handleChange}
          />
        </Grid>
        <Grid container>
          <CheckboxField
            name="isDocumentoOriginal"
            label="Documento original está arquivado na ABE"
            value="original"
            checked={formFields.isDocumentoOriginal}
            onChange={handleChangeCheckbox}
          />
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '22px' }}>
          <ButtonDocumentoCliente
            type="button"
            onClick={handleClose}
          >
            Cancelar
          </ButtonDocumentoCliente>
          <ButtonDocumentoCliente
            disabled={isDisabledConfirmButton}
            type="submit"
          >
            {idClienteDocumento ? 'Confirmar' : 'Anexar'}
          </ButtonDocumentoCliente>
        </div>
      </form>
    </FormContainer>
  );
}

export const SalvarDocumentoCliente = React.forwardRef(SalvarDocumentoClienteComponent);
