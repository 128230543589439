import { gateway } from '../../api';

const CORE = process.env.REACT_APP_CORE;

export const getTipoTelefone = () => (
  gateway.get(`${CORE}/telefones/tipos`)
);

export const getTipoTelefoneParaCliente = () => (
  gateway.get(`${CORE}/telefones/tipos-cliente`)
);
