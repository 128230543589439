import React, { createContext } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider, createSelectorHook, createDispatchHook } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import reducer from './RecebimentoCliente.store';
import watchRecebimento from './RecebimentoClientes.saga';

export const Recebimentocontext = createContext();
export const useRecebimentoSelector = createSelectorHook(Recebimentocontext);
export const useRecebimentoDispatch = createDispatchHook(Recebimentocontext);

export default function RecebimentoClienteProvider({ children }) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(watchRecebimento);

  return (
    <Provider context={Recebimentocontext} store={store}>
      {children}
    </Provider>
  );
}
