import { getUriParams } from '../../../helpers/utils/utils';
import { CORE, gateway } from '../../api';

export function buscarAcordosAs(periodoAcordoInicial, periodoAcordoFinal, nroProcesso, nomeDevedor, nomeCliente, tipoAcordo, ordenacao, page, size) {
  const sortedQuery = ordenacao.reduce((obj, sort) => (
    `${obj}&sort=${sort}`
  ), '');

  const params = getUriParams({
    periodoAcordoInicial, periodoAcordoFinal, nroProcesso, nomeDevedor, nomeCliente, tipoAcordo, page, size,
  });

  return gateway.get(`${CORE}/acordo-seguro/processos/acordos/?${sortedQuery}&${params}`);
}
