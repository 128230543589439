import React from 'react';

import Grid24 from '../../../../../UI/Grid24/Grid24';
import CustomFieldTitle from '../../../../../UI/CustomFields/CustomFieldTitle';
import { getRequiredLabel } from '../../../../../../helpers/utils/utils';
import FormWrapperTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import { useCrudParametrosSelector } from '../../../../../../store/Parametros/Crud/CrudParametrosProvider';
import {
  CONFIGURACAO_BORDERO_TITLE,
  PRAZO_RETENCAO_LOG_TITLE,
  TEMPO_MAXIMO_BORDERO_FIELD_TITLE,
} from './configuracaoBordero.constants';
import NumberInput from '../../../../../UI/Field/Number/NumberInput';

function ConfiguracoesBordero({
  isInsertMode,
  onChange,
  onFocus,
  editVisible,
}) {
  const tempoMaximoBordero = useCrudParametrosSelector(state => state.requestFields.tempoMaximoBorderoExcluido.copy());
  const prazoRetencaoLogBordero = useCrudParametrosSelector(state => state.requestFields.prazoRetencaoLogBordero.copy());

  return (
    <FormWrapperTitle title={CONFIGURACAO_BORDERO_TITLE}>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={6}>
          <Grid24 xs={24} sm={24}>
            <CustomFieldTitle textField={getRequiredLabel(PRAZO_RETENCAO_LOG_TITLE, isInsertMode)} />
          </Grid24>
          <Grid24 xs={24} sm={18}>
            <NumberInput
              isForm
              disabled={editVisible}
              name={prazoRetencaoLogBordero.name}
              value={prazoRetencaoLogBordero.value}
              error={prazoRetencaoLogBordero.error}
              errorMessage={prazoRetencaoLogBordero.errorMessage}
              onChange={onChange}
              onFocus={onFocus}
              maxLength={3}
              sideText="(dias)"
            />
          </Grid24>
        </Grid24>
        <Grid24 xs={24} sm={18}>
          <Grid24 xs={24} sm={24}>
            <CustomFieldTitle textField={getRequiredLabel(TEMPO_MAXIMO_BORDERO_FIELD_TITLE, isInsertMode)} />
          </Grid24>
          <Grid24 xs={24} sm={10}>
            <NumberInput
              isForm
              disabled={editVisible}
              name={tempoMaximoBordero.name}
              value={tempoMaximoBordero.value}
              error={tempoMaximoBordero.error}
              errorMessage={tempoMaximoBordero.errorMessage}
              onChange={onChange}
              onFocus={onFocus}
              maxLength={3}
              sideText="(dias)"
            />
          </Grid24>
        </Grid24>
      </Grid24>
    </FormWrapperTitle>
  );
}

export default React.memo(ConfiguracoesBordero);
