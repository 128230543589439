import React from 'react';
import cx from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import { CircularProgress } from '@material-ui/core';
import useStyles from './iconButtonUI.styles';

function IconButtonUI({
  children,
  disabled,
  showLoading = false,
  ...props
}) {
  const styles = useStyles();

  return (
    <IconButton
      disabled={disabled || showLoading}
      classes={{
        disabled: styles.disabled,
        root: cx({ [styles.loadingRoot]: showLoading }),
      }}
      {...props}
    >
      {showLoading ? (<CircularProgress size="100%" />) : children}
    </IconButton>

  );
}

export default IconButtonUI;
