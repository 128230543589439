import makeStyles from '@material-ui/core/styles/makeStyles';
import { baseGrey, greyish, warmGrey } from '../../../helpers/constants/colors.constants';

export default makeStyles({
  root: {
    marginLeft: '22px',
    '& .MuiDialogTitle-root': {
      padding: '0px',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '59%',
    },
    '& .MuiDialogContent-root': {
      width: '100%',
      display: 'table-row',
    },
  },
  informacoes_gerais_div: {
    marginTop: '8px',
  },
  div_mensagem_box: {
    backgroundColor: baseGrey,
    borderBottom: `solid 1px ${greyish}`,
    maxHeight: '94px',
    minHeight: '94px',
    overflow: 'auto',
    marginBottom: '15px',
  },
  div_mensagem_box_value_label: {
    fontSize: '12px',
    margin: '8px',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
  },
  label_mensagem: {
    marginTop: '16px',
    marginBottom: '6px',
    '& p': {
      fontSize: '12px',
      lineHeight: '14px',
      color: warmGrey,
    },
  },
  div_button_voltar: {
    width: '152px',
  },
});
