import React, { useCallback } from 'react';
import { RadioGroup } from '@material-ui/core';
import Button from '../../../../../UI/Button/ButtonUI';

import useStyles from './SeletorPermissoes.styles';
import useUtilStyles from '../../../../../../helpers/styles/utils.styles';
import SelectorField from '../../../../../UI/Field/Selector/SelectorField';
import Grid24 from '../../../../../UI/Grid24/Grid24';
import FormWrapperTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import RadioField from '../../../../../UI/Field/Radio/RadioField';
import { useCrudUsuarioDispatch2 } from '../../../../../../store/Usuario/V2/CrudUsuarioProvider';
import {
  CAMPO_PERFIL_TITLE,
  CAMPO_USUARIO_TITLE,
  RADIO_PERFIL_TITLE,
  RADIO_USUARIO_TITLE,
} from './seletorPermissoes.constants';
import { onChangeControls, onClearPermissionSelector } from '../../../../../../store/Usuario/V2/crudUsuario.store';

function SeletorPermissoes({
  disable,
  title,
  perfis,
  usuarios,
  radioModel,
  perfilModel,
  usuarioModel,
  buttonLabel,
  loading = false,
  onChange,
  onClickButton,
}) {
  const styles = useStyles();
  const utilStyles = useUtilStyles();
  const dispatch = useCrudUsuarioDispatch2();

  const changeRadioToApplyHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeControls(name, value));
    dispatch(onClearPermissionSelector(value, perfilModel.name, usuarioModel.name));
  }, [dispatch, perfilModel, usuarioModel]);

  const showRadioSelected = useCallback(() => {
    if (disable) {
      return false;
    }
    return radioModel.value;
  }, [disable, radioModel]);

  return (
    <div className={utilStyles.w100}>
      <FormWrapperTitle isDisableForm={disable} title={title}>
        <RadioGroup
          name={radioModel.name}
          value={showRadioSelected()}
          onChange={changeRadioToApplyHandler}
        >
          <Grid24 container isFormContainer spacing="1">
            <Grid24 xs={24} sm={4}>
              <RadioField
                disabled={disable}
                value="PERFIL"
                formProps={{ className: styles.radios }}
                label={RADIO_PERFIL_TITLE}
              />
            </Grid24>
            <Grid24 xs={24} sm={20}>
              <SelectorField
                isForm
                fullWidth
                items={perfis}
                disabled={radioModel.value === 'USUARIO' || disable}
                label={CAMPO_PERFIL_TITLE}
                name={perfilModel.name}
                value={perfilModel.value}
                onChange={onChange}
              />
            </Grid24>
          </Grid24>
          <Grid24 container isFormContainer spacing="1" className={utilStyles.mt10}>
            <Grid24 xs={24} sm={4}>
              <RadioField
                value="USUARIO"
                disabled={disable}
                formProps={{ className: styles.radios }}
                label={RADIO_USUARIO_TITLE}
              />
            </Grid24>
            <Grid24 xs={24} sm={20}>
              <SelectorField
                isForm
                items={usuarios}
                disabled={radioModel.value === 'PERFIL' || disable}
                label={CAMPO_USUARIO_TITLE}
                name={usuarioModel.name}
                value={usuarioModel.value}
                onChange={onChange}
              />
            </Grid24>
          </Grid24>
        </RadioGroup>
        <div className={styles.buttonWrapper}>
          <div className={styles.buttonContent}>
            <Button disabled={disable} onClick={onClickButton} showLoading={loading}>
              {buttonLabel}
            </Button>
          </div>
        </div>
      </FormWrapperTitle>
    </div>
  );
}

export default React.memo(SeletorPermissoes);
