import styled from 'styled-components';
import { Typography } from '@material-ui/core';

import pinDefault from '../../../assets/images/icons/pinDefault.svg';
import pinComentarioAutomatico from '../../../assets/images/icons/pinBlue.svg';
import pinComentario from '../../../assets/images/icons/pinDarkBlue.svg';
import pinNenhum from '../../../assets/images/icons/pinGray.svg';
import pinPesquisaCliente from '../../../assets/images/icons/pinGreen.svg';
import pinEmail from '../../../assets/images/icons/pinMarineBlue.svg';
import pinAlerta from '../../../assets/images/icons/pinOrange.svg';
import pinRelatorio from '../../../assets/images/icons/pinPurple.svg';
import pinInstrucao from '../../../assets/images/icons/pinRed.svg';
import pinRequisicao from '../../../assets/images/icons/pinYellow.svg';

const types = {
  1: {
    color: '#707070',
    selectedBackground: `#707070 url(${pinDefault}) no-repeat 60% 40%`,
    pinnedBackground: `white url(${pinNenhum}) no-repeat 60% 40%`,
    selectedBorder: 'none',
    pinnedBorder: '1px solid #707070',
  },
  2: {
    color: '#CB0000',
    selectedBackground: `#CB0000 url(${pinDefault}) no-repeat 60% 40%`,
    pinnedBackground: `white url(${pinInstrucao}) no-repeat 60% 40%`,
    selectedBorder: 'none',
    pinnedBorder: '1px solid #CB0000',
  },
  3: {
    color: '#03A500',
    selectedBackground: `#03A500 url(${pinDefault}) no-repeat 60% 40%`,
    pinnedBackground: `white url(${pinPesquisaCliente}) no-repeat 60% 40%`,
    selectedBorder: 'none',
    pinnedBorder: '1px solid #03A500',
  },
  4: {
    color: '#EAB600',
    selectedBackground: `#EAB600 url(${pinDefault}) no-repeat 60% 40%`,
    pinnedBackground: `white url(${pinRequisicao}) no-repeat 60% 40%`,
    selectedBorder: 'none',
    pinnedBorder: '1px solid #EAB600',
  },
  5: {
    color: '#FF5C00',
    selectedBackground: `#FF5C00 url(${pinDefault}) no-repeat 60% 40%`,
    pinnedBackground: `white url(${pinAlerta}) no-repeat 60% 40%`,
    selectedBorder: 'none',
    pinnedBorder: '1px solid #FF5C00',
  },
  6: {
    color: '#7F2EBE',
    selectedBackground: `#7F2EBE url(${pinDefault}) no-repeat 60% 40%`,
    pinnedBackground: `white url(${pinRelatorio}) no-repeat 60% 40%`,
    selectedBorder: 'none',
    pinnedBorder: '1px solid #7F2EBE',
  },
  7: {
    color: '#000571',
    selectedBackground: `#000571 url(${pinDefault}) no-repeat 60% 40%`,
    pinnedBackground: `white url(${pinComentario}) no-repeat 60% 40%`,
    selectedBorder: 'none',
    pinnedBorder: '1px solid #000571',
  },
  8: {
    color: '#034DDC',
    selectedBackground: `#034DDC url(${pinDefault}) no-repeat 60% 40%`,
    pinnedBackground: `white url(${pinComentarioAutomatico}) no-repeat 60% 40%`,
    selectedBorder: 'none',
    pinnedBorder: '1px solid #034DDC',
  },
  9: {
    color: '#1B9D8F',
    selectedBackground: `#1B9D8F url(${pinDefault}) no-repeat 60% 40%`,
    pinnedBackground: `white url(${pinEmail}) no-repeat 60% 40%`,
    selectedBorder: 'none',
    pinnedBorder: '1px solid #1B9D8F',
  },
};

export const DateIndicator = styled.div`
  border-radius: 6px;
  background-color: #707070;
  width: fit-content;
  padding: 4px 8px;
  margin-top: 8px;
  z-index: 5;

  text-align: center;
  color: white;
`;

export const Header = styled(Typography)`
  font-weight: bold;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;

  .wrapper {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: ${props => `${props.height}px`};
    overflow-y: auto;
  }

  ul li div {
    padding-bottom: 12px;
    border-bottom: 1px solid #a2a0a0;
  }

  ul li:last-child div {
    border-bottom: none;
  }
`;

export const Content = styled.li`
  padding: 0px 32px;

  > div {
    margin-top: 12px;
  }

  p {
    span {
      font-weight: bold;
    }
  }

  pre {
    white-space: pre-wrap;

    span {
      color: ${props => (props.type && types[props.type].color) || '#707070'};
      font-weight: bold;
    }
  }
`;

export const Checkbox = styled.label`
  position: relative;
  left: -24px;
  p {
    display: inline - block;
    width: fit - content;
    padding-left: 24px;
    font-weight: bold;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    :checked ~ .checkmark {
      background: ${props => (props.type && props.unchangedPin ? types[props.type].pinnedBackground : types[props.type].selectedBackground)};
      border: ${props => (props.type && props.unchangedPin ? types[props.type].pinnedBorder : types[props.type].selectedBorder)};
    }

    :checked ~ .checkmark:after {
      display: block;
    }

    :disabled {
      cursor: none;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: ${props => (props.type ? types[props.type].color : '#707070')};
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.48);
  }
`;

export const VerticalLine = styled.div`
  position: absolute;
  left: 16px;
  bottom: 0;
  width: 1px;
  height: 100%;
  background-color: #707070;
`;

export const Loading = styled.p`
  visibility: ${props => (props.show ? 'visible' : 'hidden')};

  border: 1px solid #a2a0a0;
  background-color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 4px 14px;

  font-size: 12px;
  line-height: 1.17;

  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
`;
