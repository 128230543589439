import { gateway } from '../../api';

const CORE = process.env.REACT_APP_CORE;

export const atualizarConfiguracoesLayoutDeLogin = (login, preferencias) => gateway.put(`${CORE}/logins/${login}/configuracoes-layout`, preferencias);

export function alterarSenha(senhaAtual, novaSenha, confirmarNovaSenha) {
  const payload = {
    senhaAtual, novaSenha, confirmarNovaSenha,
  };

  return gateway.post(`${CORE}/logins/alterar-senha`, payload);
}
