import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useEmailEntradaBorderoDispatch, useEmailEntradaBorderoSelector } from '../../store/EmailEntradaBordero/EmailEntradaBorderoProvider';
import LoadingUI from '../../components/UI/Loading/LoadingUI';
import {
  ButtonEnviar, Buttons, ContainerPrincipal, TitleTable,
} from './EmailEntradaBorderoStyles';
import { getNewPage } from '../../helpers';
import { emailEntradaBorderoFactory } from './EmailEntradaBorderoFactory';
import { setSimpleEmailEntradaBorderoAction } from '../../store/EmailEntradaBordero/EmailEntradaBordero.store';
import { buscarDadosTabelaAction, enviarEmailEntradaBorderoAction, excluirEmailEntradaBorderoAction } from '../../store/EmailEntradaBordero/EmailEntradaBordero.saga';
import TabelaEmailEntradaBordero from './TabelaEmailEntradaBordero';
import OptionDialogNew from '../../components/UI/Dialogs/OptionDialog/OptionDialogNew';


function EmailEntradaBorderoComponent() {
  const dispatch = useEmailEntradaBorderoDispatch();

  const page = useEmailEntradaBorderoSelector(states => states.page);
  const rowsPerPage = useEmailEntradaBorderoSelector(states => states.rowsPerPage);
  const totalElements = useEmailEntradaBorderoSelector(states => states.totalElements);
  const tableView = useEmailEntradaBorderoSelector(states => states.tableView);
  const loadingPage = useEmailEntradaBorderoSelector(states => states.common.loadingPage);
  const [conteudoTabela, setConteudoTabela] = useState([]);
  const [idEmailEntradaBorderoDelete, setIdEmailEntradaBorderoDelete] = useState();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const changeRowsPerPageHandler = useCallback((e) => {
    const size = e.target.value;
    const newPage = getNewPage(rowsPerPage, page, size);

    dispatch(setSimpleEmailEntradaBorderoAction('page', Number(newPage)));
    dispatch(setSimpleEmailEntradaBorderoAction('rowsPerPage', Number(size)));
    dispatch(setSimpleEmailEntradaBorderoAction('tableView', tableView));
    dispatch(buscarDadosTabelaAction(false));
  }, [dispatch, rowsPerPage, page, tableView]);

  const changePageHandler = useCallback((event, newPage) => {
    dispatch(setSimpleEmailEntradaBorderoAction('page', Number(newPage)));
    dispatch(setSimpleEmailEntradaBorderoAction('tableView', tableView));
    dispatch(buscarDadosTabelaAction());
  }, [dispatch, tableView]);

  const onClickEnviar = useCallback(() => {
    dispatch(enviarEmailEntradaBorderoAction());
  }, [dispatch]);

  const handlerExcluirEmailEntradaBordero = useCallback((idEmailEntradaBordero) => {
    setIdEmailEntradaBorderoDelete(idEmailEntradaBordero);
    setOpenDeleteDialog(true);
  }, []);

  const onClickConfirmDelete = useCallback(() => {
    dispatch(excluirEmailEntradaBorderoAction(idEmailEntradaBorderoDelete));
    setOpenDeleteDialog(false);
  }, [idEmailEntradaBorderoDelete, dispatch]);

  useEffect(() => {
    dispatch(buscarDadosTabelaAction());
  }, [dispatch]);

  useEffect(() => {
    setConteudoTabela(emailEntradaBorderoFactory.makeConteudoTabela(
      tableView,
      handlerExcluirEmailEntradaBordero,
    ));
  }, [tableView, handlerExcluirEmailEntradaBordero]);

  return (
    <>
      <OptionDialogNew
        open={openDeleteDialog}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={() => { setOpenDeleteDialog(false); }}
        onClickConfirm={() => onClickConfirmDelete()}
        text="Deseja excluir este processo da lista de envio do Email de Entrada de Borderô?"
      />
      <LoadingUI show={loadingPage} />
      <ContainerPrincipal>
        <Buttons>
          <ButtonEnviar
            onClick={onClickEnviar}
          >
            Enviar
          </ButtonEnviar>
        </Buttons>
        <TitleTable>
          Processos Aguardando Envio de Email de Entrada de Borderô
        </TitleTable>
        <TabelaEmailEntradaBordero
          columns={emailEntradaBorderoFactory.makeCabecalhoTabela()}
          rows={conteudoTabela}
          page={page}
          size={totalElements}
          rowsPerPageOptions={[10, 15, 20]}
          rowsPerPage={rowsPerPage}
          onChangePage={changePageHandler}
          onChangeRowsPerPage={changeRowsPerPageHandler}
        />
      </ContainerPrincipal>
    </>
  );
}

export const EmailEntradaBordero = withRouter(EmailEntradaBorderoComponent);
