import FieldModel, { POSITIVE_INTEGER } from '../../models/FieldModel';
import { getAttributeExistingObject } from '../utils/utils';

export function buildTelefoneAdvogado(telefone) {
  return {
    idTelefone: new FieldModel({
      name: 'idTelefone',
      value: getAttributeExistingObject(telefone, 'idTelefone'),
    }),
    idEntidadeTelefone: new FieldModel({
      name: 'idEntidadeTelefone',
      value: getAttributeExistingObject(telefone, 'idEntidadeTelefone'),
    }),
    tipoTelefone: new FieldModel({
      name: 'tipoTelefone', value: getAttributeExistingObject(telefone, 'tipoTelefone'),
    }),
    ddd: new FieldModel({
      name: 'ddd',
      value: getAttributeExistingObject(telefone, 'ddd'),
      maxLength: 2,
      type: POSITIVE_INTEGER,
    }),
    numero: new FieldModel({
      name: 'numero',
      value: getAttributeExistingObject(telefone, 'numero'),
      maxLength: 11,
      type: POSITIVE_INTEGER,
    }),
    permitirWhatsApp: new FieldModel({
      name: 'permitirWhatsApp', value: getAttributeExistingObject(telefone, 'permitirWhatsApp'),
    }),
    observacao: new FieldModel({
      name: 'observacao',
      value: getAttributeExistingObject(telefone, 'observacao'),
      maxLength: 60,
    }),
  };
}

export function buildTelefone(telefone) {
  return {
    idTelefone: new FieldModel({
      name: 'idTelefone',
      value: getAttributeExistingObject(telefone, 'idTelefone'),
    }),
    idEntidadeTelefone: new FieldModel({
      name: 'idEntidadeTelefone',
      value: getAttributeExistingObject(telefone, 'idEntidadeTelefone'),
    }),
    principal: new FieldModel({
      name: 'principal', value: getAttributeExistingObject(telefone, 'principal', false),
    }),
    tipoTelefone: new FieldModel({
      name: 'tipoTelefone', value: getAttributeExistingObject(telefone, 'tipoTelefone'),
    }),
    ddd: new FieldModel({
      name: 'ddd',
      value: getAttributeExistingObject(telefone, 'ddd'),
      maxLength: 2,
      type: POSITIVE_INTEGER,
    }),
    numero: new FieldModel({
      name: 'numero',
      value: getAttributeExistingObject(telefone, 'numero'),
      maxLength: 11,
      type: POSITIVE_INTEGER,
    }),
    permitirWhatsApp: new FieldModel({
      name: 'permitirWhatsApp', value: getAttributeExistingObject(telefone, 'permitirWhatsApp', false),
    }),
    observacao: new FieldModel({
      name: 'observacao',
      value: getAttributeExistingObject(telefone, 'observacao'),
      maxLength: 60,
    }),
  };
}

export function buildTelefones(telefones) {
  if (!telefones) {
    return [];
  }

  return telefones
    .sort((origin, to) => to.principal - origin.principal)
    .map(buildTelefone);
}
