import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  boxTitle: {
    border: 'solid',
    borderRightWidth: '8px',
    borderLeftWidth: '8px',
    borderTopWidth: '7px',
    borderBottomWidth: '7px',
    backgroundColor: '#707070',
    borderRadius: '7px',
    width: 'fit-content',
    marginBottom: '7px',
    marginTop: '7px',
    position: 'relative',
    left: '-25px',
  },
  textTitle: {
    fontWeight: 700,
    color: 'white',
    fontSize: '12px',
  },
  lineConfiguration: {
    marginLeft: '14px',
    marginRight: '22px',
    border: 'none',
    height: '1px',
    backgroundColor: '#cecece',
  },
});
