import React from 'react';
import PropTypes from 'prop-types';
import './LoadingUI.css';


function LoadingUI({ show }) {
  const content = (
    <div className="loading-ui">
      <div className="loading-ring">
        <div />
      </div>
    </div>
  );
  return show ? content : null;
}

LoadingUI.propTypes = {
  show: true,
};

LoadingUI.propTypes = {
  show: PropTypes.bool,
};

export default LoadingUI;
