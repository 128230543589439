import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import TableHeader from '../../../models/TableHeader';
import Grid24 from '../../../components/UI/Grid24';
import LoadingUI from '../../../components/UI/Loading/LoadingUI';
import SnackbarUI from '../../../components/UI/Snackbar/Snackbar';
import BasicActionsTable from '../../../components/UI/Tabela/PaginadaSimples/BasicActons/BasicActions';
import SearchLayout from '../../../layouts/SimpleSearchLayout/SimpleSearchLayout';
import SelectorField from '../../../components/UI/Field/Selector';

import {
  clearAdvancedSearchAction,
  setAlertDialogValuesAction,
  setCacheRequestFieldsAction,
  setOptionDialogValuesAction,
  setPageAction,
  setRequestFieldsValueAction,
  setRowsPerPageAndPageAction,
  setScrollPositionAction,
  setShowAdvancedSearchAction,
  setSnackbarValuesAction,
} from '../../../store/Usuario/Pesquisa/pesquisarUsuario.store';
import { initializePageAction } from '../../../store/theme.actions';

import { deleteUsuarioAction, findUsuariosAction } from '../../../store/Usuario/Pesquisa/pesquisarUsuario.saga';

import { findFiliaisAction, findTipoUsuarioAction } from '../../../store/Global/LoadSelectors/loadSelectors.saga';

import constants from './PesquisarUsuario.constants';
import { FIRST_PAGE, SEARCH_TABLE_TITLE, SNACKBAR_DURATION } from '../../../helpers/constants/global.constants';

import {
  assignStatus,
  checkPermission,
  getNewPage,
  getScrollPosition,
  isLoading,
  moveScroll,
} from '../../../helpers/utils/utils';
import { buildSelectorStatus } from '../../../helpers/factory/global.factory';
import OptionDialogNew from '../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';


function isUsuarioAllowed(usuario, idUsuarioLogado) {
  return (usuario.idUsuario === idUsuarioLogado) || 201;
}

function buildUsuarioTable(usuarios, idLogedUsuario, onEdit, onDetails, onRemove) {
  return usuarios.map(usuario => ({
    id: usuario.idUsuario,
    values: {
      nome: usuario.nomeUsuario,
      tipoUsuario: usuario.tipoUsuario,
      filial: usuario.filial,
      status: assignStatus(usuario.statusUsuario),
      acoes: (
        <BasicActionsTable
          id={usuario.idUsuario}
          nome={usuario.nomeUsuario}
          onDetails={onDetails}
          onEdit={onEdit}
          onRemove={onRemove}
          allows={{
            consultar: 201,
            editar: 201,
            excluir: isUsuarioAllowed(usuario, idLogedUsuario),
          }}
          userAllow="U"
        />
      ),
    },
  }));
}

function buildTableColumns() {
  return [
    new TableHeader({
      id: 'nome', label: 'Nome', showTip: true, maxWidth: 307,
    }),
    new TableHeader({
      id: 'tipoUsuario', label: 'Tipo Usuário', maxWidth: 136,
    }),
    new TableHeader({
      id: 'filial', label: 'Filial', showTip: true, maxWidth: 243,
    }),
    new TableHeader({
      id: 'status', label: 'Status', alignCell: 'center',
    }),
    new TableHeader({
      id: 'acoes', label: 'Ações', maxWidth: 121, alignCell: 'center',
    }),
  ];
}

function PesquisaUsuarioPage({ history }) {
  const dispatch = useDispatch();

  const [statusList] = useState(buildSelectorStatus());
  const [removedUsuario, setRemovedUsuario] = useState({ id: null, nome: '' });
  const [tableRows, setTableRows] = useState([]);
  const [tableColumns] = useState(buildTableColumns());

  const nome = useSelector(states => states.pesquisaUsuario.requestFields.nome);
  const status = useSelector(states => states.pesquisaUsuario.requestFields.status);
  const filial = useSelector(states => states.pesquisaUsuario.requestFields.filial);
  const tipoUsuario = useSelector(states => states.pesquisaUsuario.requestFields.tipoUsuario);
  const cacheRequestFields = useSelector(states => states.pesquisaUsuario.cacheRequestFields);
  const settingsTable = useSelector(states => states.pesquisaUsuario.settingsTable);
  const searchLoading = useSelector(states => states.pesquisaUsuario.loading);
  const snackbar = useSelector(states => states.pesquisaUsuario.snackbar);
  const alertDialog = useSelector(states => states.pesquisaUsuario.alertDialog);
  const optionDialog = useSelector(states => states.pesquisaUsuario.optionDialog);
  const scrollPosition = useSelector(states => states.pesquisaUsuario.scrollPosition);
  const showAdvancedSearch = useSelector(states => states.pesquisaUsuario.showAdvancedSearch);
  const tiposUsuario = useSelector(states => states.selectors.tipoUsuario.selector);
  const filiais = useSelector(states => states.selectors.filiais.selector);

  const loadings = useSelector(states => [
    states.selectors.tipoUsuario.loading,
    states.selectors.filiais.loading,
  ]);

  const userPermissions = useSelector(states => (states.authentication.userInformation
    ? states.authentication.userInformation.permissoes : []));

  const previewRowPerPage = useRef();
  const tableContentLength = useRef();
  tableContentLength.current = settingsTable.content.length;

  const idLogedUsuario = useSelector((states) => {
    if (states.authentication.userInformation) {
      return states.authentication.userInformation.idUsuario;
    }
    return states.authentication.userInformation;
  });

  const openRemoveDialogHandler = useCallback((idFilail, nomeUsuario) => {
    setRemovedUsuario({ id: idFilail, nome: nomeUsuario });
    dispatch(setOptionDialogValuesAction(true));
  }, [dispatch]);

  const setScrollPostion = useCallback(() => {
    const position = getScrollPosition();
    dispatch(setScrollPositionAction(position.x, position.y));
  }, [dispatch]);

  const editHandler = useCallback((idUsuario) => {
    setScrollPostion();
    history.push(`/usuario/${idUsuario}/editar`);
  }, [history, setScrollPostion]);

  const detailsHandler = useCallback((idUsuario) => {
    setScrollPostion();
    history.push(`/usuario/${idUsuario}/detalhar`);
  }, [history, setScrollPostion]);

  useEffect(() => {
    dispatch(initializePageAction(`${constants.PAGE_TITLE}`));
    moveScroll(scrollPosition.x, scrollPosition.y);
  }, [dispatch, scrollPosition]);

  // Consultar dados para preencher a tabela
  useEffect(() => {
    const isChangePageWithourTableContent = () => previewRowPerPage.current
      && previewRowPerPage.current !== settingsTable.rowsPerPage
      && tableContentLength.current === 0;

    if (isChangePageWithourTableContent()) {
      previewRowPerPage.current = settingsTable.rowsPerPage;
      return;
    }

    if (cacheRequestFields) {
      dispatch(findUsuariosAction(settingsTable.page, settingsTable.rowsPerPage));
    }
    previewRowPerPage.current = settingsTable.rowsPerPage;
  }, [dispatch, cacheRequestFields, settingsTable.page, settingsTable.rowsPerPage]);

  // Construção da tabela
  useEffect(() => {
    setTableRows(buildUsuarioTable(settingsTable.content, idLogedUsuario, editHandler, detailsHandler, openRemoveDialogHandler));
  }, [settingsTable, setTableRows, editHandler, detailsHandler, openRemoveDialogHandler, idLogedUsuario]);

  const searchUsuariosHandler = useCallback((event) => {
    event.preventDefault();
    dispatch(setCacheRequestFieldsAction());
    dispatch(setPageAction(FIRST_PAGE));
  }, [dispatch]);

  const removeUsuarioHandler = useCallback(() => {
    dispatch(deleteUsuarioAction(removedUsuario.id));
    dispatch(setOptionDialogValuesAction(false));
  }, [dispatch, removedUsuario.id]);

  const goToRegisterUsuarioHandler = useCallback(() => {
    dispatch(setSnackbarValuesAction(false));
    history.push('/usuario/cadastrar');
  }, [dispatch, history]);

  const changePageHandler = useCallback((event, newPage) => {
    dispatch(setPageAction(newPage));
  }, [dispatch]);

  const changeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setRequestFieldsValueAction(name, value));
  }, [dispatch]);

  const changeRowsPerPageHandler = useCallback((event) => {
    const newRowsPerPage = event.target.value;
    const newPage = getNewPage(settingsTable.rowsPerPage, settingsTable.page, newRowsPerPage);
    dispatch(setRowsPerPageAndPageAction(newRowsPerPage, newPage));
  }, [dispatch, settingsTable.page, settingsTable.rowsPerPage]);

  const showAdvancedSearchHandler = useCallback(() => {
    if (tiposUsuario.length === 0 && filiais.length === 0) {
      dispatch(findTipoUsuarioAction());
      dispatch(findFiliaisAction());
    }
    dispatch(clearAdvancedSearchAction());
    dispatch(setShowAdvancedSearchAction(!showAdvancedSearch));
  }, [dispatch, showAdvancedSearch, filiais.length, tiposUsuario.length]);

  const closeSnackbarHandler = () => {
    dispatch(setSnackbarValuesAction(false));
  };

  const closeRemoveUsuarioAlertDialogHandler = () => {
    dispatch(setAlertDialogValuesAction(false));
  };

  const closeOptionDialogHandler = () => {
    dispatch(setOptionDialogValuesAction(false));
  };

  return (
    <>
      <LoadingUI show={isLoading([...loadings, searchLoading])} />
      <SnackbarUI
        open={snackbar.open}
        variant={snackbar.variant}
        message={snackbar.message}
        autoHideDuration={SNACKBAR_DURATION}
        onClose={closeSnackbarHandler}
      />
      <OptionDialogNew
        open={alertDialog.open}
        confirmLabel="OK"
        onClickConfirm={closeRemoveUsuarioAlertDialogHandler}
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>{`${alertDialog.message}.`}</p>
        </div>
      </OptionDialogNew>
      <OptionDialogNew
        open={optionDialog.open}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={closeOptionDialogHandler}
        onClickConfirm={removeUsuarioHandler}
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>
            {constants.getQuestionMessageDeleteDialog(removedUsuario.nome)}
          </p>
        </div>
      </OptionDialogNew>
      <SearchLayout
        allowedNovo={checkPermission(userPermissions, 201)}
        searchFieldLabel={constants.SEARCH_LABEL}
        openPesquisaAvancada={showAdvancedSearch}
        tituloTabela={SEARCH_TABLE_TITLE}
        fieldPesquisa={nome}
        tablePageable={settingsTable}
        tableColumns={tableColumns}
        tableRows={tableRows}
        onChangePesquisa={changeFieldsHandler}
        onClickPesquisa={searchUsuariosHandler}
        onClickButtonNovo={goToRegisterUsuarioHandler}
        onChangePage={changePageHandler}
        onChangeRowsPerPage={changeRowsPerPageHandler}
        clickPesquisaAvancada={showAdvancedSearchHandler}
      >
        <Grid24 container spacing="1">
          <Grid24 item xs={24} sm={4}>
            <SelectorField
              hasEmptyLabel
              displayEmpty
              label={constants.STATUS}
              name={status.name}
              value={status.value}
              items={statusList}
              onChange={changeFieldsHandler}

            />
          </Grid24>
          <Grid24 item xs={24} sm={12}>
            <SelectorField
              hasEmptyLabel
              label={constants.FILIAL}
              name={filial.name}
              value={filial.value}
              items={filiais}
              onChange={changeFieldsHandler}
            />
          </Grid24>
          <Grid24 item xs={24} sm={8}>
            <SelectorField
              hasEmptyLabel
              label={constants.TIPO_USUARIO}
              name={tipoUsuario.name}
              value={tipoUsuario.value}
              items={tiposUsuario}
              onChange={changeFieldsHandler}
            />
          </Grid24>
        </Grid24>
      </SearchLayout>
    </>
  );
}

export default withRouter(PesquisaUsuarioPage);
