import React, { useCallback, useEffect, useState } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { onChangeControls, setDiasClienteAction, setStoreValueAction } from '../../../../../../store/Cliente/V2/Crud/crudCliente.store';
import { useCrudClienteDispatch, useCrudClienteSelector } from '../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import CheckboxField from '../../../../../UI/Field/Checkbox/CheckboxField';
import RadioField from '../../../../../UI/Field/Radio/RadioField';
import Grid24 from '../../../../../UI/Grid24';
import FormWrapperWithTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import SwapList from '../../../../../Usuario/V2/Equipe/SwapList';
import { GroupDefinitions } from '../../BaseCalculo/Honorarios/styles';
import useStyle, { ErrorMessageDiaMes, ErrorMessageSemanal } from '../PrestacaoContasStyles';
import {
  DIARIA_LABEL, MANUAL_LABEL, PERSONALIZADA_LABEL, SEMANAL_LABEL,
} from '../PrestacaoContas.constants';


function Periodicidade({ isEditMode, onChangeChecked }) {
  const dispatch = useCrudClienteDispatch();
  const style = useStyle();

  const periodiciodadeDefinition = useCrudClienteSelector(states => states.periodiciodadeDefinition);
  const diasDoMes = useCrudClienteSelector(states => states.controls.diasDoMes);
  const periodicidadeContaCliente = useCrudClienteSelector(states => states.controls.periodicidadeContaCliente.copy());
  const periodicidadeSemanalSegunda = useCrudClienteSelector(states => states.controls.periodicidadeSemanalSegunda.copy());
  const periodicidadeSemanalTerca = useCrudClienteSelector(states => states.controls.periodicidadeSemanalTerca.copy());
  const periodicidadeSemanalQuarta = useCrudClienteSelector(states => states.controls.periodicidadeSemanalQuarta.copy());
  const periodicidadeSemanalQuinta = useCrudClienteSelector(states => states.controls.periodicidadeSemanalQuinta.copy());
  const periodicidadeSemanalSexta = useCrudClienteSelector(states => states.controls.periodicidadeSemanalSexta.copy());
  const listaDiasElegiveisCliente = useCrudClienteSelector(state => state.actions.listaDiasElegiveisCliente);
  const [diasElegiveis, setDiasElegiveis] = useState([]);
  const errorSemanalMessage = periodicidadeContaCliente.error;
  const errorDiasMesMessage = diasDoMes.error;

  const apagarDiasMes = useCallback(() => {
    setDiasElegiveis(old => ([...old.map((diasExcluir) => {
      diasExcluir.rightChecked = false;
      diasExcluir.currentChecked = false;
      return diasExcluir;
    })]));
    dispatch(setDiasClienteAction(null, false, true));
  }, [dispatch]);

  const onChangePeriodicidadeHandler = useCallback((e) => {
    const { value } = e.target;
    if (value !== 'SEMANAL') {
      dispatch(onChangeControls('periodicidadeSemanalSegunda', false));
      dispatch(onChangeControls('periodicidadeSemanalTerca', false));
      dispatch(onChangeControls('periodicidadeSemanalQuarta', false));
      dispatch(onChangeControls('periodicidadeSemanalQuinta', false));
      dispatch(onChangeControls('periodicidadeSemanalSexta', false));
    }

    if (value !== 'PERSONALIZADA') {
      apagarDiasMes();
    }

    dispatch(onChangeControls('periodicidadeContaCliente', value));
    dispatch(setStoreValueAction('periodiciodadeDefinition', value));
  }, [apagarDiasMes, dispatch]);

  const setParentList = useCallback((diasClientesParam) => {
    setDiasElegiveis(diasClientesParam);
  }, []);

  const addPayloadList = useCallback((idOpcaoDiasMesParam) => {
    dispatch(setDiasClienteAction(idOpcaoDiasMesParam, true, false));
  }, [dispatch]);

  const removePayloadList = useCallback((idOpcaoDiasMesParam) => {
    dispatch(setDiasClienteAction(idOpcaoDiasMesParam, false, false));
  }, [dispatch]);

  useEffect(() => {
    if (listaDiasElegiveisCliente) {
      setDiasElegiveis(listaDiasElegiveisCliente);
    }
  }, [listaDiasElegiveisCliente]);

  return (
    <Grid24 container isFormContainer spacing="1">
      <Grid24 xs={24} sm={24}>
        <FormWrapperWithTitle
          title="Periodicidade"
        >
          <Grid24 xs={24} sm={24} className={style.grid__radioButton}>
            <GroupDefinitions
              disabled={!isEditMode}
              aria-label="periodicidade"
              value={periodiciodadeDefinition}
              onChange={onChangePeriodicidadeHandler}
            >
              <Grid24 xs={24} sm={24}>
                <RadioField label={MANUAL_LABEL} value="MANUAL" />
              </Grid24>
              <Grid24 xs={24} sm={24}>
                <RadioField label={DIARIA_LABEL} value="DIARIA" />
              </Grid24>
              <Grid24 xs={24} sm={24}>
                <RadioField label={SEMANAL_LABEL} value="SEMANAL" />
              </Grid24>
              {(periodiciodadeDefinition === 'SEMANAL'
                && (
                  <>
                    <Grid24 container isFormContainer>
                      <Grid24 xs={24} sm={24} className={style.grid__days}>
                        <Grid24 xs={24} sm={2} className={style.grid__segunda}>
                          <CheckboxField
                            disabled={!isEditMode}
                            label="Segunda"
                            name={periodicidadeSemanalSegunda.name}
                            checked={periodicidadeSemanalSegunda.value}
                            onChange={onChangeChecked}
                          />
                        </Grid24>
                        <Grid24 xs={24} sm={2}>
                          <CheckboxField
                            disabled={!isEditMode}
                            label="Terça"
                            name={periodicidadeSemanalTerca.name}
                            checked={periodicidadeSemanalTerca.value}
                            onChange={onChangeChecked}
                          />
                        </Grid24>
                        <Grid24 xs={24} sm={2}>
                          <CheckboxField
                            disabled={!isEditMode}
                            label="Quarta"
                            name={periodicidadeSemanalQuarta.name}
                            checked={periodicidadeSemanalQuarta.value}
                            onChange={onChangeChecked}
                          />
                        </Grid24>
                        <Grid24 xs={24} sm={2}>
                          <CheckboxField
                            disabled={!isEditMode}
                            label="Quinta"
                            name={periodicidadeSemanalQuinta.name}
                            checked={periodicidadeSemanalQuinta.value}
                            onChange={onChangeChecked}
                          />
                        </Grid24>
                        <Grid24 xs={24} sm={2}>
                          <CheckboxField
                            disabled={!isEditMode}
                            label="Sexta"
                            name={periodicidadeSemanalSexta.name}
                            checked={periodicidadeSemanalSexta.value}
                            onChange={onChangeChecked}
                          />
                        </Grid24>
                        {errorSemanalMessage && (
                          <ErrorMessageSemanal>
                            <Grid24 xs={24} sm={24}>
                              <Grid24 xs={24} sm={1} className={style.icone}>
                                <FaExclamationTriangle size={20} color="#FF0000" />
                              </Grid24>
                              <Grid24 xs={24} sm={22}>
                                É obrigatório selecionar ao menos um dia da semana
                              </Grid24>
                            </Grid24>
                          </ErrorMessageSemanal>
                        )}
                      </Grid24>
                    </Grid24>
                  </>
                )
              )}
              <Grid24 xs={24} sm={24}>
                <RadioField label={PERSONALIZADA_LABEL} value="PERSONALIZADA" />
              </Grid24>
            </GroupDefinitions>
            {(periodiciodadeDefinition === 'PERSONALIZADA'
              && (
                <Grid24 xs={24} sm={24} className={style.grid__swapList}>
                  <Grid24 xs={24} sm={14} className={style.grid__swapListMin}>
                    <SwapList
                      leftTitle="Selecione o dia"
                      rightTitle="Dias Selecionados"
                      parentList={diasElegiveis}
                      setParentList={setParentList}
                      addPayloadList={addPayloadList}
                      removePayloadList={removePayloadList}
                      disabled={!isEditMode}
                      error={errorDiasMesMessage}
                    />
                  </Grid24>
                  {errorDiasMesMessage && (
                    <ErrorMessageDiaMes>
                      <Grid24 xs={24} sm={24}>
                        <Grid24 xs={24} sm={1} className={style.icone}>
                          <FaExclamationTriangle size={20} color="#FF0000" />
                        </Grid24>
                        <Grid24 xs={24} sm={22}>
                          É obrigatório selecionar ao menos um dia do mês
                        </Grid24>
                      </Grid24>
                    </ErrorMessageDiaMes>
                  )}
                </Grid24>
              )
            )}
          </Grid24>
        </FormWrapperWithTitle>
      </Grid24>
    </Grid24>
  );
}
export default Periodicidade;
