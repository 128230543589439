import { INITIALIZE_MENU, SET_QUANTIDADE_NOTIFICAOES } from './theme.actions';

export const CHANGE_BG_COLOR = 'CHANGE_BG_COLOR';
export const CHANGE_TITLE = 'CHANGE_TITLE';
export const LOAD_THEME = 'LOAD_THEME';
export const CHANGE_AVATAR_MENU = 'CHANGE_AVATAR_MENU';

const initialState = {
  bgColor: 'black',
  title: '',
  avatarMenu: null,
  quantidadeNotificacoes: 0,
};

export const changeColorTheme = bgColor => ({
  type: CHANGE_BG_COLOR,
  bgColor,
});

export const loadTheme = bgColor => ({
  type: LOAD_THEME,
  bgColor,
});

export const changeTitleTheme = title => ({
  type: CHANGE_TITLE,
  title,
});

export const changeAvatarMenu = avatarMenu => ({
  type: CHANGE_AVATAR_MENU,
  avatarMenu,
});

const changeColorHandle = (state, action) => ({ ...state, bgColor: action.bgColor });

const changeTittleMenuHandle = (state, action) => ({ ...state, title: action.title });

const loadThemeHandler = (state, action) => {
  const bgColor = action.bgColor === 0 ? 'white' : 'black';
  return {
    ...state,
    bgColor,
  };
};

const changeAvatarMenuHandle = (state, action) => ({ ...state, avatarMenu: action.avatarMenu });

function initializePageHandler(state, action) {
  return ({ ...state, title: action.title });
}

function setQuantidadeNotificacoesHandler(state, action) {
  return ({ ...state, quantidadeNotificacoes: action.quantidade });
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_THEME:
      return loadThemeHandler(state, action);
    case CHANGE_BG_COLOR:
      return changeColorHandle(state, action);
    case CHANGE_TITLE:
      return changeTittleMenuHandle(state, action);
    case CHANGE_AVATAR_MENU:
      return changeAvatarMenuHandle(state, action);
    case INITIALIZE_MENU:
      return initializePageHandler(state, action);
    case SET_QUANTIDADE_NOTIFICAOES:
      return setQuantidadeNotificacoesHandler(state, action);
    default:
      return state;
  }
};
