import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  actionWithRemoveButton: {
    paddingTop: '11px',
    '& label': {
      marginLeft: '8px',
    },
  },

});
