import { makeStyles } from '@material-ui/core';

export default makeStyles({
  div_senhaAtual: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '26.4px',
  },

  div_novaSenha: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '8.5px',
  },

  div_confirmarNovaSenha: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '7px',
  },

  div_buttons: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '23px',
    marginRight: '40px',
  },

  iconButton: {
    marginTop: '16px',
    marginBottom: '0',
    paddingTop: '0',
    paddingBottom: '0',
  },

  icon: {
    color: 'black',
    marginLeft: 2,
    height: '17px',
    width: '20px',
  },


});
