import styled from 'styled-components';

export const FloatContainer = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;
  z-index: 99999;

  background-color: transparent;
`;

export const Box = styled.div`
  position: absolute;
  z-index: 99999;
`;
