import makeStyles from '@material-ui/core/styles/makeStyles';
import { black, black16, warmGrey, white } from '../../../../helpers/constants/colors.constants';

export default makeStyles({
  loadingWrapper: {
    position: 'absolute',
    bottom: '0',
    height: '100%',
    width: '100%',
    borderRadius: '7px',
  },
  loading: {
    border: `1px solid ${warmGrey}`,
    position: 'absolute',
    top: '0',
    left: '43%',
    color: black,
    backgroundColor: white,
    padding: '6px 25px',
    boxShadow: `0 3px 6px 0 ${black16}`,
    textAlign: 'center',
  },
  loadingText: {
    fontSize: '12px',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
});
