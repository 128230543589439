import { Grid, Typography } from '@material-ui/core';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getDateTimeMask, getMoneyMask, getNewPage } from '../../../helpers/utils/utils';
import TableHeader from '../../../models/TableHeader';
import Table from '../../UI/Tabela/PaginadaSimples/Table';
import useUtilStyles from '../../../helpers/styles/utils.styles';
import useStyles from './acordosAs.styles';
import IconButtonTooltip from '../../UI/IconButtonTooltip/IconButtonTooltip';
import { pesquisarAcordosAsAction } from '../../../store/AcordoSeguro/Acordos/acordosAs.saga';
import {
  setPageAction,
  setRowsPerPageAndPageAction,
  setSnackbarAcordoASAction,
} from '../../../store/AcordoSeguro/Acordos/acordosAs.store';
import { SNACKBAR_DURATION } from '../../../helpers/constants/global.constants';
import SnackbarUI from '../../UI/Snackbar/Snackbar';
import OrdenacaoColuna from '../../UI/Tabela/Ordenacao/OrdenacaoColuna';

function AcordosAsTable() {
  const utilStyles = useUtilStyles();
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();


  const [tableRows, setTableRows] = useState([]);
  const [arrowOrdenacao, setArrowOrdenacao] = useState(false);
  const [columnOrdenacao, setColumnOrdenacao] = useState({
    element: 'dataAcordo',
    order: 'ASC',
  });

  const settingsTable = useSelector(states => states.acordosAs.settingsTable);
  const cacheRequestFields = useSelector(states => states.acordosAs.cacheRequestFields);
  const snackbarAcordosAs = useSelector(states => states.acordosAs.snackbarAcordosAs);


  const previewRowPerPage = useRef();
  const tableContentLength = useRef();
  tableContentLength.current = settingsTable.content.length;

  const redirecionarPagina = useCallback((nroProcesso) => {
    const location = {
      pathname: `/processos/${nroProcesso}/inspecionar`,
      state: { title: 'Acordos > Inspeção' },
    };
    return () => history.push(location);
  }, [history]);

  useEffect(() => {
    const isChangePageWithourTableContent = () => previewRowPerPage.current
      && previewRowPerPage.current !== settingsTable.rowsPerPage
      && tableContentLength.current === 0;

    if (isChangePageWithourTableContent()) {
      previewRowPerPage.current = settingsTable.rowsPerPage;
      return;
    }

    if (cacheRequestFields) {
      const ordenacao = [`${columnOrdenacao.element},${columnOrdenacao.order}`];
      dispatch(pesquisarAcordosAsAction(settingsTable.page, settingsTable.rowsPerPage, ordenacao));
    }
    previewRowPerPage.current = settingsTable.rowsPerPage;
  }, [dispatch, cacheRequestFields, settingsTable.page, settingsTable.rowsPerPage, columnOrdenacao]);


  const tableColumns = useMemo(() => [
    new TableHeader({
      id: 'dataAcordo',
      label: <OrdenacaoColuna
        columnLabel="Data do Acordo"
        idColumn="dataAcordo"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
      />,
      alignCell: 'center',
      width: 148,
    }),
    new TableHeader({
      id: 'nroProcesso',
      label: <OrdenacaoColuna
        columnLabel="Número"
        idColumn="nroProcesso"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
      />,
      alignCell: 'center',
      width: 94,
    }),
    new TableHeader({
      id: 'nomeDevedor',
      label: <OrdenacaoColuna
        columnLabel="Nome do Devedor"
        idColumn="nomeDevedor"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
      />,
      alignCell: 'left',
      width: 205,
    }),
    new TableHeader({
      id: 'nomeCliente',
      label: <OrdenacaoColuna
        columnLabel="Nome do Cliente"
        idColumn="nomeCliente"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
      />,
      alignCell: 'left',
      width: 162,
    }),
    new TableHeader({
      id: 'tipoAcordo',
      label: <OrdenacaoColuna
        columnLabel="Tipo do Acordo"
        idColumn="tipoAcordo"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
      />,
      alignCell: 'center',
      width: 154,
    }),
    new TableHeader({
      id: 'valorAcordo',
      label: <OrdenacaoColuna
        columnLabel="Valor do Acordo"
        idColumn="valorAcordo"
        arrowOrdenacao={arrowOrdenacao}
        columnOrdenacao={columnOrdenacao}
        setArrowOrdenacaoFunc={setArrowOrdenacao}
        setColumnOrdenacao={setColumnOrdenacao}
      />,
      alignCell: 'flex-end',
      width: 146,
    }),
    new TableHeader({
      id: 'icones',
      label: '',
      alignCell: 'flex-end',
      width: 42,
    }),
  ], [arrowOrdenacao, columnOrdenacao, setArrowOrdenacao]);

  const buildAcordosTable = useCallback(content => content.map(acordos => ({
    id: acordos.nroProcesso,
    values: {
      dataAcordo: getDateTimeMask(acordos.dataAcordo, true),
      nomeCliente: acordos.nomeCliente,
      nomeDevedor: acordos.nomeDevedor,
      nroProcesso: acordos.nroProcesso,
      tipoAcordo: acordos.tipoAcordo,
      valorAcordo: getMoneyMask(acordos.valorAcordo),
      icones: (
        <div className={styles.div__icons}>
          <IconButtonTooltip
            type="searchPlus"
            hint="Inspecionar"
            clickArea="0"
            onClick={redirecionarPagina(acordos.nroProcesso)}
          />
        </div>
      ),
    },
  })), [styles, redirecionarPagina]);

  useEffect(() => {
    setTableRows(buildAcordosTable(
      settingsTable.content,
    ));
  }, [settingsTable.content, setTableRows, buildAcordosTable]);

  const onChangePageHandler = useCallback((event, newPage) => {
    dispatch(setPageAction(newPage));
  }, [dispatch]);

  const onChangeRowsPerPageHandler = useCallback((event) => {
    const newRowsPerPage = event.target.value;
    const newPage = getNewPage(settingsTable.rowsPerPage, settingsTable.page, newRowsPerPage);
    dispatch(setRowsPerPageAndPageAction(newRowsPerPage, newPage));
  }, [dispatch, settingsTable.page, settingsTable.rowsPerPage]);

  const onCloseSnackbarHandler = useCallback(() => {
    dispatch(setSnackbarAcordoASAction({ open: false }));
  }, [dispatch]);


  return (
    <>
      <SnackbarUI
        open={snackbarAcordosAs.open}
        autoHideDuration={SNACKBAR_DURATION}
        variant={snackbarAcordosAs.variant}
        message={snackbarAcordosAs.message}
        onClose={onCloseSnackbarHandler}
      />
      <div className={styles.div__table}>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Typography component="h3" className={utilStyles.tableTitle}>
              Resultado da Pesquisa
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Table
              rows={tableRows}
              columns={tableColumns}
              page={settingsTable.page}
              size={settingsTable.size}
              rowsPerPage={settingsTable.rowsPerPage}
              onChangePage={onChangePageHandler}
              onChangeRowsPerPage={onChangeRowsPerPageHandler}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default AcordosAsTable;
