import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
import { CampoObrigatorio, InputField } from '../../../../components';
import Grid24 from '../../../../components/UI/Grid24';
import useStyle from '../AcordosStyle';
import {
  extractResponseErrorMessage, getFieldName, getRequiredLabel, isBadRequestException, makeErrorField,
} from '../../../../helpers';
import { loadingInspecaoProcessoController } from '../../loadingInspecaoProcessoController';
import { dialogEnviarEmailController } from './dialogEnviarEmailController';
import DialogNew from '../../../../layouts/FormDialog/DialogNew';


function DialogEnviarEmail({
  setOpenDialogEnviarEmail, updatePage, setLoadings, idAcordo,
}) {
  const [formFields, setFormFields] = useState(dialogEnviarEmailController.makeFormFields());
  const [errorFields, setErrorFields] = useState(dialogEnviarEmailController.makeErrorFields());
  const [openDialogErroBoletoRegistrado, setOpenDialogErroBoletoRegistrado] = useState(false);
  const [msgErroBoletoRegistrado, setMsgErroBoletoRegistrado] = useState('');

  const styles = useStyle();

  const onChangeHandler = useCallback((e) => {
    const name = getFieldName(e.target);
    const { value } = e.target;
    setFormFields(oldValue => ({ ...oldValue, [name]: { ...oldValue[name], value } }));
  }, []);

  const onFocusHandler = useCallback((e) => {
    const name = getFieldName(e.target);
    setErrorFields(oldValues => ({ ...oldValues, [name]: makeErrorField() }));
  }, []);

  const onClose = useCallback(() => {
    setOpenDialogEnviarEmail(false);
    setFormFields(dialogEnviarEmailController.makeFormFields());
    setErrorFields(dialogEnviarEmailController.makeErrorFields());
  }, [setOpenDialogEnviarEmail]);

  async function onClickSubmitEmail(e) {
    e.preventDefault();
    setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(true));
    try {
      await dialogEnviarEmailController.enviarEmails(idAcordo, formFields);
      updatePage();
      onClose();
      toast.success('E-mail enviado com sucesso.');
    } catch (error) {
      const validations = error?.response?.data?.validations;
      if (validations) {
        toast.error(error?.response?.data?.error, { style: { width: '392px' } });
        validations.forEach((validation) => {
          setErrorFields((errFields) => {
            errFields[validation.field].error = true;
            errFields[validation.field].errorMessage = validation.message;
            return errFields;
          });
        });
      } else {
        setMsgErroBoletoRegistrado(error?.response?.data?.message);
        setOpenDialogErroBoletoRegistrado(true);
      }
    } finally {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(false));
    }
  }

  useEffect(() => {
    dialogEnviarEmailController.buscarEmailsPadrao(idAcordo).then((response) => {
      const emails = response.data;
      setFormFields((forms) => {
        forms.emailList.value = emails;
        return forms;
      });
    }).catch((error) => {
      if (isBadRequestException(error)) {
        toast.error('Ocorreu um erro ao comunicar com o servidor para obter os emails.', { style: { width: '392px' } });
      }
      const erroMessage = extractResponseErrorMessage(error);
      if (erroMessage !== 'Erro não mapeado') {
        toast.error(erroMessage, { style: { width: '392px' } });
      }
    }).finally(() => {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(false));
    });
  }, [idAcordo, setLoadings]);

  return (
    <>
      <DialogNew
        open={openDialogErroBoletoRegistrado}
        className={styles.dialogErroEnviarTermoAcordoBoleto}
      >
        <div style={{ fontSize: '16px' }}>
          {msgErroBoletoRegistrado}
        </div>
        <div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button onClick={() => setOpenDialogErroBoletoRegistrado(false)}>
              Ok, ENTENDI
            </Button>
          </div>
        </div>
      </DialogNew>
      {errorFields?.emailList.error === true ? (
        <CampoObrigatorio />
      ) : (
        ''
      )}
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} style={{ marginBottom: '15px', marginTop: '12px' }}>
          <InputField
            isForm
            fullWidth
            autoComplete="off"
            label={getRequiredLabel('Para', true)}
            name={formFields.emailList.name}
            value={formFields.emailList.value}
            error={errorFields.emailList.error}
            errorMessage={errorFields.emailList.errorMessage}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
          />
        </Grid24>
      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          onClick={onClickSubmitEmail}
        >
          Enviar
        </Button>
      </div>

    </>
  );
}


export default DialogEnviarEmail;
