import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  faixaHonorario__trashContainer: {
    position: 'relative',
  },
  faixaHonorario__trashButton: {
    position: 'absolute',
    top: '7px',
  },
  faixaHonorario__row: {
    display: 'flex',
    flexDirection: 'row',
  },
  faixaHonorario__tituloFaixa: {
    fontSize: '14px',
    alignSelf: 'flex-end',
    marginRight: '12px',
    marginLeft: '10px',
    color: 'blue',
    fontWeight: 'bold',
  },
});
