import React from 'react';
import { Typography } from '@material-ui/core';

export function BoldContentDialog({ fullyMessage, pontoFinalNaUltimaMsg = false }) {
  const messages = fullyMessage.split('.');

  const getAlertMessages = (message, index) => {
    if (!message) {
      return undefined;
    }
    if (message[0] === '') {
      return '';
    }
    return (pontoFinalNaUltimaMsg && index > 0) ? `${message[index]}.` : message[index];
  };

  if (messages.length === 1) {
    return (
      <Typography style={{ textAlign: 'center' }} component="p">
        {getAlertMessages(messages, 0)}
      </Typography>
    );
  }

  return (
    <>
      <Typography style={{ textAlign: 'center', fontWeight: 'bold' }} component="p">
        {`${getAlertMessages(messages, 0)}.`}
      </Typography>
      <Typography style={{ textAlign: 'center' }} component="p">
        {getAlertMessages(messages, 1)}
      </Typography>
    </>
  );
}
