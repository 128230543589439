import styled from 'styled-components';
import SubTitleForm from '../UI/SubTitleForm/SubTitleForm';

export const SearchBarForm = styled.form`
  display: flex;
  
  > button {
    margin-left: 12px;
  }
`;

export const TableTitle = styled(SubTitleForm)`
  margin-top: 34px;
  margin-bottom: 20px;
`;
