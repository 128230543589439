import FieldModel, { POSITIVE_INTEGER } from '../../models/FieldModel';
import { toBrazilianDate } from '../../helpers/utils/utils';
import { buildSettingsTable, buildSnackbar } from '../../helpers/factory/global.factory';
import {
  CHANGE_SEARCH_CRITERIAS,
  CHANGE_SEARCH_CRITERIAS_VALUES,
  FOCUS_SEARCH_CRITERIAS,
  ON_CHANGE_ACTIONS_PAGE_VIEW,
  ON_CHANGE_STATES_SNACKBACK,
  ON_LOAD_LOG_PROCESSAMENTO_BORDERO_DETALHES,
  SET_CACHE_SEARCH_CRITERIA_FIELDS,
  SET_PAGE,
  SET_ROWS_PER_PAGE,
  SET_SETTINGS_TABLE,
} from './logProcessamentoBordero.constants';

function buildDateNow() {
  return toBrazilianDate(new Date());
}

function buildDate30daysAgo() {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  return toBrazilianDate(date);
}

function buildLogProcessamentoSearchCriterias() {
  return ({
    dataCadastramentoInicial: new FieldModel({
      name: 'dataCadastramentoInicial',
      value: buildDate30daysAgo(),
    }),
    dataCadastramentoFinal: new FieldModel({
      name: 'dataCadastramentoFinal',
      value: buildDateNow(),
    }),
    idCliente: new FieldModel({
      name: 'idCliente',
      type: POSITIVE_INTEGER,
    }),
    idProcesso: new FieldModel({
      name: 'idProcesso',
      type: POSITIVE_INTEGER,
    }),
    nomeDevedor: new FieldModel({
      name: 'nomeDevedor',
      maxLength: 25,
    }),
    texto: new FieldModel({
      name: 'texto',
    }),
    tipoBorderoLog: new FieldModel({
      name: 'tipoBorderoLog',
    }),
  });
}

function buildActionsPageView() {
  return ({
    isAdvacedSearch: false,
    loading: false,
    openDetalhes: false,
  });
}

function buildLogProcessamentoBorderoDetalhes() {
  return ({
    descricaoTipoBorderoLog: null,
    codigoCliente: null,
    nomeDevedor: null,
    qtdeTitulos: null,
    mensagem: null,
    dataCadastramento: null,
    valorTotalProcesso: null,
  });
}

function buildInitialStates() {
  return {
    logProcessamentoSearchCriterias: buildLogProcessamentoSearchCriterias(),
    logProcessamentoSearchCriteriasCache: null,
    settingsTable: buildSettingsTable(),
    snackbar: buildSnackbar(),
    actionsPageView: buildActionsPageView(),
    logProcessamentoBorderoDetalhes: buildLogProcessamentoBorderoDetalhes(),
  };
}

export const onLoadLogProcessamentoBorderoDetalhesAction = data => ({
  type: ON_LOAD_LOG_PROCESSAMENTO_BORDERO_DETALHES,
  data,
});

export const onChangeStatesSnackbarAction = snackbarContent => ({
  type: ON_CHANGE_STATES_SNACKBACK,
  snackbarContent,
});

export const onChangeSearchCriteriasAction = (name, value) => ({
  type: CHANGE_SEARCH_CRITERIAS,
  name,
  value,
});

export const onFocusSearchCriteriasAction = name => ({
  type: FOCUS_SEARCH_CRITERIAS,
  name,
});

export const onChangeActionsPageViewAction = (name, value) => ({
  type: ON_CHANGE_ACTIONS_PAGE_VIEW,
  name,
  value,
});

export const setSettingsTableAction = (content, page, rowsPerPage, totalElements) => ({
  type: SET_SETTINGS_TABLE,
  settingsTable: buildSettingsTable(content, page, rowsPerPage, totalElements),
});

export const changeCacheCriteriasFieldsActions = () => ({
  type: SET_CACHE_SEARCH_CRITERIA_FIELDS,
});

export const setPageAction = page => ({
  type: SET_PAGE,
  page,
});

export const setRowsPerPageAndPageAction = (rowsPerPage, page) => ({
  type: SET_ROWS_PER_PAGE,
  rowsPerPage,
  page,
});


export const changeSearchCriteriaFieldsAction = logProcessamentoSearchCriterias => ({
  type: CHANGE_SEARCH_CRITERIAS_VALUES,
  logProcessamentoSearchCriterias,
});

function onChangeSearchCriteriasHandler(states, actions) {
  const { name, value } = actions;
  const logProcessamentoSearchCriterias = states.logProcessamentoSearchCriterias[name].copy();
  return {
    ...states,
    logProcessamentoSearchCriterias: {
      ...states.logProcessamentoSearchCriterias,
      [name]: logProcessamentoSearchCriterias.onChange(value),
    },
  };
}

function onFocusSearchCriteriasHandler(states, actions) {
  const newSearchCriterias = { ...states.logProcessamentoSearchCriterias };
  newSearchCriterias[actions.name].onFocus();
  return ({
    ...states,
    logProcessamentoSearchCriterias: newSearchCriterias,
  });
}

function changeStatesSnackbarHandler(states, actions) {
  return {
    ...states,
    actionsPageView: {
      ...states.actionsPageView,
      snackbar: actions.snackbarContent,
    },
  };
}

function onChangeActionsPageViewHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    actionsPageView: {
      ...states.actionsPageView,
      [name]: value,
    },
  };
}

function changePageHandler(states, actions) {
  return {
    ...states,
    settingsTable: {
      ...states.settingsTable,
      page: actions.page,
    },
  };
}

function changeSettingsTableHandler(states, actions) {
  return {
    ...states,
    settingsTable: { ...actions.settingsTable },
  };
}

function changeRowsPerPageAndPageHandler(states, actions) {
  return {
    ...states,
    settingsTable: {
      ...states.settingsTable,
      rowsPerPage: actions.rowsPerPage,
      page: actions.page,
    },
  };
}

function changeCacheCriteriasFieldsHandler(states) {
  const dataCadastramentoInicial = states.logProcessamentoSearchCriterias.dataCadastramentoInicial.getSerializeDate();
  const dataCadastramentoFinal = states.logProcessamentoSearchCriterias.dataCadastramentoFinal.getSerializeDate();
  const idCliente = states.logProcessamentoSearchCriterias.idCliente.getValueNotEmpty();
  const idProcesso = states.logProcessamentoSearchCriterias.idProcesso.getValueNotEmpty();
  const nomeDevedor = states.logProcessamentoSearchCriterias.nomeDevedor.getValueNotEmpty();
  const texto = states.logProcessamentoSearchCriterias.texto.getValueNotEmpty();
  const tipoBorderoLog = states.logProcessamentoSearchCriterias.tipoBorderoLog.getValueNotEmpty();

  return {
    ...states,
    logProcessamentoSearchCriteriasCache: {
      dataCadastramentoInicial,
      dataCadastramentoFinal,
      idCliente,
      idProcesso,
      nomeDevedor,
      texto,
      tipoBorderoLog,
    },
  };
}

function changeSearchCriteriaFieldsHandler(states, actions) {
  return {
    ...states,
    logProcessamentoSearchCriterias: actions.logProcessamentoSearchCriterias,
  };
}

function onLoadLogProcessamentoBorderoDetalhesHandler(states, actions) {
  const { data } = actions;

  const {
    idBorderoLog,
    codigoCliente,
    dataCadastramento,
    descricaoTipoBorderoLog,
    mensagem,
    nomeDevedor,
    numeroProcesso,
    qtdeTitulos,
    valorTotalProcesso,
    responsavelProcessamento,
    tipoEntrada,
  } = data;

  return {
    ...states,
    logProcessamentoBorderoDetalhes: {
      ...states.logProcessamentoBorderoDetalhes,
      idBorderoLog,
      codigoCliente,
      dataCadastramento,
      descricaoTipoBorderoLog,
      mensagem,
      nomeDevedor,
      numeroProcesso,
      qtdeTitulos,
      valorTotalProcesso,
      responsavelProcessamento,
      tipoEntrada,
    },
  };
}

export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    case CHANGE_SEARCH_CRITERIAS:
      return onChangeSearchCriteriasHandler(states, actions);
    case FOCUS_SEARCH_CRITERIAS:
      return onFocusSearchCriteriasHandler(states, actions);
    case ON_CHANGE_STATES_SNACKBACK:
      return changeStatesSnackbarHandler(states, actions);
    case ON_CHANGE_ACTIONS_PAGE_VIEW:
      return onChangeActionsPageViewHandler(states, actions);
    case SET_SETTINGS_TABLE:
      return changeSettingsTableHandler(states, actions);
    case SET_PAGE:
      return changePageHandler(states, actions);
    case SET_ROWS_PER_PAGE:
      return changeRowsPerPageAndPageHandler(states, actions);
    case SET_CACHE_SEARCH_CRITERIA_FIELDS:
      return changeCacheCriteriasFieldsHandler(states, actions);
    case CHANGE_SEARCH_CRITERIAS_VALUES:
      return changeSearchCriteriaFieldsHandler(states, actions);
    case ON_LOAD_LOG_PROCESSAMENTO_BORDERO_DETALHES:
      return onLoadLogProcessamentoBorderoDetalhesHandler(states, actions);
    default:
      return states;
  }
};
