import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccordionDetails } from '@material-ui/core';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { initializePageAction } from '../../store/theme.actions';
import { ContaCorrentePesquisaClienteComponent } from './Pesquisa/ContaCorrentePesquisaCliente';
import { ContaCorrenteDetalhesClienteComponent } from './Detalhes/ContaCorrenteDetalhesCliente';
import { ContaCorrenteClienteController, ContaCorrenteClienteFactory } from './ContaCorrenteClienteController';
import { Accordion } from '../../components/UI/Inspecionar/Accordion/Accordion';
import LoadingUI from '../../components/UI/Loading/LoadingUI';
import { TableUtils } from '../../helpers';
import { LancamentosNaoPrestados } from './Accordions/NaoPrestados/LancamentosNaoPrestados';
import TabModel from '../../models/TabModel';
import TabCardLayout from '../../layouts/TabCardLayout/v2/TabCardLayout';
import TabPanel from '../../components/UI/TabPanel/TabPanel';
import { Container, Root } from './contaCorrenteClienteStyles';
import RelacaoPrestacao from './Accordions/RelacaoPrestacao/RelacaoPrestacao';
import Pagamentos from './Accordions/Pagamentos/Pagamentos';
import AutorizacoesRepasses from './V2/ContaCorrenteCliente/Repasse/Accordion/AutorizacoesRepasses';
import RepasseComponent from './V2/ContaCorrenteCliente/Repasse/RepasseComponent';


function existeClienteLista(manterClienteSelecionado, listaDeClientes, idClienteParam) {
  if (manterClienteSelecionado) {
    const clienteSelecionado = listaDeClientes.find(conta => conta.idCliente === idClienteParam);
    if (clienteSelecionado !== undefined) {
      return {
        idCliente: clienteSelecionado.idCliente,
        desbloqueado: clienteSelecionado.statusPrestacaoContas === 'DESBLOQUEADO',
      };
    }
  }
  return {
    idCliente: listaDeClientes[0]?.idCliente,
    desbloqueado: listaDeClientes[0]?.statusPrestacaoContas === 'DESBLOQUEADO',
  };
}

function ContaCorrenteClienteComponent() {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);


  const [tabItens] = useState([
    new TabModel('tab-prestacoes-conta', 0, 'Prestações de Contas', 'scrollable-auto-tabpanel'),
    new TabModel('tab-repasse', 1, 'Repasse', 'scrollable-auto-tabpanel'),
    new TabModel('tab-nota-fiscal', 2, 'Nota Fiscal', 'scrollable-auto-tabpanel'),
    new TabModel('tab-gerar-boleto', 3, 'Gerar Boleto', 'scrollable-auto-tabpanel'),
    new TabModel('tab-autorizacao-prestacao', 4, 'Autorização de Prestação', 'scrollable-auto-tabpanel'),
  ]);

  const changeTabViewHandler = useCallback((activeTabParam) => {
    setActiveTab(activeTabParam);
  }, [setActiveTab]);


  dispatch(initializePageAction('Conta-Corrente do Cliente'));
  const permissoesUsuario = useSelector(states => (states.authentication.userInformation ? states.authentication.userInformation.permissoes : []));

  const [permissoes] = useState({
    consultar: permissoesUsuario?.includes('ROLE_1801'),
    consultarTodos: permissoesUsuario?.includes('ROLE_1802'),
    efetuarLancamentos: permissoesUsuario?.includes('ROLE_1803'),
    prestarContas: permissoesUsuario?.includes('ROLE_1804'),
  });

  const [clienteSelecionado, setClienteSelecionado] = useState({
    idCliente: null,
    desbloqueado: false,
  });
  const [dadosResumoCliente, setDadosResumoCliente] = useState(null);
  const [canPrestarTodos, setCanPrestarTodos] = useState(false);
  const [loading, setLoading] = useState(false);

  // States referentes a pesquisa e prestação de contas.
  const [cacheFields, setCacheFields] = useState(null);
  const [idsContasCorrenteClientes, setIdsContasCorrenteClientes] = useState(null);
  const [tableView, setTableView] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const configureStatesScreen = useCallback((makeContas, payloads, rowsPerPageParam, pageNumber, manterClienteSelecionado = false, idClienteSelecionado = null) => {
    setIdsContasCorrenteClientes(makeContas);
    setTableView(TableUtils.paginar(makeContas, rowsPerPageParam, pageNumber));
    setClienteSelecionado(existeClienteLista(manterClienteSelecionado, makeContas, idClienteSelecionado));
    setCacheFields(payloads);
    setPage(pageNumber);
    setRowsPerPage(rowsPerPageParam);
  }, []);

  const atualizarPaginaAfterAction = useCallback(async (manterClienteSelecionado = false, idClienteSelecionado = null) => {
    setLoading(true);
    try {
      const contasPesquisadas = await ContaCorrenteClienteController.pesquisarContaCorrenteCliente(cacheFields);
      const makeContas = ContaCorrenteClienteFactory.makeTodasContasCorrentes(contasPesquisadas);
      configureStatesScreen(makeContas, cacheFields, rowsPerPage, page, manterClienteSelecionado, idClienteSelecionado);
      if (makeContas.length === 0) {
        toast.warning('Nenhum resultado foi encontrado relacionado à sua pesquisa');
        setClienteSelecionado(null);
      }
    } finally {
      setLoading(false);
    }
  }, [cacheFields, rowsPerPage, page, configureStatesScreen]);

  // Carrega os detalhes do cliente.
  useEffect(() => {
    if (clienteSelecionado?.idCliente) {
      setLoading(true);
      ContaCorrenteClienteController.getDetalhesCliente(clienteSelecionado?.idCliente).then((response) => {
        setDadosResumoCliente(response);
      }).catch(() => {
        setDadosResumoCliente(null);
      }).finally(() => {
        setLoading(false);
      });
    } else {
      setDadosResumoCliente(null);
    }
  }, [clienteSelecionado]);

  return (
    <Container>
      <LoadingUI show={loading} />
      <TabCardLayout
        tabItems={tabItens}
        parentStates={[activeTab, changeTabViewHandler]}
      />
      <TabPanel id={tabItens[0].id} index={tabItens[0].index} value={activeTab}>
        <Root>
          <ContaCorrentePesquisaClienteComponent
            idClienteSelecionado={clienteSelecionado}
            setIdClienteSelecionado={setClienteSelecionado}
            setCanPrestarTodos={setCanPrestarTodos}
            idsContasCorrenteClientes={idsContasCorrenteClientes}
            setIdsContasCorrenteClientes={setIdsContasCorrenteClientes}
            tableView={tableView}
            setTableView={setTableView}
            cacheFields={cacheFields}
            setCacheFields={setCacheFields}
            pageable={{ pageState: [page, setPage], rowsState: [rowsPerPage, setRowsPerPage] }}
            configureStatesScreenOnSuccess={configureStatesScreen}
          />
          <ContaCorrenteDetalhesClienteComponent
            dadosResumoCliente={dadosResumoCliente}
            clienteSelecionado={clienteSelecionado}
            permissoes={permissoes}
            canPrestarTodos={canPrestarTodos}
            atualizarPaginaAfterAction={atualizarPaginaAfterAction}
            idsContasCorrenteClientes={idsContasCorrenteClientes}
          />
        </Root>
        <div>
          <Accordion
            style={{ marginBottom: '11px' }}
            color="purePurple"
            title="Lançamentos Não Prestados"
          >
            <AccordionDetails>
              <LancamentosNaoPrestados
                setIdClienteSelecionado={setClienteSelecionado}
                clienteSelecionado={clienteSelecionado}
                permissoes={permissoes}
                nome={dadosResumoCliente?.detalhes?.nome}
                atualizarPaginaAfterAction={atualizarPaginaAfterAction}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ marginBottom: '11px' }}
            color="purePurple"
            title="Relação de Prestação"
          >
            <AccordionDetails>
              <RelacaoPrestacao
                clienteSelecionado={clienteSelecionado}
                permissoes={permissoes}
                nome={dadosResumoCliente?.detalhes?.nome}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ marginBottom: '11px' }}
            color="purePurple"
            title="Pagamentos"
          >
            <AccordionDetails>
              <Pagamentos />
            </AccordionDetails>
          </Accordion>
        </div>
      </TabPanel>
      <TabPanel id={tabItens[1].id} index={tabItens[1].index} value={activeTab}>
        <RepasseComponent
          permissoes={permissoes}
        />
        <Accordion
          style={{ marginBottom: '11px' }}
          color="purePurple"
          title="Autorizações de Repasse"
        >
          <AccordionDetails>
            <AutorizacoesRepasses />
          </AccordionDetails>
        </Accordion>
      </TabPanel>
      <TabPanel id={tabItens[2].id} index={tabItens[2].index} value={activeTab} />
      <TabPanel id={tabItens[3].id} index={tabItens[3].index} value={activeTab} />
      <TabPanel id={tabItens[4].id} index={tabItens[4].index} value={activeTab} />
    </Container>
  );
}

export const ContaCorrenteClientePage = withRouter(ContaCorrenteClienteComponent);
