import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  error: {
    backgroundColor: '#e91b1b',
  },
  fontSize: {
    fontSize: '12px',
    margin: '5px',
  },
  fullWidth: {
    maxWidth: '100%',
  },
});
