import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  actions: {
    marginTop: '46px',
  },
  tableWrapper: {
    '& >p': {
      fontSize: '1rem',
      fontWeight: 'bold',
      marginBottom: '13px',
      marginLeft: '14px',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      width: '146px',
      marginLeft: '16px',
      height: '34px',
    },
  },
});
