import React, { useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import Selector from '../../../../../UI/Field/Selector/SelectorField';
import FormWrapperWithTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import {
  ADD_HINT,
  ADD_TITLE,
  ADD_TITLE_QUERY,
  COMBO_BOX_TYPE,
  TITLE,
} from './HonorariosContratuais.constants';
import { AddContainer, Container } from '../Honorarios/styles';
import Grid24 from '../../../../../UI/Grid24';
import useUtilStyles from '../../../../../../helpers/styles/utils.styles';
import { useCrudClienteDispatch, useCrudClienteSelector } from '../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import FaixaHonorariosContratuais from './FaixaHonorariosContratuais';
import {
  addHonorarioContratualAction, onChangeControls, removeHonorarioContratualAction, updateHonorarioContratualAction,
} from '../../../../../../store/Cliente/V2/Crud/crudCliente.store';
import { getRequiredLabel } from '../../../../../../helpers';
import { findTipoHonorariosContratuaisAction } from '../../../../../../store/Global/LoadSelectors/loadSelectors.saga';

function HonorariosContratuais({ disabled }) {
  const dispatch = useCrudClienteDispatch();
  const globalDispatch = useDispatch();
  const utilStyles = useUtilStyles();

  const tipoHonorariosContratuais = useCrudClienteSelector(states => states.controls.tipoHonorariosContratuais);
  const tiposHonorariosContratuais = useSelector(states => states.selectors.tiposHonorariosContratuais.selector);
  const idTiposHonorarioContratual = useCrudClienteSelector(states => states.controls.idTiposHonorarioContratual);

  useEffect(() => {
    globalDispatch(findTipoHonorariosContratuaisAction());
    if (tipoHonorariosContratuais.honorariosContratuais.length <= 0) {
      dispatch(addHonorarioContratualAction());
    }
  }, [dispatch, tipoHonorariosContratuais.honorariosContratuais.length, globalDispatch]);

  const onChangeTipoHonorariosContratuaisHandler = (e) => {
    const { name, value } = e.target;
    dispatch(onChangeControls(name, value));
  };

  const onAddHonorarioContratualRangeHandler = useCallback(() => {
    dispatch(addHonorarioContratualAction());
  }, [dispatch]);

  const onChangeHonorarioContratualHandler = useCallback((e) => {
    const { name: fullname, value } = e.target;
    const [name, idx] = fullname.split('-');
    dispatch(updateHonorarioContratualAction(name, value, idx));
  }, [dispatch]);

  const onFocusHonorarioContratualHandler = useCallback((e) => {
    const { name: fullname } = e.target;
    const [name, idx] = fullname.split('-');
    dispatch(updateHonorarioContratualAction(name, '', idx, 'focus'));
  }, [dispatch]);

  const onRemoveHonorarioContratualRangeHandler = useCallback((e) => {
    const idxHonorarioContratual = +e.currentTarget.getAttribute('idxHonorarioContratual');
    dispatch(removeHonorarioContratualAction(idxHonorarioContratual));
  }, [dispatch]);

  return (
    <FormWrapperWithTitle title={TITLE}>
      <Container container isFormContainer>
        <Grid24 xs={24} sm={6}>
          <Selector
            isForm
            disabled={disabled}
            name={idTiposHonorarioContratual.name}
            label={getRequiredLabel(COMBO_BOX_TYPE, !disabled)}
            value={idTiposHonorarioContratual.value}
            items={tiposHonorariosContratuais}
            onChange={onChangeTipoHonorariosContratuaisHandler}
          />
        </Grid24>
        <Grid24>
          <AddContainer
            title={ADD_TITLE}
            readTitle={ADD_TITLE_QUERY}
            disabled={disabled}
            addHandler={onAddHonorarioContratualRangeHandler}
            tooltip={ADD_HINT}
            readMode={disabled}
          >
            {tipoHonorariosContratuais.honorariosContratuais.map((honorariosContratual, idx) => (
              <div key={honorariosContratual.key.value}>
                <FaixaHonorariosContratuais
                  key={honorariosContratual.key.value}
                  initial={honorariosContratual.valorFaixaInicial}
                  final={honorariosContratual.valorFaixaFinal}
                  porcentagemHonorariosContratuaisInicial={honorariosContratual.porcentagemHonorariosContratuaisInicial}
                  porcentagemHonorariosContratuaisFinal={honorariosContratual.porcentagemHonorariosContratuaisFinal}
                  showTrash={idx === 0}
                  disabled={disabled}
                  idxHonorarioContratual={idx}
                  onChange={onChangeHonorarioContratualHandler}
                  onFocus={onFocusHonorarioContratualHandler}
                  onRemove={onRemoveHonorarioContratualRangeHandler}
                />
                {idx !== tipoHonorariosContratuais.honorariosContratuais.length - 1 && (
                  <hr className={utilStyles.faixas__divider} />
                )}
              </div>
            ))}
          </AddContainer>
        </Grid24>
      </Container>
    </FormWrapperWithTitle>
  );
}


export default withRouter(HonorariosContratuais);
