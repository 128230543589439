import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LancamentosComissionadosController } from './LancamentosComissionadosController';
import useStyle, {
  FieldContainer, FooterLancamentoComissionado, InformationTitle, RootLancamentoComissionado,
} from '../Comissionado/ContaCorrenteComissionadoStyles';
import MoneyField from '../../components/UI/Field/Money/MoneyField';
import CheckboxField from '../../components/UI/Field/Checkbox/CheckboxField';
import {
  getFieldName,
  getRequiredLabel,
} from '../../helpers';
import {
  ButtonUI, CampoObrigatorio, InputField, SelectorField,
} from '../../components';
import LoadingUI from '../../components/UI/Loading/LoadingUI';
import LancamentosService from './LancamentosService';

function LancamentosComissionados(
  {
    isInsertMode = false,
    isEditMode = false,
  },
) {
  const style = useStyle();
  const history = useHistory();
  const [formFields, setFormFields] = useState(LancamentosComissionadosController.makeFormFieldsNewLancamentos());
  const [errorFields, setErrorFields] = useState(LancamentosComissionadosController.makeErrorFields());
  const [loading, setLoading] = useState(false);
  const [itensTipoLancamento, setItensTipoLancamento] = useState([]);
  const [idUsuario, setIdUsuario] = useState(null);
  const [idContaCorrente, setIdContaCorrente] = useState(null);

  async function onClickSalvar(event) {
    event.preventDefault();
    if (!idContaCorrente) {
      const { success, error } = await LancamentosComissionadosController.novoLancamento(errorFields,
        formFields, idUsuario, setLoading);
      if (success) {
        toast.success('Lançamento inserido com sucesso!');
        setFormFields(LancamentosComissionadosController.makeFormFieldsNewLancamentos());
        setErrorFields(LancamentosComissionadosController.makeErrorFields());
      } else {
        setErrorFields(error?.campos);
        toast.error('Revise as informações e preencha o cadastro corretamente', { style: { width: '392px' } });
      }
    } else {
      const {
        success,
        error,
      } = await LancamentosComissionadosController.editarLancamento(errorFields, formFields,
        idUsuario, idContaCorrente, setLoading);
      if (success) {
        history.goBack();
      } else {
        setErrorFields(error?.campos);
      }
    }
  }

  const onChangeFields = (event) => {
    const { name, value, checked } = event.target;
    setFormFields(oldValues => ({ ...oldValues, [name]: checked || value }));
  };

  const onFocusFields = (event) => {
    const { name } = getFieldName(event.target);
    setErrorFields(oldValues => ({ ...oldValues, [name]: { error: false, errorMessage: '' } }));
  };

  useEffect(() => {
    LancamentosComissionadosController.buscarTiposLancamento().then((res) => {
      setItensTipoLancamento(LancamentosComissionadosController.makeItemsLancamento(res));
    }).catch(() => {
      setItensTipoLancamento([]);
    });
  }, []);

  useEffect(() => {
    const idComissionado = history.location?.state?.idComissionado;
    const idContaCorrenteUsuario = history.location?.state?.idContaCorrenteUsuario;
    if (idComissionado) {
      setIdUsuario(idComissionado);
    } else {
      history.goBack();
    }
    if (idContaCorrenteUsuario) {
      setIdContaCorrente(idContaCorrenteUsuario);
      LancamentosService.buscarLancamento(idContaCorrenteUsuario).then((res) => {
        setFormFields(LancamentosComissionadosController.makeFormFieldsNewLancamentos(res.data));
      }).catch(() => {
        history.goBack();
      });
    }
  }, [history]);

  return (
    <>
      <LoadingUI show={loading} />
      <form onSubmit={onClickSalvar}>
        <RootLancamentoComissionado>
          <FieldContainer>
            <CampoObrigatorio visible={isInsertMode || isEditMode} />
            <InformationTitle>Informações</InformationTitle>
            <div className={style.div__container}>
              <div className={style.div__dataLancamento}>
                <InputField
                  label="Data do Lançamento"
                  value={formFields.data}
                  onChange={onChangeFields}
                  name="data"
                  isForm
                  disabled
                />
              </div>
              <div className={style.div__tipoLancamento}>
                <SelectorField
                  label={getRequiredLabel('Tipo do Lançamento', isInsertMode || isEditMode)}
                  onChange={onChangeFields}
                  onFocus={onFocusFields}
                  items={itensTipoLancamento}
                  error={errorFields.idTipoContaCorrenteUsuario.error}
                  errorMessage={errorFields.idTipoContaCorrenteUsuario.errorMessage}
                  value={formFields.idTipoContaCorrenteUsuario}
                  name="idTipoContaCorrenteUsuario"
                  isForm
                />
              </div>
              <div className={style.div__valorLancamento}>
                <MoneyField
                  label={getRequiredLabel('Valor do Lançamento', isInsertMode || isEditMode)}
                  onChange={onChangeFields}
                  onFocus={onFocusFields}
                  error={errorFields.valor.error}
                  errorMessage={errorFields.valor.errorMessage}
                  value={formFields.valor}
                  name="valor"
                  isForm
                />
              </div>
            </div>
            <div className={style.div__historico}>
              <InputField
                label={getRequiredLabel('Histórico', isInsertMode || isEditMode)}
                onChange={onChangeFields}
                onFocus={onFocusFields}
                error={errorFields.historico.error}
                errorMessage={errorFields.historico.errorMessage}
                name="historico"
                value={formFields.historico}
                isForm
                fullWidth
              />
            </div>
            <div className={style.div__checkbox}>
              <CheckboxField
                label="Bloqueado"
                onChange={onChangeFields}
                onFocus={onFocusFields}
                checked={formFields.bloqueado}
                name="bloqueado"
              />
            </div>
          </FieldContainer>
          <FooterLancamentoComissionado>
            <div>
              <div>
                <ButtonUI
                  color="error"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancelar
                </ButtonUI>
              </div>
              <div>
                <ButtonUI
                  type="submit"
                  color="success"
                >
                  Salvar
                </ButtonUI>
              </div>
            </div>
          </FooterLancamentoComissionado>
        </RootLancamentoComissionado>
      </form>
    </>
  );
}
export default withRouter(LancamentosComissionados);
