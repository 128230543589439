import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Proptypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import CssBaseline from '@material-ui/core/CssBaseline';
import Icon from '@material-ui/core/Icon';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';

import Face from '@material-ui/icons/Face';
import Logo from '../../assets/images/icons/logo.svg';

import TextFieldUI from '../../components/UI/TextField/TextFieldUI';
import PaperUI from '../../components/UI/Paper/PaperUI';
import ButtonUI from '../../components/UI/Button/ButtonUI';
import LoadingUI from '../../components/UI/Loading/LoadingUI';
import FormErrorMessage from '../../components/UI/FormErrorMessage/FormErrorMessage';

import useFormObject from '../../hoc/useFormObject/useFormObject';
import { authenticateAction } from '../../store/Login/login.saga';

import withTokenInterceptor from '../../hoc/withTokenInterceptor/withTokenInterceptor';
import useStyles from './Login.styles';
import initialStates from './Login.state';
import constants from '../../helpers/constants/loginPage.constants';
import SweetAlertGeneric from '../../components/UI/SweetAllert/SweetAlert';

function LoginCont({ history }) {
  const classes = useStyles();
  const [submited, setSubmited] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const errorMessageAfterSubmit = useSelector(state => state.authentication.error);
  const loading = useSelector(state => state.authentication.loading);
  const usuario = useSelector(state => state.authentication.userInformation);

  const dispatch = useDispatch();

  useEffect(() => {
    if (usuario !== null && usuario.tipoUsuario !== 'C') {
      history.replace('/');
    } else if (usuario !== null && usuario.tipoUsuario === 'C') {
      setOpenDialog(true);
    }
  }, [usuario, history]);

  const handleErrors = (values, isSubmited) => {
    setFormErrors({
      submited: isSubmited,
      usuario: values.usuario.error,
      senha: values.senha.error,
    });
  };

  const isError = target => formErrors[target] && submited;

  const {
    onChange, onBlur, values, isValidForm,
  } = useFormObject(
    initialStates(sessionStorage.getItem('nome')),
    handleErrors,
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    sessionStorage.clear();
    setSubmited(true);
    const isValid = isValidForm(true);
    const { usuario, senha } = values;
    if (isValid) {
      dispatch(authenticateAction(usuario.value, senha.value));
    }
  };

  const showMessageError = () => {
    if (!submited) {
      return <FormErrorMessage message="Error" show={false} />;
    }
    if (formErrors.usuario && formErrors.senha) {
      return <FormErrorMessage message={constants.USUARIO_SENHA_INVALIDOS} />;
    }
    if (formErrors.usuario) {
      return <FormErrorMessage message={constants.USUARIO_INVALIDO} />;
    }
    if (formErrors.senha) {
      return <FormErrorMessage message={constants.SENHA_INVALIDA} />;
    }
    if (errorMessageAfterSubmit) {
      return <FormErrorMessage message={errorMessageAfterSubmit} />;
    }
    return <FormErrorMessage message="Error" show={false} />;
  };

  function closeDialog() {
    setOpenDialog(false);
  }

  return (
    <Container
      maxWidth="xs"
      component="form"
      onSubmit={handleSubmit}
      classes={{ root: classes.wrapper }}
    >
      <CssBaseline />
      <LoadingUI show={loading} />
      <PaperUI className={classes.card}>
        <header className={classes.card}>
          <div className={classes.logo}>
            <img src={Logo} alt="Logo" />
          </div>
          <Typography variant="h4" component="h1" classes={{ root: classes.title }}>
            Login
          </Typography>
        </header>
        <section className={classes.cardContent}>
          {showMessageError()}
          <TextFieldUI
            label={constants.USUARIO}
            error={isError('usuario')}
            name="usuario"
            value={values.usuario.value}
            onChange={onChange}
            InputProps={{
              onChange,
              onBlur,
              endAdornment: (
                <InputAdornment position="end">
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
          <TextFieldUI
            label={constants.SENHA}
            error={isError('senha')}
            name="senha"
            value={values.senha.value}
            InputProps={{
              type: 'password',
              onChange,
              onBlur,
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                </InputAdornment>
              ),
            }}
          />
          <ButtonUI type="submit" style={{ marginTop: '20px' }} className={classes.buttonSpacing}>Entrar</ButtonUI>
          { /* Funcionalidade escondida caso futuramente precise ser implementada
          <Link to="/login" className={classes.forgivePassword}>
            {constants.ESQUECEU_SENHA}
          </Link>
          */
          }
        </section>
      </PaperUI>
      {openDialog && (
        <SweetAlertGeneric
          title=""
          closeDialog={closeDialog}
          message="Acesso não autorizado temporariamente"
          sucess={false}
          open={openDialog}
        />
      )}
    </Container>
  );
}

LoginCont.propTypes = {
  history: Proptypes.object.isRequired,
};

export default withTokenInterceptor(withRouter(LoginCont));
