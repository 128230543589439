import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';

import CheckboxField from '../UI/Field/Checkbox/CheckboxField';
import OrdenacaoColuna from '../UI/Tabela/Ordenacao/OrdenacaoColuna';

function TableHeaderAntecedentesProcessoComponent({
  changeOrder, order, isSelectedAll, onSelectAll, disabledSelection,
  accordion,
}) {
  const arrowDirection = order.order === 'DESC';
  return (
    <TableRow>
      <TableCell>
        <CheckboxField
          disabled={disabledSelection && accordion}
          onChange={onSelectAll}
          checked={isSelectedAll}
        />
      </TableCell>
      <TableCell>
        <OrdenacaoColuna
          columnLabel="Número"
          idColumn="idProcesso"
          arrowOrdenacao={arrowDirection}
          columnOrdenacao={order}
          onClickColumn={() => changeOrder('idProcesso')}
        />
      </TableCell>
      <TableCell>
        <OrdenacaoColuna
          columnLabel="Acordo Ativo"
          idColumn="acordoAtivo"
          arrowOrdenacao={arrowDirection}
          columnOrdenacao={order}
          onClickColumn={() => changeOrder('acordoAtivo')}
        />
      </TableCell>
      <TableCell>
        <OrdenacaoColuna
          columnLabel="Fase"
          idColumn="nomeFase"
          arrowOrdenacao={arrowDirection}
          columnOrdenacao={order}
          onClickColumn={() => changeOrder('nomeFase')}
        />
      </TableCell>
      <TableCell>
        <OrdenacaoColuna
          columnLabel="Negociador"
          idColumn="nomeUsuario"
          arrowOrdenacao={arrowDirection}
          columnOrdenacao={order}
          onClickColumn={() => changeOrder('nomeUsuario')}
        />
      </TableCell>
      <TableCell>
        <OrdenacaoColuna
          columnLabel="Data de Abertura"
          idColumn="dataAbertura"
          arrowOrdenacao={arrowDirection}
          columnOrdenacao={order}
          onClickColumn={() => changeOrder('dataAbertura')}
        />
      </TableCell>
      <TableCell>
        <OrdenacaoColuna
          columnLabel="Cliente"
          idColumn="nomeCliente"
          arrowOrdenacao={arrowDirection}
          columnOrdenacao={order}
          onClickColumn={() => changeOrder('nomeCliente')}
        />
      </TableCell>
      <TableCell>
        <OrdenacaoColuna
          columnLabel="Devedor"
          idColumn="nomeDevedor"
          arrowOrdenacao={arrowDirection}
          columnOrdenacao={order}
          onClickColumn={() => changeOrder('nomeDevedor')}
        />
      </TableCell>
      <TableCell width="5%">
        <OrdenacaoColuna
          columnLabel="Valor do Processo"
          idColumn="valorTotal"
          arrowOrdenacao={arrowDirection}
          columnOrdenacao={order}
          onClickColumn={() => changeOrder('valorTotal')}
        />
      </TableCell>
      <TableCell style={{ width: '80px' }} />
    </TableRow>
  );
}

export const TableHeaderAntecedentesProcesso = TableHeaderAntecedentesProcessoComponent;
