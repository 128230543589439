import React, { useCallback, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import {
  changeEditModeAction,
  changeTabViewAction, setAlertDialogValuesAction,
  setRequestFieldsFocusAction,
  setRequestFieldsValueAction,
} from '../../../store/Parametros/Crud/crudParametros.store';
import TabCardLayout from '../../../layouts/TabCardLayout/v2/TabCardLayout';
import FooterForm from '../../UI/FooterForm/FooterForm';
import TabModel from '../../../models/TabModel';
import TabPanel from '../../UI/TabPanel/TabPanel';
import CampoObrigatorio from '../../UI/CampoObrigatorio/CampoObrigatorio';
import IdentificacaoCrudParametros from './DadosCadastrais/IdentificacaoCrudParametros';
import EnderecoCrudParametros from './Endereco/EnderecoCrudParametros';
import TelefonesCrudParametros from './Telefones/TelefonesCrudParametros';
import PaperUI from '../../UI/Paper/PaperUI';
import CardWrapper from '../../UI/CardWrapper/CardWrapper';
import {
  useCrudParametrosDispatch,
  useCrudParametrosSelector,
} from '../../../store/Parametros/Crud/CrudParametrosProvider';
import { getParametrosAction, insertParametrosAction } from '../../../store/Parametros/Crud/crudParametros.saga';
import TabCrudParametrosBorderos from './Tabs/Bordero/TabCrudParametrosBorderos';
import TabCrudParametrosGerais from './Tabs/SistemaWeb/TabCrudParametrosGerais';
import TabCrudParametrosBaseCalculo from './Tabs/BaseCalculo/TabCrudParametrosBaseCalculo';

import TabCrudParametroConfigPagamento from './Tabs/ConfiguracoesPagamento/TabCrudParametroConfigPagamento';
import AlertDialog from '../../UI/Dialogs/AlertDialog/AlertDialog';
import dicaIcon from '../../UI/Dica/lampada-icon.png';
import TabContasBancarias from './Tabs/ContasBancarias/TabContasBancarias/TabContasBancarias';


const tabItems = [
  new TabModel('tab-sistema-web', 0, 'Gerais', 'scrollable-auto-tabpanel'),
  new TabModel('tab-borderos', 1, 'Borderôs', 'scrollable-auto-tabpanel'),
  new TabModel('tab-base-calculo', 2, 'Base de Cálculo', 'scrollable-auto-tabpanel'),
  //  new TabModel('tab-site-acordo-seguro', 3, 'Site Acordo Seguro', 'scrollable-auto-tabpanel'),
  new TabModel('tab-config-pagamento', 3, 'Configurações Pagamento', 'scrollable-auto-tabpanel'),
  new TabModel('tab-contas-bancarias', 4, 'Contas Bancárias', 'scrollable-auto-tabpanel'),
];

function CrudParametros({ hasEditPermission }) {
  const dispatch = useCrudParametrosDispatch();


  const alertDialog = useCrudParametrosSelector(states => states.actions.alertDialog);
  const showLoading = useCrudParametrosSelector(states => states.loading);
  const activeTabView = useCrudParametrosSelector(states => states.pageView.tabActive);
  const isEditMode = useCrudParametrosSelector(states => states.pageView.isEditMode);


  const hasEditPermissionRef = useRef();
  hasEditPermissionRef.current = hasEditPermission;

  const isEditModeRef = useRef();
  isEditModeRef.current = isEditMode;

  const changeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setRequestFieldsValueAction(name, value));
  }, [dispatch]);

  const focusFieldsHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(setRequestFieldsFocusAction(fieldName));
  }, [dispatch]);

  const changeActiveTabeViewHandler = (activeTab) => {
    dispatch(changeTabViewAction(activeTab));
  };

  const closeEditHandler = () => {
    dispatch(getParametrosAction());
    dispatch(changeEditModeAction(false));
  };

  const openEditHandler = () => {
    dispatch(changeEditModeAction(true));
  };

  const submitPageHandler = () => {
    dispatch(insertParametrosAction());
  };

  useEffect(() => {
    dispatch(getParametrosAction());
    dispatch(changeEditModeAction(hasEditPermissionRef.current && isEditModeRef.current));
  }, [dispatch]);

  const closeErrorDialogHandler = () => {
    dispatch(setAlertDialogValuesAction(false));
  };

  const pageForm = () => (
    <>
      <AlertDialog
        open={alertDialog.open}
        variant={alertDialog.variant}
        onClickConfirm={closeErrorDialogHandler}
      >
        <Typography style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '10px' }} component="p">{alertDialog.message}</Typography>
        <Typography style={{ textAlign: 'center' }} component="p">
          <img src={dicaIcon} alt="Dica" width={18} height={18} />
          {' '}
          <b>Dica</b>
          : Abra o arquivo inválido em um editor de imagem e
          salve como uma nova imagem de formato válido (PNG, JPEG ou JPG).
          Retorne para o cadastro e tente selecionar a imagem novamente.
        </Typography>
      </AlertDialog>
      <PaperUI>
        <CardWrapper showLoading={showLoading}>
          <CampoObrigatorio disabled={!isEditMode} />
          <IdentificacaoCrudParametros isInsertMode={isEditMode} />
          <EnderecoCrudParametros isInsertMode={isEditMode} />
          <TelefonesCrudParametros isInsertMode={isEditMode} />
        </CardWrapper>
      </PaperUI>
    </>
  );

  const tabsForm = () => (
    <div style={{ marginTop: '16px' }}>
      <TabCardLayout
        tabItems={tabItems}
        parentStates={[activeTabView, changeActiveTabeViewHandler]}
        footerComponent={(
          <FooterForm
            submitHandler={(hasEditPermission && isEditMode) ? (submitPageHandler) : null}
            cancelHandler={(hasEditPermission && isEditMode) ? (closeEditHandler) : null}
            editHandler={(hasEditPermission.gerenciarParametro && !isEditMode) ? (openEditHandler) : null}
          />
        )}
      >
        <TabPanel id={tabItems[0].id} index={tabItems[0].index} value={activeTabView}>
          <TabCrudParametrosGerais isInsertMode={isEditMode} />
        </TabPanel>
        <TabPanel id={tabItems[1].id} index={tabItems[1].index} value={activeTabView}>
          <TabCrudParametrosBorderos isInsertMode={isEditMode} />
        </TabPanel>
        <TabPanel id={tabItems[2].id} index={tabItems[2].index} value={activeTabView}>
          <TabCrudParametrosBaseCalculo
            isInsertMode={isEditMode}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
          />
        </TabPanel>
        <TabPanel id={tabItems[3].id} index={tabItems[3].index} value={activeTabView}>
          <TabCrudParametroConfigPagamento
            isInsertMode={isEditMode}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
          />
        </TabPanel>
        <TabPanel id={tabItems[4].id} index={tabItems[4].index} value={activeTabView}>
          <TabContasBancarias
            isInsertMode={isEditMode}
          />
        </TabPanel>
      </TabCardLayout>
    </div>
  );

  return (
    <>
      {pageForm()}
      {tabsForm()}
    </>
  );
}

export default withRouter(CrudParametros);
