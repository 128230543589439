import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  disabled: {
    borderRadius: '46px',
    backgroundImage: 'none !important',
    backgroundColor: 'white !important',
    opacity: '0.85',
    color: 'black',
    border: 'solid thin',
  },
  loadingRoot: {
    '& .MuiCircularProgress-colorPrimary': {
      color: '#a2a0a0 !important',
    },
  },
});
