import React, { createContext } from 'react';
import { applyMiddleware, createStore } from 'redux';

import createSagaMiddleware from 'redux-saga';
import { createDispatchHook, createSelectorHook, Provider } from 'react-redux';

import reducer from './pastas.store';
import watchSaga from './pastas.saga';

export const pastasContext = createContext();
export const usePastaSelector = createSelectorHook(pastasContext);
export const usePastaDispatch = createDispatchHook(pastasContext);

export default function PastasProvider({ children }) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(watchSaga);

  return (
    <Provider context={pastasContext} store={store}>
      {children}
    </Provider>
  );
}
