import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';

import useStyles from './SwitchUI.styles';

function SwitchUI({
  model = '', label = '', checked = false, name, marginTop = '15px', ...props
}) {
  const styles = useStyles({ marginTop });

  const switchContent = (
    <Switch checked={model.value || checked} name={model.name || name} color="primary" {...props} />
  );

  return (
    <div style={{ display: 'flex' }}>
      <FormControlLabel classes={{ root: styles.wrapper }} label={label} control={switchContent} />
    </div>
  );
}

export default React.memo(SwitchUI);
