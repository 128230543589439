/* eslint-disable react/style-prop-object */
import React, { useCallback, useMemo, useState } from 'react';
import cx from 'classnames';

import IconButtonTooltip from '../../IconButtonTooltip/IconButtonTooltip';
import CheckboxField from '../../Field/Checkbox/CheckboxField';
import InputField from '../../Field/Input';
import SelectorField from '../../Field/Selector';
import MaskField from '../../Field/Mask';
import Grid24 from '../../Grid24/Grid24';

import { getAttributeExistingObject, getRequiredLabel } from '../../../../helpers/utils/utils';
import { CEP_MASK } from '../../../../helpers/constants/layout.constants';
import { getCorreiosCep } from '../../../../services/correios/cep/cep.services';

import useStyles from './Endereco.styles';

export function buildLayoutConstants(requiredFields) {
  return {
    CEP: getRequiredLabel('CEP', getAttributeExistingObject(requiredFields, 'cep', false)),
    ENDERECO: getRequiredLabel('Endereço', getAttributeExistingObject(requiredFields, 'endereco', false)),
    NUMERO: getRequiredLabel('Número', getAttributeExistingObject(requiredFields, 'numero', false)),
    COMPLEMENTO: getRequiredLabel('Complemento', getAttributeExistingObject(requiredFields, 'complemento', false)),
    BAIRRO: getRequiredLabel('Bairro', getAttributeExistingObject(requiredFields, 'bairro', false)),
    CIDADE: getRequiredLabel('Cidade', getAttributeExistingObject(requiredFields, 'cidade', false)),
    ESTADO: getRequiredLabel('Estado', getAttributeExistingObject(requiredFields, 'estado', false)),
    PRINCIPAL: 'Principal',
    TOOLTIP_TRASH: 'Excluir Endereço',
  };
}

function Endereco({
  disabled = false,
  estados = [],
  model,
  index = '',
  setEndereco,
  setEnderecos,
  setAttribute,
  setFocus,
  hasPrincipal = false,
  hasTrash = false,
  enderecosWithPrincipalFalse,
  requiredFields = {},
  onRemove,
}) {
  const styles = useStyles();
  const [showLoading, setShowLoading] = useState(false);
  const layoutConstanst = buildLayoutConstants(requiredFields);

  const loading = useMemo(() => showLoading, [showLoading]);

  const showTrashHandler = () => !disabled && hasTrash;

  const getUpdatedEndereco = useCallback((endereco) => {
    const newEndereco = { ...model };
    newEndereco.endereco.value = getAttributeExistingObject(endereco, 'logradouro');
    newEndereco.cidade.value = getAttributeExistingObject(endereco, 'cidade');
    newEndereco.bairro.value = getAttributeExistingObject(endereco, 'bairro');
    newEndereco.estado.value = getAttributeExistingObject(endereco, 'estado');
    newEndereco.estado.error = false;
    newEndereco.endereco.error = false;
    return model;
  }, [model]);

  const changeAllFieldBasedAtCepRequestHandler = useCallback(() => {
    if (disabled) {
      return;
    }
    setShowLoading(true);
    getCorreiosCep(model.cep.value).then((response) => {
      const endereco = getUpdatedEndereco(response.data);
      setEndereco(endereco);
      setShowLoading(false);
    }).catch(() => {
      const endereco = getUpdatedEndereco();
      setEndereco(endereco, index);
      setShowLoading(false);
    });
  }, [setEndereco, setShowLoading, getUpdatedEndereco, model.cep, disabled, index]);

  const changeAttributeHandler = useCallback((event) => {
    const { name, value } = event.target;
    setAttribute(name, value, index);
  }, [setAttribute, index]);

  const focusAttributeHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.autoComplete) {
      event.target.autoComplete = 'whatever';
    }
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    setFocus(fieldName, index);
  }, [setFocus, index]);

  const choosePrincipalHandler = useCallback((event) => {
    const { name } = event.target;
    const enderecos = enderecosWithPrincipalFalse();
    enderecos[index][name].onChange(true);
    setEnderecos(enderecos);
  }, [setEnderecos, enderecosWithPrincipalFalse, index]);

  return (
    <Grid24 container spacing="1">
      <Grid24 item xs={24} sm={19}>
        <Grid24 item container isFormContainer spacing="1">
          <Grid24 item xs={24} sm={3}>
            <MaskField
              isForm
              fullWidth
              disabled={disabled}
              label={layoutConstanst.CEP}
              name={model.cep.name}
              value={model.cep.value}
              error={model.cep.error}
              errorMessage={model.cep.errorMessage}
              loading={loading}
              onBlur={changeAllFieldBasedAtCepRequestHandler}
              onChange={changeAttributeHandler}
              format={CEP_MASK}
              onFocus={focusAttributeHandler}
            />
          </Grid24>
          <Grid24 item xs={24} sm={14}>
            <InputField
              isForm
              fullWidth
              disabled={disabled}
              label={layoutConstanst.ENDERECO}
              error={model.endereco.error}
              name={model.endereco.name}
              type="text"
              value={model.endereco.value}
              errorMessage={model.endereco.errorMessage}
              maxLength={model.endereco.maxLength}
              onChange={changeAttributeHandler}
              onFocus={focusAttributeHandler}
            />
          </Grid24>
          <Grid24 item xs={24} sm={2}>
            <InputField
              isForm
              fullWidth
              disabled={disabled}
              label={layoutConstanst.NUMERO}
              name={model.numero.name}
              value={model.numero.value}
              error={model.numero.error}
              errorMessage={model.numero.errorMessage}
              maxLength={model.numero.maxLength}
              onChange={changeAttributeHandler}
              onFocus={focusAttributeHandler}
            />
          </Grid24>
          <Grid24 item xs={24} sm={5}>
            <InputField
              isForm
              fullWidth
              disabled={disabled}
              label={layoutConstanst.COMPLEMENTO}
              name={model.complemento.name}
              value={model.complemento.value}
              error={model.complemento.error}
              errorMessage={model.complemento.errorMessage}
              maxLength={model.complemento.maxLength}
              onChange={changeAttributeHandler}
              onFocus={focusAttributeHandler}
            />
          </Grid24>
        </Grid24>
        <Grid24 container isFormContainer spacing="1">
          <Grid24 item xs={24} sm={15}>
            <InputField
              isForm
              fullWidth
              disabled={disabled}
              label={layoutConstanst.BAIRRO}
              name={model.bairro.name}
              value={model.bairro.value}
              error={model.bairro.error}
              errorMessage={model.bairro.errorMessage}
              maxLength={model.bairro.maxLength}
              onChange={changeAttributeHandler}
              onFocus={focusAttributeHandler}
            />
          </Grid24>
          <Grid24 item xs={24} sm={7}>
            <InputField
              isForm
              fullWidth
              disabled={disabled}
              label={layoutConstanst.CIDADE}
              name={model.cidade.name}
              value={model.cidade.value}
              error={model.cidade.error}
              errorMessage={model.cidade.errorMessage}
              maxLength={model.cidade.maxLength}
              onChange={changeAttributeHandler}
              onFocus={focusAttributeHandler}
            />
          </Grid24>
          <Grid24 item xs={24} sm={2}>
            <SelectorField
              isForm
              fullWidth
              disabled={disabled}
              hasEmptyLabel
              label={layoutConstanst.ESTADO}
              items={estados}
              name={model.estado.name}
              value={model.estado.value}
              error={model.estado.error}
              errorMessage={model.estado.errorMessage}
              onChange={changeAttributeHandler}
            />
          </Grid24>
        </Grid24>
      </Grid24>
      <Grid24
        item
        container
        xs={24}
        sm={5}
        alignContent="flex-start"
        className={
          cx({ [styles.actions]: !showTrashHandler(), [styles.actionWithRemoveButton]: showTrashHandler() })
        }
      >
        {hasPrincipal && (
          <Grid24 item xs={12} sm={12}>
            <CheckboxField
              label={layoutConstanst.PRINCIPAL}
              name={model.principal.name}
              disabled={disabled}
              checked={model.principal.value}
              onChange={choosePrincipalHandler}
            />
          </Grid24>
        )}
        {showTrashHandler() && (
          <Grid24 item xs={12} sm={12}>
            <IconButtonTooltip
              color="primary"
              type="trash"
              hint={layoutConstanst.TOOLTIP_TRASH}
              disabled={disabled}
              onClick={onRemove}
            />
          </Grid24>
        )}
      </Grid24>
    </Grid24>
  );
}

export default React.memo(Endereco);
