import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  trashButton: {
    color: 'black',
  },
  addButton: {
    color: '#034ddc',
  },
  searchPlus: {
    color: 'black',
  },
  calendarAlt: {
    color: '#43A047',
  },
  editText: {
    color: 'black',
  },
});
