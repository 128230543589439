import moment from 'moment';
import ChangeAttr from './ChangeAttrModel';

export const POSITIVE_INTEGER = 'positiveInteger';
export const DATE = 'date';
export const MONEY = 'money';

export function getValudWithPositiveInteger(value) {
  return value.match(/[0-9]*/g).join('');
}


export default class FieldModel {
  static newInstance(value) {
    return new FieldModel({ value });
  }

  constructor(
    args = {
      name: '',
      value: '',
      error: false,
      errorMessage: '',
      disable: false,
      maxLength: 1,
      changeAttr: new ChangeAttr(),
    },
  ) {
    let newValue = args.value || '';

    if (typeof args.value === 'boolean') {
      newValue = args.value || false;
    } else if (typeof args.value === 'number') {
      newValue = args.value || 0;
    }

    this.name = args.name || '';
    this.disabled = args.disabled || false;
    this.value = newValue;
    this.error = args.error || false;
    this.type = args.type;
    this.required = args.required || false;
    this.errorMessage = args.errorMessage || '';
    this.maxLength = args.maxLength || 150;
    this.changeAttr = args.changeAttr;
  }

  onChange(value) {
    this.value = value;
    if (this.type === POSITIVE_INTEGER) {
      this.value = getValudWithPositiveInteger(this.value);
    } else if (this.type === MONEY && value === '') {
      this.value = 0;
    }
    /* @Decrapted */
    if (this.changeAttr) {
      this.value = this.changeAttr.getValueWithMaxLengthFilter(this.value);
      this.value = this.changeAttr.getValudWithPositiveInteger(this.value);
    }
    return this;
  }

  onFocus() {
    this.error = false;
    this.errorMessage = '';
    return this;
  }

  isNumber() {
    return !Number.isNaN(parseFloat(this.value));
  }

  isBoolean() {
    return typeof this.value === 'boolean';
  }

  getStringValue() {
    return this.value.trim();
  }

  isFilledField() {
    return this.getStringValue().length > 0;
  }

  getValue() {
    if (this.isNumber() || this.isBoolean()) {
      return this.value;
    }

    if (Array.isArray(this.value)) {
      return (this.value) ? this.value : undefined;
    }

    return this.getStringValue();
  }


  getValueNotEmpty() {
    const value = this.getValue();
    if (value === '') {
      return undefined;
    }
    return value;
  }

  getNewModel(value) {
    const newModel = this.copy();
    return newModel.onChange(value);
  }

  getClearModel(type = 'text') {
    const newModel = this.copy();
    newModel.value = '';
    if (type === 'boolean') {
      newModel.value = false;
    }
    newModel.error = false;
    return newModel;
  }

  getStringDate() {
    if (this.value === '') {
      return undefined;
    }
    if (this.value.replace(/[ ]/g, '').length < 10) {
      return this.value;
    }
    return this.value.split('/').reverse().join('-');
  }

  getDateTime() {
    const date = moment(this.value);
    if (date.isValid()) {
      return date.format('DD/MM/YYYY HH:mm');
    }
    return '__/__/____ --:--';
  }

  copy() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  getSerializeDate() {
    const value = this.getValueNotEmpty();
    if (!value) {
      return undefined;
    }
    const splitedValue = value.split('/');
    if (splitedValue.length !== 3) {
      return value;
    }
    return `${splitedValue[2]}-${splitedValue[1]}-${splitedValue[0]}`;
  }

  isValidRequired() {
    if (this.required
      && (this.value !== undefined)
      && (this.value !== null)
      && String(this.value).trim().length === 0) {
      this.error = true;
      return;
    }
    this.error = false;
  }

  updateErrors(message) {
    const model = this.copy();
    model.errorMessage = (message && message) || '';
    model.error = !!message;
    return model;
  }
}
