import makeStyles from '@material-ui/core/styles/makeStyles';


export default makeStyles({
  title: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#000000',
  },
  titleBottom: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#000000',
    marginTop: '16px',
  },
});
