import styled from 'styled-components';
import { Paper } from '@material-ui/core';


export const TableWithAccordion = styled(Paper)`
  background-color: #f1f4f9;
  border-radius: 7px;
  margin-top: 22px;
  max-height: ${props => (props.isScrollable ? props.maxTableHeigth : 'auto')};
  overflow-x: auto;

  & td {
    font-size: .75rem;
    padding: 0px 8px !important;
  }

  & th {
    cursor: default;
    font-size: .75rem;
    color: #034ddc;
    font-weight: bold;
    padding: 0px 8px !important;
    height: 33px;
    text-align: center;
    line-height: 1rem;
  }

`;


export const Subtitles = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  width: 100%;
`;

export const SubtitlesValue = styled.div`
  width: ${props => (props.width)};
  text-align: ${props => (props.textAlign || 'center')};
`;

export const ListSeparator = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e0e0e0;
  padding: 6px;
  width: 100%;
`;
