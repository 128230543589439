const EMPTY_CHARACTERS_EDITOR_TEXTO = /<p>|<\/p>|<br>|<strong>|<\/strong>|<em>|<\/em>|<u>|<\/u>|<blockquote>|<\/blockquote>|<ol>|<\/ol>|<li>|<\/li>|<ul>|<\/ul>|<span class="ql-cursor">|<\/span>/g;


export function getErrorInModel(model) {
  return model && model.error;
}

function checkInvalidCharacters(value) {
  if (value) {
    const valueWithoutTags = value.replace(EMPTY_CHARACTERS_EDITOR_TEXTO, '');
    return valueWithoutTags.trim().length === 0;
  }
  return false;
}

export function buildEmptyEditorTexto(model) {
  return checkInvalidCharacters(model.value) ? '' : model.value;
}

export function isErrorOnEditorTexto(validations, fieldName) {
  if (validations.length > 1) {
    return validations[0].field === fieldName || validations[1].field === fieldName;
  }
  return validations[0].field === fieldName;
}


export const DefaultModules = [
  ['bold', 'italic', 'underline', 'blockquote'],
  [
    { list: 'ordered' },
    { list: 'bullet' },
  ],
];

export const DefaultFormats = ['bold', 'italic', 'underline', 'blockquote', 'list', 'bullet'];

export const AllFormats = [
  'bold', 'italic', 'underline',
  'blockquote', 'script', 'list',
  'bullet', 'image', 'background',
  'color', 'code-block', 'font', 'size',
  'header', 'indent', 'indent', 'direction', 'align', 'link', 'video',
];

export const AllModules = [
  [{ font: [] }],
  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [{ color: [] }], // dropdown with defaults from theme
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ header: 1 }, { header: 2 }],
  ['blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction
  [{ align: [] }],
  ['link', 'image', 'video'],

  ['clean'],
];
