import React from 'react';
import AddActionTitleWrapper from '../Wrapper/AddActionTitle';
import useStyles from './FaixasDeJurosEhRepasse.styles';
import { TITLE_EDIT, TITLE_READ, TOOLTIP } from './FaixasDeJurosEhRepasse.constants';
import FaixaDeJurosEhRepasse from './FaixaDeJuros/FaixaDeJurosEhRepasse';
import { buildFaixaDeJurosEhRepasse } from '../../../helpers/factory/faixasDeJuros.factory';
import { checkArrayIsRequired, getListIntervalCorrectAfterDeleteQuantidade, getNextValueToRange } from '../../../helpers/utils/utils';

function FaixasDeJurosEhRepasse({
  faixasDeJuros = [],
  disabled = false,
  readMode = false,
  setFaixaDeJuros,
  setFocus,
  setAttribute,
}) {
  const classes = useStyles();

  const addFaixaDeJurosHandler = () => {
    const allFaixasDeJuros = [...faixasDeJuros];
    const newFaixaDeJuros = buildFaixaDeJurosEhRepasse();
    newFaixaDeJuros.quantidadeDiasInicial.value = getNextValueToRange(
      allFaixasDeJuros,
      'quantidadeDiasFinal',
      999999,
      1,
    );
    newFaixaDeJuros.quantidadeDiasFinal.value = 999999;
    newFaixaDeJuros.porcentagemJuros.value = 0;
    newFaixaDeJuros.porcentagemJurosRepasse.value = 0;

    const isValidArray = checkArrayIsRequired(allFaixasDeJuros);
    if (isValidArray) {
      allFaixasDeJuros.push(newFaixaDeJuros);
    }

    setFaixaDeJuros(allFaixasDeJuros);
  };

  const removeFaixaDeJurosEhRepasseHandler = (index) => {
    const allFaixasDeJuros = [...faixasDeJuros];
    allFaixasDeJuros.splice(index, 1);
    const listaFaixaJuros = getListIntervalCorrectAfterDeleteQuantidade(allFaixasDeJuros, 'quantidadeDiasFinal', 'quantidadeDiasInicial', index, 999999);
    setFaixaDeJuros(listaFaixaJuros);
  };

  const generateDividerLine = index => ((index !== faixasDeJuros.length - 1)
    && (<hr className={classes.divider} />)
  );

  const buildFaixasDeJuros = (faixaDeJuros, index) => (
    <div key={index}>
      <FaixaDeJurosEhRepasse
        setFocus={setFocus}
        setAttribute={setAttribute}
        disabled={disabled}
        index={index}
        model={faixaDeJuros}
        onRemove={() => removeFaixaDeJurosEhRepasseHandler(index)}
      />
      {generateDividerLine(index)}
    </div>
  );

  return (
    <AddActionTitleWrapper
      title={TITLE_EDIT}
      readTitle={TITLE_READ}
      disabled={disabled}
      addHandler={addFaixaDeJurosHandler}
      tooltip={TOOLTIP}
      readMode={readMode}
    >
      <div className={classes.wrapper}>
        {faixasDeJuros.map(buildFaixasDeJuros)}
      </div>
    </AddActionTitleWrapper>
  );
}

export default FaixasDeJurosEhRepasse;
