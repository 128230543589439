import makeStyles from '@material-ui/styles/makeStyles';
import { blackTwo, blue, warmGreyTwo } from '../../../helpers/constants/colors.constants';

export default makeStyles({
  div__root: {
    display: 'flex',
    width: '100%',
  },
  div__calculo: {
    width: '24%',
    maxHeight: '348px',
    marginRight: '10px',
    padding: '10px',
    border: `1px solid ${warmGreyTwo}`,
  },
  div__resumo: {
    width: '76%',
    maxHeight: '348px',
    padding: '10px',
    border: `1px solid ${warmGreyTwo}`,
  },
  div__subTitleProcesso: {
    textAlign: 'center',
    margin: '0 0 8px 0',
  },
  div__lineInfo: {
    display: 'flex',
    marginBottom: '6px',
    fontSize: '12px',
  },
  info__name: {
    width: '44%',
  },
  info__value: {
    display: 'flex',
    width: '56%',
    justifyContent: 'flex-end',
  },
  span__space: {
    paddingLeft: '4px',
  },

  div__boxMain: {
    display: 'flex',
    width: '100%',
  },

  blueBold__title: {
    color: blue,
    fontSize: '14px',
    fontWeight: 'bold',
  },

  blackBold__title: {
    color: blackTwo,
    fontSize: '14px',
    fontWeight: 'bold',
  },

  buttonEndossante: {
    padding: 0,
    borderRadius: '4px',
    border: '1px solid #564872',
    color: '#564872',
    minWidth: '34px',
    width: '49px',
    height: '36px',
  },
  cancelIcon: {
    width: '36px',
    height: '36px',
    '&:hover': {
      width: '36px',
      height: '36px',
      backgroundColor: '#fbd0d2',
      color: '#ff000f',
    },
  },
  confirmarIcon: {
    width: '36px',
    height: '36px',
    '&:hover': {
      width: '36px',
      height: '36px',
      backgroundColor: '#caf1cc',
      color: '#43a047',
    },
  },
});
