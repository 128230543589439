
import makeStyles from '@material-ui/core/styles/makeStyles';
import styled from 'styled-components';
import { Button } from '@material-ui/core';


export default makeStyles({
  root: {
    display: ' flex',
    marginLeft: '10px',
    marginTop: '12px',
    marginBottom: '19px',
  },
  errorMessage: {
    color: 'red', fontSize: '12px', paddingTop: '17px',
  },

  margin: {
    marginTop: '-33px',

  },

  buttonEditar: {
    display: 'flex',
    width: '135px',
  },

  buttonFooter: {
    display: 'flex',
    width: '100%',
    marginTop: '16px',
    justifyContent: 'flex-end',
  },

  buttonSalvar: {
    width: '157px',
    marginRight: '12px',
  },

  buttonCancelar: {
    width: '103px',
    marginRight: '11px',
  },
});

export const BotaoWebService = styled(Button)`
  color: #564872;
  border: 1px solid #564872;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  width: 220px;
  height: 26px;
  text-transform: none;
  min-width: 220px;

  span {
    margin-left: 4px;
  }
`;
