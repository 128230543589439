import FieldModel from '../../../models/FieldModel';
import ChangeAttr from '../../../models/ChangeAttrModel';

const ON_CLEAR_ERROR_FOCUS_RAMO_ATIVIDADE = 'ON_CLEAR_ERROR_FOCUS_RAMO_ATIVIDADE';
const ON_CHANGE_NOME_RAMO_ATIVIDADE = 'ON_CHANGE_NOME_RAMO_ATIVIDADE';
const ON_LOADING_ACTION_CRUD_RAMO_ATIVIDADE = 'ON_LOADING_ACTION_CRUD_RAMO_ATIVIDADE';
const ON_OPEN_EDIT_DIALOG_ERROR_RAMO_ATIVIDADE = 'ON_OPEN_EDIT_DIALOG_ERROR_RAMO_ATIVIDADE';
const ON_ERROR_NOME_RAMO_ATIVIDADE = 'ON_ERROR_NOME_RAMO_ATIVIDADE';
const CLEAR_CONTROLS = 'CLEAR_CONTROLS_RAMO_ATIVIDADE';

function buildConstrols() {
  return {
    nomeRamoAtividade: new FieldModel({
      name: 'nomeRamoAtividade',
      changeAttr: new ChangeAttr({ maxLength: 60 }),
    }),
  };
}

function buildIniitialStates() {
  return {
    controls: buildConstrols(),
    actions: {
      loading: false,
      openDialogErrorEdit: false,
      contentEditDialogError: '',
    },
  };
}

export const onLoadingCrudAction = value => ({
  type: ON_LOADING_ACTION_CRUD_RAMO_ATIVIDADE,
  value,
});

export const onErrorNomeRamoAction = ramoAtividade => ({
  type: ON_ERROR_NOME_RAMO_ATIVIDADE,
  ramoAtividade,
});

export const onOpenEditDialogError = (open, messageError = '') => ({
  type: ON_OPEN_EDIT_DIALOG_ERROR_RAMO_ATIVIDADE,
  open,
  messageError,
});

export const onClearErrorFocusAction = () => ({
  type: ON_CLEAR_ERROR_FOCUS_RAMO_ATIVIDADE,
});

export const onChangeNomeRamoAtividadeAction = nomeRamoAtividade => ({
  type: ON_CHANGE_NOME_RAMO_ATIVIDADE,
  nomeRamoAtividade,
});

export const clearControlsAction = () => ({
  type: CLEAR_CONTROLS,
});

function onErrorNomeRamoHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      ...action.ramoAtividade,
    },
  };
}

function onClearErrorFocusHandler(states) {
  const nomeRamoCopied = states.controls.nomeRamoAtividade.copy();
  nomeRamoCopied.error = false;

  return {
    ...states,
    controls: {
      ...states.controls,
      nomeRamoAtividade: nomeRamoCopied,
    },
  };
}


function onOpenEditDialogErrorHandler(states, action) {
  return {
    ...states,
    actions: {
      ...states.actions,
      openDialogErrorEdit: action.open,
      contentEditDialogError: action.messageError,
    },
  };
}

function onChangeNomeRamoAtividadeHandler(states, action) {
  const nomeRamoAtividadeChange = states.controls.nomeRamoAtividade.getNewModel(action.nomeRamoAtividade);

  return {
    ...states,
    controls: {
      ...states.controls,
      nomeRamoAtividade: nomeRamoAtividadeChange,
    },
  };
}

function onLoadingCrudHandler(states, action) {
  return {
    ...states,
    actions: {
      ...states.actions,
      loading: action.value,
    },
  };
}

function clearControlsHandler(states) {
  return {
    ...states,
    controls: buildConstrols(),
  };
}

export default (states = buildIniitialStates(), action) => {
  switch (action.type) {
    case ON_LOADING_ACTION_CRUD_RAMO_ATIVIDADE:
      return onLoadingCrudHandler(states, action);
    case ON_CHANGE_NOME_RAMO_ATIVIDADE:
      return onChangeNomeRamoAtividadeHandler(states, action);
    case ON_OPEN_EDIT_DIALOG_ERROR_RAMO_ATIVIDADE:
      return onOpenEditDialogErrorHandler(states, action);
    case ON_CLEAR_ERROR_FOCUS_RAMO_ATIVIDADE:
      return onClearErrorFocusHandler(states);
    case ON_ERROR_NOME_RAMO_ATIVIDADE:
      return onErrorNomeRamoHandler(states, action);
    case CLEAR_CONTROLS:
      return clearControlsHandler(states);
    default:
      return states;
  }
};
