import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  div__icons: {
    width: '80px',
    display: 'flex',
    justifyContent: 'space-around',
    alignContent: 'center',
    '& i': {
      fontSize: '16px',
    },
  },
});
