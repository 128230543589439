import FieldModel from '../../../../models/FieldModel';
import { alterarParcelaCheque } from '../../../../services/core/processos/processosAcordosService';

class DialogAlterarParcelaChequeController {
  makeFormFields(dadosParcelaCheque) {
    return ({
      observacaoCheque: new FieldModel({
        name: 'observacaoCheque',
        value: dadosParcelaCheque.observacao,
      }),
    });
  }


  makePayload(idProcesso, nroParcela, idAcordo, idAcordoParcela, tipoFormPgtp, formFields) {
    return {
      observacao: formFields.observacaoCheque.value,
      idProcesso,
      nroParcela,
      idAcordo,
      idAcordoParcela,
      tipoFormPgtp,
    };
  }

  async salvar(idProcesso, nroParcela, idAcordo, idAcordoParcela, tipoFormPgtp, formFields) {
    const payload = this.makePayload(idProcesso, nroParcela, idAcordo, idAcordoParcela, tipoFormPgtp, formFields);
    await alterarParcelaCheque(payload);
  }
}

export const dialogAlterarParcelaChequeController = new DialogAlterarParcelaChequeController();
