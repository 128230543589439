import { Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import styled, { css } from 'styled-components';
import { FaLock } from 'react-icons/fa';
import { TableActionButton } from '../../../../../../components/UI/Tabela/tableDefaultStyles';

export default makeStyles({

  div__header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '16px',
  },

  div__nomeCliente: {
    width: '100%',
    textAlign: '-webkit-center',
  },

  div__root: {
    width: '100%',
  },

  div__table: {
    marginTop: '25px',
  },

  div__switch: {
    marginTop: '-45px',
  },

  dialog: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '598px',
      maxHeight: '685px',
    },
  },
});

const redIconStyleFaLock = css`
  color: red;
  font-size: 16px;
`;

export const FaLockIcon = styled(FaLock)`
  ${redIconStyleFaLock}
`;

export const CustomTableActionButtomNaoPrestados = styled(TableActionButton)`
  ${props => props.iscustom && css`
    &.Mui-disabled {
      opacity: 0.32;
    }
  `}
`;

export const ButtonImprimir = styled(Button)`
  display: flex;
  justify-content: space-between;
  margin-left: 12px;

  &.MuiButton-root {
    width: 73px;
    padding: 4px 0;
    font-weight: bold;
    text-transform: none;
    color: #000000;
    font-size:12px ;

    &.Mui-disabled {
      opacity: 0.64;
    }
  }
`;
export const HeaderCliente = styled.div`
  border: 1px solid #a2a0a0;
  padding: 7px 200px;
  margin: 0px 54px 0px 68px;
`;

export const ButtonNovo = styled(Button)`
  display: flex;
  align-items: center;

  border-radius: 4px;
  border: 1px solid #564872;
  color: #564872;
  font-weight: bold;

  width: 89px;
  height: 26px;

  text-transform: none;

  :disabled {
    border: 1px solid #A2A0A0;
    color: #A2A0A0;
  }

  & .MuiButton-label span {
    margin-left: 8px;
  }
`;

export const ActionContainer = styled.section`
  padding-top: 37px;
`;

export const ActionButtonGroup = styled.div`
  display: flex;

  > div {
    margin-right: 8px;
  }
`;

export const ActionButton = styled(IconButton)`
width: 36px;
height: 36px;
padding: 8px;

background-color: ${({ active }) => (active === 'true' ? 'transparent' : '#423362')};
color: ${({ active }) => (active === 'true' ? '#423362' : 'white')};
${({ active }) => (active === 'true' ? 'border: 2px solid #423362;' : '')}

:hover {
  background-color: ${({ active }) => (active === 'true' ? 'transparent' : '#423362')};
  opacity: 0.64;
}

:disabled {
  background-color: #c4c4c4;
}
`;

export const ActionContentContainer = styled.section`
  margin-top: 20px;
  padding: 8px;
  border :1px solid #A2A0A0;
`;

export const ActionHeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
`;
