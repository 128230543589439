import React from 'react';
import { useDispatch } from 'react-redux';
import withTokenInterceptor from '../../hoc/withTokenInterceptor/withTokenInterceptor';
import { initializePageAction } from '../../store/theme.actions';
import RecebimentoDevedoresProvider from '../../store/RecebimentoDevedores/RecebimentoDevedoresProvider';
import { RecebimentoDevedores } from './RecebimentoDevedores';


function RecebimentoDevedoresPage() {
  const dispatch = useDispatch();

  dispatch(initializePageAction('Recebimento de Devedores'));
  return (
    <RecebimentoDevedoresProvider>
      <RecebimentoDevedores />
    </RecebimentoDevedoresProvider>
  );
}

export default withTokenInterceptor(RecebimentoDevedoresPage);
