import { getUriParams } from '../../../helpers/utils/utils';
import {
  IMPORTACAO, gateway, RELATORIOS,
} from '../../api';


export function getStatusBorderoPesquisa() {
  return gateway.get(`${IMPORTACAO}/bordero-importar/status-bordero`);
}

export function buscarSequenceBorderoLote() {
  return gateway.get(`${IMPORTACAO}/bordero-importar/bordero-lote`);
}

export function buscarBorderoImportarPorStatus(idStatus, dataImportacao, page, size) {
  const params = getUriParams({
    idStatus, dataImportacao, page, size,
  });

  return gateway.get(`${IMPORTACAO}/bordero-importar?${params}`);
}

export function salvarArquivoBordero(arquivo, lastModiefiedDate, lote, idUsuario) {
  const data = new FormData();
  data.append('conteudoBordero', arquivo);
  data.append('loteBordero', lote);
  data.append('dataModificacao', lastModiefiedDate);
  data.append('idUsuario', idUsuario);

  return gateway.post(`${IMPORTACAO}/bordero-importar`, data, {
    headers: {
      Accept: 'application/async+json',
    },
  });
}

export function gerarRelatorio(idArquivoBordero) {
  return gateway.get(`${RELATORIOS}/arquivos-bordero/${idArquivoBordero}`, {
    responseType: 'blob',
  });
}
