import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Button, DialogTitle, Typography } from '@mui/material';
import { CONFIRM } from '../../../../helpers/constants/global.constants';
import useStyles from './OptionDialog.styles';


function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}


function OptionDialogNew({
  children,
  onClickConfirm,
  onClickCancel,
  disableCancel = false,
  disableConfirm = false,
  confirmLabel = CONFIRM,
  cancelLabel,
  variant,
  text,
  ...props
}) {
  const styles = useStyles();

  return (
    <Dialog
      classes={{ root: styles.rootNew }}
      fullWidth
      {...props}
      aria-labelledby="draggable-dialog-title"
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
        <Typography align="center">
          <div style={{ fontSize: '16px', marginTop: '10px' }}>
            {text}
          </div>

          {children}
        </Typography>
      </DialogTitle>
      <DialogContent className={styles.rootNew} id="draggable-dialog-title">
        <div style={{ display: 'flex', alignSelf: 'end' }}>

          {cancelLabel
            ? (
              <Button
                onClick={onClickCancel}
                disabled={disableCancel}
              >
                {cancelLabel}
              </Button>
            )
            : (
              <div> </div>
            )
          }
          <Button
            onClick={onClickConfirm}
            disabled={disableConfirm}
          >
            {confirmLabel}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default OptionDialogNew;
