
class DialogEnviarEmailDevedorController {
  makeFormFields(values) {
    return {
      emailDe: values?.emailDe || '',
      emailPara: values?.emailPara || '',
      emailCopia: values?.emailCopia || '',
      respostaPara: values?.respostaPara || '',
      assunto: values?.assunto || '',
      nomeModelo: '',
      anexos: values?.anexos || [],
      texto: values?.texto || '',
      nomeAdvogado: values?.nomeAdvogado || '',
      emailAdvogado: values?.emailAdvogado || '',
      idTipoEmailPadrao: values?.idTipoEmailPadrao || '',
      solicitarFase: values?.solicitarFase || '',
    };
  }

  makeErrorFields() {
    return {
      emailDe: {
        error: false,
        errorMessage: '',
      },
      emailPara: {
        error: false,
        errorMessage: '',
      },
      emailCopia: {
        error: false,
        errorMessage: '',
      },
      respostaPara: {
        error: false,
        errorMessage: '',
      },
      assunto: {
        error: false,
        errorMessage: '',
      },
      idTipoEmailPadrao: {
        error: false,
        errorMessage: '',
      },
      solicitarFase: {
        error: false,
        errorMessage: '',
      },
      nomeAdvogado: {
        error: false,
        errorMessage: '',
      },
      emailAdvogado: {
        error: false,
        errorMessage: '',
      },
      anexos: {
        error: false,
        errorMessage: '',
      },
      texto: {
        error: false,
        errorMessage: '',
      },
    };
  }
}


export const dialogEnviarEmailDevedorController = new DialogEnviarEmailDevedorController();
