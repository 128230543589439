import React, { useRef, useState, useEffect } from 'react';
import { Button } from '@material-ui/core';

import { toast } from 'react-toastify';
import InputField from '../Field/Input';
import { ButtonContainer, InputFileContainer } from './inputFileStyles';

function InputFileComponent({
  fileSize = 10485760,
  fileSizeLabel = '10MB',
  name,
  labelName,
  accept = '',
  acceptAll = false,
  disabled = false,
  keepButtonDisabled = false,
  textExtensions = '',
  value,
  onChange,
  clearFile = false,
  variant = 'outlined',
  contentButtonText = 'Selecionar',
  setFile,
  ...props
}) {
  const fileRef = useRef();
  const acceptValidate = accept?.split(',');

  const [fileName, setFileName] = useState(value);

  function handleClickFileSelector() {
    fileRef.current.click();
  }


  useEffect(() => {
    if (clearFile) {
      fileRef.current.value = null;
    }
  }, [clearFile]);


  function handleChangeFile(e) {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];
    if (setFile) {
      setFile(file);
    }

    reader.onloadend = () => {
      if (!acceptAll) {
        if (!acceptValidate.includes(file.type)) {
          toast.error('O arquivo selecionado possui formato inválido', { style: { width: '392px' } });
          return;
        }
      }


      if (file?.size > fileSize) {
        toast.error(
          `O arquivo selecionado excedeu o tamanho máximo de ${fileSizeLabel}`, { style: { width: '392px' } },
        );
        return;
      }
      if (props.onFocus) {
        props.onFocus(e);
      }
      setFileName(file?.name);
      onChange({ target: { name: labelName, value: file?.name } });
    };

    if (file instanceof Blob) {
      reader.readAsDataURL(file);
    }
  }

  /**
   * Manter atualizado o 'state' value para controle interno
   */
  useEffect(() => {
    setFileName(value);
  }, [value]);

  return (
    <InputFileContainer>
      <InputField
        {...props}
        name={labelName}
        fullWidth
        disabled
        value={fileName}
      />
      {(!disabled || keepButtonDisabled) && (
        <ButtonContainer hasTextExtensions={!!textExtensions}>
          <Button
            disabled={disabled}
            variant={variant}
            type="button"
            onClick={handleClickFileSelector}
          >
            {contentButtonText}
          </Button>
          <span>
            {textExtensions}
          </span>
        </ButtonContainer>
      )}
      <input accept={accept} name={name} type="file" onChange={handleChangeFile} ref={fileRef} />
    </InputFileContainer>
  );
}

export const InputFile = InputFileComponent;
