import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const OulinedButtonContainer = styled(Button)`
  &.MuiButton-outlined {
    height: 30px;

    border-color: #034DDC;
    border-radius: 15px;
    color: #034DDC;
    text-transform: none;
    font-size: 14px;
    line-height: 1;
  }
`;
