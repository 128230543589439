import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { v1 as uuidv1 } from 'uuid';

import {
  onClearAcompanhamentosAction,
  onClearCombos,
  onReloadCombos,
  onUpdateAllForm,
  onUpdateControls,
  onUpdateEmpresasGrupo,
  openDialogAction,
  openOptionalDialogAction,
  setAcompanhamentoPageableAction,
  setAcompanhamentosAction,
  setAttributesAcompanhamentoAction,
  setErrorCodPrincipal,
  setHasErrorInValidateFormAction,
  setImageAction,
  setLoadingAction,
  setLoadingEmpresasGurpoAction,
  setRequestFieldsAcompanhamentoValueAction,
  setStatusPromisseCodEmpresa,
  setStoreValueAction,
  onChangeActions,
  setSimpleValuesClienteAction,
  onChangeControlsNewEmpresaClienteNotaFiscalAction,
  setControlsAttributesAction,
  onChangeControls,
  onChangeIdsTiposUsuariosAction,
} from './crudCliente.store';
import {
  getAcompanhamentoCliente,
  getCliente,
  getClienteEmpresaNFService,
  getClientesGrupo,
  getDiasElegiveisService,
  getFaixasComissao,
  insertAcompanhamentoCliente,
  insertCliente,
  obterLogotipoCliente, removerCliente,
  saveLogotipoCliente,
  updateCliente,
  gerarChaveAutenticacaoCliente,
  enviarEmailChaveAutenticacaoCliente,
  deletarChaveAutenticacaoCliente,
} from '../../../../services/core/cliente/cliente.services';
import {
  deletePossivelCliente,
  getAcompanhamentoPossivelCliente,
  getPossivelCliente,
  insertAcompanhamentoPossivelCliente,
  insertPossivelCliente,
  migrarPossivelCliente,
  obterLogotipoPossivelCliente,
  saveLogotipoPossivelCliente,
  updatePossivelCliente,
} from '../../../../services/core/possivelCliente/possivelCliente.services';
import {
  buildPayload,
  buildPayloadArray,
  getExceptionErrorMessage,
  getExceptionMessage,
  isBadRequestException,
  isConflictException,
  isNotFoundException,
} from '../../../../helpers/utils/utils';

import { buildEnderecoPayload } from '../../../../helpers/factory/endereco.factory';
import {
  getErrorMessages,
  getExceptionHandler,
  isNotExceptionValidations,
} from '../../../../helpers/utils/exception.util';
import FieldModel from '../../../../models/FieldModel';
import { buildDefaultClienteTipoCalculoComissaoParametro } from '../../../../helpers/factory/cliente.factory';
import { buscarIdTipoUsuarioComercial, buscarIdTipoUsuarioTelemarketing } from '../../../../services/core/usuario/usuario.services';
import { buscarIdFilialPorIdUsuarioPrincipal } from '../../../../services/core/filial/filial.services';

const MESSAGES = {
  INSERT_CLIENTE_SUCESS: 'Cliente cadastrado com sucesso!',
  INSERT_POSSIVEL_CLIENTE_SUCESS: 'Possível Cliente cadastrado com sucesso!',
  OPTIONAL_DIALOG_MESSAGE_INFORMATION: 'As informações na tela serão atualizadas com os dados da empresa selecionada. '
    + 'Deseja salvar  as alterações antes de prosseguir?',
  ERROR_UPDATE_MESSAGE: 'Revise as informações e preencha o cadastro corretamente',
};

const GET_CLIENTE_BY_ID = 'GET_CLIENTE_BY_ID';
const INSERT_CLIENTE = 'INSERT_CLIENTE';
const UPDATE_CLIENTE = 'UPDATE_CLIENTE';
const FIND_EMPRESAS_GRUPO = 'FIND_EMPRESAS_GRUPO';
const MIGRAR_POSSIVEL_CLIENTE = 'MIGRAR_POSSIVEL_CLIENTE';
const FIND_ACOMPANHAMENTO_CLIENTE = 'FIND_ACOMPANHAMENTO_CLIENTE';
const INSERT_ACOMPANHAMENTO_CLIENTE = 'INSERT_ACOMPANHAMENTO_CLIENTE_CRUD_CLIENTE';
const GET_CLIENTE_EMPRESA_NF = 'GET_CLIENTE_EMPRESA_NF';
const GET_FAIXA_COMISSAO = 'GET_FAIXA_COMISSAO';
const GERAR_CHAVE_AUTENTICAÇAO_CLIENTE = 'GERAR_CHAVE_AUTENTICACAO_CLIENTE';
const ENVIAR_EMAIL_CHAVE_AUTENTICACAO_CLIENTE = 'ENVIAR_EMAIL_CHAVE_AUTENTICACAO_CLIENTE';
const DELETAR_CHAVE_AUTENTICACAO_CLIENTE = 'DELETAR_CHAVE_AUTENTICACAO_CLIENTE';
const FETCH_TIPOS_USUARIOS = 'FETCH_TIPOS_USUARIOS';
const CHANGE_ID_FILIAL_ACTION = 'CHANGE_ID_FILIAL_ACTION';


export const getAcompanhamentoClienteAction = (isCliente, resetAcompanhamentos, sort = 'data,ASC') => ({
  type: FIND_ACOMPANHAMENTO_CLIENTE,
  isCliente,
  resetAcompanhamentos,
  sort,
});

export const getClienteByIdAction = (isCliente, idCliente) => ({
  type: GET_CLIENTE_BY_ID,
  isCliente,
  idCliente,
});

export const insertClienteAction = (isCliente, foto) => ({
  type: INSERT_CLIENTE,
  isCliente,
  foto,
});

export const updateClienteAction = (isCliente, roolBackFunction, foto) => ({
  type: UPDATE_CLIENTE,
  isCliente,
  roolBackFunction,
  foto,
});

export const getEmpresasGrupoAction = (codigoEmpresaGrupo, serCliente) => ({
  type: FIND_EMPRESAS_GRUPO,
  codigoEmpresaGrupo,
  serCliente,
});

export const migrarPossivelClienteAction = roolBackFunction => ({
  type: MIGRAR_POSSIVEL_CLIENTE,
  roolBackFunction,
});

export const insertAcompanhamentoClienteAction = isCliente => ({
  type: INSERT_ACOMPANHAMENTO_CLIENTE,
  isCliente,
});

export const getClienteEmpresaNFAction = (idCliente, cnpjCpf, categoria) => ({
  type: GET_CLIENTE_EMPRESA_NF,
  idCliente,
  cnpjCpf,
  categoria,
});

export const getFaixaComissaoAction = () => ({
  type: GET_FAIXA_COMISSAO,
});

export const gerarChaveAutenticacaoClienteAction = idCliente => ({
  type: GERAR_CHAVE_AUTENTICAÇAO_CLIENTE,
  idCliente,
});

export const enviarEmailChaveAutenticacaoClienteAction = idCliente => ({
  type: ENVIAR_EMAIL_CHAVE_AUTENTICACAO_CLIENTE,
  idCliente,
});

export const deletarChaveAutenticacaoClienteAction = idCliente => ({
  type: DELETAR_CHAVE_AUTENTICACAO_CLIENTE,
  idCliente,
});

export const fetchTiposUsuariosAction = () => ({
  type: FETCH_TIPOS_USUARIOS,
});

export const changeIdFilialAction = idUsuarioPrincipal => ({
  type: CHANGE_ID_FILIAL_ACTION,
  idUsuarioPrincipal,
});

function* configurarBloqueiosEmEmpresaClienteNF(bloquearCadastroEmpresaClienteNF, bloquearBuscarEmpresaClienteNF) {
  yield put(setSimpleValuesClienteAction('bloquearCadastroEmpresaClienteNF', bloquearCadastroEmpresaClienteNF));
  yield put(setSimpleValuesClienteAction('bloquearBuscarEmpresaClienteNF', bloquearBuscarEmpresaClienteNF));
}

function* closeOptionalDialogIfVisible() {
  const optionalDialogVisible = select(states => states.actions.optionalDialogContent.open);
  if (optionalDialogVisible) {
    yield put(openOptionalDialogAction(
      {
        open: false,
        message: MESSAGES.OPTIONAL_DIALOG_MESSAGE_INFORMATION,
      },
    ));
  }
}

function* verifyRollbackPage(notFoundException, exceptionMessage) {
  let errorOrigin = '';
  if (notFoundException) {
    errorOrigin = 'CLIENTE_NOT_FOUND';
  }
  yield put(openDialogAction(true, 'error', exceptionMessage, errorOrigin));
}

function showSnackBar(errorsMessage, exceptionErrorMessage) {
  if (errorsMessage.length > 0) {
    toast.error(errorsMessage[0], { style: { width: '392px' } });
  } else {
    toast.error(exceptionErrorMessage, { style: { width: '392px' } });
  }
}

function isErrorSalvarFoto(exception) {
  return exception.response.data.path.endsWith('/foto');
}

function* generateAndShowException(exception, exceptionsMessages, idClienteCriado, promiseDelete) {
  const exceptionMessage = getExceptionMessage(exception);
  const notFoundException = isNotFoundException(exception);
  const conflictException = isConflictException(exception);
  const badRequestException = isBadRequestException(exception);
  const exceptionErrorMessage = getExceptionErrorMessage(exception);
  const reloadCombos = conflictException;
  const showDialogException = (notFoundException || conflictException);

  yield closeOptionalDialogIfVisible();
  if (isErrorSalvarFoto(exception)) {
    yield put(openDialogAction(true, 'warning', exceptionMessage));
    yield put(onChangeActions('errorImage', true));
    if (idClienteCriado) {
      yield call(promiseDelete, idClienteCriado);
    }
  } else if (badRequestException && exceptionMessage) {
    yield showSnackBar([], exceptionMessage);
  } else if (showDialogException && exceptionMessage) {
    yield verifyRollbackPage(notFoundException, exceptionMessage);
  } else {
    const errorsMessage = getErrorMessages(exceptionsMessages);
    yield showSnackBar(errorsMessage, exceptionErrorMessage);
  }

  if (reloadCombos) {
    yield put(onReloadCombos(true));
    yield put(onClearCombos());
  }
}

function* getAcompanhamentoAndConfigurePageSearched(promise, resetAcompanhamentos, sort) {
  const dataEnvioInicial = yield select(state => state.settingsAcompanhamento.requestFields.dataEnvioInicial.getStringDate());
  const conteudo = yield select(state => state.settingsAcompanhamento.requestFields.conteudo.value);
  const idCliente = yield select(state => state.controls.idCliente.value);
  const pageNumber = resetAcompanhamentos ? 0 : yield select(state => state.settingsAcompanhamento.pageable.page);

  const { data } = yield promise(idCliente, conteudo, dataEnvioInicial, pageNumber, sort);
  if (resetAcompanhamentos) yield put(onClearAcompanhamentosAction());

  const isReverseOrder = sort === 'data,DESC';
  yield put(setAcompanhamentosAction(data, isReverseOrder));
  yield put(setAcompanhamentoPageableAction(data.first, data.last, data.number + 1));
}

function* findAcompanhamentoCliente(isCliente, resetAcompanhamentos, sort) {
  yield put(setAttributesAcompanhamentoAction('loadingAcompanhamento', true));

  const promise = (isCliente) ? getAcompanhamentoCliente : getAcompanhamentoPossivelCliente;
  const requestFieldsAcompanhamentos = yield select(state => state.settingsAcompanhamento.requestFields);

  try {
    yield getAcompanhamentoAndConfigurePageSearched(promise, resetAcompanhamentos, sort);
  } catch (exception) {
    const [updatedFields] = yield getExceptionHandler(exception, requestFieldsAcompanhamentos);
    yield put(setAttributesAcompanhamentoAction('requestFields', updatedFields));
  } finally {
    yield put(setAttributesAcompanhamentoAction('loadingAcompanhamento', false));
  }
}

function* getAcompanhamentoClienteHandler(action) {
  const { isCliente, resetAcompanhamentos, sort } = action;
  yield findAcompanhamentoCliente(isCliente, resetAcompanhamentos, sort);
}


function* insertAcompanhamento(promise, isCliente) {
  yield put(setAttributesAcompanhamentoAction('loadingInsertAcompanhamento', true));
  const idCliente = yield select(state => state.controls.idCliente.value);
  const requestFields = yield select(state => state.settingsAcompanhamento.requestFields);
  const payload = yield buildPayload(requestFields);
  payload.dataEnvioInicial = undefined;
  try {
    yield call(promise, idCliente, payload);
    yield put(setRequestFieldsAcompanhamentoValueAction('texto', ''));
    yield findAcompanhamentoCliente(isCliente, true, 'data,DESC');
    yield put(setAttributesAcompanhamentoAction('scrollInBottom', true));
  } catch (exception) {
    const [updateFields] = getExceptionHandler(exception, requestFields);
    yield put(setAttributesAcompanhamentoAction('requestFields', updateFields));
    if (isBadRequestException(exception) && isNotExceptionValidations(exception)) {
      const message = getExceptionMessage(exception);
      toast.error(message, { style: { width: '392px' } });
    }
  } finally {
    yield put(setAttributesAcompanhamentoAction('loadingInsertAcompanhamento', false));
  }
}

function* insertAcompanhamentoHandler(action) {
  const { isCliente } = action;
  const promise = (isCliente) ? insertAcompanhamentoCliente : insertAcompanhamentoPossivelCliente;
  yield insertAcompanhamento(promise, isCliente);
}


function* getEmpresasDoGrupoByCodEmpresaPrincipal(codEmpresaPrincipal, idCliente, serCliente) {
  const { data } = yield call(getClientesGrupo, codEmpresaPrincipal, idCliente, serCliente);
  yield put(onUpdateEmpresasGrupo(data));
}

function* obterLogotipoHandler(promiseLogotipo, idCliente) {
  const image = yield select(state => state.image);
  const newImage = { ...image };
  try {
    const response = yield call(promiseLogotipo, idCliente);

    yield newImage.imagePreviewUrl = `data:image;base64,${response.data}`;
    yield put(setImageAction(newImage));
  } catch (e) {
    // Nenhum tratamento até agora...
  }
}

function makeDiasElegiveis(dias) {
  return {
    id: dias?.idOpcaoDiaMesPrestacao,
    label: dias?.nomeDia,
    rightChecked: dias?.pertenceCliente,
    currentChecked: false,
  };
}

function sanitizeValueUndefined(value) {
  if (value instanceof Array) {
    return value.length > 0 ? value : [];
  }
  return value !== undefined ? value : '';
}

function* configureValuesAntigosCliente(data) {
  yield put(setStoreValueAction('emailAntigo', sanitizeValueUndefined(data.emailAtendimentoAcordoSeguro)));
  yield put(setStoreValueAction('descontoPixASAntigo', sanitizeValueUndefined(data.descontoPixAS)));
  yield put(setStoreValueAction('descontoBoletoASAntigo', sanitizeValueUndefined(data.descontoBoletoAS)));
}

function* aplicarBloqueioDeEmpresaClienteNF() {
  const utilizaEmpresaAlternativaEmissaoNF = yield select(state => state.controls.utilizaEmpresaAlternativaEmissaoNF.copy());
  const idClienteNotaFiscal = yield select(state => state.controls.empresaClienteNotaFiscal.idClienteNotaFiscal.copy());
  if (utilizaEmpresaAlternativaEmissaoNF.value) {
    if (idClienteNotaFiscal.value !== undefined && idClienteNotaFiscal.value !== null && idClienteNotaFiscal.value !== '') {
      yield configurarBloqueiosEmEmpresaClienteNF(true, true);
      return;
    }
    yield configurarBloqueiosEmEmpresaClienteNF(false, true);
    return;
  }
  yield configurarBloqueiosEmEmpresaClienteNF(false, false);
}

function* getClienteById(isCliente, promiseLogotipo, idCliente, promise) {
  yield put(setLoadingAction(true));
  try {
    const diasResponse = yield call(getDiasElegiveisService, idCliente);
    const { data } = yield promise(idCliente);
    yield put(onUpdateAllForm(data, diasResponse.data.map(makeDiasElegiveis).filter(dia => dia.rightChecked).map(dia => dia.id)));
    yield aplicarBloqueioDeEmpresaClienteNF();
    yield configureValuesAntigosCliente(data);
    yield obterLogotipoHandler(promiseLogotipo, idCliente);
  } catch (exception) {
    yield generateAndShowException(exception);
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* getEmpresasGrupoByClienteIdHandler(action) {
  const { codigoEmpresaGrupo, serCliente } = action;
  const idClienteAtual = yield select(state => state.controls.idCliente.value);
  yield put(setLoadingEmpresasGurpoAction(true));
  try {
    yield getEmpresasDoGrupoByCodEmpresaPrincipal(codigoEmpresaGrupo, idClienteAtual, serCliente);
    yield put(setStatusPromisseCodEmpresa('sucess', ''));
    yield put(setHasErrorInValidateFormAction(false));
  } catch (exception) {
    yield put(setStatusPromisseCodEmpresa('fail', exception.response.data.message));
    yield put(setHasErrorInValidateFormAction(true));
    yield put(setErrorCodPrincipal(''));
  } finally {
    yield put(setLoadingEmpresasGurpoAction(false));
  }
}

function getClienteForPayload(controls) {
  const clientePayload = buildPayload(controls);
  clientePayload.endereco = buildEnderecoPayload(controls.endereco);
  clientePayload.telefones = buildPayloadArray(controls.telefones);
  clientePayload.emails = buildPayloadArray(controls.emails);
  clientePayload.usuarios = buildPayloadArray(controls.usuarios);

  if (controls.utilizarDescProprioPixAS) {
    clientePayload.descontoPixAS = buildPayloadArray(controls.descontoPixAS);
  }

  if (controls.utilizarDescProprioBoletoAS) {
    clientePayload.descontoBoletoAS = buildPayloadArray(controls.descontoBoletoAS);
  }


  clientePayload.telefoneAdvogado = (controls.telefoneAdvogado.numero.value || controls.telefoneAdvogado.tipoTelefone.value)
    ? buildPayload(controls.telefoneAdvogado) : undefined;

  if (controls.tipoCalculoHonorarios) {
    clientePayload.tipoCalculoHonorarios = buildPayload(controls.tipoCalculoHonorarios);
    clientePayload.tipoCalculoHonorarios.honorarios = buildPayloadArray(controls.tipoCalculoHonorarios.honorarios);
  }

  if (controls.tipoCalculoComissoes) {
    clientePayload.tipoCalculoComissoes = buildPayload(controls.tipoCalculoComissoes);
    clientePayload.tipoCalculoComissoes.comissoes = buildPayloadArray(controls.tipoCalculoComissoes.comissoes);
  }

  if (controls.tipoCalculoRepasse) {
    clientePayload.tipoCalculoRepasse = buildPayload(controls.tipoCalculoRepasse);
    clientePayload.tipoCalculoRepasse.repasses = buildPayloadArray(controls.tipoCalculoRepasse.repasses);
  }

  if (controls.tipoCalculoJurosDevedores) {
    clientePayload.tipoCalculoJurosDevedores = buildPayload(controls.tipoCalculoJurosDevedores);
    clientePayload.tipoCalculoJurosDevedores.jurosDosDevedores = buildPayloadArray(controls.tipoCalculoJurosDevedores.jurosDosDevedores);
  }

  if (controls.tipoHonorariosContratuais) {
    clientePayload.tipoHonorariosContratuais = buildPayloadArray(controls.tipoHonorariosContratuais.honorariosContratuais);
  }

  if (controls.empresaClienteNotaFiscal) {
    clientePayload.empresaClienteNotaFiscal = buildPayload(controls.empresaClienteNotaFiscal);
    clientePayload.empresaClienteNotaFiscal.endereco = buildEnderecoPayload(controls.empresaClienteNotaFiscal.endereco);
  }

  if (controls.campanha) {
    clientePayload.campanha = buildPayload(controls.campanha);
  }

  clientePayload.prazosExpiracao = buildPayloadArray(controls.prazosExpiracao);
  clientePayload.diasDoMes = controls?.diasDoMes;

  return clientePayload;
}

function* catchSubmitException(exception, controls, idClienteCriado, promiseDelete) {
  const [updatedControls, exceptionsMessages] = yield getExceptionHandler(exception, controls);
  if (updatedControls) {
    yield put(onUpdateControls(updatedControls));
  }
  yield generateAndShowException(exception, exceptionsMessages, idClienteCriado, promiseDelete);
}

function* successSubmit(isCliente, isInsert, roolBackFunction) {
  yield put(onUpdateAllForm());

  if (isInsert) {
    toast.success(isCliente ? MESSAGES.INSERT_CLIENTE_SUCESS : MESSAGES.INSERT_POSSIVEL_CLIENTE_SUCESS);
  } else if (roolBackFunction) {
    yield roolBackFunction();
  }
}

function* submitInsertCliente(promise, promiseLogotipo, promiseDelete, isCliente, foto) {
  const allControls = yield select(states => states.controls);
  let idClienteCriado;
  yield put(setLoadingAction(true));
  try {
    const clientePayload = getClienteForPayload(allControls);
    const { data } = yield promise(clientePayload);
    idClienteCriado = data.idCliente;

    if (idClienteCriado && clientePayload.exibirLogoProprioAS) { // Nao tem logo e quer cadastrar
      yield promiseLogotipo(data.idCliente, foto);
    }

    yield successSubmit(isCliente, true);
  } catch (exception) {
    yield catchSubmitException(exception, allControls, idClienteCriado, promiseDelete);
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* validateHasErrorInUpdate() {
  const hasErrorInValidationForm = yield select(states => states.actions.hasErrorInValidationForm);
  if (hasErrorInValidationForm) {
    toast.error(MESSAGES.ERROR_UPDATE_MESSAGE, { style: { width: '392px' } });
  }
  return hasErrorInValidationForm;
}

function* submitUpdateCliente(promise, promiseLogotipo, isCliente, roolBackFunction, foto) {
  const allControls = yield select(states => states.controls);
  const image = yield select(states => states.image);
  const hasErrorInValidationForm = yield validateHasErrorInUpdate();
  if (!hasErrorInValidationForm) {
    yield put(setLoadingAction(true));
    try {
      const clientePayload = getClienteForPayload(allControls);

      // Chamada API do logo
      if (clientePayload.idCliente) {
        if (clientePayload.exibirLogoProprioAS && !image.imagePreviewUrl) { // Nao tem logo e quer cadastrar
          yield promiseLogotipo(clientePayload.idCliente, foto, clientePayload.exibirLogoProprioAS);
        }
        if (clientePayload.exibirLogoProprioAS && image.imagePreviewUrl && foto) { // Tem logo e quer atualizar.
          yield promiseLogotipo(clientePayload.idCliente, foto, clientePayload.exibirLogoProprioAS);
        }
      }
      // Chamada api de update
      yield promise(clientePayload, clientePayload.idCliente);

      yield successSubmit(isCliente, false, roolBackFunction);
    } catch (exception) {
      yield catchSubmitException(exception, allControls);
    } finally {
      yield put(setLoadingAction(false));
    }
  }
}

function* migrarPossivelClienteHandler(action) {
  const { roolBackFunction } = action;
  const allControls = yield select(states => states.controls);
  yield put(setLoadingAction(true));
  try {
    const clientePayload = getClienteForPayload(allControls);
    yield migrarPossivelCliente(clientePayload, clientePayload.idCliente);
    yield successSubmit(false, false, roolBackFunction);
  } catch (exception) {
    yield catchSubmitException(exception, allControls);
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* insertClienteHandler(action) {
  const { isCliente, foto } = action;
  const promise = isCliente ? insertCliente : insertPossivelCliente;
  const promiseLogotipo = isCliente ? saveLogotipoCliente : saveLogotipoPossivelCliente;
  const promiseDelete = isCliente ? removerCliente : deletePossivelCliente;
  yield submitInsertCliente(promise, promiseLogotipo, promiseDelete, isCliente, foto);
}

function* updateClienteHandler(action) {
  const { isCliente, roolBackFunction, foto } = action;
  const promise = isCliente ? updateCliente : updatePossivelCliente;
  const promiseLogotipo = isCliente ? saveLogotipoCliente : saveLogotipoPossivelCliente;
  yield submitUpdateCliente(promise, promiseLogotipo, isCliente, roolBackFunction, foto);
}


function* getClienteByIdHandler(action) {
  const {
    isCliente, idCliente,
  } = action;
  const diasResponse = yield call(getDiasElegiveisService, idCliente);
  const promise = isCliente ? getCliente : getPossivelCliente;
  const promiseLogotipo = isCliente ? obterLogotipoCliente : obterLogotipoPossivelCliente;
  yield getClienteById(isCliente, promiseLogotipo, idCliente, promise);
  yield put(onChangeActions('listaDiasElegiveisCliente', diasResponse.data.map(makeDiasElegiveis)));
}

function* getClienteEmpresaNFHandler(action) {
  const {
    cnpjCpf, idCliente, categoria,
  } = action;

  const form = yield select(states => states.controls.empresaClienteNotaFiscal);

  try {
    const response = yield call(getClienteEmpresaNFService, cnpjCpf, idCliente, categoria);
    yield put(onChangeControlsNewEmpresaClienteNotaFiscalAction(response.data));
    if (response.data.idCliente !== null) {
      toast.success('Cliente encontrado!');
      yield configurarBloqueiosEmEmpresaClienteNF(true, true);
    } else if (response.data.idEmpresaNotaFiscal !== null) {
      toast.success('Empresa encontrado!');
      yield configurarBloqueiosEmEmpresaClienteNF(false, true);
    } else {
      yield configurarBloqueiosEmEmpresaClienteNF(false, false);
    }
  } catch (e) {
    if (e.response.status === 404) {
      yield configurarBloqueiosEmEmpresaClienteNF(false, true);
    }
    const [updatedRequestFields] = yield getExceptionHandler(e, form);
    if (updatedRequestFields) {
      yield put(setControlsAttributesAction('empresaClienteNotaFiscal', updatedRequestFields));
    }
    yield getExceptionHandler(e, form);
  }
}

function* getFaixaComissaoHandler() {
  yield put(setLoadingAction(true));

  try {
    const response = yield call(getFaixasComissao);
    let objComissoes = {};
    response.data.forEach((element) => {
      if (element.comissoes.length > 0) {
        objComissoes = {
          key: new FieldModel({ name: 'key', value: uuidv1() }),
          comissoes: element.comissoes,
          descricaoTipoCalculoComissao: element.descricaoTipoCalculoComissao,
          idTipoCalculoComissao: element.idTipoCalculoComissao,
        };
      }
    });
    const tipoCalculoComissoes = buildDefaultClienteTipoCalculoComissaoParametro(
      objComissoes.idTipoCalculoComissao,
      objComissoes.descricaoTipoCalculoComissao,
      objComissoes.comissoes,
    );
    yield put(setSimpleValuesClienteAction('tipoCalculoComissoes', tipoCalculoComissoes));
  } catch (e) {
    //
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* gerarChaveAutenticacaoClienteHandler(action) {
  yield put(setLoadingAction(true));

  try {
    const response = yield call(gerarChaveAutenticacaoCliente, action.idCliente);
    toast.success('Nova chave enviada com sucesso.');
    yield put(onChangeControls('authorizationKey', response.data));
  } catch (e) {
    toast.warn(e.response.data.message);
    yield put(onChangeControls('authorizationKey', e.response.data.payload));
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* enviarEmailChaveAutenticacaoClienteHandler(action) {
  yield put(setLoadingAction(true));

  try {
    yield call(enviarEmailChaveAutenticacaoCliente, action.idCliente);
    toast.success('Chave enviada com sucesso.');
  } catch (e) {
    toast.warn(e.response.data.message);
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* deletarChaveAutenticacaoClienteHandler(action) {
  yield yield put(setLoadingAction(true));

  try {
    yield call(deletarChaveAutenticacaoCliente, action.idCliente);
    yield put(onChangeControls('authorizationKey', ''));
  } catch (e) {
    toast.error('Erro ao apagar a chave.', { style: { width: '392px' } });
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* fetchTiposUsuariosHandler() {
  try {
    const [responseComercial, responseTelemarketing] = [
      yield call(buscarIdTipoUsuarioComercial),
      yield call(buscarIdTipoUsuarioTelemarketing),
    ];

    const dataIdsObject = {
      idComercial: responseComercial?.data,
      idTelemarketing: responseTelemarketing?.data,
    };
    yield put(onChangeIdsTiposUsuariosAction(dataIdsObject));
  } catch (e) {
    toast.error('Erro na obtenção dos tipos de usuário.');
  }
}

function* changeIdFilialHandler(actions) {
  try {
    const { idUsuarioPrincipal } = actions;

    if (idUsuarioPrincipal) {
      const response = yield call(buscarIdFilialPorIdUsuarioPrincipal, idUsuarioPrincipal);
      yield put(onChangeControls('idFilial', response.data));
    } else {
      yield put(onChangeControls('idFilial', ''));
    }
  } catch (e) {
    toast.error('Erro na alteração da filial.');
  }
}


export default function* watchCrudCliente() {
  yield takeLatest(GET_CLIENTE_BY_ID, getClienteByIdHandler);
  yield takeLatest(INSERT_CLIENTE, insertClienteHandler);
  yield takeLatest(UPDATE_CLIENTE, updateClienteHandler);
  yield takeLatest(FIND_EMPRESAS_GRUPO, getEmpresasGrupoByClienteIdHandler);
  yield takeLatest(MIGRAR_POSSIVEL_CLIENTE, migrarPossivelClienteHandler);
  yield takeLatest(FIND_ACOMPANHAMENTO_CLIENTE, getAcompanhamentoClienteHandler);
  yield takeLatest(INSERT_ACOMPANHAMENTO_CLIENTE, insertAcompanhamentoHandler);
  yield takeLatest(GET_CLIENTE_EMPRESA_NF, getClienteEmpresaNFHandler);
  yield takeLatest(GET_FAIXA_COMISSAO, getFaixaComissaoHandler);
  yield takeLatest(GERAR_CHAVE_AUTENTICAÇAO_CLIENTE, gerarChaveAutenticacaoClienteHandler);
  yield takeLatest(ENVIAR_EMAIL_CHAVE_AUTENTICACAO_CLIENTE, enviarEmailChaveAutenticacaoClienteHandler);
  yield takeLatest(DELETAR_CHAVE_AUTENTICACAO_CLIENTE, deletarChaveAutenticacaoClienteHandler);
  yield takeLatest(FETCH_TIPOS_USUARIOS, fetchTiposUsuariosHandler);
  yield takeLatest(CHANGE_ID_FILIAL_ACTION, changeIdFilialHandler);
}
