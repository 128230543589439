import React, { useCallback } from 'react';
import IconButtonTooltip from '../../../../UI/IconButtonTooltip/IconButtonTooltip';
import InputField from '../../../../UI/Field/Input';
import SelectorField from '../../../../UI/Field/Selector';

import useStyles from './UsuarioCliente.styles';
import { getRequiredLabel } from '../../../../../helpers/utils/utils';
import Grid24 from '../../../../UI/Grid24/Grid24';

const CONSTANTS = {
  login: 'Login',
  senha: 'Senha',
  status: 'Status',
  email: 'E-mail',
  perfil: 'Perfil',
  hintRemover: 'Excluir Usuário',
  emptyPassword: '      ',
};

function UsuarioCliente({
  index,
  isEditMode,
  showPassword,
  perfisUsuarioCliente,
  status,
  usuario,
  setAttribute,
  setFocus,
  onRemove,
}) {
  const {
    login,
    senha,
    statusClienteUsuario,
    email,
    idPerfilCliente,
  } = usuario;

  const styles = useStyles();

  const changeAttributeHandler = useCallback((event) => {
    const { name, value } = event.target;
    setAttribute(name, value, index);
  }, [setAttribute, index]);

  const focusAttributeHandler = useCallback((event) => {
    let fieldName = event.target.name;

    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    setFocus(fieldName, index);
  }, [setFocus, index]);

  return (
    <div>
      <Grid24 isFormContainer spacing={1}>
        <Grid24 item xs={24} sm={6}>
          <InputField
            isForm
            fullWidth
            disabled={!isEditMode}
            label={getRequiredLabel(CONSTANTS.login, isEditMode)}
            name={login.name}
            value={login.value}
            error={login.error}
            maxLength={login.maxLength}
            errorMessage={login.errorMessage}
            onChange={changeAttributeHandler}
            onFocus={focusAttributeHandler}
          />
        </Grid24>
        <Grid24 item xs={24} sm={6}>
          <InputField
            isForm
            fullWidth
            disabled={!isEditMode}
            label={getRequiredLabel(CONSTANTS.senha, isEditMode)}
            name={senha.name}
            type={showPassword ? 'password' : 'text'}
            value={showPassword ? senha.value : senha.value.trim()}
            error={senha.error}
            maxLength={senha.maxLength}
            errorMessage={senha.errorMessage}
            onChange={changeAttributeHandler}
            onFocus={focusAttributeHandler}
          />
        </Grid24>
        <Grid24 item xs={24} sm={2}>
          <SelectorField
            isForm
            fullWidth
            disabled={!isEditMode}
            items={status}
            label={getRequiredLabel(CONSTANTS.status, isEditMode)}
            name={statusClienteUsuario.name}
            value={statusClienteUsuario.value}
            error={statusClienteUsuario.error}
            errorMessage={statusClienteUsuario.errorMessage}
            onChange={changeAttributeHandler}
          />
        </Grid24>
        <Grid24 item xs={12} sm={5}>
          <SelectorField
            isForm
            fullWidth
            disabled={!isEditMode}
            items={perfisUsuarioCliente}
            label={getRequiredLabel(CONSTANTS.perfil, isEditMode)}
            name={idPerfilCliente.name}
            value={idPerfilCliente.value}
            error={idPerfilCliente.error}
            errorMessage={idPerfilCliente.errorMessage}
            onChange={changeAttributeHandler}
            onFocus={focusAttributeHandler}
          />
        </Grid24>
        {isEditMode && (
          <Grid24
            item
            xs={24}
            sm={4}
            className={styles.wrapperRemoveButton}
          >
            <IconButtonTooltip
              hint={CONSTANTS.hintRemover}
              onClick={onRemove}
            />
          </Grid24>
        )}
      </Grid24>
      <Grid24 isFormContainer spacing={1}>
        <Grid24 item xs={24} sm={12}>
          <InputField
            isForm
            fullWidth
            disabled={!isEditMode}
            label={CONSTANTS.email}
            name={email.name}
            value={email.value}
            error={email.error}
            maxLength={email.maxLength}
            errorMessage={email.errorMessage}
            onChange={changeAttributeHandler}
            onFocus={focusAttributeHandler}
          />
        </Grid24>
      </Grid24>
    </div>
  );
}

export default React.memo(UsuarioCliente);
