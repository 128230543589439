import React from 'react';
import { withRouter } from 'react-router';
import CheckboxField from '../../../../components/UI/Field/Checkbox/CheckboxField';
import InputField from '../../../../components/UI/Field/Input';
import Grid24 from '../../../../components/UI/Grid24';
import FormWrapperWithTitle from '../../../../components/UI/Wrapper/Form/FormWrapperWithTitle';
import {
  DDD, NOME, OBSERVACAO, TELEFONE, WHATSAPP,
} from './DadosAdvogadoCliente.constants';
import useStyles from './DadosAdvogadoCliente.styles';
import { tipoTelefonesTypes } from '../../../../types/tipoTelefonesTypes';


function DadosAdvogadoCliente({ cliente }) {
  const styles = useStyles();

  return (
    <Grid24 xs={24} sm={24}>
      <FormWrapperWithTitle title="Dados do Advogado">
        <Grid24 container isFormContainer spacing="1">
          <Grid24 item xs={24} sm={8}>
            <InputField
              isForm
              fullWidth
              disabled
              label={NOME}
              value={cliente?.nomeAdvogado || ''}
            />
          </Grid24>
          <Grid24 xs={24} sm={3}>
            <InputField
              isForm
              fullWidth
              disabled
              label="Tipo Telefone"
              name="tipoTelefone"
              value={tipoTelefonesTypes[cliente?.telefoneAdvogado?.tipoTelefone]}
            />
          </Grid24>
          <Grid24 xs={24} sm={1}>
            <InputField
              isForm
              fullWidth
              disabled
              label={DDD}
              value={cliente?.telefoneAdvogado?.ddd || ''}
            />
          </Grid24>
          <Grid24 xs={24} sm={3}>
            <InputField
              isForm
              fullWidth
              disabled
              label={TELEFONE}
              value={cliente?.telefoneAdvogado?.numero || ''}
            />
          </Grid24>
          <Grid24 xs={24} sm={5}>
            <InputField
              isForm
              fullWidth
              disabled
              label={OBSERVACAO}
              value={cliente?.telefoneAdvogado?.observacao || ''}
            />
          </Grid24>
          <Grid24
            xs={24}
            sm={2}
            container
            className={styles.style__checkbox}
          >
            <Grid24 xs={24} sm={24} spacing={1}>
              <CheckboxField
                label={WHATSAPP}
                disabled
                checked={cliente?.telefoneAdvogado?.permitirWhatsApp}
              />
            </Grid24>
          </Grid24>
        </Grid24>
      </FormWrapperWithTitle>
    </Grid24>
  );
}

export default withRouter(DadosAdvogadoCliente);
