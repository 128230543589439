export const SET_DATE_FIELD_ACTION = '@recebimentoClientes/SET_DATE_FIELD_ACTIONS';
export const SET_SIMPLE_RECEBIMENTO_ACTION = '@recebimentoClientes/SET_SIMPLE_RECEBIMENTO_ACTION';
export const RESET_FORM_FIELDS_ACTION = '@recebimentoClientes/RESET_FORM_FIELDS_ACTION';
export const SET_VALUE_IMPORTAR_ACTIONS = '@recebimentoClientes/SET_VALUE_IMPORTAR_ACTIONS';
export const IMPORTAR_ARQUIVO_LANCAMENTO = '@recebimentoClientes/IMPORTAR_ARQUIVO_LANCAMENTO';
export const SET_COMMON_ACTION = '@recebimentoClientes/SET_COMMON_ACTION';
export const BUSCAR_INFO_CARDS = '@recebimentoClientes/BUSCAR_INFO_CARDS';
export const SET_SIMPLE_VALUE_INFO_CARDS_ACTION = '@recebimentoClientes/SET_SIMPLE_VALUE_INFO_CARDS_ACTION';
export const SET_BUSCAR_DADOS_TABELA = '@recebimentoClientes/SET_BUSCAR_DADOS_TABELA';
export const DELETE_RECEBIMENTO_CLIENTE_SELECIONADO = '@recebimentoClientes/DELETE_RECEBIMENTO_CLIENTE_SELECIONADO';
export const CANCEL_LIBERAR_RECEBIMENTO_CLIENTE_SELECIONADO = '@recebimentoClientes/CANCEL_LIBERAR_RECEBIMENTO_CLIENTE_SELECIONADO';
export const LIBERAR_RECEBIMENTO_CLIENTE_SELECIONADO = '@recebimentoClientes/LIBERAR_RECEBIMENTO_CLIENTE_SELECIONADO';
export const SET_REQUEST_FIELDS_VALUE = '@recebimentoClientes/SET_REQUEST_FIELDS_VALUE';
export const SET_REQUEST_FIELDS_FOCUS = '@recebimentoClientes/SET_REQUEST_FIELDS_FOCUS';
export const INSERT_LANCAMENTO = '@recebimentoClientes/INSERT_LANCAMENTO';
export const SAVE_WITH_ERROR = '@recebimentoClientes/SAVE_WITH_ERROR';
export const RESET_FORM_NOVO = '@recebimentoCliente/RESET_FORM_NOVO';
export const BUSCAR_INFO_EDITAR = '@recebimentoClientes/BUSCAR_INFO_EDITAR';
export const FORM_EDITAR = '@recebimentoClientes/FORM_EDITAR';
export const ON_CLICK_BUTTON_IMPRIMIR_ACTION = '@recebimentoClientes/ON_CLICK_BUTTON_IMPRIMIR_ACTION';
