export const FIND_CATEGORIAS = '@selectors/FIND_CATEGORIAS_LOAD_SELECTORS';
export const FIND_ESTADOS = '@selectors/FIND_ESTADOS_LOAD_SELECTORS';
export const FIND_TIPOS_TELEFONE = '@selectors/FIND_TIPOS_TELEFONE_LOAD_SELECTORS';
export const FIND_TIPOS_TELEFONE_CLIENTES = '@selectors/FIND_TIPOS_TELEFONE_CLIENTES_LOAD_SELECTORS';
export const FIND_TIPOS_TITULO = '@selectors/FIND_TIPOS_TITULO_LOAD_SELECTORS';
export const FIND_USUARIOS = '@selectors/FIND_USUARIOS_LOAD_SELECTORS';
export const FIND_CENTRO_CUSTO = '@selectors/FIND_CENTRO_CUSTO_LOAD_SELECTORS';
export const FIND_TIPO_USUARIO = '@selectors/FIND_TIPO_USUARIO_LOAD_SELECTORS';
export const FIND_BANCOS = '@selectors/FIND_BANCOS_LOAD_SELECTORS';
export const FIND_TIPOS_CONTA = '@selectors/FIND_TIPOS_CONTA_LOAD_SELECTORS';
export const FIND_PERFIS = '@selectors/FIND_PERFIS_LOAD_SELECTORS';
export const FIND_FILIAIS = '@selectors/FIND_FILIAIS_LOAD_SELECTORS';
export const FIND_TIPO_CLASSIFICACAO_CLIENTES_ACTION = '@selectors/FIND_TIPO_CLASSIFICACAO_CLIENTES_ACTION';
export const FIND_RAMOS_ATIVIDADES = '@selectors/FIND_RAMOS_ATIVIDADES_LOAD_SELECTORS';
export const FIND_PERFIS_USUARIOS_CLIENTE = '@selectors/FIND_PERFIS_USUARIOS_CLIENTE_LOAD_SELECTORS';
export const FIND_TIPO_CALCULO_HONORARIO_PARAMETRO = '@selectors/FIND_TIPO_CALCULO_HONORARIO_PARAMETRO_SELECTOR';
export const FIND_TIPO_CALCULO_COMISSAO_PARAMETRO = '@selectors/FIND_TIPO_CALCULO_COMISSAO_PARAMETRO_SELECTOR';
export const FIND_TIPO_HONORARIOS_CONTRATUAIS_ACTION = '@selectors/FIND_TIPO_HONORARIOS_CONTRATUAIS_ACTION';
export const FIND_TIPO_DESCONTO = '@selectors/FIND_TIPO_DESCONTO_SELECTOR';
export const FIND_TIPO_FASES = '@selectors/FIND_FASES_SELECTORS';
export const FIND_REQUISICOES = '@selectors/FIND_REQUISICOES_SELECTOR';
export const FIND_REGRA_CALCULO_REPASSE_PARAMETRO = '@selectors/FIND_REGRA_CALCULO_REPASSE_PARAMETRO';
export const FIND_TIPO_USUARIO_COMERCIAL_PRINCIPAL = '@selectors/FIND_TIPO_USUARIO_COMERCIAL_PRINCIPAL';
export const FIND_TIPO_USUARIO_COMERCIAL_TELEMARKETING = '@selectors/FIND_TIPO_USUARIO_COMERCIAL_TELEMARKETING';
export const FIND_TIPO_ACORDO_SEGURO = '@selectors/FIND_TIPO_ACORDO_SEGURO';
export const FIND_TIPO_COMISSAO_BORDERO = '@selectors/FIND_TIPO_COMISSAO_BORDERO';
export const FIND_USUARIOS_TELEMARKETING = '@selectors/FIND_USUARIOS_TELEMARKETING';
export const FIND_BORDERO_TIPO_STATUS = '@selectors/FIND_BORDERO_TIPO_STATUS';
export const FIND_TIPOS_LOG_PROCESSAMENTO_BORDERO = '@selectors/FIND_TIPOS_LOG_PROCESSAMENTO_BORDERO';
export const FIND_TIPO_PRAZO_EXPIRACAO_PIX = '@selectors/FIND_TIPO_PRAZO_EXPIRACAO_PIX_PARAMETRO';
export const FIND_PASTAS_FASE = '@selectors/GET_PASTAS_FASE';
export const FIND_NEGOCIADORES = '@selectors/FIND_NEGOCIADORES';
export const FIND_TIPOS_AGENDAMENTOS = '@selectors/FIND_TIPOS_AGENDAMENTOS';
export const FIND_USUARIOS_NEGOCIADORES_ACTION = '@selectors/FIND_USUARIOS_NEGOCIADORES_ACTION';
export const FIND_STATUS_BORDERO_IMPORTACAO = '@selectors/FIND_STATUS_BORDERO_IMPORTACAO';
export const FIND_MOTIVOS_BAIXA = '@selectors/FIND_MOTIVOS_BAIXA';
export const FIND_TIPO_TEXTO_PADRAO = '@selectors/FIND_TIPO_TEXTO_PADRAO';
export const FIND_AUTORES_ACOMPANHAMENTO = '@selectros/FIND_AUTORES_ACOMPANHAMENTO';
export const FIND_TIPOS_ACOMPANHAMENTO_PROCESSO = '@selectros/FIND_TIPOS_ACOMPANHAMENTO_PROCESSO';
export const FIND_TIPO_TITULO = '@selectros/FIND_TIPO_TITULO';
export const FIND_ALL_TIPOS_CALENDARIO = '@selectors/FIND_ALL_TIPOS_CALENDARIO';
export const FIND_ALL_RESPONSAVEIS_CONSULTA_DINAMICA = '@selectors/FIND_ALL_RESPONSAVEIS_CONSULTA_DINAMICA';
export const FIND_ALL_USUARIOS_COM_PERMISSAO_CONSULTA_DINAMICA = '@selectors/FIND_ALL_USUARIOS_COM_PERMISSAO_CONSULTA_DINAMICA';
export const FIND_ALL_TIPOS_PRESTACAO = '@selectors/FIND_ALL_TIPOS_PRESTACAO';
export const FIND_ALL_TIPOS_LIQUIDACAO_PRESTACAO = '@selectors/FIND_ALL_TIPOS_LIQUIDACAO_PRESTACAO';
export const FIND_GERADORES_RELATORIOS_TIPO_PRESTACAO = '@selectors/FIND_GERADORES_RELATORIOS_TIPOS_PRESTACAO';
export const FIND_ALL_TIPOS_CHAVE_PIX = '@selectors/FIND_ALL_TIPOS_CHAVE_PIX';
export const FIND_TIPOS_LANCAMENTOS_ACTION = '@contaCorrenteCliente/GET_TIPOS_LANCAMENTOS_ACTION';
export const FIND_RESPONSAVEIS_ACTION = '@contaCorrenteCliente/GET_RESPONSAVEIS_ACTION';
export const FIND_TIPOS_NOTAS_FISCAIS_ACTION = '@contaCorrenteCliente/FIND_TIPOS_NOTAS_FISCAIS_ACTION';
export const FIND_PREFEITURAS_NOTA_FISCAL_ACTION = '@contaCorrenteCliente/FIND_PREFEITURAS_NOTA_FISCAL_ACTION';
export const FIND_TIPO_FORMA_PAGAMENTO_ACTION = '@selector/FIND_TIPO_FORMA_PAGAMENTO_ACTION';
export const FIND_TIPOS_INTERVALO_PAGAMENTO_ACTION = '@selectors/FIND_TIPOS_INTERVALO_PAGAMENTO_ACTION';
export const FIND_TIPOS_FORMAS_PAGAMENTO = '@selectors/FIND_TIPOS_FORMAS_PAGAMENTO';
export const FIND_CONTAS_BANCARIAS = '@selectors/FIND_CONTAS_BANCARIAS';
export const FIND_TIPO_REGRA_COMISSIONAMENTO = 'selectors/FIND_TIPO_REGRA_COMISSIONAMENTO';
export const FIND_USUARIOS_PERMITEM_SIMULACAO = '@selectors/FIND_USUARIOS_PERMITEM_SIMULACAO';
export const FIND_INTERVALO_ENTRE_PAGAMENTO = '@selectors/FIND_INTERVALO_ENTRE_PAGAMENTO';
export const FIND_MODELO_NOTIFICACAO_EMAIL = '@selectors/FIND_MODELO_NOTIFICACAO_EMAIL';
export const FIND_MOTIVOS_QUEBRA_ACORDO_LIST_ACTION = '@selectors/FIND_MOTIVOS_QUEBRA_ACORDO_LIST_ACTION';
export const FIND_FASES_UNIFICAR_PROCESSOS = '@selectors/FIND_FASES_UNIFICAR_PROCESSOS';
export const FIND_NEGOCIADORES_EXTERNOS_ATIVOS_PARA_FASE = '@selectores/FIND_NEGOCIADORES_EXTERNOS_ATIVOS_PARA_FASE';
export const FIND_MOTIVOS_EXCLUSAO_TITULO = '@selectors/FIND_MOTIVOS_EXCLUSAO_TITULO';
export const FIND_NEGOCIADORES_VALIDOS = '@selectors/FIND_NEGOCIADORES_VALIDOS';
export const FIND_TIPOS_TITULOS_AGENDAMENTOS = '@selectors/FIND_TIPOS_TITULOS_AGENDAMENTOS';

// MESSAGE ERRORS
export const TIPO_LANCAMENTOS_ERROR = 'Ocorreu um problema ao tentar consultar os nomes dos Tipos de Lançamentos.';
export const RESPONSAVEIS_ERROR = 'Ocorreu um problema ao tentar consultar os nomes dos Responsáveis.';
