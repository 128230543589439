import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  div__root: {
    width: '100%',
    display: 'block',
  },
  div__estatisticas: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '14px',
  },
  estatisticas__cards: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '8px',
  },
  cards__external__root: {
    display: 'flex',
    borderRadius: '6px',
    height: '55px',
    marginRight: '10px',
  },
  cards__internal__root: {
    display: 'flex',
    width: '100%',
  },
  cards__infos__root: {
    display: 'block',
    width: '100%',
  },
  infos__title: {
    width: '100%',
    marginTop: '6px',
    textAlign: 'center',
    fontSize: '14px',
    color: 'white',
  },
  infos__counter: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'white',
  },
  cards__acesso: {
    width: '200px',
    border: '1px solid transparent',
    borderRadius: '6px',
    display: 'flex',
    fontSize: '12px',
    backgroundColor: '#46a04f',
  },
  icon__acesso: {
    width: '61px',
    backgroundColor: '#2a7132',
    '& i': {
      display: 'flex',
      width: '61px',
      height: '55px',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '29px',
      color: 'white',
    },
  },

  cards__notificados: {
    width: '200px',
    border: '1px solid transparent',
    borderRadius: '6px',
    display: 'flex',
    fontSize: '12px',
    backgroundColor: '#009bfa',
  },

  icon__notificado: {
    width: '61px',
    backgroundColor: '#006fc2',
    '& i': {
      display: 'flex',
      width: '61px',
      height: '55px',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '29px',
      color: 'white',
    },
  },

  cards__sms: {
    width: '200px',
    border: '1px solid transparent',
    borderRadius: '6px',
    display: 'flex',
    fontSize: '12px',
    backgroundColor: '#6c45bd',
  },
  icon__sms: {
    width: '61px',
    backgroundColor: '#4830a4',
    '& i': {
      display: 'flex',
      width: '61px',
      height: '55px',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '29px',
      color: 'white',
    },
  },


  cards__email: {
    width: '200px',
    border: '1px solid transparent',
    borderRadius: '6px',
    display: 'flex',
    fontSize: '12px',
    backgroundColor: '#00868a',
  },
  icon__email: {
    width: '61px',
    backgroundColor: '#005e5e',
    '& i': {
      display: 'flex',
      width: '61px',
      height: '55px',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '29px',
      color: 'white',
    },
  },
});
