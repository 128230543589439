import styled, { css } from 'styled-components';
import {
  IconButton, Paper, TablePagination, TableCell, TableRow,
} from '@material-ui/core';

export const TableContainer = styled(Paper)`
  background-color: #f1f4f9;
  border-radius: 7px;
  overflow-x: auto;

  td, th {
    font-size: .75rem;
  }

  td {
    padding: 4px 8px !important;
  }

  th {
    cursor: default;
    color: #034ddc;
    font-weight: bold;
    text-align: center;
    line-height: 1rem;
    padding: 4px 8px !important;
  }
`;

export const Pagination = styled(TablePagination)`
  width: 100%;
`;

export const DefaultTableCell = styled(TableCell)`
  ${props => props?.fixedHeight && css`
    height: 33px;
  `}
  vertical-align: middle;
`;

export const TableCellCenter = styled(DefaultTableCell)`
  text-align: center;
`;

export const TableCellLeft = styled(DefaultTableCell)`
  text-align: left;
`;

export const TableCellRight = styled(DefaultTableCell)`
  text-align: right;
`;

// REvisar onde está sendo usado
export const TableActionButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  color: black;
  padding: 0px;
`;

export const TableActionContainer = styled.div`
  > * + * {
    margin-left: 4px;
  }
`;

export const AltTableRow = styled(TableRow)`
  td {
    border-bottom: unset;
  }
`;


export const TableIconsInvisible = styled(TableRow)`
  display: flex;
  justify-content: center;

  span {
    width: 30px;
  }
`;
