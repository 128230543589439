import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  div__info: {
    fontSize: '16px',
    textAlign: 'center',
  },
  div__contadores: {
    marginTop: '6px',
    fontSize: '16px',
    '& p': {
      marginTop: '8px',
    },
  },
});
