import { getAttributeExistingObject } from '../../../helpers/utils/utils';
import FieldModel from '../../../models/FieldModel';

const SET_ATTRIBUTES = '@pastas/SET_ATTRIBUTES';
const SET_REQUEST_FIELD_VALUE = '@pastas/SET_REQUEST_FIELD_VALUE';
const SET_REQUEST_FIELD_FOCUS = '@pastas/SET_REQUEST_FIELD_FOCUS';
const SAVE_PASTA_FAILURE = '@pastas/SAVE_PASTA_FAILURE';
const SAVE_PASTA_SUCCESS = '@pastas/SAVE_PASTA_SUCCESS';
const GET_REQUEST_PASTA_SUCCESS = '@pastas/GET_REQUEST_PASTA_SUCCESS';
const RESET_REQUEST_FIELDS = '@pastas/RESET_REQUEST_FIELDS';


const messages = {
  successInsert: 'Pasta cadastrada com sucesso',
};

function buildRequestFields(fields) {
  return {
    idFasePasta: new FieldModel({
      name: 'idFasePasta',
      value: getAttributeExistingObject(fields, 'idFasePasta', null),
    }),
    publico: new FieldModel({
      name: 'idFasePasta',
      value: getAttributeExistingObject(fields, 'publico', false),
    }),
    nome: new FieldModel({
      name: 'nome',
      value: getAttributeExistingObject(fields, 'nome'),
    }),
    idUsuario: new FieldModel({
      name: 'idUsuario',
      value: getAttributeExistingObject(fields, 'idUsuario', ''),
    }),
  };
}

function initiateStates(fields) {
  return {
    requestFields: buildRequestFields(fields),
    content: [],
    loading: false,
    alert: {
      message: '',
      show: false,
    },
    snackbar: {
      show: false,
      message: '',
    },
    showCrudDialog: false,
  };
}

export const resetRequestsFieldsAction = () => ({
  type: RESET_REQUEST_FIELDS,
});

export const setRequestFieldValueAction = (name, value) => ({
  type: SET_REQUEST_FIELD_VALUE,
  name,
  value,
});

export const setRequestFieldFocusAction = name => ({
  type: SET_REQUEST_FIELD_FOCUS,
  name,
});

export const setAttributesAction = (name, value) => ({
  type: SET_ATTRIBUTES,
  name,
  value,
});

export const savePastaFailureAction = requestFields => ({
  type: SAVE_PASTA_FAILURE,
  requestFields,
});

export const savePastaSuccessAction = isEditMode => ({
  type: SAVE_PASTA_SUCCESS,
  isEditMode,
});

export const upadateAfterGetPastaSuccessAction = requestFields => ({
  type: GET_REQUEST_PASTA_SUCCESS,
  requestFields,
});


function setAttributesHandler(states, { name, value }) {
  return {
    ...states,
    [name]: value,
  };
}

function setRequestFieldValueHandler(states, { name, value }) {
  const newValue = states.requestFields[name].getNewModel(value);
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [name]: newValue,
    },
  };
}


function setRequestFieldFocusHandler(states, { name }) {
  const newField = states.requestFields[name].copy();
  newField.error = false;
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [name]: newField,
    },
  };
}

function savePastaFailureHandler(states, { requestFields }) {
  return {
    ...states,
    requestFields,
  };
}

function savePastaSuccessHandler(states, { isEditMode }) {
  return {
    ...states,
    requestFields: buildRequestFields(),
    showCrudDialog: false,
    snackbar: isEditMode ? states.snackbar : { show: true, message: messages.successInsert },
  };
}

function upadateAfterGetPastaSuccessHandler(states, { requestFields }) {
  return {
    ...states,
    requestFields: buildRequestFields(requestFields),
    showCrudDialog: true,
  };
}

function resetRequestsFieldsHandler(states) {
  return {
    ...states,
    requestFields: buildRequestFields(),
  };
}

export default (states = initiateStates(), actions) => {
  switch (actions.type) {
    case SET_ATTRIBUTES:
      return setAttributesHandler(states, actions);
    case SET_REQUEST_FIELD_VALUE:
      return setRequestFieldValueHandler(states, actions);
    case SET_REQUEST_FIELD_FOCUS:
      return setRequestFieldFocusHandler(states, actions);
    case SAVE_PASTA_FAILURE:
      return savePastaFailureHandler(states, actions);
    case SAVE_PASTA_SUCCESS:
      return savePastaSuccessHandler(states, actions);
    case GET_REQUEST_PASTA_SUCCESS:
      return upadateAfterGetPastaSuccessHandler(states, actions);
    case RESET_REQUEST_FIELDS:
      return resetRequestsFieldsHandler(states, actions);
    default:
      return states;
  }
};
