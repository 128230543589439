import { CORE, gateway } from '../../api';

export function atualizarFaseProcessoService(idProcesso, payload) {
  return gateway.put(`${CORE}/processos/${idProcesso}/fases`, payload);
}

export function findFasesTimelineProcessoService(idProcesso) {
  return gateway.get(`${CORE}/processos/${idProcesso}/fases`);
}

export function atualiarFaseMultiplosProcessosService(payload) {
  return gateway.put(`${CORE}/processos/fases`, payload);
}

export function validarAntecedentes(processosAntecedentes, idFase) {
  return gateway.post(`${CORE}/processos/antecedentes/validacao/${idFase}`, processosAntecedentes);
}
