import React from 'react';
import { Tab, Tabs } from '@material-ui/core';

import cx from 'classnames';
import CardWrapper from '../../../components/UI/CardWrapper/CardWrapper';
import PaperUI from '../../../components/UI/Paper/PaperUI';

import useStyles from './TabCardLayout.styles';
import LoadingUI from '../../../components/UI/Loading/LoadingUI';


function TabCardLayout({
  children,
  separate = false,
  showLoading = false,
  heightContainer = '60vh',
  tabItems,
  parentStates,
  footerComponent = null,
}) {
  const styles = useStyles();
  const [value, setValue] = parentStates;

  const classes = cx({ [styles.separator]: separate, [styles.bsNone]: !separate });

  const tabChangeHandler = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <LoadingUI show={showLoading} />
      <PaperUI className={classes}>
        <div className={styles.appBar}>
          <Tabs
            value={value}
            onChange={tabChangeHandler}
            classes={{ indicator: styles.tabSelectColor }}
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabItems.map((item) => {
              if (item) {
                return (
                  <Tab
                    disabled={item.disabled}
                    className={styles.ajustWhiteSpace}
                    key={item.id}
                    id={item.id}
                    label={item.label}
                    aria-controls={item.ariaControl}
                  />
                );
              }
              return null;
            })}
          </Tabs>
        </div>
        <CardWrapper height={heightContainer}>{children}</CardWrapper>
        {footerComponent}
      </PaperUI>
    </>
  );
}

export default React.memo(TabCardLayout);
