import React, { useCallback } from 'react';

import ButtonUI from '../../../UI/Button/ButtonUI';
import MaskField from '../../../UI/Field/Mask/MaskField';
import WrapperSubTitleForm from '../../../UI/WrapperSubTitleForm/WrapperSubTitleForm';
import Grid24 from '../../../UI/Grid24/Grid24';
import InputField from '../../../UI/Field/Input/InputField';

import { CPF_MASK, CNPJ_MASK } from '../../../../helpers/constants/layout.constants';
import { useCrudBorderoSelector, useCrudBoderoDispatch } from '../../../../store/Borderos/Crud/CrudBorderoProvider';
import { setAttributeAction } from '../../../../store/Borderos/Crud/CrudBordero.store';
import { findClienteByIdAction } from '../../../../store/Borderos/Crud/CrudBorderos.saga';

import useCrudBorderoStyles from '../CrudBordero.styles';

const constants = {
  TITLE_REGISTER_MODE: 'Informe o Código do cliente para o qual deseja cadastrar o Borderô',
  TITLE_INSPECT_MODE: 'Dados do Cliente',
  CONSULTAR: 'Consultar',
  CODIGO: 'Código',
  CODIGO_CLIENTE: 'Código do Cliente*',
  NOME: 'Razão Social/Nome',
  DOCUMENTO: 'CPF/CNPJ',
  CIDADE: 'Cidade',
  ESTADO: 'Estado',
};

export default function DadosClienteCrudBordero({ wrapperByMode }) {
  const styleCrudBordero = useCrudBorderoStyles();
  const dispatch = useCrudBoderoDispatch();

  const codigoCliente = useCrudBorderoSelector(states => states.requestFields.codigoCliente);
  const idCliente = useCrudBorderoSelector(states => states.requestFields.cliente.idCliente);
  const nome = useCrudBorderoSelector(states => states.requestFields.cliente.nome);
  const idEstado = useCrudBorderoSelector(states => states.requestFields.cliente.idEstado);
  const cidade = useCrudBorderoSelector(states => states.requestFields.cliente.cidade);
  const documento = useCrudBorderoSelector(states => states.requestFields.cliente.documento);

  const loadingCliente = useCrudBorderoSelector(states => states.loading.cliente);

  const getFormatMask = () => {
    if (documento.value.length <= 11) {
      return CPF_MASK;
    }
    return CNPJ_MASK;
  };

  const changeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setAttributeAction(name, value));
  }, [dispatch]);

  const findClienteHandler = () => {
    dispatch(findClienteByIdAction(codigoCliente.value));
  };

  const buildSearchCliente = () => (
    <Grid24 container isFormContainer spacing="1" style={{ marginBottom: '16px' }}>
      <Grid24 item xs={24} sm={3}>
        <InputField
          isForm
          label={constants.CODIGO_CLIENTE}
          name={codigoCliente.name}
          value={codigoCliente.value}
          error={codigoCliente.error}
          errorMessage={codigoCliente.errorMessage}
          maxLength={codigoCliente.maxLength}
          onChange={changeFieldsHandler}
        />
      </Grid24>
      <Grid24 item xs={24} sm={3}>
        <ButtonUI
          disabled={codigoCliente.value.trim().length === 0}
          className={styleCrudBordero.searchButton}
          showLoading={loadingCliente}
          onClick={findClienteHandler}
        >
          {constants.CONSULTAR}
        </ButtonUI>
      </Grid24>
    </Grid24>
  );

  return (
    <WrapperSubTitleForm title={wrapperByMode(constants.TITLE_REGISTER_MODE, constants.TITLE_INSPECT_MODE)}>
      {wrapperByMode(buildSearchCliente(), null)}
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={2}>
          <InputField
            disabled
            isForm
            fullWidth
            label={constants.CODIGO}
            value={idCliente.value}
          />
        </Grid24>
        <Grid24 xs={24} sm={10}>
          <InputField
            disabled
            isForm
            fullWidth
            label={constants.NOME}
            value={nome.value}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <MaskField
            disabled
            isForm
            fullWidth
            label={constants.DOCUMENTO}
            value={documento.value}
            format={getFormatMask()}
          />
        </Grid24>
        <Grid24 xs={24} sm={6}>
          <InputField
            disabled
            isForm
            fullWidth
            label={constants.CIDADE}
            value={cidade.value}
          />
        </Grid24>
        <Grid24 xs={24} sm={2}>
          <InputField
            disabled
            isForm
            fullWidth
            label={constants.ESTADO}
            value={idEstado.value}
          />
        </Grid24>
      </Grid24>
    </WrapperSubTitleForm>
  );
}
