import React, { createContext } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider, createSelectorHook, createDispatchHook } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import reducer from './RecebimentoDevedores.store';
import watchRecebimentoDevedores from './RecebimentoDevedores.saga';

export const RecebimentoDevedorescontext = createContext();
export const useRecebimentoSelector = createSelectorHook(RecebimentoDevedorescontext);
export const useRecebimentoDispatch = createDispatchHook(RecebimentoDevedorescontext);

export default function RecebimentoDevedoresProvider({ children }) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(watchRecebimentoDevedores);

  return (
    <Provider context={RecebimentoDevedorescontext} store={store}>
      {children}
    </Provider>
  );
}
