import React, {
  memo, useState, useEffect,
} from 'react';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Grid24 from '../../../../components/UI/Grid24';
import { getRequiredLabel } from '../../../../helpers/utils/utils';
import { SelectorField } from '../../../../components';
import { dialogQuebrarAcordoController as controller } from './dialogQuebrarAcordoController';

import { findMotivosQuebraAcordoListAction } from '../../../../store/Global/LoadSelectors/loadSelectors.saga';

function DialogQuebrarAcordo({
  setOpenButtonQuebrarAcordo,
  idProcesso,
  idFaseProcesso,
  idAcordo,
  pesquisarAcordos,
  settingsTable,
  columnOrdenacao,
  updatePage,
  getDadosResumo,
  setInfoAdicionaisAcordo,
  exibirApenasAcordosAtivosEhPropostas,
}) {
  const [idMotivoQuebraAcordo, setIdMotivoQuebraAcordo] = useState('');
  const motivosQuebraAcordoList = useSelector(states => states.selectors.motivosQuebraAcordo.selector);

  const dispatch = useDispatch();

  async function onClickSubmitQuebrarAcordo(e) {
    e.preventDefault();
    try {
      await controller.quebrarAcordo(idProcesso, idFaseProcesso, idAcordo, idMotivoQuebraAcordo);
      pesquisarAcordos(settingsTable.page, settingsTable.rowsPerPage, columnOrdenacao, exibirApenasAcordosAtivosEhPropostas);
      getDadosResumo(idProcesso).then((responseData) => {
        const { data } = responseData;
        setInfoAdicionaisAcordo(data);
      });
      updatePage();
      toast.success('Acordo quebrado com sucesso.');
    } catch {
      toast.error('Não foi possível quebrar o Acordo. Tente novamente.', { style: { width: '392px' } });
    } finally {
      setOpenButtonQuebrarAcordo(oldValue => !oldValue);
    }
  }

  useEffect(() => {
    dispatch(findMotivosQuebraAcordoListAction());
  }, [dispatch]);

  return (
    <>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 item xs={24} sm={24}>
          <SelectorField
            hasEmptyLabel
            isForm
            style={{ width: '100%' }}
            label={getRequiredLabel('Motivo', true)}
            items={motivosQuebraAcordoList}
            value={idMotivoQuebraAcordo}
            onChange={e => setIdMotivoQuebraAcordo(e.target.value)}
          />
        </Grid24>
      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '22px' }}>
        <Button
          onClick={() => setOpenButtonQuebrarAcordo(false)}
        >
          Cancelar
        </Button>
        <Button
          onClick={onClickSubmitQuebrarAcordo}
          disabled={idMotivoQuebraAcordo === ''}
        >
          Confirmar
        </Button>
      </div>

    </>
  );
}
export default memo(DialogQuebrarAcordo);
