import React from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { checkAuthenticateStatusAction } from '../store/Login/login.saga';
import { Routes } from '../routes/Routes';
import 'react-toastify/dist/ReactToastify.min.css';
import './global.css';
import './toastify.css';
import { FloatContainer } from '../components/FloatContainer/FloatContainer';

function App() {
  const dispatch = useDispatch();
  dispatch(checkAuthenticateStatusAction());

  return (
    <>
      <ToastContainer style={{ minWidth: '400px', maxWidth: '700px' }} />
      <Routes />
      <FloatContainer />
    </>
  );
}

export default App;
