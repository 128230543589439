import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  div__BtnPesquisar: props => ({
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginLeft: '18px',
      filter: props.disabled ? 'contrast(0.5)' : 'unset',
    },
  }),
});
