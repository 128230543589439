import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import FormWrapperTitle from '../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import MultilineSelect from '../../../../UI/Select/Multiline/MultilineSelect';
import { generateValuesWithSeparator } from '../../../../../helpers/utils/utils';
import useStyles from './requisicoesCrudUsuario.styles';
import { findRequisicoesAction } from '../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import { LABEL, TITLE } from './requisicoesCrudUsuario.constants';
import { useCrudUsuarioDispatch2, useCrudUsuarioSelector2 } from '../../../../../store/Usuario/V2/CrudUsuarioProvider';
import { onChangeRequisicoesAction } from '../../../../../store/Usuario/V2/crudUsuario.store';

function RequisicoesUsuario({ disabled = true }) {
  const classes = useStyles();

  const dispatch = useCrudUsuarioDispatch2();
  const globalDispatch = useDispatch();

  const requisicoes = useCrudUsuarioSelector2(states => states.controls.requisicoes.copy());
  const requisicoesSelector = useSelector(states => states.selectors.requisicoes.selector);

  useEffect(() => {
    globalDispatch(findRequisicoesAction());
  }, [globalDispatch]);

  const onChange = useCallback((event) => {
    const { value } = event.target;
    dispatch(onChangeRequisicoesAction(value));
  }, [dispatch]);

  const makeMultiLineSelectWithToolTip = (notInEditMode, component) => {
    const title = generateValuesWithSeparator(requisicoesSelector, requisicoes.value);
    return ((notInEditMode) ? (
      <>
        <Tooltip
          classes={{ tooltip: classes.tooltipMaxWidth }}
          arrow
          title={title}
          placement="bottom-start"
        >
          <div className={classes.containerTooltip}>
            {component}
          </div>
        </Tooltip>
      </>
    ) : (
      <>
        { component }
      </>
    ));
  };

  return (
    <FormWrapperTitle title={TITLE}>
      <div className={classes.multiLineSelectBox}>
        {makeMultiLineSelectWithToolTip(disabled, (<MultilineSelect
          isForm
          disabled={disabled}
          label={LABEL}
          items={requisicoesSelector}
          value={requisicoes.value}
          error={requisicoes.error}
          errorMessage={requisicoes.errorMessage}
          onChange={onChange}
        />
        ))}
      </div>
    </FormWrapperTitle>
  );
}

export default React.memo(RequisicoesUsuario);
