import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  h3: {
    fontSize: '16px',
    color: '#034ddc',
    fontWeight: '700',
  },
});

export const DropdownContainer = styled.ul`
  list-style: none;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  padding: 8px;

  & li + li {
    margin-top: 8px;
  }

  & .MuiFormControlLabel-root {
    margin-right: 0;
  }

  & .MuiButtonBase-root {
    padding: 0;
    padding-left: 8px;
  }

  & .MuiTypography-body1 {
     font-size: 12px;
   }

   & .MuiCheckbox-colorPrimary.Mui-disabled {
     color: #c4c4c4 !important;
   }
`;

export const ButtonDropDown = styled.div`
  width: 20px;
  height: 34px;
  display: flex;
  justify-content: center;
  cursor: pointer;

  > div {
    width: 0;
    height: 0;
    display: flex;
    align-self: center;

    border-left: ${props => (props.open ? '5px solid transparent' : '5px solid transparent')};
    border-right: ${props => (props.open ? '5px solid transparent' : '5px solid transparent')};
    border-bottom: ${props => (props.open ? '5px solid #707070' : 'none')};
    border-top: ${props => (props.open ? 'none' : '5px solid #707070')};
  }
`;

export const BoxTiposLancamentos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3px 0 0;
  padding: 6px 8.7px 3px 6px;
  width: 700px;
  height: 25px;
  background-color: #f0f0f0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
`;

export const InputLabelTiposLancamentos = styled.div`
  width: 122px;
  height: 14px;
  flex-grow: 0;
  margin: 0 353px 3px 0;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--warm-grey);
`;
