import { TableRow, Tooltip } from '@material-ui/core';
import React, { useCallback } from 'react';
import { MdDeleteForever } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';
import { TableActionButton, TableCellCenter, TableCellRight } from '../UI/Tabela/tableDefaultStyles';
import { useCCClienteDispatch } from '../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { setSimpleValuePagamentosAction } from '../../store/ContaCorrenteCliente/contaCorrenteCliente.store';


function TableBodyPagamentosComponent({ pagamento, permissoes }) {
  const dispatch = useCCClienteDispatch();

  const handleOpenExcluirLancamento = useCallback(async (idContaCorrenteCliente) => {
    dispatch(setSimpleValuePagamentosAction('openDialogExcluirLancamento', true));
    dispatch(setSimpleValuePagamentosAction('idContaCorrenteClienteSelecionado', idContaCorrenteCliente));
  }, [dispatch]);

  return (
    <TableRow>
      <TableCellCenter>
        {pagamento.isPrestado === true && (<FaCheck size={14} />)}
      </TableCellCenter>
      <TableCellCenter>
        {pagamento.data}
      </TableCellCenter>
      <TableCellCenter>
        {pagamento.isAcordo === true && (<FaCheck size={14} />)}
      </TableCellCenter>
      <TableCellCenter>
        {pagamento.isPagamento === true && (<FaCheck size={14} />)}
      </TableCellCenter>
      <TableCellCenter>
        {pagamento.isAcerto === true && (<FaCheck size={14} />)}
      </TableCellCenter>
      <TableCellRight>
        {pagamento.valor}
      </TableCellRight>
      <TableCellCenter>
        <Tooltip title="Excluir" placement="bottom">
          <span>
            <TableActionButton
              onClick={() => handleOpenExcluirLancamento(pagamento.idContaCorrenteCliente)}
              disabled={!permissoes.prestarContas || !pagamento.excluir}
            >
              <MdDeleteForever size={20} />
            </TableActionButton>
          </span>
        </Tooltip>
      </TableCellCenter>
    </TableRow>
  );
}

export const TableBodyPagamentos = TableBodyPagamentosComponent;
