import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  circle: {
    width: '12px',
    height: '12px',
    position: 'absolute',
    borderRadius: '50%',
    backgroundColor: 'grey',
    left: '-15px',
    top: '3px',
  },
  subTitle: {
    marginLeft: '14px',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  contentWrapper: {
    marginTop: '6px',
    marginLeft: '14px',
    marginBottom: '8px',
    marginRight: '37px',
  },
  content: {
    fontSize: '12px',
    paddingBottom: '2px',
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap',
  },
});
