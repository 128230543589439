import styled from 'styled-components';
import {
  Accordion as AccordionUI,
  AccordionSummary as AccordionSummaryUI,
} from '@material-ui/core';

const palletColor = {
  blue: {
    active: '#e91e63',
    activeText: '#FFFFFF',
    default: '#3f51b5',
    defaultText: '#FFFFFF',
  },
  purple: {
    active: '#CFD9EB',
    activeText: '#564872',
    default: '#564872',
    defaultText: '#FFFFFF',
  },
  purePurple: {
    active: '#CFD9EB',
    activeText: '#564872',
    default: '#F3F2FC',
    defaultText: '#564872',
  },
};

export const CustomAccordion = styled(AccordionUI)`
  border-radius: 6px;
  border: none;

  :before {
    height: 0;
  }

  .MuiAccordionDetails-root {
    padding: 8px 16px 16px 22px;
  }

  .MuiAccordionSummary-root {
    background-color: ${({ color }) => palletColor[color].default};
    color: ${({ color }) => palletColor[color].defaultText};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }

  .Mui-expanded {
    background-color: ${({ color }) => palletColor[color].active};
    color: ${({ color }) => palletColor[color].activeText};

    margin-top: 2px;
    margin-bottom: 2px;
    min-height: 0;
  }

  .MuiAccordionSummary-root .MuiIconButton-root {
    color: ${({ color }) => palletColor[color].defaultText}
  }

  .Mui-expanded >.MuiIconButton-root {
    color: ${({ color }) => palletColor[color].activeText};
  }
`;

export const AccordionSummary = styled(AccordionSummaryUI)`
  border: none;
  border-radius: 6px;
  min-height: 38px;

  transition: none;
  display: 'flex';
  align-items: 'center';

  p {
    font-weight: 500;
    font-size: 14px;
  }

  .MuiIconButton-root {
    padding: 5px;
  }

  .MuiAccordionSummary-content {
    margin: 7px 0;
  }
`;
