import React, { useCallback } from 'react';
import WrapperSubTitleForm from '../../../../../UI/WrapperSubTitleForm/WrapperSubTitleForm';
import UsuarioCrudCliente from '../UsuarioCliente';
import IconButtonTooltip from '../../../../../UI/IconButtonTooltip/IconButtonTooltip';

import useUtilStyles from '../../../../../../helpers/styles/utils.styles';
import Grid24 from '../../../../../UI/Grid24/Grid24';
import UsuarioChaveApi from '../UsuarioChaveApi';

const CONSTANTS = {
  tituloAdicionarUsuario: 'Adicionar Usuário',
  tituloDetalhes: 'Usuários',
  hintAdicionar: 'Adicionar Usuário',
  tituloChaveAPI: "Chave de autenticação de API's",
};

function UsuariosCliente({
  isEditMode,
  showPassword,
  usuarios,
  perfisUsuarioCliente,
  status,
  onAddUsuario,
  setAttribute,
  setFocus,
  setUsuariosCliente,
}) {
  const utilStyles = useUtilStyles();

  const onRemoveUsuarioClientelHandler = (index) => {
    const allUsuarios = [...usuarios];
    allUsuarios.splice(index, 1);
    setUsuariosCliente(allUsuarios);
  };

  const onAddusuarioHandler = useCallback(() => {
    onAddUsuario();
  }, [onAddUsuario]);


  const title = (
    <Grid24 isFormContainer alignItems="center">
      {isEditMode ? CONSTANTS.tituloAdicionarUsuario : CONSTANTS.tituloDetalhes}
      {isEditMode && (
        <IconButtonTooltip
          color="primary"
          type="add"
          hint={CONSTANTS.hintAdicionar}
          onClick={onAddusuarioHandler}
        />
      )}
    </Grid24>
  );

  const buildUsuarioClienteComponente = (usuario, index) => (
    <div key={index}>
      <UsuarioCrudCliente
        index={index}
        showPassword={showPassword}
        isEditMode={isEditMode}
        perfisUsuarioCliente={perfisUsuarioCliente}
        status={status}
        usuario={usuario}
        setAttribute={setAttribute}
        setUsuariosCliente={setUsuariosCliente}
        setFocus={setFocus}
        onRemove={() => onRemoveUsuarioClientelHandler(index)}
      />
      {index !== usuarios.leading - 1 && (
        <hr className={utilStyles.divider} />
      )}
    </div>
  );

  return (
    <>
      <WrapperSubTitleForm title={CONSTANTS.tituloChaveAPI}>
        <UsuarioChaveApi
          isEditMode={isEditMode}
          setFocus={setFocus}
          setAttribute={setAttribute}
        />
      </WrapperSubTitleForm>
      <WrapperSubTitleForm title={title}>
        {usuarios.map(buildUsuarioClienteComponente)}
      </WrapperSubTitleForm>
    </>
  );
}

export default React.memo(UsuariosCliente);
