import React, { useCallback, useEffect } from 'react';
import Grid24 from '../../../../UI/Grid24';
import SelectorField from '../../../../UI/Field/Selector';
import NumberInput from '../../../../UI/Field/Number/NumberInput';
import { useCrudUsuarioSelector2, useCrudUsuarioDispatch2 } from '../../../../../store/Usuario/V2/CrudUsuarioProvider';
import {
  TIPO_USUARIO, COMISSAO, TIPO_USUARIO_TITULO, SUSPENDER_USUARIO,
  COMISSAO_ADV_EXTERNO,
  COMPARTILHAR_COMISSAO,
} from './TipoUsuarioCrud.constants';
import {
  getRequiredLabel, ID_STATUS_USUARIO_INATIVO, ID_STATUS_USUARIO_ATIVO, useUtilStyles,
} from '../../../../../helpers';
import { TROCAR_USUARIO_COMERCIAL_GERENTE, TROCAR_USUARIO_COMERCIAL_MEMBRO } from '../../../../../store/Usuario/V2/crudUsuario.constans';
import {
  configureDialogReferenteEquipeAction, onChangeActions, onChangeControls, checkEnabledAdvogadoAndAssistenteExterno, changeMudancaTipoUsuarioAction,
  openDialogMudancaTipoUsuarioAction, permitirSubmitMudancaTipoUsuarioAction, zerarFasesSelecionadasComissaoAction,
} from '../../../../../store/Usuario/V2/crudUsuario.store';
import WrapperSubTitleForm from '../../../../UI/WrapperSubTitleForm';
import OptionDialogNew from '../../../../UI/Dialogs/OptionDialog/OptionDialogNew';
import CheckboxField from '../../../../UI/Field/Checkbox/CheckboxField';
import { fetchIdsTiposUsuariosAction } from '../../../../../store/Usuario/V2/crudUsuario.saga';


function TipoUsuarioCrud({
  isEditMode,
  isInsertMode,
  disabled,
  tiposUsuarios,
  idTipoUsuario,
  onFocusHandler,
  changeHandler,
  idsTiposUsuarios,
}) {
  const utilStyles = useUtilStyles();

  const dispatch = useCrudUsuarioDispatch2();
  const porcentagemComissao = useCrudUsuarioSelector2(state => state.controls.porcentagemComissao.copy());
  const tipoUsuarioTipoComissao = useCrudUsuarioSelector2(state => state.controls.tipoUsuarioTipoComissao.copy());
  const statusUsuario = useCrudUsuarioSelector2(state => state.controls.statusUsuario.copy());
  const suspenderDistribuicaoProcesso = useCrudUsuarioSelector2(state => state.controls.suspenderDistribuicaoProcesso.copy());
  const porcentagemComissaoComAdvExterno = useCrudUsuarioSelector2(state => state.controls.porcentagemComissaoComAdvExterno.copy());
  const compartilharComissao = useCrudUsuarioSelector2(state => state.controls.compartilharComissao.copy());

  const idsUsuariosMembros = useCrudUsuarioSelector2(state => state.controls.idsUsuariosMembros);
  const pertenceAlgumaEquipe = useCrudUsuarioSelector2(state => state.controls.pertenceAlgumaEquipe);

  const mudancaTipoUsuario = useCrudUsuarioSelector2(state => state.controls.mudancaTipoUsuario);
  const openDialogMudancaTipoUsuario = useCrudUsuarioSelector2(state => state.actions.openDialogMudancaTipoUsuario);


  const isGerente = useCallback(() => !isInsertMode && isEditMode && idsUsuariosMembros?.length !== 0
    && !pertenceAlgumaEquipe && idTipoUsuario?.value === idsTiposUsuarios?.idComercial,
  [isInsertMode, isEditMode, idsUsuariosMembros, pertenceAlgumaEquipe, idTipoUsuario?.value, idsTiposUsuarios]);

  const isMembro = useCallback(() => !isInsertMode && isEditMode && idsUsuariosMembros?.length === 0
    && pertenceAlgumaEquipe && (idTipoUsuario?.value === idsTiposUsuarios?.idComercial || idTipoUsuario?.value === idsTiposUsuarios?.idTelemarketing
      || idTipoUsuario?.value === idsTiposUsuarios?.idAdministrativo),
  [isInsertMode, isEditMode, idsUsuariosMembros, pertenceAlgumaEquipe, idTipoUsuario?.value, idsTiposUsuarios]);

  const needOpenDialogReferenteEquipe = useCallback((valorAlterar, origin) => {
    if (isInsertMode) {
      return false;
    }
    if (origin === 'tipoUsuario') {
      if (isGerente() && valorAlterar !== idsTiposUsuarios?.idComercial) {
        return true;
      }
      if (!isGerente() && isMembro() && (valorAlterar !== idsTiposUsuarios?.idComercial && valorAlterar !== idsTiposUsuarios?.idTelemarketing && valorAlterar !== idsTiposUsuarios?.idAdministrativo)) {
        return true;
      } if (!isGerente() && !isMembro()) {
        return false;
      }
    } else if (statusUsuario.value === ID_STATUS_USUARIO_ATIVO && valorAlterar === ID_STATUS_USUARIO_INATIVO) {
      return true;
    }
    return false;
  }, [statusUsuario, isInsertMode, isMembro, isGerente, idsTiposUsuarios]);

  const findTipoComissaoUsuarioSelecionado = useCallback(() => {
    if (tiposUsuarios && idTipoUsuario.value >= 0) {
      return tiposUsuarios.find(user => user.id === idTipoUsuario?.value)?.aux;
    }
    return 0;
  },
  [idTipoUsuario?.value, tiposUsuarios]);

  const changePermiteComissaoForTipoUsuarioSelected = useCallback(() => {
    dispatch(onChangeControls('tipoUsuarioTipoComissao', findTipoComissaoUsuarioSelecionado()));
  }, [findTipoComissaoUsuarioSelecionado, dispatch]);

  const changeTipoUsuarioHandler = useCallback((event) => {
    dispatch(fetchIdsTiposUsuariosAction());
    const { name, value } = event.target;
    if (needOpenDialogReferenteEquipe(value, 'tipoUsuario')) {
      dispatch(configureDialogReferenteEquipeAction(true, isGerente() ? TROCAR_USUARIO_COMERCIAL_GERENTE : TROCAR_USUARIO_COMERCIAL_MEMBRO));
      dispatch(onChangeActions('tipoUsuarioAlterar', value));
      return;
    }
    if (tipoUsuarioTipoComissao !== 1) {
      dispatch(zerarFasesSelecionadasComissaoAction());
    }
    if (value !== idsTiposUsuarios?.idNegociador || value !== idsTiposUsuarios?.idNegociadorExterno) {
      dispatch(onChangeControls(suspenderDistribuicaoProcesso.name, false));
    }
    dispatch(changeMudancaTipoUsuarioAction(value));
    dispatch(onChangeControls(name, value));
    dispatch(checkEnabledAdvogadoAndAssistenteExterno(value, idsTiposUsuarios));
    changePermiteComissaoForTipoUsuarioSelected();
  }, [dispatch, isGerente, changePermiteComissaoForTipoUsuarioSelected, needOpenDialogReferenteEquipe, tipoUsuarioTipoComissao, idsTiposUsuarios, suspenderDistribuicaoProcesso]);

  const confirmarOptionDialog = useCallback(() => {
    dispatch(permitirSubmitMudancaTipoUsuarioAction());
    dispatch(openDialogMudancaTipoUsuarioAction());
  }, [dispatch]);

  useEffect(() => {
    if (idTipoUsuario.value >= 0) {
      changePermiteComissaoForTipoUsuarioSelected();
    }
  }, [tiposUsuarios, idTipoUsuario?.value, changePermiteComissaoForTipoUsuarioSelected]);

  return (
    <Grid24 container className={utilStyles.mb7mt20}>
      <OptionDialogNew
        open={mudancaTipoUsuario && isEditMode && openDialogMudancaTipoUsuario}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={() => dispatch(openDialogMudancaTipoUsuarioAction())}
        onClickConfirm={() => confirmarOptionDialog()}
      >
        <div style={{ fontSize: '18px' }}>
          <p>
            A alteração do Tipo do Usuário poderá impactar a política de comissão.
            <br />
            Confirma essa operação?
          </p>
        </div>
      </OptionDialogNew>
      <WrapperSubTitleForm title={TIPO_USUARIO_TITULO}>
        <Grid24 container isFormContainer spacing="1" className={utilStyles.mb7}>
          <Grid24 xs={24} sm={4}>
            <SelectorField
              isForm
              fullWidth
              disabled={disabled}
              label={getRequiredLabel(TIPO_USUARIO, !disabled)}
              items={tiposUsuarios}
              name={idTipoUsuario.name}
              value={idTipoUsuario.value}
              error={idTipoUsuario.error}
              errorMessage={idTipoUsuario.errorMessage}
              onChange={changeTipoUsuarioHandler}
            />
          </Grid24>
          {idTipoUsuario.value === 2 && (
            <Grid24 xs={24} sm={20}>
              <Grid24 xs={24} sm={4}>
                <NumberInput
                  label={getRequiredLabel(`${COMISSAO_ADV_EXTERNO}`, !disabled)}
                  isForm
                  disabled={tipoUsuarioTipoComissao.value !== 1 || disabled}
                  sideText="(%)"
                  numberType="percentage"
                  maxValue={100}
                  name={porcentagemComissaoComAdvExterno.name}
                  value={porcentagemComissaoComAdvExterno.value}
                  error={porcentagemComissaoComAdvExterno.error}
                  errorMessage={porcentagemComissaoComAdvExterno.errorMessage}
                  onChange={changeHandler}
                  onFocus={onFocusHandler}
                />
              </Grid24>
              <Grid24 xs={24} sm={8} style={{ marginTop: '14px', marginLeft: '14px' }}>
                <CheckboxField
                  label={COMPARTILHAR_COMISSAO}
                  disabled={disabled}
                  name={compartilharComissao.name}
                  checked={(compartilharComissao.value)}
                  onChange={changeHandler}
                />
              </Grid24>
            </Grid24>
          )}
          {tipoUsuarioTipoComissao.value === 2 && (
            <Grid24 xs={24} sm={2}>
              <NumberInput
                label={getRequiredLabel(COMISSAO, !disabled)}
                acceptEmptyValue
                isForm
                disabled={tipoUsuarioTipoComissao.value !== 2 || disabled}
                sideText="(%)"
                numberType="percentage"
                maxValue={100}
                name={porcentagemComissao.name}
                value={porcentagemComissao.value}
                error={porcentagemComissao.error}
                errorMessage={porcentagemComissao.errorMessage}
                onChange={changeHandler}
                onFocus={onFocusHandler}
              />
            </Grid24>
          )}
          { (idTipoUsuario.value === idsTiposUsuarios?.idNegociador || idTipoUsuario.value === idsTiposUsuarios?.idNegociadorExterno) && (
            <Grid24 xs={24} sm={8} style={{ marginTop: '14px', marginLeft: '14px' }}>
              <CheckboxField
                label={SUSPENDER_USUARIO}
                disabled={disabled}
                name={suspenderDistribuicaoProcesso.name}
                checked={(suspenderDistribuicaoProcesso.value)}
                onChange={changeHandler}
              />
            </Grid24>
          )
            }
        </Grid24>
      </WrapperSubTitleForm>
    </Grid24>
  );
}

export default TipoUsuarioCrud;
