import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  wrapper: {
    display: 'inline',
    '& .MuiButtonBase-root': {
      marginRight: '5px',
      padding: '5px',
      color: 'black',
    },
    '& .MuiButtonBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26) !important',
      backgroundColor: 'transparent',
    },
  },
});
