import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import DialogNew from '../../../layouts/FormDialog/DialogNew';

export default makeStyles({
  numeroProcessoStyle: {
    color: '#000',
    opacity: 0.8,
    fontWeight: '500',
    fontSize: '15px',
    marginTop: '24px',
    marginRight: '10px',
  },
  boxPesquisa: {
    marginTop: '30px',
  },
  formPesquisa: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  boxDadosPesquisa: {
    marginTop: '24px',
  },
  textoDadoPesquisa: {
    fontSize: '14px',
  },
  buttonPesquisar: {
    '&:disabled': {
      backgroundColor: grey,
    },
  },
});

export const DialogNewErro = styled(DialogNew)`
& .MuiDialog-paperWidthSm {
  width: 585px;
  height: 133px;
}
`;

export const ButtonErro = styled(Button)`
&.MuiButton-root {
    color: #034ddc;

    &.Mui-disabled {
      color: #000000;
      opacity: 0.26;
    }
  }
`;
