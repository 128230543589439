import { buildSettingsTable, buildSnackbar } from '../../../helpers/factory/global.factory';
import FieldModel from '../../../models/FieldModel';
import {
  ON_CHANGE_DATA_EXPIRACAO_FIELDS,
  ON_CHANGE_REQUEST_FIELDS,
  ON_FOCUS_REQUEST_FIELDS,
  ON_UPDATE_REQUEST_FIELDS,
  RESET_SCREEN,
  SET_CACHE_REQUEST_FIELDS,
  SET_DATAS_CAMPOS_OBRIGATORIOS,
  SET_ESTATISCAS_HISTORICO,
  SET_PAGE,
  SET_ROWS_PER_PAGE,
  SET_SNACKBAR_HISTORICO,
  SET_STORE_VALUE,
  SET_TABLE_INFO,
} from './historicoAS.constants';
import {
  getDate30daysAgoCorridos, getDateNow, toAmericanDate,
} from '../../../helpers/utils/utils';


function buildRequestFields() {
  return {
    periodoAberturaInicial: new FieldModel({
      name: 'periodoAberturaInicial',
      value: getDate30daysAgoCorridos(),
    }),
    periodoAberturaFinal: new FieldModel({
      name: 'periodoAberturaFinal',
      value: getDateNow(),
    }),
    nroProcesso: new FieldModel({
      name: 'nroProcesso',
    }),
    nomeDevedor: new FieldModel({
      name: 'nomeDevedor',
    }),
  };
}

function buildEstatisticas() {
  return {
    acessoSite: 0,
    notificados: 0,
    qtdeSmsEnviado: 0,
    qtdeEmailEnviados: 0,
  };
}

function buildDataExpiracaoFields() {
  return {
    dataExpiracaoAcordoSeguro: new FieldModel({ name: 'dataExpiracaoAcordoSeguro' }),
  };
}

function initiateStates() {
  return {
    requestFields: buildRequestFields(),
    settingsTable: buildSettingsTable(),
    estatisticas: buildEstatisticas(),
    cacheRequestFields: null,
    snackbarHistoricoAS: buildSnackbar(),
    dataExpiracaoFields: buildDataExpiracaoFields(),
    estagio: null,
    nroProcesso: null,
    loading: false,
    openPesquisaAvancada: false,
    openDialogDataExpiracao: false,
  };
}

export const clearPesquisaHistoricoASAction = () => ({
  type: RESET_SCREEN,
});

export const setEstatisticasAction = (acessoSite, notificados, qtdeSmsEnviado, qtdeEmailEnviados) => ({
  type: SET_ESTATISCAS_HISTORICO,
  acessoSite,
  notificados,
  qtdeSmsEnviado,
  qtdeEmailEnviados,
});

export const setStoreValuesAction = (name, value) => ({
  type: SET_STORE_VALUE,
  name,
  value,
});

export const onChangeRequestFieldsAction = (name, value) => ({
  type: ON_CHANGE_REQUEST_FIELDS,
  name,
  value,
});

export const onFocusRequestFieldsAction = name => ({
  type: ON_FOCUS_REQUEST_FIELDS,
  name,
});

export const setRowsPerPageAndPageAction = (rowsPerPage, page) => ({
  type: SET_ROWS_PER_PAGE,
  rowsPerPage,
  page,
});

export const setPageAction = page => ({
  type: SET_PAGE,
  page,
});

export const setTableInfoAction = (content, page, rowsPerPage, totalElements) => ({
  type: SET_TABLE_INFO,
  tableInfo: buildSettingsTable(content, page, rowsPerPage, totalElements),
});

export const setSnackbarHistoricoASAction = (open, variant, message) => ({
  type: SET_SNACKBAR_HISTORICO,
  open,
  variant,
  message,
});

export const onUpdateRequestFieldsAction = requestFields => ({
  type: ON_UPDATE_REQUEST_FIELDS,
  requestFields,
});

export const setCacheRequestFieldsAction = () => ({
  type: SET_CACHE_REQUEST_FIELDS,
});

export const setDatasCamposObrigatoriosAction = name => ({
  type: SET_DATAS_CAMPOS_OBRIGATORIOS,
  name,
});

export const onChangeDataExpiracaoFieldsAction = (name, value) => ({
  type: ON_CHANGE_DATA_EXPIRACAO_FIELDS,
  name,
  value,
});

function resetScreenHandler() {
  return initiateStates();
}


function onChangeDataExpiracaoFieldsHandler(states, actions) {
  const { name, value } = actions;
  const modelData = states.dataExpiracaoFields[name].getNewModel(value);

  return {
    ...states,
    dataExpiracaoFields: {
      ...states.dataExpiracaoFields,
      [actions.name]: modelData,
    },
  };
}

function setDatasCamposObrigatoriosHandler(states, actions) {
  const modelData = states.requestFields[actions.name];

  modelData.error = true;
  modelData.errorMessage = 'Campo Obrigatório';

  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [actions.name]: modelData,
    },
  };
}

function setCacheRequestFieldsHandler(states) {
  const periodoAberturaInicial = states.requestFields.periodoAberturaInicial.getValueNotEmpty();
  const periodoAberturaFinal = states.requestFields.periodoAberturaFinal.getValueNotEmpty();
  const nroProcesso = states.requestFields.nroProcesso.getValueNotEmpty();
  const nomeDevedor = states.requestFields.nomeDevedor.getValueNotEmpty();

  return {
    ...states,
    cacheRequestFields: {
      periodoAberturaInicial: periodoAberturaInicial ? toAmericanDate(periodoAberturaInicial) : periodoAberturaInicial,
      periodoAberturaFinal: periodoAberturaFinal ? toAmericanDate(periodoAberturaFinal) : periodoAberturaFinal,
      nroProcesso,
      nomeDevedor,
    },
  };
}

function onUpdateRequestFieldsHandler(states, actions) {
  const { requestFields } = actions;
  return {
    ...states,
    requestFields,
  };
}

function setSnackbarValueHandler(states, actions) {
  const snackbar = { ...states.snackbar, open: actions.open };
  if (actions.variant || actions.message) {
    snackbar.variant = actions.variant;
    snackbar.message = actions.message;
  }
  return {
    ...states,
    snackbarHistoricoAS: snackbar,
  };
}

function changeTableInfoHandler(states, actions) {
  return {
    ...states,
    settingsTable: { ...actions.tableInfo },
  };
}

function onChangePageHandler(states, actions) {
  return {
    ...states,
    settingsTable: {
      ...states.settingsTable,
      page: actions.page,
    },
  };
}

function onChangeRowsPerPageAndPageHandler(states, actions) {
  return {
    ...states,
    settingsTable: {
      ...states.settingsTable,
      rowsPerPage: actions.rowsPerPage,
      page: actions.page,
    },
  };
}


function setStoreValueHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    [name]: value,
  };
}

function setEstatisticasHandler(states, actions) {
  const {
    acessoSite, notificados, qtdeSmsEnviado, qtdeEmailEnviados,
  } = actions;
  return {
    ...states,
    estatisticas: {
      acessoSite,
      notificados,
      qtdeSmsEnviado,
      qtdeEmailEnviados,
    },
  };
}

function onChangeRequestFieldsHandler(states, actions) {
  const { name, value } = actions;
  const model = states.requestFields[name].getNewModel(value);

  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [name]: model,
    },
  };
}


function onFocusRequestFieldsHandler(states, actions) {
  const { name } = actions;
  const model = states.requestFields[name].onFocus();

  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [name]: model,
    },
  };
}

export default (states = initiateStates(), actions) => {
  switch (actions.type) {
    case ON_CHANGE_REQUEST_FIELDS:
      return onChangeRequestFieldsHandler(states, actions);
    case ON_FOCUS_REQUEST_FIELDS:
      return onFocusRequestFieldsHandler(states, actions);
    case SET_STORE_VALUE:
      return setStoreValueHandler(states, actions);
    case SET_ESTATISCAS_HISTORICO:
      return setEstatisticasHandler(states, actions);
    case SET_ROWS_PER_PAGE:
      return onChangeRowsPerPageAndPageHandler(states, actions);
    case SET_PAGE:
      return onChangePageHandler(states, actions);
    case SET_TABLE_INFO:
      return changeTableInfoHandler(states, actions);
    case SET_SNACKBAR_HISTORICO:
      return setSnackbarValueHandler(states, actions);
    case ON_UPDATE_REQUEST_FIELDS:
      return onUpdateRequestFieldsHandler(states, actions);
    case SET_CACHE_REQUEST_FIELDS:
      return setCacheRequestFieldsHandler(states);
    case SET_DATAS_CAMPOS_OBRIGATORIOS:
      return setDatasCamposObrigatoriosHandler(states, actions);
    case ON_CHANGE_DATA_EXPIRACAO_FIELDS:
      return onChangeDataExpiracaoFieldsHandler(states, actions);
    case RESET_SCREEN:
      return resetScreenHandler();
    default: return states;
  }
};
