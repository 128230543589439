import FieldModel from '../../models/FieldModel';
import { getAttributeExistingObject } from '../utils/utils';

export function buildSimpleEmail(email) {
  return {
    idBorderoEmail: new FieldModel({
      name: 'idBorderoEmail', value: getAttributeExistingObject(email, 'idBorderoEmail'),
    }),
    email: new FieldModel({
      name: 'email',
      value: getAttributeExistingObject(email, 'email'),
      maxLength: 70,
    }),
    principal: new FieldModel({
      name: 'principal', value: getAttributeExistingObject(email, 'principal', false),
    }),
  };
}

export function buildSimpleEmailCliente(email) {
  return {
    idEmailCliente: new FieldModel({
      name: 'idEmailCliente', value: getAttributeExistingObject(email, 'idEmailCliente'),
    }),
    email: new FieldModel({
      name: 'email',
      value: getAttributeExistingObject(email, 'email'),
      maxLength: 70,
    }),
    permitirPadrao: new FieldModel({
      name: 'permitirPadrao',
      value: getAttributeExistingObject(email, 'permitirPadrao', false),
    }),
    permitirBoleto: new FieldModel({
      name: 'permitirBoleto',
      value: getAttributeExistingObject(email, 'permitirBoleto', false),
    }),
    permitirNotaFiscal: new FieldModel({
      name: 'permitirNotaFiscal',
      value: getAttributeExistingObject(email, 'permitirNotaFiscal', false),
    }),
    permitirPrestacaoConta: new FieldModel({
      name: 'permitirPrestacaoConta',
      value: getAttributeExistingObject(email, 'permitirPrestacaoConta', false),
    }),
  };
}

export function buildSimpleEmailsCliente(emails) {
  return (emails) ? emails.map(buildSimpleEmailCliente) : [];
}

export function buildSimpleEmails(emails) {
  if (!emails) {
    return [];
  }
  return emails
    .sort((origin, to) => to.principal - origin.principal)
    .map(buildSimpleEmail);
}
