import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import useStyles from './CheckboxField.styles';

function CheckboxField({
  label,
  disabled = false,
  propsFormLabel,
  ...props
}) {
  const styles = useStyles({ hasLabel: label });

  // @TODO Investigar no PrazoExpiradoASPage.jsx o por que precisa setar o false nesse componente quando chama o checkbox.
  const buildCheckbox = () => (
    <Checkbox
      color="primary"
      classes={{ root: styles.root, checked: styles.checkedColor }}
      {...props}
    />
  );

  return (
    <FormControlLabel
      disabled={disabled}
      classes={{ root: styles.rootFormControl, disabled: styles.disbaledLabel }}
      control={buildCheckbox()}
      label={label}
      {...propsFormLabel}
    />
  );
}

export default React.memo(CheckboxField);
