import React from 'react';


import DadosAcesso from './DadosAcesso/DadosAcesso';
import Grid24 from '../../../UI/Grid24/Grid24';
import DadosBancarios from '../DadosCadastrais/DadosBancarios/DadosBancariosCrudUsuario';
import AdvogadoUsuario from '../DadosCadastrais/Advogado/AdvogadoCrudUsuario';
import RequisicoesCrudUsuario from '../DadosCadastrais/Requisicoes/RequisicoesCrudUsuario';

function DadosComplementares({
  disable = false,
  showPassword = false,
  tiposConta,
  bancos,
  tiposDocumento,
  changeHandler,
  onFocusHandler,
  changeCheckedHandler,
}) {
  return (
    <Grid24 container isFormContainer spacing="1">
      <Grid24 xs={24} sm={7}>
        <DadosAcesso
          disabled={disable}
          showPassword={showPassword}
          changeHandler={changeHandler}
          onFocusHandler={onFocusHandler}
        />
      </Grid24>
      <Grid24 xs={24} sm={17}>
        <DadosBancarios
          disabled={disable}
          tiposConta={tiposConta}
          bancos={bancos}
          tiposDocumento={tiposDocumento}
          changeCheckedHandler={changeCheckedHandler}
          changeHandler={changeHandler}
          onFocusHandler={onFocusHandler}
        />
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={24}>
          <AdvogadoUsuario disabled={disable} changeHandler={changeHandler} />
        </Grid24>
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={24}>
          <RequisicoesCrudUsuario disabled={disable} />
        </Grid24>
      </Grid24>
    </Grid24>
  );
}

export default DadosComplementares;
