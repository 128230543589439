import {CORE, gateway, gatewayImage} from '../../api';
import { getUriParams } from '../../../helpers/utils/utils';

export const getPossivelCliente = idCliente => gateway.get(`${CORE}/possiveis-clientes/${idCliente}`);

export const insertPossivelCliente = payload => gateway.post(`${CORE}/possiveis-clientes`, payload);

export const updatePossivelCliente = (payload, idCliente) => gateway.put(`${CORE}/possiveis-clientes/${idCliente}`, payload);

export function getPossiveisClientes(
  cidade, documento, idCliente, idEstado, nome, idFilial, dataFuInicial, dataFuFinal, page, size,
) {
  const uri = getUriParams({
    cidade,
    documento,
    idCliente,
    idEstado,
    nome,
    idFilial,
    dataFuInicial,
    dataFuFinal,
    page,
    size,
  });

  return gateway.get(`${CORE}/possiveis-clientes?${uri}`);
}

export function deletePossivelCliente(idCliente) {
  return gateway.delete(`${CORE}/possiveis-clientes/${idCliente}`);
}

export function migrarPossivelCliente(payloadCliente, idCliente) {
  return gateway.put(`${CORE}/possiveis-clientes/${idCliente}?operacao=MIGRAR`, payloadCliente);
}

export function obterAcompanhamentoPossivelClientePorIdAcompanhamento(idCliente, idClienteAcompanhamento) {
  return gateway.get(`${CORE}/possiveis-clientes/${idCliente}/acompanhamentos/${idClienteAcompanhamento}`);
}

export function getAcompanhamentoPossivelCliente(idCliente, conteudo, dataEnvioInicial, page, sort = 'data,ASC', size = 25) {
  const uri = getUriParams({
    dataEnvioInicial, conteudo, page, size, sort,
  });
  return gateway.get(`${CORE}/possiveis-clientes/${idCliente}/acompanhamentos?${uri}`);
}

export function insertAcompanhamentoPossivelCliente(idCliente, payload) {
  return gateway.post(`${CORE}/possiveis-clientes/${idCliente}/acompanhamentos`, payload);
}

export function removeFollowUpPossivelCliente(idCliente, idClienteAcompanhamento) {
  return gateway.delete(`${CORE}/possiveis-clientes/${idCliente}/acompanhamentos/${idClienteAcompanhamento}`);
}

export function obterLogotipoPossivelCliente(idCliente, format = 'BASE_64') {
  return gatewayImage.get(`${CORE}/possiveis-clientes/${idCliente}/foto`, { params: { format } });
}

export function saveLogotipoPossivelCliente(idCliente, image) {
  const data = new FormData();
  data.append('logoAcordoSeguro', image);
  return gateway.post(`${CORE}/possiveis-clientes/${idCliente}/foto`, data);
}
