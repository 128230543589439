import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { withRouter } from 'react-router-dom';

import ZoomInIcon from '@material-ui/icons/ZoomIn';

import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';

import withTokenInterceptor from '../../../hoc/withTokenInterceptor/withTokenInterceptor';
import TooltipUI from '../../../components/UI/Tooltip/Tooltip';
import CheckboxField from '../../../components/UI/Field/Checkbox/CheckboxField';
import SearchButton from '../../../components/UI/Button/Search/SearchButton';
import ButtonUI from '../../../components/UI/Button/ButtonUI';
import Table from '../../../components/UI/Tabela/PaginadaSimples/Table';
import InputField from '../../../components/UI/Field/Input/InputField';
import PeriodField from '../../../components/UI/Field/Period/PeriodField';
import SelectorField from '../../../components/UI/Field/Selector/SelectorField';
import Grid24 from '../../../components/UI/Grid24/Grid24';
import CardLayout from '../../../layouts/CardLayout/CardLayout';
import PermissionIconButton from '../../../components/UI/Button/Permission/IconButton/PermissionIconButton';
import { FIRST_PAGE, SEARCH_TABLE_TITLE } from '../../../helpers/constants/global.constants';


import {
  closeDialogAction,
  onChangeAllCheckboxAction,
  onChangeSearchCriteriasAction,
  onChangeTableRowCheckboxAction,
  onFocusSearchCriteriasAction,
  setDialogInfoAction,
  setInformacoesProcessamentoBorderoAction,
  setProcessamentoDialogAction,
  setSelectAllAction,
  setStatesValueAction,
  setStatesValueProcessarBorderoAction,
} from '../../../store/Borderos/Processar/processarBorderos.store';

import {
  findBorderosAction,
  findEstatisticasAction,
  getInformacoesGeraisProcessamentoBorderoAction,
  getQtdMaximaFalhasBorderoAction,
  onChangeTableInfoAction,
  onRecoverBorderosAction,
  onRemoveBorderosAction,
} from '../../../store/Borderos/Processar/processarBorderos.saga';

import { initializePageAction } from '../../../store/theme.actions';
import { getNewPage } from '../../../helpers/utils/utils';
import { STATUS_PROCESSAMENTO_BORDERO } from '../../../helpers/constants/enums.constants';


import constants from './ProcessarBorderoPage.constants';
import useStyles from './ProcessarBorderoPage.styles';
import ProcessamentoBordero from '../../../components/Bordero/Processamento/ProcessamentoBordero';
import EstatisticasBordero from '../../../components/Bordero/Processamento/Estatisca/EstatisticasBordero';
import { findBorderoTipoStatusAction } from '../../../store/Global/LoadSelectors/loadSelectors.saga';
import ZoomAlertIcon from '../../../assets/Icons/ZoomAlertIcon';
import { buildTableHeader } from './ProcessarBordero.factory';
import OptionDialogNew from '../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';

function buildInspecionarButton(onClick, qtdFalhaAtual, qntdFalhaPermitida) {
  const changeColor = qtdFalhaAtual === qntdFalhaPermitida;

  return (
    <PermissionIconButton
      title={constants.INSPECIONAR}
      userAllow="U"
      placement="bottom-end"
      onClick={onClick}
    >
      {changeColor ? <ZoomAlertIcon /> : <ZoomInIcon />}
    </PermissionIconButton>
  );
}

function ProcessarBordero({ history, location }) {
  const styles = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector(states => states.processarBorderos.loading);
  const openProcessamentoModal = useSelector(states => states.processarBorderos.openProcessamentoModal);
  const idCliente = useSelector(states => states.processarBorderos.searchCriterias.idCliente.copy());
  const dataInicial = useSelector(states => states.processarBorderos.searchCriterias.dataInicial.copy());
  const dataFinal = useSelector(states => states.processarBorderos.searchCriterias.dataFinal.copy());
  const status = useSelector(states => states.processarBorderos.searchCriterias.status.copy());
  const tableInfo = useSelector(states => ({ ...states.processarBorderos.tableInfo }));
  const selectedIds = useSelector(states => ({ ...states.processarBorderos.selectedIds }));
  const selectAll = useSelector(states => states.processarBorderos.selectAll);
  const processamentoDialog = useSelector(states => ({ ...states.processarBorderos.processamentoDialog }));
  const searchCriterias = useSelector(states => states.processarBorderos.definedSearchCriterias);
  const dialog = useSelector(states => ({ ...states.processarBorderos.dialog }));
  const borderoTipoStatus = useSelector(states => states.selectors.borderoTipoStatus);
  const qtdeMaximoTentativasBorderoParametro = useSelector(states => states.processarBorderos.qtdeMaximoTentativasBorderoParametro);
  const columnOrdenacao = useSelector(states => states.processarBorderos.tableInfo.columnOrdenacao);
  const arrowDirection = useSelector(states => states.processarBorderos.tableInfo.arrowDirection);

  const selectedIdsRef = useRef();
  selectedIdsRef.current = selectedIds;
  const [disableCheckBoxTable, setDisableCheckBoxTable] = useState(false);

  const setColumnOrdenacao = useCallback((column) => {
    dispatch(setStatesValueProcessarBorderoAction('columnOrdenacao', column));
  }, [dispatch]);

  const setArrowOrdenacao = useCallback((internalReturn) => {
    dispatch(setStatesValueProcessarBorderoAction('arrowDirection', internalReturn));
  }, [dispatch]);


  // Buscar e preencher estatísticas e titulo da página
  useEffect(() => {
    dispatch(initializePageAction(constants.TITLE));
    dispatch(findEstatisticasAction());
    dispatch(findBorderoTipoStatusAction());
    dispatch(getQtdMaximaFalhasBorderoAction());
  }, [dispatch, location.refreshPage]);

  // Desabilita ou habilita os checkbox na tabela
  useEffect(() => {
    if (searchCriterias && searchCriterias.status === STATUS_PROCESSAMENTO_BORDERO.EM_PROCESSAMENTO) {
      setDisableCheckBoxTable(true);
    } else {
      setDisableCheckBoxTable(false);
    }
  }, [searchCriterias, setDisableCheckBoxTable]);

  // Controlar a lógica de seleção de todos os itens da tabela
  useEffect(() => {
    const valuesCheckboxTableRow = Object.values(selectedIdsRef.current);
    const unselectedCheckbox = valuesCheckboxTableRow.filter(
      selected => selected,
    );
    let checkedAll = false;
    if ((unselectedCheckbox.length === valuesCheckboxTableRow.length) && unselectedCheckbox.length > 0) {
      checkedAll = true;
    }
    dispatch(setSelectAllAction(checkedAll));
  }, [dispatch, tableInfo.content]);

  const changeTableRowCheckboxHandler = useCallback((event) => {
    const { name, checked } = event.target;
    dispatch(onChangeTableRowCheckboxAction(name, checked));
  }, [dispatch]);

  const goToInspectHandler = useCallback(
    (idBordero) => {
      history.push(`/inspecionar/${idBordero}`);
    },
    [history],
  );

  const getCheckedValue = (attributeCondition, componentValue) => {
    if (attributeCondition) {
      return false;
    }
    return componentValue;
  };

  const buildCheckbox = useCallback((checked, name) => (
    <CheckboxField
      name={name}
      checked={getCheckedValue(disableCheckBoxTable, checked)}
      onChange={changeTableRowCheckboxHandler}
      disabled={disableCheckBoxTable}
    />
  ), [disableCheckBoxTable, changeTableRowCheckboxHandler]);

  const buildTable = useCallback((content, index) => {
    if (content.length === 0) {
      return [];
    }
    const clickInspectHandler = () => goToInspectHandler(content.idBorderoDevedor);
    const qtdFalha = content.qtdeFalhaProcessamento;
    return {
      id: content.idBorderoDevedor,
      values: {
        ...content,
        selecionarTodos: buildCheckbox(content.checked, `${index}`),
        inspecionar: buildInspecionarButton(clickInspectHandler, qtdFalha, qtdeMaximoTentativasBorderoParametro),
      },
    };
  }, [goToInspectHandler, qtdeMaximoTentativasBorderoParametro, buildCheckbox]);

  const changeHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeSearchCriteriasAction(name, value));
  }, [dispatch]);

  const onFocusHandler = useCallback((event) => {
    const { name } = event.target;
    dispatch(onFocusSearchCriteriasAction(name));
  }, [dispatch]);

  const changeSelectAllHandler = useCallback((event) => {
    const { checked } = event.target;
    dispatch(onChangeAllCheckboxAction(checked));
  }, [dispatch]);

  const changeTablePageHandler = useCallback((event, page) => {
    dispatch(onChangeTableInfoAction(searchCriterias, page, tableInfo.rowsPerPage, selectedIds));
  }, [dispatch, searchCriterias, tableInfo.rowsPerPage, selectedIds]);

  const changeTableRowsPerPageHandler = useCallback((event) => {
    const { value } = event.target;
    const { rowsPerPage, page } = tableInfo;
    const newPage = getNewPage(rowsPerPage, page, value);
    dispatch(onChangeTableInfoAction(searchCriterias, newPage, value, selectedIds));
  }, [dispatch, searchCriterias, tableInfo, selectedIds]);

  const searchBorderosHandler = useCallback((event) => {
    event.preventDefault();
    const criterias = {
      idCliente, dataInicial, dataFinal, status,
    };
    dispatch(findBorderosAction(criterias, FIRST_PAGE, tableInfo.rowsPerPage));
    dispatch(findEstatisticasAction());
  }, [dispatch, idCliente, dataInicial, dataFinal, status, tableInfo]);

  const getSelectBorderos = useCallback(
    () => Object.keys(selectedIdsRef.current)
      .filter(ids => selectedIdsRef.current[ids])
      .map(ids => +ids),
    [],
  );

  const hasSelectedIds = useCallback(() => getSelectBorderos().length > 0, [getSelectBorderos]);

  const getDefinedSearchParams = useCallback(
    () => ({
      ...searchCriterias,
      page: 0,
      size: tableInfo.rowsPerPage,
    }),
    [searchCriterias, tableInfo.rowsPerPage],
  );

  const removeBorderosHandler = useCallback(() => {
    const idsBorderosDevedor = getSelectBorderos();
    const params = getDefinedSearchParams();
    dispatch(onRemoveBorderosAction(idsBorderosDevedor, params, selectedIds));
  }, [dispatch, getDefinedSearchParams, getSelectBorderos, selectedIds]);

  const recoverBorderosHandler = useCallback(() => {
    const idsBorderosDevedor = getSelectBorderos();
    const params = getDefinedSearchParams();
    dispatch(onRecoverBorderosAction(idsBorderosDevedor, params, selectedIds));
  }, [dispatch, getDefinedSearchParams, getSelectBorderos, selectedIds]);

  const openDialogRemoveHandler = useCallback(() => {
    dispatch(setDialogInfoAction(
      true,
      constants.DIALOG_REMOVE_MESSAGE,
      removeBorderosHandler,
    ));
  }, [dispatch, removeBorderosHandler]);

  const openDialogRecuperarHandler = useCallback(() => {
    dispatch(setDialogInfoAction(true, constants.DIALOG_RECOVERY_MESSAGE, recoverBorderosHandler));
  }, [dispatch, recoverBorderosHandler]);

  const closeDialogHandler = useCallback(() => {
    dispatch(closeDialogAction());
  }, [dispatch]);

  const tableContent = useMemo(
    () => tableInfo.content.map((bordero, index) => buildTable(
      bordero,
      index,
    )),
    [tableInfo.content, buildTable],
  );

  const buildCheckboxSelectAll = useCallback(() => (
    <TooltipUI title={disableCheckBoxTable ? '' : constants.SELECIONAR_TODOS}>
      <div>
        <CheckboxField
          checked={getCheckedValue(disableCheckBoxTable, selectAll)}
          onChange={changeSelectAllHandler}
          disabled={disableCheckBoxTable}
        />
      </div>
    </TooltipUI>
  ), [disableCheckBoxTable, changeSelectAllHandler, selectAll]);


  const isDisabledProcessar = useCallback(() => {
    if (searchCriterias) {
      return (
        searchCriterias.status !== STATUS_PROCESSAMENTO_BORDERO.AGUARDANDO_PROCESSAMENTO
        || tableContent.length === 0
        || !hasSelectedIds()
      );
    }
    return true;
  }, [searchCriterias, tableContent, hasSelectedIds]);

  const isDisabledExcluir = useCallback(() => {
    if (searchCriterias) {
      return (
        (searchCriterias.status !== STATUS_PROCESSAMENTO_BORDERO.AGUARDANDO_PROCESSAMENTO
          && searchCriterias.status !== STATUS_PROCESSAMENTO_BORDERO.PROCESSADO)
        || tableContent.length === 0
        || !hasSelectedIds()
      );
    }
    return true;
  }, [searchCriterias, tableContent, hasSelectedIds]);

  const isDisabledRecuperar = useCallback(() => {
    if (searchCriterias) {
      if (!hasSelectedIds()) {
        return true;
      }
      return searchCriterias.status !== STATUS_PROCESSAMENTO_BORDERO.EXCLUIDO
        || tableContent.length === 0;
    }
    return true;
  }, [searchCriterias, tableContent, hasSelectedIds]);

  const openProcessamentoModalHandler = useCallback(() => {
    const selectBorderos = getSelectBorderos();
    dispatch(getInformacoesGeraisProcessamentoBorderoAction(selectBorderos));
  }, [dispatch, getSelectBorderos]);

  const onCloseProcessamentoModalHandler = useCallback(() => {
    dispatch(setStatesValueAction('openProcessamentoModal', false));
    dispatch(setInformacoesProcessamentoBorderoAction(0, 0));
  }, [dispatch]);

  const onClickConfirmProcessamentoDialogHandler = useCallback((event) => {
    if (processamentoDialog.variant === 'success') {
      searchBorderosHandler(event);
    }
    dispatch(setProcessamentoDialogAction(false, null, ''));
  }, [dispatch, searchBorderosHandler, processamentoDialog]);

  const onClickOrdernacaoColumn = useCallback(() => {
    const criterias = {
      idCliente, dataInicial, dataFinal, status,
    };
    if (tableContent.length > 0) {
      dispatch(findBorderosAction(criterias, FIRST_PAGE, tableInfo.rowsPerPage));
    }
  }, [dispatch, tableContent, idCliente, dataInicial, dataFinal, status, tableInfo.rowsPerPage]);


  return (
    <CardLayout onlyVisualization={false} showLoading={loading}>
      <OptionDialogNew
        open={dialog.open}
        onClose={closeDialogHandler}
        onClickConfirm={dialog.action}
        onClickCancel={closeDialogHandler}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>
            {dialog.message}
          </p>
        </div>
      </OptionDialogNew>
      <OptionDialogNew
        open={processamentoDialog.open}
        confirmLabel="Ok, ENTENDI"
        onClickConfirm={onClickConfirmProcessamentoDialogHandler}
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>
            {processamentoDialog.message
              ? (
                <p>
                  {processamentoDialog.message}
                </p>
              )
              : (
                <>
                  <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
                    Solicitação realizada com sucesso!
                  </div>
                  <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
                    Os Borderôs foram enviados para processamento.
                  </div>
                </>
              )
            }
          </p>
        </div>
      </OptionDialogNew>
      <ProcessamentoBordero
        open={openProcessamentoModal}
        onClose={onCloseProcessamentoModalHandler}
      />

      <Grid24 container>
        <EstatisticasBordero />
        <Grid24
          component="form"
          spacing="1"
          container
          xs={24}
          sm={17}
          alignSelf="flex-end"
          justify="flex-end"
          onSubmit={searchBorderosHandler}
        >
          <Grid24 xs={24} sm={8}>
            <SelectorField
              loading={borderoTipoStatus.loading}
              label={constants.STATUS}
              items={borderoTipoStatus.selector}
              name={status.name}
              value={status.value}
              onChange={changeHandler}
            />
          </Grid24>
          <Grid24 xs={24} sm={8}>
            <PeriodField
              label={constants.PERIODO}
              firstField={dataInicial}
              lastField={dataFinal}
              onChange={changeHandler}
              onFocus={onFocusHandler}
            />
          </Grid24>
          <Grid24 xs={24} sm={5}>
            <InputField
              label={constants.ID_CLIENTE}
              maxLength={idCliente.maxLength}
              name={idCliente.name}
              value={idCliente.value}
              onChange={changeHandler}
            />
          </Grid24>
          <Grid24
            container
            xs={24}
            sm={2}
            justify="flex-end"
            alignContent="flex-end"
            style={{ paddingRight: '0' }}
          >
            <SearchButton type="submit" />
          </Grid24>
        </Grid24>
        <Grid24
          container
          xs={24}
          justify="flex-end"
          className={styles.actions}
          style={{ paddingRight: '0' }}
        >
          <Grid24 item xs={24} justify="flex-end">
            <div className={styles.buttonWrapper}>
              <ButtonUI
                onClick={openProcessamentoModalHandler}
                disabled={isDisabledProcessar()}
              >
                {constants
                  .PROCESSAR}
              </ButtonUI>

              <ButtonUI
                color="error"
                disabled={isDisabledExcluir()}
                onClick={openDialogRemoveHandler}
              >
                {constants
                  .EXCLUIR}
              </ButtonUI>
              <ButtonUI
                color="success"
                disabled={isDisabledRecuperar()}
                onClick={openDialogRecuperarHandler}
              >
                {constants.RECUPERAR}
              </ButtonUI>
            </div>
          </Grid24>
        </Grid24>
      </Grid24>
      <div className={styles.tableWrapper}>
        <Typography component="p">{SEARCH_TABLE_TITLE}</Typography>
        <Table
          columns={buildTableHeader(arrowDirection, columnOrdenacao, setArrowOrdenacao, setColumnOrdenacao, onClickOrdernacaoColumn, buildCheckboxSelectAll)}
          rows={tableContent}
          size={tableInfo.size}
          page={tableInfo.page}
          rowsPerPage={tableInfo.rowsPerPage}
          onChangePage={changeTablePageHandler}
          onChangeRowsPerPage={changeTableRowsPerPageHandler}
        />
      </div>
    </CardLayout>
  );
}

export default withTokenInterceptor(withRouter(ProcessarBordero));
