import React from 'react';
import cx from 'classnames';
import { Tooltip } from '@material-ui/core';
import useStyles from './Tooltip.styles';

function TooltipUI({
  children, error, fullWidth = false, ...props
}) {
  const styles = useStyles();
  return (
    <Tooltip
      classes={{ tooltip: cx({ [styles.error]: error, [styles.fontSize]: true, [styles.fullWidth]: fullWidth }) }}
      placement="bottom-start"
      {...props}
    >
      {children}
    </Tooltip>
  );
}

export default TooltipUI;
