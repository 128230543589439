export default {
  USUARIO: 'Usuário',
  SENHA: 'Senha',
  ENTRAR: 'Entrar',
  ESQUECEU_SENHA: 'Esqueceu a Senha?',
  TITULO: 'Sistema ABE',
  USUARIO_INVALIDO: 'Informe o Usuário',
  SENHA_INVALIDA: 'Informe a Senha',
  USUARIO_SENHA_INVALIDOS: 'Informe o Usuário e a Senha',
  INITIAL_FORM: {
    username: {
      value: '',
      required: true,
      error: false,
      changeAttr: {
        maxLength: 25,
      },
    },
    password: {
      value: '',
      required: true,
      error: false,
      changeAttr: {
        maxLength: 25,
      },
    },
  },
};
