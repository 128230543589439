export const PROCESSAMENTO_TITLE = ['Processamento de Borderôs'];
export const INFO_GERAL_TITLE = 'Informações Gerais';
export const TOTAL_BORDERO_TITLE = 'Total de itens selecionados: ';
export const VALOR_TOTAL_BORDERO_TITLE = 'Valor total dos itens a serem processados: ';
export const CONFIGURACOES_TITLE = 'Configurações';
export const PROCESSAMENTO_NORMAL_TITLE = 'Processamento Normal';
export const PROCESSAMENTO_DIFERENCIADO_TITLE = 'Processamento Diferenciado';
export const PROCESSOS_FASE_INICIAL = 'Manter os Processos na Fase Inicial';
export const PROCESSOS_TITULOS_ANTIGOS = 'Aceitar processos que apenas possuam títulos antigos';
export const PROCESSOS_DUPLICCADOS = 'Criar novos processos, mesmo que estejam em Duplicidade';
export const PROCESSOS_VALOR_INFERIOR = 'Aceitar processos que possuam valor inferior ao mínimo permitido';
