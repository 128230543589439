import { Button, IconButton } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SearchIcon from '@material-ui/icons/Search';
import cx from 'classnames';
import InputField from '../../UI/Field/Input';
import PeriodField from '../../UI/Field/Period/PeriodField';
import SelectorField from '../../UI/Field/Selector/SelectorField';
import useStyles from './acordosAs.styles';


import {
  onChangeRequestFieldsAcordoAction,
  setDatasCamposObrigatoriosAcordoAction,
  onFocusRequestFieldsAcordosAction,
  setCacheRequestFieldsAction, setPageAction, setStoreValuesAction,
} from '../../../store/AcordoSeguro/Acordos/acordosAs.store';
import AcordosAsTable from './AcordosAsTable';
import WrapperContent from '../../UI/WrapperContent/WrapperContent';
import SelectorModel from '../../../models/SelectorModel';
import LoadingUI from '../../UI/Loading/LoadingUI';


function AcordosAs() {
  const dispatch = useDispatch();

  const periodoAcordoInicial = useSelector(states => states.acordosAs.requestFields.periodoAcordoInicial.copy());
  const periodoAcordoFinal = useSelector(states => states.acordosAs.requestFields.periodoAcordoFinal.copy());
  const nomeDevedor = useSelector(states => states.acordosAs.requestFields.nomeDevedor.copy());
  const nroProcesso = useSelector(states => states.acordosAs.requestFields.nroProcesso.copy());
  const nomeCliente = useSelector(states => states.acordosAs.requestFields.nomeCliente.copy());
  const idTipoAcordo = useSelector(states => states.acordosAs.requestFields.idTipoAcordo.copy());
  const loading = useSelector(states => states.acordosAs.loading);
  const openPesquisaAvancada = useSelector(states => states.acordosAs.openPesquisaAvancada);

  const [itemsTipoAcordo] = useState([
    new SelectorModel(1, 'Boleto'),
    new SelectorModel(2, 'Cartão de Crédito'),
    new SelectorModel(3, 'Pix'),
  ]);

  const onClickPesquisaAvancadaAcordoHandler = useCallback(() => {
    dispatch(setStoreValuesAction('openPesquisaAvancada', !openPesquisaAvancada));
    dispatch(onChangeRequestFieldsAcordoAction('nomeDevedor', ''));
    dispatch(onChangeRequestFieldsAcordoAction('nroProcesso', ''));
    dispatch(onChangeRequestFieldsAcordoAction('nomeCliente', ''));
    dispatch(onChangeRequestFieldsAcordoAction('idTipoAcordo', ''));
  }, [dispatch, openPesquisaAvancada]);


  const validarCamposObrigatoriosAcordoPesquisa = useCallback(() => {
    if (!periodoAcordoInicial.value && !periodoAcordoFinal.value) {
      dispatch(setDatasCamposObrigatoriosAcordoAction(periodoAcordoInicial.name));
      dispatch(setDatasCamposObrigatoriosAcordoAction(periodoAcordoFinal.name));
    } else if (periodoAcordoInicial.value && !periodoAcordoFinal.value) {
      dispatch(setDatasCamposObrigatoriosAcordoAction(periodoAcordoFinal.name));
    } else if (!periodoAcordoInicial.value && periodoAcordoFinal.value) {
      dispatch(setDatasCamposObrigatoriosAcordoAction(periodoAcordoInicial.name));
    } else {
      dispatch(setCacheRequestFieldsAction());
      dispatch(setPageAction(0));
    }
  }, [periodoAcordoInicial.name, periodoAcordoFinal.name, periodoAcordoFinal.value, periodoAcordoInicial.value, dispatch]);


  const onClickPesquisarHandler = useCallback(() => {
    validarCamposObrigatoriosAcordoPesquisa();
  }, [validarCamposObrigatoriosAcordoPesquisa]);

  const onChangeRequestFieldsAcordosHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeRequestFieldsAcordoAction(name, value));
  }, [dispatch]);

  const onFocusRequestFieldsAcordosHandler = useCallback((event) => {
    const { name } = event.target;
    dispatch(onFocusRequestFieldsAcordosAction(name));
  }, [dispatch]);


  const styles = useStyles();

  const buttonClassPesquisaAvancada = cx({
    [styles.pesquisaAvancadaButton]: !openPesquisaAvancada,
    [styles.pesquisaSimplesButton]: openPesquisaAvancada,
  });


  return (
    <WrapperContent>
      <LoadingUI show={loading} />
      <div className={styles.div__root}>
        {openPesquisaAvancada && (
          <div className={styles.div__pesquisaAvancada}>
            <div className={styles.div__avancada}>
              <div className={styles.div__fieldDevedorCliente}>
                <div>
                  <InputField
                    fullWidth
                    label="Nome do Devedor"
                    name={nomeDevedor.name}
                    value={nomeDevedor.value}
                    onChange={onChangeRequestFieldsAcordosHandler}
                    onFocus={onFocusRequestFieldsAcordosHandler}
                  />
                </div>
                <div className={styles.div__fieldDevedorCliente}>
                  <InputField
                    fullWidth
                    label="Nome do Cliente"
                    name={nomeCliente.name}
                    value={nomeCliente.value}
                    onChange={onChangeRequestFieldsAcordosHandler}
                    onFocus={onFocusRequestFieldsAcordosHandler}
                  />
                </div>
              </div>
              <div>
                <div className={styles.div__fieldProcessoAcordo}>
                  <InputField
                    fullWidth
                    label="Nº Processo"
                    name={nroProcesso.name}
                    value={nroProcesso.value}
                    onChange={onChangeRequestFieldsAcordosHandler}
                    onFocus={onFocusRequestFieldsAcordosHandler}
                  />
                </div>
                <div className={styles.div__fieldProcessoAcordo}>
                  <SelectorField
                    hasEmptyLabel
                    fullWidth
                    label="Tipo de Acordo"
                    items={itemsTipoAcordo}
                    name={idTipoAcordo.name}
                    value={idTipoAcordo.value}
                    onChange={onChangeRequestFieldsAcordosHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        )}


        <div className={styles.div__pesquisas}>
          <div className={styles.div__fieldsCalendario}>
            <div className={styles.field_calendario}>
              <PeriodField
                fullWidth
                label="Data do Acordo do Processo (máximo 60 dias):"
                firstField={periodoAcordoInicial}
                lastField={periodoAcordoFinal}
                onChange={onChangeRequestFieldsAcordosHandler}
                onFocus={onFocusRequestFieldsAcordosHandler}
              />
            </div>
            <div>
              <IconButton
                type="submit"
                className={styles.div__buttonPesquisar}
                onClick={onClickPesquisarHandler}
              >
                <SearchIcon style={{ marginLeft: 2 }} />
              </IconButton>
            </div>
          </div>
          <div className={styles.div__pesquisaAvancadaBtn}>
            <Button className={buttonClassPesquisaAvancada} onClick={onClickPesquisaAvancadaAcordoHandler}>
              {openPesquisaAvancada ? 'Pesquisa Simples' : 'Pesquisa Avançada'}
            </Button>
          </div>
        </div>
      </div>
      <AcordosAsTable />
    </WrapperContent>
  );
}

export default AcordosAs;
