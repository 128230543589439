import styled from 'styled-components';

export const DetalheFase = styled.div`
  width: 300px;
  flex-grow: 0;
  margin: 8px 11px 14px 0;
  padding: 2px 106px 2px 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  div {
    text-align: left;

    p {
      font-weight: bold;
      font-size: 12px;
      color: #6c6c6c;
    }

    p + p {
    font-weight: unset;
      font-size: 12px;

      span + span {
        margin-left: 4px;
      }
    }
  }
`;

export const SequenciaFase = styled.div`
  display: contents;

  .MuiTimelineConnector-root {
    border: 1px dashed #bdbdbd;
    background-color: transparent;
  }

  .MuiTimelineDot-root {
    background-color: ${props => (props.isFaseAtual ? '#f52c2c !important' : '#4d578b !important')};
    padding: 0;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 22px;
      transform: ${props => (props.isFaseAtual ? 'unset' : 'rotate(256deg)')};
    }
  }

  .MuiTimelineOppositeContent-root {
    text-align: -webkit-right;
  }

  .MuiTimelineContent-root {
    padding: 6px 24px;
  }
`;
