import {
  DateUtils,
  toAmericanDate, toBrazilianDate, updateFieldsWithErrors,
} from '../../helpers';
import SelectorModel from '../../models/SelectorModel';
import LancamentosService from './LancamentosService';

export const LancamentoFactory = {
  makeTiposLancamentos(lancamento) {
    return {
      descricao: lancamento?.historico || '',
      idTipoContaCorrenteUsuario: lancamento?.idTipoContaCorrenteUsuario || '',
    };
  },
};

class LancamentosComissionados {
  makeErrorFields(errors) {
    return {
      idTipoContaCorrenteUsuario: {
        error: errors?.idTipoContaCorrenteUsuario?.error || false,
        errorMessage: errors?.idTipoContaCorrenteUsuario?.errorMessage || '',
      },
      historico: {
        error: errors?.historico?.error || false,
        errorMessage: errors?.historico?.errorMessage || '',
      },
      valor: {
        error: errors?.valor?.error || false,
        errorMessage: errors?.valor?.errorMessage || '',
      },
    };
  }

  makeFormFieldsNewLancamentos(values) {
    return {
      descricao: values?.descricao || '',
      data: toBrazilianDate(values?.data) || toBrazilianDate(DateUtils.makeDate(0)),
      idTipoContaCorrenteUsuario: values?.idTipoContaCorrenteUsuario || 1,
      historico: values?.historico || '',
      valor: values?.valor || '',
      bloqueado: values?.bloqueado || false,
    };
  }

  async buscarTiposLancamento() {
    const tiposLancamentos = await LancamentosService.findAllTiposLancamentos();
    return tiposLancamentos?.data;
  }

  makePayload(formFields, idUsuario, idContaCorrenteUsuario) {
    return {
      bloqueado: formFields?.bloqueado || false,
      data: toAmericanDate(formFields?.data) || null,
      historico: formFields?.historico || null,
      idTipoContaCorrenteUsuario: formFields?.idTipoContaCorrenteUsuario || null,
      idUsuario,
      idContaCorrenteUsuario,
      valor: formFields?.valor || null,
    };
  }

  async novoLancamento(errorFields, formFields, idUsuario, setLoading) {
    try {
      setLoading(true);
      const payload = this.makePayload(formFields, idUsuario);
      await LancamentosService.findCadastrarLancamento(payload);
      return {
        success: true,
        error: null,
      };
    } catch (error) {
      const [updateWithErrors] = updateFieldsWithErrors(error, errorFields);
      return {
        success: false,
        error: {
          campos: this.makeErrorFields(updateWithErrors),
        },
      };
    } finally {
      setLoading(false);
    }
  }

  makeItemsLancamento(itens) {
    return itens.map(opcao => new SelectorModel(
      opcao.idTipoContaCorrenteUsuario,
      opcao.descricao,
    ));
  }

  async editarLancamento(errorFields, formFields, idUsuario, idContaCorrente, setLoading) {
    try {
      setLoading(true);
      const payload = this.makePayload(formFields, idUsuario, idContaCorrente);
      await LancamentosService.findEditarLancamento(payload);
      return {
        success: true,
        error: null,
      };
    } catch (error) {
      const [updateWithErrors] = updateFieldsWithErrors(error, errorFields);
      return {
        success: false,
        error: {
          campos: this.makeErrorFields(updateWithErrors),
        },
      };
    } finally {
      setLoading(false);
    }
  }
}

export const LancamentosComissionadosController = new LancamentosComissionados();
