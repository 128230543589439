import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  faixaComissao__trashContainer: {
    position: 'relative',
  },
  faixaComissao__trashButton: {
    position: 'absolute',
    top: '7px',
  },
});
