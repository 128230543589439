import React from 'react';
import SubTitleForm from '../../SubTitleForm/SubTitleForm';
import { TitleActionButton } from '../../..';


export default React.memo(({
  hideAddButton = false,
  children,
  identifier,
  className = '',
  show = true,
  title = '',
  readTitle = '',
  disabled = false,
  addHandler,
  tooltip = '',
  readMode = false,
}) => {
  let content = (
    <section className={className} style={{ width: '100%', cursor: 'default' }}>
      <SubTitleForm>
        <TitleActionButton
          title={title}
          readTitle={readTitle}
          disabled={disabled}
          identifier={identifier}
          addHandler={addHandler}
          tooltip={tooltip}
          readMode={readMode}
          hideAddButton={hideAddButton}
        />
      </SubTitleForm>
      <div>{children}</div>
    </section>
  );
  if (!show) {
    content = null;
  }

  return content;
});
