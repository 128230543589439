import React from 'react';
import { useDispatch } from 'react-redux';
import PrivateRoute from '../../hoc/PrivateRoute/PrivateRoute';
import { initializePageAction } from '../../store/theme.actions';
import constants from '../../helpers/constants/navigationTitle.constants';
import TituloCompromissoProvider from '../../store/TituloCompromisso/TituloCompromissoProvider';
import PesquisarTituloCompromissoPage from './Pesquisa/PesquisarTituloCompromissoPage';


function Wrapper() {
  const dispatch = useDispatch();
  dispatch(initializePageAction(`${constants.TITULOS_COMPROMISSO}`));

  return (
    <TituloCompromissoProvider>
      <PesquisarTituloCompromissoPage />
    </TituloCompromissoProvider>
  );
}

export default function RoutesTituloCompromisso() {
  return (
    <PrivateRoute path="/titulo-de-compromisso" component={Wrapper} />
  );
}
