import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


import { Container } from './styles';
import WrapperSubTitleForm from '../WrapperSubTitleForm';
import TooltipUI from '../Tooltip/Tooltip';
import {
  AllFormats, DefaultFormats, getErrorInModel, AllModules, DefaultModules,
} from './editorTexto.utils';

function makeModulesEditor(isAllModules, modules) {
  return {
    toolbar: (isAllModules) ? AllModules : modules || DefaultModules,
    clipboard: {
      matchVisual: false,
    },
  };
}

function Editor({
  label = '',
  isMural = false,
  isInputLabel = false,
  name = '',
  placeholder,
  model = null,
  isAllModules = false,
  modules,
  value,
  readOnly = true,
  onChange,
  onFocus,
  quillRef,
}) {
  const [errorProps, setErrorProps] = useState(false);

  useEffect(() => {
    setErrorProps(getErrorInModel(model));
  }, [model]);

  const editorTextoError = () => (
    <TooltipUI error title={model.errorMessage} placement="top-end">
      <Container isMural={isMural} error={errorProps} withoutToolbar={readOnly}>
        <ReactQuill
          theme="snow"
          name={name}
          onChange={onChange}
          onFocus={onFocus}
          value={value}
          modules={makeModulesEditor(isAllModules, modules)}
          formats={isAllModules ? AllFormats : DefaultFormats}
          bounds=".root"
          placeholder={placeholder}
          readOnly={readOnly}
        />
        <div className="editor" />
      </Container>
    </TooltipUI>
  );

  const editorTexto = () => (
    <Container isMural={isMural} error={errorProps} withoutToolbar={readOnly}>
      <ReactQuill
        name={name}
        theme="snow"
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        modules={makeModulesEditor(isAllModules, modules)}
        formats={isAllModules ? AllFormats : DefaultFormats}
        bounds=".root"
        placeholder={placeholder}
        readOnly={readOnly}
        ref={quillRef}
      />
      <div className="editor" />
    </Container>
  );
  return (
    <WrapperSubTitleForm title={label} isInputLabel={isInputLabel}>
      {errorProps ? (editorTextoError()) : editorTexto()}
    </WrapperSubTitleForm>
  );
}

export default React.memo(Editor);
