import React, { useCallback } from 'react';
import Grid24 from '../../../../../UI/Grid24';
import FormWrapperWithTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import RadioField from '../../../../../UI/Field/Radio/RadioField';
import { GroupDefinitions } from '../../BaseCalculo/Honorarios/styles';
import {
  NAO_PRESTAR_APOS, NENHUM_LABEL, NUNCA_ULTIMO_DIA_LABEL, ULTIMO_DIA_LABEL,
} from '../PrestacaoContas.constants';
import useStyle from '../PrestacaoContasStyles';
import SelectorField from '../../../../../UI/Field/Selector';
import { useCrudClienteDispatch, useCrudClienteSelector } from '../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import { onChangeControls, setStoreValueAction } from '../../../../../../store/Cliente/V2/Crud/crudCliente.store';

function makeDias() {
  return [
    { id: 1, value: '1' },
    { id: 2, value: '2' },
    { id: 3, value: '3' },
    { id: 4, value: '4' },
    { id: 5, value: '5' },
    { id: 6, value: '6' },
    { id: 7, value: '7' },
    { id: 8, value: '8' },
    { id: 9, value: '9' },
    { id: 10, value: '10' },
    { id: 11, value: '11' },
    { id: 12, value: '12' },
    { id: 13, value: '13' },
    { id: 14, value: '14' },
    { id: 15, value: '15' },
    { id: 16, value: '16' },
    { id: 17, value: '17' },
    { id: 18, value: '18' },
    { id: 19, value: '19' },
    { id: 20, value: '20' },
    { id: 21, value: '21' },
    { id: 22, value: '22' },
    { id: 23, value: '23' },
    { id: 24, value: '24' },
    { id: 25, value: '25' },
    { id: 26, value: '26' },
    { id: 27, value: '27' },
    { id: 28, value: '28' },
    { id: 29, value: '29' },
    { id: 30, value: '30' },
  ];
}

function RegrasEspeciais({ isEditMode, onChange }) {
  const dispatch = useCrudClienteDispatch();

  const style = useStyle();

  const regraEspecialDefinition = useCrudClienteSelector(states => states.regraEspecialDefinition);
  const diaLimitePrestacaoConta = useCrudClienteSelector(states => states.controls.diaLimitePrestacaoConta.copy());
  const disbaledSelector = regraEspecialDefinition !== 'NAO_PRESTAR_APOS_DIA';
  const dias = React.useMemo(() => makeDias(), []);

  const onChangeRegraEspecialHandler = useCallback((e) => {
    const { value } = e.target;
    dispatch(onChangeControls('regraPrestacao', value));
    dispatch(setStoreValueAction('regraEspecialDefinition', value));

    if (value !== 'NAO_PRESTAR_APOS_DIA') {
      dispatch(onChangeControls('diaLimitePrestacaoConta', ''));
    }
    if (value === 'NAO_PRESTAR_APOS_DIA') {
      dispatch(onChangeControls('diaLimitePrestacaoConta', '30'));
    }
  }, [dispatch]);

  return (
    <Grid24 container isFormContainer spacing="1">
      <Grid24 xs={24} sm={24}>
        <FormWrapperWithTitle
          title="Regras Especiais"
        >
          <Grid24 xs={24} sm={24} className={style.grid__radioButton}>
            <GroupDefinitions
              aria-label="periodicidade"
              value={regraEspecialDefinition}
              onChange={onChangeRegraEspecialHandler}
              disabled={!isEditMode}
            >
              <Grid24 xs={24} sm={24}>
                <RadioField label={NENHUM_LABEL} value="NENHUMA" />
              </Grid24>
              <Grid24 xs={24} sm={24}>
                <RadioField label={ULTIMO_DIA_LABEL} value="SEMPRE_PRESTAR_ULTIMO_DIA_UTIL_MES" />
              </Grid24>
              <Grid24 xs={24} sm={24}>
                <RadioField label={NUNCA_ULTIMO_DIA_LABEL} value="NUNCA_PRESTAR_ULTIMO_DIA_UTIL_MES" />
              </Grid24>
              <Grid24 xs={24} sm={24} className={style.grid__lastRadio}>
                <RadioField label={NAO_PRESTAR_APOS} value="NAO_PRESTAR_APOS_DIA" />
                <Grid24 xs={24} sm={1}>
                  <SelectorField
                    isForm
                    disabled={disbaledSelector || !isEditMode}
                    onChange={onChange}
                    items={dias}
                    name={diaLimitePrestacaoConta.name}
                    value={diaLimitePrestacaoConta.value}
                    error={diaLimitePrestacaoConta.error}
                    errorMessage={diaLimitePrestacaoConta.errorMessage}
                  />
                </Grid24>
              </Grid24>
            </GroupDefinitions>
          </Grid24>
        </FormWrapperWithTitle>
      </Grid24>
    </Grid24>
  );
}
export default React.memo(RegrasEspeciais);
