import {
  FaRegFileExcel, FaRegFileImage, FaRegFilePdf, FaRegFileWord,
} from 'react-icons/fa';
import React from 'react';

export const extensao = {
  pdf: <FaRegFilePdf size={40} color="#CB2128" />,
  jpeg: <FaRegFileImage size={40} color="#FFBD5A" />,
  jpg: <FaRegFileImage size={40} color="#FFBD5A" />,
  png: <FaRegFileImage size={40} color="#FFBD5A" />,
  bmp: <FaRegFileImage size={40} color="#FFBD5A" />,
  gif: <FaRegFileImage size={40} color="#FFBD5A" />,
  ico: <FaRegFileImage size={40} color="#FFBD5A" />,
  doc: <FaRegFileWord size={40} color="#2A5699" />,
  docx: <FaRegFileWord size={40} color="#2A5699" />,
  xlsx: <FaRegFileExcel size={40} color="#1F7146" />,
  xls: <FaRegFileExcel size={40} color="#1F7146" />,
};
