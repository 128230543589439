import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import {
  PREPOSTO,
} from '../../../../components/Cliente/Crud/DadosCadastrais/Identificacao/identificacaoCrudCliente.constants';
import InputField from '../../../../components/UI/Field/Input';
import MaskField from '../../../../components/UI/Field/Mask';
import Grid24 from '../../../../components/UI/Grid24';
import FormWrapperWithTitle from '../../../../components/UI/Wrapper/Form/FormWrapperWithTitle';
import {
  CATEGORIA,
  CODIGO_CLIENTE,
  COMERCIAL_PRINCIPAL,
  CPF_CNPJ,
  FILIAL_RESPONSAVEL,
  IDENTIFICACAO_INTERNA,
  INSCRICAO_ESTADUAL,
  INSCRICAO_MUNICIPAL,
  NOME,
  NOME_FANTASIA,
  OBSERVACAO,
  ORIGEM_LEAD,
  RAMOS_ATIVIDADE,
  STATUS,
  TELEMARKETING,
  TITULO,
} from './IdentificacaoCliente.constants';
import useUtilStyles from '../../../../helpers/styles/utils.styles';
import Editor from '../../../../components/UI/EditorTexto';


function IdentificacaoCliente({ cliente }) {
  const utilStyles = useUtilStyles();
  const [ramosAtividade, setRamosAtividade] = useState('');

  useEffect(() => {
    setRamosAtividade(cliente.ramosAtividade?.map(item => item.nomeRamoAtividade).join('; '));
  }, [cliente]);

  const makeMultiLineSelectWithToolTip = component => (
    <Grid24 item xs={24} sm={12}>
      {cliente.ramosAtividade ? (
        <Tooltip
          classes={{ tooltip: utilStyles.tooltipMaxWidth }}
          arrow
          title={ramosAtividade}
          placement="bottom-start"
        >
          <div className={utilStyles.containerTooltip}>
            {component}
          </div>
        </Tooltip>
      ) : (
        <div className={utilStyles.containerTooltip}>
          {component}
        </div>
      )}
    </Grid24>
  );

  return (
    <Grid24 container isFormContainer>
      <FormWrapperWithTitle title={TITULO} className={utilStyles.accordionsInternForm}>
        <Grid24 container isFormContainer spacing="1">
          <Grid24 item xs={24} sm={3}>
            <InputField
              fullWidth
              isForm
              disabled
              label={CODIGO_CLIENTE}
              value={cliente?.idCliente}
            />
          </Grid24>
          <Grid24 item xs={24} sm={13}>
            <InputField
              fullWidth
              isForm
              disabled
              label={NOME}
              value={cliente?.nomeCliente}
            />
          </Grid24>
          <Grid24 item xs={24} sm={6}>
            <InputField
              disabled
              fullWidth
              isForm
              label={ORIGEM_LEAD}
              value={cliente?.origemLead}
            />
          </Grid24>
          <Grid24 item xs={12} sm={2}>
            <InputField
              fullWidth
              isForm
              disabled
              label={STATUS}
              value={cliente?.statusCliente}
            />
          </Grid24>
        </Grid24>
        <Grid24 container isFormContainer spacing="1">
          <Grid24 item xs={24} sm={16}>
            <InputField
              fullWidth
              isForm
              disabled
              label={NOME_FANTASIA}
              value={cliente?.nomeFantasia}
            />
          </Grid24>
          <Grid24 item xs={24} sm={4}>
            <InputField
              fullWidth
              isForm
              disabled
              label={INSCRICAO_ESTADUAL}
              value={cliente?.inscricaoEstadual}
            />
          </Grid24>
          <Grid24 item xs={24} sm={4}>
            <InputField
              fullWidth
              isForm
              disabled
              label={INSCRICAO_MUNICIPAL}
              value={cliente?.inscricaoMunicipal}
            />
          </Grid24>
        </Grid24>
        <Grid24 container isFormContainer spacing="1">
          <Grid24 item xs={24} sm={3}>
            <InputField
              fullWidth
              isForm
              disabled
              label={CATEGORIA}
              value={cliente?.categoria}

            />
          </Grid24>
          <Grid24 item xs={24} sm={4}>
            <MaskField
              isForm
              fullWidth
              disabled
              label={CPF_CNPJ}
              value={cliente?.cpfCnpj}
            />
          </Grid24>
          <Grid24 item xs={24} sm={5}>
            <InputField
              isForm
              fullWidth
              disabled
              label={PREPOSTO}
              value={cliente?.preposto || ''}
            />
          </Grid24>
          <Grid24 item xs={24} sm={12}>
            <InputField
              fullWidth
              isForm
              disabled
              label={IDENTIFICACAO_INTERNA}
              value={cliente?.identificacaoInterna || ''}
            />
          </Grid24>
        </Grid24>
        <Grid24 container isFormContainer spacing="1">
          <Grid24 item xs={24} sm={12}>
            <InputField
              fullWidth
              isForm
              disabled
              label={FILIAL_RESPONSAVEL}
              value={cliente?.filialResponsavel}
            />
          </Grid24>
          {makeMultiLineSelectWithToolTip((<InputField
            fullWidth
            isForm
            disabled
            label={RAMOS_ATIVIDADE}
            value={ramosAtividade}
          />
          ))}
        </Grid24>
        <Grid24 container isFormContainer spacing="1">
          <Grid24 item xs={24} sm={12}>
            <InputField
              fullWidth
              isForm
              disabled
              label={COMERCIAL_PRINCIPAL}
              value={cliente?.usuarioPrincipal}
            />
          </Grid24>
          <Grid24 item xs={24} sm={12}>
            <InputField
              fullWidth
              isForm
              disabled
              label={TELEMARKETING}
              value={cliente?.usuarioTelemarketing}
            />
          </Grid24>
        </Grid24>
        <Grid24 container isFormContainer spacing="1">
          <Grid24 item xs={24} sm={24}>
            <Editor
              label={OBSERVACAO}
              readOnly
              isInputLabel
              value={cliente?.observacao || ''}
            />
          </Grid24>
        </Grid24>
      </FormWrapperWithTitle>
    </Grid24>
  );
}


export default withRouter(IdentificacaoCliente);
