import { downloadFile } from '../helpers';
import FieldModel from '../models/FieldModel';
import { downloadBorderoCorrigido } from '../services/bordero/bordero/bordero.services';

function makeField(name, value, errorMessage) {
  if (value === true && name === 'possuirProtesto') {
    return {
      name,
      value: 'S',
      error: !!errorMessage || false,
      errorMessage: errorMessage || '',
      disabled: !!errorMessage === false,
    };
  } if (value === false && name === 'possuirProtesto') {
    return {
      name,
      value: 'N',
      error: !!errorMessage || false,
      errorMessage: errorMessage || '',
      disabled: !!errorMessage === false,
    };
  }
  return {
    name,
    value: value === 0 ? '0' : value || null,
    error: !!errorMessage || false,
    errorMessage: errorMessage || '',
    disabled: !!errorMessage === false,
  };

  //
}

function makeFieldComplete(name, json) {
  return makeField(name, json?.texto, json?.erro?.mensagem);
}

class DadosDevedorController {
  makeEnderecoDevedor(endereco) {
    return {
      estado: new FieldModel(makeFieldComplete('estado', endereco?.estado)),
      endereco: new FieldModel(makeFieldComplete('endereco', endereco?.endereco)),
      numero: new FieldModel(makeFieldComplete('numero', endereco?.numero)),
      complemento: new FieldModel(makeFieldComplete('complemento', endereco?.complemento)),
      bairro: new FieldModel(makeFieldComplete('bairro', endereco?.bairro)),
      cidade: new FieldModel(makeFieldComplete('cidade', endereco?.cidade)),
      cep: new FieldModel(makeFieldComplete('cep', endereco?.cep)),
      principal: new FieldModel(makeFieldComplete('principal', endereco?.principal)),
    };
  }

  makeEnderecosDevedor(enderecos) {
    if (!enderecos) {
      return [];
    }

    return enderecos
      // .sort((origin, to) => to.principal - origin.principal)
      .map(this.makeEnderecoDevedor);
  }

  makeTelefoneDevedor(telefone) {
    return {
      tipoTelefone: new FieldModel(makeFieldComplete('tipoTelefone', telefone?.tipoTelefone)),
      ddd: new FieldModel(makeFieldComplete('ddd', telefone?.ddd)),
      numero: new FieldModel(makeFieldComplete('numero', telefone?.numero)),
      observacao: new FieldModel(makeFieldComplete('observacao', telefone?.observacao)),
      principal: new FieldModel(makeFieldComplete('principal', telefone?.principal)),
      permitirWhatsApp: new FieldModel(makeFieldComplete('permitirWhatsApp', telefone?.permitirWhatsApp)),
    };
  }

  makeTelefonesDevedor(telefones) {
    if (!telefones) {
      return [];
    }

    return telefones
      // .sort((origin, to) => to.principal - origin.principal)
      .map(this.makeTelefoneDevedor);
  }

  makeEmailDevedor(email) {
    return {
      email: new FieldModel(makeFieldComplete('email', email?.email)),
      principal: new FieldModel(makeFieldComplete('principal', email?.principal)),
    };
  }

  makeEmailsDevedor(emails) {
    if (!emails) {
      return [];
    }

    return emails
      .map(this.makeEmailDevedor);
  }

  makeTituloDevedor(titulo) {
    return {
      tipoDocumento: new FieldModel(makeFieldComplete('tipoDocumento', titulo?.tipoTitulo)),
      numero: new FieldModel(makeFieldComplete('numero', titulo?.numero)),
      valorOriginal: new FieldModel(makeFieldComplete('valorOriginal', titulo?.valorTitulo)),
      dataVencimento: new FieldModel(makeFieldComplete('dataVencimento', titulo?.dataVencimento)),
      valorProtesto: new FieldModel(makeFieldComplete('valorProtesto', titulo?.valorProtesto)),
      possuirProtesto: new FieldModel(makeFieldComplete('possuirProtesto', titulo?.possuirProtesto)),
      dadosRetorno: new FieldModel(makeFieldComplete('dadosRetorno', titulo?.dadosRetorno)),
    };
  }

  makeTitulosDevedor(titulos) {
    if (!titulos) {
      return [];
    }

    return titulos.map(this.makeTituloDevedor);
  }

  makeFormFields(json, idCliente, nomeCliente) {
    return {
      idCliente,
      nomeCliente,
      indice: json?.indice,
      categoria: makeFieldComplete('categoria', json?.categoria),
      documento: makeFieldComplete('documento', json?.documento),
      codigoNoCliente: makeFieldComplete('codigoNoCliente', json?.codigoNoCliente),
      razaoSocial: makeFieldComplete('razaoSocial', json?.razaoSocial),
      preposto: makeFieldComplete('preposto', json?.nomePreposto),
      nomeFantasia: makeFieldComplete('nomeFantasia', json?.nomeFantasia),
      inscricaoEstadual: makeFieldComplete('inscricaoEstadual', json?.inscricaoEstadual),
      nomeAdvogado: makeFieldComplete('nomeAdvogado', json?.nomeAdvogado),
      telefoneAdvogado: makeFieldComplete('telefoneAdvogado', json?.telefoneAdvogado),
      instrucoes: makeFieldComplete('instrucoes', json?.instrucoes),
      enderecos: this.makeEnderecosDevedor(json?.enderecos),
      telefones: this.makeTelefonesDevedor(json?.telefones),
      emails: this.makeEmailsDevedor(json?.emails),
      titulos: this.makeTitulosDevedor(json?.titulos),
    };
  }

  makePayload(fields, idCliente, nomeCliente) {
    return {
      idCliente,
      nomeCliente,
      razaoSocial: fields?.razaoSocial?.value,
      nomeFantasia: fields?.nomeFantasia?.value,
      categoria: fields?.categoria?.value,
      indice: fields?.indice,
      documento: fields?.documento?.value,
      codigoNoCliente: fields?.codigoNoCliente?.value,
      nomePreposto: fields?.preposto?.value,
      inscricaoEstadual: fields?.inscricaoEstadual?.value,
      nomeAdvogado: fields?.nomeAdvogado?.value,
      telefoneAdvogado: fields?.telefoneAdvogado?.value,
      instrucoes: fields?.instrucoes?.value,
      enderecos: fields?.enderecos?.map(this.makeDevedorEnderecoCommand) || [],
      telefones: fields?.telefones?.map(this.makeDevedorTelefoneCommand) || [],
      emails: fields?.emails?.map(this.makeDevedorEmailCommand) || [],
      titulos: fields?.titulos?.map(this.makeDevedorTitulosCommand) || [],
    };
  }

  makeDevedorEnderecoCommand(endereco) {
    return {
      bairro: endereco?.bairro?.value || null,
      cep: endereco?.cep?.value || null,
      cidade: endereco?.cidade?.value || null,
      complemento: endereco?.complemento?.value || null,
      endereco: endereco?.endereco?.value || null,
      estado: endereco?.estado?.value || null,
      numero: endereco?.numero?.value || null,
      principal: endereco?.principal?.value || false,
    };
  }

  makeDevedorTelefoneCommand(telefone) {
    return {
      ddd: telefone?.ddd?.value || null,
      numero: telefone?.numero?.value || null,
      observacao: telefone?.observacao?.value || null,
      permitirWhatsApp: telefone?.permitirWhatsApp?.value || false,
      principal: telefone?.principal?.value || false,
      tipoTelefone: telefone?.tipoTelefone?.value || null,
    };
  }

  makeDevedorEmailCommand(email) {
    return {
      email: email?.email?.value || null,
      principal: email?.principal?.value || false,
    };
  }

  makeDevedorTitulosCommand(titulo) {
    return {
      idTipoTitulo: titulo?.tipoDocumento?.value || null,
      numero: titulo?.numero?.value || null,
      valorTitulo: titulo?.valorOriginal?.value || null,
      dataVencimento: titulo?.dataVencimento?.value || null,
      valorProtesto: titulo?.valorProtesto?.value || null,
      possuirProtesto: titulo?.possuirProtesto?.value === 'S' || false,
      dadosRetorno: titulo?.dadosRetorno?.value || null,
    };
  }

  makePayloadDevedoreCorrigidos(devedoresCorrigidos) {
    return devedoresCorrigidos.map(item => ({
      indice: item.indice,
      razaoSocial: { texto: item.razaoSocial },
      nomeFantasia: { texto: item.nomeFantasia },
      categoria: { texto: item.categoria },
      documento: { texto: Number(item.documento) },
      codigoNoCliente: { texto: item.codigoNoCliente },
      nomePreposto: { texto: item.nomePreposto },
      inscricaoEstadual: { texto: item.inscricaoEstadual },
      nomeAdvogado: { texto: item.nomeAdvogado },
      telefoneAdvogado: { texto: item.telefoneAdvogado },
      instrucoes: { texto: item.instrucoes },
      enderecos: item?.enderecos?.map(this.makeDevedorCorrigidoEnderecoCommand) || [],
      telefones: item?.telefones?.map(this.makeDevedorCorrigidoTelefoneCommand) || [],
      emails: item?.emails?.map(this.makeDevedorCorrigidoEmailCommand) || [],
      titulos: item?.titulos?.map(this.makeDevedorCorrigidoTitulosCommand),
    }));
  }

  makeDevedorCorrigidoEnderecoCommand(endereco) {
    return {
      endereco: { texto: endereco?.endereco },
      numero: { texto: endereco?.numero },
      complemento: { texto: endereco?.complemento },
      bairro: { texto: endereco?.bairro },
      cidade: { texto: endereco?.cidade },
      cep: { texto: Number(endereco?.cep) },
      estado: { texto: endereco?.estado },
      principal: { texto: endereco?.principal },
    };
  }

  makeDevedorCorrigidoTelefoneCommand(telefone) {
    return {
      tipoTelefone: { texto: telefone?.tipoTelefone },
      ddd: { texto: telefone?.ddd },
      numero: { texto: telefone?.numero },
      permitirWhatsApp: { texto: telefone?.permitirWhatsApp },
      principal: { texto: telefone?.principal },
      observacao: { texto: telefone?.observacao },
    };
  }

  makeDevedorCorrigidoEmailCommand(email) {
    return {
      email: { texto: email?.email },
      principal: { texto: email?.principal },
    };
  }

  makeDevedorCorrigidoTitulosCommand(titulo) {
    return {
      tipoTitulo: { texto: titulo?.idTipoTitulo },
      numero: { texto: titulo?.numero },
      valorTitulo: { texto: Number(titulo?.valorTitulo.toString().replace('.', '')) },
      dataVencimento: { texto: titulo?.dataVencimento },
      possuirProtesto: { texto: titulo?.possuirProtesto },
      valorProtesto: { texto: Number(titulo?.valorProtesto.toString().replace('.', '')) },
      dadosRetorno: { texto: titulo?.dadosRetorno },
    };
  }

  makePayloadDevedoreCorretos(devedoresCorretos) {
    return devedoresCorretos.map(item => ({
      indice: item.indice,
      razaoSocial: { texto: item.razaoSocial.texto },
      nomeFantasia: { texto: item.nomeFantasia.texto },
      categoria: { texto: item.categoria.texto },
      documento: { texto: Number(item.documento.texto) },
      codigoNoCliente: { texto: item.codigoNoCliente.texto },
      nomePreposto: { texto: item.nomePreposto.texto },
      inscricaoEstadual: { texto: item.inscricaoEstadual.texto },
      nomeAdvogado: { texto: item.nomeAdvogado.texto },
      telefoneAdvogado: { texto: item.telefoneAdvogado.texto },
      instrucoes: { texto: item.instrucoes.texto },
      enderecos: item?.enderecos?.map(this.makeDevedorCorretoEnderecoCommand) || [],
      telefones: item?.telefones?.map(this.makeDevedorCorretoTelefoneCommand) || [],
      emails: item?.emails?.map(this.makeDevedorCorretoEmailCommand) || [],
      titulos: item?.titulos?.map(this.makeDevedorCorretoTitulosCommand) || [],
    }));
  }

  makeDevedorCorretoEnderecoCommand(endereco) {
    return {
      endereco: { texto: endereco?.endereco.texto },
      numero: { texto: endereco?.numero.texto },
      complemento: { texto: endereco?.complemento.texto },
      bairro: { texto: endereco?.bairro.texto },
      cidade: { texto: endereco?.cidade.texto },
      cep: { texto: Number(endereco?.cep.texto) },
      estado: { texto: endereco?.estado.texto },
      principal: { texto: endereco?.principal?.texto },
    };
  }

  makeDevedorCorretoTelefoneCommand(telefone) {
    return {
      tipoTelefone: { texto: telefone?.tipoTelefone.texto },
      ddd: { texto: telefone?.ddd.texto },
      numero: { texto: telefone?.numero.texto },
      permitirWhatsApp: { texto: telefone?.permitirWhatsApp.texto },
      principal: { texto: telefone?.principal.texto },
      observacao: { texto: telefone?.observacao.texto },
    };
  }

  makeDevedorCorretoEmailCommand(email) {
    return {
      email: { texto: email?.email.texto },
      principal: { texto: email?.principal.texto },
    };
  }

  makeDevedorCorretoTitulosCommand(titulo) {
    return {
      tipoTitulo: { texto: titulo?.tipoTitulo.texto },
      numero: { texto: titulo?.numero.texto },
      valorTitulo: { texto: Number(titulo?.valorTitulo.texto.toString().replace('.', '')) },
      dataVencimento: { texto: titulo?.dataVencimento.texto },
      possuirProtesto: { texto: titulo?.possuirProtesto.texto },
      valorProtesto: { texto: Number(titulo?.valorProtesto.texto.toString().replace('.', '')) },
      dadosRetorno: { texto: titulo?.dadosRetorno.texto },
    };
  }

  makePayloadFinal(payloadDevedoresCorrigidos, payloadDevedoresCorretos, retornoBordero) {
    return {
      idCliente: retornoBordero.idCliente,
      devedoresComErro: payloadDevedoresCorrigidos,
      devedoresCorretos: payloadDevedoresCorretos,
    };
  }


  downloadArquivoBordero(downloadBordero, nomeArquivo) {
    downloadBorderoCorrigido(downloadBordero, nomeArquivo)
      .then((response) => {
        if (response.data != null) {
          downloadFile(response.headers.filename, response.data);
        }
      });
  }
}

export const dadosDevedorController = new DadosDevedorController();
