import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  wrapper: {
    marginTop: '6px',
  },
  customIconTrash: {
    top: '7px',
  },
  divider: {
    borderColor: '#FEFEFEAA',
    marginTop: '7px',
  },
});
