import React from 'react';

import {
  FaExclamationTriangle,
  FaPlus,
  FaTelegramPlane,
} from 'react-icons/fa';

import {
  TableBodyDocumentosProcesso, TableHeaderDocumentosProcesso, TableDefault,
} from '../../../components';

import { SalvarDocumentoProcesso } from '../SalvarDocumentoProcesso/SalvarDocumentoProcesso';

import {
  CustomButton,
  DocumentosProcessoContainer,
  HeaderContainer,
  WarningContainer,
} from './documentosProcessoStyles';

import {
  buscarDocumentosProcessoPorIdProcessoService,
  buscarTodosIdsProcessoDocumentoPorIdProcessoService,
  deleteDocumentoProcessoService,
} from '../../../services';

import { TableUtils, toastUnmappedException, getNewPage } from '../../../helpers';

import { documentosProcessoController as controller } from './documentosProcessoController';
import { loadingInspecaoProcessoController } from '../loadingInspecaoProcessoController';
import { EnviarEmailDocumentoProcesso } from '../EnviarEmailDocumentoProcesso/EnviarEmailDocumentoProcesso';
import OptionDialogNew from '../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';

const ID_PROCESSO_DOCUMENTO = 'idProcessoDocumento';

function DocumentosProcessoComponent({
  idProcesso, podeModificarFase, setLoadings, updatePage,
}) {
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const [allIdsDocumentos, setAllIdsDocumentos] = React.useState(new Map());
  const [selectAllIdsDocumentos, setSelectAllIdsDocumentos] = React.useState(false);

  const [documentos, setDocumentos] = React.useState(controller.makeDocumentos());
  const [idProcessoDocumento, setIdProcessoDocumento] = React.useState(null);

  const salvarDocumentoRef = React.useRef();
  const enviarEmailRef = React.useRef();

  function handleShowDeleteDialog(idDocumento) {
    return () => {
      setIdProcessoDocumento(idDocumento);
      setShowDeleteDialog(oldValue => !oldValue);
    };
  }

  function handleSelectAllIdsDocumentos(e) {
    const { checked } = e.target;
    const newDocumentos = TableUtils.updateAllItensSelectionByNewSelectionValue({
      items: documentos.content,
      newSelectionValue: checked,
      ids: allIdsDocumentos,
      itemIdFieldName: ID_PROCESSO_DOCUMENTO,
    });
    setDocumentos({ ...documentos, content: newDocumentos.items });
    setAllIdsDocumentos(newDocumentos.ids);
    setSelectAllIdsDocumentos(checked);
  }

  function handleOpenEdit(idDocumento) {
    return () => {
      setIdProcessoDocumento(idDocumento);
      salvarDocumentoRef.current.handleOpen(idDocumento);
    };
  }

  function handleSelectRow(idDocumento) {
    return () => {
      const newDocumentos = TableUtils.updateUniqueItemSelectionByCurrentId({
        currentId: idDocumento,
        items: documentos.content,
        ids: allIdsDocumentos,
        itemIdFieldName: ID_PROCESSO_DOCUMENTO,
      });
      setAllIdsDocumentos(newDocumentos.ids);
      setDocumentos({ ...documentos, content: newDocumentos.items });
    };
  }


  async function updateTableAfterChangePageableAttributes(pageable) {
    setLoadings(loadingInspecaoProcessoController.changeLoadingDocumentos(true));
    try {
      const documentosResponse = await buscarDocumentosProcessoPorIdProcessoService(idProcesso, pageable);
      const newDocumentos = controller.makeDocumentos(documentosResponse.data);

      let updatedDocumentos = null;
      if (selectAllIdsDocumentos) {
        updatedDocumentos = TableUtils.updateAllItensSelectionByNewSelectionValue({
          newSelectionValue: selectAllIdsDocumentos,
          ids: allIdsDocumentos,
          items: newDocumentos.content,
          itemIdFieldName: ID_PROCESSO_DOCUMENTO,
        });
      } else {
        updatedDocumentos = TableUtils.updateItensSelectionByIds({
          ids: allIdsDocumentos,
          items: newDocumentos.content,
          itemIdFieldName: ID_PROCESSO_DOCUMENTO,
        });
      }

      setDocumentos({ ...newDocumentos, content: updatedDocumentos.items });
      setAllIdsDocumentos(updatedDocumentos.ids);
    } catch (e) {
      toastUnmappedException(e, 'Ocorreu um problema ao tentar buscar os documentos');
    } finally {
      setLoadings(loadingInspecaoProcessoController.changeLoadingDocumentos(false));
    }
  }

  async function handlePageChange(e, newPage) {
    const pageable = { size: documentos.size, page: Number(newPage) };
    updateTableAfterChangePageableAttributes(pageable).then();
  }

  function handleChangeRowsPerPage(e) {
    const size = e.target.value;
    const newPage = getNewPage(documentos.size, documentos.page, size);
    const pageable = { size, page: Number(newPage) };
    updateTableAfterChangePageableAttributes(pageable).then();
  }

  async function findAllDocumentosAfterAnyAction(withIdsDocumentos = false) {
    setLoadings(loadingInspecaoProcessoController.changeLoadingDocumentos(true));
    const pageableParam = { size: documentos.size, page: documentos.lastPage && documentos.content.length === 1 ? documentos.page - 1 : documentos.page };
    try {
      const documentosResponse = await buscarDocumentosProcessoPorIdProcessoService(idProcesso, pageableParam);
      const newDocumentos = controller.makeDocumentos(documentosResponse.data);
      setDocumentos(newDocumentos);
      if (withIdsDocumentos) {
        const idsDocumentosResponse = await buscarTodosIdsProcessoDocumentoPorIdProcessoService(idProcesso);
        setAllIdsDocumentos(controller.makeAllIdsDocumentos(idsDocumentosResponse.data));
      } else {
        setAllIdsDocumentos(controller.initializeAllIdsDocumentos);
      }
    } catch (e) {
      toastUnmappedException(e, 'Ocorreu um problema ao tentar buscar os documentos');
    } finally {
      setLoadings(loadingInspecaoProcessoController.changeLoadingDocumentos(false));
    }
  }

  async function handleDeleteDocumento() {
    setLoadings(loadingInspecaoProcessoController.changeLoadingDocumentos(true));
    try {
      await deleteDocumentoProcessoService(idProcessoDocumento).then(() => {
        findAllDocumentosAfterAnyAction(true);
        updatePage();
      });
    } catch (e) {
      toastUnmappedException(e, 'Ocorreu um problema ao tentar excluir o documento');
    } finally {
      handleShowDeleteDialog()();
      setLoadings(loadingInspecaoProcessoController.changeLoadingDocumentos(false));
    }
  }

  const screenEnabledStatus = React.useMemo(() => {
    const isDisabledSendEmail = !Array.from(allIdsDocumentos.values()).some(value => !!value);
    const isSelectedAll = Array.from(allIdsDocumentos.values()).every(value => !!value);
    return { isDisabledSendEmail, isSelectedAll };
  }, [allIdsDocumentos]);


  /**
   * Carregar dados iniciais da tela
   */
  React.useEffect(() => {
    setLoadings(loadingInspecaoProcessoController.changeLoadingDocumentos(true));
    controller.findInitialDatasOnScreen(idProcesso).then((res) => {
      setDocumentos(res.documentos);
      setAllIdsDocumentos(res.idsDocumentos);
    }).catch(() => {
      toastUnmappedException('Ocorreu um problema ao tentar buscar as informações dos documentos');
    }).finally(() => {
      setLoadings(loadingInspecaoProcessoController.changeLoadingDocumentos(false));
    });
  }, [idProcesso, setLoadings]);

  /**
   * Atualizar seleção de todos os itens com base no ids selecionados
   */
  React.useEffect(() => {
    setSelectAllIdsDocumentos(screenEnabledStatus.isSelectedAll);
  }, [screenEnabledStatus]);

  return (
    <>
      <SalvarDocumentoProcesso
        setLoadings={setLoadings}
        updatePage={updatePage}
        onUpdateTable={findAllDocumentosAfterAnyAction}
        idProcessoDocumento={idProcessoDocumento}
        idProcesso={idProcesso}
        ref={salvarDocumentoRef}
      />
      <EnviarEmailDocumentoProcesso
        ref={enviarEmailRef}
        idProcesso={idProcesso}
        setLoadings={setLoadings}
      />
      <OptionDialogNew
        open={showDeleteDialog}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={handleShowDeleteDialog()}
        onClickConfirm={handleDeleteDocumento}
        text="Deseja realmente excluir o documento? "
      />
      <DocumentosProcessoContainer>
        <HeaderContainer>
          <CustomButton
            disabled={screenEnabledStatus.isDisabledSendEmail}
            onClick={() => enviarEmailRef.current.handleOpen(allIdsDocumentos)}
          >
            <FaTelegramPlane />
            <span>Enviar</span>
          </CustomButton>
          <CustomButton
            disabled={!podeModificarFase}
            onClick={() => salvarDocumentoRef.current.handleOpen()}
          >
            <FaPlus />
            <span>Novo</span>
          </CustomButton>
        </HeaderContainer>
        <div>
          {documentos.content.length > 0 ? (
            <TableDefault
              columnsHead={(
                <TableHeaderDocumentosProcesso
                  podeModificarFase={podeModificarFase}
                  selectAll={selectAllIdsDocumentos}
                  onSelectAll={handleSelectAllIdsDocumentos}
                />
              )}
              page={documentos.page}
              rowsPerPage={documentos.size}
              totalElements={documentos.totalElements}
              totalElementsOnPage={documentos.content?.length}
              totalColumns={10}
              emptyRowsHeight={62}
              rowsPerPageOptions={[5, 10, 15]}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            >
              <TableBodyDocumentosProcesso
                podeModificarFase={podeModificarFase}
                onOpenEdit={handleOpenEdit}
                onSelectRow={handleSelectRow}
                onShowDeleteProcesso={handleShowDeleteDialog}
                content={documentos.content}
              />
            </TableDefault>
          ) : (
            <WarningContainer>
              <FaExclamationTriangle size={48} color="#FFA000" />
              <p>Não existem documentos anexados ao Processo</p>
            </WarningContainer>
          )}
        </div>
      </DocumentosProcessoContainer>
    </>
  );
}

export const DocumentosProcesso = DocumentosProcessoComponent;
