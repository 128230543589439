import React from 'react';
import { useDispatch } from 'react-redux';
import { initializePageAction } from '../../../store/theme.actions';
import withTokenInterceptor from '../../../hoc/withTokenInterceptor/withTokenInterceptor';
import ImportarBordero from '../../../components/Bordero/Importar/ImportarBordero';

function ImportarBorderoPage() {
  const dispatch = useDispatch();


  dispatch(initializePageAction('Importar Borderô'));

  return (
    <ImportarBordero />
  );
}

export default withTokenInterceptor(ImportarBorderoPage);
