import React from 'react';
import cx from 'classnames';
import {
  FormControl, InputLabel, Input, CircularProgress,
} from '@material-ui/core';
import TooltipUI from '../../Tooltip/Tooltip';
import useStyles from './InputField.styles';

function InputField({
  label,
  disabled = false,
  isForm = false,
  loading = false,
  error = false,
  errorMessage = '',
  maxLength = 150,
  fullWidth = false,
  labelProps,
  tooltipProps,
  inputProps,
  formProps,
  autoComplete = 'off',
  ...props
}) {
  const styles = useStyles();

  function getEndAdornment() {
    return loading ? <CircularProgress size={isForm ? 15 : 16} /> : null;
  }

  function buildInputGroup() {
    return (
      <>
        {label && <InputLabel error={error} {...labelProps}>{label}</InputLabel>}
        <Input
          error={error}
          disabled={disabled || loading}
          endAdornment={getEndAdornment()}
          autoCapitalize="off"
          autoComplete="nope"
          autoCorrect="off"
          inputProps={{
            maxLength,
            ...inputProps,
            ...autoComplete,
          }}
          {...props}
        />
      </>
    );
  }

  function buildOutlinedGroup() {
    return (
      <FormControl
        {...formProps}
        error={error}
        fullWidth={fullWidth}
        className={cx(styles.outlinedRoot, { [styles.loading]: loading })}
      >
        {buildInputGroup()}
      </FormControl>
    );
  }

  function buildFormInput() {
    return (
      <div
        {...formProps}
        className={
          cx(styles.formRoot, { [styles.loading]: loading, [styles.fullWidth]: fullWidth })
        }
      >
        {buildInputGroup()}
      </div>
    );
  }

  function buildField() {
    const content = isForm ? buildFormInput() : buildOutlinedGroup();
    if (error) {
      return (
        <TooltipUI error={error} title={errorMessage} placement="bottom-start" {...tooltipProps}>
          {content}
        </TooltipUI>
      );
    }
    return content;
  }


  return buildField();
}

export default React.memo(InputField);
