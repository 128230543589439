import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  root: {
    textAlign: 'right',
  },
  startAdornment: {
    fontSize: '0.75rem',
    color: '#a2a0a0',
    marginLeft: '3px',
  },
})
