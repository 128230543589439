import { makeErrorField } from '../../../../../../helpers/utils/form.utils';
import {
  atualizarDocumentoClienteService,
  inserirDocumentoClienteService,
} from '../../../../../../services/core/cliente/cliente.services';


class SalvarDocumentoClienteController {
  makeErrorFields() {
    return {
      documentoCliente: makeErrorField(),
      tipo: makeErrorField(),
      descricao: makeErrorField(),
    };
  }

  makeFormFields(values) {
    return {
      nomeDocumento: values?.nomeDocumento || '',
      tipo: values?.tipo || '',
      descricao: values?.descricao || '',
      isDocumentoOriginal: values?.serDocumentoOriginal || false,
    };
  }

  makePayload(idCliente, idClienteDocumento, formData) {
    formData.set('idCliente', idCliente);

    const arquivado = !!formData.get('isDocumentoOriginal');
    formData.set('isDocumentoOriginal', arquivado);

    if (idClienteDocumento) {
      formData.delete('documentoCliente');
      formData.set('idClienteDocumento', idClienteDocumento);
    }

    return formData;
  }

  async salvarDocumento(idCliente, idClienteDocumento, formData) {
    const payload = this.makePayload(idCliente, idClienteDocumento, formData);

    if (idClienteDocumento) {
      await atualizarDocumentoClienteService(payload);
    } else {
      await inserirDocumentoClienteService(payload);
    }
  }
}

export const salvarClienteProcessoController = new SalvarDocumentoClienteController();
