import React from 'react';
import PropTypes from 'prop-types';

import { Container, ContainerExternalLink } from './NavigationLinkStyles';

function NavigationLinkComponent({
  text, Icon, iconColor, size, exact, minimized, isDarkMode, newTab, ...linkProps
}) {
  let activeIconColor = 'white';
  if (isDarkMode && iconColor) {
    activeIconColor = iconColor;
  }

  let defaultIconColor = '#707070';
  if (isDarkMode && iconColor) {
    defaultIconColor = iconColor;
  } else if (isDarkMode) {
    defaultIconColor = 'white';
  }

  return (
    <>
      { !newTab ? (
        <Container
          {...linkProps}
          exact={exact}
          minimized={minimized ? 1 : 0}
          $activeIconColor={activeIconColor}
          $defaultIconColor={defaultIconColor}
          $isDarkMode={isDarkMode}
        >
          {Icon && <i><Icon size={size} /></i>}
          <span>{text}</span>
        </Container>
      ) : (
        <ContainerExternalLink
          href={linkProps?.to}
          underline="none"
          target="_blank"
          rel="noopener noreferrer"
          minimized={minimized ? 1 : 0}
          $activeIconColor={activeIconColor}
          $defaultIconColor={defaultIconColor}
          $isDarkMode={isDarkMode}
        >
          {Icon && <i><Icon size={size} /></i>}
          <span>{text}</span>
        </ContainerExternalLink>
      )
}
    </>
  );
}


NavigationLinkComponent.propTypes = {
  text: PropTypes.string.isRequired,
  minimized: PropTypes.bool.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  iconColor: PropTypes.string,
  Icon: PropTypes.elementType,
  exact: PropTypes.bool,
  size: PropTypes.number,
};

NavigationLinkComponent.defaultProps = {
  size: 25,
  exact: true,
  Icon: null,
  iconColor: null,
};


export const NavigationLink = NavigationLinkComponent;
