import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton } from '@material-ui/core';
import useStyles from './SearchButton.styles';

function SearchButton({ ...props }) {
  const styles = useStyles();
  return (
    <IconButton className={styles.root} {...props}>
      <SearchIcon />
    </IconButton>
  );
}

export default React.memo(SearchButton);
