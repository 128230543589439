import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import WrapperSubTitleForm from '../../../UI/WrapperSubTitleForm/WrapperSubTitleForm';
import CampoObrigatorio from '../../../UI/CampoObrigatorio/CampoObrigatorio';
import useStyles from './enviarProcesso.styles';
import SelectorField from '../../../UI/Field/Selector/SelectorField';
import TextareaField from '../../../UI/Field/Textarea/TextareaField';
import Dica from '../../../UI/Dica/Dica';
import { buildInitialStatesEnviarProcesso } from '../factory/prazoExpirado.factory';
import {
  findFasesAction,
  findMotivosBaixaAction,
  findPastasFaseAction,
} from '../../../../store/Global/LoadSelectors/loadSelectors.saga';
import { getCurrentIdUsuario, getRequiredLabel } from '../../../../helpers/utils/utils';
import { enviarProcesso } from '../../../../services/core/acordoseguro/prazoExpirado.service';
import DialogNew from '../../../../layouts/FormDialog/DialogNew';

function EnviarProcesso({
  open, onClose, nroProcessoEnviar, setLoading, recarregarTabela,
}) {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [formFields, setFormFields] = useState(buildInitialStatesEnviarProcesso());
  const [faseExigeMotivo, setFaseExigeMotivo] = useState(false);
  const [habilitarEnviar, setHabilitarEnviar] = useState(false);

  const allFases = useSelector(states => states.selectors.fases);
  const pastasDaFase = useSelector(states => states.selectors.pastasDaFase);
  const motivosBaixa = useSelector(states => states.selectors.motivosBaixa);

  useEffect(() => {
    dispatch(findFasesAction(true));
    dispatch(findMotivosBaixaAction());
  }, [dispatch]);

  useEffect(() => {
    if (faseExigeMotivo) {
      setHabilitarEnviar(formFields.idFase.value !== '' && formFields.idMotivoBaixa.value !== '');
    } else {
      setHabilitarEnviar(formFields.idFase.value !== '');
    }
  }, [formFields.idFase.value, formFields.idMotivoBaixa.value, faseExigeMotivo]);

  const onChangeSelectorsFields = useCallback((event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: formFields[name].onChange(value) });

    if (name === 'idFase') {
      setFaseExigeMotivo(allFases.selector.filter(fase => value === fase.id)[0]?.aux);
      dispatch(findPastasFaseAction(value, 'S'));
      setFormFields({ ...formFields, idFasePasta: formFields.idFasePasta.onChange('') });
      setFormFields({ ...formFields, idMotivoBaixa: formFields.idMotivoBaixa.onChange('') });
    }
  }, [formFields, dispatch, allFases.selector]);

  const onCloseHandler = () => {
    document.getElementById('enviar-processo-form').reset();
    setFormFields(buildInitialStatesEnviarProcesso());
    onClose();
  };

  async function onSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const idProcesso = [];
    [Object.entries(nroProcessoEnviar).filter(idz => idz[1] === true)][0].forEach((processo) => {
      idProcesso.push(+processo[0]);
    });
    const payload = {
      idProcesso,
      idUsuario: +getCurrentIdUsuario(),
      idFase: +formData.get('idFase') === 0 ? null : +formData.get('idFase'),
      idFasePasta: +formData.get('idFasePasta') === 0 ? null : +formData.get('idFasePasta'),
      idMotivoBaixa: +formData.get('idMotivoBaixa') === 0 ? null : +formData.get('idMotivoBaixa'),
      texto: formData.get('texto'),
    };

    try {
      setLoading(true);
      await enviarProcesso(payload);

      onCloseHandler();
      recarregarTabela();
    } catch (e) {
      const { validations } = e.response.data;
      if (validations) {
        const newForm = validations.reduce((f, erro) => {
          const newField = f[erro.field].updateErrors(erro.message);
          return { ...f, [erro.field]: newField };
        }, { ...formFields });
        setFormFields(newForm);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <DialogNew
      open={open}
      text={['Prazos Expirados > Enviar Processo']}
      onClose={onCloseHandler}
      className={styles.dialog}
    >
      <form id="enviar-processo-form" onSubmit={onSubmit}>
        {formFields.idFase.error === true ? (
          <CampoObrigatorio visible />
        ) : (
          ''
        )}

        <WrapperSubTitleForm title="Informações" />
        <div className={styles.div__inputs}>
          <div>
            <SelectorField
              isForm
              label="Fase*"
              name={formFields.idFase.name}
              value={formFields.idFase.value}
              items={allFases.selector}
              loading={allFases.loading}
              error={formFields.idFase.error}
              errorMessage={formFields.idFase.errorMessage}
              onChange={onChangeSelectorsFields}
            />
          </div>
          <div>
            <SelectorField
              disabled={!formFields.idFase.value || pastasDaFase.selector.isEmpty}
              isForm
              label="Pasta"
              name={formFields.idFasePasta.name}
              value={formFields.idFasePasta.value}
              items={pastasDaFase.selector}
              loading={pastasDaFase.loading}
              onChange={onChangeSelectorsFields}
            />
          </div>
          <div>
            <SelectorField
              disabled={!faseExigeMotivo}
              isForm
              label={getRequiredLabel('Motivo', faseExigeMotivo)}
              name={formFields.idMotivoBaixa.name}
              value={formFields.idMotivoBaixa.value}
              items={motivosBaixa.selector}
              loading={motivosBaixa.loading}
              onChange={onChangeSelectorsFields}
            />
          </div>

          <div>
            <span className={styles.div__dica}>
              <WrapperSubTitleForm className={styles.div__subtitleDica} title="Comentários no Acompanhamento" />
              <Dica mensagemDica="Esse comentário será exibido no Acompanhamento do Processo" />
            </span>
            <TextareaField
              label=""
              classForm={styles.areaSize}
              name={formFields.texto.name}
            />
          </div>
        </div>
        <div className={styles.div__footer}>
          <Button
            onClick={onCloseHandler}
          >
            Cancelar
          </Button>
          <Button
            disabled={!habilitarEnviar}
            type="submit"
          >
            Confirmar
          </Button>

        </div>
      </form>
    </DialogNew>
  );
}

export default EnviarProcesso;
