import React, { useEffect, useState } from 'react';
import { EditorTexto, Root, ClearButton } from './atualizarMuralDashboard.styles';
import { salvarInformacoesMuralService } from '../../services';
import { DashboardController } from '../../controller';
import Editor from '../UI/EditorTexto';
import { BodyDashboardDrawer, FooterDashboardDrawer } from '../DashboardDrawer/DashboardDrawer';
import ButtonUI from '../UI/Button/ButtonUI';

function AtualizarMuralDashboardComponent({ setLoading, setInformacoesMural, handleCloseSideDrawer }) {
  const [muralValue, setMuralValue] = useState(null);

  const onChangeMural = (event) => {
    setMuralValue(event);
  };

  const onClickLimpar = () => {
    setMuralValue(null);
  };


  const onClickPublicarMural = async () => {
    setLoading(true);
    try {
      await salvarInformacoesMuralService({ informacaoMural: muralValue });
      setInformacoesMural(muralValue);
      handleCloseSideDrawer(false);
    } catch (e) {
      // Nenhum tratamento definido...
      // Ver qnd a imagem é muito grande...
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    setLoading(true);
    DashboardController.buscarInformacoesMural().then((res) => {
      setMuralValue(res.data);
    }).finally(() => setLoading(false));
  }, [setLoading]);

  return (
    <>
      <BodyDashboardDrawer>
        <Root>
          <EditorTexto>
            <Editor
              isMural
              isAllModules
              name="mural"
              label="Editar"
              value={muralValue}
              readOnly={false}
              placeholder="Digite um novo Mural"
              onChange={onChangeMural}
            />
          </EditorTexto>
          <div style={{ paddingLeft: '26px' }}>
            <ClearButton
              disabled={muralValue === '<p><br></p>' || !muralValue}
              onClick={onClickLimpar}
              iconFontAwesome="fas fa-eraser"
              label="Limpar"
            />
          </div>
        </Root>
      </BodyDashboardDrawer>
      <FooterDashboardDrawer>
        <ButtonUI
          color="error"
          onClick={handleCloseSideDrawer}
        >
          Cancelar
        </ButtonUI>
        <ButtonUI
          color="success"
          onClick={onClickPublicarMural}
        >
          Publicar
        </ButtonUI>
      </FooterDashboardDrawer>
    </>
  );
}

export const AtualizarMuralDashboard = AtualizarMuralDashboardComponent;
