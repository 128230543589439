import React from 'react';
import cx from 'classnames';

import { ListItem, Typography } from '@material-ui/core';

import useStyles from './ContentList.styles';
import CheckboxField from '../../Field/Checkbox/CheckboxField';

function ContentList({
  disabled,
  title,
  model,
  withCheckbox,
  selected,
  active,
  onClick,
  onChangeCheckbox,
}) {
  const styles = useStyles();
  /** Methods */
  function getRowListClass() {
    if (withCheckbox) {
      return cx({ [styles.isText]: false });
    }
    return cx({ [styles.isText]: true });
  }

  const content = withCheckbox ? (
    <CheckboxField
      disabled={disabled}
      checked={model.value}
      name={model.name}
      label={title}
      onChange={onChangeCheckbox}
      propsFormLabel={{ className: styles.spaceCheckbox }}
      className={styles.spaceCheckboxInterno}
    />
  ) : (
    <Typography component="p">{title}</Typography>
  );
  return (
    <ListItem
      selected={selected}
      button={selected}
      divider
      onClick={onClick}
      className={cx({ [getRowListClass()]: true, [styles.active]: active })}
    >
      {content}
    </ListItem>
  );
}

ContentList.defaultProps = {
  disabled: false,
  title: '',
  active: false,
  model: {
    name: undefined,
    value: undefined,
    error: undefined,
    errorMessage: undefined,
  },
  selected: false,
  withCheckbox: false,
  onClick: () => null,
  onChangeCheckbox: () => null,
};

export default ContentList;
