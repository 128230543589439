import React, { useCallback, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { RiFileSearchLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonUI, CampoObrigatorio, InputField, SelectorField,
} from '../../../../components';
import SubTitleForm from '../../../../components/UI/SubTitleForm/SubTitleForm';
import { getRequiredLabel } from '../../../../helpers';
import useStyles, {
  ButtonPesquisarCliente, Container, Footer, Inputs, InputsNovoRecebimento,
} from './RecebimentoClienteStyles';
import TooltipUI from '../../../../components/UI/Tooltip/Tooltip';
import MoneyField from '../../../../components/UI/Field/Money/MoneyField';
import { useRecebimentoDispatch, useRecebimentoSelector } from '../../../../store/Recebimento/RecebimentoClienteProvider';
import { setRequestFieldsFocusAction, setRequestFieldsValueAction, setSimpleRecebimentoAction } from '../../../../store/Recebimento/RecebimentoCliente.store';
import { findContasBancariasAction, findTiposFormasPagamentoAction } from '../../../../store/Global/LoadSelectors/loadSelectors.saga';
import { buscarInfosEditarAction, inserirLancamentoAction } from '../../../../store/Recebimento/RecebimentoClientes.saga';


function LancamentoRecebimentoComponent({
  idLancamentoCliente = null,
  isEditMode = false,
}) {
  const styles = useStyles();
  const history = useHistory();

  const dispatch = useRecebimentoDispatch();
  const globalDispatch = useDispatch();

  const idContaBancaria = useRecebimentoSelector(states => states.formFields.idContaBancaria);
  const idTipoFormaPagto = useRecebimentoSelector(states => states.formFields.idTipoFormaPagto);
  const codigoCliente = useRecebimentoSelector(states => states.formFields.codigoCliente);
  const valorRecebimento = useRecebimentoSelector(states => states.formFields.valorRecebimento);
  const observacoes = useRecebimentoSelector(states => states.formFields.observacoes);
  const tiposFormasPagamentoRecebimento = useSelector(states => (states.selectors.tiposFormasPagamentoRecebimento.selector));
  const contaBancaria = useSelector(states => (states.selectors.contaBancaria.selector));
  const closeEdit = useRecebimentoSelector(states => states.closeEdit);

  const changeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setRequestFieldsValueAction(name, value));
  }, [dispatch]);

  const focusFieldsHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(setRequestFieldsFocusAction(fieldName));
  }, [dispatch]);

  const goBackPageHandler = useCallback(async () => {
    history.goBack();
  }, [history]);

  const submitHandler = useCallback(() => {
    dispatch(inserirLancamentoAction(isEditMode));
  }, [dispatch, isEditMode]);


  useEffect(() => {
    globalDispatch(findTiposFormasPagamentoAction());
    globalDispatch(findContasBancariasAction());
  }, [globalDispatch]);

  useEffect(() => {
    if (idLancamentoCliente !== null) {
      dispatch(setSimpleRecebimentoAction('idLancamentoCliente', idLancamentoCliente));
      dispatch(buscarInfosEditarAction(idLancamentoCliente));
    }
  }, [dispatch, idLancamentoCliente]);

  useEffect(() => {
    if (closeEdit) {
      history.goBack();
    }
  }, [closeEdit, submitHandler, history]);

  return (
    <>
      <Container>
        <InputsNovoRecebimento>
          <CampoObrigatorio />
          <SubTitleForm>
            Informações
          </SubTitleForm>
          <Inputs>
            <div>
              <div style={{ width: '15%' }}>
                <SelectorField
                  isForm
                  fullWidth
                  label={getRequiredLabel('Conta Corrente', true)}
                  items={contaBancaria}
                  name={idContaBancaria.name}
                  value={idContaBancaria.value}
                  error={idContaBancaria.error}
                  errorMessage={idContaBancaria.errorMessage}
                  onChange={changeFieldsHandler}
                  onFocus={focusFieldsHandler}
                />
              </div>
              <div style={{ width: '15%' }}>
                <SelectorField
                  isForm
                  fullWidth
                  label={getRequiredLabel('Tipo do Lançamento', true)}
                  items={tiposFormasPagamentoRecebimento}
                  name={idTipoFormaPagto.name}
                  value={idTipoFormaPagto.value}
                  error={idTipoFormaPagto.error}
                  errorMessage={idTipoFormaPagto.errorMessage}
                  onChange={changeFieldsHandler}
                  onFocus={focusFieldsHandler}
                />
              </div>
              <div style={{ width: '15%' }}>
                <InputField
                  isForm
                  fullWidth
                  label={getRequiredLabel('Código do Cliente', true)}
                  name={codigoCliente.name}
                  value={codigoCliente.value}
                  error={codigoCliente.error}
                  errorMessage={codigoCliente.errorMessage}
                  onChange={changeFieldsHandler}
                  onFocus={focusFieldsHandler}
                />
              </div>
              <ButtonPesquisarCliente onClick={() => window.open('/cliente', '_blank')}>
                <TooltipUI title="Não lembra o código? Clique aqui" placement="bottom-start">
                  <RiFileSearchLine />
                </TooltipUI>
              </ButtonPesquisarCliente>
            </div>
            <div>
              <div style={{ width: '20%' }}>
                <MoneyField
                  isForm
                  fullWidth
                  label={getRequiredLabel('Valor do Recebimento', true)}
                  name={valorRecebimento.name}
                  value={valorRecebimento.value}
                  error={valorRecebimento.error}
                  errorMessage={valorRecebimento.errorMessage}
                  onChange={changeFieldsHandler}
                  onFocus={focusFieldsHandler}
                />
              </div>
              <div style={{ width: '50%' }}>
                <InputField
                  isForm
                  fullWidth
                  label="Descrições"
                  name={observacoes.name}
                  value={observacoes.value}
                  placeholder="Observações a serem feitas sobre o depósito lançado..."
                  className={styles.placeholderDescription}
                  onChange={changeFieldsHandler}
                />
              </div>
            </div>
          </Inputs>
        </InputsNovoRecebimento>
        <Footer>
          <div>
            <div>
              <ButtonUI
                color="error"
                onClick={goBackPageHandler}
              >
                Cancelar
              </ButtonUI>
            </div>
            <div>
              <ButtonUI
                color="success"
                onClick={submitHandler}
              >
                Salvar
              </ButtonUI>
            </div>
          </div>
        </Footer>
      </Container>
    </>
  );
}

export const LancamentoRecebimento = withRouter(LancamentoRecebimentoComponent);
