import React from 'react';
import {
  Button,
  DialogActions, Typography,
} from '@material-ui/core';
import useStyle from './FollowUpModal.style';
import { TITLE_FOLLOW_UP, TRASH_LABEL } from './followUpModal.constants';
import DialogNew from '../../../layouts/FormDialog/DialogNew';


function FollowUpModal({
  openFollowUpModal,
  onCloseFollowUpModal,
  messageFollowUp,
  onClickDeleteDataFu,
  isOWnerAcompanhamento = false,
}) {
  const styles = useStyle();

  return (
    <DialogNew
      text={TITLE_FOLLOW_UP}
      classes={styles.dialog}
      aria-labelledby="draggable-dialog-title"
      open={openFollowUpModal}
      onClose={onCloseFollowUpModal}
    >
      <div className={styles.followUpBox}>
        <Typography
          component="pre"
          className={styles.followUpText}
        >
          {messageFollowUp}
        </Typography>
      </div>

      <DialogActions>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
          <Button
            type="submit"
            className={styles.buttons}
            disabled={!isOWnerAcompanhamento}
            onClick={() => onClickDeleteDataFu()}
          >
            {TRASH_LABEL}
          </Button>
          <Button
            className={styles.buttons}
            onClick={onCloseFollowUpModal}
          >
            Voltar
          </Button>
        </div>
      </DialogActions>
    </DialogNew>
  );
}

export default React.memo(FollowUpModal);
