export const LABEL_TIPO = 'Tipo: ';
export const LABEL_NUMERO_PROCESSO = 'Número do Processo: ';
export const LABEL_CODIGO_CLIENTE = 'Código do Cliente: ';
export const LABEL_DEVEDOR = 'Devedor: ';
export const LABEL_QTDE_TITULOS = 'Quantidade de Títulos: ';
export const LABEL_VALOR_TOTAL_PROCESSO = 'Valor Total do Processo: ';
export const LABEL_DATA_CADASTRAMENTO = 'Data e Hora: ';
export const LABEL_RESPONSAVEL_PROCESSAMENTO = 'Responsável pelo Processamento: ';
export const LABEL_TIPO_ENTRADA = 'Tipo de Entrada: ';
export const LABEL_MENSAGEM = 'Mensagem';
export const TITLE_DIALOG = 'Visualizar Log';
export const TITLE_INFORMACAOES = 'Informações Gerais';
export const VOLTAR_BUTTON_LABEL = 'VOLTAR';
