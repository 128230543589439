export const FIRST_PAGE = 0;
export const SNACKBAR_DURATION = 6000;
export const RESULT_NOT_FOUND = 'Nenhum resultado foi encontrado relacionado à sua pesquisa';
export const SEARCH_TABLE_TITLE = 'Resultado da Pesquisa';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_PT = 'DD-MM-YYYY';
export const DATE_FORMAT_PT_BAR = 'DD/MM/YYYY';
export const DATE_MASK = '##/##/####';
export const ENDERECO = 'Endereço';
export const CANCEL = 'Cancelar';
export const CONFIRM = 'Confirmar';
export const OK = 'OK';
export const YES = 'Sim';
export const NO = 'Não';
export const CARREGANDO = 'Carregando...';
export const REQUEST_FIELD_ERROR_MESSAGE = 'Campo obrigatório';
