import FieldModel, { POSITIVE_INTEGER, DATE } from '../../models/FieldModel';
import { getAttributeExistingObject, toBrazilianDate } from '../utils/utils';

function getTitulo(titulo) {
  if (!titulo) {
    return titulo;
  }

  const modifiedTitulo = { ...titulo };
  modifiedTitulo.possuirProtesto = titulo.possuirProtesto ? 'S' : 'N';
  return modifiedTitulo;
}


export function buildDadoRetorno(dadoRetorno) {
  return {
    nome: new FieldModel({
      name: 'nome',
      value: getAttributeExistingObject(dadoRetorno, 'nome'),
    }),
    valor: new FieldModel({
      name: 'valor',
      value: getAttributeExistingObject(dadoRetorno, 'valor'),
    }),
  };
}

function getDadosRetorno(modifiedTitulo) {
  const dadosRetorno = getAttributeExistingObject(modifiedTitulo, 'dadosRetorno');
  if (!dadosRetorno) {
    return [];
  }
  return dadosRetorno.map(buildDadoRetorno);
}

export function buildTitulo(titulo) {
  const modifiedTitulo = getTitulo(titulo);
  const modifiedDadosRetorno = getDadosRetorno(modifiedTitulo);

  return {
    dataVencimento: new FieldModel({
      name: 'dataVencimento',
      value: toBrazilianDate(getAttributeExistingObject(modifiedTitulo, 'dataVencimento')),
      type: DATE,
    }),
    idBorderoTitulo: new FieldModel({
      name: 'idBorderoTitulo',
      value: getAttributeExistingObject(modifiedTitulo, 'idBorderoTitulo'),
      type: POSITIVE_INTEGER,
    }),
    tipoDocumento: new FieldModel({
      name: 'tipoDocumento',
      value: getAttributeExistingObject(modifiedTitulo, 'tipoDocumento'),
    }),
    idTipoTitulo: new FieldModel({
      name: 'idTipoTitulo',
      value: getAttributeExistingObject(modifiedTitulo, 'idTipoTitulo'),
      maxLength: 2,
    }),
    numero: new FieldModel({
      name: 'numero',
      value: getAttributeExistingObject(modifiedTitulo, 'numero'),
      maxLength: 25,
    }),
    possuirProtesto: new FieldModel({
      name: 'possuirProtesto', value: getAttributeExistingObject(modifiedTitulo, 'possuirProtesto', 'N'),
    }),
    valorProtesto: new FieldModel({
      name: 'valorProtesto', value: getAttributeExistingObject(modifiedTitulo, 'valorProtesto', '0.0'),
    }),
    valorTitulo: new FieldModel({
      name: 'valorTitulo', value: getAttributeExistingObject(modifiedTitulo, 'valorTitulo', '0.0'),
    }),
    dadosRetorno: modifiedDadosRetorno,
  };
}

export function buildTitulos(titulos) {
  if (!titulos) {
    return [buildTitulo()];
  }

  return titulos.map(buildTitulo);
}
