import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  div__root: {
    width: '100%',
    display: 'flex',
    marginTop: '18px',
    justifyContent: 'flex-end',
  },
  div__buttonPesquisar: {
    backgroundColor: '#ff1381',
    color: 'white',
    width: '40px',
    height: '40px',
    '&:hover': {
      backgroundColor: '#ff1381',
      opacity: '.7',
    },
  },
  field_calendario: {
    width: '334px',
    marginRight: '10px',
  },
  div__pesquisas: {
    display: 'flex',
    flexDirection: 'column',
  },
  div__pesquisaAvancadaBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '5px',
    '& button': {
      marginRight: '41px',
      fontWeight: 'bold',
      textTransform: 'none',
      fontSize: '12px',
    },
  },
  div__pesquisaAvancada: {
    display: 'flex',
    width: '100%',
  },
  div__avancada: {
    display: 'flex',
    width: '100%',
    paddingLeft: '45px',
  },
  div__fieldDevedorCliente: {
    width: '100%',
    marginRight: '8px',
  },
  div__fieldProcessoAcordo: {
    width: '250px',
    marginRight: '8px',
  },
  div__fieldsCalendario: {
    display: 'flex',

  },
  div__table: {
    display: 'flex',
    paddingTop: '35px',
  },
  div__icons: {
    width: '32px',
    display: 'flex',
    justifyContent: 'space-around',
    alignContent: 'center',
    '& i': {
      fontSize: '16px',
    },
  },
  pesquisaAvancadaButton: {
    color: '#034ddc',
  },
  pesquisaSimplesButton: {
    color: '#707070',
  },
});
