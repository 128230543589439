import { TableCell, TableRow } from '@material-ui/core';
import React, { useCallback } from 'react';
import { pesquisarLancamentosNaoPrestadosClienteSelecionadoAction } from '../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import { setSimpleValueNaoPrestadosAction } from '../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import OrdenacaoColuna from '../../UI/Tabela/Ordenacao/OrdenacaoColuna';

function TableHeaderLancamentosNaoPrestadosComponent() {
  const dispatch = useCCClienteDispatch();

  const columnOrdenacao = useCCClienteSelector(states => (states.naoPrestados.columnOrdenacao));
  const arrowDirection = useCCClienteSelector(states => (states.naoPrestados.arrowDirection));
  const lancamentosNaoPrestados = useCCClienteSelector(states => ([...states.naoPrestados.lancamentosNaoPrestados]));
  const exibirApenasBloqueados = useCCClienteSelector(states => (states.naoPrestados.exibirApenasBloqueados));
  const rowsPerPage = useCCClienteSelector(states => (states.naoPrestados.rowsPerPage));
  const page = useCCClienteSelector(states => (states.naoPrestados.page));

  const setColumnOrdenacao = useCallback((column) => {
    dispatch(setSimpleValueNaoPrestadosAction('columnOrdenacao', column));
  }, [dispatch]);

  const setArrowDirection = useCallback((internalReturn) => {
    dispatch(setSimpleValueNaoPrestadosAction('arrowDirection', internalReturn));
  }, [dispatch]);

  const onClickColumnOrdenar = useCallback(() => {
    if (lancamentosNaoPrestados.length > 0) {
      dispatch(setSimpleValueNaoPrestadosAction('page', 0));
      dispatch(pesquisarLancamentosNaoPrestadosClienteSelecionadoAction(
        exibirApenasBloqueados, page, rowsPerPage, columnOrdenacao,
      ));
    }
  }, [dispatch, columnOrdenacao, exibirApenasBloqueados, lancamentosNaoPrestados, page, rowsPerPage]);

  return (
    <TableRow>
      <TableCell height={36} width={87}>
        <OrdenacaoColuna
          columnLabel="Data"
          idColumn="data"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickColumnOrdenar()}
        />
      </TableCell>
      <TableCell height={36} width={87}>
        Processo
      </TableCell>
      <TableCell height={36} width={137}>
        <OrdenacaoColuna
          columnLabel="Tipo do Lançamento"
          idColumn="tipoDescricao"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickColumnOrdenar()}
        />
      </TableCell>
      <TableCell height={36} width={479}>
        Histórico
      </TableCell>
      <TableCell height={36} width={129}>
        Crédito
      </TableCell>
      <TableCell height={36} width={113}>
        Débito
      </TableCell>
      <TableCell height={36} width={109}>
        Ações
      </TableCell>
    </TableRow>
  );
}
export const TableHeaderlancamentosNaoPrestados = TableHeaderLancamentosNaoPrestadosComponent;
