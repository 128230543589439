import React from 'react';
import { IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { checkPermission } from '../../../../../helpers/utils/utils';

import Tooltip from '../../../Tooltip/Tooltip';

import useStyles from './PermissionIconButton.styles';


function PermissionIconButton({
  children, title, idAllow, userAllow, placement = 'bottom-start', isEnable = true, ...props
}) {
  const styles = useStyles();
  const userData = useSelector(state => state.authentication.userInformation);
  const { tipoUsuario, permissoes } = userData;
  let disabled = checkPermission(permissoes, idAllow, tipoUsuario, userAllow);

  if (!idAllow) {
    disabled = false;
  }
  if (!disabled) {
    disabled = !isEnable;
  }

  let content = (
    <IconButton classes={{ root: styles.root }} disabled={disabled} {...props}>
      {children}
    </IconButton>
  );
  if (!disabled && isEnable) {
    content = (
      <Tooltip title={title} placement={placement}>
        {content}
      </Tooltip>
    );
  }
  return content;
}

export default PermissionIconButton;
