import {
  all, call, delay, put, takeLatest,
} from 'redux-saga/effects';

import { toast } from 'react-toastify';
import {
  getAuthenticationInformationAfterRefresh,
  getAuthentitionInformation,
} from '../../services/autenticacao/autenticacao.services';

import {
  authenticateFailedAction,
  authenticateStatedAction,
  authenticateSucceededAction,
  limpaDialogAlterarSenhaAction,
  logoutSucceedAction,
  setErrorFieldAtualizarSenhaAction,
  setOpenDialogAlterarSenhaAction,
} from './login.store';

import constants from '../../helpers/constants/authenticationSaga.constants';
import { loadTheme } from '../theme.store';
import { alterarSenha } from '../../services/core/login/login.services';
import { finalizaTimerControleStatusComercial, timerControleStatusComercial } from '../Global/ControleStatusUsuarioComercial/controleStatusUsuarioComercial';

const AUTHENTICATE = 'AUTHENTICATE';
const CHECK_TIMEOUT = 'CHECK_TIMEOUT';
const CHECK_AUTHENTICATE_STATE = 'CHECK_AUTHENTICATE_STATE';
const REFRESH_TOKEN = 'REFRESH_TOKEN';
const INITIATE_LOGOUT = 'INITIATE_LOGOUT';
const ALTERAR_SENHA_ACTION = 'ALTERAR_SENHA_ACTION';


export const authenticateAction = (usernameParam, passwordParam) => ({
  type: AUTHENTICATE,
  username: usernameParam,
  password: passwordParam,
});

export const checkTimeoutAction = expirationInParam => ({
  type: CHECK_TIMEOUT,
  expirationIn: expirationInParam,
});

export const refreshTokenAction = () => ({
  type: REFRESH_TOKEN,
});

export const checkAuthenticateStatusAction = () => ({
  type: CHECK_AUTHENTICATE_STATE,
});

export const logoutAction = () => ({
  type: INITIATE_LOGOUT,
});

export const alterarSenhaAction = (senhaAtual, novaSenha, confirmarNovaSenha) => ({
  type: ALTERAR_SENHA_ACTION,
  senhaAtual,
  novaSenha,
  confirmarNovaSenha,
});

function getExpirationDate(expirationIn) {
  return new Date(new Date().getTime() + expirationIn * 1000);
}

function getErrorMessage(exception) {
  if (exception.response) {
    return exception.response.data.message;
  }
  return constants.FALHA_CONEXAO;
}

function getPermissoes(response) {
  if (response.permissoes) {
    return response.permissoes;
  }
  return '';
}

function buildUsuario(usuario, accessToken, refreshToken) {
  if (!accessToken && !refreshToken) {
    return usuario;
  }
  return {
    ...usuario,
    accessToken,
    refreshToken,
    permissoes: getPermissoes(usuario),
    expirationDate: getExpirationDate(usuario.expirationIn),
  };
}

function* authenticateStep(response, accessToken, refreshToken, refresh = false) {
  const usuario = yield buildUsuario(response, accessToken, refreshToken);
  if (!refresh) {
    yield put(authenticateSucceededAction(usuario));
  }

  yield localStorage.setItem('userData', JSON.stringify(usuario));
  yield sessionStorage.setItem('nome', usuario.nomeUsuario);

  const expirationTime = yield new Date(usuario.expirationDate).getTime() - process.env.REACT_APP_TOKEN_DELAY_TIMEOUT * 1000 - new Date().getTime();

  yield timerControleStatusComercial(response.idUsuario);

  if (expirationTime > 0) {
    yield put(loadTheme(usuario.idOpcaoCor));
    yield put(checkTimeoutAction(expirationTime));
  } else {
    yield put(logoutAction());
  }
}

export function* authenticateHandler(action) {
  const { username, password } = action;
  yield put(authenticateStatedAction());
  try {
    const response = yield getAuthentitionInformation(username, password);
    yield authenticateStep(response.data, response.headers['access-token'], response.headers['refresh-token']);
  } catch (exception) {
    const errorMessage = getErrorMessage(exception);
    yield put(authenticateFailedAction(errorMessage));
    yield put(logoutAction());
  }
}

export function* checkTimeoutHandler(action) {
  yield delay(action.expirationIn);
  yield put(refreshTokenAction());
}

export function* refreshTokenHandler() {
  try {
    const refreshToken = yield JSON.parse(localStorage.getItem('userData')).refreshToken;
    const response = yield getAuthenticationInformationAfterRefresh(refreshToken);
    yield authenticateStep(response.data, response.headers['access-token'], response.headers['refresh-token'], true);
  } catch (exception) {
    const errorMessage = getErrorMessage(exception);
    yield put(authenticateFailedAction(errorMessage));
    yield put(logoutAction());
  }
}

export function* logoutHandler() {
  finalizaTimerControleStatusComercial();

  yield call([localStorage, 'removeItem'], 'userData');
  yield put(logoutSucceedAction());
}

export function* checkAuthenticateStatusHandler() {
  const usuario = yield JSON.parse(localStorage.getItem('userData'));
  if (!usuario) {
    yield put(logoutAction());
    return;
  }

  if (new Date(usuario.expirationDate) <= new Date()) {
    yield put(refreshTokenAction());
    return;
  }
  yield authenticateStep(usuario);
}

export function* alterarSenhaHandler(action) {
  const { senhaAtual, novaSenha, confirmarNovaSenha } = action;

  try {
    yield call(alterarSenha, senhaAtual, novaSenha, confirmarNovaSenha);
    yield put(setOpenDialogAlterarSenhaAction(false));
    yield put(limpaDialogAlterarSenhaAction());
    toast.success('Senha alterada com sucesso!');
  } catch (exception) {
    if (exception.response?.data?.message) {
      toast.error(exception?.response?.data?.message || 'Erro não mapeado!', { style: { width: '392px' } });
    } else if (exception.response && exception.response?.data && exception.response?.data?.validations) {
      const tamElementos = exception.response.data.validations.length;
      const elementos = exception.response.data.validations;

      for (let i = 0; i < tamElementos; i += 1) {
        const campo = elementos[i].field;
        const errorMessage = elementos[i].message;
        yield put(setErrorFieldAtualizarSenhaAction(campo, errorMessage));
      }
      toast.error('Revise as informações e preencha os campos corretamente!', { style: { width: '392px' } });
    }
  }
}

export default function* watchAuthentication() {
  yield all([
    takeLatest(AUTHENTICATE, authenticateHandler),
    takeLatest(CHECK_AUTHENTICATE_STATE, checkAuthenticateStatusHandler),
    takeLatest(REFRESH_TOKEN, refreshTokenHandler),
    takeLatest(INITIATE_LOGOUT, logoutHandler),
    takeLatest(CHECK_TIMEOUT, checkTimeoutHandler),
    takeLatest(ALTERAR_SENHA_ACTION, alterarSenhaHandler),
  ]);
}
