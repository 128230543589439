import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectorField from '../../../../../UI/Field/Selector';
import Grid24 from '../../../../../UI/Grid24';
import FormWrapperWithTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import InputField from '../../../../../UI/Field/Input';
import CheckboxField from '../../../../../UI/Field/Checkbox/CheckboxField';
import useUtilStyles from '../../../../../../helpers/styles/utils.styles';
import {
  findCategoriasAction, findEstadosAction, findPrefeiturasNotaFiscalAction, findTiposNotasFiscaisAction,
} from '../../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import { useCrudClienteDispatch, useCrudClienteSelector } from '../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import { getRequiredLabel } from '../../../../../../helpers';
import ButtonUI from '../../../../../UI/Button/ButtonUI';
import MaskField from '../../../../../UI/Field/Mask/MaskField';
import { CNPJ_MASK, CPF_MASK } from '../../../../../../helpers/constants/layout.constants';
import Endereco from '../../../../../UI/EnderecosV2/Endereco/Endereco';
import {
  onChangeControlsEmpresaClienteNotaFiscalAction,
  setEnderecoEmpresaClienteNFAttributeFocusAction,
  setEnderecoEmpresaClienteNFAttributeValueAction,
  setEnderecoEmpresaClienteNotaFiscalAction,
  setRequestFieldsFocusEmpresaClienteNFAction,
} from '../../../../../../store/Cliente/V2/Crud/crudCliente.store';
import { getClienteEmpresaNFAction } from '../../../../../../store/Cliente/V2/Crud/crudCliente.saga';
import { CATEGORIAS } from '../../../../../../helpers/constants/enums.constants';
import NumberInput from '../../../../../UI/Field/Number/NumberInput';
import { Label } from '../PrestacaoContasStyles';

function NotasFiscaisBoletos({
  isEditMode, onChange, onChangeCheckbox, onFocus, isInsertMode, idCliente,
}) {
  const utilStyle = useUtilStyles();
  const dispatchGlobal = useDispatch();
  const dispatch = useCrudClienteDispatch();

  function makeDias() {
    return [
      { id: 1, value: '1' },
      { id: 2, value: '2' },
      { id: 3, value: '3' },
      { id: 4, value: '4' },
      { id: 5, value: '5' },
      { id: 6, value: '6' },
      { id: 7, value: '7' },
      { id: 8, value: '8' },
      { id: 9, value: '9' },
      { id: 10, value: '10' },
      { id: 11, value: '11' },
      { id: 12, value: '12' },
      { id: 13, value: '13' },
      { id: 14, value: '14' },
      { id: 15, value: '15' },
      { id: 16, value: '16' },
      { id: 17, value: '17' },
      { id: 18, value: '18' },
      { id: 19, value: '19' },
      { id: 20, value: '20' },
      { id: 21, value: '21' },
      { id: 22, value: '22' },
      { id: 23, value: '23' },
      { id: 24, value: '24' },
      { id: 25, value: '25' },
      { id: 26, value: '26' },
      { id: 27, value: '27' },
      { id: 28, value: '28' },
      { id: 29, value: '29' },
      { id: 30, value: '30' },
      { id: 31, value: '31' },
    ];
  }
  const tiposNotasFiscais = useSelector(states => states.selectors.tiposNotasFiscais.selector);
  const prefeiturasNotaFiscal = useSelector(states => states.selectors.prefeiturasNotaFiscal.selector);
  const idTipoNotaFiscal = useCrudClienteSelector(states => states.controls.idTipoNotaFiscal.copy());
  const idPrefeituraNotaFiscal = useCrudClienteSelector(states => states.controls.idPrefeituraNotaFiscal.copy());
  const descricaoNotaPersonalizada = useCrudClienteSelector(states => states.controls.descricaoNotaPersonalizada.copy());
  const detalhesInfoExtraNF = useCrudClienteSelector(states => states.controls.detalhesInfoExtraNF.copy());
  const requerInformacaoExtraNF = useCrudClienteSelector(states => states.controls.requerInformacaoExtraNF.copy());
  const autorizarEmissaoNF = useCrudClienteSelector(states => states.controls.autorizarEmissaoNF.copy());
  const autorizarEmissaoBoleto = useCrudClienteSelector(states => states.controls.autorizarEmissaoBoleto.copy());
  const requerNFDespesa = useCrudClienteSelector(states => states.controls.requerNFDespesa.copy());
  const utilizaEmpresaAlternativaEmissaoNF = useCrudClienteSelector(states => states.controls.utilizaEmpresaAlternativaEmissaoNF.copy());
  const cnpjCpf = useCrudClienteSelector(states => states.controls.empresaClienteNotaFiscal.cnpjCpf.copy());
  const email = useCrudClienteSelector(states => states.controls.empresaClienteNotaFiscal.email.copy());
  const endereco = useCrudClienteSelector(states => ({ ...states.controls.empresaClienteNotaFiscal.endereco }));
  const categoria = useCrudClienteSelector(states => states.controls.empresaClienteNotaFiscal.categoria.copy());
  const nome = useCrudClienteSelector(states => states.controls.empresaClienteNotaFiscal.nome.copy());
  const bloquearCadastroEmpresaClienteNF = useCrudClienteSelector(states => states.controls.bloquearCadastroEmpresaClienteNF);
  const bloquearBuscarEmpresaClienteNF = useCrudClienteSelector(states => states.controls.bloquearBuscarEmpresaClienteNF);
  const prazoVencimentoBoleto = useCrudClienteSelector(states => states.controls.prazoVencimentoBoleto.copy());
  const dias = React.useMemo(() => makeDias(), []);
  const diaBloqueioNotaFiscal = useCrudClienteSelector(states => states.controls.diaBloqueioNotaFiscal.copy());
  const bloquearEmissaoNotaFiscal = useCrudClienteSelector(states => states.controls.bloquearEmissaoNotaFiscal.copy());

  const estados = useSelector(states => states.selectors.estados.selector);
  const categorias = useSelector(states => states.selectors.categorias.selector);

  const maskCpfCnpj = categorias.filter(cat => cat.id === categoria?.value)[0]?.aux;

  const onChangeHandler = useCallback(
    (event) => {
      const { name, value } = event.target;
      dispatch(onChangeControlsEmpresaClienteNotaFiscalAction(name, value));
    }, [dispatch],
  );

  const setEnderecoHandler = useCallback((enderecoParam) => {
    dispatch(setEnderecoEmpresaClienteNotaFiscalAction(enderecoParam));
  }, [dispatch]);

  const setEnderecoAttributeValueHandler = useCallback((name, value) => {
    dispatch(setEnderecoEmpresaClienteNFAttributeValueAction(name, value));
  }, [dispatch]);

  const setEnderecoAttributeFocusFocusHandler = useCallback((enderecoParam) => {
    dispatch(setEnderecoEmpresaClienteNFAttributeFocusAction(enderecoParam));
  }, [dispatch]);

  const focusFieldsHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(setRequestFieldsFocusEmpresaClienteNFAction(fieldName));
  }, [dispatch]);

  const onClickPesquisarClienteEmpresaNotaFiscal = useCallback(() => {
    dispatch(getClienteEmpresaNFAction(idCliente, cnpjCpf.value, categoria.value));
  }, [dispatch, cnpjCpf, categoria, idCliente]);

  React.useEffect(() => {
    dispatchGlobal(findTiposNotasFiscaisAction());
    dispatchGlobal(findPrefeiturasNotaFiscalAction());
    dispatchGlobal(findEstadosAction());
    dispatchGlobal(findCategoriasAction());
    dispatchGlobal(findCategoriasAction());
  }, [dispatchGlobal, diaBloqueioNotaFiscal.value]);

  return (
    <FormWrapperWithTitle
      className={utilStyle.mt7}
      title="Informação para Emissão de Notas Fiscais e Boletos"
    >
      <Grid24 container isFormContainer spacing={1}>
        <Grid24 xs={24} sm={6}>
          <SelectorField
            fullWidth
            isForm
            disabled={!isEditMode && !isInsertMode}
            label={getRequiredLabel('Prefeitura Emissora da NF', isEditMode)}
            items={prefeiturasNotaFiscal}
            name={idPrefeituraNotaFiscal.name}
            value={idPrefeituraNotaFiscal.value}
            error={idPrefeituraNotaFiscal.error}
            errorMessage={idPrefeituraNotaFiscal.errorMessage}
            onFocus={onFocus}
            onChange={onChange}
          />
        </Grid24>
        <Grid24 xs={24} sm={4} className={utilStyle.ml3}>
          <SelectorField
            fullWidth
            isForm
            disabled={!isEditMode && !isInsertMode}
            label={getRequiredLabel('Tipo de Emissão', isEditMode)}
            items={tiposNotasFiscais}
            name={idTipoNotaFiscal.name}
            value={idTipoNotaFiscal.value}
            onChange={onChange}
          />
        </Grid24>
        {idTipoNotaFiscal.value === 2
          && (
            <Grid24 xs={24} sm={11}>
              <InputField
                fullWidth
                isForm
                disabled={!isEditMode && !isInsertMode}
                label={getRequiredLabel('Informe a Regra', isEditMode)}
                name={descricaoNotaPersonalizada.name}
                value={descricaoNotaPersonalizada.value}
                error={descricaoNotaPersonalizada.error}
                errorMessage={descricaoNotaPersonalizada.errorMessage}
                maxLength={100}
                onFocus={onFocus}
                onChange={onChange}
              />
            </Grid24>
          )}
      </Grid24>
      <Grid24 container isFormContainer className={utilStyle.pl8}>
        <Grid24 xs={24} sm={6}>
          <CheckboxField
            label="Requer Informações Extras na Nota Fiscal"
            disabled={!isEditMode && !isInsertMode}
            name={requerInformacaoExtraNF.name}
            checked={requerInformacaoExtraNF.value}
            onChange={onChangeCheckbox}
          />
        </Grid24>
        {requerInformacaoExtraNF.value === true
          && (
            <Grid24 xs={24} sm={15}>
              <InputField
                fullWidth
                isForm
                disabled={!isEditMode && !isInsertMode}
                label={getRequiredLabel('Detalhes sobre a Informação Extra', isEditMode && isInsertMode)}
                name={detalhesInfoExtraNF.name}
                value={detalhesInfoExtraNF.value}
                error={detalhesInfoExtraNF.error}
                errorMessage={detalhesInfoExtraNF.errorMessage}
                maxLength={100}
                onFocus={onFocus}
                onChange={onChange}
              />
            </Grid24>
          )}
      </Grid24>
      <Grid24 container isFormContainer>
        <Grid24 xs={24} sm={24} className={utilStyle.mt3ml8}>
          <CheckboxField
            label="Autoriza Emissão de Nota Fiscal"
            disabled={!isEditMode && !isInsertMode}
            name={autorizarEmissaoNF.name}
            checked={autorizarEmissaoNF.value}
            onChange={onChangeCheckbox}
          />
        </Grid24>
        <Grid24 xs={24} sm={24} className={utilStyle.mt10ml8}>
          <CheckboxField
            label="Bloquear Emissão de Nota Fiscal a partir do dia"
            disabled={!isEditMode && !isInsertMode}
            name={bloquearEmissaoNotaFiscal.name}
            checked={bloquearEmissaoNotaFiscal.value}
            onChange={onChangeCheckbox}
          />
          {bloquearEmissaoNotaFiscal.value
          && (
          <Grid24 xs={24} sm={1}>
            <SelectorField
              isForm
              disabled={!isEditMode && !isInsertMode}
              onChange={onChange}
              items={dias}
              name={diaBloqueioNotaFiscal.name}
              value={diaBloqueioNotaFiscal.value}
              error={diaBloqueioNotaFiscal.error}
              errorMessage={diaBloqueioNotaFiscal.errorMessage}
            />
          </Grid24>
          )
          }

        </Grid24>
        <Grid24 xs={24} sm={24} className={utilStyle.mt10ml8}>
          <CheckboxField
            label="Requer Nota Fiscal de Despesa"
            disabled={!isEditMode && !isInsertMode}
            name={requerNFDespesa.name}
            checked={requerNFDespesa.value}
            onChange={onChangeCheckbox}
          />
        </Grid24>
        <Grid24 xs={24} sm={24} className={utilStyle.mt10ml8}>
          <CheckboxField
            label="Utiliza Empresa alternativa para Emissão da NF"
            disabled={!isEditMode || isInsertMode}
            name={utilizaEmpresaAlternativaEmissaoNF.name}
            checked={utilizaEmpresaAlternativaEmissaoNF.value}
            onChange={onChangeCheckbox}
          />
        </Grid24>
        {utilizaEmpresaAlternativaEmissaoNF.value
          && (
            <Grid24>
              <Grid24 container isFormContainer spacing={1}>
                <Grid24 xs={24} sm={3}>
                  <SelectorField
                    fullWidth
                    isForm
                    disabled={!isEditMode || bloquearBuscarEmpresaClienteNF}
                    label={getRequiredLabel('Categoria', isEditMode)}
                    items={categorias}
                    name={categoria.name}
                    value={categoria.value}
                    error={categoria.error}
                    errorMessage={categoria.errorMessage}
                    onChange={onChangeHandler}
                    onFocus={focusFieldsHandler}
                  />
                </Grid24>
                <Grid24 xs={24} sm={4}>
                  <MaskField
                    isForm
                    fullWidth
                    disabled={!isEditMode || bloquearBuscarEmpresaClienteNF || categoria.value === ''}
                    label={getRequiredLabel(categoria.value === CATEGORIAS.PESSOA_FISICA
                      ? 'CPF' : 'CNPJ', !(!isEditMode || bloquearBuscarEmpresaClienteNF || categoria.value === ''))}
                    name={cnpjCpf.name}
                    value={cnpjCpf.value}
                    error={cnpjCpf.error}
                    errorMessage={cnpjCpf.errorMessage}
                    onChange={onChangeHandler}
                    onFocus={focusFieldsHandler}
                    format={maskCpfCnpj === 'CPF' ? CPF_MASK : CNPJ_MASK}
                  />
                </Grid24>
                <Grid24 xs={24} sm={3} className={utilStyle.ml16}>
                  <ButtonUI
                    className={' '}
                    disabled={bloquearBuscarEmpresaClienteNF || categoria.value === '' || cnpjCpf.value === ''}
                    onClick={onClickPesquisarClienteEmpresaNotaFiscal}
                  >
                    Consultar
                  </ButtonUI>
                </Grid24>
              </Grid24>
              <Grid24 container isFormContainer spacing={1}>
                <Grid24 xs={24} sm={19}>
                  <InputField
                    fullWidth
                    isForm
                    disabled={!isEditMode || bloquearCadastroEmpresaClienteNF}
                    label={getRequiredLabel('Nome/Razão Social', !(!isEditMode || bloquearCadastroEmpresaClienteNF))}
                    name={nome.name}
                    value={nome.value}
                    error={nome.error}
                    errorMessage={nome.errorMessage}
                    maxLength={60}
                    onFocus={focusFieldsHandler}
                    onChange={onChangeHandler}
                  />
                </Grid24>
              </Grid24>
              <Endereco
                model={endereco}
                estados={estados}
                disabled={!isEditMode || bloquearCadastroEmpresaClienteNF}
                setEndereco={setEnderecoHandler}
                setAttribute={setEnderecoAttributeValueHandler}
                setFocus={setEnderecoAttributeFocusFocusHandler}
              />
              <Grid24 container isFormContainer spacing={1}>
                <Grid24 item xs={24} sm={16}>
                  <InputField
                    isForm
                    fullWidth
                    disabled={!isEditMode || bloquearCadastroEmpresaClienteNF}
                    label={getRequiredLabel('E-mail', !(!isEditMode || bloquearCadastroEmpresaClienteNF))}
                    name={email.name}
                    value={email.value}
                    error={email.error}
                    errorMessage={email.errorMessage}
                    maxLength={50}
                    onChange={onChangeHandler}
                    onFocus={focusFieldsHandler}
                  />
                </Grid24>
              </Grid24>
            </Grid24>
          )}
      </Grid24>
      <hr className={utilStyle.divider} />
      <Grid24 xs={24} sm={24} className={utilStyle.ml8}>
        <CheckboxField
          label="Emite Boleto"
          disabled={!isEditMode && !isInsertMode}
          name={autorizarEmissaoBoleto.name}
          checked={autorizarEmissaoBoleto.value}
          onChange={onChangeCheckbox}
        />
      </Grid24>
      <Grid24 direction="column" xs={24} sm={8}>
        <Label>
          {getRequiredLabel('Prazo Padrão para Vencimento dos Boletos', !(!isEditMode || bloquearCadastroEmpresaClienteNF))}
        </Label>
        <NumberInput
          isForm
          disabled={!isEditMode && !isInsertMode}
          sideText="(dias)"
          numberType="integer"
          minValue={1}
          maxValue={999999}
          name={prazoVencimentoBoleto.name}
          value={prazoVencimentoBoleto.value}
          error={prazoVencimentoBoleto.error}
          errorMessage={prazoVencimentoBoleto.errorMessage}
          onChange={onChange}
          onFocus={onFocus}
          style={{ width: '95px' }}
        />
      </Grid24>
    </FormWrapperWithTitle>
  );
}
export default React.memo(NotasFiscaisBoletos);
