export const MANUAL_LABEL = 'Manual';
export const DIARIA_LABEL = 'Diária';
export const SEMANAL_LABEL = 'Semanal';
export const PERSONALIZADA_LABEL = 'Personalizada';
export const NENHUM_LABEL = 'Nenhuma';
export const ULTIMO_DIA_LABEL = 'Sempre prestar no último dia útil do mês';
export const NUNCA_ULTIMO_DIA_LABEL = 'Nunca prestar no último dia útil do mês';
export const NAO_PRESTAR_APOS = 'Não prestar após o dia';
export const FORMA_PADRAO_PRESTACAO = 'Forma padrão de Liquidação de Prestação';
export const BANCO_LABEL = 'Banco';
export const AGENCIA_LABEL = 'Agência';
export const NUMERO_CONTA_LABEL = 'Número da conta';
export const DAC = 'DAC';
export const TIPO_CHAVE_PIX_LABEL = 'Tipo de Chave Pix';
export const CHAVE_PIX = 'Chave Pix';
export const GERADOR_RELATORIO = 'Geradores de Relatórios disponíveis';
export const TOLERADOR_DIAS = 'Tolerância antes de bloquear uma Prestação de Contas';
export const DIAS = '(dias)';
export const DETALHES_LIQUIDACAO = 'Informe detalhes de como a Liquidação será feita';
