import FieldModel from '../../models/FieldModel';
import { getAttributeExistingObject } from '../utils/utils';

export function buildFaixaDescontoBoleto(parametroDescontoBoleto) {
  return {
    idParametroDescontoBoleto: new FieldModel({
      value: getAttributeExistingObject(parametroDescontoBoleto, 'idParametroDescontoBoleto'),
    }),
    qtdeParcelas: new FieldModel({
      name: 'qtdeParcelas',
      value: getAttributeExistingObject(parametroDescontoBoleto, 'qtdeParcelas'),
    }),
    porcentagemDescontoBoleto: new FieldModel({
      name: 'porcentagemDescontoBoleto',
      value: getAttributeExistingObject(parametroDescontoBoleto, 'porcentagemDescontoBoleto'),
      errorMessage: 'Campo obrigatório',
      maxLength: 5,
      required: true,
    }),
  };
}


export function buildParametroDescontoBoleto(parametroDescontoBoleto) {
  return (parametroDescontoBoleto) ? parametroDescontoBoleto.map(buildFaixaDescontoBoleto) : [];
}

export function buildFaixaClienteDescontoBoleto(clienteDescontoBoleto) {
  return {
    idClienteDescontoBoletoAS: new FieldModel({
      value: getAttributeExistingObject(clienteDescontoBoleto, 'idClienteDescontoBoletoAS'),
    }),
    qtdeParcelas: new FieldModel({
      name: 'qtdeParcelas',
      value: getAttributeExistingObject(clienteDescontoBoleto, 'qtdeParcelas'),
    }),
    porcentagemDescontoBoleto: new FieldModel({
      name: 'porcentagemDescontoBoleto',
      value: getAttributeExistingObject(clienteDescontoBoleto, 'porcentagemDescontoBoleto'),
      errorMessage: 'Campo obrigatório',
      maxLength: 5,
      required: true,
    }),
  };
}


export function buildClienteDescontoBoleto(clienteDescontoBoleto) {
  return (clienteDescontoBoleto) ? clienteDescontoBoleto.map(buildFaixaClienteDescontoBoleto) : [];
}
