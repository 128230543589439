import {
  DateUtils,
  makeErrorField,
  makeErrorTrueField,
  toAmericanDate,
} from '../../../../../../helpers';
import { checkDateParcelaNovoAcordoService } from '../../../../../../services/core/processos/processosAcordosService';

class DialogNovaAlterarParcelaController {
  makeFormFieldsNovaAlterarParcela(parcela) {
    return {
      formaPagamento: parcela ? parcela.formaPagamento : '',
      dataVencimento: parcela ? parcela.vencimento : '',
      valor: parcela ? parcela.valor : '',
      nParcela: parcela ? parcela.nParcela : '',
      redistribuirValores: true,
      chequeDetalhe: parcela ? parcela?.chequeDetalhe : [this.makeChequeDetalhe(parcela)],
    };
  }

  makeChequeDetalhe(parcela) {
    return {
      idBanco: {
        value: parcela?.chequeDetalhe ? parcela?.chequeDetalhe.idBanco : 0,
        error: false,
        errorMessage: '',
      },
      serChequeTerceiro: {
        value: parcela?.chequeDetalhe ? parcela?.chequeDetalhe.serChequeTerceiro : false,
        error: false,
        errorMessage: '',
      },
      numeroAgencia: {
        value: parcela?.chequeDetalhe ? parcela?.chequeDetalhe.numeroAgencia : '',
        error: false,
        errorMessage: '',
      },
      numeroConta: {
        value: parcela?.chequeDetalhe ? parcela?.chequeDetalhe.numeroConta : '',
        error: false,
        errorMessage: '',
      },
      dac: {
        value: parcela?.chequeDetalhe ? parcela?.chequeDetalhe.dac : '',
        error: false,
        errorMessage: '',
      },
      numeroCheque: {
        value: parcela?.chequeDetalhe ? parcela?.chequeDetalhe.numeroCheque : '',
        error: false,
        errorMessage: '',
      },
      valor: {
        value: parcela?.chequeDetalhe ? parcela?.chequeDetalhe.valor : '',
        error: false,
        errorMessage: '',
      },
    };
  }

  makeErrorFields() {
    return {
      formaPagamento: makeErrorField(),
      dataVencimento: makeErrorField(),
      valor: makeErrorField(),
      idBanco: makeErrorField(),
      serChequeTerceiro: makeErrorField(),
      numeroAgencia: makeErrorField(),
      numeroConta: makeErrorField(),
      dac: makeErrorField(),
      numeroCheque: makeErrorField(),
      valorDetalhe: makeErrorField(),
    };
  }

  setErrorsIfNeededOnSubmit(formFields, getMaxDate, getMinDate) {
    const obj = {};

    // Checando campos vazios ou nulos.
    Object.keys(formFields).forEach((key) => {
      if (
        (formFields[key] === null
        || formFields[key] === ''
        || formFields[key] === undefined) && key !== 'nParcela'
      ) {
        obj[key] = makeErrorTrueField('Campo Obrigatório');
        return;
      }
      obj[key] = makeErrorField();
    });

    // Checando valor monetário
    if (obj.valor.error !== true) {
      if (formFields.valor <= 0) {
        obj.valor = makeErrorTrueField('O valor deve ser maior que R$ 0,00');
      }
    }
    // Checando data
    if (obj.dataVencimento.error !== true) {
      const dataVencimento = DateUtils.getDateWithoutTime(
        DateUtils.getISODateFromText(formFields.dataVencimento),
      );
      if (getMinDate() > dataVencimento || getMaxDate() < dataVencimento) {
        obj.dataVencimento = makeErrorTrueField(
          'Data fora do período permitido.',
        );
      }
    }

    return obj;
  }

  setErrorsDetalhesDoChequeIfNeededOnSubmit(chequeDetalhe) {
    chequeDetalhe.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        if (
          (obj[key].value === null
          || obj[key].value === ''
          || obj[key].value === undefined) && (key !== 'dac' && key !== 'serChequeTerceiro')
        ) {
          obj[key].error = true;
          obj[key].errorMessage = 'Campo Obrigatório';
        }
      });
    });
  }

  checkForErrorsOnSubmit(errorFields) {
    let flagErro = false;
    Object.keys(errorFields).forEach((key) => {
      if (errorFields[key].error) {
        flagErro = true;
      }
    });
    return flagErro;
  }

  checkForErrorsDetalhesOnSubmit(chequeDetalhe) {
    let flagErro = false;
    chequeDetalhe.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        if (obj[key].error) {
          flagErro = true;
        }
      });
    });
    return flagErro;
  }

  async checkDate(date) {
    const convertedDate = toAmericanDate(date);
    const response = await checkDateParcelaNovoAcordoService(convertedDate);
    return response.data;
  }
}

export const dialogNovaAlterarParcelaController = new DialogNovaAlterarParcelaController();
