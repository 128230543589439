import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  useCrudClienteDispatch,
  useCrudClienteSelector,
} from '../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import Endereco from '../../../../UI/EnderecosV2/Endereco/Endereco';
import WrapperSubTitleForm from '../../../../UI/WrapperSubTitleForm';
import {
  setEnderecoAction,
  setEnderecoAttributeFocusAction,
  setEnderecoAttributeValueAction,
} from '../../../../../store/Cliente/V2/Crud/crudCliente.store';
import { findEstadosAction } from '../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import useUtilStyles from '../../../../../helpers/styles/utils.styles';

const constants = {
  TITLE: 'Endereço',
};

function EnderecoCrudCliente({ isEditMode }) {
  const utilStyles = useUtilStyles();

  const dispatch = useCrudClienteDispatch();
  const globalDispatch = useDispatch();

  const endereco = useCrudClienteSelector(states => ({ ...states.controls.endereco }));
  const estados = useSelector(states => states.selectors.estados.selector);
  const onReloadAllCombos = useCrudClienteSelector(states => states.actions.reloadAllCombos);

  useEffect(() => {
    globalDispatch(findEstadosAction());
  }, [globalDispatch, onReloadAllCombos]);

  const setEnderecoHandler = useCallback((enderecoParam) => {
    dispatch(setEnderecoAction(enderecoParam));
  }, [dispatch]);

  const setEnderecoAttributeValueHandler = useCallback((name, value) => {
    dispatch(setEnderecoAttributeValueAction(name, value));
  }, [dispatch]);

  const setEnderecoAttributeFocusFocusHandler = useCallback((enderecoParam) => {
    dispatch(setEnderecoAttributeFocusAction(enderecoParam));
  }, [dispatch]);

  return (
    <WrapperSubTitleForm title={constants.TITLE} className={utilStyles.mt16}>
      <Endereco
        disabled={!isEditMode}
        estados={estados}
        model={endereco}
        setEndereco={setEnderecoHandler}
        setAttribute={setEnderecoAttributeValueHandler}
        setFocus={setEnderecoAttributeFocusFocusHandler}
      />
    </WrapperSubTitleForm>
  );
}

export default withRouter(EnderecoCrudCliente);
