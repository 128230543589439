import React, { createContext } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider, createSelectorHook, createDispatchHook } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import reducer from './EmailEntradaBordero.store';
import watchEmailEntradaBordero from './EmailEntradaBordero.saga';

export const emailEntradaBorderoContext = createContext();
export const useEmailEntradaBorderoSelector = createSelectorHook(emailEntradaBorderoContext);
export const useEmailEntradaBorderoDispatch = createDispatchHook(emailEntradaBorderoContext);

export default function EmailEntradaBorderoProvider({ children }) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(watchEmailEntradaBordero);

  return (
    <Provider context={emailEntradaBorderoContext} store={store}>
      {children}
    </Provider>
  );
}
