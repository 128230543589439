import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { RESULT_NOT_FOUND } from '../../../helpers/constants/global.constants';
import { getExceptionHandler } from '../../../helpers/utils/exception.util';
import { toAmericanDate } from '../../../helpers/utils/utils';
import { buscarAcordosAs } from '../../../services/core/acordoseguro/acordo.services';
import { PESQUISAR_ACORDOS_AS, PESQUISAR_ACORDO_ENTRAR_TELA_AS } from './acordosAs.constants';
import {
  onUpdateRequestFieldsAction, setStoreValuesAction, setTableInfoAction,
} from './acordosAs.store';


export const pesquisarAcordosAsAction = (page, rowsPerPage, ordenacao) => ({
  type: PESQUISAR_ACORDOS_AS,
  page,
  rowsPerPage,
  ordenacao,
});

export const pesquisarAcordoASEntrarTelaAction = (page, rowsPerPage, ordenacao) => ({
  type: PESQUISAR_ACORDO_ENTRAR_TELA_AS,
  page,
  rowsPerPage,
  ordenacao,
});

function* getAcordosASHandler(filters, ordenacao, page, rowsPerPage) {
  const {
    periodoAcordoInicial, periodoAcordoFinal, nroProcesso, nomeDevedor, nomeCliente, idTipoAcordo,
  } = filters;
  let response;
  response = yield call(buscarAcordosAs, periodoAcordoInicial, periodoAcordoFinal, nroProcesso, nomeDevedor, nomeCliente, idTipoAcordo, ordenacao, page, rowsPerPage);

  if (response.data.content.length === 0 && response.data.totalElements !== 0) {
    const lastPage = yield response.data.totalPages - 1;
    response = yield call(buscarAcordosAs, periodoAcordoInicial, periodoAcordoFinal, nroProcesso, nomeDevedor, nomeCliente, idTipoAcordo, ordenacao, lastPage, rowsPerPage);
  }

  return response.data;
}

function* buildTable(data) {
  yield put(setTableInfoAction(data.content, data.number, data.size, data.totalElements));
}

function* catchSubmitException(exception, controls) {
  const [updatedControls] = yield getExceptionHandler(exception, controls);
  if (updatedControls) {
    yield put(onUpdateRequestFieldsAction(updatedControls));
  }
}

function configureSnackbarAlert(content) {
  if (content.length === 0) {
    toast.warn(RESULT_NOT_FOUND);
  }
}

function* pesquisarAcordosHandler(actions) {
  yield put(setStoreValuesAction('loading', true));
  const fields = yield select(states => states.acordosAs.requestFields);
  try {
    const cacheRequestFields = yield select(states => states.acordosAs.cacheRequestFields);
    const { page, rowsPerPage, ordenacao } = actions;

    const data = yield getAcordosASHandler(cacheRequestFields, ordenacao, page, rowsPerPage);
    yield buildTable(data);
    yield configureSnackbarAlert(data.content);
  } catch (exception) {
    yield catchSubmitException(exception, fields);
  } finally {
    yield put(setStoreValuesAction('loading', false));
  }
}

function* pesquisarAcordosEntrarTelaHandler(actions) {
  yield put(setStoreValuesAction('loading', true));
  const fields = yield select(states => states.acordosAs.requestFields);
  try {
    const periodoAcordoInicial = toAmericanDate(fields.periodoAcordoInicial.getValueNotEmpty());
    const periodoAcordoFinal = toAmericanDate(fields.periodoAcordoFinal.getValueNotEmpty());
    const { page, rowsPerPage, ordenacao } = actions;

    const data = yield getAcordosASHandler({
      periodoAcordoInicial,
      periodoAcordoFinal,
    }, ordenacao, page, rowsPerPage);
    yield buildTable(data);
  } catch (exception) {
    yield catchSubmitException(exception, fields);
  } finally {
    yield put(setStoreValuesAction('loading', false));
  }
}

export default function* watchAcordosAs() {
  yield takeLatest(PESQUISAR_ACORDOS_AS, pesquisarAcordosHandler);
  yield takeLatest(PESQUISAR_ACORDO_ENTRAR_TELA_AS, pesquisarAcordosEntrarTelaHandler);
}
