import store from '../../store/createStore';

export function hasUserPermissions(...permissions) {
  const userPermissions = store.getState()?.authentication?.userInformation?.permissoes?.split(',') || [];
  const userType = store.getState()?.authentication?.userInformation?.tipoUsuario || '';
  if (userType !== 'U') {
    return false;
  }

  return userPermissions.reduce((acc, curr) => {
    const currentPermission = Number(curr.replace('ROLE_', ''));
    if (acc || permissions.includes(currentPermission)) {
      return true;
    }
    return false;
  }, false);
}
