import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  root: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#707070',
    height: '54px',
    display: 'flex',
  },
  icon: {
    width: '42px',
    height: '42px',
    borderRadius: '50px',
    backgroundColor: '#ff1381',
    color: 'white',
    margin: '6px 11px 6px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  routes: {
    alignSelf: 'center',
    '& p': {
      color: 'white',
      fontWeight: 'bold',
    },
    '& .MuiBreadcrumbs-separator': {
      color: 'white',
    },
  },
  close: {
    width: '28px',
    height: '28px',
    color: 'white',
    margin: '6px 20px 6px auto',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    '& button': {
      padding: '2px',
      backgroundColor: '#ff1381',
      color: 'white',
      '&:hover': {
        backgroundColor: '#ff1381BB',
      },
    },
  },
});
