import React, { useCallback } from 'react';
import { useUtilStyles } from '../../../../../../../helpers';
import {
  addContaPagamentosGeraisAction, setContaPagamentosGeraisAttributeFocusAction, setContaPagamentosGeraisAttributeValueAction, setObjectsAction,
} from '../../../../../../../store/Parametros/Crud/crudParametros.store';
import { useCrudParametrosDispatch, useCrudParametrosSelector } from '../../../../../../../store/Parametros/Crud/CrudParametrosProvider';
import AddActionTitleWrapper from '../../../../../../UI/Wrapper/AddActionTitle';
import CamposContaGerais from './CamposContaGerais';


const CONSTANTS = {
  EDIT_TITLE: 'Adicionar Conta Pagamentos Gerais',
  READ_TITLE: 'Conta de Pagamentos Gerais',
  TOOLTIP: 'Adicionar Conta Pagamentos Gerais',

};
function ContaPagamentosGerais({
  isInsertMode,
  readMode = false,
  bancos,
  tiposChavesPix,
}) {
  const dispatch = useCrudParametrosDispatch();
  const utilStyles = useUtilStyles();
  const contaPagamentosGerais = 'contasBancariasPagamentosGerais';

  const contasBancariasPagamentosGerais = useCrudParametrosSelector(states => ([...states.requestFields.contasBancariasPagamentosGerais]));

  const generateDividerLine = index => ((index !== contasBancariasPagamentosGerais.length - 1)
    && (<hr className={utilStyles.mt7} />)
  );

  const setFaixasDescontoBoletoAttributeValueHandler = useCallback((name, value, index) => {
    dispatch(setContaPagamentosGeraisAttributeValueAction(name, value, index));
  }, [dispatch]);

  const setFaixasDescontoBoletoAttributeFocusHandler = useCallback((name, index) => {
    dispatch(setContaPagamentosGeraisAttributeFocusAction(name, index));
  }, [dispatch]);

  const setCamposHandler = useCallback((campos) => {
    dispatch(setObjectsAction(contaPagamentosGerais, campos));
  }, [dispatch]);


  const addCampos = useCallback(() => {
    dispatch(addContaPagamentosGeraisAction());
  }, [dispatch]);

  const removeCamposHandler = useCallback((index) => {
    const allCampos = [...contasBancariasPagamentosGerais];
    allCampos.splice(index, 1);
    setCamposHandler(allCampos);
  }, [contasBancariasPagamentosGerais, setCamposHandler]);

  const buildAdicionarConta = ((pagamentosGerais, index) => (
    <div key={index}>
      <CamposContaGerais
        index={index}
        isInsertMode={isInsertMode}
        bancos={bancos}
        tiposChavesPix={tiposChavesPix}
        model={pagamentosGerais}
        onRemove={() => removeCamposHandler(index)}
        setAttribute={setFaixasDescontoBoletoAttributeValueHandler}
        setFocus={setFaixasDescontoBoletoAttributeFocusHandler}
      />
      {generateDividerLine(index)}
    </div>
  ));
  return (
    <AddActionTitleWrapper
      title={CONSTANTS.EDIT_TITLE}
      readTitle={CONSTANTS.READ_TITLE}
      addHandler={addCampos}
      tooltip={CONSTANTS.TOOLTIP}
      readMode={readMode}
      className={utilStyles.mt7}
    >
      <div className={utilStyles.mt7}>
        {contasBancariasPagamentosGerais?.map(buildAdicionarConta)}
      </div>
    </AddActionTitleWrapper>
  );
}

export default ContaPagamentosGerais;
