import { Button, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const topAlignment = '41px';

const colorsSinalizacaoBoxCores = {
  rosa: {
    color: '#ff8ac1',
  },
  azul: {
    color: '#93ecf1',
  },
  amarelo: {
    color: '#fafd79',
  },
  verde: {
    color: '#bcf5a2',
  },
};

function colorsSinalizacaoBoxSinalizacao(indicador, statusProcesso) {
  if (indicador === 'codigoConsumidor') {
    return '#c156d2';
  }
  if (indicador === 'clienteInativo') {
    return '#3565c3';
  }
  if (indicador === 'recomendacaoEspecial') {
    return '#ffbd5a';
  }
  if (indicador === 'permiteCobrancaPresencial') {
    return '#79df49';
  }
  if (indicador === 'processoExpirado') {
    if (statusProcesso === 'PRESTES_A_EXPIRAR') {
      return '#ff7a00';
    }
    if (statusProcesso === 'EXPIRADO') {
      return '#e7626a';
    }
    return '#e4e4e4';
  }
  return null;
}

const paddingSinalizacaoBoxSinalizacao = {
  codigoConsumidor: {
    padding: '0 0 1px 0',
  },
  clienteInativo: {
    padding: ' 0 0 0 2px',
  },
  recomendacaoEspecial: {
    padding: '',
  },
  permiteCobrancaPresencial: {
    padding: '',
  },
  processoExpirado: {
    padding: '',
  },
};

export const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row-reverse;

  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 8px;

  margin-right: 24px;
`;

export const MainContainer = styled.main`
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const AdditionalInfoContainer = styled.div`
  position: absolute;
  height: 46px;
  width: 100%;
  top: ${topAlignment};

  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const Container = styled.div`
  margin-top: ${topAlignment};
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 22px;
  background-color: white;
`;

export const ActionHeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const ActionContainer = styled.section`
  margin-bottom: 18px;
  padding-top: 10px;
`;

export const ActionButtonGroup = styled.div`
  display: flex;

  > div {
    margin-right: 8px;
  }
`;

export const ActionButton = styled(IconButton)`
  width: 36px;
  height: 36px;
  padding: 8px;

  background-color: ${({ active }) => (active === 'true' ? 'transparent' : '#423362')};
  color: ${({ active }) => (active === 'true' ? '#423362' : 'white')};
  ${({ active }) => (active === 'true' ? 'border: 2px solid #423362;' : '')}

  :hover {
    background-color: ${({ active }) => (active === 'true' ? 'transparent' : '#423362')};
    opacity: 0.64;
  }

  :disabled {
    background-color: #c4c4c4;
  }
`;

export const Accordions = styled.section`
  > div + div {
    margin-top: 11px;
  }
`;

export const ActionContentContainer = styled.section`
  margin-top: 20px;
  padding: 8px;
  border :1px solid #A2A0A0;
`;

export const TextoPadraoButton = styled(Button)`
  font-size: 14px;
  line-height: 16px;
  padding: 6px 8px;
  font-weight: bold;
  color: #564872;
  border: 2px solid #564872;
  text-transform: none;

  >span span {
    display: inline-block;
    margin-left: 6px;
  }
`;

export const HotizontalButtonGroup = styled.div`
  button + button {
    margin-left: 19px;
  }
`;


export const CustomButton = styled(Button)`
  width: 120px;
  height: 35px;

  background-color: white;
  color: #034DDC;
  border-radius: 16px;
  border:1px solid #034DDC;
  font-weight: bold;
  text-transform: none;

  svg {
    display: block;
  }

  span span {
    padding-left: 8px;

    display: block;
    font-size: 14px;
  }

  :disabled {
    border:1px solid #C4C4C4;
  }
`;

export const BoxCores = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 6px;
  margin-left: 12px;
  width: 142px;
  height: 34px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
`;

export const BoxCor = styled.div`
  width: 14px;
  height: 20px;
  border: 1px solid ${props => (props.indicador && colorsSinalizacaoBoxCores[props.indicador].color) || '#cecece'};
  background-color: ${props => (props.indicador && colorsSinalizacaoBoxCores[props.indicador].color) || 'white'};
`;

export const ButtonDropDown = styled.div`
  width: 20px;
  height: 34px;
  display: flex;
  justify-content: center;
  cursor: pointer;

  > div {
    width: 0;
    height: 0;
    display: flex;
    align-self: center;

    border-left: ${props => (props.open ? '5px solid transparent' : '5px solid transparent')};
    border-right: ${props => (props.open ? '5px solid transparent' : '5px solid transparent')};
    border-bottom: ${props => (props.open ? '5px solid #707070' : 'none')};
    border-top: ${props => (props.open ? 'none' : '5px solid #707070')};
  }
`;

export const DropdownContainer = styled.ul`
  list-style: none;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  padding: 8px;

  & li + li {
    margin-top: 8px;
  }

  & .MuiFormControlLabel-root {
    margin-right: 0;
  }

  & .MuiButtonBase-root {
    padding: 0;
    padding-left: 8px;
  }

  & .MuiTypography-body1 {
     font-size: 12px;
   }

   & .MuiCheckbox-colorPrimary.Mui-disabled {
     color: #c4c4c4 !important;
   }
`;

export const BoxAlertas = styled.div`
  display: flex;
  width: 33%;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
`;

export const AlertaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  cursor: default;
  box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 20%);
`;

export const Alertas = styled.div`
  display: flex;
  > div {
    margin-right: 12px;
  }
`;

export const BoxSinalizacao = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 6px;
  width: 142px;
  height: 34px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
`;

export const SinalizacaoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: default;

  border: 1px solid ${props => (props.indicador && colorsSinalizacaoBoxSinalizacao(props.indicador, props.statusProcesso)) || '#cecece'};
  background-color: ${props => (props.indicador && colorsSinalizacaoBoxSinalizacao(props.indicador, props.statusProcesso)) || '#e4e4e4'};

  i {
    color: white;
    font-size: 10px;
    padding: ${props => (props.indicador && paddingSinalizacaoBoxSinalizacao[props.indicador].padding) || '0'};;
  }

  img {
    width: 18px;
    height: 14px;
  }
`;

export const FooterInspecionar = styled.div`
  height: 73px;
  width: 100%;
  background-color: white;
  box-shadow:0 4px 4px 0 rgba(0, 0, 0, 0.25);

  ${props => props.disabled && css`

  `}

  & .MuiButton-fullWidth {
    margin-top: 19px;
    margin-left: 18px;
    width: 173px;
}
`;

export const LinkButton = styled(Link)`
  display: flex;
  align-items: center;

  border: 1px solid #564872;
  border-radius: 4px;
  padding: 5px 8px;
  text-decoration: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  font-weight: bold;
  color: #564872;

  ${props => props.disabled && css`
  border: 1px solid #c4c4c4;
  color: #c4c4c4;
`}


  & span {
    margin-left: 8px;
  }

  & :active {
    color: ${props => (props.disabled ? '#c4ce4c4' : '#564872')};
  }
`;
