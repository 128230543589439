import moment from 'moment';
import React from 'react';
import FieldModel, { DATE } from '../../models/FieldModel';

export function getYesterdayDate() {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date;
}

export function getUriParams(params) {
  const keys = Object.keys(params);
  return keys
    .filter(key => params[key] !== null && params[key] !== undefined && params[key] !== '')
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');
}

export function getNotEmptyValues(value) {
  if (value === '') {
    return undefined;
  }
  return value;
}

export function copyObject(object) {
  return Object.assign(Object.create(Object.getPrototypeOf(object)), object);
}

export function hasError(...args) {
  return args.every(arg => arg);
}

export function hasMessageInException(exception) {
  return exception.response && exception.response.data && exception.response.data.message;
}

export function getExceptionMessage(exception) {
  if (exception
    && exception.response
    && exception.response.data
    && exception.response.data.message
  ) {
    return exception.response.data.message;
  }
  return '';
}

export function haveExceptionValidations(exception) {
  return exception && exception.response && exception.response.data && exception.response.data.validations;
}

export function isBadRequestException(exception) {
  return exception && exception.response && exception.response.data && exception.response.data.status === 400;
}

export function getNewPage(oldRowsSize, oldPage, newRowsSize) {
  return Math.floor((oldRowsSize * oldPage) / newRowsSize);
}

export function isNotFoundException(exception) {
  return exception && exception.response && exception.response.data && exception.response.data.status === 404;
}

export function isConflictException(exception) {
  return exception && exception.response && exception.response.data && exception.response.data.status === 409;
}

function isIncluded(rules, idRules) {
  if (rules !== null && rules !== undefined) {
    return rules.includes(idRules);
  }
  return false;
}

export function checkPermission(rules, idRules, userType, expectedUserType) {
  if (Array.isArray(idRules)) {
    return !(idRules.some(rule => rules.indexOf(rule) !== -1) && userType === expectedUserType);
  }
  const idRulesConfirm = `ROLE_${idRules}`;
  return !((isIncluded(rules, idRulesConfirm)) && userType === expectedUserType);
}

export function checkClientePermission(perm, idPerm, tipoUsuario) {
  return checkPermission(perm, idPerm) && tipoUsuario === 'C';
}

export function assignStatus(status) {
  return status === 'A' ? 'Ativo' : 'Inativo';
}

export function convertDate(dateTextPlain) {
  if (!dateTextPlain || dateTextPlain === '') {
    return dateTextPlain;
  }
  const splitedDate = dateTextPlain.split('-');
  return `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
}

export function formatCpfCnpj(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value.length > 11) {
    return value.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
      '$1.$2.$3/$4-$5',
    );
  }
  return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4');
}

export function getMoneyMask(value, withR$ = true) {
  if (value === 0) {
    if (withR$) {
      return 'R$ 0,00';
    }
    return '0,00';
  }
  if (withR$) {
    if (Math.sign(value) === -1) { // Número negativo...
      const valueWithoutSign = Math.abs(value);
      const absoluteValue = valueWithoutSign.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })?.replace('R$', '').trim();
      return `R$ -${absoluteValue}`;
    }
    return value ? value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '';
  }
  if (Math.sign(value) === -1) { // Número negativo...
    const valueWithoutSign = Math.abs(value);
    const absoluteValue = valueWithoutSign.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })?.replace('R$', '').trim();
    return `-${absoluteValue}`;
  }
  return value ? value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '') : '';
}

export function removeMoneyMask(value) {
  if (value === '0,00') {
    return '0';
  }
  return value?.slice(3).replace(/\./g, '').replace(/,/g, '.') || '0';
}

export function toBrazilianDate(date) {
  if (!date) {
    return '';
  }
  const onlyDate = new Date(date).toISOString().split('T')[0];
  return convertDate(onlyDate);
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}

export function getScrollPosition() {
  const x = window.pageXOffset !== undefined ? window.pageXOffset : window.scrollLeft;
  const y = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop;

  return { x, y };
}

export function moveScrollToLastLocation(x = 0, y = 0) {
  window.scrollTo(x, y);
}

export function moveScroll(x = 0, y = 0) {
  window.scrollTo(x, y);
}

export function replaceTo(history, path) {
  history.replace(path);
}

export function getDateTimeMask(date, withSeconds = false, fullDate = true, separator = false) {
  if (date) {
    let format = 'DD/MM/YYYY';
    if (fullDate) {
      if (withSeconds) {
        format = `DD/MM/YYYY ${separator ? '-' : ''} HH:mm:ss`;
      } else {
        format = `DD/MM/YYYY ${separator ? '-' : ''} HH:mm`;
      }
      return moment(date).format(format);
    }
    return moment(date).format(format);
  }
  return '';
}

export function getDateTimeMaskWithSeparator(date, withSeconds = false, fullDate = true, separator = false) {
  if (date) {
    let format = 'DD/MM/YYYY';
    if (fullDate) {
      if (withSeconds) {
        format = `DD/MM/YYYY - ${separator ? '-' : ''} HH:mm:ss`;
      } else {
        format = `DD/MM/YYYY -  ${separator ? '-' : ''} HH:mm`;
      }
      return moment(date).format(format);
    }
    return moment(date).format(format);
  }
  return '';
}

export function getAttributeExistingObject(object, attribute, valueDefault = '') {
  if (object && (object[attribute] !== undefined && object[attribute] !== null)) {
    return object[attribute];
  }

  return valueDefault;
}

export function getExistingAttribute(attribute, valueDefault = '') {
  return attribute || valueDefault;
}

export function getRequiredLabel(label, showRequired) {
  return showRequired ? `${label}*` : label;
}

export function buildPayload(fields) {
  let payload = {};
  Object.keys(fields).forEach((key) => {
    if (key === 'key') {
      return;
    }
    if (fields[key] instanceof Array) {
      payload = {
        ...payload,
        [key]: buildPayloadArray(fields[key]),
      };
    }
    if (fields[key] instanceof FieldModel) {
      if (fields[key].type === DATE) {
        payload = {
          ...payload,
          [key]: fields[key].getSerializeDate(),
        };
      } else {
        if (key === 'nome do usuario editado') {
          payload = {
            ...payload,
            login: fields[key].getValueNotEmpty(),
          };
        }
        if (key === 'senha do usuario editada') {
          payload = {
            ...payload,
            senha: fields[key].getValueNotEmpty(),
          };
        }
        payload = {
          ...payload,
          [key]: fields[key].getValueNotEmpty(),
        };
      }
    }
  });
  return payload;
}

export function buildPayloadArray(fieldArray) {
  return fieldArray.map(buildPayload);
}

export function isLoading(loadings) {
  const itemsNotLoaded = loadings.filter(item => item === true);
  return itemsNotLoaded.length > 0;
}

export function getDayOfWeek(day) {
  switch (day) {
    case 0:
      return 'Domingo';
    case 1:
      return 'Segunda-Feira';
    case 2:
      return 'Terça-feira';
    case 3:
      return 'Quarta-feira';
    case 4:
      return 'Quinta-feira';
    case 5:
      return 'Sexta-feira';
    case 6:
      return 'Sábado';
    default:
      return '';
  }
}

export function toAmericanDate(date) {
  if (!date) {
    return '';
  }
  return date.split('/').reverse().join('-');
}

export function getExceptionErrorMessage(exception) {
  if (exception
    && exception.response
    && exception.response.data
    && exception.response.data.error
  ) {
    return exception.response.data.error;
  }
  return '';
}

export function capitalize(str) {
  const ignore = ['do', 'de'];

  if (str) {
    const splited = str.split(' ');
    const capitalizedArray = splited.map((s) => {
      if (ignore.includes(s)) {
        return s;
      }
      return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    });
    return capitalizedArray.join(' ');
  }
  return '';
}

export function getExistingFieldModel(response, attrName, defaultValue = '', model = {}) {
  const value = getAttributeExistingObject(response, attrName, defaultValue);
  if (value !== null && value !== undefined) {
    return new FieldModel({ name: attrName, value, ...model });
  }
  return value;
}

export function groupBy(list, attr, applyWrapper = value => value) {
  if (!list) return new Map();

  const mapped = new Map();
  list.forEach((element) => {
    const groupRef = element[attr];
    if (mapped.has(groupRef)) {
      const elements = mapped.get(groupRef);
      elements.push(applyWrapper(element));
    } else {
      mapped.set(groupRef, [applyWrapper(element)]);
    }
  });
  return mapped;
}

export function buildMapFromArray(list, attr, applyWrapper = value => value) {
  if (!list) return new Map();
  const mapped = new Map();
  list.forEach((element) => {
    mapped.set(element[attr], applyWrapper(element));
  });
  return mapped;
}

export function getNextValueToRange(ranges, attrName, maxValue, variation) {
  if (ranges.length !== 0) {
    const previousRange = ranges[ranges.length - 1];
    let value = +previousRange[attrName].value;
    if (value < maxValue) {
      value += variation;
    }
    return value;
  }
  return 0;
}

export function getListIntervalCorrectAfterDeleteQuantidade(ranges, attrFinal, attrBegin, index, maxValue = 999999) {
  if (ranges.length !== 0) {
    if (index === ranges.length) {
      ranges[index - 1][attrFinal].value = maxValue;
      return ranges;
    }
    const previousRange = ranges[index - 1];
    const value = +previousRange[attrFinal].value;
    ranges[index][attrBegin].value = value + 1;
  }
  return ranges;
}

export function getListIntervalCorrectAfterDeleteValue(ranges, attrFinal, attrBegin, index, maxValue = 9999999999) {
  if (ranges.length !== 0) {
    if (index === ranges.length) {
      ranges[index - 1][attrFinal].value = maxValue;
      return ranges;
    }
    const previousRange = ranges[index - 1];
    const value = +previousRange[attrFinal].value;

    ranges[index][attrBegin].value = value + 1;
  }
  return ranges;
}

export function getListIntervalCorrectAfterDeleteValor(ranges, attrFinal, attrBegin, index, maxValue = 999999) {
  if (ranges.length !== 0) {
    if (index === ranges.length) {
      ranges[index - 1][attrFinal].value = maxValue;
      return ranges;
    }
    const previousRange = ranges[index - 1];
    const value = +previousRange[attrFinal].value;
    ranges[index][attrBegin].value = value + 0.01;
  }
  return ranges;
}

function getRangeGroudErrorMessage(prev, next, variation, messages) {
  const diff = (next - prev);
  if (+diff.toFixed(2) !== variation) {
    return messages.invalidRange;
  }
  return undefined;
}

function getRangeErrorMessage(prev, next, minValue, maxValue, messages) {
  if (prev < minValue) {
    return messages.forMinValue;
  }
  if (next > maxValue) {
    return messages.forMaxValue;
  }

  if (prev >= next) {
    return messages.invalidRange;
  }
  return undefined;
}

function validateOnlyFirstRange(list, attrInitialRange, attrFinalRange, minValue, maxValue, messages) {
  const prevInitialValue = list[0][attrInitialRange].value;
  const prevFinalValue = list[0][attrFinalRange].value;
  return getRangeErrorMessage(prevInitialValue, prevFinalValue, minValue, maxValue, messages);
}

function validateAllRanges(list, attrInitialRange, attrFinalRange, variation, messages, minValue, maxValue) {
  const trackErrorMessage = [];
  for (let i = 0; i < list.length - 1; i += 1) {
    const prevInitialValue = +list[i][attrInitialRange].value;
    const prevFinalValue = +list[i][attrFinalRange].value;
    const nextInitialValue = +list[i + 1][attrInitialRange].value;
    const nextFinalValue = +list[i + 1][attrFinalRange].value;

    trackErrorMessage.push(getRangeGroudErrorMessage(prevFinalValue, nextInitialValue, variation, messages));
    trackErrorMessage.push(getRangeErrorMessage(nextInitialValue, nextFinalValue, minValue, maxValue, messages));
    trackErrorMessage.push(getRangeErrorMessage(prevInitialValue, prevFinalValue, minValue, maxValue, messages));
  }
  return trackErrorMessage;
}

function validateRequiredField(item, wrapperErrors = []) {
  Object.keys(item).forEach((key) => {
    if (item[key]) {
      item[key].isValidRequired();
      wrapperErrors.push(item[key].error);
    }
  });
}

function verifyLastValue(list, listSize, attrFinalRange, maxValue) {
  return +list[listSize - 1][attrFinalRange].value !== maxValue;
}

export function validateRanges(list, attrInitialRange, attrFinalRange, messages, variation, maxValue, minValue = 0) {
  if (list.length === 0) {
    return { isValid: true, errorMessages: undefined };
  }

  const wrapperErrors = [];
  let trackErrorMessage = [];
  list.forEach(item => validateRequiredField(item, wrapperErrors));

  if (wrapperErrors.includes(true)) {
    trackErrorMessage.push('Revise as informações e preencha o cadastro corretamente');
  }
  const listSize = list.length;
  if (listSize > 0 && verifyLastValue(list, listSize, attrFinalRange, maxValue)) {
    trackErrorMessage.push(messages.forMaxValue);
  } else if (listSize === 1) {
    trackErrorMessage.push(validateOnlyFirstRange(list, attrInitialRange, attrFinalRange, minValue, maxValue, messages));
  } else {
    trackErrorMessage = trackErrorMessage.concat(validateAllRanges(list, attrInitialRange, attrFinalRange, variation, messages, minValue, maxValue));
  }

  const errorMessages = trackErrorMessage.filter(element => element !== undefined);
  const isValid = !wrapperErrors.includes(true) && errorMessages.length === 0;

  return { isValid, errorMessages };
}

export function checkArrayIsRequired(list) {
  const wrapperErrors = [];
  list.forEach(item => validateRequiredField(item, wrapperErrors));
  return !wrapperErrors.includes(true);
}

export function getCurrentIdUsuario() {
  if (localStorage.userData) {
    const { idUsuario } = JSON.parse(localStorage.userData);
    return idUsuario;
  }
  return undefined;
}

export function generateValuesWithSeparator(items, selectedItems) {
  return (items.filter(item => selectedItems.includes(item.id))
    .map(item => item.value).join('; '));
}

export function replaceSemiColonOnValue(value) {
  return value.replace(/;/g, '');
}

export function getInitialTabView(allTabItems) {
  return (allTabItems.find(item => item.disabled === false).index);
}

export function upperCaseFaixas(value) {
  if (value.startsWith('Valor')) {
    return 'Valor Principal do Processo';
  }
  return 'Quantidade de Dias Vencidos';
}

export function arrayValuesToStringSeparator(array, separator) {
  return array.join(separator);
}

export function getValue(value, checked, type) {
  return type === 'checkbox' ? checked : value;
}

export function get7DaysAgo() {
  const data7DaysAgo = new Date();
  data7DaysAgo.setDate(data7DaysAgo.getDate() - 7);
  return data7DaysAgo;
}

function getMonthNumberFromString(monthString) {
  switch (monthString) {
    case 'Jan':
      return '01';
    case 'Feb':
      return '02';
    case 'Mar':
      return '03';
    case 'Apr':
      return '04';
    case 'May':
      return '05';
    case 'Jun':
      return '06';
    case 'Jul':
      return '07';
    case 'Aug':
      return '08';
    case 'Sep':
      return '09';
    case 'Oct':
      return '10';
    case 'Nov':
      return '11';
    case 'Dec':
      return '12';
    default:
      return '01';
  }
}

// Função para transformar datas no formato do input para dateTime.
// Input -> Tue Mar 30 2021 17:01:39 GMT-0300 (Hora padrão de Brasília)
// Output -> 2021-03-30T17:01:39
export function convertDateZoneToLocalDateTime(dateZone) {
  const dateParts = dateZone.toString().split(' ');
  const month = getMonthNumberFromString(dateParts[1]);
  return `${dateParts[3]}-${month}-${dateParts[2]}T${dateParts[4]}`;
}

function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function openPagePDF(data) {
  const relatorio = new Blob(
    [data],
    { type: 'application/pdf' },
  );
  const relatorioURL = URL.createObjectURL(relatorio);
  if (isSafari()) {
    window.location = relatorioURL;
  } else {
    window.open(relatorioURL, '_blank');
  }
}

export function openPageFile(data, type) {
  const relatorio = new Blob([data], { type });
  const relatorioURL = URL.createObjectURL(relatorio);
  if (isSafari()) {
    window.location = relatorioURL;
  } else {
    window.open(relatorioURL, '_blank');
  }
}


const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export function openPageFileBase64(data, type) {
  const relatorio = b64toBlob(data, type);
  const relatorioURL = URL.createObjectURL(relatorio);
  if (isSafari()) {
    window.location = relatorioURL;
  } else {
    window.open(relatorioURL, '_blank');
  }
}

export function openPagePDFBase64(data) {
  const relatorio = b64toBlob(data, 'application/pdf');
  const relatorioURL = URL.createObjectURL(relatorio);
  if (isSafari()) {
    window.location = relatorioURL;
  } else {
    window.open(relatorioURL, '_blank');
  }
}

export function downloadFileSispag(filename, bytesFile) {
  const url = window.URL.createObjectURL(b64toBlob(bytesFile));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
}

export function downloadFile(filename, bytesFile) {
  const url = window.URL.createObjectURL(new Blob([bytesFile]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
}

export function getDateNow() {
  return toBrazilianDate(new Date());
}

// 30 DIAS CONSIDERANDO MÊS COMERCIAL
export function getDate30daysAgo() {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  return toBrazilianDate(date);
}

// 30 DIAS CORRIDOS
export function getDate30daysAgoCorridos() {
  const date = new Date();
  date.setDate(date.getDate() - 30);
  return toBrazilianDate(date);
}

export function getDate7daysAgo() {
  const date = new Date();
  date.setDate(date.getDate() - 7);
  return toBrazilianDate(date);
}

// @Deprecated
// Usar o componente OrdenacaoColuna
export const getArrowOrdenacao = (elementId, id, arrowOrdenacao) => {
  if (elementId === id) {
    return arrowOrdenacao ? <i className="fas fa-arrow-down" /> : <i className="fas fa-arrow-up" />;
  }
  return null;
};

export function getDate15daysAgo() {
  const data15DaysAgo = new Date();
  data15DaysAgo.setDate(data15DaysAgo.getDate() - 15);
  return toBrazilianDate(data15DaysAgo);
}

export const getCheckedValue = (attributeCondition, componentValue) => {
  if (attributeCondition) {
    return false;
  }
  return componentValue;
};

export function removeScrollFromBackgroundLayout() {
  document.getElementById('admin-layout').setAttribute('style', 'overflow-y: hidden');
  return () => {
    document.getElementById('admin-layout').setAttribute('style', 'overflow-y: auto');
  };
}

export function getFormatedDateUSA(date) {
  return new Date(date.split('-').join('/'));
}

export function textOverflow(text = '', amountCharacters) {
  if (text.length <= amountCharacters) {
    return text;
  }
  return `${text.slice(0, amountCharacters)}...`;
}

export function formatToNumberMask(value, suffix = '') {
  if ((!value && value !== 0) || typeof value !== 'number') {
    return '';
  }
  return `${Number(String(value)).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}${suffix}`;
}

/**
 * Insere elementos no index desejado de um array.
 * @param arr array que vai ganhar os novos elementos.
 * @param index index desejado.
 * @param newItems novos elementos.
 * @returns Novo array com os elementos novos e antigos.
 */
export const insertIntoArray = (arr, index, ...newItems) => [
  ...arr.slice(0, index),
  ...newItems,
  ...arr.slice(index),
];

export function removeTags(value) {
  if (value !== null) {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })?.replace(/<.*?>/g, '');
  } return value;
}

export function getDatePlus(days) {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return toBrazilianDate(date);
}

export function colocarTagEmString(textoFormatado, valor, tag) {
  return textoFormatado.replace(valor, `<${tag}>${valor}</${tag}>`);
}

export function getDateDown(days) {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return toBrazilianDate(date);
}

export function getDate60daysAgo() {
  const date = new Date();
  date.setMonth(date.getMonth() - 2);
  return toBrazilianDate(date);
}
