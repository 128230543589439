import axios from 'axios';
import {
  gateway, gatewayImage, gatewayShortJson,
} from '../../api';
import { getUriParams } from '../../../helpers/utils/utils';

const CORE = process.env.REACT_APP_CORE;

export function getShortUsuarios(tipoUsuario) {
  const uri = getUriParams({ tipoUsuario });
  return gatewayShortJson.get(`${CORE}/usuarios?${uri}`);
}

export function getTiposUsuario() {
  return gatewayShortJson.get(`${CORE}/usuarios/tipos`);
}

export function getPerfiesUsuario() {
  return gateway.get(`${CORE}/usuarios/perfis`);
}

export function getPermissoesUsuario() {
  return gateway.get(`${CORE}/usuarios/permissoes`);
}

export function getPermissoesUsuarioByIdPerfil(idPerfilUsuario) {
  return gatewayShortJson.get(`${CORE}/usuarios/perfis/${idPerfilUsuario}/permissoes`);
}

export function getPermissoesUsuarioByIdUsuario(idUsuario) {
  return gatewayShortJson.get(`${CORE}/usuarios/${idUsuario}/permissoes`);
}

export function insertUsuario(payload) {
  return gateway.post(`${CORE}/usuarios`, payload);
}

export function updateUsuario(payload, idUsuario) {
  return gateway.put(`${CORE}/usuarios/${idUsuario}`, payload);
}

export function saveFotoUsuario(idUsuario, image) {
  const data = new FormData();
  data.append('fotoUsuario', image);
  return gateway.post(`${CORE}/usuarios/${idUsuario}/foto`, data);
}

export function findUsuarios(nomeUsuario, tipoUsuario, filial, statusUsuario, page, size) {
  const query = getUriParams({
    nomeUsuario, tipoUsuario, filial, statusUsuario, page, size,
  });
  return gateway.get(`${CORE}/usuarios?${query}`);
}

export function deleteUsuario(idUsuario) {
  return gateway.delete(`${CORE}/usuarios/${idUsuario}`);
}

export function obterFotoUsuario(idUsuario, format = 'BASE_64') {
  return gatewayImage.get(`${CORE}/usuarios/${idUsuario}/foto`, { params: { format } });
}

export function getComparacaoPermissoesUsuario(idPerfil, idUsuario, permissoes = [], qtdsMaxComparacoes = 20) {
  const params = { params: { qtdeMaxComparacoes: qtdsMaxComparacoes } };
  if (idPerfil !== undefined) {
    return gateway.post(
      `${CORE}/usuarios/perfis/${idPerfil}/permissoes/comparar`,
      permissoes,
      params,
    );
  }
  return gateway.post(`${CORE}/usuarios/${idUsuario}/permissoes/comparar`, permissoes, params);
}

export function getUsuarioCompletoPeloId(idUsuario) {
  return gateway.get(`${CORE}/usuarios/${idUsuario}`);
}

export function getTiposUsuariosCliente(idCliente, tipoUsuarioComercial) {
  const params = getUriParams({ tipoUsuarioComercial });
  return gatewayShortJson.get(`${CORE}/usuarios/tipos/comerciais/${idCliente}?${params}`);
}

export function getTiposUsuariosNegociador() {
  return gatewayShortJson.get(`${CORE}/usuarios/tipos/negociador`);
}

export function buscarNegociadoresDaFaseService(idFase) {
  return gatewayShortJson.get(`${CORE}/usuarios/negociadores/fases/${idFase}`);
}

export function getUsuariosElegiveisFazerParteEquipeService(idUsuario) {
  return gateway.get(`${CORE}/usuarios/equipe/${idUsuario}`);
}

// Ids tipos usuários

export function buscarIdTipoUsuarioComercial() {
  return gateway.get(`${CORE}/usuarios/tipo-usuario/comercial`);
}

export function buscarIdTipoUsuarioAdvogado() {
  return gateway.get(`${CORE}/usuarios/tipo-usuario/advogado`);
}

export function buscarIdTipoAdvogadoExterno() {
  return gateway.get(`${CORE}/usuarios/tipo-usuario/advogado-externo`);
}

export function buscarIdTipoUsuarioAdministrativo() {
  return gateway.get(`${CORE}/usuarios/tipo-usuario/administrativo`);
}

export function buscarIdTipoUsuarioTelemarketing() {
  return gateway.get(`${CORE}/usuarios/tipo-usuario/telemarketing`);
}

export function buscarIdTipoUsuarioNegociador() {
  return gateway.get(`${CORE}/usuarios/tipo-usuario/negociador`);
}

export function buscarIdTipoUsuarioNegociadorExterno() {
  return gateway.get(`${CORE}/usuarios/tipo-usuario/negociador-externo`);
}

export function getSerComercial(idUsuario, accessToken) {
  const URL = process.env.REACT_APP_URL;
  const gatewayAuth = axios.create({
    baseURL: URL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: accessToken,
    },
    timeout: 11000,
  });
  return gatewayAuth.get(`${CORE}/usuarios/buscar-ser-comercial/${idUsuario}`);
}

export function atualizaDataHoraOnline(idUsuario) {
  return gateway.get(`${CORE}/usuarios/atualiza-data-hora-online/${idUsuario}`);
}
