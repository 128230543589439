import { Tooltip } from '@material-ui/core';
import React, { useCallback } from 'react';
import { FaEraser } from 'react-icons/fa';
import { SimpleTitle } from '../../../../../../components/Title/SimpleTitle/SimpleTitle';
import {
  ButtonConfirmar, Container, CustomButtonLimpar, Inputs,
} from './BoletosStyles';
import IconAgrupamentoBlue from '../../../../../../assets/images/icons/iconAgrupamentoBlue.svg';
import DateField from '../../../../../../components/UI/Field/Date/DateField';
import MoneyField from '../../../../../../components/UI/Field/Money/MoneyField';
import { getRequiredLabel } from '../../../../../../helpers';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { ButtonUI } from '../../../../../../components';
import { onFocusBoletoAction, resetFormFieldsNovoBoletoAction, setNovoBoletoAction } from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { inserirNovoBoletoAction } from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';


function NovoBoletoComponent() {
  const dispatch = useCCClienteDispatch();

  const formFields = useCCClienteSelector(states => states.boletosRelacaoPrestacao.formFields);
  const prestacaoComBoletoAgrupado = useCCClienteSelector(states => (states.relacaoPrestacao.informacoesAdicionais.prestacaoComBoletoAgrupado));
  const idsBoletosAgrupados = useCCClienteSelector(states => (states.relacaoPrestacao.informacoesAdicionais.idsBoletosAgrupados));
  const dataVencimentoBoleto = useCCClienteSelector(states => (states.relacaoPrestacao.informacoesAdicionais.dataVencimentoBoleto));

  const disabledLimpar = formFields.valor.value === ''
    && formFields.dataVencimentoBoleto.value === '';

  const disabledConfirmar = formFields.valor.value === ''
    || formFields.dataVencimentoBoleto.value === '';

  const onChangeFields = (event) => {
    const { name, value } = event.target;
    dispatch(setNovoBoletoAction(name, value));
  };

  const onFocus = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(onFocusBoletoAction(fieldName));
  }, [dispatch]);

  const onClickLimpar = useCallback(() => {
    dispatch(resetFormFieldsNovoBoletoAction(dataVencimentoBoleto));
  }, [dispatch, dataVencimentoBoleto]);

  const onSubmitHandler = useCallback(() => {
    dispatch(inserirNovoBoletoAction());
  }, [dispatch]);

  return (
    <>
      <Container>
        <div style={{ justifyContent: 'space-between' }}>
          <div>
            <SimpleTitle
              text="Novo Boleto"
            />
            {prestacaoComBoletoAgrupado === true ? (
              <Tooltip title={idsBoletosAgrupados}>
                <img src={IconAgrupamentoBlue} alt="" width="15px" height="14px" />
              </Tooltip>
            ) : (
              ''
            )}
          </div>
          <div>
            <CustomButtonLimpar disabled={disabledLimpar} onClick={onClickLimpar}>
              <FaEraser size={18} />
              <span>Limpar</span>
            </CustomButtonLimpar>
          </div>
        </div>
        <Inputs>
          <DateField
            isForm
            fullWidth
            name="dataVencimentoBoleto"
            label={getRequiredLabel('Data de Vencimento', true)}
            value={formFields?.dataVencimentoBoleto.value}
            error={formFields.dataVencimentoBoleto.error}
            errorMessage={formFields.dataVencimentoBoleto.errorMessage}
            onChange={onChangeFields}
            onFocus={onFocus}
          />
          <MoneyField
            isForm
            fullWidth
            label={getRequiredLabel('Valor', true)}
            name="valor"
            value={formFields.valor.value}
            error={formFields.valor.error}
            errorMessage={formFields.valor.errorMessage}
            onChange={onChangeFields}
            onFocus={onFocus}
          />
        </Inputs>
        <ButtonConfirmar>
          <ButtonUI
            color="success"
            type="submit"
            disabled={disabledConfirmar}
            onClick={onSubmitHandler}
          >
            Confirmar
          </ButtonUI>
        </ButtonConfirmar>
      </Container>
    </>
  );
}
export const NovoBoleto = NovoBoletoComponent;
