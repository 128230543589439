import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  typography__textOne: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  typography__textTwo: {
    textAlign: 'center',
    marginTop: '7px',
  },
  div__progressbar: {
    width: '100%',
    marginTop: '34px',
  },
  div__qtdEnviados: {
    marginTop: '9px',
  },
  div__buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '32px',
    '& div:nth-child(2)': {
      width: '192px',
      marginLeft: '10px',
    },
  },
});
