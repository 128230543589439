import React from 'react';

import PrivateRoute from '../../hoc/PrivateRoute/PrivateRoute';
import PesquisarUsuarioPage from './Pesquisa/PesquisarUsuarioPage';
import CadastrarUsuarioPage from './V2/Crud/Cadastrar/CadastrarUsuarioPage';
import EditarUsuarioPage from './V2/Crud/Editar/EditarUsuarioPage';
import DetalharUsuarioPage from './V2/Crud/Detalhar/DetalharUsuarioPage';

export default function RoutesUsuario() {
  return (
    <>
      <PrivateRoute
        exact
        path="/usuario"
        component={PesquisarUsuarioPage}
      />
      <PrivateRoute
        exact
        path="/usuario/cadastrar"
        roles={['201']}
        tipoUsuario="U"
        component={CadastrarUsuarioPage}
      />
      <PrivateRoute
        exact
        path="/usuario/:id/detalhar"
        tipoUsuario="U"
        component={DetalharUsuarioPage}
      />
      <PrivateRoute
        exact
        path="/usuario/:id/editar"
        roles={['201']}
        tipoUsuario="U"
        component={EditarUsuarioPage}
      />

    </>
  );
}
