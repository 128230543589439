import styled from 'styled-components';
import Grid24 from '../../../../../../UI/Grid24';
import IconButtonTooltip from '../../../../../../UI/IconButtonTooltip/IconButtonTooltip';

export const TrashIconContainer = styled(Grid24)`
  position: relative;
  display: ${props => (props.showtrash ? 'flex' : 'none')};
`;

export const TrashIconButton = styled(IconButtonTooltip)`
  position: absolute;
  top: 8px;
`;
