import FieldModel from '../../models/FieldModel';
import { converterBlobToJson } from './converter.utils';

function isObjectOrArray(fieldsPath) {
  return fieldsPath.length > 1;
}

function isArray(fieldPath) {
  return /[[0-9]+]/g.test(fieldPath);
}


function getArrayInformations(fieldsPath) {
  return fieldsPath
    .replace('[', ';')
    .replace(']', '')
    .split(';');
}

function setErrorsInFields(fieldsPath, message, fields, addtionalErrors) {
  if (isObjectOrArray(fieldsPath)) {
    const path = fieldsPath.pop();
    if (isArray(path)) {
      const [name, index] = getArrayInformations(path);
      const children = fields[name][index];
      return setErrorsInFields(fieldsPath, message, children, addtionalErrors);
    }
    const children = fields[path];
    return setErrorsInFields(fieldsPath, message, children, addtionalErrors);
  }

  const path = fieldsPath[0];
  if (fields[path]) {
    fields[path].errorMessage = message;
    fields[path].error = true;
  } else {
    addtionalErrors.push({ field: fieldsPath, message });
  }
  return fields;
}

function updateErrorsInFields(validations, fields, addtionalErrors) {
  validations.forEach((validation) => {
    const fieldsPath = validation.field.split('.').reverse();
    setErrorsInFields(fieldsPath, validation.message, fields, addtionalErrors);
  });
  return fields;
}

function existFieldValue(fields) {
  return fields && (fields.value === null || fields.value === undefined);
}

function setFalseInErrorField(fields) {
  if (Array.isArray(fields)) {
    fields.map(setFalseInErrorField);
    return;
  }

  if (existFieldValue(fields)) {
    Object.keys(fields).forEach((key) => {
      setFalseInErrorField(fields[key]);
    });
    return;
  }
  if (fields instanceof FieldModel) {
    fields.error = false;
  }
}

function resetErrorField(fields) {
  Object.keys(fields).forEach((key) => {
    setFalseInErrorField(fields[key]);
  });
}

async function getValidationsFields(response) {
  if (response && response.data instanceof Blob) {
    const data = await converterBlobToJson(response.data);
    return data.validations;
  }
  if (response && response.data.validations) {
    return response.data.validations;
  }
  return null;
}

export async function getExceptionHandler(exception, fields) {
  const addtionalErrors = [];
  resetErrorField(fields);
  const { response } = exception;

  const validations = await getValidationsFields(response, fields, addtionalErrors);
  if (validations) {
    updateErrorsInFields(validations, fields, addtionalErrors);
  }
  return [fields, addtionalErrors];
}

export function getErrorMessages(errors = []) {
  const messages = [];
  if (!Array.isArray(errors) || errors.length === 0) {
    return [];
  }

  messages.push(errors[0].message);
  errors.forEach((error) => {
    if (!messages.includes(error.message)) {
      messages.push(error.message);
    }
  });
  return messages;
}

export function isNotExceptionValidations(exception) {
  return !exception.response.data.validations;
}

export function isExceptionFromValidations(exception) {
  return exception.response.data.validations;
}
