import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  blackTwo,
} from '../../../helpers/constants/colors.constants';

export default makeStyles({
  div__label: {
    display: 'flex',
  },
  label_title: {
    '& p': {
      fontSize: '12px',
      lineHeight: '14px',
      color: blackTwo,
      fontWeight: 'bold',
    },
  },
  label_value: {
    marginLeft: '5px',
    '& p': {
      fontSize: '12px',
      lineHeight: '14px',
      color: blackTwo,
    },
  },
});
