import { SET_SIMPLE_STATE } from './commonGlobal.constants';


function buildInitialStates() {
  return {
    habilitarAcordoSeguro: null,
  };
}

export const setSimpleGlobalCommonStateAction = (name, value) => ({
  type: SET_SIMPLE_STATE,
  name,
  value,
});

const handleSetSimpleGlobalCommonState = (states, actions) => {
  const { name, value } = actions;

  return {
    ...states,
    [name]: value,
  };
};


export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    case SET_SIMPLE_STATE:
      return handleSetSimpleGlobalCommonState(states, actions);
    default:
      return states;
  }
};
