import styled from 'styled-components';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import { widthScreen } from '../../helpers/styles/mediaScreenConstants';

export const EventsCalendarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 1;
  box-sizing: border-box;

  > div {
    width: 100%;
    box-sizing: border-box;
  }

  @media screen and (min-width: ${widthScreen.small}) {
    > div + div {
      margin-left: 8px;
    }

    > div:first-child {
      width: 189px;
    }

    > div:last-child {
      position: relative;
      width: 24px;
    }
  }
`;

export const TrashButton = styled(IconButtonTooltip)`
  position: absolute;
  top: -9px;
  left: -9px;
`;
