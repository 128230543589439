export function makeOrderBy(sortList = []) {
  return sortList.reduce((obj, sort) => (
    `${obj}&sort=${sort}`
  ), '');
}

export function concatQuertParam(filters, orderBy) {
  if (filters.length > 0 && orderBy.length > 0) {
    return `?${filters}&${orderBy}`;
  } if (filters.length > 0) {
    return `?${filters}`;
  } if (orderBy.length > 0) {
    return `?${orderBy}`;
  }
  return '';
}
