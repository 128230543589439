import { TableRow } from '@material-ui/core';
import React from 'react';
import { FaPen } from 'react-icons/fa';
import {
  TableActionButton, TableCellCenter, TableCellRight,
} from '../../../../../components/UI/Tabela/tableDefaultStyles';
import { getMoneyMask } from '../../../../../helpers';
import TooltipUI from '../../../../../components/UI/Tooltip/Tooltip';


function TableBodyPassoTres({
  content,
  handleClickButtonAlterarParcela,
  findValuePagamento,
  formaPagamentoPasso2,
}) {
  return (
    <>
      <TableRow>
        <TableCellCenter style={{ marginLeft: '20px' }}>
          {typeof (content.formaPagamento) === 'number' ? findValuePagamento(content.formaPagamento)[0].value : content.formaPagamento}
        </TableCellCenter>
        <TableCellCenter>
          {content.nParcela}
        </TableCellCenter>
        <TableCellRight>
          {getMoneyMask(content.valor, true)}
        </TableCellRight>
        <TableCellCenter>
          {content.vencimento}
        </TableCellCenter>
        <TableCellCenter>
          <TooltipUI title="Alterar Parcela">
            <TableActionButton
              style={{ marginRight: '10px' }}
              onClick={() => handleClickButtonAlterarParcela(content)}
              disabled={content.formaPagamento === 2 || (content.formaPagamento === 5 && formaPagamentoPasso2 === 5)}
            >
              <FaPen size={19} />
            </TableActionButton>
          </TooltipUI>
        </TableCellCenter>
      </TableRow>
    </>
  );
}

export default TableBodyPassoTres;
