import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

import ButtonUI from '../../Button/ButtonUI';

import WarningIcon from '../../../../assets/images/icons/warning.svg';
import ErrorIcon from '../../../../assets/images/icons/error.svg';
import SuccessIcon from '../../../../assets/images/icons/success.svg';

import { CONFIRM, CANCEL } from '../../../../helpers/constants/global.constants';
import useStyles from './OptionDialog.styles';


const icons = {
  warning: WarningIcon,
  error: ErrorIcon,
  success: SuccessIcon,
};

function buildIcons(variant, styles) {
  if (!variant) {
    return null;
  }
  return (
    <div className={styles.icon}>
      <img src={icons[variant]} alt="Aviso!" />
    </div>
  );
}

function OptionDialog({
  children,
  onClickConfirm,
  onClickCancel,
  confirmLabel = CONFIRM,
  cancelLabel = CANCEL,
  variant,
  ...props
}) {
  const styles = useStyles();

  return (
    <Dialog {...props}>
      <DialogContent className={styles.root}>
        {buildIcons(variant, styles)}
        <div className={styles.content}>{children}</div>
        <div className={styles.footer}>
          <ButtonUI color="error" onClick={onClickCancel}>
            {cancelLabel}
          </ButtonUI>
          <ButtonUI color="success" onClick={onClickConfirm}>
            {confirmLabel}
          </ButtonUI>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default OptionDialog;
