import moment from 'moment';
import ContaCorrenteClienteService, { findAllResponsaveis, findAllTiposDeLancamentos } from '../../services/core/contaCorrenteClienteService/ContaCorrenteClienteService';
import FieldModel from '../../models/FieldModel';
import {
  getMoneyMask, textOverflow, toAmericanDate, toBrazilianDate,
} from '../../helpers';

moment.locale('pt-br');
export const ContaCorrenteClienteFactory = {

  getProximoDiaUtil(date) {
    if (date.getDay() === 0) {
      return moment(date).add(1, 'd');
    }
    if (date.getDay() === 6) {
      return moment(date).add(2, 'd');
    }
    if (date.getDay() === 5) {
      return moment(date).add(3, 'd');
    }
    return moment(date).add(1, 'd');
  },


  isFimDeSemana(date, minDate) {
    const payloadDateAmericanFormat = toAmericanDate(date);
    const formatedDate = moment(payloadDateAmericanFormat);

    if (formatedDate.isValid() && formatedDate > minDate) {
      return formatedDate._d.getDay() === 0 || formatedDate._d.getDay() === 6;
    }
    return false;
  },

  makeContentResponseContaCorrente(contaCorrente) {
    return {
      idCliente: contaCorrente.idCliente,
      nomeCliente: contaCorrente.nomeCliente,
      tipoPrestacao: contaCorrente.descricaoTipoPrestacao,
      statusPrestacaoContas: contaCorrente.statusPrestacaoContas,
      valorRepasse: getMoneyMask(contaCorrente.valorRepasse),
      valorRepasseWithoutMask: contaCorrente.valorRepasse,
    };
  },

  makeTiposDeLancamentos(tiposDeLancamentos) {
    return {
      id: String(tiposDeLancamentos?.idTipoLancamento),
      value: tiposDeLancamentos?.descricao,
    };
  },
  makeResponsaveis(responsaveis) {
    return {
      id: responsaveis?.idUsuario,
      value: responsaveis?.nomeUsuario,
    };
  },

  makeContasCorrentes(res) {
    return {
      content: res?.content?.map(this.makeContentResponseContaCorrente) || [],
      size: res?.size || 10,
      page: res?.number || 0,
      totalElements: res?.totalElements || 0,
      lastPage: res?.last,
    };
  },

  makeTodasContasCorrentes(res) {
    return res.map(cliente => ({
      idCliente: cliente.idCliente,
      nomeClienteAlt: cliente?.nomeCliente || null,
      nomeCliente: textOverflow(cliente?.nomeCliente || '', 23),
      tipoPrestacao: cliente.descricaoTipoPrestacao,
      statusPrestacaoContas: cliente.statusPrestacaoContas,
      valorRepasse: getMoneyMask(cliente.valorRepasse),
      valorRepasseWithoutMask: cliente.valorRepasse,
    }));
  },

  makeIdsContasCorrenteClientes(res) {
    return res.map(cliente => ({
      idCliente: cliente.idCliente,
      statusPrestacaoContas: cliente.statusPrestacaoContas,
    }));
  },

  buildInitialStatesForm() {
    return {
      dataPrestacao: new FieldModel({ name: 'dataPrestacao', value: toBrazilianDate(new Date()) }),
      idCliente: new FieldModel({ name: 'idCliente', value: '' }),
      idFilial: new FieldModel({ name: 'idFilial', value: null }),
      idUsuarioResponsavel: new FieldModel({ name: 'idUsuarioResponsavel', value: null }),
      numeroBoleto: new FieldModel({ name: 'numeroBoleto', value: null }),
      tipoLancamento: new FieldModel({ name: 'tipoLancamento', value: null }),
    };
  },

  makeErrorFields() {
    return {
      idCliente: {
        error: false,
        errorMessage: '',
      },
      dataPrestacao: {
        error: false,
        errorMessage: '',
      },
    };
  },

};


export const ContaCorrenteClienteController = {
  async findAllTiposDeLancamentos() {
    const responseNome = await findAllTiposDeLancamentos();
    const tiposResponse = responseNome.data.map(ContaCorrenteClienteFactory.makeTiposDeLancamentos);
    return { tiposDeLancamentos: tiposResponse };
  },
  async findAllResponsaveis() {
    const responseNome = await findAllResponsaveis();
    const responsaveisResponse = responseNome.data.map(ContaCorrenteClienteFactory.makeResponsaveis);
    return { responsaveis: responsaveisResponse };
  },

  async getDetalhesCliente(idClienteSelecionado) {
    const detalhesResponse = await ContaCorrenteClienteService.getDetalhesCliente(idClienteSelecionado);
    const detalhesFinanceiroResponse = await ContaCorrenteClienteService.getDetalhesFinanceiroCliente(idClienteSelecionado);

    return { detalhes: detalhesResponse.data, financeiro: detalhesFinanceiroResponse.data };
  },

  async pesquisarContaCorrenteCliente(payload) {
    const {
      dataPrestacao, idFilial, idCliente, numeroBoleto, idUsuarioResponsavel, tipoLancamento, exibirApenasDesbloqueados,
    } = payload;
    const todosIds = await ContaCorrenteClienteService.pesquisarContaCorrenteTodosCliente(toAmericanDate(dataPrestacao), idFilial, idCliente, numeroBoleto, idUsuarioResponsavel, tipoLancamento, exibirApenasDesbloqueados);

    return todosIds.data;
  },

  async downloadRelatorioClientes(fields, idsContasCorrenteClientes, columnOrdenacao) {
    const httpResponse = await ContaCorrenteClienteService.downloadRelatorioClientes(
      toAmericanDate(fields.dataPrestacao),
      fields.idFilial,
      fields.idCliente,
      fields.numeroBoleto,
      fields.idUsuarioResponsavel,
      fields.tipoLancamento,
      fields.exibirApenasDesbloqueados,
      ContaCorrenteClienteFactory.makeIdsContasCorrenteClientes(idsContasCorrenteClientes),
      columnOrdenacao,
    );
    return httpResponse;
  },

  async prestarContas(payload) {
    const response = await ContaCorrenteClienteService.prestarContasCliente(payload);
    return { status: response.status };
  },

  async prestarTodos(payload) {
    const response = await ContaCorrenteClienteService.prestarTodos(payload);
    return { status: response.status };
  },
};
