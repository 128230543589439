import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  pesquisaAvancada: {
    float: 'right',
    marginRight: '50px',
    '& button': {
      fontWeight: 'bold',
      textTransform: 'none',
      fontSize: '12px',
    },
  },
  pesquisaAvancadaButton: {
    color: '#034ddc',
  },
  pesquisaSimplesButton: {
    color: '#707070',
  },
  hidePesquisaAvancada: {
    display: 'none',
  },
  buttonNovo: {
    marginTop: '46px',
    '& button': {
      width: '152px',
    },
  },
  tableTitle: {
    fontWeight: 'bold',
    margin: '0px 0px 13px 13px',
  },
});
