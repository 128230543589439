import { getDate60daysAgo } from '../../helpers';
import FieldModel from '../../models/FieldModel';
import {
  ON_CHANGE_RECEBIMENTOS_ACTION,
  ON_CHANGE_RECEBIMENTOS_MANUAIS_ACTION,
  ON_FOCUS_DATE_RECEBIMENTOS_ACTION,
  ON_FOUCUS_RECEBIMENTOS_MANUAIS_ACTION,
  RESET_FORM_FIELDS_ACTION,
  SET_COMMON_ACTION, SET_ERRO_EM_CAMPO_ACTION, SET_FORM_FIELDS_RECEBIMENTOS_ACTION, SET_SIMPLE_RECEBIMENTO_ACTION, SET_SIMPLE_VALUE_ARQUIVO_ACTION, SET_SIMPLE_VALUE_INFO_CARDS_ACTION, SET_SIMPLE_VALUE_RECEBIMENTO_DISTRIBUICAO,
} from './RecebimentoDevedores.constants';


function buildRequestFields() {
  return {
    dataVencimento: new FieldModel({
      name: 'dataVencimento',
      value: getDate60daysAgo(new Date()),
    }),
    nomeCliente: new FieldModel({
      name: 'cliente',
      value: '',
    }),
    nomeDevedor: new FieldModel({
      name: 'devedor',
      value: '',
    }),
    dataPagamento: new FieldModel({
      name: 'dataPagamento',
      value: '',
    }),
    idTipoFormaPagamento: new FieldModel({
      name: 'idTipoFormaPagamento',
      value: '',
    }),
    valorDistribuido: new FieldModel({
      name: 'valorDistribuido',
      value: '',
    }),
    somenteParcelasAguardandoPagamento: new FieldModel({
      name: 'somenteParcelasAguardandoPagamento',
      value: true,
    }),
    somenteParcelasrecebidasNaoDistribuidas: new FieldModel({
      name: 'somenteParcelasrecebidasNaoDistribuidas',
      value: false,
    }),
  };
}

function buildRecebimentoManual(arquivo) {
  return {
    dataPagamento: new FieldModel({
      name: 'dataPgto',
      value: '',
    }),
    valorPago: new FieldModel({
      name: 'valor',
      value: '',
    }),
    comprovante: arquivo?.comprovante || '',
  };
}

function buildRecebimentoDistribuicao() {
  return {
    valorRepasse: new FieldModel({
      name: 'valorRepasse',
      value: 0,
    }),
    valorTotalRecebimentoDistribuicao: 0,
    idAcordoParcelaDistribuicao: 0,
    dataPagamento: '',
  };
}


function buildInitialStates() {
  return {
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    columnOrdenacao: { element: 'dataVencimento', order: 'ASC' },
    arrowDirection: false,
    tableView: [],
    idAcordoParcelas: [],
    recebimentosNoDia: 0,
    valoresRecebidosNoDia: 0,
    parcelasAguardandoPagamento: 0,
    parcelasAtrasadas: 0,
    formFields: buildRequestFields(),
    cacheFormFields: null,
    recebimentoManual: buildRecebimentoManual(),
    idAcordo: null,
    nroParcela: null,
    numeroProcesso: null,
    numeroAcordo: null,
    tipoFormaPagto: null,
    valorParcela: null,
    idProcesso: null,
    idAcordoParcela: null,
    recebimentoDistribuicao: buildRecebimentoDistribuicao(),
    common: {
      loadingPage: false,
    },
  };
}

export const setSimpleRecebimentoAction = (name, value) => ({
  type: SET_SIMPLE_RECEBIMENTO_ACTION,
  name,
  value,
});

export const setCommonAction = (name, value) => ({
  type: SET_COMMON_ACTION,
  name,
  value,
});

export const setSimpleValueInfoCardsAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_INFO_CARDS_ACTION,
  name,
  value,
});

export const onChangeFormFieldsRecebimentosAction = (name, value) => ({
  type: ON_CHANGE_RECEBIMENTOS_ACTION,
  name,
  value,
});

export const onFocusDateRecebimentosAction = name => ({
  type: ON_FOCUS_DATE_RECEBIMENTOS_ACTION,
  name,
});

export const setFormFieldsRecebimentosAction = (name, value) => ({
  type: SET_FORM_FIELDS_RECEBIMENTOS_ACTION,
  name,
  value,
});

export const onChangeRecebimentoManualAction = (name, value) => ({
  type: ON_CHANGE_RECEBIMENTOS_MANUAIS_ACTION,
  name,
  value,
});

export const onFoucusRecebimentoManualAction = name => ({
  type: ON_FOUCUS_RECEBIMENTOS_MANUAIS_ACTION,
  name,
});

export const setSimpleValueArquivoAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_ARQUIVO_ACTION,
  name,
  value,
});


export const resetFormFieldsAction = () => ({
  type: RESET_FORM_FIELDS_ACTION,
});


export const setErroEmCampoAction = (campo, msg) => ({
  type: SET_ERRO_EM_CAMPO_ACTION,
  campo,
  msg,
});

export const setSimpleValueRecebimentoDistribuicao = (name, value) => ({
  type: SET_SIMPLE_VALUE_RECEBIMENTO_DISTRIBUICAO,
  name,
  value,
});

function setErroEmCampoHandler(states, actions) {
  const { campo, msg } = actions;
  const campoField = new FieldModel({
    ...states.recebimentoManual[campo],
    error: true,
    errorMessage: msg,
  });
  return {
    ...states,
    recebimentoManual: {
      ...states.recebimentoManual,
      [campo]: campoField,
    },
  };
}

function setSimpleRecebimentoHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    [name]: value,
  };
}

function setSimpleValueInfoCardsHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    cards: {
      ...states.cards,
      [name]: value,
    },
  };
}

function onChangeFormFieldsRecebimentosHandler(states, actions) {
  const { name, value } = actions;
  const model = states.formFields[name].getNewModel(value);

  return {
    ...states,
    formFields: {
      ...states.formFields,
      [name]: model,
    },
  };
}

function onFocusDateRecebimentosHandler(states) {
  const dataVencimento = states.formFields.dataVencimento.copy();


  return {
    ...states,
    formFields: {
      ...states.formFields,
      dataVencimento: dataVencimento.onFocus(),
    },
  };
}

function setFormFieldsRecebimentosHandler(states, actions) {
  const { name, value } = actions;
  const fieldModel = states.formFields[name].copy();
  return {
    ...states,
    formFields: {
      ...states.formFields,
      [name]: fieldModel.onChange(value),

    },
  };
}

function setCommonHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    common: {
      ...states.common,
      [name]: value,
    },
  };
}


function onChangeRecebimentosManuaisHandler(states, actions) {
  const { name, value } = actions;
  const model = states.recebimentoManual[name].copy();
  model.error = false;
  model.errorMessage = '';
  return {
    ...states,
    recebimentoManual: {
      ...states.recebimentoManual,
      [name]: model.onChange(value),
    },
  };
}

function setSimpleValueRecebimentosDistribuicao(states, actions) {
  const { name, value } = actions;
  let model = null;
  if (states.recebimentoDistribuicao[name].name) {
    model = states.recebimentoDistribuicao[name].copy();
  }
  if (model) {
    return {
      ...states,
      recebimentoDistribuicao: {
        ...states.recebimentoDistribuicao,
        [name]: model.onChange(value),
      },
    };
  }
  return {
    ...states,
    recebimentoDistribuicao: {
      ...states.recebimentoDistribuicao,
      [name]: value,
    },
  };
}

function onFoucusRecebimentosManuaisHandler(states, actions) {
  const { name } = actions;
  const model = states.recebimentoManual[name].copy();
  model.error = false;
  model.errorMessage = '';
  return {
    ...states,
    recebimentoManual: {
      ...states.recebimentoManual,
      [name]: model,
    },
  };
}


function setSimpleValueArquivoHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    recebimentoManual: {
      ...states.recebimentoManual,
      [name]: value,
    },
  };
}

function resetFormFieldsHandler(states) {
  return {
    ...states,
    formFields: {
      ...states.formFields,
      nomeCliente: new FieldModel({
        name: 'cliente',
        value: '',
      }),
      nomeDevedor: new FieldModel({
        name: 'devedor',
        value: '',
      }),
      dataPagamento: new FieldModel({
        name: 'dataPagamento',
        value: '',
      }),
      idTipoFormaPagamento: new FieldModel({
        name: 'idTipoFormaPagamento',
        value: '',
      }),
      valorDistribuido: new FieldModel({
        name: 'valorDistribuido',
        value: '',
      }),
    },
  };
}


export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    case SET_SIMPLE_RECEBIMENTO_ACTION:
      return setSimpleRecebimentoHandler(states, actions);
    case SET_SIMPLE_VALUE_INFO_CARDS_ACTION:
      return setSimpleValueInfoCardsHandler(states, actions);
    case ON_CHANGE_RECEBIMENTOS_ACTION:
      return onChangeFormFieldsRecebimentosHandler(states, actions);
    case ON_FOCUS_DATE_RECEBIMENTOS_ACTION:
      return onFocusDateRecebimentosHandler(states, actions);
    case SET_FORM_FIELDS_RECEBIMENTOS_ACTION:
      return setFormFieldsRecebimentosHandler(states, actions);
    case SET_COMMON_ACTION:
      return setCommonHandler(states, actions);
    case RESET_FORM_FIELDS_ACTION:
      return resetFormFieldsHandler(states, actions);
    case ON_CHANGE_RECEBIMENTOS_MANUAIS_ACTION:
      return onChangeRecebimentosManuaisHandler(states, actions);
    case ON_FOUCUS_RECEBIMENTOS_MANUAIS_ACTION:
      return onFoucusRecebimentosManuaisHandler(states, actions);
    case SET_SIMPLE_VALUE_ARQUIVO_ACTION:
      return setSimpleValueArquivoHandler(states, actions);
    case SET_ERRO_EM_CAMPO_ACTION:
      return setErroEmCampoHandler(states, actions);
    case SET_SIMPLE_VALUE_RECEBIMENTO_DISTRIBUICAO:
      return setSimpleValueRecebimentosDistribuicao(states, actions);
    default:
      return states;
  }
};
