import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;

  h3 {
    margin-right: 8px;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: #034DDC;
  }
`;
