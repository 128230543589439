
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';


import { FaEraser } from 'react-icons/fa';
import { MdLibraryBooks, MdLightbulbOutline } from 'react-icons/md';

import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import ButtonUI from '../../../components/UI/Button/ButtonUI';
import SelectorField from '../../../components/UI/Field/Selector/SelectorField';
import TextareaField from '../../../components/UI/Field/Textarea/TextareaField';
import TextoPadraoDialog from '../TextoPadraoDialog/TextoPadraoDialog';

import useStyles, { FooterContainer, MainContainer } from './AcaoAnotarStyles';
import {
  ActionHeaderContainer, CustomButton, TextoPadraoButton,
} from '../InspecionarProcessoStyles';


import { acaoAnotarController as controller } from './acaoAnotarController';
import { loadingInspecaoProcessoController as loadingController } from '../loadingInspecaoProcessoController';

import { clearErrorField, getFieldName, updateFieldsWithErrors } from '../../../helpers/utils/form.utils';
import { isBadRequestException } from '../../../helpers/utils/utils';
import { tipoTextoPadraoTypes } from '../../../types/tipoTextoPadraoTypes';
import { Title } from '../../../components/Title/Title';
import { buscarIsUsuarioAssistente, buscarUltimoProcessoAcompanhamento, inserirNovoAcompanhamento } from '../../../services/core/processos/processosService';
import OptionDialogNew from '../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';
import DialogGPT from '../../../components/DialogGPT/DialogGPT';
import TooltipUI from '../../../components/UI/Tooltip/Tooltip';
import { LightIndicator } from '../../../components/Title/Title.styles';
import { UltimoAcompanhamento } from '../AcaoGerarRelatorio/acaoGerarRelatorioStyles';
import { AcompanhamentoProcesso } from '../../../components/UI/AcompanhamentoProcesso/AcompanhamentoProcesso';
import { findQuantidadeRequisicoesAction } from '../../../store/theme.actions';
import { tiposAcompanhamentos } from '../../../types/tiposAcompanhamentoTypes';
import DialogNew from '../../../layouts/FormDialog/DialogNew';
import { AntecedenteProcesso } from '../AntecendentesProcesso';


function AcaoAnotarComponent({
  processo, tiposAcompanhamento = [], setLoadings, updatePage,
  idFaseAtual,
  idProcesso,
  clientePermiteUnificar,
  dadosProcesso,
  fasesIds,
  podeModificarFase,
  disabledAntecedentes,
}) {
  const [idTipoAcompanhamento, setIdTipoAcompanhemento] = useState('7');
  const [textoAnotacao, setTextoAnotacao] = useState('');
  const [textoRelatorio, setTextoRelatorio] = useState('');

  const [alert, setAlert] = useState({ show: false });
  const [showTextoPadraoAnotacao, setShowTextoPadraoAnotacao] = useState(false);
  const [showTextoPadraoRelatorio, setShowTextoPadraoRelatorio] = useState(false);
  const [errorsFields, setErrorsField] = useState(controller.makeDefaultErrorsFields());
  const [processoAcompanhamento, setProcessoAcompanhamento] = useState({});
  const [isUsuarioAssistente, setIsUsuarioAssistente] = useState(false);
  const [reescreveuRelatorio, setReescreveuRelatorio] = useState(false);

  const [antecedentesSelecionados, setAntecedentesSelecionados] = useState([]);
  const [openDialogAntecedentes, setOpenDialogAntecedentes] = useState(false);

  const styles = useStyles();

  // Dialog GPT
  const [showDialogTestRelatorio, setShowDialogTestRelatorio] = useState(false);
  const [showDialogTestAnotacao, setShowDialogTestAnotacao] = useState(false);
  const [desabilitarReescreverRelatorio, setDesabilitarReescreverRelatorio] = useState(true);
  const [desabilitarReescreverAnotacao, setDesabilitarReescreverAnotacao] = useState(true);

  const dispatch = useDispatch();

  function closeAlert() {
    setAlert({ ...alert, show: false });
  }

  function resetForm() {
    setTextoAnotacao('');
    setTextoRelatorio('');
    setIdTipoAcompanhemento('');
    setErrorsField(controller.makeDefaultErrorsFields());
    setDesabilitarReescreverRelatorio(true);
    setDesabilitarReescreverAnotacao(true);
  }

  function clearFieldsAfterCloseAlert() {
    closeAlert();
    resetForm();
  }

  async function submitForm(e) {
    e.preventDefault();
    setLoadings(loadingController.changeLoadingAction(true));
    try {
      if (textoAnotacao.trim().length > 0) {
        await inserirNovoAcompanhamento(processo.id, idTipoAcompanhamento, textoAnotacao.trim());
        await antecedentesSelecionados.forEach((idSelecionado) => { inserirNovoAcompanhamento(idSelecionado, idTipoAcompanhamento, textoAnotacao.trim()); });
      }
      if (textoRelatorio.trim().length > 0) {
        await inserirNovoAcompanhamento(processo.id, tiposAcompanhamentos.RELATORIO.key, textoRelatorio.trim());
        await antecedentesSelecionados.forEach((idSelecionado) => { inserirNovoAcompanhamento(idSelecionado, tiposAcompanhamentos.RELATORIO.key, textoRelatorio.trim()); });
      }

      toast.success('Ação realizada com sucesso!');
      resetForm();
      updatePage();

      dispatch(findQuantidadeRequisicoesAction());
    } catch (exception) {
      if (isBadRequestException(exception)) {
        const [updatedErrors] = updateFieldsWithErrors(exception, errorsFields);
        setErrorsField({ ...updatedErrors });
      }
    } finally {
      setLoadings(loadingController.changeLoadingAction(false));
    }
  }

  const clearError = useCallback((e) => {
    setErrorsField(clearErrorField(errorsFields, getFieldName(e.target)));
  }, [errorsFields]);

  const onChangeTipoAcompanhemento = useCallback((e) => {
    setIdTipoAcompanhemento(e.target.value);
  }, []);

  const onChangeTextoAnotacao = useCallback((e) => {
    setTextoAnotacao(e.target.value);
    const qtdePalavras = e.target.value.trim().split(' ').length;
    setDesabilitarReescreverAnotacao(qtdePalavras < 5);
  }, []);

  const onChangeTextoRelatorio = useCallback((e) => {
    setTextoRelatorio(e.target.value);
    const qtdePalavras = e.target.value.trim().split(' ').length;
    setDesabilitarReescreverRelatorio(qtdePalavras < 5);
  }, []);

  const openMelhorarTextoDialogAnotacao = () => {
    setShowDialogTestAnotacao(true);
  };

  const openMelhorarTextoDialogRelatorio = () => {
    setReescreveuRelatorio(true);
    setShowDialogTestRelatorio(true);
  };

  const updateTextoRelatorio = (newText) => {
    setTextoRelatorio(newText);
  };

  const updateTextoAnotacao = (newText) => {
    setTextoAnotacao(newText);
  };

  const onFocusFields = useCallback((e) => {
    setErrorsField(clearErrorField(errorsFields, e.target.name));
  }, [errorsFields]);

  const tipoAcompanhamentoSelectorItems = useMemo(() => tiposAcompanhamento.filter(tipo => tipo.id === 7 || tipo.id === 9), [tiposAcompanhamento]);

  function mainContainerAnotar() {
    return (
      <>
        <div>
          <SelectorField
            isForm
            style={{ width: '212px' }}
            items={tipoAcompanhamentoSelectorItems}
            name="idTipoAcompanhamento"
            label="Tipo do Acompanhamento*"
            value={idTipoAcompanhamento}
            error={errorsFields.idTipoAcompanhamento.error}
            errorMessage={errorsFields.idTipoAcompanhamento.errorMessage}
            onChange={onChangeTipoAcompanhemento}
            onFocus={clearError}
          />
        </div>
        <div>
          <TextareaField
            label="Texto*"
            name="textoAnotacao"
            minRows={8}
            placeholder="Inserir Texto"
            value={textoAnotacao}
            error={errorsFields.texto.error}
            errorMessage={errorsFields.texto.errorMessage}
            onChange={onChangeTextoAnotacao}
            onFocus={clearError}
          />
        </div>


        <div style={{ width: '100%', display: 'flex' }}>
          <TextoPadraoButton
            variant="outlined"
            onClick={() => setShowTextoPadraoAnotacao(true)}
          >
            <MdLibraryBooks />
            <span>Textos Padrão</span>
          </TextoPadraoButton>

          <TextoPadraoButton
            variant="outlined"
            onClick={openMelhorarTextoDialogAnotacao}
            style={{ marginLeft: '10px' }}
            disabled={desabilitarReescreverAnotacao}
          >
            <MdLibraryBooks />
            <span>Reescrever</span>
          </TextoPadraoButton>
          <TooltipUI style={{ marginTop: '4px', marginLeft: '8px' }} title="São necessárias no mínimo 5 palavras para reescrever o texto.">
            <LightIndicator><MdLightbulbOutline /></LightIndicator>
          </TooltipUI>
          <DialogGPT
            open={showDialogTestAnotacao}
            onClose={() => setShowDialogTestAnotacao(false)}
            initialText={textoAnotacao}
            updateText={updateTextoAnotacao}
          />

        </div>
      </>
    );
  }

  function mainContainerGerarRelatorio() {
    return (
      <>
        <TextareaField
          label="Texto*"
          name="textoRelatorio"
          minRows={6}
          placeholder="Inserir Texto"
          value={textoRelatorio}
          error={errorsFields.texto.error}
          errorMessage={errorsFields.texto.errorMessage}
          onChange={onChangeTextoRelatorio}
          onFocus={onFocusFields}
        />
        <div style={{ width: '100%', display: 'flex' }}>
          <TextoPadraoButton
            variant="outlined"
            onClick={() => setShowTextoPadraoRelatorio(true)}
          >
            <MdLibraryBooks />
            <span>Textos Padrão</span>
          </TextoPadraoButton>

          <TextoPadraoButton
            variant="outlined"
            onClick={openMelhorarTextoDialogRelatorio}
            style={{ marginLeft: '10px' }}
            disabled={desabilitarReescreverRelatorio}
          >
            <MdLibraryBooks />
            <span>Reescrever</span>
          </TextoPadraoButton>
          <TooltipUI style={{ marginTop: '4px', marginLeft: '8px' }} title="São necessárias no mínimo 5 palavras para reescrever o texto.">
            <LightIndicator><MdLightbulbOutline /></LightIndicator>
          </TooltipUI>
          <DialogGPT
            open={showDialogTestRelatorio}
            onClose={() => setShowDialogTestRelatorio(false)}
            initialText={textoRelatorio}
            updateText={updateTextoRelatorio}
          />

        </div>
        <UltimoAcompanhamento>
          <Typography component="p">Último Relatório</Typography>
          <div>
            <AcompanhamentoProcesso
              height={156}
              acompanhamentos={processoAcompanhamento}
              isDisbaledChangePin
            />
          </div>
        </UltimoAcompanhamento>
      </>
    );
  }

  useEffect(() => {
    setLoadings(loadingController.changeLoadingAction(true));
    buscarIsUsuarioAssistente().then(({ data }) => {
      setIsUsuarioAssistente(data === 'S');
    });

    buscarUltimoProcessoAcompanhamento(processo.id).then(({ data }) => {
      setProcessoAcompanhamento({ content: [data], last: true });
    }).catch(() => {
      setProcessoAcompanhamento({});
    }).finally(() => {
      setLoadings(loadingController.changeLoadingAction(false));
    });
  }, [processo, setLoadings]);

  function habilitaConcluir() {
    if (isUsuarioAssistente) {
      return textoRelatorio.trim().length > 0 && textoAnotacao.trim().length > 0 && reescreveuRelatorio;
    }
    return (textoRelatorio.trim().length === 0 && textoAnotacao.trim().length > 0) || (textoRelatorio.trim().length > 0 && reescreveuRelatorio);
  }

  return (
    <>
      <DialogNew
        open={openDialogAntecedentes}
        text="Antecedentes"
        className={styles.dialog}
      >
        <Typography component="p" className={styles.typography}>
          Selecione os antecedentes:
        </Typography>
        <AntecedenteProcesso
          idProcesso={idProcesso}
          setLoadings={setLoadings}
          clientePermiteUnificar={clientePermiteUnificar}
          dadosProcesso={dadosProcesso}
          updatePage={updatePage}
          podeModificarFase={podeModificarFase}
          idFaseProcesso={idFaseAtual}
          fasesIds={fasesIds}
          accordion={false}
          antecedentesSelecionados={antecedentesSelecionados}
        />
        <div className={styles.div__footer}>
          <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
            <Button
              onClick={() => {
                setOpenDialogAntecedentes(false);
                setAntecedentesSelecionados([]);
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => { setOpenDialogAntecedentes(false); }}
            >
              Confirmar
            </Button>
          </div>
        </div>
      </DialogNew>
      <TextoPadraoDialog
        open={showTextoPadraoAnotacao}
        tipos={[tipoTextoPadraoTypes.ACOMPANHAMENTO.value, tipoTextoPadraoTypes.DEVOLUCAO.value]}
        setTextoHandler={textoPadrao => setTextoAnotacao(oldValues => (` ${oldValues} ${textoPadrao} `))}
        closeDialogHandler={() => setShowTextoPadraoAnotacao(false)}
      />
      <TextoPadraoDialog
        open={showTextoPadraoRelatorio}
        tipos={[tipoTextoPadraoTypes.RELATORIO.value]}
        setTextoHandler={textoPadrao => setTextoRelatorio(oldValues => (` ${oldValues} ${textoPadrao} `))}
        closeDialogHandler={() => setShowTextoPadraoRelatorio(false)}
      />

      <OptionDialogNew
        open={alert.show}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickConfirm={clearFieldsAfterCloseAlert}
        onClickCancel={closeAlert}
        text="Tem certeza de que deseja descartar essas informações? "
      />
      <form onSubmit={submitForm} onReset={resetForm}>
        <ActionHeaderContainer>
          <Title
            text="Anotação no Processo"
            hint="A Anotação será inserida no Acompanhamento do Processo"
          />
          <CustomButton
            disabled={textoAnotacao === '' && String(idTipoAcompanhamento) === ''}
            onClick={() => setAlert({ ...alert, show: true })}
          >
            <FaEraser size={18} />
            <span>Limpar</span>
          </CustomButton>
        </ActionHeaderContainer>

        <MainContainer>
          {mainContainerAnotar()}
          <Title
            text="Relatório para Cliente"
            hint="O Relatório será inserido no Acompanhamento do Processo"
          />
          {mainContainerGerarRelatorio()}
        </MainContainer>
        <FooterContainer>

          <TextoPadraoButton
            variant="outlined"
            onClick={() => setOpenDialogAntecedentes(true)}
            style={{ marginRight: '10px', marginLeft: 'auto' }}
            disabled={disabledAntecedentes}
          >
            <MdLibraryBooks />
            <span>Antecedentes</span>
          </TextoPadraoButton>
          <ButtonUI
            color="success"
            style={{ width: '136px' }}
            type="submit"
            disabled={!habilitaConcluir()}
          >
            Concluir
          </ButtonUI>
        </FooterContainer>
      </form>
    </>
  );
}

export const AcaoAnotar = AcaoAnotarComponent;
