import React, { useState, useEffect, useCallback } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { withRouter } from 'react-router-dom';
import { MdGetApp as MdGetAppIcon } from 'react-icons/md';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { IconButton } from '@material-ui/core';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';

import { white, lightGrey } from '../../../../../../helpers/constants/colors.constants';
import { downloadPdfAction, getPdfAction } from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import {
  ButtonDownloadPdf, LabelNomeArquivo, LabelZoom, TableArquivo,
} from '../../../../contaCorrenteClienteStyles';

function ImprimirPdf({ match }) {
  const dispatch = useCCClienteDispatch();
  const pdf = useCCClienteSelector(states => states.pdf);
  const [numPages, setNumPages] = useState(null);
  const [nome, setNome] = useState('');
  const [scale, setScale] = useState(1.0);

  useEffect(() => {
    const { id, tipo } = match.params;
    if (pdf === null) {
      dispatch(getPdfAction(id));
      const dataAtual = `${new Date().getFullYear()}${(`00${new Date().getMonth() + 1}`).slice(-2)}${(`00${new Date().getDate()}`).slice(-2)}`;
      if (tipo === 'SISPAG') {
        setNome(`SISPAG_${dataAtual}_${match.params.id}.pdf`);
      }
      if (tipo === 'REPASSE') {
        setNome(`REPASSE_${dataAtual}_${match.params.id}.pdf`);
      }
    }
  }, [match, dispatch, pdf, setNome]);

  const onLoadSuccess = useCallback(({ numPages: numPagesSuccess }) => {
    setNumPages(numPagesSuccess);
  }, [setNumPages]);

  const goToDownload = useCallback(() => {
    dispatch(downloadPdfAction(match.params.id, nome));
  }, [dispatch, match, nome]);

  const zoomIn = useCallback(() => {
    const scaleFinal = (scale + 0.2).toFixed(2) ** 1;
    setScale(scaleFinal);
  }, [setScale, scale]);

  const zoomOut = useCallback(() => {
    const scaleFinal = (scale - 0.2).toFixed(2) ** 1;
    setScale(scaleFinal);
  }, [setScale, scale]);

  return (
    <center>
      <div>
        <TableArquivo id="header" hidden={numPages === null}>
          <tr>
            <td>
              <LabelNomeArquivo align="left">
                {nome}
              </LabelNomeArquivo>
            </td>
            <td align="center">
              <table>
                <tr>
                  <LabelZoom>
                    Zoom:
                  </LabelZoom>
                  <td>
                    <IconButton
                      size="small"
                      disabled={scale <= 0.2}
                      onClick={() => zoomOut()}
                    >
                      <div>
                        <FaMinus style={{ color: scale <= 0.2 ? lightGrey : white }} size={17} />
                      </div>
                    </IconButton>
                  </td>
                  <LabelZoom>
                    {`${((scale.toFixed(2)) * 100).toFixed(0)} %`}
                  </LabelZoom>
                  <td>
                    <IconButton
                      size="small"
                      disabled={scale >= 5.0}
                      onClick={() => zoomIn()}
                    >
                      <div>
                        <FaPlus style={{ color: scale >= 5.0 ? lightGrey : white }} size={17} />
                      </div>
                    </IconButton>
                  </td>
                </tr>
              </table>
            </td>
            <td>
              <div align="right">
                <ButtonDownloadPdf
                  margin-right="8px"
                  onClick={goToDownload}
                >
                  <MdGetAppIcon size={16} />
                  <span>Download</span>
                </ButtonDownloadPdf>
              </div>
            </td>
          </tr>
        </TableArquivo>
        <div>
          <Document
            file={pdf?.data}
            onLoadSuccess={onLoadSuccess}
          >
            {Array.from(
              new Array(numPages),
              (el, index) => (
                <>
                  <Page
                    scale={scale}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                  <div style={{ padding: '10px' }} />
                </>
              ),
            )}
          </Document>
        </div>
      </div>
    </center>
  );
}

export default withRouter(ImprimirPdf);
