import React from 'react';

import useStyles from './CardWrapper.styles';
import LoadingUI from '../Loading/LoadingUI';

function CardWrapper({ children, showLoading = false, ...props }) {
  const styles = useStyles(props);

  return (
    <>
      <LoadingUI show={showLoading} />
      <div className={styles.wrapper}>
        {children}
      </div>
    </>
  );
}

export default CardWrapper;
