import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Comissoes from '../../../../../UI/Comissao/Comissoes';
import {
  useCrudParametrosDispatch,
  useCrudParametrosSelector,
} from '../../../../../../store/Parametros/Crud/CrudParametrosProvider';
import {
  getListIntervalCorrectAfterDeleteQuantidade, getListIntervalCorrectAfterDeleteValor, getNextValueToRange,
} from '../../../../../../helpers/utils/utils';
import { setRequestFieldsAction, setRequestFieldsValueAction } from '../../../../../../store/Parametros/Crud/crudParametros.store';
import { findRegraComissaoComercial, findTipoCalculoComissaoAction } from '../../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import { buildParametroComissao } from '../../../../../../helpers/factory/parametro.factory';


function ParametroComissoes({ isInsertMode = false, location }) {
  const dispatch = useCrudParametrosDispatch();
  const globalDispatch = useDispatch();

  const [variationValidations] = useState({ MONEY: 0.01, DIAS: 1 });
  const [maxValueValidations] = useState({ MONEY: 9999999999.99, DIAS: 999999 });

  const selectorComissao = useSelector(states => states.selectors.tipoCalculoComissaoParametro.selector);
  const tiposCalculoComissoes = useCrudParametrosSelector(state => [...state.requestFields.tiposCalculoComissoes]);
  const tipoComissaoSelecionada = useCrudParametrosSelector(state => state.requestFields.tipoComissaoSelecionada.copy());

  // Carregar o combo box do tipo calculo
  useEffect(() => {
    globalDispatch(findTipoCalculoComissaoAction());
    globalDispatch(findRegraComissaoComercial());
  }, [globalDispatch, location.refreshPage]);

  const onChangeTipoComissaoHandler = useCallback((event) => {
    const { name, value } = event.target;

    dispatch(setRequestFieldsValueAction(name, value));
  }, [dispatch]);

  const addComissaoHandler = useCallback((event) => {
    const idxTipoComissao = +event.currentTarget.getAttribute('identifier');
    const { comissoes, type } = tiposCalculoComissoes[idxTipoComissao];

    const maxValue = maxValueValidations[type.value];
    const variation = variationValidations[type.value];
    const nextValue = getNextValueToRange(comissoes, 'valorFaixaFinal', maxValue, variation);
    const newComissao = {
      valorFaixaInicial: nextValue,
      valorFaixaFinal: maxValue,
      porcentagemComissao: 0,
      porcentagemPagtoDiretoComissao: 0,
      idTipoRegraComissaoComercial: 0,
    };

    tiposCalculoComissoes[idxTipoComissao].comissoes.push(buildParametroComissao(newComissao));
    dispatch(setRequestFieldsAction('tiposCalculoComissoes', tiposCalculoComissoes));
  }, [tiposCalculoComissoes, dispatch, maxValueValidations, variationValidations]);

  const changeComissaoHandler = useCallback((event) => {
    const { name, value } = event.target;
    const [nome, idxTipoComissao, idxComissao] = name.split('-');
    const { comissoes } = tiposCalculoComissoes[idxTipoComissao];


    const updatedModel = comissoes[idxComissao][nome].copy().onChange(value);
    tiposCalculoComissoes[idxTipoComissao].comissoes[idxComissao][nome] = updatedModel;

    dispatch(setRequestFieldsAction('tiposCalculoComissoes', tiposCalculoComissoes));
  }, [tiposCalculoComissoes, dispatch]);

  const onFocusComissaoHandler = useCallback((event) => {
    const { name } = event.target;
    const [nome, idxTipoComissao, idxComissao] = name.split('-');
    const { comissoes } = tiposCalculoComissoes[idxTipoComissao];

    const updatedModel = comissoes[idxComissao][nome].copy().onFocus();
    tiposCalculoComissoes[idxTipoComissao].comissoes[idxComissao][nome] = updatedModel;

    dispatch(setRequestFieldsAction('tiposCalculoComissoes', tiposCalculoComissoes));
  }, [tiposCalculoComissoes, dispatch]);


  const removeComissaoHandler = useCallback((event) => {
    const idxComissao = +event.currentTarget.getAttribute('idxComissao');
    const idxTipoComissao = +event.currentTarget.getAttribute('idxTipoComissao');

    tiposCalculoComissoes[idxTipoComissao].comissoes.splice(idxComissao, 1);
    let listaComissao = [{}];
    if (idxTipoComissao === 0) {
      listaComissao = getListIntervalCorrectAfterDeleteValor(tiposCalculoComissoes[idxTipoComissao].comissoes, 'valorFaixaFinal', 'valorFaixaInicial', idxComissao, 9999999999.99);
    } if (idxTipoComissao === 1) {
      listaComissao = getListIntervalCorrectAfterDeleteQuantidade(tiposCalculoComissoes[idxTipoComissao].comissoes, 'valorFaixaFinal', 'valorFaixaInicial', idxComissao, 999999);
    }
    dispatch(setRequestFieldsAction('comissoes', listaComissao));
  }, [tiposCalculoComissoes, dispatch]);

  return (
    <Comissoes
      disabled={!isInsertMode}
      selectorItens={selectorComissao}
      selector={tipoComissaoSelecionada}
      tiposCalculoComissoes={tiposCalculoComissoes}
      onChangeSelector={onChangeTipoComissaoHandler}
      onChangeComissaoItem={changeComissaoHandler}
      onFocusComissaoItem={onFocusComissaoHandler}
      onAddHandler={addComissaoHandler}
      onRemoveComissaoItem={removeComissaoHandler}
    />
  );
}

export default withRouter(ParametroComissoes);
