import loadSelectors from './Global/LoadSelectors';

import watchPesquisaCliente from './Cliente/Pesquisar/pesquisarCliente.saga';
import watchHistoricoAS from './AcordoSeguro/Historico/historicoAS.saga';
import watchRelatorioAS from './AcordoSeguro/Relatorios/relatoriosAS.saga';
import watchAcordosAs from './AcordoSeguro/Acordos/acordosAs.saga';
import watchLoginSaga from './Login/login.saga';
import watchProcessarBorderos from './Borderos/Processar/processarBorderos.saga';
import watchImportarBordero from './Borderos/Importar/importarBordero.saga';
import watchPesquisaFilial from './Filial/Pesquisar/pesquisarFilial.saga';
import watchPesquisaUsuario from './Usuario/Pesquisa/pesquisarUsuario.saga';
import watchCCCliente from './ContaCorrenteCliente/contaCorrenteCliente.saga';
import { watchSagaMenu } from './menu.saga';
import watchCommonGlobal from './Global/CommonGlobal/commonGlobal.saga';

export function configSageMiddleware(sageMiddleware) {
  sageMiddleware.run(watchLoginSaga);
  sageMiddleware.run(watchProcessarBorderos);
  sageMiddleware.run(loadSelectors.watchLoadSelectors);
  sageMiddleware.run(watchPesquisaFilial);
  sageMiddleware.run(watchPesquisaUsuario);
  sageMiddleware.run(watchPesquisaCliente);
  sageMiddleware.run(watchImportarBordero);
  sageMiddleware.run(watchHistoricoAS);
  sageMiddleware.run(watchRelatorioAS);
  sageMiddleware.run(watchAcordosAs);
  sageMiddleware.run(watchSagaMenu);
  sageMiddleware.run(watchCCCliente);
  sageMiddleware.run(watchCommonGlobal);
}
