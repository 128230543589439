import { useDispatch } from 'react-redux';
import React, { useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { initializePageAction } from '../../../../store/theme.actions';
import withTokenInterceptor from '../../../../hoc/withTokenInterceptor/withTokenInterceptor';
import { LancamentoRecebimento } from './LancamentoRecebimento';
import RecebimentoClienteProvider from '../../../../store/Recebimento/RecebimentoClienteProvider';


function RecebimentoClienteEditarPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  dispatch(initializePageAction('Recebimentos Clientes >  Editar Lançamento'));

  const [idLancamentoSelecionado, setidLancamentoSelecionado] = useState(null);

  useLayoutEffect(() => {
    if (history.location.state?.idLancamentoSelecionado) {
      setidLancamentoSelecionado(history.location.state?.idLancamentoSelecionado);
    } else {
      history.goBack();
    }
  }, [history]);

  return (
    <>
      <RecebimentoClienteProvider>
        <LancamentoRecebimento isEditMode idLancamentoCliente={idLancamentoSelecionado} />
      </RecebimentoClienteProvider>
    </>
  );
}

export default withTokenInterceptor(RecebimentoClienteEditarPage);
