import SelectorModel from '../../../models/SelectorModel';

export function buildUsuarios(usuarios) {
  return usuarios.map(usuario => ({
    id: usuario.idUsuario,
    value: usuario.nomeUsuario,
    ...usuario,
  }));
}

export function buildCentrosCusto(centrosCusto) {
  return centrosCusto.map(data => new SelectorModel(data.idCentroCusto, data.nomeCentroCusto));
}

export function buildTipoUsuario(tiposUsuario) {
  return tiposUsuario.map(data => new SelectorModel(data.idTipoUsuario, data.nomeTipoUsuario, data.idTipoComissaoUsuario));
}

export function buildBancos(bancos) {
  return bancos.map(data => new SelectorModel(data.idBanco, `${data.idBanco} - ${data.nomeBanco}`));
}

export function buildTiposConta(tiposConta) {
  return tiposConta.map(data => new SelectorModel(data.idTipoConta, data.descricaoTipoConta));
}

export function buildPerfies(perfies) {
  return perfies.map(data => new SelectorModel(data.idPerfilUsuario, data.nomePerfilUsuario));
}

export function buildFiliais(filiais) {
  return filiais.map(data => new SelectorModel(data.idFilial, data.nomeFilial));
}

export function buildTipoClassificacaoClientes(tipoClassificacaoClientes) {
  return tipoClassificacaoClientes.map(data => new SelectorModel(data.idTipoClassificacaoCliente, data.descricaoClassificacao));
}

export function buildRamosAtividade(ramosAtividades) {
  return ramosAtividades.map(data => new SelectorModel(data.idRamoAtividade, data.nomeRamoAtividade));
}

export function buildFasesUsuario(fasesUsuario) {
  return fasesUsuario.map(data => new SelectorModel(data.idFase, data.nome));
}

export function buildPerfisUsuarioCliente(perfisUsuarioCliente) {
  return perfisUsuarioCliente.map(data => new SelectorModel(data.idPerfilCliente, data.nomePerfilCliente));
}

export function buildTipoCalculoHonorarioParametro(tipoCalculoHonorarios) {
  return tipoCalculoHonorarios.map(data => new SelectorModel(
    data.idTipoCalculoHonorario, data.descricaoTipoCalculoHonorario,
  ));
}

export function buildTipoCalculoComissaoParametro(tipoCalculoComissao) {
  return tipoCalculoComissao.map(data => new SelectorModel(
    data.idTipoCalculoComissao, data.descricaoTipoCalculoComissao,
  ));
}

export function buildTiposHonorariosContratuaisParametro(tiposHonorariosContratuais) {
  return tiposHonorariosContratuais.map(data => new SelectorModel(
    data.idTipoHonorarioContratual, data.descricao,
  ));
}

export function buildTipoDesconto(tipoDesconto) {
  return tipoDesconto.map(data => new SelectorModel(
    data.idTipoDesconto, data.descricaoTipoDesconto,
  ));
}

export function buildFases(fases) {
  return fases.map(data => new SelectorModel(
    data.idFase, data.nome, data.exigirMotivoBaixa, data.pagarComissao,
  ));
}

export function buildRequisicoes(requisicoes) {
  return requisicoes.map(data => new SelectorModel(
    data.idRequisicao, data.nome,
  ));
}

export function buildRegraCalculoRepasseParametro(regraCalculoRepasse) {
  return regraCalculoRepasse.map(data => new SelectorModel(
    data.idTipoRegraCalculoRepasse, data.descricaoTipoRegraCalculoRepasse,
  ));
}

export function buildTipoAcordoSeguro(tipoAcordoSeguro) {
  return tipoAcordoSeguro.map(data => new SelectorModel(
    data.idTipoAcordoSeguro, data.descricaoTipoAcordoSeguro,
  ));
}

export function buildIntervaloPagamento(intervaloPagamento) {
  return intervaloPagamento.map(data => new SelectorModel(
    data.idIntervaloPagamento, data.descricao,
  ));
}

export function buildModeloNotificacaoEmail(modeloNotificacaoEmail) {
  return modeloNotificacaoEmail.map(data => new SelectorModel(
    data.idTipoEmailPadrao, data.nomeTipo,
  ));
}

export function buildTipoComissaoBordero(tipoComissaoBordero) {
  return tipoComissaoBordero.map(data => new SelectorModel(
    data.idTipoComissaoBordero, data.descricaoTipoComissaoBordero,
  ));
}

export function buildBorderoTipoStatus(borderoTipoStatus) {
  return borderoTipoStatus.map(data => new SelectorModel(
    data.statusBordero, data.descricaoStatusBordero,
  ));
}

export function buildTiposLogProcessamentoBordero(tiposLogProcessamentoBordero) {
  return tiposLogProcessamentoBordero.map(data => new SelectorModel(
    data.tipoBorderoLog, data.descricaoBorderoLog,
  ));
}

export function buildTipoPrazoExpiracaoPix(tipoPrazoExpiracaoPix) {
  return tipoPrazoExpiracaoPix.map(data => new SelectorModel(
    data.idTipoExpiracaoPix, data.descricaoTipoExpiracaoPix,
  ));
}

export function buildFasesPastas(fasesPastas) {
  return fasesPastas.map(data => new SelectorModel(
    data.idFasePasta, data.nome,
  ));
}

export function buildTiposAgendamentos(tiposAgendamentos) {
  return tiposAgendamentos.map(data => new SelectorModel(
    data.idTipoFU, data.descricaoTipoFU,
  ));
}

export function buildStatusBorderoPesquisa(statusBorderoImportar) {
  return statusBorderoImportar.map(data => new SelectorModel(
    data.idStatusBorderoPesquisa, data.descricaoStatusPesquisa,
  ));
}

export function buildTipoTextoPadrao(tipoTextoPadrao) {
  return tipoTextoPadrao.map(data => new SelectorModel(
    data.idTipoTextoPadrao, data.descricaoTipoTextoPadrao,
  ));
}

export function buildMotivosBaixa(motivosBaixa) {
  return motivosBaixa.map(data => new SelectorModel(
    data.idMotivoBaixa, data.descricao,
  ));
}

export function buildTipoTitulo(tipo) {
  return tipo.map(data => new SelectorModel(
    data.idTipoTitulo, data.descricaoTipoTitulo,
  ));
  // Falta Adicionar no saga
}

export function buildDefaultSelector(data) {
  return data.map(item => new SelectorModel(item.id, item.nome));
}

export function buildTiposPrestacao(tiposPrestacao) {
  return tiposPrestacao.map(data => new SelectorModel(
    data.idTipoPrestaçãoContaCliente, data.descricao,
  ));
}

export function buildTiposLiquidacaoPrestacao(tiposPrestacao) {
  return tiposPrestacao.map(data => new SelectorModel(
    data.idTipoLiquidacaoPrestacao, data.descricao,
  ));
}

export function buildGeradoresRelatorios(geradoresRelatorios) {
  return geradoresRelatorios.map(data => new SelectorModel(
    data.idGeradorRelatorio, data.descricao, data.serPadrao,
  ));
}

export function buildTiposChavesPix(tiposChavesPix) {
  return tiposChavesPix.map(data => new SelectorModel(
    data.idTipoChavePix, data.descricao,
  ));
}

export function buildTiposLancamentos(tiposLancamentos) {
  return tiposLancamentos.map(data => new SelectorModel(
    data.idTipoLancamento, data.descricao,
  ));
}

export function buildResponsaveisCC(responsaveis) {
  return responsaveis.map(data => new SelectorModel(
    data.idUsuario, data.nomeUsuario,
  ));
}

export function buildTiposNotasFiscais(tiposNotasFiscais) {
  return tiposNotasFiscais.map(data => new SelectorModel(
    data.idTipoNotaFiscal, data.descricao,
  ));
}

export function buildPrefeiturasNotaFiscal(prefeiturasNotaFiscal) {
  return prefeiturasNotaFiscal.map(data => new SelectorModel(
    data.idPrefeituraNotaFiscal, data.descricao,
  ));
}

export function buildTipoFormaPagamento(tipoFormaPagamento) {
  return tipoFormaPagamento.map(data => new SelectorModel(
    data.idTipoFormaPagto, data.descricao,
  ));
}

export function buildTipoIntervaloPagamento(intervalosPagamento) {
  return intervalosPagamento.map(data => new SelectorModel(
    data.idIntervaloPagamento, data.descricao,
  ));
}

export function buildTiposFormasPagamentoRecebimento(tiposFormasPagamentoRecebimento) {
  return tiposFormasPagamentoRecebimento.map(data => new SelectorModel(
    data.idTipoFormaPagto, data.descricao,
  ));
}

export function buildContasBancarias(contasBancarias) {
  return contasBancarias.map(data => new SelectorModel(
    data.idContaBancaria, data.contaBancaria,
  ));
}

export function buildRegraComissionamento(tipoComissao) {
  return tipoComissao.map(data => new SelectorModel(
    data.idTipoRegraComissaoComercial, data.descricao,
  ));
}


export function buildUsuariosPermitemSimulacao(usuarios) {
  return usuarios.map(data => new SelectorModel(
    data.idUsuario, data.nomeUsuario,
  ));
}

export function buildMotivosQuebraAcordo(motivosQuebraAcordoList) {
  return motivosQuebraAcordoList.map(data => new SelectorModel(
    data.idMotivoQuebraAcordo, data.descricao,
  ));
}

export function buildNegociadoresExternosAtivosFase(negociadores) {
  return negociadores.map(data => new SelectorModel(
    data.id, data.nome,
  ));
}

export function buildMotivosExclusaoTitulo(motivosExclusao) {
  return motivosExclusao.map(data => new SelectorModel(
    data.idMotivoExclusaoTitulo, data.descricao,
  ));
}

export function buildNegociadoresHabilitadosProcesso(negociadores) {
  return negociadores.map(data => new SelectorModel(data.id, data.nome));
}

export function buildTiposTitulosAgendamentos(tiposTitulosAgendamentos) {
  return tiposTitulosAgendamentos.map(data => new SelectorModel(
    data.idTipoTituloAgendamento, data.descricao,
  ));
}
