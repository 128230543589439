import { toBrazilianDate, getAttributeExistingObject } from '../../helpers';
import FieldModel from '../../models/FieldModel';
import {
  RESET_FORM_FIELDS_ACTION, SET_COMMON_ACTION, SET_DATE_FIELD_ACTION, SET_SIMPLE_RECEBIMENTO_ACTION, SET_SIMPLE_VALUE_INFO_CARDS_ACTION, SET_VALUE_IMPORTAR_ACTIONS,

  SET_REQUEST_FIELDS_FOCUS, SET_REQUEST_FIELDS_VALUE, SAVE_WITH_ERROR, RESET_FORM_NOVO, FORM_EDITAR,
} from './RecebimentoClientes.constants';


export function buildValoresCards(value) {
  return {
    valorTotalRecebimentosDia: value?.valorTotalRecebimentosDia || 0,
    lancamentosNaoLiberadosDia: value?.lancamentosNaoLiberadosDia || 0,
  };
}

function buildFormFields(fields) {
  return {
    idLancamentoCliente: new FieldModel({
      name: 'idLancamentoCliente',
      value: fields?.idLancamentoCliente || null,
    }),
    idContaBancaria: new FieldModel({
      name: 'idContaBancaria',
      value: getAttributeExistingObject(fields, 'idContaBancaria'),
      error: false,
      errorMessage: '',
    }),
    idTipoFormaPagto: new FieldModel({
      name: 'idTipoFormaPagto',
      value: getAttributeExistingObject(fields, 'idTipoFormaPagto'),
      error: false,
      errorMessage: '',
    }),
    codigoCliente: new FieldModel({
      name: 'codigoCliente',
      value: getAttributeExistingObject(fields, 'codigoCliente'),
      error: false,
      errorMessage: '',
    }),
    valorRecebimento: new FieldModel({
      name: 'valorRecebimento',
      value: getAttributeExistingObject(fields, 'valorRecebimento'),
      error: false,
      errorMessage: '',
    }),
    observacoes: new FieldModel({
      name: 'observacoes',
      value: getAttributeExistingObject(fields, 'observacoes'),
      error: false,
      errorMessage: '',
    }),
  };
}

function buildRequestFields() {
  return {
    data: new FieldModel({
      name: 'data',
      value: toBrazilianDate(new Date()),
    }),
    lancamentosNaoLiberados: new FieldModel({
      name: 'lancamentosNaoLiberados',
      value: true,
      // disabled: false,
    }),
  };
}

function buildRequestFieldsImportar(arquivo) {
  return {
    arquivoLancamentosRetorno: arquivo?.arquivoLancamentosRetorno || '',
  };
}


function buildInitialStates(fields) {
  return {
    requestFields: buildRequestFields(),
    formFields: buildFormFields(fields),
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    columnOrdenacao: { element: 'dataRecebimento', order: 'ASC' },
    arrowDirection: true,
    tableView: [],
    openButtonImportar: false,
    formFieldsImportar: buildRequestFieldsImportar(),
    openDialogErro: false,
    openDialogSucesso: false,
    arquivosProcessados: {},
    valoresCards: buildValoresCards(null),
    openDialogExcluir: false,
    idLancamentoCliente: null,
    openDialogCancel: false,
    closeEdit: false,
    common: {
      loadingPage: false,
    },

  };
}

function onChangeDateFieldHandler(states, actions) {
  const { name, value } = actions;
  const field = states.requestFields[name].getNewModel(value);

  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [name]: field,
    },
  };
}


function setSimpleRecebimentoHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    [name]: value,
  };
}

function setResetFormFields(states) {
  return {
    ...states,
    formFieldsImportar: {
      ...states.formFieldsImportar,
      arquivoRetorno: '',
    },
  };
}

function setValueImportar(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    formFieldsImportar: {
      ...states.formFieldsImportar,
      [name]: value,
    },
  };
}


function setCommonHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    common: {
      ...states.common,
      [name]: value,
    },
  };
}

function setSimpleValueInfoCardsHandler(states, actions) {
  const { name, value } = actions;

  return {
    ...states,
    valoresCards: {
      ...states.valoresCards,
      [name]: value,
    },
  };
}

function changeRequestFieldsValueHandler(states, actions) {
  const { name, value } = actions;
  const fieldModel = states.formFields[actions.name].copy();
  return { ...states, formFields: { ...states.formFields, [name]: fieldModel.onChange(value) } };
}

function changeRequestFieldsFocusHandler(states, actions) {
  const fieldModel = states.formFields[actions.name].copy();
  return {
    ...states,
    formFields:
      {
        ...states.formFields,
        [actions.name]: fieldModel.onFocus(),
      },
  };
}

function saveWithErrorHandler(states, { formFields }) {
  return {
    ...states,
    formFields: {
      ...states.formFields,
      idContaBancaria: new FieldModel({
        name: 'idContaBancaria',
        value: formFields?.idContaBancaria?.value || '',
        error: formFields?.idContaBancaria?.error,
        errorMessage: formFields?.idContaBancaria?.errorMessage,
      }),
      idTipoFormaPagto: new FieldModel({
        name: 'idTipoFormaPagto',
        value: formFields?.idTipoFormaPagto?.value || '',
        error: formFields?.idTipoFormaPagto?.error,
        errorMessage: formFields.idTipoFormaPagto.errorMessage,
      }),
      codigoCliente: new FieldModel({
        name: 'codigoCliente',
        value: formFields?.codigoCliente?.value || '',
        error: formFields?.codigoCliente?.error,
        errorMessage: formFields.codigoCliente.errorMessage,
      }),
      valorRecebimento: new FieldModel({
        name: 'valorRecebimento',
        value: formFields?.valorRecebimento?.value || '',
        error: formFields?.valorRecebimento?.error,
        errorMessage: formFields.valorRecebimento.errorMessage,
      }),
      observacoes: new FieldModel({
        name: 'observacoes',
        value: formFields?.observacoes?.value || '',
        error: false,
        errorMessage: '',
      }),
    },
  };
}

function resetFormFieldsNovoHandle(states, actions) {
  return {
    ...states,
    formFields: {
      ...states.formFields,
      idContaBancaria: new FieldModel({
        name: 'idContaBancaria',
        value: getAttributeExistingObject(actions, 'idContaBancaria'),
        error: false,
        errorMessage: '',
      }),
      idTipoFormaPagto: new FieldModel({
        name: 'idTipoFormaPagto',
        value: getAttributeExistingObject(actions, 'idTipoFormaPagto'),
        error: false,
        errorMessage: '',
      }),
      codigoCliente: new FieldModel({
        name: 'codigoCliente',
        value: getAttributeExistingObject(actions, 'codigoCliente'),
        error: false,
        errorMessage: '',
      }),
      valorRecebimento: new FieldModel({
        name: 'valorRecebimento',
        value: getAttributeExistingObject(actions, 'valorRecebimento'),
        error: false,
        errorMessage: '',
      }),
      observacoes: new FieldModel({
        name: 'observacoes',
        value: getAttributeExistingObject(actions, 'observacoes'),
        error: false,
        errorMessage: '',
      }),
    },
  };
}

function formEditarHandler(states, actions) {
  return {
    ...states,
    formFields: buildFormFields(actions.form),
  };
}


export const onChangeDateFieldAction = (name, value) => ({
  type: SET_DATE_FIELD_ACTION,
  name,
  value,
});
export const setSimpleRecebimentoAction = (name, value) => ({
  type: SET_SIMPLE_RECEBIMENTO_ACTION,
  name,
  value,
});

export const resetFormFieldsAction = () => ({
  type: RESET_FORM_FIELDS_ACTION,
});

export const setValueImportarAction = (name, value) => ({
  type: SET_VALUE_IMPORTAR_ACTIONS,
  name,
  value,
});

export const setCommonAction = (name, value) => ({
  type: SET_COMMON_ACTION,
  name,
  value,
});

export const setSimpleValueInfoCardsAction = (name, value) => ({
  type: SET_SIMPLE_VALUE_INFO_CARDS_ACTION,
  name,
  value,
});

export const setRequestFieldsValueAction = (name, value) => ({
  type: SET_REQUEST_FIELDS_VALUE,
  name,
  value,
});

export const setRequestFieldsFocusAction = name => ({
  type: SET_REQUEST_FIELDS_FOCUS,
  name,
});

export const saveWithErrorAction = formFields => ({
  type: SAVE_WITH_ERROR,
  formFields,
});

export const resetFormFieldsNovoAction = () => ({
  type: RESET_FORM_NOVO,
});

export const formEditarAction = form => ({
  type: FORM_EDITAR,
  form,
});


export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    case SET_DATE_FIELD_ACTION:
      return onChangeDateFieldHandler(states, actions);
    case SET_SIMPLE_RECEBIMENTO_ACTION:
      return setSimpleRecebimentoHandler(states, actions);
    case RESET_FORM_FIELDS_ACTION:
      return setResetFormFields(states, actions);
    case SET_VALUE_IMPORTAR_ACTIONS:
      return setValueImportar(states, actions);
    case SET_COMMON_ACTION:
      return setCommonHandler(states, actions);
    case SET_SIMPLE_VALUE_INFO_CARDS_ACTION:
      return setSimpleValueInfoCardsHandler(states, actions);
    case SET_REQUEST_FIELDS_VALUE:
      return changeRequestFieldsValueHandler(states, actions);
    case SET_REQUEST_FIELDS_FOCUS:
      return changeRequestFieldsFocusHandler(states, actions);
    case SAVE_WITH_ERROR:
      return saveWithErrorHandler(states, actions);
    case RESET_FORM_NOVO:
      return resetFormFieldsNovoHandle(states, actions);
    case FORM_EDITAR:
      return formEditarHandler(states, actions);
    default:
      return states;
  }
};
