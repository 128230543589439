import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IconButtonTooltip from '../../../components/UI/IconButtonTooltip/IconButtonTooltip';

import TableHeader from '../../../models/TableHeader';

import LoadingUI from '../../../components/UI/Loading/LoadingUI';
import AlertDialog from '../../../components/UI/Dialogs/AlertDialog/AlertDialog';
import SnackbarUI from '../../../components/UI/Snackbar/Snackbar';
import SearchLayout from '../../../layouts/SimpleSearchLayout/SimpleSearchLayout';

import {
  setAlertDialogValuesAction,
  setCacheRequestFieldsAction,
  setOptionDialogValuesAction,
  setPageAction,
  setRequestFieldsValueAction,
  setRowsPerPageAndPageAction,
  setScrollPositionAction,
  setSnackbarValuesAction,
} from '../../../store/Filial/Pesquisar/pesquisarFilial.store';


import { deleteFilialAction, findFiliaisAction } from '../../../store/Filial/Pesquisar/pesquisarFilial.saga';

import {
  assignStatus, getNewPage, getScrollPosition, moveScroll,
} from '../../../helpers/utils/utils';
import { initializePageAction } from '../../../store/theme.actions';


import constants from './PesquisarFilial.constants';
import { FIRST_PAGE, SEARCH_TABLE_TITLE, SNACKBAR_DURATION } from '../../../helpers/constants/global.constants';
import { BoldContentDialog } from '../../../components/UI/Dialogs/Content/Bold/BoldContentDialog';
import OptionDialogNew from '../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';


function buildFilialTable(filiais, onEdit, onDetails, onRemove, hasNotPermissaoFilial) {
  return filiais.map(filial => ({
    id: filial.idFilial,
    nome: filial.nomeFilial,
    values: {
      nomeFilial: filial.nomeFilial,
      statusFilial: assignStatus(filial.statusFilial),
      acoes: (
        <>
          <IconButtonTooltip
            style={{ padding: '2px', marginRight: '18px', fontSize: '16px' }}
            disabled={hasNotPermissaoFilial}
            type="searchPlus"
            hint="Consultar"
            onClick={() => onDetails(filial.idFilial)}
          />

          <IconButtonTooltip
            style={{ padding: '2px', marginRight: '18px', fontSize: '16px' }}
            disabled={hasNotPermissaoFilial}
            type="edit"
            hint="Editar"
            onClick={() => onEdit(filial.idFilial)}
          />

          <IconButtonTooltip
            style={{ padding: '2px', fontSize: '16px' }}
            disabled={hasNotPermissaoFilial}
            type="trash"
            hint="Excluir"
            onClick={() => onRemove(filial.idFilial, filial.nomeFilial)}
          />
        </>
      ),
    },
  }));
}

function buildTableColumns() {
  return [
    new TableHeader({
      id: 'nomeFilial', label: 'Nome', maxWidth: 640,
    }),
    new TableHeader({
      id: 'statusFilial', label: 'Status', alignCell: 'center',
    }),
    new TableHeader({
      id: 'acoes', label: 'Ações', alignCell: 'center', maxWidth: 121,
    }),
  ];
}

function PesquisaFilialPage({ history }) {
  const dispatch = useDispatch();

  const [removedFilial, setRemovedFilial] = useState({ id: null, nome: '' });
  const [tableRows, setTableRows] = useState([]);
  const [tableColumns] = useState(buildTableColumns());

  const nome = useSelector(states => states.pesquisaFilial.requestFields.nome);
  const cacheRequestFields = useSelector(states => states.pesquisaFilial.cacheRequestFields);
  const settingsTable = useSelector(states => states.pesquisaFilial.settingsTable);
  const loading = useSelector(states => states.pesquisaFilial.loading);
  const snackbar = useSelector(states => states.pesquisaFilial.snackbar);
  const alertDialog = useSelector(states => states.pesquisaFilial.alertDialog);
  const optionDialog = useSelector(states => states.pesquisaFilial.optionDialog);
  const scrollPosition = useSelector(states => states.pesquisaFilial.scrollPosition);

  const previewRowPerPage = useRef();
  const tableContentLength = useRef();
  tableContentLength.current = settingsTable.content.length;

  const hasNotPermissaoFilial = useSelector((state) => {
    const permissoes = state.authentication.userInformation?.permissoes || [];
    if (!permissoes) {
      return true;
    }
    return !permissoes.includes('ROLE_101');
  });

  const openRemoveDialogHandler = useCallback((idFilial, nomeFilial) => {
    setRemovedFilial({ id: idFilial, nome: nomeFilial });
    dispatch(setOptionDialogValuesAction(true));
  }, [dispatch]);

  const setScrollPostion = useCallback(() => {
    const position = getScrollPosition();
    dispatch(setScrollPositionAction(position.x, position.y));
  }, [dispatch]);

  const editHandler = useCallback((idFilial) => {
    setScrollPostion();
    history.push(`/filial/${idFilial}/editar`);
  }, [history, setScrollPostion]);

  const detailsHandler = useCallback((idFilial) => {
    setScrollPostion();
    history.push(`/filial/${idFilial}/detalhar`);
  }, [history, setScrollPostion]);

  useEffect(() => {
    dispatch(initializePageAction(`${constants.PAGE_TITLE}`));
    moveScroll(scrollPosition.x, scrollPosition.y);
  }, [dispatch, scrollPosition]);

  // Consultar dados para preencher a tabela
  useEffect(() => {
    const isChangePageWithourTableContent = () => previewRowPerPage.current
      && previewRowPerPage.current !== settingsTable.rowsPerPage
      && tableContentLength.current === 0;

    if (isChangePageWithourTableContent()) {
      previewRowPerPage.current = settingsTable.rowsPerPage;
      return;
    }

    if (cacheRequestFields) {
      dispatch(findFiliaisAction(cacheRequestFields.nome, settingsTable.page, settingsTable.rowsPerPage));
    }
    previewRowPerPage.current = settingsTable.rowsPerPage;
  }, [dispatch, cacheRequestFields, settingsTable.page, settingsTable.rowsPerPage]);

  // Construção da tabela
  useEffect(() => {
    setTableRows(buildFilialTable(settingsTable.content, editHandler, detailsHandler, openRemoveDialogHandler, hasNotPermissaoFilial));
  }, [settingsTable, setTableRows, editHandler, detailsHandler, openRemoveDialogHandler, hasNotPermissaoFilial]);

  const searchFiliaisHandler = useCallback((event) => {
    event.preventDefault();
    dispatch(setCacheRequestFieldsAction());
    dispatch(setPageAction(FIRST_PAGE));
  }, [dispatch]);

  const removeFilialHandler = useCallback(() => {
    dispatch(deleteFilialAction(removedFilial.id));
    dispatch(setOptionDialogValuesAction(false));
  }, [dispatch, removedFilial.id]);

  const goToRegisterFilialHandler = useCallback(() => {
    dispatch(setSnackbarValuesAction(false));
    history.push('/filial/cadastrar');
  }, [dispatch, history]);

  const changePageHandler = useCallback((event, newPage) => {
    dispatch(setPageAction(newPage));
  }, [dispatch]);

  const changeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setRequestFieldsValueAction(name, value));
  }, [dispatch]);

  const changeRowsPerPageHandler = useCallback((event) => {
    const newRowsPerPage = event.target.value;
    const newPage = getNewPage(settingsTable.rowsPerPage, settingsTable.page, newRowsPerPage);
    dispatch(setRowsPerPageAndPageAction(newRowsPerPage, newPage));
  }, [dispatch, settingsTable.page, settingsTable.rowsPerPage]);

  const closeSnackbarHandler = () => {
    dispatch(setSnackbarValuesAction(false));
  };

  const closeRemoveFilialAlertDialogHandler = () => {
    dispatch(setAlertDialogValuesAction(false));
  };

  const closeOptionDialogHandler = () => {
    dispatch(setOptionDialogValuesAction(false));
  };

  return (
    <>
      <LoadingUI show={loading} />
      <SnackbarUI
        open={snackbar.open}
        variant={snackbar.variant}
        message={snackbar.message}
        autoHideDuration={SNACKBAR_DURATION}
        onClose={closeSnackbarHandler}
      />
      <AlertDialog
        open={alertDialog.open}
        variant={alertDialog.variant}
        onClickConfirm={closeRemoveFilialAlertDialogHandler}
      >
        <BoldContentDialog fullyMessage={alertDialog.message} />
      </AlertDialog>
      <OptionDialogNew
        open={optionDialog.open}
        onClickCancel={closeOptionDialogHandler}
        onClickConfirm={removeFilialHandler}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>
            {constants.getQuestionMessageDeleteDialog(removedFilial.nome)}
          </p>
        </div>
      </OptionDialogNew>
      <SearchLayout
        allowedNovo={hasNotPermissaoFilial}
        searchFieldLabel={constants.SEARCH_LABEL}
        tituloTabela={SEARCH_TABLE_TITLE}
        fieldPesquisa={nome}
        tablePageable={settingsTable}
        tableColumns={tableColumns}
        tableRows={tableRows}
        onChangePesquisa={changeFieldsHandler}
        onClickPesquisa={searchFiliaisHandler}
        onClickButtonNovo={goToRegisterFilialHandler}
        onChangePage={changePageHandler}
        onChangeRowsPerPage={changeRowsPerPageHandler}
      />
    </>
  );
}

export default withRouter(PesquisaFilialPage);
