import { getUriParams } from '../../../helpers';
import {
  CORE, gateway, gatewayShortJson, RELATORIOS,
} from '../../api';

export default {
  pesquisarContaCorrenteTodosCliente(
    dataPrestacao, idFilial, idCliente, nossoNumeroBoleto, idUsuarioResponsavel, tipoLancamento, exibirApenasDesbloqueados,
  ) {
    const body = {
      dataPrestacao, idFilial, idCliente, nossoNumeroBoleto, idUsuarioResponsavel, tipoLancamento, exibirApenasDesbloqueados,
    };
    return gateway.post(`${CORE}/conta-corrente-cliente/todos-cliente`, body);
  },
  pesquisarContaCorrenteTodosClienteV2({
    dataPrestacao,
    idFilial,
    idCliente,
    nossoNumeroBoleto,
    idUsuarioResponsavel,
    tipoLancamento,
    exibirApenasDesbloqueados,
    nomeCliente,
    numeroRPSNotaFiscal,
  }) {
    const body = {
      dataPrestacao,
      idFilial,
      idCliente,
      nossoNumeroBoleto,
      idUsuarioResponsavel,
      tipoLancamento,
      exibirApenasDesbloqueados,
      nomeCliente,
      numeroRPSNotaFiscal,
    };
    return gateway.post(`${CORE}/conta-corrente-cliente/todos-cliente`, body);
  },

  getDetalhesCliente(idCliente) {
    return gatewayShortJson.get(`${CORE}/conta-corrente-cliente/${idCliente}/cliente/detalhes`);
  },

  getDetalhesFinanceiroCliente(idCliente) {
    return gatewayShortJson.get(`${CORE}/conta-corrente-cliente/${idCliente}/cliente/resumo`);
  },

  downloadRelatorioClientes(
    dataPrestacao,
    idFilial,
    idCliente,
    nossoNumeroBoleto,
    idUsuarioResponsavel,
    tipoLancamento,
    exibirApenasDesbloqueados,
    nomeCliente,
    idsContasCorrenteClientes,
    columnOrdenacao,
  ) {
    const body = {
      dataPrestacao,
      idFilial,
      idCliente,
      nossoNumeroBoleto,
      idUsuarioResponsavel,
      tipoLancamento,
      exibirApenasDesbloqueados,
      nomeCliente,
      statusPrestacaoContasList: idsContasCorrenteClientes,
    };
    return gateway.post(
      `${RELATORIOS}/conta-corrente-cliente/relatorio/clientes?sort=${columnOrdenacao.element},${columnOrdenacao.order}`,
      body,
      {
        responseType: 'blob',
      },
    );
  },

  prestarContasCliente(payload) {
    return gateway.post(`${CORE}/conta-corrente-cliente/prestar-conta`, payload);
  },

  prestarTodos(payload) {
    return gateway.post(`${CORE}/conta-corrente-cliente/prestar-todos`, payload);
  },

  buscarRelacaoPrestacao(payload, page, size, order) {
    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    const queryParam = getUriParams({ page, size });

    return gateway.post(`${CORE}/conta-corrente-cliente/prestacao-relacao?${queryParam}${sortedQuery}`, payload);
  },

  downloadRelatorioPrestacao(idPrestacaoContaCliente) {
    return gateway.get(
      `${RELATORIOS}/conta-corrente-cliente/relatorio/cliente/prestacao?idPrestacaoContaCliente=${idPrestacaoContaCliente}`, {
        responseType: 'blob',
      },
    );
  },

  findSaldosPrestacao(idCliente, idPrestacao) {
    const body = {
      idCliente,
      idPrestacao,
    };
    return gateway.post(`${CORE}/conta-corrente-cliente/totais-prestacao`, body);
  },

  cancelarPrestacao(idCliente, idPrestacao) {
    const body = {
      idCliente,
      idPrestacao,
    };
    return gateway.post(`${CORE}/conta-corrente-cliente/cancelar`, body);
  },

  estornarPrestacao(idCliente, idPrestacao, motivoEstorno) {
    const body = {
      idCliente,
      idPrestacao,
      motivoEstorno,
    };
    return gateway.post(`${CORE}/conta-corrente-cliente/estornar`, body);
  },

  buscarMotivoEstornoPrestacao(idCliente, idPrestacao) {
    const body = {
      idCliente,
      idPrestacao,
    };
    return gateway.post(`${CORE}/conta-corrente-cliente/motivo-estorno`, body);
  },

  enviarEmailPrestacao(idPrestacaoContaCliente) {
    return gateway.get(`${RELATORIOS}/conta-corrente-cliente/relatorio/cliente/prestacao-email?idPrestacaoContaCliente=${idPrestacaoContaCliente}`);
  },

  downloadRepassesAutorizacaoRepasse(idAutorizacaoRepasse) {
    return gateway.get(
      `${RELATORIOS}/conta-corrente-cliente/relatorio/cliente/repasses/autorizacao?idAutorizacaoRepasse=${idAutorizacaoRepasse}`, {
        responseType: 'blob',
      },
    );
  },
};

export function findAllResponsaveis() {
  return gatewayShortJson.get(`${CORE}/conta-corrente-cliente/usuario-responsavel`);
}

export function findAllTiposDeLancamentos() {
  return gatewayShortJson.get(`${CORE}/conta-corrente-cliente/tipo-filtro-lancamento`);
}

export function findBuscarLancamento(idPrestacaoContaCliente, pageable) {
  const queryParam = getUriParams(pageable);
  return gateway.get(`${CORE}/conta-corrente-cliente/prestacao-relacao/lancamentos/${idPrestacaoContaCliente}?${queryParam}`);
}

export function findBuscarNotasFiscais(idCliente,
  idPrestacao, pageable) {
  const queryParam = getUriParams({
    idCliente,
    idPrestacao,
    ...pageable,
  });
  return gateway.get(`${CORE}/conta-corrente-cliente/prestacao-relacao/notas-fiscais?${queryParam}`);
}

export function cancelarNotaFiscal(idNotaFiscalCliente) {
  const queryParam = getUriParams({
    idNotaFiscalCliente,
  });
  return gateway.put(`${CORE}/conta-corrente-cliente/prestacao-relacao/notas-fiscais/cancelar?${queryParam} `);
}

export function inserirNotaFiscal(
  payload,
) {
  const body = {
    despesas: payload.despesas,
    honorarios: payload.honorarios,
    idPrestacaoContaCliente: payload.idPrestacaoContaCliente,
    informacaoComplementar: payload.informacaoComplementar,
    informacaoExtra: payload.informacaoExtra,
    tipoNovaNFCliente: payload.tipoNovaNFCliente,
  };
  return gateway.post(
    `${CORE}/conta-corrente-cliente/nota-fiscal`, body, {
      responseType: 'blob',
    },
  );
}

export function downloadProtocoloCancelamentoNF(idNotaFiscalCliente) {
  return gateway.get(
    `${RELATORIOS}/conta-corrente-cliente/relatorio/cliente/notaFiscal/protocolo-cancelamento/${idNotaFiscalCliente}`, {
      responseType: 'blob',
    },
  );
}

export function inserirNovoBoleto(
  payload,
) {
  const body = {
    valor: payload.valor,
    idPrestacaoContaCliente: payload.idPrestacaoContaCliente,
    dataVencimentoBoleto: payload.dataVencimentoBoleto,
  };
  return gateway.post(
    `${CORE}/conta-corrente-cliente/prestacao-relacao/novo-boleto`, body,
  );
}
