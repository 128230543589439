import React, { useCallback, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton, TableContainer } from '@material-ui/core';
import { MdPrint } from 'react-icons/md';
import {
  ButtonImprimir,
  ButtonLiberar,
  Container, ContainerResumo, FiltroPesquisa, TableHeader,
} from './repasseStyles';
import CardResumo from '../Accordions/RelacaoPrestacao/Cards/CardResumo';
import { getMoneyMask, getNewPage, useUtilStyles } from '../../../../../helpers';
import { TableDefault } from '../../../../../components';
import PeriodField from '../../../../../components/UI/Field/Period/PeriodField';
import { TableHeaderRepasse } from '../../../../../components/TabelaHeader/TableHeaderRepasse';
import { TableBodyRepasse } from '../../../../../components/TabelaBody/TableBodyRepasse';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import {
  definirValorRepassesSelecionadosAction, downloadRelatorioRepassesAction, liberarPrestacoesAction, pesquisarRepasseAction, pesquisarTotalRepasseAction,
} from '../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import {
  onChangeFormFieldsRepasseAction, onFocusFormFieldsRepasseAction, setSimpleValueRepasseListIdsAction, setSimpleValuesRepasseAction,
} from '../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import OptionDialogNew from '../../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';

function RepasseComponent({ permissoes }) {
  const utilStyle = useUtilStyles();
  const dispatch = useCCClienteDispatch();

  const [selectAll, setSelectAll] = useState(false);

  const page = useCCClienteSelector(states => states.repasse.repasse.page);
  const rowsPerPage = useCCClienteSelector(states => states.repasse.repasse.rowsPerPage);
  const tableView = useCCClienteSelector(states => ([...states.repasse.repasse.tableView]));
  const totalElements = useCCClienteSelector(states => (states.repasse.repasse.totalElements));
  const totalRepasse = useCCClienteSelector(states => (states.repasse.repasse.totalRepasse));
  const listIds = useCCClienteSelector(states => (states.repasse.repasse.listIds));
  const openDialogLiberar = useCCClienteSelector(states => (states.repasse.repasse.openDialogLiberar));
  const formFields = useCCClienteSelector(states => ({ ...states.repasse.repasse.formFields }));
  const totalRepasseSelecionada = useCCClienteSelector(states => (states.repasse.repasse.totalRepasseSelecionada));

  async function handleLiberarRepasses() {
    dispatch(liberarPrestacoesAction());
  }

  const changeSelectAll = useCallback((e) => {
    const { checked } = e.target;
    setSelectAll(checked);
    dispatch(setSimpleValuesRepasseAction('listIds', Object.keys(listIds).reduce((acc, key) => ({ ...acc, [key]: checked }), {})));
    dispatch(definirValorRepassesSelecionadosAction());
  }, [listIds, dispatch]);


  const changeSelectId = useCallback((e) => {
    const { name, checked } = e.target;
    dispatch(setSimpleValueRepasseListIdsAction(name, checked));
    dispatch(definirValorRepassesSelecionadosAction());
  }, [dispatch]);

  const onChangeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeFormFieldsRepasseAction(name, value));
  }, [dispatch]);

  const onFocusFieldsHandler = useCallback((event) => {
    const { name } = event.target;
    dispatch(onFocusFormFieldsRepasseAction(name));
  }, [dispatch]);

  const onClickPesquisar = useCallback(async () => {
    dispatch(setSimpleValuesRepasseAction('atualizarListaIds', true));
    dispatch(pesquisarRepasseAction());
    dispatch(pesquisarTotalRepasseAction());
  }, [dispatch]);

  const changePageHandler = useCallback((event, newPage) => {
    dispatch(setSimpleValuesRepasseAction('page', Number(newPage)));

    dispatch(setSimpleValuesRepasseAction('atualizarListaIds', false));
    dispatch(pesquisarRepasseAction());
  }, [dispatch]);

  const changeRowsPerPageHandler = useCallback((e) => {
    const size = e.target.value;
    const newPage = getNewPage(rowsPerPage, page, size);

    dispatch(setSimpleValuesRepasseAction('page', Number(newPage)));
    dispatch(setSimpleValuesRepasseAction('rowsPerPage', Number(size)));

    dispatch(setSimpleValuesRepasseAction('atualizarListaIds', false));
    dispatch(pesquisarRepasseAction());
  }, [dispatch, rowsPerPage, page]);

  const handlePrintRepasses = useCallback(() => {
    dispatch(downloadRelatorioRepassesAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setSimpleValuesRepasseAction('atualizarListaIds', true));
    dispatch(pesquisarRepasseAction());
    dispatch(pesquisarTotalRepasseAction());
  }, [dispatch]);

  return (
    <>
      <OptionDialogNew
        open={openDialogLiberar}
        confirmLabel="Sim"
        cancelLabel="Não"
        onClickCancel={() => { dispatch(setSimpleValuesRepasseAction('openDialogLiberar', false)); }}
        onClickConfirm={handleLiberarRepasses}
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>
            Tem certeza que deseja autorizar o Repasse
            <br />
            das Prestações selecionadas?
          </p>
        </div>
      </OptionDialogNew>
      <Container>
        <div className={utilStyle.padding22}>
          <div>
            <FiltroPesquisa>
              <div>
                <div>
                  <PeriodField
                    label="Intervalo das Prestações:"
                    onChange={onChangeFieldsHandler}
                    onFocus={onFocusFieldsHandler}
                    firstField={formFields.periodoInicial.copy()}
                    lastField={formFields.periodoFinal.copy()}
                  />
                  <div>
                    <ButtonImprimir
                      disabled={tableView?.length === 0}
                      onClick={handlePrintRepasses}
                    >
                      <MdPrint size={16} />
                      <span style={{ marginLeft: '10px' }}>Imprimir</span>
                    </ButtonImprimir>
                  </div>
                </div>
                <IconButton
                  type="button"
                  disabled={formFields.periodoInicial.value === '' && formFields.periodoFinal.value === ''}
                  onClick={onClickPesquisar}
                >
                  <SearchIcon />
                </IconButton>
              </div>
            </FiltroPesquisa>
            <ContainerResumo>
              <h3><b>Resumo</b></h3>
              <div>
                <CardResumo
                  title="Total dos Repasses"
                  isDetail={false}
                  bgColor="#A3A1A3"
                  value={totalRepasse}
                />
                <CardResumo
                  title="Total de Repasses Selecionados"
                  value={getMoneyMask(totalRepasseSelecionada)}
                  isDetail={false}
                  bgColor="#C54FD9"
                />
              </div>
              <div>
                <ButtonLiberar
                  disabled={!permissoes?.prestarContas || tableView?.length === 0 || Object.keys(listIds).filter((key => listIds[key] === true)).map(key => Number(key)).length === 0}
                  onClick={() => { dispatch(setSimpleValuesRepasseAction('openDialogLiberar', true)); }}
                >
                  Liberar
                </ButtonLiberar>
              </div>
            </ContainerResumo>
          </div>
          <div>
            <TableContainer>
              <TableHeader>
                Resultado da Pesquisa
              </TableHeader>
              <div>
                <TableDefault
                  columnsHead={(
                    <TableHeaderRepasse
                      tableView={tableView}
                      formFields={formFields}
                      checked={selectAll}
                      onChange={changeSelectAll}
                    />
                  )}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  totalElements={totalElements}
                  totalElementsOnPage={tableView?.length}
                  totalColumns={7}
                  onPageChange={changePageHandler}
                  onRowsPerPageChange={changeRowsPerPageHandler}
                  emptyRowsHeight={33}
                  rowsPerPageOptions={[10, 15, 20]}
                >
                  {tableView?.map((repasse, i) => (
                    <TableBodyRepasse
                      content={repasse}
                      listIds={listIds}
                      changeSelectId={changeSelectId}
                      key={i}
                    />
                  ))}
                </TableDefault>
              </div>
            </TableContainer>
          </div>
        </div>
      </Container>
    </>
  );
}

export default React.memo(RepasseComponent);
