import React, { useCallback, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { FaEnvelope, FaKey } from 'react-icons/fa';
import { DeleteForever } from '@material-ui/icons';
import InputField from '../../../../UI/Field/Input';
import Grid24 from '../../../../UI/Grid24';
import { useCrudClienteDispatch, useCrudClienteSelector } from '../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import { onChangeControls } from '../../../../../store/Cliente/V2/Crud/crudCliente.store';
import { onFocusAction } from '../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import TooltipUI from '../../../../UI/Tooltip/Tooltip';

import useUsuarioChaveStyles from './UsuarioChaveApi.styles';
import OptionDialogNew from '../../../../UI/Dialogs/OptionDialog/OptionDialogNew';
import { gerarChaveAutenticacaoClienteAction, enviarEmailChaveAutenticacaoClienteAction, deletarChaveAutenticacaoClienteAction } from '../../../../../store/Cliente/V2/Crud/crudCliente.saga';

const CONSTANTS_BUTTONS_HINTS = {
  enviarEmail: 'Reenviar Chave',
  novaChave: 'Nova Chave',
  excluir: 'Excluir',
};

const COLOR_BUTTONS = {
  black: '#000000',
  greyDisabled: '#A2A0A0',
};


function UsuarioChaveApi({
  isEditMode,
  isInsertMode,
}) {
  const chaveApiForm = useCrudClienteSelector(states => states.controls.authorizationKey);
  const dispatch = useCrudClienteDispatch();
  const idCliente = useCrudClienteSelector(states => states.controls.idCliente);

  const styles = useUsuarioChaveStyles();

  const [openDialogAlterarChave, setOpenDialogAlterarChave] = useState(false);
  const [dialogOrigin, setDialogOrigin] = useState('');


  const onChangeFieldHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeControls(name, value));
  }, [dispatch]);

  const onFocusFieldHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(onFocusAction(fieldName));
  }, [dispatch]);

  const handleConfirmAlterarChave = useCallback(() => {
    dispatch(gerarChaveAutenticacaoClienteAction(idCliente.value));
    setOpenDialogAlterarChave(false);
  }, [dispatch, idCliente]);

  const handleEnviarChave = useCallback(() => {
    dispatch(enviarEmailChaveAutenticacaoClienteAction(idCliente.value));
  }, [dispatch, idCliente]);

  const handleConfirmDeleteChave = useCallback(() => {
    dispatch(deletarChaveAutenticacaoClienteAction(idCliente.value));
    setOpenDialogAlterarChave(false);
  }, [dispatch, idCliente]);

  const returnButtonColor = () => {
    if (!chaveApiForm.value || !isEditMode) {
      return COLOR_BUTTONS.greyDisabled;
    }
    return COLOR_BUTTONS.black;
  };

  return (
    <>
      <OptionDialogNew
        open={openDialogAlterarChave}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={() => setOpenDialogAlterarChave(false)}
        onClickConfirm={dialogOrigin === 'novaChave' ? handleConfirmAlterarChave : handleConfirmDeleteChave}
      >
        <div className={styles.textDialogAlterarChave}>
          {
            chaveApiForm.value === null || chaveApiForm.value === '' ? (
              'Tem certeza que deseja realizar a operação?'
            ) : (
              <div>
                A chave anterior será cancelada.
                <br />
                Tem certeza que deseja realizar a operação?
              </div>
            )
          }
        </div>
      </OptionDialogNew>
      <Grid24 xs={24}>
        <Grid24 xs={24} sm={10} fullWidth alignItems="center">
          <InputField
            isForm
            fullWidth
            disabled
            type="password"
            name={chaveApiForm.name}
            value={chaveApiForm.value}
            error={chaveApiForm.error}
            maxLength={chaveApiForm.maxLength}
            errorMessage={chaveApiForm.errorMessage}
            onChange={onChangeFieldHandler}
            onFocus={onFocusFieldHandler}
            className={styles.inputTextKey}
          />
        </Grid24>
        <Grid24 xs={24} sm={2} fullWidth direction="row" alignItems="flex-start" justify="space-between" style={{ marginLeft: '16px' }}>
          <TooltipUI title={CONSTANTS_BUTTONS_HINTS.enviarEmail}>
            <IconButton
              size="small"
              className={styles.buttonBox}
              onClick={handleEnviarChave}
              disabled={!chaveApiForm.value || !isEditMode}
            >
              <FaEnvelope className={styles.buttonIcons} color={returnButtonColor()} />
            </IconButton>
          </TooltipUI>
          <TooltipUI title={CONSTANTS_BUTTONS_HINTS.novaChave}>
            <IconButton
              size="small"
              className={styles.buttonBox}
              onClick={() => {
                setDialogOrigin('novaChave');
                setOpenDialogAlterarChave(true);
              }}
              disabled={!isEditMode || isInsertMode}
            >
              <FaKey className={styles.buttonIcons} size={16} color={isEditMode ? COLOR_BUTTONS.black : COLOR_BUTTONS.greyDisabled} />
            </IconButton>
          </TooltipUI>
          <TooltipUI title={CONSTANTS_BUTTONS_HINTS.excluir}>
            <IconButton
              size="small"
              className={styles.buttonBox}
              onClick={() => {
                setDialogOrigin('excluir');
                setOpenDialogAlterarChave(true);
              }}
              disabled={!chaveApiForm.value || !isEditMode}
            >
              <DeleteForever fontSize="medium" className={styles.buttonIcons} style={{ color: returnButtonColor() }} />
            </IconButton>
          </TooltipUI>
        </Grid24>
      </Grid24>
    </>
  );
}


export default React.memo(UsuarioChaveApi);
