import React, { useCallback } from 'react';
import { getArrowOrdenacao } from '../../../../helpers/utils/utils';
import useUtilStyles from '../../../../helpers/styles/utils.styles';

function OrdenacaoColuna({
  isIconLabel = false,
  columnLabel,
  idColumn,
  arrowOrdenacao,
  columnOrdenacao,
  setArrowOrdenacaoFunc = () => { },
  setColumnOrdenacao = () => { },
  onClickColumn,
  setArrowStoreFunction,
}) {
  const utilStyles = useUtilStyles();

  const handleClickOrderColumn = useCallback(() => {
    if (setArrowStoreFunction) {
      setArrowStoreFunction(!arrowOrdenacao);
      setColumnOrdenacao({ order: arrowOrdenacao ? 'ASC' : 'DESC', element: idColumn });
      onClickColumn(!arrowOrdenacao, { order: arrowOrdenacao ? 'ASC' : 'DESC', element: idColumn });
      return;
    }
    setArrowOrdenacaoFunc(old => !old);

    setColumnOrdenacao({ order: arrowOrdenacao ? 'ASC' : 'DESC', element: idColumn });
    if (onClickColumn) {
      onClickColumn();
    }
  }, [setArrowOrdenacaoFunc, arrowOrdenacao, setColumnOrdenacao, idColumn, onClickColumn, setArrowStoreFunction]);

  return (
    <div
      tabIndex={0}
      onKeyDown={() => { }}
      role="button"
      className={utilStyles.div__header__ordenacao}
      onClick={() => handleClickOrderColumn()}
    >
      {isIconLabel ? (
        <i className={columnLabel} />
      ) : (
        <div>
          {columnLabel}
        </div>
      )}
      <div className={isIconLabel ? utilStyles.iconLabel__ordenacao : utilStyles.icon__ordenacao}>
        {getArrowOrdenacao(columnOrdenacao?.element, idColumn, arrowOrdenacao)}
      </div>
    </div>
  );
}

export default OrdenacaoColuna;
