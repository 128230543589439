import makeStyles from '@material-ui/styles/makeStyles';
import { brightRed } from '../../../helpers/constants/colors.constants';

export default makeStyles({
  textInfo: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '& p': {
      color: brightRed,
      fontSize: '13px',
      fontWeight: 'bold',
      lineHeight: '1.15',
    },
  },
});
