import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequiredLabel } from '../../../../../../helpers';
import { useCrudClienteSelector } from '../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import CheckboxField from '../../../../../UI/Field/Checkbox/CheckboxField';
import SelectorField from '../../../../../UI/Field/Selector';
import Grid24 from '../../../../../UI/Grid24';
import FormWrapperWithTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import useStyles from '../PrestacaoContasStyles';
import { findAllTiposPrestacaoAction, findGeradoresRelatoriosTipoPrestacaoAction } from '../../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import utilsStyles from '../../../../../../helpers/styles/utils.styles';
import { GERADOR_RELATORIO, TOLERADOR_DIAS, DIAS } from '../PrestacaoContas.constants';
import InputField from '../../../../../UI/Field/Input';


function ConfiguracoesGerais({
  isEditMode, onChangeChecked, onChange, onChangeCheckbox, onFocus,
}) {
  const style = useStyles();
  const utilStyle = utilsStyles();

  const dispatch = useDispatch();

  const tiposPrestacao = useSelector(states => states.selectors.tiposPrestacao.selector);
  const geradoresRelatorios = useSelector(states => states.selectors.geradoresRelatorios.selector);
  const prestarContaSeparadamentePorProcesso = useCrudClienteSelector(states => states.controls.prestarContaSeparadamentePorProcesso.copy());
  const enviarPrestacaoContaEmail = useCrudClienteSelector(states => states.controls.enviarPrestacaoContaEmail.copy());
  const idGeradorRelatorioPrestacao = useCrudClienteSelector(states => states.controls.idGeradorRelatorioPrestacao.copy());
  const idTipoPrestacaoContaCliente = useCrudClienteSelector(states => states.controls.idTipoPrestacaoContaCliente.copy());
  const bloquearPrestacaoSeValorBaixo = useCrudClienteSelector(states => states.controls.bloquearPrestacaoSeValorBaixo.copy());
  const bloquearPrestacaoSeDebito = useCrudClienteSelector(states => states.controls.bloquearPrestacaoSeDebito.copy());
  const prazoToleranciaBloqueioPrestacaoConta = useCrudClienteSelector(states => states.controls.prazoToleranciaBloqueioPrestacaoConta.copy());


  React.useEffect(() => {
    dispatch(findAllTiposPrestacaoAction());
    dispatch(findGeradoresRelatoriosTipoPrestacaoAction());
  }, [dispatch]);

  return (
    <FormWrapperWithTitle
      title="Configurações Gerais"
    >
      <Grid24 container isFormContainer spacing={1}>
        <Grid24 xs={24} sm={24}>
          <Grid24 xs={24} sm={3} className={style.grid__selectorField}>
            <SelectorField
              isForm
              disabled={!isEditMode}
              label={getRequiredLabel('Tipo da Prestação', true)}
              items={tiposPrestacao}
              name={idTipoPrestacaoContaCliente.name}
              value={idTipoPrestacaoContaCliente.value}
              onChange={onChange}
            />
          </Grid24>
        </Grid24>
      </Grid24>
      <Grid24 xs={24} sm={24} className={style.grid__checkConfiguracoes}>
        <Grid24 xs={24} sm={24}>
          <CheckboxField
            label="Prestar Contas separadamente para cada processo"
            disabled={!isEditMode}
            name={prestarContaSeparadamentePorProcesso.name}
            checked={prestarContaSeparadamentePorProcesso.value}
            onChange={onChangeChecked}
          />
        </Grid24>
        <Grid24 xs={24} sm={24} className={utilStyle.mt7}>
          <CheckboxField
            label="Enviar Relatório de Prestação de Contas por e-mail ao Cliente"
            disabled={!isEditMode}
            name={enviarPrestacaoContaEmail.name}
            checked={enviarPrestacaoContaEmail.value}
            onChange={onChangeChecked}
          />
          {(enviarPrestacaoContaEmail.value && (
            <Grid24 container isFormContainer>
              <Grid24 xs={24} sm={24}>
                <Grid24 xs={24} sm={7} className={utilStyle.mt12ml10}>
                  <SelectorField
                    isForm
                    disabled={!isEditMode}
                    label={getRequiredLabel(GERADOR_RELATORIO, true)}
                    items={geradoresRelatorios}
                    name={idGeradorRelatorioPrestacao.name}
                    value={idGeradorRelatorioPrestacao.value}
                    error={idGeradorRelatorioPrestacao.error}
                    errorMessage={idGeradorRelatorioPrestacao.errorMessage}
                    onChange={onChange}
                  />
                </Grid24>
              </Grid24>
            </Grid24>
          ))}
          <Grid24 xs={24} sm={24} className={utilStyle.mt7}>
            <CheckboxField
              label="Não Prestar Contas para Prestações com valores baixos"
              disabled={!isEditMode}
              name={bloquearPrestacaoSeValorBaixo.name}
              checked={bloquearPrestacaoSeValorBaixo.value}
              onChange={onChangeCheckbox}
            />

          </Grid24>
        </Grid24>
        <Grid24 xs={24} sm={24} className={utilStyle.mt7}>
          <CheckboxField
            label="Bloquear se estiver em débito usando prazo personalizado"
            disabled={!isEditMode}
            name={bloquearPrestacaoSeDebito.name}
            checked={bloquearPrestacaoSeDebito.value}
            onChange={onChangeCheckbox}
          />

        </Grid24>
        {(bloquearPrestacaoSeDebito.value && (
          <Grid24 container isFormContainer>
            <Grid24 xs={24} sm={24}>
              <span className={style.grid__toleradorDias}>
                {getRequiredLabel(TOLERADOR_DIAS, true)}
              </span>
              <Grid24 xs={24} sm={1}>
                <InputField
                  isForm
                  disabled={!isEditMode}
                  name={prazoToleranciaBloqueioPrestacaoConta.name}
                  value={prazoToleranciaBloqueioPrestacaoConta.value}
                  onChange={onChange}
                  error={prazoToleranciaBloqueioPrestacaoConta.error}
                  errorMessage={prazoToleranciaBloqueioPrestacaoConta.errorMessage}
                  onFocus={onFocus}
                  maxLength={3}
                />
              </Grid24>
              <span className={style.grid__Dias}>{DIAS}</span>
            </Grid24>
          </Grid24>
        ))}
      </Grid24>
    </FormWrapperWithTitle>
  );
}
export default React.memo(ConfiguracoesGerais);
