import React, {
  useEffect, useState, useRef, useCallback, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SearchIcon from '@material-ui/icons/Search';

import { toast } from 'react-toastify';
import { initializePageAction } from '../../../store/theme.actions';

import TabelaPesquisaProcessos from '../../../components/Processos/Pesquisa/Tabela/TabelaPesquisaProcessos';

import withTokenInterceptor from '../../../hoc/withTokenInterceptor/withTokenInterceptor';

import {
  ComplementarySearchButton,
  Container,
  SimpleSearchContainer,
  Table,
  TableTitle,
} from './PesquisarProcessosPage.styles';
import InputField from '../../../components/UI/Field/Input';
import SelectorField from '../../../components/UI/Field/Selector/SelectorField';
import {
  findEstadosAction, findFasesAction, findNegociadoresAction, findTiposAgendamentosAction, findUsuariosNegociadoresAction,
} from '../../../store/Global/LoadSelectors/loadSelectors.saga';

import { getNewPage, isLoading } from '../../../helpers/utils/utils';
import LoadingUI from '../../../components/UI/Loading/LoadingUI';
import { changeKeyHandler, KEY_NUMBER } from '../../../helpers/utils/changeEvents.utils';
import { pesquisaProcessoController as controller } from '../../../controller/pesquisarProcessos.controller';
import { PesquisaComplementarProcesso } from '../../../components/Processos/Pesquisa/PesquisaComplementarProcesso';
import { AcoesPesquisaProcesso } from '../../../components/Processos/Pesquisa/AcoesPesquisaProcesso/AcoesPesquisaProcesso';
import { SearchButton } from '../../../components/SearchButton/SeachButton';

function hasContentInSearch(response) {
  return !response.error && response?.content.length === 0;
}

function PesquisarProcessosPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  const advancedSearchRef = useRef();
  const simpleSearchFormRef = useRef();

  const [selectedIds, setSelectedIds] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [dropdownValues, setDropdownValues] = useState(controller.makeDefaultDropdownSimpleSearch());
  const [ordenacao, setOrdenacao] = useState(controller.makeOrdenacao());
  const [dadosPesquisa, setDadosPesquisa] = useState(controller.makeDefaultDadosPesquisa());
  const [conteudoTabela, setConteudoTabela] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultFilterNumeroProcesso] = useState(controller.getNumeroProcessoPesquisaSimples());

  const loadingDropdowns = useSelector(controller.getLoadingDropdown);
  const simpleSeachDropdown = useSelector(controller.getSimpleSearchDropdownItems);

  const changeSelectAll = useCallback((e) => {
    const { checked } = e.target;
    setSelectAll(checked);
    setSelectedIds(Object.keys(selectedIds).reduce((acc, key) => ({ ...acc, [key]: checked }), {}));
  }, [selectedIds]);

  const changeSelectId = useCallback((e) => {
    const { name, checked } = e.target;
    setSelectedIds({ ...selectedIds, [name]: checked });
  }, [selectedIds]);

  const changeDropdown = useCallback((e) => {
    const { name, value } = e.target;
    setDropdownValues(old => controller.updateDropdownValue(old, name, value));
  }, []);

  function resetSimpleSearch() {
    setDropdownValues(controller.makeDefaultDropdownSimpleSearch());
  }

  async function loadingWrapper(request) {
    setLoading(true);
    const response = await request();
    setDadosPesquisa(response);
    setLoading(false);
    return response;
  }

  async function openAdvancedSearch() {
    dispatch(findEstadosAction());
    dispatch(findFasesAction());
    dispatch(findNegociadoresAction());
    dispatch(findTiposAgendamentosAction());
    dispatch(findUsuariosNegociadoresAction());
    advancedSearchRef.current.openAdvancedSearch();
  }

  async function changePageHandler(event, newPage) {
    loadingWrapper(async () => {
      controller.page = newPage;
      const response = await controller.pesquisar();
      return response;
    });
  }

  async function changeRowPerPageHandler(event) {
    loadingWrapper(async () => {
      controller.page = getNewPage(dadosPesquisa.pageable.size, dadosPesquisa.pageable.page, event.target.value);
      controller.size = event.target.value;
      const response = await controller.pesquisar();
      return response;
    });
  }

  async function pesquisaComFiltroSimples(e) {
    e.preventDefault();
    const result = await loadingWrapper(async () => {
      const response = await controller.pesquisarComFiltroSimples(new FormData(e.target), dadosPesquisa.pageable.size);
      setSelectedIds(await controller.buscarIdsProcessos());
      return response;
    });

    setOrdenacao(controller.makeOrdenacao(true));
    if (hasContentInSearch(result)) {
      toast.warning('Nenhum resultado foi encontrado relacionado à sua pesquisa');
    }
  }

  async function pesquisarComFiltroEspecial(e) {
    e.preventDefault();
    const result = await loadingWrapper(async () => {
      simpleSearchFormRef.current.reset();
      const response = await controller.pesquisarComFiltroEspecial(new FormData(e.target), dadosPesquisa.pageable.size);
      setSelectedIds(await controller.buscarIdsProcessos());
      if (!response.error) {
        advancedSearchRef.current.closeAdvancedSearch();
      }
      return response;
    });

    setOrdenacao(controller.makeOrdenacao(true));
    if (hasContentInSearch(result)) {
      toast.warning('Nenhum resultado foi encontrado relacionado à sua pesquisa');
    }
    return result;
  }

  const pesquisarProcessosComNotificacoes = useCallback(() => {
    const result = loadingWrapper(async () => {
      simpleSearchFormRef.current.reset();
      const response = await controller.pesquisarProcessosComNotificacoes(dadosPesquisa.pageable.size);
      setSelectedIds(await controller.buscarIdsProcessosComNotificacoes());
      return response;
    });

    setOrdenacao(controller.makeOrdenacao(true));
    return result;
  }, [dadosPesquisa.pageable.size]);

  async function pesquisarComFiltroAvancado(e) {
    e.preventDefault();
    const result = await loadingWrapper(async () => {
      simpleSearchFormRef.current.reset();
      const response = await controller.pesquisarComFiltroAvancada(new FormData(e.target), dadosPesquisa.pageable.size);
      if (!response.error) {
        advancedSearchRef.current.closeAdvancedSearch();
      }
      setSelectedIds(await controller.buscarIdsAvancadoProcessos());
      return response;
    });

    setOrdenacao(controller.makeOrdenacao(true));
    if (hasContentInSearch(result)) {
      toast.warning('Nenhum resultado foi encontrado relacionado à sua pesquisa');
    }
  }

  const applyOrder = useCallback(async (columnName) => {
    if (controller.hasNotFilledPayload()) {
      return;
    }
    const result = await loadingWrapper(async () => {
      const response = await controller.pesquisarAplicandoOrdenacao(columnName, ordenacao.arrow);
      return response;
    });
    setOrdenacao(result.order);
  }, [ordenacao.arrow]);

  const tableHeader = useMemo(() => controller.makeCabecalhoTabela(ordenacao, applyOrder, {
    checked: selectAll,
    onChange: changeSelectAll,
  }), [applyOrder, ordenacao, selectAll, changeSelectAll]);

  const totalSelecionados = useMemo(() => Object.keys(selectedIds).filter(id => selectedIds[id] === true).length, [selectedIds]);

  useEffect(() => {
    setDropdownValues(controller.makeDefaultDropdownSimpleSearch(controller.filters));
    setLoading(true);
    controller.pesquisarProcessoCarregarPagina().then((data) => {
      if (data.naoCarregar) {
        return;
      }
      setDadosPesquisa(data.processos);
      setSelectedIds(data.idsProcessos);
    }).finally(() => {
      setLoading(false);
    });
  }, []);


  useEffect(() => {
    if (dadosPesquisa?.content?.length > 0) {
      setSelectAll(Object.keys(selectedIds).every(key => selectedIds[key] === true));
    }
    setConteudoTabela(controller.makeConteudoTabela(dadosPesquisa?.content, { selectedIds, changeSelectId }));
  }, [dadosPesquisa, changeSelectId, selectedIds]);

  useEffect(() => {
    dispatch(findFasesAction());
    dispatch(findNegociadoresAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(initializePageAction('Pesquisar Processos'));
  }, [dispatch]);

  useEffect(() => {
    const notificacao = new URLSearchParams(history.location.search).get('notificacao');
    if (notificacao) {
      pesquisarProcessosComNotificacoes();
    }
  }, [history.location.search, pesquisarProcessosComNotificacoes]);

  return (
    <Container>
      <LoadingUI show={isLoading([...loadingDropdowns, loading])} />
      <SimpleSearchContainer ref={simpleSearchFormRef} onSubmit={pesquisaComFiltroSimples} onReset={resetSimpleSearch}>
        <SearchButton type="submit">
          <SearchIcon style={{ marginLeft: 2 }} />
        </SearchButton>
        <InputField
          defaultValue={defaultFilterNumeroProcesso}
          name="numeroProcesso"
          label="Número do Processo"
          onChange={e => changeKeyHandler(e, KEY_NUMBER)}
          maxLength={9}
        />
        <div style={{ width: '259px' }}>
          <SelectorField
            hasEmptyLabel
            fullWidth
            name="idFase"
            label="Fase"
            items={simpleSeachDropdown.fases}
            value={dropdownValues.idFase}
            onChange={changeDropdown}
          />
        </div>
        <div style={{ width: '302px' }}>
          <SelectorField
            defaultValue={controller.filters.idUsuarioNegociador}
            hasEmptyLabel
            name="idUsuarioNegociador"
            label="Negociador"
            items={simpleSeachDropdown.negociadores}
            value={dropdownValues.idUsuarioNegociador}
            onChange={changeDropdown}
          />
        </div>
      </SimpleSearchContainer>
      <PesquisaComplementarProcesso
        ref={advancedSearchRef}
        pesquisarComFiltroEspecial={pesquisarComFiltroEspecial}
        pesquisarComFiltroAvancado={pesquisarComFiltroAvancado}
      />

      <ComplementarySearchButton type="button" onClick={openAdvancedSearch}>
        Pesquisa Avançada
      </ComplementarySearchButton>

      <Table>
        {totalSelecionados === 0 ? (
          <TableTitle> Resultado da Pesquisa</TableTitle>
        ) : (
          <AcoesPesquisaProcesso setLoading={setLoading} simpleSearchFormRef={simpleSearchFormRef} advancedSearchRef={advancedSearchRef} idsProcessos={selectedIds} totalSelecionados={totalSelecionados} />
        )}

        <TabelaPesquisaProcessos
          columns={tableHeader}
          rows={conteudoTabela}
          page={dadosPesquisa.pageable.page}
          size={dadosPesquisa.totalElements}
          rowsPerPageOptions={[15, 20, 25]}
          rowsPerPage={dadosPesquisa.pageable.size}
          onChangePage={changePageHandler}
          onChangeRowsPerPage={changeRowPerPageHandler}
        />
      </Table>
    </Container>
  );
}

export default withTokenInterceptor(PesquisarProcessosPage);
