import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default function MigrarIcon() {
  const tornarClientePath = 'M6.635 7.583a3.792 3.792 0 1 0-3.791-3.791 3.791 3.791 0 0 0 3.791 '
    + '3.791zm2.654.948h-.494a5.157 5.157 0 0 1-4.319 0h-.495A3.982 3.982 0 0 0 0 12.512v1.232a1.422 1.422 '
    + '0 0 0 1.422 1.422h10.427a1.422 1.422 0 0 0 1.422-1.422v-1.232A3.982 3.982 0 0 0 9.29 8.531zm9.568-3.8l-.823-.831a.351.351 '
    + '0 0 0-.5 0l-3.1 3.081-1.35-1.365a.351.351 0 0 0-.5 0l-.832.826a.351.351 0 0 0 0 .5l2.42 2.438a.351.351 0 0 0 '
    + '.5 0l4.186-4.153a.354.354 0 0 0 0-.5z';

  return (
    <SvgIcon transform="scale(1.13)" viewBox="-4 -4 24 24">
      <path d={tornarClientePath} />
    </SvgIcon>
  );
}
