import { makeStyles } from '@material-ui/styles';
import styled, { css } from 'styled-components';

export default makeStyles({

  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },

  header: {
    marginTop: '16px',
  },

  header_nome_cliente: {
    border: '1px solid #a2a0a0',
    '& span:first-child': {
      fontWeight: 'bold',
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '7px',
    marginBottom: '25px',
  },

  header_filtros_cards: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  header_filtro: {
    display: 'flex',
    flexDirection: 'row',
  },

  header_card: {
    display: 'flex',
    flexDirection: 'column',
    width: '52%',
    gap: '10px',
    alignItems: 'center',
    '& h3': {
      textAlign: 'center',
      fontSize: '16px',
    },
    '& h5': {
      textAlign: 'center',
      fontSize: '13px',
    },
    '& h6': {
      fontSize: '11px',
    },
  },


  cards_primeira_linha: {
    display: 'flex',
    gap: '10px',
    width: '100%',
    color: '#ffffff',
  },

  cards_segunda_linha: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    justifyContent: 'center',
    color: '#fff',
    width: '100%',
    '& h4': {
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: '700',
    },
  },

  switch: {
    display: 'flex',
    flexDirection: 'row',
  },

});

export const CardResumoContainer = styled.div`
  ${props => (props?.isDetail ? css`
  display: flex;  
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    background-color: ${props.bgColor};
  
    & div {
      display: flex;
      flex-direction: row;
      gap: 10px;
      width: 100%;
      justify-content:space-between;
      border-top: 1px solid ${props.lineColor};
    }

    & h5{
      margin-bottom: 2px;
    }
  }

` : css` 
    display: flex;
    flex-direction: column;
    background-color: ${props.bgColor};
    border-radius: 10px;
    padding: 10px;
    width: 100%;
  `)}
  
`;
