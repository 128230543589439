import React, { useCallback, useState } from 'react';
import { MdPrint as MdPrintIcon } from 'react-icons/md';
import {
  FaPlus,
} from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import useStyle, { ButtonImprimir, ButtonNovo, HeaderCliente } from './LancamentosNaoPrestadosStyle';
import {
  getFieldName, getNewPage, openPageFile, TableUtils, toastUnmappedException,
} from '../../../../helpers';
import { TableDefault, Loading } from '../../../../components';
import { TableHeaderlancamentosNaoPrestados } from '../../../../components/TabelaHeader/TableHeaderLancamentosNaoPrestados';
import { TableBodyLancamentosNaoPrestados } from '../../../../components/TabelaBody/TableBodyLancamentosNaoPrestados';
import SwitchUI from '../../../../components/UI/Switch/SwitchUI';
import { LancamentosNaoPrestadosController, LancamentosNaoPrestadosFactory } from './LancamentosNaoPrestadosController';
import { CONTA_CLIENTE, EDITAR, NOVO } from './LancamentosNaoPrestados.constants';
import DialogLancamentosNaoPrestados from './DialogNovo/DialogLancamentosNaoPrestados';
import store from '../../../../store/createStore';
import tableUtils from '../../../../helpers/utils/table.utils';
import { setValuesLancamentosNaoPrestadosPageAction } from '../../../../store/MenuAccessPage/menuAccessPageActions';
import DialogNew from '../../../../layouts/FormDialog/DialogNew';
import OptionDialogNew from '../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';


function makeFields(fields) {
  return {
    statusContaCorrenteCliente:
      fields?.statusContaCorrenteCliente || false,
  };
}

function makeColumnOrder(columnName, columnOrder) {
  return TableUtils.makeColumnOrder({
    element: columnName || 'data',
    order: columnOrder?.getNextOrder() || 'ASC',
    arrow: columnOrder ? columnOrder?.getNextArrow() : true,
  });
}

function makeLoading() {
  return {
    lancamentosNaoPrestados: false,
  };
}

function getColumnOrder() {
  const { columnOrder } = store.getState().previousPageStates.lancamentosNaoPrestados;

  return {
    ...makeColumnOrder(),
    ...columnOrder,
  };
}

function LancamentosNaoPrestadosComponent({
  clienteSelecionado, permissoes, nome, atualizarPaginaAfterAction,
}) {
  const dispatch = useDispatch();
  const style = useStyle();

  const [openDialogDeleteLancamento, setOpenDialogDeleteLancamento] = React.useState(false);
  const [openDialogAtualizarStatusLancamento, setOpenDialogAtualizarStatusLancamento] = React.useState(false);
  const [lancamentosNaoPrestados, setLancamentosNaoPrestados] = React.useState(LancamentosNaoPrestadosFactory.defaultMakeLancamentosNaoPrestados());
  const [columnOrder, setColumnOrder] = React.useState(getColumnOrder());
  const [loading, setLoading] = React.useState(makeLoading());
  const [fields, setFields] = React.useState(makeFields());
  const [openDialogButtonNovo, setOpenDialogButtonNovo] = useState(false);
  const [routesName, setRouteNames] = useState([CONTA_CLIENTE]);
  const [selectedIdContaCorrenteCliente, setSelectedIdContaCorrenteCliente] = React.useState(null);
  const [atualizar, setAtualizar] = React.useState(false);
  const [selectedStatusContaCorrenteCliente, setSelectedStatusContaCorrenteCliente] = React.useState(null);

  const onClickButtonNovo = useCallback(() => {
    setSelectedIdContaCorrenteCliente(null);
    setOpenDialogButtonNovo(oldValue => !oldValue);
    setRouteNames([CONTA_CLIENTE, NOVO]);
  }, []);

  const onClose = useCallback(() => {
    setOpenDialogButtonNovo(oldValue => !oldValue);
  }, []);

  function onEditLancamentoHandler(idContaCorrenteCliente) {
    setRouteNames([CONTA_CLIENTE, EDITAR]);
    setOpenDialogButtonNovo(oldValue => !oldValue);
    setSelectedIdContaCorrenteCliente(idContaCorrenteCliente);
  }

  function handleOnClickCancel() {
    setSelectedIdContaCorrenteCliente(null);
    setOpenDialogDeleteLancamento(false);
  }

  const findAllLancamentosNaoPrestados = React.useCallback(
    async (idCliente, status, order, page, size) => {
      setLoading(oldLoading => ({ ...oldLoading, lancamentosNaoPrestados: true }));
      try {
        const pageable = { page, size };
        const newLancamentos = await LancamentosNaoPrestadosController.findAllLancamentosNaoPrestados(
          idCliente,
          status,
          order,
          pageable,
        );
        setLancamentosNaoPrestados(newLancamentos.lancamentosNaoPrestados);
      } catch (err) {
        toastUnmappedException(
          err,
          'Ocorreu um problema ao tentar consultas os lançamentos',
        );
        setLancamentosNaoPrestados(
          LancamentosNaoPrestadosFactory.makeLancamentosNaoPrestados({ size: 10 }),
        );
      } finally {
        setLoading(oldLoading => ({ ...oldLoading, lancamentosNaoPrestados: false }));
      }
    },
    [],
  );

  function savePreviewsPagesState() {
    const pageable = tableUtils.makePageableFromTableItem(lancamentosNaoPrestados);
    dispatch(setValuesLancamentosNaoPrestadosPageAction(pageable, fields, columnOrder));
  }

  function handleOnClickCancelAtualizarStatus() {
    setSelectedIdContaCorrenteCliente(null);
    setSelectedStatusContaCorrenteCliente(null);
    setOpenDialogAtualizarStatusLancamento(false);
  }

  function handleOpenDeleteLancamento(idContaCorrenteCliente) {
    setSelectedIdContaCorrenteCliente(idContaCorrenteCliente);
    setOpenDialogDeleteLancamento(true);
  }

  function handleOpenAtualizarLancamento(idContaCorrenteCliente, statusContaCorrente) {
    setSelectedIdContaCorrenteCliente(idContaCorrenteCliente);
    setSelectedStatusContaCorrenteCliente(statusContaCorrente);
    setOpenDialogAtualizarStatusLancamento(true);
  }

  const handleChangeChecked = React.useCallback((e) => {
    const name = getFieldName(e.target);
    const { checked } = e.target;
    setFields(oldFields => ({ ...oldFields, [name]: checked }));
  }, []);

  function handleChangePage(e, newPage) {
    setLancamentosNaoPrestados(oldLancamentos => ({
      ...oldLancamentos,
      size: lancamentosNaoPrestados.size,
      page: Number(newPage),
    }));

    findAllLancamentosNaoPrestados(clienteSelecionado?.idCliente,
      fields.statusContaCorrenteCliente, columnOrder, newPage, lancamentosNaoPrestados.size).then();
    savePreviewsPagesState();
  }

  function handleRowsPerPageChange(e) {
    const size = e.target.value;
    const newPage = getNewPage(lancamentosNaoPrestados.size, lancamentosNaoPrestados.page, size);
    setLancamentosNaoPrestados(oldLancamentos => ({
      ...oldLancamentos,
      size,
      page: Number(newPage),
    }));
  }

  async function handleDeleteLancamento() {
    savePreviewsPagesState();
    setLoading(oldLoading => ({ ...oldLoading, delete: true }));
    try {
      await LancamentosNaoPrestadosController.deleteLancamento(selectedIdContaCorrenteCliente);
      findAllLancamentosNaoPrestados(
        clienteSelecionado?.idCliente,
        fields.statusContaCorrenteCliente,
        columnOrder,
        lancamentosNaoPrestados?.page,
        lancamentosNaoPrestados?.size,
      ).then(() => {
        atualizarPaginaAfterAction(true, clienteSelecionado?.idCliente);
      });
      setFields(oldFields => ({ ...oldFields }));
    } catch (err) {
      toastUnmappedException(err, `Ocorreu um problema ao tentar excluir lançamento ${selectedIdContaCorrenteCliente}`);
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, delete: false }));
      setSelectedIdContaCorrenteCliente(null);
      setOpenDialogDeleteLancamento(false);
    }
  }

  async function handleAtualizarStatus() {
    savePreviewsPagesState();
    setLoading(oldLoading => ({ ...oldLoading }));
    try {
      await LancamentosNaoPrestadosController.atualizarStatus(selectedIdContaCorrenteCliente, selectedStatusContaCorrenteCliente);
      findAllLancamentosNaoPrestados(clienteSelecionado.idCliente,
        fields.statusContaCorrenteCliente, columnOrder, lancamentosNaoPrestados.page, lancamentosNaoPrestados.size).then(() => {
        atualizarPaginaAfterAction(true, clienteSelecionado?.idCliente);
      });
      setFields(oldFields => ({ ...oldFields }));
    } catch (err) {
      toastUnmappedException(err, `Ocorreu um problema ao tentar desbloquear o lançamento ${selectedIdContaCorrenteCliente}`);
    } finally {
      setLoading(oldLoading => ({ ...oldLoading }));
      setSelectedIdContaCorrenteCliente(null);
      setSelectedStatusContaCorrenteCliente(null);
      setOpenDialogAtualizarStatusLancamento(false);
    }
  }

  async function handlePrintLancamentoNaoPrestado() {
    try {
      setLoading(oldLoading => ({ ...oldLoading, download: true }));
      const httpResponse = await LancamentosNaoPrestadosController.downloadLancamentoNaoPrestado(
        clienteSelecionado.idCliente,
        nome,
        fields.statusContaCorrenteCliente,
        columnOrder,
      );
      if (httpResponse.data.size > 0) {
        openPageFile(httpResponse.data, httpResponse.data.type);
      }
    } catch (err) {
      toastUnmappedException(
        err,
        'Ocorreu um problema ao tentar abrir o relatório',
      );
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, download: false }));
    }
  }

  async function handleApplyOrder(columnName) {
    const newColumnOrder = makeColumnOrder(columnName, columnOrder);
    setColumnOrder(newColumnOrder);
  }

  React.useEffect(() => {
    if (clienteSelecionado?.idCliente) {
      findAllLancamentosNaoPrestados(clienteSelecionado?.idCliente,
        fields.statusContaCorrenteCliente, columnOrder, 0, lancamentosNaoPrestados.size).then();
      setAtualizar(false);
    } else {
      setLancamentosNaoPrestados(LancamentosNaoPrestadosFactory.makeLancamentosNaoPrestados({ size: 10 }));
    }
  }, [
    clienteSelecionado?.idCliente,
    fields.statusContaCorrenteCliente,
    columnOrder,
    lancamentosNaoPrestados?.size,
    findAllLancamentosNaoPrestados,
    atualizar]);


  return (
    <>
      <DialogNew
        open={openDialogButtonNovo}
        text={routesName}
        className={style.dialog}
        onClose={onClose}
      >
        {openDialogButtonNovo && (
          <DialogLancamentosNaoPrestados
            onClose={onClose}
            onEditLancamentoHandler={onEditLancamentoHandler}
            idCliente={clienteSelecionado?.idCliente}
            nome={nome}
            idContaCorrenteNaoPrestado={selectedIdContaCorrenteCliente}
            setAtualizar={setAtualizar}
            atualizarPaginaAfterAction={atualizarPaginaAfterAction}
          />
        )}
      </DialogNew>
      <OptionDialogNew
        open={openDialogDeleteLancamento}
        confirmLabel="Sim"
        cancelLabel="Não"
        onClickCancel={handleOnClickCancel}
        onClickConfirm={handleDeleteLancamento}
        text="Deseja excluir o Lançamento da Conta Corrente do Cliente?"
      />
      <OptionDialogNew
        open={openDialogAtualizarStatusLancamento}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={handleOnClickCancelAtualizarStatus}
        onClickConfirm={handleAtualizarStatus}
      >
        <div style={{ fontSize: '18px' }}>
          <p>
            Confirma o
            {selectedStatusContaCorrenteCliente === 'BLOQUEADO' ? ' bloqueio ' : ' desbloqueio '}
            do Lançamento?
          </p>
        </div>
      </OptionDialogNew>
      <Loading show={loading.lancamentosNaoPrestados} />
      <div className={style.div__root}>
        <div className={style.div__header}>
          <div>
            <ButtonImprimir
              disabled={lancamentosNaoPrestados?.content.length === 0}
              onClick={handlePrintLancamentoNaoPrestado}
            >
              <MdPrintIcon size={16} />
              <span>Imprimir</span>
            </ButtonImprimir>
          </div>
          <div className={style.div__nomeCliente}>
            <HeaderCliente>
              <b>
                Cliente:
                {' '}
              </b>
              {clienteSelecionado?.idCliente}
              {clienteSelecionado?.idCliente !== null ? (' - ') : (' ')}
              {nome}
            </HeaderCliente>
          </div>
          <div>
            <ButtonNovo
              disabled={!permissoes?.efetuarLancamentos || clienteSelecionado?.idCliente === null}
              onClick={onClickButtonNovo}
            >

              <FaPlus />
              <span>Novo</span>
            </ButtonNovo>
          </div>
        </div>
        <div className={style.div__table}>
          <TableDefault
            columnsHead={(
              <TableHeaderlancamentosNaoPrestados
                columnOrder={columnOrder}
                onChangeOrder={columnName => handleApplyOrder(columnName)}
              />
            )}
            page={lancamentosNaoPrestados.page}
            rowsPerPage={lancamentosNaoPrestados.size}
            totalElements={lancamentosNaoPrestados.totalElements}
            totalElementsOnPage={lancamentosNaoPrestados.content?.length}
            totalColumns={7}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            emptyRowsHeight={36}
            rowsPerPageOptions={[10, 15, 20]}
          >
            {lancamentosNaoPrestados.content?.map(lancamento => (
              <TableBodyLancamentosNaoPrestados
                disabledPermissao={!permissoes?.efetuarLancamentos}
                key={lancamento.idContaCorrenteCliente}
                lancamento={lancamento}
                openDialogDelete={handleOpenDeleteLancamento}
                onEditLancamentoHandler={onEditLancamentoHandler}
                openDialogAtualizarStatus={handleOpenAtualizarLancamento}
                cannotAcoesIcones={!permissoes?.efetuarLancamentos}
              />
            ))}
          </TableDefault>
        </div>
        <div className={style.div__switch}>
          <SwitchUI
            label="Exibir apenas lançamentos Bloqueados"
            name="statusContaCorrenteCliente"
            checked={fields.statusContaCorrenteCliente}
            onChange={handleChangeChecked}
          />
        </div>
      </div>
    </>
  );
}

export const LancamentosNaoPrestados = LancamentosNaoPrestadosComponent;
