import React, { useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import useUtilStyles from '../../../helpers/styles/utils.styles';
import Grid24 from '../../UI/Grid24/Grid24';
import FileSelector from '../../UI/FileSelector/FileSelector';
import useStyles from './importarBordero.styles';

import {
  onFocusRequestFieldsAction,
  setActionsValue,
  setArquivosAction,
  setCacheRequestFieldsAction,
  setPageAction,
  setRequestFieldsAction,
  setSnackbarImportacaoAction,
} from '../../../store/Borderos/Importar/importarBordero.store';
import SelectorField from '../../UI/Field/Selector/SelectorField';
import DateField from '../../UI/Field/Date/DateField';
import SearchButton from '../../UI/Button/Search/SearchButton';
import ImportarDialog from './Dialog/ImportarDialog';
import { findStatusBorderoPesquisaAction } from '../../../store/Global/LoadSelectors/loadSelectors.saga';
import TableImportar from './Table/TableImportar';
import { SNACKBAR_DURATION } from '../../../helpers/constants/global.constants';
import SnackbarUI from '../../UI/Snackbar/Snackbar';
import WrapperContent from '../../UI/WrapperContent/WrapperContent';
import LoadingUI from '../../UI/Loading/LoadingUI';
import { pesquisarArquivosImportacaoAction } from '../../../store/Borderos/Importar/importarBordero.saga';
import Dica from '../../UI/Dica/Dica';


function ImportarBordero({ location }) {
  const utilStyles = useUtilStyles();
  const dispatch = useDispatch();
  const styles = useStyles();

  const statusBorderoItens = useSelector(states => states.selectors.statusBorderoPesquisa.selector);
  const statusBorderoItensLoading = useSelector(states => states.selectors.statusBorderoPesquisa.loading);

  const openDialog = useSelector(states => states.importarBorderos.actions.openDialog);
  const snackbarImportacao = useSelector(states => states.importarBorderos.actions.snackbarImportacao);
  const loading = useSelector(states => states.importarBorderos.actions.loading);
  const error = useSelector(states => states.importarBorderos.actions.error);
  const setArquivosHandler = useCallback(arquivosParam => dispatch(setArquivosAction(arquivosParam)), [dispatch]);
  const idStatus = useSelector(states => states.importarBorderos.requestFields.idStatus.copy());
  const dataImportacao = useSelector(states => states.importarBorderos.requestFields.dataImportacao.copy());
  const settingsTable = useSelector(states => states.importarBorderos.settingsTable);
  const cacheRequestFields = useSelector(states => states.importarBorderos.cacheRequestFields);


  // Limpa tudo quando clica no opção do menu.
  // Realiza a primeira busca na tela e também faz a pesquisa quando troca de página enquanto o
  // usuário não clicou no botão pesquisar.
  useEffect(() => {
    if (cacheRequestFields === null) {
      dispatch(pesquisarArquivosImportacaoAction(settingsTable.page, settingsTable.rowsPerPage));
    }
  }, [dispatch, cacheRequestFields, location.refreshPage, settingsTable.page, settingsTable.rowsPerPage]);

  useEffect(() => {
    dispatch(findStatusBorderoPesquisaAction());
  }, [dispatch, location.refreshPage]);

  const onFocusDataFieldHandler = useCallback((event) => {
    const { name } = event.target;
    let fieldName = name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }

    dispatch(onFocusRequestFieldsAction(fieldName));
  }, [dispatch]);

  const setStatesErrorsHandler = useCallback((name, value) => {
    dispatch(setActionsValue(name, value));
  }, [dispatch]);

  const openDialogImportacao = useCallback(() => {
    dispatch(setActionsValue('openDialog', true));
  }, [dispatch]);

  const onCloseDialogImportacao = useCallback(() => {
    dispatch(setActionsValue('openDialog', false));
  }, [dispatch]);

  const onChangeRequestFieldsHandler = useCallback((e) => {
    const { name, value } = e.target;
    dispatch(setRequestFieldsAction(name, value));
  }, [dispatch]);

  const onClickPesquisarHandler = useCallback(() => {
    dispatch(setCacheRequestFieldsAction(true));
    dispatch(setPageAction(0));
  }, [dispatch]);


  const onCloseSnackbarHandler = useCallback(() => {
    dispatch(setSnackbarImportacaoAction({ open: false }));
  }, [dispatch]);

  const onCloseSnackbarFileHandler = useCallback(() => {
    dispatch(setActionsValue('error', false));
  }, [dispatch]);

  const canHabilitarRelatorio = useCallback(() => {
    if (cacheRequestFields !== null) {
      return cacheRequestFields.idStatus !== 0;
    }
    return false;
  }, [cacheRequestFields]);

  return (
    <>
      <ImportarDialog open={openDialog} closeHandler={onCloseDialogImportacao} />
      <LoadingUI show={loading} />
      <SnackbarUI
        open={snackbarImportacao.open}
        autoHideDuration={SNACKBAR_DURATION}
        variant={snackbarImportacao.variant}
        message={snackbarImportacao.message}
        onClose={onCloseSnackbarHandler}
      />
      <WrapperContent>
        <Grid container>
          <Grid item xs={12} sm={11} className={styles.grid__escolherArquivos}>
            <FileSelector
              setStatesErrors={setStatesErrorsHandler}
              arquivosObject={[setArquivosHandler]}
              error={error}
              callbackToExecute={openDialogImportacao}
              closeSnackbarFileHandler={onCloseSnackbarFileHandler}
              errorMessage="O tamanho do(s) arquivo(s) selecionado(s) para Upload excedeu o tamanho máximo permitido."
            />
            <Grid item xs={12} sm={1} className={styles.grid__icon}>
              <Dica mensagemDica="A soma do tamanho dos arquivos não pode exceder 100MB e o tamanho individual não pode exceder 10MB." />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="flex-end">
          <Grid xs={12} sm={2} item className={styles.grid__status}>
            <SelectorField
              label="Status"
              loading={statusBorderoItensLoading}
              isForm
              fullWidth
              name={idStatus.name}
              value={idStatus.value}
              items={statusBorderoItens}
              onChange={onChangeRequestFieldsHandler}
            />
          </Grid>
          <Grid xs={12} sm={2} item className={styles.grid__dataImportacao}>
            <DateField
              label="A partir de:"
              maxDate={new Date()}
              name={dataImportacao.name}
              value={dataImportacao.value}
              error={dataImportacao.error}
              errorMessage={dataImportacao.errorMessage}
              onChange={onChangeRequestFieldsHandler}
              onFocus={onFocusDataFieldHandler}
            />
          </Grid>
          <Grid item className={styles.grid__buttonPesquisa}>
            <SearchButton onClick={onClickPesquisarHandler} />
          </Grid>
        </Grid>
        <Grid24 container isFormContainer className={utilStyles.mt54}>
          <TableImportar habilitarRelatorio={canHabilitarRelatorio()} />
        </Grid24>
      </WrapperContent>
    </>
  );
}

export default React.memo(withRouter(ImportarBordero));
