import styled from 'styled-components';

export const MainContainer = styled.div`
  margin-top: 7px;

  >div + div {
    margin-top: 8px;
  }
`;

export const UltimoAcompanhamento = styled.div`
  padding-top: 8px;

  > p {
    padding-bottom: 6px;

    font-size: 12px;
    line-height: 14px;
    color: #707070;
  }

  > div {
    height: 158px;
    overflow: auto;
    border: 1px solid #707070;
    border-radius: 8px;
  }
`;
export const FooterContainer = styled.footer`
  margin-top: 8px;

  display: flex;
  flex-direction: row-reverse;
`;
