import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  BoxCor, BoxCores, ButtonDropDown, DropdownContainer,
} from '../../InspecionarProcessoStyles';
import TooltipUI from '../../../../components/UI/Tooltip/Tooltip';
import CheckboxUI from '../../../../components/UI/Checkbox/CheckboxUI';
import { loadingInspecaoProcessoController as loadingController } from '../../loadingInspecaoProcessoController';
import { FloatBox } from '../../../../components/FloatContainer/FloatBox';
import { alterarExistirCobrancaJudicialDoProcessoService, findInformacoesAdicionaisInpescaoProcessoService } from '../../../../services/core/processos/processosService';
import OptionDialogNew from '../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';

function SinalizacaoCores({
  idProcesso,
  sinalizacaoCoresQuery,
  podeModificarFase,
  setLoadings,
  setAdictionalInfos,
  permitirAjuizarProcesso,
}) {
  const parentFloatBoxRef = useRef();
  const floatBoxRef = useRef();

  const permissoesUsuario = useSelector(state => state.authentication.userInformation.permissoes);
  const [openClienteNaoAjuizarProcesso, setOpenClienteNaoAjuizarProcesso] = useState(false);

  const alterarCobrancaJudicial = permissoesUsuario?.includes('ROLE_1209');

  async function onChangeAjuizarProcesso(checked) {
    try {
      floatBoxRef.current.handlerShowBox();
      setLoadings(loadingController.changeLoadingSinalizarCores(true));
      await alterarExistirCobrancaJudicialDoProcessoService(idProcesso, checked);
      const { data } = await findInformacoesAdicionaisInpescaoProcessoService(idProcesso);
      setAdictionalInfos(data);
    } finally {
      setLoadings(loadingController.changeLoadingSinalizarCores(false));
    }
  }

  function habilitaCobrancaJudicial() {
    if (permitirAjuizarProcesso === 'N' && alterarCobrancaJudicial && podeModificarFase) {
      return true;
    }

    if (permitirAjuizarProcesso === 'S' && podeModificarFase) {
      return true;
    }

    if (permitirAjuizarProcesso === 'N' && !alterarCobrancaJudicial) {
      return false;
    }

    return podeModificarFase;
  }

  return (
    <>
      <OptionDialogNew
        open={openClienteNaoAjuizarProcesso}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={() => setOpenClienteNaoAjuizarProcesso(false)}
        onClickConfirm={() => {
          onChangeAjuizarProcesso(true);
          setOpenClienteNaoAjuizarProcesso(false);
        }}
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>
            No cadastro deste Cliente está configurado para não permitir ajuizar processos.
            <br />
            Confirma a marcação de “Cobrança Judicial” para este processo?
          </p>
        </div>
      </OptionDialogNew>
      <div style={{ width: '33%', display: 'flex', justifyContent: 'flex-start' }}>
        <BoxCores ref={parentFloatBoxRef}>
          <TooltipUI title={sinalizacaoCoresQuery?.cobrancaJudicial ? 'Cobrança Judicial' : ''}>
            <BoxCor indicador={sinalizacaoCoresQuery?.cobrancaJudicial ? 'rosa' : ''} />
          </TooltipUI>

          <TooltipUI title={sinalizacaoCoresQuery?.devedorClienteAtivo ? 'O Devedor do Processo é também um Cliente ativo' : ''}>
            <BoxCor indicador={sinalizacaoCoresQuery?.devedorClienteAtivo ? 'azul' : ''} />
          </TooltipUI>

          <TooltipUI title={sinalizacaoCoresQuery?.titulosAntigos ? 'Processo com Títulos antigos' : ''}>
            <BoxCor indicador={sinalizacaoCoresQuery?.titulosAntigos ? 'amarelo' : ''} />
          </TooltipUI>

          <TooltipUI title={sinalizacaoCoresQuery?.honorariosJurosZero ? 'O percentual de recebimento de Honorários e de Juros é igual a zero' : ''}>
            <BoxCor indicador={sinalizacaoCoresQuery?.honorariosJurosZero ? 'verde' : ''} />
          </TooltipUI>

          <ButtonDropDown onClick={() => floatBoxRef.current.handlerShowBox()}>
            <div />
          </ButtonDropDown>
        </BoxCores>
        <FloatBox ref={floatBoxRef} parentRef={parentFloatBoxRef} variant="left">
          <DropdownContainer>
            <li>
              <CheckboxUI
                label="Cobrança Judicial"
                checked={sinalizacaoCoresQuery?.cobrancaJudicial}
                disabled={!habilitaCobrancaJudicial()}
                onChange={(e) => {
                  if (e.target.checked) {
                    if (permitirAjuizarProcesso === 'N') {
                      setOpenClienteNaoAjuizarProcesso(true);
                    } else {
                      onChangeAjuizarProcesso(true);
                    }
                  } else {
                    onChangeAjuizarProcesso(false);
                  }
                }}
              />
            </li>
            <li>
              <CheckboxUI
                label="Devedor é um Cliente ativo"
                checked={sinalizacaoCoresQuery?.devedorClienteAtivo}
                disabled
              />
            </li>
            <li>
              <CheckboxUI
                label="Processo com Títulos antigos"
                checked={sinalizacaoCoresQuery?.titulosAntigos}
                disabled
              />
            </li>
            <li>
              <CheckboxUI
                label="Isenção de comissionamento"
                checked={sinalizacaoCoresQuery?.honorariosJurosZero}
                disabled
              />
            </li>
          </DropdownContainer>
        </FloatBox>

      </div>
    </>
  );
}

export default React.memo(SinalizacaoCores);
