import {
  Table, TableBody, TableHead, TableRow, TableCell, TableFooter,
} from '@material-ui/core';
import React from 'react';

import PropTypes from 'prop-types';

import PaginationActions from './PaginadaSimples/Pagination/PaginationActions';
import { Pagination, TableContainer } from './tableDefaultStyles';

function EmptyRowsComponent({
  rowsPerPage, totalElementsOnPage, height, totalColumns,
}) {
  const totalEmptyRows = rowsPerPage - Math.min(rowsPerPage, totalElementsOnPage);

  const emptyRows = [];
  for (let i = 0; i < totalEmptyRows; i += 1) {
    emptyRows.push(
      <TableRow key={i} style={{ height }}>
        <TableCell colSpan={totalColumns}>
          <p />
        </TableCell>
      </TableRow>,
    );
  }

  return emptyRows;
}

function TableDefaultComponent({
  children,
  columnsHead,
  footerTableBody,
  colgroup = [],
  page,
  onPageChange,
  totalElementsOnPage,
  totalColumns,
  emptyRowsHeight,
  totalElements,
  rowsPerPage,
  rowsPerPageOptions,
  onRowsPerPageChange,
  showTablePagination = true,
  showTableFooter = false,
  withEmptyRows = true,
  scrollableX = false,
}) {
  function makeLabelDisplayed({ from, to, count }) {
    return `${from}-${to} de ${count}`;
  }

  return (
    <>
      <TableContainer>
        <Table style={{ minWidth: scrollableX ? 'max-content' : '' }}>
          <colgroup>
            {colgroup.map(((col, idx) => <col key={idx} width={col} />))}
          </colgroup>
          <TableHead>
            {columnsHead}
          </TableHead>
          <TableBody>
            {children}
            {withEmptyRows ? (
              <EmptyRowsComponent
                rowsPerPage={rowsPerPage}
                totalElementsOnPage={totalElementsOnPage}
                height={emptyRowsHeight}
                totalColumns={totalColumns}
              />
            ) : null}
          </TableBody>
          {
          showTableFooter && (
            <TableFooter>
              {footerTableBody}
            </TableFooter>
          )
        }
        </Table>
      </TableContainer>
      {showTablePagination ? (
        <Pagination
          component="div"
          ActionsComponent={PaginationActions}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          count={totalElements}
          page={page}
          labelRowsPerPage="Linhas por página"
          backIconButtonProps={{ 'aria-label': 'Página Anterior' }}
          nextIconButtonProps={{ 'aria-label': 'Próxima Página' }}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          labelDisplayedRows={makeLabelDisplayed}
        />
      ) : null}

    </>
  );
}

TableDefaultComponent.propTypes = {
  children: PropTypes.node.isRequired,
  columnsHead: PropTypes.node.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalElementsOnPage: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  totalColumns: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  emptyRowsHeight: PropTypes.number,
};

TableDefaultComponent.defaultProps = {
  rowsPerPageOptions: [10, 15, 20],
  emptyRowsHeight: 33,
};

export const TableDefault = TableDefaultComponent;
