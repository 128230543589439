import styled from 'styled-components';

const cardsColors = {
  borderos: {
    color: '#ff8ac1',
  },
  clientes: {
    color: '#75c3c8',
  },
  acordos: {
    color: '#93f068',
  },
  processos: {
    color: '#c156d2',
  },
};

export const InfoCard = styled.div`
  display: block;
  width: 130px;
  height: 130px;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 20%);

  border-radius: 8px;
  border: 1px solid ${props => (cardsColors[props.type].color)};
  background-color: ${props => (cardsColors[props.type].color)};
  color: white;
  text-align: center;

  > i {
    margin-top: 10px;
    font-size: 26px;
    margin-left: ${props => (props.type === 'clientes' ? '10px' : 0)};
  }

  > span {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  > span + span {
    display: block;
    font-size: 30px;
    font-weight: bold;
  }

  > div {
    font-size: 10px;
    i {
      margin-right: 4px;
      font-size: 12px;
    }
  }

  @media (min-width: 1400px){
    width: 158px;
    height: 158px;

    > i {
      margin-top: 24px;
      font-size: 28px;
      margin-left: ${props => (props.type === 'clientes' ? '11px' : 0)};
    }

    > span {
      font-size: 15px;
      margin-top: 3px;
      margin-bottom: 3px;
    }

    > span + span {
      font-size: 32px;
    }

    > div {
      font-size: 11px;
      i {
        margin-right: 4px;
        font-size: 13px;
      }
    }

  @media (min-width: 1920px){
    width: 195px;
    height: 195px;

    > i {
      margin-top: 16px;
      font-size: 40px;
      margin-left: ${props => (props.type === 'clientes' ? '16px' : 0)};
    }

    > span {
      font-size: 21px;
      margin-top: 4px;
      margin-bottom: 4px;
    }

    > span + span {
      font-size: 44px;
    }

    > div {
      font-size: 15px;
      i {
        margin-right: 6px;
        font-size: 18px;
      }
  }
`;
