import styled from 'styled-components';
import { tomato, white, whiteGrey } from '../../../helpers/constants/colors.constants';

export const Container = styled.div`

  width: 100%;

  & .ql-snow.ql-toolbar {
    display: ${props => (props.withoutToolbar ? 'none' : 'block')};
    background: ${white};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin-top: 4px;
    border-color: ${props => (props.error ? tomato : whiteGrey)};
  }

  & .ql-container {
    background: ${whiteGrey};
    color: black;
    max-height: ${props => (props.isMural ? '286px' : '150px')};
    height: ${props => (props.isMural ? '286px' : '150px')};
    overflow-x: auto;

    @media (min-width: 1400px){
      max-height: ${props => (props.isMural ? '312px' : '150px')};
      height: ${props => (props.isMural ? '312px' : '150px')};
    }

    @media (min-width: 1920px){
      max-height: ${props => (props.isMural ? '430px' : '150px')};
      height: ${props => (props.isMural ? '430px' : '150px')};
    }
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-color: ${props => (props.error ? tomato : whiteGrey)};
  }

  & .ql-snow .ql-editor img {
    width: 440px;
  }

  & .ql-tooltip.ql-editing {
    left: 0 !important;
  }

  & .ql-editor {
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #888;
      outline: 1px solid #cec8c8;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .ql-video {
      width: 440px;
      height: 238px;

      @media (min-width: 1400px){
        height: 268px;
      }

      @media (min-width: 1920px){
        height: 385px;
      }
    }
  }

  & /** Hint dos botões */
  .ql-formats button {
    position: relative
  }

  .ql-formats button:hover:after,
  .ql-formats button:active:after,
  .ql-formats button:focus:after {
    position: absolute;
    background: #707070;
    color: white;
    padding: .5em;
    border-radius: .5em;
    left: -50%;
    top: 100%;
    width: max-content;
    z-index: 100;
  }

  .ql-bold:hover:after,
  .ql-bold:active:after,
  .ql-bold:focus:after {
    content: 'Negrito';
  }

  .ql-italic:hover:after,
  .ql-italic:active:after,
  .ql-italic:focus:after {
    content: 'Itálico';
  }

  .ql-underline:hover:after,
  .ql-underline:active:after,
  .ql-underline:focus:after {
    content: 'Sublinhado';
  }

  .ql-strike:hover:after,
  .ql-strike:active:after,
  .ql-strike:focus:after {
    content: 'Tachado';
  }

  .ql-color:hover:after,
  .ql-color:active:after,
  .ql-color:focus:after {
    position: relative;
    padding: .5em;
    right: 30px;
    background-color: #707070;
    border: 1px solid #707070;
    border-radius: .5em;
    color: white;
    z-index: 1;
    content: 'Cor';
  }

  .ql-script[value = 'sub']:hover:after,
  .ql-script:active:after,
  .ql-script:focus:after {
    content: 'Subscrito';
  }

  .ql-script[value = 'super']:hover:after,
  .ql-script:active:after,
  .ql-script:focus:after {
    content: 'Sobrescrito';
  }

  .ql-header[value = '1']:hover:after,
  .ql-header:active:after,
  .ql-header:focus:after {
    content: 'Cabeçalho 1';
  }

  .ql-header[value = '2']:hover:after,
  .ql-header:active:after,
  .ql-header:focus:after {
    content: 'Cabeçalho 2';
  }

  .ql-blockquote:hover:after,
  .ql-blockquote:active:after,
  .ql-blockquote:focus:after {
    content: 'Bloco de citação';
  }

  .ql-code-block:hover:after,
  .ql-code-block:active:after,
  .ql-code-block:focus:after {
    content: 'Bloco de código';
  }

  .ql-list[value = 'ordered']:hover:after,
  .ql-list:active:after,
  .ql-list:focus:after {
    content: 'Numeração';
  }

  .ql-list[value = 'bullet']:hover:after,
  .ql-list:active:after,
  .ql-list:focus:after {
    content: 'Marcadores';
  }

  .ql-indent[value = '-1']:hover:after,
  .ql-indent:active:after,
  .ql-indent:focus:after {
    content: 'Diminuir Recuo';
  }

  .ql-indent[value = '+1']:hover:after,
  .ql-indent:active:after,
  .ql-indent:focus:after {
    content: 'Aumentar Recuo';
  }

  .ql-direction[value = 'rtl']:hover:after,
  .ql-direction:active:after,
  .ql-direction:focus:after {
    content: 'Marcas de parágrafo';
  }

  .ql-align.ql-picker:hover:after,
  .ql-align.ql-picker:active:after,
  .ql-align.ql-picker:focus:after {
    position: relative;
    padding: .5em;
    font-size: 12px;
    right: 80px;
    background-color: #707070;
    border: 1px solid #707070;
    border-radius: .5em;
    color: white;
    z-index: 1;
    content: 'Alinhamento';
  }

  .ql-link:hover:after,
  .ql-link:active:after,
  .ql-link:focus:after {
    content: 'Link';
  }

  .ql-image:hover:after,
  .ql-image:active:after,
  .ql-image:focus:after {
    content: 'Imagem';
  }

  .ql-video:hover:after,
  .ql-video:active:after,
  .ql-video:focus:after {
    content: 'Vídeo';
  }

  .ql-clean:hover:after,
  .ql-clean:active:after,
  .ql-clean:focus:after {
    content: 'Limpar formatação';
  }
`;
