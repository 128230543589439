import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export default makeStyles({
  div__AlignButton: {
    display: 'flex',
    gap: '16px',
  },
  div__Button: {
    marginTop: '22px',
    width: '148px',
  },
  div__ButtonFinalizar: {
    marginTop: '22px',
    width: '148px',
    '&:disabled': {
      backgroundColor: grey,
    },
  },
});

export const TableWithAccordion = styled(Paper)`
  background-color: #f1f4f9;
  border-radius: 7px;
  margin-top: 22px;
  max-height: ${props => (props.isScrollable ? props.maxTableHeigth : 'auto')};
  overflow-x: auto;

  & td {
    font-size: .75rem;
    padding: 0px 8px !important;
  }

  & th {
    cursor: default;
    font-size: .75rem;
    color: #034ddc;
    font-weight: bold;
    padding: 0px 8px !important;
    height: 33px;
    text-align: center;
    line-height: 1rem;
  }

`;
