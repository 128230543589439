import React from 'react';

import InputField from '../../../UI/Field/Input/InputField';
import Grid24 from '../../../UI/Grid24/Grid24';
import WrapperSubTitleForm from '../../../UI/WrapperSubTitleForm/WrapperSubTitleForm';

import { useCrudBorderoSelector } from '../../../../store/Borderos/Crud/CrudBorderoProvider';

import constants from './DadosAdvogadoCrudBordero.constants';
import useUtilStyles from '../../../../helpers/styles/utils.styles';

export default function DadosAdvogadoCrudBordero({ changeFields, isInspectMode, disabled }) {
  const utilStyles = useUtilStyles();

  const nomeAdvogado = useCrudBorderoSelector(states => states.requestFields.nomeAdvogado);
  const telefoneAdvogado = useCrudBorderoSelector(states => states.requestFields.telefoneAdvogado);


  return (
    <WrapperSubTitleForm title={constants.TITULO} className={utilStyles.mt16}>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={9}>
          <InputField
            isForm
            fullWidth
            disabled={isInspectMode || disabled}
            label={constants.NOME}
            name={nomeAdvogado.name}
            value={nomeAdvogado.value}
            error={nomeAdvogado.error}
            errorMessage={nomeAdvogado.errorMessage}
            maxLength={nomeAdvogado.maxLength}
            onChange={changeFields}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <InputField
            isForm
            fullWidth
            disabled={isInspectMode || disabled}
            label={constants.TELEFONE}
            name={telefoneAdvogado.name}
            value={telefoneAdvogado.value}
            error={telefoneAdvogado.error}
            errorMessage={telefoneAdvogado.errorMessage}
            maxLength={telefoneAdvogado.maxLength}
            onChange={changeFields}
          />
        </Grid24>
      </Grid24>
    </WrapperSubTitleForm>
  );
}
