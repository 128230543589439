import { TableRow, TableCell } from '@material-ui/core';
import React, { useCallback } from 'react';
import { pesquisarRepasseAction } from '../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import { setSimpleValuesRepasseAction } from '../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { useCCClienteDispatch, useCCClienteSelector } from '../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import CheckboxField from '../UI/Field/Checkbox/CheckboxField';
import OrdenacaoColuna from '../UI/Tabela/Ordenacao/OrdenacaoColuna';
import { TableCellCenter, TableCellLeft, TableCellRight } from '../UI/Tabela/tableDefaultStyles';
import TooltipUI from '../UI/Tooltip/Tooltip';


function TableHeaderRepasseComponent({ tableView, checked = false, onChange = () => { } }) {
  const dispatch = useCCClienteDispatch();

  const columnOrdenacao = useCCClienteSelector(states => (states.repasse.repasse.columnOrdenacao));
  const arrowDirection = useCCClienteSelector(states => (states.repasse.repasse.arrowDirection));

  const setColumnOrdenacao = useCallback((column) => {
    dispatch(setSimpleValuesRepasseAction('columnOrdenacao', column));
  }, [dispatch]);

  const setArrowDirection = useCallback((internalReturn) => {
    dispatch(setSimpleValuesRepasseAction('arrowDirection', internalReturn));
  }, [dispatch]);

  const onClickColumnOrdenar = useCallback(() => {
    if (tableView.length > 0) {
      dispatch(setSimpleValuesRepasseAction('page', 0));
      dispatch(pesquisarRepasseAction());
    }
  }, [dispatch, tableView]);

  return (
    <TableRow>
      <TableCell width={17}>
        <TooltipUI title="Selecionar Todos">
          <div>
            <CheckboxField
              checked={checked}
              onChange={onChange}
            />
          </div>
        </TooltipUI>
      </TableCell>
      <TableCellCenter width={107}>
        <OrdenacaoColuna
          columnLabel="Data"
          idColumn="dataPrestacao"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickColumnOrdenar()}
        />
      </TableCellCenter>
      <TableCellCenter width={107}>
        <OrdenacaoColuna
          columnLabel="Código"
          idColumn="idCliente"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickColumnOrdenar()}
        />
      </TableCellCenter>
      <TableCellLeft width={344}>
        <OrdenacaoColuna
          columnLabel="Nome do Cliente"
          idColumn="nomeCliente"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickColumnOrdenar()}
        />
      </TableCellLeft>
      <TableCellCenter width={160}>
        <OrdenacaoColuna
          columnLabel="Forma do Repasse"
          idColumn="tipoLiquidacaoPrestacao"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickColumnOrdenar()}
        />
      </TableCellCenter>
      <TableCellLeft width={213}>
        <OrdenacaoColuna
          columnLabel="Nome do Banco"
          idColumn="idBanco"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickColumnOrdenar()}
        />
      </TableCellLeft>
      <TableCellRight width={160}>
        <OrdenacaoColuna
          columnLabel="Valor do Repasse"
          idColumn="valorRepasse"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickColumnOrdenar()}
        />
      </TableCellRight>
    </TableRow>
  );
}

export const TableHeaderRepasse = React.memo(TableHeaderRepasseComponent);
