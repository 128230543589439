
import React from 'react';
import { getMoneyMask, toBrazilianDate } from '../../helpers';
import TableHeader from '../../models/TableHeader';
import OrdenacaoColuna from '../../components/UI/Tabela/Ordenacao/OrdenacaoColuna';
import CheckboxField from '../../components/UI/Field/Checkbox/CheckboxField';

class RegistrarBoletoFactory {
  makeCabecalhoTabela(
    columnOrdenacao, setColumnOrdenacao, arrowDirection, setArrowDirection, onClickColumnOrdenar, changeSelectAll = () => { }, selectAll = false,
  ) {
    return [
      new TableHeader({
        id: 'idBoletoCliente',
        checkbox: true,
        label: (
          <CheckboxField
            checked={selectAll}
            onChange={changeSelectAll}
          />
        ),
        alignCell: 'left',
        maxWidth: 39,
      }),
      new TableHeader({
        id: 'dataEmissao',
        label: <OrdenacaoColuna
          columnLabel="Data de Emissão"
          idColumn="dataEmissao"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 125,
      }),
      new TableHeader({
        id: 'nossoNumero',
        label: <OrdenacaoColuna
          columnLabel="Nosso Número"
          idColumn="nossoNumero"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 124
        ,
      }),

      new TableHeader({
        id: 'tipoPagador',
        label: <OrdenacaoColuna
          columnLabel="Tipo do Pagador"
          idColumn="tipoPagador"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 127,
      }),
      new TableHeader({
        id: 'cpfCnpjPagador',
        label: <OrdenacaoColuna
          columnLabel="CNPJ/CPF do Pagador"
          idColumn="cpfCnpjPagador"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 140,
      }),
      new TableHeader({
        id: 'nomePagador',
        label: <OrdenacaoColuna
          columnLabel="Nome do Pagador"
          idColumn="nomePagador"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 268,
      }),
      new TableHeader({
        id: 'vencimento',
        label: <OrdenacaoColuna
          columnLabel="Vencimento"
          idColumn="vencimento"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 95,
      }),
      new TableHeader({
        id: 'valor',
        label: <OrdenacaoColuna
          columnLabel="Valor"
          idColumn="valor"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        alignCell: 'center',
        maxWidth: 110,
      }),
    ];
  }

  makeConteudoTabela(boletos, changeSelectId, listIds) {
    if (!boletos || boletos.length === 0) {
      return [];
    }

    return boletos.map(boleto => ({
      id: boleto.idBoletoRegistro,
      values: {
        idBoletoCliente: (
          <CheckboxField
            name={String(boleto.idBoletoRegistro)}
            checked={listIds[boleto.idBoletoRegistro] || false}
            onChange={changeSelectId}
          />),
        dataEmissao: toBrazilianDate(boleto.dataEmissao),
        nossoNumero: boleto.nossoNumero,
        tipoPagador: boleto.tipoPagador,
        cpfCnpjPagador: boleto.cpfCnpjPagador,
        nomePagador: boleto.nomePagador,
        vencimento: toBrazilianDate(boleto.vencimento),
        valor: getMoneyMask(boleto.valor),
        mensagemErro: boleto.mensagemErro,
      },
    }));
  }
}

export const registrarBoletoFactory = new RegistrarBoletoFactory();
