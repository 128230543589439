import React, { useCallback, useEffect, useState } from 'react';
import { RadioGroup, Typography } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { Button } from '@mui/material';
import useStyles from './EmailsPadraoDialog.styles';
import TableHeader from '../../../models/TableHeader';
import RadioField from '../../../components/UI/Field/Radio/RadioField';
import LoadingUI from '../../../components/UI/Loading/LoadingUI';
import Table from '../../../components/UI/Tabela/PaginadaSimples/Table';
import DialogNew from '../../../layouts/FormDialog/DialogNew';
import { buscarEmailsPadrao, buscarEmailsPadraoNotificacaoCobranca } from '../../../services/core/processos/processosEnviarEmailDevedorService';


const tableColumns = [
  new TableHeader({
    id: 'selecionado', label: '', width: '50px', align: 'center', alignCell: 'center',
  }),
  new TableHeader({
    id: 'modeloEmail', label: 'Modelos de E-mail', width: '250px', alignCell: 'left',
  }),
];

function EmailsPadraoDialog({
  open, closeDialogHandler, onChangeTexto, setFormFields, isAcaoEmMassa,
}) {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [tableRows, setTableRows] = useState([]);

  const [selecionadoRadio, setSelecionadoRadio] = useState('');
  const [emailPadraoSelecionado, setEmailPadraoSelecionado] = useState('');

  const carregarEmailsPadrao = useCallback(async () => {
    setLoading(true);
    try {
      let response;
      if (isAcaoEmMassa) {
        response = await buscarEmailsPadraoNotificacaoCobranca();
      } else {
        response = await buscarEmailsPadrao();
      }
      setTableRows(response.data);
    } finally {
      setLoading(false);
    }
  }, [isAcaoEmMassa]);

  const onChangeRadioGroup = (event) => {
    setSelecionadoRadio(event.target.value);
  };

  const onCloseHandler = useCallback(() => {
    setSelecionadoRadio('');
    setEmailPadraoSelecionado('');
    closeDialogHandler();
  }, [closeDialogHandler]);


  const onClickConfirmarHandler = useCallback(() => {
    onChangeTexto(emailPadraoSelecionado?.textoEmail);
    setFormFields(formFields => ({ ...formFields, assunto: emailPadraoSelecionado?.assuntoEmail }));
    setFormFields(formFields => ({ ...formFields, idTipoEmailPadrao: emailPadraoSelecionado?.idTipoEmailPadrao }));
    setFormFields(formFields => ({ ...formFields, solicitarFase: emailPadraoSelecionado?.solicitarFase }));
    setFormFields(formFields => ({ ...formFields, nomeModelo: selecionadoRadio }));
    closeDialogHandler();
  }, [closeDialogHandler, onChangeTexto, emailPadraoSelecionado, setFormFields, selecionadoRadio]);


  const conteudoTabela = tableRows.map(conteudo => ({
    id: uuid(),
    values: {
      selecionado: (
        <RadioField
          name="selecionados"
          onChange={() => setEmailPadraoSelecionado({
            assuntoEmail: conteudo.assuntoEmail,
            textoEmail: conteudo.textoEmail,
            idTipoEmailPadrao: conteudo.idTipoEmailPadrao,
            solicitarFase: conteudo.solicitarFase,
          })}
          value={`${conteudo.assuntoEmail}-${conteudo.nomeTipo}`}
        />),
      modeloEmail: conteudo.nomeTipo,
    },
  }));

  useEffect(() => {
    if (open) {
      carregarEmailsPadrao().then();
    }
  }, [open, carregarEmailsPadrao]);

  return (
    <DialogNew
      open={open}
      text={['Emails Padrão']}
      className={styles.dialog}
      onClose={onCloseHandler}
    >
      <LoadingUI show={loading} />
      <Typography component="p" className={styles.typography}>
        Selecione um E-mail Padrão:
      </Typography>
      <div className={styles.div__table}>
        <RadioGroup
          name="selecionados"
          value={selecionadoRadio}
          onChange={onChangeRadioGroup}
        >
          <Table isScrollable rows={conteudoTabela} columns={tableColumns} />
        </RadioGroup>
      </div>
      <div className={styles.div__footer}>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
          <Button
            onClick={onCloseHandler}
          >
            Cancelar
          </Button>
          <Button
            disabled={!selecionadoRadio}
            onClick={onClickConfirmarHandler}
          >
            Confirmar
          </Button>
        </div>
      </div>
    </DialogNew>
  );
}

export default EmailsPadraoDialog;
