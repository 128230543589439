import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  perrywinkle,
  black16,
  lightPeriwinkle,
  paleGrey,
  blackTwo,
} from '../../../../../helpers/constants/colors.constants';

export default makeStyles({
  wrapper: {
    minWidth: '100% !important',
    backgroundColor: paleGrey,
    overflow: 'auto',
    maxHeight: '186px',
    cursor: 'pointer !important',
    '& .Mui-selected': {
      backgroundColor: `${perrywinkle} !important`,
      '&:hover ': {
        backgroundColor: `${perrywinkle} !important`,
      },
    },
    '& tr': {
      '&:hover ': {
        backgroundColor: `${black16} !important`,
      },
    },
    '& td': {
      fontSize: '12px',
      paddingTop: '5.1px',
      paddingBottom: '4.1px',
      padding: '0px 16px',
      height: '33px',
    },
    '& th': {
      fontSize: '12px',
      backgroundColor: lightPeriwinkle,
      color: blackTwo,
      fontWeight: 'bold',
      padding: '0px 20px',
      height: '33px',
    },
  },
  overFlowText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'fit-content',
  },
  emptyValue: {
    textAlign: 'center',
  },
  fitContent: {
    width: 'fit-content',
  },
});
