import React from 'react';
import PropTypes from 'prop-types';
import { MdMenu, MdNotifications } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import {
  Badge, Button, IconButton, Typography,
} from '@material-ui/core';
import {
  Actions, NavigationInfo, Container, ActionsContainer,
} from './toolbarStyles';

import { ActionsNavBar } from '../NavBar/ActionsNavBar';
import { FloatBox } from '../FloatContainer/FloatBox';
import { Notifications } from '../NavBar/actionsNavBarStyles';

function ToolbarComponent({ minimized, handleChangeMinimize }) {
  const title = useSelector(state => state?.theme?.title || '');
  const quantidadeNotificacoes = useSelector(state => state.theme.quantidadeNotificacoes);

  const history = useHistory();
  const badgeReg = React.useRef();
  const badgeFloatReg = React.useRef();

  function handleShowNotitications() {
    if (quantidadeNotificacoes > 0) {
      badgeFloatReg.current.handlerShowBox();
    }
  }

  function goToProcessos() {
    badgeFloatReg.current.handlerShowBox();
    localStorage.setItem('previousUrl', '/');
    history.push('/processos?notificacao=true');
  }

  return (
    <Container>
      <NavigationInfo minimized={minimized}>
        <button
          type="button"
          onClick={handleChangeMinimize}
        >
          <MdMenu size={24} />
        </button>
        <Typography>{title}</Typography>
      </NavigationInfo>
      <Actions>
        <IconButton
          ref={badgeReg}
          color="inherit"
          onClick={handleShowNotitications}
        >
          <Badge badgeContent={quantidadeNotificacoes} color="error">
            <MdNotifications color="black" size={24} />
          </Badge>
        </IconButton>
        <FloatBox
          ref={badgeFloatReg}
          parentRef={badgeReg}
        >
          <Notifications>
            <div>
              <Button
                style={{
                  textTransform: 'capitalize',
                  fontSize: '14px',
                  paddingLeft: '0',
                  fontWeight: 'normal',
                }}
                onClick={() => goToProcessos()}
              >
                <p>Você tem Requisições aguardando.</p>
              </Button>
            </div>

          </Notifications>
        </FloatBox>
        <ActionsContainer>
          <ActionsNavBar />
        </ActionsContainer>
      </Actions>
    </Container>
  );
}

ToolbarComponent.propTypes = {
  minimized: PropTypes.bool.isRequired,
  handleChangeMinimize: PropTypes.func.isRequired,
};

export const Toolbar = React.memo(ToolbarComponent);
