import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import SubTitleForm from '../../components/UI/SubTitleForm/SubTitleForm';
import InputField from '../../components/UI/Field/Input/InputField';
import Grid24 from '../../components/UI/Grid24/Grid24';
import {
  getFieldName, getRequiredLabel, hasUserPermissions, toAmericanDate,
} from '../../helpers';
import { CampoObrigatorio, SelectorField } from '../../components';
import MoneyField from '../../components/UI/Field/Money/MoneyField';
import ButtonUI from '../../components/UI/Button/ButtonUI';
import useUtilStyles from '../../helpers/styles/utils.styles';
import CheckboxField from '../../components/UI/Field/Checkbox/CheckboxField';
import { FieldsContainer, FooterLancamento, RootLancamento } from './CrudLancamentoFinanceiro.styles';
import { lancamentoController } from './lancamentoController';
import DateField from '../../components/UI/Field/Date/DateField';
import { InputFile } from '../../components/UI/InputFile/InputFile';
import LoadingUI from '../../components/UI/Loading/LoadingUI';

function InformacoesLancamento(
  {
    idFilial = null,
    idCaixaFilial = null,
    isInsertMode = false,
    isEditMode = false,
  },
) {
  const utilStyles = useUtilStyles();
  const history = useHistory();

  const [formFields, setFormFields] = useState(lancamentoController.makeFormFields());
  const [errorFields, setErrorFields] = useState(lancamentoController.makeErrors());
  const [itemsLancamento, setItemsLancamento] = useState([]);
  const [clearFile, setClearFile] = useState(false);
  const [itemsOrigem, setItemsOrigem] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChangeFields = (event) => {
    const { name, value, checked } = event.target;
    setFormFields(oldValues => ({ ...oldValues, [name]: checked || value }));
    if (name === 'naoPossuiComprovante' && checked) {
      setFormFields(oldValues => ({ ...oldValues, nomeComprovante: '' }));
      setErrorFields(oldValues => ({ ...oldValues, nomeComprovante: { error: false, errorMessage: '' } }));
    }
  };

  const onFocusFields = (event) => {
    const name = getFieldName(event.target);
    setErrorFields(oldValues => ({ ...oldValues, [name]: { error: false, errorMessage: '' } }));
  };

  const onFocusComprovanteWhenSelecionar = () => {
    setErrorFields(oldValues => ({ ...oldValues, nomeComprovante: { error: false, errorMessage: '' } }));
  };

  async function onClickSalvar(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.set('valorLancamento', formFields.valorLancamento);
    formData.set('nomeComprovante', formFields.nomeComprovante);
    formData.set('naoPossuiComprovante', formFields.naoPossuiComprovante ? 'true' : 'false');
    formData.set('dataLancamento', toAmericanDate(formFields.dataLancamento));

    if (isInsertMode) {
      formData.set('manterArquivo', 'false');
      if (formFields.naoPossuiComprovante) {
        formData.set('nomeComprovante', null); // Back nao pode receber null na variavel comprovante
      }
      const { success, errors } = await lancamentoController.novoLancamento(idFilial, formData, errorFields, setLoading);
      if (success) {
        toast.success('Lançamento inserido com sucesso!');
        setFormFields(lancamentoController.makeFormFields());
        setErrorFields(lancamentoController.makeErrors());
        setClearFile(true);
      } else {
        toast.error(errors.toastMessage,
          { style: { width: '392px' } });
        setErrorFields(errors?.campos);
      }
    }

    if (isEditMode) {
      formData.set('idCaixaFilial', idCaixaFilial);
      const arquivo = formData.get('comprovante');
      if (formFields.nomeComprovante && arquivo.size === 0) {
        formData.set('manterArquivo', 'true');
      } else {
        formData.set('manterArquivo', 'false');
      }

      const {
        success,
        errors,
      } = await lancamentoController.editarLancamento(idFilial, formData, errorFields, setLoading);
      if (success) {
        history.goBack();
      } else {
        toast.error(errors.toastMessage, { style: { width: '392px' } });
        setErrorFields(errors?.campos);
      }
    }
  }

  // Redirecionamento caso não haja permissão
  useEffect(() => {
    if (!hasUserPermissions(1503)) {
      if (!hasUserPermissions(1502)) {
        history.goBack();
      }
    }
  }, [history, isInsertMode, isEditMode]);

  // Carregar informações iniciais da tela.
  useEffect(() => {
    lancamentoController.buscarTiposLancamentoEOrigens(setLoading).then((res) => {
      setItemsLancamento(lancamentoController.makeItemsLancamento(res.tiposLancamentos.data, hasUserPermissions(1503)));
      setItemsOrigem(lancamentoController.makeItemsOrigens(res.origens.data));
    }).catch(() => {
      setItemsLancamento([]);
    });

    if (isEditMode && idCaixaFilial) {
      lancamentoController.buscarLancamentoCaixaFilial(idCaixaFilial).then((res) => {
        setFormFields(lancamentoController.makeFormFields(res.data));
      }).catch(() => {
        history.goBack();
      });
    }
  }, [isEditMode, idCaixaFilial, history]);

  return (
    <>
      <LoadingUI show={loading} />
      <form onSubmit={onClickSalvar}>
        <RootLancamento>
          <FieldsContainer>
            <CampoObrigatorio visible={isInsertMode || isEditMode} />
            <SubTitleForm>Informações</SubTitleForm>
            <Grid24 container isFormContainer spacing="1">
              <Grid24 item xs={24} sm={3}>
                <DateField
                  maxDate={new Date()}
                  fullWidth
                  isForm
                  name="dataLancamento"
                  value={formFields.dataLancamento}
                  error={errorFields.dataLancamento.error}
                  errorMessage={errorFields.dataLancamento.errorMessage}
                  label={getRequiredLabel('Data do Lançamento', isInsertMode || isEditMode)}
                  onChange={onChangeFields}
                  onFocus={onFocusFields}
                />
              </Grid24>

              <Grid24 item xs={24} sm={7}>
                <SelectorField
                  fullWidth
                  isForm
                  items={itemsLancamento}
                  name="idLancamentoCaixaFilial"
                  value={formFields.idLancamentoCaixaFilial}
                  error={errorFields.idLancamentoCaixaFilial.error}
                  errorMessage={errorFields.idLancamentoCaixaFilial.errorMessage}
                  label={getRequiredLabel('Lançamento', isInsertMode || isEditMode)}
                  onChange={onChangeFields}
                  onFocus={onFocusFields}
                />
              </Grid24>

              <Grid24 item xs={24} sm={5}>
                <MoneyField
                  fullWidth
                  isForm
                  name="valorLancamento"
                  value={formFields.valorLancamento}
                  error={errorFields.valorLancamento.error}
                  errorMessage={errorFields.valorLancamento.errorMessage}
                  label={getRequiredLabel('Valor do Lançamento', isInsertMode || isEditMode)}
                  onChange={onChangeFields}
                  onFocus={onFocusFields}
                />
              </Grid24>

              {itemsLancamento.filter(item => formFields.idLancamentoCaixaFilial === item.id)[0]?.aux === 'DEBITO' && (
                <Grid24 item xs={24} sm={5}>
                  <SelectorField
                    fullWidth
                    isForm
                    items={itemsOrigem}
                    name="idTipoOrigemCaixaFilial"
                    value={formFields.idTipoOrigemCaixaFilial}
                    error={errorFields.idTipoOrigemCaixaFilial.error}
                    errorMessage={errorFields.idTipoOrigemCaixaFilial.errorMessage}
                    label={getRequiredLabel('Origem', isInsertMode || isEditMode)}
                    onChange={onChangeFields}
                    onFocus={onFocusFields}
                  />
                </Grid24>
              )}
            </Grid24>

            <Grid24 container isFormContainer spacing="1">
              <Grid24 item xs={24} sm={15}>
                <InputField
                  fullWidth
                  isForm
                  name="descricaoLancamento"
                  value={formFields.descricaoLancamento}
                  error={formFields.descricaoLancamento.error}
                  errorMessage={formFields.descricaoLancamento.errorMessage}
                  label="Descrição"
                  onChange={onChangeFields}
                  onFocus={onFocusFields}
                />
              </Grid24>
            </Grid24>

            <Grid24 container isFormContainer spacing="3" className={utilStyles.mt15}>
              <Grid24 item xs={24} sm={13}>
                <InputFile
                  fullWidth
                  isForm
                  maxLength={200}
                  textExtensions="(.pdf, .png, .jpeg ou .jpg /máx: 10MB)"
                  label="Comprovante*"
                  disabled={formFields?.naoPossuiComprovante}
                  keepButtonDisabled
                  labelName="nomeComprovante"
                  name="comprovante"
                  accept="image/png,image/jpeg,application/pdf"
                  value={formFields?.nomeComprovante || ''}
                  error={errorFields.nomeComprovante.error}
                  errorMessage={errorFields.nomeComprovante.errorMessage}
                  onChange={onChangeFields}
                  onFocus={onFocusComprovanteWhenSelecionar}
                  clearFile={clearFile}
                />
              </Grid24>
            </Grid24>

            <Grid24 container isFormContainer spacing="1" className={utilStyles.ml8} style={{ marginTop: '24px' }}>
              <CheckboxField
                name="naoPossuiComprovante"
                label="Lançamento não possui comprovante"
                checked={formFields?.naoPossuiComprovante || false}
                onChange={onChangeFields}
              />
            </Grid24>
            {formFields.naoPossuiComprovante === true && (
              <Grid24 xs={24} sm={10} className={utilStyles.mt22}>
                <InputField
                  fullWidth
                  isForm
                  name="motivoAusenciaComprovante"
                  value={formFields.motivoAusenciaComprovante}
                  error={errorFields.motivoAusenciaComprovante.error}
                  errorMessage={errorFields.motivoAusenciaComprovante.errorMessage}
                  label={getRequiredLabel('Motivo', isInsertMode || isEditMode)}
                  onChange={onChangeFields}
                  onFocus={onFocusFields}
                />
              </Grid24>
            )}
          </FieldsContainer>
          <FooterLancamento>
            <div>
              <div>
                <ButtonUI
                  color="error"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancelar
                </ButtonUI>
              </div>

              <div>
                <ButtonUI
                  type="submit"
                  color="success"
                >
                  Salvar
                </ButtonUI>
              </div>
            </div>
          </FooterLancamento>
        </RootLancamento>
      </form>
    </>

  );
}

export default withRouter(InformacoesLancamento);
