export default class ChangeAttr {
  constructor(args = { maxLength: undefined, isPositiveInteger: false }) {
    this.maxLength = args.maxLength;
    this.isPositiveInteger = args.isPositiveInteger || false;
  }

  getValueWithMaxLengthFilter(value) {
    if (this.maxLength && value.length > this.maxLength) {
      return value.slice(0, this.maxLength);
    }
    return value;
  }

  getValudWithPositiveInteger(value) {
    if (this.isPositiveInteger) {
      return value.match(/[0-9]*/g).join('');
    }
    return value;
  }
}
