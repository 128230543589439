import React from 'react';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'core-js/stable';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';


import App from './main/App';

import store from './store/createStore';

import * as serviceWorker from './serviceWorker';

const application = (
  <Provider store={store}>
    <App />
  </Provider>
);
ReactDOM.render(application, document.getElementById('root'));

serviceWorker.unregister();
