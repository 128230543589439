import React, { useCallback } from 'react';
import { FaEraser } from 'react-icons/fa';
import ButtonUI from '../../../../../../../components/UI/Button/ButtonUI';
import {
  MainContainer, FooterContainer,
} from './AcaoPagamentoStyles';
import { ActionHeaderContainer, CustomButton } from '../PagamentosStyle';
import { SimpleTitle } from '../../../../../../../components/Title/SimpleTitle/SimpleTitle';
import Grid24 from '../../../../../../../components/UI/Grid24/Grid24';
import MoneyField from '../../../../../../../components/UI/Field/Money/MoneyField';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { setRequestFieldsFocusPagamentosAction, setSimpleValuePagamentosValorAction } from '../../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { inserirLancamentoPagamentoAction } from '../../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import { getRequiredLabel } from '../../../../../../../helpers';

export default function AcaoPagamentoComponent() {
  const dispatch = useCCClienteDispatch();

  const valor = useCCClienteSelector(states => (states.pagamentos.formToSave.valor));

  const onChangeFields = (event) => {
    const { name, value, checked } = event.target;
    dispatch(setSimpleValuePagamentosValorAction(name, checked || value));
  };

  const onSubmitHandler = useCallback(() => {
    dispatch(inserirLancamentoPagamentoAction(true));
  }, [dispatch]);

  const cleanValue = useCallback(() => {
    dispatch(setSimpleValuePagamentosValorAction('valor', 0));
  }, [dispatch]);

  const focusFieldsHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(setRequestFieldsFocusPagamentosAction(fieldName));
  }, [dispatch]);

  return (
    <>
      <ActionHeaderContainer>
        <SimpleTitle
          text="Valor do Pagamento"
        />

        <CustomButton
          disabled={valor.value === 0}
          onClick={cleanValue}
        >
          <FaEraser size={18} />
          <span>Limpar</span>
        </CustomButton>

      </ActionHeaderContainer>
      <MainContainer>
        <Grid24 xs={24} sm={24}>
          <Grid24 xs={24} sm={12}>
            <MoneyField
              isForm
              label={getRequiredLabel('Valor', true)}
              name="valor"
              value={valor.value}
              error={valor.error}
              errorMessage={valor.errorMessage}
              onChange={onChangeFields}
              onFocus={focusFieldsHandler}
            />
          </Grid24>
        </Grid24>
      </MainContainer>
      <FooterContainer>
        <ButtonUI
          color="success"
          style={{ width: '136px' }}
          onClick={onSubmitHandler}
        >
          Confirmar
        </ButtonUI>
      </FooterContainer>
    </>
  );
}

export const AcaoPagamento = AcaoPagamentoComponent;
