import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  wrapper: {
    height: '100vh',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    borderRadius: '46px !important',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    marginTop: '50px',
  },
  logo: {
    position: 'absolute',
    background: '#ff1381',
    height: '90px',
    width: '90px',
    borderRadius: '50%',
    padding: '20px',
    marginTop: '-45px',
    '& img': {
      color: 'white',
      height: '50px',
      width: '50px',
    },
    '& h6': {
      textAlign: 'center',
    },
  },
  cardContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    '& >div': {
      padding: '5px 25px',
    },
    '& >div:nth-child(3)': {
      marginTop: '12px',
    },
    '& >button': {
      marginTop: '5px',
      width: '152px',
    },
    '& >a': {
      textDecoration: 'none',
      color: 'black',
      marginTop: '12px',
      fontWeight: 'bold',
      marginBottom: '18px',
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  buttonSpacing: {
    marginBottom: '16px',
  },
  justifyContentCenter: {
    justifyContent: 'center !important',
    marginBottom: '60px',
  },
  inputAdornment: {
    marginRight: '18px',
  },
  inputAdornmentIcon: {
    color: 'black !important',
  },
  errorMessage: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'red',
  },
  hideError: {
    opacity: '0',
  },
});
