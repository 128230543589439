import { put, select, takeLatest } from 'redux-saga/effects';

import { getExceptionHandler } from '../../../helpers/utils/exception.util';
import {
  buildPayload,
  getExceptionMessage,
  isBadRequestException,
  isNotFoundException,
} from '../../../helpers/utils/utils';

import { findBorderoById, insertBordero } from '../../../services/bordero/bordero/bordero.services';
import { findSimpleCliente } from '../../../services/core/cliente/cliente.services';
import { findDevedorByDocumento } from '../../../services/core/devedor/devedor.services';
import {
  setBorderosAction,
  setBorderoWithErrorAction,
  setClienteAction,
  setDevedorAction,
  setDialogAction,
  setLoadingAction,
  setSuccessDialogAction,
} from './CrudBordero.store';

const constants = {
  DIALOG_MESSAGE_NOT_FOUND_BORDERO: 'Não foi possível atender sua solicitação pois este borderô já excedeu o prazo de permanência e foi removido',
};

const FIND_BODEROS_BY_ID = 'FIND_BODEROS_BY_ID_CRUD_BORDERO';
const FIND_CLIENTE_BY_ID = 'FIND_CLIENTE_BY_ID_CRUD_BORDERO';
const FIND_DEVEDOR_BY_DOCUMENTO = 'FIND_DEVEDOR_BY_DOCUMENTO_CRUD_BORDERO';
const SUBMIT = 'SUBMIT_CRUD_BORDERO';
const STATUS_ACTIVE = 'A';

export const findBoderoByIdAction = idBordero => ({
  type: FIND_BODEROS_BY_ID,
  idBordero,
});

export const findClienteByIdAction = idCliente => ({
  type: FIND_CLIENTE_BY_ID,
  idCliente,
});

export const findDevedorByDocumentoAction = documento => ({
  type: FIND_DEVEDOR_BY_DOCUMENTO,
  documento,
});

export const submitAction = () => ({
  type: SUBMIT,
});

function* findBoderoByIdHandler(actions) {
  yield put(setLoadingAction(true));
  try {
    const borderoResponse = yield findBorderoById(actions.idBordero);
    const clienteResponse = yield findSimpleCliente(borderoResponse.data.idCliente, null);
    const bordero = { cliente: { ...clienteResponse.data }, ...borderoResponse.data };
    yield put(setBorderosAction(bordero));
  } catch (e) {
    yield put(setDialogAction(true, 'error', constants.DIALOG_MESSAGE_NOT_FOUND_BORDERO, 'INSPECT_SCREEN_BORDERO_NOT_FOUND'));
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* findClienteByCodigoHandler(actions) {
  yield put(setLoadingAction(true, 'cliente'));
  try {
    const cliente = yield findSimpleCliente(actions.idCliente, STATUS_ACTIVE);
    yield put(setClienteAction(cliente.data));
  } catch (exception) {
    yield put(setClienteAction({}));
    if (isBadRequestException(exception) || isNotFoundException(exception)) {
      yield put(setDialogAction(true, 'warning', getExceptionMessage(exception)));
    }
  } finally {
    yield put(setLoadingAction(false, 'cliente'));
  }
}

function* findDevedorByDocumentoHandler(actions) {
  yield put(setLoadingAction(true, 'devedor'));
  try {
    const devedor = yield findDevedorByDocumento(actions.documento);
    yield put(setDevedorAction(devedor.data));
  } catch (exception) {
    yield put(setDevedorAction({}));
  } finally {
    yield put(setLoadingAction(false, 'devedor'));
  }
}

function getPayload(fields) {
  const payload = buildPayload(fields);
  payload.categoria = payload.categoria === undefined ? 'PESSOA_JURIDICA' : payload.categoria;
  payload.idCliente = fields.cliente.idCliente.value;
  return payload;
}

function* submitHandler() {
  yield put(setLoadingAction(true));
  const fields = yield select(states => states.requestFields);
  try {
    const payload = yield getPayload(fields);
    payload.titulos = payload.titulos.map((titulo) => {
      const modified = { ...titulo };
      modified.possuirProtesto = titulo.possuirProtesto === 'S';
      return modified;
    });
    yield insertBordero(payload);
    yield put(setSuccessDialogAction(true));
  } catch (exception) {
    const [updatedFields] = yield getExceptionHandler(exception, fields);
    if (updatedFields) {
      yield put(setBorderoWithErrorAction(updatedFields));
    }
  }
  yield put(setLoadingAction(false));
}

export default function* watchCrudBordero() {
  yield takeLatest(FIND_BODEROS_BY_ID, findBoderoByIdHandler);
  yield takeLatest(FIND_CLIENTE_BY_ID, findClienteByCodigoHandler);
  yield takeLatest(FIND_DEVEDOR_BY_DOCUMENTO, findDevedorByDocumentoHandler);
  yield takeLatest(SUBMIT, submitHandler);
}
