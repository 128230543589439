import makeStyles from '@material-ui/core/styles/makeStyles';
import { warmGrey } from '../../../helpers/constants/colors.constants';

export default makeStyles({
  div__header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '14px',
  },
  div__buttonsHeader: {
    width: '148px',
    backgroundColor: 'white',
    border: '1px solid #034ddc',
    color: '#034ddc',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  icon__eneabled: {
    '& i': {
      color: '#43a047',
      fontSize: '16px',
    },
  },
  icon__disabled: {
    '& i': {
      color: warmGrey,
      fontSize: '16px',
    },
  },
});
