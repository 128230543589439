/* eslint-disable indent */
import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';

import { toast } from 'react-toastify';
import CheckboxField from '../../../../../components/UI/Field/Checkbox/CheckboxField';
import {
 buildSettingsTable, getMoneyMask, toBrazilianDate,
  getExceptionErrorMessage, isBadRequestException,
} from '../../../../../helpers';
import { loadingInspecaoProcessoController } from '../../../loadingInspecaoProcessoController';
import { acordosFactory } from '../../AcordosFactory';
import useStyles, { Buttons, ContainerNovoAcordo, StepSubLabel } from '../../AcordosStyle';
import TableNovoAcordo from '../TableNovoAcordo';

import { findDadosDefaultAcordo, findResumoAcordo } from '../../../../../services/core/processos/processosAcordosService';
import { findIdsTitulosService, findNovoAcordosService } from '../../../../../services/core/processos/processosTitulosService';
import { diferenca } from '../../../../../helpers/utils/dataStructure.utils';

function NovoAcordoPassoUmComponent({
  setLoadings, idProcesso, setOpenButtonNovoAcordo, handleNextStep, setDadosDefault, setDadosResumo, setSelectedIds, selectedIds, listaDeIds,
}) {
  const styles = useStyles();
  const [tableRows, setTableRows] = useState([]);
  const [settingsTable, setSettingsTable] = useState(buildSettingsTable([], 0, 0, 0));
  const [arrowOrdenacao, setArrowOrdenacao] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const [columnOrdenacao, setColumnOrdenacao] = useState({
    element: 'sequencia',
    order: 'ASC',
  });

  const getSelectTitulos = useCallback(
    () => Object.keys(selectedIds)
      .filter(ids => selectedIds[ids])
      .map(ids => +ids),
    [selectedIds],
  );

  const hasSelectedIds = useCallback(() => getSelectTitulos().length > 0, [getSelectTitulos]);

  const configureStatesPesquisa = useCallback((data) => {
    setSettingsTable({
      content: data,
    });
  }, []);

  const pesquisarTitulos = useCallback((ordenacao) => {
    setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(true));
    findNovoAcordosService(idProcesso, [`${ordenacao.element},${ordenacao.order}`]).then((response) => {
      const { data } = response;
      configureStatesPesquisa(data);
    }).catch(() => {
      setSettingsTable(buildSettingsTable([[], 0, 0, 0]));
    }).finally(() => {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(false));
    });
  }, [configureStatesPesquisa, idProcesso, setLoadings]);

  const onClickOrdernacaoColumn = useCallback(async (columnName) => {
    pesquisarTitulos({ element: columnName, order: arrowOrdenacao ? 'ASC' : 'DESC' });
  }, [pesquisarTitulos, arrowOrdenacao]);

  const changeSelectAll = useCallback((e) => {
    const { checked } = e.target;
    setSelectAll(checked);
    setSelectedIds(Object.keys(selectedIds).reduce((acc, key) => ({ ...acc, [key]: checked }), {}));
  }, [selectedIds, setSelectAll, setSelectedIds]);

  const changeSelectId = useCallback((e) => {
    const { name, checked } = e.target;
    setSelectedIds({ ...selectedIds, [name]: checked });
  }, [selectedIds, setSelectedIds]);

  const onClickButtonVoltar = useCallback(() => {
    setOpenButtonNovoAcordo(oldValue => !oldValue);
  }, [setOpenButtonNovoAcordo]);

  const makeConteudoTabela = useCallback((titulos) => {
    if (!titulos || titulos.length === 0) {
      return [];
    }

    return {
      values: {
        idTitulo: (
          <CheckboxField
            name={String(titulos.idTitulo)}
            checked={selectedIds[titulos.idTitulo] || false}
            onChange={changeSelectId}
          />),
        sequencia: titulos.sequencia,
        idTipoTitulo: titulos.idTipoTitulo,
        numero: titulos.numero,
        dataVencimento: toBrazilianDate(titulos.dataVencimento),
        atraso: titulos.atraso,
        valorOriginal: getMoneyMask(titulos.valorOriginal),
        valorSaldoOriginal: getMoneyMask(titulos.valorSaldoOriginal),
        valorProtesto: getMoneyMask(titulos.valorProtesto),
        valorMulta: getMoneyMask(titulos.valorMulta),
        valorJuros: getMoneyMask(titulos.valorJuros),
        valorHonorarios: getMoneyMask(titulos.valorHonorarios),
        valorDesconto: getMoneyMask(titulos.valorDesconto),
      },
    };
  }, [changeSelectId, selectedIds]);

  const pesquisarDadosDefault = useCallback(() => {
    setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(true));
    findDadosDefaultAcordo(idProcesso).then((response) => {
      const { data } = response;
      setDadosDefault(data);
    }).catch((error) => {
      if (isBadRequestException(error)) {
        toast.error(error?.response?.data?.message, { style: { width: '392px' } });
      } else {
        toast.error(getExceptionErrorMessage(error), { style: { width: '392px' } });
      }
    }).finally(() => {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(false));
    });
  }, [idProcesso, setLoadings, setDadosDefault]);

  const pesquisarDadosResumo = useCallback(() => {
    setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(true));
    findResumoAcordo(idProcesso, listaDeIds).then((response) => {
      const { data } = response;
      // eslint-disable-next-line prefer-destructuring
      data.jsonErros = JSON.parse(data.jsonErros)[0];
      setDadosResumo(data);
    }).catch((error) => {
      if (isBadRequestException(error)) {
        toast.error(error?.response?.data?.message, { style: { width: '392px' } });
      } else {
        toast.error(getExceptionErrorMessage(error), { style: { width: '392px' } });
      }
    }).finally(() => {
      setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(false));
    });
  }, [idProcesso, setLoadings, listaDeIds, setDadosResumo]);

  const tableContent = useMemo(
    () => settingsTable.content.map((processoParam, index) => makeConteudoTabela(
      processoParam,
      index,
    )),
    [settingsTable.content, makeConteudoTabela],
  );

  const onClickButtonProximo = useCallback(() => {
    pesquisarDadosDefault();
    pesquisarDadosResumo();
    handleNextStep();
  }, [pesquisarDadosDefault, pesquisarDadosResumo, handleNextStep]);

  useEffect(() => {
    findIdsTitulosService(idProcesso).then((response) => {
      setSelectedIds((prevSelectedIds) => {
        if (Object.keys(prevSelectedIds).every(key => prevSelectedIds[key] !== true)) {
          return response.data.reduce((acumulador, id) => ({ ...acumulador, [id]: false }), {});
        }
          const setPrevSelected = new Set(Object.keys(prevSelectedIds).map(key => Number(key)));
          const setNewIds = new Set(response.data);
          const diffNewPrev = diferenca(setNewIds, setPrevSelected);
          if (diffNewPrev.size === 0) {
            return prevSelectedIds;
          }
          const newObjectIds = [...diffNewPrev].reduce((acumulador, id) => ({ ...acumulador, [id]: false }), {});
          return { ...prevSelectedIds, ...newObjectIds };
      });
    }).catch(() => {
      //
    });
  }, [idProcesso, setSelectedIds]);

  useEffect(() => {
    setTableRows(tableContent);
    setSelectAll(Object.keys(selectedIds).every(key => selectedIds[key] === true));
  }, [tableContent, selectedIds]);

  useEffect(() => {
    findNovoAcordosService(idProcesso, [columnOrdenacao.element, columnOrdenacao.order]).then((response) => {
      const { data } = response;
      configureStatesPesquisa(data);
    }).catch(() => {
      setSettingsTable(buildSettingsTable([[], 0, 0, 0]));
    });
  }, [idProcesso, configureStatesPesquisa, columnOrdenacao.element, columnOrdenacao.order]);

  return (
    <>
      <ContainerNovoAcordo>
        <StepSubLabel>
          Selecione os Títulos que deseja incluir no Acordo ou Simulação. Somente Títulos que não fazem parte de outro Acordo estão listados abaixo:
        </StepSubLabel>
        <div>
          <TableNovoAcordo
            columns={acordosFactory.makeCabecalhoTabelNovoAcordo(
              setArrowOrdenacao,
              arrowOrdenacao,
              columnOrdenacao,
              setColumnOrdenacao,
              onClickOrdernacaoColumn, {
              checked: selectAll,
              onChange: changeSelectAll,
            },
            )}
            rows={tableRows}
            page={settingsTable.page}
            size={settingsTable.size}
            rowsPerPage={settingsTable.rowsPerPage}
          />
        </div>
        <div className={styles.div__buttons}>
          <Buttons onClick={onClickButtonVoltar}>
            Voltar
          </Buttons>
          <Buttons
            disabled={!hasSelectedIds()}
            onClick={onClickButtonProximo}
          >
            Próximo
          </Buttons>
        </div>
      </ContainerNovoAcordo>
    </>
  );
}

export const PassoUm = NovoAcordoPassoUmComponent;
