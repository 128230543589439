import React, { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import useStyles from './testarSmsDialog.styles';
import WrapperSubTitleForm from '../../../../UI/WrapperSubTitleForm/WrapperSubTitleForm';
import InputField from '../../../../UI/Field/Input/InputField';
import FieldModel, { getValudWithPositiveInteger } from '../../../../../models/FieldModel';
import { enviarSmsTeste } from '../../../../../services/core/acordoseguro/enviarSMS.services';
import DialogNew from '../../../../../layouts/FormDialog/DialogNew';

function TestarSmsDialog({
  open,
  setLoading,
  onCloseHandler,
}) {
  const styles = useStyles();
  const [form, setForm] = useState({
    ddd: new FieldModel({ name: 'ddd', value: '' }),
    numeroTelefone: new FieldModel({ name: 'numeroTelefone', value: '' }),
  });

  const onFocusFieldsHandler = useCallback((event) => {
    const { name } = event.target;
    setForm({ ...form, [name]: form[name].updateErrors() });
  }, [form]);

  const onChangeFieldsHandler = (event) => {
    event.target.value = getValudWithPositiveInteger(event.target.value);
  };

  const closeDialogHandler = () => {
    setForm({
      ddd: new FieldModel({ name: 'ddd', value: '' }),
      numeroTelefone: new FieldModel({ name: 'numeroTelefone', value: '' }),
    });
    onCloseHandler();
  };

  async function onSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const payload = {
      ddd: formData.get('ddd'),
      numeroTelefone: formData.get('numeroTelefone'),
    };
    try {
      setLoading(true);
      await enviarSmsTeste(payload);
      onCloseHandler();
    } catch (e) {
      const { validations } = e.response.data;
      if (validations) {
        const newForm = validations.reduce((f, erro) => {
          const newField = f[erro.field].updateErrors(erro.message);
          return { ...f, [erro.field]: newField };
        }, { ...form });
        setForm(newForm);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <DialogNew
      open={open}
      text={['Testar Envio de SMS/Email']}
      onClose={closeDialogHandler}
      className={styles.dialog}
    >
      <form onSubmit={onSubmit}>
        <WrapperSubTitleForm title="Informe o Número para Teste" />
        <div className={styles.div__inputs}>
          <div className={styles.ddd__field}>
            <InputField
              isForm
              label="DDD"
              name="ddd"
              error={form.ddd.error}
              errorMessage={form.ddd.errorMessage}
              maxLength={3}
              onChange={onChangeFieldsHandler}
              onFocus={onFocusFieldsHandler}
            />
          </div>
          <div className={styles.numero__field}>
            <InputField
              isForm
              label="Número"
              name="numeroTelefone"
              error={form.numeroTelefone.error}
              errorMessage={form.numeroTelefone.errorMessage}
              maxLength={9}
              onChange={onChangeFieldsHandler}
              onFocus={onFocusFieldsHandler}
            />
          </div>
        </div>
        <div className={styles.div__footer}>
          <Button
            onClick={closeDialogHandler}
          >
            Cancelar
          </Button>
          <Button
            onClick={onSubmit}
          >
            Confirmar
          </Button>
        </div>
      </form>
    </DialogNew>
  );
}

export default TestarSmsDialog;
