import React from 'react';
import { useDispatch } from 'react-redux';
import { initializePageAction } from '../../../store/theme.actions';
import LogProcessamentoBordero from '../../../components/LogProcessamento/LogProcessamentoBordero';
import withTokenInterceptor from '../../../hoc/withTokenInterceptor/withTokenInterceptor';
import LogProcessamentoBorderoProvider from '../../../store/LogProcessamento/LogProcessamentoBorderoProvider';

function LogProcessamentoBorderoPage() {
  const dispatch = useDispatch();
  dispatch(initializePageAction('Visualizar Log'));

  return (
    <LogProcessamentoBorderoProvider>
      <LogProcessamentoBordero />
    </LogProcessamentoBorderoProvider>
  );
}

export default withTokenInterceptor(LogProcessamentoBorderoPage);
