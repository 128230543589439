import React from 'react';
import { useDispatch } from 'react-redux';

import CrudFilialProvider from '../../../store/Filial/Crud/CrudFilialProvider';
import CrudFilial from '../../../components/Filial/Crud/CrudFilial';
import { initializePageAction } from '../../../store/theme.actions';
import constants from '../../../helpers/constants/navigationTitle.constants';
import withTokenInterceptor from '../../../hoc/withTokenInterceptor/withTokenInterceptor';

function CadastrarFilialPage() {
  const dispatch = useDispatch();
  dispatch(initializePageAction(`${constants.FILIAL} ${constants.SEPARADOR}  ${constants.NOVO}`));

  return (
    <CrudFilialProvider>
      <CrudFilial />
    </CrudFilialProvider>
  );
}

export default withTokenInterceptor(CadastrarFilialPage);
