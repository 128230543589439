import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

import useStyles from './CampoObrigatorio.styles';

/**
 * TODO: Refatorar lógica do componente para melhor legibilidade e desenvolvimento.
 * Foi adicionado o boolean visible que indica quando será renderizado ou não o componente.
 */
function CampoObrigatorio({ disabled, visible = true }) {
  const styles = useStyles();
  let content = null;
  if (disabled === false && visible) {
    content = (
      <div className={styles.textInfo}>
        <Typography component="p">*Campo obrigatório</Typography>
      </div>
    );
  }
  return content;
}

CampoObrigatorio.defaultProps = {
  disabled: false,
};

CampoObrigatorio.propTypes = {
  show: PropTypes.bool,
};

export default CampoObrigatorio;
