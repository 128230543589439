import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const PreferenciasButton = styled(Button)`
  color: #034ddc;
  border: 2px solid #034ddc;
  border-radius: 23px;

  font-size: 18px;
  font-weight: bold;
  text-transform: none;

  & .MuiButton-label {
    span {
      margin-left: 10px;
      display: inline-block;
    }
  }
`;

export const Preferencia = styled.li`
  display: flex;
  align-items: center;

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  svg {
    color: ${props => (props.disabled ? '#a1a0a0' : 'black')};
  }

  span {
    margin-left: 8px;
    color: ${props => (props.disabled ? '#a1a0a0' : 'black')};
    font-size: 12px;
  }
`;

export const ListaPreferencias = styled.div`
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  padding: 8px;

  & li + li {
    margin-top:8px;
  }
`;
