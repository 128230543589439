import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  root: {
    backgroundColor: '#ff1381',
    padding: '10px',
    textAlign: 'center',
    color: 'white',
    '&:hover': {
      backgroundColor: '#ff1381A0',
    },
    '&:disabled': {
      backgroundColor: 'lightgray',
    },
  },
});
