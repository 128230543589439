import { styled, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  div__root: {
    display: 'flex',
    marginTop: '41px',
    justifyContent: 'flex-End',
    width: '100%',
    alignItems: 'baseline',
  },
  div__tipo: {
    display: 'flex',
    width: '243px',
    marginRight: '10px',
  },
  div__buttonPesquisar: {
    backgroundColor: '#ff1381',
    color: 'white',
    width: '40px',
    height: '40px',
    '&:hover': {
      backgroundColor: '#ff1381',
      opacity: '.7',
    },
  },
  buttonNovo: {
    marginTop: '50px',
    '& button': {
      width: '152px',
    },
  },
  dialog: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '585px',
      heigth: '58px',
    },
  },
  titleTextoPadrao: {
    marginLeft: '5px',
    marginTop: '13px',
  },
});

export const ContentDialog = styled(Typography)`
  text-align: center;
`;
