import React, { useCallback } from 'react';

import Grid24 from '../../../../UI/Grid24/Grid24';
import CheckboxField from '../../../../UI/Field/Checkbox/CheckboxField';
import InputField from '../../../../UI/Field/Input';
import SelectorField from '../../../../UI/Field/Selector';

import { getAttributeExistingObject, getRequiredLabel } from '../../../../../helpers/utils/utils';

import useStyles from './TelefoneAdvogadoCrudCliente.styles';
import { useCrudClienteSelector } from '../../../../../store/Cliente/V2/Crud/CrudClienteProvider';

export function buildLayoutConstants(requiredFields) {
  return {
    TIPO_TELEFONE: getRequiredLabel('Tipo Telefone', getAttributeExistingObject(requiredFields, 'tipoTelefone', false)),
    DDD: getRequiredLabel('DDD', getAttributeExistingObject(requiredFields, 'ddd', false)),
    NUMERO: getRequiredLabel('Telefone', getAttributeExistingObject(requiredFields, 'numero', false)),
    OBSERVACAO: getRequiredLabel('Observação', getAttributeExistingObject(requiredFields, 'observacao', false)),
    WHATSAPP: 'WhatsApp',
  };
}

function TelefoneAdvogadoCrudCliente({
  disabled = false,
  tiposTelefone = [],
  setAttribute,
  setFocus,
  requiredFields = {},
}) {
  const styles = useStyles();
  const constants = buildLayoutConstants(requiredFields);

  const tipoTelefone = useCrudClienteSelector(states => states.controls.telefoneAdvogado.tipoTelefone.copy());
  const ddd = useCrudClienteSelector(states => states.controls.telefoneAdvogado.ddd.copy());
  const numero = useCrudClienteSelector(states => states.controls.telefoneAdvogado.numero.copy());
  const permitirWhatsApp = useCrudClienteSelector(states => states.controls.telefoneAdvogado.permitirWhatsApp.copy());
  const observacao = useCrudClienteSelector(states => states.controls.telefoneAdvogado.observacao.copy());

  const getValue = (value, checked, type) => ((type === 'checkbox') ? checked : value);

  const changeAttributeHandler = useCallback((event) => {
    const {
      name, value, checked, type,
    } = event.target;
    setAttribute(name, getValue(value, checked, type));
  }, [setAttribute]);

  const focusAttributeHandler = useCallback((event) => {
    let fieldName = event.target.name;

    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    setFocus(fieldName);
  }, [setFocus]);

  return (
    <>
      <Grid24 xs={24} sm={3}>
        <SelectorField
          isForm
          fullWidth
          disabled={disabled}
          hasEmptyLabel
          label={constants.TIPO_TELEFONE}
          items={tiposTelefone}
          name={tipoTelefone.name}
          value={tipoTelefone.value}
          error={tipoTelefone.error}
          errorMessage={tipoTelefone.errorMessage}
          onChange={changeAttributeHandler}
        />
      </Grid24>
      <Grid24 xs={24} sm={1}>
        <InputField
          isForm
          fullWidth
          disabled={disabled}
          label={constants.DDD}
          error={ddd.error}
          name={ddd.name}
          value={ddd.value}
          errorMessage={ddd.errorMessage}
          maxLength={ddd.maxLength}
          onChange={changeAttributeHandler}
          onFocus={focusAttributeHandler}
        />
      </Grid24>
      <Grid24 xs={24} sm={3}>
        <InputField
          isForm
          fullWidth
          disabled={disabled}
          label={constants.NUMERO}
          error={numero.error}
          name={numero.name}
          value={numero.value}
          errorMessage={numero.errorMessage}
          maxLength={numero.maxLength}
          onChange={changeAttributeHandler}
          onFocus={focusAttributeHandler}
        />
      </Grid24>
      <Grid24 xs={24} sm={5}>
        <InputField
          isForm
          fullWidth
          disabled={disabled}
          label={constants.OBSERVACAO}
          error={observacao.error}
          name={observacao.name}
          value={observacao.value}
          errorMessage={observacao.errorMessage}
          maxLength={observacao.maxLength}
          onChange={changeAttributeHandler}
          onFocus={focusAttributeHandler}
        />
      </Grid24>
      <Grid24
        xs={24}
        sm={2}
        container
        className={styles.style}
      >
        <Grid24 xs={24} sm={24} spacing={1}>
          <CheckboxField
            label={constants.WHATSAPP}
            disabled={disabled}
            name={permitirWhatsApp.name}
            checked={permitirWhatsApp.value || false}
            onChange={changeAttributeHandler}
          />
        </Grid24>
      </Grid24>
    </>
  );
}

export default React.memo(TelefoneAdvogadoCrudCliente);
