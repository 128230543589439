import { takeLatest, put } from 'redux-saga/effects';
import { buscarQuantidadeNotificacoesRequisicoesService } from '../services/core/processos/processosRequisicoesService';
import {
  findQuantidadeRequisicoesAction, FIND_QUANTIDADE_REQUISICOES, INITIALIZE_MENU, setQuantidadeNotificacoesAction,
} from './theme.actions';

export function* inializePageHandler() {
  yield put(findQuantidadeRequisicoesAction());
}

export function* findQuantidadeRequisicoesHandler() {
  try {
    const quantidadeRequisicoes = yield buscarQuantidadeNotificacoesRequisicoesService();
    yield put(setQuantidadeNotificacoesAction(quantidadeRequisicoes?.data || 0));
  } catch (e) {
    yield put(setQuantidadeNotificacoesAction(0));
  }
}

export function* watchSagaMenu() {
  yield takeLatest(INITIALIZE_MENU, inializePageHandler);
  yield takeLatest(FIND_QUANTIDADE_REQUISICOES, findQuantidadeRequisicoesHandler);
}
