import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initializePageAction } from '../../store/theme.actions';
import { CorrecaoBordero } from '../../components/CorrecaoBordero/CorrecaoBordero';
import ConclusaoValidacaoBordero from '../../components/ConclusaoValidacaoBordero/ConclusaoValidacaoBordero';
import SumarioValidacaoBordero from '../../components/SumarioValidacaoBordero/SumarioValidacaoBordero';

function ValidarBorderoPage() {
  const dispatch = useDispatch();
  const [nomeArquivo, setNomeArquivo] = useState(null);
  const [retornoBordero, setRetornoBordero] = useState(null);
  const [retornoVersionBordero, setRetornoVersionBordero] = useState(null);
  const [downloadBordero, setDownloadBordero] = useState([]);
  const [hasFieldsCorrections, setHasFieldsCorrections] = useState(false);
  const [correcaoFinalizada, setCorrecaoFinalizada] = useState(false);

  useEffect(() => {
    dispatch(initializePageAction('Validar Borderô'));
  }, [dispatch]);

  function handleClickCorrections() {
    setHasFieldsCorrections(!hasFieldsCorrections);
  }

  const handleRetornoBordero = useCallback((data, version) => {
    setRetornoBordero(data);
    setRetornoVersionBordero(version);
  }, [setRetornoBordero, setRetornoVersionBordero]);

  return (
    <div>
      {!correcaoFinalizada && !hasFieldsCorrections && (
        <SumarioValidacaoBordero
          onChangeRetorno={handleRetornoBordero}
          retornoBordero={retornoBordero}
          onClickCorrections={handleClickCorrections}
          nomeArquivo={nomeArquivo}
          setNomeArquivo={setNomeArquivo}
          setRetornoBordero={setRetornoBordero}
        />
      )}

      {!correcaoFinalizada && hasFieldsCorrections && (
      <CorrecaoBordero
        retornoBordero={retornoBordero}
        retornoVersionBordero={retornoVersionBordero}
        borderosComErro={retornoBordero?.devedoresComErro}
        idCliente={retornoBordero?.idCliente}
        nomeCliente={retornoBordero?.nomeCliente}
        onClickCorrections={handleClickCorrections}
        setDownloadBordero={setDownloadBordero}
        setCorrecaoFinalizada={setCorrecaoFinalizada}
        setHasFieldsCorrections={setHasFieldsCorrections}
        downloadBordero={downloadBordero}
      />
      )}

      {correcaoFinalizada && (
        <ConclusaoValidacaoBordero
          nomeArquivo={nomeArquivo}
          retornoBordero={retornoBordero}
          downloadBordero={downloadBordero}
          setCorrecaoFinalizada={setCorrecaoFinalizada}
          setHasFieldsCorrections={setHasFieldsCorrections}
          setNomeArquivo={setNomeArquivo}
        />
      )}
    </div>
  );
}

export default ValidarBorderoPage;
