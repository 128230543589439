import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  isText: {
    paddingTop: '10px !important',
    paddingBottom: '9px !important',
  },
  active: {
    backgroundColor: '#A0BBEF !important',
  },
  spaceCheckbox: {
    paddingTop: '6px !important',
    paddingBottom: '6px !important',
    paddingLeft: '6px !important',
  },
  spaceCheckboxInterno: {
    paddingRight: '6px !important',
  },
});
