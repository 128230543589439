import React, { useCallback, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './RelacaoPrestacaoStyle';
import PeriodField from '../../../../../../components/UI/Field/Period/PeriodField';
import {
  getFieldName,
  getNewPage,
  useUtilStyles,
} from '../../../../../../helpers/index';
import { ButtonPesquisar } from '../../../../contaCorrenteClienteStyles';
import SwitchUI from '../../../../../../components/UI/Switch/SwitchUI';
import CardResumo from './Cards/CardResumo';
import TabelaRelacaoPrestacao from './Tabela/TabelaRelacaoPrestacao';
import { relacaoPrestacaoFactory as factory } from './relacaoPrestacaoFactory';
import {
  useCCClienteDispatch,
  useCCClienteSelector,
} from '../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import {
  disableFieldsRelacaoPrestacaoAction,
  onChangeFormFieldsRelacaoPrestacaoAction,
  onFocusPeriodFieldRelacaoPrestacaoAction,
  resetFormFieldsNovaNotaAction,
  resetFormFieldsRelacaoPrestacaoAction,
  setCommonAction,
  setSimpleValueLancamentoHandlerAction,
  setSimpleValueNotasFiscaisHandlerAction,
  setSimpleValueRelacaoPrestacaoAction,
} from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';

import {
  cancelarPrestacaoAction,
  pesquisarPrestacaoInicialAction,
  buscarRelacoesDoClienteAction,
  buscarSaldosRelacaoAction,
  printRelacaoPrestacaoAction,
  enviarEmailPrestacaoAction,
  buscarInformacoesRelacaoPrestacaoAction,
  estornarPrestacaoAction,
  buscarMotivoEstornoPrestacaoAction,
} from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import TabModel from '../../../../../../models/TabModel';
import TabCardLayoutAccordion from '../../../../../../layouts/TabCardLayoutAccordion/TabCardLayoutAccordion';
import WrapperSubTitleForm from '../../../../../../components/UI/WrapperSubTitleForm';
import PagamentosComponent from '../Pagamentos/Pagamentos';
import TabPanel from '../../../../../../components/UI/TabPanel/TabPanel';
import LancamentosComponent from '../Lancamentos/Lancamentos';
import NotasFiscais from '../NotasFiscais/NotasFiscais';
import BoletosComponent from '../Boletos/Boletos';
import OptionDialogNew from '../../../../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';
import DialogNew from '../../../../../../layouts/FormDialog/DialogNew';
import DialogEstornoPrestacao from './DialogEstornoPrestacao/DialogEstornoPrestacao';
import DialogMotivoEstornoPrestacao from './DialogMotivoEstornoPrestacao/DialogMotivoEstornoPrestacao';


function getDetailsCard(details = []) {
  return details.map(info => ({
    label: info.label,
    value: info.value,
  }));
}

function RelacaoPrestacao({ permissoes }) {
  const utilStyles = useUtilStyles();
  const dispatch = useCCClienteDispatch();


  const [conteudoTabela, setConteudoTabela] = useState([]); // State que vai atualizar quando tiver dadosPesquisa...
  const detalhes = useCCClienteSelector(states => states.detalhes.detalhesCliente);

  const idClienteSelecionado = useCCClienteSelector(states => (states.common.clienteSelecionado.idCliente));
  const idRelacaoSelecionada = useCCClienteSelector(states => (states.common.idRelacaoSelecionada));
  const formFields = useCCClienteSelector(states => (states.relacaoPrestacao.formFields));
  const relacoesPrestacoes = useCCClienteSelector(states => (states.relacaoPrestacao.relacoesPrestacoes));
  const saldos = useCCClienteSelector(states => (states.relacaoPrestacao.saldos));
  const openDialogCancelPrestacao = useCCClienteSelector(states => (states.relacaoPrestacao.openDialogCancelPrestacao));
  const openDialogEstornoPrestacao = useCCClienteSelector(states => (states.relacaoPrestacao.openDialogEstornoPrestacao));
  const openDialogMotivoEstornoPrestacao = useCCClienteSelector(states => (states.relacaoPrestacao.openDialogMotivoEstornoPrestacao));
  const motivoEstornoPrestacao = useCCClienteSelector(states => (states.relacaoPrestacao.motivoEstornoPrestacao));
  const page = useCCClienteSelector(states => (states.relacaoPrestacao.page));
  const rowsPerPage = useCCClienteSelector(states => (states.relacaoPrestacao.rowsPerPage));
  const totalElements = useCCClienteSelector(states => (states.relacaoPrestacao.totalElements));
  const columnOrdenacao = useCCClienteSelector(states => (states.relacaoPrestacao.columnOrdenacao));
  const arrowDirection = useCCClienteSelector(states => (states.relacaoPrestacao.arrowDirection));
  const [disabled, setDisabled] = useState(false); // Desabilitar campos da tela...
  const [activeTab, setActiveTab] = useState(0);
  const idPrestacaoContaClienteSelecionado = useCCClienteSelector(states => (states.common.idPrestacaoContaClienteSelecionado));
  const lancamentos = useCCClienteSelector(states => (states.lancamentos.tableView));
  const [idPrestacaoClicada, setIdPrestacaoClicada] = useState(null);
  const cacheFormFields = useCCClienteSelector(states => (states.relacaoPrestacao.cacheFormFields));

  const [tabItens] = useState([
    new TabModel('tab-lancamentos', 0, 'Lançamentos', 'scrollable-auto-tabpanel'),
    new TabModel('tab-notas-fiscais', 1, 'Notas Fiscais', 'scrollable-auto-tabpanel'),
    new TabModel('tab-boletos', 2, 'Boletos', 'scrollable-auto-tabpanel'),
    new TabModel('tab-pagamentos', 3, 'Pagamentos', 'scrollable-auto-tabpanel'),
  ]);

  useEffect(() => {
    setDisabled(idClienteSelecionado === null);
  }, [idClienteSelecionado]);


  useEffect(() => {
    dispatch(disableFieldsRelacaoPrestacaoAction(disabled));
  }, [dispatch, disabled]);

  const changeTabViewHandler = useCallback((activeTabParam) => {
    setActiveTab(activeTabParam);
  }, [setActiveTab]);

  const onChangeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeFormFieldsRelacaoPrestacaoAction(name, value));
  }, [dispatch]);

  const onFocusFieldsHandler = useCallback(() => {
    dispatch(onFocusPeriodFieldRelacaoPrestacaoAction());
  }, [dispatch]);

  function handleOnClickCancelPrestacao() {
    dispatch(setSimpleValueRelacaoPrestacaoAction('openDialogCancelPrestacao', false));
  }

  const handleOpenCancelPrestacao = useCallback(async (idPrestacao) => {
    setIdPrestacaoClicada(idPrestacao);
    dispatch(setSimpleValueRelacaoPrestacaoAction('openDialogCancelPrestacao', true));
  }, [dispatch, setIdPrestacaoClicada]);

  const handleOpenEstornoPrestacao = useCallback(async (idPrestacao) => {
    setIdPrestacaoClicada(idPrestacao);
    dispatch(setSimpleValueRelacaoPrestacaoAction('openDialogEstornoPrestacao', true));
  }, [dispatch, setIdPrestacaoClicada]);

  const handleOpenMotivoEstornoPrestacao = useCallback((idPrestacao) => {
    dispatch(buscarMotivoEstornoPrestacaoAction(idPrestacao));
  }, [dispatch]);

  const pesquisarHandler = useCallback(async () => {
    dispatch(setSimpleValueRelacaoPrestacaoAction('page', 0));
    dispatch(buscarRelacoesDoClienteAction(formFields));
  }, [dispatch, formFields]);


  const changePageHandler = useCallback((event, newPage) => {
    dispatch(setSimpleValueRelacaoPrestacaoAction('page', Number(newPage)));
    dispatch(buscarRelacoesDoClienteAction(formFields));
  }, [dispatch, formFields]);

  const changeRowsPerPageHandler = useCallback((e) => {
    const size = e.target.value;
    const newPage = getNewPage(rowsPerPage, page, size);

    dispatch(setSimpleValueRelacaoPrestacaoAction('page', Number(newPage)));
    dispatch(setSimpleValueRelacaoPrestacaoAction('rowsPerPage', Number(size)));

    dispatch(buscarRelacoesDoClienteAction(formFields));
  }, [dispatch, rowsPerPage, page, formFields]);

  const handlePrintPrestacao = useCallback(async (idPrestacaoContaCliente) => {
    dispatch(printRelacaoPrestacaoAction(idPrestacaoContaCliente));
  }, [dispatch]);

  const handleChangeChecked = React.useCallback((e) => {
    const name = getFieldName(e.target);
    const { checked } = e.target;

    dispatch(setSimpleValueRelacaoPrestacaoAction('page', 0));
    dispatch(onChangeFormFieldsRelacaoPrestacaoAction(name, checked));
    if (cacheFormFields !== null && typeof cacheFormFields !== 'undefined') {
      cacheFormFields.prestacaoAPagar.value = checked;
      dispatch(buscarRelacoesDoClienteAction(cacheFormFields));
    }
  }, [dispatch, cacheFormFields]);

  const handleEnviarEmailPrestacao = useCallback(async (idPrestacaoContaCliente) => {
    dispatch(enviarEmailPrestacaoAction(idPrestacaoContaCliente));
  }, [dispatch]);

  const setColumnOrdenacao = useCallback((column) => {
    dispatch(setSimpleValueRelacaoPrestacaoAction('columnOrdenacao', column));
  }, [dispatch]);

  const setArrowDirection = useCallback((internalReturn) => {
    dispatch(setSimpleValueRelacaoPrestacaoAction('arrowDirection', internalReturn));
  }, [dispatch]);

  const onClickColumnOrdenar = useCallback(() => {
    if (relacoesPrestacoes.length > 0) {
      dispatch(setSimpleValueRelacaoPrestacaoAction('page', 0));
      dispatch(buscarRelacoesDoClienteAction());
    }
  }, [dispatch, relacoesPrestacoes]);

  useEffect(() => {
    if (idPrestacaoContaClienteSelecionado) {
      dispatch(buscarInformacoesRelacaoPrestacaoAction());
    }
  }, [idPrestacaoContaClienteSelecionado, dispatch]);

  useEffect(() => {
    setConteudoTabela(factory.makeConteudoTabela(
      utilStyles,
      relacoesPrestacoes,
      permissoes,
      handlePrintPrestacao,
      handleOpenCancelPrestacao,
      handleOpenEstornoPrestacao,
      handleEnviarEmailPrestacao,
      handleOpenMotivoEstornoPrestacao,
      lancamentos,
    ));
  }, [relacoesPrestacoes,
    handlePrintPrestacao,
    handleEnviarEmailPrestacao,
    handleOpenCancelPrestacao,
    handleOpenEstornoPrestacao,
    handleOpenMotivoEstornoPrestacao,
    utilStyles,
    permissoes,
    rowsPerPage,
    page,
    lancamentos]);

  const onChangeRelacaoSelecionadaHandler = useCallback((event, relacaoParam) => {
    dispatch(setCommonAction('idRelacaoSelecionada', relacaoParam.idPrestacao));
    dispatch(setCommonAction('idPrestacaoContaClienteSelecionado', relacaoParam.idPrestacaoContaCliente));
    dispatch(buscarSaldosRelacaoAction(relacaoParam.idPrestacao));
    dispatch(setSimpleValueLancamentoHandlerAction('page', 0));
    dispatch(setSimpleValueNotasFiscaisHandlerAction('openButtonNovaNota', false));
    dispatch(resetFormFieldsNovaNotaAction());
  }, [dispatch]);

  function handleOnClickCancelEstorno() {
    dispatch(setSimpleValueRelacaoPrestacaoAction('openDialogEstornoPrestacao', false));
  }

  function handleOnClickConfirmMotivoEstorno() {
    dispatch(setSimpleValueRelacaoPrestacaoAction('openDialogMotivoEstornoPrestacao', false));
  }

  const handleEstornarPrestacao = useCallback((motivoEstorno) => {
    dispatch(estornarPrestacaoAction(idPrestacaoClicada, motivoEstorno));
  }, [dispatch, idPrestacaoClicada]);

  /**
   * Entra sempre quando selecionar um novo cliente.
   * Reseta os campos para os valores default.
   */
  useEffect(() => {
    // Resetar default nos campos
    dispatch(resetFormFieldsRelacaoPrestacaoAction());
    if (idClienteSelecionado) {
      dispatch(pesquisarPrestacaoInicialAction());
    }
  }, [dispatch, idClienteSelecionado]);

  const handleCancelarPrestacao = useCallback(() => {
    dispatch(cancelarPrestacaoAction(idPrestacaoClicada));
  }, [dispatch, idPrestacaoClicada]);

  const styles = useStyles();
  return (
    <div className={styles.body}>
      <OptionDialogNew
        open={openDialogMotivoEstornoPrestacao}
        confirmLabel="Ok"
        onClickConfirm={handleOnClickConfirmMotivoEstorno}
        text={motivoEstornoPrestacao}
      />
      <DialogNew
        open={openDialogMotivoEstornoPrestacao}
        text="Motivo do Estorno da Prestação"
        className={styles.dialog_motivo_estorno}
      >
        {openDialogMotivoEstornoPrestacao && (
        <DialogMotivoEstornoPrestacao
          textoAcompanhamento={motivoEstornoPrestacao}
          handleOnClickConfirmMotivoEstorno={handleOnClickConfirmMotivoEstorno}
        />
        )}
      </DialogNew>
      <OptionDialogNew
        open={openDialogCancelPrestacao}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={handleOnClickCancelPrestacao}
        onClickConfirm={handleCancelarPrestacao}
        text="Deseja realmente cancelar esta Prestação?"
      />
      <div className={styles.body}>
        <DialogNew
          open={openDialogEstornoPrestacao}
          text="Motivo do Estorno"
          className={styles.body_motivo_estorno}
        >
          {openDialogEstornoPrestacao && (
          <DialogEstornoPrestacao
            handleOnClickCancelEstorno={handleOnClickCancelEstorno}
            handleEstornarPrestacao={handleEstornarPrestacao}
          />
          )}
        </DialogNew>
      </div>

      <header className={styles.header}>
        <section className={styles.header_nome_cliente}>
          <div>
            <b>
              Cliente:
              {' '}
            </b>
            {idClienteSelecionado}
            {idClienteSelecionado !== null ? (' - ') : (' ')}
            {detalhes.nome}
          </div>
        </section>
        <section className={styles.header_filtros_cards}>
          <div className={styles.header_filtro}>
            <div>
              <PeriodField
                label="Período:"
                firstField={formFields?.periodoInicial.copy()}
                lastField={formFields?.periodoFinal.copy()}
                onChange={onChangeFieldsHandler}
                onFocus={onFocusFieldsHandler}
              />
              <div className={styles.switch}>
                <SwitchUI
                  label="Exibir apenas as Prestações a pagar"
                  name="prestacaoAPagar"
                  disabled={disabled}
                  checked={formFields?.prestacaoAPagar.value}
                  onChange={handleChangeChecked}
                />
              </div>
            </div>
            <ButtonPesquisar
              type="button"
              onClick={() => pesquisarHandler()}
              disabled={disabled}
            >
              <SearchIcon />
            </ButtonPesquisar>
          </div>

          <div className={styles.header_card}>
            <div>
              <h3><b>Resumo da Prestação</b></h3>
            </div>
            <div className={styles.cards_primeira_linha}>
              <CardResumo
                title="Totais"
                value={getDetailsCard([
                  {
                    label: 'Honorários',
                    value: saldos.totalHonorarios,
                  },
                  {
                    label: 'Estornos',
                    value: saldos.totalEstornos,
                  }])}
                isDetail
                bgColor="#FF7B51"
                lineColor="#FF521B"
              />
              <CardResumo
                title="Despesas"
                value={getDetailsCard([
                  {
                    label: 'Não Reemb.',
                    value: saldos.totalDespesasNaoReembolsaveis,
                  },
                  {
                    label: 'Reemb.',
                    value: saldos.totalDespesasReembolsaveis,
                  }])}
                isDetail
                bgColor="#F85861"
                lineColor="#E9303B"
              />
              <CardResumo
                title="Valores"
                value={getDetailsCard([
                  {
                    label: 'Créditos',
                    value: saldos.totalCreditos,
                  },
                  {
                    label: 'Pagamentos',
                    value: saldos.totalPagamentos,
                  },
                  {
                    label: 'Acordos',
                    value: saldos.totalAcordos,
                  }])}
                isDetail
                bgColor="#FF449B"
                lineColor="#E9207E"
              />
            </div>
            <div className={styles.cards_segunda_linha}>
              <CardResumo
                title="Saldo Anterior"
                value={saldos.totalSaldoAnterior}
                isDetail={false}
                bgColor="#c54fd9"
              />
              <CardResumo
                title="Saldo"
                value={saldos.totalSaldo}
                isDetail={false}
                bgColor="#3cb6be"
              />
            </div>
          </div>
        </section>
      </header>
      <main />
      <div>
        <TabelaRelacaoPrestacao
          columns={factory.makeCabecalhoTabela(
            columnOrdenacao, setColumnOrdenacao, arrowDirection, setArrowDirection, onClickColumnOrdenar,
          )}
          rows={conteudoTabela}
          relacaoSelecionada={idRelacaoSelecionada}
          onChangeRelacaoSelecionada={onChangeRelacaoSelecionadaHandler}
          page={page}
          rowsPerPage={rowsPerPage}
          size={totalElements}
          rowsPerPageOptions={[10, 15, 20]}
          onChangePage={changePageHandler}
          onChangeRowsPerPage={changeRowsPerPageHandler}
          lancamentos={lancamentos}
        />
      </div>
      <div>
        <div className={styles.titleAccordions}>
          <div>
            <WrapperSubTitleForm title="Detalhamento da Prestação:" />
          </div>
          <div>
            {idRelacaoSelecionada}
          </div>
        </div>
        <TabCardLayoutAccordion
          tabItems={tabItens}
          parentStates={[activeTab, changeTabViewHandler]}
        />
        <div className={styles.border}>
          <TabPanel id={tabItens[3].id} index={tabItens[3].index} value={activeTab}>
            <PagamentosComponent permissoes={permissoes} />
          </TabPanel>
          <TabPanel id={tabItens[0].id} index={tabItens[0].index} value={activeTab}>
            <LancamentosComponent idPrestacaoContaCliente={idPrestacaoContaClienteSelecionado} />
          </TabPanel>
          <TabPanel id={tabItens[1].id} index={tabItens[1].index} value={activeTab}>
            <NotasFiscais permissoes={permissoes} />
          </TabPanel>
          <TabPanel id={tabItens[2].id} index={tabItens[2].index} value={activeTab}>
            <BoletosComponent permissoes={permissoes} />
          </TabPanel>
        </div>
      </div>
    </div>
  );
}

export default RelacaoPrestacao;
