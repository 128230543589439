import {
  CLEAR_STATES,
  ON_FOCUS_REQUEST_FIELDS,
  ON_UPDATE_REQUEST_FIELDS,
  SET_ACTIONS_VALUE,
  SET_ARQUIVOS,
  SET_CACHE_REQUEST_FIELDS,
  SET_PAGE,
  SET_REQUEST_FIELDS,
  SET_ROWS_PER_PAGE,
  SET_SNACKBAR_IMPORTACAO,
  SET_TABLE_INFO,
} from './importarBordero.constans';
import { buildSettingsTable, buildSnackbar } from '../../../helpers/factory/global.factory';
import FieldModel from '../../../models/FieldModel';
import { get7DaysAgo, toAmericanDate, toBrazilianDate } from '../../../helpers/utils/utils';

function buildInitialStates() {
  return {
    arquivos: [],
    cacheRequestFields: null,
    settingsTable: buildSettingsTable(),

    requestFields: {
      idStatus: new FieldModel({
        name: 'idStatus',
        value: 1,
      }),
      dataImportacao: new FieldModel({
        name: 'dataImportacao',
        value: toBrazilianDate(get7DaysAgo()),
      }),
    },
    actions: {
      loading: false,
      error: false,
      errorMessage: '',
      snackbarImportacao: buildSnackbar(),
      openDialog: false,
      clickPesquisar: false,
    },
  };
}

export const onUpdateRequestFieldsAction = requestFields => ({
  type: ON_UPDATE_REQUEST_FIELDS,
  requestFields,
});

export const setCacheRequestFieldsAction = clickPesquisar => ({
  type: SET_CACHE_REQUEST_FIELDS,
  clickPesquisar,
});

export const setPageAction = page => ({
  type: SET_PAGE,
  page,
});

export const setRowsPerPageAndPageAction = (rowsPerPage, page) => ({
  type: SET_ROWS_PER_PAGE,
  rowsPerPage,
  page,
});

export const setActionsValue = (name, value) => ({
  type: SET_ACTIONS_VALUE,
  name,
  value,
});

export const setRequestFieldsAction = (name, value) => ({
  type: SET_REQUEST_FIELDS,
  name,
  value,
});

export const onFocusRequestFieldsAction = name => ({
  type: ON_FOCUS_REQUEST_FIELDS,
  name,
});

function onFocusRequestFieldsHandler(states, actions) {
  const field = states.requestFields[actions.name];
  field.error = false;
  field.errorMessage = '';
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [actions.name]: field,
    },
  };
}

export const setArquivosAction = arquivos => ({
  type: SET_ARQUIVOS,
  arquivos,
});

export const setSnackbarImportacaoAction = (open, variant, message) => ({
  type: SET_SNACKBAR_IMPORTACAO,
  open,
  variant,
  message,
});

export const setTableInfoAction = (content, page, rowsPerPage, totalElements) => ({
  type: SET_TABLE_INFO,
  tableInfo: buildSettingsTable(content, page, rowsPerPage, totalElements),
});


export const clearImportarBorderosAction = () => ({
  type: CLEAR_STATES,
});

function clearImportarBorderoHandler() {
  return buildInitialStates();
}

function onUpdateRequestFieldsHandler(states, actions) {
  const { requestFields } = actions;
  return {
    ...states,
    requestFields,
  };
}

function changeTableInfoHandler(states, actions) {
  return {
    ...states,
    settingsTable: { ...actions.tableInfo },
  };
}

function onChangePageHandler(states, actions) {
  return {
    ...states,
    settingsTable: {
      ...states.settingsTable,
      page: actions.page,
    },
  };
}

function onChangeRowsPerPageAndPageHandler(states, actions) {
  return {
    ...states,
    settingsTable: {
      ...states.settingsTable,
      rowsPerPage: actions.rowsPerPage,
      page: actions.page,
    },
  };
}

function setActionsValueHandler(states, actions) {
  return {
    ...states,
    actions: {
      ...states.actions,
      [actions.name]: actions.value,
    },
  };
}

function setArquivoHandler(states, actions) {
  return {
    ...states,
    arquivos: [...actions.arquivos],
  };
}

function setSnackbarValueHandler(states, actions) {
  const snackbar = { ...states.snackbar, open: actions.open };
  if (actions.variant || actions.message) {
    snackbar.variant = actions.variant;
    snackbar.message = actions.message;
  }
  return {
    ...states,
    actions: {
      ...states.actions,
      snackbarImportacao: snackbar,
    },
  };
}

function setRequestFieldsHandler(states, actions) {
  const model = states.requestFields[actions.name].onChange(actions.value);

  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [actions.name]: model,
    },
  };
}

function setCacheRequestFieldsHandler(states, actions) {
  const idStatus = states.requestFields.idStatus.getValueNotEmpty();
  const dataImportacao = states.requestFields.dataImportacao.getValueNotEmpty();
  return {
    ...states,
    cacheRequestFields: {
      idStatus,
      dataImportacao: dataImportacao ? toAmericanDate(dataImportacao) : dataImportacao,
    },
    actions: {
      ...states.actions,
      clickPesquisar: actions.clickPesquisar,
    },
  };
}

export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    case SET_ACTIONS_VALUE:
      return setActionsValueHandler(states, actions);
    case SET_ARQUIVOS:
      return setArquivoHandler(states, actions);
    case SET_SNACKBAR_IMPORTACAO:
      return setSnackbarValueHandler(states, actions);
    case SET_REQUEST_FIELDS:
      return setRequestFieldsHandler(states, actions);
    case SET_PAGE:
      return onChangePageHandler(states, actions);
    case SET_ROWS_PER_PAGE:
      return onChangeRowsPerPageAndPageHandler(states, actions);
    case SET_CACHE_REQUEST_FIELDS:
      return setCacheRequestFieldsHandler(states, actions);
    case SET_TABLE_INFO:
      return changeTableInfoHandler(states, actions);
    case ON_UPDATE_REQUEST_FIELDS:
      return onUpdateRequestFieldsHandler(states, actions);
    case ON_FOCUS_REQUEST_FIELDS:
      return onFocusRequestFieldsHandler(states, actions);
    case CLEAR_STATES:
      return clearImportarBorderoHandler(states);
    default:
      return states;
  }
};
