import React, {
  useCallback, useEffect, useState, useRef, useMemo,
} from 'react';
import { Grid } from '@material-ui/core';

import { FaEraser } from 'react-icons/fa';
import { MdLibraryBooks, MdLightbulbOutline } from 'react-icons/md';

import { toast } from 'react-toastify';

import { useSelector } from 'react-redux';
import ButtonUI from '../../../components/UI/Button/ButtonUI';
import InputField from '../../../components/UI/Field/Input';
import TextoPadraoDialog from '../TextoPadraoDialog/TextoPadraoDialog';
import EditorTexto from '../../../components/UI/EditorTexto';

import {
  FooterContainer, MainContainer,
} from './AcaoEnviarConsultaClienteStyles';
import {
  ActionHeaderContainer, CustomButton, TextoPadraoButton,
} from '../InspecionarProcessoStyles';

import { acaoEnviarConsultaClienteController as controller } from './acaoEnviarConsultaClienteController';
import { loadingInspecaoProcessoController as loadingController } from '../loadingInspecaoProcessoController';

import { clearErrorField, getFieldName } from '../../../helpers/utils/form.utils';
import { tipoTextoPadraoTypes } from '../../../types/tipoTextoPadraoTypes';
import { Title } from '../../../components/Title/Title';
import { buscarClienteEmailProcessoService } from '../../../services/core/processos/processosService';
import OptionDialogNew from '../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';
import DialogGPT from '../../../components/DialogGPT/DialogGPT';
import TooltipUI from '../../../components/UI/Tooltip/Tooltip';
import { LightIndicator } from '../../../components/Title/Title.styles';

function AcaoEnviarConsultaClienteComponent({ processo, setLoadings, updatePage }) {
  const [showAlertLimpar, setShowAlertLimpar] = useState(false);
  const [showAlertConfirmarEnvio, setShowAlertConfirmarEnvio] = useState(false);
  const [showTextoPadrao, setShowTextoPadrao] = useState(false);
  const [formFields, setFormFields] = useState(controller.makeFormFields());
  const [errorFields, setErrorFields] = useState(controller.makeErrorFields());
  const [textoValue, setTextoValue] = useState('');

  const fasesPermitidasUsuario = useSelector(state => state.authentication.userInformation.fases);
  // Dialog GPT
  const [desabilitarReescrever, setDesabilitarReescrever] = useState(true);
  const [showDialogTest, setShowDialogTest] = useState(false);

  const onChangeTexto = useCallback((event) => {
    const qtdePalavras = event.trim().split(' ').length;
    setTextoValue(event);
    setFormFields(oldValue => ({ ...oldValue, texto: oldValue }));
    setDesabilitarReescrever(qtdePalavras < 5);
  }, []);

  const onFocusTexto = () => {
    setErrorFields(oldValues => ({ ...oldValues, texto: { error: false } }));
  };

  const formRef = useRef();

  const isDisbledLimpar = Object.keys(formFields)
    .filter(key => formFields[key].length > 0).length === 0;

  function limparFormulario() {
    setFormFields(controller.makeFormFields());
    setErrorFields(controller.makeErrorFields());
    setTextoValue('');
    setShowAlertLimpar(false);
  }

  async function onSubmitHandler(e) {
    e.preventDefault();
    setLoadings(loadingController.changeLoadingAction(true));
    const response = await controller.enviarEmailCliente(processo?.id,
      e.target, errorFields, textoValue, fasesPermitidasUsuario);

    if (response.success) {
      updatePage();
      toast.success('Ação realizada com sucesso!');
    }
    setErrorFields(response.errorFields);
    setLoadings(loadingController.changeLoadingAction(false));
  }

  const onChangeHandler = useCallback((e) => {
    const { value } = e.target;
    const name = getFieldName(e.target);
    setFormFields(oldValue => ({ ...oldValue, [name]: value }));
  }, []);


  const onFocusHander = useCallback((e) => {
    setErrorFields(clearErrorField(errorFields, getFieldName(e.target)));
  }, [errorFields]);


  useEffect(() => {
    setLoadings(loadingController.changeLoadingAction(true));
    buscarClienteEmailProcessoService(processo?.id).then((response) => {
      setFormFields(controller.makeFormFields(response?.data));
    }).catch(() => {
      setFormFields({});
    }).finally(() => {
      setLoadings(loadingController.changeLoadingAction(false));
    });
  }, [processo, setLoadings]);

  useEffect(() => {
    if (textoValue === '<p><br></p>') {
      setTextoValue('');
    }
  }, [textoValue]);

  const openMelhorarTextoDialog = () => {
    setShowDialogTest(true);
  };

  const updateDescricaoAtividades = (newText) => {
    setTextoValue(newText);
    setFormFields(oldValue => ({ ...oldValue, texto: newText }));
  };

  const disabledEnviar = useMemo(() => (
    formFields?.emailCliente.trim().length === 0 || formFields?.nomePreposto.trim().length === 0 || formFields?.emailUsuario.trim().length === 0 || textoValue.trim().length === 0
  ), [formFields, textoValue]);


  return (
    <>
      <TextoPadraoDialog
        open={showTextoPadrao}
        tipos={[tipoTextoPadraoTypes.CONSULTACLIENTES.value]}
        setTextoHandler={textoPadrao => setTextoValue(oldValues => (`${oldValues} ${textoPadrao}`))}
        closeDialogHandler={() => setShowTextoPadrao(false)}
      />
      <OptionDialogNew
        open={showAlertLimpar}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickConfirm={limparFormulario}
        onClickCancel={() => setShowAlertLimpar(false)}
        text="Tem certeza de que deseja descartar essas informações? "
      />
      <OptionDialogNew
        open={showAlertConfirmarEnvio}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickConfirm={() => {
          formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
          setShowAlertConfirmarEnvio(false);
        }}
        onClickCancel={() => {
          setShowAlertConfirmarEnvio(false);
        }}
      >
        <div style={{ fontSize: '18px' }}>
          <p>
            ATENÇÃO!
            <br />
            O texto digitado será enviado para o Cliente como consulta.
            <br />
            Confirma o Envio?
          </p>
        </div>
      </OptionDialogNew>
      <form ref={formRef} onSubmit={onSubmitHandler}>
        <ActionHeaderContainer>
          <Title
            text="Envio da Consulta para o Cliente"
            hint="A Consulta ao Cliente será inserida no Acompanhamento do Processo"
          />
          <CustomButton
            disabled={isDisbledLimpar}
            onClick={() => setShowAlertLimpar(true)}
          >
            <FaEraser size={18} />
            <span>Limpar</span>
          </CustomButton>
        </ActionHeaderContainer>
        <MainContainer>
          <Grid container>
            <InputField
              fullWidth
              isForm
              value={formFields.emailCliente}
              label="E-mail(s) do Cliente*"
              name="emailCliente"
              error={errorFields.emailCliente.error}
              errorMessage={errorFields.emailCliente.errorMessage}
              onChange={onChangeHandler}
              onFocus={onFocusHander}
            />
          </Grid>
          <Grid container>
            <InputField
              fullWidth
              isForm
              label="Nome do Contato no Cliente*"
              name="nomePreposto"
              value={formFields.nomePreposto}
              error={errorFields.nomePreposto.error}
              errorMessage={errorFields.nomePreposto.errorMessage}
              onChange={onChangeHandler}
              onFocus={onFocusHander}
            />
          </Grid>
          <Grid container>
            <InputField
              fullWidth
              isForm
              label="Resposta Para*"
              name="emailUsuario"
              value={formFields.emailUsuario}
              error={errorFields.emailUsuario.error}
              errorMessage={errorFields.emailUsuario.errorMessage}
              onChange={onChangeHandler}
              onFocus={onFocusHander}
            />
          </Grid>
          <Grid container>
            <EditorTexto
              placeholder="Insira um Texto"
              readOnly={false}
              name="texto"
              label="Texto*"
              isInputLabel
              value={textoValue}
              onChange={onChangeTexto}
              model={errorFields.texto.error}
              onFocus={onFocusTexto}
              errorMessage={errorFields.texto.errorMessage}
            />
          </Grid>
        </MainContainer>
        <FooterContainer>
          <TextoPadraoButton
            variant="outlined"
            onClick={() => setShowTextoPadrao(true)}
          >
            <MdLibraryBooks />
            <span>Textos Padrão</span>
          </TextoPadraoButton>
          <TextoPadraoButton
            variant="outlined"
            onClick={openMelhorarTextoDialog}
            style={{ marginLeft: '10px' }}
            disabled={desabilitarReescrever}
          >
            <MdLibraryBooks />
            <span>Reescrever</span>
          </TextoPadraoButton>
          <TooltipUI style={{ marginTop: '4px', marginLeft: '8px' }} title="São necessárias no mínimo 5 palavras para reescrever o texto.">
            <LightIndicator><MdLightbulbOutline /></LightIndicator>
          </TooltipUI>
          <DialogGPT
            open={showDialogTest}
            onClose={() => setShowDialogTest(false)}
            initialText={textoValue}
            updateText={updateDescricaoAtividades}
          />

          <ButtonUI
            onClick={() => setShowAlertConfirmarEnvio(true)}
            color="success"
            style={{ width: '136px', marginLeft: 'auto' }}
            disabled={disabledEnviar}
          >
            Enviar
          </ButtonUI>
        </FooterContainer>
      </form>
    </>
  );
}

export const AcaoEnviarConsultaCliente = AcaoEnviarConsultaClienteComponent;
