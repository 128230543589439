import React, { useCallback } from 'react';
import cx from 'classnames';
import { Typography } from '@material-ui/core';
import InputField from '../Input';

import useStyles from './NumberInput.styles';
import MoneyField from '../Money/MoneyField';

function isEmptyValue(output, acceptEmptyValue) {
  return (output === null || output === undefined) || (output === '' && acceptEmptyValue);
}

function isEmptyTrim(output, acceptEmptyValue) {
  const type = typeof output;
  if (type === 'string' && acceptEmptyValue) {
    if (output.trim() === '') {
      return true;
    }
    return false;
  }
  return false;
}

function NumberInput({
  sideText,
  value,
  onChange,
  numberType = 'integer',
  maxValue = 999999999,
  fullWidth = false,
  acceptEmptyValue = false,
  ...props
}) {
  const styles = useStyles();

  const formatOutput = (output) => {
    if (isEmptyValue(output, acceptEmptyValue)) {
      return output;
    }
    try {
      if (isEmptyTrim(output, acceptEmptyValue)) {
        return '';
      }
      return Number(String(output)).toLocaleString('pt-BR', { minimumFractionDigits: 0 });
    } catch (e) {
      return '';
    }
  };

  const changeIntegerValueHandler = (event) => {
    const filteredValue = event.target.value.replace(/[.]/g, '').replace(/[,]/g, '');
    const retunedEvent = event;
    if (filteredValue <= maxValue) {
      try {
        retunedEvent.target.value = isEmptyValue(filteredValue, acceptEmptyValue)
          ? ''
          : Number(filteredValue);
      } catch (e) {
        retunedEvent.target.value = '';
      }
      onChange(retunedEvent);
    }
  };

  const onClickPercentage = useCallback((event) => {
    const end = event.target;
    const len = end.value.length;

    // Mostly for Web Browsers
    if (end.setSelectionRange) {
      end.focus();
      end.setSelectionRange(len, len);
    } else if (end.createTextRange) {
      const t = end.createTextRange();
      t.collapse(true);
      t.moveEnd('character', len);
      t.moveStart('character', len);
      t.select();
    }
  }, []);

  return (
    <div className={cx(styles.numberInput, { [styles.numberInput_fullWidth]: fullWidth })}>
      {numberType === 'integer'
        ? (
          <InputField
            fullWidth={fullWidth}
            value={formatOutput(value)}
            onChange={changeIntegerValueHandler}
            {...props}
          />
        )
        : (
          <MoneyField
            onClick={(numberType === 'percentage' || numberType === 'percantage')
              ? onClickPercentage : null}
            fullWidth={fullWidth}
            showAdornment={false}
            maxValue={maxValue}
            value={value}
            onChange={onChange}
            acceptEmptyValue={acceptEmptyValue}
            {...props}
          />
        )}

      {sideText && <Typography className={styles.numberInput_sideText} component="p">{sideText}</Typography>}
    </div>
  );
}

export default React.memo(NumberInput);
