import { Typography } from '@material-ui/core';
import React from 'react';
import { toBrazilianDate } from '../../helpers';
import { Title } from '../Title/Title';
import ButtonUI from '../UI/Button/ButtonUI';

import { Container } from './ResumoAntecedenteProcesso.styles';

function ResumoAntecedenteProcessoAtualComponent({
  open, title, buttonLabel, onClick, disabledButton, idProcesso, dadosProcesso,
}) {
  const dados = React.useMemo(() => {
    const getCliente = () => {
      if (!!dadosProcesso?.codigoCliente && !!dadosProcesso?.nomeCliente) {
        return `${dadosProcesso?.codigoCliente} - ${dadosProcesso?.nomeCliente}`;
      }
      return '';
    };

    const getDevedor = () => {
      if (!!dadosProcesso?.codigoDevedor && !!dadosProcesso?.nomeDevedor) {
        return `${dadosProcesso?.codigoDevedor} - ${dadosProcesso?.nomeDevedor}`;
      }
      return '';
    };

    return {
      negociador: dadosProcesso?.nomeUsuario || '',
      cliente: getCliente(),
      devedor: getDevedor(),
      dataAbertura: toBrazilianDate(dadosProcesso?.dataAbertura),
      dataExpiracao: toBrazilianDate(dadosProcesso?.dataExpiracao),
    };
  }, [
    dadosProcesso?.nomeUsuario,
    dadosProcesso?.codigoCliente,
    dadosProcesso?.nomeCliente,
    dadosProcesso?.codigoDevedor,
    dadosProcesso?.nomeDevedor,
    dadosProcesso?.dataAbertura,
    dadosProcesso?.dataExpiracao,
  ]);

  return (
    <Container open={open}>
      <div>
        <Title
          text={title.text}
          hint={title.hint}
        />

        <Typography>
          <b>Processo: </b>
          {idProcesso}
        </Typography>
        <Typography>
          <b>Negociador: </b>
          {dados.negociador}
        </Typography>
        <Typography>
          <b>Cliente: </b>
          {dados.cliente}
        </Typography>
        <Typography>
          <b>Devedor: </b>
          {dados.devedor}
        </Typography>
        <Typography>
          <b>Data da Abertura: </b>
          {dados.dataAbertura}
        </Typography>
        <Typography>
          <b>Data da Expiração: </b>
          {dados.dataExpiracao}
        </Typography>
      </div>
      <div>
        <ButtonUI
          color="success"
          onClick={onClick}
          disabled={disabledButton}
        >
          {buttonLabel}
        </ButtonUI>
      </div>
    </Container>
  );
}

export const ResumoAntecedenteProcessoAtual = ResumoAntecedenteProcessoAtualComponent;
