import React from 'react';
import cx from 'classnames';
import {
  CircularProgress, FormControl, Input, InputLabel,
} from '@material-ui/core';
import { CheckCircle, Error } from '@material-ui/icons';
import TooltipUI from '../../Tooltip/Tooltip';
import useStyles from './customInputField.styles';

function CustomInputField({
  label,
  disabled = false,
  isForm = false,
  loading = false,
  error = false,
  errorMessage = '',
  maxLength = 150,
  fullWidth = false,
  labelProps,
  tooltipProps,
  inputProps,
  formProps,
  statusPromisse = '',
  ...props
}) {
  const styles = useStyles();

  function getEndAdornment() {
    if (loading) {
      return <CircularProgress size={isForm ? 15 : 16} />;
    }
    if (statusPromisse === 'sucess' && !disabled) {
      return <CheckCircle className={styles.iconCheckedAdornment} />;
    }
    if (statusPromisse === 'fail') {
      return <Error className={styles.iconErrorAdornment} />;
    }
    return null;
  }

  function buildInputGroup() {
    return (
      <>
        {label && <InputLabel error={error} {...labelProps}>{label}</InputLabel>}
        <Input
          error={error}
          disabled={disabled || loading}
          endAdornment={getEndAdornment()}
          inputProps={{
            maxLength,
            ...inputProps,
          }}
          {...props}
        />
      </>
    );
  }

  function buildOutlinedGroup() {
    return (
      <FormControl
        {...formProps}
        error={error}
        fullWidth={fullWidth}
        className={cx(styles.outlinedRoot, { [styles.loading]: loading })}
      >
        {buildInputGroup()}
      </FormControl>
    );
  }

  function buildFormInput() {
    return (
      <div
        {...formProps}
        className={
          cx(styles.formRoot, { [styles.loading]: loading, [styles.fullWidth]: fullWidth })
        }
      >
        {buildInputGroup()}
      </div>
    );
  }

  function buildField() {
    const content = isForm ? buildFormInput() : buildOutlinedGroup();
    if (error) {
      return (
        <TooltipUI error title={errorMessage} placement="bottom-start" {...tooltipProps}>
          {content}
        </TooltipUI>
      );
    }
    return content;
  }


  return buildField();
}

export default React.memo(CustomInputField);
