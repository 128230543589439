import styled from 'styled-components';
import { Button } from '@material-ui/core';
import SubTitleForm from '../../../components/UI/SubTitleForm/SubTitleForm';
import DialogNew from '../../../layouts/FormDialog/DialogNew';

export const FormContainer = styled(DialogNew)`
  & .MuiDialog-paper {
    width: 585px;
  }

  & form >div + div {
    margin-top: 16px;
  }

  & .MuiCheckbox-root {
    margin-left: 8px;
    margin-right: 8px;
  }

  & .MuiFormControlLabel-root {
    color: #707070;
  }
`;

export const ButtonDocumentoCliente = styled(Button)`
&.MuiButton-root {
    color: #034ddc;

    &.Mui-disabled {
      color: #000000;
      opacity: 0.26;
    }
  }
`;

export const TitleFormDivider = styled(SubTitleForm)`
  margin: 16px auto;
`;

export const FormFooterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  & button + button {
    margin-right: 8px;
  }

  & :first-child {
    width: 152px;
  }

  & :nth-child(2) {
    width: 100px;
  }
`;
