import React from 'react';
import { useDispatch } from 'react-redux';
import { initializePageAction } from '../../../../store/theme.actions';
import CrudCliente from '../../../../components/Cliente/Crud/CrudCliente';
import withTokenInterceptor from '../../../../hoc/withTokenInterceptor/withTokenInterceptor';
import CrudClienteProvider from '../../../../store/Cliente/V2/Crud/CrudClienteProvider';

function DetalharClientePage() {
  const dispatch = useDispatch();

  dispatch(initializePageAction('Clientes > Dados Cadastrais'));
  return (
    <CrudClienteProvider>
      <CrudCliente isInpectMode />
    </CrudClienteProvider>
  );
}

export default withTokenInterceptor(DetalharClientePage);
