import React from 'react';
import cx from 'classnames';
import lampadaIcon from './lampada-icon.png';
import useStyles from './dica.styles';
import TooltipUI from '../Tooltip/Tooltip';

function Dica({
  mensagemDica = '',
  hide = false,
  onlyIcon = false,
}) {
  const styles = useStyles();

  const rootClass = cx(styles.root, {
    [styles.display__none]: hide,
  });


  let content;

  if (onlyIcon) {
    content = (
      <div className={rootClass}>
        <img src={lampadaIcon} alt="Dica" />
      </div>
    );
  } else {
    content = (
      <div className={rootClass}>
        <TooltipUI title={mensagemDica}>
          <img src={lampadaIcon} alt="Dica" />
        </TooltipUI>
      </div>
    );
  }

  return content;
}

export default Dica;
