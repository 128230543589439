import React, { useCallback } from 'react';
import {
  useCrudClienteDispatch,
  useCrudClienteSelector,
} from '../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import useUtilStyles from '../../../../../helpers/styles/utils.styles';
import Emails from '../../../../UI/EmailsV2/Emails';
import {
  onChangeEmail,
  onFocusEmails,
  onUpdateControlsEmails,
} from '../../../../../store/Cliente/V2/Crud/crudCliente.store';

export default function EmailsCrudCliente({ isEditMode }) {
  const utilStyles = useUtilStyles();

  const dispatch = useCrudClienteDispatch();
  const emails = useCrudClienteSelector(states => states.controls.emails);

  const onUpdateEmails = useCallback((emailsParam) => {
    dispatch(onUpdateControlsEmails(emailsParam));
  }, [dispatch]);

  const onChangeEmailsHandler = useCallback((index, name, value) => {
    dispatch(onChangeEmail(index, name, value));
  }, [dispatch]);

  const onFocusEmailHandler = useCallback((index, name, model) => {
    dispatch(onFocusEmails(index, name, model));
  }, [dispatch]);

  return (
    <Emails
      className={utilStyles.mt16}
      disabled={!isEditMode}
      emails={emails}
      onChange={onChangeEmailsHandler}
      onFocus={onFocusEmailHandler}
      onUpdateAll={onUpdateEmails}
      requiredFields={{
        email: isEditMode,
      }}
    />
  );
}
