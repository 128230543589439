import { makeErrorField } from '../../../helpers/utils/form.utils';
import { toastUnmappedException } from '../../../helpers/utils/toastUtils';
import { buscarEmailProcessoByIdProcessoAndTipoDestinatarioService } from '../../../services/core/processos/processosEmails';

import store from '../../../store/createStore';

class EnviarEmailDocumentoProcessoController {
  makeFormFields(email, idsProcessoDocumento) {
    const emailUsuario = store.getState()?.authentication?.userInformation?.email;

    return {
      tipoDestinatario: 'EMAIL_CLIENTE',
      email: email || '',
      assunto: '',
      copia: emailUsuario || '',
      corpoEmail: '',
      idProcessoDocumento: idsProcessoDocumento || [],
    };
  }

  makeErrorFields() {
    return {
      email: makeErrorField(),
      assunto: makeErrorField(),
      copia: makeErrorField(),
      corpoEmail: makeErrorField(),
    };
  }

  makePayload(idProcesso, formFields) {
    return {
      idProcesso,
      tipoDestinatario: formFields.tipoDestinatario || null,
      email: formFields.email || null,
      assunto: formFields.assunto || null,
      copia: formFields.copia || null,
      corpoEmail: formFields.corpoEmail || null,
      idProcessoDocumento: formFields.idProcessoDocumento || [],
    };
  }

  async buscarEmails(idProcesso, tipoDestinatario) {
    try {
      if (tipoDestinatario !== 'EMAIL_COLABORADOR_ABE') {
        const emailsResponse = await buscarEmailProcessoByIdProcessoAndTipoDestinatarioService(idProcesso, tipoDestinatario);
        return emailsResponse.data;
      }
    } catch (err) {
      toastUnmappedException(err, 'Ocorreu um problema ao buscar os e-mails para envio');
    }
    return '';
  }
}

export const enviarEmailDocumentoProcessoController = new EnviarEmailDocumentoProcessoController();
