import React, { useCallback } from 'react';
import cx from 'classnames';

import { buildSimpleEmail } from '../../../helpers/factory/email.factory';

import useStyles from './SimpleEmails.styles';
import useUtilStyles from '../../../helpers/styles/utils.styles';
import SimpleEmail from './SimpleEmail/SimpleEmail';
import AddActionTitleWrapper from '../Wrapper/AddActionTitle';
import SubTitleForm from '../SubTitleForm/SubTitleForm';

const constants = {
  TITLE_READ: 'E-mails',
  TITLE_EDIT: 'Adicionar E-mail',
  TOOLTIP: 'Adicionar E-mail',
};

export default function SimpleEmails({
  className = '',
  emails = [],
  disabled = false,
  readMode = false,
  setFocus,
  setAttribute,
  setEmails,
  showTrashAtFirstItem = false,
  requiredFields = {},
  haveAdd = true,
  subtitle = '',
  isCorrigir = false,
}) {
  const utilStyles = useUtilStyles();
  const styles = useStyles();


  const addEmailHandler = () => {
    const modifiedEmails = [...emails];
    const email = buildSimpleEmail();
    if (modifiedEmails.length === 0) {
      email.principal.onChange(true);
    }
    modifiedEmails.push(email);
    setEmails(modifiedEmails);
  };

  const removeTelefoneHandler = (index) => {
    const modifiedEmails = [...emails];
    const removedItem = modifiedEmails.splice(index, 1);
    if (removedItem[0].principal.value === true && modifiedEmails.length > 0) {
      modifiedEmails[0].principal.value = true;
    }
    setEmails(modifiedEmails);
  };

  const getEmailsWithPrincipalValueFalse = useCallback(() => emails.map((email) => {
    const modifiedTelefone = { ...email };
    modifiedTelefone.principal.onChange(false);
    return modifiedTelefone;
  }), [emails]);

  const showTrashAtFirstItemHandler = (index) => {
    if (showTrashAtFirstItem) {
      return index !== 0;
    }
    if (isCorrigir) {
      return false;
    }
    return true;
  };

  const buildEmailComponent = (email, index) => (
    <div key={index}>
      <SimpleEmail
        hasTrash={showTrashAtFirstItemHandler(index)}
        index={index}
        disabled={disabled}
        model={email}
        setAttribute={setAttribute}
        setEmails={setEmails}
        setFocus={setFocus}
        emailsWithPrincipalFalse={getEmailsWithPrincipalValueFalse}
        requiredFields={requiredFields}
        onRemove={() => removeTelefoneHandler(index)}
      />
      {index !== emails.length - 1 && (
        <hr className={cx(utilStyles.dividerEmail, { [styles.dividerEmail]: showTrashAtFirstItemHandler(index) })} />
      )}
    </div>
  );

  return (
    <>
      {haveAdd ? (
        <AddActionTitleWrapper
          title={constants.TITLE_EDIT}
          readTitle={constants.TITLE_READ}
          disabled={disabled}
          addHandler={addEmailHandler}
          tooltip={constants.TOOLTIP}
          readMode={readMode}
          className={className}
        >
          <div className={styles.wrapper}>
            {emails.map(buildEmailComponent)}
          </div>
        </AddActionTitleWrapper>
      ) : (
        <div className={styles.wrapper}>
          <div className={utilStyles.mb12}><SubTitleForm>{subtitle}</SubTitleForm></div>

          {emails.map(buildEmailComponent)}
        </div>
      )}
    </>
  );
}
