import React from 'react';
import FormWrapperWithTitle from '../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import Grid24 from '../../../../UI/Grid24/Grid24';
import {
  GERAL_TITLE,
  PRAZO_LIMITE_TITULO, PRAZO_MAX_PAGAMENTO_PROCESSO,
  VALOR_MIN_PROCESSO,

} from './TabCrudParametroBaseCalculo.constants';
import MoneyField from '../../../../UI/Field/Money/MoneyField';
import { useCrudParametrosSelector } from '../../../../../store/Parametros/Crud/CrudParametrosProvider';
import CustomFieldView from '../../../../UI/CustomFields/CustomFieldView';
import CustomFieldTitle from '../../../../UI/CustomFields/CustomFieldTitle';
import ParametroHonorarios from './Honorarios/ParametroHonorarios';
import ParametroComissoes from './Comissoes/ParametroComissoes';
import { getRequiredLabel } from '../../../../../helpers/utils/utils';
import NumberInput from '../../../../UI/Field/Number/NumberInput';
import FaixasDeJurosEhRepasseCrudParametros from './FaixasDeJuros/FaixasDeJurosEhRepasseCrudParametros';

function TabCrudParametrosBaseCalculo({ isInsertMode, onChange, onFocus }) {
  const prazoLimiteTitulo = useCrudParametrosSelector(state => state.requestFields.prazoLimiteTitulo.copy());
  const valorMinimoProcesso = useCrudParametrosSelector(state => state.requestFields.valorMinimoProcesso.copy());
  const prazoMaximoPagtoSemAcrescimo = useCrudParametrosSelector(state => state.requestFields.prazoMaximoPagtoSemAcrescimo.copy());
  const valorLimiteFaseEletronica = useCrudParametrosSelector(state => state.requestFields.valorLimiteFaseEletronica.copy());

  return (
    <>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={24}>
          <FormWrapperWithTitle title={GERAL_TITLE}>
            <Grid24 xs={24} sm={4}>
              <Grid24 xs={24} sm={24}>
                <CustomFieldTitle textField={getRequiredLabel(PRAZO_LIMITE_TITULO, isInsertMode)} />
              </Grid24>
              <Grid24 xs={24} sm={17}>
                <NumberInput
                  isForm
                  fullWidth
                  numberType="integer"
                  maxValue={999999999}
                  disabled={!isInsertMode}
                  name={prazoLimiteTitulo.name}
                  value={prazoLimiteTitulo.value}
                  error={prazoLimiteTitulo.error}
                  errorMessage={prazoLimiteTitulo.errorMessage}
                  maxLength={9}
                  onChange={onChange}
                  onFocus={onFocus}
                />
              </Grid24>
              <Grid24 xs={24} sm={2}>
                <CustomFieldView textField="(dias)" />
              </Grid24>
            </Grid24>
            <Grid24 xs={24} sm={4}>
              <Grid24 xs={24} sm={24}>
                <CustomFieldTitle textField={getRequiredLabel(VALOR_MIN_PROCESSO, isInsertMode)} />
              </Grid24>
              <Grid24 xs={24} sm={17}>
                <MoneyField
                  fullWidth
                  isForm
                  disabled={!isInsertMode}
                  name={valorMinimoProcesso.name}
                  value={valorMinimoProcesso.value}
                  error={valorMinimoProcesso.error}
                  errorMessage={valorMinimoProcesso.errorMessage}
                  onChange={onChange}
                  onFocus={onFocus}
                />
              </Grid24>
            </Grid24>
            <Grid24 xs={24} sm={4}>
              <Grid24 xs={24} sm={24}>
                <CustomFieldTitle textField={getRequiredLabel('Valor Limite para Fase Eletrônica', isInsertMode)} />
              </Grid24>
              <Grid24 xs={24} sm={17}>
                <MoneyField
                  fullWidth
                  isForm
                  disabled={!isInsertMode}
                  name={valorLimiteFaseEletronica.name}
                  value={valorLimiteFaseEletronica.value}
                  error={valorLimiteFaseEletronica.error}
                  errorMessage={valorLimiteFaseEletronica.errorMessage}
                  onChange={onChange}
                  onFocus={onFocus}
                />
              </Grid24>
            </Grid24>
            <Grid24 xs={24} sm={9}>
              <Grid24 xs={24} sm={24}>
                <CustomFieldTitle textField={getRequiredLabel(PRAZO_MAX_PAGAMENTO_PROCESSO, isInsertMode)} />
              </Grid24>
              <Grid24 xs={24} sm={11}>
                <NumberInput
                  isForm
                  fullWidth
                  numberType="integer"
                  maxValue={99}
                  disabled={!isInsertMode}
                  sideText="(dias)"
                  name={prazoMaximoPagtoSemAcrescimo.name}
                  value={prazoMaximoPagtoSemAcrescimo.value}
                  error={prazoMaximoPagtoSemAcrescimo.error}
                  errorMessage={prazoMaximoPagtoSemAcrescimo.errorMessage}
                  maxLength={9}
                  onChange={onChange}
                  onFocus={onFocus}
                />
              </Grid24>
            </Grid24>
          </FormWrapperWithTitle>
        </Grid24>
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={24}>
          <ParametroHonorarios isInsertMode={isInsertMode} />
        </Grid24>
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={24}>
          <ParametroComissoes isInsertMode={isInsertMode} />
        </Grid24>
      </Grid24>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={24}>
          <FaixasDeJurosEhRepasseCrudParametros isInsertMode={isInsertMode} />
        </Grid24>
      </Grid24>
    </>
  );
}

export default TabCrudParametrosBaseCalculo;
