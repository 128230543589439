import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Telefones from '../../../../UI/TelefonesV2/Telefones';

import useUtilStyles from '../../../../../helpers/styles/utils.styles';
import { findTiposTelefoneAction } from '../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import { useCrudUsuarioDispatch2, useCrudUsuarioSelector2 } from '../../../../../store/Usuario/V2/CrudUsuarioProvider';
import {
  setTelefoneAttributeFocusAction,
  setTelefoneAttributeValueAction, setTelefonesAction,
} from '../../../../../store/Usuario/V2/crudUsuario.store';


function TelefonesCrudUsuario({ isInspectMode, isInsertMode, isEditMode }) {
  const utilStyles = useUtilStyles();

  const dispatch = useCrudUsuarioDispatch2();
  const globalDispatch = useDispatch();

  const telefones = useCrudUsuarioSelector2(states => states.controls.telefones);
  const tiposTelefone = useSelector(states => states.selectors.tiposTelefone.selector);

  // Busca os tipos dos telefones
  useEffect(() => {
    globalDispatch(findTiposTelefoneAction());
  }, [globalDispatch]);

  const setTelefoneAttributeValueHandler = useCallback((name, value, index) => {
    dispatch(setTelefoneAttributeValueAction(name, value, index));
  }, [dispatch]);

  const setTelefoneAttributeFocusHandler = useCallback((name, index) => {
    dispatch(setTelefoneAttributeFocusAction(name, index));
  }, [dispatch]);


  const setTelefonesHandler = useCallback((telefonesParam) => {
    dispatch(setTelefonesAction(telefonesParam));
  }, [dispatch]);

  return (
    <Telefones
      className={utilStyles.mt16}
      disabled={isInspectMode}
      readMode={isInspectMode}
      telefones={telefones}
      tiposTelefone={tiposTelefone}
      setAttribute={setTelefoneAttributeValueHandler}
      setFocus={setTelefoneAttributeFocusHandler}
      setTelefones={setTelefonesHandler}
      requiredFields={{
        tipoTelefone: isInsertMode || isEditMode,
        numero: isInsertMode || isEditMode,
      }}
    />
  );
}

export default TelefonesCrudUsuario;
