import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MdPrint as MdPrintIcon } from 'react-icons/md';
import {
  Button,
  RadioGroup, TableContainer, TableRow, Tooltip,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import cx from 'classnames';
import {
  InputField, Loading, SelectorField, TableDefault,
} from '../../../components';
import { TableHeaderContaCorrenteCliente } from '../../../components/TabelaHeader/TableHeaderContaCorrenteCliente';
import {
  getMoneyMask, getNewPage, insertIntoArray, TableUtils, toastUnmappedException, openPageFile,
  useUtilStyles,
} from '../../../helpers';
import { ContaCorrenteClienteController, ContaCorrenteClienteFactory } from '../ContaCorrenteClienteController';
import { TableCellCenter, TableCellLeft, TableCellRight } from '../../../components/UI/Tabela/tableDefaultStyles';
import { findFiliaisAction } from '../../../store/Global/LoadSelectors/loadSelectors.saga';
import RadioField from '../../../components/UI/Field/Radio/RadioField';
import { LockOpenIcon } from '../../../assets/Icons/AttachFileIcon/lockOpen';
import { FaLockIcon } from '../../Comissionado/ContaCorrenteComissionadoStyles';
import { getExceptionHandler } from '../../../helpers/utils/exception.util';
import useStyle, {
  ButtonPesquisaAvancada,
  ButtonPesquisar,
  TableHeader, ButtonImprimir, CustomTableActionButtomCliente,
  FaLockIconGrey,
  CardPreviaPrestacao,
  DateFieldPrestacao,
  Switch,
  ContainerPesquisa,
  FiltroPesquisaSimples,
  ContainerPesquisaAvancada,
} from '../contaCorrenteClienteStyles';
import TooltipUI from '../../../components/UI/Tooltip/Tooltip';
import FieldModel from '../../../models/FieldModel';

function makeLoading() {
  return {
    pesquisarCliente: false,
    tiposDeLancamentos: false,
    responsaveis: false,
    download: false,
  };
}


const statusPrestacao = {
  DESBLOQUEADO: {
    icon: <LockOpenIcon size={15} />,
    tooltip: 'Bloquear prestação de contas',
    type: 'normal',
    isCustom: false,
  },
  SUSPENSO_INATIVO: {
    icon: <FaLockIconGrey />,
    tooltip: 'Cliente Inativo, Prestação Suspensa ou sem Lançamentos',
    type: 'custom',
    isCustom: true,
  },
  BLOQUEIO_TEMPORARIO: {
    icon: <FaLockIcon />,
    tooltip: 'Desbloquear prestação de contas',
    type: 'custom',
    isCustom: true,
  },
};


function ContaCorrentePesquisaCliente({
  idClienteSelecionado,
  setIdClienteSelecionado,
  setCanPrestarTodos,
  idsContasCorrenteClientes,
  setIdsContasCorrenteClientes,
  tableView,
  setTableView,
  cacheFields,
  pageable,
  configureStatesScreenOnSuccess,
}) {
  const globalDispatch = useDispatch();
  const style = useStyle();
  const utilStyle = useUtilStyles();

  const filiais = useSelector(states => states.selectors.filiais.selector);
  const [page, setPage] = pageable?.pageState;
  const [rowsPerPage, setRowsPerPage] = pageable?.rowsState;

  const [loading, setLoading] = React.useState(makeLoading());
  const [errorsField, setErrorsField] = React.useState(ContaCorrenteClienteFactory.makeErrorFields());
  const [tiposLancamentosItems, setTiposLancamentosItems] = React.useState([]);
  const [responsaveisItems, setResponsaveisItems] = React.useState([]);

  const [formFields, setFormFields] = useState(ContaCorrenteClienteFactory.buildInitialStatesForm());
  const [openPesquisaAvancada, setOpenPesquisaAvancada] = useState(false);
  const [valorPrevia, setValorPrevia] = useState(null);
  const [mostrarSoDesbloqueados, setMostrarSoDesbloqueados] = useState(true);
  const [columnOrdenacao, setColumnOrdenacao] = useState({
    element: 'nomeCliente',
    order: 'ASC',
  });


  const onFocusHandler = useCallback((event) => {
    const { name } = event.target;
    setErrorsField({ ...formFields, [name]: { error: false, errorMessage: '' } });
  }, [formFields]);

  const onFocusDataPrestacaoHandler = useCallback(() => {
    setErrorsField(old => ({
      ...old,
      dataPrestacao: { error: false, errorMessage: '' },
    }));
  }, []);


  const onChangeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    setFormFields(old => ({ ...old, [name]: old[name].onChange(value) }));
  }, []);

  const setExternalDataPrestacaoError = useCallback((dateFieldInternalError) => {
    const { error, message } = dateFieldInternalError;
    setErrorsField(old => ({
      ...old,
      dataPrestacao: { error, errorMessage: message },
    }));
  }, []);

  const resetAdvancedFieldsValues = useCallback(() => {
    const fields = { ...formFields };
    fields.numeroBoleto = new FieldModel({ name: 'numeroBoleto', value: null });
    fields.idFilial = new FieldModel({ name: 'idFilial', value: null });
    fields.idUsuarioResponsavel = new FieldModel({ name: 'idUsuarioResponsavel', value: null });
    fields.numeroBoleto = new FieldModel({ name: 'numeroBoleto', value: null });
    fields.tipoLancamento = new FieldModel({ name: 'tipoLancamento', value: null });

    setFormFields(fields);
  }, [formFields]);


  const onClickPesquisaAvancadaAcordoHandler = useCallback(() => {
    setOpenPesquisaAvancada((old) => {
      resetAdvancedFieldsValues();
      return !old;
    });
  }, [resetAdvancedFieldsValues]);

  const buttonClassPesquisaAvancada = cx({
    [style.pesquisaAvancadaButton]: !openPesquisaAvancada,
    [style.pesquisaSimplesButton]: openPesquisaAvancada,
  });

  const findAllTiposDeLancamentos = React.useCallback(async () => {
    setLoading(oldLoading => ({ ...oldLoading, tiposDeLancamentos: true }));
    try {
      const response = await ContaCorrenteClienteController.findAllTiposDeLancamentos();
      setTiposLancamentosItems(response.tiposDeLancamentos);
    } catch (err) {
      toastUnmappedException(err, 'Ocorreu um problema ao tentar consultar os nomes dos Tipos de Lançamentos');
      setTiposLancamentosItems([]);
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, tiposDeLancamentos: false }));
    }
  }, []);

  const findAllResponsaveis = React.useCallback(async () => {
    setLoading(oldLoading => ({ ...oldLoading, responsaveis: true }));
    try {
      const response = await ContaCorrenteClienteController.findAllResponsaveis();
      setResponsaveisItems(response.responsaveis);
    } catch (err) {
      toastUnmappedException(err, 'Ocorreu um problema ao tentar consultar os nomes dos Responsáveis');
      setResponsaveisItems([]);
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, responsaveis: false }));
    }
  }, []);


  const getFormDataValue = (formData, name) => (formData.get(name) !== '' ? formData.get(name) : null);

  const onClickPesquisar = React.useCallback(async (event) => {
    setLoading(oldLoading => ({ ...oldLoading, pesquisarCliente: true }));
    setIdClienteSelecionado(null);
    setTableView([]);
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const payload = {
      idCliente: getFormDataValue(formData, 'idCliente'),
      dataPrestacao: getFormDataValue(formData, 'dataPrestacao'),
      exibirApenasDesbloqueados: mostrarSoDesbloqueados,
      idFilial: getFormDataValue(formData, 'idFilial'),
      idUsuarioResponsavel: getFormDataValue(formData, 'idUsuarioResponsavel'),
      numeroBoleto: getFormDataValue(formData, 'numeroBoleto'),
      tipoLancamento: getFormDataValue(formData, 'tipoLancamento'),
    };

    try {
      const contasPesquisadas = await ContaCorrenteClienteController.pesquisarContaCorrenteCliente(payload);
      const makeContas = ContaCorrenteClienteFactory.makeTodasContasCorrentes(contasPesquisadas);
      configureStatesScreenOnSuccess(makeContas, payload, rowsPerPage, 0);
      if (makeContas.length === 0) {
        toast.warning('Nenhum resultado foi encontrado relacionado à sua pesquisa');
        setIdClienteSelecionado(null);
      }
    } catch (e) {
      const [updateErrorFields] = await getExceptionHandler(e, formFields);
      setErrorsField({ ...updateErrorFields });
      setTableView([]);
      setIdsContasCorrenteClientes([]);
      setIdClienteSelecionado(null);
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, pesquisarCliente: false }));
    }
  }, [configureStatesScreenOnSuccess, mostrarSoDesbloqueados, setIdClienteSelecionado, formFields, setIdsContasCorrenteClientes, setTableView, rowsPerPage]);


  function handleChangePage(e, newPage) {
    const viewPaginada = TableUtils.paginar(idsContasCorrenteClientes, rowsPerPage, newPage);
    setTableView(viewPaginada);
    setIdClienteSelecionado({
      idCliente: viewPaginada[0].idCliente,
      desbloqueado: viewPaginada[0].statusPrestacaoContas === 'DESBLOQUEADO',
    });
    setPage(newPage);
  }


  function handleRowsPerPageChange(e) {
    const size = e.target.value;
    const newPage = getNewPage(rowsPerPage, page, size);
    if (idsContasCorrenteClientes?.length > 0) {
      setTableView(TableUtils.paginar(idsContasCorrenteClientes, size, newPage));
    }
    setRowsPerPage(size);
    setPage(newPage);
  }

  const handlePrintClientes = useCallback(async () => {
    try {
      const payload = {
        ...cacheFields,
        exibirApenasDesbloqueados: mostrarSoDesbloqueados,
      };
      setLoading(oldLoading => ({ ...oldLoading, download: true }));
      const httpResponse = await ContaCorrenteClienteController.downloadRelatorioClientes(payload, idsContasCorrenteClientes, columnOrdenacao);
      if (httpResponse.data.size > 0) {
        openPageFile(httpResponse.data, httpResponse.data.type);
      }
    } catch (err) {
      toastUnmappedException(
        err,
        'Ocorreu um problema ao tentar abrir o relatório',
      );
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, download: false }));
    }
  }, [cacheFields, columnOrdenacao, idsContasCorrenteClientes, mostrarSoDesbloqueados]);

  const onChangeClienteSelecionado = useCallback((event, idCliente, status) => {
    setIdClienteSelecionado({
      idCliente,
      desbloqueado: status === 'DESBLOQUEADO',
    });
  }, [setIdClienteSelecionado]);


  const onClickBloquearDesbloquearCliente = useCallback((event, idCliente, idxAlterado, statusAtual) => {
    if (statusAtual !== 'SUSPENSO_INATIVO') {
      const allIds = [...idsContasCorrenteClientes];
      const tableViewAux = [...tableView];
      // Bloquear ou desbloquear o que estou vendo na tabela.
      let contaCorrenteAlterar = tableViewAux.find(conta => conta.idCliente === idCliente);

      contaCorrenteAlterar = {
        ...contaCorrenteAlterar,
        statusPrestacaoContas: (statusAtual === 'BLOQUEADO' || statusAtual === 'BLOQUEIO_TEMPORARIO') ? 'DESBLOQUEADO' : 'BLOQUEIO_TEMPORARIO',
      };

      const viewNaoDuplicada = tableViewAux.filter(conta => conta.idCliente !== contaCorrenteAlterar.idCliente);
      const newView = insertIntoArray(viewNaoDuplicada, idxAlterado, contaCorrenteAlterar);

      if (page === 0) {
        setTableView(TableUtils.paginar(newView, rowsPerPage, page));
      } else {
        setTableView(newView);
      }

      // Fazer essa alteração no array que tem todos os elementos também (para que se mantenha na mudança de página)

      const idxSelecionado = allIds.findIndex(ids => ids.idCliente === idCliente);
      allIds[idxSelecionado] = {
        ...allIds[idxSelecionado],
        statusPrestacaoContas: contaCorrenteAlterar.statusPrestacaoContas,
        valorRepasse: contaCorrenteAlterar.valorRepasse,
      };
      setIdsContasCorrenteClientes(allIds);
      setIdClienteSelecionado({
        idCliente,
        desbloqueado: contaCorrenteAlterar.statusPrestacaoContas === 'DESBLOQUEADO',
      });
    }
  }, [idsContasCorrenteClientes, tableView, rowsPerPage, page, setIdClienteSelecionado, setIdsContasCorrenteClientes, setTableView]);


  useEffect(() => {
    findAllTiposDeLancamentos().then();
  }, [findAllTiposDeLancamentos]);

  useEffect(() => {
    findAllResponsaveis().then();
  }, [findAllResponsaveis]);

  useEffect(() => {
    globalDispatch(findFiliaisAction());
  }, [globalDispatch]);

  // Soma da prévia só de quem é desbloqueado.
  useEffect(() => {
    if (idsContasCorrenteClientes) {
      const valorSomatoriaPrevia = idsContasCorrenteClientes.filter(conta => conta.statusPrestacaoContas === 'DESBLOQUEADO').reduce((actual, next) => actual + next.valorRepasseWithoutMask, 0);
      setValorPrevia(valorSomatoriaPrevia);
    }
  }, [idsContasCorrenteClientes]);

  useEffect(() => {
    if (idsContasCorrenteClientes) {
      setCanPrestarTodos(idsContasCorrenteClientes.filter(conta => conta.statusPrestacaoContas === 'DESBLOQUEADO').length > 0);
    }
  }, [idsContasCorrenteClientes, setCanPrestarTodos]);

  const onClickMostrarDesbloqueado = useCallback(async (e) => {
    const { checked } = e.target;
    setMostrarSoDesbloqueados(checked);

    if (idsContasCorrenteClientes?.length !== undefined) {
      const payload = {
        ...cacheFields,
        exibirApenasDesbloqueados: checked,

      };

      setLoading(oldLoading => ({ ...oldLoading, pesquisarCliente: true }));
      try {
        const contasPesquisadas = await ContaCorrenteClienteController.pesquisarContaCorrenteCliente(payload);
        const makeContas = ContaCorrenteClienteFactory.makeTodasContasCorrentes(contasPesquisadas);
        configureStatesScreenOnSuccess(makeContas, payload, rowsPerPage, 0);
      } catch {
        setTableView([]);
        setIdsContasCorrenteClientes([]);
      } finally {
        setLoading(oldLoading => ({ ...oldLoading, pesquisarCliente: false }));
      }
    }
  }, [cacheFields, configureStatesScreenOnSuccess, idsContasCorrenteClientes?.length, setIdsContasCorrenteClientes, setTableView, rowsPerPage]);


  const getDisabledPesquisarButton = () => {
    const {
      dataPrestacao, idCliente, idFilial, idUsuarioResponsavel, numeroBoleto, tipoLancamento,
    } = formFields;
    if (openPesquisaAvancada) {
      return !(dataPrestacao.value || idCliente.value || idFilial.value || idUsuarioResponsavel.value || numeroBoleto.value || tipoLancamento.value);
    }
    return !(dataPrestacao.value || idCliente.value);
  };

  function RowsTableBodyComponent({
    content,
  }) {
    return content.map((item, idx) => (
      <TableRow key={item.idCliente}>
        <TableCellCenter>
          <RadioField
            value={item.idCliente}
            label=""
            onClick={e => onChangeClienteSelecionado(e, item.idCliente, item.statusPrestacaoContas)}
          />
        </TableCellCenter>
        <TableCellCenter>
          {item.idCliente}
        </TableCellCenter>
        <TableCellLeft>
          <TooltipUI title={item.nomeClienteAlt}>
            <span>{item?.nomeCliente}</span>
          </TooltipUI>
        </TableCellLeft>
        <TableCellCenter>
          {item.tipoPrestacao}
        </TableCellCenter>
        <TableCellRight>
          {item.valorRepasse}
        </TableCellRight>
        <TableCellCenter>
          {!!statusPrestacao && (
            <Tooltip title={statusPrestacao[item.statusPrestacaoContas]?.tooltip} placement="bottom">
              <CustomTableActionButtomCliente
                iscustom={statusPrestacao[item.statusPrestacaoContas]?.isCustom ? 1 : 0}
                iscadeadocinza={item.statusPrestacaoContas === 'SUSPENSO_INATIVO' ? 1 : 0}
                isdesbloqueado={(item.statusPrestacaoContas === 'DESBLOQUEADO' ? 1 : 0)}
                onClick={e => onClickBloquearDesbloquearCliente(e, item.idCliente, idx, item.statusPrestacaoContas)}
              >
                <span>
                  {statusPrestacao[item.statusPrestacaoContas]?.icon}
                </span>
              </CustomTableActionButtomCliente>
            </Tooltip>
          )}
        </TableCellCenter>
      </TableRow>
    ));
  }

  return (
    <ContainerPesquisa>
      <Loading
        show={loading.tiposDeLancamentos || loading.responsaveis || loading.pesquisarCliente || loading.download}
      />
      <form id="form-conta-corrente-cliente" onSubmit={onClickPesquisar}>
        <FiltroPesquisaSimples>
          <div>
            <InputField
              fullWidth
              name="idCliente"
              label="Código"
              value={formFields?.idCliente?.value}
              error={errorsField.idCliente.error}
              errorMessage={errorsField.idCliente.errorMessage}
              onChange={onChangeFieldsHandler}
              onFocus={onFocusHandler}
            />
          </div>
          <div>
            <p>Dia da Prestação</p>
            <div>
              <DateFieldPrestacao
                name="dataPrestacao"
                value={formFields?.dataPrestacao?.value}
                error={errorsField.dataPrestacao.error}
                errorMessage={errorsField.dataPrestacao.errorMessage}
                setExternalError={setExternalDataPrestacaoError}
                onFocus={onFocusDataPrestacaoHandler}
                onChange={onChangeFieldsHandler}
              />
            </div>
          </div>
          <ButtonPesquisar
            type="submit"
            disabled={getDisabledPesquisarButton()}
          >
            <SearchIcon />
          </ButtonPesquisar>
        </FiltroPesquisaSimples>
        {openPesquisaAvancada && (
          <ContainerPesquisaAvancada>
            <div>
              <div id="campoFilial">
                <SelectorField
                  fullWidth
                  hasEmptyLabel
                  name="idFilial"
                  label="Filial"
                  items={filiais}
                  onChange={onChangeFieldsHandler}
                />
              </div>
              <InputField
                fullWidth
                name="numeroBoleto"
                label="Nosso Número do boleto"
                onChange={onChangeFieldsHandler}
              />
            </div>
            <div>
              <SelectorField
                hasEmptyLabel
                label="Responsável"
                items={responsaveisItems}
                name="idUsuarioResponsavel"
                onChange={onChangeFieldsHandler}
              />
            </div>
            <SelectorField
              hasEmptyLabel
              items={tiposLancamentosItems}
              label="Tipo do Lançamento"
              name="tipoLancamento"
              onChange={onChangeFieldsHandler}
            />
          </ContainerPesquisaAvancada>
        )}
        <ButtonPesquisaAvancada>
          <div>
            <Button
              fullWidth
              className={buttonClassPesquisaAvancada}
              onClick={onClickPesquisaAvancadaAcordoHandler}
            >
              {openPesquisaAvancada ? 'Pesquisa Simples' : 'Pesquisa Avançada'}
            </Button>
          </div>
        </ButtonPesquisaAvancada>

      </form>
      <Switch
        name="mostrarSoDesbloqueados"
        label="Exibir apenas clientes com lançamentos desbloqueados"
        checked={mostrarSoDesbloqueados}
        onChange={onClickMostrarDesbloqueado}
        marginTop="8px"
      />
      <div>
        <ButtonImprimir
          disabled={idsContasCorrenteClientes?.length === undefined || idsContasCorrenteClientes?.length === 0}
          onClick={handlePrintClientes}
        >
          <MdPrintIcon size={16} />
          <span>Imprimir</span>
        </ButtonImprimir>
      </div>
      <CardPreviaPrestacao>
        <div>
          <p>Prévia do Total de Repasses</p>
          <p>{getMoneyMask(valorPrevia) || '0,00'}</p>
        </div>
      </CardPreviaPrestacao>
      <TableContainer>
        <div className={utilStyle.padding22}>
          <TableHeader>
            Resultado da Pesquisa
          </TableHeader>
          <RadioGroup
            key={tableView?.idCliente}
            value={idClienteSelecionado?.idCliente}
          >
            <div>
              <TableDefault
                columnsHead={(
                  <TableHeaderContaCorrenteCliente
                    allItems={idsContasCorrenteClientes}
                    setTableView={setTableView}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    columnOrdenacao={columnOrdenacao}
                    setColumnOrdenacao={setColumnOrdenacao}
                    idClienteSelecionado={idClienteSelecionado?.idCliente}
                    setClienteSelecionado={setIdClienteSelecionado}
                  />
                )}
                page={page}
                rowsPerPage={rowsPerPage}
                totalElements={idsContasCorrenteClientes?.length || 0}
                totalElementsOnPage={tableView?.length}
                totalColumns={6}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleRowsPerPageChange}
                emptyRowsHeight={33}
                rowsPerPageOptions={[10, 15, 20]}
              >
                {tableView?.length > 0 ? (
                  <RowsTableBodyComponent
                    content={tableView}
                  />
                ) : []}
              </TableDefault>
            </div>
          </RadioGroup>
        </div>
      </TableContainer>
    </ContainerPesquisa>
  );
}

export const ContaCorrentePesquisaClienteComponent = ContaCorrentePesquisaCliente;
