import React, { useCallback } from 'react';
import cx from 'classnames';

import Grid24 from '../../Grid24';
import CheckboxField from '../../Field/Checkbox/CheckboxField';
import InputField from '../../Field/Input';
import SelectorField from '../../Field/Selector';
import IconButtonTooltip from '../../IconButtonTooltip/IconButtonTooltip';

import { getRequiredLabel, getAttributeExistingObject } from '../../../../helpers/utils/utils';

import useStyles from './Telefone.styles';
import MaskField from '../../Field/Mask';
import { CELPHONE_MASK, RESIDENCIAL_MASK, SERVICO_MASK } from '../../../../helpers/constants/layout.constants';


export function buildLayoutConstants(requiredFields) {
  return {
    TIPO_TELEFONE: getRequiredLabel('Tipo Telefone', getAttributeExistingObject(requiredFields, 'tipoTelefone', false)),
    DDD: getRequiredLabel('DDD', getAttributeExistingObject(requiredFields, 'ddd', false)),
    NUMERO: getRequiredLabel('Telefone', getAttributeExistingObject(requiredFields, 'numero', false)),
    OBSERVACAO: getRequiredLabel('Observação', getAttributeExistingObject(requiredFields, 'observacao', false)),
    PRINCIPAL: 'Principal',
    WHATSAPP: 'WhatsApp',
    TOOLTIP_TRASH: 'Excluir Telefone',
  };
}

function Telefone({
  showOnlyWhatsapp = false,
  hasTrash = false,
  index,
  disabled = false,
  tiposTelefone = [],
  model,
  setAttribute,
  setTelefones,
  setFocus,
  telefonesWithPrincipalFalse,
  telefonesWithWhatsAppFalse,
  requiredFields = {},
  onRemove,
  validaWhatsApp,
}) {
  const styles = useStyles();
  const constants = buildLayoutConstants(requiredFields);

  const showTrashHandler = () => !disabled && hasTrash;

  const getValue = (value, checked, type) => ((type === 'checkbox') ? checked : value);

  const changeAttributeHandler = useCallback((event) => {
    const {
      name, value, checked, type,
    } = event.target;
    setAttribute(name, getValue(value, checked, type), index);
  }, [setAttribute, index]);

  const focusAttributeHandler = useCallback((event) => {
    let fieldName = event.target.name;

    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    setFocus(fieldName, index);
  }, [setFocus, index]);

  const choosePrincipalHandler = useCallback((event) => {
    const { name } = event.target;
    const enderecos = telefonesWithPrincipalFalse();
    enderecos[index][name].onChange(true);
    setTelefones(enderecos);
  }, [setTelefones, telefonesWithPrincipalFalse, index]);

  const chooseWhatsAppHandler = useCallback((event) => {
    const { name } = event.target;
    const enderecos = telefonesWithWhatsAppFalse();
    enderecos[index][name].onChange(true);
    setTelefones(enderecos);
  }, [setTelefones, telefonesWithWhatsAppFalse, index]);

  const maskTelefone = () => {
    if (model.tipoTelefone.value === 'CELULAR') {
      return CELPHONE_MASK;
    }
    if (model.tipoTelefone.value === 'RESIDENCIAL') {
      return RESIDENCIAL_MASK;
    }
    if (model.tipoTelefone.value === 'COMERCIAL') {
      if (model.numero?.value?.startsWith('0')) {
        return SERVICO_MASK;
      }
      if (model.numero?.value?.startsWith('1')
        || model.numero?.value?.startsWith('2')
        || model.numero?.value?.startsWith('3')
        || model.numero?.value?.startsWith('4')
        || model.numero?.value?.startsWith('5')) {
        return RESIDENCIAL_MASK;
      }
      if (model.numero?.value?.startsWith('6')
        || model.numero?.value?.startsWith('7')
        || model.numero?.value?.startsWith('8')
        || model.numero?.value?.startsWith('9')) {
        return CELPHONE_MASK;
      }
    }
    return null;
  };

  return (
    <Grid24 container spacing={1}>
      <Grid24 xs={24} sm={3}>
        <SelectorField
          isForm
          fullWidth
          disabled={disabled}
          label={constants.TIPO_TELEFONE}
          items={tiposTelefone}
          name={model.tipoTelefone.name}
          value={model.tipoTelefone.value}
          error={model.tipoTelefone.error}
          errorMessage={model.tipoTelefone.errorMessage}
          onChange={changeAttributeHandler}
          onFocus={focusAttributeHandler}
        />
      </Grid24>
      <Grid24 xs={24} sm={1}>
        <InputField
          isForm
          fullWidth
          disabled={disabled}
          label={constants.DDD}
          error={model.ddd.error}
          name={model.ddd.name}
          value={model.ddd.value}
          errorMessage={model.ddd.errorMessage}
          maxLength={model.ddd.maxLength}
          onChange={changeAttributeHandler}
          onFocus={focusAttributeHandler}
        />
      </Grid24>
      <Grid24 xs={24} sm={3}>
        <MaskField
          isForm
          fullWidth
          disabled={disabled}
          label={constants.NUMERO}
          error={model.numero.error}
          name={model.numero.name}
          value={model.numero.value}
          errorMessage={model.numero.errorMessage}
          maxLength={14}
          onChange={changeAttributeHandler}
          onFocus={focusAttributeHandler}
          format={maskTelefone()}
        />
      </Grid24>
      <Grid24 xs={24} sm={6}>
        <InputField
          isForm
          fullWidth
          disabled={disabled}
          label={constants.OBSERVACAO}
          error={model.observacao.error}
          name={model.observacao.name}
          value={model.observacao.value}
          errorMessage={model.observacao.errorMessage}
          maxLength={model.observacao.maxLength}
          onChange={changeAttributeHandler}
          onFocus={focusAttributeHandler}
        />
      </Grid24>
      <Grid24
        xs={24}
        sm={11}
        container
        className={
          cx({
            [styles.actions]: !showTrashHandler(),
            [styles.actionWithRemoveButton]: showTrashHandler(),
          })}
      >
        {!showOnlyWhatsapp && (
          <Grid24 xs={8} sm={4}>
            <CheckboxField
              label={constants.PRINCIPAL}
              disabled={disabled}
              name={model.principal.name}
              checked={model.principal.value}
              onChange={choosePrincipalHandler}
            />
          </Grid24>
        )}
        <Grid24 xs={8} sm={4}>
          <CheckboxField
            label={constants.WHATSAPP}
            disabled={disabled}
            name={model.permitirWhatsApp.name}
            checked={model.permitirWhatsApp.value}
            onChange={validaWhatsApp ? chooseWhatsAppHandler : changeAttributeHandler}
          />
        </Grid24>
        {showTrashHandler() && (
          <Grid24 xs={8} sm={3}>
            <IconButtonTooltip
              type="trash"
              disabled={disabled}
              hint={constants.TOOLTIP_TRASH}
              onClick={onRemove}
            />
          </Grid24>
        )}
      </Grid24>
    </Grid24>
  );
}

export default React.memo(Telefone);
