import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SnackbarUI from '../../../components/UI/Snackbar/Snackbar';

import SearchLayout from '../../../layouts/SimpleSearchLayout/SimpleSearchLayout';
import TableHeader from '../../../models/TableHeader';
import LoadingUI from '../../../components/UI/Loading/LoadingUI';
import CrudTituloCompromisso from '../Crud/CrudTituloCompromissoPage';
import { initializePageAction } from '../../../store/theme.actions';
import constants from '../../../helpers/constants/navigationTitle.constants';
import { checkPermission, getNewPage } from '../../../helpers/utils/utils';
import withTokenInterceptor from '../../../hoc/withTokenInterceptor/withTokenInterceptor';
import { ERROR, SEARCH_TABLE_TITLE, SNACKBAR_DURATION } from '../../../helpers/constants/global.constants';
import AlertDialog from '../../../components/UI/Dialogs/AlertDialog/AlertDialog';
import { BoldContentDialog } from '../../../components/UI/Dialogs/Content/Bold/BoldContentDialog';
import OptionDialogNew from '../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';
import { useTitulosCompromissoDispatch, useTitulosCompromissoSelector } from '../../../store/TituloCompromisso/TituloCompromissoProvider';
import {
  excluirTituloCompromissoAction, buscarTodosTituloCompromissoAction, buscarTituloCompromissoAction,
} from '../../../store/TituloCompromisso/Pesquisa/pesquisaTituloCompromisso.saga';
import {
  onAcaoCrudAction,
  onAssignPesquisaCopyAction,
  onChangePageAction,
  onChangeRowsPerPageAction,
  onDigitarPesquisaAction,
  onIdTipoTituloDeCompromissoDeleteAction,
  onIdTipoTituloDeCompromissoEditAction,
  onNomeTituloCompromissoEditAction,
  onOpenCrudDialogAction,
  onOpenDeleteTituloCompromissoAction,
  onOpenDialogTituloCompromissoAction,
  onSetActions,
  onSucessCadastroAction,
  onTituloCompromissoMessageDeleteAction,
} from '../../../store/TituloCompromisso/Pesquisa/pesquisaTituloCompromisso.store';
import { clearControlsAction } from '../../../store/TituloCompromisso/Crud/crudTituloCompromisso.store';
import { CADASTRADO_COM_SUCESSO, CADASTRO_ACTION, EDITAR_ACTION } from './PesquisarTituloCompromisso.constants';
import { buildRows } from '../../../services/core/tituloCompromisso/TituloCompromisso.factory';

const columns = [
  new TableHeader({ id: 'nome', label: 'Título de Compromisso', minWidth: 815 }),
  new TableHeader({
    id: 'acoes', label: 'Ações', alignCell: 'center', maxWidth: 121,
  }),
];

function PesquisaTituloCompromissoPage({ history }) {
  const dispatch = useTitulosCompromissoDispatch();

  dispatch(initializePageAction(`${constants.TITULOS_COMPROMISSO}`));

  const userData = useSelector(state => state.authentication.userInformation);
  const loading = useTitulosCompromissoSelector(states => states.pesquisaTituloCompromisso.actions.loading);
  const openSnackBar = useTitulosCompromissoSelector(states => states.pesquisaTituloCompromisso.actions.openSnackBar);
  const removendo = useTitulosCompromissoSelector(states => states.pesquisaTituloCompromisso.actions.removendo);
  const fail = useTitulosCompromissoSelector(states => states.pesquisaTituloCompromisso.actions.fail);
  const sucessCadastro = useTitulosCompromissoSelector(states => states.pesquisaTituloCompromisso.actions.sucessCadastro);
  const openDialog = useTitulosCompromissoSelector(states => states.pesquisaTituloCompromisso.actions.openDialog);
  const openDelete = useTitulosCompromissoSelector(states => states.pesquisaTituloCompromisso.actions.openDelete);
  const openCrudDialog = useTitulosCompromissoSelector(states => states.pesquisaTituloCompromisso.actions.openCrudDialog);
  const shouldUpdate = useTitulosCompromissoSelector(states => states.pesquisaTituloCompromisso.actions.shouldUpdateContent);
  const idTipoTituloDeCompromisso = useTitulosCompromissoSelector(states => states.pesquisaTituloCompromisso.controls.idTipoTituloDeCompromisso);
  const nomeTituloCompromisso = useTitulosCompromissoSelector(states => states.pesquisaTituloCompromisso.controls.nomeTituloCompromisso);
  const contentError = useTitulosCompromissoSelector(states => states.pesquisaTituloCompromisso.controls.contentError);
  const acaoCrud = useTitulosCompromissoSelector(states => states.pesquisaTituloCompromisso.controls.acaoCrud);
  const tituloCompromissoDeleteMsg = useTitulosCompromissoSelector(states => states.pesquisaTituloCompromisso.controls.tituloCompromissoDeleteMsg);
  const tableContent = useTitulosCompromissoSelector(states => states.pesquisaTituloCompromisso.controls.tableContent);
  const tablePageable = useTitulosCompromissoSelector(states => states.pesquisaTituloCompromisso.controls.tablePageable);
  const fieldPesquisa = useTitulosCompromissoSelector(
    states => states.pesquisaTituloCompromisso.controls.pesquisa.fieldPesquisa,
  );
  const [tableRows, setTableRows] = useState([]);
  const { permissoes } = userData;

  const clickEditAcoesHandler = useCallback(
    (idTipoTituloDeCompromissoParam, nomeTituloCompromissoParam) => {
      dispatch(onIdTipoTituloDeCompromissoEditAction(idTipoTituloDeCompromissoParam));
      dispatch(onNomeTituloCompromissoEditAction(nomeTituloCompromissoParam));
      dispatch(onAcaoCrudAction(EDITAR_ACTION));
      dispatch(onOpenCrudDialogAction(true));
    },
    [dispatch],
  );

  const clickRemoverHandler = useCallback((idTipoTituloDeCompromissoDeleteParam, nome) => {
    dispatch(onOpenDeleteTituloCompromissoAction(true));
    dispatch(onSetActions(false, false, true, fail));
    dispatch(onIdTipoTituloDeCompromissoDeleteAction(idTipoTituloDeCompromissoDeleteParam));
    dispatch(onTituloCompromissoMessageDeleteAction(
      `Deseja excluir o Título de Compromisso ${nome}? Ao confirmar, o Título de Compromisso será excluído permanentemente`,
    ));
  }, [dispatch, fail]);

  // Construção da tabela
  useEffect(() => {
    setTableRows(buildRows(tableContent, clickEditAcoesHandler, clickRemoverHandler));
  }, [tableContent, clickEditAcoesHandler, clickRemoverHandler]);

  // Atualização da tabela depois de alguma ação
  useEffect(() => {
    if (shouldUpdate) {
      dispatch(buscarTodosTituloCompromissoAction(tablePageable.page, tablePageable.rowsPerPage));
    }
  }, [shouldUpdate, tablePageable.page, tablePageable.rowsPerPage, dispatch]);

  const closeDialogHandler = useCallback(() => {
    dispatch(onOpenDeleteTituloCompromissoAction(false));
  }, [dispatch]);

  const onChangePesquisaHandler = (event) => {
    dispatch(onDigitarPesquisaAction(event.target.value));
  };

  const onChangeRowsPerPageHandler = useCallback(
    async (event) => {
      const { value } = event.target;
      const { rowsPerPage, page } = tablePageable;
      const newPage = getNewPage(rowsPerPage, page, value);

      if (tableContent.length > 0) {
        dispatch(buscarTodosTituloCompromissoAction(newPage, value));
      }
      dispatch(onChangeRowsPerPageAction(parseInt(value, 10)));
      dispatch(onChangePageAction(newPage));
    },
    [tablePageable, dispatch, tableContent.length],
  );

  const onChangePageHandler = useCallback(
    (event, newPage) => {
      if (fieldPesquisa.getValue() !== '') {
        dispatch(buscarTituloCompromissoAction(fieldPesquisa.getValue(), newPage, tablePageable.rowsPerPage));
      } else {
        dispatch(buscarTodosTituloCompromissoAction(newPage, tablePageable.rowsPerPage));
      }
    },
    [dispatch, tablePageable, fieldPesquisa],
  );


  const onCloseSuccessSnackbarHandler = () => {
    dispatch(onSucessCadastroAction(false));
  };

  const onClickRemoveTituloCompromisso = () => {
    dispatch(onSetActions(false, openSnackBar, removendo, fail));
    dispatch(excluirTituloCompromissoAction(idTipoTituloDeCompromisso));
    closeDialogHandler();
  };

  const onClickButtonNovoHandler = useCallback(() => {
    dispatch(onNomeTituloCompromissoEditAction(''));
    dispatch(onIdTipoTituloDeCompromissoEditAction(null));
    dispatch(onOpenCrudDialogAction(true));
    dispatch(clearControlsAction());
    dispatch(onAcaoCrudAction(CADASTRO_ACTION));
  }, [dispatch]);

  function closeDialog() {
    dispatch(onSetActions(false, openSnackBar, true, fail));
    dispatch(onOpenDialogTituloCompromissoAction(false));
  }

  const fechaDialogCrud = useCallback(() => {
    dispatch(onOpenCrudDialogAction(false));
    dispatch(onNomeTituloCompromissoEditAction(''));
    dispatch(onIdTipoTituloDeCompromissoEditAction(null));
    dispatch(clearControlsAction());
  }, [dispatch]);

  const onClickPesquisaHandler = (event) => {
    event.preventDefault();
    if (fieldPesquisa.getValue() !== '') {
      dispatch(buscarTituloCompromissoAction(fieldPesquisa.getValue(), 0, tablePageable.rowsPerPage));
    } else {
      dispatch(onAssignPesquisaCopyAction((fieldPesquisa.getValue())));
      dispatch(buscarTodosTituloCompromissoAction(0, tablePageable.rowsPerPage));
    }
  };
  return (
    <>
      <LoadingUI show={loading} />
      <SnackbarUI
        open={sucessCadastro}
        autoHideDuration={SNACKBAR_DURATION}
        variant="success"
        message={CADASTRADO_COM_SUCESSO}
        onClose={onCloseSuccessSnackbarHandler}
      />
      <OptionDialogNew
        open={openDelete}
        onClickCancel={closeDialogHandler}
        onClickConfirm={() => onClickRemoveTituloCompromisso()}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>
            {tituloCompromissoDeleteMsg}
          </p>
        </div>
      </OptionDialogNew>

      <AlertDialog
        open={openDialog}
        variant={ERROR}
        onClickConfirm={closeDialog}
      >
        <BoldContentDialog fullyMessage={contentError} />
      </AlertDialog>
      {openCrudDialog && (
        <CrudTituloCompromisso
          open={openCrudDialog}
          idTipoTituloDeCompromisso={idTipoTituloDeCompromisso}
          nomeTituloCompromisso={nomeTituloCompromisso}
          onClose={fechaDialogCrud}
          acao={acaoCrud}
        />
      )}
      <SearchLayout
        history={history}
        allowedNovo={checkPermission(permissoes, 2201)}
        onClickButtonNovo={onClickButtonNovoHandler}
        tableColumns={columns}
        tableRows={tableRows}
        tablePageable={tablePageable}
        searchFieldLabel="Nome"
        tituloTabela={SEARCH_TABLE_TITLE}
        fieldPesquisa={fieldPesquisa}
        onChangePesquisa={onChangePesquisaHandler}
        onClickPesquisa={onClickPesquisaHandler}
        onChangePage={onChangePageHandler}
        onChangeRowsPerPage={onChangeRowsPerPageHandler}
      />
    </>
  );
}

export default withTokenInterceptor(withRouter(PesquisaTituloCompromissoPage));
