import React, { useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';

import AlertDialog from '../../UI/Dialogs/AlertDialog/AlertDialog';
import PaperUI from '../../UI/Paper/PaperUI';
import CardWrapper from '../../UI/CardWrapper/CardWrapper';
import IdentificacaoCrudFilial from './Identificacao/IdentificacaoCrudFilial';
import EnderecoCrudFilial from './Endereco/EnderecoCrudFilial';
import TelefoneCrudFilial from './Telefone/TelefoneCrudFilial';
import FooterForm from '../../UI/FooterForm/FooterForm';
import CampoObrigatorio from '../../UI/CampoObrigatorio/CampoObrigatorio';
import SnackbarUI from '../../UI/Snackbar/Snackbar';

import { insertFilialAction, findFilialByIdAction, updateFilialAction } from '../../../store/Filial/Crud/crudFilial.saga';
import {
  setSnackbarValuesAction, setAlertDialogValuesAction,
} from '../../../store/Filial/Crud/crudFilial.store';
import { useCrudFilialSelector, useCrudFilialDispatch } from '../../../store/Filial/Crud/CrudFilialProvider';

import { moveScroll } from '../../../helpers/utils/utils';
import { SNACKBAR_DURATION } from '../../../helpers/constants/global.constants';

function isLoading(loadings) {
  const itemsNotLoaded = loadings.filter(item => item === true);
  return itemsNotLoaded.length > 0;
}


function CrudFilial({
  history, match, isUpdateMode = false, isQueryMode = false,
}) {
  const dispatch = useCrudFilialDispatch();

  const snackbar = useCrudFilialSelector(states => states.snackbar);
  const alertDialog = useCrudFilialSelector(states => states.alertDialog);
  const goToBackPage = useCrudFilialSelector(states => states.goToBackPage);
  const submitLoading = useCrudFilialSelector(states => states.loading);

  const loadings = useSelector(states => [
    states.selectors.usuarios.loading,
    states.selectors.centrosCusto.loading,
    states.selectors.tiposTelefone.loading,
    states.selectors.estados.loading,
  ]);

  const isAllowedToFindUsuario = useCallback(() => (isQueryMode || isUpdateMode) && match.params.id, [isQueryMode, isUpdateMode, match.params.id]);

  useEffect(() => {
    moveScroll();
  }, []);

  useEffect(() => {
    if (isAllowedToFindUsuario()) {
      dispatch(findFilialByIdAction(match.params.id));
    }
  }, [dispatch, isAllowedToFindUsuario, isQueryMode, isUpdateMode, match.params.id]);

  useEffect(() => {
    if (goToBackPage) {
      history.replace('/filial');
    }
  }, [goToBackPage, history]);

  const goBackPageHandler = () => {
    history.replace('/filial');
  };

  const submitHandler = () => {
    if (isUpdateMode) {
      dispatch(updateFilialAction());
    } else {
      dispatch(insertFilialAction());
    }
  };

  const closeSnackbarHandler = () => {
    dispatch(setSnackbarValuesAction(false));
  };

  const closeAlertDialogHandler = () => {
    dispatch(setAlertDialogValuesAction(false));
    if (alertDialog.origin === 'FILIAL_NOT_FOUND') {
      history.replace('/filial');
    }
  };

  return (
    <>
      <AlertDialog
        open={alertDialog.open}
        variant={alertDialog.variant}
        onClickConfirm={closeAlertDialogHandler}
      >
        <Typography style={{ textAlign: 'center' }} component="p">{alertDialog.message}</Typography>
      </AlertDialog>
      <SnackbarUI
        open={snackbar.open}
        variant={snackbar.variant}
        message={snackbar.message}
        autoHideDuration={SNACKBAR_DURATION}
        onClose={closeSnackbarHandler}
      />
      <PaperUI>
        <CardWrapper showLoading={isLoading([...loadings, submitLoading])}>
          {!isQueryMode && <CampoObrigatorio />}
          <IdentificacaoCrudFilial isQueryMode={isQueryMode} />
          <EnderecoCrudFilial isQueryMode={isQueryMode} />
          <TelefoneCrudFilial isQueryMode={isQueryMode} />
          {/* <FinanceiroFilial isQueryMode={isQueryMode} /> */}
        </CardWrapper>
        <FooterForm
          hasRollback={isQueryMode}
          cancelHandler={goBackPageHandler}
          submitHandler={submitHandler}
          rollbackHandler={goBackPageHandler}
        />
      </PaperUI>
    </>
  );
}

export default withRouter(CrudFilial);
