import styled from 'styled-components';
import ButtonUI from '../../UI/Button/ButtonUI';
import {Typography} from "@material-ui/core";

export const ButtonNewRequisicao = styled(ButtonUI)`
  width: 152px;
  margin-right: 0;
  align-self: flex-end;
  margin-bottom: 16px;
  margin-top: 16px;
`;


export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentDialog = styled(Typography)`
  text-align: center;
`;
