import { getUriParams } from '../../../helpers';
import {
  CORE, gateway, RELATORIOS,
} from '../../api';

export default {
  findSaldos(
    period,
    exibirLancamentosNaoConsolidados,
    exibirLancamentosConsolidados,
    exibirLancamentosDeConsolidacao,
  ) {
    const body = {
      dataInicial: period?.startDate,
      dataFinal: period?.endDate,
      exibirLancamentosNaoConsolidados,
      exibirLancamentosConsolidados,
      exibirLancamentosDeConsolidacao,
    };
    return gateway.post(
      `${CORE}/conta-corrente-honorario/resumo-periodo-honorario`,
      body,
    );
  },

  findAllLancamentos(
    period,
    exibirLancamentosNaoConsolidados,
    exibirLancamentosConsolidados,
    exibirLancamentosDeConsolidacao,
    pageable,
    order,
  ) {
    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    const queryParam = getUriParams(pageable);
    const body = {
      dataInicial: period?.startDate,
      dataFinal: period?.endDate,
      exibirLancamentosNaoConsolidados,
      exibirLancamentosConsolidados,
      exibirLancamentosDeConsolidacao,
    };
    return gateway.post(
      `${CORE}/conta-corrente-honorario/honorarios?${queryParam}${sortedQuery}`,
      body,
    );
  },
  downloadExtratoLancamento(
    period,
    exibirLancamentosNaoConsolidados,
    exibirLancamentosConsolidados,
    exibirLancamentosDeConsolidacao,
    order,
  ) {
    const sortedQuery = order.reduce((obj, sort) => `${obj}&sort=${sort}`, '');
    const body = {
      dataInicial: period?.startDate,
      dataFinal: period?.endDate,
      exibirLancamentosNaoConsolidados,
      exibirLancamentosConsolidados,
      exibirLancamentosDeConsolidacao,
    };
    return gateway.post(
      `${RELATORIOS}/conta-corrente-honorario/relatorio-conta-corrente?${sortedQuery}`,
      body,
      {
        responseType: 'blob',
      },
    );
  },
  consolidarLancamento() {
    return gateway.put(`${CORE}/conta-corrente-honorario/consolidar-honorarios`);
  },
};
