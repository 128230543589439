import {
  buscarLancamentoCaixaFilial,
  buscarTiposLancamentosService,
  buscarTiposOrigemLancamentosService,
  editarLancamentoService,
  novoLancamentoService,
} from '../../services/core/lancamento/lancamento.service';
import SelectorModel from '../../models/SelectorModel';
import {
  getExceptionErrorMessage, getExceptionMessage, toBrazilianDate, updateFieldsWithErrors,
} from '../../helpers';

class LancamentoFinanceiro {
  makeFormFields(values) {
    return {
      nomeComprovante: values?.nomeComprovante || '',
      dataLancamento: toBrazilianDate(values?.dataLancamento) || '',
      descricaoLancamento: values?.descricaoLancamento || '',
      idLancamentoCaixaFilial: values?.idLancamentoCaixaFilial || '',
      naoPossuiComprovante: values?.naoPossuiComprovante || false,
      idTipoOrigemCaixaFilial: values?.idTipoOrigemCaixaFilial || '',
      valorLancamento: +values?.valorLancamento || '',
      motivoAusenciaComprovante: values?.motivoAusenciaComprovante || '',
    };
  }

  makeErrors(errors) {
    return {
      dataLancamento: {
        error: errors?.dataLancamento?.error || false,
        errorMessage: errors?.dataLancamento?.errorMessage || '',
      },
      idLancamentoCaixaFilial: {
        error: errors?.idLancamentoCaixaFilial?.error || false,
        errorMessage: errors?.idLancamentoCaixaFilial?.errorMessage || '',
      },
      valorLancamento: {
        error: errors?.valorLancamento?.error || false,
        errorMessage: errors?.valorLancamento?.errorMessage || '',
      },
      idTipoOrigemCaixaFilial: {
        error: errors?.idTipoOrigemCaixaFilial?.error || false,
        errorMessage: errors?.idTipoOrigemCaixaFilial?.errorMessage || '',
      },
      nomeComprovante: {
        error: errors?.nomeComprovante?.error || false,
        errorMessage: errors?.nomeComprovante?.errorMessage || '',
      },
      motivoAusenciaComprovante: {
        error: errors?.motivoAusenciaComprovante?.error || false,
        errorMessage: errors?.motivoAusenciaComprovante?.errorMessage || '',
      },
    };
  }


  makeItemsLancamento(itens, hasPermissaoGerenciar = false) {
    if (hasPermissaoGerenciar) {
      return itens.map(opcao => new SelectorModel(
        opcao.idLancamentoCaixaFilial,
        opcao.lancamento,
        opcao.tipoLancamentoCaixaFilial,
      ));
    }
    return itens.filter(item => item.tipoLancamentoCaixaFilial !== 'CREDITO').map(opcao => new SelectorModel(
      opcao.idLancamentoCaixaFilial,
      opcao.lancamento,
      opcao.tipoLancamentoCaixaFilial,
    ));
  }

  makeItemsOrigens(itens = false) {
    return itens.map(opcao => new SelectorModel(
      opcao.idTipoOrigemCaixaFilial,
      opcao.descricao,
      opcao.tipoOrigemCaixaFilial,
    ));
  }

  async buscarTiposLancamentoEOrigens(setLoading) {
    try {
      setLoading(true);
      const origens = await buscarTiposOrigemLancamentosService();
      const tiposLancamentos = await buscarTiposLancamentosService();

      return {
        origens,
        tiposLancamentos,
      };
    } catch {
      // Nenhum tratamento
    } finally {
      setLoading(false);
    }
    return [];
  }


  async novoLancamento(idFilial, payload, errorsField, setLoading) {
    try {
      setLoading(true);
      await novoLancamentoService(idFilial, payload);
      return { success: true, errors: null };
    } catch (error) {
      const [updatedErrors] = updateFieldsWithErrors(error, errorsField);
      const exceptionMessage = getExceptionMessage(error);
      const exceptionErrorMessage = getExceptionErrorMessage(error);
      return {
        success: false,
        errors: {
          campos: this.makeErrors(updatedErrors),
          toastMessage: error.response.data.validations ? exceptionErrorMessage : exceptionMessage,
        },
      };
    } finally {
      setLoading(false);
    }
  }

  async editarLancamento(idFilial, payload, errorsField, setLoading) {
    try {
      setLoading(true);
      await editarLancamentoService(idFilial, payload);
      return { success: true, errors: null };
    } catch (error) {
      const [updatedErrors] = updateFieldsWithErrors(error, errorsField);
      const exceptionMessage = getExceptionMessage(error);
      const exceptionErrorMessage = getExceptionErrorMessage(error);
      return {
        success: false,
        errors: {
          campos: this.makeErrors(updatedErrors),
          toastMessage: error.response.data.validations ? exceptionErrorMessage : exceptionMessage,
        },
      };
    } finally {
      setLoading(false);
    }
  }

  async buscarLancamentoCaixaFilial(idCaixaFilial) {
    try {
      return await buscarLancamentoCaixaFilial(idCaixaFilial);
    } catch {
      // Nenhum tratamento
    }
    return null;
  }
}

export const lancamentoController = new LancamentoFinanceiro();
