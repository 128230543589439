import styled from 'styled-components';

export const BodyDashboardDrawer = styled.div`
  flex-grow: 1;
`;

export const FooterDashboardDrawer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 62px;
  min-height: 62px;
  padding-left: 40px;
  padding-right: 24px;

  box-shadow: 3px 0 6px 0 rgba(0, 0, 0, 0.28);

  button {
    padding: 8px 64px;
    width: auto;
  }

  button:first-child {
    padding: 8px 32px;
  }
`;
