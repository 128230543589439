import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  blueGrey, paleGrey, warmGrey, white,
} from '../../../helpers/constants/colors.constants';

export default makeStyles({
  appBar: {
    opacity: '1 !important',
    backgroundColor: `${paleGrey} !important`,
    borderTopLeftRadius: '7px',
    borderTopRightRadius: '7px',
    '& .Mui-selected': {
      backgroundColor: `${white} !important`,
      color: warmGrey,
      fontWeight: 'bold !important',
      letterSpacing: '0.49px',
      font: 'Roboto-Bold',
    },
    '& .MuiTab-root': {
      color: warmGrey,
      font: 'Roboto-Regular',
      padding: '10px 6px !important',
      minWidth: '41px',
      marginRight: '3px',
      textTransform: 'none',
      fontSize: '12px',
      lineHeight: '1.19',
      fontWeight: 'normal',
      minHeight: '41px',
      borderTopLeftRadius: '7px',
      borderTopRightRadius: '7px',
      backgroundColor: blueGrey,
      opacity: 1,
    },
    '& .MuiTabs-root': {
      minHeight: '41px',
    },
  },
  tabSelectColor: {
    backgroundColor: white,
  },
  ajustWhiteSpace: {
    whiteSpace: 'nowrap',
  },
  bsNone: {
    boxShadow: 'none',
  },
  separator: {
    boxShadow: 'none',
    marginTop: '20px',
  },
});
