import { gatewayShortJson } from '../../api';

const CORE = process.env.REACT_APP_CORE;

export const getBancos = () => (
  gatewayShortJson.get(`${CORE}/bancos`)
);

export const getBancoTipoConta = () => (
  gatewayShortJson.get(`${CORE}/bancos/tipos-conta`)
);
