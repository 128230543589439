import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useUtilStyles from '../../../../../helpers/styles/utils.styles';
import InputField from '../../../../UI/Field/Input/InputField';
import FormWrapperTitle from '../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import SelectorField from '../../../../UI/Field/Selector/SelectorField';
import MaskField from '../../../../UI/Field/Mask/MaskField';
import Grid24 from '../../../../UI/Grid24/Grid24';
import { findBancosAction, findTiposContaAction } from '../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import { initiateTiposDocumento } from '../../../../../helpers/factory/global.factory';
import { useCrudUsuarioDispatch2, useCrudUsuarioSelector2 } from '../../../../../store/Usuario/V2/CrudUsuarioProvider';
import {
  AGENCIA_TITLE,
  BANCO_TITLE,
  CNPJ_TITLE, COMPLEMENTO_TITLE, CONTA_TITLE,
  CPF_TITLE,
  DADOS_BANCARIOS_TITLE,
  FAVORECIDO_TITLE, TIPO_CONTA_TITLE,
  TIPO_DOCUMENTO_TITLE,
} from './dadosBancariosCrudUsuario.constants';
import { onChangeTipoDocumentoAction } from '../../../../../store/Usuario/V2/crudUsuario.store';

function DadosBancarios({
  disabled,
  changeHandler,
  onFocusHandler,
}) {
  const utilStyles = useUtilStyles();
  const dispatch = useCrudUsuarioDispatch2();
  const globalDispatch = useDispatch();

  const tiposDocumento = initiateTiposDocumento;
  const bancos = useSelector(states => states.selectors.bancos.selector);
  const tiposConta = useSelector(states => states.selectors.tiposConta.selector);
  const dadosBancoFavorecido = useCrudUsuarioSelector2(states => states.controls.dadosBancoFavorecido.copy());
  const dadosBancoTipoDocumento = useCrudUsuarioSelector2(states => states.controls.dadosBancoTipoDocumento.copy());
  const dadosBancoDocumento = useCrudUsuarioSelector2(states => states.controls.dadosBancoDocumento.copy());
  const idBanco = useCrudUsuarioSelector2(states => states.controls.idBanco.copy());
  const dadosBancoAgencia = useCrudUsuarioSelector2(states => states.controls.dadosBancoAgencia.copy());
  const dadosidTipoConta = useCrudUsuarioSelector2(states => states.controls.idTipoConta.copy());
  const dadosBancoConta = useCrudUsuarioSelector2(states => states.controls.dadosBancoConta.copy());
  const dadosBancoComplemento = useCrudUsuarioSelector2(states => states.controls.dadosBancoComplemento.copy());

  // Carrega os combobox
  useEffect(() => {
    globalDispatch(findTiposContaAction());
    globalDispatch(findBancosAction());
  }, [globalDispatch]);

  const getDocumentLabel = () => (dadosBancoTipoDocumento.value === 'CNPJ' ? CNPJ_TITLE : CPF_TITLE);

  const getDocumentMask = () => (dadosBancoTipoDocumento.value === 'CNPJ' ? '##.###.###/####-##' : '###.###.###-##');

  const changeTipoDocumentoHandler = useCallback((event, model) => {
    const { name, value } = event.target;
    const newModel = model.getNewModel(value);
    dispatch(onChangeTipoDocumentoAction(name, newModel));
  }, [dispatch]);

  return (
    <FormWrapperTitle title={DADOS_BANCARIOS_TITLE}>
      <div className={utilStyles.mt7}>
        <Grid24 container isFormContainer spacing="1">
          <Grid24 xs={24} sm={14}>
            <InputField
              isForm
              fullWidth
              label={FAVORECIDO_TITLE}
              disabled={disabled}
              name={dadosBancoFavorecido.name}
              value={dadosBancoFavorecido.value}
              error={dadosBancoFavorecido.error}
              errorMessage={dadosBancoFavorecido.errorMessage}
              onChange={changeHandler}
              maxLength={dadosBancoFavorecido.maxLength}
            />
          </Grid24>
          <Grid24 xs={24} sm={4}>
            <SelectorField
              isForm
              fullWidth
              label={TIPO_DOCUMENTO_TITLE}
              disabled={disabled}
              items={tiposDocumento}
              name={dadosBancoTipoDocumento.name}
              value={dadosBancoTipoDocumento.value}
              error={dadosBancoTipoDocumento.error}
              errorMessage={dadosBancoTipoDocumento.errorMessage}
              onChange={event => changeTipoDocumentoHandler(event, dadosBancoTipoDocumento)}
            />
          </Grid24>
          <Grid24 xs={24} sm={5}>
            <MaskField
              isForm
              label={getDocumentLabel()}
              name={dadosBancoDocumento.name}
              disabled={disabled}
              value={dadosBancoDocumento.value}
              error={dadosBancoDocumento.error}
              errorMessage={dadosBancoDocumento.errorMessage}
              onChange={event => changeHandler(event, dadosBancoDocumento)}
              onFocus={onFocusHandler}
              inputProps={{
                format: getDocumentMask(),
              }}
            />
          </Grid24>
        </Grid24>
        <div className={utilStyles.mt7}>
          <Grid24 container isFormContainer spacing="1">
            <Grid24 xs={24} sm={14}>
              <SelectorField
                isForm
                fullWidth
                label={BANCO_TITLE}
                disabled={disabled}
                items={bancos}
                name={idBanco.name}
                value={idBanco.value}
                error={idBanco.error}
                errorMessage={idBanco.errorMessage}
                onChange={changeHandler}
              />
            </Grid24>
            <Grid24 xs={24} sm={3}>
              <InputField
                isForm
                fullWidth
                label={AGENCIA_TITLE}
                name={dadosBancoAgencia.name}
                disabled={disabled}
                value={dadosBancoAgencia.value}
                error={dadosBancoAgencia.error}
                errorMessage={dadosBancoAgencia.errorMessage}
                onChange={changeHandler}
                maxLength={dadosBancoAgencia.maxLength}
              />
            </Grid24>
            <Grid24 xs={24} sm={4}>
              <InputField
                isForm
                fullWidth
                label={CONTA_TITLE}
                name={dadosBancoConta.name}
                disabled={disabled}
                value={dadosBancoConta.value}
                error={dadosBancoConta.error}
                errorMessage={dadosBancoConta.errorMessage}
                onChange={changeHandler}
                maxLength={dadosBancoConta.maxLength}
              />
            </Grid24>
          </Grid24>
        </div>
        <Grid24 container isFormContainer spacing="1">
          <Grid24 xs={24} sm={6}>
            <SelectorField
              isForm
              fullWidth
              label={TIPO_CONTA_TITLE}
              items={tiposConta}
              name={dadosidTipoConta.name}
              disabled={disabled}
              value={dadosidTipoConta.value}
              error={dadosidTipoConta.error}
              errorMessage={dadosidTipoConta.errorMessage}
              onChange={changeHandler}
              hasEmptyLabel
            />
          </Grid24>
          <Grid24 xs={24} sm={5}>
            <InputField
              isForm
              fullWidth
              label={COMPLEMENTO_TITLE}
              name={dadosBancoComplemento.name}
              disabled={disabled}
              value={dadosBancoComplemento.value}
              error={dadosBancoComplemento.error}
              errorMessage={dadosBancoComplemento.errorMessage}
              onChange={changeHandler}
              maxLength={dadosBancoComplemento.maxLength}
            />
          </Grid24>
        </Grid24>
      </div>
    </FormWrapperTitle>
  );
}

export default React.memo(DadosBancarios);
