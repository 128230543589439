/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { Tooltip } from '@material-ui/core';
import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';

import {
  FaCircle,
  FaEnvelope,
  FaExclamationTriangle,
  FaHandshake,
} from 'react-icons/fa';

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { HiClock } from 'react-icons/hi';
import { RiFileWarningFill, RiRobot2Fill } from 'react-icons/ri';
import { MdEdit, MdReceipt } from 'react-icons/md';
import { AiFillPrinter } from 'react-icons/ai';
import PollIcon from '@material-ui/icons/Poll';
import {
  TableActionButton,
  TableActionContainer,
} from '../../../components/UI/Tabela/tableDefaultStyles';
import {
  buildSettingsTable,
  getMoneyMask,
  getNewPage,
  hasUserPermissions,
  openPagePDF,
  toastUnmappedException,
  toBrazilianDate,
} from '../../../helpers';
import {
  blackTwo,
  lightGrey,
} from '../../../helpers/constants/colors.constants';
import { loadingInspecaoProcessoController } from '../loadingInspecaoProcessoController';
import { acordosFactory } from './AcordosFactory';
import useStyle, { ButtonNovoAcordo, TableHeader } from './AcordosStyle';
import TableAcordos from './TableAcordos';
import handShakeGrey from '../../../assets/images/icons/handShakeGrey.svg';
import handShakeBlack from '../../../assets/images/icons/handShakeBlack.svg';
import { NovoAcordo } from './NovoAcordo/NovoAcordo';
import InfoAdicionaisAcordo from './InfoAdicionaisAcordo';
import TooltipUI from '../../../components/UI/Tooltip/Tooltip';
import DialogQuebrarAcordo from './DialogQuebrarAcordo/DialogQuebrarAcordo';
import DialogEnviarEmail from './DialogEnviarEmail/DialogEnviarEmail';
import DialogAnaliseSimulacao from './DialogAnaliseSimulacao/DialogAnaliseSimulacao';
import DialogAcompanhamento from './DialogAcompanhemento';
import DialogNew from '../../../layouts/FormDialog/DialogNew';
import OptionDialogNew from '../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';
import {
  buscarDadosEditarBoleto,
  buscarDadosEditarCartao,
  buscarDadosEditarCheque,
  buscarDadosEditarDinheiro, buscarDadosEditarPix, cancelarProposta, cancelarSimulacao, findAcordosService, gerarAcordo, getDadosResumo, imprimirAcordoService,
} from '../../../services/core/processos/processosAcordosService';
import DialogRecibo from './DialogRecibo/DialogRecibo';
import DialogEnviarParcelaAcordo from './DialogEnviarParcelaAcordo/DialogEnviarParcelaAcordo';
import DialogAlterarDinheiro from './DialogAlterarDinheiro/DialogAlterarDinheiro';
import DialogAlterarBoleto from './DialogAlterarBoleto/DialogAlterarBoleto';
import DialogAlterarPix from './DialogAlterarPix/DialogAlterarPix';
import DialogAlterarCartao from './AlterarParcelaCartao/DialogAlterarCartao';
import DialogAlterarCheque from './DialogAletrarCheque/DialogAlterarCheque';
import DialogDistribuicaoPersonalizada from './DialogDistribuicaoPersonalizada/DialogDistribuicaoPersonalizada';

// Legenda referente ao StatusAcordo:

// 0 = Ativo

// 1 = Quebrado

// 2 = Quitado

// 3 = Simulação

// 4 = Proposta

function Acordos({
  podeModificarFase,
  setLoadings,
  idProcesso,
  dadosProcesso,
  sinalizacaoCoresQuery,
  idFaseProcesso,
  updatePage,

}) {
  const styles = useStyle();


  const [arrowOrdenacao, setArrowOrdenacao] = useState(true);
  const [dadosAcordo, setDadosAcordo] = useState([]);
  const [idAcordoSelectTable, setIdAcordoSelectTable] = useState(null);
  const [infoAdicionaisAcordo, setInfoAdicionaisAcordo] = useState([]);
  const [settingsTable, setSettingsTable] = useState(
    buildSettingsTable([], 0, 10, 0),
  );

  const [tableRows, setTableRows] = useState([]);
  const [openButtonNovoAcordo, setOpenButtonNovoAcordo] = useState(false);
  const [openButtonQuebrarAcordo, setOpenButtonQuebrarAcordo] = useState(false);
  const [openButtonCancelarSimulacao, setOpenButtonCancelarSimulacao] = useState(false);
  const [openButtonCancelarProposta, setOpenButtonCancelarProposta] = useState(false);

  const [openDialogFecharAcordo, setOpenDialogFecharAcordo] = useState(false);
  const [openDialogEnviarEmail, setOpenDialogEnviarEmail] = useState(false);
  const [idAcordoDialogEnviarEmail, setIdAcordoDialogEnviarEmail] = useState('');
  const [openDialogDistribuicaoPersonalizada, setOpenDialogDistribuicaoPersonalizada] = useState(false);

  const [openDialogEnviarParcelaAcordo, setOpenDialogEnviarParcelaAcordo] = useState(false);
  const [idAcordoEnviarParcelaAcordo, setIdAcordoEnviarParcelaAcordo] = useState('');
  const [nroParcelaEnviarParcelaAcordo, setNroParcelaEnviarParcelaAcordo] = useState(0);
  const [tipoFormaPagtoEnviarParcelaAcordo, setTipoFormaPagtoEnviarParcelaAcordo] = useState('');

  // Dialog Analise Simulacao
  const [openDialogAnaliseSimulacao, setOpenDialogAnaliseSimulacao] = useState(false);
  const [selectedAcordoAnaliseSimulacao, setSelectedAcordoAnaliseSimulacao] = useState('');

  // Dialog Recibo
  const [openDialogRecibo, setOpenDialogRecibo] = useState(false);
  const [idAcordoDialogRecibo, setIdAcordoDialogRecibo] = useState('');
  const [nroParcelaDialogRecibo, setNroParcelaDialogRecibo] = useState(0);
  const [tipoFormaPagtoDialogRecibo, setTipoFormaPagtoDialogRecibo] = useState('');

  // Dialog Acompanhamento
  const [openDialogAcompanhamento, setOpenDialogAcompanhamento] = useState(false);
  const [textoAcompanhamento, setTextoAcompanhamento] = useState('');

  const [settingsTableAccordion, setSettingsTableAccordion] = useState(buildSettingsTable([], 0, 0, 0));
  const [tableRowsAccordion, setTableRowsAccordion] = useState([]);

  const [openDialogAlterarBoleto, setOpenDialogAlterarBoleto] = useState(false);
  const [openDialogAlterarDinheiro, setOpenDialogAlterarDinheiro] = useState(false);
  const [openDialogAlterarCheque, setOpenDialogAlterarCheque] = useState(false);
  const [openDialogAlterarPix, setOpenDialogAlterarPix] = useState(false);
  const [openDialogAlterarCartao, setOpenDialogAlterarCartao] = useState(false);
  const [idAcordoDialogAlterar, setIdAcordoDialogAlterar] = useState('');
  const [nroParcelaDialogAlterar, setNroParcelaDialogAlterar] = useState(0);
  const [tipoFormaPagtoDialogAlterar, setTipoFormaPagtoDialogAlterar] = useState('');
  const [idAcordoParcelaDialogAlterar, setIdAcordoParcelaDialogAlterar] = useState('');
  const [dadosParcelaDinheiro, setDadosParcelaDinheiro] = useState([]);
  const [dadosParcelaCheque, setDadosParcelaCheque] = useState([]);
  const [dadosParcelaCartao, setDadosParcelaCartao] = useState([]);
  const [dadosParcelaPix, setDadosParcelaPix] = useState([]);
  const [dadosParcelaBoleto, setDadosParcelaBoleto] = useState([]);
  const [dados, setDados] = useState([]);
  const [parcela, setParcela] = useState([]);
  const [idAcordoDistribuicaoPersonalizada, setIdAcordoDistribuicaoPersonalizada] = useState('');

  const [columnOrdenacao, setColumnOrdenacao] = useState({
    order: 'DESC',
    element: 'nroAcordo',
  });

  const permission = React.useMemo(() => hasUserPermissions(1206), []);
  const permissaoDistribuicaoPersonalizada = React.useMemo(() => hasUserPermissions(1903), []);
  const usuarioLogado = useSelector(states => states.authentication.userInformation);

  const [exibirApenasAcordosAtivosEhPropostas, setExibirApenasAcordosAtivosEhPropostas] = useState(true);
  const [collapse, setCollapse] = useState(false);

  const configureStatesPesquisa = useCallback((data) => {
    setSettingsTable({
      content: data.content,
      page: data.number,
      rowsPerPage: data.size,
      size: data.totalElements,
    });
  }, []);

  const configureStatesPesquisaAccordion = useCallback((data) => {
    setSettingsTableAccordion({
      content: data.content.map(item => item.parcelas),
    });
  }, []);

  const pesquisarAcordos = useCallback(async (page, rowsPerPage, ordenacao, filtro) => {
    setLoadings(loadingInspecaoProcessoController.changeLoadingAcordos(true));
    setCollapse(true);
    findAcordosService(idProcesso, page, rowsPerPage, [
      `${ordenacao.element},${ordenacao.order}`,
    ], filtro)
      .then((response) => {
        const { data } = response;
        configureStatesPesquisa(data);
        configureStatesPesquisaAccordion(data);
      })
      .catch(() => {
        setSettingsTable(buildSettingsTable([[], 0, 10, 0]));
      })
      .finally(() => {
        setLoadings(
          loadingInspecaoProcessoController.changeLoadingAcordos(false),
        );
      });
  },
    [configureStatesPesquisa, idProcesso, setLoadings, configureStatesPesquisaAccordion, setCollapse]);

  const onClickButtonNovoAcordo = useCallback(() => {
    setOpenButtonNovoAcordo(oldValue => !oldValue);
  }, []);

  function onClickButtonQuebrarAcordo(acordos) {
    switch (acordos.idStatusAcordo) {
      case 3: // Simulação
        return () => {
          setOpenButtonCancelarSimulacao(oldValue => !oldValue);
          setDadosAcordo(acordos);
        };
      case 4: // Proposta
        return () => {
          setOpenButtonCancelarProposta(oldValue => !oldValue);
          setDadosAcordo(acordos);
        };
      default: // Acordo
        return () => {
          setOpenButtonQuebrarAcordo(oldValue => !oldValue);
          setDadosAcordo(acordos);
      };
    }
  }

  const onChangePageHandler = useCallback(async (event, newPage) => {
    pesquisarAcordos(newPage, settingsTable.rowsPerPage, columnOrdenacao, exibirApenasAcordosAtivosEhPropostas);
  },

    [pesquisarAcordos, settingsTable.rowsPerPage, columnOrdenacao, exibirApenasAcordosAtivosEhPropostas]);

  const onChangeRowsPerPageHandler = useCallback(
    async (event) => {
      pesquisarAcordos(settingsTable.page, event.target.value, columnOrdenacao, exibirApenasAcordosAtivosEhPropostas);
      setSettingsTable(old => ({ ...old, rowsPerPage: event.target.value }));
    },
    [pesquisarAcordos, columnOrdenacao, settingsTable.page, exibirApenasAcordosAtivosEhPropostas],
  );

  const onClickOrdernacaoColumn = useCallback(async () => {
    pesquisarAcordos(settingsTable.page, settingsTable.rowsPerPage, columnOrdenacao, exibirApenasAcordosAtivosEhPropostas);
  }, [pesquisarAcordos, settingsTable.page, settingsTable.rowsPerPage, columnOrdenacao, exibirApenasAcordosAtivosEhPropostas]);

  function onClickGerarAcordo(idAcordo) {
    setIdAcordoSelectTable(idAcordo);
    setOpenDialogFecharAcordo(true);
  }

  const isDisabledButton = useCallback(
    (acordos) => {
      if (permission) { // habilita
        return (new Date().toISOString().slice(0, 10) <= acordos.dataValidade)
          && (acordos.idUsuarioAutorizacao === usuarioLogado.idUsuario);
      }
      return false; // desabilita
    }, [permission, usuarioLogado],
  );


  function onClickDialogAcompanhamento(texto) {
    setOpenDialogAcompanhamento(oldValue => !oldValue);
    setTextoAcompanhamento(texto);
  }

  const handleClickImprimir = useCallback((acordo) => {
    setLoadings(
      loadingInspecaoProcessoController.changeLoadingAcordos(true),
    );
    imprimirAcordoService(acordo).then((response) => {
       openPagePDF(response.data);
    }).catch((error) => {
       error.response.data.text().then(text => toast.error(JSON.parse(text).message));
    }).finally(() => {
        setLoadings(
          loadingInspecaoProcessoController.changeLoadingAcordos(false),
        );
    });
  }, [setLoadings]);

  const onChangeSwitch = useCallback(async () => {
    const newPage = getNewPage(settingsTable.rowsPerPage, settingsTable.page, settingsTable.size);
    if (exibirApenasAcordosAtivosEhPropostas === true) {
      setExibirApenasAcordosAtivosEhPropostas(false);
      pesquisarAcordos(newPage, settingsTable.rowsPerPage, columnOrdenacao, false);
    } else if (exibirApenasAcordosAtivosEhPropostas === false) {
      setExibirApenasAcordosAtivosEhPropostas(true);
      pesquisarAcordos(newPage, settingsTable.rowsPerPage, columnOrdenacao, true);
    }
  }, [pesquisarAcordos, exibirApenasAcordosAtivosEhPropostas, columnOrdenacao, settingsTable.page, settingsTable.rowsPerPage, settingsTable.size]);

  const makeConteudoTabela = useCallback(

    (acordos) => {
      if (!acordos || acordos.length === 0) {
        return [];
      }

      function getTituloBotaoCancelar(idStatusAcordo) {
        switch (idStatusAcordo) {
          case 3:
            return 'Cancelar Simulação';
          case 4:
            return 'Cancelar Proposta';
          default:
            return 'Quebrar Acordo';
        }
      }

      function todasParcelasPagas(parcelas) {
        let parcelaPaga = true;
        parcelas.forEach((p) => {
          if (!p.valorPago && parcelaPaga) {
            parcelaPaga = false;
          }
        });
        return parcelaPaga;
      }

      return {
        id: acordos.idAcordo,
        values: {
          nroAcordo: acordos.nroAcordo,
          dataAcordo: toBrazilianDate(acordos.dataAcordo),
          nomeFase: acordos.nomeFase,
          nomeUsuarioNegociador: acordos.nomeUsuarioNegociador,
          idStatusAcordo: (
            <div>
              <TableActionContainer>
                {acordos.idStatusAcordo === 3 && (
                  <Tooltip
                    title={`Simulação - Data da Expiração: ${toBrazilianDate(
                      acordos.dataValidade,
                    )}`}
                    placement="bottom"
                  >
                    <TableActionButton>
                      <FaCircle size={12} color="#ffa000" />
                    </TableActionButton>
                  </Tooltip>
                )}
                {acordos.idStatusAcordo === 2 && (
                  <Tooltip title={acordos.serAutomatico ? 'Acordo Automático' : 'Acordo Quitado'} placement="bottom">
                    <TableActionButton>
                    {acordos.serAutomatico ? (<RiRobot2Fill size={16} color="#52b656" />)
                    : (<FaCircle size={12} color="#52b656" />)
                    }

                    </TableActionButton>
                  </Tooltip>
                )}
                {acordos.idStatusAcordo === 1 && (
                  <Tooltip title={acordos.serAutomatico ? 'Acordo Automático' : 'Acordo Quebrado'} placement="bottom">
                    <TableActionButton>
                    {acordos.serAutomatico ? (<RiRobot2Fill size={16} color="#f1515b" />)
                    : (<FaCircle size={12} color="#f1515b" />)
                    }
                    </TableActionButton>
                  </Tooltip>
                )}
                {acordos.idStatusAcordo === 0 && (
                  <Tooltip title={acordos.serAutomatico ? 'Acordo Automático' : 'Acordo Ativo'} placement="bottom">
                    <TableActionButton>
                    {acordos.serAutomatico ? (<RiRobot2Fill size={16} color="#009bfa" />)
                    : (<FaCircle size={12} color="#009bfa" />)
                    }
                    </TableActionButton>
                  </Tooltip>
                )}
                {acordos.idStatusAcordo === 4 && (
                  <Tooltip
                    title={`${
                      acordos.texto.substr(0, 22) === 'Proposta Não Aprovada '
                        ? 'Proposta não aprovada'
                        : 'Proposta pendente'
                    } - Data da expiração: ${toBrazilianDate(
                      acordos.dataValidade,
                    )}`}
                    placement="bottom"
                  >
                    <TableActionButton>
                    <FaCircle size={12} color="#7D69A4" />
                    </TableActionButton>
                  </Tooltip>
                )}
              </TableActionContainer>
            </div>
          ),
          descricaoFormaPagto: acordos.descricaoFormaPagto,
          valor: getMoneyMask(acordos.valor),
          texto: acordos.texto,
          simulacaoExpirada: acordos.simulacaoExpirada,
          simulacaoComTitulosLiberados: acordos.simulacaoComTitulosLiberados,
          porcentagemDesconto: acordos.porcentagemDesconto,
          porcentagemHonorarios: acordos.porcentagemHonorarios,
          porcentagemTaxaJuros: acordos.porcentagemTaxaJuros,
          parcelas: acordos.parcelas,
          acoes: (
            <div>
              <TableActionContainer>
                <Tooltip title="Exibir Detalhes" placement="bottom">
                  <TableActionButton
                    onClick={() => onClickDialogAcompanhamento(acordos.texto)}
                  >
                    <RiFileWarningFill size={18} color={blackTwo} />
                  </TableActionButton>
                </Tooltip>
                <span style={{ marginRight: '4px' }}>
                  {!podeModificarFase
                  || acordos.simulacaoExpirada === true
                  || acordos.simulacaoComTitulosLiberados === false
                  || acordos.idStatusAcordo === 4 ? (
                    <TableActionButton disabled>
                      <FaHandshake size={20} color={lightGrey} />
                    </TableActionButton>
                  ) : (
                    <Tooltip title="Gerar Acordo" placement="bottom">
                      <TableActionButton
                        onClick={() => onClickGerarAcordo(acordos.idAcordo)}
                      >
                        <FaHandshake size={20} color={blackTwo} />
                      </TableActionButton>
                    </Tooltip>
                  )}
                </span>
                {!podeModificarFase
                || (acordos.idStatusAcordo !== 0
                  && !(
                    acordos.idStatusAcordo === 3
                    && new Date().toISOString().slice(0, 10)
                      <= acordos.dataValidade
                  )
                  && !(
                    acordos.idStatusAcordo === 4
                    && new Date().toISOString().slice(0, 10)
                      <= acordos.dataValidade
                  )) ? (
                  <TableActionButton disabled>
                    <img
                      src={handShakeGrey}
                      alt=""
                      width="20px"
                      height="20px"
                    />
                  </TableActionButton>
                ) : (
                  <Tooltip
                    title={getTituloBotaoCancelar(acordos.idStatusAcordo)}
                    placement="bottom"
                  >
                    <TableActionButton
                      onClick={onClickButtonQuebrarAcordo(acordos)}
                    >
                      <img
                        src={handShakeBlack}
                        alt=""
                        width="20px"
                        height="20px"
                      />
                    </TableActionButton>
                  </Tooltip>
                )}
                {!podeModificarFase
                || acordos.simulacaoExpirada === true
                || acordos.idStatusAcordo !== 0
                || todasParcelasPagas(acordos.parcelas) ? (
                  <TableActionButton disabled>
                    <FaEnvelope size={17} color={lightGrey} />
                  </TableActionButton>
                ) : (
                  <Tooltip title="Enviar Termo de Acordo" placement="bottom">
                    <TableActionButton
                      onClick={() => {
                        setOpenDialogEnviarEmail(true);
                        setIdAcordoDialogEnviarEmail(acordos.idAcordo);
                      }}
                    >
                      <FaEnvelope size={17} color={blackTwo} />
                    </TableActionButton>
                  </Tooltip>
                )}
                <Tooltip title="Imprimir">
                  <TableActionButton
                    disabled={
                    acordos.idStatusAcordo !== 0}
                    onClick={() => handleClickImprimir(acordos.idAcordo)}
                  >
                    <AiFillPrinter size={19} color={acordos.idStatusAcordo === 0 ? blackTwo : lightGrey} />
                  </TableActionButton>
                </Tooltip>
                {isDisabledButton(acordos) === false
                || acordos.idStatusAcordo !== 4
                || acordos.simulacaoComTitulosLiberados === false
                || acordos.texto.substr(0, 22) === 'Proposta Não Aprovada ' ? (
                  <TableActionButton disabled>
                    <div className={styles.icon__simulacoesPendentesGrid}>
                      <HiClock color={lightGrey} size="18.75px" />
                    </div>
                  </TableActionButton>
                ) : (
                  <Tooltip title="Análise da Proposta" placement="bottom">
                    <TableActionButton
                      onClick={() => {
                        setSelectedAcordoAnaliseSimulacao(acordos);
                        setOpenDialogAnaliseSimulacao(true);
                      }}
                    >
                      <div className={styles.icon__simulacoesPendentesGrid}>
                        <HiClock color={blackTwo} size="18.75px" />
                      </div>
                    </TableActionButton>
                  </Tooltip>
                )}
                <Tooltip title="Distribuição Personalizada">
                  <TableActionButton
                    disabled={acordos.idStatusAcordo !== 0 || !permissaoDistribuicaoPersonalizada}
                    onClick={() => {
                      setIdAcordoDistribuicaoPersonalizada(acordos.idAcordo);
                      setOpenDialogDistribuicaoPersonalizada(true);
                    }}
                  >
                    <PollIcon size={19} color={blackTwo} />
                  </TableActionButton>
                </Tooltip>
              </TableActionContainer>
            </div>
          ),
        },
      };
    },
    [podeModificarFase, isDisabledButton, styles, handleClickImprimir, permissaoDistribuicaoPersonalizada],
  );

  const tableContent = useMemo(
    () => settingsTable.content.map((processoParam, index) => makeConteudoTabela(processoParam, index, setDadosAcordo(processoParam))),
    [settingsTable.content, makeConteudoTabela],
  );

  function formaPgtoString(value) {
    let statusAcordo;
    if (value === 0) {
      statusAcordo = 'Boleto';
    } else if (value === 1) {
      statusAcordo = 'Pix';
    } else if (value === 2) {
      statusAcordo = 'Cartão';
    } else if (value === 5) {
      statusAcordo = 'Dinheiro';
    } else if (value === 6) {
      statusAcordo = 'Cheque';
    }
    return statusAcordo;
  }

  function statusParcelaString(value) {
    let statusAcordo;
    if (value === 0) {
      statusAcordo = 'Quitada';
    } else if (value === 1) {
      statusAcordo = 'Aguardando Envio';
    } else if (value === 2) {
      statusAcordo = 'Aguardando Pagamento';
    } else if (value === 3) {
      statusAcordo = 'Cancelado';
    }
    return statusAcordo;
  }

  function onClickEnviarParcelaAcordo(idAcordo, nroParcela, tipoFormaPagto) {
    setOpenDialogEnviarParcelaAcordo(true);
    setIdAcordoEnviarParcelaAcordo(idAcordo);
    setNroParcelaEnviarParcelaAcordo(nroParcela);
    setTipoFormaPagtoEnviarParcelaAcordo(tipoFormaPagto);
  }

  const onClickAlterarParcelaAcordo = useCallback((idAcordo, nroParcela, tipoFormaPagto, idAcordoParcela, parcelas) => {
    if (tipoFormaPagto === 'Dinheiro') {
      buscarDadosEditarDinheiro(idAcordo, nroParcela).then((response) => {
        const { data } = response;
        setDadosParcelaDinheiro(data);
        setOpenDialogAlterarDinheiro(true);
      });
      setIdAcordoDialogAlterar(idAcordo);
      setNroParcelaDialogAlterar(nroParcela);
      setTipoFormaPagtoDialogAlterar(tipoFormaPagto);
      setIdAcordoParcelaDialogAlterar(idAcordoParcela);
    }
    if (tipoFormaPagto === 'Cheque') {
      buscarDadosEditarCheque(idAcordo, nroParcela).then((response) => {
        const { data } = response;
        setDadosParcelaCheque(data);
        setOpenDialogAlterarCheque(true);
      });
      setIdAcordoDialogAlterar(idAcordo);
      setNroParcelaDialogAlterar(nroParcela);
      setTipoFormaPagtoDialogAlterar(tipoFormaPagto);
      setIdAcordoParcelaDialogAlterar(idAcordoParcela);
    }
    if (tipoFormaPagto === 'Boleto') {
      buscarDadosEditarBoleto(idAcordo, nroParcela).then((response) => {
        const { data } = response;
        setDadosParcelaBoleto(data);
        setOpenDialogAlterarBoleto(true);
      });
      setIdAcordoDialogAlterar(idAcordo);
      setNroParcelaDialogAlterar(nroParcela);
      setTipoFormaPagtoDialogAlterar(tipoFormaPagto);
      setIdAcordoParcelaDialogAlterar(idAcordoParcela);
      setParcela(parcelas);
    }
    if (tipoFormaPagto === 'Pix') {
      buscarDadosEditarPix(idAcordo, nroParcela).then((response) => {
        const { data } = response;
        setDadosParcelaPix(data);
        setOpenDialogAlterarPix(true);
      });
      setIdAcordoDialogAlterar(idAcordo);
      setNroParcelaDialogAlterar(nroParcela);
      setTipoFormaPagtoDialogAlterar(tipoFormaPagto);
      setIdAcordoParcelaDialogAlterar(idAcordoParcela);
      setParcela(parcelas);
    }
    if (tipoFormaPagto === 'Cartão') {
      buscarDadosEditarCartao(idAcordo, nroParcela).then((response) => {
        const { data } = response;
        setDadosParcelaCartao(data);
        setOpenDialogAlterarCartao(true);
      });
      setIdAcordoDialogAlterar(idAcordo);
      setNroParcelaDialogAlterar(nroParcela);
      setTipoFormaPagtoDialogAlterar(tipoFormaPagto);
      setIdAcordoParcelaDialogAlterar(idAcordoParcela);
    }
  }, [setDadosParcelaDinheiro]);

  const makeConteudoTabelaAcordion = useCallback(
    (parcelasAcordo, index) => {
      if (!parcelasAcordo || parcelasAcordo.length === 0) {
        return [];
      }
      return {
        id: parcelasAcordo.idAcordo,
        values: {
          idAcordo: parcelasAcordo.idAcordo,
          dataVencimento: toBrazilianDate(parcelasAcordo.dataVencimento),
          idTipoFormaPagto: formaPgtoString(parcelasAcordo.idTipoFormaPagto),
          idStatusParcelaAcordo: statusParcelaString(parcelasAcordo.idStatusParcelaAcordo),
          valor: getMoneyMask(parcelasAcordo.valor),
          nroParcela: parcelasAcordo.nroParcela,
          dataPagamento: toBrazilianDate(parcelasAcordo.dataPagamento),
          idAcordoParcela: parcelasAcordo.idAcordoParcela,
          acoes: (
            <div>
              <TableActionContainer>
                {parcelasAcordo.idStatusAcordo !== 0
                  || parcelasAcordo.idStatusParcelaAcordo === 0
                  || parcelasAcordo.idStatusParcelaAcordo === 1
                  ? (
                    <TableActionButton disabled>
                      <MdEdit size={20} color={lightGrey} />
                    </TableActionButton>
                  ) : (
                    <Tooltip title="Alterar" placement="bottom">
                      <TableActionButton
                        onClick={() => {
                          onClickAlterarParcelaAcordo(parcelasAcordo.idAcordo,
                            parcelasAcordo.nroParcela,
                            formaPgtoString(parcelasAcordo.idTipoFormaPagto), parcelasAcordo.idAcordoParcela, parcelasAcordo);
                        }}
                      >
                        <MdEdit size={20} color={blackTwo} />
                      </TableActionButton>
                    </Tooltip>
                  )}
                {parcelasAcordo.idStatusAcordo !== 0
                  || (parcelasAcordo.idStatusParcelaAcordo === 0
                    || parcelasAcordo.idStatusParcelaAcordo === 1
                    || parcelasAcordo.idTipoFormaPagto === 5
                    || parcelasAcordo.idTipoFormaPagto === 6
                    || parcelasAcordo.idTipoFormaPagto === 2) ? (
                  <TableActionButton disabled>
                    <FaEnvelope size={17} color={lightGrey} />
                  </TableActionButton>
                ) : (
                  <Tooltip title="Enviar" placement="bottom">
                    <TableActionButton
                      onClick={() => {
                        onClickEnviarParcelaAcordo(parcelasAcordo.idAcordo,
                          parcelasAcordo.nroParcela,
                          formaPgtoString(parcelasAcordo.idTipoFormaPagto));
                      }}
                    >
                      <FaEnvelope size={17} color={blackTwo} />
                    </TableActionButton>
                  </Tooltip>
                )}
                {parcelasAcordo.idStatusAcordo === 0
                  && (parcelasAcordo.idTipoFormaPagto === 5
                    || parcelasAcordo.idTipoFormaPagto === 6) ? (
                  <Tooltip title="Recibo" placement="bottom">
                    <TableActionButton onClick={() => {
                      setOpenDialogRecibo(true);
                      setIdAcordoDialogRecibo(parcelasAcordo.idAcordo);
                      setNroParcelaDialogRecibo(parcelasAcordo.nroParcela);
                      setTipoFormaPagtoDialogRecibo(formaPgtoString(parcelasAcordo.idTipoFormaPagto));
                    }}
                    >
                      <MdReceipt size={20} color={blackTwo} />
                    </TableActionButton>
                  </Tooltip>
                ) : (
                  <TableActionButton disabled>
                    <MdReceipt size={20} color={lightGrey} />
                  </TableActionButton>
                )}
              </TableActionContainer>
            </div>
          ),
        },
      };
    },
    [onClickAlterarParcelaAcordo],
  );

  const tableContentAccordion = useMemo(
    () => settingsTableAccordion.content.map(parcelas => parcelas.map((processoParam, index) => makeConteudoTabelaAcordion(
      processoParam,
      index,
    ))),
    [settingsTableAccordion.content, makeConteudoTabelaAcordion],
  );


  const handleDataFromChild = (data) => {
    setDados(data);
  };


  useEffect(() => {
    setTableRows(tableContent);
    setTableRowsAccordion(tableContentAccordion);
  }, [tableContent, tableContentAccordion]);

  useEffect(() => {
    findAcordosService(
      idProcesso,
      settingsTable.page,
      settingsTable.rowsPerPage,
      [`${columnOrdenacao.element},${columnOrdenacao.order}`],
      exibirApenasAcordosAtivosEhPropostas,
    )
      .then((response) => {
        const { data } = response;

        configureStatesPesquisa(data);
        configureStatesPesquisaAccordion(data);
      })
      .catch(() => {
        setSettingsTable(buildSettingsTable([[], 0, 10, 0]));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    getDadosResumo(idProcesso)
      .then((responseResumo) => {
        const { data } = responseResumo;
        setInfoAdicionaisAcordo(data);
      })
      .catch(() => {
        toastUnmappedException(
          'Ocorreu um problema ao tentar buscar as informações dos acordos',
        );
      });
  }, [idProcesso, configureStatesPesquisa]);

  useEffect(() => {
    if (sinalizacaoCoresQuery.cobrancaJudicial) {
      setOpenButtonNovoAcordo(false);
    }
  }, [sinalizacaoCoresQuery]);

  function handleOnClickCancelFecharAcordo() {
    setIdAcordoSelectTable(null);
    setOpenDialogFecharAcordo(false);
  }

  const handleOnClickFecharAcordo = useCallback(async () => {
    gerarAcordo(idAcordoSelectTable).then((value) => {
      const { codResult, msgResult } = value.data;
      if (codResult > 0) {
        if (codResult === 1) {
          toast.warn(value.data.msgResult);
        } else {
          toast.error(msgResult, { style: { width: '392px' } });
        }
      }
      pesquisarAcordos(
        settingsTable.pag,
        settingsTable.rowsPerPage,
        columnOrdenacao,
        exibirApenasAcordosAtivosEhPropostas,
      );
      getDadosResumo(idProcesso).then((responseData) => {
        const { data } = responseData;
        setInfoAdicionaisAcordo(data);
      });
      updatePage();
      if (codResult === 0) {
        toast.success('Acordo gerado com sucesso.');
      }
    });
    setIdAcordoSelectTable(null);
    setOpenDialogFecharAcordo(false);
  }, [
    settingsTable,
    pesquisarAcordos,
    columnOrdenacao,
    idAcordoSelectTable,
    updatePage,
    idProcesso,
    exibirApenasAcordosAtivosEhPropostas,
  ]);

  const updatePageAcordo = useCallback(async () => {
    updatePage();
    pesquisarAcordos(settingsTable.page, settingsTable.rowsPerPage, columnOrdenacao, exibirApenasAcordosAtivosEhPropostas);
  }, [pesquisarAcordos, settingsTable.page, settingsTable.rowsPerPage, columnOrdenacao, exibirApenasAcordosAtivosEhPropostas, updatePage]);

  const handleOnClickCancelarSimulacao = useCallback(async () => {
    try {
      await cancelarSimulacao(dadosAcordo.idAcordo, idProcesso);
      pesquisarAcordos(settingsTable.page, settingsTable.rowsPerPage, columnOrdenacao, exibirApenasAcordosAtivosEhPropostas);
      getDadosResumo(idProcesso).then((responseData) => {
        const { data } = responseData;
        setInfoAdicionaisAcordo(data);
      });
      updatePage();
      toast.success('Simulação cancelada com sucesso.');
    } catch {
      toast.error('Não foi possível cancelar a Simulação. Tente novamente.', { style: { width: '392px' } });
    } finally {
      setOpenButtonCancelarSimulacao(oldValue => !oldValue);
    }
  }, [pesquisarAcordos, dadosAcordo.idAcordo, idProcesso, settingsTable.page, settingsTable.rowsPerPage, columnOrdenacao, exibirApenasAcordosAtivosEhPropostas, updatePage]);

  const handleOnClickCancelarProposta = useCallback(async () => {
    try {
      await cancelarProposta(dadosAcordo.idAcordo, idProcesso);
      pesquisarAcordos(settingsTable.page, settingsTable.rowsPerPage, columnOrdenacao, exibirApenasAcordosAtivosEhPropostas);
      getDadosResumo(idProcesso).then((responseData) => {
        const { data } = responseData;
        setInfoAdicionaisAcordo(data);
      });
      updatePage();
      toast.success('Proposta cancelada com sucesso.');
    } catch {
      toast.error('Não foi possível cancelar a Proposta. Tente novamente.', { style: { width: '392px' } });
    } finally {
      setOpenButtonCancelarProposta(oldValue => !oldValue);
    }
  }, [pesquisarAcordos, dadosAcordo.idAcordo, idProcesso, settingsTable.page, settingsTable.rowsPerPage, columnOrdenacao, exibirApenasAcordosAtivosEhPropostas, updatePage]);

  return (
    <>
      <DialogNew
        open={openDialogEnviarParcelaAcordo}
        text="Informações do E-mail"
        className={styles.dialogEnviarParcelaAcordo}
      >
        {openDialogEnviarParcelaAcordo
          && (
            <DialogEnviarParcelaAcordo
              setOpenDialogEnviarParcelaAcordo={setOpenDialogEnviarParcelaAcordo}
              setLoadings={setLoadings}
              updatePage={updatePage}
              idAcordo={idAcordoEnviarParcelaAcordo}
              nroParcela={nroParcelaEnviarParcelaAcordo}
              tipoFormaPagto={tipoFormaPagtoEnviarParcelaAcordo}
            />
          )
        }
      </DialogNew>
      <OptionDialogNew
        open={openButtonCancelarSimulacao}
        confirmLabel="SIM"
        cancelLabel="NÃO"
        onClickCancel={() => { setOpenButtonCancelarSimulacao(false); }}
        onClickConfirm={handleOnClickCancelarSimulacao}
        text="Deseja realmente cancelar esta Simulação?"
      />
      <OptionDialogNew
        open={openButtonCancelarProposta}
        confirmLabel="SIM"
        cancelLabel="NÃO"
        onClickCancel={() => { setOpenButtonCancelarProposta(false); }}
        onClickConfirm={handleOnClickCancelarProposta}
        text="Deseja realmente cancelar esta Proposta?"
      />
      <DialogNew
        open={openButtonQuebrarAcordo}
        text="Quebra de Acordo"
        className={styles.dialogQuebrarAcordo}
      >
        {openButtonQuebrarAcordo && (
          <DialogQuebrarAcordo
            setOpenButtonQuebrarAcordo={setOpenButtonQuebrarAcordo}
            idProcesso={idProcesso}
            idFaseProcesso={idFaseProcesso}
            idAcordo={dadosAcordo.idAcordo}
            pesquisarAcordos={pesquisarAcordos}
            settingsTable={settingsTable}
            columnOrdenacao={columnOrdenacao}
            updatePage={updatePage}
            getDadosResumo={getDadosResumo}
            setInfoAdicionaisAcordo={setInfoAdicionaisAcordo}
            exibirApenasAcordosAtivosEhPropostas={exibirApenasAcordosAtivosEhPropostas}
          />
        )}
      </DialogNew>
      <DialogNew
        open={openDialogEnviarEmail}
        text="Enviar Termo de Acordo"
        className={styles.dialogEnviarEmai}
      >
        {openDialogEnviarEmail
          && (
            <DialogEnviarEmail
              setOpenDialogEnviarEmail={setOpenDialogEnviarEmail}
              setLoadings={setLoadings}
              updatePage={updatePageAcordo}
              idAcordo={idAcordoDialogEnviarEmail}
            />
          )
        }
      </DialogNew>
      <DialogNew
        open={openDialogDistribuicaoPersonalizada}
        text="Distribuição Personalizada"
        className={styles.dialogDistribuicaoPersonalizada}
      >
        {openDialogDistribuicaoPersonalizada
          && (
            <DialogDistribuicaoPersonalizada
              setOpenDialogDistribuicaoPersonalizada={setOpenDialogDistribuicaoPersonalizada}
              idAcordo={idAcordoDistribuicaoPersonalizada}
              setLoadings={setLoadings}
            />
          )
        }
      </DialogNew>
      <DialogNew
        open={openDialogRecibo}
        text="Informações do E-mail"
        className={styles.dialogRecibo}
      >
        {openDialogRecibo && (
          <DialogRecibo
            setOpenDialogRecibo={setOpenDialogRecibo}
            setLoadings={setLoadings}
            updatePage={updatePage}
            idAcordo={idAcordoDialogRecibo}
            tipoFormaPagtoDialogRecibo={tipoFormaPagtoDialogRecibo}
            nroParcelaDialogRecibo={nroParcelaDialogRecibo}
          />
        )
        }
      </DialogNew>
      <DialogNew
        open={openDialogAnaliseSimulacao}
        text="Análise da Proposta"
        className={styles.dialogAnaliseSimulacao}
        onClose={() => setOpenDialogAnaliseSimulacao(false)}
      >
        {openDialogAnaliseSimulacao && (
          <DialogAnaliseSimulacao
            setOpenDialogAnaliseSimulacao={setOpenDialogAnaliseSimulacao}
            selectedAcordoAnaliseSimulacao={selectedAcordoAnaliseSimulacao}
            updatePage={updatePage}
            setLoadings={setLoadings}
            settingsTable={settingsTable}
            pesquisarAcordos={pesquisarAcordos}
            columnOrdenacao={columnOrdenacao}
            getDadosResumo={getDadosResumo}
            idProcesso={idProcesso}
            setInfoAdicionaisAcordo={setInfoAdicionaisAcordo}
            exibirApenasAcordosAtivosEhPropostas={exibirApenasAcordosAtivosEhPropostas}
          />
        )}
      </DialogNew>
      <div className={styles.div__header}>
        <OptionDialogNew
          open={openDialogFecharAcordo}
          confirmLabel="Confirmar"
          cancelLabel="Cancelar"
          onClickCancel={handleOnClickCancelFecharAcordo}
          onClickConfirm={handleOnClickFecharAcordo}
          text="Esta operação transforma uma Simulação em um Acordo ativo. "
        />
        <DialogNew
          open={openDialogAcompanhamento}
          text="Acompanhamento"
          className={styles.dialogAcompanhamento}
        >
          {openDialogAcompanhamento && (
            <DialogAcompanhamento
              textoAcompanhamento={textoAcompanhamento}
              setOpenDialogAcompanhamento={setOpenDialogAcompanhamento}
            />
          )}
        </DialogNew>
        <DialogNew
          open={openDialogAlterarDinheiro}
          text="Dinheiro: Alterar Parcela"
          className={styles.alterarParcelaSize}
        >
          {openDialogAlterarDinheiro && (
            <DialogAlterarDinheiro
              setOpenDialogAlterarDinheiro={setOpenDialogAlterarDinheiro}
              dadosParcelaDinheiro={dadosParcelaDinheiro}
              idProcesso={idProcesso}
              nroParcela={nroParcelaDialogAlterar}
              idAcordo={idAcordoDialogAlterar}
              idAcordoParcela={idAcordoParcelaDialogAlterar}
              tipoFormPgtp={tipoFormaPagtoDialogAlterar}
              setLoadings={setLoadings}
              updatePage={updatePage}
            />
          )}
        </DialogNew>
        <DialogNew
          open={openDialogAlterarBoleto}
          text="Alterar Boleto"
          className={styles.alterarParcelaSize}
        >
          {openDialogAlterarBoleto && (
            <DialogAlterarBoleto
              setOpenDialogAlterarBoleto={setOpenDialogAlterarBoleto}
              dadosParcelaBoleto={dadosParcelaBoleto}
              idProcesso={idProcesso}
              nroParcela={nroParcelaDialogAlterar}
              idAcordo={idAcordoDialogAlterar}
              idAcordoParcela={idAcordoParcelaDialogAlterar}
              tipoFormPgtp={tipoFormaPagtoDialogAlterar}
              setLoadings={setLoadings}
              updatePage={updatePage}
              dados={dados}
              parcela={parcela}
            />
          )}
        </DialogNew>
        <DialogNew
          open={openDialogAlterarPix}
          text="Pix: Alterar Parcela"
          className={styles.alterarParcelaSize}
        >
          {openDialogAlterarPix && (
            <DialogAlterarPix
              setOpenDialogAlterarPix={setOpenDialogAlterarPix}
              dadosParcelaPix={dadosParcelaPix}
              idProcesso={idProcesso}
              nroParcela={nroParcelaDialogAlterar}
              idAcordo={idAcordoDialogAlterar}
              idAcordoParcela={idAcordoParcelaDialogAlterar}
              tipoFormPgtp={tipoFormaPagtoDialogAlterar}
              setLoadings={setLoadings}
              updatePage={updatePage}
              dados={dados}
              parcela={parcela}
            />
          )}
        </DialogNew>
        <DialogNew
          open={openDialogAlterarCartao}
          text="Cartão: Alterar Parcela"
          className={styles.alterarParcelaSize}
        >
          {openDialogAlterarCartao && (
            <DialogAlterarCartao
              setOpenDialogAlterarCartao={setOpenDialogAlterarCartao}
              dadosParcelaCartao={dadosParcelaCartao}
              idProcesso={idProcesso}
              nroParcela={nroParcelaDialogAlterar}
              idAcordo={idAcordoDialogAlterar}
              idAcordoParcela={idAcordoParcelaDialogAlterar}
              tipoFormPgtp={tipoFormaPagtoDialogAlterar}
              setLoadings={setLoadings}
              updatePage={updatePage}
            />
          )}
        </DialogNew>
        <DialogNew
          open={openDialogAlterarCheque}
          text="Cheque: Alterar Parcela"
          className={styles.alterarParcelaSize}
        >
          {openDialogAlterarCheque && (
            <DialogAlterarCheque
              setOpenDialogAlterarCheque={setOpenDialogAlterarCheque}
              dadosParcelaCheque={dadosParcelaCheque}
              idProcesso={idProcesso}
              nroParcela={nroParcelaDialogAlterar}
              idAcordo={idAcordoDialogAlterar}
              idAcordoParcela={idAcordoParcelaDialogAlterar}
              tipoFormPgtp={tipoFormaPagtoDialogAlterar}
              setLoadings={setLoadings}
              updatePage={updatePage}
            />
          )}
        </DialogNew>
        <div>
          <TooltipUI title="Novo Acordo ou Simulação">
            <ButtonNovoAcordo
              disabled={
                !podeModificarFase
                || infoAdicionaisAcordo.processoComTitulosLiberados === false
                || dadosProcesso.nomeUsuario === null
              }
              active={openButtonNovoAcordo.toString()}
              onClick={onClickButtonNovoAcordo}
            >
              <FaHandshake />
            </ButtonNovoAcordo>
          </TooltipUI>
          {openButtonNovoAcordo === true && (
            <NovoAcordo
              setLoadings={setLoadings}
              idProcesso={idProcesso}
              setOpenButtonNovoAcordo={setOpenButtonNovoAcordo}
              pesquisarAcordos={pesquisarAcordos}
              settingsTable={settingsTable}
              columnOrdenacao={columnOrdenacao}
              updatePage={updatePage}
              getDadosResumo={getDadosResumo}
              setInfoAdicionaisAcordo={setInfoAdicionaisAcordo}
              exibirApenasAcordosAtivosEhPropostas={exibirApenasAcordosAtivosEhPropostas}
            />
          )}
        </div>
        <InfoAdicionaisAcordo conteudoCards={infoAdicionaisAcordo} />
        <div>
          <TableHeader className={styles.acordoSimulacao}>Acordos e Simulações</TableHeader>
          {settingsTable?.content?.length > 0
            || infoAdicionaisAcordo?.qtdAcordosQuitados > 0
            || infoAdicionaisAcordo?.qtdAcordosQuebrados > 0//
            || infoAdicionaisAcordo?.qtdAcordosSimulados > 0 ? (
            <div>
              <TableAcordos
                columns={acordosFactory.makeCabecalhoTabelaAcordos(
                  setArrowOrdenacao,
                  arrowOrdenacao,
                  columnOrdenacao,
                  setColumnOrdenacao,
                  onClickOrdernacaoColumn,
                )}
                collapse={collapse}
                setCollapse={setCollapse}
                rows={tableRows}
                page={settingsTable.page}
                size={settingsTable.size}
                rowsPerPage={settingsTable.rowsPerPage}
                rowsPerPageOptions={[10, 15, 20]}
                onChangePage={onChangePageHandler}
                onChangeRowsPerPage={onChangeRowsPerPageHandler}
                tableRowsAccordion={tableRowsAccordion}
                pageAccordion={settingsTableAccordion.page}
                sizeAccordion={settingsTableAccordion.size}
                rowsPerPageAccordion={settingsTableAccordion.rowsPerPage}
                columnsAccordion={acordosFactory.makeCabecalhoTabelaAcordosAccordion()}
                setExibirApenasAcordosAtivosEhPropostas={setExibirApenasAcordosAtivosEhPropostas}
                exibirApenasAcordosAtivosEhPropostas={exibirApenasAcordosAtivosEhPropostas}
                pesquisarAcordos={pesquisarAcordos}
                columnOrdenacao={columnOrdenacao}
                onChangeSwitch={onChangeSwitch}
                setDadosLength={handleDataFromChild}
              />
            </div>
          ) : (
            <div className={styles.border}>
              <div className={styles.semAcordos}>
                <FaExclamationTriangle size={48} color="#FFA000" />
              </div>
              <div className={styles.semAcordosLabel}>
                <p> Não existem Acordos ou Simulações para este Processo.</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Acordos;
