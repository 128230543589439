import React from 'react';
import RamoAtividadeActionsTable from '../../../components/RamoAtividade/Pesquisa/RamoAtividadeActionsTable';
import SelectorModel from '../../../models/SelectorModel';

export const buildRows = (ramos, onEdit, onRemove) => ramos.map(ramo => ({
  id: ramo.idRamoAtividade,
  values: {
    nomeRamo: ramo.nomeRamoAtividade,
    acoes: (
      <RamoAtividadeActionsTable
        id={ramo.idRamoAtividade}
        nome={ramo.nomeRamoAtividade}
        onRemove={onRemove}
        onEdit={onEdit}
        allows={{
          editar: 401,
          excluir: 401,
        }}
        userAllow="U"
      />
    ),
  },
}));

export const buildRamosAtividadeSelector = ramosAtividade => ramosAtividade.map(
  ramoAtividade => new SelectorModel(ramoAtividade.idRamoAtividade, ramoAtividade.nomeRamoAtividade),
);
