import { Button } from '@material-ui/core';
import styled from 'styled-components';
import {
  PaperUI, ButtonUI, SelectorField, PeriodSelector as PeriodSelectorUi,
} from '../../components';

export const Container = styled(PaperUI)`
  padding: 22px;
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .filters {
    display: flex;
    flex-direction: column;
    width: 589px;
  }

  .additional-info {
    display: flex;
    flex-direction: column;
  }
`;

export const SaldoAtualContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    display: block;
    margin-bottom: 9px;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  >div {
    display: flex;
    justify-content: space-around;

    >div + div {
      margin-left: 25px;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  box-sizing: border-box;
  width: 125px;
  height: 59px;
  border-radius: 5px;

  background-color: ${props => props.color};

  p {
    display: block;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;

    color: #FFFFFF;
  }

  p:first-child {
    padding-top: 5px;
  }

  p:last-child {
    padding-top: 11px;
    padding-bottom: 13px;
  }
`;

export const ButtonNovo = styled(ButtonUI)`
  align-self: flex-end;
  margin-top: 39px;

  width: 152px;
`;

export const SelectFilial = styled(SelectorField)`
  &.MuiFormControl-root {
    max-width: 302px;
  }
`;

export const ButtonImprimir = styled(Button)`
  display: flex;
  justify-content: space-between;

  margin-top: 12px;

  &.MuiButtonBase-root {
    width: 73px;
    padding: 4px 0;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: none;
    
    color: #000000;
    
    &.Mui-disabled {
      opacity: 0.64;
    }
  }
`;

export const PeriodSelector = styled(PeriodSelectorUi)`
  margin-top: 15px;
`;

export const TableContainer = styled.div`
  margin-top: 19px;
  box-sizing: border-box;

  >p {
    display: block;
    padding: 13px;
    
    font-family: Roboto;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
  }
`;

export const TableActionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  height: 42px;
  
  background-color: #fec1dd;
  p {
    display: block;
    margin-left: 15px;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;

    color: #000000;

    span {
      display: inline-block;
      padding-left: 10px;
    }
  }
`;

export const ApprovedButton = styled(Button)`
  display: block;
  box-sizing: border-box;

  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  text-transform: none;

  width: 135px;
  height: 100%;

  border-radius: 0;
  background-color: #FF1381;
  color: white;

  svg {
    margin-right: 15px;
  }

  .MuiButton-label {
    display: flex;
    align-items: center;
  }
`;
