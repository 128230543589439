import React from 'react';

import CadastrarClientePage from './Crud/Cadastrar/CadastrarClientePage';
import EditarClientePage from './Crud/Atualizar/EditarClientePage';
import DetalharClientePage from './Crud/Detalhes/DetalharClientePage';
import PrivateRoute from '../../hoc/PrivateRoute/PrivateRoute';
import PesquisaCliente from './Crud/Pesquisar/PesquisaClientePage';

export default function RoutesCliente() {
  return (
    <>
      <PrivateRoute
        exact
        tipoUsuario="U"
        path="/cliente"
        component={PesquisaCliente}
      />
      <PrivateRoute
        path="/cliente/cadastrar"
        tipoUsuario="U"
        roles={['502']}
        component={CadastrarClientePage}
      />
      <PrivateRoute
        path="/cliente/:id/editar"
        tipoUsuario="U"
        roles={['504']}
        component={EditarClientePage}
      />
      <PrivateRoute
        path="/cliente/:id/detalhar"
        exact
        tipoUsuario="U"
        roles={['501', '504']}
        component={DetalharClientePage}
      />
    </>
  );
}
