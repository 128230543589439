import FieldModel from '../../../../../models/FieldModel';


class EnviarProcessosOutraFaseController {
  buildInitialStatesEnviarProcessosOutraFase() {
    return {
      idFase: new FieldModel({ name: 'idFase', value: '' }),
      idFasePasta: new FieldModel({ name: 'idFasePasta', value: '' }),
      idMotivoBaixa: new FieldModel({ name: 'idMotivoBaixa', value: '' }),
      idNegociador: new FieldModel({ name: 'idNegociador', value: '' }),
      liberarRequisicao: new FieldModel({ name: 'liberarRequisicao', value: true }),
      recalcularValorProcesso: new FieldModel({ name: 'recalcularValorProcesso', value: true }),
      liberarCompromissos: new FieldModel({ name: 'liberarCompromissos', value: true }),
    };
  }
}

export const enviarProcessosOutraFaseController = new EnviarProcessosOutraFaseController();
