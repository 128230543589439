import { useState, useEffect } from 'react';

function getWindowDimensions(hasWindow, element) {
  const width = hasWindow ? element.innerWidth : null;
  const height = hasWindow ? element.innerHeight : null;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined';


  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions(hasWindow, window));

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions(hasWindow, window));
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    return () => { };
  }, [hasWindow]);

  return windowDimensions;
}
