import { SET_COMMON_ACTION, SET_SIMPLE_EMAIL_ENTRADA_BORDERO_ACTION } from './EmailEntradaBordero.constants';

function buildInitialStates() {
  return {
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    tableView: [],
    common: {
      loadingPage: false,
    },
  };
}

export const setSimpleEmailEntradaBorderoAction = (name, value) => ({
  type: SET_SIMPLE_EMAIL_ENTRADA_BORDERO_ACTION,
  name,
  value,
});

export const setCommonAction = (name, value) => ({
  type: SET_COMMON_ACTION,
  name,
  value,
});

function setSimpleEmailEntradaBorderoHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    [name]: value,
  };
}

function setCommonHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    common: {
      ...states.common,
      [name]: value,
    },
  };
}

export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    case SET_SIMPLE_EMAIL_ENTRADA_BORDERO_ACTION:
      return setSimpleEmailEntradaBorderoHandler(states, actions);
    case SET_COMMON_ACTION:
      return setCommonHandler(states, actions);
    default:
      return states;
  }
};
