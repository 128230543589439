import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CrudFasesPastasProvider from '../../../store/FasesPastas/Crud/CrudFasesPastasProvider';
import PastasProvider from '../../../store/FasesPastas/Pastas/PastasProvicer';

import { initializePageAction } from '../../../store/theme.actions';
import withTokenInterceptor from '../../../hoc/withTokenInterceptor/withTokenInterceptor';

import constants from '../../../helpers/constants/navigationTitle.constants';
import CrudFasesPastas from '../../../components/FasesPastas/FasesPastas';
import { checkPermission } from '../../../helpers/utils/utils';

function CrudFasesPastasPage() {
  const dispatch = useDispatch();
  dispatch(initializePageAction(`${constants.FASES_PASTAS}`));

  const permissoesUsuario = useSelector(states => states.authentication.userInformation);
  const hasEditPermission = !checkPermission(permissoesUsuario.permissoes, 1001, 'U', 'U');
  return (
    <CrudFasesPastasProvider>
      <PastasProvider>
        <CrudFasesPastas hasEditPermission={hasEditPermission} />
      </PastasProvider>
    </CrudFasesPastasProvider>
  );
}

export default withTokenInterceptor(CrudFasesPastasPage);
