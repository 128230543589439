import React, { useCallback } from 'react';
import { FaEraser } from 'react-icons/fa';
import { InputLabel, Tooltip } from '@material-ui/core';
import { SimpleTitle } from '../../../../../../components/Title/SimpleTitle/SimpleTitle';
import useStyle, {
  ButtonEmitir,
  Container, CustomButtonLimpar, Header, Input,
} from './NotasFiscaisStyle';
import IconAgrupamentoBlue from '../../../../../../assets/images/icons/iconAgrupamentoBlue.svg';
import { ButtonUI, InputField, SelectorField } from '../../../../../../components';
import { getRequiredLabel } from '../../../../../../helpers';
import MoneyField from '../../../../../../components/UI/Field/Money/MoneyField';
import Dica from '../../../../../../components/UI/Dica/Dica';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { onFocusAction, resetFormFieldsNovaNotaAction, setNovaNotaAction } from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { inserirNovaNotaFiscalAction, pesquisarNotasFiscaisAction } from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';

function MakeTipoNota() {
  return [
    { id: 'AMIGAVEIS_DESPESAS', value: 'Honorários Amigáveis/Despesas' },
    { id: 'JUDICIAIS', value: 'Honorários Judiciais' },
  ];
}

function NovaNotaFiscalComponent() {
  const style = useStyle();
  const dispatch = useCCClienteDispatch();

  const tipoNota = React.useMemo(() => MakeTipoNota(), []);
  const formFields = useCCClienteSelector(states => states.notasFiscais.formFields);
  const requerInformacaoExtraNF = useCCClienteSelector(states => (states.relacaoPrestacao.informacoesAdicionais.requerInformacaoExtraNF));
  const detalhesInfoExtraNF = useCCClienteSelector(states => (states.relacaoPrestacao.informacoesAdicionais.detalhesInfoExtraNF));
  const prestacaoComNotaFiscalAgrupada = useCCClienteSelector(states => (states.relacaoPrestacao.informacoesAdicionais.prestacaoComNotaFiscalAgrupada));
  const idsPrestacoesAgrupadas = useCCClienteSelector(states => (states.relacaoPrestacao.informacoesAdicionais.idsPrestacoesAgrupadas));

  const disabledLimpar = formFields.despesas.value === ''
    && formFields.honorarios.value === ''
    && formFields.informacaoComplementar.value === ''
    && formFields.informacaoExtra.value === ''
    && formFields.tipoNovaNFCliente.value === '';


  const getDisabledButton = () => {
    if (formFields.tipoNovaNFCliente.value === 'AMIGAVEIS_DESPESAS') {
      return !(
        formFields.tipoNovaNFCliente.value
        && (formFields.honorarios.value
          || formFields.despesas.value)

      );
    } if (formFields.tipoNovaNFCliente.value === 'JUDICIAIS') {
      return !(
        formFields.tipoNovaNFCliente.value
        && formFields.honorarios.value
      );
    }
    return !(formFields.tipoNovaNFCliente.value);
  };

  const getDisabledButtonInfoExtra = () => {
    if (requerInformacaoExtraNF === true) {
      return !(
        formFields.informacaoExtra.value
      );
    } return null;
  };

  const onChangeFields = (event) => {
    const { name, value, checked } = event.target;
    dispatch(setNovaNotaAction(name, checked || value));
  };

  const onFocus = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(onFocusAction(fieldName));
  }, [dispatch]);

  const onSubmitHandler = useCallback(() => {
    dispatch(inserirNovaNotaFiscalAction());
    dispatch(pesquisarNotasFiscaisAction());
  }, [dispatch]);

  const onClickLimpar = useCallback(() => {
    dispatch(resetFormFieldsNovaNotaAction());
  }, [dispatch]);

  return (
    <>
      <Container>
        <div style={{ justifyContent: 'space-between' }}>
          <div>
            <SimpleTitle
              text="Nova Nota Fiscal"
            />
            {prestacaoComNotaFiscalAgrupada === true ? (
              <Tooltip title={idsPrestacoesAgrupadas}>
                <img src={IconAgrupamentoBlue} alt="" width="15px" height="14px" />
              </Tooltip>
            ) : (
              ''
            )}
          </div>
          <div>
            <CustomButtonLimpar disabled={disabledLimpar} onClick={onClickLimpar}>
              <FaEraser size={18} />
              <span>Limpar</span>
            </CustomButtonLimpar>
          </div>
        </div>
        <Header>
          <SelectorField
            isForm
            label={getRequiredLabel('Tipo de Nota Fiscal', true)}
            name="tipoNovaNFCliente"
            items={tipoNota}
            value={formFields.tipoNovaNFCliente.value}
            error={formFields.tipoNovaNFCliente.error}
            errorMessage={formFields.tipoNovaNFCliente.errorMessage}
            onChange={onChangeFields}
            onFocus={onFocus}
          />
          {formFields.tipoNovaNFCliente.value === 'AMIGAVEIS_DESPESAS' && (
            <>
              <MoneyField
                isForm
                fullWidth
                label="Honorários"
                name="honorarios"
                value={formFields.honorarios.value}
                error={formFields.honorarios.error}
                errorMessage={formFields.honorarios.errorMessage}
                onChange={onChangeFields}
                onFocus={onFocus}
              />
              <MoneyField
                isForm
                fullWidth
                label="Despesas"
                name="despesas"
                value={formFields.despesas.value}
                error={formFields.despesas.error}
                errorMessage={formFields.despesas.errorMessage}
                onChange={onChangeFields}
                onFocus={onFocus}
              />
            </>
          )}
          {formFields.tipoNovaNFCliente.value === 'JUDICIAIS' && (
            <MoneyField
              isForm
              fullWidth
              label="Honorários *"
              name="honorarios"
              value={formFields.honorarios.value}
              error={formFields.honorarios.error}
              errorMessage={formFields.honorarios.errorMessage}
              onChange={onChangeFields}
            />
          )}
        </Header>
        <Input>
          <div style={{ display: 'flow-root' }}>
            {requerInformacaoExtraNF === true ? (
              <>
                <div className={style.inputField}>
                  <InputLabel className={style.label}>Informação Extra*</InputLabel>
                  <Dica mensagemDica={detalhesInfoExtraNF} />
                </div>
                <InputField
                  isForm
                  fullWidth
                  name="informacaoExtra"
                  value={formFields.informacaoExtra.value}
                  error={formFields.informacaoExtra.error}
                  errorMessage={formFields.informacaoExtra.errorMessage}
                  onChange={onChangeFields}
                  onFocus={onFocus}
                />
              </>
            ) : (
              <>
                <div className={style.inputField}>
                  <InputLabel className={style.label}>Informação Extra</InputLabel>
                  <Dica mensagemDica="Não exige a Informação Extra" />
                </div>
                <InputField
                  isForm
                  fullWidth
                  name="informacaoExtra"
                  disabled
                />
              </>
            )}

          </div>
          <InputField
            isForm
            fullWidth
            label="Informação Complementar"
            name="informacaoComplementar"
            value={formFields.informacaoComplementar.value}
            onChange={onChangeFields}
          />
        </Input>
        <ButtonEmitir>
          <ButtonUI
            color="success"
            type="submit"
            disabled={getDisabledButton() || getDisabledButtonInfoExtra()}
            onClick={onSubmitHandler}
          >
            Emitir
          </ButtonUI>
        </ButtonEmitir>
      </Container>
    </>
  );
}
export const NovaNotaFiscal = NovaNotaFiscalComponent;
