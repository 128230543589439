import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import {
  DialogActions, DialogContent, DialogContentText, DialogTitle, Paper,
} from '@material-ui/core';
import { Button, Dialog } from '@mui/material';
import Draggable from 'react-draggable';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import { melhorarTextService } from '../../services/core/processos/processosService';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        style={{ minWidth: '650px' }}
        {...props}
      />
    </Draggable>
  );
}

export default function DialogGPT({
  open, onClose, initialText, updateText,
}) {
  const [outputText, setOutputText] = useState('');
  const pergunta = 'Reescreva o texto em português formal seguindo as condições: Não altere as tags marcadas com <$tag$> caso existam e mantenha o formato do texto, caso seja enviado um HTML também retorne um HTML. Texto';
  const [disabled, setDisabled] = useState(false);
  const [primeiraExecucao, setPrimeiraExecucao] = useState(true);

  const handleOnClose = () => {
    setPrimeiraExecucao(true);
    onClose();
  };

  const handleAccept = () => {
    updateText(outputText);
    handleOnClose();
  };

  const inputTextRef = useRef(initialText);

  const melhorarText = useCallback(async () => {
    setDisabled(true);
    try {
      const cleanedInputText = inputTextRef.current.replace(/\n+/g, '\n');
      const response = await melhorarTextService(`${pergunta}: ${cleanedInputText}`);
      if (response.status === 200) {
        setOutputText(response.data.choices[0].message.content);
        inputTextRef.current = response.data.choices[0].message.content;
      }
    } catch (error) {
      setOutputText('');
      toast.error('Erro ao reescrever texto.');
    } finally {
      setDisabled(false);
    }
  }, [setOutputText, setDisabled, pergunta]);

  useEffect(() => {
    if (open && primeiraExecucao) {
      melhorarText();
      setPrimeiraExecucao(false);
    }
    inputTextRef.current = initialText;
  }, [open, initialText, melhorarText, primeiraExecucao]);

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="draggable-dialog-title"
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="draggable-dialog-title" style={{ marginTop: '10px', cursor: 'move' }}>
        Reescrever
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ whiteSpace: 'pre-line', color: 'black' }}>
          {disabled ? 'Reescrevendo...' : parse(outputText)}
        </DialogContentText>
      </DialogContent>
      <div style={{ padding: '9px' }}>
        <DialogActions>

          <div style={{ display: 'flex', width: '100%', justifyContent: 'start' }}>
            <Button onClick={melhorarText} disabled={disabled}>
              Tentar Novamente
            </Button>
          </div>
          <Button onClick={handleAccept} disabled={disabled}>
            Aceitar
          </Button>
          <Button autoFocus onClick={handleOnClose} style={{ marginRight: '10px' }}>
            Cancelar
          </Button>

        </DialogActions>
      </div>
    </Dialog>
  );
}
