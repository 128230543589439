import makeStyles from '@material-ui/core/styles/makeStyles';
import { alternativeTableTitile, paleGrey } from '../../../helpers/constants/colors.constants';

export default makeStyles({
  wrapper: props => ({
    width: '90%',
    position: 'relative',
    overflow: 'auto',
    heigth: props.height,
    maxHeight: props.height,
    borderRadius: '7px',
    '& .MuiListSubheader-root': {
      lineHeight: '1.19',
      color: 'black',
      fontSize: '1rem',
      fontWeight: 'bold',
      backgroundColor: alternativeTableTitile,
    },
    '& .MuiListItem-root': {
      paddingTop: '0',
      paddingBottom: '0',
      fontSize: '1rem',
      borderBottom: '1px solid #CECECE',
      backgroundColor: paleGrey,
      ' & .MuiTypography-body1': {
        fontSize: '12px !important',
      },
    },
  }),
});
