export const SET_COMMON_ACTION = '@contaCorrenteCliente/SET_COMMON_ACTION';
export const SET_FORM_PESQUISA_CLIENTE_ACTION = '@contaCorrenteCliente/SET_FORM_PESQUISA_CLIENTE_ACTIONS';
export const RESET_FORM_FIELDS_PESQUISA_ACTION = '@contaCorrenteCliente/RESET_FORM_FIELDS_PESQUISA_ACTION';
export const SET_SIMPLE_VALUES_PESQUISA_ACTION = '@contaCorrenteCliente/SET_SIMPLE_VALUES_PESQUISA_ACTION';
export const SET_SIMPLE_VALUES_REPASSE_ACTION = '@contaCorrenteCliente/SET_SIMPLE_VALUES_REPASSE_ACTION';
export const SET_SIMPLE_VALUES_DETALHES_ACTION = '@contaCorrenteCliente/SET_SIMPLE_VALUES_DETALHES_ACTION';
export const SET_DETALHES_CLIENTE_SELECIONADO_ACTION = '@contaCorrenteCliente/SET_DETALHES_CLIENTE_SELECIONADO_ACTION';
export const SET_DEFAULT_CLIENTE_SELECIONADO_ACTION = '@contaCorrenteCliente/SET_DEFAULT_CLIENTE_SELECIONADO_ACTION';
export const SET_CLIENTE_SELECIONADO_ACTION = '@contaCorrenteCliente/SET_CLIENTE_SELECIONADO_ACTION';
export const PESQUISAR_CONTAS_CORRENTES_CLIENTE_ONCLICK_ACTION = '@contaCorrenteCliente/PESQUISAR_CONTAS_CORRENTES_CLIENTE_ACTION';
export const BUSCAR_DETALHES_CLIENTE_SELECIONADO_ACTION = '@contaCorrenteCliente/BUSCAR_DETALHES_CLIENTE_SELECIONADO_ACTION';
export const PRESTAR_CONTAS_CLIENTE_SELECIONADO_ACTION = '@contaCorrenteCliente/PRESTAR_CONTAS_CLIENTE_SELECIONADO_ACTION';
export const PESQUISAR_CONTA_CORRENTE_CLIENTES_ACTION = '@contaCorrenteCliente/PESQUISAR_CLIENTE_ACTION';
export const ATUALIZAR_PAGINA_ACTION = '@contaCorrenteCliente/ATUALIZAR_PAGINA_ACTION';
export const SET_SIMPLE_VALUE_NAO_PRESTADOS_ACTION = '@contaCorrenteCliente/SET_SIMPLE_VALUE_NAO_PRESTADOS_ACTION';
export const RESET_STATES_EMPTY_VALUE = '@contaCorrenteCliente/RESET_STATES_EMPTY_VALUE';
export const DELETE_LANCAMENTO_NAO_PRESTADO_CLIENTE_SELECIONADO = '@contaCorrenteCliente/DELETE_LANCAMENTO_NAO_PRESTADO_CLIENTE_SELECIONADO';
export const SET_SIMPLE_VALUE_RELACAO_PRESTACAO_ACTION = '@contaCorrenteCliente/SET_SIMPLE_VALUE_RELACAO_PRESTACAO_ACTION';
export const SET_FORM_FIELDS_RELACAO_PRESTACAO_ACTION = '@contaCorrenteCliente/SET_FORM_FIELDS_RELACAO_PRESTACAO_ACTION';
export const DISABLE_FIELDS_RELACAO_PRESTACAO_ACTION = '@contaCorrenteCliente/DISABLE_FIELDS_RELACAO_PRESTACAO_ACTION';
export const ON_FOCUS_PERIOD_FIELD_RELACAO_PRESTACAO_ACTION = '@contaCorrenteCliente/ON_FOCUS_PERIOD_FIELD_RELACAO_PRESTACAO_ACTION';
export const PESQUISAR_PRESTACAO_INICIAL_ACTION = '@contaCorrenteCliente/PESQUISAR_PRESTACAO_INICIAL_ACTION';
export const CANCELAR_PRESTACAO_ACTION = '@contaCorrenteCliente/CANCELAR_PRESTACAO_ACTION';
export const ESTORNAR_PRESTACAO_ACTION = '@contaCorrenteCliente/ESTORNAR_PRESTACAO_ACTION';
export const BUSCAR_MOTIVO_ESTORNO_PRESTACAO_ACTION = '@contaCorrenteCliente/BUSCAR_MOTIVO_ESTORNO_PRESTACAO_ACTION';
export const ATUALIZAR_STATUS_LANCAMENTO_NAO_PRESTADO_ACTION = '@contaCorrenteCliente/ATUALIZAR_STATUS_LANCAMENTO_NAO_PRESTADO_ACTION';
export const PRESTAR_TODOS_ACTION = '@contaCorrenteCliente/PRESTAR_TODOS_ACTION';
export const DOWNLOAD_RELATORIO_CLIENTE = '@contaCorrenteCliente/DOWNLOAD_RELATORIO_CLIENTE';
export const DOWNLOAD_RELATORIO_LANCAMENTOS_NAO_PRESTADOS = '@contaCorrenteCliente/DOWNLOAD_RELATORIO_LANCAMENTOS_NAO_PRESTADOS';
export const SET_DEFAULT_NAO_PRESTADOS = '@contaCorrenteCliente/SET_DEFAULT_NAO_PRESTADOS';
export const SET_NAO_PRESTADOS_FORM_TO_SAVE = '@contaCorrenteCliente/SET_NAO_PRESTADOS_FORM_TO_SAVE';
export const FIND_EDITAR_LANCAMENTO_ACTION = '@contaCorrenteCliente/FIND_EDITAR_LANCAMENTO_ACTION';
export const SET_SIMPLE_VALUE_NAO_PRESTADOS_FORM_TO_SAVE_ACTION = '@contaCorrenteCliente/SET_SIMPLE_VALUE_NAO_PRESTADOS_FORM_TO_SAVE_ACTION';
export const SAVE_LANCAMENTO_NAO_PRESTADO_ACTION = '@contaCorrenteCliente/SAVE_LANCAMENTO_NAO_PRESTADO_ACTION';
export const EDIT_LANCAMENTO_NAO_PRESTADO_ACTION = '@contaCorrenteCliente/EDIT_LANCAMENTO_NAO_PRESTADO_ACTION';
export const ON_FOCUS_REQUEST_FIELDS_NAO_PRESTADOS_FORM_TO_SAVE = '@contaCorrenteCliente/ON_FOCUS_REQUEST_FIELDS_NAO_PRESTADOS_FORM_TO_SAVE';
export const BUSCAR_RELACOES_DO_CLIENTE_ACTION = '@contaCorrenteCliente/BUSCAR_RELACOES_DO_CLIENTE_ACTION';
export const BUSCAR_SALDOS_RELACAO_PRESTACAO_ACTION = '@contaCorrenteCliente/BUSCAR_SALDOS_RELACAO_PRESTACAO_ACTION';
export const SET_RELACAO_PRESTACAO_ERROR_ACTION = '@contaCorrenteCliente/SET_RELACAO_PRESTACAO_ACTION_ERROR';
export const RESET_FORM_FIELDS_RELACAO_PRESTACAO_ACTION = '@contaCorrenteCliente/RESET_FORM_FIELDS_RELACAO_PRESTACAO_ACTION';
export const PRINT_RELACAO_PRESTACAO_ACTION = '@contaCorrenteCliente/PRINT_RELACAO_PRESTACAO_ACTION';
export const ENVIAR_EMAIL_PRESTACAO_ACTION = '@contaCorrenteCliente/ENVIAR_EMAIL_PRESTACAO_ACTION';
export const PESQUISAR_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION = '@contaCorrenteCliente/PESQUISAR_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION';
export const SET_SIMPLE_VALUE_PAGAMENTOS_ACTION = '@contaCorrenteCliente/SET_SIMPLE_VALUE_PAGAMENTOS_ACTION';
export const FIND_PAGAMENTOS_ACTION = '@contaCorrenteCliente/FIND_PAGAMENTOS_ACTION';
export const DELETE_LANCAMENTO_PAGAMENTO_ACTION = '@contaCorrenteCliente/DELETE_LANCAMENTO_PAGAMENTO_ACTION';
export const SET_SIMPLE_VALUE_PAGAMENTOS_VALOR_ACTION = '@contaCorrenteCliente/SET_SIMPLE_VALUE_PAGAMENTOS_VALOR_ACTION';
export const INSERIR_LANCAMENTO_PAGAMENTO_ACTION = '@contaCorrenteCliente/INSERIR_LANCAMENTO_PAGAMENTO_ACTION';
export const ON_FOCUS_REQUEST_FIELDS_PAGAMENTOS_ACTION = '@contaCorrenteCliente/ON_FOCUS_REQUEST_FIELDS_PAGAMENTOS_ACTION';
export const DOWNLOAD_RELATORIO_PAGAMENTOS = '@contaCorrenteCliente/DOWNLOAD_RELATORIO_PAGAMENTOS';
export const PESQUISAR_REPASSE_ACTION = '@contaCorrenteCliente/PESQUISAR_REPASSE_ACTION';
export const SET_SIMPLE_AUTORIZACAO_REPASSE_ACTION = '@contaCorrenteCliente/SET_SIMPLE_AUTORIZACAO_REPASSE_ACTION';
export const PESQUISAR_LISTA_AUTORIZACAO_REPASSE_ACTION = '@contaCorrenteCliente/PESQUISAR_LISTA_AUTORIZACAO_REPASSE_ACTION';
export const SET_FORM_FIELDS_AUTORIZACAO_REPASSE_ACTION = '@contaCorrenteCliente/SET_FORM_FIELDS_AUTORIZACAO_REPASSE_ACTION';
export const ATUALIZAR_STATUS_LIDO_AUTORIZACAO_REPASSE_ACTION = '@contaCorrenteCliente/ATUALIZAR_STATUS_LIDO_AUTORIZACAO_REPASSE_ACTION';
export const DOWNLOAD_ARQUIVO_REMESSA_ACTION = '@contaCorrenteCliente/DOWNLOAD_ARQUIVO_REMESSA_ACTION';
export const DOWNLOAD_ARQUIVO_AUTORIZACAO_REPASSE_ACTION = '@contaCorrenteCliente/DOWNLOAD_ARQUIVO_AUTORIZACAO_REPASSE_ACTION';
export const SET_FORM_CHANGE_REPASSE_ACTION = '@contaCorrenteCliente/SET_FORM_REPASSE_ACTION';
export const SET_FORM_FOCUS_REPASSE_ACTION = '@contaCorrenteCliente/SET_FORM_FOCUS_REPASSE_ACTION';
export const PESQUISAR_TOTAL_REPASSE_ACTION = '@contaCorrenteCliente/PESQUISAR_TOTAL_REPASSE_ACTION';
export const SET_SIMPLE_VALUE_REPASSE_ACTION = '@contaCorrenteCliente/SET_SIMPLE_VALUE_REPASSE_ACTION';
export const SET_FORM_FIELDS_REPASSE_ACTION = '@contaCorrenteCliente/SET_FORM_FIELDS_REPASSE_ACTION';
export const ON_FOCUS_PERIOD_FIELD_REPASSE_ACTION = '@contaCorrenteCliente/ON_FOCUS_PERIOD_FIELD_REPASSE_ACTION';
export const SET_SIMPLE_VALUE_REPASSE_LIST_IDS_ACTION = '@contaCorrenteCliente/SET_SIMPLE_VALUE_REPASSE_LIST_IDS_ACTION';
export const LIBERAR_REPASSES_ACTION = '@contaCorrenteCliente/LIBERAR_REPASSES_ACTION';
export const DEFINIR_VALOR_SELECIONADO_REPASSE_ACTION = '@contaCorrenteCliente/DEFINIR_VALOR_SELECIONADO_REPASSE_ACTION';
export const PRINT_ARQUIVO_REPASSES_AUTORIZACAO_REPASSE_PDF_ACTION = '@contaCorrenteCliente/PRINT_ARQUIVO_REPASSES_AUTORIZACAO_REPASSE_PDF_ACTION';
export const PESQUISAR_TIPOS_LANCAMENTOS_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION = '@contaCorrenteCliente/PESQUISAR_TIPOS_LANCAMENTOS_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION';
export const ATUALIZA_STATE_TIPOS_LANCAMENTOS_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION = '@contaCorrenteCliente/ATUALIZA_STATE_TIPOS_LANCAMENTOS_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION';
export const MUDA_STATUS_LANCAMENTOS_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION = '@contaCorrenteCliente/MUDA_STATUS_LANCAMENTOS_NAO_PRESTADOS_CLIENTE_SELECIONADO_ACTION';
export const DOWNLOAD_RELATORIO_REPASSES_ACTION = '@contaCorrenteCliente/DOWNLOAD_RELATORIO_REPASSES_ACTION';
export const ON_CHANGE_RECEBIMENTOS_ACTION = '@contaCorrenteCliente/SET_FORM_FIELDS_RECEBIMENTOS_ACTION';
export const ON_FOCUS_PERIOD_FIELD_RECEBIMENTOS_ACTION = '@contaCorrenteCliente/ON_FOCUS_PERIOD_FIELD_RECEBIMENTOS_ACTION';
export const PESQUISAR_RECEBIMENTOS_ACTION = '@contaCorrenteCliente/PESQUISAR_RECEBIMENTOS_ACTION';
export const SET_SIMPLE_VALUE_RECEBIMENTOS_ACTION = '@contaCorrenteCliente/SET_SIMPLE_VALUE_RECEBIMENTOS_ACTION';
export const PESQUISAR_RECEBIMENTOS_ONCLICK_ACTION = '@contaCorrenteCliente/PESQUISAR_RECEBIMENTOS_ONCLICK_ACTION';
export const SET_FORM_FIELDS_RECEBIMENTOS_ACTION = '@contaCorrenteCliente/SET_FORM_FIELDS_RECEBIMENTOS_ACTION';
export const DEFINIR_VALOR_RECEBIMENTO_ACTION = '@contaCorrenteCliente/DEFINIR_VALOR_RECEBIMENTO_ACTION';
export const SET_SIMPLE_VALUE_RECEBIMENTOS_LIST_IDS_ACTION = '@contaCorenteCliente/SET_SIMPLE_VALUE_RECEBIMENTOS_LIST_IDS_ACTION';
export const SET_RECEBIMENTOS_FORM_TO_SAVE = '@contaCorrenteCliente/SET_RECEBIMENTOS_FORM_TO_SAVE';
export const SET_FORM_LIST_IDS_RECEBIMENTOS_ACTION = '@contaCorrenteCliente/SET_FORM_LIST_IDS_RECEBIMENTOS_ACTION';
export const SET_FORM_FIELDS_RECEBIMENTOS_ERRORS_ACTION = '@contaCorenteCliente/SET_FORM_FIELDS_RECEBIMENTOS_ERRORS_ACTION';
export const HABILITAR_EMITIR_EH_AGRUPAR_RPS_ACTION = '@contaCorenteCliente/HABILITAR_EMITIR_EH_AGRUPAR_RPS_ACTION';
export const GERAR_PDF_RECEBIMENTOS_PESQUISADOS_ACTION = '@contaCorrentCliente/GERAR_PDF_RECEBIMENTOS_PESQUISADOS_ACTION';
export const EMITIR_RPS_ONCLICK_ACTION = '@contaCorenteCliente/EMITIR_RPS_ONCLICK_ACTION';
export const AGRUPAR_RPS_ON_CLICK_ACTION = '@contaCorrente/AGRUPAR_RPS_ON_CLICK_ACTION';
export const SET_SIMPLE_VALUE_RPS_EMITIDOS = '@contaCorenteCliente/SET_SIMPLE_VALUE_RPS_EMITIDOS';
export const PESQUISAR_RPS_EMITIDOS_ACTION = '@contaCorenteCliente/PESQUISAR_RPS_EMITIDOS_ACTION';
export const SET_SIMPLE_VALUE_RPS_EMITIDOS_LIST_IDS_ACTION = '@contaCorenteCliente/SET_SIMPLE_VALUE_RPS_EMITIDOS_LIST_IDS_ACTION';
export const SET_SIMPLE_VALUE_RPS_EMITIDOS_FORM_ACTION = '@contaCorrenteCliente/SET_SIMPLE_VALUE_RPS_EMITIDOS_FORM_ACTION';
export const SALVAR_ALTERACOES_INFORMACOES_CLIENTE_ACTION = '@contaCorrenteCliente/SALVAR_ALTERACOES_INFORMACOES_CLIENTE_ACTION';
export const SET_RPS_EMITIDOS_FORM_ACTION = '@contaCorrenteCliente/SET_RPS_EMITIDOS_FORM_ACTION';
export const CANCELAR_RPS_ACTION = '@contaCorrenteCliente/CANCELAR_RPS_ACTION';
export const SET_SIMPLE_VALUE_LANCAMENTO_ACTION = '@contaCorrenteCliente/SET_SIMPLE_LANCAMENTO_ACTION';
export const PESQUISAR_LISTA_LANCAMENTOS_ACTION = '@contaCorrenteCliente/PESQUISAR_LISTA_LANCAMENTOS_ACTION';
export const SET_DADOS_GERACAO_NF_SWITCH = '@contaCorrenteCliente/SET_DADOS_GERACAO_NF_SWITCH';
export const PESQUISAR_DADOS_GERACAO_NOTA_FISCAL_ACTION = '@contaCorrenteCliente/PESQUISAR_DADOS_GERACAO_NOTA_FISCAL_ACTION';
export const SET_SIMPLE_VALUE_DADOS_GERACAO_NF_ACTION = '@contaCorrenteCliente/SET_SIMPLE_VALUE_DADOS_GERACAO_NF_ACTION';
export const SET_SIMPLE_VALUE_DADOS_GERACAO_NF_ARQUIVO_ACTION = '@contaCorrenteCliente/SET_SIMPLE_VALUE_DADOS_GERACAO_NF_ARQUIVO_ACTION';
export const ATUALIZAR_STATUS_LIDO_DADOS_GERACAO_NF_ACTION = '@contaCorrenteCliente/ATUALIZAR_STATUS_LIDO_DADOS_GERACAO_NF_ACTION';
export const VISUALIZAR_PDF_REMESSA_ON_CLICK_ACTION = 'contaCorrenteCliente/VISUALIZAR_PDF_REMESSA_ON_CLICK_ACTION';
export const GERAR_NOTA_FISCAL_ACTION = '@contaCorrenteCliente/GERAR_NOTA_FISCAL_ACTION';
export const SET_SIMPLE_VALUE_BOLETOS_RELACAO_PRESTACAO_ACTION = '@contaCorrenteCliente/SET_SIMPLE_VALUE_BOLETOS_RELACAO_PRESTACAO_ACTION';
export const PESQUISAR_DADOS_BOLETOS_RELACAO_PRESTACAO_ACTION = '@contaCorrenteCliente/PESQUISAR_DADOS_BOLETOS_RELACAO_PRESTACAO_ACTION';
export const CANCELAR_BOLETO_RELACAO_PRESTACAO_ACTION = '@contaCorrenteCliente/CANCELAR_BOLETO_RELACAO_PRESTACAO_ACTION';
export const BUSCAR_INFORMACOES_RELACAO_PRESTACAO_ACTION = '@contaCorrenteCliente/GET_INFORMACOES_RELACAO_PRESTACAO_ACTION';
export const SET_SIMPLE_VALUE_INFORMACOES_ADICIONAIS_RELACAO_PRESTACAO_ACTION = '@contaCorrenteCliente/SET_SIMPLE_VALUE_INFORMACOES_ADICIONAIS_RELACAO_PRESTACAO_ACTION';
export const IMPORTAR_ARQUIVO_RETORNO_ACTION = '@contaCorrenteCliente/IMPORTAR_ARQUIVO_RETORNO_ACTION';
export const RESET_FORM_FIELDS_NF_ACTION = '@contaCorrenteCliente/RESET_FORM_FIELDS_NF_ACTION';
export const PESQUISAR_NOTAS_FISCAIS_ACTION = '@contaCorrenteCliente/PESQUISAR_NOTAS_FISCAIS_ACTION';
export const SET_SIMPLE_VALUE_NOTAS_FISCAIS_ACTION = '@contaCorrenteCliente/SET_SIMPLE_VALUE_NOTAS_FISCAIS_ACTION';
export const SET_NOVA_NOTA = '@contaCorrenteCliente/SET_NOVA_NOTA';
export const CANCELAR_NOTAS_FISCAIS_ACTION = '@contaCorrenteCliente/CANCELAR_NOTAS_FISCAIS_ACTION';
export const INSERIR_NOVA_NOTA_FISCAL = '@contaCorrenteCliente/ INSERIR_NOVA_NOTA_FISCAL';
export const RESET_FORM_FIELDS_NOVA_NOTA_ACTION = '@contaCorrenteCliente/RESET_FORM_FIELDS_NOVA_NOTA_ACTION';
export const ON_FOCUS = '@contaCorrenteCliente/ON_FOCUS';
export const VISUALIZAR_NOTA_FISCAL_CLIENTE_ACTION = '@contaCorrenteCliente/VISUALIZAR_NOTA_FISCAL_CLIENTE_ACTION';
export const DOWNLOAD_PROTOCOLO_CANCELAMENTO_NF_ACTION = '@contaCorrenteCliente/DOWNLOAD_PROTOCOLO_CANCELAMENTO_NF_ACTION';
export const SET_FORM_FIELDS_NOVA_NOTA_ERRORS_ACTION = '@contaCorrenteCliente/SET_FORM_FIELDS_NOVA_NOTA_ERRORS_ACTION';
export const RESET_FORM_FIELDS_NOVO_BOLETO_ACTION = '@contaCorrenteCliente/RESET_FORM_FIELDS_NOVO_BOLETO_ACTION';
export const SET_NOVO_BOLETO = '@contaCorrenteCliente/SET_NOVO_BOLETO';
export const ON_FOCUS_BOLETO = '@contaCorrenteCliente/ON_FOCUS_BOLETO';
export const INSERIR_NOVO_BOLETO = '@contaCorrenteCliente/INSERIR_NOVO_BOLETO';
export const SET_FORM_FIELDS_BOLETO_ERRORS_ACTION = '@contaCorrenteCliente/SET_FORM_FIELDS_BOLETO_ERRORS_ACTION';
export const REGISTRAR_BOLETO_RELACAO_PRESTACAO_ACTION = '@contaCorrenteCliente/REGISTRAR_BOLETO_RELACAO_PRESTACAO_ACTION';
export const ENVIAR_EMAIL_BOLETO_ACTION = '@contaCorrenteCliente/ENVIAR_EMAIL_BOLETO_ACTION';
export const IMPRIMIR_BOLETO_RELACAO_PRESTACAO_ACTION = '@contaCorrenteCliente/IMPRIMIR_BOLETO_RELACAO_PRESTACAO_ACTION';
export const GET_PDF_ACTION = '@contaCorrenteCliente/GET_PDF_ACTION';
export const DOWNLAOD_PDF_ACTION = '@contaCorrenteCliente/DOWNLAOD_PDF_ACTION';
export const SET_SIMPLE_VALUE_PDF_ACTION = '@contaCorrenteCliente/SET_SIMPLE_VALUE_PDF_ACTION';


export const DETALHES_ORIGIN = '@contaCorrenteCliente/DETALHES_ORIGIN';
export const NAO_PRESTADOS_ORIGIN = '@contaCorrenteCliente/NAO_PRESTADOS_ORIGIN';
export const RELACAO_PRESTACAO_ORIGIN = '@contaCorrenteCliente/RELACAO_PRESTACAO_ORIGIN';
export const PAGAMENTOS = '@contaCorrenteCliente/PAGAMENTOS';


export const PRESTACAO_SUCCESS = 'Prestação realizada com sucesso!';
