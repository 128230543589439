import { getUriParams } from '../../../helpers';
import {
  CORE, gateway, gatewayShortJson, RELATORIOS,
} from '../../api';

export default {
  findAllFiliais() {
    return gatewayShortJson.get(`${CORE}/filiais/ativas-usuario`);
  },

  findAllIdsLancamentos(idFilial, periodoLancamento) {
    const queryParam = getUriParams({ idFilial, periodoLancamento });
    return gateway.get(`${CORE}/filiais/caixa-filial/ids?${queryParam}`);
  },

  findAllLancamentos(idFilial, periodoLancamento, pageable, order) {
    const sortedQuery = order.reduce((obj, sort) => (
      `${obj}&sort=${sort}`
    ), '');
    const queryParam = getUriParams({ idFilial, periodoLancamento, ...pageable });
    return gateway.get(`${CORE}/filiais/caixa-filial?${queryParam}${sortedQuery}`);
  },

  findSaldos(idFilial) {
    return gateway.get(`${CORE}/filiais/saldos/${idFilial}/filial`);
  },

  deleteLancamento(idCaixaFilial) {
    const queryParam = getUriParams({ idCaixaFilial });
    return gateway.delete(`${CORE}/filiais/deletar-lancamento?${queryParam}`);
  },

  approveLancamento(idsCaixaFilial = []) {
    return gateway.put(`${CORE}/filiais/aprovar-lancamentos`, idsCaixaFilial);
  },

  downloadComprovante(idsCaixaFilial) {
    return gateway.get(`${CORE}/filiais/caixa-filial/comprovante/${idsCaixaFilial}`, {
      responseType: 'blob',
    });
  },
  downloadExtratoLancamento(idFilial, dataLancamento, order) {
    const sortedQuery = order.reduce((obj, sort) => (
      `${obj}&sort=${sort}`
    ), '');
    return gateway.post(`${RELATORIOS}/caixa-filial?${sortedQuery}`, { idFilial, dataLancamento }, {
      responseType: 'blob',
    });
  },
};
