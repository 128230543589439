import React from 'react';
import { useDispatch } from 'react-redux';
import withTokenInterceptor from '../../hoc/withTokenInterceptor/withTokenInterceptor';
import { initializePageAction } from '../../store/theme.actions';
import LancamentosComissionados from './LancamentosComissionados';

function EditarContaCorrenteComissionadoPage() {
  const dispatch = useDispatch();

  dispatch(initializePageAction('Conta-Corrente de Comissionado > Editar Lançamento'));

  return (
    <LancamentosComissionados isEditMode />
  );
}
export default withTokenInterceptor(EditarContaCorrenteComissionadoPage);
