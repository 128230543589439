import React, { useCallback } from 'react';
import Grid24 from '../../../../../UI/Grid24/Grid24';
import { TITLE } from './prazoExpiracao.constants';
import useUtilStyles from '../../../../../../helpers/styles/utils.styles';
import FaixasPrazoExpiracao from './FaixasDePrazoExpiracao';
import {
  useCrudClienteDispatch,
  useCrudClienteSelector,
} from '../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import {
  setFaixasPrazoExpiracaoAction,
  setFaixasPrazoExpiracaoAttributeFocusAction,
  setFaixasPrazoExpiracaoAttributeValueAction,
} from '../../../../../../store/Cliente/V2/Crud/crudCliente.store';
import WrapperSubTitleForm from '../../../../../UI/WrapperSubTitleForm/WrapperSubTitleForm';


function PrazoExpiracao({ isInsertMode }) {
  const utilStyles = useUtilStyles();
  const dispatch = useCrudClienteDispatch();

  const prazosExpiracao = useCrudClienteSelector(states => [...states.controls.prazosExpiracao]);

  const setFaixasPrazoExpiracaoAttributeValueHandler = useCallback((name, value, index) => {
    dispatch(setFaixasPrazoExpiracaoAttributeValueAction(name, value, index));
  }, [dispatch]);

  const setFaixasPrazoExpiracaoAttributeFocusHandler = useCallback((name, index) => {
    dispatch(setFaixasPrazoExpiracaoAttributeFocusAction(name, index));
  }, [dispatch]);

  const setFaixasPrazoExpiracaoHandler = useCallback((faixaPrazoExpiracaoParam) => {
    dispatch(setFaixasPrazoExpiracaoAction(faixaPrazoExpiracaoParam));
  }, [dispatch]);

  return (
    <WrapperSubTitleForm title={TITLE}>
      <Grid24 container className={utilStyles.mt7}>
        <FaixasPrazoExpiracao
          disabled={!isInsertMode}
          readMode={!isInsertMode}
          faixasPrazoExpiracao={prazosExpiracao}
          setAttribute={setFaixasPrazoExpiracaoAttributeValueHandler}
          setFaixaDePrazoExpiracao={setFaixasPrazoExpiracaoHandler}
          setFocus={setFaixasPrazoExpiracaoAttributeFocusHandler}
        />
      </Grid24>
    </WrapperSubTitleForm>
  );
}

export default PrazoExpiracao;
