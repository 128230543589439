import React from 'react';
import Grid24 from '../../../../../UI/Grid24';
import FormWrapperWithTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import Dica from '../../../../../UI/Dica/Dica';
import { GroupDefinitionsCliente } from '../../BaseCalculo/Honorarios/styles';
import RadioField from '../../../../../UI/Field/Radio/RadioField';
import CheckboxField from '../../../../../UI/Field/Checkbox/CheckboxField';
import { useCrudClienteSelector } from '../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import useStyles from '../PrestacaoContasStyles';
import { PADRAO_DISTRIBUICAO_OPCOES_DEVEDOR } from './PadraoDistribuicaoOpcoes';

function PadraoDistribuicao({
  isEditMode,
  isInsertMode,
  title,
  dicaDistribuicao,
  opcoes,
  onChange,
  formModel,
  onChangeChecked,
}) {
  const style = useStyles();

  const bloquearDistribuicaoAutomaticaFichaFinanceira = useCrudClienteSelector(states => states.controls.bloquearDistribuicaoAutomaticaFichaFinanceira.copy());

  return (
    <Grid24 container isFormContainer spacing={1}>
      <FormWrapperWithTitle
        title={title}
        dicaComponent={<Dica mensagemDica={dicaDistribuicao} />}
      >
        {opcoes === PADRAO_DISTRIBUICAO_OPCOES_DEVEDOR
          && (
            <CheckboxField
              className={style.grid__checkBox}
              label="Bloquear a distribuição automática na Ficha Financeira"
              name={bloquearDistribuicaoAutomaticaFichaFinanceira.name}
              checked={bloquearDistribuicaoAutomaticaFichaFinanceira.value}
              onChange={onChangeChecked}
              disabled={!isEditMode && !isInsertMode}
            />
          )}

        <GroupDefinitionsCliente
          aria-label="padrao-distribuicao"
          value={formModel.value}
          onChange={e => onChange(e, formModel.name)}
        >
          {
            opcoes?.map(opcao => (
              <RadioField disabled={!isEditMode && !isInsertMode} label={opcao.label} value={opcao.value} />
            ))
          }
        </GroupDefinitionsCliente>
      </FormWrapperWithTitle>
    </Grid24>

  );
}

export default PadraoDistribuicao;
