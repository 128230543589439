import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { brightBlue, warmGrey } from '../../helpers/constants/colors.constants';

export const Root = styled.div`
  display: flex;
  width: 100%;
`;

export const CardsRoot = styled.div`
  display: block;
  width: 13%;
  margin: 12px 48px 0 26px;

  > div + div {
    margin-top: 10px;
  }

  @media (min-width: 1400px) {
    margin: 26px 60px 0 32px;
    > div + div {
      margin-top: 20px;
    }
  }

  @media (min-width: 1920px) {
    margin: 18px 72px 0 40px;
    > div + div {
      margin-top: 26px;
    }
  }

`;

export const MuralRoot = styled.div`
  display: block;
  width: 100%;
  margin: 0 26px 0 0;

  @media (min-width: 1400px) {
    margin: 0 29px 0 0;
  }

  @media (min-width: 1600px) {
    margin: 0 32px 0 0;
  }

  @media (min-width: 1680px) {
    margin: 0 34px 0 0;
  }

  @media (min-width: 1920px) {
    margin: 0 40px 0 0;
  }
`;

export const BottomRoot = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;

  @media (min-width: 1400px) {
    margin-top: 30px;
  }

  @media (min-width: 1920px) {
    margin-top: 41px;
  }
`;

export const BottomCalendario = styled.div`
  > span {
    font-size: 14px;
    color: #564872;
    font-weight: 500;
  }

  > button {
    padding: 2px !important;
    margin-left: 8px;
    margin-bottom: 10px;
    color: #564872;
  }

  @media (min-width: 1400px) {
    > span {
      font-size: 15px;
    }

    > button {
      margin-left: 9px;
      margin-bottom: 11px;
      color: #564872;
    }
  }

  @media (min-width: 1920px) {
    > span {
      font-size: 21px;
    }

    > button {
      padding: 4px !important;
      margin-left: 12px;
      margin-bottom: 14px;
      color: #564872;
    }
  }
`;

export const BottomMuralAvisos = styled.div`
  > span {
    font-size: 14px;
    color: #564872;
    font-weight: 500;
  }

  > button {
    padding: 2px !important;
    margin-left: 8px;
    margin-bottom: 10px;
    color: #564872;
  }


  @media (min-width: 1400px) {
    > span {
      font-size: 15px;
    }

    > button {
      margin-left: 10px;
      margin-bottom: 11px;
    }
  }

  @media (min-width: 1920px) {
    > span {
      font-size: 21px;
    }

    > button {
      padding: 4px !important;
      margin-left: 12px;
      margin-bottom: 14px;
    }
  }
`;

export const MuralCardsRoot = styled.div`
  display: grid;
  grid-template-columns:  300px 300px 300px;
  justify-content: space-between;
  grid-gap: 16px 16px;
  margin-top: 8px;
  background-color: transparent;


  @media (min-width: 1400px) {
    grid-template-columns: 328px 328px 328px;
    grid-gap: 28px 17px;
    margin-top: 10px;
  }

  @media (min-width: 1600px) {
    grid-template-columns: 380px 380px 380px;
    grid-gap: 28px 17px;
    margin-top: 10px;
  }

  @media (min-width: 1920px) {
    grid-template-columns: 450px 450px 450px;
    grid-gap: 24px 24px;
    margin-top: 24px;
  }
`;

export const MuralCards = styled.div`
  background-color: #ffffff;
  color: #656464;
  border-radius: 4px;
  display: flex;
  height: 60px;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 20%);

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #cfd9eb;
    min-width: 60px;
    font-size: 28px;
    color: #564872;
  }

  > span {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    margin-left: 8px;
  }


  @media (min-width: 1400px) {
    height: 77px;

    > div {
      min-width: 77px;
      font-size: 30px;
    }

    > span {
      font-size: 14px;
      margin-left: 10px;
    }
  }

  @media (min-width: 1920px) {
    height: 90px;

    > div {
      min-width: 90px;
      font-size: 42px;
    }

    > span {
      font-size: 18px;
      margin-left: 12px;
    }
  }
`;

export const FacilitadoresRoot = styled.div`
  display: flex;
  //justify-content: space-between;
  gap:22px;
  width: 100%;
  margin-top: 12px;

  @media (min-width: 1400px) {
    margin-top: 26px;
  }

  @media (min-width: 1920px) {
    margin-top: 18px;
  }
`;

export const FacilitadoresButton = styled(Button)`
  color: #564872;
  border: 2px solid #564872;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  width: 180px;
  height: 42px;
  text-transform: none;
  transition: width 0.2s;

  :disabled {
    color: #a2a0a0;
    border: 2px solid #a2a0a0;
  }

  @media (min-width: 1400px) {
    font-size: 18px;
    width: 220px;
    height: 42px;
  }

  @media (min-width: 1920px) {
    font-size: 24px;
    width: 280px;
    height: 42px;
  }

`;

export const MyInfoText = styled.div`
  font-size: 14px;
  margin-top: 12px;
  color: #564872;
  font-weight: 500;

  @media (min-width: 1400px) {
    font-size: 15px;
    margin-top: 30px;
  }

  @media (min-width: 1920px) {
    font-size: 21px;
    margin-top: 24px;
  }
`;

export const EventosCalendario = styled.div`
  border: 1px solid white;
  width: 220px;
  background-color: white;
  max-height: 286px;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 20%);
  margin-left: 4px;
  display: block;

  > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 27px;
    font-size: 12px;
    color: #564872;
    font-weight: bold;
  }

  @media (min-width: 1400px) {
    width: 240px;
    max-height: 312px;

    > div:first-child {
      min-height: 29px;
      font-size: 13px;
    }
  }

  @media (min-width: 1920px) {
    width: 330px;
    max-height: 430px;

    > div:first-child {
      min-height: 40px;
      font-size: 18px;
    }
  }
`;

export const EventosCalendarioRolagem = styled.div`
  max-height: 258px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    outline: 1px solid #cec8c8;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  p {
    padding-left: 20px;
    padding-top: 10px;

    i {
      padding-right: 10px;
      color: #564872;
      font-size: 16px;
    }
  }

  @media (min-width: 1400px) {
    max-height: 282px;
    p {
      padding-left: 21px;
      padding-top: 11px;

      i {
        padding-right: 11px;
        font-size: 18px;
      }
    }
  }

  @media (min-width: 1920px) {
    max-height: 386px;
    p {
      padding-left: 30px;
      padding-top: 14px;

      i {
        padding-right: 14px;
        font-size: 24px;
      }
    }
  }
`;

export const DashboardDrawer = styled.div`
  height: 100vh;

  display: flex;
  flex-direction: column;
`;

export const HeaderDashboardDrawer = styled.header`
  display: flex;
  align-items: center;

  height: 62px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.28);

  p {

    display: inline-block;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    border: none;

    line-height: 61px;
    text-align: center;
    color: ${warmGrey};
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;

    &[data-active='true'] {
      color: ${brightBlue};
      border-bottom: 2px solid ${brightBlue};
    }
  }
`;

export const InputNumeroProcesso = styled.div`
margin-top:-5px;
gap:12px;
width:50%;
display:flex;
justify-content:end;
> div {
  width:61%;
}
`;
