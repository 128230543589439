import React, { useCallback, useState } from 'react';
import cx from 'classnames';

import Grid24 from '../../Grid24';
import CheckboxField from '../../Field/Checkbox/CheckboxField';
import InputField from '../../Field/Input';
import IconButtonTooltip from '../../IconButtonTooltip/IconButtonTooltip';

import { getRequiredLabel, getAttributeExistingObject } from '../../../../helpers/utils/utils';

import useStyles from './SimpleEmail.styles';


export function buildLayoutConstants(requiredFields) {
  return {
    EMAIL: getRequiredLabel('E-mail', getAttributeExistingObject(requiredFields, 'email', false)),
    PRINCIPAL: 'Principal',
    TOOLTIP_TRASH: 'Excluir E-mail',
  };
}

function SimpleEmail({
  hasTrash = false,
  index,
  disabled = false,
  model,
  setAttribute,
  setEmails,
  setFocus,
  emailsWithPrincipalFalse,
  requiredFields = {},
  onRemove,
}) {
  const styles = useStyles();
  const [constants] = useState(buildLayoutConstants(requiredFields));

  const showTrashHandler = () => !disabled && hasTrash;

  const getValue = useCallback(((value, checked) => (checked || value)), []);

  const changeAttributeHandler = useCallback((event) => {
    const { name, value, checked } = event.target;
    setAttribute(name, getValue(value, checked), index);
  }, [setAttribute, index, getValue]);

  const focusAttributeHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    setFocus(fieldName, index);
  }, [setFocus, index]);

  const choosePrincipalHandler = useCallback((event) => {
    const { name } = event.target;
    const enderecos = emailsWithPrincipalFalse();
    enderecos[index][name].onChange(true);
    setEmails(enderecos);
  }, [setEmails, emailsWithPrincipalFalse, index]);

  return (
    <Grid24 container spacing={1}>
      <Grid24 xs={24} sm={8}>
        <InputField
          isForm
          fullWidth
          disabled={disabled}
          label={constants.EMAIL}
          error={model.email.error}
          name={model.email.name}
          value={model.email.value}
          errorMessage={model.email.errorMessage}
          maxLength={model.email.maxLength}
          onChange={changeAttributeHandler}
          onFocus={focusAttributeHandler}
        />
      </Grid24>
      <Grid24
        xs={24}
        sm={11}
        container
        className={
          cx({
            [styles.actions]: !showTrashHandler(),
            [styles.actionWithRemoveButton]: showTrashHandler(),
          })}
      >
        <Grid24 xs={8} sm={6} spacing={1}>
          <CheckboxField
            label={constants.PRINCIPAL}
            disabled={disabled}
            name={model.principal.name}
            checked={model.principal.value}
            onChange={choosePrincipalHandler}
          />
        </Grid24>
        {showTrashHandler() && (
          <Grid24 xs={8} sm={5} spacing={1}>
            <IconButtonTooltip
              type="trash"
              disabled={disabled}
              hint={constants.TOOLTIP_TRASH}
              onClick={onRemove}
            />
          </Grid24>
        )}
      </Grid24>
    </Grid24>
  );
}

export default React.memo(SimpleEmail);
