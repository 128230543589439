import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  wrapper: props => ({
    backgroundColor: '#f1f4f9',
    borderRadius: '7px',
    maxHeight: props.isScrollable ? props.maxTableHeigth : 'auto',
    '& td': {
      width: '46px',
      fontSize: '.75rem',
      padding: '0px 8px !important',
    },
    '& th': {
      cursor: 'default',
      fontSize: '.75rem',
      color: '#034ddc',
      fontWeight: 'bold',
      padding: '0px 8px !important',
      height: '33px',
      textAlign: 'center',
      lineHeight: '1rem',
    },
  }),
  overFlowText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  emptyValue: {
    textAlign: 'center',
  },
  tableRowRoot: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});
