import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  buttonIcons: {
    color: 'black',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonBox: {
    height: '100%',
  },
  textDialogAlterarChave: {
    width: '550px',
    fontSize: '16px',
    textAlign: 'center',
  },
  inputTextKey:{
    fontSize: '30px',
    fontFamily: 'caption',
    letterSpacing: '4px',
  }
});
