import React from 'react';
import FormWrapperWithTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import {
  TAXAS_MULTA,
  TITLE,
} from './taxasDesconto.constants';
import Grid24 from '../../../../../UI/Grid24/Grid24';
import NumberInput from '../../../../../UI/Field/Number/NumberInput';
import { useCrudClienteSelector } from '../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';

function TaxasDesconto({
  isEditMode,
  onChange,
}) {
  const porcentagemMulta = useCrudClienteSelector(states => states.controls.porcentagemMulta.copy());

  return (
    <FormWrapperWithTitle
      title={TITLE}
    >
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={4}>
          <NumberInput
            fullWidth
            disabled={!isEditMode}
            isForm
            label={TAXAS_MULTA}
            sideText="(%)"
            name={porcentagemMulta.name}
            value={porcentagemMulta.value}
            error={porcentagemMulta.error}
            onChange={onChange}
            numberType="percentage"
            maxValue={100}
          />
        </Grid24>
      </Grid24>
    </FormWrapperWithTitle>
  );
}

export default React.memo(TaxasDesconto);
