import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Grid24 from '../../Grid24';
import ChooseField from '../../Field/Choose/ChooseField';
import RadioField from '../../Field/Radio/RadioField';
import InputField from '../../Field/Input';
import SelectorField from '../../Field/Selector';
import MoneyField from '../../Field/Money/MoneyField';
import DateField from '../../Field/Date/DateField';
import IconButtonTooltip from '../../IconButtonTooltip/IconButtonTooltip';

import { getAttributeExistingObject, getRequiredLabel } from '../../../../helpers/utils/utils';

import useStyles from './Titulo.styles';
import { DadosRetorno } from '../../../Bordero/Crud/Titulos/DadosRetorno/DadosRetorno';
import TooltipUI from '../../Tooltip/Tooltip';

export function buildLayoutConstants(requiredFields) {
  return {
    TIPO_DOCUMENTO: getRequiredLabel('Tipo', getAttributeExistingObject(requiredFields, 'tipoDocumento', false)),
    NUMERO: getRequiredLabel('Número', getAttributeExistingObject(requiredFields, 'numero', false)),
    VALOR: getRequiredLabel('Valor Original', getAttributeExistingObject(requiredFields, 'valor', false)),
    DATA_VENCIMENTO: getRequiredLabel('Data Vencimento', getAttributeExistingObject(requiredFields, 'dataVencimento', false)),
    CUSTOS_CARTORIOS: getRequiredLabel('Valor do Protesto', getAttributeExistingObject(requiredFields, 'custasCartorio', false)),
    TOOLTIP_TRASH: 'Excluir Título',
    TITULO_PROTESTADO: 'Título Protestado:',
    SIM: 'Sim',
    NAO: 'Não',
  };
}

function Titulo({
  hasTrash = false,
  index,
  disabled = false,
  tiposDocumento = [],
  model,
  setAttribute,
  setFocus,
  requiredFields = {},
  onRemove,
}) {
  const modalRef = useRef(null);
  const [enableDadosRetorno, setEnableDadosRetorno] = useState(false);
  const [hasItensSalvosDadosRetorno, setHasItensSalvoDadosRetorno] = useState(false);
  const styles = useStyles({ enableDadosRetorno });
  const [constants] = useState(buildLayoutConstants(requiredFields));
  const showTrashHandler = () => !disabled && hasTrash;

  const getValue = useCallback(((value, checked) => (checked || value)), []);

  const changeAttributeHandler = useCallback((event) => {
    const { name, value, checked } = event.target;
    setAttribute(name, getValue(value, checked), index);
  }, [setAttribute, index, getValue]);

  const changeTitulosProtestadosHandler = useCallback((event) => {
    const { name, value } = event.target;
    setAttribute('valorProtesto', '0.00', index);
    setAttribute(name, value, index);
  }, [setAttribute, index]);

  const focusAttributeHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling && event.target.nextElementSibling.name) {
      fieldName = event.target.nextElementSibling.name;
    }
    setFocus(fieldName, index);
  }, [setFocus, index]);

  const onClickDadosRetornoHandler = useCallback(() => {
    modalRef.current.openModal(model.dadosRetorno);
  }, [modalRef, model.dadosRetorno]);


  const changeEnabledButtonColor = useCallback(() => {
    if (model.dadosRetorno.length > 0) {
      setHasItensSalvoDadosRetorno(true);
      setEnableDadosRetorno(false);
    } else {
      setHasItensSalvoDadosRetorno(false);
      setEnableDadosRetorno(true);
    }
  }, [setHasItensSalvoDadosRetorno, setEnableDadosRetorno, model.dadosRetorno.length]);

  // Verificação dos campos obrigatórios para habilitar o botão de dados retorno.
  // Ou configurar o state para deixar verde.
  useEffect(() => {
    if (hasItensSalvosDadosRetorno) {
      setHasItensSalvoDadosRetorno(true);
      setEnableDadosRetorno(false);
    } else if (model.numero.value && model.valorTitulo.value && model.idTipoTitulo.value && model.dataVencimento.value) {
      setEnableDadosRetorno(true);
      setHasItensSalvoDadosRetorno(false);
    } else {
      setHasItensSalvoDadosRetorno(false);
      setEnableDadosRetorno(false);
    }
  }, [
    model.numero.value,
    model.valorTitulo.value,
    model.idTipoTitulo.value,
    model.dataVencimento.value,
    model.dadosRetorno.length,
    setEnableDadosRetorno,
    setHasItensSalvoDadosRetorno,
    hasItensSalvosDadosRetorno,
  ]);

  // Retorna o icone dados de retorno com a cor configurada.
  const getDadosRetornoButton = useCallback(() => {
    const hint = disabled ? 'Dados de Retorno' : 'Adicionar Dados de Retorno';

    // Habilitado verde se tiver dados retorno.
    if (model.dadosRetorno.length > 0) {
      return (
        <TooltipUI title={hint}>
          <NoteAddIcon className={styles.buttonDadosRetornoComItens} onClick={onClickDadosRetornoHandler} />
        </TooltipUI>
      );
    }

    // Campos preenchidos para habilitar o botão.
    if (enableDadosRetorno && !disabled) {
      return (
        <TooltipUI title={hint}>
          <NoteAddIcon className={styles.buttonDadosRetornoEnable} onClick={onClickDadosRetornoHandler} />
        </TooltipUI>
      );
    }

    // Habilitado verde se tiver dados retorno.
    if (model.dadosRetorno.length > 0 && hasItensSalvosDadosRetorno) {
      return (<NoteAddIcon className={styles.buttonDadosRetornoComItens} onClick={onClickDadosRetornoHandler} />);
    }

    // Desabilitado se nao tiver dados retorno e for inspecionar.
    if (disabled && model.dadosRetorno.length === 0) {
      return (<NoteAddIcon className={styles.buttonDadosRetornoDisabled} />);
    }


    // Default desabilitado.
    return (<NoteAddIcon className={styles.buttonDadosRetornoDisabled} />);
  }, [styles, enableDadosRetorno, onClickDadosRetornoHandler, model.dadosRetorno, hasItensSalvosDadosRetorno, disabled]);

  return (
    <>
      <DadosRetorno
        ref={modalRef}
        numero={model.numero}
        indexTitulo={index}
        disabled={disabled}
        onEnableColor={changeEnabledButtonColor}
      />
      <Grid24 isFormContainer container spacing={1}>
        <Grid24 xs={24} sm={4} item>
          <SelectorField
            isForm
            fullWidth
            disabled={disabled}
            label={constants.TIPO_DOCUMENTO}
            items={tiposDocumento}
            name={model.idTipoTitulo.name}
            value={model.idTipoTitulo.value}
            error={model.idTipoTitulo.error}
            errorMessage={model.idTipoTitulo.errorMessage}
            onChange={changeAttributeHandler}
            onFocus={focusAttributeHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={5} item>
          <InputField
            isForm
            fullWidth
            disabled={disabled}
            label={constants.NUMERO}
            error={model.numero.error}
            name={model.numero.name}
            value={model.numero.value}
            errorMessage={model.numero.errorMessage}
            maxLength={model.numero.maxLength}
            onChange={changeAttributeHandler}
            onFocus={focusAttributeHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={3} item>
          <MoneyField
            isForm
            fullWidth
            disabled={disabled}
            label={constants.VALOR}
            error={model.valorTitulo.error}
            name={model.valorTitulo.name}
            value={model.valorTitulo.value}
            errorMessage={model.valorTitulo.errorMessage}
            maxLength={model.valorTitulo.maxLength}
            onChange={changeAttributeHandler}
            onFocus={focusAttributeHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={3} item>
          <DateField
            isForm
            fullWidth
            disabled={disabled}
            label={constants.DATA_VENCIMENTO}
            error={model.dataVencimento.error}
            name={model.dataVencimento.name}
            value={model.dataVencimento.value}
            errorMessage={model.dataVencimento.errorMessage}
            maxLength={model.dataVencimento.maxLength}
            onChange={changeAttributeHandler}
            onFocus={focusAttributeHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={3} item>
          <MoneyField
            isForm
            fullWidth
            disabled={disabled || model.possuirProtesto.value === 'N'}
            label={constants.CUSTOS_CARTORIOS}
            error={model.valorProtesto.error}
            name={model.valorProtesto.name}
            value={model.valorProtesto.value}
            errorMessage={model.valorProtesto.errorMessage}
            maxLength={model.valorProtesto.maxLength}
            onChange={changeAttributeHandler}
            onFocus={focusAttributeHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={4} item alignItems="center" style={{ maxWidth: '154px' }}>
          <ChooseField
            label={constants.TITULO_PROTESTADO}
            name={model.possuirProtesto.name}
            value={model.possuirProtesto.value}
            onChange={changeTitulosProtestadosHandler}
          >
            <RadioField disabled={disabled} value="S" label={constants.SIM} />
            <RadioField disabled={disabled} value="N" label={constants.NAO} />
          </ChooseField>
          {getDadosRetornoButton()}
        </Grid24>
        {showTrashHandler() && (
          <Grid24 xs={4} sm={1}>
            <IconButtonTooltip
              type="trash"
              disabled={disabled}
              hint={constants.TOOLTIP_TRASH}
              onClick={onRemove}
            />
          </Grid24>
        )}
      </Grid24>
    </>
  );
}

export default React.memo(Titulo);
