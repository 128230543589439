import styled from 'styled-components';
import { Radio } from '@material-ui/core';

export const RadioButton = styled(Radio)`
  &.MuiRadio-root {
    padding: 0 ;
  }

  &.Mui-checked {
    color: #034DDC !important;
  }
`;
