import React, { useCallback, useEffect } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import Grid24 from '../UI/Grid24';
import SelectorField from '../UI/Field/Selector';
import InputField from '../UI/Field/Input';
import CheckboxField from '../UI/Field/Checkbox/CheckboxField';
import AddActionTitleWrapper from '../UI/Wrapper/AddActionTitle';
import { findTiposTelefoneAction } from '../../store/Global/LoadSelectors/loadSelectors.saga';
import { getFieldName } from '../../helpers';
import useStyles from './Telefone.styles';
import useUtilStyles from '../../helpers/styles/utils.styles';

function CorrigirTelefoneDevedorBorderoComponent({ devedor, setBordero }) {
  const constants = {
    INFO_TELEFONE: 'Informações do Telefone do Devedor',
    TIPO_TELEFONE: 'Tipo Telefone*',
    DDD: 'DDD',
    NUMERO: 'Telefone*',
    OBSERVACAO: 'Observação',
    PRINCIPAL: 'Principal',
    WHATSAPP: 'WhatsApp',
  };

  const utilStyles = useUtilStyles();
  const styles = useStyles();

  const dispatch = useDispatch();

  const tiposTelefone = useSelector(states => states.selectors.tiposTelefone.selector);

  useEffect(() => {
    dispatch(findTiposTelefoneAction());
  }, [dispatch]);

  const onChangeTelefoneHandler = useCallback((event, index) => {
    const name = getFieldName(event.target);
    const { value } = event.target;
    const telefones = [...devedor.telefones];
    telefones[index][name].onChange(value);
    setBordero(oldBordero => ({ ...oldBordero, telefones }));
  }, [devedor?.telefones, setBordero]);

  const onFocusTelefoneHandler = useCallback((event, index) => {
    const name = getFieldName(event.target);
    const telefones = [...devedor.telefones];
    telefones[index][name].onFocus();
    setBordero(oldBordero => ({ ...oldBordero, telefones }));
  }, [devedor?.telefones, setBordero]);

  const getEnderecosWithPrincipalValueFalse = useCallback(() => devedor?.telefones.map((telefone) => {
    const modiFieldTelefone = { ...telefone };
    modiFieldTelefone.principal.onChange(false);
    return modiFieldTelefone;
  }), [devedor?.telefones]);

  const choosePrincipalHandler = useCallback((event, index) => {
    const name = getFieldName(event.target);
    const telefones = getEnderecosWithPrincipalValueFalse();
    telefones[index][name].onChange(true);
    setBordero(oldBordero => ({ ...oldBordero, telefones }));
  }, [setBordero, getEnderecosWithPrincipalValueFalse]);

  const buildTelefoneComponent = (telefone, index) => (
    <div key={index}>
      <Grid24 container spacing={1}>
        <Grid24 xs={24} sm={3}>
          <SelectorField
            isForm
            fullWidth
            disabled={telefone?.tipoTelefone?.disabled}
            label={constants.TIPO_TELEFONE}
            items={tiposTelefone}
            name="tipoTelefone"
            value={telefone?.tipoTelefone?.value}
            error={telefone?.tipoTelefone?.error}
            errorMessage={telefone?.tipoTelefone?.errorMessage}
            onChange={e => onChangeTelefoneHandler(e, index)}
            onFocus={e => onFocusTelefoneHandler(e, index)}
          />
        </Grid24>
        <Grid24 xs={24} sm={1}>
          <InputField
            isForm
            fullWidth
            disabled={telefone?.ddd?.disabled && telefone?.numero?.disabled}
            label={constants.DDD}
            error={telefone?.ddd?.error || telefone?.numero?.error}
            name="ddd"
            value={telefone?.ddd?.value}
            errorMessage={telefone?.ddd?.errorMessage}
            maxLength={2}
            onChange={e => onChangeTelefoneHandler(e, index)}
            onFocus={e => onFocusTelefoneHandler(e, index)}
          />
        </Grid24>
        <Grid24 xs={24} sm={3}>
          <InputField
            isForm
            fullWidth
            disabled={telefone?.numero?.disabled}
            label={constants.NUMERO}
            error={telefone?.numero?.error}
            name="numero"
            value={telefone?.numero?.value}
            errorMessage={telefone?.numero?.errorMessage}
            maxLength={9}
            onChange={e => onChangeTelefoneHandler(e, index)}
            onFocus={e => onFocusTelefoneHandler(e, index)}
          />
        </Grid24>
        <Grid24 xs={24} sm={6}>
          <InputField
            isForm
            fullWidth
            disabled={telefone?.observacao?.disabled}
            label={constants.OBSERVACAO}
            error={telefone?.observacao?.error}
            name="observacao"
            value={telefone?.observacao?.value}
            errorMessage={telefone?.observacao?.errorMessage}
            maxLength={100}
            onChange={e => onChangeTelefoneHandler(e, index)}
            onFocus={e => onFocusTelefoneHandler(e, index)}
          />
        </Grid24>
        <Grid24
          xs={24}
          sm={11}
          container
          className={cx([styles.actions])}
        >
          <Grid24 xs={8} sm={6} spacing={1}>
            <CheckboxField
              label={constants.PRINCIPAL}
              disabled={telefone?.principal?.disabled}
              name="principal"
              checked={telefone?.principal?.value}
              onChange={e => choosePrincipalHandler(e, index)}
            />
          </Grid24>
          <Grid24 xs={8} sm={6} spacing={1}>
            <CheckboxField
              label={constants.WHATSAPP}
              disabled={telefone?.permitirWhatsApp?.disabled}
              name="permitirWhatsApp"
              checked={telefone?.permitirWhatsApp?.value}
              onChange={e => onChangeTelefoneHandler(e, index)}
            />
          </Grid24>
        </Grid24>
      </Grid24>
    </div>
  );

  return (
    <AddActionTitleWrapper
      title={constants.INFO_TELEFONE}
      className={utilStyles.mt16}
      hideAddButton
    >
      <div className={styles.wrapper}>
        {devedor?.telefones?.map(buildTelefoneComponent)}
      </div>
    </AddActionTitleWrapper>
  );
}

export const CorrigirTelefoneDevedorBordero = CorrigirTelefoneDevedorBorderoComponent;
