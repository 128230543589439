import React, { useCallback } from 'react';
import FormWrapperTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import { FAIXA_JUROS_TITLE } from './FaixasDeJurosEhRepasseCrudParametros.constants';
import Grid24 from '../../../../../UI/Grid24/Grid24';
import useUtilStyles from '../../../../../../helpers/styles/utils.styles';
import {
  useCrudParametrosDispatch,
  useCrudParametrosSelector,
} from '../../../../../../store/Parametros/Crud/CrudParametrosProvider';
import {
  setFaixasDeJurosAction,
  setFaixasDeJurosAttributeFocusAction,
  setFaixasDeJurosAttributeValueAction,
} from '../../../../../../store/Parametros/Crud/crudParametros.store';
import FaixasDeJurosEhRepasse from '../../../../../UI/FaixasDeJuros/FaixasDeJurosEhRepasse';

function FaixasDeJurosEhRepasseCrudParametros({ isInsertMode }) {
  const utilStyles = useUtilStyles();
  const dispatch = useCrudParametrosDispatch();
  const jurosEhRepasse = useCrudParametrosSelector(states => states.requestFields.jurosEhRepasse);

  const setFaixasDeJurosAttributeValueHandler = useCallback((name, value, index) => {
    dispatch(setFaixasDeJurosAttributeValueAction(name, value, index));
  }, [dispatch]);

  const setFaixasDeJurosAttributeFocusHandler = useCallback((name, index) => {
    dispatch(setFaixasDeJurosAttributeFocusAction(name, index));
  }, [dispatch]);

  const setFaixasDeJurosHandler = useCallback((faixaDeJurosParam) => {
    dispatch(setFaixasDeJurosAction(faixaDeJurosParam));
  }, [dispatch]);

  return (
    <FormWrapperTitle title={FAIXA_JUROS_TITLE}>
      <Grid24 container className={utilStyles.mt7}>
        <FaixasDeJurosEhRepasse
          disabled={!isInsertMode}
          readMode={!isInsertMode}
          faixasDeJuros={jurosEhRepasse}
          setAttribute={setFaixasDeJurosAttributeValueHandler}
          setFaixaDeJuros={setFaixasDeJurosHandler}
          setFocus={setFaixasDeJurosAttributeFocusHandler}
        />
      </Grid24>
    </FormWrapperTitle>
  );
}

export default FaixasDeJurosEhRepasseCrudParametros;
