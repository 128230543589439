import { TableRow, Tooltip } from '@material-ui/core';
import React, { useCallback } from 'react';
import { FaFileDownload, FaCheckDouble } from 'react-icons/fa';
import { IoMdEye } from 'react-icons/io';
import { EmissaoAvulsaIcon } from '../../../assets/Icons/EmissaoAvulsaNFIcon';
import {
  TableIconsInvisible,
  TableActionButton, TableCellCenter, TableCellLeft, TableCellRight,
} from '../../UI/Tabela/tableDefaultStyles';
import TooltipUI from '../../UI/Tooltip/Tooltip';
import { useCCClienteDispatch } from '../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { atualizarStatusLidoDadosGeracaoNotaFiscalAction, downloadArquivoRemessaHandlerAction, visualizarPDFRemessaOnClickAction } from '../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';

function TableBodyDadosGeracaoNFComponent({ content = {}, permissoes }) {
  const dispatch = useCCClienteDispatch();

  const alterarStatusLido = useCallback((idNFClienteRemessa) => {
    dispatch(atualizarStatusLidoDadosGeracaoNotaFiscalAction(idNFClienteRemessa));
  }, [dispatch]);

  const downloadArquivo = useCallback(() => {
    dispatch(downloadArquivoRemessaHandlerAction(content.idNotaFiscalClienteRemessa));
  }, [dispatch, content.idNotaFiscalClienteRemessa]);

  const visualizarPdfRemessaOnClickHandler = useCallback((idNFClienteRemessa) => {
    dispatch(visualizarPDFRemessaOnClickAction(idNFClienteRemessa));
  }, [dispatch]);

  return (
    <TableRow>
      <TableCellCenter>
        {content.idNotaFiscalClienteRemessa}
      </TableCellCenter>
      <TableCellCenter>
        {
          content.serAvulso
          && <EmissaoAvulsaIcon />
        }
      </TableCellCenter>
      <TableCellCenter>
        {content.data}
      </TableCellCenter>
      <TableCellLeft>
        <TooltipUI title={content.nomeUsuario}>
          <span>
            {content.nomeUsuario}
          </span>
        </TooltipUI>
      </TableCellLeft>
      <TableCellCenter>
        {content.dataLeitura}
      </TableCellCenter>
      <TableCellCenter>
        {content.prefeitura}
      </TableCellCenter>
      <TableCellRight>
        {content.valorTotal}
      </TableCellRight>
      <TableCellCenter>
        <table>
          <tbody>
            <TableIconsInvisible>
              <React.Fragment>
                <Tooltip title="Download" placement="bottom">
                  <td>
                    <TableActionButton
                      disabled={!permissoes.prestarContas}
                      onClick={() => downloadArquivo()}
                    >
                      <FaFileDownload size={20} />
                    </TableActionButton>
                  </td>
                </Tooltip>
              </React.Fragment>
              <React.Fragment>
                <Tooltip title="Visualização" placement="bottom">
                  <td>
                    <TableActionButton
                      onClick={() => visualizarPdfRemessaOnClickHandler(content.idNotaFiscalClienteRemessa)}
                      disabled={!permissoes.prestarContas}
                    >
                      <IoMdEye size={20} />
                    </TableActionButton>
                  </td>
                </Tooltip>
              </React.Fragment>
              <React.Fragment>
                {content.lido && (
                  <Tooltip title="Retirar Leitura" placement="bottom">
                    <td>
                      <TableActionButton
                        disabled={!permissoes.prestarContas}
                        onClick={() => alterarStatusLido(content.idNotaFiscalClienteRemessa)}
                      >
                        <FaCheckDouble size={20} />
                      </TableActionButton>
                    </td>
                  </Tooltip>
                )}
              </React.Fragment>
            </TableIconsInvisible>
          </tbody>
        </table>
      </TableCellCenter>
    </TableRow>
  );
}

export const TableBodyDadosGeracaoNF = TableBodyDadosGeracaoNFComponent;
