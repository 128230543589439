import React, { useCallback, useEffect, useState } from 'react';
import { Button, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputField from '../../../UI/Field/Input';
import PeriodField from '../../../UI/Field/Period/PeriodField';
import useStyles from './prazoExpiradoLayoutAS.styles';
import { getValudWithPositiveInteger } from '../../../../models/FieldModel';
import SelectorField from '../../../UI/Field/Selector/SelectorField';
import IntervalMoney from '../../../UI/Field/IntervalMoney/IntervalMoney';
import {
  buildInitialStatesForm,
  buildInitialStatesPesquisaAvancada,
} from '../factory/prazoExpirado.factory';
import { buscarPrazoExpiradosAcordoSeguro } from '../../../../services/core/acordoseguro/prazoExpirado.service';
import { toAmericanDate } from '../../../../helpers/utils/utils';
import SelectorModel from '../../../../models/SelectorModel';
import { RESULT_NOT_FOUND, SNACKBAR_DURATION } from '../../../../helpers/constants/global.constants';
import SnackbarUI from '../../../UI/Snackbar/Snackbar';
import useUtilStyles from '../../../../helpers/styles/utils.styles';


function PrazoExpiradoLayoutAS({
  openPesquisaAvancada,
  onClickPesquisaAvancada,
  setCacheRequestFields,
  settingsTable,
  setSettingsTable,
  setLoading,
  ordenacao,
  buscarIdsProcesso,
}) {
  const styles = useStyles();
  const utilStyles = useUtilStyles();
  const location = useLocation();

  const [formFields, setFormFields] = useState(buildInitialStatesForm());
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const buttonClassPesquisaAvancada = cx({
    [utilStyles.pesquisaAvancadaButton]: !openPesquisaAvancada,
    [utilStyles.pesquisaSimplesButton]: openPesquisaAvancada,
  });

  const [itemsAntecedentes] = useState([
    new SelectorModel('NENHUM', 'Nenhum'),
    new SelectorModel('ATIVO', 'Ativo'),
    new SelectorModel('INATIVO', 'Inativo'),
  ]);

  const onFocusFieldsHandler = useCallback((event) => {
    const { name } = event.target;
    setFormFields({ ...formFields, [name]: formFields[name].updateErrors() });
  }, [formFields]);

  const onChangeNumericFieldsHandler = useCallback((event) => {
    event.target.value = getValudWithPositiveInteger(event.target.value);
  }, []);

  const onChangeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    setFormFields(old => ({ ...old, [name]: old[name].onChange(value) }));
  }, []);

  const onClickPesquisaAvancadaHandle = () => {
    const avancadaFields = buildInitialStatesPesquisaAvancada();
    setFormFields(old => ({
      ...old,
      ...avancadaFields,
    }));
    onClickPesquisaAvancada();
  };

  const onCloseSnackbarHandler = () => {
    setOpenSnackbar(false);
  };

  async function onSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const payload = {
      nomeDevedor: formData.get('nomeDevedor'),
      nroProcesso: formData.get('nroProcesso'),
      periodoAcordoInicial: toAmericanDate(formData.get('periodoAcordoInicial')),
      periodoAcordoFinal: toAmericanDate(formData.get('periodoAcordoFinal')),
      tipoAntecedente: formData.get('tipoAntecedente'),
      valorProcessoInicial: formData.get('valorProcessoInicial')?.replace(/[.]/g, '')?.replace(/[,]/g, '.'),
      valorProcessoFinal: formData.get('valorProcessoFinal')?.replace(/[.]/g, '')?.replace(/[,]/g, '.'),
    };
    try {
      setLoading(true);
      const response = await buscarPrazoExpiradosAcordoSeguro(payload, ordenacao, settingsTable.page, settingsTable.rowsPerPage);

      if (response.data.content.length === 0) {
        toast.warn(RESULT_NOT_FOUND);
      }
      setSettingsTable(old => ({
        ...old,
        content: response.data.content,
        page: response.data.number,
        rowsPerPage: response.data.size,
        size: response.data.totalElements,
      }));
      setCacheRequestFields(payload);
      buscarIdsProcesso(payload);
    } catch (e) {
      const { validations } = e.response.data;
      if (validations) {
        const newForm = validations.reduce((f, erro) => {
          const newField = f[erro.field].updateErrors(erro.message);
          return { ...f, [erro.field]: newField };
        }, { ...formFields });
        setFormFields(newForm);
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setFormFields(buildInitialStatesForm);
  }, [location.refreshPage]);

  return (
    <>
      <SnackbarUI
        open={openSnackbar}
        autoHideDuration={SNACKBAR_DURATION}
        variant="warning"
        message={RESULT_NOT_FOUND}
        onClose={onCloseSnackbarHandler}
      />
      <form onSubmit={onSubmit}>
        <div className={styles.div__root}>
          <div className={styles.div__rootAvancada}>
            {openPesquisaAvancada && (
            <>
              <div className={styles.fieldsTop}>
                <div className={styles.field__devedor}>
                  <InputField
                    fullWidth
                    label="Nome do Devedor"
                    name={formFields.nomeDevedor.name}
                    maxLength={60}
                    error={formFields.nomeDevedor.error}
                    errorMessage={formFields.nomeDevedor.errorMessage}
                    onFocus={onFocusFieldsHandler}
                  />
                </div>
                <div className={styles.field__nroProcesso}>
                  <InputField
                    fullWidth
                    label="Número do Processo"
                    name={formFields.nroProcesso.name}
                    maxLength={10}
                    error={formFields.nroProcesso.error}
                    errorMessage={formFields.nroProcesso.errorMessage}
                    onChange={onChangeNumericFieldsHandler}
                    onFocus={onFocusFieldsHandler}
                  />
                </div>
              </div>

              <div className={styles.fieldsBottom}>
                <div className={styles.field__antecedentes}>
                  <SelectorField
                    hasEmptyLabel
                    items={itemsAntecedentes}
                    label="Antecedentes"
                    name={formFields.tipoAntecedente.name}
                    value={formFields.tipoAntecedente.value}
                    onChange={onChangeFieldsHandler}
                  />
                </div>
                <IntervalMoney
                  label="Valor do Processo"
                  firstField={formFields.valorProcessoInicial}
                  lastField={formFields.valorProcessoFinal}
                  onFocus={onFocusFieldsHandler}
                />
              </div>
            </>
            )}
          </div>
          <div>
            <div className={styles.div__fields}>
              <PeriodField
                label="Período de Abertura do Processo"
                firstField={formFields.periodoAcordoInicial}
                lastField={formFields.periodoAcordoFinal}
                onChange={onChangeFieldsHandler}
                onFocus={onFocusFieldsHandler}
              />
              <div style={{ marginLeft: '10px' }}>
                <IconButton
                  type="submit"
                  className={styles.div__pesquisarBtn}
                >
                  <SearchIcon style={{ marginLeft: 2 }} />
                </IconButton>
              </div>
            </div>
            <div className={styles.div__pesquisaAvancadaBtn}>
              <Button className={buttonClassPesquisaAvancada} onClick={onClickPesquisaAvancadaHandle}>
                {openPesquisaAvancada ? 'Pesquisa Simples' : 'Pesquisa Avançada'}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default PrazoExpiradoLayoutAS;
