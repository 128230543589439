import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { getRequiredLabel } from '../../../../helpers/utils/utils';
import InputField from '../../../UI/Field/Input/InputField';
import MaskField from '../../../UI/Field/Mask/MaskField';
import { CNPJ_MASK } from '../../../../helpers/constants/layout.constants';
import WrapperSubTitleForm from '../../../UI/WrapperSubTitleForm/WrapperSubTitleForm';
import {
  setRequestFieldsFocusAction,
  setRequestFieldsValueAction,
} from '../../../../store/Parametros/Crud/crudParametros.store';
import Grid24 from '../../../UI/Grid24/Grid24';
import {
  useCrudParametrosDispatch,
  useCrudParametrosSelector,
} from '../../../../store/Parametros/Crud/CrudParametrosProvider';

function IdentificacaoCrudParametros({ isInsertMode }) {
  const dispatch = useCrudParametrosDispatch();

  const razaoSocial = useCrudParametrosSelector(states => states.requestFields.razaoSocial.copy());
  const cnpj = useCrudParametrosSelector(states => states.requestFields.cnpj.copy());
  const inscricaoEstadual = useCrudParametrosSelector(states => states.requestFields.inscricaoEstadual.copy());
  const inscricaoMunicipal = useCrudParametrosSelector(states => states.requestFields.inscricaoMunicipal.copy());
  const email = useCrudParametrosSelector(states => states.requestFields.email.copy());

  const IdentificacaoCrudParametrosConst = {
    TITULO_IDENTIFICACAO: 'Identificação',
    FIELD_RAZAO_SOCIAL: getRequiredLabel('Razão Social', isInsertMode),
    FIELD_CNPJ: getRequiredLabel('CNPJ', isInsertMode),
    FIELD_INSC_ESTADUAL: getRequiredLabel('Inscrição Estadual', isInsertMode),
    FIELD_INSC_MUNICIPAL: getRequiredLabel('Inscrição Municipal', isInsertMode),
    FIELD_EMAIL: getRequiredLabel('E-mail', isInsertMode),
  };

  const changeFieldsHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setRequestFieldsValueAction(name, value));
  }, [dispatch]);

  const focusFieldsHandler = useCallback((event) => {
    let fieldName = event.target.name;
    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    dispatch(setRequestFieldsFocusAction(fieldName));
  }, [dispatch]);

  return (
    <WrapperSubTitleForm
      title={IdentificacaoCrudParametrosConst.TITULO_IDENTIFICACAO}
    >
      <Grid24 container isFormContainer spacing="1" style={{ marginBottom: '16px' }}>
        <Grid24 xs={24} sm={12}>
          <InputField
            fullWidth
            isForm
            disabled={!isInsertMode}
            label={IdentificacaoCrudParametrosConst.FIELD_RAZAO_SOCIAL}
            name={razaoSocial.name}
            value={razaoSocial.value}
            error={razaoSocial.error}
            errorMessage={razaoSocial.errorMessage}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
            maxLength={razaoSocial.maxLength}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <MaskField
            isForm
            fullWidth
            disabled={!isInsertMode}
            label={IdentificacaoCrudParametrosConst.FIELD_CNPJ}
            name={cnpj.name}
            value={cnpj.value}
            error={cnpj.error}
            errorMessage={cnpj.errorMessage}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
            format={CNPJ_MASK}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <InputField
            fullWidth
            isForm
            disabled={!isInsertMode}
            label={IdentificacaoCrudParametrosConst.FIELD_INSC_ESTADUAL}
            name={inscricaoEstadual.name}
            value={inscricaoEstadual.value}
            error={inscricaoEstadual.error}
            maxLength={inscricaoEstadual.maxLength}
            errorMessage={inscricaoEstadual.errorMessage}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <InputField
            fullWidth
            isForm
            disabled={!isInsertMode}
            label={IdentificacaoCrudParametrosConst.FIELD_INSC_MUNICIPAL}
            name={inscricaoMunicipal.name}
            value={inscricaoMunicipal.value}
            error={inscricaoMunicipal.error}
            maxLength={inscricaoMunicipal.maxLength}
            errorMessage={inscricaoMunicipal.errorMessage}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
          />
        </Grid24>
      </Grid24>
      <Grid24 container isFormContainer spacing="1" style={{ marginBottom: '16px' }}>
        <Grid24 xs={24} sm={12}>
          <InputField
            fullWidth
            isForm
            disabled={!isInsertMode}
            label={IdentificacaoCrudParametrosConst.FIELD_EMAIL}
            name={email.name}
            value={email.value}
            error={email.error}
            maxLength={email.maxLength}
            errorMessage={email.errorMessage}
            onChange={changeFieldsHandler}
            onFocus={focusFieldsHandler}
          />
        </Grid24>
      </Grid24>
    </WrapperSubTitleForm>
  );
}

export default withRouter(IdentificacaoCrudParametros);
