import makeStyles from '@material-ui/core/styles/makeStyles';
import { blue } from '../../../../helpers/constants/colors.constants';

export default makeStyles({
  rootFormControl: props => ({
    marginLeft: props.hasLabel ? '-11px' : '0px',
    marginRight: props.hasLabel ? '16px' : '0px',

    '& .MuiFormControlLabel-label': {
      fontSize: '12px',
    },
  }),
  root: {
    padding: '0px',
  },
  checkedColor: {
    color: `${blue} !important`,
  },
  disbaledLabel: {
    color: '#707070 !important',
  },
});
