import moment from 'moment';
import { format } from 'date-fns-tz';
import ptBr from 'date-fns/locale/pt-BR';
import { DATE_FORMAT_PT, DATE_FORMAT_PT_BAR } from '../constants/global.constants';
import { getFormatedDateUSA, toAmericanDate } from './utils';

export const config = {
  timeZone: 'America/Sao_Paulo',
  locale: ptBr,
};

export class DateUtils {
  static getLastDays(day = 30) {
    return moment().subtract(day, 'days').format(DATE_FORMAT_PT_BAR);
  }


  static convertToUniversalDate(brazilianDateString) {
    const BRAZILIAN_DATE_FORMAT = /^\d{2}\/\d{2}\/\d{4}$/;
    const INVALID_BRAZILIAN_DATE_FORMAT_ERROR = 'Invalid Brazilian date format';
    const INVALID_DATE_ERROR = 'Invalid date';
    if (!BRAZILIAN_DATE_FORMAT.test(brazilianDateString)) {
      throw new Error(INVALID_BRAZILIAN_DATE_FORMAT_ERROR);
    }
    const [day, month, year] = brazilianDateString.split('/').map(Number);

    const dateObject = new Date(year, month - 1, day);
    if (
      dateObject.getFullYear() !== year
      || dateObject.getMonth() !== month - 1
      || dateObject.getDate() !== day
    ) {
      throw new Error(INVALID_DATE_ERROR);
    }

    const universalDateString = `${dateObject.getFullYear()
    }-${
      (dateObject.getMonth() + 1).toString().padStart(2, '0')
    }-${
      dateObject.getDate().toString().padStart(2, '0')}`;

    return universalDateString;
  }

  static isAValidDate(dateValue) {
    return moment(dateValue, 'DD/MM/YYYY', true).isValid();
  }

  static getYearsFromText(textDate = '') {
    if (!textDate) {
      return 0;
    }

    const textSplit = textDate.split('/');
    if (textSplit.length === 2) {
      const year = textSplit.replace(/ /g, '');
      return year === '' ? 0 : Number(year);
    }

    return 0;
  }

  static getDateFromText(textDate) {
    const year = this.getYearsFromText(textDate);
    const fullDate = moment(textDate, DATE_FORMAT_PT);
    const { length } = textDate.replace(/ /g, '');
    return { year, fullDate, textLength: length };
  }

  static getISODateFromText(textDate) {
    const [day, month, year] = textDate.split('/');
    if (!day || !month || !year || day.trim() === '' || month.trim() === '' || year.trim() === '') {
      return null;
    }
    const castDate = new Date(`${year}-${month}-${day} 00:00`);
    if (this.isDate(castDate)) {
      return castDate;
    }
    return null;
  }


  static isDate(date) {
    return date instanceof Date && !isNaN(date);
  }

  static isValidInput(fullDate, length) {
    return (length === 10 || length === 0) && (fullDate.isValid() || length === 0);
  }

  static isDateInValidInterval(year, length, minDate, maxDate) {
    return length === 0 || (length === 10 && year >= minDate.getFullYear() && year <= maxDate.getFullYear());
  }


  static isValidInterval(textDate, minFullDate, maxFullDate) {
    const date = this.getDateFromText(textDate);
    return this.isValidInput(date.fullDate, date.textLength)
    && this.isDateInValidInterval(date.year, date.textLength, minFullDate, maxFullDate);
  }

  static isBetweenDate(minDate, maxDate, date) {
    if (!minDate && !maxDate) {
      return false;
    }
    const min = this.getDateWithoutTime(minDate);
    const max = this.getDateWithoutTime(maxDate);
    const selected = this.getDateWithoutTime(date);
    return selected.getTime() >= min.getTime() && selected.getTime() <= max.getTime();
  }

  static getDefaultMaxDate(maxDate) {
    if (maxDate) {
      return this.getDateWithoutTime(maxDate);
    }

    const date = this.getDateWithoutTime(new Date());
    date.setFullYear(date.getFullYear() + 10);
    return date;
  }

  static getDefaultMinDate(minDate) {
    if (minDate) {
      return this.getDateWithoutTime(minDate);
    }
    return this.getDateWithoutTime(new Date('1900-01-01 00:00'));
  }

  static getFirstDatePreviousMonth(year, month) {
    const date = new Date(`${year}-${month + 1}-01 00:00`);
    date.setDate(date.getDate() - 1);
    return date;
  }

  static getFirstDateNextMonth(year, month) {
    return new Date(`${year}-${month + 2}-01 00:00`);
  }

  static getLastDateCurrentMonth(year, month) {
    return new Date(year, month + 1, 0);
  }

  static getDateWithoutTime(date = new Date()) {
    const returnedDate = new Date(date);
    returnedDate.setHours(0);
    returnedDate.setMinutes(0);
    returnedDate.setSeconds(0);
    returnedDate.setMilliseconds(0);
    return returnedDate;
  }

  static incrementMonth(monthYear) {
    const newValue = { ...monthYear };
    newValue.month = monthYear.month + 1;
    if (newValue.month > 11) {
      newValue.month = 0;
      newValue.year = monthYear.year + 1;
    }
    return newValue;
  }

  static decrementMonth(monthYear) {
    const newValue = { ...monthYear };
    newValue.month = monthYear.month - 1;
    if (newValue.month < 0) {
      newValue.month = 11;
      newValue.year = monthYear.year - 1;
    }
    return newValue;
  }


  static isInvalidValidDate(text, minDate, maxDate) {
    const date = this.getISODateFromText(text);
    if (date === null && text !== '') {
      return true;
    }

    if (text === '') {
      return false;
    }
    return !(this.isBetweenDate(minDate, maxDate, date));
  }

  static getValidationDate(text, minDate, maxDate) {
    const isInvalidValid = this.isInvalidValidDate(text, minDate, maxDate);

    let errorField = this.makeErrorField();
    if (this.isInvalidValidDate(text, minDate, maxDate)) {
      errorField = this.makeErrorField({ error: true, message: 'Data inválida' });
    }

    if (isInvalidValid || text === '') {
      const currentDate = new Date();
      return {
        errorField,
        selectedDate: null,
        monthYear: { month: currentDate.getMonth(), year: currentDate.getFullYear() },
      };
    }

    const fullDate = new Date(this.getISODateFromText(text));
    return {
      errorField,
      selectedDate: fullDate,
      monthYear: { month: fullDate.getMonth(), year: fullDate.getFullYear() },
    };
  }

  static getDisbaledMonthStatus(monthYear, minDate, maxDate) {
    const previousMonthDate = this.getFirstDatePreviousMonth(monthYear.year, monthYear.month);
    const lastMonthDate = this.getLastDateCurrentMonth(monthYear.year, monthYear.month);
    return {
      previous: previousMonthDate.getTime() <= minDate.getTime(),
      next: lastMonthDate.getTime() >= maxDate.getTime(),
    };
  }

  static toStringDate(monthYear, selectedDay) {
    let month = monthYear.month + 1;
    if (month < 10) {
      month = `0${month}`;
    }

    let day = selectedDay;
    if (selectedDay < 10) {
      day = `0${day}`;
    }

    return `${day}/${month}/${monthYear.year}`;
  }


  static weekCount(year, monthNumber) {
    const firstOfMonth = new Date(year, monthNumber - 1, 1);
    const lastOfMonth = new Date(year, monthNumber, 0);

    const used = firstOfMonth.getDay() + 6 + lastOfMonth.getDate();

    return Math.floor(used / 7);
  }

  static makeErrorField(value) {
    return { error: value?.error || false, message: value?.message || '' };
  }

  static makeMonthYear(value) {
    let date = this.getISODateFromText(value);
    if (!date) {
      date = new Date();
    }

    return { month: date.getMonth(), year: date.getFullYear() };
  }

  static getFullDateFromText(date = new Date()) {
    let month = format(date, 'MMMM', config);
    month = month[0].toUpperCase() + month.slice(1);
    const day = format(date, 'dd', config);
    return `${day} de ${month}`;
  }

  static makeDate(day) {
    const date = new Date();
    date.setDate(date.getDate() - day);
    return format(date, 'yyyy-MM-dd', config);
  }

  static formatTextDate(textDate) {
    const dateArr = textDate.split('-');
    const newDate = dateArr.reverse().join('/');
    return newDate;
  }

  /**
   * Valida uma data válida externamente do componente DateField para usos diretos na tela de acordo com a RN.
   * @param date data no formato FieldModel padrão com o value sendo digitado no campo ou selecionado no caledário.
   * @returns {boolean} Se é uma data válida ou não.
   */
  static isValidDate(date) {
    return !isNaN(parseInt(date[9])) && `${getFormatedDateUSA(toAmericanDate(date))}` !== 'Invalid Date';
  }


  static CALENDAR_MONTHS = [
    {
      id: 1, name: 'Janeiro', amountDays: 31,
    },
    {
      id: 2, name: 'Fevereiro', amountDays: 28,
    },
    {
      id: 3, name: 'Março', amountDays: 31,
    },
    {
      id: 4, name: 'Abril', amountDays: 30,
    },
    {
      id: 5, name: 'Maio', amountDays: 31,
    },
    {
      id: 6, name: 'Junho', amountDays: 30,
    },
    {
      id: 7, name: 'Julho', amountDays: 31,
    },
    {
      id: 8, name: 'Agosto', amountDays: 31,
    },
    {
      id: 9, name: 'Setembro', amountDays: 30,
    },
    {
      id: 10, name: 'Outubro', amountDays: 31,
    },
    {
      id: 11, name: 'Novembro', amountDays: 30,
    },
    {
      id: 12, name: 'Dezembro', amountDays: 31,
    },
  ];
}
