import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { MainContainer, Content } from './LayoutNavigationStyles';
import { SideDrawerNavigation } from '../SideDrawerNavigation/SideDrawerNavigation';
import { Toolbar } from '../Toolbar/Toolbar';


function LayoutNavigationComponent({ children }) {
  const [isMinimized, setIsMinimized] = React.useState(true);
  const [mouseOnHoverDrawer, setMouseOnHoverDrawer] = React.useState(false);

  const handleChangeMinimize = useCallback(() => {
    setIsMinimized(oldValue => !oldValue);
  }, []);

  const handleChangeMouseOnhoverDrawer = useCallback((status) => {
    setMouseOnHoverDrawer(status);
  }, []);

  return (
    <>
      <SideDrawerNavigation
        minimized={isMinimized}
        handleCloseNavigation={handleChangeMinimize}
        handleChangeMouseOnhoverDrawer={handleChangeMouseOnhoverDrawer}
        mouseOnHoverDrawer={mouseOnHoverDrawer}
      />
      <MainContainer minimized={isMinimized}>
        <Toolbar minimized={isMinimized} handleChangeMinimize={handleChangeMinimize} />
        <Content id="admin-layout">
          {children}
        </Content>
      </MainContainer>
    </>
  );
}

LayoutNavigationComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export const LayoutNavigation = LayoutNavigationComponent;
