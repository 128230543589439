import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { FaEnvelope } from 'react-icons/fa';
import { MdPrint as MdPrintIcon } from 'react-icons/md';
import { RiFileWarningFill } from 'react-icons/ri';
import OrdenacaoColuna from '../../../../../../components/UI/Tabela/Ordenacao/OrdenacaoColuna';
import TooltipUI from '../../../../../../components/UI/Tooltip/Tooltip';
import { getMoneyMask, toBrazilianDate } from '../../../../../../helpers';
import TableHeader from '../../../../../../models/TableHeader';
import { blackTwo, lightGrey } from '../../../../../../helpers/constants/colors.constants';
import { EstornarIcon } from '../../../../../../assets/Icons/EstornarIcon';
import { CancelarEnvioIcon } from '../../../../../../assets/Icons/CancelarEnvioIcon';


const tiposRetornoEnum = {
  AGUARDANDO_ENVIO_EMAIL: { color: '#3f51b5' },
  EMAIL_ENVIADO_COM_SUCESSO: { color: '#43a047' },
  CLIENTE_NAO_POSSUI_EMAIL_DE_PRESTACAO_DE_CONTAS: { color: '#ffa000' },
  ENVIO_NAO_SOLICITADO: { color: '#858484' },
  ERRO_NO_ENVIO_DO_EMAIL: { color: '#e91b1b' },
};
class RelacaoPrestacaoFactory {
  makeCabecalhoTabela(columnOrdenacao, setColumnOrdenacao, arrowDirection, setArrowDirection, onClickColumnOrdenar) {
    return [
      new TableHeader({
        id: 'relacaoSelecionada',
        radioButton: true,
        label: '',
        maxWidth: 55,
        alignCell: 'center',
      }),
      new TableHeader({
        id: 'idPrestacao',
        label: <OrdenacaoColuna
          columnLabel="Prestação"
          idColumn="idPrestacao"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={onClickColumnOrdenar}
        />,
        maxWidth: 55,
        alignCell: 'center',
      }),
      new TableHeader({
        id: 'tipoRetornoEnvioPrestacaoContaCliente',
        label: <i className="fas fa-envelope" />,
        alignCell: 'center',
        maxWidth: 45,
      }),
      new TableHeader({
        id: 'dataPrestacao',
        label: <OrdenacaoColuna
          columnLabel="Data"
          idColumn="dataPrestacao"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickColumnOrdenar()}
        />,
        alignCell: 'center',
        maxWidth: 80,
      }),
      new TableHeader({
        id: 'tipoPrestacaoContaCliente',
        label: 'Tipo da Prestação',
        alignCell: 'center',
        maxWidth: 116,
      }),
      new TableHeader({
        id: 'dataEstorno',
        label: 'Data Estorno',
        alignCell: 'center',
        maxWidth: 90,
      }),
      new TableHeader({
        id: 'totalPrestacao',
        label: 'Total Repasse',
        alignCell: 'right',
        maxWidth: 154,
      }),
      new TableHeader({
        id: 'totalDemonstracao',
        label: 'Total Demonstração',
        alignCell: 'right',
        maxWidth: 154,
      }),
      new TableHeader({
        id: 'saldoAPagar',
        label: 'A Pagar',
        alignCell: 'right',
        maxWidth: 160,
      }),
      new TableHeader({
        id: 'acoes',
        label: 'Ações',
        icone: true,
        maxWidth: 140,
        alignCell: 'center',
      }),
    ];
  }


  getTipoRetornoEmail(tipoRetorno, hint) {
    return (
      <TooltipUI title={hint}>
        <i style={{ color: tiposRetornoEnum[tipoRetorno].color }} className="fas fa-circle" />
      </TooltipUI>
    );
  }

  makeConteudoTabela(utilStyles,
    prestacoes,
    permissoes,
    handlePrintPrestacao,
    handleOpenCancelPrestacao,
    handleOpenEstornoPrestacao,
    handleEnviarEmailPrestacao,
    handleOpenMotivoEstornoPrestacao,
    lancamentos) {
    if (!prestacoes || prestacoes.length === 0) {
      return [];
    }

    return prestacoes.map(prestacao => ({
      id: prestacao.idPrestacao,
      values: {
        relacaoSelecionada: { idPrestacao: prestacao.idPrestacao, idPrestacaoContaCliente: prestacao.idPrestacaoContaCliente },
        idPrestacao: prestacao.idPrestacao,
        dataEstorno: toBrazilianDate(prestacao.dataEstorno),
        dataPrestacao: toBrazilianDate(prestacao.dataPrestacao),
        existirEmailPrestacao: prestacao.existirEmailPrestacao,
        prestacaoRelacaoClientes: prestacao.prestacaoRelacaoClientes,
        saldoAPagar: getMoneyMask(prestacao.saldoAPagar),
        tipoPrestacaoContaCliente: prestacao.tipoPrestacaoContaCliente,
        totalDemonstracao: getMoneyMask(prestacao.totalDemonstracao),
        totalPrestacao: getMoneyMask(prestacao.totalPrestacao),
        tipoRetornoEnvioPrestacaoContaCliente: this.getTipoRetornoEmail(prestacao.tipoRetornoEnvioPrestacaoContaCliente, prestacao.retornoEnvioPrestacaoContaCliente),
        acoes: (
          <div>
            {(!prestacao.existirEmailPrestacao || lancamentos?.length === 0 || lancamentos?.length === undefined) ? (
              <IconButton
                size="small"
                disabled
                className={utilStyles.mr8}
              >
                <FaEnvelope
                  style={{
                    color: lightGrey,
                  }}
                  size={17}
                />
              </IconButton>
            ) : (
              <TooltipUI title={prestacao.existirEmailPrestacao ? 'Enviar Prestação' : ''}>
                <IconButton
                  size="small"
                  className={utilStyles.mr8}
                  onClick={() => handleEnviarEmailPrestacao(prestacao.idPrestacaoContaCliente)}
                >
                  <FaEnvelope
                    style={{
                      color: blackTwo,
                    }}
                    size={17}
                  />
                </IconButton>
              </TooltipUI>
            )}

            {(lancamentos?.length === 0 || lancamentos?.length === undefined) ? (
              <IconButton
                size="small"
                className={utilStyles.mr8}
                style={{ color: blackTwo }}
                disabled
              >
                <MdPrintIcon
                  size={20}
                  color={lightGrey}
                />
              </IconButton>
            ) : (
              <TooltipUI title="Imprimir">
                <IconButton
                  size="small"
                  className={utilStyles.mr8}
                  style={{ color: blackTwo }}
                  onClick={() => handlePrintPrestacao(prestacao.idPrestacaoContaCliente)}
                >
                  <MdPrintIcon
                    size={20}
                    color={blackTwo}
                  />
                </IconButton>
              </TooltipUI>
            )}

            {(!permissoes.prestarContas
            || !prestacao.isUltimaPrestacao
            || !prestacao.existeBoletoPrestacao
            || prestacao.dataEstorno !== null
            || prestacao.existeNumeroNotaEhCodigoVerificacao) ? (
              <IconButton
                size="small"
                className={utilStyles.mr8}
                disabled
              >
                <CancelarEnvioIcon
                  color={lightGrey}
                />
              </IconButton>
              ) : (
                <TooltipUI title={permissoes.prestarContas && prestacao.isUltimaPrestacao ? 'Cancelar' : ''}>
                  <IconButton
                    size="small"
                    className={utilStyles.mr8}
                    onClick={() => handleOpenCancelPrestacao(prestacao.idPrestacao)}
                  >
                    <CancelarEnvioIcon
                      color={blackTwo}
                    />
                  </IconButton>
                </TooltipUI>
              )}

            {(!permissoes.prestarContas || prestacao.dataEstorno !== null || !prestacao.existeBoletoPrestacao) ? (
              <IconButton
                size="small"
                disabled
              >
                <EstornarIcon
                  color={lightGrey}
                />
              </IconButton>
            ) : (
              <TooltipUI title={permissoes.prestarContas && !prestacao.dataEstorno ? 'Estornar' : ''}>
                <IconButton
                  size="small"
                  onClick={() => handleOpenEstornoPrestacao(prestacao.idPrestacao)}
                >
                  <EstornarIcon
                    color={blackTwo}
                  />
                </IconButton>
              </TooltipUI>
            )}

            {(prestacao.dataEstorno === null) ? (
              <IconButton
                size="small"
                disabled
              >
                <RiFileWarningFill
                  color={lightGrey}
                />
              </IconButton>
            ) : (
              <TooltipUI title="Motivo do Estorno da Prestação">
                <IconButton
                  size="small"
                  onClick={() => handleOpenMotivoEstornoPrestacao(prestacao.idPrestacao)}
                >
                  <RiFileWarningFill
                    color={blackTwo}
                  />
                </IconButton>
              </TooltipUI>
            )}


          </div>
        ),
      },
    }));
  }
}

export const relacaoPrestacaoFactory = new RelacaoPrestacaoFactory();
