import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles({
  dialog: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '838px',
    },
  },
  typography: {
    fontWeight: 'bold',
    fontSize: '12px',
  },
  div__table: {
    marginTop: '12px',
  },
  div__favorito: {
    cursor: 'pointer',
  },
  div__footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '18px',
  },
});
