import { makeErrorField } from '../../../../helpers/utils/form.utils';
import FieldModel from '../../../../models/FieldModel';
import { motivosQuebraAcordo, quebrarAcordo } from '../../../../services/core/processos/processosAcordosService';

class DialogQuebrarAcordoController {
  makeErrorFields() {
    return {
      motivoQuebra: makeErrorField(),
    };
  }

  makeFormFields() {
    return {
      motivoQuebra: new FieldModel({
        name: 'motivoQuebra',
        value: '',
      }),
    };
  }

  makePayload(idProcesso, idFase, idAcordo, idMotivoQuebraAcordo) {
    return {
      idProcesso,
      idFase,
      idAcordo,
      motivoQuebra: idMotivoQuebraAcordo,
    };
  }

  async quebrarAcordo(idProcesso, idFase, idAcordo, idMotivoQuebraAcordo) {
    const payload = this.makePayload(idProcesso, idFase, idAcordo, idMotivoQuebraAcordo);
    await quebrarAcordo(payload);
  }

  motivosQuebraAcordo() {
    return motivosQuebraAcordo();
  }
}

export const dialogQuebrarAcordoController = new DialogQuebrarAcordoController();
