import FieldModel from '../../../models/FieldModel';
import ChangeAttr from '../../../models/ChangeAttrModel';

const ON_CLEAR_ERROR_FOCUS_TITULO_COMPROMISSO = 'ON_CLEAR_ERROR_FOCUS_TITULO_COMPROMISSO';
const ON_CHANGE_NOME_TITULO_COMPROMISSO = 'ON_CHANGE_NOME_TITULO_COMPROMISSO';
const ON_LOADING_ACTION_CRUD_TITULO_COMPROMISSO = 'ON_LOADING_ACTION_CRUD_TITULO_COMPROMISSO';
const ON_ERROR_NOME_TITULO_COMPROMISSO = 'ON_ERROR_NOME_TITULO_COMPROMISSO';
const CLEAR_CONTROLS = 'CLEAR_CONTROLS_TITULO_COMPROMISSO';

function buildConstrols() {
  return {
    nomeTituloCompromisso: new FieldModel({
      name: 'nomeTituloCompromisso',
      changeAttr: new ChangeAttr({ maxLength: 40 }),
    }),
  };
}

function buildIniitialStates() {
  return {
    controls: buildConstrols(),
    actions: {
      loading: false,
      openDialogError: false,
      contentDialogError: '',
    },
  };
}

export const onLoadingCrudAction = value => ({
  type: ON_LOADING_ACTION_CRUD_TITULO_COMPROMISSO,
  value,
});

export const onErrorTituloCompromissoAction = tituloCompromisso => ({
  type: ON_ERROR_NOME_TITULO_COMPROMISSO,
  tituloCompromisso,
});

export const onClearErrorFocusAction = () => ({
  type: ON_CLEAR_ERROR_FOCUS_TITULO_COMPROMISSO,
});

export const onChangeTituloCompromissoAction = nomeTituloCompromisso => ({
  type: ON_CHANGE_NOME_TITULO_COMPROMISSO,
  nomeTituloCompromisso,
});

export const clearControlsAction = () => ({
  type: CLEAR_CONTROLS,
});

function onErrorNomeTituloCompromissoHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      ...action.tituloCompromisso,
    },
  };
}

function onClearErrorFocusHandler(states) {
  const tituloCompromissoCopied = states.controls.nomeTituloCompromisso.copy();
  tituloCompromissoCopied.error = false;

  return {
    ...states,
    controls: {
      ...states.controls,
      nomeTituloCompromisso: tituloCompromissoCopied,
    },
  };
}


function onChangeNomeTituloCompromissoHandler(states, action) {
  const nomeTituloCompromissoChange = states.controls.nomeTituloCompromisso.getNewModel(action.nomeTituloCompromisso);
  return {
    ...states,
    controls: {
      ...states.controls,
      nomeTituloCompromisso: nomeTituloCompromissoChange,
    },
  };
}

function onLoadingCrudHandler(states, action) {
  return {
    ...states,
    actions: {
      ...states.actions,
      loading: action.value,
    },
  };
}

function clearControlsHandler(states) {
  return {
    ...states,
    controls: buildConstrols(),
  };
}

export default (states = buildIniitialStates(), action) => {
  switch (action.type) {
    case ON_LOADING_ACTION_CRUD_TITULO_COMPROMISSO:
      return onLoadingCrudHandler(states, action);
    case ON_CHANGE_NOME_TITULO_COMPROMISSO:
      return onChangeNomeTituloCompromissoHandler(states, action);
    case ON_CLEAR_ERROR_FOCUS_TITULO_COMPROMISSO:
      return onClearErrorFocusHandler(states);
    case ON_ERROR_NOME_TITULO_COMPROMISSO:
      return onErrorNomeTituloCompromissoHandler(states, action);
    case CLEAR_CONTROLS:
      return clearControlsHandler(states);
    default:
      return states;
  }
};
