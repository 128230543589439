import React from 'react';
import { useDispatch } from 'react-redux';
import { initializePageAction } from '../../../../store/theme.actions';
import CrudCliente from '../../../../components/Cliente/Crud/CrudCliente';
import withTokenInterceptor from '../../../../hoc/withTokenInterceptor/withTokenInterceptor';
import CrudClienteProvider from '../../../../store/Cliente/V2/Crud/CrudClienteProvider';


function EditarClientePage() {
  const dispatch = useDispatch();

  dispatch(initializePageAction('Clientes > Editar Cadastro'));
  return (
    <CrudClienteProvider>
      <CrudCliente isEditMode />
    </CrudClienteProvider>
  );
}

export default withTokenInterceptor(EditarClientePage);
