import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default function ZoomAlertIcon() {
  const zoomPath = 'M15.7 14.357h-.708l-.251-.242a5.833 5.833 0 1 0-.627.627l.242.251v.707l4.48 4.471 1.335-1.335zm-3.136-3.584h-1.792v1.792h-.9v-1.793H8.084v-.9h1.792V8.084h.9v1.792h1.792z';

  return (
    <SvgIcon transform="scale(1.20)" viewBox="1 1 24 24">
      <path color="red" d={zoomPath} />
    </SvgIcon>
  );
}
