import makeStyles from '@material-ui/core/styles/makeStyles';
import styled from 'styled-components';

export default makeStyles({
  grid__bloqueio: {
    paddingBottom: '16px',
    marginBottom: '8px',
  },

  grid__checkBox: {
    paddingLeft: '8px',
    marginRight: '8px',
  },

  grid__segunda: {
    marginRight: '9px',
  },

  grid__checkConfiguracoes: {
    padding: '10px',
  },

  grid__selectorField: {
    marginTop: '16px',
  },

  grid__toleradorDias: {
    marginRight: '5px',
    fontSize: '12px',
    marginLeft: '12px',
  },

  grid__Dias: {
    marginLeft: '5px',
    fontSize: '12px',
  },

  grid__inputField: {
    marginTop: '50x',
    marginLeft: '2px',
    marginRight: '2px',
  },

  grid__radioButton: {
    display: 'inline',
    marginTop: '10px',
  },

  grid__days: {
    marginLeft: '34px',
  },

  grid__swapList: {
    marginLeft: '18px',
    marginTop: '17px',
    '&:first-child': {
      width: '60%',
    },
  },

  grid__swapListMin: {
    width: '40%',
  },

  radioGroup_distribuicaoValores: {
    marginTop: '12px',
  },

  grid__lastRadio: {
    marginTop: '12px',
  },

  grid__selectorPrestacao: {
    width: '335px',
  },

  icone: {
    marginRight: '7px',
  },
});

export const ErrorMessageSemanal = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #FF0000;
  margin-top: 15px;
`;

export const ErrorMessageDiaMes = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #FF0000;
  margin-top: 15px;
  display: flex;
  align-self: center;
`;

export const Label = styled.label`
  font-size: 0.75rem;
  color: #707070;
  margin-top: 17px;
`;
