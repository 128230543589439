import makeStyles from '@material-ui/core/styles/makeStyles';
import { blue } from '../../../../helpers/constants/colors.constants';

export default makeStyles({
  root: {
    fontSize: '0.75rem',
    '& .MuiButtonBase-root': {
      padding: '0px',
    },
    '& span': {
      fontSize: '0.75rem',
    },
    '& .MuiFormControlLabel-label.Mui-disabled ': {
      color: '#707070',
    },
  },
  checked: {
    color: `${blue} !important`,
  },
});
