import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const MainContainer = styled.div`
  margin-top: 8px;

  & >div + div {
    margin-top: 8px;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-top: 8px;
`;

export const TextBold = styled(Typography)`
  font-weight: bold;
`;

export const ContainerConfirmDialog = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
`;
