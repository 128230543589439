import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import Telefones from '../../../UI/TelefonesV2/Telefones';

import { useCrudBoderoDispatch, useCrudBorderoSelector } from '../../../../store/Borderos/Crud/CrudBorderoProvider';
import { setObjectsAction, setObjectFocusAction, setObjectAttributeAction } from '../../../../store/Borderos/Crud/CrudBordero.store';

import useUtilStyles from '../../../../helpers/styles/utils.styles';

const TELEFONES = 'telefones';

export default function TelefonesCrudBordero({ isInspectMode, disabled }) {
  const utilStyles = useUtilStyles();
  const dispatch = useCrudBoderoDispatch();
  const telefones = useCrudBorderoSelector(states => states.requestFields.telefones);

  const tiposTelefone = useSelector(states => states.selectors.tiposTelefone.selector);

  const setAttributeHandler = useCallback((name, value, index) => {
    dispatch(setObjectAttributeAction(TELEFONES, name, value, index));
  }, [dispatch]);

  const setFocusHandler = useCallback((name, index) => {
    dispatch(setObjectFocusAction(TELEFONES, name, index));
  }, [dispatch]);


  const setTelefonesHandler = useCallback((telefonesParam) => {
    dispatch(setObjectsAction(TELEFONES, telefonesParam));
  }, [dispatch]);

  return (
    <Telefones
      className={utilStyles.mt16}
      disabled={isInspectMode || disabled}
      readMode={isInspectMode}
      telefones={telefones}
      tiposTelefone={tiposTelefone}
      setAttribute={setAttributeHandler}
      setFocus={setFocusHandler}
      setTelefones={setTelefonesHandler}
      requiredFields={{
        tipoTelefone: !isInspectMode,
        numero: !isInspectMode,
      }}
    />
  );
}
