import { getUriParams } from '../../../helpers/utils/utils';
import { CORE, gateway } from '../../api';

export function buscarPrazoExpiradosAcordoSeguro(payload, ordenacao, page, size) {
  const {
    nomeDevedor, nroProcesso, periodoAcordoInicial, periodoAcordoFinal, valorProcessoInicial, valorProcessoFinal, tipoAntecedente,
  } = payload;

  const sortedQuery = ordenacao.reduce((obj, sort) => (
    `${obj}&sort=${sort}`
  ), '');

  const params = getUriParams({
    nomeDevedor, nroProcesso, periodoAcordoInicial, periodoAcordoFinal, valorProcessoInicial, valorProcessoFinal, tipoAntecedente, page, size,
  });

  return gateway.get(`${CORE}/acordo-seguro/processos/prazos-expirados/?${sortedQuery}&${params}`);
}

export function atualizarStatusExpiradoParaAguardandoEnvioSmsEmail(idProcesso, idUsuario) {
  const payload = {
    idProcesso,
    idUsuario,
  };

  return gateway.put(`${CORE}/acordo-seguro/processos/prazos-expirados/`, payload);
}

export function enviarProcesso(payload) {
  return gateway.post(`${CORE}/acordo-seguro/processos/prazos-expirados/enviar-processo`, payload);
}

export function distribuirProcessoExpiradoAcordoSeguro(payload) {
  return gateway.post(`${CORE}/acordo-seguro/processos/prazos-expirados/distribuir-processo`, payload);
}


export function buscarIdProcessoExpirados(payload) {
  const {
    nomeDevedor, nroProcesso, periodoAcordoInicial, periodoAcordoFinal, valorProcessoInicial, valorProcessoFinal, tipoAntecedente,
  } = payload;

  const params = getUriParams({
    nomeDevedor, nroProcesso, periodoAcordoInicial, periodoAcordoFinal, valorProcessoInicial, valorProcessoFinal, tipoAntecedente,
  });

  return gateway.get(`${CORE}/acordo-seguro/processos/id-processo/prazos-expirados?${params}`);
}
