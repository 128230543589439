import {
  CLEAR_EDITOR_TEXTO,
  RESET_REQUEST_FIELDS,
  SAVE_FAILURE,
  SAVE_SUCCESSFUL,
  SET_ALERT_DIALOG,
  SET_ATTRIBUTES,
  SET_REQUEST_FIELD_FOCUS,
  SET_REQUEST_FIELD_VALUE,
} from './requisicao.constants';
import FieldModel from '../../models/FieldModel';
import { getAttributeExistingObject } from '../../helpers/utils/utils';

const messages = {
  successInsert: 'Requisição cadastrada com sucesso!',
};

function buildRequestFields(fields) {
  return {
    idRequisicao: null,
    nome: new FieldModel({
      name: 'nome',
      value: getAttributeExistingObject(fields, 'nome'),
    }),
    enviarNotificacao: new FieldModel({
      name: 'enviarNotificacao',
      value: getAttributeExistingObject(fields, 'enviarNotificacao', false),
    }),
    descricaoAtividades: new FieldModel({
      name: 'descricaoAtividades',
      value: getAttributeExistingObject(fields, 'descricaoAtividades'),
    }),
  };
}

function buildInitialStates(fields) {
  return {
    requestFields: buildRequestFields(fields),
    content: [],
    usuariosAssociados: [],
    loading: false,
    alert: {
      message: '',
      show: false,
    },
    snackbar: {
      show: false,
      message: '',
    },
    showCrudDialog: false,
  };
}

export const setAttributesAction = (name, value) => ({
  type: SET_ATTRIBUTES,
  name,
  value,
});

export const saveRequisicaoSuccessAction = isEditMode => ({
  type: SAVE_SUCCESSFUL,
  isEditMode,
});

export const saveRequisicaoFailureAction = requestFields => ({
  type: SAVE_FAILURE,
  requestFields,
});

export const setRequestFieldValueAction = (name, value) => ({
  type: SET_REQUEST_FIELD_VALUE,
  name,
  value,
});

export const setRequestFieldFocusAction = name => ({
  type: SET_REQUEST_FIELD_FOCUS,
  name,
});

export const resetRequestFieldsAction = () => ({
  type: RESET_REQUEST_FIELDS,
});

export const setAlertDialogValuesAction = (show, message) => ({
  type: SET_ALERT_DIALOG,
  show,
  message,
});

export const clearEditorTextoAction = () => ({
  type: CLEAR_EDITOR_TEXTO,
});


function clearEditorTextoHandler(states) {
  const newEditor = states.requestFields.descricaoAtividades.getNewModel('');

  newEditor.error = true;
  newEditor.errorMessage = 'Campo Obrigatório';

  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      descricaoAtividades: newEditor,
    },
  };
}

function setAttributesHandler(states, { name, value }) {
  return {
    ...states,
    [name]: value,
  };
}

function saveRequisicaoSuccessHandler(states, { isEditMode }) {
  return {
    ...states,
    requestFields: buildRequestFields(),
    showCrudDialog: false,
    snackbar: isEditMode ? states.snackbar : { show: true, message: messages.successInsert },
  };
}

function saveRequisicaoFailureHandler(states, { requestFields }) {
  return {
    ...states,
    requestFields,
  };
}

function setRequestFieldValueHandler(states, { name, value }) {
  const newValue = states.requestFields[name].getNewModel(value);
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [name]: newValue,
    },
  };
}

function setRequestFieldFocusHandler(states, { name }) {
  const newField = states.requestFields[name].copy();
  newField.error = false;
  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [name]: newField,
    },
  };
}

function resetRequestFieldsHandler(states) {
  return {
    ...states,
    requestFields: buildRequestFields(),
  };
}

function changeAlertDialogValueHandler(states, actions) {
  const alert = { ...states.alert, show: actions.show };
  if (actions.message) {
    alert.message = actions.message;
  }
  return { ...states, alert };
}

export default (states = buildInitialStates(), action) => {
  switch (action.type) {
    case SET_ATTRIBUTES:
      return setAttributesHandler(states, action);
    case SAVE_SUCCESSFUL:
      return saveRequisicaoSuccessHandler(states, action);
    case SAVE_FAILURE:
      return saveRequisicaoFailureHandler(states, action);
    case SET_REQUEST_FIELD_VALUE:
      return setRequestFieldValueHandler(states, action);
    case SET_REQUEST_FIELD_FOCUS:
      return setRequestFieldFocusHandler(states, action);
    case RESET_REQUEST_FIELDS:
      return resetRequestFieldsHandler(states);
    case SET_ALERT_DIALOG:
      return changeAlertDialogValueHandler(states, action);
    case CLEAR_EDITOR_TEXTO:
      return clearEditorTextoHandler(states);
    default:
      return states;
  }
};
