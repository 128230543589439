import { Button, makeStyles, Paper } from '@material-ui/core';
import styled from 'styled-components';
import {
  black, lightGrey, strongPink, white,
} from '../../../../../helpers/constants/colors.constants';


export default makeStyles({

  pesquisaAvancadaButton: {
    color: '#034ddc',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    fontSize: '12px',
    textTransform: 'none',
  },

  pesquisaSimplesButton: {
    color: '#707070',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    fontSize: '12px',
    textTransform: 'none',
  },

  container: {
    width: '100%',
    padding: '22px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },

});

export const Container = styled.div`
  margin: 6px 0px 12px 0;
  box-Shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-Color: ${white};

  > div+div {
    display:flex ;
    flex-direction:column ;
      }

  #resumo{
    display:flex ;
    flex-direction:column ;
    justify-content:start ;
    width:50% ;
  }
`;

export const FiltroPesquisa = styled.div`
  display: flex ;

 
  > div {
    display: flex ;
    flex-direction:row ; 
      > button {
        background-color: ${strongPink};
        color: ${white};
        width: 44px;
        height: 44px;
        margin-left: 7px;

  &:hover {
    background-color: ${strongPink};
    opacity: .7;
  }

  &:disabled {
    border: 1px solid ${lightGrey};
      }
    }  
  }
`;

export const ButtonPesquisaAvancada = styled.div`
  > div {
    width: 94%;
    display: flex;
    justify-content: flex-end;

@media (min-width: 1400px) {
      width: 94%;
    }

    > button {
      width: 40%;
    }
    
  }
`;

export const ContainerPesquisaAvancada = styled.div`

    width: 100%;

  > div {
    display: flex;
    width: 88%;

    #codigoCliente{
      width: 140%;
    margin-right: 8px;
    }
    
  }
`;

export const ButtonImprimir = styled(Button)`
  display: flex;
  justify-content: space-between;
  margin-top: 22px;

  &.MuiButtonBase-root {
    width: 73px;
    padding: 4px 0;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: none;
    color: ${black};

    &.Mui-disabled {
      opacity: 0.64;
    }
  }
`;

export const ContainerResumo = styled.div`
  display: flex ;
  justify-content:center ;
  gap:10px ;
  flex-flow:column ;

  #buttons{
    margin-top:31px ;
    display:flex ;
    justify-content:flex-end ;
  }

  #emitir{
    width: 176px;
    height: 36px;
    margin-right:10px ;
  }

  #agrupar{
    width: 176px;
    height: 36px;
  }

  > h3 {
    display:flex ;
    justify-content:center ;
  }

  > div {
    display:flex ;
    flex-direction:row ;
    gap:4px ;
    justify-content:center ;
    color:${white} ;

    h3 {
      text-align:center ;
      font-size:14px ;
      font-weight:normal ;
    }

    h4 {
      text-align:center ;
      font-size:16px ;
      font-weight:normal ;
    }
  }

  
  > div + div {
    display:flex ;
    flex-direction:row ;
    gap:4px ;
    justify-content:center ;
    color:${white} ;

    h3 {
      text-align:center ;
      font-size:11px ;
      font-weight:normal ;
    }

    h4 {
      text-align:center ;
      font-size:13px ;
      font-weight:normal ;
    }
  }
   `;

export const TableHeader = styled.div`
margin-bottom:13px ;
margin-top:24px ;
font-weight: bold;
font-size: 16px;
color: #000000;
`;

export const TableWithAccordion = styled(Paper)`
  background-color: #f1f4f9;
  border-radius: 7px;
    max-height: ${props => (props.isScrollable ? props.maxTableHeigth : 'auto')};
  overflow-x: auto;

  & td {
    font-size: .75rem;
    padding: 0px 8px !important;
  }

  & th {
    cursor: default;
    font-size: .75rem;
    color: #034ddc;
    font-weight: bold;
    padding: 0px 8px !important;
    height: 33px;
    text-align: center;
    line-height: 1rem;
  }
`;
