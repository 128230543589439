import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../UI/Tabela/PaginadaSimples/Table';
import useUtilStyles from '../../../../helpers/styles/utils.styles';
import TableHeader from '../../../../models/TableHeader';
import { getDateTimeMask, getNewPage } from '../../../../helpers/utils/utils';
import {
  setPageAction,
  setRowsPerPageAndPageAction,
} from '../../../../store/Borderos/Importar/importarBordero.store';
import {
  gerarRelatorioAction,
  pesquisarArquivosImportacaoAction,
} from '../../../../store/Borderos/Importar/importarBordero.saga';
import useStyles from './tableImportar.styles';
import TooltipUI from "../../../UI/Tooltip/Tooltip";


const tableColumns = [
  new TableHeader({
    id: 'lote', label: 'Lote', alignCell: 'center', width: 37, maxWidth: 37,
  }),
  new TableHeader({
    id: 'dataImportacao', label: 'Data da Importação', alignCell: 'center', width: 114,
  }),
  new TableHeader({
    id: 'nomeArquivo', label: 'Nome do Arquivo ', alignCell: 'left', showTip: true, minWidth: 'auto', maxWidth: 200, width: 157,
  }),
  new TableHeader({
    id: 'tamanho', label: 'Tamanho', alignCell: 'center', width: 50,
  }),
  new TableHeader({
    id: 'dataModificacao', label: 'Data da Modificação', alignCell: 'center', width: 136,
  }),
  new TableHeader({
    id: 'responsavel', label: 'Responsável', alignCell: 'left', showTip: true, width: 180,
  }),
  new TableHeader({
    id: 'relatorio', label: 'Relatório', alignCell: 'center', width: 68,
  }),
];

function TableImportar({ habilitarRelatorio = false }) {
  const dispatch = useDispatch();
  const utilStyles = useUtilStyles();
  const styles = useStyles({ habilitarRelatorio });

  const settingsTable = useSelector(states => states.importarBorderos.settingsTable);
  const cacheRequestFields = useSelector(states => states.importarBorderos.cacheRequestFields);

  const [tableRows, setTableRows] = useState([]);

  const previewRowPerPage = useRef();
  const tableContentLength = useRef();
  tableContentLength.current = settingsTable.content.length;

  const onClickGerarRelatorioHandler = useCallback((idBorderoImportar) => {
    if (habilitarRelatorio) {
      dispatch(gerarRelatorioAction(idBorderoImportar));
    }
  }, [dispatch,habilitarRelatorio]);

  const buildImportarTable = useCallback(content => content.map(arquivo => ({
    id: arquivo.idBorderoImportar,
    values: {
      lote: arquivo.loteBordero,
      dataImportacao: getDateTimeMask(arquivo.dataImportacao, true),
      nomeArquivo: arquivo.nomeBordero,
      tamanho: `${arquivo.tamanhoBordero} KB`,
      dataModificacao: getDateTimeMask(arquivo.dataModificacao, true),
      responsavel: arquivo.nomeUsuario,
      relatorio: habilitarRelatorio ? (
        <TooltipUI title="Gerar Relatório" placement="bottom">
          <div className={styles.icon__relatorios}>
            <i className="fas fa-file-download" onClick={() => onClickGerarRelatorioHandler(arquivo.idBorderoImportar)} />
          </div>
        </TooltipUI>
      ) : (
        <div className={styles.icon__relatorios}>
          <i className="fas fa-file-download" />
        </div>
      ),
    },
  })), [styles, onClickGerarRelatorioHandler, habilitarRelatorio]);

  // Construção da tabela
  useEffect(() => {
    setTableRows(buildImportarTable(
      settingsTable.content,
    ));
  }, [settingsTable, setTableRows, buildImportarTable]);


  useEffect(() => {
    dispatch(pesquisarArquivosImportacaoAction(0, 10));
  }, [dispatch]);

  // Realiza a pesquisa de arquivos.
  useEffect(() => {
    const isChangePageWithourTableContent = () => previewRowPerPage.current
      && previewRowPerPage.current !== settingsTable.rowsPerPage
      && tableContentLength.current === 0;

    if (isChangePageWithourTableContent()) {
      previewRowPerPage.current = settingsTable.rowsPerPage;
      return;
    }

    if (cacheRequestFields) {
      dispatch(pesquisarArquivosImportacaoAction(settingsTable.page, settingsTable.rowsPerPage));
    }
    previewRowPerPage.current = settingsTable.rowsPerPage;
  }, [dispatch, cacheRequestFields, settingsTable.page, settingsTable.rowsPerPage]);

  const onChangePageHandler = useCallback((event, newPage) => {
    dispatch(setPageAction(newPage));
  }, [dispatch]);

  const onChangeRowsPerPageHandler = useCallback((event) => {
    const newRowsPerPage = event.target.value;
    const newPage = getNewPage(settingsTable.rowsPerPage, settingsTable.page, newRowsPerPage);
    dispatch(setRowsPerPageAndPageAction(newRowsPerPage, newPage));
  }, [dispatch, settingsTable.page, settingsTable.rowsPerPage]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Typography component="h3" className={utilStyles.tableTitle}>
          Status da Importação
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Table
          rows={tableRows}
          columns={tableColumns}
          page={settingsTable.page}
          size={settingsTable.size}
          rowsPerPage={settingsTable.rowsPerPage}
          isMemory={settingsTable.isMemory}
          onChangePage={onChangePageHandler}
          onChangeRowsPerPage={onChangeRowsPerPageHandler}
        />
      </Grid>
    </Grid>
  );
}

export default TableImportar;
