import styled from 'styled-components';

export const DocumentosClienteContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  padding-top: 8px;
`;

export const HeaderContainerCliente = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
`;
