import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SnackbarUI from '../../../components/UI/Snackbar/Snackbar';

import {
  onAcaoCrudAction,
  onAssignPesquisaCopyAction,
  onChangePageAction,
  onChangeRowsPerPageAction,
  onDigitarPesquisaAction,
  onIdRamoDeleteAction,
  onIdRamoEditAction,
  onNomeRamoEditAction,
  onOpenCrudDialogAction,
  onOpenDeleteRamoAction,
  onOpenDialogRamoAction,
  onRamoMessageDeleteAction,
  onSetActions,
  onSucessCadastroAction,
} from '../../../store/RamoAtividade/Pesquisa/pesquisaRamoAtividade.store';
import { clearControlsAction } from '../../../store/RamoAtividade/Crud/crudRamoAtividade.store';

import { buildRows } from '../../../services/core/ramoAtividade/ramoAtividade.factory';
import SearchLayout from '../../../layouts/SimpleSearchLayout/SimpleSearchLayout';
import TableHeader from '../../../models/TableHeader';
import LoadingUI from '../../../components/UI/Loading/LoadingUI';
import CrudRamoAtividade from '../Crud/CrudRamoAtividadePage';
import { initializePageAction } from '../../../store/theme.actions';
import constants from '../../../helpers/constants/navigationTitle.constants';
import { checkPermission, getNewPage } from '../../../helpers/utils/utils';
import withTokenInterceptor from '../../../hoc/withTokenInterceptor/withTokenInterceptor';
import { CADASTRADO_COM_SUCESSO, CADASTRO_ACTION } from './pesquisarRamoAtividade.constants';
import { ERROR, SEARCH_TABLE_TITLE, SNACKBAR_DURATION } from '../../../helpers/constants/global.constants';
import {
  beforeEditRamoAtividadeAction,
  deleteRamoAtividadeAction,
  findRamoAtividadeAction,
} from '../../../store/RamoAtividade/Pesquisa/pesquisaRamoAtividade.saga';
import { useRamoAtividadeDispatch, useRamoAtividadeSelector } from '../../../store/RamoAtividade/RamoAtividadeProvider';
import AlertDialog from '../../../components/UI/Dialogs/AlertDialog/AlertDialog';
import { BoldContentDialog } from '../../../components/UI/Dialogs/Content/Bold/BoldContentDialog';
import OptionDialogNew from '../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';

const notFound = 'Nenhum resultado encontrado relacionado à sua pesquisa';
const columns = [
  new TableHeader({ id: 'nomeRamo', label: 'Ramo de Atividade', minWidth: 815 }),
  new TableHeader({
    id: 'acoes', label: 'Ações', alignCell: 'center', maxWidth: 121,
  }),
];

function PesquisaRamoAtividadePage({ history }) {
  const dispatch = useRamoAtividadeDispatch();

  dispatch(initializePageAction(`${constants.RAMO_ATIVIDADE}`));

  const userData = useSelector(state => state.authentication.userInformation);
  const pesquisaCopy = useRamoAtividadeSelector(
    states => states.pesquisaRamoAtividade.controls.pesquisa.pesquisaCopy,
  );
  const loading = useRamoAtividadeSelector(states => states.pesquisaRamoAtividade.actions.loading);
  const openSnackBar = useRamoAtividadeSelector(states => states.pesquisaRamoAtividade.actions.openSnackBar);
  const removendo = useRamoAtividadeSelector(states => states.pesquisaRamoAtividade.actions.removendo);
  const fail = useRamoAtividadeSelector(states => states.pesquisaRamoAtividade.actions.fail);
  const sucessCadastro = useRamoAtividadeSelector(states => states.pesquisaRamoAtividade.actions.sucessCadastro);
  const openDialog = useRamoAtividadeSelector(states => states.pesquisaRamoAtividade.actions.openDialog);
  const openDelete = useRamoAtividadeSelector(states => states.pesquisaRamoAtividade.actions.openDelete);
  const openCrudDialog = useRamoAtividadeSelector(states => states.pesquisaRamoAtividade.actions.openCrudDialog);
  const shouldUpdate = useRamoAtividadeSelector(states => states.pesquisaRamoAtividade.actions.shouldUpdateContent);
  const idRamoDelete = useRamoAtividadeSelector(states => states.pesquisaRamoAtividade.controls.idRamoDelete);
  const idRamoEdit = useRamoAtividadeSelector(states => states.pesquisaRamoAtividade.controls.idRamoEdit);
  const nomeRamoEdit = useRamoAtividadeSelector(states => states.pesquisaRamoAtividade.controls.nomeRamoEdit);
  const contentError = useRamoAtividadeSelector(states => states.pesquisaRamoAtividade.controls.contentError);
  const acaoCrud = useRamoAtividadeSelector(states => states.pesquisaRamoAtividade.controls.acaoCrud);
  const ramoDeleteMsg = useRamoAtividadeSelector(states => states.pesquisaRamoAtividade.controls.ramoDeleteMsg);
  const tableContent = useRamoAtividadeSelector(states => states.pesquisaRamoAtividade.controls.tableContent);
  const tablePageable = useRamoAtividadeSelector(states => states.pesquisaRamoAtividade.controls.tablePageable);
  const pesquisaSimples = useRamoAtividadeSelector(
    states => states.pesquisaRamoAtividade.controls.pesquisa.pesquisaSimples,
  );
  const [tableRows, setTableRows] = useState([]);
  const { permissoes } = userData;

  const clickEditAcoesHandler = useCallback(
    (idRamoParam) => {
      dispatch(beforeEditRamoAtividadeAction(idRamoParam));
    },
    [dispatch],
  );

  const clickRemoverHandler = useCallback((idRamoDeleteParam, nome) => {
    dispatch(onOpenDeleteRamoAction(true));
    dispatch(onSetActions(false, false, true, fail));
    dispatch(onIdRamoDeleteAction(idRamoDeleteParam));
    dispatch(onRamoMessageDeleteAction(
      `Deseja excluir o Ramo de Atividade ${nome}? Ao confirmar, o Ramo de Atividade será excluído permanentemente`,
    ));
  }, [dispatch, fail]);

  // Construção da tabela
  useEffect(() => {
    setTableRows(buildRows(tableContent, clickEditAcoesHandler, clickRemoverHandler));
  }, [tableContent, clickEditAcoesHandler, clickRemoverHandler]);

  // Atualização da tabela depois de alguma ação
  useEffect(() => {
    if (shouldUpdate) {
      dispatch(findRamoAtividadeAction(pesquisaCopy, tablePageable.page, tablePageable.rowsPerPage));
    }
  }, [shouldUpdate, pesquisaCopy, tablePageable.page, tablePageable.rowsPerPage, dispatch]);

  const closeDialogHandler = useCallback(() => {
    dispatch(onOpenDeleteRamoAction(false));
  }, [dispatch]);

  const digitarPesquisaHandleChange = (event) => {
    dispatch(onDigitarPesquisaAction(event.target.value));
  };

  const changeRowsPerPageHandler = useCallback(
    async (event) => {
      const { value } = event.target;
      const { rowsPerPage, page } = tablePageable;
      const newPage = getNewPage(rowsPerPage, page, value);

      if (tableContent.length > 0) {
        dispatch(findRamoAtividadeAction(pesquisaCopy, newPage, value));
      }
      dispatch(onChangeRowsPerPageAction(parseInt(value, 10)));
      dispatch(onChangePageAction(newPage));
    },
    [tablePageable, dispatch, pesquisaCopy, tableContent.length],
  );

  const changePageHandler = useCallback(
    (event, newPage) => {
      dispatch(findRamoAtividadeAction(pesquisaCopy, newPage, tablePageable.rowsPerPage));
    },
    [dispatch, tablePageable, pesquisaCopy],
  );

  const closeSnackbarHandler = () => {
    dispatch(onSetActions(loading, false, removendo, fail));
  };

  const closeSuccessSnackbarHandler = () => {
    dispatch(onSucessCadastroAction(false));
  };

  const clickRemoveRamoHandler = () => {
    dispatch(onSetActions(false, openSnackBar, removendo, fail));
    closeDialogHandler();
    dispatch(deleteRamoAtividadeAction(idRamoDelete));
  };

  const openCrudRamoAtividade = useCallback(() => {
    dispatch(onNomeRamoEditAction(''));
    dispatch(onIdRamoEditAction(null));
    dispatch(onOpenCrudDialogAction(true));
    dispatch(clearControlsAction());
    dispatch(onAcaoCrudAction(CADASTRO_ACTION));
  }, [dispatch]);

  function closeDialog() {
    dispatch(onSetActions(false, openSnackBar, true, fail));
    dispatch(onOpenDialogRamoAction(false));
  }

  const closeCrudDialog = useCallback(() => {
    dispatch(onOpenCrudDialogAction(false));
    dispatch(onNomeRamoEditAction(''));
    dispatch(onIdRamoEditAction(null));
    dispatch(clearControlsAction());
  }, [dispatch]);

  const clickSearchHandler = (event) => {
    event.preventDefault();
    dispatch(onAssignPesquisaCopyAction((pesquisaSimples.getValue())));
    dispatch(findRamoAtividadeAction(pesquisaSimples.getValue(), 0, tablePageable.rowsPerPage));
  };

  return (
    <>
      <LoadingUI show={loading} />
      <SnackbarUI
        open={openSnackBar && !removendo}
        autoHideDuration={SNACKBAR_DURATION}
        variant="warning"
        message={notFound}
        onClose={closeSnackbarHandler}
      />
      <SnackbarUI
        open={sucessCadastro}
        autoHideDuration={SNACKBAR_DURATION}
        variant="success"
        message={CADASTRADO_COM_SUCESSO}
        onClose={closeSuccessSnackbarHandler}
      />
      <OptionDialogNew
        open={openDelete}
        onClickCancel={closeDialogHandler}
        onClickConfirm={() => clickRemoveRamoHandler()}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>
            {ramoDeleteMsg}
          </p>
        </div>
      </OptionDialogNew>

      <AlertDialog
        open={openDialog}
        variant={ERROR}
        onClickConfirm={closeDialog}
      >
        <BoldContentDialog fullyMessage={contentError} />
      </AlertDialog>
      {openCrudDialog && (
        <CrudRamoAtividade
          open={openCrudDialog}
          idRamo={idRamoEdit}
          nomeRamo={nomeRamoEdit}
          onClose={closeCrudDialog}
          acao={acaoCrud}
        />
      )}
      <SearchLayout
        history={history}
        allowedNovo={checkPermission(permissoes, 401)}
        onClickButtonNovo={openCrudRamoAtividade}
        tableColumns={columns}
        tableRows={tableRows}
        tablePageable={tablePageable}
        searchFieldLabel="Nome"
        tituloTabela={SEARCH_TABLE_TITLE}
        fieldPesquisa={pesquisaSimples}
        onChangePesquisa={digitarPesquisaHandleChange}
        onClickPesquisa={clickSearchHandler}
        onChangePage={changePageHandler}
        onChangeRowsPerPage={changeRowsPerPageHandler}
      />
    </>
  );
}

export default withTokenInterceptor(withRouter(PesquisaRamoAtividadePage));
