import React from 'react';
import CheckboxField from '../../../components/UI/Field/Checkbox/CheckboxField';
import OrdenacaoColuna from '../../../components/UI/Tabela/Ordenacao/OrdenacaoColuna';
import TableHeader from '../../../models/TableHeader';

class AcordosFactory {
  makeCabecalhoTabelaAcordos(
    setArrowOrdenacao,
    arrowOrdenacao,
    columnOrdenacao,
    setColumnOrdenacao,
    onClickOrdernacaoColumn,
  ) {
    return [
      new TableHeader({
        id: 'nroAcordo',
        label: <OrdenacaoColuna
          columnLabel="Número"
          idColumn="nroAcordo"
          arrowOrdenacao={arrowOrdenacao}
          columnOrdenacao={columnOrdenacao}
          setArrowOrdenacaoFunc={setArrowOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickOrdernacaoColumn('nroAcordo')}
        />,
        alignCell: 'center',
        maxWidth: 85,
      }),
      new TableHeader({
        id: 'dataAcordo',
        label: <OrdenacaoColuna
          columnLabel="Data"
          idColumn="dataAcordo"
          arrowOrdenacao={arrowOrdenacao}
          columnOrdenacao={columnOrdenacao}
          setArrowOrdenacaoFunc={setArrowOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickOrdernacaoColumn('dataAcordo')}
        />,
        maxWidth: 110,
        alignCell: 'center',
      }),
      new TableHeader({
        id: 'nomeFase',
        label: 'Fase',
        alignCell: 'center',
        maxWidth: 154,
      }),
      new TableHeader({
        id: 'nomeUsuarioNegociador',
        label: <OrdenacaoColuna
          columnLabel="Negociador"
          idColumn="nomeUsuarioNegociador"
          arrowOrdenacao={arrowOrdenacao}
          columnOrdenacao={columnOrdenacao}
          setArrowOrdenacaoFunc={setArrowOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickOrdernacaoColumn('nomeUsuarioNegociador')}
        />,
        alignCell: 'left',
        maxWidth: 208,
      }),
      new TableHeader({
        id: 'idStatusAcordo',
        label: 'Status',
        alignCell: 'center',
        maxWidth: 75,
      }),
      new TableHeader({
        id: 'descricaoFormaPagto',
        label: 'Forma do Pagamento',
        alignCell: 'center',
        maxWidth: 140,
      }),
      new TableHeader({
        id: 'valor',
        label: 'Valor',
        alignCell: 'right',
        maxWidth: 125,
      }),
      new TableHeader({
        id: 'acoes',
        label: 'Ações',
        alignCell: 'center',
        maxWidth: 190,
        color: 'black',
      }),
    ];
  }

  makeCabecalhoTabelNovoAcordo(
    setArrowOrdenacao,
    arrowOrdenacao,
    columnOrdenacao,
    setColumnOrdenacao,
    onClickOrdernacaoColumn,
    selectAll = { checked: false, onChange: () => { } },
  ) {
    return [
      new TableHeader({
        id: 'idTitulo',
        checkbox: true,
        label: (
          <CheckboxField
            checked={selectAll.checked}
            onChange={selectAll.onChange}
          />),
        width: 16,
      }),
      new TableHeader({
        id: 'sequencia',
        label: <OrdenacaoColuna
          columnLabel="Sequência"
          idColumn="sequencia"
          arrowOrdenacao={arrowOrdenacao}
          columnOrdenacao={columnOrdenacao}
          setArrowOrdenacaoFunc={setArrowOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickOrdernacaoColumn('sequencia')}
        />,
        alignCell: 'center',
        maxWidth: 90,
      }),
      new TableHeader({
        id: 'idTipoTitulo',
        label: 'Tipo',
        alignCell: 'center',
        maxWidth: 90,
      }),
      new TableHeader({
        id: 'numero',
        label: 'Número',
        alignCell: 'center',
        maxWidth: 154,
      }),
      new TableHeader({
        id: 'dataVencimento',
        label: <OrdenacaoColuna
          columnLabel="Vencimento"
          idColumn="dataVencimento"
          arrowOrdenacao={arrowOrdenacao}
          columnOrdenacao={columnOrdenacao}
          setArrowOrdenacaoFunc={setArrowOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickOrdernacaoColumn('dataVencimento')}
        />,
        maxWidth: 117,
        alignCell: 'center',
      }),
      new TableHeader({
        id: 'atraso',
        label: <OrdenacaoColuna
          columnLabel="Atraso"
          idColumn="atraso"
          arrowOrdenacao={arrowOrdenacao}
          columnOrdenacao={columnOrdenacao}
          setArrowOrdenacaoFunc={setArrowOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickOrdernacaoColumn('atraso')}
        />,
        maxWidth: 117,
        alignCell: 'center',
      }),
      new TableHeader({
        id: 'valorOriginal',
        label: 'Valor Original',
        alignCell: 'right',
        maxWidth: 154,
      }),
      new TableHeader({
        id: 'valorSaldoOriginal',
        label: <OrdenacaoColuna
          columnLabel="Saldo do Original"
          idColumn="valorSaldoOriginal"
          arrowOrdenacao={arrowOrdenacao}
          columnOrdenacao={columnOrdenacao}
          setArrowOrdenacaoFunc={setArrowOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickOrdernacaoColumn('valorSaldoOriginal')}
        />,
        alignCell: 'right',
        maxWidth: 154,
      }),
    ];
  }

  makeCabecalhoTabelaAcordosAccordion(
  ) {
    return [
      new TableHeader({
        id: 'nroParcela',
        label: 'Nº Parcela',
        alignCell: 'center',
        maxWidth: 90,
        iconLabel: 'black',
      }),
      new TableHeader({
        id: 'idTipoFormaPagto',
        label: 'Forma do Pagamento',
        alignCell: 'center',
        maxWidth: 165,
      }),
      new TableHeader({
        id: 'dataVencimento',
        label: 'Vencimento',
        alignCell: 'center',
        maxWidth: 135,
      }),
      new TableHeader({
        id: 'valor',
        label: 'Valor',
        alignCell: 'right',
        maxWidth: 110,
      }),
      new TableHeader({
        id: 'idStatusParcelaAcordo',
        label: 'Status da Parcela',
        alignCell: 'center',
        maxWidth: 150,
        color: 'black',
      }),
      new TableHeader({
        id: 'acoes',
        label: '',
        alignCell: 'center',
        maxWidth: 140,
        color: 'black',
      }),
    ];
  }
}


export const acordosFactory = new AcordosFactory();
