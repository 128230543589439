import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({

  root: {
    width: '100%',
    '& img': {
      width: '20px',
      height: '20px',
    },
  },
  display__none: {
    display: 'none',
  },
});
