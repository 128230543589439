import React, { useState } from 'react';
import cx from 'classnames';
import { InputLabel } from '@material-ui/core';
import TextareaAutosize from 'react-textarea-autosize';

import TooltipUI from '../../Tooltip/Tooltip';
import useStyles from './TextareaField.styles';

function TextareaField({
  value,
  disabled = false,
  error = false,
  errorMessage = '',
  label,
  loading,
  fullWidth = true,
  labelProps,
  formProps,
  classForm = '',
  maxCounter = 1000,
  showCounter = false,
  onChange = () => null,
  tooltipProps = {},
  ...props

}) {
  const styles = useStyles({ disabled });
  const [counter, setCounter] = useState(0);

  const changeHandler = (event) => {
    setCounter(event.target.value.length);
    onChange(event);
  };

  const counterLabel = `${counter}/${maxCounter}`;
  const clazz = cx(
    styles.formRoot,
    classForm,
    {
      [styles.loading]: loading,
      [styles.fullWidth]: fullWidth,
      [styles.error]: error,
    },
  );

  const buildContent = () => (
    <div
      {...formProps}
      className={clazz}
    >
      <InputLabel error={error} {...labelProps}>{label}</InputLabel>
      <TextareaAutosize
        disabled={disabled}
        value={value}
        onChange={changeHandler}
        {...props}
      />
    </div>
  );

  const buildField = () => {
    const content = buildContent();
    if (error) {
      return (
        <TooltipUI error title={errorMessage} placement="bottom-start" {...tooltipProps}>
          {content}
        </TooltipUI>
      );
    }
    return content;
  };

  return (
    <>
      {buildField()}
      {showCounter && (
        <div className={styles.counter}>
          <span>{counterLabel}</span>
        </div>
      )}
    </>
  );
}

export default React.memo(TextareaField);
