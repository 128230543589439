import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  div__root: {
    width: '100%',
    display: 'flex',
    paddingTop: '28px',
    justifyContent: 'flex-end',
  },
  fieldsTop: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fieldsBottom: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px',
  },
  field__devedor: {
    width: '342px',
    marginRight: '9px',
  },
  field__nroProcesso: {
    width: '147px',
    marginRight: '9px',
  },
  field__antecedentes: {
    width: '168px',
    marginRight: '9px',
  },
  div__fields: {
    display: 'flex',
  },
  div__rootAvancada: {
    width: '60%',
  },
  div__pesquisaAvancada: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  div__pesquisarBtn: {
    backgroundColor: '#ff1381',
    color: 'white',
    width: '44px',
    height: '44px',
    '&:hover': {
      backgroundColor: '#ff1381',
      opacity: '.7',
    },
  },
  div__pesquisaAvancadaBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '34px',
    '& button': {
      marginRight: '34px',
      fontWeight: 'bold',
      textTransform: 'none',
      fontSize: '12px',
    },
  },
});
