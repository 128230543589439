import moment from 'moment';
import {
  getMoneyMask, textOverflow, toAmericanDate, toBrazilianDate,
} from '../../../../helpers';
import FieldModel from '../../../../models/FieldModel';

moment.locale('pt-br');
export const ContaCorrenteClienteFactory = {

  getProximoDiaUtil(date) {
    if (date.getDay() === 0) {
      return moment(date).add(1, 'd');
    }
    if (date.getDay() === 6) {
      return moment(date).add(2, 'd');
    }
    if (date.getDay() === 5) {
      return moment(date).add(3, 'd');
    }
    return moment(date).add(1, 'd');
  },


  isFimDeSemana(date, minDate) {
    const payloadDateAmericanFormat = toAmericanDate(date);
    const formatedDate = moment(payloadDateAmericanFormat);

    if (formatedDate.isValid() && formatedDate > minDate) {
      return formatedDate._d.getDay() === 0 || formatedDate._d.getDay() === 6;
    }
    return false;
  },

  makeContentResponseContaCorrente(contaCorrente) {
    return {
      idCliente: contaCorrente.idCliente,
      nomeCliente: contaCorrente.nomeCliente,
      tipoPrestacao: contaCorrente.descricaoTipoPrestacao,
      statusPrestacaoContas: contaCorrente.statusPrestacaoContas,
      valorRepasse: getMoneyMask(contaCorrente.valorRepasse),
      valorRepasseWithoutMask: contaCorrente.valorRepasse,
    };
  },

  makeTiposDeLancamentos(tiposDeLancamentos) {
    return {
      id: String(tiposDeLancamentos?.idTipoLancamento),
      value: tiposDeLancamentos?.descricao,
    };
  },
  makeResponsaveis(responsaveis) {
    return {
      id: responsaveis?.idUsuario,
      value: responsaveis?.nomeUsuario,
    };
  },

  makeContasCorrentes(res) {
    return {
      content: res?.content?.map(this.makeContentResponseContaCorrente) || [],
      size: res?.size || 10,
      page: res?.number || 0,
      totalElements: res?.totalElements || 0,
      lastPage: res?.last,
    };
  },

  makeTodasContasCorrentes(res) {
    return res.map(cliente => ({
      idCliente: cliente.idCliente,
      nomeClienteAlt: cliente?.nomeCliente || null,
      nomeCliente: textOverflow(cliente?.nomeCliente || '', 23),
      tipoPrestacao: cliente.descricaoTipoPrestacao,
      statusPrestacaoContas: cliente.statusPrestacaoContas,
      valorRepasse: getMoneyMask(cliente.valorRepasse),
      valorRepasseWithoutMask: cliente.valorRepasse,
    }));
  },

  makeIdsContasCorrenteClientes(res) {
    return res.map(cliente => ({
      idCliente: cliente.idCliente,
      statusPrestacaoContas: cliente.statusPrestacaoContas,
    }));
  },

  buildInitialStatesForm() {
    return {
      dataPrestacao: new FieldModel({ name: 'dataPrestacao', value: toBrazilianDate(new Date()) }),
      idCliente: new FieldModel({ name: 'idCliente', value: '' }),
      idFilial: new FieldModel({ name: 'idFilial', value: null }),
      idUsuarioResponsavel: new FieldModel({ name: 'idUsuarioResponsavel', value: null }),
      nossoNumeroBoleto: new FieldModel({ name: 'nossoNumeroBoleto', value: null }),
      tipoLancamento: new FieldModel({ name: 'tipoLancamento', value: null }),
      nomeCliente: new FieldModel({ name: 'nomeCliente', value: '' }),
    };
  },

  makeErrorFields() {
    return {
      idCliente: {
        error: false,
        errorMessage: '',
      },
      dataPrestacao: {
        error: false,
        errorMessage: '',
      },
      nossoNumeroBoleto: {
        error: false,
        errorMessage: '',
      },
    };
  },

};
