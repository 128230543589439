import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const DocumentosProcessoContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  padding-top: 8px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const WarningContainer = styled.div`
  text-align: center;
  font-weight: bold;
`;

export const CustomButton = styled(Button)`
  display: flex;
  align-items: center;

  border-radius: 4px;
  border: 1px solid #564872;
  color: #564872;
  font-weight: bold;

  width: 89px;
  height: 26px;

  text-transform: none;

  :disabled {
    border: 1px solid #A2A0A0;
    color: #A2A0A0;
  }

  & .MuiButton-label span {
    margin-left: 8px;
  }
`;
