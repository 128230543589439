import React from 'react';
import useStyles from './whiteCircleIcon.styles';

function WhiteCircleIcon({ children }) {
  const styles = useStyles();

  return (
    <div className={styles.div__icon__root}>
      {children}
    </div>
  );
}

export default WhiteCircleIcon;
