import React, { memo, useMemo, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import TooltipUI from '../../UI/Tooltip/Tooltip';
import { useUtilStyles } from '../../../helpers';
import { TableWithAccordion } from './PassoDois.style';

function getAlignOption(row, column) {
  let alignOption = row.values[column.id] ? column.alignCell : 'center';
  if (!row.values[column.id] && column.showTip) {
    alignOption = 'center';
  }
  return alignOption;
}

function Row({ row, columns, columnLength }) {
  const styles = useUtilStyles();
  const [open, setOpen] = useState(false);

  const getContent = (value, column) => {
    if (column.checkbox) {
      return value;
    }

    if (column.showTip) {
      return (
        <TooltipUI title={value} placement="bottom" interactive>
          <pre
            className={styles.overFlowText}
            style={{ maxWidth: column.maxWidth - 16 }}
          >
            {column.format && typeof value === 'number'
              ? column.format(value)
              : value}
          </pre>
        </TooltipUI>
      );
    }
    if (column.hiddenFullLine) {
      return (
        <pre
          className={styles.overFlowText}
          style={{ maxWidth: column.maxWidth - 16 }}
        >
          {column.format && typeof value === 'number'
            ? column.format(value)
            : value}
        </pre>
      );
    }
    return (
      <pre>
        {column.format && typeof value === 'number'
          ? column.format(value)
          : value}
      </pre>
    );
  };

  return (
    <>
      <TableRow className={styles.tableRowRoot}>
        {columns.map((column) => {
          const value = row.values[column.id];
          const alignOption = getAlignOption(row, column);
          return (
            <TableCell
              key={column.id}
              style={{
                maxWidth: column.maxWidth,
                minWidth: column.minWidth,
                width: column.width,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: alignOption,
                }}
              >
                {getContent(value, column)}
              </div>
            </TableCell>
          );
        })}
        <TableCell style={{ borderTop: '1px solid #e0e0e0' }}>
          <IconButton
            size="small"
            onClick={() => setOpen(!open)}
            style={{ color: '#034ddc' }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={columnLength}
          style={{ paddingBottom: 0, paddingTop: 0 }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginLeft: '35px',
                  marginRight: '66px',
                }}
              >
                <p>
                  <b>Protesto: </b>
                  {row.values.valorProtesto}
                </p>
                <p>
                  <b>Multa: </b>
                  {row.values.valorMulta}
                </p>
                <p>
                  <b>Juros: </b>
                  {row.values.valorJuros}
                </p>
                <p>
                  <b>Honorários: </b>
                  {row.values.valorHonorarios}
                </p>
                <p>
                  <b>Descontos: </b>
                  {row.values.valorDesconto}
                </p>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function TablePassoDois({ columns = [], rows = [], rowsPerPage }) {
  const columnLength = useMemo(() => columns.length + 1, [columns.length]);

  const buildEmptyRows = () => {
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

    const content = [];
    for (let i = 0; i < emptyRows; i += 1) {
      content.push(
        <TableRow key={i} style={{ height: 31 }}>
          <TableCell colSpan={columnLength}>
            <p />
          </TableCell>
        </TableRow>,
      );
    }
    return content;
  };

  return (
    <>
      <TableWithAccordion>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  id={column.id}
                  key={column.id}
                  style={{
                    maxWidth: column.maxWidth,
                    minWidth: column.minWidth,
                    width: column.width,
                    textAlign: column.align,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <Row
                key={row.id}
                row={row}
                columns={columns}
                columnLength={columnLength}
              />
            ))}
            {buildEmptyRows()}
          </TableBody>
        </Table>
      </TableWithAccordion>
    </>
  );
}

export default memo(TablePassoDois);
