import { TableRow } from '@material-ui/core';
import React from 'react';
import { textOverflow } from '../../../helpers';
import { TableCellCenter, TableCellLeft, TableCellRight } from '../../UI/Tabela/tableDefaultStyles';
import TooltipUI from '../../UI/Tooltip/Tooltip';

function TableBodyLancamentosComponent({ lancamento }) {
  return (
    <TableRow>
      <TableCellCenter>
        {lancamento.data}
      </TableCellCenter>
      <TableCellCenter>
        {lancamento.idProcesso}
      </TableCellCenter>
      <TableCellCenter>
        {lancamento.descricaoLancamento}
      </TableCellCenter>
      <TableCellLeft>
        <TooltipUI title={lancamento.historico}>
          <span>
            {textOverflow(lancamento.historico, 125)}
          </span>
        </TooltipUI>
      </TableCellLeft>
      <TableCellRight>
        {lancamento.valorCredito}
      </TableCellRight>
      <TableCellRight>
        {lancamento.valorDebito}
      </TableCellRight>
    </TableRow>
  );
}

export const TableBodyLancamentos = TableBodyLancamentosComponent;
