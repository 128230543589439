import { IconButton } from '@material-ui/core';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';


export const ButtonInverterParaCopia = styled(IconButton)`
    width:28px;
    height:28px;

    background-color: white;
    color: #423362;
    border: 1px solid #423362;

:hover {
  opacity: 0.64;
}

:disabled {
  background-color: #c4c4c4;
}
`;


export default makeStyles({
  dialog: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '1200px',
    },
    typography: {
      fontSize: '12px',
    },
  },
  dialogInformacoes: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '500px',
    },
    typography: {
      fontSize: '12px',
    },
  },
});
