import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function LockOpenIcon(props) {
  const bloquearLancamento = 'M12.1905 0C10.0876 0 8.38095 1.70667 8.38095 3.80952V5.33333H1.52381C1.11967 5.33333 0.732083 5.49388 0.446313 5.77965C0.160544 6.06542 0 6.453 0 6.85714V14.4762C0 14.8803 0.160544 15.2679 0.446313 15.5537C0.732083 15.8395 1.11967 16 1.52381 16H10.6667C11.5124 16 12.1905 15.3219 12.1905 14.4762V6.85714C12.1905 6.453 12.0299 6.06542 11.7442 5.77965C11.4584 5.49388 11.0708 5.33333 10.6667 5.33333H9.90476V3.80952C9.90476 2.54476 10.9257 1.52381 12.1905 1.52381C13.4552 1.52381 14.4762 2.54476 14.4762 3.80952V5.33333H16V3.80952C16 1.70667 14.2933 0 12.1905 0ZM6.09524 9.14286C6.49938 9.14286 6.88696 9.3034 7.17273 9.58917C7.4585 9.87494 7.61905 10.2625 7.61905 10.6667C7.61905 11.5124 6.94095 12.1905 6.09524 12.1905C5.6911 12.1905 5.30351 12.0299 5.01774 11.7442C4.73197 11.4584 4.57143 11.0708 4.57143 10.6667C4.57143 10.2625 4.73197 9.87494 5.01774 9.58917C5.30351 9.3034 5.6911 9.14286 6.09524 9.14286V9.14286Z';
  return (
    <SvgIcon transform="scale(1.13)" viewBox="-4 -4 24 24" {...props}>
      <path d={bloquearLancamento} />
    </SvgIcon>
  );
}
