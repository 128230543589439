import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import OrdenacaoColuna from '../UI/Tabela/Ordenacao/OrdenacaoColuna';

function TableHeaderContaCorrenteHonorarioComponent({ columnOrder, onChangeOrder }) {
  return (
    <TableRow>
      <TableCell height={50} width={161}>
        <OrdenacaoColuna
          columnLabel="Data do Lançamento"
          idColumn="dataLancamento"
          arrowOrdenacao={columnOrder?.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder('dataLancamento')}
        />
      </TableCell>
      <TableCell height={50} width={534}>
        <OrdenacaoColuna
          columnLabel="Histórico do Lançamento"
          idColumn="historico"
          arrowOrdenacao={columnOrder?.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder('historico')}
        />
      </TableCell>
      <TableCell height={50} width={160}>
        <OrdenacaoColuna
          columnLabel="Crédito"
          idColumn="valorCredito"
          arrowOrdenacao={columnOrder?.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder('valorCredito')}
        />
      </TableCell>
      <TableCell height={50} width={160}>
        <OrdenacaoColuna
          columnLabel="Débito"
          idColumn="valorDebito"
          arrowOrdenacao={columnOrder?.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder('valorDebito')}
        />
      </TableCell>
      <TableCell height={50} width={131}>
        <OrdenacaoColuna
          columnLabel="Consolidado"
          idColumn="estarConsolidado"
          arrowOrdenacao={columnOrder?.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder('estarConsolidado')}
        />
      </TableCell>
    </TableRow>
  );
}


export const TableHeaderContaCorrenteHonorario = TableHeaderContaCorrenteHonorarioComponent;
