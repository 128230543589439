import styled from 'styled-components';


export const Container = styled.div`
  >p {
    margin-bottom: 15px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #707070;
  }
`;


export const ButtonGroup = styled.div`
  display: -webkit-inline-box;
  -webkit-box-align: end;
  margin-top: -20px;

  >button {
    &.MuiButtonBase-root {
      padding: 7px;
      border-radius: 4px;
      color: #564872;

      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-transform: none;

      &.active {
        background-color: #564872;
        color: white;
      }

      &.aplicar-action {
        width: 94px;
      }
    }

    &.MuiButton-outlined {
      border: 1px solid #564872;
    }
  }

  button {
    &.action-apply {
      width: 94px;
      
      &.Mui-disabled {
        &.MuiButton-outlined {
          border: 1px solid rgba(0, 0, 0, 0.12);
          color: rgba(0, 0, 0, 0.26);
        }
      }
    }
  }
  
  >button + button {
    margin-left: 8px;
  }
`;

export const DateFieldContainer = styled.div`
  display: flex;
  align-items: flex-end;

  margin-left: 11px;
  margin-right: 15px;

  > div:last-child {
    margin-left: 8px;
  }
  
  .MuiFormControl-root {
    .MuiIconButton-label {
      svg {
        color: black;
      }
    }
  }
`;
