import { v4 as uuid } from 'uuid';
import { format, parseISO } from 'date-fns';
import { getDayOfWeek } from '../../../helpers/utils/utils';
import { buscarAcompanhamentoProcesso } from '../../../services/core/processos/processosService';

const ORDENACAO_ACOMPANHAMENTOS_PROCESSO = [
  'fixoAcomp,ASC', 'dataCadastramento,ASC',
];


class AcompanhamentoProcessoController {
  makeDateIndicator() {
    return {
      index: -1,
      unique: 0,
      getLabel(date) {
        return `${this.unique}?${date}`;
      },
      update(date, index, mapping) {
        if ((index - 1) === this.index && !mapping[this.getLabel(date)]) {
          this.unique += 1;
        }
        this.index = index;
      },
    };
  }

  makeItem(acompanhamento, dataHora) {
    const apelido = (acompanhamento.apelido && String(acompanhamento.apelido)) || '';
    const nomeUsuario = (acompanhamento.nomeUsuario && ` - ${String(acompanhamento.nomeUsuario)}`) || '';
    const email = (acompanhamento.email && ` - ${String(acompanhamento.email)}`) || '';
    return ({
      key: uuid(),
      idProcessoAcompanhamento: acompanhamento.idProcessoAcompanhamento,
      idTipoAcompanhamento: acompanhamento.idTipoAcompanhamento,
      descricaoTipo: acompanhamento.descricaoTipo && String(acompanhamento.descricaoTipo),
      dataHora,
      usuario: `${apelido}${nomeUsuario}${email}`,
      texto: acompanhamento.texto && String(acompanhamento.texto),
      faseAtualProcesso: acompanhamento.faseAtualProcesso && String(acompanhamento.faseAtualProcesso),
      faseSeguinteProcesso: acompanhamento.faseSeguinteProcesso && String(acompanhamento.faseSeguinteProcesso),
      fixar: acompanhamento.fixar && String(acompanhamento.fixar),
    });
  }

  makeItems(acompanhamentos = []) {
    if (acompanhamentos && acompanhamentos.length === 0) {
      return [];
    }

    const dateIndicator = this.makeDateIndicator();

    return acompanhamentos.reduce((mapping, acompanhamento, index) => {
      if (!acompanhamento.dataCadastramento) {
        return mapping;
      }

      const dataCompleta = parseISO(acompanhamento.dataCadastramento);
      const data = `${format(dataCompleta, 'dd/MM/yyyy')} - ${getDayOfWeek(dataCompleta.getDay())}`;
      const dataHora = format(dataCompleta, 'dd/MM/yyyy - HH:mm');

      dateIndicator.update(data, index, mapping);

      if (mapping[dateIndicator.getLabel(data)]) {
        return {
          ...mapping,
          [dateIndicator.getLabel(data)]: [...mapping[dateIndicator.getLabel(data)], this.makeItem(acompanhamento, dataHora)],
        };
      }

      return {
        ...mapping,
        [dateIndicator.getLabel(data)]: [this.makeItem(acompanhamento, dataHora)],
      };
    }, {});
  }

  async findAllFromPage(idProcesso, queryParams = {}, page = 0) {
    try {
      const response = await buscarAcompanhamentoProcesso(idProcesso, ORDENACAO_ACOMPANHAMENTOS_PROCESSO, { page, ...queryParams });
      return { acompanhamentos: response.data, error: null };
    } catch (error) {
      return { error, acompanhamentos: [] };
    }
  }


  async findAllFromNextPage(idProcesso, oldersAcompanhamentosResponse, queryParams = {}) {
    try {
      const data = await this.findAllFromPage(idProcesso, queryParams, oldersAcompanhamentosResponse.number + 1);
      data.acompanhamentos.content = [...oldersAcompanhamentosResponse.content, ...data.acompanhamentos.content];
      return { acompanhamentos: data.acompanhamentos };
    } catch (error) {
      return { error, acompanhamentos: oldersAcompanhamentosResponse };
    }
  }
}

export const acompanhamentoProcessoController = new AcompanhamentoProcessoController();
