import makeStyles from '@material-ui/styles/makeStyles';

import { blue } from '../../../helpers/constants/colors.constants';

export default makeStyles({
  properties: {
    color: blue,
    fontSize: '16px',
    fontWeight: 'bold',
  },
  propertiesInputLabel: {
    fontSize: '12px',
    color: '#707070',
  },
  disabled: {
    color: 'grey',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  disabledInputLabel: {
    fontSize: '12px',
    color: '#707070',
  },
});
