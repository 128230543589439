import { gateway } from '../api';

const LOGIN = '/autenticacao/login';
const REFRESH_TOKEN = '/autenticacao/refresh-token';

export const getAuthentitionInformation = (usernameParam, passwordParam) => (
  gateway.post(LOGIN, {}, {
    headers: {
      usuario: usernameParam,
      senha: passwordParam,
    },
  })
);

export const getAuthenticationInformationAfterRefresh = refreshTokenParam => (
  gateway.put(REFRESH_TOKEN, {}, {
    headers: {
      refreshToken: refreshTokenParam,
    },
  })
);
