import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  titleEnvioEmail: {
    color: '#034DDC',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  headerBoxEnviarEmail: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',
  },
});
