export const statusContaCorrenteUsuarioType = {
  naoPrestado: 'NAO_PRESTADO',
  prestado: 'PRESTADO',
  bloqueadoAutomaticamente: 'BLOQUEADO_AUTOMATICAMENTE',
  bloqueadoManualmente: 'BLOQUEADO_MANUALMENTE',

  isOnlyNaoPrestado(status) {
    return status ? ['NAO_PRESTADO', 'BLOQUEADO_AUTOMATICAMENTE', 'BLOQUEADO_MANUALMENTE'] : ['BLOQUEADO_AUTOMATICAMENTE', 'BLOQUEADO_MANUALMENTE', 'PRESTADO', 'NAO_PRESTADO'];
  },
};
