import { makeStyles } from '@material-ui/core/styles';
import { blue } from '../../../../helpers/constants/colors.constants';

export default makeStyles({
  outlinedRoot: {
    '& .MuiInputBase-root': {
      fontSize: '0.865rem',
    },
    '& .MuiFormLabel-root': {
      fontSize: '0.865rem',
      color: '#707070',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px) scale(0.857)',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: blue,
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#e91b1b !important',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: '#707070',
    },
    '& .MuiInput-underline::after': {
      borderBottom: `2px solid ${blue}`,
    },
    '& .MuiInput-underline.Mui-error::after': {
      borderBottom: '2px solid #e91b1b',
    },
  },
  formRoot: {
    border: '0',
    margin: '0',
    display: 'inline-flex',
    padding: '0',
    position: 'relative',
    minWidth: '0',
    flexDirection: 'column',
    verticalAlign: 'top',
    '&:focus-within': {
      '& label': {
        color: blue,
      },
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#e91b1b !important',
    },
    '& .MuiInput-underline.Mui-error::after': {
      borderBottom: '2px solid red',
    },
    '& .MuiInputBase-input': {
      fontSize: '0.75rem',
      padding: '3px 6px 4px',
      borderRadius: '2px',
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#48484820',
      borderRadius: '2px',
    },
    '& .MuiFormLabel-root': {
      fontSize: '0.75rem',
      color: '#707070',
      marginBottom: '3px',
    },

    '& .MuiInputBase-input.Mui-disabled': {
      color: '#707070',
    },
    '& .MuiInput-underline::after': {
      borderBottom: `2px solid ${blue}`,
    },
  },
  loading: {
    '& .MuiCircularProgress-root': {
      color: blue,
    },
    '& .MuiInputBase-root ': {
      cursor: 'wait !important',
      paddingRight: '6px',
    },
    '& input': {
      cursor: 'wait !important',
    },
  },
  fullWidth: {
    width: '100%',
  },
});
