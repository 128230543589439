import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid24 from '../../../../../../UI/Grid24/Grid24';
import SelectorField from '../../../../../../UI/Field/Selector/SelectorField';
import {
  FORMA_PAGAMENTO_LABEL,
  QUANTIDADE_BORDERO_DIAS_LABEL,
  QUANTIDADE_BORDERO_LABEL,
} from './processamentoFields.constants';
import NumberInput from '../../../../../../UI/Field/Number/NumberInput';
import { getRequiredLabel } from '../../../../../../../helpers/utils/utils';
import {
  useCrudClienteDispatch,
  useCrudClienteSelector,
} from '../../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import { onChangeControls } from '../../../../../../../store/Cliente/V2/Crud/crudCliente.store';
import {
  findTipoAcordoSeguroAction,
  findTipoComissaoBorderoAction,
} from '../../../../../../../store/Global/LoadSelectors/loadSelectors.saga';

function ProcessamentoFields({
  onChange,
  onFocus,
  disabled,
}) {
  const dispatch = useCrudClienteDispatch();
  const globalDispatch = useDispatch();

  const tipoComissaoBorderoSelector = useSelector(states => states.selectors.tipoComissaoBordero.selector);


  const idTipoComissaoBordero = useCrudClienteSelector(states => states.controls.idTipoComissaoBordero.copy());
  const qtdeComissaoBordero = useCrudClienteSelector(states => states.controls.qtdeComissaoBordero.copy());


  const onChangeComissaoBordero = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(onChangeControls(name, value));
    dispatch(onChangeControls('qtdeComissaoBordero', ''));
  }, [dispatch]);

  const labelQtdBordero = () => (idTipoComissaoBordero.value === 1 ? QUANTIDADE_BORDERO_LABEL : QUANTIDADE_BORDERO_DIAS_LABEL);

  // Busca tipo acordo seguro e tipo comissao bordero
  useEffect(() => {
    globalDispatch(findTipoAcordoSeguroAction());
    globalDispatch(findTipoComissaoBorderoAction());
  }, [globalDispatch]);

  return (
    <>
      <Grid24 isFormContainer container spacing="1">
        <Grid24 xs={24} sm={8}>
          <SelectorField
            isForm
            items={tipoComissaoBorderoSelector}
            name={idTipoComissaoBordero.name}
            value={idTipoComissaoBordero.value}
            error={idTipoComissaoBordero.error}
            errorMessage={idTipoComissaoBordero.errorMessage}
            label={getRequiredLabel(FORMA_PAGAMENTO_LABEL, !disabled)}
            onChange={onChangeComissaoBordero}
            disabled={disabled}
          />
        </Grid24>
        <Grid24 xs={24} sm={4}>
          <NumberInput
            isForm
            acceptEmptyValue
            name={qtdeComissaoBordero.name}
            value={qtdeComissaoBordero.value}
            error={qtdeComissaoBordero.error}
            errorMessage={qtdeComissaoBordero.errorMessage}
            label={getRequiredLabel(labelQtdBordero(), !disabled)}
            onChange={onChange}
            onFocus={onFocus}
            disabled={disabled}
          />
        </Grid24>
      </Grid24>
    </>
  );
}

export default ProcessamentoFields;
