import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  dialog: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth: '671px',
    },
  },
  div__root: {
    display: 'flex',
    width: '100%',
  },
  div__info__root: {
    display: 'block',
    width: '100%',
  },
  div__calendario__root: {
    display: 'flex',
    width: '50%',
  },
  wrapperSubtitle: {
    marginBottom: '12px',
  },
  infos__gerais: {
    marginBottom: '16px',
    fontSize: '12px',
  },
  div__footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '48px',
  },
  button__cancelar: {
    width: '100px',
    marginRight: '10px',
  },
  button__salvar: {
    width: '152px',
  },
});
