import React from 'react';

import useUtilStyles from '../../../../../helpers/styles/utils.styles';

import Grid24 from '../../../../UI/Grid24/Grid24';
import FormWrapperTitle from '../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import InputField from '../../../../UI/Field/Input/InputField';
import { useCrudUsuarioSelector2 } from '../../../../../store/Usuario/V2/CrudUsuarioProvider';
import {
  ADOVGADO_TITLE, NACIONALIDADE_TITLE, OAB_TITLE, SECCIONAL_TITLE,
} from './AdvogadoCrudUsuario.constants';


function AdvogadoUsuario({ disabled, changeHandler }) {
  const utilStyles = useUtilStyles();

  const isAdvogado = useCrudUsuarioSelector2(states => states.actions.isAdvogado);
  const advogadoNacionalidade = useCrudUsuarioSelector2(states => states.controls.advogadoNacionalidade.copy());
  const advogadoSeccional = useCrudUsuarioSelector2(states => states.controls.advogadoSeccional.copy());
  const advogadoOab = useCrudUsuarioSelector2(states => states.controls.advogadoOab.copy());

  return (
    <FormWrapperTitle title={ADOVGADO_TITLE}>
      <div className={utilStyles.mt16}>
        <Grid24 container isFormContainer spacing="1">
          <Grid24 xs={24} sm={5}>
            <InputField
              isForm
              fullWidth
              disabled={isAdvogado || disabled}
              label={NACIONALIDADE_TITLE}
              name={advogadoNacionalidade.name}
              value={advogadoNacionalidade.value}
              error={advogadoNacionalidade.error}
              errorMessage={advogadoNacionalidade.errorMessage}
              onChange={changeHandler}
              maxLength={advogadoNacionalidade.maxLength}
            />
          </Grid24>
          <Grid24 xs={24} sm={4}>
            <InputField
              isForm
              fullWidth
              disabled={isAdvogado || disabled}
              label={OAB_TITLE}
              name={advogadoOab.name}
              value={advogadoOab.value}
              error={advogadoOab.error}
              errorMessage={advogadoOab.errorMessage}
              onChange={changeHandler}
              maxLength={advogadoOab.maxLength}
            />
          </Grid24>
          <Grid24 xs={24} sm={4}>
            <InputField
              isForm
              fullWidth
              disabled={isAdvogado || disabled}
              label={SECCIONAL_TITLE}
              name={advogadoSeccional.name}
              value={advogadoSeccional.value}
              error={advogadoSeccional.error}
              errorMessage={advogadoSeccional.errorMessage}
              onChange={changeHandler}
              maxLength={advogadoSeccional.maxLength}
            />
          </Grid24>
        </Grid24>
      </div>
    </FormWrapperTitle>
  );
}

export default React.memo(AdvogadoUsuario);
