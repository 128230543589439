import React from 'react';

import SelectorModel from '../../../models/SelectorModel';
import TituloCompromissoActionsTable from '../../../components/TituloCompromisso/TituloCompromissoActionsTable';

export const buildRows = (tituloCompromissos, onEdit, onRemove) => tituloCompromissos.map(tituloCompromisso => ({
  id: tituloCompromisso.idTipoTituloAgendamento,
  values: {
    nome: tituloCompromisso.descricao,
    acoes: (
      <TituloCompromissoActionsTable
        id={tituloCompromisso.idTipoTituloAgendamento}
        nome={tituloCompromisso.descricao}
        onRemove={onRemove}
        onEdit={onEdit}
        allows={{
          editar: 2201,
          excluir: 2201,
        }}
        userAllow="U"
      />
    ),
  },
}));

export const buildTituloCompromissoSelector = titulosCompromisso => titulosCompromisso.map(
  tituloCompromisso => new SelectorModel(tituloCompromisso.idTipoTituloDeCompromisso, tituloCompromisso.nomeTituloCompromisso),
);
