import { TableContainer } from '@material-ui/core';
import React from 'react';
import { MdPrint } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  ContaCorrenteHonorarioController,
  ContaCorrenteHonorarioFactory,
} from './ContaCorrenteHonorarioController';
import { TableBodyContaCorrenteHonorario } from '../../components/TabelaBody/TableBodyContaCorrenteHonorarioComponent';
import { TableHeaderContaCorrenteHonorario } from '../../components/TabelaHeader/TableHeaderContaCorrenteHonorario';
import ContaCorrenteHonorarioService from '../../services/core/ContaCorrenteHonorario/ContaCorrenteHonorarioService';
import {
  DateUtils,
  getMoneyMask,
  toastUnmappedException,
  TableUtils,
  getFieldName,
  getNewPage,
  openPageFile,
  hasUserPermissions,
} from '../../helpers';
import useStyle, {
  Container,
  InfoContainer,
  PeriodSelector,
  ButtonImprimir,
  ResumoContainer,
  CardTop,
  CardBottom,
  TableHeader,
  CheckFooter,
  HrColored,
} from './ContaCorrenteHonorarioStyles';
import { initializePageAction } from '../../store/theme.actions';
import {
  ButtonUI,
  Loading,
  TableDefault,
} from '../../components';
import SwitchUI from '../../components/UI/Switch/SwitchUI';
import OptionDialogNew from '../../components/UI/Dialogs/OptionDialog/OptionDialogNew';

function makeFields(fields) {
  return {
    exibirLancamentosNaoConsolidados:
      fields?.exibirLancamentosNaoConsolidados || true,
    exibirLancamentosConsolidados:
      fields?.exibirLancamentosConsolidados || false,
    exibirLancamentosDeConsolidacao:
      fields?.exibirLancamentosDeConsolidacao || false,
    period: {
      interval: fields ? fields?.period?.interval : 6,
      startDate: fields ? fields?.period?.startDate : DateUtils.makeDate(1),
      endDate: fields ? fields?.period?.endDate : DateUtils.makeDate(0),
    },
  };
}

function makeLoading() {
  return {
    consolidar: false,
    saldos: false,
    lancamentos: false,
  };
}

function makeColumnOrder(columnName, columnOrder) {
  return TableUtils.makeColumnOrder({
    element: columnName || 'dataLancamento',
    order: columnOrder?.getNextOrder() || 'ASC',
    arrow: columnOrder ? columnOrder?.getNextArrow() : true,
  });
}

function makeResumo(response) {
  return {
    hbDevedoresNoPeriodoNC: response?.hbDevedoresNoPeriodoNC === 0 ? 'R$ 0,00' : getMoneyMask(response?.hbDevedoresNoPeriodoNC),
    hbClientesNoPeriodoNC: response?.hbClientesNoPeriodoNC === 0 ? 'R$ 0,00' : getMoneyMask(response?.hbClientesNoPeriodoNC),
    debitosEstornosNoPeriodoNC: response?.debitosEstornosNoPeriodoNC === 0 ? 'R$ 0,00' : getMoneyMask(response?.debitosEstornosNoPeriodoNC),
    hbDevedoresNoPeriodo: response?.hbDevedoresNoPeriodo === 0 ? 'R$ 0,00' : getMoneyMask(response?.hbDevedoresNoPeriodo),
    hbClientesNoPeriodo: response?.hbClientesNoPeriodo === 0 ? 'R$ 0,00' : getMoneyMask(response?.hbClientesNoPeriodo),
    debitosEstornosNoPeriodo: response?.debitosEstornosNoPeriodo === 0 ? 'R$ 0,00' : getMoneyMask(response?.debitosEstornosNoPeriodo),
    totalHbNaoConsolidado: response?.totalHbNaoConsolidado === 0 ? 'R$ 0,00' : getMoneyMask(response?.totalHbNaoConsolidado),
    totalHbNaoConsolidadoWithoutMask:
      response?.totalHbNaoConsolidado || 0,
  };
}

function ContaCorrenteHonorarioComponent() {
  const dispatch = useDispatch();
  const style = useStyle();
  const [loading, setLoading] = React.useState(makeLoading());
  const [saldos, setSaldos] = React.useState(makeResumo());
  const [fields, setFields] = React.useState(makeFields());
  const [columnOrder, setColumnOrder] = React.useState(makeColumnOrder());
  const [lancamentos, setLancamentos] = React.useState(
    ContaCorrenteHonorarioFactory.defaultMakeLancamentos(),
  );

  const [openDialogConsolidarHonorario, setOpenDialogConsolidarHonorario] = React.useState(false);
  const [shouldShowToast, setShouldShowToast] = React.useState(false);

  function handleOpenDialogConsolidarHonorario() {
    setOpenDialogConsolidarHonorario(true);
  }

  const permission = React.useMemo(() => {
    const isRead = hasUserPermissions(1701);
    const isManager = hasUserPermissions(1702);
    const hasAnyPermission = isRead || isManager;
    return {
      isRead,
      isManager,
      hasAnyPermission,
    };
  }, []);

  async function handleChangePeriod(interval, startDate, endDate) {
    setFields(oldFields => ({
      ...oldFields,
      period: {
        ...oldFields.period,
        startDate,
        endDate,
        interval,
      },
    }));
    setColumnOrder(makeColumnOrder());
    setShouldShowToast(true);
  }

  function handleOnClickCancel() {
    setOpenDialogConsolidarHonorario(false);
  }

  async function handleApplyOrder(columnName) {
    const newColumnOrder = makeColumnOrder(columnName, columnOrder);
    setColumnOrder(newColumnOrder);
  }

  const handleChangeChecked = (e) => {
    const name = getFieldName(e.target);
    const { checked } = e.target;
    setFields(oldFields => ({ ...oldFields, [name]: checked }));
    setShouldShowToast(true);
  };


  function handleChangePage(e, newPage) {
    setLancamentos(oldLancamentos => ({
      ...oldLancamentos,
      size: lancamentos.size,
      page: Number(newPage),
    }));
  }

  function handleRowsPerPageChange(e) {
    const size = e.target.value;
    const newPage = getNewPage(lancamentos.size, lancamentos.page, size);
    setLancamentos(oldLancamentos => ({
      ...oldLancamentos,
      size,
      page: Number(newPage),
    }));
  }

  async function handlePrintLancamentos() {
    try {
      setLoading(oldLoading => ({ ...oldLoading, download: true }));
      const httpResponse = await ContaCorrenteHonorarioController.downloadExtratoLancamentos(fields, columnOrder);
      if (httpResponse.data.size > 0) {
        openPageFile(httpResponse.data, httpResponse.data.type);
      }
    } catch (err) {
      toastUnmappedException(
        err,
        'Ocorreu um problema ao tentar abrir o relatório',
      );
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, download: false }));
    }
  }

  React.useEffect(() => {
    dispatch(initializePageAction('Conta-Corrente de Honorários'));
  }, [columnOrder, dispatch]);

  const handleConsolidarLancamentos = React.useCallback(async () => {
    setLoading(oldLoading => ({ ...oldLoading, consolidar: true }));
    try {
      await ContaCorrenteHonorarioService.consolidarLancamento();
      setFields(oldFields => ({ ...oldFields }));
    } catch (err) {
      toastUnmappedException(
        err,
        'Ocorreu um problema ao tentar consolidar lançamentos',
      );
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, consolidar: false }));
      setOpenDialogConsolidarHonorario(false);
    }
  }, []);

  const findAllLancamentosHonorarios = React.useCallback(
    async (formFields, order, page, size) => {
      setLoading(oldLoading => ({ ...oldLoading, lancamentos: true }));
      try {
        const pageable = { page, size };
        const newLancamentos = await ContaCorrenteHonorarioController.findAllLancamentosHonorarios(
          formFields,
          order,
          pageable,
        );
        setLancamentos(newLancamentos.lancamentos);

        if (shouldShowToast && newLancamentos.lancamentos.content.length === 0 && !loading.lancamentos) {
          await new Promise(resolve => setTimeout(resolve, 0));
          if (newLancamentos.lancamentos.content.length === 0 && !loading.lancamentos) {
            toast.warning('Nenhum resultado foi encontrado relacionado à sua pesquisa.', { style: { width: '392px' } });
            setShouldShowToast(false); // Reseta para não mostrar o toast novamente
          }
        }
      } catch (err) {
        toastUnmappedException(
          err,
          'Ocorreu um problema ao tentar consultas os lançamentos',
        );
        setLancamentos(
          ContaCorrenteHonorarioFactory.makeLancamentos({ size: 10 }),
        );
      } finally {
        setLoading(oldLoading => ({ ...oldLoading, lancamentos: false }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldShowToast],
  );

  React.useEffect(() => {
    findAllLancamentosHonorarios(
      fields, columnOrder, lancamentos.page, lancamentos.size,
    ).then();
  }, [
    fields,
    columnOrder,
    lancamentos.page,
    lancamentos.size,
    findAllLancamentosHonorarios,
  ]);

  const findSaldosResumoHonorario = React.useCallback(async () => {
    setLoading(oldLoading => ({ ...oldLoading, saldos: true }));
    try {
      const httpResponse = await ContaCorrenteHonorarioService.findSaldos(
        fields.period,
        fields.exibirLancamentosNaoConsolidados,
        fields.exibirLancamentosConsolidados,
        fields.exibirLancamentosDeConsolidacao,
      );
      setSaldos(makeResumo(httpResponse.data));
    } catch (err) {
      toastUnmappedException(
        err,
        'Ocorreu um problema ao tentar consultar os saldos',
      );
      setSaldos(makeResumo());
    } finally {
      setLoading(oldLoading => ({ ...oldLoading, saldos: false }));
    }
  }, [fields]);

  /** Atualizar saldo honorários */
  React.useEffect(() => {
    findSaldosResumoHonorario().then();
  }, [findSaldosResumoHonorario]);


  return (
    <>
      <OptionDialogNew
        open={openDialogConsolidarHonorario}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={handleOnClickCancel}
        onClickConfirm={handleConsolidarLancamentos}
      >
        <div style={{ fontSize: '16px' }}>
          <p>
            Confirma a consolidação dos lançamentos de honorários
            <br />
            no valor de
            {' '}
            {saldos.totalHbNaoConsolidado}
            ?
          </p>
        </div>
      </OptionDialogNew>
      <Loading show={loading.nome || loading.saldos || loading.lancamentos} />
      <Container>
        <InfoContainer>
          <div className="filters">
            <PeriodSelector
              name="period"
              range={[1, 7, 15]}
              value={fields.period}
              setMaxDate
              withPeriod
              onChange={handleChangePeriod}
            />
            <ButtonImprimir
              disabled={lancamentos.content.length === 0}
              onClick={handlePrintLancamentos}
            >
              <MdPrint size={16} />
              <span>Imprimir</span>
            </ButtonImprimir>
          </div>
          <div className="additional-info">
            <ResumoContainer>
              <p className="title">Resumo no Período</p>
              <div>
                <CardTop color="#fc4482">
                  <div>
                    <p>HB de Devedores</p>
                    <HrColored color="#ba003f" />
                    <p>
                      Não Consolidados
                      {' '}
                      <span>{saldos.hbDevedoresNoPeriodoNC}</span>
                    </p>
                    <HrColored color="#ba003f" />
                    <p>
                      Consolidados
                      {' '}
                      <span>{saldos.hbDevedoresNoPeriodo}</span>
                    </p>

                  </div>

                </CardTop>
                <CardTop color="#c156d2">
                  <div>
                    <p>HB de Clientes</p>
                    <HrColored color="#9b00b4" />
                    <p>
                      Não Consolidados
                      {' '}
                      <span>{saldos.hbClientesNoPeriodoNC}</span>
                    </p>
                    <HrColored color="#9b00b4" />
                    <p>
                      Consolidados
                      {' '}
                      <span>{saldos.hbClientesNoPeriodo}</span>
                    </p>

                  </div>
                </CardTop>


                <CardTop color="#f49096">
                  <div>
                    <p>Débitos/Estornos</p>
                    <HrColored color="#fc424d" />
                    <p>
                      Não Consolidados
                      {' '}
                      <span>{saldos.debitosEstornosNoPeriodoNC}</span>
                    </p>
                    <HrColored color="#fc424d" />
                    <p>
                      Consolidados
                      {' '}
                      <span>{saldos.debitosEstornosNoPeriodo}</span>
                    </p>

                  </div>

                </CardTop>
              </div>
              <div className={style.div__consolidacao}>
                <p>Previsão para a próxima Consolidação</p>
              </div>
              <div className={style.div__cards}>

                <CardBottom color="#ff8ac1">

                  <p>Total de HB</p>
                  <p>{saldos.totalHbNaoConsolidado}</p>
                </CardBottom>
              </div>
            </ResumoContainer>
            <div className={style.div__align}>
              <div className={style.div__buttons}>
                <ButtonUI
                  color="white"
                  onClick={handleOpenDialogConsolidarHonorario}
                  disabled={!permission.isManager || saldos.totalHbNaoConsolidadoWithoutMask <= 0}
                >
                  Consolidar HB
                </ButtonUI>
              </div>
            </div>
          </div>
        </InfoContainer>
        <TableContainer>
          <TableHeader>Lançamentos</TableHeader>
          <div>
            <TableDefault
              columnsHead={(
                <TableHeaderContaCorrenteHonorario
                  columnOrder={columnOrder}
                  onChangeOrder={columnName => handleApplyOrder(columnName)}
                />
              )}
              page={lancamentos.page}
              rowsPerPage={lancamentos.size}
              totalElements={lancamentos.totalElements}
              totalElementsOnPage={lancamentos.content?.length}
              totalColumns={5}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleRowsPerPageChange}
              emptyRowsHeight={26}
              rowsPerPageOptions={[10, 15, 20]}
            >
              {lancamentos.content?.map(lancamento => (
                <TableBodyContaCorrenteHonorario
                  key={lancamento.idContaCorrenteHonorario}
                  lancamento={lancamento}
                />
              ))}
            </TableDefault>
          </div>
          <CheckFooter>
            <div className={style.div__checkFooter}>
              <SwitchUI
                label="Exibir lançamentos não Consolidados"
                name="exibirLancamentosNaoConsolidados"
                checked={fields.exibirLancamentosNaoConsolidados}
                onChange={handleChangeChecked}
              />
            </div>
            <div className={style.div__checkFooter}>
              <SwitchUI
                label="Exibir lançamentos Consolidados"
                name="exibirLancamentosConsolidados"
                checked={fields.exibirLancamentosConsolidados}
                onChange={handleChangeChecked}
              />
            </div>
            <div className={style.div__checkFooter}>
              <SwitchUI
                label="Exibir Lançamentos de Consolidação"
                name="exibirLancamentosDeConsolidacao"
                checked={fields.exibirLancamentosDeConsolidacao}
                onChange={handleChangeChecked}
              />
            </div>
          </CheckFooter>
        </TableContainer>
      </Container>
    </>
  );
}

export const ContaCorrenteHonorarioPage = ContaCorrenteHonorarioComponent;
