import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import useStyles from './FormErrorMessage.styles';

function FormErrorMessage({ message, show }) {
  const classes = useStyles();
  let errorClasses = `${classes.message}`;
  if (!show) {
    errorClasses = `${classes.message} ${classes.hide}`;
  }
  return (
    <Typography component="p" classes={{ root: errorClasses }}>
      {message}
    </Typography>
  );
}

FormErrorMessage.defaultProps = {
  show: true,
};

FormErrorMessage.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string.isRequired,
};

export default FormErrorMessage;
