import FieldModel from '../../../models/FieldModel';
import {
  ON_CHANGE_REQUEST_FIELDS,
  ON_FOCUS_REQUEST_FIELDS,
  ON_UPDATE_REQUEST_FIELDS,
  RESET_SCREEN,
  SET_SNACKBAR_RELATORIO,
  SET_STORE_VALUE,
} from './relatoriosAS.constans';
import { buildSnackbar } from '../../../helpers/factory/global.factory';

function buildRequestFields() {
  return {
    dataInicialAcesso: new FieldModel({
      name: 'dataInicialAcesso',
    }),
    dataFinalAcesso: new FieldModel({
      name: 'dataFinalAcesso',
    }),
    dataInicialNotificado: new FieldModel({
      name: 'dataInicialNotificado',
    }),
    dataFinalNotificado: new FieldModel({
      name: 'dataFinalNotificado',
    }),
  };
}

function initiateStates() {
  return {
    requestFields: buildRequestFields(),
    loading: false,
    snackbarRelatorioAS: buildSnackbar(),
  };
}

export const setStoreValuesAction = (name, value) => ({
  type: SET_STORE_VALUE,
  name,
  value,
});

export const onChangeRequestFieldsAction = (name, value) => ({
  type: ON_CHANGE_REQUEST_FIELDS,
  name,
  value,
});

export const onFocusRequestFieldsAction = name => ({
  type: ON_FOCUS_REQUEST_FIELDS,
  name,
});

export const onUpdateRequestFieldsAction = requestFields => ({
  type: ON_UPDATE_REQUEST_FIELDS,
  requestFields,
});

export const setSnackbarRelatorioASAction = (open, variant, message) => ({
  type: SET_SNACKBAR_RELATORIO,
  open,
  variant,
  message,
});

export const resetScreenAction = () => ({
  type: RESET_SCREEN,
});

function resetScreenHandler() {
  return initiateStates();
}

function setSnackbarValueHandler(states, actions) {
  const snackbar = { ...states.snackbar, open: actions.open };
  if (actions.variant || actions.message) {
    snackbar.variant = actions.variant;
    snackbar.message = actions.message;
  }
  return {
    ...states,
    snackbarRelatorioAS: snackbar,
  };
}

function onChangeRequestFieldsHandler(states, actions) {
  const { name, value } = actions;
  const model = states.requestFields[name].getNewModel(value);

  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [name]: model,
    },
  };
}


function onFocusRequestFieldsHandler(states, actions) {
  const { name } = actions;
  const model = states.requestFields[name].onFocus();

  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      [name]: model,
    },
  };
}

function setStoreValueHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    [name]: value,
  };
}

function onUpdateRequestFieldsHandler(states, actions) {
  const { requestFields } = actions;
  return {
    ...states,
    requestFields,
  };
}

export default (states = initiateStates(), actions) => {
  switch (actions.type) {
    case ON_CHANGE_REQUEST_FIELDS:
      return onChangeRequestFieldsHandler(states, actions);
    case ON_FOCUS_REQUEST_FIELDS:
      return onFocusRequestFieldsHandler(states, actions);
    case SET_STORE_VALUE:
      return setStoreValueHandler(states, actions);
    case ON_UPDATE_REQUEST_FIELDS:
      return onUpdateRequestFieldsHandler(states, actions);
    case SET_SNACKBAR_RELATORIO:
      return setSnackbarValueHandler(states, actions);
    case RESET_SCREEN:
      return resetScreenHandler(states, actions);
    default:
      return states;
  }
};
