import { IconButton, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';

export default makeStyles({

  table__NotasFiscais: {
    paddingTop: '22px',
    paddingLeft: '8px',
    paddingRight: '8px',
  },

  semNotas: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '22px',
    '& div + div': {
      fontWeight: 'bold',
    },
  },

  semNotasLabel: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '17px',
    fontWeight: 'bold',
    marginBottom: '23px',
  },
  label: {
    fontSize: '0.75rem',
    color: '#707070',
    width: '150px',
    marginRight: '10px',
  },

  inputField: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
  },
});
export const TableWithAccordion = styled(Paper)`
  background-color: #f1f4f9;
  border-radius: 7px;
  margin-top: 22px;
  max-height: ${props => (props.isScrollable ? props.maxTableHeigth : 'auto')};
  overflow-x: auto;

  & td {
    font-size: .75rem;
    padding: 0px 8px !important;
  }

  & th {
    cursor: default;
    font-size: .75rem;
    color: #034ddc;
    font-weight: bold;
    padding: 0px 8px !important;
    height: 33px;
    text-align: center;
    line-height: 1rem;
  }

`;

export const Subtitles = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  width: 100%;
`;

export const SubtitlesValue = styled.div`
  width: ${props => (props.width)};
  text-align: ${props => (props.textAlign || 'center')};
`;

export const ListSeparator = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e0e0e0;
  padding: 6px;
  width: 100%;
`;

export const ButtonNovaNota = styled(IconButton)`
    width:35px;
    height:35px;

    background-color: ${({ active }) => (active === 'true' ? 'transparent' : '#423362')};
    color: ${({ active }) => (active === 'true' ? '#423362' : '#ffff')};
    ${({ active }) => (active === 'true' ? 'border: 2px solid #423362;' : '')}

:hover {
  background-color: ${({ active }) => (active === 'true' ? 'transparent' : '#423362')};
  opacity: 0.64;
}

:disabled {
  background-color: #c4c4c4;
}
 `;

export const Container = styled.section`
margin-top: 14px;
padding: 8px;
border :1px solid #A2A0A0;
> div {
  display:flex ;
    >div {
    display: flex ; 
      > img {
  margin-top:4px;
}
  }
 
}

`;

export const Header = styled.div`
display:flex ;
width:100% ;
margin-top:16px ;

>div {
  display: flex ;
  width:25% ;
  margin-right:8px ;
}
> div + div{
  width:20% ;
}
`;


export const CustomButtonLimpar = styled(Button)`
  width: 120px;
  height: 35px;

  background-color: white;
  color: #034DDC;
  border-radius: 16px;
  border:1px solid #034DDC;
  font-weight: bold;
  text-transform: none;

  svg {
    display: block;
  }

  span span {
    padding-left: 8px;

    display: block;
    font-size: 14px;
  }

  :disabled {
    border:1px solid #C4C4C4;
  }
`;

export const Input = styled.div`
display:flex ;
width:100% ;
margin-top:10px ;

> div {
  width:35% ;
  margin-right:8px ;
}
>div + div{
width:45% ;
margin-top:10px ;
}
`;

export const ButtonEmitir = styled.div`
display:flex ;
width:100% ;
margin-top:10px ;
justify-content:flex-end ;

> button {
  width: 135px;
  height: 35px;
}

`;
