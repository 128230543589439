import React from 'react';
import { withRouter } from 'react-router-dom';
import Grid24 from '../../../../components/UI/Grid24';
import FormWrapperWithTitle from '../../../../components/UI/Wrapper/Form/FormWrapperWithTitle';
import useStyles from '../DadosDevedor.styles';
import Enderecos from '../../../../components/UI/EnderecosV2';

function EnderecoDevedor({
  immutableSelectors, podeModificarFase, isEditMode, formFields, setEnderecoAttributeValueHandler, setEnderecosHandler,
  setEnderecoAttributeFocusHandler, setEnderecoHandler,
}) {
  const styles = useStyles();


  return (
    <Grid24 container isFormContainer>
      <FormWrapperWithTitle>
        <Grid24 item spacing="1" className={styles.margin}>
          <Grid24 xs={24} sm={24} style={{ marginTop: '-15px' }}>
            <Enderecos
              readMode={!podeModificarFase || !isEditMode}
              disabled={!podeModificarFase || !isEditMode}
              estados={immutableSelectors.estados}
              enderecos={formFields.enderecos}
              setEnderecos={setEnderecosHandler}
              setEndereco={setEnderecoHandler}
              setAttribute={setEnderecoAttributeValueHandler}
              setFocus={setEnderecoAttributeFocusHandler}
              requiredFields={{
                cep: isEditMode,
                estado: isEditMode,
              }}
            />
          </Grid24>
        </Grid24>
      </FormWrapperWithTitle>
    </Grid24>
  );
}

export default withRouter(EnderecoDevedor);
