import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid24 from '../UI/Grid24';
import { InputField, SelectorField } from '..';
import { findTipoTituloAction } from '../../store/Global/LoadSelectors/loadSelectors.saga';
import AddActionTitleWrapper from '../UI/Wrapper/AddActionTitle';
import MoneyField from '../UI/Field/Money/MoneyField';
import ChooseField from '../UI/Field/Choose/ChooseField';
import RadioField from '../UI/Field/Radio/RadioField';
import DateField from '../UI/Field/Date/DateField';
import useUtilStyles from '../../helpers/styles/utils.styles';
import { DadosRetorno } from './CorrigirTitulos.styles';
import { DateUtils, getDateTimeMask, getFieldName } from '../../helpers';

function CorrigirTituloDevedorBorderoComponent({ devedor, setBordero }) {
  const constants = {
    INFO_TITULOS: 'Tipos de Título',
    TIPO: 'Tipo*',
    NUMERO: 'Número*',
    VALOR_ORIGINAL: 'Valor Original*',
    DATA_VENCIMENTO: 'Data Vencimento*',
    VALOR_PROTESTO: 'Valor do Protesto*',
    TITULO_PROTESTADO: 'Titulo Protestado:',
    TITULO_SIM: 'Sim',
    TITULO_NAO: 'Não',
    DADOS_RETORNO: 'Dados de Retorno',
  };

  const utilStyles = useUtilStyles();
  const dispatch = useDispatch();

  const tiposDomumento = useSelector(states => states.selectors.tipoTitulo.selector);

  useEffect(() => {
    dispatch(findTipoTituloAction());
  }, [dispatch]);


  const onChangeTituloHandler = useCallback((event, index) => {
    const name = getFieldName(event.target);
    let { value } = event.target;
    if (name === 'dataVencimento') {
      value = DateUtils.convertToUniversalDate(value);
    }
    const titulos = [...devedor.titulos];
    titulos[index][name].onChange(value);
    setBordero(oldBordero => ({ ...oldBordero, titulos }));
  }, [devedor?.titulos, setBordero]);

  const onFocusTituloHandler = useCallback((event, index) => {
    const name = getFieldName(event.target);
    const titulos = [...devedor.titulos];
    titulos[index][name].onFocus();
    setBordero(oldBordero => ({ ...oldBordero, titulos }));
  }, [devedor?.titulos, setBordero]);

  const buildTitulosComponent = (titulo, index) => (
    <div key={index}>
      <Grid24 container isFormContainer spacing="1">
        <Grid24 xs={24} sm={4}>
          <SelectorField
            isForm
            fullWidth
            disabled={titulo?.tipoDocumento?.disabled}
            label={constants.TIPO}
            items={tiposDomumento}
            name="tipoDocumento"
            value={titulo?.tipoDocumento?.value}
            error={titulo?.tipoDocumento?.error}
            errorMessage={titulo?.tipoDocumento?.errorMessage}
            onChange={e => onChangeTituloHandler(e, index)}
            onFocus={e => onFocusTituloHandler(e, index)}
          />
        </Grid24>
        <Grid24 xs={24} sm={5}>
          <InputField
            isForm
            fullWidth
            disabled={titulo?.numero?.disabled}
            label={constants.NUMERO}
            name="numero"
            value={titulo?.numero?.value}
            error={titulo?.numero?.error}
            errorMessage={titulo?.numero?.errorMessage}
            onChange={e => onChangeTituloHandler(e, index)}
            onFocus={e => onFocusTituloHandler(e, index)}
          />
        </Grid24>
        <Grid24 xs={24} sm={2} item>
          <MoneyField
            isForm
            fullWidth
            disabled={titulo?.valorOriginal?.disabled}
            label={constants.VALOR_ORIGINAL}
            name="valorOriginal"
            value={titulo?.valorOriginal?.value}
            error={titulo?.valorOriginal?.error}
            errorMessage={titulo?.valorOriginal?.errorMessage}
            maxLength={5}
            onChange={e => onChangeTituloHandler(e, index)}
            onFocus={e => onFocusTituloHandler(e, index)}
          />
        </Grid24>
        <Grid24 xs={24} sm={3} item>
          <DateField
            isForm
            fullWidth
            disabled={titulo?.dataVencimento?.disabled}
            label={constants.DATA_VENCIMENTO}
            name="dataVencimento"
            value={getDateTimeMask(titulo?.dataVencimento?.value, false, false, false)}
            error={titulo?.dataVencimento?.error}
            errorMessage={titulo?.dataVencimento?.errorMessage}
            onChange={e => onChangeTituloHandler(e, index)}
            onFocus={e => onFocusTituloHandler(e, index)}
          />
        </Grid24>
        <Grid24 xs={24} sm={3} item>
          <MoneyField
            isForm
            fullWidth
            disabled={titulo?.valorProtesto?.disabled}
            label={constants.VALOR_PROTESTO}
            name="valorProtesto"
            value={titulo?.valorProtesto?.value}
            error={titulo?.valorProtesto?.error}
            errorMessage={titulo?.valorProtesto?.errorMessage}
            maxLength={5}
            onChange={e => onChangeTituloHandler(e, index)}
            onFocus={e => onFocusTituloHandler(e, index)}
          />
        </Grid24>
        <Grid24 xs={24} sm={4} item alignItems="center" style={{ maxWidth: '154px' }}>
          <ChooseField
            label={constants.TITULO_PROTESTADO}
            name="possuirProtesto"
            value={titulo?.possuirProtesto?.value}
            onChange={e => onChangeTituloHandler(e, index)}
            onFocus={e => onFocusTituloHandler(e, index)}
          >
            <RadioField disabled={titulo?.possuirProtesto?.disabled} value="S" label={constants.TITULO_SIM} />
            <RadioField disabled={titulo?.possuirProtesto?.disabled} value="N" label={constants.TITULO_NAO} />
          </ChooseField>
        </Grid24>
        <Grid24>
          <DadosRetorno
            isForm
            fullWidth
            disabled={titulo?.dadosRetorno?.disabled}
            label={constants.DADOS_RETORNO}
            name="dadosRetorno"
            value={titulo?.dadosRetorno?.value}
            error={titulo?.dadosRetorno?.error}
            errorMessage={titulo?.dadosRetorno?.errorMessage}
            onChange={e => onChangeTituloHandler(e, index)}
            onFocus={e => onFocusTituloHandler(e, index)}
          />
        </Grid24>
      </Grid24>
      {index !== devedor?.titulos?.length - 1 && <hr className={utilStyles.dividerTitulo} />}
    </div>
  );

  return (
    <AddActionTitleWrapper
      title={constants.INFO_TITULOS}
      className={utilStyles.mt2}
      hideAddButton
    >
      {devedor?.titulos?.map(buildTitulosComponent)}
    </AddActionTitleWrapper>
  );
}

export const CorrigirTituloDevedorBordero = CorrigirTituloDevedorBorderoComponent;
