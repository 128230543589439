import styled from 'styled-components';
import { PaperUI } from '../../components';

export const RootContainer = styled(PaperUI)`
  border-radius: 7px 7px 0px 0px;
`;

export const FieldsContainer = styled.div`
  padding: 22px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const FieldsFirstDiv = styled.div`
margin-top: 7px;
display: flex;
`;

export const SmallFields = styled.div`
> div{
   width: 163px;
  margin-right: 8px;
  }
`;

export const BigFields = styled.div`
> div {
  width: 207px;
  margin-right:8px ;
  }
`;

export const FieldHistorico = styled.div`
> div {
  width: 843px;
  margin-top: 8px;
  margin-bottom:17px ;
  }
`;
