export default class TableHeader {
  constructor(args) {
    function getId() {
      if (!args.id) {
        return args.label.replace(' ', '');
      }
      return args.id;
    }

    function getMinWidth() {
      if (args.maxWidth && !args.minWidth) {
        return args.maxWidth;
      }
      return args.minWidth || 0;
    }

    this.id = getId();
    this.label = args.label;
    this.iconLabel = args.iconLabel;
    this.isIconLabel = args.isIconLabel || false;
    this.hintLabel = args.hintLabel || '';
    this.showTip = args.showTip || false;
    this.minWidth = getMinWidth();
    this.maxWidth = args.maxWidth || 'auto';
    this.width = args.width || 'auto';
    this.align = args.align || 'center';
    this.alignCell = args.alignCell || 'inherit';
    this.format = args.format;
    this.hiddenFullLine = args.hiddenFullLine || false;
    this.mostrarMais = args.mostrarMais || false;
    this.isAction = args.isAction || false;
    this.checkbox = args.checkbox || false;
    this.radioButton = args.radioButton || false;
    this.icone = args.icone || false;
    this.color = args.color || 'black';
  }
}
