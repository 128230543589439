import FieldModel, { POSITIVE_INTEGER } from '../../models/FieldModel';
import { getAttributeExistingObject } from '../utils/utils';

export function buildBoletoCliente(boletoCliente) {
  return ({

    idContaBancaria: new FieldModel({
      value: getAttributeExistingObject(boletoCliente, 'idContaBancaria'),
    }),
    idBanco: new FieldModel({
      value: getAttributeExistingObject(boletoCliente, 'idBanco'),
    }),
    agencia: new FieldModel({
      name: 'agencia',
      value: getAttributeExistingObject(boletoCliente, 'agencia'),
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    numero: new FieldModel({
      name: 'numero',
      value: getAttributeExistingObject(boletoCliente, 'numero'),
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    dac: new FieldModel({
      name: 'dac',
      value: getAttributeExistingObject(boletoCliente, 'dac'),
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    titular: new FieldModel({
      name: 'titular',
      value: getAttributeExistingObject(boletoCliente, 'titular'),
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    cnpjCpf: new FieldModel({
      name: 'cnpjCpf',
      value: getAttributeExistingObject(boletoCliente, 'cnpjCpf'),
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    chavePix: new FieldModel({
      name: 'chavePix',
      value: getAttributeExistingObject(boletoCliente, 'chavePix'),
      errorMessage: 'Campo obrigatório',
      required: true,
    }),
    idTipoContaBancaria: new FieldModel({
      name: 'idTipoContaBancaria',
      value: getAttributeExistingObject(boletoCliente, 'idTipoContaBancaria'),
    }),
    idTipoChavePix: new FieldModel({
      name: 'idTipoChavePix',
      value: getAttributeExistingObject(boletoCliente, 'idTipoChavePix'),
    }),
    carteira: new FieldModel({
      name: 'carteira',
      value: getAttributeExistingObject(boletoCliente, 'carteira'),
      type: POSITIVE_INTEGER,
    }),
  });
}
