import { CORE, gateway, gatewayShortJson } from '../../services/api';

export default {

  findAllTiposLancamentos(exibirLancamento = true) {
    return gatewayShortJson.get(`${CORE}/tipo-conta-corrente-usuario/tipos?exibirLancamento=${exibirLancamento}`);
  },

  findCadastrarLancamento(payload) {
    return gateway.post(`${CORE}/conta-corrente-usuario`, payload);
  },

  findEditarLancamento(payload) {
    return gateway.put(`${CORE}/conta-corrente-usuario`, payload);
  },

  buscarLancamento(idContaCorrenteUsuario) {
    return gatewayShortJson.get(`${CORE}/conta-corrente-usuario/${idContaCorrenteUsuario}`);
  },
};
