import { put, select, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  onChangePageAction,
  onChangeSizeAction,
  onSetActions,
  onTableContentAction, setOnlyLoadingAction,
  shouldUpdateContentTituloCompromissoAction,
} from './pesquisaTituloCompromisso.store';
import { getExceptionMessage } from '../../../helpers/utils/utils';
import {
  excluirTituloCompromisso, buscarTodosTituloCompromisso, buscarTituloCompromisso,
} from '../../../services/core/tituloCompromisso/TituloCompromisso.services';

const BUSCAR_TODOS_TITULO_COMPROMISSO = 'BUSCAR_TODOS_TITULO_COMPROMISSO';
const EXCLUIR_TITULO_COMPROMISSO = 'EXCLUIR_TITULO_COMPROMISSO';
const BUSCAR_TITULO_COMPROMISSO = 'BUSCA_TITULO_COMPROMISSO';

export const buscarTodosTituloCompromissoAction = (page = 0, rowsPerPage = 10) => ({
  type: BUSCAR_TODOS_TITULO_COMPROMISSO,
  page,
  rowsPerPage,
});

export const buscarTituloCompromissoAction = (value, page, rowsPerPage) => ({
  type: BUSCAR_TITULO_COMPROMISSO,
  value,
  page,
  rowsPerPage,
});


export const excluirTituloCompromissoAction = idTipoTituloDeCompromisso => ({
  type: EXCLUIR_TITULO_COMPROMISSO,
  idTipoTituloDeCompromisso,
});

function* selectControlStates() {
  const openSnackBar = yield select(states => states.pesquisaTituloCompromisso.actions.openSnackBar);
  const removendo = yield select(states => states.pesquisaTituloCompromisso.actions.removendo);
  const fail = yield select(states => states.pesquisaTituloCompromisso.actions.fail);
  const loading = yield select(states => states.pesquisaTituloCompromisso.actions.loading);

  return {
    openSnackBar,
    removendo,
    fail,
    loading,
  };
}

function* buscarTodosTituloCompromissoHandler(actions) {
  const { openSnackBar, removendo, fail } = yield selectControlStates();
  const shouldUpdateContent = yield select(states => states.pesquisaTituloCompromisso.actions.shouldUpdateContent);

  yield put(onSetActions(true, openSnackBar, removendo, fail));
  try {
    const tituloCompromissoResponse = yield buscarTodosTituloCompromisso(
      actions.page,
      actions.rowsPerPage,
    );
    if (tituloCompromissoResponse.data.length === 0 && !shouldUpdateContent) {
      yield put(onSetActions(false, true, false, fail));
    }
    yield put(onTableContentAction(tituloCompromissoResponse.data.content));
    yield put(onChangeSizeAction(tituloCompromissoResponse.data.totalElements));
    yield put(onChangePageAction(actions.page));
    yield put(shouldUpdateContentTituloCompromissoAction(false));
  } catch (exception) {
    yield put(onTableContentAction([]));
    yield put(onChangeSizeAction(0));
    yield put(onChangePageAction(0));
    toast.warning(getExceptionMessage(exception), { style: { width: '392px' } });
  } finally {
    yield put(setOnlyLoadingAction(false));
  }
}

function* buscarTituloCompromissoHandler(actions) {
  const { openSnackBar, removendo, fail } = yield selectControlStates();
  const shouldUpdateContent = yield select(states => states.pesquisaTituloCompromisso.actions.shouldUpdateContent);

  yield put(onSetActions(true, openSnackBar, removendo, fail));
  try {
    const tituloCompromissoResponse = yield buscarTituloCompromisso(
      actions.value,
      actions.page,
      actions.rowsPerPage,
    );
    if (tituloCompromissoResponse.data.length === 0 && !shouldUpdateContent) {
      yield put(onSetActions(false, true, false, fail));
    }
    yield put(onTableContentAction(tituloCompromissoResponse.data.content));
    yield put(onChangeSizeAction(tituloCompromissoResponse.data.totalElements));
    yield put(onChangePageAction(actions.page));
    yield put(shouldUpdateContentTituloCompromissoAction(false));
  } catch (exception) {
    yield put(onTableContentAction([]));
    yield put(onChangeSizeAction(0));
    yield put(onChangePageAction(0));
    toast.warning(getExceptionMessage(exception), { style: { width: '392px' } });
  } finally {
    yield put(setOnlyLoadingAction(false));
  }
}

function* excluirTituloCompromissoHandler(actions) {
  const { removendo, openSnackBar, fail } = yield selectControlStates();
  try {
    yield put(onSetActions(true, openSnackBar, removendo, fail));
    yield excluirTituloCompromisso(actions.idTipoTituloDeCompromisso);
  } catch (exception) {
    toast.warning(getExceptionMessage(exception), { style: { width: '392px' } });
  } finally {
    yield put(shouldUpdateContentTituloCompromissoAction(true));
    yield put(onSetActions(false, openSnackBar, removendo, fail));
  }
}

export default function* watchPesquisaTituloCompromisso() {
  yield takeLatest(BUSCAR_TODOS_TITULO_COMPROMISSO, buscarTodosTituloCompromissoHandler);
  yield takeLatest(EXCLUIR_TITULO_COMPROMISSO, excluirTituloCompromissoHandler);
  yield takeLatest(BUSCAR_TITULO_COMPROMISSO, buscarTituloCompromissoHandler);
}
