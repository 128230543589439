import FieldModel from '../../models/FieldModel';

/* eslint-disable*/
export default class FormValidation {
  constructor(
    initialState = {}, promise, successCalback = null, errorCalback = null, params = null, order = null,
  ) {
    this.validations = [];
    this.promise = promise;
    this.successCalback = successCalback;
    this.errorCalback = errorCalback;
    this.initialState = initialState;
    this.otherErrors = [];
    this.params = params;
    this.order = order;
  }

  static getMessageFromException(exception) {
    if (exception && exception.response && exception.response.data) {
      return exception.response.data.message;
    }
    if (exception && exception.error && exception.message) {
      return exception.message;
    }
  }

  getFragmentadPath(validations) {
    return validations
      .replace('[', ';')
      .replace(']', '')
      .split(';');
  }

  organizeArrays(validations) {
    const keys = Object.keys(this.initialState);
    keys.forEach(key => {
      this.initialState;
    });
  }

  updateStates(errorValues, message, init) {
    if (errorValues.length > 1) {
      const dad = errorValues.pop();
      if (/[[0-9]+]/g.test(dad)) {
        const [arrayName, arrayIndex] = this.getFragmentadPath(dad);
        const arr = init[arrayName][arrayIndex];
        return this.updateStates(errorValues, message, arr);
      }
      const arr = init[dad];
      return this.updateStates(errorValues, message, arr);
    }

    const targetName = errorValues[0];
    if (init[targetName]) {
      init[targetName].errorMessage = message;
      init[targetName].error = true;
    } else {
      this.otherErrors.push({ field: errorValues, message: message });
    }
  }

  validate() {
    this.validations.map(init => {
      const firstFragmentedPath = init.field.split('.').reverse();
      this.updateStates(firstFragmentedPath, init.message, this.initialState);
      return init;
    });
    return this.initialState;
  }

  cleanErrorInfo(state) {
    if (Array.isArray(state)) {
      state.map(children => {
        return this.cleanErrorInfo(children);
      });
      return;
    }
    const notExistValue = !state && (state.value === null || state.value === undefined);
    if (notExistValue) {
      const keys = Object.keys(state);
      keys.forEach(key => {
        return this.cleanErrorInfo(state[key]);
      });
    } else {
      if (state instanceof FieldModel) {
        state.error = false;
      }
    }
  }

  clearError() {
    const keys = Object.keys(this.initialState);
    keys.forEach(key => {
      this.cleanErrorInfo(this.initialState[key]);
    });
  }

  async catchSubmit() {
    try {
      const response = await this.promise;
      if (this.successCalback) {
        this.successCalback(response);
      }
    } catch (exception) {
      this.otherErrors = [];
      this.clearError();
      const exceptionData = exception.response;
      if (exceptionData) {
        if (exceptionData.data.validations) {
          this.validations = exceptionData.data.validations;
          this.validate();
        }
      }
      if (this.errorCalback) {
        this.errorCalback(this.initialState, exception, this.otherErrors);
      }
    }
  }

  static getErrorMessages(errors = []) {
    const messages = [];
    if (!Array.isArray(errors) || errors.length === 0) {
      return [];
    }

    messages.push(errors[0].message);
    errors.forEach(error => {
      if (!messages.includes(error.message)) {
        messages.push(error.message);
      }
    });
    return messages;
  }

  * requestSubmit() {
    try {
      const response = yield this.promise(this.params, this.order);
      if (this.successCalback) {
        yield this.successCalback(response);
      }
    } catch (exception) {
      this.otherErrors = [];
      yield this.clearError();
      const exceptionData = exception.response;
      if (exceptionData) {
        if (exceptionData.data.validations) {
          this.validations = exceptionData.data.validations;
          yield this.validate();
        }
      }
      if (this.errorCalback) {
        yield this.errorCalback(yield this.initialState, exception, yield this.otherErrors);
      }
    }
  }
}
