import React, { createContext } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider, createSelectorHook, createDispatchHook } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import reducer from './crudCliente.store';
import watchCrudCliente from './crudCliente.saga';

export const context = createContext();
export const useCrudClienteSelector = createSelectorHook(context);
export const useCrudClienteDispatch = createDispatchHook(context);

export default function CrudClienteProvider({ children }) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(watchCrudCliente);

  return (
    <Provider context={context} store={store}>
      {children}
    </Provider>
  );
}
