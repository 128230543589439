import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { withRouter } from 'react-router-dom';
import Honorarios from '../../../../../UI/Honorarios/Honorarios';
import { useCrudParametrosSelector, useCrudParametrosDispatch } from '../../../../../../store/Parametros/Crud/CrudParametrosProvider';
import { setRequestFieldsAction, setRequestFieldsValueAction } from '../../../../../../store/Parametros/Crud/crudParametros.store';
import { findFasesAction, findTipoCalculoHonorarioAction } from '../../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import { getListIntervalCorrectAfterDeleteQuantidade, getListIntervalCorrectAfterDeleteValor, getNextValueToRange } from '../../../../../../helpers/utils/utils';
import { buildParametroHonorario } from '../../../../../../helpers/factory/parametro.factory';

function ParametroHonorarios({ location, isInsertMode = false }) {
  const dispatch = useCrudParametrosDispatch();
  const globalDispatch = useDispatch();

  const [variationValidations] = useState({ MONEY: 0.01, DIAS: 1 });
  const [maxValueValidations] = useState({ MONEY: 9999999999.99, DIAS: 999999 });

  const selectorHonorario = useSelector(
    states => states.selectors.tipoCalculoHonorarioParametro.selector,
  );

  const tiposCalculoHonorarios = useCrudParametrosSelector(
    state => [...state.requestFields.tiposCalculoHonorarios],
  );

  const tipoHonorarioSelecionado = useCrudParametrosSelector(
    state => state.requestFields.tipoHonorarioSelecionado.copy(),
  );

  const fases = useSelector(state => state.selectors.fases.selector);

  // Carregar o combo box do tipo calculo
  useEffect(() => {
    globalDispatch(findTipoCalculoHonorarioAction());
    globalDispatch(findFasesAction());
  }, [globalDispatch, location.refreshPage]);


  const onChangeTipoHonorario = useCallback((event) => {
    const { name, value } = event.target;

    dispatch(setRequestFieldsValueAction(name, value));
  }, [dispatch]);


  const addHonorarioHandler = useCallback((event) => {
    const idxTipoHonorario = +event.currentTarget.getAttribute('identifier');
    const { honorarios, type } = tiposCalculoHonorarios[idxTipoHonorario];

    const maxValue = maxValueValidations[type.value];
    const variation = variationValidations[type.value];
    const nextValue = getNextValueToRange(honorarios, 'valorFaixaFinal', maxValue, variation);
    const newHonorario = { valorFaixaInicial: nextValue, valorFaixaFinal: maxValue, porcentagemHonorario: 0 };

    tiposCalculoHonorarios[idxTipoHonorario].honorarios.push(buildParametroHonorario(newHonorario, fases));

    dispatch(setRequestFieldsAction('tiposCalculoHonorarios', tiposCalculoHonorarios));
  }, [tiposCalculoHonorarios, maxValueValidations, variationValidations, dispatch, fases]);


  const changeHonorarioHandler = useCallback((event) => {
    const { name, value } = event.target;
    const [attr, idxTipoHonorario, idxHonorario] = name.split('-');
    const { honorarios } = tiposCalculoHonorarios[idxTipoHonorario];

    const updatedModel = honorarios[idxHonorario][attr].getNewModel(value);
    tiposCalculoHonorarios[idxTipoHonorario].honorarios[idxHonorario][attr] = updatedModel;

    dispatch(setRequestFieldsAction('tiposCalculoHonorarios', tiposCalculoHonorarios));
  }, [tiposCalculoHonorarios, dispatch]);


  const onFocusHonorarioHandler = useCallback((event) => {
    const { name } = event.target;
    const [attr, idxTipoHonorario, idxHonorario] = name.split('-');
    const { honorarios } = tiposCalculoHonorarios[idxTipoHonorario];

    const updatedModel = honorarios[idxHonorario][attr].copy().onFocus();
    tiposCalculoHonorarios[idxTipoHonorario].honorarios[idxHonorario][attr] = updatedModel;

    dispatch(setRequestFieldsAction('tiposCalculoHonorarios', tiposCalculoHonorarios));
  }, [tiposCalculoHonorarios, dispatch]);

  const removeHonorarioHandler = useCallback((event) => {
    const idxHonorario = +event.currentTarget.getAttribute('idxHonorario');
    const idxTipoHonorario = +event.currentTarget.getAttribute('idxTipoHonorario');
    let listaFaixaHonorarios = [];

    tiposCalculoHonorarios[idxTipoHonorario].honorarios.splice(idxHonorario, 1);

    if (idxTipoHonorario === 0) {
      listaFaixaHonorarios = getListIntervalCorrectAfterDeleteValor(tiposCalculoHonorarios[idxTipoHonorario].honorarios, 'valorFaixaFinal', 'valorFaixaInicial', idxHonorario, 9999999999.99);
    } else {
      listaFaixaHonorarios = getListIntervalCorrectAfterDeleteQuantidade(tiposCalculoHonorarios[idxTipoHonorario].honorarios, 'valorFaixaFinal', 'valorFaixaInicial', idxHonorario, 999999);
    }

    dispatch(setRequestFieldsAction('honorarios', listaFaixaHonorarios));
  }, [tiposCalculoHonorarios, dispatch]);


  return (
    <Honorarios
      disabled={!isInsertMode}
      selectorItens={selectorHonorario}
      selector={tipoHonorarioSelecionado}
      tiposCalculoHonorarios={tiposCalculoHonorarios}
      onChangeSelector={onChangeTipoHonorario}
      onChangeHonorarioItem={changeHonorarioHandler}
      onFocusHonoratioItem={onFocusHonorarioHandler}
      onAddHandler={addHonorarioHandler}
      onRemoveHonorarioItem={removeHonorarioHandler}
    />
  );
}

export default withRouter(ParametroHonorarios);
