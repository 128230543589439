import { gateway } from '../../api';

const CORE = process.env.REACT_APP_CORE;

export function buscarTiposOrigemLancamentosService() {
  return gateway.get(`${CORE}/filiais/caixa-filial/tipos-origem`);
}

export function buscarTiposLancamentosService() {
  return gateway.get(`${CORE}/filiais/caixa-filial/lancamentos`);
}

export function novoLancamentoService(idFilial, payload) {
  return gateway.post(`${CORE}/filiais/caixa-filial/lancamento/${idFilial}/filial`, payload);
}

export function editarLancamentoService(idFilial, payload) {
  return gateway.put(`${CORE}/filiais/caixa-filial/lancamento/${idFilial}/filial`, payload);
}

export function buscarLancamentoCaixaFilial(idCaixaFilial) {
  return gateway.get(`${CORE}/filiais/caixa-filial/${idCaixaFilial}`);
}
