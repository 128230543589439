import React from 'react';
import { withRouter } from 'react-router';
import InputField from '../../../../components/UI/Field/Input';
import MaskField from '../../../../components/UI/Field/Mask';
import Grid24 from '../../../../components/UI/Grid24';
import FormWrapperWithTitle from '../../../../components/UI/Wrapper/Form/FormWrapperWithTitle';
import {
  BAIRRO, CEP, CIDADE, COMPLEMENTO, ENDERECO, ESTADO, NUMERO,
} from './EnderecoCliente.constants';

function EnderecoCliente({ cliente }) {
  return (
    <Grid24 container isFormContainer>
      <FormWrapperWithTitle title="Endereço">
        <Grid24 item container isFormContainer spacing="1">
          <Grid24 item xs={24} sm={2}>
            <MaskField
              isForm
              fullWidth
              disabled
              label={CEP}
              value={cliente?.endereco?.cep}
            />
          </Grid24>
          <Grid24 item xs={24} sm={9}>
            <InputField
              isForm
              fullWidth
              disabled
              label={ENDERECO}
              value={cliente?.endereco?.endereco}
            />
          </Grid24>
          <Grid24 item xs={24} sm={2}>
            <InputField
              isForm
              fullWidth
              disabled
              label={NUMERO}
              value={cliente?.endereco?.numero}
            />
          </Grid24>
          <Grid24 item xs={24} sm={4}>
            <InputField
              isForm
              fullWidth
              disabled
              label={COMPLEMENTO}
              value={cliente?.endereco?.complemento}
            />
          </Grid24>
        </Grid24>

        <Grid24 container isFormContainer spacing="1">
          <Grid24 item xs={24} sm={6}>
            <InputField
              isForm
              fullWidth
              disabled
              label={BAIRRO}
              value={cliente?.endereco?.bairro}
            />
          </Grid24>
          <Grid24 item xs={24} sm={6}>
            <InputField
              isForm
              fullWidth
              disabled
              label={CIDADE}
              value={cliente?.endereco?.cidade}
            />
          </Grid24>
          <Grid24 item xs={24} sm={2}>
            <InputField
              isForm
              fullWidth
              disabled
              label={ESTADO}
              value={cliente?.endereco?.estado}

            />
          </Grid24>
        </Grid24>
      </FormWrapperWithTitle>
    </Grid24>
  );
}

export default withRouter(EnderecoCliente);
