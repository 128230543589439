import React from 'react';
import { RadioGroup, InputLabel } from '@material-ui/core';

import useStyles from './ChooseField.styles';

function ChooseField({
  children, label, labelProps, ...props
}) {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      {label && <InputLabel {...labelProps}>{label}</InputLabel>}
      <RadioGroup className={styles.group} {...props}>{children}</RadioGroup>
    </div>
  );
}

export default React.memo(ChooseField);
