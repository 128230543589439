import {
  CORE, gateway, gatewayImage, gatewayShortJson,
} from '../../api';
import { getUriParams } from '../../../helpers/utils/utils';

export const getPerfiesCliente = () => gateway.get(`${CORE}/clientes/perfis`);

export const getCliente = idCliente => gateway.get(`${CORE}/clientes/${idCliente}`);

export function findSimpleCliente(idCliente, statusCliente) {
  const uri = getUriParams({ statusCliente });
  return gatewayShortJson.get(`${CORE}/clientes/${idCliente}?${uri}`);
}

export const getClientesGrupo = (idClientePrincipal, idCliente, serCliente) => {
  const uri = getUriParams({ idClientePrincipal, idCliente, serCliente });
  return gateway.get(`${CORE}/clientes/grupo/?${uri}`);
};

export const getTipoClassificacaoClientes = () => gateway.get(`${CORE}/clientes/tipoClassificacaoClientes`);


export function insertCliente(payload) {
  return gateway.post(`${CORE}/clientes`, payload);
}


export const updateCliente = (payload, idCliente) => gateway.put(`${CORE}/clientes/${idCliente}`, payload);

export const buscarClientes = (
  cidade,
  documento,
  idCliente,
  idEstado,
  nome,
  idFilial,
  dataFuInicial,
  dataFuFinal,
  page,
  size,
) => {
  const uri = getUriParams({
    cidade,
    documento,
    idCliente,
    idEstado,
    nome,
    idFilial,
    dataFuInicial,
    dataFuFinal,
    page,
    size,
  });
  return gateway.get(`${CORE}/clientes?${uri}`);
};

export const removerCliente = idCliente => gateway.delete(`${CORE}/clientes/${idCliente}`);

export function obterAcompanhamentoPorIdAcompanhamento(idCliente, idClienteAcompanhamento) {
  return gateway.get(`${CORE}/clientes/${idCliente}/acompanhamentos/${idClienteAcompanhamento}`);
}

export function getAcompanhamentoCliente(idCliente, conteudo, dataEnvioInicial, page, sort = 'data,ASC', size = 25) {
  const uri = getUriParams({
    conteudo, dataEnvioInicial, page, size, sort,
  });
  return gateway.get(`${CORE}/clientes/${idCliente}/acompanhamentos?${uri}`);
}

export function insertAcompanhamentoCliente(idCliente, payload) {
  return gateway.post(`${CORE}/clientes/${idCliente}/acompanhamentos`, payload);
}

export function removeFollowUpCliente(idCliente, idClienteAcompanhamento) {
  return gateway.delete(`${CORE}/clientes/${idCliente}/acompanhamentos/${idClienteAcompanhamento}`);
}

export function obterLogotipoCliente(idCliente, format = 'BASE_64') {
  return gatewayImage.get(`${CORE}/clientes/${idCliente}/foto`, { params: { format } });
}

export function saveLogotipoCliente(idCliente, image, exibirLogoProprioAS) {
  const data = new FormData();
  data.append('logoAcordoSeguro', image);
  data.append('exibirLogoProprioAS', exibirLogoProprioAS);
  return gateway.post(`${CORE}/clientes/${idCliente}/foto`, data);
}

export function buscarDocumentosClientePorIdClienteService(idCliente, pageable = { page: 0, size: 5 }) {
  const queryParams = getUriParams(pageable);
  return gateway.get(`${CORE}/clientes/${idCliente}/documentos?${queryParams}`);
}

export function inserirDocumentoClienteService(multipartPayload) {
  return gateway.post(`${CORE}/clientes/documento`, multipartPayload, {
    'Content-Type': 'multipart/form-data',
  });
}

export function atualizarDocumentoClienteService(multipartPayload) {
  return gateway.post(`${CORE}/clientes/editar-documento`, multipartPayload, {
    'Content-Type': 'multipart/form-data',
  });
}

export function buscarDocumentosClientePorIdDocumentoService(idClienteDocumento) {
  return gateway.get(`${CORE}/clientes/${idClienteDocumento}/documento-edicao`);
}

export function deleteDocumentoClienteService(idClienteDocumento) {
  return gateway.delete(`${CORE}/clientes/${idClienteDocumento}/documento`);
}

export function buscarArquivoDocumentoClientePorIdDocumentoService(idClienteDocumento) {
  return gateway.get(`${CORE}/clientes/documento-download/${idClienteDocumento}`, {
    responseType: 'blob',
  });
}

export function findAllTiposPrestacao() {
  return gatewayShortJson.get(`${CORE}/clientes/tipos-prestacao`);
}

export function findAllTiposLiquidacaoPrestacao() {
  return gatewayShortJson.get(`${CORE}/clientes/tipos-liquidacao-prestacao`);
}

export function findAllTiposChavePix() {
  return gatewayShortJson.get(`${CORE}/clientes/tipos-chave-pix`);
}

export function getDiasElegiveisService(idCliente) {
  const params = getUriParams({ idCliente });
  return gatewayShortJson.get(`${CORE}/clientes/dias?${params}`);
}

export function findAllTiposNotasFiscais() {
  return gatewayShortJson.get(`${CORE}/clientes/tipos-nota-fiscal`);
}

export function findAllPrefeiturasNotaFiscal() {
  return gatewayShortJson.get(`${CORE}/clientes/prefeituras-nota-fiscal`);
}

export function getClienteEmpresaNFService(cnpjCpf, idCliente, categoria) {
  const params = getUriParams({ idCliente, cnpjCpf, categoria });
  return gatewayShortJson.get(`${CORE}/clientes/empresa-nota-fiscal?${params}`);
}

export function getFaixasComissao() {
  return gateway.get(`${CORE}/tipos-calculo/faixas-comissao`);
}

export function getIntervaloPagamento() {
  return gateway.get(`${CORE}/tipo-intervalo-pagamento`);
}

export function getModeloNotificacaoEmail() {
  return gateway.get(`${CORE}/clientes/modelo-notificacao-email`);
}

export function gerarChaveAutenticacaoCliente(idCliente) {
  return gateway.post(`${CORE}/clientes/gerar-auth-key/${idCliente}`);
}

export function enviarEmailChaveAutenticacaoCliente(idCliente) {
  return gateway.post(`${CORE}/clientes/auth-key/enviar-email/${idCliente}`);
}

export function deletarChaveAutenticacaoCliente(idCliente) {
  return gateway.put(`${CORE}/clientes/auth-key-deletar/${idCliente}`);
}

export function getTiposHonorariosContratuais() {
  return gateway.get(`${CORE}/clientes/tipo-honorario-contratual`);
}
