import { textOverflow, toBrazilianDate } from '../../../helpers/utils/utils';
import {
  buscarDocumentosProcessoPorIdProcessoService,
  buscarTodosIdsProcessoDocumentoPorIdProcessoService,
} from '../../../services/core/processos/processoDocumentoService';

const TOTAL_CHARS_1 = 30;
const TOTAL_CHARS_2 = 48;

class DocumentosProcessoController {
  makeDocumentos(res) {
    return {
      content: res?.content?.map(this.makeContentResponseDocumentos) || [],
      size: res?.size || 5,
      page: res?.number || 0,
      totalElements: res?.totalElements || 1,
      lastPage: res?.last || true,
    };
  }

  makeContentResponseDocumentos(documento) {
    let nomeDocumento = '';
    if (documento?.nomeDocumento) {
      nomeDocumento = `${documento.nomeDocumento}.${documento.extensao}`;
    }
    return {
      idProcessoDocumento: documento.idProcessoDocumento || -1,
      checked: false,
      tipo: textOverflow(documento?.tipo || '', TOTAL_CHARS_2),
      tipoAlt: documento?.tipo || '',
      descricao: textOverflow(documento?.descricao || '', TOTAL_CHARS_1),
      descricaoAlt: documento?.descricao || '',
      dataArquivamento: toBrazilianDate(documento?.dataArquivamento) || '',
      nomeUsuario: textOverflow(documento?.nomeUsuario || '', TOTAL_CHARS_1),
      nomeUsuarioAlt: documento?.nomeUsuario || '',
      serDocumentoOriginal: documento?.serDocumentoOriginal || false,
      nomeDocumento: textOverflow(nomeDocumento, TOTAL_CHARS_2),
      nomeDocumentoAlt: nomeDocumento,
      extensao: documento?.extensao || '',
      ocultarDocumentoCliente: documento?.ocultarDocumentoCliente || false,
    };
  }

  makeAllIdsDocumentos(idsDocumentos) {
    return idsDocumentos.reduce((acc, id) => {
      acc.set(id, false);
      return acc;
    }, new Map());
  }

  initializeAllIdsDocumentos(idsDocumentos = new Map()) {
    const newIds = new Map();
    Array.from(idsDocumentos.keys()).forEach((key) => {
      newIds.set(key, false);
    });
    return newIds;
  }

  async findInitialDatasOnScreen(idProcesso) {
    const [documentos, idsDocumentos] = await Promise.all([
      buscarDocumentosProcessoPorIdProcessoService(idProcesso),
      buscarTodosIdsProcessoDocumentoPorIdProcessoService(idProcesso),
    ]);

    return {
      documentos: this.makeDocumentos(documentos.data),
      idsDocumentos: this.makeAllIdsDocumentos(idsDocumentos.data),
    };
  }
}

export const documentosProcessoController = new DocumentosProcessoController();
