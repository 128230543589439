import {
  call, put, takeLatest, all,
} from 'redux-saga/effects';
import { GET_STATUS_HABILITAR_FUNCOES_PROJETO } from './commonGlobal.constants';
import { setSimpleGlobalCommonStateAction } from './commonGlobal.store';
import { buscarStatusAcordoSeguroHabilitar } from '../../../services/core/globalCommon/globalCommon.service';


export const getStatusHabilitarFuncoesProjetoAction = () => ({
  type: GET_STATUS_HABILITAR_FUNCOES_PROJETO,
});

function* getStatusHabilitarFuncoesProjetoHandler() {
  try {
    const [responseAcordoSeguroHabilitar] = yield all([
      call(buscarStatusAcordoSeguroHabilitar),
    ]);


    yield put(setSimpleGlobalCommonStateAction('habilitarAcordoSeguro', responseAcordoSeguroHabilitar?.data));
  } catch (exception) {
    // Nenhum tratamento definido...
  }
}

export default function* watchCommonGlobal() {
  yield takeLatest(GET_STATUS_HABILITAR_FUNCOES_PROJETO, getStatusHabilitarFuncoesProjetoHandler);
}
