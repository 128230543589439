import React from 'react';
import {
  DialogContent, Typography,
} from '@material-ui/core';
import { Button } from '@mui/material';
import {
  LABEL_CODIGO_CLIENTE,
  LABEL_DATA_CADASTRAMENTO,
  LABEL_DEVEDOR, LABEL_MENSAGEM,
  LABEL_NUMERO_PROCESSO,
  LABEL_QTDE_TITULOS,
  LABEL_RESPONSAVEL_PROCESSAMENTO,
  LABEL_TIPO, LABEL_TIPO_ENTRADA,
  LABEL_VALOR_TOTAL_PROCESSO,
  TITLE_INFORMACAOES,
  VOLTAR_BUTTON_LABEL,
} from './logProcessamentoBorderoDetalhesModal.constants';
import Grid24 from '../../UI/Grid24/Grid24';
import SubTitleForm from '../../UI/SubTitleForm/SubTitleForm';
import CustomLabel from './CustomLabel';
import useStyle from './logProcessamentoBorderoDetalhesModal.styles';
import { getDateTimeMask, getMoneyMask } from '../../../helpers/utils/utils';
import DialogNew from '../../../layouts/FormDialog/DialogNew';

function LogProcessamentoBorderoDetalhesModal({
  onOpenModal,
  onCloseHandlerModal,
  logProcessamentoBorderoDetalhes,
}) {
  const styles = useStyle();
  const {
    codigoCliente,
    dataCadastramento,
    descricaoTipoBorderoLog,
    mensagem,
    nomeDevedor,
    numeroProcesso,
    qtdeTitulos,
    valorTotalProcesso,
    responsavelProcessamento,
    tipoEntrada,
  } = logProcessamentoBorderoDetalhes;

  return (
    <DialogNew

      open={onOpenModal}
      text="Visualizar Log"
      className={styles.root}

    >
      <DialogContent>
        <Grid24 isFormContainer spacing={1} style={{ backGroundColor: 'tomato' }}>
          <Grid24 xs={24} sm={24}>
            <Grid24 xs={24} sm={24}>
              <SubTitleForm>
                {TITLE_INFORMACAOES}
              </SubTitleForm>
            </Grid24>
            <Grid24 xs={24} sm={16} style={{ display: 'content' }}>
              <Grid24 xs={24} sm={24} className={styles.informacoes_gerais_div}>
                <CustomLabel title={LABEL_TIPO} value={descricaoTipoBorderoLog} />
              </Grid24>
              <Grid24 xs={24} sm={24} className={styles.informacoes_gerais_div}>
                <CustomLabel title={LABEL_NUMERO_PROCESSO} value={numeroProcesso} />
              </Grid24>
              <Grid24 xs={24} sm={24} className={styles.informacoes_gerais_div}>
                <CustomLabel title={LABEL_CODIGO_CLIENTE} value={codigoCliente} />
              </Grid24>
              <Grid24 xs={24} sm={24} className={styles.informacoes_gerais_div}>
                <CustomLabel title={LABEL_DEVEDOR} value={nomeDevedor} />
              </Grid24>
              <Grid24 xs={24} sm={24} className={styles.informacoes_gerais_div}>
                <CustomLabel title={LABEL_QTDE_TITULOS} value={qtdeTitulos} />
              </Grid24>
              <Grid24 xs={24} sm={24} className={styles.informacoes_gerais_div}>
                <CustomLabel title={LABEL_VALOR_TOTAL_PROCESSO} value={getMoneyMask(valorTotalProcesso)} />
              </Grid24>
              <Grid24 xs={24} sm={24} className={styles.informacoes_gerais_div}>
                <CustomLabel title={LABEL_RESPONSAVEL_PROCESSAMENTO} value={responsavelProcessamento} />
              </Grid24>
            </Grid24>
            <Grid24 xs={24} sm={8} alignItems="flex-start" alignContent="flex-start" className={styles.informacoes_gerais_div}>
              <Grid24 xs={24} sm={24} justify="flex-end" className={styles.informacoes_gerais_div}>
                <CustomLabel title={LABEL_DATA_CADASTRAMENTO} value={getDateTimeMask(dataCadastramento)} />
              </Grid24>
              <Grid24 xs={24} sm={24} justify="flex-end" className={styles.informacoes_gerais_div}>
                <CustomLabel title={LABEL_TIPO_ENTRADA} value={tipoEntrada} />
              </Grid24>
            </Grid24>
          </Grid24>
        </Grid24>
        <Grid24 xs={24} sm={24}>
          <Grid24 xs={24} sm={24} className={styles.label_mensagem}>
            <Typography component="p">{LABEL_MENSAGEM}</Typography>
          </Grid24>
          <Grid24 xs={24} sm={24} className={styles.div_mensagem_box}>
            <Typography
              component="pre"
              className={styles.div_mensagem_box_value_label}
            >
              {mensagem}
            </Typography>
          </Grid24>
        </Grid24>
        <Grid24 item xs={24} sm={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div>
            <Button
              onClick={onCloseHandlerModal}
            >
              {VOLTAR_BUTTON_LABEL}
            </Button>
          </div>

        </Grid24>
      </DialogContent>
    </DialogNew>
  );
}

export default React.memo(LogProcessamentoBorderoDetalhesModal);
