import React from 'react';

import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Stepper, Step,
} from '@material-ui/core';
import { MdCheck } from 'react-icons/md';
import { FaCircle } from 'react-icons/fa';
import {
  ButtonUI,
  ConsultaDinamicaManagerList,
  ConsultaDinamicaParameters,
  ConsultaDinamicaResult,
  Loading,
} from '../../components';

import {
  ContainerConsultaDinamica,
  ContainerStepperConsultaDinamica,
  CustomStepConnector,
  StepLabel,
} from './consultaDinamicaPage.styles';

import {
  getNewPage, isBadRequestException, TableUtils,
} from '../../helpers';
import { ConsultaDinamicaService } from '../../services';
import { ConsultaDinamicaTypes } from '../../types';
import { findAllResponsaveisConsultaDinamicaAction } from '../../store/Global/LoadSelectors/loadSelectors.saga';
import { initializePageAction } from '../../store/theme.actions';

function makeConsultaDinamicaList(httpResponse) {
  return TableUtils.makeTableItems({
    httpResponse,
    makeContentFactory: consulta => ({
      idConsultaDinamica: consulta?.idConsultaDinamica,
      tipo: ConsultaDinamicaTypes.lookup(consulta?.tipo),
      idTipo: ConsultaDinamicaTypes.altLookup(consulta?.tipo),
      nomeUsuario: consulta?.nomeUsuario || '',
      descricao: consulta?.descricao || '',
      idUsuario: consulta?.idUsuario || '',
      statusConsulta: consulta?.statusConsulta === 'A',
    }),
  });
}

function makeSummary(httpResponse) {
  return {
    resultado: httpResponse?.resultado || '',
    parametros: httpResponse?.parametros || [],
  };
}

function makeParameters(httpResponse = []) {
  return httpResponse.reduce((acc, parametro) => ({
    ...acc,
    [parametro.parametro]: '',
  }), {});
}

function ConsultaDinamicaStepIcon({ active, completed }) {
  let icon = null;
  if (completed) {
    icon = <MdCheck size={24} />;
  } else if (active) {
    icon = <FaCircle size={12} />;
  }

  return (
    <ContainerStepperConsultaDinamica active={active} completed={completed}>
      {icon}
    </ContainerStepperConsultaDinamica>
  );
}

function ConsultaDinamicaPageComponent() {
  const dispatch = useDispatch();
  const formResultRef = React.useRef();

  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const [selectedIdConsultaDinamica, setSelectedIdConsultaDinamica] = React.useState(null);
  const [searchManagerListPayload, setSearchManagerListPayload] = React.useState({ status: 'A' });
  const [consultaDinamicaList, setConsultaDinamicaList] = React.useState(makeConsultaDinamicaList({ size: 10 }));
  const [columnOrder, setColumnOrder] = React.useState(TableUtils.makeColumnOrder({ element: 'idConsultaDinamica', order: 'ASC', arrow: false }));

  const [parametersFields, setParametersFields] = React.useState(makeParameters());
  const [summary, setSummary] = React.useState(makeSummary());
  const [parametersPayload, setParametersPayload] = React.useState(null);

  const [consultaDinamicaResult, setConsultaDinamicaResult] = React.useState(TableUtils.makeTableItems({}));

  function handleNextStep() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBackStep() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleResetStep() {
    setActiveStep(0);
  }

  function handleChangeColumnOrderManagerList(columnName) {
    const newOrdenacao = TableUtils.makeColumnOrder({
      element: columnName,
      order: columnOrder.getNextOrder(),
      arrow: columnOrder.getNextArrow(),
    });
    setColumnOrder(newOrdenacao);
  }

  function handlePageChangeManagerList(e, newPage) {
    const pageable = { size: consultaDinamicaList.size, page: Number(newPage) };
    setConsultaDinamicaList(oldValue => ({ ...oldValue, ...pageable }));
  }

  function handleChangeRowsPerPageManagerList(e) {
    const size = e.target.value;
    const newPage = getNewPage(consultaDinamicaList.size, consultaDinamicaList.page, size);
    const pageable = { size, page: Number(newPage) };
    setConsultaDinamicaList(oldValue => ({ ...oldValue, ...pageable }));
  }

  async function updateConsultaDinamicaResult(pageable) {
    setLoading(true);
    try {
      const httpResponse = await ConsultaDinamicaService.executeConsultaDinamica(pageable, selectedIdConsultaDinamica, parametersPayload);
      setConsultaDinamicaResult(TableUtils.makeTableItems({ httpResponse: httpResponse.data }));
    } catch (error) {
      if (isBadRequestException(error)) {
        toast.error(error?.response?.data?.message, { style: { width: '392px' } });
      } else {
        toast.error('Ocorreu um problema ao realizar consulta', { style: { width: '392px' } });
      }
    } finally {
      setLoading(false);
    }
  }

  function handlePageChangeResultList(e, newPage) {
    const pageable = { size: consultaDinamicaResult.size, page: Number(newPage) };
    updateConsultaDinamicaResult(pageable).then();
  }

  function handleChangeRowsPerPageResultList(e) {
    const size = e.target.value;
    const newPage = getNewPage(consultaDinamicaResult.size, consultaDinamicaResult.page, size);
    const pageable = { size, page: Number(newPage) };
    updateConsultaDinamicaResult(pageable).then();
  }

  async function findAllParametrosFromQuery() {
    setLoading(true);
    try {
      const httpResponse = await ConsultaDinamicaService.findSummaryConsultaDinamica(selectedIdConsultaDinamica);
      setSummary(makeSummary(httpResponse.data));
      setParametersFields(makeParameters(httpResponse.data.parametros));
      setParametersPayload(null);
      handleNextStep();
    } catch (e) {
      toast.error('Consulta está com erro de digitação!', { style: { width: '392px' } });
    } finally {
      setLoading(false);
    }
  }

  const stepsActionsConfig = {
    0: {
      label: 'Prosseguir',
      run: findAllParametrosFromQuery,
      disabled: selectedIdConsultaDinamica === null,
    },
    1: {
      label: 'Executar',
      run: () => formResultRef.current.dispatchEvent(new Event('submit', { cancelable: true })),
      disabled: Object.keys(parametersFields).some(key => !parametersFields[key]),
    },
    2: {
      label: 'Nova Pesquisa',
      run: handleResetStep,
      disabled: false,
    },
  };

  /** Atualizar tabelas que lista todos as consultas dinâmicas */
  React.useEffect(() => {
    setLoading(true);
    const pageable = { size: consultaDinamicaList.size, page: consultaDinamicaList.page };
    ConsultaDinamicaService.findAllListConsultaDinamica(pageable, searchManagerListPayload, columnOrder.get())
      .then((httpResponse) => {
        setConsultaDinamicaList(makeConsultaDinamicaList(httpResponse.data));
      }).catch(() => {
        setConsultaDinamicaList(makeConsultaDinamicaList());
      }).finally(() => {
        setLoading(false);
      });
  }, [consultaDinamicaList.size, consultaDinamicaList.page, columnOrder, searchManagerListPayload]);


  /** Executar consulta dinâmica selecionada */
  React.useEffect(() => {
    if (!parametersPayload) {
      return;
    }

    setLoading(true);
    ConsultaDinamicaService.executeConsultaDinamica({ page: 0, size: 10 }, selectedIdConsultaDinamica, parametersPayload)
      .then((httpResponse) => {
        setConsultaDinamicaResult(TableUtils.makeTableItems({ httpResponse: httpResponse.data }));
        if (httpResponse.data.content.length === 0) {
          toast.warning('Nenhum resultado foi encontrado relacionado à sua pesquisa');
        } else {
          handleNextStep();
        }
      }).catch(() => {
        toast.error('Ocorreu um erro na consulta! Verifique os parâmetros informados', { style: { width: '392px' } });
      }).finally(() => {
        setLoading(false);
      });
  }, [selectedIdConsultaDinamica, parametersPayload]);

  /** Inicializar compobox do responsável */
  React.useEffect(() => {
    dispatch(initializePageAction('Consulta Dinâmica'));
    dispatch(findAllResponsaveisConsultaDinamicaAction());
  }, [dispatch]);

  return (
    <>
      <Loading show={loading} />
      <ContainerConsultaDinamica>
        <div>
          <Stepper alternativeLabel activeStep={activeStep} connector={<CustomStepConnector />}>
            <Step>
              <StepLabel StepIconComponent={ConsultaDinamicaStepIcon}>PASSO 1</StepLabel>
            </Step>
            <Step>
              <StepLabel StepIconComponent={ConsultaDinamicaStepIcon}>PASSO 2</StepLabel>
            </Step>
            <Step>
              <StepLabel StepIconComponent={ConsultaDinamicaStepIcon}>PASSO 3</StepLabel>
            </Step>
          </Stepper>
        </div>
        <div>
          {activeStep === 0 && (
            <ConsultaDinamicaManagerList
              payload={searchManagerListPayload}
              onUpdatePayload={setSearchManagerListPayload}
              idConsultaDinamica={selectedIdConsultaDinamica}
              onChangeSelectedId={setSelectedIdConsultaDinamica}
              columnOrder={columnOrder}
              list={consultaDinamicaList}
              clearParameterPayload={() => setParametersPayload(null)}
              onChangeColumnOrder={handleChangeColumnOrderManagerList}
              onChangePage={handlePageChangeManagerList}
              onChangeRowsPerPage={handleChangeRowsPerPageManagerList}
            />
          )}

          {activeStep === 1 && (
            <ConsultaDinamicaParameters
              formRef={formResultRef}
              updateParametersFields={setParametersFields}
              parametersFields={parametersFields}
              onChangeParametersPayload={setParametersPayload}
              summary={summary}
            />
          )}

          {activeStep === 2 && (
            <ConsultaDinamicaResult
              idConsultaDinamica={selectedIdConsultaDinamica}
              columnsNames={summary.resultado}
              list={consultaDinamicaResult}
              parametersPayload={parametersPayload}
              onChangePage={handlePageChangeResultList}
              onChangeRowsPerPage={handleChangeRowsPerPageResultList}
              onChangeLoading={setLoading}
            />
          )}
        </div>
        <div>
          <ButtonUI
            style={{ visibility: activeStep === 0 ? 'hidden' : 'visible' }}
            onClick={handleBackStep}
          >
            {activeStep === 2 ? 'Repetir Pesquisa' : 'Voltar'}
          </ButtonUI>
          <ButtonUI
            disabled={stepsActionsConfig[activeStep].disabled}
            color="success"
            onClick={stepsActionsConfig[activeStep].run}
          >
            {stepsActionsConfig[activeStep].label}
          </ButtonUI>
        </div>
      </ContainerConsultaDinamica>
    </>
  );
}

export const ConsultaDinamicaPage = ConsultaDinamicaPageComponent;
