export default nome => ({
  usuario: {
    value: nome || '',
    required: true,
    error: false,
    changeAttr: {
      maxLength: 25,
    },
  },
  senha: {
    value: '',
    required: true,
    error: false,
    changeAttr: {
      maxLength: 25,
    },
  },
});
