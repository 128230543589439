import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { setCommonAction, setSimpleEmailEntradaBorderoAction } from './EmailEntradaBordero.store';
import { ENVIAR_EMAIL_ENTRADA_BORDERO_ACTION, EXCLUIR_EMAIL_ENTRADA_BORDERO_ACTION, SET_BUSCAR_DADOS_TABELA } from './EmailEntradaBordero.constants';
import EmailEntradaBorderoService from '../../services/core/emailEntradaBordero/EmailEntradaBorderoService';


function* successPesquisarEmailEntradaBordero(data) {
  yield put(setSimpleEmailEntradaBorderoAction('tableView', data.data.content));
  yield put(setSimpleEmailEntradaBorderoAction('totalElements', data.data.totalElements));
  yield put(setSimpleEmailEntradaBorderoAction('rowsPerPage', data.data.pageable.pageSize));
  yield put(setSimpleEmailEntradaBorderoAction('page', data.data.pageable.pageNumber));
}

function* emptyPesquisaEmailEntradaBordero() {
  yield put(setSimpleEmailEntradaBorderoAction('tableView', []));
  yield put(setSimpleEmailEntradaBorderoAction('totalElements', 0));
}

export const enviarEmailEntradaBorderoAction = () => ({
  type: ENVIAR_EMAIL_ENTRADA_BORDERO_ACTION,
});

export const excluirEmailEntradaBorderoAction = idEmailEntradaBordero => ({
  type: EXCLUIR_EMAIL_ENTRADA_BORDERO_ACTION,
  idEmailEntradaBordero,
});

export const buscarDadosTabelaAction = () => ({
  type: SET_BUSCAR_DADOS_TABELA,
});

function* buscarDadosTabelaHandler() {
  yield put(setCommonAction('loadingPage', true));
  const page = yield select(states => states.page);
  const rowsPerPage = yield select(states => states.rowsPerPage);

  try {
    const response = yield call(EmailEntradaBorderoService.buscarEmailEntradaBordero, { page, size: rowsPerPage });

    yield successPesquisarEmailEntradaBordero(response);
  } catch (e) {
    yield emptyPesquisaEmailEntradaBordero();
  } finally {
    yield put(setCommonAction('loadingPage', false));
  }
}

function* enviarEmailEntradaBorderoHandler() {
  yield put(setCommonAction('loadingPage', true));
  try {
    const response = yield call(EmailEntradaBorderoService.enviarEmailEntradaBordero);

    switch (response.data.codResult) {
      case 0:
        toast.success('Emails enviados com sucesso.');
        break;
      case 1:
        toast.warn('Alguns e-mails não puderam ser enviados. Tente novamente.');
        break;
      default:
        toast.error('Os e-mails não foram enviados. Tente novamente.', { style: { width: '392px' } });
        break;
    }
  } catch (e) {
    yield emptyPesquisaEmailEntradaBordero();
    toast.error('Os e-mails não foram enviados. Tente novamente.', { style: { width: '392px' } });
  } finally {
    yield put(setCommonAction('loadingPage', false));
    yield call(buscarDadosTabelaHandler);
  }
}

function* excluirEmailEntradaBorderoHandler(actions) {
  const { idEmailEntradaBordero } = actions;
  yield put(setCommonAction('loadingPage', true));
  try {
    yield call(EmailEntradaBorderoService.excluirEmailEntradaBordero, idEmailEntradaBordero);
    toast.success('Email removido com sucesso.');
  } catch (e) {
    yield emptyPesquisaEmailEntradaBordero();
    toast.error('O e-mail não foi removido. Tente novamente.', { style: { width: '392px' } });
  } finally {
    yield put(setCommonAction('loadingPage', false));
    yield call(buscarDadosTabelaHandler);
  }
}

export default function* watchEmailEntradaBordero() {
  yield takeLatest(SET_BUSCAR_DADOS_TABELA, buscarDadosTabelaHandler);
  yield takeLatest(ENVIAR_EMAIL_ENTRADA_BORDERO_ACTION, enviarEmailEntradaBorderoHandler);
  yield takeLatest(EXCLUIR_EMAIL_ENTRADA_BORDERO_ACTION, excluirEmailEntradaBorderoHandler);
}
