import { useDispatch } from 'react-redux';
import React from 'react';
import { initializePageAction } from '../../../store/theme.actions';
import withTokenInterceptor from '../../../hoc/withTokenInterceptor/withTokenInterceptor';
import RelatoriosAS from '../../../components/AcordoSeguro/Relatorios/RelatoriosAS';

function RelatoriosASPage() {
  const dispatch = useDispatch();

  dispatch(initializePageAction('Relatórios'));

  return (<RelatoriosAS />);
}

export default withTokenInterceptor(RelatoriosASPage);
