import React from 'react';
import { v4 as uuid } from 'uuid';
import { CardResumoContainer } from '../RelacaoPrestacaoStyle';

function CardResumo({
  isDetail = false, title = '', value = null, bgColor = '', lineColor = '',
}) {
  return isDetail ? (
    <CardResumoContainer
      isDetail={isDetail}
      bgColor={bgColor}
      lineColor={lineColor}
    >
      <div>
        <h5>{title}</h5>
        {value.map(resumo => (
          <div key={uuid()}>
            <h6>{resumo?.label}</h6>
            <h6>{resumo?.value}</h6>
          </div>
        ))}
      </div>
    </CardResumoContainer>

  ) : (
    <CardResumoContainer
      bgColor={bgColor}
    >
      <h4>{title}</h4>
      <h3>{value}</h3>
    </CardResumoContainer>
  );
}

export default CardResumo;
