import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import OrdenacaoColuna from '../UI/Tabela/Ordenacao/OrdenacaoColuna';

function TableHeaderLancamentosNaoPrestadosComponent({ columnOrder, onChangeOrder }) {
  return (
    <TableRow>
      <TableCell height={36} width={104}>
        <OrdenacaoColuna
          columnLabel="Data"
          idColumn="data"
          arrowOrdenacao={columnOrder?.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder('data')}
        />
      </TableCell>
      <TableCell height={36} width={107}>
        Processo
      </TableCell>
      <TableCell height={36} width={176}>
        <OrdenacaoColuna
          columnLabel="Tipo do Lançamento"
          idColumn="tipoDescricao"
          arrowOrdenacao={columnOrder?.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder('tipoDescricao')}
        />
      </TableCell>
      <TableCell height={36} width={200}>
        Histórico
      </TableCell>
      <TableCell height={36} width={104}>
        Crédito
      </TableCell>
      <TableCell height={36} width={104}>
        Débito
      </TableCell>
      <TableCell height={36} width={115}>
        Ações
      </TableCell>
    </TableRow>
  );
}
export const TableHeaderlancamentosNaoPrestados = TableHeaderLancamentosNaoPrestadosComponent;
