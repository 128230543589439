import React, { useCallback } from 'react';
import Grid24 from '../../Grid24/Grid24';
import {
  LABEL_ATE, LABEL_DE, LABEL_DIAS, LABEL_PORCENTAGEM, LABEL_REPASSE, LABEL_TAXA_JUROS, TOOLTIP_TRASH,
} from './FaixaDeJurosEhRepasse.constants';
import { getRequiredLabel } from '../../../../helpers/utils/utils';
import IconButtonTooltip from '../../IconButtonTooltip/IconButtonTooltip';
import useStyle from './FaixaDeJurosEhRepasse.styles';
import NumberInput from '../../Field/Number/NumberInput';

function FaixaDeJurosEhRepasse({
  index,
  model,
  disabled,
  setAttribute,
  setFocus,
  onRemove,
}) {
  const classes = useStyle();

  const onFocusAttributeHandler = useCallback((event) => {
    let fieldName = event.target.name;

    if (event.target.nextElementSibling) {
      fieldName = event.target.nextElementSibling.name;
    }
    setFocus(fieldName, index);
  }, [setFocus, index]);

  const onChangeAttributeHandler = useCallback((event) => {
    const { name, value } = event.target;
    setAttribute(name, value, index);
  }, [setAttribute, index]);

  return (
    <Grid24 container spacing={1}>
      <Grid24 xs={24} sm={4}>
        <NumberInput
          isForm
          fullWidth
          numberType="integer"
          maxValue={999999}
          sideText={LABEL_DIAS}
          disabled={disabled || index === 0}
          label={getRequiredLabel(LABEL_DE, (index !== 0 && !disabled))}
          name={model.quantidadeDiasInicial.name}
          value={model.quantidadeDiasInicial.value}
          errorMessage={model.quantidadeDiasInicial.errorMessage}
          error={model.quantidadeDiasInicial.error}
          onChange={onChangeAttributeHandler}
          onFocus={onFocusAttributeHandler}
        />
      </Grid24>
      <Grid24 xs={24} sm={4}>
        <NumberInput
          isForm
          fullWidth
          numberType="integer"
          maxValue={999999}
          sideText={LABEL_DIAS}
          disabled={disabled}
          label={getRequiredLabel(LABEL_ATE, !disabled)}
          name={model.quantidadeDiasFinal.name}
          value={model.quantidadeDiasFinal.value}
          error={model.quantidadeDiasFinal.error}
          errorMessage={model.quantidadeDiasFinal.errorMessage}
          onChange={onChangeAttributeHandler}
          onFocus={onFocusAttributeHandler}
        />
      </Grid24>
      <Grid24 xs={24} sm={3}>
        <NumberInput
          isForm
          fullWidth
          numberType="percentage"
          maxValue={100}
          sideText={LABEL_PORCENTAGEM}
          disabled={disabled}
          label={getRequiredLabel(LABEL_TAXA_JUROS, !disabled)}
          error={model.porcentagemJuros.error}
          name={model.porcentagemJuros.name}
          value={model.porcentagemJuros.value}
          errorMessage={model.porcentagemJuros.errorMessage}
          maxLength={model.porcentagemJuros.maxLength}
          onChange={onChangeAttributeHandler}
          onFocus={onFocusAttributeHandler}
        />
      </Grid24>
      <Grid24 xs={24} sm={3}>
        <NumberInput
          isForm
          fullWidth
          numberType="percantage"
          maxValue={100}
          sideText={LABEL_PORCENTAGEM}
          disabled={disabled}
          label={getRequiredLabel(LABEL_REPASSE, !disabled)}
          error={model.porcentagemJurosRepasse.error}
          name={model.porcentagemJurosRepasse.name}
          value={model.porcentagemJurosRepasse.value}
          errorMessage={model.porcentagemJurosRepasse.errorMessage}
          maxLength={model.porcentagemJurosRepasse.maxLength}
          onChange={onChangeAttributeHandler}
          onFocus={onFocusAttributeHandler}
        />
      </Grid24>
      {(index !== 0 && !disabled) && (
        <Grid24 item xs={24} sm={2} justify="end" className={classes.customIconTrash}>
          <IconButtonTooltip
            type="trash"
            disabled={disabled}
            hint={TOOLTIP_TRASH}
            onClick={onRemove}
          />
        </Grid24>
      )}
    </Grid24>
  );
}

export default React.memo(FaixaDeJurosEhRepasse);
