import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import { useDispatch } from 'react-redux';

import PaperUI from '../../../components/UI/Paper/PaperUI';
import CardWrapper from '../../../components/UI/CardWrapper/CardWrapper';

import { Resume } from '../../../components/UI/Inspecionar/Resume/Resume';
import { Accordion } from '../../../components/UI/Inspecionar/Accordion/Accordion';
import { AcompanhamentoProcesso } from '../../../components/UI/AcompanhamentoProcesso/AcompanhamentoProcesso';

import {
  Title,
  Titulos,
  ChildContainer,
  Divider,
  ParentContainer,
  Info,
  Pagamento,
  AccordionDetails,
  BackButton,
  ButtonErro,
  DialogNewErro,
} from './InspecionarASPage.styles';

import { buscarProcessos } from '../../../services/core/acordoseguro/processo.services';
import { makeProcesso } from '../../../helpers/factory/inspecionarProcesso.factory';
import { initializePageAction } from '../../../store/theme.actions';
import { acompanhamentoProcessoController } from '../../../components/UI/AcompanhamentoProcesso/acompanhamentoProcessoController';

const DEVEDOR = 'devedor';
const CLIENTE = 'cliente';
const TITULOS = 'titulos';
const ACOMPANHAMENTO = 'acompanhamento';
const ACORDO = 'acordo';

export function InspecionarASPage() {
  const [expandedPanel, setExpandedPanel] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingAcompanhamentos, setLoadingAcompanhamentos] = useState(false);
  const [processo, setProcesso] = useState(makeProcesso());
  const [acompanhentos, setAcompanhamentos] = useState([]);
  const [pageLoadError, setPageLoadError] = useState(false);

  const { numeroProcesso } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  function handleExpandPanel(accordionName) {
    return (event, isExpanded) => {
      setExpandedPanel(isExpanded ? accordionName : '');
    };
  }

  async function addNextAcompanhamentos() {
    setLoadingAcompanhamentos(true);
    try {
      const data = await acompanhamentoProcessoController.findAllFromNextPage(numeroProcesso, acompanhentos);
      setAcompanhamentos(data.acompanhamentos);
    } finally {
      setLoadingAcompanhamentos(false);
    }
  }

  const handleGoBack = useCallback(() => {
    if (history.location.state?.title) {
      history.goBack();
    } else {
      history.replace('/');
    }
  }, [history]);

  const spanDocumento = useMemo(() => {
    if (processo.devedor.cnpjCpf.length === 14) {
      return 'CPF:';
    }
    return 'CNPJ:';
  }, [processo.devedor.cnpjCpf]);

  useEffect(() => {
    dispatch(initializePageAction(history.location.state?.title ? history.location.state?.title : 'Inspecionar Processos'));
  }, [dispatch, history.location.state]);


  const buscarProcesso = useCallback(async (numProcesso) => {
    try {
      const processoResponse = await buscarProcessos(numProcesso);
      setProcesso(makeProcesso(processoResponse.data));
      const data = await acompanhamentoProcessoController.findAllFromPage(numProcesso);
      setAcompanhamentos(data.acompanhamentos);
    } catch {
      setPageLoadError(true);
      setProcesso(makeProcesso());
      setAcompanhamentos([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    buscarProcesso(numeroProcesso);
  }, [buscarProcesso, numeroProcesso]);

  return (
    <PaperUI>
      <DialogNewErro
        open={pageLoadError}
      >
        <Typography style={{ textAlign: 'left' }}>
          Não foi possível atender sua solicitação, pois este processo não existe!
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '22px' }}>
          <ButtonErro
            type="submit"
            onClick={handleGoBack}
          >
            OK, ENTENDI
          </ButtonErro>
        </div>
      </DialogNewErro>
      <CardWrapper showLoading={loading}>
        <Title variant="h2">
          Processo Nº
          {pageLoadError ? '' : numeroProcesso}
        </Title>
        <Accordion
          style={{ marginBottom: '4px' }}
          title="Dados do Devedor"
          expanded={expandedPanel === DEVEDOR}
          onChange={handleExpandPanel(DEVEDOR)}
        >
          <AccordionDetails>
            <ParentContainer>
              <ChildContainer>
                <Info>
                  <span>Nome: </span>
                  {processo.devedor.nome}
                </Info>
                <Info>
                  <span>E-mail: </span>
                  {processo.devedor.email}
                </Info>
              </ChildContainer>
              <ChildContainer>
                <Info>
                  <span>
                    {spanDocumento}
                    {' '}
                  </span>
                  {processo.devedor.cnpjCpf}
                </Info>
                <Info>
                  <span>Telefone: </span>
                  {processo.devedor.telefone}
                </Info>
              </ChildContainer>
            </ParentContainer>
            <Divider />
            <ParentContainer>
              <ChildContainer>
                <Info>
                  <span>CEP: </span>
                  {processo.devedor.cep}
                </Info>
                <Info>
                  <span>Endereço: </span>
                  {processo.devedor.endereco}
                </Info>
                <Info>
                  <span>Cidade: </span>
                  {processo.devedor.cidade}
                </Info>
              </ChildContainer>
              <ChildContainer>
                <Info>
                  <span>Bairro: </span>
                  {processo.devedor.bairro}
                </Info>
                <Info>
                  <span>Estado: </span>
                  {processo.devedor.estado}
                </Info>
              </ChildContainer>
            </ParentContainer>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ marginBottom: '4px' }}
          title="Dados do Cliente"
          expanded={expandedPanel === CLIENTE}
          onChange={handleExpandPanel(CLIENTE)}
        >
          <AccordionDetails>
            <ParentContainer>
              <Info>
                <span>Nome: </span>
                {processo.cliente.nome}
              </Info>
            </ParentContainer>
            <Divider />
            <ParentContainer>
              <ChildContainer>
                <Info>
                  <span>CEP: </span>
                  {processo.cliente.cep}
                </Info>
                <Info>
                  <span>Endereço: </span>
                  {processo.cliente.endereco}
                </Info>
                <Info>
                  <span>Cidade: </span>
                  {processo.cliente.cidade}
                </Info>
              </ChildContainer>
              <ChildContainer>
                <Info>
                  <span>Bairro: </span>
                  {processo.cliente.bairro}
                </Info>
                <Info>
                  <span>Estado: </span>
                  {processo.cliente.estado}
                </Info>
              </ChildContainer>
            </ParentContainer>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ marginBottom: '4px' }}
          title="Títulos"
          expanded={expandedPanel === TITULOS}
          onChange={handleExpandPanel(TITULOS)}
        >
          <AccordionDetails alignitems="center">
            <Resume valores={processo.valores} />
            <Titulos>
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Número</th>
                  <th>Vencimento</th>
                  <th>Valor principal (R$)</th>
                </tr>
              </thead>
              <tbody>
                {processo.titulos.map(titulo => (
                  <tr key={titulo.id}>
                    <td>{titulo.descricaoTitulo}</td>
                    <td>{titulo.numeroTitulo}</td>
                    <td>{titulo.dataVencimentoTitulo}</td>
                    <td>{titulo.valorPrincipalTitulo}</td>
                  </tr>
                ))}
              </tbody>
            </Titulos>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ marginBottom: '4px' }}
          title="Acompanhamento"
          expanded={expandedPanel === ACOMPANHAMENTO}
          onChange={handleExpandPanel(ACOMPANHAMENTO)}
        >
          <AccordionDetails style={{ padding: 0, paddingLeft: '4px' }}>
            <AcompanhamentoProcesso
              acompanhamentos={acompanhentos}
              addNextAcompanhamentos={addNextAcompanhamentos}
              showLoading={loadingAcompanhamentos}
              isDisbaledChangePin
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ marginBottom: '4px' }}
          title="Dados do Acordo"
          expanded={expandedPanel === ACORDO}
          onChange={handleExpandPanel(ACORDO)}
        >
          <AccordionDetails alignitems="center">
            <Resume valores={processo.valores} />
            <Pagamento>
              <Typography>
                <span>Opção de pagamento:</span>
                {' '}
                Boleto a prazo em 3x
              </Typography>
              <Typography>Valor total: R$ 5.315,85</Typography>
            </Pagamento>
          </AccordionDetails>
        </Accordion>
      </CardWrapper>
      <BackButton
        onClick={handleGoBack}
        color="primary"
      >
        Voltar
      </BackButton>
    </PaperUI>
  );
}
