const SET_SELECTOR_DATA = 'SET_SELECTOR_DATA_LOAD_SELECTORS';
const SET_LOADING = 'SET_LOADING_LOAD_SELECTORS';
const CLEAR = 'CLEAR_LOAD_SELECTORS';
const CLEAR_SELECTOR = '@selector/CLEAR_SELECTOR';

function buildInitialStates() {
  return {
    categorias: {
      selector: [],
      loading: false,
    },
    estados: {
      selector: [],
      loading: false,
    },
    tiposTelefone: {
      selector: [],
      loading: false,
    },
    tiposTitulo: {
      selector: [],
      loading: false,
    },
    usuarios: {
      selector: [],
      loading: false,
    },
    usuariosTelemarketing: {
      selector: [],
      loading: false,
    },
    centrosCusto: {
      selectot: [],
      loading: false,
    },
    filiais: {
      selector: [],
      loading: false,
    },
    tipoClassificacaoClientes: {
      selector: [],
      loading: false,
    },
    tipoUsuario: {
      selector: [],
      loading: false,
    },
    bancos: {
      selector: [],
      loading: false,
    },
    tiposConta: {
      selector: [],
      loading: false,
    },
    perfis: {
      selector: [],
      loading: false,
    },
    ramosAtividades: {
      selector: [],
      loading: false,
    },
    perfisUsuarioCliente: {
      selector: [],
      loading: false,
    },
    tipoCalculoHonorarioParametro: {
      selector: [],
      loading: false,
    },
    tipoCalculoComissaoParametro: {
      selector: [],
      loading: false,
    },
    tipoDescontoCliente: {
      selector: [],
      loading: false,
    },
    fases: {
      selector: [],
      loading: false,
    },
    requisicoes: {
      selector: [],
      loading: false,
    },
    regraCalculoRepasseParametro: {
      selector: [],
      loading: false,
    },
    tipoUsuarioComercialPrincipal: {
      selector: [],
      loading: false,
    },
    tipoUsuarioComercialTelemarketing: {
      selector: [],
      loading: false,
    },
    tipoAcordoSeguro: {
      selector: [],
      loading: false,
    },
    intervaloPagamento: {
      selector: [],
      loading: false,
    },
    modeloNotificacaoEmail: {
      selector: [],
      loading: false,
    },
    tipoComissaoBordero: {
      selector: [],
      loading: false,
    },
    borderoTipoStatus: {
      selector: [],
      loading: false,
    },
    tiposLogProcessamentoBordero: {
      selector: [],
      loading: false,
    },
    tipoPrazoExpiracaoPix: {
      selector: [],
      loading: false,
    },
    pastasDaFase: {
      selector: [],
      loading: false,
    },
    negociadores: {
      selector: [],
      loading: false,
    },
    tiposAgendamentos: {
      selector: [],
      loading: false,
    },
    statusBorderoPesquisa: {
      selector: [],
      loading: false,
    },
    tipoTextoPadrao: {
      selector: [],
      loading: false,
    },
    motivosBaixa: {
      selector: [],
      loading: false,
    },
    autoresAcompanhamentos: {
      selector: [],
      loading: false,
    },
    tiposAcompanhamentoProcesso: {
      selector: [],
      loading: false,
    },
    tipoTitulo: {
      selector: [],
      loading: false,
    },
    tiposCalendario: {
      selector: [],
      loading: false,
    },
    usuariosConsultaDinamica: {
      selector: [],
      loading: false,
    },
    usuariosPermissaoConsultaDinamica: {
      selector: [],
      loading: false,
    },
    tiposPrestacao: {
      selector: [],
      loading: false,
    },
    tiposLiquidacaoPrestacao: {
      selector: [],
      loading: false,
    },
    geradoresRelatorios: {
      selector: [],
      loading: false,
    },
    tiposChavesPix: {
      selector: [],
      loading: false,
    },
    tiposLancamentosItems: {
      selector: [],
      loading: false,
    },
    responsaveisItems: {
      selector: [],
      loading: false,
    },
    tiposNotasFiscais: {
      selector: [],
      loading: false,
    },
    prefeiturasNotaFiscal: {
      selector: [],
      loading: false,
    },
    tiposFormaPagamento: {
      selector: [],
      loading: false,
    },
    tiposIntervaloPagamento: {
      selector: [],
      loading: false,
    },
    tiposFormasPagamentoRecebimento: {
      selector: [],
      loading: false,
    },
    contaBancaria: {
      selector: [],
      loading: false,
    },
    tipoComissao: {
      selector: [],
      loading: false,
    },
    usuariosPermitemSimulacao: {
      selector: [],
      loading: false,
    },
    motivosQuebraAcordo: {
      selector: [],
      loading: false,
    },
    negociadoresExternosAtivosFase: {
      selector: [],
      loading: false,
    },
    pastasEnviarProcessoOutraFase: {
      selector: [],
      loading: false,
    },
    motivosExclusaoTitulo: {
      selector: [],
      loading: false,
    },
    negociadoresValidos: {
      selector: [],
      loading: false,
    },
    tiposTitulosAgendamentos: {
      selector: [],
      loading: false,
    },
    tiposHonorariosContratuais: {
      selector: [],
      loading: false,
    },
  };
}

function setSelectorDataHandler(states, actions) {
  return {
    ...states,
    [actions.name]: {
      ...states[actions.name],
      selector: [...actions.values],
    },
  };
}

function setLoadingHandler(states, actions) {
  return {
    ...states,
    [actions.name]: {
      ...states[actions.name],
      loading: actions.value,
    },
  };
}

function clearSelectorHandler(states, actions) {
  return {
    ...states,
    [actions.name]: {
      selector: [],
      loading: false,
    },
  };
}

function clearHandler() {
  return buildInitialStates();
}

export const clearSelectorAction = name => ({
  type: CLEAR_SELECTOR,
  name,
});

export const setSelectorDataAction = (name, values) => ({
  type: SET_SELECTOR_DATA,
  name,
  values,
});

export const setLoadingAction = (name, value) => ({
  type: SET_LOADING,
  name,
  value,
});

export const clearSelectorsAction = () => ({
  type: CLEAR,
});

export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    case SET_SELECTOR_DATA:
      return setSelectorDataHandler(states, actions);
    case SET_LOADING:
      return setLoadingHandler(states, actions);
    case CLEAR:
      return clearHandler();
    case CLEAR_SELECTOR:
      return clearSelectorHandler(states, actions);
    default:
      return states;
  }
};
