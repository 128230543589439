import React from 'react';
import { useDispatch } from 'react-redux';
import CrudUsuarioProvider from '../../../../../store/Usuario/V2/CrudUsuarioProvider';
import { initializePageAction } from '../../../../../store/theme.actions';
import withTokenInterceptor from '../../../../../hoc/withTokenInterceptor/withTokenInterceptor';
import CrudUsuario from '../../../../../components/Usuario/V2/CrudUsuario';


function EditarUsuarioPage2() {
  const dispatch = useDispatch();

  dispatch(initializePageAction('Usuários > Editar Cadastro'));
  return (
    <CrudUsuarioProvider>
      <CrudUsuario isEditMode />
    </CrudUsuarioProvider>
  );
}

export default withTokenInterceptor(EditarUsuarioPage2);
