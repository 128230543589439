import { AcompanhamentoItemModel } from './Item/AcompanhamentoItemModel';

function extractDayAndHour(data) {
  const splited = data.split(' ');
  const day = splited[0];
  const hour = splited[1];

  return {
    day,
    hour,
  };
}

export function convertDataToObject(data) {
  const acompanhamentoItem = new Map();
  data.forEach((idx) => {
    const { day, hour } = extractDayAndHour(idx.data);
    const acompanhamento = new AcompanhamentoItemModel(hour, idx.nomeUsuario, idx.login, idx.email, idx.texto);
    if (acompanhamentoItem.has(day)) {
      acompanhamentoItem.set(day, [...acompanhamentoItem.get(day), acompanhamento]);
    } else {
      acompanhamentoItem.set(day, [acompanhamento]);
    }
  });
  return acompanhamentoItem;
}
