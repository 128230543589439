import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 362px;
  padding: 12px 24px;
  border-radius: 8px;

  color: white;
  background-color: #6c45bd;

  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);

  header {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }

  > div {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const ChildContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.alignItem ? props.alignItem : 'flex-start')};

  p {
    padding-bottom: 0;
  }

  p:not(:last-child) {
    padding-bottom: 4px;
  }
`;

export const Divider = styled.hr`
  width: 100%;
  margin-block-start: 8px;
  margin-block-end: 4px;
  border: 1px solid white;
  color: white;
`;

export const Total = styled.p`
  font-weight: 500;
`;
