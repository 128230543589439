import { FaLock, FaLockOpen } from 'react-icons/fa';
import { v4 as uuid } from 'uuid';
import {
  DateUtils, getMoneyMask, TableUtils, textOverflow, toAmericanDate, toBrazilianDate, updateFieldsWithErrors,
} from '../../../../../../helpers';
import LancamentosNaoPrestadosService from '../../../../../../services/core/contaCorrenteClienteService/LancamentosNaoPrestadosService';
import store from '../../../../../../store/createStore';
import { AcaoBloqueio } from './AcaoBloqueio/AcaoBloqueio';
import { AcaoDesbloqueio } from './AcaoDesbloqueio/AcaoDesbloqueio';

export const actionProps = {
  bloquear: {
    tag: 'bloquear',
    makeActionProps: preveiwActions => ({ ...preveiwActions }),
  },
  desbloquear: {
    tag: 'desbloquear',
    makeActionProps: preveiwActions => ({ ...preveiwActions }),
  },
};

function getLancamentoNaoPrestadosPageableFromStore() {
  const { pageable } = store.getState().previousPageStates.lancamentosNaoPrestados;
  return {
    ...pageable,
    number: pageable?.page || 0,
    size: pageable?.size || 10,
    last: pageable?.lastPage || true,
  };
}

function makeLancamentosNaoPrestados(httpResponse) {
  return TableUtils.makeTableItems({
    httpResponse,
    makeContentFactory: lancamento => ({
      data: toBrazilianDate(lancamento?.data),
      historicoAlt: lancamento?.historico || null,
      historico: textOverflow(lancamento?.historico || '', 30),
      idContaCorrenteCliente: lancamento.idContaCorrenteCliente,
      idProcesso: lancamento.idProcesso,
      statusContaCorrenteCliente: lancamento.statusContaCorrenteCliente,
      tipoDescricao: lancamento.tipoDescricao,
      valorCredito: getMoneyMask(lancamento?.valorCredito) || '',
      valorDebito: getMoneyMask(lancamento?.valorDebito) || '',
    }),
  });
}


export const LancamentosNaoPrestadosFactory = {

  makeFormFields(values) {
    return {
      bloqueado: values?.bloqueado || false,
      data: toBrazilianDate(values?.data) || toBrazilianDate(DateUtils.makeDate(0)),
      historico: values?.historico || '',
      idCliente: values?.idCliente || '',
      idContaCorrenteCliente: values?.idContaCorrenteCliente || '',
      idProcesso: values?.idProcesso || '',
      idTipoContaCorrenteCliente: values?.idTipoContaCorrenteCliente || '',
      valor: values?.valor || '',
    };
  },

  makeTiposDoLancamentos(tiposDeLancamentos) {
    return {
      id: String(tiposDeLancamentos?.idTipoContaCorrenteCliente),
      value: tiposDeLancamentos?.descricao,
    };
  },
  makeLancamentosNaoPrestados,
  defaultMakeLancamentosNaoPrestados() {
    return makeLancamentosNaoPrestados(getLancamentoNaoPrestadosPageableFromStore());
  },
};

export const LancamentosNaoPrestadosController = {
  makePayload(formFields, idCliente, idContaCorrenteNaoPrestado) {
    return {
      bloqueado: formFields?.bloqueado || false,
      data: toAmericanDate(formFields?.data) || null,
      historico: formFields?.historico || null,
      idContaCorrenteCliente: idContaCorrenteNaoPrestado || null,
      idCliente,
      idProcesso: formFields?.idProcesso || null,
      idTipoContaCorrenteCliente: formFields?.idTipoContaCorrenteCliente || null,
      valor: formFields?.valor || null,
    };
  },

  async editarLancamento(errorsField, formFields, idCliente, setLoading, idContaCorrenteNaoPrestado) {
    try {
      setLoading(true);
      const payload = this.makePayload(formFields, idCliente, idContaCorrenteNaoPrestado);
      await LancamentosNaoPrestadosService.alterarLancamento(payload);
      return {
        success: true,
        error: null,
      };
    } catch (error) {
      const [updateWithErrors] = updateFieldsWithErrors(error, errorsField);
      return {
        success: false,
        error: {
          campos: this.makeErrorFields(updateWithErrors),
        },
      };
    } finally {
      setLoading(false);
    }
  },


  async novoLancamento(errorsField, formFields, codigo, setLoading) {
    try {
      setLoading(true);
      const payload = this.makePayload(formFields, codigo);
      await LancamentosNaoPrestadosService.findCadastrarLancamento(payload);
      return {
        success: true,
        error: null,
      };
    } catch (error) {
      const [updateWithErrors] = updateFieldsWithErrors(error, errorsField);
      return {
        success: false,
        error: {
          campos: this.makeErrorFields(updateWithErrors),
        },
      };
    } finally {
      setLoading(false);
    }
  },

  async findAllTiposDoLancamentos() {
    const responseNome = await LancamentosNaoPrestadosService.findAllTiposDoLancamentos();
    const tiposResponse = responseNome.data.map(LancamentosNaoPrestadosFactory.makeTiposDoLancamentos);
    return { tiposDoLancamentos: tiposResponse };
  },

  async findEditarLancamento(idContaCorrenteNaoPrestado) {
    const responseNome = await LancamentosNaoPrestadosService.findEditarLancamento(idContaCorrenteNaoPrestado);
    const lancamentoCliente = LancamentosNaoPrestadosFactory.makeFormFields(responseNome.data);
    return { lancamentoCliente };
  },

  async findAllLancamentosNaoPrestados(idCliente, statusContaCorrenteCliente, columnOrder, pageable) {
    let response = await LancamentosNaoPrestadosService.findAllLancamentosNaoPrestados(
      idCliente,
      statusContaCorrenteCliente,
      pageable,
      columnOrder.get(),
    );
    if (response.data.content.length === 0 && response.data.number > 0) {
      pageable.page -= 1;
      response = await LancamentosNaoPrestadosService.findAllLancamentosNaoPrestados(
        idCliente, statusContaCorrenteCliente,
        pageable,
        columnOrder.get(),
      );
    }
    const newLancamentosNaoPrestados = LancamentosNaoPrestadosFactory.makeLancamentosNaoPrestados(
      response.data,
    );
    return {
      lancamentosNaoPrestados: { ...newLancamentosNaoPrestados },
    };
  },
  async downloadLancamentoNaoPrestado(
    idCliente,
    nomeCliente,
    statusContaCorrenteCliente,
    columnOrder,
  ) {
    const httpResponse = await LancamentosNaoPrestadosService.downloadLancamentoNaoPrestado(
      idCliente,
      nomeCliente,
      statusContaCorrenteCliente,
      columnOrder.get(),
    );
    return httpResponse;
  },

  makeErrorFields(errors) {
    return {
      data: {
        error: errors?.data?.error || false,
        errorMessage: errors?.data?.errorMessage || '',
      },
      historico: {
        error: errors?.historico?.error || false,
        errorMessage: errors?.historico?.errorMessage || '',
      },
      idCliente: {
        error: errors?.idCliente?.error || false,
        errorMessage: errors?.idCliente?.errorMessage || '',
      },
      idContaCorrenteCliente: {
        error: errors?.idContaCorrenteCliente?.error || false,
        errorMessage: errors?.idContaCorrenteCliente?.errorMessage || '',
      },
      idProcesso: {
        error: errors?.idProcesso?.error || false,
        errorMessage: errors?.idProcesso?.errorMessage || '',
      },
      idTipoContaCorrenteCliente: {
        error: errors?.idTipoContaCorrenteCliente?.error || false,
        errorMessage: errors?.idTipoContaCorrenteCliente?.errorMessage || '',
      },
      valor: {
        error: errors?.valor?.error || false,
        errorMessage: errors?.valor?.errorMessage || '',
      },
    };
  },
  async deleteLancamento(
    idContaCorrenteCliente,
  ) {
    const httpResponse = await LancamentosNaoPrestadosService.deleteLancamento(idContaCorrenteCliente);
    return httpResponse;
  },
  async atualizarStatus(
    idContaCorrenteCliente,
    idStatusContaCorrenteCliente,
  ) {
    const httpResponse = await LancamentosNaoPrestadosService.atualizarStatus(idContaCorrenteCliente, idStatusContaCorrenteCliente);
    return httpResponse;
  },
  makeActionsButtons() {
    return [
      {
        key: uuid(),
        label: 'Bloquear Lançamentos',
        active: false,
        tag: actionProps.bloquear.tag,
        Icon: FaLock,
        Content: AcaoBloqueio,
      },
      {
        key: uuid(),
        label: 'Desbloquear Lançamentos',
        active: false,
        tag: actionProps.desbloquear.tag,
        Icon: FaLockOpen,
        Content: AcaoDesbloqueio,
      },
    ];
  },
  changeActionButton(actionsButtons, selectedTag) {
    return actionsButtons.map((action) => {
      if (action.tag === selectedTag) {
        action.active = !action.active;
      } else {
        action.active = false;
      }
      return action;
    });
  },
};
