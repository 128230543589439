import React, {
  useCallback, useState, useEffect, useRef, useMemo,
} from 'react';
import { toast } from 'react-toastify';

import { MdDeleteForever, MdLibraryBooks } from 'react-icons/md';
import { FaEraser } from 'react-icons/fa';

import {
  Grid, Typography,
  DialogActions,
} from '@material-ui/core';
import {
  Button,
} from '@mui/material';

import {
  ActionHeaderContainer, CustomButton, HotizontalButtonGroup, TextoPadraoButton,
} from '../InspecionarProcessoStyles';
import useStyles, { MainContainer, FooterContainer } from './acaoAgendarCompromissoStyles';

import TextareaField from '../../../components/UI/Field/Textarea/TextareaField';
import SelectorField from '../../../components/UI/Field/Selector/SelectorField';
import DateField from '../../../components/UI/Field/Date/DateField';
import ButtonUI from '../../../components/UI/Button/ButtonUI';

import { clearErrorField, getFieldName } from '../../../helpers/utils/form.utils';

import { acaoAgendarCompromissoController as controller } from './acaoAgendarCompromissoController';
import { loadingInspecaoProcessoController as loadingController } from '../loadingInspecaoProcessoController';
import { Title } from '../../../components/Title/Title';
import OptionDialogNew from '../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';
import DialogNew from '../../../layouts/FormDialog/DialogNew';
import { AntecedenteProcesso } from '../AntecendentesProcesso';

function AcaoAgendarCompromissoComponent({
  processo, tiposAgendamentos = [], updatePage, setLoadings,
  idFaseAtual,
  idProcesso,
  clientePermiteUnificar,
  dadosProcesso,
  fasesIds,
  podeModificarFase,
  disabledAntecedentes,
  tiposTitulosAgendamentos = [],
}) {
  const formRef = useRef();

  const [formFields, setFormFields] = useState(controller.makeFormFields());
  const [changeIndicators, setChangeIndicators] = useState(controller.makeChangeIndicators());

  const [agendamentoInicial, setAgendamentoInicial] = useState(controller.makeDefaultAgendamento());
  const [errors, setErrors] = useState(controller.makeErrors());

  const [showLimparAlert, setShowLimparAlert] = useState(false);
  const [showExcluirAlert, setShowExcluirAlert] = useState(false);

  const [antecedentesSelecionados, setAntecedentesSelecionados] = useState([]);
  const [openDialogAntecedentes, setOpenDialogAntecedentes] = useState(false);

  const hasNotFormChange = Object.keys(changeIndicators).filter(key => !!changeIndicators[key]).length === 0;


  const styles = useStyles();

  const updateChangeIndicators = useCallback((name, value) => {
    const isChange = value !== '';
    setChangeIndicators(oldChangeIndicators => ({ ...oldChangeIndicators, [name]: isChange }));
  }, []);

  function limparFormulario() {
    setFormFields(controller.makeFormFields({}, true));
    setErrors(controller.makeErrors());
    setChangeIndicators(controller.makeChangeIndicators(true));
    setShowLimparAlert(false);
  }

  async function excluirCompromisso() {
    setLoadings(loadingController.changeLoadingAction(true));
    const response = await controller.excluirAgendamentoCompromisso(processo.id);
    if (response.success) {
      updatePage();
      toast.success('Ação realizada com sucesso!');
    } else {
      toast.error('Não foi possível excluir', { style: { width: '392px' } });
    }
    setShowExcluirAlert(false);
    setLoadings(loadingController.changeLoadingAction(false));
  }

  async function onSubmit(e) {
    e.preventDefault();
    setLoadings(loadingController.changeLoadingAction(true));
    const response = await controller.agendarCompromisso(processo.id, formFields, errors, false);
    await antecedentesSelecionados.forEach((idSelecionado) => { controller.agendarCompromisso(idSelecionado, formFields, errors, true); });
    if (response.success) {
      updatePage();
      toast.success('Ação realizada com sucesso!');
    }
    setErrors(response.errors);
    setLoadings(loadingController.changeLoadingAction(false));
  }

  const onChangeHandler = useCallback((e) => {
    const { value } = e.target;
    const name = getFieldName(e.target);
    setFormFields(oldValues => ({ ...oldValues, [name]: value }));
    updateChangeIndicators(name, value);
  }, [updateChangeIndicators]);

  const clearError = useCallback((e) => {
    setErrors(clearErrorField(errors, getFieldName(e.target)));
  }, [errors]);

  const loadAgendamento = useCallback(async (idProcessoAtual) => {
    setLoadings(loadingController.changeLoadingAction(true));
    const response = await controller.buscarAgendamentoProcesso(idProcessoAtual);
    setFormFields(controller.makeFormFields(response));
    setAgendamentoInicial(response);
    setLoadings(loadingController.changeLoadingAction(false));
  }, [setLoadings]);

  useEffect(() => {
    loadAgendamento(processo.id).then();
  }, [processo, loadAgendamento]);

  const disabledAgendar = useMemo(() => (
    !formFields.dataAgendamento || formFields.textoAgendamento.trim().length === 0 || !formFields.idTipoAgendamento
  ), [formFields]);

  return (
    <>
      <DialogNew
        open={openDialogAntecedentes}
        text="Antecedentes"
        className={styles.dialog}
      >
        <Typography component="p" className={styles.typography}>
          Selecione os antecedentes:
        </Typography>
        <AntecedenteProcesso
          idProcesso={idProcesso}
          setLoadings={setLoadings}
          clientePermiteUnificar={clientePermiteUnificar}
          dadosProcesso={dadosProcesso}
          updatePage={updatePage}
          podeModificarFase={podeModificarFase}
          idFaseProcesso={idFaseAtual}
          fasesIds={fasesIds}
          accordion={false}
          antecedentesSelecionados={antecedentesSelecionados}
        />
        <DialogActions>
          <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
            <Button
              onClick={() => {
                setOpenDialogAntecedentes(false);
                setAntecedentesSelecionados([]);
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => { setOpenDialogAntecedentes(false); }}
            >
              Confirmar
            </Button>
          </div>
        </DialogActions>
      </DialogNew>
      <OptionDialogNew
        open={showExcluirAlert}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickConfirm={excluirCompromisso}
        onClickCancel={() => setShowExcluirAlert(false)}
      >
        <div style={{ fontSize: '18px' }}>
          <p>
            Deseja excluir o Compromisso Agendado?
            <br />
            Ao confirmar, o Compromisso será excluído permanentemente.
          </p>
        </div>
      </OptionDialogNew>
      <OptionDialogNew
        open={showLimparAlert}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickConfirm={limparFormulario}
        onClickCancel={() => setShowLimparAlert(false)}
        text="Tem certeza de que deseja descartar essas informações? "
      />
      <form ref={formRef} onSubmit={onSubmit}>
        <ActionHeaderContainer>
          <Title
            text="Agendamento de Compromisso"
            hint="O Compromisso será inserido no Acompanhamento do Processo"
          />
          <HotizontalButtonGroup>
            <CustomButton
              disabled={hasNotFormChange}
              onClick={() => setShowLimparAlert(true)}
            >
              <FaEraser size={18} />
              <span>Limpar</span>
            </CustomButton>
            <CustomButton
              disabled={!agendamentoInicial.hasAgendamentoCadastrado}
              onClick={() => setShowExcluirAlert(true)}
            >
              <MdDeleteForever size={18} />
              <span>Excluir</span>
            </CustomButton>
          </HotizontalButtonGroup>
        </ActionHeaderContainer>
        <MainContainer>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={2}>
              <DateField
                isForm
                fullWidth
                label="Data*"
                name="dataAgendamento"
                minDate={new Date()}
                error={errors.dataAgendamento.error}
                errorMessage={errors.dataAgendamento.errorMessage}
                value={formFields.dataAgendamento}
                onChange={onChangeHandler}
                onFocus={clearError}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <SelectorField
                isForm
                label="Tipo do Compromisso*"
                name="idTipoAgendamento"
                error={errors.idTipoAgendamento.error}
                errorMessage={errors.idTipoAgendamento.errorMessage}
                value={String(formFields.idTipoAgendamento)}
                items={tiposAgendamentos}
                onChange={onChangeHandler}
                onFocus={clearError}
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <SelectorField
                isForm
                label="Título do Compromisso*"
                name="tituloAgendamento"
                errorMessage={errors.tituloAgendamento.errorMessage}
                error={errors.tituloAgendamento.error}
                value={formFields.tituloAgendamento}
                items={tiposTitulosAgendamentos}
                onChange={onChangeHandler}
                onFocus={clearError}
              />
            </Grid>
          </Grid>
          <Grid container>
            <TextareaField
              label="Texto"
              name="textoAgendamento"
              minRows={8}
              value={formFields.textoAgendamento}
              placeholder="Inserir Texto"
              onChange={onChangeHandler}
            />
          </Grid>
        </MainContainer>
        <FooterContainer>
          <ButtonUI
            color="success"
            style={{ width: '136px' }}
            type="submit"
            disabled={disabledAgendar}
          >
            Agendar
          </ButtonUI>
          <TextoPadraoButton
            variant="outlined"
            onClick={() => setOpenDialogAntecedentes(true)}
            style={{ marginRight: '10px' }}
            disabled={disabledAntecedentes}
          >
            <MdLibraryBooks />
            <span>Antecedentes</span>
          </TextoPadraoButton>
        </FooterContainer>
      </form>
    </>
  );
}

export const AcaoAgendarCompromisso = AcaoAgendarCompromissoComponent;
