import { Button, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: '22px',
    paddingLeft: '8px',
    paddingRight: '8px',
  },

  header_nome_cliente: {
    justifyContent: 'center',
    border: '1px solid #a2a0a0',
    '& span:first-child': {
      fontWeight: 'bold',
    },
    display: 'flex',
    flexDirection: 'row',
    padding: '7px',
    marginBottom: '20px',
  },

  header_imprimir_resumo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '20%',
    '& h3': {
      textAlign: 'center',
      fontSize: '16px',
    },
  },

  cardResumo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    color: '#fff',
    width: '100%',
    '& h3': {
      textAlign: 'center',
      fontSize: '16px',
    },
    '& h4': {
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: '700',
    },
  },

  div__table: {
    marginTop: '20px',
  },
});

export const ButtonImprimir = styled(Button)`
  display: flex;
  justify-content: space-between;
  margin-left: 12px;

  &.MuiButton-root {
    width: 73px;
    padding: 4px 0;
    font-weight: bold;
    text-transform: none;
    color: #000000;
    font-size:12px ;

    &.Mui-disabled {
      opacity: 0.64;
    }
  }
`;

export const ActionContainer = styled.section`
  padding-top: 10px;
`;

export const ActionButtonGroup = styled.div`
  display: flex;

  > div {
    margin-right: 8px;
  }
`;

export const ActionButton = styled(IconButton)`
  width: 36px;
  height: 36px;
  padding: 8px;

  background-color: ${({ active }) => (active === 'true' ? 'transparent' : '#423362')};
  color: ${({ active }) => (active === 'true' ? '#423362' : 'white')};
  ${({ active }) => (active === 'true' ? 'border: 2px solid #423362;' : '')}

  :hover {
    background-color: ${({ active }) => (active === 'true' ? 'transparent' : '#423362')};
    opacity: 0.64;
  }

  :disabled {
    background-color: #c4c4c4;
  }
`;

export const ActionContentContainer = styled.section`
  margin-top: 20px;
  padding: 8px;
  border :1px solid #A2A0A0;
`;

export const ActionHeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const CustomButton = styled(Button)`
  width: 120px;
  height: 35px;

  background-color: white;
  color: #034DDC;
  border-radius: 16px;
  border:1px solid #034DDC;
  font-weight: bold;
  text-transform: none;

  svg {
    display: block;
  }

  span span {
    padding-left: 8px;

    display: block;
    font-size: 14px;
  }

  :disabled {
    border:1px solid #C4C4C4;
  }
`;
