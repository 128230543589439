import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  useCrudClienteDispatch,
  useCrudClienteSelector,
} from '../../../../../../store/Cliente/V2/Crud/CrudClienteProvider';
import FormWrapperWithTitle from '../../../../../UI/Wrapper/Form/FormWrapperWithTitle';
import { AddContainer, Container } from '../Honorarios/styles';
import Grid24 from '../../../../../UI/Grid24';
import Selector from '../../../../../UI/Field/Selector/SelectorField';
import { getRequiredLabel } from '../../../../../../helpers/utils/utils';
import {
  ADD_HINT,
  ADD_TITLE,
  ADD_TITLE_QUERY,
  COMBO_BOX_TYPE,
  TITLE,
} from './comissoes.constants';
import {
  addComissaoAction,
  removeComissaoAction,
  updateComissaoAction,
  updateTipoCalculoComissaoAction,
} from '../../../../../../store/Cliente/V2/Crud/crudCliente.store';
import { findRegraComissaoComercial, findTipoCalculoComissaoAction } from '../../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import FaixaComissao from './FaixaComissao';
import useUtilStyles from '../../../../../../helpers/styles/utils.styles';
import { getFaixaComissaoAction } from '../../../../../../store/Cliente/V2/Crud/crudCliente.saga';


function Comissoes({ disabled, location, isInsertMode }) {
  const globalDispatch = useDispatch();
  const dispatch = useCrudClienteDispatch();
  const utilStyles = useUtilStyles();

  const tiposCalculoComissoesComboItens = useSelector(states => states.selectors.tipoCalculoComissaoParametro.selector);
  const tipoCalculoComissoes = useCrudClienteSelector(states => states.controls.tipoCalculoComissoes);
  const tipoComissao = useSelector(states => states.selectors.tipoComissao.selector);

  useEffect(() => {
    globalDispatch(findTipoCalculoComissaoAction());
    globalDispatch(findRegraComissaoComercial());
    if (isInsertMode) {
      dispatch(getFaixaComissaoAction());
    }
  }, [globalDispatch, location.refreshPage, dispatch, isInsertMode]);

  const onChangeTipoComissaoHandler = (e) => {
    const { value } = e.target;
    const { id, value: description } = tiposCalculoComissoesComboItens.filter(tipo => tipo.id === value)[0];
    dispatch(updateTipoCalculoComissaoAction(id, description));
  };

  const onAddComissaoRangeHandler = useCallback(() => {
    dispatch(addComissaoAction());
  }, [dispatch]);

  const onChangeComissaoHandler = useCallback((e) => {
    const { name: fullname, value } = e.target;
    const [name, idx] = fullname.split('-');
    dispatch(updateComissaoAction(name, value, idx));
  }, [dispatch]);


  const onFocusComissaoHandler = useCallback((e) => {
    const { name: fullname } = e.target;
    const [name, idx] = fullname.split('-');
    dispatch(updateComissaoAction(name, '', idx, 'focus'));
  }, [dispatch]);


  const onRemoveComissaoRangeHandler = useCallback((e) => {
    const idxComissao = +e.currentTarget.getAttribute('idxComissao');
    dispatch(removeComissaoAction(idxComissao));
  }, [dispatch]);


  return (
    <FormWrapperWithTitle title={TITLE}>
      <Container container isFormContainer>
        <Grid24 xs={24} sm={6}>
          <Selector
            isForm
            disabled={disabled}
            label={getRequiredLabel(COMBO_BOX_TYPE, !disabled)}
            value={tipoCalculoComissoes.idTipoCalculoComissao.value}
            items={tiposCalculoComissoesComboItens}
            onChange={onChangeTipoComissaoHandler}
          />
        </Grid24>

        <Grid24>
          <AddContainer
            title={ADD_TITLE + tipoCalculoComissoes.descricaoTipoCalculoComissao.value}
            readTitle={ADD_TITLE_QUERY + tipoCalculoComissoes.descricaoTipoCalculoComissao.value}
            disabled={disabled}
            addHandler={onAddComissaoRangeHandler}
            tooltip={ADD_HINT}
            readMode={disabled}
          >
            {tipoCalculoComissoes.comissoes.map((comissao, idx) => (
              <div key={comissao.key.value}>
                <FaixaComissao
                  key={comissao.key.value}
                  type={tipoCalculoComissoes.type.value}
                  initial={comissao.valorFaixaInicial}
                  final={comissao.valorFaixaFinal}
                  porcentagemComissao={comissao.porcentagemComissao}
                  porcentagemPagtoDiretoComissao={comissao.porcentagemPagtoDiretoComissao}
                  showTrash={idx === 0}
                  disabled={disabled}
                  idxComissao={idx}
                  onChange={onChangeComissaoHandler}
                  onFocus={onFocusComissaoHandler}
                  onRemove={onRemoveComissaoRangeHandler}
                  tipoComissao={tipoComissao}
                  idTipoRegraComissaoComercial={comissao.idTipoRegraComissaoComercial}
                />
                {idx !== tipoCalculoComissoes.comissoes.length - 1 && (
                  <hr className={utilStyles.faixas__divider} />
                )}
              </div>
            ))}
          </AddContainer>
        </Grid24>

      </Container>
    </FormWrapperWithTitle>
  );
}

export default withRouter(Comissoes);
