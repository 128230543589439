export const SET_SIMPLE_RECEBIMENTO_ACTION = '@recebimentoDevedores/SET_SIMPLE_RECEBIMENTO_ACTION';
export const SET_COMMON_ACTION = '@recebimentoDevedores/SET_COMMON_ACTION';
export const SET_SIMPLE_VALUE_INFO_CARDS_ACTION = '@recebimentoDevedores/SET_SIMPLE_VALUE_INFO_CARDS_ACTION';
export const BUSCAR_INFO_CARDS = '@recebimentoDevedores/BUSCAR_INFO_CARDS';
export const SET_BUSCAR_DADOS_TABELA = '@recebimentoDevedores/SET_BUSCAR_DADOS_TABELA';
export const ON_CHANGE_RECEBIMENTOS_ACTION = '@recebimentoDevedores/ON_CHANGE_RECEBIMENTOS_ACTION';
export const ON_FOCUS_DATE_RECEBIMENTOS_ACTION = '@recebimentoDevedores/ON_FOCUS_DATE_RECEBIMENTOS_ACTION';
export const SET_FORM_FIELDS_RECEBIMENTOS_ACTION = '@recebimentoDevedores/ON_FOCUS_DATE_RECEBIMENTOS_ACTION';
export const SET_BUSCAR_DADOS_TABELA_FILTRO = '@recebimentoDevedores/SET_BUSCAR_DADOS_TABELA_FILTRO';
export const SET_BUSCAR_DADOS_TABELA_FILTRO_SIMPLES = '@recebimentoDevedores/SET_BUSCAR_DADOS_TABELA_FILTRO_SIMPLES';
export const RESET_FORM_FIELDS_ACTION = '@recebimentoDevedores/RESET_FORM_FIELDS_ACTION';
export const SET_ATUALIZAR_PARCELA = '@recebimentoDevedores/SET_ATUALIZAR_PARCELA';
export const ATUALIZAR_PARCELAS_ACTION = '@recebimentoDevedores/ATUALIZAR_PARCELAS_ACTION';
export const ATUALIZAR_PARCELAS_LOTE_ACTION = '@recebimentoDevedores/ATUALIZAR_PARCELAS_LOTE_ACTION';
export const SET_IMPRIMIR_PARCELAS = '@recebimentoDevedores/SET_IMPRIMIR_PARCELAS';
export const SET_EXPORTAR = '@recebimentoDevedores/SET_EXPORTAR';
export const ON_CHANGE_RECEBIMENTOS_MANUAIS_ACTION = '@recebimentoDevedores/ON_CHANGE_RECEBIMENTOS_MANUAIS_ACTION';
export const SET_SIMPLE_VALUE_ARQUIVO_ACTION = '@recebimentoDevedores/SET_SIMPLE_VALUE_ARQUIVO_ACTION';
export const IMPORTAR_COMPROVANTE_ACTION = '@recebimentoDevedores/IMPORTAR_COMPROVANTE_ACTION';
export const CLICK_COMPROVANTE_ACTION = '@recebimentoDevedores/CLICK_COMPROVANTE_ACTION';
export const SET_ERRO_EM_CAMPO_ACTION = '@recebimentoDevedores/SET_ERRO_EM_CAMPO_ACTION';
export const ON_FOUCUS_RECEBIMENTOS_MANUAIS_ACTION = '@recebimentoDevedores/ON_FOUCUS_RECEBIMENTOS_MANUAIS_ACTION';
export const SET_SIMPLE_VALUE_RECEBIMENTO_DISTRIBUICAO = '@recebimentoDevedores/SET_SIMPLE_VALUE_RECEBIMENTO_DISTRIBUICAO';
export const SUBMIT_DISTRIBUICAO_MANUAL_ACTION = '@recebimentoDevedores/SUBMIT_DISTRIBUICAO_MANUAL_ACTION';
export const BUSCAR_ULTIMA_DATA_HORA_ATUALIZACAO_ACTION = '@recebimentoDevedores/BUSCAR_ULTIMA_DATA_HORA_ATUALIZACAO_ACTION';
