import { makeErrorField } from '../../../../helpers';
import FieldModel from '../../../../models/FieldModel';
import {
  buscarEmailsPadraoDevedorAcordo, buscarParametrosEmailTermoAcordo, enviarEmailsTermoAcordo,
} from '../../../../services/core/processos/processosAcordosService';

class DialogEnviarParcelaAcordoController {
  makeFormFields() {
    return ({
      emailList: new FieldModel({
        name: 'emailList',
        value: '',
      }),
    });
  }

  makeErrorFields() {
    return {
      emailList: makeErrorField(),
    };
  }

  makePayload(emailList, idAcordo, nroParcela, tipoFormaPagto) {
    return {
      idAcordo: idAcordo || 0,
      nroParcela: nroParcela || 0,
      tipoFormaPagto: tipoFormaPagto || '',
      emailList: emailList || '',
    };
  }

  enviarEmails(idAcordo, nroParcela, tipoFormaPagto, formFields) {
    return buscarParametrosEmailTermoAcordo(
      idAcordo,
      {
        emailList: formFields.emailList.value,
        nroParcela,
      },
    ).then(response => enviarEmailsTermoAcordo(response.data, 'parcela'));
  }

  buscarEmailsPadrao(idAcordo) {
    return buscarEmailsPadraoDevedorAcordo(idAcordo);
  }
}

export const dialogEnviarParcelaAcordoController = new DialogEnviarParcelaAcordoController();
