import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Telefones from '../../../UI/TelefonesV2/Telefones';

import { setTelefoneAttributeFocusAction, setTelefonesAction, setTelefoneAttributeValueAction } from '../../../../store/Filial/Crud/crudFilial.store';
import { findTiposTelefoneAction } from '../../../../store/Global/LoadSelectors/loadSelectors.saga';
import { useCrudFilialSelector, useCrudFilialDispatch } from '../../../../store/Filial/Crud/CrudFilialProvider';

import useUtilStyles from '../../../../helpers/styles/utils.styles';


function TelefoneCrudFilial({ isQueryMode, location }) {
  const utilStyles = useUtilStyles();

  const dispatch = useCrudFilialDispatch();
  const globalDispatch = useDispatch();

  const telefones = useCrudFilialSelector(states => [...states.requestFields.telefones]);
  const tiposTelefone = useSelector(states => states.selectors.tiposTelefone.selector);

  useEffect(() => {
    globalDispatch(findTiposTelefoneAction());
  }, [globalDispatch, location.refreshPage]);

  const setTelefoneAttributeValueHandler = useCallback((name, value, index) => {
    dispatch(setTelefoneAttributeValueAction(name, value, index));
  }, [dispatch]);

  const setTelefoneAttributeFocusHandler = useCallback((name, index) => {
    dispatch(setTelefoneAttributeFocusAction(name, index));
  }, [dispatch]);


  const setTelefonesHandler = useCallback((telefonesParam) => {
    dispatch(setTelefonesAction(telefonesParam));
  }, [dispatch]);

  return (
    <Telefones
      validaWhatsApp
      className={utilStyles.mt16}
      disabled={isQueryMode}
      readMode={isQueryMode}
      telefones={telefones}
      tiposTelefone={tiposTelefone}
      setAttribute={setTelefoneAttributeValueHandler}
      setFocus={setTelefoneAttributeFocusHandler}
      setTelefones={setTelefonesHandler}
      requiredFields={{
        tipoTelefone: !isQueryMode,
        numero: !isQueryMode,
      }}
    />
  );
}

export default withRouter(TelefoneCrudFilial);
