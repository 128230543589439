export const DADOS_BANCARIOS_TITLE = 'Dados Bancários';
export const CNPJ_TITLE = 'CNPJ';
export const CPF_TITLE = 'CPF';
export const FAVORECIDO_TITLE = 'Favorecido';
export const TIPO_DOCUMENTO_TITLE = 'Tipo Documento';
export const BANCO_TITLE = 'Banco';
export const AGENCIA_TITLE = 'Agência';
export const CONTA_TITLE = 'Conta';
export const TIPO_CONTA_TITLE = 'Tipo Conta';
export const COMPLEMENTO_TITLE = 'Complemento';
