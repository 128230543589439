class TabUiController {
  makeItem(name, props = {}) {
    return {
      name: String(name),
      ...props,
    };
  }
}

export const tabUiController = new TabUiController();
