import React from 'react';
import { IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';


function CollapseTableButtonComponent({ isOpen, ...rest }) {
  return (
    <IconButton size="small" style={{ color: '#034ddc' }} {...rest}>
      {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );
}

export const CollapseTableButton = CollapseTableButtonComponent;
