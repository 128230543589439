import React from 'react';
import cx from 'classnames';

import { buildTitulo } from '../../../helpers/factory/titulos.factory';

import Titulo from './Titulo/Titulo';
import AddActionTitleWrapper from '../Wrapper/AddActionTitle';

import useStyles from './Titulos.styles';
import useUtilStyles from '../../../helpers/styles/utils.styles';

const constants = {
  TITLE_READ: 'Títulos',
  TITLE_EDIT: 'Adicionar Títulos',
  TOOLTIP: 'Adicionar Títulos',
};

export default function Titulos({
  className = '',
  titulos = [],
  tiposDocumento = [],
  disabled = false,
  readMode = false,
  setFocus,
  setAttribute,
  setTitulos,
  requiredFields = {},
}) {
  const utilStyles = useUtilStyles();
  const styles = useStyles();

  const addTituloHandler = () => {
    const modifiedTitulos = [...titulos];
    const titulo = buildTitulo();
    modifiedTitulos.push(titulo);
    setTitulos(modifiedTitulos);
  };

  const removeTelefoneHandler = (index) => {
    const modifiedTitulos = [...titulos];
    modifiedTitulos.splice(index, 1);
    setTitulos(modifiedTitulos);
  };

  const showTrashAtFirstItemHandler = index => index !== 0;

  const buildTelefoneComponent = (titulo, index) => (
    <div key={index}>
      <Titulo
        hasTrash={showTrashAtFirstItemHandler(index)}
        index={index}
        disabled={disabled}
        tiposDocumento={tiposDocumento}
        model={titulo}
        setAttribute={setAttribute}
        setFocus={setFocus}
        requiredFields={requiredFields}
        onRemove={() => removeTelefoneHandler(index)}
      />
      {index !== titulos.length - 1 && (
        <hr className={cx(utilStyles.dividerTitulo, { [styles.dividerTitulo]: showTrashAtFirstItemHandler(index) })} />
      )}
    </div>
  );

  return (
    <>
      <AddActionTitleWrapper
        title={constants.TITLE_EDIT}
        readTitle={constants.TITLE_READ}
        disabled={disabled}
        addHandler={addTituloHandler}
        tooltip={constants.TOOLTIP}
        readMode={readMode}
        className={className}
      >
        <div className={styles.wrapper}>
          {titulos.map(buildTelefoneComponent)}
        </div>
      </AddActionTitleWrapper>
    </>
  );
}
