import { TableCell, TableRow } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { EmissaoAvulsaIcon } from '../../../assets/Icons/EmissaoAvulsaNFIcon';
import OrdenacaoColuna from '../../UI/Tabela/Ordenacao/OrdenacaoColuna';
import { setSimpleValueDadosGeracaoNFAction } from '../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { pesquisarDadosGeracaoNotaFiscalAction } from '../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';

function TableHeaderDadosGeracaoNFComponent() {
  const dispatch = useCCClienteDispatch();
  const columnOrdenacao = useCCClienteSelector(states => states.dadosGeracaoNF.columnOrdenacao);
  const arrowDirection = useCCClienteSelector(states => states.dadosGeracaoNF.arrowDirection);
  const dadosGeracaoNFData = useCCClienteSelector(states => states.dadosGeracaoNF.dadosGeracaoNFData);

  const setColumnOrdenacao = useCallback((column) => {
    dispatch(setSimpleValueDadosGeracaoNFAction('columnOrdenacao', column));
  }, [dispatch]);

  const setArrowDirection = useCallback((internalReturn) => {
    dispatch(setSimpleValueDadosGeracaoNFAction('arrowDirection', internalReturn));
  }, [dispatch]);

  const onClickColumnOrdenar = useCallback(() => {
    if (dadosGeracaoNFData.length > 0) {
      dispatch(setSimpleValueDadosGeracaoNFAction('page', 0));
      dispatch(pesquisarDadosGeracaoNotaFiscalAction());
    }
  }, [dispatch, dadosGeracaoNFData]);


  return (
    <TableRow>
      <TableCell height={36} width={77}>
        ID
      </TableCell>
      <TableCell height={36} width={69}>
        <EmissaoAvulsaIcon fill="blue" />
      </TableCell>
      <TableCell height={36} width={108}>
        <OrdenacaoColuna
          columnLabel="Data"
          idColumn="data"
          arrowOrdenacao={arrowDirection}
          setArrowStoreFunction={setArrowDirection}
          columnOrdenacao={columnOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => onClickColumnOrdenar()}
        />
      </TableCell>
      <TableCell height={36} width={253}>
        Lido por
      </TableCell>
      <TableCell height={36} width={112}>
        Data da Leitura
      </TableCell>
      <TableCell height={36} width={180}>
        Prefeitura
      </TableCell>
      <TableCell height={36} width={214}>
        Valor Total das Notas Fiscais
      </TableCell>
      <TableCell height={36} width={130}>
        Ações
      </TableCell>
    </TableRow>
  );
}
export const TableHeaderDadosGeracaoNF = TableHeaderDadosGeracaoNFComponent;
