import React from 'react';

import LoadingUI from '../../components/UI/Loading/LoadingUI';
import PaperUI from '../../components/UI/Paper/PaperUI';

import useStyles from './CardLayout.styles';

function CardLayout({
  children, showLoading = false,
}) {
  const styles = useStyles();

  return (
    <>
      <LoadingUI show={showLoading} />
      <PaperUI className={styles.root}>
        {children}
      </PaperUI>
    </>
  );
}

export default CardLayout;
