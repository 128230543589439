import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { AccordionDetails } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Container, Root } from './contaCorrenteClienteStyles';
import {
  useCCClienteSelector,
} from '../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { ContaCorrentePesquisaClienteComponent } from './Pesquisa/ContaCorrentePesquisaCliente';
import { Accordion } from '../../../../components/UI/Inspecionar/Accordion/Accordion';
import TabCardLayout from '../../../../layouts/TabCardLayout/v2/TabCardLayout';
import LoadingUI from '../../../../components/UI/Loading/LoadingUI';
import TabPanel from '../../../../components/UI/TabPanel/TabPanel';
import TabModel from '../../../../models/TabModel';
import { ContaCorrenteDetalhesClienteComponent } from './Detalhes/ContaCorrenteDetalhesCliente';
import { LancamentosNaoPrestados } from './Accordions/NaoPrestados/LancamentosNaoPrestados';
import RelacaoPrestacao from './Accordions/RelacaoPrestacao/RelacaoPrestacao';
import RepasseComponent from './Repasse/RepasseComponent';
import AutorizacoesRepasses from './Repasse/Accordion/AutorizacoesRepasses';
import RecebimentosComponent from './Recebimentos/RecebimentosComponent';
import RpsEmitidos from './Recebimentos/Accordions/RpsEmitidos/RpsEmitidos';
import DadosGeracaoNF from './Recebimentos/Accordions/DadosGeraçãoNotaFiscal/DadosGeracaoNF';


function ContaCorrenteClienteComponent({ idCliente, nossoNumeroBoleto }) {
  const loadingPage = useCCClienteSelector(states => states.common.loadingPage);
  const loadingPesquisarDadosBoletoPrestacao = useCCClienteSelector(states => states.common.loadingPesquisarDadosBoletoPrestacao);
  const loadingPesquisarNotasFiscais = useCCClienteSelector(states => states.common.loadingPesquisarNotasFiscais);
  const loadingPesquisarRpsEmitidos = useCCClienteSelector(states => states.common.loadingPesquisarRpsEmitidos);
  const loadingPesquisarDadosGeracaoNF = useCCClienteSelector(states => states.common.loadingPesquisarDadosGeracaoNF);
  const loadingPesquisarRecebimentos = useCCClienteSelector(states => states.common.loadingPesquisarRecebimentos);
  const loadingPesquisarTiposLancamentosNaoPrestados = useCCClienteSelector(states => states.common.loadingPesquisarTiposLancamentosNaoPrestados);
  const loadingPesquisarListaAutorizacaoRepasse = useCCClienteSelector(states => states.common.loadingPesquisarListaAutorizacaoRepasse);
  const loadingPesquisarRepasse = useCCClienteSelector(states => states.common.loadingPesquisarRepasse);
  const loadingDefinirValorRepasse = useCCClienteSelector(states => states.common.loadingDefinirValorRepasse);
  const loadingPesquisarPagamentos = useCCClienteSelector(states => states.common.loadingPesquisarPagamentos);
  const loadingPesquisarLancamentos = useCCClienteSelector(states => states.common.loadingPesquisarLancamentos);
  const loadingPesquisarLancamentosNaoPrestados = useCCClienteSelector(states => states.common.loadingPesquisarLancamentosNaoPrestados);

  const permissoesUsuario = useSelector(states => (states.authentication.userInformation ? states.authentication.userInformation.permissoes : []));
  const [activeTab, setActiveTab] = useState(0);


  const [tabItens] = useState([
    new TabModel('tab-prestacoes-conta', 0, 'Prestações de Contas', 'scrollable-auto-tabpanel'),
    new TabModel('tab-repasse', 1, 'Repasses', 'scrollable-auto-tabpanel'),
    new TabModel('recebimentos', 2, 'Recebimentos', 'scrollable-auto-tabpanel'),
    // new TabModel('tab-nota-fiscal', 2, 'Nota Fiscal', 'scrollable-auto-tabpanel'),
    // new TabModel('tab-gerar-boleto', 3, 'Gerar Boleto', 'scrollable-auto-tabpanel'),
    // new TabModel('tab-autorizacao-prestacao', 4, 'Autorização de Prestação', 'scrollable-auto-tabpanel'),
  ]);

  const changeTabViewHandler = useCallback((activeTabParam) => {
    setActiveTab(activeTabParam);
  }, [setActiveTab]);

  const [permissoes] = useState({
    consultar: permissoesUsuario?.includes('ROLE_1801'),
    consultarTodos: permissoesUsuario?.includes('ROLE_1802'),
    efetuarLancamentos: permissoesUsuario?.includes('ROLE_1803'),
    prestarContas: permissoesUsuario?.includes('ROLE_1804'),
    baixarVisualizarSispagPlanilha: permissoesUsuario?.includes('ROLE_1901'),
  });

  return (
    <Container>
      <LoadingUI show={loadingPage
        || loadingPesquisarDadosBoletoPrestacao
        || loadingPesquisarNotasFiscais
        || loadingPesquisarRpsEmitidos
        || loadingPesquisarDadosGeracaoNF
        || loadingPesquisarRecebimentos
        || loadingPesquisarTiposLancamentosNaoPrestados
        || loadingPesquisarListaAutorizacaoRepasse
        || loadingPesquisarRepasse
        || loadingDefinirValorRepasse
        || loadingPesquisarPagamentos
        || loadingPesquisarLancamentos
        || loadingPesquisarLancamentosNaoPrestados}
      />
      <TabCardLayout
        tabItems={tabItens}
        parentStates={[activeTab, changeTabViewHandler]}
      />
      <TabPanel id={tabItens[0].id} index={tabItens[0].index} value={activeTab}>
        <Root>
          <ContaCorrentePesquisaClienteComponent idClientePage={idCliente} nossoNumeroBoletoPage={nossoNumeroBoleto} />
          <ContaCorrenteDetalhesClienteComponent permissoes={permissoes} />
        </Root>
        <div>
          <Accordion
            style={{ marginBottom: '11px' }}
            color="purePurple"
            title="Lançamentos Não Prestados"
          >
            <AccordionDetails>
              <LancamentosNaoPrestados permissoes={permissoes} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ marginBottom: '11px' }}
            color="purePurple"
            title="Relação de Prestações"
          >
            <AccordionDetails>
              <RelacaoPrestacao permissoes={permissoes} />
            </AccordionDetails>
          </Accordion>
          <AccordionDetails />
        </div>
      </TabPanel>

      <TabPanel id={tabItens[1].id} index={tabItens[1].index} value={activeTab}>
        <RepasseComponent
          permissoes={permissoes}
        />
        <Accordion
          style={{ marginBottom: '11px' }}
          color="purePurple"
          title="Autorizações de Repasse"
        >
          <AccordionDetails>
            <AutorizacoesRepasses permissoes={permissoes} />
          </AccordionDetails>
        </Accordion>
      </TabPanel>
      <TabPanel id={tabItens[2].id} index={tabItens[2].index} value={activeTab}>
        <RecebimentosComponent permissoes={permissoes} />
        <Accordion
          style={{ marginBottom: '11px' }}
          color="purePurple"
          title="RPS Emitidos"
        >
          <AccordionDetails>
            <RpsEmitidos permissoes={permissoes} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ marginBottom: '11px' }}
          color="purePurple"
          title="Dados para Geração das Notas Fiscais"
        >
          <AccordionDetails>
            <DadosGeracaoNF permissoes={permissoes} />
          </AccordionDetails>
        </Accordion>
      </TabPanel>

    </Container>
  );
}

export const ContaCorrenteCliente = withRouter(ContaCorrenteClienteComponent);
