import { makeStyles } from '@material-ui/core/styles';
import { blue } from '../../../../helpers/constants/colors.constants';

export default makeStyles({
  root: {
    '& .MuiInputBase-root': {
      fontSize: '0.865rem',
    },
    '& .MuiFormLabel-root': {
      fontSize: '0.75rem',
      color: '#707070',
      marginBottom: '4px',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px) scale(0.857)',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: blue,
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#e91b1b !important',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: '#707070',
    },
    '& .MuiInput-underline::after': {
      borderBottom: `2px solid ${blue}`,
    },
    '& .MuiInput-underline.Mui-error::after': {
      borderBottom: '2px solid #e91b1b',
    },
  },
  centralizeText: {
    textAlign: 'center',
    '& p': {
      fontSize: '.865rem',
      marginTop: '10px',
    },
  },
});
