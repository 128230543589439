import React from 'react';
import { toBrazilianDate } from '../../../helpers/utils/utils';
import TooltipUI from '../Tooltip/Tooltip';
import useStyles from './tempoFaseAmpulheta.styles';
import WhiteCircleIcon from '../Icon/WhiteCircleIcon';

function TempoFaseAmpulheta({ corAlertaFase, dataVencimento }) {
  const styles = useStyles();
  const getHintTempoDentroFase = () => `Vencimento do processo na fase: ${toBrazilianDate(dataVencimento)}`;

  const getIconAmpulheta = () => {
    if (corAlertaFase === 'M') {
      return (<i className="fas fa-hourglass-end" />);
    }
    if (corAlertaFase === 'V') {
      return (<i className="fas fa-hourglass-start" />);
    }
    return (<i className="fas fa-hourglass-half" />);
  };

  return (
    <WhiteCircleIcon>
      <TooltipUI title={getHintTempoDentroFase()}>
        <div className={styles[corAlertaFase]}>
          {getIconAmpulheta(corAlertaFase)}
        </div>
      </TooltipUI>
    </WhiteCircleIcon>
  );
}

export default TempoFaseAmpulheta;
