import { getParametrosInterno } from '../../../services/core/parametro/parametro.services';
import { atualizaDataHoraOnline, getSerComercial } from '../../../services/core/usuario/usuario.services';

let intervalId = null;

function iniciaTimerControleStatusComercial(idUsuario, intervaloUsuarioOnline) {
  // Chama o backEnd para atualizar o horario para atual assim que faz o login
  atualizaDataHoraOnline(idUsuario);
  intervalId = setInterval(() => {
    // Chama o backEnd para atualizar o horario para atual a cada intervalo
    atualizaDataHoraOnline(idUsuario);
  }, (intervaloUsuarioOnline * 60 * 1000));
}

export function* timerControleStatusComercial(idUsuario) {
  const accessToken = yield JSON.parse(localStorage.getItem('userData')).accessToken;


  // Chama o backend para verificar se o usuario é do tipo Comercial
  const serComercialResponse = yield getSerComercial(idUsuario, accessToken);

  if (serComercialResponse.data) { // TODO se for comercial
    // TODO buscar intervalo no parametro interno.
    const parametrosInternoResponse = yield getParametrosInterno();

    iniciaTimerControleStatusComercial(idUsuario, parametrosInternoResponse.data.intervaloUsuarioOnline);
  }
}

export function finalizaTimerControleStatusComercial() {
  if (intervalId != null) {
    // Interrompe o interval que atualiza o status do usuario
    clearInterval(intervalId);
  }
}
