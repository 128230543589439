class AcaoAnotarController {
  makeDefaultErrorsFields() {
    return {
      idTipoAcompanhamento: {
        error: false,
        errorMessage: '',
      },
      texto: {
        error: false,
        errorMessage: '',
      },
    };
  }
}

export const acaoAnotarController = new AcaoAnotarController();
