import React, {
  useCallback, useState, useEffect, useMemo,
} from 'react';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Grid24 from '../../../../UI/Grid24';
import SelectorField from '../../../../UI/Field/Selector/SelectorField';
import { enviarProcessosOutraFaseController } from './EnviarProcessosOutraFaseController';

import { findFasesEnviarProcessos } from '../../../../../services/core/fase/fase.services';
import SelectorModel from '../../../../../models/SelectorModel';
import { findMotivosBaixaAction, findNegociadoresExternosAtivosAction, findPastasFaseAction } from '../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import CheckboxField from '../../../../UI/Field/Checkbox/CheckboxField';
import { buildPayload, getValue } from '../../../../../helpers';
import { atualiarFaseMultiplosProcessosService } from '../../../../../services/core/processos/processosFaseService';


export default function DialogEnviarProcessosOutraFase({
  processosSelecionados,
  faseProcessos,
  processosMesmaFase,
  setOpenDialog,
  processosPermitemFaseCobrancaPresencial,
  processosTemAgendamento,
  processosTemRequisicao,
  simpleSearchFormRef,
}) {
  const dispatch = useDispatch();

  const [formFields, setFormFields] = useState(enviarProcessosOutraFaseController.buildInitialStatesEnviarProcessosOutraFase());


  const [fases, setFases] = useState([]);

  const fasesItemsSelector = useMemo(() => fases.map(fase => (new SelectorModel(fase.idFase, fase.nome))), [fases]);

  const negociadoresExternosAtivosSelectorItems = useSelector(states => states.selectors.negociadoresExternosAtivosFase.selector);
  const fasePastasSelectorItems = useSelector(states => states.selectors.pastasDaFase.selector);
  const motivosBaixaSelectorItems = useSelector(states => states.selectors.motivosBaixa.selector);


  // Show Selectors
  const [showNegociadoresExternosSelector, setShowNegociadoresExternosSelector] = useState(false);
  const [showPastasSelector, setShowPastasSelector] = useState(false);
  const [showMotivosSelector, setShowMotivosSelector] = useState(false);


  const handleChangeForm = useCallback((event) => {
    const {
      name, value, checked, type,
    } = event.target;
    if (name === 'idFase') {
      const faseInfo = fases.find(fase => fase.idFase === value);
      if (faseInfo.idTipoNegociador === 3) {
        setShowNegociadoresExternosSelector(true);
        dispatch(findNegociadoresExternosAtivosAction(faseInfo.idFase));
      } else {
        setShowNegociadoresExternosSelector(false);
      }
      if (faseInfo.numPastas > 0) {
        setShowPastasSelector(true);
        if (processosMesmaFase) {
          if (value === faseProcessos) {
            dispatch(findPastasFaseAction(value, null));
          } else {
            dispatch(findPastasFaseAction(value, 'S'));
          }
        } else {
          dispatch(findPastasFaseAction(value, 'S'));
        }
      } else {
        setShowPastasSelector(false);
      }
      if (faseInfo.exigirMotivo === 'S') {
        setShowMotivosSelector(true);
        dispatch(findMotivosBaixaAction());
      } else {
        setShowMotivosSelector(false);
      }
    }
    setFormFields((fields) => {
      fields[name].value = getValue(value, checked, type);
      const newForms = { ...fields };
      return newForms;
    });
  }, [dispatch, fases, faseProcessos, processosMesmaFase]);


  const onClickCancelar = useCallback(() => {
    setOpenDialog(false);
  }, [setOpenDialog]);

  const onSubmitHandler = useCallback((e) => {
    e.preventDefault();
    const payloadForms = buildPayload(formFields);
    const payload = { processos: processosSelecionados, ...payloadForms };
    atualiarFaseMultiplosProcessosService(payload).then(() => {
      setOpenDialog(false);
      simpleSearchFormRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true, preventDefault: true }));
      toast.success('Ações realizadas com sucesso!');
    }).catch((err) => {
      const { validations } = err.response.data;
      if (validations) {
        const newForm = validations.reduce((f, erro) => {
          const newField = f[erro.field].updateErrors(erro.message);
          return { ...f, [erro.field]: newField };
        }, { ...formFields });
        setFormFields(newForm);
      } else if (err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error('Não foi possível alterar as fases.');
      }
    });
  }, [formFields, processosSelecionados, setOpenDialog, simpleSearchFormRef]);

  useEffect(() => {
    findFasesEnviarProcessos(processosPermitemFaseCobrancaPresencial).then((response) => {
      setFases(response.data);
    });
  }, [processosPermitemFaseCobrancaPresencial]);

  useEffect(() => {
    if (showNegociadoresExternosSelector === false) {
      setFormFields((fields) => {
        fields.idNegociador.value = '';
        return fields;
      });
    }
  }, [showNegociadoresExternosSelector]);

  useEffect(() => {
    if (showPastasSelector === false) {
      setFormFields((fields) => {
        fields.idFasePasta.value = '';
        return fields;
      });
    }
  }, [showPastasSelector]);

  useEffect(() => {
    if (showMotivosSelector === false) {
      setFormFields((fields) => {
        fields.idMotivoBaixa.value = '';
        return fields;
      });
    }
  }, [showMotivosSelector]);

  return (

    <form onSubmit={onSubmitHandler}>
      <Grid24 container isFormContainer spacing={1}>
        <Grid24 xs={24} sm={10}>
          <SelectorField
            isForm
            label="Fases*"
            name={formFields.idFase.name}
            value={formFields.idFase.value}
            items={fasesItemsSelector}
            error={formFields.idFase.error}
            errorMessage={formFields.idFase.errorMessage}
            onChange={handleChangeForm}
          />
        </Grid24>
        { showNegociadoresExternosSelector
        && (
        <Grid24 xs={24} sm={14}>
          <SelectorField
            isForm
            label="Negociador Externo*"
            name={formFields.idNegociador.name}
            value={formFields.idNegociador.value}
            items={negociadoresExternosAtivosSelectorItems}
            error={formFields.idNegociador.error}
            errorMessage={formFields.idNegociador.errorMessage}
            onChange={handleChangeForm}
          />
        </Grid24>
        )
}
        {
  showPastasSelector && fasePastasSelectorItems.length > 0
        && (
        <Grid24 xs={24} sm={14} style={{ marginTop: showNegociadoresExternosSelector ? '10px' : '0px' }}>
          <SelectorField
            isForm
            label="Pasta"
            name={formFields.idFasePasta.name}
            value={formFields.idFasePasta.value}
            items={fasePastasSelectorItems}
            error={formFields.idFasePasta.error}
            errorMessage={formFields.idFasePasta.errorMessage}
            onChange={handleChangeForm}
          />
        </Grid24>
        )

 }
        {
  showMotivosSelector && motivosBaixaSelectorItems.length > 0
   && (
   <Grid24 xs={24} sm={10} style={{ marginTop: showNegociadoresExternosSelector || showPastasSelector ? '10px' : '0px' }}>
     <SelectorField
       isForm
       label="Motivo*"
       name={formFields.idMotivoBaixa.name}
       value={formFields.idMotivoBaixa.value}
       items={motivosBaixaSelectorItems}
       error={formFields.idMotivoBaixa.error}
       errorMessage={formFields.idMotivoBaixa.errorMessage}
       onChange={handleChangeForm}
     />
   </Grid24>
   )
 }
        <Grid24 xs={24} sm={24} style={{ marginTop: '10px', marginLeft: '10px' }}>
          <CheckboxField
            label="Liberar Requisições de Processos"
            name="liberarRequisicao"
            checked={!processosTemRequisicao ? false : formFields.liberarRequisicao.value}
            onChange={handleChangeForm}
            disabled={!processosTemRequisicao}
          />
          <CheckboxField
            label="Liberar compromissos das Agendas dos Processos"
            name="liberarCompromissos"
            checked={!processosTemAgendamento ? false : formFields.liberarCompromissos.value}
            onChange={handleChangeForm}
            disabled={!processosTemAgendamento}
          />
        </Grid24>
        <Grid24 xs={24} sm={24} style={{ marginTop: '10px', marginLeft: '10px' }}>
          <CheckboxField
            label="Recalcular o valor dos Processos"
            name="recalcularValorProcesso"
            checked={formFields.recalcularValorProcesso.value}
            onChange={handleChangeForm}
          />
        </Grid24>
        <div style={{
          display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: '22px',
        }}
        >
          <Button
            onClick={onClickCancelar}
          >
            Cancelar
          </Button>
          <Button
            disabled={!formFields.idFase.value
              || (showNegociadoresExternosSelector && negociadoresExternosAtivosSelectorItems.length > 0 && !formFields.idNegociador.value)
              || (showMotivosSelector && motivosBaixaSelectorItems.length > 0 && !formFields.idMotivoBaixa.value)}
            type="submit"
          >
            Confirmar
          </Button>
        </div>
      </Grid24>
    </form>
  );
}
