import React from 'react';

import { useDispatch } from 'react-redux';

import CrudFilial from '../../../components/Filial/Crud/CrudFilial';

import { initializePageAction } from '../../../store/theme.actions';
import withTokenInterceptor from '../../../hoc/withTokenInterceptor/withTokenInterceptor';

import constants from '../../../helpers/constants/navigationTitle.constants';
import CrudFilialProvider from '../../../store/Filial/Crud/CrudFilialProvider';


function DetalharFilialPage() {
  const dispatch = useDispatch();
  dispatch(initializePageAction(`${constants.FILIAL}  ${constants.SEPARADOR}  ${constants.DADOS}`));

  return (
    <CrudFilialProvider>
      <CrudFilial isQueryMode />
    </CrudFilialProvider>
  );
}

export default withTokenInterceptor(DetalharFilialPage);
