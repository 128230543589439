import React from 'react';
import cx from 'classnames';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  CircularProgress, FormControl, InputLabel, ListItemText, MenuItem, Select,
} from '@material-ui/core';

import TooltipUI from '../../Tooltip/Tooltip';
import useStyles from './SelectorField.styles';
import CheckboxField from '../Checkbox/CheckboxField';

function SelectorField({
  className,
  label,
  isNative = false,
  hasEmptyLabel = false,
  items = [],
  loading = false,
  disabled = false,
  isForm = false,
  error = false,
  fullWidth = true,
  errorMessage = '',
  tooltipProps,
  labelProps,
  isCheckedItems = false,
  ...props
}) {
  const styles = useStyles();
  function buildEmptyNativeLabel() {
    if (!hasEmptyLabel) {
      return null;
    }
    return (
      <option value="" classes={{ root: styles.menuItemRoot }} />
    );
  }

  function buildEmptyLabel() {
    if (!hasEmptyLabel) {
      return null;
    }
    return (
      <MenuItem value="" classes={{ root: styles.menuItemRoot }}>
        <pre> </pre>
      </MenuItem>
    );
  }

  function generateItemsForMultLineSelect() {
    return items.map(item => (
      <MenuItem key={item.id} value={item.id} classes={{ root: styles.menuItemRoot }}>
        <CheckboxField checked={items.includes(item.id)} />
        <ListItemText primary={item.value} />
      </MenuItem>
    ));
  }

  function buildSelectorNativeContent() {
    return isCheckedItems ? generateItemsForMultLineSelect()
      : items.map(item => (
        <option key={item.id} value={item.id} classes={{ root: styles.menuItemRoot }}>
          {item.value}
        </option>
      ));
  }

  function buildSelectorContent() {
    return isCheckedItems ? generateItemsForMultLineSelect()
      : items.map(item => (
        <MenuItem key={item.id} value={item.id} classes={{ root: styles.menuItemRoot }}>
          {item.value}
        </MenuItem>
      ));
  }

  function getEndAdornment() {
    return loading ? CircularProgress : ArrowDropDownIcon;
  }

  function buildInputNativeGroup() {
    return (
      <>
        <InputLabel error={error} {...labelProps}>{label}</InputLabel>
        <Select
          native
          error={error}
          disabled={disabled || loading}
          className={cx({ [styles.error]: error, [styles.disabled]: disabled })}
          MenuProps={{
            MenuListProps: {
              classes: {
                root: styles.listRoot,
              },
            },
          }}
          IconComponent={getEndAdornment()}
          {...props}
        >
          {buildEmptyNativeLabel()}
          {buildSelectorNativeContent()}
        </Select>
      </>
    );
  }

  function buildInputGroup() {
    return (
      <>
        <InputLabel error={error} {...labelProps}>{label}</InputLabel>
        <Select
          error={error}
          disabled={disabled || loading}
          className={cx({ [styles.error]: error, [styles.disabled]: disabled })}
          MenuProps={{
            MenuListProps: {
              classes: {
                root: styles.listRoot,
              },
            },
          }}
          IconComponent={getEndAdornment()}
          {...props}
        >
          {buildEmptyLabel()}
          {buildSelectorContent()}
        </Select>
      </>
    );
  }

  function buildOutlinedGroup() {
    return (
      <FormControl
        error={error}
        className={
          cx(styles.outlinedRoot, className, { [styles.loading]: loading })
        }
        fullWidth
      >
        {buildInputGroup()}
      </FormControl>
    );
  }

  function buildFormInput() {
    return (
      <div
        className={
          cx(styles.formRoot, className, { [styles.fullWidth]: fullWidth, [styles.loading]: loading })
        }
      >
        {isNative ? buildInputNativeGroup() : buildInputGroup()}
      </div>
    );
  }

  function buildField() {
    const content = isForm ? buildFormInput() : buildOutlinedGroup();
    if (error) {
      return (
        <TooltipUI error title={errorMessage} placement="bottom-start" {...tooltipProps}>
          {content}
        </TooltipUI>
      );
    }
    return content;
  }

  return buildField();
}

export default React.memo(SelectorField);
