import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Grid } from '@material-ui/core';

import { FaEraser } from 'react-icons/fa';

import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import SelectorField from '../../../components/UI/Field/Selector/SelectorField';
import TextareaField from '../../../components/UI/Field/Textarea/TextareaField';
import ButtonUI from '../../../components/UI/Button/ButtonUI';

import {
  ActionHeaderContainer, CustomButton, HotizontalButtonGroup,
} from '../InspecionarProcessoStyles';

import { MainContainer, FooterContainer } from './acaoRequisicaoStyle';

import { clearErrorField, getFieldName } from '../../../helpers/utils/form.utils';

import { acaoGerarRequisicaoController as controller } from './acaoRequisicaoController';
import { loadingInspecaoProcessoController as loadingController } from '../loadingInspecaoProcessoController';

import { findQuantidadeRequisicoesAction } from '../../../store/theme.actions';
import { Title } from '../../../components/Title/Title';
import { buscarRequisicaoProcessoService } from '../../../services/core/processos/processosRequisicoesService';
import OptionDialogNew from '../../../components/UI/Dialogs/OptionDialog/OptionDialogNew';

function AcaoRequisicaoComponent({
  processo, requisicoes, updatePage, setLoadings,
}) {
  const [hasRequisicao, setHasRequisicao] = useState(null);
  const [formFields, setFormFields] = useState(controller.makeFormFields());
  const [errorFields, setErrorFields] = useState(controller.makeErrorFields());
  const [showLimparAlert, setShowLimparAlert] = useState(false);

  const dispatch = useDispatch();

  function limparFormulario() {
    setFormFields(controller.getDefaultFields(formFields, hasRequisicao));
    setErrorFields(controller.makeErrorFields());
    setShowLimparAlert(false);
  }

  async function onSubmitHandler(e) {
    e.preventDefault();
    setLoadings(loadingController.changeLoadingAction(true));
    let response = null;
    if (hasRequisicao) {
      response = await controller.atenderRequisicao(processo.id, { ...formFields, temPermissaoAtender: true }, errorFields);
    } else {
      response = await controller.gerarRequisicao(processo.id, formFields, errorFields);
    }

    if (response.success) {
      updatePage();
      toast.success('Ação realizada com sucesso!');
    }

    setErrorFields(response.errorFields);
    setLoadings(loadingController.changeLoadingAction(false));
    dispatch(findQuantidadeRequisicoesAction());
  }

  const onChangeHandler = useCallback((e) => {
    const { value } = e.target;
    const name = getFieldName(e.target);
    setFormFields(oldValue => ({ ...oldValue, [name]: value }));
  }, []);

  const onFocusHander = useCallback((e) => {
    setErrorFields(clearErrorField(errorFields, getFieldName(e.target)));
  }, [errorFields]);

  useEffect(() => {
    setLoadings(loadingController.changeLoadingAction(true));
    buscarRequisicaoProcessoService(processo.id).then((response) => {
      setFormFields(controller.makeFormFields(response?.data));
      if (response?.data?.idRequisicao) {
        setHasRequisicao(true);
      }
    }).catch(() => {
      setFormFields(controller.makeFormFields());
      setHasRequisicao(false);
    }).finally(() => {
      setLoadings(loadingController.changeLoadingAction(false));
    });
  }, [processo, setLoadings]);

  const disabledAtenderConfirmar = useMemo(() => (!formFields.idRequisicao || formFields.texto.trim().length === 0), [formFields]);

  return (
    <>
      <OptionDialogNew
        open={showLimparAlert}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickConfirm={limparFormulario}
        onClickCancel={() => setShowLimparAlert(false)}
        text="Tem certeza de que deseja descartar essas informações?"
      />
      <form onSubmit={onSubmitHandler}>
        <ActionHeaderContainer>
          <Title
            text={hasRequisicao ? 'Atendimento de Requisição' : 'Geração de Requisição'}
            hint="O Comentário será inserido no Acompanhamento do Processo"
          />

          <HotizontalButtonGroup>
            <CustomButton
              disabled={controller.isEnabledLimpar(formFields, hasRequisicao)}
              onClick={() => setShowLimparAlert(true)}
            >
              <FaEraser size={18} />
              <span>Limpar</span>
            </CustomButton>
          </HotizontalButtonGroup>
        </ActionHeaderContainer>
        <MainContainer>
          <Grid container item xs={12} sm={6}>
            <SelectorField
              isForm
              disabled={hasRequisicao}
              hasEmptyLabel
              label="Nome da Requisição*"
              name="idRequisicao"
              items={requisicoes}
              value={formFields.idRequisicao}
              onChange={onChangeHandler}
              onFocus={onFocusHander}
              error={errorFields.idRequisicao.error}
              errorMessage={errorFields.idRequisicao.errorMessage}
            />
          </Grid>
          <Grid container>
            <TextareaField
              minRows={8}
              label={hasRequisicao ? 'Resposta da Solicitação' : 'Texto'}
              name="texto"
              placeholder={hasRequisicao ? 'Inserir Resposta' : 'Inserir Texto'}
              value={formFields.texto}
              onChange={onChangeHandler}
            />
          </Grid>
        </MainContainer>
        <FooterContainer>
          <ButtonUI
            color="success"
            style={{ width: '136px' }}
            type="submit"
            disabled={disabledAtenderConfirmar}
          >
            {hasRequisicao ? 'Atender' : 'Concluir'}
          </ButtonUI>
        </FooterContainer>
      </form>
    </>
  );
}

export const AcaoRequisicao = AcaoRequisicaoComponent;
