import FieldModel from '../../../models/FieldModel';

export function getDadosBancoTipoDocumento(tipoDocumento, documento) {
  if (documento.getValueNotEmpty()) {
    return tipoDocumento.getValueNotEmpty();
  }
  return undefined;
}

export const buildPermissoes = permissoes => permissoes
  .map(permissao => permissao.acoesUsuario.filter(acao => acao.model.value === true).map(acao => acao.id))
  .flat();

const buildPermissoesConfiguraveis = permissoes => permissoes.map(data => ({
  id: data.idAcaoUsuario,
  value: data.nomeAcaoUsuario,
  model: new FieldModel({ name: `${data.idAcaoUsuario}`, value: false }),
  isCheckbox: true,
}));

export const buildRelacaoItemsConfiguraveis = response => response.map(data => ({
  id: data.idConjuntoAcaoUsuario,
  value: data.nomeConjuntoAcao,
  acoesUsuario: buildPermissoesConfiguraveis(data.acoesUsuario),
  selected: true,
}));

export const buildUsuarioComPermissoesPreenchidas = (acoes, permissoes) => {
  const newModel = acoes.model.copy();
  if (!permissoes || !permissoes.includes(acoes.id)) {
    return acoes;
  }
  newModel.value = true;
  return {
    ...acoes,
    model: newModel,
  };
};

export function getItensAtualizados(itensConfiguraveis, permissoesUsuario) {
  return itensConfiguraveis.map(item => ({
    ...item,
    acoesUsuario: item.acoesUsuario.map(
      acoes => buildUsuarioComPermissoesPreenchidas(acoes, permissoesUsuario),
    ),
  }));
}

const hasNotAcoesUsuarios = (index, relacaoPermissoes) => relacaoPermissoes.length === 0 || relacaoPermissoes.length < index;

export const getAcoesUsuarios = (index, relacaoPermissoes) => {
  if (hasNotAcoesUsuarios(index, relacaoPermissoes)) {
    return [];
  }
  return relacaoPermissoes[index].acoesUsuario;
};

const getAcoesUsuariosAposAplicar = (acaoUsuario, permissoes) => {
  const returnedAcaoUsuario = { ...acaoUsuario };
  if (permissoes.includes(acaoUsuario.id)) {
    returnedAcaoUsuario.model.value = true;
  } else {
    returnedAcaoUsuario.model.value = false;
  }
  return acaoUsuario;
};

export const buildNewRelacaoItensConfiguraveis = (itensConfiguraveis, permissoes) => itensConfiguraveis.map(item => ({
  ...item,
  acoesUsuario: item.acoesUsuario.map(acao => getAcoesUsuariosAposAplicar(acao, permissoes)),
}));

export const getPermissoesSelecionadas = relacaoItensConfiguraveis => relacaoItensConfiguraveis.map(item => item.acoesUsuario
  .filter(permissao => permissao.model.value === true)
  .map(permissao => permissao.id))
  .flat();

export const buildRelacaoPermissoes = relacaoItensConfiguraveis => relacaoItensConfiguraveis.map(item => item.acoesUsuario.map(permissao => ({
  id: permissao.id,
  item: item.value,
  acao: permissao.value,
}))).flat();

export const buildPermissoesComparadas = (relacoesPermissoes, permissoesComparadas) => permissoesComparadas.map((permissao) => {
  const relacaoPermissao = relacoesPermissoes.find(perm => perm.id === permissao.idAcaoUsuario);
  return {
    ...relacaoPermissao,
    usuario: new FieldModel({ name: undefined, value: permissao.apenasNaListaEnviada }),
    usuarioPerfil: permissao.apenasNaListaEnviada ? 'Não' : 'Sim',
  };
});

export const getNomeSelecionado = (perfilPermissaoComparar, usuarioPermissaoComparar, perfis, usuarios) => {
  if (perfilPermissaoComparar !== undefined) {
    const perfilRetornado = perfis.find(perfil => perfil.id === perfilPermissaoComparar);
    return perfilRetornado.value;
  }
  if (usuarioPermissaoComparar !== undefined) {
    const usuarioRetornado = usuarios.find(
      usuario => usuario.id === usuarioPermissaoComparar,
    );
    return usuarioRetornado.value;
  }
  return '';
};

export const getCheckedPermissaoComparada = (idPermissao, permissoesComparacao) => permissoesComparacao.find(
  permissao => permissao.id === idPermissao,
);

export function buildPermissaoComparada(permissaoComparada, model, acoes) {
  const newModel = model;
  if (permissaoComparada) {
    newModel.value = permissaoComparada.usuario.value;
  }
  return {
    ...acoes,
    model: newModel,
  };
}
