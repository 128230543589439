import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import WrapperSubTitleForm from '../../../../UI/WrapperSubTitleForm/WrapperSubTitleForm';
import ImageSelector from '../../../../UI/ImageSelector/ImageSelector';
import useStyles from './IdentificacaoCrudUsuario.styles';

import InputField from '../../../../UI/Field/Input/InputField';
import DateField from '../../../../UI/Field/Date/DateField';
import MaskField from '../../../../UI/Field/Mask/MaskField';
import Grid24 from '../../../../UI/Grid24/Grid24';
import {
  CPF,
  DATA_NASCIMENTO,
  EMAIL,
  FILIAL,
  IDENTIFICACAO_TITULO,
  MATRICULA,
  NOME,
  RG,
  STATUS_USUARIO,
} from './identificacaoCrudUsuario.constants';
import SelectorField from '../../../../UI/Field/Selector/SelectorField';
import { findFiliaisAction } from '../../../../../store/Global/LoadSelectors/loadSelectors.saga';
import {
  buildSelectorStatus,
  ID_STATUS_USUARIO_ATIVO,
  ID_STATUS_USUARIO_INATIVO,
} from '../../../../../helpers/factory/global.factory';
import useUtilStyles from '../../../../../helpers/styles/utils.styles';
import { getRequiredLabel, getYesterdayDate } from '../../../../../helpers/utils/utils';
import { useCrudUsuarioDispatch2, useCrudUsuarioSelector2 } from '../../../../../store/Usuario/V2/CrudUsuarioProvider';
import {
  configureDialogReferenteEquipeAction,
  onChangeActions,
  onChangeControls,
  setImage,
} from '../../../../../store/Usuario/V2/crudUsuario.store';
import {
  INATIVAR_GERENTE, INATIVAR_MEMBRO,
} from '../../../../../store/Usuario/V2/crudUsuario.constans';
import { findFilialPrincipalAction } from '../../../../../store/Usuario/V2/crudUsuario.saga';

function IdentificacaoUsuario({
  isEditMode = false,
  isInsertMode = false,
  disabled = false,
  reloadCombos,
  changeHandler,
  onFocusHandler,
  idTipoUsuario,
  idsTiposUsuarios,
}) {
  const styles = useStyles();
  const utilStyles = useUtilStyles();

  const dispatch = useCrudUsuarioDispatch2();
  const globalDispatch = useDispatch();

  const [statusSelector] = useState(buildSelectorStatus());

  const filiais = useSelector(states => states.selectors.filiais.selector);
  const image = useCrudUsuarioSelector2(state => state.image);
  const errorImage = useCrudUsuarioSelector2(state => state.actions.errorImage);
  const nome = useCrudUsuarioSelector2(state => state.controls.nome.copy());
  const cpf = useCrudUsuarioSelector2(state => state.controls.cpf.copy());
  const rg = useCrudUsuarioSelector2(state => state.controls.rg.copy());
  const matricula = useCrudUsuarioSelector2(state => state.controls.matricula.copy());
  const dataNascimento = useCrudUsuarioSelector2(state => state.controls.dataNascimento.copy());
  const email = useCrudUsuarioSelector2(state => state.controls.email.copy());
  const statusUsuario = useCrudUsuarioSelector2(state => state.controls.statusUsuario.copy());
  const idFilial = useCrudUsuarioSelector2(state => state.controls.idFilial.copy());

  const idsUsuariosMembros = useCrudUsuarioSelector2(state => state.controls.idsUsuariosMembros);
  const pertenceAlgumaEquipe = useCrudUsuarioSelector2(state => state.controls.pertenceAlgumaEquipe);

  const setImageHandler = useCallback(imageParam => dispatch(setImage(imageParam)), [dispatch]);

  const isGerente = useCallback(() => !isInsertMode && isEditMode && idsUsuariosMembros?.length !== 0 && !pertenceAlgumaEquipe && idTipoUsuario?.value === idsTiposUsuarios?.idComercial, [isInsertMode, isEditMode, idsUsuariosMembros, pertenceAlgumaEquipe, idTipoUsuario?.value, idsTiposUsuarios]);

  // Carrega as combos da parte de identificação
  useEffect(() => {
    globalDispatch(findFiliaisAction());
  }, [globalDispatch, reloadCombos]);

  // Carrega as combos da parte de identificação
  useEffect(() => {
    if (idFilial.value === '') {
      dispatch(findFilialPrincipalAction());
    }
  }, [idFilial.value, dispatch]);

  const needOpenDialogReferenteEquipe = useCallback((valorAlterar) => {
    if (isInsertMode) {
      return false;
    }
    if (statusUsuario.value === ID_STATUS_USUARIO_ATIVO && valorAlterar === ID_STATUS_USUARIO_INATIVO) {
      return true;
    }
    return false;
  }, [statusUsuario, isInsertMode]);

  const onChangeStatusUsuario = useCallback((event) => {
    const { name, value } = event.target;
    if (needOpenDialogReferenteEquipe(value, 'status')) {
      dispatch(configureDialogReferenteEquipeAction(true, isGerente() ? INATIVAR_GERENTE : INATIVAR_MEMBRO));
      dispatch(onChangeActions('statusUsuarioAlterar', value));
      return;
    }
    dispatch(onChangeControls(name, value));
  }, [dispatch, isGerente, needOpenDialogReferenteEquipe]);

  const onClearErrorImage = useCallback(() => {
    dispatch(onChangeActions('errorImage', false));
  }, [dispatch]);

  const onSetErrorImage = useCallback(() => {
    dispatch(onChangeActions('errorImage', true));
  }, [dispatch]);

  return (
    <Grid24 container spacing="1" className={utilStyles.mb7}>
      <Grid24 xs={24} sm={4} classes={{ root: styles.imageMargin }}>
        <ImageSelector
          disabled={disabled}
          image={[image, setImageHandler]}
          size={103424}
          maxLenghtText="(.png, .jpeg ou .jpg / máx: 100KB)"
          errorImage={errorImage}
          setErrorImage={onSetErrorImage}
          onClearErrorImage={onClearErrorImage}
          errorMessageLenght="O arquivo selecionado excedeu o tamanho máximo permitido"
          errorMessageType="O arquivo selecionado possui formato inválido"
        />
      </Grid24>
      <Grid24 container xs={24} sm={20}>
        <WrapperSubTitleForm title={IDENTIFICACAO_TITULO}>
          <Grid24 container isFormContainer spacing="1" className={utilStyles.mb7}>
            <Grid24 xs={24} sm={20}>
              <InputField
                isForm
                fullWidth
                disabled={disabled}
                label={getRequiredLabel(NOME, !disabled)}
                name={nome.name}
                value={nome.value}
                error={nome.error}
                errorMessage={nome.errorMessage}
                onChange={changeHandler}
                onFocus={onFocusHandler}
                maxLength={nome.maxLength}
              />
            </Grid24>
            <Grid24 xs={24} sm={4}>
              <SelectorField
                isForm
                disabled={disabled}
                fullWidth
                label={getRequiredLabel(STATUS_USUARIO, !disabled)}
                items={statusSelector}
                name={statusUsuario.name}
                value={statusUsuario.value}
                error={statusUsuario.error}
                errorMessage={statusUsuario.errorMessage}
                onChange={onChangeStatusUsuario}
              />
            </Grid24>
          </Grid24>
          <Grid24 container isFormContainer spacing="1">
            <Grid24 xs={24} sm={4}>
              <DateField
                isForm
                maxDate={getYesterdayDate()}
                disabled={disabled}
                fullWidth
                label={DATA_NASCIMENTO}
                name={dataNascimento.name}
                value={dataNascimento.value}
                error={dataNascimento.error}
                errorMessage={dataNascimento.errorMessage}
                onChange={changeHandler}
                onFocus={onFocusHandler}
              />
            </Grid24>
            <Grid24 xs={24} sm={4}>
              <MaskField
                isForm
                disabled={disabled}
                label={CPF}
                name={cpf.name}
                fullWidth
                value={cpf.value}
                error={cpf.error}
                errorMessage={cpf.errorMessage}
                onChange={changeHandler}
                onFocus={onFocusHandler}
                inputProps={{
                  format: '###.###.###-##',
                }}
              />
            </Grid24>
            <Grid24 xs={24} sm={4}>
              <InputField
                isForm
                fullWidth
                disabled={disabled}
                label={RG}
                name={rg.name}
                value={rg.value}
                error={rg.error}
                errorMessage={rg.errorMessage}
                onChange={changeHandler}
                maxLength={rg.maxLength}
              />
            </Grid24>
            <Grid24 xs={24} sm={4}>
              <InputField
                isForm
                fullWidth
                disabled={disabled}
                label={MATRICULA}
                name={matricula.name}
                value={matricula.value}
                error={matricula.error}
                errorMessage={matricula.errorMessage}
                onChange={changeHandler}
                maxLength={matricula.maxLength}
              />
            </Grid24>
            <Grid24 xs={24} sm={8}>
              <SelectorField
                isForm
                fullWidth
                disabled={disabled}
                label={getRequiredLabel(FILIAL, !disabled)}
                items={filiais}
                name={idFilial.name}
                value={idFilial.value}
                error={idFilial.error}
                errorMessage={idFilial.errorMessage}
                onChange={changeHandler}
              />
            </Grid24>

          </Grid24>
          <Grid24
            container
            isFormContainer
            spacing="1"
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={utilStyles.mb16}
          >
            <Grid24 xs={24} sm={24}>
              <InputField
                isForm
                fullWidth
                disabled={disabled}
                label={EMAIL}
                name={email.name}
                value={email.value}
                error={email.error}
                errorMessage={email.errorMessage}
                onChange={changeHandler}
                onFocus={onFocusHandler}
                maxLength={email.maxLength}
              />
            </Grid24>
          </Grid24>
        </WrapperSubTitleForm>
      </Grid24>
    </Grid24>
  );
}

export default React.memo(withRouter(IdentificacaoUsuario));
