import React, { useCallback } from 'react';
import cx from 'classnames';

import Telefone from './Telefone/Telefone';
import AddActionTitleWrapper from '../Wrapper/AddActionTitle';

import { buildTelefone } from '../../../helpers/factory/telefone.factory';

import useStyles from './Telefones.styles';
import useUtilStyles from '../../../helpers/styles/utils.styles';
import SubTitleForm from '../SubTitleForm/SubTitleForm';

const constants = {
  TITLE_READ: 'Telefones',
  TITLE_EDIT: 'Adicionar Telefone',
  TOOLTIP: 'Adicionar Telefone',
};

export default function Telefones({
  className = '',
  telefones = [],
  tiposTelefone = [],
  disabled = false,
  readMode = false,
  setFocus,
  setAttribute,
  setTelefones,
  showTrashAtFirstItem = false,
  requiredFields = {},
  haveAdd = true,
  subtitle = '',
  isCorrigir = false,
  validaWhatsApp = false,
}) {
  const utilStyles = useUtilStyles();
  const styles = useStyles();

  const addTelefoneHandler = () => {
    const modifiedTelefones = [...telefones];
    const telefone = buildTelefone();
    if (modifiedTelefones.length === 0) {
      telefone.principal.onChange(true);
    }
    modifiedTelefones.push(telefone);
    setTelefones(modifiedTelefones);
  };

  const removeTelefoneHandler = (index) => {
    const modifiedTelefones = [...telefones];
    const removedItem = modifiedTelefones.splice(index, 1);
    if (removedItem[0].principal.value === true && modifiedTelefones.length > 0) {
      modifiedTelefones[0].principal.value = true;
    }
    setTelefones(modifiedTelefones);
  };

  const getTelefonesWithPrincipalValueFalse = useCallback(() => telefones.map((telefone) => {
    const modifiedTelefone = { ...telefone };
    modifiedTelefone.principal.onChange(false);
    return modifiedTelefone;
  }), [telefones]);

  const getTelefonesWithWhatsAppFalse = useCallback(() => telefones.map((telefone) => {
    const modifiedTelefone = { ...telefone };
    modifiedTelefone.permitirWhatsApp.onChange(false);
    return modifiedTelefone;
  }), [telefones]);

  const showTrashAtFirstItemHandler = (index) => {
    if (showTrashAtFirstItem) {
      return index !== 0;
    }
    if (isCorrigir) {
      return false;
    }
    return true;
  };

  const buildTelefoneComponent = (telefone, index) => (
    <div key={index}>
      <Telefone
        hasTrash={showTrashAtFirstItemHandler(index)}
        index={index}
        disabled={disabled}
        tiposTelefone={tiposTelefone}
        model={telefone}
        setAttribute={setAttribute}
        setTelefones={setTelefones}
        setFocus={setFocus}
        telefonesWithPrincipalFalse={getTelefonesWithPrincipalValueFalse}
        telefonesWithWhatsAppFalse={getTelefonesWithWhatsAppFalse}
        requiredFields={requiredFields}
        onRemove={() => removeTelefoneHandler(index)}
        validaWhatsApp={validaWhatsApp}
      />
      {index !== telefones.length - 1 && (
        <hr className={cx(utilStyles.dividerTelefone, { [utilStyles.dividerTelefone]: showTrashAtFirstItemHandler(index) })} />
      )}
    </div>
  );

  return (
    <>
      {haveAdd ? (
        <AddActionTitleWrapper
          title={constants.TITLE_EDIT}
          readTitle={constants.TITLE_READ}
          disabled={disabled}
          addHandler={addTelefoneHandler}
          tooltip={constants.TOOLTIP}
          readMode={readMode}
          className={className}
        >
          <div className={styles.wrapper}>
            {telefones.map(buildTelefoneComponent)}
          </div>
        </AddActionTitleWrapper>
      ) : (
        <div className={styles.wrapper}>
          <div className={utilStyles.mb12}><SubTitleForm>{subtitle}</SubTitleForm></div>
          {telefones.map(buildTelefoneComponent)}
        </div>
      )}
    </>
  );
}
