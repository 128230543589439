import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import OrdenacaoColuna from '../UI/Tabela/Ordenacao/OrdenacaoColuna';

function TableHeaderContaCorrenteComissionadoComponent({ columnOrder, onChangeOrder }) {
  // const arrowDirection = order.order === 'DESC';
  return (
    <TableRow>
      <TableCell height={50} width={92}>
        <OrdenacaoColuna
          columnLabel="Prestação"
          idColumn="idPrestacao"
          arrowOrdenacao={columnOrder?.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder('idPrestacao')}
        />
      </TableCell>
      <TableCell height={50} width={101}>
        <OrdenacaoColuna
          columnLabel="Data"
          idColumn="data"
          arrowOrdenacao={columnOrder?.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder('data')}
        />
      </TableCell>
      <TableCell height={50} width={484}>
        <OrdenacaoColuna
          columnLabel="Histórico"
          idColumn="historico"
          arrowOrdenacao={columnOrder?.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder('historico')}
        />
      </TableCell>
      <TableCell height={50} width={134}>
        <OrdenacaoColuna
          columnLabel="Crédito"
          idColumn="valorCredito"
          arrowOrdenacao={columnOrder?.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder('valorCredito')}
        />
      </TableCell>
      <TableCell height={50} width={134}>
        <OrdenacaoColuna
          columnLabel="Débito"
          idColumn="valorDebito"
          arrowOrdenacao={columnOrder?.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder('valorDebito')}
        />
      </TableCell>
      <TableCell height={50} width={84}>
        <OrdenacaoColuna
          columnLabel="Tipo"
          idColumn="idTipoContaCorrenteUsuario"
          arrowOrdenacao={columnOrder?.arrow}
          columnOrdenacao={columnOrder}
          onClickColumn={() => onChangeOrder('idTipoContaCorrenteUsuario')}
        />
      </TableCell>
      <TableCell height={50} width={119}>Ações</TableCell>
    </TableRow>
  );
}


export const TableHeaderContaCorrenteComissionado = TableHeaderContaCorrenteComissionadoComponent;
