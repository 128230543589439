import FieldModel, { DATE, POSITIVE_INTEGER } from '../../../../models/FieldModel';
import { CATEGORIAS, tipoCalculoEnum } from '../../../../helpers/constants/enums.constants';
import {
  getAttributeExistingObject, getListIntervalCorrectAfterDeleteQuantidade, getListIntervalCorrectAfterDeleteValor, getNextValueToRange, toBrazilianDate,
} from '../../../../helpers/utils/utils';
import { buildEndereco } from '../../../../helpers/factory/endereco.factory';
import { buildTelefoneAdvogado, buildTelefones } from '../../../../helpers/factory/telefone.factory';
import { buildSimpleEmailsCliente } from '../../../../helpers/factory/email.factory';
import { buildDialog, buildOptinalDialog } from '../../../../helpers/factory/global.factory';
import {
  builClienteHonorario,
  buildClienteComissao,
  buildClienteJuros,
  buildClienteTipoCalculoComissao,
  buildClienteTipoCalculoHonorario,
  buildDefaultClienteComissaoChange,
  buildDefaultClienteHonorario,
  buildDefaultClienteJuros,
  buildDefaultClienteTipoCalculoComissao,
  buildDefaultClienteTipoCalculoHonorario,
  buildDefaultClienteTipoCalculoJurosDevedores,
  buildDefaultHonorarioContratual,
  buildHonorariosContratuais,
  buildHonorariosContratuaisCliente,
  buildJurosDosDevedores,
} from '../../../../helpers/factory/cliente.factory';
import { buildFaixasPrazoExpiracao } from '../../../../helpers/factory/faixasPrazoExpiracao.factory';
import { buildClienteDescontosPix } from '../../../../helpers/factory/descontosPix.factory';
import {
  buildClienteDescontoBoleto,
} from '../../../../helpers/factory/descontoBoleto.factory';
import { buildCampanha } from '../../../../helpers/factory/campanha.factory';


const ON_FOCUS_EMAIL = 'ON_FOCUS_EMAIL_CRUD_CLIENTE';
const ON_CHANGE_EMAIL = 'ON_CHANGE_EMAIL_CRUD_CLIENTE';
const ON_UPDATE_EMAILS = 'ON_UPDATE_EMAILS_CRUD_CLIENTE';
const ON_CHANGE_RAMOS_ATIVIDADE = 'ON_CHANGE_RAMOS_ATIVIDADE_CRUD_CLIENTE';
const ON_CHANGE_CATEGORIA = 'ON_CHANGE_CATEGORIA_CRUD_CLIENTE';
const ON_CHANGE_CONTROLS = 'ON_CHANGE_CONTROLS_CRUD_CLIENTE';
const ON_FOCUS_CONTROLS = 'ON_FOCUS_CONTROLS_CRUD_CLIENTE';
const ON_ADD_USUARIOS = 'ON_ADD_USUARIOS_CRUD_CLIENTE';
const ON_UPDATE_ALL_FORM = 'ON_UPDATE_ALL_FORM_CRUD_CLIENTE';
const ON_UPDATE_CONTROLS = 'ON_UPDATE_CONTROLS_CRUD_CLIENTE';
const ON_UPDATE_EMPRESAS_GRUPO = 'ON_UPDATE_EMPRESAS_GRUPO_CRUD_CLIENTE';
const ON_RELOAD_COMBOS_CLIENTE = 'ON_RELOAD_COMBOS_CLIENTE';
const ON_CLEAR_COMBOS_CLIENTE = 'ON_CLEAR_COMBOS_CLIENTE';
const ON_CHANGE_EMPRESA_CLIENTE_NOTA_FISCAL_CONTROLS = 'ON_CHANGE_EMPRESA_CLIENTE_NOTA_FISCAL_CONTROLS';
const ON_CHANGE_CAMPANHA_ACTION = 'ON_CHANGE_CAMPANHA_ACTION';
const ON_CHANGE_CAMPANHA_GERAL_ACTION = 'ON_CHANGE_CAMPANHA_GERAL_ACTION';
const ON_FOCUS_CAMPANHA = 'ON_FOCUS_CAMPANHA';

const SET_ENDERECO = 'SET_ENDERECO_CRUD_CLIENTE';
const SET_ENDERECO_ATTRIBUTE_VALUE = 'SET_ENDERECO_ATTRIBUTE_VALUE_CRUD_CLIENTE';
const SET_ENDERECO_ATTRIBUTE_FOCUS = 'SET_ENDERECO_ATTRIBUTE_FOCUS_CRUD_CLIENTE';
const SET_ERRO_IN_VALIDATION_FORM = 'SET_HAS_ERRO_IN_VALIDATION_FORM';
const SET_ENDERECO_EMPRESA_CLIENTE_NF_ACTION = 'SET_ENDERECO_EMPRESA_CLIENTE_NF_ACTION';
const SET_ENDERECO_EMPRESA_CLIENTE_NF_ATTRIBUTE_VALUE = 'SET_ENDERECO_EMPRESA_CLIENTE_NF_ATTRIBUTE_VALUE';
const SET_ENDERECO_EMPRESA_CLIENTE_NF_ATTRIBUTE_FOCUS = 'SET_ENDERECO_EMPRESA_CLIENTE_NF_ATTRIBUTE_FOCUS';
const SET_SIMPLE_VALUES_CLIENTE_ACTION = 'SET_SIMPLE_VALUES_CLIENTE_ACTION';
const ON_CHANGE_NEW_EMPRESA_CLIENTE_NOTA_FISCAL_CONTROLS = 'ON_CHANGE_NEW_EMPRESA_CLIENTE_NOTA_FISCAL_CONTROLS';
const SET_ATTRIBUTES_CONTROLS_CLIENTE = 'SET_ATTRIBUTES_CONTROLS_CLIENTE';
const SET_JUROS = 'SET_JUROS';

const SET_OBJECT_FOCUS = 'SET_OBJECT_FOCUS_CRUD_CLIENTE';
const SET_OBJECTS = 'SET_OBJECTS_CRUD_CLIENTE';
const SET_OBJECT_ATTRIBUTE = 'SET_OBJECT_ATTRIBUTE_CRUD_CLIENTE';
const SET_ACOMPANHAMENTOS = 'SET_ACOMPANHAMENTOS_CLIENTE';
const SET_ATTRIBUTES_ACOMPANHAMENTO_CLIENTE = 'SET_ATTRIBUTES_ACOMPANHAMENTO_CLIENTE';
const CLEAR_ACOMPANHAMENTOS_CLIENTE = 'CLEAR_ACOMPANHAMENTOS_CLIENTE';

const SET_LOADING = 'SET_LOADING_CRUD_CLIENTE';
const SET_LOADING_EMPRESAS_GRUPO = 'SET_LOADING_EMPRESAS_GRUPO_CRUD_CLIENTE';

const SET_TELEFONE_ADVOGADO_ATTRIBUTE_FOCUS = 'SET_TELEFONE_ADVOGADO_ATTRIBUTE_FOCUS_CRUD_CLIENTE';
const SET_TELEFONE_ADVOGADO_ATTRIBUTE_VALUE = 'SET_TELEFONE_ADVOGADO_ATTRIBUTE_VALUE_CRUD_CLIENTE';

const CHANGE_TAB_PAGE_VIEW = 'ON_CHANGE_TAB_PAGE_VIEW_VALUE';

const ON_OPEN_SNACKBAR = 'OPEN_SNACKBAR_CRUD_CLIENTE';
const ON_OPEN_DIALOG = 'OPEN_DIALOG';

const ON_OPEN_OPTIONAL_DIALOG = 'OPEN_OPTIONAL_DIALOG';

const ON_FOCUS_RAMOS_ATIVIADDE = 'ON_FOCUS_RAMOS_ATIVIDADE_CRUD_CLIENTE';
const ON_FOCUS_COMERCIAL_PRINCIPAL = 'ON_FOCUS_COMERCIAL_PRINCIPAL';
const SET_ERROR_COD_PRINCIPAL = 'SET_ERROR_COD_PRINCIPAL_CRUD_CLIENTE';
const SET_STATUS_PROMISSE_COD_EMPRESA = 'SET_STATUS_PROMISSE_CRUD_CLIENTE';

const SET_REQUEST_FIELDS_ACOMPANHAMENTO_ERROR = 'SET_REQUEST_FIELDS_ACOMPANHAMENTO_ERROR_CRUD_CLIENTE';
const SET_REQUEST_FIELDS_ACOMPANHAMENTO_VALUE = 'SET_REQUEST_FIELDS_ACOMPANHAMENTO_VALUE_CRUD_CLIENTE';
const SET_SCROLL_DIRECTION = 'SET_SCROLL_DIRECTION_CRUD_CLIENTE';
const SET_ACOMPANHAMENTO_PAGEABLE = 'SET_ACOMPANHAMENTO_PAGEABLE_CRUD_CLIENTE';
const SET_PRAZO_EXPIRACAO = 'SET_PRAZO_EXPIRACAO_CRUD_CLIENTE';
const SET_PRAZO_EXPIRACAO_ATTRIBUTE_VALUE = 'SET_PRAZO_EXPIRACAO_ATTRIBUTE_VALUE_CRUD_CLIENTE';
const SET_PRAZO_EXPIRACAO_ATTRIBUTE_FOCUS = 'SET_PRAZO_EXPIRACAO_ATTRIBUTE_FOCUS_CRUD_CLIENTE';

const SET_HONORARIO_DEFINITION = 'SET_HONORARIO_DEFINITION_CRUD_CLIENTE';
const SET_COMISSAO_DEFINITION = 'SET_COMISSAO_DEFINITION_CRUD_CLIENTE';
const SET_JUROS_DOS_DEVEDORES_DEFINITION = 'SET_JUROS_DOS_DEVEDORES_DEFINITION';
const UPDATE_HONORARIO = 'UPDATE_HONORARIO_CRUD_CLIENTE';
const UPDATE_COMISSAO = 'UPDATE_COMISSAO_CRUD_CLIENTE';
const UPDATE_HONORARIO_CONTRATUAL = 'UPDATE_HONORARIO_CONTRATUAL_CRUD_CLIENTE';
const UPDATE_JUROS_DOS_DEVEDORES = 'UPDATE_JUROS_DOS_DEVEDORES';
const REMOVE_HONORARIO = 'REMOVE_HONORARIO_CRUD_CLIENTE';
const REMOVE_COMISSAO = 'REMOVE_COMISSAO_CRUD_CLIENTE';
const REMOVE_HONORARIOS_CONTRATUAIS = 'REMOVE_HONORARIOS_CONTRATUAIS_CRUD_CLIENTE';
const ADD_HONORARIO = 'ADD_HONORARIO_CRUD_CLIENTE';
const ADD_COMISSAO = 'ADD_COMISSAO_CRUD_CLIENTE';
const ADD_JUROS_DO_DEVEDOR = 'ADD_JUROS_DO_DEVEDOR';
const ADD_HONORARIOS_CONTRATUAIS = 'ADD_HONORARIOS_CONTRATUAIS';
const UPDATE_TIPO_CALCULO_HONORARIO = 'UPDATE_TIPO_CALCULO_HONORARIO_CRUD_CLIENTE';
const UPDATE_TIPO_CALCULO_COMISSAO = 'UPDATE_TIPO_CALCULO_COMISSAO_CRUD_CLIENTE';
const UPDATE_TIPO_HONORARIOS_CONTRATUAIS_ACTION = 'UPDATE_TIPO_HONORARIOS_CONTRATUAIS_ACTION';
const SET_IMAGE = '@cliente/SET_IMAGE';
const SET_STORE_VALUE = '@cliente/SET_STORE_VALUE';
const SET_DESCONTOS_BOLETO_ATTRIBUTE_VALUE = '@cliente/SET_DESCONTOS_BOLETO_ATTRIBUTE_VALUE';
const SET_DESCONTOS_BOLETO_ATTRIBUTE_FOCUS = '@cliente/SET_DESCONTOS_BOLETO_ATTRIBUTE_FOCUS';
const SET_DESCONTOS_BOLETO = '@cliente/SET_DESCONTOS_BOLETO';
const SET_DESCONTOS_PIX_ATTRIBUTE_VALUE = '@cliente/SET_DESCONTOS_PIX_ATTRIBUTE_VALUE';
const SET_DESCONTOS_PIX_ATTRIBUTE_FOCUS = '@cliente/SET_DESCONTOS_PIX_ATTRIBUTE_FOCUS';
const SET_DESCONTOS_PIX = '@cliente/SET_DESCONTOS_PIX';
const SET_FAIXA_ARRAY = '@cliente/SET_FAIXA_ARRAY';
const ON_FOCUS_REQUEST_FIELDS_CLIENTES = '@cliente/ON_FOCUS_REQUEST_FIELDS_CLIENTES';
const ON_FOCUS_REQUEST_FIELDS_EMPRESA_CLIENTE_NF = '@cliente/ON_FOCUS_REQUEST_FIELDS_EMPRESA_CLIENTE_NF';
export const SET_DIAS_MES_CLIENTE = '@usuarios/SET_DIAS_MES_CLIENTE';
export const ON_CHANGE_ACTIONS = '@cliente/ON_CHANGE_ACTIONS';
const SET_ADICIONAR_CAMPOS_ATTRIBUTE_VALUE = '@cliente/SET_ADICIONAR_CAMPOS_ATTRIBUTE_VALUE';
const SET_ADICIONAR_CAMPOS_ATTRIBUTE_FOCUS = '@cliente/SET_ADICIONAR_CAMPOS_ATTRIBUTE_FOCUS';
const SET_ADICIONAR_CAMPOS = '@cliente/SET_ADICIONAR_CAMPOS';
const ON_CHANGE_IDS_TIPOS_USUARIOS = '@cliente/ON_CHANGE_IDS_TIPOS_USUARIOS';
const ALTERAR_VALOR_HONORARIOS_MINIMO_FAIXA_FASE_CLIENTE = '@cliente/ALTERAR_VALOR_HONORARIOS_MINIMO_FAIXA_FASE_CLIENTE';


const SENHA_DEFAULT = '      ';

const ON_OPEN_MIGRAR_FOLLOWUP_DIALOG = 'OPEN_MIGRAR_FOLLOWUP';

function getIdGeradorRelatorioPrestacao(object, idGeradorRelatorioPrestacao, enviarPrestacaoContaEmail, valueDefault = '') {
  if (object && (object[idGeradorRelatorioPrestacao] !== undefined && object[idGeradorRelatorioPrestacao] !== null)) {
    return object[idGeradorRelatorioPrestacao];
  }

  if (object && (object[enviarPrestacaoContaEmail] !== undefined && object[enviarPrestacaoContaEmail] !== null)) {
    if (object[enviarPrestacaoContaEmail]) {
      return valueDefault;
    }
    if (!object[enviarPrestacaoContaEmail]) {
      return '';
    }
    return object[idGeradorRelatorioPrestacao];
  }

  return valueDefault;
}

function getDiasMes() {
  return [
    { id: 1, label: '1', rightChecked: false },
    { id: 2, label: '2', rightChecked: false },
    { id: 3, label: '3', rightChecked: false },
    { id: 4, label: '4', rightChecked: false },
    { id: 5, label: '5', rightChecked: false },
    { id: 6, label: '6', rightChecked: false },
    { id: 7, label: '7', rightChecked: false },
    { id: 8, label: '8', rightChecked: false },
    { id: 9, label: '9', rightChecked: false },
    { id: 10, label: '10', rightChecked: false },
    { id: 11, label: '11', rightChecked: false },
    { id: 12, label: '12', rightChecked: false },
    { id: 13, label: '13', rightChecked: false },
    { id: 14, label: '14', rightChecked: false },
    { id: 15, label: '15', rightChecked: false },
    { id: 16, label: '16', rightChecked: false },
    { id: 17, label: '17', rightChecked: false },
    { id: 18, label: '18', rightChecked: false },
    { id: 19, label: '19', rightChecked: false },
    { id: 20, label: '20', rightChecked: false },
    { id: 21, label: '21', rightChecked: false },
    { id: 22, label: '22', rightChecked: false },
    { id: 23, label: '23', rightChecked: false },
    { id: 24, label: '24', rightChecked: false },
    { id: 25, label: '25', rightChecked: false },
    { id: 26, label: '26', rightChecked: false },
    { id: 27, label: '27', rightChecked: false },
    { id: 28, label: '28', rightChecked: false },
    { id: 29, label: '29', rightChecked: false },
    { id: 30, label: '30', rightChecked: false },
    { id: 31, label: '31', rightChecked: false },
  ];
}

function buildIdRamoAtividadeRamosAtividade(ramosAtividade) {
  return (ramosAtividade) ? ramosAtividade.map(ramoAtiviade => ramoAtiviade.idRamoAtividade) : [];
}

function buildUsuario(usuario) {
  return {
    idClienteUsuario: new FieldModel({
      name: 'idClienteUsuario',
      value: getAttributeExistingObject(usuario, 'idClienteUsuario'),
    }),
    login: new FieldModel({
      name: 'login',
      value: getAttributeExistingObject(usuario, 'login'),
      maxLength: 25,
    }),
    senha: new FieldModel({
      name: 'senha',
      value: SENHA_DEFAULT,
      maxLength: 25,
    }),
    statusClienteUsuario: new FieldModel({
      name: 'statusClienteUsuario',
      value: getAttributeExistingObject(usuario, 'statusClienteUsuario', 'A'),
    }),
    email: new FieldModel({
      name: 'email',
      value: getAttributeExistingObject(usuario, 'email'),
      maxLength: 70,
    }),
    idPerfilCliente: new FieldModel({
      name: 'idPerfilCliente',
      value: getAttributeExistingObject(usuario, 'idPerfilCliente'),
    }),
  };
}

function buildUsuarioNotEmptyPasword(usuario) {
  return {
    idClienteUsuario: new FieldModel({
      name: 'idClienteUsuario',
      value: getAttributeExistingObject(usuario, 'idClienteUsuario'),
    }),
    login: new FieldModel({
      name: 'login',
      value: getAttributeExistingObject(usuario, 'login'),
      maxLength: 25,
    }),
    senha: new FieldModel({
      name: 'senha',
      value: getAttributeExistingObject(usuario, 'senha'),
      maxLength: 25,
    }),
    statusClienteUsuario: new FieldModel({
      name: 'statusClienteUsuario',
      value: getAttributeExistingObject(usuario, 'statusClienteUsuario', 'A'),
    }),
    email: new FieldModel({
      name: 'email',
      value: getAttributeExistingObject(usuario, 'email'),
      maxLength: 70,
    }),
    idPerfilCliente: new FieldModel({
      name: 'idPerfilCliente',
      value: getAttributeExistingObject(usuario, 'idPerfilCliente'),
    }),
  };
}

function buildUsuarios(usuarios) {
  return (usuarios) ? usuarios.map(buildUsuario) : [];
}

function buildEmpresaClienteNotaFiscal(empresaClienteNotaFiscal, modoBusca = false) {
  return {
    cnpjCpf: new FieldModel({
      name: 'cnpjCpf',
      value: getAttributeExistingObject(empresaClienteNotaFiscal, 'cnpjCpf'),
    }),
    email: new FieldModel({
      name: 'email',
      value: getAttributeExistingObject(empresaClienteNotaFiscal, 'email'),
    }),
    endereco: buildEndereco(empresaClienteNotaFiscal === null || empresaClienteNotaFiscal === undefined || empresaClienteNotaFiscal.endereco),
    categoria: new FieldModel({
      name: 'categoria',
      value: getAttributeExistingObject(empresaClienteNotaFiscal, 'categoria'),
    }),
    idClienteNotaFiscal: new FieldModel({
      name: 'idClienteNotaFiscal',
      value: getAttributeExistingObject(empresaClienteNotaFiscal, modoBusca ? 'idCliente' : 'idClienteNotaFiscal', null),
    }),
    idEmpresaNotaFiscal: new FieldModel({
      name: 'idEmpresaNotaFiscal',
      value: getAttributeExistingObject(empresaClienteNotaFiscal, 'idEmpresaNotaFiscal', null),
    }),
    nome: new FieldModel({
      name: 'nome',
      value: getAttributeExistingObject(empresaClienteNotaFiscal, 'nome'),
    }),
  };
}

function buildSettingsAcompanhamento() {
  return {
    acompanhamentos: [],
    requestFields: {
      dataEnvioInicial: new FieldModel({ name: 'dataEnvioInicial', type: DATE }),
      conteudo: new FieldModel({ name: 'conteudo', value: '' }),
      texto: new FieldModel({ name: 'texto' }),
      dataFollowUp: new FieldModel({ name: 'dataFollowUp', type: DATE }),
      obrigatorioFollowUp: new FieldModel({
        name: 'obrigatorioFollowUp',
        value: false,
      }),
      copiaAnotacoesEmpresasGrupo: new FieldModel({
        name: 'copiaAnotacoesEmpresasGrupo',
        value: false,
      }),
    },
    pageable: {
      page: 0,
      lastPage: false,
      firstPage: true,
      direction: 'BOTTOM',
    },
    loadingAcompanhamento: false,
    loadingInsertAcompanhamento: false,
    sendDataFollowUp: false,
    scrollInBottom: false,
  };
}

function buildControls(controls, dias) {
  return {
    serCliente: new FieldModel({
      name: 'serCliente',
      value: getAttributeExistingObject(controls, 'serCliente', true),
    }),
    idCliente: new FieldModel({
      name: 'idCliente',
      value: getAttributeExistingObject(controls, 'idCliente'),
    }),
    nome: new FieldModel({
      name: 'nome',
      value: getAttributeExistingObject(controls, 'nome'),
      maxLength: 60,
    }),
    identificacaoInterna: new FieldModel({
      name: 'identificacaoInterna',
      value: getAttributeExistingObject(controls, 'identificacaoInterna'),
      maxLength: 60,
    }),
    statusCliente: new FieldModel({
      name: 'statusCliente',
      value: getAttributeExistingObject(controls, 'statusCliente', 'A'),
    }),
    categoria: new FieldModel({
      name: 'categoria',
      value: getAttributeExistingObject(controls, 'categoria', CATEGORIAS.PESSOA_JURIDICA),
    }),
    documento: new FieldModel({
      name: 'documento',
      value: getAttributeExistingObject(controls, 'documento'),
      maxLength: 15,
    }),
    preposto: new FieldModel({
      name: 'preposto',
      value: getAttributeExistingObject(controls, 'preposto'),
      maxLength: 20,
    }),
    idUsuarioPrincipal: new FieldModel({
      name: 'idUsuarioPrincipal',
      value: getAttributeExistingObject(controls, 'idUsuarioPrincipal'),
    }),
    idUsuarioTelemarketing: new FieldModel({
      name: 'idUsuarioTelemarketing',
      value: getAttributeExistingObject(controls, 'idUsuarioTelemarketing'),
    }),
    nomeFantasia: new FieldModel({
      name: 'nomeFantasia',
      value: getAttributeExistingObject(controls, 'nomeFantasia'),
      maxLength: 60,
    }),
    inscricaoEstadual: new FieldModel({
      name: 'inscricaoEstadual',
      value: getAttributeExistingObject(controls, 'inscricaoEstadual'),
      maxLength: 15,
    }),
    inscricaoMunicipal: new FieldModel({
      name: 'inscricaoMunicipal',
      value: getAttributeExistingObject(controls, 'inscricaoMunicipal'),
      maxLength: 15,
    }),
    idFilial: new FieldModel({
      name: 'idFilial',
      value: getAttributeExistingObject(controls, 'idFilial'),
    }),
    idTipoClassificacaoCliente: new FieldModel({
      name: 'idTipoClassificacaoCliente',
      value: getAttributeExistingObject(controls, 'idTipoClassificacaoCliente', 5),
    }),
    observacao: new FieldModel({
      name: 'observacao',
      value: getAttributeExistingObject(controls, 'observacao'),
      maxLength: 8000,
    }),
    codEmpresaPrincipal: new FieldModel({
      name: 'codEmpresaPrincipal',
      value: getAttributeExistingObject(controls, 'codEmpresaPrincipal'),
      maxLength: 9,
      type: POSITIVE_INTEGER,
    }),
    nomeAdvogado: new FieldModel({
      name: 'nomeAdvogado',
      value: getAttributeExistingObject(controls, 'nomeAdvogado'),
      maxLength: 40,
    }),
    dataAtivacao: new FieldModel({
      name: 'dataAtivacao',
      value: getAttributeExistingObject(controls, 'dataAtivacao'),
    }),
    dataCadastramento: new FieldModel({
      name: 'dataCadastramento',
      value: getAttributeExistingObject(controls, 'dataCadastramento'),
    }),
    origemLead: new FieldModel({
      name: 'origemLead',
      value: getAttributeExistingObject(controls, 'origemLead'),
      maxLength: 15,
    }),
    ramosAtividade: new FieldModel({
      name: 'ramosAtiviade',
      value: buildIdRamoAtividadeRamosAtividade(getAttributeExistingObject(controls, 'ramosAtividade', [])),
    }),
    porcentagemMulta: new FieldModel({
      name: 'porcentagemMulta',
      value: getAttributeExistingObject(controls, 'porcentagemMulta', 0),
    }),
    protegerCodigoConsumidor: new FieldModel({
      name: 'protegerCodigoConsumidor',
      value: getAttributeExistingObject(controls, 'protegerCodigoConsumidor', false),
    }),
    incluirNaProducao: new FieldModel({
      name: 'incluirNaProducao',
      value: getAttributeExistingObject(controls, 'incluirNaProducao', true),
    }),
    instrucaoInicial: new FieldModel({
      name: 'instrucaoInicial',
      value: getAttributeExistingObject(controls, 'instrucaoInicial'),
    }),
    dataFu: new FieldModel({
      name: 'dataFu',
      value: getAttributeExistingObject(controls, 'dataFu', ''),
    }),
    removerDataFollowUp: new FieldModel({
      name: 'removerDataFollowUp',
      value: getAttributeExistingObject(controls, 'removerDataFollowUp', false),
    }),
    tipoCalculoHonorarios: buildClienteTipoCalculoHonorario(
      getAttributeExistingObject(controls, 'tipoCalculoHonorarios', undefined),
    ),
    tipoCalculoComissoes: buildClienteTipoCalculoComissao(
      getAttributeExistingObject(controls, 'tipoCalculoComissoes', 1),
    ),
    telefoneAdvogado: buildTelefoneAdvogado(
      getAttributeExistingObject(controls, 'telefoneAdvogado'),
    ),
    endereco: buildEndereco(
      getAttributeExistingObject(controls, 'endereco'),
    ),
    telefones: buildTelefones(
      getAttributeExistingObject(controls, 'telefones'),
    ),
    emails: buildSimpleEmailsCliente(
      getAttributeExistingObject(controls, 'emails'),
    ),
    usuarios: buildUsuarios(getAttributeExistingObject(controls, 'usuarios')),
    prazosExpiracao: buildFaixasPrazoExpiracao(getAttributeExistingObject(controls, 'prazosExpiracao', [])),
    idTipoRegraCalculoRepasse: new FieldModel({
      name: 'idTipoRegraCalculoRepasse',
      value: getAttributeExistingObject(controls, 'idTipoRegraCalculoRepasse', 1),
    }),
    idTipoAcordoSeguro: new FieldModel({
      name: 'idTipoAcordoSeguro',
      value: getAttributeExistingObject(controls, 'idTipoAcordoSeguro', 1),
    }),
    diasAvisoExpiracaoProcesso: new FieldModel({
      name: 'diasAvisoExpiracaoProcesso',
      value: getAttributeExistingObject(controls, 'diasAvisoExpiracaoProcesso', 30),
    }),
    idTipoComissaoBordero: new FieldModel({
      name: 'idTipoComissaoBordero',
      value: getAttributeExistingObject(controls, 'idTipoComissaoBordero', 1),
    }),
    idIntervaloPagamento: new FieldModel({
      name: 'idIntervaloPagamento',
      value: getAttributeExistingObject(controls, 'idIntervaloPagamento', ''),
    }),
    idTipoEmailPadrao: new FieldModel({
      name: 'idTipoEmailPadrao',
      value: getAttributeExistingObject(controls, 'idTipoEmailPadrao', ''),
    }),
    qtdeComissaoBordero: new FieldModel({
      name: 'qtdeComissaoBordero',
      value: getAttributeExistingObject(controls, 'qtdeComissaoBordero'),
    }),
    porcentagemComissaoPrincipal: new FieldModel({
      name: 'porcentagemComissaoPrincipal',
      value: getAttributeExistingObject(controls, 'porcentagemComissaoPrincipal'),
    }),
    porcentagemComissaoTelemark: new FieldModel({
      name: 'porcentagemComissaoTelemark',
      value: getAttributeExistingObject(controls, 'porcentagemComissaoTelemark'),
    }),
    serComissaoPermanenteSecunda: new FieldModel({
      name: 'serComissaoPermanenteSecunda',
      value: getAttributeExistingObject(controls, 'serComissaoPermanenteSecunda', false),
    }),
    serComissaoPermanenteTelemark: new FieldModel({
      name: 'serComissaoPermanenteTelemark',
      value: getAttributeExistingObject(controls, 'serComissaoPermanenteTelemark', false),
    }),
    avisarProcessoExpirado: new FieldModel({
      name: 'avisarProcessoExpirado',
      value: getAttributeExistingObject(controls, 'avisarProcessoExpirado', false),
    }),
    processarTitulosAntigos: new FieldModel({
      name: 'processarTitulosAntigos',
      value: getAttributeExistingObject(controls, 'processarTitulosAntigos', true),
    }),
    processarValoresBaixos: new FieldModel({
      name: 'processarValoresBaixos',
      value: getAttributeExistingObject(controls, 'processarValoresBaixos', true),
    }),
    calcularJurosUnico: new FieldModel({
      name: 'calcularJurosUnico',
      value: getAttributeExistingObject(controls, 'calcularJurosUnico', false),
    }),
    dataPrimeiroBordero: new FieldModel({
      name: 'dataPrimeiroBordero',
      value: toBrazilianDate(getAttributeExistingObject(controls, 'dataPrimeiroBordero')),
    }),
    dataUltimoBordero: new FieldModel({
      name: 'dataUltimoBordero',
      value: toBrazilianDate(getAttributeExistingObject(controls, 'dataUltimoBordero')),
    }),
    qtdeBorderoEnviado: new FieldModel({
      name: 'qtdeBorderoEnviado',
      value: getAttributeExistingObject(controls, 'qtdeBorderoEnviado'),
    }),
    emailAtendimentoAcordoSeguro: new FieldModel({
      name: 'emailAtendimentoAcordoSeguro',
      value: getAttributeExistingObject(controls, 'emailAtendimentoAcordoSeguro', ''),
    }),
    telefoneContatoAcordoSeguro1: new FieldModel({
      name: 'telefoneContatoAcordoSeguro1',
      value: getAttributeExistingObject(controls, 'telefoneContatoAcordoSeguro1'),
      type: POSITIVE_INTEGER,
    }),
    telefoneContatoAcordoSeguro2: new FieldModel({
      name: 'telefoneContatoAcordoSeguro2',
      value: getAttributeExistingObject(controls, 'telefoneContatoAcordoSeguro2'),
      type: POSITIVE_INTEGER,
    }),
    utilizarContatoProprioAS: new FieldModel({
      name: 'utilizarContatoProprioAS',
      value: getAttributeExistingObject(controls, 'utilizarContatoProprioAS', false),
    }),
    exibirLogoProprioAS: new FieldModel({
      name: 'exibirLogoProprioAS',
      value: getAttributeExistingObject(controls, 'exibirLogoProprioAS', false),
    }),
    utilizarDescProprioPixAS: new FieldModel({
      name: 'utilizarDescProprioPixAS',
      value: getAttributeExistingObject(controls, 'utilizarDescProprioPixAS', false),
    }),
    utilizarDescProprioBoletoAS: new FieldModel({
      name: 'utilizarDescProprioBoletoAS',
      value: getAttributeExistingObject(controls, 'utilizarDescProprioBoletoAS', false),
    }),
    descontoPixAS: buildClienteDescontosPix(getAttributeExistingObject(controls, 'descontoPixAS', [])),
    descontoBoletoAS: buildClienteDescontoBoleto(getAttributeExistingObject(controls, 'descontoBoletoAS', [])),
    aceitarBoletoAS: new FieldModel({
      name: 'aceitarBoletoAS',
      value: getAttributeExistingObject(controls, 'aceitarBoletoAS', true),
    }),
    aceitarCartaoAS: new FieldModel({
      name: 'aceitarCartaoAS',
      value: getAttributeExistingObject(controls, 'aceitarCartaoAS', true),
    }),
    aceitarPixAS: new FieldModel({
      name: 'aceitarPixAS',
      value: getAttributeExistingObject(controls, 'aceitarPixAS', true),
    }),
    controlarExpiracaoProcesso: new FieldModel({
      name: 'controlarExpiracaoProcesso',
      value: getAttributeExistingObject(controls, 'controlarExpiracaoProcesso', true),
    }),
    permiteCobrancaPresencial: new FieldModel({
      name: 'permiteCobrancaPresencial',
      value: getAttributeExistingObject(controls, 'permiteCobrancaPresencial', true),
    }),
    permitirUnificarProcesso: new FieldModel({
      name: 'permitirUnificarProcesso',
      value: getAttributeExistingObject(controls, 'permitirUnificarProcesso', true),
    }),
    permitirAjuizarProcessos: new FieldModel({
      name: 'permitirAjuizarProcessos',
      value: getAttributeExistingObject(controls, 'permitirAjuizarProcessos', true),
    }),
    permiteCnpj: new FieldModel({
      name: 'permiteCnpj',
      value: getAttributeExistingObject(controls, 'permiteCnpj', false),
    }),
    semanal: new FieldModel({
      name: 'semanal',
      value: getAttributeExistingObject(controls, 'semanal', true),
    }),
    periodicidadeSemanalSegunda: new FieldModel({
      name: 'periodicidadeSemanalSegunda',
      value: getAttributeExistingObject(controls, 'periodicidadeSemanalSegunda', false),
    }),
    periodicidadeSemanalTerca: new FieldModel({
      name: 'periodicidadeSemanalTerca',
      value: getAttributeExistingObject(controls, 'periodicidadeSemanalTerca', false),
    }),
    periodicidadeSemanalQuarta: new FieldModel({
      name: 'periodicidadeSemanalQuarta',
      value: getAttributeExistingObject(controls, 'periodicidadeSemanalQuarta', false),
    }),
    periodicidadeSemanalQuinta: new FieldModel({
      name: 'periodicidadeSemanalQuinta',
      value: getAttributeExistingObject(controls, 'periodicidadeSemanalQuinta', false),
    }),
    periodicidadeSemanalSexta: new FieldModel({
      name: 'periodicidadeSemanalSexta',
      value: getAttributeExistingObject(controls, 'periodicidadeSemanalSexta', true),
    }),
    suspenderPrestacaoConta: new FieldModel({
      name: 'suspenderPrestacaoConta',
      value: getAttributeExistingObject(controls, 'suspenderPrestacaoConta', false),
    }),
    prestarContaSeparadamentePorProcesso: new FieldModel({
      name: 'prestarContaSeparadamentePorProcesso',
      value: getAttributeExistingObject(controls, 'prestarContaSeparadamentePorProcesso', false),
    }),
    enviarPrestacaoContaEmail: new FieldModel({
      name: 'enviarPrestacaoContaEmail',
      value: getAttributeExistingObject(controls, 'enviarPrestacaoContaEmail', true),
    }),
    idGeradorRelatorioPrestacao: new FieldModel({
      name: 'idGeradorRelatorioPrestacao',
      value: getIdGeradorRelatorioPrestacao(controls, 'idGeradorRelatorioPrestacao', 'enviarPrestacaoContaEmail', 1),
    }),
    idTipoLiquidacaoPrestacao: new FieldModel({
      name: 'idTipoLiquidacaoPrestacao',
      value: getAttributeExistingObject(controls, 'idTipoLiquidacaoPrestacao', 0),
    }),
    idTipoPrestacaoContaCliente: new FieldModel({
      name: 'idTipoPrestacaoContaCliente',
      value: getAttributeExistingObject(controls, 'idTipoPrestacaoContaCliente', 0),
    }),
    periodicidadeContaCliente: new FieldModel({
      name: 'periodicidadeContaCliente',
      value: getAttributeExistingObject(controls, 'periodicidadeContaCliente', 'SEMANAL'),
    }),
    regraPrestacao: new FieldModel({
      name: 'regraPrestacao',
      value: getAttributeExistingObject(controls, 'regraPrestacao', 'NENHUMA'),
    }),
    idBanco: new FieldModel({
      name: 'idBanco',
      value: getAttributeExistingObject(controls, 'idBanco', ''),
    }),
    ccAgenciaSispag: new FieldModel({
      name: 'ccAgenciaSispag',
      value: getAttributeExistingObject(controls, 'ccAgenciaSispag', ''),
    }),
    ccContaSispag: new FieldModel({
      name: 'ccContaSispag',
      value: getAttributeExistingObject(controls, 'ccContaSispag', ''),
    }),
    chavePix: new FieldModel({
      name: 'chavePix',
      value: getAttributeExistingObject(controls, 'chavePix', ''),
    }),
    idTipoChavePix: new FieldModel({
      name: 'idTipoChavePix',
      value: getAttributeExistingObject(controls, 'idTipoChavePix', ''),
    }),
    diaLimitePrestacaoConta: new FieldModel({
      name: 'diaLimitePrestacaoConta',
      value: getAttributeExistingObject(controls, 'diaLimitePrestacaoConta', 0),
    }),
    diasDoMes: dias || [],
    bloquearPrestacaoSeValorBaixo: new FieldModel({
      name: 'bloquearPrestacaoSeValorBaixo',
      value: getAttributeExistingObject(controls, 'bloquearPrestacaoSeValorBaixo', true),
    }),
    bloquearPrestacaoSeDebito: new FieldModel({
      name: 'bloquearPrestacaoSeDebito',
      value: getAttributeExistingObject(controls, 'bloquearPrestacaoSeDebito', false),
    }),
    prazoToleranciaBloqueioPrestacaoConta: new FieldModel({
      name: 'prazoToleranciaBloqueioPrestacaoConta',
      value: getAttributeExistingObject(controls, 'prazoToleranciaBloqueioPrestacaoConta', ''),
    }),
    detalheLiquidacaoPrestacao: new FieldModel({
      name: 'detalheLiquidacaoPrestacao',
      value: getAttributeExistingObject(controls, 'detalheLiquidacaoPrestacao'),
    }),
    idTipoNotaFiscal: new FieldModel({
      name: 'idTipoNotaFiscal',
      value: getAttributeExistingObject(controls, 'idTipoNotaFiscal', 1),
    }),
    idPrefeituraNotaFiscal: new FieldModel({
      name: 'idPrefeituraNotaFiscal',
      value: getAttributeExistingObject(controls, 'idPrefeituraNotaFiscal', ''),
    }),
    descricaoNotaPersonalizada: new FieldModel({
      name: 'descricaoNotaPersonalizada',
      value: getAttributeExistingObject(controls, 'descricaoNotaPersonalizada', ''),
    }),
    autorizarEmissaoNF: new FieldModel({
      name: 'autorizarEmissaoNF',
      value: getAttributeExistingObject(controls, 'autorizarEmissaoNF', true),
    }),
    autorizarEmissaoBoleto: new FieldModel({
      name: 'autorizarEmissaoBoleto',
      value: getAttributeExistingObject(controls, 'autorizarEmissaoBoleto', true),
    }),
    requerNFDespesa: new FieldModel({
      name: 'requerNFDespesa',
      value: getAttributeExistingObject(controls, 'requerNFDespesa', false),
    }),
    motivoStatusInativo: new FieldModel({
      name: 'motivoStatusInativo',
      value: getAttributeExistingObject(controls, 'motivoStatusInativo', ''),
    }),
    dacContaSispag: new FieldModel({
      name: 'dacContaSispag',
      value: getAttributeExistingObject(controls, 'dacContaSispag', ''),
    }),
    requerInformacaoExtraNF: new FieldModel({
      name: 'requerInformacaoExtraNF',
      value: getAttributeExistingObject(controls, 'requerInformacaoExtraNF', false),
    }),
    detalhesInfoExtraNF: new FieldModel({
      name: 'detalhesInfoExtraNF',
      value: getAttributeExistingObject(controls, 'detalhesInfoExtraNF', ''),
    }),
    utilizaEmpresaAlternativaEmissaoNF: new FieldModel({
      name: 'utilizaEmpresaAlternativaEmissaoNF',
      value: getAttributeExistingObject(controls, 'utilizaEmpresaAlternativaEmissaoNF', false),
    }),
    empresaClienteNotaFiscal: buildEmpresaClienteNotaFiscal(controls === undefined || controls.empresaClienteNotaFiscal, false),
    bloquearCadastroEmpresaClienteNF: false,
    bloquearBuscarEmpresaClienteNF: false,
    prazoVencimentoBoleto: new FieldModel({
      name: 'prazoVencimentoBoleto',
      value: getAttributeExistingObject(controls, 'prazoVencimentoBoleto', 10),
    }),
    idTipoDistribuicaoValorCliente: new FieldModel({
      name: 'idTipoDistribuicaoValorCliente',
      value: getAttributeExistingObject(controls, 'idTipoDistribuicaoValorCliente', 0),
    }),
    idFormaQuitacaoValorCliente: new FieldModel({
      name: 'idFormaQuitacaoValorCliente',
      value: getAttributeExistingObject(controls, 'idFormaQuitacaoValorCliente', 0),
    }),
    diaBloqueioNotaFiscal: new FieldModel({
      name: 'diaBloqueioNotaFiscal',
      value: getAttributeExistingObject(controls, 'diaBloqueioNotaFiscal', 1),
    }),
    bloquearEmissaoNotaFiscal: new FieldModel({
      name: 'bloquearEmissaoNotaFiscal',
      value: getAttributeExistingObject(controls, 'bloquearEmissaoNotaFiscal', controls?.diaBloqueioNotaFiscal !== undefined),
    }),
    bloquearDistribuicaoAutomaticaFichaFinanceira: new FieldModel({
      name: 'bloquearDistribuicaoAutomaticaFichaFinanceira',
      value: getAttributeExistingObject(controls, 'bloquearDistribuicaoAutomaticaFichaFinanceira', false),
    }),
    tipoCalculoJurosDevedores: buildJurosDosDevedores(
      getAttributeExistingObject(controls, 'jurosDosDevedores', undefined),
    ),
    tipoHonorariosContratuais: buildHonorariosContratuais(
      getAttributeExistingObject(controls, 'honorariosContratuais', []),
    ),
    idTiposHonorarioContratual: new FieldModel({
      name: 'idTiposHonorarioContratual',
      value: getAttributeExistingObject(controls, 'idTiposHonorarioContratual', 0),
    }),
    campanha: buildCampanha(
      getAttributeExistingObject(controls, 'campanha', null),
    ),
    authorizationKey: new FieldModel({
      name: 'authorizationKey',
      value: getAttributeExistingObject(controls, 'authorizationKey', ''),
    }),
  };
}

function initiateStates(controls, dias) {
  return {
    image: {
      file: null,
      imagePreviewUrl: null,
      error: false,
      errorMessage: '',
    },
    emailAntigo: '', // Guarda o valor que vem do banco para poder manter as duas opções de acordo com a escolha do usuario
    descontoPixASAntigo: [], // Guarda o valor que vem do banco para poder manter as duas opções de acordo com a escolha do usuario
    descontoBoletoASAntigo: [], // Guarda o valor que vem do banco para poder manter as duas opções de acordo com a escolha do usuario
    controls: buildControls(controls, dias),
    honorarioDefinition: controls?.tipoCalculoHonorarios ? 'cliente' : 'abe',
    comissoesDefinition: controls?.tipoCalculoComissoes ? 'cliente' : 'abe',
    jurosDosDevedoresDefinition: controls?.jurosDosDevedores ? 'cliente' : 'abe',
    contatosAtendimentoDefinition: controls?.utilizarContatoProprioAS ? 'cliente' : 'abe',
    copyForCheckEmpresasGrupo: null,
    empresasGrupo: [],
    idsTiposUsuarios: null,
    settingsAcompanhamento: buildSettingsAcompanhamento(),
    logoTipoClienteDefinition: controls?.exibirLogoProprioAS ? 'cliente' : 'abe',
    utilizarDescontoPixDefition: controls?.utilizarDescProprioPixAS ? 'cliente' : 'abe',
    utilizarDescontoBoletoDefition: controls?.utilizarDescProprioBoletoAS ? 'cliente' : 'abe',
    controlarExpiracaoProcessoDefinition: controls?.controlarExpiracaoProcesso ? 'cliente' : 'abe',
    periodiciodadeDefinition: controls?.periodicidadeContaCliente ? controls.periodicidadeContaCliente : 'SEMANAL',
    regraEspecialDefinition: controls?.regraPrestacao ? controls.regraPrestacao : 'NENHUMA',
    actions: {
      listaDiasElegiveisCliente: getDiasMes(),
      errorImage: false,
      reloadAllCombos: false,
      loading: false,
      loadingEmpresasGrupo: false,
      statusPromisseCodEmpresa: '',
      errorMessageCodEmpresa: '',
      hasErrorInValidationForm: false,
      snackbar: {
        open: false,
        variant: null,
        message: '',
      },
      alertDialog: buildDialog(),
      optionalDialogContent: buildOptinalDialog(),
      followUpMigrarDialogContent: {
        open: false,
        message: '',
        redLabel: '',
        greenLabel: '',
      },
      tabActive: 0,
    },
  };
}

function buildAcompanhamentos(reverteOrder, storedAcompanhamentos, newAcompanhamentos) {
  if (reverteOrder) {
    return [...newAcompanhamentos.reverse(), ...storedAcompanhamentos];
  }
  return [...storedAcompanhamentos, ...newAcompanhamentos];
}

function onClearAcompanhamentosHandler(states) {
  const { sendDataFollowUp } = states.settingsAcompanhamento;
  const { dataEnvioInicial } = states.settingsAcompanhamento.requestFields;
  const { direction } = states.settingsAcompanhamento.pageable;
  return {
    ...states,
    settingsAcompanhamento: {
      ...buildSettingsAcompanhamento(),
      sendDataFollowUp,
      requestFields: {
        ...states.settingsAcompanhamento.requestFields,
        dataEnvioInicial,
      },
      pageable: {
        ...states.settingsAcompanhamento.pageable,
        direction,
      },
    },
  };
}

export const setDiasClienteAction = (idOpcaoDiaMes, isAdd, fullRemove) => ({
  type: SET_DIAS_MES_CLIENTE,
  idOpcaoDiaMes,
  isAdd,
  fullRemove,
});

function setStoreValueHandler(states, actions) {
  const { name, value } = actions;

  return {
    ...states,
    [name]: value,
  };
}


function setAcompanhamentosHandler(states, actions) {
  const { acompanhamentos, reverteOrder } = actions;
  const newAcompanhamentos = buildAcompanhamentos(reverteOrder, states.settingsAcompanhamento.acompanhamentos, acompanhamentos.content);

  return {
    ...states,
    settingsAcompanhamento: {
      ...states.settingsAcompanhamento,
      acompanhamentos: newAcompanhamentos,
    },
  };
}

function setAcompanhamentosAttributesHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    settingsAcompanhamento: {
      ...states.settingsAcompanhamento,
      [name]: value,
    },
  };
}

function setControlsAttributesHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    controls: {
      ...states.controls,
      [name]: value,
    },
  };
}

function changeTelefoneAdvogadoAttributeFocusHandler(states, actions) {
  const field = states.controls.telefoneAdvogado[actions.fieldName].copy();
  return {
    ...states,
    controls: {
      ...states.controls,
      telefoneAdvogado: {
        ...states.controls.telefoneAdvogado,
        [actions.fieldName]: field.onFocus(),
      },
    },
  };
}

function changeTelefoneAdvogadoAttributeValueHandler(states, actions) {
  const { name, value } = actions;
  const field = states.controls.telefoneAdvogado[name].getNewModel(value);
  return {
    ...states,
    controls: {
      ...states.controls,
      telefoneAdvogado: {
        ...states.controls.telefoneAdvogado,
        [name]: field,
      },
    },
  };
}

function changeEnderecoAttributeFocusHandler(states, actions) {
  const field = states.controls.endereco[actions.name].copy();
  return {
    ...states,
    controls: {
      ...states.controls,
      endereco: {
        ...states.controls.endereco,
        [actions.name]: field.onFocus(),
      },
    },
  };
}

function changeEnderecoEmpresaClienteNFAttributeFocusHandler(states, actions) {
  const field = states.controls.empresaClienteNotaFiscal.endereco[actions.name].copy();
  return {
    ...states,
    controls: {
      ...states.controls,
      empresaClienteNotaFiscal: {
        ...states.controls.empresaClienteNotaFiscal,
        endereco: {
          ...states.controls.empresaClienteNotaFiscal.endereco,
          [actions.name]: field.onFocus(),
        },
      },
    },
  };
}

function changeEnderecoHandler(states, actions) {
  return {
    ...states,
    controls: {
      ...states.controls,
      endereco: { ...actions.endereco },
    },
  };
}

function changeEnderecoEmpresaClienteNotaFiscalHandler(states, actions) {
  return {
    ...states,
    controls: {
      ...states.controls,
      empresaClienteNotaFiscal: {
        ...states.controls.empresaClienteNotaFiscal,
        endereco: { ...actions.endereco },
      },
    },
  };
}

function changeEnderecoAttributeValueHandler(states, actions) {
  const { name, value } = actions;
  const field = states.controls.endereco[name].getNewModel(value);
  return {
    ...states,
    controls: {
      ...states.controls,
      endereco: {
        ...states.controls.endereco,
        [name]: field,
      },
    },
  };
}

function changeEnderecoAttributeValueEmpresaClienteNFHandler(states, actions) {
  const { name, value } = actions;
  const field = states.controls.empresaClienteNotaFiscal.endereco[name].getNewModel(value);
  return {
    ...states,
    controls: {
      ...states.controls,
      empresaClienteNotaFiscal: {
        ...states.controls.empresaClienteNotaFiscal,
        endereco: {
          ...states.controls.empresaClienteNotaFiscal.endereco,
          [name]: field,
        },
      },
    },
  };
}

function setObjectFocusHandler(states, actions) {
  const objects = [...states.controls[actions.objectName]];
  objects[actions.index][actions.name].onFocus();

  return {
    ...states,
    controls: {
      ...states.controls,
      [actions.objectName]: objects,
    },
  };
}

function setObjectsHandler(states, actions) {
  return {
    ...states,
    controls: {
      ...states.controls,
      [actions.objectName]: [...actions.objects],
    },
  };
}


function updateFieldsAfterChangeCategorias(states, value) {
  const categoriaModel = states.controls.categoria.getNewModel(value);
  const nomeFantasiaModel = states.controls.nomeFantasia.copy();
  const incricaoEstadualModel = states.controls.inscricaoEstadual.copy();
  const inscricaoMunicipalModel = states.controls.inscricaoMunicipal.copy();
  const documentoModel = states.controls.documento.copy();

  documentoModel.value = '';

  const isPessoaFisica = value === CATEGORIAS.PESSOA_FISICA;

  incricaoEstadualModel.disabled = isPessoaFisica;
  inscricaoMunicipalModel.disabled = isPessoaFisica;
  nomeFantasiaModel.disabled = isPessoaFisica;

  if (isPessoaFisica) {
    incricaoEstadualModel.value = '';
    inscricaoMunicipalModel.value = '';
    nomeFantasiaModel.value = '';
  }

  return {
    categoriaModel,
    nomeFantasiaModel,
    incricaoEstadualModel,
    inscricaoMunicipalModel,
    documentoModel,
  };
}

function onClearCombosHandler(states) {
  return {
    ...states,
    controls: {
      ...states.controls,
      idUsuarioPrincipal: states.controls.idUsuarioPrincipal.getNewModel(''),
      idFilial: states.controls.idFilial.getNewModel(''),
      idTipoClassificacaoCliente: states.controls.idTipoClassificacaoCliente.getNewModel(''),
      ramosAtividade: states.controls.ramosAtividade.getNewModel([]),
    },
  };
}

function onReloadCombosHandler(states, actions) {
  return {
    ...states,
    actions: {
      ...states.actions,
      reloadAllCombos: actions.reloadAllCombos,
    },
  };
}

function onUpdateEmpresasGrupoHandler(states, actions) {
  return {
    ...states,
    controls: {
      ...states.controls,
    },
    empresasGrupo: [...actions.empresasGrupo],
  };
}

function onUpdateAllFormHandler(states, actions) {
  const settingsAcompanhamento = { ...states.settingsAcompanhamento };
  const newStates = initiateStates(actions.cliente, actions.diasMes);
  return {
    ...newStates,
    settingsAcompanhamento,
    copyForCheckEmpresasGrupo: JSON.stringify(newStates.controls),
  };
}

function onAddUsuariosHandler(states) {
  const usuarios = [...states.controls.usuarios];
  usuarios.push(buildUsuarioNotEmptyPasword());
  return {
    ...states,
    controls: {
      ...states.controls,
      usuarios,
    },
  };
}

function onUpdateControlsEmailsHandler(states, actions) {
  return {
    ...states,
    controls: {
      ...states.controls,
      emails: [...actions.emails],
    },
  };
}

function onChangeRamosAtividadeHandler(states, actions) {
  const ramosAtividade = states.controls.ramosAtividade.copy();
  ramosAtividade.value = [...actions.values];
  return {
    ...states,
    controls: {
      ...states.controls,
      ramosAtividade,
    },
  };
}

function onChangeEmailsHandler(states, actions) {
  const emails = [...states.controls.emails];
  emails[actions.index][actions.name].onChange(actions.value);
  return {
    ...states,
    controls: {
      ...states.controls,
      emails,
    },
  };
}

function onFocusEmailsHandler(states, actions) {
  const emails = [...states.controls.emails];
  emails[actions.index][actions.name] = actions.model;
  return {
    ...states,
    controls: {
      ...states.controls,
      emails,
    },
  };
}

function onChangeCategoriaHandler(states, actions) {
  const {
    categoriaModel,
    nomeFantasiaModel,
    incricaoEstadualModel,
    documentoModel,
    inscricaoMunicipalModel,
  } = updateFieldsAfterChangeCategorias(states, actions.value);

  documentoModel.value = '';

  return {
    ...states,
    controls: {
      ...states.controls,
      categoria: categoriaModel,
      nomeFantasia: nomeFantasiaModel,
      inscricaoEstadual: incricaoEstadualModel,
      inscricaoMunicipal: inscricaoMunicipalModel,
      documento: documentoModel,
    },
  };
}

function onChangeControlsHandler(states, actions) {
  const model = states.controls[actions.name].getNewModel(actions.value);
  return { ...states, controls: { ...states.controls, [actions.name]: model } };
}

function onChangeCampanhaHandler(states, actions) {
  const model = states.controls.campanha[actions.name].getNewModel(actions.value);
  return {
    ...states,
    controls: {
      ...states.controls,
      campanha: {
        ...states.controls.campanha,
        [actions.name]: model,
      },
    },
  };
}

function onChangeCampanhaGeralHandler(states, actions) {
  const { value } = actions;
  return {
    ...states,
    controls: {
      ...states.controls,
      campanha: value,
    },
  };
}

function onFocusCampanhaHandler(states, actions) {
  const model = states.controls.campanha[actions.name].copy();
  return {
    ...states,
    controls: {
      ...states.controls,
      campanha: {
        ...states.controls.campanha,
        [actions.name]: model.onFocus(),
      },
    },
  };
}

function onFocusControlsHandler(states, actions) {
  const model = states.controls[actions.name].copy();
  return {
    ...states,
    controls: {
      ...states.controls,
      [actions.name]: model.onFocus(),
    },
  };
}

function onUpdateControlsHandler(states, actions) {
  if (states.controls.campanha === null) {
    return {
      ...states,
      controls: {
        ...states.controls,
        ...actions.controls,
      },
    };
  }

  return {
    ...states,
    controls: {
      ...states.controls,
      ...actions.controls,
      campanha: {
        ...states.controls.campanha,
        ...actions.controls.campanha,
      },
    },
  };
}

function setSimpleValuesCliente(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    controls: {
      ...states.controls,
      [name]: value,
    },
  };
}

function changeLoadingHandler(states, actions) {
  return {
    ...states,
    actions: {
      ...states.actions,
      loading: actions.showLoading,
    },
  };
}

function changeLoadingEmpresasGurpoHandlerHandler(states, actions) {
  return {
    ...states,
    actions: {
      ...states.actions,
      loadingEmpresasGrupo: actions.showLoading,
    },
  };
}

function setObjectAttributeHandler(states, actions) {
  const objects = [...states.controls[actions.objectName]];
  objects[actions.index][actions.name].onChange(actions.value);

  return {
    ...states,
    controls: {
      ...states.controls,
      [actions.objectName]: objects,
    },
  };
}

function changeActivePageViewHandler(states, actions) {
  return {
    ...states,
    actions: {
      ...states.actions,
      tabActive: actions.activeTabView,
    },
  };
}

function openSnackBarHandler(states, actions) {
  return {
    ...states,
    actions: {
      ...states.actions,
      snackbar: actions.snackBarContent,
    },
  };
}

function setRequestFieldsAcompanhamentoErrorHandler(states, actions) {
  const { name, error } = actions;
  const field = states.settingsAcompanhamento.requestFields[name];
  field.error = error;
  return {
    ...states,
    settingsAcompanhamento: {
      ...states.settingsAcompanhamento,
      requestFields: {
        ...states.settingsAcompanhamento.requestFields,
        [name]: field,
      },
    },
  };
}

function setRequestFieldsAcompanhamentoValueHandler(states, actions) {
  const { name, value } = actions;
  const field = states.settingsAcompanhamento.requestFields[name].onChange(value);
  return {
    ...states,
    settingsAcompanhamento: {
      ...states.settingsAcompanhamento,
      requestFields: {
        ...states.settingsAcompanhamento.requestFields,
        [name]: field,
      },
    },
  };
}

function openAlertDialogHandler(states, actions) {
  const alertDialog = {
    ...states.alertDialog,
    open: actions.open,
  };

  if (actions.variant || actions.message) {
    alertDialog.variant = actions.variant;
    alertDialog.message = actions.message;
    alertDialog.origin = actions.origin;
  }

  return {
    ...states,
    actions: {
      ...states.actions,
      alertDialog,
    },
  };
}

function openOptionalDialogHandler(states, actions) {
  return {
    ...states,
    actions: {
      ...states.actions,
      optionalDialogContent: actions.optionalDialogContent,
    },
  };
}

function openFollowUpMigrarDialogHandler(states, actions) {
  return {
    ...states,
    actions: {
      ...states.actions,
      followUpMigrarDialogContent: actions.followUpMigrarDialogContent,
    },
  };
}

function onFocusRamosAtividadeHandler(actions, states) {
  const model = states.controls.ramosAtividade.copy();
  return {
    ...states,
    controls: {
      ...states.controls,
      ramosAtividade: model.onFocus(),
    },
  };
}

function onFocusComercialPrincipalHandler(actions, states) {
  const model = states.controls.idUsuarioPrincipal.copy();
  return {
    ...states,
    controls: {
      ...states.controls,
      idUsuarioPrincipal: model.onFocus(),
    },
  };
}

function changeScrollDirectionHandler(states, actions) {
  return {
    ...states,
    settingsAcompanhamento: {
      ...states.settingsAcompanhamento,
      pageable: {
        ...states.settingsAcompanhamento.pageable,
        direction: actions.direction,
      },
    },
  };
}

function changePageableHandler(states, actions) {
  const { firstPage, lastPage, page } = actions;
  return {
    ...states,
    settingsAcompanhamento: {
      ...states.settingsAcompanhamento,
      pageable: {
        ...states.settingsAcompanhamento.pageable,
        firstPage,
        lastPage,
        page,
      },
    },
  };
}

function changeComissaoDefinitionHandler(states, actions) {
  const { idTipoCalculoComissao, description, value } = actions;

  const tipoCalculoComissoes = buildDefaultClienteTipoCalculoComissao(idTipoCalculoComissao, description);

  return {
    ...states,
    comissoesDefinition: value,
    controls: {
      ...states.controls,
      tipoCalculoComissoes: value === 'cliente' ? tipoCalculoComissoes : undefined,
    },
  };
}

function changeJurosDosDevedoresDefinitionHandler(states, actions) {
  const { value } = actions;

  const tipoCalculoJurosDevedores = buildDefaultClienteTipoCalculoJurosDevedores();

  return {
    ...states,
    jurosDosDevedoresDefinition: value,
    controls: {
      ...states.controls,
      tipoCalculoJurosDevedores: value === 'cliente' ? tipoCalculoJurosDevedores : undefined,
    },
  };
}

function changeHorarioDefinitionHandler(states, actions) {
  const {
    idTipoCalculoHonorario, description, value, fases,
  } = actions;

  const tipoCalculoHonorarios = buildDefaultClienteTipoCalculoHonorario(idTipoCalculoHonorario, description, fases);

  return {
    ...states,
    honorarioDefinition: value,
    controls: {
      ...states.controls,
      tipoCalculoHonorarios: value === 'cliente' ? tipoCalculoHonorarios : undefined,
    },
  };
}

function updateComissaoHandler(states, actions) {
  const {
    name, value, idx, event,
  } = actions;

  const tipoCalculoComissoes = { ...states.controls.tipoCalculoComissoes };
  const field = tipoCalculoComissoes.comissoes[idx][name].copy();

  let newField = field.copy().onFocus();
  if (event === 'change') {
    newField = field.onChange(value);
  }

  tipoCalculoComissoes.comissoes[idx][name] = newField;

  return {
    ...states,
    controls: {
      ...states.controls,
      tipoCalculoComissoes,
    },
  };
}

function updateHonorarioContratualHandler(states, actions) {
  const {
    name, value, idx, event,
  } = actions;

  const tipoHonorariosContratuais = { ...states.controls.tipoHonorariosContratuais };
  const field = tipoHonorariosContratuais.honorariosContratuais[idx][name].copy();

  let newField = field.copy().onFocus();
  if (event === 'change') {
    newField = field.onChange(value);
  }

  tipoHonorariosContratuais.honorariosContratuais[idx][name] = newField;

  return {
    ...states,
    controls: {
      ...states.controls,
      tipoHonorariosContratuais,
    },
  };
}

function updateJurosDosDevedoresHandler(states, actions) {
  const {
    name, value, idx, event,
  } = actions;

  const tipoCalculoJurosDevedores = { ...states.controls.tipoCalculoJurosDevedores };
  const field = tipoCalculoJurosDevedores.jurosDosDevedores[idx][name].copy();

  let newField = field.copy().onFocus();
  if (event === 'change') {
    newField = field.onChange(value);
  }

  tipoCalculoJurosDevedores.jurosDosDevedores[idx][name] = newField;

  return {
    ...states,
    controls: {
      ...states.controls,
      tipoCalculoJurosDevedores,
    },
  };
}

function updateHonorarioHandler(states, actions) {
  const {
    name, value, idx, event,
  } = actions;

  const tipoCalculoHonorarios = { ...states.controls.tipoCalculoHonorarios };
  const field = tipoCalculoHonorarios.honorarios[idx][name].copy();

  let newField = field.copy().onFocus();
  if (event === 'change') {
    newField = field.onChange(value);
  }

  tipoCalculoHonorarios.honorarios[idx][name] = newField;

  return {
    ...states,
    controls: {
      ...states.controls,
      tipoCalculoHonorarios,
    },
  };
}

function addHonorarioHandler(states, actions) {
  const { fases } = actions;

  const tipoCalculoHonorarios = { ...states.controls.tipoCalculoHonorarios };
  const { honorarios, idTipoCalculoHonorario: id } = tipoCalculoHonorarios;
  const { max, variation } = tipoCalculoEnum[id.value];

  const nextValue = getNextValueToRange(honorarios, 'valorFaixaFinal', max, variation);
  const newHonorario = buildDefaultClienteHonorario(nextValue, max, fases);

  tipoCalculoHonorarios.honorarios.push(builClienteHonorario(newHonorario));

  return {
    ...states,
    controls: {
      ...states.controls,
      tipoCalculoHonorarios,
    },
  };
}

function addComissaoHandler(states) {
  const tipoCalculoComissoes = { ...states.controls.tipoCalculoComissoes };
  const { comissoes, idTipoCalculoComissao: id } = tipoCalculoComissoes;
  const { max, variation } = tipoCalculoEnum[id.value];

  const nextValue = getNextValueToRange(comissoes, 'valorFaixaFinal', max, variation);
  const newComissao = buildDefaultClienteComissaoChange(nextValue, max);

  tipoCalculoComissoes.comissoes.push(buildClienteComissao(newComissao));

  return {
    ...states,
    controls: {
      ...states.controls,
      tipoCalculoComissoes,
    },
  };
}

function addJurosDoDevedorHandler(states) {
  const tipoCalculoJurosDevedores = { ...states.controls.tipoCalculoJurosDevedores };
  const { jurosDosDevedores } = tipoCalculoJurosDevedores;
  const { max, variation } = tipoCalculoEnum[1];

  const nextValue = getNextValueToRange(jurosDosDevedores, 'quantidadeDiasFinal', max, variation);
  const newJuros = buildDefaultClienteJuros(nextValue, max);

  tipoCalculoJurosDevedores.jurosDosDevedores.push(buildClienteJuros(newJuros));
  return {
    ...states,
    controls: {
      ...states.controls,
      tipoCalculoJurosDevedores,
    },
  };
}

function addHonorariosContratuaisHandler(states) {
  const tipoHonorariosContratuais = { ...states.controls.tipoHonorariosContratuais };
  const { honorariosContratuais } = tipoHonorariosContratuais;
  const { max, variation } = tipoCalculoEnum[2];
  let newHonorariosContratuais;
  if (honorariosContratuais.length > 0) {
    const nextValue = getNextValueToRange(honorariosContratuais, 'valorFaixaFinal', max, variation);
    newHonorariosContratuais = buildDefaultHonorarioContratual(nextValue, max);
  } else {
    newHonorariosContratuais = buildDefaultHonorarioContratual(0, max);
  }


  honorariosContratuais.push(buildHonorariosContratuaisCliente(newHonorariosContratuais));
  return {
    ...states,
    controls: {
      ...states.controls,
      tipoHonorariosContratuais: {
        ...tipoHonorariosContratuais,
        honorariosContratuais,
      },
    },
  };
}

function removeComissaoHandler(states, actions) {
  const { idx } = actions;
  let tipoComissao;

  const tipoCalculoComissoes = { ...states.controls.tipoCalculoComissoes };

  const tipoCalculo = tipoCalculoComissoes.idTipoCalculoComissao.value;
  const comissao = [...tipoCalculoComissoes.comissoes];

  comissao.splice(idx, 1);
  if (tipoCalculo === 1 || tipoCalculo === 3) {
    tipoComissao = getListIntervalCorrectAfterDeleteQuantidade(comissao, 'valorFaixaFinal', 'valorFaixaInicial', idx, 999999);
  } else {
    tipoComissao = getListIntervalCorrectAfterDeleteValor(comissao, 'valorFaixaFinal', 'valorFaixaInicial', idx, 9999999999.99);
  }

  tipoCalculoComissoes.comissoes = tipoComissao;

  return {
    ...states,
    controls: {
      ...states.controls,
      tipoCalculoComissoes,
    },
  };
}

function removeHonorarioContratualHandler(states, actions) {
  const { idx } = actions;

  const tipoHonorariosContratuais = { ...states.controls.tipoHonorariosContratuais };

  const honorariosContratuais = [...tipoHonorariosContratuais.honorariosContratuais];

  honorariosContratuais.splice(idx, 1);
  const tipoHonorarioContratual = getListIntervalCorrectAfterDeleteValor(honorariosContratuais, 'valorFaixaFinal', 'valorFaixaInicial', idx, 9999999999.99);

  tipoHonorariosContratuais.honorariosContratuais = tipoHonorarioContratual;

  return {
    ...states,
    controls: {
      ...states.controls,
      tipoHonorariosContratuais,
    },
  };
}

function removeHonorarioHandler(states, actions) {
  const { idx } = actions;
  let tipoCalculo;

  const tipoCalculoHonorarios = { ...states.controls.tipoCalculoHonorarios };

  const tipo = tipoCalculoHonorarios.idTipoCalculoHonorario.value;
  const lista = [...tipoCalculoHonorarios.honorarios];
  lista.splice(idx, 1);
  if (tipo === 1) {
    tipoCalculo = getListIntervalCorrectAfterDeleteQuantidade(lista, 'valorFaixaFinal', 'valorFaixaInicial', idx, 999999);
  } else {
    tipoCalculo = getListIntervalCorrectAfterDeleteValor(lista, 'valorFaixaFinal', 'valorFaixaInicial', idx, 9999999999.99);
  }
  tipoCalculoHonorarios.honorarios = tipoCalculo;
  return {
    ...states,
    controls: {
      ...states.controls,
      tipoCalculoHonorarios,
    },
  };
}

function setJurosDosDevedoresHandler(states, actions) {
  return {
    ...states,
    controls: {
      ...states.controls,
      tipoCalculoJurosDevedores: {
        ...states.controls.tipoCalculoJurosDevedores,
        jurosDosDevedores: [...actions.jurosDosDevedores],
      },
    },
  };
}

function updateTipoCalculoHonorarioHandler(states, actions) {
  const { id, description, fases } = actions;


  return {
    ...states,
    controls: {
      ...states.controls,
      tipoCalculoHonorarios: buildDefaultClienteTipoCalculoHonorario(id, description, fases),
    },
  };
}

function updateTipoCalculoComissaoHandler(states, actions) {
  const { id, description } = actions;

  return {
    ...states,
    controls: {
      ...states.controls,
      tipoCalculoComissoes: buildDefaultClienteTipoCalculoComissao(id, description),
    },
  };
}

function updateTipoHonorariosContratuaisHandler(states, actions) {
  const { id } = actions;

  return {
    ...states,
    controls: {
      ...states.controls,
      idTiposHonorarioContratual: {
        ...states.controls.idTiposHonorarioContratual,
        value: id,
      },
    },
  };
}

function setFaixasPrazoExpiracaoAttributeFocusHandler(states, action) {
  const prazosExpiracao = [...states.controls.prazosExpiracao];
  prazosExpiracao[action.index][action.name].onFocus();
  return {
    ...states,
    controls: {
      ...states.controls,
      prazosExpiracao,
    },
  };
}

function setFaixasPrazoExpiracaoAttributeValueHandler(states, action) {
  const prazosExpiracao = [...states.controls.prazosExpiracao];
  prazosExpiracao[action.index][action.name].onChange(action.value);
  return {
    ...states,
    controls: {
      ...states.controls,
      prazosExpiracao,
    },
  };
}

function setFaixasPrazoExpiracaoHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      prazosExpiracao: [...action.prazosExpiracao],
    },
  };
}

function setErrorCodPrincipalHandler(states, actions) {
  const codEmpresaPrincipal = new FieldModel({
    name: 'codEmpresaPrincipal',
    value: states.controls.codEmpresaPrincipal.value,
    maxLength: 9,
    type: POSITIVE_INTEGER,
    error: true,
    errorMessage: actions.errorMessage,
  });

  return {
    ...states,
    controls: {
      ...states.controls,
      codEmpresaPrincipal,
    },
  };
}

function setStatusPromisseCodEmpresaHandler(states, actions) {
  return {
    ...states,
    actions: {
      ...states.actions,
      statusPromisseCodEmpresa: actions.status,
      errorMessageCodEmpresa: actions.error,
    },
  };
}

function setHasErrorInValidationFormHandler(states, actions) {
  return {
    ...states,
    actions: {
      ...states.actions,
      hasErrorInValidationForm: actions.hasErrorInValidationForm,
    },
  };
}

function setImageHandler(states, action) {
  return {
    ...states,
    image: { ...action.image },
  };
}


function setFaixasDescontosBoletoAttributeValueHandler(states, action) {
  const descontoBoletoAS = [...states.controls.descontoBoletoAS];
  descontoBoletoAS[action.index][action.name].onChange(action.value);
  return {
    ...states,
    controls: {
      ...states.controls,
      descontoBoletoAS,
    },
  };
}

function setFaixasDescontosBoletoHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      descontoBoletoAS: [...action.descontoBoletos],
    },
  };
}

function setFaixasDescontosBoletoAttributeFocusHandler(states, action) {
  const descontoBoletoAS = [...states.controls.descontoBoletoAS];
  descontoBoletoAS[action.index][action.name].onFocus();
  return {
    ...states,
    controls: {
      ...states.controls,
      descontoBoletoAS,
    },
  };
}

function setFaixasDescontosPixAttributeValueHandler(states, action) {
  const descontoPixAS = [...states.controls.descontoPixAS];
  descontoPixAS[action.index][action.name].onChange(action.value);
  return {
    ...states,
    controls: {
      ...states.controls,
      descontoPixAS,
    },
  };
}

function setFaixasDescontosPixHandler(states, action) {
  return {
    ...states,
    controls: {
      ...states.controls,
      descontoPixAS: [...action.descontoPix],
    },
  };
}

function setFaixasDescontosPixAttributeFocusHandler(states, action) {
  const descontoPixAS = [...states.controls.descontoPixAS];
  descontoPixAS[action.index][action.name].onFocus();
  return {
    ...states,
    controls: {
      ...states.controls,
      descontoPixAS,
    },
  };
}

const onChangeActionsHandler = (states, action) => ({
  ...states,
  actions: {
    ...states.actions,
    [action.name]: action.value,
  },
});

function setDiasMesClientesHandler(states, action) {
  const {
    idOpcaoDiaMes,
    isAdd,
    fullRemove,
  } = action;

  if (fullRemove) {
    return {
      ...states,
      controls: {
        ...states.controls,
        diasDoMes: [],
      },
    };
  }
  const idsOpcaoDiaMes = [...states.controls.diasDoMes];
  if (isAdd) {
    idsOpcaoDiaMes.push(idOpcaoDiaMes);
    return {
      ...states,
      controls: {
        ...states.controls,
        diasDoMes: idsOpcaoDiaMes,
      },
    };
  }

  idsOpcaoDiaMes.splice(idsOpcaoDiaMes.indexOf(idOpcaoDiaMes), 1);
  return {
    ...states,
    controls: {
      ...states.controls,
      diasDoMes: idsOpcaoDiaMes,
    },
  };
}

function setFaixaArrayHandler(states, action) {
  const { name, value, buildFunc } = action;
  const newDescontoBoleto = buildFunc(value);

  return {
    ...states,
    controls: {
      ...states.controls,
      [name]: newDescontoBoleto,
    },
  };
}

function onChangeIdsTiposUsuariosHandler(states, actions) {
  const { idsTiposUsuarios } = actions;
  return {
    ...states,
    idsTiposUsuarios,
  };
}

function changeRequestFieldsFocusHandler(states, actions) {
  const { name } = actions;
  const fieldModel = states.controls[actions.name].copy();
  return {
    ...states,
    controls: {
      ...states.controls,
      [name]: fieldModel.onFocus(),
    },
  };
}

function changeRequestFieldsFocusEmpresaClienteNFHandler(states, actions) {
  const { name } = actions;
  const fieldModel = states.controls.empresaClienteNotaFiscal[actions.name].copy();
  return {
    ...states,
    controls: {
      ...states.controls,
      empresaClienteNotaFiscal: {
        ...states.controls.empresaClienteNotaFiscal,
        [name]: fieldModel.onFocus(),
      },
    },
  };
}

function onChangeControlsEmpresaClienteNotaFiscalHandler(states, actions) {
  const model = states.controls.empresaClienteNotaFiscal[actions.name].getNewModel(actions.value);

  return {
    ...states,
    controls: {
      ...states.controls,
      empresaClienteNotaFiscal: {
        ...states.controls.empresaClienteNotaFiscal,
        [actions.name]: model,
      },
    },
  };
}

function onChangeControlsNewEmpresaClienteNotaFiscalHandler(states, actions) {
  const { empresaCliente } = actions;

  return {
    ...states,
    controls: {
      ...states.controls,
      empresaClienteNotaFiscal: buildEmpresaClienteNotaFiscal(empresaCliente, true),
    },
  };
}

function alterarValorHonorariosMinimoFaixaFaseClienteHandler(states, actions) {
  const {
    faixaNum,
    newFaixa,
  } = actions;


  const { tipoCalculoHonorarios } = states.controls;

  tipoCalculoHonorarios.honorarios[faixaNum] = newFaixa;

  return {
    ...states,
    requestFields: {
      ...states.requestFields,
      tipoCalculoHonorarios,
    },
  };
}


export const setRequestFieldsFocusAction = name => ({
  type: ON_FOCUS_REQUEST_FIELDS_CLIENTES,
  name,
});

export const setRequestFieldsFocusEmpresaClienteNFAction = name => ({
  type: ON_FOCUS_REQUEST_FIELDS_EMPRESA_CLIENTE_NF,
  name,
});

export const setAcompanhamentosAction = (acompanhamentos, reverteOrder) => ({
  type: SET_ACOMPANHAMENTOS,
  acompanhamentos,
  reverteOrder,
});

export const onClearCombos = () => ({
  type: ON_CLEAR_COMBOS_CLIENTE,
});

export const onReloadCombos = reloadAllCombos => ({
  type: ON_RELOAD_COMBOS_CLIENTE,
  reloadAllCombos,
});

export const onChangeCategoria = value => ({
  type: ON_CHANGE_CATEGORIA,
  value,
});

export const onChangeControls = (name, value) => ({
  type: ON_CHANGE_CONTROLS,
  name,
  value,
});

export const onChangeCampanha = (name, value) => ({
  type: ON_CHANGE_CAMPANHA_ACTION,
  name,
  value,
});

export const onChangeCampanhaGeral = value => ({
  type: ON_CHANGE_CAMPANHA_GERAL_ACTION,
  value,
});

export const onFocusControls = name => ({
  type: ON_FOCUS_CONTROLS,
  name,
});

export const onFocusCampanha = name => ({
  type: ON_FOCUS_CAMPANHA,
  name,
});

export const onAddUsuarios = () => ({
  type: ON_ADD_USUARIOS,
});

export const onUpdateControlsEmails = emails => ({
  type: ON_UPDATE_EMAILS,
  emails,
});

export const onChangeEmail = (index, name, value) => ({
  type: ON_CHANGE_EMAIL,
  index,
  name,
  value,
});

export const onFocusEmails = (index, name, model) => ({
  type: ON_FOCUS_EMAIL,
  index,
  name,
  model,
});

export const onChangeRamosAtividade = values => ({
  type: ON_CHANGE_RAMOS_ATIVIDADE,
  values,
});

export const onUpdateEmpresasGrupo = (empresasGrupo = []) => ({
  type: ON_UPDATE_EMPRESAS_GRUPO,
  empresasGrupo,
});

export const onUpdateAllForm = (cliente, diasMes) => ({
  type: ON_UPDATE_ALL_FORM,
  cliente,
  diasMes,
});

export const onUpdateControls = controls => ({
  type: ON_UPDATE_CONTROLS,
  controls,
});

export const setLoadingAction = showLoading => ({
  type: SET_LOADING,
  showLoading,
});

export const setLoadingEmpresasGurpoAction = showLoading => ({
  type: SET_LOADING_EMPRESAS_GRUPO,
  showLoading,
});

export const setObjectFocusAction = (objectName, name, index) => ({
  type: SET_OBJECT_FOCUS,
  objectName,
  name,
  index,
});

export const setObjectsAction = (objectName, objects) => ({
  type: SET_OBJECTS,
  objectName,
  objects,
});

export const setObjectAttributeAction = (objectName, name, value, index) => ({
  type: SET_OBJECT_ATTRIBUTE,
  objectName,
  name,
  value,
  index,
});

export const setEnderecoAction = endereco => ({
  type: SET_ENDERECO,
  endereco,
});

export const setEnderecoEmpresaClienteNotaFiscalAction = endereco => ({
  type: SET_ENDERECO_EMPRESA_CLIENTE_NF_ACTION,
  endereco,
});

export const setEnderecoAttributeFocusAction = name => ({
  type: SET_ENDERECO_ATTRIBUTE_FOCUS,
  name,
});

export const setEnderecoEmpresaClienteNFAttributeFocusAction = name => ({
  type: SET_ENDERECO_EMPRESA_CLIENTE_NF_ATTRIBUTE_FOCUS,
  name,
});

export const setEnderecoAttributeValueAction = (name, value) => ({
  type: SET_ENDERECO_ATTRIBUTE_VALUE,
  name,
  value,
});

export const setEnderecoEmpresaClienteNFAttributeValueAction = (name, value) => ({
  type: SET_ENDERECO_EMPRESA_CLIENTE_NF_ATTRIBUTE_VALUE,
  name,
  value,
});

export const setTelefoneAdvogadoAttributeFocusAction = fieldName => ({
  type: SET_TELEFONE_ADVOGADO_ATTRIBUTE_FOCUS,
  fieldName,
});

export const setTelefoneAdvogadoAttributeValueAction = (name, value) => ({
  type: SET_TELEFONE_ADVOGADO_ATTRIBUTE_VALUE,
  name,
  value,
});

export const changeTabViewAction = activeTabView => ({
  type: CHANGE_TAB_PAGE_VIEW,
  activeTabView,
});

export const openSnackbarAction = snackBarContent => ({
  type: ON_OPEN_SNACKBAR,
  snackBarContent,
});

export const openDialogAction = (open, variant, message, origin) => ({
  type: ON_OPEN_DIALOG,
  open,
  variant,
  message,
  origin,
});

export const openOptionalDialogAction = optionalDialogContent => ({
  type: ON_OPEN_OPTIONAL_DIALOG,
  optionalDialogContent,
});

export const openMigrarFollowUpAction = followUpMigrarDialogContent => ({
  type: ON_OPEN_MIGRAR_FOLLOWUP_DIALOG,
  followUpMigrarDialogContent,
});

export const onFocusRamosAtividadeAction = ramosAtividade => ({
  type: ON_FOCUS_RAMOS_ATIVIADDE,
  ramosAtividade,
});

export const onFocusComercialPrincipalAction = idUsuarioPrincipal => ({
  type: ON_FOCUS_COMERCIAL_PRINCIPAL,
  idUsuarioPrincipal,
});

export const setAttributesAcompanhamentoAction = (name, value) => ({
  type: SET_ATTRIBUTES_ACOMPANHAMENTO_CLIENTE,
  name,
  value,
});

export const setAcompanhamentoPageableAction = (firstPage, lastPage, page) => ({
  type: SET_ACOMPANHAMENTO_PAGEABLE,
  firstPage,
  lastPage,
  page,
});

export const onClearAcompanhamentosAction = () => ({
  type: CLEAR_ACOMPANHAMENTOS_CLIENTE,
});

export const setRequestFieldsAcompanhamentoErrorAction = (name, error) => ({
  type: SET_REQUEST_FIELDS_ACOMPANHAMENTO_ERROR,
  name,
  error,
});

export const setRequestFieldsAcompanhamentoValueAction = (name, value) => ({
  type: SET_REQUEST_FIELDS_ACOMPANHAMENTO_VALUE,
  name,
  value,
});

export const setScrollDirectionAction = direction => ({
  type: SET_SCROLL_DIRECTION,
  direction,
});


export const setHonorarioDefinitionAction = (idTipoCalculoHonorario, description, value, fases) => ({
  type: SET_HONORARIO_DEFINITION,
  idTipoCalculoHonorario,
  description,
  value,
  fases,
});

export const setComissaoDefinitionAction = (idTipoCalculoComissao, description, value) => ({
  type: SET_COMISSAO_DEFINITION,
  idTipoCalculoComissao,
  description,
  value,
});

export const setJurosDosDevedoresDefinitionAction = value => ({
  type: SET_JUROS_DOS_DEVEDORES_DEFINITION,
  value,
});

export const updateComissaoAction = (name, value, idx, event = 'change') => ({
  type: UPDATE_COMISSAO,
  name,
  value,
  idx,
  event,
});

export const updateHonorarioContratualAction = (name, value, idx, event = 'change') => ({
  type: UPDATE_HONORARIO_CONTRATUAL,
  name,
  value,
  idx,
  event,
});

export const updateHonorarioAction = (name, value, idx, event = 'change') => ({
  type: UPDATE_HONORARIO,
  name,
  value,
  idx,
  event,
});

export const updateJurosDosDevedoresAction = (name, value, idx, event = 'change') => ({
  type: UPDATE_JUROS_DOS_DEVEDORES,
  name,
  value,
  idx,
  event,
});

export const alterarValorHonorariosMinimoFaixaFaseClienteAction = (faixaNum, newFaixa, fases) => ({
  type: ALTERAR_VALOR_HONORARIOS_MINIMO_FAIXA_FASE_CLIENTE,
  faixaNum,
  newFaixa,
  fases,
});

export const addHonorarioAction = fases => ({
  type: ADD_HONORARIO,
  fases,
});

export const addComissaoAction = () => ({
  type: ADD_COMISSAO,
});

export const addJurosDoDevedorAction = () => ({
  type: ADD_JUROS_DO_DEVEDOR,
});

export const addHonorarioContratualAction = () => ({
  type: ADD_HONORARIOS_CONTRATUAIS,
});

export const removeComissaoAction = idx => ({
  type: REMOVE_COMISSAO,
  idx,
});

export const removeHonorarioContratualAction = idx => ({
  type: REMOVE_HONORARIOS_CONTRATUAIS,
  idx,
});

export const removeHonorarioAction = idx => ({
  type: REMOVE_HONORARIO,
  idx,
});

export const updateTipoCalculoHonorarioAction = (id, description, fases) => ({
  type: UPDATE_TIPO_CALCULO_HONORARIO,
  id,
  description,
  fases,
});

export const updateTipoCalculoComissaoAction = (id, description) => ({
  type: UPDATE_TIPO_CALCULO_COMISSAO,
  id,
  description,
});

export const updateTipoHonorariosContratuaisAction = id => ({
  type: UPDATE_TIPO_HONORARIOS_CONTRATUAIS_ACTION,
  id,
});

export const setFaixasPrazoExpiracaoAttributeValueAction = (name, value, index) => ({
  type: SET_PRAZO_EXPIRACAO_ATTRIBUTE_VALUE,
  name,
  value,
  index,
});

export const setFaixasPrazoExpiracaoAttributeFocusAction = (name, index) => ({
  type: SET_PRAZO_EXPIRACAO_ATTRIBUTE_FOCUS,
  name,
  index,
});

export const setFaixasPrazoExpiracaoAction = prazosExpiracao => ({
  type: SET_PRAZO_EXPIRACAO,
  prazosExpiracao,
});

export const setErrorCodPrincipal = errorMessage => ({
  type: SET_ERROR_COD_PRINCIPAL,
  errorMessage,
});

export const setStatusPromisseCodEmpresa = (status = '', error) => ({
  type: SET_STATUS_PROMISSE_COD_EMPRESA,
  status,
  error,
});

export const setHasErrorInValidateFormAction = hasErrorInValidationForm => ({
  type: SET_ERRO_IN_VALIDATION_FORM,
  hasErrorInValidationForm,
});


export const setImageAction = image => ({
  type: SET_IMAGE,
  image,
});

export const setStoreValueAction = (name, value) => ({
  type: SET_STORE_VALUE,
  name,
  value,
});

export const setFaixasDescontoBoletoAttributeValueAction = (name, value, index) => ({
  type: SET_DESCONTOS_BOLETO_ATTRIBUTE_VALUE,
  name,
  value,
  index,
});

export const setFaixasDescontoBoletoAttributeFocusAction = (name, index) => ({
  type: SET_DESCONTOS_BOLETO_ATTRIBUTE_FOCUS,
  name,
  index,
});

export const setAdicionarContaAttributeValueAction = (name, value, index) => ({
  type: SET_ADICIONAR_CAMPOS_ATTRIBUTE_VALUE,
  name,
  value,
  index,
});

export const setAdicionarContaAttributeFocusAction = (name, index) => ({
  type: SET_ADICIONAR_CAMPOS_ATTRIBUTE_FOCUS,
  name,
  index,
});

export const setAdicionarCampoAction = adicionarConta => ({
  type: SET_ADICIONAR_CAMPOS,
  adicionarConta,
});

export const setFaixasDescontoBoletoAction = descontoBoletos => ({
  type: SET_DESCONTOS_BOLETO,
  descontoBoletos,
});

export const setFaixasDescontoPixAttributeValueAction = (name, value, index) => ({
  type: SET_DESCONTOS_PIX_ATTRIBUTE_VALUE,
  name,
  value,
  index,
});

export const setFaixasDescontoPixAttributeFocusAction = (name, index) => ({
  type: SET_DESCONTOS_PIX_ATTRIBUTE_FOCUS,
  name,
  index,
});

export const setFaixasDescontoPixAction = descontoPix => ({
  type: SET_DESCONTOS_PIX,
  descontoPix,
});

export const setFaixaArrayAction = (name, value, buildFunc) => ({
  type: SET_FAIXA_ARRAY,
  name,
  value,
  buildFunc,
});

export const onChangeActions = (name, value) => ({
  type: ON_CHANGE_ACTIONS,
  name,
  value,
});

export const onChangeControlsEmpresaClienteNotaFiscalAction = (name, value) => ({
  type: ON_CHANGE_EMPRESA_CLIENTE_NOTA_FISCAL_CONTROLS,
  name,
  value,
});

export const setSimpleValuesClienteAction = (name, value) => ({
  type: SET_SIMPLE_VALUES_CLIENTE_ACTION,
  name,
  value,
});

export const onChangeControlsNewEmpresaClienteNotaFiscalAction = empresaCliente => ({
  type: ON_CHANGE_NEW_EMPRESA_CLIENTE_NOTA_FISCAL_CONTROLS,
  empresaCliente,
});

export const setControlsAttributesAction = (name, value) => ({
  type: SET_ATTRIBUTES_CONTROLS_CLIENTE,
  name,
  value,
});

export const setJurosDosDevedoresAction = jurosDosDevedores => ({
  type: SET_JUROS,
  jurosDosDevedores,
});

export const onChangeIdsTiposUsuariosAction = idsTiposUsuarios => ({
  type: ON_CHANGE_IDS_TIPOS_USUARIOS,
  idsTiposUsuarios,
});


export default (states = initiateStates(), actions) => {
  switch (actions.type) {
    case ON_FOCUS_EMAIL:
      return onFocusEmailsHandler(states, actions);
    case ON_CHANGE_RAMOS_ATIVIDADE:
      return onChangeRamosAtividadeHandler(states, actions);
    case ON_CHANGE_EMAIL:
      return onChangeEmailsHandler(states, actions);
    case ON_ADD_USUARIOS:
      return onAddUsuariosHandler(states);
    case ON_UPDATE_EMAILS:
      return onUpdateControlsEmailsHandler(states, actions);
    case ON_CHANGE_CATEGORIA:
      return onChangeCategoriaHandler(states, actions);
    case ON_CHANGE_CONTROLS:
      return onChangeControlsHandler(states, actions);
    case ON_FOCUS_CONTROLS:
      return onFocusControlsHandler(states, actions);
    case ON_UPDATE_EMPRESAS_GRUPO:
      return onUpdateEmpresasGrupoHandler(states, actions);
    case ON_UPDATE_ALL_FORM:
      return onUpdateAllFormHandler(states, actions);
    case ON_UPDATE_CONTROLS:
      return onUpdateControlsHandler(states, actions);
    case ON_RELOAD_COMBOS_CLIENTE:
      return onReloadCombosHandler(states, actions);
    case SET_LOADING:
      return changeLoadingHandler(states, actions);
    case SET_LOADING_EMPRESAS_GRUPO:
      return changeLoadingEmpresasGurpoHandlerHandler(states, actions);
    case SET_OBJECT_ATTRIBUTE:
      return setObjectAttributeHandler(states, actions);
    case SET_OBJECTS:
      return setObjectsHandler(states, actions);
    case SET_OBJECT_FOCUS:
      return setObjectFocusHandler(states, actions);
    case SET_ENDERECO_ATTRIBUTE_FOCUS:
      return changeEnderecoAttributeFocusHandler(states, actions);
    case SET_ENDERECO:
      return changeEnderecoHandler(states, actions);
    case SET_ENDERECO_ATTRIBUTE_VALUE:
      return changeEnderecoAttributeValueHandler(states, actions);
    case SET_TELEFONE_ADVOGADO_ATTRIBUTE_FOCUS:
      return changeTelefoneAdvogadoAttributeFocusHandler(states, actions);
    case SET_TELEFONE_ADVOGADO_ATTRIBUTE_VALUE:
      return changeTelefoneAdvogadoAttributeValueHandler(states, actions);
    case CHANGE_TAB_PAGE_VIEW:
      return changeActivePageViewHandler(states, actions);
    case ON_OPEN_SNACKBAR:
      return openSnackBarHandler(states, actions);
    case ON_OPEN_DIALOG:
      return openAlertDialogHandler(states, actions);
    case ON_OPEN_OPTIONAL_DIALOG:
      return openOptionalDialogHandler(states, actions);
    case ON_FOCUS_RAMOS_ATIVIADDE:
      return onFocusRamosAtividadeHandler(actions, states);
    case ON_FOCUS_COMERCIAL_PRINCIPAL:
      return onFocusComercialPrincipalHandler(actions, states);
    case ON_CLEAR_COMBOS_CLIENTE:
      return onClearCombosHandler(states);
    case SET_ACOMPANHAMENTOS:
      return setAcompanhamentosHandler(states, actions);
    case SET_ATTRIBUTES_ACOMPANHAMENTO_CLIENTE:
      return setAcompanhamentosAttributesHandler(states, actions);
    case CLEAR_ACOMPANHAMENTOS_CLIENTE:
      return onClearAcompanhamentosHandler(states);
    case SET_REQUEST_FIELDS_ACOMPANHAMENTO_ERROR:
      return setRequestFieldsAcompanhamentoErrorHandler(states, actions);
    case SET_REQUEST_FIELDS_ACOMPANHAMENTO_VALUE:
      return setRequestFieldsAcompanhamentoValueHandler(states, actions);
    case SET_SCROLL_DIRECTION:
      return changeScrollDirectionHandler(states, actions);
    case SET_ACOMPANHAMENTO_PAGEABLE:
      return changePageableHandler(states, actions);
    case ON_OPEN_MIGRAR_FOLLOWUP_DIALOG:
      return openFollowUpMigrarDialogHandler(states, actions);
    case SET_HONORARIO_DEFINITION:
      return changeHorarioDefinitionHandler(states, actions);
    case UPDATE_HONORARIO:
      return updateHonorarioHandler(states, actions);
    case ADD_HONORARIO:
      return addHonorarioHandler(states, actions);
    case REMOVE_HONORARIO:
      return removeHonorarioHandler(states, actions);
    case UPDATE_TIPO_CALCULO_HONORARIO:
      return updateTipoCalculoHonorarioHandler(states, actions);
    case SET_PRAZO_EXPIRACAO_ATTRIBUTE_VALUE:
      return setFaixasPrazoExpiracaoAttributeValueHandler(states, actions);
    case SET_PRAZO_EXPIRACAO_ATTRIBUTE_FOCUS:
      return setFaixasPrazoExpiracaoAttributeFocusHandler(states, actions);
    case SET_PRAZO_EXPIRACAO:
      return setFaixasPrazoExpiracaoHandler(states, actions);
    case SET_COMISSAO_DEFINITION:
      return changeComissaoDefinitionHandler(states, actions);
    case UPDATE_TIPO_CALCULO_COMISSAO:
      return updateTipoCalculoComissaoHandler(states, actions);
    case UPDATE_TIPO_HONORARIOS_CONTRATUAIS_ACTION:
      return updateTipoHonorariosContratuaisHandler(states, actions);
    case ADD_COMISSAO:
      return addComissaoHandler(states);
    case UPDATE_COMISSAO:
      return updateComissaoHandler(states, actions);
    case UPDATE_HONORARIO_CONTRATUAL:
      return updateHonorarioContratualHandler(states, actions);
    case REMOVE_COMISSAO:
      return removeComissaoHandler(states, actions);
    case REMOVE_HONORARIOS_CONTRATUAIS:
      return removeHonorarioContratualHandler(states, actions);
    case SET_ERROR_COD_PRINCIPAL:
      return setErrorCodPrincipalHandler(states, actions);
    case SET_STATUS_PROMISSE_COD_EMPRESA:
      return setStatusPromisseCodEmpresaHandler(states, actions);
    case SET_ERRO_IN_VALIDATION_FORM:
      return setHasErrorInValidationFormHandler(states, actions);
    case SET_IMAGE:
      return setImageHandler(states, actions);
    case SET_STORE_VALUE:
      return setStoreValueHandler(states, actions);
    case SET_DESCONTOS_BOLETO_ATTRIBUTE_VALUE:
      return setFaixasDescontosBoletoAttributeValueHandler(states, actions);
    case SET_DESCONTOS_BOLETO_ATTRIBUTE_FOCUS:
      return setFaixasDescontosBoletoAttributeFocusHandler(states, actions);
    case SET_DESCONTOS_BOLETO:
      return setFaixasDescontosBoletoHandler(states, actions);
    case SET_DESCONTOS_PIX_ATTRIBUTE_VALUE:
      return setFaixasDescontosPixAttributeValueHandler(states, actions);
    case SET_DESCONTOS_PIX_ATTRIBUTE_FOCUS:
      return setFaixasDescontosPixAttributeFocusHandler(states, actions);
    case SET_DESCONTOS_PIX:
      return setFaixasDescontosPixHandler(states, actions);
    case SET_FAIXA_ARRAY:
      return setFaixaArrayHandler(states, actions);
    case ON_CHANGE_ACTIONS:
      return onChangeActionsHandler(states, actions);
    case SET_DIAS_MES_CLIENTE:
      return setDiasMesClientesHandler(states, actions);
    case ON_FOCUS_REQUEST_FIELDS_CLIENTES:
      return changeRequestFieldsFocusHandler(states, actions);
    case ON_FOCUS_REQUEST_FIELDS_EMPRESA_CLIENTE_NF:
      return changeRequestFieldsFocusEmpresaClienteNFHandler(states, actions);
    case ON_CHANGE_EMPRESA_CLIENTE_NOTA_FISCAL_CONTROLS:
      return onChangeControlsEmpresaClienteNotaFiscalHandler(states, actions);
    case SET_ENDERECO_EMPRESA_CLIENTE_NF_ACTION:
      return changeEnderecoEmpresaClienteNotaFiscalHandler(states, actions);
    case SET_ENDERECO_EMPRESA_CLIENTE_NF_ATTRIBUTE_VALUE:
      return changeEnderecoAttributeValueEmpresaClienteNFHandler(states, actions);
    case SET_ENDERECO_EMPRESA_CLIENTE_NF_ATTRIBUTE_FOCUS:
      return changeEnderecoEmpresaClienteNFAttributeFocusHandler(states, actions);
    case SET_SIMPLE_VALUES_CLIENTE_ACTION:
      return setSimpleValuesCliente(states, actions);
    case ON_CHANGE_NEW_EMPRESA_CLIENTE_NOTA_FISCAL_CONTROLS:
      return onChangeControlsNewEmpresaClienteNotaFiscalHandler(states, actions);
    case SET_ATTRIBUTES_CONTROLS_CLIENTE:
      return setControlsAttributesHandler(states, actions);
    case UPDATE_JUROS_DOS_DEVEDORES:
      return updateJurosDosDevedoresHandler(states, actions);
    case SET_JUROS_DOS_DEVEDORES_DEFINITION:
      return changeJurosDosDevedoresDefinitionHandler(states, actions);
    case ADD_JUROS_DO_DEVEDOR:
      return addJurosDoDevedorHandler(states);
    case ADD_HONORARIOS_CONTRATUAIS:
      return addHonorariosContratuaisHandler(states);
    case ON_CHANGE_CAMPANHA_ACTION:
      return onChangeCampanhaHandler(states, actions);
    case ON_CHANGE_CAMPANHA_GERAL_ACTION:
      return onChangeCampanhaGeralHandler(states, actions);
    case ON_FOCUS_CAMPANHA:
      return onFocusCampanhaHandler(states, actions);
    case SET_JUROS:
      return setJurosDosDevedoresHandler(states, actions);
    case ON_CHANGE_IDS_TIPOS_USUARIOS:
      return onChangeIdsTiposUsuariosHandler(states, actions);
    case ALTERAR_VALOR_HONORARIOS_MINIMO_FAIXA_FASE_CLIENTE:
      return alterarValorHonorariosMinimoFaixaFaseClienteHandler(states, actions);
    default:
      return states;
  }
};
