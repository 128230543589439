import React from 'react';
import { Button, TextField } from '@mui/material';
import Grid24 from '../../../../../../../components/UI/Grid24';

function DialogMotivoEstornoPrestacao({ textoAcompanhamento, handleOnClickConfirmMotivoEstorno }) {
  return (
    <>
      <Grid24 style={{ display: 'flex', flexDirection: 'column' }}>

        <TextField
          name="acompanhamento"
          defaultValue={textoAcompanhamento}
          id="outlined-multiline-static"
          rows={4}
          multiline
          disabled
          InputProps={{
            style: { fontSize: '13px' },
          }}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
            },
          }}
        />
      </Grid24>
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '22px' }}>
        <Button
          onClick={() => handleOnClickConfirmMotivoEstorno()}
        >
          OK
        </Button>
      </div>

    </>
  );
}


export default DialogMotivoEstornoPrestacao;
