import { getUriParams } from '../../helpers/utils/utils';
import { CORE, gateway } from '../api';

export function buscarTextos(idTipo, ordenacao, page, size) {
  const sortedQuery = ordenacao.reduce((obj, sort) => (
    `${obj}&sort=${sort}`
  ), '');

  const params = getUriParams({
    idTipo, page, size,
  });

  return gateway.get(`${CORE}/textos-padrao/?${sortedQuery}&${params}`);
}

export function editTexto(payload) {
  return gateway.put(`${CORE}/textos-padrao/`, payload);
}

export function deleteTexto(idTextoPadrao, idTipoTextoPadrao) {
  return gateway.delete(`${CORE}/textos-padrao/${idTextoPadrao}/textopadrao/${idTipoTextoPadrao}/tipotextopadrao/`);
}

export function cadastrarTextoPadrao(payload) {
  return gateway.post(`${CORE}/textos-padrao`, payload);
}

export function getTipoTextoPadrao() {
  return gateway.get(`${CORE}/tipos-texto-padrao`);
}

export function buscarTextosPadraoDeAcordoComTiposService(idsTextosPadrao = [], paged) {
  const textosPadrao = idsTextosPadrao.reduce((acc, id) => {
    if (!acc) {
      return `tipoTextoPadrao=${id}`;
    }
    return `${acc}&tipoTextoPadrao=${id}`;
  }, '');
  return gateway.get(`${CORE}/textos-padrao/tipos?${textosPadrao}`);
}

export function atualizarFavoritoService(payload) {
  return gateway.put(`${CORE}/textos-padrao/atualizar-favorito`, payload);
}
