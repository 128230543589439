import React, { useCallback, useEffect, useState } from 'react';


import { TableDefault } from '../../../../../../components';
import { TableBodyLancamentos } from '../../../../../../components/TabelaBody/V2/TableBodyLancamentos';
import { TableHeaderLacamentos } from '../../../../../../components/TabelaHeader/V2/TableHeaderLancamentos';
import { getNewPage } from '../../../../../../helpers';
import { pesquisarListaLancamentosAction } from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.saga';
import { setSimpleValueLancamentoHandlerAction } from '../../../../../../store/ContaCorrenteCliente/contaCorrenteCliente.store';
import { useCCClienteDispatch, useCCClienteSelector } from '../../../../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { lancamentosFactory } from './LancamentosFactory';
import useStyles from '../RelacaoPrestacao/RelacaoPrestacaoStyle';

function LancamentosComponent({ idPrestacaoContaCliente }) {
  const dispatch = useCCClienteDispatch();
  const styles = useStyles();

  const tableView = useCCClienteSelector(states => (states.lancamentos.tableView));
  const page = useCCClienteSelector(states => (states.lancamentos.page));
  const rowsPerPage = useCCClienteSelector(states => (states.lancamentos.rowsPerPage));
  const totalElements = useCCClienteSelector(states => (states.lancamentos.totalElements));
  const [conteudoTabela, setConteudoTabela] = useState([]); // State que vai atualizar quando tiver dadosPesquisa...
  const idRelacaoSelecionada = useCCClienteSelector(states => (states.common.idRelacaoSelecionada));

  const changePageHandler = useCallback((event, newPage) => {
    dispatch(setSimpleValueLancamentoHandlerAction('page', Number(newPage)));
    dispatch(pesquisarListaLancamentosAction(idPrestacaoContaCliente));
  }, [dispatch, idPrestacaoContaCliente]);

  const changeRowsPerPageHandler = useCallback((e) => {
    const size = e.target.value;
    const newPage = getNewPage(rowsPerPage, page, size);

    dispatch(setSimpleValueLancamentoHandlerAction('page', Number(newPage)));
    dispatch(setSimpleValueLancamentoHandlerAction('rowsPerPage', Number(size)));
    dispatch(pesquisarListaLancamentosAction(idPrestacaoContaCliente));
  }, [dispatch, rowsPerPage, page, idPrestacaoContaCliente]);

  useEffect(() => {
    setConteudoTabela(lancamentosFactory.makeConteudoTabela(
      tableView,
      idPrestacaoContaCliente,
    ));
  }, [tableView, idPrestacaoContaCliente]);

  useEffect(() => {
    if (idRelacaoSelecionada) {
      dispatch(pesquisarListaLancamentosAction(idPrestacaoContaCliente));
    }
  }, [dispatch, idPrestacaoContaCliente, idRelacaoSelecionada]);

  return (
    <div className={styles.table__lancamentos}>
      <TableDefault
        columnsHead={(
          <TableHeaderLacamentos />
        )}
        page={page}
        rowsPerPage={rowsPerPage}
        totalElements={totalElements}
        totalElementsOnPage={tableView?.length}
        totalColumns={6}
        onPageChange={changePageHandler}
        onRowsPerPageChange={changeRowsPerPageHandler}
        emptyRowsHeight={30}
        rowsPerPageOptions={[10, 15, 20]}
      >
        {conteudoTabela?.map((lancamento, index) => (
          <TableBodyLancamentos
            lancamento={lancamento}
            key={index}
          />
        ))}
      </TableDefault>
    </div>
  );
}

export default LancamentosComponent;
