export const TITLE = 'Juros Cobrados dos Devedores e Repasse ao Cliente';
export const ABE_DEF = 'Utilizar Percentual de Juros definido pela ABE';
export const CLIENTE_DEF = 'Utilizar Percentual de Juros definido pelo Cliente';
export const ADD_TITLE = 'Adicionar Faixa de Juros ';
export const ADD_TITLE_QUERY = 'Faixa(s) de Juros ';
export const ADD_HINT = 'Adicionar Faixa';
export const INITIAL = 'De';
export const FINAL = 'Até';
export const TAXA = 'Percentual de Juros';
export const REGRA_CALCULO_REPASSE = 'Regra de cálculo para o Repasse';
export const TAXA_REPASSE = 'Repasse';
