import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';

import TabCardLayout from '../../../layouts/TabCardLayout/v2/TabCardLayout';
import CampoObrigatorio from '../../UI/CampoObrigatorio/CampoObrigatorio';
import FooterForm from '../../UI/FooterForm/FooterForm';
import TabPanel from '../../UI/TabPanel/TabPanel';
import Snackbar from '../../UI/Snackbar/Snackbar';


import {
  closeErrorDialogContext,
  onChangeActions,
  onChangeControls,
  onClearComboFilial,
  onFocusAction,
  onSetActionAttributesAction,
  setAlertDialogAction,
  openDialogMudancaTipoUsuarioAction,
  closeErrorVinculadoDialogContext,
} from '../../../store/Usuario/V2/crudUsuario.store';
import {
  checkPermission, getInitialTabView, isLoading, moveScroll,
} from '../../../helpers/utils/utils';
import PaperUI from '../../UI/Paper/PaperUI';
import CardWrapper from '../../UI/CardWrapper/CardWrapper';
import DadosComplementares from './DadosComplementares/DadosComplementares';
import { findFasesAction, findTipoUsuarioAction } from '../../../store/Global/LoadSelectors/loadSelectors.saga';
import TabModel from '../../../models/TabModel';
import { useCrudUsuarioDispatch2, useCrudUsuarioSelector2 } from '../../../store/Usuario/V2/CrudUsuarioProvider';
import IdentificacaoUsuario from './DadosCadastrais/Identificacao/IdentificacaoCrudUsuario';
import EnderecoCrudUsuario from './DadosCadastrais/Endereco/EnderecoCrudUsuario';
import TelefonesCrudUsuario from './DadosCadastrais/Telefones/TelefonesCrudUsuario';
import TipoUsuario from './DadosCadastrais/TipoUsuario/TipoUsuarioCrud';
import SelecionarPermissoesUsuario from './Permissoes/Selecionar/SelecionarPermissoes';
import DefinirPermissoes from './Permissoes/Definir/DefinirPermissoes';
import {
  buildRelacoesItensConfiguraveisAction,
  fetchIdsTiposUsuariosAction,
  getUsuarioByIdAction,
  insertUsuarioAction,
  obterFotoAction,
  updateUsuarioAction,
} from '../../../store/Usuario/V2/crudUsuario.saga';
import dicaIcon from '../../UI/Dica/lampada-icon.png';
import UsuarioEquipe from './Equipe/UsuarioEquipe';
import FasesAtuacaoCrudUsuario from './DadosCadastrais/FasesAtuacaoCrudUsuario/FasesAtuacaoCrudUsuario';
import OptionDialogNew from '../../UI/Dialogs/OptionDialog/OptionDialogNew';


function CrudUsuario({
  history,
  match,
  isEditMode = false,
  isInsertMode = false,
  isInspectMode = false,
}) {
  const dispatch = useCrudUsuarioDispatch2();
  const globalDispatch = useDispatch();


  const openSnackbar = useCrudUsuarioSelector2(state => state.actions.openSnackbar);
  const snackbarVariantion = useCrudUsuarioSelector2(state => state.actions.snackbarVariantion);
  const snackbarMessage = useCrudUsuarioSelector2(state => state.actions.snackbarMessage);
  const alertDialog = useCrudUsuarioSelector2(state => state.actions.alertDialog);
  const image = useCrudUsuarioSelector2(state => state.image);
  const loadingPage = useCrudUsuarioSelector2(states => states.actions.loadingPage);
  const isConflict = useCrudUsuarioSelector2(states => states.actions.isConflict);
  const openErrorDialog = useCrudUsuarioSelector2(states => states.actions.openErrorDialog);
  const openErrorVinculadoDialog = useCrudUsuarioSelector2(states => states.actions.openErrorVinculadoDialog);
  const messageDialog = useCrudUsuarioSelector2(states => states.actions.messageDialog);
  const permissoesUsuario = useSelector(states => (states.authentication.userInformation ? states.authentication.userInformation.permissoes : []));
  const tiposUsuarios = useSelector(states => states.selectors.tipoUsuario.selector);
  const idTipoUsuario = useCrudUsuarioSelector2(state => state.controls.idTipoUsuario.copy());
  const pertenceAlgumaEquipe = useCrudUsuarioSelector2(state => state.controls.pertenceAlgumaEquipe);
  const mudancaTipoUsuario = useCrudUsuarioSelector2(state => state.controls.mudancaTipoUsuario);
  const permitirSubmitMudancaTipoUsuario = useCrudUsuarioSelector2(state => state.actions.permitirSubmitMudancaTipoUsuario);
  const idsTiposUsuarios = useCrudUsuarioSelector2(state => state.idsTiposUsuarios);


  const hasPermissoes = useCallback(() => (isInspectMode && checkPermission(permissoesUsuario, '201')), [isInspectMode, permissoesUsuario]);
  const isComercial = useCallback(() => (idTipoUsuario?.value === idsTiposUsuarios?.idComercial), [idTipoUsuario?.value, idsTiposUsuarios]);

  const makeAbas = useCallback((isComercialParam, hasPermissoesParam, pertenceAlgumaEquipeParam) => {
    const tabs = [
      new TabModel('tab-permissoes-usuario', 0, 'Permissões do Usuário', 'scrollable-auto-tabpanel', hasPermissoesParam),
      new TabModel('tab-dados-complementares', 1, 'Dados Complementares', 'scrollable-auto-tabpanel'),
    ];

    if (!pertenceAlgumaEquipeParam && isComercialParam) {
      tabs.push(new TabModel('tab-equipe-usuario', 2, 'Equipe', 'scrollable-auto-tabpanel'));
    }

    return tabs;
  }, []);

  const [tabItems, setTabItems] = useState(makeAbas(isComercial(), hasPermissoes(), pertenceAlgumaEquipe));


  useEffect(() => {
    setTabItems(makeAbas(isComercial(), hasPermissoes(), pertenceAlgumaEquipe));
  }, [isComercial, hasPermissoes, pertenceAlgumaEquipe, makeAbas]);

  const [reloadCombos, setReloadCombos] = useState(false);
  const [activeTabView, setActiveTabView] = useState(getInitialTabView(tabItems));

  const loadings = useSelector(states => [
    states.selectors.tipoUsuario.loading,
    states.selectors.bancos.loading,
    states.selectors.tiposConta.loading,
    states.selectors.perfis.loading,
  ]);

  useEffect(() => {
    setActiveTabView(getInitialTabView(tabItems));
  }, [tabItems]);


  // Carregamento de comportamentos iniciais, quando for um novo usuario carrega a relação de itens configuraveis.
  useEffect(() => {
    globalDispatch(findTipoUsuarioAction());
    moveScroll();
    if (match.params && match.params.id) {
      const idUsuario = match.params.id;
      dispatch(getUsuarioByIdAction(idUsuario));
      dispatch(obterFotoAction(idUsuario));
    } else {
      dispatch(buildRelacoesItensConfiguraveisAction());
    }
  }, [dispatch, globalDispatch, match]);


  const rollbackPageUsuario = useCallback(() => {
    history.replace('/usuario');
  }, [history]);

  useEffect(() => {
    if (permitirSubmitMudancaTipoUsuario) {
      const foto = image.file ? image.file : null;
      dispatch(updateUsuarioAction(match.params.id, rollbackPageUsuario, foto));
    }
  }, [permitirSubmitMudancaTipoUsuario, dispatch, image.file, match.params.id, rollbackPageUsuario]);

  const submitHandler = useCallback(() => {
    const foto = image.file ? image.file : null;
    if (isInsertMode) {
      dispatch(insertUsuarioAction(foto));
      setActiveTabView(getInitialTabView(tabItems));
      moveScroll();
    }
    if (isEditMode) {
      if (mudancaTipoUsuario) {
        dispatch(openDialogMudancaTipoUsuarioAction());
      } else {
        dispatch(updateUsuarioAction(match.params.id, rollbackPageUsuario, foto));
      }
    }
  }, [dispatch, isEditMode, isInsertMode, match, image, rollbackPageUsuario, tabItems, mudancaTipoUsuario]);


  const closeSnackbarHandler = useCallback(() => {
    dispatch(onChangeActions('openSnackbar', false));
  }, [dispatch]);


  const closeErrorDialogHandler = useCallback(() => {
    dispatch(closeErrorDialogContext());
    if (!isConflict) {
      history.replace('/usuario');
    }
    dispatch(onSetActionAttributesAction('isConflict', false));
    setReloadCombos(reload => !reload);
    dispatch(onClearComboFilial(''));
  }, [dispatch, isConflict, setReloadCombos, history]);

  const getValue = useCallback((value, checked, type) => {
    if (type === 'checkbox') {
      return checked;
    }
    return value;
  }, []);

  const changeHandler = useCallback((event) => {
    const {
      name, value, checked, type,
    } = event.target;
    const attributeType = getValue(value, checked, type);
    dispatch(onChangeControls(name, attributeType));
  }, [dispatch, getValue]);

  const onFocusHandler = useCallback((event) => {
    const { name } = event.target;
    dispatch(onFocusAction(name));
  }, [dispatch]);

  const onCloseWarningDialogHandler = useCallback(() => {
    dispatch(setAlertDialogAction(false, ''));
  }, [dispatch]);

  useEffect(() => {
    globalDispatch(findFasesAction());
    dispatch(fetchIdsTiposUsuariosAction());
  }, [dispatch, globalDispatch]);

  return (
    <>
      <OptionDialogNew
        open={openErrorDialog}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={closeErrorDialogHandler}
        onClickConfirm={closeErrorDialogHandler}
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>
            {messageDialog}
          </p>
        </div>
      </OptionDialogNew>
      <OptionDialogNew
        open={openErrorVinculadoDialog}
        confirmLabel="Ok, entendi!"
        onClickConfirm={() => { dispatch(closeErrorVinculadoDialogContext()); }}
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>
            {messageDialog}
          </p>
        </div>
      </OptionDialogNew>
      <OptionDialogNew
        open={alertDialog.open}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        onClickCancel={onCloseWarningDialogHandler}
        onClickConfirm={onCloseWarningDialogHandler}
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>
            {alertDialog.message}
          </p>
        </div>
        <Typography style={{ textAlign: 'center' }} component="p">
          <img src={dicaIcon} alt="Dica" width={18} height={18} />
          {' '}
          <b>Dica</b>
          : Abra o arquivo inválido em um editor de imagem e
          salve como uma nova imagem de formato válido (PNG, JPEG ou JPG).
          Retorne para o cadastro e tente selecionar a imagem novamente.
        </Typography>
      </OptionDialogNew>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={10000}
        variant={snackbarVariantion}
        message={snackbarMessage}
        onClose={closeSnackbarHandler}
      />
      <PaperUI>
        <CardWrapper showLoading={isLoading([...loadings, loadingPage])}>
          <CampoObrigatorio disabled={isInspectMode} />
          <IdentificacaoUsuario
            isEditMode={isEditMode}
            isInsertMode={isInsertMode}
            disabled={isInspectMode}
            showPassword={isInspectMode || isEditMode}
            changeHandler={changeHandler}
            reloadCombos={reloadCombos}
            onFocusHandler={onFocusHandler}
            idTipoUsuario={idTipoUsuario}
            idsTiposUsuarios={idsTiposUsuarios}
          />
          <EnderecoCrudUsuario
            isInspectMode={isInspectMode}
          />
          <TipoUsuario
            isEditMode={isEditMode}
            isInsertMode={isInsertMode}
            disabled={isInspectMode}
            tiposUsuarios={tiposUsuarios}
            idTipoUsuario={idTipoUsuario}
            onFocusHandler={onFocusHandler}
            changeHandler={changeHandler}
            idsTiposUsuarios={idsTiposUsuarios}
          />
          <FasesAtuacaoCrudUsuario
            isEditMode={isEditMode}
            isInsertMode={isInsertMode}
            disabled={isInspectMode}
          />
          <TelefonesCrudUsuario
            isInsertMode={isInsertMode}
            isEditMode={isEditMode}
            isInspectMode={isInspectMode}
          />
        </CardWrapper>
      </PaperUI>
      <TabCardLayout
        separate
        tabItems={tabItems}
        parentStates={[activeTabView, setActiveTabView]}
        footerComponent={(
          <FooterForm
            hasRollback={isInspectMode}
            cancelHandler={rollbackPageUsuario}
            submitHandler={submitHandler}
            rollbackHandler={rollbackPageUsuario}
          />
        )}
      >
        <TabPanel id={tabItems[0].id} index={tabItems[0].index} value={activeTabView}>
          <SelecionarPermissoesUsuario
            isInsertMode={isInsertMode}
            isInspectMode={isInspectMode}
            changeHandler={changeHandler}
          />
          <DefinirPermissoes disabled={isInspectMode} />
        </TabPanel>
        <TabPanel id={tabItems[1].id} index={tabItems[1].index} value={activeTabView}>
          <DadosComplementares
            disable={isInspectMode}
            showPassword={isInspectMode || isEditMode}
            changeCheckedHandler={changeHandler}
            changeHandler={changeHandler}
            onFocusHandler={onFocusHandler}
          />
        </TabPanel>
        <TabPanel id={tabItems[2]?.id} index={tabItems[2]?.index} value={activeTabView}>
          <UsuarioEquipe
            isInspectMode={isInspectMode}
            isInsertMode={isInsertMode}
            idUsuarioEdicao={match.params.id}
            changeHandler={changeHandler}
            onFocusHandler={onFocusHandler}
          />
        </TabPanel>
      </TabCardLayout>
    </>
  );
}

export default withRouter(CrudUsuario);
