import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ContaCorrenteClienteProvider from '../../../store/ContaCorrenteCliente/ContaCorrenteClienteProvider';
import { ContaCorrenteCliente } from './ContaCorrenteCliente/ContaCorrenteCliente';
import { initializePageAction } from '../../../store/theme.actions';
import withTokenInterceptor from '../../../hoc/withTokenInterceptor/withTokenInterceptor';

function ContaCorrenteClientePageV2() {
  const dispatch = useDispatch();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const idCliente = query.get('idCliente');
  const nossoNumeroBoleto = query.get('nossoNumeroBoleto');

  dispatch(initializePageAction('Conta-Corrente do Cliente'));
  return (
    <ContaCorrenteClienteProvider>
      <ContaCorrenteCliente
        idCliente={idCliente}
        nossoNumeroBoleto={nossoNumeroBoleto}
      />
    </ContaCorrenteClienteProvider>
  );
}

export default withTokenInterceptor(ContaCorrenteClientePageV2);
