import React from 'react';
import { Typography } from '@material-ui/core';

function CustomFieldTitle({ textField }) {
  return (
    <Typography component="p" style={{ fontSize: '12px', marginBottom: '3px' }}>
      {textField}
    </Typography>
  );
}

export default React.memo(CustomFieldTitle);
